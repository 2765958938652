import React from "react";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";

import EditConsignmentModal from "containers/Consignment";

import { openEditConsignmentModal } from "lib/navigation";

function EditConsignmentModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.Consignment] || "";
  const [
    consignmentId,
    section,
    showSaleLots,
    fullScreen,
    nominationId,
    agencyId,
    receivalLotId,
  ] = JSON.parse(hashParams);
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";

  function changeSection(currentSection, newSection) {
    openEditConsignmentModal(
      consignmentId,
      currentSection === newSection ? null : newSection,
      showSaleLots,
      fullScreen,
      decodedReturnTo,
      nominationId,
      agencyId,
      receivalLotId,
    );
  }

  return (
    <WaitForSync requiredData={[ApiModel.BUSINESSES, ApiModel.CONSIGNMENTS]}>
      <EditConsignmentModal
        consignmentId={consignmentId}
        returnTo={decodedReturnTo}
        section={section}
        showSaleLots={showSaleLots}
        fullScreen={fullScreen}
        changeSection={changeSection}
        nominationId={nominationId}
        agencyId={agencyId}
        receivalLotId={receivalLotId}
        {...props}
      />
    </WaitForSync>
  );
}

export default EditConsignmentModalAdapter;
