import React, { useEffect, useMemo } from "react";

import { Divider, Grid } from "@material-ui/core";
import Big from "big.js";
import { Form, Formik } from "formik";
import sum from "lodash/sum";
import { useDispatch, useSelector } from "react-redux";

import {
  DefaultVendorSplitAction,
  SaleVendorSplitAction,
} from "actions/vendorSplits";

import { SlimSecondaryButton } from "components/Button";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import { SelectField } from "components/Form/FormikControls";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { Splits } from "components/VendorSplits/NewVendorSplitModal/splits";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import {
  VendorSplitType,
  VendorSplitTypeOptions,
} from "constants/vendorSplits";

import { openModalLink } from "lib/navigation";
import { getVendorSplitKey } from "lib/vendorSplits";

import {
  getActiveLivestockAgentDeployment,
  getConsignmentById,
  getSaleLotById,
  getSaleLots,
  getVendorSplitByKey,
  selectConsignmentIdsByVendorIdLookup,
  selectDescriptionBySplitIdLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectVendorSplitByKeyLookup,
} from "selectors";

import { useBoolean } from "hooks";

const VendorSplitForm = ({
  primaryBusinessId,
  livestockSaleId,
  consignmentId,
  saleLotId,
  isDefault,
  onClose,
  returnTo,
}) => {
  const dispatch = useDispatch();

  const singleConsignmentVendor =
    useSelector(selectConsignmentIdsByVendorIdLookup)[primaryBusinessId]
      .length === 1;

  useEffect(() => {
    if (singleConsignmentVendor && consignmentId) {
      //   redirect to the sale default.
      openModalLink(
        ModalTypes.VendorSplit,
        {
          primaryBusinessId,
        },
        returnTo,
      );
    }
  }, [consignmentId, primaryBusinessId, returnTo, singleConsignmentVendor]);

  const deploymentId = useSelector(getActiveLivestockAgentDeployment)?.id;
  const action = isDefault ? DefaultVendorSplitAction : SaleVendorSplitAction;
  const handleSubmit = values => {
    const payload = {
      ...values,
      deploymentId,
    };

    // If there is no default split for this vendor, make it the default.
    if (consignmentId && !saleSplit) {
      payload.consignmentId = null;
    }

    dispatch(action.create(payload));

    onClose();
  };

  const filterFields = {
    primaryBusinessId,
    deploymentId,
    saleLotId,
    consignmentId,
    livestockSaleId,
  };

  const existingSplit = useSelector(
    getVendorSplitByKey(getVendorSplitKey(filterFields)),
  );

  const onConfirmDelete = () => {
    dispatch(action.delete(existingSplit.id));
    onClose();
  };

  const initialValues = {
    primaryBusinessId,
    saleLotId,
    consignmentId,
    isDefault,
    type: VendorSplitType.Percentage,
    livestockSaleId,
    splits: [{ businessId: primaryBusinessId, amount: 1 }],
    ...existingSplit,
  };

  const descriptionBySplitIdLookup = useSelector(
    selectDescriptionBySplitIdLookup,
  );

  const consignment = useSelector(getConsignmentById(consignmentId));
  const saleLot = useSelector(getSaleLotById(saleLotId));

  const saleLotIdsByConsignmentIdLookup = useSelector(
    selectSaleLotIdsByConsignmentIdLookup,
  );
  const saleLotLookup = useSelector(getSaleLots);

  const vendorSplitByKeyLookup = useSelector(selectVendorSplitByKeyLookup);

  const saleSplitKey = getVendorSplitKey({
    primaryBusinessId,
    deploymentId,
    livestockSaleId,
  });
  const saleSplit = vendorSplitByKeyLookup[saleSplitKey];

  const relatedConsignmentId = consignmentId || saleLot?.consignment_id;
  const consignmentSplitKey =
    relatedConsignmentId &&
    getVendorSplitKey({
      primaryBusinessId,
      deploymentId,
      livestockSaleId,
      consignmentId: relatedConsignmentId,
    });
  const consignmentSplit = vendorSplitByKeyLookup[consignmentSplitKey];

  const headCount = useMemo(() => {
    if (consignmentId) {
      return sum(
        saleLotIdsByConsignmentIdLookup[consignmentId]?.map(
          saleLotId => saleLotLookup[saleLotId].quantity,
        ),
      );
    }
  }, [consignmentId, saleLotIdsByConsignmentIdLookup, saleLotLookup]);

  const splitMessage = useMemo(() => {
    const action = existingSplit ? "Update" : "Create";

    if (saleLot) {
      return `${action} Vendor Split for Sale Lot #${saleLot.lot_number} (${saleLot.quantity})`;
    } else if (consignment) {
      return `${action} Vendor Split for Consignment ( ${headCount} hd in ${
        saleLotIdsByConsignmentIdLookup[consignmentId]?.length || 0
      } Sale Lots)`;
    } else {
      return `${action} Sale Vendor Split`;
    }
  }, [
    consignment,
    consignmentId,
    existingSplit,
    headCount,
    saleLot,
    saleLotIdsByConsignmentIdLookup,
  ]);

  const validate = values => {
    const errors = {};
    const { saleLotId, splits, type } = values;

    const totalAmount = splits.reduce((acc, cur) => {
      return acc.plus(new Big(cur.amount || 0));
    }, new Big(0));

    if (splits.some(split => !split.businessId)) {
      errors.splits = "All splits must have a business.";
    }

    if (type === VendorSplitType.Percentage) {
      if (!totalAmount.eq(1)) {
        errors.splits = `Split percentages must add up to 100% (${new Big(1)
          .minus(totalAmount)
          .times(100)}% difference)`;
      }
    } else if (!saleLotId) {
      errors.splits = "Headcount splits must be on a Sale Lot.";
    } else if (!totalAmount.eq(saleLot.quantity)) {
      errors.splits = `Total split amount must equal Sale Lot headcount (${totalAmount
        .times(-1)
        .plus(saleLot.quantity)} difference)`;
    }

    return errors;
  };
  const [isShowingConfirmDelete, showConfirmDelete, hideConfirmDelete] =
    useBoolean(false);

  const onClickEditDefault = () =>
    openModalLink(ModalTypes.VendorSplit, {
      primaryBusinessId,
    });

  const showSaleOverride = saleSplit && (consignmentId || saleLotId);
  const showConsignmentOverride = consignmentSplit && saleLotId;

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>{splitMessage}</DialogTitle>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
      >
        <Form>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {showSaleOverride && (
                  <Grid item xs={12}>
                    This will override the existing
                    <SlimSecondaryButton onClick={onClickEditDefault}>
                      Split for the Sale
                    </SlimSecondaryButton>{" "}
                    on the vendor ({descriptionBySplitIdLookup[saleSplit.id]})
                  </Grid>
                )}
                {showConsignmentOverride && (
                  <Grid item xs={12}>
                    This will override the split defined on the consignment (
                    {descriptionBySplitIdLookup[consignmentSplit.id]})
                  </Grid>
                )}
                {(showSaleOverride || showConsignmentOverride) && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
                {saleLotId && (
                  <Grid item xs={6}>
                    <SelectField
                      name="type"
                      label="Type"
                      tooltip="Only sale lots can be split by head count."
                      options={VendorSplitTypeOptions}
                      menuPortalTarget={document.body}
                    />
                  </Grid>
                )}
              </Grid>
              <Splits />
              {consignmentId && (
                <Grid item xs={12}>
                  Note: Splits on a Consignment will override those on a Sale,
                  and can be overridden further on a Sale Lot
                </Grid>
              )}
              {saleLotId && (
                <Grid item xs={12}>
                  Note: Splits on a Sale Lot will override those on a Sale or
                  Consignment.
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <SecondaryButton type="button" onClick={onClose}>
              Back
            </SecondaryButton>
            <DeleteButton
              type="button"
              onClick={showConfirmDelete}
              disabled={!existingSplit?.id}
            >
              Delete
            </DeleteButton>
            <Button data-tour="submit" type="submit">
              Submit
            </Button>
          </DialogActions>
          <ConfirmDialog
            title={createModalTitle("this Vendor Split")}
            isOpen={isShowingConfirmDelete}
            onCancel={hideConfirmDelete}
            onDelete={onConfirmDelete}
          />
        </Form>
      </Formik>
    </ZoomyDialog>
  );
};

export function VendorSplitModal(props) {
  return (
    <WaitForSync
      requiredData={[
        ApiModel.CONSIGNMENTS,
        ApiModel.BUSINESSES,
        ApiModel.SALE_VENDOR_SPLITS,
      ]}
    >
      <VendorSplitForm {...props} />
    </WaitForSync>
  );
}
