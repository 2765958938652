import React from "react";

import { Grid, Slide } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import styled from "styled-components/macro";

import { ForMobile } from "components/ForDeviceType";
import { Paper } from "components/Paper";
import { MediumMobileCard } from "components/SaleSummaryCards/Cards";
import { CompactCardStack } from "components/SaleSummaryCards/Common";
import { TitleText } from "components/Text";

import { SaleTypes } from "constants/sale";

import Reports from "containers/Dashboard/Reports";
import { ForNotHooks } from "containers/ForSaleType";

import { dateTimeStringToDateTime, formatDateString } from "lib/timeFormats";

import { PrivateSaleExceptionsComponent } from "./ExceptionsTable";

const SummaryGrid = styled(Grid)`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const PaddedPaper = styled(Paper)`
  margin: ${({ theme, spacing }) => theme.space[spacing]}px;
  width: 100%;
`;

export const PrivateSaleOverview = ({
  sale,
  notes,
  buyerName,
  vendorName,
  product,
  breed,
  carrierName,
  quantity,
  deploymentSales,
}) => {
  return (
    <Slide in direction="right">
      <SummaryGrid container justifyContent="space-evenly">
        <Grid item xs={12}>
          <TitleText center block>
            {sale.sale_title}
          </TitleText>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Container disableGutters>
            <CompactCardStack>
              <MediumMobileCard
                title={
                  sale.sale_type === SaleTypes.OVER_HOOKS
                    ? "Kill Date"
                    : "Sale Date"
                }
                value={
                  sale.date
                    ? formatDateString(dateTimeStringToDateTime(sale.date))
                    : "-"
                }
              />
              <ForNotHooks>
                <MediumMobileCard title="Vendor" value={vendorName} />
              </ForNotHooks>
              <MediumMobileCard title="Buyer" value={buyerName} />
              <MediumMobileCard title="Head" value={quantity} />
              <MediumMobileCard title="Description" value={product} />
              <MediumMobileCard title="Breed" value={breed} />
              <MediumMobileCard title="Carrier" value={carrierName} />
            </CompactCardStack>
          </Container>
          {notes && (
            <PaddedPaper spacing={2} elevation={3} square>
              {notes}
            </PaddedPaper>
          )}
        </Grid>

        <Grid container spacing={2} style={{ margin: 0 }}>
          {/* TODO: Make this button work */}
          {/* <Grid item xs={12}>
            <IconButton icon={faCheck} fullWidth disabled data-tour="confirm">
              Confirm
            </IconButton>
          </Grid> */}
        </Grid>
        <ForMobile>
          <Grid item container xs={12} justifyContent="center">
            <PaddedPaper elevation={3} spacing={2} square>
              <Reports />
            </PaddedPaper>
          </Grid>
        </ForMobile>
        {deploymentSales.map(deploymentSale => (
          <PrivateSaleExceptionsComponent
            key={deploymentSale?.deployment_sale_id}
            deploymentSaleId={deploymentSale?.deployment_sale_id}
          />
        ))}
      </SummaryGrid>
    </Slide>
  );
};
