import {
  GET_REPORTS,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAILURE,
  SET_ACTIVE_ROLE,
  SET_CURRENT_LIVESTOCKSALE,
} from "constants/actionTypes";

const initialState = {
  isFetching: false,
  reports: {},
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_ROLE.REQUEST:
    case SET_CURRENT_LIVESTOCKSALE.SUCCESS: {
      return initialState;
    }

    case GET_REPORTS:
      return {
        ...initialState,
        isFetching: true,
      };

    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.reports,
        isFetching: false,
      };
    case GET_REPORTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default reports;
