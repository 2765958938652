import React from "react";

import { useTheme } from "hooks";

export const Unknown = ({ color, size }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.black;
  const svgSize = size || theme.fontSizes.gamma;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox="0 0 27500 27500"
    >
      <defs>
        <style type="text/css" />
      </defs>
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6299.2"
        y1="6270.7"
        x2="6299.2"
        y2="17175.6"
      />
      <line
        stroke={svgColor}
        style={{
          strokeWidth: 1270,
          strokeMiterlimit: 22.9256,
          strokeDasharray: 1270.0,
        }}
        x1="21259.3"
        y1="10010.7"
        x2="21259.3"
        y2="6270.7"
      />
      <line
        stroke={svgColor}
        style={{
          strokeWidth: 1270,
          strokeMiterlimit: 22.9256,
          strokeDasharray: 1270.0,
        }}
        x1="6299.2"
        y1="21230.8"
        x2="6299.2"
        y2="17512.2"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13779.3"
        y1="21230.8"
        x2="21259.3"
        y2="21230.8"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21259.3"
        y1="21230.8"
        x2="21259.3"
        y2="9807.6"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13779.3"
        y1="6270.7"
        x2="21259.3"
        y2="6270.7"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6299.2"
        y1="21230.8"
        x2="13779.3"
        y2="21230.8"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6299.2"
        y1="6270.7"
        x2="13779.3"
        y2="6270.7"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6299.2"
        cy="16582.2"
        r="336.6"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6299.2"
        cy="6270.7"
        r="336.6"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6299.2"
        cy="21230.8"
        r="336.6"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13779.3"
        cy="21230.8"
        r="336.6"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13779.3"
        cy="6270.7"
        r="336.6"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21259.3"
        cy="9367.6"
        r="336.6"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21259.3"
        cy="21230.8"
        r="336.6"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21259.3"
        cy="6270.7"
        r="336.6"
      />
      <path
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 20, strokeMiterlimit: 22.9256 }}
        d="M13402.7 9713.7c-494.9 33.5-946.7 139.5-1331.3 365.6-290.5 170.8-277.4 191.8-490.4 352.4-146.1 110.3-477.1 509.1-584 672.7-60.1 92.1-74.8 225.2-29.8 335.1 65.4 159.7 271.8 272.9 441 402.4 223.8 171.2 543.1 507.8 820.6 208.4l142.8-171.4c20.1-24.5 35.4-33.4 56.2-57.4 131.6-152.1 240.6-273.6 448.7-394.4 607.2-352.3 1630.9-35 1683.1 559.9 76.4 871.9-1798.6 819.2-1985.5 2237.8-112 850.3 269.3 727 932.6 731.8 234.6 1.7 604.3 45.2 756.7-86.3 227.9-196.7 48.4-391.7 234.7-606.9 398.2-459.9 1964.6-755.5 1801.3-2411.6-34.9-353.9-159.1-640-340.8-918.3-536.3-820.9-1571.8-1286.6-2555.9-1219.9z"
      />
      <path
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 20, strokeMiterlimit: 22.9256 }}
        d="M12424.9 16556c39 549.1 549.8 1014.2 1153.7 959.3 553.9-50.3 996.5-540.8 952.5-1143.5-40.1-549.7-544.3-1015.7-1153.6-961.6-552.2 49-995.8 537.7-952.6 1145.8z"
      />
    </svg>
  );
};

export default Unknown;
