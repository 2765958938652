import { DeclarationTypes } from "constants/documentTypes";
import { Species } from "constants/species";

export const declarationVersions = {
  [Species.CATTLE]: [
    {
      value: DeclarationTypes.C0413,
      label: "Cattle (Yellow) C0413",
    },
    {
      value: DeclarationTypes.C0720,
      label: "Cattle (Yellow) C0720",
    },

    {
      value: DeclarationTypes.E0413,
      label: "EU Cattle (Green) E0413",
    },
    {
      value: DeclarationTypes.E0720,
      label: "EU Cattle (Green) E0720",
    },
    {
      value: DeclarationTypes.BC0720,
      label: "Bobby Calves (Dark Green) BC0720",
    },
  ],
  [Species.SHEEP]: [
    {
      value: DeclarationTypes.S0413,
      label: "Sheep (Purple) S0413",
    },
    {
      value: DeclarationTypes.S0720,
      label: "Sheep (Purple) S0720",
    },
    {
      value: DeclarationTypes.G0720,
      label: "Goat (Red) S0720",
    },
  ],
};
