import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Badge from "components/Badge";
import { Row } from "components/Layout";

import { getSoldSaleLotQuantity } from "lib/auctionPens";

const SingleBadge = styled(Badge)`
  height: 24px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  :not(:first-child) {
    margin-left: 12px;
  }
`;

const ProgressBadge = styled(SingleBadge)`
  ${({ progress }) => `
    background-color: #58c1ac;
    background-image: -webkit-linear-gradient(330deg, #58c1ac ${progress}%, #c2c2c2 ${progress}%);
   `};
`;

const StatusBadge = ({
  status,
  saleLots,
  selectedSaleLotQuantity,
  salesOrderByStatus,
}) => {
  // show the badge "unsold" only if there is no sale
  const displayUnsold =
    !salesOrderByStatus.SOLD &&
    salesOrderByStatus.UNSOLD &&
    salesOrderByStatus.UNSOLD.length > 0;
  const setPercentProgress = progress => {
    if (progress === 100) {
      return 100;
    } else if (progress >= 65) {
      return 75;
    } else if (progress >= 33) {
      return 50;
    } else if (progress > 0) {
      return 25;
    }
  };
  let style = { opacity: 0.5 };
  let color = "gray40";

  if (status === "SOLD") {
    color = "success";
    style = {};
  } else if (status === "DELIVERED") {
    color = "ready";
    style = {};
  } else if (status === "SAVING") {
    color = "ready";
    style = {};
  } else if (status === "NO SALE") {
    color = "error";
    style = {};
  } else if (status === "UNSOLD") {
    color = "gray40";
    style = {};
  }

  if (status === "SOLD") {
    const totalSold = getSoldSaleLotQuantity(saleLots);
    const progress = (totalSold * 100) / selectedSaleLotQuantity;
    return (
      <ProgressBadge
        key={`badge-${status}`}
        color={color}
        uppercase
        progress={setPercentProgress(progress)}
      >
        {status}
      </ProgressBadge>
    );
  } else if ((status === "UNSOLD" && displayUnsold) || status !== "UNSOLD") {
    return (
      <SingleBadge
        key={`badge-${status}`}
        color={color}
        style={style}
        uppercase
      >
        {status}
      </SingleBadge>
    );
  } else {
    return null;
  }
};

const BadgeList = ({
  salesOrderByStatus,
  auctionPen,
  selectedSaleLotQuantity,
}) => (
  <Row>
    {Object.keys(salesOrderByStatus)
      .sort()
      .map((saleStatus, index) => {
        if (salesOrderByStatus[saleStatus].length > 0) {
          return (
            <StatusBadge
              key={`badge-${auctionPen.id}-${index}`}
              status={saleStatus}
              saleLots={auctionPen.sale_lots}
              selectedSaleLotQuantity={selectedSaleLotQuantity}
              salesOrderByStatus={salesOrderByStatus}
            />
          );
        }
        return null;
      })}
  </Row>
);

BadgeList.propTypes = {
  salesOrderByStatus: PropTypes.object,
  auctionPen: PropTypes.object,
  selectedSaleLotQuantity: PropTypes.number,
};

export default BadgeList;
