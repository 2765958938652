import React from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link, Route, Switch } from "react-router-dom";
import styled from "styled-components/macro";

import {
  confirmEraseSavedScans,
  confirmResetDevice,
  confirmSetDeviceTime,
  disconnectFromDevice,
  sellPossession,
  uploadNLISIdToSale,
} from "actions";

import { NLISInput } from "components/Input";
import { Column } from "components/Layout";
import { AuctionPenListItem } from "components/TakeBobbyCalf";

import { PenTypes } from "constants/auctionPens";
import { TRANSFER_STATUS } from "constants/nlis";
import { SaleRoundName } from "constants/sale";
import { ScanningMode } from "constants/scanner";

import AnimalModal from "containers/AnimalModal";
import BobbyCalfSaleLot from "containers/SaleLot/BobbyCalfSaleLot";

import {
  openScanModal,
  openAnimalModal,
  openNLISTransferReceipt,
} from "lib/navigation";
import { saleIdOnly } from "lib/sale";
import { getScansForSaleLots } from "lib/saleLot";

import {
  getAuctionPensByRound,
  currentSaleSelector,
  selectScansBySaleLotIdLookup,
  getNestedConsignments,
  getSaleRoundIdByName,
  getScansByNLISId,
  getConnectedDeviceId,
} from "selectors";

const Sell = ({
  history,
  match,
  auctionPens,
  scans,
  connectedDeviceId,
  disconnectFromDevice,
  sale,
  sellPosession,
  consignments,
  saleRoundId,
  scansByNLISId,
  uploadNLISIdToSale,
  confirmEraseSavedScans,
  confirmSetDeviceTime,
  confirmResetDevice,
}) => {
  const handleDisconnectClick = () => {
    disconnectFromDevice(connectedDeviceId);
  };

  const handleEditClick = id => {
    history.push(`${match.url}/salelot/edit/${id}`);
  };

  const handleViewClick = nlisFile => {
    const { id, transferType } = nlisFile;
    openNLISTransferReceipt(id, transferType);
  };

  const handleSellClick = (sale, saleLotIds, auctionPenId) => {
    sellPosession(sale, saleLotIds, auctionPenId);
  };

  const handleFindNLIS = NLISID => {
    if (NLISID in scansByNLISId) {
      // Open the edit screen.
      openAnimalModal(match.url, scansByNLISId[NLISID].EID);
    } else {
      const scanArr = [
        {
          created: new Date().toISOString(),
          NLISID,
        },
      ];
      uploadNLISIdToSale(scanArr, sale);
    }
  };

  const handleScannerImport = () => {
    openScanModal(
      null,
      null,
      false,
      ScanningMode.DEVICE_IMPORT,
      PenTypes.SELLING,
      match.url,
    );
  };

  const isSellEnabled = (auctionPen, animals) =>
    auctionPen.saleLots.every(
      saleLot => saleLot.nlisTakeFileStatus === TRANSFER_STATUS.SUCCESS,
    ) && animals.length > 0;

  return (
    <>
      <Wrapper id="sell-screen">
        <PaddedContainer>
          <NLISInput
            handleDisconnectClick={handleDisconnectClick}
            connectedMessage="Scan now to select an animal"
            disconnectedMessage="Connect to scanner to select an animal"
            handleFindClick={handleFindNLIS}
            handleEraseSavedScans={confirmEraseSavedScans}
            handleUpdateDeviceTime={confirmSetDeviceTime}
            handleResetDevice={confirmResetDevice}
            handleScannerImport={handleScannerImport}
          />
        </PaddedContainer>
        {auctionPens.has(saleRoundId.toString()) ? (
          <AuctionPensWrapper>
            <PaddedContainer>
              <StyledHeading>Sale Lots</StyledHeading>
            </PaddedContainer>
            {auctionPens.get(saleRoundId.toString()).map(ap => {
              const animals = getScansForSaleLots(ap.saleLots, scans) || [];
              const headcount = animals.length;
              const isSellable = isSellEnabled(ap, animals);
              const saleLotIds = ap.saleLots
                .filter(s => s.scans.length !== 0) // We only want to submit lots that actually have animals.
                .map(s => s.id);
              return (
                <AuctionPenListItemStyled
                  key={ap.auctionPenId}
                  animals={animals}
                  handleEditClick={() => handleEditClick(ap.firstSaleLotId)}
                  handleViewClick={() => handleViewClick(ap.sellFile)}
                  handleSellClick={() =>
                    handleSellClick(sale, saleLotIds, ap.auctionPenId)
                  }
                  headcount={headcount}
                  consignments={consignments}
                  isSellable={isSellable}
                  {...ap}
                />
              );
            })}
          </AuctionPensWrapper>
        ) : (
          <EmptyMessage>Or create a consignment to get started.</EmptyMessage>
        )}
      </Wrapper>
      <AddButton data-tour="addSaleLot" to={`${match.url}/salelot/create`}>
        <StyledIcon icon={faPlus} /> Add Sale Lot
      </AddButton>
      <Switch>
        <Route
          path={`${match.path}/salelot/create`}
          component={BobbyCalfSaleLot}
        />
        <Route
          path={`${match.path}/salelot/edit/:saleLotId`}
          component={BobbyCalfSaleLot}
        />
        <Route path={`${match.path}/animal/:EID`} component={AnimalModal} />
      </Switch>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  connectedDeviceId: getConnectedDeviceId(state),
  auctionPens: getAuctionPensByRound(state, saleIdOnly(props)),
  scans: selectScansBySaleLotIdLookup(state, saleIdOnly(props)),
  sale: currentSaleSelector(state, saleIdOnly(props)),
  consignments: getNestedConsignments(state, saleIdOnly(props)),
  saleRoundId: getSaleRoundIdByName(state, SaleRoundName.BobbyCalf),
  scansByNLISId: getScansByNLISId(state, saleIdOnly(props)),
});

const mapDispatchToProps = {
  disconnectFromDevice,
  sellPosession: sellPossession,
  uploadNLISIdToSale,
  confirmEraseSavedScans,
  confirmSetDeviceTime,
  confirmResetDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sell));

Sell.propTypes = {
  scannerCapabilities: PropTypes.object,
  auctionPens: PropTypes.object,
  scans: PropTypes.object,
  saleRoundId: PropTypes.number,
};

const Wrapper = styled(Column).attrs({
  flexGrow: true,
})`
  padding: ${({ theme }) => theme.space[2]}px 0
    ${({ theme }) => theme.space[5]}px;
`;

const PaddedContainer = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]}px;
`;

const AddButton = styled(Link)`
  position: sticky;
  bottom: ${({ theme }) => theme.space[2]}px;
  left: ${({ theme }) => theme.space[2]}px;
  text-decoration: none;
  height: 54px;
  width: 191px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 27px;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.4);
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.beta + 2}px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme }) => theme.space[1]}px;
`;

const StyledHeading = styled.h2`
  margin: 0;
`;

const AuctionPensWrapper = styled(Column).attrs({})`
  margin-top: ${({ theme }) => theme.space[3]}px;
`;

const AuctionPenListItemStyled = styled(AuctionPenListItem)`
  margin-top: ${({ theme }) => theme.space[2]}px;
`;

const EmptyMessage = styled.div`
  margin-top: ${({ theme }) => theme.space[5]}px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  flex: 1;
`;
