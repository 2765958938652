import queryString from "query-string";

import { DEPLOYMENT } from "constants/actionTypes";

import { serializeDeployment } from "lib/serializers/deployment";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/deployments/", id, action),
    query: {
      changesSince,
    },
  });

export const DeploymentAction = offlineActionCreator(
  DEPLOYMENT,
  serializeDeployment,
  urlCreator,
  false,
);
