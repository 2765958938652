import React from "react";

import { get } from "lodash";
import styled from "styled-components";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  LedgerAccountPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import { nullableBooleanRenderer } from "lib/agGrid/renderers/tickRenderer";
import { openModalLink } from "lib/navigation";

import { getLedgerAccountById } from "selectors";

import { useHasPermission, useHasSaleyardOrDeploymentPermission } from "hooks";
import XeroLogo from "img/Xero";

export const LedgerAccountColId = {
  CODE: "24b90533-d6da-4cd1-9826-6f5b8430a118",
  MASTER_LEDGER_ACCOUNT_CODES: "d3eb1f61-3603-4aa6-8260-92de1951b665",
  NAME: "6e171522-059f-4657-b320-960aa74c5ebf",
  DESCRIPTION: "da471b4c-00a1-4c6e-9bce-268875a25cb5",
  ACCOUNT_TYPE: "9033510b-a0b8-4914-a6a4-70a8cfe90267",
  STATUS: "c975ea07-2eeb-4296-8708-7513110dbe31",
  XERO_ACCOUNT: "888bd463-5aa5-4cbf-9fe9-33700c70af19",
  IN_USE: "f68a447b-3a67-44b8-87a3-d7679f1fd319",
  ACTIONS: "4e3e7d2a-5f7c-4cbf-9f4d-47e2ffe3da6a",
};

const Actions = ({ data, value }) => {
  const hasCreatePermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.canCreateLedgerAccount,
    SaleyardPermissions.canCreateLedgerAccount,
  );

  const hasEditPermission = useHasPermission(
    getLedgerAccountById(value),
    LedgerAccountPermissions.update,
  );

  if (!data) {
    return null;
  }

  const onClickEdit = () =>
    openModalLink(ModalTypes.EditLedgerAccount, { id: value });

  const onClickCreate = () => {
    if (data.masterLedgerAccountCodes?.length === 1) {
      openModalLink(ModalTypes.EditLedgerAccount, {
        ...data,
        id: undefined,
        code: data.masterLedgerAccountCodes[0],
        masterLedgerAccountIds: data.masterLedgerAccountIds,
      });
    } else {
      openModalLink(ModalTypes.EditLedgerAccount, { code: data.code });
    }
  };

  return (
    <ActionButtonContainer>
      {value ? (
        <ActionButton onClick={onClickEdit} disabled={!hasEditPermission}>
          Edit
        </ActionButton>
      ) : (
        <ActionButton onClick={onClickCreate} disabled={!hasCreatePermission}>
          Create
        </ActionButton>
      )}
    </ActionButtonContainer>
  );
};

const GridIconContainer = styled.div`
  svg {
    display: block;
    width: 25px;
  }

  height: 25px;
  display: flex;
  align-items: center;
`;

const InXero = ({ data, colDef }) => {
  if (!data) {
    return null;
  }

  if (get(data, colDef.field)) {
    return (
      <GridIconContainer>
        <XeroLogo />
      </GridIconContainer>
    );
  }
};

export const LedgerAccountColumnDef = {
  [LedgerAccountColId.CODE]: {
    colId: LedgerAccountColId.CODE,
    field: "code",
    headerName: "My Code",
  },
  [LedgerAccountColId.MASTER_LEDGER_ACCOUNT_CODES]: {
    colId: LedgerAccountColId.MASTER_LEDGER_ACCOUNT_CODES,
    field: "masterLedgerAccountCodes",
    headerName: "AgriNous Code",
  },
  [LedgerAccountColId.NAME]: {
    colId: LedgerAccountColId.NAME,
    field: "name",
    headerName: "Name",
  },
  [LedgerAccountColId.DESCRIPTION]: {
    colId: LedgerAccountColId.DESCRIPTION,
    field: "description",
    headerName: "Description",
  },
  [LedgerAccountColId.ACCOUNT_TYPE]: {
    colId: LedgerAccountColId.ACCOUNT_TYPE,
    field: "accountType",
    headerName: "Account Type",
  },
  [LedgerAccountColId.STATUS]: {
    colId: LedgerAccountColId.STATUS,
    field: "status",
    headerName: "Status",
  },
  [LedgerAccountColId.XERO_ACCOUNT]: {
    colId: LedgerAccountColId.XERO_ACCOUNT,
    field: "xeroAccountId",
    headerName: "In Xero",
    cellRenderer: InXero,
  },
  [LedgerAccountColId.IN_USE]: {
    colId: LedgerAccountColId.IN_USE,
    field: "inUse",
    headerName: "In Use",
    cellRenderer: nullableBooleanRenderer,
  },
  [LedgerAccountColId.ACTIONS]: {
    colId: LedgerAccountColId.ACTIONS,
    headerName: ACTION_COLUMN_NAME,
    field: "id",
    cellRenderer: Actions,
    pinned: "right",
    width: 80,
  },
};
