import React from "react";

import { Badge as MuiBadge } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";

import { AdvancedDraftingValidationStates } from "constants/draftingAttributes";
import { ExportSiteHeaders, ExportSiteIcons } from "constants/exportSites";

import { advancedDraftingValidation } from "lib/advancedDraftingValidation";

import {
  getConsignments,
  getIntegrationErrorsByAuctionPenId,
  getIntegrationErrorsOrStatusBySaleLotId,
  getNotAngusVerifiedCountByPenId,
  getRounds,
  selectCurrentDeploymentSales,
} from "selectors";

import AngusAustraliaLogo from "img/AngusAustralia";

const IndicatorWarningBadge = styled(MuiBadge)`
  margin-top: 30px;
  margin-right: 30px;
`;

const WarningBadge = ({ children, badgeContent, color }) => {
  return (
    <IndicatorWarningBadge badgeContent={badgeContent} color={color || "error"}>
      {children}
    </IndicatorWarningBadge>
  );
};

const AuctionPenIntegrationIcon = React.memo(({ auctionPenId }) => {
  const exportSiteStatus = useSelector(
    getIntegrationErrorsByAuctionPenId(auctionPenId),
  );

  const notAngusVerifiedCount = useSelector(
    getNotAngusVerifiedCountByPenId(auctionPenId),
  );

  if (
    Object.keys(exportSiteStatus).length === 0 &&
    notAngusVerifiedCount === null
  ) {
    return null;
  } else {
    return (
      <Row>
        {typeof notAngusVerifiedCount === "number" &&
        notAngusVerifiedCount === 0 ? (
          <AngusAustraliaLogo />
        ) : null}
        {Object.keys(exportSiteStatus).map(exportSite => {
          const Icon = ExportSiteIcons[exportSite];
          if (Icon) {
            const { disabledCount, status } = exportSiteStatus[exportSite];
            return (
              <WarningBadge key={exportSite} badgeContent={disabledCount}>
                <Icon
                  color={status}
                  disabled={
                    status === AdvancedDraftingValidationStates.DISABLED
                  }
                />
              </WarningBadge>
            );
          } else {
            return null;
          }
        })}
      </Row>
    );
  }
});

function ExportSiteToolTip({ statusOrErrors, exportSite }) {
  if (typeof statusOrErrors === "object") {
    return (
      <span>
        {Object.values(statusOrErrors).map((error, idx) => (
          <li key={`error-${idx}`}>{error}</li>
        ))}
      </span>
    );
  } else if (statusOrErrors === AdvancedDraftingValidationStates.DISABLED) {
    return `${ExportSiteHeaders[exportSite]} disabled`;
  }
  return `${ExportSiteHeaders[exportSite]} compliant`;
}

const SaleLotIntegrationIcon = React.memo(({ exportSiteErrors }) => {
  if (Object.keys(exportSiteErrors).length === 0) {
    return null;
  } else {
    return (
      <Row>
        {Object.keys(exportSiteErrors).map(exportSite => {
          const Icon = ExportSiteIcons[exportSite];
          if (Icon) {
            const statusOrErrors = exportSiteErrors[exportSite];
            const hasErrors = typeof statusOrErrors === "object";

            const title = (
              <ExportSiteToolTip
                statusOrErrors={statusOrErrors}
                exportSite={exportSite}
              />
            );
            return (
              <Tooltip title={title}>
                <Icon
                  key={exportSite}
                  color={hasErrors ? "warning" : statusOrErrors}
                  disabled={
                    statusOrErrors === AdvancedDraftingValidationStates.DISABLED
                  }
                />
              </Tooltip>
            );
          } else {
            return null;
          }
        })}
      </Row>
    );
  }
});

const SaleLotByIdIntegrationIcon = React.memo(({ saleLotId }) => {
  const exportSiteErrors = useSelector(
    getIntegrationErrorsOrStatusBySaleLotId(saleLotId),
  );
  return <SaleLotIntegrationIcon exportSiteErrors={exportSiteErrors} />;
});

const SaleLotByValuesIntegrationIcon = React.memo(({ saleLotValues }) => {
  const currentDeploymentSales = useSelector(selectCurrentDeploymentSales);
  const consignments = useSelector(getConsignments);
  const rounds = useSelector(getRounds);

  const exportSiteErrors = advancedDraftingValidation(
    saleLotValues,
    currentDeploymentSales,
    consignments,
    rounds,
  );

  return <SaleLotIntegrationIcon exportSiteErrors={exportSiteErrors} />;
});

export const IntegrationIcon = React.memo(
  ({ auctionPenId, saleLotId, saleLotValues }) => {
    if (auctionPenId) {
      return <AuctionPenIntegrationIcon auctionPenId={auctionPenId} />;
    }
    if (saleLotId) {
      return <SaleLotByIdIntegrationIcon saleLotId={saleLotId} />;
    }
    if (saleLotValues) {
      return <SaleLotByValuesIntegrationIcon saleLotValues={saleLotValues} />;
    }
  },
);

export default IntegrationIcon;
