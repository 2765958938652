import React, { useMemo } from "react";

import { DialogActions } from "@material-ui/core";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { patchDeploymentSale } from "actions/offline/deploymentSale";

import { DeploymentSaleInterfaceOptionsForm } from "components/DeploymentSaleOptions/DeploymentSaleInterfaceOptionsForm";
import { Button, SecondaryButton } from "components/Form";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { ApiModel } from "constants/loading";

import {
  closeAllHashModals,
  getLivestockSaleId,
  returnToLocation,
} from "lib/navigation";

import { selectCurrentDeploymentSales } from "selectors";

export default function DeploymentSaleOptionsModal({ returnTo }) {
  const onClose = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const deploymentSales = useSelector(selectCurrentDeploymentSales);
  const deploymentIds = useMemo(
    () =>
      Object.values(deploymentSales).map(
        deploymentSale => deploymentSale.deployment_id,
      ),
    [deploymentSales],
  );

  const dispatch = useDispatch();
  const handleSubmit = values => {
    values.deployment_sales.forEach(deploymentSaleValues => {
      const { updateExistingSaleLotExportSites, ...deploymentSale } =
        deploymentSaleValues;
      dispatch(
        patchDeploymentSale(
          deploymentSale,
          deploymentSale.deployment_sale_id, // Pull from the object to ensure type-match (integer vs string in Object.keys)
          getLivestockSaleId(),
          { updateExistingSaleLotExportSites },
        ),
      );
    });
    onClose();
  };

  const initialValues = { deployment_sales: Object.values(deploymentSales) };

  // Note this wraps the whole dialog in the formik context - it may cause an
  // unnecessary render if the deployment sales change underneath... They should
  // probably be dumped out from the whole modal if the sale changes anyway.
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ handleSubmit, isValid, isDirty }) => (
        <ZoomyDialog open onClose={onClose} maxWidth="md" fullWidth>
          <DialogTitle onClose={onClose}>
            Update Deployment Sale Options
          </DialogTitle>
          <WaitForSync
            requiredData={[
              ApiModel.DEPLOYMENTS,
              ApiModel.AGENCIES,
              ApiModel.SALES,
            ]}
          >
            <DialogContent dividers>
              <DeploymentSaleInterfaceOptionsForm
                namespace="deployment_sales"
                deploymentIds={deploymentIds}
              />
            </DialogContent>
            <DialogActions>
              <SecondaryButton type="button" onClick={onClose}>
                Cancel
              </SecondaryButton>
              <Button
                data-tour="submit"
                type="submit"
                onClick={handleSubmit}
                disabled={!isDirty && !isValid}
              >
                Submit
              </Button>
            </DialogActions>
          </WaitForSync>
        </ZoomyDialog>
      )}
    </Formik>
  );
}
