import React from "react";

import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";

import { IconButton } from "components/Form/Button";
import IdConnectedList from "components/IdConnectedList";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";

import { ApiModel } from "constants/loading";

import { caseInsensitiveCompare } from "lib/compare";
import { openEditConsignmentModal } from "lib/navigation";

import { getBusinesses, getConsignments } from "selectors";

import { useHasAddConsignmentPermission } from "hooks/useHasPermission";

import { VendorCard } from "./VendorCard";

function EmptyVendors() {
  return (
    <MessageBox>No Vendors yet. Click Add Vendor to get started.</MessageBox>
  );
}

function EmptyVendorsNoPermissions() {
  return <MessageBox>No Vendors yet.</MessageBox>;
}

export const Vendors = React.memo(() => {
  const consignments = useSelector(getConsignments);
  const businesses = useSelector(getBusinesses);
  const consignmentIds = Object.values(consignments)
    .sort((a, b) =>
      caseInsensitiveCompare(
        businesses[a.vendor_id]?.name,
        businesses[b.vendor_id]?.name,
      ),
    )
    .map(consignment => consignment.id);

  const hasAddConsignmentPermission = useHasAddConsignmentPermission();
  return (
    <WaitForSync
      requiredData={[
        ApiModel.SALE_LOTS,
        ApiModel.CONSIGNMENTS,
        ApiModel.BUSINESSES,
      ]}
    >
      <div>
        <Row padding={1}>
          <IconButton
            data-tour="addVendor"
            onClick={() => openEditConsignmentModal()}
            disabled={!hasAddConsignmentPermission}
            icon={!hasAddConsignmentPermission ? faLock : undefined}
          >
            Add Vendor
          </IconButton>
        </Row>
        <IdConnectedList
          component={VendorCard}
          orderedIds={consignmentIds}
          emptyComponent={
            hasAddConsignmentPermission
              ? EmptyVendors
              : EmptyVendorsNoPermissions
          }
        />
      </div>
    </WaitForSync>
  );
});
