import React from "react";

import { DeliveryPenOwnersModal } from ".";

function DeliveryPenOwnersModalAdapter(props) {
  const { hash } = props;
  const { returnTo, deliveryPenId } = hash;

  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <DeliveryPenOwnersModal
      returnTo={decodedReturnTo}
      deliveryPenId={deliveryPenId}
    />
  );
}

export default DeliveryPenOwnersModalAdapter;
