import { put, takeEvery } from "redux-saga/effects";

import {
  updateDeclarationSuccess,
  updateDeclarationError,
  setAdditionalPICsSuccess,
} from "actions";

import { UPDATE_DECLARATION, SET_ADDITIONAL_PICS } from "constants/actionTypes";

import toast from "lib/toast";

import { api } from "./api";

function* updateDeclaration(action) {
  try {
    const updatePromise = yield api.put(
      `/v2/consignments/${action.consignmentId}/nvd/`,
      action.declaration,
    );
    yield put(
      updateDeclarationSuccess(action.consignmentId, yield updatePromise),
    );
    // toast.success("Declaration Saved.");
  } catch (e) {
    yield put(updateDeclarationError(e.statusText));
    toast.error("Error saving NVD!");
  }
}

function* setAdditionalPICs(action) {
  try {
    const url = `/v2/consignments/${action.consignmentId}/additional-properties/`;
    // Filter out any empty/null/undefined PICS
    const pics = action.pics.filter(Boolean);
    const picsPromise = yield api.put(url, pics);

    yield put(
      setAdditionalPICsSuccess(action.consignmentId, yield picsPromise),
    );
  } catch (e) {
    toast.error("Error updating PIC's!");
  }
}

function* rootSaga() {
  yield takeEvery(UPDATE_DECLARATION, updateDeclaration);
  yield takeEvery(SET_ADDITIONAL_PICS, setAdditionalPICs);
}

export default rootSaga;
