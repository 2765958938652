import React from "react";

import {
  faExclamationTriangle,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";

import { fetchGeoData, SaleAction, setGeoBlock } from "actions";

import { IconButton } from "components/Form";
import { MediumText } from "components/Text";

import { GeoBlockReasons } from "constants/auth";

import { getGeoBlock } from "selectors";

import { useTheme } from "hooks";

const GeoBlockNotification = () => {
  const geoBlock = useSelector(getGeoBlock);

  const dispatch = useDispatch();
  const theme = useTheme();

  const recheckGeoBlock = () => {
    dispatch(setGeoBlock(null));
    dispatch(fetchGeoData());
    dispatch(SaleAction.request());
  };

  if (!geoBlock) {
    return null;
  }

  return (
    <Box m={3}>
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item xs={3} container alignItems="center" justifyContent="center">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="3x"
            color={theme.colors.warning}
          />
        </Grid>
        <Grid item xs={9}>
          {geoBlock.error === GeoBlockReasons.OUTSIDE_RESTRICTION ? (
            <MediumText>
              Your access has been limited due to your current location.
            </MediumText>
          ) : null}
          {geoBlock.error === GeoBlockReasons.NO_LOCATION_DATA ? (
            <MediumText>
              Your access has been limited due to your device not sending
              location data.
            </MediumText>
          ) : null}
          {geoBlock.error === GeoBlockReasons.INVALID_LOCATION_DATA ? (
            <MediumText>
              Your access has been limited due to your device sending invalid
              location data.
            </MediumText>
          ) : null}
          {geoBlock.error === GeoBlockReasons.LOCATION_NOT_ENABLED ? (
            <MediumText>
              Your access has been limited due to your device blocking location
              data requests.
            </MediumText>
          ) : null}
          {geoBlock.allowableZones ? (
            <div>
              You should move back into one of the following locations:
              <ul>
                {geoBlock.allowableZones.map(zone => (
                  <li key={zone}>{zone}</li>
                ))}
              </ul>
              Or contact AgriNous support to upgrade your account.
            </div>
          ) : null}
        </Grid>

        <Grid
          item
          xs={8}
          md={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          <IconButton icon={faSync} onClick={recheckGeoBlock}>
            Check Location Now
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeoBlockNotification;
