export const PenTypes = {
  AUTO_DRAFT: "AUTO_DRAFT",
  CRUSH: "CRUSH",
  DELIVERY: "DELIVERY",
  DRAFT: "DRAFT",
  HOLDING: "HOLDING",
  LANE: "LANE",
  NOT_IN_SALE: "NOT_IN_SALE",
  RECEIVING: "RECEIVING",
  SELLING: "SELLING",
  TEMP: "TEMPORARY_PEN",
};

export const PenTypesWithRounds = [PenTypes.SELLING, PenTypes.TEMP];

export const SaleLotPenTypeMap = {
  auction_pen: PenTypes.SELLING,
  deliveryPen: PenTypes.DELIVERY,
};
export const SingleWeighPenTypes = [PenTypes.CRUSH, PenTypes.AUTO_DRAFT];

export const GateStatus = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  LOCKED: "LOCKED",
};

export const ScanLotStatus = {
  ALL_SCANNED: "ALL_SCANNED", // Scanner, you've done your job.
  NONE_SCANNED: "NONE_SCANNED", // nothing to do here.
  PARTIALLY_SCANNED: "PARTIALLY_SCANNED", // Scanner, there's half scanned lots here
};

export const ScanningPenStatus = {
  ALL_SCANNED_WITH_EMPTY: "ALL_SCANNED_WITH_EMPTY", // Scanner, there's missed lots/pens here.
  ALL_SCANNED: "ALL_SCANNED", // Scanner, you've done your job.
  NONE_SCANNED: "NONE_SCANNED", // Pretty boring, nothing to do here.
  PARTIALLY_SCANNED: "PARTIALLY_SCANNED", // Scanner, there's half scanned lots here
};

export const ScanningPenStatusColourMap = {
  [ScanningPenStatus.ALL_SCANNED_WITH_EMPTY]: "separatorBlue",
  [ScanningPenStatus.ALL_SCANNED]: "successGreen",
  [ScanningPenStatus.NONE_SCANNED]: "noProgress",
  [ScanningPenStatus.PARTIALLY_SCANNED]: "red",
};

export const ScanLotStatusColourMap = {
  [ScanLotStatus.ALL_SCANNED]: "successGreen",
  [ScanLotStatus.NONE_SCANNED]: "noProgress",
  [ScanLotStatus.PARTIALLY_SCANNED]: "red",
};

export const PenScanningGroupingType = {
  LANE: "LANE",
  PREFIX: "PREFIX",
};

export const MAX_PEN_RANGE = 100;
