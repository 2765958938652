import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { ActionButton } from "components/AgGrid/ActionButton";
import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

import { SaleLotModalSection } from "constants/navigation";
import { containsOnlyGoodStatuses } from "constants/nlis";

import { openEditSaleLotModal } from "lib/navigation";

export function erpColumnRenderer({ value, data }) {
  if (!data) {
    return null;
  }
  const erpCheck = data.erpMessages
    ? !containsOnlyGoodStatuses(data.erpMessages.filter(Boolean))
    : false;

  return (
    <div>
      {erpCheck && (
        <ActionButton
          data-tour="erp-edit-lot"
          onClick={() =>
            openEditSaleLotModal(data.id, SaleLotModalSection.EIDS)
          }
        >
          <FaIcon icon={faExclamationCircle} color="warning" />
        </ActionButton>
      )}{" "}
      {value}
    </div>
  );
}
