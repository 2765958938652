import groupBy from "lodash/groupBy";
import partition from "lodash/partition";
import { call, debounce, put, select, takeEvery } from "redux-saga/effects";

import {
  createManualSellPossessions,
  createManualTakePossessions,
  P2PFileAction,
  receiveAllSellFiles,
  receiveSellFile,
  requestAllSellFilesFailed,
  requestAllSellReversalsFailure,
  requestAllSellReversalsSuccess,
  requestAllTakeReversalsFailure,
  requestAllTakeReversalsSuccess,
  requestAllTakeStatusesError,
  requestAllTakeStatusesSuccess,
  requestScansChanges,
  requestSellFileFailed,
  requestSellReversalError,
  requestSellReversalSuccess,
  requestTakeReversalError,
  requestTakeReversalSuccess,
  requestTakeStatusError,
  requestTakeStatusSuccess,
  sellPossessionByBuyer,
  takeConsignmentsIndividually,
} from "actions";

import {
  P2P_FILE,
  REFRESH_SELL_FILE,
  REFRESH_SELL_REVERSAL,
  REFRESH_TAKE_FILE,
  REFRESH_TAKE_REVERSAL,
  SELL_FILE,
  SELL_REVERSAL,
  SYNC_NLIS,
  TAKE_FILE,
  TAKE_REVERSAL,
} from "constants/actionTypes";

import { getSaleUrl } from "lib/navigation";
import toast from "lib/toast";

import {
  getConsignmentById,
  getConsignmentIdBySaleLotId,
  getCurrentSale,
  getIsFetchingNLISInformation,
  getPropertyById,
  getSaleLotById,
  getScanByEid,
  selectConsignmentIdByEidLookup,
  selectSaleLotIdByEidLookup,
} from "selectors";

import { api } from "./api";
import { fetchCurrentSaleScansChanges } from "./scans";

function* getAllTakeStatuses(action) {
  const saleUrl = getSaleUrl(action.sale);
  try {
    const promise = yield call(api.get, `${saleUrl}/take-possession/`);
    yield put(requestAllTakeStatusesSuccess(yield promise));
  } catch (e) {
    yield put(requestAllTakeStatusesError(e.statusText));
    toast.error(`Could not fetch NLIS Take submission statuses`);
  }
}

function* refreshTakeFile(action) {
  const { takeFileId, sale } = action;
  const saleUrl = getSaleUrl(sale);
  try {
    const promise = yield call(
      api.get,
      `${saleUrl}/take-possession/${takeFileId}/`,
    );
    yield put(requestTakeStatusSuccess(yield promise));
  } catch (e) {
    yield put(requestTakeStatusError(e.statusText));
    toast.error(`Could not fetch NLIS Take submission statuses`);
  }
}

function* getAllTakeReversals(action) {
  const saleUrl = getSaleUrl(action.sale);
  try {
    const promise = yield call(api.get, `${saleUrl}/reverse-take-possession/`);
    yield put(requestAllTakeReversalsSuccess(yield promise));
  } catch (e) {
    yield put(requestAllTakeReversalsFailure(e.statusText));
    toast.error(`Could not fetch NLIS Take Reversal statuses`);
  }
}

function* refreshTakeReversal(action) {
  const { takeReversalId, sale } = action;
  const saleUrl = getSaleUrl(sale);
  try {
    const promise = yield call(
      api.get,
      `${saleUrl}/reverse-take-possession/${takeReversalId}/`,
    );
    yield put(requestTakeReversalSuccess(yield promise));
  } catch (e) {
    yield put(requestTakeReversalError(e.statusText));
    toast.error(`Could not fetch NLIS Take reversal`);
  }
}

function* getAllSellReversals(action) {
  const saleUrl = getSaleUrl(action.sale);
  try {
    const promise = yield call(api.get, `${saleUrl}/reverse-sell-possession/`);
    yield put(requestAllSellReversalsSuccess(yield promise));
  } catch (e) {
    yield put(requestAllSellReversalsFailure(e.statusText));
    toast.error(`Could not fetch NLIS Sell Reversals`);
  }
}

function* refreshSellReversal(action) {
  const { sellReversalId, sale } = action;
  const saleUrl = getSaleUrl(sale);
  try {
    const promise = yield call(
      api.get,
      `${saleUrl}/reverse-sell-possession/${sellReversalId}/`,
    );
    yield put(requestSellReversalSuccess(yield promise));
  } catch (e) {
    yield put(requestSellReversalError(e.statusText));
    toast.error(`Could not fetch NLIS Sell reversal`);
  }
}

function* requestSellFile(action) {
  const { sale, saleLot } = action;
  const saleUrl = getSaleUrl(sale);

  try {
    const sellFileRequest = yield call(
      api.get,
      `${saleUrl}/sale_lots/${saleLot.id}/sell-possession/`,
    );
    const sellFiles = yield sellFileRequest;

    yield put(receiveSellFile(sellFiles));
  } catch (e) {
    yield put(requestSellFileFailed(e.statusText));
    yield call(api.handleFetchError, e, "NLIS transfer", action);
  }
}

function* requestAllSellFiles(action) {
  const { sale } = action;
  const saleUrl = getSaleUrl(sale);

  try {
    const sellFileRequest = yield call(api.get, `${saleUrl}/sell-possession/`);
    const sellFiles = yield sellFileRequest;
    yield put(receiveAllSellFiles(sellFiles));
  } catch (e) {
    yield put(requestAllSellFilesFailed(e.statusText));
    yield call(api.handleFetchError, e, "NLIS transfer", action);
  }
}

function* refreshSellFile(action) {
  const { sellFileId, sale } = action;
  const saleUrl = getSaleUrl(sale);
  try {
    const promise = yield call(
      api.get,
      `${saleUrl}/sell-possession/${sellFileId}/`,
    );

    yield put(receiveSellFile(yield promise));
  } catch (e) {
    yield put(requestSellFileFailed(e.statusText));
    yield call(api.handleFetchError, e, "NLIS transfer", action);
  }
}

function generateSellTransfer(saleLotId, saleLotEids, state) {
  const saleLot = getSaleLotById(saleLotId)(state);
  const consignment = getConsignmentById(saleLot.consignment_id)(state) || {};
  const destinationProperty = getPropertyById(saleLot.destination_property_id)(
    state,
  ) || { PIC: null }; // for NLIS Takes the Destination Property should be `null`
  const vendorProperty = getPropertyById(consignment.vendor_property_id)(state);

  const transferEids = [];

  for (const eid of saleLotEids) {
    const transferEid = {
      EID: eid,
      response_type: "",
      message_no: null,
      action: null,
    };
    transferEids.push(transferEid);
  }

  return {
    buyer_pic: destinationProperty.PIC,
    eids: transferEids,
    freetext: "",
    NVD: consignment.NVD,
    vendor_pic: vendorProperty.PIC,
  };
}

function generateSellTransfers(saleLotId, eids, state) {
  const transfers = [];
  const [deceasedEids, liveEids] = partition(
    eids,
    eid => getScanByEid(eid)(state)?.animal.is_deceased,
  );
  transfers.push(generateSellTransfer(saleLotId, liveEids, state));

  if (deceasedEids.length > 0) {
    // add an additional transfer for all deceased animals
    const deceasedTransfer = generateSellTransfer(
      saleLotId,
      deceasedEids,
      state,
    );
    deceasedTransfer.buyer_pic = "DECEASED";
    transfers.push(deceasedTransfer);
  }
  return transfers;
}

function* onSellEidsAction(action) {
  const { eids, options } = action;
  const { isManualTransfer = true } = options || {};
  const state = yield select();
  const saleLotIdByEidLookup = selectSaleLotIdByEidLookup(state);

  const sellPossessions = [];

  const [unlottedEids, lottedEids] = partition(
    eids,
    eid => saleLotIdByEidLookup[eid] === "UNALLOCATED",
  );

  const eidsBySaleLotId = groupBy(lottedEids, eid => saleLotIdByEidLookup[eid]);

  for (const [saleLotId, saleLotEids] of Object.entries(eidsBySaleLotId)) {
    const transfers = generateSellTransfers(saleLotId, saleLotEids, state);
    const nlisSellTransfer = {
      asynchronous: true,
      isManualTransfer,
      pending: true,
      saleLots: [saleLotId],
      transfers,
    };
    sellPossessions.push(nlisSellTransfer);
  }
  if (isManualTransfer) {
    yield put(createManualSellPossessions(sellPossessions));
  } else {
    yield put(sellPossessionByBuyer(sellPossessions));
  }

  if (unlottedEids.length > 0) {
    toast.error(`${unlottedEids.length} EIDs were unable to be submitted`);
  }
}

function* onP2PCreateBulkAction(action) {
  const { eids, authority } = action;

  const state = yield select();
  const saleLotIdByEidLookup = selectSaleLotIdByEidLookup(state);

  const p2pFiles = [];

  const [unlottedEids, lottedEids] = partition(
    eids,
    eid => saleLotIdByEidLookup[eid] === "UNALLOCATED",
  );

  const eidsBySaleLotId = groupBy(lottedEids, eid => saleLotIdByEidLookup[eid]);

  for (const [saleLotId, saleLotEids] of Object.entries(eidsBySaleLotId)) {
    const transfers = generateSellTransfers(saleLotId, saleLotEids, state);

    p2pFiles.push({
      asynchronous: true,
      pending: true,
      saleLots: [saleLotId],
      transfers,
      ...authority,
    });
  }

  yield put(P2PFileAction.createBulk(p2pFiles));

  if (unlottedEids.length > 0) {
    toast.error(`${unlottedEids.length} EIDs were unable to be submitted`);
  }
}

function generateTakeTransfer(saleLotId, saleLotEids, state) {
  const consignmentId = getConsignmentIdBySaleLotId(saleLotId)(state);
  const consignment = getConsignmentById(consignmentId)(state) || {};

  const vendorProperty = getPropertyById(consignment.vendor_property_id)(state);

  const transferEids = [];

  for (const eid of saleLotEids) {
    const transferEid = {
      EID: eid,
      response_type: "",
      message_no: null,
      action: null,
    };
    transferEids.push(transferEid);
  }

  return {
    buyer_pic: null,
    eids: transferEids,
    freetext: "",
    NVD: consignment.NVD,
    vendor_pic: vendorProperty.PIC,
  };
}

function generateTakeTransfers(saleLotId, eids, state) {
  return [generateTakeTransfer(saleLotId, eids, state)];
}
function* onTakeEidsAction(action) {
  const { eids, options } = action;
  const { isManualTransfer = true } = options || {};
  const state = yield select();
  const saleLotIdByEidLookup = selectSaleLotIdByEidLookup(state);
  const consignmentIdByEidLookup = selectConsignmentIdByEidLookup(state);

  const takePossessions = [];

  const [unlottedEids, lottedEids] = partition(
    eids,
    eid => saleLotIdByEidLookup[eid] === "UNALLOCATED",
  );

  const saleLotIdEidGroups = [];

  if (isManualTransfer) {
    const eidsBySaleLotId = groupBy(
      lottedEids,
      eid => saleLotIdByEidLookup[eid],
    );
    Object.entries(eidsBySaleLotId).forEach(entry =>
      saleLotIdEidGroups.push(entry),
    );
  } else {
    const eidsByConsignmentId = groupBy(
      lottedEids,
      eid => consignmentIdByEidLookup[eid],
    );
    Object.values(eidsByConsignmentId).forEach(eids =>
      saleLotIdEidGroups.push([saleLotIdByEidLookup[eids[0]], eids]),
    );
  }

  for (const [saleLotId, eids] of saleLotIdEidGroups) {
    const transfers = generateTakeTransfers(saleLotId, eids, state);
    const consignmentId = getConsignmentIdBySaleLotId(saleLotId)(state);

    const nlisTakeTransfer = {
      asynchronous: true,
      isManualTransfer,
      pending: true,
      consignments: [consignmentId],
      transfers,
    };
    takePossessions.push(nlisTakeTransfer);
  }

  if (isManualTransfer) {
    yield put(createManualTakePossessions(takePossessions));
  } else {
    yield put(takeConsignmentsIndividually(takePossessions));
  }

  if (unlottedEids.length > 0) {
    toast.error(`${unlottedEids.length} EIDs were unable to be submitted`);
  }
}
function* nlisSync() {
  const state = yield select();
  const sale = getCurrentSale(state);
  const isFetching = getIsFetchingNLISInformation(state);
  if (!isFetching) {
    yield call(getAllTakeStatuses, { sale });
    yield call(getAllTakeReversals, { sale });
    yield call(requestAllSellFiles, { sale });
    yield call(getAllSellReversals, { sale });
    yield call(getAllTakeReversals, { sale });
    yield put(requestScansChanges(sale));
  }
}

export default function* nlisSaga() {
  yield takeEvery(TAKE_FILE.FETCH_BULK.REQUEST, getAllTakeStatuses);
  // Only grab one every 10ms (a saleyard admin may receive many in a very
  // short period for a single take file).
  yield debounce(10, REFRESH_TAKE_FILE, refreshTakeFile);
  yield debounce(10, REFRESH_SELL_FILE, refreshSellFile);
  yield debounce(10, REFRESH_TAKE_REVERSAL, refreshTakeReversal);
  yield debounce(10, REFRESH_SELL_REVERSAL, refreshSellReversal);

  yield takeEvery(TAKE_REVERSAL.FETCH_BULK.REQUEST, getAllTakeReversals);
  yield takeEvery(SELL_REVERSAL.FETCH_BULK.REQUEST, getAllSellReversals);

  yield takeEvery(SELL_FILE.FETCH.REQUEST, requestSellFile);
  yield takeEvery(SELL_FILE.FETCH_BULK.REQUEST, requestAllSellFiles);

  // Refresh scans after a NLIS Transaction
  yield takeEvery(TAKE_FILE.CREATE.SUCCESS, fetchCurrentSaleScansChanges);

  yield takeEvery(SELL_FILE.CREATE_BULK.ACTION, onSellEidsAction);
  yield takeEvery(TAKE_FILE.CREATE_BULK.ACTION, onTakeEidsAction);
  yield takeEvery(SELL_FILE.AUTO.ACTION, onSellEidsAction);
  yield takeEvery(TAKE_FILE.AUTO.ACTION, onTakeEidsAction);
  yield takeEvery(P2P_FILE.CREATE_BULK.ACTION, onP2PCreateBulkAction);

  yield takeEvery(SYNC_NLIS, nlisSync);
}
