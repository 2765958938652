import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  id: "id",
  mode: "mode",
  name: "name",
  saleyardId: "sale_yard_id",
  speciesId: "species_id",
  penArchetypes: "pen_archetypes",
  noEidDelay: "no_eid_delay",
  unsteadyWeightDelay: "unsteady_weight_delay",
  reweighWarningThreshold: "reweigh_warning_threshold",
  minStableWeightGrams: "min_stable_weight_grams",
  stableWeightSampleThresholdGrams: "stable_weight_sample_threshold_grams",
  stableWeightSampleCount: "stable_weight_sample_count",
  stableWeightDebounce: "stable_weight_debounce",
  isPaused: "is_paused",
};

export const serializeSingleWeigh = buildSerializer(
  serializeMap,
  "singleWeigh",
);

export const deserializeSingleWeigh = buildDeserializer(
  serializeMap,
  "singleWeigh",
);
