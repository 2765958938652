import React from "react";

import { faChevronDoubleUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { colors } from "constants/theme";

import { getAuctionPenRangesByAuctionPenId } from "selectors";

const BreakIcon = styled(FontAwesomeIcon)`
  margin-left: ${({ theme }) => theme.space[2]}px;
`;
const Break = styled.div`
  margin: 0 0 ${({ theme }) => theme.space[2]}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BreakLine = styled.hr`
  border: 1px solid ${({ theme, color }) => color || theme.colors.separatorBlue};
  width: 100%;
  max-width: 800px;
`;
const BreakLabel = styled.div`
  color: ${({ theme, color }) => color || theme.colors.separatorBlue};
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  padding-left: ${({ theme }) => theme.space[2]}px;
  padding-right: ${({ theme }) => theme.space[2]}px;
`;
export const LaneStartLine = React.memo(() => (
  <Break>
    <BreakLine />
    <BreakLabel>Lane Start</BreakLabel>
    <BreakLine />
  </Break>
));

export const PenRangeIndicator = React.memo(({ auctionPenId }) => {
  const { anyOutOfOrder, anyEmpty, selectUnPennedLot } = useSelector(
    getAuctionPenRangesByAuctionPenId(auctionPenId),
  );

  if (!anyOutOfOrder && !anyEmpty) {
    return null;
  }
  return (
    <Break>
      <BreakLine color={colors.inactive} />
      <BreakLabel color={colors.inactive}>
        {anyOutOfOrder && anyEmpty && "Empty Pens and Pens sold out of order."}
        {anyOutOfOrder && !anyEmpty && "Pens sold out of order"}
        {!anyOutOfOrder && anyEmpty && "Empty pens found"}
        {anyEmpty && (
          <BreakIcon icon={faChevronDoubleUp} onClick={selectUnPennedLot} />
        )}
      </BreakLabel>
      <BreakLine color={colors.inactive} />
    </Break>
  );
});
