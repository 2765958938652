import { call, put, select, takeEvery, takeLeading } from "redux-saga/effects";

import {
  receiveProperties,
  receivePropertiesChanges,
  requestPropertiesChanges,
  requestPropertiesError,
  updateProperties,
} from "actions";

import {
  BUSINESS,
  GET_PROPERTIES,
  GET_PROPERTIES_CHANGES,
} from "constants/actionTypes";

import { getPropertyById, selectPropertyIdByPICLookup } from "selectors";

import { api } from "./api";

function* fetchProperties(action) {
  try {
    const propertiesEndpoint = `/v2/properties/`;

    const propertiesResponsePromise = yield call(api.get, propertiesEndpoint);
    const propertiesResponse = yield propertiesResponsePromise;
    const properties = yield propertiesResponse.JSON;
    const { lastModifiedTimestamp, cacheHit } = propertiesResponse;

    yield put(receiveProperties(properties, lastModifiedTimestamp));

    if (cacheHit) {
      yield put(requestPropertiesChanges());
    }
  } catch (e) {
    yield call(api.handleFetchError, e, "properties", action);
    yield put(requestPropertiesError(e.statusText));
  }
}

function* fetchPropertiesChanges(action) {
  try {
    const state = yield select();
    const changesSince = state.properties.lastModifiedTimestamp;
    const propertiesEndpoint = `/v2/properties/?changesSince=${changesSince}`;

    const propertiesResponsePromise = yield call(api.get, propertiesEndpoint);
    const propertiesResponse = yield propertiesResponsePromise;
    const properties = yield propertiesResponse.JSON;
    const { lastModifiedTimestamp } = propertiesResponse;

    yield put(receivePropertiesChanges(properties, lastModifiedTimestamp));
  } catch (e) {
    yield call(api.handleFetchError, e, "properties", action);
    yield put(requestPropertiesError(e.statusText));
  }
}

function* ensureBusinessPropertiesExist(propertyIds) {
  const state = yield select();
  if (!propertyIds.every(propertyId => getPropertyById(propertyId)(state))) {
    yield put(requestPropertiesChanges());
  }
}

function* onBusinessAddedOrChanged(action) {
  const { properties } = action.payload;
  if (properties.length > 0) {
    yield ensureBusinessPropertiesExist(
      properties.map(property => property.id),
    );
  }
}

function* onBusinessesAddedOrChanged(action) {
  const propertyIds = action.payload.reduce((acc, { properties }) => {
    if (Array.isArray(properties) && properties.length > 0) {
      properties.map(property => property.id).forEach(acc.add, acc);
    }
    return acc;
  }, new Set());
  if (propertyIds.size > 0) {
    yield ensureBusinessPropertiesExist(Array.from(propertyIds));
  }
}

function* onPatchBusinessOffline(action) {
  const { properties } = action.payload;
  if (Array.isArray(properties) && properties.length > 0) {
    const state = yield select();
    yield put(
      updateProperties(
        properties.map(deploymentBusinessProperty => ({
          ...deploymentBusinessProperty,
          id: selectPropertyIdByPICLookup(state)[
            deploymentBusinessProperty.PIC
          ],
        })),
      ),
    );
  }
}

function* rootSaga() {
  yield takeEvery(GET_PROPERTIES, fetchProperties);
  yield takeLeading(GET_PROPERTIES_CHANGES, fetchPropertiesChanges);

  yield takeEvery(BUSINESS.UPDATE.REQUEST, onPatchBusinessOffline);

  yield takeEvery(BUSINESS.CREATE.SUCCESS, onBusinessAddedOrChanged);
  yield takeEvery(BUSINESS.UPDATE.SUCCESS, onBusinessAddedOrChanged);

  yield takeEvery(BUSINESS.FETCH_CHANGES.SUCCESS, onBusinessesAddedOrChanged);
  yield takeEvery(BUSINESS.FETCH_BULK.SUCCESS, onBusinessesAddedOrChanged);
}

export default rootSaga;
