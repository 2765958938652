import React from "react";

import { useSelector } from "react-redux";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { Dialog, DialogTitle } from "components/MaterialDialog";

import { AuditLogTypes } from "constants/auditLog";
import { ModalTypes } from "constants/navigation";

import { getContextByModalType, getRuleById } from "selectors";

import { EditCustomRuleForm } from "./CustomRule";
import { CustomiseManagedRuleModalForm } from "./ManagedRule";

export function RuleModal(props) {
  const { onClose } = props;

  const context = useSelector(getContextByModalType(ModalTypes.EditRule)) || {};
  const { ruleId = null, rule: templateRule = null } = context;

  const rule = useSelector(getRuleById(ruleId)) || null;

  const mergedRule = {
    ...rule,
    ...templateRule,
  };

  const ruleBookId = mergedRule.rule_book_id || null;

  const isManagedRule = mergedRule.master_rule_id;

  if (!ruleBookId) {
    return null;
  }

  return (
    <Dialog open onClose={onClose} fullScreen>
      <DialogTitle onClose={onClose}>
        {ruleId && (
          <AuditLogLink
            auditLogType={AuditLogTypes.RULE}
            dataId={ruleId}
            returnTo={window.location.hash}
          />
        )}
        &nbsp;
        {ruleId ? "Edit" : "Create"} Rule
      </DialogTitle>
      {isManagedRule ? (
        <CustomiseManagedRuleModalForm
          ruleId={ruleId}
          ruleBookId={ruleBookId}
          onClose={onClose}
        />
      ) : (
        <EditCustomRuleForm onClose={onClose} ruleBookId={ruleBookId} />
      )}
    </Dialog>
  );
}
