import reduceReducers from "reduce-reducers";

import { LIVESTOCK_AGENT } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
} from "lib/reducers";
import { deserializeLivestockAgent } from "lib/serializers/livestockAgents";

const create = apiModelOfflineCreateReducer(LIVESTOCK_AGENT, {
  deserializer: deserializeLivestockAgent,
});

const fetch = apiModelOfflineFetchReducer(LIVESTOCK_AGENT, {
  deserializer: deserializeLivestockAgent,
  clearOnRequest: true,
});

const update = apiModelOfflineUpdateReducer(LIVESTOCK_AGENT, {
  deserializer: deserializeLivestockAgent,
});

const deleteReducer = apiModelOfflineDeleteReducer(LIVESTOCK_AGENT);

function reinviteReducer(state, action) {
  switch (action.type) {
    case LIVESTOCK_AGENT.REINVITE.SUCCESS: {
      const { payload } = action;
      // Only take most recent request success
      const data = deserializeLivestockAgent(payload);
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: data,
        },
      };
    }
    default:
      return state;
  }
}

const livestockAgentReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
  reinviteReducer,
);

export default livestockAgentReducer;
