export const VendorSplitType = {
  Head: "HEADCOUNT",
  Percentage: "PERCENTAGE",
};

export const VendorSplitTypeOptions = Object.entries(VendorSplitType).map(
  ([label, value]) => ({
    label,
    value,
  }),
);
