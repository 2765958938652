import React from "react";

import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";

import FontAwesomeButton, { ButtonIcon } from "./FontAwesomeButton";

export default ({ onClick, disabled, dataTour = "edit" }) => (
  <FontAwesomeButton
    data-tour={dataTour}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    <ButtonIcon icon={faPencilAlt} />
  </FontAwesomeButton>
);
