import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Input, withNamespace } from "components/Form/FormikControls";
import { PenningMode } from "components/PenningSaleLotForm";

import { Settings } from "constants/settings";

import { getSettings } from "selectors";

function SaleLotOverflowFormComponent(props) {
  const { forceShow, namespace: ns, readOnly } = props;

  const penningModeSetting = useSelector(getSettings)[Settings.penningMode];

  const isVisible =
    forceShow === true || penningModeSetting === PenningMode.ADVANCED;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Grid item sm={6}>
        <Input
          label="Overflow Pen"
          name={withNamespace(ns, "overflowPen")}
          disableAutoComplete
          disabled={readOnly}
        />
      </Grid>
      <Grid item sm={6}>
        <Input
          type="number"
          label="Overflow Head Count"
          name={withNamespace(ns, "overflowQuantity")}
          emptyValue={null}
          disabled={readOnly}
        />
      </Grid>
    </>
  );
}

SaleLotOverflowFormComponent.propTypes = {
  namespace: PropTypes.string,
};

export default memo(SaleLotOverflowFormComponent);
