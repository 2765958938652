import reduceReducers from "reduce-reducers";

import { SAVED_VIEW } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
} from "lib/reducers";
import { deserializeSavedView } from "lib/serializers/savedViews";

const create = apiModelOfflineCreateReducer(SAVED_VIEW, {
  deserializer: deserializeSavedView,
});

const fetch = apiModelOfflineFetchReducer(SAVED_VIEW, {
  deserializer: deserializeSavedView,
  clearOnRequest: true,
});

const update = apiModelOfflineUpdateReducer(SAVED_VIEW, {
  deserializer: deserializeSavedView,
});

const deleteReducer = apiModelOfflineDeleteReducer(SAVED_VIEW);

const reducer = reduceReducers(create, fetch, update, deleteReducer);

export default reducer;
