import {
  API_RESPONSE,
  REQUEST_FAILURE,
  NOTIFY_FETCH_REQUEST_RETRYING,
  NOTIFY_MUTATE_REQUEST_RETRYING,
} from "constants/actionTypes";

export function notifyFetchRequestRetrying(retryCount = 0) {
  return {
    type: NOTIFY_FETCH_REQUEST_RETRYING,
    retryCount,
  };
}

export function notifyMutateRequestRetrying(retryCount = 0) {
  return {
    type: NOTIFY_MUTATE_REQUEST_RETRYING,
    retryCount,
  };
}

export function requestFailure(action) {
  return {
    type: REQUEST_FAILURE,
    action,
  };
}

export function apiResponseGetSuccess(response) {
  return {
    type: API_RESPONSE.FETCH.SUCCESS,
    response,
  };
}

export function apiResponseGetFailure(response, isCatastrophic) {
  return {
    type: API_RESPONSE.FETCH.FAILURE,
    response,
    isCatastrophic,
  };
}

export function apiResponsePatchSuccess(response) {
  return {
    type: API_RESPONSE.UPDATE.SUCCESS,
    response,
  };
}

export function apiResponsePatchFailure(action, error) {
  return {
    type: API_RESPONSE.UPDATE.FAILURE,
    action,
    error,
  };
}
