import * as React from "react";

import { suggestedValueMap } from "components/AttachmentForm/constants";
import { OptionTogglerField } from "components/Form/FormikControls";

export const NVD_OPTIONS = {
  YES: true,
  NO: false,
  NOT_ANSWERED: null,
};

const NVDChoices = [
  {
    value: NVD_OPTIONS.YES,
    label: "Yes",
  },
  {
    value: NVD_OPTIONS.NO,
    label: "No",
  },
  {
    value: NVD_OPTIONS.NOT_ANSWERED,
    label: "Not Answered",
  },
];

export const NVDOptionToggler = React.memo(
  ({ label, name, labelPosition = "left" }) => {
    return (
      <OptionTogglerField
        options={NVDChoices}
        label={label}
        name={name}
        labelPosition={labelPosition}
        suggestedValueFieldName={suggestedValueMap[name]}
      />
    );
  },
);

const DatePurchasedChoices = [
  {
    value: "A",
    label: "< 2",
  },
  {
    value: "B",
    label: "2-6",
  },
  {
    value: "C",
    label: "6-12",
  },
  {
    value: "D",
    label: "> 12",
  },
];

export const DatePurchasedOptionsToggler = ({ label, name, labelPosition }) => (
  <OptionTogglerField
    options={DatePurchasedChoices}
    label={label}
    name={name}
    labelPosition={labelPosition}
    suggestedValueFieldName={suggestedValueMap[name]}
  />
);

const YesNoChoices = [
  {
    value: NVD_OPTIONS.YES,
    label: "Yes",
  },
  {
    value: NVD_OPTIONS.NO,
    label: "No",
  },
];

export const TrueFalseToggler = ({ label, name }) => (
  <OptionTogglerField options={YesNoChoices} label={label} name={name} />
);
