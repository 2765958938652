import styled from "styled-components/macro";

const Badge = styled.span(
  ({
    theme,
    margin,
    textAlign,
    bold,
    uppercase,
    color,
    display,
    partially,
    inline = false,
    width,
  }) => `
  padding: ${inline ? "0px 7px" : "5px 7px"};
  border-radius: 5px;

  font-weight: ${bold ? theme.fontWeights.bold : "inherit"};
  color: ${theme.colors.white};
  ${
    margin
      ? margin === "auto"
        ? "margin: auto;"
        : `margin: ${theme.space[margin]}px;`
      : ""
  };
  ${width ? `width: ${width}px;` : ""};
  ${display ? `display: ${display};` : ""};
  ${textAlign ? `text-align: ${textAlign};` : ""};

  height: ${inline ? "auto" : `${theme.fontSizes.zeta}px`};
  white-space: nowrap;

  background-color: ${
    color
      ? theme.colors[color] || theme.statusColors[color]
      : theme.colors.primary
  };
  ${uppercase ? "text-transform: uppercase;" : ""};

  ${
    partially
      ? `
    background-color: ${theme.colors.gray78};
    position: relative;
    overflow: hidden;

    &::after {
      position: absolute;
      display: inline-block;
      height: 100px;
      width: 100px;
      transform: rotate(20deg);
      top: -12px;
      right: 50%;
      content: "";
      background-color: ${theme.statusColors[color]};
      z-index: -1;
    }
  `
      : ""
  }
`,
);

export const SubtleBadge = styled.div`
  padding: 4px 7px;
  border-radius: 5px;
  font-weight: bold;
  background-color: rgba(102, 102, 102, 0.1);
  ${({ theme, hasErrorBadge }) =>
    hasErrorBadge &&
    `
    position: relative;
    &::after {
      position: absolute;
      top: -4px;
      right: -4px;
      background-color: ${theme.colors.error};
      border: solid 1px ${theme.colors.error};
      border-radius: 20px;
      height: 8px;
      width: 8px;
      content: "";
    }
  `};
`;

export default Badge;
