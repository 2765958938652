import * as React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { SelectField } from "components/Form/FormikControls";

import {
  selectCurrentDeploymentIds,
  selectDeploymentOptions,
  selectRoleDeploymentIds,
} from "selectors";

export const DeploymentSelectField = ({
  disabled,
  name,
  onChangeExtra,
  label,
  required,
  restrictToOwnDeployments,
  retrictToSaleDeployments,
  isMulti,
  warningText = null,
}) => {
  let deploymentOptions = useSelector(selectDeploymentOptions);
  const roleDeploymentIds = useSelector(selectRoleDeploymentIds);
  const saleDeploymentIds = useSelector(selectCurrentDeploymentIds);

  if (restrictToOwnDeployments) {
    deploymentOptions = deploymentOptions.filter(deployment =>
      roleDeploymentIds.includes(deployment.value),
    );
  }

  if (retrictToSaleDeployments) {
    deploymentOptions = deploymentOptions.filter(deployment =>
      saleDeploymentIds.includes(deployment.value),
    );
  }

  return (
    <SelectField
      disabled={disabled}
      label={label}
      name={name}
      onChangeExtra={onChangeExtra}
      options={deploymentOptions}
      required={required}
      isClearable={!required}
      isMulti={isMulti}
      warningText={warningText}
    />
  );
};
DeploymentSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
