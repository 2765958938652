import React from "react";

import Grid from "@material-ui/core/Grid";

import { CollapseHeader, FormCollapse } from "components/Form";
import { VendorCommissionBands } from "components/Form/FormikControls/VendorCommissionBands";

import { FinanceSubModalSection } from "constants/settings";

import { useSectionHelper } from "hooks";

export const VendorCommissionSettings = ({ sectionHelper }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.VENDOR_COMMISSION,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<CollapseHeader title="Vendor Commission" />}
    >
      <Grid item xs={12}>
        <VendorCommissionBands tooltip="Enter the default commission rates per sale sub type.  These defaults are applicable to all vendors, unless overridden on a per vendor basis." />
      </Grid>
    </FormCollapse>
  );
};
