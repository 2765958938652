import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { printSaleLotWeightSheet } from "actions";

import OpenedInNewWindow from "components/Pdfs/OpenedInNewWindow";

import { formatWeightKg, toTitleCase } from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { createAndOpenPdf, getFirstTime, getLastTime } from "lib/pdf";
import { getCombinedLotNumber } from "lib/saleLot";
import { formatDate, formatTime } from "lib/timeFormats";

import { useTranslatedSaleTypeText } from "hooks";
import logo from "img/AgriNous-logo-base64";

const pageMargin = 12;
const pageMarginBottom = 40;

const getFooterText = (lot, sale) => {
  return `${sale.saleyard_name} ${toTitleCase(
    lot.sale_round.name,
  )} Sale ${formatDate(sale.date)} - HC ${lot.quantity} - Printed: ${formatTime(
    Date.now(),
  )}`;
};

const getFooter = (lot, sale, pageMargin) => () => ({
  columns: [
    {
      image: `data:image/jpeg;base64,${logo}`,
      width: 100,
    },
    {
      width: "auto",
      alignment: "right",
      text: getFooterText(lot, sale),
      marginTop: 4,
      marginLeft: 10,
    },
  ],
  margin: [pageMargin + 3, 0],
});

// Render the outer tables, one for each lane.
const getContent = (lot, agencyBySaleLotIdLookup) => {
  const totalWeight = formatWeightKg(lot.total_mass_grams);
  const averageWeight = formatWeightKg(
    lot.quantity > 0 ? lot.total_mass_grams / lot.quantity : 0,
  );

  const scanTimes = lot.scans
    .filter(scan => scan.time_weighed)
    .map(scan => new Date(scan.time_weighed))
    .sort();

  return [
    {
      style: "headerStyle",
      table: {
        widths: [200, "*"],
        body: [
          [{ colSpan: 2, text: "Weighbridge Docket", alignment: "center" }, ""],
        ],
      },
      layout: "noBorders",
    },
    {
      style: "contentStyle",
      table: {
        widths: [170, "auto"],
        body: [
          ["Head:", lot.quantity],
          ["Avg. Wt:", averageWeight],
          ["Tot. Wt:", totalWeight],
          ["Agent:", agencyBySaleLotIdLookup[lot.id].name],
          ["Vendor:", lot.vendor.name],
          ["Pen:", getAuctionPenDisplayName(lot.auction_pen)],
          ["Lot #:", getCombinedLotNumber(lot)],
          getFirstTime(lot, scanTimes),
          getLastTime(lot, scanTimes),
        ],
      },
      layout: "noBorders",
    },
  ];
};

/**
 *
 * @param {Object} saleLot
 * @param {Object} sale
 * @return {Document}
 */
export const generateSaleLotWeightSummaryPdf = (
  saleLot,
  sale,
  agencyBySaleLotIdLookup,
  suppressBack,
) => {
  const pdfDefinition = {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [pageMargin, pageMargin, pageMargin, pageMarginBottom],
    content: getContent(saleLot, agencyBySaleLotIdLookup),
    footer: getFooter(saleLot, sale, pageMargin),
    styles: {
      headerStyle: {
        fontSize: 40,
        margin: [15, 15, 15, 15],
      },
      contentStyle: {
        fontSize: 40,
        overflow: "hidden",
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin: [15, 30, 15, 15],
      },
    },
  };

  createAndOpenPdf(pdfDefinition, undefined, suppressBack);
};

export const LotSheet = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const saleTypeText = useTranslatedSaleTypeText("Sale Lot");
  const { saleLotId } = match.params;
  // http://localhost:3000/saleyard/Bendigo/sale/2692/lot-sheet/21370166-fb99-4d7c-a303-6db16937da8e
  useEffect(() => {
    dispatch(printSaleLotWeightSheet(saleLotId, true));
  }, [dispatch, saleLotId]);

  return (
    <OpenedInNewWindow
      message={`The ${saleTypeText} Sheet was opened in a new window.`}
    />
  );
};
