export class JWTError extends Error {
  constructor(response, status, method, message) {
    super(message);
    this.status = status;
    this.method = method;
    this.response = response;
    this.message = message;
    this.name = "JWTError";
  }
}
