import * as React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { SelectField } from "components/Form/FormikControls";

import { selectNominationOptions, selectRoleDeploymentIds } from "selectors";

export const NominationSelectField = ({
  disabled,
  name,
  label,
  required,
  isMulti,
}) => {
  const roleDeploymentIds = useSelector(selectRoleDeploymentIds);
  const nominations = useSelector(selectNominationOptions).filter(nomination =>
    roleDeploymentIds.includes(nomination.deployment),
  );

  return (
    <SelectField
      disabled={disabled}
      label={label}
      name={name}
      options={nominations}
      required={required}
      isClearable={!required}
      isMulti={isMulti}
      menuPortalTarget={document.body}
    />
  );
};
NominationSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
