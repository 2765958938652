import {
  GET_REPORTS,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAILURE,
} from "constants/actionTypes";

export function requestReports(saleyard, saleId) {
  return {
    type: GET_REPORTS,
    saleyard,
    saleId,
  };
}

export function receiveReports(reports) {
  return {
    type: GET_REPORTS_SUCCESS,
    reports,
  };
}

export function requestReportsError() {
  return {
    type: GET_REPORTS_FAILURE,
  };
}
