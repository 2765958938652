export const getEContractStatus = eContract => {
  if (!eContract) {
    return "--";
  }

  const version = `v${eContract.version}`;

  if (eContract.voidedOn) {
    return `${version} - Voided`;
  }

  if (!eContract.agentAcceptedOn) {
    return `${version} - Drafted`;
  }

  if (eContract.buyerAcceptedOn && eContract.vendorAcceptedOn) {
    return `${version} - Completed`;
  }
  if (eContract.buyerAcceptedOn) {
    return `${version} - Buyer Accepted`;
  }
  if (eContract.vendorAcceptedOn) {
    return `${version} - Vendor Accepted`;
  }
  return `${version} - Not Accepted`;
};
