import React from "react";

import flatten from "lodash/flatten";
import { connect } from "react-redux";

import {
  openConfirmModal,
  closeConfirmModal,
  addConsignment,
  patchConsignment,
  deleteConsignment,
  uploadRequest,
  addSaleLot,
} from "actions";

import { addAuctionPen } from "actions/auctionPens";

import SaleConfirmationModal from "components/SaleConfirmationModal";

import {
  getConsignments,
  selectLegacyConsignmentExceptionsByDeploymentSaleIdLookup,
  selectLegacySaleLotExceptionsByDeploymentSaleIdLookup,
} from "selectors";

const SaleConfirmationModalContainer = props => (
  <SaleConfirmationModal {...props} />
);

const mapStateToProps = (state, props) => {
  const { saleLots = [] } = props;

  // Get the Consignments Related to the Sale Lot
  const consignments = [];
  saleLots.forEach(sl => {
    if (sl.consignmentId) {
      const saleLotConsignment = getConsignments(state)[sl.consignmentId];
      saleLotConsignment && consignments.push(saleLotConsignment);
    }
  });

  // Generate Exceptions
  let exceptions = {};
  if (consignments.length) {
    exceptions = {
      buyers: flatten(
        Object.values(
          selectLegacySaleLotExceptionsByDeploymentSaleIdLookup(state),
        ),
      ),
      vendors: flatten(
        Object.values(
          selectLegacyConsignmentExceptionsByDeploymentSaleIdLookup(state),
        ),
      ),
    };
  }

  return { exceptions };
};

const mapDispatchToProps = {
  openConfirmModal,
  closeConfirmModal,
  addConsignment,
  patchConsignment,
  deleteConsignment,
  uploadRequest,
  addAuctionPen,
  addSaleLot,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaleConfirmationModalContainer);
