/**
 * @typedef {Object} GeodeticCoordinates
 * @property {number} latitude The latitude component of a geodetic coordinate
 * @property {number} longitude The longitude component of a geodetic coordinate
 */

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

/** The approximate midpoint of Australia
 * @type {GeodeticCoordinates}
 */
export const AUSTRALIA_GEODETIC_COORDINATES = {
  latitude: -27.932857623281947,
  longitude: 135.00358754443175,
};

// The approximate radius of Australia in km from it's midpoint
export const AUSTRALIA_RADIUS = 2050;
