import React, { useMemo } from "react";

import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Divider, Grid } from "@material-ui/core";
import { FieldArray, useField, useFormikContext } from "formik";
import { isObject, sortBy, uniq } from "lodash";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { SlimButton } from "components/Button";
import { ButtonIcon } from "components/Button/FontAwesomeButton";
import {
  Autocomplete,
  Error,
  Input,
  Label,
  Required,
} from "components/Form/FormikControls";
import {
  withArrayNamespace,
  withNamespace,
} from "components/Form/FormikControls/lib";
import { Column } from "components/Layout";

import { getSaleSubTypes, getSaleTypes, getSpecies } from "selectors";

import { useFieldMeta, useFieldValue } from "hooks";

const getOptionValue = option => option.value;

export const tradingTermValidation = Yup.array()
  .nullable()
  .of(
    Yup.object().shape({
      speciesId: Yup.number().nullable(true),
      saleType: Yup.string().nullable(true),
      saleSubTypeId: Yup.number().nullable(true),
      buyerTerms: Yup.number().required("Required"),
      vendorTerms: Yup.number().required("Required"),
    }),
  )
  .test(
    "non-dupe",
    "This combination of Sale Type, Species and Sale Sub Type is not unique.",
    //
    value =>
      uniq(
        value?.map(
          tradingTerm =>
            `${tradingTerm.saleType}${tradingTerm.speciesId}${tradingTerm.saleSubTypeId}`,
        ),
      ).length === (value?.length || 0),
  );

function TradingTerm(props) {
  const { index, name, namespace, removeRow } = props;

  const saleSubTypes = useSelector(getSaleSubTypes);
  const species = useSelector(getSpecies);
  const saleTypes = useSelector(getSaleTypes);

  const { error } = useFieldMeta(`${withNamespace(namespace, name)}.${index}`);
  const [{ value: speciesId }] = useField(
    `${withNamespace(namespace, name)}.${index}.speciesId`,
  );
  const [{ value: saleType }] = useField(
    `${withNamespace(namespace, name)}.${index}.saleType`,
  );

  const saleTypeOptions = useMemo(() => {
    return [
      {
        value: null,
        label: "Default for all Sale Types",
      },
    ].concat(
      sortBy(
        saleTypes.map(saleType => ({
          label: saleType,
          value: saleType,
        })),
        "label",
      ),
    );
  }, [saleTypes]);

  const speciesOptions = useMemo(() => {
    return [
      {
        value: null,
        label: "Default for all Species in the selected Sale Type",
      },
    ].concat(
      sortBy(
        Object.values(species).map(s => ({
          label: s.name,
          value: s.id,
        })),
        "label",
      ),
    );
  }, [species]);

  const subTypeOptions = useMemo(() => {
    return [
      {
        value: null,
        label: "Default for all Sale Sub Types",
      },
    ].concat(
      sortBy(
        Object.values(saleSubTypes)
          .filter(s => {
            if (speciesId && s.speciesId !== speciesId) {
              return false;
            } else if (saleType && s.saleType !== saleType) {
              return false;
            } else {
              return true;
            }
          })
          .map(s => ({
            label: `${species[s.speciesId].name} - ${s.name}`,
            value: s.id,
          })),
        "label",
      ),
    );
  }, [saleSubTypes, speciesId, saleType, species]);

  const ns = withArrayNamespace(withNamespace(namespace, name), index);

  return (
    <>
      <Grid item xs={3}>
        <Autocomplete
          name={withNamespace(ns, "saleType")}
          options={saleTypeOptions}
          required
          getOptionValue={getOptionValue}
          disableClearable
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          name={withNamespace(ns, "speciesId")}
          options={speciesOptions}
          required
          getOptionValue={getOptionValue}
          disableClearable
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          name={withNamespace(ns, "saleSubTypeId")}
          options={subTypeOptions}
          getOptionValue={getOptionValue}
          disableClearable
        />
      </Grid>
      <Grid item xs={1}>
        <Input name={withNamespace(ns, "buyerTerms")} />
      </Grid>
      <Grid item xs={1}>
        <Input name={withNamespace(ns, "vendorTerms")} />
      </Grid>
      <Grid item xs={1} container justifyContent="flex-end">
        <SlimButton
          onClick={() => removeRow(index)}
          color="deleteRed"
          type="button"
        >
          <ButtonIcon icon={faTrash} />
          &nbsp;Remove
        </SlimButton>
      </Grid>
      {error?.rowError && (
        <Grid item xs={12}>
          <Error>{error.rowError}</Error>
        </Grid>
      )}
      <Grid xs={12} item>
        <Divider />
      </Grid>
    </>
  );
}

const generateDefaultTerm = () => ({
  id: uuidv4(),
  saleType: null,
  speciesId: null,
  saleSubTypeId: null,
  buyerTerms: 0,
  vendorTerms: 0,
});

export function TradingTerms(props) {
  const { namespace = "", name = "tradingTerms", tooltip } = props;
  const value = useFieldValue(withNamespace(namespace, name)) || [];
  const { errors } = useFormikContext();
  const error = errors[withNamespace(namespace, name)];

  return (
    <Column fullWidth fullHeight>
      <Label tooltip={tooltip} />
      <Column fullWidth fullHeight paddingHorizontal={3}>
        <FieldArray name={withNamespace(namespace, name)}>
          {arrayHelpers => {
            const addNew = () => {
              arrayHelpers.push(generateDefaultTerm());
            };
            return (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SlimButton onClick={addNew} type="button">
                    <ButtonIcon icon={faPlus} />
                    &nbsp;New Trading Terms
                  </SlimButton>
                </Grid>
                {!isObject(error) && error ? (
                  <Grid item xs={12}>
                    <Error>{error}</Error>
                  </Grid>
                ) : null}
                {value.length > 0 ? (
                  <>
                    <Grid item xs={3}>
                      <Label>Sale Type</Label>
                    </Grid>
                    <Grid item xs={2}>
                      <Label>Species</Label>
                    </Grid>
                    <Grid item xs={4}>
                      <Label>Sale Sub Type</Label>
                    </Grid>
                    <Grid item xs={1}>
                      <Label>
                        Buyer Terms
                        <Required />
                      </Label>
                    </Grid>
                    <Grid item xs={1}>
                      <Label>
                        Vendor Terms
                        <Required />
                      </Label>
                    </Grid>

                    <Grid item xs={1}>
                      &nbsp;
                    </Grid>

                    <Grid xs={12} item>
                      <Divider />
                    </Grid>
                    {value.map((tradingTerm, idx) => (
                      <TradingTerm
                        index={idx}
                        removeRow={arrayHelpers.remove}
                        name={name}
                        key={idx}
                      />
                    ))}
                  </>
                ) : null}
              </Grid>
            );
          }}
        </FieldArray>
      </Column>
    </Column>
  );
}
