import React from "react";

import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";

import { BusinessColId } from "constants/columnDefinitions";

import { openEditBusinessModal } from "lib/navigation";

const BusinessListActionColumn = ({ api, value, node }) => {
  const onEditClick = () => {
    openEditBusinessModal(value);
  };

  const name = api.getValue(BusinessColId.NAME, node);

  return (
    <ActionButtonContainer>
      <ActionButton onClick={onEditClick} data-tour={`editBusiness.${name}`}>
        <FontAwesomeIcon icon={faEye} />
        &nbsp;View/Edit
      </ActionButton>
    </ActionButtonContainer>
  );
};

export default BusinessListActionColumn;
