import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Subtitle = styled.h3(
  ({ theme, dark, color, align, inline = false, display, alignItems }) => `
    color: ${
      color || (dark ? theme.colors.titleDark : theme.colors.titleLight)
    };
    font-size: ${theme.fontSizes.beta}px;
    line-height: ${theme.fontSizes.gamma}px;
    text-align: ${align || "start"};
    text-transform: uppercase;
    margin: 0;
    ${inline ? "display: inline-block;" : `margin-bottom: ${theme.space[1]}px;`}
    
    ${display ? `display: ${display};` : ""}
    ${alignItems ? `align-items: ${alignItems};` : ""}
    
  `,
);

Subtitle.propTypes = {
  dark: PropTypes.bool,
  align: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  labelColor: PropTypes.string,
};

export default Subtitle;
