import * as React from "react";

const codes = {
  RETURN: 13,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

const RadioGroupContext = React.createContext({});

export function RadioGroup({
  labelledBy,
  children,
  value,
  onChange,
  disabled,
}) {
  const otherRadioValues = React.Children.map(
    children,
    child => child.props.value,
  );

  const ctx = React.useMemo(
    () => ({
      value,
      otherRadioValues,
      setChecked: onChange,
      disabled,
    }),
    [otherRadioValues, value, onChange, disabled],
  );
  return (
    <RadioGroupContext.Provider value={ctx}>
      <div
        role="radiogroup"
        aria-labelledby={labelledBy}
        data-palmerhq-radio-group
      >
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
}

export const Radio = React.forwardRef(
  (
    { allowToggleOff, children, forceTabStop, skipTab = false, ...props },
    maybeOuterRef,
  ) => {
    const { value: formikValue, onBlur, onFocus } = props;
    const [focus, setFocus] = React.useState(false);
    const ref = React.useRef(null);

    const ctx = React.useContext(RadioGroupContext);
    const { otherRadioValues, value, setChecked, disabled } = ctx;
    const count = otherRadioValues.length - 1;

    const handleKeyDown = React.useCallback(
      event => {
        event.persist();
        let flag = false;

        const activeIndex = otherRadioValues.findIndex(i => i === value);

        function setPrevious() {
          if (!disabled) {
            if (activeIndex === 0) {
              setChecked(otherRadioValues[count]);
            } else {
              setChecked(otherRadioValues[activeIndex - 1]);
            }
          }
        }

        function setNext() {
          if (!disabled) {
            if (activeIndex === count) {
              setChecked(otherRadioValues[0]);
            } else {
              setChecked(otherRadioValues[activeIndex + 1]);
            }
          }
        }

        switch (event.keyCode) {
          case codes.SPACE:
          case codes.RETURN:
            !disabled && setChecked(formikValue);
            flag = true;
            break;
          case codes.UP:
          case codes.LEFT:
            setPrevious();
            flag = true;
            break;
          case codes.DOWN:
          case codes.RIGHT:
            setNext();
            flag = true;
            break;
          default:
            break;
        }

        if (flag) {
          event.stopPropagation();
          event.preventDefault();
        }
      },
      [disabled, value, formikValue, otherRadioValues, count, setChecked],
    );

    const handleClick = React.useCallback(() => {
      // If you click this item, and it's the current value allow setting to
      // null
      if (!disabled) {
        if (allowToggleOff && formikValue === value) {
          setChecked(undefined);
        } else {
          setChecked(formikValue);
        }
      }
    }, [formikValue, setChecked, allowToggleOff, value, disabled]);

    const handleBlur = React.useCallback(
      e => {
        if (onBlur) {
          onBlur(e);
        }
        setFocus(false);
      },
      [onBlur, setFocus],
    );

    const handleFocus = React.useCallback(
      e => {
        if (onFocus) {
          onFocus(e);
        }
        setFocus(true);
      },
      [onFocus, setFocus],
    );

    const hasTabIndex = skipTab ? false : formikValue || forceTabStop;

    return (
      <div
        {...props}
        role="radio"
        tabIndex={hasTabIndex ? 0 : -1}
        aria-checked={value === formikValue}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        data-palmerhq-radio
        data-palmerhq-radio-focus={focus}
        ref={el => {
          if (maybeOuterRef) {
            maybeOuterRef.current = el;
          }
          ref.current = el;
        }}
      >
        {children}
      </div>
    );
  },
);
