import React, { memo, useCallback, useMemo, useState } from "react";

import Pagination from "@material-ui/lab/Pagination";

import DuplicateListing from "./DuplicateListing";

const PAGE_SIZE = 10;

function DuplicateBusinessSuggestionsComponent(props) {
  const { dismissDuplicate, dismissDuplicates, reviewDuplicate, suggestions } =
    props;

  const [page, setPage] = useState(1);
  const pageCount = Math.ceil(suggestions.length / PAGE_SIZE);

  const onPageChanged = useCallback(
    (_, nextPage) => {
      setPage(nextPage);
    },
    [setPage],
  );

  const currentSlice = useMemo(() => {
    const pageSliceStart = (page - 1) * PAGE_SIZE;
    const pageSliceEnd = pageSliceStart + PAGE_SIZE;

    return suggestions.slice(pageSliceStart, pageSliceEnd);
  }, [suggestions, page]);

  return (
    <>
      {currentSlice.map((dedupe, index) => (
        <DuplicateListing
          key={`${index}_${dedupe.masterBusinessId}`}
          businessId={dedupe.masterBusinessId}
          duplicates={dedupe.duplicates}
          dismissDuplicate={dismissDuplicate}
          dismissDuplicates={dismissDuplicates}
          reviewDuplicate={reviewDuplicate}
        />
      ))}
      <Pagination
        count={pageCount}
        onChange={onPageChanged}
        page={page}
        shape="rounded"
        variant="outlined"
      />
    </>
  );
}

export default memo(DuplicateBusinessSuggestionsComponent);
