import React, { memo } from "react";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import {
  ConfirmDialog,
  MESSAGE_CANT_BE_UNDONE,
} from "components/ConfirmDialog";

import { PenTypes } from "constants/auctionPens";

export function ClearSavedEidsModalComponent(reactProps) {
  const { closeSelf, modalProps } = reactProps;
  const { penType, message, onDelete: onDeleteScans } = modalProps;

  function onDelete() {
    onDeleteScans();
    closeSelf();
  }

  return (
    <ConfirmDialog
      isOpen
      message={
        message ||
        `Are you sure you want to clear stored EIDs from this ${
          penType === PenTypes.RECEIVING ? "Receival" : "Pen Scan"
        } Lot? ${MESSAGE_CANT_BE_UNDONE}`
      }
      messageIcon={faExclamationTriangle}
      buttonMessage="Clear Saved EIDs"
      onDelete={onDelete}
      onCancel={closeSelf}
      title="Clear Saved EIDs?"
    />
  );
}
export default memo(ClearSavedEidsModalComponent);
