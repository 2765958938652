import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { IntegrationCredentialAction } from "actions/integrationCredentials";

import { SimpleGrid } from "components/AgGrid/SimpleGrid";
import { ModalTableWrapper } from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import {
  LedgerAccountColId,
  LedgerAccountColumnDef,
} from "constants/columnDefinitions/ledgerAccount";
import { IntegrationTypes } from "constants/integrations";

import {
  getActiveLivestockAgentDeployment,
  selectIntegrationCredentialIdByTypeAndDeploymentIdLookup,
} from "selectors";

import { selectLedgerAccountsAgGridData } from "selectors/aggrid/ledgerAccounts";

const columnDefs = Object.values(LedgerAccountColumnDef).filter(
  ({ colId }) => colId !== LedgerAccountColId.ACTIONS,
);
export const AutoCreateInXeroConfirmation = ({ open, toggleShowConfirm }) => {
  const dispatch = useDispatch();

  const rowData = useSelector(selectLedgerAccountsAgGridData);

  const accountNamesInXero = rowData
    .filter(row => row.xeroAccountId)
    .map(row => row.name);

  // Names must be unique.
  const pushableLedgerAccounts = rowData.filter(
    row =>
      row.id && !row.xeroAccountId && !accountNamesInXero.includes(row.name),
  );
  const deployment = useSelector(getActiveLivestockAgentDeployment);
  const integrationCredentialId = useSelector(
    selectIntegrationCredentialIdByTypeAndDeploymentIdLookup,
  )?.[IntegrationTypes.Xero]?.[deployment.id];
  if (!integrationCredentialId) {
    return;
  }

  const onSubmit = () => {
    dispatch(
      IntegrationCredentialAction.pushLedgerAccount(
        integrationCredentialId,
        pushableLedgerAccounts.map(ledgerAccount => ledgerAccount.id).join(","),
      ),
    );
  };

  return (
    <Dialog open={open} onClose={toggleShowConfirm} maxWidth="lg" fullWidth>
      <DialogTitle onClose={toggleShowConfirm}>
        Are you sure you wish to push the following Ledger Accounts to Xero?
      </DialogTitle>
      <DialogContent>
        <ModalTableWrapper minHeight={500}>
          <SimpleGrid
            rowData={pushableLedgerAccounts}
            columnDefs={columnDefs}
          />
        </ModalTableWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleShowConfirm}>Cancel</Button>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
