import React from "react";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SecondaryButton } from "components/Form";
import { Column, Row } from "components/Layout";
import { HelpModal, ShareModal } from "components/Modal";
import { Paper } from "components/Paper";

import { EID_TRANSFER_STATUS, TRANSFER_STATUS } from "constants/nlis";

import {
  getEIDStatus,
  getIsTransferIgnorable,
  getOverallIconAndColor,
} from "lib/nlisTake";
import { formatDateTimeUTC } from "lib/timeFormats";

import { getIsStaff, getNLISTransactionByTypeById } from "selectors";

import { useBoolean, useToggle } from "hooks";
import NLISLogo from "img/NLIS-logo.png";

const messages = {
  [TRANSFER_STATUS.SUCCESS]: "Successful",
  [TRANSFER_STATUS.FAILED]: "Failed",
  [TRANSFER_STATUS.PENDING]: "Pending",
  [TRANSFER_STATUS.UNSUBMITTED]: "Submission in Progress",
  [TRANSFER_STATUS.PENDING_ROLLBACK]: "Pending Rollback",
  [TRANSFER_STATUS.ROLLED_BACK]: "Rolled Back",
  [TRANSFER_STATUS.PARTIAL_SUCCESS]:
    "Partially Successful.  See detail for more information.",
  [TRANSFER_STATUS.ACCEPTED]: "Accepted",
};

const getMessageText = (status, nlisStatus) => {
  const eidStatus = getEIDStatus(nlisStatus);
  // If the transaction was successful but some eids failed to transfer.
  if (
    status === TRANSFER_STATUS.SUCCESS &&
    eidStatus === EID_TRANSFER_STATUS.WARNING
  ) {
    return messages[TRANSFER_STATUS.PARTIAL_SUCCESS];
  }
  // If we have nothing in there (that is, waiting for the server, waiting for the
  // nlis response, fallback to unsubmitted.
  return messages[status] || messages[TRANSFER_STATUS.UNSUBMITTED];
};

const getStatusIcon = nlisStatus => {
  const obj = getOverallIconAndColor(nlisStatus);
  return obj ? <StatusIcon icon={obj.icon} color={obj.color} /> : null;
};

export const NLISDocket = ({
  className,
  from,
  headcount,
  isFetching,
  messageContent,
  nlisStatus,
  onClickIgnore,
  refreshClick,
  reversalTransaction,
  rollbackClick,
  to,
}) => {
  const [isHelpModalOpen, toggleIsHelpModalOpen] = useToggle(false);
  const [shareModalOpen, openShareModal, closeShareModal] = useBoolean(false);

  const { createdDate, createdTime, status, errorNotes, transactionId } =
    nlisStatus;

  const isStaff = useSelector(getIsStaff);

  const isIgnoreAvailable = getIsTransferIgnorable(nlisStatus) || isStaff;

  return (
    <Modal className={className} data-tour="ticket-modal">
      <ModalHeader>
        <NLISImg />
      </ModalHeader>
      <Divider>
        <Dotted />
      </Divider>
      <ModalBody>
        {getStatusIcon(nlisStatus)}
        <MessageStyled color={getOverallIconAndColor(nlisStatus).color}>
          Transfer {getMessageText(status, nlisStatus)}
        </MessageStyled>
        <Message data-tour={transactionId || "Waiting for NLIS"} small>
          Transaction ID: {transactionId || "Waiting for NLIS"}
        </Message>
        {reversalTransaction && (
          <>
            <Message small>
              Rollback Transaction Id:{" "}
              {reversalTransaction.transactionId || "Waiting for NLIS"}
            </Message>
            <Message small>
              Rollback Status: {reversalTransaction.status}
            </Message>
            {reversalTransaction.processed && (
              <Message small>
                Rollback Processed:{" "}
                {formatDateTimeUTC(reversalTransaction.processed)}
              </Message>
            )}
            {reversalTransaction.errorNotes && (
              <Message small>
                Rollback Errors: {reversalTransaction.errorNotes}
              </Message>
            )}
          </>
        )}
        {errorNotes && (
          <Message small isWarning>
            {errorNotes}
          </Message>
        )}

        <StyledRow border>
          <Text width="40%">{from}</Text>
          {(from || to) && <ArrowIcon />}
          <Text alignRight width="40%">
            {to}
          </Text>
        </StyledRow>
        <StyledRow>
          <StyledColumn>
            <Label>date</Label>
            <Text>{createdDate}</Text>
          </StyledColumn>
          {headcount > 0 && (
            <StyledColumn textAlign="center">
              <Label>headcount</Label>
              <Text>{headcount}</Text>
            </StyledColumn>
          )}
          <StyledColumn textAlign="right">
            <Label alignRight>time</Label>
            <Text>{createdTime}</Text>
          </StyledColumn>
        </StyledRow>
        <ButtonWrapper>
          {isIgnoreAvailable && (
            <SecondaryButtonStyled onClick={onClickIgnore}>
              Ignore
            </SecondaryButtonStyled>
          )}
          {refreshClick && (
            <SecondaryButtonStyled
              data-tour="refresh"
              onClick={refreshClick}
              disabled={isFetching}
            >
              Refresh
            </SecondaryButtonStyled>
          )}
          <SecondaryButton data-tour="shareReceipt" onClick={openShareModal}>
            Share Receipt
          </SecondaryButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <SecondaryButton data-tour="getHelp" onClick={toggleIsHelpModalOpen}>
            Get Help
          </SecondaryButton>
        </ButtonWrapper>

        {rollbackClick ? (
          <ButtonWrapper>
            <SecondaryButton onClick={rollbackClick}>Rollback</SecondaryButton>
          </ButtonWrapper>
        ) : null}
        {isHelpModalOpen ? <HelpModal onClose={toggleIsHelpModalOpen} /> : null}
        {shareModalOpen ? (
          <ShareModal
            messageTitle={`NLIS Transfer ${status} (${transactionId})`}
            messageContent={messageContent}
            onClose={closeShareModal}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};

NLISDocket.propTypes = {
  className: PropTypes.string,
  from: PropTypes.string,
  headcount: PropTypes.number,
  isFetching: PropTypes.bool,
  messageContent: PropTypes.string,
  nlisStatus: PropTypes.shape({}),
  onClickIgnore: PropTypes.func,
  refreshClick: PropTypes.func,
  reversalTransaction: PropTypes.any,
  rollbackClick: PropTypes.func,
  to: PropTypes.string,
};

NLISDocket.defaultProps = { nlisStatus: {} };

const Modal = styled(Paper).attrs({ elevation: 4 })`
  margin: 12px auto;
  flex: 1;
  max-width: 375px;
  overflow: hidden;
`;

const ModalHeader = styled.div`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space[3]}px;
`;

const Divider = styled.div`
  height: 20px;
  margin: 0 10px;
  background-color: white;
  position: relative;
  box-shadow:
    0 1px 0 0 #fff,
    0 -1px 0 0 #fff;
  :before,
  :after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    border: 5px solid transparent;
    border-top-color: #fff;
    border-right-color: #fff;
    border-radius: 100%;
  }
  :before {
    left: -10px;
  }
  :after {
    -webkit-transform: translate(-50%, -50%) rotate(225deg);
    transform: translate(-50%, -50%) rotate(225deg);
    right: -40px;
  }
`;

const Dotted = styled.div`
  position: absolute;
  top: 8px;
  left: 5px;
  width: 350px;
  background-image: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.gray7A} 50%, ${theme.colors.white} 50%);`} 
  background-repeat: repeat-x;
  background-size: 21px;
  height: 2px;
`;

const ModalBody = styled.div`
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[3]}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledRow = styled(Row).attrs({
  fullWidth: true,
  justifyBetween: true,
  alignCenter: true,
})`
  ${({ theme, border }) =>
    border ? `border-bottom: 1px solid ${theme.colors.gray7A};` : ""}
  padding: ${({ theme }) => theme.space[2]}px 0;
`;

const StyledColumn = styled(Column).attrs({
  flexGrow: true,
})`
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`}
`;

const Text = styled.div`
  font-size: 17px;
  font-weight: bold;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray13};
  ${({ alignRight }) => (alignRight ? "text-align: right;" : null)}
  overflow-wrap: break-word;
  ${({ width }) => width && `width: ${width}`}
`;

const Message = styled.div`
  color: ${({ theme, isWarning }) =>
    isWarning ? theme.colors.warning : theme.colors.gray8C};
  font-size: ${({ theme, small }) => (small ? theme.fontSizes.alpha : "15")}px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding: ${({ theme, padding }) => (padding ? `${theme.space[2]}px` : "")};
`;

const Label = styled.div`
  color: #b2b2b2;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
`;

const ArrowIcon = styled(FontAwesomeIcon).attrs({
  icon: faArrowRight,
})`
  font-size: ${({ theme }) => theme.fontSizes.delta}px;
  color: ${({ theme }) => theme.colors.gray13};
`;

const StatusIcon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSizes.eta}px;
  color: ${({ color }) => color};
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

const NLISImg = styled.img.attrs({
  src: NLISLogo,
  alt: "NLIS-Logo",
})`
  padding: ${({ theme }) => theme.space[2]}px;
`;

const MessageStyled = styled(Message)`
  color: ${({ color }) => color};
`;

const ButtonWrapper = styled(Row)`
  width: 100%;
  padding: ${({ theme }) => theme.space[2]}px 0;
`;

const SecondaryButtonStyled = styled(SecondaryButton)`
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

export const NLISSightingDocket = ({ refreshClick, transferType, id }) => {
  const [isHelpModalOpen, toggleIsHelpModalOpen] = useToggle(false);

  const sightingFile = useSelector(
    getNLISTransactionByTypeById(transferType, id),
  );
  const {
    transactionId,
    errorNotes,
    createdDate,
    PIC,
    sightings,
    createdTime,
    isFetching,
  } = sightingFile;

  return (
    <Modal data-tour="ticket-modal">
      <ModalHeader>
        <NLISImg />
      </ModalHeader>
      <Divider>
        <Dotted />
      </Divider>
      <ModalBody>
        <MessageStyled>PIC: {PIC}</MessageStyled>
        <MessageStyled color={getOverallIconAndColor(sightingFile).color}>
          Sighting {messages[sightingFile.status]}
        </MessageStyled>
        <Message data-tour={transactionId || "Waiting for NLIS"} small>
          Transaction ID: {transactionId || "Waiting for NLIS"}
        </Message>
        {errorNotes && (
          <Message small isWarning>
            {errorNotes}
          </Message>
        )}
        <StyledRow>
          <StyledColumn>
            <Label>date</Label>
            <Text>{createdDate}</Text>
          </StyledColumn>
          <StyledColumn textAlign="center">
            <Label>headcount</Label>
            <Text>{sightings.length}</Text>
          </StyledColumn>
          <StyledColumn textAlign="right">
            <Label alignRight>time</Label>
            <Text>{createdTime}</Text>
          </StyledColumn>
        </StyledRow>
        <ButtonWrapper>
          {refreshClick && (
            <SecondaryButtonStyled onClick={refreshClick} disabled={isFetching}>
              Refresh
            </SecondaryButtonStyled>
          )}
        </ButtonWrapper>
        <ButtonWrapper>
          <SecondaryButton onClick={toggleIsHelpModalOpen}>
            Get Help
          </SecondaryButton>
        </ButtonWrapper>
        {isHelpModalOpen ? <HelpModal onClose={toggleIsHelpModalOpen} /> : null}
      </ModalBody>
    </Modal>
  );
};
