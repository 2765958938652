import {
  MERGE_SALE_LOT_COMMIT,
  MERGE_SALE_LOT_OFFLINE,
  MERGE_SALE_LOT_ROLLBACK,
} from "constants/actionTypes";

export function saleLotMerge(fromSaleLotId, toSaleLotId) {
  return {
    type: MERGE_SALE_LOT_OFFLINE,
    payload: { fromSaleLotId, toSaleLotId },
    meta: {
      offline: {
        effect: {
          url: `/v2/salelots/${fromSaleLotId}/merge/${toSaleLotId}`,
          method: "POST",
        },
        commit: {
          type: MERGE_SALE_LOT_COMMIT,
        },
        rollback: {
          type: MERGE_SALE_LOT_ROLLBACK,
          meta: { fromSaleLotId, toSaleLotId },
        },
      },
    },
  };
}
