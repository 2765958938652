import React, { memo, useMemo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import { ERPChip } from "components/Chip";
import { CollapseSection } from "components/EIDsSection/CollapseSection";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Row } from "components/Layout";
import { AddDetails } from "components/NlisAddDetails";

import { userTypes } from "constants/users";

import { scanHasIssues } from "lib/scans";

import {
  getHasEnteredNlisCreds,
  getPropertyById,
  getScansBySaleLotId,
  selectIsUserOfType,
} from "selectors";

export const EIDsCollapseHeader = memo(
  ({ numIssues, showAddNLISCredentials, erpStatus, readOnly }) => (
    <Row>
      <SubtleBadge>EIDs</SubtleBadge>
      {numIssues > 0 && (
        <CollapseLabel>{numIssues} potential issues</CollapseLabel>
      )}
      <ERPChip erpMessages={erpStatus} />
      <CollapseLabel>
        {!readOnly && <AddDetails isVisible={showAddNLISCredentials} />}
      </CollapseLabel>
    </Row>
  ),
);

function EIDsCollapseComponent(props) {
  const { isOpen, onToggle, saleyard, saleLotId, vendorPropertyId, readOnly } =
    props;

  const scans = useSelector(getScansBySaleLotId(saleLotId)) || [];
  const vendorProperty = useSelector(getPropertyById(vendorPropertyId));
  const hasEnteredNlisCreds = useSelector(getHasEnteredNlisCreds);
  const isNLISAwareUser = useSelector(
    selectIsUserOfType([userTypes.SALEYARD_ADMIN, userTypes.LIVESTOCK_AGENT]),
  );

  const showAddNlisCredentials = !hasEnteredNlisCreds && isNLISAwareUser;

  const numIssues = scans.filter(scan =>
    scanHasIssues(scan, vendorProperty?.PIC),
  ).length;

  const erpStatus = scans.map(a => a.ERP_status);

  const header = useMemo(
    () => (
      <EIDsCollapseHeader
        numIssues={numIssues}
        saleyard={saleyard}
        showAddNLISCredentials={showAddNlisCredentials}
        erpStatus={erpStatus}
        readOnly={readOnly}
      />
    ),
    [numIssues, saleyard, showAddNlisCredentials, erpStatus, readOnly],
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      id="collapse-eids"
      dataTour={isOpen ? "hideEIDs" : "showEIDs"}
    >
      <CollapseSection scans={scans} readOnly={readOnly} />
    </FormCollapse>
  );
}
EIDsCollapseComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  saleLotId: PropTypes.string,
  vendorPropertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(EIDsCollapseComponent);
