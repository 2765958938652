import React, { useState } from "react";

import { PropTypes } from "prop-types";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";

import AnimalListItem from "components/AnimalListItem";
import ExpandIcon from "components/ExpandIcon";
import { Button, SecondaryButton } from "components/Form";
import { ExclamationIcon } from "components/Icons";
import { Column, Row } from "components/Layout";
import NLISLogo from "components/NLISLogoStatus";

import { logoStatus, TRANSFER_STATUS } from "constants/nlis";

import SaleConfirmationModal from "containers/SaleConfirmationModal";

import { openAnimalModal } from "lib/navigation";
import {
  getAlertByStatus,
  getDefaultStatusColor,
  getEIDStatus,
  getTransferFileStatusText,
  getUnresolvedAlert,
} from "lib/nlisTake";

const AuctionPenListItem = ({
  className,
  animals,
  buyerName,
  destinationProperty,
  saleLots,
  sellFile,
  exceptions,
  handleEditClick,
  handleSellClick,
  handleViewClick,
  headcount,
  isSellable,
  ...others
}) => {
  const [expanded, setExpanded] = useState(false);
  const [saleConfirmation, setSaleConfirmation] = useState(false);

  const match = useRouteMatch();

  const toggleExpanded = () => setExpanded(!expanded);

  const toggleSaleConfirmation = () => {
    setSaleConfirmation(!saleConfirmation);
  };

  const saleConfirmProps = {
    animals,
    buyerName,
    destinationProperty,
    saleLots,
    ...others,
  };

  const color = getDefaultStatusColor();
  const alert = sellFile
    ? getAlertByStatus(sellFile)
    : exceptions && exceptions.length
      ? getUnresolvedAlert(exceptions)
      : null;

  const parentStatus = getTransferFileStatusText(sellFile);

  let sellStatusText = "INVALID";
  if (parentStatus === null) {
    sellStatusText = "UNSOLD";
  } else if (parentStatus === TRANSFER_STATUS.SUCCESS.toUpperCase()) {
    sellStatusText = "SOLD";
  } else {
    sellStatusText = TRANSFER_STATUS[parentStatus].toUpperCase();
  }

  return (
    <>
      <Wrapper
        color={alert && alert.sidebarColor ? alert.sidebarColor : color}
        className={className}
        onClick={toggleExpanded}
        data-tour={`sale-lot-${buyerName}`}
      >
        <TopWrapper>
          <Row justifyBetween>
            <AlertIconWrapper>
              <Status
                color={alert && alert.statusColor ? alert.statusColor : color}
              >
                {sellStatusText}
              </Status>
              {alert && alert.texts.length > 0 ? (
                <ExclamationIcon className="px-1" color="warning" />
              ) : null}
            </AlertIconWrapper>
            <ButtonWrapper>
              {sellStatusText !== "UNSOLD" && (
                <>
                  {sellFile.status && (
                    <LogoWrapper onClick={handleViewClick}>
                      <NLISLogo status={logoStatus[getEIDStatus(sellFile)]} />
                    </LogoWrapper>
                  )}
                  <SecondaryButtonStyled
                    data-tour="view"
                    onClick={handleViewClick}
                  >
                    View
                  </SecondaryButtonStyled>
                </>
              )}

              {(!sellFile ||
                sellFile.status === TRANSFER_STATUS.ROLLED_BACK) && (
                <>
                  <SecondaryButtonStyled
                    singleButton={!isSellable}
                    onClick={handleEditClick}
                    data-tour="edit"
                  >
                    Edit
                  </SecondaryButtonStyled>
                  {isSellable && (
                    <Button data-tour="sell" onClick={toggleSaleConfirmation}>
                      Sell
                    </Button>
                  )}
                </>
              )}
            </ButtonWrapper>
          </Row>
          <BottomRow justifyBetween>
            <Column truncate flexGrow>
              <Label>Buyer</Label>
              <Text>
                {buyerName} - {destinationProperty}
              </Text>
            </Column>
            <Column>
              <Label>Head Count</Label>
              <Text data-tour="headCount">{headcount}</Text>
              <StyledExpandIcon
                hide={animals.length < 1 ? true : undefined}
                expanded={expanded}
              />
            </Column>
          </BottomRow>
        </TopWrapper>
        {expanded && animals.length ? (
          <AnimalListContainer>
            {animals.map(animal => (
              <AnimalListItemStyled
                key={animal.EID}
                onClick={() => openAnimalModal(match.url, animal.EID)}
                {...animal}
              />
            ))}
          </AnimalListContainer>
        ) : null}
      </Wrapper>
      {saleConfirmation ? (
        <SaleConfirmationModal
          closeSelf={toggleSaleConfirmation}
          handleSellClick={handleSellClick}
          animals={animals}
          {...saleConfirmProps}
        />
      ) : null}
    </>
  );
};

export default AuctionPenListItem;

AuctionPenListItem.propTypes = {
  animals: PropTypes.array,
  buyerName: PropTypes.string,
  destinationProperty: PropTypes.string,
  saleLots: PropTypes.array,
  handleEditClick: PropTypes.func,
  handleSellClick: PropTypes.func,
  requestSellStatus: PropTypes.func,
  openAnimalModal: PropTypes.func,
  isSellable: PropTypes.bool,
};

const TopWrapper = styled.div`
  padding: ${({ theme }) => theme.space[2]}px;
`;

export const Label = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.grayAE};
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: ${({ color }) => `6px solid ${color}`};
  box-shadow: 0 2px 4px 0 rgba(200, 200, 200, 0.6);
`;

export const Text = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.gray40};
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonWrapper = styled(Row)`
  width: 40%;
`;

const SecondaryButtonStyled = styled(SecondaryButton)`
  ${({ singleButton }) =>
    singleButton ? "margin-right: 0" : "margin-right: 12px"};
`;

export const BottomRow = styled(Row)`
  position: relative;
  margin-top: ${({ theme }) => theme.space[2]}px;
`;

const StyledExpandIcon = styled(ExpandIcon)`
  position: absolute;
  bottom: 10px;
  right: 0;
  ${({ hide }) => (hide ? "display: none" : "")};
`;

export const Status = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ color }) => color};
  height: 30px;
  min-width: 91px;
  border-radius: ${({ theme }) => theme.radii[4]}px;
  padding: 0 ${({ theme }) => theme.space[1]}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AnimalListItemStyled = styled(AnimalListItem)`
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[3]}px;
`;

export const AnimalListContainer = styled(Column)`
  > div:nth-child(2n - 1) {
    background-color: ${({ theme }) => theme.colors.gray95};
  }
`;

const AlertIconWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: ${({ theme }) => theme.space[1]}px;
  cursor: pointer;
`;
