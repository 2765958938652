import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components/macro";

export const UploadIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.ready};
  font-size: ${({ theme }) => theme.space[4]}px;
`;

export function StepIconSpinner() {
  return <CircularProgress />;
}
