import React from "react";

import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import styled from "styled-components/macro";

import { Clickable } from "components/Button";
import { ErrorArea } from "components/Form/Grid";
import { Row, Column } from "components/Layout";

import { fontSizes, space } from "constants/theme";

import toast from "lib/toast";

const OptionRow = styled(Column)`
  height: ${({ rowHeight }) => rowHeight}px;
  padding: ${({ large }) => (large ? "0 24px 0 36px;" : "0 12px;")};
  ${({ dark }) => dark && "color: #c8c8c8"};
  cursor: pointer;
  background-color: white;
  :hover {
    background-color: ${({ dark }) => (dark ? "#666" : "#f2f2f2")};
  }
`;

const MainRow = styled(Row)`
  height: ${({ rowHeight }) => rowHeight}px;
  padding: 0 ${({ theme }) => theme.space[1]}px;
`;

const ClickableBlock = styled(Clickable)`
  color: black;
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CollapsingButton = styled.button`
  width: 20%;
  height: ${({ rowHeight }) => rowHeight};
  cursor: pointer;
  background: transparent;
  border: none;
  &:focus {
    outline: none;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.gray40};
`;

// (Line height * items) + top padding + footer padding;
// here is what's used as the height calculation up in SearchableSelector
export const expandedLineHeight = fontSizes.gamma;
export const expandedPadding = space[2];
const ExpandedInformationWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.beta}px;
  line-height: ${expandedLineHeight}px;
  background-color: white;
  width: 100%;
  padding: ${expandedPadding}px;
`;

const SubTitle = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray78};
`;

const ValueText = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

const ComponentText = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

const ExpandedInformation = ({ information }) => {
  if (!information) {
    return null;
  }
  return (
    <ExpandedInformationWrapper data-tour="expandedInfo">
      {information.map(({ title, value, textToCopy }, idx) => (
        <Row justifyBetween fullWidth key={`expandedInfo-${idx}`}>
          <SubTitle>{title}</SubTitle>
          {textToCopy ? (
            <CopyToClipboard
              text={textToCopy}
              onCopy={() => toast.success("Link successfully copied")}
            >
              <ComponentText>{value}</ComponentText>
            </CopyToClipboard>
          ) : (
            <ValueText>{value}</ValueText>
          )}
        </Row>
      ))}
    </ExpandedInformationWrapper>
  );
};

const PreText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  color: ${({ theme }) => theme.colors.gray40};
`;

const ListItem = ({
  dataTour,
  name,
  preText,
  metadata,
  expanded,
  expandedHeight,
  toggleMetadata,
  itemName,
  style,
  rowHeight,
  dark,
  large,
  onClick,
  disabled,
}) => (
  <OptionRow
    style={style}
    rowHeight={expanded ? expandedHeight : rowHeight}
    dark={dark || undefined}
    large={large || undefined}
    disabled={disabled}
    fullWidth
    justifyBetween
    alignCenter
  >
    <MainRow justifyBetween alignTop fullWidth rowHeight={rowHeight}>
      <ClickableBlock data-tour={dataTour} onClick={onClick} type="button">
        {preText ? <PreText>{preText}</PreText> : null}
        <span>{name}</span>
      </ClickableBlock>

      {metadata ? (
        <CollapsingButton
          onClick={toggleMetadata}
          data-tour={`${dataTour}Collapse`}
        >
          <StyledIcon icon={expanded ? faChevronUp : faChevronDown} />
        </CollapsingButton>
      ) : null}
    </MainRow>
    {disabled && (
      <ErrorArea style={{ marginLeft: "12px" }}>
        {`${itemName} is invalid`}
      </ErrorArea>
    )}
    {expanded ? <ExpandedInformation information={metadata} /> : null}
  </OptionRow>
);

ListItem.propTypes = {
  dataTour: PropTypes.string,
  name: PropTypes.string,
  itemName: PropTypes.string,
  style: PropTypes.object,
  rowHeight: PropTypes.number,
  dark: PropTypes.bool,
  large: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ListItem;
