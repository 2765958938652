import React, { memo } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Card from "components/Card";
import { Column, Row, SpaceBetweenRow } from "components/Layout";
import {
  CardItemTitle,
  formatCardValue,
} from "components/SaleSummaryCards/Common";
import { BigText, BoldText, MediumText, SmHeading } from "components/Text";

import { getProgenyDisplayCount } from "lib/saleLot";

import {
  getCurrentRoundsList,
  getRounds,
  selectDeliveredCount,
  selectDeliveredCountByRoundIdLookup,
  selectIsBalancedByDeploymentSaleIdLookup,
  selectTotalNoSaleCount,
  selectNoSaleCountByRoundIdLookup,
  selectNotDraftedCount,
  selectNotPennedCount,
  selectNotPennedCountByRoundIdLookup,
  selectPennedCount,
  selectPennedCountByRoundIdLookup,
  selectReceivedCount,
  selectRoundIsBalancedByDeploymentSaleIdLookup,
  selectTotalSoldHeadCount,
  selectSoldCountByRoundIdLookup,
  getTotalQuantityProgenyNotPenned,
  getTotalQuantityProgenyPenned,
  getTotalQuantityProgenyNoSale,
  getTotalQuantityProgenySold,
  getTotalQuantityProgenyDelivered,
} from "selectors";

import ArrivedLogo from "img/ArrivedLogo";
import DeliveredLogo from "img/DeliveredLogo";
import NoSaleLogo from "img/NoSaleLogo";
import NotDraftedLogo from "img/NotDraftedLogo";
import NotPennedLogo from "img/NotPennedLogo";
import PennedLogo from "img/PennedLogo";
import SoldLogo from "img/SoldLogo";

const HoverCard = styled(Card)`
  padding: ${({ theme }) => theme.space[2]}px;
  opacity: 0;
  transition: ${({ theme }) => theme.transitions[0]};
  position: absolute;
  left: 3px;
  top: 80px;
  width: 180px;
  z-index: 25;
`;

const CardWrapper = styled(Card)`
  min-width: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &:hover ${HoverCard} {
    opacity: 1;
  }
`;

const DesktopMetricValue = styled(BoldText)`
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
`;

export const MediumMobileCard = ({
  title,
  value,
  isError,
  isSuccess,
  dataTour,
  progeny,
  logo,
}) => (
  <SpaceBetweenRow data-tour={dataTour || title}>
    <Row alignStart>
      {logo}
      <CardItemTitle>{title} </CardItemTitle>
    </Row>
    <Column alignEnd>
      <MediumText error={isError} success={isSuccess}>
        {formatCardValue(value)}
      </MediumText>
      {getProgenyDisplayCount(progeny)}
    </Column>
  </SpaceBetweenRow>
);

export const LargeMobileCard = ({
  title,
  value,
  isError,
  isSuccess,
  dataTour,
  progeny,
  logo,
}) => (
  <SpaceBetweenRow data-tour={dataTour || title}>
    <Row alignStart>
      {logo}
      <CardItemTitle>{title}</CardItemTitle>
    </Row>
    <Column alignEnd>
      <BigText error={isError} success={isSuccess}>
        {formatCardValue(value)}
      </BigText>
      {getProgenyDisplayCount(progeny)}
    </Column>
  </SpaceBetweenRow>
);

export const DesktopHoverCard = ({ metrics }) => {
  if (Array.isArray(metrics) && metrics.length) {
    return (
      <HoverCard>
        {metrics.map(({ title, value, isError }) => (
          <SpaceBetweenRow key={title}>
            <SmHeading>{title}</SmHeading>
            <SmHeading bold error={isError || value < 0}>
              {formatCardValue(value)}
            </SmHeading>
          </SpaceBetweenRow>
        ))}
      </HoverCard>
    );
  }
  return null;
};

export const DesktopCard = ({
  children,
  title,
  value,
  isError,
  isSuccess,
  additionalInfo,
  progeny,
  logo,
}) => {
  return (
    <CardWrapper data-tour={`summary.${title}`} key={title}>
      {logo}
      <CardItemTitle>{title}</CardItemTitle>
      <DesktopMetricValue error={isError} success={isSuccess}>
        {formatCardValue(value)}
      </DesktopMetricValue>
      {additionalInfo && <DesktopHoverCard metrics={additionalInfo} />}
      {children}
      {getProgenyDisplayCount(progeny)}
    </CardWrapper>
  );
};

export const createCountCard = (countSelectors, displayConfig) => {
  const {
    dataTour,
    title,
    cardComponent: CardComponent = DesktopCard,
    childComponent: ChildComponent = null,
    getIsSuccess = () => false,
    getIsError = () => false,
    getData = ([firstSelectorResult]) => firstSelectorResult,
    progeny,
    logo,
  } = displayConfig;
  return function useSimpleDesktopCardCreator() {
    const dataSelectorResults = [];
    for (const selector of countSelectors) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      dataSelectorResults.push(useSelector(selector));
    }
    return (
      <CardComponent
        title={title}
        // value below takes into account if there's progeny,
        // then subtracts progeny from the count to display eg. 100 hd with 50 progeny as 50 (x50)
        value={
          progeny
            ? getData(dataSelectorResults) - dataSelectorResults.slice(-1)[0]
            : getData(dataSelectorResults)
        }
        isError={getIsError(dataSelectorResults)}
        isSuccess={getIsSuccess(dataSelectorResults)}
        dataTour={dataTour}
        // Line Below Is getting the first value of the last element which is the progeny count
        progeny={progeny && dataSelectorResults.slice(-1)[0]}
        logo={logo}
      >
        {ChildComponent !== null && ChildComponent !== undefined && (
          <ChildComponent />
        )}
      </CardComponent>
    );
  };
};

export const ReceivedDesktopCard = memo(
  createCountCard([selectReceivedCount], {
    dataTour: "summary.received",
    title: "Received",
    logo: <ArrivedLogo />,
  }),
);
export const ReceivedMobileCard = memo(
  createCountCard([selectReceivedCount], {
    dataTour: "summary.received",
    title: "Received",
    cardComponent: MediumMobileCard,
    logo: <ArrivedLogo />,
  }),
);

function getNotDraftedIsSuccess([notDraftedCount]) {
  return notDraftedCount === 0;
}

function getNotDraftedIsError([notDraftedCount]) {
  return notDraftedCount !== 0;
}

export const NotDraftedDesktopCard = memo(
  createCountCard([selectNotDraftedCount], {
    dataTour: "summary.not_drafted",
    title: "Not Drafted",
    getIsSuccess: getNotDraftedIsSuccess,
    getIsError: getNotDraftedIsError,
    logo: <NotDraftedLogo />,
  }),
);

export const NotDraftedMobileCard = memo(
  createCountCard([selectNotDraftedCount], {
    dataTour: "summary.not_drafted",
    title: "Not Drafted",
    cardComponent: MediumMobileCard,
    getIsSuccess: getNotDraftedIsSuccess,
    getIsError: getNotDraftedIsError,
    logo: <NotDraftedLogo />,
  }),
);

function NotPennedHoverCardComponent() {
  const saleRoundIds = useSelector(getCurrentRoundsList);
  const rounds = useSelector(getRounds);
  const notPennedCountByRoundId = useSelector(
    selectNotPennedCountByRoundIdLookup,
  );
  const metrics = saleRoundIds.map(roundId => ({
    title: rounds[roundId]?.name,
    value: notPennedCountByRoundId[roundId] || 0,
  }));
  return <DesktopHoverCard metrics={metrics} />;
}

const NotPennedHoverCard = memo(NotPennedHoverCardComponent);

function getNotPennedIsSuccess([notPennedCount]) {
  return notPennedCount === 0;
}

function getNotPennedIsError([notPennedCount]) {
  return notPennedCount < 0;
}

export const NotPennedDesktopCard = memo(
  createCountCard([selectNotPennedCount, getTotalQuantityProgenyNotPenned], {
    dataTour: "summary.not_penned",
    title: "Not Penned",
    childComponent: NotPennedHoverCard,
    getIsError: getNotPennedIsError,
    getIsSuccess: getNotPennedIsSuccess,
    progeny: true,
    logo: <NotPennedLogo />,
  }),
);

export const NotPennedMobileCard = memo(
  createCountCard([selectNotPennedCount, getTotalQuantityProgenyNotPenned], {
    dataTour: "summary.not_penned",
    title: "Not Penned",
    cardComponent: MediumMobileCard,
    getIsError: getNotPennedIsError,
    getIsSuccess: getNotPennedIsSuccess,
    progeny: true,
    logo: <NotPennedLogo />,
  }),
);

function PennedHoverCardComponent() {
  const saleRoundIds = useSelector(getCurrentRoundsList);
  const rounds = useSelector(getRounds);
  const pennedCountByRoundId = useSelector(selectPennedCountByRoundIdLookup);
  const metrics = saleRoundIds.map(roundId => ({
    title: rounds[roundId]?.name,
    value: pennedCountByRoundId[roundId] || 0,
  }));
  return <DesktopHoverCard metrics={metrics} />;
}

const PennedHoverCard = memo(PennedHoverCardComponent);

function getPennedIsSuccess([pennedCount, receivedCount]) {
  return pennedCount === receivedCount;
}
export const PennedDesktopCard = memo(
  createCountCard(
    [selectPennedCount, selectReceivedCount, getTotalQuantityProgenyPenned],
    {
      dataTour: "summary.penned",
      title: "Penned",
      childComponent: PennedHoverCard,
      getIsSuccess: getPennedIsSuccess,
      progeny: true,
      logo: <PennedLogo />,
    },
  ),
);
export const PennedMobileCard = memo(
  createCountCard(
    [selectPennedCount, selectReceivedCount, getTotalQuantityProgenyPenned],
    {
      dataTour: "summary.penned",
      title: "Penned",
      cardComponent: MediumMobileCard,
      getIsSuccess: getPennedIsSuccess,
      progeny: true,
      logo: <PennedLogo />,
    },
  ),
);

function NoSaleHoverCardComponent() {
  const saleRoundIds = useSelector(getCurrentRoundsList);
  const rounds = useSelector(getRounds);
  const noSaleCountByRoundId = useSelector(selectNoSaleCountByRoundIdLookup);
  const metrics = saleRoundIds.map(roundId => ({
    title: rounds[roundId]?.name,
    value: noSaleCountByRoundId[roundId] || 0,
  }));
  return <DesktopHoverCard metrics={metrics} />;
}

const NoSaleHoverCard = memo(NoSaleHoverCardComponent);

export const NoSaleDesktopCard = memo(
  createCountCard([selectTotalNoSaleCount, getTotalQuantityProgenyNoSale], {
    title: "No Sale",
    dataTour: "summary.no_sale",
    children: NoSaleHoverCard,
    progeny: true,
    logo: <NoSaleLogo />,
  }),
);

export const NoSaleMobileCard = memo(
  createCountCard([selectTotalNoSaleCount, getTotalQuantityProgenyNoSale], {
    title: "No Sale",
    dataTour: "summary.no_sale",
    cardComponent: MediumMobileCard,
    progeny: true,
    logo: <NoSaleLogo />,
  }),
);

function SoldHoverCardComponent() {
  const saleRoundIds = useSelector(getCurrentRoundsList);
  const rounds = useSelector(getRounds);
  const soldCountByRoundId = useSelector(selectSoldCountByRoundIdLookup);
  const metrics = saleRoundIds.map(roundId => ({
    title: rounds[roundId]?.name,
    value: soldCountByRoundId[roundId] || 0,
  }));
  return <DesktopHoverCard metrics={metrics} />;
}

const SoldHoverCard = memo(SoldHoverCardComponent);

export const SoldDesktopCard = memo(
  createCountCard([selectTotalSoldHeadCount, getTotalQuantityProgenySold], {
    dataTour: "summary.sold",
    title: "Sold",
    childComponent: SoldHoverCard,
    progeny: true,
    logo: <SoldLogo />,
  }),
);

export const SoldMobileCard = memo(
  createCountCard([selectTotalSoldHeadCount, getTotalQuantityProgenySold], {
    dataTour: "summary.sold",
    title: "Sold",
    cardComponent: MediumMobileCard,
    progeny: true,
    logo: <SoldLogo />,
  }),
);

function DeliveredHoverCardComponent() {
  const saleRoundIds = useSelector(getCurrentRoundsList);
  const rounds = useSelector(getRounds);
  const deliveredCountByRoundId = useSelector(
    selectDeliveredCountByRoundIdLookup,
  );
  const roundBalanceStatus = useSelector(
    selectRoundIsBalancedByDeploymentSaleIdLookup,
  );
  const deploymentSalesBalanceStatusList = Object.values(roundBalanceStatus);

  const metrics = saleRoundIds.map((roundId, index) => ({
    title: rounds[roundId]?.name,
    value: deliveredCountByRoundId[roundId] || 0,
    isError: deploymentSalesBalanceStatusList.every(
      balanceStatus => !balanceStatus[index],
    ),
  }));
  return <DesktopHoverCard metrics={metrics} />;
}

const DeliveredHoverCard = memo(DeliveredHoverCardComponent);

function getDeliveredIsSuccess([ignored, isBalancedByDeploymentSale]) {
  return Object.values(isBalancedByDeploymentSale).every(Boolean);
}

export const DeliveredDesktopCard = memo(
  createCountCard(
    [
      selectDeliveredCount,
      selectIsBalancedByDeploymentSaleIdLookup,
      getTotalQuantityProgenyDelivered,
    ],
    {
      dataTour: "summary.delivered",
      title: "Delivered",
      childComponent: DeliveredHoverCard,
      getIsSuccess: getDeliveredIsSuccess,
      logo: <DeliveredLogo />,
    },
  ),
);

export const DeliveredMobileCard = memo(
  createCountCard(
    [
      selectDeliveredCount,
      selectIsBalancedByDeploymentSaleIdLookup,
      getTotalQuantityProgenyDelivered,
    ],
    {
      dataTour: "summary.delivered",
      title: "Delivered",
      cardComponent: LargeMobileCard,
      getIsSuccess: getDeliveredIsSuccess,
      logo: <DeliveredLogo />,
    },
  ),
);
