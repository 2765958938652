import { kebabCase } from "lodash";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { IntegrationCredentialAction } from "actions/integrationCredentials";

import { INTEGRATION_CREDENTIAL } from "constants/actionTypes";

import {
  getSettingsRoute,
  openActivateIntegrationCredential,
} from "lib/navigation";
import toast from "lib/toast";

import { getActiveRole } from "selectors";

import { getIntegrationCredentialById } from "selectors/integrationCredentials";

import history from "appHistory";

export function* checkIntegrationActive(
  integrationCredentialId,
  nextFunction,
  props,
) {
  const state = yield select();
  const integrationCredential = getIntegrationCredentialById(
    integrationCredentialId,
  )(state);
  if (integrationCredential.isEnabled) {
    yield put(nextFunction(props));
  } else {
    toast.error("Xero Integration Not Activated", {}, "INTEGRATIONS", () =>
      history.push(getSettingsRoute("Integrations")),
    );
  }
}

function* connectIntegration(action) {
  if (action.options.redirectToActivate) {
    yield put(IntegrationCredentialAction.activate(action.payload.id));
  }
}

function* sendToActivate(action) {
  const { slug } = getActiveRole(yield select());
  openActivateIntegrationCredential(action.id, slug, action.tenantId);
}

function onLoadBusinessRequest() {
  toast.syncing("Updating businesses from external source...", {
    autoClose: false,
  });
}

export function* checkXeroActive(action) {
  const { props, nextFunction } = action;
  yield call(checkIntegrationActive, props.id, nextFunction, props);
}

function onLoadBusinessSuccess() {
  toast.dismiss(kebabCase("Updating businesses from external source..."));
}

function onLoadBusinessFailure() {
  toast.dismiss(kebabCase("Updating businesses from external source..."));
  toast.error("Failed to update external businesses.");
}

export default function* rootSaga() {
  yield takeEvery(INTEGRATION_CREDENTIAL.CREATE.SUCCESS, connectIntegration);

  yield takeEvery(INTEGRATION_CREDENTIAL.ACTIVATE.REQUEST, sendToActivate);

  yield takeEvery(
    INTEGRATION_CREDENTIAL.LOAD_BUSINESSES.REQUEST,
    onLoadBusinessRequest,
  );
  yield takeEvery(
    INTEGRATION_CREDENTIAL.LOAD_BUSINESSES.ACTION,
    checkXeroActive,
  );
  yield takeEvery(
    INTEGRATION_CREDENTIAL.SYNC_LEDGER_ACCOUNTS.ACTION,
    checkXeroActive,
  );
  yield takeEvery(
    INTEGRATION_CREDENTIAL.PUSH_LEDGER_ACCOUNTS.ACTION,
    checkXeroActive,
  );
  yield takeEvery(
    INTEGRATION_CREDENTIAL.SYNC_DOCUMENTS.ACTION,
    checkXeroActive,
  );
  yield takeEvery(
    INTEGRATION_CREDENTIAL.CREATE_BUSINESS.ACTION,
    checkXeroActive,
  );
  yield takeEvery(
    INTEGRATION_CREDENTIAL.LOAD_BUSINESSES.SUCCESS,
    onLoadBusinessSuccess,
  );
  yield takeEvery(
    INTEGRATION_CREDENTIAL.LOAD_BUSINESSES.FAILURE,
    onLoadBusinessFailure,
  );
}
