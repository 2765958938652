import { createSelector } from "reselect";

import {
  SCAN_NLIS_STATUS,
  SellBlockingExceptions,
  TakeBlockingExceptions,
} from "constants/nlis";
import { PropertyStatus } from "constants/properties";
import { UNALLOCATED } from "constants/scanner";

import {
  createLookupCombiner,
  createLookupSelectors,
  getConsignments,
  getProperties,
  getSaleLots,
  getScans,
  selectBuyerHashBySaleLotIdLookup,
  selectConsignmentIdByEidLookup,
  selectEidsByConsignmentIdLookup,
  selectEidsBySaleLotIdLookup,
  selectExceptionsByConsignmentIdLookup,
  selectExceptionsBySaleLotIdLookup,
  selectSaleyardScanEidsByConsignmentIdLookup,
} from "selectors";

export const [selectIsTakeableByEid, getIsTakeableByEid] =
  createLookupSelectors(
    [
      getScans,
      selectConsignmentIdByEidLookup,
      getConsignments,
      selectExceptionsByConsignmentIdLookup,
      getProperties,
    ],
    createLookupCombiner(
      (
        scan,
        consignmentIdByEIDLookup,
        consignments,
        exceptionsByConsignmentId,
        properties,
      ) => {
        if (scan.nlis_take_status !== SCAN_NLIS_STATUS.UNSUBMITTED) {
          return false;
        }
        const consignmentId = consignmentIdByEIDLookup[scan.EID];
        const consignment = consignments[consignmentId];
        const property = properties[consignment?.vendor_property_id] || {};
        const vendorPic = property?.PIC;
        const propertyStatus = property?.activeStatus;

        if (
          !consignmentId ||
          !consignment ||
          !vendorPic ||
          propertyStatus === PropertyStatus.INACTIVE ||
          propertyStatus === PropertyStatus.BLOCKED
        ) {
          return false;
        }

        const exceptions = exceptionsByConsignmentId[consignmentId] || [];
        const noBlockingExceptions = exceptions.every(
          e => !TakeBlockingExceptions.includes(e),
        );

        return (
          scan.nlis_take_status === SCAN_NLIS_STATUS.UNSUBMITTED &&
          noBlockingExceptions &&
          !scan.latest_take_file_id
        );
      },
    ),
  );

export const selectSightableScans = createSelector([getScans], scans =>
  Object.values(scans).filter(scan => !scan.is_nlis_sighted),
);

export const selectTakeableConsignmentIds = createSelector(
  [
    selectEidsByConsignmentIdLookup,
    selectSaleyardScanEidsByConsignmentIdLookup,
    selectIsTakeableByEid,
  ],
  (
    eidsByConsignmentIdLookup,
    saleyardScanEidsByConsignmentIdLookup,
    isTakeableByEid,
  ) =>
    [].concat(
      // Scans in consignment-only (saleyard scan salelots)
      Object.keys(saleyardScanEidsByConsignmentIdLookup).filter(consignmentId =>
        saleyardScanEidsByConsignmentIdLookup[consignmentId].some(
          eid => isTakeableByEid[eid],
        ),
      ),
      // Scans in regular salelots
      Object.keys(eidsByConsignmentIdLookup).filter(consignmentId =>
        eidsByConsignmentIdLookup[consignmentId].some(
          eid => isTakeableByEid[eid],
        ),
      ),
    ),
);
export const [selectIsSellableByEid, getIsSellableByEid] =
  createLookupSelectors(
    [
      getScans,
      getSaleLots,
      selectExceptionsByConsignmentIdLookup,
      selectExceptionsBySaleLotIdLookup,
    ],
    createLookupCombiner(
      (scan, saleLots, exceptionsByConsignmentId, exceptionsBySaleLotId) => {
        const saleLot = saleLots[scan.sale_lot_id];
        if (!scan.sale_lot_id || scan.sale_lot_id === UNALLOCATED || !saleLot) {
          return false;
        }
        const consignmentExceptions =
          exceptionsByConsignmentId[saleLot.consignment_id];
        const saleLotExceptions = exceptionsBySaleLotId[saleLot.id];

        const noBlockingTakeExceptions = consignmentExceptions?.every(
          e => !TakeBlockingExceptions.includes(e),
        );
        const noBlockingSellExceptions = saleLotExceptions?.every(
          e => !SellBlockingExceptions.includes(e),
        );

        return (
          saleLot.destination_property_id &&
          noBlockingTakeExceptions &&
          noBlockingSellExceptions &&
          (scan.nlis_take_status === SCAN_NLIS_STATUS.GOOD ||
            scan.nlis_take_status === SCAN_NLIS_STATUS.NEEDS_CORRECTING) &&
          !scan.latest_sell_file_id
        );
      },
    ),
  );

export const selectSellableSaleLotIds = createSelector(
  [selectEidsBySaleLotIdLookup, selectIsSellableByEid],
  (eidsBySaleLotIdLookup, isSellableByEid) =>
    Object.keys(eidsBySaleLotIdLookup).filter(saleLotId =>
      eidsBySaleLotIdLookup[saleLotId].some(eid => isSellableByEid[eid]),
    ),
);

export const selectSellableSaleLotIdsByBuyerHashLookup = createSelector(
  [selectSellableSaleLotIds, selectBuyerHashBySaleLotIdLookup],
  (sellableSaleLotIds, buyerHashBySaleLotIdLookup) => {
    return sellableSaleLotIds.reduce((acc, saleLotId) => {
      const buyerHash = buyerHashBySaleLotIdLookup[saleLotId];
      if (acc[buyerHash]) {
        acc[buyerHash] = acc[buyerHash].concat(saleLotId);
      } else {
        acc[buyerHash] = [saleLotId];
      }
      return acc;
    }, {});
  },
);

export const [selectIsP2PTransferrableByEidLookup, getIsP2PTransferrableByEid] =
  createLookupSelectors(
    [
      getScans,
      getSaleLots,
      selectExceptionsByConsignmentIdLookup,
      selectExceptionsBySaleLotIdLookup,
    ],
    createLookupCombiner(
      (scan, saleLots, exceptionsByConsignmentId, exceptionsBySaleLotId) => {
        const saleLot = saleLots[scan.sale_lot_id];

        if (!scan.sale_lot_id || scan.sale_lot_id === UNALLOCATED || !saleLot) {
          return false;
        }
        const consignmentExceptions =
          exceptionsByConsignmentId[saleLot.consignment_id];
        const saleLotExceptions = exceptionsBySaleLotId[saleLot.id];

        const noBlockingTakeExceptions = consignmentExceptions?.every(
          e => !TakeBlockingExceptions.includes(e),
        );
        const noBlockingSellExceptions = saleLotExceptions?.every(
          e => !SellBlockingExceptions.includes(e),
        );

        return (
          saleLot.destination_property_id &&
          noBlockingTakeExceptions &&
          noBlockingSellExceptions &&
          scan.nlis_p2p_status === SCAN_NLIS_STATUS.UNSUBMITTED &&
          !scan.latest_p2p_file_id
        );
      },
    ),
  );
