export const WATCHER_STATES = {
  SEND_TO_SALE: "SEND_TO_SALE",
  PROMPT_FOR_EMAIL: "PROMPT_FOR_EMAIL",
  EMAIL_EXISTS: "EMAIL_EXISTS",
  SALE_NOT_FOUND: "SALE_NOT_FOUND",
  INVALID_VERIFICATION: "INVALID_VERIFICATION",
  INVALID_EMAIL: "INVALID_EMAIL",
  ERROR: "ERROR",
  JWT_REFRESH_REQUIRED: "JWT_REFRESH_REQUIRED",
};

export const ViewModes = {
  DEFAULT: "DEFAULT",
  RING_SELLING_BY_PRICE: "RING_SELLING_BY_PRICE",
  RING_SELLING_BY_WEIGHT: "RING_SELLING_BY_WEIGHT",
  RING_SELLING_BY_PRICE_THEN_WEIGHT: "RING_SELLING_BY_PRICE_THEN_WEIGHT",
  RING_SELLING_BRIEF_BY_PRICE: "RING_SELLING_BRIEF_BY_PRICE",
  RING_SELLING_BRIEF_BY_WEIGHT: "RING_SELLING_BRIEF_BY_WEIGHT",
  RING_SELLING_BRIEF_BY_PRICE_THEN_WEIGHT:
    "RING_SELLING_BRIEF_BY_PRICE_THEN_WEIGHT",
  BIG_SCREEN: "BIG_SCREEN",
  SALE_PREVIEW: "SALE_PREVIEW",
  ON_SCALES: "ON_SCALES",
  LAST_WEIGHED: "LAST_WEIGHED",
  LAST_WEIGHED_DETAILED: "LAST_WEIGHED_DETAILED",
  STUD_WATCHER: "STUD_WATCHER",
  STUD_WATCHER_WITH_BUYER_WAY: "STUD_WATCHER_WITH_BUYER_WAY",
};

export const ViewModeConfig = {
  [ViewModes.DEFAULT]: {
    label: "Default",
    isPublic: true,
  },
  [ViewModes.RING_SELLING_BY_PRICE]: {
    label: "Ring ($)",
    isPublic: true,
  },
  [ViewModes.RING_SELLING_BY_WEIGHT]: {
    label: "Ring (kg)",
    isPublic: true,
  },
  [ViewModes.RING_SELLING_BY_PRICE_THEN_WEIGHT]: {
    label: "Ring (kg, $)",
    isPublic: true,
  },
  [ViewModes.RING_SELLING_BRIEF_BY_PRICE]: {
    label: "Ring Brief ($)",
    isPublic: true,
  },
  [ViewModes.RING_SELLING_BRIEF_BY_WEIGHT]: {
    label: "Ring Brief (kg)",
    isPublic: true,
  },
  [ViewModes.RING_SELLING_BRIEF_BY_PRICE_THEN_WEIGHT]: {
    label: "Ring Brief (kg, $)",
    isPublic: true,
  },
  [ViewModes.BIG_SCREEN]: {
    label: "Big Screen",
    isPublic: true,
  },
  [ViewModes.SALE_PREVIEW]: {
    label: "Sale Preview",
    isPublic: true,
  },
  [ViewModes.STUD_WATCHER]: {
    label: "Stud Watcher",
    isPublic: false,
  },
  [ViewModes.STUD_WATCHER_WITH_BUYER_WAY]: {
    label: "Stud Watcher w/Account",
    isPublic: false,
  },
  [ViewModes.ON_SCALES]: {
    label: "On Scales",
    isPublic: false,
  },
  [ViewModes.LAST_WEIGHED]: {
    label: "Last Weighed",
    isPublic: false,
  },
  [ViewModes.LAST_WEIGHED_DETAILED]: {
    label: "Last Weighed (Detailed)",
    isPublic: false,
  },
};
