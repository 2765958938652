import React from "react";

import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { TitleText } from "components/Text";

import { AgGridTables } from "constants/aggrid";
import { ruleBookColumns } from "constants/columnDefinitions/ruleBook";
import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";

import { selectRuleBooksAggridData } from "selectors/aggrid/ruleBooks";

import { useDebounceSelector } from "hooks";
import { useHasCreateRuleBookPermission } from "hooks/useHasCreateRuleBookPermission";

export function RuleBooksManager() {
  const getRowId = params => params.data.ruleBook.id;

  const openCreateRuleBook = () => openModalLink(ModalTypes.RuleBook);

  const ruleBooks = useDebounceSelector(selectRuleBooksAggridData);

  const hasCreateRuleBookPermission = useHasCreateRuleBookPermission();

  const additionalActions = [
    {
      title: "Create Rule Book",
      dataTour: "createRuleBook",
      onClick: openCreateRuleBook,
      disabled: !hasCreateRuleBookPermission,
    },
  ];

  return (
    <>
      <Box p={2}>
        <Grid container>
          <TitleText>Billing Rule Books</TitleText>
        </Grid>
      </Box>
      <AgGridTable
        columnDefs={ruleBookColumns}
        additionalActions={additionalActions}
        rowData={ruleBooks}
        tableName={AgGridTables.RULE_BOOKS}
        getRowId={getRowId}
        headerJustifyContent="space-between"
      />
    </>
  );
}
