import React, { useEffect, useMemo } from "react";

import { Paper, Tab, Tabs } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { NLISCredentialType } from "constants/nlis";

import { getSettingsRoute } from "lib/navigation";

import {
  getIsLivestockAgent,
  getIsSaleyardAdmin,
  getIsScaleOperator,
} from "selectors";

import history from "appHistory";
import { useGetSelectedTabFromRoute } from "hooks/useGetSelectedTabFromRoute";

import { AgentCredentials } from "./Agent";
import { SaleyardCredentials } from "./Saleyard";
import { ScaleOperatorCredentials } from "./ScaleOperator";

const TabByCredentialType = {
  [NLISCredentialType.AGENT]: {
    label: "Agent",
    path: "agent",
    value: NLISCredentialType.AGENT,
  },
  [NLISCredentialType.SALEYARD]: {
    label: "Saleyard",
    path: "saleyard",
    value: NLISCredentialType.SALEYARD,
  },
  [NLISCredentialType.SCALE_OPERATOR]: {
    label: "Scale Operator",
    path: "scale-operator",
    value: NLISCredentialType.SCALE_OPERATOR,
  },
};

export const NLISPanel = () => {
  const match = useRouteMatch();
  const isScaleOperator = useSelector(getIsScaleOperator);
  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);
  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const tabs = useMemo(() => {
    const result = [];
    if (isScaleOperator) {
      result.push(TabByCredentialType[NLISCredentialType.SCALE_OPERATOR]);
    } else if (isSaleyardAdmin) {
      result.push(TabByCredentialType[NLISCredentialType.SALEYARD]);
    } else if (isLivestockAgent) {
      result.push(TabByCredentialType[NLISCredentialType.AGENT]);
      // Some Agents operate their own yards and therefore need to perform actions
      // only available to Saleyard type NLIS Creds (Take/Sell, etc.)
      result.push(TabByCredentialType[NLISCredentialType.SALEYARD]);
    }
    return result;
  }, [isScaleOperator, isSaleyardAdmin, isLivestockAgent]);

  const currentTab = useGetSelectedTabFromRoute(tabs);

  useEffect(() => {
    // If we aren't on a tab, open the first.
    if (!currentTab && tabs[0]?.path) {
      history.push(getSettingsRoute(`NLIS/${tabs[0].path}`));
    }
  }, [currentTab, match.path, tabs]);

  const handleChange = (event, newValue) => {
    history.push(getSettingsRoute(`NLIS/${newValue}`));
  };

  return (
    <Paper>
      <Tabs variant="scrollable" onChange={handleChange} value={currentTab}>
        {tabs.map(({ label, path }) => (
          <Tab key={path} label={label} value={path} />
        ))}
      </Tabs>

      <Switch>
        <Route path={`${match.path}agent`} component={AgentCredentials} />
        <Route path={`${match.path}saleyard`} component={SaleyardCredentials} />
        <Route
          path={`${match.path}scale-operator`}
          component={ScaleOperatorCredentials}
        />
        <Route
          location={
            // Scale Operators are forced here when they have never entered credentials however they are not
            // redirected here, intead the component subtree in mounted in place of the standard saleyard home meaning
            // none of the normal parent routes and matched apply
            `/app/`
          }
          component={ScaleOperatorCredentials}
        />
      </Switch>
    </Paper>
  );
};
