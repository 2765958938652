import { createSelector } from "reselect";

import {
  getConsignments,
  getSaleLots,
  selectAgencyBusinessByBusinessId,
  selectAgencyIdByConsignmentIdLookup,
  selectVendorIdBySaleLotIdLookup,
} from "selectors";

// Gets master business by sale lot id, gets consignment from salelot, then masterBusiness from consignment,
// and deploymentSale from the consignment, checks if the deployment sale agent id equals masterbusiness id and return,
// or if masterbusiness has supplementary id return those masterbusinesses
export const selectDeploymentBusinessVendorBySaleLotIdLookup = createSelector(
  [
    getSaleLots,
    selectVendorIdBySaleLotIdLookup,
    selectAgencyBusinessByBusinessId,
    selectAgencyIdByConsignmentIdLookup,
  ],
  (saleLots, vendorIdLkp, businessLkp, agencyIdLkp) =>
    Object.values(saleLots).reduce((acc, saleLot) => {
      const vendorId = vendorIdLkp[saleLot.id];
      const agencyId = agencyIdLkp[saleLot.consignment_id];
      const relatedVendors = businessLkp[vendorId] || {};
      acc[saleLot.id] = relatedVendors[agencyId] || relatedVendors.null;
      return acc;
    }, {}),
);

export const selectDeploymentBusinessVendorByConsignmentIdLookup =
  createSelector(
    [
      getConsignments,
      selectAgencyBusinessByBusinessId,
      selectAgencyIdByConsignmentIdLookup,
    ],
    (consignments, businessLkp, agencyIdLkp) =>
      Object.values(consignments).reduce((acc, consignment) => {
        const vendorId = consignment.vendor_id;
        const agencyId = agencyIdLkp[consignment.id];
        const relatedVendors = businessLkp[vendorId] || {};
        acc[consignment.id] = relatedVendors[agencyId] || relatedVendors.null;
        return acc;
      }, {}),
  );
