import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

export const baseVendorCommissionBandSerializeMap = {
  id: "id",
  saleSubTypeId: "sale_sub_type_id",
  commissionType: "commission_type",
  commissionValue: "commission_value",
};

const vendorCommissionBandSerializeMap = {
  ...baseVendorCommissionBandSerializeMap,
  deploymentBusinessId: "deployment_business_id",
  deploymentId: "deployment_id",
};

export const serializeVendorCommissionBand = buildSerializer(
  vendorCommissionBandSerializeMap,
  "vendor commission band",
);

export const deserializeVendorCommissionBand = buildDeserializer(
  vendorCommissionBandSerializeMap,
  "vendor commission band",
);
