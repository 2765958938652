// For Hooks sale, show drafting information.
import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { SelectField, TextArea } from "components/Form/FormikControls";

import { getSpeciesAttributeOptions } from "selectors";

export function DraftingForm() {
  const speciesAttributes = useSelector(getSpeciesAttributeOptions);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <SelectField
          label="Breed"
          name="saleLot.breed_id"
          options={speciesAttributes.breeds}
          isClearable
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          label="Sex"
          name="saleLot.sex_id"
          options={speciesAttributes.sexes}
          isClearable
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          label="Grade"
          name="saleLot.grade_id"
          options={speciesAttributes.grades}
          isClearable
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SelectField
          label="Age"
          name="saleLot.age_id"
          options={speciesAttributes.ages}
          isClearable
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SelectField
          label="Category"
          name="saleLot.category_id"
          options={speciesAttributes.categories}
          isClearable
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextArea label="Notes" name="note" />
      </Grid>
    </>
  );
}
