import { sortBy } from "lodash";
import { createSelector } from "reselect";

import { SaleTypes } from "constants/sale";

import { EMPTY_ARRAY } from "lib";

import { attributeOrderSort } from "lib/speciesAttributes";

import {
  getDeploymentAttributes,
  getDeployments,
  selectActiveProducts,
  selectCurrentDeploymentIds,
  getCurrentSale,
  getSpeciesAttributes,
  getCurrentOrFirstSale,
} from "selectors";

export const getActiveDeploymentAttributes = createSelector(
  [getDeploymentAttributes],
  deploymentAttributes =>
    Object.entries(deploymentAttributes).reduce(
      (acc, [attributeName, attributeValues]) => {
        acc[attributeName] = Object.values(attributeValues)
          .filter(a => !a.deprecated && !a.deleted)
          .sort(attributeOrderSort);
        return acc;
      },
      {},
    ),
);

export const selectDeploymentBreedsByDeploymentIdLookup = createSelector(
  [
    getCurrentOrFirstSale,
    getDeploymentAttributes,
    getSpeciesAttributes,
    selectCurrentDeploymentIds,
  ],
  (sale, deploymentAttributes, speciesAttributes, currentDeploymentIds) => {
    const defaults = currentDeploymentIds.reduce((acc, cur) => {
      acc[cur] = [];
      return acc;
    }, {});

    const getBreedQuickSelectKey = saleType => {
      switch (saleType) {
        case SaleTypes.PADDOCK:
          return "quick_select_paddock";
        case SaleTypes.OVER_HOOKS:
          return "quick_select_hooks";
        default:
          return "quick_select";
      }
    };

    const breedQuickSelectKey = getBreedQuickSelectKey(sale.sale_type);

    return sortBy(Object.values(deploymentAttributes.breeds), "order").reduce(
      (acc, db) => {
        const masterBreed = speciesAttributes.breeds[db.breed_id] || {
          name: "Loading Breeds",
        };
        if (
          masterBreed &&
          masterBreed.species_id === sale.species_id &&
          db.is_used
        ) {
          const quickCode = db[breedQuickSelectKey]
            ? db.quick_code
              ? db.quick_code
              : masterBreed.name
            : null;

          const entry = {
            label: masterBreed.name,
            value: db.breed_id,
            quick_code: quickCode,
          };

          if (Array.isArray(acc[db.deployment_id])) {
            acc[db.deployment_id].push(entry);
          } else {
            acc[db.deployment_id] = [entry];
          }
        }
        return acc;
      },
      defaults,
    );
  },
);

export const selectDeploymentAgesByDeploymentIdLookup = createSelector(
  [getCurrentSale, getDeploymentAttributes, getSpeciesAttributes],
  (sale, deploymentAttributes, speciesAttributes) =>
    sortBy(Object.values(deploymentAttributes.ages), "order").reduce(
      (acc, db) => {
        if (
          speciesAttributes.ages[db.age_id].species_id === sale.species_id &&
          db.is_used
        ) {
          const quickCode = db.quick_select
            ? db.quick_code
              ? db.quick_code
              : speciesAttributes.ages[db.age_id].name
            : null;

          const entry = {
            label: speciesAttributes.ages[db.age_id].name,
            value: db.age_id,
            quick_code: quickCode,
          };

          if (typeof acc[db.deployment_id] === "undefined") {
            acc[db.deployment_id] = [entry];
          } else {
            acc[db.deployment_id].push(entry);
          }
        }
        return acc;
      },
      {},
    ),
);

export const selectDeploymentSexesByDeploymentIdLookup = createSelector(
  [getCurrentSale, getDeploymentAttributes, getSpeciesAttributes],
  (sale, deploymentAttributes, speciesAttributes) =>
    sortBy(Object.values(deploymentAttributes.sexes), "order").reduce(
      (lookup, sex) => {
        if (
          speciesAttributes.sexes[sex.sex_id].species_id === sale.species_id &&
          sex.is_used
        ) {
          const quickCode = sex.quick_select
            ? sex.quick_code || speciesAttributes.sexes[sex.sex_id].name
            : null;

          const entry = {
            label: speciesAttributes.sexes[sex.sex_id].name,
            value: sex.sex_id,
            quick_code: quickCode,
          };

          if (typeof lookup[sex.deployment_id] === "undefined") {
            lookup[sex.deployment_id] = [entry];
          } else {
            lookup[sex.deployment_id].push(entry);
          }
        }
        return lookup;
      },
      {},
    ),
);

export const getDeploymentBreedOptionsByDeploymentId = deploymentId => state =>
  selectDeploymentBreedsByDeploymentIdLookup(state)[deploymentId] ||
  EMPTY_ARRAY;

export const getDeploymentAgeOptionsByDeploymentId = deploymentId => state =>
  selectDeploymentAgesByDeploymentIdLookup(state)[deploymentId] || EMPTY_ARRAY;

export const selectAllAttributes = createSelector(
  [getDeploymentAttributes, selectActiveProducts],
  (deploymentAttributes, products) => ({
    ...deploymentAttributes,
    products,
  }),
);

export const selectAllAttributesByDeploymentIdLookup = createSelector(
  [getDeployments, selectAllAttributes],
  (deployments, allAttributes) =>
    Object.keys(deployments).reduce((acc, deploymentId) => {
      acc[deploymentId] = Object.keys(allAttributes).reduce(
        (attrAcc, attribute) => {
          attrAcc[attribute] = Object.values(allAttributes[attribute]).filter(
            a => `${a.deployment_id}` === deploymentId,
          );
          return attrAcc;
        },
        {},
      );
      return acc;
    }, {}),
);
