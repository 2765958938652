import React from "react";

import { retry } from "lib/lazy";

const AgGridReact = React.lazy(() =>
  retry(() => import("components/AgGrid/shims/AgGridReact")),
);

const AgGrid = props => {
  // The fallback should not actually be seen - we preload aggrid anyway :D
  return (
    <React.Suspense fallback="Loading...">
      <AgGridReact {...props} />
    </React.Suspense>
  );
};

AgGrid.propTypes = AgGridReact.propTypes;

export default AgGrid;
