import React, { useEffect } from "react";

import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { requestReports } from "actions";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import MessageBox from "components/MessageBox";
import { AgentReports } from "components/SaleReports";
import SaleReportsTable from "components/SaleReportsTable";

import { userTypes } from "constants/users";

import toast from "lib/toast";

import {
  currentSaleSelector,
  getIsBusinessUser,
  getIsOnline,
  getReportFavourites,
  getSaleLotsBySale,
  prepareAgentReportData,
  prepareBusinessReportData,
  selectIsUserOfType,
} from "selectors";

import { isLoadingSaleReports } from "selectors/loading";

const Reports = ({
  requestReports,
  isBusinessUser,
  isFetching,
  saleLots,
  saleId,
  saleyard,
  businessReportData,
  agentReportData,
  isNonBusinessReportUser,
  showNLISActions,
  reportFavourites,
}) => {
  useEffect(() => {
    requestReports(saleyard, saleId);
  }, [requestReports, saleyard, saleId]);

  if (isFetching) {
    return (
      <MessageBox>
        <LoadingSpinner size={24} />
      </MessageBox>
    );
  }

  if (isBusinessUser) {
    const { links } = businessReportData;
    return <SaleReportsTable reportData={saleLots} links={links} />;
  } else if (isNonBusinessReportUser) {
    if (!isFetching && saleLots.length === 0) {
      toast.info(
        "More reports will become available once sale lots have been created.",
      );
    }
    const { filters, reportData } = agentReportData;
    return (
      <AgentReports
        reportData={reportData}
        filters={filters}
        showNLISActions={showNLISActions}
        reportFavourites={reportFavourites}
      />
    );
  } else {
    return <MessageBox>No Access</MessageBox>;
  }
};

const mapStateToProps = state => {
  const sale = currentSaleSelector(state);

  const isBusinessUser = getIsBusinessUser(state);
  const isNonBusinessReportUser = selectIsUserOfType([
    userTypes.LIVESTOCK_AGENT,
    userTypes.SALEYARD_ADMIN,
    userTypes.SCALE_OPERATOR,
  ])(state);
  const showNLISActions = selectIsUserOfType([
    userTypes.SALEYARD_ADMIN,
    userTypes.SCALE_OPERATOR,
  ])(state);

  const businessReportData = isNonBusinessReportUser
    ? null
    : prepareBusinessReportData(state);

  const agentReportData = isBusinessUser ? null : prepareAgentReportData(state);

  return {
    saleLots: getSaleLotsBySale(state),
    saleyard: sale.saleyard_name,
    saleId: sale.livestocksale_id,
    isFetching: isLoadingSaleReports(state),
    isBusinessUser,
    isNonBusinessReportUser,
    showNLISActions,
    businessReportData,
    agentReportData,
    reportFavourites: getReportFavourites(state),
  };
};

const mapDispatchToProps = {
  requestReports,
};

const ReportComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(Reports);

const OfflineWrapper = React.memo(() => {
  const isOnline = useSelector(getIsOnline);
  if (!isOnline) {
    return <MessageBox>Reports are not available offline.</MessageBox>;
  } else {
    return <ReportComponent />;
  }
});

export default OfflineWrapper;
