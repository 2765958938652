import React from "react";

import { useTheme } from "hooks";

export const FrontGateLockedBackGateLocked = ({ color, size }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.black;

  const svgSize = size || theme.fontSizes.gamma;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox="0 0 27500 27500"
    >
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6414.6"
        y1="6216.9"
        x2="6414.6"
        y2="17772.4"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21482.2"
        y1="9983.8"
        x2="21482.2"
        y2="6216.9"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6414.6"
        y1="21284.6"
        x2="6414.6"
        y2="17772.4"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13948.4"
        y1="21284.6"
        x2="21482.2"
        y2="21284.6"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21482.2"
        y1="21284.6"
        x2="21482.2"
        y2="10322.9"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13948.4"
        y1="6216.9"
        x2="21482.2"
        y2="6216.9"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6414.6"
        y1="21284.6"
        x2="13948.4"
        y2="21284.6"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6414.6"
        y1="6216.9"
        x2="13948.4"
        y2="6216.9"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6414.6"
        cy="17772.4"
        r="339"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6414.6"
        cy="6216.9"
        r="339"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6414.6"
        cy="21284.6"
        r="339"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13948.4"
        cy="21284.6"
        r="339"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13948.4"
        cy="6216.9"
        r="339"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21482.2"
        cy="9983.8"
        r="339"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21482.2"
        cy="21284.6"
        r="339"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21482.2"
        cy="6216.9"
        r="339"
      />
      <path
        fill={svgColor}
        d="M23750.4 13586.3c-13.9-120.5-1.5-314-1.4-442.6 0.1-155.5-6.7-258.7 52.8-391.5 47.8-106.7 114.5-177.8 203.6-240.5 203.6-143.2 467.5-136.2 671.7 2.4 239.7 162.7 252.1 384 252 637.3 0 133.1 8.5 306.2-2.5 434.8l-1176.1 0.1zm-652-624.8l-1.8 628.7-206.4-1.5c-167.6-10.8-301.9 118-349.4 224.9-58.8 132.4-37 375.9-37.2 533.2-0.5 380.5 0.4 761.1-0.1 1141.6-0.6 508.7 396.3 455.9 693.4 455.9l2283.2-0.2c220.8 0 526.1 45.6 648.6-212.9 40.9-86.3 44.8-139.5 44.7-242.7-0.5-380.5-0.1-761.1 0.1-1141.6 0.1-165 23.1-398.3-40.6-537.9-47-102.9-185.8-230.8-347.3-219.7l-204.7-1.8-0.8-622.7c12.4-226.8-102.9-515.9-231.4-693-39.5-54.4-99.5-128.2-140.9-165.5l-168-138.6c-189.3-132.5-458.4-215.4-701.3-215.1-319.9 0.4-554.8 105.1-788.3 281.3l-221.6 238.2c-122.7 150.8-241.4 473.3-230.2 689.7z"
      />
      <path
        stroke={svgColor}
        d="M2938.7 13547.5c-13.9-120.5-1.5-314-1.4-442.6 0.1-155.5-6.7-258.7 52.8-391.5 47.8-106.7 114.5-177.8 203.6-240.5 203.6-143.2 467.4-136.2 671.7 2.4 239.7 162.7 252.1 384 252 637.3 0 133.1 8.5 306.2-2.5 434.8l-1176.1 0.1zm-652-624.8l-1.8 628.7-206.3-1.5c-167.6-10.8-301.9 118-349.4 224.8-58.8 132.4-37 375.9-37.2 533.2-0.5 380.5 0.4 761.1-0.1 1141.6-0.6 508.7 396.3 455.9 693.4 455.9l2283.2-0.2c220.8 0 526.1 45.6 648.6-212.9 40.9-86.3 44.8-139.5 44.7-242.7-0.5-380.5-0.1-761.1 0.1-1141.6 0.1-165 23.1-398.3-40.6-537.9-47-102.9-185.8-230.8-347.3-219.7l-204.7-1.8-0.8-622.7c12.4-226.8-102.9-515.9-231.4-693-39.5-54.4-99.5-128.2-140.9-165.5l-168-138.6c-189.3-132.6-458.4-215.4-701.3-215.1-319.8 0.4-554.8 105.1-788.3 281.3l-221.6 238.2c-122.7 150.8-241.4 473.3-230.2 689.7z"
      />
    </svg>
  );
};

export default FrontGateLockedBackGateLocked;
