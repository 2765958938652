import React from "react";

import { Grid } from "@material-ui/core";
import { Formik, useField } from "formik";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { patchSaleLot } from "actions";

import { Button, SecondaryButton } from "components/Form";
import { Error } from "components/Form/FormikControls/Error";
import { FullPageForm } from "components/Form/FormikControls/layout";
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  ZoomyDialog,
} from "components/MaterialDialog";

import { calculateTotalPriceCents, calculateUnitPriceDollars } from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { closeAllHashModals, returnToLocation } from "lib/navigation";
import { getProgenyDisplayCount } from "lib/saleLot";

import { getSaleLotById, getAuctionPenById } from "selectors";

const Errors = styled(Error)`
  color: ${({ theme }) => theme.colors.deleteRed};
  text-align: center;
  font-size: 2em;
`;

const Label = styled.p`
  font-size: 2em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const ProgenyLabel = styled(Label)`
  font-size: 1.5em;
`;

const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  text-align: center;
  font-size: 3em;
  background: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const DiffValues = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}px) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    align-content: center;
  }
`;

const GridContainer = styled(Grid)`
  margin-top: -50px;
`;

const NumberInput = ({ name, type, placeholder, disabled, onFocus, style }) => {
  const [field] = useField(name);
  return (
    <Input
      {...field}
      name={name}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      onFocus={onFocus}
      style={style}
    />
  );
};

const DeliverSaleLotModal = ({ saleLotId, returnTo }) => {
  const saleLot = useSelector(getSaleLotById(saleLotId)) || {};
  const { auction_pen_id: auctionPenId, quantity, quantityProgeny } = saleLot;
  const pen = useSelector(getAuctionPenById(auctionPenId));
  const penName = getAuctionPenDisplayName(pen);

  const dispatch = useDispatch();

  const onClose = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const updateInCount = values => {
    dispatch(
      patchSaleLot(
        {
          // N.B. the `inCount` fields are disabled, so we use the values which the user has entered in the `outCount` and `progenyOutCount` fields
          // There is a dedicated button to update the in counts that fires this function
          quantity: values.outCount,
          quantityProgeny: values.progenyOutCount,
          id: saleLotId,
          total_price_cents: calculateTotalPriceCents({
            total_mass_grams: saleLot.total_mass_grams,
            pricing_type_id: saleLot.pricing_type_id,
            quantity: values.outCount,
            unitPrice: calculateUnitPriceDollars(saleLot),
          }),
        },
        { changeReason: "Update in count" },
      ),
    );
  };

  const deliverSaleLot = values => {
    dispatch(
      patchSaleLot(
        {
          quantity_delivered:
            Number(values.outCount) + Number(values.progenyOutCount),
          quantityProgeny: values.progenyOutCount,
          id: saleLotId,
        },
        { actionText: "delivered", changeReason: "Delivered" },
      ),
    );
    onClose();
  };

  const initialValues = {
    outCount: saleLot.quantity,
    inCount: saleLot.quantity,
    progenyInCount: saleLot.quantityProgeny,
    progenyOutCount: saleLot.quantityProgeny,
  };

  const validationSchema = Yup.object({
    outCount: Yup.number()
      .typeError("Must be a number")
      .test(
        "correct",
        val => {
          if (val.value > quantity) {
            return `+${Math.abs(val.value - quantity)}`;
          } else {
            return val.value - quantity;
          }
        },
        val => val === saleLot.quantity,
      ),
    progenyOutCount: Yup.number()
      .typeError("Must be a number")
      .test(
        "correct",
        val => {
          if (val.value > quantityProgeny) {
            return `+${Math.abs(val.value - quantityProgeny)}`;
          } else {
            return val.value - quantityProgeny;
          }
        },
        val => val === saleLot.quantityProgeny,
      ),
  });
  return (
    <ZoomyDialog open onClose={onClose} scroll="paper">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, errors }) => (
          <FullPageForm>
            <DialogTitle onClose={onClose}>
              Delivering{" "}
              {Number(values.outCount) +
                (values.progenyOutCount
                  ? Number(values.progenyOutCount)
                  : 0)}{" "}
              hd from Pen {penName}
            </DialogTitle>
            <DialogContent dividers form>
              <DiffValues>
                <GridContainer
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Grid item xs={6}>
                    <Label>In</Label>
                    <NumberInput
                      name="inCount"
                      type="number"
                      placeholder={saleLot.quantity}
                      disabled
                    />
                    {saleLot.quantityProgeny > 0 && (
                      <>
                        <ProgenyLabel>Progeny In</ProgenyLabel>
                        <NumberInput
                          name="progenyInCount"
                          type="number"
                          placeholder={saleLot.quantityProgeny}
                          disabled
                          style={{ fontSize: "24px" }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Label>Out</Label>
                    <NumberInput
                      name="outCount"
                      type="tel"
                      onFocus={e => e.currentTarget.select()}
                    />
                    {saleLot.quantityProgeny > 0 && (
                      <>
                        <ProgenyLabel>Progeny Out</ProgenyLabel>
                        <NumberInput
                          name="progenyOutCount"
                          type="tel"
                          onFocus={e => e.currentTarget.select()}
                          style={{ fontSize: "24px" }}
                        />
                      </>
                    )}
                  </Grid>
                </GridContainer>
              </DiffValues>
              {errors.outCount ? (
                <Errors className="error">{errors.outCount}</Errors>
              ) : null}

              {errors.progenyOutCount ? (
                <p style={{ textAlign: "center" }}>
                  Progeny
                  <Errors className="error">{errors.progenyOutCount}</Errors>
                </p>
              ) : null}
            </DialogContent>
            <DialogActions>
              <SecondaryButton
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </SecondaryButton>
              <Button
                type="button"
                onClick={() => updateInCount(values)}
                disabled={!errors.outCount}
              >
                Update in count
              </Button>
              <Button
                data-tour="deliver"
                type="button"
                onClick={() => deliverSaleLot(values)}
              >
                {`Deliver ${values.outCount} ${getProgenyDisplayCount(
                  Number(values.progenyOutCount),
                )} Hd`}
              </Button>
            </DialogActions>
          </FullPageForm>
        )}
      </Formik>
    </ZoomyDialog>
  );
};

export default DeliverSaleLotModal;
