import React from "react";

import { Grid } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Column } from "components/Layout";

import { VENDOR_LABELS } from "constants/clearingSaleAttributes";

import {
  getBusinessById,
  getConsignmentById,
  getSaleLotIdsByConsignmentId,
  getVendorIdByConsignmentId,
} from "selectors";

import {
  BusinessGSTStatus,
  ConsignmentLocality,
  DeploymentBusinessAddress,
  DeploymentBusinessName,
} from "./BusinessComponents";
import { ConsignmentMenu } from "./CardOptionsMenu";
import { CommonLabels } from "./CommonLabels";
import { ExpandablePaper } from "./ExpandablePaper";
import { ConsignmentSaleLotSummary } from "./SaleLotSummary";
import { VendorSaleLotCards } from "./VendorSaleLotCards";

export const Thumbnail = styled.img`
  max-height: auto;
  width: 100%;
`;

// https://stackoverflow.com/questions/49797264/full-width-material-ui-grid-not-working-as-it-should
const GridItemXs12 = props => <Grid item xs={12} {...props} />;

export const VendorCard = React.memo(({ id, buyerId = null, disableAdd }) => {
  const [expanded, setExpanded] = React.useState(false);

  const businessId = useSelector(getVendorIdByConsignmentId(id));

  const consignment = useSelector(getConsignmentById(id));

  const saleLotIds = useSelector(getSaleLotIdsByConsignmentId(id)) || [];

  const businessName =
    useSelector(state => getBusinessById(businessId)(state)?.name) || "";

  return (
    <Column data-tour={businessName} margin={buyerId ? 1 : 2}>
      <ExpandablePaper
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      >
        <Grid container>
          <Grid item xs={4} container alignItems="center">
            <DeploymentBusinessName businessId={businessId} />
          </Grid>

          <Grid item xs={2} container alignItems="center">
            <ConsignmentLocality consignmentId={id} />
          </Grid>

          <Grid item xs={4} container alignItems="center">
            <ConsignmentSaleLotSummary
              consignmentId={id}
              buyerId={buyerId}
              condensed
            />
          </Grid>

          <Grid
            item
            xs={2}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <ConsignmentMenu consignmentId={id} disableAdd={disableAdd} />
          </Grid>
          <Grid item xs={12}>
            <CommonLabels saleLotIds={saleLotIds} limitTo={VENDOR_LABELS} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Collapse
            in={expanded}
            mountOnEnter
            unmountOnExit
            component={GridItemXs12}
          >
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <BusinessGSTStatus businessId={businessId} />
                <DeploymentBusinessAddress businessId={businessId} />
                <div>Earliest Pickup: {consignment.earliestPickupDate}</div>
                <div>Latest Pickup: {consignment.latestPickupDate}</div>
              </Grid>
              <Grid item xs={5}>
                <ConsignmentSaleLotSummary
                  consignmentId={id}
                  buyerId={buyerId}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </ExpandablePaper>
      <Collapse in={expanded} mountOnEnter unmountOnExit>
        <VendorSaleLotCards consignmentId={id} buyerId={buyerId} />
      </Collapse>
    </Column>
  );
});
