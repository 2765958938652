import queryString from "query-string";

import { WEIGH_LOT } from "constants/actionTypes";

import { serializeWeighLot } from "lib/serializers/weighLots";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, livestockSaleId, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/weigh-lots/", id, action),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const WeighLotAction = offlineActionCreator(
  WEIGH_LOT,
  serializeWeighLot,
  urlCreator,
  true,
);

WeighLotAction.import = (fileName, fileContents, scaleName, options = null) => {
  return {
    type: WEIGH_LOT.IMPORT.ACTION,
    fileName,
    fileContents,
    scaleName,
    options,
  };
};

WeighLotAction.process = weighLotScans => {
  return { type: WEIGH_LOT.PROCESS.ACTION, weighLotScans };
};

WeighLotAction.updateMappedWeighLotScanStatus = weighLotScans => {
  return {
    type: WEIGH_LOT.UPDATE_MAPPED_WEIGH_LOT_SCAN_STATUS.ACTION,
    weighLotScans,
  };
};

WeighLotAction.keep = weighLotId => {
  return {
    type: WEIGH_LOT.KEEP.ACTION,
    weighLotId,
  };
};

WeighLotAction.ignore = weighLotId => {
  return {
    type: WEIGH_LOT.IGNORE.ACTION,
    weighLotId,
  };
};

WeighLotAction.deleteWithScans = weighLotId => {
  return {
    type: WEIGH_LOT.DELETE_WITH_SCANS.ACTION,
    weighLotId,
  };
};
