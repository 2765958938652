import React, { memo } from "react";

import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Button from "components/Button";
import { CheckpointCards } from "components/Checkpoints/CheckpointCards";
import AgentSales from "components/DeploymentSaleSummaries";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { SaleStatisticsOverViewComponent } from "components/SaleStatistics/SaleStatisticsOverViewComponent";
import {
  SaleSummaryCardsDesktop,
  SaleSummaryCardsMobile,
} from "components/SaleSummaryCards";
import { SaleActionsButton } from "components/SaleSummaryCards/SaleActionsButton";

import { ApiModel } from "constants/loading";
import { DeploymentPermissions } from "constants/permissions";

import { getLivestockSaleId, openSaleModal } from "lib/navigation";

import { selectRoleCurrentDeployments } from "selectors";

import { getSortedCheckpoints } from "selectors/checkpoints";

import { useTheme } from "hooks";
import { useSomeHasPermission } from "hooks/useHasPermission";

const CheckpointContainer = styled.div`
  overflow-y: scroll;
  max-height: 200px;
  padding: 8px;
  margin: 12px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  border-radius: 2px;
  border: 1px solid #c8c8c8;
`;

const OverviewComponent = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${breakpoints[2]}px)`);
  const sortedCheckpoints = useSelector(getSortedCheckpoints);

  const hasSaleStatisticsPermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureSaleStatistics,
  );

  return (
    <div style={{ overflowX: "auto", flex: 1 }}>
      <WaitForSync
        requiredData={[
          ApiModel.AGENCIES,
          ApiModel.CONSIGNMENTS,
          ApiModel.DEPLOYMENTS,
          ApiModel.SALES,
          ApiModel.SALE_LOTS,
          ApiModel.ROUNDS,
          ApiModel.BUSINESSES,
        ]}
      >
        {isMobile ? <SaleSummaryCardsMobile /> : <SaleSummaryCardsDesktop />}
        {hasSaleStatisticsPermission && <SaleStatisticsOverViewComponent />}
        <AgentSales isMobile={isMobile} />
        {sortedCheckpoints.length > 0 && (
          <CheckpointContainer>
            {sortedCheckpoints.map(checkpoint => (
              <CheckpointCards
                checkpointId={checkpoint.id}
                key={checkpoint.id}
              />
            ))}
          </CheckpointContainer>
        )}

        <Row padding={2} justifyBetween>
          <Button
            data-tour="editSale"
            onClick={() => openSaleModal(getLivestockSaleId())}
          >
            Edit Sale
          </Button>
          {isMobile && <SaleActionsButton isMobile />}
        </Row>
      </WaitForSync>
    </div>
  );
};

export default memo(OverviewComponent);
