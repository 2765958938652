import {
  SEND_EMAIL_OFFLINE,
  SEND_EMAIL_COMMIT,
  SEND_EMAIL_ROLLBACK,
  DEPLOYMENT_SALE,
} from "constants/actionTypes";

import { getSaleUrl } from "lib/navigation";

export const sendReport = (
  sale,
  reportUrl,
  reportName,
  emailRecipients,
  businessUserIds,
  rawEmailAddress,
  businessId,
  deploymentId,
) => {
  const payload = {
    report_url: reportUrl,
    report_name: reportName,
    email_recipients: emailRecipients,
    business_user_ids: businessUserIds,
    raw_email_address: rawEmailAddress,
    recipient_business_id: businessId,
    deployment_id: deploymentId,
  };
  const meta = {
    businessId,
    businessUserIds,
    emailRecipients,
    rawEmailAddress,
    reportName,
    reportUrl,
  };

  const url = `${getSaleUrl(sale)}/email-report/`;
  return {
    type: SEND_EMAIL_OFFLINE,
    payload,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: SEND_EMAIL_COMMIT,
          meta,
        },
        rollback: {
          type: SEND_EMAIL_ROLLBACK,
          meta,
        },
      },
    },
  };
};

export const sendAllVendorReports = deploymentSaleId => {
  const url = `/v2/deployment-sale/${deploymentSaleId}/send_vendor_reports/`;

  return {
    type: DEPLOYMENT_SALE.SEND_VENDOR_REPORTS.REQUEST,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
        },
        commit: {
          type: DEPLOYMENT_SALE.SEND_VENDOR_REPORTS.SUCCESS,
        },
        rollback: {
          type: DEPLOYMENT_SALE.SEND_VENDOR_REPORTS.FAILURE,
        },
      },
    },
  };
};
