import React from "react";

import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

export function hasAddressRenderer(props) {
  if (!props.data) {
    return null;
  }
  const { value } = props;
  if (value === true) {
    return <FaIcon icon={faCheck} color="success" />;
  } else {
    return <FaIcon icon={faExclamationCircle} color="warning" />;
  }
}
