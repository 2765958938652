import { useEffect, useState } from "react";

import {
  faCheckCircle,
  faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import { useDispatch } from "react-redux";

export function useWaityLoadyMultiButton(
  title,
  action,
  isLoading,
  isDefault = true,
  isDisabled = false,
  tooltip,
) {
  const dispatch = useDispatch();
  const [icon, setIcon] = useState(null);
  const isSpinnerShowing = icon === faSpinnerThird;
  const runAction = () => {
    dispatch(action());
  };

  useEffect(() => {
    // If we are done loading and the spinner is still showing, we want to show the tick and move on
    // We check if the spinner is showing as if this action is loaded on render, we want to prevent flashing of icons
    if (!isLoading && isSpinnerShowing) {
      setIcon(faCheckCircle);
      setTimeout(() => {
        setIcon(null);
      }, 1000);
    } else if (isLoading) {
      setIcon(faSpinnerThird);
    }
  }, [isLoading, isSpinnerShowing]);

  return {
    title: isLoading ? "" : title,
    onClick: () => runAction(),
    default: isDefault,
    icon,
    spin: isLoading,
    isDisabled: isDisabled || isLoading,
    tooltip,
  };
}
