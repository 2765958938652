import React from "react";

import { faMailBulk } from "@fortawesome/pro-duotone-svg-icons";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@material-ui/core";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { EmailAction } from "actions/email";
import { ReportJobAction } from "actions/reportJob";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { LoadAll } from "components/AgGrid/LoadAll";
import TableWrapper from "components/AgGrid/TableWrapper";
import { Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { TitleText } from "components/Text";

import { AgGridTables } from "constants/aggrid";
import { SelectCurrentPageOnlyColumnDefinition } from "constants/columnDefinitions/actions";
import { ReportJobColumnDef } from "constants/columnDefinitions/reportJob";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import { ReportJobStatus } from "constants/reportJob";

import { EmailDetailGridOptions } from "lib/agGrid/columnDefinitions/email";
import { openModalLink } from "lib/navigation";
import { dateXDaysAgo } from "lib/timeFormats";

import { getBusinesses, getSales } from "selectors";

import { selectReportJobAggridData } from "selectors/aggrid/reportJob";

import { useMountEffect, useToggle } from "hooks";

import { BulkSendModal } from "./BulkSend";

const rowSelectionId = "reportJob.id";
const getRowId = ({ data }) => get(data, rowSelectionId);

const columnDefs = [
  SelectCurrentPageOnlyColumnDefinition,
  ...Object.values(ReportJobColumnDef),
];

const ReportJobListAggridWrapper = ({ children }) => {
  // move loading indicator to inside AgGridTable component,
  // allowing headers and actions to be rendered while waiting for aggrid data
  return (
    <WaitForSync
      requiredData={[
        ApiModel.BUSINESSES,
        ApiModel.EMAIL,
        ApiModel.REPORT_JOBS,
        ApiModel.SALES,
      ]}
    >
      <TableWrapper>{children}</TableWrapper>
    </WaitForSync>
  );
};

export const ReportJobList = () => {
  const dispatch = useDispatch();

  const [allLoaded, setAllLoaded] = React.useState(false);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const [showBulkSend, toggleShowBulkSend] = useToggle(false);

  const businessByIdLookup = useSelector(getBusinesses);
  const saleByIdLookup = useSelector(getSales);
  const data = useSelector(selectReportJobAggridData);

  // Billing Documents, eContracts and NVD Image PDF should not be shown here
  const excludeReportSlugs = [
    "BillingDocumentPdf",
    "EContractPdf",
    "NVDImagesPDFView",
  ];
  const baseReportJobFilter = {
    excludeReportSlugs,
  };
  const baseEmailFilter = {
    hasReportJob: true,
    excludeReportSlugs,
  };

  const oneDayAgo = dateXDaysAgo(1);
  const threeMonthsAgo = dateXDaysAgo(93);

  useMountEffect(() => {
    dispatch(ReportJobAction.pusher_subscribe());

    // fetch most recent (& relevant) data ASAP
    // this blocks AgGrid display until loaded
    dispatch(
      ReportJobAction.request({
        ...baseReportJobFilter,
        createdGte: oneDayAgo,
      }),
    );
    dispatch(
      EmailAction.request({
        ...baseEmailFilter,
        createdGte: oneDayAgo,
      }),
    );

    // fetch the rest in background
    dispatch(
      ReportJobAction.requestSome({
        ...baseReportJobFilter,
        createdLte: oneDayAgo,
        createdGte: threeMonthsAgo,
        suppressToast: true,
      }),
    );
    dispatch(
      EmailAction.requestSome({
        ...baseEmailFilter,
        createdLte: oneDayAgo,
        createdGte: threeMonthsAgo,
      }),
    );

    return () => {
      dispatch(ReportJobAction.pusher_unsubscribe());
    };
  }, [dispatch]);

  const loadAll = () => {
    // fetch [only] the remaining data in background
    dispatch(
      ReportJobAction.requestSome({
        ...baseReportJobFilter,
        createdLte: threeMonthsAgo,
      }),
    );
    dispatch(
      EmailAction.requestSome({
        ...baseEmailFilter,
        createdLte: threeMonthsAgo,
      }),
    );

    setAllLoaded(true);
  };

  const loadAllLabel = (
    <LoadAll
      allLoaded={allLoaded}
      onClick={loadAll}
      text="Only reports created within the last three months are shown."
    />
  );

  const isBulkSendDisabled = selectedRows.length < 1;
  const additionalActions = [
    {
      title: "Generate",
      icon: faGear,
      onClick: () => openModalLink(ModalTypes.MultiSaleReports, {}),
      dataTour: "openGenerateReportModal",
    },
    {
      dataTour: "openBulkSendReportsModal",
      icon: faMailBulk,
      isDisabled: isBulkSendDisabled,
      onClick: toggleShowBulkSend,
      title: selectedRows.length
        ? `Bulk Send (${selectedRows.length}) Reports`
        : "Bulk Send Reports",
    },
  ];

  const detailCellRendererParams = {
    detailGridOptions: EmailDetailGridOptions,
    getDetailRowData: ({ data, successCallback }) => {
      successCallback(data.reportJob.emails);
    },
  };

  const isRowSelectable = ({ data }) => {
    const { reportJob } = data;
    const businessIds = reportJob.businessIds || [];
    // check reportJob has at least one business this user can see
    const hasValidBusiness = businessIds.some(
      businessId => businessByIdLookup[businessId],
    );
    return reportJob.status !== ReportJobStatus.ERROR && hasValidBusiness;
  };

  return (
    <Column fullHeight>
      <Box p={2}>
        <TitleText>Reports</TitleText>
      </Box>
      <AgGridTable
        additionalActions={additionalActions}
        columnDefs={columnDefs}
        context={{ businessByIdLookup, saleByIdLookup }}
        detailCellRendererParams={detailCellRendererParams}
        extraHeaderComponents={[loadAllLabel]}
        getRowId={getRowId}
        isRowSelectable={isRowSelectable}
        masterDetail
        paginationPageSize={100}
        // paginationPageSizeSelector={[25, 50, 100, 200]} // AgGrid v31.0.0+
        rowData={data}
        rowSelection="multiple"
        rowSelectionId={rowSelectionId}
        rowsSelectable
        onRowSelectionChanged={setSelectedRows}
        tableName={AgGridTables.REPORT_JOB}
        WrapperComponent={ReportJobListAggridWrapper}
      />
      {showBulkSend && (
        <BulkSendModal
          rowData={selectedRows}
          toggleShowBulkSend={toggleShowBulkSend}
        />
      )}
    </Column>
  );
};
