import React from "react";

import {
  faExclamationCircle,
  faEye,
  faPencil,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sum } from "lodash";
import { createSelector } from "reselect";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import Badge from "components/Badge";

import { ModalTypes } from "constants/navigation";
import {
  PenScanLotStatus,
  PenScanLotStatusColors,
} from "constants/penScanLots";
import { PenScanLotPermissions } from "constants/permissions";
import { UNALLOCATED } from "constants/scanner";
import { colors } from "constants/theme";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { openModalLink } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import { selectSaleLotIdsByPenScanLotIdLookup } from "selectors";

import {
  selectResolvedCommentCountByPenScanLotLotIdLookup,
  selectUnresolvedCommentCountByPenScanLotLotIdLookup,
} from "selectors/comments";
import { selectNameByDeploymentIdLookup } from "selectors/deployments";
import { selectFilteredPenScanLotIds } from "selectors/globalSearch/penScanLotFilters";
import {
  selectEidsByPenScanLotIdLookup,
  selectEidsBySaleLotIdLookup,
  selectReceivalLotIdByEidLookup,
} from "selectors/indexes";
import {
  selectConsignmentIdsByPenScanLotIdLookup,
  selectMappingStatusByPenScanLotIdLookup,
  selectReceivalLotIdsByPenScanLotIdLookup,
  selectReceivalLotMarksByPenScanLotLookup,
  selectReceivalPenIdsFromPenScanLotLookup,
} from "selectors/penScanLots";
import {
  getAuctionPens,
  getBusinesses,
  getConsignments,
  getPenScanLots,
  getReceivalLots,
  getRounds,
  getSaleLots,
} from "selectors/root";

export const PenScanLotRenderer = ({ value, data }) => {
  if (!data) {
    return null;
  }

  return (
    <Badge color={PenScanLotStatusColors[value]}>
      {value === PenScanLotStatus.NOT_MAPPABLE && (
        <FontAwesomeIcon icon={faExclamationCircle} color={colors.white} />
      )}{" "}
      {value}
    </Badge>
  );
};

export const selectPenScanLotData = createSelector(
  [
    selectFilteredPenScanLotIds,
    getPenScanLots,
    selectEidsByPenScanLotIdLookup,
    getAuctionPens,
    getConsignments,
    getBusinesses,
    selectResolvedCommentCountByPenScanLotLotIdLookup,
    selectUnresolvedCommentCountByPenScanLotLotIdLookup,
    selectMappingStatusByPenScanLotIdLookup,
    selectReceivalLotIdByEidLookup,
    selectConsignmentIdsByPenScanLotIdLookup,
    selectReceivalLotIdsByPenScanLotIdLookup,
    getReceivalLots,
    selectReceivalLotMarksByPenScanLotLookup,
    selectReceivalPenIdsFromPenScanLotLookup,
    selectNameByDeploymentIdLookup,
    getRounds,
    selectSaleLotIdsByPenScanLotIdLookup,
    getSaleLots,
    selectEidsBySaleLotIdLookup,
  ],
  (
    filteredPenScanLotIds,
    penScanLotByIdLookup,
    eidsByPenScanLotIdLookup,
    auctionPenLookup,
    consignmentsLookup,
    businessLookup,
    resolvedCommentCountByPenScanLotLotIdLookup,
    unresolvedCommentCountByPenScanLotLotIdLookup,
    mappingStatusByPenScanLotId,
    receivalLotIdLookupByEidLookup,
    consignmentIdsByPenScanLotIdLookup,
    receivalLotIdsByPenScanLotIdLookup,
    receivalLotLookup,
    receivalLotMarksByPenScanLotLookup,
    receivalPenIdsFromPenScanLotLookup,
    deploymentNameByIdLookup,
    roundLookup,
    saleLotIdsByPenScanLotIdLookup,
    saleLotByIdLookup,
    eidsBySaleLotIdLookup,
  ) => {
    return filteredPenScanLotIds.map(penScanLotId => {
      const penScanLot = penScanLotByIdLookup[penScanLotId];
      const penScanLotEids = eidsByPenScanLotIdLookup[penScanLot.id] || [];
      const penScanEidsWithReceivalLots = penScanLotEids.filter(
        eid => !!receivalLotIdLookupByEidLookup[eid],
      );

      const receivalLotScanCount = penScanEidsWithReceivalLots.length;

      const receivalLotVendors = consignmentIdsByPenScanLotIdLookup[
        penScanLot.id
      ].map(consignmentId => {
        return businessLookup[consignmentsLookup[consignmentId]?.vendor_id]
          ?.name;
      });

      const receivalLotIds =
        receivalLotIdsByPenScanLotIdLookup[penScanLot.id] || [];

      const receivalLotsQuantity = receivalLotIds.length
        ? sum(
            receivalLotIds.map(
              receivalLotId => receivalLotLookup[receivalLotId]?.quantity,
            ),
          )
        : 0;

      const saleLots = saleLotIdsByPenScanLotIdLookup[penScanLot.id]
        ?.map(saleLotId => saleLotByIdLookup[saleLotId])
        .filter(Boolean);

      const receivalLotMarks =
        receivalLotMarksByPenScanLotLookup[penScanLot.id];

      const receivalLotPens = receivalPenIdsFromPenScanLotLookup[
        penScanLot.id
      ].map(receivalPenId =>
        getAuctionPenDisplayName(auctionPenLookup[receivalPenId]),
      );

      let rowWarning = null;

      const eidsByPenScanLot = eidsByPenScanLotIdLookup[penScanLotId] || [];

      const penScanLotSaleLotIds = (
        saleLotIdsByPenScanLotIdLookup[penScanLotId] || []
      ).filter(saleLotId => saleLotId !== UNALLOCATED);

      const penScanLotHasSaleLots = !!penScanLotSaleLotIds.length;

      const penScanLotHasMoreThanOneSaleLot = penScanLotSaleLotIds.length > 1;

      const penScanLotEidsCount = eidsByPenScanLot.length || 0;

      const penScanLotsSaleLotEidsCount = sum(
        penScanLotSaleLotIds.map(
          saleLotId => eidsBySaleLotIdLookup[saleLotId]?.length || 0,
        ),
      );

      // if the pen scan lot is associated to more than one sale lot
      // or the amount of pen scan lot eids is not equal to the count
      // of sale lot eids

      if (penScanLotHasSaleLots) {
        if (penScanLotHasMoreThanOneSaleLot) {
          rowWarning = "Pen Scan Lot is associated to more than one Sale Lot";
        } else {
          // has exactly one associated Sale Lot
          const saleLot = saleLotByIdLookup[penScanLotSaleLotIds[0]] || {};
          if (
            penScanLotsSaleLotEidsCount > 0 &&
            penScanLotEidsCount !== penScanLotsSaleLotEidsCount
          ) {
            rowWarning =
              "Pen Scan Lot EID count is not equal to associated Sale Lot EID count";
          } else if (saleLot.quantity !== penScanLot.quantity) {
            rowWarning =
              "Pen Scan Lot head count is not equal to Sale Lot head count";
          }
        }
        // explain why a pen scan lot is not mappable
      } else if (penScanLotEidsCount !== penScanLot.quantity) {
        rowWarning =
          "Pen Scan Lot head count is not equal to Pen Scan Lot scans count";
      } else if (!receivalLotIds.length) {
        rowWarning = "There are no linked Receival Lots";
      } else if (!penScanLot.deploymentId) {
        rowWarning = "There is no selected Agency";
      } else if (!penScanLot.saleRoundId) {
        rowWarning = "There is no selected Sale Round";
      }

      return {
        penScanLot,
        status: mappingStatusByPenScanLotId[penScanLot.id],
        marks: penScanLot.marks,
        sellingPen: getAuctionPenDisplayName(
          auctionPenLookup[penScanLot.sellingPenId],
        ),
        deployment: deploymentNameByIdLookup[penScanLot.deploymentId],
        round: roundLookup[penScanLot.saleRoundId]?.name || "",
        scanHd: penScanLotEids.length,
        isLocked: penScanLot.isLocked,
        receivalLot: {
          count: receivalLotIds.length,
          vendors: receivalLotVendors.join(", "),
          marks: receivalLotMarks.join(", "),
          pens: receivalLotPens.join(", "),
          quantity: receivalLotsQuantity,
          scanHd: receivalLotScanCount,
        },
        saleLots,
        resolvedCommentCount:
          resolvedCommentCountByPenScanLotLotIdLookup[penScanLot.id],
        unresolvedCommentCount:
          unresolvedCommentCountByPenScanLotLotIdLookup[penScanLot.id],
        rowWarning,
      };
    });
  },
);

export function PenScanLotActionsColumnRenderer(props) {
  const { data } = props;

  if (!data) {
    return null;
  }

  const { penScanLot } = data;

  const readOnly = !hasPermission(penScanLot, PenScanLotPermissions.update);

  return (
    <ActionButtonContainer>
      <ActionButton
        data-tour="editPenScanLot"
        type="button"
        onClick={() =>
          openModalLink(ModalTypes.EditPenScanLot, {
            penScanLotId: penScanLot.id,
          })
        }
      >
        <FontAwesomeIcon icon={readOnly ? faEye : faPencil} />
        &nbsp;{readOnly ? `View` : `Edit`}
      </ActionButton>
    </ActionButtonContainer>
  );
}

export const selectPenScanLotAggridDataByIdLookup = createSelector(
  [getPenScanLots, getAuctionPens, selectEidsByPenScanLotIdLookup],
  (penScanLots, auctionPens, eidsByPenScanLotIdLookup) =>
    Object.values(penScanLots).reduce((acc, penScanLot) => {
      const sellingPen = auctionPens[penScanLot.sellingPenId];

      const marksString = penScanLot.marks
        .map(mark => mark.location)
        .join(", ");

      const penScanLotScansCount =
        eidsByPenScanLotIdLookup[penScanLot.id]?.length || 0;

      acc[penScanLot.id] = {
        penDisplayName: getAuctionPenDisplayName(sellingPen),
        marks: marksString,
        isLocked: penScanLot.isLocked,
        quantity: penScanLot.quantity,
        scansCount: penScanLotScansCount,
        id: penScanLot.id,
      };

      return acc;
    }, {}),
);
