import React from "react";

import {
  faEye,
  faPencil,
  faTruck,
  faUndo,
} from "@fortawesome/pro-solid-svg-icons";

import { AgGridActionButtons } from "constants/columnDefinitions/actions";
import {
  ConsignmentPermissions,
  SaleLotPermissions,
} from "constants/permissions";
import { saleLotStatuses } from "constants/sale";

import { hasPermission } from "lib/permissions";

export function actionsColumnRenderer(props) {
  const { data, context } = props;

  const { handleDeliver, handleEdit, handleEditConsignment, handleUndeliver } =
    context;

  if (!data) {
    return null;
  }

  const hasChangeSaleLotPermission = hasPermission(
    data,
    SaleLotPermissions.update,
  );
  const hasChangeConsignmentPermission = hasPermission(
    data.consignment,
    ConsignmentPermissions.update,
  );

  const { status } = data;
  // Optional - Params for rendering. The same params that are passed to the cellRenderer function.
  const onDeliverClick = () => {
    if (hasChangeSaleLotPermission) {
      handleDeliver(data.id);
    }
  };

  const onUndeliverClick = () => {
    if (hasChangeSaleLotPermission) {
      handleUndeliver(data.id);
    }
  };

  const onEditClick = () => {
    handleEdit(data.id);
  };

  const onEditConsignmentClick = () => {
    handleEditConsignment(data.consignment_id);
  };

  const showEdit = typeof handleEdit === "function";
  const showEditConsignment = typeof handleEditConsignment === "function";

  const showDeliver =
    typeof handleDeliver === "function" &&
    showEdit &&
    hasChangeSaleLotPermission &&
    (status === saleLotStatuses.SOLD ||
      status === saleLotStatuses.NOT_SOLD ||
      status === saleLotStatuses.NO_SALE);

  const showUndeliver =
    typeof handleUndeliver === "function" &&
    showEdit &&
    hasChangeSaleLotPermission &&
    status === saleLotStatuses.DELIVERED;

  if (!(showEdit || showDeliver || showUndeliver || showEditConsignment)) {
    return null;
  }

  const actions = [
    {
      onClick: onEditClick,
      dataTour: `action-edit-lot:${data.lot_number}`,
      icon: hasChangeSaleLotPermission ? faPencil : faEye,
      title: " Lot",
      hide: !showEdit,
    },
    {
      onClick: onDeliverClick,
      dataTour: `action-deliver`,
      icon: faTruck,
      title: " Deliver",
      hide: !showDeliver,
    },
    {
      onClick: onUndeliverClick,
      dataTour: `action-undeliver`,
      icon: faUndo,
      title: " Undeliver",
      hide: !showUndeliver,
    },
    {
      onClick: onEditConsignmentClick,
      dataTour: `action-edit-consignment`,
      icon: hasChangeConsignmentPermission ? faPencil : faEye,
      title: " Vendor",
      hide: !showEdit,
    },
  ];

  return <AgGridActionButtons actions={actions} />;
}
