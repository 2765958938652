import React from "react";

import * as PropTypes from "prop-types";
import styled from "styled-components/macro";

import { FatFingerButton } from "components/Button/FatFingers";
import { Column, Row } from "components/Layout";

import { PricingTypes } from "constants/pricingTypes";

import { getProgenyDisplayCount } from "lib/saleLot";

import { SectionContainer } from "./Layout";
import NumberPad from "./NumberPad";

const PricingTypeButton = styled(Row)`
  flex: 1;
  color: #4a4a4a;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  background: ${({ active }) => (active ? "rgba(0,133,255,0.1)" : "#fff")};
  &:active {
    box-shadow: inset 0px 0px 3px 0px rgba(200, 200, 200, 0.59);
    color: black;
  }
  transition: all ease-out 200ms;
  user-select: none;
  ${({ disabled }) => (disabled ? "opacity: 0.33" : "cursor: pointer")};
`;

const PricingTypeWrapper = styled(Column)`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  grid-gap: 1px;
  flex: 1;
`;

const MiddleGrid = styled(Column)`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 1px;
  flex: 1;
`;
const GridWrapper = styled(SectionContainer)`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 0.15fr 1fr auto;
  grid-gap: 1px;
`;

export const KeypadInput = {
  PRICE: 0,
  HEADCOUNT: 1,
  MASS: 2,
  BIDDER_SEARCH: 3,
};

export function PricingSelect(props) {
  const {
    allowHdCountEntry,
    handleNumPress,
    handleDeletePress,
    isDisabled,
    isSingleLot,
    kilograms,
    onBlur,
    price,
    showMassEntry,
    showNumPad,
    selectedHdCount,
    selectedInput,
    selectedPricingTypeId,
    setSelectedInput,
    setSelectedPricingType,
    totalHdCount,
    totalProgeny,
  } = props;
  return (
    <GridWrapper>
      <PricingTypeWrapper>
        {PricingTypes.all().map(pricingType => (
          <PricingTypeButton
            key={pricingType.value}
            data-testid={`pricingTypeButton:${pricingType.value}`}
            onClick={() =>
              !isDisabled && setSelectedPricingType(pricingType.value)
            }
            active={pricingType.value === selectedPricingTypeId}
            disabled={isDisabled}
          >
            {pricingType.label}
          </PricingTypeButton>
        ))}
      </PricingTypeWrapper>
      <MiddleGrid>
        <FatFingerButton
          description="HC"
          text={selectedHdCount}
          smallText={`/${totalHdCount} ${getProgenyDisplayCount(totalProgeny)}`}
          onBlur={onBlur}
          onClick={e => setSelectedInput(KeypadInput.HEADCOUNT, e)}
          onFocus={e => setSelectedInput(KeypadInput.HEADCOUNT, e)}
          isSelected={selectedInput === KeypadInput.HEADCOUNT}
          isDisabled={!allowHdCountEntry || !isSingleLot || isDisabled}
        />

        {showMassEntry && selectedPricingTypeId === PricingTypes.PER_KILO && (
          <FatFingerButton
            onBlur={onBlur}
            onClick={e => setSelectedInput(KeypadInput.MASS, e)}
            onFocus={e => setSelectedInput(KeypadInput.MASS, e)}
            isSelected={selectedInput === KeypadInput.MASS}
            description="Kg"
            text={kilograms}
            isDisabled={!isSingleLot || isDisabled}
          />
        )}

        <FatFingerButton
          text={price}
          onBlur={onBlur}
          onClick={e => setSelectedInput(KeypadInput.PRICE, e)}
          onFocus={e => setSelectedInput(KeypadInput.PRICE, e)}
          isSelected={selectedInput === KeypadInput.PRICE}
          description={PricingTypes.toString(selectedPricingTypeId)}
          isDisabled={isDisabled}
        />
      </MiddleGrid>
      {showNumPad && (
        <NumberPad
          disabled={isDisabled}
          handleNumPress={handleNumPress}
          handleDeletePress={handleDeletePress}
        />
      )}
    </GridWrapper>
  );
}
PricingSelect.propTypes = {
  allowHdCountEntry: PropTypes.bool,
  handleNumPress: PropTypes.func,
  handleDeletePress: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSingleLot: PropTypes.bool,
  kilograms: PropTypes.string,
  onBlur: PropTypes.func,
  price: PropTypes.string,
  showMassEntry: PropTypes.bool,
  showNumPad: PropTypes.bool,
  selectedHdCount: PropTypes.number,
  selectedInput: PropTypes.number,
  selectedPricingTypeId: PropTypes.number,
  setSelectedInput: PropTypes.func,
  setSelectedPricingType: PropTypes.func,
  totalHdCount: PropTypes.number,
};
