import React from "react";

import { useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import { Button, DeleteButton, SecondaryButton } from "components/Form";
import { CheckBox, SelectField } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";

const AnimalForm = ({
  consignments,
  auctionPens,
  handleDelete,
  markDeceased,
  allowBlankConsignment,
  allowBlankAuctionPen,
}) => {
  const formikProps = useFormikContext();
  const {
    handleSubmit: formikSubmit,
    isValid,
    touched,
    values,
    setValues,
    setTouched,
  } = formikProps;

  const onClickUnallocate = () => {
    setValues({ ...values, auctionPen: null, consignment: null }).then(() =>
      setTouched({ ...touched, auctionPen: true, consignment: true }, false),
    );
  };

  const handleSubmit = () => {
    Object.keys(touched).length === 1 && Boolean(touched.deceased)
      ? markDeceased(values.deceased)
      : formikSubmit();
  };

  const canEditAnimal = allowBlankConsignment && allowBlankAuctionPen;

  const allowUnallocate =
    canEditAnimal && values.auctionPen !== -1 && values.consignment !== -1;

  return (
    <Wrapper>
      <Row>
        <SelectField
          isClearable={allowBlankConsignment}
          label="Vendor"
          name="consignment"
          options={consignments}
          required={!allowBlankConsignment}
        />
      </Row>
      <Row>
        <SelectField
          required={!allowBlankAuctionPen}
          isClearable={allowBlankAuctionPen}
          includeBlankOption={allowBlankAuctionPen}
          name="auctionPen"
          label="Sale Lot"
          options={auctionPens}
        />
      </Row>
      <Row>
        <CheckBox
          disabled={!allowBlankAuctionPen}
          name="deceased"
          label="Animal is deceased"
        />
      </Row>

      <ButtonWrapper>
        {/* Only allow deletion when the animal is not taken, or not sold */}
        <SpacedDeleteButton
          data-tour="deleteAnimal"
          disabled={!canEditAnimal}
          onClick={handleDelete}
        >
          Delete Animal
        </SpacedDeleteButton>
        <UnallocateButton
          data-tour="unallocate"
          type="submit"
          disabled={!allowUnallocate}
          onClick={onClickUnallocate}
        >
          Unallocate
        </UnallocateButton>
        <SubmitButton
          data-tour="save&Close"
          type="submit"
          onClick={handleSubmit}
          disabled={!isValid}
        >
          Save & Close
        </SubmitButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

AnimalForm.propTypes = {
  consignments: PropTypes.array,
  auctionPens: PropTypes.array,
  handleDelete: PropTypes.func,
};

export default AnimalForm;

const Wrapper = styled(Column).attrs({
  flexGrow: true,
})`
  height: 75%;
`;

const ButtonWrapper = styled(Row)`
  margin-top: auto;
  padding-left: ${({ theme }) => theme.space[2]}px;
`;

const UnallocateButton = styled(SecondaryButton)`
  margin: ${({ theme }) => theme.space[1]}px;
`;

const SubmitButton = styled(Button)`
  margin: ${({ theme }) => theme.space[1]}px;
`;

const SpacedDeleteButton = styled(DeleteButton)`
  margin: ${({ theme }) => theme.space[1]}px;
`;
