import React from "react";

import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, DeleteButton, SecondaryButton } from "components/Form/Button";
import { ReadOnly } from "components/ReadOnly";

import {
  DeploymentPermissions,
  NominationPermissions,
} from "constants/permissions";

import { hasPermission } from "lib/permissions";

import {
  getActiveRoleDeployments,
  getDeploymentById,
  getNominationById,
} from "selectors";

import { useToggle } from "hooks";

export function Footer(props) {
  const { nominationId, onClose, onDelete } = props;
  const [isConfirmDialogOpen, toggleIsConfirmDialogOpen] = useToggle();

  const { errors } = useFormikContext();

  const canEditNomination = useSelector(state =>
    nominationId
      ? hasPermission(
          getNominationById(nominationId)(state) || {},
          NominationPermissions.update,
        )
      : false,
  );
  const canDeleteNomination = useSelector(state =>
    nominationId
      ? hasPermission(
          getNominationById(nominationId)(state),
          NominationPermissions.delete,
        )
      : false,
  );

  const canCreateNomination = useSelector(state =>
    getActiveRoleDeployments(state)
      .map(roleDeployment => getDeploymentById(roleDeployment.id)(state))
      .some(deployment =>
        hasPermission(deployment, DeploymentPermissions.canNominateFor),
      ),
  );

  const isSubmitHidden = nominationId
    ? !canEditNomination
    : !canCreateNomination;

  const isSubmitDisabled = !isEmpty(errors);

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      {canDeleteNomination && (
        <DeleteButton
          data-tour="delete"
          disabled={!canDeleteNomination}
          type="button"
          onClick={toggleIsConfirmDialogOpen}
        >
          <ReadOnly readOnly={isSubmitDisabled} showLock /> Delete
        </DeleteButton>
      )}
      {!isSubmitHidden && (
        <Button
          disabled={isSubmitDisabled}
          data-tour={nominationId ? "update" : "create"}
          type="submit"
        >
          {nominationId ? "Update" : "Create"}
        </Button>
      )}
      <ConfirmDialog
        title="Confirm Delete Nomination"
        isOpen={isConfirmDialogOpen}
        onCancel={toggleIsConfirmDialogOpen}
        onDelete={onDelete}
      />
    </>
  );
}

Footer.propTypes = {
  nominationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};
