import React from "react";

import DeploymentSaleOptionsModal from "./DeploymentSaleOptionsModal";

function DeploymentSaleOptionsAdapter(props) {
  const { hash } = props;
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return <DeploymentSaleOptionsModal returnTo={decodedReturnTo} />;
}

export default DeploymentSaleOptionsAdapter;
