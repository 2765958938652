import React from "react";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { AuctionPenAction } from "actions";

import Button from "components/Button";
import { ERPChip } from "components/Chip";
import { Row } from "components/Layout";

import { ForSaleWatcherInCurrentSale } from "containers/FeatureToggles";

import { formatSaleLotOverflow } from "lib/saleLot";

import { getIsLivestockAgent, getSellingNowByPriceSaleLot } from "selectors";

import ProgressBarSales from "./ProgressBarSales";

const MediumText = styled.div`
  font-size: 24px;
`;

const SmallerText = styled.div`
  font-size: 14px;
  padding-left: 12px;
`;

const Wrapper = styled(Row)`
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 0.5px solid #c2c2c2;
  padding: ${({ theme }) => `${theme.space[1]}px ${theme.space[2]}px`};
`;

const AuctionPenSummaryHeader = ({
  penName,
  totalCount,
  soldCount,
  overflowPen,
  overflowCount,
  saleLots,
  auctionPenId,
}) => {
  const overflowDisplayValue = formatSaleLotOverflow(
    overflowPen,
    overflowCount,
  );

  const currentlySelling =
    useSelector(getSellingNowByPriceSaleLot)?.auction_pen_id === auctionPenId;

  const dispatch = useDispatch();

  const isSold = saleLots?.every(sl => !!sl.first_sold);

  const handleSetCurrent = () => {
    dispatch(AuctionPenAction.orderSellingNow(auctionPenId));
  };

  const isLivestockAgent = useSelector(getIsLivestockAgent);

  return (
    <Fade in>
      <Wrapper data-tour="auctionPenHeader">
        <Row data-tour="auctionPen" baseline>
          AP
          <MediumText>{penName}</MediumText>
          <ERPChip saleLots={saleLots} />
          {overflowDisplayValue && (
            <SmallerText> {overflowDisplayValue}</SmallerText>
          )}
        </Row>
        <ForSaleWatcherInCurrentSale>
          {isSold === false && (!currentlySelling || isLivestockAgent) && (
            <Row>
              <Button onClick={handleSetCurrent}>
                <FontAwesomeIcon icon={faEye} size="1x" />
                Set Currently Selling
              </Button>
            </Row>
          )}
        </ForSaleWatcherInCurrentSale>
        <Row alignEnd>
          {soldCount > 0 && (
            <ProgressBarSales total={totalCount} sold={soldCount} />
          )}
          <Row baseline>
            HC
            <MediumText>{totalCount}</MediumText>
          </Row>
        </Row>
      </Wrapper>
    </Fade>
  );
};

AuctionPenSummaryHeader.propTypes = {
  penName: PropTypes.string,
  totalCount: PropTypes.number,
  soldCount: PropTypes.number,
  overflowPen: PropTypes.string,
  overflowCount: PropTypes.number,
  auctionPenId: PropTypes.string,
};

export default React.memo(AuctionPenSummaryHeader);
