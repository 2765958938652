import { getPropertyList } from "lib";

const PRICING_TYPES = {
  PER_HEAD: 1,
  PER_KILO: 2,
  GROSS: 3,
};

Object.freeze(PRICING_TYPES);

export const ALL_PRICING_TYPES = [
  PRICING_TYPES.PER_HEAD,
  PRICING_TYPES.PER_KILO,
  PRICING_TYPES.GROSS,
];

export const LIVESTOCK_SALE_PRICING_TYPES = [
  PRICING_TYPES.PER_HEAD,
  PRICING_TYPES.PER_KILO,
  PRICING_TYPES.GROSS,
];

export const CLEARING_SALE_PRICING_TYPES = [
  PRICING_TYPES.PER_HEAD,
  PRICING_TYPES.GROSS,
];

export const LivestockSalePricingTypeDisplayNameLkp = {
  [PRICING_TYPES.PER_HEAD]: "$/Head",
  [PRICING_TYPES.PER_KILO]: "¢/kg",
  [PRICING_TYPES.GROSS]: "$/Gross",
};

export const ClearingSalePricingTypeDisplayNameLkp = {
  [PRICING_TYPES.PER_HEAD]: "$/Unit",
  [PRICING_TYPES.GROSS]: "$/Gross",
};

export const pricingTypeString =
  (pricingTypeDisplayNameLookup = LivestockSalePricingTypeDisplayNameLkp) =>
  pricingTypeId => {
    if (pricingTypeDisplayNameLookup[pricingTypeId]) {
      return pricingTypeDisplayNameLookup[pricingTypeId.toString()];
    } else {
      throw new Error("Not a pricing type");
    }
  };

export const formatPricingTypeOutput = (id, price) => {
  switch (id) {
    case PRICING_TYPES.PER_HEAD:
      return `$${price}/Head`;
    case PRICING_TYPES.PER_KILO:
      return `$${price}/kg`;
    case PRICING_TYPES.GROSS:
      return `$${price} Gross`;
    default:
      throw new Error("Not a pricing type");
  }
};

export const PricingTypes = {
  ...PRICING_TYPES,
  toString: pricingTypeString(),
  forOutput: formatPricingTypeOutput,
  forClearingSales: () =>
    getPropertyList(
      CLEARING_SALE_PRICING_TYPES,
      pricingTypeString(ClearingSalePricingTypeDisplayNameLkp),
    ),
  all: () => getPropertyList(ALL_PRICING_TYPES, pricingTypeString()),
};

export function getReceiptPricingTypeString(pricingTypeId) {
  if (pricingTypeId === PricingTypes.PER_HEAD) {
    return "$/Hd";
  } else if (pricingTypeId === PricingTypes.PER_KILO) {
    return "c/kg";
  } else if (pricingTypeId === PricingTypes.GROSS) {
    return "$ Gross";
  }
}

export function getReceiptPriceString(
  unitPrice,
  pricingTypeId,
  defaultPricingTypeId,
) {
  if (
    defaultPricingTypeId === undefined ||
    pricingTypeId === defaultPricingTypeId
  ) {
    if (
      pricingTypeId === PricingTypes.PER_HEAD ||
      pricingTypeId === PricingTypes.GROSS
    ) {
      return `$${unitPrice}`;
    } else if (pricingTypeId === PricingTypes.PER_KILO) {
      return `${unitPrice}c`;
    }
  } else if (pricingTypeId === PricingTypes.PER_HEAD) {
    return `$${unitPrice}/Hd`;
  } else if (pricingTypeId === PricingTypes.PER_KILO) {
    return `${unitPrice}c/kg`;
  } else if (pricingTypeId === PricingTypes.GROSS) {
    return `$${unitPrice} Gross`;
  }
}
