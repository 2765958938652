import React from "react";
import { DialogActions } from "components/MaterialDialog";
import { Button, SecondaryButton } from "components/Form";
import { useFormikContext } from "formik";
import { FormValues } from "components/RetainProceeds/Form";

interface ModalActionsProps {
  isCreate?: boolean;
  onClose: () => void;
  readOnly?: boolean;
}
export function ModalActions(props: ModalActionsProps): React.JSX.Element {
  const { isCreate = false, onClose, readOnly = false } = props;

  const { isValid } = useFormikContext<FormValues>();

  return (
    <DialogActions>
      <SecondaryButton onClick={onClose}>Back</SecondaryButton>
      <Button type="submit" disabled={readOnly || !isValid}>
        {isCreate ? "Create" : "Save"}
      </Button>
    </DialogActions>
  );
}
