import reduceReducers from "reduce-reducers";

import { ENVD_DOCUMENT } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
} from "lib/reducers";
import { deserializeEnvdDocument } from "lib/serializers/envd";

const fetch = apiModelOfflineFetchReducer(ENVD_DOCUMENT, {
  deserializer: deserializeEnvdDocument,
});

const update = apiModelOfflineUpdateReducer(ENVD_DOCUMENT, {
  deserializer: deserializeEnvdDocument,
});

const envdDocumentReducer = reduceReducers(fetch, update);

export default envdDocumentReducer;
