import styled from "styled-components/macro";

import Button from "components/Button";
import Table from "components/Table";

export const ExpandTable = styled(Table)`
  flex-grow: 1;
`;
export const ActionButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;
