import queryString from "query-string";

import { NOMINATION_TERM } from "constants/actionTypes";

import { serializeNominationTerm } from "lib/serializers/nominationTerm";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction(`/v2/nomination-terms/`, id, action),
  });

export const NominationTermsAction = offlineActionCreator(
  NOMINATION_TERM,
  serializeNominationTerm,
  urlCreator,
  false,
);
