import reduceReducers from "reduce-reducers";

import { DEPLOYMENT } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
} from "lib/reducers";
import { deserializeDeployment } from "lib/serializers/deployment";

const fetch = apiModelOfflineFetchReducer(DEPLOYMENT, {
  deserializer: deserializeDeployment,
  clearOnRequest: true,
});

const update = apiModelOfflineUpdateReducer(DEPLOYMENT, {
  deserializer: deserializeDeployment,
});

const deployments = reduceReducers(fetch, update);

export default deployments;
