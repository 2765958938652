import React from "react";

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import uniq from "lodash/uniq";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Badge from "components/Badge";
import { MasterBusinessEmailRecipientCount } from "components/Business";
import { StatusCard } from "components/Card";
import { EditIcon } from "components/Card/Elements";
import { DeliveryPenChip, PICChip } from "components/Chip";
import { PlainCollapse } from "components/Collapse";
import { UnresolvedCommentIcon } from "components/Comments/Icon";
import DiffBadge from "components/DiffBadge/DiffBadge";
import { RightActionColumn } from "components/GroupedCard";
import { OpenIndicator } from "components/Icons/CollapseIcon";
import { Column, Row } from "components/Layout";
import { CurrentSaleReadOnly } from "components/ReadOnly";
import { ResponsiveText } from "components/ResponsiveText";
import { BoldText, Subtitle } from "components/Text";
import StatusText from "components/Text/StatusText";

import { Species } from "constants/species";

import { BuyerCardActionOptions } from "containers/ActionOptions";

import { sortNumericalStrings } from "lib";

import { applyFilters, groupedBuyersFilterOptions } from "lib/filters";
import { openEditBidderRegistrationModal } from "lib/navigation";
import {
  getBuyerHashFromSaleLot,
  getProgenyCountFromSaleLots,
  getProgenyDisplayCount,
} from "lib/saleLot";

import {
  getSaleLotById,
  selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
} from "selectors";

import { useToggle } from "hooks";
import AgriNousActiveLogo from "img/AgriNousActiveLogo";

import { calculateBuyerGridProps } from "./buyerGridPropsCalculator";
import BuyerWayCard from "./BuyerWayCard";

const HeadCountNumber = styled.span`
  font-size: 18px;
`;

const CardTopRow = styled(Row)`
  width: 100%;
  padding-right: ${({ theme }) => theme.space[2]}px;
`;

const BuyerStatusBox = styled(Row)`
  margin-left: -6px; // Deal with the padding on the agrinous icon.
  padding: ${({ theme }) => theme.space[1]}px 0;
`;

const ChangesPending = styled.div`
  ${({ theme }) => `
    color: ${theme.errorRed};
     padding-left: ${theme.space[2]}px
 `}
`;

const ProgenyCount = styled.span`
  white-space: nowrap;
  margin: ${({ theme }) => theme.space[1]}px;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin-left: ${({ theme }) => theme.space[0]}px;
  }
`;

const buyerWayNameKeySelector = obj => obj.buyerWayName;

export const GroupedBuyerCard = ({
  agencyId,
  averageCost,
  averageWeight,
  basePath,
  bidders,
  bidderBySaleLotId,
  buyerName,
  buyerReportUrl,
  buyerWays,
  deliveryReportUrl,
  hasAgriNousUser,
  hasExceptions,
  masterName,
  PICs,
  quantity,
  quantityDelivered,
  saleLots,
  showCommercialData,
  species,
  status,
  filterValues,
}) => {
  const unresolvedSaleLotCommentCountBySaleLotIdLookup = useSelector(
    selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
  );

  const [isOpen, toggleIsOpen] = useToggle(false);

  const saleLotPriceUnitsAreEqual =
    uniq(saleLots.map(sl => sl.priceUnits)).length === 1;

  const saleLotBidders = uniq(
    saleLots.map(saleLot => bidderBySaleLotId[saleLot.id]).filter(Boolean),
  );

  const buyerInfo = {
    quantity,
    status,
    hasExceptions,
    buyerName,
    buyerWays,
    quantityDelivered,
    saleLots,
    saleLotBidders,
  };
  const quantityProgeny = getProgenyCountFromSaleLots(saleLots);

  let filteredQuantityDelivered = quantityDelivered;
  let filteredQuantity = quantity;
  let buyerWaysFiltered = buyerWays;

  if (agencyId) {
    filteredQuantity = 0;
    filteredQuantityDelivered = 0;

    buyerWaysFiltered = buyerWaysFiltered.filter(buyerWays => {
      return buyerWays.saleLots.some(saleLot => {
        return saleLot.agency && saleLot.agency.id === agencyId;
      });
    });

    buyerWaysFiltered.map(buyerWays => ({
      ...buyerWays,
      saleLots: buyerWays.saleLots.filter(saleLot => {
        if (saleLot.agency && saleLot.agency.id === agencyId) {
          filteredQuantity += saleLot.quantity;
          filteredQuantityDelivered += saleLot.quantityDelivered;
        }
        return filteredQuantity && filteredQuantityDelivered;
      }),
    }));
  }

  if (filterValues) {
    buyerWaysFiltered = applyFilters(
      groupedBuyersFilterOptions,
      filterValues,
      buyerWaysFiltered,
    );
  }

  const sortedBuyerWays = sortNumericalStrings(
    buyerWaysFiltered,
    buyerWayNameKeySelector,
  );
  const [gridItemProps, gridBuyerTextProps] = calculateBuyerGridProps(species);

  const saleLotIds = React.useMemo(() => saleLots.map(s => s.id), [saleLots]);

  const unresolvedSaleLotCommentCount = saleLotIds.reduce(
    (acc, saleLotId) =>
      acc + (unresolvedSaleLotCommentCountBySaleLotIdLookup[saleLotId] || 0),
    0,
  );

  const offlinePatching = useSelector(state =>
    saleLotIds.some(id => getSaleLotById(id)(state).syncing),
  );
  return (
    <StatusCard
      data-tour={masterName || buyerName}
      status={offlinePatching ? "inputError" : status}
      onClick={toggleIsOpen}
    >
      <Row full>
        <Column full>
          <Row justifyBetween>
            <CardTopRow alignCenter justifyBetween>
              <Row>
                <Badge uppercase color={status}>
                  {status}
                </Badge>
                <UnresolvedCommentIcon
                  unresolvedCommentCount={unresolvedSaleLotCommentCount}
                  horizontalPadding={1}
                  size="2x"
                />
              </Row>
              <Row flexWrap>
                {offlinePatching && (
                  <ChangesPending>Changes Pending</ChangesPending>
                )}

                <DeliveryPenChip saleLotIds={saleLotIds} />
                <PICChip
                  businessId={saleLots[0].buyerId}
                  PICs={PICs.filter(Boolean)}
                />

                <CurrentSaleReadOnly showLock={false}>
                  <MasterBusinessEmailRecipientCount
                    masterBusinessId={saleLots[0].buyerId}
                  />
                </CurrentSaleReadOnly>
              </Row>
            </CardTopRow>
          </Row>

          <Grid container>
            <Grid {...gridBuyerTextProps}>
              <BuyerStatusBox alignCenter>
                <AgriNousActiveLogo isActive={hasAgriNousUser} />
                <div>
                  <BoldText>
                    <Row>
                      {masterName || buyerName}
                      {saleLotBidders.map(bidder => (
                        <Row>
                          &nbsp;- {bidders[bidder].registrationNumber}
                          <EditIcon
                            onClick={() =>
                              openEditBidderRegistrationModal(bidder)
                            }
                            icon={faPencilAlt}
                          />
                        </Row>
                      ))}
                      {hasExceptions && <StatusText status="error" />}
                    </Row>
                  </BoldText>
                </div>
              </BuyerStatusBox>
            </Grid>

            <Grid item {...gridItemProps}>
              <Subtitle>
                <ResponsiveText
                  mobile="HC"
                  tablet="Head Count"
                  desktop="Head Count"
                />
              </Subtitle>
              <BoldText>
                <HeadCountNumber>{filteredQuantity}</HeadCountNumber>
                {quantityProgeny > 0 ? (
                  <ProgenyCount>
                    {getProgenyDisplayCount(quantityProgeny)}
                  </ProgenyCount>
                ) : null}
                <DiffBadge
                  quantityDelivered={quantityDelivered}
                  quantity={quantity}
                  quantityProgeny={quantityProgeny}
                />
              </BoldText>
            </Grid>

            <Grid {...gridItemProps}>
              <Subtitle>
                <ResponsiveText
                  mobile="Del"
                  tablet="Delivered"
                  desktop="Delivered"
                />
              </Subtitle>
              <BoldText>{filteredQuantityDelivered}</BoldText>
            </Grid>

            {species === Species.CATTLE && (
              <Grid {...gridItemProps}>
                <Subtitle>
                  <ResponsiveText
                    mobile="Avg. Wt."
                    tablet="Avg. Weight"
                    desktop="Avg. Weight"
                  />
                </Subtitle>
                <BoldText>{averageWeight}</BoldText>
              </Grid>
            )}

            {saleLotPriceUnitsAreEqual && (
              <Grid {...gridItemProps}>
                <Subtitle>Avg. Price</Subtitle>
                <BoldText>{averageCost}</BoldText>
              </Grid>
            )}
          </Grid>
        </Column>

        <RightActionColumn>
          <BuyerCardActionOptions
            buyerInfo={buyerInfo}
            saleLots={saleLots}
            buyerReportUrl={buyerReportUrl}
            deliveryReportUrl={deliveryReportUrl}
          />
          <OpenIndicator isOpen={isOpen} />
        </RightActionColumn>
      </Row>

      <PlainCollapse isOpen={isOpen}>
        {sortedBuyerWays.map(buyerWay => {
          const buyerHash = getBuyerHashFromSaleLot({
            buyer_id: buyerWay.saleLots[0].buyerId,
            buyer_way: {
              name: buyerWay.buyerWayRawName,
            },
          });
          return (
            <BuyerWayCard
              key={buyerHash}
              buyerHash={buyerHash}
              thirdPartyId={buyerWay.saleLots[0].thirdPartyId}
              basePath={basePath}
              showCommercialData={showCommercialData}
              agencyId={agencyId}
              saleLotIdFilter={saleLotIds}
              filterValues={filterValues}
            />
          );
        })}
      </PlainCollapse>
    </StatusCard>
  );
};

GroupedBuyerCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  basePath: PropTypes.string.isRequired,
  buyerReportUrl: PropTypes.string,
  deliveryReportUrl: PropTypes.string,
  quantity: PropTypes.number,
  status: PropTypes.string,
  hasExceptions: PropTypes.bool,
  buyerName: PropTypes.string,
  buyerWays: PropTypes.array.isRequired,
  quantityDelivered: PropTypes.number,
  saleLots: PropTypes.array.isRequired,
  showCommercialData: PropTypes.bool,
};

export default GroupedBuyerCard;
