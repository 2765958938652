import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  name: "name",
  ruleBookIds: "rule_book_ids",
  id: "id",
  weighbridgeSpeciesId: "weighbridge_species_id",
  saleyardSpecies: "saleyard_species",
  isAgentManagedNlisYard: "is_agent_managed_nlis_yard",
};

export const serializeSaleyard = buildSerializer(serializeMap, "saleyard");
export const deserializeSaleyard = buildDeserializer(serializeMap, "saleyard");
