import styled from "styled-components/macro";

const UnstyledButton = styled.button`
  all: unset; /* This should unset it all, but may not, sometimes, so do the major ones as well */
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  outline: inherit;
  cursor: pointer;
`;

export const CenteredUnstyledButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default UnstyledButton;
