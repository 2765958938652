import React, { useCallback } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { useDispatch } from "react-redux";

import { withNamespace } from "components/Form/FormikControls";
import { Grid } from "@material-ui/core";
import * as Yup from "yup";

import { ReportJobAction } from "actions/reportJob";
import { InputDateRange } from "components/DateRangePicker/dateRangePicker";
import {
  getEndDateValidation,
  getStartDateValidation,
} from "components/MultiSaleReports/commonValidation";
import {
  BulkReportFormProps,
  ModalActions,
} from "components/MultiSaleReports/ManyReportsForm/BulkReportFormCommon";

const validationSchema = Yup.object().shape({
  startDate: getStartDateValidation("endDate", { isYearOptionAllowed: true }),
  endDate: getEndDateValidation(),
});

type SupportedReportType =
  | "FinancialStatementReport"
  | "FinancialStatementZipReport";

type FormValueType = {
  report: SupportedReportType;
  startDate: Date | null;
  endDate: Date | null;
};

type FormInnerProps = {
  namespace?: string;
};

function FormInner(props: FormInnerProps): React.JSX.Element {
  const { namespace: ns = "" } = props;

  const { values: formValues, setValues } = useFormikContext<FormValueType>();

  const updateDates = useCallback(
    (startDate, endDate) => {
      const newValue: FormValueType = {
        ...formValues,
        // Naively apply the new dates to the root of the form values
        startDate,
        endDate,
      };
      setValues(newValue, true);
    },
    [formValues, setValues],
  );

  return (
    <>
      <Grid item xs={12}>
        <p>
          Generate Financial Statements containing start balance, closing
          balance, charges and payments within the given date range.
        </p>
      </Grid>

      <InputDateRange
        endDateFieldName={withNamespace(ns, "endDate")}
        endDateLabel="End date"
        includeTime={false}
        showRangeOptions
        showYearOptions
        startDateFieldName={withNamespace(ns, "startDate")}
        startDateLabel="Start date"
        updateDates={updateDates}
      />
    </>
  );
}

interface FormProps extends BulkReportFormProps {
  reportSlug: SupportedReportType;
}

export function CreateFinancialStatementReportJobsForm(
  props: FormProps,
): React.JSX.Element {
  const { reportSlug, onClose } = props;

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: FormValueType) => {
      dispatch(ReportJobAction.createBulk(values));
      onClose();
    },
    [dispatch, onClose],
  );

  const initialValues: FormValueType = {
    report: reportSlug,
    startDate: null,
    endDate: null,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Grid container spacing={2}>
          <FormInner namespace="" />
        </Grid>
        <ModalActions onClose={onClose} />
      </Form>
    </Formik>
  );
}
