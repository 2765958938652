import { createSelector } from "reselect";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import {
  getAuctionPens,
  getBusinesses,
  getConsignments,
  getReceivalLots,
  selectAgencyByDeploymentSaleIdLookup,
  selectEidsByReceivalLotIdLookup,
  selectResolvedCommentCountByReceivalLotIdLookup,
  selectUnresolvedCommentCountByReceivalLotIdLookup,
} from "selectors";

import { selectFilteredReceivalLotIds } from "selectors/globalSearch/receivalLotFilters";

export const selectReceivalLotAggridDataByIdLookup = createSelector(
  [
    selectFilteredReceivalLotIds,
    getAuctionPens,
    selectEidsByReceivalLotIdLookup,
    getBusinesses,
    getConsignments,
    selectAgencyByDeploymentSaleIdLookup,
    selectResolvedCommentCountByReceivalLotIdLookup,
    selectUnresolvedCommentCountByReceivalLotIdLookup,
    getReceivalLots,
  ],
  (
    filteredReceivalLotIds,
    auctionPens,
    eidsByReceivalLotIdLookup,
    businesses,
    consignments,
    agencyByDeploymentSaleIdLookup,
    resolvedCommentCountByReceivalLotIdLookup,
    unresolvedCommentCountByReceivalLotIdLookup,
    receivalLotByIdLookup,
  ) =>
    filteredReceivalLotIds.reduce((acc, receivalLotId) => {
      const receivalLot = receivalLotByIdLookup[receivalLotId];
      const receivalPen = auctionPens[receivalLot.receivalPenId];

      const receivalLotScansCount =
        eidsByReceivalLotIdLookup[receivalLot.id]?.length || 0;

      const receivalConsignment = consignments[receivalLot.consignmentId];

      const receivalLotVendor = businesses[receivalConsignment?.vendor_id];

      const receivalLotAgency =
        agencyByDeploymentSaleIdLookup[receivalConsignment?.deployment_sale];

      acc[receivalLot.id] = {
        receivalLot,
        penDisplayName: getAuctionPenDisplayName(receivalPen),
        mark: receivalLot.mark,
        quantity: receivalLot.quantity,
        agency: receivalLotAgency,
        scansCount: receivalLotScansCount,
        vendor: receivalLotVendor,
        NVD: receivalConsignment?.NVD,
        id: receivalLot.id,
        consignment: {
          vendor: receivalLotVendor,
          ...receivalConsignment,
        },
        resolvedCommentCount:
          resolvedCommentCountByReceivalLotIdLookup[receivalLot.id],
        unresolvedCommentCount:
          unresolvedCommentCountByReceivalLotIdLookup[receivalLot.id],
      };

      return acc;
    }, {}),
);

export const getReceivalLotAggridData = createSelector(
  [selectReceivalLotAggridDataByIdLookup],
  receivalLotAggridDataByIdLookup =>
    Object.values(receivalLotAggridDataByIdLookup),
);
