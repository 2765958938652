import { sortBy } from "lodash";
import flatten from "lodash/flatten";
import { createSelector } from "reselect";

import { TRANSFER_STATUS } from "constants/nlis";
import { Settings } from "constants/settings";

import { EMPTY_ARRAY } from "lib";

import {
  createLookupCombiner,
  createLookupSelectors,
  getBusinesses,
  getConsignments,
  getCurrentDeploymentSalesList,
  getNVDUploads,
  getProperties,
  getSettings,
  getVendorSplitConsignments,
  selectConsignmentIdsByVendorIdLookup,
  selectExceptionsByConsignmentIdLookup,
  selectExceptionsBySaleLotIdLookup,
  selectIsNoSaleBySaleLotIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectTakeFilesByConsignmentIdLookup,
  selectUnknownBusinessIdsByAgencyIdLookup,
  selectUnknownBusinessIdsByDeploymentSaleIdLookup,
  selectVendorIdByConsignmentIdLookup,
  selectVendorSplitConsignmentIdsByParentConsignmentIdLookup,
  selectWarningsByConsignmentIdLookup,
} from "selectors";

export const getConsignmentById = consignmentId => state =>
  getConsignments(state)[consignmentId] || null;

export const getVendorSplitConsignmentById =
  vendorSplitConsignmentId => state =>
    getVendorSplitConsignments(state)[vendorSplitConsignmentId] || null;

export const getVendorIdByConsignmentId = consignmentId => state =>
  selectVendorIdByConsignmentIdLookup(state)[consignmentId] || null;

export const getConsignmentFromIdOrSaleLotId = (
  consignments,
  saleLots,
  props,
) => {
  let consignmentId = props.consignmentId || props.match?.params.consignmentId;
  if (!consignmentId) {
    const saleLot = saleLots.find(
      s => s.id === props.saleLotId || props.match?.params.saleLotId,
    );
    consignmentId = saleLot && saleLot.consignment_id;
  }
  if (consignmentId) {
    return consignments.find(c => c.id === consignmentId) || {};
  }
  return {};
};

export const getConsignmentsWithVendorProperties = createSelector(
  [getBusinesses, getConsignments, getProperties],
  (businesses, consignments, properties) =>
    Object.values(consignments).map(consignment => {
      const withVendor = {
        vendor: {
          ...businesses[consignment.vendor_id],
        },
      };
      const withVendorProperty = {
        vendor_property: {
          ...properties[consignment.vendor_property_id],
        },
      };
      return {
        ...consignment,
        ...withVendor,
        ...withVendorProperty,
      };
    }),
);

export const getUntakenConsignmentsWithVendorProperties = createSelector(
  [getConsignmentsWithVendorProperties, selectTakeFilesByConsignmentIdLookup],
  (consignments, takeStatuses) =>
    consignments.filter(
      consignment =>
        !takeStatuses[consignment.id] ||
        takeStatuses[consignment.id].filter(
          ts => ts.status !== TRANSFER_STATUS.ROLLED_BACK,
        ).length === 0,
    ),
);

export const getConsignmentsByVendorNumber = createSelector(
  [getConsignments],
  consignments =>
    Object.values(consignments).reduce((cbv, consignment) => {
      if (consignment.vendorNumber) {
        if (!(consignment.vendorNumber in cbv)) {
          cbv[consignment.vendorNumber] = [];
        }
        cbv[consignment.vendorNumber].push(consignment);
      }
      return cbv;
    }, {}),
);

export const getConsignmentIdsByCarrierChargeId = createSelector(
  [getConsignments],
  consignments =>
    Object.values(consignments).reduce((acc, { id, carrierChargeId }) => {
      if (carrierChargeId) {
        if (!(carrierChargeId in acc)) {
          acc[carrierChargeId] = [];
        }
        acc[carrierChargeId].push(id);
      }
      return acc;
    }, {}),
);

export const selectConsignmentsForNVDUpload = createSelector(
  [
    getConsignments,
    selectExceptionsByConsignmentIdLookup,
    selectWarningsByConsignmentIdLookup,
    selectExceptionsBySaleLotIdLookup,
    getNVDUploads,
    getBusinesses,
    selectSaleLotIdsByConsignmentIdLookup,
    selectIsSoldBySaleLotIdLookup,
    selectIsNoSaleBySaleLotIdLookup,
  ],
  (
    consignments,
    exceptionsByConsignmentId,
    warningsByConsignmentId,
    exceptionsBySaleLotId,
    nvdUploads,
    businesses,
    saleLotIdsByConsignmentIdLookup,
    isSoldBySaleLotIdLookup,
    isNoSaleBySaleLotIdLookup,
  ) => {
    return flatten(
      Object.values(consignments).map(consignment => {
        const uploads = [];
        const hasConsignmentExceptions =
          exceptionsByConsignmentId[consignment.id].length > 0;
        const hasSaleLotExceptions = saleLotIdsByConsignmentIdLookup[
          consignment.id
        ]?.some(
          saleLotId =>
            exceptionsBySaleLotId[saleLotId] &&
            exceptionsBySaleLotId[saleLotId].length > 0,
        );
        const hasSoldLots = saleLotIdsByConsignmentIdLookup[
          consignment.id
        ]?.some(saleLotId => isSoldBySaleLotIdLookup[saleLotId]);
        const hasNoSaleLots = saleLotIdsByConsignmentIdLookup[
          consignment.id
        ]?.some(saleLotId => isNoSaleBySaleLotIdLookup[saleLotId]);

        const hasAnyExceptions =
          hasConsignmentExceptions || hasSaleLotExceptions;
        const hasWarnings = warningsByConsignmentId[consignment.id].length > 0;
        const consignmentDetails = {
          id: consignment.id,
          vendorName: businesses[consignment.vendor_id]?.name,
          hasExceptions: hasAnyExceptions,
          hasWarnings,
          canBeUploaded: !hasAnyExceptions && (hasSoldLots || hasNoSaleLots),
          isSubmissionPending: false,
          isSubmissionSuccessful: false,
        };
        if (nvdUploads[consignment.id]) {
          return uploads.concat(
            nvdUploads[consignment.id].map(nvdUpload => ({
              ...consignmentDetails,
              transactionId: nvdUpload.transactionId,
              status: nvdUpload.status,
              errorNotes: nvdUpload.errorNotes,
              created: nvdUpload.created,
              submitted: nvdUpload.submitted,
              // If status is error, and there are no others, or the others are  others are pending/ allow retry
              canBeUploaded:
                nvdUpload.status === TRANSFER_STATUS.FAILED &&
                (nvdUploads[consignment.id].length === 1 ||
                  !nvdUploads[consignment.id].some(otherNVDUpload =>
                    [
                      TRANSFER_STATUS.UNSUBMITTED,
                      TRANSFER_STATUS.PENDING,
                      TRANSFER_STATUS.ACCEPTED,
                      TRANSFER_STATUS.SYNCING,
                    ].includes(otherNVDUpload.status),
                  )),
              isSubmissionPending: [
                TRANSFER_STATUS.UNSUBMITTED,
                TRANSFER_STATUS.PENDING,
                TRANSFER_STATUS.SYNCING,
              ].includes(nvdUpload.status),
              isSubmissionSuccessful: [
                TRANSFER_STATUS.ACCEPTED,
                TRANSFER_STATUS.SUCCESS,
              ].includes(nvdUpload.status),
            })),
          );
        } else {
          uploads.push(consignmentDetails);
          return uploads;
        }
      }),
    );
  },
);

export const selectUnknownConsignmentIdsByDeploymentSaleIdLookup =
  createSelector(
    [
      selectUnknownBusinessIdsByDeploymentSaleIdLookup,
      selectConsignmentIdsByVendorIdLookup,
    ],
    (
      unknownBusinessIdsByDeploymentSaleIdLookup,
      consignmentIdsByVendorIdLookup,
    ) =>
      Object.entries(unknownBusinessIdsByDeploymentSaleIdLookup).reduce(
        (lookup, [deploymentSaleId, unknownBusinessIds]) => {
          lookup[deploymentSaleId] =
            flatten(
              unknownBusinessIds.map(
                businessId => consignmentIdsByVendorIdLookup[businessId],
              ),
            ) || EMPTY_ARRAY;
          return lookup;
        },
        {},
      ),
  );

export const getUnknownConsignmentIdsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectUnknownConsignmentIdsByDeploymentSaleIdLookup(state)[
      deploymentSaleId
    ];

export const selectUnknownConsignmentIdsByAgencyIdLookup = createSelector(
  [selectUnknownBusinessIdsByAgencyIdLookup, getCurrentDeploymentSalesList],
  (unknownBusinessIdsByAgencyIdLookup, currentDeploymentSalesList) =>
    currentDeploymentSalesList.reduce((lookup, deploymentSale) => {
      lookup[deploymentSale.livestock_agency_id] =
        unknownBusinessIdsByAgencyIdLookup[deploymentSale.livestock_agency_id];
      return lookup;
    }, {}),
);

export const getUnknownConsignmentIdsByAgencyId = agencyId => state =>
  selectUnknownConsignmentIdsByAgencyIdLookup(state)[agencyId];

function consignmentPickupAddressReducer(consignment, businessByIdLookup) {
  const {
    pickupAddress = null,
    useVendorAddressAsPickupAddress,
    vendor_id: vendorId,
  } = consignment;
  if (useVendorAddressAsPickupAddress) {
    // At present this is only used for Clearing Sales, which are only run by Livestock Agents.
    // There is a bug here if this selector is used by a saleyard admin.
    const { address = null } = businessByIdLookup[vendorId] || {};
    return address;
  }
  return pickupAddress;
}

export const [
  selectPickupAddressByConsignmentIdLookup,
  getPickupAddressByConsignmentId,
] = createLookupSelectors(
  [getConsignments, getBusinesses],
  createLookupCombiner(consignmentPickupAddressReducer),
);

export const getVendorSplitConsignmentIdsByParentConsignmentId =
  consignmentId => state =>
    selectVendorSplitConsignmentIdsByParentConsignmentIdLookup(state)[
      consignmentId
    ] || EMPTY_ARRAY;

export const selectLastAddedConsignment = createSelector(
  [getSettings, getConsignments],
  (settings, consignmentsLookup) => {
    const lastAddedConsignmentId = settings[Settings.lastAddedConsignmentId];
    return consignmentsLookup[lastAddedConsignmentId];
  },
);

const createLabel = (consignment, vendor, property) => {
  let label = "";
  if (consignment.livestock_agency_code) {
    label += `${consignment.livestock_agency_code} `;
  }
  if (consignment.vendorNumber) {
    label += `${consignment.vendorNumber} `;
  }
  if (vendor?.name) {
    label += `${vendor?.name} `;
  }
  if (consignment.quantity) {
    label += `- Hd: ${consignment.quantity} `;
  }
  if (consignment.NVD) {
    label += `- NVD: ${consignment.NVD} `;
  }
  if (property) {
    label += `- PIC: ${property.PIC} `;
  }
  return label;
};

export const selectLivestockSaleConsignmentOptions = createSelector(
  [getConsignments, getBusinesses, getProperties],
  (consignments, businesses, properties) => {
    return sortBy(
      Object.values(consignments).map(c => ({
        value: c.id,
        vendorName: businesses[c.vendor_id]?.name,
        label: createLabel(
          c,
          businesses[c.vendor_id],
          properties[c.vendor_property_id],
        ),
      })),
      "vendorName",
    );
  },
);

/**
 * For Clearing Sales, we want to show the vendor name and vendor number in the dropdown.
 * It doesn't make sense to the user to show agency code, quantity, NVD and PIC, though
 * the UI only allows the user to imply agency code and the consignment quantity will always
 * be 1.
 */
export const selectClearingSaleConsignmentOptions = createSelector(
  [getConsignments, getBusinesses],
  (consignmentByIdLookup, businessByIdLookup) => {
    return sortBy(
      Object.values(consignmentByIdLookup).map(consignment => {
        const vendor = businessByIdLookup[consignment.vendor_id] || {};
        return {
          value: consignment.id,
          vendorName: vendor.name,
          label: createLabel(
            { vendorNumber: consignment.vendorNumber },
            vendor,
          ),
        };
      }),
      "vendorName",
    );
  },
);
