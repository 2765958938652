import queryString from "query-string";

import {
  ACCEPT_IMPORT_PENDING_SCANS,
  ADD_CONSIGNMENT_SALEYARD_SCANS,
  ADD_SCAN_FROM_SCANNER,
  ADD_SCANS_FROM_BUFFER,
  ADD_SCANS_FROM_FILE,
  ADD_SCANS_FROM_MANUAL_INPUT,
  ADD_SCANS_FROM_SCANNER,
  ADD_SCANS_FROM_SOCKET,
  ADD_SCANS_IN_UNASSIGNED,
  BULK_MOVE_SCANS,
  CLEAR_IMPORTED_SCANS,
  CONFIRM_ERASE_SAVED_SCANS,
  CONFIRM_RESET_DEVICE,
  CONFIRM_SET_DEVICE_TIME,
  CONFIRM_START_DRAFT,
  CONNECT_TO_DEVICE,
  CONNECT_TO_HUB,
  DELETE_ALL_SCANS_FROM_UNASSIGNED,
  DELETE_SALE_LOT_SCANS_ACTION,
  DELETE_SCAN_FROM_SOCKET,
  DELETE_SCANS_FROM_DEVICE_IMPORT,
  DELETE_SCANS_FROM_FILE_IMPORT,
  DELETE_SCANS_FROM_UNASSIGNED,
  DEVICE_CONNECTED,
  DEVICE_MESSAGE_RESPONSE,
  DISCONNECT_FROM_DEVICE,
  DRAFTING_INFORMATION,
  ERASE_SAVED_SCANS,
  ERASE_SAVED_SCANS_RESULT,
  GENERIC_UPLOAD_SCANS_AND_DRAFTING_INFORMATION,
  GET_AVAILABLE_DEVICES,
  GET_DEVICE_TIME,
  GET_DEVICE_TIME_RESULT,
  GET_SCANS,
  GET_SCANS_CHANGES,
  GET_SCANS_CHANGES_SUCCESS,
  GET_SCANS_FAILURE,
  GET_SCANS_SUCCESS,
  HUB_CONNECTED,
  HUB_DISCONNECTED,
  IMPORT_MT_HOST_SESSIONS,
  KEEP_SCANS_IN_DEVICE_IMPORT,
  KEEP_SCANS_IN_FILE_IMPORT,
  KEEP_SCANS_IN_UNASSIGNED,
  PING_FROM_APP,
  READ_SAVED_SCANS,
  READ_SAVED_SCANS_RESULT,
  RESEND_SCAN,
  RESET_DEVICE,
  SCAN,
  SEND_DEVICE_MESSAGE,
  SEND_DEVICE_REQUEST_LIST_WEIGHING_SESSIONS,
  SEND_DEVICE_REQUEST_READ_WEIGHING_SESSION,
  SEND_RAW_HUB_PAYLOAD,
  SET_AVAILABLE_DEVICES,
  SET_DEVICE_STATUS,
  SET_DEVICE_TIME,
  SET_DEVICE_TIME_RESULT,
  SET_WEIGHING_SESSIONS,
  START_DRAFT,
  UPDATE_DEVICE_PROTOCOL_STATUS,
  UPLOAD_SCANS_ACTION,
  UPLOAD_SCANS_AND_DRAFTING_INFORMATION,
} from "constants/actionTypes";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

export const addScanFromScanner = (deviceId, scan) => ({
  type: ADD_SCAN_FROM_SCANNER,
  scan,
  deviceId,
});
export const addScansFromScanner = (deviceId, scans) => ({
  type: ADD_SCANS_FROM_SCANNER,
  deviceId,
  scans,
});

export const addScansFromFile = (fileName, scans) => ({
  type: ADD_SCANS_FROM_FILE,
  fileName,
  scans,
});

export const addScansFromManualInput = scans => ({
  type: ADD_SCANS_FROM_MANUAL_INPUT,
  scans,
});

export const addScansFromSocket = scans => {
  return {
    type: ADD_SCANS_FROM_SOCKET,
    scans,
  };
};

export const deleteScanFromSocket = message => {
  const { id } = message;
  return {
    type: DELETE_SCAN_FROM_SOCKET,
    EID: id,
  };
};

export const addScansFromBuffer = () => {
  return {
    type: ADD_SCANS_FROM_BUFFER,
  };
};

export const setAvailableDevices = devices => ({
  type: SET_AVAILABLE_DEVICES,
  devices,
});

export const updateAvailableDevices = () => ({
  type: GET_AVAILABLE_DEVICES,
});

export const hubConnected = () => ({
  type: HUB_CONNECTED,
});

export const connectToHub = () => ({
  type: CONNECT_TO_HUB,
});

export const hubDisconnected = () => ({
  type: HUB_DISCONNECTED,
});

export const connectToDevice = (deviceId, vendorName, resumeScan) => ({
  type: CONNECT_TO_DEVICE,
  deviceId,
  vendorName,
  resumeScan,
});

export const pingFromApp = () => ({
  type: PING_FROM_APP,
});

export const disconnectFromDevice = deviceId => ({
  type: DISCONNECT_FROM_DEVICE,
  deviceId,
});

export const deviceMessage = (deviceId, object) => ({
  type: DEVICE_MESSAGE_RESPONSE,
  deviceId,
  object,
});

export const deviceConnected = (deviceId, object) => ({
  type: DEVICE_CONNECTED,
  deviceId,
  object,
});

export const requestScans = sale => ({
  type: GET_SCANS,
  sale,
});

export const confirmStartDraft = (deviceId, draftName) => ({
  type: CONFIRM_START_DRAFT,
  deviceId,
  draftName,
});

export const requestScansChanges = sale => ({
  type: GET_SCANS_CHANGES,
  sale,
});

export const receiveScans = (
  livestockSaleId,
  scans,
  lastModifiedTimestamp,
) => ({
  type: GET_SCANS_SUCCESS,
  scans,
  lastModifiedTimestamp,
  meta: {
    livestockSaleId,
  },
});

export const receiveScansChanges = (
  livestockSaleId,
  scans,
  lastModifiedTimestamp,
) => ({
  type: GET_SCANS_CHANGES_SUCCESS,
  scans,
  lastModifiedTimestamp,
  meta: {
    livestockSaleId,
  },
});

export const requestScansError = error => ({
  type: GET_SCANS_FAILURE,
  error,
});

export const startDraft = (deviceId, sessionName) => ({
  type: START_DRAFT,
  deviceId,
  sessionName: sessionName || "",
});

export const resendScan = deviceId => ({
  type: RESEND_SCAN,
  deviceId,
});

export const readSavedScans = deviceId => ({
  type: READ_SAVED_SCANS,
  deviceId,
});

export const loadSavedScans = (deviceId, success, importedScans) => ({
  type: READ_SAVED_SCANS_RESULT,
  deviceId,
  success,
  importedScans,
});

export const confirmEraseSavedScans = deviceId => ({
  type: CONFIRM_ERASE_SAVED_SCANS,
  deviceId,
});

export const eraseSavedScans = deviceId => ({
  type: ERASE_SAVED_SCANS,
  deviceId,
});

export const eraseSavedScansResult = (deviceId, success) => ({
  type: ERASE_SAVED_SCANS_RESULT,
  deviceId,
  success,
});

export const getDeviceTime = deviceId => ({
  type: GET_DEVICE_TIME,
  deviceId,
});

export const getDeviceTimeResult = (deviceId, deviceTime) => ({
  type: GET_DEVICE_TIME_RESULT,
  deviceId,
  deviceTime,
});

export const confirmSetDeviceTime = deviceId => ({
  type: CONFIRM_SET_DEVICE_TIME,
  deviceId,
});

export const setDeviceTime = deviceId => ({
  type: SET_DEVICE_TIME,
  deviceId,
});

export const setDeviceStatus = (deviceId, status) => ({
  type: SET_DEVICE_STATUS,
  deviceId,
  status,
});

export const updateDeviceProtocolStatus = (deviceId, protocolStatus) => ({
  type: UPDATE_DEVICE_PROTOCOL_STATUS,
  deviceId,
  protocolStatus,
});

export const setDeviceTimeResult = (deviceId, success) => ({
  type: SET_DEVICE_TIME_RESULT,
  deviceId,
  success,
});

export const confirmResetDevice = deviceId => ({
  type: CONFIRM_RESET_DEVICE,
  deviceId,
});

export const resetDevice = deviceId => ({
  type: RESET_DEVICE,
  deviceId,
});

export const sendMessage = (deviceId, message) => ({
  type: SEND_DEVICE_MESSAGE,
  deviceId,
  message,
});

export const sendDeviceRequestListWeighingSessions = deviceIds => ({
  type: SEND_DEVICE_REQUEST_LIST_WEIGHING_SESSIONS,
  deviceIds,
});

export const sendDeviceRequestReadWeighingSession = (
  deviceId,
  sessionName,
) => ({
  type: SEND_DEVICE_REQUEST_READ_WEIGHING_SESSION,
  deviceId,
  sessionName,
});

export const importMtHostSessions = storedMtHostSettings => ({
  type: IMPORT_MT_HOST_SESSIONS,
  storedMtHostSettings,
});

export const deleteUnassignedScans = eids => ({
  type: DELETE_SCANS_FROM_UNASSIGNED,
  eids,
});

export const deleteAllUnassignedScans = () => ({
  type: DELETE_ALL_SCANS_FROM_UNASSIGNED,
});

export const keepUnassignedScans = eids => ({
  type: KEEP_SCANS_IN_UNASSIGNED,
  eids,
});

export const addUnassignedScans = scans => ({
  type: ADD_SCANS_IN_UNASSIGNED,
  scans,
});

export const deleteDeviceImportedScans = eids => ({
  type: DELETE_SCANS_FROM_DEVICE_IMPORT,
  eids,
});

export const keepDeviceImportedScans = eids => ({
  type: KEEP_SCANS_IN_DEVICE_IMPORT,
  eids,
});

export const deleteFileImportedScans = eids => ({
  type: DELETE_SCANS_FROM_FILE_IMPORT,
  eids,
});

export const keepFileImportedScans = eids => ({
  type: KEEP_SCANS_IN_FILE_IMPORT,
  eids,
});

/**
 * Migrates the selected eids from the import pending scans to the list of imported scans
 * @param {String} deviceId
 * @param {Array<{eid: string, groupName: string}>} selections
 */
export const acceptImportPendingScans = (deviceId, selections) => ({
  type: ACCEPT_IMPORT_PENDING_SCANS,
  deviceId,
  selections,
});

export const clearImportedScans = () => ({
  type: CLEAR_IMPORTED_SCANS,
});

export const addSaleyardScans = (scans, consignmentId) => ({
  type: ADD_CONSIGNMENT_SALEYARD_SCANS,
  scans,
  consignmentId,
});

export const bulkMoveScans = (eids, saleLotId) => ({
  type: BULK_MOVE_SCANS,
  eids,
  saleLotId,
});

export const addEidsToUnknownSaleLot = (eids, saleLotPayload) => ({
  type: SCAN.ADD_TO_UNKNOWN_SALELOT.ACTION,
  eids,
  saleLotPayload,
});

export const deleteSaleLotScansAction = (saleLotId, sale) => ({
  type: DELETE_SALE_LOT_SCANS_ACTION,
  saleLotId,
  sale,
});

export const deleteScanAction = scan => ({
  type: SCAN.DELETE.ACTION,
  scan,
});

export const uploadScansAction = (scans, saleLotId) => ({
  type: UPLOAD_SCANS_ACTION,
  scans,
  saleLotId,
});

export const uploadScansAndDraftingInformation = (scans, saleLotId) => ({
  type: UPLOAD_SCANS_AND_DRAFTING_INFORMATION,
  scans,
  saleLotId,
});

export const genericUploadScansAndDraftingInformation = (
  scans,
  callbackAction,
  callbackActionArg,
) => ({
  type: GENERIC_UPLOAD_SCANS_AND_DRAFTING_INFORMATION,
  scans,
  callbackAction,
  callbackActionArg,
});

export const addDraftingInformationFromSocket = message => ({
  type: DRAFTING_INFORMATION.CREATE.FROM_SOCKET,
  payload: message.object,
});

export const sendRawHubPayload = rawPayloadObj => ({
  type: SEND_RAW_HUB_PAYLOAD,
  rawPayloadObj,
});

const urlCreator = ({ changesSince, livestockSaleId, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/scans/", id, action),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const ScanAction = offlineActionCreator(
  SCAN,
  scan => scan,
  urlCreator,
  true,
);

export const setWeighingSessions = (deviceId, weighingSessions) => ({
  type: SET_WEIGHING_SESSIONS,
  deviceId,
  weighingSessions,
});
