import React from "react";

import { useSelector } from "react-redux";

import { Column } from "components/Layout";

import { EMPTY_ARRAY } from "lib";

import { caseInsensitiveCompare } from "lib/compare";

import {
  getSaleLotIdsByConsignmentId,
  getSaleLots,
  selectConsignmentIdsSaleLotIdsByBuyerIdLookup,
} from "selectors";

import { LotCard } from "./LotCard";

export const VendorSaleLotCards = React.memo(({ consignmentId, buyerId }) => {
  const saleLotIds =
    useSelector(state =>
      buyerId
        ? selectConsignmentIdsSaleLotIdsByBuyerIdLookup(state)[buyerId][
            consignmentId
          ]
        : getSaleLotIdsByConsignmentId(consignmentId)(state),
    ) || EMPTY_ARRAY;

  const saleLots = useSelector(getSaleLots);
  const sortedSaleLotIds = saleLotIds.sort((a, b) =>
    caseInsensitiveCompare(saleLots[a].lot_number, saleLots[b].lot_number),
  );

  return sortedSaleLotIds.map(saleLotId => (
    <Column key={saleLotId} margin={1}>
      <LotCard saleLotId={saleLotId} />
    </Column>
  ));
});
