import React, { useEffect } from "react";

import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import { Form, Formik, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { updateModalContext } from "actions";

import { ReceivalLotAction } from "actions/receivalLots";

import { extendedReceivalLotValidationSchema } from "components/ArrivalScanning/ReceivalLot/validationSchema";
import AuditLogLink from "components/AuditLog/AuditLogLink";
import { IconTextButton } from "components/Button";
import { ClickableCommentIcon } from "components/Comments/Icon";
import { Warning } from "components/ErrorMessage";
import { Button, SecondaryButton } from "components/Form";
import { ConsignmentAwareConsignmentField } from "components/Form/Fields/ConsignmentField";
import { Input, useSubmitHandler } from "components/Form/FormikControls";
import { PenPicker } from "components/Form/FormikControls/PenPicker";
import { ReceivalMarkInput } from "components/Form/FormikControls/ReceivalMarks";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { ScanLotDeleteDialog } from "components/ScanLotDeleteDialog";

import { PenTypes } from "constants/auctionPens";
import { AuditLogTypes } from "constants/auditLog";
import { CommentTypes } from "constants/comments";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import {
  LivestockSalePermissions,
  ReceivalLotPermissions,
} from "constants/permissions";

import { EMPTY_ARRAY } from "lib";

import { hasPermission } from "lib/permissions";

import {
  getEidsByReceivalLotId,
  getReceivalLotById,
  getReceivalLots,
  selectReceivalLotIdsByReceivalPenIdLookup,
} from "selectors";

import {
  useBoolean,
  useFieldValue,
  useHasLivestockSalePermission,
} from "hooks";

import { useHandleReceivalLotSubmission } from "./Hooks";

function ReceivalLotForm(props) {
  const {
    readOnly,
    receivalLotId,
    receivalPenId,
    newPenName,
    hideConsignmentInput,
  } = props;

  const formikProps = useFormikContext();

  const { setFieldValue, values } = formikProps;

  const { receivalPenId: selectedReceivalPenId } = values;

  const receivalLotInPenLookup = useSelector(
    selectReceivalLotIdsByReceivalPenIdLookup,
  );

  const receivalLotIdsInPen =
    receivalLotInPenLookup[selectedReceivalPenId || receivalPenId] ||
    EMPTY_ARRAY;

  const receivalLotLookup = useSelector(getReceivalLots);
  const mark = useFieldValue("mark");

  const markInPenConflictIds = React.useMemo(
    () =>
      receivalLotIdsInPen.filter(
        rLotId => receivalLotLookup[rLotId].mark === mark,
      ),
    [receivalLotIdsInPen, receivalLotLookup, mark],
  );

  const hasConflict =
    markInPenConflictIds.filter(rLotId => rLotId !== receivalLotId).length > 0;

  useEffect(() => {
    if (hasConflict) {
      setFieldValue("hasMarksConflict", true);
    } else {
      setFieldValue("hasMarksConflict", false);
    }
  }, [hasConflict, values.hasMarksConflict, mark, setFieldValue]);

  return (
    <>
      <Grid item xs={12}>
        <PenPicker
          label="Receival Pen"
          idName="receivalPenId"
          isCreateEnabled
          isCreateFreeFormEnabled
          isCreateArchetypeDerivedEnabled
          dataName="receivalPenData"
          penType={PenTypes.RECEIVING}
          required
          penName={newPenName}
        />
      </Grid>
      <Grid item xs={12}>
        <ReceivalMarkInput label="Mark" name="mark" disabled={readOnly} />
      </Grid>

      {hasConflict && (
        <Warning className="mt-12">
          {`${mark} has already been added to the Mark of another Lot in this
            Pen, multiple Lots with the same Marks shouldn't exist in the same 
            Receiving Pen`}
        </Warning>
      )}

      <Grid item xs={8}>
        <Input
          label="Head Count"
          name="quantity"
          disabled={readOnly}
          type="number"
        />
      </Grid>
      <Grid item xs={4}>
        <Input label="Scanned" name="scanCount" disabled />
      </Grid>

      {!hideConsignmentInput && (
        <Grid item xs={12}>
          <ConsignmentAwareConsignmentField
            isClearable
            name="consignmentId"
            label="Consignment"
            readOnly={readOnly}
            menuPortalTarget={document.body}
            receivalLotId={receivalLotId}
          />
        </Grid>
      )}
    </>
  );
}

ReceivalLotForm.propTypes = {
  readOnly: PropTypes.bool,
  receivalLotId: PropTypes.string,
  receivalPenId: PropTypes.string,
};

function Footer(props) {
  const { isReadOnly, isCreate, onClose, receivalLotId, newPenName } = props;

  const dispatch = useDispatch();

  const handleReceivalLotSubmission = useHandleReceivalLotSubmission();

  const { values } = useFormikContext();

  const newReceivalLotId = uuidv4();

  const onSubmit = () => {
    const { receivalPenId, receivalPenData } = values;

    if (newPenName) {
      // if we are creating a receival lot from another modal form
      // update the modal state to include the new receival lot id
      dispatch(
        updateModalContext(ModalTypes.EditReceivalLot, {
          newReceivalLotId,
        }),
      );
    }

    handleReceivalLotSubmission({
      receivalPenData,
      receivalLotId,
      receivalPenId,
      changeReasonLocation: "Edit Receival Lot Modal",
      allowCreate: true,
      newReceivalLotId,
    });

    onClose();
  };

  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  useSubmitHandler(false, setIsSubmitEnabled);

  return (
    <DialogActions>
      <SecondaryButton onClick={onClose}>Back</SecondaryButton>
      <Button
        data-tour={isCreate === null ? "create" : "save"}
        onClick={onSubmit}
        disabled={isReadOnly || !isSubmitEnabled}
      >
        {isCreate === null ? "Create" : "Save"}
      </Button>
    </DialogActions>
  );
}

Footer.propTypes = {
  isReadOnly: PropTypes.bool,
  isCreate: PropTypes.bool,
  onClose: PropTypes.func,
};

export function EditReceivalLotModal({
  receivalLotId,
  consignmentId,
  newPenName,
  hideConsignmentInput,
  onClose,
}) {
  const [isConfirmDeleteVisible, showConfirmDelete, hideConfirmDelete] =
    useBoolean(false);

  const receivalLot = useSelector(getReceivalLotById(receivalLotId)) || {};

  const scanCount = useSelector(state =>
    receivalLotId ? getEidsByReceivalLotId(receivalLotId)(state).length : 0,
  );

  const receivalPenId = receivalLot?.receivalPenId || null;

  const hasUpdatePermission = hasPermission(
    receivalLot,
    ReceivalLotPermissions.update,
  );
  const hasCreatePermission = useHasLivestockSalePermission(
    LivestockSalePermissions.canAddReceivalLot,
  );
  const isReadOnly = receivalLotId
    ? !hasUpdatePermission
    : !hasCreatePermission;

  const dispatch = useDispatch();

  const initialValues = {
    scanCount,
    hasMarksConflict: false,
    quantity: 1,
    ...receivalLot,
  };

  // when we open the form and we have passed a consignment Id in (ie: from just creating one)
  // we want to populate the consignment field with the newly created consignment
  // not the one set on the lot.
  if (consignmentId) {
    initialValues.consignmentId = consignmentId;
  }

  const onDelete = () => {
    dispatch(ReceivalLotAction.delete(receivalLotId));
    onClose();
  };

  return (
    <ZoomyDialog open onClose={onClose}>
      <WaitForSync
        requiredData={[
          ApiModel.AGENCIES,
          ApiModel.RECEIVAL_LOTS,
          ApiModel.CONSIGNMENTS,
          ApiModel.AUCTION_PENS,
          ApiModel.PEN_ARCHETYPES,
        ]}
      >
        <DialogTitle onClose={onClose}>
          <Row justifyBetween alignCenter>
            <Row alignCenter>
              {receivalLotId && (
                <AuditLogLink
                  auditLogType={AuditLogTypes.RECEIVAL_LOT}
                  dataId={receivalLotId}
                  returnTo={window.location.hash}
                />
              )}
              &nbsp; {receivalLotId ? "Edit" : "Create"} Receival Lot
            </Row>
            {receivalLotId && (
              <ClickableCommentIcon
                commentType={CommentTypes.RECEIVAL_LOT}
                commentTypeId={receivalLotId}
                returnTo={window.location.hash}
              />
            )}
          </Row>
        </DialogTitle>
        <Formik
          validationSchema={extendedReceivalLotValidationSchema}
          initialValues={initialValues}
          enableReinitialize
          validateOnBlur
        >
          <>
            <Form>
              <DialogContent dividers>
                <Grid spacing={2} container>
                  <ReceivalLotForm
                    readOnly={isReadOnly}
                    receivalLotId={receivalLotId}
                    receivalPenId={receivalPenId}
                    newPenName={newPenName}
                    consignmentId={consignmentId}
                    hideConsignmentInput={hideConsignmentInput}
                  />

                  {receivalLotId ? (
                    <IconTextButton
                      icon={faTrash}
                      color="gray40"
                      onClick={showConfirmDelete}
                    >
                      Delete Receival Lot
                    </IconTextButton>
                  ) : null}
                </Grid>
              </DialogContent>

              <Footer
                onClose={onClose}
                isReadOnly={isReadOnly}
                isCreate={!receivalLotId}
                receivalLotId={receivalLotId}
                newPenName={newPenName}
              />
            </Form>
          </>
        </Formik>

        <ScanLotDeleteDialog
          penType={PenTypes.RECEIVING}
          isOpen={isConfirmDeleteVisible}
          onCancel={hideConfirmDelete}
          onDelete={onDelete}
          scanCount={scanCount}
        />
      </WaitForSync>
    </ZoomyDialog>
  );
}

EditReceivalLotModal.propTypes = {
  receivalLotId: PropTypes.string,
  consignmentId: PropTypes.string,
  onClose: PropTypes.func,
};
