export const PageSize = {
  A3: "A3",
  A4: "A4",
};
export const PlacardVersions = {
  V1: "v1",
  V2: "v2",
  V3: "v3",
  V4: "v4",
  V5: "v5",
};
