import styled from "styled-components/macro";

const TableWrapper = styled.div.attrs({
  className: "ag-theme-balham safari_only",
})`
  flex-grow: 1;
  height: 1px;
`;

export const FlexWrapper = styled.div.attrs({
  className: "ag-theme-balham safari_only",
})`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const CollapseTableWrapper = styled(TableWrapper)`
  height: auto;
`;

export const ModalTableWrapper = styled(TableWrapper)(
  ({ minHeight = 250 }) => `
  min-height: ${minHeight}px;
`,
);

export const AlmostFullScreenModalTableWrapper = styled(TableWrapper)`
  height: 94%;
`;

export default TableWrapper;
