import React from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { SlimSecondaryButton } from "components/Button";
import { Row } from "components/Layout";
import Property from "components/Property";

const FormRowWrapper = styled(Row)`
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 0 24px 0 12px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grayF7 : theme.colors.controlBackground};
  ${({ theme, error }) => {
    const borderColor = error ? theme.colors.inputError : theme.colors.gray78;
    return `border: 1px solid ${borderColor};`;
  }};
`;

export const FormRow = ({
  business,
  property,
  propertyPending,
  handleClick,
  showProperty,
  businessFieldName,
  disabled,
  onClear,
  clearable,
}) => {
  const hasValue = business || property;

  return (
    <FormRowWrapper
      onClick={disabled ? null : handleClick}
      data-tour={businessFieldName}
      disabled={disabled}
    >
      <Row flexGrow>
        {business ? business.name : "No Business"}
        {showProperty && [
          " - ",
          property ? (
            <Property
              key="property"
              businessId={business?.id}
              propertyId={property.id}
            />
          ) : propertyPending ? (
            "PIC Syncing..."
          ) : (
            "No PIC"
          ),
        ]}
      </Row>

      {!disabled && clearable && hasValue && (
        <Row onClick={onClear}>
          <SlimSecondaryButton type="button">
            Clear <FontAwesomeIcon icon={faTimes} />
          </SlimSecondaryButton>
        </Row>
      )}
      <Row>
        <FontAwesomeIcon icon={faSearch} />
      </Row>
    </FormRowWrapper>
  );
};
