import { intersection } from "lodash";
import { createSelector } from "reselect";

import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { getConsignmentCode } from "lib/consignments";
import { isSaleLotInProgress, getSortedMarkDetails } from "lib/saleLot";

import {
  getConsignments,
  getGlobalSearchBySearchField,
  getSaleLots,
  selectAgencyIdBySaleLotId,
  selectCanHaveProgenyBySaleLotIdLookup,
  selectConsignmentHasImageLookup,
  selectConsignmentHasVideoLookup,
  selectExceptionsByConsignmentIdLookup,
  selectExceptionsBySaleLotIdLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectSaleLotHasImageLookup,
  selectSaleLotHasVideoLookup,
  selectSaleLotIdByEidLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectScanStatusBySaleLotIdLookup,
  selectVendorIdBySaleLotIdLookup,
  selectIsWeighedBySaleLotIdLookup,
} from "selectors";

import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";

import {
  isAuctionPlusCompliantBySaleLotComparator,
  isStockLiveCompliantBySaleLotComparator,
  saleLotsFilteredBuyerIdsComparator,
  saleLotsFilteredThirdPartyIdsComparator,
  saleLotsFilteredBuyerHashesComparator,
  saleLotsFilteredSaleRoundIdsComparator,
  saleLotsFilteredIsBuyerBalancedComparator,
  saleLotsFilteredIsCountedComparator,
  saleLotsFilteredHaveOverflowPenCompararator,
  saleLotsFilteredIsDeliveredComparator,
  saleLotsFilteredAuctionPenComparator,
  saleLotsFilteredDeliveryPenComparator,
  saleLotsFilteredLanesComparator,
  saleLotsFilteredLabelsComparator,
  saleLotsFilteredPricingTypesComparator,
  saleLotsFilteredPropertyIdComparator,
  saleLotsFilteredCheckpointComparator,
  hasBuyerExceptionsBySaleLotFilteredComparator,
} from "./comparators";
import {
  filterAndMapObjectIdsWithLookup,
  lookupFilterSelectorFactory,
  rawObjectFilterSelectorFactory,
} from "./lib";

const selectUnfilteredSaleLotIds = createSelector([getSaleLots], saleLots =>
  Object.keys(saleLots),
);

const selectBuyerFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Buyer,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredBuyerIdsComparator,
);

const selectThirdPartyFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.ThirdParty,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredThirdPartyIdsComparator,
);

const selectBuyerAndBuyerWayFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.BuyerAndBuyerWay,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredBuyerHashesComparator,
);

const selectVendorFilteredSaleLotIds = lookupFilterSelectorFactory(
  selectVendorIdBySaleLotIdLookup,
  GlobalSearchFields.Vendor,
  selectUnfilteredSaleLotIds,
  vendorIds =>
    ([ignored, vendorId]) =>
      vendorIds.includes(vendorId),
);

export const selectSaleRoundFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.SaleRound,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredSaleRoundIdsComparator,
);

const selectAgencyFilteredSaleLotIds = lookupFilterSelectorFactory(
  selectAgencyIdBySaleLotId,
  GlobalSearchFields.Agency,
  selectUnfilteredSaleLotIds,
  agencyIds =>
    ([ignored, agencyId]) =>
      agencyIds.includes(agencyId),
);

const selectHasArrivedFilteredSaleLotIds = createSelector(
  [
    getConsignments,
    selectSaleLotIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasArrived),
    selectUnfilteredSaleLotIds,
  ],
  (
    consignments,
    saleLotIdsByConsignmentIdLookup,
    hasArrived,
    unfilteredSaleLotIds,
  ) =>
    hasArrived === null
      ? unfilteredSaleLotIds
      : Object.values(consignments)
          .filter(consignment => consignment.hasArrived === hasArrived[0])
          .reduce(
            (acc, consignment) =>
              acc.concat(saleLotIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectIsBuyerBalancedFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.IsBuyerBalanced,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredIsBuyerBalancedComparator,
);

const selectIsVendorPostSaleBalancedFilteredSaleLotIds = createSelector(
  [
    getSaleLots,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPostSaleVendorBalanced),
    selectUnfilteredSaleLotIds,
  ],
  (
    saleLots,
    isPostSaleBalancedByConsignmentId,
    isVendorBalanced,
    unfilteredSaleLotIds,
  ) =>
    isVendorBalanced === null
      ? unfilteredSaleLotIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isPostSaleBalancedByConsignmentId[saleLot.consignment_id] ===
              isVendorBalanced[0],
          )
          .map(saleLot => saleLot.id),
);

const selectIsVendorPreSaleBalancedFilteredSaleLotIds = createSelector(
  [
    getSaleLots,
    selectIsPreSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPreSaleVendorBalanced),
    selectUnfilteredSaleLotIds,
  ],
  (
    saleLots,
    isPreSaleBalancedByConsignmentId,
    isVendorPresaleBalanced,
    unfilteredSaleLotIds,
  ) =>
    isVendorPresaleBalanced === null
      ? unfilteredSaleLotIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isPreSaleBalancedByConsignmentId[saleLot.consignment_id] ===
              isVendorPresaleBalanced[0],
          )
          .map(saleLot => saleLot.id),
);

const selectIsCountedFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.IsCounted,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredIsCountedComparator,
);

const selectIsDeliveredFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.IsDelivered,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredIsDeliveredComparator,
);

const selectIsEidCompliantFilteredSaleLotIds = lookupFilterSelectorFactory(
  selectScanStatusBySaleLotIdLookup,
  GlobalSearchFields.IsEidCompliant,
  selectUnfilteredSaleLotIds,
  isEidCompliant =>
    ([ignored, scanStatus]) =>
      (scanStatus === ScanStatus.PASS) === isEidCompliant[0],
);

// I don't think this is ever going to be useful - they're not SALELOT filters per se?
const selectHasConsignmentScansFilteredSaleLotIds = selectUnfilteredSaleLotIds;

export const selectHasVendorExceptionsFilteredSaleLotIds = createSelector(
  [
    getSaleLots,
    selectExceptionsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasVendorExceptions),
    selectUnfilteredSaleLotIds,
  ],
  (
    saleLots,
    exceptionsByConsignmentId,
    hasVendorExceptions,
    unfilteredSaleLotIds,
  ) =>
    hasVendorExceptions === null
      ? unfilteredSaleLotIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              (exceptionsByConsignmentId[saleLot.consignment_id].length !==
                0) ===
              hasVendorExceptions[0],
          )
          .map(saleLot => saleLot.id),
);

const selectHasBuyerExceptionsFilteredSaleLotIds = lookupFilterSelectorFactory(
  selectExceptionsBySaleLotIdLookup,
  GlobalSearchFields.HasBuyerExceptions,
  selectUnfilteredSaleLotIds,
  hasBuyerExceptionsBySaleLotFilteredComparator,
);

const selectHasOverflowFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.HasOverflow,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredHaveOverflowPenCompararator,
);

const selectHasWeightFilteredSaleLotIds = createSelector(
  [
    getSaleLots,
    selectIsWeighedBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredSaleLotIds,
  ],
  (saleLots, isWeighedBySaleLotIdLookup, hasWeight, unfilteredSaleLotIds) => {
    return hasWeight === null
      ? unfilteredSaleLotIds
      : Object.values(saleLots)
          .filter(
            saleLot => isWeighedBySaleLotIdLookup[saleLot.id] === hasWeight[0],
          )
          .map(lot => lot.id);
  },
);

const selectIsInProgressFilteredSaleLotIds = createSelector(
  [
    getSaleLots,
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredSaleLotIds,
  ],
  (saleLots, consignments, isInProgress, unfilteredSaleLotIds) =>
    isInProgress === null
      ? unfilteredSaleLotIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isSaleLotInProgress(
                saleLot,
                consignments[saleLot.consignment_id],
              ) === isInProgress[0],
          )
          .map(saleLot => saleLot.id),
);

const selectIsSoldFilteredSaleLotIds = lookupFilterSelectorFactory(
  selectIsSoldBySaleLotIdLookup,
  GlobalSearchFields.IsSold,
  selectUnfilteredSaleLotIds,
  isSoldFilterValue =>
    ([ignored, isSold]) =>
      isSold === isSoldFilterValue[0],
);

const selectIsAuctionsPlusCompliantFilteredSaleLotIds =
  lookupFilterSelectorFactory(
    selectIsIntegrationCompliantBySaleLotIdLookup,
    GlobalSearchFields.IsAuctionsPlusCompliant,
    selectUnfilteredSaleLotIds,
    isAuctionPlusCompliantBySaleLotComparator,
  );

const selectIsStockLiveCompliantFilteredSaleLotIds =
  lookupFilterSelectorFactory(
    selectIsIntegrationCompliantBySaleLotIdLookup,
    GlobalSearchFields.IsStockLiveCompliant,
    selectUnfilteredSaleLotIds,
    isStockLiveCompliantBySaleLotComparator,
  );

const selectAuctionPenFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.AuctionPen,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredAuctionPenComparator,
);

const selectDeliveryPenFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.DeliveryPen,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredDeliveryPenComparator,
);

const selectOverflowPenFilteredSaleLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.OverflowPen),
    selectUnfilteredSaleLotIds,
  ],
  (overflowPenIds, unfilteredSaleLotIds) =>
    overflowPenIds === null ? unfilteredSaleLotIds : overflowPenIds,
);

const selectLaneFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Lane,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredLanesComparator,
);

const selectLabelFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Label,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredLabelsComparator,
);

const selectPricingTypeFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.PricingType,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredPricingTypesComparator,
);

const selectHasProgenyFilteredSaleLotIds = lookupFilterSelectorFactory(
  selectCanHaveProgenyBySaleLotIdLookup,
  GlobalSearchFields.HasProgeny,
  selectUnfilteredSaleLotIds,
  hasProgeny =>
    ([ignored, canHaveProgeny]) =>
      canHaveProgeny === hasProgeny[0],
);

const selectScanFilteredSaleLotIds = createSelector(
  [
    selectSaleLotIdByEidLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Scan),
    selectUnfilteredSaleLotIds,
  ],
  (saleLotIdByEidLookup, eids, unfilteredSaleLotIds) =>
    eids === null
      ? unfilteredSaleLotIds
      : eids.map(eid => saleLotIdByEidLookup[eid]).filter(Boolean),
);

const selectVendorNumberFilteredSaleLotIds = createSelector(
  [
    getConsignments,
    selectSaleLotIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorNumber),
    selectUnfilteredSaleLotIds,
  ],
  (
    consignments,
    saleLotIdsByConsignmentIdLookup,
    vendorNumbers,
    unfilteredSaleLotIds,
  ) =>
    vendorNumbers === null
      ? unfilteredSaleLotIds
      : Object.values(consignments)
          .filter(consignment =>
            vendorNumbers.includes(getConsignmentCode(consignment)),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(saleLotIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectVendorPicFilteredSaleLotIds = createSelector(
  [
    getConsignments,
    selectSaleLotIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorPic),
    selectUnfilteredSaleLotIds,
  ],
  (
    consignments,
    saleLotIdsByConsignmentIdLookup,
    vendorPic,
    unfilteredSaleLotIds,
  ) =>
    vendorPic === null
      ? unfilteredSaleLotIds
      : Object.values(consignments)
          .filter(consignment =>
            vendorPic.includes(consignment.vendor_property_id),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(saleLotIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectBuyerPicFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.BuyerPic,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredPropertyIdComparator,
);

const selectCheckpointFilteredSaleLotIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Checkpoint,
  selectUnfilteredSaleLotIds,
  saleLotsFilteredCheckpointComparator,
);

const selectisSaleLotImageUploadedSaleLotIds = filterAndMapObjectIdsWithLookup(
  getSaleLots,
  GlobalSearchFields.IsSaleLotImageUploaded,
  "id",
  selectUnfilteredSaleLotIds,
  getConsignments,
  selectSaleLotHasImageLookup,
);

const selectIsConsignmentImageUploadedSaleLotIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentImageUploaded,
    "consignment_id",
    selectUnfilteredSaleLotIds,
    getSaleLots,
    selectConsignmentHasImageLookup,
    (consignment, objectKey, lots) => {
      const saleLots = Object.values(lots).filter(
        lot => lot.consignment_id === consignment.id,
      );
      return saleLots.map(saleLot => saleLot.id);
    },
  );

const selectIsSaleLotVideoUploadedSaleLotIds = filterAndMapObjectIdsWithLookup(
  getSaleLots,
  GlobalSearchFields.IsSaleLotVideoUploaded,
  "id",
  selectUnfilteredSaleLotIds,
  getConsignments,
  selectSaleLotHasVideoLookup,
);

const selectIsConsignmentVideoUploadedSaleLotIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentVideoUploaded,
    "consignment_id",
    selectUnfilteredSaleLotIds,
    getSaleLots,
    selectConsignmentHasVideoLookup,
    (consignment, objectKey, lots) => {
      const saleLots = Object.values(lots).filter(
        lot => lot.consignment_id === consignment.id,
      );
      return saleLots.map(saleLot => saleLot.id);
    },
  );

const selectReceivalPenSaleLotIds = createSelector(
  [
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.ReceivalPen),
    selectUnfilteredSaleLotIds,
    selectSaleLotIdsByConsignmentIdLookup,
  ],
  (
    consignments,
    receivalPens,
    unfilteredSaleLotIds,
    saleLotIdsByConsignmentIdLookup,
  ) =>
    receivalPens === null
      ? unfilteredSaleLotIds
      : Object.values(consignments)
          .filter(consignment =>
            receivalPens.includes(consignment.receiving_pen),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(saleLotIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectMarksFilteredSaleLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredSaleLotIds,
  ],
  (saleLots, marks, unfilteredSaleLotIds) =>
    marks === null
      ? unfilteredSaleLotIds
      : Object.values(saleLots)
          .filter(saleLot =>
            marks.some(mark => mark === getSortedMarkDetails(saleLot)),
          )
          .map(lot => lot.id),
);

export const selectFilteredSaleLotIds = createSelector(
  [
    selectAgencyFilteredSaleLotIds,
    selectAuctionPenFilteredSaleLotIds,
    selectBuyerFilteredSaleLotIds,
    selectBuyerAndBuyerWayFilteredSaleLotIds,
    selectDeliveryPenFilteredSaleLotIds,
    selectHasArrivedFilteredSaleLotIds,
    selectHasBuyerExceptionsFilteredSaleLotIds,
    selectHasConsignmentScansFilteredSaleLotIds,
    selectHasOverflowFilteredSaleLotIds,
    selectHasProgenyFilteredSaleLotIds,
    selectHasVendorExceptionsFilteredSaleLotIds,
    selectHasWeightFilteredSaleLotIds,
    selectIsBuyerBalancedFilteredSaleLotIds,
    selectIsCountedFilteredSaleLotIds,
    selectIsDeliveredFilteredSaleLotIds,
    selectIsEidCompliantFilteredSaleLotIds,
    selectIsInProgressFilteredSaleLotIds,
    selectIsSoldFilteredSaleLotIds,
    selectIsVendorPreSaleBalancedFilteredSaleLotIds,
    selectIsVendorPostSaleBalancedFilteredSaleLotIds,
    selectLabelFilteredSaleLotIds,
    selectLaneFilteredSaleLotIds,
    selectOverflowPenFilteredSaleLotIds,
    selectPricingTypeFilteredSaleLotIds,
    selectSaleRoundFilteredSaleLotIds,
    selectScanFilteredSaleLotIds,
    selectThirdPartyFilteredSaleLotIds,
    selectVendorFilteredSaleLotIds,
    selectVendorNumberFilteredSaleLotIds,
    selectVendorPicFilteredSaleLotIds,
    selectBuyerPicFilteredSaleLotIds,
    selectCheckpointFilteredSaleLotIds,
    selectIsAuctionsPlusCompliantFilteredSaleLotIds,
    selectIsStockLiveCompliantFilteredSaleLotIds,
    selectisSaleLotImageUploadedSaleLotIds,
    selectIsConsignmentImageUploadedSaleLotIds,
    selectIsSaleLotVideoUploadedSaleLotIds,
    selectIsConsignmentVideoUploadedSaleLotIds,
    selectReceivalPenSaleLotIds,
    selectMarksFilteredSaleLotIds,
  ],
  (
    agencyFilteredSaleLotIds,
    auctionPenFilteredSaleLotIds,
    buyerFilteredSaleLotIds,
    buyerAndBuyerWayFilteredSaleLotIds,
    deliveryPenFilteredSaleLotIds,
    hasArrivedFilteredSaleLotIds,
    hasBuyerExceptionsFilteredSaleLotIds,
    hasConsignmentScansFilteredSaleLotIds,
    hasOverflowFilteredSaleLotIds,
    hasProgenyFilteredSaleLotIds,
    hasVendorExceptionsFilteredSaleLotIds,
    hasWeightFilteredSaleLotIds,
    isBuyerBalancedFilteredSaleLotIds,
    isCountedFilteredSaleLotIds,
    isDeliveredFilteredSaleLotIds,
    isEidCompliantFilteredSaleLotIds,
    isInProgressFilteredSaleLotIds,
    isSoldFilteredSaleLotIds,
    isVendorPreSaleBalancedFilteredSaleLotIds,
    isVendorPostSaleBalancedFilteredSaleLotIds,
    labelFilteredSaleLotIds,
    laneFilteredSaleLotIds,
    overflowPenFilteredSaleLotIds,
    pricingTypeFilteredSaleLotIds,
    saleRoundFilteredSaleLotIds,
    scanFilteredSaleLotIds,
    thirdPartyFilteredSaleLotIds,
    vendorFilteredSaleLotIds,
    vendorNumberFilteredSaleLotIds,
    vendorPicFilteredSaleLotIds,
    buyerPicFilteredSaleLotIds,
    checkpointFilteredSaleLotIds,
    isAuctionsPlusCompliantFilteredSaleLotIds,
    isStockLiveCompliantFilteredSaleLotIds,
    isSaleLotImageUploadedSaleLotIds,
    isConsignmentImageUploadedSaleLotIds,
    isSaleLotVideoUploadedSaleLotIds,
    isConsignmentVideoUploadedSaleLotIds,
    receivalPenSaleLotIds,
    marksFilteredSaleLotIds,
  ) =>
    intersection(
      agencyFilteredSaleLotIds,
      auctionPenFilteredSaleLotIds,
      buyerFilteredSaleLotIds,
      buyerAndBuyerWayFilteredSaleLotIds,
      deliveryPenFilteredSaleLotIds,
      hasArrivedFilteredSaleLotIds,
      hasBuyerExceptionsFilteredSaleLotIds,
      hasConsignmentScansFilteredSaleLotIds,
      hasOverflowFilteredSaleLotIds,
      hasProgenyFilteredSaleLotIds,
      hasVendorExceptionsFilteredSaleLotIds,
      hasWeightFilteredSaleLotIds,
      isBuyerBalancedFilteredSaleLotIds,
      isCountedFilteredSaleLotIds,
      isDeliveredFilteredSaleLotIds,
      isEidCompliantFilteredSaleLotIds,
      isInProgressFilteredSaleLotIds,
      isSoldFilteredSaleLotIds,
      isVendorPreSaleBalancedFilteredSaleLotIds,
      isVendorPostSaleBalancedFilteredSaleLotIds,
      labelFilteredSaleLotIds,
      laneFilteredSaleLotIds,
      overflowPenFilteredSaleLotIds,
      pricingTypeFilteredSaleLotIds,
      saleRoundFilteredSaleLotIds,
      scanFilteredSaleLotIds,
      thirdPartyFilteredSaleLotIds,
      vendorFilteredSaleLotIds,
      vendorNumberFilteredSaleLotIds,
      vendorPicFilteredSaleLotIds,
      buyerPicFilteredSaleLotIds,
      checkpointFilteredSaleLotIds,
      isAuctionsPlusCompliantFilteredSaleLotIds,
      isStockLiveCompliantFilteredSaleLotIds,
      isSaleLotImageUploadedSaleLotIds,
      isConsignmentImageUploadedSaleLotIds,
      isSaleLotVideoUploadedSaleLotIds,
      isConsignmentVideoUploadedSaleLotIds,
      receivalPenSaleLotIds,
      marksFilteredSaleLotIds,
    ),
);
