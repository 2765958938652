function renderFixed(value, params, configKey) {
  const { decimalPlaces = null } = params.column.colDef[configKey] || {};
  if (typeof value !== "number") {
    return null;
  }
  if (typeof decimalPlaces !== "number") {
    return value;
  }
  return value.toFixed(decimalPlaces);
}

export function avgAggFunction(params) {
  const { values } = params;
  // the average will be the sum / count
  let sum = 0;
  let count = 0;
  let avg = null;

  values.forEach(value => {
    const groupNode =
      value !== null && value !== undefined && typeof value === "object";
    if (groupNode) {
      // we are aggregating groups, so we take the
      // aggregated values to calculate a weighted average
      sum += value.avg * value.count;
      count += value.count;
    }
    // skip values that are not numbers (ie skip empty values)
    else if (typeof value === "number") {
      sum += value;
      count++;
    }
  });

  // avoid divide by zero error
  if (count !== 0) {
    avg = sum / count;
  }

  // the result will be an object. when this cell is rendered, only the avg is shown.
  // however when this cell is part of another aggregation, the count is also needed
  // to create a weighted average for the next level.
  return {
    count,
    avg,
    // the grid by default uses toString to render values for an object, so this
    // is a trick to get the default cellRenderer to display the avg value
    toString() {
      return renderFixed(this.avg, params, "aggFuncParams");
    },
  };
}
