import { createSelector } from "reselect";

import {
  getAges,
  getBreeds,
  getBusinesses,
  getConsignments,
  getCurrentSale,
  getProperties,
  getSaleLots,
  getSexes,
  selectScannedCountBySaleLotIdLookup,
  selectScannedPercentBySaleLotIdLookup,
  selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
  selectXeroBusinessesBySaleLotIdLookup,
} from "selectors";

export const selectPrivateSalesSaleLotsData = createSelector(
  [
    getSaleLots,
    getConsignments,
    getBusinesses,
    getCurrentSale,
    getProperties,
    getAges,
    getSexes,
    getBreeds,
    selectScannedCountBySaleLotIdLookup,
    selectScannedPercentBySaleLotIdLookup,
    selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
    selectXeroBusinessesBySaleLotIdLookup,
  ],
  (
    saleLots,
    consignments,
    businesses,
    livestockSale,
    properties,
    ages,
    sexes,
    breeds,
    scannedCountBySaleLotIdLookup,
    scannedPercentBySaleLotIdLookup,
    unresolvedSaleLotCommentCount,
    xeroBusinessesBySaleLotIdLookup,
  ) =>
    Object.values(saleLots).map(saleLot => {
      const xeroBusinesses = xeroBusinessesBySaleLotIdLookup[saleLot.id];
      const consignment = consignments[saleLot.consignment_id];
      saleLot.xeroBuyer = xeroBusinesses.buyer;
      saleLot.xeroVendor = xeroBusinesses.vendor;
      return {
        saleLot,
        consignment,
        livestockSale,
        vendor: businesses[consignment?.vendor_id],
        vendorProperty: properties[consignment?.vendor_property_id],
        carrier: businesses[consignment?.carrierCharge?.carrier],
        buyer: businesses[saleLot.buyer_id],
        buyerProperty: properties[saleLot.destination_property_id],
        age: ages[saleLot.age_id],
        breed: breeds[saleLot.breed_id],
        sex: sexes[saleLot.sex_id],
        scanCount: scannedCountBySaleLotIdLookup[saleLot.id],
        scanPercent: scannedPercentBySaleLotIdLookup[saleLot.id],
        unresolvedSaleLotCommentCount:
          unresolvedSaleLotCommentCount[saleLot.id],
      };
    }),
);
