import queryString from "query-string";

import { ECONTRACT } from "constants/actionTypes";

import { getLivestockSaleId } from "lib/navigation";
import { serializeEContract } from "lib/serializers/eContracts";

import {
  offlineActionCreator,
  offlineSubActionCreator,
  urlCheckAndAddIdAndAction,
} from "./lib";

const urlCreator = ({ action, changesSince, livestockSaleId, id } = {}) => {
  return queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction(`/v2/econtracts/`, id, action),
    query: {
      changesSince,
      livestock_sale_id: livestockSaleId,
    },
  });
};

export const EContractAction = offlineActionCreator(
  ECONTRACT,
  serializeEContract,
  urlCreator,
  true,
);

EContractAction.void = offlineSubActionCreator(ECONTRACT.VOID, payload =>
  urlCreator({
    id: payload.id,
    action: "void",
    livestockSaleId: getLivestockSaleId(),
  }),
);

EContractAction.email = offlineSubActionCreator(ECONTRACT.EMAIL, payload =>
  urlCreator({
    id: payload.econtract_id,
    action: "email",
    livestockSaleId: getLivestockSaleId(),
  }),
);
