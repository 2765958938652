import { useSelector } from "react-redux";

import { getLivestockSaleId } from "lib/navigation";
import { getBestMatchingVendorSplit } from "lib/vendorSplits";

import { selectVendorSplitByKeyLookup } from "selectors";

export const useGetBestMatchingVendorSplit = ({
  primaryBusinessId,
  deploymentId,
  consignmentId,
  saleLotId,
}) => {
  const vendorSplitByKeyLookup = useSelector(selectVendorSplitByKeyLookup);
  const livestockSaleId = getLivestockSaleId();
  return getBestMatchingVendorSplit({
    vendorSplitByKeyLookup,
    primaryBusinessId,
    deploymentId,
    livestockSaleId,
    consignmentId,
    saleLotId,
  });
};
