import queryString from "query-string";

import {
  BUSINESS,
  CREATE_BUSINESS_FROM_IMPORT,
  DELETE_BUSINESS_NO_LONGER_EXISTS,
  GET_DUPLICATE_BUSINESS_SUGGESTIONS,
  GLOBAL_BUSINESSES,
  MERGE_BUSINESSES,
  PATCH_BUSINESS_FROM_IMPORT,
  REQUERY_ABN_FOR_BUSINESS,
} from "constants/actionTypes";

import { getLivestockSaleId } from "lib/navigation";
import {
  serializeBusiness,
  serializeGlobalBusinesses,
} from "lib/serializers/businesses";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

export function requestDuplicateBusinessSuggestions(filters, options) {
  return {
    type: GET_DUPLICATE_BUSINESS_SUGGESTIONS.REQUEST,
    filters,
    options,
  };
}

/**
 *
 * @param {string} toMasterBusinessId the UUID of the Business to keep
 * @param {string} fromMasterBusinessId the UUID of the Business to remove from the system
 * @param {object} options
 */
export function mergeBusiness(
  toMasterBusinessId,
  fromMasterBusinessId,
  options = {},
) {
  return {
    type: MERGE_BUSINESSES.REQUEST,
    toMasterBusinessId,
    fromMasterBusinessId,
    options,
  };
}

export function mergeBusinessSuccess(toMasterBusinessId, fromMasterBusinessId) {
  return {
    type: MERGE_BUSINESSES.SUCCESS,
    toMasterBusinessId,
    fromMasterBusinessId,
  };
}

export function mergeBusinessFailure(action, error) {
  return {
    type: MERGE_BUSINESSES.FAILURE,
    action,
    error,
  };
}

export const deleteBusinessNoLongerExists = id => ({
  type: DELETE_BUSINESS_NO_LONGER_EXISTS,
  meta: {
    business_id: id,
  },
});

export const createBusinessFromImport = payload => {
  return {
    type: CREATE_BUSINESS_FROM_IMPORT,
    payload,
  };
};

export const patchBusinessFromImport = (businessId, payload) => {
  return {
    type: PATCH_BUSINESS_FROM_IMPORT,
    businessId,
    payload,
  };
};

// Note - we're explicitly looking for `saleyardNameFilter` rather than the globally set `saleyardName` from the URL
const urlCreator = ({ changesSince, saleyardNameFilter, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction(`/v2/businesses/`, id, action),
    query: {
      changesSince,
      saleyard__name: saleyardNameFilter,
    },
  });

export const BusinessAction = offlineActionCreator(
  BUSINESS,
  serializeBusiness,
  urlCreator,
  false,
);

// Inject the current sale id into the create payload.
// A bit nasty, but means changes to the generic create are not lost.
const originalCreateAction = BusinessAction.create;

BusinessAction.create = (
  deserializedPayload,
  options = {
    suppressToast: false,
    suppressErrorToast: false,
    suppressSuccessToast: false,
    changeReason: null,
    saleyardName: null,
  },
) => {
  const extendedPayload = Object.assign({}, deserializedPayload, {
    addedInLivestockSaleId: getLivestockSaleId(),
  });
  return originalCreateAction(extendedPayload, options);
};

BusinessAction.suggestBuyerWayProperty = (
  businessId,
  buyerWayName,
  propertyId,
) => {
  return {
    type: BUSINESS.SUGGEST_BUYER_WAY_PROPERTY.ACTION,
    businessId,
    buyerWayName,
    propertyId,
  };
};

export const requeryABNForBusiness = (cbid, saleyardName) => {
  const payload = { cbid };
  const meta = { id: cbid, saleyardName };

  const url = `/v2/businesses/${cbid}/requery-abn/`;

  return {
    type: REQUERY_ABN_FOR_BUSINESS.REQUEST,
    payload,
    meta: {
      id: cbid,
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: REQUERY_ABN_FOR_BUSINESS.SUCCESS,
          meta,
        },
        rollback: {
          type: REQUERY_ABN_FOR_BUSINESS.ROLLBACK,
          meta,
        },
      },
    },
  };
};

export const GlobalBusinessesAction = offlineActionCreator(
  GLOBAL_BUSINESSES,
  serializeGlobalBusinesses,
  () => `/v2/global-businesses/`,
  false,
);
