import React from "react";

import {
  GenericMultiFileTypeImporter,
  GenericMultiFileTypeImporterSection,
} from "components/Importer/GenericImporter";

import { EXTERNAL_AGENT_XML } from "constants/actionTypes";
import { IMPORTER_TYPES } from "constants/importer";

const externalAgentXmlSections = [
  GenericMultiFileTypeImporterSection.PRODUCTS,
  GenericMultiFileTypeImporterSection.VENDORS,
];

const HelpTextComponent = () => (
  <ul>
    <li>Use the example file as template.</li>
  </ul>
);

export function ExternalAgentXmlImporter() {
  return (
    <GenericMultiFileTypeImporter
      importName={IMPORTER_TYPES.EXTERNAL_AGENT_XML}
      fileTypes={["text/xml", "application/xml"]}
      fileDescription="External Agent XML"
      type={EXTERNAL_AGENT_XML}
      sections={externalAgentXmlSections}
      HelpTextComponent={HelpTextComponent}
    />
  );
}
