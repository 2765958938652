import React from "react";

import { parse } from "date-fns";
import { useHistory } from "react-router-dom";

import AgGrid from "components/AgGrid/AgGrid";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";
import { NLISFileTypeHeadings } from "constants/nlis";
import { colors } from "constants/theme";

import { getColumnState } from "lib/agGrid";
import { NLISRelatedTransaction } from "lib/agGrid/columns/nlisFile";
import { caseInsensitiveCompare } from "lib/compare";
import { getHashModalRoute } from "lib/navigation";

import { actionsColumnRenderer } from "./ActionColumn";

const defaultColDef = {
  sortable: true,
  flex: true,
  resizable: true,
  enableCellChangeFlash: true,
  filter: "agMultiColumnFilter",
  floatingFilter: true,
};

function dateComparator(date1, date2) {
  // NLIS time formats are a bti different so using the below formats into a date to be sorted
  const date1Number = date1 && parse(date1, "dd/MM/yyyy h:ma", new Date());
  const date2Number = date2 && parse(date2, "dd/MM/yyyy h:ma", new Date());

  if (date1Number == null && date2Number == null) {
    return 0;
  }

  if (date1Number == null) {
    return -1;
  } else if (date2Number == null) {
    return 1;
  }

  return date1Number - date2Number;
}

export function eidStatusGetter({ data }) {
  const { eidSuccessCount, eidCount } = data;

  return `${eidSuccessCount} / ${eidCount} Successful`;
}

export function nlisFileTypeGetter({ data }) {
  const { transferType } = data;

  return NLISFileTypeHeadings[transferType] || transferType;
}

const columnDef = [
  {
    headerName: "Type",
    colId: "transferType",
    field: "transferType",
    valueGetter: nlisFileTypeGetter,
    minWidth: 90,
  },
  {
    headerName: "Transaction Id",
    colId: "transactionId",
    field: "transactionId",
    minWidth: 90,
  },
  {
    headerName: "Upload Status",
    colId: "status",
    field: "status",
    minWidth: 90,
  },
  {
    headerName: "EID Status",
    colId: "eidStatus",
    field: "eidStatus",
    valueGetter: eidStatusGetter,
    minWidth: 100,
    comparator: caseInsensitiveCompare,
  },
  {
    headerName: "Consignment",
    colId: "vendorDisplay",
    field: "vendorDisplay",
    minWidth: 50,
  },
  {
    headerName: "Buyer",
    minWidth: 50,
    colId: "buyerDisplay",
    field: "buyerDisplay",
  },
  {
    headerName: "Created",
    colId: "created",
    field: "created",
    minWidth: 130,
    comparator: dateComparator,
  },
  {
    headerName: "Submitted",
    colId: "submitted",
    field: "submitted",
    minWidth: 130,
    comparator: dateComparator,
  },
  {
    headerName: "Processed",
    colId: "processed",
    field: "processed",
    minWidth: 130,
    sort: "desc",
    comparator: dateComparator,
  },
  {
    headerName: "Related Transaction",
    colId: "relatedTransaction",
    field: "relatedTransaction",
    minWidth: 60,
    cellRenderer: NLISRelatedTransaction,
  },
  {
    headerName: ACTION_COLUMN_NAME,
    colId: ACTION_COLUMN_ID,
    cellRenderer: actionsColumnRenderer,
    pinned: "right",
    width: 100,
  },
];

const getRowStyle = ({ data }) => {
  const { eidSuccessCount, eidCount } = data;
  if (eidSuccessCount !== eidCount) {
    return {
      background: `${colors.warningOrange}50`,
    };
  }
};

export const NLISFileGrid = React.memo(({ rowData, columnState = [] }) => {
  const history = useHistory();

  const openReceipt = transfer => {
    const { id, transferType } = transfer;
    const params = {
      modalType: ModalTypes.NLISTransferReceipt,
      queryArray: [id, transferType],
    };

    history.push(`#${getHashModalRoute(params)}`);
  };

  const context = {
    handleViewAction: openReceipt,
  };

  const columnDefs = React.useMemo(
    () => getColumnState(columnState, columnDef),
    [columnState],
  );

  const onGridReady = agGridInstance => {
    // Auto size the columns when the grid is ready.
    agGridInstance.columnApi.autoSizeAllColumns(false);
  };

  return (
    <AgGrid
      rowData={rowData}
      columnDefs={columnDefs}
      pagination
      paginationPageSize={100}
      defaultColDef={defaultColDef}
      context={context}
      onGridReady={onGridReady}
      getRowStyle={getRowStyle}
      enableCellTextSelection
      suppressColumnVirtualisation
      enableCellChangeFlash
    />
  );
});
