import { intersection } from "lodash";

import {
  doesSaleLotHaveOverflowPen,
  isSaleLotBalanced,
  isSaleLotCounted,
  isSaleLotDelivered,
  getBuyerHashFromSaleLot,
} from "lib/saleLot";

import { isObjectChangedAfter } from "selectors/globalSearch/lib";

export const saleLotsFilteredBuyerIdsComparator = buyerIds => saleLot =>
  buyerIds.includes(saleLot.buyer_id);

export const saleLotsFilteredThirdPartyIdsComparator =
  thirdPartyIds => saleLot =>
    thirdPartyIds.includes(saleLot.thirdPartyId);

export const saleLotsFilteredBuyerHashesComparator = buyerHashes => saleLot =>
  buyerHashes.includes(getBuyerHashFromSaleLot(saleLot));

export const saleLotsFilteredSaleRoundIdsComparator = saleRoundIds => saleLot =>
  saleRoundIds.includes(saleLot.sale_round_id);

export const saleLotsFilteredHaveOverflowPenCompararator =
  hasOverflow => saleLot =>
    doesSaleLotHaveOverflowPen(saleLot) === hasOverflow[0];

export const saleLotsFilteredIsBuyerBalancedComparator =
  isBuyerBalanced => saleLot =>
    isSaleLotBalanced(saleLot) === isBuyerBalanced[0];

export const saleLotsFilteredIsCountedComparator = isCounted => saleLot =>
  isSaleLotCounted(saleLot) === isCounted[0];

export const saleLotsFilteredIsDeliveredComparator = isDelivered => saleLot =>
  isSaleLotDelivered(saleLot) === isDelivered[0];

export const saleLotsFilteredLabelsComparator = labels => saleLot =>
  saleLot.labels?.length > 0 &&
  labels.some(labelIds => intersection(saleLot.labels, labelIds).length > 0);

export const saleLotsFilteredPricingTypesComparator = pricingTypes => saleLot =>
  pricingTypes.includes(saleLot.pricing_type_id);

export const saleLotsFilteredPropertyIdComparator = propertyIds => saleLot =>
  propertyIds.includes(saleLot.destination_property_id);

export const saleLotsOverflowPensComparator = overflowPenIds => saleLot =>
  overflowPenIds.includes(saleLot.id);

export const saleLotsFilteredCheckpointComparator = checkpoints => saleLot =>
  isObjectChangedAfter(saleLot, checkpoints[0]);

export const saleLotsFilteredLanesComparator = lanes => saleLot =>
  lanes.some(lane => lane.includes(saleLot.auction_pen_id));

export const saleLotsFilteredDeliveryPenComparator =
  deliveryPenIds => saleLot =>
    deliveryPenIds.includes(saleLot.deliveryPenId);

export const saleLotsFilteredAuctionPenComparator = auctionPenIds => saleLot =>
  auctionPenIds.includes(saleLot.auction_pen_id);

export const hasBuyerExceptionsBySaleLotFilteredComparator =
  hasBuyerExceptions =>
  ([_ignored, exceptions]) =>
    (exceptions.length !== 0) === hasBuyerExceptions[0];
