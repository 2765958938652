import React, { memo, useCallback, useState } from "react";

import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { MarkChips } from "components/Chip";

import { SaleLotType } from "constants/saleLots";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import {
  getAges,
  getAuctionPenById,
  getBreeds,
  getSaleLotByEid,
  getSexes,
} from "selectors";

import { useTheme } from "hooks";

const { Popover, Box } = require("@material-ui/core");

export const PopoverLocation = {
  END: 0,
  WITH_WEIGHT: 1,
};

const AnimalDetailsPopoverComponent = ({ eid }) => {
  const { colors } = useTheme();

  const saleLot = useSelector(getSaleLotByEid(eid));
  const {
    age_id: ageId,
    auction_pen_id: auctionPenId,
    deliveryPenId,
    breed_id: breedId,
    marks,
    saleLotType,
    sex_id: sexId,
  } = saleLot || {};

  const auctionPen = useSelector(getAuctionPenById(auctionPenId));
  const deliveryPen = useSelector(getAuctionPenById(deliveryPenId));

  const ageName = useSelector(state => getAges(state)[ageId]?.name) || "";
  const sexName = useSelector(state => getSexes(state)[sexId]?.name) || "";
  const breedName = useSelector(state => getBreeds(state)[breedId]?.name) || "";

  const [anchorEl, setAnchorEl] = useState(null);

  const onClickIcon = event => {
    setAnchorEl(event.target);
  };

  const closePopover = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  if (saleLotType !== SaleLotType.AUCTION) {
    // Don't render anything when the SaleLot isn't a regular Selling/Grading Lot
    return null;
  }

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <>
      <FontAwesomeIcon
        color={colors.primary}
        icon={faInfoCircle}
        onClick={onClickIcon}
        size="2x"
      />
      <Popover open={isPopoverOpen} anchorEl={anchorEl} onClose={closePopover}>
        <Box padding={3}>
          <div>
            <b>Auction Pen:&nbsp;</b>
            {auctionPen ? getAuctionPenDisplayName(auctionPen) : ""}
          </div>
          <div>
            <b>Delivery Pen:&nbsp;</b>
            {deliveryPen ? getAuctionPenDisplayName(deliveryPen) : ""}
          </div>
          <div>
            <b>Marks:&nbsp;</b> {<MarkChips marks={marks} /> || "N/A"}
          </div>
          <div>
            <b>Sex:&nbsp;</b> {sexName}
          </div>
          <div>
            <b>Age:&nbsp;</b> {ageName}
          </div>
          <div>
            <b>Breed:&nbsp;</b> {breedName}
          </div>
        </Box>
      </Popover>
    </>
  );
};

AnimalDetailsPopoverComponent.propTypes = {
  eid: PropTypes.string.isRequired,
};

export const AnimalDetailsPopover = memo(AnimalDetailsPopoverComponent);
