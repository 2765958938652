import reduceReducers from "reduce-reducers";

import {
  COMMENT,
  DEPLOYMENT_SALE,
  DEPLOYMENT_SALE_COMMENT,
  SALE_LOT,
} from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  resetStateOnRoleChangeReducer,
} from "lib/reducers";
import { deserializeComment } from "lib/serializers/comments";

const create = apiModelOfflineCreateReducer(COMMENT, {
  deserializer: deserializeComment,
});

const fetch = apiModelOfflineFetchReducer(COMMENT, {
  deserializer: deserializeComment,
});

const fetchDeploymentSaleComment = apiModelOfflineFetchReducer(
  DEPLOYMENT_SALE_COMMENT,
  {
    deserializer: deserializeComment,
  },
);

const update = apiModelOfflineUpdateReducer(COMMENT, {
  deserializer: deserializeComment,
});

const updateDeploymentSaleComment = apiModelOfflineUpdateReducer(
  DEPLOYMENT_SALE_COMMENT,
  {
    deserializer: deserializeComment,
  },
);

const deleteReducer = apiModelOfflineDeleteReducer(COMMENT);

// The actions fire a `<ModelName>.COMMENT.REQUEST`, where Model name refers to the model which you are commenting on,
// but they knock onto a COMMENT.CREATE.SUCCESS, which is handled by the default create reducer.
function createRequest(state, action) {
  switch (action.type) {
    case SALE_LOT.COMMENT.REQUEST:
    case DEPLOYMENT_SALE.COMMENT.REQUEST: {
      const {
        payload: { comment, id },
      } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            id,
            comment,
            created: new Date().toISOString(),
            syncing: true,
          },
        },
      };
    }
    default:
      return state;
  }
}

const commentReducer = reduceReducers(
  create,
  createRequest,
  fetch,
  fetchDeploymentSaleComment,
  update,
  updateDeploymentSaleComment,
  deleteReducer,
  resetStateOnRoleChangeReducer,
);

export default commentReducer;
