export const RowSelectionMode = {
  MULTIPLE: "multiple",
  NOT_SET: "",
  SINGLE: "single",
};

export const ColumnType = {
  BOOLEAN: "boolean",
  CURRENCY: "currency",
  CURRENCY_FROM_CENTS: "currency_from_cents",
  DATE: "date",
  DATE_TIME: "datetime",
  EDIT: "edit",
  GROUPER: "grouper",
  NUMERIC: "numeric",
  PERCENTAGE: "percentage",
  RIGHT_ALIGNED: "right",
  STRING: "string",
  TOTAL: "total",
  UTC_LOCAL_DATE_TIME: "utctolocaldatetime",
  WEIGHT_FROM_GRAMS: "weight_from_grams",
};

export const MIN = "min";
export const MAX = "max";

export const WARNING_COLUMN_VALUE = "Warning";
