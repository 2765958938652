import React from "react";

import { Error } from "components/DetailedSaleLotModal/AdvancedDrafting/components";

export const TimeUnitChoices = [
  {
    value: "months",
    label: "Months",
  },
  {
    value: "years",
    label: "Years",
  },
];
export const toErrorMessage = number =>
  typeof number === "number" && number ? (
    <Error>
      {Math.abs(number)} {number > 0 ? "under" : "over"}
    </Error>
  ) : null;
