import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
  billingDocument: Yup.object()
    .shape({
      type: Yup.string().required("Required!"),
      number: Yup.string().required("Required!"),
      issuerBusinessId: Yup.string().required("Required!"),
      recipientBusinessId: Yup.string().required("Required!"),
    })
    .nullable(),
  ledgerEntry: Yup.object().shape({
    description: Yup.string().when("showOnInvoiceAs", {
      is: "single",
      then: schema => schema.required("Required!"),
      otherwise: schema => schema.nullable(),
    }),
    documentId: Yup.string().required("Required!"),
    glCode: Yup.string().required("Required!"),
    id: Yup.string().required("Required!"),

    includeIfZero: Yup.boolean().required("Required!"),
    invoiceCategory: Yup.mixed().when("showOnInvoiceAs", {
      is: "group",
      then: schema => schema.required("Required!"),
      otherwise: schema => schema.nullable(),
    }),
    notes: Yup.string().required("Required!"),
    taxType: Yup.string().required("Required!"),
  }),
  pricingModel: Yup.object().shape({
    quantity: Yup.number().required("Required!"),
    taxAmount: Yup.number().required("Required!"),
    subtotal: Yup.number().required("Required!"),
    unitAmount: Yup.number().required("Required!"),
  }),
});
