import { css } from "styled-components";
import styled from "styled-components/macro";

import { saleLotStatuses, auctionPenStatuses } from "constants/sale";

const StatusCard = styled.div(
  ({ color, rounded, status, theme, selected, faded }) => {
    let backgroundImage = null;
    let barColor =
      theme.colors[status] ||
      theme.statusColors[status] ||
      color ||
      `transparent`;
    const backgroundColor =
      theme.statusBackgroundColors[status] || theme.colors.white;
    if (status === auctionPenStatuses.TEMP_PEN) {
      barColor = theme.colors.errorRed;
    }
    if (
      (status && status === saleLotStatuses.NOT_COUNTED) ||
      status === auctionPenStatuses.NOT_COUNTED
    ) {
      backgroundImage = `linear-gradient(to right, ${theme.colors.error}50 , ${theme.colors.error}80)`;
      barColor = theme.colors.error;
    } else if (
      (status && status === saleLotStatuses.NOT_PENNED) ||
      status === auctionPenStatuses.NOT_PENNED
    ) {
      backgroundImage = `linear-gradient(to right, ${theme.colors.warning}50 , ${theme.colors.warning}80)`;
      barColor = theme.colors.warning;
    }

    return `
    cursor: ${typeof selected === "boolean" ? "pointer" : "inherit"};
    width: 100%;
    box-shadow: ${selected ? `0 0 1px 2px #4A90E2` : theme.shadows[1]};
    padding: ${theme.space[2]}px;

    // Reduce padding on the right to offset the bar on the left.
    padding-right: ${theme.space[2] - theme.space[1]}px;

    background-image: ${backgroundImage || ""};
    background-color: ${backgroundColor};
    position: relative;
    margin-top: ${theme.space[2]}px;
    border-radius: ${rounded ? theme.radii[2] : theme.radii[0]}px;
    
    opacity: ${faded ? "40%" : "100%"};

    &:before {
      content: '';
      display: inline-box;
      position:  absolute;
      left: 0;
      top: 0;
      width: ${theme.space[1]}px;
      height: 100%;
      background-color: ${barColor};
    }

    &:first-child {
      margin-top: 0;
    }

  `;
  },
);

export const StatusStyles = css(({ theme, status }) => {
  const barColor =
    theme.colors[status] || theme.statusColors[status] || `transparent`;

  return `
    &:before {
      content: '';
      display: inline-box;
      position:  absolute;
      left: 0;
      top: 0;
      width: ${theme.space[1]}px;
      height: 100%;
      background-color: ${barColor};
    }

    &:first-child {
      margin-top: 0;
    }
`;
});

export default StatusCard;
