import React, { useCallback, useState } from "react";

import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Card from "components/Card";

import { getIsBalancedByDeploymentSaleId } from "selectors";

import DeploymentSaleSummaryTable from "./DeploymentSaleSummaryTable";
import ExceptionsView from "./ExceptionsView";

const RowCard = styled(Card)(
  ({ theme }) => `
  padding: ${theme.space[1]}px;
  width: 1330px;
`,
);

function DeploymentSaleSummaryComponent(props) {
  const { deploymentSaleId } = props;

  const isBalanced = useSelector(
    getIsBalancedByDeploymentSaleId(deploymentSaleId),
  );

  const [isExceptionsVisible, setIsExceptionsVisible] = useState(false);

  const toggleExceptions = useCallback(
    () => setIsExceptionsVisible(!isExceptionsVisible),
    [isExceptionsVisible, setIsExceptionsVisible],
  );

  const status = isBalanced ? "ready" : "ongoing";

  return (
    <>
      <RowCard data-tour="rowCard" status={status}>
        <DeploymentSaleSummaryTable
          deploymentSaleId={deploymentSaleId}
          onClickExceptions={toggleExceptions}
          isExceptionsSelected={isExceptionsVisible}
        />
      </RowCard>

      {isExceptionsVisible && (
        <ExceptionsView
          deploymentSaleId={deploymentSaleId}
          handleClose={toggleExceptions}
        />
      )}
    </>
  );
}

DeploymentSaleSummaryComponent.propTypes = {
  deploymentSaleId: PropTypes.number.isRequired,
};

export default DeploymentSaleSummaryComponent;
