import React from "react";

import styled from "styled-components/macro";

import { PenCard } from "components/AuctionPens/List/AuctionPenCardList";
import StatusCard from "components/Card/StatusCard";
import { PlainCollapse } from "components/Collapse";
import { RightActionColumn } from "components/GroupedCard";
import { OpenIndicator } from "components/Icons/CollapseIcon";
import { Row } from "components/Layout";

import { PenTypes } from "constants/auctionPens";
import { cards } from "constants/sale";

import { PrimaryInfoHeaderLarge } from "./PrimaryInfoSet";

const PenWrapper = styled.div`
  padding-bottom: 12px;
`;

export function TempPenCards({
  tempPensIds,
  quantity,
  openTempCard,
  openCard,
  setOpenCard,
  setOpenTempCard,
  weighedOnly,
}) {
  if (tempPensIds.length === 0) {
    return null;
  }
  const isTempPen = openTempCard === PenTypes.TEMP;
  return (
    <PenWrapper data-tour="tempPensWrapper">
      <StatusCard status={cards.TEMP_PEN_CARD}>
        <Row
          justifyBetween
          onClick={() =>
            isTempPen ? setOpenTempCard() : setOpenTempCard(PenTypes.TEMP)
          }
        >
          <PrimaryInfoHeaderLarge>Temp Pens </PrimaryInfoHeaderLarge>
          <PrimaryInfoHeaderLarge>
            {tempPensIds.length} Pens{" "}
          </PrimaryInfoHeaderLarge>
          <PrimaryInfoHeaderLarge>
            {quantity} HD In Pens{" "}
          </PrimaryInfoHeaderLarge>
          <RightActionColumn>
            {typeof setOpenCard === "function" ? (
              <OpenIndicator isOpen={isTempPen} />
            ) : null}
          </RightActionColumn>
        </Row>

        <PlainCollapse isOpen={isTempPen}>
          {tempPensIds.map(penId => (
            <PenCard
              key={penId}
              auctionPenId={penId}
              setOpenCard={setOpenCard}
              isOpen={penId === openCard}
              weighedOnly={weighedOnly}
            />
          ))}
        </PlainCollapse>
      </StatusCard>
    </PenWrapper>
  );
}
