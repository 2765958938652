import { createSelector } from "reselect";

import { ReservedProperties } from "constants/properties";

import { getProperties } from "selectors";

export const getPropertyById = propertyId => state =>
  getProperties(state)[propertyId] || null;

export const selectReservedPropertyIds = createSelector(
  [getProperties],
  properties =>
    Object.values(properties)
      .filter(property => ReservedProperties.indexOf(property.PIC) > -1)
      .map(property => property.id),
);
