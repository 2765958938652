import React from "react";

import { useDispatch } from "react-redux";

import { updateDeploymentAttributes } from "actions";

import { BaseTable } from "components/Importer/BaseTable";
import { breedColumns } from "components/Importer/columnDefinitions";

import { UPDATE_DEPLOYMENT_BREEDS } from "constants/actionTypes";

export function BreedImporter({
  importBreeds,
  agrinousBreeds,
  onCellValueChanged,
}) {
  /**
   * Breeds
   */

  const dispatch = useDispatch();

  const updateDeploymentBreed = data => {
    const payload = {
      breed_id: data.breed_id,
      quick_code: data.quick_code || data.strgBreedCode,
      code: data.code || data.strgBreedCode,
      deployment_id: data.deployment_id,
      id: data.id,
      is_used: data.is_used,
      order: data.order,
      quick_select: data.quick_select,
      saleyard_code: data.strgBreedCode,
    };
    dispatch(updateDeploymentAttributes(UPDATE_DEPLOYMENT_BREEDS, [payload]));
  };

  const breedOptions = agrinousBreeds.filter(Boolean).map(b => ({
    label: b.name || "",
    value: b.id || "",
  }));

  const breedData = importBreeds.map(b => {
    const agrinousData = agrinousBreeds.find(
      p => p.saleyard_code === b.strgBreedCode,
    );
    return {
      ...b,
      ...agrinousData,
      name: {
        label: agrinousData?.name,
        value: agrinousData?.id,
      },
    };
  });

  return (
    <BaseTable
      columns={breedColumns(breedOptions)}
      data={breedData}
      onCellValueChanged={onCellValueChanged(agrinousBreeds)}
      context={{
        update: updateDeploymentBreed,
        mappingColumn: "strgBreedCode",
      }}
    />
  );
}
