import React from "react";

import { PropTypes } from "prop-types";

import { Species } from "constants/species";

import {
  createAndOpenPdf,
  getBuyerDetails,
  getSaleAndDocumentInformation,
  getPriceDetails,
  getVendorDetails,
  getWeightDetails,
  dashBetweenLots,
  getBookingPenDetails,
  getCattleTableData,
  getTableData,
  getLanes,
  BookingCardVersions,
  getDescriptionText,
} from "lib/pdf";
import { getMarkDetails } from "lib/saleLot";

const columnWidths = [40, 30, 180, 150, 100, "*"];
const signOffColumnWidths = [80, 30, 25, 180, 150, 60, "*"];
const cattleColumnWidths = isPostSaleReport =>
  isPostSaleReport
    ? [60, 120, 30, 60, 95, 25, 70, 70, 45, 150]
    : [60, 120, 30, 60, 95, 25, 70, 115, 150];
const pageMargin = 12;
const pageMarginTop = 40;
const pageMarginBottom = 20;

const footer = {
  footer: {
    columns: [
      { text: "Auctioneer Signature", alignment: "right" },
      {
        canvas: [
          {
            type: "line",
            x1: 10,
            y1: 10,
            x2: 300,
            y2: 10,
            lineWidth: 1,
            dash: { length: 1, space: 1 },
          },
        ],
      },
    ],
  },
};

function getSaleLotsPerPageCount(extraSpacing) {
  return extraSpacing ? 1 : 3;
}

// In order to keep the auction pen data and the following blank
// row (which is used for note-taking) together, these are rendered
// as a nested table, taking advantage of the outer table's
// dontBreakRows property.
/**
 * Get pen details in format for pdfMake
 * @param {object} auctionPen
 * @return {object}
 */

const makeCattleAuctionPenRow = (
  auctionPen,
  saleLots,
  showComment,
  blankRowHeight,
  extraSpacing,
  isPostSaleReport,
) => {
  const rows = [];
  rows.push([
    {
      layout: dashBetweenLots,
      table: {
        widths: isPostSaleReport
          ? [60, 120, 30, 60, 95, 25, 70, 72, 47, 150]
          : [60, 120, 30, 60, 95, 25, 70, 119, 150],
        body: saleLots.map(sl => {
          return [
            getBookingPenDetails(auctionPen, true, true),
            getVendorDetails(
              sl,
              {
                includeWeight: false,
                includeVendorNumber: true,
                extraSpacing,
                showVendorInfo: false,
                includeEuStatus: true,
              },
              true,
            ),
            { text: sl.quantity, bold: true, style: "mainText" },
            { text: getMarkDetails(sl), bold: true, style: "mainText" },
            getDescriptionText(sl),
            sl?.consignment?.declaration?.treated_with_hgp ? "Y" : "N",
            sl.consignment.vendor_property?.PIC || " ",
            ...(isPostSaleReport ? [getWeightDetails(sl, true)] : []),
            getPriceDetails(sl),
            getBuyerDetails(sl, extraSpacing),
          ];
        }) || [" ", " ", " ", " "],
      },
    },
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ...(isPostSaleReport ? [""] : []),
  ]);
  if (!extraSpacing) {
    rows.push([
      { text: "", colSpan: cattleColumnWidths(isPostSaleReport).length },
    ]);
  }

  return {
    border: [false, false, false, false, false],
    marginTop: -6,
    table: {
      headerRows: 0,
      widths: cattleColumnWidths(isPostSaleReport),
      heights(row) {
        return row === 0 ? "auto" : blankRowHeight;
      },
      body: rows,
    },
  };
};

// Render the outer tables, one for each lane.
const getContent = (
  auctionPens,
  sale,
  blankRowHeight,
  extraSpacing,
  version,
) => {
  let tableHeaders = [];
  // We want it to always be postSale unless it is specificly set as pre
  // so that the non-spaced one behaves correctly
  const isPostSaleReport = version !== BookingCardVersions.PreSale;
  const isSignOffReport =
    version === BookingCardVersions.SignOff ||
    BookingCardVersions.SignOffCompact;
  let tableColumnWidths = [];
  if (
    sale.species_id === Species.CATTLE &&
    version !== BookingCardVersions.SignOffCompact
  ) {
    tableHeaders = [
      { text: "PEN", style: "tableHeader" },
      { text: "VENDOR", style: "tableHeader" },
      { text: "HD", style: "tableHeader" },
      { text: "MARK", style: "tableHeader" },
      { text: "DESCRIPTION", style: "tableHeader" },
      { text: "HGP", style: "tableHeader" },
      { text: "PIC", style: "tableHeader" },

      ...(isPostSaleReport
        ? [{ text: "AVG/Total Kg", style: "tableHeader" }]
        : []),
      { text: "PRICE", style: "tableHeader" },
      { text: "BUYER", style: "tableHeader" },
    ];
    tableColumnWidths = cattleColumnWidths(isPostSaleReport);
  } else {
    tableHeaders = [
      { text: "PEN", style: "tableHeader" },
      { text: "HD", style: "tableHeader" },
      ...(isSignOffReport ? [{ text: "LPA", style: "tableHeader" }] : []),
      { text: "VENDOR", style: "tableHeader" },
      { text: "DESCRIPTION", style: "tableHeader" },
      { text: "PRICE", style: "tableHeader" },
      { text: "BUYER", style: "tableHeader" },
    ];
    tableColumnWidths = columnWidths;
    if (isSignOffReport) {
      tableColumnWidths = signOffColumnWidths;
    }
  }
  return getLanes(auctionPens).map((lane, i) => {
    const laneTableData =
      sale.species_id === Species.CATTLE &&
      version !== BookingCardVersions.SignOffCompact
        ? getCattleTableData(
            lane,
            blankRowHeight,
            extraSpacing,
            makeCattleAuctionPenRow,
            getSaleLotsPerPageCount,
            false,
            isPostSaleReport,
            false,
          )
        : getTableData(
            lane,
            blankRowHeight,
            extraSpacing,
            tableColumnWidths,
            BookingCardVersions.SignOffCompact
              ? () => 10
              : getSaleLotsPerPageCount,
            true,
            version === BookingCardVersions.SignOffCompact,
            isSignOffReport,
            version !== BookingCardVersions.SignOffCompact,
            version === BookingCardVersions.SignOffCompact,
          );
    return {
      pageBreak: i > 0 ? "before" : null,
      table: {
        headerRows: 1,
        dontBreakRows: true,
        widths: ["*"],
        body: [
          [
            {
              border: [false, false, false, false, false],
              table: {
                headerRows: 0,
                dontBreakRows: true,
                widths: tableColumnWidths,
                body: [tableHeaders],
              },
            },
          ],
          ...laneTableData,
        ],
      },
    };
  });
};

export const BookingCardLandscape = ({
  auctionPens,
  sale,
  agencyName,
  round,
  history,
  extraSpacing,
  version,
  deploymentLogo,
  saleLogo,
}) => {
  const isSignOffReport = [
    BookingCardVersions.SignOff,
    BookingCardVersions.SignOffCompact,
  ].includes(version);
  const blankRowHeight =
    version === BookingCardVersions.SignOffCompact ? 0 : 50;
  const pdfDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [pageMargin, pageMarginTop, pageMargin, pageMarginBottom],
    content: getContent(
      auctionPens,
      sale,
      blankRowHeight,
      extraSpacing,
      version,
    ),
    images: {
      logo: deploymentLogo || saleLogo,
    },
    header: getSaleAndDocumentInformation(
      auctionPens,
      sale,
      agencyName,
      round,
      pageMargin,
      isSignOffReport,
      "logo",
    ),
    ...(isSignOffReport && {
      ...footer,
    }),
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 11,
      },
      mainText: {
        bold: true,
        fontSize: 15,
      },
      middleText: {
        fontSize: 14,
        bold: true,
      },
      smallTextBold: {
        fontSize: 12,
        bold: true,
      },
      smallText: {
        fontSize: 12,
      },
      spacingText: {
        fontSize: 10,
      },
      smallSpacingText: {
        fontSize: 6,
      },
    },
  };

  createAndOpenPdf(pdfDefinition, history);

  return <div />;
};

BookingCardLandscape.propTypes = {
  auctionPens: PropTypes.array,
  sale: PropTypes.object,
  history: PropTypes.object,
  agencyName: PropTypes.string,
  round: PropTypes.object,
  extraSpacing: PropTypes.bool,
  version: PropTypes.string,
};
