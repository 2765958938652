import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { SubtleBadge } from "components/Badge";
import { CollapseLabel, FormCollapse } from "components/Form";
import { withNamespace } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import SellingSaleLotForm from "components/SellingSaleLotForm";

import { calculateTotalPriceCents, getDollarPriceStringFromCents } from "lib";

import { sectionHasErrors } from "lib/form";
import { getAverageWeightFormatted } from "lib/saleLot";

function SaleCollapse(props) {
  const {
    deploymentSaleId,
    isOpen,
    namespace: ns,
    onToggle,
    quantity,
    readOnly,
  } = props;

  const formikProps = useFormikContext();
  const { values } = formikProps;

  const hasErrorBadge = sectionHasErrors(formikProps, [
    withNamespace(ns, "quantity_delivered"),
    withNamespace(ns, "unit_price"),
    withNamespace(ns, "buyer_id"),
    withNamespace(ns, "buyer_way.id"),
    withNamespace(ns, "destination_property_id"),
    withNamespace(ns, "skin_pricing_type"),
    withNamespace(ns, "skin_quantity"),
    withNamespace(ns, "skin_price"),
  ]);

  const buyerName = values?.buyer?.name || "";
  const buyerWay = values.buyer_way?.name || " ";
  const total_price_cents = calculateTotalPriceCents({
    unitPrice: values.unit_price ? parseFloat(values.unit_price) : 0,
    quantity: values.quantity,
    pricing_type_id: values.pricing_type_id,
    total_mass_grams: values.total_mass_grams,
  });

  const buyerPrice =
    (values.quantity > 0 &&
      getDollarPriceStringFromCents(total_price_cents / values.quantity)) ||
    "-";
  const averageWeight = getAverageWeightFormatted(values);
  const averageWeightText = averageWeight ? `, Ave ${averageWeight} Kg` : "";

  const header = (
    <Row data-tour="Sale">
      <SubtleBadge hasErrorBadge={hasErrorBadge}>Buyer/Price</SubtleBadge>
      <Row alignCenter flexWrap>
        <CollapseLabel>
          {buyerName} {buyerWay} ${buyerPrice}
          {averageWeightText}
        </CollapseLabel>
      </Row>
    </Row>
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      hasErrorBadge={hasErrorBadge}
      header={header}
      id="collapse-buyerprice"
      dataTour={isOpen ? "hideBuyerPrice" : "showBuyerPrice"}
    >
      <SellingSaleLotForm
        deploymentSaleId={deploymentSaleId}
        namespace={ns}
        quantity={quantity}
        readOnly={readOnly}
      />
    </FormCollapse>
  );
}

SaleCollapse.propTypes = {
  deploymentSaleId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  namespace: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  quantity: PropTypes.number,
};

export default SaleCollapse;
