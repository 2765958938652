import React from "react";

import { EditUserRoleModal } from "components/UserRoles/Modal/index";

import { ModalTypes } from "constants/navigation";

function EditUserRoleModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.EditUserRole] || null;
  const { returnTo, userRoleType, userRoleId } = JSON.parse(hashParams);
  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <EditUserRoleModal
      userRoleType={userRoleType}
      returnTo={decodedReturnTo}
      userRoleId={userRoleId}
    />
  );
}

export default EditUserRoleModalAdapter;
