import React, { useCallback, useEffect, useRef, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";

import { requestDuplicateBusinessSuggestions, mergeBusiness } from "actions";

import { Button } from "components/Form";
import { CenteredGreedy } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { SuggestDuplicateBusinessFilter } from "constants/businesses";
import { ApiModel } from "constants/loading";

import {
  closeAllHashModals,
  getLivestockSaleId,
  returnToLocation,
} from "lib/navigation";

import DuplicateBusinessSuggestions from "./DuplicateBusinessSuggestions";
import { Filters } from "./Filters";
import { ReviewDuplicateDialog } from "./ReviewDuplicateDialog";
import {
  resolveDuplicatesRelations,
  dismissSuggestion,
  dismissSuggestions,
  filterSuggestions,
  ALL_DUPLICATE_ATTRIBUTES,
} from "./util";

export function DuplicateBusinessModal(props) {
  const { returnTo } = props;

  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const dispatch = useDispatch();

  const [suggestions, setSuggestions] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewBusinessIds, setReviewBusinessIds] = useState([null, null]);
  const [visibleSuggestions, setVisibleSuggestions] = useState([]);
  const lastFiltersRef = useRef(ALL_DUPLICATE_ATTRIBUTES);

  const onSuccess = useCallback(
    suggestions => {
      setError(null);
      setIsLoading(false);

      const nextSuggestions = resolveDuplicatesRelations(suggestions);

      setSuggestions(nextSuggestions);
      setVisibleSuggestions(
        filterSuggestions(nextSuggestions, lastFiltersRef.current),
      );
    },
    [
      lastFiltersRef,
      setError,
      setIsLoading,
      setSuggestions,
      setVisibleSuggestions,
    ],
  );

  const onError = useCallback(
    error => {
      setError(error.message);
      setIsLoading(false);
      setSuggestions(null);
      setVisibleSuggestions([]);
    },
    [setError, setIsLoading, setSuggestions, setVisibleSuggestions],
  );

  const reviewDuplicate = useCallback(
    (aBusinessId, bBusinessId) => {
      setReviewBusinessIds([aBusinessId, bBusinessId]);
    },
    [setReviewBusinessIds],
  );

  const dismissDuplicate = useCallback(
    (aBusiness, bBusiness) => {
      const nextSuggestions = [...suggestions];
      dismissSuggestion(nextSuggestions, aBusiness, bBusiness);

      setSuggestions(nextSuggestions);
      setVisibleSuggestions(
        filterSuggestions(nextSuggestions, lastFiltersRef.current),
      );
    },
    [lastFiltersRef, suggestions, setSuggestions, setVisibleSuggestions],
  );

  const dismissDuplicates = useCallback(
    aBusinessId => {
      const nextSuggestions = [...suggestions];
      dismissSuggestions(nextSuggestions, aBusinessId);

      setSuggestions(nextSuggestions);
      setVisibleSuggestions(
        filterSuggestions(nextSuggestions, lastFiltersRef.current),
      );
    },
    [lastFiltersRef, suggestions, setSuggestions, setVisibleSuggestions],
  );

  const closeReviewDialog = useCallback(() => {
    setReviewBusinessIds([null, null]);
  }, [setReviewBusinessIds]);

  const onMergeSuccess = useCallback(
    (toBusinessId, fromBusinessId) => {
      closeReviewDialog();

      // Check if the Business that no longer exists has an entry in the root of duplicate business list
      const fromBusinessIndex = suggestions.findIndex(
        suggestion => suggestion.masterBusinessId === fromBusinessId,
      );
      if (fromBusinessIndex > -1) {
        dismissDuplicates(fromBusinessId);
      } else {
        dismissDuplicate(toBusinessId, fromBusinessId);
      }
    },
    [closeReviewDialog, dismissDuplicate, dismissDuplicates, suggestions],
  );

  const mergeBusinesses = useCallback(
    (destinationBusinessId, sourceBusinessId) => {
      dispatch(
        mergeBusiness(destinationBusinessId, sourceBusinessId, {
          onSuccess: onMergeSuccess,
        }),
      );
    },
    [dispatch, onMergeSuccess],
  );

  const onFiltersChanged = useCallback(
    newFilters => {
      lastFiltersRef.current = newFilters;
      setVisibleSuggestions(
        filterSuggestions(suggestions, lastFiltersRef.current),
      );
    },
    [lastFiltersRef, suggestions, setVisibleSuggestions],
  );

  useEffect(() => {
    dispatch(
      requestDuplicateBusinessSuggestions(
        {
          [SuggestDuplicateBusinessFilter.LIVESTOCK_SALE_ID]:
            getLivestockSaleId(),
        },
        {
          onSuccess,
          onError,
        },
      ),
    );
    setIsLoading(true);
  }, [dispatch, onError, onSuccess, setIsLoading]);

  const isReviewVisible = reviewBusinessIds[0] && reviewBusinessIds[1];

  return (
    <Dialog open onClose={closeSelf} maxWidth="md" fullWidth>
      <DialogTitle onClose={closeSelf}>
        Review Duplicate Business Suggestions
      </DialogTitle>
      <DialogContent dividers>
        <Grid container justifyContent="flex-end">
          <Grid item xs={1}>
            <Filters onAfterFiltersChanged={onFiltersChanged} />
          </Grid>
        </Grid>
        {isLoading && (
          <CenteredGreedy>
            <LoadingSpinner subjectName="Duplicate Business Suggestions" />
          </CenteredGreedy>
        )}
        {error && <h1>{error}</h1>}
        <WaitForSync requiredData={[ApiModel.BUSINESSES, ApiModel.DEPLOYMENTS]}>
          {Array.isArray(suggestions) && visibleSuggestions.length > 0 && (
            <DuplicateBusinessSuggestions
              dismissDuplicate={dismissDuplicate}
              dismissDuplicates={dismissDuplicates}
              reviewDuplicate={reviewDuplicate}
              suggestions={visibleSuggestions}
            />
          )}
        </WaitForSync>
        {isReviewVisible && (
          <ReviewDuplicateDialog
            onClose={closeReviewDialog}
            firstBusinessId={reviewBusinessIds[0]}
            secondBusinessId={reviewBusinessIds[1]}
            onMerge={mergeBusinesses}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeSelf}>Back</Button>
      </DialogActions>
    </Dialog>
  );
}
