import {
  SET_ACTIVE_ROLE,
  SET_CURRENT_LIVESTOCKSALE,
  SET_SALE_PROGRESS_SETTING,
  SET_SETTING,
  SET_TABLE_SETTING,
} from "constants/actionTypes";
import { AuctionScreenMode } from "constants/auctionModes";
import { PenScanningGroupingType } from "constants/auctionPens";
import { Settings } from "constants/settings";

const initialState = {
  [Settings.agencyId]: null,
  [Settings.auctionScreenMode]: AuctionScreenMode.Selling,
  [Settings.globalSearch]: {},
  [Settings.markColor]: "",
  [Settings.round]: null,
  [Settings.speciesId]: null,
  [Settings.tableViews]: {},
  [Settings.resolveAlternatives.filteredFields]: ["address", "name"],
  [Settings.resolveAlternatives.filterByLastReviewed]: true,
  [Settings.resolveAlternatives.filterByLastModified]: true,
  [Settings.resolveAlternatives.filterEmptyValues]: true,
  [Settings.resolveAlternatives.filterByAlternativeSource]: null,
  [Settings.suggestBuyerWayProperties]: true,
  [Settings.saleProgress]: {
    ExcludeNoSaleSaleLots: true,
    ExcludeDeceasedSaleLots: true,
    ExcludeWithdrawnSaleLots: true,
  },
  [Settings.showQuickSellDrafting]: false,
  [Settings.showDeduplicatedWeighLotScans]: false,
  [Settings.penScanningGroupType]: PenScanningGroupingType.LANE,
  [Settings.mtHostSettings]: {},
  [Settings.livestockQuickCreateValues]: null,
  [Settings.storedSaleLotFormData]: null,
  [Settings.useQuickBuyerInput]: false,
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_ROLE.REQUEST: {
      return {
        ...state,
        ...initialState,
        // Persist the table views
        [Settings.tableViews]: state[Settings.tableViews],
      };
    }
    case SET_CURRENT_LIVESTOCKSALE.SUCCESS: {
      return {
        ...state,
        ...initialState,
        // Persist the table views
        [Settings.tableViews]: state[Settings.tableViews],
      };
    }
    case SET_SETTING:
      return {
        ...state,
        ...action.payload,
      };
    case SET_TABLE_SETTING:
      return {
        ...state,
        [Settings.tableViews]: {
          ...state[Settings.tableViews],
          ...action.payload,
        },
      };
    case SET_SALE_PROGRESS_SETTING:
      return {
        ...state,
        [Settings.saleProgress]: {
          ...state[Settings.saleProgress],
          [action.setting]: action.value,
        },
      };
    default:
      return state;
  }
}
