import { MediaDocumentTypes } from "constants/documentTypes";

import { extractFileExtensionFromURL } from "lib";

export const isFileOfTypeMedia = (file, type) => {
  return (
    Object.values(MediaDocumentTypes).includes(file.type) &&
    type.includes(extractFileExtensionFromURL(file.image_url))
  );
};
