import React from "react";

import { VendorSplitModal } from "components/VendorSplits/NewVendorSplitModal/index";

import { ModalTypes } from "constants/navigation";

import { getLivestockSaleId } from "lib/navigation";

import { useModalAdapter } from "hooks";

function VendorSplitModalAdapter(props) {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.VendorSplit,
    props,
  );
  const {
    saleLotId = null,
    primaryBusinessId = null,
    consignmentId = null,
    isDefault = false,
    livestockSaleId = getLivestockSaleId(),
  } = hashParams;

  return (
    <VendorSplitModal
      key={JSON.stringify(hashParams)}
      consignmentId={consignmentId}
      saleLotId={saleLotId}
      primaryBusinessId={primaryBusinessId}
      livestockSaleId={livestockSaleId}
      isDefault={isDefault}
      onClose={onClose}
      returnTo={returnTo}
    />
  );
}

export default VendorSplitModalAdapter;
