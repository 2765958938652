import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";
import { useField } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { Input, Switch, withNamespace } from "components/Form/FormikControls";

export const deploymentEmailRecipientBaseValidation = Yup.object().shape(
  {
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().required("Required").email("Invalid email"),
    phoneNumber: Yup.string(),
    isAccountsRecipient: Yup.boolean(),
    isCommercialReportRecipient: Yup.mixed().when(
      "isComplianceReportRecipient",
      {
        is: isComplianceReportRecipient => isComplianceReportRecipient !== true,
        then: Yup.boolean()
          .required("Required")
          .isTrue("Email Recipients must be either compliance or commercial."),
      },
    ),
    isComplianceReportRecipient: Yup.mixed().when(
      "isCommercialReportRecipient",
      {
        is: isCommercialReportRecipient => isCommercialReportRecipient !== true,
        then: Yup.boolean()
          .required("Required")
          .isTrue("Email Recipients must be either compliance or commercial."),
      },
    ),
    isEnvdRecipient: Yup.boolean(),
  },
  [["isComplianceReportRecipient", "isCommercialReportRecipient"]],
);

function DeploymentEmailRecipientFormComponent(props) {
  const { namespace: ns } = props;

  const id = useField(withNamespace(ns, "id"))[0].value;
  const isCommercialReportRecipientField = useField(
    withNamespace(ns, "isCommercialReportRecipient"),
  );
  const isComplianceReportRecipientField = useField(
    withNamespace(ns, "isComplianceReportRecipient"),
  );

  const isEnvdRecipientField = useField(withNamespace(ns, "isEnvdRecipient"));
  const isAccountsRecipient = useField(
    withNamespace(ns, "isAccountsRecipient"),
  );

  function onAfterEmailChanged() {
    if (!id) {
      // when this is a new DeploymentEmailRecipient

      if (typeof isCommercialReportRecipientField[0].value !== "boolean") {
        // When the isCommercialReportRecipient field has not yet been set
        isCommercialReportRecipientField[2].setValue(true);
      }

      if (typeof isComplianceReportRecipientField[0].value !== "boolean") {
        // When the isComplianceReportRecipientField field has not yet been set
        isComplianceReportRecipientField[2].setValue(true);
      }

      if (typeof isEnvdRecipientField[0].value !== "boolean") {
        // When the isEnvdRecipientField field has not yet been set
        isEnvdRecipientField[2].setValue(true);
      }

      if (typeof isAccountsRecipient[0].value !== "boolean") {
        // When the isAccountsRecipient field has not yet been set
        isAccountsRecipient[2].setValue(true);
      }
    }
  }

  return (
    <>
      <input
        name={withNamespace(ns, "id")}
        type="hidden"
        value={id || undefined}
      />
      <Grid item xs={12} md={4}>
        <Input
          label="Email"
          name={withNamespace(ns, "email")}
          onChangeExtra={onAfterEmailChanged}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Input
          label="First Name"
          name={withNamespace(ns, "firstName")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Input
          label="Last Name"
          name={withNamespace(ns, "lastName")}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Input label="Phone" name={withNamespace(ns, "phoneNumber")} />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <Switch
          label="Is Accounts Recipient"
          name={withNamespace(ns, "isAccountsRecipient")}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <Switch
          label="Is Commercial Recipient"
          name={withNamespace(ns, "isCommercialReportRecipient")}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <Switch
          label="Is Compliance Recipient"
          name={withNamespace(ns, "isComplianceReportRecipient")}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <Switch
          label="Is eNVD Recipient"
          name={withNamespace(ns, "isEnvdRecipient")}
        />
      </Grid>
    </>
  );
}

DeploymentEmailRecipientFormComponent.propTypes = {
  namespace: PropTypes.string,
};

export default memo(DeploymentEmailRecipientFormComponent);
