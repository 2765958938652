import reduceReducers from "reduce-reducers";

import { USER_LEVEL } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeUserLevel } from "lib/serializers/userLevel";

const fetch = apiModelOfflineFetchReducer(USER_LEVEL, {
  deserializer: deserializeUserLevel,
  clearOnRequest: true,
});

const userLevelReducer = reduceReducers(fetch);

export default userLevelReducer;
