import React from "react";

import { Paper as MuiPaper } from "@material-ui/core";
import styled from "styled-components/macro";

export const Paper = styled(
  ({ maxHeight: ignored, backgroundColor: ignored2, isSquare, ...other }) => (
    <MuiPaper {...other} square={isSquare === undefined ? true : isSquare} />
  ),
)(
  ({ theme, maxHeight, backgroundColor = null }) => `
  padding: ${theme.space[2]}px ${theme.space[2]}px; 
  display: flex;
  flex-direction: column;
  flex: 1;
  ${
    backgroundColor
      ? `background-color: ${theme.colors[backgroundColor]} !important;`
      : ""
  }
  ${
    maxHeight
      ? `
    max-height: ${maxHeight}; 
    overflow-y: auto;
  `
      : ""
  }
 
  && {
    transition: ${theme.transitions[0]};
  }
`,
);

export const AbsolutePaper = styled(Paper)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-bottom: 0;
`;
