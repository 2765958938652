import React from "react";

import { useSelector } from "react-redux";

import Button from "components/Button";
import { FoundPremiumFeature } from "components/PremiumFeature";
import { Text } from "components/Text";

import { DeploymentPermissions } from "constants/permissions";
import { Species } from "constants/species";

import {
  getCurrentSale,
  selectDraftingInformationIdsBySelectableOnSaleWatcher,
  selectRoleCurrentDeployments,
} from "selectors";

import { useIsMobile, useSomeHasPermission, useToggle } from "hooks";

import {
  BasicSaleStatisticTable,
  SaleStatisticTable,
  ScaleStatisticsOverViewComponent,
  ScannerStatisticsOverViewComponent,
} from "./Tables";

export const SaleStatisticsOverViewComponent = () => {
  const [showScaleStatistics, toggleShowSaleStatistics] = useToggle(false);
  const [showScannerStatistics, toggleShowScannerStatistics] = useToggle(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const sale = useSelector(getCurrentSale);
  const scaleIds = useSelector(
    selectDraftingInformationIdsBySelectableOnSaleWatcher,
  ).true;
  const scannerIds = useSelector(
    selectDraftingInformationIdsBySelectableOnSaleWatcher,
  ).false;

  const hasPremiumSaleStatisticsFeaturePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featurePremiumSaleStatistics,
  );

  const handleScaleClick = event => {
    hasPremiumSaleStatisticsFeaturePermission
      ? toggleShowSaleStatistics()
      : setAnchorEl(event.target);
  };

  const handleScannerClick = event => {
    hasPremiumSaleStatisticsFeaturePermission
      ? toggleShowScannerStatistics()
      : setAnchorEl(event.target);
  };

  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile ? (
        <SaleStatisticTable isMobile={isMobile} />
      ) : (
        <BasicSaleStatisticTable isMobile={isMobile} />
      )}
      {showScaleStatistics && (
        <>
          <Text className="p-4">
            Single Weighs are not included in scale statistics yet
          </Text>
          <ScaleStatisticsOverViewComponent />
        </>
      )}
      {showScannerStatistics && <ScannerStatisticsOverViewComponent />}
      {!isMobile && (
        <>
          {scaleIds?.length > 0 && sale.species_id !== Species.SHEEP && (
            <Button className="m-12" onClick={handleScaleClick}>
              {showScaleStatistics ? "Hide" : "Show"} Scale Statistics
            </Button>
          )}
          {scannerIds?.length > 0 && (
            <Button className="m-12" onClick={handleScannerClick}>
              {showScannerStatistics ? "Hide" : "Show"} Scanner Statistics
            </Button>
          )}
        </>
      )}
      <FoundPremiumFeature setAnchorEl={setAnchorEl} anchorEl={anchorEl} />
    </>
  );
};
