import { useCloseDialog } from "hooks/useCloseDialog";

/**
 * Provides utility functions for managing hash modals.
 */
export const useModalAdapter = (modalType, modalProps) => {
  const { hash } = modalProps;
  const hashParams = JSON.parse(hash[modalType] || null);

  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";

  const closeDialog = useCloseDialog(decodedReturnTo);

  return [hashParams, decodedReturnTo, closeDialog];
};
