import styled from "styled-components/macro";

export const SubGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 6px;
`;

export const VerticalQuestion = styled.div`
  align-items: center;
  ${({ error, theme }) =>
    error &&
    `
  color: ${theme.colors.inputError};
`};
`;

export const ErrorArea = styled.div`
  grid-area: error;
  ${({ theme, color, large }) => `
    color:  ${color || theme.colors.inputError};
    
    font-size: ${large ? `${theme.fontSizes.charlie}px` : "10px"};
    line-height: ${large ? `${theme.fontSizes.delta}px` : "12px"};
    ${large ? `padding: ${theme.space[1]}px;` : null}
    `}
  font-weight: bold;
`;

export const LabelArea = styled.div`
  grid-area: label;
  font-size: 16px;
  font-weight: bold;
  line-height: 36px;
  display: flex;
  flex-direction: row;
`;

export const BodyArea = styled.div`
  grid-area: choice;
`;
