import { intersection, uniq } from "lodash";
import { createSelector } from "reselect";

import { PenTypes, ScanLotStatus } from "constants/auctionPens";

import { EMPTY_ARRAY } from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { getPicFromScan } from "lib/scans";

import {
  createAggregateIdsByKeySelector,
  createLookupCombiner,
  createLookupSelectors,
  getAuctionPens,
  getConsignments,
  getReceivalLots,
  getSaleLots,
  getScans,
  selectEidsByReceivalLotIdLookup,
  selectEidsBySaleLotIdLookup,
  selectFilteredReceivalLotIds,
  selectOrderedPenIdsByPenType,
  selectReceivalLotIdByEidLookup,
  selectReceivalLotIdsByConsignmentIdLookup,
  selectReceivalLotIdsByPenIdLookup,
  selectReceivalLotIdsByReceivalPenIdLookup,
} from "selectors";

export const selectFilteredReceivalLotIdsByReceivalPenId = createSelector(
  [selectFilteredReceivalLotIds, selectReceivalLotIdsByReceivalPenIdLookup],
  (filteredReceivalLotIds, receivalLotIdsByReceivalPenIdLookup) => {
    return Object.entries(receivalLotIdsByReceivalPenIdLookup).reduce(
      (acc, [receivalPenId, receivalLotIds]) => {
        acc[receivalPenId] = intersection(
          filteredReceivalLotIds,
          receivalLotIds,
        );
        return acc;
      },
      {},
    );
  },
);

export const getReceivalLotsIdsByReceivalPenId = receivalPenId => state => {
  return (
    selectFilteredReceivalLotIdsByReceivalPenId(state)[receivalPenId] ||
    EMPTY_ARRAY
  );
};

export const getEidsByReceivalLotId = receivalLotId => state =>
  selectEidsByReceivalLotIdLookup(state)[receivalLotId] || EMPTY_ARRAY;

export const getReceivalLotById = receivalLotId => state =>
  getReceivalLots(state)[receivalLotId] || null;

function selectReceivalLotStatusByIdReducer(receivalLot, eidsByReceivalLotId) {
  if (!eidsByReceivalLotId[receivalLot.id]?.length) {
    return ScanLotStatus.NONE_SCANNED;
  } else if (
    receivalLot.quantity ===
    eidsByReceivalLotId[receivalLot.id]?.filter(Boolean)?.length
  ) {
    // All animals are scanned and accounted for
    return ScanLotStatus.ALL_SCANNED;
  } else {
    // Some of the animals are scanned.
    return ScanLotStatus.PARTIALLY_SCANNED;
  }
}

export const [selectReceivalLotStatusByIdLookup, getReceivalLotStatusById] =
  createLookupSelectors(
    [getReceivalLots, selectEidsByReceivalLotIdLookup],
    createLookupCombiner(selectReceivalLotStatusByIdReducer),
    EMPTY_ARRAY,
  );

export const getReceivalLotIdsByConsignmentId = consignmentId => state =>
  selectReceivalLotIdsByConsignmentIdLookup(state)[consignmentId] ||
  EMPTY_ARRAY;

export const selectReceivalLotsAsOptions = createSelector(
  [getReceivalLots, getAuctionPens, selectEidsByReceivalLotIdLookup, getScans],
  (receivalLots, auctionPens, eidsByReceivalLotLookup, scans) =>
    Object.values(receivalLots).map(receivalLot => {
      const receivalPen = getAuctionPenDisplayName(
        auctionPens[receivalLot.receivalPenId],
      );

      const PICs = [];
      if (!eidsByReceivalLotLookup[receivalLot.id]?.length) {
        PICs.push("No Scans Yet");
      } else {
        const scansInLot = eidsByReceivalLotLookup[receivalLot.id].map(
          eid => scans[eid],
        );
        // Find the unique PICs from the tag origin for each of the scans in this lot's NLIS ID.
        const scanPics = new Set(
          scansInLot.map(getPicFromScan).filter(Boolean) || [],
        );
        PICs.push(...Array.from(scanPics));

        if (scansInLot.some(scan => !scan.animal?.nlis_id)) {
          PICs.push("NLIS Lookups Pending");
        }
      }

      const scanCount = eidsByReceivalLotLookup[receivalLot.id]?.length || 0;

      return {
        value: receivalLot.id,
        hasConsignment: receivalLot.consignmentId
          ? "Linked To Consignment"
          : "Not Linked To Consignment",
        PICs,
        label: `${receivalPen} - ${receivalLot.mark} (${scanCount} / ${receivalLot.quantity})`,
      };
    }),
);

export const selectMarksByReceivalPenId = createAggregateIdsByKeySelector(
  getReceivalLots,
  "receivalPenId",
  "mark",
);

export const selectUsedReceivalLotIdsByReceivalPenId = createSelector(
  [getReceivalLots, getAuctionPens],
  (receivalLots, auctionPens) =>
    Object.values(auctionPens)
      .filter(pen => pen.penType === PenTypes.RECEIVING)
      .reduce((acc, pen) => {
        acc[pen.id] = Object.values(receivalLots).filter(
          lot => lot.receivalPenId === pen.id,
        );
        return acc;
      }, []),
);

export const selectReceivalPenNamesByConsignmentId = createSelector(
  [
    getConsignments,
    selectReceivalLotIdsByConsignmentIdLookup,
    getReceivalLots,
    getAuctionPens,
  ],
  (
    consignmentsByIdLookup,
    receivalLotIdsByConsignmentIdLookup,
    receivalLotIdLookup,
    auctionPensLookup,
  ) =>
    Object.keys(consignmentsByIdLookup).reduce((acc, consignmentId) => {
      const receivalLotIds = receivalLotIdsByConsignmentIdLookup[consignmentId];
      acc[consignmentId] = [];
      receivalLotIds?.map(receivalLotId => {
        const receivalLot = receivalLotIdLookup[receivalLotId];
        acc[consignmentId].push(
          auctionPensLookup[receivalLot.receivalPenId]?.start_pen || "",
        );
        return acc;
      });
      return acc;
    }, {}),
);

export const selectOrderedReceivalLotIds = createSelector(
  [
    selectReceivalLotIdsByPenIdLookup,
    state => selectOrderedPenIdsByPenType(state)[PenTypes.RECEIVING],
  ],
  (receivalLotIdsByReceivalPenIdLookup, receivalPens) =>
    [].concat(
      ...receivalPens
        .map(
          receivalPen =>
            receivalLotIdsByReceivalPenIdLookup[receivalPen.penId] || [],
        )
        .filter(Boolean),
    ),
);

export const selectConsignmentByReceivalLotIdLookup = createSelector(
  [getConsignments, getReceivalLots],
  (consignmentByIdLookup, receivalLotByIdLookup) =>
    Object.entries(receivalLotByIdLookup).reduce(
      (acc, [receivalLotId, receivalLot]) => {
        acc[receivalLotId] = consignmentByIdLookup[receivalLot.consignmentId];
        return acc;
      },
      {},
    ),
);

export const selectReceivalLotIdsBySaleLotIdLookup = createSelector(
  [getSaleLots, selectEidsBySaleLotIdLookup, selectReceivalLotIdByEidLookup],
  (saleLots, eidsBySaleLotIdLookup, receivalLotIdByEidLookup) => {
    return Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      acc[saleLot.id] = uniq(
        eidsBySaleLotIdLookup[saleLotId]?.map(
          eid => receivalLotIdByEidLookup[eid],
        ),
      );
      return acc;
    }, {});
  },
);
