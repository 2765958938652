import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import { useField } from "formik";
import * as Yup from "yup";

import { CheckBox, Input, withNamespace } from "components/Form/FormikControls";

import { getAusPaymentsNetworkBsbs } from "constants/ausPaymentsNetworkBsbs";

const BSB_LOOKUP = getAusPaymentsNetworkBsbs().reduce((acc, bsbEntry) => {
  acc[bsbEntry[0]] = bsbEntry;
  return acc;
}, {});

export function normaliseBsb(bsb) {
  return (bsb || "").replace(/[^\d]/g, "");
}

function bsbValueTransformer(value) {
  const valueNormalised = normaliseBsb(value);
  const bsbPart1 = valueNormalised.slice(0, 3);
  const bsbPart2 = valueNormalised.slice(3, 6);

  return bsbPart2
    ? // Return both of the parts combined with the the hyphen separator
      `${bsbPart1}-${bsbPart2}`
    : // Return just the first part of the BSB, without the hyphen,
      // unless that was the last character entered by the user.
      // This provides a more comfortable experience when deleting/editing
      // character around the hyphen.
      `${bsbPart1}${value.slice(-1) === "-" ? "-" : ""}`;
}

function getBsbEntryHelpText(bsbEntry) {
  const [_ignored, mnemonic = "", bsbName = ""] = bsbEntry || [];
  return [mnemonic, bsbName].filter(Boolean).join(", ");
}

function checkIsRegistered(value) {
  return Boolean(BSB_LOOKUP[normaliseBsb(value)]) || value === undefined;
}

export const ValidationSchema = Yup.object().shape({
  bsb: Yup.string()
    .nullable()
    .test("is-registered", "That BSB is not recognised", checkIsRegistered),
  accountNumber: Yup.string().nullable(),
  accountName: Yup.string().nullable(),
  payid: Yup.string().nullable(),
  chequePayableTo: Yup.string().nullable(),
});

export function BsbInput(props) {
  const { disabled, label, name } = props;

  const [{ value: bsb }] = useField(name);

  const bsbNormalised = normaliseBsb(bsb);

  const bsbEntry =
    bsbNormalised.length === 6 ? BSB_LOOKUP[bsbNormalised] : null;

  return (
    <Input
      disabled={disabled}
      helpText={getBsbEntryHelpText(bsbEntry)}
      label={label}
      maxLength={7}
      name={name}
      valueTransformer={bsbValueTransformer}
    />
  );
}

function BankingDetailsFormComponent(props) {
  const { namespace: ns, showBpay } = props;
  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <BsbInput label="BSB" name={withNamespace(ns, "bsb")} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Input
          label="Account Number"
          maxLength={20}
          name={withNamespace(ns, "accountNumber")}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label="Account Name"
          maxLength={50}
          name={withNamespace(ns, "accountName")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input label="PayID" maxLength={50} name={withNamespace(ns, "payid")} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Cheque Payable To"
          maxLength={255}
          name={withNamespace(ns, "chequePayableTo")}
        />
      </Grid>
      {showBpay && (
        <>
          <Grid item xs={12} md={6}>
            <Input
              label="BPay CRN"
              maxLength={20}
              name="bpayCustomerReferenceNumber"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CheckBox
              id="generateBpayCRNCheckBox"
              label="Request new BPay CRN"
              name="generate_bpay_crn"
            />
          </Grid>
        </>
      )}
    </>
  );
}

export default memo(BankingDetailsFormComponent);
