import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";

import {
  CHECK_USER_AUTH,
  CLEAR_LOGIN_FAILURE,
  CURRENT_USER,
  LIVESTOCK_AGENT,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  NLIS_SIGN_UP_FAILURE,
  NLIS_SIGN_UP_REQUEST,
  NLIS_SIGN_UP_SUCCESS,
  RECEIVE_SOCKET_ID,
  RECEIVE_WATCHER_ENTRY,
  REQUEST_NEW_JWT_TOKEN_COMMIT,
  REQUEST_NEW_JWT_TOKEN_OFFLINE,
  REQUEST_NEW_JWT_TOKEN_ROLLBACK,
  REQUEST_WATCHER_ENTRY,
  SALEYARD_ADMIN,
  SET_ACTIVE_ROLE,
  SET_CONCURRENT_USER_BLOCK,
  SET_GEO_BLOCK,
  STORE_GEO_DATA,
  USER_ROLES,
} from "constants/actionTypes";
import { UNIX_START_TIME } from "constants/time";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import {
  deserializeBusinessUserRole,
  deserializeConcurrentUserBlock,
  deserializeLivestockAgentRole,
  deserializeSaleWatcherRole,
  deserializeSaleyardAdminRole,
  deserializeScaleOperatorRole,
} from "lib/serializers/auth";

/**
 * @typedef {ProcessorRole|SaleyardOperatorRole|ScaleOperatorRole|LivestockAgentRole} ActiveRole
 */
/**
 * @typedef {Object} Agency
 * @property {Number} agency_id
 * @property {String} name
 *
 */

/**
 * @typedef {Object} Saleyard
 * @property {String} name
 * @property {Number} saleyard_id
 * @property {Array<Number>} weighbridge_species_ids
 */

/**
 * @typedef {Object} LivestockAgentRole
 * @property {Array<Agency>} agencies
 * @property {Array<Saleyard>} saleyards
 * @property {Boolean} default_role
 * @property {String} name
 * @property {String} slug
 * @property {ROLE_TYPES.STOCK_AGENT} type
 */

/**
 * @typedef {Object} Business
 * @property {String} id;
 * @property {String} name;
 * @property {Array<Number>} properties;
 */

/**
 * @typedef {Object} ProcessorRole
 * @property {Business} business
 * @property {Boolean} default_role
 * @property {Array<Saleyard>} saleyards
 * @property {String} name
 * @property {String} slug
 * @property {ROLE_TYPES.BUSINESS_USER} type
 */
/**
 * @typedef {Object} SaleyardOperatorRole
 * @property {Array<Saleyard>} saleyards
 * @property {Boolean} default_role
 * @property {String} name
 * @property {String} slug
 * @property {ROLE_TYPES.SALEYARD_OPERATOR} type
 */

/**
 * @typedef {Object} ScaleOperatorRole
 * @property {Number} default_saleyard_id
 * @property {Boolean} is_paid_user
 * @property {Number} last_buyer_id
 * @property {Boolean} default_role
 * @property {String} name
 * @property {String} slug
 * @property {ROLE_TYPES.SCALE_OPERATOR} type
 * @property {NLISCredentials} [nlis_agent_credentials]*
 * @property {NLISCredentials} [nlis_saleyard_credentials]*
 */
/**
 * @typedef {Object} NLISCredentials
 * @property {String} nlis_user  the user's username as known to the NLIS
 * @property {String} nlis_email the user's email address as known to the NLIS
 * @property {String} nlis_saleyard_id and NLIS EUSY saleyard number
 */

/**
 * @typedef {Object} AuthUserContext
 * @property {ActiveRole} activeRole
 * @property {Array<ProcessorRole>} business_user
 * @property {Array<SaleyardOperatorRole>} saleyard_admin
 * @property {Array<ScaleOperatorRole>} scale_operator
 * @property {Array<LivestockAgentRole>} livestock_agent
 * @property {Array} channels
 * @property {String} email
 * @property {String} first_name
 * @property {Boolean} is_staff
 * @property {String} last_name
 * @property {Number} user_id
 * @property {Boolean} isAuthenticated
 */
/**
 * Reducer for the user auth context
 * @param state {AuthUserContext}
 * @param action
 * @returns {AuthUserContext}
 */

const defaultState = {
  isFetching: false,
  isNLISFetching: false,
  isAuthenticated: false,
  watcherFetching: false,
  watcherStatus: undefined,
  watcherErrorMessage: undefined,
  lastJWTRequestTime: UNIX_START_TIME,
  requestingNewJWTToken: false,
  channels: [],
  lastLocation: null,
  geoBlock: null,
  concurrentUserBlock: null,
};

// TODO - pull nlis stuff out to it's own blob.
const authentication = (state = defaultState, action) => {
  switch (action.type) {
    case STORE_GEO_DATA:
      return {
        ...state,
        lastLocation: action.position,
      };
    case SET_GEO_BLOCK:
      return {
        ...state,
        geoBlock: action.geoBlock,
      };

    case SET_CONCURRENT_USER_BLOCK:
      return {
        ...state,
        concurrentUserBlock: deserializeConcurrentUserBlock(
          action.concurrentUserBlock,
        ),
      };
    case CHECK_USER_AUTH.REQUEST:
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        lastJWTRequestTime: Date.now(),
        requestingNewJWTToken: true,
      };
    case CHECK_USER_AUTH.FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        requestingNewJWTToken: false,
        errorMessage: action.message,
      };
    case CHECK_USER_AUTH.SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        requestingNewJWTToken: false,
        isFetching: false,
        isAuthenticated: true,
      };
    case REQUEST_NEW_JWT_TOKEN_OFFLINE:
      return {
        ...state,
        lastJWTRequestTime: Date.now(),
        // due to the nature offline, use client side time as a judge of
        // when token should be refreshed
        requestingNewJWTToken: true,
      };
    case REQUEST_NEW_JWT_TOKEN_COMMIT:
      return {
        ...state,
        requestingNewJWTToken: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      };
    case CLEAR_LOGIN_FAILURE:
      return {
        ...state,
        errorMessage: null,
      };
    case REQUEST_NEW_JWT_TOKEN_ROLLBACK:
    case LOGOUT_SUCCESS:
      return {
        ...defaultState,
        isAuthenticated: false,
        requestingNewJWTToken: false,
      };
    case RECEIVE_SOCKET_ID:
      return {
        ...state,
        socketId: action.socketId,
      };

    case NLIS_SIGN_UP_REQUEST: {
      return { ...state, isNLISFetching: true };
    }
    case NLIS_SIGN_UP_FAILURE: {
      return { ...state, isNLISFetching: false };
    }
    case REQUEST_WATCHER_ENTRY: {
      return {
        ...state,
        watcherFetching: true,
        watcherStatus: undefined,
        watcherMessage: undefined,
        watcherSaleName: undefined,
      };
    }
    case RECEIVE_WATCHER_ENTRY: {
      const { status, livestockSale, errorMessage } = action;
      return {
        ...state,
        watcherFetching: false,
        watcherStatus: status,
        watcherSale: livestockSale,
        watcherErrorMessage: errorMessage,
      };
    }
    default:
      return state;
  }
};

const currentUser = (
  state = {
    isFetching: false,
    // Other data?
  },
  action,
) => {
  switch (action.type) {
    case CURRENT_USER.FETCH_BULK.REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CURRENT_USER.FETCH_BULK.SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

const activeRole = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_ROLE.REQUEST: {
      const { role } = action;
      return {
        ...role,
      };
    }
    case NLIS_SIGN_UP_SUCCESS: {
      // TODO -  AP-702 - move the nlis credentials up to a deployment/saleyard/their own endpoint rather than on a role.
      // Update the active instance.
      const credentialKey =
        action.credentialType === "AGENT"
          ? "nlis_agent_credentials"
          : "nlis_saleyard_credentials";
      return {
        ...state,
        [credentialKey]: action.nlisCredentials,
      };
    }
    case LIVESTOCK_AGENT.ADD_REPORT_FAVOURITE.REQUEST: {
      const { payload } = action;
      return {
        ...state,
        reportFavourites: [...state.reportFavourites, payload.slug],
      };
    }
    case LIVESTOCK_AGENT.REMOVE_REPORT_FAVOURITE.REQUEST: {
      const { payload } = action;
      return {
        ...state,
        reportFavourites: state.reportFavourites.filter(
          favourite => favourite !== payload.slug,
        ),
      };
    }
    case SALEYARD_ADMIN.ADD_REPORT_FAVOURITE.REQUEST: {
      const { payload } = action;
      return {
        ...state,
        reportFavourites: [...state.reportFavourites, payload.slug],
      };
    }
    case SALEYARD_ADMIN.REMOVE_REPORT_FAVOURITE.REQUEST: {
      const { payload } = action;
      return {
        ...state,
        reportFavourites: state.reportFavourites.filter(
          favourite => favourite !== payload.slug,
        ),
      };
    }
    default:
      return state;
  }
};

const saleyardAdminRoleFetch = apiModelOfflineFetchReducer(
  USER_ROLES.SALEYARD_ADMIN,
  {
    deserializer: deserializeSaleyardAdminRole,
  },
);

const livestockAgentRoleFetch = apiModelOfflineFetchReducer(
  USER_ROLES.LIVESTOCK_AGENT,
  {
    deserializer: deserializeLivestockAgentRole,
  },
);
const saleWatcherRoleFetch = apiModelOfflineFetchReducer(
  USER_ROLES.SALE_WATCHER,
  {
    deserializer: deserializeSaleWatcherRole,
  },
);
const scaleOperatorRoleFetch = apiModelOfflineFetchReducer(
  USER_ROLES.SCALE_OPERATOR,
  {
    deserializer: deserializeScaleOperatorRole,
  },
);

const businessUserRoleFetch = apiModelOfflineFetchReducer(
  USER_ROLES.BUSINESS_USER,
  {
    deserializer: deserializeBusinessUserRole,
  },
);

const saleyardAdminFavouritesReducer = (state = {}, action) => {
  switch (action.type) {
    case SALEYARD_ADMIN.ADD_REPORT_FAVOURITE.REQUEST: {
      const { payload } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            reportFavourites: [
              ...state.byId[payload.id].reportFavourites,
              payload.slug,
            ],
          },
        },
      };
    }
    case SALEYARD_ADMIN.REMOVE_REPORT_FAVOURITE.REQUEST: {
      const { payload } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            reportFavourites: state.byId[payload.id].reportFavourites.filter(
              favourite => favourite !== payload.slug,
            ),
          },
        },
      };
    }
    default:
      return state;
  }
};

const livestockAgentFavouritesReducer = (state = {}, action) => {
  switch (action.type) {
    case LIVESTOCK_AGENT.ADD_REPORT_FAVOURITE.REQUEST: {
      const { payload } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            reportFavourites: [
              ...state.byId[payload.id].reportFavourites,
              payload.slug,
            ],
          },
        },
      };
    }
    case LIVESTOCK_AGENT.REMOVE_REPORT_FAVOURITE.REQUEST: {
      const { payload } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            reportFavourites: state.byId[payload.id].reportFavourites.filter(
              favourite => favourite !== payload.slug,
            ),
          },
        },
      };
    }
    default:
      return state;
  }
};

const roleReducer = combineReducers({
  authentication,
  activeRole,
  currentUser,
  saleyardAdmins: reduceReducers(
    saleyardAdminRoleFetch,
    saleyardAdminFavouritesReducer,
  ),
  livestockAgents: reduceReducers(
    livestockAgentRoleFetch,
    livestockAgentFavouritesReducer,
  ),
  saleWatchers: saleWatcherRoleFetch,
  scaleOperators: scaleOperatorRoleFetch,
  businessUsers: businessUserRoleFetch,
});

export default roleReducer;
