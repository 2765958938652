import {
  ExportSites,
  ExportSiteValidationErrorOrStatusGetter,
} from "constants/exportSites";

import { EMPTY_ARRAY } from "lib/index";

export function advancedDraftingValidation(
  saleLotValue,
  currentDeploymentSales,
  consignments,
  rounds,
) {
  const consignment = consignments[saleLotValue.consignment_id] || {};
  // We need the consignment available due to our change of selectors, so fudge it in there.
  // Additionally, the round pre-return from server is sale_round_id, but the response has sale_round as a nested object.
  const saleLotWithConsignment = {
    consignment,
    ...saleLotValue,
    sale_round: saleLotValue.sale_round || rounds[saleLotValue.sale_round_id],
  };
  const deploymentSale = currentDeploymentSales[consignment.deployment_sale];

  const exportSites =
    Object.keys(ExportSites).filter(exportSite =>
      Boolean(deploymentSale?.export_sites.find(es => es === exportSite)),
    ) || EMPTY_ARRAY;

  return exportSites.reduce((sites, exportSite) => {
    sites[exportSite] = ExportSiteValidationErrorOrStatusGetter[exportSite](
      saleLotWithConsignment,
    );
    return sites;
  }, {});
}
