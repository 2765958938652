import { useState, useCallback } from "react";

export const useBoolean = (initialState = false) => {
  const [value, setValue] = useState(initialState);

  const setTrue = useCallback(() => setValue(true), [setValue]);
  const setFalse = useCallback(() => setValue(false), [setValue]);
  const toggle = useCallback(() => setValue(!value), [value]);

  return [value, setTrue, setFalse, setValue, toggle];
};
