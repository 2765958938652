import React from "react";

import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { setSetting } from "actions";

import { ReactSelect } from "components/SearchableSelector";

import { Settings } from "constants/settings";
import { ViewModeConfig, ViewModes } from "constants/watcher";

import { getIsSaleyardAdmin, getSetting } from "selectors";

const Drawer = styled.div`
  position: absolute;
  right: 0;
  bottom: 1%;
  display: flex;
  align-items: center;
  height: 36px;
  width: ${({ showing }) => (showing ? "230px" : "50px")};
  ${({ theme }) => `
    background-color: ${theme.colors.grayAE};  
    border-top-left-radius: ${theme.radii[4]}px;
    border-bottom-left-radius: ${theme.radii[4]}px;
    box-shadow:  ${theme.shadows[1]};
  `}
`;

const SizedSelect = styled(ReactSelect)`
  width: 100%;
`;

const DrawerOpener = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const ModeSwitcher = () => {
  const dispatch = useDispatch();
  const [showing, setShowing] = React.useState(false);
  const onChange = e => {
    dispatch(setSetting(Settings.watcherViewMode, e.value));
    setShowing(false);
  };
  const toggleShowing = () => {
    setShowing(!showing);
  };

  const isFullScreen = useSelector(getSetting(Settings.fullScreen));
  const toggleFullScreen = () =>
    dispatch(setSetting(Settings.fullScreen, !isFullScreen));

  const currentMode =
    useSelector(getSetting(Settings.watcherViewMode)) || ViewModes.DEFAULT;

  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  const visibleOptions = Object.entries(ViewModeConfig)
    .filter(([_ignored, config]) => config.isPublic || isSaleyardAdmin)
    .map(([modeKey, config]) => ({
      label: config.label,
      value: modeKey,
    }));

  const currentModeOption = visibleOptions.find(o => o.value === currentMode);

  return (
    <Drawer showing={showing}>
      <DrawerOpener onClick={toggleShowing}>
        <FontAwesomeIcon icon={faEye} size="3x" />
      </DrawerOpener>
      {showing && (
        <>
          <SizedSelect
            isClearable={false}
            value={currentModeOption}
            options={visibleOptions}
            onChange={onChange}
            menuPlacement="top"
          />
          <DrawerOpener onClick={toggleFullScreen}>
            <FontAwesomeIcon
              icon={isFullScreen ? faCompressAlt : faExpandAlt}
              size="2x"
            />
          </DrawerOpener>
        </>
      )}
    </Drawer>
  );
};

export default ModeSwitcher;
