import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

export const FaIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  ${({ theme, color }) => {
    if (color) {
      return `color: ${theme.colors[color]}`;
    }
  }};
`;
