import React from "react";

import { Checkbox } from "@material-ui/core";
import { getIn, useField } from "formik";

import { withNamespace } from "components/Form/FormikControls";

import { CustomQueryField } from "containers/Settings/RuleBooks/DataSource";
import {
  getSchemaField,
  getSchemaFieldReverseRelations,
  updateCriterionRelations,
} from "containers/Settings/RuleBooks/lib";
import { useRuleFieldSchema } from "containers/Settings/RuleBooks/schemaContext";

import { CheckGroups } from "./CheckGroups";

export function CriterionRelationField(props) {
  const { namespace: ns, onChangeCriterionQuery, parentFieldId } = props;

  const schema = useRuleFieldSchema();

  const options = getSchemaFieldReverseRelations(
    parentFieldId,
    schema,
    true,
  ).map(option => {
    const field = getSchemaField(option.id, schema);
    if (!field) {
      return option;
    }
    return {
      name: option.name || field.plural,
      via: option.via,
      id: option.id,
    };
  });

  return (
    <CustomQueryField
      namespace={ns}
      onChangeDropdown={onChangeCriterionQuery}
      options={options}
    />
  );
}

export function Criterion(props) {
  const { criteriaOptions, namespace: ns, parentFieldId } = props;
  const [
    { value: criterion },
    // eslint-disable-next-line no-unused-vars
    ignored,
    { setValue },
  ] = useField(ns);
  const schema = useRuleFieldSchema();
  const queryFieldId = getIn(criterion, "query.fieldId");

  const isUsingRelatedQueryData = Boolean(criterion.query);

  function onChangeCheckUsingQueryData(event) {
    setValue(
      updateCriterionRelations(
        criterion,
        event.target.checked ? { fieldId: "" } : null,
        parentFieldId,
        parentFieldId,
        schema,
      ),
    );
  }

  const checkParentFieldId = isUsingRelatedQueryData
    ? queryFieldId
    : parentFieldId;

  function setUpdateCriterionRelations(fieldId) {
    setValue(
      updateCriterionRelations(
        criterion,
        { fieldId },
        parentFieldId,
        parentFieldId,
        schema,
      ),
    );
  }

  function onChangeCriterionQuery(event) {
    setUpdateCriterionRelations(event.target.value);
  }

  return (
    <div>
      <p>
        <Checkbox
          type="checkbox"
          name={withNamespace(ns, "usingQueryData")}
          onChange={onChangeCheckUsingQueryData}
          checked={isUsingRelatedQueryData}
        />{" "}
        {!isUsingRelatedQueryData ? (
          "(not using related data)"
        ) : (
          <>
            when related{" "}
            <CriterionRelationField
              namespace={withNamespace(ns, "query")}
              onChangeCriterionQuery={onChangeCriterionQuery}
              parentFieldId={parentFieldId}
            />
            {criterion.checkGroups.length === 0 && " exist."}
          </>
        )}
        {criterion.checkGroups.length > 0 && " meet the following conditions:"}
      </p>
      <CheckGroups
        criteriaOptions={criteriaOptions}
        namespace={withNamespace(ns, "checkGroups")}
        parentFieldId={checkParentFieldId}
      />
    </div>
  );
}
