import React from "react";

import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActionButtonContainer,
  ActionButton,
} from "components/AgGrid/ActionButton";

export function actionsColumnRenderer({ data, context }) {
  return (
    <ActionButtonContainer>
      <ActionButton
        data-tour="action-view"
        onClick={() => context.handleViewAction(data)}
      >
        <FontAwesomeIcon icon={faSearch} />
        View Status
      </ActionButton>
    </ActionButtonContainer>
  );
}
