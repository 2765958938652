import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { addAuctionPen, patchSaleLot } from "actions";

import PenMultipleLotsModal from "components/PenMultipleLotsModal";

import { Settings } from "constants/settings";

import { expandAuctionPen } from "lib/auctionPens";

import {
  getAuctionPenById,
  getOverflowPenByAuctionPenId,
  getSaleLotIdsByAuctionPenId,
  getSetting,
  selectFormattedSaleLotsBySaleLotId,
} from "selectors";

function RepenMultiple({ toggleRepenIsOpen, auctionPenId }) {
  const { start_pen: startPen, end_pen: endPen } = useSelector(
    getAuctionPenById(auctionPenId),
  );

  const selectedRoundId = useSelector(getSetting(Settings.round));
  const overflowPen = useSelector(getOverflowPenByAuctionPenId(auctionPenId));
  // Yuck...
  const formattedSaleLotsBySaleLotId = useSelector(
    selectFormattedSaleLotsBySaleLotId,
  );
  const saleLotIds = useSelector(getSaleLotIdsByAuctionPenId(auctionPenId));
  const saleLots = saleLotIds.map(
    saleLotId => formattedSaleLotsBySaleLotId[saleLotId],
  );

  const dispatch = useDispatch();

  const onPatchSaleLot = payload => {
    dispatch(patchSaleLot(payload, { changeReason: "Bulk repen" }));
  };

  const onAddAuctionPen = (payload, penId) => {
    dispatch(addAuctionPen(payload, penId));
  };

  const auctionPenInitialValues = {
    ...expandAuctionPen({
      start_pen: startPen,
      end_pen: endPen,
    }),
    saleLots,
    saleRoundId: selectedRoundId,
    overflowPen,
  };

  return (
    <PenMultipleLotsModal
      groupedSaleLots={saleLots}
      startPen={startPen}
      endPen={endPen}
      saleRoundId={selectedRoundId}
      closeSelf={toggleRepenIsOpen}
      patchSaleLot={onPatchSaleLot}
      addAuctionPen={onAddAuctionPen}
      initialValues={auctionPenInitialValues}
    />
  );
}

export default React.memo(RepenMultiple);
