import uniq from "lodash/uniq";

export const aggregateText = (obj, accessor, label) => {
  const accessorFunc =
    typeof accessor === "function" ? accessor : item => item[accessor];
  try {
    const options = uniq(obj.map(accessorFunc));
    const count = options.length;
    return count > 1 ? `${count} ${label}` : options[0];
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
    return "";
  }
};

export const createListString = (list, showCount) => {
  // return an array as a string, setting how many values to show before a remainder
  if (!list.length) {
    return "";
  }
  return list.length > showCount
    ? `${list.slice(0, showCount).join(", ")}, + ${
        list.length - showCount
      } more`
    : list.join(", ");
};

export const indefiniteArticle = word => {
  if (!word) {
    return "a";
  }

  const vowels = ["a", "e", "i", "o", "u"];

  const lowercaseWord = word.toLowerCase();

  // Specific words preceeded by 'an'
  const specialWords = ["honest", "hour", "xml"];

  if (
    specialWords.includes(lowercaseWord) ||
    vowels.includes(lowercaseWord[0])
  ) {
    return "an";
  }

  return "a";
};
