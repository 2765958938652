import { isEqual } from "lodash";
import { all, put, select, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import { AuctionPenAction, patchSaleLot } from "actions";

import {
  ADD_SALE_LOT_COMMIT,
  ADD_SALE_LOT_OFFLINE,
  AUCTION_PEN,
  DRAFTING_DECISION,
  PEN_ARCHETYPE,
  SALE_LOT,
} from "constants/actionTypes";
import { GateStatus, PenTypes } from "constants/auctionPens";
import { autoDraftPenInitialState } from "constants/singleWeigh";

import { getAuctionPenOrder } from "lib/auctionPens";

import {
  getAuctionPenById,
  getAuctionPens,
  getDeploymentSaleById,
  getDeploymentSaleIdByConsignmentId,
  getEidsByCurrentAuctionPenId,
  getPenArchetypeById,
  getPensBySingleWeighId,
  getSaleLotById,
  getSellingNowByPriceSaleLot,
  getSoldSaleLotsInSoldOrder,
} from "selectors";

function* addAuctionPenAction(action) {
  const { payload, tempId } = action;

  const { saleRoundId } = payload;

  const state = yield select();

  const auctionPens = Object.values(getAuctionPens(state)).filter(
    auctionPen => auctionPen.saleRoundId === +saleRoundId,
  );

  payload.order = getAuctionPenOrder(auctionPens, payload);

  payload.id = tempId;

  yield put(AuctionPenAction.create(payload));
}

function* addAuctionPenFromPenArchetype({
  penArchetypeId,
  tempId,
  extraPayload,
}) {
  const state = yield select();

  const penArchetype = getPenArchetypeById(penArchetypeId)(state);

  const payload = {
    id: tempId,
    penArchetypeId,
    start_pen: penArchetype.penName,
    isLaneStart: penArchetype.isLaneStart,
    order: penArchetype.order,
    capacity: penArchetype.capacity,
    penType: penArchetype.penType,
    ...extraPayload,
  };

  yield put(AuctionPenAction.create(payload));
}

function* onDraftingDecision(action) {
  // When we send a drafting decision we should:
  // - update the destinatino pen id of the selected pen
  // - update the destination pen to have an open gate
  // - update all other pends to have a closed gate.

  const { singleWeighId, draftingDecision } = action;

  const { draftPenId, destinationPenId } = draftingDecision;

  const state = yield select();
  const draftPen = getAuctionPenById(draftPenId)(state);

  // First make sure all other auto draft pens, in this single weigh, that
  // are anything but open, are marked as closed.
  // NB this is based on a few current assumptions, using the Thompson Longhorn
  // Allen Bradley PLC
  // - When a drafting decision is sent, that door is opened, others are closed
  // - We don't have any other current means of deducing door status - this
  // will hopefully be superceded by a status update string at a later date
  // - We are making no assumptions about the crush exit.  Yet.
  const singleWeighPens = getPensBySingleWeighId(singleWeighId)(state);
  const otherAutoDraftPens = singleWeighPens.filter(
    pen =>
      pen.id !== draftPenId &&
      pen.penType === PenTypes.AUTO_DRAFT &&
      (!pen.autoDraftState ||
        pen.autoDraftState.entryGateStatus === GateStatus.OPEN),
  );
  for (const otherPen of otherAutoDraftPens) {
    yield put(
      AuctionPenAction.update({
        id: otherPen.id,
        autoDraftState: {
          entryGateStatus: GateStatus.CLOSED,
        },
      }),
    );
  }

  // If this pen is not currently flagged as open, or the destination pen is not
  // set, they need to be fiddled.
  const isOpen = draftPen?.autoDraftState?.entryGateStatus === GateStatus.OPEN;
  const isAlreadyAssigned =
    draftPen?.autoDraftState?.destinationPenId === destinationPenId;
  if (!isAlreadyAssigned || !isOpen) {
    // TODO - do we LOCK the back gate?
    yield put(
      AuctionPenAction.update({
        id: draftPenId,
        autoDraftState: {
          entryGateStatus: GateStatus.OPEN,
          destinationPenId,
        },
      }),
    );
  }
}

function* onResetAutoDraftingPens(action) {
  const { penIds } = action;

  const state = yield select();

  yield all(
    penIds
      .map(penId => {
        const eids = getEidsByCurrentAuctionPenId(penId)(state);

        return [
          put(AuctionPenAction.moveScans(penId, null, eids)),
          put(
            AuctionPenAction.update({
              id: penId,
              autoDraftState: autoDraftPenInitialState,
            }),
          ),
        ];
      })
      .flat(),
  );
}

function* onSaveSaleLot(action) {
  // When a sale lot is created or patched, check for the delivery pen id and buyer.
  // If both are set, check if this buyer is an owner of the pen, and if not, make it so

  let deliveryPenId;
  let buyerId;
  let buyerWay;
  // Pull data out one way for a create or a patch.
  // This could/should be a divergent saga, but, it's not.
  if (action.type === SALE_LOT.UPDATE.REQUEST) {
    const {
      payload: { patch, saleLot },
    } = action;
    // If the value is not in the patch, pull it out of the saleLot
    deliveryPenId =
      typeof patch.deliveryPenId === "undefined"
        ? saleLot.deliveryPenId
        : patch.deliveryPenId;
    buyerId =
      typeof patch.buyer_id === "undefined" ? saleLot.buyer_id : patch.buyer_id;

    buyerWay =
      typeof patch.buyer_way === "undefined"
        ? saleLot.buyer_way
        : patch.buyer_way;
  } else if (action.type === ADD_SALE_LOT_OFFLINE) {
    const { payload } = action;
    ({
      delivery_pen_id: deliveryPenId,
      buyer_id: buyerId,
      buyer_way: buyerWay,
    } = payload);
  }

  if (deliveryPenId && buyerId) {
    const state = yield select();
    const pen = getAuctionPenById(deliveryPenId)(state);
    // If we don't have the pen yet, the pen doesn't have owners yet, or the buyer/buyerway isn't in the pen.
    if (
      !pen ||
      !pen.penOwners ||
      !pen.penOwners.some(
        owner =>
          owner.businessId === buyerId && isEqual(buyerWay, owner.buyerWay),
      )
    ) {
      yield put(
        AuctionPenAction.penOwner.create(deliveryPenId, {
          businessId: buyerId,
          buyerWay,
        }),
      );
    }
  }
}

function* onBulkSaveSaleLot(action) {
  const { payload } = action;
  // Payload is a list of sale lots - go through and find if delivery pen is being set on any of them, and if
  // so make sure the owner is set.
  const state = yield select();
  const penOwnerUpdates = payload.reduce((acc, saleLotPayload) => {
    const { delivery_pen_id: deliveryPenId, id } = saleLotPayload;
    if (deliveryPenId) {
      if (!acc[deliveryPenId]) {
        acc[deliveryPenId] = [];
      }
      const saleLot = getSaleLotById(id)(state);
      const deliveryPen = getAuctionPenById(deliveryPenId)(state);
      const newPenOwner = {
        businessId: saleLot.buyer_id,
        buyerWay: saleLot.buyer_way
          ? {
              name: saleLot.buyer_way.name,
              id: saleLot.buyer_way.id,
            }
          : null,
      };

      if (
        saleLot.buyer_id &&
        !acc[deliveryPenId].find(penOwner => isEqual(penOwner, newPenOwner)) &&
        !deliveryPen.penOwners.find(penOwner => isEqual(penOwner, newPenOwner))
      ) {
        acc[deliveryPenId].push(newPenOwner);
      }
    }
    return acc;
  }, {});

  for (const [deliveryPenId, owners] of Object.entries(penOwnerUpdates)) {
    for (const penOwner of owners) {
      yield put(AuctionPenAction.penOwner.create(deliveryPenId, penOwner));
    }
  }
}

function* onOrderSellingNow(action) {
  const { auctionPenId } = action;

  const state = yield select();

  // If I am an agent performing this action, optimistically set the order for my world and let the back
  // end deal with the real world.  I.e to set the currently selling pen, I need to know what the last
  // sold lot was, and I may not have visibility of that (as an agent).

  // Using getSellingNowByPriceSaleLot is a bit flimsy, but we are only using the currentlySellingAuctionPenId if we dont have
  // any pens sold (so it will just be the first unsold lot in order)
  const currentlySellingAuctionPenId =
    getSellingNowByPriceSaleLot(state)?.auction_pen_id;

  const lastSoldAuctionPenId =
    getSoldSaleLotsInSoldOrder(state)?.[0]?.auction_pen_id;

  if (lastSoldAuctionPenId) {
    yield put({
      type: AUCTION_PEN.ORDER_AFTER.REQUEST,
      auctionPenId,
      afterPenId: lastSoldAuctionPenId,
    });
  } else if (currentlySellingAuctionPenId) {
    yield put({
      type: AUCTION_PEN.ORDER_BEFORE.REQUEST,
      auctionPenId,
      beforePenId: currentlySellingAuctionPenId,
    });
  }
}

function* onCreateSaleLot(action) {
  const { payload } = action;
  const state = yield select();
  const deploymentSaleId = getDeploymentSaleIdByConsignmentId(
    payload.consignment_id,
  )(state);
  const isLotNumberAsPenNumber =
    getDeploymentSaleById(deploymentSaleId)(
      state,
    )?.use_lot_number_as_pen_number;

  // Different types of consignments/lots may not be referenced or relevant here.
  if (isLotNumberAsPenNumber === undefined) {
    return;
  }

  // If we use using lot numbers as pen numbers, create the auction pen
  // if it isnt already and patch the salelot if necessary.
  if (isLotNumberAsPenNumber) {
    const tempId = uuidv4();

    const auctionPenPayload = {
      id: tempId,
      start_pen: payload.lot_number.toString(),
      saleRoundId: payload.sale_round_id,
    };

    yield put(AuctionPenAction.create(auctionPenPayload));
    yield put(
      patchSaleLot(
        { auction_pen_id: tempId, id: payload.id },
        { disabledToast: true },
      ),
    );
  }
}

export default function* rootSaga() {
  yield takeEvery(AUCTION_PEN.CREATE.ACTION, addAuctionPenAction);
  yield takeEvery(AUCTION_PEN.ORDER_SELLING_NOW.REQUEST, onOrderSellingNow);
  yield takeEvery(PEN_ARCHETYPE.CREATE.ACTION, addAuctionPenFromPenArchetype);
  yield takeEvery(
    [ADD_SALE_LOT_OFFLINE, SALE_LOT.UPDATE.REQUEST],
    onSaveSaleLot,
  );
  yield takeEvery(SALE_LOT.UPDATE_BULK.REQUEST, onBulkSaveSaleLot);

  // Catch on commit because we need the lot_number.
  yield takeEvery(ADD_SALE_LOT_COMMIT, onCreateSaleLot);

  yield takeEvery(DRAFTING_DECISION.ACTION.REQUEST, onDraftingDecision);
  yield takeEvery(
    AUCTION_PEN.BULK_RESET_AUTO_DRAFT.REQUEST,
    onResetAutoDraftingPens,
  );
}
