import { keyBy } from "lodash";
import { createSelector } from "reselect";

import { NVDRelatedDocumentTypes } from "constants/documentTypes";
import { IMAGE_FILE_EXTENSIONS, VIDEO_FILE_EXTENSIONS } from "constants/files";

import { getIsDeclarationComplete } from "lib/nvd";

import {
  createIdByKeySelector,
  createLookupCombiner,
  createLookupSelectors,
  getAgencies,
  getConsignments,
  getDeployments,
  getFiles,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScans,
  reduceXByZId,
  selectAttachmentIdsByConsignmentIdLookup,
  selectCurrentDeploymentSales,
  selectEidsBySaleLotIdLookup,
  selectObjectHasMediaAttachmentLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleyardScanSaleLotIdsByConsignmentIdLookup,
} from "selectors";

/**
 * Returns whether or not a Consignment has an NVD Scan attached, keyed by Consignment Id
 * Requires:
 *  - `Attachments`
 *  - `Consignments`
 * @type {function(state): Object<string, boolean>}
 */
export const selectHasNvdUploadByConsignmentIdLookup = createSelector(
  [getConsignments, selectAttachmentIdsByConsignmentIdLookup, getFiles],
  (consignments, attachmentsByConsignmentId, attachments) =>
    Object.keys(consignments).reduce((acc, consignmentId) => {
      acc[consignmentId] = attachmentsByConsignmentId[consignmentId]?.some(
        attachmentId =>
          attachments[attachmentId].type === NVDRelatedDocumentTypes.NVD,
      );
      return acc;
    }, {}),
);

export const selectConsignmentHasImageLookup =
  selectObjectHasMediaAttachmentLookup(
    getConsignments,
    getFiles,
    selectAttachmentIdsByConsignmentIdLookup,
    IMAGE_FILE_EXTENSIONS,
  );

export const selectConsignmentHasVideoLookup =
  selectObjectHasMediaAttachmentLookup(
    getConsignments,
    getFiles,
    selectAttachmentIdsByConsignmentIdLookup,
    VIDEO_FILE_EXTENSIONS,
  );

/**
 * Returns whether or not a Consignment's NVD has been completed, keyed by Consignment Id
 * Requires:
 *  - `Consignments`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsDeclarationCompleteByConsignmentIdLookup = createSelector(
  [getConsignments],
  consignments =>
    Object.entries(consignments).reduce(
      (acc, [consignmentId, { declaration }]) => {
        acc[consignmentId] = getIsDeclarationComplete(declaration);
        return acc;
      },
      {},
    ),
);

/**
 * Returns a Deployment Sale, keyed by Consignment Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 * @type {function(state): Object<string, object>}
 */
export const selectDeploymentSaleByConsignmentIdLookup = createSelector(
  [
    selectCurrentDeploymentSales,
    createIdByKeySelector(getConsignments, "id", "deployment_sale"),
  ],
  reduceXByZId,
);

/**
 * Returns a Deployment Id, keyed by Consignment Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 * @type {function(state): Object<string, number|null>}
 */
export const selectDeploymentIdByConsignmentIdLookup = createSelector(
  [selectDeploymentSaleByConsignmentIdLookup],
  deploymentSaleByConsignmentIdLookup =>
    Object.entries(deploymentSaleByConsignmentIdLookup).reduce(
      (acc, [consignmentId, deploymentSale]) => {
        acc[consignmentId] = deploymentSale?.deployment_id || null;
        return acc;
      },
      {},
    ),
);
/**
 * Returns an Agency Id, keyed by Consignment Id
 * Requires:
 *  - `Consignments`
 *  - `Deployments
 *  - `Sales`
 * @type {function(state): Object<string, number|null>}
 */
export const selectAgencyIdByConsignmentIdLookup = createSelector(
  [getDeployments, selectDeploymentIdByConsignmentIdLookup],
  (deployments, deploymentIdByConsignmentIdLookup) =>
    Object.entries(deploymentIdByConsignmentIdLookup).reduce(
      (acc, [consignmentId, deploymentId]) => {
        const deployment = deployments[deploymentId];
        acc[consignmentId] = deployment?.livestockAgencyId;
        return acc;
      },
      {},
    ),
);

export const getAgencyIdByConsignmentId = consignmentId => state =>
  selectAgencyIdByConsignmentIdLookup(state)[consignmentId] || null;

export const selectAgencyByConsignmentIdLookup = createSelector(
  [getAgencies, selectAgencyIdByConsignmentIdLookup],
  reduceXByZId,
);

export const getAgencyByConsignmentIdLookup = consignmentId => state =>
  selectAgencyByConsignmentIdLookup(state)[consignmentId] || null;

export const selectAgencyIdBySaleLotId = createSelector(
  [selectAgencyIdByConsignmentIdLookup, getSaleLots],
  (agencyIdByConsignmentIdLookup, saleLots) =>
    Object.entries(saleLots).reduce((lookup, [saleLotId, saleLot]) => {
      lookup[saleLotId] =
        agencyIdByConsignmentIdLookup[saleLot.consignment_id] || null;
      return lookup;
    }, {}),
);

export const selectAgencyBySaleLotIdLookup = createSelector(
  [getAgencies, selectAgencyIdBySaleLotId],
  reduceXByZId,
);

export const getAgencyBySaleLotIdLookup = saleLotId => state =>
  selectAgencyBySaleLotIdLookup(state)[saleLotId] || null;

export const [
  selectSaleyardScanEidsByConsignmentIdLookup,
  getSaleyardScanEidsByConsignmentId,
] = createLookupSelectors(
  [
    selectSaleyardScanSaleLotIdsByConsignmentIdLookup,
    selectEidsBySaleLotIdLookup,
  ],
  createLookupCombiner((saleLotIds, scansBySaleLotIdLookup) =>
    saleLotIds.reduce(
      (acc, saleLotId) => acc.concat(scansBySaleLotIdLookup[saleLotId]),
      [],
    ),
  ),
);

export const [selectEidsByConsignmentIdLookup, getEidsByConsignmentId] =
  createLookupSelectors(
    [selectSaleLotIdsByConsignmentIdLookup, selectEidsBySaleLotIdLookup],
    createLookupCombiner((saleLotIds, scansBySaleLotIdLookup) =>
      saleLotIds.reduce(
        (acc, saleLotId) => acc.concat(scansBySaleLotIdLookup[saleLotId]),
        [],
      ),
    ),
  );
export const selectConsignmentIdByEidLookup = createIdByKeySelector(
  createSelector(
    [getScans, getSaleLots, getSaleyardScanSaleLots],
    (scans, saleLots, saleyardScanSaleLots) =>
      Object.entries(scans).reduce((acc, [eid, scan]) => {
        const auctionSaleLot = saleLots[scan.sale_lot_id];
        const saleyardScanSaleLot = saleyardScanSaleLots[scan.sale_lot_id];
        const consignmentId =
          (auctionSaleLot && auctionSaleLot.consignment_id) ||
          (saleyardScanSaleLot && saleyardScanSaleLot.consignmentId);
        acc[eid] = { eid, consignmentId };
        return acc;
      }, {}),
  ),
  "eid",
  "consignmentId",
);

export const selectConsignmentIdBySaleLotIdLookup = createIdByKeySelector(
  createSelector(
    [getSaleLots, getSaleyardScanSaleLots],
    (saleLots, saleyardScanSaleLots) =>
      keyBy(
        []
          .concat(Object.values(saleLots), Object.values(saleyardScanSaleLots))
          .map(saleLot => ({
            id: saleLot.id,
            consignmentId: saleLot.consignment_id || saleLot.consignmentId,
          })),
        saleLot => saleLot.id,
      ),
  ),
  "id",
  "consignmentId",
);

export const getConsignmentIdBySaleLotId = saleLotId => state => {
  return selectConsignmentIdBySaleLotIdLookup(state)[saleLotId];
};
const selectVendorPropertyIdByConsignmentIdLookup = createIdByKeySelector(
  getConsignments,
  "id",
  "vendor_property_id",
);

export const [
  selectVendorPropertyIdBySaleLotIdLookup,
  getVendorPropertyIdBySaleLotId,
] = createLookupSelectors(
  [
    selectVendorPropertyIdByConsignmentIdLookup,
    selectConsignmentIdBySaleLotIdLookup,
  ],
  reduceXByZId,
);
