import { uniq } from "lodash";

const commonPluralize = word => `${word}s`;

const consonantYPluralize = word => `${word.slice(0, -1)}ies`;

const consonantSPluralize = word =>
  word === "is" ? "are" : word === "Is" ? "Are" : `${word}es`;

const yPluralize = word => {
  const secondLastChar = word.slice(-2, -1);
  if (["a", "e", "i", "o", "u"].includes(secondLastChar)) {
    return commonPluralize(word);
  }

  return consonantYPluralize(word);
};

export const pluralize = (word, count) => {
  if (count === 1) {
    return word;
  }
  if (word.endsWith("y")) {
    return yPluralize(word);
  }
  if (word.endsWith("s")) {
    return consonantSPluralize(word);
  }
  return commonPluralize(word);
};

export const uniqOrCount = (words, noun) => {
  const uniqWords = uniq(words);
  if (uniqWords.length === 1) {
    return uniqWords[0];
  } else {
    return `${uniqWords.length} ${pluralize(noun, uniqWords.length)}`;
  }
};
