import React from "react";

import * as PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Row } from "components/Layout";

const Container = styled.div`
  flex: 1;
  transition: background 200ms ease;
  ${({ isDisabled, isSelected, theme }) => `
  background-color: ${isSelected ? "rgba(0, 133, 255, 0.1)" : "#fff"};
  ${isDisabled ? "opacity: 0.33;" : ""}
  min-height: ${theme.fontSizes.theta}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:focus-within {
    background-color: rgba(0, 133, 255, 0.1);
  }
`}
`;

const Description = styled.div`
  color: #777777;
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
  font-weight: 300;
  line-height: 48px;
  padding-left: 24px;
`;

const Wrapper = styled(Row)`
  align-items: baseline;
  color: #4a4a4a;
  font-size: 36px;
  line-height: 60px;
  font-weight: bold;
  padding-right: 36px;
`;

const SmallNumber = styled.div`
  font-size: 28px;
`;

export const FatFingerButton = ({
  isSelected,
  onClick,
  onFocus,
  onBlur,
  isDisabled,
  text,
  smallText,
  description,
}) => (
  <Container
    isSelected={isSelected}
    isDisabled={isDisabled}
    onClick={e => !isDisabled && typeof onClick === "function" && onClick(e)}
    onFocus={e => !isDisabled && typeof onFocus === "function" && onFocus(e)}
    onBlur={e => !isDisabled && typeof onBlur === "function" && onBlur(e)}
    tabIndex={isDisabled ? -1 : 0}
    data-tour={description}
  >
    <Description>{description}</Description>
    <Wrapper>
      <div>{text}</div>
      {smallText && <SmallNumber>{smallText}</SmallNumber>}
    </Wrapper>
  </Container>
);
FatFingerButton.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isDisabled: PropTypes.bool,
  text: PropTypes.node,
  smallText: PropTypes.node,
  description: PropTypes.node,
};
