import React from "react";

import isArray from "lodash/isArray";
import { useSelector } from "react-redux";

import {
  ConsignableSaleTypes,
  LivestockAuctionAndClearanceSaleTypes,
  LivestockAuctionAndPaddockSaleTypes,
  LivestockAuctionSaleTypes,
  LivestockSaleTypes,
  SaleTypes,
  SaleyardSaleTypes,
  TransactionalSaleTypes,
} from "constants/sale";

import { currentSaleSelector } from "selectors";

export const ForSaleType = ({
  selectedSaleType = null,
  saleType,
  exceptSaleType,
  children,
}) => {
  // If selectedSaleType is provided, use that instead of
  // getting the current sale from redux.

  let sale_type = useSelector(state => currentSaleSelector(state))?.sale_type;
  if (selectedSaleType) {
    sale_type = selectedSaleType;
  } else if (!sale_type) {
    return null;
  }

  let visible = false;
  if (isArray(saleType)) {
    visible = saleType.includes(sale_type);
  } else if (typeof saleType === "string") {
    visible = saleType === sale_type;
  } else if (isArray(exceptSaleType)) {
    visible = !exceptSaleType.includes(sale_type);
  } else if (typeof exceptSaleType === "string") {
    visible = exceptSaleType !== sale_type;
  }
  return (visible && children) || null;
};

export const ForSaleyardAuction = ({ selectedSaleType = null, children }) => (
  <ForSaleType selectedSaleType={selectedSaleType} saleType={SaleyardSaleTypes}>
    {children}
  </ForSaleType>
);

export const ForLivestockAuctionAndPaddock = ({
  selectedSaleType = null,
  children,
}) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    saleType={LivestockAuctionAndPaddockSaleTypes}
  >
    {children}
  </ForSaleType>
);

export const ForLivestockAuction = ({
  selectedSaleType = null,
  children,
  exceptSaleType,
}) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    exceptSaleType={exceptSaleType}
    saleType={LivestockAuctionSaleTypes}
  >
    {children}
  </ForSaleType>
);

export const ForLivestockAuctionAndClearingSale = ({
  selectedSaleType = null,
  children,
  exceptSaleType,
}) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    exceptSaleType={exceptSaleType}
    saleType={LivestockAuctionAndClearanceSaleTypes}
  >
    {children}
  </ForSaleType>
);

export const ForConsignableSales = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    saleType={ConsignableSaleTypes}
  >
    {children}
  </ForSaleType>
);

export const ForLivestockSale = ({ children }) => (
  <ForSaleType saleType={LivestockSaleTypes}>{children}</ForSaleType>
);

export const ForHooks = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    saleType={[SaleTypes.OVER_HOOKS]}
  >
    {children}
  </ForSaleType>
);

export const ForPaddock = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    saleType={[SaleTypes.PADDOCK]}
  >
    {children}
  </ForSaleType>
);

export const ForHooksOrPaddock = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    saleType={TransactionalSaleTypes}
  >
    {children}
  </ForSaleType>
);

export const ForNotHooksOrPaddock = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    exceptSaleType={TransactionalSaleTypes}
  >
    {children}
  </ForSaleType>
);

export const ForHooksOrPaddockOrExternalAgentSales = ({
  selectedSaleType = null,
  children,
}) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    saleType={[SaleTypes.EXTERNAL_AGENT_SALE, ...TransactionalSaleTypes]}
  >
    {children}
  </ForSaleType>
);

export const ForNotHooksOrPaddockOrExternalAgentSales = ({
  selectedSaleType = null,
  children,
}) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    exceptSaleType={[SaleTypes.EXTERNAL_AGENT_SALE, ...TransactionalSaleTypes]}
  >
    {children}
  </ForSaleType>
);

export const ForNotHooks = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    exceptSaleType={SaleTypes.OVER_HOOKS}
  >
    {children}
  </ForSaleType>
);

export const ForClearingSale = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    saleType={SaleTypes.CLEARING}
  >
    {children}
  </ForSaleType>
);

export const ForNotClearingSale = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    exceptSaleType={SaleTypes.CLEARING}
  >
    {children}
  </ForSaleType>
);

export const ForNotPrivateWeigh = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    exceptSaleType={SaleTypes.PRIVATE_WEIGH}
  >
    {children}
  </ForSaleType>
);

export const ForExternalAgentSale = ({ selectedSaleType = null, children }) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    saleType={SaleTypes.EXTERNAL_AGENT_SALE}
  >
    {children}
  </ForSaleType>
);

export const ForNotExternalAgentSale = ({
  selectedSaleType = null,
  children,
}) => (
  <ForSaleType
    selectedSaleType={selectedSaleType}
    exceptSaleType={SaleTypes.EXTERNAL_AGENT_SALE}
  >
    {children}
  </ForSaleType>
);

export default ForSaleType;
