import { createStructuredSelector } from "reselect";

import { ApiModel } from "constants/loading";

import {
  getConsignableSalesIsFetching,
  getIsFetchingAgencies,
  getIsFetchingAuctionPens,
  getIsFetchingBidders,
  getIsFetchingBillingData,
  getIsFetchingBillingDocuments,
  getIsFetchingBillingRuns,
  getIsFetchingBusinesses,
  getIsFetchingBusinessUserRoles,
  getIsFetchingComments,
  getIsFetchingConsignments,
  getIsFetchingDeployments,
  getIsFetchingDraftingInformation,
  getIsFetchingEContracts,
  getIsFetchingEmail,
  getIsFetchingENVDDocuments,
  getIsFetchingFiles,
  getIsFetchingIntegrationBusinesses,
  getIsFetchingIntegrationCredentials,
  getIsFetchingLedgerAccounts,
  getIsFetchingLedgerEntries,
  getIsFetchingLivestockAgentRoles,
  getIsFetchingLivestockAgents,
  getIsFetchingManualAdjustments,
  getIsFetchingMasterLedgerAccounts,
  getIsFetchingMasterRuleBooks,
  getIsFetchingMasterRules,
  getIsFetchingNominations,
  getIsFetchingNVDUploads,
  getIsFetchingPayments,
  getIsFetchingPenArchetypes,
  getIsFetchingPermissionGroups,
  getIsFetchingProperties,
  getIsFetchingReceivalLots,
  getIsFetchingReportJobs,
  getIsFetchingReports,
  getIsFetchingRounds,
  getIsFetchingRuleBooks,
  getIsFetchingRules,
  getIsFetchingSaleDefinitions,
  getIsFetchingSaleLots,
  getIsFetchingSales,
  getIsFetchingSaleVendorSplits,
  getIsFetchingSaleWatcherRoles,
  getIsFetchingSaleyardAdminRoles,
  getIsFetchingSaleyardAdmins,
  getIsFetchingSaleyards,
  getIsFetchingScaleOperatorRoles,
  getIsFetchingScans,
  getIsFetchingSellFiles,
  getIsFetchingSellReversals,
  getIsFetchingSightingFiles,
  getIsFetchingSingleWeighs,
  getIsFetchingSpecies,
  getIsFetchingTakeFiles,
  getIsFetchingTakeReversals,
  getIsFetchingTradingTerms,
} from "selectors";

export const isLoadingSaleReports = state =>
  getIsFetchingBusinesses(state) ||
  getIsFetchingSaleLots(state) ||
  getIsFetchingConsignments(state) ||
  getIsFetchingReports(state);

// If we're fetching any of these, we can't reliably say what state our nlis files are in.
export const getIsFetchingNLISInformation = state =>
  getIsFetchingTakeFiles(state) ||
  getIsFetchingTakeReversals(state) ||
  getIsFetchingSellFiles(state) ||
  getIsFetchingSellReversals(state) ||
  getIsFetchingConsignments(state) ||
  getIsFetchingSaleLots(state) ||
  getIsFetchingSightingFiles(state);

export const getIsFetchingRoles = state =>
  getIsFetchingSaleyardAdminRoles(state) ||
  getIsFetchingLivestockAgentRoles(state) ||
  getIsFetchingSaleWatcherRoles(state) ||
  getIsFetchingScaleOperatorRoles(state) ||
  getIsFetchingBusinessUserRoles(state) ||
  false;

export const selectItemsFetching = createStructuredSelector({
  [ApiModel.AGENCIES]: getIsFetchingAgencies,
  [ApiModel.AUCTION_PENS]: getIsFetchingAuctionPens,
  [ApiModel.BIDDERS]: getIsFetchingBidders,
  [ApiModel.BILLING_DATA]: getIsFetchingBillingData,
  [ApiModel.BILLING_DOCUMENTS]: getIsFetchingBillingDocuments,
  [ApiModel.BILLING_RUNS]: getIsFetchingBillingRuns,
  [ApiModel.BUSINESS_USER_ROLES]: getIsFetchingBusinessUserRoles,
  [ApiModel.BUSINESSES]: getIsFetchingBusinesses,
  [ApiModel.COMMENTS]: getIsFetchingComments,
  [ApiModel.CONSIGNABLE_SALES]: getConsignableSalesIsFetching,
  [ApiModel.CONSIGNMENTS]: getIsFetchingConsignments,
  [ApiModel.DEPLOYMENTS]: getIsFetchingDeployments,
  [ApiModel.DRAFTING_INFORMATION]: getIsFetchingDraftingInformation,
  [ApiModel.ECONTRACTS]: getIsFetchingEContracts,
  [ApiModel.EMAIL]: getIsFetchingEmail,
  [ApiModel.ENVD_DOCUMENTS]: getIsFetchingENVDDocuments,
  [ApiModel.FILES]: getIsFetchingFiles,
  [ApiModel.INTEGRATION_BUSINESSES]: getIsFetchingIntegrationBusinesses,
  [ApiModel.INTEGRATION_CREDENTIALS]: getIsFetchingIntegrationCredentials,
  [ApiModel.LEDGER_ACCOUNTS]: getIsFetchingLedgerAccounts,
  [ApiModel.LEDGER_ENTRIES]: getIsFetchingLedgerEntries,
  [ApiModel.LIVESTOCK_AGENT_ROLES]: getIsFetchingLivestockAgentRoles,
  [ApiModel.LIVESTOCK_AGENTS]: getIsFetchingLivestockAgents,
  [ApiModel.MANUAL_ADJUSTMENTS]: getIsFetchingManualAdjustments,
  [ApiModel.MASTER_LEDGER_ACCOUNTS]: getIsFetchingMasterLedgerAccounts,
  [ApiModel.MASTER_RULE_BOOKS]: getIsFetchingMasterRuleBooks,
  [ApiModel.MASTER_RULES]: getIsFetchingMasterRules,
  [ApiModel.NLIS_SELL_FILES]: getIsFetchingSellFiles,
  [ApiModel.NLIS_SELL_REVERSALS]: getIsFetchingSellReversals,
  [ApiModel.NLIS_TAKE_FILES]: getIsFetchingTakeFiles,
  [ApiModel.NLIS_TAKE_REVERSALS]: getIsFetchingTakeReversals,
  [ApiModel.NOMINATIONS]: getIsFetchingNominations,
  [ApiModel.NVD_UPLOADS]: getIsFetchingNVDUploads,
  [ApiModel.PAYMENTS]: getIsFetchingPayments,
  [ApiModel.PEN_ARCHETYPES]: getIsFetchingPenArchetypes,
  [ApiModel.PERMISSION_GROUPS]: getIsFetchingPermissionGroups,
  [ApiModel.PROPERTIES]: getIsFetchingProperties,
  [ApiModel.RECEIVAL_LOTS]: getIsFetchingReceivalLots,
  [ApiModel.REPORT_JOBS]: getIsFetchingReportJobs,
  [ApiModel.ROLES]: getIsFetchingRoles,
  [ApiModel.ROUNDS]: getIsFetchingRounds,
  [ApiModel.RULE_BOOKS]: getIsFetchingRuleBooks,
  [ApiModel.RULES]: getIsFetchingRules,
  [ApiModel.SALE_DEFINITIONS]: getIsFetchingSaleDefinitions,
  [ApiModel.SALE_LOTS]: getIsFetchingSaleLots,
  [ApiModel.SALE_VENDOR_SPLITS]: getIsFetchingSaleVendorSplits,
  [ApiModel.SALE_WATCHER_ROLES]: getIsFetchingSaleWatcherRoles,
  [ApiModel.SALES]: getIsFetchingSales,
  [ApiModel.SALEYARD_ADMIN_ROLES]: getIsFetchingSaleyardAdminRoles,
  [ApiModel.SALEYARD_ADMINS]: getIsFetchingSaleyardAdmins,
  [ApiModel.SALEYARDS]: getIsFetchingSaleyards,
  [ApiModel.SCALE_OPERATOR_ROLES]: getIsFetchingScaleOperatorRoles,
  [ApiModel.SCANS]: getIsFetchingScans,
  [ApiModel.SINGLE_WEIGH]: getIsFetchingSingleWeighs,
  [ApiModel.SPECIES]: getIsFetchingSpecies,
  [ApiModel.TRADING_TERMS]: getIsFetchingTradingTerms,
});
