import { uniq } from "lodash";
import { createSelector } from "reselect";

import { UNALLOCATED } from "constants/scanner";
import {
  UnIgnorableStatuses,
  UnKeepableStatuses,
  WeighLotScanStatus,
} from "constants/weighScanning";

import { calculateWeighLotScanStatus } from "lib/weighLotScan";

import {
  createLookupCombiner,
  createLookupSelectors,
  getScans,
  getWeighLotScans,
  getWeighLots,
  selectScanByWeighLotScanIdLookup,
  selectWeighLotIdsByEidLookup,
} from "selectors";

export const getWeighLotById = weighLotId => state =>
  getWeighLots(state)[weighLotId] || null;

const weighLotScansByWeighLotIdReducer = (weighLot, weighLotScanByIdLookup) =>
  Object.values(weighLotScanByIdLookup).filter(
    weighLotScan => weighLotScan.weighLotId === weighLot.id,
  );

export const [selectWeighLotScansByWeighLotId, getWeighLotScansByWeighLotId] =
  createLookupSelectors(
    [getWeighLots, getWeighLotScans],
    createLookupCombiner(weighLotScansByWeighLotIdReducer),
  );

export const selectWeighLotScansByWeighLotIdLookup = createSelector(
  [getWeighLots, selectWeighLotScansByWeighLotId],
  createLookupCombiner(
    (weighLot, weighLotScansByWeighLotIdLookup) =>
      weighLotScansByWeighLotIdLookup[weighLot.id] || [],
  ),
);

const uniqueWeighLotScansStatusesByWeighLotIdReducer = (
  weighLot,
  weighLotScansByWeighLotIdLookup,
  weighLotIdsByEidlookup,
  scans,
) =>
  uniq(
    weighLotScansByWeighLotIdLookup[weighLot.id]?.map(weighLotScan =>
      calculateWeighLotScanStatus(weighLotScan, scans, weighLotIdsByEidlookup),
    ),
  );

export const [
  selectUniqueWeighLotScansStatusesByWeighLotId,
  getUniqueWeighLotScansStatusesByWeighLotId,
] = createLookupSelectors(
  [
    getWeighLots,
    selectWeighLotScansByWeighLotId,
    selectWeighLotIdsByEidLookup,
    getScans,
  ],
  createLookupCombiner(uniqueWeighLotScansStatusesByWeighLotIdReducer),
);

const receivalLotIdsByWeighLotIdReducer = (
  weighLot,
  weighLotScansByWeighLotIdLookup,
  scanByWeighLotScanIdLookup,
) => {
  const weighLotScansScans = weighLotScansByWeighLotIdLookup[weighLot.id] || [];
  return uniq(
    weighLotScansScans
      .map(
        weighLotScan =>
          scanByWeighLotScanIdLookup[weighLotScan.id]?.receival_lot_id,
      )
      .filter(Boolean),
  );
};

export const [
  selectReceivalLotIdsByWeighLotIdlookup,
  getReceivalLotIdsByWeighLotId,
] = createLookupSelectors(
  [
    getWeighLots,
    selectWeighLotScansByWeighLotIdLookup,
    selectScanByWeighLotScanIdLookup,
  ],
  createLookupCombiner(receivalLotIdsByWeighLotIdReducer),
);

const penScanLotIdsByWeighLotIdReducer = (
  weighLot,
  weighLotScansByWeighLotIdLookup,
  scanByWeighLotScanIdLookup,
) => {
  const weighLotScansScans = weighLotScansByWeighLotIdLookup[weighLot.id] || [];
  return uniq(
    weighLotScansScans
      .map(
        weighLotScan =>
          scanByWeighLotScanIdLookup[weighLotScan.id]?.pen_scan_lot_id,
      )
      .filter(Boolean),
  );
};

export const [
  selectPenScanLotIdsByWeighLotIdlookup,
  getPenScanLotIdsByWeighLotId,
] = createLookupSelectors(
  [
    getWeighLots,
    selectWeighLotScansByWeighLotIdLookup,
    selectScanByWeighLotScanIdLookup,
  ],
  createLookupCombiner(penScanLotIdsByWeighLotIdReducer),
);

const saleLotIdsByWeighLotIdReducer = (
  weighLot,
  weighLotScansByWeighLotIdLookup,
  scanByWeighLotScanIdLookup,
) => {
  const weighLotScansScans = weighLotScansByWeighLotIdLookup[weighLot.id] || [];
  return uniq(
    weighLotScansScans
      .map(weighLotScan =>
        scanByWeighLotScanIdLookup[weighLotScan.id]?.sale_lot_id !== UNALLOCATED
          ? scanByWeighLotScanIdLookup[weighLotScan.id]?.sale_lot_id
          : null,
      )
      .filter(Boolean),
  );
};

export const [selectSaleLotIdsByWeighLotIdLookup, getSaleLotIdsByWeighLotId] =
  createLookupSelectors(
    [
      getWeighLots,
      selectWeighLotScansByWeighLotIdLookup,
      selectScanByWeighLotScanIdLookup,
    ],
    createLookupCombiner(saleLotIdsByWeighLotIdReducer),
  );

const keepableWeighLotScansByWeighLotIdReducer = (
  weighLot,
  weighLotScansByWeighLotId,
  scans,
) => {
  return weighLotScansByWeighLotId[weighLot.id].filter(
    weighLotScan =>
      weighLotScan.status !== WeighLotScanStatus.DEDUPLICATED &&
      !UnKeepableStatuses.includes(weighLotScan.status) &&
      !!scans[weighLotScan.eid],
  );
};

export const [
  selectKeepableWeighLotScansByWeighLotIdLookup,
  getKeepableWeighLotScansByWeighLotId,
] = createLookupSelectors(
  [getWeighLots, selectWeighLotScansByWeighLotId, getScans],
  createLookupCombiner(keepableWeighLotScansByWeighLotIdReducer),
);

const ignorableWeighLotScansByWeighLotIdReducer = (
  weighLot,
  weighLotScansByWeighLotId,
) => {
  return weighLotScansByWeighLotId[weighLot.id].filter(
    weighLotScan =>
      weighLotScan.status !== WeighLotScanStatus.DEDUPLICATED &&
      !UnIgnorableStatuses.includes(weighLotScan.status),
  );
};

export const [
  selectIgnorableWeighLotScansByWeighLotIdLookup,
  getIgnorableWeighLotScansByWeighLotId,
] = createLookupSelectors(
  [getWeighLots, selectWeighLotScansByWeighLotId],
  createLookupCombiner(ignorableWeighLotScansByWeighLotIdReducer),
);
