import { sortBy } from "lodash";
import { createSelector } from "reselect";

import { getCurrentSaleSubType, getWeightRanges } from "selectors";

export const selectWeightRangesByRound = createSelector(
  [getWeightRanges, getCurrentSaleSubType],
  (weightRanges, subType) =>
    sortBy(
      Object.values(weightRanges).filter(
        range => range.saleSubType === subType.id,
      ),
      "order",
    ).reduce((lookup, range) => {
      if (!lookup[range.saleRoundId]) {
        lookup[range.saleRoundId] = [];
      }
      lookup[range.saleRoundId].push(range);
      return lookup;
    }, {}),
);

export const getWeightRangeBySaleRoundId = roundId => state =>
  selectWeightRangesByRound(state)[roundId] || [];
