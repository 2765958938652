import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const emailSerializeMap = {
  id: "id",
  created: "created",
  dispatched: "dispatched",
  formattedRecipient: "formatted_recipient",
  formattedSender: "formatted_sender",
  recipientEmail: "recipient_email",
  livestockSaleId: "livestock_sale_id",
  reason: "reason",
  status: "status",
  subject: "subject",
  rejectReason: "reject_reason",
  errorReason: "error_reason",

  queuedTime: "queued_time",
  sentTime: "sent_time",
  rejectedTime: "rejected_time",
  failedTime: "failed_time",
  bouncedTime: "bounced_time",
  deferredTime: "deferred_time",
  deliveredTime: "delivered_time",
  autorespondedTime: "autoresponded_time",
  openedTime: "opened_time",
  clickedTime: "clicked_time",
  complainedTime: "complained_time",
  unsubscribedTime: "unsubscribed_time",
  errorTime: "error_time",
};

export const serializeEmail = buildSerializer(emailSerializeMap, "email");

export const deserializeEmail = buildDeserializer(emailSerializeMap, "email");
