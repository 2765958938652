import rollbackFailureSaga from "sagas/rollbackFailure";

import agenciesSaga from "./agencies";
import alertsSaga from "./alerts";
import attachmentsSaga from "./attachments";
import auctionPenSaga from "./auctionPens";
import auditLogSaga from "./auditLog";
import authSaga from "./auth";
import bidderRegistrationsSaga from "./bidderRegistrations";
import billingDocumentsSaga from "./billingDocuments";
import billingRunsSaga from "./billingRuns";
import businessesSaga from "./businesses";
import changesSince from "./changesSince";
import consignmentSaga from "./consignments";
import livestockSaleDataSaga from "./dashboard";
import deploymentAttributesSaga from "./deploymentAttributes";
import deploymentSaga from "./deployments";
import tlabAutoDraftPlcSaga from "./deviceDrivers/tlabAutoDraftPlc";
import deviceMessageSaga from "./devices";
import eContractSaga from "./eContracts";
import emailSaga from "./emails";
import fileSaga from "./files";
import hubSaga from "./hub";
import importSaga from "./import";
import integrationBusinessesSaga from "./integrationBusinesses";
import integrationCredentialsSaga from "./integrationCredentials";
import ledgerEntriesSaga from "./ledgerEntries";
import modalSagas from "./modals";
import nlisSaga from "./nlisFiles";
import nominationSaga from "./nominations";
import nvdSaga from "./nvd";
import nvdUploadSaga from "./nvdUploads";
import offlineSaga from "./offline";
import penningSaga from "./penning";
import penScanLotsSaga from "./penScanLots";
import printersSaga from "./printers";
import productsSaga from "./products";
import propertiesSaga from "./properties";
import receivalLotsSaga from "./receivalLots";
import reportJobsSaga from "./reportJobs";
import reportsSaga from "./reports";
import ruleBooksSaga from "./ruleBooks";
import ruleTesterSaga from "./ruleTester";
import saleLotsSaga from "./saleLots";
import salesSaga from "./sales";
import savedViewSaga from "./savedViews";
import scansSaga from "./scans";
import singleWeighSaga from "./singleWeighs";
import socketSaga from "./socket";
import speciesSaga from "./species";
import systemSaga from "./system";
import weighLotSaga from "./weighLots";
import weighLotScanSaga from "./weighLotScans";

const allSagas = [
  agenciesSaga,
  alertsSaga,
  attachmentsSaga,
  auditLogSaga,
  auctionPenSaga,
  authSaga,
  bidderRegistrationsSaga,
  billingDocumentsSaga,
  billingRunsSaga,
  businessesSaga,
  changesSince,
  consignmentSaga,
  livestockSaleDataSaga,
  deploymentAttributesSaga,
  deploymentSaga,
  deviceMessageSaga,
  eContractSaga,
  emailSaga,
  fileSaga,
  integrationBusinessesSaga,
  integrationCredentialsSaga,
  hubSaga,
  importSaga,
  ledgerEntriesSaga,
  modalSagas,
  nlisSaga,
  nominationSaga,
  nvdSaga,
  nvdUploadSaga,
  offlineSaga,
  penningSaga,
  penScanLotsSaga,
  printersSaga,
  productsSaga,
  propertiesSaga,
  receivalLotsSaga,
  reportJobsSaga,
  reportsSaga,
  rollbackFailureSaga,
  ruleBooksSaga,
  ruleTesterSaga,
  saleLotsSaga,
  salesSaga,
  savedViewSaga,
  scansSaga,
  socketSaga,
  speciesSaga,
  systemSaga,
  singleWeighSaga,
  tlabAutoDraftPlcSaga,
  weighLotSaga,
  weighLotScanSaga,
];

export default allSagas;
