import React from "react";

import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useField, useFormikContext } from "formik";
import styled from "styled-components/macro";

import { Tooltip } from "./Tooltip";

export const StyledCheckbox = styled(MuiCheckbox)(
  ({ theme, disabled }) => `
  && {
    color: ${disabled ? theme.colors.disabled : theme.colors.primary};
    &.Mui-checked {
      color: ${theme.colors.primary};
    }
  }
`,
);

export const CheckBoxBase = ({
  name,
  label,
  dataTour,
  tooltip,
  labelPlacement = "end",
  disabled,
  onChange,
  value,
}) => {
  const control = (
    <StyledCheckbox
      onChange={onChange}
      checked={value}
      data-tour={dataTour || name}
      disabled={disabled}
    />
  );
  return (
    <>
      <FormControlLabel
        control={control}
        label={label}
        labelPlacement={labelPlacement}
      />
      {tooltip && <Tooltip title={tooltip} />}
    </>
  );
};

export const CheckBox = ({
  name,
  dataTour = undefined,
  label,
  tooltip = undefined,
  labelPlacement = "end",
  disabled = false,
  trueValue = true,
  falseValue = false,
  mutuallyExclusiveWithName = "",
  mutuallyExclusiveWithFalseValue = false,
  onChangeExtra = null,
}) => {
  const [field] = useField(name);

  const { setFieldTouched, setFieldValue } = useFormikContext();
  function onChange() {
    const value = field.value === trueValue ? falseValue : trueValue;
    setFieldValue(name, value);
    setFieldTouched(name);

    if (value && mutuallyExclusiveWithName) {
      setFieldValue(mutuallyExclusiveWithName, mutuallyExclusiveWithFalseValue);
      setFieldTouched(mutuallyExclusiveWithName);
    }

    if (typeof onChangeExtra === "function") {
      onChangeExtra(value);
    }
  }

  // Default to unchecked if we dont have a value, this will keep the component
  // in a controlled state.
  const { value = false } = field;

  return (
    <CheckBoxBase
      name={name}
      label={label}
      tooltip={tooltip}
      labelPlacement={labelPlacement}
      disabled={disabled}
      value={value === trueValue}
      onChange={onChange}
      dataTour={dataTour}
    />
  );
};

function defaultGetIsChecked(value, values) {
  return values.includes(value);
}

export const FieldArrayCheckBox = ({
  name,
  label,
  getIsChecked = defaultGetIsChecked,
  key,
  array,
  value,
  labelPlacement,
  arrayHelpers,
}) => {
  const onChange = e => {
    if (e.target.checked) {
      arrayHelpers.push(value);
    } else {
      arrayHelpers.remove(array.indexOf(value));
    }
  };

  const control = (
    <StyledCheckbox
      name={name}
      key={value || key}
      checked={getIsChecked(value, array)}
      onChange={onChange}
    />
  );
  return (
    <FormControlLabel
      control={control}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};
