import React from "react";

import styled from "styled-components/macro";

import StatusCard from "components/Card/StatusCard";
import { PlainCollapse } from "components/Collapse";
import { OpenIndicator } from "components/Icons/CollapseIcon";
import { Row, Column } from "components/Layout";

export const RightActionColumn = styled(Column)`
  min-width: 48px;
`;

export const GroupedCard = ({
  status,
  groupSummary,
  actions,
  groupedItems,
  onClick,
  isOpen,
  topRow = null,
  startPen = null,
}) => {
  return (
    <StatusCard data-tour={`pen:${startPen}`} status={status} onClick={onClick}>
      {topRow}
      <Row full>
        <Column full>{groupSummary}</Column>

        <RightActionColumn>
          {actions}
          {typeof onClick === "function" ? (
            <OpenIndicator isOpen={isOpen} />
          ) : null}
        </RightActionColumn>
      </Row>

      <PlainCollapse isOpen={isOpen}>{groupedItems}</PlainCollapse>
    </StatusCard>
  );
};
