//  Status of the email (at least, for the primary recipient) as per
// https://anymail.readthedocs.io/en/stable/sending/anymail_additions/#esp-send-status # noqa
// PLUS our own error string, background_queued, and async_queued
// Mirror of server side constants.py:EmailStatuses

export enum EmailStatus {
  AsyncQueued = "async_queued",
  AutoResponded = "autoresponded",
  BackgroundQueued = "background_queued",
  Bounced = "bounced",
  Clicked = "clicked",
  Complained = "complained",
  Deferred = "deferred",
  Delivered = "delivered",
  Error = "error",
  Failed = "failed",
  Opened = "opened",
  Queued = "queued",
  Rejected = "rejected",
  Sent = "sent",
  Unsubscribed = "unsubscribed",
}
