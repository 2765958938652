import React from "react";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { Column } from "components/Layout";

import { Label } from "./Label";

export const ToggleButtons = ({
  name,
  label,
  options,
  tooltip,
  value,
  onChange,
  disabled,
}) => {
  return (
    <Column fullWidth>
      <Label htmlFor={name} tooltip={tooltip}>
        {label}
      </Label>

      <ToggleButtonGroup
        value={value}
        onChange={onChange}
        aria-label={label}
        data-tour={label}
      >
        {options.map(option => (
          <ToggleButton
            key={option}
            data-tour={option}
            value={option}
            aria-label={option}
            disabled={disabled}
          >
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Column>
  );
};
