import React from "react";

import { Grid } from "@material-ui/core";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { patchSaleLot } from "actions";

import { Button, SecondaryButton } from "components/Form";
import { OtherMarkingField, HasDeploymentMarks } from "components/Form/Fields";
import MarkingField from "components/Form/Fields/MarkingField";
import MarkingPopover from "components/MarkingPopover";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "components/MaterialDialog";

import {
  selectDeploymentIdByConsignmentIdLookup,
  selectMarkOptionsByDeploymentIdLookup,
} from "selectors";

const anchorOrigin = {
  horizontal: "left",
  vertical: "bottom",
};

export const MarkingModal = ({ onClose, saleLot, anchorEl }) => {
  const dispatch = useDispatch();
  const save = values => {
    dispatch(
      patchSaleLot(
        { marks: values.marks, id: saleLot.id },
        { changeReason: "Marks updated" },
      ),
    );
    onClose();
  };

  const deploymentId = useSelector(selectDeploymentIdByConsignmentIdLookup)[
    saleLot.consignment_id
  ];
  const markOptions =
    useSelector(selectMarkOptionsByDeploymentIdLookup)[deploymentId] || [];

  const onMarksChanged = (color, location) => {
    const newMark = { color, location };
    save({ marks: [...saleLot.marks, newMark] });
  };

  if (markOptions.length === 0) {
    // If there are no predefined mark options configured, fall back to just selecting the custom mark.
    return (
      <MarkingPopover
        onMarksChanged={onMarksChanged}
        anchorOrigin={anchorOrigin}
        anchorEl={anchorEl.current}
        isOpen
        closeSelf={onClose}
      />
    );
  }

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Formik onSubmit={save} initialValues={saleLot}>
        <Form>
          <DialogTitle onClose={onClose}>Marks</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <HasDeploymentMarks deploymentId={deploymentId}>
                <Grid item xs={12}>
                  <MarkingField deploymentId={deploymentId} />
                </Grid>
              </HasDeploymentMarks>
              <Grid item xs={12}>
                <OtherMarkingField deploymentId={deploymentId} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

MarkingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  saleLot: PropTypes.object.isRequired,
};
