export const PaymentMethod = {
  ACCOUNT: "Account",
  CHEQUE: "Cheque",
  EFT: "EFT",
  IN_PERSON: "In Person",
};

export const ALL_PAYMENT_METHODS = Object.values(PaymentMethod);

export const PAYMENT_METHOD_OPTIONS = ALL_PAYMENT_METHODS.map(
  paymentMethod => ({
    label: paymentMethod,
    value: paymentMethod,
  }),
);
