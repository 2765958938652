import React, { useMemo, useState } from "react";

import {
  faCheck,
  faCompressAlt,
  faExpandAlt,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { Paper } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  matchPath,
  Redirect,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import styled from "styled-components/macro";

import { BillingDocumentAction, BillingRunAction } from "actions";

import SecurityTrimmedRoute from "components/Authorization/SecurityTrimmedRoute";
import { Export } from "components/BillingWorkFlow/Export";
import { Overview } from "components/BillingWorkFlow/Overview";
import { Review } from "components/BillingWorkFlow/Review";
import { Send } from "components/BillingWorkFlow/Send";
import { Sundries } from "components/BillingWorkFlow/Sundries";
import { MultiButton } from "components/Button";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { BillingScreens } from "constants/billing";
import { BillingRunStatus } from "constants/billingRuns";
import { ApiModel } from "constants/loading";
import { DeploymentPermissions } from "constants/permissions";
import { ReportJobStatus } from "constants/reportJob";

import { billingDocumentAllowsReversal } from "lib/billingDocument";
import {
  getBillingSubPageUrl,
  getLivestockSaleId,
  getSaleRoute,
  openBulkExportReport,
} from "lib/navigation";

import {
  getActiveBillingRunId,
  getBillingDocuments,
  getBillingRunById,
  getBillingRuns,
  getIsBillingRunCheckingForChanges,
  getIsStaff,
  getLastActiveBillingRunId,
  selectRoleCurrentDeployments,
  getActiveRole,
} from "selectors";

import appHistory from "appHistory";
import { useBoolean, useMountEffect } from "hooks";
import { useToggleFullScreen } from "hooks/useToggleFullscreen";
import { useWaityLoadyMultiButton } from "hooks/useWaityLoadyMultiButton";

const noBillingRunLabel = {
  disabled: true,
};

const changesDetectedLabel = {
  optional: (
    <Typography variant="caption" color="error">
      Changes Detected
    </Typography>
  ),
  error: true,
};

const steps = [
  {
    label: "Overview",
    path: BillingScreens.OVERVIEW,
  },
  {
    label: "Sundries",
    path: BillingScreens.SUNDRIES,
  },
  {
    label: "Review",
    path: BillingScreens.REVIEW,
    getLabelProps: billingRun => {
      if (isEmpty(billingRun)) {
        return noBillingRunLabel;
      }
      if (billingRun?.hasChanges) {
        return changesDetectedLabel;
      }
    },
  },
  {
    label: "Send",
    path: BillingScreens.SEND,
    getLabelProps: billingRun => {
      if (isEmpty(billingRun)) {
        return noBillingRunLabel;
      }
    },
  },
  {
    label: "Export",
    path: BillingScreens.EXPORT,
    getLabelProps: billingRun => {
      if (isEmpty(billingRun)) {
        return noBillingRunLabel;
      }
    },
  },
];

const Actions = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BillingWorkFlow = () => {
  const dispatch = useDispatch();

  const [selectedBillingDocuments, setSelectedBillingDocuments] = useState([]);

  const [
    isConfirmCosmeticDialogOpen,
    openConfirmCosmeticDialog,
    closeConfirmCosmeticDialog,
  ] = useBoolean();

  const [isConfirmAllDialogOpen, openConfirmAllDialog, closeConfirmAllDialog] =
    useBoolean();

  const [isFullScreen, toggleFullScreen] = useToggleFullScreen();

  const isStaff = useSelector(getIsStaff);

  const billingDocumentsByIdLookup = useSelector(getBillingDocuments);

  const billingPath = `${getSaleRoute(":saleyard", ":saleId")}/billing`;

  const billingRunId = useSelector(getActiveBillingRunId);
  const lastActiveBillingRunId = useSelector(getLastActiveBillingRunId);

  const match = useRouteMatch();
  const location = useLocation();

  const isBillingEnabled = useSelector(state => state.billingRuns.isEnabled);

  // the order of calling BillingRunAction.enable first before BillingRunAction.subscribe is important
  // because enable requests billing runs and is a much faster endpoint than subscribe's checkForChanges endpoint.
  // The components below are dependant on the billing runs being loaded and we don't want our users to wait longer
  // than they have to.
  React.useEffect(() => {
    if (!isBillingEnabled) {
      dispatch(BillingRunAction.enable(getLivestockSaleId()));
    }
  }, [isBillingEnabled, dispatch]);

  useMountEffect(() => {
    // If we move away from the page, we want to unsubscribe, but if we come back, we want to open the last open billing run.
    if (!billingRunId && lastActiveBillingRunId) {
      dispatch(BillingRunAction.subscribe(lastActiveBillingRunId));
    }

    return () => {
      dispatch(BillingRunAction.unsubscribe());
      dispatch(BillingRunAction.disable());
    };
  });

  function changeTabs(newScreen) {
    appHistory.push(getBillingSubPageUrl(newScreen));
  }

  const openStep = index => {
    changeTabs(steps[index].path);
  };

  const activeStep = useMemo(() => {
    // use the same mechanism that react-router uses to match paths to determine the open section.
    const matchProfile = matchPath(location.pathname, {
      path: `${billingPath}/:section`,
    });
    const match = (matchProfile && matchProfile.params) || {};

    return steps.findIndex(step => step.path === match.section) || 0;
  }, [billingPath, location.pathname]);

  const billingRun = useSelector(getBillingRunById(billingRunId));
  const isFinished = billingRun?.status === BillingRunStatus.FINISHED;

  // We fetch data on page load, so to avoid the initial isloading flash if we already have data, override it.
  const billingRunsExist = useSelector(state => {
    const billingRuns = getBillingRuns(state);
    return Object.keys(billingRuns).length > 0;
  });

  const isBillingRunCheckingForChanges = useSelector(
    getIsBillingRunCheckingForChanges,
  );

  const isReviewStep = steps[activeStep]?.path === BillingScreens.REVIEW;

  const bulkAcceptChanges = allowFinancial => {
    dispatch(
      BillingDocumentAction.bulkAcceptChanges(
        selectedBillingDocuments.map(doc => doc.id),
        allowFinancial,
      ),
    );
    closeConfirmAllDialog();
    closeConfirmCosmeticDialog();
  };

  const filterForChangedBillingDocuments = (
    billingDocumentId,
    allowFinancial,
  ) => {
    const billingDocument = billingDocumentsByIdLookup[billingDocumentId] || {};

    const needsReversal = billingDocumentAllowsReversal(billingDocument);

    if (allowFinancial) {
      // do not allow bulk accepting of documents that require
      // a choice on handling a reversal
      if (needsReversal) {
        return false;
      }
      return (
        billingDocument.hasFinancialChanges ||
        billingDocument.hasCosmeticChanges
      );
    }
    return (
      billingDocument.hasCosmeticChanges && !billingDocument.hasFinancialChange
    );
  };

  const filteredBillingDocumentIds = (allowFinancial = false) =>
    selectedBillingDocuments.filter(billingDocument =>
      filterForChangedBillingDocuments(billingDocument.id, allowFinancial),
    );

  const userRoleSlug = useSelector(getActiveRole).slug;

  // Bulk export requires that all document job are already rendered successfully
  // This could be removed if we upgrade the bulk export to also render, or if documents
  // are automatically rendered with the billing run is created.
  const reportJobIdsReadyForExport = selectedBillingDocuments
    .map(
      bd => bd.reportJobs.find(rj => rj.status === ReportJobStatus.SUCCESS)?.id,
    )
    .filter(Boolean);

  const buttons = [
    useWaityLoadyMultiButton(
      "Re-run",
      () => BillingRunAction.checkForChanges(billingRunId),
      isBillingRunCheckingForChanges,
      true,
      !billingRunsExist || isFinished,
      isFinished
        ? "Billing Run cannot be re-run while the status is set to Finished"
        : false,
    ),
    isReviewStep &&
      filteredBillingDocumentIds(false).length && {
        title: `Accept (${filteredBillingDocumentIds(false).length}) non-financial changes`,
        icon: faCheck,
        onClick: () => openConfirmCosmeticDialog(),
      },
    isReviewStep &&
      isStaff &&
      filteredBillingDocumentIds(true).length && {
        title: `(Staff Only) Accept all (${filteredBillingDocumentIds(true).length}) non-reversal changes`,
        icon: faCheck,
        onClick: () => openConfirmAllDialog(),
      },
    {
      title: isFullScreen ? "Exit Fullscreen mode" : "Enter Fullscreen mode",
      icon: isFullScreen ? faCompressAlt : faExpandAlt,
      onClick: toggleFullScreen,
    },
  ].filter(Boolean);

  if (
    [
      BillingScreens.REVIEW,
      BillingScreens.SEND,
      BillingScreens.EXPORT,
    ].includes(steps[activeStep]?.path) &&
    reportJobIdsReadyForExport.length > 1
  ) {
    buttons.push({
      title: `Compile (${reportJobIdsReadyForExport.length}) items as PDF`,
      icon: faFilePdf,
      onClick: () =>
        openBulkExportReport(reportJobIdsReadyForExport, userRoleSlug),
    });
  }

  return (
    <WaitForSync
      requiredData={[
        ApiModel.BILLING_DATA,
        ApiModel.BILLING_DOCUMENTS,
        ApiModel.BILLING_RUNS,
        ApiModel.BUSINESSES,
        ApiModel.EMAIL,
        ApiModel.LEDGER_ENTRIES,
        ApiModel.MANUAL_ADJUSTMENTS,
      ]}
      forceNotLoading={billingRunsExist}
    >
      <Column full>
        <Paper>
          <Row>
            <Column full>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((step, index) => {
                  const labelProps = step.getLabelProps?.(billingRun) || {};
                  if (!labelProps.disabled) {
                    labelProps.onClick = () => openStep(index);
                  }

                  return (
                    <Step data-tour={step.path} key={step.label}>
                      <StepLabel {...labelProps}>{step.label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Column>
            <Actions>
              <Column alignCenter gridGap={1}>
                <MultiButton buttons={buttons} />
              </Column>
            </Actions>
          </Row>
        </Paper>

        <Switch>
          <SecurityTrimmedRoute
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureBilling]}
            path={`${billingPath}/overview`}
            component={Overview}
          />
          <SecurityTrimmedRoute
            exact
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureManualCharges]}
            path={`${billingPath}/sundries`}
            component={Sundries}
          />
          <SecurityTrimmedRoute
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureBillingRun]}
            path={`${billingPath}/review`}
            render={() => (
              <Review
                setSelectedBillingDocuments={setSelectedBillingDocuments}
              />
            )}
          />
          <SecurityTrimmedRoute
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureBillingRun]}
            path={`${billingPath}/send`}
            render={() => (
              <Send setSelectedBillingDocuments={setSelectedBillingDocuments} />
            )}
          />

          <SecurityTrimmedRoute
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureBillingRun]}
            path={`${billingPath}/export`}
            render={() => (
              <Export
                setSelectedBillingDocuments={setSelectedBillingDocuments}
              />
            )}
          />

          <Redirect to={`${match.url}/overview`} />
        </Switch>
      </Column>
      <ConfirmDialog
        title="Accept non-financial changes"
        message={`Please confirm you'd like to accept ${filteredBillingDocumentIds(false).length} non-financial changes`}
        isOpen={isConfirmCosmeticDialogOpen}
        onCancel={closeConfirmCosmeticDialog}
        onDelete={() => bulkAcceptChanges()}
        buttonMessage={`Accept (${filteredBillingDocumentIds(false).length}) changes`}
      />
      <ConfirmDialog
        title="Accept changes"
        message={`Please confirm you'd like to accept ${filteredBillingDocumentIds(true).length} changes including any financial changes not requiring a reversal.`}
        isOpen={isConfirmAllDialogOpen}
        onCancel={closeConfirmAllDialog}
        onDelete={() => bulkAcceptChanges(true)}
        buttonMessage={`Accept (${filteredBillingDocumentIds(true).length}) changes`}
      />
    </WaitForSync>
  );
};
