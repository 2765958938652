import React, { useEffect, useRef } from "react";

import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";

import { updateModalContext } from "actions";

import { getContextByModalType } from "selectors";

import { useBoolean, useModalAdapter } from "hooks";

function DispatchingModalStateAlignmentComponent(props) {
  const { modalComponent: ModalComponent, onClose } = props;

  return <ModalComponent onClose={onClose} />;
}

const DispatchingModalStateAlignment = React.memo(
  DispatchingModalStateAlignmentComponent,
);

function DispatchingHashModalAdapterComponent(props) {
  const { modalComponent, modalType } = props;

  const [hashParams, _ignored, onClose] = useModalAdapter(modalType, props);
  const dispatch = useDispatch();

  const { isOpen } = useSelector(getContextByModalType(modalType)) || {};

  // the first mount of the component may mean the arguments passed in from the URL have
  // not yet been applied to the redux state for the modal. We don't want to let the
  // child component re-render until these have been applied.

  // check to see if the current hashParams are the same as the last hashParams, if they are not, prevent the render.
  const hashParamsRef = useRef(null);
  const [hasMounted, setHasMounted] = useBoolean(false);
  let hasUrlStateDisparity = false;

  if (!isEqual(hashParams, hashParamsRef.current)) {
    hasUrlStateDisparity = true;
  }

  useEffect(() => {
    // When the URL changes mark the supplied modal as open, and push the hash params to state.
    let contextUpdate = null;
    if (!isOpen) {
      contextUpdate = { isOpen: true };
    }
    if (hasUrlStateDisparity) {
      /*
      Handles the following scenario:
      Modal opens with a parameter in the url,
      the parameter from the url isn't yet in the modal state,
      a formik form is mounted without enable reinitialise,
      the following render puts the requisite id (or other url param) in the state
      formik doesn't update
       */
      contextUpdate = {
        ...contextUpdate,
        ...hashParams,
      };
      hashParamsRef.current = hashParams;
      setHasMounted();
    }
    if (contextUpdate) {
      dispatch(updateModalContext(modalType, contextUpdate));
    }
  }, [
    dispatch,
    setHasMounted,
    hasUrlStateDisparity,
    hashParams,
    hashParamsRef,
    isOpen,
    modalType,
  ]);

  useEffect(() => {
    return () => {
      // When the modal is closed dispatch an action to state that this type is closed.
      dispatch(updateModalContext(modalType, { isOpen: false }));
      hashParamsRef.current = null;
    };
  }, [dispatch, modalType, hashParamsRef]);

  if (
    // the URL has changed and the modal is not yet open
    (hasUrlStateDisparity && !isOpen) ||
    // The first render, and there are props in the URL will not have been applied to the redux state yet.
    (hashParams !== null && !hasMounted)
  ) {
    // avoid rendering the modal until the URL state has been applied to the redux
    // state (so long as it's not already open). If it is already open and the URL
    // changes (eg a different collapse is opened that is tracked in the URL) we
    // do not want to remount.
    return null;
  }

  return (
    <DispatchingModalStateAlignment
      modalComponent={modalComponent}
      onClose={onClose}
    />
  );
}

export default DispatchingHashModalAdapterComponent;
