import { useReducer } from "react";

function sectionReducer(state, section) {
  if (state !== section) {
    return section;
  } else {
    return null;
  }
}

export function useSectionToggle(initialSection = null) {
  return useReducer(sectionReducer, initialSection);
}

export function useSectionHelper(sectionHelper, section) {
  const [currentSection, setSection] = sectionHelper;
  const isOpen = currentSection === section;
  const onToggle = () => setSection(section);

  return { isOpen, onToggle };
}
