import React, { useEffect, useState } from "react";

import {
  faCrop,
  faDownload,
  faSave,
  faSearchMinus,
  faSearchPlus,
  faSyncAlt,
  faTrash,
  faTrashUndo,
} from "@fortawesome/pro-duotone-svg-icons";
import { faEllipsisH, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faImages } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@material-ui/core";
import Cropper from "react-cropper";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { updateAttachment } from "actions";

import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Column, Row } from "components/Layout";
import { OptionToggler } from "components/OptionToggler";
import { Paper } from "components/Paper";
import { UploadModal } from "components/UploadModal";

import { DocumentTypes } from "constants/documentTypes";

import "cropperjs/dist/cropper.css";

import { extractFileNameFromURL } from "lib";

import toast from "lib/toast";

import { useBoolean, useTheme } from "hooks";

const IconStyle = styled(Column)`
  color: ${({ active, theme, highlight, disabled }) => {
    if (disabled) {
      return theme.colors.grayAE;
    } else if (active) {
      return theme.colors.white;
    } else if (highlight) {
      return theme.colors.warning;
    }
    return theme.colors.black;
  }};

  ${({ theme, active, highlight, disabled }) => `
  font-size: 12px;
  margin: 0 ${theme.space[1]}px;
  background: ${
    disabled
      ? theme.colors.gray78
      : active
        ? theme.colors.primary
        : theme.colors.white
  };
  ${highlight ? `border 1px solid ${theme.colors.warning};` : ""}
  &:hover {
      background: ${
        disabled ? theme.colors.gray78 : theme.colors.primaryHighlight
      };
      color: ${theme.colors.white};
  }
  box-shadow: ${theme.shadows[1]};
  width: 65px;
  height: 65px;
  transition: ${theme.transitions[0]};
  cursor: ${disabled ? `not-allowed` : `pointer`};
  @media (max-width: ${theme.breakpoints[0]}px) {
    margin: 0;
  }
  
`}
`;
const IconText = styled.div`
  ${({ theme }) => `
      padding-top: ${theme.space[1]}px;
`}
`;

const IconBox = ({
  icon,
  onClick,
  text,
  active = false,
  highlight = false,
  disabled,
}) => (
  <IconStyle
    alignCenter
    justifyCenter
    onClick={onClick}
    active={active}
    highlight={highlight}
    disabled={disabled}
  >
    <FontAwesomeIcon icon={icon} size="2x" />
    <IconText>{text}</IconText>
  </IconStyle>
);

const CropCanvas = styled(Cropper)`
  ${({ theme }) => `
    height: calc(100% - 65px - ${theme.space[1] * 4}px);
    width: 100%;
    border: 1px solid ${theme.colors.controlBorder};
`}
`;

const MobileOverlay = styled(Column)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  ${({ theme }) => `
    background-color: ${theme.colors.darkOverlay};`}
`;

const ActivationOverlay = styled(Column)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const FaIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin: -2px 7px;
  line-height: 0;
  color: ${({ theme, color }) =>
    color && theme.colors[color]
      ? theme.colors[color]
      : theme.colors.devilGray};
`;

const OpenCloseButton = styled.button`
  ${({ theme, highlight }) =>
    highlight
      ? `
      border 1px solid ${theme.colors.warning};
      color: ${theme.colors.warning};      
      `
      : ""}
`;

const MobileControlHandler = ({ rows, isModified = false }) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${breakpoints[2]}px)`);
  const [isShowingOverlay, setIsShowingOverlay] = useState(false);

  // When the modified state changes (eg, they rotate) hide the overlay.
  useEffect(() => {
    setIsShowingOverlay(false);
  }, [isModified]);

  if (isMobile && isShowingOverlay) {
    return (
      <MobileOverlay fullWidth alignCenter justifyBetween padding={4}>
        {rows.map(r => r)}
        <OpenCloseButton
          onClick={() => {
            setIsShowingOverlay(false);
          }}
        >
          <FaIcon icon={faTimes} />
        </OpenCloseButton>
      </MobileOverlay>
    );
  } else if (isMobile) {
    return (
      <ActivationOverlay fullWidth alignCenter justifyEnd padding={4}>
        <OpenCloseButton
          onClick={() => {
            setIsShowingOverlay(true);
          }}
          highlight={isModified}
        >
          <FaIcon
            icon={faEllipsisH}
            color={isModified ? "warning" : undefined}
          />
        </OpenCloseButton>
      </ActivationOverlay>
    );
  }
  return rows.map((r, idx) => (
    <Row justifyCenter padding={2} key={idx}>
      {r}
    </Row>
  ));
};

export const CropControls = ({
  cropper,
  file,
  handleDelete,
  handleReplace,
  nvdAttachmentIds,
  attachmentTypeOptions,
  disabled,
}) => {
  const dispatch = useDispatch();

  const initialType = file?.type;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCropActive, setIsCropActive] = useState(false);
  const [attachmentType, setAttachmentType] = useState(initialType);
  const [isModified, setIsModified] = useState(false);
  const [isUploadModalOpen, openUploadModal, closeUploadModal] =
    useBoolean(false);

  const save = async () => {
    if (
      attachmentType === DocumentTypes.NVD &&
      nvdAttachmentIds &&
      nvdAttachmentIds.length > 0 &&
      !nvdAttachmentIds.includes(file.id)
    ) {
      // Block multiple NVD attachments.
      toast.error("Only one NVD can be uploaded per Consignment");
      return;
    }

    if (typeof cropper !== "undefined") {
      const blob = await new Promise(resolve => {
        cropper.getCroppedCanvas().toBlob(resolve, "image/jpeg", 0.9);
      });

      const filename = extractFileNameFromURL(file.image_url);
      const newFile = new File([blob], filename);

      // If the type has changed, explicitly move it to the start
      const order = attachmentType === file.type ? file.order : -1;
      const payload = {
        ...file,
        order,
        file: newFile,
        type: attachmentType,
      };
      dispatch(updateAttachment(payload));
      setIsModified(false);
      setIsCropActive(false);
    }
  };

  const deleteFile = () => {
    handleDelete(file);
    setIsDeleteDialogOpen(false);
  };

  const replaceFile = newFile => {
    handleReplace(file, newFile);
    closeUploadModal();
  };

  const crop = () => {
    if (cropper) {
      setIsCropActive(!cropper.cropped);
      setIsModified(true);
      if (cropper.cropped) {
        cropper.clear();
        cropper.setDragMode("move");
      } else {
        cropper.setDragMode("crop");
        cropper.crop();
      }
    }
  };
  const reset = () => {
    setAttachmentType(file.type);
    setIsModified(false);
    setIsCropActive(false);
    cropper && cropper.clear();
  };
  const rotate = () => {
    setIsModified(true);
    cropper.rotate(90);
  };
  const zoomIn = () => cropper?.zoom(0.1);
  const zoomOut = () => cropper?.zoom(-0.1);
  const download = () => {
    const a = document.createElement("a");
    const filename = extractFileNameFromURL(file.image_url);
    a.href = file.image_url;
    a.download = filename;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const openReplaceDialog = () => {
    openUploadModal();
  };

  const rows = [
    <>
      <IconBox
        icon={faCrop}
        onClick={!disabled && crop}
        text="Crop"
        active={isCropActive}
        disabled={disabled}
      />
      <IconBox
        icon={faSyncAlt}
        onClick={!disabled && rotate}
        text="Rotate 90"
        disabled={disabled}
      />
      <IconBox icon={faSearchPlus} onClick={zoomIn} text="Zoom In" />
      <IconBox icon={faSearchMinus} onClick={zoomOut} text="Zoom Out" />
      <IconBox
        icon={faTrashUndo}
        onClick={!disabled && reset}
        text="Reset"
        disabled={disabled}
      />
      <IconBox
        icon={faTrash}
        onClick={() => !disabled && setIsDeleteDialogOpen(true)}
        text="Delete"
        disabled={disabled}
      />
      <IconBox icon={faDownload} onClick={download} text="Download" />
      <IconBox
        icon={faImages}
        onClick={!disabled && openReplaceDialog}
        text="Replace"
        disabled={disabled}
      />
      <IconBox
        icon={faSave}
        onClick={!disabled && save}
        text="Save"
        highlight={isModified}
        disabled={disabled}
      />
      {isUploadModalOpen && (
        <UploadModal closeSelf={closeUploadModal} handleUpload={replaceFile} />
      )}
    </>,
  ];

  if (attachmentTypeOptions) {
    rows.push(
      <OptionToggler
        name="Attachment Type"
        options={attachmentTypeOptions}
        value={attachmentType}
        onChange={setAttachmentType}
      />,
    );
  }

  return (
    <>
      <MobileControlHandler rows={rows} isModified={isModified} />
      {isDeleteDialogOpen && (
        <ConfirmDialog
          title={createModalTitle("this Attachment")}
          isOpen={isDeleteDialogOpen}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onDelete={deleteFile}
        />
      )}
    </>
  );
};

export const Crop = ({ file, handleDelete, handleReplace, disabled }) => {
  const [cropper, setCropper] = useState();

  return (
    <Paper style={{ position: "relative" }}>
      <CropControls
        cropper={cropper}
        file={file}
        handleDelete={handleDelete}
        handleReplace={handleReplace}
        disabled={disabled}
      />
      <CropCanvas
        initialAspectRatio={1}
        preview=".img-preview"
        src={file.image_url}
        viewMode={1}
        guides
        rotatable
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive
        autoCrop={false}
        autoCropArea={1}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        onInitialized={instance => {
          setCropper(instance);
        }}
        dragMode="move"
      />
    </Paper>
  );
};
