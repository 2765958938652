import React from "react";

export default ({ color, className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="26px"
    height="27px"
    viewBox="0 0 26 27"
    version="1.1"
  >
    <title>83342BC0-705E-43FF-BF5A-8D816134BA9D</title>
    <desc>Created with sketchtool.</desc>
    <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Scanning-states"
        transform="translate(-22.000000, -424.000000)"
        fill={color}
      >
        <g
          id="Scanner-Row/Tag-Lookup/1.-Normal-Copy"
          transform="translate(0.000000, 397.000000)"
        >
          <g
            id="icons/scanner/highlighted"
            transform="translate(22.000000, 26.963415)"
          >
            <g id="Group-7-Copy-11">
              <path
                d="M13.9855405,22.0124605 L12.0133,22.0124605 C10.6943622,22.0124605 9.62108919,20.9272382 9.62108919,19.5936158 L9.62108919,1.62369474 C9.62108919,0.810461842 10.2743919,0.149921053 11.0786351,0.149921053 L14.9203108,0.149921053 C15.7245892,0.149921053 16.3778568,0.810497368 16.3778568,1.62369474 L16.3778568,19.5936158 C16.3778568,20.9272382 15.3045838,22.0124605 13.9856459,22.0124605 L13.9855405,22.0124605 Z M11.0786703,1.51697368 C11.0196538,1.51697368 10.971617,1.56554526 10.971617,1.62382974 L10.971617,19.5937508 C10.971617,20.1738245 11.4382468,20.647035 12.0133035,20.647035 L13.9855441,20.647035 C14.5592305,20.647035 15.0258603,20.17521 15.0258603,19.5937508 L15.0272341,1.62382974 C15.0272341,1.56415618 14.9791973,1.51697368 14.9215546,1.51697368 L11.0786703,1.51697368 Z"
                id="Fill-1"
              />
              <path
                d="M14.352,26.8500789 L11.6496162,26.8500789 L11.6496162,19.3355526 C11.6496162,18.5820039 12.2562595,17.9686066 13.0015108,17.9686066 C13.7467622,17.9686066 14.3534054,18.5820039 14.3534054,19.3355526 L14.352,26.8500789 Z"
                id="Fill-2"
              />
              <path
                d="M3.93618919,19.1479737 C1.5028,16.8276789 0.161972973,13.7398026 0.161972973,10.4521974 C0.161972973,7.16459211 1.50287027,4.077 3.93618919,1.75642105 L4.8625973,2.75005658 C2.70371892,4.81086711 1.5137973,7.54610921 1.5137973,10.4521618 C1.5137973,13.3594934 2.70371892,16.0933855 4.8640027,18.1542671 L3.93618919,19.1479737 Z"
                id="Fill-3"
              />
              <path
                d="M22.0638108,19.1479737 L21.1374027,18.1543382 C23.2962811,16.0949132 24.4862027,13.3597066 24.4862027,10.4522329 C24.4862027,7.54475921 23.2962811,4.81100921 21.1359973,2.75012763 L22.0624054,1.75649211 C24.4971649,4.07678684 25.838027,7.16608421 25.838027,10.4522684 C25.838027,13.7398382 24.4971297,16.8274658 22.0638108,19.1480447 L22.0638108,19.1479737 Z"
                id="Fill-4"
              />
              <path
                d="M5.80537838,17.3663289 C3.87020541,15.5220158 2.80515405,13.0669342 2.80515405,10.4525526 C2.80515405,7.83941447 3.87017027,5.38436842 5.80537838,3.54019737 L6.73178649,4.53383289 C5.07108919,6.11724079 4.15567838,8.21968816 4.15567838,10.4525171 C4.15567838,12.6853461 5.06975405,14.7877934 6.73178649,16.3712013 L5.80537838,17.3663289 Z"
                id="Fill-5"
              />
              <path
                d="M20.1946216,17.3663289 L19.2682135,16.3726934 C20.9289108,14.7892855 21.8443216,12.6868382 21.8429514,10.4540092 C21.8429514,8.21972368 20.9288757,6.11873289 19.2682135,4.535325 L20.1946216,3.54168947 C22.1284243,5.38600263 23.1948459,7.84108421 23.1948459,10.4540447 C23.1934722,13.0671829 22.1284243,15.5222289 20.1946216,17.3664 L20.1946216,17.3663289 Z"
                id="Fill-6"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
