import queryString from "query-string";

import { INTEREST_SETTING } from "constants/actionTypes";
import { DeploymentPermissions } from "constants/permissions";

import { hasPermission } from "lib/permissions";
import { serializeInterestSettings } from "lib/serializers/interest";

import {
  getActiveLivestockAgentDeployment,
  getIsFetchingDeployments,
} from "selectors";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/interest-settings/", id, action),
    query: {
      changesSince,
    },
  });

export const InterestSettingsAction = offlineActionCreator(
  INTEREST_SETTING,
  serializeInterestSettings,
  urlCreator,
  false,
);

InterestSettingsAction.actionFilter = (state, changes = false) => {
  const deployment = getActiveLivestockAgentDeployment(state);
  const hasInterestPermission = hasPermission(
    deployment,
    DeploymentPermissions.featureInterest,
  );
  if (!hasInterestPermission) {
    return null;
  } else if (!changes) {
    return InterestSettingsAction.request();
  } else if (changes && !getIsFetchingDeployments(state)) {
    return InterestSettingsAction.requestChanges({
      changesSince: state.interestSettings.lastModifiedTimestamp,
    });
  } else {
    // we requested changes but are fetching already.
    return null;
  }
};
