import React from "react";

import {
  faCommentAlt,
  faCommentAltPlus,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCommentAltCheck,
  faCommentAltExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { UnstyledButton } from "components/Button";

import { CommentTypes } from "constants/comments";

import { openCommentsModal } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import {
  getCommentById,
  getHasResolvedCommentsByNominationId,
  getHasResolvedCommentsByPenScanLotId,
  getHasResolvedCommentsByReceivalLotId,
  getHasResolvedCommentsBySaleLotId,
  getHasResolvedCommentsByDeploymentSaleId,
  getHasResolvedCommentsByWeighLotId,
  getHasUnresolvedCommentsByNominationId,
  getHasUnresolvedCommentsByPenScanLotId,
  getHasUnresolvedCommentsByRecevialLotId,
  getHasUnresolvedCommentsBySaleLotId,
  getHasUnresolvedCommentsByDeploymentSaleId,
  getHasUnresolvedCommentsByWeighLotId,
} from "selectors";

import { useTheme } from "hooks";

const FaIcon = styled(({ horizontalPadding: ignored, ...props }) => (
  <FontAwesomeIcon {...props} />
))(
  ({ theme, horizontalPadding }) => `
  ${
    horizontalPadding
      ? `padding-left: ${theme.space[horizontalPadding]}px; padding-right: ${theme.space[horizontalPadding]}px;`
      : ""
  }
`,
);

const IconWrapper = styled.div(
  ({ theme, horizontalPadding }) => `  
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${
    horizontalPadding
      ? `padding-left: ${theme.space[horizontalPadding]}px; padding-right: ${theme.space[horizontalPadding]}px;`
      : ""
  }
  `,
);

const IconInternalText = styled.div(
  ({ color }) => `
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20%;
  font-weight: bold;
  ${color ? `color: ${color};` : ""}
`,
);

export function CommentIcon({
  hasUnresolvedComments,
  hasResolvedComments,
  size = "2x",
  horizontalPadding,
  internalText,
  title = "",
}) {
  const theme = useTheme();
  let icon = faCommentAltPlus;
  let color = theme.colors.gray40;
  if (hasUnresolvedComments) {
    icon = faCommentAltExclamation;
    color = theme.colors.inputError;
  } else if (hasResolvedComments) {
    icon = faCommentAltCheck;
    color = theme.colors.successGreen;
  }

  // If we want to display content, use the 'blank' icon, but keep the singificant colours.
  if (internalText) {
    return (
      <IconWrapper horizontalPadding={horizontalPadding} title={title}>
        <FaIcon icon={faCommentAlt} size={size} color={color} />
        <IconInternalText color={color}>{internalText}</IconInternalText>
      </IconWrapper>
    );
  }

  return (
    <FaIcon
      icon={icon}
      size={size}
      color={color}
      horizontalPadding={horizontalPadding}
      title={title}
    />
  );
}

export function SingleCommentIcon({
  commentId,
  size = "2x",
  horizontalPadding,
}) {
  const comment = useSelector(getCommentById(commentId));
  return (
    <CommentIcon
      hasUnresolvedComments={!comment.resolvedDate}
      hasResolvedComments={Boolean(comment.resolvedDate)}
      size={size}
      horizontalPadding={horizontalPadding}
    />
  );
}

export function UnresolvedCommentIcon({
  unresolvedCommentCount,
  size = "4x",
  horizontalPadding,
}) {
  if (!unresolvedCommentCount) {
    return null;
  }
  return (
    <CommentIcon
      hasUnresolvedComments
      internalText={unresolvedCommentCount}
      size={size}
      horizontalPadding={horizontalPadding}
      title={`${unresolvedCommentCount} unresolved ${pluralize(
        "comment",
        unresolvedCommentCount,
      )}.`}
    />
  );
}

const HasUnresolvedCommentsSelectorMap = {
  [CommentTypes.SALE_LOT]: getHasUnresolvedCommentsBySaleLotId,
  [CommentTypes.NOMINATION]: getHasUnresolvedCommentsByNominationId,
  [CommentTypes.RECEIVAL_LOT]: getHasUnresolvedCommentsByRecevialLotId,
  [CommentTypes.PEN_SCAN_LOT]: getHasUnresolvedCommentsByPenScanLotId,
  [CommentTypes.DEPLOYMENT_SALE]: getHasUnresolvedCommentsByDeploymentSaleId,
  [CommentTypes.WEIGH_LOT]: getHasUnresolvedCommentsByWeighLotId,
};

const HasResolvedCommentsSelectorMap = {
  [CommentTypes.SALE_LOT]: getHasResolvedCommentsBySaleLotId,
  [CommentTypes.NOMINATION]: getHasResolvedCommentsByNominationId,
  [CommentTypes.RECEIVAL_LOT]: getHasResolvedCommentsByReceivalLotId,
  [CommentTypes.PEN_SCAN_LOT]: getHasResolvedCommentsByPenScanLotId,
  [CommentTypes.DEPLOYMENT_SALE]: getHasResolvedCommentsByDeploymentSaleId,
  [CommentTypes.WEIGH_LOT]: getHasResolvedCommentsByWeighLotId,
};

export function ClickableCommentIcon({
  returnTo,
  commentType,
  commentTypeId,
  options,
}) {
  const hasUnresolvedComments = useSelector(
    HasUnresolvedCommentsSelectorMap[commentType](commentTypeId),
  );
  const hasResolvedComments = useSelector(
    HasResolvedCommentsSelectorMap[commentType](commentTypeId),
  );

  return (
    <UnstyledButton
      type="button"
      onClick={() =>
        openCommentsModal(commentType, commentTypeId, returnTo, options)
      }
    >
      <CommentIcon
        hasUnresolvedComments={hasUnresolvedComments}
        hasResolvedComments={hasResolvedComments}
      />
    </UnstyledButton>
  );
}
