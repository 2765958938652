import React from "react";

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "@material-ui/core/Popover";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { SlimButton } from "components/Button";
import { PreventPropagation } from "components/Events";
import { Row } from "components/Layout";

const Inner = styled.div`
  padding: ${({ hasTitle }) => (hasTitle ? "24" : "6")}px 6px 6px 6px;
  ${({ align }) => align === "left" && "padding-top: 0;"};
  border-color: #666;
  background-color: #666;
  color: #fff;
  line-height: 36px;
`;

const TitleWrapper = styled(Row)`
  padding: ${({ theme }) => `${theme.space[1]}px ${theme.space[2]}px`};
  line-height: 24px;
`;

const Button = styled(SlimButton)`
  ${({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.gray78};
  border-radius: ${theme.radii[1]}px;
  border: 1px solid ${theme.colors.gray40};
  &:hover {
    background: ${theme.colors.gray85};
  }
  transition: ${theme.transitions[0]};

`}
`;

const FaIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin: -2px 7px;
  line-height: 0;
  color: ${({ theme, color }) =>
    color && theme.colors[color] ? theme.colors[color] : "#666"};
`;

const anchorOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const transformOrigin = {
  vertical: "top",
  horizontal: "right",
};

const TabletDropdown = ({ dropdownTitle, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = event => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const isOpen = Boolean(anchorEl);

  return (
    <PreventPropagation onClick={handleClose}>
      <Button data-tour="tabletDropdown" onClick={handleClick}>
        {dropdownTitle ? (
          <TitleWrapper alignCenter>
            {dropdownTitle}
            <FaIcon
              icon={faCaretDown}
              color="gray40"
              style={{ fontSize: 18 }}
            />
          </TitleWrapper>
        ) : (
          <FaIcon icon={faEllipsisH} />
        )}
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Inner data-tour="popOver" hasTitle={dropdownTitle}>
          {children}
        </Inner>
      </Popover>
    </PreventPropagation>
  );
};

TabletDropdown.propTypes = {
  children: PropTypes.any,
  dropdownTitle: PropTypes.string,
};

TabletDropdown.defaultProps = {
  dropdownTitle: "",
};

export default TabletDropdown;
