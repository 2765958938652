import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { CenteredGreedy } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

import { selectItemsFetching } from "selectors";

function WaitForSync({
  children,
  requiredData,
  size = undefined,
  actionName = "Loading",
  subjectName = null,
  showLoadingSpinner = true,
  delay = null,
  forceNotLoading = false,
}) {
  // isDelayed should be false if no delay is passed in, otherwise a value denotes to wait.
  const [isDelayed, setIsDelayed] = useState(delay !== null);
  const itemsLoading = useSelector(selectItemsFetching);
  const isLoading = Array.isArray(requiredData)
    ? requiredData.some(k => itemsLoading[k])
    : Object.values(itemsLoading).some(v => v);

  // If we've been asked to delay, wait [delay] seconds then trigger the render.
  useEffect(() => {
    if (delay) {
      const timeOut = setTimeout(() => {
        setIsDelayed(false);
      }, delay);
      return () => clearTimeout(timeOut);
    }
  }, [delay, setIsDelayed]);

  if (!forceNotLoading && (isLoading || isDelayed)) {
    if (showLoadingSpinner) {
      return (
        <CenteredGreedy>
          <LoadingSpinner
            size={size}
            actionName={actionName}
            subjectName={subjectName}
          />
        </CenteredGreedy>
      );
    } else {
      return null;
    }
  }
  return children;
}

export default WaitForSync;
