import React from "react";

import { Grid } from "@material-ui/core";
import { Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  connectToHub,
  updateAvailableDevices,
  disconnectFromDevice,
  connectToDevice,
  sendMessage,
} from "actions";

import { ActionButtonContainer } from "components/AgGrid/ActionButton";
import { SlimButton } from "components/Button";
import { Input, PercentageInput } from "components/Form/FormikControls";
import { Column, SpaceBetweenRow } from "components/Layout";
import { Paper } from "components/Paper";

import { PLC_DRIVERS } from "lib/deviceDrivers";

import {
  getAvailableDevices,
  getIsHubConnected,
  getConnectedPLCs,
} from "selectors";

const InputTest = () => {
  const { values } = useFormikContext();
  return (
    <>
      <SpaceBetweenRow />
      <Paper>
        <h1>Input Tests</h1>
        <Grid justifyContent="space-between" container spacing={2}>
          <Grid item>
            <Input
              label="Number"
              type="number"
              name="number"
              tooltip="Expected type: Number"
            />
            <text>
              Value: {values.number} Type: {typeof values.number}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Text"
              type="text"
              name="text"
              tooltip="Expected type: String"
            />
            <text>
              Value: {values.text} Type: {typeof values.text}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Number and Decimal"
              type="number"
              name="numberDecimal"
              decimal
              tooltip="Expected type: Number"
            />
            <text>
              Value: {values.numberDecimal} Type: {typeof values.numberDecimal}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Text and Decimal"
              type="text"
              name="textDecimal"
              decimal
              tooltip="Expected type: String"
            />
            <text>
              Value: {values.textDecimal} Type: {typeof values.textDecimal}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Text and 5 Decimal Places"
              type="text"
              name="textDecimal5Places"
              decimal
              decimalPlaces={5}
              tooltip="Expected type: String"
            />
            <text>
              Value: {values.textDecimal5Places} Type:{" "}
              {typeof values.textDecimal5Places}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Number and 5 Decimals"
              type="number"
              name="number5DecimalPlaces"
              decimal
              decimalPlaces={5}
              tooltip="Expected type: Number"
            />
            <text>
              Value: {values.number5DecimalPlaces} Type:{" "}
              {typeof values.number5DecimalPlaces}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Number 1000x Multiplier"
              type="number"
              name="numberMultiplier"
              multiplier={1000}
              tooltip="Expected type: Number"
            />
            <text>
              Value: {values.numberMultiplier} Type:{" "}
              {typeof values.numberMultiplier}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Num, Decimal, 1000x Multiplier"
              type="number"
              name="numberDecimalMultiplier"
              multiplier={1000}
              decimal
              tooltip="Expected type: Number"
            />
            <text>
              Value: {values.numberDecimalMultiplier} Type:{" "}
              {typeof values.numberDecimalMultiplier}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Text, Decimal, 1000x Multiplier"
              type="text"
              name="textDecimalMultiplier"
              multiplier={1000}
              decimal
              tooltip="Expected type: String"
            />
            <text>
              Value: {values.textDecimalMultiplier} Type:{" "}
              {typeof values.textDecimalMultiplier}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Text Max Length 10 Chars"
              type="text"
              name="textMaxLength"
              maxLength={10}
              tooltip="Expected type: String"
            />
            <text>
              Value: {values.textMaxLength} Type: {typeof values.textMaxLength}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Number Max Length 10 Chars"
              type="number"
              name="numberMaxLength"
              maxLength={10}
              tooltip="Expected type: Number"
            />
            <text>
              Value: {values.numberMaxLength} Type:{" "}
              {typeof values.numberMaxLength}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Override Value Text"
              type="text"
              name="overrideValueText"
              overrideValue="Sean Doolan"
              tooltip="The value should be what you entered, but it should display Sean Doolan. Type: String"
            />
            <text>
              Value: {values.overrideValueText} Type:{" "}
              {typeof values.overrideValueText}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Override Value Number"
              type="number"
              name="overrideValueNumber"
              overrideValue={9000}
              tooltip="The value should be what you entered, but it should display 9000. Type: Number"
            />
            <text>
              Value: {values.overrideValueNumber} Type:{" "}
              {typeof values.overrideValueNumber}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Placholder"
              type="text"
              name="placholder"
              placeholder="Placholder Text"
              tooltip="There Should be Placeholder text before entering anything"
            />
            <text>
              Value: {values.placholder} Type: {typeof values.placholder}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="After Symbol"
              type="text"
              name="afterSymbol"
              afterSymbol="$"
              tooltip="There Should be a $ as the After Symbol"
            />
            <text>
              Value: {values.afterSymbol} Type: {typeof values.afterSymbol}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Before Symbol"
              type="text"
              name="beforeSymbol"
              beforeSymbol="$"
              tooltip="There Should be a $ as the before Symbol"
            />
            <text>
              Value: {values.beforeSymbol} Type: {typeof values.beforeSymbol}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Bold"
              type="text"
              name="bold"
              bold
              tooltip="Should be bold"
            />
            <text>
              Value: {values.bold} Type: {typeof values.bold}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Help Text"
              type="text"
              name="helpText"
              helpText="Here is some help text"
              tooltip="Should be have help text below"
            />
            <text>
              Value: {values.helpText} Type: {typeof values.helpText}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Required"
              type="text"
              name="required"
              required
              tooltip="Should be required, (have a red astrix)"
            />
            <text>
              Value: {values.required} Type: {typeof values.required}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Alternative Tooltip"
              type="text"
              name="altTooltip"
              altTooltip={<h3>Has a different Tooltip</h3>}
            />
            <text>
              Value: {values.altTooltip} Type: {typeof values.altTooltip}
            </text>
          </Grid>
          <Grid item>
            <PercentageInput
              label="Percentage Input"
              name="percentageInput"
              tooltip="This is our special Percentage Field, it should convert what you type into a decimal, for submitting to the backend. If you enter 33, the value should be 0.33. 100% is 1.00. The type should be a string"
            />
            <text>
              Value: {values.percentageInput} Type:{" "}
              {typeof values.percentageInput}
            </text>
          </Grid>
          <Grid item>
            <Input
              label="Disabled"
              name="disabled"
              type="Text"
              disabled
              tooltip="Should be disabled"
            />
            <text>
              Value: {values.disabled} Type:
              {typeof values.disabled}
            </text>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export const DevicePlayground = () => {
  const availableDevices = useSelector(getAvailableDevices);
  const isHubConnected = useSelector(getIsHubConnected);
  const connectedPLCId = (useSelector(getConnectedPLCs) || [])[0];

  const availablePLCs = availableDevices.filter(
    device => PLC_DRIVERS.indexOf(device.deviceType) > -1,
  );

  const availableDevicesCount = availableDevices.length;
  const dispatch = useDispatch();

  const handleDisconnect = deviceId =>
    connectedPLCId && dispatch(disconnectFromDevice(deviceId));

  // disconnected -> connected
  const handleConnect = deviceId => dispatch(connectToDevice(deviceId));

  // connected -> ready
  const handleHandshake = deviceId =>
    dispatch(sendMessage(deviceId, "handshake"));

  // ready -> ready
  const handleSend = (deviceId, command) =>
    dispatch(sendMessage(deviceId, command));

  const connectedPLC = availableDevices.find(
    d => d.deviceId === connectedPLCId,
  );

  React.useEffect(() => {
    if (!isHubConnected) {
      dispatch(connectToHub());
    } else if (!availableDevicesCount) {
      dispatch(updateAvailableDevices());
    }
    // In real life, could auto connect and handshake here.
  }, [availableDevicesCount, dispatch, isHubConnected, connectedPLC]);

  return (
    <Column padding={2}>
      {availablePLCs.map(plc => {
        const isConnected = plc.status !== "disconnected";
        const isAwaitingHandshake = plc.status === "connected";
        const isReady = plc.status === "ready";
        return (
          <Paper index={plc.deviceId}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <pre>PLC {JSON.stringify(plc, null, 2)}</pre>

                <ActionButtonContainer>
                  <SlimButton
                    onClick={() => handleDisconnect(plc.deviceId)}
                    disabled={!isConnected}
                  >
                    Disconnect
                  </SlimButton>
                  <SlimButton
                    onClick={() => handleConnect(plc.deviceId)}
                    disabled={isConnected}
                  >
                    Connect
                  </SlimButton>
                  <SlimButton
                    onClick={() => handleHandshake(plc.deviceId)}
                    disabled={!isAwaitingHandshake}
                  >
                    Respond to Handshake
                  </SlimButton>
                  <SlimButton
                    disabled={!isReady}
                    onClick={() => handleSend(plc.deviceId, "D1")}
                  >
                    Gate1
                  </SlimButton>
                  <SlimButton
                    disabled={!isReady}
                    onClick={() => handleSend(plc.deviceId, "D2")}
                  >
                    Gate2
                  </SlimButton>
                  <SlimButton
                    disabled={!isReady}
                    onClick={() => handleSend(plc.deviceId, "D3")}
                  >
                    Gate3
                  </SlimButton>
                  <SlimButton
                    disabled={!isReady}
                    onClick={() => handleSend(plc.deviceId, "D4")}
                  >
                    Gate4
                  </SlimButton>
                </ActionButtonContainer>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
      <Formik initialValues={{}}>
        <InputTest />
      </Formik>
    </Column>
  );
};
