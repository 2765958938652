import { put, takeEvery, call } from "redux-saga/effects";

import { receiveReports, requestReportsError } from "actions";

import { GET_REPORTS } from "constants/actionTypes";

import { getSaleUrl } from "lib/navigation";

import { api } from "./api";

function* fetchReports(action) {
  try {
    const { saleyard, saleId } = action;
    if (saleId && saleyard) {
      const saleUrl = getSaleUrl({
        saleyard_name: saleyard,
        livestocksale_id: saleId,
      });
      const url = `${saleUrl}/reports-for-user/`;
      const reportsPromise = yield call(api.get, url);
      const reports = yield reportsPromise;
      yield put(receiveReports(reports));
    }
  } catch (e) {
    yield call(api.handleFetchError, e, "reports", action);
    yield put(requestReportsError(e.statusText));
  }
}

function* rootSaga() {
  yield takeEvery(GET_REPORTS, fetchReports);
}

export default rootSaga;
