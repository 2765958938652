import { sortBy, uniq, uniqBy } from "lodash";
import { createSelector } from "reselect";

import {
  FieldToGroupLabelDescriptionMap,
  GlobalSearchBooleanOptions,
  GlobalSearchFields,
  GlobalSearchPlaceholder,
  GlobalSearchPricingTypeOptions,
} from "constants/globalSearch";
import { SaleyardPermissions } from "constants/permissions";
import { SaleTypes } from "constants/sale";
import { userTypes } from "constants/users";

import { getAuctionPenDisplayName, sortByStartPen } from "lib/auctionPens";
import { getConsignmentCode } from "lib/consignments";
import { hasPermission } from "lib/permissions";
import {
  doesSaleLotHaveOverflowPen,
  getBuyerHashFromSaleLot,
  getSortedMarkDetails,
} from "lib/saleLot";
import {
  convertDateTimeToUTCDateTime,
  formatUTCToLocalDateTimeString,
} from "lib/timeFormats";

import {
  getPenScanLots,
  getReceivalLots,
  getActiveRole,
  getAgencies,
  getAuctionPens,
  getBusinesses,
  getCheckpoints,
  getConsignments,
  getCurrentDeploymentSalesList,
  getCurrentRoundsList,
  getCurrentSale,
  getCurrentSaleyard,
  getLabels,
  getProperties,
  getRounds,
  getSaleLots,
  getScans,
  selectAuctionPensByLane,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByDeliveryPenIdLookup,
} from "selectors";

function simpleArrayOptionValue(option) {
  return option.value.value.map(value => String(value)).join("__");
}

const selectGlobalSearchBuyerOptions = createSelector(
  [getSaleLots, getBusinesses],
  (saleLots, businesses) =>
    sortBy(
      uniq(
        Object.values(saleLots)
          .map(sl => sl.buyer_id)
          .filter(Boolean),
      ).map(buyerId => ({
        label: businesses[buyerId]?.name,
        value: {
          field: GlobalSearchFields.Buyer,
          value: buyerId,
        },
      })),
      "label",
    ),
);
const selectGlobalSearchBuyerAndBuyerWayOptions = createSelector(
  [getSaleLots, getBusinesses],
  (saleLots, businesses) =>
    sortBy(
      uniqBy(
        Object.values(saleLots)
          .filter(sl => sl.buyer_id)
          .map(sl => {
            return {
              buyerHash: getBuyerHashFromSaleLot(sl),
              buyerId: sl.buyer_id,
              buyerWay: sl.buyer_way?.name,
            };
          }),
        obj => obj.buyerHash,
      ).map(buyerHashInfo => {
        return {
          label: `${businesses[buyerHashInfo.buyerId]?.name} - ${
            buyerHashInfo.buyerWay ? buyerHashInfo.buyerWay : `(No Buyer Way)`
          }`,
          value: {
            field: GlobalSearchFields.BuyerAndBuyerWay,
            value: buyerHashInfo.buyerHash,
          },
        };
      }),

      "label",
    ),
);
const selectGlobalSearchVendorOptions = createSelector(
  [getConsignments, getBusinesses],

  (consignments, businesses) =>
    sortBy(
      uniq(
        Object.values(consignments)
          .map(sl => sl.vendor_id)
          .filter(Boolean),
      ).map(vendorId => ({
        label: businesses[vendorId]?.name,
        value: {
          field: GlobalSearchFields.Vendor,
          value: vendorId,
        },
      })),
      "label",
    ),
);
const selectGlobalSearchVendorNumberOptions = createSelector(
  [getConsignments],
  consignments =>
    sortBy(
      uniq(
        Object.values(consignments).filter(
          consignment => consignment.vendorNumber,
        ),
      ).map(consignment => ({
        label: getConsignmentCode(consignment),
        value: {
          field: GlobalSearchFields.VendorNumber,
          value: getConsignmentCode(consignment),
        },
      })),
      "label",
    ),
);
const selectGlobalSearchVendorPicOptions = createSelector(
  [getConsignments, getProperties],
  (consignments, properties) =>
    sortBy(
      uniq(
        Object.values(consignments)
          .map(consignment => consignment.vendor_property_id)
          .filter(Boolean),
      ).map(propertyId => ({
        label: `${properties[propertyId]?.PIC}${
          properties[propertyId]?.name
            ? ` (${properties[propertyId]?.name})`
            : ""
        }`,
        value: {
          field: GlobalSearchFields.VendorPic,
          value: propertyId,
        },
      })),
      "label",
    ),
);
const selectGlobalSearchBuyerPicOptions = createSelector(
  [getSaleLots, getBusinesses, getProperties],
  (saleLots, businesses, properties) =>
    sortBy(
      uniq(
        Object.values(saleLots)
          .map(sl => {
            return sl.destination_property_id;
          })
          .filter(Boolean),
      ).map(
        buyerPropertyId => ({
          label: `${properties[buyerPropertyId]?.PIC}${
            properties[buyerPropertyId]?.name
              ? ` (${properties[buyerPropertyId]?.name})`
              : ""
          }`,
          value: {
            field: GlobalSearchFields.BuyerPic,
            value: buyerPropertyId,
          },
        }),
        "label",
      ),
    ),
);

const selectGlobalSearchAgencyOptions = createSelector(
  [getAgencies, getCurrentDeploymentSalesList],
  (agencies, deploymentSales) =>
    sortBy(
      deploymentSales.map(deploymentSale => ({
        label: agencies[deploymentSale.livestock_agency_id]?.name,
        value: {
          field: GlobalSearchFields.Agency,
          value: deploymentSale.livestock_agency_id, // TODO - should this instead be deployments?
        },
      })),
      "label",
    ),
);
const selectGlobalSearchSaleRoundOptions = createSelector(
  [getCurrentRoundsList, getRounds],
  (currentRounds, rounds) =>
    sortBy(
      currentRounds.map(roundId => ({
        label: rounds[roundId]?.name,
        value: {
          field: GlobalSearchFields.SaleRound,
          value: roundId,
        },
      })),
      "label",
    ),
);

const selectGlobalSearchAuctionPenOptions = createSelector(
  [getAuctionPens, selectSaleLotIdsByAuctionPenIdLookup],
  (auctionPens, saleLotIdsByAuctionPenIdLookup) =>
    sortByStartPen(
      Object.values(auctionPens).filter(auctionPen =>
        Boolean(saleLotIdsByAuctionPenIdLookup[auctionPen.id]),
      ),
    ).map(auctionPen => ({
      label: getAuctionPenDisplayName(auctionPen),
      value: {
        field: GlobalSearchFields.AuctionPen,
        value: auctionPen.id,
      },
    })),
);

const selectGlobalSearchDeliveryPenOptions = createSelector(
  [getAuctionPens, selectSaleLotIdsByDeliveryPenIdLookup],
  (auctionPens, saleLotIdsByDeliveryPenIdLookup) =>
    sortByStartPen(
      Object.values(auctionPens).filter(auctionPen =>
        Boolean(saleLotIdsByDeliveryPenIdLookup[auctionPen.id]),
      ),
    ).map(auctionPen => ({
      label: getAuctionPenDisplayName(auctionPen),
      value: {
        field: GlobalSearchFields.DeliveryPen,
        value: auctionPen.id,
      },
    })),
);

const selectGlobalSearchLaneOptions = createSelector(
  [selectAuctionPensByLane, getRounds],
  (auctionPensByLane, rounds) =>
    Object.entries(auctionPensByLane).reduce(
      (acc, [saleRoundId, lanes]) =>
        acc.concat(
          Object.entries(lanes).map(([laneName, laneValue]) => ({
            label: `${rounds[saleRoundId]?.name}: ${laneName}`,
            value: {
              field: GlobalSearchFields.Lane,
              value: laneValue,
            },
          })),
        ),
      [],
    ),
);

const selectGlobalSearchOverflowPenOptions = createSelector(
  [getSaleLots],
  saleLots =>
    Object.values(saleLots)
      .filter(doesSaleLotHaveOverflowPen)
      .map(saleLot => ({
        label: saleLot.overflowPen,
        value: {
          field: GlobalSearchFields.OverflowPen,
          value: saleLot.id,
        },
      })),
);

// For a Saleyard Admin they have a different version of the label for each deployment - group together by name, and set the value
// to a list of label ids.
const selectGlobalSearchLabelOptions = createSelector(
  [getLabels, getSaleLots],
  (labels, saleLots) =>
    Object.values(
      Object.values(saleLots).reduce((acc, saleLot) => {
        const saleLotLabels = saleLot.labels || [];
        saleLotLabels.forEach(labelId => {
          const labelName = labels[labelId]?.name;
          if (labelName) {
            if (!acc[labelName]) {
              acc[labelName] = {
                label: labelName,
                value: {
                  field: GlobalSearchFields.Label,
                  value: [],
                },
                getOptionValue: simpleArrayOptionValue,
              };
            }
            acc[labelName].value.value.push(labelId);
          }
        });

        return acc;
      }, {}),
    ),
);

const selectGlobalSearchCheckpointOptions = createSelector(
  [getCheckpoints],
  checkpoints =>
    sortBy(Object.values(checkpoints), "timestamp").map(
      checkpoint => ({
        label: `${checkpoint.type} - ${formatUTCToLocalDateTimeString(
          convertDateTimeToUTCDateTime(checkpoint?.timestamp),
        )} ${checkpoint.comments ? `- ${checkpoint.comments}` : ""}`,
        value: {
          field: GlobalSearchFields.Checkpoint,
          value: checkpoint.timestamp,
        },
      }),
      "label",
    ),
);

const selectGlobalSearchScanOptions = createSelector([getScans], scans =>
  [
    {
      label: GlobalSearchPlaceholder,
      value: GlobalSearchPlaceholder,
      isDisabled: true,
    },
  ].concat(
    Object.values(scans).map(scan => ({
      label: `${scan.EID}${
        scan.animal?.nlis_id ? ` - ${scan.animal?.nlis_id}` : ""
      }`,
      value: {
        field: GlobalSearchFields.Scan,
        value: scan.EID,
      },
    })),
  ),
);

const selectGlobalSearchThirdPartyOptions = createSelector(
  [getSaleLots, getBusinesses],
  (saleLots, businesses) =>
    sortBy(
      uniq(
        Object.values(saleLots)
          .map(sl => sl.thirdPartyId)
          .filter(Boolean),
      ).map(thirdPartyId => ({
        label: businesses[thirdPartyId]?.name,
        value: {
          field: GlobalSearchFields.ThirdParty,
          value: thirdPartyId,
        },
      })),
      "label",
    ),
);

const selectGlobalSearchReceivalPenOptions = createSelector(
  [getConsignments],

  consignments =>
    sortBy(
      uniq(
        Object.values(consignments)
          .map(consignment => consignment.receiving_pen)
          .filter(Boolean),
      ).map(receivingPen => ({
        label: receivingPen,
        value: {
          field: GlobalSearchFields.ReceivalPen,
          value: receivingPen,
        },
      })),
      "label",
    ),
);

const selectGlobalSearchMarksOptions = createSelector(
  [getSaleLots, getReceivalLots, getPenScanLots],
  (saleLots, receivalLots, penScanLots) => {
    return sortBy(
      uniq(
        Object.values(saleLots)
          .map(saleLot => getSortedMarkDetails(saleLot))
          .concat(
            Object.values(receivalLots).map(receivalLot => receivalLot.mark),
            Object.values(penScanLots).map(penScanLot =>
              getSortedMarkDetails(penScanLot),
            ),
          ),
      ).map(mark => ({
        label: mark || "Blanks",
        value: {
          field: GlobalSearchFields.Marks,
          value: mark,
        },
      })),
      "label",
    );
  },
);

export const selectGlobalSearchOptions = createSelector(
  [
    selectGlobalSearchAgencyOptions,
    selectGlobalSearchAuctionPenOptions,
    selectGlobalSearchBuyerOptions,
    selectGlobalSearchBuyerAndBuyerWayOptions,
    selectGlobalSearchDeliveryPenOptions,
    selectGlobalSearchLabelOptions,
    selectGlobalSearchLaneOptions,
    selectGlobalSearchOverflowPenOptions,
    selectGlobalSearchSaleRoundOptions,
    selectGlobalSearchVendorOptions,
    selectGlobalSearchScanOptions,
    selectGlobalSearchVendorNumberOptions,
    selectGlobalSearchVendorPicOptions,
    selectGlobalSearchBuyerPicOptions,
    selectGlobalSearchCheckpointOptions,
    selectGlobalSearchThirdPartyOptions,
    getCurrentSale,
    selectGlobalSearchReceivalPenOptions,
    getActiveRole,
    selectGlobalSearchMarksOptions,
    getCurrentSaleyard,
  ],
  (
    agencyOptions,
    auctionPenOptions,
    buyerOptions,
    buyerAndBuyerWayOptions,
    deliveryPenOptions,
    labelOptions,
    laneOptions,
    overflowPenOptions,
    roundOptions,
    vendorOptions,
    scanOptions,
    vendorNumberOptions,
    vendorPicOptions,
    buyerPicOptions,
    checkpointOptions,
    thirdPartyOptions,
    currentSale,
    receivalPenOptions,
    activeRole,
    markOptions,
    currentSaleyard,
  ) => {
    const hasReceivalLotPermissions = hasPermission(
      currentSaleyard,
      SaleyardPermissions.featureReceivalLots,
    );
    return [
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.Agency],
        field: GlobalSearchFields.Agency,
        options: agencyOptions,
        hide: currentSale.sale_type === SaleTypes.CLEARING,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.AuctionPen],
        field: GlobalSearchFields.AuctionPen,
        options: auctionPenOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.Buyer],
        field: GlobalSearchFields.Buyer,
        options: buyerOptions,
      },
      {
        label:
          FieldToGroupLabelDescriptionMap[GlobalSearchFields.BuyerAndBuyerWay],
        field: GlobalSearchFields.BuyerAndBuyerWay,
        options: buyerAndBuyerWayOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.BuyerPic],
        field: GlobalSearchFields.BuyerPic,
        options: buyerPicOptions,
        hide: currentSale.sale_type === SaleTypes.CLEARING,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.Checkpoint],
        field: GlobalSearchFields.Checkpoint,
        options: checkpointOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.DeliveryPen],
        field: GlobalSearchFields.DeliveryPen,
        options: deliveryPenOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.ExportSites],
        field: GlobalSearchFields.ExportSites,
        options: GlobalSearchBooleanOptions(
          currentSale,
          GlobalSearchFields.ExportSites,
        ),
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.Label],
        field: GlobalSearchFields.Label,
        options: labelOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.Lane],
        field: GlobalSearchFields.Lane,
        options: laneOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.Marks],
        field: GlobalSearchFields.Marks,
        options: markOptions,
        hide: currentSale.sale_type === SaleTypes.CLEARING,
      },
      {
        label:
          FieldToGroupLabelDescriptionMap[GlobalSearchFields.Miscellaneous],
        field: GlobalSearchFields.Miscellaneous,
        options: GlobalSearchBooleanOptions(
          currentSale,
          GlobalSearchFields.Miscellaneous,
        ),
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.OverflowPen],
        field: GlobalSearchFields.OverflowPen,
        options: overflowPenOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.PricingType],
        field: GlobalSearchFields.PricingType,
        options: GlobalSearchPricingTypeOptions,
        hide: currentSale.sale_type === SaleTypes.CLEARING,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.ReceivalPen],
        field: GlobalSearchFields.ReceivalPen,
        options: receivalPenOptions,
        hide:
          currentSale.sale_type === SaleTypes.CLEARING ||
          hasReceivalLotPermissions,
      },

      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.SaleRound],
        field: GlobalSearchFields.SaleRound,
        options: roundOptions,
        hide: currentSale.sale_type === SaleTypes.CLEARING,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.Scan],
        field: GlobalSearchFields.Scan,
        maxResults: 300, // Only show scans as selectable when there are less than 300 filtered down.
        options: scanOptions,
        hide: currentSale.sale_type === SaleTypes.CLEARING,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.ThirdParty],
        field: GlobalSearchFields.ThirdParty,
        options: thirdPartyOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.Vendor],
        field: GlobalSearchFields.Vendor,
        options: vendorOptions,
        hide: activeRole.type === userTypes.BUSINESS_USER,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.VendorNumber],
        field: GlobalSearchFields.VendorNumber,
        options: vendorNumberOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[GlobalSearchFields.VendorPic],
        field: GlobalSearchFields.VendorPic,
        options: vendorPicOptions,
        hide: currentSale.sale_type === SaleTypes.CLEARING,
      },
    ].filter(option => !option.hide);
  },
);
