import React from "react";

import Badge from "components/Badge";

export function statusColumnRenderer({ value, data }) {
  if (!data || !value) {
    return null;
  }

  return (
    <Badge inline color={value}>
      {value}
    </Badge>
  );
}
