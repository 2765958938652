import { offlineActionCreator, urlCheckAndAddIdAndAction } from "actions/lib";

import { XERO_ACCOUNTING_SETTINGS } from "constants/actionTypes";

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/xero/accounting-settings/", id, action);

export const XeroAccountingSettingsAction = offlineActionCreator(
  XERO_ACCOUNTING_SETTINGS,
  null,
  urlCreator,
  false,
);
