import * as Yup from "yup";

export const getEndDateValidation = (): Yup.DateSchema => Yup.date().nullable();

const MAXIMUM_MONTHS_OF_DATA = 12;

const MAXIMUM_DAYS_OF_DATA = 95;

function getOffsetDate(
  endDate: Date,
  { years = 0, months = 0, days = 0 },
): Date {
  const startDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate(),
  );
  startDate.setFullYear(startDate.getFullYear() + years);
  startDate.setMonth(startDate.getMonth() + months);
  startDate.setDate(startDate.getDate() + days);
  return startDate;
}

export function getStartDateValidation(
  endDateFieldName: string,
  {
    isYearOptionAllowed = false,
    maxMonthsOfData = MAXIMUM_MONTHS_OF_DATA,
    maxDaysOfData = MAXIMUM_DAYS_OF_DATA,
  },
): Yup.DateSchema {
  return Yup.date()
    .nullable()
    .when(endDateFieldName, (endDate: Date | null, schema) => {
      if (!endDate) {
        return schema;
      }
      // If we have an end date, compare the two, and make sure it's less than allowed for the report type
      schema = schema
        .required("Date is required")
        .test(
          "lt-inclusive",
          "Start date must be on or before the End date",
          startDate => startDate <= endDate,
        );

      if (isYearOptionAllowed) {
        return schema.min(
          getOffsetDate(endDate, { months: -maxMonthsOfData }),
          `Maximum date range of ${maxMonthsOfData} months.`,
        );
      }
      return schema.min(
        getOffsetDate(endDate, { days: -maxDaysOfData }),
        `Maximum date range of ${maxDaysOfData} days.`,
      );
    });
}
