import flatten from "lodash/flatten";
import { createSelector } from "reselect";

import { getGlobalSearchBySearchField } from "selectors/settings";

// Note - when passing in related object sets ... eg saleLots for consignments - we are excluding those that do not yet have this foreign object (eg a consignment with no lots)
// This creates a bit of a false dichotomy (eg show me all consignments not weighed - if they're not drafted they're not weighed... but they're not not weighed, because there's nothing
// to weigh.
export function rawObjectFilterSelectorFactory(
  rawObjectSelector,
  globalSearchField,
  unfilteredSelector,
  comparator,
  idField = "id",
) {
  return createSelector(
    [
      rawObjectSelector,
      getGlobalSearchBySearchField(globalSearchField),
      unfilteredSelector,
    ],
    (rawObjects, searchFieldValue, unfilteredValues) =>
      searchFieldValue === null
        ? unfilteredValues
        : Object.values(rawObjects)
            .filter(comparator(searchFieldValue))
            .map(rawObject => rawObject[idField]),
  );
}

export function nestedRawObjectFilterSelectorFactory(
  /**
   * This handles climbing into one selector filtering then retrieving filtered ids from another
   *
   * an example is selectBuyerFilteredNominationIds
   *
   * rawObjectSelector = getSaleLots,
   * globalSearchField = GloablSearchFields.Buyer,
   * unfilteredSelector = selectUnfilteredNominationIds,
   * comparator = buyerIds => saleLot => buyerIds.includes(saleLot.buyer_id)
   * idField = "nominationId"
   * nestedSelector = getConsignments
   * nestedSelectorId = "consignment_id"
   *
   * if no search value is provided we'll show the unfiltered Selector values (all nomination ids)
   *
   * otherwise we're grabbing salelots, filtering them by the comparator by passing in the search value
   * then returning a list of each salelot.consignment.nominationId
   *
   * rawObject => nestedSelect[rawObject[nestedSelectorId]][idField]
   * ie: saleLot => consignments[saleLot["consignment_id"]]["nominationId"]
   *
   */
  rawObjectSelector,
  globalSearchField,
  unfilteredSelector,
  comparator,
  idField = "id",
  nestedSelector,
  nestedSelectorId,
) {
  return createSelector(
    [
      rawObjectSelector,
      getGlobalSearchBySearchField(globalSearchField),
      unfilteredSelector,
      nestedSelector,
    ],
    (rawObjects, searchFieldValue, unfilteredValues, nestedSelect) =>
      searchFieldValue === null
        ? unfilteredValues
        : Object.values(rawObjects)
            .filter(comparator(searchFieldValue))
            .map(
              rawObject => nestedSelect[rawObject[nestedSelectorId]][idField],
            ),
  );
}

export function lookupFilterSelectorFactory(
  lookupSelector,
  globalSearchField,
  unfilteredSelector,
  comparator,
) {
  return createSelector(
    [
      lookupSelector,
      getGlobalSearchBySearchField(globalSearchField),
      unfilteredSelector,
    ],
    (lookup, searchFieldValue, unfilteredValues) =>
      searchFieldValue === null
        ? unfilteredValues
        : Object.entries(lookup)
            .filter(comparator(searchFieldValue))
            .map(([id, ignored]) => id),
  );
}

export function lookupNestedFilterSelectorFactory(
  lookupSelector,
  globalSearchField,
  unfilteredSelector,
  comparator,
  nestedSelector,
  nestedIdField = "id",
) {
  return createSelector(
    [
      lookupSelector,
      getGlobalSearchBySearchField(globalSearchField),
      unfilteredSelector,
      nestedSelector,
    ],
    (lookup, searchFieldValue, unfilteredValues, nestedSelect) =>
      searchFieldValue === null
        ? unfilteredValues
        : Object.entries(lookup)
            .filter(comparator(searchFieldValue))
            .map(([id, ignored]) => nestedSelect[id][nestedIdField]),
  );
}

export function isObjectChangedAfter({ created, last_modified }, checkpoint) {
  return (
    (created && new Date(checkpoint) < new Date(created)) ||
    (last_modified && new Date(checkpoint) < new Date(last_modified))
  );
}

export const filterAndMapObjectIdsWithLookup = (
  getObject,
  globalSearchField,
  objectKey,
  mapFunction,
  getLinkedObject,
  lookupFunction,
  additionalMappingFunction,
) =>
  createSelector(
    [
      getObject,
      getGlobalSearchBySearchField(globalSearchField),
      mapFunction,
      lookupFunction,
      getLinkedObject,
    ],
    (object, seachField, mappedIds, lookup, linkedObject) =>
      seachField === null
        ? mappedIds
        : flatten(
            Object.values(object)
              .filter(object => lookup[object.id] === seachField[0])
              .map(obj => {
                return additionalMappingFunction
                  ? additionalMappingFunction(obj, objectKey, linkedObject)
                  : obj[objectKey];
              }),
          ),
  );
