import React from "react";

import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { PropTypes } from "prop-types";

import IconTextButton from "./IconTextButton";

const HideShowButton = ({ isOpen, onToggle, text }) => {
  return (
    <IconTextButton
      dataTour={`${isOpen ? "hide" : "show"}${text || ""}`}
      onClick={onToggle}
      icon={isOpen ? faMinus : faPlus}
    >
      {`${isOpen ? "Hide" : "Show"}${text ? ` ${text}` : ""}`}
    </IconTextButton>
  );
};

HideShowButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  text: PropTypes.string,
};

export default React.memo(HideShowButton);
