import React from "react";

import { uniqBy } from "lodash";
import { connect } from "react-redux";
import styled from "styled-components/macro";

import { acceptImportPendingScans, readSavedScans } from "actions";

import { Column, Row } from "components/Layout";
import CheckboxItem from "components/ScanningScreen/CheckboxItem";
import {
  ButtonContainer,
  ButtonStyled,
  TotalTextContainer,
} from "components/ScanningScreen/Elements";
import { MediumText } from "components/Text";

import { scannerMessages } from "constants/scanner";

import { formatLocalTimestampToDateTimeString } from "lib/timeFormats";

import {
  getConnectedDeviceId,
  getImportPendingScans,
  getIsImportRunning,
  getScannerCapabilities,
  getScans,
} from "selectors";

const getSubLabel = scans => {
  if (!scans) {
    return null;
  }
  const firstScanTime = formatLocalTimestampToDateTimeString(scans[0].scanTime);
  const lastScanTime = formatLocalTimestampToDateTimeString(
    scans[scans.length - 1].scanTime,
  );
  return `${firstScanTime}${
    firstScanTime !== lastScanTime ? ` to ${lastScanTime}` : ""
  }`;
};

const getSelectedText = selections => {
  if (selections.length) {
    const groupCount = uniqBy(selections, "groupName").length;
    const eidCount = uniqBy(selections, "eid").length;
    return `${eidCount} animals selected from ${groupCount} groups`;
  } else {
    return "No animals selected";
  }
};

const SelectedText = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const ImportText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.delta}px;
`;

const GroupList = styled.div`
  width: 30%;
`;

class ImportView extends React.Component {
  state = {
    selectedEids: [],
    visibleGroup: null,
  };

  componentDidMount() {
    const { connectedDeviceId, readSavedScans, scannerCapabilities } =
      this.props;
    if (
      connectedDeviceId &&
      scannerCapabilities.includes(scannerMessages.READ_SAVED_SCANS)
    ) {
      readSavedScans(connectedDeviceId);
    }
  }

  importScans = () => {
    const { acceptImportPendingScans, connectedDeviceId, onEidsSelected } =
      this.props;

    const { selectedEids } = this.state;

    acceptImportPendingScans(connectedDeviceId, selectedEids);
    typeof onEidsSelected === "function" && onEidsSelected();
  };

  selectGroup = visibleGroup => {
    this.setState({ visibleGroup });
  };

  onClickEid = (eid, groupName) => {
    const { selectedEids } = this.state;
    const selectionIndex = selectedEids.findIndex(
      selection => selection.eid === eid && selection.groupName === groupName,
    );
    const newSelectedEids = selectedEids.slice();
    if (selectionIndex === -1) {
      newSelectedEids.unshift({ eid, groupName });
    } else {
      newSelectedEids.splice(selectionIndex, 1);
    }
    this.setState({ selectedEids: newSelectedEids });
  };

  onClickSelectGroup = groupName => {
    const { importPendingScans } = this.props;
    const { selectedEids } = this.state;

    const alreadySelected = [];
    const unselected = [];
    // Get the list of scans from the pending group which aren't already selected

    importPendingScans[groupName].forEach(scan => {
      const existingSelectionIndex = selectedEids.findIndex(
        selection =>
          selection.eid === scan.EID && selection.groupName === groupName,
      );
      if (existingSelectionIndex > -1) {
        alreadySelected.push(existingSelectionIndex);
      } else {
        unselected.push(scan);
      }
    });
    let newSelectedEids = [];
    if (!unselected.length) {
      // When all are already selected, unselect all
      newSelectedEids = selectedEids.filter(
        (ignored, i) => !alreadySelected.includes(i),
      );
    } else {
      // When none or some are selected, select all
      newSelectedEids = selectedEids.concat(
        unselected.map(scan => ({ eid: scan.EID, groupName })),
      );
    }
    this.setState({ selectedEids: newSelectedEids });
  };

  render() {
    const { importPendingScans, importRunning, saleLotScans } = this.props;
    const { selectedEids, visibleGroup } = this.state;

    const groupNames = Object.keys(importPendingScans);

    // Sort the group names by their most recent scan (desc)
    groupNames.sort(
      (a, b) =>
        // scans are already sorted by desc by scanTime, and empty sessions are filtered out on ingestion
        importPendingScans[b][0].scanTime - importPendingScans[a][0].scanTime,
    );

    const informationMessage = importRunning
      ? "Loading saved scans from scanner..."
      : groupNames.length
        ? null
        : "No scans to import";
    return (
      <>
        <Column flexGrow overflow="hidden">
          <Column padding="2">
            <ImportText>Import from scanner</ImportText>
            <div>Tick the groups or individual EIDs to import</div>
          </Column>
          {informationMessage ? (
            <Column padding="2">
              <TotalTextContainer>{informationMessage}</TotalTextContainer>
            </Column>
          ) : (
            <Row flexGrow overflow="hidden">
              <GroupList overflow="scroll">
                {groupNames.map(group => {
                  const selectedInGroup = selectedEids.filter(
                    selection => selection.groupName === group,
                  );
                  const importPendingGroup = importPendingScans[group];
                  return (
                    <CheckboxItem
                      key={group}
                      checked={
                        selectedInGroup.length === importPendingGroup.length
                      }
                      highlighted={group === visibleGroup}
                      onClick={() => this.selectGroup(group)}
                      onChange={() => this.onClickSelectGroup(group)}
                      label={group}
                      subLabel={getSubLabel(importPendingGroup)}
                    />
                  );
                })}
              </GroupList>
              <Column overflow="scroll" flexGrow>
                {visibleGroup &&
                  importPendingScans[visibleGroup].map((scan, i) => {
                    const selection = selectedEids.find(
                      selection =>
                        selection.eid === scan.EID &&
                        selection.groupName === visibleGroup,
                    );
                    const eidInSystem = Boolean(saleLotScans[scan.EID]);
                    const label = (
                      <span>
                        {!eidInSystem && (
                          <MediumText success>(New) </MediumText>
                        )}
                        {scan.EID}
                      </span>
                    );
                    return (
                      <CheckboxItem
                        key={`${scan.EID}_${i}`}
                        label={label}
                        subLabel={formatLocalTimestampToDateTimeString(
                          scan.scanTime,
                        )}
                        scanItem
                        onChange={() => this.onClickEid(scan.EID, visibleGroup)}
                        onClick={() => this.onClickEid(scan.EID, visibleGroup)}
                        checked={Boolean(selection)}
                      />
                    );
                  })}
              </Column>
            </Row>
          )}
        </Column>
        <ButtonContainer>
          <SelectedText>{getSelectedText(selectedEids)}</SelectedText>
          <ButtonStyled
            onClick={this.importScans}
            disabled={selectedEids.length === 0}
          >
            Select
          </ButtonStyled>
        </ButtonContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    connectedDeviceId: getConnectedDeviceId(state),
    importPendingScans: getImportPendingScans(state),
    importRunning: getIsImportRunning(state),
    saleLotScans: getScans(state),
    scannerCapabilities: getScannerCapabilities(state),
  };
};

const mapDispatchToProps = {
  acceptImportPendingScans,
  readSavedScans,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportView);
