import React, { useRef, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import xor from "lodash/xor";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Button from "components/Button";
import { Row, Column } from "components/Layout";
import { MarkingModal } from "components/MarkingModal";

import { AuctionScreenMode } from "constants/auctionModes";
import { saleLotStatuses } from "constants/sale";
import { SplitType } from "constants/saleLots";
import { Settings } from "constants/settings";

import { isSoldOrNoSale } from "lib/auctionPens";
import { openSplitSaleLotModal } from "lib/navigation";
import { isNoSale } from "lib/saleLot";

import { getSetting } from "selectors";

import { useTheme } from "hooks";

import SaleLotSummaryCard from "./SaleLotSummaryCard";
import { UnsoldGrid, StyledHeadcount, SoldGrid } from "./UnsoldGrid";

const ActionButtonsWrapper = styled.div`
  padding: 12px;
  height: 76px;
  border-top: 1px solid rgba(200, 200, 200, 0.59);
  background-color: #f2f2f2;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 0px;
`;

const ActionButton = styled(Button)`
  font-size: 24px;
  font-weight: 300;
  line-height: 48px;
  flex: 1;
  ${({ disabled }) => disabled && "cursor: default;"};
`;

const SalelotWrapper = styled(Row)`
  padding: 6px 0;
  max-height: 98px;
  justify-content: center;
  align-items: center;
`;

const Separator = styled(Row)`
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  border-bottom: 1px solid #c2c2c2;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const orderByModeLookup = {
  [AuctionScreenMode.Selling]: [
    "markOrder",
    "sex.name",
    "marks.location",
    "lot_number",
  ],
  [AuctionScreenMode.Weigh]: [
    "timeWeighed",
    "markOrder",
    "sex.name",
    "marks.location",
    "lot_number",
  ],
  [AuctionScreenMode.LastSeen]: [
    "lastSeenAtTime",
    "markOrder",
    "sex.name",
    "marks.location",
    "lot_number",
  ],
};

function SaleLots(props) {
  const {
    saleLots,
    defaultSaleState,
    setSelectedSaleLots,
    selectedSaleLotIds,
    selectSaleLot,
  } = props;

  const mode = useSelector(getSetting(Settings.auctionScreenMode));

  return orderBy(saleLots, orderByModeLookup[mode]).map(saleLot => {
    const selected = selectedSaleLotIds.includes(saleLot.id);

    const saleState = isNoSale(saleLot.vendor_id, saleLot.buyer_id)
      ? saleLotStatuses.NO_SALE
      : defaultSaleState;

    return (
      <SalelotWrapper
        key={saleLot.id}
        data-id={saleLot.id}
        onClick={selectSaleLot}
        data-tour="saleLot"
      >
        <SaleLotSummaryCard
          saleState={saleState}
          selected={selected}
          selectable
          setSelectedSaleLots={setSelectedSaleLots}
          saleLots={[saleLot]}
        />
      </SalelotWrapper>
    );
  });
}

export function SaleLotsView(props) {
  const { selectedSaleLotIds, setSelectedSaleLots, saleLots, toggleView } =
    props;
  const [isVisibleMarkingPicker, setVisibleMarkingPicker] = useState(false);

  const saleLotsSold = saleLots.filter(isSoldOrNoSale);

  const saleLotsAvailable = saleLots.filter(
    saleLot => !isSoldOrNoSale(saleLot),
  );

  const refMarkButton = useRef(null);

  const selectedSaleLots = saleLots.filter(saleLot =>
    selectedSaleLotIds.includes(saleLot.id),
  );

  const selectSaleLot = event => {
    const { id } = event.currentTarget.dataset;

    const selected = xor(selectedSaleLotIds, [id]);

    if (saleLotsSold.find(s => selected.includes(s.id))) {
      setSelectedSaleLots([id]);
    } else {
      setSelectedSaleLots(selected);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints[0]}px)`);

  const disableMarking = selectedSaleLotIds.length !== 1;
  const disableSplit = sumBy(selectedSaleLots, "quantity") < 2;
  const disableSell = selectedSaleLotIds.length === 0;

  const allSelected = selectedSaleLotIds.length === saleLotsAvailable.length;

  const toggleAllSelected = () => {
    const allAvailable = saleLotsAvailable.map(s => s.id);
    allSelected ? setSelectedSaleLots([]) : setSelectedSaleLots(allAvailable);
  };

  function onClickSplitLot() {
    openSplitSaleLotModal(selectedSaleLotIds[0], SplitType.STANDARD, true);
  }

  return (
    <Column>
      <Column style={{ padding: "1rem" }}>
        {!!saleLotsAvailable.length && (
          <>
            <Separator>NOT SOLD</Separator>
            <UnsoldGrid>
              <Button onClick={toggleAllSelected}>
                {allSelected ? "None" : "All"}
              </Button>
              <div>Vendor</div>
              <div>Ven#</div>
              <div>Lot#</div>
              <div>Sex</div>
              <div>Marks</div>
              <StyledHeadcount>Head</StyledHeadcount>
            </UnsoldGrid>
            <SaleLots
              saleLots={saleLotsAvailable}
              defaultSaleState={saleLotStatuses.NOT_SOLD}
              setSelectedSaleLots={setSelectedSaleLots}
              selectedSaleLotIds={selectedSaleLotIds}
              selectSaleLot={selectSaleLot}
            />
          </>
        )}
        {!!saleLotsSold.length && (
          <>
            <Separator>SOLD</Separator>
            <SoldGrid>
              <div />
              {!isMobile && <div>Vendor</div>}
              <div>Ven#</div>
              <div>Lot#</div>
              <div style={{ textAlign: "center" }}>Sex</div>
              <div>Marks</div>
              <StyledHeadcount>Head</StyledHeadcount>
              {!isMobile ? (
                <>
                  <div>Buyer</div>
                  <div>Way</div>
                </>
              ) : null}
              <div>Price</div>
            </SoldGrid>
            <SaleLots
              saleLots={saleLotsSold}
              defaultSaleState={saleLotStatuses.SOLD}
              setSelectedSaleLots={setSelectedSaleLots}
              selectedSaleLotIds={selectedSaleLotIds}
              selectSaleLot={selectSaleLot}
            />
          </>
        )}
      </Column>

      <ActionButtonsWrapper>
        {isVisibleMarkingPicker && (
          <MarkingModal
            onClose={() => setVisibleMarkingPicker(false)}
            saleLot={selectedSaleLots[0]}
            anchorEl={refMarkButton}
          />
        )}

        <ActionButton
          ref={refMarkButton}
          onClick={() => setVisibleMarkingPicker(true)}
          disabled={disableMarking}
        >
          Mark
        </ActionButton>
        <ActionButton
          data-tour="split"
          disabled={disableSplit}
          onClick={onClickSplitLot}
        >
          Split
        </ActionButton>
        <ActionButton
          onClick={toggleView}
          data-tour="sell"
          disabled={disableSell}
        >
          {saleLotsSold.find(s => s.id === selectedSaleLotIds[0])
            ? "Edit"
            : "Sell"}
        </ActionButton>
      </ActionButtonsWrapper>
    </Column>
  );
}

SaleLotsView.propTypes = {
  saleLots: PropTypes.array,
  selectedSaleLotIds: PropTypes.array,
  setSelectedSaleLots: PropTypes.func,
};
