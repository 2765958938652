import React, { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { setSetting } from "actions";

import { OptionToggler } from "components/OptionToggler";
import { ReactSelect } from "components/SearchableSelector";

import { Settings } from "constants/settings";

import {
  getCurrentAgenciesOptions,
  getSaleRoundsOptions,
  getSetting,
} from "selectors";

const ViewSelector = styled(ReactSelect)`
  border-radius: 0px;
  min-width: 190px;
`;
export const ConnectedSelector = ({
  includeAll,
  onAfterChanged,
  optionsSelector,
  setting,
}) => {
  const value = useSelector(getSetting(setting));
  const options = useSelector(optionsSelector(includeAll));
  const dispatch = useDispatch();
  const onChange = useCallback(
    value => {
      dispatch(setSetting(setting, value));
      typeof onAfterChanged === "function" && onAfterChanged(value);
    },
    [dispatch, onAfterChanged, setting],
  );
  const onSelect = useCallback(
    selectedOption => onChange(selectedOption.value),
    [onChange],
  );

  useEffect(() => {
    if (!options.map(o => o.value).includes(value)) {
      onSelect(options[0]);
    }
  }, [onSelect, options, value]);

  return options.length <= 3 ? (
    <OptionToggler
      onChange={onChange}
      options={options}
      value={value}
      name={setting}
    />
  ) : (
    <ViewSelector
      options={options}
      onChange={onSelect}
      value={options.find(v => v.value === value)}
      menuPortalTarget={document.body}
    />
  );
};

export const CurrentAgencySelector = ({ includeAll, onAfterChanged }) => (
  <ConnectedSelector
    includeAll={includeAll}
    optionsSelector={getCurrentAgenciesOptions}
    setting={Settings.agencyId}
    onAfterChanged={onAfterChanged}
  />
);

export const RoundSelector = ({ includeAll, onAfterChanged }) => (
  <ConnectedSelector
    data-tour="roundSelector"
    includeAll={includeAll}
    optionsSelector={getSaleRoundsOptions}
    setting={Settings.round}
    onAfterChanged={onAfterChanged}
  />
);
