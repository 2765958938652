import React, { memo, useEffect } from "react";

import { faBalanceScale, faUserEdit } from "@fortawesome/pro-duotone-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  IndicatorReadout,
  LiveWeight,
} from "containers/WeightIndicator/IndicatorReadout";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { getSecondsSince } from "lib/timeFormats";

import { getAuctionPenById, getBusinessById, getScanByEid } from "selectors";

import { AnimalDetailsPopover, PopoverLocation } from "./AnimalDetailPopover";

const SmallIndicatorReadoutGrid = styled(Grid)`
  font-size: 0.5em;
`;

function TimeSinceRecorded({ totalMassGramsRecorded }) {
  // force a refresh every second.
  const invokeRerender = React.useState(null)[1];
  useEffect(() => {
    const timer = setInterval(() => {
      invokeRerender(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [invokeRerender]);

  return (
    <span>
      {totalMassGramsRecorded
        ? `${getSecondsSince(totalMassGramsRecorded)}`
        : "-"}
    </span>
  );
}
function AnimalDetailsComponent(props) {
  const {
    destinationPenId,
    popoverLocation,
    draftPenId,
    eid,
    buyerId,
    buyerWayName,
    totalMassGrams = null,
    totalMassGramsRecorded = null,
    overrideWeight,
    areMultipleEidsDetected,
  } = props;

  const scan = useSelector(getScanByEid(eid));

  const { animal, sale_lot_id: saleLotId } = scan || {};
  const { nlis_id: nlisId } = animal || {};

  const draftPenName = getAuctionPenDisplayName(
    useSelector(getAuctionPenById(draftPenId)),
  );

  const destinationPenName = getAuctionPenDisplayName(
    useSelector(getAuctionPenById(destinationPenId)),
  );

  const buyerName =
    useSelector(state => getBusinessById(buyerId)(state)?.name) || "";

  const isPopoverVisible = Boolean(eid && saleLotId);

  const isUnknownAnimalWarningVisible = Boolean(eid && !scan);

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={6} container>
          <TimeSinceRecorded totalMassGramsRecorded={totalMassGramsRecorded} />
        </Grid>
        <SmallIndicatorReadoutGrid
          item
          xs={6}
          container
          justifyContent="center"
        >
          <IndicatorReadout />
        </SmallIndicatorReadoutGrid>

        <Grid item xs={10}>
          {totalMassGrams === null ? (
            <IndicatorReadout />
          ) : (
            <LiveWeight
              weightGrams={totalMassGrams}
              icon={overrideWeight ? faUserEdit : faBalanceScale}
              color="primary"
              title="Recorded weight."
            />
          )}
        </Grid>
        <Grid item container xs={2} alignItems="center">
          {popoverLocation === PopoverLocation.WITH_WEIGHT &&
            isPopoverVisible && <AnimalDetailsPopover eid={eid} />}
        </Grid>
      </Grid>
      <Box alignItems="center" display="flex">
        <b>Draft Pen:&nbsp;</b>
        {draftPenId ? draftPenName : <CircularProgress size={14} />}
      </Box>
      <Box alignItems="center" display="flex">
        <b>{nlisId ? "NLIS ID" : "EID"}:&nbsp;</b>
        {eid ? (
          // When there is an EID:
          // if there is an NLIS ID, show "<NLIS ID> (<EID>)" (when this EID is a rescan)
          // otherwise just show "<EID>" (when this EID is a new scan)
          `${nlisId ? `${nlisId} (${eid})` : eid}`
        ) : (
          <CircularProgress size={14} />
        )}
        {isUnknownAnimalWarningVisible && [
          " ",
          <FontAwesomeIcon key={0} icon={faExclamationTriangle} />,
          " New EID",
        ]}
        {areMultipleEidsDetected && [
          " ",
          <FontAwesomeIcon key={0} icon={faExclamationTriangle} />,
          " Multiple EIDs read",
        ]}
      </Box>

      <Box alignItems="center" display="flex">
        <b>Destination Pen:&nbsp;</b>{" "}
        {destinationPenId ? destinationPenName : ""}
        {isUnknownAnimalWarningVisible && [
          " ",
          <FontAwesomeIcon key={0} icon={faExclamationTriangle} />,
          " Not found (new EID)",
        ]}
      </Box>

      <Box alignItems="center" display="flex">
        <b>Buyer:&nbsp;</b> {buyerId ? buyerName : ""} {buyerWayName || ""}
        {isUnknownAnimalWarningVisible && [
          " ",
          <FontAwesomeIcon key={0} icon={faExclamationTriangle} />,
          " Not found (new EID)",
        ]}
      </Box>

      {popoverLocation === PopoverLocation.END && isPopoverVisible && (
        <Box alignItems="center" display="flex">
          <AnimalDetailsPopover eid={eid} />
        </Box>
      )}
    </>
  );
}

AnimalDetailsComponent.propTypes = {
  destinationPenId: PropTypes.string,
  popoverLocation: PropTypes.number,
  draftPenId: PropTypes.string,
  eid: PropTypes.string,
  buyerId: PropTypes.string,
  totalMassGrams: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  areMultipleEidsDetected: PropTypes.bool,
};

export default memo(AnimalDetailsComponent);
