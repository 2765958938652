import React, { useMemo } from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { BillingDocumentAction } from "actions";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { BillingDocumentForm } from "components/Billing/BillingDocument/Form";
import { ValidationSchema } from "components/Billing/BillingDocument/validationSchema";
import { Button, SecondaryButton } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { AuditLogTypes } from "constants/auditLog";
import { BillingDocumentType } from "constants/billingDocuments";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import { DeploymentPermissions } from "constants/permissions";

import { deepObjectChanges } from "lib/compare";

import {
  getActiveLivestockAgentDeployment,
  getBillingDocumentById,
  getIsReadOnlyByBillingDocumentId,
} from "selectors";

import { useHasDeploymentPermission, useModalAdapter } from "hooks";

export function BillingDocumentModalAdapter(props) {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.EditBillingDocument,
    props,
  );

  const { id: billingDocumentId, saleId } = hashParams;

  const hasBillingRunFeaturePermission = useHasDeploymentPermission(
    DeploymentPermissions.featureBillingRun,
  );

  if (!hasBillingRunFeaturePermission) {
    return null;
  }

  return (
    <WaitForSync
      requiredData={[
        ApiModel.BILLING_RUNS,
        ApiModel.BILLING_DATA,
        ApiModel.BILLING_DOCUMENTS,
      ]}
    >
      <BillingDocumentModal
        billingDocumentId={billingDocumentId}
        onClose={onClose}
        returnTo={returnTo}
        saleId={saleId}
      />
    </WaitForSync>
  );
}

export function Footer(props) {
  const { onClose, billingDocumentId } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);

  // We have no deeper permissions on a billing document - it's all billing run driven - so
  // if they're here, we're letting them update it.
  const isReadOnly = useSelector(
    getIsReadOnlyByBillingDocumentId(billingDocumentId),
  );

  useSubmitHandler(false, setIsSubmitEnabled);

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      <Button type="submit" disabled={!isSubmitEnabled || isReadOnly}>
        Save
      </Button>
    </>
  );
}

export function BillingDocumentModal(props) {
  const { billingDocumentId, onClose, saleId } = props;
  const dispatch = useDispatch();
  const billingDocument = useSelector(
    getBillingDocumentById(billingDocumentId),
  );
  const { deploymentSettings } =
    useSelector(getActiveLivestockAgentDeployment) || {};
  const documentType = billingDocument.type;

  const defaultDetailsText = useMemo(() => {
    if (documentType === BillingDocumentType.RCTI) {
      return deploymentSettings?.rctiSettings?.detailsText;
    } else {
      return deploymentSettings?.taxInvoiceSettings?.detailsText;
    }
  }, [documentType, deploymentSettings]);

  const initialValues = useMemo(
    () => ({
      ...billingDocument,
      detailsText: billingDocument.detailsText
        ? billingDocument.detailsText
        : defaultDetailsText,
    }),
    [billingDocument, defaultDetailsText],
  );

  const prefillApplied = useMemo(
    () => initialValues.detailsText === defaultDetailsText,
    [defaultDetailsText, initialValues],
  );

  function onSubmit(values) {
    // If we have never entered a value for details text, but have edited other values in the form
    // leave the detail text as blank (don't set it as the prefilled value!)
    if (
      !billingDocument.detailsText &&
      values.detailsText === initialValues.detailsText
    ) {
      values.detailsText = "";
    }

    const patch = {
      ...deepObjectChanges(initialValues, values),
      id: billingDocumentId,
    };

    dispatch(
      BillingDocumentAction.update(patch, {
        changeReason: "Updated from Edit Billing Document modal",
      }),
    );

    onClose();
  }

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>
        <AuditLogLink
          auditLogType={AuditLogTypes.BILLING_DOCUMENT}
          dataId={billingDocumentId}
          returnTo={window.location.hash}
        />
        &nbsp;Edit Billing Document
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
      >
        <Form data-tour="billing-document-form">
          <DialogContent dividers>
            <BillingDocumentForm
              billingDocumentId={billingDocumentId}
              saleId={saleId}
              prefillApplied={prefillApplied}
            />
          </DialogContent>
          <DialogActions>
            <Footer billingDocumentId={billingDocumentId} onClose={onClose} />
          </DialogActions>
        </Form>
      </Formik>
    </ZoomyDialog>
  );
}
