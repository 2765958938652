import React from "react";

import PropTypes from "prop-types";

import { Subtitle, BoldText } from "components/Text";

import { formatSaleLotOverflow } from "lib/saleLot";

const PenSummary = ({
  startPen,
  endPen,
  overflowPen,
  overflowQuantity,
  labelColor,
}) => (
  <>
    <Subtitle labelColor={labelColor}>Pen</Subtitle>
    <BoldText>
      {startPen || "-"}
      {endPen && ` - ${endPen}`}{" "}
      {formatSaleLotOverflow(overflowPen, overflowQuantity)}
    </BoldText>
  </>
);

PenSummary.propTypes = {
  startPen: PropTypes.string,
  endPen: PropTypes.string,
  overflowPen: PropTypes.string,
  overflowQuantity: PropTypes.number,
  labelColor: PropTypes.string,
};

export default PenSummary;
