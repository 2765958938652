import React, { memo, useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { StatusCard } from "components/Card";
import { CompactLotCard } from "components/LotCard/CompactLotCard";
import { Label, Text } from "components/LotCard/Elements";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import { getSaleLots, getSaleLotsBySale, getAuctionPens } from "selectors";

import {
  WeighBridgeSaleDispatchContext,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";

const RecentlyWeighedList = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: ${theme.space[0]}px;
`,
);

function RecentSoldListPlaceholder() {
  return (
    <StatusCard status="disabled">
      <Label />
      <Text />
    </StatusCard>
  );
}

export function RecentlyWeighedTableComponent(props) {
  const { recentlyWeighed } = props;

  const { updateSelectedSaleLotId } = useContext(
    WeighBridgeSaleDispatchContext,
  );

  const saleLots = useSelector(getSaleLots);
  const saleLotsBySale = useSelector(getSaleLotsBySale);
  const auctionPens = useSelector(getAuctionPens);

  const [selectedRecent, setSelectedRecent] = useState(recentlyWeighed[0]);
  useEffect(() => {
    if (recentlyWeighed.length > 0) {
      setSelectedRecent(recentlyWeighed[0]);
    }
  }, [recentlyWeighed]);

  const selectedSaleLot = saleLotsBySale.find(
    saleLot => saleLot.id === selectedRecent,
  );

  return (
    <div>
      <RecentlyWeighedList>
        {[0, 1, 2, 3, 4].map(i => {
          const saleLotId = recentlyWeighed[i];
          if (!saleLotId) {
            return <RecentSoldListPlaceholder key={i} />;
          }
          const saleLot = saleLots[saleLotId];
          if (!saleLot) {
            return <RecentSoldListPlaceholder key={i} />;
          }
          const auctionPen = auctionPens[saleLot.auction_pen_id];
          const auctionPenName = getAuctionPenDisplayName(auctionPen);

          function onClickRecentlyWeighedSaleLot() {
            setSelectedRecent(saleLotId);
            updateSelectedSaleLotId(saleLotId);
          }

          return (
            <StatusCard
              status={saleLotId === selectedRecent ? "primary" : "inactive"}
              onClick={onClickRecentlyWeighedSaleLot}
              key={`${saleLotId}_${i}`}
            >
              <Label>Pen</Label>
              <Text>{auctionPenName}</Text>
            </StatusCard>
          );
        })}
      </RecentlyWeighedList>
      {selectedSaleLot && (
        <CompactLotCard
          status={selectedRecent !== null ? "primary" : "inactive"}
          title={selectedSaleLot.vendorName}
          {...selectedSaleLot}
          showPrint
        />
      )}
    </div>
  );
}
const RecentlyWeighedTable = memo(RecentlyWeighedTableComponent);

function StateAdapter() {
  const { recentlyWeighedSaleLotIds } = useContext(WeighBridgeSaleStateContext);
  return <RecentlyWeighedTable recentlyWeighed={recentlyWeighedSaleLotIds} />;
}

export default memo(StateAdapter);
