import React from "react";

import ReactTable, { ReactTableDefaults } from "react-table";
import styled from "styled-components/macro";

const StyledReactTable = styled(ReactTable)`
  font-size: 12px;
  max-height: "600px";
`;

const column = {
  ...ReactTableDefaults.column,
  headerStyle: {
    textAlign: "left",
    border: "none",
    paddingTop: 12,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  style: { border: "none" },
};

export function SummaryReactTable({ data, columns }) {
  return (
    <StyledReactTable
      data={data}
      columns={columns}
      className="-striped -highlight"
      showPagination
      column={column}
      minRows={0}
    />
  );
}
