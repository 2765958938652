import React, { useCallback } from "react";

import { Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";

import { setSetting } from "actions";

import { getSetting } from "selectors";

export const SettingSwitch = ({ setting, label, dataTour }) => {
  const checked = useSelector(getSetting(setting));

  const dispatch = useDispatch();
  const onChange = useCallback(() => {
    dispatch(setSetting(setting, !checked));
  }, [checked, dispatch, setting]);

  return (
    <FormControlLabel
      control={
        <Switch
          data-tour={dataTour}
          checked={checked}
          onChange={onChange}
          color="primary"
        />
      }
      label={label}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
};
