import React from "react";

import { Divider, Grid } from "@material-ui/core";
import { FieldArray, getIn, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { deleteSaleLot } from "actions";

import SlimButton, { SlimSecondaryButton } from "components/Button/SlimButton";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { ProductSelectField } from "components/Form/Fields";
import { BreedField } from "components/Form/Fields/BreedField";
import {
  ControlledLifeCycleInput,
  Input,
  KilogramDisplayGramsInputProps,
  Label,
  OptionTogglerField,
  UnitPrice,
} from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { PricingTypes } from "constants/pricingTypes";
import { Species } from "constants/species";

import { calculateTotalPriceCents, formatDecimal, formatNumber } from "lib";

import { getCurrentSpeciesId, getEffectiveDeploymentId } from "selectors";

const summarizeLots = saleLots => {
  const { totalQuantity, totalWeightGrams, totalPriceCents } = saleLots.reduce(
    (acc, saleLot) => {
      acc.totalQuantity += saleLot.quantity || 0;
      acc.totalWeightGrams += +saleLot.total_mass_grams || 0;
      acc.totalPriceCents += calculateTotalPriceCents(saleLot) || 0;
      return acc;
    },
    { totalQuantity: 0, totalWeightGrams: 0, totalPriceCents: 0 },
  );

  const totalPriceDollars = totalPriceCents / 100;
  const totalKilograms = totalWeightGrams / 1000;

  const dollarsPerHead =
    totalQuantity > 0 ? totalPriceDollars / totalQuantity : 0;

  const averageWeightKg =
    totalWeightGrams > 0 && totalQuantity > 0
      ? totalKilograms / totalQuantity || 0
      : 0;

  const averageDollarsPerKilo =
    totalWeightGrams > 0
      ? formatDecimal(totalPriceDollars / totalKilograms)
      : 0;

  return {
    totalQuantity,
    totalWeightGrams,
    totalPriceCents,
    dollarsPerHead,
    averageDollarsPerKilo,
    averageWeightKg,
    totalPriceDollars,
  };
};

const SkinsSummary = () => {
  const { values } = useFormikContext();

  const { saleLots, skinsLots } = values;

  const summary = summarizeLots([...saleLots, ...skinsLots]);
  const { averageDollarsPerKilo, dollarsPerHead, totalPriceDollars } = summary;

  return (
    <>
      <Grid item xs={4}>
        <Input
          disabled
          name="averagePricePerKgSkins"
          align="right"
          beforeSymbol="$"
          label="Avg $/kg (inc skins)"
          overrideValue={averageDollarsPerKilo}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="dollarsPerHeadSkins"
          align="right"
          beforeSymbol="$"
          label="$/Hd (inc skins)"
          overrideValue={formatDecimal(dollarsPerHead)}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="totalPriceSkins"
          beforeSymbol="$"
          align="right"
          label="Total (inc skins)"
          overrideValue={formatDecimal(totalPriceDollars)}
        />
      </Grid>
    </>
  );
};

const NonSkinSummary = () => {
  const { values } = useFormikContext();

  const { saleLots } = values;

  const {
    totalQuantity,
    totalWeightGrams,
    totalPriceCents,
    dollarsPerHead,
    averageWeightKg,
    averageDollarsPerKilo,
  } = summarizeLots(saleLots);

  return (
    <>
      <Grid item xs={4}>
        <Input
          disabled
          name="total_quantity"
          align="right"
          label="Total Hd"
          overrideValue={formatNumber(totalQuantity, 0)}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="total_weight"
          align="right"
          label="Total Weight"
          afterSymbol="kg"
          overrideValue={formatNumber(totalWeightGrams / 1000)}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          disabled
          name="total_price"
          align="right"
          label="Total Price"
          beforeSymbol="$"
          overrideValue={formatNumber(totalPriceCents / 100)}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          disabled
          name="dollarsPerHead"
          align="right"
          label="$/Hd"
          beforeSymbol="$"
          overrideValue={formatNumber(dollarsPerHead)}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="averageWeight"
          align="right"
          label="Avg Weight"
          afterSymbol="kg"
          overrideValue={formatNumber(averageWeightKg)}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="averagePricePerKg"
          align="right"
          beforeSymbol="$"
          label="Avg $/kg"
          overrideValue={averageDollarsPerKilo}
        />
      </Grid>
    </>
  );
};

const SkinLotEntry = (skinsLot, index) => {
  const skinValue = calculateTotalPriceCents(skinsLot) || 0;

  return (
    <React.Fragment key={skinsLot.id || index}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={4}>
        <Input
          label={index === 0 ? "Skin Quantity" : undefined}
          name={`skinsLots[${index}]quantity`}
          required={index === 0}
          type="number"
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label={index === 0 ? "Skin Price" : undefined}
          name={`skinsLots[${index}]unitPrice`}
          beforeSymbol="$"
          required={index === 0}
          type="number"
          decimal
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          label="Skin Value"
          name="skinValue"
          beforeSymbol="$"
          disabled
          overrideValue={formatDecimal(skinValue / 100)}
          align="right"
        />
      </Grid>
    </React.Fragment>
  );
};

export function KillSheetForm({ defaultSaleLot }) {
  const formikProps = useFormikContext();

  const [confirmDeleteSaleLot, setConfirmDeleteSaleLot] = React.useState(false);
  const dispatch = useDispatch();

  const handleDeleteSaleLot = ({ saleLot, index }) => {
    dispatch(deleteSaleLot(saleLot));
    removeSaleLotAtIndex(index);
  };

  const { values } = formikProps;

  const { saleLots, skinsLots } = values;

  const removeSaleLotAtIndex = index => {
    const nextValue = values.saleLots.filter((s, i) => i !== index);
    // If there is nothing left, add the default.
    const nextOrDefault = nextValue.length === 0 ? [defaultSaleLot] : nextValue;

    formikProps.setFieldValue("saleLots", nextOrDefault);
  };

  const handleClickDelete = (saleLot, index) => {
    if (!saleLot.id) {
      removeSaleLotAtIndex(index);
    } else {
      setConfirmDeleteSaleLot({ saleLot, index });
    }
  };

  const closeConfirmDialog = () => setConfirmDeleteSaleLot(null);

  const speciesId = useSelector(getCurrentSpeciesId);
  const deploymentId = useSelector(getEffectiveDeploymentId);

  const setPricingType = pricingTypeId => {
    formikProps.setFieldValue(
      "saleLots",
      values.saleLots.map(saleLot => ({
        ...saleLot,
        pricing_type_id: pricingTypeId,
      })),
    );
  };

  return (
    <>
      <Grid xs={12} item>
        <OptionTogglerField
          name="saleLots[0].pricing_type_id"
          options={PricingTypes.all()}
          onChangeExtra={setPricingType}
        />
      </Grid>
      <FieldArray name="saleLots">
        {arrayHelpers => (
          <>
            <Grid item xs={12}>
              <Input
                label="External Ref/s"
                name="consignment.billingReference"
                maxLength={255}
              />
            </Grid>
            {saleLots.map((saleLot, i) => {
              const isPricingTypeDifferent =
                getIn(values, `saleLots[${i}]pricing_type_id`) !==
                getIn(values, `saleLots[0]pricing_type_id`);

              return (
                <React.Fragment key={saleLot.id || i}>
                  <Grid item xs={1}>
                    <Input
                      label={i === 0 && "Headcount"}
                      name={`saleLots[${i}]quantity`}
                      required={i === 0}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ControlledLifeCycleInput
                      {...KilogramDisplayGramsInputProps}
                      label={i === 0 && "Weight (kg)"}
                      name={`saleLots[${i}]total_mass_grams`}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <UnitPrice
                      name={`saleLots[${i}]unitPrice`}
                      pricingTypeField={`saleLots[${i}]pricing_type_id`}
                      showLabel={i === 0 || isPricingTypeDifferent}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ProductSelectField
                      ns={`saleLots[${i}]`}
                      hideQuickSelect
                      deploymentId={deploymentId}
                      bestMatchAutoSelect
                      label={i === 0 && "Description"}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <BreedField
                      name={`saleLots[${i}]breed_id`}
                      label={i === 0 && "Breed"}
                      speciesId={speciesId}
                      deploymentId={deploymentId}
                      showQuickCode
                    />
                  </Grid>

                  <Grid
                    item
                    // container
                    xs={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* To make it align nicer on the first row */}
                    {i === 0 && <Label>&nbsp;</Label>}
                    <SlimSecondaryButton
                      type="button"
                      color="red"
                      onClick={() => handleClickDelete(saleLot, i)}
                    >
                      Delete
                    </SlimSecondaryButton>
                  </Grid>
                </React.Fragment>
              );
            })}

            <Grid item xs={12}>
              <Row justifyEnd>
                <SlimButton
                  onClick={() =>
                    arrayHelpers.push({
                      ...saleLots[saleLots.length - 1],
                      quantity: "",
                      total_mass_grams: "",
                      cents_per_kilo: "",
                      unitPrice: "",
                      id: undefined,
                    })
                  }
                  type="button"
                >
                  Add line
                </SlimButton>
              </Row>
            </Grid>
          </>
        )}
      </FieldArray>

      <NonSkinSummary />

      {(speciesId === Species.SHEEP || skinsLots.length > 0) && (
        <>
          <FieldArray name="skinsLots">
            <>{skinsLots.map(SkinLotEntry)}</>
          </FieldArray>
          <SkinsSummary />
        </>
      )}

      <ConfirmDialog
        title={createModalTitle("this item")}
        isOpen={confirmDeleteSaleLot}
        onCancel={closeConfirmDialog}
        onDelete={() => {
          handleDeleteSaleLot(confirmDeleteSaleLot);
          closeConfirmDialog();
        }}
      />
    </>
  );
}
