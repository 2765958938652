import reduceReducers from "reduce-reducers";

import { NOMINATION_TERM } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeNominationTerm } from "lib/serializers/nominationTerm";

const fetch = apiModelOfflineFetchReducer(NOMINATION_TERM, {
  deserializer: deserializeNominationTerm,
});

const nominationTermReducer = reduceReducers(fetch);

export default nominationTermReducer;
