import { ALEIS_70X0_SCANNER_DRIVER_NAME } from "./aleis70x0";
import { ALEIS_90X0_SCANNER_DRIVER_NAME } from "./aleis90x0";
import { ALEIS_EID8500_ALLFLEX_RS420_SCANNER_DRIVER_NAME } from "./aleisEID8500";
import { ALEIS_NXM_SCANNER_DRIVER_NAME } from "./aleisNXM";
import { GENERIC_SCANNER_DRIVER_NAME } from "./genericScanner";
import { MT_HOST_FILE_SESSION_DRIVER_NAME } from "./mtHost";
import { MT_SERIAL_DRIVER_NAME } from "./mtSerial";
import { MT_TCP_DRIVER_NAME } from "./mtTcp";
import { RINSTRUM_5000_DRIVER_NAME } from "./rinstrum5000";
import { SHEARWELL_SDL440S_SCANNER_DRIVER_NAME } from "./shearwellSDL440S";
import { THOMPSON_LONGHORN_ALLEN_BRADLEY_PLC_DRIVER_NAME } from "./thompsonLonghonAllenBradleyAutoDraftPlc/tlabAutoDraftPlc";

export const WEIGHBRIDGE_DRIVERS = [
  MT_SERIAL_DRIVER_NAME,
  MT_TCP_DRIVER_NAME,
  RINSTRUM_5000_DRIVER_NAME,
];

export const SCANNER_DRIVERS = [
  GENERIC_SCANNER_DRIVER_NAME,
  ALEIS_90X0_SCANNER_DRIVER_NAME,
  ALEIS_70X0_SCANNER_DRIVER_NAME,
  ALEIS_EID8500_ALLFLEX_RS420_SCANNER_DRIVER_NAME,
  ALEIS_NXM_SCANNER_DRIVER_NAME,
  SHEARWELL_SDL440S_SCANNER_DRIVER_NAME,
];

export const PLC_DRIVERS = [THOMPSON_LONGHORN_ALLEN_BRADLEY_PLC_DRIVER_NAME];

export const MT_HOST_DRIVERS = [MT_HOST_FILE_SESSION_DRIVER_NAME];
