import React from "react";

import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { Grid, Table, TableContainer, TableHead } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { getIn, useField, useFormikContext } from "formik";
import { get, set } from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { patchSaleLot } from "actions";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { SubtleBadge } from "components/Badge";
import { SlimButton } from "components/Button";
import { ConfirmDialog } from "components/ConfirmDialog";
import { FormCollapse } from "components/Form";
import {
  Input,
  Label,
  MutuallyExclusiveInput,
  OptionTogglerField,
  StyledRow,
  withNamespace,
} from "components/Form/FormikControls";
import { Row } from "components/Layout";
import { BigText, SmallText } from "components/Text";

import {
  Accreditation,
  AccreditationOptions,
} from "constants/draftingAttributes";

import { ForCattle, ForSheep } from "containers/ForSpecies";

import { getDeclarationFields } from "lib/nvd";
import { pluralize } from "lib/pluralize";

import {
  getConsignmentById,
  getSaleLotIdsByConsignmentId,
  getSaleLots,
} from "selectors";

import { useToggle } from "hooks";

const header = (
  <Row>
    <SubtleBadge>NVD &amp; Adv. Drafting</SubtleBadge>
  </Row>
);

const getNvdAnswer = answer => {
  if (answer === true) {
    return "Yes";
  } else if (answer === false) {
    return "No";
  } else if (answer === null) {
    return "-";
  }
  return answer;
};

const NVDSection = ({ consignmentId }) => {
  const consignment = useSelector(getConsignmentById(consignmentId));

  const { declaration } = consignment || {};

  if (!declaration) {
    return <BigText>No NVD Mapped</BigText>;
  }
  const { VERSION: declarationType } = declaration;

  const questions = getDeclarationFields(declarationType);
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {questions.map((ignored, idx) => (
              <TableCell key={`nvdq_${idx}`}>Q{idx + 1}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {questions.map((question, idx) => (
              <TableCell key={`nvda_${idx}`}>
                {getNvdAnswer(declaration[question])}
              </TableCell>
            ))}
          </TableRow>
          {declaration.owned_since_birth === false ? (
            <TableRow>
              <TableCell colSpan={questions.length + 1}>
                Owned Since Birth Duration:{" "}
                {declaration.owned_since_birth_duration}
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell colSpan={questions.length + 1}>
              Additional Information: {declaration.additional_information}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const defaultMatchTest = saleLotValue => saleLotValue === Accreditation.ALL;
const defaultNoMatchTest = saleLotValue =>
  saleLotValue === Accreditation.NONE || !saleLotValue; // If the value is not set, we should probably assume it is not matching, and allow them to update it.
const defaultAnyMismatchTest = (consignmentValue, noMatchCount, matchCount) =>
  (consignmentValue === Accreditation.ALL && noMatchCount > 0) ||
  (consignmentValue === Accreditation.NONE && matchCount > 0);
const defaultExclusiveIsSetTest = value => value === Accreditation.ALL;

const SaleLotAdvancedDraftingReconcile = ({
  consignmentId,
  fieldName,
  label,
  matchTest = defaultMatchTest,
  noMatchTest = defaultNoMatchTest,
  anyMismatchedTest = defaultAnyMismatchTest,
  exclusiveIsSetTest = defaultExclusiveIsSetTest,
  mutuallyExclusiveWithFieldName = "",
  mutuallyExclusiveWithFalseValue = Accreditation.NONE,
}) => {
  const saleLotIds = useSelector(getSaleLotIdsByConsignmentId(consignmentId));
  const saleLots = useSelector(getSaleLots);
  const [{ value }] = useField(fieldName);

  const totalCount = saleLotIds?.length || 0;
  const matchCount =
    saleLotIds?.filter(saleLotId =>
      matchTest(get(saleLots[saleLotId], fieldName), value),
    ).length || 0;
  const noMatchCount =
    saleLotIds?.filter(saleLotId =>
      noMatchTest(get(saleLots[saleLotId], fieldName), value),
    ).length || 0;

  const dispatch = useDispatch();

  const [showConfirmUpdate, toggleShowConfirmUpdate] = useToggle(false);

  const anyMismatched = anyMismatchedTest(value, noMatchCount, matchCount);

  const onUpdateLots = () => {
    saleLotIds
      .filter(saleLotId => get(saleLots[saleLotId], fieldName) !== value)
      .forEach(saleLotId => {
        const patch = {
          id: saleLotId,
        };
        set(patch, fieldName, value);
        // clear any mutually exclusive field when we are setting a value, don't touch when clearing
        if (mutuallyExclusiveWithFieldName && exclusiveIsSetTest(value)) {
          set(
            patch,
            mutuallyExclusiveWithFieldName,
            mutuallyExclusiveWithFalseValue,
          );
        }
        dispatch(patchSaleLot(patch));
      });
  };

  return (
    <Row alignCenter justifyBetween>
      <FaIcon
        size="4x"
        icon={anyMismatched ? faExclamationCircle : faCheckCircle}
        color={anyMismatched ? "warning" : "success"}
      />
      <div>
        <SmallText>
          {matchCount} match, {noMatchCount} do not match, of {totalCount} total{" "}
          {pluralize("Sale Lot", totalCount)}
        </SmallText>
      </div>
      <SlimButton
        disabled={!anyMismatched}
        onClick={toggleShowConfirmUpdate}
        type="button"
        data-tour="updateLots"
      >
        Update Lots
      </SlimButton>
      <ConfirmDialog
        isOpen={showConfirmUpdate}
        onCancel={toggleShowConfirmUpdate}
        onDelete={() => {
          onUpdateLots();
          toggleShowConfirmUpdate();
        }}
        buttonMessage="Confirm"
        message={`This will update the ${label} value of all Sale Lots in this Consignment.`}
        title="Update Sale Lots"
      />
    </Row>
  );
};

const NullableAdvancedDraftingSelector = ({
  consignmentId,
  fieldName,
  label,
  tooltip,
  disabled = false,
  exclusiveIsSetTest = defaultExclusiveIsSetTest,
  mutuallyExclusiveWithIsSetTest = defaultExclusiveIsSetTest,
  mutuallyExclusiveWithFieldName = "",
  mutuallyExclusiveWithFalseValue = Accreditation.NONE,
}) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext();
  const onChangeExtra = value => {
    if (mutuallyExclusiveWithFieldName) {
      const mutuallyExclusiveWithValue = getIn(
        values,
        mutuallyExclusiveWithFieldName,
      );
      const mutuallyExclusiveIsSet = mutuallyExclusiveWithIsSetTest(
        mutuallyExclusiveWithValue,
      );
      const isSetting = exclusiveIsSetTest(value);
      if (mutuallyExclusiveIsSet && isSetting) {
        setFieldValue(
          mutuallyExclusiveWithFieldName,
          mutuallyExclusiveWithFalseValue,
        );
        setFieldTouched(mutuallyExclusiveWithFieldName);
      }
    }
  };
  return (
    <Grid data-tour={label} item xs={12}>
      <OptionTogglerField
        name={fieldName}
        label={label}
        options={AccreditationOptions}
        tooltip={tooltip}
        labelPosition="top"
        disabled={disabled}
        onChangeExtra={onChangeExtra}
      />
      <SaleLotAdvancedDraftingReconcile
        consignmentId={consignmentId}
        fieldName={fieldName}
        label={label}
        exclusiveIsSetTest={exclusiveIsSetTest}
        mutuallyExclusiveWithFieldName={mutuallyExclusiveWithFieldName}
        mutuallyExclusiveWithFalseValue={mutuallyExclusiveWithFalseValue}
      />
    </Grid>
  );
};

const withAttributeNamespace = name =>
  withNamespace("draftingAttributes", name);

const numberMatchTest = (saleLotValue, consignmentValue) =>
  saleLotValue === consignmentValue;
const numberNoMatchTest = (saleLotValue, consignmentValue) =>
  saleLotValue !== consignmentValue;
const numberAnyMismatchTest = (consignmentValue, noMatchCount) =>
  typeof consignmentValue === "number" && noMatchCount > 0;
const numberExclusiveIsSetTest = value => value !== 0;

const AdvancedDraftingSection = React.memo(
  ({ isOpen, onToggle, consignmentId, readOnly }) => {
    return (
      <FormCollapse
        isOpen={isOpen}
        onToggle={onToggle}
        header={header}
        id="collapse-drafting-attributes"
        dataTour={isOpen ? "hideAdvancedDrafting" : "showAdvancedDrafting"}
      >
        <Grid item xs={12}>
          <NVDSection consignmentId={consignmentId} readOnly={readOnly} />
        </Grid>

        <ForCattle>
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("accreditationNEE")}
            label="Never Ever"
            tooltip="Greenham NEVER EVER Beef Program.  This value is prefilled from the NEE status on the Vendor's PIC"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("vendorBredOverride")}
            label="Vendor Bred Override"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("accreditationGrassFed")}
            label="Grass Fed"
            consignmentId={consignmentId}
            disabled={readOnly}
            data-tour="grassFed"
            mutuallyExclusiveWithIsSetTest={numberExclusiveIsSetTest}
            mutuallyExclusiveWithFieldName={withAttributeNamespace(
              "grainFedDays",
            )}
            mutuallyExclusiveWithFalseValue={0}
          />
          <Grid data-tour="Grain Fed" item xs={12}>
            <StyledRow alignCenter labelPosition="top">
              <Label tooltip="Number of Days on Feed">Grain Fed</Label>
            </StyledRow>
            <Row>
              <MutuallyExclusiveInput
                name={withAttributeNamespace("grainFedDays")}
                maxLength={4}
                type="number"
                columnProps={{ fullWidth: true, alignEnd: false }}
                emptyValue={null}
                disabled={readOnly}
                mutuallyExclusiveWithName={withAttributeNamespace(
                  "accreditationGrassFed",
                )}
                mutuallyExclusiveWithFalseValue={Accreditation.NONE}
              />
            </Row>
            <SaleLotAdvancedDraftingReconcile
              consignmentId={consignmentId}
              fieldName={withAttributeNamespace("grainFedDays")}
              label="Grain Fed"
              matchTest={numberMatchTest}
              noMatchTest={numberNoMatchTest}
              anyMismatchedTest={numberAnyMismatchTest}
              exclusiveIsSetTest={numberExclusiveIsSetTest}
              mutuallyExclusiveWithFieldName={withAttributeNamespace(
                "accreditationGrassFed",
              )}
            />
          </Grid>
          <NullableAdvancedDraftingSelector
            consignmentId={consignmentId}
            data-tour="organic"
            disabled={readOnly}
            fieldName={withAttributeNamespace("accreditationOrganic")}
            label="Organic"
            tooltip="Refer to Additional Information on NVD"
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("accreditationAntibioticFree")}
            label="Antibiotic Free"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("accreditationEU")}
            label="EU Eligible"
            tooltip="European Union Cattle Accreditation Scheme.  This value is prefilled from the EU status on the Vendor's PIC"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <Grid data-tour="JBAS" item xs={12}>
            <Row>
              <Label>JBAS</Label>
            </Row>
            <Row>
              <Input
                name={withAttributeNamespace("accreditationJBAS")}
                maxLength={1}
                type="number"
                columnProps={{ fullWidth: true, alignEnd: false }}
                emptyValue={null}
                disabled={readOnly}
              />
            </Row>
            <SaleLotAdvancedDraftingReconcile
              consignmentId={consignmentId}
              fieldName={withAttributeNamespace("accreditationJBAS")}
              label="JBAS"
              matchTest={numberMatchTest}
              noMatchTest={numberNoMatchTest}
              anyMismatchedTest={numberAnyMismatchTest}
            />
          </Grid>

          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("accreditationPCAS")}
            label="PCAS"
            tooltip="Pasturefed Cattle Assurance System.  This value is prefilled from the PCAS status on the Vendor's PIC"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            consignmentId={consignmentId}
            disabled={readOnly}
            fieldName={withAttributeNamespace("stationMated")}
            label="Station Mated"
            tooltip="Refer to Additional Information on NVD"
            mutuallyExclusiveWithFieldName={withAttributeNamespace(
              "notStationMated",
            )}
          />
          <NullableAdvancedDraftingSelector
            consignmentId={consignmentId}
            disabled={readOnly}
            fieldName={withAttributeNamespace("notStationMated")}
            label="Not Station Mated"
            tooltip="Refer to Additional Information on NVD"
            mutuallyExclusiveWithFieldName={withAttributeNamespace(
              "stationMated",
            )}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("accreditationPTIC")}
            label="PTIC"
            consignmentId={consignmentId}
            disabled={readOnly}
            tooltip="Refer to Additional Information on NVD"
            mutuallyExclusiveWithFieldName={withAttributeNamespace(
              "accreditationPTE",
            )}
          />
          <NullableAdvancedDraftingSelector
            consignmentId={consignmentId}
            disabled={readOnly}
            fieldName={withAttributeNamespace("accreditationPTE")}
            label="PTE"
            tooltip="Refer to Additional Information on NVD"
            mutuallyExclusiveWithFieldName={withAttributeNamespace(
              "accreditationPTIC",
            )}
          />
          <NullableAdvancedDraftingSelector
            consignmentId={consignmentId}
            disabled={readOnly}
            fieldName={withAttributeNamespace("accreditationBackgrounded")}
            label="Backgrounded"
            tooltip="Refer to Additional Information on NVD"
            mutuallyExclusiveWithFieldName={withAttributeNamespace("feeder")}
          />
          <NullableAdvancedDraftingSelector
            consignmentId={consignmentId}
            disabled={readOnly}
            fieldName={withAttributeNamespace("feeder")}
            label="Feeder"
            tooltip="Agent Assessed as a Feeder"
            mutuallyExclusiveWithFieldName={withAttributeNamespace(
              "accreditationBackgrounded",
            )}
          />
        </ForCattle>
        <ForSheep>
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("drenched")}
            label="Drenched"
            tooltip="Animals have been drenched"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("gudairApproved")}
            label="Gudair Approved"
            tooltip="Animals have been subject to the Gudair approval process"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("backline")}
            label="Backline / Dipped"
            tooltip="Animals have been backlined or dipped"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("SAEligible")}
            label="SA Eligible"
            tooltip="Animals are SA eligible"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("sixInOne")}
            label="6 in 1"
            tooltip="Animals have been treated with 6 in 1 Vaccine"
            consignmentId={consignmentId}
            disabled={readOnly}
          />

          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("b12Vac")}
            label="B12 Vac"
            tooltip="Animals have been treated with B12 Vaccine"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("MAndTS")}
            label="M &amp; TS"
            tooltip="Animals have been mulesed and Tails Docked"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("painRelief")}
            label="Pain Relief"
            tooltip="Animals have received pain relief @ mulesing"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
          <NullableAdvancedDraftingSelector
            consignmentId={consignmentId}
            data-tour="organic"
            disabled={readOnly}
            fieldName={withAttributeNamespace("accreditationOrganic")}
            label="Organic"
            tooltip="Refer to Additional Information on NVD"
          />
          <NullableAdvancedDraftingSelector
            fieldName={withAttributeNamespace("vendorBredOverride")}
            label="Vendor Bred Override"
            consignmentId={consignmentId}
            disabled={readOnly}
          />
        </ForSheep>
        <Grid item xs={12}>
          <Input name="brands" label="Brands" />
        </Grid>
      </FormCollapse>
    );
  },
);
AdvancedDraftingSection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default AdvancedDraftingSection;
