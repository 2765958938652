import React from "react";

import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash";
import styled from "styled-components/macro";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import Clickable from "components/Button/Clickable";
import { Row } from "components/Layout";

import { NVDRelatedDocumentTypeValues } from "constants/documentTypes";

import { openAttachments } from "lib/navigation";

const WrapperImage = styled.div`
  position: relative;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  height: 24px;
`;

const plusMore = value =>
  value.length > 1 && <span>&nbsp; +{value.length - 1}</span>;

export function declarationAttachmentsRenderer(params) {
  if (!params.data) {
    return null;
  }
  const value = get(params.data, params.colDef.field) || [];

  const declarationAttachments = value.filter(a =>
    NVDRelatedDocumentTypeValues.includes(a.type),
  );

  if (declarationAttachments.length > 0) {
    const attachment = declarationAttachments[0];

    const src = attachment.thumbnail_url || attachment.image_url;

    if (attachment.isCompleted === true) {
      return (
        <Clickable
          onClick={() =>
            openAttachments({
              attachment: attachment.id,
              consignment: attachment.consignment,
            })
          }
          key={`attachment-${attachment.consignment}`}
        >
          <Row>
            <WrapperImage>
              <FaIcon icon={faCheckCircle} color="success" />
              <img alt="consignmentAttachment" height={36} src={src} />
            </WrapperImage>
            {plusMore(declarationAttachments)}
          </Row>
        </Clickable>
      );
    } else {
      return (
        <Clickable
          onClick={() =>
            openAttachments({ consignment: attachment.consignment })
          }
          key={`attachment-${attachment.consignment}`}
        >
          <Row>
            <WrapperImage>
              <FaIcon icon={faExclamationCircle} color="warning" />
              <img alt="consignmentAttachment" height={36} src={src} />
            </WrapperImage>
            {plusMore(declarationAttachments)}
          </Row>
        </Clickable>
      );
    }
  } else {
    return <FaIcon icon={faExclamationCircle} color="warning" />;
  }
}

export function imageAttachmentsRenderer(props) {
  const { data } = props;
  if (!data) {
    return null;
  }
  const value = data.attachments || [];

  if (value.length > 0) {
    return (
      <WrapperImage>
        <img alt="saleLotImage" src={value[0].thumbnail_url} />
        {plusMore(value)}
      </WrapperImage>
    );
  } else {
    return null;
  }
}
