import {
  apiResponseGetFailure,
  notifyFetchRequestRetrying,
  notifyMutateRequestRetrying,
  refreshJWT,
  sendToUserOverview,
  setConcurrentUserBlock,
  setGeoBlock,
} from "actions";

import {
  isActionFetchBulk,
  isActionMutative,
  REPORT_JOB,
  REQUEST_NEW_JWT_TOKEN_OFFLINE,
  SELL_FILE,
  TAKE_FILE,
} from "constants/actionTypes";
import { AccessBlockReasons } from "constants/auth";

import { getAuthentication, getCurrentUser } from "selectors";

import { BlockedError } from "offline/blockedError";
import { JWTError } from "offline/jwtError";
import { RequestError } from "offline/requestError";
import { fetchDecaySchedule } from "offline/retry";
import { WrongSaleError } from "offline/wrongSaleError";

import { store } from "..";

const ACTION_RETRY_BLACKLIST = [
  TAKE_FILE.CREATE_BULK.REQUEST,
  SELL_FILE.CREATE_BULK.REQUEST,
  REPORT_JOB.CREATE_BULK.REQUEST,
];

// discard
// Decides whether a request should be retried or not. Tightly coupled with config.effect.
// Receives the rejection error from config.effect, the related offline action, and the number of
// times the request has been retried. Returns or resolves to a boolean representing if the action should be discarded (as opposed to retried).
//
// Our implementation checks for a few known errors:
// - In the wrong sale - we don't want to retry that as we should never have bothered with it!
// - A JWT error - we dispatch a refresh and then retry.
// - A network error (timeout, DNS, something broken in fetch) goes through the default redux offline
// pathway - it likely means we ARE offline.
// - A specified  network error like 503, 504, bad content is hopefully transient  - we do want to retry these.
//    - A special case is a 500 - this may be "permanently" broken, so retry less.

// This also spits out  some  other actions - like "REQUEST RETRY" and "REQUEST FAILURE" which may be used
// to alert the user that things are not working

// This overrides the decay schedules in retry.js
const MAX_RETRIES_FOR_500 = 4;

export default (error, action, retries) => {
  // If they requested something from another sale, no need to continue.
  if (error instanceof WrongSaleError) {
    // eslint-disable-next-line no-console
    console.warn("Discarding action: ", error);
    return true;
  }
  if (error instanceof BlockedError) {
    if (error.errors.reason === AccessBlockReasons.CONCURRENT_USER_BLOCK) {
      store.dispatch(setConcurrentUserBlock(error.errors));
    } else if (error.errors.reason === AccessBlockReasons.GEO_BLOCK) {
      store.dispatch(
        setGeoBlock({
          error: error.errors.information,
          allowableZones: error.errors.allowable_zones || null,
        }),
      );
    }
    store.dispatch(sendToUserOverview());
    return true;
  }

  // On receiving a JWT expired (401) error, fire off a refresh.
  // This will be pushed to the front of the queue in queue.js, so we should NOT discard
  // this action - it will be retried when it re-reaches the front of the queue.
  if (error instanceof JWTError) {
    // If the action itself was trying to refresh a token, it's LIKELY the user has been removed, or something similar.
    // A 401 is a pretty definitive result from the refresh token endpoint - no need to retry.
    // Once it fails (REQUEST_NEW_JWT_TOKEN_OFFLINE_ROLLBACK) the subsequent reducer will flip auth things
    // around, and routes will send us to login.
    if (action.type === REQUEST_NEW_JWT_TOKEN_OFFLINE) {
      return true;
    }

    const state = store.getState();
    const { user_id } = getCurrentUser(state);
    const { isAuthenticated, JWTRefreshToken } = getAuthentication(state);

    // Nothing to refresh if we're not logged in... weird spot to be in, tho.
    if (isAuthenticated) {
      // Let the system know there'll be some retries.
      store.dispatch(notifyFetchRequestRetrying(retries));
      store.dispatch(refreshJWT(JWTRefreshToken, user_id));
      return false;
    }
    return true;
  }

  // If this is a fetch bulk request (ie, getting all the data, before getting changes)
  // and we're going to fail on this GET request,
  // and not retry again,
  // It's kind of catastrophic (a sale with no lots isn't really a sale at all) so, tell the system, and stop retrying.
  // (For a changes since - it's not such a big issue, so, retry again later)
  if (retries >= fetchDecaySchedule.length && isActionFetchBulk(action)) {
    // eslint-disable-next-line no-console
    console.warn(
      `Catastrophic failure after ${fetchDecaySchedule.length} for fetch bulk action: `,
      action,
      error,
    );
    store.dispatch(apiResponseGetFailure(null, true));
    return true;
  }

  // fetch throws a TypeError when it fails to fetch (DNS, network, etc) - let these take the default course
  // for redux offline, because we are, in reality, probably offline.
  if (error instanceof TypeError) {
    // eslint-disable-next-line no-console
    console.warn(
      `Retrying after ${retries} errors thrown from fetch: `,
      error,
      action,
    );
    // Let the system know some retries are happening (which *may* let the user know)
    if (isActionMutative(action)) {
      store.dispatch(notifyMutateRequestRetrying(retries));
    } else {
      store.dispatch(notifyFetchRequestRetrying(retries));
    }
    return false;
  }

  const { status } = error;

  // If they got a weird response, there's a good chance it's transient.  Let it retry.
  // After X tries (defined in retry.js) we'll fail it.
  if (error instanceof RequestError) {
    if (ACTION_RETRY_BLACKLIST.includes(action.type)) {
      return true;
    }

    // Important exception here to deride the saveDecaySchedule in retry.js
    // Only retry a 500 a few times, NOT the full offline experience.  We don't want an error in our server (eg bad data)
    // to sit at the front of the queue for 1 second + 5 seconds + 15 seconds + ...
    if (status === 500 && retries > MAX_RETRIES_FOR_500) {
      // eslint-disable-next-line no-console
      console.warn(
        `Not retrying 500 error after ${MAX_RETRIES_FOR_500} attempts. Discarding action: `,
        action,
        error,
      );
      return true;
    } else if (status === 400) {
      // A validation error will always be that - discard these.
      return true;
    } else if (status === 404) {
      // The resource doesn't exist - discard
      return true;
    }

    // Let the system know some retries are happening (which *may* let the user know)
    if (isActionMutative(action)) {
      store.dispatch(notifyMutateRequestRetrying(retries));
    } else {
      store.dispatch(notifyFetchRequestRetrying(retries));
    }

    // Otherwise, use the standard retry mechanisms - 503, 504, timeouts, actual offline, etc, etc
    // eslint-disable-next-line no-console
    console.warn(
      `Retrying network error after ${retries} retries: `,
      error,
      action,
    );
    return false;
  }

  // Most of these should already be caught, but keep it around as a fallback.
  return status >= 400 && status < 501;
};
