import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import GlobalButton from "components/Button";
import { SecondaryStyles } from "components/Button/Styles";

export const Button = styled(GlobalButton)`
  ${({ theme, fullWidth }) => `
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.white};
  cursor:enabled: pointer;
  margin: 0 ${theme.space[1]}px;
  background: ${theme.colors.primary}; 
  ${fullWidth ? "width: 100%;" : ""}
  @media print {
    display: none;
  }
  &:hover:enabled {
    background: ${theme.colors.primaryHighlight};
  }
  transition: ${theme.transitions[0]};
  `}
`;

export const BottomSticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  margin-top: auto;
  bottom: 0;
  justify-self: flex-end;
  flex: 0;
`;

export const SecondaryButton = styled(Button)(SecondaryStyles);

export const DeleteButton = styled(SecondaryButton)(
  ({ theme }) => `

  border: 1px solid ${theme.colors.deleteRed};
  color: ${theme.colors.deleteRed};

  &:hover:enabled {
    color: ${theme.colors.white};
    background-color: ${theme.colors.deleteRed};
  }
`,
);

export const LinkButton = styled.button`
  background: none;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const IconButton = ({ icon, children, ...buttonProps }) => (
  <Button {...buttonProps}>
    {icon && <FontAwesomeIcon icon={icon} />}
    &nbsp;{children}
  </Button>
);
