import {
  GET_SALE_DEFINITIONS,
  GET_SALE_DEFINITIONS_SUCCESS,
  GET_SALE_DEFINITIONS_FAILURE,
} from "constants/actionTypes";

const initialState = {
  isFetching: false,
  defaultRounds: [],
  saleTypes: [],
};

function saleDefinitions(state = initialState, action) {
  switch (action.type) {
    case GET_SALE_DEFINITIONS:
      return {
        ...initialState,
        isFetching: true,
      };

    case GET_SALE_DEFINITIONS_SUCCESS:
      return {
        ...state,
        defaultRounds: action.defaultRounds,
        saleTypes: action.saleTypes,
        isFetching: false,
      };

    case GET_SALE_DEFINITIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}

export default saleDefinitions;
