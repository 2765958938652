import React from "react";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { Row } from "components/Layout";

export default function ModalHeader({ title, closeSelf, className }) {
  return (
    <WrapperTitle justifyBetween className={className}>
      {title && <Title>{title}</Title>}
      <Icon onClick={closeSelf} data-tour="close-modal" />
    </WrapperTitle>
  );
}

const Icon = styled(FontAwesomeIcon).attrs({ icon: faTimes })`
  font-size: ${({ theme }) => theme.fontSizes.delta}px;
  color: #4a4a4a;
  cursor: pointer;
`;

const Title = styled.span`
  color: #4a4a4a;
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
  font-weight: bold;
  line-height: 36px;
  margin-top: 6px;
  margin-left: 12px;
`;

const WrapperTitle = styled(Row)`
  margin-bottom: 24px;
`;
