import {
  formatDateString,
  getDateFromISO8601DateString,
} from "lib/timeFormats";

export function dateGetter(params) {
  const { data, colDef } = params;
  if (!data) {
    return null;
  }
  const value = data[colDef.field];
  return value ? getDateFromISO8601DateString(value) : null;
}

export function dateFormatter(props) {
  const { value } = props;
  if (value) {
    return formatDateString(value);
  }
  return "";
}

export function dateRenderer(props) {
  const { valueFormatted } = props;
  return valueFormatted || "-";
}
