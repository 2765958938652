import React, { useMemo, useState } from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMove from "array-move";
import flatten from "lodash/flatten";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AuctionPenAction } from "actions";

import { Lane } from "components/AuctionPens/PenOrder/Lane";
import {
  ActionsWrapper,
  CloseButton,
  LaneControlButton,
  Legend,
  ListWrapper,
} from "components/AuctionPens/PenOrder/PenOrderComponents";
import { getAuctionPensByRoundSelector } from "components/AuctionPens/PenOrder/selector";
import { Button, SecondaryButton } from "components/Form";
import { Row, Column } from "components/Layout";

import { Settings } from "constants/settings";

import { sortByStartPen } from "lib/auctionPens";
import { openAuctionPenCardsRoute } from "lib/navigation";

import { getSetting, getIsSaleyardAdmin } from "selectors";

const getLanes = auctionPens => {
  return auctionPens.reduce((lanes, auctionPen, index) => {
    if (index === 0) {
      lanes[0] = [auctionPen];
    } else {
      const latestLane = Math.max(...Object.keys(lanes));
      if (auctionPen.isLaneStart) {
        lanes[latestLane + 1] = [auctionPen];
      } else {
        lanes[latestLane] = [...lanes[latestLane], auctionPen];
      }
    }
    return lanes;
  }, {});
};

const resetLaneStart = (p, index) => ({
  ...p,
  isLaneStart: index === 0,
});

const PenOrder = () => {
  const round = useSelector(getSetting(Settings.round));
  const [selectedPens, setSelectedPens] = useState([]);
  const [tempOrder, setTempOrder] = useState(null);
  const params = useParams();

  const saleId = +params.saleId;

  const selectorProps = useMemo(
    () => ({
      saleId,
      round,
      selectedPenIds: selectedPens.map(({ id }) => id),
      tempOrder,
    }),
    [saleId, round, selectedPens, tempOrder],
  );

  const orderedAuctionPens = useSelector(state => {
    return getAuctionPensByRoundSelector(state, selectorProps);
  });

  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  const lanes = useMemo(
    () => getLanes(orderedAuctionPens),
    [orderedAuctionPens],
  );

  const selectedPenIds = selectedPens.map(p => p.id);

  const toggleSelection = auctionPen => {
    if (auctionPen.hasLots || isSaleyardAdmin) {
      setSelectedPens(selectedPens => {
        if (
          selectedPens.find(selectedPen => selectedPen.id === auctionPen.id)
        ) {
          return selectedPens.filter(
            selectedPen => selectedPen.id !== auctionPen.id,
          );
        } else {
          return [...selectedPens, auctionPen];
        }
      });
    }
  };

  const clearSelection = () => setSelectedPens([]);

  const addToNewLane = () => {
    const nextLane = Math.max(...Object.keys(lanes)) + 1;
    const newLanes = Object.keys(lanes).reduce((newLanes, lane) => {
      // Get existing without selected.
      const newPens = lanes[lane].filter(
        ({ id }) => !selectedPenIds.includes(id),
      );
      newLanes[lane] = newPens;
      return newLanes;
    }, {});
    newLanes[nextLane] = selectedPens.map(resetLaneStart);
    setSelectedPens([]);
    setTempOrder(flatten(Object.values(newLanes)));
  };

  const dispatch = useDispatch();

  const onClickSubmit = () => {
    let order = 0;
    const payload = Object.values(lanes).reduce((acc, auctionPens) => {
      const lane = auctionPens.map((auctionPen, index) => ({
        isLaneStart: index === 0,
        order: order++,
        id: auctionPen.id,
      }));

      acc = [...acc, ...lane];
      return acc;
    }, []);
    dispatch(AuctionPenAction.setSellingOrder(round, payload));

    setTempOrder(null);
    openAuctionPenCardsRoute();
  };

  const onCancel = () => {
    openAuctionPenCardsRoute();
  };

  const movePen =
    lane =>
    ({ oldIndex, newIndex }) => {
      const newLanes = flatten(
        Object.values({
          ...lanes,
          [lane]: arrayMove(lanes[lane], oldIndex, newIndex).map(
            resetLaneStart,
          ),
        }),
      );

      setTempOrder(newLanes);
    };

  const addToLane = (newLane, start) => {
    const newLanes = Object.keys(lanes).reduce((newLanes, lane) => {
      // Get existing without selected.
      let newPens = lanes[lane].filter(
        ({ id }) => !selectedPenIds.includes(id),
      );
      if (lane === newLane) {
        if (start) {
          newPens = [...selectedPens, ...newPens];
        } else {
          newPens = [...newPens, ...selectedPens];
        }
      }
      newLanes[lane] = newPens.map(resetLaneStart);
      return newLanes;
    }, {});

    setSelectedPens([]);
    setTempOrder(flatten(Object.values(newLanes)));
  };

  const moveEntireLane = (laneIndex, direction) => {
    let newLanes;
    if (direction === "up") {
      newLanes = {
        ...lanes,
        [Object.keys(lanes)[laneIndex]]: lanes[laneIndex - 1],
        [Object.keys(lanes)[laneIndex - 1]]: lanes[laneIndex],
      };

      setTempOrder(flatten(Object.values(newLanes)));
    } else {
      newLanes = {
        ...lanes,
        [Object.keys(lanes)[laneIndex]]: lanes[laneIndex + 1],
        [Object.keys(lanes)[laneIndex + 1]]: lanes[laneIndex],
      };
    }

    setTempOrder(flatten(Object.values(newLanes)));
  };

  const resetLane = laneIndex => {
    const sortedLane = sortByStartPen(lanes[laneIndex]).map(resetLaneStart);

    const newLanes = {
      ...lanes,
      [laneIndex]: sortedLane,
    };
    setTempOrder(flatten(Object.values(newLanes)));
  };

  const nothingTouched = orderedAuctionPens.filter(p => p.touched).length === 0;
  const nothingSelected = selectedPenIds.length === 0;

  return (
    <>
      <Column style={{ position: "relative" }}>
        <CloseButton onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>

        <Column data-tour="legend" style={{ margin: "0 2rem" }}>
          <h1 style={{ textAlign: "center" }}>Reorder Pens</h1>
          <Row textAlignCenter alignCenter justifyCenter>
            Drag pens to change pen order within a lane, or tap pen(s) to move
            to start/end of a lane. Change order of lanes using arrows.
          </Row>
          <Legend auctionPenLanes={lanes} />
        </Column>
      </Column>
      <ListWrapper>
        {Object.keys(lanes).map((lane, index) => {
          const moveLaneUp =
            index > 0 ? () => moveEntireLane(index, "up") : undefined;
          const moveLaneDown =
            index < Object.keys(lanes).length - 1
              ? () => moveEntireLane(index, "down")
              : undefined;

          return (
            <Lane
              laneNumber={index}
              movePen={movePen(index)}
              key={lane}
              auctionPens={lanes[lane]}
              toggleSelection={toggleSelection}
              addToStart={() => addToLane(lane, true)}
              addToEnd={() => addToLane(lane, false)}
              moveLaneUp={moveLaneUp}
              moveLaneDown={moveLaneDown}
              resetLane={() => resetLane(index)}
              selectedPens={selectedPens}
              clearSelection={clearSelection}
            />
          );
        })}

        {!nothingSelected && (
          <LaneControlButton show={nothingSelected} onClick={addToNewLane}>
            Create new lane with {selectedPenIds.length} pens.
          </LaneControlButton>
        )}
      </ListWrapper>
      <ActionsWrapper alignCenter justifyCenter>
        <SecondaryButton type="button" onClick={onCancel}>
          Cancel
        </SecondaryButton>
        <Button
          type="submit"
          onClick={onClickSubmit}
          disabled={!nothingSelected && !nothingTouched}
          data-tour="reorderConfirm"
        >
          Confirm
        </Button>
      </ActionsWrapper>
    </>
  );
};

export default PenOrder;
