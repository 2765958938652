import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  Chip,
  ChipBag,
  FullOrPartial,
  MarkChips,
  NLISChips,
  NoteChip,
  PenTotalChip,
  VendorBredChip,
} from "components/Chip";

import { getIsVendorBredBySaleLotId } from "selectors";

const SizedChipBag = styled(ChipBag)`
  font-size: ${({ theme, bigScreen }) =>
    bigScreen ? `${theme.fontSizes.epsilon}px` : "inherit"};
`;

const WatcherChips = ({ saleLot, bigScreen }) => {
  const {
    id,
    breed_name,
    age_name,
    sex_name,
    grade_name,
    category_name,
    marks,
    note,
    EIDNLISDetails,
    quantity,
    auction_pen,
  } = saleLot;
  const { EUStatus, lifetimeTraceable } = EIDNLISDetails;

  const vendorBred = useSelector(getIsVendorBredBySaleLotId(id));

  return (
    <SizedChipBag bigScreen={bigScreen}>
      {breed_name && <Chip backgroundColor="blueHighlight">{breed_name}</Chip>}
      {age_name && <Chip backgroundColor="blueHighlight">{age_name}</Chip>}
      {sex_name && <Chip backgroundColor="blueHighlight">{sex_name}</Chip>}
      {grade_name && <Chip backgroundColor="blueHighlight">{grade_name}</Chip>}
      {category_name && (
        <Chip backgroundColor="blueHighlight">{category_name}</Chip>
      )}
      <MarkChips marks={marks} />
      <NoteChip note={note.trim()} />
      <NLISChips vendor_property={saleLot.consignment?.vendor_property_id} />
      <VendorBredChip value={vendorBred} />
      <FullOrPartial label="EU Eligible" values={EUStatus} positive="Y" />
      <FullOrPartial label="LT" values={lifetimeTraceable} positive="Y" />
      <PenTotalChip
        lotHeadCount={quantity}
        penLotCount={auction_pen.lotCount}
        penHeadCount={auction_pen.headCount}
      />
    </SizedChipBag>
  );
};

export default WatcherChips;
