import React, { memo, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  connectToAllScales,
  connectToHub,
  updateAvailableDevices,
} from "actions";

import {
  getAvailableDevices,
  getConnectedScales,
  getIsHubConnected,
} from "selectors";

function IndicatorStatusInlineComponent() {
  const availableDevices = useSelector(getAvailableDevices);
  const isHubConnected = useSelector(getIsHubConnected);
  const connectedScales = useSelector(getConnectedScales);
  const dispatch = useDispatch();

  const isWeighbridgeConnected = connectedScales.length > 0;
  const availableDevicesCount = availableDevices.length;

  useEffect(() => {
    if (!isHubConnected) {
      dispatch(connectToHub());
    } else if (!availableDevicesCount) {
      dispatch(updateAvailableDevices());
    } else if (!isWeighbridgeConnected) {
      dispatch(connectToAllScales());
    }
  }, [availableDevicesCount, dispatch, isHubConnected, isWeighbridgeConnected]);

  let deviceName = "";

  let statusMessage = "";
  if (!isHubConnected) {
    statusMessage = "AgriNous Hub not connected.";
  } else if (!isWeighbridgeConnected) {
    statusMessage = "No Weighbridge connected.";
  } else {
    statusMessage = connectedScales
      .map(
        deviceId =>
          availableDevices.find(device => device.deviceId === deviceId).name,
      )
      .join(", ");

    deviceName = availableDevices.find(
      device => device.deviceId === connectedScales[0],
    ).name;
    if (connectedScales.length > 1) {
      deviceName += ` (+${connectedScales.length - 1} more)`;
    }
  }
  return (
    <div title={statusMessage}>{deviceName || "No Weighbridge connected."}</div>
  );
}

export default memo(IndicatorStatusInlineComponent);
