import React from "react";

import MuiCheckbox from "@material-ui/core/Checkbox";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SubtleBadge } from "components/Badge";
import { FormCollapse } from "components/Form";
import { Row } from "components/Layout";

import { getProperties } from "selectors";

import {
  AlternativesTable,
  NestedAlternativesTable,
} from "./SelectSyncDataTables";

const CheckBoxBadge = styled(SubtleBadge)`
  padding: 2px 25px;
`;

export const SelectSyncData = ({
  business,
  alternatives,
  idx,
  openSections,
  onToggle,
  noDifferences,
  source,
  setSource,
}) => {
  const checkBoxOnChange = event => {
    const sourceId = event.target.name;
    if (source === sourceId) {
      setSource(null);
    } else {
      setSource(event.target.name);
    }
  };

  const propertiesLookup = useSelector(getProperties);

  const collapseHeader = (
    <Row>
      <CheckBoxBadge>
        <MuiCheckbox
          name={String(alternatives.source.id)}
          onChange={checkBoxOnChange}
          checked={String(source) === String(alternatives.source.id)}
        />{" "}
        {alternatives.sourceName}
      </CheckBoxBadge>
    </Row>
  );

  return (
    <FormCollapse
      isOpen={openSections.has(idx)}
      onToggle={onToggle(idx)}
      hasErrorBadge={false}
      header={collapseHeader}
    >
      <>
        {noDifferences ? (
          <p className="p-7">No Alternative Changes Available</p>
        ) : (
          <>
            <h3>General</h3>
            <AlternativesTable
              business={business}
              alternatives={alternatives}
            />

            <NestedAlternativesTable
              business={business}
              alternatives={alternatives}
              alternativesField="properties"
              title="Properties"
              propertiesLookup={propertiesLookup}
            />
            <NestedAlternativesTable
              business={business}
              alternatives={alternatives}
              alternativesField="emailRecipients"
              title="Email Recipients"
            />
          </>
        )}
      </>
    </FormCollapse>
  );
};
