import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { BillingDocumentAction } from "actions";

import { ExportAPI } from "components/BillingWorkFlow/ExportAPI";
import { Button } from "components/Form";
import { Column } from "components/Layout";
import MessageBox from "components/MessageBox";

import { DeploymentPermissions } from "constants/permissions";

import { getActiveBillingRunId } from "selectors";

import { useHasDeploymentPermission } from "hooks";

export const Export = ({ setSelectedBillingDocuments }) => {
  const billingRunId = useSelector(getActiveBillingRunId);
  const dispatch = useDispatch();

  const exportXeroBills = () => {
    dispatch(BillingDocumentAction.exportXeroBillCsv(billingRunId));
  };

  const exportXeroInvoices = () => {
    dispatch(BillingDocumentAction.exportXeroInvoiceCsv(billingRunId));
  };

  const hasXeroIntegrationFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureXeroIntegration,
  );

  const hasMyobExportsFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureMyobIntegration,
  );

  const hasBulkBillingExportFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureBulkBillingExport,
  );

  if (
    hasXeroIntegrationFeature ||
    hasMyobExportsFeature ||
    hasBulkBillingExportFeature
  ) {
    return (
      <ExportAPI setSelectedBillingDocuments={setSelectedBillingDocuments} />
    );
  } else {
    return (
      <MessageBox>
        <Column>
          <Column>
            Click to download all approved Invoices as a CSV to be uploaded to
            Xero.
            <Button onClick={exportXeroInvoices}>
              Export Invoices for Xero
            </Button>
          </Column>
          <Column>
            Click to download all approved Account Sales/RCTIs as a CSV to be
            uploaded to Xero.
            <Button onClick={exportXeroBills}>
              Export Account Sales/RCTIs for Xero
            </Button>
          </Column>
        </Column>
      </MessageBox>
    );
  }
};
