import { createSelector } from "reselect";

import { getSundryTemplates } from "selectors";

export const selectSundryTemplatesAggridData = createSelector(
  [getSundryTemplates],
  sundryTemplatesByIdLookup => {
    const data = [];
    for (const sundryTemplate of Object.values(sundryTemplatesByIdLookup)) {
      data.push({ sundryTemplate });
    }
    return data;
  },
);
