export const ConsignmentGroupByOptions = {
  VENDOR: "Vendor",
  VENDOR_NUMBER: "Vendor No",
  RECEIVING_PEN: "Receiving Pen",
};

export const ConsignmentType = {
  PRIMARY: "Primary",
  VENDOR_SPLIT: "Vendor Split",
};

// A marker for when we don't know the consignment id
export const UNKNOWN_CONSIGNMENT_ID = "UNKNOWN_CONSIGNMENT_ID";
