import React from "react";

import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import styled from "styled-components/macro";

import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { ScaleOperatorSaleSummaryCards } from "components/SaleSummaryCards";

import { ApiModel } from "constants/loading";
import { ScreenTypes } from "constants/screen";

import { getSaleNameForSale } from "lib/sale";

import {
  agentSummarySelector,
  currentSaleSelector,
  scaleOperatorSummarySelector,
} from "selectors";

const TitleWrapper = styled(Row)`
  margin: 0 ${({ theme }) => theme.space[3]}px;
  justify: around;
  align: center;
`;
TitleWrapper.displayName = "TitleWrapper";

const Title = styled.h1`
  flex-grow: 1;
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
`;
Title.displayName = "Title";

const IconBtn = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;
IconBtn.displayName = "IconBtn";

const EditSaleButton = ({ handleClick }) => (
  <IconBtn size="lg" icon={faPencil} onClick={handleClick} />
);
EditSaleButton.displayName = "EditSaleButton";

const ScaleOperatorOverview = ({ screenType, summary, saleName }) => {
  const isMobile = screenType !== ScreenTypes.DESKTOP;
  return (
    <WaitForSync
      requiredData={[ApiModel.CONSIGNMENTS, ApiModel.SALES, ApiModel.SALE_LOTS]}
    >
      <div>
        <TitleWrapper>
          <Title>{saleName}</Title>
        </TitleWrapper>
        <ScaleOperatorSaleSummaryCards summary={summary} isMobile={isMobile} />
      </div>
    </WaitForSync>
  );
};

const mapStateToProps = state => {
  const sale = currentSaleSelector(state);
  return {
    saleId: sale.id,
    saleName: getSaleNameForSale(sale),
    screenType: state.screen.type,
    summary: scaleOperatorSummarySelector(state),
    agentSummaries: agentSummarySelector(state),
  };
};

ScaleOperatorOverview.propTypes = {
  screenType: PropTypes.string,
  summary: PropTypes.object,
  saleName: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  withRouter,
)(ScaleOperatorOverview);
