import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { PricingTypes } from "constants/pricingTypes";
import { getUnitPriceString } from "lib";
import {
  selectSaleProgressHeadCount,
  selectSaleProgressCents,
  selectSaleProgressWeighedGrams,
  selectSaleProgressWeighedCents,
} from "selectors";

export function SaleProgressAverages(): React.JSX.Element {
  const saleProgressCents = useSelector(selectSaleProgressCents);

  const saleProgressHeadCount = useSelector(selectSaleProgressHeadCount);

  const weighedHeadGrams = useSelector(selectSaleProgressWeighedGrams);

  const weighedHeadCents = useSelector(selectSaleProgressWeighedCents);

  const showAverageLiveWeightPrice = weighedHeadGrams > 0;
  const showAverageOpenAuctionPrice = saleProgressHeadCount > 0;

  return (
    <>
      <Grid item xs={6}>
        <h3>{`Average ${PricingTypes.toString(PricingTypes.PER_HEAD)}`}</h3>
        {showAverageOpenAuctionPrice ? (
          <span>
            {getUnitPriceString({
              total_price_cents: saleProgressCents,
              pricing_type_id: PricingTypes.PER_HEAD,
              total_mass_grams: 0, // Unused for $/head
              quantity: saleProgressHeadCount,
            })}
          </span>
        ) : (
          <span>N/A</span>
        )}
      </Grid>
      <Grid item xs={6}>
        <h3>{`Average ${PricingTypes.toString(PricingTypes.PER_KILO)}`}</h3>
        {/* This should be the sum of the purchase price divided by the sum of the weight */}
        {showAverageLiveWeightPrice ? (
          <span>
            {getUnitPriceString({
              total_price_cents: weighedHeadCents,
              pricing_type_id: PricingTypes.PER_KILO,
              total_mass_grams: weighedHeadGrams,
              quantity: 0, // Unused for c/kg
            })}
          </span>
        ) : (
          <span>N/A</span>
        )}
      </Grid>
    </>
  );
}
