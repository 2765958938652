import React from "react";

import { Skeleton } from "@material-ui/lab";
import { uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { sendAllVendorReports } from "actions";

import { Button, SecondaryButton } from "components/Form";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { closeAllHashModals, openEditBusinessModal } from "lib/navigation";

import {
  getBusinessById,
  getConsignments,
  getSaleLotIdsByVendorId,
  selectCurrentDeploymentSaleIdsList,
} from "selectors";

import { useToggle } from "hooks";

const VendorContact = ({ vendorId }) => {
  const business = useSelector(getBusinessById(vendorId));
  const lots = useSelector(getSaleLotIdsByVendorId(vendorId));

  if (!business) {
    return <Skeleton animation="wave" variant="text" />;
  }

  const { name, emailRecipients } = business;

  const hasNoLots = lots.length === 0;

  if (hasNoLots) {
    return (
      <div>
        <h3>{name}</h3>
        <ul>
          <li>Vendor has no lots. Emails can not be sent</li>
        </ul>
      </div>
    );
  }

  return (
    <div onClick={() => openEditBusinessModal(vendorId, window.location.hash)}>
      <h3>{name}</h3>
      <ul>
        {emailRecipients.length === 0 ? (
          <li>No email address recorded.</li>
        ) : (
          emailRecipients.map(er => <li key={er.id}>{er.email}</li>)
        )}
      </ul>
    </div>
  );
};

const SentConfirmation = () => <div>Emails have been queued for sending.</div>;

export const SendVendorReportsModal = () => {
  const [isSent, toggleIsSent] = useToggle(false);

  const closeSelf = () => {
    closeAllHashModals();
  };

  const currentDeploymentSaleIdsList = useSelector(
    selectCurrentDeploymentSaleIdsList,
  );

  const dispatch = useDispatch();

  const send = () => {
    currentDeploymentSaleIdsList.forEach(deploymentSaleId =>
      dispatch(sendAllVendorReports(deploymentSaleId)),
    );

    toggleIsSent();
  };

  const consignments = useSelector(getConsignments);

  const vendors = uniq(Object.values(consignments).map(c => c.vendor_id));

  return (
    <ZoomyDialog open onClose={closeSelf} maxWidth="lg" fullWidth>
      <DialogTitle onClose={closeSelf}>Email all Vendor Reports</DialogTitle>
      <DialogContent dividers>
        {isSent ? (
          <SentConfirmation />
        ) : (
          vendors.map(vendorId => <VendorContact vendorId={vendorId} />)
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={closeSelf}>Close</SecondaryButton>
        <Button onClick={send} disabled={isSent}>
          Send
        </Button>
      </DialogActions>
    </ZoomyDialog>
  );
};
