import { put, takeEvery, call, all } from "redux-saga/effects";

import {
  receiveAgesSuccess,
  receiveBreedsSuccess,
  receiveCategoriesSuccess,
  receiveDentitionSuccess,
  receiveExemptionsSuccess,
  receiveGradesSuccess,
  receiveRoundsSuccess,
  receiveSexesSuccess,
  receiveSpeciesSuccess,
  requestSpeciesAttributesFailure,
} from "actions";

import { GET_SPECIES_ATTRIBUTES } from "constants/actionTypes";

import { api } from "./api";

function* fetchSpeciesAttributes(action) {
  try {
    const [
      ages,
      breeds,
      categories,
      exemptions,
      grades,
      sexes,
      rounds,
      species,
      dentition,
    ] = yield all([
      yield call(api.get, "/v2/ages/"),
      yield call(api.get, "/v2/breeds/"),
      yield call(api.get, "/v2/categories/"),
      yield call(api.get, "/v2/exemptions/"),
      yield call(api.get, "/v2/grades/"),
      yield call(api.get, "/v2/sexes/"),
      yield call(api.get, "/v2/rounds/"),
      yield call(api.get, "/v2/species/"),
      yield call(api.get, "/v2/dentition/"),
    ]);
    yield put(receiveBreedsSuccess(breeds));
    yield put(receiveSexesSuccess(sexes));
    yield put(receiveAgesSuccess(ages));
    yield put(receiveGradesSuccess(grades));
    yield put(receiveCategoriesSuccess(categories));
    yield put(receiveExemptionsSuccess(exemptions));
    yield put(receiveRoundsSuccess(rounds));
    yield put(receiveSpeciesSuccess(species));
    yield put(receiveDentitionSuccess(dentition));
  } catch (e) {
    yield call(api.handleFetchError, e, "animal and breed data", action);
    if (!(e instanceof TypeError) && (!e.status || e.status !== 401)) {
      yield put(requestSpeciesAttributesFailure());
    }
  }
}

function* rootSaga() {
  yield takeEvery(GET_SPECIES_ATTRIBUTES, fetchSpeciesAttributes);
}

export default rootSaga;
