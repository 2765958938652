import React from "react";

import { Tab, Tabs } from "@material-ui/core";
import { get } from "lodash";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import AgGrid from "components/AgGrid/AgGrid";
import CheckboxCellRenderer from "components/AgGrid/CheckboxCellRenderer";
import { CompositeFilter } from "components/AgGrid/filters/compositeFilter";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";
import { SpeciesSelector } from "components/SpeciesSelector";

import { Settings } from "constants/settings";
import { colors } from "constants/theme";

import { getActiveRole, getAges, getSetting } from "selectors";

const SaveButton = styled(Button)`
  flex: 0;
  margin-left: auto;
  align-self: center;
`;

const touchRow = e => {
  if (!isEqual(e.newValue, e.oldValue)) {
    e.node.setData({
      ...e.node.data,
      touched: true,
    });
  }
};

const getRowStyle = row => {
  const { data } = row;
  if (data.syncing) {
    return {
      background: `${colors.primaryActive}50`,
    };
  }
  if (data.syncError) {
    return {
      background: colors.errorRed,
    };
  }
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  flex: true,
  editable: true,
  enableCellChangeFlash: true,
};

const components = {
  [CompositeFilter.componentName]: CompositeFilter,
  checkboxRenderer: CheckboxCellRenderer,
};

const getRowId = params =>
  params.data.id ||
  get(params.data, params.context.dataKey) ||
  params.data.display_value ||
  params.data.name;

export const DeploymentAttributesTable = ({
  baseData,
  deploymentData,
  dataKey,
  columnDefs,
  handleUpdate,
}) => {
  const { deployments } = useSelector(getActiveRole);

  const selectedSpeciesId = useSelector(getSetting(Settings.speciesId));

  const [selectedDeployment, setSelectedDeployment] = React.useState(
    deployments[0].id,
  );

  const ages = useSelector(getAges);
  const agGridInstance = React.useRef();

  const onGridReady = params => {
    agGridInstance.current = params;
  };

  const handleSetSelectedDeployment = (_event, newValue) =>
    setSelectedDeployment(newValue);

  const save = () => {
    const updates = [];
    agGridInstance.current.api.forEachNode((rowNode, index) => {
      const { data } = rowNode;
      const reordered = data.deploymentOrder !== index;
      // Only update the row if its been touched or re-ordered.
      if (data.touched || reordered) {
        const payload = {
          order: index,
          [dataKey]: data[dataKey],
          deployment_id: selectedDeployment,
          code: data.code,
          is_used: data.is_used || false,
          id: data.id || uuidv4(),
          quick_select: data.quick_select,
          quick_select_hooks: data.quick_select_hooks,
          quick_select_paddock: data.quick_select_paddock,
          saleyard_code:
            dataKey === "breed_id" ? data.saleyard_code : undefined,
          quick_code: dataKey === "breed_id" ? data.quick_code : undefined,
        };
        updates.push(payload);
      }
    });

    if (updates.length > 0) {
      handleUpdate(updates);
    }
  };

  // combine

  const rowData = React.useMemo(() => {
    // Filter the base data by select species.
    const selectedSpeciesData = baseData.filter(
      attr => attr.species_id === selectedSpeciesId,
    );

    // Filter the deployment data by selected deployment
    const selectedDeploymentData = deploymentData.filter(
      attr => attr.deployment_id === selectedDeployment,
    );

    const data = selectedSpeciesData.map(attr => {
      const deploymentAttr = selectedDeploymentData.find(
        a => a[dataKey] === attr.id,
      );

      let isUsed = deploymentAttr?.is_used || false;
      // Dentitions should be used by default, so if no deploymentAttr, default to true
      if (dataKey === "dentition_id" && !deploymentAttr) {
        isUsed = true;
      }
      return {
        deploymentOrder: deploymentAttr?.order,
        id: deploymentAttr?.id || "",
        order: attr.order,
        code: deploymentAttr?.code || "",
        name: attr.name,
        [dataKey]: attr.id,
        is_used: isUsed,
        saleyard_code: deploymentAttr?.saleyard_code || "",
        quick_code: deploymentAttr?.quick_code || "",
        quick_select: deploymentAttr?.quick_select || false,
        quick_select_hooks: deploymentAttr?.quick_select_hooks || false,
        quick_select_paddock: deploymentAttr?.quick_select_paddock || false,
        age: dataKey === "dentition_id" ? ages[attr.age_id]?.name : undefined,
        display_value:
          dataKey === "dentition_id" ? attr.display_value : undefined,
        default: dataKey === "dentition_id" ? attr.default : undefined,
      };
    });
    return sortBy(data, ["deploymentOrder", "order"]);
  }, [
    baseData,
    dataKey,
    deploymentData,
    selectedDeployment,
    selectedSpeciesId,
    ages,
  ]);

  return (
    <Row flexGrow>
      {deployments.length > 1 && (
        <Column margin={2}>
          <Paper square elevation={2}>
            <Tabs
              orientation="vertical"
              value={selectedDeployment}
              onChange={handleSetSelectedDeployment}
            >
              {deployments.map(deployment => (
                <Tab
                  label={deployment.channel}
                  value={deployment.id}
                  key={deployment.id}
                />
              ))}
            </Tabs>
          </Paper>
        </Column>
      )}
      <Column full>
        <Row>
          <SpeciesSelector />

          <SaveButton onClick={save} data-tour="save">
            Save
          </SaveButton>
        </Row>
        <CollapseTableWrapper>
          <AgGrid
            context={{ dataKey }}
            onGridReady={onGridReady}
            onCellValueChanged={touchRow}
            rowData={rowData}
            undoRedoCellEditing
            undoRedoCellEditingLimit={200}
            suppressColumnVirtualisation
            suppressScrollOnNewData
            singleClickEdit
            columnDefs={columnDefs}
            rowDragManaged
            getRowStyle={getRowStyle}
            defaultColDef={defaultColDef}
            components={components}
            stopEditingWhenCellsLoseFocus
            getRowId={getRowId}
          />
        </CollapseTableWrapper>
      </Column>
    </Row>
  );
};

export default DeploymentAttributesTable;
