import React from "react";

import { ModalTypes } from "constants/navigation";

import { EditIntegrationBusinessModal } from "./Modal";

export function Adapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.EditIntegrationBusiness] || null;
  const [integrationCredentialType, businessId, options] =
    JSON.parse(hashParams);
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";

  return (
    <EditIntegrationBusinessModal
      integrationCredentialType={integrationCredentialType}
      businessId={businessId}
      options={options}
      returnTo={decodedReturnTo}
    />
  );
}
