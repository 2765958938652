import React from "react";

import { UpdateBuyerModal } from "components/UpdateBuyerModal";

function UpdateBuyerModalAdapter(props) {
  const { hash } = props;
  const {
    returnTo,
    buyerId,
    destinationPropertyId,
    buyerWayId,
    thirdPartyId,
    invoiceToBusinessId,
  } = hash;

  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <UpdateBuyerModal
      returnTo={decodedReturnTo}
      buyerId={buyerId}
      destinationPropertyId={destinationPropertyId}
      buyerWayId={buyerWayId}
      thirdPartyId={thirdPartyId}
      invoiceToBusinessId={invoiceToBusinessId}
    />
  );
}

export default UpdateBuyerModalAdapter;
