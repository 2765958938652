import React, { memo } from "react";

import Transactions from "components/BusinessForm/Transactions";
import { withNamespace } from "components/Form/FormikControls";

import {
  BusinessTransactionsHeader,
  BusinessTransactionsTable,
} from "./BusinessTransactionsTable";
import { businessTransactionsValidation } from "./BusinessTransactionsTableForm";

const TransactionsTableComponent = ({
  transactions,
  masterBusinessId,
  namespace: ns,
  isOpen,
  onToggle,
}) => {
  const transactionsNs = withNamespace(ns, "transactions");

  return (
    <Transactions
      headerComponent={BusinessTransactionsHeader}
      initialValuesList={transactions}
      isOpen={isOpen}
      masterBusinessId={masterBusinessId}
      namespace={transactionsNs}
      onToggle={onToggle}
      tableComponent={BusinessTransactionsTable}
      validationSchema={businessTransactionsValidation}
      dataTour="transactionsCollapse"
    />
  );
};

export default memo(TransactionsTableComponent);
