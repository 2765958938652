import React, { useCallback, useEffect, useRef, useState } from "react";

import Big from "big.js";
import { useField } from "formik";

import { BaseInput } from "components/Form/FormikControls";

// _props_ is here to document the available parameters
// eslint-disable-next-line no-unused-vars
function defaultFormatFieldValue(value, props) {
  if (value === null) {
    return null;
  }
  return value;
}

// eslint-disable-next-line no-unused-vars
function defaultFormatInternalValue(value, props) {
  if (value === null) {
    return "";
  }
  return value;
}

// eslint-disable-next-line no-unused-vars
function defaultCompareValues(valueA, valueB, props) {
  return valueA === valueB;
}

// eslint-disable-next-line no-unused-vars
function defaultParseValue(value, props) {
  if (value === null) {
    return null;
  }
  return value;
}

// eslint-disable-next-line no-unused-vars
function defaultValidateInputValue(value, props) {
  return true;
}

function formatDecimalFieldValue(fieldValue, props) {
  const { multiplier = 1 } = props;
  if (fieldValue === null) {
    return null;
  }
  try {
    return new Big(fieldValue).times(multiplier);
  } catch (e) {
    return null;
  }
}

function formatDecimalInternalValue(internalValue, props) {
  const { decimalPlaces = 2 } = props;
  if (internalValue === null) {
    return "";
  }
  return internalValue.toFixed(decimalPlaces);
}

function parseDecimalInputValueDefaultToZero(inputValue) {
  if (inputValue === "") {
    return new Big(0);
  }
  return new Big(inputValue);
}

export function parseDecimalInputValueDefaultToNull(inputValue) {
  if (inputValue === "") {
    return null;
  }
  return new Big(inputValue);
}

function parseDecimalInternalStringValue(internalValue, props) {
  const { fieldMultiplier = 1, fieldDecimalPlaces = 0 } = props;
  if (internalValue === null) {
    return null;
  }
  return internalValue.times(fieldMultiplier).toFixed(fieldDecimalPlaces);
}

function validateDecimalInputValue(inputValue) {
  return /^-?\d*(\.\d*)?$/.test(inputValue);
}

function compareDecimalInternalValues(valueA, valueB) {
  if (valueA === valueB) {
    return true;
  } else if (valueA !== null && valueB !== null) {
    return valueA.eq(valueB);
  }
  return false;
}

function parseDecimalInternalNumberValue(internalValue, props) {
  const { fieldMultiplier = 1, fieldDecimalPlaces = 0 } = props;
  if (internalValue === null) {
    return null;
  }
  return parseFloat(
    internalValue.times(fieldMultiplier).toFixed(fieldDecimalPlaces),
  );
}

export const DecimalStringInputProps = {
  decimal: true,
  decimalPlaces: 2,
  fieldDecimalPlaces: 2,
  type: "number",
  formatFieldValue: formatDecimalFieldValue,
  formatInternalValue: formatDecimalInternalValue,
  compareInternalValues: compareDecimalInternalValues,
  parseInputValue: parseDecimalInputValueDefaultToZero,
  parseInternalValue: parseDecimalInternalStringValue,
  validateInputValue: validateDecimalInputValue,
};
export const DecimalNumberInputProps = {
  formatFieldValue: formatDecimalFieldValue,
  formatInternalValue: formatDecimalInternalValue,
  compareInternalValues: compareDecimalInternalValues,
  parseInputValue: parseDecimalInputValueDefaultToZero,
  parseInternalValue: parseDecimalInternalNumberValue,
  validateInputValue: validateDecimalInputValue,
};

export const KilogramDisplayGramsInputProps = {
  ...DecimalStringInputProps,
  afterSymbol: "kg",
  decimal: true,
  decimalPlaces: 2,
  fieldDecimalPlaces: 0,
  fieldMultiplier: 1000,
  multiplier: 0.001,
  type: "number",
};

export const DollarDisplayCentsInputProps = {
  ...DecimalStringInputProps,
  beforeSymbol: "$",
  decimal: true,
  decimalPlaces: 2,
  fieldDecimalPlaces: 0,
  fieldMultiplier: 100,
  multiplier: 0.01,
  type: "number",
};

export const PercentageInputProps = {
  ...DecimalStringInputProps,
  beforeSymbol: "%",
  decimal: true,
  decimalPlaces: 2,
  fieldDecimalPlaces: 4,
  fieldMultiplier: 0.01,
  multiplier: 100,
  type: "number",
};

export function ControlledLifeCycleInput(props) {
  const {
    name,
    label,
    type = "text",
    maxLength,
    decimal = false,
    decimalPlaces = 2,
    // multiplier = undefined,
    // overrideValue = undefined,
    align,
    formatFieldValue = defaultFormatFieldValue,
    formatInternalValue = defaultFormatInternalValue,
    compareFieldValues = defaultCompareValues,
    compareInputValues = defaultCompareValues,
    compareInternalValues = defaultCompareValues,
    parseInputValue = defaultParseValue,
    parseInternalValue = defaultParseValue,
    validateInputValue = defaultValidateInputValue,
    onAfterInputValueChanged,
    onAfterInternalValueChanged,
    onChangeExtra,
    disableAutoComplete,
    ...inputProps
  } = props;

  if (decimal) {
    // e.g. 2 => 0.01
    inputProps.step = (10 ** -decimalPlaces).toFixed(decimalPlaces);
  }

  const onAfterFieldValueChanged =
    props.onAfterFieldValueChanged || onChangeExtra;

  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const error = meta.touched && meta.error;
  const fieldValue = field.value;

  const lastFieldValueRef = useRef(fieldValue);
  const propsRef = useRef(props);
  propsRef.current = props;

  const [internalValue, setInternalValue] = useState(
    formatFieldValue(fieldValue, props),
  );

  const [inputValue, setInputValue] = useState(
    formatInternalValue(internalValue, props),
  );

  const setFieldValue = useCallback(
    (value, shouldValidate) => {
      lastFieldValueRef.current = value;
      return setValue(value, shouldValidate);
    },
    [lastFieldValueRef, setValue],
  );

  useEffect(() => {
    if (
      !compareFieldValues(
        fieldValue,
        lastFieldValueRef.current,
        propsRef.current,
      )
    ) {
      // This will cause the Input to lose all of its editing state, i.e. cursor position, selection/highlighting, etc. It should be avoided were possible
      lastFieldValueRef.current = fieldValue;
      const nextInternalValue = formatFieldValue(fieldValue, propsRef.current);
      if (
        !compareInternalValues(
          nextInternalValue,
          internalValue,
          propsRef.current,
        )
      ) {
        setInternalValue(nextInternalValue);
        const nextInputValue = formatInternalValue(
          nextInternalValue,
          propsRef.current,
        );
        if (!compareInputValues(nextInputValue, inputValue, propsRef.current)) {
          setInputValue(nextInputValue, true);
        }
      }
    }
  }, [
    compareFieldValues,
    compareInputValues,
    compareInternalValues,
    fieldValue,
    formatFieldValue,
    formatInternalValue,
    inputValue,
    internalValue,
    lastFieldValueRef,
    propsRef,
  ]);

  const alignInput = React.useMemo(() => {
    if (align) {
      return align;
    } else if (decimal) {
      return "right";
    } else {
      return undefined;
    }
  }, [align, decimal]);

  function onChange(event) {
    let internalValueChanged = false;
    let fieldValueChanged = false;
    // Prevent the user from entering invalid characters, etc.
    if (validateInputValue(event.target.value, propsRef.current)) {
      let nextFieldValue;

      // Always show the user what they have entered, update accordingly
      setInputValue(event.target.value);

      const nextInternalValue = parseInputValue(
        event.target.value,
        propsRef.current,
      );

      // Check if the stored internal value has functionally changed from the last stored value
      if (
        !compareInternalValues(
          nextInternalValue,
          internalValue,
          propsRef.current,
        )
      ) {
        internalValueChanged = true;

        setInternalValue(nextInternalValue);

        nextFieldValue = parseInternalValue(
          nextInternalValue,
          propsRef.current,
        );
        if (!compareFieldValues(nextFieldValue, fieldValue, propsRef.current)) {
          fieldValueChanged = true;

          // Update the Formik field values
          setFieldValue(nextFieldValue, true).then(() =>
            setTouched(true, false),
          );
        }
      }
      typeof onAfterInputValueChanged === "function" &&
        onAfterInputValueChanged(event.target.value);

      if (internalValueChanged) {
        typeof onAfterInternalValueChanged === "function" &&
          onAfterInternalValueChanged(nextInternalValue);
      }

      if (fieldValueChanged) {
        typeof onAfterFieldValueChanged === "function" &&
          onAfterFieldValueChanged(nextFieldValue);
      }
    }
  }

  function onBlur() {
    // Apply the field value to the input when the user focuses out of the field.
    const finalInternalValue = formatFieldValue(fieldValue, props);
    if (!compareInternalValues(finalInternalValue, internalValue, props)) {
      setInternalValue(finalInternalValue);
      typeof onAfterInternalValueChanged === "function" &&
        onAfterInternalValueChanged(finalInternalValue);
    }
    const finalInputValue = formatInternalValue(finalInternalValue, props);
    if (!compareInputValues(finalInputValue, inputValue, props)) {
      setInputValue(finalInputValue);
      typeof onAfterInputValueChanged === "function" &&
        onAfterInputValueChanged(finalInputValue);
    }
  }

  return (
    <BaseInput
      label={label}
      name={name}
      maxLength={maxLength}
      type={type}
      value={inputValue}
      error={error}
      onBlur={onBlur}
      onChange={onChange}
      align={alignInput}
      disableAutoComplete={disableAutoComplete}
      {...inputProps}
    />
  );
}
