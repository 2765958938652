import React from "react";

import { Grid } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import ErrorMessage from "components/ErrorMessage";
import {
  CheckBox,
  Input,
  SelectField,
  TextArea,
} from "components/Form/FormikControls";
import { withNamespace } from "components/Form/FormikControls/lib";
import { SmallText } from "components/Text";

import { ExportSites } from "constants/exportSites";

import { ForNotClearingSale } from "containers/ForSaleType";
import { ForLivestockAgent, ForSaleyardAdmin } from "containers/ForUserType";

import { EMPTY_ARRAY } from "lib";

import {
  getAgencyByDeploymentId,
  getDeploymentById,
  getDeploymentSaleIdByAgencyId,
  getExportSitesByDeploymentId,
  getIsSaleLotExportSitesInSyncByDeploymentSaleIdLookup,
  toExportSiteOption,
} from "selectors";

import { DeploymentSaleInterfaceActions } from "./DeploymentSaleInterfaceActions";

const AuctionsPlusInput = styled(Input)`
  width: ${({ isCreateMode }) => (isCreateMode ? `81%` : `44%`)};
`;

const IdentifierTd = styled.td`
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ExportSiteSelectField = ({ name, dataTour, deploymentId, disabled }) => {
  const exportSiteOptions = useSelector(
    state => getExportSitesByDeploymentId(deploymentId)(state) || EMPTY_ARRAY,
  ).map(toExportSiteOption);

  return (
    <SelectField
      dataTour={dataTour}
      disabled={exportSiteOptions.length === 0 || disabled}
      isMulti
      options={exportSiteOptions}
      name={name}
      menuPortalTarget={document.body}
    />
  );
};

export const DeploymentSaleInterfaceOption = ({
  deploymentId,
  namespace: ns,
  isCreateMode,
  readOnly,
}) => {
  const formikProps = useFormikContext();
  const selectedSaleType = formikProps.values.sale_type;

  const agency = useSelector(getAgencyByDeploymentId(deploymentId)) || {};
  const deployment = useSelector(getDeploymentById(deploymentId)) || {};
  const deploymentSaleId = useSelector(
    getDeploymentSaleIdByAgencyId(agency.id),
  );

  // Deployment name is not a required field, but agency can be ambiguous.
  // Show the deployment name if we have it, falling back to an agency name.
  const primaryAgencyIdentifier = deployment.name || agency.name;
  // Show the agency name if it's different to the primary name
  const secondaryAgencyIdentifier =
    agency.name !== primaryAgencyIdentifier ? agency.name : null;

  const [values, meta] = useField(withNamespace(ns, "export_sites"));

  const areExportSitesChanged = !isEqual(meta.initialValue, values.value);

  const isAuctionsPlusSelected = values.value?.includes(
    ExportSites.AUCTIONS_PLUS,
  );

  // Are there any salelots that don't have the export sites for this deployment sale?
  const saleLotsInSync = useSelector(
    state =>
      !isCreateMode &&
      getIsSaleLotExportSitesInSyncByDeploymentSaleIdLookup(deploymentSaleId)(
        state,
      ),
  );

  return [
    <tr key="form" data-tour={`deployment:${agency.name}`}>
      <ForSaleyardAdmin>
        <IdentifierTd
          title={`${agency.name} - ${deployment.name} - ${deployment.channel}`}
        >
          {primaryAgencyIdentifier}
          {secondaryAgencyIdentifier ? (
            <SmallText>{secondaryAgencyIdentifier}</SmallText>
          ) : null}
        </IdentifierTd>
      </ForSaleyardAdmin>
      <td>
        <ExportSiteSelectField
          dataTour="exportSites"
          name={withNamespace(ns, "export_sites")}
          deploymentId={deploymentId}
          disabled={readOnly}
        />
      </td>
      {!isCreateMode && (
        <>
          <td className="center">
            <CheckBox
              dataTour="manualVendorNumbers"
              name={withNamespace(ns, "manual_vendor_numbers")}
              labelPlacement="bottom"
              disabled={readOnly}
            />
          </td>

          <td className="center">
            <DeploymentSaleInterfaceActions
              deploymentSaleId={deploymentSaleId}
              name={ns}
              disabled={readOnly}
            />
          </td>
        </>
      )}
      <ForLivestockAgent>
        <td>
          <Input
            name={withNamespace(ns, "vendor_terms")}
            type="number"
            disabled={isCreateMode}
          />
        </td>

        <td>
          <Input
            name={withNamespace(ns, "buyer_terms")}
            type="number"
            disabled={isCreateMode}
          />
        </td>
      </ForLivestockAgent>
      <ForNotClearingSale selectedSaleType={selectedSaleType}>
        <td className="center">
          <CheckBox
            name={withNamespace(ns, "use_lot_number_as_pen_number")}
            labelPlacement="bottom"
            disabled={readOnly}
          />
        </td>
      </ForNotClearingSale>
    </tr>,

    <tr key="warning">
      <td />

      <td colSpan={3}>
        {!isCreateMode && (!saleLotsInSync || areExportSitesChanged) ? (
          <>
            <div>
              <ErrorMessage>
                Not all Sale Lots match these Export Sites.
              </ErrorMessage>
            </div>
            <div>
              <CheckBox
                label="Update existing Sale Lots' Export Sites"
                name={withNamespace(ns, "updateExistingSaleLotExportSites")}
                labelPlacement="end"
                disabled={readOnly}
              />
            </div>
          </>
        ) : null}
        {isAuctionsPlusSelected && (
          <div>
            <AuctionsPlusInput
              label="Auctions Plus Link"
              name={withNamespace(ns, "auctions_plus_url")}
              isCreateMode={isCreateMode}
              disabled={readOnly}
            />
          </div>
        )}
      </td>
    </tr>,
    <ForLivestockAgent>
      <tr>
        <td colSpan={6}>
          <Grid item xs={12}>
            <TextArea
              name={withNamespace(ns, "market_report_notes")}
              label="Market Report Notes"
              rows={4}
            />
          </Grid>
        </td>
      </tr>
    </ForLivestockAgent>,
  ];
};
