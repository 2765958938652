import React from "react";

import { ModalTypes } from "constants/navigation";

import { BusinessPaymentsModal } from "./Modal";

export function Adapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.BusinessPayments] || null;
  const [businessId] = JSON.parse(hashParams);
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";

  return (
    <BusinessPaymentsModal businessId={businessId} returnTo={decodedReturnTo} />
  );
}
