import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { MetadataField } from "components/Card/Elements";
import { ChipBag, MarkChips } from "components/Chip";
import { Row } from "components/Layout";
import { MarkingModal } from "components/MarkingModal";

import { userTypes } from "constants/users";

import { ForUserType } from "containers/ForUserType";

import { formatSaleLotOverflow } from "lib/saleLot";

import { getSaleLotsBySale } from "selectors";

import { useToggle } from "hooks";

const MarkBag = styled(ChipBag)`
  cursor: pointer;
  flex-grow: 0;
`;

function SaleLotDetailHeader(props) {
  const { saleLotId } = props;

  const [isMarkingModalVisible, toggleIsMarkingModalVisible] = useToggle(false);

  const saleLot = useSelector(state =>
    getSaleLotsBySale(state).find(saleLot => saleLot.id === saleLotId),
  );

  if (!saleLotId || !saleLot) {
    return null;
  }
  const {
    marks,
    penName,
    scannedCount,
    quantity,
    overflowPen,
    overflowQuantity,
  } = saleLot || {};

  const penText = `${penName} ${formatSaleLotOverflow(
    overflowPen,
    overflowQuantity,
  )}`;

  return (
    <ForUserType
      userType={[userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN]}
    >
      <Row justifyAround alignCenter>
        <MetadataField title="Pen" value={penText} />
        <MetadataField
          title="Scanned"
          value={`${scannedCount} of ${quantity}`}
        />
        <MarkBag onClick={toggleIsMarkingModalVisible} grow={false}>
          <MarkChips marks={marks} showNoMarks />
        </MarkBag>
        {isMarkingModalVisible ? (
          <MarkingModal
            onClose={toggleIsMarkingModalVisible}
            saleLot={saleLot}
          />
        ) : null}
      </Row>
    </ForUserType>
  );
}

export default React.memo(SaleLotDetailHeader);
