import queryString from "query-string";

import { SAVED_VIEW } from "constants/actionTypes";

import { serializeSavedView } from "lib/serializers/savedViews";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ action, changesSince, id } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/saved-views/", id, action),
    query: {
      changesSince,
    },
  });

export const SavedViewAction = offlineActionCreator(
  SAVED_VIEW,
  serializeSavedView,
  urlCreator,
  false,
);
