import React from "react";

import { get } from "lodash";

import { ActionButton } from "components/AgGrid/ActionButton";

import { openEditBusinessModal } from "lib/navigation";

export const formatRelationship = relationship =>
  `${relationship.relationType} (${relationship.percentage * 100}%)`;

export const BusinessRelationshipRenderer = ({ node, colDef, data }) => {
  const value = get(data, colDef.field);

  if (node.group || !value) {
    return null;
  }

  return value.map(relationship => {
    return (
      <ActionButton
        key={relationship.id}
        onClick={() => openEditBusinessModal(relationship.relatedToId)}
      >
        {formatRelationship(relationship)}
      </ActionButton>
    );
  });
};
