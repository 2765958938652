import React from "react";

import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { useSelector } from "react-redux";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { colors } from "constants/theme";

import { getAuctionPenDisplayName, sortPenByOrder } from "lib/auctionPens";
import { createAndOpenPdf } from "lib/pdf";
import { getAverageWeightKg, getCombinedLotNumber } from "lib/saleLot";

import {
  currentSaleSelector,
  getAuctionPens,
  getDeployments,
  getSaleLotsBySale,
  selectDeploymentPlacardLogoUrlByDeploymentId,
  selectDeploymentSaleIdBySaleLotIdLookup,
} from "selectors";

const padding = () => 14;

const layout = {
  hLineWidth: i => (i === 1 ? 1 : 0),
  // Put vertical lines around the type column.
  vLineWidth: i => (i === 1 || i === 3 ? 1 : 0),
  hLineColor: () => colors.titleMedium,
  paddingTop: padding,
  paddingBottom: padding,
};

// Scale the image so its half a page wide.
const standardImageWidth = 800;
const standardImageHeight = 150;
const a4LandscapeWidth = 841;
const imageScale = standardImageWidth / (a4LandscapeWidth / 2); // To take up half the right space,

const getAuctionPenPage = (auctionPen, saleLots, deploymentLogo, pageBreak) => {
  return [
    {
      // Position the logo, absolutely.
      image: deploymentLogo,
      width: standardImageWidth / imageScale,
      height: standardImageHeight / imageScale,
      absolutePosition: { x: a4LandscapeWidth / 2, y: 50 },
    },
    {
      style: "heading",
      table: {
        widths: ["*", "*"],
        body: [
          [
            {
              text: `Pen: ${getAuctionPenDisplayName(auctionPen)}`,
              alignment: "left",
              style: "pen",
            },
          ],
        ],
      },
      layout: "noBorders",
    },
    {
      style: "body",
      table: {
        widths: ["*", "*"],
        body: [
          [
            {
              layout,
              table: {
                headerRows: 1,
                widths: [90, 90, 150, 135, 130, 130],
                body: [
                  [
                    { text: "LOT", style: "bodyHeader" },
                    { text: "QTY", style: "bodyHeader" },
                    { text: "TYPE", style: "bodyHeader" },
                    { text: "AVE. KG", style: "bodyHeader" },
                    { text: "MARK", style: "bodyHeader" },
                    {},
                  ],
                  ...sortBy(saleLots, "lot_number").map(s => {
                    const weight = Math.round(getAverageWeightKg(s));
                    return [
                      getCombinedLotNumber(s),
                      s.quantity,
                      s.sex.code || "-",
                      {
                        text: weight,
                        fontSize: weight > 999 ? 58 : 72,
                        marginTop: weight > 999 ? 12 : 0,
                      },
                      s.marks[0]?.location?.slice(0, 2) || "-",
                      {
                        text: "VB  GF  NA  NEE  MSA",
                        style: "accreditions",
                      },
                    ];
                  }),
                ],
              },
            },
          ],
        ],
      },
      layout: "noBorders",
      pageBreak: pageBreak ? "after" : undefined,
    },
  ];
};

const CattlePenCardComponent = ({ auctionPenId, roundId, agentId }) => {
  let saleLotsInSale = useSelector(getSaleLotsBySale);
  const auctionPens = useSelector(getAuctionPens);
  const deployments = useSelector(getDeployments);
  const deploymentSaleIdBySaleLotIdLookup = useSelector(
    selectDeploymentSaleIdBySaleLotIdLookup,
  );
  const deploymentPlacardLogos = useSelector(
    selectDeploymentPlacardLogoUrlByDeploymentId,
  );

  if (agentId) {
    saleLotsInSale = saleLotsInSale.filter(
      saleLot => saleLot.livestockAgency.id === parseInt(agentId, 10),
    );
  }

  const saleLotsByAuctionPens = groupBy(
    Object.values(saleLotsInSale),
    "auction_pen_id",
  );

  const currentSale = useSelector(currentSaleSelector);

  const auctionPenIdsInRound = sortPenByOrder(Object.values(auctionPens))
    .filter(a => a.saleRoundId === +roundId)
    .map(a => a.id);

  const auctionPenIds =
    auctionPenId === "all" ? auctionPenIdsInRound : [auctionPenId];

  const content = auctionPenIds
    .map((penId, i) => {
      const auctionPen = auctionPens[penId];
      const saleLots = saleLotsByAuctionPens[penId];
      if (!saleLots || saleLots.length === 0) {
        return null;
      }
      const deploymentSaleId =
        deploymentSaleIdBySaleLotIdLookup[saleLots[0].id];
      const deployment_sale = currentSale.deployment_sales.find(
        ds => ds.deployment_sale_id === deploymentSaleId,
      );

      const deployment = deployments[deployment_sale.deployment_id];

      const deploymentLogo = deploymentPlacardLogos[deployment.id];
      const pageBreak = i !== auctionPenIds.length - 1;

      if (saleLots) {
        return getAuctionPenPage(
          auctionPen,
          saleLots,
          deploymentLogo,
          pageBreak,
        );
      }
      return null;
    })
    .filter(Boolean);

  const images = currentSale.deployment_sales.reduce((acc, ds) => {
    const deployment = deployments[ds.deployment_id];
    const deploymentLogo = deploymentPlacardLogos[deployment.id];
    acc[deploymentLogo] = deploymentLogo;
    return acc;
  }, {});

  const pdfDefinition = {
    pageSize: "A4", // A4: [595.28, 841.89],
    margins: [0, 0, 0, 0],
    pageOrientation: "landscape",
    content,
    styles: {
      accreditions: {
        fontSize: 26,
        marginTop: 12,
      },

      smaller: {
        fontSize: 22,
        marginTop: 12,
      },
      pen: {
        fontSize: 72,
      },
      heading: {
        fontSize: 32,
      },
      body: {
        fontSize: 72,
        color: "black",
        alignment: "center",
      },
      bodyHeader: {
        bold: true,
        fontSize: 20,
        color: "#666666",
      },
    },
    defaultStyle: {
      bold: true,
    },
    images,
  };

  createAndOpenPdf(pdfDefinition);

  return <div />;
};

export const CattlePenCard = ({ match }) => {
  const { auctionPenId, roundId, agentId } = match.params;

  // When opening a new window, we go and re-fetch everything - this leads to opening many tabs as each component re-render occurs
  // due to the large getSaleLotsBySale selector.  It references (lots of things) so wait for (all the things)
  // Similarly - if this renders BEFORE anything is loading, it will fire off a blank window - so wait 2 seconds before
  // firing.
  return (
    <WaitForSync delay={2000}>
      <CattlePenCardComponent
        auctionPenId={auctionPenId}
        roundId={roundId}
        agentId={agentId}
      />
    </WaitForSync>
  );
};
