import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import styled from "styled-components/macro";
import {
  SaleProgressSpeciesAttributeTableProps,
  SaleProgressTotalsAttributeTable,
} from "./SaleProgressTotalsAttributeTable";

const ScrollableAccordionDetails = styled(AccordionDetails)`
  overflow-x: scroll;
`;

interface SaleTotalsAttributeTableProps<Type>
  extends SaleProgressSpeciesAttributeTableProps<Type> {
  square?: boolean;
}

export function SaleProgressTotalsAttributeAccordion<Type>(
  props: SaleTotalsAttributeTableProps<Type>,
): React.JSX.Element {
  const {
    name,
    saleLotAttributeField,
    attributeLookupSelector,
    square = false,
  } = props;

  const expandIcon = <FontAwesomeIcon icon={faCaretDown} size="sm" />;

  return (
    <Accordion square={square}>
      <AccordionSummary
        id={`${name.toLowerCase()}-panel-header`}
        expandIcon={expandIcon}
      >
        <span className="font-bold">{name}</span>
      </AccordionSummary>
      <ScrollableAccordionDetails>
        <SaleProgressTotalsAttributeTable
          name={name}
          saleLotAttributeField={saleLotAttributeField}
          attributeLookupSelector={attributeLookupSelector}
        />
      </ScrollableAccordionDetails>
    </Accordion>
  );
}
