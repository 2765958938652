import React, { memo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { CommentIcon } from "components/Comments/Icon";
import {
  OpenBuyerEdit,
  OpenVendorEdit,
} from "components/DeploymentSaleSummaries/BusinessCells";
import { CellButton } from "components/DeploymentSaleSummaries/CellButton";
import {
  OpenGeneralCell,
  OpenPenningCell,
  OpenSaleCell,
} from "components/DeploymentSaleSummaries/SaleLotCells";
import { SummaryReactTable } from "components/DeploymentSaleSummaries/SummaryTable";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { openEditSaleLotModal } from "lib/navigation";

import {
  getAuctionPens,
  getBusinesses,
  getConsignments,
  getIsEditableBySaleLotId,
  getProperties,
  getSaleLotIdsWithUnresolvedCommentsByDeploymentSaleId,
  getSaleLots,
  selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
} from "selectors";

function CommentsCell(props) {
  // Given we know this is displaying for unresolved, just show that icon.
  const { original } = props;
  const { saleLotId } = original;

  const isEditable = useSelector(getIsEditableBySaleLotId(saleLotId));

  return (
    <CellButton
      disabled={!isEditable}
      onClick={() => openEditSaleLotModal(saleLotId)}
    >
      <CommentIcon hasUnresolvedComments size="2x" />
    </CellButton>
  );
}

const SALE_LOT_WARNINGS_COLUMNS = [
  { Header: "Vendor Name", accessor: "vendorName", Cell: OpenVendorEdit },
  { Header: "Buyer Name", accessor: "buyerName", Cell: OpenBuyerEdit },
  { Header: "Buyer PIC", accessor: "buyerPic", Cell: OpenSaleCell },
  { Header: "Pen #", accessor: "pen", Cell: OpenPenningCell },
  { Header: "Drafted HC", accessor: "quantity", Cell: OpenGeneralCell },
  {
    Header: "Comments",
    accessor: "unresolvedSaleLotCommentCount",
    Cell: CommentsCell,
  },
];

function UnresolvedSaleLotCommentsTable(props) {
  const { deploymentSaleId } = props;
  const saleLotIds = useSelector(
    getSaleLotIdsWithUnresolvedCommentsByDeploymentSaleId(deploymentSaleId),
  );

  const saleLots = useSelector(getSaleLots);
  const consignments = useSelector(getConsignments);
  const auctionPens = useSelector(getAuctionPens);
  const properties = useSelector(getProperties);
  const businesses = useSelector(getBusinesses);
  const unresolvedSaleLotCommentCountBySaleLotIdLookup = useSelector(
    selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
  );

  const data = saleLotIds.map(saleLotId => {
    const saleLot = saleLots[saleLotId];

    const {
      auction_pen_id: auctionPenId,
      buyer_id: buyerId,
      consignment_id: consignmentId,
      destination_property_id: destinationPropertyId,
      quantity,
    } = saleLot;
    const auctionPen = auctionPens[auctionPenId];
    const vendorId = consignments[consignmentId]?.vendor_id;

    return {
      vendorName: businesses[vendorId]?.name,
      vendorId,
      buyerName: businesses[buyerId]?.name,
      buyerId,
      buyerPic: properties[destinationPropertyId]?.PIC,
      pen: getAuctionPenDisplayName(auctionPen),
      quantity,
      saleLotId,
      unresolvedSaleLotCommentCount:
        unresolvedSaleLotCommentCountBySaleLotIdLookup[saleLotId],
    };
  });

  return (
    <SummaryReactTable data={data} columns={SALE_LOT_WARNINGS_COLUMNS} c />
  );
}

UnresolvedSaleLotCommentsTable.propTypes = {
  deploymentSaleId: PropTypes.number.isRequired,
};

export default memo(UnresolvedSaleLotCommentsTable);
