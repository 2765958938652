import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { CheckingCard } from "components/Pdfs";

import { getPensByAgentId } from "lib/pdf";
import { saleIdOnly, saleIdRoundIdOnly } from "lib/sale";

import {
  getAuctionPensWithSaleLotsBySaleAndRound,
  currentSaleSelector,
  getOrganizationName,
  currentRoundSelector,
} from "selectors";

const mapStateToProps = (state, props) => ({
  auctionPens: props.match.params?.agentId
    ? getPensByAgentId(
        getAuctionPensWithSaleLotsBySaleAndRound(
          state,
          saleIdRoundIdOnly(props),
        ),
        props.match.params?.agentId,
      )
    : getAuctionPensWithSaleLotsBySaleAndRound(state, saleIdRoundIdOnly(props)),
  sale: currentSaleSelector(state, saleIdOnly(props)),
  agencyName: getOrganizationName(state),
  round: currentRoundSelector(state, saleIdRoundIdOnly(props)),
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(CheckingCard);
