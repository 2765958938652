import { caseInsensitiveCompare } from "lib/compare";

export function buyerWayDefaultPropertyFilter(buyerWayName, saleyardId) {
  return buyerWay => {
    if (!buyerWay.isShown) {
      return false;
    }
    if (!buyerWay.destinationPropertyId) {
      return false;
    }
    if (caseInsensitiveCompare(buyerWay.name, buyerWayName)) {
      return false;
    }
    if (
      // The shape of the businesses in state was from the release prior to
      // AG-4856 Or the business has just been created and the serialiser hasn't
      // put the attribute on the business yet
      !Array.isArray(buyerWay.saleyardIds)
    ) {
      return false;
    }

    if (
      // No Saleyard was provided, don't perform any filtering on the Buyer Way Saleyard
      saleyardId === null ||
      // The Buyer Way doesn't specify any Saleyards to filter on
      buyerWay.saleyardIds.length === 0
    ) {
      return true;
    }

    // This is a match when the Saleyard is in the Buyer Way's Saleyard list
    return buyerWay.saleyardIds.includes(saleyardId);
  };
}

export function sortBuyerWayProperties(saleyardId) {
  // Used alongside buyerWayDefaultPropertyFilter
  // this function orders the buyer ways by their match against the provided saleyard and the buyer way's saleyard_id

  return (buyerWayA, buyerWayB) => {
    // When the provided saleyard is saleyard set to null, prefers buyer ways with no saleyard_ids
    if (saleyardId === null) {
      const aLength = buyerWayA.saleyardIds?.length || 0;
      const bLength = buyerWayB.saleyardIds?.length || 0;
      if (aLength === 0 && bLength === 0) {
        // we don't know which saleyard to use and there are no saleyards on either Buyer Way. They are equal
        return 0;
      } else if (aLength === 0) {
        // we don't know which saleyard to use, buyerWayA applies to all saleyards, and buyerWayB applies to a specific saleyard (or set of), prefer that buyerWayA
        return -1;
      }
      // we don't know which saleyard to use, buyerWayA applies to a specific saleyard (or set of) and buyerWayB applies to all saleyards prefer that buyerWayB
      return 1;
    }
    // When the provided saleyard is set, prefers buyer ways matching with the provided saleyard
    const aMatch = buyerWayA.saleyardIds.includes(saleyardId);
    const bMatch = buyerWayB.saleyardIds.includes(saleyardId);
    if (aMatch && bMatch) {
      return 0;
    } else if (aMatch) {
      return -1;
    }
    return 1;
  };
}

export const getDefaultPropertyId = (
  business,
  saleyardId,
  buyerWayName = null,
) => {
  if (!business) {
    return null;
  }

  const buyerWays = buyerWayName
    ? business.buyerWays
        .filter(buyerWayDefaultPropertyFilter(buyerWayName, saleyardId))
        .sort(sortBuyerWayProperties(saleyardId))
    : [];

  if (Array.isArray(buyerWays) && buyerWays.length > 0) {
    // There was a matching buyer way, use the property from the first one - they are ordered by best match
    return buyerWays[0].destinationPropertyId;
  }

  if (business.defaultDestinations) {
    const defaultDestination = business.defaultDestinations.find(
      destination => destination.saleyardId === saleyardId,
    );
    if (defaultDestination) {
      return defaultDestination.propertyId;
    }
  }

  if (Array.isArray(business.properties) && business.properties.length === 1) {
    return business.properties[0].id;
  }

  return null;
};

const NLIS_LPA_PROGRAM_CODE = "LPA";
export const getLPAStatusFromPrograms = programs =>
  programs.find(program => program.code === NLIS_LPA_PROGRAM_CODE)?.status ||
  "";

export const isLpaAccredited = lpaCode => ["A", "A1"].includes(lpaCode);
