import React from "react";

import { Table, TableContainer, TableHead } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { requestAuditLog } from "actions/auditLog";

import { AuditRow } from "components/AuditLog/AuditRow";
import { CenteredGreedy } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";

const LoadingWrapper = styled(CenteredGreedy)`
  padding: ${({ theme }) => theme.space[2]}px;
`;

export const AuditLogTable = ({ dataType, dataId, queryParams }) => {
  const auditActions = useSelector(state => state.auditLog)?.[dataType]?.[
    dataId
  ];
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(requestAuditLog(dataType, dataId, queryParams));
  }, [dataType, dataId, dispatch, queryParams]);

  if (auditActions === null) {
    return (
      <CenteredGreedy>There was an error fetching the history.</CenteredGreedy>
    );
  } else if (typeof auditActions === "undefined") {
    return (
      <LoadingWrapper>
        <LoadingSpinner subjectName="Changes" />
      </LoadingWrapper>
    );
  }
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Timestamp</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Info</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {auditActions.map((action, idx) => (
            <AuditRow
              action={action}
              key={`audit-row-${idx}`}
              previousChanges={auditActions?.[idx + 1]?.changes}
              dataType={dataType}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
