import { flatten, get, isEmpty, isEqual, uniq } from "lodash";
import { createSelector } from "reselect";

import {
  getFieldsWithAlternativeValues,
  getNamespacedCompareFieldNames,
} from "components/BusinessForm/lib";

import { AlternativeComparableKeys } from "constants/businesses";
import { IntegrationTypes } from "constants/integrations";
import { Settings } from "constants/settings";

import { getDateFromISO8601DateString } from "lib/timeFormats";

import {
  BusinessTypes,
  expandAlternatives,
  getBusinesses,
  getCurrentSale,
  getDeployments,
  getSaleyards,
  getSettings,
  selectBuyerIdBySaleLotIdLookup,
  selectDeploymentIdByManualAdjustmentIdLookup,
  selectDeploymentIdBySaleLotIdLookup,
  selectFromBusinessIdByManualAdjustmentIdLookup,
  selectManualAdjustmentIdsByLivestockSaleId,
  selectorInvoiceToBusinessIdBySaleLotIdLookup,
  selectorThirdPartyBusinessIdBySaleLotIdLookup,
  selectToBusinessIdByManualAdjustmentIdLookup,
  selectVendorIdBySaleLotIdLookup,
} from "selectors";

const selectBusinessesIdsInSaleByTypeAndDeploymentId = createSelector(
  [
    selectVendorIdBySaleLotIdLookup,
    selectBuyerIdBySaleLotIdLookup,
    selectorInvoiceToBusinessIdBySaleLotIdLookup,
    selectorThirdPartyBusinessIdBySaleLotIdLookup,
    selectDeploymentIdBySaleLotIdLookup,
    selectManualAdjustmentIdsByLivestockSaleId,
    selectFromBusinessIdByManualAdjustmentIdLookup,
    selectToBusinessIdByManualAdjustmentIdLookup,
    selectDeploymentIdByManualAdjustmentIdLookup,
    getCurrentSale,
  ],
  (
    vendorIdBySaleLotIdLookup,
    buyerIdBySaleLotIdLookup,
    invoiceToBusinessIdBySaleLotIdLookup,
    thirdPartyBusinessIdBySaleLotIdLookup,
    deploymentIdBySaleLotIdLookup,
    manualAdjustmentIdsByLivestockSaleId,
    fromBusinessIdByManualAdjustmentIdLookup,
    toBusinessIdByManualAdjustmentIdLookup,
    deploymentIdByManualAdjustmentIdLookup,
    currentSale,
  ) => {
    const groupBusinessByDeployment = (acc, [saleLotId, businessId]) => {
      if (businessId) {
        const deploymentId = deploymentIdBySaleLotIdLookup[saleLotId];
        if (!acc[deploymentId]) {
          acc[deploymentId] = new Set();
        }
        acc[deploymentId].add(businessId);
      }
      return acc;
    };

    const buyersIds = Object.entries(buyerIdBySaleLotIdLookup).reduce(
      groupBusinessByDeployment,
      {},
    );

    const invoiceToBusinessIds = Object.entries(
      invoiceToBusinessIdBySaleLotIdLookup,
    ).reduce(groupBusinessByDeployment, {});

    const thirdPartyBusinessIds = Object.entries(
      thirdPartyBusinessIdBySaleLotIdLookup,
    ).reduce(groupBusinessByDeployment, {});

    const vendorIds = Object.entries(vendorIdBySaleLotIdLookup).reduce(
      groupBusinessByDeployment,
      {},
    );

    const manualAdjustmentIdsInSale =
      manualAdjustmentIdsByLivestockSaleId[currentSale?.livestocksale_id] || [];
    const sundryFromBuisnessIds = {};
    const sundryToBuisnessIds = {};
    for (const adjustmentId of manualAdjustmentIdsInSale) {
      const fromBusinessId =
        fromBusinessIdByManualAdjustmentIdLookup[adjustmentId];
      const toBusinessId = toBusinessIdByManualAdjustmentIdLookup[adjustmentId];
      const deploymentId = deploymentIdByManualAdjustmentIdLookup[adjustmentId];

      if (!sundryFromBuisnessIds[deploymentId]) {
        sundryFromBuisnessIds[deploymentId] = new Set();
      }
      sundryFromBuisnessIds[deploymentId].add(fromBusinessId);

      if (!sundryToBuisnessIds[deploymentId]) {
        sundryToBuisnessIds[deploymentId] = new Set();
      }
      sundryToBuisnessIds[deploymentId].add(toBusinessId);
    }

    return {
      [BusinessTypes.BUYER]: buyersIds,
      [BusinessTypes.INVOICEE]: invoiceToBusinessIds,
      [BusinessTypes.THIRD_PARTY]: thirdPartyBusinessIds,
      [BusinessTypes.VENDOR]: vendorIds,
      [BusinessTypes.SUNDRY_FROM]: sundryFromBuisnessIds,
      [BusinessTypes.SUNDRY_TO]: sundryToBuisnessIds,
    };
  },
);

const selectDeploymentsAndBusinessesInSaleByIdLookup = createSelector(
  [
    getBusinesses,
    selectBusinessesIdsInSaleByTypeAndDeploymentId,
    getDeployments,
    getSaleyards,
  ],
  (businesses, businessIdsByType, deploymentsLookup, saleyardsLookup) => {
    const result = {};

    Object.entries(businessIdsByType).forEach(([businessType, dataSet]) => {
      Object.entries(dataSet).forEach(([deploymentId, businessIds]) => {
        const deploymentName = deploymentsLookup[deploymentId]?.name;
        businessIds.forEach(businessId => {
          const business = expandAlternatives(
            { ...(businesses[businessId] || {}) },
            deploymentsLookup,
            saleyardsLookup,
          );

          if (isEmpty(business)) {
            return;
          }

          const lastModified = business.lastModified
            ? getDateFromISO8601DateString(business.lastModified)
            : null;
          const lastReviewed = business.lastReviewed
            ? getDateFromISO8601DateString(business.lastReviewed)
            : null;

          const hasAlternativeNewerThanReviewed = business.alternatives.some(
            altBusiness => altBusiness.lastModified > lastReviewed,
          );
          const hasAlternativeNewerThanModified = business.alternatives.some(
            altBusiness => altBusiness.lastModified > lastModified,
          );

          const outOfSyncFields = [].concat(
            getFieldsWithAlternativeValues(AlternativeComparableKeys, business),
            ...business.properties.map((property, idx) =>
              getFieldsWithAlternativeValues(
                getNamespacedCompareFieldNames(
                  "properties",
                  AlternativeComparableKeys,
                  idx,
                ),
                property,
              ),
            ),
            ...business.emailRecipients.map((emailRecipient, idx) =>
              getFieldsWithAlternativeValues(
                getNamespacedCompareFieldNames(
                  "emailRecipients",
                  AlternativeComparableKeys,
                  idx,
                ),
                emailRecipient,
              ),
            ),
          );

          if (businessId in result) {
            result[businessId].roleInSale.push({
              businessType,
              deploymentName,
            });
          } else {
            result[businessId] = {
              business,
              roleInSale: [{ businessType, deploymentName }],
              outOfSyncFields,
              lastModified,
              lastReviewed,
              hasAlternativeNewerThanReviewed,
              hasAlternativeNewerThanModified,
              xeroIntegrationBusiness: business.integrationBusinesses?.find(
                ib => ib.type === IntegrationTypes.Xero,
              ),
            };
          }
        });
      });
    });
    return result;
  },
);

const selectDeploymentsAndBusinessesInSaleData = createSelector(
  [selectDeploymentsAndBusinessesInSaleByIdLookup],
  deploymentsAndBusinessesInSaleLookup =>
    Object.values(deploymentsAndBusinessesInSaleLookup),
);

export const selectAlternativeFieldOptions = createSelector(
  [selectDeploymentsAndBusinessesInSaleData],
  businessInSaleData => {
    return uniq(flatten(businessInSaleData.map(b => b.outOfSyncFields)));
  },
);

export const selectRoleInSaleOptions = createSelector(
  [selectDeploymentsAndBusinessesInSaleData],
  businessInSaleData => {
    return uniq(
      flatten(
        businessInSaleData.map(b =>
          b.roleInSale.reduce((roleTypes, role) => {
            roleTypes.push(role.businessType);
            return roleTypes;
          }, []),
        ),
      ),
    );
  },
);

export const selectAlternativeSourceOptions = createSelector(
  [selectDeploymentsAndBusinessesInSaleData],
  businessInSaleData => {
    return Object.keys(
      businessInSaleData.reduce((acc, { business }) => {
        business.alternatives.forEach(alt => {
          acc[alt.sourceName] = alt.sourceName;
        });
        return acc;
      }, {}),
    );
  },
);

export const selectFilteredAlternativesByBusinessId = createSelector(
  [selectDeploymentsAndBusinessesInSaleData, getSettings],
  (businesses, settings) => {
    const filterByAlternativeSource = get(
      settings,
      Settings.resolveAlternatives.filterByAlternativeSource,
      [],
    );

    const filteredFields = get(
      settings,
      Settings.resolveAlternatives.filteredFields,
      [],
    );

    const filteredRolesValues = get(
      settings,
      Settings.resolveAlternatives.filterByRoleInSale,
      [],
    );

    const filterByLastReviewed = get(
      settings,
      Settings.resolveAlternatives.filterByLastReviewed,
    );

    const filterByLastModified = get(
      settings,
      Settings.resolveAlternatives.filterByLastModified,
    );

    const filterEmptyValues = get(
      settings,
      Settings.resolveAlternatives.filterEmptyValues,
    );

    return businesses.reduce(
      (
        acc,
        { roleInSale, business, outOfSyncFields, lastModified, lastReviewed },
      ) => {
        if (outOfSyncFields.length === 0) {
          return acc;
        }

        const filteredRoles = [];
        roleInSale.forEach(({ businessType, deploymentName }) => {
          const includesRole = filteredRolesValues
            ? filteredRolesValues.includes(businessType)
            : true;
          const includesDeployment = filterByAlternativeSource
            ? filterByAlternativeSource.includes(deploymentName)
            : true;
          if (includesRole && includesDeployment) {
            filteredRoles.push({ businessType, deploymentName });
          }
        });
        if (filteredRoles.length === 0) {
          return acc;
        }

        const filteredAlternatives = business.alternatives.filter(
          altBusiness => {
            // Filter by field values
            if (
              filteredFields.filter(fieldName => {
                if (filterEmptyValues && isEmpty(altBusiness[fieldName])) {
                  // Empty Value
                  return false;
                } else {
                  // Is the value different
                  return !isEqual(altBusiness[fieldName], business[fieldName]);
                }
              }).length === 0
            ) {
              return false;
            }

            // Filter by selected sources.
            if (
              filterByAlternativeSource &&
              !filterByAlternativeSource.includes(altBusiness.sourceName)
            ) {
              return false;
            }

            // Filter by last modified
            if (
              filterByLastModified &&
              business.lastModified > altBusiness.lastModified
            ) {
              return false;
            }

            // Filter by last reviewed
            if (
              filterByLastReviewed &&
              business.lastReviewed > altBusiness.lastModified
            ) {
              return false;
            }

            return true;
          },
        );

        if (filteredAlternatives.length === 0) {
          return acc;
        }

        acc[business.id] = {
          ...business,
          lastModified,
          lastReviewed,
          outOfSyncFields,
          alternatives: filteredAlternatives,
        };
        return acc;
      },
      {},
    );
  },
);

export const getFilteredAlternativesByBusinessId = businessId => state =>
  selectFilteredAlternativesByBusinessId(state)[businessId];
