import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { setSetting } from "actions";

import { FullWidthSelect } from "components/SearchableSelector/ReactSelect";

import { Settings } from "constants/settings";

import { getDraftingInformation, getSetting } from "selectors";

const DraftingInformationSwitcher = () => {
  const dispatch = useDispatch();

  const onChange = e => {
    dispatch(setSetting(Settings.saleWatcherDraftingInformationId, e.value));
  };
  const currentSelection =
    useSelector(getSetting(Settings.saleWatcherDraftingInformationId)) || {};

  const draftingInformations = useSelector(getDraftingInformation);
  const options = Object.values(draftingInformations)
    .filter(draftingInformation => draftingInformation.selectableOnSaleWatcher)
    .map(draftingInformation => ({
      label: draftingInformation.commonName,
      value: draftingInformation.id,
    }));

  const currentDraftingInformation = options.find(
    o => o.value === currentSelection,
  );

  return (
    <FullWidthSelect
      isClearable={false}
      value={currentDraftingInformation}
      options={options}
      onChange={onChange}
      menuPlacement="top"
      menuPortalTarget={document.body}
    />
  );
};

export default DraftingInformationSwitcher;
