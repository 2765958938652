import React, { useState, useRef, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import styled from "styled-components/macro";

const Error = styled.div`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  color: ${({ theme }) => theme.colors.deleteRed};
  text-align: center;
  font-size: 2rem;
`;

const Info = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}px) {
    width: 100%;
  }
`;

const Label = styled.label`
  font-size: 3rem;
`;

const Number = styled.input`
  width: 100%;
  outline: none;
  border: none;
  text-align: center;
  font-size: 2rem;
  background: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export function SplitDiff({ name, existingCount, onError }) {
  function checkIsError(existing, split) {
    if (existing - split < 1) {
      return -1;
    } else if (split === 0) {
      return 1;
    }
    return 0;
  }

  const { setFieldValue } = useFormikContext();

  const [split, setSplit] = useState(1);

  React.useEffect(() => {
    setFieldValue(name, split);
  }, [setFieldValue, name, split]);

  const splitNumberInput = useRef(null);
  // Always put the focus on the input on render.
  useEffect(() => {
    if (onError) {
      if (checkIsError(existingCount, split)) {
        onError(true);
      } else {
        onError(false);
      }
    }
  }, [existingCount, split, onError]);

  useEffect(() => {
    splitNumberInput.current.focus();
    splitNumberInput.current.select();
  }, [existingCount]);

  const remainingCount = existingCount - split;
  const isError = checkIsError(existingCount, split);

  const setSplitNumber = event => {
    const value = parseInt(event.target.value, 10) || 0; // convert to number

    if (value !== split) {
      setSplit(value);
      const error = checkIsError(existingCount, value);
      if (error) {
        onError && onError(error);
      } else {
        onError && onError(error);
      }
    }
  };

  return (
    <div>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Info>
            <Label>Existing</Label>
            <Number value={remainingCount} disabled />
          </Info>
        </Grid>
        <Grid item xs={6}>
          <Info>
            <Label>New</Label>
            <Number
              data-tour="newNumber"
              ref={splitNumberInput}
              type="number"
              max={existingCount - 1}
              min="1"
              onChange={setSplitNumber}
              value={split}
            />
          </Info>
        </Grid>
      </Grid>
      <Error visible={isError}>
        {isError > 0 ? "Enter a number of animals" : "Too many animals"}
      </Error>
    </div>
  );
}
