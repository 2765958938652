import * as Sentry from "@sentry/react";
import { size } from "lodash";
import { eventChannel } from "redux-saga";
import { call, put, select, take, takeEvery } from "redux-saga/effects";

import {
  addScanFromScanner,
  deviceConnected,
  deviceMessage,
  eraseSavedScans,
  eraseSavedScansResult,
  getDeviceTime,
  getDeviceTimeResult,
  hubConnected,
  hubDisconnected,
  loadSavedScans,
  resetDevice,
  sendDeviceRequestReadWeighingSession,
  sendRawHubPayload,
  setAvailableDevices,
  setDeviceTime,
  setDeviceTimeResult,
} from "actions";

import {
  CONFIRM_ERASE_SAVED_SCANS,
  CONFIRM_RESET_DEVICE,
  CONFIRM_SET_DEVICE_TIME,
  CONNECT_TO_DEVICE,
  CONNECT_TO_HUB,
  DEVICE_CONNECTED,
  DISCONNECT_FROM_DEVICE,
  ERASE_SAVED_SCANS,
  ERASE_SAVED_SCANS_RESULT,
  GET_AVAILABLE_DEVICES,
  GET_DEVICE_TIME,
  GET_DEVICE_TIME_RESULT,
  IMPORT_MT_HOST_SESSIONS,
  PING_FROM_APP,
  PRINT_RECEIPT,
  READ_SAVED_SCANS,
  READ_SAVED_SCANS_RESULT,
  RESEND_SCAN,
  RESET_DEVICE,
  SEND_DEVICE_MESSAGE,
  SEND_DEVICE_REQUEST_LIST_WEIGHING_SESSIONS,
  SEND_DEVICE_REQUEST_READ_WEIGHING_SESSION,
  SEND_RAW_HUB_PAYLOAD,
  SET_DEVICE_TIME,
  SET_DEVICE_TIME_RESULT,
  START_DRAFT,
} from "constants/actionTypes";
import {
  DEVICE_TIME_DIFFERENCE_ERROR_THRESHOLD,
  DEVICE_TIME_DIFFERENCE_ERROR_THRESHOLD_ENGLISH,
  MIN_HUB_VERSION,
  MIN_IOS_HUB_VERSION,
  scannerMessages,
} from "constants/scanner";

import { confirmDialogWithSubAction } from "lib/confirmDialog";
import {
  getCapabilityHandler,
  getDeviceTypeCapabilites,
} from "lib/deviceDrivers";
import { isIOSApp } from "lib/devices";
import { WebSocketHubConnection, IOSHubConnection } from "lib/hub";
import { isSentryActive } from "lib/sentry/config";
import { formatDateTimeString } from "lib/timeFormats";
import toast from "lib/toast";

import { getAvailableDevices } from "selectors";

import history from "appHistory";

let hubChannelEmitter = null;

/**
 * This function captures a redux saga channel emitter for the purposes of
 * making it available to non-generator functions.
 * @param  emitter
 * @returns {onDataChannelClosed}
 */
function captureDataChannelEmitter(emitter) {
  // obtain a copy of the event emitter for the dataChannel
  hubChannelEmitter = emitter;

  // provide a function to the "eventChannel create" function
  // to call when the channel is closed
  return function noop() {};
}
const hubChannel = eventChannel(captureDataChannelEmitter);

/*
 * If running in the iOS app, the webview will expose the webkit message handler
 * see https://developer.apple.com/documentation/webkit/wkscriptmessagehandler
 * otherwise, fallback to the websocket hub connection
 */
const hubConnection = isIOSApp()
  ? new IOSHubConnection(window, MIN_IOS_HUB_VERSION)
  : new WebSocketHubConnection("ws://localhost:8887/client", MIN_HUB_VERSION);

/**
 * An async worker function to process the actions being emitted from an event
 * channel, by re-emitting them on the main/global redux saga event queue.
 * In effect, this will consume any action emitted from the worker channel in an
 * async fashion, and `put` them into a root saga's work queue via it the
 * yielding this function.
 * @param reduxChannel
 * @returns {IterableIterator<*>}
 */
function* dataCallbackWorker(reduxChannel) {
  while (true) {
    try {
      yield put(yield take(reduxChannel));
    } catch (error) {
      // ensure that any other user code doesn't affect this worker.
      if (isSentryActive) {
        Sentry.captureException(error);
      }
    }
  }
}

function toastErrorMessageFactory(errorAction) {
  return function toastErrorMessage(errorString) {
    toast.error(`Error ${errorAction}. ${errorString}`);
  };
}

function toastSuccessMessageFactory(text) {
  return function toastSuccessMessage() {
    toast.success(text);
  };
}

/**
 * Wrapper function to provide the channel emitter to the HubConnection
 * "connected" event
 * @param emit
 * @returns {onHubConnectedHandler}
 */
function hubConnectedHandler(emit) {
  return function onHubConnectedHandler() {
    emit(hubConnected());
  };
}

/**
 * Wrapper function to provide the channel emitter to the HubConnection
 * "closed" event
 * @param emit
 * @returns {onConnectionClosedHandler}
 */
function hubDisconnectedHandler(emit) {
  return function onConnectionClosedHandler(event) {
    // eslint-disable-next-line no-console
    console.log("onConnectionClosedHandler: ", event);
    emit(hubDisconnected());
  };
}

function onVersionHandler({ minVersion, connectingVersion }) {
  if (minVersion > connectingVersion) {
    toast.error(
      `AgriNous Hub (v{connectingVersion}) is out of date. Visit the store to update`,
    );
  }
}

/**
 * Wrapper function to provide the channel emitter to the HubConnection
 * "data" event
 * @param emit
 * @returns {onDataData}
 */
function hubEventHandler(emit) {
  return function onDataHandler({ data }) {
    const { event, object, deviceId } = data;
    switch (event) {
      case scannerMessages.NEW_SCAN:
        emit(addScanFromScanner(deviceId, object));
        break;
      case scannerMessages.AVAILABLE_DEVICES:
        emit(setAvailableDevices(object.devices));
        break;
      case scannerMessages.CONNECTION_STATUS:
        emit(deviceConnected(deviceId, object));
        break;
      case scannerMessages.MESSAGE:
        emit(deviceMessage(deviceId, object));
        break;
      case scannerMessages.SAVED_SCANS:
        // We get back a "success": false if it fails.
        // We don't get back any success
        const success = !("success" in object) || object.success;
        emit(loadSavedScans(deviceId, success, object.savedScans));
        break;
      case scannerMessages.ERASE_SAVED_SCANS:
        emit(eraseSavedScansResult(deviceId, object.success));
        break;
      case scannerMessages.DEVICE_TIME:
        emit(getDeviceTimeResult(deviceId, object.deviceTime));
        break;
      case scannerMessages.UPDATE_DEVICE_TIME:
        emit(setDeviceTimeResult(deviceId, object.success));
        break;
      case scannerMessages.WEIGHING_SESSIONS:
      case scannerMessages.WEIGHING_SESSION:
        emit(deviceMessage(deviceId, { type: event, payload: object }));
        break;

      default:
        console.warn("Unsupported event %s", event); // eslint-disable-line no-console
        console.debug(data); // eslint-disable-line no-console
    }
  };
}

function queueRequestObject(
  object,
  onSuccessHandler = null,
  onErrorHandler = null,
) {
  const request = JSON.stringify(object);
  hubConnection.submitRequest(request, onSuccessHandler, onErrorHandler);
}

function onConnectToHub() {
  hubConnection.ensureConnectionEstablished(
    () => {},
    () => toast.warning("Could not connect to AgriNous Hub."),
  );
}

function onGetAvailableDevices() {
  queueRequestObject(
    { event: scannerMessages.GET_AVAILABLE_DEVICES },
    null,
    toastErrorMessageFactory("getting available scanners"),
  );
}

function onSendDeviceMessage({ deviceId, message }) {
  queueRequestObject({
    event: scannerMessages.MESSAGE,
    deviceId,
    object: message,
  });
}

function onSendDeviceRequestListWeighingSessions({ deviceIds }) {
  for (const deviceId of deviceIds) {
    queueRequestObject({
      event: scannerMessages.LIST_WEIGHING_SESSIONS,
      deviceId,
    });
  }
}

function onSendDeviceRequestReadWeighingSession({ deviceId, sessionName }) {
  queueRequestObject({
    event: scannerMessages.READ_WEIGHING_SESSION,
    deviceId,
    object: {
      sessionName,
    },
  });
}

function onSendRawHubPayload({ rawPayloadObj }) {
  queueRequestObject(rawPayloadObj);
}

function onSetDeviceTime({ deviceId }) {
  return sendRawHubPayload({
    event: scannerMessages.UPDATE_DEVICE_TIME,
    deviceId,
  });
}

function onConnectToDevice({ deviceId }) {
  queueRequestObject(
    { event: scannerMessages.CONNECT_TO_DEVICE, deviceId },
    null,
    toastErrorMessageFactory("connecting to scanner"),
  );
}

function onDisconnectFromDevice({ deviceId }) {
  queueRequestObject({
    event: scannerMessages.DISCONNECT_FROM_DEVICE,
    deviceId,
  });
}

function onPrintReceipt({ content }) {
  queueRequestObject(
    {
      event: scannerMessages.PRINT_RECEIPT,
      object: { content },
    },
    toastSuccessMessageFactory("Receipt job submitted."),
    toastErrorMessageFactory("printing receipt"),
  );
}

function* onResetDevice({ deviceId }) {
  yield put(setDeviceTime(deviceId));
  yield put(eraseSavedScans(deviceId));
}

function onPing() {
  if (hubConnection.doesHubSupportPing()) {
    // This should be called every second - only ping every 5 second
    const now = Math.round(new Date().getTime() / 1000);
    if (now % 5 === 0) {
      queueRequestObject({ event: scannerMessages.PING_FROM_APP });
    }
  }
}

// If the device supports it, load time on connection.
function* getTimeOnConnect({ object, deviceId }) {
  // when a device is connected, and a deviceType is provided, we can determine
  // what is capable of, and if getting the device time is supported, go and get it
  if (object) {
    if (
      (object.device && object.device.status === "connected") ||
      object.deviceType
    ) {
      if (
        getDeviceTypeCapabilites(object.device.deviceType).includes(
          scannerMessages.READ_DEVICE_TIME,
        )
      ) {
        yield put(getDeviceTime(deviceId));
      }
    }
  }
}

function onDeviceTime(action) {
  const deviceTime = new Date(action.deviceTime);

  // See if the stick is more than 20 minutes out.
  const now = new Date();
  if (
    Math.abs(now.getTime() - deviceTime.getTime()) >
    DEVICE_TIME_DIFFERENCE_ERROR_THRESHOLD
  ) {
    toast.error(
      `Loaded time from device: ${formatDateTimeString(
        deviceTime,
      )} - more than ${DEVICE_TIME_DIFFERENCE_ERROR_THRESHOLD_ENGLISH} difference.  Time update suggested.`,
    );
  } else {
    toast.success(
      `Loaded time from device: ${formatDateTimeString(deviceTime)}`,
    );
  }
}

function* onDeviceTimeUpdated({ success, deviceId }) {
  if (success) {
    toast.success("Successfully updated device time.");
  } else {
    toast.error("Error updating device time.");
  }
  // And do a get to keep the store up to date.
  yield put(getDeviceTime(deviceId));
}

function onSavedScansErased({ success }) {
  if (success) {
    toast.success("Successfully erased scanning sessions on device.");
  } else {
    toast.error("Error erasing scanning sessions.");
  }
}

function onSavedScansLoaded({ success, importedScans }) {
  if (success) {
    const numberOfDrafts = size(importedScans);
    toast.success(
      `Successfully loaded scans from ${numberOfDrafts} draft${
        numberOfDrafts === 1 ? "" : "s"
      }.`,
    );
  } else {
    toastErrorMessageFactory("loading scans")("");
    history.goBack();
  }
}

function* confirmEraseSavedScans({ deviceId }) {
  yield call(
    confirmDialogWithSubAction,
    {
      title: "Clear Scans from Scanner",
      message:
        "Are you sure you want to remove saved sessions?  Scanning sessions CANNOT BE RECOVERED. ",
    },
    eraseSavedScans(deviceId),
  );
}

function* confirmSetDeviceTime({ deviceId }) {
  yield call(
    confirmDialogWithSubAction,
    {
      title: "Set Scanner Time",
      message:
        "This will reset the time on your scanner to the current time on your phone or tablet.  Continue?",
    },
    setDeviceTime(deviceId),
  );
}

function* confirmResetDevice({ deviceId }) {
  yield call(
    confirmDialogWithSubAction,
    {
      title: "Reset Scanner",
      message:
        "This will reset the time on your scanner to the current time on your phone or tablet and remove all saved sessions?  Scanning sessions CANNOT BE RECOVERED. ",
    },
    resetDevice(deviceId),
  );
}

function* getDeviceCapabilityHandler(deviceId, capability) {
  const state = yield select();

  const device = yield getAvailableDevices(state).find(
    device => device.deviceId === deviceId,
  );

  if (!device) {
    return;
  }

  return getCapabilityHandler(
    device.deviceType,
    capability,
    hubConnection.connectedVersion,
  );
}

function deferToDeviceDriver(scannerMessage) {
  return function* deferToDeviceDriverHandler(action) {
    const handler = yield getDeviceCapabilityHandler(
      action.deviceId,
      scannerMessage,
    );
    if (typeof handler !== "function") {
      return;
    }

    const chainedAction = handler(action);
    if (!chainedAction) {
      return;
    }

    yield put(chainedAction);
  };
}

function* handleImportMtHostSessions(action) {
  const { storedMtHostSettings } = action;

  for (const [deviceId, settings] of Object.entries(storedMtHostSettings)) {
    if (settings.inUse) {
      yield put(
        sendDeviceRequestReadWeighingSession(deviceId, settings.fileName),
      );
    }
  }
}

const onEraseSavedScans = deferToDeviceDriver(
  scannerMessages.ERASE_SAVED_SCANS,
);

const onGetDeviceTime = deferToDeviceDriver(scannerMessages.READ_DEVICE_TIME);

const onReadSavedScans = deferToDeviceDriver(scannerMessages.READ_SAVED_SCANS);

const onStartDraft = deferToDeviceDriver(scannerMessages.START_DRAFT);

const onResendScan = deferToDeviceDriver(scannerMessages.RESEND_SCAN);

export default function* hubSagas() {
  // Defer these actions to the device driver's handler
  yield takeEvery(ERASE_SAVED_SCANS, onEraseSavedScans);
  yield takeEvery(GET_DEVICE_TIME, onGetDeviceTime);
  yield takeEvery(READ_SAVED_SCANS, onReadSavedScans);
  yield takeEvery(START_DRAFT, onStartDraft);
  yield takeEvery(RESEND_SCAN, onResendScan);

  // Request events
  yield takeEvery(CONNECT_TO_HUB, onConnectToHub);
  yield takeEvery(GET_AVAILABLE_DEVICES, onGetAvailableDevices);
  yield takeEvery(SET_DEVICE_TIME, onSetDeviceTime);
  yield takeEvery(DISCONNECT_FROM_DEVICE, onDisconnectFromDevice);
  yield takeEvery(CONNECT_TO_DEVICE, onConnectToDevice);
  yield takeEvery(PING_FROM_APP, onPing);
  yield takeEvery(PRINT_RECEIPT, onPrintReceipt);
  yield takeEvery(SEND_DEVICE_MESSAGE, onSendDeviceMessage);
  yield takeEvery(
    SEND_DEVICE_REQUEST_LIST_WEIGHING_SESSIONS,
    onSendDeviceRequestListWeighingSessions,
  );
  yield takeEvery(
    SEND_DEVICE_REQUEST_READ_WEIGHING_SESSION,
    onSendDeviceRequestReadWeighingSession,
  );
  yield takeEvery(SEND_RAW_HUB_PAYLOAD, onSendRawHubPayload);

  // response and chained events
  yield takeEvery(RESET_DEVICE, onResetDevice);
  yield takeEvery(DEVICE_CONNECTED, getTimeOnConnect);

  // user notifications
  yield takeEvery(GET_DEVICE_TIME_RESULT, onDeviceTime);
  yield takeEvery(SET_DEVICE_TIME_RESULT, onDeviceTimeUpdated);
  yield takeEvery(ERASE_SAVED_SCANS_RESULT, onSavedScansErased);
  yield takeEvery(READ_SAVED_SCANS_RESULT, onSavedScansLoaded);

  // confirm events
  yield takeEvery(CONFIRM_ERASE_SAVED_SCANS, confirmEraseSavedScans);
  yield takeEvery(CONFIRM_SET_DEVICE_TIME, confirmSetDeviceTime);
  yield takeEvery(CONFIRM_RESET_DEVICE, confirmResetDevice);
  yield takeEvery(IMPORT_MT_HOST_SESSIONS, handleImportMtHostSessions);

  // hub channel worker
  yield call(dataCallbackWorker, hubChannel);
}

hubConnection.addEventListener(
  "connected",
  hubConnectedHandler(hubChannelEmitter),
);
hubConnection.addEventListener("data", hubEventHandler(hubChannelEmitter));
hubConnection.addEventListener("version", onVersionHandler);
hubConnection.addEventListener(
  "closed",
  hubDisconnectedHandler(hubChannelEmitter),
);
