import React, { useEffect, useMemo, useState } from "react";

import { faSignOutAlt, faTrashAlt } from "@fortawesome/pro-duotone-svg-icons";
import {
  faCompressAlt,
  faExpandAlt,
  faPlus,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import { AttachmentForm } from "components/AttachmentForm";
import { ConfirmBrandUpdateDialog } from "components/AttachmentForm/ConfirmBrandUpdateDialog";
import { MultiButton } from "components/Button";
import Clickable from "components/Button/Clickable";
import Dialog from "components/Dialog";
import { Button } from "components/Form/Button";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";
import { UploadModal } from "components/UploadModal";

import { NVDRelatedDocumentTypeValues } from "constants/documentTypes";

import { compareAttachments } from "lib/compare";
import { getLivestockSaleId } from "lib/navigation";

import {
  currentSaleSelector,
  getFiles,
  selectDeploymentSaleIdByLivestockSaleIdAndAgencyId,
  selectIsCompleteByAttachmentIdLookup,
} from "selectors";

import { useHasAddAttachmentWithoutImagePermission } from "hooks";

import AttachmentThumb from "./AttachmentThumb";
import MobileImageViewer from "./MobileImageViewer";
import { Preview } from "./Preview";

const Wrapper = styled(Column)`
  ${({ theme }) => `
  flex: 1;
  overflow-y: hidden;
  height: calc(100vh - 63px - ${theme.space[2] * 2}px);
`}
`;

const ModalWrapper = styled(Column)`
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const AddAttachmentContainer = styled.div`
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const MobileButton = styled(Clickable)`
  display: flex;
  align-items: center;
  color: #666;
  font-size: 24px;
  ${({ theme, hoverRed }) =>
    hoverRed && `&:hover{ color: ${theme.colors.inputError} }`};
`;

const CloseButton = styled(Clickable)`
  font-size: 20px;
  margin: ${({ isMobile }) => (isMobile ? "24px 24px 18px" : "20px 24px 0 0")};
`;

const CloseModalButton = styled(Clickable)`
  position: fixed;
  right: 36px;
  bottom: 36px;
  font-size: 24px;
  color: #666;
  line-height: 0;
  padding: 12px;
  border-radius: 100px;
  background-color: #fff;
`;

const ThumbnailRow = styled.div`
  position: relative;
  min-height: 78px;
  border-bottom: 1px solid #bababa;
`;

const ThumbnailRowInner = styled(Row)`
  padding: 0 12px;
  margin-right: 72px;
  overflow-x: auto;
`;

const ThumbnailColumn = styled(Column)`
  flex: 1;
  overflow-y: auto;
`;

const MobileControls = styled(Row)`
  justify-content: space-between;
  padding: 24px;
  ${({ disabled }) => {
    if (disabled) {
      return `opacity: 0.33;
              pointer-events: none;`;
    }
  }};
`;

const DesktopGridLayout = styled.div`
  ${({ theme }) => `
  flex: 1;
  display: grid;
  grid-gap: ${theme.space[2]}px;
  margin: ${theme.space[2]}px;
  grid-template-columns: 100px auto 328px;
  // Set the direct children in the grid to be the full page.
  > div {
    height: calc(100vh - 63px - ${theme.space[2] * 2}px);
  }
`}
`;

const FormWrapper = styled(Paper)`
  position: relative;
`;

const AttachmentsView = ({
  isMobile,
  setAdditionalPICs,
  updateDeclaration,
  redirectToConsignments,
  updateAttachment,
  uploadRequest,
  createPlaceholderAttachment,
  deleteAttachment,
  patchConsignment,
}) => {
  const sale = useSelector(currentSaleSelector);
  const isCompleteByAttachmentIdLookup = useSelector(
    selectIsCompleteByAttachmentIdLookup,
  );

  const hasAddAttachmentWithoutImagePermission =
    useHasAddAttachmentWithoutImagePermission();

  const files = useSelector(getFiles);

  const location = useLocation();

  const queryParams = useMemo(
    () =>
      queryString.parseUrl(location.search, {
        parseBooleans: true,
        parseNumbers: true,
      }).query,
    [location.search],
  );

  const deploymentSaleId = useSelector(state =>
    selectDeploymentSaleIdByLivestockSaleIdAndAgencyId(
      state,
      getLivestockSaleId(),
      queryParams.selectedAgency,
    ),
  );

  const filters = useMemo(() => {
    const { selectedAgency, ...simpleFiltersValues } = queryParams;
    const filterValues = {
      ...simpleFiltersValues,
    };
    if (selectedAgency && selectedAgency !== "-1") {
      filterValues.deploymentSale = deploymentSaleId;
    }
    return filterValues;
  }, [queryParams, deploymentSaleId]);

  const attachments = useMemo(() => {
    const nvdRelatedFiles = Object.values(files).filter(
      file => NVDRelatedDocumentTypeValues.includes(file.type) || !file.type,
    );

    const filterKeys = Object.keys(filters);

    return nvdRelatedFiles
      .filter(file =>
        filterKeys.every(key => file[key] === filters[key] || !file[key]),
      )
      .sort(compareAttachments);
  }, [files, filters]);

  const filteredAttachments = attachments;

  // Open the attachment in the query param if it is provided, otherwise fall back to
  // the one they are most likely actioning.
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(
    queryParams.attachment ||
      (filteredAttachments[0] ? filteredAttachments[0].id : null),
  );

  const [confirmBrandUpdateDialogProps, setConfirmBrandUpdateDialogProps] =
    useState(null);

  useEffect(() => {
    if (
      !selectedAttachmentId ||
      !filteredAttachments.find(a => a.id === selectedAttachmentId)
    ) {
      setSelectedAttachmentId(
        filteredAttachments[0] ? filteredAttachments[0].id : null,
      );
    }
  }, [filteredAttachments, selectedAttachmentId]);

  const [isImageModal, setIsImageModal] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);
  const [isCloseDialog, setIsCloseDialog] = useState(false);

  const selectedAttachment = useMemo(
    () =>
      attachments.find(attachment => attachment.id === selectedAttachmentId),
    [attachments, selectedAttachmentId],
  );

  const selectedAttachmentUrl = selectedAttachment
    ? selectedAttachment.image_url
    : "";

  const getNextAttachment = () => {
    const currentIndex = attachments.findIndex(
      a => a.id === selectedAttachmentId,
    );
    const nextAttachment = attachments[currentIndex + 1];
    if (nextAttachment) {
      setSelectedAttachmentId(nextAttachment.id);
    }
  };

  const deleteSelectedAttachment = () => {
    const attachment = attachments.find(
      attachment => attachment.id === selectedAttachmentId,
    );
    selectedAttachmentId && deleteAttachment(attachment) && getNextAttachment();
  };

  const replaceSelectedAttachment = (attachment, newFile) => {
    typeof updateAttachment === "function" &&
      updateAttachment({ ...attachment, file: newFile });
  };

  const handleUpload = file => {
    // If this page is filtered by consignment then
    // uploaded attachments should have that consignment id
    const formData = { sale };
    if (queryParams.consignment) {
      formData.consignment = queryParams.consignment;
    }
    if (queryParams.selectedAgency) {
      formData.agency = queryParams.selectedAgency;
    }
    uploadRequest(file, formData);
    setIsUploadModal(false);
  };

  const thumbnails = filteredAttachments.map(attachment => {
    return (
      <AttachmentThumb
        key={attachment.id}
        id={attachment.id}
        progress={attachment.progress}
        thumbUrl={attachment.thumbnail_url}
        selected={selectedAttachmentId === attachment.id}
        setSelected={setSelectedAttachmentId}
        isComplete={isCompleteByAttachmentIdLookup[attachment.id]}
        isMobile={isMobile}
        isEnvd={Boolean(attachment.envd)}
      />
    );
  });

  const DeleteDialog = () =>
    isDeleteDialog && (
      <Dialog
        closeSelf={() => setIsDeleteDialog(false)}
        handleSubmit={deleteSelectedAttachment}
        title="Permanently delete document?"
        subtitle="Are you sure you want to permanently delete the file?"
        warningText="The document data entered will also be deleted"
        submitButtonText="Delete Document"
        rejectButtonText="Keep"
        icon={faTrashAlt}
      />
    );

  const UploadFileModal = () =>
    isUploadModal && (
      <UploadModal
        closeSelf={() => setIsUploadModal(false)}
        handleUpload={handleUpload}
      />
    );

  const handleGoBack = () => setIsCloseDialog(true);

  const BackButton = () => (
    <CloseButton isMobile onClick={handleGoBack}>
      <FontAwesomeIcon icon={faTimes} />
    </CloseButton>
  );

  const handleShowUploadModal = () => setIsUploadModal(true);

  const handleCreateEmptyAttachment = () => {
    // If this page is filtered by consignment then
    // uploaded attachments should have that consignment id
    const formData = { sale };
    if (queryParams.consignment) {
      formData.consignment = queryParams.consignment;
    }
    if (queryParams.selectedAgency) {
      formData.agency = queryParams.selectedAgency;
    }
    createPlaceholderAttachment(formData);
  };

  const CloseDialog = () =>
    isCloseDialog && (
      <Dialog
        closeSelf={() => setIsCloseDialog(false)}
        handleSubmit={redirectToConsignments}
        title="Leave Session?"
        subtitle="Are you sure you want to leave this session?"
        warningText="All progress not saved will be lost"
        submitButtonText="Leave"
        rejectButtonText="Stay"
        icon={faSignOutAlt}
      />
    );

  const attachmentActions = [
    {
      title: isMobile ? null : "Add",
      isDisabled: false,
      onClick: handleShowUploadModal,
      default: true,
      icon: faPlus,
      dataTour: "addAttachment",
    },
    {
      title: "Add without image",
      isDisabled: !hasAddAttachmentWithoutImagePermission,
      onClick: handleCreateEmptyAttachment,
      default: false,
      icon: faPlus,
      dataTour: "addAttachmentWithoutImage",
    },
  ];

  if (isMobile) {
    const thumbnailsAndAddNew = (
      <ThumbnailRow>
        <ThumbnailRowInner alignCenter>{thumbnails}</ThumbnailRowInner>
        <AddAttachmentContainer>
          {hasAddAttachmentWithoutImagePermission ? (
            <MultiButton buttons={attachmentActions} />
          ) : (
            <Button onClick={handleShowUploadModal}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          )}
        </AddAttachmentContainer>
      </ThumbnailRow>
    );

    if (isImageModal) {
      return (
        <ModalWrapper>
          {thumbnailsAndAddNew}

          <MobileImageViewer
            imageUrl={selectedAttachmentUrl}
            style={{ flexGrow: 1 }}
          />

          <CloseModalButton onClick={() => setIsImageModal(false)}>
            <FontAwesomeIcon icon={faCompressAlt} />
          </CloseModalButton>
        </ModalWrapper>
      );
    }

    return (
      <Wrapper isMobile>
        <Row justifyEnd>
          <BackButton />
        </Row>
        {thumbnailsAndAddNew}
        <MobileControls disabled={selectedAttachmentId === null}>
          <MobileButton onClick={handleShowUploadModal}>
            <FontAwesomeIcon icon={faExpandAlt} />
            <span style={{ fontSize: 14, paddingLeft: 12 }}>View document</span>
          </MobileButton>
          <MobileButton hoverRed onClick={() => setIsDeleteDialog(true)}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </MobileButton>
        </MobileControls>
        <FormWrapper>
          <AttachmentForm
            key={selectedAttachmentId}
            updateDeclaration={updateDeclaration}
            setAdditionalPICs={setAdditionalPICs}
            selectedAttachment={selectedAttachment}
            updateAttachment={updateAttachment}
            patchConsignment={patchConsignment}
            getNextAttachment={getNextAttachment}
            handleGoBack={handleGoBack}
          />
        </FormWrapper>
        <DeleteDialog />
        <UploadFileModal />
        <CloseDialog />
        <ConfirmBrandUpdateDialog
          confirmBrandUpdateDialogProps={confirmBrandUpdateDialogProps}
          setConfirmBrandUpdateDialogProps={setConfirmBrandUpdateDialogProps}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <DesktopGridLayout>
        <Paper spacing={1}>
          <Scrollbars>
            <ThumbnailColumn>{thumbnails}</ThumbnailColumn>
          </Scrollbars>
          <Row justifyCenter style={{ marginTop: 12, marginBottom: 6 }}>
            {hasAddAttachmentWithoutImagePermission ? (
              <MultiButton buttons={attachmentActions} />
            ) : (
              <Button onClick={handleShowUploadModal}>
                Add <FontAwesomeIcon icon={faPlus} />
              </Button>
            )}
          </Row>
        </Paper>

        <Preview
          file={selectedAttachment}
          handleDelete={deleteSelectedAttachment}
          handleReplace={replaceSelectedAttachment}
        />

        <FormWrapper>
          <AttachmentForm
            key={selectedAttachmentId}
            updateDeclaration={updateDeclaration}
            setAdditionalPICs={setAdditionalPICs}
            selectedAttachment={selectedAttachment}
            patchConsignment={patchConsignment}
            updateAttachment={updateAttachment}
            getNextAttachment={getNextAttachment}
            handleGoBack={handleGoBack}
            setConfirmBrandUpdateDialogProps={setConfirmBrandUpdateDialogProps}
          />
        </FormWrapper>
      </DesktopGridLayout>
      <UploadFileModal />
      <CloseDialog />
      <ConfirmBrandUpdateDialog
        confirmBrandUpdateDialogProps={confirmBrandUpdateDialogProps}
        setConfirmBrandUpdateDialogProps={setConfirmBrandUpdateDialogProps}
      />
    </Wrapper>
  );
};

AttachmentsView.propTypes = {
  updateAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  setAdditionalPICs: PropTypes.func.isRequired,
  updateDeclaration: PropTypes.func.isRequired,
  patchConsignment: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  uploadRequest: PropTypes.func.isRequired,
  redirectToConsignments: PropTypes.func.isRequired,
};

AttachmentsView.defaultProps = {
  isMobile: false,
};

export default AttachmentsView;
