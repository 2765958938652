import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { ActionButton } from "components/AgGrid/ActionButton";
import AgGrid from "components/AgGrid/AgGrid";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { SubtleBadge } from "components/Badge";
import { SlimSecondaryButton } from "components/Button";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Row } from "components/Layout";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import {
  getBusinessById,
  getSplitTotalsByConsignmentIdLookup,
  getVendorIdByConsignmentId,
  getVendorSplitConsignmentIdsByParentConsignmentId,
} from "selectors";

import { getConsignmentModalVendorSplitConsignmentsAggridDataByParentConsignmentId } from "selectors/aggrid/consignmentModalVendorSplits";

const defaultColDef = {
  sortable: true,
  resizable: true,
  enableCellChangeFlash: true,
  flex: true,
};

function VendorSplitHeader({ consignmentId }) {
  const vendorSplitConsignmentIds = useSelector(
    getVendorSplitConsignmentIdsByParentConsignmentId(consignmentId),
  );
  return (
    <Row>
      <SubtleBadge>Legacy Vendor Splits</SubtleBadge>
      <CollapseLabel>
        {vendorSplitConsignmentIds.length}{" "}
        {pluralize("Split", vendorSplitConsignmentIds.length)}
      </CollapseLabel>
    </Row>
  );
}

const onClickEditSubConsignment = (consignmentId, parentConsignmentId) => {
  openModalLink(ModalTypes.VendorSplitConsignment, {
    consignmentId,
    parentConsignmentId,
  });
};

const actionsColumnRenderer = ({ data }) => {
  const { isPrimaryVendor, consignment } = data;

  if (isPrimaryVendor) {
    return <div>Primary Vendor</div>;
  }
  return (
    <ActionButton
      data-tour="action-goto-subconsignment"
      type="button"
      onClick={() =>
        onClickEditSubConsignment(consignment.id, consignment.parentId)
      }
    >
      <FontAwesomeIcon icon={faPencil} />
      &nbsp;Edit
    </ActionButton>
  );
};

const columns = [
  {
    headerName: "Vendor",
    field: "vendor.name",
  },

  {
    headerName: "Hd",
    field: "quantity",
    width: 80,
  },
  {
    headerName: "Lots",
    field: "lotCount",
    width: 80,
  },
  {
    headerName: "IsPrimary",
    field: "isPrimaryVendor",
    sort: "desc",
    hide: true,
  },
  {
    headerName: ACTION_COLUMN_NAME,
    colId: ACTION_COLUMN_ID,
    cellRenderer: actionsColumnRenderer,
    pinned: "right",
    width: 130,
  },
];

export const LegacyVendorSplitSection = ({
  isOpen,
  onToggle,
  consignmentId,
}) => {
  const subConsignments = useSelector(
    getConsignmentModalVendorSplitConsignmentsAggridDataByParentConsignmentId(
      consignmentId,
    ),
  );

  const { unSplitQuantity, unSplitSaleLots } =
    useSelector(getSplitTotalsByConsignmentIdLookup(consignmentId)) || {};
  const primaryVendorId = useSelector(
    getVendorIdByConsignmentId(consignmentId),
  );

  const { name: primaryVendorName = "" } =
    useSelector(getBusinessById(primaryVendorId)) || {};

  const onClickCreateVendorSplitConsignment = () =>
    openModalLink(ModalTypes.VendorSplitConsignment, {
      parentConsignmentId: consignmentId,
    });

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<VendorSplitHeader consignmentId={consignmentId} />}
      dataTour={isOpen ? "hideVendorSplits" : "showVendorSplits"}
    >
      <Grid item xs={12} container justifyContent="center">
        <div className="text-center font-bold">
          {unSplitQuantity} Head in {unSplitSaleLots}{" "}
          {pluralize("SaleLot", unSplitSaleLots)} remain unsplit and will be
          attributed to the Primary Vendor: {primaryVendorName}
        </div>
      </Grid>
      <Grid container justifyContent="center" spacing={2}>
        {subConsignments.length > 0 ? (
          <Grid item xs={12}>
            <CollapseTableWrapper>
              <AgGrid
                rowData={subConsignments}
                suppressColumnVirtualisation
                suppressScrollOnNewData
                columnDefs={columns}
                rowBuffer={10}
                defaultColDef={defaultColDef}
              />
            </CollapseTableWrapper>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <SlimSecondaryButton
          type="button"
          onClick={onClickCreateVendorSplitConsignment}
          data-tour="createVendorSplit"
        >
          Create Vendor Split
        </SlimSecondaryButton>
      </Grid>
    </FormCollapse>
  );
};
