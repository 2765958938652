import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { SaleAction } from "actions";

import { Settings } from "constants/settings";

import { getSetting } from "selectors";

export const useLoadAllSales = () => {
  const dispatch = useDispatch();

  const allSalesLoaded = useSelector(getSetting(Settings.allSalesLoaded));
  useEffect(() => {
    if (!allSalesLoaded) {
      dispatch(SaleAction.request({ fetchAll: true }));
    }
  }, [allSalesLoaded, dispatch]);
};
