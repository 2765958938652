import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

export const carrierChargeSerializeMap = {
  attachmentId: "attachment_id",
  carrier: "carrier",
  deliveryDate: "delivery_date",
  id: "id",
  invoiceAmount: "invoice_amount",
  invoiceNumber: "invoice_number",
  notes: "notes",
};

export const serializeCarrierCharge = buildSerializer(
  carrierChargeSerializeMap,
  "CarrierCharge",
);

export const deserializeCarrierCharge = buildDeserializer(
  carrierChargeSerializeMap,
  "CarrierCharge",
);
