import React from "react";

import { useSelector } from "react-redux";

import AuctionPenCardList from "components/AuctionPens/List/";
import DeliveryPensListView from "components/DeliveryPens/ListView";

import { PenTypes } from "constants/auctionPens";
import { Settings } from "constants/settings";

import { getSetting } from "selectors";

function AuctionPenCardsView() {
  const penView = useSelector(
    state => getSetting(Settings.penView)(state) || PenTypes.SELLING,
  );

  return penView === PenTypes.DELIVERY ? (
    <DeliveryPensListView />
  ) : (
    <AuctionPenCardList />
  );
}

export default AuctionPenCardsView;
