import {
  ATTACHMENT,
  UPLOAD_FAILURE,
  UPLOAD_PROGRESS,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
} from "constants/actionTypes";

import { reduceById } from "lib/reducers";
import { deserializeAttachment } from "lib/serializers/files";

const initialState = { files: {}, isFetching: false };

const files = (state = initialState, action) => {
  switch (action.type) {
    case ATTACHMENT.FETCH_BULK.REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };

    case ATTACHMENT.FETCH_CHANGES.SUCCESS:
    case ATTACHMENT.FETCH_BULK.SUCCESS: {
      // Replace existing
      const { attachments } = action;
      const newAttachments = attachments.reduce((map, attachment) => {
        map[attachment.id] = deserializeAttachment(attachment);
        return map;
      }, {});
      return {
        ...state,
        files: newAttachments,
        isFetching: false,
      };
    }

    case ATTACHMENT.CREATE_BULK.FROM_SOCKET: {
      // Insert into existing.
      const { attachments } = action;
      const newAttachments = attachments.reduce((map, attachment) => {
        map[attachment.id] = deserializeAttachment(attachment);
        return map;
      }, {});
      return {
        ...state,
        files: {
          ...state.files,
          ...newAttachments,
        },
        isFetching: false,
      };
    }

    case ATTACHMENT.UPDATE.REQUEST:
    case ATTACHMENT.UPDATE.SUCCESS:
    case ATTACHMENT.UPDATE.FROM_SOCKET: {
      const { attachment } = action;
      if (!attachment) {
        return state;
      }
      return {
        ...state,
        files: {
          ...state.files,
          [attachment.id]: {
            ...state.files[attachment.id],
            ...deserializeAttachment(attachment),
          },
        },
      };
    }

    case ATTACHMENT.FETCH_CHANGES.FAILURE:
    case ATTACHMENT.UPDATE.FAILURE:
      // TBI
      return state;

    case UPLOAD_REQUEST:
      return {
        ...state,
        files: {
          ...state.files,
          [action.id]: {
            ...action.formData,
            image_url: "",
            id: action.id,
            isFinished: false,
          },
        },
      };

    case UPLOAD_PROGRESS:
      return {
        ...state,
        files: {
          ...state.files,
          [action.id]: {
            ...state.files[action.id],
            progress: action.progress,
          },
        },
      };

    case UPLOAD_SUCCESS:
      if (action.response && action.response.length > 0) {
        const committedFile = action.response[0];
        const { id } = committedFile;
        const tempId = action.id;
        const { [tempId]: uncommittedFile, ...remainingFiles } = state.files;
        return {
          ...state,
          files: {
            ...remainingFiles,
            [id]: {
              ...uncommittedFile,
              ...deserializeAttachment(committedFile),
              isFinished: true,
            },
          },
        };
      } else {
        return state;
      }

    case UPLOAD_FAILURE:
      // Remove the failed temp id from files.
      // eslint-disable-next-line
      const { [action.id]: _, ...updatedFiles } = state.files;
      return {
        ...state,
        files: {
          ...updatedFiles,
        },
      };

    case ATTACHMENT.DELETE.SUCCESS:
    case ATTACHMENT.DELETE.FROM_SOCKET: {
      const newFiles = { ...state.files };
      delete newFiles[action.attachment.id];
      return {
        ...state,
        files: newFiles,
      };
    }

    case ATTACHMENT.UPDATE_BULK.REQUEST: {
      const { attachments } = action.meta.offline.commit;
      const updatedFiles = attachments.reduce((acc, cur) => {
        acc[cur.id] = {
          ...state.files[cur.id],
          ...deserializeAttachment(cur),
        };
        return acc;
      }, state.files);

      return {
        ...state,
        files: updatedFiles,
      };
    }

    case ATTACHMENT.UPDATE_BULK.FROM_SOCKET:
    case ATTACHMENT.UPDATE_BULK.SUCCESS: {
      const { payload } = action;
      const updatedFiles = reduceById(
        payload,
        undefined,
        deserializeAttachment,
      );
      return {
        ...state,
        files: {
          ...state.files,
          ...updatedFiles,
        },
      };
    }

    case ATTACHMENT.CREATE_WITH_PLACEHOLDER.SUCCESS: {
      if (action.payload) {
        const committedFile = action.payload[0];
        const { id } = committedFile;
        return {
          ...state,
          files: {
            ...state.files,
            [id]: {
              ...deserializeAttachment(committedFile),
              isFinished: true,
            },
          },
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

export default files;
