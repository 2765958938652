import { createTheme } from "@material-ui/core/styles";

import * as theme from "./theme";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.primary,
    },
  },
  components: {
    // Change the default options of useMediaQuery - otherwise we get a False first render, then the correct value each time.
    // This fixes issues such as Modals opening full screen on first render, then contracting to the expected width immediately thereafter.
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        // Allow dialog body to scroll with a the header fixed.
        "& > form": {
          display: "contents",
        },
      },
    },
    MuiButtonBase: {
      root: {
        "&.Mui-disabled": {
          pointerEvents: "auto",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: theme.colors.titleDark,
        minWidth: 0,
        width: "100%",
        padding: `${theme.space[1]}px ${theme.space[2]}px`,
        border: "1px solid #c8c8c8",
        borderRadius: `${theme.radii[1]}px`,
        height: "36px",
        background: theme.colors.white,
      },
    },
    MuiToggleButton: {
      root: {
        color: theme.colors.primary,
        flex: 1,
        "&.Mui-disabled": {
          outline: `1px solid ${theme.colors.gray78}`,
        },
        outline: `1px solid ${theme.colors.primary}`,
        borderRadius: 0,
        border: "none",
        background: theme.colors.white,
        "&$selected": {
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
          "&:hover": {
            backgroundColor: theme.colors.primaryHighlight,
            color: theme.colors.white,
          },
        },
        "&:hover": {
          backgroundColor: theme.colors.primaryHighlight,
          color: theme.colors.white,
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        flexWrap: "wrap",
        background: theme.colors.white,
        width: "100%",
        border: `1px solid ${theme.colors.primary}`,
        borderRadius: 0,
      },
      groupedHorizontal: {
        "&:not(:first-child)": { borderLeft: "none", marginLeft: 0 },
        "&:first-child": { border: "none" },
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: theme.colors.gray40,
        color: theme.colors.white,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
      popupIndicator: {
        padding: "2px 8px",
        borderLeft: `1px solid ${theme.colors.gray78}`,
        borderRadius: 0,
        marginRight: 0,
        "& .MuiSvgIcon-root": {
          color: theme.colors.gray78,
        },
      },
      option: {
        '&[data-focus="true"]': {
          backgroundColor: theme.colors.primaryHighlight,
          color: theme.colors.white,
        },
        '&[aria-selected="true"]': {
          backgroundColor: theme.colors.primaryActive,
          color: theme.colors.white,
        },
      },
    },
    MuiChip: {
      root: {
        height: "26px",
      },
    },
    MuiStepLabel: {
      root: {
        "&.Mui-disabled .MuiStepIcon-root": {
          color: theme.colors.grayDisabled,
        },
      },
    },
  },
});
