import React, { useState } from "react";

import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";

import ExpandIcon from "components/ExpandIcon";
import { Button, SecondaryButton } from "components/Form";
import { AlertIcon } from "components/Icons";
import { Row, Column } from "components/Layout";
import NLISLogo from "components/NLISLogoStatus";
import {
  LogoWrapper,
  Text,
  Status,
  Wrapper,
  Label,
  BottomRow,
  AnimalListContainer,
  AnimalListItemStyled,
} from "components/TakeBobbyCalf/AuctionPenListItem";

import { TRANSFER_STATUS, logoStatus } from "constants/nlis";
import { ConsignmentExceptions } from "constants/sale";

import { openAnimalModal } from "lib/navigation";
import {
  getEIDStatus,
  getTransferFileStatusText,
  getAlertByStatus,
  getDefaultStatusColor,
  getUnresolvedAlert,
  alert as alerts,
} from "lib/nlisTake";

// The following Consignment Exceptions are not really relevant for bobbycalf, so just hide them.
const hiddenExceptions = [
  ConsignmentExceptions.NVD_SCAN_MISSING,
  ConsignmentExceptions.UNBALANCED,
  ConsignmentExceptions.NVD_INCOMPLETE,
];

const ConsignmentListItem = ({
  animals,
  className,
  consignmentStatus,
  exceptions,
  quantity,
  saleLots,
  scannedCount,
  scannedPercentage,
  takeStatus,
  vendorName,
  handleEditClick,
  handleScanClick,
  handleTakeClick,
  handleViewClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  const filteredExceptions = exceptions.filter(
    e => !hiddenExceptions.includes(e),
  );

  const match = useRouteMatch();

  const toggleExpanded = () => setExpanded(!expanded);

  const color = getDefaultStatusColor();

  const getStatusText = () => {
    if (takeStatus && takeStatus.length > 0) {
      const parentStatus = getTransferFileStatusText(takeStatus[0]);
      if (parentStatus) {
        return parentStatus === TRANSFER_STATUS.SUCCESS.toUpperCase()
          ? "TAKEN"
          : TRANSFER_STATUS[parentStatus].toUpperCase();
      }
      return "";
    } else {
      if (scannedPercentage >= 1) {
        return consignmentStatus.SCANNED;
      }
      return consignmentStatus.BOOKED;
    }
  };

  const getAlertType = () => {
    const statusText = getStatusText();
    if (statusText === consignmentStatus.BOOKED) {
      if (quantity !== scannedCount) {
        return alerts.mismatch;
      }
    } else if (statusText === consignmentStatus.SCANNED) {
      if (filteredExceptions && filteredExceptions.length > 0) {
        return getUnresolvedAlert(filteredExceptions);
      }

      if (scannedCount >= quantity) {
        return alerts.take;
      }
    } else {
      const alertObj =
        takeStatus && takeStatus.length > 0
          ? getAlertByStatus(takeStatus[0]) || {}
          : {};
      if (filteredExceptions && filteredExceptions.length > 0) {
        alertObj.texts = getUnresolvedAlert(filteredExceptions).texts;
      }
      return alertObj;
    }
  };

  const alert = getAlertType();

  const hasSaleLots = saleLots.length > 0;

  return (
    <Wrapper
      color={alert && alert.sidebarColor ? alert.sidebarColor : color}
      className={className}
      onClick={toggleExpanded}
      data-tour={`consignment-${vendorName}`}
    >
      <TopWrapper>
        <Row justifyBetween>
          <ColumnStyled truncate>
            <Label>Vendor</Label>
            <Text>{vendorName}</Text>
          </ColumnStyled>

          <ButtonWrapper>
            {takeStatus && takeStatus.length > 0 && (
              <>
                {takeStatus[0].status && (
                  <LogoWrapper onClick={handleViewClick}>
                    <NLISLogo
                      status={logoStatus[getEIDStatus(takeStatus[0])]}
                    />
                  </LogoWrapper>
                )}
                <SecondaryButtonStyled
                  leftMargin
                  onClick={handleViewClick}
                  data-tour="view"
                >
                  View
                </SecondaryButtonStyled>
              </>
            )}
            {(!takeStatus ||
              takeStatus.length === 0 ||
              takeStatus[0].status === TRANSFER_STATUS.ROLLED_BACK) && (
              <>
                <SecondaryButton onClick={handleEditClick} data-tour="edit">
                  Edit
                </SecondaryButton>
                {(scannedPercentage < 1 || quantity === 0) && (
                  <PrimaryButtonStyled
                    data-tour="scan"
                    onClick={handleScanClick}
                    disabled={!hasSaleLots}
                  >
                    <CrosshairIcon /> Scan
                  </PrimaryButtonStyled>
                )}
                {scannedPercentage >= 1 && scannedCount > 0 && (
                  <PrimaryButtonStyled
                    onClick={handleTakeClick}
                    data-tour="take"
                  >
                    Take
                  </PrimaryButtonStyled>
                )}
              </>
            )}
          </ButtonWrapper>
        </Row>

        <BottomRow justifyBetween>
          <Row>
            <Status
              color={alert && alert.statusColor ? alert.statusColor : color}
            >
              {getStatusText()}
            </Status>
          </Row>
          <StyledExpandIcon expanded={expanded} />
          <Row>
            <BookedColumn>
              <Label>HC</Label>
              <Text data-tour="quantity">
                {quantity === 0 ? "-" : quantity}
              </Text>
            </BookedColumn>
            <Column>
              <Label>Scanned</Label>
              <Text data-tour="scanned-count">{scannedCount}</Text>
            </Column>
          </Row>
        </BottomRow>
        {alert?.texts?.length > 0 ? (
          <Row>
            {alert.texts.map((txt, index) => (
              <Small italics bottomMargin key={`${txt}-${index}`}>
                <AlertIcon /> {txt}
              </Small>
            ))}
          </Row>
        ) : null}
      </TopWrapper>
      {expanded && animals.length ? (
        <AnimalListContainer>
          {animals.map(animal => (
            <AnimalListItemStyled
              key={animal.EID}
              onClick={() => openAnimalModal(match.url, animal.EID)}
              {...animal}
            />
          ))}
        </AnimalListContainer>
      ) : null}
    </Wrapper>
  );
};

ConsignmentListItem.propTypes = {
  animals: PropTypes.array,
  quantity: PropTypes.number,
  scannedCount: PropTypes.number,
  vendorName: PropTypes.string,
  exceptions: PropTypes.array,
  handleEditClick: PropTypes.func,
  handleScanClick: PropTypes.func,
  handleTakeClick: PropTypes.func,
  handleViewClick: PropTypes.func,
};

ConsignmentListItem.defaultProps = {
  animals: [],
  quantity: 0,
  exceptions: [],
};

export default ConsignmentListItem;

const TopWrapper = styled.div`
  padding: ${({ theme }) => theme.space[2]}px;
`;

const ButtonWrapper = styled(Row)`
  position: relative;
  width: 40%;
`;

const ColumnStyled = styled(Column)`
  width: 55%;
`;

const BookedColumn = styled(Column)`
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

const SecondaryButtonStyled = styled(SecondaryButton)`
  margin-${({ leftMargin }) => (leftMargin ? "left" : "right")}: ${({
    theme,
  }) => theme.space[2]}px;
`;

const PrimaryButtonStyled = styled(Button)`
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const StyledExpandIcon = styled(ExpandIcon)`
  position: absolute;
  bottom: 10px;
  right: 0;
`;

const CrosshairIcon = styled(FontAwesomeIcon).attrs({
  icon: faCrosshairs,
})`
  margin-right: ${({ theme }) => theme.space[1]}px;
`;

const Small = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.gray40};
  ${({ italics }) => (italics ? "font-style: italic" : null)}
  ${({ error, theme }) => (error ? `color: ${theme.colors.error}` : null)}
  ${({ bottomMargin, theme }) =>
    bottomMargin ? `display:block; margin-bottom: ${theme.space[2]}px` : null}
`;
