import reduceReducers from "reduce-reducers";

import { ECONTRACT } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineFetchReducer,
} from "lib/reducers";
import { deserializeEContract } from "lib/serializers/eContracts";

const create = apiModelOfflineCreateReducer(ECONTRACT, {
  deserializer: deserializeEContract,
});

const fetch = apiModelOfflineFetchReducer(ECONTRACT, {
  deserializer: deserializeEContract,
  clearOnRequest: true,
});

/**
 * while similar to apiModelOfflineUpdateReducer,
 * there are a couple of differences that prevent using directly:
 *  - subActionVerb used (VOID vs UPDATE)
 *  - REQUEST payload differences - full instance payload isn't send to this endpoint.
 *  - handling of request/success is subtly different
 *    - don't pre-emptively show the local instance as voided
 *      (voided_on data can only be set by server)
 *      until the server has confirmed void successful via response with updated payload
 */
function eContractVoidReducers(state, action) {
  switch (action.type) {
    case ECONTRACT.VOID.REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case ECONTRACT.VOID.SUCCESS:
    case ECONTRACT.VOID.FROM_SOCKET: {
      return {
        ...state,
        isFetching: false,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...deserializeEContract(action.payload),
          },
        },
      };
    }

    default:
      return state;
  }
}

const EContracts = reduceReducers(create, fetch, eContractVoidReducers);

export default EContracts;
