import { createSelector } from "reselect";

import {
  getEntryKeyIsUUID,
  getIsNumberId,
  getSales,
  objectMapper,
  selectVendorCommissionBandIdsByDeploymentBusinessIdLookup,
  selectVendorCommissionBandIdsByDeploymentIdLookup,
} from "selectors";

import { createLookupCombiner, createLookupSelectors } from "selectors/lib";
import { getVendorCommissionBands } from "selectors/root";

export const [
  selectVendorCommissionBandsByDeploymentBusinessIdLookup,
  getVendorCommissionBandsByDeploymentBusinessId,
] = createLookupSelectors(
  [
    selectVendorCommissionBandIdsByDeploymentBusinessIdLookup,
    getVendorCommissionBands,
  ],
  createLookupCombiner(objectMapper, getEntryKeyIsUUID),
);

export const [
  selectVendorCommissionBandsByDeploymentIdLookup,
  getVendorCommissionBandsByDeploymentId,
] = createLookupSelectors(
  [selectVendorCommissionBandIdsByDeploymentIdLookup, getVendorCommissionBands],
  createLookupCombiner(objectMapper, getIsNumberId),
);

export const selectDefaultVendorCommissionByDeploymentSaleId = createSelector(
  [selectVendorCommissionBandsByDeploymentIdLookup, getSales],
  (vendorCommissionBandsByDeploymentIdLookup, saleLookup) => {
    // Find the most specific vendor commission defaults for a deploymentSale.
    return Object.values(saleLookup).reduce((acc, livestockSale) => {
      livestockSale.deployment_sales.forEach(deploymentSale => {
        acc[deploymentSale.deployment_sale_id] =
          vendorCommissionBandsByDeploymentIdLookup[
            deploymentSale.deployment_id
          ]?.reduce((acc, cur) => {
            // If we have a sale sub type id, use that.
            if (cur.saleSubTypeId === livestockSale.sale_sub_type_id) {
              acc = cur.commissionValue;
            }
            // Use the default (no sale sub type specified) if there is nothing else
            if (acc === false && cur.saleSubTypeId === null) {
              acc = cur.commissionValue;
            }

            return acc;
          }, false) || false;
      });
      return acc;
    }, {});
  },
);
