import React from "react";

import { Tab, Tabs, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { setSetting } from "actions";

import { Row } from "components/Layout";

import { Settings } from "constants/settings";

import { getSetting, selectCurrentSpecies } from "selectors";

export const SpeciesSelector = () => {
  const dispatch = useDispatch();

  const speciesOptions = useSelector(selectCurrentSpecies);

  const selectedSpeciesId = useSelector(getSetting(Settings.speciesId));

  const setSelectedSpeciesId = React.useCallback(
    speciesId => dispatch(setSetting(Settings.speciesId, speciesId)),
    [dispatch],
  );

  React.useEffect(() => {
    if (!selectedSpeciesId && speciesOptions[0]) {
      setSelectedSpeciesId(speciesOptions[0].id);
    }
  }, [speciesOptions, selectedSpeciesId, setSelectedSpeciesId]);

  const handleSetSelectedSpecies = (_event, newValue) =>
    setSelectedSpeciesId(newValue);

  return (
    <Row margin={2}>
      <Paper square elevation={2}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedSpeciesId}
          onChange={handleSetSelectedSpecies}
        >
          {speciesOptions.map(({ name, id }) => (
            <Tab label={name} value={id} key={id} data-tour={name} />
          ))}
        </Tabs>
      </Paper>
    </Row>
  );
};
