import React from "react";

import { isObject } from "lodash";

import Badge from "components/Badge";

import { roundNumber } from "lib";

export function quantityRenderer(props) {
  if (!props.data) {
    return null;
  }

  const { value } = props;
  let quantity = value;
  if (isObject(value)) {
    quantity = roundNumber(value.value, 1);
  }
  if (quantity === 0) {
    return (
      <Badge inline color="warning">
        {quantity}
      </Badge>
    );
  } else {
    return <>{quantity}</>;
  }
}
