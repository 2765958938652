import React from "react";

import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { useField } from "formik";

import { withArrayNamespace } from "components/Form/FormikControls";

import { getParentFieldId } from "containers/Settings/RuleBooks/lib";

import { Criterion } from "./Criterion";

function Criteria(props) {
  const { namespace: ns, parentFieldId } = props;

  const [
    { value: criteria },
    // eslint-disable-next-line no-unused-vars
    ignored,
    { setValue },
  ] = useField(ns);

  function onRemoveCriterion(index) {
    const newValue = criteria.slice();
    newValue.splice(index, 1);
    setValue(newValue);
  }

  return (
    <section>
      {criteria.map((ignored, i) => {
        const criteriaOptions = criteria.slice(0, -i).map((criterion, j) => ({
          name: `Criteria ${i + j}`,
          fieldId: getParentFieldId(criterion.query, parentFieldId),
        }));

        return (
          <fieldset key={`${i}_${parentFieldId}`} className="p-2">
            <legend className="p-2">
              Criteria {i + 1}{" "}
              <IconButton
                aria-label="delete"
                onClick={() => onRemoveCriterion(i)}
              >
                <FontAwesomeIcon icon={faTrash} size="xs" />
              </IconButton>
            </legend>
            <Criterion
              criteriaOptions={criteriaOptions}
              hideCriteriaOutput={i === 0}
              namespace={withArrayNamespace(ns, i)}
              parentFieldId={parentFieldId}
            />
          </fieldset>
        );
      })}
    </section>
  );
}
export function WhenClause(props) {
  const { parentFieldId, namespace: ns } = props;

  return <Criteria parentFieldId={parentFieldId} namespace={ns} />;
}
