import { IntegrationTypes } from "constants/integrations";

import { IntegrationBusinessRenderer } from "lib/agGrid/columns/shortCode";
import getters from "lib/agGrid/getters";

export const XeroIntegrationBusinessColId = {
  XERO_INTEGRATION_BUSINESS: "1d4ecc77-d065-47da-a646-9cc96d125095",
};
// Fairly hard to pick apart and namespace, relies on being in an object like
// {
//  xeroIntegrationBusiness: {xxx},
//  business: {id: 1}
//  }
export const XeroIntegrationBusinessDef = {
  [XeroIntegrationBusinessColId.XERO_INTEGRATION_BUSINESS]: {
    colId: XeroIntegrationBusinessColId.XERO_INTEGRATION_BUSINESS,
    field: "xeroIntegrationBusiness",
    headerName: "Xero Business",
    width: 120,
    cellRenderer: IntegrationBusinessRenderer,
    valueGetter: getters.xeroBusinessGetter,
    filterValueGetter: getters.isDefinedGetter,
    cellRendererParams: {
      businessKeys: ["business"],
      complainIfMissing: true,
      useLogoAndTooltip: true,
      integrationType: IntegrationTypes.Xero,
    },
  },
};
