import React from "react";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";

import SaleLot from "containers/SaleLot";

import { openEditSaleLotModal } from "lib/navigation";

function EditSaleLotModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.EditSaleLot] || null;
  const [saleLotId, section, action, consignmentId, copyFrom] =
    JSON.parse(hashParams);

  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";

  function changeSection(currentSection, newSection) {
    openEditSaleLotModal(
      saleLotId,
      currentSection === newSection ? null : newSection,
      action,
      consignmentId,
      copyFrom,
      decodedReturnTo,
    );
  }

  return (
    <WaitForSync requiredData={[ApiModel.SALE_LOTS, ApiModel.SPECIES]}>
      <SaleLot
        saleLotId={saleLotId}
        section={section}
        action={action}
        consignmentId={consignmentId}
        copyFrom={copyFrom}
        changeSection={changeSection}
        returnTo={decodedReturnTo}
        {...props}
      />
    </WaitForSync>
  );
}

export default EditSaleLotModalAdapter;
