import { BillingRunStatus } from "constants/billingRuns";

import {
  createLookupCombiner,
  createLookupSelectors,
  getBillingRuns,
} from "selectors";

export const getBillingRunById = billingRunId => state =>
  getBillingRuns(state)[billingRunId] || null;

export const getActiveBillingRunId = state =>
  state.billingRuns.activeBillingRunId;

export const getLastActiveBillingRunId = state =>
  state.billingRuns.lastActiveBillingRunId;

export const [selectIsReadOnlyByBillingRunId, getIsReadOnlyByBillingRunId] =
  createLookupSelectors(
    [getBillingRuns],
    createLookupCombiner(
      billingRun => billingRun.status === BillingRunStatus.FINISHED,
    ),
  );
