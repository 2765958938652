import React, { useEffect } from "react";

import { Paper, Tab, Tabs } from "@material-ui/core";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import { Accounts } from "containers/Settings/Billing/Accounts";
import { SundryTemplates } from "containers/Settings/Billing/SundryTemplates";

import { getSettingsRoute } from "lib/navigation";

import history from "appHistory";
import { useGetSelectedTabFromRoute } from "hooks/useGetSelectedTabFromRoute";

const tabs = [
  { label: "Accounts", component: Accounts },
  { label: "Sundry Templates", component: SundryTemplates },
];
export const Billing = () => {
  const match = useRouteMatch();

  const currentTab = useGetSelectedTabFromRoute(tabs);

  useEffect(() => {
    // If we aren't on a tab, open the first.
    if (!currentTab && tabs[0]?.label) {
      history.push(getSettingsRoute(`Billing/${tabs[0].label}`));
    }
  }, [currentTab, match.path]);

  return (
    <>
      <Paper margin={2}>
        <Tabs value={currentTab} variant="scrollable">
          {tabs.map(({ label }) => (
            <Tab
              label={label}
              key={label}
              value={label}
              data-tour={label}
              component={Link}
              to={label}
            />
          ))}
        </Tabs>
      </Paper>

      <Switch>
        {tabs.map(({ label, component }) => (
          <Route
            key={label}
            path={`${match.path}${label}`}
            component={component}
          />
        ))}
      </Switch>
    </>
  );
};
