import { BILLING_DATA } from "constants/actionTypes";

import { deserializeBillingData } from "lib/serializers/billing";

const initialState = {
  isFetching: false,
  data: [],
};

const billingData = (state = initialState, action) => {
  switch (action.type) {
    case BILLING_DATA.FETCH_BULK.REQUEST: {
      return {
        ...state,
        data: [],
        isFetching: true,
      };
    }
    case BILLING_DATA.FETCH_BULK.SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        data: payload.map(deserializeBillingData),
        isFetching: false,
      };
    }
    case BILLING_DATA.FETCH_BULK.FAILURE: {
      return {
        ...state,
        data: null,
        isFetching: false,
      };
    }

    default:
      return state;
  }
};

export default billingData;
