import React from "react";

import { useSelector } from "react-redux";

import { Button } from "components/Form";

import { getLivestockSaleId } from "lib/navigation";

import { getActiveRole } from "selectors";

export const SaleExport = () => {
  const userRoleSlug = useSelector(getActiveRole).slug;

  return (
    <Button onClick={openSaleExport(userRoleSlug)}>
      (Staff) View Sale Export
    </Button>
  );
};

export const openSaleExport = userRoleSlug =>
  window.open(
    `/v2/export/?format=json&livestocksale-id__in=${getLivestockSaleId()}&output_format=html&user_role=${userRoleSlug}`,
  );
