export const DuplicateAttributeType = {
  ABN: "abn",
  AUCTION_PLUS_SHORT_CODE: "auctions_plus",
  EMAIL_RECIPIENT: "email_recipient",
  GOOGLE_MAPS_PLACE_ID: "address",
  NAME: "name",
  PROPERTY: "property",
};

export const ALL_DUPLICATE_ATTRIBUTES = Object.values(DuplicateAttributeType);

export function sortDuplicatesByScore(duplicateRelationTree) {
  duplicateRelationTree.forEach(relation =>
    relation.duplicates.sort((a, b) => b.score - a.score),
  );

  duplicateRelationTree.sort(
    (a, b) => b.duplicates[0].score - a.duplicates[0].score,
  );
}

export function scoreDuplicates(duplicateRelations) {
  /*
    A PIC contributes a score of 5 per PIC, up to 25 points, then 0.1 points per PIC thereafter
    An email contributes 15 points per email, up to 40 points, then 0.1 points per email thereafter
    ABN, AuctionsPlus Short Code, Address and Name matches all contribute 65 points

    The basic premise being that a duplicate PIC doesn't indicate a very good match
    a duplicate email address is more likely to be a better match than a PIC
    and an ABN, AuctionsPlus Short Code, Address or Name are far better indicators of a match,

    A matching ABN, or Name bears the same weight as 5 matching PICs and 3 matching Email Addresses.

    I expect that the weighting will need to be tweaked for better results when it goes out in the field.

    The points allocated _thereafter_ for Emails and PICs are to ensure that a suggestion with only 6
    matching PICs is weighted higher than a suggestion with 5 PICs, or that that one with a Name and 11 PICs
    is weighted higher than one with a Name and 6 PICs, whilst ensuring that the overall score it
    not contributed to in any significant way by having say 50 PICs
   */

  duplicateRelations.forEach(relation => {
    relation.duplicates.forEach(relatedDuplicate => {
      let score = 0;

      const duplicatePics = relatedDuplicate.duplicateValues.filter(
        relatedDuplicateValue =>
          relatedDuplicateValue.type === DuplicateAttributeType.PROPERTY,
      );
      const picScore =
        Math.min(duplicatePics.length * 5, 25) +
        Math.min((duplicatePics.length - 5) * 0.1, 0);

      const duplicateEmails = relatedDuplicate.duplicateValues.filter(
        relatedDuplicateValue =>
          relatedDuplicateValue.type === DuplicateAttributeType.EMAIL_RECIPIENT,
      );

      const emailScore =
        Math.min(duplicateEmails.length * 15, 40) +
        Math.min((duplicateEmails.length - 3) * 0.1, 0);

      relatedDuplicate.duplicateValues.forEach(relatedDuplicateValue => {
        if (
          relatedDuplicateValue.type === DuplicateAttributeType.ABN ||
          relatedDuplicateValue.type ===
            DuplicateAttributeType.AUCTION_PLUS_SHORT_CODE ||
          relatedDuplicateValue.type ===
            DuplicateAttributeType.GOOGLE_MAPS_PLACE_ID ||
          relatedDuplicateValue.type === DuplicateAttributeType.NAME
        ) {
          score += 65;
        }
      });

      relatedDuplicate.score = score + picScore + emailScore;
    });
  });
}

export function resolveDuplicatesRelations(duplicateRelationTree) {
  const duplicateRelations = Object.entries(duplicateRelationTree).map(
    ([masterBusinessId, duplicateMasterBusinessRelations]) => ({
      masterBusinessId,
      duplicates: Object.entries(duplicateMasterBusinessRelations).map(
        ([relatedMasterBusinessId, duplicateValues]) => ({
          relatedMasterBusinessId,
          duplicateValues,
          score: 0,
        }),
      ),
    }),
  );

  scoreDuplicates(duplicateRelations);

  sortDuplicatesByScore(duplicateRelations);

  return duplicateRelations;
}

export function removeDuplicateRelation(
  duplicateRelations,
  aBusinessId,
  bBusinessId,
) {
  const aBusinessEntry = duplicateRelations.find(
    duplicate => duplicate.masterBusinessId === aBusinessId,
  );
  if (!aBusinessEntry) {
    return;
  }

  const relatedIndex = aBusinessEntry.duplicates.findIndex(
    relatedDuplicate =>
      relatedDuplicate.relatedMasterBusinessId === bBusinessId,
  );
  if (relatedIndex > -1) {
    aBusinessEntry.duplicates.splice(relatedIndex, 1);

    if (aBusinessEntry.duplicates.length === 0) {
      duplicateRelations.splice(duplicateRelations.indexOf(aBusinessEntry), 1);
    }
  }
}

export function dismissSuggestion(
  duplicateRelations,
  aBusinessId,
  bBusinessId,
) {
  removeDuplicateRelation(duplicateRelations, aBusinessId, bBusinessId);

  scoreDuplicates(duplicateRelations);

  sortDuplicatesByScore(duplicateRelations);
}

export function dismissSuggestions(duplicateRelations, aBusinessId) {
  const duplicateRelationIndex = duplicateRelations.findIndex(
    duplicate => duplicate.masterBusinessId === aBusinessId,
  );

  if (duplicateRelationIndex > -1) {
    duplicateRelations.splice(duplicateRelationIndex, 1);

    scoreDuplicates(duplicateRelations);

    sortDuplicatesByScore(duplicateRelations);
  }
}
export function mergeSuggestions(duplicateRelations, aBusinessId) {
  const duplicateRelationIndex = duplicateRelations.findIndex(
    duplicate => duplicate.masterBusinessId === aBusinessId,
  );

  if (duplicateRelationIndex > -1) {
    duplicateRelations.splice(duplicateRelationIndex, 1);

    scoreDuplicates(duplicateRelations);

    sortDuplicatesByScore(duplicateRelations);
  }
}

export function filterSuggestions(duplicateRelations, filters) {
  if (!Array.isArray(duplicateRelations)) {
    return [];
  }

  return duplicateRelations.reduce((acc, duplicateRelation) => {
    const duplicates = duplicateRelation.duplicates.reduce(
      (acc, relatedDuplicate) => {
        if (
          relatedDuplicate.duplicateValues.filter(duplicateValue =>
            filters.includes(duplicateValue.type),
          ).length > 0
        ) {
          acc.push(relatedDuplicate);
        }
        return acc;
      },
      [],
    );
    if (duplicates.length > 0) {
      acc.push({
        masterBusinessId: duplicateRelation.masterBusinessId,
        duplicates,
      });
    }
    return acc;
  }, []);
}
