import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

export const draftingAttributesMap = {
  accreditationAntibioticFree: "accreditation_antibiotic_free",
  accreditationBackgrounded: "accreditation_backgrounded",
  accreditationEU: "accreditation_eu",
  accreditationGrassFed: "accreditation_grass_fed",
  accreditationJBAS: "accreditation_jbas",
  accreditationNEE: "accreditation_nee",
  accreditationOrganic: "accreditation_organic",
  accreditationPCAS: "accreditation_pcas",
  accreditationPTE: "accreditation_pte",
  accreditationPTIC: "accreditation_ptic",
  advancedBreed1Hd: "advanced_breed_1_hd",
  advancedBreed1Id: "advanced_breed_1_id",
  advancedBreed2Hd: "advanced_breed_2_hd",
  advancedBreed2Id: "advanced_breed_2_id",
  advancedBreed3Hd: "advanced_breed_3_hd",
  advancedBreed3Id: "advanced_breed_3_id",
  ageRangeTimeUnit: "age_range_time_unit",
  animalName: "animal_name",
  b12Vac: "b12_vac",
  backline: "backline",
  bloodline: "bloodline",
  bySire: "by_sire",
  conditionForward: "condition_forward",
  conditionPrime: "condition_prime",
  conditionStore: "condition_store",
  dentition0: "dentition_0",
  dentition2: "dentition_2",
  dentition4: "dentition_4",
  dentition6: "dentition_6",
  dentition8: "dentition_8",
  dentitionBroken: "dentition_broken",
  depasturedId: "depastured_id",
  drenched: "drenched",
  dressingPercent: "dressing_percent",
  dropEnd: "drop_end",
  dropStart: "drop_start",
  estCarcassWeight: "est_carcass_weight",
  fatScore1: "fat_score_1",
  fatScore2: "fat_score_2",
  fatScore3: "fat_score_3",
  fatScore4: "fat_score_4",
  fatScore5: "fat_score_5",
  fatScore6: "fat_score_6",
  feeder: "feeder",
  frameStructure: "frame_structure",
  grainFedDays: "grain_fed_days",
  gudairApproved: "gudair_approved",
  hornDehorned: "horn_dehorned",
  hornHorned: "horn_horned",
  hornPolled: "horn_polled",
  hornScurred: "horn_scurred",
  hornTipped: "horn_tipped",
  isEstimatedWeight: "is_estimated_weight",
  isWeaned: "is_weaned",
  joinedEnd: "joined_end",
  joinedStart: "joined_start",
  MAndTS: "m_and_ts",
  maxAge: "max_age",
  maxMassGrams: "max_mass_grams",
  minAge: "min_age",
  minMassGrams: "min_mass_grams",
  muscleScore1: "muscle_score_1",
  muscleScore2: "muscle_score_2",
  muscleScore3: "muscle_score_3",
  muscleScore4: "muscle_score_4",
  muscleScore5: "muscle_score_5",
  muscleScore6: "muscle_score_6",
  notStationMated: "not_station_mated",
  ojdVac: "ojd_vac",
  painRelief: "pain_relief",
  partOfRun: "part_of_run",
  publicDescription: "public_description",
  publicFaultDescription: "public_fault_description",
  pregStatus: "preg_status",
  SAEligible: "sa_eligible",
  shearing: "shearing",
  shearingStatus: "shearing_status",
  sil: "sil",
  sixInOne: "six_in_one",
  stationMated: "station_mated",
  tagNumber: "tag_number",
  vendorBredOverride: "vendor_bred_override",
  weighLocation: "weigh_location",
  xBloodline: "x_bloodline",
  animalHealthStatementOverride: "animal_health_statement_override",
  isNotMouthed: "is_not_mouthed",
};

export const deserializeDraftingAttributes = buildDeserializer(
  draftingAttributesMap,
);
export const serializeDraftingAttributes = buildSerializer(
  draftingAttributesMap,
);
