import React, { useEffect, useMemo } from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";

import { UserLevelAction } from "actions/userLevels";

import AgGrid from "components/AgGrid/AgGrid";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import Button from "components/Button";
import { SearchInput } from "components/Form";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { TitleText } from "components/Text";
import { userTypeConfigMap } from "components/UserRoles/constants";
import {
  defaultColumnDef,
  getColumnDefs,
} from "components/UserRoles/List/columnDefs";

import {
  getUserTypeDisplayName,
  getUserTypeDisplayNamePlural,
} from "constants/users";

import { openEditUserRoleModal } from "lib/navigation";
import { hasPermission } from "lib/permissions";

const getRowId = params => params.data.id;

export default function UserRoleTable(props) {
  const { userRoleType } = props;
  const [searchText, setSearchText] = React.useState("");

  const rowData = useSelector(state =>
    userTypeConfigMap[userRoleType].tableDataSelector(state),
  );

  const createPermissionsObject = useSelector(state =>
    userTypeConfigMap[userRoleType].createPermissionObjectSelector(state),
  );

  const showCreateButton = hasPermission(
    createPermissionsObject,
    userTypeConfigMap[userRoleType].createPermission,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userTypeConfigMap[userRoleType].action.request());
    dispatch(UserLevelAction.request());
  }, [userRoleType, dispatch]);

  const columnDefs = useMemo(() => {
    return getColumnDefs(userRoleType);
  }, [userRoleType]);

  function openNewRoleButton() {
    openEditUserRoleModal(userRoleType, null);
  }

  return (
    <Column fullHeight fullWidth>
      <Box p={2}>
        <Grid container>
          <Grid container item xs={12} md={4} alignItems="center">
            <TitleText>
              Manage {getUserTypeDisplayNamePlural(userRoleType)}
            </TitleText>
          </Grid>
          <Grid
            item
            container
            xs={8}
            md={5}
            alignItems="center"
            justifyContent="center"
          >
            <SearchInput onChange={setSearchText} value={searchText} />
          </Grid>
          <Grid
            item
            container
            xs={4}
            md={3}
            alignItems="center"
            justifyContent="center"
          >
            {showCreateButton ? (
              <Button onClick={openNewRoleButton} data-tour="addRoleType">
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;Add {getUserTypeDisplayName(userRoleType)}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Box>
      <Row full>
        <WaitForSync
          requiredData={userTypeConfigMap[userRoleType].requiredData}
        >
          <Column fullHeight flexGrow>
            <CollapseTableWrapper>
              <AgGrid
                rowData={rowData}
                quickFilterText={searchText}
                columnDefs={columnDefs}
                defaultColDef={defaultColumnDef}
                pagination
                paginationPageSize={100}
                enableCellTextSelection
                getRowId={getRowId}
              />
            </CollapseTableWrapper>
          </Column>
        </WaitForSync>
      </Row>
    </Column>
  );
}
