import React from "react";
import { useBoolean } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { useSelector } from "react-redux";
import {
  getIsCurrentSaleClearingSale,
  selectSoldCents,
  selectSoldHeadCount,
  selectSoldProgenyCount,
  selectSoldSaleLotCount,
} from "selectors";
import { getDollarPriceStringFromCents } from "lib";
import { ActionButton } from "components/AgGrid/ActionButton";
import { SaleProgressDetailsDialog } from "components/SaleProgress/SaleProgressDetailsDialog";
import { getProgenyDisplayCount } from "lib/saleLot";

export default function SaleOverviewStatusBar(): React.JSX.Element {
  const buttonRef = React.useRef(null);

  const [
    isSaleProgressDetailsVisible,
    openSaleProgressDetails,
    closeSaleProgressDetails,
  ] = useBoolean(false);

  const soldHeadCount = useSelector(selectSoldHeadCount);
  const soldSaleLotsCount = useSelector(selectSoldSaleLotCount);
  const soldProgenyCount = useSelector(selectSoldProgenyCount);
  const soldCents = useSelector(selectSoldCents);
  const isClearingSale = useSelector(getIsCurrentSaleClearingSale);
  const soldMetric = isClearingSale
    ? soldSaleLotsCount
    : `${soldHeadCount} ${getProgenyDisplayCount(soldProgenyCount)}`;
  const soldMetricText = isClearingSale ? "Lots" : "Hd";
  const totalSoldText = `Total Sold (${soldMetricText}):`;

  return (
    <>
      <div>
        <span className="p-2">
          {totalSoldText}
          {soldMetric}
        </span>
        <span className="p-2">
          {`Total Sold ($): ${getDollarPriceStringFromCents(
            soldCents,
            "N/A",
            true,
          )}`}
        </span>
        <ActionButton
          className="p-2"
          data-tour="view-sale-progress-details"
          onClick={openSaleProgressDetails}
          ref={buttonRef}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
          &nbsp;View Details
        </ActionButton>
      </div>

      <SaleProgressDetailsDialog
        isOpen={isSaleProgressDetailsVisible as boolean}
        onClose={closeSaleProgressDetails as () => void}
      />
    </>
  );
}
