import React from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { closeConfirmModal, openConfirmModal } from "actions";

import { LedgerAccountAction } from "actions/ledgerAccounts";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import { LedgerAccountForm } from "components/LedgerAccount/Form";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { AuditLogTypes } from "constants/auditLog";
import { LedgerAccountStatuses } from "constants/ledgerAccounts";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  LedgerAccountPermissions,
} from "constants/permissions";

import { deepObjectChanges } from "lib/compare";
import { hasPermission } from "lib/permissions";

import {
  getActiveLivestockAgentDeployment,
  getLedgerAccountById,
} from "selectors";

import { useHasDeploymentPermission, useModalAdapter } from "hooks";

import { LedgerAccountValidationSchema } from "./validationSchema";

export function LedgerAccountModalAdapter(props) {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.EditLedgerAccount,
    props,
  );

  const { id, ...formDefaults } = hashParams;

  const hasBillingRunFeaturePermission = useHasDeploymentPermission(
    DeploymentPermissions.featureBillingRun,
  );

  if (!hasBillingRunFeaturePermission) {
    return null;
  }

  return (
    <WaitForSync
      requiredData={[ApiModel.LEDGER_ACCOUNTS, ApiModel.MASTER_LEDGER_ACCOUNTS]}
    >
      <LedgerAccountModal
        id={id}
        formDefaults={formDefaults}
        onClose={onClose}
        returnTo={returnTo}
      />
    </WaitForSync>
  );
}

export function Footer(props) {
  const { onClose, id } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);

  const deployment = useSelector(getActiveLivestockAgentDeployment);

  const hasCreatePermission = hasPermission(
    deployment,
    DeploymentPermissions.canCreateLedgerAccount,
  );
  const ledgerAccount = useSelector(getLedgerAccountById(id));
  const hasEditPermission = hasPermission(
    ledgerAccount,
    LedgerAccountPermissions.read,
  );

  const hasSavePermission = id ? hasEditPermission : hasCreatePermission;
  const hasDeletePermission = hasPermission(
    ledgerAccount,
    LedgerAccountPermissions.delete,
  );

  useSubmitHandler(true, setIsSubmitEnabled);
  const dispatch = useDispatch();
  const onClickConfirmDelete = () => {
    dispatch(LedgerAccountAction.delete(id));
    dispatch(closeConfirmModal());
    onClose();
  };

  function onClickCancelDelete() {
    dispatch(closeConfirmModal());
  }

  function onClickDelete() {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: "Are you sure you want to delete the Ledger Account?",
        actions: [
          {
            label: "Cancel",
            onClick: onClickCancelDelete,
            secondary: true,
          },
          {
            label: "Delete Ledger Account",
            onClick: onClickConfirmDelete,
            secondary: false,
          },
        ],
      }),
    );
  }

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      {ledgerAccount && (
        <DeleteButton
          type="button"
          disabled={!isSubmitEnabled || !hasDeletePermission}
          onClick={onClickDelete}
        >
          Delete
        </DeleteButton>
      )}
      <Button type="submit" disabled={!isSubmitEnabled || !hasSavePermission}>
        Save
      </Button>
    </>
  );
}

export function LedgerAccountModal(props) {
  const { id, onClose, formDefaults } = props;
  const dispatch = useDispatch();
  const ledgerAccount = useSelector(getLedgerAccountById(id)) || {};
  const deployment = useSelector(getActiveLivestockAgentDeployment);
  const initialValues = {
    masterLedgerAccountIds: [],
    deploymentId: deployment.id,
    saleyardId: null,
    status: LedgerAccountStatuses.Active,
    ...formDefaults,
    ...ledgerAccount,
  };

  function onSubmit(values) {
    // Send empty array if not mapped, not null.
    values.masterLedgerAccountIds = values.masterLedgerAccountIds || [];
    if (values.id) {
      dispatch(
        LedgerAccountAction.update(
          { id, ...deepObjectChanges(initialValues, values) },
          { changeReason: "Updated from Edit Ledger Account modal" },
        ),
      );
    } else {
      dispatch(LedgerAccountAction.create(values));
    }

    onClose();
  }

  const isInXero = !!ledgerAccount?.xeroAccountId;

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="sm">
      <DialogTitle onClose={onClose}>
        {id && (
          <AuditLogLink
            auditLogType={AuditLogTypes.LEDGER_ACCOUNT}
            dataId={id}
            returnTo={window.location.hash}
          />
        )}
        &nbsp;{id ? "Edit" : "Create"} Ledger Account {initialValues.code}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={LedgerAccountValidationSchema}
      >
        <Form data-tour="ledger-account-form">
          <DialogContent dividers>
            <LedgerAccountForm id={id} isInXero={isInXero} />
          </DialogContent>
          <DialogActions>
            <Footer id={id} onClose={onClose} />
          </DialogActions>
        </Form>
      </Formik>
    </ZoomyDialog>
  );
}
