import { get } from "lodash";

import { ColumnType } from "components/AgGrid/constants";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import { CommentColId, CommentColumnDef } from "constants/columnDefinitions";
import { CommentTypes } from "constants/comments";

import { formatters, getters } from "lib/agGrid";
import { naturalSortStringComparator } from "lib/agGrid/columns/comparators";
import {
  saleLotScanLotRenderer,
  scanLotSaleLotLotNumbersRenderer,
  singleSaleLotLotNumbersRenderer,
  singleScanLotRenderer,
} from "lib/agGrid/renderers/scanLotrenderers";

import {
  WeighLotActionsColumnRenderer,
  WeighLotScanStatusesRenderer,
  WeighLotScanStatusRenderer,
  WeighLotStatusRenderer,
} from "./renderers";

export const WeighLotColumnId = {
  STATUS: "9974314c-74a7-478b-add3-d0560c2fcb64",
  SCAN_STATUSES: "28d6f82f-07a0-4b29-9e26-0087bfa636b2",
  WEIGH_LOT_NUMBER: "872351d0-adc7-4241-b5b3-221fc5b8d080",
  TOTAL_MASS_GRAMS: "68288e74-1f8d-4f96-8ca9-ed90546752b1",
  SCALE_NAME: "25e9739b-3bbe-4e1f-bc71-744064fc5d81",
  WEIGH_DATE_TIME: "a906080c-6bcc-427f-ad2f-755ca9b6d828",
  EIDS: "2ef76aa6-85b2-4fe5-b72f-278c684aa95d",
  RECEIVAL_LOTS: "a90f9ccf-0095-462e-861b-3c87286fd940",
  PEN_SCAN_LOTS: "19bab9cd-8307-4692-bc69-2ff5d1c74943",
  SALE_LOTS: "3c43a9f3-0c66-41c2-b043-9b967a2e8801",
  FILE_NAME: "19f3a9c2-3ef6-4d41-a450-d7eb409908fa",
};

const eidListGetter = ({ data, colDef }) => {
  const value = get(data, colDef.field, []);
  return value.map(val => val.eid);
};

export const weighLotColumns = [
  {
    colId: WeighLotColumnId.STATUS,
    enablePivot: true,
    enableRowGroup: true,
    field: "status",
    pinned: "left",
    headerName: "Status",
    cellRenderer: WeighLotStatusRenderer,
  },
  {
    colId: WeighLotColumnId.SCAN_STATUSES,
    enablePivot: true,
    enableRowGroup: true,
    field: "weighLotScanStatuses",
    pinned: "left",
    headerName: "Weigh Lot Scan Statuses",
    cellRenderer: WeighLotScanStatusesRenderer,
    width: 300,
  },
  {
    colId: WeighLotColumnId.EIDS,
    field: "weighLotScans",
    pinned: "left",
    headerName: "EIDs",
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
    valueGetter: eidListGetter,
    valueFormatter: formatters.listCountFormatter,
    cellRenderer: "agGroupCellRenderer",
  },
  {
    colId: WeighLotColumnId.WEIGH_LOT_NUMBER,
    enablePivot: true,
    enableRowGroup: true,
    field: "lotNumber",
    headerName: "Weigh Lot Number",
    width: 90,
    comparator: naturalSortStringComparator,
  },
  {
    colId: WeighLotColumnId.TOTAL_MASS_GRAMS,
    enablePivot: true,
    enableRowGroup: true,
    field: "totalMassGrams",
    headerName: "Total Weight (kg)",
    width: 120,
    valueFormatter: formatters.kilogramsFormatter,
    valueGetter: getters.kilogramsGetter,
  },
  {
    colId: WeighLotColumnId.SCALE_NAME,
    enablePivot: true,
    enableRowGroup: true,
    field: "scaleName",
    headerName: "WeighBridge",
    width: 150,
  },
  {
    colId: WeighLotColumnId.FILE_NAME,
    enablePivot: true,
    enableRowGroup: true,
    field: "fileName",
    headerName: "File Name",
    width: 150,
  },
  {
    colId: WeighLotColumnId.WEIGH_DATE_TIME,
    enablePivot: true,
    enableRowGroup: true,
    field: "weighDateTime",
    type: ColumnType.DATE_TIME,
  },

  {
    colId: WeighLotColumnId.RECEIVAL_LOTS,
    field: "receivalLots",
    headerName: "Receival Lots",
    width: 200,
    enablePivot: true,
    enableRowGroup: true,
    cellRenderer: saleLotScanLotRenderer,
  },
  {
    colId: WeighLotColumnId.PEN_SCAN_LOTS,
    field: "penScanLots",
    headerName: "Pen Scan Lots",
    width: 200,
    enablePivot: true,
    enableRowGroup: true,
    cellRenderer: saleLotScanLotRenderer,
  },
  {
    field: "saleLots",
    headerName: "Sale Lots",
    colId: WeighLotColumnId.SALE_LOTS,
    enablePivot: true,
    enableRowGroup: true,
    width: 200,
    cellRenderer: scanLotSaleLotLotNumbersRenderer,
  },
  {
    headerName: ACTION_COLUMN_NAME,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: WeighLotActionsColumnRenderer,
    colId: ACTION_COLUMN_ID,
    suppressCellFlash: true,
    width: 300,
    mobileWidth: 100,
    pinned: "right",
  },
  {
    ...CommentColumnDef[CommentColId.COMMENTS],
    pinned: "right",
    valueGetter: params => {
      if (!params.data) {
        return null;
      }

      const { unresolvedCommentCount, resolvedCommentCount, weighLot } =
        params.data;

      return {
        commentType: CommentTypes.WEIGH_LOT,
        commentTypeId: weighLot.id,
        unresolvedCommentCount,
        resolvedCommentCount,
      };
    },
  },
];

export const WeighLotScanColumnId = {
  STATUS: "2b09370b-409f-48a6-9da0-dc505c578870",
  EID: "d06116a4-5b36-4f14-b46d-e170a34d193e",
  TOTAL_MASS_GRAMS: "938199cc-9d66-4566-ad32-ee3cb49ccd93",
  RECEIVAL_LOT: "f0e7e7bb-1ed1-4044-8ec1-43f1e8d3b196",
  PEN_SCAN_LOT: "54903501-3f25-411e-bc90-60f60f83e8d3",
  SALE_LOT: "c0a8b2e4-b4aa-494f-ba85-a4e86ae55493",
};

export const weighLotScanColumns = {
  [WeighLotScanColumnId.STATUS]: {
    colId: WeighLotScanColumnId.STATUS,
    headerName: "Status",
    field: "weighLotScan.status",
    cellRenderer: WeighLotScanStatusRenderer,
    width: 300,
    resizable: true,
  },
  [WeighLotScanColumnId.EID]: {
    colId: WeighLotScanColumnId.EID,
    headerName: "EID",
    field: "weighLotScan.eid",
    resizable: true,
    maxWidth: 200,
  },
  [WeighLotScanColumnId.TOTAL_MASS_GRAMS]: {
    colId: WeighLotScanColumnId.TOTAL_MASS_GRAMS,
    field: "weighLotScan.totalMassGrams",
    valueGetter: getters.kilogramsGetter,
    valueFormatter: formatters.kilogramsFormatter,
    headerName: "Weight (kg)",
    resizable: true,
    maxWidth: 200,
    type: ColumnType.NUMERIC,
  },
  [WeighLotScanColumnId.RECEIVAL_LOT]: {
    colId: WeighLotScanColumnId.RECEIVAL_LOT,
    field: "weighLotScan.receivalLot",
    headerName: "Receival Lot",
    cellRenderer: singleScanLotRenderer,
    resizable: true,
    maxWidth: 200,
  },
  [WeighLotScanColumnId.PEN_SCAN_LOT]: {
    colId: WeighLotScanColumnId.PEN_SCAN_LOT,
    field: "weighLotScan.penScanLot",
    headerName: "Pen Scan Lot",
    cellRenderer: singleScanLotRenderer,
    resizable: true,
    maxWidth: 200,
  },
  [WeighLotScanColumnId.SALE_LOT]: {
    colId: WeighLotScanColumnId.SALE_LOT,
    field: "weighLotScan.saleLot",
    headerName: "Sale Lot",
    cellRenderer: singleSaleLotLotNumbersRenderer,
    resizable: true,
    maxWidth: 200,
  },
  [WeighLotColumnId.WEIGH_LOT_NUMBER]: {
    colId: WeighLotColumnId.WEIGH_LOT_NUMBER,
    field: "weighLot.lotNumber",
    headerName: "Weigh Lot Number",
    resizable: true,
    maxWidth: 200,
  },
  [WeighLotColumnId.SCALE_NAME]: {
    colId: WeighLotColumnId.SCALE_NAME,
    field: "weighLot.scaleName",
    headerName: "Weigh Lot Scale",
    resizable: true,
    maxWidth: 200,
  },
};
