import React, { memo, useLayoutEffect, useRef } from "react";

import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  CardMetaItem,
  MetadataField,
  PrimaryHeading,
} from "components/Card/Elements";
import StatusCard from "components/Card/StatusCard";
import { MarkChips, WeightChip } from "components/Chip";
import { Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import LotCard from "components/LotCard";
import { Subtitle } from "components/Text";

import { UNKNOWN_BUSINESS_NAME } from "constants/businesses";
import { cards } from "constants/sale";

import { formatWeightKg } from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { getConsignmentCode } from "lib/consignments";
import { getAverageWeightFormatted } from "lib/saleLot";

import {
  formatSaleLot,
  getAuctionPenById,
  getBusinessById,
  getConsignmentById,
  getCurrentSale,
  getCurrentSaleyard,
  getIsFetchingBusinesses,
  getSaleLotById,
  getSaleLotsBySale,
  selectAgencyByConsignmentIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectScansBySaleLotIdLookup,
} from "selectors";

const Card = styled(StatusCard)(
  ({ theme, selected }) => `
  ${
    selected
      ? `border: 2px solid ${theme.colors.separatorBlue};
  border-right: 2px solid ${theme.colors.white};
  box-shadow: none;
  `
      : `
      border-right: 2px solid ${theme.colors.separatorBlue}; 
      background: ${theme.colors.grayF3};
      `
  }
  margin: 0;
  padding-right: ${theme.space[2]}px;
  padding-left: ${theme.space[1]}px;
`,
);

const CardLayout = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: 1fr;
  grid-template-areas: "pen-vendor code hd"  "tags tags tags";
  grid-gap: ${theme.space[1]}px;
`,
);

const HoverablePopover = styled(Popover)`
  pointer-events: none;
`;

const useStyles = makeStyles({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
    overflow: "visible",
  },
});

export const MinimalSaleLotCard = memo(
  ({ onSelectSaleLot, id, isSelected, cardEl, setCardEl, slim = false }) => {
    const cardRef = useRef();
    const saleLot = useSelector(getSaleLotById(id)) || {};
    const consignment = useSelector(getConsignmentById(saleLot.consignment_id));
    const auctionPen = useSelector(getAuctionPenById(saleLot.auction_pen_id));
    const isFetchingBusinesses = useSelector(getIsFetchingBusinesses);
    const vendor = useSelector(getBusinessById(saleLot.vendor_id));

    const penName = getAuctionPenDisplayName(auctionPen);
    const consignmentCode = getConsignmentCode(consignment);
    const hasWeight = Boolean(saleLot.total_mass_grams);
    const isDetailedCardVisible = Boolean(cardEl === cardRef.current);
    const averageWeightFormatted = getAverageWeightFormatted(saleLot);

    const classes = useStyles();

    function onClickStatusCard() {
      typeof onSelectSaleLot === "function" && onSelectSaleLot(id);
    }

    useLayoutEffect(() => {
      if (isSelected) {
        cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, [isSelected, cardRef]);

    const [timeoutId, setTimeoutId] = React.useState(null);

    function attachDetailedCard(event) {
      //   If we hover and there is no button depressed
      if (event.buttons === 0) {
        if (!timeoutId) {
          setTimeoutId(
            setTimeout(() => {
              setCardEl(cardRef.current);
            }, 1000),
          );
        }
      }
    }

    function closeDetailedCard() {
      setCardEl(null);
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
      setCardEl(null);
    }

    let vendorNameNode = UNKNOWN_BUSINESS_NAME;

    if (vendor) {
      vendorNameNode = vendor.name;
    } else if (isFetchingBusinesses) {
      vendorNameNode = <LoadingSpinner size={12} subjectName="Businesses" />;
    }
    return (
      <Card
        ref={cardRef}
        status={isSelected ? "separatorBlue" : null}
        selected={isSelected}
        onClick={onClickStatusCard}
        onMouseEnter={attachDetailedCard}
        onMouseLeave={closeDetailedCard}
        tabIndex={0}
        data-tour="saleLotCard"
      >
        <CardLayout>
          <CardMetaItem gridArea="pen-vendor">
            {isSelected ? (
              <>
                <PrimaryHeading color="success">{penName}</PrimaryHeading>
                <Subtitle dark>{vendorNameNode}</Subtitle>
              </>
            ) : (
              <>
                <PrimaryHeading>{penName}</PrimaryHeading>
                <Subtitle>{vendorNameNode}</Subtitle>
              </>
            )}
          </CardMetaItem>
          <MetadataField gridArea="code" title="Ven" value={consignmentCode} />
          <MetadataField gridArea="hd" title="Hd" value={saleLot.quantity} />
          {!slim && (
            <CardMetaItem gridArea="tags">
              <Row reverse justifyBetween>
                <Row justifyEnd>
                  {hasWeight && (
                    <WeightChip dataTour="totalWeightChip">
                      {formatWeightKg(saleLot.total_mass_grams)}
                    </WeightChip>
                  )}
                  {averageWeightFormatted && (
                    <WeightChip>Avg. Wt {averageWeightFormatted} kg</WeightChip>
                  )}
                </Row>
                <MarkChips marks={saleLot.marks} />
              </Row>
            </CardMetaItem>
          )}
        </CardLayout>
        <HoverablePopover
          open={isDetailedCardVisible}
          className={classes.popover}
          classes={{
            paper: classes.popoverContent,
          }}
          anchorEl={cardRef.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            // This needs to cover the scrollbar or any gap between the hoverable component and the popover.
            horizontal: 8,
            vertical: 0,
          }}
          // Dont hide the backdrop
          BackdropProps={{}}
          TransitionProps={{
            onExiting: closeDetailedCard,
            onExited: closeDetailedCard,
          }}
        >
          <DetailedSaleLotCardPopover id={id} />
        </HoverablePopover>
      </Card>
    );
  },
);

const getSaleLotsBySaleById = saleLotId => state =>
  getSaleLotsBySale(state).find(saleLot => saleLot.id === saleLotId);

// Take up at most half the page minus  lot selection minus the sidebar.
const MaxWidthWrapper = styled.div`
  max-width: calc((100vw - 300px - 72px) / 2);
  min-height: 290px;
  display: grid;
`;

function DetailedSaleLotCardPopover({ id }) {
  const scansBySaleLotId = useSelector(selectScansBySaleLotIdLookup);
  const speciesId = useSelector(state => getCurrentSale(state).species_id);
  const saleyard = useSelector(getCurrentSaleyard);
  const consignments = useSelector(selectAgencyByConsignmentIdLookup);
  const formattedSaleLot = formatSaleLot(
    useSelector(getSaleLotsBySaleById(id)),
    scansBySaleLotId,
    consignments,
    {},
    speciesId,
    selectIsWeighedBySaleLotIdLookup,
    saleyard,
  );
  const { pathname, search } = window.location;
  const basePath = `${pathname}${search}`;
  return (
    <MaxWidthWrapper>
      <LotCard
        key={formattedSaleLot.id}
        cardType={cards.SALE_LOT_CARD}
        saleLot={formattedSaleLot}
        consignmentId={id}
        basePath={basePath}
        showActions
        showBuyer
        showBuyerWay
        showPen
        showScanning
        showVendor
      />
    </MaxWidthWrapper>
  );
}
