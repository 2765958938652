import { RESET_STATE } from "@redux-offline/redux-offline/lib/constants";

import { offlineActionCreator, offlineSubActionCreator } from "actions/lib";

import {
  CHECK_USER_AUTH,
  CLEAR_LOCAL_STATE,
  CLEAR_LOGIN_FAILURE,
  CURRENT_USER,
  FETCH_GEO_DATA,
  GET_DEFAULT_ROUNDS_AND_SALE_TYPES,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  NLIS_SIGN_UP_FAILURE,
  NLIS_SIGN_UP_REQUEST,
  NLIS_SIGN_UP_SUCCESS,
  RECEIVE_SOCKET_ID,
  RECEIVE_WATCHER_ENTRY,
  REQUEST_WATCHER_ENTRY,
  SEND_TO_USER_OVERVIEW,
  SET_ACTIVE_ROLE,
  SET_CONCURRENT_USER_BLOCK,
  SET_GEO_BLOCK,
  STORE_GEO_DATA,
  USER_ROLES,
} from "constants/actionTypes";

import {
  serializeBusinessUserRole,
  serializeCurrentUser,
  serializeLivestockAgentRole,
  serializeSaleWatcherRole,
  serializeSaleyardAdminRole,
  serializeScaleOperatorRole,
} from "lib/serializers/auth";

export function requestLogin(creds, returnTo = undefined) {
  return {
    type: LOGIN_REQUEST,
    creds,
    returnTo,
  };
}

export function loginSuccess(token, refreshToken) {
  return {
    type: LOGIN_SUCCESS,
    jwt: token,
    jwtRefreshToken: refreshToken,
  };
}

export function checkUserAuth(errorUrl) {
  return {
    type: CHECK_USER_AUTH.REQUEST,
    errorUrl,
  };
}

export function checkUserAuthSuccess(token, refreshToken) {
  return {
    type: CHECK_USER_AUTH.SUCCESS,
    jwt: token,
    jwtRefreshToken: refreshToken,
  };
}

export function checkUserAuthError(message) {
  return {
    type: CHECK_USER_AUTH.FAILURE,
    message,
  };
}

export function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    message,
  };
}

export function clearLoginFailure() {
  return {
    type: CLEAR_LOGIN_FAILURE,
  };
}

export function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function resetOfflineState() {
  // Clear redux-offline outbox
  return {
    type: RESET_STATE,
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}
export function clearLocalState() {
  return {
    type: CLEAR_LOCAL_STATE,
  };
}

export function logoutError(message) {
  return {
    type: LOGOUT_FAILURE,
    message,
  };
}

export function receiveSocketId(socketId) {
  return {
    type: RECEIVE_SOCKET_ID,
    socketId,
  };
}

export function requestNLISSignUp(creds, options = null) {
  return {
    type: NLIS_SIGN_UP_REQUEST,
    creds,
    options,
  };
}

export function receiveNLISSignUp(nlisCredentials, credentialType, options) {
  return {
    type: NLIS_SIGN_UP_SUCCESS,
    nlisCredentials,
    credentialType,
    options,
  };
}

export function nlisSignUpError(message) {
  return {
    type: NLIS_SIGN_UP_FAILURE,
    message,
  };
}

export function fetchGeoData() {
  return {
    type: FETCH_GEO_DATA,
  };
}

export function storeGeoData(position) {
  return {
    type: STORE_GEO_DATA,
    position,
  };
}

export function setGeoBlock(geoBlock) {
  return {
    type: SET_GEO_BLOCK,
    geoBlock,
  };
}

export function setConcurrentUserBlock(concurrentUserBlock) {
  return {
    type: SET_CONCURRENT_USER_BLOCK,
    concurrentUserBlock,
  };
}

export function setActiveRole(role) {
  return {
    type: SET_ACTIVE_ROLE.ACTION,
    role,
  };
}

export function sendToUserOverview() {
  return {
    type: SEND_TO_USER_OVERVIEW,
  };
}

export const requestDefaultRoundsAndSaleTypes = () => ({
  type: GET_DEFAULT_ROUNDS_AND_SALE_TYPES,
});

export const checkWatcherEntry = (
  livestocksale_id,
  verification,
  email = undefined,
) => ({
  type: REQUEST_WATCHER_ENTRY,
  livestocksale_id,
  verification,
  email,
});

export const receiveWatcherEntry = (
  status,
  auth,
  livestockSale,
  errorMessage = undefined,
) => ({
  type: RECEIVE_WATCHER_ENTRY,
  status,
  auth,
  livestockSale,
  errorMessage,
});

export const CurrentUserAction = offlineActionCreator(
  CURRENT_USER,
  serializeCurrentUser,
  // Note, this is the only available route on here, for GETing the one entry (self).  Later this may enumerate, PATCH, PUT, etc, but, later.
  () => `/v2/auth/user/self/`,
  false,
);

CurrentUserAction.requestAccountDelete = offlineSubActionCreator(
  CURRENT_USER.REQUEST_ACCOUNT_DELETION,
  () => `/v2/auth/user/request-account-deletion/`,
);

export const SaleyardAdminRoleAction = offlineActionCreator(
  USER_ROLES.SALEYARD_ADMIN,
  serializeSaleyardAdminRole,
  () => `/v2/auth/user-roles/saleyard-admin/`,
  false,
);

export const LivestockAgentRoleAction = offlineActionCreator(
  USER_ROLES.LIVESTOCK_AGENT,
  serializeLivestockAgentRole,
  () => `/v2/auth/user-roles/livestock-agent/`,
  false,
);

export const SaleWatcherRoleAction = offlineActionCreator(
  USER_ROLES.SALE_WATCHER,
  serializeSaleWatcherRole,
  () => `/v2/auth/user-roles/sale-watcher/`,
  false,
);

export const ScaleOperatorRoleAction = offlineActionCreator(
  USER_ROLES.SCALE_OPERATOR,
  serializeScaleOperatorRole,
  () => `/v2/auth/user-roles/scale-operator/`,
  false,
);

export const BusinessUserRoleAction = offlineActionCreator(
  USER_ROLES.BUSINESS_USER,
  serializeBusinessUserRole,
  () => `/v2/auth/user-roles/business-user/`,
  false,
);
