import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { fetchWithFullyQualifiedUrl } from "lib/fetch";
import toast from "lib/toast";

import { currentSaleSelector } from "selectors";

import "./transitions.css";

const VideoContainer = styled.div`
  padding: ${({ theme }) => theme.space[2]}px;
  margin: 0 ${({ theme }) => theme.space[1]}px;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

// What we render at to begin with, but after sizing is sorted, really just
// provides the aspect ratio.
const DEFAULT_RENDER_WIDTH = 500;
const DEFAULT_RENDER_HEIGHT = 281; // 4:3
const DEFAULT_ZOOM_RENDER_HEIGHT = 350;

const VideoFrame = styled.iframe`
  width: 100%;
  max-width: 90vw;
  ${({ fullHeight }) => (fullHeight ? "height: 100%;" : "")}
`;

const VideoEmbed = ({ videoUrl, fullHeightIframe = false }) => {
  const [signedUrl, setSignedUrl] = React.useState(null);
  const renderHeight = videoUrl.includes("zoom")
    ? DEFAULT_ZOOM_RENDER_HEIGHT
    : DEFAULT_RENDER_HEIGHT;

  const [height, setHeight] = React.useState(renderHeight);
  const iframeRef = React.useRef();

  const { livestocksale_id } = useSelector(currentSaleSelector);
  const roleSlug = useSelector(state => state.auth?.activeRole?.slug || {});

  const calculateHeight = React.useCallback(() => {
    const widthInPixels = iframeRef.current?.offsetWidth;
    if (widthInPixels) {
      const newHeight = (renderHeight / DEFAULT_RENDER_WIDTH) * widthInPixels;
      if (height !== newHeight) {
        setHeight(newHeight);
      }
    }
  }, [iframeRef, height, renderHeight, setHeight]);

  React.useEffect(() => {
    async function fetchSignature() {
      const url = `/v2/sales/${livestocksale_id}/signed_sale_link/`;
      const response = await fetchWithFullyQualifiedUrl(url, {
        headers: {
          "X-User-Role": roleSlug || "",
        },
      });
      if (response.ok) {
        const { streaming_video } = await response.json();
        setSignedUrl(streaming_video);
        calculateHeight();
      } else {
        toast.error("Unable to load the stream, please try again later.");
      }
    }

    if (videoUrl.includes("zoom")) {
      fetchSignature();
    } else if (videoUrl.includes("facebook")) {
      setSignedUrl(
        `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
          videoUrl,
        )}&width=${DEFAULT_RENDER_WIDTH}&show_text=false&appId=${FACEBOOK_APP_ID}&height=${DEFAULT_RENDER_HEIGHT}`,
      );
    }
  }, [videoUrl, roleSlug, livestocksale_id, calculateHeight]);

  // TODO - debounce this.
  // Calculate and re-set the height on resize.
  React.useEffect(() => {
    calculateHeight();
    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, [calculateHeight]);

  if (!signedUrl) {
    return null;
  }

  return (
    <VideoContainer>
      <VideoFrame
        src={signedUrl}
        ref={iframeRef}
        width="100%"
        height={height}
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media; camera; microphone"
        allowFullScreen
        title="Agrinous Auction"
        fullHeight={fullHeightIframe}
      />
    </VideoContainer>
  );
};

export default VideoEmbed;
