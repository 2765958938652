import React, { memo, useCallback, useRef, useState } from "react";

import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { createUnknownConsignmentPen } from "actions";

import { Button, SecondaryButton, SubmitHandler } from "components/Form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { PenTypes } from "constants/auctionPens";
import { Settings } from "constants/settings";

import {
  getExpandedEndPenDisplayName,
  getExpandedStartPenDisplayName,
} from "lib/auctionPens";
import {
  closeAllHashModals,
  openScanModal,
  returnToLocation,
} from "lib/navigation";

import { getSettings, getPrimaryAgency, currentSaleSelector } from "selectors";

import ScanToPenForm from "./ScanToPenForm";
import { ValidationSchemaWithAgency } from "./validationSchema";

const FormState = {
  DATA_ENTRY: 0,
  WORKING: 1,
  ERROR: 2,
};

const PostSubmitAction = {
  CLOSE: 0,
  SCAN: 1,
};

export function ScanToPenModalComponent(props) {
  const { returnTo } = props;
  const closeSelf = useCallback(() => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }, [returnTo]);

  const roundId = useSelector(getSettings)[Settings.round];
  const { agency_id: agencyId } = useSelector(getPrimaryAgency);
  const { pricing_type_id: pricingTypeId } = useSelector(currentSaleSelector);

  const [isFormValid, setIsFormValid] = useState(false);
  const [formState, setFormState] = useState(FormState.DATA_ENTRY);
  const formRef = useRef(null);
  const postSubmitAction = useRef(null);
  const dispatch = useDispatch();

  const initialValues = {
    agency_id: agencyId,
    buyer_id: undefined,
    destination_property_id: undefined,
    end_pen: undefined,
    end_pen_suffix: "",
    overflowPen: undefined,
    overflowQuantity: undefined,
    round_id: roundId,
    start_pen_prefix: "",
    start_pen: undefined,
    start_pen_suffix: "",
  };

  const onSubmit = useCallback(
    values => {
      const {
        agency_id: agencyId,
        buyer_id: buyerId,
        destination_property_id: destinationPropertyId,
        overflowPen,
        overflowQuantity,
        round_id: roundId,
      } = values;

      const auctionPenValues = {
        start_pen: getExpandedStartPenDisplayName(values),
        end_pen: getExpandedEndPenDisplayName(values),
      };

      const saleLotValues = {
        buyer_id: buyerId,
        destination_property_id: destinationPropertyId,
        overflowPen,
        overflowQuantity,
        pricing_type_id: pricingTypeId,
        quantity: 0,
      };

      setFormState(FormState.WORKING);

      const returnTo = `${window.location.pathname}${window.location.search}`;

      dispatch(
        createUnknownConsignmentPen(
          agencyId,
          roundId,
          saleLotValues,
          auctionPenValues,
          {
            ignoredSaleLotAttributes: "quantity",
            onSuccess: (consignmentId, saleLotId) => {
              if (postSubmitAction.current === PostSubmitAction.SCAN) {
                openScanModal(
                  consignmentId,
                  saleLotId,
                  false,
                  null,
                  PenTypes.SELLING,
                  returnTo,
                );
              } else {
                // Must be in an else statement, otherwise query params are set to "" on scanning screen initial render
                closeSelf();
              }
            },
            onError: errorMessage => {
              setFormState(FormState.ERROR);
              formRef.current.setFieldError("system", errorMessage);
            },
          },
        ),
      );
    },
    [closeSelf, dispatch, postSubmitAction, pricingTypeId],
  );

  function onClickSaveAndClose() {
    postSubmitAction.current = PostSubmitAction.CLOSE;
    formRef.current.submitForm();
  }

  function onClickSaveAndScan() {
    postSubmitAction.current = PostSubmitAction.SCAN;
    formRef.current.submitForm();
  }

  const showAgencyPicker = Boolean(!agencyId);
  const isSubmitEnabled =
    isFormValid &&
    (formState === FormState.DATA_ENTRY || formState === FormState.ERROR);

  return (
    <Dialog open onClose={closeSelf} maxWidth="sm" fullWidth>
      <DialogTitle onClose={closeSelf}>Scan To Pen</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validateOnChange
          validationSchema={ValidationSchemaWithAgency}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          <>
            <SubmitHandler
              setIsSubmitEnabled={setIsFormValid}
              allowInitialSubmit={false}
            />
            <Grid container>
              <ScanToPenForm showAgencyPicker={showAgencyPicker} />
            </Grid>
          </>
        </Formik>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          disabled={!isSubmitEnabled}
          onClick={onClickSaveAndClose}
        >
          Save & Close
        </SecondaryButton>
        <Button disabled={!isSubmitEnabled} onClick={onClickSaveAndScan}>
          Save & Scan
        </Button>
      </DialogActions>
    </Dialog>
  );
}
ScanToPenModalComponent.propTypes = {
  returnTo: PropTypes.string,
};

export default memo(ScanToPenModalComponent);
