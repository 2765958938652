// -----------------------------------------------
// Modifier tokens
// -----------------------------------------------
export const WeightModifier = {
  WEIGHT_5_CHARS: 170,
  WEIGHT_6_CHARS: 171,
  WEIGHT_7_CHARS: 172,
  // Default
  WEIGHT_8_CHARS: 173,
  WEIGHT_9_CHARS: 174,
  // Alternatively, this could a variable length output - need to field test
  WEIGHT_NONE: 179,
};

export const SignModifier = {
  SIGN_NONE: 180,
  // Default
  SIGN_SPACE_MINUS: 181,
  SIGN_PLUS_MINUS: 182,
  SIGN_ZERO_MINUS: 183,
};

export const DecimalPointModifier = {
  DECIMAL_POINT_NONE: 184,
  // Default
  DECIMAL_POINT_FULL_STOP: 185,
  DECIMAL_POINT_COMMA: 186,
};

export const LeadingZeroBlankingModifier = {
  LEADING_ZERO_ZERO: 187,
  // Default
  LEADING_ZERO_SPACE: 188,
};

export const ErrorStateOutputModifier = {
  // Default
  ERROR_STATE_SEND_WEIGHT: 189,
  ERROR_STATE_SEND_BLANK: 190,
  ERROR_STATE_SEND_DASHES: 191,
};

export const StatusCharCasingModifier = {
  // Default
  STATUS_CHARS_UPPERCASE: 192,
  STATUS_CHARS_LOWERCASE: 193,
};

// -----------------------------------------------
// Data tokens
// -----------------------------------------------

// Weight outputs
export const WeightDataType = {
  SELECTED: 200,
  DISPLAYED: 201,
  GROSS: 202,
  NET: 203,
  TARE: 204,
  TOTAL: 205,
};

// Weight status outputs
export const StatusDataOutput = {
  UNITS: 210,
  COMBINED_STATUS_STANDARD: 211,
  COMBINED_STATUS_NO_MOTION: 212,
  COMBINED_STATUS_GROSS_NET_ONLY: 213,
  MOTION_M_SPACE: 214,
  MOTION_M_S: 215,
  UNITS_SPACE_ON_MOTION: 216,
  CAPACITY_CONDITION_M_C_SPACE: 217,
  CAPACITY_CONDITION_M_I_O_SPACE: 218,
  LOAD_CONDITION_I_O_U: 219,
  ZERO_Z_SPACE: 220,
  RANGE_SINGLE_1_2: 221,
  STABILITY_ST_US_OVERLOAD_OL: 222,
  TIME: 230,
  DATE: 231,
};

// -----------------------------------------------
// Control tokens
// -----------------------------------------------

export const CustomTerminationChar = {
  NULL_CHAR: 128,
  DO_NOT_SEND: 0,
};

export const WeightDataLengths = new Map([
  // Weight values
  [WeightModifier.WEIGHT_5_CHARS, 5],
  [WeightModifier.WEIGHT_6_CHARS, 6],
  [WeightModifier.WEIGHT_7_CHARS, 7],
  [WeightModifier.WEIGHT_8_CHARS, 8],
  [WeightModifier.WEIGHT_9_CHARS, 9],
  [WeightModifier.WEIGHT_NONE, 0],

  // Positive/Negative sign
  [SignModifier.SIGN_NONE, 0],
  [SignModifier.SIGN_SPACE_MINUS, 1],
  [SignModifier.SIGN_PLUS_MINUS, 1],
  [SignModifier.SIGN_ZERO_MINUS, 1],

  // Decimal point
  [DecimalPointModifier.DECIMAL_POINT_NONE, 0],
  [DecimalPointModifier.DECIMAL_POINT_FULL_STOP, 0],
  [DecimalPointModifier.DECIMAL_POINT_COMMA, 0],

  // Leading Zeros
  [LeadingZeroBlankingModifier.LEADING_ZERO_ZERO, 0],
  [LeadingZeroBlankingModifier.LEADING_ZERO_SPACE, 0],

  // Weight output on error condition (stability, overload, etc.)
  [ErrorStateOutputModifier.ERROR_STATE_SEND_WEIGHT, 0],
  [ErrorStateOutputModifier.ERROR_STATE_SEND_BLANK, 0],
  [ErrorStateOutputModifier.ERROR_STATE_SEND_DASHES, 0],

  // Status output casing (upper case, lower case)
  [StatusCharCasingModifier.STATUS_CHARS_UPPERCASE, 0],
  [StatusCharCasingModifier.STATUS_CHARS_LOWERCASE, 0],
]);

export const StaticTokenLengths = new Map([
  // Measurement units
  [StatusDataOutput.UNITS, 3], // this should be two and a space, but that is a breaking change :(
  [StatusDataOutput.UNITS_SPACE_ON_MOTION, 3], // this should be two and a space, but that is a breaking change :(

  // Combined status output
  [StatusDataOutput.COMBINED_STATUS_STANDARD, 1],
  [StatusDataOutput.COMBINED_STATUS_NO_MOTION, 1],
  [StatusDataOutput.COMBINED_STATUS_GROSS_NET_ONLY, 1],

  // Motion status
  [StatusDataOutput.MOTION_M_SPACE, 1],
  [StatusDataOutput.MOTION_M_S, 1],

  // Capacity status
  [StatusDataOutput.CAPACITY_CONDITION_M_C_SPACE, 1],
  [StatusDataOutput.CAPACITY_CONDITION_M_I_O_SPACE, 1],

  // Loading/Capacity status
  [StatusDataOutput.LOAD_CONDITION_I_O_U, 1],

  // Taring/Zeroing status
  [StatusDataOutput.ZERO_Z_SPACE, 1],

  // Range status
  [StatusDataOutput.RANGE_SINGLE_1_2, 1],

  // Combined stabiliy and loading status
  [StatusDataOutput.STABILITY_ST_US_OVERLOAD_OL, 2],

  // Date output
  [StatusDataOutput.TIME, 10],
  [StatusDataOutput.DATE, 10],

  // Termination character/special characters
  [CustomTerminationChar.NULL_CHAR, 1],
  [CustomTerminationChar.DO_NOT_SEND, 0],
]);

export function isModifierToken(formatToken) {
  return (
    (formatToken >= WeightModifier.WEIGHT_5_CHARS &&
      formatToken <= WeightModifier.WEIGHT_9_CHARS) ||
    (formatToken >= WeightModifier.WEIGHT_NONE &&
      formatToken <= StatusCharCasingModifier.STATUS_CHARS_LOWERCASE)
  );
}

export function isControlToken(formatToken) {
  return (
    formatToken === CustomTerminationChar.NULL_CHAR ||
    formatToken === CustomTerminationChar.DO_NOT_SEND
  );
}

export function isWeightDataToken(formatToken) {
  return (
    formatToken >= WeightDataType.SELECTED &&
    formatToken <= WeightDataType.TOTAL
  );
}
export function isStatusDataToken(formatToken) {
  return (
    (formatToken >= StatusDataOutput.UNITS &&
      formatToken <= StatusDataOutput.STABILITY_ST_US_OVERLOAD_OL) ||
    formatToken === StatusDataOutput.TIME ||
    formatToken === StatusDataOutput.DATE
  );
}

export function isDataToken(formatToken) {
  return isWeightDataToken(formatToken) || isStatusDataToken(formatToken);
}

export function isLiteralToken(formatToken) {
  return formatToken >= 0x01 && formatToken <= 0x7f;
}

export function isStaticLengthToken(formatToken) {
  return (
    isLiteralToken(formatToken) ||
    isControlToken(formatToken) ||
    isStatusDataToken(formatToken)
  );
}

export function getStaticTokenLength(formatToken) {
  if (isLiteralToken(formatToken)) {
    return 1;
  } else if (isControlToken(formatToken) || isStatusDataToken(formatToken)) {
    return StaticTokenLengths.get(formatToken);
  } else {
    throw Error(`Invalid token supplied "${formatToken}"`);
  }
}

export function getWeightDataLength(modifiers) {
  const { weightMode, signMode } = modifiers;
  return weightMode === WeightModifier.WEIGHT_NONE
    ? 0
    : WeightDataLengths.get(weightMode) + WeightDataLengths.get(signMode);
}

// -----------------------------------------------
// Inbuilt Weight Format default strings
// -----------------------------------------------
export const FormatADefaults = [
  WeightModifier.WEIGHT_7_CHARS,
  WeightDataType.SELECTED,
  StatusDataOutput.COMBINED_STATUS_STANDARD,
];

export const FormatBDefaults = [
  StatusDataOutput.COMBINED_STATUS_STANDARD,
  WeightModifier.WEIGHT_7_CHARS,
  WeightDataType.SELECTED,
  StatusDataOutput.UNITS_SPACE_ON_MOTION,
];

export const FormatCDefaults = [
  WeightModifier.WEIGHT_7_CHARS,
  WeightDataType.SELECTED,
  StatusDataOutput.COMBINED_STATUS_NO_MOTION,
  StatusDataOutput.MOTION_M_SPACE,
  StatusDataOutput.ZERO_Z_SPACE,
  StatusDataOutput.RANGE_SINGLE_1_2,
  StatusDataOutput.UNITS,
];

export const FormatDDefaults = [
  SignModifier.SIGN_SPACE_MINUS,
  WeightModifier.WEIGHT_7_CHARS,
  WeightDataType.SELECTED,
];

export const FormatEDefaults = [
  WeightModifier.WEIGHT_7_CHARS,
  LeadingZeroBlankingModifier.LEADING_ZERO_ZERO,
  WeightDataType.SELECTED,
  StatusDataOutput.CAPACITY_CONDITION_M_C_SPACE,
  StatusDataOutput.UNITS_SPACE_ON_MOTION,
  0x20,
  StatusDataOutput.COMBINED_STATUS_GROSS_NET_ONLY,
  0x20,
  0x20,
];
