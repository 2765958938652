import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

/**
 * @typedef {Object} NlisPropertyProgram
 * @property {string} code An NLIS Program code
 * @property {string} status A PIC's status for the NLIS Program identified by `code`
 */

/**
 * @typedef {Object} Property
 * @property {string} activeStatus A read only property containing the `Active Status` for this PIC as provided by the NLIS
 * @property {string} id an offline uuid
 * @property {string|null} district
 * @property {string|null} lpaStatus A read only property with the LPA Program status's value
 * @property {string|null} name The system-wide (default) name for this property
 * @property {NlisPropertyProgram[]} nlisPrograms A read only property containing the accreditations and warnings attached to this PIC as provided by the NLIS
 * @property {string} PIC the unique Property Identification Code issued by the NLIS
 */

const serializeMap = {
  PIC: "PIC",
  accreditationEu: "accreditation_eu",
  accreditationJbas: "accreditation_jbas",
  accreditationNee: "accreditation_nee",
  accreditationPcas: "accreditation_pcas",
  activeStatus: "active_status",
  district: "district",
  id: "id",
  lpaNumber: "lpa_number",
  lpaStatus: "lpa_status",
  msaNumber: "msa_number",
  name: "name",
  nlisPrograms: "nlis_programs",
  postCode: "post_code",
  shireCode: "shire_code",
  state: "state",
  town: "town",
};

export const serializeProperty = buildSerializer(serializeMap, "property");

/**
 * @type {function(Object, Object=): Property}
 */
export const deserializeProperty = buildDeserializer(
  serializeMap,
  "property",
  null,
  {
    accreditationEu: null,
    accreditationJbas: null,
    accreditationNee: null,
    accreditationPcas: null,
    activeStatus: "",
    district: "",
    lpaNumber: "",
    lpaStatus: null,
    msaNumber: "",
    name: null,
    nlisPrograms: [],
  },
);
