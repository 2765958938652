import { Species } from "constants/species";

export const calculateBuyerGridProps = species => {
  // Centralise the grids for the buyer and buyer way cards so the datapoints are aligned for easy viewing.
  const dataPoints = species === Species.CATTLE ? 4 : 3;
  const gridItemProps = {
    item: true,
    xs: 12 / dataPoints,
    md: 2,
    lg: 1,
  };

  const gridBuyerTextProps = {
    item: true,
    xs: 12,
    md: 12 - gridItemProps.md * dataPoints,
    lg: 12 - gridItemProps.lg * dataPoints,
  };

  return [gridItemProps, gridBuyerTextProps];
};
