import React from "react";

import { faPhotoVideo, faVideo } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge as MuiBadge } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import DiffBadge from "components/DiffBadge/DiffBadge";
import { Column, Row } from "components/Layout";

import { ExportSites } from "constants/exportSites";
import { SaleLotModalSection } from "constants/navigation";

import { formatPercentage } from "lib";

import { openEditSaleLotModal } from "lib/navigation";
import { formatSaleLotOverflow, getProgenyDisplayCount } from "lib/saleLot";
import { getScanStatusColour } from "lib/scans";

import {
  getAuctionPenById,
  getExportSitesByAuctionPenId,
  getHasVideoByAuctionPenId,
  getImageCountByAuctionPenId,
  getImageStatusByAuctionPenId,
  getOverflowPenByAuctionPenId,
  getOverflowQuantityByAuctionPenId,
  getQuantityByAuctionPenId,
  getQuantityDeliveredByAuctionPenId,
  getQuantityProgenyByAuctionPenId,
  getSaleLotIdsByAuctionPenId,
  getScannedPercentageByAuctionPenId,
  getScannedStatusByAuctionPenId,
  getUnpennedQuantityByAuctionPenId,
  getVideoCountByAuctionPenId,
} from "selectors";

import { primaryInfoStyle } from "./elements";

export const HeadCount = styled(Column)`
  width: 30%;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
`;
export const ProgenyCount = styled.span`
  margin: ${({ theme }) => theme.space[1]}px;
  white-space: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin-left: ${({ theme }) => theme.space[0]}px;
  }
`;
export const PrimaryInfoWrapper = styled(Row)`
  width: 70%;
  justify-content: space-around;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    flex-direction: column;
  }
`;
const PrimaryInfoHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  line-height: ${({ theme }) => theme.fontSizes.gamma}px;
  padding: 0 ${({ theme }) => theme.space[1]}px;
  color: ${({ theme }) => theme.colors.titleLight};
  font-weight: bold;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    min-width: 30px;
  }
`;
export const PrimaryInfoHeaderLarge = styled(PrimaryInfoHeader)`
  white-space: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    display: none;
  }
`;
export const PrimaryInfoHeaderMobile = styled(PrimaryInfoHeader)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    display: block;
  }
`;
export const PrimaryInfo = styled.div`
  ${primaryInfoStyle}
  margin-top:${({ theme }) => theme.space[1]}px;
`;
export const PrimaryInfoPercent = styled(PrimaryInfo)(
  ({ theme, color }) => `
  font-weight: normal;
  margin-top: 5px;
  white-space: nowrap;
  font-size: ${theme.fontSizes.beta}px;
  color: ${theme.colors[color]};
`,
);
export const ImageIcon = styled(FontAwesomeIcon).attrs({ icon: faImage })`
  font-size: 20px;
  width: auto;
`;
export const VideoIcon = styled(FontAwesomeIcon).attrs({ icon: faVideo })`
  font-size: 20px;
  width: auto;
`;
export const NoMediaIcon = styled(FontAwesomeIcon).attrs({
  icon: faPhotoVideo,
})`
  font-size: 20px;
  width: auto;
`;

export const ColoredBadge = styled(MuiBadge)`
  & .MuiBadge-badge {
    background: ${({ theme, status }) => theme.colors[status]};
  }
`;

function PrimaryInfoSet({ auctionPenId }) {
  const scannedStatus = useSelector(
    getScannedStatusByAuctionPenId(auctionPenId),
  );

  const auctionPen = useSelector(getAuctionPenById(auctionPenId)) || {};

  const imageCount = useSelector(getImageCountByAuctionPenId(auctionPenId));
  const imageStatus = useSelector(getImageStatusByAuctionPenId(auctionPenId));

  const scannedPercentage = useSelector(
    getScannedPercentageByAuctionPenId(auctionPenId),
  );
  const quantityDelivered = useSelector(
    getQuantityDeliveredByAuctionPenId(auctionPenId),
  );
  const quantityProgeny = useSelector(
    getQuantityProgenyByAuctionPenId(auctionPenId),
  );
  const unpennedQuantity = useSelector(
    getUnpennedQuantityByAuctionPenId(auctionPenId),
  );
  const quantity = useSelector(getQuantityByAuctionPenId(auctionPenId));
  const hasVideo = useSelector(getHasVideoByAuctionPenId(auctionPenId));
  const videoCount = useSelector(getVideoCountByAuctionPenId(auctionPenId));
  const overflowPen = useSelector(getOverflowPenByAuctionPenId(auctionPenId));
  const overflowQuantity = useSelector(
    getOverflowQuantityByAuctionPenId(auctionPenId),
  );
  const saleLotIds = useSelector(getSaleLotIdsByAuctionPenId(auctionPenId));

  const firstSaleLotId =
    Array.isArray(saleLotIds) && saleLotIds.length === 1 ? saleLotIds[0] : null;

  const onClickAddMedia = e => {
    if (!firstSaleLotId) {
      return null;
    }
    openEditSaleLotModal(firstSaleLotId, SaleLotModalSection.MEDIA);
    e.stopPropagation();
  };

  const overflowDisplayValue = formatSaleLotOverflow(
    overflowPen,
    overflowQuantity,
  );

  const auctionPenExportSites = useSelector(
    getExportSitesByAuctionPenId(auctionPenId),
  );

  const { start_pen: startPen, end_pen: endPen } = auctionPen;

  let quantityDisplayValue = quantity || "?";

  if (auctionPenId === null) {
    quantityDisplayValue = unpennedQuantity;
  }

  return (
    <PrimaryInfoWrapper>
      <HeadCount justifyCenter alignLeft>
        <PrimaryInfoHeaderLarge>PEN</PrimaryInfoHeaderLarge>
        <PrimaryInfoHeaderMobile>P</PrimaryInfoHeaderMobile>
        <PrimaryInfo>
          {startPen || "-"}
          {endPen && ` - ${endPen}`} {overflowDisplayValue}
        </PrimaryInfo>
      </HeadCount>

      <HeadCount justifyCenter alignLeft>
        <PrimaryInfoHeaderLarge>HD / SCAN</PrimaryInfoHeaderLarge>
        <PrimaryInfoHeaderMobile>Hd</PrimaryInfoHeaderMobile>
        <Row justifyCenter>
          <PrimaryInfo>{quantityDisplayValue} </PrimaryInfo>
          {quantityProgeny > 0 && (
            <ProgenyCount>
              {getProgenyDisplayCount(quantityProgeny)}
            </ProgenyCount>
          )}
          {quantityDelivered > 0 && (
            <DiffBadge
              quantityDelivered={quantityDelivered}
              quantity={quantity}
              quantityProgeny={quantityProgeny}
            />
          )}
          <PrimaryInfoPercent color={getScanStatusColour(scannedStatus)}>
            / {formatPercentage(scannedPercentage)}
          </PrimaryInfoPercent>
        </Row>
      </HeadCount>

      <div>
        {auctionPenExportSites.includes(ExportSites.AUCTIONS_PLUS) &&
          !hasVideo &&
          !imageCount && (
            <ColoredBadge badgeContent="!" status="warning" className="m-20">
              <NoMediaIcon
                onClick={onClickAddMedia}
                className="cursor-pointer"
              />
            </ColoredBadge>
          )}
        {hasVideo && (
          <ColoredBadge
            badgeContent={videoCount}
            status="success"
            className="m-20"
          >
            <VideoIcon />
          </ColoredBadge>
        )}
        {imageCount > 0 ? (
          <ColoredBadge badgeContent={`${imageCount}`} status={imageStatus}>
            <ImageIcon onClick={onClickAddMedia} className="cursor-pointer" />
          </ColoredBadge>
        ) : null}
      </div>
    </PrimaryInfoWrapper>
  );
}

export default React.memo(PrimaryInfoSet);
