import React from "react";

import { faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import styled from "styled-components/macro";

import { ChangesSummary } from "components/AuditLog/ChangesSummary";

import { AGRINOUS_ADMIN } from "constants/users";

import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

import { useToggle } from "hooks";

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(4n - 1)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:nth-of-type(4n)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const FaIconComponent = styled(FontAwesomeIcon)`
  ${({ theme, yflip, color }) => `
  transition: ${theme.transitions[0]};
  transform: scale(1, ${yflip ? -1 : 1});
  font-size: ${theme.fontSizes.gamma}px;
  margin: 0 ${theme.space[2]}px;
  color: ${color && theme.colors[color] ? theme.colors[color] : "#ccc"};
`}
`;
const FaIcon = React.memo(({ yflip, color, icon }) => (
  <FaIconComponent yflip={yflip} color={color} icon={icon} />
));
export const AuditRow = ({ action, previousChanges, dataType }) => {
  const [showChanges, toggleShowChanges] = useToggle(false);

  return (
    <>
      <StyledTableRow onClick={toggleShowChanges}>
        <TableCell>{action.history_type}</TableCell>
        <TableCell>
          {formatUTCToLocalDateTimeString(action.history_date)}
        </TableCell>
        <TableCell>{action.user || AGRINOUS_ADMIN}</TableCell>
        <TableCell>{action.history_change_reason}</TableCell>
        <TableCell>
          <FaIcon
            icon={faCaretUp}
            yflip={showChanges ? undefined : "show"}
            color="primary"
          />
        </TableCell>
      </StyledTableRow>
      <StyledTableRow>
        {showChanges ? (
          <TableCell colSpan={5}>
            <ChangesSummary
              data={action.changes}
              previousData={previousChanges}
              dataType={dataType}
            />
          </TableCell>
        ) : null}
      </StyledTableRow>
    </>
  );
};
