import { EventTarget } from "event-target-shim";

export type OnConnectionEstablishedCallback = () => void;
export type OnConnectionFailedCallback = (reason: string) => void;

export type HubRequest = string | ArrayBufferLike | Blob | ArrayBufferView;

/*
 * HubConnection connects the web app data state to an external native hub component.
 *
 * The mechanism for inter-process communication is implementation-specific.
 *
 * To store connection status and data received in the web app state, implementations
 * should dispatch the following event types:
 *
 * - connecting
 * - connected
 * - connectionFailed
 * - version
 * - data
 * - closed
 *
 *  These events are re-emitted as redux actions and processed by redux-saga (see sagas/hub).
 *
 */
export abstract class HubConnection extends EventTarget {
  protected _connectedVersion: number;

  protected _supportedFeatures: string[];

  protected _minVersion: string;

  constructor(minVersion: string) {
    super();
    this._minVersion = minVersion;
    this._connectedVersion = null;
    this._supportedFeatures = null;
  }

  public get minVersion() {
    return this._minVersion;
  }

  public get connectedVersion() {
    return this._connectedVersion;
  }

  public set connectedVersion(version: unknown) {
    const versionNumber = Number(version);
    this._connectedVersion = Number.isNaN(versionNumber) ? null : versionNumber;
  }

  public set supportedFeatures(features: string[]) {
    this._supportedFeatures = features;
  }

  /*
   * Send a message to the hub to be delivered to external scanning or printing hardware.
   */
  public abstract submitRequest(
    request: HubRequest,
    onSuccess: () => void,
    onError: (message: string) => void,
  ): void;

  public abstract ensureConnectionEstablished(
    onConnectionEstablished: OnConnectionEstablishedCallback,
    onConnectionFailed: OnConnectionFailedCallback,
  ): void;

  public abstract doesHubSupportPing(): boolean;

  public abstract disconnect(): void;
}
