import React, { memo, useCallback } from "react";

import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import { NominationAction } from "actions";

import { ClickableCommentIcon } from "components/Comments/Icon";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  ZoomyDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { CommentTypes } from "constants/comments";
import { ApiModel } from "constants/loading";

import {
  closeAllHashModals,
  getLivestockSaleId,
  returnToLocation,
} from "lib/navigation";

import {
  getConsigningDeploymentIdByLivestockSaleId,
  getCurrentOrFirstSale,
  getNominationById,
  getSellingDeploymentIdByLivestockSaleId,
  selectActiveNominationTermIds,
} from "selectors";

import { EditNominationForm } from "./EditNominationForm";
import { Footer } from "./Footer";
import { ValidationSchema } from "./validationSchema";

function EditNominationModalModalTitleComponent(props) {
  const { nominationId, onClose } = props;

  return (
    <DialogTitle onClose={onClose}>
      <Row justifyBetween>
        {nominationId ? "Edit" : "Create"} Nomination
        {nominationId && (
          <ClickableCommentIcon
            commentType={CommentTypes.NOMINATION}
            commentTypeId={nominationId}
            returnTo={window.location.hash}
          />
        )}
      </Row>
    </DialogTitle>
  );
}

const EditNominationModalModalTitle = memo(
  EditNominationModalModalTitleComponent,
);
EditNominationModalModalTitle.propTypes = {
  nominationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

/**
 * When nominationId is a string, the form will show in edit mode and a delete button will be shown in the actions buttons at the bottom of the modal.
 * When nominationId is `null`, the form will show in create mode.
 *
 * @param {Object} props
 * @param {Object} [props.options]
 * @param {Nomination|null} [props.options.presets]
 * @param {string|null} [props.nominationId]
 * @param {string} [props.returnTo]
 * @returns {*}
 * @constructor
 */

const DialogContentContainer = styled(DialogContent)`
  ${({ theme }) => `
    background-color: ${theme.colors.collapseBackground};
  `}
`;

export function EditNominationModal(props) {
  const { options, nominationId, returnTo } = props;
  const presets = options?.presets || {};
  const readOnly = options?.readOnly || false;

  const { livestockSaleId = getLivestockSaleId() } = presets;

  const nomination = useSelector(getNominationById(nominationId)) || null;
  const nominationTermIds = useSelector(selectActiveNominationTermIds);

  const deploymentId =
    useSelector(getSellingDeploymentIdByLivestockSaleId(livestockSaleId)) ||
    null;
  const consigningDeploymentId =
    useSelector(getConsigningDeploymentIdByLivestockSaleId(livestockSaleId)) ||
    null;

  const dispatch = useDispatch();

  const isEdit = nominationId !== null;

  const onClose = useCallback(() => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }, [returnTo]);

  const onDelete = useCallback(() => {
    dispatch(NominationAction.delete(nominationId));
    onClose();
  }, [dispatch, onClose, nominationId]);

  const onSubmitForm = useCallback(
    formValues => {
      const {
        consigningDeploymentId,
        deploymentId,
        hasArrived,
        id,
        livestockSaleId,
        nominationValues,
        propertyId,
        transporterId,
        vendorId,
      } = formValues;

      const payload = {
        consigningDeploymentId,
        deploymentId,
        hasArrived,
        id,
        livestockSaleId,
        // convert back from a quantity and notes object, keyed by NominationTerm id
        // to the database shape of a list of NominationDetail items
        nominationDetails: Object.entries(nominationValues)
          .filter(([_ignored, nominationValues]) => nominationValues.quantity)
          .map(
            ([
              nominationTermId,
              { quantity, estimatedWeightGrams, notes, breedId, sexId, ageId },
            ]) => ({
              nominationTermId,
              quantity,
              estimatedWeightGrams,
              notes,
              breedId,
              sexId,
              ageId,
            }),
          ),
        transporterId,
        vendorId,
        propertyId,
      };

      if (isEdit) {
        dispatch(NominationAction.update(payload));

        onClose();
      } else {
        dispatch(
          NominationAction.create(payload, {
            redirectToSale: livestockSaleId !== getLivestockSaleId(),
          }),
        );
        closeAllHashModals();
      }
    },
    [dispatch, isEdit, onClose],
  );

  const { saleyard_id: saleyardId } = useSelector(getCurrentOrFirstSale) || {};
  const initialValues = {
    deploymentId,
    consigningDeploymentId,
    id: uuidv4(),
    hasArrived: false,
    livestockSaleId,
    saleyardId,
    // convert from a list of NominationDetail items to a quantity and notes object,
    // keyed by NominationTerm id for ease of display/implementation of the Formik form controls
    nominationValues: nominationTermIds.reduce((acc, nominationTermId) => {
      acc[nominationTermId] = {
        quantity: null,
        estimatedWeightGrams: null,
        notes: "",
        breedId: null,
        sexId: null,
        ageId: null,
      };
      return acc;
    }, {}),
    propertyId: null,
    vendorId: null,
    transporterId: null,
    ...presets,
  };
  if (nomination) {
    initialValues.consigningDeploymentId = nomination.consigningDeploymentId;
    initialValues.deploymentId = nomination.deploymentId;
    initialValues.hasArrived = nomination.hasArrived;
    initialValues.id = nomination.id;
    initialValues.livestockSaleId = nomination.livestockSaleId;
    initialValues.nominationValues = nomination.nominationDetails.reduce(
      (acc, nominationDetail) => {
        acc[nominationDetail.nominationTermId] = {
          quantity: nominationDetail.quantity,
          estimatedWeightGrams: nominationDetail.estimatedWeightGrams,
          notes: nominationDetail.notes,
          breedId: nominationDetail.breedId,
          sexId: nominationDetail.sexId,
          ageId: nominationDetail.ageId,
        };
        return acc;
      },
      {},
    );
    initialValues.transporterId = nomination.transporterId;
    initialValues.vendorId = nomination.vendorId;
    initialValues.propertyId = nomination.propertyId;
  }

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="sm" fullWidth>
      <WaitForSync
        requiredData={[
          ApiModel.BUSINESSES,
          ApiModel.DEPLOYMENTS,
          ApiModel.NOMINATIONS,
          ApiModel.SPECIES,
        ]}
      >
        <EditNominationModalModalTitle
          nominationId={nominationId}
          onClose={onClose}
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validationSchema={ValidationSchema}
        >
          <Form>
            <DialogContentContainer dividers>
              <Grid spacing={1} container>
                <EditNominationForm
                  nominationId={nominationId}
                  readOnly={readOnly}
                />
              </Grid>
            </DialogContentContainer>
            <DialogActions>
              <Footer
                nominationId={nominationId}
                onDelete={onDelete}
                onClose={onClose}
              />
            </DialogActions>
          </Form>
        </Formik>
      </WaitForSync>
    </ZoomyDialog>
  );
}
EditNominationModal.propTypes = {
  nominationId: PropTypes.string,
  options: PropTypes.shape({
    presets: PropTypes.shape({
      livestockSaleId: PropTypes.string,
      vendorId: PropTypes.string.isRequired,
    }),
  }),
  returnTo: PropTypes.string,
};
