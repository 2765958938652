import React from "react";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { PaddedIcon, Row } from "components/Layout";
import TabSelector from "components/TabSelector";

const ClickableIconWrapper = styled(PaddedIcon)(
  ({ theme }) => `font-size: ${theme.fontSizes.epsilon}px;`,
);

export const BuyerTypes = {
  RECENT: "RECENT",
  REGISTERED: "REGISTERED",
};

function BuyerTypeTabs(props) {
  const { selected, onSetSelected, onOpenSearch, disabled } = props;

  function onClickRecent() {
    onSetSelected(BuyerTypes.RECENT);
  }
  function onClickRegistered() {
    onSetSelected(BuyerTypes.REGISTERED);
  }

  function onClickSearch() {
    onOpenSearch && onOpenSearch();
  }

  return (
    <Row justifyBetween alignCenter style={{ padding: 12 }}>
      <Row data-tour="recent">
        <TabSelector
          selected={selected === BuyerTypes.RECENT}
          onClick={onClickRecent}
        >
          Recent
        </TabSelector>
        <TabSelector
          selected={selected === BuyerTypes.REGISTERED}
          onClick={onClickRegistered}
        >
          Registered
        </TabSelector>
      </Row>
      <Row style={{ fontSize: 24, margin: -12 }}>
        {!disabled && selected === BuyerTypes.RECENT && (
          <ClickableIconWrapper data-tour="searchBuyer" onClick={onClickSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </ClickableIconWrapper>
        )}
      </Row>
    </Row>
  );
}

export default BuyerTypeTabs;
