import React from "react";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  faCow,
  faSheep,
  faContainerStorage,
  faUserCrown,
  faHandshakeAlt,
  faQuestion,
  faSteak,
  faTractor,
  faTruckMoving,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ROLE_TYPES } from "constants/users";

const DEFAULT_ICON = faQuestion;

const ROLE_ICON_MAP = {
  [ROLE_TYPES.BUSINESS_USER]: faSheep,
  [ROLE_TYPES.PROCESSOR]: faSteak,
  [ROLE_TYPES.PRODUCER]: faTractor,
  [ROLE_TYPES.SALE_WATCHER]: faEye,
  [ROLE_TYPES.SALEYARD_OPERATOR]: faContainerStorage,
  [ROLE_TYPES.SCALE_OPERATOR]: faCow,
  [ROLE_TYPES.STOCK_AGENT]: faHandshakeAlt,
  [ROLE_TYPES.TRANSPORTER]: faTruckMoving,
  [ROLE_TYPES.STAFF]: faUserCrown,
};

function getRoleIconOrDefault(role) {
  return ROLE_ICON_MAP[role] || DEFAULT_ICON;
}

/**
 * @typedef RoleIconProps
 * @extends Object
 * @property {String} role
 */

/**
 * return an icon that represents the given role
 * @param {RoleIconProps} props
 * @returns {*}
 */
export const RoleIcon = React.memo(({ role, size }) => {
  const icon = getRoleIconOrDefault(role);
  return <FontAwesomeIcon icon={icon} size={size} />;
});
