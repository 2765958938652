import LogRocket from "logrocket";

import { isLogRocketActive } from "./config";

export const identifyUserToLogRocket = currentUser => {
  if (!isLogRocketActive) {
    return;
  }
  const { user_id, first_name, last_name, email } = currentUser;

  LogRocket.identify(user_id, {
    user_id,
    email,
    first_name,
    last_name,
  });
};
