import React from "react";

import { useBoolean } from "hooks";

export const HeaderBulkSelectCellRenderer = props => {
  const { api, column, value, columnApi } = props;
  const { colId } = column;

  const [hasChanged, setHasChanged] = useBoolean(false);

  const columnValues = [];

  api.forEachNode(node => columnValues.push(node.data[colId]));

  const allAreTrue = columnValues.every(value => !!value);

  const checkedHandler = event => {
    const { checked } = event.target;
    setHasChanged();

    api.forEachNode(node => {
      if (node.data && (node.data.name || node.data.short_code)) {
        node.setDataValue(colId, checked);
        node.setData({
          ...node.data,
          touched: true,
        });
      }
    });
  };

  return (
    <span>
      <input
        type="checkbox"
        onChange={checkedHandler}
        checked={allAreTrue && !hasChanged ? allAreTrue : value}
      />{" "}
      {columnApi.getDisplayNameForColumn(column)}
    </span>
  );
};
