import queryString from "query-string";

import { WEIGH_LOT_SCAN } from "constants/actionTypes";

import { serializeWeighLotScan } from "lib/serializers/weighLotScans";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, livestockSaleId, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/weigh-lot-scans/", id, action),
    query: {
      weigh_lot__livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const WeighLotScanAction = offlineActionCreator(
  WEIGH_LOT_SCAN,
  serializeWeighLotScan,
  urlCreator,
  true,
);

WeighLotScanAction.keep = weighLotScanId => {
  return {
    type: WEIGH_LOT_SCAN.KEEP.ACTION,
    weighLotScanId,
  };
};

WeighLotScanAction.ignore = weighLotScanId => {
  return {
    type: WEIGH_LOT_SCAN.IGNORE.ACTION,
    weighLotScanId,
  };
};

WeighLotScanAction.addScanToSale = weighLotScanId => {
  return {
    type: WEIGH_LOT_SCAN.ADD_SCAN_TO_SALE.ACTION,
    weighLotScanId,
  };
};

WeighLotScanAction.manaullyMapToScan = (weighLotScanId, scan) => {
  return {
    type: WEIGH_LOT_SCAN.MANUALLY_MAP_TO_SCAN.ACTION,
    weighLotScanId,
    scan,
  };
};

WeighLotScanAction.addWeightToSaleLot = (weighLotScanId, saleLotId) => {
  return {
    type: WEIGH_LOT_SCAN.ADD_WEIGH_TO_SALE_LOT.ACTION,
    weighLotScanId,
    saleLotId,
  };
};
