import { useEffect } from "react";

import { useField } from "formik";
import { useSelector } from "react-redux";

/* If we create a piece of related data in a form (eg a destination property on buyer on a salelot form)

we need to update the form value with the real id once we know what it is.
*/

export const useUpdateFormWithOfflineLookup = fieldName => {
  const offlineTemp = useSelector(state => state.offlineTemp);
  const [field, _ignored, helpers] = useField(fieldName);
  const { value } = field;
  const { setValue } = helpers;

  useEffect(() => {
    if (offlineTemp[value]) {
      setValue(offlineTemp[value]);
    }
  }, [value, setValue, offlineTemp]);
};
