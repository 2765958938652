import React from "react";

import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { BusinessAction } from "actions";

import { Button, SecondaryButton } from "components/Form";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import {
  AlternativeComparableKeys,
  AlternativeType,
} from "constants/businesses";
import { SystemSaleyards } from "constants/sale";

import { getBusinessById, getDeployments, getSaleyards } from "selectors";

import { SelectSyncData } from "./SelectSyncData";

export const SelectSyncDataModal = ({ businessId, returnTo }) => {
  const [openSections, setOpenSections] = React.useState(new Set());
  const [source, setSource] = React.useState(null);
  const business = useSelector(getBusinessById(businessId));
  const dispatch = useDispatch();

  const history = useHistory();

  const hasDifferences = (val, sourceId) => {
    // this aims to check if there is a diff between alternative field
    // and the field on the business/property/recipient regardless of whether it
    // recieve a list or a general object of alternative data

    if (Array.isArray(val)) {
      const hasDifference = val.some(property => {
        const alternative = property.alternatives?.find(
          alternative => alternative.source.id === sourceId,
        );
        if (alternative) {
          Object.keys(alternative).forEach(key => {
            if (
              AlternativeComparableKeys.includes(key) &&
              !isEqual(property[key], alternative[key])
            ) {
              return true;
            }
          });
        }
        return false;
      });
      return hasDifference;
    } else {
      const alternative = val.alternatives?.find(
        alternative => alternative.source.id === sourceId,
      );
      const hasDifference =
        alternative &&
        Object.keys(alternative).some(key => {
          if (
            AlternativeComparableKeys.includes(key) &&
            !isEqual(val[key], alternative[key])
          ) {
            return true;
          }
          return false;
        });
      return hasDifference;
    }
  };

  const handleClose = () => {
    history.push(returnTo);
  };

  function onToggle(section) {
    return function onClickSection(e) {
      if (openSections.has(section)) {
        const nextOpenSections = new Set(openSections);
        nextOpenSections.delete(section);
        setOpenSections(nextOpenSections);
      } else {
        const nextOpenSections = new Set(openSections).add(section);
        setOpenSections(nextOpenSections);
      }
      // Clicking on the `+ Show more` button bubbles up to the header, causing this function to be called twice
      e.stopPropagation();
    };
  }

  const getAlternatives = (alternativesList, sourceId) => {
    return (
      alternativesList &&
      alternativesList.find(
        alternatives => String(alternatives.source.id) === String(sourceId),
      )
    );
  };

  const handleSubmit = source => {
    const generalAlternative = getAlternatives(business.alternatives, source);

    const propertiesAlternative = business.properties
      .map(property => getAlternatives(property.alternatives, source))
      .filter(Boolean);

    const emailRecipientsAlternative = business.emailRecipients
      .map(emailRecipient =>
        getAlternatives(emailRecipient.alternatives, source),
      )
      .filter(Boolean);

    let patchBusiness = {};

    if (generalAlternative) {
      patchBusiness = {
        ...patchBusiness,
        ...generalAlternative,
      };
    }
    if (propertiesAlternative) {
      patchBusiness = {
        ...patchBusiness,
        properties: business.properties.reduce((acc, property) => {
          const newProperty = propertiesAlternative.find(
            altProp => altProp.id === property.id,
          );
          if (newProperty) {
            acc.push(newProperty);
          } else {
            acc.push(property);
          }
          return acc;
        }, []),
      };
    }
    if (emailRecipientsAlternative) {
      patchBusiness = {
        ...patchBusiness,
        emailRecipients: business.emailRecipients.reduce(
          (acc, emailRecipient) => {
            const newEmailRecipient = emailRecipientsAlternative.find(
              altRecipient => altRecipient.id === emailRecipient.id,
            );
            if (newEmailRecipient) {
              acc.push(newEmailRecipient);
            } else {
              acc.push(emailRecipient);
            }
            return acc;
          },
          [],
        ),
      };
    }

    dispatch(
      BusinessAction.update(
        { id: business.id, ...patchBusiness },
        {
          changeReason: "Updated via Sync All.",
        },
      ),
    );
    handleClose();
  };

  const generalAlternatives = business?.alternatives || [];
  const saleyardsLookup = useSelector(getSaleyards);
  const deploymentsLookup = useSelector(getDeployments);

  const generalAlternativesWithSourceNames = generalAlternatives
    .map(alternatives => {
      if (alternatives.source.type === AlternativeType.Deployment) {
        return {
          ...alternatives,
          sourceName: deploymentsLookup[alternatives.source.id]?.name,
        };
      } else {
        return {
          ...alternatives,
          sourceName: saleyardsLookup[alternatives.source.id]?.name,
        };
      }
    })
    .filter(alternatives => !SystemSaleyards.includes(alternatives.sourceName));

  return (
    <ZoomyDialog open onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={handleClose}>Updating {business.name}</DialogTitle>
      <DialogContent dividers>
        {generalAlternativesWithSourceNames.map((alternatives, idx) => {
          const hasGeneralAlternatives = hasDifferences(
            business,
            alternatives.source.id,
          );
          const hasPropertyAlternatives = hasDifferences(
            business.properties,
            alternatives.source.id,
          );
          const hasEmailRecipeintAlternatives = hasDifferences(
            business.emailRecipients,
            alternatives.source.id,
          );
          const noDifferences =
            !hasGeneralAlternatives &&
            !hasPropertyAlternatives &&
            !hasEmailRecipeintAlternatives;
          return (
            <SelectSyncData
              business={business}
              alternatives={alternatives}
              idx={idx}
              key={idx}
              openSections={openSections}
              onToggle={onToggle}
              noDifferences={noDifferences}
              source={source}
              setSource={setSource}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        <Button onClick={() => handleSubmit(source)} disabled={!source}>
          Submit
        </Button>
      </DialogActions>
    </ZoomyDialog>
  );
};
