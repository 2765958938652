import { isEmpty, sortBy } from "lodash";
import { createSelector } from "reselect";

import { getBusinesses, getSaleLots } from "selectors";

export const selectExternalAgentSaleOverviewDataByBusinessField =
  createSelector([getSaleLots, getBusinesses], (saleLots, businesses) => {
    const fields = ["vendor_id", "buyer_id"];

    const initial = fields.reduce((acc, cur) => {
      acc[cur] = {};
      return acc;
    }, {});

    const summaryByFieldByBusiness = Object.values(saleLots).reduce(
      (acc, lot) => {
        fields.forEach(businessField => {
          // If the lot doesn't have a buyer, check if it has an account note.
          const businessId = lot[businessField];
          const businessKey = businessId || lot.accountNote;

          const existing = acc[businessField][businessKey];

          if (existing) {
            acc[businessField][businessKey] = {
              ...existing,
              quantity: existing.quantity + lot.quantity,
              count: existing.count + 1,
              gross: existing.gross + lot.total_price_cents,
            };
          } else {
            const businessName = businesses[businessKey]?.name || "-";

            acc[businessField][businessKey] = {
              quantity: lot.quantity,
              count: 1,
              gross: lot.total_price_cents,
              businessName,
              isLoading: businessId ? isEmpty(businesses[businessKey]) : false,
              accountNote: lot.accountNote,
            };
          }
        });
        return acc;
      },
      initial,
    );

    // Sort the results
    return Object.entries(summaryByFieldByBusiness).reduce(
      (acc, [businessField, summaryByBusiness]) => {
        acc[businessField] = sortBy(
          Object.values(summaryByBusiness),
          "accountNote",
          "businessName",
        );
        return acc;
      },
      {},
    );
  });
