import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";

import { connectToDevice, connectToHub, updateAvailableDevices } from "actions";

import { Button } from "components/Form";
import { LabelledControl } from "components/Form/FormikControls";
import ReactSelect from "components/SearchableSelector/ReactSelect";

import {
  selectAvailablePlcOptions,
  selectAvailableScannerOptions,
  selectAvailableWeighbridgeOptions,
} from "selectors";

export function HubNotConnectedAction() {
  const dispatch = useDispatch();
  function handleConnectToHub() {
    dispatch(connectToHub());
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button fullWidth onClick={handleConnectToHub}>
          Connect
        </Button>
      </Grid>
    </Grid>
  );
}

export function DeviceNotConnectedAction({ optionsSelector, label }) {
  const dispatch = useDispatch();
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);

  function handleRefreshDevices() {
    dispatch(updateAvailableDevices());
  }

  function handleConnect() {
    dispatch(connectToDevice(selectedDeviceId));
  }

  const deviceOptions = useSelector(optionsSelector);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LabelledControl label={label}>
          <ReactSelect
            defaultValue={selectedDeviceId}
            options={deviceOptions}
            onChange={e => setSelectedDeviceId(e.value)}
            menuPortalTarget={document.body}
          />
        </LabelledControl>
      </Grid>
      <Grid item xs={6}>
        <Button fullWidth onClick={handleRefreshDevices}>
          Refresh {label} Options
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button fullWidth onClick={handleConnect} disabled={!selectedDeviceId}>
          Connect
        </Button>
      </Grid>
    </Grid>
  );
}

export function ScannerNotConnectedAction() {
  return (
    <DeviceNotConnectedAction
      optionsSelector={selectAvailableScannerOptions}
      label="Scanner"
    />
  );
}

export function WeighbridgeNotConnectedAction() {
  return (
    <DeviceNotConnectedAction
      optionsSelector={selectAvailableWeighbridgeOptions}
      label="Weigh Bridge"
    />
  );
}

export function PlcNotConnectedAction() {
  return (
    <DeviceNotConnectedAction
      optionsSelector={selectAvailablePlcOptions}
      label="PLC"
    />
  );
}
