import { createSelector } from "reselect";

import {
  getLivestockAgents,
  getSaleyardAdmins,
  getUserLevels,
  selectConsignableSaleyardNameLookup,
  selectNameByDeploymentIdLookup,
} from "selectors";

export const selectSaleyardAdminsAggridData = createSelector(
  [getSaleyardAdmins, getUserLevels],
  (saleyardAdmins, userLevels) =>
    Object.values(saleyardAdmins).map(syadmin => ({
      ...syadmin,
      userLevel: userLevels[syadmin.userLevelId]?.name || "Unknown",
    })),
);

export const selectLivestockAgentsAggridData = createSelector(
  [
    getLivestockAgents,
    selectNameByDeploymentIdLookup,
    selectConsignableSaleyardNameLookup,
    getUserLevels,
  ],
  (
    livestockAgents,
    deploymentNameByDeploymentIdLookup,
    consignableSaleyardNameLookup,
    userLevels,
  ) =>
    Object.values(livestockAgents).map(livestockAgent => ({
      ...livestockAgent,
      deploymentNames:
        livestockAgent?.deployments?.map(
          d => deploymentNameByDeploymentIdLookup[d.id],
        ) || [],
      receiveConsignedToEmailsFromNames:
        livestockAgent?.receiveConsignedToEmailsFrom.map(
          saleyardId => consignableSaleyardNameLookup[saleyardId] || "",
        ),
      userLevel: userLevels[livestockAgent.userLevelId]?.name || "Unknown",
    })),
);
