import React from "react";

import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { CollapseHeader, FormCollapse } from "components/Form";
import {
  Input,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";

import { DeploymentPermissions as DeploymentPermission } from "constants/permissions";
import { FinanceSubModalSection } from "constants/settings";

import { selectLedgerAccountOptionsForRuleBuilder } from "selectors";

import { useHasDeploymentPermission, useSectionHelper } from "hooks";

export const RetainedProceedsSettings = ({ sectionHelper, namespace: ns }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.RETAINED_PROCEEDS,
  );
  const LedgerAccountOptions = useSelector(
    selectLedgerAccountOptionsForRuleBuilder,
  );

  // Only show this if we have permissions turned on!
  const hasRetainedProcedurePermissions = useHasDeploymentPermission(
    DeploymentPermission.featureRetainedProceeds,
  );
  if (!hasRetainedProcedurePermissions) {
    return null;
  }

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<CollapseHeader title="Retained Proceeds" />}
    >
      <Grid item xs={12} md={6}>
        <Input
          label="Retained Proceeds Prefix"
          name={withNamespace(ns, "retainedProceedsPrefix")}
          tooltip="The prefix used for Credit Notes created as a result of a Retained Proceeds action (eg RP-1234)"
          placeholder="RP"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Next Retained Proceeds Number"
          name={withNamespace(ns, "nextRetainedProceedsNumber")}
          tooltip="The next Credit Note document created as a result of a Retained Proceeds action will use this number, combined with your current Retained Proceeds Prefix."
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label="Default Account For Retained Proceeds"
          name={withNamespace(ns, "defaultRetainedProceedsGlCode")}
          options={LedgerAccountOptions}
          tooltip="Select the default account that any retained proceeds should pass through.  This would normally be your Livestock Transactions account."
          menuPortalTarget={document.body}
        />
      </Grid>
    </FormCollapse>
  );
};
