import * as Sentry from "@sentry/react";

import { isIOSApp, isMobileSafariType } from "lib/devices";
import { getSentryDSN, isSentryActive } from "lib/sentry/config";

import history from "appHistory";

if (isSentryActive) {
  // Notes
  // - we're not using redux middleware - our state is too big for it being of any use.
  // - breadcrumbs is quite low too, for similar reasons.
  // - sampleRate is the fraction of events that should be sent - in the case of errors, 100
  // - tracesSampleRate is the fraction of TRACE events to send.  This should be LOWER than 1.0 - we only want
  // a fraction of them.
  // https://docs.sentry.io/platforms/javascript/#monitor-performance

  const integrations = [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
  ];

  // Temp added to test ios fetch errors.
  if (isIOSApp || isMobileSafariType) {
    integrations.push(Sentry.captureConsoleIntegration({ levels: ["error"] }));
  }

  Sentry.init({
    dsn: getSentryDSN(),
    release: `${process.env.REACT_APP_SENTRY_RELEASE}`,
    integrations,
    // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
    // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
    // Ignore IOS noise when a user navigates to a different sale or similar
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
    ],
    maxBreadcrumbs: 5,
    sampleRate: 1.0,
    tracesSampleRate: 0.01,
    enableAutoSessionTracking: true,
    beforeSend(event, hint) {
      const error = hint.originalException;
      // https://github.com/pusher/pusher-js/issues/744
      if (error.data?.code === 1006 || error.error?.data?.code === 1006) {
        // ignore, 1006 is an unstable connection error
        return null;
      }
      return event;
    },
  });
}
