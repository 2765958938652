import React from "react";

import { PropTypes } from "prop-types";

import { buildLineBreakingAuctionPenDisplayName } from "lib/auctionPens";
import { createAndOpenPdf, getSaleAndDocumentInformation } from "lib/pdf";

const pageMargin = 12;
const pageMarginTop = 12;
const pageMarginBottom = 40;

const tableHeaders = [
  { text: "PEN", style: "tableHeader" },
  { text: "HD", style: "tableHeader" },
  { text: "MARK", style: "tableHeader" },
  { text: "VENDOR", style: "tableHeader" },
  { text: "AGE", style: "tableHeader" },
  { text: "SEX", style: "tableHeader" },
  { text: "BREED", style: "tableHeader" },
];

export const CattleSpottingCardPortrait = ({
  auctionPens,
  sale,
  agencyName,
  round,
  history,
}) => {
  const rows = [];

  let cachedPenName = null;
  let penHasChanged = true;
  let color = null;

  Object.values(auctionPens).forEach(auctionPen => {
    auctionPen.sale_lots.forEach(saleLot => {
      const { overflowPen, overflowQuantity = null } = saleLot;
      const penNameAndOverflow = buildLineBreakingAuctionPenDisplayName(
        auctionPen,
        overflowPen,
        overflowQuantity,
        "",
      );

      if (cachedPenName && penNameAndOverflow !== cachedPenName) {
        if (!color) {
          color = "#CCCCCC";
        } else {
          color = null;
        }
        penHasChanged = true;
      }

      cachedPenName = penNameAndOverflow;

      rows.push({
        penName: (penHasChanged && penNameAndOverflow) || null,
        head: saleLot.quantity,
        marks: saleLot.marks
          .map(mark => mark.location)
          .filter(Boolean)
          .join(", "),
        vendorName: [saleLot.vendorNumber, saleLot.vendor.name]
          .filter(Boolean)
          .join(" - "),
        age: saleLot.age.name || "",
        sex: saleLot.sex.name || "",
        breed: saleLot.breed.name || "",
        color,
      });

      penHasChanged = false;
    });
  });

  const pdfDefinition = {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [pageMargin, pageMarginTop, pageMargin, pageMarginBottom],
    content: {
      table: {
        headerRows: 1,
        widths: [40, 40, 40, 140, 80, 80, 80],

        body: [
          tableHeaders,
          ...rows.map(row => [
            row.penName,
            row.head,
            row.marks,
            row.vendorName,
            row.age,
            row.sex,
            row.breed,
          ]),
        ],
      },
      layout: {
        fillColor(rowIndex) {
          return rows[rowIndex - 1]?.color;
        },
      },
    },
    footer: getSaleAndDocumentInformation(
      auctionPens,
      sale,
      agencyName,
      round,
      pageMargin,
    ),
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 14,
      },
      mainText: {
        bold: true,
        fontSize: 20,
      },
    },
  };

  createAndOpenPdf(pdfDefinition, history);

  return <div />;
};

CattleSpottingCardPortrait.propTypes = {
  auctionPens: PropTypes.array,
  sale: PropTypes.object,
  history: PropTypes.object,
  agencyName: PropTypes.string,
  round: PropTypes.object,
};
