import React from "react";

import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { isEmpty, isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { BusinessAction } from "actions";

import { ChangeTableBody } from "components/BusinessesInSale/ResolveAlternatives/ChangeTableBody";
import { IconButton, SlimButton } from "components/Button";
import { FormSectionGrid } from "components/Form";
import {
  AlternativeFieldConnectedSelect,
  AlternativeSourceConnectedSelect,
  RoleInSaleConnectedSelect,
} from "components/Form/Settings/Select";
import { SettingSwitch } from "components/Form/Settings/Switch";
import { Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Paper } from "components/Paper";
import { TitleText } from "components/Text";

import { ApiModel } from "constants/loading";
import { Settings } from "constants/settings";

import { openEditBusinessModal } from "lib/navigation";
import {
  formatUTCToLocalDateTimeString,
  getNowNoTimezone,
} from "lib/timeFormats";

import { selectFilteredAlternativesByBusinessId } from "selectors";

const Table = styled.table(
  ({ theme }) => `
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: 2px;
  }

  .highlight {
    color: ${theme.colors.red};
  }

  .br {
    border-right: 1px solid black;
  }
  .bs {
    border-bottom: 2px solid black;
  }
  
  .alt {
    background: ${theme.colors.gray85};
   }
  
  input {
    margin-right: 4px;
  }
`,
);

const updateReducer = (oldState, action) => {
  const { businessId, field, value } = action;

  const newValue = isEqual(oldState[businessId]?.[field], value)
    ? undefined
    : value;

  const updatedBusiness = {
    ...oldState[businessId],
    [field]: newValue,
  };

  const newState = {
    ...oldState,
    [businessId]: updatedBusiness,
  };

  // Remove any changes that have been undone.
  if (newState[businessId][field] === undefined) {
    delete newState[businessId][field];
  }
  if (isEmpty(newState[businessId])) {
    delete newState[businessId];
  }
  return newState;
};

export const ResolveAlternativesComponent = () => {
  const [updates, dispatchUpdates] = React.useReducer(updateReducer, {});

  const filteredAlternativesByBusinessId = useSelector(
    selectFilteredAlternativesByBusinessId,
  );

  const dispatch = useDispatch();

  const setReviewed = businessId => {
    dispatch(
      BusinessAction.update(
        { id: businessId, lastReviewed: getNowNoTimezone() },
        {
          changeReason: "Updated using alternatives.",
        },
      ),
    );
  };

  const saveBusiness = patch => {
    const reviewedPatch = {
      ...patch,
      lastReviewed: getNowNoTimezone(),
    };

    dispatch(
      BusinessAction.update(reviewedPatch, {
        changeReason: "Updated using alternatives.",
      }),
    );
  };

  const filteredCount = Object.values(filteredAlternativesByBusinessId).length;

  return (
    <Column>
      <FormSectionGrid container spacing={2}>
        <Paper>
          <Grid item xs={12} container spacing={2}>
            <Grid
              item
              xs={12}
              direction="row"
              justifyContent="space-between"
              container
            >
              <Typography variant="h6">Filters</Typography>
              <div>
                {`${filteredCount} ${
                  filteredCount === 1 ? "business" : "businesses"
                } ${
                  filteredCount === 1 ? "matches" : "match"
                } the filter criteria.`}
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              <AlternativeFieldConnectedSelect label="Field" />
            </Grid>

            <Grid item xs={12} lg={4}>
              <RoleInSaleConnectedSelect label="Role In Sale" />
            </Grid>

            <Grid item xs={12} lg={4}>
              <AlternativeSourceConnectedSelect label="Alternative Source" />
            </Grid>

            <Grid item xs={12} lg={8} container direction="column">
              <SettingSwitch
                label="Only show changes since last review"
                setting={Settings.resolveAlternatives.filterByLastReviewed}
              />

              <SettingSwitch
                label="Only show newer changes"
                setting={Settings.resolveAlternatives.filterByLastModified}
              />

              <SettingSwitch
                label="Hide empty alternatives"
                setting={Settings.resolveAlternatives.filterEmptyValues}
              />
            </Grid>
          </Grid>
        </Paper>
      </FormSectionGrid>
      <FormSectionGrid item xs={12} container spacing={2}>
        {Object.values(filteredAlternativesByBusinessId).map(business => (
          <Grid item xs={12} key={business.id}>
            <Paper>
              <Grid item xs={12}>
                <Table>
                  <colgroup>
                    <col width="12%" />
                    <col width="26%" />
                    <col width="26%" />
                    <col width="12%" />
                    <col width="20%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan={3} className="text-left">
                        <IconButton
                          onClick={() => openEditBusinessModal(business.id)}
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </IconButton>
                        <TitleText>{business.name}</TitleText>
                      </th>
                      <th>
                        <Column>
                          <div className="font-medium text-right">
                            Last reviewed:
                          </div>
                          <div className="font-medium text-right">
                            Last modified:
                          </div>
                        </Column>
                      </th>
                      <th colSpan={2} className="text-left">
                        <Column>
                          <div>
                            {formatUTCToLocalDateTimeString(
                              business.lastReviewed,
                            )}
                          </div>
                          <div>
                            {formatUTCToLocalDateTimeString(
                              business.lastModified,
                            )}
                          </div>
                        </Column>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2} className="text-left br">
                        Current
                      </th>
                      <th colSpan={4} className="text-left alt">
                        Alternative
                      </th>
                    </tr>
                    <tr>
                      <th className="text-left bs">Field</th>
                      <th className="text-left br bs">Value</th>
                      <th className="text-left bs alt">Value</th>
                      <th className="text-left bs alt">Source</th>
                      <th className="text-left bs alt">Last Modified</th>
                    </tr>
                  </thead>

                  <ChangeTableBody
                    businessId={business.id}
                    dispatchUpdates={dispatchUpdates}
                    updates={updates[business.id]}
                  />
                  <tfoot>
                    <tr>
                      <th>&nbsp;</th>
                      <th>
                        <SlimButton onClick={() => setReviewed(business.id)}>
                          Keep current values
                        </SlimButton>
                      </th>
                      <th>
                        <SlimButton
                          disabled={isEmpty(updates?.[business.id])}
                          onClick={() =>
                            saveBusiness({
                              ...updates[business.id],
                              id: business.id,
                            })
                          }
                        >
                          Update to selected alternatives.
                        </SlimButton>
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </tfoot>
                </Table>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </FormSectionGrid>
    </Column>
  );
};

const WaitingWrapper = () => {
  return (
    <WaitForSync requiredData={[ApiModel.BUSINESSES, ApiModel.SALE_LOTS]}>
      <ResolveAlternativesComponent />
    </WaitForSync>
  );
};

export default WaitingWrapper;
