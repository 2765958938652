import React, { useCallback } from "react";

import { Box, Divider, Switch } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { Button, SecondaryButton } from "components/Form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import {
  getBusinessById,
  getContextByModalType,
  getPropertyById,
  getSetting,
} from "selectors";
import { ModalTypes } from "constants/navigation";
import { BusinessAction, setSetting } from "actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Settings } from "constants/settings";
import { uniq } from "lodash";

interface UpdateDefaultPropertyModalState {
  businessId: string;
  buyerWayName: string;
  propertyId: string;
  saleyardId: number | null;
}

function useSuggestBuyerWayPropertyContext() {
  const { businessId, buyerWayName, propertyId, saleyardId } = useSelector<
    RootState,
    UpdateDefaultPropertyModalState
  >(getContextByModalType(ModalTypes.DefaultBuyerWayProperty));

  const business = useSelector<RootState, Business>(
    getBusinessById(businessId),
  );

  // Normalise to lowercase because the backend is not case-sensitive (MySQL)
  const normalisedBuyerWayName = buyerWayName ? buyerWayName.toLowerCase() : "";

  const buyerWays = business?.buyerWays || [];
  const relevantBuyerWays = buyerWays.filter(
    buyerWay => buyerWay.name.toLowerCase() === normalisedBuyerWayName,
  );

  let buyerWay = null;
  let alternateBuyerWay = null;

  if (saleyardId) {
    // when saleyard Id is specified, use the following logic:
    // If they have an existing buyer way for the given yard, and that is the only yard in the set, update that.
    // when they have another buyer way for a different yard where the property is the same, update that
    // otherwise create a new one
    // if there was an existing, shared buyer way we need to remove the yard from there as part of our updates
    const saleyardBuyerWays = relevantBuyerWays.filter(
      relevantBuyerWay => relevantBuyerWay.saleyardIds.length > 0,
    );

    buyerWay =
      saleyardBuyerWays.find(saleyardBuyerWay =>
        saleyardBuyerWay.saleyardIds.includes(saleyardId),
      ) || null;

    alternateBuyerWay =
      saleyardBuyerWays.find(
        saleyardBuyerWay =>
          saleyardBuyerWay.destinationPropertyId === propertyId,
      ) || null;
  } else {
    // when saleyard Id is not specified, use the following logic:
    // If they have an existing buyer way with no yards, update that.
    buyerWay =
      relevantBuyerWays.find(
        relevantBuyerWay => relevantBuyerWay.saleyardIds.length === 0,
      ) || null;
  }

  const isReplacementProperty = Boolean(
    buyerWay && buyerWay.destinationPropertyId,
  );

  const existingProperty = useSelector<RootState, Property>(
    getPropertyById(buyerWay?.destinationPropertyId),
  );
  const updatedProperty = useSelector<RootState, Property>(
    getPropertyById(propertyId),
  );
  const existingPic: string = existingProperty?.PIC;
  const updatedPic: string = updatedProperty?.PIC;
  return {
    alternateBuyerWay,
    business,
    businessId,
    buyerWay,
    buyerWays,
    buyerWayName,
    existingPic,
    isReplacementProperty,
    propertyId,
    saleyardId,
    updatedPic,
  };
}

interface UpdateDefaultPropertyActionsProps {
  onClose: () => void;
}

function UpdateDefaultPropertyActions(
  props: UpdateDefaultPropertyActionsProps,
): React.JSX.Element {
  const { onClose } = props;
  const dispatch = useDispatch();

  const {
    alternateBuyerWay,
    businessId,
    buyerWay,
    buyerWayName,
    buyerWays,
    propertyId,
    saleyardId,
    updatedPic,
  } = useSuggestBuyerWayPropertyContext();

  const updateBuyerWay = useCallback(() => {
    const removeFromExistingSharedBuyerWay =
      buyerWay && buyerWay.saleyardIds.length > 1;
    const consolidateSaleyardIdWithExistingBuyerWay =
      Boolean(alternateBuyerWay);
    const updateExistingBuyerWay =
      buyerWay && buyerWay.saleyardIds.length === 1;
    const omitExistingBuyerWay =
      consolidateSaleyardIdWithExistingBuyerWay &&
      buyerWay &&
      buyerWay.saleyardIds.length === 1;

    const initialBuyerWays =
      !updateExistingBuyerWay && !alternateBuyerWay
        ? [
            {
              id: -Date.now(),
              name: buyerWayName,
              isShown: true,
              saleyardIds: saleyardId ? [saleyardId] : [],
              destinationPropertyId: propertyId,
            },
          ]
        : [];

    const businessPayload = {
      id: businessId,
      buyerWays: buyerWays.reduce<BusinessBuyerWay[]>(
        (updatedBuyerWays, businessBuyerWay) => {
          if (omitExistingBuyerWay && businessBuyerWay === buyerWay) {
            return updatedBuyerWays;
          }

          if (
            removeFromExistingSharedBuyerWay &&
            businessBuyerWay === buyerWay
          ) {
            updatedBuyerWays.push({
              ...buyerWay,
              saleyardIds: buyerWay.saleyardIds.filter(id => id !== saleyardId),
            });
          } else if (
            consolidateSaleyardIdWithExistingBuyerWay &&
            businessBuyerWay === alternateBuyerWay
          ) {
            updatedBuyerWays.push({
              ...alternateBuyerWay,
              saleyardIds: uniq([...alternateBuyerWay.saleyardIds, saleyardId]),
            });
          } else if (updateExistingBuyerWay && businessBuyerWay === buyerWay) {
            updatedBuyerWays.push({
              ...buyerWay,
              destinationPropertyId: propertyId,
            });
          } else if (businessBuyerWay.isShown) {
            updatedBuyerWays.push(businessBuyerWay);
          }
          return updatedBuyerWays;
        },
        initialBuyerWays,
      ),
    };

    dispatch(BusinessAction.update(businessPayload));
    onClose();
  }, [
    alternateBuyerWay,
    businessId,
    buyerWay,
    buyerWayName,
    buyerWays,
    dispatch,
    onClose,
    propertyId,
    saleyardId,
  ]);

  return (
    <>
      <SecondaryButton onClick={onClose} tabIndex={0}>
        Don&apos;t update default
      </SecondaryButton>
      <Button onClick={updateBuyerWay}>Set {updatedPic} as default</Button>
    </>
  );
}

interface UpdateDefaultPropertyModalProps {
  onClose: () => void;
}

export const UpdateDefaultPropertyModal = (
  props: UpdateDefaultPropertyModalProps,
): React.JSX.Element => {
  const { onClose } = props;

  const dispatch = useDispatch();

  const {
    businessId,
    business,
    buyerWayName,
    existingPic,
    isReplacementProperty,
    propertyId,
    updatedPic,
  } = useSuggestBuyerWayPropertyContext();

  const suggestBuyerWayProperties = useSelector(
    getSetting(Settings.suggestBuyerWayProperties),
  );

  const onChangeSuggestBuyerWayProperties = useCallback(() => {
    dispatch(
      setSetting(
        Settings.suggestBuyerWayProperties,
        !suggestBuyerWayProperties,
      ),
    );
  }, [suggestBuyerWayProperties, dispatch]);

  if (!propertyId || !buyerWayName || !businessId || !business) {
    return null;
  }

  const replacementPrompt = isReplacementProperty
    ? `The PIC "${updatedPic}" is different to the current default PIC "${existingPic}"`
    : "There is no default PIC";

  const message = `${replacementPrompt} for Buyer Way "${buyerWayName}" on Business "${business.name}".`;

  const title = isReplacementProperty
    ? `Update default PIC for Buyer Way "${buyerWayName}"?`
    : `Set default PIC for Buyer Way "${buyerWayName}"?`;

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        <Box marginY={2}>
          <p>{message}</p>
          <p>
            This default PIC will be used when this Buyer Way and Business
            combination is used in the future.
          </p>
        </Box>
        <Divider />
        <Box padding={2}>
          <FormControlLabel
            control={
              <Switch
                checked={suggestBuyerWayProperties}
                color="primary"
                onChange={onChangeSuggestBuyerWayProperties}
              />
            }
            label="Keep offering to set default PICs for this session."
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <UpdateDefaultPropertyActions onClose={onClose} />
      </DialogActions>
    </Dialog>
  );
};
