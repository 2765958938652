import React from "react";

import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { faCloudUpload } from "@fortawesome/pro-duotone-svg-icons";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { requestAllNvdUploads, submitNVDUploads } from "actions";

import { Action, ActionIcon } from "components/Button/Actions";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Paper } from "components/Paper";

import { ApiModel } from "constants/loading";

import { pluralize } from "lib/pluralize";
import { formatDateTimeUTC } from "lib/timeFormats";

import {
  getCurrentSale,
  selectConsignmentsForNVDUpload,
  getNVDUploads,
} from "selectors";

import { getIsFetchingNLISInformation } from "selectors/loading";

import { useMountEffect } from "hooks";

import { ActionButton, ExpandTable } from "./utils";

const ToolTipPadding = styled.span`
  padding: ${({ theme }) => `0 ${theme.space[2]}px`};
`;

export const NLISSNVDUploadInfo = () => {
  const consignments = useSelector(selectConsignmentsForNVDUpload);
  const uploadableConsignments = consignments.filter(c => c.canBeUploaded);
  const consignmentsWithExceptionsCount = consignments.filter(
    c => c.hasExceptions,
  ).length;
  const consignmentsWithWarningsCount = consignments.filter(
    c => c.hasWarnings,
  ).length;
  const pendingConsignmentsCount = consignments.filter(
    c => c.isSubmissionPending,
  ).length;
  const successfulConsignmentCount = consignments.filter(
    c => c.isSubmissionSuccessful,
  ).length;
  const sale = useSelector(getCurrentSale);

  const dispatch = useDispatch();
  const isFetching = useSelector(getIsFetchingNLISInformation);
  const handleRefresh = React.useCallback(() => {
    !isFetching && dispatch(requestAllNvdUploads(sale));
  }, [dispatch, sale, isFetching]);

  const nvdUploads = useSelector(getNVDUploads);

  useMountEffect(() => {
    if (Object.keys(nvdUploads).length === 0) {
      handleRefresh();
    }
  });

  const uploadConsignments = () => {
    dispatch(
      submitNVDUploads(
        uploadableConsignments.map(c => c.id),
        sale,
      ),
    );
  };

  const uploadConsignment = consignmentId => {
    dispatch(submitNVDUploads([consignmentId], sale));
  };

  return (
    <Paper>
      <Grid container spacing={2}>
        <WaitForSync requiredData={[ApiModel.NVD_UPLOADS]}>
          <Row flexGrow justifyBetween>
            <Column fullWidth padding={2}>
              <Alert severity="info">
                You currently have {consignmentsWithExceptionsCount}{" "}
                {pluralize("Consignment", consignmentsWithExceptionsCount)}
                &nbsp;with exceptions, {consignmentsWithWarningsCount} with
                warnings, {pendingConsignmentsCount} pending submission and{" "}
                {successfulConsignmentCount} successfully submitted.
              </Alert>

              <Alert severity="info">
                {uploadableConsignments.length} Consignment
                {uploadableConsignments.length === 1 ? " is " : "s are"} ready
                for submission.
              </Alert>
            </Column>
            <Action onClick={handleRefresh} disabled={isFetching}>
              <ActionIcon icon={faRedo} />
              Refresh
            </Action>
          </Row>

          <Grid item xs={12}>
            {uploadableConsignments.length > 0 && (
              <ActionButton onClick={uploadConsignments}>
                Submit All Ready Consignments Now
              </ActionButton>
            )}
          </Grid>

          <Grid item xs={12}>
            <ExpandTable
              data={consignments}
              columns={[
                {
                  Header: "Vendor",
                  accessor: "vendorName",
                },

                {
                  Header: "Transaction Id",
                  accessor: "transactionId",
                },
                {
                  Header: "Status",
                  accessor: "status",
                  Cell: props => {
                    const {
                      value: status,
                      original: { errorNotes },
                    } = props;
                    return (
                      <>
                        {status}
                        {errorNotes && (
                          <ToolTipPadding>
                            <Tooltip title={errorNotes} />
                          </ToolTipPadding>
                        )}
                      </>
                    );
                  },
                },
                {
                  id: "created",
                  Header: "Created",
                  accessor: row =>
                    row.created ? formatDateTimeUTC(row.created) : "-",
                },
                {
                  id: "submitted",
                  Header: "Submitted",
                  accessor: row =>
                    row.submitted ? formatDateTimeUTC(row.submitted) : "-",
                },
                {
                  Header: "",
                  id: "upload",
                  accessor: row => (
                    <Action
                      disabled={!row.canBeUploaded}
                      onClick={() => {
                        row.canBeUploaded && uploadConsignment(row.id);
                      }}
                    >
                      <ActionIcon icon={faCloudUpload} flip="horizontal" />
                      Upload
                    </Action>
                  ),
                },
              ]}
              suppressNoResult
            />
          </Grid>
        </WaitForSync>
      </Grid>
    </Paper>
  );
};
