import find from "lodash/find";
import groupBy from "lodash/groupBy";
import max from "lodash/max";
import meanBy from "lodash/meanBy";
import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";
import sortedUniq from "lodash/uniq";
import { createSelector } from "reselect";

import {
  CONSIGNMENT_NVD_EXCEPTIONS,
  consignmentStatusesOrder,
  saleLotStatuses,
  saleLotStatusesOrder,
} from "constants/sale";
import { ScanStatus } from "constants/scanner";

import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formatDecimal,
  sortNumericalStrings,
} from "lib";

import { caseInsensitiveCompare } from "lib/compare";
import { getConsignmentStatus } from "lib/consignments";
import {
  doesSaleLotHaveOverflowPen,
  getSaleLotStatus,
  isSaleLotDelivered,
  isSaleLotNoSale,
  isSaleLotPenned,
  isSaleLotSold,
} from "lib/saleLot";
import { aggregateText } from "lib/textUtils";

import {
  formatSaleLot,
  getBranchMap,
  getBusinesses,
  getCarrierCharges,
  getConsignments,
  getCurrentSale,
  getCurrentSaleyard,
  getFiles,
  getModalContexts,
  getNominations,
  getProperties,
  getSaleLots,
  getSaleLotsBySale,
  getSellFileBySaleLotId,
  getStatusForSaleLots,
  getTakeFilesWithExtraInfo,
  isAttachmentCompleted,
  selectAgencyByConsignmentIdLookup,
  selectAttachmentIdsByConsignmentIdLookup,
  selectConsignmentIdsByVendorIdLookup,
  selectDeploymentBusinessVendorByConsignmentIdLookup,
  selectEidsBySaleLotIdLookup,
  selectExceptionsByConsignmentIdLookup,
  selectFilteredConsignmentIds,
  selectFilteredSaleLotIds,
  selectHasNvdUploadByConsignmentIdLookup,
  selectImageSummaryByAuctionPenIdLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectMarkOrderBySaleLotIdLookup,
  selectNameByDeploymentIdLookup,
  selectNominationIdsByVendorIdLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleLotSummaryByAuctionPenIdLookup,
  selectSaleyardScanSaleLotIdsByConsignmentIdLookup,
  selectScannedStatusByAuctionPenIdLookup,
  selectScansBySaleLotIdLookup,
  selectSellFileBySaleLotIdLookup,
  selectUnresolvedSaleLotCommentCountByAuctionPenIdLookup,
} from "selectors";

import { getSkinsSexId } from "./sexes";

export const getConsignmentsWithAttachments = createSelector(
  [
    getBusinesses,
    getConsignments,
    selectAttachmentIdsByConsignmentIdLookup,
    getProperties,
    getSaleLotsBySale,
    selectSaleyardScanSaleLotIdsByConsignmentIdLookup,
    getBranchMap,
    getCarrierCharges,
    selectEidsBySaleLotIdLookup,
    selectAgencyByConsignmentIdLookup,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    selectIsPreSaleBalancedByConsignmentIdLookup,
    selectExceptionsByConsignmentIdLookup,
    selectNameByDeploymentIdLookup,
    selectDeploymentBusinessVendorByConsignmentIdLookup,
    getFiles,
    selectHasNvdUploadByConsignmentIdLookup,
    getSkinsSexId,
  ],
  (
    businesses,
    consignments,
    attachmentIdsByConsignmentIdLookup,
    properties,
    saleLots,
    saleyardScansSaleLotIds,
    branchMap,
    charges,
    eidsBySaleLotId,
    agencyByConsignmentIdLookup,
    isPostSaleBalancedByConsignmentId,
    isPreSaleBalancedByConsignmentIdLookup,
    exceptionsByConsignmentId,
    deploymentNameById,
    deploymentBusinessByConsignmentId,
    files,
    hasNvdUploadByConsignmentId,
    skinsSexId,
  ) =>
    Object.values(consignments).map(consignment => {
      const consignmentId = consignment.id;
      const attachments =
        attachmentIdsByConsignmentIdLookup[consignmentId]?.map(
          attachmentId => files[attachmentId],
        ) || EMPTY_ARRAY;

      const withAttachments = {
        attachments: attachments.map(attachment => ({
          ...attachment,
          isCompleted: isAttachmentCompleted(attachment, consignment),
        })),
        isNVDAttached: hasNvdUploadByConsignmentId[consignment.id],
      };

      const consignmentSaleLots = saleLots.filter(
        s => s.consignment_id === consignmentId,
      );

      const saleyardScanSaleLotIds =
        saleyardScansSaleLotIds[consignmentId] || EMPTY_ARRAY;

      const saleyardScans =
        eidsBySaleLotId[saleyardScanSaleLotIds[0]] || EMPTY_ARRAY;

      const saleyardScannedCount = saleyardScans.length;

      const quantityProgeny = sumBy(consignmentSaleLots, "quantityProgeny");
      const scannedCount = sumBy(consignmentSaleLots, "scannedCount");

      const scannedStatus =
        max(consignmentSaleLots.map(saleLot => saleLot.scannedStatus)) ||
        ScanStatus.PASS;

      const scannedPercentage = scannedCount / consignment.quantity;
      const consignmentScannedCount = scannedCount + saleyardScannedCount;
      const consignmentScannedPercentage =
        consignmentScannedCount / consignment.quantity;
      const consignmentScannedStatus =
        saleyardScannedCount > 0 ? ScanStatus.WARNING : ScanStatus.PASS;

      const exceptions =
        exceptionsByConsignmentId[consignmentId] || EMPTY_ARRAY;

      const vendorProperty = properties[consignment.vendor_property_id];
      const vendor = deploymentBusinessByConsignmentId[consignment.id]
        ? deploymentBusinessByConsignmentId[consignment.id]
        : businesses[consignment.vendor_id];
      const drafted = consignmentSaleLots
        .filter(saleLot => saleLot.sex_id !== skinsSexId)
        .reduce((a, sl) => a + (sl.quantity + sl.quantityProgeny), 0);
      const isBalanced = isPostSaleBalancedByConsignmentId[consignmentId];
      const isPreSaleBalanced =
        isPreSaleBalancedByConsignmentIdLookup[consignmentId];
      const branch = vendor?.branchId ? branchMap[vendor.branchId] : "";

      const quantity_diff = consignment.quantity - drafted;
      const livestockAgency = agencyByConsignmentIdLookup[consignmentId];
      const consignedFrom = deploymentNameById[consignment.consignedFromId];

      return {
        ...consignment,
        ...withAttachments,
        livestockAgency,
        branchName: branch,
        carrierCharge: charges[consignment.carrierChargeId],
        consignmentScannedCount,
        consignmentScannedPercentage,
        consignmentScannedStatus,
        consignedFrom,
        drafted,
        hasExceptions: exceptions.length > 0,
        hasNVDExceptions: CONSIGNMENT_NVD_EXCEPTIONS.some(e =>
          exceptions.includes(e),
        ),
        hasArrived: consignment.hasArrived,
        isBalanced,
        isPreSaleBalanced,
        quantity_diff,
        vendor,
        quantityProgeny,
        saleyardScannedCount,
        scannedCount,
        scannedPercentage,
        scannedStatus,
        vendor_name: vendor ? vendor.name : "",
        vendor_property: vendorProperty,
        vendor_short_code: vendor ? vendor.shortCode : "",
      };
    }),
);

/**
 * Gets the nested consignments for the consignment cards view
 */
export const getNestedConsignments = createSelector(
  [
    getConsignmentsWithAttachments,
    getSaleLotsBySale,
    selectScansBySaleLotIdLookup,
    getTakeFilesWithExtraInfo,
    selectAgencyByConsignmentIdLookup,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    selectExceptionsByConsignmentIdLookup,
    state => getCurrentSale(state).species_id,
    selectIsWeighedBySaleLotIdLookup,
    selectFilteredConsignmentIds,
    selectFilteredSaleLotIds,
    getCurrentSaleyard,
  ],
  (
    consignments,
    saleLots,
    scansBySaleLot,
    takeStatuses,
    agencyByConsignmentIdLookup,
    isPostSaleBalancedByConsignmentId,
    exceptionsByConsignmentId,
    speciesId,
    isWeighedBySaleLotIdLookup,
    filteredConsignmentIds,
    filteredSaleLotIds,
    currentSaleyard,
  ) => {
    // nest consignments by vendor
    const nestedConsignments = consignments
      .filter(consignment => filteredConsignmentIds.includes(consignment.id))
      .map(consignment => {
        const consignmentSaleLots = Object.values(saleLots).filter(
          s =>
            s.consignment_id === consignment.id &&
            filteredSaleLotIds.includes(s.id),
        );

        const nestedSaleLots = consignmentSaleLots.map(saleLots =>
          formatSaleLot(
            saleLots,
            scansBySaleLot,
            agencyByConsignmentIdLookup,
            {},
            speciesId,
            isWeighedBySaleLotIdLookup,
            currentSaleyard,
          ),
        );

        const orderedSaleLots = sortBy(
          nestedSaleLots,
          saleLot => saleLot.receivingPen,
        ).sort(
          (a, b) =>
            saleLotStatusesOrder.indexOf(a.status) -
            saleLotStatusesOrder.indexOf(b.status),
        );

        const exceptions = exceptionsByConsignmentId[consignment.id];

        const vendorNumber = consignment.vendorNumber
          ? consignment.vendorNumber
          : "";

        return {
          ...consignment,
          id: consignment.id,
          NVD: consignment.NVD,
          attachments: consignment.attachments,
          vendorId: consignment.vendor_id,
          vendorName: consignment.vendor ? consignment.vendor.name : "",
          vendorNumber,
          agencyVendorNumber: `${consignment.livestock_agency_code}${vendorNumber}`,
          vendorPIC: consignment.vendor_property
            ? consignment.vendor_property.PIC
            : "",
          vendorPropertyId: consignment.vendor_property
            ? consignment.vendor_property.id
            : "",
          status: getConsignmentStatus(consignment),
          exceptions,
          receivingReference: consignment.receiving_reference,
          receivingPen: consignment.receiving_pen,
          quantity: consignment.quantity,
          averageWeight: formatDecimal(
            meanBy(orderedSaleLots, "totalMassGrams") / 1000,
          ),
          quantityDrafted: consignment.drafted,
          quantityUndrafted: consignment.quantity - consignment.drafted,
          isBalanced: isPostSaleBalancedByConsignmentId[consignment.id],
          saleLots: orderedSaleLots,
          isAllPenned:
            orderedSaleLots.length &&
            orderedSaleLots.every(lot => lot.startPen),
          hasArrived: consignment.hasArrived,
          takeStatus: takeStatuses[consignment.id],
          agencyId: agencyByConsignmentIdLookup[consignment.id]?.id,
        };
      });
    return nestedConsignments;
  },
);

const groupedConsignmentFormatter = consignments => {
  const scannedCount = sumBy(consignments, "scannedCount");
  const saleyardScannedCount = sumBy(consignments, "saleyardScannedCount");
  const quantity = sumBy(consignments, "quantity");
  const quantityProgeny = sumBy(consignments, "quantityProgeny");

  const consignmentsScannedCount = saleyardScannedCount + scannedCount;
  const orderedConsignments = sortBy(
    consignments,
    consignment => consignment.receivingPen,
  ).sort(
    (a, b) =>
      consignmentStatusesOrder.indexOf(a.status) -
      consignmentStatusesOrder.indexOf(b.status),
  );

  const scannedStatus = max(
    consignments.map(consignment => consignment.scannedStatus),
  );

  const vendorNumbers = sortedUniq(
    consignments.map(consignment => consignment.vendorNumber),
  );

  const publicDisplayName = sortedUniq(
    consignments
      .map(consignment => consignment.vendor?.publicDisplayName)
      .filter(Boolean),
  );

  return {
    consignments: orderedConsignments,
    consignmentsScannedCount,
    livestock_agency_code: consignments[0].livestock_agency_code || "",
    vendorId: consignments[0].vendorId,
    vendorName: consignments[0].vendorName,
    agencyVendorNumber: consignments[0].agencyVendorNumber,
    vendorNumbers,

    vendorPublicDisplayName: publicDisplayName || "",

    vendorNumber: Number(consignments[0].vendorNumber) || 0,

    receivingPen: consignments[0].receivingPen,
    quantity,
    quantityDrafted: sumBy(consignments, "quantityDrafted"),
    quantityUndrafted: sumBy(consignments, "quantityUndrafted"),
    quantityProgeny,
    saleyardScannedCount,
    scannedCount,
    scannedStatus,
    scannedPercentage: quantity > 0 ? consignmentsScannedCount / quantity : 0,
    hasArrived: consignments.every(c => c.hasArrived),
    isBalanced: consignments.every(c => c.isBalanced),
    isPreSaleBalanced: consignments.every(c => c.isPreSaleBalanced),
    hasExceptions: consignments.some(c => c.exceptions.length > 0),
  };
};

export const getNestedConsignmentsByVendor = createSelector(
  [getNestedConsignments],
  consignments =>
    sortBy(
      Object.values(groupBy(consignments, "vendorId")).map(
        groupedConsignmentFormatter,
      ),
      consignment =>
        consignment.vendorName ? consignment.vendorName.toLowerCase() : "",
    ),
);

export const getNestedConsignmentsByReceivingPen = createSelector(
  [getNestedConsignments],
  consignments =>
    sortNumericalStrings(
      Object.values(
        groupBy(
          consignments,
          consignment => `${consignment.vendorId}_${consignment.receivingPen}`,
        ),
      ).map(groupedConsignmentFormatter),
      consignment => consignment.receivingPen,
    ),
);

export const selectNestedConsignmentsByVendorNumberLookup = createSelector(
  [getNestedConsignments],
  consignments => {
    const consignmentsByVendorId = groupBy(consignments, "vendorId");

    const groupedConsignments = Object.values(consignmentsByVendorId).map(
      groupedConsignmentFormatter,
    );

    const removeLetters = value => value.replace(/[^\d.-]/g, "");

    return Object.values(groupedConsignments).sort((a, b) => {
      const valueA = removeLetters(a.vendorNumbers[0]);
      const valueB = removeLetters(b.vendorNumbers[0]);
      if (a.vendorNumbers[0] === "" && b.vendorNumbers[0] === "") {
        return caseInsensitiveCompare(a.vendorName, b.vendorName);
      } else if (valueA === "" && valueB !== "") {
        return 1;
      } else if (valueA !== "" && valueB === "") {
        return -1;
      }
      return valueA - valueB;
    });
  },
);

export const selectFormattedSaleLotsBySaleLotId = createSelector(
  [
    getSaleLotsBySale,
    selectScansBySaleLotIdLookup,
    selectAgencyByConsignmentIdLookup,
    selectMarkOrderBySaleLotIdLookup,
    state => getCurrentSale(state).species_id,
    getCurrentSaleyard,
  ],
  (
    allSaleLots,
    scansBySaleLot,
    agencyByConsignmentIdLookup,
    markOrderBySaleLotIdLookup,
    speciesId,
    currentSaleyard,
  ) =>
    allSaleLots.reduce((lookup, saleLot) => {
      lookup[saleLot.id] = formatSaleLot(
        saleLot,
        scansBySaleLot,
        agencyByConsignmentIdLookup,
        markOrderBySaleLotIdLookup,
        speciesId,
        {},
        currentSaleyard,
      );
      return lookup;
    }, {}),
);

export const getFormattedSaleLotBySaleLotId = saleLotId => state =>
  selectFormattedSaleLotsBySaleLotId(state)[saleLotId] || EMPTY_OBJECT;

export const getAuctionPensByRound = createSelector(
  [
    selectFormattedSaleLotsBySaleLotId,
    selectSellFileBySaleLotIdLookup,
    selectUnresolvedSaleLotCommentCountByAuctionPenIdLookup,
    selectImageSummaryByAuctionPenIdLookup,
    selectSaleLotSummaryByAuctionPenIdLookup,
    selectScannedStatusByAuctionPenIdLookup,
  ],
  (
    formattedSaleLots,
    sellFiles,
    unresolvedSaleLotCommentCountByAuctionPenIdLookup,
    imageSummaryByAuctionPenIdLookup,
    saleLotCountSummaryByAuctionPenIdLookup,
    scannedStatusByAuctionPenIdLookup,
  ) => {
    const groupedBySaleRound = groupBy(formattedSaleLots, "saleRoundId");
    // Return a map of saleRound: saleLotsInThatRound
    return new Map(
      Object.entries(groupedBySaleRound).map(([saleRound, saleLotsByRound]) => {
        // Group penned items together, but separate unpenned and uncounted.
        const groupedByStartPen = groupBy(
          saleLotsByRound.map(sl => ({
            ...sl,
            mapKey: `${sl.startPen}-${
              sl.quantity === 0 && !sl.startPen ? "needs-count" : "counted"
            }`,
          })),
          "mapKey",
        );
        return [
          saleRound,
          Object.values(groupedByStartPen).map(saleLots => {
            const saleLotWithOverflowPen = find(saleLots, lot =>
              doesSaleLotHaveOverflowPen(lot),
            );
            const updatedStatusesSaleLots = saleLots.map(saleLot => {
              const updatedStatus =
                saleLot.status === saleLotStatuses.PENNED
                  ? saleLotStatuses.NOT_SOLD
                  : saleLot.status;
              return {
                ...saleLot,
                status: updatedStatus,
              };
            });

            const saleLotsSortedByMark = sortBy(
              updatedStatusesSaleLots,
              "markOrder",
            );

            const { auctionPenId } = saleLots[0];
            const imageCount =
              imageSummaryByAuctionPenIdLookup[auctionPenId].count;
            const imageStatus =
              imageSummaryByAuctionPenIdLookup[auctionPenId].status;
            const unresolvedSaleLotComments =
              unresolvedSaleLotCommentCountByAuctionPenIdLookup[auctionPenId];

            const {
              scanned: scannedCount,
              scannedPercentage,
              quantity,
              hasVideo,
            } = saleLotCountSummaryByAuctionPenIdLookup[auctionPenId];
            const scannedStatus =
              scannedStatusByAuctionPenIdLookup[auctionPenId];

            const prices = sortedUniq(
              saleLots
                .filter(sl => sl.priceCents > 0)
                .map(sl => formatDecimal(sl.priceCents / 100)),
            );
            let price = "";
            if (prices.length > 0) {
              price =
                prices.length > 1 ? `${prices.length} prices` : `$${prices[0]}`;
            }

            // In theory, all sale lots in an auction pen should have the same NLIS
            // sell file attached if an attempt has been made to transfer the devices.
            // Return the first sellfile we can find.  Important if the buyer is not the
            // default buyer.
            const sellFile = saleLots
              .map(saleLot => getSellFileBySaleLotId(sellFiles, saleLot.id))
              .filter(Boolean)?.[0];

            return {
              imageCount,
              hasVideo,
              imageStatus,
              agencyName: aggregateText(saleLots, "agencyName", "agencies"),
              agencyShortName: aggregateText(
                saleLots,
                "agencyShortName",
                "agencies",
              ),
              firstSaleLotId: saleLots[0].id,
              consignmentId: saleLots[0].consignment_id,
              saleLots: saleLotsSortedByMark,
              startPen: saleLots[0].startPen,
              startPenNumeric: +saleLots[0].startPen.replace(/[^\d]/, ""),
              endPen: saleLots[0].endPen,
              endPenNumeric: saleLots[0].endPen
                ? +saleLots[0].endPen.replace(/[^\d]/, "")
                : undefined,
              penName: saleLots[0].penName,
              order: saleLots[0].penOrder,
              saleRoundId: saleLots[0].saleRoundId,
              auctionPenId,
              isLaneStart: saleLots[0].isLaneStart,
              buyerId: saleLots[0].buyerId,
              vendorName: aggregateText(saleLots, "vendorName", "vendors"),
              vendorPublicDisplayName: aggregateText(
                saleLots,
                "vendorPublicDisplayName",
              ),
              buyerName: aggregateText(saleLots, "buyerName", "buyers"),
              destinationProperty: aggregateText(
                saleLots,
                "destinationProperty",
                "properties",
              ),
              buyerWayName: aggregateText(
                saleLots,
                "buyerWayName",
                "buyer ways",
              ),
              price,
              quantity,
              scannedCount,
              scannedStatus,
              scannedPercentage,
              status: getStatusForSaleLots(updatedStatusesSaleLots),
              hasOverflowPen: !!saleLotWithOverflowPen,
              overflowPen: saleLotWithOverflowPen
                ? saleLotWithOverflowPen.overflowPen
                : null,
              overflowQuantity: saleLotWithOverflowPen
                ? saleLotWithOverflowPen.overflowQuantity
                : null,
              sellFile,
              unresolvedSaleLotComments,
            };
          }),
        ];
      }),
    );
  },
);

export const selectNestedCountsByConsignmentId = createSelector(
  [getConsignments, selectSaleLotIdsByConsignmentIdLookup, getSaleLots],
  (consignments, saleLotIdsByConsignmentId, saleLots) => {
    return Object.entries(consignments).reduce(
      (acc, [consignmentId, consignment]) => {
        const saleLotIds =
          saleLotIdsByConsignmentId[consignmentId] || EMPTY_ARRAY;

        const consignmentSaleLots = saleLotIds.map(saleLotId => {
          return saleLots[saleLotId];
        });
        const consignmentSaleLotsWithStatus = consignmentSaleLots.map(lot => {
          return {
            ...lot,
            status: getSaleLotStatus(lot, consignment),
          };
        });

        const groupedSaleLotsByStatus = groupBy(
          consignmentSaleLotsWithStatus,
          "status",
        );

        const notPennedSaleLots =
          groupedSaleLotsByStatus[saleLotStatuses.NOT_PENNED];
        const pennedSaleLots = groupedSaleLotsByStatus[saleLotStatuses.PENNED];
        const soldSaleLots = groupedSaleLotsByStatus[saleLotStatuses.SOLD];
        const noSaleSaleLots = groupedSaleLotsByStatus[saleLotStatuses.NO_SALE];
        const deliveredSaleLots =
          groupedSaleLotsByStatus[saleLotStatuses.DELIVERED];
        const quantityProgenyNotPenned =
          sumBy(notPennedSaleLots, "quantityProgeny") || 0;
        const quantityProgenyPenned =
          sumBy(pennedSaleLots, "quantityProgeny") +
            sumBy(soldSaleLots, "quantityProgeny") +
            sumBy(deliveredSaleLots, "quantityProgeny") || 0;
        const quantityProgenySold =
          sumBy(soldSaleLots, "quantityProgeny") +
            sumBy(deliveredSaleLots, "quantityProgeny") || 0;
        const quantityProgenyNoSale =
          sumBy(noSaleSaleLots, "quantityProgeny") || 0;
        const quantityProgenyDelivered =
          sumBy(deliveredSaleLots, "quantityProgeny") || 0;

        acc[consignmentId] = {
          quantityProgenyNotPenned,
          quantityProgenyPenned,
          quantityProgenySold,
          quantityProgenyNoSale,
          quantityProgenyDelivered,
        };

        return acc;
      },
      {},
    );
  },
);

export const getTotalQuantityProgenyNotPenned = createSelector(
  [selectNestedCountsByConsignmentId],
  countsByConsignment =>
    Object.values(countsByConsignment).reduce((acc, cbc) => {
      acc += cbc.quantityProgenyNotPenned;
      return acc;
    }, 0),
);

export const getTotalQuantityProgenyPenned = createSelector(
  [selectNestedCountsByConsignmentId],
  countsByConsignment =>
    Object.values(countsByConsignment).reduce((acc, cbc) => {
      acc += cbc.quantityProgenyPenned;
      return acc;
    }, 0),
);

export const getTotalQuantityProgenyNoSale = createSelector(
  [selectNestedCountsByConsignmentId],
  countsByConsignment =>
    Object.values(countsByConsignment).reduce((acc, cbc) => {
      acc += cbc.quantityProgenyNoSale;
      return acc;
    }, 0),
);

export const getTotalQuantityProgenySold = createSelector(
  [selectNestedCountsByConsignmentId],
  countsByConsignment =>
    Object.values(countsByConsignment).reduce((acc, cbc) => {
      acc += cbc.quantityProgenySold;
      return acc;
    }, 0),
);

export const getTotalQuantityProgenyDelivered = createSelector(
  [selectNestedCountsByConsignmentId],
  countsByConsignment =>
    Object.values(countsByConsignment).reduce((acc, cbc) => {
      acc += cbc.quantityProgenyDelivered;
      return acc;
    }, 0),
);

export const selectSaleLotIdsByVendorIdLookup = createSelector(
  [selectSaleLotIdsByConsignmentIdLookup, selectConsignmentIdsByVendorIdLookup],
  (saleLotIdsByConsignmentIdLookup, consignmentIdsByVendorIdLookup) =>
    Object.entries(consignmentIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, consignmentIds]) => {
        lookup[vendorId] = consignmentIds.reduce((acc, consignmentId) => {
          return acc.concat(
            saleLotIdsByConsignmentIdLookup[consignmentId] || [],
          );
        }, []);
        return lookup;
      },
      {},
    ),
);

export const getSaleLotIdsByVendorId = vendorId => state =>
  selectSaleLotIdsByVendorIdLookup(state)[vendorId] || EMPTY_ARRAY;

const selectQuantityByVendorId = createSelector(
  [selectConsignmentIdsByVendorIdLookup, getConsignments],
  (consignmentIdsByVendorIdLookup, consignments) =>
    Object.entries(consignmentIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, consignmentIds]) => {
        lookup[vendorId] = consignmentIds.reduce((total, consignmentId) => {
          return total + consignments[consignmentId].quantity;
        }, 0);
        return lookup;
      },
      {},
    ),
);

export const getQuantityByVendorId = vendorId => state =>
  selectQuantityByVendorId(state)[vendorId];

const selectQuantityNominatedByVendorId = createSelector(
  [selectNominationIdsByVendorIdLookup, getNominations],
  (nominationIdsByVendorIdLookup, nominations) =>
    Object.entries(nominationIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, nominationIds]) => {
        lookup[vendorId] = nominationIds.reduce(
          (acc, nominationId) =>
            acc +
              sumBy(nominations[nominationId].nominationDetails, "quantity") ||
            0,
          0,
        );
        return lookup;
      },
      {},
    ),
);

export const getQuantityNominatedByvendorId = vendorId => state =>
  selectQuantityNominatedByVendorId(state)[vendorId];

const selectQuantityArrivedByVendorLookup = createSelector(
  [selectConsignmentIdsByVendorIdLookup, getConsignments],
  (consignmentIdsByVendorIdLookup, consignments) =>
    Object.entries(consignmentIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, consignmentIds]) => {
        lookup[vendorId] = consignmentIds.reduce((total, consignmentId) => {
          return consignments[consignmentId].hasArrived
            ? total + consignments[consignmentId].quantity
            : total;
        }, 0);
        return lookup;
      },
      {},
    ),
);

export const getQuantityArrivedByVendorId = vendorId => state =>
  selectQuantityArrivedByVendorLookup(state)[vendorId];

const selectQuantityPennedByVendorIdLookup = createSelector(
  [selectSaleLotIdsByVendorIdLookup, getSaleLots],
  (saleLotIdsByVendorIdLookup, saleLots) =>
    Object.entries(saleLotIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, saleLotIds]) => {
        lookup[vendorId] = sumBy(
          saleLotIds
            .map(saleLotId => saleLots[saleLotId])
            .filter(isSaleLotPenned),
          "quantity",
        );
        return lookup;
      },
      {},
    ),
);

export const getQuantityPennedByVendorId = vendorId => state =>
  selectQuantityPennedByVendorIdLookup(state)[vendorId];

const selectQuantitySoldByVendorIdLookup = createSelector(
  [selectSaleLotIdsByVendorIdLookup, getSaleLots, getConsignments],
  (saleLotIdsByVendorIdLookup, saleLots, consignments) =>
    Object.entries(saleLotIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, saleLotIds]) => {
        lookup[vendorId] = sumBy(
          saleLotIds
            .map(saleLotId => saleLots[saleLotId])
            .filter(saleLot =>
              isSaleLotSold(saleLot, consignments[saleLot.consignment_id]),
            ),
          "quantity",
        );
        return lookup;
      },
      {},
    ),
);

export const getQuantitySoldByVendorId = vendorId => state =>
  selectQuantitySoldByVendorIdLookup(state)[vendorId];

const selectQuantityNoSaleByVendorIdLookup = createSelector(
  [selectSaleLotIdsByVendorIdLookup, getSaleLots, getConsignments],
  (saleLotIdsByVendorIdLookup, saleLots, consignments) =>
    Object.entries(saleLotIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, saleLotIds]) => {
        lookup[vendorId] = sumBy(
          saleLotIds
            .map(saleLotId => saleLots[saleLotId])
            .filter(saleLot =>
              isSaleLotNoSale(saleLot, consignments[saleLot.consignment_id]),
            ),
          "quantity",
        );
        return lookup;
      },
      {},
    ),
);

export const getQuantityNoSaleByVendorId = vendorId => state =>
  selectQuantityNoSaleByVendorIdLookup(state)[vendorId];

const selectQuantityDeliveredByVendorIdLookup = createSelector(
  [selectSaleLotIdsByVendorIdLookup, getSaleLots],
  (saleLotIdsByVendorIdLookup, saleLots) =>
    Object.entries(saleLotIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, saleLotIds]) => {
        lookup[vendorId] = sumBy(
          saleLotIds
            .map(saleLotId => saleLots[saleLotId])
            .filter(isSaleLotDelivered),
          "quantity_delivered",
        );
        return lookup;
      },
      {},
    ),
);
export const getQuantityDeliveredByVendorId = vendorId => state =>
  selectQuantityDeliveredByVendorIdLookup(state)[vendorId];

const selectQuantityProgenyPennedByVendorIdLookup = createSelector(
  [selectSaleLotIdsByVendorIdLookup, getSaleLots],
  (saleLotIdsByVendorIdLookup, saleLots) =>
    Object.entries(saleLotIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, saleLotIds]) => {
        lookup[vendorId] = sumBy(
          saleLotIds
            .map(saleLotId => saleLots[saleLotId])
            .filter(isSaleLotPenned),
          "quantityProgeny",
        );
        return lookup;
      },
      {},
    ),
);

export const getQuantityProgenyPennedByVendorId = vendorId => state =>
  selectQuantityProgenyPennedByVendorIdLookup(state)[vendorId];

const selectQuantityProgenySoldByVendorIdLookup = createSelector(
  [selectSaleLotIdsByVendorIdLookup, getSaleLots, getConsignments],
  (saleLotIdsByVendorIdLookup, saleLots, consignments) =>
    Object.entries(saleLotIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, saleLotIds]) => {
        lookup[vendorId] = sumBy(
          saleLotIds
            .map(saleLotId => saleLots[saleLotId])
            .filter(saleLot =>
              isSaleLotSold(saleLot, consignments[saleLot.consignment_id]),
            ),
          "quantityProgeny",
        );
        return lookup;
      },
      {},
    ),
);

export const getQuantityProgenySoldByVendorId = vendorId => state =>
  selectQuantityProgenySoldByVendorIdLookup(state)[vendorId];

const selectQuantityProgenyNoSaleByVendorIdLookup = createSelector(
  [selectSaleLotIdsByVendorIdLookup, getSaleLots, getConsignments],
  (saleLotIdsByVendorIdLookup, saleLots, consignments) =>
    Object.entries(saleLotIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, saleLotIds]) => {
        lookup[vendorId] = sumBy(
          saleLotIds
            .map(saleLotId => saleLots[saleLotId])
            .filter(saleLot =>
              isSaleLotNoSale(saleLot, consignments[saleLot.consignment_id]),
            ),
          "quantityProgeny",
        );
        return lookup;
      },
      {},
    ),
);

export const getQuantityProgenyNoSaleByVendorId = vendorId => state =>
  selectQuantityProgenyNoSaleByVendorIdLookup(state)[vendorId];

// Note - this one isn't quite correct - it's the progeny count, on a delivered lot; as compared to the
// quantity_delivered.
const selectQuantityProgenyDeliveredByVendorIdLookup = createSelector(
  [selectSaleLotIdsByVendorIdLookup, getSaleLots],
  (saleLotIdsByVendorIdLookup, saleLots) =>
    Object.entries(saleLotIdsByVendorIdLookup).reduce(
      (lookup, [vendorId, saleLotIds]) => {
        lookup[vendorId] = sumBy(
          saleLotIds
            .map(saleLotId => saleLots[saleLotId])
            .filter(isSaleLotDelivered),
          "quantityProgeny",
        );
        return lookup;
      },
      {},
    ),
);
export const getQuantityProgenyDeliveredByVendorId = vendorId => state =>
  selectQuantityProgenyDeliveredByVendorIdLookup(state)[vendorId];

export const selectConsignmentOptionsByDeploymentSaleId = createSelector(
  [getConsignments, getBusinesses, getProperties],
  (consignmentsLookup, businessLookup, propertiesLookup) => {
    const optionsByDeploymentSaleId = Object.values(consignmentsLookup).reduce(
      (acc, consignment) => {
        const deploymentSaleId = consignment.deployment_sale;
        acc[deploymentSaleId] = acc[deploymentSaleId] || [];

        const vendor = businessLookup[consignment.vendor_id];
        acc[deploymentSaleId].push({
          value: consignment.id,
          label: `${vendor?.name} ${vendor?.shortCode || ""} (${
            consignment.quantity
          } Hd)`,
          nvd: consignment.NVD,
          vendorName: vendor?.name,
          vendorPICs: vendor?.properties?.map(
            prop => propertiesLookup[prop.id]?.PIC,
          ),
        });
        return acc;
      },
      {},
    );

    return Object.entries(optionsByDeploymentSaleId).reduce(
      (acc, [deploymentSaleId, options]) => {
        acc[deploymentSaleId] = sortBy(options, "label");
        return acc;
      },
      {},
    );
  },
);

export const getConsignmentOptionsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectConsignmentOptionsByDeploymentSaleId(state)[deploymentSaleId] ||
    EMPTY_ARRAY;

export const getContextByModalType = modalType => state =>
  getModalContexts(state)[modalType];
