import React from "react";

import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { get, sortBy } from "lodash";

import { ColumnType, WARNING_COLUMN_VALUE } from "components/AgGrid/constants";
import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import BusinessListActionColumn from "components/BusinessTable/BusinessListActionColumn";
import { Tooltip } from "components/Form/FormikControls/Tooltip";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import { IntegrationTypes } from "constants/integrations";
import { BusinessModalSection } from "constants/navigation";
import { VendorCommissionBandTypes } from "constants/vendorCommissionBands";

import { formatPercentage, getDollarPriceStringFromCents } from "lib";

import { getters } from "lib/agGrid";
import { IconClickableColumn } from "lib/agGrid/columns/columnStyles";
import {
  BusinessRenderer,
  IntegrationBusinessRenderer,
} from "lib/agGrid/columns/shortCode";
import { BusinessDefaultVendorSplitGetter } from "lib/agGrid/columns/vendorSplit";
import { BusinessRelationshipRenderer } from "lib/agGrid/renderers/businessRelationshipRenderer";
import {
  booleanRenderer,
  nullableBooleanRenderer,
} from "lib/agGrid/renderers/tickRenderer";
import { openEditBusinessModal } from "lib/navigation";

export const BusinessColId = {
  ABN: "ad6434e9-2375-4a7a-9b60-da4914aad913",
  ABR_LAST_CHECKED: "3af37b97-95a6-4c01-9b12-6eda508141e4",
  ACN: "da2010bc-0ee9-4ce9-ae3b-e612ec2e28a3",
  ACTIONS: "80da7ad3-50aa-4eea-b039-b23122ab7b9a",
  ALTERNATIVES: "b88562a3-aad8-4ce7-bd76-a4016e433163",
  // BRANCH_NAME: "12d3711d-ae2b-4471-a0b7-805f8a78c507",
  BUSINESS_USERS_SUMMARY: "d2830158-e6fd-4ea0-bfff-2be50c58097d",
  BUYER_WAYS_SUMMARY: "83c53095-844c-4217-bd12-5f8d5fb96716",
  BPAY_CRN: "add2bedb-19dc-4720-b4b5-39e3222d2ca3",
  BRANDS: "158395b9-f20b-4c47-ac95-e0cf232e9a6b",
  CREATED: "8f23f06b-1fec-468f-b56b-3557c9c06660",
  DEFAULT_DESTINATIONS: "d4113500-555b-4f2a-9444-0548752eefd1",
  DEFAULT_VENDOR_SPLITS: "d5aba74c-70f5-46c7-ba2b-55e837b681e7",
  EMAIL_RECIPIENTS_SUMMARY: "96b20407-a181-4be6-86ae-f6772cb7f884",
  HAS_DEBTOR_INSURANCE: "506e732c-1247-449f-99bc-99d878116dcf",
  HAS_TRANSIT_INSURANCE: "654e5cf2-9826-40d2-a680-dfb885112c0e",
  ID: "b05483b2-1cd1-45d5-b32b-aa778fd25cc1",
  INTEREST: "cfe18b49-db55-408a-a1ba-28f9a3a825ba",
  IS_ACTIVE: "c4abd0d7-72d6-4f10-8835-e8d5bc6009c6",
  IS_BUYER: "d32354ae-9fb5-48ef-aa35-7df925f2510b",
  IS_DEPLOYMENT: "e88e9168-6049-4b3b-bff7-027d16b97064",
  IS_GST_REGISTERED: "b067e076-3f9b-467a-9979-4818cc679b64",
  IS_HOBBY_FARMER: "23038514-2839-40fe-b5ec-c656ad29c13c",
  IS_LIVESTOCK_AGENT: "83892ebd-10ab-48d1-b983-1def748092a4",
  IS_MEAT_PROCESSOR_COMMERCIAL_BUYER: "2b5e2ecc-9d9f-4ede-95a8-d34b8bb59966",
  IS_PUBLISHED: "68fc99ce-5028-4913-b38e-3939df252c0f",
  IS_TEMPORARY: "05940dc5-2d8d-43f0-8997-24488b250fd2",
  IS_TOP_BUYER_CATTLE: "6041c791-df12-44d9-87a8-9ffd97400f86",
  IS_TOP_BUYER_SHEEP: "641483d8-e007-420f-b021-4da920239482",
  IS_TRANSPORTER: "9b8cac65-b204-4ba3-a2d2-682315a9c138",
  IS_VENDOR: "0717c47e-d198-4fdc-9871-00c0f9af24c2",
  LAST_MODIFIED: "ff36eaec-52d0-4c60-aaa4-d52cbf26b209",
  LAST_REVIEWED: "e95ebc34-169e-4b6d-b2eb-0de987770b76",
  NAME: "75442c04-6c7b-43eb-9a42-8ee5422e150e",
  OVERRIDE_INTEREST_SETTINGS: "afbbf603-b50a-4f0b-b720-a9360aa9da0c",
  PHONE_NUMBERS_SUMMARY: "59dc1bd1-6e7c-4d98-9c6d-232b7b9c36f4",
  PK: "a7b47414-8dcb-4950-9a5b-4babceec7b55",
  PROPERTIES_SUMMARY: "f5944e31-e62b-4b9f-a9a3-48069b341bf0",
  PUBLIC_DISPLAY_NAME: "b49d4af0-9d57-48c3-ba19-205ae239bf07",
  PRIMARY_CONTACT_EMAIL: "6dda816b-50c3-4dd4-8e2b-6d63c4f94a51",
  RELATIONSHIPS: "ddcc7ff2-9c01-4a71-b3b3-467ccf8fbb58",
  SHORT_CODE: "f4270f71-6e01-445b-94c9-8dbd2732024a",
  SHORT_CODE_AUCTIONS_PLUS: "3435869a-5f23-4c76-8036-f8713127fd4a",
  SHORT_CODE_SALEYARD: "05d7f901-b63f-4aaa-89bd-d040c10941dc",
  SLUG: "0d75f420-8dc7-4c47-8d19-b237f6db77c1",
  TRADING_NAME: "14a20642-6644-4956-b625-025c799844df",
  XERO_INTEGRATION_BUSINESS: "6487f4bf-cdd6-4c9f-a0c3-ea90de3d4e18",
  VENDOR_COMMISSION: "577d7fc8-8672-4d50-83d6-e348a42ff211",
  MYOB_CUSTOMER_CARD_ID: "bbaf69a2-3942-473b-90d6-43f6457d2dbd",
  MYOB_SUPPLIER_CARD_ID: "2176bf6b-2e03-4539-bc8a-2bc8b801733a",
};

export const BusinessBankDetailsColId = {
  ACCOUNT_NUMBER: "90e3c5ed-fdcd-477e-b813-4d45209c2fb0",
  ACCOUNT_NAME: "06f3f760-1c6b-4a5e-b3cd-709dd1ee4498",
  BANKING_DETAILS: "8e166090-7db5-439b-a320-a92f6774f367",
  BANKING_DETAILS_GROUP: "2e286a32-a76f-477b-b4d4-81a2f3d11f12",
  BSB: "71cdf755-d145-44e2-923a-a72ee8916c11",
  CHEQUE_PAYABLE_TO: "1e7ef2f3-e628-4c9b-ab1b-648eb7298f21",
  HAS_BANK_DETAILS: "09d0d7ee-a73b-46a1-808f-bc0565eed3a9",
  HAS_CHEQUE_DETAILS: "05f746a6-5197-4071-86ae-6c9f9defdd1d",
  PAYID: "47e7e9d9-a53f-4a6c-a40d-3d1a85d29337",
};

export const BusinessBankDetailsColumnDef = {
  [BusinessBankDetailsColId.ACCOUNT_NUMBER]: {
    colId: BusinessBankDetailsColId.ACCOUNT_NUMBER,
    columnGroupShow: "open",
    headerName: "Account Number",
    field: "business.bankingDetails.accountNumber",
    width: 90,
    hideOnMobile: true,
  },
  [BusinessBankDetailsColId.BSB]: {
    colId: BusinessBankDetailsColId.BSB,
    columnGroupShow: "open",
    headerName: "BSB",
    field: "business.bankingDetails.bsb",
    width: 90,
    hideOnMobile: true,
  },
  [BusinessBankDetailsColId.ACCOUNT_NAME]: {
    colId: BusinessBankDetailsColId.ACCOUNT_NAME,
    columnGroupShow: "open",
    headerName: "Account Name",
    field: "business.bankingDetails.accountName",
    width: 90,
    hideOnMobile: true,
  },
  [BusinessBankDetailsColId.PAYID]: {
    colId: BusinessBankDetailsColId.PAYID,
    columnGroupShow: "open",
    headerName: "PayID",
    field: "business.bankingDetails.payid",
    width: 90,
    hideOnMobile: true,
  },
  [BusinessBankDetailsColId.CHEQUE_PAYABLE_TO]: {
    colId: BusinessBankDetailsColId.CHEQUE_PAYABLE_TO,
    columnGroupShow: "open",
    headerName: "Cheque Payable To",
    field: "business.bankingDetails.chequePayableTo",
    width: 90,
    hideOnMobile: true,
  },
  [BusinessBankDetailsColId.HAS_BANK_DETAILS]: {
    colId: BusinessBankDetailsColId.HAS_BANK_DETAILS,
    field: "business.bankingDetails",
    headerName: "Has Banking Details",
    cellRenderer: nullableBooleanRenderer,
    valueGetter: getters.isDefinedGetter,
    width: 120,
    hideOnMobile: true,
  },

  [BusinessBankDetailsColId.HAS_CHEQUE_DETAILS]: {
    colId: BusinessBankDetailsColId.HAS_CHEQUE_DETAILS,
    field: "business.bankingDetails.chequePayableTo",
    enableRowGroup: true,
    headerName: "Has Cheque Details?",
    cellRenderer: nullableBooleanRenderer,
    valueGetter: getters.isDefinedGetter,
    width: 120,
    hideOnMobile: true,
  },
};

export const BusinessBankDetailsGroupDef = {
  [BusinessBankDetailsColId.BANKING_DETAILS_GROUP]: {
    headerName: "Banking Details",
    marryChildren: true,
    hideOnMobile: true,
    groupId: BusinessBankDetailsColId.BANKING_DETAILS_GROUP,
    children: [
      BusinessBankDetailsColumnDef[BusinessBankDetailsColId.HAS_BANK_DETAILS],
      BusinessBankDetailsColumnDef[BusinessBankDetailsColId.ACCOUNT_NUMBER],
      BusinessBankDetailsColumnDef[BusinessBankDetailsColId.BSB],
      BusinessBankDetailsColumnDef[BusinessBankDetailsColId.ACCOUNT_NAME],
      BusinessBankDetailsColumnDef[BusinessBankDetailsColId.PAYID],
      BusinessBankDetailsColumnDef[BusinessBankDetailsColId.HAS_CHEQUE_DETAILS],
      BusinessBankDetailsColumnDef[BusinessBankDetailsColId.CHEQUE_PAYABLE_TO],
    ],
  },
};

export const BusinessAddressColId = {
  ADDRESS_GROUP: "1b2cb9f5-0117-449b-9568-0a964b911ae5",
  SUMMARY: "8d2763a5-ebbc-4bcd-b29b-a7f236f30c1b",
  CONTACT: "ab559767-923b-41ab-b94d-5d43a9413198",
  COUNTRY: "3e8d2405-b06c-4e9b-bdb8-b0251b9c3178",
  LOCALITY: "d4b39cc6-5666-4cfa-91ac-da640841ee37",
  LONGITUDE: "22f2e27c-d531-450f-bbe1-dea148ce70ed",
  LATITUDE: "6cbfaa4c-c0bf-4cd7-a13d-568d8d0c0e39",
  POSTAL_CODE: "183e41c6-f1da-47b6-9d79-058e0eb205b8",
  STATE: "7c9f629f-812b-4871-bca6-5ac9ff2a72bc",
  STREET_NAME: "b76057ca-7f0d-46fe-91ae-c57466f791c2",
  STREET_NUMBER: "c7c716bf-727a-464a-92b3-bf4eea63c523",
};
export const BusinessAddressColumnDef = {
  [BusinessAddressColId.SUMMARY]: {
    colId: BusinessAddressColId.SUMMARY,
    headerName: "Address",
    field: "business.address",
    width: 200,
    valueGetter: getters.addressGetter,
    hideOnMobile: true,
  },
  [BusinessAddressColId.CONTACT]: {
    colId: BusinessAddressColId.CONTACT,
    headerName: "Contact/Place Name",
    field: "business.address.addressingInformation",
    width: 90,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
  [BusinessAddressColId.STREET_NUMBER]: {
    colId: BusinessAddressColId.STREET_NUMBER,
    headerName: "Street Number",
    field: "business.address.streetNumber",
    width: 90,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
  [BusinessAddressColId.STREET_NAME]: {
    colId: BusinessAddressColId.STREET_NAME,
    headerName: "Street Name",
    field: "business.address.route",
    width: 90,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
  [BusinessAddressColId.LOCALITY]: {
    colId: BusinessAddressColId.LOCALITY,
    headerName: "Locality",
    field: "business.address.locality",
    width: 90,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
  [BusinessAddressColId.STATE]: {
    colId: BusinessAddressColId.STATE,
    headerName: "State",
    field: "business.address.state",
    width: 90,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
  [BusinessAddressColId.POSTAL_CODE]: {
    colId: BusinessAddressColId.POSTAL_CODE,
    headerName: "Postal Code",
    field: "business.address.postalCode",
    width: 90,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
  [BusinessAddressColId.COUNTRY]: {
    colId: BusinessAddressColId.COUNTRY,
    headerName: "Country",
    hide: true,
    field: "business.address.country",
    width: 90,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
  [BusinessAddressColId.LONGITUDE]: {
    colId: BusinessAddressColId.LONGITUDE,
    headerName: "Latitude",
    hide: true,
    field: "business.address.latitude",
    filter: "agNumberColumnFilter",
    type: "numericColumn",
    width: 90,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
  [BusinessAddressColId.LATITUDE]: {
    colId: BusinessAddressColId.LATITUDE,
    headerName: "Longitude",
    hide: true,
    field: "business.address.longitude",
    filter: "agNumberColumnFilter",
    type: "numericColumn",
    width: 90,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
};

export const BusinessInterestColId = {
  INTEREST_RATE: "afc0dbf7-b4fa-4398-9f15-e987c14e808b",
  INTEREST_THRESHOLD_DAYS: "1f1ab1d0-1e0a-46d4-b1a4-38bcb83f576f",
};

const BusinessInterestColumnDef = {
  [BusinessInterestColId.INTEREST_RATE]: {
    colId: BusinessInterestColId.INTEREST_RATE,
    headerName: "Interest Rate",
    field: "interestSettings.0.interestRate",
    width: 120,
    columnGroupShow: "open",
    hideOnMobile: true,
    type: ColumnType.PERCENTAGE,
  },
  [BusinessInterestColId.INTEREST_THRESHOLD_DAYS]: {
    colId: BusinessInterestColId.INTEREST_THRESHOLD_DAYS,
    headerName: "Interest Threshold Days",
    field: "interestSettings.0.daysOverdueThreshold",
    width: 160,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
};

export const BusinessAddressGroupDef = {
  [BusinessAddressColId.ADDRESS_GROUP]: {
    headerName: "Address",
    marryChildren: true,
    groupId: BusinessAddressColId.ADDRESS_GROUP,
    children: [
      BusinessAddressColumnDef[BusinessAddressColId.SUMMARY],
      BusinessAddressColumnDef[BusinessAddressColId.STREET_NUMBER],
      BusinessAddressColumnDef[BusinessAddressColId.STREET_NAME],
      BusinessAddressColumnDef[BusinessAddressColId.LOCALITY],
      BusinessAddressColumnDef[BusinessAddressColId.STATE],
      BusinessAddressColumnDef[BusinessAddressColId.POSTAL_CODE],
      BusinessAddressColumnDef[BusinessAddressColId.COUNTRY],
      BusinessAddressColumnDef[BusinessAddressColId.LONGITUDE],
      BusinessAddressColumnDef[BusinessAddressColId.LATITUDE],
    ],
  },
};

export const BusinessColumnDef = {
  [BusinessColId.NAME]: {
    colId: BusinessColId.NAME,
    field: "business.name",
    headerName: "Name",
    cellRenderer: BusinessRenderer,
    cellRendererParams: {
      businessKey: "business.id",
    },
    enableRowGroup: true,
    showOnMobile: true,
    flexOnMobile: true,
  },
  [BusinessColId.HAS_DEBTOR_INSURANCE]: {
    colId: BusinessColId.HAS_DEBTOR_INSURANCE,
    field: "business.hasDebtorInsurance",
    headerName: "Has Debtor Insurance",
    cellRenderer: nullableBooleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.HAS_TRANSIT_INSURANCE]: {
    colId: BusinessColId.HAS_TRANSIT_INSURANCE,
    field: "business.hasTransitInsurance",
    headerName: "Has Transit Insurance",
    cellRenderer: nullableBooleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_TEMPORARY]: {
    colId: BusinessColId.IS_TEMPORARY,
    field: "business.isTemporary",
    headerName: "Is Temporary",
    cellRenderer: booleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_TOP_BUYER_CATTLE]: {
    colId: BusinessColId.IS_TOP_BUYER_CATTLE,
    field: "business.isTopBuyerCattle",
    headerName: "Is Top Buyer Cattle",
    cellRenderer: nullableBooleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_TOP_BUYER_SHEEP]: {
    colId: BusinessColId.IS_TOP_BUYER_SHEEP,
    field: "business.isTopBuyerSheep",
    headerName: "Is Top Buyer Sheep",
    cellRenderer: nullableBooleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },

  [BusinessColId.PHONE_NUMBERS_SUMMARY]: {
    colId: BusinessColId.PHONE_NUMBERS_SUMMARY,
    field: "business.emailRecipients",
    headerName: "Phone Number",
    valueGetter: getters.emailRecipientPhoneGetter,

    cellRenderer: ({ value }) => {
      if (Array.isArray(value) && value.length > 0) {
        return value.map((phoneNumber, index) => {
          return (
            <React.Fragment key={`${phoneNumber}_${index}`}>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              {index < value.length - 1 ? ", " : ""}
            </React.Fragment>
          );
        });
      }
      return null;
    },
    hideOnMobile: true,
  },
  [BusinessColId.BRANDS]: {
    colId: BusinessColId.BRANDS,
    field: "business.brands",
    headerName: "Brands",
    hideOnMobile: true,
  },

  [BusinessColId.PUBLIC_DISPLAY_NAME]: {
    colId: BusinessColId.PUBLIC_DISPLAY_NAME,
    field: "business.publicDisplayName",
    headerName: "Public Display Name",
    hideOnMobile: true,
  },
  [BusinessColId.SHORT_CODE]: {
    colId: BusinessColId.SHORT_CODE,
    field: "business.shortCode",
    headerName: "Short Code",
    hideOnMobile: true,
  },
  [BusinessColId.SLUG]: {
    colId: BusinessColId.SLUG,
    field: "business.slug",
    headerName: "Slug",
    hide: true,
    hideOnMobile: true,
  },
  [BusinessColId.XERO_INTEGRATION_BUSINESS]: {
    colId: BusinessColId.XERO_INTEGRATION_BUSINESS,
    field: "business.xeroIntegrationBusiness",
    headerName: "Xero Business",
    width: 140,
    cellRenderer: IntegrationBusinessRenderer,
    valueGetter: getters.xeroBusinessGetter,
    cellRendererParams: {
      complainIfMissing: true,
      useLogoAndTooltip: true,
      integrationType: IntegrationTypes.Xero,
      businessKeys: ["business", null],
    },
    hideOnMobile: true,
  },
  [BusinessColId.SHORT_CODE_AUCTIONS_PLUS]: {
    colId: BusinessColId.SHORT_CODE_AUCTIONS_PLUS,
    field: "business.shortCodeAuctionsPlus",
    headerName: "Short Code Auctions Plus",
    hideOnMobile: true,
  },
  [BusinessColId.SHORT_CODE_SALEYARD]: {
    colId: BusinessColId.SHORT_CODE_SALEYARD,
    field: "business.shortCodeSaleyard",
    headerName: "Short Code Saleyard",
    hideOnMobile: true,
  },
  [BusinessColId.LAST_MODIFIED]: {
    colId: BusinessColId.LAST_MODIFIED,
    field: "business.lastModified",
    headerName: "Last Modified",
    type: ColumnType.DATE_TIME,
    hideOnMobile: true,
  },
  [BusinessColId.LAST_REVIEWED]: {
    colId: BusinessColId.LAST_REVIEWED,
    field: "business.lastReviewed",
    headerName: "Last Reviewed",
    type: ColumnType.DATE_TIME,
    hideOnMobile: true,
  },
  [BusinessColId.ABN]: {
    colId: BusinessColId.ABN,
    field: "business.abn",
    headerName: "ABN",
    hideOnMobile: true,
  },
  [BusinessColId.ABR_LAST_CHECKED]: {
    colId: BusinessColId.ABR_LAST_CHECKED,
    field: "business.abrLastChecked",
    headerName: "ABR Last Checked",
    type: ColumnType.DATE_TIME,
    hideOnMobile: true,
  },
  [BusinessColId.ACN]: {
    colId: BusinessColId.ACN,
    field: "business.acn",
    headerName: "ACN",
    hideOnMobile: true,
  },
  [BusinessColId.IS_BUYER]: {
    colId: BusinessColId.IS_BUYER,
    field: "business.isBuyer",
    headerName: "Is Buyer",
    cellRenderer: nullableBooleanRenderer,
    width: 90,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_GST_REGISTERED]: {
    colId: BusinessColId.IS_GST_REGISTERED,
    field: "business.isGSTRegistered",
    headerName: "Is GST Registered",
    cellRenderer: nullableBooleanRenderer,
    width: 120,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_HOBBY_FARMER]: {
    colId: BusinessColId.IS_HOBBY_FARMER,
    field: "business.isHobbyFarmer",
    headerName: "Is Hobby Farmer",
    cellRenderer: nullableBooleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_MEAT_PROCESSOR_COMMERCIAL_BUYER]: {
    colId: BusinessColId.IS_MEAT_PROCESSOR_COMMERCIAL_BUYER,
    field: "business.isMeatProcessorCommercialBuyer",
    headerName: "Is Meat Processor Commercial Buyer",
    cellRenderer: nullableBooleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_PUBLISHED]: {
    colId: BusinessColId.IS_PUBLISHED,
    field: "business.isPublished",
    headerName: "Is Published",
    cellRenderer: nullableBooleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_TRANSPORTER]: {
    colId: BusinessColId.IS_TRANSPORTER,
    field: "business.isTransporter",
    headerName: "Is Transporter",
    cellRenderer: nullableBooleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_VENDOR]: {
    colId: BusinessColId.IS_VENDOR,
    field: "business.isVendor",
    headerName: "Is Vendor",
    cellRenderer: nullableBooleanRenderer,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.TRADING_NAME]: {
    colId: BusinessColId.TRADING_NAME,
    field: "business.tradingName",
    headerName: "Trading Name",
    hideOnMobile: true,
  },
  [BusinessColId.ACTIONS]: {
    colId: BusinessColId.ACTIONS,
    headerName: ACTION_COLUMN_NAME,
    pinned: "right",
    cellRenderer: BusinessListActionColumn,
    width: 82,
    hideOnMobile: true,
  },
  [BusinessColId.RELATIONSHIPS]: {
    colId: BusinessColId.RELATIONSHIPS,
    field: "business.relationships",
    enableRowGroup: true,
    headerName: "Relationships",
    valueGetter: getters.businessRelationshipGetter,
    cellRenderer: BusinessRelationshipRenderer,
    hideOnMobile: true,
  },
  [BusinessColId.IS_DEPLOYMENT]: {
    colId: BusinessColId.IS_DEPLOYMENT,
    headerName: "Is Agency",
    field: "business.businessDeploymentId",
    cellRenderer: nullableBooleanRenderer,
    valueGetter: getters.hasValueGetter,
    width: 110,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_LIVESTOCK_AGENT]: {
    colId: BusinessColId.IS_LIVESTOCK_AGENT,
    headerName: "Is Agent",
    field: "business.livestockAgencyId",
    cellRenderer: nullableBooleanRenderer,
    valueGetter: getters.hasValueGetter,
    width: 110,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.IS_ACTIVE]: {
    colId: BusinessColId.IS_ACTIVE,
    headerName: "Is Active",
    field: "business.isActive",
    cellRenderer: nullableBooleanRenderer,
    width: 110,
    hideOnMobile: true,
    enableRowGroup: true,
  },
  [BusinessColId.BUYER_WAYS_SUMMARY]: {
    colId: BusinessColId.BUYER_WAYS_SUMMARY,
    headerName: "Buyer Ways",
    field: "business.buyerWays",
    width: 200,
    valueGetter: ({ data, colDef }) => {
      const businessBuyerWays = get(data, colDef.field) || [];
      if (Array.isArray(businessBuyerWays) && businessBuyerWays.length > 0) {
        return businessBuyerWays.map(businessBuyerWay => businessBuyerWay.name);
      }
      return null;
    },

    hideOnMobile: true,
  },

  [BusinessColId.PROPERTIES_SUMMARY]: {
    colId: BusinessColId.PROPERTIES_SUMMARY,
    headerName: "Properties",
    field: "business.properties",
    width: 90,
    filterValueGetter: ({ data, colDef, context }) => {
      // The values we want to filter on include the pic NAME, as well as just the PICs, so re-pull
      // the raw data out of params.
      const businessProperties = get(data, colDef.field) || [];
      if (Array.isArray(businessProperties) && businessProperties.length > 0) {
        return businessProperties.reduce((result, businessProperty) => {
          const property = context.propertyByIdLookup[businessProperty.id];
          if (property) {
            result.push(property.PIC);
            if (businessProperty.name === null) {
              result.push(businessProperty.name);
            } else if (property.name) {
              result.push(property.name);
            }
          }
          return result;
        }, []);
      }
      return null;
    },
    valueGetter: ({ data, colDef, context }) => {
      // We're just interested in the PIC - leave the name out.
      const value = get(data, colDef.field) || [];
      if (Array.isArray(value) && value.length > 0) {
        return value
          .map(businessProperty => {
            return context.propertyByIdLookup[businessProperty.id]?.PIC;
          })
          .filter(Boolean);
      }
      return null;
    },

    hideOnMobile: true,
  },
  [BusinessColId.EMAIL_RECIPIENTS_SUMMARY]: {
    colId: BusinessColId.EMAIL_RECIPIENTS_SUMMARY,
    headerName: "Email Recipients",
    field: "business.emailRecipients",
    width: 90,
    filterValueGetter: params => {
      const emailRecipients = get(params.data, params.colDef.field) || [];
      if (Array.isArray(emailRecipients) && emailRecipients.length > 0) {
        const results = emailRecipients.reduce((acc, emailRecipient) => {
          acc.push(emailRecipient.email);
          acc.push(
            [emailRecipient.firstName, emailRecipient.lastName]
              .filter(Boolean)
              .join(" "),
          );
          return acc;
        }, []);
        return results;
      }
      return null;
    },
    valueGetter: params => {
      const emailRecipients = get(params.data, params.colDef.field) || [];
      return emailRecipients.map(emailRecipient => {
        const displayName = [emailRecipient.firstName, emailRecipient.lastName]
          .filter(Boolean)
          .join(" ");
        return `${displayName} <${emailRecipient.email}>`;
      });
    },
    cellRenderer: ({ value }) => {
      if (Array.isArray(value) && value.length > 0) {
        return value.map((rfc5322NameAddr, index) => {
          return (
            <React.Fragment key={`emailRecipient${index}`}>
              <a href={`mailto:${rfc5322NameAddr}`}>{rfc5322NameAddr}</a>
              {index < value.length - 1 ? ", " : ""}
            </React.Fragment>
          );
        });
      }
      return null;
    },
    hideOnMobile: true,
  },
  [BusinessColId.OVERRIDE_INTEREST_SETTINGS]: {
    colId: BusinessColId.OVERRIDE_INTEREST_SETTINGS,
    headerName: "Using Business Interest Settings?",
    field: "business.overrideInterestSettings",
    type: ColumnType.BOOLEAN,
    hideOnMobile: true,
  },
  [BusinessColId.CREATED]: {
    colId: BusinessColId.CREATED,
    headerName: "Created",
    field: "business.created",
    type: ColumnType.DATE_TIME,
    hideOnMobile: true,
  },
  [BusinessColId.INTEREST]: {
    colId: BusinessColId.INTEREST,
    headerName: "Interest",
    children: [
      BusinessInterestColumnDef[BusinessInterestColId.INTEREST_RATE],
      BusinessInterestColumnDef[BusinessInterestColId.INTEREST_THRESHOLD_DAYS],
    ],
    hideOnMobile: true,
  },
  [BusinessColId.VENDOR_COMMISSION]: {
    colId: BusinessColId.VENDOR_COMMISSION,
    headerName: "Vendor Commission",
    field: "vendorCommissionBands",
    // if multiple commissions rates have been set up,
    // show formatted as “4% / 6% / 12%“ in ascending order of percentages
    // (they can edit business to see more details)
    valueGetter: ({ data, colDef }) => {
      const value = get(data, colDef.field) || [];
      if (Array.isArray(value) && value.length > 0) {
        return sortBy(value, "commissionValue")
          .map(vendorCommissionBand => {
            const amountText =
              vendorCommissionBand.commissionType ===
              VendorCommissionBandTypes.DollarsPerHead
                ? `$${getDollarPriceStringFromCents(
                    vendorCommissionBand.commissionValue,
                  )} per Hd`
                : `${formatPercentage(vendorCommissionBand.commissionValue)}`;
            return amountText;
          })
          .join(" / ");
      }
      return "";
    },
    type: ColumnType.STRING,
    hideOnMobile: true,
  },
  [BusinessColId.BPAY_CRN]: {
    colId: BusinessColId.BPAY_CRN,
    headerName: "BPAY CRN",
    field: "business.bpayCustomerReferenceNumber",
    type: ColumnType.STRING,
    hideOnMobile: true,
  },
  [BusinessColId.DEFAULT_VENDOR_SPLITS]: {
    colId: BusinessColId.DEFAULT_VENDOR_SPLITS,
    headerName: "Default Vendor Splits",
    valueGetter: BusinessDefaultVendorSplitGetter,
    hideOnMobile: true,
  },
  [BusinessColId.MYOB_CUSTOMER_CARD_ID]: {
    colId: BusinessColId.MYOB_CUSTOMER_CARD_ID,
    field: "business.myobCustomerCardId",
    headerName: "MYOB Customer Card Id",
    hideOnMobile: true,
  },
  [BusinessColId.MYOB_SUPPLIER_CARD_ID]: {
    colId: BusinessColId.MYOB_CUSTOMER_CARD_ID,
    field: "business.myobSupplierCardId",
    headerName: "MYOB Supplier Card Id",
    hideOnMobile: true,
  },
  [BusinessColId.PRIMARY_CONTACT_EMAIL]: {
    colId: BusinessColId.PRIMARY_CONTACT_EMAIL,
    field: "business.primaryContactEmail",
    headerName: "Primary Email Address",
    hideOnMobile: true,
    valueGetter: ({ data, colDef }) => {
      if (!data) {
        return null;
      }

      const primaryContactEmail = get(data, colDef.field) || null;

      const { business: { emailRecipients = [] } = {} } = data;

      const primaryEmailAddressIsAvailableRecipientEmail = emailRecipients.some(
        recipient => recipient.email === primaryContactEmail,
      );

      if (
        !primaryEmailAddressIsAvailableRecipientEmail &&
        emailRecipients.length
      ) {
        return WARNING_COLUMN_VALUE;
      }

      return primaryContactEmail;
    },
    cellRenderer: ({ value: primaryContactEmail, data }) => {
      if (!primaryContactEmail) {
        return null;
      }

      const { business: { id } = {} } = data;

      const onClickEdit = () =>
        openEditBusinessModal(id, null, BusinessModalSection.EMAIL_RECIPIENTS);

      if (primaryContactEmail === WARNING_COLUMN_VALUE) {
        return (
          <Tooltip title="None of the Email Recipients for this Business have been made the Primary Email Address">
            <IconClickableColumn onClick={onClickEdit}>
              <FaIcon icon={faExclamationCircle} color="warning" />
            </IconClickableColumn>
          </Tooltip>
        );
      }

      return (
        <a href={`mailto:${primaryContactEmail}`}>{primaryContactEmail}</a>
      );
    },
  },
};
