import React from "react";

import { Button, SecondaryButton } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import { DialogActions } from "components/MaterialDialog";

export default function CreateSaveCancelFooter(props) {
  const { isReadOnly, isCreate, onClose } = props;

  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  useSubmitHandler(false, setIsSubmitEnabled);

  return (
    <DialogActions>
      <SecondaryButton onClick={onClose}>Back</SecondaryButton>
      <Button type="submit" disabled={isReadOnly || !isSubmitEnabled}>
        {isCreate === null ? "Create" : "Save"}
      </Button>
    </DialogActions>
  );
}
