import { PlacardVersions, PageSize } from "constants/pdf";

import { createAndOpenPdf } from "lib/pdf";

import { getContentForPlacardV1 } from "./Placard";
import { getContentForPlacardV2 } from "./PlacardV2";
import { getContentForPlacardV3 } from "./PlacardV3";
import { getContentForPlacardV4 } from "./PlacardV4";
import { getContentForPlacardV5 } from "./PlacardV5";

export const preparePlacard = (
  saleLots,
  sale,
  deploymentIdBySaleLotIdLookup,
  deployments,
  vendorDeploymentBusinessBySaleLotIdLookup,
  isVendorBredBySaleLotIdLookup,
  suppressBack,
  includeWeight,
  size = PageSize.A3,
  orientation = "landscape",
  version,
  deploymentPlacardLogos = [],
) => {
  const contentDefintion = {
    pageMargins: [12, 12, 12, 12],
    content: null,
  };

  switch (version) {
    case PlacardVersions.V2:
      contentDefintion.content = getContentForPlacardV2(
        saleLots,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
      );
      break;
    case PlacardVersions.V3:
      contentDefintion.pageMargins = [12, 80, 12, 40];
      contentDefintion.content = getContentForPlacardV3(
        saleLots,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
        includeWeight,
        size,
      );
      break;
    case PlacardVersions.V4:
      contentDefintion.content = getContentForPlacardV4(
        saleLots,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
      );
      break;
    case PlacardVersions.V5:
      contentDefintion.content = getContentForPlacardV5(
        saleLots,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
      );
      break;
    default:
      contentDefintion.content = getContentForPlacardV1(
        saleLots,
        deploymentIdBySaleLotIdLookup,
        deployments,
        vendorDeploymentBusinessBySaleLotIdLookup,
        isVendorBredBySaleLotIdLookup,
      );
  }

  const deploymentLogoImages = sale.deployment_sales.reduce(
    (acc, deploymentSale) => {
      acc[`logo_${deploymentSale.deployment_id}`] =
        deploymentPlacardLogos[deploymentSale.deployment_id];
      return acc;
    },
    {},
  );

  const pdfDefinition = {
    ...contentDefintion,
    pageSize: size,
    pageOrientation: orientation,
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 11,
      },
      contentStyle: {
        fontSize: 40,
        overflow: "hidden",
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin: [15, 30, 15, 15],
      },
    },
    images: deploymentLogoImages,
  };
  createAndOpenPdf(pdfDefinition, undefined, suppressBack);
};
