import React from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { TitleText } from "components/Text";

import { AgGridTables } from "constants/aggrid";
import { ApiModel } from "constants/loading";
import { DeploymentPermissions } from "constants/permissions";

import { openEditBusinessModal } from "lib/navigation";

import {
  getActiveLivestockAgentDeployment,
  getIsLivestockAgent,
  getProperties,
  selectDefaultVendorSplitByBusinessIdLookup,
} from "selectors";

import { selectBusinessesAggridData } from "selectors/aggrid/businesses";

import {
  useDebounceSelector,
  useHasDeploymentPermission,
  useHasExportBusinessesPermission,
  useIsMobile,
} from "hooks";

import { columnsDefs } from "./BusinessListColumns";

const getRowId = params => params.data.business.id;

const additionalActions = [
  {
    default: true,
    title: "Add Business",
    isDisabled: false,
    onClick: () => openEditBusinessModal(),
    icon: faPlus,
    dataTour: "addBusiness",
  },
];
export function BusinessTable() {
  const rowData = useDebounceSelector(selectBusinessesAggridData);
  const propertyByIdLookup = useSelector(getProperties);

  const defaultSplitByBusinessIdLookup = useSelector(
    selectDefaultVendorSplitByBusinessIdLookup,
  );

  const hasExportCsvPermissions = useHasExportBusinessesPermission();

  const context = { defaultSplitByBusinessIdLookup, propertyByIdLookup };

  const isMobile = useIsMobile();

  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const hasInterestFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureInterest,
  );

  const activeDeployment = useSelector(getActiveLivestockAgentDeployment);

  const isInterestEnabled =
    hasInterestFeature && activeDeployment.deploymentSettings?.enableInterest;

  const hasMyobExportsFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureMyobIntegration,
  );

  return (
    <Column fullHeight>
      <Box p={2}>
        <TitleText>Businesses</TitleText>
      </Box>
      <AgGridTable
        additionalActions={additionalActions}
        columnDefs={columnsDefs(
          isLivestockAgent,
          isInterestEnabled,
          hasMyobExportsFeature,
        )}
        context={context}
        getRowId={getRowId}
        rowData={rowData}
        tableName={AgGridTables.BUSINESSES}
        showTextFilter
        cacheQuickFilter
        hasExportCsvPermissions={hasExportCsvPermissions}
        suppressCellSelection={!!isMobile}
        hideSideBar={!!isMobile}
        hideSavedViews={!!isMobile}
      />
    </Column>
  );
}

const LoadingWrapper = () => (
  <WaitForSync
    requiredData={[
      ApiModel.BUSINESSES,
      ApiModel.AGENCIES,
      ApiModel.DEPLOYMENTS,
    ]}
  >
    <BusinessTable />
  </WaitForSync>
);

export default LoadingWrapper;
