import React from "react";

import {
  faFilePdf,
  faFileWord,
  faFileVideo,
  faFile,
  faFileAudio,
  faFileArchive,
  faFileExcel,
  faFileImage,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

const StyledIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => `
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.zeta}px;
  &:hover {
    color: ${theme.colors.primaryHighlight};
    }
  `}
`;

export const FilenameText = styled.span`
  ${({ theme }) => `
    font-size: ${theme.fontSizes.alpha}px;
    color: ${theme.colors.gray44};
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc((100vw / 3) - (${theme.space[2]}px * 2));
`}
`;

const extensionToIcon = {
  pdf: faFilePdf,

  docx: faFileWord,
  doc: faFileWord,

  mp4: faFileVideo,
  mpg: faFileVideo,
  mov: faFileVideo,
  webm: faFileVideo,

  zip: faFileArchive,
  gz: faFileArchive,

  xls: faFileExcel,
  xlsx: faFileExcel,

  wav: faFileAudio,
  mp3: faFileAudio,

  tif: faFileImage,
  tiff: faFileImage,
};

// Try and deduce an fa Icon from the extension - MimeType would be nicer, maybe, but we don't have it handy
export const FileIcon = ({ fileExtension }) => (
  <StyledIcon icon={extensionToIcon?.[fileExtension] || faFile} />
);
