import React, { memo, useCallback } from "react";

import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ModalTableWrapper } from "components/AgGrid/TableWrapper";
import { Button, SecondaryButton } from "components/Form/Button";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  ZoomyDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { ApiModel } from "constants/loading";

import {
  closeAllHashModals,
  openEditPaymentModal,
  returnToLocation,
} from "lib/navigation";

import { getBusinessById } from "selectors";

import { BusinessPaymentsTable } from "./BusinessPaymentsTable";

function BusinessPaymentsModalTitleComponent(props) {
  const { businessId, onClose } = props;

  const businessName = useSelector(
    state => getBusinessById(businessId)(state)?.name,
  );
  return (
    <DialogTitle onClose={onClose}>Payments from {businessName}</DialogTitle>
  );
}

const BusinessPaymentsModalTitle = memo(BusinessPaymentsModalTitleComponent);
BusinessPaymentsModalTitle.propTypes = {
  onClose: PropTypes.func.isRequired,
  businessId: PropTypes.string.isRequired,
};

export function BusinessPaymentsModal(props) {
  const { businessId, returnTo } = props;
  const onClose = useCallback(() => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }, [returnTo]);

  function onClickRecordPayment() {
    openEditPaymentModal(null, {
      presets: {
        fromBusinessId: businessId,
      },
    });
  }

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="md" fullWidth>
      <WaitForSync requiredData={[ApiModel.BUSINESSES]}>
        <BusinessPaymentsModalTitle onClose={onClose} businessId={businessId} />
        <DialogContent dividers>
          <Grid spacing={2} container>
            <Grid item>
              <Button data-tour="recordPayment" onClick={onClickRecordPayment}>
                Record Payment
              </Button>
            </Grid>
          </Grid>
          <ModalTableWrapper>
            <WaitForSync requiredData={[ApiModel.PAYMENTS]}>
              <BusinessPaymentsTable businessId={businessId} />
            </WaitForSync>
          </ModalTableWrapper>
        </DialogContent>
        <DialogActions>
          <SecondaryButton data-tour="close" type="button" onClick={onClose}>
            Close
          </SecondaryButton>
        </DialogActions>
      </WaitForSync>
    </ZoomyDialog>
  );
}
