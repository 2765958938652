import React from "react";
import { Table, TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { getProgenyDisplayCount } from "lib/saleLot";
import { useSelector } from "react-redux";
import groupBy from "lodash/groupBy";
import { selectSaleProgressSaleLots } from "selectors";
import { formatDecimal } from "lib";
import { sortBy } from "lodash";

export interface SaleProgressSpeciesAttributeTableProps<ReduxState> {
  saleLotAttributeField: string;
  name: string;
  attributeLookupSelector: (
    state: ReduxState,
  ) => LookupSelectorResult<{ name: string }>;
}

export function SaleProgressTotalsAttributeTable<ReduxState>(
  props: SaleProgressSpeciesAttributeTableProps<ReduxState>,
): React.JSX.Element {
  const { attributeLookupSelector, name, saleLotAttributeField } = props;
  const saleLots = useSelector(selectSaleProgressSaleLots) || [];
  const attributeByIdLookup = useSelector(attributeLookupSelector);

  const saleLotsByAttribute = groupBy(saleLots, saleLotAttributeField);

  const attributeGroups = sortBy(
    Object.entries(saleLotsByAttribute),
    ([attributeId]) =>
      // Should the atribute id is not set the value will be null.
      // When null is used as the key in an object it is converted to a string, becoming "null"
      // Provide a sensible name for the attribute
      attributeId !== String(null)
        ? attributeByIdLookup[attributeId].name
        : "(Not data)",
  );

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{name}</TableCell>
          <TableCell align="right">Lots</TableCell>
          <TableCell align="right">Hd</TableCell>
          <TableCell align="right">Sold</TableCell>
          <TableCell align="right">Total $</TableCell>
          <TableCell align="right">Average $/Hd</TableCell>
          <TableCell align="right">Top $/Hd</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {attributeGroups.map(([attributeId, attributeGroupSaleLots]) => {
          const attributeName =
            attributeId !== "null"
              ? attributeByIdLookup[attributeId].name
              : "(No data)";
          const {
            headCount,
            soldHeadCount,
            progenyCount,
            soldProgenyCount,
            totalPriceCents,
          } = attributeGroupSaleLots.reduce(
            (acc, saleLot) => {
              acc.headCount += saleLot.quantity || 0;
              acc.soldHeadCount += saleLot.is_sold ? saleLot.quantity : 0;
              acc.progenyCount += saleLot.quantityProgeny || 0;
              acc.soldProgenyCount += saleLot.is_sold
                ? saleLot.quantityProgeny
                : 0;
              acc.totalPriceCents += saleLot.total_price_cents || 0;
              return acc;
            },
            {
              headCount: 0,
              soldHeadCount: 0,
              progenyCount: 0,
              soldProgenyCount: 0,
              totalPriceCents: 0,
            },
          );

          const averagePriceCents =
            soldHeadCount > 0 ? totalPriceCents / soldHeadCount : 0;
          const maxPriceCents = Math.max(
            ...attributeGroupSaleLots.map(
              saleLot =>
                (saleLot.total_price_cents || 0) / (saleLot.quantity || 1),
            ),
          );
          return (
            <TableRow key={attributeId}>
              <TableCell>{attributeName}</TableCell>
              <TableCell align="right">
                {attributeGroupSaleLots.length}
              </TableCell>
              <TableCell align="right">
                {`${headCount} ${getProgenyDisplayCount(progenyCount)}`}
              </TableCell>
              <TableCell align="right">{`${soldHeadCount} ${getProgenyDisplayCount(soldProgenyCount)}`}</TableCell>
              <TableCell align="right">
                {`${formatDecimal(totalPriceCents * 0.01)}`}
              </TableCell>
              <TableCell align="right">
                {`${formatDecimal(averagePriceCents * 0.01)}`}
              </TableCell>
              <TableCell align="right">
                {formatDecimal(maxPriceCents * 0.01)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
