import reduceReducers from "reduce-reducers";

import { LEDGER_ACCOUNT } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
} from "lib/reducers";
import { deserializeLedgerAccount } from "lib/serializers/ledgerAccounts";

const create = apiModelOfflineCreateReducer(LEDGER_ACCOUNT, {
  deserializer: deserializeLedgerAccount,
});

const fetch = apiModelOfflineFetchReducer(LEDGER_ACCOUNT, {
  deserializer: deserializeLedgerAccount,
});

const update = apiModelOfflineUpdateReducer(LEDGER_ACCOUNT, {
  deserializer: deserializeLedgerAccount,
});

const deleteReducer = apiModelOfflineDeleteReducer(LEDGER_ACCOUNT);

const ledgerAccountReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
);

export default ledgerAccountReducer;
