import reduceReducers from "reduce-reducers";

import { SALE_SUB_TYPE } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeSaleSubType } from "lib/serializers/saleSubTypes";

const fetch = apiModelOfflineFetchReducer(SALE_SUB_TYPE, {
  deserializer: deserializeSaleSubType,
});

const saleSubTypeReducer = reduceReducers(fetch);

export default saleSubTypeReducer;
