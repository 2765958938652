import React from "react";

import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useField } from "formik";
import { useSelector } from "react-redux";

import {
  DateInput,
  flattenOptions,
  Input,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";
import { HelpText } from "components/Form/FormikControls/HelpText";
import { Link } from "components/Link";

import { BillingNumberPrefixes } from "constants/billing";
import {
  BillingRunStatus,
  BillingRunStatusOptions,
} from "constants/billingRuns";

import { getSettingsRoute } from "lib/navigation";

import {
  getActiveLivestockAgentDeployment,
  selectRuleBookOptions,
  selectSaleOptionsV2,
} from "selectors";

export function BillingRunForm(props) {
  const { billingRunId, namespace: ns = "" } = props;
  const livestockSaleOptions = useSelector(selectSaleOptionsV2);

  const ruleBookOptions = useSelector(selectRuleBookOptions);

  const [{ value: status }] = useField(withNamespace(ns, "status"));
  const [_ignored0, _ignored2, { setValue: setName }] = useField(
    withNamespace(ns, "name"),
  );
  const [{ value: livestockSaleIds = [] }] = useField(
    withNamespace(ns, "livestockSaleIds"),
  );
  const [{ value: ruleBookId }] = useField(withNamespace(ns, "ruleBookId"));
  const [{ value: exportedAtDateTime }] = useField(
    withNamespace(ns, "exportedAtDateTime"),
  );

  function updateBillingRunName(livestockSaleIds, ruleBookId) {
    const ruleBookName =
      flattenOptions(ruleBookOptions).find(
        option => option.value === ruleBookId,
      )?.label || "";

    const livestockSaleNames = livestockSaleOptions
      .filter(option => livestockSaleIds.includes(option.value))
      .map(option => option.label)
      .join(", ");

    setName(`${ruleBookName} - ${livestockSaleNames}`);
  }

  function onAfterRuleBookIdChanged(ruleBookId) {
    updateBillingRunName(livestockSaleIds, ruleBookId);
  }

  function onAfterLivestockSaleIdsChanged(livestockSaleIds) {
    updateBillingRunName(livestockSaleIds, ruleBookId);
  }

  const isEdit = Boolean(billingRunId);

  const isRuleBookEnabled = !isEdit;
  const isStatusEnabled = isEdit;
  const isExportedAtVisible =
    isEdit &&
    status === BillingRunStatus.EXPORTED &&
    exportedAtDateTime !== null;

  const deployment = useSelector(getActiveLivestockAgentDeployment);
  const deploymentSettings = deployment.deploymentSettings || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SelectField
          disabled
          isMulti
          menuPortalTarget={document.body}
          name={withNamespace(ns, "livestockSaleIds")}
          label="Sale"
          onChangeExtra={onAfterLivestockSaleIdsChanged}
          options={livestockSaleOptions}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <SelectField
          disabled={!isRuleBookEnabled}
          label="Rulebook"
          menuPortalTarget={document.body}
          name={withNamespace(ns, "ruleBookId")}
          onChangeExtra={onAfterRuleBookIdChanged}
          options={ruleBookOptions}
          required
        />
      </Grid>
      <Grid item sm={12}>
        <Input
          label="Name"
          name={withNamespace(ns, "name")}
          required
          isClearable
        />
      </Grid>
      <Grid item xs={6}>
        <SelectField
          disabled={!isStatusEnabled}
          label="Status"
          name={withNamespace(ns, "status")}
          options={BillingRunStatusOptions}
          required
          menuPortalTarget={document.body}
        />
      </Grid>
      <Grid item xs={6}>
        {isExportedAtVisible && (
          <DateInput
            disabled
            name={withNamespace(ns, "exportedAtDateTime")}
            label="Exported At"
            type="number"
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={6}>
        <Input
          beforeSymbol={
            deploymentSettings.accountSalePrefix
              ? `${deploymentSettings.accountSalePrefix}-`
              : BillingNumberPrefixes.RCTI
          }
          label="First Account Sale/RCTI Number"
          name={withNamespace(ns, "nextAccountSaleNumber")}
          disabled
          tooltip="The first value to assign to an Account Sale/RCTI. Subsequent Account Sale/RCTIs will increment by 1."
          type="number"
          overrideValue={deploymentSettings.nextAccountSaleNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          beforeSymbol={
            deploymentSettings.invoicePrefix
              ? `${deploymentSettings.invoicePrefix}-`
              : BillingNumberPrefixes.Invoice
          }
          label="First Invoice Number"
          name={withNamespace(ns, "nextInvoiceNumber")}
          disabled
          overrideValue={deploymentSettings.nextInvoiceNumber}
          tooltip="The first value to assign to an Invoice. Subsequent Invoices will increment by 1."
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <HelpText>
          Document Prefixes & Numbers are managed in your{" "}
          <Link to={getSettingsRoute("Agency")}>Agency Settings</Link> page.
          Documents will be assigned incrementing numbers upon approval.
        </HelpText>
      </Grid>
    </Grid>
  );
}
