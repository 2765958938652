import React, { useState } from "react";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Divider, Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { CurrentUserAction } from "actions";

import Dialog from "components/Dialog";
import { Button, DeleteButton } from "components/Form";
import { Version } from "components/Version";

import { SIMULATE_REDUX_SAGA_ERROR } from "constants/actionTypes";

import { getIsStaff } from "selectors";

import { useToggle } from "hooks";
import { checkForSWUpdates } from "registerServiceWorker";

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.space[3]}px;
`;
const Description = styled(Paper)`
  padding: ${({ theme }) => theme.space[2]}px;
  font-size: 16px;
  font-weight: bold;
`;

const Text = styled.span(
  ({ theme }) => `
  font-size: ${theme.fontSizes.beta}px;
  font-weight: normal;
`,
);

const SystemSettings = () => {
  const isStaff = useSelector(getIsStaff);

  const [errorReported, setErrorReported] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [checkingForUpdates, setCheckingForUpdates] = useState(false);

  const dispatch = useDispatch();

  const reportError = () => {
    Sentry.captureException(new Error("Simulated Error"));
    setErrorReported(true);
    setTimeout(() => {
      setErrorReported(false);
    }, 3000);
  };

  const sendMessage = () => {
    Sentry.captureMessage("Simulated message");
    setMessageSent(true);
    setTimeout(() => {
      setMessageSent(false);
    }, 3000);
  };

  const checkForUpdates = () => {
    setCheckingForUpdates(true);
    checkForSWUpdates();
    setTimeout(() => {
      setCheckingForUpdates(false);
    }, 3000);
  };

  const simulateCatastrophicError = () => {
    throw new Error("Simulated Catastrophic Error");
  };

  const simulateReduxSagaError = () => {
    dispatch({ type: SIMULATE_REDUX_SAGA_ERROR });
  };

  const [showDeleteConfirm, toggleDeleteConfirm] = useToggle();

  const onClickDeleteAccount = () => {
    dispatch(CurrentUserAction.requestAccountDelete());
  };

  const warningLabel = (
    <>
      <Text>
        Once you confirm your request to delete this account, our support team
        will be in contact via email or phone.
      </Text>
      <br />
      <Text>
        Note: Due to the nature of this application, your account will not be
        deleted until our support team has contacted you and helped guide you
        through the process.
      </Text>
    </>
  );

  return (
    <Wrapper>
      {isStaff && (
        <>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Paper square elevation={2} p={2}>
                <Description>Simulate sending a Sentry message.</Description>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} container alignItems="center">
              <Button
                onClick={messageSent ? null : sendMessage}
                disabled={messageSent}
              >
                {messageSent ? "Sending message..." : "Send Sentry message"}
              </Button>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Paper square elevation={2} p={2}>
                <Description>Simulate sending a Sentry error.</Description>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} container alignItems="center">
              <Button
                onClick={errorReported ? null : reportError}
                disabled={errorReported}
              >
                {errorReported
                  ? "Sending error..."
                  : "Send simulated Sentry error"}
              </Button>
            </Grid>
          </Grid>
          <Divider />

          <Grid container>
            <Grid item xs={12} sm={8}>
              <Paper square elevation={2} p={2}>
                <Description>Simulate a browser error to Sentry.</Description>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} container alignItems="center">
              <Button onClick={simulateCatastrophicError}>
                Send browser error Sentry error
              </Button>
            </Grid>
          </Grid>

          <Divider />

          <Grid container>
            <Grid item xs={12} sm={8}>
              <Paper square elevation={2} p={2}>
                <Description>
                  Simulate a Redux Saga error to Sentry.
                </Description>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} container alignItems="center">
              <Button onClick={simulateReduxSagaError}>
                Send Redux Saga Sentry error
              </Button>
            </Grid>
          </Grid>

          <Divider />
        </>
      )}

      <Grid container>
        <Grid item xs={12} sm={8}>
          <Paper square elevation={2} p={2}>
            <Description>Check for updates</Description>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} container alignItems="center">
          <Button onClick={checkForUpdates} disabled={checkingForUpdates}>
            {" "}
            {checkingForUpdates ? "Checking for updates..." : "Check Now"}
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Paper square elevation={2} p={2}>
            <Description>Delete Account</Description>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} container alignItems="center">
          <DeleteButton color="lightCoral" onClick={toggleDeleteConfirm}>
            {" "}
            Delete Now
          </DeleteButton>
        </Grid>
      </Grid>

      <Version fullPage={false} />
      {showDeleteConfirm && (
        <Dialog
          closeSelf={toggleDeleteConfirm}
          title="Request Account Deletion"
          warningText={warningLabel}
          submitButtonText="Request Account Deletion"
          rejectButtonText="Cancel"
          handleSubmit={onClickDeleteAccount}
          icon={faTrash}
        />
      )}
    </Wrapper>
  );
};
export default SystemSettings;
