import reduceReducers from "reduce-reducers";

import { DRESSING_RANGE } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeDressingRanges } from "lib/serializers/dressingRanges";

const fetch = apiModelOfflineFetchReducer(DRESSING_RANGE, {
  deserializer: deserializeDressingRanges,
});

const dressingRangeReducer = reduceReducers(fetch);

export default dressingRangeReducer;
