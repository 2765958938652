import React from "react";

import { printPlacard } from "actions";

import { Accreditation } from "constants/draftingAttributes";
import { PageSize, PlacardVersions } from "constants/pdf";

import { toTitleCase } from "lib";

import { sortByRoundAndPen } from "lib/auctionPens";
import { blackBox, breakword, fontSize, getVendorName, tick } from "lib/pdf";
import { formatTime } from "lib/timeFormats";

import agrinousLogo from "img/AgriNous-logo-black-white-base64";

import { RenderPlacard } from "./RenderPlacard";

export const getContentForPlacardV1 = (
  saleLots,
  deploymentIdBySaleLotIdLookup,
  deployments,
  vendorDeploymentBusinessBySaleLotIdLookup,
  isVendorBredBySaleLotIdLookup,
) => {
  const contents = [];
  sortByRoundAndPen(saleLots).forEach((saleLot, index) => {
    const deploymentBusiness =
      vendorDeploymentBusinessBySaleLotIdLookup[saleLot.id];
    const vendorName = getVendorName(saleLot, deploymentBusiness);

    const deploymentId = deploymentIdBySaleLotIdLookup[saleLot.id];
    const deployment = deployments[deploymentId];

    contents.push([
      {
        layout: "noBorders",
        table: {
          widths: [835],
          body: [
            [""],
            [
              {
                text: " ",
              },
            ],
            [
              {
                image: `logo_${deployment.id}`,
                width: 800,
                height: 110,
                alignment: "center",
              },
            ],

            [
              {
                width: 90,
                text: breakword(vendorName, 24),
                fontSize: fontSize(vendorName),
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: saleLot.quantity ? saleLot.quantity : "-",
                fontSize: 125,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: saleLot?.auction_pen?.headCount
                  ? `of ${saleLot.auction_pen.headCount} in pen`
                  : "",
                fontSize: 25,
                alignment: "center",
              },
            ],
            [
              {
                text: `${saleLot.age_group_name} ${saleLot.sex_name}`,
                fontSize: 80,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: ` ${
                  saleLot.averageWeightFormattedRounded
                    ? ` @ ${saleLot.averageWeightFormattedRounded} Kg`
                    : ""
                }`,
                fontSize: 60,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: `${
                  saleLot.draftingAttributes?.publicDescription
                    ? saleLot.draftingAttributes.publicDescription.toUpperCase()
                    : ""
                }`,
                fontSize: 40,
                alignment: "center",
              },
            ],
          ],
        },
      },
      {
        columns: [
          {
            canvas: [
              {
                type: "rect",
                x: 20,
                y: 15,
                w: 1136,
                h: 790,
                lineWidth: 10,
                lineColor: deployment.corporateColor
                  ? deployment.corporateColor
                  : "#000000",
              },
            ],
            absolutePosition: { x: 4, y: 4 },
          },
          blackBox("Life Time \nStatus", 34, 30, deployment, 840, 845, 1050),
          tick(
            saleLot.EIDNLISDetails.lifetimeTraceable.length === 1 &&
              saleLot.EIDNLISDetails.lifetimeTraceable.has("Y"),
            1052,
            24,
          ),
          blackBox("Vendor \nBred", 144, 150, deployment, 840, 845, 1050),
          tick(isVendorBredBySaleLotIdLookup[saleLot.id] === true, 1052, 154),
          blackBox("PTIC", 254, 280, deployment, 840, 845, 1050),
          tick(
            saleLot.draftingAttributes?.accreditationPTIC === Accreditation.ALL,
            1052,
            264,
          ),
          blackBox("JBAS", 364, 390, deployment, 840, 845, 1050),
          tick(saleLot.draftingAttributes?.accreditationJBAS, 1052, 374),
          blackBox("PCAS \nEligible", 474, 480, deployment, 840, 845, 1050),
          tick(
            saleLot.draftingAttributes?.accreditationPCAS === Accreditation.ALL,
            1052,
            484,
          ),
          blackBox("Grass/ \nAntibiotic", 584, 590, deployment, 840, 845, 1050),
          tick(
            saleLot.draftingAttributes?.accreditationGrassFed ===
              Accreditation.ALL,
            1052,
            594,
          ),
          blackBox("Weaned", 694, 720, deployment, 840, 845, 1050),
          tick(saleLot.draftingAttributes?.isWeaned, 1052, 704),
          {
            image: `data:image/jpeg;base64,${agrinousLogo}`,
            width: 100,
            absolutePosition: { x: 30, y: 778 },
          },
          {
            text: `${toTitleCase(
              saleLot.livestockAgency ? saleLot.livestockAgency.name : "",
            )} Pen: ${saleLot.penName ? saleLot.penName : ""} ${
              saleLot.vendor.shortCode ? saleLot.vendor.shortCode : ""
            } ${saleLot.vendor_name ? saleLot.vendor_name : ""}  ${formatTime(
              Date.now(),
            )}    -    TO BE SOLD AS DESCRIBED BY THE VENDOR, PARTICULARS FOR INFORMATION ONLY BUT NOT GUARANTEED.`,
            fontSize: 8,
            absolutePosition: { x: 140, y: 788 },
            // Insert a page break if there is another page.
            pageBreak: saleLots.length > index + 1 ? "after" : undefined,
          },
        ],
      },
    ]);
  });
  return contents;
};

export const RenderPlacardV1 = () => {
  return (
    <RenderPlacard
      action={printPlacard}
      version={PlacardVersions.V3}
      size={PageSize.A4}
    />
  );
};
