import React, { useEffect, useState } from "react";

import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";

import {
  Container,
  LaneActions,
  LaneControlButton,
  LaneMover,
  LaneReset,
  LaneTitle,
  PenContainer,
  StyledPen,
} from "components/AuctionPens/PenOrder/PenOrderComponents";
import { Column, Row } from "components/Layout";
import { SortableItem } from "components/SortableItem";

import { getNumberWithOrdinal } from "lib";

import { getIsSaleyardAdmin, selectRoleDeploymentIds } from "selectors";

const pensAreEqual = (prevProps, nextProps) => {
  if (!isEqual(prevProps.pen, nextProps.pen)) {
    return false;
  }
};

const penText = auctionPen =>
  `${auctionPen.start_pen}${
    auctionPen.end_pen ? ` to ${auctionPen.end_pen}` : ""
  }`;

const Pen = React.memo(({ auctionPen, toggleSelection, isDisabled }) => {
  const onClick = () => toggleSelection(auctionPen);
  const { selected, touched, reordered, hasLots, deployments } = auctionPen;
  return (
    <StyledPen
      onClick={onClick}
      data-tour={penText(auctionPen)}
      selected={selected}
      touched={touched}
      reordered={reordered}
      hasLots={hasLots}
      deployments={deployments}
      disabled={isDisabled}
      title={`${
        deployments?.map(deployment => deployment.agencyName).join(", ") ||
        "Unassigned"
      } Pen`}
    >
      {penText(auctionPen)}
    </StyledPen>
  );
}, pensAreEqual);

export const Lane = ({
  auctionPens,
  selectedPens,
  toggleSelection,
  addToStart,
  addToEnd,
  moveLaneUp,
  moveLaneDown,
  movePen,
  resetLane,
  laneNumber,
  clearSelection,
}) => {
  const showButton = selectedPens.length > 0;
  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);
  const activeRoleDeploymentIds = useSelector(selectRoleDeploymentIds);
  const [sortableAuctionPens, setSortableAuctionPens] = useState(auctionPens);

  useEffect(() => {
    setSortableAuctionPens(auctionPens);
  }, [auctionPens]);

  // Only allow global sort options on this lane if:
  // - I'm a saleyard admin
  // OR
  // - I'm the only agent that has ANY stake in the pens in this lane (ie, I have a lot here, and no other agents do)
  const allowGlobalActions =
    isSaleyardAdmin ||
    (auctionPens.some(ap => ap.hasLots) &&
      auctionPens.every(
        ap =>
          ap.deployments.length === 0 ||
          (ap.deployments.length === 1 &&
            activeRoleDeploymentIds.includes(ap.deployments[0].id)),
      ));

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 20 } }),
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setSortableAuctionPens(sortableAuctionPens => {
        const oldIndex = sortableAuctionPens.findIndex(
          auctionPen => auctionPen.id === active.id,
        );
        const newIndex = sortableAuctionPens.findIndex(
          auctionPen => auctionPen.id === over.id,
        );
        movePen({ oldIndex, newIndex });
        return arrayMove(sortableAuctionPens, oldIndex, newIndex);
      });
    }
  }

  return (
    <Container>
      <LaneActions>
        <LaneMover
          hide={!moveLaneUp}
          onClick={allowGlobalActions ? moveLaneUp : null}
          style={{ alignItems: "flex-end" }}
          disabled={!allowGlobalActions}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </LaneMover>
        <LaneReset
          onClick={allowGlobalActions ? resetLane : null}
          disabled={!allowGlobalActions}
        >
          Reset
        </LaneReset>
        <LaneMover
          hide={!moveLaneDown}
          onClick={allowGlobalActions ? moveLaneDown : null}
          style={{ alignItems: "flex-start" }}
          disabled={!allowGlobalActions}
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </LaneMover>
      </LaneActions>

      <PenContainer data-tour="penContainer">
        <Column full>
          <LaneTitle>{getNumberWithOrdinal(laneNumber + 1)} Lane</LaneTitle>
          <Row flexWrap>
            {showButton && (
              <>
                <LaneControlButton show={showButton} onClick={addToStart}>
                  Move {selectedPens.map(penText).join(", ")} here
                </LaneControlButton>
                <LaneControlButton show={showButton} onClick={clearSelection}>
                  Clear Selection
                </LaneControlButton>
              </>
            )}

            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={sortableAuctionPens}
                strategy={rectSortingStrategy}
              >
                {sortableAuctionPens?.map((auctionPen, index) => {
                  const disabled = !(auctionPen.hasLots || isSaleyardAdmin);
                  return (
                    <SortableItem key={auctionPen.id} id={auctionPen.id}>
                      <Pen
                        index={index}
                        key={auctionPen.id}
                        auctionPen={auctionPen}
                        disabled={disabled}
                        isDisabled={disabled}
                        toggleSelection={toggleSelection}
                      />
                    </SortableItem>
                  );
                })}
              </SortableContext>
            </DndContext>
            {showButton && (
              <LaneControlButton show={showButton} onClick={addToEnd}>
                Move {selectedPens.map(penText).join(", ")} here
              </LaneControlButton>
            )}
          </Row>
        </Column>
      </PenContainer>
    </Container>
  );
};
