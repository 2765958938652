import React from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import { connect, useDispatch } from "react-redux";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import {
  addSaleLot,
  closeConfirmModal,
  confirmEraseSavedScans,
  confirmResetDevice,
  confirmSetDeviceTime,
  disconnectFromDevice,
  openConfirmModal,
  takeConsignments,
  updateNLISScanDetails,
  uploadNLISIdToSale,
  uploadScansAction,
} from "actions";

import { addAuctionPen } from "actions/auctionPens";

import { ConsignmentListItem } from "components/ConsignmentBobbyCalf";
import { NLISInput } from "components/Input";
import { Column } from "components/Layout";
import UnallocatedScansList from "components/UnallocatedScansList";

import { PenTypes } from "constants/auctionPens";
import { ConsignmentExceptions, SaleRoundName } from "constants/sale";
import { ScanningMode } from "constants/scanner";

import AnimalModal from "containers/AnimalModal";
import BobbyCalfConsignment from "containers/BobbyCalfConsignment";

import { neg1ToNulls, removeNulls, sortByKey } from "lib";

import { getAuctionPenPayload } from "lib/auctionPens";
import {
  openScanModal,
  openAnimalModal,
  openNLISTransferReceipt,
} from "lib/navigation";
import { saleIdOnly } from "lib/sale";
import { getScansForSaleLots } from "lib/saleLot";
import { getUnallocatedScanGroups } from "lib/scans";
import toast from "lib/toast";

import {
  currentSaleSelector,
  getConnectedDeviceId,
  getNestedConsignments,
  getNextAvailableAuctionPen,
  getSaleRoundIdByName,
  getScansByNLISId,
  selectScansBySaleLotIdLookup,
  getUnallocatedScans,
  getUnsoldDefaultBobbyCalfSaleLots,
} from "selectors";

const consignmentStatus = {
  BOOKED: "BOOKED-IN",
  SCANNED: "SCANNED",
};

const Take = ({
  addAuctionPen,
  addSaleLot,
  allScans,
  closeConfirmModal,
  confirmEraseSavedScans,
  confirmSetDeviceTime,
  confirmResetDevice,
  connectedDeviceId,
  consignments,
  disconnectFromDevice,
  history,
  match,
  nextAvailableAuctionPen,
  openConfirmModal,
  sale,
  saleRoundId,
  scansByNLISId,
  takeConsignments,
  unallocatedScans,
  unsoldDefaultBobbyCalfSaleLots,
  uploadNLISIdToSale,
  uploadScansAction,
}) => {
  const dispatch = useDispatch();

  consignments = sortByKey(consignments, "vendorName");

  const handleDisconnectClick = () => {
    disconnectFromDevice(connectedDeviceId);
  };

  const handleEditClick = id => {
    history.push(`${match.url}/edit/${id}`);
  };

  const handleViewClick = nlisFile => {
    const { id, transferType } = nlisFile;
    openNLISTransferReceipt(id, transferType);
  };

  const handleScanClick = id => {
    const saleLot =
      consignments.find(consignment => consignment.id === id).saleLots[0] || {};
    openScanModal(id, saleLot.id, false, null, PenTypes.SELLING, match.url);
  };

  const handleTakeConsignment = consignment => {
    takeConsignments(sale, [consignment.id]);
  };

  const handleScannerImport = () => {
    openScanModal(
      null,
      null,
      false,
      ScanningMode.DEVICE_IMPORT,
      PenTypes.SELLING,
      match.url,
    );
  };

  const handleTakeClick = consignment => {
    const { scannedPercentage, quantity } = consignment;

    if (
      consignment.exceptions &&
      (consignment.exceptions.includes(ConsignmentExceptions.NO_PIC) ||
        consignment.exceptions.includes(ConsignmentExceptions.NVD_ID_MISSING))
    ) {
      const message = ["Could not take"];
      if (
        consignment.exceptions.includes(ConsignmentExceptions.NO_PIC_EXCEPTION)
      ) {
        message.push("must assign a PIC to consignment");
      }
      if (
        consignment.exceptions.includes(ConsignmentExceptions.NVD_ID_MISSING)
      ) {
        message.push("must assign a NVD to consignment");
      }
      toast.error(message.join(", "));
    } else if (scannedPercentage === 1 || quantity === 0) {
      handleTakeConsignment(consignment);
    } else {
      openConfirmModal({
        title: "Are you sure?",
        message: "Head Count is less than the EID's, do you wish to continue?",
        actions: [
          {
            label: "No",
            secondary: true,
            onClick: () => {
              closeConfirmModal();
            },
          },
          {
            label: "Yes",
            onClick: () => {
              handleTakeConsignment(consignment);
              closeConfirmModal();
            },
          },
        ],
      });
    }
  };

  const handleFindNLIS = NLISID => {
    if (NLISID in scansByNLISId) {
      // Open the edit screen.
      openAnimalModal(match.url, scansByNLISId[NLISID].EID);
    } else {
      const scanArr = [
        {
          created: new Date().toISOString(),
          NLISID,
        },
      ];
      uploadNLISIdToSale(scanArr, sale);
    }
  };

  const createAuctionPen = () => {
    const newAuctionPenId = uuidv4();
    const auctionPenPayload = getAuctionPenPayload({
      start_pen_prefix: "",
      start_pen: nextAvailableAuctionPen,
      start_pen_suffix: "",
      end_pen: nextAvailableAuctionPen,
      end_pen_suffix: "",
      sale_round_id: saleRoundId,
    });
    addAuctionPen(auctionPenPayload, newAuctionPenId, [], true);
    return newAuctionPenId;
  };

  const handleAllocateScans = scans => {
    // Find the relevant sale lot within the auction pen.
    const consignmentId = scans[0].allocatableConsignments[0].id;
    const saleLotForConsignment = unsoldDefaultBobbyCalfSaleLots.find(
      sl => sl.consignmentId === consignmentId,
    );

    // If it doesn't exist, it needs to be created.
    let saleLotId = saleLotForConsignment ? saleLotForConsignment.id : null;
    if (!saleLotForConsignment) {
      // Get or create the auction pen id.
      const auctionPenId = unsoldDefaultBobbyCalfSaleLots.length
        ? unsoldDefaultBobbyCalfSaleLots[0].auction_pen_id
        : createAuctionPen();

      // Create the sale lot.
      saleLotId = uuidv4();
      const saleLotPayload = {
        created: new Date().toUTCString(),
        id: saleLotId,
        pricing_type_id: sale.pricing_type_id,
        livestocksale_id: sale.livestocksale_id,
        buyer_id: sale.default_buyer_id,
        destination_property_id: sale.default_property_id,
        auction_pen_id: auctionPenId,
        consignment_id: consignmentId,
        sale_round_id: saleRoundId,
        quantity: 1, // ?
      };
      addSaleLot(removeNulls(neg1ToNulls(saleLotPayload)), saleLotId);
    }
    uploadScansAction(scans, saleLotId);
  };

  const handleUpdateNLISScanDetails = scans => {
    dispatch(updateNLISScanDetails(scans));
  };

  const groupedUnallocatedScans = getUnallocatedScanGroups(unallocatedScans);

  return (
    <>
      <Wrapper id="take-screen">
        <PaddedContainer>
          <NLISInput
            handleDisconnectClick={handleDisconnectClick}
            connectedMessage="Scan now to capture/search EIDs"
            disconnectedMessage="Connect to scanner to capture EIDs"
            handleFindClick={handleFindNLIS}
            handleEraseSavedScans={confirmEraseSavedScans}
            handleUpdateDeviceTime={confirmSetDeviceTime}
            handleResetDevice={confirmResetDevice}
            handleScannerImport={handleScannerImport}
          />
        </PaddedContainer>
        <UnallocatedScansList
          groupedScans={groupedUnallocatedScans}
          handleAllocateScans={handleAllocateScans}
          updateNLISDetails={handleUpdateNLISScanDetails}
        />
        {consignments.length ? (
          <ConsignmentsWrapper>
            <PaddedContainer>
              <StyledHeading>Consignments</StyledHeading>
            </PaddedContainer>
            {consignments.map(c => {
              const { id } = c;
              const consignmentScans = getScansForSaleLots(
                c.saleLots,
                allScans,
              );

              return (
                <ConsignmentListItemStyled
                  key={c.id}
                  handleViewClick={() => handleViewClick(c.takeStatus[0])}
                  handleEditClick={() => handleEditClick(id)}
                  handleScanClick={() => handleScanClick(id)}
                  handleTakeClick={() => handleTakeClick(c)}
                  animals={consignmentScans}
                  consignmentStatus={consignmentStatus}
                  {...c}
                />
              );
            })}
          </ConsignmentsWrapper>
        ) : (
          <EmptyMessage>Or create a consignment to get started.</EmptyMessage>
        )}
      </Wrapper>
      <AddButton data-tour="add-consignment" to={`${match.url}/create`}>
        <StyledIcon icon={faPlus} /> Add Consignment
      </AddButton>
      <Switch>
        <Route path={`${match.path}/create`} component={BobbyCalfConsignment} />
        <Route
          path={`${match.path}/edit/:consignmentId`}
          component={BobbyCalfConsignment}
        />
        <Route path={`${match.path}/animal/:EID`} component={AnimalModal} />
      </Switch>
    </>
  );
};

const mapDispatchToProps = {
  disconnectFromDevice,
  openConfirmModal,
  closeConfirmModal,
  takeConsignments,
  uploadNLISIdToSale,
  uploadScansAction,
  addSaleLot,
  addAuctionPen,
  confirmEraseSavedScans,
  confirmSetDeviceTime,
  confirmResetDevice,
  updateNLISScanDetails,
};

const mapStateToProps = (state, props) => {
  const saleRoundId = getSaleRoundIdByName(state, SaleRoundName.BobbyCalf);
  return {
    connectedDeviceId: getConnectedDeviceId(state),
    consignments: getNestedConsignments(state, saleIdOnly(props)),
    allScans: selectScansBySaleLotIdLookup(state, saleIdOnly(props)),
    unallocatedScans: getUnallocatedScans(state, saleIdOnly(props)),
    sale: currentSaleSelector(state, saleIdOnly(props)),
    nextAvailableAuctionPen: getNextAvailableAuctionPen(
      state,
      saleIdOnly(props),
    ),
    unsoldDefaultBobbyCalfSaleLots: getUnsoldDefaultBobbyCalfSaleLots(
      state,
      saleIdOnly(props),
    ),
    scansByNLISId: getScansByNLISId(state, saleIdOnly(props)),
    saleRoundId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Take));

Take.propTypes = {
  addAuctionPen: PropTypes.func,
  addSaleLot: PropTypes.func,
  allScans: PropTypes.object,
  closeConfirmModal: PropTypes.func,
  confirmEraseSavedScans: PropTypes.func,
  confirmSetDeviceTime: PropTypes.func,
  confirmResetDevice: PropTypes.func,
  connectedDeviceId: PropTypes.object,
  consignments: PropTypes.array,
  disconnectFromDevice: PropTypes.func,
  nextAvailableAuctionPen: PropTypes.number,
  openConfirmModal: PropTypes.func,
  sale: PropTypes.object,
  saleRoundId: PropTypes.number,
  scansByNLISId: PropTypes.object,
  takeConsignments: PropTypes.func,
  unallocatedScans: PropTypes.array,
  unsoldDefaultBobbyCalfSaleLots: PropTypes.array,
  uploadNLISIdToSale: PropTypes.func,
  uploadScans: PropTypes.func,
};

const Wrapper = styled(Column).attrs({
  flexGrow: true,
})`
  padding: ${({ theme }) => theme.space[2]}px 0
    ${({ theme }) => theme.space[5]}px;
`;

const PaddedContainer = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]}px;
`;

const StyledHeading = styled.h2`
  margin: 0;
`;

const ConsignmentsWrapper = styled(Column).attrs({})`
  margin-top: ${({ theme }) => theme.space[3]}px;
`;

const ConsignmentListItemStyled = styled(ConsignmentListItem)`
  margin-top: ${({ theme }) => theme.space[2]}px;
`;

const EmptyMessage = styled.div`
  margin-top: ${({ theme }) => theme.space[5]}px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  flex: 1;
`;

const AddButton = styled(Link)`
  position: sticky;
  bottom: ${({ theme }) => theme.space[2]}px;
  left: ${({ theme }) => theme.space[2]}px;
  text-decoration: none;
  height: 54px;
  width: 191px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 27px;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.4);
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.beta + 2}px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme }) => theme.space[1]}px;
`;
