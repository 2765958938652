import React from "react";

import Badge from "components/Badge";

const DiffBadge = ({ quantityDelivered, quantity, quantityProgeny }) => {
  const totalQuantity = quantity + (quantityProgeny || 0);
  if (quantityDelivered - totalQuantity > 0) {
    return <Badge color="warning">+{quantityDelivered - totalQuantity}</Badge>;
  } else if (quantityDelivered - totalQuantity < 0) {
    return <Badge color="warning">{quantityDelivered - totalQuantity}</Badge>;
  } else {
    return null;
  }
};

export default DiffBadge;
