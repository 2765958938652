import React, { useCallback, useEffect } from "react";

import { get } from "lodash";
import { partition } from "lodash/collection";

import { bulkCreateSellPossessions, bulkCreateTakePossessions } from "actions";

import { Column } from "constants/columns";
import { NLISFileTypes } from "constants/nlis";

import { openNLISTransferReceipt } from "lib/navigation";

import { ClickableColumn } from "./columnStyles";

const fileTypeFieldMap = {
  [NLISFileTypes.NLISTakeFile]: "scan.latest_take_file_id",
  [NLISFileTypes.NLISSellFile]: "scan.latest_sell_file_id",
};

export const TransferType = {
  TAKE: "TAKE",
  SELL: "SELL",
};

function getIsTransferable(props) {
  const { api, node, transferType } = props;

  if (transferType === TransferType.TAKE) {
    return api.getValue(Column.IS_TAKEABLE, node);
  } else if (transferType === TransferType.SELL) {
    return api.getValue(Column.IS_SELLABLE, node);
  }
  return false;
}

export function NLISFileRenderer({ data, nlisFileType, context, value }) {
  const nlisFileIdField = fileTypeFieldMap[nlisFileType];
  const nlisFileId = get(data, nlisFileIdField);
  const openModal = useCallback(() => {
    openNLISTransferReceipt(nlisFileId, nlisFileType);
  }, [nlisFileId, nlisFileType]);

  if (!data) {
    return null;
  }

  if (!nlisFileId) {
    return "-";
  }

  if (value) {
    return <ClickableColumn onClick={openModal}>{value}</ClickableColumn>;
  }

  return (
    <ClickableColumn onClick={context.handleNLISRefresh}>
      Refresh
    </ClickableColumn>
  );
}

export function NLISRelatedTransaction({ data, context, value }) {
  if (!data || value === null) {
    return null;
  }

  if (value) {
    return (
      <ClickableColumn
        onClick={() => openNLISTransferReceipt(value.id, value.transferType)}
      >
        {value.transactionId}
      </ClickableColumn>
    );
  }

  return (
    <ClickableColumn onClick={context.handleNLISRefresh}>
      Refresh
    </ClickableColumn>
  );
}

export function NlisStatusGroupCellRenderer(props) {
  const { api, column, context, node, transferType, value } = props;

  const invokeRerender = useCallback(() => {
    api.refreshCells({
      columns: [column.colId],
      force: true,
    });
  }, [api, column]);

  useEffect(() => {
    api.addEventListener("rowDataChanged", invokeRerender);
    return () => {
      api.removeEventListener("rowDataChanged", invokeRerender);
    };
  }, [api, invokeRerender]);

  const { childrenAfterFilter } = node;
  const leafChildren = [];
  function pushLeafNodes(rowNode) {
    if (rowNode.childrenAfterFilter) {
      rowNode.childrenAfterFilter.forEach(pushLeafNodes);
    } else {
      leafChildren.push(rowNode);
    }
  }

  childrenAfterFilter.forEach(pushLeafNodes);

  const childrenCount = leafChildren.length;

  const [transferableNodes] = partition(leafChildren, rowNode =>
    getIsTransferable({ node: rowNode, api, transferType }),
  );

  function onClickTransfer() {
    const transferableEids = transferableNodes.map(rowNode =>
      api.getValue(Column.EID, rowNode),
    );
    if (transferType === TransferType.TAKE) {
      context.dispatch(bulkCreateTakePossessions(transferableEids));
    } else if (transferType === TransferType.SELL) {
      context.dispatch(bulkCreateSellPossessions(transferableEids));
    }
  }

  const transferableEidCount = transferableNodes.length;

  return (
    <span>
      {value}{" "}
      {transferableEidCount > 0 && (
        <input
          type="button"
          onClick={onClickTransfer}
          value={`Transfer ${transferableEidCount}${
            transferableEidCount !== childrenCount ? ` of ${childrenCount}` : ""
          }`}
        />
      )}
    </span>
  );
}

export function NlisStatusCellRenderer(props) {
  const { api, context, node, transferType, value } = props;

  const eid = api.getValue(Column.EID, node);

  function onClickTransfer() {
    if (transferType === TransferType.TAKE) {
      context.dispatch(bulkCreateTakePossessions([eid]));
    } else if (transferType === TransferType.SELL) {
      context.dispatch(bulkCreateSellPossessions([eid]));
    }
  }

  const isTransferable = getIsTransferable(props);

  if (isTransferable) {
    return (
      <span>
        {value}{" "}
        <input type="button" onClick={onClickTransfer} value="Transfer" />
      </span>
    );
  }
  return <span>{value}</span>;
}

export function NlisStatusRenderer(props) {
  if (props.node.group) {
    return <NlisStatusGroupCellRenderer {...props} />;
  }
  return <NlisStatusCellRenderer {...props} />;
}
