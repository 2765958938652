import React from "react";

import styled from "styled-components/macro";

import { Row } from "components/Layout";

const BlueLine = styled.hr`
  border: 1px solid #4a90e2;
  width: 100%;
  max-width: 800px;
`;

const LaneLabel = styled.span`
  color: #4a90e2;
  font-size: 14px;
  text-transform: uppercase;
`;

const LaneStartRow = styled(Row)`
  margin-bottom: 12px;
`;

export const LaneStartLine = () => (
  <LaneStartRow alignCenter justifyCenter>
    <LaneLabel>Lane Start</LaneLabel>
    <BlueLine />
  </LaneStartRow>
);
