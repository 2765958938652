import reduceReducers from "reduce-reducers";

import {
  CLOSE_CONFIRM_MODAL,
  MODAL_CONTEXT,
  OPEN_CONFIRM_MODAL,
  PEN_SCAN_LOT,
  SELL_FILE,
  TAKE_FILE,
} from "constants/actionTypes";
import { ModalTypes } from "constants/navigation";

const initialConfirmModal = {
  isOpen: false,
  modalProps: {
    actions: [],
  },
};
const initialSaleModal = {
  isOpen: false,
  saleId: null,
  values: {},
};

const initialState = {
  context: {
    confirmModal: { ...initialConfirmModal },
    saleModal: { ...initialSaleModal },
  },
};

export function modalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CONFIRM_MODAL: {
      Object.assign(state.context.confirmModal, {
        isOpen: true,
        modalProps: action.modalProps,
      });
      return state;
    }
    case CLOSE_CONFIRM_MODAL: {
      Object.assign(state.context.confirmModal, initialConfirmModal);
      return state;
    }

    case SELL_FILE.CREATE.REQUEST:
    case TAKE_FILE.CREATE.REQUEST: {
      Object.assign(state.context.confirmModal, {
        isOpen: true,
        modalProps: {
          title: "",
          timeOut: 3000,
          nlisSubmitting: true,
        },
      });
      return state;
    }

    default:
      return state;
  }
}

function advancedModalReducer(state, action) {
  switch (action.type) {
    case MODAL_CONTEXT.UPDATE.ACTION:
      const existingContext = state[action.modalType] || null;
      return {
        ...state,
        [action.modalType]: {
          ...existingContext,
          ...action.context,
        },
      };
    case MODAL_CONTEXT.SET.ACTION:
      return {
        ...state,
        [action.modalType]: action.context,
      };
    default:
      return state;
  }
}

function penScanModalReducer(state, action) {
  switch (action.type) {
    case PEN_SCAN_LOT.UPDATE_MAPPING.ACTION:
      const existingContext = state[ModalTypes.AllocatePenScanLots] || {
        mapping: {},
      };
      return {
        ...state,
        [ModalTypes.AllocatePenScanLots]: {
          ...existingContext,
          mapping: {
            ...existingContext.mapping,
            [action.penScanLotId]: [...action.mappingGroups],
          },
        },
      };
    default:
      return state;
  }
}

export default reduceReducers(
  modalReducer,
  advancedModalReducer,
  penScanModalReducer,
);
