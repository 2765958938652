import { getIn, setIn } from "formik";

import { withNamespace } from "components/Form/FormikControls";
/**
 * @typedef {Object} Address
 * @property {string} addressingInformation
 * @property {string} country
 * @property {string} googleMapsPlaceId
 * @property {number} [latitude]
 * @property {string} locality
 * @property {number} [longitude]
 * @property {string} postalCode
 * @property {string} route
 * @property {string} streetNumber
 * @property {string} state
 * @property {boolean} isPrefillAddress
 */

/**
 * @type {Address}
 */
export const INITIAL_ADDRESS_FORM_VALUES = {
  addressingInformation: "",
  country: "",
  googleMapsPlaceId: "",
  isPrefillAddress: false,
  latitude: null,
  locality: "",
  longitude: null,
  postalCode: "",
  route: "",
  streetNumber: "",
  state: "",
};

export const ADDRESS_FIELDS = [
  "addressingInformation",
  "country",
  "googleMapsPlaceId",
  "isPrefillAddress",
  "latitude",
  "locality",
  "longitude",
  "postalCode",
  "route",
  "streetNumber",
  "state",
];

function getUpperOrEmpty(val) {
  return typeof val === "string" ? val.toUpperCase() : "";
}

const formatPostalLocality = getUpperOrEmpty;
const formatPostalState = getUpperOrEmpty;
const formatPostalPostcode = getUpperOrEmpty;
const formatPostalCountry = getUpperOrEmpty;

export const AddressDisplayFormat = {
  SHORT: 0,
  POSTAL: 1,
  FULL: 2,
  CITY_STATE: 3,
};

/**
 *
 * @returns {string}
 * @param {string} [locality]
 * @param {string} [state]
 * @param {string} [postalCode]
 * @param {number} [format=AddressDisplayFormat.SHORT] Determines the output format of the address
 */
export function formatLocalityAddressLine(
  locality,
  state,
  postalCode,
  format = AddressDisplayFormat.SHORT,
) {
  let addressComponents = [];
  if (format === AddressDisplayFormat.SHORT) {
    addressComponents = [locality];
  } else if (format === AddressDisplayFormat.POSTAL) {
    addressComponents = [
      formatPostalLocality(locality),
      formatPostalState(state),
      formatPostalPostcode(postalCode),
    ];
  } else if (format === AddressDisplayFormat.FULL) {
    addressComponents = [locality, state, postalCode];
  } else if (format === AddressDisplayFormat.CITY_STATE) {
    addressComponents = [locality, state];
  }
  return addressComponents.filter(Boolean).join(", ");
}

/**
 *
 * @returns {string}
 * @param {string} [streetNumber]
 * @param {string} [route]
 */
export function formatRouteAddressLine(streetNumber, route) {
  return [streetNumber, route].filter(Boolean).join(" ");
}

/**
 *
 * @param {Address} address An Address object
 * @param {number} [format=AddressDisplayFormat.SHORT] Determines the output format of the address
 * @returns {string}
 */
export function formatAddress(address, format = AddressDisplayFormat.SHORT) {
  const separator = [
    AddressDisplayFormat.SHORT,
    AddressDisplayFormat.CITY_STATE,
  ].includes(format)
    ? ", "
    : "\r\n";
  const country = [
    AddressDisplayFormat.SHORT,
    AddressDisplayFormat.CITY_STATE,
  ].includes(format)
    ? ""
    : address.country;
  return [
    // TODO: If we can determine the address is NOT a street address, show the
    // addressingInformation which should be the company name
    // address.addressingInformation,
    format !== AddressDisplayFormat.CITY_STATE &&
      formatRouteAddressLine(address.streetNumber, address.route),
    formatLocalityAddressLine(
      address.locality,
      address.state,
      address.postalCode,
      format,
    ),
    format === AddressDisplayFormat.POSTAL
      ? formatPostalCountry(country)
      : country,
  ]
    .filter(Boolean)
    .join(separator);
}

/**
 * extracts an address from an arbitrary object
 * @param namespace the namespace path to extract the address values from
 * @param {object} values An Address object
 * @returns {Address}
 */
export function getAddressIn(namespace, values) {
  return ADDRESS_FIELDS.reduce((address, field) => {
    address[field] = getIn(values, withNamespace(namespace, field));
    return address;
  }, {});
}

/**
 * sets an address in an arbitrary object
 * @param namespace the namespace path to store the address values in
 * @param {object} values The destination object to set the values in
 * @param {Address} address An Address object
 */
export function setAddressIn(namespace, values, address) {
  return ADDRESS_FIELDS.reduce(
    (acc, field) => setIn(acc, withNamespace(namespace, field), address[field]),
    values,
  );
}

/**
 * @returns {Address}
 * @param placeId
 * @param {google.maps.places.PlaceResult} placeDetails
 */
export function convertPlaceDetailsToAddress(placeId, placeDetails) {
  const address = { ...INITIAL_ADDRESS_FORM_VALUES };
  placeDetails.address_components.forEach(addressComponent => {
    addressComponent.types.forEach(componentType => {
      switch (componentType) {
        case "sublocality": // https://code.google.com/p/gmaps-api-issues/issues/detail?id=635
        case "postal_town": // https://github.com/furious-luke/django-address/issues/114
          if (!address.locality) {
            address.locality = addressComponent.long_name;
          }
          break;
        case "locality": // For most cases, the City will be "locality"
        case "country":
        case "route":
          address[componentType] = addressComponent.long_name;
          break;
        case "street_number":
          address.streetNumber = addressComponent.long_name;
          break;
        case "postal_code":
          address.postalCode = addressComponent.long_name;
          break;
        case "administrative_area_level_1":
          address.state = addressComponent.short_name;
          break;
        default:
          break;
      }
    });
  });
  // When the type of Google Maps AutocompletePrediction is an "establisment" or other named place such as "place_of_worship" (as opposed to a point of interest et. al.)
  // the place name is the first two address component short names (street number and street name)
  // https://developers.google.com/maps/documentation/javascript/supported_types?hl=en#table2
  const placeName = `${placeDetails.address_components[0].short_name} ${placeDetails.address_components[1].short_name}`;

  // We want to check if place name is the same as our created one. if it isn't it's a special place name and should be used
  // E.g. Searching for 108a lowndes street will return the place name '108A Lowndes St' (with the address 108A Lowndes street)
  // while searching for agrinous will return the place name Agrinous (with the address 108A Lowndes street).
  address.addressingInformation =
    placeDetails.name === placeName ? "" : placeDetails.name;
  address.latitude = placeDetails.geometry.location.lat().toFixed(9);
  address.longitude = placeDetails.geometry.location.lng().toFixed(9);
  address.googleMapsPlaceId = placeId;
  address.isPrefillAddress = true;

  return address;
}

export const formatAllAddressFields = address => {
  return [
    address.addressingInformation,
    [address.streetNumber, address.route].filter(Boolean).join(" "),
    address.locality,
    address.state,
    address.postalCode,
  ]
    .filter(Boolean)
    .join(", ");
};
