import React, { memo } from "react";

import { isEqual } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { EIDListItemDetailed } from "containers/EIDListItem";

const MESSAGE_NO_EIDS = "There are no EIDs to display";

const CenteredText = styled.div`
  text-align: center;
`;

export function EIDListDetailedComponent(props) {
  const { eids, emptyMessage, largeView } = props;
  const message = emptyMessage !== undefined ? emptyMessage : MESSAGE_NO_EIDS;
  if ((Array.isArray(eids) && !eids.length) || !eids) {
    return <CenteredText>{message}</CenteredText>;
  }
  return eids.map(eid => (
    <EIDListItemDetailed key={eid} EID={eid} largeView={largeView} />
  ));
}

EIDListDetailedComponent.propTypes = {
  eids: PropTypes.arrayOf(PropTypes.string),
  emptyMessage: PropTypes.string,
  largeView: PropTypes.bool,
};

export default memo(
  EIDListDetailedComponent,
  (a, b) =>
    isEqual(a.eids, b.eids) &&
    a.emptyMessage === b.emptyMessage &&
    a.largeView === b.largeView,
);
