import React from "react";

import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import {
  NLISMessageGroupsAction,
  requestScansChanges,
  SightingAction,
} from "actions";

import TableWrapper from "components/AgGrid/TableWrapper";
import { SlimButton } from "components/Button";
import { Action, ActionIcon } from "components/Button/Actions";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";

import { ApiModel } from "constants/loading";

import { pluralize } from "lib/pluralize";

import {
  currentSaleSelector,
  getScans,
  selectSightableScans,
  selectSightingTransactions,
} from "selectors";

import { getIsFetchingNLISInformation } from "selectors/loading";

import { useMountEffect } from "hooks";

import { NLISFileGrid } from "./NLISFileGrid";

const columnState = [
  {
    colId: "transferType",
    hide: true,
  },
  {
    colId: "transactionId",
  },
  {
    colId: "status",
  },
  {
    colId: "eidStatus",
  },
  {
    colId: "vendorDisplay",
    hide: true,
  },
  { colId: "buyerDisplay", hide: true },
  {
    colId: "created",
  },
  {
    colId: "submitted",
  },
  {
    colId: "processed",
  },
  {
    colId: "relatedTransaction",
    hide: true,
  },
  {
    colId: "actions",
  },
];

const SightAllButton = () => {
  const dispatch = useDispatch();
  const sale = useSelector(currentSaleSelector);

  const doSighting = () => {
    dispatch(
      SightingAction.create({
        id: uuidv4(),
        livestockSaleId: sale.livestocksale_id,
        sightings: [],
      }),
    );
  };

  return <SlimButton onClick={doSighting}>Sight Now</SlimButton>;
};

export const NLISSightingFileInfo = React.memo(() => {
  const dispatch = useDispatch();
  const sale = useSelector(currentSaleSelector);
  const isFetching = useSelector(getIsFetchingNLISInformation);

  const scans = useSelector(getScans);
  const isSomeScans = !isEmpty(scans);
  const sightableScanCount = useSelector(selectSightableScans)?.length || 0;
  const rowData = useSelector(selectSightingTransactions);

  const handleRefresh = React.useCallback(() => {
    if (!isFetching) {
      dispatch(SightingAction.request());
      dispatch(requestScansChanges(sale));
      dispatch(NLISMessageGroupsAction.request());
    }
  }, [isFetching, sale, dispatch]);

  const sightText = `You currently have ${sightableScanCount} unsighted ${pluralize(
    "EID",
    sightableScanCount,
  )}`;

  useMountEffect(() => {
    // If we have no rows on mount, refresh from the backend.
    // (If we run this when rowData changes, it will continually refresh if there is actually no data.)
    if (rowData.length === 0) {
      handleRefresh();
    }
  });

  if (!isSomeScans) {
    return <MessageBox>No Scans yet.</MessageBox>;
  }

  return (
    <WaitForSync requiredData={[ApiModel.SCANS, ApiModel.PROPERTIES]}>
      <Row flexGrow justifyBetween>
        <Column fullWidth padding={2}>
          {sightableScanCount > 0 && (
            <Grid item xs={12}>
              <Alert severity="error" action={<SightAllButton />}>
                {sightText}
              </Alert>
            </Grid>
          )}

          {sightableScanCount === 0 && (
            <Grid item xs={12}>
              <Alert severity="success">
                All available EIDs have been sighted.
              </Alert>
            </Grid>
          )}
        </Column>

        <Action onClick={handleRefresh} disabled={isFetching}>
          <ActionIcon icon={faRedo} />
          Refresh
        </Action>
      </Row>

      <Column fullHeight>
        <TableWrapper>
          <NLISFileGrid rowData={rowData} columnState={columnState} />
        </TableWrapper>
      </Column>
    </WaitForSync>
  );
});
