import { MasterRuleStatus } from "constants/rules";

export const getMasterRuleStatus = (rule, masterRule) => {
  if (!masterRule) {
    return MasterRuleStatus.NOT_MANAGED;
  } else if (masterRule.is_deleted) {
    return MasterRuleStatus.DELETED;
  } else if (
    rule.content !== masterRule.content ||
    rule.unit_amount_raw_format !== masterRule.unit_amount_raw_format ||
    rule.quantity_raw_format !== masterRule.quantity_raw_format
  ) {
    return MasterRuleStatus.OUT_SYNC;
  } else {
    return MasterRuleStatus.IN_SYNC;
  }
};
