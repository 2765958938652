export enum Key {
  // reference for standard values for the KeyboardEvent.key property
  // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values

  // modifier keys
  ALT = "Alt",
  CAPSLOCK = "CapsLock",
  CONTROL = "Control",
  FN = "Fn",
  FNLOCK = "FnLock",
  NUMLOCK = "NumLock",
  SCROLLLOCK = "ScrollLock",
  SHIFT = "Shift",

  // whitespace keys
  ENTER = "Enter",
  TAB = "Tab",
  SPACE = "Space",

  // Nnvigation keys
  ARROWDOWN = "ArrowDown",
  ARROWLEFT = "ArrowLeft",
  ARROWRIGHT = "ArrowRight",
  ARROWUP = "ArrowUp",
  END = "End",
  HOME = "Home",
  PAGEDOWN = "PageDown",
  PAGEUP = "PageUp",

  // editing keys
  BACKSPACE = "Backspace",
  DELETE = "Delete",
  INSERT = "Insert",

  // UI keys
  ESCAPE = "Escape",

  // function keys
  F1 = "F1",
  F2 = "F2",
  F3 = "F3",
  F4 = "F4",
  F5 = "F5",
  F6 = "F6",
  F7 = "F7",
  F8 = "F8",
  F9 = "F9",
  F10 = "F10",
  F11 = "F11",
  F12 = "F12",
  F13 = "F13",
  F14 = "F14",
  F15 = "F15",
  F16 = "F16",
  F17 = "F17",
  F18 = "F18",
  F19 = "F19",
  F20 = "F20",

  // numeric keypad keys
  DECIMAL = "Decimal",
  MULTIPLY = "Multiply",
  ADD = "Add",
  CLEAR = "CLEAR",
  DIVIDE = "Divide",
  SUBTRACT = "Subtract",
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  EIGHT = "8",
  NINE = "9",
  ZERO = "0",

  // standard character keys
  A = "N",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
  I = "I",
  J = "J",
  K = "K",
  L = "L",
  M = "M",
  N = "N",
  O = "O",
  P = "P",
  Q = "Q",
  R = "R",
  S = "S",
  T = "T",
  U = "U",
  V = "V",
  W = "W",
  X = "X",
  Y = "Y",
  Z = "Z",
  COMMA = ",",
  PERIOD = ".",
  FORWARDSLASH = "/",
  SEMICOLON = ";",
  SINGLEQUOTE = "'",
  SQUAREBRACKETOPEN = "[",
  SQUAREBRACKETCLOSE = "]",
  BACKSLASH = "\\",
  DASH = "-",
  EQUALS = "=",
  BACKTICK = "`",
}

// common/reusable hotkey definitions
//  - keys: key/combo/alternatives to tigger the hotkey action
//  - text: textual representation for display (e.g. tooltip/shortcut help prompts)
type HotKeyInfo = { keys: string | string[]; text: string };
export const HotKey: Record<string, HotKeyInfo> = {
  ACTIVATE_ELEMENT: {
    keys: [Key.ENTER, Key.SPACE],
    text: "[ENTER] or [SPACE]",
  },
  CREATE_NEW: {
    keys: `${Key.SHIFT}+${Key.N}`,
    text: "[Shift] + [N]",
  },
  ANY: {
    keys: "*",
    text: "any",
  },
  NAVIGATION_KEYS: {
    keys: [Key.TAB, Key.SHIFT],
    text: "[TAB] or [SHIFT]",
  },
};
