// Create the map of api variable name to app variable name.
import { get, isPlainObject, transform } from "lodash";

const deepInvert = obj => {
  return transform(obj, (res, val, key) => {
    if (isPlainObject(val)) {
      res[key] = deepInvert(val);
    } else {
      res[val] = key;
    }
  });
};

export const buildSerializer = (
  serializeMap,
  modelName,
  nestedObjectMap = {},
  defaultValues = null,
) => {
  return (apiRequest, requestDefaultValues = null) =>
    apiRequest === null || apiRequest === undefined
      ? apiRequest
      : Object.entries(apiRequest).reduce(
          (acc, [key, value]) => {
            if (typeof serializeMap[key] === "object") {
              // Use the untransformed key
              const tk = get(deepInvert(nestedObjectMap), key, key);
              const subSerializer = buildSerializer(
                serializeMap[key],
                modelName,
                nestedObjectMap,
              );
              if (Array.isArray(value)) {
                acc[tk] = value.map(v => subSerializer(v));
              } else {
                acc[tk] = subSerializer(value);
              }
            } else {
              // TODO: Refactor everywhere that is using salelots with the unwanted snake_case
              // variable names then uncomment this.
              // if (!(key in serializeMap)) {
              //   console.warn(`${key} not in in ${modelName} map`);
              // }

              acc[serializeMap[key] || key] = value;
            }
            return acc;
          },
          { ...defaultValues, ...requestDefaultValues },
        );
};

export const buildDeserializer = (
  serializeMap,
  modelName,
  nestedObjectMap = {},
  defaultValues = null,
) => {
  const deserializeMap = deepInvert(serializeMap);

  return (apiResponse, responseDefaultValues = null) =>
    apiResponse === null || apiResponse === undefined
      ? apiResponse
      : Object.entries(apiResponse || {}).reduce(
          (acc, [key, value]) => {
            const tk = get(nestedObjectMap, key, key);
            if (typeof deserializeMap[tk] === "object") {
              // Call buildDeSerializer with serializeMap again so it doesn't double invert.
              const subSerializer = buildDeserializer(
                serializeMap[tk],
                modelName,
                nestedObjectMap,
              );
              if (Array.isArray(value)) {
                acc[tk] = value.map(v => subSerializer(v));
              } else {
                acc[tk] = subSerializer(value);
              }
            } else {
              // TODO: Refactor everywhere that is using salelots with the unwanted snake_case
              // variable names then uncomment this.
              // if (!(key in serializeMap)) {
              //   console.warn(`${key} not in in ${modelName} map`);
              // }
              acc[deserializeMap[tk] || tk] = value;
            }
            return acc;
          },
          { ...defaultValues, ...responseDefaultValues },
        );
};
