import { DELETE_OFFLINE_ACTION } from "constants/actionTypes";

export const deleteOfflineAction = transaction => ({
  type: DELETE_OFFLINE_ACTION,
  meta: {
    offline: {
      deleteTransaction: transaction,
    },
  },
});
