import { addDays, differenceInDays, isLeapYear } from "date-fns";
import { sortBy } from "lodash";

import {
  BillingDocumentIntegrationStatus,
  BillingDocumentStatus,
} from "constants/billingDocuments";

import { getDateFromISO8601DateString } from "./timeFormats";

export const INTEREST_NOT_CHARGABLE_DOCUMENT_STATUSES = [
  BillingDocumentStatus.PAID,
  BillingDocumentStatus.DRAFT,
  BillingDocumentStatus.EXPORT_ERROR,
];
export const INTEREST_NOT_CHARGABLE_INTEGRATION_DOCUMENT_STATUSES = [
  BillingDocumentIntegrationStatus.PAID,
  BillingDocumentIntegrationStatus.DRAFT,
  BillingDocumentIntegrationStatus.VOIDED,
  BillingDocumentIntegrationStatus.DELETED,
];

const calculateInterest = (
  calculatedChargeFromDate,
  chargeToDate,
  amountInCents,
  interestRate,
) => {
  // Calculate the daily interest rate
  const dailyInterestRate =
    interestRate / (isLeapYear(calculatedChargeFromDate) ? 366 : 365);

  // Caclulate how many days interest by getting the diff between our caluclated charge from and charge to date
  const daysToChargeInterest = differenceInDays(
    chargeToDate,
    calculatedChargeFromDate,
  );

  // Convert to dollars
  const amountInDollars = amountInCents / 100;
  // Calculate the total interest
  return dailyInterestRate * amountInDollars * daysToChargeInterest;
};

/**
 * Billing documents currently only contain pricing in dollars
 * This will return the interest in cents.
 */
export const calculateInterestChargeCentsAndDaysToCharge = (
  billingDocument,
  interestSettings = {},
  chargeFromDate = null,
  chargeToDate = new Date(),
  activeDeployment = {},
  saleSubType = {},
  paymentsById = {},
) => {
  const { daysOverdueThreshold = 0, interestRate } = interestSettings;
  const {
    dueDate,
    status,
    integrationStatus,
    totalAmountDollars,
    latestInterestChargeDate,
    paymentIds = [],
  } = billingDocument;

  const enableCompoundInterest =
    activeDeployment?.deploymentSettings?.enableCompoundInterest;

  const isFinancialSubType = saleSubType?.isFinancialSaleSubType;

  const formattedDueDate = addDays(getDateFromISO8601DateString(dueDate), 1);
  const dueFromDatePlusThreshold = addDays(
    formattedDueDate,
    daysOverdueThreshold,
  );

  const shouldInterestBeCharged =
    !INTEREST_NOT_CHARGABLE_DOCUMENT_STATUSES.includes(status) &&
    !INTEREST_NOT_CHARGABLE_INTEGRATION_DOCUMENT_STATUSES.includes(
      integrationStatus,
    ) &&
    new Date() > dueFromDatePlusThreshold && // if there current day is greater than the dueDate plus threshold it is overdue
    ((isFinancialSubType && enableCompoundInterest) || !isFinancialSubType); // If the sale subtype is finanical we only want to calculate interest for it if compound interest is enabled

  if (shouldInterestBeCharged) {
    // Now we know that interest should be charged we now need to determine when interest should be charged from.
    // By default it is just from the due date
    let calculatedChargeFromDate = formattedDueDate;

    const documentTotalCents = totalAmountDollars * 100;

    // If we have passed a chargeFrom Date in then we check if it is greater than the default calculatedChargeFromDate
    // This is to cover if the interest has become chargable this month and we only want to charge interest from then.
    if (chargeFromDate && chargeFromDate > calculatedChargeFromDate) {
      calculatedChargeFromDate = chargeFromDate;
    }

    // However if there was a interest charge date AND it is after our selected date range, we should override as that is now the 'due date'
    if (
      latestInterestChargeDate &&
      latestInterestChargeDate > calculatedChargeFromDate
    ) {
      calculatedChargeFromDate = getDateFromISO8601DateString(
        latestInterestChargeDate,
      );
    }
    // Caclulate how many total days of interest by getting the diff between our caluclated charge from and charge to date
    const daysToChargeInterest = differenceInDays(
      chargeToDate,
      calculatedChargeFromDate,
    );

    //  Get all the payment objects and sort by payment date
    const payments =
      sortBy(paymentIds.map(id => paymentsById[id]).filter(Boolean), [
        "paymentDate",
      ]) || [];

    const initialChargeToDate = chargeToDate;
    const initialChargeFromDate = calculatedChargeFromDate;

    // If there are payments and they are within the passed in charge dates,
    // go through each one, add to the total interest to charge,
    // and update the charge from date to be the payment date
    // If there are no payments, then we will calculate interest from the total owing on the document.
    let interestToCharge = 0;
    let totalPaymentsAmount = 0;
    if (payments.length > 0) {
      payments.forEach(payment => {
        const { paymentDate, totalAmountCents } = payment;
        const formattedPaymentDate = getDateFromISO8601DateString(paymentDate);
        // The only relevant payments that interest should be calculated are payments that are on or after the date range.
        // Any payment outside of this range should be skipped.
        // Payments made on the last day should also be skipped, and will be calculated futher down in totalUnpaidCents
        if (
          formattedPaymentDate >= calculatedChargeFromDate &&
          formattedPaymentDate < chargeToDate
        ) {
          const calculatedTotalAmountCents =
            documentTotalCents - totalPaymentsAmount;
          const paymentInterest = calculateInterest(
            calculatedChargeFromDate,
            formattedPaymentDate,
            calculatedTotalAmountCents,
            interestRate,
          );
          calculatedChargeFromDate = formattedPaymentDate;
          interestToCharge += paymentInterest;
          totalPaymentsAmount += totalAmountCents;
        } else if (formattedPaymentDate < chargeToDate) {
          totalPaymentsAmount += totalAmountCents;
        }
      });
    }

    // After all payments have been processed (or there is none), calculate interest for the remainder
    const totalUnpaidCents = documentTotalCents - totalPaymentsAmount;
    interestToCharge += calculateInterest(
      calculatedChargeFromDate,
      chargeToDate,
      totalUnpaidCents,
      interestRate,
    );

    // Return an object with the cents and days of interest.
    return {
      interestToChargeCents: interestToCharge * 100, // Return in cents
      daysOfInterest: daysToChargeInterest, // Include how many days of interest are being charged
      chargeFromDate: initialChargeFromDate,
      chargeToDate: initialChargeToDate,
    };
  }

  return { interestToChargeCents: 0, daysOfInterest: 0 };
};
