import React from "react";

import {
  faCheck,
  faExclamationCircle,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Tooltip } from "components/Form/FormikControls/Tooltip";

import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

import AgriNousActiveLogo from "img/AgriNousActiveLogo";

export function booleanTickRenderer(props) {
  const { value } = props;
  if (value === true) {
    return <FaIcon icon={faCheck} color="success" />;
  } else {
    return <FaIcon icon={faExclamationCircle} color="warning" />;
  }
}

export function nullableTickRenderer(props) {
  const { value } = props;
  if (typeof value === "boolean") {
    if (value === true) {
      return <FaIcon icon={faCheck} color="success" />;
    } else {
      return <FaIcon icon={faExclamationCircle} color="warning" />;
    }
  }
  return <FaIcon icon={faQuestionCircle} color="primary" />;
}

export function nullableBooleanRenderer(props) {
  const { value, colDef } = props;
  if (typeof value === "boolean") {
    if (value === true) {
      return <FaIcon icon={faCheck} color="success" />;
    } else {
      return <FaIcon icon={faTimes} color="error" />;
    }
  }

  if (colDef.showRowGroup) {
    return value;
  }

  return null;
}

const BooleanIcon = ({ value }) => {
  if (value === true || value === "true") {
    return <FaIcon icon={faCheck} color="success" />;
  } else if (value === false || value === "false") {
    return <FaIcon icon={faTimes} color="error" />;
  }
  return "";
};

export function booleanRenderer(props) {
  const {
    data,
    value,
    tooltipComponent: TooltipComponent,
    buildToolTipProps,
  } = props;
  // 'true' and 'false' come from grouped columns!

  if (TooltipComponent) {
    return (
      <Tooltip title={<TooltipComponent {...buildToolTipProps(data)} />}>
        <BooleanIcon value={value} />
      </Tooltip>
    );
  } else {
    return <BooleanIcon value={value} />;
  }
}

export function timeValueExistsRenderer(props) {
  // We have a value, and the value is good.
  const { value } = props;
  if (value !== null && value !== undefined) {
    return (
      <FaIcon
        icon={faCheck}
        color="success"
        title={formatUTCToLocalDateTimeString(value)}
      />
    );
  }
  return null;
}

export function invertedTimeValueExistsRenderer(props) {
  // We have a value, and the value is bad.
  const { value } = props;
  if (value !== null && value !== undefined) {
    return (
      <FaIcon
        icon={faTimes}
        color="error"
        title={formatUTCToLocalDateTimeString(value)}
      />
    );
  }
  return null;
}

export function valueExistsRenderer(props) {
  // We have a value, and the value is good.
  const { value } = props;
  if (value !== null && value !== undefined) {
    return (
      <FaIcon
        icon={faCheck}
        color="success"
        title={typeof value === "string" ? value : undefined}
      />
    );
  }
  return null;
}

export function AgriNousManagedRenderer({ value }) {
  return <AgriNousActiveLogo isActive={value} scale={0.4} size={25} />;
}
