import React from "react";

import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { addAuctionPen, bulkUpdateSaleLotsSerializer } from "actions";

import { Button, SecondaryButton } from "components/Form";
import { FullPageForm } from "components/Form/FormikControls/layout";
import { Column } from "components/Layout";
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  ZoomyDialog,
} from "components/MaterialDialog";
import PenningSaleLotForm from "components/PenningSaleLotForm";
import validationSchema from "components/PenningSaleLotForm/validationSchema";

import { PenTypes } from "constants/auctionPens";

import { getAuctionPenPayload } from "lib/auctionPens";
import { closeAllHashModals, returnToLocation } from "lib/navigation";
import { pluralize } from "lib/pluralize";

const DeliveryPenModal = props => {
  const { saleLotIds, returnTo } = props;

  const dispatch = useDispatch();

  const onClose = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const onSubmit = values => {
    const deliveryPenId = values.deliveryPen.start_pen ? uuidv4() : null;
    if (values.deliveryPen.start_pen) {
      dispatch(
        addAuctionPen(
          getAuctionPenPayload(values, "deliveryPen", PenTypes.DELIVERY),
          deliveryPenId,
        ),
      );
    }

    dispatch(
      bulkUpdateSaleLotsSerializer(
        saleLotIds.map(id => ({
          id,
          deliveryPenId,
        })),
      ),
    );

    onClose();
  };

  const initialValues = {
    deliveryPen: {
      penType: PenTypes.DELIVERY,
    },
  };

  return (
    <ZoomyDialog open onClose={onClose} scroll="paper">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchema)}
        onSubmit={onSubmit}
      >
        <FullPageForm>
          <DialogTitle onClose={onClose}>
            {`Set Delivery Pen for ${saleLotIds.length} ${pluralize(
              "lot",
              saleLotIds.length,
            )}`}
          </DialogTitle>
          <DialogContent dividers form>
            <Column padding={2}>
              <Grid container spacing={2}>
                <PenningSaleLotForm
                  label="Delivery Pen"
                  autoFocusStartPen
                  namespace="deliveryPen"
                  penType={PenTypes.DELIVERY}
                />
              </Grid>
            </Column>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={onClose}>Close</SecondaryButton>

            <Button type="submit">Update</Button>
          </DialogActions>
        </FullPageForm>
      </Formik>
    </ZoomyDialog>
  );
};

export default React.memo(
  DeliveryPenModal,
  (a, b) =>
    isEqual(a.saleLotIds, b.saleLotIds) &&
    a.fullScreen === b.fullScreen &&
    a.returnTo === b.returnTo,
);
