import React from "react";

import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { updateCurrentDraftingDecision } from "actions";

import Button from "components/Button";

import { formatWeightKg } from "lib";

import {
  getCurrentDraftingDecisionBySingleWeighById,
  getScanByEid,
  getSingleWeighById,
} from "selectors";

export const ReWeighAction = ({ singleWeighId }) => {
  const currentDecision = useSelector(
    getCurrentDraftingDecisionBySingleWeighById(singleWeighId),
  );

  const reweighWarningThreshold = useSelector(
    state => getSingleWeighById(singleWeighId)(state).reweighWarningThreshold,
  );

  const { eid, totalMassGrams } = currentDecision;

  const scanWeight = useSelector(
    state => getScanByEid(eid)(state).total_mass_grams,
  );
  const isScanWeightManual = useSelector(
    state => getScanByEid(eid)(state).is_manually_weighed,
  );

  const dispatch = useDispatch();

  const handleKeepExisting = () =>
    dispatch(
      updateCurrentDraftingDecision(singleWeighId, {
        // When using the existing weight, we need to replace the Current Drafting Decision
        // with the `total_mass_grams` and `is_manually_weighed` flags from the existing weighing event
        totalMassGrams: scanWeight,
        overrideWeight: isScanWeightManual,
        ignoreAlreadyWeighedCheck: true,
      }),
    );

  const handleUseNew = () =>
    dispatch(
      updateCurrentDraftingDecision(singleWeighId, {
        // When using the the new weight, the `total_mass_grams` and `is_manually_weighed`
        // flags already exist in the Current Drafting Decision, so they don't need to be updated.
        ignoreAlreadyWeighedCheck: true,
      }),
    );

  const relativeDiff = totalMassGrams - scanWeight;
  const absDiff = Math.abs(relativeDiff);

  const isOverThreshold =
    absDiff >= scanWeight * (reweighWarningThreshold / 100);

  const percentDiff = absDiff / scanWeight;

  const showReweighThresholdWarning =
    reweighWarningThreshold >= 0 && isOverThreshold;

  const isHeavier = relativeDiff > 0;

  const diffText = `${(percentDiff * 100).toFixed(0)}% ${
    isHeavier ? "heavier" : "lighter"
  }`;

  const warningText = showReweighThresholdWarning
    ? `The new weight is ${diffText}.`
    : "";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        That animal is already weighed at {formatWeightKg(scanWeight)}.<br />
        {warningText}
      </Grid>
      <Grid item xs={12} container justifyContent="space-around">
        <Button data-tour="keepExistingWeight" onClick={handleKeepExisting}>
          Keep existing weight ({formatWeightKg(scanWeight)})
        </Button>
        <Button
          data-tour="useWeightFromScales"
          onClick={handleUseNew}
          color={showReweighThresholdWarning ? "warningOrange" : "primary"}
          title={warningText}
        >
          Use weight from scales ({formatWeightKg(totalMassGrams)}) <br />
          {showReweighThresholdWarning && diffText}
        </Button>
      </Grid>
    </Grid>
  );
};
