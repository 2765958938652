import {
  PRINT_BUYER_SUMMARY,
  PRINT_BUYER_WAY_PICK_LIST,
  PRINT_RECEIPT,
  PRINT_SALE_LOT_WEIGHT_SUMMARY,
  PRINT_WEIGH_PEN_CARD_PORTRAIT,
  PRINT_WEIGH_PEN_CARD_LANDSCAPE,
  PRINT_PLACARD,
  PRINT_VENDOR_SUMMARY,
  PRINT_PLACARD_V2,
  PRINT_SHEEP_PLACARD,
  PRINT_SHEEP_PEN_STORE_CARD,
  PRINT_PLACARD_V3,
  PRINT_PLACARD_V4,
  PRINT_PLACARD_V5,
} from "constants/actionTypes";
import { PlacardVersions } from "constants/pdf";

export const printBuyerWayPickList = buyerWay => ({
  type: PRINT_BUYER_WAY_PICK_LIST,
  buyerWay,
});

export const printBuyerSummary = buyer => ({
  type: PRINT_BUYER_SUMMARY,
  buyer,
});

export const printVendorSummary = consignmentId => ({
  type: PRINT_VENDOR_SUMMARY,
  consignmentId,
});

/**
 * Base64 encodes the ArrayBuffer contents passed in as the first parameter before returning the action
 * @param {ArrayBuffer} content
 * @returns {{type: String, content: String}}
 */
export const printReceipt = content => ({
  type: PRINT_RECEIPT,
  content: btoa(String.fromCharCode(...new Uint8Array(content))),
});

/**
 * @param {String} saleLotId
 * @return {{saleLotId: String, type: string}}
 */
export const printSaleLotWeightSheet = (saleLotId, suppressBack = false) => ({
  type: PRINT_SALE_LOT_WEIGHT_SUMMARY,
  saleLotId,
  suppressBack,
});

export const printPenWeighCardPortrait = (
  auctionPenId = null,
  suppressBack = false,
  agentId = null,
) => ({
  type: PRINT_WEIGH_PEN_CARD_PORTRAIT,
  auctionPenId,
  suppressBack,
  agentId,
});

export const printPenWeighCardLandscape = (
  auctionPenId = null,
  suppressBack = false,
  agentId = null,
) => ({
  type: PRINT_WEIGH_PEN_CARD_LANDSCAPE,
  auctionPenId,
  suppressBack,
  agentId,
});

/**
 * @param {String} saleLotId
 * @return {{saleLotId: String, type: string}}
 */
export const printPlacard = (
  saleLotId,
  round,
  suppressBack = false,
  agentId,
) => ({
  type: PRINT_PLACARD,
  saleLotId,
  round,
  suppressBack,
  agentId,
  version: PlacardVersions.V1,
});

/**
 * @param {String} saleLotId
 * @return {{saleLotId: String, type: string}}
 */
export const printPlacardV2 = (saleLotId, round, agentId) => ({
  type: PRINT_PLACARD_V2,
  saleLotId,
  round,
  agentId,
  version: PlacardVersions.V2,
});

export const printPlacardV3 = (
  saleLotId,
  round,
  agentId,
  includeWeight,
  size,
) => {
  return {
    type: PRINT_PLACARD_V3,
    saleLotId,
    round,
    agentId,
    includeWeight,
    size,
    version: PlacardVersions.V3,
  };
};

/**
 * @param {String} saleLotId
 * @return {{saleLotId: String, type: string}}
 */
export const printPlacardV4 = (saleLotId, round, agentId) => ({
  type: PRINT_PLACARD_V4,
  saleLotId,
  round,
  agentId,
  version: PlacardVersions.V4,
});

/**
 * @param {String} saleLotId
 * @return {{saleLotId: String, type: string}}
 */
export const printPlacardV5 = (
  saleLotId,
  round,
  suppressBack = false,
  agentId,
) => ({
  type: PRINT_PLACARD_V5,
  saleLotId,
  round,
  suppressBack,
  agentId,
  version: PlacardVersions.V5,
});

export const printSheepPlacard = (saleLotId, round, agencyId) => ({
  type: PRINT_SHEEP_PLACARD,
  saleLotId,
  round,
  agencyId,
});

export const printSheepPenStoreCard = (saleLotId, round, agencyId) => ({
  type: PRINT_SHEEP_PEN_STORE_CARD,
  saleLotId,
  round,
  agencyId,
});
