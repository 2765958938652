import {
  addDraftingInformationFromSocket,
  addProductFromSocket,
  AuctionPenAction,
  BidderRegistrationAction,
  BillingDocumentAction,
  BillingRunAction,
  bulkUpdateFromSocket,
  BusinessAction,
  createAttachmentsFromSocket,
  deleteAttachmentFromSocket,
  deleteProductFromSocket,
  deleteSaleLotFromSocket,
  deleteScanFromSocket,
  DeploymentAction,
  EContractAction,
  EnvdDocumentAction,
  LedgerEntryAction,
  ManualAdjustmentAction,
  MasterRuleAction,
  MasterRuleBookAction,
  NominationAction,
  P2PFileAction,
  P2PReversalAction,
  PaymentAction,
  refreshNVDUpload,
  refreshSellFile,
  refreshSellReversal,
  refreshTakeFile,
  refreshTakeReversal,
  requestAllSellFiles,
  requestAllTakeStatuses,
  requestConsignmentsChanges,
  requestDeploymentAttributes,
  requestPropertiesChanges,
  requestScansChanges,
  RuleAction,
  RuleBookAction,
  SaleAction,
  SaleLotAction,
  SavedViewAction,
  SingleWeighAction,
  updateAttachmentFromSocket,
  updateAttachmentsFromSocket,
  updateProductFromSocket,
  WeighLotAction,
  WeighLotScanAction,
} from "actions";

import { CheckpointAction } from "actions/checkpoints";
import { CommentAction } from "actions/comments";
import { EmailAction } from "actions/email";
import { IntegrationCredentialAction } from "actions/integrationCredentials";
import { InterestSettingsAction } from "actions/interest";
import { LedgerAccountAction } from "actions/ledgerAccounts";
import { MasterLedgerAccountAction } from "actions/masterLedgerAccounts";
import { PenScanLotAction } from "actions/penScanLots";
import { ReceivalLotAction } from "actions/receivalLots";
import { ReportJobAction } from "actions/reportJob";
import { SundryTemplateAction } from "actions/sundryTemplates";
import { TradingTermAction } from "actions/tradingTerms";
import { VendorCommissionBandAction } from "actions/vendorCommissionBands";
import {
  DefaultVendorSplitAction,
  SaleVendorSplitAction,
} from "actions/vendorSplits";

import { PusherTypes } from "constants/socket";

import { getLivestockSaleId } from "lib/navigation";

import { getActiveBillingRunId, getSaleById } from "selectors";

// Pulls out the object from the event that must be a LivestockSaleMiniSerializer.
const refreshViaPusher = handler => event => handler(event.object);

// NLIS Files don't have a changes since endpoint, and the system needs to handle when a File is deleted
// Not having an id attribute implicates that the NlisFile has been deleted, to remove it from state,
// dispatch a get all, which completely overwrites the state.
const refreshNlisFileNoChangesSinceWorkaround =
  (refreshSingleAction, refreshAllAction) => message =>
    message.object.id
      ? refreshSingleAction(message)
      : refreshAllAction(message.object);

const filterAttachmentsByLivestockSaleId = payload => {
  const livestockSaleId = getLivestockSaleId();
  const filteredAttachments = payload.object.filter(
    attachment => attachment.livestock_sale === livestockSaleId,
  );
  return {
    ...payload,
    object: filteredAttachments,
  };
};

/*
A set of actions that will be used to handle pusher packets.
The key is the name of the pusher event (see, pusher_constants.py)
Possible data keys are:
- action (required) a redux action to call
- filterOnLivestockSaleId - if the packet has a livestock sale id, only action the result if we are currently viewing that sale
- mutatePayload - a function that is passed the pusher payload, and current state, that allows the data passed to the action to be mutated.  This is a bit of a shortcut, rather than triggering multiple actions through a saga.
 */
export const channelEvents = {
  [PusherTypes.BULK_UPDATE]: {
    action: bulkUpdateFromSocket,
  },
  [PusherTypes.CREATE_AGENCY_PRODUCT]: {
    action: addProductFromSocket,
  },
  [PusherTypes.CREATE_ATTACHMENTS]: {
    action: createAttachmentsFromSocket,
    mutatePayload: filterAttachmentsByLivestockSaleId,
  },
  [PusherTypes.DELETE_AGENCY_PRODUCT]: {
    action: deleteProductFromSocket,
  },
  [PusherTypes.DELETE_ATTACHMENT]: {
    action: deleteAttachmentFromSocket,
  },
  [PusherTypes.DELETE_SALE_LOT]: { action: deleteSaleLotFromSocket },
  [PusherTypes.DELETE_SCAN]: {
    action: deleteScanFromSocket,
    filterOnLivestockSaleId: true,
  },
  [PusherTypes.REFRESH_AUCTIONPENS]: {
    action: AuctionPenAction.requestChanges,
    filterOnLivestockSaleId: true,
    mutatePayload: (payload, state) => ({
      changesSince: state.auctionPens.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_BIDDER_REGISTRATIONS]: {
    action: BidderRegistrationAction.requestChanges,
    filterOnLivestockSaleId: true,
    mutatePayload: (payload, state) => ({
      changesSince: state.bidderRegistrations.lastModifiedTimestamp,
    }),
  },

  [PusherTypes.REFRESH_BILLING_DOCUMENTS]: {
    action: BillingDocumentAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.billingDocuments.lastModifiedTimestamp,
      billingRunId: getActiveBillingRunId(state),
    }),
  },
  [PusherTypes.REFRESH_BILLING_LEDGER_ENTRIES]: {
    action: LedgerEntryAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.ledgerEntries.lastModifiedTimestamp,
      documentBillingRunId: getActiveBillingRunId(state),
    }),
  },
  [PusherTypes.REFRESH_BILLING_RUNS]: {
    action: BillingRunAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.billingRuns.lastModifiedTimestamp,
      livestockSalesIdIn: getLivestockSaleId(),
    }),
  },
  [PusherTypes.REFRESH_BUSINESSES_V2]: {
    action: BusinessAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.businessesV2.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_CHECKPOINTS]: {
    action: CheckpointAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.checkpoints.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_COMMENTS]: {
    action: CommentAction.requestChanges,
    filterOnLivestockSaleId: true,
    mutatePayload: (payload, state) => ({
      changesSince: state.comments.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_CONSIGNMENTS]: {
    action: requestConsignmentsChanges,
    filterOnLivestockSaleId: true,
    mutatePayload: (payload, state) =>
      getSaleById(payload.object.livestocksale_id)(state),
  },
  [PusherTypes.REFRESH_DEPLOYMENT_ATTRIBUTES]: {
    action: requestDeploymentAttributes,
  },

  [PusherTypes.REFRESH_DEPLOYMENTS]: {
    action: DeploymentAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.deployments.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_ECONTRACTS]: {
    action: EContractAction.requestChanges,
    filterOnLivestockSaleId: true,
    mutatePayload: (payload, state) => ({
      changesSince: state.eContracts.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_EMAILS]: {
    action: EmailAction.requestChanges,
    mutatePayload: (payload, state) => ({
      // additional processing is performed in the saga listening for
      // this event which may cause this event to be short circuited/discarded
      changesSince: state.email.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_ENVD_DOCUMENTS]: {
    action: EnvdDocumentAction.requestChanges,
    mutatePayload: (payload, state) => state.checkpoints.lastModifiedTimestamp,
  },
  [PusherTypes.REFRESH_INTEGRATION_CREDENTIALS]: {
    action: IntegrationCredentialAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.integrationCredentials.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_LEDGER_ACCOUNTS]: {
    action: LedgerAccountAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.ledgerAccounts.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_MANUAL_ADJUSTMENTS]: {
    action: ManualAdjustmentAction.requestChanges,
    filterOnLivestockSaleId: true,
    mutatePayload: (payload, state) => ({
      changesSince: state.manualAdjustments.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_MASTER_LEDGER_ACCOUNTS]: {
    action: MasterLedgerAccountAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.masterLedgerAccounts.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_MASTER_RULES]: {
    action: MasterRuleAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.masterRules.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_MASTER_RULE_BOOKS]: {
    action: MasterRuleBookAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.masterRuleBooks.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_NLIS_NVD_UPLOAD]: {
    action: refreshNVDUpload,
    filterOnLivestockSaleId: true,
  },
  [PusherTypes.REFRESH_NLIS_P2P_FILE]: {
    action: P2PFileAction.request,
    filterOnLivestockSaleId: true,
  },
  [PusherTypes.REFRESH_NLIS_P2P_REVERSAL]: {
    action: P2PReversalAction.request,
    filterOnLivestockSaleId: true,
  },
  [PusherTypes.REFRESH_NLIS_SELL_FILE]: {
    action: refreshNlisFileNoChangesSinceWorkaround(
      refreshSellFile,
      requestAllSellFiles,
    ),
    filterOnLivestockSaleId: true,
  },
  [PusherTypes.REFRESH_NLIS_SELL_REVERSAL]: {
    action: refreshSellReversal,
    filterOnLivestockSaleId: true,
  },

  [PusherTypes.REFRESH_NLIS_TAKE_FILE]: {
    action: refreshNlisFileNoChangesSinceWorkaround(
      refreshTakeFile,
      requestAllTakeStatuses,
    ),
    filterOnLivestockSaleId: true,
  },
  [PusherTypes.REFRESH_NLIS_TAKE_REVERSAL]: {
    action: refreshTakeReversal,
    filterOnLivestockSaleId: true,
  },
  [PusherTypes.REFRESH_NOMINATIONS]: {
    action: NominationAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.nominations.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_PAYMENTS]: {
    action: PaymentAction.requestChanges,
    filterOnLivestockSaleId: true,
    mutatePayload: (payload, state) => ({
      changesSince: state.payments.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_PEN_SCAN_LOTS]: {
    action: PenScanLotAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.penScanLots.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_PROPERTIES]: {
    action: refreshViaPusher(requestPropertiesChanges),
  },
  [PusherTypes.REFRESH_RECEIVAL_LOTS]: {
    action: ReceivalLotAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.receivalLots.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_REPORT_JOBS]: {
    action: ReportJobAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.reportJobs.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_RULES]: {
    action: RuleAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.rules.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_RULE_BOOKS]: {
    action: RuleBookAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.ruleBooks.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_SALES]: {
    action: SaleAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.sales.lastModifiedTimestamp,
      dontChangeSale: true,
    }),
  },
  [PusherTypes.REFRESH_SALE_LOTS]: {
    action: SaleLotAction.requestChanges,
    filterOnLivestockSaleId: true,
    mutatePayload: (payload, state) => ({
      changesSince: state.saleLots.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_SAVED_VIEWS]: {
    action: SavedViewAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.savedViews.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_SCANS]: {
    action: refreshViaPusher(requestScansChanges),
    filterOnLivestockSaleId: true,
  },
  [PusherTypes.REFRESH_SINGLE_WEIGH]: {
    action: SingleWeighAction.requestChanges,
  },
  [PusherTypes.REFRESH_SUNDRY_TEMPLATES]: {
    action: SundryTemplateAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.sundryTemplates.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_TRADING_TERMS]: {
    action: TradingTermAction.requestChanges,
    filterOnLivestockSaleId: false,
    mutatePayload: (payload, state) => ({
      changesSince: state.tradingTerms.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_VENDOR_COMMISSION_BANDS]: {
    action: VendorCommissionBandAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.vendorCommissionBands.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_SALE_VENDOR_SPLITS]: {
    action: SaleVendorSplitAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.vendorSplits.sale.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_DEFAULT_VENDOR_SPLITS]: {
    action: DefaultVendorSplitAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.vendorSplits.default.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_INTEREST_SETTINGS]: {
    action: InterestSettingsAction.requestChanges,
  },
  [PusherTypes.REFRESH_WEIGH_LOTS]: {
    action: WeighLotAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.weighLots.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.REFRESH_WEIGH_LOT_SCANS]: {
    action: WeighLotScanAction.requestChanges,
    mutatePayload: (payload, state) => ({
      changesSince: state.weighLotScans.lastModifiedTimestamp,
    }),
  },
  [PusherTypes.UPDATE_AGENCY_PRODUCT]: {
    action: updateProductFromSocket,
  },
  [PusherTypes.UPDATE_ATTACHMENT]: {
    action: updateAttachmentFromSocket,
    mutatePayload: payload => {
      if (payload.object.livestock_sale !== getLivestockSaleId()) {
        return null;
      }
      return payload;
    },
  },
  [PusherTypes.UPDATE_ATTACHMENTS]: {
    action: updateAttachmentsFromSocket,
    mutatePayload: filterAttachmentsByLivestockSaleId,
  },
  [PusherTypes.CREATE_DRAFTING_INFORMATION]: {
    action: addDraftingInformationFromSocket,
  },
};

export function getPusherChannelName(stream, system = null, instance = null) {
  return [system, instance, stream].filter(part => part !== null).join("__");
}
