import { useMediaQuery } from "@material-ui/core";

import { useTheme } from "./useTheme";

function getBreakpointCss(minBreakpointIndex, maxBreakpointIndex, breakpoints) {
  if (
    typeof maxBreakpointIndex === "number" &&
    typeof minBreakpointIndex === "number"
  ) {
    return `(min-width: ${breakpoints[minBreakpointIndex]}px) and (max-width: ${breakpoints[maxBreakpointIndex]}px)`;
  } else if (typeof minBreakpointIndex === "number") {
    return `(min-width: ${breakpoints[minBreakpointIndex]}px)`;
  } else if (typeof maxBreakpointIndex === "number") {
    return `(max-width: ${breakpoints[maxBreakpointIndex]}px)`;
  }
  return "";
}

/**
 * Returns whether or not the device's current viewport matches the dimensions in the active theme's breakpoints list
 * @param {number} [min] An index of a breakpoint from the current theme used for the minimum screen size
 * @param {number} [max] An index of a breakpoint from the current theme used for the maximum screen size
 * @returns {boolean}
 */
export function useScreenSize({ min, max }) {
  const { breakpoints } = useTheme();
  return useMediaQuery(getBreakpointCss(min, max, breakpoints), {
    noSsr: true,
  });
}

/**
 * Returns whether or not the device's current viewport matches the dimensions of a mobile screen
 * @returns {boolean}
 */
export function useIsMobile() {
  return !useScreenSize({ min: 2 });
}
/**
 * Returns whether or not the device's current viewport matches the dimensions of a small mobile screen
 * @returns {boolean}
 */
export function useIsSmallMobile() {
  return !useScreenSize({ min: 1 });
}
/**
 * Returns whether or not the device's current viewport matches the dimensions of a very small mobile screen
 * @returns {boolean}
 */
export function useIsExtraSmallMobile() {
  return !useScreenSize({ min: 0 });
}

/**
 * Returns whether or not the device's current viewport matches the dimensions of a desktop screen
 * @returns {boolean}
 */
export function useIsDesktop() {
  return useScreenSize({ min: 2 });
}
