import React from "react";

import { Grid } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { AuctionPenAction } from "actions";

import { Button, /* DeleteButton, */ SecondaryButton } from "components/Form";
import {
  OptionTogglerField,
  useSubmitHandler,
} from "components/Form/FormikControls";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "components/MaterialDialog";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import { getAuctionPenById } from "selectors";

import { RoundPenSelect } from "./PenSelect";

const AuctionPenReorderType = {
  ORDER_AFTER: 0,
  ORDER_BEFORE: 1,
};

const AuctionPenReorderTypeDisplayNameMap = {
  [AuctionPenReorderType.ORDER_AFTER]: "Sell After",
  [AuctionPenReorderType.ORDER_BEFORE]: "Sell Before",
};

const ALL_AUCTION_PEN_REORDER_TYPES = Object.values(AuctionPenReorderType);
const AUCTION_PEN_REORDER_TYPES_OPTIONS = ALL_AUCTION_PEN_REORDER_TYPES.map(
  reorderType => ({
    value: reorderType,
    label: AuctionPenReorderTypeDisplayNameMap[reorderType],
  }),
);

const validationSchema = Yup.object().shape({
  reorderType: Yup.string().required("Please select an insert position"),
  auctionPenId: Yup.string().required("Please select an Auction Pen"),
});

function AuctionPenReOrderForm(props) {
  const { roundId } = props;
  return (
    <>
      <Grid item xs={12} md={6}>
        <OptionTogglerField
          label="Insert Position"
          labelPosition="top"
          name="reorderType"
          options={AUCTION_PEN_REORDER_TYPES_OPTIONS}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <RoundPenSelect
          label="Auction Pen"
          name="auctionPenId"
          roundId={roundId}
        />
      </Grid>
    </>
  );
}

function ReorderDialogPenForm(props) {
  const { /* auctionPenId, */ closeSelf, roundId } = props;
  // const dispatch = useDispatch();

  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  useSubmitHandler(false, setIsSubmitEnabled);

  // function onClickResetToNatural() {
  //   dispatch(AuctionPenAction.update({ id: auctionPenId, order: null }));
  //   closeSelf();
  // }

  return (
    <>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <AuctionPenReOrderForm roundId={roundId} />
        </Grid>
      </DialogContent>

      <DialogActions>
        <SecondaryButton type="button" onClick={closeSelf}>
          Cancel
        </SecondaryButton>
        {/* <DeleteButton type="button" onClick={onClickResetToNatural}> */}
        {/*  Reset to Natural Order */}
        {/* </DeleteButton> */}
        <Button type="submit" disabled={!isSubmitEnabled}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
}

function ReorderPen(props) {
  const { auctionPenId, closeSelf } = props;
  const dispatch = useDispatch();
  const auctionPen = useSelector(getAuctionPenById(auctionPenId));

  function onSubmit(formValues) {
    if (formValues.reorderType === AuctionPenReorderType.ORDER_AFTER) {
      dispatch(
        AuctionPenAction.orderAfter(auctionPenId, formValues.auctionPenId),
      );
    } else if (formValues.reorderType === AuctionPenReorderType.ORDER_BEFORE) {
      dispatch(
        AuctionPenAction.orderBefore(auctionPenId, formValues.auctionPenId),
      );
    }
    closeSelf();
  }

  return (
    <Dialog fullWidth fullScreen onClose={closeSelf} open>
      <DialogTitle onClose={closeSelf}>
        Re-Order Pen ({getAuctionPenDisplayName(auctionPen)})
      </DialogTitle>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <ReorderDialogPenForm
            auctionPenId={auctionPenId}
            closeSelf={closeSelf}
            roundId={auctionPen.saleRoundId}
          />
        </Form>
      </Formik>
    </Dialog>
  );
}
export default React.memo(ReorderPen);
