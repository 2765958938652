import React, { useCallback, useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import {
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
  isAfter,
  addDays,
  addYears,
  subDays,
} from "date-fns";
import ReactSelect from "react-select";

import { DateInput, Label } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import { ExternalLink } from "components/Link";

import {
  datePrefillDays,
  datePrefillMonths,
  datePrefillCalendarYears,
  datePrefillFinancialYears,
  datePrefillOptions,
  datePrefillOptionsWithYears,
  datePrefills,
} from "constants/time";

import {
  dateXDaysAgo,
  formatISO8601DateString,
  formatUTCToLocalDateTimeInputString,
} from "lib/timeFormats";

import { useFieldValue } from "hooks";

export function InputDateRange(props) {
  const {
    startDateFieldName,
    endDateFieldName,
    updateDates,
    tooltip,
    label = "Date Range",
    includeTime = true,
    singleLine = false,
    showYearOptions = false,
    showRangeOptions = true,
    startDateLabel = "",
    endDateLabel = "",
    lastDayInclusive = false,
  } = props;

  const [prefill, setPrefill] = useState(null);

  const clearDates = () => {
    updateDates(null, null);
  };

  const getPrefillDates = useCallback(
    selected => {
      const today = new Date();
      let startDate = today;
      let endDate = today;

      if (!selected) {
        return {
          startDateTime: null,
          endDateTime: null,
        };
      }

      // If months are selected filter by months
      if (Object.keys(datePrefillMonths).includes(selected.value)) {
        const subbed = subMonths(today, datePrefillMonths[selected.value]); // Take the select amount of months away from todays date
        startDate = new Date(subbed.getFullYear(), subbed.getMonth(), 1); // Get the first day of current date - x months
        endDate = new Date(today.getFullYear(), today.getMonth(), 0); // Get the last day of last month, by getting the 0 day (which is last day of last month)
      } else if (
        Object.keys(datePrefillCalendarYears).includes(selected.value)
      ) {
        const subbed = subYears(
          today,
          datePrefillCalendarYears[selected.value],
        ); // Take the selected amount of years away from todays date
        startDate = startOfYear(subbed);
        endDate = endOfYear(subbed);
      } else if (
        Object.keys(datePrefillFinancialYears).includes(selected.value)
      ) {
        const subbed = subYears(today, [
          datePrefillFinancialYears[selected.value],
        ]); // Take the selected amount of years away from todays date
        const endOFY = new Date(subbed.getFullYear(), 5, 30); // end of financial year for subbed date's year
        const startONFY = addDays(endOFY, 1); // start of new financial year for subbed date's year
        if (isAfter(subbed, endOFY)) {
          // subbed date is in second half of calendar year
          startDate = startONFY;
          endDate = addYears(endOFY, 1);
        } else {
          // subbed date is in first half of calendar year
          startDate = subYears(startONFY, 1);
          endDate = endOFY;
        }
      } else {
        // 'last 24 hours' means "today" for the purposes dates, but it means "yesterday" for times
        // the same is true the last week vs the last 7 days
        const dayOffset = includeTime ? 0 : -1;

        const date = dateXDaysAgo(datePrefillDays[selected.value] + dayOffset);
        // Otherwise the value is in days
        startDate = date;
        // if we've selected yesterday - set the end date to yesterday
        if (selected.value === datePrefills.yesterday) {
          endDate = date;
        }
      }

      // // If lastDayInclusive remove a day from our start date range to make our date ranges inclusive of the last day
      const finalStartDate = lastDayInclusive
        ? subDays(startDate, 1)
        : startDate;

      // If we are including time, we need to set the string to be in the DATETIME_INPUT_FORMAT.
      // Otherwise if just the date we can set it to be ISO8601_DATE_FORMAT.
      return {
        startDateTime: includeTime
          ? formatUTCToLocalDateTimeInputString(finalStartDate)
          : formatISO8601DateString(finalStartDate),
        endDateTime: includeTime
          ? formatUTCToLocalDateTimeInputString(endDate)
          : formatISO8601DateString(endDate),
      };
    },
    [includeTime, lastDayInclusive],
  );

  const onSelectDatePrefill = useCallback(
    selected => {
      const { startDateTime, endDateTime } = getPrefillDates(selected);

      updateDates(startDateTime, endDateTime);
      setPrefill(selected);
    },
    [getPrefillDates, setPrefill, updateDates],
  );

  const clearDatePrefill = () => {
    setPrefill(null);
  };

  const dateOptions = showYearOptions
    ? datePrefillOptionsWithYears(includeTime, lastDayInclusive)
    : datePrefillOptions(includeTime, lastDayInclusive);

  const startDateValue = useFieldValue(startDateFieldName);
  const endDateValue = useFieldValue(endDateFieldName);

  useEffect(() => {
    const { startDateTime, endDateTime } = getPrefillDates(prefill);
    if (
      prefill &&
      (startDateValue !== startDateTime || endDateValue !== endDateTime)
    ) {
      setPrefill(null);
    }
  }, [endDateValue, getPrefillDates, prefill, setPrefill, startDateValue]);

  return (
    <>
      {showRangeOptions && (
        <Grid item xs={singleLine ? 3 : 12}>
          <Row justifyBetween>
            <Label htmlFor="date-prefill" tooltip={tooltip}>
              {label}
            </Label>
            <ExternalLink onClick={clearDates} color="primary">
              Clear
            </ExternalLink>
          </Row>
          <ReactSelect
            value={prefill}
            placeholder="Select date range..."
            options={dateOptions}
            labelledBy="date-prefill"
            onChange={onSelectDatePrefill}
            menuPosition="fixed"
          />
        </Grid>
      )}
      <Grid item xs={singleLine ? 3 : 6}>
        <DateInput
          name={startDateFieldName}
          includeTime={includeTime}
          onChangeExtra={clearDatePrefill}
          showErrorUntouched
          label={startDateLabel}
        />
      </Grid>
      <Grid item xs={singleLine ? 3 : 6}>
        <DateInput
          name={endDateFieldName}
          includeTime={includeTime}
          onChangeExtra={clearDatePrefill}
          showErrorUntouched
          label={endDateLabel}
        />
        <Row justifyEnd />
      </Grid>
    </>
  );
}
