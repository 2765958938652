import isArray from "lodash/isArray";

import {
  BusinessUserRoleAction,
  LivestockAgentRoleAction,
  SaleWatcherRoleAction,
  SaleyardAdminRoleAction,
  ScaleOperatorRoleAction,
} from "actions";

import { ROLE_TYPES } from "constants/users";

/**
 * Check if user is of type given, (type is null if not applicable to user)
 *
 * @param {(string|array)} userType
 * @param {object} auth
 * @returns {boolean}
 */
export const isUserOfType = (userType, auth) => {
  if (!auth.activeRole) {
    return false;
  }
  if (isArray(userType)) {
    return userType.includes(auth.activeRole.type);
  } else if (typeof userType === "string") {
    return userType === auth.activeRole.type;
  }
  return false;
};

export const getReloadActionFromSlug = slug => {
  if (slug.startsWith("sa")) {
    return SaleyardAdminRoleAction.request;
  } else if (slug.startsWith("la")) {
    return LivestockAgentRoleAction.request;
  } else if (slug.startsWith("so")) {
    return ScaleOperatorRoleAction.request;
  } else if (slug.startsWith("bu")) {
    return BusinessUserRoleAction.request;
  } else if (slug.startsWith("sw")) {
    return SaleWatcherRoleAction.request;
  }
  // We shouldn't hit this...
  return null;
};

export const getWatcherRole = user => {
  return user[ROLE_TYPES.SALE_WATCHER]?.[0];
};

export function getInitialsFromUserInfo(userInfo, fallbackInitials) {
  if (!userInfo) {
    return fallbackInitials || "?";
  }
  const { firstName, lastName } = userInfo;
  let initials = "";
  if (firstName) {
    initials = firstName.substring(0, 1).toUpperCase();
  }
  if (lastName) {
    initials += lastName.substring(0, 1).toUpperCase();
  }
  return initials;
}

export function getNameFromUserInfo(userInfo, syncing = false, fallbackName) {
  if (!userInfo) {
    if (syncing) {
      return "[Name Pending]";
    }
    return fallbackName || "Deleted User";
  }
  return `${userInfo.firstName} ${userInfo.lastName}`;
}
