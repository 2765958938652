import React from "react";

import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniq } from "lodash";
import styled from "styled-components";

import { FieldName } from "components/AuditLog/Fields";
import { BoldText } from "components/Text";

import { AuditLogTypes } from "constants/auditLog";
import { colors } from "constants/theme";

import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

const ToolTipText = styled.p`
  margin: 5px;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
`;

export const AlternativesToolTipComponent = ({ fieldsWithDifferentValues }) => {
  if (fieldsWithDifferentValues.length < 1) {
    return null;
  }
  return (
    <>
      {uniq(fieldsWithDifferentValues).map(fieldName => {
        return (
          <ToolTipText key={fieldName}>
            <FontAwesomeIcon icon={faLink} color={colors.white} />{" "}
            <FieldName
              dataType={AuditLogTypes.ALTERNATIVES}
              fieldName={fieldName}
            />
          </ToolTipText>
        );
      })}
    </>
  );
};

export const XeroIntegrationToolTip = ({
  deploymentBusinessIntegrationBusiness,
}) => {
  if (!deploymentBusinessIntegrationBusiness) {
    return null;
  }

  const { name, last_successful_pull: lastSuccessfulPull } =
    deploymentBusinessIntegrationBusiness;

  if (!name && !lastSuccessfulPull) {
    return null;
  }

  return (
    <>
      {name && (
        <ToolTipText>
          <BoldText>Name: </BoldText> {name}
        </ToolTipText>
      )}
      {lastSuccessfulPull && (
        <ToolTipText>
          <BoldText>Last Synced: </BoldText>{" "}
          {formatUTCToLocalDateTimeString(lastSuccessfulPull)}
        </ToolTipText>
      )}
    </>
  );
};

export const AbnCheckExplainer = ({ business }) => {
  let text = "";
  const hasAbn = Boolean(business.abn);
  const isHobbyFarmer = Boolean(business.isHobbyFarmer);
  if (hasAbn) {
    text = `${business.name} has an ABN`;
  } else if (!hasAbn) {
    if (isHobbyFarmer) {
      text = `${business.name} is a Hobby Farmer`;
    } else {
      text = `${business.name} does not have an ABN`;
    }
  }
  return <BoldText>{text}</BoldText>;
};
