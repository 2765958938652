import { simpleUserSerializerMap } from "lib/serializers/simpleUser";
import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const checkpointSerializeMap = {
  comment: "comment",
  deploymentId: "deployment_id",
  hasUpdatesSince: "has_updates_since",
  isComplianceCheck: "is_compliance_check",
  isCommercialCheck: "is_commercial_check",
  livestockSaleId: "livestock_sale_id",
  permissions: "permissions",
  timestamp: "timestamp",
  type: "type",
  user: simpleUserSerializerMap,
};

export const serializeCheckpoint = buildSerializer(
  checkpointSerializeMap,
  "checkpoint",
);

export const deserializeCheckpoint = buildDeserializer(
  checkpointSerializeMap,
  "checkpoint",
);
