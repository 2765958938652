import React, { useMemo } from "react";

import { get } from "lodash";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { ColumnType } from "components/AgGrid/constants";
import { AlmostFullScreenModalTableWrapper } from "components/AgGrid/TableWrapper";

import { AgGridTables } from "constants/aggrid";
import {
  DeploymentColId,
  DeploymentColumnDef,
} from "constants/columnDefinitions/deployment";
import {
  AgeColumnDef,
  AnimalColumnDef,
  AuctionPenColumnDef,
  BreedColumnDef,
  BuyerColumnDef,
  BuyerPropertyColumnDef,
  ConsignmentColumnDef,
  LotColumnDef,
  RoundColumnDef,
  SaleColumnDef,
  SexColumnDef,
  SpeciesColumnDef,
  VendorColumnDef,
  VendorPropertyColumnDef,
  YardColumnDef,
} from "constants/columnDefinitions/saleData";

import {
  flattenSaleDataWithRegardsToAnimals,
  flattenSaleDataWithRegardsToBuyers,
  flattenSaleDataWithRegardsToConsignments,
  flattenSaleDataWithRegardsToLots,
  flattenSaleDataWithRegardsToVendors,
} from "lib/saleData";

export const ResultConfigs = {
  Animals: "Animals",
  Buyers: "Buyers",
  Consignments: "Consignments",
  SaleLots: "Sale Lots",
  Vendors: "Vendors",
};

const RenderConfigurations = {
  [ResultConfigs.SaleLots]: {
    flattenFunction: flattenSaleDataWithRegardsToLots,
    getId: dataOrParams =>
      get(dataOrParams.data || dataOrParams, "lot.id:uuid"),
    columnDefs: [
      AgeColumnDef,
      AuctionPenColumnDef,
      BreedColumnDef,
      BuyerColumnDef,
      BuyerPropertyColumnDef,
      ConsignmentColumnDef,
      DeploymentColumnDef[DeploymentColId.DEPLOYMENT_NAME],
      LotColumnDef,
      RoundColumnDef,
      SaleColumnDef,
      SexColumnDef,
      SpeciesColumnDef,
      VendorColumnDef,
      VendorPropertyColumnDef,
      YardColumnDef,
    ],
    tableName: AgGridTables.TEST_RULEBOOK_SALELOTS,
  },
  [ResultConfigs.Consignments]: {
    flattenFunction: flattenSaleDataWithRegardsToConsignments,
    getId: dataOrParams =>
      get(dataOrParams.data || dataOrParams, "consignment.id:uuid"),
    columnDefs: [
      ConsignmentColumnDef,
      DeploymentColumnDef[DeploymentColId.DEPLOYMENT_NAME],
      SaleColumnDef,
      SpeciesColumnDef,
      VendorColumnDef,
      VendorPropertyColumnDef,
      YardColumnDef,
    ],
    tableName: AgGridTables.TEST_RULEBOOK_CONSIGNMENTS,
  },

  [ResultConfigs.Animals]: {
    flattenFunction: flattenSaleDataWithRegardsToAnimals,
    getId: dataOrParams =>
      get(dataOrParams.data || dataOrParams, "animal.id:uuid"),
    columnDefs: [
      AgeColumnDef,
      AnimalColumnDef,
      AuctionPenColumnDef,
      BreedColumnDef,
      BuyerColumnDef,
      BuyerPropertyColumnDef,
      ConsignmentColumnDef,
      DeploymentColumnDef[DeploymentColId.DEPLOYMENT_NAME],
      LotColumnDef,
      RoundColumnDef,
      SaleColumnDef,
      SexColumnDef,
      SpeciesColumnDef,
      VendorColumnDef,
      VendorPropertyColumnDef,
      YardColumnDef,
    ],
    tableName: AgGridTables.TEST_RULEBOOK_ANIMALS,
  },

  [ResultConfigs.Buyers]: {
    flattenFunction: flattenSaleDataWithRegardsToBuyers,
    getId: dataOrParams =>
      `${get(dataOrParams.data || dataOrParams, "buyer.id:auto_id")}__${get(
        dataOrParams.data || dataOrParams,
        "buyer.buyer_way",
      )}`,
    columnDefs: [
      BuyerColumnDef,
      BuyerPropertyColumnDef,
      DeploymentColumnDef[DeploymentColId.DEPLOYMENT_NAME],
      SaleColumnDef,
      SpeciesColumnDef,
      YardColumnDef,
    ],
    tableName: AgGridTables.TEST_RULEBOOK_BUYERS,
  },

  [ResultConfigs.Vendors]: {
    flattenFunction: flattenSaleDataWithRegardsToVendors,
    getId: dataOrParams =>
      get(dataOrParams.data || dataOrParams, "vendor.id:auto_id"),
    columnDefs: [
      VendorColumnDef,
      VendorPropertyColumnDef,
      DeploymentColumnDef[DeploymentColId.DEPLOYMENT_NAME],
      SaleColumnDef,
      SpeciesColumnDef,
      YardColumnDef,
    ],
    tableName: AgGridTables.TEST_RULEBOOK_VENDORS,
  },
};

export function SaleDataResults(props) {
  const { results, renderConfigKey } = props;

  const { saleData, rulebook, ledgerEntries } = results;
  const renderConfig = RenderConfigurations[renderConfigKey];

  const data = renderConfig.flattenFunction(saleData);

  const getRowId = params => renderConfig.getId(params);

  // Add a column for each rule.
  const columnDefs = useMemo(() => {
    return renderConfig.columnDefs.concat([
      {
        headerName: "Rules",
        children: rulebook.map(rule => ({
          colId: rule.id,
          field: `ruleResults.${rule.id}`,
          headerName: rule.name,
          type: ColumnType.BOOLEAN,
          hide: false,
        })),
      },
    ]);
  }, [rulebook, renderConfig]);

  // Go through all the rows, and find out if there's a ledger entry to match.
  const enhancedData = useMemo(() => {
    return data.reduce((acc, row) => {
      // Drop an entry for each rule on here as false.
      const ruleResults = rulebook.reduce((acc, rule) => {
        acc[rule.id] = false;
        return acc;
      }, {});
      // Then populate the true ones.
      ledgerEntries
        .filter(le => le.itemId === renderConfig.getId(row))
        .forEach(le => {
          ruleResults[le.ruleId] = true;
        });

      acc.push(Object.assign({}, row, { ruleResults }));
      return acc;
    }, []);
  }, [rulebook, data, ledgerEntries, renderConfig]);

  return (
    <AgGridTable
      columnDefs={columnDefs}
      getRowId={getRowId}
      rowData={enhancedData}
      showGlobalSearchFilter={false}
      WrapperComponent={AlmostFullScreenModalTableWrapper}
      tableName={renderConfig.tableName}
    />
  );
}
