import React from "react";

import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ActionButton } from "components/AgGrid/ActionButton";

export const labelActionsColumnRenderer = ({ data, context }) => {
  return (
    <ActionButton
      data-tour="action-delete-label"
      type="button"
      onClick={() => context.handleOpenDeleteDialog(data)}
    >
      <FontAwesomeIcon icon={faTrash} />
      &nbsp;Delete
    </ActionButton>
  );
};
