import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { PaddedIcon } from "components/Layout";

export const Action = styled(PaddedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  padding: 6px;
  &:hover {
    color: #0f4a80;
  }
  ${({ disabled }) => disabled && "opacity: 0.33; cursor: default;"};
`;
export const ActionIcon = styled(FontAwesomeIcon)`
  margin: auto 4px;
`;
