import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { pluralize } from "lib/pluralize";

import { getSingleWeighById } from "selectors";

export const SteadyWeightTimeoutAction = ({ singleWeighId }) => {
  const singleWeigh = useSelector(getSingleWeighById(singleWeighId));
  const { unsteadyWeightDelay } = singleWeigh;
  return (
    <Grid container spacing={2}>
      {`No steady weight could be captured after ${unsteadyWeightDelay} ${pluralize(
        "seconds",
        unsteadyWeightDelay,
      )}; the system has been paused for your safety.`}
    </Grid>
  );
};
