import React from "react";

import AgGrid from "components/AgGrid/AgGrid";

const defaultColDef = {
  sortable: true,
  flex: true,
  resizable: true,
  enableCellChangeFlash: true,
  floatingFilter: true,
  filter: "agMultiColumnFilter",
};

export const AgGridPreview = ({ rowData, columnDefs, tableName }) => {
  const context = {
    tableName,
  };
  return (
    <AgGrid
      rowData={rowData}
      columnDefs={columnDefs}
      pagination
      defaultColDef={defaultColDef}
      enableCellTextSelection
      context={context}
    />
  );
};
