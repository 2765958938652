import React from "react";

import { AddButton } from "components/Button/CollapseInlineButton";

import { getSettingsRoute } from "lib/navigation";

export function AddDetails({ isVisible = false }) {
  return isVisible ? (
    <AddButton data-tour="addNlisCreds" to={getSettingsRoute()}>
      Add NLIS Creds
    </AddButton>
  ) : null;
}
