import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { formatDecimal } from "lib";
import {
  selectNoSaleSaleLotIds,
  selectSoldSaleLotIds,
  selectSoldTotalPriceCents,
} from "selectors";

export function SaleProgressTotals(): React.JSX.Element {
  const soldSaleLotIds = useSelector(selectSoldSaleLotIds) || [];
  const noSaleSaleLotIds = useSelector(selectNoSaleSaleLotIds) || [];
  const soldTotalPriceCents = useSelector(selectSoldTotalPriceCents) || 0;

  const lotCount = soldSaleLotIds.length + noSaleSaleLotIds.length;

  return (
    <>
      <Grid item xs={6}>
        <h3>Sold Lots</h3>
        <span>{soldSaleLotIds.length}</span>
      </Grid>
      <Grid item xs={6}>
        <h3>No Sale Lots</h3>
        <span>{noSaleSaleLotIds.length}</span>
      </Grid>
      <Grid item xs={6}>
        <h3>Lots</h3>
        <span>{lotCount}</span>
      </Grid>
      <Grid item xs={6}>
        <h3>Total $</h3>
        <span>{`$${formatDecimal(soldTotalPriceCents * 0.01)}`}</span>
      </Grid>
    </>
  );
}
