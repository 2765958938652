import React from "react";

import Grid from "@material-ui/core/Grid";
import { getIn, useFormikContext } from "formik";

import {
  ControlledLifeCycleInput,
  DecimalStringInputProps,
  DollarDisplayCentsInputProps,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";

import { GstMethod } from "constants/billing";

import {
  ledgerEntryPriceFormatter,
  ledgerEntryQuantityFormatter,
} from "lib/numberFormatters";

import { usePricingFormContext } from "hooks";

export const PricingMethod = {
  UNIT_PRICE: "UNIT_PRICE",
  GROSS_PRICE: "GROSS_PRICE",
};

const PricingMethodOptions = [
  {
    label: "Unit Price",
    value: PricingMethod.UNIT_PRICE,
  },
  {
    label: "Gross Price",
    value: PricingMethod.GROSS_PRICE,
  },
];

const GstMethodOptions = [
  {
    label: "GST Exclusive",
    value: GstMethod.GST_EXCLUSIVE,
  },
  {
    label: "GST Inclusive",
    value: GstMethod.GST_INCLUSIVE,
  },
  {
    label: "GST Exempt",
    value: GstMethod.GST_EXEMPT,
  },
];

export function LedgerEntryPricingForm(props) {
  const { namespace: ns = "", onAfterPricingModelChanged } = props;

  const { values } = useFormikContext();
  const fieldValues = getIn(values, ns);

  const pricingMethod = getIn(fieldValues, "pricingMethod");
  const gstMethod = getIn(fieldValues, "gstMethod");

  const {
    onAfterGstMethodChanged,
    onAfterQuantityChanged,
    onAfterUnitPriceChanged,
    onAfterTotalPriceChanged,
  } = usePricingFormContext(ns, onAfterPricingModelChanged);

  return (
    <>
      <Grid item xs={8}>
        <SelectField
          label="Pricing Method"
          labelPosition="top"
          name={withNamespace(ns, "pricingMethod")}
          options={PricingMethodOptions}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <SelectField
          label="GST Method"
          labelPosition="top"
          name={withNamespace(ns, "gstMethod")}
          options={GstMethodOptions}
          onChangeExtra={onAfterGstMethodChanged}
          required
        />
      </Grid>

      <Grid item xs={4}>
        <ControlledLifeCycleInput
          {...DecimalStringInputProps}
          disabled={!gstMethod || !pricingMethod}
          decimal
          decimalPlaces={9}
          fieldDecimalPlaces={9}
          label="Units"
          type="number"
          name={withNamespace(ns, "quantity")}
          onChangeExtra={onAfterQuantityChanged}
          required
          formatInternalValue={ledgerEntryQuantityFormatter.format}
        />
      </Grid>

      <Grid item xs={4}>
        <ControlledLifeCycleInput
          {...DollarDisplayCentsInputProps}
          disabled={
            !(
              (gstMethod === GstMethod.GST_EXCLUSIVE ||
                gstMethod === GstMethod.GST_EXEMPT) &&
              pricingMethod === PricingMethod.UNIT_PRICE
            )
          }
          label={
            gstMethod !== GstMethod.GST_EXEMPT
              ? "Unit Price (Ex. GST)"
              : "Unit Price"
          }
          name={withNamespace(ns, "unitAmount")}
          onChangeExtra={onAfterUnitPriceChanged}
          required
          decimalPlaces={9}
          fieldDecimalPlaces={9}
          formatInternalValue={ledgerEntryPriceFormatter.format}
        />
      </Grid>
      {gstMethod !== GstMethod.GST_EXEMPT && (
        <Grid item xs={4}>
          <ControlledLifeCycleInput
            {...DollarDisplayCentsInputProps}
            disabled={
              !(
                gstMethod === GstMethod.GST_INCLUSIVE &&
                pricingMethod === PricingMethod.UNIT_PRICE
              )
            }
            label="Unit Price (Inc. GST)"
            name={withNamespace(ns, "unitAmountInc")}
            onChangeExtra={onAfterUnitPriceChanged}
            required
            decimalPlaces={9}
            fieldDecimalPlaces={9}
            formatInternalValue={ledgerEntryPriceFormatter.format}
          />
        </Grid>
      )}
      {gstMethod !== GstMethod.GST_EXEMPT && (
        <Grid item xs={4}>
          <ControlledLifeCycleInput
            {...DollarDisplayCentsInputProps}
            disabled
            label="GST Total"
            name={withNamespace(ns, "taxAmount")}
            onChangeExtra={onAfterTotalPriceChanged}
            required
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <ControlledLifeCycleInput
          {...DollarDisplayCentsInputProps}
          disabled={
            !(
              (gstMethod === GstMethod.GST_EXCLUSIVE ||
                gstMethod === GstMethod.GST_EXEMPT) &&
              pricingMethod === PricingMethod.GROSS_PRICE
            )
          }
          label={
            gstMethod !== GstMethod.GST_EXEMPT
              ? "Total Price (Ex. GST)"
              : "Total Price"
          }
          name={withNamespace(ns, "subtotal")}
          onChangeExtra={onAfterTotalPriceChanged}
          required
        />
      </Grid>
      {gstMethod !== GstMethod.GST_EXEMPT && (
        <Grid item xs={4}>
          <ControlledLifeCycleInput
            {...DollarDisplayCentsInputProps}
            disabled={
              !(
                gstMethod === GstMethod.GST_INCLUSIVE &&
                pricingMethod === PricingMethod.GROSS_PRICE
              )
            }
            label="Total Price (Inc. GST)"
            name={withNamespace(ns, "totalInc")}
            onChangeExtra={onAfterTotalPriceChanged}
            required
          />
        </Grid>
      )}
    </>
  );
}
