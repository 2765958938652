import queryString from "query-string";

import { INTEGRATION_BUSINESS } from "constants/actionTypes";

import { serializeIntegrationBusiness } from "lib/serializers/integrationBusinesses";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/integration-businesses/", id, action),
    query: {
      changesSince,
    },
  });

export const IntegrationBusinessAction = offlineActionCreator(
  INTEGRATION_BUSINESS,
  serializeIntegrationBusiness,
  urlCreator,
  false,
);

IntegrationBusinessAction.linkToBusiness = (
  integrationBusinessId,
  businessId,
  options,
) => ({
  type: INTEGRATION_BUSINESS.LINK_TO_BUSINESS.ACTION,
  id: integrationBusinessId,
  businessId,
  options,
});

IntegrationBusinessAction.unlinkFromBusiness = (
  integrationBusinessId,
  businessId,
  options,
) => ({
  type: INTEGRATION_BUSINESS.UNLINK_FROM_BUSINESS.ACTION,
  id: integrationBusinessId,
  businessId,
  options,
});
