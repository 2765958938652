import React from "react";

import { useSelector } from "react-redux";

import { CenteredGreedy } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";

import { getIsFetchingSaleLots } from "selectors";

export function SaleLotEmptyPlaceholder() {
  const isFetchingSaleLots = useSelector(getIsFetchingSaleLots);

  if (isFetchingSaleLots) {
    return (
      <CenteredGreedy>
        <LoadingSpinner size={14} subjectName="Sale Lots" />
      </CenteredGreedy>
    );
  }
  return "There are no Sale Lots to display.";
}
