import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { BoldText } from "components/Text";

import {
  getVendorNameByAuctionPenId,
  getBuyerNameByAuctionPenId,
  getBuyerWayNameByAuctionPenId,
  getCurrentSale,
  getBidderNumbersByAuctionPenId,
} from "selectors";

export const InfoContainer = styled.div`
  width: 45%;
  text-align: center;
`;

function CardInfo({ auctionPenId }) {
  const vendorName = useSelector(getVendorNameByAuctionPenId(auctionPenId));
  const buyerName = useSelector(getBuyerNameByAuctionPenId(auctionPenId));
  const buyerWayName = useSelector(getBuyerWayNameByAuctionPenId(auctionPenId));
  const showBidderNumbers =
    useSelector(getCurrentSale)?.using_registered_bidders;
  const bidderNumbers =
    useSelector(getBidderNumbersByAuctionPenId(auctionPenId)) || [];

  return (
    <>
      <InfoContainer>
        <BoldText>{vendorName || "-"}</BoldText>
      </InfoContainer>
      <InfoContainer>
        <BoldText>{buyerName || "-"}</BoldText>&nbsp;&nbsp;
        <BoldText>{buyerWayName || "-"}</BoldText>
        {showBidderNumbers && (
          <BoldText>
            {" - "}
            {bidderNumbers.join(", ") || "-"}
          </BoldText>
        )}
      </InfoContainer>
    </>
  );
}

export default React.memo(CardInfo);
