import React from "react";

import { useSelector } from "react-redux";

import AgGrid from "components/AgGrid/AgGrid";

import { getTransferMessage } from "constants/nlis";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import {
  getAuctionPens,
  getBusinesses,
  selectSaleLotByEidLookup,
  selectVendorIdBySaleLotIdLookup,
} from "selectors";

export function messageGetter({ data }) {
  const { message_no } = data;
  return getTransferMessage(message_no);
}

const colDef = [
  {
    headerName: "Vendor",
    field: "vendor",
    minWidth: 80,
  },
  {
    headerName: "Pen",
    field: "auctionPen",
    minWidth: 80,
  },
  {
    headerName: "EID",
    field: "EID",
    minWidth: 80,
  },
  {
    headerName: "From",
    field: "vendorPIC",
    minWidth: 60,
  },
  {
    headerName: "To",
    field: "buyerPIC",
    minWidth: 60,
  },
  {
    headerName: "NVD Number",
    field: "NVD",
    minWidth: 60,
  },
  {
    headerName: "Status",
    field: "response_type",
    minWidth: 40,
  },
  {
    headerName: "Message",
    colId: "message",
    valueGetter: messageGetter,
  },
  {
    headerName: "Action",
    field: "action",
  },
];

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: "agMultiColumnFilter",
  floatingFilter: true,
};

export const TransfersGrid = ({ transfers }) => {
  const saleLotByEidLookup = useSelector(selectSaleLotByEidLookup);
  const vendorIdBySaleLotIdLookup = useSelector(
    selectVendorIdBySaleLotIdLookup,
  );
  const businessLookup = useSelector(getBusinesses);
  const auctionPensLookup = useSelector(getAuctionPens);

  const rowData = React.useMemo(
    () =>
      transfers.reduce(
        (acc, t) =>
          acc.concat(
            t.eids.map(eid => {
              const saleLot = saleLotByEidLookup[eid.EID];

              return {
                ...eid,
                buyerPIC: t.buyer_pic,
                vendorPIC: t.vendor_pic,
                NVD: t.NVD,
                vendor:
                  businessLookup[vendorIdBySaleLotIdLookup[saleLot?.id]]
                    ?.name || "",
                auctionPen:
                  getAuctionPenDisplayName(
                    auctionPensLookup?.[saleLot?.auction_pen_id],
                  ) || "",
              };
            }),
          ),
        [],
      ),
    [
      transfers,
      auctionPensLookup,
      businessLookup,
      vendorIdBySaleLotIdLookup,
      saleLotByEidLookup,
    ],
  );

  const onGridReady = agGridInstance => {
    // Auto size the columns when the grid is ready.
    agGridInstance.columnApi.autoSizeAllColumns(false);
  };

  return (
    <AgGrid
      rowData={rowData}
      columnDefs={colDef}
      pagination
      paginationPageSize={100}
      defaultColDef={defaultColDef}
      enableCellTextSelection
      onGridReady={onGridReady}
      suppressColumnVirtualisation
    />
  );
};
