import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faClipboardListCheck } from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { PropTypes } from "prop-types";
import { connect, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { OutlineButton } from "components/Button";
import { SyncingStatus } from "components/Card/Elements";
import { Column, Row } from "components/Layout";
import { ResponsiveText } from "components/ResponsiveText";

import { PenTypes } from "constants/auctionPens";
import { SaleLotType } from "constants/saleLots";
import { EIDPattern } from "constants/scanner";
import { userTypes } from "constants/users";

import { ForUserType } from "containers/ForUserType";

import { formatWeightKg } from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import {
  formatSaleLotOverflow,
  getAverageWeightFormatted,
  getCombinedLotNumber,
  getMarkDetails,
} from "lib/saleLot";
import { scanLotConfig } from "lib/scanLot";

import {
  getAuctionPenById,
  getBusinessById,
  getConsignmentById,
  getConsignmentIdByEid,
  getIsFetchingConsignments,
  getIsFetchingSaleLots,
  getIsFetchingScans,
  getSaleLotById,
  getSaleLotTypeIdByEid,
  getScanByEid,
  getScanByNlisId,
  selectAgencyByConsignmentIdLookup,
} from "selectors";

import { FaIcon } from "./Elements";

const Wrapper = styled.div`
  padding: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.gray95};
  }
`;

const Or = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.error};
  padding: 0 12px;
`;

const Text = styled.span`
  font-size: 12px;
  margin-bottom: 3px;
  ${({ light }) => light && "color: #a1a1a1"};
`;

const ButtonText = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 6px;
`;

const weighTypes = {
  SINGLE: "single",
  AVERAGE: "average",
};

const InfoColumn = styled(Column)`
  justify-content: space-around;
`;

/* React dev tools note - this incorrectly flags itself as a re-render in
 * dev tools, but, it's not - looks like a hiccup with redux and (not) hooks.
 * https://github.com/facebook/react-devtools/issues/1290
 * */
const ScanRow = React.memo(
  ({
    agencyName,
    buyerWayName,
    eid,
    isDuplicate,
    isEidTaken, // Duplicate Sale lot.
    vendorName,
    penName,
    buyerName,
    lotNumber,
    disallowTaken,
    nlisId,
    nlisPic,
    marks,
    onEdit,
    onIgnore,
    onKeep,
    onSelect,
    isSaleyardScanned,
    isExpanded,
    showEdit,
    showIgnore,
    showKeep,
    showSelect,
    syncing,
    weight,
    penType,
    isArrivalOrPenScanning,
    scanLotId,
  }) => {
    const {
      getLotById,
      getNextPenTitleByPenIdOrPenArchetypeId,
      lotPenIdReference,
      lotMarksReference,
    } = scanLotConfig(penType);

    const scanLot = useSelector(getLotById(scanLotId));

    const scanLotPenTitle = useSelector(
      getNextPenTitleByPenIdOrPenArchetypeId(scanLot?.[lotPenIdReference]),
    );

    function onClickKeep() {
      typeof onKeep === "function" && onKeep(eid);
    }
    function onClickEdit() {
      typeof onEdit === "function" && onEdit(eid);
    }
    function onClickIgnore() {
      typeof onIgnore === "function" && onIgnore(eid);
    }
    function onClickSelect() {
      typeof onSelect === "function" && onSelect(eid);
    }
    const isEidEditable = !isArrivalOrPenScanning && isDuplicate && isExpanded;
    const canKeep =
      showKeep && ((disallowTaken && !isEidTaken) || !disallowTaken);
    const isTakenError = disallowTaken && isEidTaken;
    const isNewScan = !isDuplicate && !isSaleyardScanned;
    const nlisDataAvailable = nlisId || nlisPic;

    const displayEid = eid || "(EID Pending)";

    const duplicateText = () => {
      if (isSaleyardScanned) {
        return "This EID was scanned into another Consignment.";
      } else if (
        isArrivalOrPenScanning &&
        penType === PenTypes.RECEIVING &&
        scanLot
      ) {
        return `This EID was scanned into another Receival Lot from Receival Pen ${scanLotPenTitle} with Mark ${scanLot[lotMarksReference]}.`;
      } else if (
        isArrivalOrPenScanning &&
        penType === PenTypes.SELLING &&
        scanLot
      ) {
        return `This EID was scanned into another Pen Scan Lot from Selling Pen ${scanLotPenTitle} with Marks ${scanLot[
          lotMarksReference
        ]
          .map(mark => mark.location)
          .join(", ")}.`;
      } else {
        return `This EID has already been scanned${
          typeof lotNumber === "number" ? ` in Lot #${lotNumber}` : ""
        }.`;
      }
    };

    return (
      <Wrapper>
        <Row justifyBetween alignCenter>
          <Row baseline>
            {isTakenError && (
              <FaIcon
                icon={faExclamationCircle}
                color="warning"
                title="This EID cannot be changed as it has already been taken."
              />
            )}
            {isDuplicate && !isTakenError && (
              <FaIcon
                icon={faExclamationCircle}
                color="error"
                title="This EID already exists."
              />
            )}
            {isSaleyardScanned && (
              <FaIcon
                icon={faClipboardListCheck}
                color="primary"
                title="This EID has been scanned into a Consignment."
              />
            )}
            {isNewScan && (
              <FaIcon icon={faPlus} color="success" title="This EID is new." />
            )}
            {displayEid}
          </Row>
          {syncing && (
            <Row alignCenter>
              <SyncingStatus />
            </Row>
          )}
          {isEidEditable && (
            <Row alignCenter>
              {canKeep && (
                <OutlineButton
                  data-tour={`keep.${displayEid}`}
                  color="error"
                  onClick={onClickKeep}
                >
                  <ButtonText>Keep EID</ButtonText>
                </OutlineButton>
              )}
              {canKeep && (showEdit || showIgnore) && <Or>or</Or>}
              {showEdit && (
                <OutlineButton color="error" onClick={onClickEdit}>
                  <ButtonText>Edit EID</ButtonText>
                </OutlineButton>
              )}
              {showIgnore && showEdit && <Or>or</Or>}
              {showIgnore && (
                <OutlineButton
                  data-tour={`ignore.${displayEid}`}
                  color="error"
                  onClick={onClickIgnore}
                >
                  <ButtonText>Ignore</ButtonText>
                </OutlineButton>
              )}
              {showSelect && (showEdit || showIgnore) && !isSaleyardScanned && (
                <Or>or</Or>
              )}
              {showSelect && !isSaleyardScanned && (
                <OutlineButton
                  data-tour={`selectSaleLot.${displayEid}`}
                  color="error"
                  onClick={onClickSelect}
                >
                  <ButtonText>Select Sale Lot</ButtonText>
                </OutlineButton>
              )}
            </Row>
          )}
        </Row>

        {nlisDataAvailable && isExpanded && (
          <Row alignCenter>
            {nlisId && <Text light>{nlisId}</Text>}
            {nlisId && nlisPic && "\u00A0-\u00A0"}
            {nlisPic && <Text light>{nlisPic}</Text>}
          </Row>
        )}
        {isTakenError && (
          <Row>
            <Text bold>
              This EID has already been taken, so cannot be re-assigned.
            </Text>
          </Row>
        )}
        {((!isTakenError && isDuplicate) ||
          isExpanded ||
          isSaleyardScanned) && (
          <>
            {isDuplicate && (
              <Row>
                <Text>{duplicateText()}</Text>
              </Row>
            )}
            <Row justifyBetween>
              <Column>
                {!!buyerName && <Text light>Buyer: {buyerName}</Text>}
                {!!buyerWayName && <Text light>Buyer Way: {buyerWayName}</Text>}
                <ForUserType
                  userType={[
                    userTypes.LIVESTOCK_AGENT,
                    userTypes.SALEYARD_ADMIN,
                  ]}
                >
                  {!!weight && (
                    <Text light>
                      {" "}
                      <ResponsiveText
                        mobile={
                          weight.type === weighTypes.AVERAGE
                            ? "Avg. Wt.: "
                            : "Single Wt.: "
                        }
                        desktop={
                          weight.type === weighTypes.AVERAGE
                            ? "Average Weight: "
                            : "Single Weighed: "
                        }
                      />
                      {weight.value}
                    </Text>
                  )}
                </ForUserType>
              </Column>

              <InfoColumn>
                <ForUserType
                  userType={[
                    userTypes.LIVESTOCK_AGENT,
                    userTypes.SALEYARD_ADMIN,
                  ]}
                >
                  {!!penName && (
                    <Text light>
                      <ResponsiveText mobile="Pen:" desktop="Auction Pen:" />{" "}
                      {penName}
                    </Text>
                  )}
                  {!!marks && <Text light>Marks: {marks}</Text>}
                </ForUserType>
              </InfoColumn>
              <Column>
                <ForUserType userType={[userTypes.SALEYARD_ADMIN]}>
                  {!!agencyName && <Text light>Agency: {agencyName}</Text>}
                </ForUserType>
                {!!vendorName && <Text light>Vendor: {vendorName}</Text>}
              </Column>
            </Row>
            <Row justifyBetween />
          </>
        )}
      </Wrapper>
    );
  },
);

// 982 123710691990

ScanRow.propTypes = {
  eid: PropTypes.string.isRequired,
  isDuplicate: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isEidTaken: PropTypes.bool,
  isSaleyardScanned: PropTypes.bool,
  // Duplicate Sale lot.
  vendorName: PropTypes.string,
  buyerName: PropTypes.string,
  lotNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nlisId: PropTypes.string,
  nlisPic: PropTypes.string,
  penName: PropTypes.string,

  disallowTaken: PropTypes.bool,
  onEdit: PropTypes.func,
  onIgnore: PropTypes.func,
  onKeep: PropTypes.func,
  onSelect: PropTypes.func,
  showEdit: PropTypes.bool,
  showIgnore: PropTypes.bool,
  showKeep: PropTypes.bool,
  showSelect: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const { destinationConsignmentId, eidOrNlisId, showDuplicate } = props;

  const isEid = EIDPattern.SIXTEEN_DIGIT_PATTERN.test(eidOrNlisId);

  const scan = isEid
    ? getScanByEid(eidOrNlisId)(state)
    : getScanByNlisId(eidOrNlisId)(state);
  const eid = (scan && scan.EID) || (isEid && eidOrNlisId);

  let nlisId = isEid ? "" : eidOrNlisId;

  let isDuplicate = false;
  let isEidTaken = false;
  let isSaleyardScanned = false;

  let vendorName = "";
  let lotNumber = "";
  let buyerName = "";
  let buyerWayName = "";
  let nlisPic = "";
  let penName = "";
  let agencyName = "";
  let marks = "";
  let weight = "";

  if (scan) {
    nlisId = isEid ? scan.animal?.nlis_id : eidOrNlisId;
    nlisPic = scan.current_pic;

    const consignmentId = getConsignmentIdByEid(eid)(state);
    const agency =
      selectAgencyByConsignmentIdLookup(state)[consignmentId] || {};
    const saleLotType = getSaleLotTypeIdByEid(eid)(state);
    const consignment = getConsignmentById(consignmentId)(state) || {};
    const vendor = getBusinessById(consignment.vendor_id)(state) || {};
    vendorName = vendor.name;
    agencyName = agency.name;

    isEidTaken = Boolean(consignment.nlis_take_file_status);

    if (saleLotType === SaleLotType.SALEYARD_SCAN) {
      // If the existing scan is a Saleyard Scan Sale Lot
      isSaleyardScanned = true;
      isDuplicate = consignmentId !== destinationConsignmentId;
    } else if (showDuplicate) {
      // If the existing scan is an Auction Sale Lot
      const saleLot = getSaleLotById(scan.sale_lot_id)(state) || {};
      const buyer = getBusinessById(saleLot.buyer_id)(state) || {};
      const auctionPen = getAuctionPenById(saleLot.auction_pen_id)(state);
      const { overflowPen } = saleLot;
      penName = overflowPen
        ? `${getAuctionPenDisplayName(auctionPen, "")} ${formatSaleLotOverflow(
            overflowPen,
          )}`
        : getAuctionPenDisplayName(auctionPen, "");
      buyerName = buyer.name;
      buyerWayName = saleLot.buyer_way?.name;
      lotNumber = getCombinedLotNumber(saleLot);
      isDuplicate = true;
      marks = getMarkDetails(saleLot);
      weight = scan.time_weighed
        ? {
            value: `${formatWeightKg(scan.total_mass_grams, true)}`,
            type: weighTypes.SINGLE,
          }
        : {
            value: `${getAverageWeightFormatted(saleLot)} kg`,
            type: weighTypes.AVERAGE,
          };
    }
  }

  return {
    agencyName,
    buyerName,
    buyerWayName,
    eid,
    isEidTaken,
    isDuplicate,
    isSaleyardScanned,
    lotNumber,
    nlisId,
    nlisPic,
    marks,
    penName,
    syncing:
      getIsFetchingSaleLots(state) ||
      getIsFetchingScans(state) ||
      getIsFetchingConsignments(state),
    vendorName,
    weight,
  };
};

export default connect(mapStateToProps)(ScanRow);
