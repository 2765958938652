import React from "react";

import { Tab, Tabs } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { NlisTakeAndSellSaleTypes } from "constants/sale";
import { userTypes } from "constants/users";

import { NLISSNVDUploadInfo } from "containers/NLISFiles/NLISNVDUploads";
import { NLISP2PFileInfo } from "containers/NLISFiles/P2P";
import { AgentCredentials } from "containers/Settings/NLIS/Agent";
import { SaleyardCredentials } from "containers/Settings/NLIS/Saleyard";

import { isUserOfType } from "lib/auth";

import { getAuth, getCurrentSaleType } from "selectors";

import { NLISSellFileInfo } from "./NLISSellFiles";
import { NLISSightingFileInfo } from "./NLISSightingFiles";
import { NLISTakeFileInfo } from "./NLISTakeFiles";

const WhiteBox = styled.div(
  ({ theme }) => `
  background: ${theme.colors.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-gap: ${theme.space[2]}px;
`,
);

const TabValues = {
  TAKE: "0",
  SELL: "1",
  MBM: "2",
  SIGHTING: "3",
  P2P: "4",
  AGENT_CREDENTIALS: "5",
  SALEYARD_CREDENTIALS: "6",
};

const getTabsForUser = (user, saleType) => [
  {
    title: "Take",
    id: TabValues.TAKE,
    dataTour: "take",
    display:
      isUserOfType(
        [
          userTypes.SALEYARD_ADMIN,
          userTypes.SCALE_OPERATOR,
          userTypes.LIVESTOCK_AGENT,
        ],
        user,
      ) && NlisTakeAndSellSaleTypes.includes(saleType),
  },
  {
    id: TabValues.SELL,
    title: "Sell",
    dataTour: "sell",
    display:
      isUserOfType(
        [
          userTypes.SALEYARD_ADMIN,
          userTypes.SCALE_OPERATOR,
          userTypes.LIVESTOCK_AGENT,
        ],
        user,
      ) && NlisTakeAndSellSaleTypes.includes(saleType),
  },
  {
    id: TabValues.MBM,
    title: "NVD Upload / MBM",
    dataTour: "NVDUploadMBM",
    display: isUserOfType(
      [userTypes.SALEYARD_ADMIN, userTypes.SCALE_OPERATOR],
      user,
    ),
  },
  {
    id: TabValues.SIGHTING,
    title: "Sightings",
    dataTour: "sightings",
    display: isUserOfType(
      [userTypes.SALEYARD_ADMIN, userTypes.SCALE_OPERATOR],
      user,
    ),
  },

  {
    title: "P2P",
    id: TabValues.P2P,
    dataTour: "p2p",
    display: isUserOfType([userTypes.LIVESTOCK_AGENT], user),
  },
  {
    title: "Agent Credentials",
    id: TabValues.AGENT_CREDENTIALS,
    dataTour: "agentCredentials",
    display: isUserOfType([userTypes.LIVESTOCK_AGENT], user),
  },
  {
    title: "Saleyard Credentials",
    id: TabValues.SALEYARD_CREDENTIALS,
    dataTour: "saleyardCredentials",
    display: isUserOfType(
      [
        userTypes.LIVESTOCK_AGENT,
        userTypes.SALEYARD_ADMIN,
        userTypes.SCALE_OPERATOR,
      ],
      user,
    ),
  },
];

export const NLISFiles = () => {
  const auth = useSelector(getAuth);
  const currentSaleType = useSelector(getCurrentSaleType);
  const tabs = React.useMemo(
    () => getTabsForUser(auth, currentSaleType).filter(tab => tab.display),
    [auth, currentSaleType],
  );

  const [value, setValue] = React.useState(tabs[0]?.id);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <WaitForSync
      requiredData={[
        ApiModel.BUSINESSES,
        ApiModel.CONSIGNMENTS,
        ApiModel.SALE_LOTS,
      ]}
    >
      <WhiteBox>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          data-tour="tabs"
        >
          {tabs.map(tab => {
            return (
              <Tab
                key={tab.id}
                data-tour={tab.dataTour}
                label={tab.title}
                value={tab.id}
              />
            );
          })}
        </Tabs>

        {value === TabValues.TAKE && <NLISTakeFileInfo />}
        {value === TabValues.SELL && <NLISSellFileInfo />}
        {value === TabValues.MBM && <NLISSNVDUploadInfo />}
        {value === TabValues.SIGHTING && <NLISSightingFileInfo />}
        {value === TabValues.P2P && <NLISP2PFileInfo />}
        {value === TabValues.AGENT_CREDENTIALS && <AgentCredentials />}
        {value === TabValues.SALEYARD_CREDENTIALS && <SaleyardCredentials />}
      </WhiteBox>
    </WaitForSync>
  );
};
