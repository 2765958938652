import { createSelector } from "reselect";

import { EMPTY_ARRAY } from "lib";

import { caseInsensitiveCompare } from "lib/compare";
import { auctionPenFilterOptions } from "lib/filters";

import {
  selectAuctionPensByLane,
  selectPenRangesByAuctionPenIdLookup,
} from "selectors";

import { getAuctionPensByRound } from "selectors/composite";
import { currentSaleSelector, getBobbyCalfSaleRoundId } from "selectors/sales";

export const getAuctionPensBySaleRoundId = createSelector(
  [getAuctionPensByRound, selectPenRangesByAuctionPenIdLookup],
  (auctionPens, penRangesByAuctionPenIdLookup) => {
    // Convert the Map to an object
    return Object.assign(
      {},
      ...[...auctionPens.entries()].map(([round, auctionPens]) => ({
        [round]: auctionPens.map(ap => ({
          ...ap,
          ...penRangesByAuctionPenIdLookup[ap.id],
        })),
      })),
    );
  },
);

export const selectLaneByAuctionPenIdLookup = createSelector(
  [selectAuctionPensByLane],
  auctionPensByLane => {
    const result = {};
    // Round -> lanes -> auction pens.
    Object.values(auctionPensByLane).forEach(auctionPensByLane =>
      Object.entries(auctionPensByLane).forEach(([lane, auctionPenIds]) => {
        auctionPenIds.forEach(auctionPenId => {
          result[auctionPenId] = lane;
        });
      }),
    );
    return result;
  },
);

export const selectAuctionFilterOptionsBySaleRoundIdLookup = createSelector(
  [selectAuctionPensByLane],
  auctionPensByRoundThenLane => {
    const result = Object.entries(auctionPensByRoundThenLane).reduce(
      (lookup, [roundId, auctionPensByLane]) => {
        const laneOptions = Object.keys(auctionPensByLane).map((lane, idx) => ({
          label: lane,
          value: idx,
          auctionPenIds: auctionPensByLane[lane],
        }));

        laneOptions.sort((a, b) => caseInsensitiveCompare(a.label, b.label));

        lookup[roundId] = auctionPenFilterOptions.map(apFilter =>
          apFilter.name === "lane"
            ? { ...apFilter, options: laneOptions }
            : apFilter,
        );

        return lookup;
      },
      {},
    );
    return result;
  },
);

export const getAuctionPenLaneFilterOptionsBySaleRoundId =
  saleRoundId => state =>
    selectAuctionFilterOptionsBySaleRoundIdLookup(state)[saleRoundId] ||
    EMPTY_ARRAY;

export const getBobbyCalfAuctionPens = createSelector(
  [getAuctionPensBySaleRoundId, getBobbyCalfSaleRoundId],
  (auctionPens, roundId) => (auctionPens[roundId] ? auctionPens[roundId] : []),
);
export const getUnsoldBobbyCalfAuctionPens = createSelector(
  [getBobbyCalfAuctionPens],
  auctionPens => auctionPens.filter(ap => !ap.sellFile),
);
export const getBobbyCalfSaleLots = createSelector(
  [getBobbyCalfAuctionPens],
  auctionPens => {
    if (auctionPens.length === 0) {
      return [];
    }
    const saleLots = auctionPens.reduce(
      (result, auctionPen) => result.concat(auctionPen.saleLots),
      [],
    );
    return saleLots;
  },
);
export const getUnsoldBobbyCalfSaleLots = createSelector(
  [getUnsoldBobbyCalfAuctionPens],
  auctionPens => {
    if (auctionPens.length === 0) {
      return [];
    }
    const saleLots = auctionPens.reduce(
      (result, auctionPen) => result.concat(auctionPen.saleLots),
      [],
    );
    return saleLots;
  },
);
export const getUnsoldDefaultBobbyCalfSaleLots = createSelector(
  [getUnsoldBobbyCalfSaleLots, currentSaleSelector],
  (saleLots, sale) =>
    saleLots.filter(
      sl =>
        sl.buyerId === sale.default_buyer_id &&
        sl.destinationPropertyId === sale.default_property_id,
    ),
);
