import reduceReducers from "reduce-reducers";

import { SALEYARD } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeSaleyard } from "lib/serializers/saleyard";

const fetch = apiModelOfflineFetchReducer(SALEYARD, {
  deserializer: deserializeSaleyard,
  clearOnRequest: true,
});

const saleyardReducer = reduceReducers(fetch);

export default saleyardReducer;
