import { useSelector } from "react-redux";

import {
  selectCanSeeOtherDeployments,
  getHasWriteAccessInCurrentSale,
  getHasMultipleDeploymentsInCurrentSale,
} from "selectors";

export const ForWithOtherDeployments = ({ children }) => {
  // If a user has visibility over more than one (their own) deployment.
  return useSelector(selectCanSeeOtherDeployments) ? children : null;
};

export const ForHasWriteAccessInCurrentSale = ({ children }) => {
  // If a user DOES have a deployment within a deployment sale in this sale, and none of the accessible deployment sales are locked
  return useSelector(getHasWriteAccessInCurrentSale) ? children : null;
};

export const ForHasNoDeploymentInCurrentSale = ({ children }) => {
  // If a user DOES NOT have a deployment within this sale.
  return useSelector(getHasWriteAccessInCurrentSale) ? null : children;
};

export const ForMultipleDeploymentsInCurrentSale = ({ children }) => {
  return useSelector(getHasMultipleDeploymentsInCurrentSale) ? null : children;
};
