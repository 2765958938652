import React from "react";

import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const tabHeight = 49;
const appHeaderHeight = 63;

export const FullScreenContainer = styled(
  ({ tabsVisible: ignored, ...gridAttrs }) => <Grid {...gridAttrs} />,
).attrs({ container: true })(
  ({ tabsVisible }) => `
  height: calc(100vh - ${
    tabsVisible ? tabHeight + appHeaderHeight : appHeaderHeight
  }px);
  
  flex: 1;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}px) {
    overflow: hidden;
  }  
  &[class*="MuiGrid-spacing"] {
    margin: 0px;
    width: 100%;
  }
`,
);
FullScreenContainer.propTypes = {
  tabsVisible: PropTypes.bool,
};
