import React from "react";

import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import {
  closeConfirmModal,
  deleteSellFile,
  deleteTakeFile,
  openConfirmModal,
  P2PFileAction,
  P2PReversalAction,
  refreshSellFile,
  refreshSellReversal,
  refreshTakeFile,
  refreshTakeReversal,
  reverseSellFile,
  reverseTakeFile,
} from "actions";

import TableWrapper from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import { Column } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import {
  NLISFileTypeHeadings,
  NLISFileTypes,
  NLISSellTypes,
  NLISTakeTypes,
} from "constants/nlis";

import { closeAllHashModals, returnToLocation } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import {
  currentSaleSelector,
  getIsRollbackAvailableByTypeById,
  getIsScaleOperator,
  getNLISTransactionByTypeById,
} from "selectors";

import { useToggle } from "hooks";

import { NLISDocket } from "./NLISDocket";
import { TransfersGrid } from "./TransfersGrid";

export const NLISTransferReceiptModal = React.memo(
  ({ decodedReturnTo, id, transferType }) => {
    const [isViewSummaryShowing, toggleIsViewSummaryShowing] = useToggle(true);

    const closeSelf = () => {
      if (decodedReturnTo) {
        returnToLocation(decodedReturnTo);
      } else {
        closeAllHashModals();
      }
    };

    const transfer = useSelector(
      getNLISTransactionByTypeById(transferType, id),
    );

    const isRollbackAvailable = useSelector(
      getIsRollbackAvailableByTypeById(transferType, id),
    );

    const sale = useSelector(currentSaleSelector);

    const isScaleOperator = useSelector(getIsScaleOperator);
    const saleyardName = isScaleOperator
      ? `${sale.saleyard_name}`
      : `${sale.saleyard_name} Saleyard`;
    const dispatch = useDispatch();

    // Make the from and to contextual based on the transfer type.
    const from = React.useMemo(() => {
      if (NLISTakeTypes.includes(transferType)) {
        if (transfer.consignmentDisplay) {
          return transfer.consignmentDisplay;
        }

        const num = transfer.consignments?.length;
        return `${num} ${pluralize("Consignment", num)}`;
      } else if (NLISSellTypes.includes(transferType)) {
        return saleyardName;
      }
    }, [transferType, saleyardName, transfer]);

    const to = React.useMemo(() => {
      if (NLISTakeTypes.includes(transferType)) {
        return saleyardName;
      } else if (NLISSellTypes.includes(transferType)) {
        const num = transfer.saleLots?.length;
        return `${num} ${pluralize("Sale Lot", num)}`;
      }
    }, [transferType, saleyardName, transfer]);

    if (!transfer) {
      return null;
    }

    // Figure out what Rollback / Refresh Function to use based on the type.
    const handleRefresh = () => {
      const payload = {
        object: {
          id: transfer.id,
          livestock_sale_id: sale.livestocksale_id,
          saleyard_name: sale.saleyard_name,
        },
      };

      if (transferType === NLISFileTypes.NLISSellFile) {
        dispatch(refreshSellFile(payload));
      } else if (transferType === NLISFileTypes.NLISSellReversalFile) {
        dispatch(refreshSellReversal(payload));
      } else if (transferType === NLISFileTypes.NLISTakeFile) {
        dispatch(refreshTakeFile(payload));
      } else if (transferType === NLISFileTypes.NLISTakeReversalFile) {
        dispatch(refreshTakeReversal(payload));
      }

      return null;
    };

    const handleRollback = () => {
      if (transferType === NLISFileTypes.NLISSellFile) {
        dispatch(
          openConfirmModal({
            title: "Are you sure?",
            message: `Rolling back this transfer will notify the NLIS that these animals are back in the possession of ${sale.saleyard_name}.`,
            actions: [
              {
                label: "Cancel",
                secondary: true,
                onClick: () => {
                  dispatch(closeConfirmModal());
                },
              },
              {
                label: "Roll Back",
                onClick: () => {
                  dispatch(reverseSellFile(sale, transfer.id));
                  dispatch(closeConfirmModal());
                  closeAllHashModals();
                },
              },
            ],
          }),
        );
      }
      if (transferType === NLISFileTypes.NLISTakeFile) {
        dispatch(
          openConfirmModal({
            title: "Are you sure?",
            message: `Rolling back this transfer will notify the NLIS that these animals are back in the possession of their respective vendors.`,
            actions: [
              {
                label: "Cancel",
                secondary: true,
                onClick: () => {
                  dispatch(closeConfirmModal());
                },
              },
              {
                label: "Roll Back",
                onClick: () => {
                  dispatch(reverseTakeFile(sale, transfer.id));
                  dispatch(closeConfirmModal());
                  closeAllHashModals();
                },
              },
            ],
          }),
        );
      }
      if (transferType === NLISFileTypes.NLISP2PTransferFile) {
        dispatch(
          openConfirmModal({
            title: "Are you sure?",
            message: `Rolling back this transfer will notify the NLIS that these animals are back in the possession of their vendor.`,
            actions: [
              {
                label: "Cancel",
                secondary: true,
                onClick: () => {
                  dispatch(closeConfirmModal());
                },
              },
              {
                label: "Roll Back",
                onClick: () => {
                  dispatch(
                    P2PReversalAction.create({
                      id: uuidv4(),
                      reversalOf: transfer.id,
                    }),
                  );
                  dispatch(closeConfirmModal());
                  closeAllHashModals();
                },
              },
            ],
          }),
        );
      }
    };

    // TODO: update the email message content so it works as before.
    // const messageContent = () => {

    //   const getConsignmentMessageContent = (
    //     transactionId,
    //     nvd,
    //     vendorPIC,
    //     created,
    //     eids,
    //   ) => `Transaction id: ${transactionId}
    //   NVD Number: ${nvd}
    //   Vendor PIC: ${vendorPIC}
    //   Taken on: ${created ? formatDateString(dateTimeStringToDateTime(created)) : "-"}
    //   ${eids.length} Animals:
    //   ${eids.join("\n")}
    //   `;

    //   const getAuctionPenMessageContent = (transactionId, buyerPIC, created, eids) =>
    //     `Transaction id: ${transactionId}
    //   Buyer PIC: ${buyerPIC}
    //   Sold on: ${created ? formatDateString(dateTimeStringToDateTime(created)) : "-"}
    //   ${eids.length} Animals:
    //   ${eids.join("\n")}
    //   `;
    // }

    const onClickIgnore = () => {
      let deleteAction = null;
      if (transferType === NLISFileTypes.NLISTakeFile) {
        deleteAction = deleteTakeFile;
      } else if (transferType === NLISFileTypes.NLISSellFile) {
        deleteAction = deleteSellFile;
      } else if (transferType === NLISFileTypes.NLISP2PTransferFile) {
        deleteAction = P2PFileAction.delete;
      } else {
        throw Error(
          `Transfer type ${transferType} does not yet support ignoring.`,
        );
      }

      dispatch(
        openConfirmModal({
          title: "Are you sure?",
          message:
            "Ignoring the results of this Transfer will allow you to perform another NLIS Transfer.",
          actions: [
            {
              label: "Cancel",
              secondary: true,
              onClick: () => {
                dispatch(closeConfirmModal());
              },
            },
            {
              label: "Ignore Transfer",
              onClick: () => {
                dispatch(deleteAction(transfer.id));
                dispatch(closeConfirmModal());
                closeSelf();
              },
            },
          ],
        }),
      );
    };
    const hasDetail = transfer.transfers?.length > 0;

    return (
      <Dialog
        open
        onClose={closeSelf}
        maxWidth={isViewSummaryShowing ? "sm" : "lg"}
        fullWidth
        fullScreen={!isViewSummaryShowing}
      >
        <DialogTitle onClose={closeSelf}>
          {NLISFileTypeHeadings[transfer.transferType] || transfer.transferType}
        </DialogTitle>
        <DialogContent dividers style={{ maxHeight: "85vh" }}>
          {isViewSummaryShowing ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <NLISDocket
                  from={from}
                  headcount={transfer.eidCount}
                  isFetching={false}
                  nlisStatus={transfer}
                  onClickIgnore={onClickIgnore}
                  refreshClick={handleRefresh}
                  rollbackClick={isRollbackAvailable ? handleRollback : null}
                  to={to}
                />
              </Grid>
            </Grid>
          ) : (
            hasDetail && (
              <Column fullWidth fullHeight>
                <TableWrapper>
                  <TransfersGrid transfers={transfer.transfers} />
                </TableWrapper>
              </Column>
            )
          )}
        </DialogContent>

        <DialogActions>
          {hasDetail && (
            <Button
              data-tour={isViewSummaryShowing ? "viewDetail" : "viewSummary"}
              onClick={toggleIsViewSummaryShowing}
            >
              {isViewSummaryShowing ? "View Detail" : "View Summary"}
            </Button>
          )}
          <Button onClick={closeSelf}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  },
);
