import React, { Children } from "react";

import { useSelector } from "react-redux";

import { getOnlineStatus } from "selectors";

/**
 * Used to wrap a single component ie.g. a button and set 'disabled' prop if the
 * app seems to be offline.
 */
export const OnlineOnly = ({ children }) => {
  const { status, changesOutboxSize } = useSelector(getOnlineStatus);
  const element = Children.only(children) || <></>;
  const isDisabled = element.props?.disabled || !status || !!changesOutboxSize;

  return React.cloneElement(element, { disabled: isDisabled });
};
