import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { saleLotMerge } from "actions";

import { Button } from "components/Form";
import { Column, Row } from "components/Layout";
import { CompactLotCard } from "components/LotCard/CompactLotCard";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import MessageBox from "components/MessageBox";

import CompactSaleLotCardList from "containers/WeighbridgeSaleView/CompactSaleLotCardList";

import {
  closeAllHashModals,
  openEditSaleLotModal,
  returnToLocation,
} from "lib/navigation";
import { getCombinedLotNumber } from "lib/saleLot";

import { getSaleLotsBySale } from "selectors";

export const MergeSalelotModal = ({ saleLotId, returnTo }) => {
  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const vendorLots = useSelector(state => {
    const allLots = getSaleLotsBySale(state);
    const referenceLot = allLots.find(({ id }) => id === saleLotId);
    return allLots.filter(
      saleLot =>
        saleLot.vendor_id === referenceLot.vendor_id &&
        saleLot.livestocksale_id === referenceLot.livestocksale_id,
    );
  });

  const [selectedLotId, setSelectedLot] = useState(null);
  const [toSaleLotId, setToSaleLotId] = useState(null);
  const [startSubmit, setStartSubmit] = useState(null);

  const dispatch = useDispatch();
  const handleSubmit = (fromLotId, toLotId) => {
    dispatch(saleLotMerge(fromLotId, toLotId));
    openEditSaleLotModal(toLotId);
  };
  const submitEnabled = !!(setToSaleLotId && toSaleLotId);

  const [thisLot, otherSelectedLot, otherLotIds] = vendorLots.reduce(
    ([thisLot, otherSelectedLot, otherLotsIds], currLot) => {
      if (currLot.id === saleLotId) {
        return [currLot, otherSelectedLot, otherLotsIds];
      } else if (currLot.id === selectedLotId) {
        return [thisLot, currLot, otherLotsIds.concat(currLot.id)];
      } else {
        return [thisLot, otherSelectedLot, otherLotsIds.concat(currLot.id)];
      }
    },
    [null, null, []],
  );

  const handleBack = () => {
    if (selectedLotId) {
      setSelectedLot(null);
      setStartSubmit(false);
      setToSaleLotId(null);
    } else {
      closeSelf();
    }
  };
  const handleStartSubmit = () => setStartSubmit(true);

  useEffect(() => {
    if (!otherSelectedLot) {
      setSelectedLot(null);
    }
  }, [otherSelectedLot]);

  return (
    <Dialog
      data-tour="mergeLotsDialog"
      open
      onClose={closeSelf}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={closeSelf}>Merge Lots</DialogTitle>
      <DialogContent dividers>
        <MergeLotContent
          otherLotIds={otherLotIds}
          thisLot={thisLot}
          selectedLotId={selectedLotId}
          setSelectedLot={setSelectedLot}
          vendorLots={vendorLots}
          toSaleLotId={toSaleLotId}
          setToSaleLotId={setToSaleLotId}
          otherSelectedLot={otherSelectedLot}
          handleBack={handleBack}
          startSubmit={startSubmit}
          handleSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBack}>Back</Button>
        <Button
          data-tour="submit"
          onClick={handleStartSubmit}
          disabled={!submitEnabled}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmDialog = ({ fromLot, toLot, closeSelf, handleSubmit }) => (
  <Dialog open onClose={closeSelf} maxWidth="sm" fullWidth>
    <DialogTitle id="form-dialog-title" onClose={closeSelf}>
      Confirm Merge
    </DialogTitle>
    <DialogContent dividers>
      <div>
        {`Are you sure you want to move ${fromLot.quantity} animals and ${
          fromLot.scannedCount
        } scans from lot #${getCombinedLotNumber(
          fromLot,
        )} into lot #${getCombinedLotNumber(toLot)}`}
      </div>
      <div>
        {`This will delete lot #${getCombinedLotNumber(
          fromLot,
        )} and cannot be undone`}
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeSelf}>Cancel</Button>
      <Button
        data-tour="confirmMerge"
        onClick={() => handleSubmit(fromLot.id, toLot.id)}
      >
        Confirm Merge
      </Button>
    </DialogActions>
  </Dialog>
);

const MergeLotContent = ({
  otherLotIds,
  thisLot,
  selectedLotId,
  setSelectedLot,
  vendorLots,
  toSaleLotId,
  setToSaleLotId,
  otherSelectedLot,
  handleBack,
  startSubmit,
  handleSubmit,
}) => {
  if (otherLotIds.length === 0) {
    return (
      <MessageBox>
        No other lots found in this sale belonging to{" "}
        {thisLot.vendor?.name || "this vendor"}.
      </MessageBox>
    );
  }
  if (!selectedLotId) {
    return (
      <>
        <Row justifyCenter alignCenter>
          <h3>Merge</h3>
        </Row>
        <CompactLotCard {...thisLot} selected />
        <Row justifyCenter alignCenter>
          <h3>With (tap to select)</h3>
        </Row>
        <CompactSaleLotCardList
          orderedIds={otherLotIds}
          selectedIds={[selectedLotId]}
          onSelectSaleLot={setSelectedLot}
        />
      </>
    );
  } else {
    const toLot = vendorLots.find(l => l.id === toSaleLotId);
    const fromLot = vendorLots.find(
      l => l.id === (toSaleLotId === thisLot.id ? selectedLotId : thisLot.id),
    );

    return (
      <>
        <Column justifyCenter alignCenter full>
          <h3>Select which lot to keep.</h3>
          <div>
            Only headcount, weight and scans will be merged, all other
            information such as sale and species information will be retained
            from the selected lot only.
          </div>
        </Column>
        <CompactLotCard
          {...thisLot}
          onClick={() => setToSaleLotId(thisLot.id)}
          selected={toSaleLotId === thisLot.id}
        />

        <CompactLotCard
          {...otherSelectedLot}
          onClick={() => setToSaleLotId(otherSelectedLot.id)}
          selected={toSaleLotId === otherSelectedLot.id}
        />
        {startSubmit && (
          <ConfirmDialog
            fromLot={fromLot}
            toLot={toLot}
            closeSelf={handleBack}
            handleSubmit={handleSubmit}
          />
        )}
      </>
    );
  }
};
