import React from "react";

import { Grid } from "@material-ui/core";
import styled from "styled-components/macro";

const GridContainer = styled(Grid)`
  height: 100%;
`;

const GridBody = styled(
  ({ roundedSide: ignored, backgroundColor: ignored2, ...gridAttrs }) => (
    <Grid {...gridAttrs} />
  ),
)`
  flex-grow: 1;
  overflow: auto;
  height: 100%;

  ${({ theme, roundedSide, backgroundColor }) => `
    ${backgroundColor ? `background-color: ${backgroundColor};` : ""}
    ${
      roundedSide
        ? `
    border-top-${roundedSide}-radius: ${theme.radii[4]}px;
    border-bottom-${roundedSide}-radius: ${theme.radii[4]}px;    
    `
        : ""
    }
      `}
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Make scrollbar invisible */
  }
`;

const ScrollingColumnTitle = styled(Grid)`
  flex-grow: 0;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  ${({ theme }) => `
  
          font-size: ${theme.fontSizes.delta}px;
          height: ${theme.fontSizes.eta}px;
          line-height: ${theme.fontSizes.eta}px;
          font-weight: ${theme.fontWeights.bold};
          color: ${theme.colors.primary};


          `}
`;

const OrderedGrid = styled(Grid)`
  ${({ theme, mobileOrder }) => `
  @media (max-width: ${theme.breakpoints[2]}px) {
      order: ${mobileOrder};
  }`}
`;

export const ScrollingGrid = styled(Grid)`
  overflow: hidden;
  height: 100%;
`;

export class ScrollingColumn extends React.Component {
  render() {
    // This innerRef is a requirement when wrapping in a WayPoint - it gives a DOM element for sizing.
    const { title, children, columnProps, roundedSide, innerRef } = this.props;
    return (
      <OrderedGrid item {...columnProps} ref={innerRef}>
        <GridContainer container direction="column" wrap="nowrap">
          <ScrollingColumnTitle item>{title}</ScrollingColumnTitle>
          <GridBody item roundedSide={roundedSide}>
            {children}
          </GridBody>
        </GridContainer>
      </OrderedGrid>
    );
  }
}
