import React from "react";

import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import { Column } from "components/Layout";

const WrapperCircle = styled(Column)`
  margin-right: 12px;
`;

const Circle = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme, color }) =>
    color ? theme.colors.white : theme.colors.gray44};
  line-height: 36px;
  text-align: center;
  background: ${({ theme, color }) =>
    theme.colors[color] || theme.colors.white};
`;

const UserInitials = ({ initials, color }) => (
  <WrapperCircle data-tour="userInitials">
    <Circle color={color}>{initials}</Circle>
  </WrapperCircle>
);

UserInitials.propTypes = {
  initials: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default UserInitials;
