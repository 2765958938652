// @ts-check

import {
  faQuestionCircle,
  faExclamationCircle,
  faTimesCircle,
  faCheckCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";

import {
  EID_TRANSFER_STATUS,
  FORCE_SUCCESS_CODES,
  NLISFileTypes,
  TRANSFER_STATUS,
} from "constants/nlis";
import { colors } from "constants/theme";

// alert types
export const alert = {
  mismatch: {
    statusColor: colors.primary,
    sidebarColor: colors.warning,
    texts: [
      "Booked vs scanned counts do not match. Scan or edit the consignment before you can take.",
    ],
  },
  unresolved: {
    statusColor: colors.warningOrange,
    sidebarColor: colors.warning,
    texts: [],
  },
  take: {
    statusColor: colors.primary,
    sidebarColor: colors.successGreen,
    texts: [],
  },
  success: {
    statusColor: colors.successGreen,
    sidebarColor: colors.successGreen,
    iconColor: colors.successGreen,
    texts: [],
  },
  warning: {
    statusColor: colors.successGreen,
    sidebarColor: colors.successGreen,
    iconColor: colors.warningOrange,
    texts: [],
  },
  failed: {
    statusColor: colors.gray40,
    sidebarColor: colors.deleteRed,
    iconColor: colors.deleteRed,
    texts: [],
  },
  pending: {
    statusColor: colors.gray40,
    sidebarColor: colors.gray40,
    iconColor: colors.gray40,
    texts: [],
  },
};

// categorised EIDs by their status
export const categorisedEID = transaction => {
  const success = [];
  const warning = [];
  const error = [];

  if (transaction && transaction.transfers) {
    for (const transfer of transaction.transfers) {
      if (transfer.eids) {
        for (const eid of transfer.eids) {
          if (eid.response_type) {
            if (FORCE_SUCCESS_CODES.includes(eid.message_no)) {
              success.push(eid);
            } else if (eid.response_type === EID_TRANSFER_STATUS.SUCCESS) {
              success.push(eid);
            } else if (eid.response_type === EID_TRANSFER_STATUS.WARNING) {
              warning.push(eid);
            } else if (eid.response_type === EID_TRANSFER_STATUS.ERROR) {
              error.push(eid);
            }
          }
        }
      }
    }
  }
  return { successEID: success, warningEID: warning, errorEID: error };
};

// returns NLIS status by checking EIDs' status
export const getEIDStatus = transaction => {
  if (transaction.status === TRANSFER_STATUS.PENDING_ROLLBACK) {
    return EID_TRANSFER_STATUS.PENDING;
  }
  if (transaction.status === TRANSFER_STATUS.ROLLED_BACK) {
    return EID_TRANSFER_STATUS.ROLLED_BACK;
  }

  const { successEID, warningEID, errorEID } = categorisedEID(transaction);

  if (successEID.length && !warningEID.length && !errorEID.length) {
    return EID_TRANSFER_STATUS.SUCCESS;
  } else if (errorEID.length && successEID.length) {
    return EID_TRANSFER_STATUS.WARNING;
  } else if (errorEID.length) {
    return EID_TRANSFER_STATUS.ERROR;
  } else if (warningEID.length) {
    return EID_TRANSFER_STATUS.WARNING;
  }
  return EID_TRANSFER_STATUS.PENDING;
};

const getIconAndColor = status => {
  switch (status) {
    case EID_TRANSFER_STATUS.SUCCESS:
      return { icon: faCheckCircle, color: alert.success.iconColor };
    case EID_TRANSFER_STATUS.ERROR:
      return { icon: faTimesCircle, color: alert.failed.iconColor };
    case EID_TRANSFER_STATUS.WARNING:
      return { icon: faExclamationCircle, color: alert.warning.iconColor };
    default:
      return { icon: faQuestionCircle, color: alert.pending.iconColor };
  }
};

export const getOverallIconAndColor = transaction => {
  const { status } = transaction;
  // If we're a big error, show it.
  if (status === TRANSFER_STATUS.FAILED) {
    return { icon: faTimesCircle, color: alert.failed.iconColor };
  } else if (status === TRANSFER_STATUS.ROLLED_BACK) {
    return { icon: faUndo, color: alert.failed.warning };
  } else if (status === TRANSFER_STATUS.PENDING_ROLLBACK) {
    return { icon: faUndo, color: alert.pending.iconColor };
  } else if (status === TRANSFER_STATUS.ACCEPTED) {
    return { icon: faCheckCircle, color: alert.success.iconColor };
  }
  const overallStatus = getEIDStatus(transaction);
  return getIconAndColor(overallStatus);
};

export const getIndividualIconAndColor = individualStatus =>
  getIconAndColor(individualStatus);

// returns transfer status text
export const getTransferFileStatusText = transaction => {
  if (transaction && transaction.status) {
    const key = Object.keys(TRANSFER_STATUS).find(
      key => TRANSFER_STATUS[key] === transaction.status,
    );
    if (key) {
      // If it's not yet submitted, it's functionally PENDING for an end user
      if (transaction.status === TRANSFER_STATUS.UNSUBMITTED) {
        return Object.keys(TRANSFER_STATUS).find(
          key => TRANSFER_STATUS[key] === TRANSFER_STATUS.PENDING,
        );
      }
      // handling special case - parent status is success even though there are errors in sub items
      if (
        transaction.status === TRANSFER_STATUS.SUCCESS &&
        getEIDStatus(transaction) === EID_TRANSFER_STATUS.ERROR
      ) {
        return Object.keys(TRANSFER_STATUS).find(
          key => TRANSFER_STATUS[key] === TRANSFER_STATUS.FAILED,
        );
      }
      return key;
    }
  }
  return null;
};

// returns an alert instance by status
export const getAlertByStatus = transaction => {
  if (transaction) {
    const { status } = transaction;
    switch (status) {
      case TRANSFER_STATUS.SUCCESS:
        const subStatus = getEIDStatus(transaction);
        if (subStatus === EID_TRANSFER_STATUS.SUCCESS) {
          return alert.success;
        } else if (subStatus === EID_TRANSFER_STATUS.WARNING) {
          return alert.warning;
        } else if (subStatus === EID_TRANSFER_STATUS.ERROR) {
          return alert.failed;
        } else {
          return alert.pending;
        }
      case TRANSFER_STATUS.FAILED:
        return alert.failed;
      case TRANSFER_STATUS.PENDING:
        return alert.pending;
      default:
        return null;
    }
  }
  return null;
};

// returns default color
export const getDefaultStatusColor = () => colors.gray40;

// returns unresolved alert
export const getUnresolvedAlert = exceptions => {
  const tempAlert = alert.unresolved;

  if (exceptions && exceptions.length) {
    tempAlert.texts = exceptions;
  }
  return tempAlert;
};

export const getIsTransferIgnorable = nlisTransfer =>
  Boolean(nlisTransfer) &&
  nlisTransfer.status === TRANSFER_STATUS.FAILED &&
  (nlisTransfer.transferType === NLISFileTypes.NLISTakeFile ||
    nlisTransfer.transferType === NLISFileTypes.NLISSellFile);
