import React from "react";

import { faEye, faPencil } from "@fortawesome/pro-solid-svg-icons";

import { ColumnType } from "components/AgGrid/constants";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";
import { ReceivalLotPermissions } from "constants/permissions";

import { openEditConsignmentModal, openModalLink } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import { AgGridActionButtons } from "./actions";

export const ReceivalLotColId = {
  PEN: "e65b3346-04d9-4cbf-9c6f-16987f4b1320",
  MARK: "d7267660-b070-4430-bb36-c49b3ec793c3",
  SCANS_COUNT: "34467fb7-4ce7-48b7-81ea-f099d21cebf6",
  QUANTITY: "45fc480b-8d5c-4974-b359-948cdb488ccc",
  VENDOR_NAME: "10461ead-4b87-44b1-9f0e-8d77dbf9718e",
  AGENCY: "47503090-5133-492c-ab7c-abb44da0ebb9",
  NVD: "9c4b204b-273f-4be7-bcc1-e6208af2d8e2",
  EDIT: "68a22ee6-afb3-4fca-9bbd-7c1a9016cd7d",
};

export const ReceivalLotColumnDefinitions = {
  [ReceivalLotColId.PEN]: {
    colId: ReceivalLotColId.PEN,
    field: "receivalLot.penDisplayName",
    headerName: "Pen",
    width: 90,
    type: ColumnType.STRING,
  },
  [ReceivalLotColId.MARK]: {
    colId: ReceivalLotColId.MARK,
    field: "receivalLot.mark",
    headerName: "Mark",
    width: 90,
    type: ColumnType.STRING,
  },

  [ReceivalLotColId.SCANS_COUNT]: {
    colId: ReceivalLotColId.SCANS_COUNT,
    field: "receivalLot.scansCount",
    headerName: "Scan Count",
    width: 110,
    type: ColumnType.NUMERIC,
  },
  [ReceivalLotColId.QUANTITY]: {
    colId: ReceivalLotColId.QUANTITY,
    field: "receivalLot.quantity",
    headerName: "Quantity",
    width: 90,
    type: ColumnType.NUMERIC,
  },
  [ReceivalLotColId.VENDOR_NAME]: {
    colId: ReceivalLotColId.VENDOR_NAME,
    field: "receivalLot.consignment.vendor.name",
    headerName: "Vendor",
    type: ColumnType.STRING,
  },
  [ReceivalLotColId.AGENCY]: {
    colId: ReceivalLotColId.AGENCY,
    field: "receivalLot.agency.name",
    headerName: "Agency",
    type: ColumnType.STRING,
  },
  [ReceivalLotColId.NVD]: {
    colId: ReceivalLotColId.NVD,
    field: "receivalLot.consignment.NVD",
    headerName: "NVD",
    type: ColumnType.STRING,
  },
  [ReceivalLotColId.EDIT]: {
    colId: ReceivalLotColId.EDIT,
    field: "receivalLot.id",
    type: ColumnType.EDIT,
    headerName: ACTION_COLUMN_NAME,
  },
};

export function ReceivalLotActionsColumnRenderer(props) {
  const { data } = props;

  if (!data) {
    return null;
  }

  const { receivalLot, consignment } = data;

  const readOnly = !hasPermission(receivalLot, ReceivalLotPermissions.update);

  const actions = [
    {
      onClick: () =>
        openModalLink(ModalTypes.EditReceivalLot, {
          receivalLotId: receivalLot.id,
        }),
      dataTour: "editReceivalLot",
      icon: readOnly ? faEye : faPencil,
      title: readOnly ? ` View` : ` Edit`,
    },
    {
      onClick: () => openEditConsignmentModal(consignment.id),
      dataTour: "editConsignment",
      icon: readOnly ? faEye : faPencil,
      title: " Consignment",
    },
  ];

  return <AgGridActionButtons actions={actions} />;
}
