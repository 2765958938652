import { all, put, takeLatest } from "redux-saga/effects";

import {
  AuctionPenAction,
  BidderRegistrationAction,
  BillingDocumentAction,
  BillingRunAction,
  BusinessAction,
  CheckpointAction,
  ContactBillingDocumentAction,
  DeploymentAction,
  DeploymentSaleCommentAction,
  EnvdDocumentAction,
  GlobalBusinessesAction,
  IntegrationBusinessAction,
  LedgerEntryAction,
  ManualAdjustmentAction,
  MasterRuleAction,
  MasterRuleBookAction,
  NominationAction,
  PaymentAction,
  RuleAction,
  RuleBookAction,
  SaleAction,
  SaleLotAction,
  SavedViewAction,
  WeighLotAction,
  WeighLotScanAction,
} from "actions";

import { CommentAction } from "actions/comments";
import { EmailAction } from "actions/email";
import { InterestSettingsAction } from "actions/interest";
import { LedgerAccountAction } from "actions/ledgerAccounts";
import { MasterLedgerAccountAction } from "actions/masterLedgerAccounts";
import { AddDeploymentSaleCommentAction } from "actions/offline/deploymentSale";
import { PenScanLotAction } from "actions/penScanLots";
import { ReceivalLotAction } from "actions/receivalLots";
import { ReportJobAction } from "actions/reportJob";
import { SundryTemplateAction } from "actions/sundryTemplates";
import { TradingTermAction } from "actions/tradingTerms";
import { VendorCommissionBandAction } from "actions/vendorCommissionBands";
import {
  DefaultVendorSplitAction,
  SaleVendorSplitAction,
} from "actions/vendorSplits";

import {
  AUCTION_PEN,
  BIDDER_REGISTRATION,
  BILLING_DOCUMENT,
  BILLING_RUN,
  BUSINESS,
  CHECKPOINT,
  COMMENT,
  CONTACT_BILLING_DOCUMENT,
  DEFAULT_VENDOR_SPLIT,
  DEPLOYMENT,
  DEPLOYMENT_SALE,
  DEPLOYMENT_SALE_COMMENT,
  EMAIL,
  ENVD_DOCUMENT,
  GLOBAL_BUSINESSES,
  INTEGRATION_BUSINESS,
  INTEREST_SETTING,
  LEDGER_ACCOUNT,
  LEDGER_ENTRY,
  MANUAL_ADJUSTMENT,
  MASTER_LEDGER_ACCOUNT,
  MASTER_RULE,
  MASTER_RULE_BOOK,
  NOMINATION,
  PAYMENT,
  PEN_SCAN_LOT,
  RECEIVAL_LOT,
  REPORT_JOB,
  RULE,
  RULE_BOOK,
  SALE,
  SALE_LOT,
  SALE_VENDOR_SPLIT,
  SAVED_VIEW,
  SUNDRY_TEMPLATE,
  TRADING_TERM,
  VENDOR_COMMISSION_BAND,
  WEIGH_LOT,
  WEIGH_LOT_SCAN,
} from "constants/actionTypes";

/** *
 * Add more actionTypes and action functions here to handle cached requests that have changes since.
 */
const changesSinceableActionTypes = [
  [AUCTION_PEN, AuctionPenAction.requestChanges],
  [BIDDER_REGISTRATION, BidderRegistrationAction.requestChanges],
  [BILLING_DOCUMENT, BillingDocumentAction.requestChanges],
  [BILLING_RUN, BillingRunAction.requestChanges],
  [BUSINESS, BusinessAction.requestChanges],
  [CHECKPOINT, CheckpointAction.requestChanges],
  [COMMENT, CommentAction.requestChanges],
  [CONTACT_BILLING_DOCUMENT, ContactBillingDocumentAction.requestChanges],
  [DEPLOYMENT, DeploymentAction.requestChanges],
  [DEPLOYMENT_SALE, AddDeploymentSaleCommentAction.requestChanges],
  [DEPLOYMENT_SALE_COMMENT, DeploymentSaleCommentAction.requestChanges],
  [EMAIL, EmailAction.requestChanges],
  [ENVD_DOCUMENT, EnvdDocumentAction.requestChanges],
  [GLOBAL_BUSINESSES, GlobalBusinessesAction.requestChanges],
  [INTEGRATION_BUSINESS, IntegrationBusinessAction.requestChanges],
  [LEDGER_ENTRY, LedgerEntryAction.requestChanges],
  [MANUAL_ADJUSTMENT, ManualAdjustmentAction.requestChanges],
  [NOMINATION, NominationAction.requestChanges],
  [PAYMENT, PaymentAction.requestChanges],
  [RECEIVAL_LOT, ReceivalLotAction.requestChanges],
  [PEN_SCAN_LOT, PenScanLotAction.requestChanges],
  [REPORT_JOB, ReportJobAction.requestChanges],
  [RULE, RuleAction.requestChanges],
  [RULE_BOOK, RuleBookAction.requestChanges],
  [WEIGH_LOT, WeighLotAction.requestChanges],
  [WEIGH_LOT_SCAN, WeighLotScanAction.requestChanges],
  [SALE, SaleAction.requestChanges],
  [SALE_LOT, SaleLotAction.requestChanges],
  [SAVED_VIEW, SavedViewAction.requestChanges],
  [VENDOR_COMMISSION_BAND, VendorCommissionBandAction.requestChanges],
  [INTEREST_SETTING, InterestSettingsAction.requestChanges],
  [LEDGER_ACCOUNT, LedgerAccountAction.requestChanges],
  [MASTER_LEDGER_ACCOUNT, MasterLedgerAccountAction.requestChanges],
  [MASTER_RULE, MasterRuleAction.requestChanges],
  [MASTER_RULE_BOOK, MasterRuleBookAction.requestChanges],
  [DEFAULT_VENDOR_SPLIT, DefaultVendorSplitAction.requestChanges],
  [SALE_VENDOR_SPLIT, SaleVendorSplitAction.requestChanges],
  [SUNDRY_TEMPLATE, SundryTemplateAction.requestChanges],
  [TRADING_TERM, TradingTermAction.requestChanges],
];

const handlers = changesSinceableActionTypes.reduce(
  (acc, [actionType, action]) => {
    function* handler(result) {
      if (result.meta.cacheHit) {
        yield put(
          action({
            // Include the same base URL options in the changes since, so that we are calling changes since for the same saleyard etc.
            ...result.meta.options,
            changesSince: result.meta.lastModifiedTimestamp,
          }),
        );
      }
    }

    acc[actionType.FETCH_BULK.SUCCESS] = handler;
    return acc;
  },
  {},
);

export default function* changesSinceSagas() {
  yield all(
    Object.entries(handlers).map(([action, handle]) =>
      takeLatest(action, handle),
    ),
  );
}
