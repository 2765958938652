import React, { useEffect } from "react";

import sortBy from "lodash/sortBy";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { printPenWeighCardLandscape } from "actions";

import OpenedInNewWindow from "components/Pdfs/OpenedInNewWindow";

import { getConsignmentsHgpDetails } from "lib/consignments";
import { createAndOpenPdf } from "lib/pdf";
import { getAverageWeightKg } from "lib/saleLot";
import { formatTime, getAUFormattedDate } from "lib/timeFormats";

import {
  getIsFetchingAuctionPens,
  getIsFetchingBusinesses,
  getIsFetchingConsignments,
  getIsFetchingSaleLots,
  getIsFetchingSales,
  getIsFetchingScans,
} from "selectors";

import logo from "img/AgriNous-logo-base64";

const pageMargin = 12;
const pageMarginBottom = 40;

const getFooterText = (
  { saleyardName, roundName, startPen, saleDate },
  showPen = true,
  currentPage,
  pageCount,
) => {
  return `${saleyardName} ${(
    roundName || ""
  ).toUpperCase()} Sale ${getAUFormattedDate(saleDate)} ${
    showPen ? `- Pen: ${startPen}` : `Page ${currentPage}/${pageCount}`
  }  Printed: ${formatTime(Date.now())}`;
};

const getFooter =
  (weighPenCardData, pageMargin) => (currentPage, pageCount) => ({
    layout: "noBorders",
    table: {
      margin: [pageMargin + 3, 0],
      widths: [50, 450, "*"],
      body: [
        [
          {
            alignment: "left",
            text: "",
          },
          {
            text: "The auctioneer, its agents and employees accept no responsibility for any errors or omissions howsoever arising or occurring in the description of this lot and liability to any person who relies on this description is disclaimed.",
            alignment: "left",
            fontSize: 7,
            noWrap: true,
          },
          {
            alignment: "left",
            text: "",
          },
        ],
        [
          {
            image: `data:image/jpeg;base64,${logo}`,
            width: 100,
            marginLeft: 5,
          },
          {
            marginTop: 4,
            marginLeft: 50,
            alignment: "left",
            text: weighPenCardData[currentPage - 1]
              ? getFooterText(
                  weighPenCardData[currentPage - 1].footerData,
                  false,
                  pageCount,
                  currentPage,
                )
              : "",
          },
          {
            alignment: "left",
            text: "",
          },
        ],
      ],
    },
  });

const getContent = weighPenCardData => {
  const contents = weighPenCardData.map((data, index, array) =>
    [
      {
        layout: {
          hLineWidth(i) {
            return i > 2 ? 2 : 0;
          },
          vLineWidth() {
            return 0;
          },
          hLineColor() {
            return "black";
          },
          vLineColor() {
            return 0;
          },
        },
        table: {
          headerRows: 3,
          widths: [165, 150, 150, 130, 160],
          body: [
            [
              {
                image: "saleLogo",
                width: 200,
                height: 45,
                alignment: "left",
                margin: [20, 0, 0, 0],
              },
              { fontSize: 20, text: "", alignment: "center" },
              {
                text: data.agencyName,
                fontSize: 40,
                alignment: "center",
                bold: true,
                noWrap: true,
              },
              { fontSize: 20, text: "", alignment: "center" },
              { fontSize: 20, text: "", alignment: "center" },
            ],
            [
              {
                text: data.penName,
                fontSize: 75,
                alignment: "center",
                bold: true,
                noWrap: true,
              },
              { fontSize: 20, text: "", alignment: "center" },
              { fontSize: 20, text: "", alignment: "center" },
              { fontSize: 20, text: "", alignment: "center" },
              {
                text: data.penName,
                fontSize: 75,
                alignment: "center",
                bold: true,
                noWrap: true,
              },
            ],
            [
              { fontSize: 30, text: "Head", alignment: "center" },
              {
                text: "Sex",
                fontSize: 30,
                alignment: "center",
              },
              { fontSize: 30, text: "Mark", alignment: "center" },
              { fontSize: 30, text: "Total kg", alignment: "center" },
              {
                text: "Avg. kg",
                fontSize: 30,
                alignment: "center",
              },
            ],
            ...sortBy(data.saleLots, "lot_number").map(s => {
              const avgWeight = Math.round(getAverageWeightKg(s));
              const totalWeight = Math.round(
                parseFloat(s.total_weight.replace(",", "")),
              );
              const hasHgp = getConsignmentsHgpDetails([s?.consignment]);

              return [
                [
                  {
                    text: s.quantity,
                    alignment: "center",
                    fontSize: 45,
                  },
                  { text: " ", alignment: "center", fontSize: 10 },
                  {
                    text: ["HGP: ", { text: hasHgp, bold: true }],
                    alignment: "center",
                    fontSize: 35,
                  },
                ],
                [
                  {
                    text: s.sex_name || " ",
                    alignment: "center",
                    fontSize: 45,
                    noWrap: true,
                  },
                  { text: " ", alignment: "center", fontSize: 10 },
                  {
                    text: ["SAE: ", { text: data.isSaudiEligible, bold: true }],
                    alignment: "center",
                    fontSize: 35,
                  },
                ],
                [
                  {
                    text:
                      data.saleLotsInMarkOrder[s.id].map(
                        mark => mark.mark.location,
                      ).length > 0
                        ? data.saleLotsInMarkOrder[s.id].map(
                            mark => mark.mark.location,
                          )
                        : "-",
                    alignment: "center",
                    fontSize: 45,
                  },
                  { text: " ", alignment: "center", fontSize: 10 },
                  {
                    text: [
                      "Ven: ",
                      { text: s.vendorNumber || "-", bold: true },
                    ],
                    alignment: "center",
                    fontSize: 35,
                  },
                ],
                [
                  {
                    text: totalWeight,
                    alignment: "center",
                    fontSize: 45,
                  },
                  { text: " ", alignment: "center", fontSize: 10 },
                  {
                    text: s.consignment?.vendor_property?.PIC
                      ? `   ${s.consignment.vendor_property.PIC}`
                      : " ",
                    alignment: "justify",
                    fontSize: 42,
                    bold: true,
                    noWrap: true,
                    preserveLeadingSpaces: true,
                  },
                ],
                [
                  { text: avgWeight || "-", alignment: "center", fontSize: 45 },
                  { text: " ", alignment: "center", fontSize: 10 },
                  { text: " ", alignment: "center", fontSize: 40 },
                ],
              ];
            }),
          ],
        },
        pageBreak: array.length - 1 !== index ? "after" : undefined,
      },
      {
        columns: [],
      },
    ].filter(Boolean),
  );
  return contents;
};

/**
 *
 * @param {Object} saleLot
 * @param {Object} sale
 * @param {String} agentId
 * @return {Document}
 */
export const generatePenWeighCardLandscapePdf = (
  weighPenCardData,
  suppressBack,
  logo,
) => {
  const pdfDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [pageMargin, pageMargin, pageMargin, pageMarginBottom],
    content: getContent(weighPenCardData),
    footer: getFooter(weighPenCardData, pageMargin),
    styles: {
      headerStyle: {
        fontSize: 40,
        margin: [15, 15, 15, 15],
      },
      contentStyle: {
        fontSize: 40,
        overflow: "hidden",
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin: [15, 30, 15, 15],
      },
    },
    images: {
      saleLogo: logo,
    },
  };

  createAndOpenPdf(pdfDefinition, undefined, suppressBack);
};

export const PenWeighCardLandscape = () => {
  const isLoading = useSelector(
    state =>
      getIsFetchingSaleLots(state) ||
      getIsFetchingConsignments(state) ||
      getIsFetchingAuctionPens(state) ||
      getIsFetchingScans(state) ||
      getIsFetchingSales(state) ||
      getIsFetchingBusinesses(state),
  );
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { saleLotId, agentId } = match.params;
  useEffect(() => {
    if (!isLoading) {
      dispatch(printPenWeighCardLandscape(saleLotId, false, agentId));
    }
  }, [dispatch, isLoading, saleLotId, agentId]);

  return (
    <OpenedInNewWindow message="The Pen Weigh Card was opened in a new window." />
  );
};
