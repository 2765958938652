import { useEffect, useState, useCallback } from "react";

export const useIsAgGridToolPanelVisible = (api, toolPanelId) => {
  const [isVisible, setVisible] = useState(false);

  const updateIsVisible = useCallback(() => {
    setVisible(toolPanelId === api.getOpenedToolPanel());
  }, [api, toolPanelId]);

  useEffect(() => {
    api.addEventListener("toolPanelVisibleChanged", updateIsVisible);
    return () =>
      api.removeEventListener("toolPanelVisibleChanged", updateIsVisible);
  }, [api, updateIsVisible]);

  return isVisible;
};
