import { get } from "lodash";

/**
 * Check if a form section has errors on elements that have been touched
 * @param {object} props.touched
 * @param {object} props.error
 * @param {array} fields
 */
export const sectionHasErrors = ({ errors }, fields) =>
  fields.some(field => get(errors, field));

// Checkboxes from formik throw back "false" or "true" as a value when
// unchecked.  Normalise that back to an actual boolean
export const checkboxTrueOrFalse = value => {
  if (value === "true") {
    return true;
  }
  if (value === "false" || typeof value === "undefined") {
    return false;
  }
  return value;
};
