import React, { memo, useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { confirmStartDraft } from "actions";

import { Row } from "components/Layout";
import ConsignmentDetailHeader from "components/ScanningScreen/ConsignmentDetailHeader";
import {
  DraftColumn,
  EmptyStateText,
} from "components/ScanningScreen/Elements";
import SaleLotDetailHeader from "components/ScanningScreen/SaleLotDetailHeader";
import ScanLotScanningHeader from "components/ScanningScreen/ScanLotScanningHeader";
import ScannerSelect from "components/ScanningScreen/ScannerSelect";
import ScanningSummaryHeader from "components/ScanningScreen/ScanningSummaryHeader";

import { caseInsensitiveCompare } from "lib/compare";
import { scanLotConfig } from "lib/scanLot";

import {
  getConnectedDeviceId,
  getIsHubConnected,
  getUnassignedScans,
} from "selectors";

import { useBoolean } from "hooks";
import { useGetReceivalScannedDeploymentIds } from "hooks/useGetReceivalScannedDeploymentIds";

import RealtimeScanningColumn from "./RealtimeScanningColumn";

const MESSAGE_NO_SCANS = "Waiting for scans.";

const ScanningScreenColumn = props => {
  const {
    draftNames,
    openDraft,
    setOpenDraft,
    penArchetypeId,
    penId,
    scanLotId,
  } = props;

  const isArrivalOrPenScanning = !!penArchetypeId || !!penId || !!scanLotId;

  const hasScans = draftNames.length > 0;

  if (!hasScans) {
    if (isArrivalOrPenScanning) {
      return <RealtimeScanningColumn {...props} />;
    } else {
      return <EmptyStateText>{MESSAGE_NO_SCANS}</EmptyStateText>;
    }
  } else {
    return (
      <Row overflow="hidden">
        {draftNames.map((draftName, index) => {
          const isSelected = openDraft === draftName;
          return (
            <DraftColumn
              basis={isSelected ? "100%" : "10%"}
              key={`${index}-${draftName}`}
              onClickCapture={() => setOpenDraft(draftName)}
            >
              <RealtimeScanningColumn
                key={draftName}
                draftName={draftName}
                isSelected={isSelected}
                {...props}
              />
            </DraftColumn>
          );
        })}
      </Row>
    );
  }
};

export function RealtimeScanningComponent(props) {
  const {
    consignmentId,
    onCancel,
    onOpenClearModal,
    onOpenClearSavedModal,
    onOpenCreateBulkModal,
    onOpenCreateModal,
    onOpenSelectModal,
    resumeScan,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    scanListType,
    selectedReceivalLotId,
  } = props;
  const [openDraft, setOpenDraft] = useState(null);
  const [duplicateEids, setDuplicateEids] = useState([]);
  const dispatch = useDispatch();
  const [
    isScanDiscrepancyDialogVisible,
    showScanDiscrepancyDialog,
    hideScanDiscrepancyDialog,
  ] = useBoolean(false);

  const connectedDeviceId = useSelector(getConnectedDeviceId);
  const isHubConnected = useSelector(getIsHubConnected);
  const unassignedScans = useSelector(getUnassignedScans);
  const draftNames = Object.values(unassignedScans).reduce((acc, curr) => {
    if (acc.indexOf(curr.draftName) === -1) {
      acc.push(curr.draftName);
    }
    return acc;
  }, []);
  const hasScans = draftNames.length > 0;
  const isArrivalOrPenScanning = penArchetypeId || penId || scanLotId;

  const { getEidsByLotId } = scanLotConfig(penType);

  const scanLotEids = useSelector(getEidsByLotId(scanLotId)).filter(Boolean);

  const receivalScannedDeploymentIds = useGetReceivalScannedDeploymentIds(
    scanLotEids.concat(Object.keys(unassignedScans)),
  );

  // if not in a receival scanning screen
  // when not resuming scanning, prompt user to send start draft to scanner
  useEffect(() => {
    if (!isArrivalOrPenScanning) {
      const shouldStartNewDraft = resumeScan !== true;
      const isConnectedToDevice = Boolean(connectedDeviceId);

      if (shouldStartNewDraft && isHubConnected && isConnectedToDevice) {
        dispatch(confirmStartDraft(connectedDeviceId));
      }
    }
  }, [
    connectedDeviceId,
    dispatch,
    isHubConnected,
    resumeScan,
    isArrivalOrPenScanning,
  ]);

  draftNames.sort(caseInsensitiveCompare);

  // Open up a draft if there isn't one set
  useEffect(() => {
    // when a draft is selected, but is not in the list of available ones,
    // e.g. after a draft has been deleted
    const selectedDraftNoLongerExists =
      openDraft !== null &&
      draftNames.length > 0 &&
      !draftNames.includes(openDraft);

    // when no draft is selected, but there is one available to select
    const noDefaultDraftSelected = openDraft === null && draftNames.length > 0;

    if (selectedDraftNoLongerExists || noDefaultDraftSelected) {
      // get the first available draft name
      const openDraftName = draftNames[0];
      setOpenDraft(openDraftName);
    }
  }, [draftNames, openDraft, setOpenDraft]);

  if (!connectedDeviceId && !hasScans) {
    return <ScannerSelect resumeScan={resumeScan} onCancel={onCancel} />;
  }

  return (
    <>
      <ConsignmentDetailHeader consignmentId={consignmentId} />
      <SaleLotDetailHeader saleLotId={saleLotId} />
      <ScanLotScanningHeader
        penArchetypeId={penArchetypeId}
        penId={penId}
        scanLotId={scanLotId}
        penType={penType}
        duplicateEids={duplicateEids}
        showScanDiscrepancyDialog={showScanDiscrepancyDialog}
        receivalScannedDeploymentIds={receivalScannedDeploymentIds}
      />
      <ScanningSummaryHeader />
      <ScanningScreenColumn
        draftNames={draftNames}
        openDraft={openDraft}
        setOpenDraft={setOpenDraft}
        consignmentId={consignmentId}
        onCancel={onCancel}
        onOpenCreateBulkModal={onOpenCreateBulkModal}
        onOpenCreateModal={onOpenCreateModal}
        onOpenClearModal={onOpenClearModal}
        onOpenClearSavedModal={onOpenClearSavedModal}
        onOpenSelectModal={onOpenSelectModal}
        saleLotId={saleLotId}
        selectColumn={setOpenDraft}
        penArchetypeId={penArchetypeId}
        penId={penId}
        scanLotId={scanLotId}
        penType={penType}
        scanListType={scanListType}
        selectedReceivalLotId={selectedReceivalLotId}
        showScanDiscrepancyDialog={showScanDiscrepancyDialog}
        storedDuplicateEids={duplicateEids}
        setDuplicateEids={setDuplicateEids}
        isScanDiscrepancyDialogVisible={isScanDiscrepancyDialogVisible}
        hideScanDiscrepancyDialog={hideScanDiscrepancyDialog}
      />
    </>
  );
}
RealtimeScanningComponent.propTypes = {
  onCancel: PropTypes.func,
  onOpenClearModal: PropTypes.func,
  onOpenCreateModal: PropTypes.func,
  onOpenSelectModal: PropTypes.func,
  resumeScan: PropTypes.bool,
  saleLotId: PropTypes.string,
  penArchetypeId: PropTypes.number,
  penId: PropTypes.string,
  scanLotId: PropTypes.string,
};

export default memo(RealtimeScanningComponent, (a, b) =>
  [
    "resumeScan",
    "saleLotId",
    "penArchetypeId",
    "penId",
    "scanLotId",
    "penType",
  ].every(key => a[key] === b[key]),
);
