import { END, eventChannel } from "redux-saga";
import { all, call, put, race, take } from "redux-saga/effects";

import { closeConfirmModal, openConfirmModal } from "actions";

import { CLOSE_CONFIRM_MODAL } from "constants/actionTypes";

function openDialogChannel(modalProps, modalYesAction) {
  return eventChannel(emit => {
    const noOnClick = () => {
      emit(closeConfirmModal());
      emit(END);
    };
    const yesOnClick = () => {
      emit(modalYesAction);
      emit(closeConfirmModal());
      emit(END);
    };
    const ocmAction = openConfirmModal({
      ...modalProps,
      actions: [
        {
          label: "No",
          secondary: true,
          onClick: noOnClick,
        },
        {
          label: "Yes",
          onClick: yesOnClick,
        },
      ],
    });
    // Calling emit straight out just... doesn't work.
    setTimeout(() => {
      emit(ocmAction);
    }, 1);
    return () => {};
  });
}

export function* confirmDialogWithSubAction(modalProps, modalYesAction) {
  // Calling an action, from a click, is actually a bit of a headache.
  // This idiom will:
  // - start an eventChannel
  // - `put` any events `emit`ted from it into redux
  // The race call will make sure we bail on the channel if the close confirm
  // modal is called from elsewhere.
  const chan = yield call(openDialogChannel, modalProps, modalYesAction);
  while (true) {
    const result = yield race({
      channel: take(chan),
      close: take(CLOSE_CONFIRM_MODAL),
    });
    if (result.channel) {
      if (Array.isArray(result.channel)) {
        yield all(result.channel.map(action => put(action)));
      } else {
        // One of our emitted actions.
        yield put(result.channel);
      }
    } else {
      // Closed by other means.
      break;
    }
  }
}
