import queryString from "query-string";

import { MASTER_RULE_BOOK, RULE_BOOK } from "constants/actionTypes";

import {
  offlineActionCreator,
  passthroughSerializer,
  urlCheckAndAddIdAndAction,
} from "./lib";

const ruleBookUrlCreator = ({
  changesSince,
  deploymentId,
  saleyardName,
  id,
  action,
} = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/rule-books/", id, action),
    query: {
      changesSince,
      deployment_id: deploymentId,
      saleyard_name: saleyardName,
    },
  });

export const RuleBookAction = offlineActionCreator(
  RULE_BOOK,
  passthroughSerializer,
  ruleBookUrlCreator,
  false,
);

RuleBookAction.unsubscribeFromMasterRuleBook = (
  ruleBookId,
  masterRuleBookId,
  options,
) => ({
  type: RULE_BOOK.UNSUBSCRIBE_FROM_MASTER_RULE_BOOK.ACTION,
  ruleBookId,
  masterRuleBookId,
  options,
});

RuleBookAction.subscribeToMasterRuleBook = (
  ruleBookId,
  masterRuleBookId,
  options,
) => ({
  type: RULE_BOOK.SUBSCRIBE_TO_MASTER_RULE_BOOK.ACTION,
  ruleBookId,
  masterRuleBookId,
  options,
});

RuleBookAction.copy = (sourceRuleBookId, destinationRuleBookId, options) => ({
  type: RULE_BOOK.COPY.ACTION,
  destinationRuleBookId,
  sourceRuleBookId,
  options,
});

const masterRuleBookUrlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/master-rule-books/", id, action),
    query: { changesSince },
  });

export const MasterRuleBookAction = offlineActionCreator(
  MASTER_RULE_BOOK,
  passthroughSerializer,
  masterRuleBookUrlCreator,
  false,
);

MasterRuleBookAction.copy = (
  sourceMasterRuleBookId,
  destinationMasterRuleBookId,
  options,
) => ({
  type: MASTER_RULE_BOOK.COPY.ACTION,
  destinationMasterRuleBookId,
  sourceMasterRuleBookId,
  options,
});

MasterRuleBookAction.updateManagedRule = (
  sourceMasterRuleBookId,
  managedRuleBookId,
  ruleId,
) => ({
  type: MASTER_RULE_BOOK.UPDATE_MANAGED_RULE_BOOK.ACTION,
  sourceMasterRuleBookId,
  managedRuleBookId,
  ruleId,
});
