import styled from "styled-components/macro";

export const WeighingLayout = styled.div(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "selection current";
  overflow-y: clip;
  @media (max-width: ${theme.breakpoints[2]}px) {
    grid-template-columns: 250px 1fr;
  }
`,
);

export const SelectionLayout = styled.div`
  display: grid;
  grid-template-rows: 40px auto 1fr;
  grid-template-columns: 100%;
  grid-template-areas: "recent-lots" "search" "existing-lots";
  grid-area: selection;
`;

export const FiltersLayout = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto ${theme.space[5]}px;
  grid-gap: ${theme.space[1]}px;
  grid-area: filters;
  padding: ${theme.space[1]}px;
  border-right: 2px solid ${theme.colors.separatorBlue};
  align-items: center;
`,
);

export const CurrentLotLayout = styled.div(
  ({ theme }) => `
  background: ${theme.colors.controlBackground};
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: "body" "footer";
  grid-area: current;
  overflow: hidden;
`,
);

export const CurrentLotBodyLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "hub-weighing" "lot-detail";
  grid-area: body;
  overflow-y: auto;
`;

export const LotDetailsAreaLayout = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-template-rows: auto;
  grid-template-areas: "lot-form hub-scanning";
  grid-area: lot-detail;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 20px;
`;

export const FooterLayout = styled.div(
  ({ theme }) => `
  background: ${theme.colors.surfaceBackground};
  border-top: 1px solid ${theme.colors.controlBorder};
  padding: ${theme.space[1]}px;
  grid-area: footer;
`,
);

export const CurrentLotLayoutItem = styled.div(
  ({ gridArea, theme, padding = 2 }) => `
  grid-area: ${gridArea};
  box-shadow: ${theme.shadows[3]};
  margin: ${theme.space[2]}px;
  padding: ${theme.space[padding]}px;
`,
);

export const ExistingLotsLayout = styled.div`
  height: 100%;
  grid-area: existing-lots;
`;

export const SearchLayout = styled.div(
  ({ theme }) => `
  height: 100%;
  grid-area: search;
  border-bottom: 1px solid ${theme.colors.controlBorder}
`,
);

export const SelectionLayoutItem = styled.div(
  ({ gridArea, isActiveSelection, theme }) => `
  grid-area: ${gridArea};
  padding: ${theme.space[1]}px;
  display: flex;
  align-items: center;
  ${
    isActiveSelection
      ? `
      box-shadow: 0 0 0 2px ${theme.colors.separatorBlue};
      background-color: ${theme.colors.controlBackground};
      `
      : `border-right: 2px solid ${theme.colors.separatorBlue};`
  }

`,
);

export const FiltersOptionsLayout = styled.div(
  ({ theme }) => `
  padding: ${theme.space[3]}px;
`,
);
