import React, { memo, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { useField } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { SlimButton } from "components/Button";
import { Warning } from "components/ErrorMessage";
import { AddressForm } from "components/Form/Fields";
import {
  Input,
  SelectField,
  Switch,
  withNamespace,
} from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { validatePIC } from "lib/PICValidator";

import { getActiveRole, selectPropertyIdByPICLookup } from "selectors";

import { useBoolean, useFieldValue } from "hooks";

export const DeploymentPropertyFormMode = {
  INLINE: 0,
  FULL: 1,
};

export const PropertyValidationSchema = Yup.object().shape({
  PIC: Yup.string()
    .required("PIC is required")
    .test("PIC", "Invalid PIC", validatePIC),
  accreditationJbas: Yup.number()
    .min(0, "Must be at least 0")
    .max(9, "Must be less than 10")
    .nullable(),
});

function DeploymentPropertyFormComponent(props) {
  const {
    isEditPicAllowed,
    namespace: ns,
    mode = DeploymentPropertyFormMode.INLINE,
  } = props;

  const [{ value: propertyId }, _ignored, idFieldHelpers] = useField(
    withNamespace(ns, "id"),
  );

  const currentRole = useSelector(getActiveRole);
  const saleyardOptions = currentRole.saleyards.map(saleyard => ({
    label: saleyard.name,
    value: saleyard.saleyard_id,
  }));

  const address = useFieldValue(withNamespace(ns, "address"));
  const hasAddress = !!address;
  const [addressDetailsShowing, showAddressDetails] = useBoolean(hasAddress);
  // This form is persistent - we need to make sure the address details are showing when they exist.
  useEffect(() => {
    if (hasAddress && !addressDetailsShowing) {
      showAddressDetails();
    }
  }, [hasAddress, addressDetailsShowing, showAddressDetails]);

  // If there's a single PIC (and I'm editing, or adding it) show a message about the address.
  const allPropertyValues = useFieldValue("values");
  const hasSinglePIC = propertyId
    ? allPropertyValues?.length === 1
    : allPropertyValues?.length === 0;

  const propertyIdByPicLookup = useSelector(selectPropertyIdByPICLookup);

  function onAfterPicChanged(event) {
    const nextPropertyId =
      propertyIdByPicLookup[event.target.value] || propertyId || uuidv4();
    if (propertyId !== nextPropertyId) {
      idFieldHelpers.setValue(nextPropertyId);
    }
  }

  return (
    <>
      <input
        type="hidden"
        name={withNamespace(ns, "id")}
        value={propertyId || ""}
      />
      <Grid item xs={4} md={3}>
        <Input
          label="PIC"
          disabled={!isEditPicAllowed}
          valueTransformer={v => v.toUpperCase()}
          maxLength={8}
          name={withNamespace(ns, "PIC")}
          onChangeExtra={onAfterPicChanged}
        />
      </Grid>
      {mode === DeploymentPropertyFormMode.FULL && (
        <>
          <Grid item xs={8} md={3}>
            <Input label="Name" name={withNamespace(ns, "name")} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Input label="District" name={withNamespace(ns, "district")} />
          </Grid>

          <Grid item xs={4} md={3}>
            <Input
              emptyValue={null}
              label="JBAS"
              name={withNamespace(ns, "accreditationJbas")}
              type="number"
            />
          </Grid>
          <Grid item xs={3} md={4}>
            <Switch label="EU" name={withNamespace(ns, "accreditationEu")} />
          </Grid>
          <Grid item xs={3} md={4}>
            <Switch label="NEE" name={withNamespace(ns, "accreditationNee")} />
          </Grid>
          <Grid item xs={3} md={4}>
            <Switch
              label="PCAS"
              name={withNamespace(ns, "accreditationPcas")}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Input
              emptyValue={null}
              label="LPA #"
              name={withNamespace(ns, "lpaNumber")}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Input
              emptyValue={null}
              label="MSA #"
              name={withNamespace(ns, "msaNumber")}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SelectField
              label="Default Destination Saleyards"
              isMulti
              options={saleyardOptions}
              name={withNamespace(ns, "defaultForSaleyardIds")}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={12}>
            <Row alignCenter>
              <h4>PIC Address</h4>
              &nbsp;
              {addressDetailsShowing ? null : (
                <SlimButton onClick={showAddressDetails}>
                  Show Details
                </SlimButton>
              )}
            </Row>
            {hasSinglePIC ? (
              <Warning>
                You only have one PIC attached to this business. Consider adding
                an address directly to the business.
              </Warning>
            ) : null}
          </Grid>

          {addressDetailsShowing ? (
            <AddressForm namespace={withNamespace(ns, "address")} />
          ) : null}
        </>
      )}
    </>
  );
}

DeploymentPropertyFormComponent.propTypes = {
  mode: PropTypes.number,
  namespace: PropTypes.string,
};

export default memo(DeploymentPropertyFormComponent);
