import { useSelector } from "react-redux";

import {
  DeploymentPermissions,
  SaleLotPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import { hasPermission, someHasPermission } from "lib/permissions";

import {
  getActiveLivestockAgentDeployment,
  getActiveSaleyardAdminSaleyard,
  getCurrentSaleyard,
  getCurrentSale,
  getIsLivestockAgent,
  getIsSaleyardAdmin,
  selectCurrentSaleyard,
  selectRoleCurrentDeployments,
  selectRoleDeployments,
  getSaleLots,
} from "selectors";

/**
 * Tests whether the Model instance returned by the objectSelector contains the permission specified by the permissionRequired argument
 * @param {function(state: State): SelectedState} objectSelector
 * @param {String} permissionRequired
 * @template State, SelectedState
 * @returns {boolean}
 */
export function useHasPermission(objectSelector, permissionRequired) {
  return useSelector(state =>
    hasPermission(objectSelector(state), permissionRequired),
  );
}

/**
 * Tests whether any Model instance in the array returned by the objectSelector argument contains the permission specified by the permissionRequired argument
 * @param {function(state: State): SelectedState[]} objectSelector
 * @param {String} permissionRequired
 * @template State, SelectedState
 * @returns {boolean}
 */
export function useSomeHasPermission(objectSelector, permissionRequired) {
  return useSelector(state =>
    someHasPermission(objectSelector(state), permissionRequired),
  );
}

/**
 * Tests whether all Model instances in a lookup returned lookupSelector argument contains the permission specified by the permissionRequired argument
 * @param {function(state: State): SelectedState[]} lookupSelector
 * @param {String} permissionRequired
 * @param {function(obj): Boolean} filterFn
 * @template State, SelectedState
 * @returns {boolean}
 */
const defaultFilterFn = () => true;
export function useAllHasPermission(
  lookupSelector,
  permissionRequired,
  filterFn = defaultFilterFn,
) {
  return useSelector(state =>
    Object.values(lookupSelector(state))
      .filter(filterFn)
      .every(obj => hasPermission(obj, permissionRequired)),
  );
}

// Common usages

export function useHasDeploymentPermission(deploymentPermission) {
  return useHasPermission(
    getActiveLivestockAgentDeployment,
    deploymentPermission,
  );
}

export function useHasLivestockSalePermission(salePermission) {
  return useHasPermission(getCurrentSale, salePermission);
}

export function useHasSaleyardPermission(saleyardPermission) {
  return useHasPermission(selectCurrentSaleyard, saleyardPermission);
}

export function useHasSaleyardAdminSaleyardPermission(saleyardPermission) {
  return useHasPermission(getActiveSaleyardAdminSaleyard, saleyardPermission);
}

export function useHasSaleyardOrDeploymentPermission(
  deploymentPermission,
  saleyardPermission,
) {
  const isLivestockAgent = useSelector(getIsLivestockAgent);
  const selectedPermission = isLivestockAgent
    ? deploymentPermission
    : saleyardPermission;

  const parentObjectSelector = isLivestockAgent
    ? getActiveLivestockAgentDeployment
    : getActiveSaleyardAdminSaleyard;

  return useHasPermission(parentObjectSelector, selectedPermission);
}

export function useHasAddConsignmentPermission() {
  return useSelector(state =>
    someHasPermission(
      selectRoleCurrentDeployments(state),
      DeploymentPermissions.canAddConsignment,
    ),
  );
}

export function useHasEmailListPermission() {
  return useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureEmailList,
    SaleyardPermissions.featureEmailList,
  );
}

export function useHasBusinessAlternativesPermission() {
  return useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureBusinessAlternatives,
    SaleyardPermissions.featureBusinessAlternatives,
  );
}

export function useHasReceivalOrPenScanLotsPermission() {
  const hasReceivalLotPermission = useHasSaleyardPermission(
    SaleyardPermissions.featureReceivalLots,
  );
  const hasPenScanPermission = useHasSaleyardPermission(
    SaleyardPermissions.featurePenScanLots,
  );
  return hasReceivalLotPermission || hasPenScanPermission;
}

export function useHasMultiSaleReportPermission() {
  return useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureMultiSaleReports,
    SaleyardPermissions.featureMultiSaleReports,
  );
}

export function useHasEditWeightOnEIDPermission() {
  return useHasSaleyardPermission(SaleyardPermissions.featureEditWeightOnEid);
}

export const useHasAddAttachmentWithoutImagePermission = () => {
  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);
  const isLivestockAgent = useSelector(getIsLivestockAgent);

  let hasAddAttachmentWithoutImagePermission = false;

  const hasSaleyardAddAttachmentWithoutImagePermission =
    useHasSaleyardPermission(SaleyardPermissions.canAddAttachmentWithoutImage);
  const hasDeploymentAddAttachmentWithoutImagePermission =
    useHasDeploymentPermission(
      DeploymentPermissions.canAddAttachmentWithoutImage,
    );

  if (isSaleyardAdmin) {
    hasAddAttachmentWithoutImagePermission =
      hasSaleyardAddAttachmentWithoutImagePermission;
  }

  if (isLivestockAgent) {
    hasAddAttachmentWithoutImagePermission =
      hasDeploymentAddAttachmentWithoutImagePermission;
  }

  return hasAddAttachmentWithoutImagePermission;
};

export const useHasExportBusinessesPermission = () => {
  const saleyardHasExportBusinessesPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.canExportBusinesses,
  );

  const deploymentHasExportBusinessesPermission = useSomeHasPermission(
    selectRoleDeployments,
    DeploymentPermissions.canExportBusinesses,
  );

  return (
    saleyardHasExportBusinessesPermission ||
    deploymentHasExportBusinessesPermission
  );
};

export const useHasSaleLotPermission = () =>
  useSomeHasPermission(
    state => Object.values(getSaleLots(state)),
    SaleLotPermissions.update,
  );
