import React from "react";

import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { SundryTemplateModal } from "components/SundryTemplate/Modal";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

export function SundryTemplateModalAdapter(props) {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.SundryTemplate,
    props,
  );

  const { id, ...formDefaults } = hashParams;

  return (
    <WaitForSync requiredData={[]}>
      <SundryTemplateModal
        id={id}
        formDefaults={formDefaults}
        onClose={onClose}
        returnTo={returnTo}
      />
    </WaitForSync>
  );
}
