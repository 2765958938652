import { ColumnType } from "components/AgGrid/constants";

import { LPAStatusRenderer } from "lib/agGrid/columns/lpaStatus";
import getters from "lib/agGrid/getters";

export const PropertyColumnId = {
  ACCREDITATION_EU: "064b1e04-09fd-475c-8d81-b78a4b09e9ca",
  ACCREDITATION_JBAS: "74895166-3203-46fc-a232-0ff56f8dfb2a",
  ACCREDITATION_NEE: "ee6b3de0-e2c1-400b-ae76-8759bdf672ad",
  ACCREDITATION_PCAS: "3ea03476-d407-4a86-858e-45e397d2517d",
  BRAND: "1963eeec-230e-459a-9728-9ca0814b4485",
  DISTRICT: "d280a70c-7c35-4a2c-ad0b-727081c78082",
  LPA_NUMBER: "90900e97-524c-4113-ba65-c94b623ccb7f",
  LPA_STATUS: "97d3c7a4-d0d6-41f1-ab27-8342cbc05da7",
  MSA_NUMBER: "a2de010f-4a0a-450b-8800-79cad53736de",
  NAME: "bfb49fed-cc5a-41dd-9b0b-66cc07ff672b",
  NLIS_PROGRAMS: "edb32270-1708-4c63-a07f-f2f5fa8653d8",
  PIC: "12274abd-38db-4acf-b349-a6ef169aba59",
  POST_CODE: "bda0a627-0522-43f5-b1bb-95cd2c1cce1c",
  SHIRE_CODE: "33b9fc59-86ee-4a05-b173-dd613d0f7c97",
  STATE: "6af79ec3-e540-46f5-8531-97fa29784554",
  STATUS: "78ff431d-46e0-4bd6-b0f2-590fe4fd6142",
  TOWN: "1ed8ac50-e287-4697-b135-9e99ff5b4b5e",
};

export const PropertyNamespaceColumnId = {
  BUYER_PROPERTY: "f9d7f937-ccd7-4f61-88c4-10bede56acf5",
  VENDOR_PROPERTY: "eba63872-2b42-4c3b-88d1-817c9aaa03f4",
};

export const PropertyColumnDefinitions = {
  [PropertyColumnId.ACCREDITATION_EU]: {
    colId: PropertyColumnId.ACCREDITATION_EU,
    field: "accreditationEu",
    headerName: "EU",
    type: ColumnType.BOOLEAN,
  },
  [PropertyColumnId.ACCREDITATION_JBAS]: {
    colId: PropertyColumnId.ACCREDITATION_JBAS,
    field: "accreditationJbas",
    headerName: "JBAS",
    type: ColumnType.NUMERIC,
  },
  [PropertyColumnId.ACCREDITATION_NEE]: {
    colId: PropertyColumnId.ACCREDITATION_NEE,
    field: "accreditationNee",
    headerName: "NEE",
    type: ColumnType.BOOLEAN,
  },
  [PropertyColumnId.ACCREDITATION_PCAS]: {
    colId: PropertyColumnId.ACCREDITATION_PCAS,
    field: "accreditationPcas",
    headerName: "PCAS",
    type: ColumnType.BOOLEAN,
  },
  [PropertyColumnId.BRAND]: {
    colId: PropertyColumnId.BRAND,
    field: "brand",
    headerName: "Brand",
    type: ColumnType.STRING,
  },
  [PropertyColumnId.DISTRICT]: {
    colId: PropertyColumnId.DISTRICT,
    field: "district",
    headerName: "District",
    type: ColumnType.STRING,
  },
  [PropertyColumnId.LPA_NUMBER]: {
    colId: PropertyColumnId.LPA_NUMBER,
    field: "lpaNumber",
    headerName: "LPA Number",
    type: ColumnType.NUMERIC,
  },
  [PropertyColumnId.MSA_NUMBER]: {
    colId: PropertyColumnId.MSA_NUMBER,
    field: "msaNumber",
    headerName: "MSA Number",
    type: ColumnType.NUMERIC,
  },
  [PropertyColumnId.NAME]: {
    colId: PropertyColumnId.NAME,
    field: "name",
    headerName: "Name",
    type: ColumnType.STRING,
  },
  [PropertyColumnId.NLIS_PROGRAMS]: {
    // Shows a tick if its LPA Accredited.
    colId: PropertyColumnId.NLIS_PROGRAMS,
    field: "nlisPrograms",
    headerName: "LPA",
    width: 70,
    valueGetter: getters.lpaStatusGetter,
    cellRenderer: LPAStatusRenderer,
  },
  [PropertyColumnId.PIC]: {
    colId: PropertyColumnId.PIC,
    field: "PIC",
    headerName: "PIC",
    type: ColumnType.STRING,
  },
  [PropertyColumnId.POST_CODE]: {
    colId: PropertyColumnId.POST_CODE,
    field: "postCode",
    headerName: "Post Code",
    type: ColumnType.STRING,
  },
  [PropertyColumnId.SHIRE_CODE]: {
    colId: PropertyColumnId.SHIRE_CODE,
    field: "shireCode",
    headerName: "Shire Code",
    type: ColumnType.STRING,
  },
  [PropertyColumnId.STATE]: {
    colId: PropertyColumnId.STATE,
    field: "state",
    headerName: "State",
    type: ColumnType.STRING,
  },
  [PropertyColumnId.STATUS]: {
    colId: PropertyColumnId.STATUS,
    field: "activeStatus",
    headerName: "Status",
    type: ColumnType.STRING,
  },
  [PropertyColumnId.TOWN]: {
    colId: PropertyColumnId.TOWN,
    field: "town",
    headerName: "Town",
    type: ColumnType.STRING,
  },
};
