import { useRef } from "react";

import { useSelector } from "react-redux";

import { ColumnType } from "components/AgGrid/constants";
import { MoneyTableColId } from "components/MoneyTable/columnDefinitions";

import {
  BillingDocumentColId,
  LivestockSalesColId,
} from "constants/columnDefinitions";

import { formatDateString, formatISO8601DateString } from "lib/timeFormats";

import { getActiveLivestockAgentDeployment } from "selectors";

const processHeaderCallback = ({ column }) => {
  if (column.parent.providedColumnGroup.colGroupDef.headerName) {
    return `${column.parent.providedColumnGroup.colGroupDef.headerName} ${column.colDef.headerName}`;
  }
  return column.colDef.headerName;
};
const processCellCallback = cell => {
  const { column, value } = cell;
  // If we're a date type, apply some sanity
  if (
    column.colDef.type === ColumnType.DATE ||
    column.colDef.colId === LivestockSalesColId.SALE_DATES
  ) {
    return column.colDef.valueFormatter(cell);
  }
  // If we're the actions column, make it blank
  if (column.colId === BillingDocumentColId.ACTIONS) {
    return "";
  }
  return value;
};

export function useGetDownloadOutstandingSettlementReport() {
  const agGridRef = useRef();

  const { deploymentSettings } = useSelector(getActiveLivestockAgentDeployment);

  // Pull an aggrid api reference in, so we can create some export magic.
  function onGridReady(agGrid) {
    agGridRef.current = agGrid;
  }

  const handleDownloadOutstandingSettlementReport = () => {
    const agGrid = agGridRef.current;

    // Grab the columns we're showing, so we can count them.
    const currentColumnState = agGrid.columnApi.getColumnState();
    // Explicitly IGNORE the field used for filtering.  This should give the user the ability to add other columns
    // (EG ABN?) if required.
    const columnKeys = currentColumnState
      .filter(
        colState =>
          !colState.hide &&
          colState.colId !==
            MoneyTableColId.SHOW_ON_OUTSTANDING_SETTLEMENT_REPORT,
      )
      .map(colState => colState.colId);

    const numberOfColumns = columnKeys.length;
    const blankFooterRows = {
      mergeAcross: numberOfColumns - 3,
      data: {
        value: "",
      },
    };

    const { physicalAddress, postalAddress } = deploymentSettings;
    const address = postalAddress || physicalAddress;
    const addressLine1 = address
      ? `${address.streetNumber} ${address.route}`
      : "";
    const addressLine2 = address
      ? `${address.locality} ${address.state} ${address.postalCode}`
      : "";

    const prependContent = [
      {
        cells: [
          {
            mergeAcross: numberOfColumns - 3,
            data: {
              value: deploymentSettings.companyName,
              type: "String",
            },
            styleId: "bold",
          },
          {
            mergeAcross: 1,
            data: {
              value: `TEL ${deploymentSettings.phoneNumber}`,
              type: "String",
            },
          },
        ],
      },

      {
        cells: [
          {
            mergeAcross: 1,
            data: {
              value: "WEEKLY REPORT",
              type: "String",
            },
          },
          {
            mergeAcross: numberOfColumns - 3,
            data: {
              value: "STOCK AND STATION AGENTS CO-OPERATIVE LIMITED",
              type: "String",
            },
          },
        ],
      },
      {
        cells: [
          {
            mergeAcross: 1,
            data: {
              value: "",
              type: "String",
            },
          },

          {
            mergeAcross: numberOfColumns - 3,
            data: {
              value: "OUTSTANDING SETTLEMENT REPORT",
              type: "String",
            },
          },
        ],
      },

      {
        cells: [
          {
            mergeAcross: 1,
            data: {
              value: "Outstanding 13 days and over as at",
              type: "String",
            },
          },
          {
            mergeAcross: numberOfColumns - 3,
            data: {
              value: formatDateString(new Date()),
              type: "String",
            },
          },
        ],
      },

      {
        cells: [
          {
            mergeAcross: 3,
            data: {
              value: `COMPANY - ${deploymentSettings.companyName}`,
              type: "String",
            },
          },
          {
            mergeAcross: numberOfColumns - 5,
            data: {
              value:
                "Email: admin@stockinsure.com.au,office@stockinsure.com.au ",
              type: "String",
            },
          },
        ],
      },
      {
        cells: [],
      },
    ];

    const appendContent = [
      { cells: [] },
      {
        cells: [
          blankFooterRows,
          {
            mergeAcross: 1,
            data: {
              value: deploymentSettings.companyName,
              type: "String",
            },
            styleId: "horizontal-center",
          },
        ],
      },

      {
        cells: [
          blankFooterRows,
          {
            mergeAcross: 1,
            data: {
              value: addressLine1,
              type: "String",
            },
            styleId: "horizontal-center",
          },
        ],
      },
      {
        cells: [
          blankFooterRows,
          {
            mergeAcross: 1,
            data: {
              value: addressLine2,
              type: "String",
            },
            styleId: "horizontal-center",
          },
        ],
      },
    ];

    agGrid.api.exportDataAsExcel({
      appendContent,
      prependContent,
      processHeaderCallback,
      processCellCallback,
      skipColumnGroupHeaders: true,
      fileName: `Outstanding Settlement Report - ${formatISO8601DateString(
        new Date(),
      )}.xlsx`,
      columnKeys,
    });
  };

  return [onGridReady, handleDownloadOutstandingSettlementReport];
}
