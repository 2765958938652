import React, { useState } from "react";

import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { EContractAction } from "actions";

import { TableIconTextButton } from "components/Button/IconTextButton";
import { MainContentWrapper } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Paper } from "components/Paper";
import Table from "components/Table";
import { BoldText, Text, TitleText } from "components/Text";
import StatusText from "components/Text/StatusText";

import {
  EContractParty,
  EContractType,
  EContractTypeLabel,
} from "constants/eContract";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  EContractPermissions,
} from "constants/permissions";

import { getEContractStatus } from "lib/econtracts";
import { openModalLink } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import {
  getCurrentSale,
  hasValidEContract,
  selectBuyerIdBySaleLotIdLookup,
  selectEContractsByTypeLookup,
  selectVendorIdByConsignmentIdLookup,
} from "selectors";

import { useHasDeploymentPermission, useMountEffect } from "hooks";

import { EContractSendEmailModal } from "./sendEmailModal";

const EContractActions = ({ row, createHandler }) => {
  const { eContractType, eContract } = row;

  const validEContractExists = useSelector(hasValidEContract);
  const livestockSale = useSelector(getCurrentSale);
  const vendorIdLookup = useSelector(selectVendorIdByConsignmentIdLookup);
  const vendorIds = uniq(Object.values(vendorIdLookup));
  const buyerIdLookup = useSelector(selectBuyerIdBySaleLotIdLookup);
  const buyerIds = uniq(Object.values(buyerIdLookup));
  const buyerBusinessId = livestockSale.default_buyer_id || buyerIds?.[0];
  const vendorBusinessId = livestockSale.default_vendor_id || vendorIds?.[0];

  const [emailDialogParty, setEmailDialogParty] = useState(null);
  const hasCreatePermission = useHasDeploymentPermission(
    DeploymentPermissions.canCreateEContract,
  );

  const canRead = hasPermission(eContract, EContractPermissions.read);
  const canAccept = hasPermission(eContract, EContractPermissions.accept);

  return (
    <>
      {!validEContractExists ? (
        <TableIconTextButton
          disabled={
            !hasCreatePermission || !buyerBusinessId || !vendorBusinessId
          }
          icon={faPencil}
          onClick={() => createHandler({ eContractType })}
        >
          <BoldText>Draft New</BoldText>
        </TableIconTextButton>
      ) : null}

      <TableIconTextButton
        disabled={!eContract || !canRead}
        icon={faFilePdf}
        onClick={() =>
          openModalLink(ModalTypes.EContract, { id: eContract.id })
        }
      >
        <BoldText>PDF</BoldText>
      </TableIconTextButton>

      <TableIconTextButton
        disabled={!canAccept}
        icon={faPaperPlane}
        onClick={() => setEmailDialogParty(EContractParty.BUYER)}
      >
        <BoldText>Send To Buyer</BoldText>
      </TableIconTextButton>

      <TableIconTextButton
        disabled={!canAccept}
        icon={faPaperPlane}
        onClick={() => setEmailDialogParty(EContractParty.VENDOR)}
      >
        <BoldText>Send To Vendor</BoldText>
      </TableIconTextButton>
      {emailDialogParty ? (
        <EContractSendEmailModal
          eContractId={eContract.id}
          onClose={() => setEmailDialogParty(null)}
          party={emailDialogParty}
        />
      ) : null}
    </>
  );
};

const eContractColumns = ({ createHandler }) => {
  return [
    {
      accessor: "eContractType",
      Cell: row => {
        const { eContractType } = row.original;
        const label = EContractTypeLabel[EContractType[eContractType]];
        return <Text fontSize="beta">{label}</Text>;
      },
      maxWidth: 250,
    },
    {
      Cell: row => {
        const { eContract } = row.original;
        const eContractStatus = getEContractStatus(eContract);
        return <StatusText fontSize="beta">{eContractStatus}</StatusText>;
      },
      maxWidth: 140,
    },
    {
      Cell: row => (
        <EContractActions row={row.original} createHandler={createHandler} />
      ),
      style: {
        justifyContent: "flex-end",
        flexWrap: "wrap", // allow small screens to see actions
      },
      minWidth: 140,
    },
  ];
};

export const EContractView = () => {
  const livestockSale = useSelector(getCurrentSale);
  const eContractsByType = useSelector(selectEContractsByTypeLookup);
  const buyerIdLookup = useSelector(selectBuyerIdBySaleLotIdLookup);
  const buyerIds = uniq(Object.values(buyerIdLookup));
  const vendorIdLookup = useSelector(selectVendorIdByConsignmentIdLookup);
  const vendorIds = uniq(Object.values(vendorIdLookup));

  const dispatch = useDispatch();

  useMountEffect(() => {
    dispatch(EContractAction.request());
  });

  // AG-4693 - limit to ONLY the ALPA template for now
  // const eContractTypes = Object.values(EContractType);
  const eContractTypes = [EContractType.ALPA];

  const eContractList = eContractTypes.map(eContractType => {
    const [eContract, ...voidEContracts] =
      eContractsByType[eContractType] || [];
    return {
      eContractType,
      eContract,
      voidEContracts,
    };
  });

  // For private sales, the deployment_sales quantity will always be 1.
  const deploymentSaleId = livestockSale.deployment_sales[0].deployment_sale_id;
  const buyerBusinessId = livestockSale.default_buyer_id || buyerIds?.[0];
  const vendorBusinessId = livestockSale.default_vendor_id || vendorIds?.[0];

  const createHandler = ({ eContractType }) => {
    const payload = {
      type: eContractType,
      deployment_sale_id: deploymentSaleId,
      buyer_business_id: buyerBusinessId,
      vendor_business_id: vendorBusinessId,
    };
    dispatch(EContractAction.create(payload));
  };

  return (
    <WaitForSync
      requiredData={[
        ApiModel.BUSINESSES,
        ApiModel.CONSIGNMENTS,
        ApiModel.DEPLOYMENTS,
        ApiModel.ECONTRACTS,
        ApiModel.LIVESTOCK_AGENTS,
      ]}
      subjectName="eContracts"
    >
      <MainContentWrapper>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12}>
            <Paper elevation={1} spacing={2} square>
              <TitleText block>eContracts</TitleText>
              Welcome to your eContract hub for this Sale.
              <br />
              eContracts use the data you’ve already added to create an
              eContract that can be viewed, sent and approved online.
              <br />
              You can also choose to view and print out contracts for offline
              approval and signing if you prefer.
            </Paper>
          </Grid>
        </Grid>
        <Table
          data={eContractList}
          columns={eContractColumns({
            createHandler,
          })}
          suppressNoResult
        />
      </MainContentWrapper>
    </WaitForSync>
  );
};
