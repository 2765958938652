import React from "react";

import styled from "styled-components/macro";

import { Row } from "components/Layout";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ fullPage, theme }) =>
    fullPage
      ? `height: 100vh
    width: 100vw;`
      : `margin: ${theme.space[2]}px;`}
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.gray78};
  padding: 1rem;
`;

const Right = styled.div`
  flex: 1;
  text-align: right;
`;

const Label = styled.div`
  font-weight: bold;
  margin-right: 1rem;
`;

export const Version = ({ fullPage = true }) => (
  <Container fullPage={fullPage}>
    <Content>
      <Row full>
        <Label>Version: </Label>
        <Right>
          {process.env.REACT_APP_SEMANTIC_VERSION || "v?"}{" "}
          {process.env.REACT_APP_VERSION || "###"}
        </Right>
      </Row>
      <Row full>
        <Label>Deployed on: </Label>
        <Right>{process.env.REACT_APP_UPDATED || "###"}</Right>
      </Row>
    </Content>
  </Container>
);
