import { isArray, isString, kebabCase } from "lodash";
import { put, select, takeEvery, call } from "redux-saga/effects";

import { BillingDocumentAction, billingDocumentUrlCreator } from "actions";

import {
  BILLING_DOCUMENT,
  BILLING_RUN,
  INTEGRATION_CREDENTIAL,
} from "constants/actionTypes";
import { IntegrationTypes } from "constants/integrations";

import { downloadFileBlob } from "lib";

import toast from "lib/toast";

import {
  getActiveRole,
  getBillingRunById,
  getBillingDocumentById,
  selectIntegrationCredentialIdByTypeAndDeploymentIdLookup,
} from "selectors";

import { checkIntegrationActive } from "./integrationCredentials";

function* onSubscribeBillingRun(action) {
  const { id } = action;
  yield put(BillingDocumentAction.request({ billingRunId: id }));
}

function fetchBillingFileExportFactory(
  urlAction,
  reportName,
  extraQueryParams = {},
) {
  return function* onFetchBillingDocumentFile(action) {
    const { billingRunId, billingDocumentIds } = action;
    const state = yield select();
    const userRole = getActiveRole(state).slug;

    const url = billingDocumentUrlCreator({
      action: urlAction,
      billingRunId,
      billingDocumentIds,
      userRole,
      extraQueryParams,
    });

    const toastMessage = `Generating ${reportName} Export...`;
    toast.syncing(toastMessage, {
      autoClose: false,
    });
    try {
      // TODO Temporarily use a base fetch here, as there is a bug in cloudfront/chrome/edge,
      // that prevents the correct headers being avaliable to allow a download (BAU-3014)
      // Once this AWS ticket is resolved, we can change back to use `fetchWithFullyQualifiedUrl`
      // https://support.console.aws.amazon.com/support/home?region=ap-southeast-2#/case/?displayId=172299756200918&language=en
      const response = yield fetch(url);
      if (response.ok) {
        const fileBlob = yield response.blob();
        const contentDisposition = response.headers.get("content-disposition");
        // Extract the server generated file name
        const fileName = contentDisposition.match(/filename="(.+)"/)[1];
        toast.dismiss(kebabCase(toastMessage));
        downloadFileBlob(fileName, fileBlob);
      } else {
        // If we get here, assume it's a json response with an error...
        toast.dismiss(kebabCase(toastMessage));
        const responseText = yield response.json();
        toast.error(
          `Export Failed: ${isString(responseText) || isArray(responseText) ? responseText : "Request Issue"}`,
        );
      }
    } catch (e) {
      toast.dismiss(kebabCase(toastMessage));
      toast.error(`Export Failed`);
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

function* onExportXeroFail() {
  const state = yield select();
  yield put(
    BillingDocumentAction.request({
      billingRunId: state.billingRuns.activeBillingRunId,
    }),
  );
}

export function* checkXeroActive(action) {
  const state = yield select();
  const { props, nextFunction } = action;
  const { billingDocumentIds } = props;
  const billingDocument = getBillingDocumentById(billingDocumentIds[0])(state);
  const billingRun = getBillingRunById(billingDocument.billingRunId)(state);
  const integrationCredentialId =
    selectIntegrationCredentialIdByTypeAndDeploymentIdLookup(state)[
      IntegrationTypes.Xero
    ][billingRun.deploymentId];
  yield call(
    checkIntegrationActive,
    integrationCredentialId,
    nextFunction,
    billingDocumentIds,
  );
}

function* onSyncDocumentSuccess() {
  yield put(BillingDocumentAction.request());
}

export default function* billingDocumentsSaga() {
  yield takeEvery(BILLING_RUN.SUBSCRIBE.ACTION, onSubscribeBillingRun);
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_XERO_BILL_CSV.ACTION,
    fetchBillingFileExportFactory(
      "xero-bill-csv",
      "Xero Account Sale/RCTI CSV",
    ),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_XERO_INVOICE_CSV.ACTION,
    fetchBillingFileExportFactory("xero-invoice-csv", "Xero Invoice CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.EXPORT_XERO_INVOICE.FAILURE,
    onExportXeroFail,
  );
  yield takeEvery(BILLING_DOCUMENT.EXPORT_XERO_INVOICE.ACTION, checkXeroActive);
  yield takeEvery(
    INTEGRATION_CREDENTIAL.SYNC_DOCUMENTS.SUCCESS,
    onSyncDocumentSuccess,
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_CUSTOMER_EXPORT.ACTION,
    fetchBillingFileExportFactory("myob-customers", "MYOB Customer Card CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_SUPPLIER_EXPORT.ACTION,
    fetchBillingFileExportFactory("myob-suppliers", "MYOB Supplier Card CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_SALES_EXPORT.ACTION,
    fetchBillingFileExportFactory("myob-sale", "MYOB Sale CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_PURCHASES_EXPORT.ACTION,
    fetchBillingFileExportFactory("myob-purchase", "MYOB Purchase CSV"),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_CLASSIC_SALES_EXPORT.ACTION,
    fetchBillingFileExportFactory("myob-sale", "MYOB Sale CSV", {
      myob: "classic",
    }),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_MYOB_CLASSIC_PURCHASES_EXPORT.ACTION,
    fetchBillingFileExportFactory("myob-purchase", "MYOB Purchase CSV", {
      myob: "classic",
    }),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_SALEYARD_BULK_BILLING_XML.ACTION,
    fetchBillingFileExportFactory("bulk-billing", "Saleyard Bulk Billing XML", {
      report_type: "saleyard",
    }),
  );
  yield takeEvery(
    BILLING_DOCUMENT.FETCH_AGENT_BULK_BILLING_XML.ACTION,
    fetchBillingFileExportFactory("bulk-billing", "Agent Bulk Billing XML", {
      report_type: "agent",
    }),
  );
}
