import { ColumnType } from "components/AgGrid/constants";

export const DeploymentColId = {
  DEPLOYMENT_NAME: "243f0317-df8e-41da-b265-0c487a477877",
  BUYER_TERMS: "03595808-2792-49f9-983a-31fdcdcce732",
  VENDOR_TERMS: "6d07a772-a7fc-47c6-af0a-94b016b9095e",
};

export const DeploymentColumnDef = {
  [DeploymentColId.DEPLOYMENT_NAME]: {
    headerName: "Deployment",
    children: [
      {
        field: "deployment.name",
        headerName: "Name",
        type: ColumnType.STRING,
        enableRowGroup: true,
      },
    ],
  },
  [DeploymentColId.BUYER_TERMS]: {
    colId: DeploymentColId.BUYER_TERMS,
    headerName: "Buyer Terms (days)",
    field: "deployment.buyerTerms",
    width: 140,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
  [DeploymentColId.VENDOR_TERMS]: {
    colId: DeploymentColId.VENDOR_TERMS,
    headerName: "Vendor Terms (days)",
    field: "deployment.vendorTerms",
    width: 140,
    columnGroupShow: "open",
    hideOnMobile: true,
  },
};
