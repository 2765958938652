import React from "react";

import { get } from "lodash";

import { Chip } from "components/Chip";
import { Row } from "components/Layout";

import { getStatus } from "constants/erpCodes";

export function nlisProgramsRenderer(params) {
  if (!params.data) {
    return null;
  }

  const value = get(params.data, params.colDef.field);

  return (
    <Row>
      {value ? (
        value.map(({ code, status }, i) => (
          <Chip key={`${code}_${i}`} tooltip={getStatus(`${code}${status}`)}>
            {code}: {status}
          </Chip>
        ))
      ) : (
        <div>-</div>
      )}
    </Row>
  );
}
