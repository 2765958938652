import React from "react";

import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import get from "lodash/get";
import memoizeOne from "memoize-one";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

import { findOutlierParameters, formatWeightKg } from "lib";

const outlierParameters = memoizeOne((rowsToDisplay, field) => {
  const weights = rowsToDisplay
    .map(({ data }) => get(data, field), 10)
    .filter(Boolean);
  weights.sort((a, b) => a - b);

  return findOutlierParameters(weights);
});

export function weightOutlierRenderer(params) {
  if (!params.data) {
    return null;
  }

  const { field } = params.colDef;

  const weight = get(params.data, field);
  if (typeof weight === "undefined" || weight === null || weight === 0) {
    return null;
  }

  const { rowsToDisplay } = params.api.getModel();

  const { lowerLimit, upperLimit, lowerQuartile, upperQuartile } =
    outlierParameters(rowsToDisplay, field);

  if (weight < lowerLimit || weight > upperLimit) {
    return (
      <FaIcon
        icon={faExclamationCircle}
        color="error"
        title={`${formatWeightKg(
          weight,
        )} is outside outlier range ${formatWeightKg(
          lowerLimit,
        )} to ${formatWeightKg(upperLimit)}`}
      />
    );
  } else if (weight < lowerQuartile || weight > upperQuartile) {
    return (
      <FaIcon
        icon={faCheckCircle}
        color="warning"
        title={`${formatWeightKg(
          weight,
        )} is outside median quartile of ${formatWeightKg(
          lowerQuartile,
        )} to ${formatWeightKg(
          upperQuartile,
        )}, but inside expected outlier range of ${formatWeightKg(
          lowerLimit,
        )} to ${formatWeightKg(upperLimit)}`}
      />
    );
  } else {
    return (
      <FaIcon
        icon={faCheckCircle}
        color="success"
        title={`${formatWeightKg(weight)} in expected range ${formatWeightKg(
          lowerLimit,
        )} to ${formatWeightKg(upperLimit)}`}
      />
    );
  }
}
export const weightOutlierGetter = params => {
  if (!params.data) {
    return null;
  }

  const { field } = params.colDef;

  const value = get(params.data, field);
  if (!value) {
    return null;
  }

  const { rowsToDisplay } = params.api.getModel();
  const { lowerLimit, upperLimit } = outlierParameters(rowsToDisplay, field);

  return value > lowerLimit && value < upperLimit;
};

export const weightOutlierComparator = (dataA, dataB) => {
  if (dataA === null && dataB === null) {
    return 0;
  } else if (dataA === null) {
    return -1;
  }
  if (dataB === null) {
    return 1;
  }
  return dataA - dataB;
};
