import React from "react";

import { AccountsRecipientIcon } from "components/Icons/AccountsRecipientIcon";
import { CommercialEmailRecipientIcon } from "components/Icons/CommercialEmailRecipientIcon";
import { ComplianceEmailRecipientIcon } from "components/Icons/ComplianceEmailRecipientIcon";
import { ENVDEmailRecipientIcon } from "components/Icons/ENVDEmailRecipientIcon";
import { Row } from "components/Layout";

export const EmailRecipientIconList = ({ recipient }) => {
  return (
    <Row alignCenter>
      {recipient.isAccountsRecipient ? <AccountsRecipientIcon /> : null}
      {recipient.isCommercialReportRecipient || recipient.isCommercialUser ? (
        <CommercialEmailRecipientIcon />
      ) : null}
      {recipient.isComplianceReportRecipient || recipient.isComplianceUser ? (
        <ComplianceEmailRecipientIcon />
      ) : null}
      {recipient.isEnvdRecipient ? <ENVDEmailRecipientIcon /> : null}
    </Row>
  );
};
