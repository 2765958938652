import { intersection } from "lodash/array";
import { createSelector } from "reselect";

import { getLivestockSaleId } from "lib/navigation";

import {
  getCurrentRoundsList,
  getSaleLots,
  selectCurrentDeploymentSaleIdsList,
  selectIsNoSaleBySaleLotIdLookup,
  selectIsPennedBySaleLotIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectRoundSaleLotIdsByDeploymentSaleIdLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByDeploymentSaleIdLookup,
  selectSaleLotIdsByRoundIdLookup,
  selectTotalHdCountBySaleLotIdLookup,
} from "selectors";

/**
 * Returns the total number of penned head, in their Sale Round Indexes, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<number>}
 */
export const selectRoundPennedCountByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    getCurrentRoundsList,
    selectSaleLotIdsByDeploymentSaleIdLookup,
    selectSaleLotIdsByRoundIdLookup,
    selectTotalHdCountBySaleLotIdLookup,
    selectIsPennedBySaleLotIdLookup,
  ],
  (
    deploymentSaleIds,
    saleRoundIds,
    saleLotIdsByDeploymentSaleId,
    saleLotIdsByRoundIdLookup,
    totalHdCountBySaleLotIdLookup,
    isPennedBySaleLotIdLookup,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      acc[deploymentSaleId] = saleRoundIds.map(saleRoundId => {
        // Go through each sale lot in the round, and if it's penned and matches this deployment, add to the total.
        return (
          saleLotIdsByRoundIdLookup[saleRoundId]
            ?.filter(saleLotId => isPennedBySaleLotIdLookup[saleLotId])
            .filter(saleLotId =>
              saleLotIdsByDeploymentSaleId[deploymentSaleId].includes(
                saleLotId,
              ),
            ) // Sum all of the Sale Lot Quantities in Auction Pens in the current Round in this Deployment sale
            .reduce(
              (acc, saleLotId) =>
                acc + totalHdCountBySaleLotIdLookup[saleLotId],
              0,
            ) || 0
        );
      });
      return acc;
    }, {}),
);
/**
 * Returns the total progeny number, in their Sale Round Indexes, keyed by Deployment Sale Id
 * Requires:
 *  - `Auction Pens`
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<number>}
 */
export const selectRoundProgenyCountsByDeploymentSaleId = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    getCurrentRoundsList,
    getSaleLots,
    selectRoundSaleLotIdsByDeploymentSaleIdLookup,
    selectIsSoldBySaleLotIdLookup,
    selectIsPennedBySaleLotIdLookup,
  ],
  (
    deploymentSaleIds,
    saleRoundIds,
    saleLots,
    saleRoundSaleLotIdsListByDeploymentSaleId,
    isSoldBySaleLotId,
    isPennedBySaleLotId,
  ) => {
    const result = deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      const roundSaleLotIdsList =
        saleRoundSaleLotIdsListByDeploymentSaleId[deploymentSaleId] || [];

      acc[deploymentSaleId] = saleRoundIds.map((_, index) => {
        const sold = roundSaleLotIdsList[index].reduce((acc, saleLotId) => {
          return isSoldBySaleLotId[saleLotId]
            ? acc + saleLots[saleLotId].quantityProgeny || 0
            : acc;
        }, 0);

        const notPenned = roundSaleLotIdsList[index].reduce(
          (acc, saleLotId) => {
            return !isPennedBySaleLotId[saleLotId]
              ? acc + saleLots[saleLotId].quantityProgeny || 0
              : acc;
          },
          0,
        );

        const penned = roundSaleLotIdsList[index].reduce((acc, saleLotId) => {
          return isPennedBySaleLotId[saleLotId]
            ? acc + saleLots[saleLotId].quantityProgeny || 0
            : acc;
        }, 0);
        return { notPenned, penned, sold };
      });

      return acc;
    }, {});
    return result;
  },
);

/**
 * Returns the total number of sold head, in their Sale Round Indexes, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<number>}
 */
export const selectRoundSoldCountByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    getCurrentRoundsList,
    selectTotalHdCountBySaleLotIdLookup,
    selectRoundSaleLotIdsByDeploymentSaleIdLookup,
    selectIsSoldBySaleLotIdLookup,
  ],
  (
    deploymentSaleIds,
    saleRoundIds,
    totalHdCountBySaleLotIdLookup,
    saleRoundSaleLotIdsListByDeploymentSaleId,
    isSoldBySaleLotId,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      // Get all of the Sale Lot Ids in this Deployment Sale, grouped by their sale round index
      const roundSaleLotIdsList =
        saleRoundSaleLotIdsListByDeploymentSaleId[deploymentSaleId] || [];

      acc[deploymentSaleId] = saleRoundIds.map((_, index) =>
        // Sum all of the Sale Lot Quantities which have been sold in this Deployment sale
        roundSaleLotIdsList[index].reduce(
          (acc, saleLotId) =>
            isSoldBySaleLotId[saleLotId]
              ? acc + totalHdCountBySaleLotIdLookup[saleLotId]
              : acc,
          0,
        ),
      );
      return acc;
    }, {}),
);
/**
 * Returns the total number of no-sale (return to vendor) head, in their Sale Round Indexes, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<number>}
 */
export const selectRoundNoSaleCountByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    getCurrentRoundsList,
    selectTotalHdCountBySaleLotIdLookup,
    selectRoundSaleLotIdsByDeploymentSaleIdLookup,
    selectIsNoSaleBySaleLotIdLookup,
  ],
  (
    deploymentSaleIds,
    saleRoundIds,
    totalHdCountBySaleLotIdLookup,
    saleRoundSaleLotIdsListByDeploymentSaleId,
    isNoSaleBySaleLotId,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      // Get all of the Sale Lot Ids in this Deployment Sale, grouped by their sale round index
      const roundSaleLotIdsList =
        saleRoundSaleLotIdsListByDeploymentSaleId[deploymentSaleId] || [];

      acc[deploymentSaleId] = saleRoundIds.map((_, index) =>
        // Sum all of the Sale Lot Quantities which have been sold in this Deployment sale
        roundSaleLotIdsList[index].reduce(
          (acc, saleLotId) =>
            isNoSaleBySaleLotId[saleLotId]
              ? acc + totalHdCountBySaleLotIdLookup[saleLotId]
              : acc,
          0,
        ),
      );
      return acc;
    }, {}),
);

/**
 * Returns the total number of delivered head, in their Sale Round Indexes, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<number>}
 */
export const selectRoundDeliveredCountByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    getCurrentRoundsList,
    getSaleLots,
    selectRoundSaleLotIdsByDeploymentSaleIdLookup,
  ],
  (
    deploymentSaleIds,
    saleRoundIds,
    saleLots,
    saleRoundSaleLotIdsListByDeploymentSaleId,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      // Get all of the Sale Lot Ids in this Deployment Sale, grouped by their sale round index
      const roundSaleLotIdsList =
        saleRoundSaleLotIdsListByDeploymentSaleId[deploymentSaleId] || [];

      acc[deploymentSaleId] = saleRoundIds.map(
        (_, index) =>
          roundSaleLotIdsList[index]?.reduce(
            // Sum all of the Sale Lot Delivered Quantities in this Deployment sale
            (acc, saleLotId) => acc + saleLots[saleLotId].quantity_delivered,
            0,
          ) || 0,
      );
      return acc;
    }, {}),
);

/**
 * Returns the total number of un-penned head, in their Sale Round Indexes, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<number>}
 */
export const selectRoundNotPennedCountByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    getCurrentRoundsList,
    selectTotalHdCountBySaleLotIdLookup,
    selectRoundSaleLotIdsByDeploymentSaleIdLookup,
    selectSaleLotIdsByAuctionPenIdLookup,
  ],
  (
    deploymentSaleIds,
    saleRoundIds,
    totalHdCountBySaleLotIdLookup,
    saleRoundSaleLotIdsListByDeploymentSaleId,
    saleLotIdsByAuctionPenId,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      // Get all of the Sale Lot Ids in this Deployment Sale, grouped by their sale round index
      const roundSaleLotIdsList =
        saleRoundSaleLotIdsListByDeploymentSaleId[deploymentSaleId] || [];

      acc[deploymentSaleId] = saleRoundIds.map((_, index) =>
        // Inner join the Sale Lot Ids without an Auction Pen, with the Sale Lot Ids in the current Round in this Deployment Sale
        intersection(
          saleLotIdsByAuctionPenId.null || [],
          roundSaleLotIdsList[index] || [],
        ).reduce(
          (acc, saleLotId) => acc + totalHdCountBySaleLotIdLookup[saleLotId],
          0,
        ),
      );
      return acc;
    }, {}),
);

export const getRoundNotPennedCountByDeploymentSaleId =
  (deploymentSaleId, roundIndex) => state =>
    selectRoundNotPennedCountByDeploymentSaleIdLookup(
      state,
      getLivestockSaleId(),
    )[deploymentSaleId][roundIndex];

export const getRoundPennedCountByDeploymentSaleId =
  (deploymentSaleId, roundIndex) => state =>
    selectRoundPennedCountByDeploymentSaleIdLookup(state, getLivestockSaleId())[
      deploymentSaleId
    ][roundIndex];

export const getStatusAndRoundProgenyCountByDeploymentSaleId =
  (deploymentSaleId, roundIndex) => state =>
    selectRoundProgenyCountsByDeploymentSaleId(state, getLivestockSaleId())[
      deploymentSaleId
    ][roundIndex];

export const getRoundNoSaleCountByDeploymentSaleId =
  (deploymentSaleId, roundIndex) => state =>
    selectRoundNoSaleCountByDeploymentSaleIdLookup(state, getLivestockSaleId())[
      deploymentSaleId
    ][roundIndex];

export const getRoundSoldCountByDeploymentSaleId =
  (deploymentSaleId, roundIndex) => state =>
    selectRoundSoldCountByDeploymentSaleIdLookup(state, getLivestockSaleId())[
      deploymentSaleId
    ][roundIndex];

export const getRoundDeliveredCountByDeploymentSaleId =
  (deploymentSaleId, roundIndex) => state =>
    selectRoundDeliveredCountByDeploymentSaleIdLookup(
      state,
      getLivestockSaleId(),
    )[deploymentSaleId][roundIndex];
