import React from "react";

import { Slide } from "react-slideshow-image";
import styled from "styled-components/macro";

import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";

const GalleryWrapper = styled.div`
  width: 100%;
  position: relative;
  ${({ theme }) => `
  @media (max-width: ${theme.breakpoints[2]}px) {
      width: 75vw;
      margin: auto;      
   }
   
   .images-wrap {
     display: flex;
     } 
   `}
`;
const GalleryImage = styled.img`
  max-height: 400px;
  margin: 0 auto;
  ${({ theme, clickable }) => `
      ${clickable ? "cursor: pointer;" : ""}
      padding:  ${theme.space[1]}px;      
      border: 1px solid ${theme.colors.grayAE};      
       @media (min-width: ${theme.breakpoints[2]}px) {
           max-width: 80vw;
       }
  `}
`;
const GallerySlide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Gallery = ({ attachments, largeImages, title }) => {
  const [imageIndex, setImageIndex] = React.useState(undefined);

  const closeModal = () => {
    setImageIndex(undefined);
  };
  const openModal = imageIdx => {
    setImageIndex(imageIdx);
  };

  if (!attachments || attachments.length === 0) {
    return null;
  }
  return (
    <GalleryWrapper>
      <Slide
        arrows={false}
        pauseOnHover
        transitionDuration={500}
        duration={5000}
      >
        {attachments.map((attachment, idx) => (
          <GallerySlide key={attachment.id}>
            <GalleryImage
              src={
                largeImages ? attachment.image_url : attachment.thumbnail_url
              }
              clickable={!largeImages}
              onClick={
                largeImages
                  ? null
                  : () => {
                      openModal(idx);
                    }
              }
            />
          </GallerySlide>
        ))}
      </Slide>
      {largeImages ? null : (
        <Dialog open={imageIndex !== undefined} onClose={closeModal} fullWidth>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Slide indicators arrows pauseOnHover transitionDuration={500}>
              {attachments.map(attachment => (
                <GallerySlide key={attachment.id}>
                  <GalleryImage src={attachment.image_url} />
                </GallerySlide>
              ))}
            </Slide>
          </DialogContent>
        </Dialog>
      )}
    </GalleryWrapper>
  );
};
