import * as Yup from "yup";

export const NominationValueValidationSchema = Yup.object().shape({
  notes: Yup.string(),
  quantity: Yup.mixed().when("notes", {
    is: notes => typeof notes === "string" && Boolean(notes.trim()),
    then: Yup.number()
      .required("Headcount required")
      .typeError("required when notes provided")
      .integer("This field must be integer"),
    otherwise: Yup.number()
      .nullable()
      .typeError("This field must be a number")
      .integer("This field must be integer"),
  }),
});

export const ValidationSchema = Yup.object().shape({
  consigningDeploymentId: Yup.string()
    .required("required")
    .typeError("required"),
  deploymentId: Yup.string().required("required"),
  hasArrived: Yup.boolean().required("required"),
  id: Yup.string().required("required"),
  livestockSaleId: Yup.number().required("required"),
  nominationValues: Yup.array()
    .transform((_, nominationValues) => {
      return Object.entries(nominationValues)
        .filter(
          ([_ignored, nominationValue]) =>
            // Don't validate any items which don't have any values entered
            nominationValue.quantity || nominationValue.notes,
        )
        .reduce((acc, [nominationTermId, nominationValue]) => {
          acc[nominationTermId] = nominationValue;
          return acc;
        }, []);
    })
    .of(NominationValueValidationSchema)
    .min(1, "You must enter nomination details"),
  propertyId: Yup.string().nullable(),
  vendorId: Yup.string()
    .nullable(false)
    .typeError("required")
    .required("required"),
});
