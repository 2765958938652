import { useMemo } from "react";

import { useSelector } from "react-redux";

import { selectReceivalScanDeploymentIdByEidLookup } from "selectors";

export function useGetReceivalScannedDeploymentIds(eids) {
  // For the eids provided, go and find the deployments that they are linked to via a receival scan -> receival lot-> consignment -> deployment sale -> deployment.

  const receivalScanDeploymentIdsByEidLookup = useSelector(
    selectReceivalScanDeploymentIdByEidLookup,
  );
  return useMemo(() => {
    const deploymentIds = new Set();
    eids.forEach(eid => {
      if (receivalScanDeploymentIdsByEidLookup[eid]) {
        deploymentIds.add(receivalScanDeploymentIdsByEidLookup[eid]);
      }
    });
    return deploymentIds;
  }, [receivalScanDeploymentIdsByEidLookup, eids]);
}
