import { intersection } from "lodash";
import sortBy from "lodash/sortBy";
import { createSelector } from "reselect";

import { PenTypes, ScanningPenStatus } from "constants/auctionPens";
import { Settings } from "constants/settings";

import { EMPTY_ARRAY } from "lib";

import {
  buildScanningPenGroups,
  sortPenScanLotsByGroupingName,
} from "lib/auctionPens";

import {
  createLookupCombiner,
  createLookupSelectors,
  getAuctionPenById,
  getAuctionPens,
  getPenArchetypeById,
  getPenArchetypes,
  getReceivalLots,
  getSetting,
  selectFilteredAuctionPenIds,
  selectOrderedPenIdsByPenType,
  selectPenIdsByPenArchetypeIdLookup,
  selectPenIdsByPenTypeLookup,
  selectPenStatusByIdReducer,
  selectReceivalLotIdsByReceivalPenIdLookup,
  selectReceivalLotStatusByIdLookup,
} from "selectors";

import { selectFilteredPenArchetypeIds } from "./globalSearch/penArchetypes";

export const [selectStatusByReceivalPenIdLookup, getStatusByReceivalPenId] =
  createLookupSelectors(
    [
      getAuctionPens,
      selectReceivalLotIdsByReceivalPenIdLookup,
      selectReceivalLotStatusByIdLookup,
    ],
    createLookupCombiner(selectPenStatusByIdReducer),
    ScanningPenStatus.NONE_SCANNED,
  );

const selectReceivalFreeFormPenIds = createSelector(
  [
    selectPenIdsByPenArchetypeIdLookup,
    selectPenIdsByPenTypeLookup,
    selectFilteredAuctionPenIds,
  ],
  (penIdsByPenArchetypeIdLookup, penIdsByPenType, filteredAuctionPenIds) =>
    intersection(
      penIdsByPenArchetypeIdLookup.null,
      penIdsByPenType[PenTypes.RECEIVING],
      filteredAuctionPenIds,
    ),
);

export const selectFreeFormReceivingPens = createSelector(
  [selectReceivalFreeFormPenIds, getAuctionPens],
  (freeFormPenIds, auctionPens) =>
    freeFormPenIds.map(penId => auctionPens[penId]),
);

const selectNextReceivalPenIdsByReceivalPenIdOrReceivalArchetypeIdLookup =
  createSelector([selectOrderedPenIdsByPenType], orderedPenIdsByType =>
    orderedPenIdsByType[PenTypes.RECEIVING].reduce((acc, penIds, idx) => {
      if (idx < orderedPenIdsByType[PenTypes.RECEIVING].length - 1) {
        if (penIds.penId) {
          acc[penIds.penId] = orderedPenIdsByType[PenTypes.RECEIVING][idx + 1];
        }

        if (penIds.penArchetypeId) {
          acc[penIds.penArchetypeId] =
            orderedPenIdsByType[PenTypes.RECEIVING][idx + 1];
        }
      }
      return acc;
    }, {}),
  );

export const getNextReceivalPenIdByReceivalPenIdOrReceivalPenArchetypeId =
  penId => state =>
    selectNextReceivalPenIdsByReceivalPenIdOrReceivalArchetypeIdLookup(state)[
      penId
    ] || null;

const selectPreviousReceivalPenIdsByReceivalPenIdOrReceivalArchetypeIdLookup =
  createSelector([selectOrderedPenIdsByPenType], orderedPenIdsByType =>
    orderedPenIdsByType[PenTypes.RECEIVING].reduce((acc, penIds, idx) => {
      if (idx > 0) {
        if (penIds.penId) {
          acc[penIds.penId] = orderedPenIdsByType[PenTypes.RECEIVING][idx - 1];
        }

        if (penIds.penArchetypeId) {
          acc[penIds.penArchetypeId] =
            orderedPenIdsByType[PenTypes.RECEIVING][idx - 1];
        }
      }
      return acc;
    }, {}),
  );

export const getPreviousReceivalPenIdByReceivalPenId = penId => state =>
  selectPreviousReceivalPenIdsByReceivalPenIdOrReceivalArchetypeIdLookup(state)[
    penId
  ] || null;

export const getNextReceivalPenByPenIdAndArchetypeId =
  (penId, penArchetypeId) => state => {
    const nextByArchetypeId =
      getNextReceivalPenIdByReceivalPenIdOrReceivalPenArchetypeId(
        penArchetypeId,
      )(state) || null;

    const nextByPenId =
      getNextReceivalPenIdByReceivalPenIdOrReceivalPenArchetypeId(penId)(
        state,
      ) || null;

    return nextByPenId || nextByArchetypeId || null;
  };

export const getNextReceivalPenTitleByPenIdOrPenArchetypeId =
  (penId, penArchetypeId) => state => {
    const nextPenDetails = getNextReceivalPenByPenIdAndArchetypeId(
      penId,
      penArchetypeId,
    )(state);

    return (
      getAuctionPenById(nextPenDetails?.penId)(state)?.start_pen ||
      getPenArchetypeById(nextPenDetails?.penArchetypeId)(state)?.penName ||
      null
    );
  };

export const getPrevReceivalPenByPenIdAndArchetypeId =
  (penId, penArchetypeId) => state => {
    const prevByArchetypeId =
      getPreviousReceivalPenIdByReceivalPenId(penArchetypeId)(state) || null;
    const prevByPenId =
      getPreviousReceivalPenIdByReceivalPenId(penId)(state) || null;
    return prevByPenId || prevByArchetypeId || null;
  };

export const getPrevReceivalPenTitleByPenIdOrPenArchetypeId =
  (penId, penArchetypeId) => state => {
    const prevPenDetails = getPrevReceivalPenByPenIdAndArchetypeId(
      penId,
      penArchetypeId,
    )(state);

    return (
      getAuctionPenById(prevPenDetails?.penId)(state)?.start_pen ||
      getPenArchetypeById(prevPenDetails?.penArchetypeId)(state)?.penName ||
      null
    );
  };

export const selectFilteredReceivalPenGroups = createSelector(
  [
    selectFilteredPenArchetypeIds,
    getPenArchetypes,
    selectPenIdsByPenArchetypeIdLookup,
    selectStatusByReceivalPenIdLookup,
    selectFreeFormReceivingPens,
    selectReceivalLotIdsByReceivalPenIdLookup,
    getReceivalLots,
    getSetting(Settings.penScanningGroupType),
  ],

  (
    filteredPenArchetypeIds,
    penArchetypes,
    penIdsByPenArchetypeIdLookup,
    statusByReceivalPenIdLookup,
    freeFormReceivingPens,
    receivalLotIdsByReceivalPenIdLookup,
    receivalLotByIdLookup,
    groupedBy,
  ) => {
    const receivalPenArchetypes = sortBy(
      filteredPenArchetypeIds
        .map(penArchetypeId => penArchetypes[penArchetypeId])
        .filter(penArchetype => penArchetype.penType === PenTypes.RECEIVING),
      "order",
    );

    const buildScanPenGroups = buildScanningPenGroups(
      receivalPenArchetypes,
      penIdsByPenArchetypeIdLookup,
      statusByReceivalPenIdLookup,
      freeFormReceivingPens,
      receivalLotIdsByReceivalPenIdLookup,
      receivalLotByIdLookup,
      groupedBy,
    );

    return sortPenScanLotsByGroupingName(groupedBy, buildScanPenGroups);
  },
);

export const selectUsedReceivalPenGroups = createSelector(
  [selectFilteredReceivalPenGroups],
  penGroups => penGroups.filter(penGroup => penGroup.quantity > 0),
);

export const getReceivalPenGroupByIndex = index => state => {
  return selectFilteredReceivalPenGroups(state)[index] || EMPTY_ARRAY;
};

export const getUsedReceivalPenGroupByIndex = index => state =>
  selectUsedReceivalPenGroups(state)[index] || EMPTY_ARRAY;
