import React, { memo } from "react";

import { uniqBy } from "lodash/array";
import PropTypes from "prop-types";

import { caseInsensitiveCompare } from "lib/compare";

function PropertyNamesComponent(props) {
  const { names } = props;

  if (!Array.isArray(names) || names.length === 0) {
    return null;
  }

  const formattedNames = uniqBy(names, name => name.toLowerCase()).sort(
    caseInsensitiveCompare,
  );

  return formattedNames.map((name, i, arr) => {
    return [
      <span key="name">{name}</span>,
      i !== arr.length - 1
        ? // Append a comma and a space to all items which are not the last name the list
          ", "
        : // Don't anything to the last element in the list
          null,
    ];
  });
}
PropertyNamesComponent.propTypes = {
  names: PropTypes.array,
};

export const PropertyNames = memo(PropertyNamesComponent);

function PropertyComponent(props) {
  const { pic, names } = props;
  return (
    <span className="property">
      {pic && <span className="pic">{pic}</span>}
      {Array.isArray(names) &&
        names.length > 0 && [
          " (",
          <PropertyNames key="property-names" names={names} />,
          ")",
        ]}
    </span>
  );
}
PropertyComponent.propTypes = {
  pic: PropTypes.string.isRequired,
  names: PropTypes.array,
};

export const Property = memo(PropertyComponent);
