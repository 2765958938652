export const AUCTIONS_PLUS_CSV_STORAGE_KEY = "auctionsPlusCSVData";
export const AUCTIONS_PLUS_CSV_FILENAME_KEY = "auctionsPlusCSVFilename";

export const SG8_XML_STORAGE_KEY = "SG8XMLData";
export const SG8_XML_FILENAME_KEY = "SG8XMLFilename";

export const IMPORTER_TYPES = {
  PRE_SALE_CSV: "preSaleCsv",
  EXTERNAL_AGENT_XML: "externalAgentXml",
};
