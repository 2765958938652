import queryString from "query-string";

import { LEDGER_ENTRY } from "constants/actionTypes";

import { serializeLedgerEntry } from "lib/serializers/ledgerEntries";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const ledgerEntryUrlCreator = ({
  changesSince,
  documentBillingRunId,
  id,
  action,
} = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction(`/v2/billing-ledger-entries/`, id, action),
    query: {
      changesSince,
      document__billing_run__cbid: documentBillingRunId,
    },
  });

export const LedgerEntryAction = offlineActionCreator(
  LEDGER_ENTRY,
  serializeLedgerEntry,
  ledgerEntryUrlCreator,
  false,
);

LedgerEntryAction.deduplicate = (
  id,
  options = {
    changeReason: null,
    suppressToast: false,
    suppressErrorToast: false,
    suppressSuccessToast: false,
  },
) => {
  return {
    type: LEDGER_ENTRY.DEDUPLICATE.REQUEST,
    id,
    meta: {
      offline: {
        effect: {
          url: ledgerEntryUrlCreator({
            id,
            action: "deduplicate",
          }),
          method: "POST",
          options,
        },
        commit: {
          options,
          type: LEDGER_ENTRY.DEDUPLICATE.SUCCESS,
        },
        rollback: {
          options,
          type: LEDGER_ENTRY.DEDUPLICATE.FAILURE,
        },
      },
    },
  };
};
