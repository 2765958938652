import React, { useState } from "react";

import { Dialog, Grid } from "@material-ui/core";

import { Label } from "components/Form/FormikControls";
import { Column } from "components/Layout";
import { DialogTitle } from "components/MaterialDialog";
import { Paper } from "components/Paper";
import ReactSelect, {
  findOptionByValue,
} from "components/SearchableSelector/ReactSelect";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

import { ManyReportsForm } from "./ManyReportsForm";
import { MultiSaleReportForm } from "./SingleReportForm";

export const GenerateMode = {
  SINGLE: "single",
  MANY: "bulk",
};

const GenerateModeDisplayNameMap = {
  [GenerateMode.SINGLE]: "One Report",
  [GenerateMode.MANY]: "Many Reports",
};

export const GenerateModeOptions = Object.values(GenerateMode).map(value => ({
  label: GenerateModeDisplayNameMap[value],
  value,
}));

export function MultiSaleReportsModal(props) {
  const [hashParams, _ignored, onClose] = useModalAdapter(
    ModalTypes.MultiSaleReports,
    props,
  );

  const { allowedReports, generateMode: preselectedGenerateMode } = hashParams;

  const [generateMode, setGenerateMode] = useState(
    preselectedGenerateMode || GenerateMode.SINGLE,
  );

  const onChangeAmount = option => {
    setGenerateMode(option.value);
  };

  return (
    <Dialog open onClose={onClose} fullScreen>
      <DialogTitle onClose={onClose}>Generate Reports</DialogTitle>

      <Column padding={2}>
        <Paper>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <Label>Mode</Label>
              <ReactSelect
                options={GenerateModeOptions}
                onChange={onChangeAmount}
                value={findOptionByValue(GenerateModeOptions, generateMode)}
              />
            </Grid>
          </Grid>
          {generateMode === GenerateMode.SINGLE && (
            <MultiSaleReportForm
              onClose={onClose}
              allowedReports={allowedReports}
            />
          )}

          {generateMode === GenerateMode.MANY && (
            <ManyReportsForm
              onClose={onClose}
              allowedReports={allowedReports}
            />
          )}
        </Paper>
      </Column>
    </Dialog>
  );
}
