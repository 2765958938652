import React from "react";

import { faEye, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";

import { BillingRunPermissions } from "constants/billingRuns";
import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";
import { hasPermission } from "lib/permissions";

export function BillingRunActionsColumnRenderer(props) {
  const { data, node } = props;

  if (node.group) {
    return null;
  }

  const hasChangeBillingRunPermission = hasPermission(
    data.billingRun,
    BillingRunPermissions.update,
  );

  function onEditClick() {
    openModalLink(ModalTypes.EditBillingRun, {
      billingRunId: node.id,
    });
  }

  return (
    <ActionButtonContainer>
      <ActionButton
        data-tour="action-edit-billing-run"
        onClick={onEditClick}
        type="button"
      >
        <FontAwesomeIcon
          icon={hasChangeBillingRunPermission ? faPencil : faEye}
        />
        &nbsp;Billing Run
      </ActionButton>
    </ActionButtonContainer>
  );
}

export const BillingRunColId = {
  STATUS: "5249cf35-eb3f-43c4-9143-a4b22a984234",
  NAME: "a586ab31-cee5-4808-824e-fce6ff04797c",
  ACTIONS: "c35f1baa-1871-46ee-82b2-30837d42517a",
};

export const BillingRunColumnDefs = {
  [BillingRunColId.STATUS]: {
    colId: BillingRunColId.STATUS,
    field: "billingRun.status",
    headerName: "Status",
    rowGroup: true,
  },
  [BillingRunColId.NAME]: {
    colId: BillingRunColId.NAME,
    field: "billingRun.name",
    headerName: "Name",
  },
  [BillingRunColId.ACTIONS]: {
    colId: BillingRunColId.ACTIONS,
    headerName: "Actions",
    pinned: "right",
    cellRenderer: BillingRunActionsColumnRenderer,
  },
};
