import React, { useEffect, useMemo, useState } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, getIn, useFormikContext } from "formik";
import { isEmpty, uniq } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { LegacyVendorSplitSection } from "components/ConsignmentForm/LegacyVendorSplitsSection";
import { getValidationSchema } from "components/ConsignmentForm/validationSchema";
import { VendorSplitSection } from "components/ConsignmentForm/VendorSplitSection";
import { FeesAndChargesCollapse } from "components/FeesAndChargesCollapse";
import { CollapseLabel, FormCollapse } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { MediaCollapse } from "components/MediaCollapse";

import { ApiModel } from "constants/loading";
import { ConsignmentModalSection, ModalTypes } from "constants/navigation";
import {
  ConsignmentPermissions,
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { SaleTypes, TransactionalSaleTypes } from "constants/sale";

import {
  ForClearingSale,
  ForNotClearingSale,
  ForSaleType,
} from "containers/ForSaleType";
import { ForLivestockAgent } from "containers/ForUserType";

import {
  getLivestockSaleId,
  openConsignmentCarrierChargeModal,
} from "lib/navigation";
import { hasPermission } from "lib/permissions";
import toast from "lib/toast";

import {
  getActiveLivestockAgentAgency,
  getConsignmentById,
  getContextByModalType,
  getCurrentSale,
  getCurrentSaleyard,
  getDeploymentSaleIdByLivestockSaleIdAndAgencyId,
  getIsLivestockAgent,
  getIsSaleyardAdmin,
  getPrimaryAgency,
  getReceivalLotIdsByConsignmentId,
  getSaleLotIdsByConsignmentId,
  getSaleLotQuantityByConsignmentId,
  getSaleyardScanSaleLotByConsignmentId,
  getScansBySaleLotId,
  getVendorSplitConsignmentIdsByParentConsignmentId,
  selectDeploymentCodeByAgencyIdLookup,
  selectDeploymentCodeByConsignmentIdLookup,
} from "selectors";

import { useHasDeploymentPermission, useHasPermission } from "hooks";

import AdvancedDraftingSection from "./AdvancedDrafting";
import {
  CarrierChargeSection,
  CarrierChargeSectionHeader,
} from "./CarrierChargeSection";
import ClearingSalePickupDetails from "./ClearingSalePickupDetails";
import { DraftingForm } from "./DraftingForm";
import EIDsSection from "./EIDsSection";
import { GeneralForm, GeneralFormSectionHeader } from "./GeneralForm";
import { NLISCollapse } from "./nlis";
import NotesSection from "./NotesSection";
import { SaleLotSection } from "./SaleLotSection";

const getDeploymentSaleIdForCreateOrEditConsignment =
  (consignmentId, livestockSaleId, agencyId) => state => {
    // When editing an existing consignment, a Consignment Id is provided, we must honour the Deployment Sale attached to that
    // NOTE: This only accounts for editing a Consignment from THE CURRENT Livestock Sale.
    if (consignmentId) {
      return getConsignmentById(consignmentId)(state)?.deployment_sale;
    }
    const isLivestockAgent = getIsLivestockAgent(state);
    const isSaleyardAdmin = getIsSaleyardAdmin(state);

    // When consigning to another Deployment/Livestock Sale, a Livestock Sale Id is provided, and it will NOT be the current Livestock Sale Id
    if (isSaleyardAdmin) {
      // When **creating** a new Consignment as a Saleyard Admin, determine the Deployment Sale Id by the selected Agency Id, and the selected Livestock Sale Id
      return getDeploymentSaleIdByLivestockSaleIdAndAgencyId(
        livestockSaleId,
        agencyId,
      )(state);
    } else if (isLivestockAgent) {
      const primaryAgency = getPrimaryAgency(state);
      // When **creating** a new Consignment as a Livestock Agent, determine the Deployment Sale Id by the user's default Agency Id, and the selected Livestock Sale Id
      return getDeploymentSaleIdByLivestockSaleIdAndAgencyId(
        livestockSaleId,
        primaryAgency.agency_id,
      )(state);
    }
  };

const ConsignmentFormBody = ({
  associatedCarrierChargesCount,
  branches,
  carrierName,
  consignmentId,
  consignmentsByVendorNumber,
  deleteConsignment,
  deploymentSaleId,
  handleClose,
  isCreate,
  livestockSaleId = getLivestockSaleId(), // defaults to the current Livestock Sale Id
  restrictConsignedFrom,
  setIsSubmitEnabled,
  showSaleLots,
  showClearingSection = true,
  showNominations = true,
  vendors,
  selectedSaleType = null,
  requireConsignedFrom,
  saleyardName,
  section,
  changeSection,
  readOnly,
  defaultAgencyId,
  receivalLotIds,
  hasModalContextValues,
}) => {
  const { handleSubmit, values, setFieldValue } = useFormikContext();

  const hasReceivalLotPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featureReceivalLots,
  );
  useEffect(() => {
    if (hasReceivalLotPermission) {
      setFieldValue("receivalLotIds", [...receivalLotIds]);
    }
  }, [setFieldValue, receivalLotIds, hasReceivalLotPermission]);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useSubmitHandler(isCreate || hasModalContextValues, setIsSubmitEnabled);

  const saleyardScanSaleLot =
    useSelector(getSaleyardScanSaleLotByConsignmentId(values.id)) || {};

  const deploymentCodeByConsignmentIdLookup = useSelector(
    selectDeploymentCodeByConsignmentIdLookup,
  );

  const saleLotIds =
    useSelector(getSaleLotIdsByConsignmentId(consignmentId)) || [];
  const consignmentSaleLotsHeadCount = useSelector(
    getSaleLotQuantityByConsignmentId(consignmentId),
  );

  const saleyardScans =
    useSelector(getScansBySaleLotId(saleyardScanSaleLot.id)) || [];

  const deploymentCodeByAgencyIdLookup = useSelector(
    selectDeploymentCodeByAgencyIdLookup,
  );

  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);
  const { id, vendorNumber, agency_id } = values;
  const consignmentsWithSameVendorNumber =
    consignmentsByVendorNumber && consignmentsByVendorNumber[vendorNumber]
      ? consignmentsByVendorNumber[vendorNumber].filter(c => {
          if (!id && agency_id) {
            // if a new consignment, and an agency is selected (ie: SY admin)
            return (
              deploymentCodeByAgencyIdLookup[agency_id] ===
              deploymentCodeByConsignmentIdLookup[c.id]
            );
          } else if (!id && !isSaleyardAdmin) {
            // if a new consignment - no agency has been selected and is not a saleyard admin
            return c.vendorNumber === vendorNumber;
          } else {
            // otherwise an existing consignment
            // if a SY admin - The deploymentCode is important : if a stock agent it will be identical (check it anyway)
            return (
              c.id !== id &&
              deploymentCodeByConsignmentIdLookup[c.id] ===
                deploymentCodeByConsignmentIdLookup[id]
            );
          }
        })
      : [];

  const agencyId = getIn(values, "agency_id");
  const vendorId = getIn(values, "vendor_id");

  const headcount = values?.quantity;
  const vendorName = vendors ? vendors[(values || {}).vendor_id]?.name : "";
  const invoiceAmount = values?.carrierCharge?.invoiceAmount;
  const NVD = values?.NVD;

  const consignmentHasSaleLots = saleLotIds.length > 0;

  // Build a toggle function for each section.
  const toggleSectionMap = React.useMemo(() => {
    return Object.keys(ConsignmentModalSection).reduce((acc, sectionName) => {
      acc[sectionName] = e => {
        changeSection && changeSection(section, sectionName);
        e.stopPropagation();
      };
      return acc;
    }, {});
  }, [changeSection, section]);

  const isSectionOpen = testSection => section === testSection;

  const onDeleteConsignment = () => {
    saleLotIds.length === 0
      ? setShowConfirmDelete(true)
      : toast.error("Consignment cannot be deleted when it has sale lots.");
  };

  const closeConfirmDialog = () => setShowConfirmDelete(false);

  function onResetCarrierCharge() {
    setFieldValue("carrierChargeId", null);
    setFieldValue("carrierCharge", {});
  }

  function onFindCarrierCharge() {
    openConsignmentCarrierChargeModal(
      consignmentId,
      false,
      window.location.hash,
    );
  }

  const generalFormHeader = (
    <GeneralFormSectionHeader
      headcount={headcount}
      vendorName={vendorName}
      NVD={NVD}
    />
  );
  const carrierFormHeader = (
    <CarrierChargeSectionHeader
      carrierName={carrierName}
      invoiceAmount={invoiceAmount}
    />
  );
  const header = (
    <Grid data-tour="saleLotsTab" container>
      <SubtleBadge>Sale Lots</SubtleBadge>
      <CollapseLabel>
        {`${saleLotIds.length} Lot(s), ${consignmentSaleLotsHeadCount} Head`}
      </CollapseLabel>
    </Grid>
  );

  const calculateDeploymentSaleId = useSelector(
    getDeploymentSaleIdForCreateOrEditConsignment(
      consignmentId,
      livestockSaleId,
      agencyId,
    ),
  );

  // Manual vendor number is only allowed when consigning to the current Livestock Sale
  const isVendorNumberingAllowed = livestockSaleId === getLivestockSaleId();

  const hasFeatureVendorSplitsPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureVendorSplits,
  );
  const hasFeaturePercentageVendorSplitsPermission = useHasDeploymentPermission(
    DeploymentPermissions.featurePercentageVendorSplits,
  );
  const hasVendorSplitsPermission =
    hasFeatureVendorSplitsPermission ||
    hasFeaturePercentageVendorSplitsPermission;

  const hasLegacyVendorSplits = useSelector(
    getVendorSplitConsignmentIdsByParentConsignmentId(consignmentId),
  );

  const hasDeletePermission = hasPermission(
    values,
    ConsignmentPermissions.delete,
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FormCollapse
        isOpen={isSectionOpen(ConsignmentModalSection.GENERAL)}
        onToggle={toggleSectionMap[ConsignmentModalSection.GENERAL]}
        header={generalFormHeader}
        dataTour={`collapse.${ConsignmentModalSection.GENERAL}`}
      >
        <GeneralForm
          branches={branches}
          isDeleteDisabled={saleLotIds.length > 0 || !hasDeletePermission}
          deploymentSaleId={deploymentSaleId || calculateDeploymentSaleId}
          isCreate={isCreate}
          isVendorNumberingAllowed={isVendorNumberingAllowed}
          onDeleteConsignment={onDeleteConsignment}
          showDelete={!isCreate && typeof deleteConsignment === "function"}
          vendorNumConflictCount={consignmentsWithSameVendorNumber.length}
          restrictConsignedFrom={restrictConsignedFrom}
          selectedSaleType={selectedSaleType}
          requireConsignedFrom={requireConsignedFrom}
          saleyardName={saleyardName}
          consignmentHasSaleLots={consignmentHasSaleLots}
          readOnly={readOnly}
          showNominations={showNominations}
          defaultAgencyId={defaultAgencyId}
        />
      </FormCollapse>
      <ForClearingSale selectedSaleType={selectedSaleType}>
        {showClearingSection && (
          <ClearingSalePickupDetails
            isOpen={isSectionOpen(ConsignmentModalSection.PICKUP_DETAILS)}
            onToggle={toggleSectionMap[ConsignmentModalSection.PICKUP_DETAILS]}
            vendorId={vendorId}
            readOnly={readOnly}
          />
        )}
      </ForClearingSale>

      <ForClearingSale selectedSaleType={selectedSaleType}>
        {showClearingSection && (
          <FeesAndChargesCollapse
            isOpen={isSectionOpen(ConsignmentModalSection.FEES_AND_CHARGES)}
            onToggle={
              toggleSectionMap[ConsignmentModalSection.FEES_AND_CHARGES]
            }
            consignmentId={consignmentId}
            readOnly={readOnly}
          />
        )}
      </ForClearingSale>

      <ForSaleType saleType={[SaleTypes.OVER_HOOKS, SaleTypes.PADDOCK]}>
        {isCreate && (
          <FormCollapse
            isOpen={isSectionOpen(ConsignmentModalSection.DRAFTING)}
            onToggle={toggleSectionMap[ConsignmentModalSection.DRAFTING]}
            header={<SubtleBadge>Draft Information</SubtleBadge>}
          >
            <DraftingForm />
          </FormCollapse>
        )}
        <ForLivestockAgent>
          <FormCollapse
            isOpen={isSectionOpen(ConsignmentModalSection.CARRIER_CHARGE)}
            onToggle={toggleSectionMap[ConsignmentModalSection.CARRIER_CHARGE]}
            header={carrierFormHeader}
            dataTour={
              isSectionOpen(ConsignmentModalSection.CARRIER_CHARGE)
                ? "hideCarrier"
                : "showCarrier"
            }
          >
            <CarrierChargeSection
              associatedCount={associatedCarrierChargesCount}
              onFindCarrierCharge={onFindCarrierCharge}
              onResetCarrierCharge={onResetCarrierCharge}
              carrierChargeId={values.carrierChargeId}
              showLinkToExisting={Boolean(consignmentId)}
            />
          </FormCollapse>
        </ForLivestockAgent>
      </ForSaleType>
      {showSaleLots && (
        <SaleLotSection
          isOpen={isSectionOpen(ConsignmentModalSection.SALE_LOTS)}
          onToggle={toggleSectionMap[ConsignmentModalSection.SALE_LOTS]}
          header={header}
          consignmentId={consignmentId}
        />
      )}
      {!isCreate && (
        <ForNotClearingSale>
          <MediaCollapse
            isOpen={isSectionOpen(ConsignmentModalSection.MEDIA)}
            onToggle={toggleSectionMap[ConsignmentModalSection.MEDIA]}
            consignmentId={consignmentId}
            readOnly={readOnly}
            saleType={selectedSaleType}
          />
          <NLISCollapse
            isOpen={isSectionOpen(ConsignmentModalSection.NLIS)}
            onToggle={toggleSectionMap[ConsignmentModalSection.NLIS]}
            consignmentId={consignmentId}
          />
          <EIDsSection
            isOpen={isSectionOpen(ConsignmentModalSection.EIDS)}
            onToggle={toggleSectionMap[ConsignmentModalSection.EIDS]}
            scans={saleyardScans}
          />
          <AdvancedDraftingSection
            isOpen={isSectionOpen(ConsignmentModalSection.ADVANCED_DRAFTING)}
            onToggle={
              toggleSectionMap[ConsignmentModalSection.ADVANCED_DRAFTING]
            }
            consignmentId={consignmentId}
            readOnly={readOnly}
          />
          <NotesSection
            isOpen={isSectionOpen(ConsignmentModalSection.NOTES)}
            onToggle={toggleSectionMap[ConsignmentModalSection.NOTES]}
            readOnly={readOnly}
          />
          {hasVendorSplitsPermission && hasLegacyVendorSplits.length > 0 && (
            <LegacyVendorSplitSection
              isOpen={isSectionOpen(
                ConsignmentModalSection.LEGACY_VENDOR_SPLITS,
              )}
              onToggle={
                toggleSectionMap[ConsignmentModalSection.LEGACY_VENDOR_SPLITS]
              }
              readOnly={readOnly}
              consignmentId={consignmentId}
            />
          )}
          {hasVendorSplitsPermission && (
            <VendorSplitSection
              isOpen={isSectionOpen(ConsignmentModalSection.VENDOR_SPLITS)}
              onToggle={toggleSectionMap[ConsignmentModalSection.VENDOR_SPLITS]}
              readOnly={readOnly}
              consignmentId={consignmentId}
            />
          )}
        </ForNotClearingSale>
      )}

      <ConfirmDialog
        title={createModalTitle("this Consignment")}
        isOpen={showConfirmDelete && saleLotIds.length === 0}
        onCancel={closeConfirmDialog}
        onDelete={() => {
          if (saleLotIds.length === 0) {
            deleteConsignment({
              ...values,
              vendor: vendors[values.vendor_id],
            });
            // Close assuming the delete was successful.
            typeof handleClose === "function" && handleClose();
          }
        }}
      />
    </Form>
  );
};

const ConsignmentForm = ({
  associatedCarrierChargesCount,
  branches = [],
  carrierName,
  consignmentId,
  consignmentsByVendorNumber,
  deleteConsignment,
  formRef,
  handleClose,
  handleSubmit,
  isCreate,
  livestockSaleId,
  requiresAgency,
  restrictConsignedFrom,
  setIsSubmitEnabled,
  showSaleLots,
  showClearingSection,
  showNominations,
  values,
  vendors,
  deploymentSaleId,
  selectedSaleType = null,
  requireConsignedFrom,
  saleyardName,
  section,
  changeSection,
  readOnly,
  defaultAgencyId,
}) => {
  const existingVendor = vendors && values ? vendors[values.vendor_id] : null;

  let agencyId = defaultAgencyId;
  let branchId;

  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const agency = useSelector(getActiveLivestockAgentAgency);

  const receivalLotIds = useSelector(
    getReceivalLotIdsByConsignmentId(consignmentId),
  );

  // collect together the receival lot ids in values and receival lot ids associated to this consignmment
  const consolidatedReceivalLotIds = useMemo(
    () =>
      values?.receivalLotIds
        ? uniq([...values.receivalLotIds, ...receivalLotIds])
        : [...receivalLotIds],
    [values?.receivalLotIds, receivalLotIds],
  );

  const hasReceivalLotPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featureReceivalLots,
  );

  const {
    values: modalStateValues = {},
    errors: modalStateErrors = {},
    touched: modalStateTouched = {},
  } = useSelector(getContextByModalType(ModalTypes.Consignment)) || {};

  const { newReceivalLotId = null } =
    useSelector(getContextByModalType(ModalTypes.EditReceivalLot)) || {};

  if (isLivestockAgent) {
    agencyId = isLivestockAgent && agency.id;
    branchId = existingVendor && existingVendor.branchId;
  }

  const isSaleYardAuction = selectedSaleType === SaleTypes.SALEYARD_AUCTION;

  const formDefaults = {
    vendor_id: null,
    quantity: null,
    hasArrived: false,
    useVendorAddressAsPickupAddress: true,
  };

  const initialValues = {
    ...formDefaults,
    ...values,
    branch_id: branchId,
    agency_id: agencyId,
    receivalLotIds: consolidatedReceivalLotIds,
    // Note that modal state values may overwrite the initial values above
    ...modalStateValues,
  };

  if (hasReceivalLotPermission) {
    // if a new receival lot Id is passed in via state, update the receival lot ids to include it
    if (newReceivalLotId) {
      initialValues.receivalLotIds.push(newReceivalLotId);
    }
  } else {
    // Prevent user without permission overwriting any receival lots
    delete initialValues.receivalLotIds;
  }

  // For a consignment, if not set, initialize (relevant) drafting attributes as unset.
  // Note that this could be species driven, but the default is null anyway - this is more about
  // the form rendering a namespaced sub-value.
  if (!initialValues.draftingAttributes) {
    initialValues.draftingAttributes = {
      accreditationNEE: null,
      accreditationGrassFed: null,
      accreditationAntibioticFree: null,
      accreditationEU: null,
      accreditationPCAS: null,
      accreditationPTIC: null,
      drenched: null,
      gudairApproved: null,
      backline: null,
      SAEligible: null,
      sixInOne: null,
      b12Vac: null,
      MAndTS: null,
      painRelief: null,
    };
  }

  const saleType = useSelector(getCurrentSale)?.sale_type || null;

  const includeCarrierCharge =
    TransactionalSaleTypes.includes(saleType) && isLivestockAgent;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(
        requiresAgency,
        restrictConsignedFrom || requireConsignedFrom,
        isSaleYardAuction,
        includeCarrierCharge,
      )}
      initialErrors={modalStateErrors}
      initialTouched={modalStateTouched}
      innerRef={formRef}
    >
      <ConsignmentFormBody
        associatedCarrierChargesCount={associatedCarrierChargesCount}
        branches={branches}
        carrierName={carrierName}
        consignmentId={consignmentId}
        consignmentsByVendorNumber={consignmentsByVendorNumber}
        deleteConsignment={deleteConsignment}
        livestockSaleId={livestockSaleId}
        formRef={formRef}
        handleClose={handleClose}
        isCreate={isCreate}
        requiresAgency={requiresAgency}
        restrictConsignedFrom={restrictConsignedFrom}
        setIsSubmitEnabled={setIsSubmitEnabled}
        showSaleLots={showSaleLots}
        showClearingSection={showClearingSection}
        showNominations={showNominations}
        values={values}
        vendors={vendors}
        deploymentSaleId={deploymentSaleId}
        selectedSaleType={selectedSaleType}
        requireConsignedFrom={requireConsignedFrom}
        saleyardName={saleyardName}
        section={section}
        changeSection={changeSection}
        readOnly={readOnly}
        defaultAgencyId={defaultAgencyId}
        receivalLotIds={initialValues.receivalLotIds}
        hasModalContextValues={!isEmpty(modalStateValues)}
        modalStateErrors={modalStateErrors}
        modalStateTouched={modalStateTouched}
      />
    </Formik>
  );
};

const LoadingWrapper = props => (
  <WaitForSync
    requiredData={[
      ApiModel.BUSINESSES,
      ApiModel.PROPERTIES,
      ApiModel.NOMINATIONS,
      ApiModel.SALE_LOTS,
      ApiModel.DRAFTING_INFORMATION,
      ApiModel.RECEIVAL_LOTS,
      ApiModel.AUCTION_PENS,
    ]}
  >
    <ConsignmentForm {...props} />
  </WaitForSync>
);

ConsignmentForm.propTypes = {
  associatedCarrierChargesCount: PropTypes.number,
  branches: PropTypes.array,
  consignmentId: PropTypes.string,
  consignmentsByVendorNumber: PropTypes.object,
  deleteConsignment: PropTypes.func,
  formRef: PropTypes.object,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  isCreate: PropTypes.bool,
  showNominations: PropTypes.bool,
  livestockSaleId: PropTypes.number,
  setIsSubmitEnabled: PropTypes.func,
  saleyardName: PropTypes.string,
  showSaleLots: PropTypes.bool,
  values: PropTypes.object.isRequired,
  vendors: PropTypes.object,
};

export default LoadingWrapper;
