export function localDateComparator(filterLocalDateAtMidnight, cellValue) {
  const midnightCellDate = new Date(cellValue).setHours(0, 0, 0, 0);
  if (midnightCellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (midnightCellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
}

export default {
  localDateComparator,
};
