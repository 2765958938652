import React, { memo } from "react";

import PropTypes from "prop-types";
import * as Yup from "yup";

import { InputDateRange } from "components/DateRangePicker/dateRangePicker";
import { withNamespace } from "components/Form/FormikControls";

export const businessTransactionsValidation = Yup.object().shape({
  startDate: Yup.date().required("Required"),
  endDate: Yup.date().required("Required"),
});
function BusinessTransactionsTableFormComponent(props) {
  const { namespace: ns, updateDates } = props;

  return (
    <InputDateRange
      updateDates={updateDates}
      startDateFieldName={withNamespace(ns, "startDate")}
      endDateFieldName={withNamespace(ns, "endDate")}
      includeTime={false}
      singleLine
      showYearOptions
      tooltip="Download a Transaction List for this business by date range (maximum allowable range: 1 year)"
    />
  );
}

BusinessTransactionsTableFormComponent.propTypes = {
  namespace: PropTypes.string,
};

export default memo(BusinessTransactionsTableFormComponent);
