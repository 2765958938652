import React from "react";

import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import SlimButton from "components/Button/SlimButton";
import { SelectField } from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";

import { ConsignmentPermissions } from "constants/permissions";

import { caseInsensitiveCompare } from "lib/compare";
import { formatConsignmentLabel } from "lib/consignments";
import { openEditConsignmentModal } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import {
  getNestedConsignments,
  selectAgencyByConsignmentIdLookup,
  selectAgencyByDeploymentSaleIdLookup,
  selectCurrentDeploymentSales,
  selectExportSiteOptionsByConsignmentIdLookup,
  selectExportSitesByDeploymentSaleIdLookup,
  selectUnknownBusinessIds,
} from "selectors";

import { useHasAddConsignmentPermission } from "hooks/useHasPermission";

const InlineConsignmentSelect = ({
  name = "consignment_id",
  label = "Consignment",
  includeCreateConsignmentButton = false,
  includeCreateUnknownConsignment = false,
  ignoreHeadCountForVendorSearch = false,
}) => {
  const formikProps = useFormikContext();
  const { errors, values } = formikProps;
  const consignments = useSelector(getNestedConsignments);
  const deploymentSales = useSelector(selectCurrentDeploymentSales);
  const agencyByConsignmentIdLookup = useSelector(
    selectAgencyByConsignmentIdLookup,
  );
  const unknownBusinessIds = useSelector(selectUnknownBusinessIds);
  const agencyByDeploymentSaleIdLookup = useSelector(
    selectAgencyByDeploymentSaleIdLookup,
  );
  const exportSitesByConsignmentIdLookup = useSelector(
    selectExportSiteOptionsByConsignmentIdLookup,
  );
  const exportSitesByDeploymentSaleIdLookup = useSelector(
    selectExportSitesByDeploymentSaleIdLookup,
  );

  const onAddConsignment = () => {
    openEditConsignmentModal();
  };

  // The first time we add the unknown business consignment, we use the same machinery,
  // but infer what the value refers to by checking its type.  If its a string/uuid, its
  // consignment, if its a number, its a livestock agency id.
  const isConsignmentId = value => value && typeof value === "string";
  const isLivestockAgencyId = value => value && typeof value === "number";

  const hasAddConsignmentPermission = useHasAddConsignmentPermission();

  let consignmentOptionFilter;
  if (ignoreHeadCountForVendorSearch) {
    consignmentOptionFilter = (option, input) => {
      const labelWithoutHeadCount = option.label
        .toLowerCase()
        .replace(/\(hd \d+\)/, "");
      return labelWithoutHeadCount.includes(input.toLowerCase());
    };
  }

  // Avoiding inlining in useSelector as that causes a react hook based re-render every time a hub message comes through
  const consignmentOptions = consignments
    .filter(
      c =>
        hasPermission(c, ConsignmentPermissions.canAddSaleLot) ||
        values[name] === c.id,
    )
    .map(consignment => ({
      value: consignment.id,
      label: formatConsignmentLabel(
        consignment,
        agencyByConsignmentIdLookup[consignment.id]?.name || "Loading Agencies",
      ),
      agency_name:
        agencyByConsignmentIdLookup[consignment.id]?.name || "Loading Agencies",
      isKnown: !unknownBusinessIds.includes(consignment.vendor_id),
    }))
    .sort((a, b) => {
      if (a.isKnown !== b.isKnown) {
        return b.isKnown - a.isKnown;
      } else {
        return caseInsensitiveCompare(a.label, b.label);
      }
    });

  // Insert 'create unknown consignment for [agency]
  if (includeCreateUnknownConsignment) {
    const deploymentSaleIdsWithUnknownConsignments = consignments
      .filter(consignment => unknownBusinessIds.includes(consignment.vendor_id))
      .reduce((acc, consignment) => {
        acc.add(consignment.deployment_sale);
        return acc;
      }, new Set());
    Object.values(deploymentSales)
      .filter(
        deploymentSale =>
          !deploymentSaleIdsWithUnknownConsignments.has(
            deploymentSale.deployment_sale_id,
          ),
      )
      .forEach(deploymentSale => {
        consignmentOptions.unshift({
          value: deploymentSale.livestock_agency_id,
          label: `Add Unknown Business Consignment For: ${
            agencyByDeploymentSaleIdLookup[deploymentSale.deployment_sale_id]
              ?.name || "Loading Agencies..."
          }`,
        });
      });
  }

  const setExportSites = value => {
    let exportSites = [];
    if (isConsignmentId(value)) {
      exportSites =
        exportSitesByConsignmentIdLookup[value]?.map(o => o.value) || [];
    } else if (isLivestockAgencyId(value)) {
      const deploymentSaleId = Object.values(deploymentSales).find(
        ds => ds.livestock_agency_id === value,
      ).deployment_sale_id;
      exportSites = exportSitesByDeploymentSaleIdLookup[deploymentSaleId] || [];
    }

    formikProps.setFieldValue("exportSites", exportSites);
  };

  return (
    <>
      {includeCreateConsignmentButton &&
      consignmentOptions.length === 0 &&
      hasAddConsignmentPermission ? (
        <Grid container item xs={12} justifyContent="center">
          <SlimButton onClick={onAddConsignment} type="button" fullWidth>
            Create A New Consignment
          </SlimButton>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <>
            <SelectField
              required
              name={name}
              options={consignmentOptions}
              label={label}
              onChangeExtra={setExportSites}
              filterOption={consignmentOptionFilter}
            />
            {errors[name] && <Error>{errors[name]}</Error>}
          </>
        </Grid>
      )}
    </>
  );
};

export default InlineConsignmentSelect;
