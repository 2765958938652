import React, { memo, useCallback } from "react";

import { faBluetoothB } from "@fortawesome/free-brands-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { disconnectFromDevice } from "actions";

import { IconTextButton } from "components/Button";

import { getConnectedDevice } from "selectors";

export function ScannerActionsInlineComponent() {
  const connectedDevice = useSelector(getConnectedDevice) || {};
  const dispatch = useDispatch();
  const connectedDeviceId = connectedDevice.deviceId;
  const onClickDisconnect = useCallback(() => {
    dispatch(disconnectFromDevice(connectedDeviceId));
  }, [connectedDeviceId, dispatch]);

  if (!connectedDeviceId) {
    return null;
  }

  const connectedDeviceName = connectedDevice.name || "";
  const trimmedConnectedDeviceName =
    connectedDeviceName.length > 13
      ? `${connectedDeviceName.substr(0, 10)}...`
      : connectedDeviceName;

  return (
    <IconTextButton
      icon={faBluetoothB}
      onClick={onClickDisconnect}
      tooltip={`Disconnect from ${connectedDeviceName}`}
    >
      Disconnect ({trimmedConnectedDeviceName})
    </IconTextButton>
  );
}

export default memo(ScannerActionsInlineComponent);
