import React from "react";

import { DataTour } from "components/DataTour";
import { Column } from "components/Layout";

import { Label } from "./Label";

export const LabelledControl = ({
  label,
  name,
  tooltip,
  required,
  children,
}) => {
  const hasLabel = !!label;
  return (
    <Column fullWidth>
      <DataTour dataTour={name}>
        <Label htmlFor={name} required={hasLabel && required} tooltip={tooltip}>
          {label}
        </Label>
        {children}
      </DataTour>
    </Column>
  );
};
