import { intersection } from "lodash/array";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import { createSelector } from "reselect";

import { EID_TRANSFER_STATUS } from "constants/nlis";

import { EMPTY_ARRAY } from "lib";

import { categorisedEID } from "lib/nlisTake";

import {
  getCurrentDeploymentSalesList,
  getCurrentRoundsList,
  selectCurrentDeploymentSaleIdsList,
  selectConsignmentIdsByDeploymentSaleIdLookup,
  selectConsignmentIdsByNvd,
  selectConsignmentIdsByVendorPropertyIdLookup,
  getRounds,
  selectDraftedCountByDeploymentSaleIdLookup,
  selectReceivedCountByDeploymentSaleIdLookup,
  selectRoundDeliveredCountByDeploymentSaleIdLookup,
  selectRoundIsBalancedByDeploymentSaleIdLookup,
  selectRoundNoSaleCountByDeploymentSaleIdLookup,
  selectRoundNotPennedCountByDeploymentSaleIdLookup,
  selectRoundPennedCountByDeploymentSaleIdLookup,
  selectRoundSoldCountByDeploymentSaleIdLookup,
  selectIsBalancedByDeploymentSaleIdLookup,
  selectConsignmentExceptionsByDeploymentSaleIdLookup,
  selectLegacyConsignmentExceptionsByDeploymentSaleIdLookup,
  selectLegacySaleLotExceptionsByDeploymentSaleIdLookup,
  selectSaleLotExceptionsByDeploymentSaleIdLookup,
  selectScansBySaleLotIdLookup,
  selectWarningsForEqxDataByDeploymentSaleId,
} from "selectors";

import { getNestedConsignments, getAuctionPensByRound } from "./composite";

/**
 * @typedef {Object} RoundCount
 * @property {string} round_name
 * @property {number} round_id
 * @property {number} count
 */

/**
 * @typedef {Object} RoundBalance
 * @property {string} round_name
 * @property {number} round_id
 * @property {boolean} balanced
 */

/**
 * @typedef {Object} SaleLotExceptions
 * @property {string} saleLotId
 * @property {Array<string>} exceptions
 */

/**
 * @typedef {Object} ConsignmentExceptions
 * @property {string} consignmentId
 * @property {Array<string>} exceptions
 */

/**
 * @typedef {Object} SaleSummary
 * @property {number} received
 * @property {number} not_drafted
 * @property {number} drafted
 * @property {Array<RoundCount>} penned
 * @property {Array<RoundCount>} not_penned
 * @property {Array<RoundCount>} sold
 * @property {Array<RoundCount>} no_sale
 * @property {Array<RoundCount>} delivered
 * @property {Array<RoundBalance>} sale_round_balance
 * @property {{ vendors: Array<ConsignmentExceptions>, buyers: Array<SaleLotException>}}  exceptions
 * @property {boolean} allConsignmentsHasPICAndNVD
 * @property {boolean} isBalanced
 */

/**
 * Returns the total number of no-sale (return to vendor) head, in their Sale Round Indexes, keyed by Deployment Sale Id
 * Requires:
 *  - `Attachments`
 *  - `Auction Pens`
 *  - `Consignments`
 *  - `Properties`
 *  - `Rounds`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, SaleSummary>}
 */
export const selectDeploymentSaleSummaryByDeploymentSaleIdLookup =
  createSelector(
    [
      selectCurrentDeploymentSaleIdsList,
      getCurrentRoundsList,
      getRounds,
      selectReceivedCountByDeploymentSaleIdLookup,
      selectDraftedCountByDeploymentSaleIdLookup,
      selectRoundPennedCountByDeploymentSaleIdLookup,
      selectRoundNotPennedCountByDeploymentSaleIdLookup,
      selectRoundSoldCountByDeploymentSaleIdLookup,
      selectRoundNoSaleCountByDeploymentSaleIdLookup,
      selectRoundDeliveredCountByDeploymentSaleIdLookup,
      selectRoundIsBalancedByDeploymentSaleIdLookup,
      selectIsBalancedByDeploymentSaleIdLookup,
      selectConsignmentIdsByDeploymentSaleIdLookup,
      selectConsignmentIdsByVendorPropertyIdLookup,
      selectConsignmentIdsByNvd,
      selectConsignmentExceptionsByDeploymentSaleIdLookup,
      selectSaleLotExceptionsByDeploymentSaleIdLookup,
      selectWarningsForEqxDataByDeploymentSaleId,
    ],
    (
      deploymentSaleIds,
      saleRoundIds,
      rounds,
      receivedCountByDeploymentSaleId,
      draftedCountByDeploymentSaleId,
      roundPennedCountByDeploymentSaleId,
      roundNotPennedCountByDeploymentSaleId,
      roundSoldCountByDeploymentSaleId,
      roundNoSaleCountByDeploymentSaleId,
      roundDeliveredCountByDeploymentSaleId,
      roundIsBalancedByDeploymentSaleId,
      isBalancedByDeploymentSaleId,
      consignmentIdsByDeploymentSaleId,
      consignmentIdsByVendorPropertyId,
      consignmentIdsByNvd,
      consignmentExceptionsByDeploymentSaleId,
      saleLotExceptionsByDeploymentSaleId,
      warningsForEqxDataByDeploymentSaleId,
    ) => {
      return deploymentSaleIds.reduce((acc, deploymentSaleId) => {
        function countByRound(roundCount) {
          return saleRoundIds.map((roundId, index) => ({
            round_id: roundId,
            round_name: rounds[roundId]?.name || "",
            count: roundCount[index] || 0,
          }));
        }

        // Get the received head count for the current Deployment Sale
        const receivedCount =
          receivedCountByDeploymentSaleId[deploymentSaleId] || 0;

        // Get the drafted head count for the current Deployment Sale
        const draftedCount =
          draftedCountByDeploymentSaleId[deploymentSaleId] || 0;

        // Calculate the not drafted head count for the current Deployment Sale
        const notDraftedCount = receivedCount - draftedCount;

        // Get the sold head count, per round for the current Deployment Sale
        const sold = countByRound(
          roundSoldCountByDeploymentSaleId[deploymentSaleId],
        );

        // Get the no sale head count, per round for the current Deployment Sale
        const noSale = countByRound(
          roundNoSaleCountByDeploymentSaleId[deploymentSaleId],
        );

        // Get the delivered head count, per round for the current Deployment Sale
        const delivered = countByRound(
          roundDeliveredCountByDeploymentSaleId[deploymentSaleId],
        );

        // Get the penned head count, per round for the current Deployment Sale
        const penned = countByRound(
          roundPennedCountByDeploymentSaleId[deploymentSaleId],
        );

        // Get the not penned head count, per round for the current Deployment Sale
        const notPenned = countByRound(
          roundNotPennedCountByDeploymentSaleId[deploymentSaleId],
        );

        // Get the Sale Lot and Auction Pen balanced status, per round for the current Deployment Sale
        const saleRoundBalance = saleRoundIds.map((roundId, index) => ({
          round_id: roundId,
          round_name: rounds[roundId]?.name || "",
          balanced: roundIsBalancedByDeploymentSaleId[deploymentSaleId][index],
        }));

        // Get the balanced status for the current Deployment Sale
        const isBalanced = isBalancedByDeploymentSaleId[deploymentSaleId];

        // Duplicate of `saleRoundBalance`, attached to each delivered count per round
        saleRoundIds.forEach((_, index) => {
          // 'cause we need MOAR BALANCE
          delivered[index].balanced =
            roundIsBalancedByDeploymentSaleId[deploymentSaleId][index];
        });

        // Get the Consignment Ids in the current Deployment Sale
        const consignmentIds =
          consignmentIdsByDeploymentSaleId[deploymentSaleId] || [];

        // Get the Consignment Ids without a Vendor Property Id in the current Deployment Sale
        const consignmentsWithoutVendorPropertyId = intersection(
          consignmentIds,
          consignmentIdsByVendorPropertyId.null || [],
        );

        // Get the Consignment Ids without an NVD Number in the current Deployment Sale
        const consignmentsWithoutNVD = intersection(
          consignmentIds,
          consignmentIdsByNvd.null || [],
        );

        // Calculate whether there is an issue with any of the Consignments' NVD Numbers or Vendor Property Ids in the current Deployment Sale
        const allConsignmentsHasPICAndNVD =
          consignmentsWithoutVendorPropertyId.length === 0 &&
          consignmentsWithoutNVD.length === 0;

        const warningsForEqxReport =
          warningsForEqxDataByDeploymentSaleId[deploymentSaleId];

        // Get the list of Consignment Exceptions for the current Deployment Sale
        const consignmentExceptions =
          consignmentExceptionsByDeploymentSaleId[deploymentSaleId] ||
          EMPTY_ARRAY;

        // Get the list of Sale Lot Exceptions for the current Deployment Sale
        const saleLotExceptions =
          saleLotExceptionsByDeploymentSaleId[deploymentSaleId] || EMPTY_ARRAY;

        acc[deploymentSaleId] = {
          received: receivedCount,
          not_drafted: notDraftedCount,
          drafted: draftedCount,
          penned,
          not_penned: notPenned,
          sold,
          no_sale: noSale,
          delivered,
          sale_round_balance: saleRoundBalance,
          exceptions: {
            buyers: saleLotExceptions,
            vendors: consignmentExceptions,
          },
          allConsignmentsHasPICAndNVD,
          isBalanced,
          warningsForEqxReport,
        };
        return acc;
      }, {});
    },
  );

export const agentSummarySelector = createSelector(
  [
    getCurrentDeploymentSalesList,
    selectDeploymentSaleSummaryByDeploymentSaleIdLookup,
    selectLegacyConsignmentExceptionsByDeploymentSaleIdLookup,
    selectLegacySaleLotExceptionsByDeploymentSaleIdLookup,
  ],
  (
    deploymentSales,
    saleSummaryByDeploymentSaleId,
    legacyConsignmentExceptionsByDeploymentSaleId,
    legacySaleLotExceptionsByDeploymentSaleId,
  ) =>
    deploymentSales.map(deploymentSale => {
      const {
        deployment_sale_id: deploymentSaleId,
        livestock_agency_name: livestockAgencyName,
      } = deploymentSale;

      const summary = {
        ...saleSummaryByDeploymentSaleId[deploymentSaleId],
        exceptions: {
          vendors:
            legacyConsignmentExceptionsByDeploymentSaleId[deploymentSaleId] ||
            EMPTY_ARRAY,
          buyers:
            legacySaleLotExceptionsByDeploymentSaleId[deploymentSaleId] ||
            EMPTY_ARRAY,
        },
      };

      summary.deploymentSaleId = deploymentSaleId;
      summary.livestock_agency_name = livestockAgencyName;

      return summary;
    }),
);

export const selectSaleSummary = createSelector(
  [selectDeploymentSaleSummaryByDeploymentSaleIdLookup],
  deploymentSaleSummaryByDeploymentSaleId =>
    Object.values(deploymentSaleSummaryByDeploymentSaleId).reduce(
      (acc, deploymentSaleSummary) => {
        function combineSaleSummaryRoundCount(key) {
          return (round, index) =>
            (round.count += deploymentSaleSummary[key][index].count);
        }

        const saleLotExceptions = acc.exceptions.buyers;
        const consignmentExceptions = acc.exceptions.vendors;

        acc.received += deploymentSaleSummary.received;
        acc.not_drafted += deploymentSaleSummary.not_drafted;
        acc.drafted.forEach(combineSaleSummaryRoundCount("drafted"));
        acc.penned.forEach(combineSaleSummaryRoundCount("penned"));
        acc.not_penned.forEach(combineSaleSummaryRoundCount("not_penned"));
        acc.sold.forEach(combineSaleSummaryRoundCount("sold"));
        acc.no_sale.forEach(combineSaleSummaryRoundCount("no_sale"));
        acc.delivered.forEach(combineSaleSummaryRoundCount("delivered"));
        acc.exceptions.buyers = saleLotExceptions.concat(
          deploymentSaleSummary.exceptions.buyers,
        );
        acc.exceptions.vendors = consignmentExceptions.concat(
          deploymentSaleSummary.exceptions.vendors,
        );
        acc.allConsignmentsHasPICAndNVD =
          acc.allConsignmentsHasPICAndNVD &&
          deploymentSaleSummary.allConsignmentsHasPICAndNVD;
        acc.balanced = acc.balanced && deploymentSaleSummary.isBalanced;
        acc.warningsForEqxReport = deploymentSaleSummary.warningsForEqxReport;
        return acc;
      },
      {
        received: 0,
        not_drafted: 0,
        drafted: [],
        penned: [],
        not_penned: [],
        sold: [],
        no_sale: [],
        delivered: [],
        exceptions: {
          buyers: [],
          vendors: [],
        },
        allConsignmentsHasPICAndNVD: true,
        balanced: true,
      },
    ),
);

export const scaleOperatorSummarySelector = createSelector(
  [getNestedConsignments, selectScansBySaleLotIdLookup, getAuctionPensByRound],
  (consignments, scansBySaleLot, auctionPensMap) => {
    const summary = {
      consignments: consignments.length,
      consignments_in_progress: 0,
      booked_in: 0,
      scanned: sumBy(consignments, "scannedCount"),
      taken: 0,
      sold: 0,
      failed: 0,
      pending: 0,
      deceased: 0,
    };

    let consignmentScans = [];
    consignments.forEach(c => {
      if (c.saleLots && c.saleLots.length) {
        c.saleLots.forEach(sl => {
          if (scansBySaleLot[sl.id]) {
            consignmentScans = [...consignmentScans, ...scansBySaleLot[sl.id]];
          }
        });
      }
    });

    // Use the sale lot grouped ones, so we include the UNALLOCATED sale lot id.
    Object.values(scansBySaleLot).forEach(scanList => {
      scanList.forEach(scan => {
        if (scan.animal && scan.animal.marked_deceased) {
          summary.deceased += 1;
        }
      });
    });

    consignments.forEach(c => {
      if (c.takeStatus) {
        const successTake = c.takeStatus.find(
          takeStatus => takeStatus.status === EID_TRANSFER_STATUS.SUCCESS,
        );
        const eidStatusObject = categorisedEID(successTake);
        summary.taken += get(eidStatusObject, "successEID.length") || 0;
        summary.failed += get(eidStatusObject, "errorEID.length") || 0;
      } else {
        summary.consignments_in_progress += 1;
      }
      summary.booked_in += c.quantity === 0 ? c.scannedCount : c.quantity;
    });
    for (const [, auctionPens] of auctionPensMap) {
      auctionPens.forEach(ap => {
        const sellFile = get(ap, "sellFile");
        if (sellFile) {
          if (sellFile.pending) {
            summary.pending += ap.quantity;
          } else {
            const eidStatusObject = categorisedEID(sellFile);
            summary.sold += get(eidStatusObject, "successEID.length") || 0;
          }
        }
      });
    }

    return summary;
  },
);
