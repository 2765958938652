import React from "react";

import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";

import { FontAwesomeButton } from "components/Button";
import { ButtonIcon } from "components/Button/FontAwesomeButton";
import { Warning } from "components/ErrorMessage";
import { FormGrid } from "components/Form";
import {
  DeploymentSelectField,
  OtherMarkingField,
  SaleRoundPickerField,
} from "components/Form/Fields";
import MarkingField from "components/Form/Fields/MarkingField";
import { Input } from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";
import { ReceivalMarkInput } from "components/Form/FormikControls/ReceivalMarks";
import { Row } from "components/Layout";
import { EmbeddedScanningPenCard } from "components/ScanningPenCards/Card/Singular";

import { PenTypes } from "constants/auctionPens";

import { EMPTY_ARRAY } from "lib";

import { openPenScanning } from "lib/navigation";
import { pluralize } from "lib/pluralize";
import { scanLotConfig } from "lib/scanLot";

import {
  getReceivalLots,
  selectNameByDeploymentIdLookup,
  selectPenScanLotIdsBySellingPenIdLookup,
  selectReceivalLotIdsByReceivalPenIdLookup,
} from "selectors";

import { useFieldValue } from "hooks";

export const ReceivalLotScanningForm = ({ penId, scanLotId }) => {
  const mark = useFieldValue("mark");

  const receivalLotLookup = useSelector(getReceivalLots);

  const receivalLotInPenLookup = useSelector(
    selectReceivalLotIdsByReceivalPenIdLookup,
  );

  const receivalLotIdsInPen = receivalLotInPenLookup[penId] || EMPTY_ARRAY;

  const markInPenConflictIds = React.useMemo(
    () =>
      receivalLotIdsInPen.filter(
        rLotId => receivalLotLookup[rLotId].mark === mark,
      ),
    [receivalLotIdsInPen, receivalLotLookup, mark],
  );

  const hasConflict =
    markInPenConflictIds?.filter(rLotId => rLotId !== scanLotId).length > 0;

  return (
    <FormGrid container spacing={2}>
      <Grid item xs={12}>
        <Input
          type="number"
          label="Quantity"
          name="quantity"
          required
          disableAutoComplete
        />
      </Grid>

      <Grid item xs={12}>
        <ReceivalMarkInput name="mark" label="Mark" required />
      </Grid>
      {hasConflict && (
        <Warning className="mt-12">
          {`${mark} has already been added to the mark of another lot in this
            pen, multiple lots with the same marks shouldn't exist in the same 
            receiving pen`}
        </Warning>
      )}
    </FormGrid>
  );
};

export const PenScanLotScanningForm = props => {
  const { receivalScannedDeploymentIds } = props;
  const { touched, errors, values } = useFormikContext();

  const nameByDeploymentIdLookup = useSelector(selectNameByDeploymentIdLookup);

  // Show a warning when the entered scans don't match deployments for the one(s) given.
  let deploymentWarningText = null;

  const { deploymentId: selectedDeploymentId } = values;

  const theme = useTheme();

  if (receivalScannedDeploymentIds) {
    if (receivalScannedDeploymentIds.size > 1) {
      deploymentWarningText = `Scans from ${
        receivalScannedDeploymentIds.size
      } ${pluralize("agency", receivalScannedDeploymentIds.size)} found.`;
    } else if (
      receivalScannedDeploymentIds.size === 1 &&
      selectedDeploymentId &&
      !receivalScannedDeploymentIds.has(selectedDeploymentId)
    ) {
      deploymentWarningText = `Scans entered were received into "${
        nameByDeploymentIdLookup[Array.from(receivalScannedDeploymentIds)[0]]
      }".`;
    }
  }

  return (
    <FormGrid container spacing={2}>
      <Grid item xs={12}>
        <Input
          type="number"
          label="Quantity"
          name="quantity"
          required
          disableAutoComplete
        />
      </Grid>
      <Grid container direction="row" spacing={2} item xs={12}>
        <Grid item xs={6}>
          <DeploymentSelectField
            retrictToSaleDeployments
            label="Agency"
            name="deploymentId"
            warningText={deploymentWarningText}
          />
        </Grid>
        <Grid item xs={6}>
          <SaleRoundPickerField name="saleRoundId" required />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <MarkingField name="marks" label="Marks" required useAllDeployments />
        <OtherMarkingField
          name="marks"
          label="Custom Marks"
          useAllDeployments
          defaultColor={theme.colors.black}
        />
        {errors.marks && touched.marks && <Error>{errors.marks}</Error>}
      </Grid>
    </FormGrid>
  );
};

export function ScanLotScanningDetailHeader(props) {
  const {
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    receivalScannedDeploymentIds,
  } = props;

  const {
    getNextPenByPenIdAndArchetypeId,
    getPreviousPenByPenIdAndArchetypeId,
  } = scanLotConfig(penType, scanLotId);

  const isReceivalScanning = penType === PenTypes.RECEIVING;

  const nextPen = useSelector(
    getNextPenByPenIdAndArchetypeId(penId, penArchetypeId),
  );

  const previousPen = useSelector(
    getPreviousPenByPenIdAndArchetypeId(penId, penArchetypeId),
  );

  const nextScanLotIdPreselection = useSelector(state => {
    if (!nextPen || !nextPen.penId) {
      return null;
    }
    const nextSelector =
      penType === PenTypes.RECEIVING
        ? selectReceivalLotIdsByReceivalPenIdLookup
        : selectPenScanLotIdsBySellingPenIdLookup;
    const penIds = nextSelector(state)[nextPen.penId] || [];
    if (penIds.length === 1) {
      return penIds[0];
    }
    return null;
  });

  const prevScanLotIdPreselection = useSelector(state => {
    if (!previousPen || !previousPen.penId) {
      return null;
    }
    const previousSelector =
      penType === PenTypes.RECEIVING
        ? selectReceivalLotIdsByReceivalPenIdLookup
        : selectPenScanLotIdsBySellingPenIdLookup;
    const penIds = previousSelector(state)[previousPen.penId] || [];
    if (penIds.length === 1) {
      return penIds[0];
    }
    return null;
  });

  if (!penArchetypeId && !penId && !scanLotId) {
    return null;
  }

  const onClickPrevious = () => {
    openPenScanning(
      previousPen.penArchetypeId,
      previousPen.penId,
      prevScanLotIdPreselection,
      null,
      null,
      penType,
    );
  };
  const onClickNext = () => {
    openPenScanning(
      nextPen.penArchetypeId,
      nextPen.penId,
      nextScanLotIdPreselection,
      null,
      null,
      penType,
    );
  };

  return (
    <>
      <Row justifyBetween alignCenter>
        <FontAwesomeButton
          data-tour="previousPen"
          onClick={onClickPrevious}
          disabled={!previousPen}
          className="h-full justify-around items-center"
          minHeight={200}
        >
          <ButtonIcon icon={faAngleLeft} />
        </FontAwesomeButton>

        <EmbeddedScanningPenCard
          penArchetypeId={penArchetypeId}
          penId={penId}
          selectedLotId={scanLotId}
          penType={penType}
        />
        <FontAwesomeButton
          data-tour="nextPen"
          onClick={onClickNext}
          disabled={!nextPen}
          className="h-full justify-around items-center"
          minHeight={200}
        >
          <ButtonIcon icon={faAngleRight} />
        </FontAwesomeButton>
      </Row>
      {isReceivalScanning ? (
        <ReceivalLotScanningForm penId={penId} scanLotId={scanLotId} />
      ) : (
        <PenScanLotScanningForm
          receivalScannedDeploymentIds={receivalScannedDeploymentIds}
        />
      )}
    </>
  );
}

export default React.memo(ScanLotScanningDetailHeader);
