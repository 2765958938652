import { intersection } from "lodash";
import { createSelector } from "reselect";

import { ExportSites } from "constants/exportSites";
import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { getConsignmentCode } from "lib/consignments";
import {
  doesSaleLotHaveOverflowPen,
  getBuyerHashFromSaleLot,
  getSortedMarkDetails,
  isSaleLotCounted,
  isSaleLotDelivered,
  isSaleLotInProgress,
  isSaleLotSold,
} from "lib/saleLot";

import {
  getConsignments,
  getGlobalSearchBySearchField,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScans,
  selectCanHaveProgenyBySaleLotIdLookup,
  selectDeploymentIdsByAgencyIdLookup,
  selectEidsByAuctionPenIdLookup,
  selectEidsByConsignmentIdLookup,
  selectEidsByDeliveryPenIdLookup,
  selectEidsByDeploymentIdLookup,
  selectEidsBySaleLotIdLookup,
  selectExceptionsByConsignmentIdLookup,
  selectExceptionsBySaleLotIdLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectSaleLotHasImageLookup,
  selectSaleLotHasVideoLookup,
  selectScanStatusBySaleLotIdLookup,
  selectVendorIdBySaleLotIdLookup,
} from "selectors";

import { selectIsBalancedBySaleLotIdLookup } from "selectors/balances";
import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";

import {
  checkpointsChangedAfterScanComparator,
  scansByEidFilteredComparator,
} from "./comparators";
import { rawObjectFilterSelectorFactory } from "./lib";

const selectUnfilteredEids = createSelector([getScans], scans =>
  Object.keys(scans),
);

const selectIsAuctionsPlusCompliantFilteredEids = createSelector(
  [
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredEids,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsAuctionsPlusCompliant),
    getSaleLots,
  ],
  (
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredEids,
    eidsBySaleLotIdLookup,
    searchField,
    saleLots,
  ) => {
    return searchField === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isIntegrationCompliantBySaleLotIdLookup[saleLot.id][
                ExportSites.AUCTIONS_PLUS
              ] === searchField[0],
          )
          .map(saleLot => eidsBySaleLotIdLookup[saleLot.id] || [])
          .reduce((acc, eids) => acc.concat(eids), []);
  },
);

const selectIsStockLiveCompliantFilteredEids = createSelector(
  [
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredEids,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsStockLiveCompliant),
    getSaleLots,
  ],
  (
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredEids,
    eidsBySaleLotIdLookup,
    searchField,
    saleLots,
  ) => {
    return searchField === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isIntegrationCompliantBySaleLotIdLookup[saleLot.id][
                ExportSites.STOCK_LIVE
              ] === searchField[0],
          )
          .map(saleLot => eidsBySaleLotIdLookup[saleLot.id] || [])
          .reduce((acc, eids) => acc.concat(eids), []);
  },
);

const selectBuyerFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Buyer),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, buyerIds, unfilteredEids) =>
    buyerIds === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot => buyerIds.includes(saleLot.buyer_id))
          .map(saleLot => eidsBySaleLotIdLookup[saleLot.id] || [])
          .reduce((acc, eids) => acc.concat(eids), []),
);

const selectThirdPartyFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.ThirdParty),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, thirdPartyIds, unfilteredEids) =>
    thirdPartyIds === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot => thirdPartyIds.includes(saleLot.thirdPartyId))
          .map(saleLot => eidsBySaleLotIdLookup[saleLot.id] || [])
          .reduce((acc, eids) => acc.concat(eids), []),
);

const selectBuyerAndBuyerWayFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerAndBuyerWay),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, buyerHashes, unfilteredEids) =>
    buyerHashes === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot =>
            buyerHashes.includes(getBuyerHashFromSaleLot(saleLot)),
          )
          .map(saleLot => eidsBySaleLotIdLookup[saleLot.id] || [])
          .reduce((acc, eids) => acc.concat(eids), []),
);

const selectVendorFilteredEids = createSelector(
  [
    selectVendorIdBySaleLotIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Vendor),
    selectUnfilteredEids,
  ],
  (
    vendorIdBySaleLotIdLookup,
    eidsBySaleLotIdLookup,
    vendorIds,
    unfilteredEids,
  ) =>
    vendorIds === null
      ? unfilteredEids
      : Object.entries(vendorIdBySaleLotIdLookup)
          .filter(([ignored, vendorId]) => vendorIds.includes(vendorId))
          .map(([saleLotId, ignored]) => eidsBySaleLotIdLookup[saleLotId] || [])
          .reduce((acc, eids) => acc.concat(eids), []),
);

const selectSaleRoundFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.SaleRound),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, saleRoundIds, unfilteredEids) =>
    saleRoundIds === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot => saleRoundIds.includes(saleLot.sale_round_id))
          .map(saleLot => eidsBySaleLotIdLookup[saleLot.id] || [])
          .reduce((acc, eids) => acc.concat(eids), []),
);

const selectAgencyFilteredEids = createSelector(
  [
    selectDeploymentIdsByAgencyIdLookup,
    selectEidsByDeploymentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Agency),
    selectUnfilteredEids,
  ],
  (
    deploymentIdsByAgencyIdLookup,
    eidsByDeploymentIdLookup,
    agencyIds,
    unfilteredEids,
  ) =>
    agencyIds === null
      ? unfilteredEids
      : Object.keys(deploymentIdsByAgencyIdLookup)
          .filter(agencyId => agencyIds.includes(parseInt(agencyId, 10)))
          .map(agencyId => deploymentIdsByAgencyIdLookup[agencyId])
          .reduce((acc, deploymentIds) => acc.concat(deploymentIds), [])
          .reduce(
            (acc, deploymentId) =>
              acc.concat(eidsByDeploymentIdLookup[deploymentId] || []),
            [],
          ),
);

const selectHasArrivedFilteredEids = createSelector(
  [
    getConsignments,
    selectEidsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasArrived),
    selectUnfilteredEids,
  ],
  (consignments, eidsByConsignmentIdLookup, hasArrived, unfilteredEids) =>
    hasArrived === null
      ? unfilteredEids
      : Object.values(consignments)
          .filter(consignment => consignment.hasArrived === hasArrived[0])
          .reduce(
            (acc, consignment) =>
              acc.concat(eidsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectHasBuyerExceptionsFilteredEids = createSelector(
  [
    selectExceptionsBySaleLotIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasBuyerExceptions),
    selectUnfilteredEids,
  ],
  (
    exceptionsBySaleLotIdLookup,
    eidsBySaleLotIdLookup,
    hasBuyerExceptions,
    unfilteredEids,
  ) =>
    hasBuyerExceptions === null
      ? unfilteredEids
      : Object.entries(exceptionsBySaleLotIdLookup)
          .filter(
            ([ignored, exceptions]) =>
              (exceptions.length === 0) === hasBuyerExceptions[0],
          )
          .reduce(
            (acc, [saleLotId, ignored]) =>
              acc.concat(eidsBySaleLotIdLookup[saleLotId] || []),
            [],
          ),
);

// Show consignment scans...
const selectHasConsignmentScansFilteredEids = createSelector(
  [
    getSaleyardScanSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasConsignmentScans),
    selectUnfilteredEids,
  ],
  (
    saleyardScanSaleLots,
    eidsBySaleLotIdLookup,
    hasConsignmentScans,
    unfilteredEids,
  ) =>
    hasConsignmentScans === null
      ? unfilteredEids
      : Object.entries(eidsBySaleLotIdLookup)
          .filter(
            ([saleLotId, ignored]) =>
              Boolean(saleyardScanSaleLots[saleLotId]) ===
              hasConsignmentScans[0],
          )
          .reduce((acc, [ignored, eids]) => acc.concat(eids), []),
);

const selectHasOverflowFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasOverflow),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, hasOverflow, unfilteredEids) =>
    hasOverflow === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot => doesSaleLotHaveOverflowPen(saleLot) === hasOverflow[0],
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectHasVendorExceptionsFilteredEids = createSelector(
  [
    getSaleLots,
    selectExceptionsByConsignmentIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasVendorExceptions),
    selectUnfilteredEids,
  ],
  (
    saleLots,
    exceptionsByConsignmentIdLookup,
    eidsBySaleLotIdLookup,
    hasVendorExceptions,
    unfilteredEids,
  ) =>
    hasVendorExceptions === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              (exceptionsByConsignmentIdLookup[saleLot.consignment_id]
                .length !==
                0) ===
              hasVendorExceptions[0],
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectHasWeightFilteredEids = createSelector(
  [
    getSaleLots,
    selectIsWeighedBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredEids,
    selectEidsBySaleLotIdLookup,
  ],
  (
    saleLots,
    isWeighedBySaleLotIdLookup,
    hasWeight,
    selectUnfilteredEids,
    eidsBySaleLotIdLookup,
  ) => {
    return hasWeight === null
      ? selectUnfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot => isWeighedBySaleLotIdLookup[saleLot.id] === hasWeight[0],
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          );
  },
);

const selectIsBuyerBalancedFilteredEids = createSelector(
  [
    selectIsBalancedBySaleLotIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsBuyerBalanced),
    selectUnfilteredEids,
  ],
  (
    isBalancedBySaleLotIdLookup,
    eidsBySaleLotIdLookup,
    isBuyerBalanced,
    unfilteredEids,
  ) =>
    isBuyerBalanced === null
      ? unfilteredEids
      : Object.entries(isBalancedBySaleLotIdLookup)
          .filter(
            ([ignored, isBuyerBalancedValue]) =>
              isBuyerBalancedValue === isBuyerBalanced[0],
          )
          .reduce(
            (acc, [saleLotId, ignored]) =>
              acc.concat(eidsBySaleLotIdLookup[saleLotId] || []),
            [],
          ),
);

const selectIsCountedFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsCounted),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, isCounted, unfilteredEids) =>
    isCounted === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot => isSaleLotCounted(saleLot) === isCounted[0])
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectIsDeliveredFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsDelivered),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, isDelivered, unfilteredEids) =>
    isDelivered === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot => isSaleLotDelivered(saleLot) === isDelivered[0])
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectIsEidCompliantFilteredEids = createSelector(
  [
    selectScanStatusBySaleLotIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsEidCompliant),
    selectUnfilteredEids,
  ],
  (
    scanStatusBySaleLotIdLookup,
    eidsBySaleLotIdLookup,
    isEidCompliant,
    unfilteredEids,
  ) =>
    isEidCompliant === null
      ? unfilteredEids
      : Object.entries(scanStatusBySaleLotIdLookup)
          .filter(
            ([ignored, scanStatus]) =>
              (scanStatus === ScanStatus.PASS) === isEidCompliant[0],
          )
          .reduce(
            (acc, [saleLotId, ignored]) =>
              acc.concat(eidsBySaleLotIdLookup[saleLotId] || []),
            [],
          ),
);

const selectIsInProgressFilteredEids = createSelector(
  [
    getSaleLots,
    getConsignments,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredEids,
  ],
  (
    saleLots,
    consignments,
    eidsBySaleLotIdLookup,
    isInProgress,
    unfilteredEids,
  ) =>
    isInProgress === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isSaleLotInProgress(
                saleLot,
                consignments[saleLot.consignment_id],
              ) === isInProgress[0],
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);
const selectIsSoldFilteredEids = createSelector(
  [
    getSaleLots,
    getConsignments,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSold),
    selectUnfilteredEids,
  ],
  (saleLots, consignments, eidsBySaleLotIdLookup, isSold, unfilteredEids) =>
    isSold === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isSaleLotSold(saleLot, consignments[saleLot.consignment_id]) ===
              isSold[0],
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectIsVendorPostSaleBalancedFilteredEids = createSelector(
  [
    getSaleLots,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPostSaleVendorBalanced),
    selectUnfilteredEids,
  ],
  (
    saleLots,
    isPostSaleBalancedByConsignmentIdLookup,
    eidsBySaleLotIdLookup,
    isVendorBalanced,
    unfilteredEids,
  ) =>
    isVendorBalanced === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isPostSaleBalancedByConsignmentIdLookup[
                saleLot.consignment_id
              ] === isVendorBalanced[0],
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectIsVendorPreSaleBalancedFilteredEids = createSelector(
  [
    getSaleLots,
    selectIsPreSaleBalancedByConsignmentIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPreSaleVendorBalanced),
    selectUnfilteredEids,
  ],
  (
    saleLots,
    isPreSaleBalancedByConsignmentId,
    eidsBySaleLotIdLookup,
    isVendorPresaleBalanced,
    unfilteredEids,
  ) =>
    isVendorPresaleBalanced === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isPreSaleBalancedByConsignmentId[saleLot.consignment_id] ===
              isVendorPresaleBalanced[0],
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectAuctionPenFilteredEids = createSelector(
  [
    selectEidsByAuctionPenIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.AuctionPen),
    selectUnfilteredEids,
  ],
  (eidsByAuctionPenIdLookup, auctionPenIds, unfilteredEids) =>
    auctionPenIds === null
      ? unfilteredEids
      : Object.entries(eidsByAuctionPenIdLookup)
          .filter(([auctionPenId, ignored]) =>
            auctionPenIds.includes(auctionPenId),
          )
          .reduce((acc, [ignored, eids]) => acc.concat(eids), []),
);

const selectDeliveryPenFilteredEids = createSelector(
  [
    selectEidsByDeliveryPenIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.DeliveryPen),
    selectUnfilteredEids,
  ],
  (eidsByDeliveryPenIdLookup, deliveryPenIds, unfilteredEids) =>
    deliveryPenIds === null
      ? unfilteredEids
      : Object.entries(eidsByDeliveryPenIdLookup)
          .filter(([deliveryPenId, ignored]) =>
            deliveryPenIds.includes(deliveryPenId),
          )
          .reduce((acc, [ignored, eids]) => acc.concat(eids), []),
);
const selectOverflowPenFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.OverflowPen),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, overflowPenIds, unfilteredEids) =>
    overflowPenIds === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot => overflowPenIds.includes(saleLot.id))
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectLaneFilteredEids = createSelector(
  [
    selectEidsByAuctionPenIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Lane),
    selectUnfilteredEids,
  ],
  (eidsByAuctionPenIdLookup, lanes, unfilteredEids) =>
    lanes === null
      ? unfilteredEids
      : Object.entries(eidsByAuctionPenIdLookup)
          .filter(([auctionPenId, ignored]) =>
            lanes.some(lane => lane.includes(auctionPenId)),
          )
          .reduce((acc, [ignored, eids]) => acc.concat(eids), []),
);

const selectLabelFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Label),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, labels, unfilteredEids) =>
    labels === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              saleLot.labels?.length > 0 &&
              labels.some(
                labelIds => intersection(saleLot.labels, labelIds).length > 0,
              ),
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectPricingTypeFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.PricingType),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, pricingTypeIds, unfilteredEids) =>
    pricingTypeIds === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot => pricingTypeIds.includes(saleLot.pricing_type_id))
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectHasProgenyFilteredEids = createSelector(
  [
    selectCanHaveProgenyBySaleLotIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasProgeny),
    selectUnfilteredEids,
  ],
  (
    canHaveProgenyBySaleLotIdLookup,
    eidsBySaleLotIdLookup,
    hasProgeny,
    unfilteredEids,
  ) =>
    hasProgeny === null
      ? unfilteredEids
      : Object.entries(canHaveProgenyBySaleLotIdLookup)
          .filter(
            ([ignored, canHaveProgenyValue]) =>
              canHaveProgenyValue === hasProgeny[0],
          )
          .reduce(
            (acc, [saleLotId, ignored]) =>
              acc.concat(eidsBySaleLotIdLookup[saleLotId] || []),
            [],
          ),
);

const selectScanFilteredEids = rawObjectFilterSelectorFactory(
  getScans,
  GlobalSearchFields.Scan,
  selectUnfilteredEids,
  scansByEidFilteredComparator,
  "EID",
);

const selectVendorNumberFilteredEids = createSelector(
  [
    getConsignments,
    selectEidsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorNumber),
    selectUnfilteredEids,
  ],
  (consignments, eidsByConsignmentIdLookup, vendorNumbers, unfilteredEids) =>
    vendorNumbers === null
      ? unfilteredEids
      : Object.values(consignments)
          .filter(consignment =>
            vendorNumbers.includes(getConsignmentCode(consignment)),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(eidsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectVendorPicFilteredEids = createSelector(
  [
    getConsignments,
    selectEidsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorPic),
    selectUnfilteredEids,
  ],
  (consignments, eidsByConsignmentIdLookup, vendorPic, unfilteredEids) =>
    vendorPic === null
      ? unfilteredEids
      : Object.values(consignments)
          .filter(consignment =>
            vendorPic.includes(consignment.vendor_property_id),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(eidsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectBuyerPicFilteredEids = createSelector(
  [
    getSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerPic),
    selectUnfilteredEids,
  ],
  (saleLots, eidsBySaleLotIdLookup, buyerPropertyIds, unfilteredEids) =>
    buyerPropertyIds === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot =>
            buyerPropertyIds.includes(saleLot.destination_property_id),
          )
          .map(saleLot => eidsBySaleLotIdLookup[saleLot.id] || [])
          .reduce((acc, eids) => acc.concat(eids), []),
);

const selectCheckpointFilteredEids = rawObjectFilterSelectorFactory(
  getScans,
  GlobalSearchFields.Checkpoint,
  selectUnfilteredEids,
  checkpointsChangedAfterScanComparator,
  "EID",
);

const selectIsSaleLotImageUploadedEids = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotImageUploaded),
    selectUnfilteredEids,
    selectSaleLotHasImageLookup,
    selectEidsBySaleLotIdLookup,
  ],
  (
    saleLots,
    IsSaleLotImageUploaded,
    unfilteredEids,
    saleLotHasImageLookup,
    eidsBySaleLotIdLookup,
  ) =>
    IsSaleLotImageUploaded === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              saleLotHasImageLookup[saleLot.id] === IsSaleLotImageUploaded[0],
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectIsSaleLotVideoUploadedEids = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotVideoUploaded),
    selectUnfilteredEids,
    selectSaleLotHasVideoLookup,
    selectEidsBySaleLotIdLookup,
  ],
  (
    saleLots,
    isSaleLotVideoUploaded,
    unfilteredEids,
    saleLotHasVideoLookup,
    eidsBySaleLotIdLookup,
  ) =>
    isSaleLotVideoUploaded === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(
            saleLot =>
              saleLotHasVideoLookup[saleLot.id] === isSaleLotVideoUploaded[0],
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

const selectReceivalPenSaleLotIds = createSelector(
  [
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.ReceivalPen),
    selectUnfilteredEids,
    selectEidsByConsignmentIdLookup,
  ],
  (consignments, receivalPens, unfilteredEids, eidsByConsignmentIdLookup) =>
    receivalPens === null
      ? unfilteredEids
      : Object.values(consignments)
          .filter(consignment =>
            receivalPens.includes(consignment.receiving_pen),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(eidsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectMarksFilteredSaleLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredEids,
    selectEidsBySaleLotIdLookup,
  ],
  (saleLots, marks, unfilteredEids, eidsBySaleLotIdLookup) =>
    marks === null
      ? unfilteredEids
      : Object.values(saleLots)
          .filter(saleLot =>
            marks.some(mark => mark === getSortedMarkDetails(saleLot)),
          )
          .reduce(
            (acc, saleLot) =>
              acc.concat(eidsBySaleLotIdLookup[saleLot.id] || []),
            [],
          ),
);

export const selectFilteredEids = createSelector(
  [
    selectAgencyFilteredEids,
    selectAuctionPenFilteredEids,
    selectBuyerFilteredEids,
    selectBuyerAndBuyerWayFilteredEids,
    selectDeliveryPenFilteredEids,
    selectHasArrivedFilteredEids,
    selectHasBuyerExceptionsFilteredEids,
    selectHasConsignmentScansFilteredEids,
    selectHasOverflowFilteredEids,
    selectHasProgenyFilteredEids,
    selectHasVendorExceptionsFilteredEids,
    selectHasWeightFilteredEids,
    selectIsBuyerBalancedFilteredEids,
    selectIsCountedFilteredEids,
    selectIsDeliveredFilteredEids,
    selectIsEidCompliantFilteredEids,
    selectIsInProgressFilteredEids,
    selectIsSoldFilteredEids,
    selectIsVendorPreSaleBalancedFilteredEids,
    selectIsVendorPostSaleBalancedFilteredEids,
    selectLabelFilteredEids,
    selectLaneFilteredEids,
    selectOverflowPenFilteredEids,
    selectPricingTypeFilteredEids,
    selectSaleRoundFilteredEids,
    selectScanFilteredEids,
    selectThirdPartyFilteredEids,
    selectVendorFilteredEids,
    selectVendorNumberFilteredEids,
    selectVendorPicFilteredEids,
    selectBuyerPicFilteredEids,
    selectCheckpointFilteredEids,
    selectIsAuctionsPlusCompliantFilteredEids,
    selectIsStockLiveCompliantFilteredEids,
    selectIsSaleLotImageUploadedEids,
    selectIsSaleLotVideoUploadedEids,
    selectReceivalPenSaleLotIds,
    selectMarksFilteredSaleLotIds,
  ],
  (
    agencyFilteredEids,
    auctionPenFilteredEids,
    buyerFilteredEids,
    buyerAndBuyerWayFilteredEids,
    deliveryPenFilteredEids,
    hasArrivedFilteredEids,
    hasBuyerExceptionsFilteredEids,
    hasConsignmentScansFilteredEids,
    hasOverflowFilteredEids,
    hasProgenyFilteredEids,
    hasVendorExceptionsFilteredEids,
    hasWeightFilteredEids,
    isBuyerBalancedFilteredEids,
    isCountedFilteredEids,
    isDeliveredFilteredEids,
    isEidCompliantFilteredEids,
    isInProgressFilteredSaleLotIds,
    isSoldFilteredEids,
    isVendorPreSaleBalancedFilteredEids,
    isVendorPostSaleBalancedFilteredEids,
    labelFilteredEids,
    laneFilteredEids,
    overflowPenFilteredEids,
    pricingTypeFilteredEids,
    saleRoundFilteredEids,
    scanFilteredEids,
    thirdPartyFilteredEids,
    vendorFilteredEids,
    vendorNumberFilteredEids,
    vendorPicFilteredEids,
    buyerPicFilteredEids,
    checkpointFilteredEids,
    isAuctionsPlusCompliantFilteredEids,
    isStockLiveCompliantFilteredEids,
    isSaleLotImageUploadedEids,
    isSaleLotVideoUploadedEids,
    receivalPenSaleLotIds,
    marksFilteredSaleLotIds,
  ) =>
    intersection(
      agencyFilteredEids,
      auctionPenFilteredEids,
      buyerFilteredEids,
      buyerAndBuyerWayFilteredEids,
      deliveryPenFilteredEids,
      hasArrivedFilteredEids,
      hasBuyerExceptionsFilteredEids,
      hasConsignmentScansFilteredEids,
      hasOverflowFilteredEids,
      hasProgenyFilteredEids,
      hasVendorExceptionsFilteredEids,
      hasWeightFilteredEids,
      isBuyerBalancedFilteredEids,
      isCountedFilteredEids,
      isDeliveredFilteredEids,
      isEidCompliantFilteredEids,
      isInProgressFilteredSaleLotIds,
      isSoldFilteredEids,
      isVendorPreSaleBalancedFilteredEids,
      isVendorPostSaleBalancedFilteredEids,
      labelFilteredEids,
      laneFilteredEids,
      overflowPenFilteredEids,
      pricingTypeFilteredEids,
      saleRoundFilteredEids,
      scanFilteredEids,
      thirdPartyFilteredEids,
      vendorFilteredEids,
      vendorNumberFilteredEids,
      vendorPicFilteredEids,
      buyerPicFilteredEids,
      checkpointFilteredEids,
      isAuctionsPlusCompliantFilteredEids,
      isStockLiveCompliantFilteredEids,
      isSaleLotImageUploadedEids,
      isSaleLotVideoUploadedEids,
      receivalPenSaleLotIds,
      marksFilteredSaleLotIds,
    ),
);
