import { camelCase } from "lodash";
import { createSelector } from "reselect";

import { getDeployments, getCurrentSaleSubType } from "selectors";

/**
 * Returns a Deployment Validation Overrides, keyed by Deployment Id
 * e.g.
 *
 * {
 *   13: {
 *     missingBreed: {
 *       validationName: "Missing Breed",
 *       validationLevel: "Warning",
 *       headThreshold: 1,
 *       excludeSaleSubTypes: [
 *       ],
 *     },
 *     missingSex: {
 *       validationName: "Missing Sex",
 *       validationLevel: "Warning & Block",
 *       headThreshold: 1,
 *       excludeSaleSubTypes: [
 *       ],
 *     },
 *     ...
 *   },
 *   ...
 * }
 * @type {function(state): Object>}
 */
export const selectValidationOverridesByDeploymentIdLookup = createSelector(
  [getDeployments, getCurrentSaleSubType],
  (deployments, currentSaleSubType) =>
    Object.entries(deployments).reduce((acc, [deploymentId, deployment]) => {
      const validationOverrides = deployment.validationOverrides || [];
      acc[deploymentId] = {};
      validationOverrides.forEach(vo => {
        if (!vo.excludeSaleSubTypes.includes(currentSaleSubType?.id)) {
          // e.g. "Missing Breed" => missingBreed
          acc[deploymentId][camelCase(vo.validationName)] = vo;
        }
      });
      return acc;
    }, {}),
);
