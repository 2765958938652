import { createSelector } from "reselect";

import { SaleLotType } from "constants/saleLots";
import { UNALLOCATED } from "constants/scanner";
import { PLCProtocolStatus } from "constants/singleWeigh";

import { EMPTY_ARRAY } from "lib";

import {
  getDeviceTypeCapabilites,
  PLC_DRIVERS,
  SCANNER_DRIVERS,
  WEIGHBRIDGE_DRIVERS,
} from "lib/deviceDrivers";
import { getDraftingInformationHash, getPicFromScan } from "lib/scans";
import { sortByDateInOptionsLabel } from "lib/timeFormats";

import {
  createIdByKeySelector,
  createLookupCombiner,
  currentSaleSelector,
  getAvailableDevices,
  getConnectedDeviceId,
  getConnectedMtHostSessions,
  getDraftingInformation,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScanBuffer,
  getScans,
  getUnassignedScans,
  getWeightHistory,
  selectConsignmentIdByEidLookup,
  selectCurrentDeploymentIds,
  selectEidsByCurrentAuctionPenIdLookup,
  selectEidsBySaleLotIdLookup,
  selectSaleLotIdByEidLookup,
} from "selectors";

import { getUntakenConsignmentsWithVendorProperties } from "selectors/consignments";

export const selectScansBySaleLotIdLookup = createSelector([getScans], scans =>
  Object.values(scans).reduce((acc, scan) => {
    const saleLotId = scan.sale_lot_id;
    let saleLotScans = acc[saleLotId];

    if (!Array.isArray(saleLotScans)) {
      saleLotScans = [];
      acc[saleLotId] = saleLotScans;
    }
    saleLotScans.push(scan);
    return acc;
  }, {}),
);

export const getScansBySaleLotId = saleLotId => state =>
  selectScansBySaleLotIdLookup(state)[saleLotId] || null;

export const getEidsBySaleLotId = saleLotId => state =>
  selectEidsBySaleLotIdLookup(state)[saleLotId] || null;

export const getConnectedDevice = createSelector(
  [getConnectedDeviceId, getAvailableDevices],
  (connectedDeviceId, availableDevices) =>
    connectedDeviceId
      ? availableDevices.find(device => device.deviceId === connectedDeviceId)
      : null,
);

export const getScannerCapabilities = createSelector(
  [getConnectedDevice],
  connectedDevice => {
    if (!connectedDevice) {
      return EMPTY_ARRAY;
    }
    return getDeviceTypeCapabilites(connectedDevice.deviceType) || EMPTY_ARRAY;
  },
);

export const getDeviceLookup = createSelector(
  [getAvailableDevices],
  availableDevices => {
    return availableDevices.reduce((lookup, device) => {
      lookup[device.deviceId] = device;
      return lookup;
    }, {});
  },
);

export const getGroupedUnassignedScans = createSelector(
  [getUnassignedScans],
  scans => {
    const draftGroups = {};
    for (const eid in scans) {
      const scan = scans[eid];
      if (!Array.isArray(draftGroups[scan.draftName])) {
        draftGroups[scan.draftName] = [];
      }
      draftGroups[scan.draftName].push(scan);
    }
    return draftGroups;
  },
);

export const getUnassignedScanSummary = createSelector(
  [getUnassignedScans, getScanBuffer],
  (scans, scanBuffer) => {
    const eids = Object.keys(scans);
    return {
      hasScans: eids.length > 0 || scanBuffer.length > 0,
      processedScanCount: eids.length,
    };
  },
);

export const getUnallocatedScans = createSelector(
  [
    currentSaleSelector,
    getScans,
    getUntakenConsignmentsWithVendorProperties,
    selectCurrentDeploymentIds,
  ],
  (sale, scans, consignments, currentDeploymentIds) => {
    // Pull out all unallocated scans (ie, no sale lot, or sale lot is
    // UNALLOCATED).  If we have an NLIS ID, the first 8 characters are
    // the PIC this tag came from, so attempt to deduce which consignments
    // the scan can be auto allocated to.
    try {
      return Object.values(scans)
        .filter(
          scan =>
            (!scan.deployment_id ||
              currentDeploymentIds.includes(scan.deployment_id)) &&
            scan.sale_lot_id === UNALLOCATED,
        )
        .map(scan => {
          const picFromNLISId = getPicFromScan(scan);
          const allocatableConsignments = picFromNLISId
            ? consignments.filter(
                consignment =>
                  consignment.livestocksale_id === sale.livestocksale_id &&
                  consignment.vendor_property &&
                  consignment.vendor_property.PIC &&
                  picFromNLISId === consignment.vendor_property.PIC,
              )
            : [];
          return {
            ...scan,
            picFromNLISId,
            allocatableConsignments,
          };
        });
    } catch (e) {
      return [];
    }
  },
);

// Build an object key'd on the NLIS id, where available.
export const getScansByNLISId = createSelector(
  [getScans, selectCurrentDeploymentIds],
  (scans, currentDeploymentIds) => {
    try {
      const lookup = {};
      for (const eid in scans) {
        const scan = scans[eid];
        if (
          (!scan.deployment_id ||
            currentDeploymentIds.includes(scan.deployment_id)) &&
          scan.animal &&
          scan.animal.nlis_id
        ) {
          lookup[scan.animal.nlis_id] = scan;
        }
      }
      return lookup;
    } catch (e) {
      return {};
    }
  },
);

export const getMostRecentWeight = state => {
  const weightHistory = getWeightHistory(state);
  return weightHistory[weightHistory.length - 1];
};

export const selectSaleLotTypeByEidLookup = createIdByKeySelector(
  createSelector(
    [getScans, getSaleLots, getSaleyardScanSaleLots],
    (scans, saleLots, saleyardScanSaleLots) =>
      Object.entries(scans).reduce((acc, [eid, scan]) => {
        const saleLotType =
          (saleLots[scan.sale_lot_id] && SaleLotType.AUCTION) ||
          (saleyardScanSaleLots[scan.sale_lot_id] && SaleLotType.SALEYARD_SCAN);
        acc[eid] = { eid, saleLotType };
        return acc;
      }, {}),
  ),
  "eid",
  "saleLotType",
);

export const getScanByEid = eid => state => getScans(state)[eid];

/**
 * Returns either the Sale Lot, or the Saleyard Scan Sale Lot, keyed by EID
 * Requires:
 *  - `Scans`
 *  - `Sale Lots`
 *  - `Saleyard Scan Sale Lots`
 * @type {function(state): Object<string, SaleLot|SaleyardScanSaleLot>}
 */
export const selectSaleLotByEidLookup = createSelector(
  [getSaleLots, getSaleyardScanSaleLots, selectSaleLotIdByEidLookup],
  (auctionSaleLots, saleyardScanSaleLots, saleLotIdByEidLookup) =>
    Object.entries(saleLotIdByEidLookup).reduce((acc, [eid, saleLotId]) => {
      acc[eid] = auctionSaleLots[saleLotId] || saleyardScanSaleLots[saleLotId];
      return acc;
    }, {}),
);

export const getSaleLotIdByEid = eid => state =>
  selectSaleLotIdByEidLookup(state)[eid] || null;

export const getSaleLotByEid = eid => state =>
  selectSaleLotByEidLookup(state)[eid] || null;

export const getConsignmentIdByEid = eid => state =>
  selectConsignmentIdByEidLookup(state)[eid];

export const getSaleLotTypeIdByEid = eid => state =>
  selectSaleLotTypeByEidLookup(state)[eid];

export const selectScanByNlisIdLookup = createSelector([getScans], scans =>
  Object.values(scans).reduce((acc, scan) => {
    if (scan.animal?.nlis_id) {
      acc[scan.animal.nlis_id] = scan;
    }
    return acc;
  }, {}),
);

export const getScanByNlisId = nlisId => state =>
  selectScanByNlisIdLookup(state)[nlisId];

const selectDeviceInformationIdByHashLookup = createSelector(
  [getDraftingInformation],
  draftingInformation =>
    Object.keys(draftingInformation).reduce((lookup, draftingInformationId) => {
      lookup[
        getDraftingInformationHash(
          draftingInformation[draftingInformationId].deviceName,
          draftingInformation[draftingInformationId].deviceId,
          draftingInformation[draftingInformationId].draftName,
        )
      ] = draftingInformationId;
      return lookup;
    }, {}),
);

export const getDraftingInformationIdByHash = hash => state =>
  selectDeviceInformationIdByHashLookup(state)[hash] || null;

export const getDraftingInformationById = draftingInformationId => state =>
  getDraftingInformation(state)[draftingInformationId] || null;

const selectDraftingInformationIdsBySaleLotIdLookup = createSelector(
  [selectScansBySaleLotIdLookup, getSaleLots],
  (scansBySaleLotIdLookup, saleLots) =>
    Object.keys(saleLots).reduce((lookup, saleLotId) => {
      if (!lookup[saleLotId]) {
        lookup[saleLotId] = new Set();
      }
      (scansBySaleLotIdLookup[saleLotId] || []).forEach(scan => {
        lookup[saleLotId].add(scan.drafting_id);
      });
      return lookup;
    }, {}),
);
export const getDraftingInformationIdsBySaleLotId = saleLotId => state =>
  selectDraftingInformationIdsBySaleLotIdLookup(state)[saleLotId] || null;

export const getEidsByCurrentAuctionPenId = auctionPenId => state =>
  selectEidsByCurrentAuctionPenIdLookup(state)[auctionPenId] || EMPTY_ARRAY;

export const selectConnectedPLC = createSelector(
  [getAvailableDevices],
  devices =>
    devices?.find(
      device =>
        device.status !== "disconnected" &&
        PLC_DRIVERS.includes(device.deviceType),
    ),
);

export const selectIsConnectedPLC = createSelector(
  [selectConnectedPLC],
  connectedPLC => !!connectedPLC,
);

export const selectIsPLCReady = createSelector(
  [selectConnectedPLC],
  connectedPLC =>
    connectedPLC?.protocolStatus ===
    PLCProtocolStatus.WAITING_FOR_DRAFTING_DECISION,
);

export const selectConnectedWeighBridge = createSelector(
  [getAvailableDevices],
  devices =>
    devices?.find(
      device =>
        device.status !== "disconnected" &&
        WEIGHBRIDGE_DRIVERS.includes(device.deviceType),
    ),
);

export const selectIsConnectedWeighBridge = createSelector(
  [selectConnectedWeighBridge],
  connectedWeighBridge => !!connectedWeighBridge,
);

export const selectConnectedScanner = createSelector(
  [getAvailableDevices],
  devices =>
    devices?.find(
      device =>
        device.status !== "disconnected" &&
        SCANNER_DRIVERS.includes(device.deviceType),
    ),
);

export const selectIsConnectedScanner = createSelector(
  [selectConnectedScanner],
  connectedScanner => !!connectedScanner,
);

const selectAvailableDeviceOptions = createSelector(
  [getAvailableDevices],
  availableDevices =>
    availableDevices.map(device => ({
      label: device.name,
      value: device.deviceId,
      ...device,
    })),
);

export const selectAvailableScannerOptions = createSelector(
  [selectAvailableDeviceOptions],
  availableAndConnectedOptions =>
    availableAndConnectedOptions.filter(option =>
      SCANNER_DRIVERS.includes(option.deviceType),
    ),
);

export const selectAvailablePlcOptions = createSelector(
  [selectAvailableDeviceOptions],
  availableAndConnectedOptions =>
    availableAndConnectedOptions.filter(option =>
      PLC_DRIVERS.includes(option.deviceType),
    ),
);

export const selectAvailableWeighbridgeOptions = createSelector(
  [selectAvailableDeviceOptions],
  availableAndConnectedOptions =>
    availableAndConnectedOptions.filter(option =>
      WEIGHBRIDGE_DRIVERS.includes(option.deviceType),
    ),
);

export const getMtHostSessionFileNameOptionsByDeviceId = createSelector(
  [getConnectedMtHostSessions],
  createLookupCombiner(connectedMtHostSessions =>
    connectedMtHostSessions
      .map(session => ({
        label: session.fileName,
        value: session.fileName,
      }))
      .sort(sortByDateInOptionsLabel),
  ),
);

export const selectAvailableMtHostSessions = createSelector(
  [getConnectedMtHostSessions, getAvailableDevices],
  (connectedMtHostSessions, availableDevices) => {
    return availableDevices.filter(device =>
      Object.keys(connectedMtHostSessions).includes(device.deviceId),
    );
  },
);
