import React from "react";

import { useSelector } from "react-redux";

import { SelectField, withNamespace } from "components/Form/FormikControls";

export const SelectorSelectField = React.memo(
  ({ label, name, ns, optionsSelector, disabled }) => {
    const options = useSelector(optionsSelector);

    return (
      <SelectField
        label={label}
        name={withNamespace(ns, name)}
        options={options}
        isClearable
        disabled={disabled}
        menuPortalTarget={document.body}
      />
    );
  },
);
