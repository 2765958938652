import Big from "big.js";
import { parse } from "date-fns";
import { intersection } from "lodash/array";
import { readString } from "react-papaparse";
import { put, select, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import { ScanAction, WeighLotAction, WeighLotScanAction } from "actions";

import { WEIGH_LOT } from "constants/actionTypes";
import { EIDPattern, UNALLOCATED } from "constants/scanner";
import { WeighLotScanStatus } from "constants/weighScanning";

import { getLivestockSaleId } from "lib/navigation";
import toast from "lib/toast";

import {
  getScans,
  getWeighLotScans,
  getWeighLots,
  selectScanByWeighLotScanIdLookup,
  selectWeighLotIdsByEidLookup,
  selectWeighLotIdsByLotNumberLookup,
  selectWeighLotIdsByScaleNameLookup,
  selectWeighLotScanIdsByEidLookup,
  selectWeighLotScanIdsByWeighLotIdLookup,
} from "selectors";

function getWeighLotKey(lotNumber, scaleName) {
  return `${lotNumber}_${scaleName}}`;
}

function parseCsv(fileName, scaleName, fileContents, state) {
  const weighLotIdsByScaleNameLookup =
    selectWeighLotIdsByScaleNameLookup(state);
  const weighLotIdsByLotNumberLookup =
    selectWeighLotIdsByLotNumberLookup(state);

  const csvData = readString(fileContents, {
    header: true,
    skipEmptyLines: true,
    transformHeader: header => header.trim().toLowerCase(),
  });
  // iterate through row data
  return csvData.data.reduce(
    (acc, row) => {
      // "05/Jul/2023"
      const date = (row["[date]"] || "").trim();
      // "09:39:10"
      const time = (row["[time]"] || "").trim();

      const weighDateTime = parse(
        `${date} ${time}`,
        `dd/MMM/yyyy HH:mm:ss`,
        new Date(),
      );

      // "      5"
      const lotNumber = (row["[lot number]"] || row["[lot]"] || "").trim();

      // "      951 000317803150" or "      A 00000 0 0951 000317803150  "
      const rowEid = (
        row["[tag number]"] ||
        row["[rfid]"] ||
        row["[tag#]"] ||
        ""
      ).trim();

      const eid =
        EIDPattern.ISO_26_DIGIT_PATTERN.exec(rowEid)?.[1] ||
        EIDPattern.ISO_27_DIGIT_PATTERN.exec(rowEid)?.[1] ||
        rowEid;

      // "    1521.5"
      const totalMassGrams = new Big(
        +(row["[gross weight]"] || row["[total weight]"] || "").trim(),
      )
        .times(1000)
        .toFixed(0);

      // find weigh lot that shares the same lot number, scale name - there should only be one
      const identicalWeighLots = intersection(
        weighLotIdsByScaleNameLookup[scaleName],
        weighLotIdsByLotNumberLookup[lotNumber],
      );

      const identicalWeighLotExists = !!identicalWeighLots.length;

      const identicalWeighLotId = identicalWeighLots[0];

      const identicalWeighLot = identicalWeighLotExists
        ? getWeighLots(state)[identicalWeighLotId]
        : null;

      let weighLotKey = null;

      let weighLot = null;

      // if we haven't found an identical weigh lot - we're safe to create a new one
      if (!identicalWeighLotExists) {
        weighLotKey = getWeighLotKey(lotNumber, scaleName);

        if (!acc.weighLots[weighLotKey]) {
          acc.weighLots[weighLotKey] = {
            fileName,
            id: uuidv4(),
            livestockSaleId: getLivestockSaleId(),
            lotNumber,
            scaleName,
            totalMassGrams,
            weighDateTime,
          };
        }
        weighLot = acc.weighLots[weighLotKey];
      } else {
        weighLot = identicalWeighLot;
        weighLotKey = `${identicalWeighLot.lotNumber}_${scaleName}`;
        acc.weighLots[weighLotKey] = weighLot;
      }

      // assign weigh lot scans to weigh lots
      if (weighLot && weighLotKey) {
        const weighLotScan = {
          eid,
          status: WeighLotScanStatus.UNRESOLVED,
          totalMassGrams: 0,
          weighLotId: weighLot.id,
        };

        if (!acc.weighLotScansByWeighLotKey[weighLotKey]) {
          acc.weighLotScansByWeighLotKey[weighLotKey] = [];
        }
        acc.weighLotScansByWeighLotKey[weighLotKey].push(weighLotScan);
      }

      return acc;
    },
    { weighLots: {}, weighLotScansByWeighLotKey: {} },
  );
}

function apportionWeighLotsScanWeights(
  weighLotsByWeighLotKeyLookup,
  weighLotScansByWeighLotKeyLookup,
) {
  Object.entries(weighLotScansByWeighLotKeyLookup).forEach(
    ([weighLotKey, weighLotScans]) => {
      const associatedWeighLot = weighLotsByWeighLotKeyLookup[weighLotKey];
      const distinctScansCount =
        weighLotScans.filter(
          weighLotScan =>
            // we don't want to divide weights among deduplicated weigh lots - these are not included
            weighLotScan.status !== WeighLotScanStatus.DEDUPLICATED,
        ).length || 1;

      const averageWeightGrams = parseInt(
        new Big(associatedWeighLot.totalMassGrams)
          .div(distinctScansCount)
          .toFixed(0),
        10,
      );

      // parse the used Big totalMassGrams into an integer
      associatedWeighLot.totalMassGrams = parseInt(
        associatedWeighLot.totalMassGrams,
        10,
      );

      weighLotScans
        .filter(
          weighLotScan =>
            // we don't want to assign weights to deduplicated weigh lots
            weighLotScan.status !== WeighLotScanStatus.DEDUPLICATED,
        )
        .forEach(weighLotScan => {
          weighLotScan.totalMassGrams = averageWeightGrams;
        });
    },
  );
}

function indexParsedWeighLots(
  parsedWeighLotsByWeighLotKey,
  weighLotIdsByLotNumberLookup,
  weighLotIdsByScaleNameLookup,
) {
  // checking for existing weigh lots matching parsed weigh lots
  return Object.entries(parsedWeighLotsByWeighLotKey).reduce(
    (acc, [weighLotKey, parsedWeighLot]) => {
      const lotNumberWeighLotIds =
        weighLotIdsByLotNumberLookup[parseInt(parsedWeighLot.lotNumber, 10)] ||
        [];
      const scaleNameWeighLotIds =
        weighLotIdsByScaleNameLookup[parsedWeighLot.scaleName] || [];
      const weighLotIds = intersection(
        // checking for weigh lot ids that exist in
        lotNumberWeighLotIds,
        scaleNameWeighLotIds,
      );

      if (weighLotIds.length === 0) {
        // There is not an existing Weigh Lot Id matching the parsed Weigh Lot from the CSV file
        acc[weighLotKey] = null;
      } else {
        acc[weighLotKey] = weighLotIds[0];
      }

      return acc;
    },
    {},
  );
}

function* onImportWeighLotAction(action) {
  const { fileName, fileContents, scaleName, options } = action;

  const { suppressToast = false } = options || {};

  const state = yield select();

  // Step 1 Parse the file
  const { weighLots, weighLotScansByWeighLotKey } = parseCsv(
    fileName,
    scaleName,
    fileContents,
    state,
  );

  // Step 2 filter out any existing Weigh Lots
  const weighLotIdsByLotNumberLookup =
    selectWeighLotIdsByLotNumberLookup(state);
  const weighLotIdsByScaleNameLookup =
    selectWeighLotIdsByScaleNameLookup(state);

  const weighLotKeyToWeighLotIdMap = indexParsedWeighLots(
    weighLots,
    weighLotIdsByLotNumberLookup,
    weighLotIdsByScaleNameLookup,
  );

  const weighLotScanIdsByWeighLotIdLookup =
    selectWeighLotScanIdsByWeighLotIdLookup(state);

  const weighLotScanIdsByEidLookup = selectWeighLotScanIdsByEidLookup(state);

  const weighLotScanByIdLookup = getWeighLotScans(state);

  const createableWeighLots = [];
  const createableWeighLotScans = [];
  const updatableWeighLotScans = [];

  // Determine all of the Weigh Lot keys which need to be updated
  Object.entries(weighLots).forEach(([weighLotKey, parsedWeighLot]) => {
    let isWeighLotDirty = false;
    let isWeighLotScansDirty = false;
    const existingWeighLotId = weighLotKeyToWeighLotIdMap[weighLotKey];
    if (!existingWeighLotId) {
      isWeighLotDirty = true;
    } else {
      parsedWeighLot.id = existingWeighLotId;
    }
    // by now we know that there is a Weigh Lot in state which matches the current parsed Weigh Lot from the File
    const weighLotScanIds =
      weighLotScanIdsByWeighLotIdLookup[existingWeighLotId] || [];
    const parsedWeighLotScans = weighLotScansByWeighLotKey[weighLotKey] || [];

    // check if the there are any new weigh lot scans coming in
    if (weighLotScanIds.length !== parsedWeighLotScans.length) {
      isWeighLotScansDirty = true;
    }

    const uniqueWeighLotScanEids = [];

    // step 3 determine and set deduplicated statuses for weigh lot scans
    Object.values(weighLotScansByWeighLotKey[weighLotKey]).forEach(
      weighLotScan => {
        if (!uniqueWeighLotScanEids.includes(weighLotScan.eid)) {
          uniqueWeighLotScanEids.push(weighLotScan.eid);
        } else {
          weighLotScan.status = WeighLotScanStatus.DEDUPLICATED;
        }
      },
    );

    // Step 4 Apportion the average weight of the Weigh Lot to the Weigh Lot Scans
    apportionWeighLotsScanWeights(weighLots, weighLotScansByWeighLotKey);

    if (isWeighLotDirty) {
      createableWeighLots.push(parsedWeighLot);
    }

    if (isWeighLotScansDirty) {
      // don't create weigh lot scans we have already created
      weighLotScansByWeighLotKey[weighLotKey].forEach(weighLotScan => {
        if (
          // we know this based on if weigh lots with this eid exist in this weigh lot
          !selectWeighLotIdsByEidLookup(state)[weighLotScan.eid]?.includes(
            weighLotScan.weighLotId,
          )
        ) {
          createableWeighLotScans.push({ id: uuidv4(), ...weighLotScan });
        } else if (weighLotScan.status !== WeighLotScanStatus.DEDUPLICATED) {
          // handle new weigh lot scans coming into an exisitng weigh lot
          // find the ID for the weigh lot scan and update with the new average weight
          // we're doing this by comparing non deduplicated lots with the same EID in the same weigh lot
          const weighLotScanId = intersection(
            weighLotScanIds,
            weighLotScanIdsByEidLookup[weighLotScan.eid],
          ).find(
            id =>
              weighLotScanByIdLookup[id].status !==
              WeighLotScanStatus.DEDUPLICATED,
          );

          updatableWeighLotScans.push({
            id: weighLotScanId,
            totalMassGrams: weighLotScan.totalMassGrams,
            weighLotId: weighLotScan.weighLotId,
            eid: weighLotScan.eid,
          });
        }
      });
    }
  });

  if (
    !suppressToast &&
    !createableWeighLotScans.length &&
    !createableWeighLots.length &&
    !updatableWeighLotScans.length
  ) {
    toast.info(
      `There are no new Weigh Lots or Weigh Lot Scans in this file. Please import an updated file.`,
    );
  }

  if (createableWeighLots.length) {
    // create the weigh lots
    yield put(WeighLotAction.bulkUpdateOrCreate(createableWeighLots));
  }

  if (createableWeighLotScans.length) {
    // create the weigh lot scans
    yield put(WeighLotScanAction.bulkUpdateOrCreate(createableWeighLotScans));
  }

  if (updatableWeighLotScans.length) {
    // update the weigh lot scans
    yield put(WeighLotScanAction.bulkUpdateOrCreate(updatableWeighLotScans));
  }

  yield put(
    WeighLotAction.process([
      ...createableWeighLotScans,
      ...updatableWeighLotScans,
    ]),
  );
}

/**
 * Once we have imported the weigh lots and weigh lot scans
 * we then need to handle the mapping, which we have called "process".
 *
 * This takes in weigh lot scans and links the weigh lot scans to
 * associated Scans.
 *
 * We then update the linked Scans weight from the
 * Weigh Lot Scan, remove the manually weighed flag from the Scan
 * and set recalculate_sale_lot_weight so that we can trigger
 * calculations in the serialiser on the backend.
 *
 * Finally we update the status of these weigh lot scans as mapped.
 *
 * @param {array} weighLotScans
 */
function* onProcessWeighLotAction(action) {
  const { weighLotScans } = action;

  const state = yield select();

  const selectScanByEidLookup = getScans(state);

  const mappableScans = [];

  weighLotScans.forEach(weighLotScan => {
    // build a list of eids to check for duplicates in - we don't want to map reweighs
    const otherWeighLotScanEids = Object.values(getWeighLotScans(state))
      .filter(
        wls =>
          wls.id !== weighLotScan.id &&
          wls.status !== WeighLotScanStatus.DEDUPLICATED,
      )
      .map(weighLotScan => weighLotScan.eid);

    const weighLotScanIsDeDuplicated =
      weighLotScan.status === WeighLotScanStatus.DEDUPLICATED;

    const weighLotScanExistsInSale = otherWeighLotScanEids.includes(
      weighLotScan.eid,
    );

    // deduplicated scans need to be ignored and duplicate eids (reweighs) should be a manual check by the user
    if (!weighLotScanIsDeDuplicated && !weighLotScanExistsInSale) {
      const associatedScan = selectScanByEidLookup[weighLotScan.eid];
      if (
        associatedScan &&
        weighLotScan.status !== WeighLotScanStatus.DEDUPLICATED
      ) {
        mappableScans.push({
          EID: associatedScan.EID,
          id: associatedScan.id,
          livestock_sale_id: associatedScan.livestock_sale_id,
          weigh_lot_scan_id: weighLotScan.id,
          total_mass_grams: weighLotScan.totalMassGrams,
          recalculate_salelot_weight: true,
          is_manually_weighed: false,
          sale_lot_id:
            associatedScan.sale_lot_id !== UNALLOCATED
              ? associatedScan.sale_lot_id
              : null,
        });
      }
    }
  });

  // link weigh lot scans to scans in the sale
  if (mappableScans.length) {
    yield put(
      // this is ackchyually performing an Bulk Create or update
      ScanAction.create(
        mappableScans.map(mappableScan => {
          return {
            ...mappableScan,
          };
        }),
      ),
    );
  }

  if (!weighLotScans.length) {
    toast.info("No new Weigh Lot Scans");
  } else {
    yield put(WeighLotAction.updateMappedWeighLotScanStatus(weighLotScans));
  }
}

function* onUpdateMappedWeighLotScanStatuses(action) {
  const { weighLotScans } = action;

  const state = yield select();

  const scanByWeighLotScanIdLookup = selectScanByWeighLotScanIdLookup(state);

  const mappedWeighLotScans = [];

  weighLotScans.forEach(weighLotScan => {
    const associatedScan = scanByWeighLotScanIdLookup[weighLotScan.id];

    const weighLotScanIsAssociatedToAScan = !!associatedScan;

    // a weigh lot scan is mapped when the weigh lot scan is linked to a scan
    if (weighLotScanIsAssociatedToAScan) {
      mappedWeighLotScans.push(weighLotScan);
    }
  });

  if (mappedWeighLotScans.length) {
    // set the weigh lot scans as mapped
    yield put(
      WeighLotScanAction.bulkUpdateOrCreate(
        mappedWeighLotScans.map(weighLotScan => ({
          ...weighLotScan,
          status: WeighLotScanStatus.MAPPED,
        })),
      ),
    );
  }
}

/**
 * Selecting keep on a Weigh Lot will look for all non deduplicated weigh lot scans in
 * that Weigh Lot that have a Scan sharing the same EID.
 *
 * The user is essentially deciding they would like to use the Weigh Lot Scans in this
 * Weigh Lot - Ignoring any other references to these Weigh Lot Scans outside of this
 * Weigh Lot
 *
 * We then will run the Weigh Lot Scan keep machinery for each of these Weigh Lot Scans
 * @param {String} weighLotId
 */
function* onHandleKeep(action) {
  const { weighLotId } = action;

  const state = yield select();

  const weighLotScanByIdLookup = getWeighLotScans(state);

  const scanByEidLookup = getScans(state);

  const weighLotScanIdsThatArentDeDuplicatedAndExistInTheSale =
    selectWeighLotScanIdsByWeighLotIdLookup(state)[weighLotId].filter(
      wlsId =>
        weighLotScanByIdLookup[wlsId].status !==
          WeighLotScanStatus.DEDUPLICATED &&
        !!scanByEidLookup[weighLotScanByIdLookup[wlsId].eid],
    );

  // TODO - use bulk update machinery
  for (const weighLotScanId of weighLotScanIdsThatArentDeDuplicatedAndExistInTheSale) {
    yield put(WeighLotScanAction.keep(weighLotScanId));
  }
}

/**
 * Selecting ignore on a Weigh Lot will look for all non deduplicated weigh lot scans in
 * that Weigh Lot and run the ignore machinery on each Weigh Lot Scan.
 *
 * The user is essentially deciding they would like to ignore all of  the Weigh Lot Scans in this
 * Weigh Lot - which will remove references these Weigh Lot Scans have to Scans and mark as ignored
 * @param {String} weighLotId
 */
function* onHandleIgnore(action) {
  const { weighLotId } = action;

  const state = yield select();

  const weighLotScanByIdLookup = getWeighLotScans(state);

  const weighLotScanIdsThatArentDeDuplicatedAndExistInTheSale =
    selectWeighLotScanIdsByWeighLotIdLookup(state)[weighLotId].filter(
      wlsId =>
        weighLotScanByIdLookup[wlsId].status !==
        WeighLotScanStatus.DEDUPLICATED,
    );

  // TODO - use bulk update machinery
  for (const weighLotScanId of weighLotScanIdsThatArentDeDuplicatedAndExistInTheSale) {
    yield put(WeighLotScanAction.ignore(weighLotScanId));
  }
}

function* onHandleDeleteWithScans(action) {
  const { weighLotId } = action;

  const state = yield select();

  const weighLotScanIdsByWeighLotIdLookup =
    selectWeighLotScanIdsByWeighLotIdLookup(state);

  const weighLotScanIds = weighLotScanIdsByWeighLotIdLookup[weighLotId] || [];

  for (const weighLotScanId of weighLotScanIds) {
    yield put(WeighLotScanAction.delete(weighLotScanId));
  }

  yield put(WeighLotAction.delete(weighLotId));
}

export default function* weighLotSagas() {
  yield takeEvery(WEIGH_LOT.IMPORT.ACTION, onImportWeighLotAction);
  yield takeEvery(WEIGH_LOT.PROCESS.ACTION, onProcessWeighLotAction);
  yield takeEvery(
    WEIGH_LOT.UPDATE_MAPPED_WEIGH_LOT_SCAN_STATUS.ACTION,
    onUpdateMappedWeighLotScanStatuses,
  );
  yield takeEvery(WEIGH_LOT.KEEP.ACTION, onHandleKeep);
  yield takeEvery(WEIGH_LOT.IGNORE.ACTION, onHandleIgnore);
  yield takeEvery(WEIGH_LOT.DELETE_WITH_SCANS.ACTION, onHandleDeleteWithScans);
}
