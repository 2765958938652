import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Button from "components/Button";
import { Row, Column } from "components/Layout";

import { SplitType } from "constants/saleLots";

import {
  getSoldSaleLotQuantity,
  getAuctionPenDisplayName,
} from "lib/auctionPens";
import { openSplitSaleLotModal } from "lib/navigation";

import AddNoteModal from "./AddNoteModal";
import AuctionPenSummaryHeader from "./AuctionPenSummaryHeader";
import SaleLotSummary from "./SaleLotSummary";

const SaleLotsActionButton = styled(Button)`
  margin: 0 0 0 12px;
  padding: 12px;
  font-size: ${({ theme }) => theme.fontSizes.delta}px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85px;
`;

const Wrapper = styled(Column)`
  border: 1px solid ${({ theme }) => theme.colors.gray78};
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 2rem;
  color: #4a4a4a;
  height: 172px;
`;

const NoneSelected = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;

class AuctionPenSummary extends React.Component {
  state = {
    isAddNoteModal: false,
    noteText: "",
  };

  setAddNoteModal = (isOpen = false) =>
    this.setState({ isAddNoteModal: isOpen });

  setNote = noteText => this.setState({ noteText, isAddNoteModal: false });

  handleSplit = saleLotId => {
    const { toggleView, showSaleLots } = this.props;
    const currentHash = window.location.hash;
    openSplitSaleLotModal(saleLotId, SplitType.STANDARD, false, currentHash);
    if (!showSaleLots) {
      toggleView();
    }
  };

  render() {
    const { isAddNoteModal, noteText } = this.state;
    const {
      auctionPen,
      saleLots,
      selectedSaleLotIds,
      showSaleLots,
      totalSaleLotQuantity,
      toggleView,
      enableSplit,
      enableSelectSaleLot,
    } = this.props;

    const { overflowPen, overflowQuantity } =
      saleLots.find(lot => lot.overflowPen) || {};

    if (isAddNoteModal) {
      return (
        <AddNoteModal
          closeSelf={() => this.setAddNoteModal(false)}
          setNote={this.setNote}
          initialValue={noteText}
        />
      );
    }
    return (
      <Wrapper>
        <AuctionPenSummaryHeader
          penName={getAuctionPenDisplayName(auctionPen)}
          totalCount={totalSaleLotQuantity}
          soldCount={getSoldSaleLotQuantity(saleLots)}
          overflowPen={overflowPen}
          overflowCount={overflowQuantity}
          saleLots={saleLots}
          auctionPenId={auctionPen.id}
        />
        <Row alignCenter margin={2} full>
          {selectedSaleLotIds.length === 0 ? (
            <NoneSelected>Select at least one sale lot to action</NoneSelected>
          ) : (
            <>
              <SaleLotSummary saleLotIds={selectedSaleLotIds} />
              {enableSplit ? (
                <SaleLotsActionButton
                  data-tour="lotsButton"
                  onClick={() => this.handleSplit(selectedSaleLotIds[0])}
                >
                  <span>Split</span>
                  <span>Sale&nbsp;Lot</span>
                </SaleLotsActionButton>
              ) : null}

              {enableSelectSaleLot ? (
                <SaleLotsActionButton
                  data-tour="lotsButton"
                  onClick={toggleView}
                >
                  <span>{showSaleLots ? "Hide" : "Show"}</span>
                  <span>Sale&nbsp;Lots</span>
                </SaleLotsActionButton>
              ) : null}
            </>
          )}
        </Row>
      </Wrapper>
    );
  }
}

AuctionPenSummary.propTypes = {
  totalSaleLotQuantity: PropTypes.number,
  auctionPen: PropTypes.object,
  selectedSaleLotIds: PropTypes.array,
};

export default AuctionPenSummary;
