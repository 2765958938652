import React from "react";

import { useSelector } from "react-redux";

import { LabelChip } from "components/Chip";
import { ClickableCommentIcon } from "components/Comments/Icon";
import { Row } from "components/Layout";
import MarkingInfo from "components/MarkingInfo";

import { CommentTypes } from "constants/comments";

import { getSaleLotById } from "selectors";

function WeighBridgeCurrentLotSummaryComponent({ saleLotId }) {
  const saleLot = useSelector(getSaleLotById(saleLotId));

  if (!saleLot) {
    return null;
  }

  const { marks = [], labels = [] } = saleLot;
  return (
    <Row justifyEnd alignCenter>
      <Row justifyEnd>
        {labels.map(labelId => (
          <LabelChip labelId={labelId} key={labelId} />
        ))}
      </Row>
      <Row justifyEnd>
        {marks.map(mark => (
          <MarkingInfo
            key={`${mark.color}-${mark.location}`}
            color={mark.color}
            location={mark.location}
          />
        ))}
      </Row>

      <ClickableCommentIcon
        commentType={CommentTypes.SALE_LOT}
        commentTypeId={saleLotId}
      />
    </Row>
  );
}

const WeighBridgeCurrentLotSummary = React.memo(
  WeighBridgeCurrentLotSummaryComponent,
);
export default WeighBridgeCurrentLotSummary;
