import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { RadioGroup, Radio } from "components/RadioGroup";

const ToggleGroup = styled.span`
  display: inline-block;
  min-width: fit-content;
  border: 1px solid
    ${({ theme, error, disabled }) =>
      error
        ? theme.colors.errorRed
        : disabled
          ? theme.colors.grayAE
          : theme.colors.primary};

  ${({ width }) => (width ? `width: ${width};` : "")}

  & > div {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    user-select: none;
    flex-wrap: wrap;
  }

  & > div:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }

  border-radius: 2px;
`;

const ToggleOption = styled(Radio)(
  ({ theme, error, disabled }) => `
  outline: 1px solid ${
    error
      ? theme.colors.errorRed
      : disabled
        ? theme.colors.grayAE
        : theme.colors.primary
  };
  background: ${disabled ? theme.colors.grayF7 : theme.colors.white};
  color: ${disabled ? theme.colors.grayAE : theme.colors.primary};
  transition: ${theme.transitions[1]};
  flex: 1;
  margin-top: -1px;
  margin-left: -1px;
  text-align: center;

  cursor: ${disabled ? "disabled" : "pointer"};
  display: inline-block;
  padding: ${theme.space[1]}px ${theme.space[2]}px;
  height: 36px;
  font-size: ${theme.fontSizes.gamma}px;

  &[aria-checked="true"] {
    background: ${disabled ? theme.colors.grayAE : theme.colors.primary};
    color: ${theme.colors.white};
  }

  &:hover {
    background: ${!disabled && theme.colors.primaryHighlight};
    color: ${!disabled && theme.colors.white};
  }
  
  &:active {
    background: ${disabled ? theme.colors.grayAE : theme.colors.primaryActive};
    color: ${theme.colors.white};
  }

  &:focus {
    background: ${theme.colors.primaryHighlight};
    color: ${theme.colors.white};
  }
`,
);

export function OptionToggler(props) {
  const {
    error,
    options = [],
    onChange,
    name,
    dataTour,
    value,
    width,
    allowToggleOff,
    disabled,
    optionIcons = [],
    forceTabStop = false,
    skipTab = false,
  } = props;
  return (
    <ToggleGroup data-tour={dataTour} width={width} disabled={disabled}>
      <RadioGroup
        value={value}
        labelledBy={name}
        onChange={onChange}
        disabled={disabled}
        forceTabStop={forceTabStop}
      >
        {options.map(({ label, value }, i) => (
          <ToggleOption
            data-tour={label}
            key={value}
            value={value}
            error={error ? 1 : 0}
            allowToggleOff={allowToggleOff}
            disabled={disabled}
            forceTabStop={forceTabStop}
            skipTab={skipTab}
          >
            {optionIcons.length > i && optionIcons[i]} {label}
          </ToggleOption>
        ))}
      </RadioGroup>
    </ToggleGroup>
  );
}

OptionToggler.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  width: PropTypes.string,
};
