import React from "react";

import { Column, Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import MessageBox from "components/MessageBox";
import { MediumText } from "components/Text";

function OpenedInNewWindow({ isLoading = false, message = "" }) {
  return (
    <MessageBox>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Column alignCenter>
          <Row>{message}</Row>
          <Row>
            <MediumText>
              If your file has not downloaded please check your pop up blockers
            </MediumText>
          </Row>
        </Column>
      )}
    </MessageBox>
  );
}

export default OpenedInNewWindow;
