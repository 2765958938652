/* eslint no-plusplus: 0 */ // --> OFF

const getLetterValue = (aequals, letter) => {
  const origValue = letter.charCodeAt(0);
  let outValue;
  if (origValue < 58) {
    outValue = origValue - 48;
  } else {
    outValue = aequals + origValue - 65;
  }
  return outValue;
};

const getWACharValue = letter => {
  const origValue = letter.charCodeAt(0);
  if (origValue < 58) {
    return origValue - 48;
  } else {
    return (origValue - 55) % 11;
  }
};

const validateVicPIC = PIC => {
  const pattern = /3[A-Z]{4}\d{3}/;
  if (!pattern.test(PIC)) {
    return false;
  }

  let i = 7;
  let sum = 0;
  [...PIC].forEach(character => {
    sum += 2 ** i * getLetterValue(10, character);
    i--;
  });
  return sum % 23 === 0;
};

const validateNSWPIC = PIC => {
  const pattern = /N[A-K]\d{6}/;
  if (!pattern.test(PIC)) {
    return false;
  }

  let i = 6;
  let sum = 12 * 2 ** 7;
  [...PIC.substring(1)].forEach(character => {
    sum += 2 ** i * getLetterValue(10, character);
    i--;
  });
  return sum % 11 === 0;
};

const validateQLDPIC = PIC => {
  const pattern = /Q[A-K][A-Z]{2}\d{4}/;

  if (!pattern.test(PIC)) {
    return false;
  }

  let i = 7;
  let sum = 0;
  [...PIC].forEach(character => {
    sum += 2 ** i * getLetterValue(10, character);
    i--;
  });
  return sum % 11 === 0;
};

const validateSAPIC = PIC => {
  const pattern = /S[A-K]\d{6}/;

  if (!pattern.test(PIC)) {
    return false;
  }

  let i = 6;
  let sum = 6 * 2 ** 7;
  [...PIC.substring(1)].forEach(character => {
    sum += 2 ** i * getLetterValue(65, character);
    i--;
  });
  return sum % 11 === 0;
};

const validateTASPIC = PIC => {
  const pattern = /M[A-K][A-Z]{2}\d{4}/;

  if (!pattern.test(PIC)) {
    return false;
  }

  let i = 7;
  let sum = 0;
  [...PIC].forEach(character => {
    sum += 2 ** i * getLetterValue(10, character);
    i--;
  });
  return sum % 11 === 0;
};

const validateWAPIC = PIC => {
  const pattern = /W[A-K][A-Z]{2}\d{4}/;
  if (!pattern.test(PIC)) {
    return false;
  }

  let i = 6;
  let sum = 87 * 2 ** 7;
  [...PIC.substring(1)].forEach(character => {
    sum += 2 ** i * getWACharValue(character);
    i--;
  });
  return sum % 11 === 0;
};

const validateNTPIC = PIC => {
  const pattern = /^T[A-K][ADBV][SGTR][0-9]{4}$/;
  if (!pattern.test(PIC)) {
    return false;
  }
  let value = 0;
  switch (PIC.substring(1, 2)) {
    case "A":
      value = 90;
      break;
    case "B":
      value = 0;
      break;
    case "C":
      value = 9;
      break;
    case "D":
      value = 18;
      break;
    case "E":
      value = 27;
      break;
    case "F":
      value = 36;
      break;
    case "G":
      value = 45;
      break;
    case "H":
      value = 54;
      break;
    case "I":
      value = 63;
      break;
    case "J":
      value = 72;
      break;
    case "K":
      value = 81;
      break;
    default:
      return false;
  }

  switch (PIC.substring(2, 4)) {
    case "AS":
      value += 179;
      break;
    case "DG":
      value += 94;
      break;
    case "BT":
      value += 84;
      break;
    case "VR":
      value += 164;
      break;
    default:
      return false;
  }

  for (let i = 4; i < 8; i++) {
    let sint = 0;
    sint = parseInt(PIC.substring(i, i + 1), 10);
    if (!Number.isNaN(sint)) {
      value += sint * 2 ** (7 - i);
    } else {
      return false;
    }
  }
  return value % 11 === 0;
};

export const validatePIC = PIC => {
  if (typeof PIC !== "string" || PIC.length !== 8) {
    return false;
  } else {
    const inital = PIC.substring(0, 1);

    if (inital === "3") {
      // VIC
      return validateVicPIC(PIC);
    } else if (inital === "N") {
      // NSW
      return validateNSWPIC(PIC);
    } else if (inital === "Q") {
      // QLD
      return validateQLDPIC(PIC);
    } else if (inital === "S") {
      // SA
      return validateSAPIC(PIC);
    } else if (inital === "M") {
      // TAS
      return validateTASPIC(PIC);
    } else if (inital === "W") {
      // WA
      return validateWAPIC(PIC);
    } else if (inital === "T") {
      // NT
      return validateNTPIC(PIC);
    } else if (inital === "E") {
      // test export or EU stuff
      if (PIC === "EEEEEEEE") {
        return true;
      }
      const pattern = /EU(AB|SY)\d{4}/;
      if (pattern.test(PIC)) {
        return true;
      }
    } else if (PIC === "AAAAAAAA") {
      return true;
    } else if (PIC === "DECEASED") {
      return true;
    } else if (PIC === "AGRINOUS") {
      return true;
    } else if (PIC === "OUTCROSS") {
      return true;
    }
    return false;
  }
};
