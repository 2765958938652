import { isString } from "lodash";
import * as Yup from "yup";

const validObject = Yup.lazy(values =>
  Yup.object()
    .shape({
      carrier: Yup.mixed().test(
        "carrier-or-undefined",
        "Please select a carrier",
        value => isString(value) || (!value && !values.deliveryDate),
      ),
      invoiceNumber: Yup.string().nullable(),
      invoiceAmount: Yup.number().nullable(),
      notes: Yup.string().nullable(),
    })
    .nullable(),
);

export const ValidationSchema = validObject;
