import reduceReducers from "reduce-reducers";

import { LEDGER_ENTRY } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  resetStateOnRoleChangeReducer,
} from "lib/reducers";
import { deserializeLedgerEntry } from "lib/serializers/ledgerEntries";

const create = apiModelOfflineCreateReducer(LEDGER_ENTRY, {
  deserializer: deserializeLedgerEntry,
});

const fetch = apiModelOfflineFetchReducer(LEDGER_ENTRY, {
  deserializer: deserializeLedgerEntry,
  clearOnRequest: false,
});

const update = apiModelOfflineUpdateReducer(LEDGER_ENTRY, {
  deserializer: deserializeLedgerEntry,
});

const deleteReducer = apiModelOfflineDeleteReducer(LEDGER_ENTRY);

function deduplicateReducer(state, action) {
  switch (action.type) {
    case LEDGER_ENTRY.DEDUPLICATE.REQUEST: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            isFetching: true,
          },
        },
      };
    }

    case LEDGER_ENTRY.DEDUPLICATE.SUCCESS: {
      const lastModifiedTimestamp =
        action.meta?.lastModifiedTimestamp || state.lastModifiedTimestamp;
      const newState =
        action.payload.length > 0
          ? action.payload.map(deserializeLedgerEntry).reduce(
              (map, obj) => {
                if (obj.deleted === true) {
                  delete map[obj.id];
                } else {
                  map[obj.id] = obj;
                }
                return map;
              },
              { ...state.byId },
            )
          : state.byId;

      return {
        ...state,
        byId: newState,
        isFetching: false,
        lastModifiedTimestamp,
      };
    }

    default:
      return state;
  }
}

const ledgerEntryReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
  deduplicateReducer,
  resetStateOnRoleChangeReducer,
);

export default ledgerEntryReducer;
