import React, { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import styled from "styled-components/macro";

import { requestLogout } from "actions";

import SlimButton from "components/Button/SlimButton";
import { Column, Row } from "components/Layout";

import { openClearHistoryCache } from "lib/navigation";
import { isSentryActive } from "lib/sentry/config";

import { getIsAuthenticated } from "selectors";

import Logo from "img/AgriNous-logo";
import { checkForSWUpdates } from "registerServiceWorker";

const Wrapper = styled.div`
  text-align: center;
  padding: 12px;
  line-height: 1.5rem;
`;

const Fallback = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(requestLogout());
  };

  const checkForUpdates = () => {
    checkForSWUpdates();
  };

  useEffect(() => {
    checkForUpdates();
  }, []);

  return (
    <Row justifyCenter>
      <Column alignCenter style={{ maxWidth: "400px", marginTop: 120 }}>
        <div style={{ fontSize: 24, textAlign: "center" }}>
          <div
            style={{
              position: "relative",
              zIndex: -1,
              transform: "rotate(90deg) scale(2)",
            }}
          >
            <Logo dark />
          </div>
          apologies...
          <br />
          😢 something went wrong
        </div>
        <Wrapper>
          <SlimButton onClick={() => Sentry.showReportDialog()}>
            Report feedback
          </SlimButton>
        </Wrapper>
        <Wrapper>
          <SlimButton as="a" href="/">
            Back to the application
          </SlimButton>
        </Wrapper>
        <Wrapper>
          <SlimButton as="a" onClick={logout}>
            Logout
          </SlimButton>
        </Wrapper>
        <Wrapper>
          <SlimButton onClick={openClearHistoryCache}>
            Instructions on Clearing Application Data
          </SlimButton>
        </Wrapper>
      </Column>
    </Row>
  );
};

const PrivateRoute = ({ component: ChildComponent, ...rest }) => {
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
        if (isSentryActive) {
          return (
            <Sentry.ErrorBoundary fallback={() => <Fallback />}>
              <ChildComponent {...props} />
            </Sentry.ErrorBoundary>
          );
        }
        return <ChildComponent {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
