import React from "react";

import { useTheme } from "hooks";

export const Arrived = ({ color }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.inactiveStep;

  return (
    <svg
      width="15"
      height="128"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 467.4 467.4"
    >
      <g fill={svgColor}>
        <g id="_1680840125728">
          <g>
            <path
              className="fil0"
              d="M84.66 296.06c-15.17,-2.51 -26.42,3.47 -35.8,10 -1.27,0.88 -1.86,1.81 -3.05,2.91 -7.48,6.91 -12.29,16.77 -13.65,26.99 -3.64,27.36 16.18,49.46 39.71,52.6 27.99,3.74 49.11,-15.83 52.82,-39.58 2.29,-14.68 -3.04,-27.29 -9.97,-36.21 -7.26,-9.35 -17.85,-14.7 -30.06,-16.71zm87.35 -210.95l-175.32 0.03 0 -35.04 185.87 0c5.87,0 10.15,1.27 14.27,4.09 13.64,9.36 10.2,25.79 10.19,44.18l-0.01 223.87c24.14,6.84 48.73,13.22 73.03,19.64 24.57,6.49 48.55,13.06 73.09,19.61 24.29,6.49 48.64,12.75 73.11,19.6 41.89,11.73 40.74,5.4 34.62,28.04 -5.19,19.2 -2.5,16.56 -27.16,10.22 -6.1,-1.57 -12.06,-3.3 -18.17,-4.9l-219.03 -58.66c-12.03,-3.31 -24.62,-7.01 -36.65,-9.79 -1.22,24.69 -12.89,44.18 -25.28,55.6 -7.72,7.1 -14.03,11.57 -24.16,16.01 -9.88,4.33 -21.47,6.52 -33.81,6.4 -66.2,-0.62 -105.59,-79.44 -60.83,-134.38 0.71,-0.87 1.47,-1.56 2.22,-2.46 0.74,-0.87 1.22,-1.44 2.02,-2.27 2.89,-3.01 6.45,-5.64 9.69,-8.25 23.93,-19.32 63.17,-21.08 88.72,-5.61 7.38,4.47 11.91,7.85 17.65,13.54 5.27,5.22 9.62,11.3 13.41,17.78 2.12,3.62 1.76,4.86 3.6,4.85l18.89 -0.01 0 -115.26c0.23,-31.61 -0.45,-65.36 0.05,-96.56l-0.01 -10.27z"
            />
          </g>
          <path
            className="fil0"
            d="M357.48 212.6c-1.72,-2.42 -2,-3.34 -3.33,-5.14 -1.81,-2.43 -1.55,-2.5 -2.48,-3.94l-2.67 -3.83c-0.32,-0.52 -0.42,-0.91 -0.75,-1.42 -0.29,-0.45 -0.53,-0.74 -0.86,-1.18 -1.23,-1.68 -2.1,-3.44 -3.33,-5.13 -2.83,-3.9 -7.08,-11.18 -10.09,-15.51 -0.99,-1.42 -2.45,-3.23 -2.87,-5.41 -0.48,-2.57 0.07,-4.86 1.64,-6.96 1.2,-1.59 8.13,-5.58 10.16,-6.82 1.75,-1.06 2.88,-2.14 5.84,-2.19 5.72,-0.09 8.19,5.45 9.71,7.99 4.22,7.07 8.59,13.92 12.91,20.95l35.37 57.4c1,1.68 1.74,3.77 1.05,6.54 -0.68,2.69 -2.14,4.01 -4.09,5.32 -1.71,1.15 -3.44,2.12 -5.2,3.22l-52.17 32.19c-6.97,4.3 -13.78,8.67 -20.81,12.86 -2.79,1.66 -6.85,5.44 -12.35,2.57 -2.25,-1.18 -3.3,-3.12 -4.21,-4.59 -1.61,-2.61 -5.34,-7.92 -5.87,-10.75 -0.44,-2.39 0.38,-5.22 1.88,-6.94 1.52,-1.74 3.29,-2.3 5.02,-3.51l13.37 -7.73c0.95,-0.51 1.66,-1.07 2.6,-1.61l13.26 -7.63c0.92,-0.49 1.66,-1.11 2.58,-1.63 0.96,-0.55 1.74,-0.92 2.7,-1.45 0.93,-0.51 1.65,-1.12 2.58,-1.63 0.96,-0.53 1.75,-0.93 2.7,-1.46 0.93,-0.51 1.66,-1.12 2.58,-1.64 0.96,-0.53 1.75,-0.92 2.7,-1.45 0.85,-0.47 1.74,-1.12 2.52,-1.61 -1.66,-0.64 -58.66,-13.98 -60.22,-14.35 -10.02,-2.38 -20.05,-4.73 -30.07,-7.1 -4.76,-1.13 -9.95,-1.55 -12.69,-4.95 -3.02,-3.73 -1.56,-7.09 -0.27,-12.67 0.46,-2.05 1.1,-5.58 1.98,-7.32 3.68,-7.24 11.65,-3.84 17.67,-2.41l75.15 17.78c4.81,1.14 10.25,2.7 15.04,3.54l-6.68 -10.4z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Arrived;
