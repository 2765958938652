import { v4 as uuidv4 } from "uuid";

import { COMMENT } from "constants/actionTypes";

import { getLivestockSaleId, getSaleyardName } from "lib/navigation";
import { serializeComment } from "lib/serializers/comments";

export const requestOfflineSubActionCreator =
  (action, serializer, urlCreator, livestockSaleSensitive) =>
  (options = {}) => {
    const livestockSaleId = livestockSaleSensitive
      ? getLivestockSaleId()
      : null;
    const saleyardName = getSaleyardName() || null;
    const paramsWithOptions = {
      livestockSaleSensitive,
      livestockSaleId,
      saleyardName,
      ...options,
    };
    return {
      type: action.FETCH_BULK.REQUEST,
      meta: {
        skipQueue: true,
        livestockSaleSensitive,
        livestockSaleId,
        saleyardName,
        offline: {
          effect: {
            url: urlCreator(paramsWithOptions),
            method: "GET",
          },
          commit: {
            type: action.FETCH_BULK.SUCCESS,
            meta: {
              livestockSaleSensitive,
              livestockSaleId,
              saleyardName,
              options,
            },
          },
          rollback: {
            type: action.FETCH_BULK.FAILURE,
          },
        },
      },
    };
  };

export const requestOneOfflineSubActionCreator =
  (action, serializer, urlCreator, livestockSaleSensitive) =>
  (options = {}) => {
    const livestockSaleId = livestockSaleSensitive
      ? getLivestockSaleId()
      : null;
    const saleyardName = getSaleyardName() || null;
    const paramsWithOptions = {
      livestockSaleSensitive,
      livestockSaleId,
      saleyardName,
      ...options,
    };
    return {
      type: action.FETCH.REQUEST,
      meta: {
        skipQueue: true,
        livestockSaleSensitive,
        livestockSaleId,
        saleyardName,
        offline: {
          effect: {
            url: urlCreator(paramsWithOptions),
            method: "GET",
          },
          commit: {
            type: action.FETCH.SUCCESS,
            meta: {
              livestockSaleSensitive,
              livestockSaleId,
              saleyardName,
              options,
            },
          },
          rollback: {
            type: action.FETCH.FAILURE,
          },
        },
      },
    };
  };

/**
 * FETCH_SOME sits somewhere in the middle ground between:
 *   - FETCH
 *     - GET one via single endpoint
 *     - doesn't set isFetching
 *     - response adds to state byId (doesn't clear state)
 *   - BULK_FETCH
 *     - GET ALL via list endpoint
 *     - sets isFetching
 *     - response replaces state byId
 *
 *   where FETCH_SOME:
 *     - GET subset (less than ALL) via list endpoint with filtering
 *     - doesn't set isFetching (intended for background uses)
 *     - response adds to state byId (doesn't clear state)
 *
 * It is intended to be used as a background fetch (as in doesn't interrupt UI)
 *   to enhance a set of data already retrieved.
 *   i.e. in cases where we may start with a small section of data and progressively fetch more.
 *
 * It's use should be quite limited, i.e. only when the normal use of BULK_FETCH doesn't fit.
 * */
export const requestSomeOfflineSubActionCreator =
  (action, serializer, urlCreator, livestockSaleSensitive) =>
  (options = {}) => {
    const livestockSaleId = livestockSaleSensitive
      ? getLivestockSaleId()
      : null;
    const paramsWithOptions = {
      livestockSaleId,
      saleyardName: getSaleyardName(),
      ...options,
    };
    return {
      type: action.FETCH_SOME.REQUEST,
      meta: {
        livestockSaleSensitive,
        livestockSaleId,
        ...options,
        offline: {
          effect: {
            url: urlCreator(paramsWithOptions),
            method: "GET",
          },
          commit: {
            type: action.FETCH_SOME.SUCCESS,
            meta: {
              livestockSaleSensitive,
              livestockSaleId,
              ...options,
            },
          },
          rollback: {
            type: action.FETCH_SOME.FAILURE,
            meta: options,
          },
        },
      },
    };
  };

export const requestChangesOfflineSubActionCreator =
  (action, serializer, urlCreator, livestockSaleSensitive) =>
  (options = {}) => {
    const livestockSaleId = livestockSaleSensitive
      ? getLivestockSaleId()
      : null;
    const paramsWithOptions = {
      livestockSaleId,
      saleyardName: getSaleyardName(),
      ...options,
    };
    return {
      type: action.FETCH_CHANGES.REQUEST,
      meta: {
        livestockSaleSensitive,
        livestockSaleId,
        offline: {
          effect: {
            url: urlCreator(paramsWithOptions),
            method: "GET",
          },
          commit: {
            type: action.FETCH_CHANGES.SUCCESS,
            meta: {
              livestockSaleSensitive,
              livestockSaleId,
              ...options,
            },
          },
          rollback: {
            type: action.FETCH_CHANGES.FAILURE,
            meta: options,
          },
        },
      },
    };
  };

export const createOfflineSubActionCreator =
  (action, serializer, urlCreator) =>
  (
    deserializedPayload,
    options = {
      suppressToast: false,
      suppressErrorToast: false,
      suppressSuccessToast: false,
      changeReason: null,
      saleyardName: null,
    },
  ) => {
    const payload = serializer(deserializedPayload);
    const livestockSaleId = getLivestockSaleId();
    const saleyardName = options?.saleyardName
      ? options.saleyardName
      : getSaleyardName();
    const paramsWithOptions = {
      ...options,
      livestockSaleId,
      saleyardName,
    };
    return {
      type: action.CREATE.REQUEST,
      payload,
      meta: {
        offline: {
          effect: {
            options,
            // if we're creating a consignment in another sale (ie, consigned from) through this machinery (which we're not) this won't work... but it'll do for now.
            // In future we'll need to pass a livestocksaleId through to paramsWithOptions (in line with other actions) to use the intended livestockSaleId
            url: urlCreator(paramsWithOptions),
            method: "POST",
            body: JSON.stringify(serializer(payload)),
            changeReason: options.changeReason,
          },
          commit: {
            options,
            type: action.CREATE.SUCCESS,
            tempId: payload.id,
          },
          rollback: {
            options,
            type: action.CREATE.FAILURE,
            tempId: payload.id,
          },
        },
      },
    };
  };

export const createBulkOfflineSubActionCreator =
  (action, serializer, urlCreator) =>
  (
    deserializedPayload,
    options = {
      suppressToast: false,
      suppressErrorToast: false,
      suppressSuccessToast: false,
      changeReason: null,
    },
  ) => {
    const payload = deserializedPayload.map(deserializedPayload =>
      serializer(deserializedPayload),
    );

    const tempIds = payload.map(obj => obj.id);

    return {
      type: action.CREATE_BULK.REQUEST,
      payload,
      meta: {
        offline: {
          effect: {
            options,
            url: urlCreator({
              saleyardName: getSaleyardName(),
              livestockSaleId: getLivestockSaleId(),
            }),
            method: "POST",
            body: JSON.stringify(payload),
            changeReason: options.changeReason,
          },
          commit: {
            options,
            type: action.CREATE_BULK.SUCCESS,
            tempIds,
          },
          rollback: {
            options,
            type: action.CREATE_BULK.FAILURE,
            tempIds,
          },
        },
      },
    };
  };

export const updateOfflineSubActionCreator =
  (action, serializer, urlCreator) =>
  (
    deserializedPayload,
    options = {
      changeReason: null,
      suppressToast: false,
      suppressErrorToast: false,
      suppressSuccessToast: false,
    },
  ) => {
    const payload = serializer(deserializedPayload);
    const REDUX_OFFLINE_TIMESTAMP = Date.now();
    const livestockSaleId = getLivestockSaleId();
    const saleyardName = getSaleyardName() || null;
    const paramsWithOptions = {
      id: payload.id,
      saleyardName,
      livestockSaleId,
      ...options,
    };
    return {
      type: action.UPDATE.REQUEST,
      payload,
      REDUX_OFFLINE_TIMESTAMP,
      meta: {
        offline: {
          effect: {
            url: urlCreator(paramsWithOptions),
            method: "PATCH",
            body: JSON.stringify(payload),
            options,
            changeReason: options.changeReason,
          },
          commit: {
            options,
            type: action.UPDATE.SUCCESS,
            REDUX_OFFLINE_TIMESTAMP,
          },
          rollback: { options, type: action.UPDATE.FAILURE, id: payload.id },
        },
      },
    };
  };

export const deleteOfflineSubActionCreator =
  (action, serializer, urlCreator) =>
  (
    id,
    options = {
      suppressToast: false,
      suppressErrorToast: false,
      suppressSuccessToast: false,
    },
  ) => ({
    type: action.DELETE.REQUEST,
    id,
    meta: {
      offline: {
        effect: {
          url: urlCreator({
            id,
            saleyardName: getSaleyardName(),
            livestockSaleId: getLivestockSaleId(),
          }),
          method: "DELETE",
          options,
        },
        commit: {
          options,
          type: action.DELETE.SUCCESS,
          id,
        },
        rollback: {
          options,
          type: action.DELETE.FAILURE,
          id,
        },
      },
    },
  });

export const commentOfflineSubActionCreator =
  (action, serializer, urlCreator) => (id, deserializedPayload) => {
    const payload = serializeComment(deserializedPayload, { id: uuidv4() });
    const options = {
      id,
      saleyardName: getSaleyardName(),
      livestockSaleId: getLivestockSaleId(),
      action: "comment",
    };

    return {
      type: action.COMMENT.REQUEST,
      payload,
      id,
      meta: {
        offline: {
          effect: {
            url: urlCreator(options),
            method: "POST",
            body: JSON.stringify(payload),
          },
          commit: {
            type: COMMENT.CREATE.SUCCESS,
            tempId: payload.id,
          },
          rollback: {
            type: COMMENT.CREATE.FAILURE,
            tempId: payload.id,
          },
        },
      },
    };
  };

export const bulkUpdateOrCreateOfflineSubActionCreator =
  (action, serializer, urlCreator) => deserializedPayload => {
    const payload = deserializedPayload.map(payload => serializer(payload));
    return {
      type: action.BULK_UPDATE_OR_CREATE.REQUEST,
      payload,
      meta: {
        offline: {
          effect: {
            url: urlCreator(),
            method: "PUT",
            body: JSON.stringify(payload),
          },
          commit: {
            type: action.BULK_UPDATE_OR_CREATE.SUCCESS,
          },
          rollback: {
            type: action.BULK_UPDATE_OR_CREATE.FAILURE,
          },
        },
      },
    };
  };

export const actionOfflineSubAction = (type, nextFunction, props) => {
  return {
    type: type.ACTION,
    nextFunction,
    props,
  };
};

/**
 * Standard redux offline action creator.
 * @param {object} action
 * @param {function} serializer
 * @param {function} urlCreator
 * @param {boolean} livestockSaleSensitive Is the data from this url relevant to a single sale only.
 * * Used in redux-offline machinery (effect.js & discard.js) to drop data received for a different sale.
 */
export const offlineActionCreator = (
  action,
  serializer,
  urlCreator,
  livestockSaleSensitive = true,
) => ({
  request: requestOfflineSubActionCreator(
    action,
    serializer,
    urlCreator,
    livestockSaleSensitive,
  ),
  requestSome: requestSomeOfflineSubActionCreator(
    action,
    serializer,
    urlCreator,
    livestockSaleSensitive,
  ),
  requestOne: requestOneOfflineSubActionCreator(
    action,
    serializer,
    urlCreator,
    livestockSaleSensitive,
  ),
  requestChanges: requestChangesOfflineSubActionCreator(
    action,
    serializer,
    urlCreator,
    livestockSaleSensitive,
  ),
  create: createOfflineSubActionCreator(action, serializer, urlCreator),
  createBulk: createBulkOfflineSubActionCreator(action, serializer, urlCreator),
  update: updateOfflineSubActionCreator(action, serializer, urlCreator),

  delete: deleteOfflineSubActionCreator(action, serializer, urlCreator),

  comment: commentOfflineSubActionCreator(action, serializer, urlCreator),
  bulkUpdateOrCreate: bulkUpdateOrCreateOfflineSubActionCreator(
    action,
    serializer,
    urlCreator,
  ),
  action: actionOfflineSubAction,
});

export const currentSaleFilter = action => {
  const { livestockSaleSensitive, livestockSaleId } = action.meta || {};

  const currentSaleId = getLivestockSaleId();

  if (livestockSaleSensitive) {
    if (livestockSaleId === undefined) {
      // No sale is open and the request is sale sensitive.
      return false;
    }

    if (livestockSaleId !== currentSaleId) {
      return false;
    }
  }

  return true;
};

// checks for an id passed in and adjusts URL
export const urlCheckAndAddIdAndAction = (url, id, action) => {
  if (id && action) {
    return `${url}${id}/${action}/`;
  } else if (id) {
    return `${url}${id}/`;
  } else if (action) {
    return `${url}${action}/`;
  } else {
    return url;
  }
};

export const reportFavorouriteActionCreator =
  (actionType, method, roleUrl) => (userRoleId, slug) => {
    const urlCreator = ({ id, action } = {}) =>
      urlCheckAndAddIdAndAction(`/v2/${roleUrl}/`, id, action);

    const payload = {
      id: userRoleId,
      slug,
    };

    return {
      type: actionType.REQUEST,
      payload,
      meta: {
        slug,
        offline: {
          effect: {
            url: urlCreator({
              id: userRoleId,
              action: "report_favourites",
            }),
            method,
            body: JSON.stringify(payload),
          },
          commit: {
            type: actionType.SUCCESS,
            body: JSON.stringify(payload),
          },
          rollback: {
            type: actionType.FAILURE,
          },
        },
      },
    };
  };

export const offlineSubActionCreator =
  (
    subAction,
    urlCreator,
    method = "POST",
    serializer = passthroughSerializer,
  ) =>
  deserializedPayload => {
    const url = urlCreator(deserializedPayload);

    const payload = serializer(deserializedPayload);

    const effect = {
      url,
      method,
    };
    if (method !== "GET") {
      effect.body = JSON.stringify(payload);
    }

    return {
      type: subAction.REQUEST,
      payload,
      meta: {
        offline: {
          effect,
          commit: {
            type: subAction.SUCCESS,
          },
          rollback: {
            type: subAction.FAILURE,
          },
        },
      },
    };
  };

export function passthroughSerializer(data) {
  return data;
}
