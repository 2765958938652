import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

export const SmallFaIcon = styled(FontAwesomeIcon)`
  ${({ theme, isHidden, color }) => `
    font-size: ${theme.fontSizes.gamma}px;
    color: ${
      color && theme.colors[color] ? theme.colors[color] : theme.colors.white
    };
    ${isHidden && "opacity: 0; pointer-events: none;"}
  `};
`;
export const LargeFaIcon = styled(FontAwesomeIcon)`
  ${({ theme, color }) => `
    color: ${
      color && theme.colors[color] ? theme.colors[color] : theme.colors.white
    };
    font-size: ${theme.fontSizes.zeta}px;
  `};
`;
