import { put, select, takeEvery } from "redux-saga/effects";

import { EmailAction } from "actions/email";

import {
  BILLING_RUN,
  EMAIL,
  SEND_EMAIL_COMMIT,
  SEND_EMAIL_ROLLBACK,
} from "constants/actionTypes";

import { pluralize } from "lib/pluralize";
import toast from "lib/toast";

import { getEmailFilters } from "selectors";

const getRecipientCountText = action => {
  const { businessUserIds, emailRecipients, rawEmailAddress } = action.meta;
  const rawEmailCount = rawEmailAddress !== "" ? 1 : 0;
  const totalRecipients =
    businessUserIds.length + emailRecipients.length + rawEmailCount;
  return pluralize("recipient", totalRecipients);
};
function onSendEmailCommit(action) {
  toast.success(
    `Email has been sent successfully to ${getRecipientCountText(action)}`,
  );
}

function onSendEmailRollback(action) {
  toast.error(
    `Email has failed to be sent to ${getRecipientCountText(action)}`,
  );
}

function* onSubscribeBillingRun(action) {
  // Get emails associated with this billing run/documents.
  const { id } = action;

  const filters = {
    billingRunId: id,
  };

  yield put(EmailAction.request(filters));
}

function* onFetchChangesAction(action) {
  const state = yield select();
  const filters = getEmailFilters(state) || null;
  if (filters) {
    yield put(
      EmailAction.requestChangesInternal({ ...filters, ...action.options }),
    );
  }
}

export default function* emailSaga() {
  yield takeEvery(SEND_EMAIL_COMMIT, onSendEmailCommit);
  yield takeEvery(SEND_EMAIL_ROLLBACK, onSendEmailRollback);
  yield takeEvery(BILLING_RUN.SUBSCRIBE.ACTION, onSubscribeBillingRun);
  yield takeEvery(EMAIL.FETCH_CHANGES.ACTION, onFetchChangesAction);
}
