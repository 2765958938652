import React from "react";

import { useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";

import { Column } from "components/Layout";
import { Paper } from "components/Paper";

import { SaleyardCredentials } from "containers/Settings/NLIS/Saleyard";

const Title = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.zeta}px;
  text-align: center;
`;
const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.delta}px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
`;
const Content = styled.p`
  color: ${({ theme }) => theme.colors.gray40};
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
`;

export const ScaleOperatorCredentials = () => {
  const match = useRouteMatch();

  return (
    <>
      {match.path === "/app/" && (
        <Column padding={3}>
          <Paper>
            <Title>Welcome to AgriNous.</Title>
            <SubTitle>
              Each day you operate your scales you should consider a
              &apos;sale&apos;.
            </SubTitle>
            <Content>
              Before you get started creating sales, enter your NLIS details
              below.
            </Content>
          </Paper>
        </Column>
      )}
      <SaleyardCredentials />
    </>
  );
};
