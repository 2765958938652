import React from "react";

import PropTypes from "prop-types";

import { formatPercentage } from "lib";

import { getScanStatusColour } from "lib/scans";

import { Percentage, ScanCount, ScanCountWrapper } from "./Elements";

const ScannedCount = ({
  count,
  percentage,
  status,
  saleLotScansCount,
  prefix = null,
}) => (
  <ScanCountWrapper
    color={
      typeof saleLotScansCount === "number" && saleLotScansCount !== count
        ? "warning"
        : undefined
    }
  >
    {prefix}
    <ScanCount>{count}</ScanCount>
    <Percentage color={getScanStatusColour(status)}>
      {percentage ? formatPercentage(percentage) : "-"}
    </Percentage>
  </ScanCountWrapper>
);

ScannedCount.propTypes = {
  count: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  saleLotScansCount: PropTypes.number,
  status: PropTypes.number.isRequired,
};

export default ScannedCount;
