import { isEmpty } from "lodash";

import { ColumnType } from "components/AgGrid/constants";

import { Column, LivestockSaleColId } from "constants/columns";

import { filterComparators, formatters, getters } from "lib/agGrid";
import { SaleRenderer } from "lib/agGrid/renderers/saleRenderer";

/* Note - this file contains two sets of column configuration.
 * One set is for `livestockSales` - multiple records
 * One for `livestockSale` - one record.
 * */

export const LivestockSalesColId = {
  SALE_CODES: "424c3860-6f05-44c4-9b81-4197f86dd621",
  SALE_DATES: "67cbd411-28e8-4748-833c-4f899f36ae7e",
  SALE_TITLES: "b24ae996-616c-4019-bf83-571b223301d2",
  SALE_TYPES: "e6dc8634-731f-419e-a2ec-071e47b810e6",
};

export const LivestockSalesColumnDef = {
  [LivestockSalesColId.SALE_TITLES]: {
    colId: LivestockSalesColId.SALE_TITLES,
    headerName: "Name(s)",
    field: "sale_title",
    namespace: "livestockSales",
    valueGetter: getters.keyInObjArrayGetter,
  },
  [LivestockSalesColId.SALE_CODES]: {
    colId: LivestockSalesColId.SALE_CODES,
    headerName: "Code(s)",
    field: "sale_code",
    namespace: "livestockSales",
    valueGetter: getters.keyInObjArrayGetter,
    cellRenderer: SaleRenderer,
  },
  [LivestockSalesColId.SALE_DATES]: {
    colId: LivestockSalesColId.SALE_DATES,
    headerName: "Date(s)",
    field: "date",
    namespace: "livestockSales",
    valueGetter: params => {
      const values = getters.keyInObjArrayGetter(params);
      if (!values) {
        return null;
      }
      return values.map(dateStr =>
        !isEmpty(dateStr) ? new Date(dateStr) : null,
      );
    },
    filter: "agDateColumnFilter",
    filterParams: { comparator: filterComparators.localDateComparator },
    valueFormatter: ({ value }) =>
      value?.map(v => formatters.localDateFormatter({ value: v })),
  },
  [LivestockSalesColId.SALE_TYPES]: {
    colId: LivestockSalesColId.SALE_TYPES,
    headerName: "Type(s)",
    field: "sale_type",
    namespace: "livestockSales",
    valueGetter: getters.keyInObjArrayGetter,

    enableRowGroup: true,
  },
};

export const LivestockSaleColumnDefinitions = {
  [LivestockSaleColId.SALE_TYPE]: {
    colId: Column.LivestockSaleColId.SALE_TYPE,
    headerName: "Sale Type",
    type: ColumnType.STRING,
    field: "livestockSale.sale_type",
  },
  [LivestockSaleColId.SALE_SUB_TYPE]: {
    colId: Column.LivestockSaleColId.SALE_SUB_TYPE,
    headerName: "Sale Sub Type",
    type: ColumnType.STRING,
    field: "livestockSale.saleSubType.name",
  },
  [LivestockSaleColId.DATE]: {
    colId: Column.LivestockSaleColId.DATE,
    headerName: "Date",
    type: ColumnType.STRING,
    field: "livestockSale.date",
  },
  [LivestockSaleColId.TITLE]: {
    colId: Column.LivestockSaleColId.TITLE,
    headerName: "Title",
    type: ColumnType.STRING,
    field: "livestockSale.sale_title",
  },
  [LivestockSaleColId.SPECIES_NAME]: {
    colId: Column.LivestockSaleColId.SPECIES_NAME,
    headerName: "Species Name",
    type: ColumnType.STRING,
    field: "livestockSale.species.name",
  },
};
