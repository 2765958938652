import React from "react";

import { PenTypes } from "constants/auctionPens";
import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

import { EditScanningPenModal } from ".";

function EditScanningPenModalAdapter(props) {
  const [hashParams, ignored, onClose] = useModalAdapter(
    ModalTypes.EditScanningPen,
    props,
  );
  const {
    penId = null,
    penType = PenTypes.SELLING,
    isBulkRePen = false,
  } = hashParams;

  return (
    <EditScanningPenModal
      penId={penId}
      penType={penType}
      onClose={onClose}
      isBulkRePen={isBulkRePen}
    />
  );
}

export default EditScanningPenModalAdapter;
