import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import { addTempBuyerWay } from "actions";

import { Button } from "components/Form/Button";
import { Row } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { BoldText } from "components/Text";

import { getAgencyBusinessById } from "selectors";

const InputWrapper = styled(Row)`
  color: #666;
  font-size: 48px;
  line-height: 84px;
  padding-bottom: 10px;
  border-bottom: 1px solid
    ${({ theme, error }) => ` ${error ? theme.colors.inputError : "#979797"}`};
`;

const TextInput = styled.input`
  border: none;
  width: 100%;
  color: #666;
  font-size: 60px;
  padding: 0 24px;
  font-weight: 100;
  &:focus {
    outline: none;
  }
`;

const LabelInput = styled.span`
  color: #666666;
  font-size: 24px;
  font-weight: bold;
  line-height: 48px;
  white-space: nowrap;
`;

const ErrorText = styled(BoldText)`
  margin-top: 5px;
  color: ${({ theme }) => `${theme.colors.inputError};`};
`;

const CHARACTER_LIMIT = 50;

function AddBuyerWayModal(props) {
  const { agencyId, buyerId, closeSelf, fullScreen, setBuyerWayId } = props;

  const [buyerWayName, setBuyerWayName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const buyer = useSelector(getAgencyBusinessById(buyerId, agencyId));

  const dispatch = useDispatch();

  const buyerWays = (buyer && buyer.buyerWays) || [];

  const handleBuyerWaySet = () => {
    const newBuyerWayName = buyerWayName.trim();
    const existingBuyerWayId = buyerWays.find(
      bw => bw.name === newBuyerWayName,
    )?.id;

    if (!existingBuyerWayId) {
      const tempId = uuidv4();
      dispatch(addTempBuyerWay(tempId, buyerId, newBuyerWayName));
      setBuyerWayId(tempId);
    } else {
      setBuyerWayId(existingBuyerWayId);
    }

    closeSelf();
  };

  const onBuyerWayInputChange = event => {
    const buyerWayName = event.target.value
      .substr(0, CHARACTER_LIMIT)
      .replace("  ", " ");
    let errorMessage = "";

    if (buyerWayName.length === 0) {
      errorMessage = "Buyer way can't be blank or be empty spaces";
    }

    setBuyerWayName(buyerWayName.length === 0 ? "" : buyerWayName);
    setErrorMessage(errorMessage);
  };

  const isSubmitDisabled = errorMessage || buyerWayName.length === 0;

  return (
    <Dialog open onClose={closeSelf} maxWidth="xl" fullScreen={fullScreen}>
      <DialogTitle onClose={closeSelf} />
      <DialogContent>
        <InputWrapper alignCenter error={!!errorMessage}>
          <LabelInput>New buyer way:</LabelInput>
          <TextInput
            data-tour="search"
            value={buyerWayName}
            onChange={onBuyerWayInputChange}
            autoFocus
          />
        </InputWrapper>

        {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      </DialogContent>

      <DialogActions>
        <Button
          data-tour="addBuyerWayButton"
          onClick={handleBuyerWaySet}
          disabled={isSubmitDisabled}
        >
          Add buyer way
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddBuyerWayModal.propTypes = {
  agencyId: PropTypes.string,
  buyerId: PropTypes.string,
  closeSelf: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  setBuyerWayId: PropTypes.func.isRequired,
};

export default AddBuyerWayModal;
