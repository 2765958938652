import React from "react";

import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { SightingAction } from "actions";

import TableWrapper from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import { Column } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { NLISSightingDocket } from "components/ReceiptModals/NLISDocket";
import { SightingsGrid } from "components/ReceiptModals/SightingsGrid";

import { NLISFileTypeHeadings } from "constants/nlis";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

import { getNLISTransactionByTypeById } from "selectors";

import { useToggle } from "hooks";

export const NLISSightingReceiptModal = React.memo(
  ({ decodedReturnTo, id, transferType }) => {
    const [isShowingViewSummary, toggleIsShowingViewSummary] = useToggle(true);

    const closeSelf = () => {
      if (decodedReturnTo) {
        returnToLocation(decodedReturnTo);
      } else {
        closeAllHashModals();
      }
    };

    const sighting = useSelector(
      getNLISTransactionByTypeById(transferType, id),
    );

    const dispatch = useDispatch();

    const handleRefresh = () => {
      dispatch(SightingAction.request({ id: sighting.id }));
    };

    const hasDetail = sighting.sightings?.length > 0;

    return (
      <Dialog
        open
        onClose={closeSelf}
        maxWidth={isShowingViewSummary ? "sm" : "lg"}
        fullWidth
        fullScreen={!isShowingViewSummary}
      >
        <DialogTitle onClose={closeSelf}>
          {NLISFileTypeHeadings[sighting.transferType] || sighting.transferType}
        </DialogTitle>
        <DialogContent dividers style={{ maxHeight: "85vh" }}>
          {isShowingViewSummary ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <NLISSightingDocket
                  id={id}
                  transferType={transferType}
                  refreshClick={handleRefresh}
                />
              </Grid>
            </Grid>
          ) : (
            hasDetail && (
              <Column fullWidth fullHeight>
                <TableWrapper>
                  <SightingsGrid sightings={sighting.sightings} />
                </TableWrapper>
              </Column>
            )
          )}
        </DialogContent>

        <DialogActions>
          {hasDetail && (
            <Button
              data-tour={isShowingViewSummary ? "viewDetail" : "viewSummary"}
              onClick={toggleIsShowingViewSummary}
            >
              {isShowingViewSummary ? "View Detail" : "View Summary"}
            </Button>
          )}
          <Button onClick={closeSelf}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  },
);
