import React from "react";

import { QuestionnaireRow } from "components/AttachmentForm/Components";
import {
  NVDOptionToggler,
  DatePurchasedOptionsToggler,
  NVD_OPTIONS,
} from "components/AttachmentForm/NVDOptionToggles";
import { Input } from "components/Form/FormikControls";
import { Column } from "components/Layout";

export const E0413 = ({ formikProps }) => (
  <Column>
    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.fed_feed_containing_animal_fats"
        label="1."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.owned_since_birth" label="2." />
    </QuestionnaireRow>

    {formikProps.values.declaration.owned_since_birth === NVD_OPTIONS.NO && (
      <QuestionnaireRow>
        <DatePurchasedOptionsToggler
          name="declaration.owned_since_birth_duration"
          label=""
        />
      </QuestionnaireRow>
    )}

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.fed_byproduct_stockfeeds"
        label="3."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.chemical_residue_restrictions"
        label="4."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.veterinary_treatment" label="5." />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.consumed_material_in_withholding_period"
        label="6."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.spray_drift_risk" label="7." />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <Input
        name="declaration.additional_information"
        label="8."
        suggestedValueFieldName="declaration.additional_information_suggested"
      />
    </QuestionnaireRow>
  </Column>
);
