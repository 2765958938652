import React from "react";

import { Formik } from "formik";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { requestNLISSignUp, sendToUserOverview } from "actions";

import { NLISForm, nlisValidationSchema } from "components/NlisForm";

import { NLISCredentialType } from "constants/nlis";

import {
  getActiveNLISSaleyardCredentials,
  getIsFetchingAuth,
  getIsFetchingNLISAuth,
  getIsScaleOperator,
} from "selectors";

export const SaleyardCredentials = () => {
  const nlisSaleyardCredentials =
    useSelector(getActiveNLISSaleyardCredentials) || {};

  const isScaleOperator = useSelector(getIsScaleOperator);

  const credentialType = isScaleOperator
    ? NLISCredentialType.SCALE_OPERATOR
    : NLISCredentialType.SALEYARD;

  const isFetching = useSelector(
    state => getIsFetchingAuth(state) || getIsFetchingNLISAuth(state),
  );
  const dispatch = useDispatch();

  const onSubmit = values =>
    dispatch(
      requestNLISSignUp(
        {
          ...values,
          credentialType,
        },
        {
          onSuccess: () => {
            // when the user is a scale operator and this is their first login, shoot them
            // back to the overview after they have entered the pre-requisite NLIS credentials
            if (isScaleOperator && isEmpty(nlisSaleyardCredentials)) {
              dispatch(sendToUserOverview());
            }
          },
        },
      ),
    );

  const { nlis_user, nlis_email, nlis_saleyard_id } = nlisSaleyardCredentials;

  const initialValues = {
    nlis_user,
    nlis_email,
    nlis_saleyard_id,
    nlis_password: "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={nlisValidationSchema}
      onSubmit={onSubmit}
    >
      <NLISForm
        isFetching={isFetching}
        credentialType={
          isScaleOperator
            ? NLISCredentialType.SCALE_OPERATOR
            : NLISCredentialType.SALEYARD
        }
      />
    </Formik>
  );
};
