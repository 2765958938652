import { useEffect } from "react";

import { useDebounce } from "@react-hook/debounce";
import { useSelector } from "react-redux";

export const useDebounceSelector = (selector, wait = 250, leading = false) => {
  const selectorData = useSelector(selector);

  const [data, setData] = useDebounce(selectorData, wait, leading);

  useEffect(() => {
    setData(selectorData);
  }, [selectorData, setData]);

  return data;
};
