import React from "react";

import { PropTypes } from "prop-types";

import { Species } from "constants/species";

import {
  createAndOpenPdf,
  getBuyerDetails,
  getSaleAndDocumentInformation,
  getPriceDetails,
  getQuantityDetails,
  getVendorDetails,
  getWeightDetails,
  dashBetweenLots,
  getBookingPenDetails,
  getCattleTableData,
  getTableData,
  getLanes,
  BookingCardVersions,
  getHgpDetails,
} from "lib/pdf";

const columnWidths = [90, 50, 180, 60, "*"];
const pageMargin = 12;
const pageMarginTop = 80;
const pageMarginBottom = 40;

function getSaleLotsPerPageCount(extraSpacing) {
  return extraSpacing ? 4 : 11;
}

const cattleColumnWidths = isPostSaleReport =>
  isPostSaleReport ? [70, 40, 180, 60, 40, "*"] : [70, 40, 180, 30, 70, "*"];

// In order to keep the auction pen data and the following blank
// row (which is used for note-taking) together, these are rendered
// as a nested table, taking advantage of the outer table's
// dontBreakRows property.

const makeCattleAuctionPenRow = (
  auctionPen,
  saleLots,
  showComment,
  blankRowHeight,
  extraSpacing,
  isPostSaleReport,
) => {
  const rows = [];
  rows.push([
    getBookingPenDetails(auctionPen, true),
    getQuantityDetails(auctionPen),
    {
      layout: dashBetweenLots,
      table: {
        widths: isPostSaleReport ? [180, 55, 45, 110] : [175, 33, 70, 110],
        body: saleLots.map(sl => {
          return [
            getVendorDetails(
              sl,
              {
                includeWeight: false,
                includeVendorNumber: true,
                extraSpacing,
                showVendorInfo: true,
                includeEuStatus: true,
              },
              false,
              true,
            ),
            isPostSaleReport ? getWeightDetails(sl) : getHgpDetails(sl),
            getPriceDetails(sl),
            getBuyerDetails(sl, extraSpacing),
          ];
        }) || [" ", " ", " ", " "],
      },
    },
    "",
    "",
    "",
  ]);
  if (showComment) {
    rows.push([
      { text: "", colSpan: cattleColumnWidths(isPostSaleReport).length },
    ]);
  }
  return {
    border: [false, false, false, false, false],
    marginTop: -6,
    table: {
      headerRows: 0,
      widths: cattleColumnWidths(isPostSaleReport),
      heights(row) {
        return row === 0 ? "auto" : blankRowHeight;
      },
      body: rows,
    },
  };
};

// Render the outer tables, one for each lane.
const getContent = (
  auctionPens,
  sale,
  blankRowHeight,
  extraSpacing,
  isPostSaleReport,
) => {
  let tableHeaders = [];

  let tableColumnWidths = [];
  if (sale.species_id === Species.CATTLE) {
    tableHeaders = [
      { text: "PEN", style: "tableHeader" },
      { text: "HD", style: "tableHeader" },
      { text: "VENDOR", style: "tableHeader" },
      isPostSaleReport
        ? { text: "WEIGHT", style: "tableHeader" }
        : { text: "HGP", style: "tableHeader" },
      { text: "PRICE", style: "tableHeader" },
      { text: "BUYER", style: "tableHeader" },
    ];
    tableColumnWidths = cattleColumnWidths(isPostSaleReport);
  } else {
    tableHeaders = [
      { text: "PEN", style: "tableHeader" },
      { text: "HD", style: "tableHeader" },
      { text: "VENDOR", style: "tableHeader" },
      { text: "PRICE", style: "tableHeader" },
      { text: "BUYER", style: "tableHeader" },
    ];
    tableColumnWidths = columnWidths;
  }
  return getLanes(auctionPens).map((lane, i) => {
    const laneTableData =
      sale.species_id === Species.CATTLE
        ? getCattleTableData(
            lane,
            blankRowHeight,
            extraSpacing,
            makeCattleAuctionPenRow,
            getSaleLotsPerPageCount,
            true,
            isPostSaleReport,
          )
        : getTableData(
            lane,
            blankRowHeight,
            extraSpacing,
            columnWidths,
            getSaleLotsPerPageCount,
            false, // Landscape
            true, // Show Name And Tag
            false, // isSignOffReport
            true, // showVendorInfo
            false, // showTagAndNameInDescriptionFirst
          );

    return {
      pageBreak: i > 0 ? "before" : null,
      table: {
        headerRows: 1,
        dontBreakRows: true,
        widths: ["*"],
        body: [
          [
            {
              border: [false, false, false, false, false],
              table: {
                headerRows: 0,
                dontBreakRows: true,
                widths: tableColumnWidths,
                body: [tableHeaders],
              },
            },
          ],
          ...laneTableData,
        ],
      },
    };
  });
};

export const BookingCardPortrait = ({
  auctionPens,
  sale,
  agencyName,
  round,
  history,
  extraSpacing,
  version = BookingCardVersions.PostSale,
}) => {
  const isPostSaleReport = version !== BookingCardVersions.PreSale;
  const blankRowHeight = 50;
  const pdfDefinition = {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [pageMargin, pageMarginTop, pageMargin, pageMarginBottom],
    content: getContent(
      auctionPens,
      sale,
      blankRowHeight,
      extraSpacing,
      isPostSaleReport,
    ),
    header: getSaleAndDocumentInformation(
      auctionPens,
      sale,
      agencyName,
      round,
      pageMargin,
    ),
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 11,
      },
      mainText: {
        bold: true,
        fontSize: 16,
      },
      middleText: {
        fontSize: 14,
        bold: true,
      },
      smallTextBold: {
        bold: true,
        fontSize: 12,
      },
      smallText: {
        fontSize: 12,
      },
      spacingText: {
        fontSize: 10,
      },
      smallSpacingText: {
        fontSize: 6,
      },
    },
  };

  createAndOpenPdf(pdfDefinition, history);

  return <div />;
};

BookingCardPortrait.propTypes = {
  auctionPens: PropTypes.array,
  sale: PropTypes.object,
  history: PropTypes.object,
  agencyName: PropTypes.string,
  round: PropTypes.object,
  extraSpacing: PropTypes.bool,
};
