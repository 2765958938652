import React from "react";

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Tooltip } from "@material-ui/core";
import styled from "styled-components/macro";

import { Chip } from "components/Chip";
import { SecondaryButton } from "components/Form";
import { Tooltip as SecondaryTooltip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";
import { Subtitle } from "components/Text";

import { toTitleCase } from "lib";

export const CardMetaItem = styled.div(
  ({ left, center, right, start, middle, end, gridArea }) => `
  justify-self: ${
    left ? "start" : center ? "center" : right ? "end" : "initial"
  };
  align-self: ${start ? "start" : middle ? "center" : end ? "end" : "initial"};
  ${gridArea ? `grid-area: ${gridArea};` : ""}
`,
);

export const UntitledDetailsBlock = styled(CardMetaItem)(
  ({ theme, expanded }) => `
  border-radius: ${theme.radii[3]}px;
  background-color: ${theme.colors.gray95};
  padding: ${expanded ? theme.space[2] : theme.space[1]}px;
  color: ${theme.colors.titleMedium};
  font-size: ${theme.fontSizes.gamma}px;
  line-height: ${theme.fontSizes.epsilon}px;
  font-weight: ${theme.fontWeights.medium};
`,
);

export const CardGrid = styled.div(
  ({
    align = "center",
    areas,
    columns,
    gap = 1,
    theme,
    printHidden,
    topMargin = 1,
  }) => `
  grid-gap: ${theme.space[gap]}px;
  display: grid;
  margin-top: ${theme.space[topMargin]}px;
  align-items: ${align};
  ${
    Array.isArray(columns) && columns.length > 0
      ? `grid-template-columns: ${columns.join(" ")};`
      : ""
  }
  ${
    Array.isArray(areas) && areas.length > 0
      ? `grid-template-areas: ${areas
          .map(area => `"${area.join(" ")}"`)
          .join(" ")};`
      : ""
  }
  ${printHidden ? "@media print { display: none; }" : ""}
`,
);

export const PrimaryHeading = styled.span(
  ({ theme, color }) => `
  display: block;
  color: ${theme.colors[color] || theme.colors.titleDark};
  font-size: ${theme.fontSizes.gamma}px;
  line-height: ${theme.fontSizes.epsilon}px;
  font-weight: ${theme.fontWeights.medium}`,
);

export const SecondaryCardButton = styled(SecondaryButton)(
  ({ theme }) => `
  margin: 0;
  padding: ${theme.space[1]}px ${theme.space[2]}px;
  min-width: ${theme.space[6]}px;
`,
);

const SyncErrorText = styled.span`
  color: ${({ theme }) => theme.colors.inputError};
`;

export const EditIcon = styled(FontAwesomeIcon)`
  margin: 0 ${({ theme }) => theme.space[1]}px;
  cursor: pointer;
`;

export function SyncingStatus({ error }) {
  if (error) {
    return <SyncErrorText>Error syncing</SyncErrorText>;
  }
  return [<CircularProgress size={12} key={0} />, " Syncing"];
}

export function DetailsBlock({ gridArea, children, expanded }) {
  return (
    <CardMetaItem gridArea={gridArea}>
      <UntitledDetailsBlock expanded={expanded}>
        {children || "\u00A0"}
      </UntitledDetailsBlock>
    </CardMetaItem>
  );
}

export function SelfTitledField(props) {
  const {
    title,
    value,
    gridArea,
    onClickEdit = null,
    tooltip,
    extraButtons = [],
  } = props;
  const buttons = [
    { icon: faPencilAlt, onClick: onClickEdit },
    ...extraButtons,
  ];
  return (
    <CardMetaItem gridArea={gridArea}>
      <PrimaryHeading>
        {title}
        {buttons.map((button, idx) =>
          typeof button.onClick === "function" ? (
            <EditIcon
              icon={button.icon}
              onClick={button.onClick}
              key={`button-${idx}`}
            />
          ) : null,
        )}
      </PrimaryHeading>
      {tooltip && <SecondaryTooltip title={tooltip} />}
      <Subtitle>{value || "\u00A0"}</Subtitle>
    </CardMetaItem>
  );
}

const TitleCase = styled.span`
  text-transform: capitalize;
`;

export function Mandoline(props) {
  const { title, showKeys, showTooltip, data, gridArea } = props;
  return (
    <CardMetaItem gridArea={gridArea}>
      <Subtitle>{title}</Subtitle>
      <Row flexWrap>
        {Object.keys(data).map(key => {
          const normalisedKey = toTitleCase(key.replace(/_/g, " "));
          return (
            <Tooltip
              disableFocusListener={!showTooltip}
              disableHoverListener={!showTooltip}
              disableTouchListener={!showTooltip}
              title={`${normalisedKey}: ${data[key]}`}
              key={key}
            >
              <Chip>
                {showKeys && <TitleCase>{normalisedKey}: </TitleCase>}
                {data[key]}
              </Chip>
            </Tooltip>
          );
        }) || "\u00A0"}
      </Row>
    </CardMetaItem>
  );
}

export function MetadataField(props) {
  const { title, value, gridArea, children } = props;
  return (
    <CardMetaItem gridArea={gridArea}>
      <Subtitle>{title}</Subtitle>
      {children || <PrimaryHeading>{value || "\u00A0"}</PrimaryHeading>}
    </CardMetaItem>
  );
}
