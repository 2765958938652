import { sum, sumBy } from "lodash/math";
import { createSelector } from "reselect";

import { isLiveWeightLot, isOpenAuctionLot } from "lib/saleLot";

import {
  getConsignments,
  getCurrentRoundsList,
  getSaleLots,
  selectConsignmentIdsByDeploymentSaleIdLookup,
  selectCurrentDeploymentSaleIdsList,
  selectIsNoSaleBySaleLotIdLookup,
  selectIsPennedBySaleLotIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectIsUnsoldBySaleLotIdLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleLotIdsByDeploymentSaleIdLookup,
  selectSaleLotIdsByRoundIdLookup,
  selectTotalHdCountBySaleLotIdLookup,
  selectTotalHdCountByVendorSplitSaleLotIdLookup,
  selectVendorSplitSaleLotIdsByConsignmentIdLookup,
} from "selectors";

const saleLotQuantityByConsignmentIdReducer = (
  saleLotIdsByConsignmentId,
  totalHdCountLookup,
) =>
  Object.entries(saleLotIdsByConsignmentId).reduce(
    (acc, [consignmentId, saleLotIds]) => {
      acc[consignmentId] = saleLotIds.reduce(
        (acc, saleLotId) => acc + totalHdCountLookup[saleLotId],
        0,
      );
      return acc;
    },
    {},
  );

/**
 * Returns the total number of head in all of the Sale Lots for a given Consignment, keyed by Consignment Id
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): Object<string, number>}
 */
export const selectSaleLotQuantityByConsignmentIdLookup = createSelector(
  [selectSaleLotIdsByConsignmentIdLookup, selectTotalHdCountBySaleLotIdLookup],
  saleLotQuantityByConsignmentIdReducer,
);
/**
 * Returns the total number of head in all of the Sale Lots for a given Consignment, keyed by Consignment Id
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): Object<string, number>}
 */
export const selectVendorSplitSaleLotQuantityByConsignmentIdLookup =
  createSelector(
    [
      selectVendorSplitSaleLotIdsByConsignmentIdLookup,
      selectTotalHdCountByVendorSplitSaleLotIdLookup,
    ],
    saleLotQuantityByConsignmentIdReducer,
  );

export const getSaleLotQuantityByConsignmentId = consignmentId => state =>
  selectSaleLotQuantityByConsignmentIdLookup(state)[consignmentId] || 0;

/**
 * Returns the total number of received head
 * Requires:
 *  - `Consignments`
 * @type {function(state): number}
 */
export const selectReceivedCount = createSelector(
  [getConsignments],
  consignments => sumBy(Object.values(consignments), "quantity"),
);
/**
 * Returns the total number of drafted head
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectDraftedCount = createSelector(
  [selectTotalHdCountBySaleLotIdLookup],
  totalHdCountLookup => sum(Object.values(totalHdCountLookup)),
);
/**
 * Returns the total number of undrafted head
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectNotDraftedCount = createSelector(
  [selectReceivedCount, selectDraftedCount],
  (receivedCount, draftedCount) => receivedCount - draftedCount,
);
/**
 * Returns the total number of penned head
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectPennedCount = createSelector(
  [selectTotalHdCountBySaleLotIdLookup, selectIsPennedBySaleLotIdLookup],
  (totalHdCountBySaleLotIdLookup, isPennedBySaleLotId) =>
    Object.entries(totalHdCountBySaleLotIdLookup).reduce(
      (acc, [saleLotId, totalHdCount]) =>
        isPennedBySaleLotId[saleLotId] ? acc + totalHdCount : acc,
      0,
    ),
);
/**
 * Returns the total number of drafted head
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectNotPennedCount = createSelector(
  [selectDraftedCount, selectPennedCount],
  (draftedCount, pennedCount) => draftedCount - pennedCount,
);
/**
 * Returns the number of head not yet sold
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectUnsoldHeadCount = createSelector(
  [getSaleLots, selectIsUnsoldBySaleLotIdLookup],
  (saleLotByIdLookup, isUnsoldBySaleLotIdLookup) =>
    sumBy(
      Object.values(saleLotByIdLookup).filter(
        saleLot => isUnsoldBySaleLotIdLookup[saleLot.id],
      ),
      "quantity",
    ),
);
/**
 * Returns the number of head not yet sold
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectUnsoldProgenyCount = createSelector(
  [getSaleLots, selectIsUnsoldBySaleLotIdLookup],
  (saleLotByIdLookup, isUnsoldBySaleLotIdLookup) =>
    sumBy(
      Object.values(saleLotByIdLookup).filter(
        saleLot => isUnsoldBySaleLotIdLookup[saleLot.id],
      ),
      "quantityProgeny",
    ),
);
/**
 * Returns the total number of sold head and progeny
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectTotalSoldHeadCount = createSelector(
  [selectTotalHdCountBySaleLotIdLookup, selectIsSoldBySaleLotIdLookup],
  (totalHdCountBySaleLotIdLookup, isSoldBySaleLotId) =>
    Object.entries(totalHdCountBySaleLotIdLookup).reduce(
      (acc, [saleLotId, totalHdCount]) =>
        isSoldBySaleLotId[saleLotId] ? acc + totalHdCount : acc,
      0,
    ),
);
/**
 * Returns the number of sold head
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectSoldHeadCount = createSelector(
  [getSaleLots, selectIsSoldBySaleLotIdLookup],
  (saleLotByIdLookup, isSoldBySaleLotId) =>
    sumBy(
      Object.values(saleLotByIdLookup).filter(
        saleLot => isSoldBySaleLotId[saleLot.id],
      ),
      "quantity",
    ),
);
/**
 * Returns the number of sold sale lots
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectSoldSaleLotCount = createSelector(
  [getSaleLots, selectIsSoldBySaleLotIdLookup],
  (saleLotByIdLookup, isSoldBySaleLotId) =>
    Object.values(saleLotByIdLookup).filter(
      saleLot => isSoldBySaleLotId[saleLot.id],
    ).length,
);
/**
 * Returns the total number of sold progeny
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectSoldProgenyCount = createSelector(
  [getSaleLots, selectIsSoldBySaleLotIdLookup],
  (saleLotByIdLookup, isSoldBySaleLotId) =>
    sumBy(
      Object.values(saleLotByIdLookup).filter(
        saleLot => isSoldBySaleLotId[saleLot.id],
      ),
      "quantityProgeny",
    ),
);
/**
 * Returns the total value in cents of sold head
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectSoldCents = createSelector(
  [getSaleLots, selectIsSoldBySaleLotIdLookup],
  (saleLotByIdLookup, isSoldBySaleLotId) =>
    Object.entries(saleLotByIdLookup).reduce(
      (acc, [saleLotId, saleLot]) =>
        isSoldBySaleLotId[saleLotId] ? acc + saleLot.total_price_cents : acc,
      0,
    ),
);
/**
 * Returns the total number of no sale head and progeny
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectTotalNoSaleCount = createSelector(
  [selectTotalHdCountBySaleLotIdLookup, selectIsNoSaleBySaleLotIdLookup],
  (totalHdCountBySaleLotIdLookup, isNoSaleBySaleLotId) =>
    Object.entries(totalHdCountBySaleLotIdLookup).reduce(
      (acc, [saleLotId, totalHdCount]) =>
        isNoSaleBySaleLotId[saleLotId] ? acc + totalHdCount : acc,
      0,
    ),
);
/**
 * Returns the number of no sale head
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectNoSaleHeadCount = createSelector(
  [getSaleLots, selectIsNoSaleBySaleLotIdLookup],
  (saleLotByIdLookup, isNoSaleBySaleLotId) =>
    sumBy(
      Object.values(saleLotByIdLookup).filter(
        saleLot => isNoSaleBySaleLotId[saleLot.id],
      ),
      "quantity",
    ),
);
/**
 * Returns the total number of no sale progeny
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectNoSaleProgenyCount = createSelector(
  [getSaleLots, selectIsNoSaleBySaleLotIdLookup],
  (saleLotByIdLookup, isNoSaleBySaleLotId) =>
    sumBy(
      Object.values(saleLotByIdLookup).filter(
        saleLot => isNoSaleBySaleLotId[saleLot.id],
      ),
      "quantityProgeny",
    ),
);
/**
 * Returns the total number of delivered head
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectDeliveredCount = createSelector([getSaleLots], saleLots =>
  Object.values(saleLots).reduce(
    (acc, saleLot) => acc + (saleLot.quantity_delivered || 0),
    0,
  ),
);
/**
 * Returns the total number of head from all of a Deployment Sale's Consignment Quantities, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 * @type {function(state): Object<string, number>}
 */
export const selectReceivedCountByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    selectConsignmentIdsByDeploymentSaleIdLookup,
    getConsignments,
  ],
  (deploymentSaleIds, consignmentIdsByDeploymentSaleId, consignments) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      // Get all of the Consignment Ids in this Deployment Sale
      const consignmentIds =
        consignmentIdsByDeploymentSaleId[deploymentSaleId] || [];

      // Sum all of the Consignments' Quantities in this Deployment sale
      acc[deploymentSaleId] = consignmentIds.reduce(
        (acc, consignmentId) => acc + consignments[consignmentId].quantity,
        0,
      );
      return acc;
    }, {}),
);
/**
 * Returns the total number of head from all of a Deployment Sale's Sale Lot Quantities, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, number>}
 */
export const selectDraftedCountByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    selectSaleLotIdsByDeploymentSaleIdLookup,
    selectTotalHdCountBySaleLotIdLookup,
  ],
  (
    deploymentSaleIds,
    saleLotIdsByDeploymentSaleId,
    totalHdCountBySaleLotIdLookup,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      // Get all of the Sale Lot Ids in this Deployment Sale
      const deploymentSaleSaleLotIds =
        saleLotIdsByDeploymentSaleId[deploymentSaleId] || [];

      // Sum all of the Sale Lot Quantities in this Deployment sale
      acc[deploymentSaleId] = deploymentSaleSaleLotIds.reduce(
        (acc, saleLotId) => acc + totalHdCountBySaleLotIdLookup[saleLotId],
        0,
      );
      return acc;
    }, {}),
);
/**
 * Returns the total number of penned head, keyed by Sale Round Id
 * Requires:
 *  - `Auction Pens`
 *  - `Sale Lots`
 *  - `Sales`
 * @type {function(state): Object<string, number}
 */
export const selectPennedCountByRoundIdLookup = createSelector(
  [
    getCurrentRoundsList,
    selectIsPennedBySaleLotIdLookup,
    selectSaleLotIdsByRoundIdLookup,
    selectTotalHdCountBySaleLotIdLookup,
  ],

  (
    roundIds,
    isPennedBySaleLotIdLookup,
    saleLotIdsByRoundIdLookup,
    totalHdCountBySaleLotIdLookup,
  ) =>
    roundIds.reduce((acc, roundId) => {
      acc[roundId] =
        saleLotIdsByRoundIdLookup[roundId]
          ?.filter(saleLotId => isPennedBySaleLotIdLookup[saleLotId])
          .reduce(
            (acc, saleLotId) => acc + totalHdCountBySaleLotIdLookup[saleLotId],
            0,
          ) || 0;
      return acc;
    }, {}),
);
/**
 * Returns the total number of un-penned head, keyed by Sale Round Id
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): Object<string, number}
 */
export const selectNotPennedCountByRoundIdLookup = createSelector(
  [
    getCurrentRoundsList,
    selectTotalHdCountBySaleLotIdLookup,
    selectSaleLotIdsByRoundIdLookup,
    selectIsPennedBySaleLotIdLookup,
  ],
  (
    roundIds,
    totalHdCountBySaleLotIdLookup,
    saleLotIdsByRoundId,
    isPennedBySaleLotId,
  ) =>
    roundIds.reduce((acc, roundId) => {
      const saleLotIds = saleLotIdsByRoundId[roundId] || [];
      acc[roundId] = saleLotIds.reduce(
        (acc, saleLotId) =>
          isPennedBySaleLotId[saleLotId]
            ? acc
            : acc + totalHdCountBySaleLotIdLookup[saleLotId],
        0,
      );
      return acc;
    }, {}),
);
/**
 * Returns the total number of sold head, keyed by Sale Round Id
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 *  - `Sales`
 * @type {function(state): Object<string, number}
 */
export const selectSoldCountByRoundIdLookup = createSelector(
  [
    getCurrentRoundsList,
    selectTotalHdCountBySaleLotIdLookup,
    selectSaleLotIdsByRoundIdLookup,
    selectIsSoldBySaleLotIdLookup,
  ],
  (
    roundIds,
    totalHdCountBySaleLotIdLookup,
    saleLotIdsByRoundId,
    isSoldBySaleLotId,
  ) =>
    roundIds.reduce((acc, roundId) => {
      const saleLotIds = saleLotIdsByRoundId[roundId] || [];
      acc[roundId] = saleLotIds.reduce(
        (acc, saleLotId) =>
          isSoldBySaleLotId[saleLotId]
            ? acc + totalHdCountBySaleLotIdLookup[saleLotId]
            : acc,
        0,
      );
      return acc;
    }, {}),
);
/**
 * Returns the total number of no sale head, keyed by Sale Round Id
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): Object<string, number}
 */
export const selectNoSaleCountByRoundIdLookup = createSelector(
  [
    getCurrentRoundsList,
    selectSaleLotIdsByRoundIdLookup,
    selectTotalHdCountBySaleLotIdLookup,
    selectIsNoSaleBySaleLotIdLookup,
  ],
  (
    roundIds,
    saleLotIdsByRoundId,
    totalHdCountBySaleLotIdLookup,
    isNoSaleBySaleLotId,
  ) =>
    roundIds.reduce((acc, roundId) => {
      const saleLotIds = saleLotIdsByRoundId[roundId] || [];
      acc[roundId] = saleLotIds.reduce(
        (acc, saleLotId) =>
          isNoSaleBySaleLotId[saleLotId]
            ? acc + totalHdCountBySaleLotIdLookup[saleLotId]
            : acc,
        0,
      );
      return acc;
    }, {}),
);
/**
 * Returns the total number of delivered head, keyed by Sale Round Id
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): Object<string, number}
 */
export const selectDeliveredCountByRoundIdLookup = createSelector(
  [getCurrentRoundsList, selectSaleLotIdsByRoundIdLookup, getSaleLots],
  (roundIds, saleLotIdsByRoundId, saleLots) =>
    roundIds.reduce((acc, roundId) => {
      const saleLotIds = saleLotIdsByRoundId[roundId] || [];
      acc[roundId] = saleLotIds.reduce(
        (acc, saleLotId) => acc + saleLots[saleLotId].quantity_delivered,
        0,
      );
      return acc;
    }, {}),
);

/**
 * Returns the total number of progeny
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): number}
 */
export const selectProgenyHeadCount = createSelector([getSaleLots], saleLots =>
  sumBy(Object.values(saleLots), "quantityProgeny"),
);

/**
 * Returns the total number of head in the sale
 * Requires:
 *  - `SaleLots`
 * @type {function(state): number}
 */
export const selectLottedHeadCount = createSelector([getSaleLots], saleLots =>
  sumBy(Object.values(saleLots), "quantity"),
);

/**
 * Returns the total number of head sold as open auction (per head or gross) in the sale
 * Requires:
 *  - `SaleLots`
 * @type {function(state): number}
 */
export const selectSoldOpenAuctionHeadCount = createSelector(
  [getSaleLots],
  saleLots =>
    sumBy(Object.values(saleLots).filter(isOpenAuctionLot), "quantity"),
);

/**
 * Returns the total number of head sold as open auction (per head or gross) in the sale
 * Requires:
 *  - `SaleLots`
 * @type {function(state): number}
 */
export const selectSoldOpenAuctionProgenyCount = createSelector(
  [getSaleLots],
  saleLots =>
    sumBy(Object.values(saleLots).filter(isOpenAuctionLot), "quantityProgeny"),
);

/**
 * Returns the total number of head sold as live weight (c/kg) in the sale
 * Requires:
 *  - `SaleLots`
 * @type {function(state): number}
 */
export const selectSoldLiveWeightHeadCount = createSelector(
  [getSaleLots],
  saleLots =>
    sumBy(Object.values(saleLots).filter(isLiveWeightLot), "quantity"),
);

/**
 * Returns the total number of progeny sold as live weight (c/kg) in the sale
 * Requires:
 *  - `SaleLots`
 * @type {function(state): number}
 */
export const selectSoldLiveWeightProgenyCount = createSelector(
  [getSaleLots],
  saleLots =>
    sumBy(Object.values(saleLots).filter(isLiveWeightLot), "quantityProgeny"),
);

export const getReceivedCountByDeploymentSaleId = deploymentSaleId => state =>
  selectReceivedCountByDeploymentSaleIdLookup(state)[deploymentSaleId];

export const getDraftedCountByDeploymentSaleId = deploymentSaleId => state =>
  selectDraftedCountByDeploymentSaleIdLookup(state)[deploymentSaleId];
