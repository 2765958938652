import React, { useCallback, useEffect } from "react";

import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { setSetting } from "actions";

import { Label } from "components/Form/FormikControls";
import { FullWidthSelect } from "components/SearchableSelector/ReactSelect";

import { AlternativeComparableFields } from "constants/businesses";
import { Settings } from "constants/settings";

import {
  getSetting,
  selectAlternativeFieldOptions,
  selectAlternativeSourceOptions,
  selectRoleInSaleOptions,
} from "selectors";

const defaultOptionGetter = v => ({ value: v, label: v });

export const SettingSelect = ({
  setting,
  optionsSelector,
  label,
  optionGetter = defaultOptionGetter,
  defaultToAllSelected = true,
  isMulti = true,
}) => {
  const options = useSelector(optionsSelector);
  const value = useSelector(getSetting(setting)) || options;

  const dispatch = useDispatch();
  const onChange = useCallback(
    value => {
      dispatch(
        setSetting(
          setting,
          value.map(o => o.value),
        ),
      );
    },
    [dispatch, setting],
  );

  useEffect(() => {
    if (defaultToAllSelected && value === undefined && Array.isArray(options)) {
      dispatch(setSetting(setting, options));
    }
  });

  return (
    <>
      <Label>{label}</Label>
      <FullWidthSelect
        isMulti={isMulti}
        isClearable
        closeMenuOnSelect={false}
        isSearchable
        menuShouldScrollIntoView
        openMenuOnFocus
        options={options.map(optionGetter)}
        hideSelectedOptions={false}
        onChange={onChange}
        maxMenuHeight={600} // We can only specify pixels.
        value={value.map(optionGetter)}
      />
    </>
  );
};

export const AlternativeFieldConnectedSelect = ({ label }) => {
  const fieldOptionGetter = f => ({
    label: get(AlternativeComparableFields, f, f),
    value: f,
  });

  return (
    <SettingSelect
      setting={Settings.resolveAlternatives.filteredFields}
      optionsSelector={selectAlternativeFieldOptions}
      optionGetter={fieldOptionGetter}
      label={label}
    />
  );
};

export const RoleInSaleConnectedSelect = ({ label }) => {
  return (
    <SettingSelect
      setting={Settings.resolveAlternatives.filterByRoleInSale}
      optionsSelector={selectRoleInSaleOptions}
      label={label}
    />
  );
};
export const AlternativeSourceConnectedSelect = ({ label }) => {
  return (
    <SettingSelect
      setting={Settings.resolveAlternatives.filterByAlternativeSource}
      optionsSelector={selectAlternativeSourceOptions}
      label={label}
    />
  );
};
