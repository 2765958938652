import {
  createLookupCombiner,
  createLookupSelectors,
  getLedgerEntries,
  selectBillingDocumentIdsByBillingRunIdLookup,
  selectLedgerEntryIdsByBillingDocumentIdLookup,
  selectLedgerEntryIdsByMincenousIdLookup,
} from "selectors";

export const getLedgerEntryById = ledgerEntryId => state =>
  getLedgerEntries(state)[ledgerEntryId] || null;

export const getLedgerEntryIdsByMincenousId = mincenousId => state =>
  selectLedgerEntryIdsByMincenousIdLookup(state)[mincenousId];

export const [selectIsDuplicateByLedgerEntryId, getIsDuplicateByLedgerEntryId] =
  createLookupSelectors(
    [selectLedgerEntryIdsByMincenousIdLookup],
    ledgerEntryIdsByMincenousIdLookup =>
      Object.entries(ledgerEntryIdsByMincenousIdLookup).reduce(
        (acc, [_ignored, ledgerEntryIds]) => {
          const isDuplicate = ledgerEntryIds.length > 1;
          for (const ledgerEntryId of ledgerEntryIds) {
            acc[ledgerEntryId] = isDuplicate;
          }
          return acc;
        },
        {},
      ),
  );

export const [
  selectHasDuplicatesByBillingDocumentIdLookup,
  getHasDuplicatesByBillingDocumentId,
] = createLookupSelectors(
  [
    selectLedgerEntryIdsByBillingDocumentIdLookup,
    selectIsDuplicateByLedgerEntryId,
  ],
  createLookupCombiner((ledgerEntryIds, isDuplicateByLedgerEntryId) =>
    ledgerEntryIds.some(
      ledgerEntryId => isDuplicateByLedgerEntryId[ledgerEntryId],
    ),
  ),
);

export const [
  selectHasDuplicatesByBillingRunIdLookup,
  getHasDuplicatesByBillingRunId,
] = createLookupSelectors(
  [
    selectBillingDocumentIdsByBillingRunIdLookup,
    selectHasDuplicatesByBillingDocumentIdLookup,
  ],
  createLookupCombiner((billingDocumentIds, hasDuplicatesByBillingDocumentId) =>
    billingDocumentIds.some(
      billingDocumentId => hasDuplicatesByBillingDocumentId[billingDocumentId],
    ),
  ),
);
