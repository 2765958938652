import React, { useState } from "react";
import { Button, SecondaryButton } from "components/Form";
import { DialogActions } from "components/MaterialDialog";
import { useSubmitHandler } from "components/Form/FormikControls";

export interface BulkReportFormProps {
  reportSlug: string;
  onClose: () => void;
}

interface ModalActionsProps {
  onClose: () => void;
}

export function ModalActions(props: ModalActionsProps): React.JSX.Element {
  const { onClose } = props;

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  useSubmitHandler(false, setIsSubmitEnabled);

  return (
    <DialogActions>
      <SecondaryButton onClick={onClose}>Back</SecondaryButton>
      <Button type="submit" disabled={!isSubmitEnabled}>
        Generate Reports
      </Button>
    </DialogActions>
  );
}
