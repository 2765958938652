import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { RowCard } from "components/Card";
import UnresolvedSaleLotCommentsTable from "components/DeploymentSaleSummaries/UnresolvedSaleLotCommentsTable";
import { Column, Row } from "components/Layout";
import TabSelector from "components/TabSelector";

import { getSaleLotIdsWithUnresolvedCommentsByDeploymentSaleId } from "selectors";

import { useIsMobile } from "hooks";

const ScrollableRow = styled(Row)`
  overflow-x: scroll;
`;

export const PrivateSaleExceptionsComponent = ({ deploymentSaleId }) => {
  const isMobile = useIsMobile;
  const hasUnresolvedSaleLotComments = useSelector(
    state =>
      getSaleLotIdsWithUnresolvedCommentsByDeploymentSaleId(deploymentSaleId)(
        state,
      ).length > 0,
  );

  if (!hasUnresolvedSaleLotComments) {
    return null;
  }

  return (
    <RowCard fullWidth isMobile={isMobile}>
      <Column fullWidth>
        <ScrollableRow>
          <TabSelector>Unresolved Sale Lot Comments</TabSelector>
        </ScrollableRow>
        <div>
          <UnresolvedSaleLotCommentsTable deploymentSaleId={deploymentSaleId} />
        </div>
      </Column>
    </RowCard>
  );
};
