import React, { memo } from "react";

import { faLink } from "@fortawesome/free-solid-svg-icons";
import { styled as styledMaterial } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { LinkButton } from "components/Form";
import { Row } from "components/Layout";

import { LabelText, Required } from "./layout";
import { Tooltip } from "./Tooltip";

const StyledFaIcon = styledMaterial(FaIcon)`
  font-size: 16px;
`;

const StyledToolTipWrapper = styled.div`
  padding-left: 5px;
`;

export const LabelComponent = props => {
  const {
    children,
    htmlFor,
    error,
    required,
    tooltip,
    altTooltip,
    altColor,
    justifyStart,
    inlineButton = null,
  } = props;
  return (
    <Row
      justifyBetween={!justifyStart}
      justifyStart={justifyStart}
      whiteSpaceNoWrap
    >
      <LabelText error={error} htmlFor={htmlFor}>
        {children}
        {required ? <Required /> : ""}
      </LabelText>
      <StyledToolTipWrapper>
        {tooltip && <Tooltip title={tooltip} />}{" "}
        {altTooltip && (
          <Tooltip clickable title={altTooltip}>
            <StyledFaIcon icon={faLink} color={altColor} />
          </Tooltip>
        )}
      </StyledToolTipWrapper>
      {inlineButton && (
        <LinkButton type="button" onClick={inlineButton.onClick}>
          {inlineButton.label}
        </LinkButton>
      )}
    </Row>
  );
};

export const Label = memo(LabelComponent);
Label.propTypes = {
  tooltip: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  altTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  altColor: PropTypes.string,
};
