import React from "react";

import { useSelector } from "react-redux";

import { Dialog, DialogTitle } from "components/MaterialDialog";

import { ModalTypes } from "constants/navigation";

import { getContextByModalType } from "selectors";

import { ModalContent } from "./ModalContent";

export function RetainProceedsModalAdapter(props) {
  const { onClose } = props;

  const context =
    useSelector(getContextByModalType(ModalTypes.RetainProceeds)) || {};

  const { billingDocumentId, isOpen } = context;

  if (!billingDocumentId) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      key={billingDocumentId}
    >
      <DialogTitle onClose={onClose}>Retain Proceeds</DialogTitle>
      <ModalContent billingDocumentId={billingDocumentId} onClose={onClose} />
    </Dialog>
  );
}
