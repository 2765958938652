import React from "react";

import { Grid } from "@material-ui/core";

import { Column } from "components/Layout";

import { SingleWeighSelector } from "./SingleWeighSelector";

export const SingleWeigh = () => {
  return (
    <Column padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SingleWeighSelector />
        </Grid>
      </Grid>
    </Column>
  );
};
