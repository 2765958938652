import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  content: "content",
  id: "id",
  isGlobal: "is_global",
  name: "name",
  ownerId: "owner_id",
  ownerType: "owner_type",
  table: "table",
  filters: "filters",
  permissions: "permissions",
};

export const serializeSavedView = buildSerializer(serializeMap, "savedView");
export const deserializeSavedView = buildDeserializer(
  serializeMap,
  "savedView",
);
