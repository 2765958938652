import React from "react";

import { faMobile, faPlus } from "@fortawesome/free-solid-svg-icons";
import { PropTypes } from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { screenResize } from "actions";

import { MultiButton } from "components/Button";
import GlobalSearch from "components/SearchInput/GlobalSearch";

import {
  getLivestockSaleId,
  getSaleRoute,
  getSaleyardName,
} from "lib/navigation";

import { useTheme } from "hooks";

const Wrapper = styled.div`
  text-align: center;
  padding: 36px 18px;
  line-height: 1.5rem;
`;

const StyledMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  color: ${({ theme }) => theme.colors.gray78};
`;

const EmptyState = ({
  infoText,
  actionText,
  dataTour,
  onClick,
  desktopRedirect,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const buttons = [
    actionText && {
      title: actionText,
      isDisabled: false,
      onClick,
      default: true,
      icon: faPlus,
      dataTour,
    },
    desktopRedirect && {
      title: "Desktop View",
      icon: faMobile,
      onClick: () => {
        dispatch(screenResize(theme.breakpoints[2]));
        history.push(
          `${getSaleRoute(
            getSaleyardName(),
            getLivestockSaleId(),
          )}/${desktopRedirect}`,
        );
      },
      dataTour: "desktopView",
    },
  ].filter(Boolean);

  return (
    <Wrapper>
      <GlobalSearch />
      <StyledMessage>{infoText}</StyledMessage>
      {buttons && <MultiButton buttons={buttons} />}
    </Wrapper>
  );
};

EmptyState.propTypes = {
  infoText: PropTypes.any.isRequired,
  actionText: PropTypes.any,
  dataTour: PropTypes.string,
  onClick: PropTypes.func,
};

export default EmptyState;
