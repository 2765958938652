import React from "react";

import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { BoldText } from "components/Text";

import { getWeightRangeBySaleRoundId } from "selectors";

const { OptionTogglerField } = require("components/Form/FormikControls");

export const EstAvgWeightRangeField = ({
  name,
  disabled,
  saleRoundId,
  saleSubType,
}) => {
  const formik = useFormikContext();
  const { setFieldValue, values } = formik;
  const weightRanges = useSelector(getWeightRangeBySaleRoundId(saleRoundId));

  const mappedWeightRanges = weightRanges.map(range => {
    return {
      label: range.shortCode,
      value: range.id,
    };
  });

  const weightRangeToolTip = (
    <div>
      {weightRanges.map(range => {
        let toolTipText = `${range.shortCode} - ${range.details} - ${range.weightRangeMinKg}- ${range.weightRangeMaxKg}Kg`;
        if (!range.weightRangeMaxKg) {
          toolTipText = `${range.shortCode} - ${range.details} - ${range.weightRangeMinKg}+ Kg`;
        }
        return (
          <BoldText>
            {toolTipText}
            <br />
          </BoldText>
        );
      })}
    </div>
  );

  const currentWeightRange = weightRanges.find(
    range => range.id === values?.estimatedAverageWeightId,
  );

  const currentWeightRangeMatchesRound =
    currentWeightRange?.saleRoundId === values.sale_round_id;

  const currentWeightRangeMatchesSubType =
    currentWeightRange?.saleSubType === saleSubType;

  React.useEffect(() => {
    if (!currentWeightRangeMatchesRound) {
      setFieldValue("estimatedAverageWeightId", null);
    }
    if (!currentWeightRangeMatchesSubType) {
      setFieldValue("estimatedAverageWeightId", null);
    }
  }, [
    setFieldValue,
    currentWeightRangeMatchesRound,
    currentWeightRangeMatchesSubType,
  ]);

  if (mappedWeightRanges.length > 0) {
    return (
      <OptionTogglerField
        labelPosition="top"
        name={name}
        label="Est Avg Weight (+/- 2Kg)"
        options={mappedWeightRanges}
        disabled={disabled}
        tooltip={weightRangeToolTip}
      />
    );
  } else {
    return false;
  }
};
