import React from "react";

import CommentModal from "components/Comments/Modal/";

import { ModalTypes } from "constants/navigation";

function CommentsModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.Comments] || null;
  const { returnTo, commentType, commentTypeId, options } =
    JSON.parse(hashParams);

  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <CommentModal
      returnTo={decodedReturnTo}
      commentType={commentType}
      commentTypeId={commentTypeId}
      options={options}
    />
  );
}

export default CommentsModalAdapter;
