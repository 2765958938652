import React from "react";

import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { CommentAction } from "actions/comments";

import { ActionColumn } from "components/Comments/components/ActionColumn";
import { SingleCommentIcon } from "components/Comments/Icon";
import { Button } from "components/Form";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";
import { UserInitials } from "components/UserAvatar";

import { getInitialsFromUserInfo, getNameFromUserInfo } from "lib/auth";
import { dateTimeStringToDateTime, getTimeSince } from "lib/timeFormats";

import { getCommentById, getIsOnline } from "selectors";

const CommentDivider = styled.hr(
  ({ theme }) => `
  margin: ${theme.space[2]}px 0;

`,
);
const IconWrapper = styled(Row)(
  ({ theme }) => `
padding: ${theme.space[0]}px ${theme.space[4]}px ${theme.space[2]}px;
`,
);
const HeaderWrapper = styled(Row)(
  ({ theme }) => `
padding: ${theme.space[0]}px 0 ${theme.space[2]}px; 
`,
);
const CommentWrapper = styled.pre`
  overflow-x: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin: 0;
`;

function SingleComment({ commentId }) {
  const comment = useSelector(getCommentById(commentId));

  const isOnline = useSelector(getIsOnline);
  const {
    syncing,
    creatingUser,
    created,
    comment: commentText,
    resolvedDate,
    resolvingUser,
  } = comment || {};
  const actionsDisabled = !!(isOnline && syncing);

  const dispatch = useDispatch();
  if (!comment) {
    return null;
  }

  function unresolveComment() {
    dispatch(
      CommentAction.update({
        id: commentId,
        resolvedDate: null,
      }),
    );
  }

  function resolveComment() {
    dispatch(
      CommentAction.update({
        id: commentId,
        resolvedDate: new Date().toISOString(),
      }),
    );
  }

  return (
    <>
      <Paper>
        <Row fullWidth justifyBetween alignStretch>
          <Column fullWidth fullHeight padding={1} justifyBetween>
            <HeaderWrapper alignCenter fullWidth>
              <UserInitials
                initials={getInitialsFromUserInfo(creatingUser, "AN")}
                color="primary"
              />
              <Column>
                <span>
                  {getNameFromUserInfo(creatingUser, syncing, "AgriNous Bot")}
                </span>
                <span title={dateTimeStringToDateTime(resolvedDate)}>
                  {getTimeSince(created)} ago.
                </span>
              </Column>
            </HeaderWrapper>
            <Row
              alignStart
              padding={2}
              background="surfaceBackground"
              fullWidth
            >
              <CommentWrapper>{commentText}</CommentWrapper>
            </Row>
          </Column>
          <Row>
            <ActionColumn justifyBetween fullHeight padding={1} alignCenter>
              <IconWrapper>
                <SingleCommentIcon commentId={commentId} size="3x" />
              </IconWrapper>
              <Badge
                color="primary"
                badgeContent={
                  actionsDisabled ? (
                    <FontAwesomeIcon spin icon={faSpinnerThird} />
                  ) : null
                }
              >
                {resolvedDate ? (
                  <Button
                    onClick={unresolveComment}
                    disabled={actionsDisabled}
                    padding={2}
                  >
                    Unresolve
                  </Button>
                ) : (
                  <Button
                    onClick={resolveComment}
                    disabled={actionsDisabled}
                    padding={2}
                  >
                    Resolve
                  </Button>
                )}
              </Badge>
            </ActionColumn>
          </Row>
          <div style={{ flexGrow: 1 }} />
        </Row>

        {resolvedDate ? (
          <Row fullWidth justifyCenter padding={2}>
            <strong title={dateTimeStringToDateTime(resolvedDate)}>
              Resolved by {getNameFromUserInfo(resolvingUser)}{" "}
              {getTimeSince(resolvedDate)} ago.
            </strong>
          </Row>
        ) : null}
      </Paper>
      <CommentDivider />
    </>
  );
}

export default SingleComment;
