import React from "react";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

import { EditPenScanLotLotModal } from ".";

function EditPenScanLotModalAdapter(props) {
  const [hashParams, ignored, onClose] = useModalAdapter(
    ModalTypes.EditPenScanLot,
    props,
  );
  const { penScanLotId = null } = hashParams;

  return (
    <EditPenScanLotLotModal penScanLotId={penScanLotId} onClose={onClose} />
  );
}

export default EditPenScanLotModalAdapter;
