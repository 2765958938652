import reduceReducers from "reduce-reducers";

import { REPORT_JOB } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  reduceById,
  resetStateOnRoleChangeReducer,
} from "lib/reducers";
import { deserializeReportJob } from "lib/serializers/reportJob";

const fetchReducer = apiModelOfflineFetchReducer(REPORT_JOB, {
  deserializer: deserializeReportJob,
});

const extraReportJobsReducer = (state, action) => {
  switch (action.type) {
    case REPORT_JOB.CREATE_BULK.SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...reduceById(action.payload.map(deserializeReportJob)),
        },
      };
    case REPORT_JOB.PUSHER.ACTION:
      return { ...state, doPusherSubscribe: true };
    case REPORT_JOB.PUSHER.RESET:
      return { ...state, doPusherSubscribe: false };
    default:
      return state;
  }
};

const reportJobReducer = reduceReducers(
  fetchReducer,
  extraReportJobsReducer,
  resetStateOnRoleChangeReducer,
);

export default reportJobReducer;
