import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";

import { ModalTypes } from "constants/navigation";

import { openEditSaleLotModal, openModalLink } from "lib/navigation";

export const scanLotSaleLotLotNumbersRenderer = props => {
  const { value: saleLots } = props;

  if (saleLots) {
    return (
      <ActionButtonContainer>
        {saleLots.map(saleLot => {
          return (
            <ActionButton onClick={() => openEditSaleLotModal(saleLot.id)}>
              <FontAwesomeIcon icon={faPencil} />
              &nbsp;{saleLot.lot_number}
            </ActionButton>
          );
        })}
      </ActionButtonContainer>
    );
  }
  return null;
};

export const saleLotScanLotRenderer = props => {
  const { value: scanLots } = props;

  if (scanLots) {
    return (
      <ActionButtonContainer>
        {scanLots.map(scanLot => {
          const modalType = scanLot.receivalPenId
            ? ModalTypes.EditReceivalLot
            : ModalTypes.EditPenScanLot;

          const reference = scanLot.receivalPenId
            ? "receivalLotId"
            : "penScanLotId";

          return (
            <React.Fragment key={scanLot.id}>
              <ActionButton
                onClick={() =>
                  openModalLink(modalType, {
                    [reference]: scanLot.id,
                  })
                }
              >
                <FontAwesomeIcon icon={faPencil} />
                &nbsp;{scanLot.title}
              </ActionButton>
            </React.Fragment>
          );
        })}
      </ActionButtonContainer>
    );
  }
  return null;
};

export const singleScanLotRenderer = props => {
  const { value: scanLot } = props;

  if (!scanLot) {
    return null;
  }

  const modalType = scanLot.receivalPenId
    ? ModalTypes.EditReceivalLot
    : ModalTypes.EditPenScanLot;

  const reference = scanLot.receivalPenId ? "receivalLotId" : "penScanLotId";

  return (
    <ActionButtonContainer>
      <ActionButton
        onClick={() =>
          openModalLink(modalType, {
            [reference]: scanLot.id,
          })
        }
      >
        <FontAwesomeIcon icon={faPencil} />
        &nbsp;{scanLot.title}
      </ActionButton>
    </ActionButtonContainer>
  );
};

export const singleSaleLotLotNumbersRenderer = props => {
  const { value: saleLot } = props;
  if (saleLot) {
    return (
      <ActionButtonContainer>
        <ActionButton onClick={() => openEditSaleLotModal(saleLot.id)}>
          <FontAwesomeIcon icon={faPencil} />
          &nbsp;{saleLot.lot_number}
        </ActionButton>
      </ActionButtonContainer>
    );
  }
  return null;
};
