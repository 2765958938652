import React, { useEffect } from "react";

import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { differenceInCalendarDays } from "date-fns";
import { getIn, useFormikContext } from "formik";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { ContactBillingDocumentAction } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { SubtleBadge } from "components/Badge";
import { withNamespace } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { LinkFileTypeDownload } from "components/Link";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Text } from "components/Text";

import { AgGridTables } from "constants/aggrid";
import { BillingDocumentStatus } from "constants/billingDocuments";
import {
  BillingDocumentColId,
  BillingDocumentColumnDef,
  LivestockSalesColId,
  LivestockSalesColumnDef,
} from "constants/columnDefinitions";
import { ApiModel } from "constants/loading";

import {
  getFinancialStatementReportUrl,
  getFinancialStatementZipReportUrl,
  getTransactionListUrl,
} from "lib/navigation";

import {
  getActiveRole,
  selectContactBillingDocumentsAggridData,
} from "selectors";

import { useLoadAllSales } from "hooks/useLoadAllSales";

import BusinessTransactionsTableForm from "./BusinessTransactionsTableForm";

export function BusinessTransactionsHeader() {
  return (
    <Row>
      <SubtleBadge>Transactions</SubtleBadge>
    </Row>
  );
}

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: "agMultiColumnFilter",
  enableCellChangeFlash: true,
  floatingFilter: false,
  flex: true,
};

export const contactBillingTableColumnDefs = [
  {
    headerName: "Sale(s)",
    children: [
      LivestockSalesColumnDef[LivestockSalesColId.SALE_DATES],
      LivestockSalesColumnDef[LivestockSalesColId.SALE_CODES],
      LivestockSalesColumnDef[LivestockSalesColId.SALE_TITLES],
    ],
  },
  {
    headerName: "Documents",
    width: "100%",
    children: [
      BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_TYPE],
      BillingDocumentColumnDef[BillingDocumentColId.STATUS],
      BillingDocumentColumnDef[BillingDocumentColId.DUE_DATE],
      BillingDocumentColumnDef[BillingDocumentColId.TOTAL_AMOUNT_DOLLARS],
      BillingDocumentColumnDef[BillingDocumentColId.TOTAL_TAX_AMOUNT_DOLLARS],
      BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_DOWNLOAD],
      BillingDocumentColumnDef[BillingDocumentColId.ACTIVITY],
    ],
  },
];

const rowSelectionId = "billingDocument.id";
const getRowId = params => get(params.data, rowSelectionId);

export function BusinessTransactionsTable(props) {
  const { masterBusinessId, namespace: ns } = props;
  const dispatch = useDispatch();

  useLoadAllSales();

  useEffect(() => {
    dispatch(
      ContactBillingDocumentAction.request({
        contactBusinessId: masterBusinessId,
        statuses: [
          BillingDocumentStatus.APPROVED,
          BillingDocumentStatus.SENT,
          BillingDocumentStatus.PAID,
          BillingDocumentStatus.NEEDS_ATTENTION,
          BillingDocumentStatus.EXPORTED,
          BillingDocumentStatus.EXPORTING,
        ].join(","),
      }),
    );
  }, [dispatch, masterBusinessId]);

  const userRole = useSelector(getActiveRole);
  const rowData = useSelector(selectContactBillingDocumentsAggridData);
  const formikContext = useFormikContext();
  const { setValues, values } = formikContext;

  const updateDates = (startDate, endDate) => {
    const updates = {
      startDate,
      endDate,
    };
    setValues({
      ...values,
      editingValue: { ...updates },
    });
  };

  const dates = getIn(values, withNamespace(ns, "editingValue"));
  const { startDate = null, endDate = null } = dates || {};
  const showDownloadButton = !!startDate && !!endDate;
  const dateRangeTooLarge =
    differenceInCalendarDays(new Date(endDate), new Date(startDate)) >= 366;

  return (
    <Grid container spacing={2} alignItems="flex-end" p={2}>
      <Grid item xs={12}>
        <Text>
          Download a PDF Transaction List, or Statement for this business by
          date range
        </Text>
      </Grid>
      <BusinessTransactionsTableForm
        namespace={withNamespace(ns, "editingValue")}
        updateDates={updateDates}
      />
      <Grid item xs={2} style={{ marginBottom: 5 }}>
        {showDownloadButton &&
          userRole &&
          (dateRangeTooLarge ? (
            <Text error>Date range exceeds 1 year</Text>
          ) : (
            <Row>
              <LinkFileTypeDownload
                url={getTransactionListUrl({
                  startDate,
                  endDate,
                  masterBusinessId,
                  userRole,
                })}
                id="transactions-list-report"
                data-tour="download-transactions-list-report"
                text="Transactions"
                fileType="pdf"
              />
              <Column paddingVertical={1}>
                <LinkFileTypeDownload
                  url={getFinancialStatementReportUrl({
                    startDate,
                    endDate,
                    masterBusinessId,
                    userRole,
                  })}
                  id="financial-statement-report"
                  data-tour="download-financial-statement-report"
                  text="Financial Statement (PDF)"
                  fileType="pdf"
                />
                <LinkFileTypeDownload
                  url={getFinancialStatementZipReportUrl({
                    startDate,
                    endDate,
                    masterBusinessId,
                    userRole,
                  })}
                  id="financial-statement-zip-report"
                  data-tour="download-financial-statement-zip-report"
                  text="Financial Statement (Zip)"
                  fileType="zip"
                />
              </Column>
            </Row>
          ))}
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <WaitForSync
          requiredData={[ApiModel.SALES, ApiModel.BILLING_DOCUMENTS]}
        >
          <AgGridTable
            columnDefs={contactBillingTableColumnDefs}
            getRowId={getRowId}
            rowData={rowData}
            showGlobalSearchFilter={false}
            hideHeader
            WrapperComponent={CollapseTableWrapper}
            defaultColDef={defaultColDef}
            tableName={AgGridTables.CONTACT_BILLING_DOCUMENTS}
            paginationPageSize={10}
            context={{ userRoleSlug: userRole.slug }}
          />
        </WaitForSync>
      </Grid>
    </Grid>
  );
}
