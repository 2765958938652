import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { getSellingNowByPriceSaleLot } from "selectors";

import SaleLotCard from "./SaleLotCard";
import "./transitions.css";

const SellingNow = ({ largeImages }) => {
  const saleLot = useSelector(getSellingNowByPriceSaleLot);
  if (!saleLot) {
    return (
      <Card raised>
        <CardContent>Nothing selling yet.</CardContent>
      </Card>
    );
  }
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={saleLot.id}
        timeout={2000}
        appear
        classNames="sellingNow"
      >
        <Container key={saleLot.id}>
          <SaleLotCard saleLot={saleLot} largeImages={largeImages} />
        </Container>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default SellingNow;
