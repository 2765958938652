import React from "react";

import { get, isEmpty, isEqual } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Tooltip } from "components/Form/FormikControls/Tooltip";

import { AlternativeComparableFields } from "constants/businesses";
import { Settings } from "constants/settings";

import { formatAllAddressFields } from "lib/address";
import { pluralize } from "lib/pluralize";
import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

import { getFilteredAlternativesByBusinessId, getSetting } from "selectors";

const StyledAlternativeCell = styled.td(
  ({ selected, theme }) => `
    background-color: ${
      selected ? `${theme.colors.warningOrange}50` : `${theme.colors.gray85}90`
    };
    cursor: pointer;
`,
);

const CurrentValueCell = styled.td`
  border-right: 1px solid black;
  cursor: pointer;
`;

const getFieldValue = (obj, field, defaultValue = "Not Set") => {
  const value = get(obj, field);
  if (value && field === "address") {
    return formatAllAddressFields(value);
  }
  if (value === undefined || value === null) {
    return defaultValue;
  }
  return value;
};

const groupByValueWithNewestLastModified = (alternatives, field) => {
  return alternatives.reduce((acc, alt) => {
    const value = getFieldValue(alt, field);

    if (value in acc) {
      acc[value].count += 1;
    } else {
      acc[value] = {
        ...alt,
        count: 1,
      };
    }

    // Use the most recent value for last modified
    if (acc[value].lastModified < alt.lastModified) {
      acc[value] = {
        ...acc[value],
        ...alt,
      };
    }
    return acc;
  }, {});
};

const timeIntervals = [31536000, 2628000, 604800, 86400, 3600, 60, 1];
const intervalNames = [
  "year",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
];

const TimeDifference = ({ base, relative }) => {
  if (!(base instanceof Date && relative instanceof Date)) {
    throw new RangeError("Invalid date arguments");
  }

  const baseTime = base.getTime();
  const relativeTime = relative.getTime();
  if (relativeTime === 0) {
    return "-";
  }
  const diff = baseTime - relativeTime;
  const diffSeconds = Math.abs(diff) / 1000;

  const index = timeIntervals.findIndex(i => diffSeconds / i >= 1);
  if (index === -1) {
    return "-";
  }
  const n = Math.floor(diffSeconds / timeIntervals[index]);
  const interval = intervalNames[index];

  const dateTense = diff > 0 ? "(older)" : "(newer)";

  return (
    <Tooltip title={formatUTCToLocalDateTimeString(relative)}>
      <div className={diff < 0 ? "highlight" : ""}>
        {`${n} ${pluralize(interval, n)} ${dateTense}`}
      </div>
    </Tooltip>
  );
};

const ChangeTableBodyComponent = ({ businessId, dispatchUpdates, updates }) => {
  const business = useSelector(getFilteredAlternativesByBusinessId(businessId));

  const filteredFieldValues = useSelector(
    getSetting(Settings.resolveAlternatives.filteredFields),
  );

  const filterEmptyValues = useSelector(
    getSetting(Settings.resolveAlternatives.filterEmptyValues),
  );

  return (
    <tbody>
      {business.outOfSyncFields
        .filter(fieldName => filteredFieldValues.includes(fieldName))
        .map(fieldName => {
          const currentSelected = updates?.[fieldName];
          const currentValue = getFieldValue(business, fieldName);
          const groupedAlternatives = Object.values(
            groupByValueWithNewestLastModified(
              business.alternatives,
              fieldName,
            ),
          );

          return (
            <React.Fragment key={fieldName}>
              {groupedAlternatives.map((altBusiness, altIndex) => {
                const altSelected = isEqual(
                  currentSelected,
                  altBusiness[fieldName],
                );

                const setAlternative = () => {
                  dispatchUpdates({
                    businessId: business.id,
                    field: fieldName,
                    value: altBusiness[fieldName],
                  });
                };

                const setMine = () => {
                  dispatchUpdates({
                    businessId: business.id,
                    field: fieldName,
                    value: undefined,
                  });
                };

                const altValue = getFieldValue(altBusiness, fieldName);

                if (filterEmptyValues && isEmpty(altValue)) {
                  return;
                }

                return (
                  <tr
                    className={
                      altIndex === groupedAlternatives.length - 1
                        ? "border-bottom-black"
                        : ""
                    }
                    key={altBusiness.source.id}
                  >
                    {altIndex === 0 && (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <>
                        <CurrentValueCell
                          rowSpan={groupedAlternatives.length}
                          onClick={setMine}
                        >
                          <b>
                            {get(
                              AlternativeComparableFields,
                              fieldName,
                              fieldName,
                            )}
                          </b>
                        </CurrentValueCell>

                        <CurrentValueCell
                          rowSpan={groupedAlternatives.length}
                          onClick={setMine}
                        >
                          {currentValue}
                        </CurrentValueCell>
                      </>
                    )}
                    <StyledAlternativeCell
                      selected={altSelected}
                      onClick={setAlternative}
                    >
                      <input type="checkbox" checked={altSelected} readOnly />
                      {getFieldValue(altBusiness, fieldName)}

                      {currentValue === altValue && (
                        <Tooltip title="Inspect the business to view these complicated differences." />
                      )}
                    </StyledAlternativeCell>
                    <StyledAlternativeCell
                      selected={altSelected}
                      onClick={setAlternative}
                    >
                      {altBusiness.sourceName}{" "}
                      {altBusiness.count > 1
                        ? `+ ${altBusiness.count - 1} More`
                        : ""}
                    </StyledAlternativeCell>

                    <StyledAlternativeCell
                      selected={altSelected}
                      onClick={setAlternative}
                    >
                      <TimeDifference
                        base={business.lastModified}
                        relative={altBusiness.lastModified}
                      />
                    </StyledAlternativeCell>
                  </tr>
                );
              })}
            </React.Fragment>
          );
        })}
    </tbody>
  );
};

export const ChangeTableBody = React.memo(ChangeTableBodyComponent);
