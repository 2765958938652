import React from "react";

import flatten from "lodash/flatten";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import { startCase } from "lodash/string";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Column, Row } from "components/Layout";
import MarkingInfo from "components/MarkingInfo";

import { firstIfAllEqual } from "lib";

import { getCombinedLotNumber } from "lib/saleLot";

import {
  getAges,
  getBusinesses,
  getConsignments,
  getSaleLots,
  getSexes,
  selectAreStudFeaturesEnabled,
} from "selectors";

import { TagWrapper } from "./SaleLotSummaryCard";

const Container = styled(Column)(
  ({ theme }) => `
  flex: 1;
  box-shadow: ${theme.shadows[1]};
  display: flex;
  background: white;
  height: 98px;
  padding: ${theme.space[2]}px;
  overflow: hidden;
`,
);

const MarkingContainer = styled(Row)`
  align-items: center;
  flex: 1;
`;

const getSaleLotDescription = (saleLots, ageLookup, sexLookup) => {
  const speciesInfo = saleLots.map(saleLot =>
    startCase(
      `${sexLookup[saleLot.sex_id]?.name || ""} ${
        ageLookup[saleLot.age_id]?.name || ""
      }`,
    ),
  );
  return firstIfAllEqual(speciesInfo, "Mixed");
};

const MAX_MARKS = 6;

const VendorText = styled.div`
  font-size: 1.5rem;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  font-size: 1.5rem;
  flex: 0;
  justify-content: flex-end;
`;

const SmallText = styled.div`
  font-size: 0.7rem;
  display: flex;
  flex: 1;
  align-items: center;
`;

const SaleLotSummary = ({ saleLotIds }) => {
  const saleLotsByIdLookup = useSelector(getSaleLots);
  const consignmentByIdLookup = useSelector(getConsignments);
  const businessByIdLookup = useSelector(getBusinesses);
  const sexByIdLookup = useSelector(getSexes);
  const ageByIdLookup = useSelector(getAges);

  const saleLots = saleLotIds
    .map(saleLotId => saleLotsByIdLookup[saleLotId])
    // Remove nulls - can happen if a salelot gets deleted while we are looking at it.
    .filter(Boolean);

  const consignments = saleLots.map(
    saleLot => consignmentByIdLookup[saleLot.consignment_id],
  );
  const vendors = consignments.map(
    consignment => businessByIdLookup[consignment.vendor_id],
  );

  const uniqueVendors = uniq(vendors);
  const vendorCount = uniqueVendors.length;

  const uniqueMarks = uniqBy(
    flatten(saleLots.map(s => s.marks)),
    "location",
  ).filter(Boolean);
  const sortedMarks = orderBy(uniqueMarks, ["location", "color"]);
  const numMarksToShow =
    sortedMarks.length > MAX_MARKS ? MAX_MARKS - 1 : MAX_MARKS;

  const vendorName =
    vendorCount === 1 ? uniqueVendors[0].name : `${vendorCount} Vendors`;

  const vendorNumber =
    vendorCount === 1 ? consignments[0].vendorNumber : `${vendorCount} Vendors`;

  const lotNumber = saleLots.map(s => getCombinedLotNumber(s)).join(", ");

  const areStudFeaturesEnabled = useSelector(selectAreStudFeaturesEnabled);

  const tagNumber =
    saleLots.length === 1 ? saleLots[0]?.draftingAttributes?.tagNumber : null;

  return (
    <Container data-tour="vendorDetails" justifyBetween alignCenter full>
      <Row fullWidth>
        <SmallText>Ven. #{vendorNumber}</SmallText>
        <SmallText style={{ justifyContent: "flex-end" }}>
          Lot {lotNumber}
        </SmallText>
      </Row>

      <Row fullWidth justifyBetween alignCenter>
        <Column>
          <VendorText>{vendorName}</VendorText>
          {areStudFeaturesEnabled && tagNumber && (
            <TagWrapper>Tag # {tagNumber} </TagWrapper>
          )}
          {sortedMarks.length > 0 && (
            <MarkingContainer>
              {sortedMarks.slice(0, numMarksToShow).map(mark => (
                <MarkingInfo
                  key={`${mark.color}-${mark.location}`}
                  color={mark.color}
                  location={mark.location}
                />
              ))}
              {sortedMarks.length > MAX_MARKS && (
                <div>+{sortedMarks.length - MAX_MARKS + 1} more</div>
              )}
            </MarkingContainer>
          )}
        </Column>
        <Description>
          {getSaleLotDescription(saleLots, ageByIdLookup, sexByIdLookup)}
        </Description>
      </Row>
    </Container>
  );
};

SaleLotSummary.propTypes = {
  saleLotIds: PropTypes.array.isRequired,
};

export default React.memo(SaleLotSummary, (a, b) =>
  isEqual(a.saleLotIds, b.saleLotIds),
);
