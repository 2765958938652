import { GET_AGENCIES } from "constants/actionTypes";

import { apiModelReducer } from "lib/reducers";
import { deserializeAgency } from "lib/serializers/agencies";

const agencies = apiModelReducer("agencies", GET_AGENCIES, {
  clearOnRequest: true,
  deserializer: deserializeAgency,
  includeOrder: false,
});

export default agencies;
