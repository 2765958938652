import React, { memo } from "react";

import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import groupBy from "lodash/groupBy";
import { useSelector } from "react-redux";

import MultiButton from "components/Button/MultiButton";
import { StatusCard } from "components/Card";
import { CardGrid, CardMetaItem } from "components/Card/Elements";
import Property from "components/Property";

import { ForSaleyardAdmin } from "containers/ForUserType";

import { pluralize } from "lib/pluralize";

import { getBusinessById, getNameByDeploymentId } from "selectors";

import { DuplicateAttributeType } from "./util";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
}));

function EmailPopout(props) {
  const { duplicateEmails } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  function onMouseEnterSummary(event) {
    if (duplicateEmails.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  }

  function closePopover() {
    setAnchorEl(null);
  }

  const groupedEmails = groupBy(
    duplicateEmails,
    duplicateEmail => duplicateEmail.value,
  );
  const uniqueEmailCount = Object.keys(groupedEmails).length;

  const isOpen = Boolean(anchorEl);

  return (
    <div>
      <span onMouseEnter={onMouseEnterSummary} onMouseLeave={closePopover}>
        {uniqueEmailCount} Matching {pluralize("Email", uniqueEmailCount)}
      </span>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        className={classes.popover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={closePopover}
        disableRestoreFocus
      >
        <ol>
          {Object.entries(groupedEmails).map(
            ([email, allDuplicates], index) => (
              <li key={index}>
                {email} ({allDuplicates.length} Agenc
                {allDuplicates.length === 1 ? "y" : "ies"})
              </li>
            ),
          )}
        </ol>
      </Popover>
    </div>
  );
}

function PicLi(props) {
  const { agencyCount, propertyId } = props;

  return (
    <li>
      <Property propertyId={propertyId} /> ({agencyCount}{" "}
      {pluralize("Agency", agencyCount)}
    </li>
  );
}
function PicPopout(props) {
  const { duplicatePics } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  function onMouseEnterSummary(event) {
    if (duplicatePics.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  }

  function closePopover() {
    setAnchorEl(null);
  }

  const groupedPics = groupBy(
    duplicatePics,
    duplicatePic => duplicatePic.value,
  );
  const uniquePicCount = Object.keys(groupedPics).length;

  const isOpen = Boolean(anchorEl);

  return (
    <div>
      <span onMouseEnter={onMouseEnterSummary} onMouseLeave={closePopover}>
        {uniquePicCount} Matching {pluralize("PIC", uniquePicCount)}
      </span>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        className={classes.popover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={closePopover}
        disableRestoreFocus
      >
        <ol>
          {Object.entries(groupedPics).map(
            ([propertyId, allDuplicates], index) => (
              <PicLi
                key={index}
                propertyId={propertyId}
                agencyCount={allDuplicates.length}
              />
            ),
          )}
        </ol>
      </Popover>
    </div>
  );
}

function DuplicateBusinessSuggestionComponent(props) {
  const {
    businessId,
    dismissDuplicate,
    duplicateValues,
    mergeIntoBusinessId,
    reviewDuplicate,
  } = props;

  const relatedDeploymentBusinessIds = duplicateValues
    .map(duplicateValue => duplicateValue.deploymentBusinessId)
    .filter(Boolean);

  const deploymentBusinessId =
    relatedDeploymentBusinessIds.length === 1
      ? relatedDeploymentBusinessIds[0]
      : null;
  const mergeIntoBusiness =
    useSelector(getBusinessById(mergeIntoBusinessId)) || {};

  let deploymentBusiness =
    useSelector(getBusinessById(deploymentBusinessId)) || {};

  const primaryDeploymentBusiness =
    useSelector(getBusinessById(businessId)) || {};
  if (
    deploymentBusinessId &&
    primaryDeploymentBusiness.deployment_business_cbid === deploymentBusinessId
  ) {
    deploymentBusiness = primaryDeploymentBusiness;
  }

  const deploymentName = useSelector(
    getNameByDeploymentId(deploymentBusiness.deployment_id),
  );

  // There is a scenario where a duplicate business suggestion may not be in the state
  // This happens when the duplicate MasterBusiness is from and Saleyard that the current UserRole has access to
  // i.e. A user 1 has access to Saleyard 1 and Saleyard 2, they are currently looking at Saleyard 1 and the suggestion is for a Deployment in Saleyard 2.
  // By rights they have access to it,however the local state only requests and stores DeploymentBusinesses from the Deployments in the current Saleyard.
  const isMasterBusinessAvailable = Boolean(
    (deploymentBusiness.id || primaryDeploymentBusiness.id) &&
      mergeIntoBusiness.id,
  );

  const name = deploymentBusinessId
    ? deploymentBusiness.name
    : primaryDeploymentBusiness.name;

  const duplicateValuesByType = groupBy(duplicateValues, "type");

  const similarAbns = duplicateValuesByType[DuplicateAttributeType.ABN] || [];
  const similarAuctionsPlusShortCode =
    duplicateValuesByType[DuplicateAttributeType.AUCTION_PLUS_SHORT_CODE] || [];
  const similarEmails =
    duplicateValuesByType[DuplicateAttributeType.EMAIL_RECIPIENT] || [];
  const similarAddresses =
    duplicateValuesByType[DuplicateAttributeType.GOOGLE_MAPS_PLACE_ID] || [];
  const similarNames = duplicateValuesByType[DuplicateAttributeType.NAME] || [];
  const similarPics =
    duplicateValuesByType[DuplicateAttributeType.PROPERTY] || [];

  const matchingValues = [
    similarAbns.length > 0 && "ABN",
    similarAuctionsPlusShortCode.length > 0 && "AuctionsPlus Short Code",
    similarAddresses.length > 0 && "Address",
    similarNames.length > 0 && "Business Name",
  ].filter(Boolean);

  function onClickReviewDuplicate() {
    reviewDuplicate(mergeIntoBusinessId, businessId);
  }

  function onClickDismissDuplicate() {
    dismissDuplicate(mergeIntoBusinessId, businessId);
  }

  return (
    <StatusCard>
      <CardGrid
        areas={[
          ["businessName", "businessName", "agency", "actions"],
          ["matches", "matches", "emails", "pics"],
        ]}
      >
        <CardMetaItem gridArea="businessName" left start>
          {isMasterBusinessAvailable ? (
            name
          ) : (
            <i>Business not currently available</i>
          )}
        </CardMetaItem>

        <CardMetaItem gridArea="agency" left start>
          <ForSaleyardAdmin>
            {relatedDeploymentBusinessIds.length > 1
              ? ` ${relatedDeploymentBusinessIds.length} Agencies`
              : relatedDeploymentBusinessIds.length > 0
                ? ` ${deploymentName}`
                : ""}
          </ForSaleyardAdmin>
        </CardMetaItem>
        <CardMetaItem gridArea="actions" right start>
          <MultiButton
            buttons={[
              isMasterBusinessAvailable && {
                onClick: onClickReviewDuplicate,
                title: "Review",
              },
              {
                onClick: onClickDismissDuplicate,
                title: "Dismiss",
              },
            ].filter(Boolean)}
          />
        </CardMetaItem>
        <CardMetaItem gridArea="matches">
          {matchingValues.length > 0 && (
            <div>Matching {matchingValues.join(", ")}</div>
          )}
        </CardMetaItem>
        <CardMetaItem gridArea="emails">
          <EmailPopout duplicateEmails={similarEmails} />
        </CardMetaItem>
        <CardMetaItem gridArea="pics">
          <PicPopout duplicatePics={similarPics} />
        </CardMetaItem>
      </CardGrid>
    </StatusCard>
  );
}
export default memo(DuplicateBusinessSuggestionComponent);
