import { HubMessageEvent } from "./HubMessageEvent";
import {
  HubConnection,
  HubRequest,
  OnConnectionEstablishedCallback,
  OnConnectionFailedCallback,
} from "./HubConnection";

declare global {
  interface Window {
    webkit: any;
    hubConnection: any;
  }
}
/**
 * Provides Bluetooth Hub connectivity via WebKit on iOS.
 *
 * WebKit can register WKScriptMessageHandler objects on the `window.webkit` object.
 * These message handlers expose a `postMessage()` function that can be called to
 * communicate with the iOS app hosting the WKWebView that the PWA is running in.
 *
 * IOSHubConnection has access to two message handlers:
 * - `_connectionHandler`: Sends connection messages to iOS.
 * - `_messageHandler`: Sends bluetooth hub messages to iOS.
 *
 * IOSHubConnection is called from iOS via
 * - `didConnect` - handles connection responses from iOS
 * - `receiveMessage` - handles bluetooth messages from iOS
 */
export class IOSHubConnection extends HubConnection {
  private _connectionHandler: any;

  private _messageHandler: any;

  private _isConnecting: boolean;

  private _isConnected: boolean;

  private onConnectionEstablished: OnConnectionEstablishedCallback;

  private onConnectionFailed: OnConnectionFailedCallback;

  private _supportsPing: boolean = false;

  constructor(window: Window, minVersion: string) {
    super(minVersion);
    const hub = window;
    if (hub.webkit && hub.webkit.messageHandlers) {
      this._connectionHandler = window.webkit.messageHandlers.connect;
      this._messageHandler = window.webkit.messageHandlers.hub;
    }
    hub.hubConnection = this;
    this._isConnecting = false;
    this._isConnected = false;
  }

  public get supportedFeatures() {
    return this._supportedFeatures;
  }

  public isConnected() {
    return this._isConnected;
  }

  public submitRequest(
    request: HubRequest,
    onSuccess: () => void,
    onError: (message: string) => void,
  ): void {
    const messageHandler = this._messageHandler;
    function onConnectionEstablished() {
      messageHandler.postMessage(request);
      if (onSuccess) {
        onSuccess();
      }
    }
    this.ensureConnectionEstablished(onConnectionEstablished, onError);
  }

  public ensureConnectionEstablished(
    onConnectionEstablished: OnConnectionEstablishedCallback,
    onConnectionFailed: OnConnectionFailedCallback,
  ): void {
    if (this.isConnected()) {
      onConnectionEstablished();
      return;
    }

    function failureHandler(message: string) {
      const failureEvent = new HubMessageEvent("connectionFailed");
      failureEvent.data = message;
      this.dispatchEvent(failureEvent);
      if (onConnectionFailed) {
        onConnectionFailed(message);
      }
    }
    this.onConnectionEstablished = onConnectionEstablished;
    this.onConnectionFailed = failureHandler;
    this.connect();
  }

  private connect() {
    this._isConnecting = true;
    this._connectionHandler.postMessage("open");
  }

  public didConnect(welcomeMessage: string) {
    this._isConnecting = false;
    const hubInfo = JSON.parse(welcomeMessage);
    if (hubInfo && hubInfo.object && hubInfo.object.AgriNousHub) {
      this._connectedVersion = hubInfo.object.AgriNousHub.version;
      this._supportedFeatures = hubInfo.object.AgriNousHub.supportedFeatures;

      if (this._connectedVersion > Number(this.minVersion)) {
        this._isConnected = true;
        this.dispatchEvent(new HubMessageEvent("connected"));
        if (this.onConnectionEstablished) {
          this.onConnectionEstablished();
          this.onConnectionEstablished = null;
        }
      } else {
        this.onConnectionFailed(
          "Please update AgriNous iOS via the App Store.",
        );
        this.onConnectionFailed = null;
      }
    } else if (this.onConnectionFailed) {
      this.onConnectionFailed("Incorrect Welcome");
      this.onConnectionFailed = null;
    }
  }

  public disconnect(): void {
    this._isConnected = false;
    this.dispatchEvent(new HubMessageEvent("closed"));
  }

  public doesHubSupportPing(): boolean {
    return this._supportsPing;
  }

  public isConnecting(): boolean {
    return this._isConnecting;
  }

  public receiveMessage(message: string) {
    const receivedJSON = JSON.parse(message);
    const dataEvent = new HubMessageEvent("data");
    dataEvent.data = receivedJSON;
    this.dispatchEvent(dataEvent);
  }
}
