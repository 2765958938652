import React from "react";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { requestNLISSignUp } from "actions";

import { NLISForm, nlisValidationSchema } from "components/NlisForm";

import { NLISCredentialType } from "constants/nlis";

import {
  getActiveNLISAgentCredentials,
  getIsFetchingAuth,
  getIsFetchingNLISAuth,
} from "selectors";

export const AgentCredentials = () => {
  const nlisCredentials = useSelector(getActiveNLISAgentCredentials) || {};

  const isFetching = useSelector(
    state => getIsFetchingAuth(state) || getIsFetchingNLISAuth(state),
  );
  const dispatch = useDispatch();

  const onSubmit = values =>
    dispatch(
      requestNLISSignUp({
        ...values,
        credentialType: NLISCredentialType.AGENT,
      }),
    );

  const { nlis_user, nlis_email, nlis_saleyard_id } = nlisCredentials;

  const initialValues = {
    nlis_user,
    nlis_email,
    nlis_saleyard_id,
    nlis_password: "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={nlisValidationSchema}
      onSubmit={onSubmit}
    >
      <NLISForm
        isFetching={isFetching}
        credentialType={NLISCredentialType.AGENT}
      />
    </Formik>
  );
};
