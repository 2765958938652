import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const serializeMap = {
  billingReference: "billing_reference",
  category: "category",
  description: "description",
  documentId: "document_id",
  glCode: "gl_code",
  id: "id",
  includeIfZero: "include_if_zero",
  invoiceCategory: "invoice_category",
  isModified: "is_modified",
  itemId: "item_id",
  itemType: "item_type",
  // mincenousLedgerEntry: "mincenous_ledger_entry",
  mincenousId: "mincenous_id",
  notes: "notes",
  quantityOutputFormat: "quantity_output_format",
  quantityRawFormat: "quantity_raw_format",
  unitAmountOutputFormat: "unit_amount_output_format",
  unitAmountRawFormat: "unit_amount_raw_format",
  totalCents: "total_cents",
  // originalCategory: "original_category",
  // originalDescription: "original_description",
  // originalGlCode: "original_gl_code",
  // originalInvoiceCategory: "original_invoice_category",
  // originalInvoiceId: "original_invoice_id",
  // originalQuantity: "original_quantity",
  // originalTaxAmount: "original_tax_amount",
  // originalTaxType: "original_tax_type",
  // originalTotal: "original_total",
  // originalUnitAmount: "original_unit_amount",
  quantity: "quantity",
  ruleId: "rule_id",
  ruleName: "rule_name",
  status: "status",
  subtotal: "subtotal",
  tagIds: "tag_ids",
  taxAmount: "tax_amount",
  taxType: "tax_type",
  unitAmount: "unit_amount",
};

export const serializeLedgerEntry = buildSerializer(
  serializeMap,
  "ledgerEntry",
);

export const deserializeLedgerEntry = buildDeserializer(
  serializeMap,
  "ledgerEntry",
);
