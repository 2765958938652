import React from "react";

import { ModalTypes } from "constants/navigation";

import { EditBusiness } from "containers/Businesses/EditBusiness";

export function EditBusinessAdapter(props) {
  const { hash = {} } = props;
  const { businessId, returnTo, defaultTab, defaultAddressSearch } = JSON.parse(
    hash[ModalTypes.EditBusiness],
  );

  let parsedReturnTo;

  if (returnTo) {
    parsedReturnTo = decodeURIComponent(returnTo);
  } else if (hash.returnTo) {
    parsedReturnTo = atob(hash.returnTo);
  }

  return (
    <EditBusiness
      businessId={businessId}
      returnTo={parsedReturnTo}
      defaultTab={defaultTab}
      defaultAddressSearch={defaultAddressSearch}
    />
  );
}
