import React from "react";

import { faFilter, faPrint } from "@fortawesome/free-solid-svg-icons";
import { faCircle as faCaretRightOutline } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle as faCaretRightSolid } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  BusinessAction,
  closeConfirmModal,
  openConfirmModal,
  patchBidderRegistrationOffline,
  setSetting,
} from "actions";

import Badge from "components/Badge";
import { MultiButton, SlimButton } from "components/Button";
import { StatusCard } from "components/Card";
import {
  CardGrid,
  CardMetaItem,
  DetailsBlock,
  Mandoline,
  PrimaryHeading,
  SelfTitledField,
  SyncingStatus,
} from "components/Card/Elements";
import { SmallFaIcon } from "components/FaIcon";
import { Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { StatusText } from "components/Text";

import {
  RegisteredBusinessStatus,
  RegisteredBusinessStatusColourMap,
  RegisteredBusinessStatustTitleMap,
} from "constants/bidders";
import { ApiModel } from "constants/loading";
import { BusinessModalSection } from "constants/navigation";
import { Settings } from "constants/settings";

import { AddressDisplayFormat, formatAddress } from "lib/address";
import {
  getLivestockSaleId,
  getSaleyardName,
  openBuyerCards,
  openEditBidderRegistrationModal,
  openEditBusinessModal,
} from "lib/navigation";

import { getActiveRole, getBidderById, getBusinessById } from "selectors";

const BidderStatusCard = styled(StatusCard)(
  ({ theme }) =>
    `padding: 0px ${theme.space[2]}px ${theme.space[2]}px ${theme.space[2]}px;`,
);
const BidderNumber = styled(PrimaryHeading).attrs({ color: "primary" })(
  ({ theme }) => `
  font-size: ${theme.fontSizes.epsilon}px;
  line-height: ${theme.fontSizes.epsilon}px;
`,
);
const TitleRow = styled(CardGrid)(
  ({ theme }) => `
  grid-template-columns: 70px 1fr 90px auto;
  grid-template-rows: minmax(${theme.space[7]}px, 1fr);
  grid-template-areas: "bidder-number bidder-name sync-status edit-action";
`,
);
const DetailsRow = styled(CardGrid)(
  ({ theme }) => `
  grid-template-columns: 1fr 100px;
  grid-template-rows: ${theme.space[5]}px;
  grid-template-areas: "details status"; 
`,
);

const BusinessAddressHeader = styled.strong`
  cursor: pointer;
`;

function getDeliveryAddress(bidderRegistration) {
  // If we have an actual serialized deliveryAddress, use that.
  if (bidderRegistration.deliveryAddress) {
    return formatAddress(
      bidderRegistration.deliveryAddress,
      AddressDisplayFormat.FULL,
    );
  }
  // Otherwise, if we have an old free text one, show that.

  const { provisionalData = {} } = bidderRegistration;
  return provisionalData.delivery_details || "None Entered";
}

function getPostalAddress(bidderRegistration) {
  // If we have an actual serialized deliveryAddress, use that.
  if (bidderRegistration.postalAddress) {
    return formatAddress(
      bidderRegistration.postalAddress,
      AddressDisplayFormat.FULL,
    );
  }
  // Otherwise, if we have an old free text one, show that.
  const { provisionalData = {} } = bidderRegistration;
  return provisionalData.postal_address || null;
}

function BusinessAddressRow(props) {
  const { bidderRegistrationId, useBusinessAddress, businessId } = props;

  const dispatch = useDispatch();

  const business = useSelector(getBusinessById(businessId));
  const bidderRegistration = useSelector(getBidderById(bidderRegistrationId));

  const { provisionalData } = bidderRegistration;

  if (!businessId) {
    return null;
  }

  const onClickUseBusinessAddress = () => {
    dispatch(
      patchBidderRegistrationOffline(bidderRegistrationId, {
        useBusinessAddress: true,
      }),
    );
  };

  const onClickUseRegistrationAddress = () => {
    dispatch(
      patchBidderRegistrationOffline(bidderRegistrationId, {
        useBusinessAddress: false,
      }),
    );
  };

  const setDeliveryAddress = () => {
    dispatch(
      BusinessAction.update({
        id: businessId,
        address: bidderRegistration.deliveryAddress,
      }),
    );
  };
  const setPostalAddress = () => {
    dispatch(
      BusinessAction.update({
        id: businessId,
        address: bidderRegistration.postalAddress,
      }),
    );
  };

  const onClickCopyDeliveryAddress = () => {
    // if we have an actual address object, we should be able to patch it straight onto the business.
    if (bidderRegistration.deliveryAddress) {
      // Just do it if the business doesn't have an address.
      if (!business.address) {
        setDeliveryAddress();
      } else {
        // Otherwise, ask about overwriting.
        dispatch(
          openConfirmModal({
            title: "Are you sure?",
            message:
              "Are you sure you want to copy the delivery address to the business?  It will overwrite the existing value.",
            actions: [
              {
                label: "No",
                secondary: true,
                onClick: () => {
                  dispatch(closeConfirmModal());
                },
              },
              {
                label: "Yes",
                onClick: () => {
                  dispatch(closeConfirmModal());
                  setDeliveryAddress();
                },
              },
            ],
          }),
        );
      }
    } else {
      // Otherwise hook into the edit business prefill address machinery
      openEditBusinessModal(
        businessId,
        undefined,
        BusinessModalSection.ADDRESS,
        provisionalData.delivery_details,
      );
    }
  };

  const onClickCopyPostalAddress = () => {
    // if we have an actual address object, we should be able to patch it straight onto the business.
    if (bidderRegistration.postalAddress) {
      // Just do it if the business doesn't have an address.
      if (!business.address) {
        setPostalAddress();
      } else {
        // Otherwise, ask about overwriting.
        dispatch(
          openConfirmModal({
            title: "Are you sure?",
            message:
              "Are you sure you want to copy the postal address to the business?  It will overwrite the existing value.",
            actions: [
              {
                label: "No",
                secondary: true,
                onClick: () => {
                  dispatch(closeConfirmModal());
                },
              },
              {
                label: "Yes",
                onClick: () => {
                  dispatch(closeConfirmModal());
                  setPostalAddress();
                },
              },
            ],
          }),
        );
      }
    } else {
      // Otherwise hook into the edit business prefill address machinery
      openEditBusinessModal(
        businessId,
        undefined,
        BusinessModalSection.ADDRESS,
        provisionalData.postal_address,
      );
    }
  };

  const deliveryAddress = getDeliveryAddress(bidderRegistration);
  const postalAddress = getPostalAddress(bidderRegistration);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={6} item>
        <BusinessAddressHeader onClick={onClickUseRegistrationAddress}>
          <SmallFaIcon
            icon={useBusinessAddress ? faCaretRightOutline : faCaretRightSolid}
            title={
              useBusinessAddress
                ? "Not Using Submitted Delivery Address"
                : "Using Submitted Delivery Address"
            }
            color="primary"
          />
          &nbsp; Registration Address:
        </BusinessAddressHeader>

        <DetailsBlock expanded>
          Delivery Address: {deliveryAddress}
        </DetailsBlock>
        <Row justifyEnd>
          <SlimButton onClick={onClickCopyDeliveryAddress}>
            Copy Delivery Address To Business
          </SlimButton>
        </Row>

        {postalAddress ? (
          <>
            <DetailsBlock expanded>
              Postal Address: {postalAddress}
            </DetailsBlock>
            <Row justifyEnd>
              <SlimButton onClick={onClickCopyPostalAddress}>
                Copy Postal Address To Business
              </SlimButton>
            </Row>
          </>
        ) : null}
      </Grid>
      <Grid xs={12} sm={6} item p={2}>
        <BusinessAddressHeader onClick={onClickUseBusinessAddress}>
          <SmallFaIcon
            icon={useBusinessAddress ? faCaretRightSolid : faCaretRightOutline}
            title={
              useBusinessAddress
                ? "Using Invoice To Business Address"
                : "Not Using Invoice To Business Address"
            }
            color="primary"
          />
          &nbsp; Business Address:
        </BusinessAddressHeader>
        <DetailsBlock expanded>
          <WaitForSync requiredData={[ApiModel.BUSINESSES]}>
            {business?.address
              ? formatAddress(business.address, AddressDisplayFormat.FULL)
              : "-"}
          </WaitForSync>
        </DetailsBlock>
      </Grid>
    </Grid>
  );
}

function BusinessContactRow(props) {
  const { bidderRegistrationId, businessId, useBusinessContact } = props;

  const dispatch = useDispatch();

  const business = useSelector(getBusinessById(businessId));
  const bidderRegistration = useSelector(getBidderById(bidderRegistrationId));

  const emailRecipient = business?.emailRecipients[0] || {};

  if (!business) {
    return <Skeleton animation="wave" variant="text" />;
  }

  const inEmailRecipients = business.emailRecipients.some(
    recipient => recipient.email === bidderRegistration.email,
  );

  const onClickAddBusinessContact = () => {
    // Add this contact to the list (we're only worries about email uniqueness... the other fields maybe duplicated!)
    dispatch(
      BusinessAction.update({
        id: businessId,
        emailRecipients: [
          ...business.emailRecipients,
          {
            email: bidderRegistration.email,
            firstName: bidderRegistration.firstName,
            isAccountsRecipient: true,
            isCommercialReportRecipient: true,
            isComplianceReportRecipient: true,
            lastName: bidderRegistration.lastName,
            phoneNumber: bidderRegistration.phoneNumber,
          },
        ],
      }),
    );
  };
  const onClickUseBusinessContact = () => {
    dispatch(
      patchBidderRegistrationOffline(bidderRegistrationId, {
        useBusinessContact: true,
      }),
    );
  };

  const onClickUseRegistrationContact = () => {
    dispatch(
      patchBidderRegistrationOffline(bidderRegistrationId, {
        useBusinessContact: false,
      }),
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={6} item>
        <BusinessAddressHeader onClick={onClickUseRegistrationContact}>
          <SmallFaIcon
            icon={useBusinessContact ? faCaretRightOutline : faCaretRightSolid}
            title={
              useBusinessContact
                ? "Not Using Submitted Contact Details"
                : "Using Submitted Contact Details"
            }
            color="primary"
          />
          &nbsp; Registration Contact:
        </BusinessAddressHeader>
        <DetailsBlock expanded>
          {bidderRegistration.firstName} {bidderRegistration.lastName} -
          {bidderRegistration.email} {bidderRegistration.phoneNumber}
        </DetailsBlock>
        {inEmailRecipients && bidderRegistration.email ? null : (
          <Row justifyEnd>
            <SlimButton onClick={onClickAddBusinessContact}>
              Add Email Contact To Business
            </SlimButton>
          </Row>
        )}
      </Grid>
      <Grid xs={6} item>
        <BusinessAddressHeader onClick={onClickUseBusinessContact}>
          <SmallFaIcon
            icon={useBusinessContact ? faCaretRightSolid : faCaretRightOutline}
            title={
              useBusinessContact
                ? "Using Invoice To Business Contact Details"
                : "Not Using Invoice To Business Contact Details"
            }
            color="primary"
          />
          &nbsp; Business Contact:
        </BusinessAddressHeader>
        <DetailsBlock expanded>
          {emailRecipient.firstName} {emailRecipient.lastName} -{" "}
          {emailRecipient.email} {emailRecipient.phoneNumber}
        </DetailsBlock>
      </Grid>
    </Grid>
  );
}

const PropertyTitle = props => {
  const { property, provisionalProperty } = props;
  if (property) {
    return `PIC: ${property}`;
  }
  return (
    <>
      {provisionalProperty}
      &nbsp;
      <StatusText status="warning">No PIC</StatusText>
    </>
  );
};

export function BidderCard(props) {
  const {
    id,
    businessId,
    provisionalData,
    buyerWay,
    notes,
    property,
    registrationNumber,
    syncing,
    syncError,
    useBusinessAddress,
    useBusinessContact,
    status,
  } = props;

  const business = useSelector(getBusinessById(businessId));

  const dispatch = useDispatch();

  const color = RegisteredBusinessStatusColourMap[status];
  const title = RegisteredBusinessStatustTitleMap[status];

  const provisionalProperty = provisionalData?.destination_pic
    ? `(${provisionalData.destination_pic})`
    : "";

  let provisionalName = "";
  if (provisionalData.stock_agency) {
    provisionalName = `Agency: ${provisionalData.stock_agency} / Account: ${provisionalData.name}`;
  } else if (provisionalData.invoice_to) {
    provisionalName = `Agency: ${provisionalData.invoice_to} / Account: ${provisionalData.name}`;
  } else if (provisionalData.name) {
    provisionalName = `Trading Name: ${provisionalData.name}`;
  }

  const buyerWayTitle = buyerWay ? ` - ${buyerWay}` : "";
  const propertyTitle = (
    <PropertyTitle
      property={property}
      provisionalProperty={provisionalProperty}
    />
  );

  let placeholderBusinessName = "Unknown Business";

  if (status === RegisteredBusinessStatus.CALCULATING) {
    placeholderBusinessName = <LoadingSpinner size={18} />;
  } else if (status === RegisteredBusinessStatus.INCOMPLETE) {
    placeholderBusinessName = provisionalName || "No Business";
  }

  function onClickEdit() {
    openEditBidderRegistrationModal(id);
  }

  const businessNameTitle = business
    ? `${business.name}${buyerWayTitle}`
    : placeholderBusinessName;

  const isEditAvailable = status !== RegisteredBusinessStatus.CALCULATING;

  const chipData = Object.keys(provisionalData).reduce((chips, key) => {
    if (
      !key.startsWith("delivery") &&
      !key.startsWith("postal") &&
      key !== "notes"
    ) {
      if (provisionalData[key]) {
        chips[key] = provisionalData[key];
      }
    }
    return chips;
  }, {});

  const editBusiness = () => {
    openEditBusinessModal(businessId, undefined);
  };

  const livestockSaleId = getLivestockSaleId();
  const saleyardName = getSaleyardName();
  const userRoleSlug = useSelector(state => getActiveRole(state).slug);

  const bidderDetailReportUrl = `/reports/saleyards/${saleyardName}/bidder-detail-individual/${livestockSaleId}/${id}/?user_role=${userRoleSlug}`;

  const buttons = [
    {
      dataTour: "edit",
      title: "Edit",
      disabled: !isEditAvailable,
      onClick: () => {
        onClickEdit();
      },
      default: true,
    },
    {
      title: "Bidder Detail Report",
      onClick: () => {
        window.open(bidderDetailReportUrl);
      },
      default: false,
      icon: faPrint,
    },
  ];

  const changeGlobalSearch = businessId => {
    dispatch(setSetting(Settings.globalSearch, { buyer: [businessId] }));
    openBuyerCards();
  };

  return (
    <BidderStatusCard data-tour={businessNameTitle} status={color} expanded>
      <TitleRow>
        <CardMetaItem gridArea="bidder-number" center>
          <BidderNumber>{registrationNumber}</BidderNumber>
        </CardMetaItem>
        <SelfTitledField
          title={businessNameTitle}
          value={propertyTitle}
          onClickEdit={editBusiness}
          gridArea="bidder-name"
          extraButtons={[
            { icon: faFilter, onClick: () => changeGlobalSearch(businessId) },
          ]}
        />
        <CardMetaItem right gridArea="sync-status">
          {(syncing || syncError) && <SyncingStatus error={syncError} />}
        </CardMetaItem>
        <CardMetaItem gridArea="edit-action">
          <MultiButton buttons={buttons} />
        </CardMetaItem>
      </TitleRow>
      <Mandoline title="Registration Information" data={chipData} showTooltip />
      <BusinessAddressRow
        businessId={businessId}
        useBusinessAddress={useBusinessAddress}
        bidderRegistrationId={id}
      />
      <BusinessContactRow
        businessId={businessId}
        bidderRegistrationId={id}
        useBusinessContact={useBusinessContact}
      />
      <DetailsRow>
        <DetailsBlock gridArea="details" expanded>
          {notes}
        </DetailsBlock>
        <CardMetaItem gridArea="status" right>
          <Badge color={color} uppercase>
            {title}
          </Badge>
        </CardMetaItem>
      </DetailsRow>
    </BidderStatusCard>
  );
}
