import { combineReducers } from "redux";

import { reduceById } from "lib/reducers";

const createDeploymentAttributeReducer =
  attribute =>
  (state = {}, action) => {
    switch (action.type) {
      case `GET_DEPLOYMENT_${attribute.toUpperCase()}_SUCCESS`:
        return reduceById(action.payload);

      case `UPDATE_DEPLOYMENT_${attribute.toUpperCase()}_COMMIT`: {
        // Mark any temp ids as deleted.
        const deleted = reduceById(
          action.meta.request.map(obj => ({
            ...state[obj.id],
            deleted: true,
          })),
        );

        const updated = reduceById(action.payload);

        return {
          // If the id remains the same it will appear in the updated object, and override any deleted version.
          ...state,
          ...deleted,
          ...updated,
        };
      }

      case `UPDATE_DEPLOYMENT_${attribute.toUpperCase()}_OFFLINE`: {
        const { id } = action.payload;
        const updatedAttributes = id
          ? action.payload
          : reduceById(action.payload);

        if (id) {
          // If an existing attribute, do not deprecate optimistically - wait for a server confirmation.
          if (updatedAttributes.deprecated) {
            updatedAttributes.deprecated = false;
          }
          return {
            ...state,
            [id]: {
              ...state[id],
              ...updatedAttributes,
            },
          };
        } else {
          return {
            ...state,
            ...updatedAttributes,
          };
        }
      }

      case `DELETE_DEPLOYMENT_${attribute.toUpperCase()}_OFFLINE`: {
        const { id } = action.meta;
        return {
          ...state,
          [id]: {
            ...state[id],
            deleted: true,
          },
        };
      }

      case `DELETE_DEPLOYMENT_${attribute.toUpperCase()}_COMMIT`:
      case `DELETE_DEPLOYMENT_${attribute.toUpperCase()}_FROM_SOCKET`: {
        const { id } = action.meta;
        const newAttributes = { ...state };
        delete newAttributes[id];
        return {
          ...newAttributes,
        };
      }

      case `DELETE_DEPLOYMENT_${attribute.toUpperCase()}_ROLLBACK`: {
        const { id } = action.meta;
        return {
          ...state,
          [id]: {
            ...state[id],
            deleted: undefined,
            syncError: true,
          },
        };
      }

      default:
        return state;
    }
  };

const attributes = ["breeds", "ages", "sexes", "labels", "marks", "dentition"];

export default combineReducers(
  attributes.reduce((acc, attribute) => {
    acc[attribute] = createDeploymentAttributeReducer(attribute);
    return acc;
  }, {}),
);
