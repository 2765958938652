import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setSetting } from "actions";

import { Column } from "components/Layout";
import Select, {
  DescriptiveOption,
} from "components/SearchableSelector/ReactSelect";

import { AuctionScreenModeOptions } from "constants/auctionModes";
import { Settings } from "constants/settings";

import { getSetting } from "selectors";

export function AuctionScreenModeSelector() {
  const dispatch = useDispatch();

  const mode = useSelector(getSetting(Settings.auctionScreenMode));

  const onChange = option => {
    dispatch(setSetting(Settings.auctionScreenMode, option.value));
  };

  const selectedOption = useMemo(
    () => AuctionScreenModeOptions.find(o => o.value === mode),
    [mode],
  );

  return (
    <Column minWidth="50%" maxWidth="75%">
      <Select
        menuPortalTarget={document.body}
        options={AuctionScreenModeOptions}
        components={{ Option: DescriptiveOption }}
        value={selectedOption}
        onChange={onChange}
        isClearable={false}
      />
    </Column>
  );
}
