// The time in milliseconds between each check for the JS Engine responsiveness 0.75 seconds
const HEARTBEAT_TICK_INTERVAL_MS = 750;

// The default time between each forced "getChangesSince" for a LivestockSale - 2 mins
// this may be overridden by a parameter provided to the heartBeatTrigger
const DEFAULT_REFRESH_INTERVAL_MS = 120000;

// The minimum time between each forced "getChangesSince" - 30 seconds
const MIN_REFRESH_INTERVAL_MS = 30000;

// The minimum time between each forced refresh - 15 seconds
const MIN_FORCE_INTERVAL_MS = 15000;

// The maximum amount of time to tolerate no JavaScript Engine ticks - 5 seconds
const MAX_TICK_INTERVAL_MS = 5000;

const HeartbeatReason = {
  NONE: 0,
  FORCED: 1,
  JS_ENGINE_UNRESPONSIVE: 2,
  PERIOD_ELAPSED: 3,
};

/**
 * Clear the heart to stop background updating.  May be called when
 * resetting parameters or if user has been logged out.
 */
export const clearHeartBeatInterval = () => {
  const { heartBeatIntervalId } = window;
  if (heartBeatIntervalId) {
    clearInterval(heartBeatIntervalId);
    delete window.heartBeatIntervalId;
  }
};

/**
 * Detects when JS execution:
 * - has paused for more than MAX_TICK_INTERVAL_MS seconds or
 * - when a refresh has been forced or
 * - when a refresh hasn't been run in the last DEFAULT_REFRESH_INTERVAL_MS seconds (or `preferredRefreshIntervalMs`) when provided.
 * Note that when a refresh is not triggered by a forceHearbeat, refreshes are rate limited to 1 every MIN_REFRESH_INTERVAL_MS milliseconds.
 *
 * @param {Function} cb
 * @param {Function} everyTick a function which is called every tick of the heartbeat, this function is called after `cb`
 * @param {number} preferredRefreshIntervalMs The preferred time delta in milliseconds at which to call `cb`
 * @param {number} initialTickTime from `Date.now()`
 * @param {number} initialHeartbeatTime from `Date.now()`
 */
export const heartBeatTrigger = (
  cb,
  everyTick,
  preferredRefreshIntervalMs,
  initialTickTime = Date.now(),
  initialHeartbeatTime = Date.now(),
) => {
  // Used to keep track of the last time the javascript engine ticked/the last time the interval ran
  let lastTickTime = initialTickTime;

  // Used to keep track of the last time the heartbeat was triggered
  let lastHeartbeatTime = initialHeartbeatTime;
  const refreshIntervalMs = Math.max(
    // When no valid refresh interval is provided, use the default instead
    preferredRefreshIntervalMs || DEFAULT_REFRESH_INTERVAL_MS,
    MIN_REFRESH_INTERVAL_MS,
  );

  // Used to keep track of the last time a forced heartbeat triggered
  let lastForceHeartbeatTimeMs = 0;

  const tick = () => {
    const nowMs = Date.now();

    let heartbeatReason = HeartbeatReason.NONE;

    const heartbeatDeltaMs = nowMs - lastHeartbeatTime;

    if (window.intervalHeartbeatForceUpdate) {
      // Force refresh frequency is clamped a 1 every MIN_FORCE_INTERVAL_MS milliseconds
      const forcedHearbeatDeltaMs = nowMs - lastForceHeartbeatTimeMs;

      if (
        // Select the lesser of the regular heartbeat delta and the forced heartbeat delta
        Math.min(forcedHearbeatDeltaMs, heartbeatDeltaMs) >
          MIN_FORCE_INTERVAL_MS ||
        // if we have a force waiting, and there has never been one
        lastForceHeartbeatTimeMs === 0
      ) {
        // Only clear the force flag once we have actually forced the refresh
        delete window.intervalHeartbeatForceUpdate;
        heartbeatReason = HeartbeatReason.FORCED;
        lastForceHeartbeatTimeMs = nowMs;
      }
    } else if (heartbeatDeltaMs > refreshIntervalMs) {
      heartbeatReason = HeartbeatReason.PERIOD_ELAPSED;
    } else if (
      // Check if the JS Engine has missed more than MAX_TICK_INTERVAL_MS milliseconds of check ins.
      // Because the JS Engine hasn't executed for more than, MAX_TICK_INTERVAL_MS, the client may have missed
      // some pusher messages.
      // This allows the user to request changes since and catch up on said messages.

      // Although, I'm inclined to think that there may be another bug going on here, as pusher automatically
      // re-establishes a connection (which is detected by it's own heartbeat) and we are forcing a changes since
      // via the `forceHeartbeatOnNextTick` in `src/hooks/useSocketListener.js`
      nowMs - lastTickTime > MAX_TICK_INTERVAL_MS &&
      // clamp the heartbeats at once per MIN_CHANGES_SINCE_INTERVAL_MS, even if the JS Engine has been unresponsive
      heartbeatDeltaMs > MIN_REFRESH_INTERVAL_MS
    ) {
      // It is assumed that the reason that the JS Engine has missed check ins is due to the OS being in a sleep state,
      // or Browser Application (for mobiles) doesn't have focus/is minimised and the OS has disabled JS Execution for power saving.
      // A major caveat here is that in the event of a bug (or "feature") which uses a lot of CPU time, the call stack may have not
      // returned for in more than MAX_TICK_INTERVAL_MS milliseconds. If that's the case, then that code should be moved to a promise,
      // a generator function, a service worker or something else that's not blocking the main event loop/UI thread.
      heartbeatReason = HeartbeatReason.JS_ENGINE_UNRESPONSIVE;
    }

    if (heartbeatReason) {
      cb();
      lastHeartbeatTime = nowMs;
    }

    everyTick();
    lastTickTime = nowMs;
  };

  // One does not simply create a second heartbeat interval
  clearHeartBeatInterval();

  window.heartBeatIntervalId = setInterval(tick, HEARTBEAT_TICK_INTERVAL_MS);
  return window.heartBeatIntervalId;
};

export const forceHeartbeatOnNextTick = () => {
  window.intervalHeartbeatForceUpdate = true;
};
