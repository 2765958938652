import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import kebabCase from "lodash/kebabCase";
import startCase from "lodash/startCase";
import { all, put, select, takeEvery, throttle } from "redux-saga/effects";

import { closeConfirmModal, openConfirmModal } from "actions";

import { ImportStatus } from "components/Importer/constants";
import { Link } from "components/Link";

import {
  ADD_CONSIGNMENT_ROLLBACK,
  ADD_PRODUCT,
  API_RESPONSE,
  ATTACHMENT,
  AUCTION_PEN,
  BIDDER_REGISTRATION,
  BILLING_DOCUMENT,
  BILLING_RUN,
  BUSINESS,
  CREATE_UNKNOWN_CONSIGNMENT_PEN_FAILURE,
  CURRENT_USER,
  DEFAULT_VENDOR_SPLIT,
  DEPLOYMENT,
  DEPLOYMENT_SALE,
  ECONTRACT,
  ENVD_DOCUMENT,
  EXTERNAL_AGENT_XML,
  INTEGRATION_BUSINESS,
  INTEGRATION_CREDENTIAL,
  LEDGER_ACCOUNT,
  LEDGER_ENTRY,
  LIVESTOCK_AGENT,
  MANUAL_ADJUSTMENT,
  MASTER_RULE,
  MASTER_RULE_BOOK,
  NLIS_SIGN_UP_FAILURE,
  NLIS_SIGN_UP_SUCCESS,
  NOTIFY_FETCH_REQUEST_RETRYING,
  NOTIFY_MUTATE_REQUEST_RETRYING,
  PATCH_CONSIGNMENT_ROLLBACK,
  PEN_SCAN_LOT,
  PRE_SALE_CSV,
  PROPERTY,
  RECEIVAL_LOT,
  REPORT_JOB,
  RULE,
  RULE_BOOK,
  SALE,
  SALE_LOT,
  SALE_VENDOR_SPLIT,
  SALEYARD_ADMIN,
  SCAN,
  SET_ACTIVE_ROLE,
  SET_CURRENT_LIVESTOCKSALE,
  SINGLE_WEIGH,
  TAKE_FILE,
  UPDATE_DEPLOYMENT_AGES,
  UPDATE_DEPLOYMENT_BREEDS,
  UPDATE_DEPLOYMENT_LABELS,
  UPDATE_DEPLOYMENT_MARKS,
  UPDATE_DEPLOYMENT_SEXES,
  UPDATE_PRODUCT,
  WEIGH_LOT,
  WEIGH_LOT_SCAN,
} from "constants/actionTypes";
import { ModalTypes } from "constants/navigation";
import { defaultEffectType, effectMap, methodsMap } from "constants/system";

import {
  getLivestockSaleId,
  isOnWeighBridge,
  openModalLink,
} from "lib/navigation";
import { formatDateTimeUTC } from "lib/timeFormats";
import toast from "lib/toast";

import { getSingleWeighById } from "selectors";

function alertFetchRequestRetry(action) {
  // Only notify on the second retry - the first two have a retry threshold of 1 second, so next to nothing.
  if (action.retryCount > 1) {
    toast.info(
      `Failed to fetch some data, retrying...`,
      { autoClose: 2000 },
      true,
    );
  }
}

function alertMutateRequestRetry(action) {
  // Only notify on the second retry - the first two have a retry threshold of 1 second, so next to nothing.
  if (action.retryCount > 1) {
    toast.info(
      `Failed to save some data, retrying...`,
      { autoClose: 2000 },
      true,
    );
  }
}

function alertTakeFailure() {
  toast.error(`Could not process take`);
}

function alertCreatedAuctionPen(action) {
  const { disabledToast } = action.meta;
  if (!disabledToast) {
    toast.success(`Auction Pen updated`);
  }
}

function alertAuctionPensBulkUpdated() {
  toast.success("Auction Pens updated");
}

function alertENVDAssignSuccess() {
  toast.success("Assigned eNVD to sale");
}

function alertENVDAssignFailure(action) {
  const reason = action.payload.response?.non_field_errors?.[0];
  toast.error(`Failed to assign eNVD to sale${reason ? `: ${reason}` : ""}.`);
}

const saleCreateRequestToastText = "Creating Sale";

function onSaleCreateRequest() {
  toast.syncing(saleCreateRequestToastText, { autoClose: false });
}

function onSaleCreateSuccess(action) {
  toast.dismiss(kebabCase(saleCreateRequestToastText));

  const { payload } = action;
  const { sale_type, saleyard_name } = payload;

  let message = `${saleyard_name}`;
  if (saleyard_name !== sale_type) {
    message = `${message} ${sale_type}`;
  } else {
    message = `${message} sale`;
  }
  message = `${message} successfully added.`;
  toast.success(message);
}

function onSaleDeleteSuccess() {
  toast.success(`Sale #${getLivestockSaleId()} successfully removed.`);
}

function onSaleDeleteFailure() {
  toast.error(`Error removing sale #${getLivestockSaleId()}`);
}

function alertSaleUpdateSuccess(action) {
  toast.success(`Sale #${action.meta.livestockSaleId} successfully updated.`);
}

function alertSaleUpdateFailure(action) {
  const reason = action.payload.response?.non_field_errors?.[0];
  toast.error(
    `Error updating sale #${action.meta.livestockSaleId}${
      reason ? `: ${reason}` : ""
    }`,
  );
}

function onChangeOrderFailed() {
  toast.error("Error updating Auction Pen order.");
}

function alertUpdateSingleWeigh() {
  toast.success(`Single Weigh updated successfully`);
}

function alertChangeRoleFailed({ reason }) {
  toast.error(`Could not change Roles: ${reason}`);
}

function alertChangeSaleFailed({ reason }) {
  toast.error(`Could not change Sales: ${reason}`);
}

function alertCreateUnknownConsignmentPenFailed(action) {
  const { errorMessage } = action;
  toast.error(errorMessage);
}

function* alert409(action) {
  const { error, action: originalAction } = action;
  const { response, method } = error;

  if (error.status === 409) {
    const state = yield select();
    const currentSingleWeigh = getSingleWeighById(
      state.singleWeighs.currentSingleWeighId,
    )(state);

    const lastModified = response.last_modified;
    const modifiedBy = response.user;

    const effectType =
      effectMap(
        originalAction?.payload?.saleLot?.lot_number ||
          response.object_string ||
          response.lot_number,
      )[originalAction.type] || defaultEffectType;

    const methodType = methodsMap[method] || "With";

    if (effectType) {
      const errorMessage = `Error ${methodType} ${effectType.type} ${
        effectType.id
      }, Newer Changes Made By ${modifiedBy} At ${formatDateTimeUTC(
        lastModified,
      )} - CHANGES HAVE BEEN DISCARDED`;
      if (isOnWeighBridge()) {
        yield put(
          openConfirmModal({
            title: `Error ${effectType.type} Lot #${effectType.id}`,
            message: errorMessage,
            icon: faExclamationCircle,
            color: "red",
            actions: [
              {
                label: "Close",
                onClickAction: () => {
                  return closeConfirmModal();
                },
              },
            ],
          }),
        );
      } else if (currentSingleWeigh) {
        toast.error(` ${errorMessage} - Single Weigh Has Been Paused`);
      } else {
        toast.error(errorMessage);
      }
    }
  }
}

function alert404(action) {
  const { error, action: originalAction } = action;
  const { response } = error;
  if (error.status === 404) {
    const method = effectMap(
      originalAction?.payload?.saleLot?.lot_number ||
        response?.lot_number ||
        "",
    )[originalAction.type];
    const errorMessage = `The ${
      method?.type || "requested item"
    } has been deleted or is not available.`;
    toast.error(errorMessage);
  }
}

const displayErrorMessage = fields =>
  toast.error(`There was an error with the following fields: ${fields}`);

function alert400(action) {
  const { error } = action;
  const { response } = error;
  const fields = [];

  if (!response || error.status !== 400) {
    return;
  }

  if (response && response[0]) {
    toast.error(response[0]);
  } else if (response && Object.values(response)[0]) {
    if (typeof Object.values(response)[0] === "string") {
      displayErrorMessage(response[0]);
    } else {
      Object.keys(Object.values(response)[0]).forEach(fieldName => {
        fields.push(fieldName);
      });
      displayErrorMessage(fields);
    }
  } else if (response) {
    Object.keys(response).forEach(fieldName => {
      fields.push(fieldName);
    });
    displayErrorMessage(fields);
  }
}

function importFailureSummaryModal(type) {
  function openModalAndAlertError(action) {
    toastErrorFactory(type, "imported");
    openModalLink(ModalTypes.ImportSummary, {
      action,
      iterateErrorMessage: true,
      type: ImportStatus.FAILURE,
    });
  }

  return openModalAndAlertError;
}

function bulkUpdateLotsToPensSync() {
  const message = "Updating Sale Lots";
  toast.syncing(message, {
    autoClose: false,
  });
}

function bulkUpdateLotsToPensFailure() {
  toast.dismiss(kebabCase("Updating Sale Lots"));
  // Failure message is handled by alert400
}

function bulkUpdateLotsToPensSuccess() {
  const message = "Updating Sale Lots";
  toast.dismiss(kebabCase(message));
  toast.success(`Sale Lots have been updated successfully`);
}

function nlisCredsFailure(error) {
  const message = `NLIS Credentials failed to be updated: ${error.message}`;
  const toastId = "63973048-5a11-41df-98b9-e2b165bd00e3";
  toast.error(message, { toastId });
}

function onBulkUpdateSaleLotsSucceeded(action) {
  const { payload } = action;
  const saleLotsCount = payload.length;
  toast.success(
    saleLotsCount > 0
      ? `${saleLotsCount} Sale Lot(s) have been bulk-updated`
      : `No Sale Lots were updated`,
  );
}

function onAllocatePenScanLotsSucceeded(action) {
  const { meta } = action;
  const penScanLotsCount = meta?.penScanLotIds?.length || 0;
  toast.success(
    penScanLotsCount > 0 ? (
      <>
        {`${penScanLotsCount} Pen Scan Lot(s) have been allocated to`}
        &nbsp;
        <Link to="./salelots">Sale Lots</Link>
      </>
    ) : (
      <>`No Pen Scan Lots were allocated!`</>
    ),
    { autoClose: 8000, closeOnClick: true },
  );
}

function getToastOptions(action) {
  // our SubActionCreators are not consistent with where the options are placed,
  //   sometimes in options
  //   sometimes in meta
  //   sometimes in meta.options
  //   sometimes in meta.offline.effect.options
  const { meta, options } = action;
  const toastOptions = {
    suppressToast:
      options?.suppressToast ||
      meta?.suppressToast ||
      meta?.options?.suppressToast ||
      meta?.offline?.effect?.options?.suppressToast ||
      false,
    suppressErrorToast:
      options?.suppressErrorToast ||
      meta?.suppressErrorToast ||
      meta?.options?.suppressErrorToast ||
      meta?.offline?.effect?.options?.suppressErrorToast ||
      false,
    suppressSuccessToast:
      options?.suppressSuccessToast ||
      meta?.suppressSuccessToast ||
      meta?.options?.suppressSuccessToast ||
      meta?.offline?.effect?.options?.suppressSuccessToast ||
      false,
  };
  return toastOptions;
}

function toastSuccessFactory(objectName, action) {
  return () => {
    const { suppressToast, suppressSuccessToast } = getToastOptions(action);
    if (suppressToast || suppressSuccessToast) {
      return;
    }
    toast.success(`${objectName} successfully ${action}.`);
  };
}

export function getErrorReasons(action) {
  const reasons = [];

  // response maybe an object (single object update) or an array (multi object update).
  // If there's a single row here in the multi value update (eg, working with deployment attributes), treat it
  // the same as a single object update.
  let objectResponse = null;
  if (
    typeof action.payload.response === "object" &&
    !Array.isArray(action.payload.response)
  ) {
    objectResponse = action.payload.response;
  } else if (
    Array.isArray(action.payload.response) &&
    action.payload.response.length === 1 &&
    typeof action.payload.response[0] === "object"
  ) {
    objectResponse = action.payload.response[0];
  }

  if (objectResponse) {
    // Some responses have a single "detail" to display - use that.
    const { detail } = objectResponse;

    if (isEmpty(detail)) {
      // If we have an object, interrogate it further.
      // Handle validation errors where the key is the field with the error message.
      Object.entries(objectResponse).forEach(([key, error]) => {
        if (typeof error === "object") {
          Object.entries(error).forEach(([ignored, error]) => {
            if (Array.isArray(error)) {
              reasons.push(...error);
            }
          });
          if (Array.isArray(error)) {
            reasons.push(...error);
          }
        } else {
          reasons.push(`${startCase(key)}: ${error}`);
        }
      });
    } else {
      reasons.push(detail);
    }
  } else if (Array.isArray(action.payload.response)) {
    // If there's multiple rows in the response, they're possibly strings.
    // (TODO) sometimes they're a list of objects for a multi-object-update.   These should generally
    // be handled in a bespoke way (see, XML imports) rather than this generic response function.
    // For now, let's just make sure the user sees SOMETHING.
    if (action.payload.response.every(i => typeof i === "string")) {
      reasons.push(...action.payload.response);
    } else {
      reasons.push("Multiple errors occurred.");
    }
  }
  return reasons;
}

function toastErrorFactory(objectName, performedAction) {
  function alertError(action) {
    const { suppressToast, suppressErrorToast } = getToastOptions(action);
    if (suppressToast || suppressErrorToast) {
      return;
    }

    const reasons = getErrorReasons(action);

    toast.error(
      `${objectName} failed to be ${performedAction}${
        reasons.length > 0 ? `: ${reasons.join(". ")}` : ""
      }`,
    );
  }

  return alertError;
}

export function* generalToastFactory(action, { objectText }) {
  yield takeEvery(
    action.CREATE.SUCCESS,
    toastSuccessFactory(objectText, "created"),
  );
  yield takeEvery(
    action.UPDATE.SUCCESS,
    toastSuccessFactory(objectText, "updated"),
  );
  yield takeEvery(
    action.DELETE.SUCCESS,
    toastSuccessFactory(objectText, "deleted"),
  );
  yield takeEvery(
    action.UPDATE_BULK.SUCCESS,
    toastSuccessFactory(`${objectText}s`, "bulk updated"),
  );
  yield takeEvery(
    action.BULK_UPDATE_OR_CREATE.SUCCESS,
    toastSuccessFactory(`${objectText}s`, "bulk updated or created"),
  );
  yield takeEvery(
    action.CREATE.FAILURE,
    toastErrorFactory(objectText, "created"),
  );
  yield takeEvery(
    action.UPDATE.FAILURE,
    toastErrorFactory(objectText, "updated"),
  );
  yield takeEvery(
    action.DELETE.FAILURE,
    toastErrorFactory(objectText, "deleted"),
  );
  yield takeEvery(
    action.UPDATE_BULK.FAILURE,
    toastSuccessFactory(`${objectText}s`, "bulk updated"),
  );
  yield takeEvery(
    action.BULK_UPDATE_OR_CREATE.FAILURE,
    toastErrorFactory(`${objectText}s`, "bulk updated or created"),
  );
}

function* waityToastyFactory(
  actionType,
  { requestText, successText, failureText },
) {
  const request = action => {
    const { suppressToast } = getToastOptions(action);
    if (suppressToast) {
      return;
    }

    toast.syncing(requestText, {
      autoClose: false,
    });
  };
  const failure = action => {
    const { suppressToast, suppressErrorToast } = getToastOptions(action);
    if (suppressToast) {
      return;
    }

    toast.dismiss(kebabCase(requestText));

    if (suppressErrorToast) {
      return;
    }

    if (failureText) {
      toast.error(failureText);
    }
  };
  const success = action => {
    const { suppressToast, suppressSuccessToast } = getToastOptions(action);
    if (suppressToast) {
      return;
    }

    toast.dismiss(kebabCase(requestText));

    if (suppressSuccessToast) {
      return;
    }

    if (successText) {
      toast.success(successText);
    }
  };
  yield takeEvery(actionType.REQUEST, request);
  yield takeEvery(actionType.SUCCESS, success);
  yield takeEvery(actionType.FAILURE, failure);
}

function onCreateBidderRegistrationSucceeded(action) {
  const { payload } = action;
  const { registration_number } = payload;
  toast.success(`Bidder Registration ${registration_number} Created`);
}

function onUpdateBidderRegistrationSucceeded(action) {
  const { payload } = action;
  const { registration_number } = payload;
  toast.success(`Bidder Registration ${registration_number} Updated`);
}

function onDeleteBidderRegistrationSucceeded() {
  toast.success("Bidder Registration Deleted");
}

function onMutateBidderRegistrationFailure() {
  toast.error("Failed to save Bidder Registration!");
}

export default function* rootSaga() {
  // Only show offline fetch toast at most 60 seconds
  yield throttle(60000, NOTIFY_FETCH_REQUEST_RETRYING, alertFetchRequestRetry);
  // Be a bit more verbose with save - 15 seconds.
  yield throttle(
    15000,
    NOTIFY_MUTATE_REQUEST_RETRYING,
    alertMutateRequestRetry,
  );

  yield takeEvery(TAKE_FILE.CREATE.FAILURE, alertTakeFailure);
  yield takeEvery(SINGLE_WEIGH.UPDATE.SUCCESS, alertUpdateSingleWeigh);

  yield takeEvery(AUCTION_PEN.CREATE.SUCCESS, alertCreatedAuctionPen);

  yield takeEvery(AUCTION_PEN.UPDATE_BULK.SUCCESS, alertAuctionPensBulkUpdated);

  yield takeEvery(ENVD_DOCUMENT.UPDATE.SUCCESS, alertENVDAssignSuccess);
  yield takeEvery(ENVD_DOCUMENT.UPDATE.FAILURE, alertENVDAssignFailure);
  yield takeEvery(
    [AUCTION_PEN.ORDER_AFTER.FAILURE, AUCTION_PEN.ORDER_BEFORE.FAILURE],
    onChangeOrderFailed,
  );

  yield takeEvery(SALE.CREATE.REQUEST, onSaleCreateRequest);
  yield takeEvery(SALE.CREATE.SUCCESS, onSaleCreateSuccess);

  yield takeEvery(SALE.DELETE.SUCCESS, onSaleDeleteSuccess);
  yield takeEvery(SALE.DELETE.FAILURE, onSaleDeleteFailure);

  yield takeEvery(SALE.UPDATE.SUCCESS, alertSaleUpdateSuccess);
  yield takeEvery(SALE.UPDATE.FAILURE, alertSaleUpdateFailure);

  yield takeEvery(
    DEPLOYMENT.UPDATE.SUCCESS,
    toastSuccessFactory("Agency Settings", "updated"),
  );
  yield takeEvery(
    DEPLOYMENT.UPDATE.FAILURE,
    toastErrorFactory("Agency Settings", "updated"),
  );

  yield takeEvery(
    SALEYARD_ADMIN.CREATE.SUCCESS,
    toastSuccessFactory("Saleyard Admin", "created"),
  );
  yield takeEvery(
    SALEYARD_ADMIN.UPDATE.SUCCESS,
    toastSuccessFactory("Saleyard Admin", "updated"),
  );
  yield takeEvery(
    SALEYARD_ADMIN.DELETE.SUCCESS,
    toastSuccessFactory("Saleyard Admin", "deleted"),
  );

  yield takeEvery(
    SALE_LOT.UPDATE_BULK.FAILURE,
    toastErrorFactory("Sale Lots", "updated"),
  );
  yield takeEvery(
    SALE_LOT.UPDATE.FAILURE,
    toastErrorFactory("Sale Lot", "updated"),
  );

  yield takeEvery(
    SALEYARD_ADMIN.CREATE.FAILURE,
    toastErrorFactory("Saleyard Admin", "created"),
  );
  yield takeEvery(
    SALEYARD_ADMIN.UPDATE.FAILURE,
    toastErrorFactory("Saleyard Admin", "updated"),
  );
  yield takeEvery(
    SALEYARD_ADMIN.DELETE.FAILURE,
    toastErrorFactory("Saleyard Admin", "deleted"),
  );

  yield takeEvery(
    LIVESTOCK_AGENT.CREATE.SUCCESS,
    toastSuccessFactory("Livestock Agent", "created"),
  );
  yield takeEvery(
    LIVESTOCK_AGENT.UPDATE.SUCCESS,
    toastSuccessFactory("Livestock Agent", "updated"),
  );
  yield takeEvery(
    LIVESTOCK_AGENT.DELETE.SUCCESS,
    toastSuccessFactory("Livestock Agent", "deleted"),
  );

  yield takeEvery(
    LIVESTOCK_AGENT.CREATE.FAILURE,
    toastErrorFactory("Livestock Agent", "created"),
  );
  yield takeEvery(
    LIVESTOCK_AGENT.UPDATE.FAILURE,
    toastErrorFactory("Livestock Agent", "updated"),
  );
  yield takeEvery(
    LIVESTOCK_AGENT.DELETE.FAILURE,
    toastErrorFactory("Livestock Agent", "deleted"),
  );

  yield takeEvery(
    LIVESTOCK_AGENT.REINVITE.SUCCESS,
    toastSuccessFactory("Livestock Agent", "reinvited"),
  );
  yield takeEvery(
    SALEYARD_ADMIN.REINVITE.SUCCESS,
    toastSuccessFactory("Saleyard Admin", "reinvited"),
  );

  yield takeEvery(
    LIVESTOCK_AGENT.REINVITE.FAILURE,
    toastSuccessFactory("Saleyard Admin", "reinvited"),
  );
  yield takeEvery(
    SALEYARD_ADMIN.REINVITE.FAILURE,
    toastSuccessFactory("Saleyard Admin", "reinvited"),
  );

  yield takeEvery(NLIS_SIGN_UP_FAILURE, nlisCredsFailure);

  yield takeEvery(
    NLIS_SIGN_UP_SUCCESS,
    toastSuccessFactory("NLIS Credentials", "updated"),
  );

  yield takeEvery(SET_ACTIVE_ROLE.FAILURE, alertChangeRoleFailed);
  yield takeEvery(SET_CURRENT_LIVESTOCKSALE.FAILURE, alertChangeSaleFailed);

  yield takeEvery(API_RESPONSE.UPDATE.FAILURE, alert409);
  yield takeEvery(API_RESPONSE.UPDATE.FAILURE, alert404);
  yield takeEvery(API_RESPONSE.UPDATE.FAILURE, alert400);

  yield takeEvery(
    DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.REQUEST,
    bulkUpdateLotsToPensSync,
  );
  yield takeEvery(
    DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.FAILURE,
    bulkUpdateLotsToPensFailure,
  );
  yield takeEvery(
    DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.SUCCESS,
    bulkUpdateLotsToPensSuccess,
  );
  yield takeEvery(
    INTEGRATION_BUSINESS.UPDATE.SUCCESS,
    toastSuccessFactory("Business linkage", "updated"),
  );
  yield takeEvery(
    INTEGRATION_BUSINESS.UPDATE.FAILURE,
    toastErrorFactory("Business linkage", "updated"),
  );

  yield takeEvery(
    INTEGRATION_CREDENTIAL.CREATE_BUSINESS.SUCCESS,
    toastSuccessFactory("Business", "created"),
  );

  yield takeEvery(
    EXTERNAL_AGENT_XML.PROCESS.FAILURE,
    toastErrorFactory("External Agent XML", "loaded"),
  );

  yield takeEvery(
    EXTERNAL_AGENT_XML.PROCESS.SUCCESS,
    toastSuccessFactory("External Agent XML", "loaded"),
  );

  yield takeEvery(
    EXTERNAL_AGENT_XML.IMPORT.FAILURE,
    importFailureSummaryModal("External Agent XML"),
  );

  yield takeEvery(
    EXTERNAL_AGENT_XML.IMPORT.SUCCESS,
    toastSuccessFactory("External Agent XML", "imported"),
  );

  yield takeEvery(
    PRE_SALE_CSV.IMPORT.FAILURE,
    importFailureSummaryModal("Sale Import CSV"),
  );

  yield takeEvery(
    PRE_SALE_CSV.IMPORT.SUCCESS,
    toastSuccessFactory("Sale Import CSV", "imported"),
  );

  yield takeEvery(
    [
      UPDATE_DEPLOYMENT_LABELS.COMMIT,
      UPDATE_DEPLOYMENT_BREEDS.COMMIT,
      UPDATE_DEPLOYMENT_SEXES.COMMIT,
      UPDATE_DEPLOYMENT_AGES.COMMIT,
      UPDATE_DEPLOYMENT_MARKS.COMMIT,
      UPDATE_PRODUCT.SUCCESS,
      ADD_PRODUCT.SUCCESS,
    ],
    toastSuccessFactory("Data", "saved"),
  );
  yield takeEvery(
    UPDATE_DEPLOYMENT_LABELS.ROLLBACK,
    toastErrorFactory("Label", "modified"),
  );

  yield takeEvery(
    BILLING_RUN.CREATE.FAILURE,
    toastErrorFactory("Billing Run", "created"),
  );

  yield takeEvery(
    BILLING_RUN.CHECK_FOR_CHANGES.FAILURE,
    toastErrorFactory("Billing Run", "checked for changes"),
  );

  yield takeEvery(
    PATCH_CONSIGNMENT_ROLLBACK,
    toastErrorFactory("Consignment", "updated"),
  );

  yield takeEvery(
    INTEGRATION_CREDENTIAL.CREATE_BUSINESS.FAILURE,
    toastErrorFactory("Business", "created"),
  );

  yield takeEvery(
    ADD_CONSIGNMENT_ROLLBACK,
    toastErrorFactory("Consignment", "created"),
  );

  yield takeEvery(
    BILLING_DOCUMENT.EXPORT_XERO_INVOICE.FAILURE,
    toastErrorFactory("Invoices", "exported"),
  );

  yield takeEvery(
    BILLING_DOCUMENT.ACCEPT_AND_REVERSE.FAILURE,
    toastErrorFactory("Billing Document", "reversed"),
  );

  yield takeEvery(
    INTEGRATION_CREDENTIAL.CREATE_TAX_RATES.FAILURE,
    toastErrorFactory("Tax Rates", "created"),
  );

  yield takeEvery(
    INTEGRATION_CREDENTIAL.SYNC_LEDGER_ACCOUNTS.FAILURE,
    toastErrorFactory("Accounts", "sync"),
  );

  yield takeEvery(
    CREATE_UNKNOWN_CONSIGNMENT_PEN_FAILURE,
    alertCreateUnknownConsignmentPenFailed,
  );

  yield takeEvery(
    INTEGRATION_CREDENTIAL.PUSH_LEDGER_ACCOUNTS.FAILURE,
    toastErrorFactory("Ledger Accounts", "pushed"),
  );
  yield takeEvery(SALE_LOT.UPDATE_BULK.SUCCESS, onBulkUpdateSaleLotsSucceeded);
  yield takeEvery(
    PEN_SCAN_LOT.ALLOCATE.SUCCESS,
    onAllocatePenScanLotsSucceeded,
  );

  yield takeEvery(
    BIDDER_REGISTRATION.CREATE.SUCCESS,
    onCreateBidderRegistrationSucceeded,
  );
  yield takeEvery(
    BIDDER_REGISTRATION.UPDATE.SUCCESS,
    onUpdateBidderRegistrationSucceeded,
  );
  yield takeEvery(
    BIDDER_REGISTRATION.DELETE.SUCCESS,
    onDeleteBidderRegistrationSucceeded,
  );

  yield takeEvery(
    [BIDDER_REGISTRATION.CREATE.FAILURE, BIDDER_REGISTRATION.UPDATE.FAILURE],
    onMutateBidderRegistrationFailure,
  );

  yield takeEvery(
    BIDDER_REGISTRATION.CREATE_WITHOUT_INCREMENTING_BIDDER_NUMBER.FAILURE,
    toastErrorFactory("Bidder registration", "created"),
  );

  yield all([
    waityToastyFactory(PROPERTY.ERP_QUERY, {
      requestText: "Querying NLIS",
      successText: "Success!",
      failureText: "Failed.",
    }),

    waityToastyFactory(BILLING_RUN.CREATE, {
      requestText: "Creating Billing Run...",
      successText: "Success!",
      // Failure text handled with a toast error factory to provide more detail.
    }),

    waityToastyFactory(BILLING_DOCUMENT.EMAIL, {
      requestText: "Queuing Emails...",
      successText: "Success!",
      failureText: "Failed!",
    }),

    waityToastyFactory(BILLING_DOCUMENT.EXPORT_XERO_INVOICE, {
      requestText: "Exporting to Xero...",
      successText: "Export to Xero Success!",
      // Failure text handled with a toast error factory to provide more detail.
    }),

    waityToastyFactory(CURRENT_USER.REQUEST_ACCOUNT_DELETION, {
      requestText: "Creating Account Deletion Request...",
      successText: "Account Deletion Request Sent!",
      // Failure text handled with a toast error factory to provide more detail.
    }),

    waityToastyFactory(INTEGRATION_CREDENTIAL.SYNC_LEDGER_ACCOUNTS, {
      requestText: "Syncing Accounting Settings...",
      successText: "Success!",
      // Failure text handled with a toast error factory to provide more detail.
    }),

    waityToastyFactory(BILLING_DOCUMENT.ACCEPT_AND_REVERSE, {
      requestText: "Actioning Reversal...",
      successText: "Success!",
      failureText:
        "There was an error performing the reversal.  Please contact support.",
    }),

    generalToastFactory(RECEIVAL_LOT, {
      objectText: "Receival Lot",
    }),

    generalToastFactory(LEDGER_ENTRY, {
      objectText: "Ledger Entry",
    }),

    generalToastFactory(SCAN, { objectText: "Scan" }),

    generalToastFactory(PEN_SCAN_LOT, { objectText: "Pen Scan Lot" }),

    generalToastFactory(MASTER_RULE_BOOK, { objectText: "Master Rule Book" }),

    generalToastFactory(MASTER_RULE, { objectText: "Master Rule" }),

    generalToastFactory(RULE_BOOK, { objectText: "Rule Book" }),

    generalToastFactory(RULE, { objectText: "Rule" }),

    waityToastyFactory(INTEGRATION_CREDENTIAL.CREATE_TAX_RATES, {
      requestText: "Creating Tax Rates...",
      successText: "Success!",
      // Failure text handled with a toast error factory to provide more detail.
    }),

    generalToastFactory(WEIGH_LOT, { objectText: "Weigh Lot" }),

    generalToastFactory(WEIGH_LOT_SCAN, { objectText: "Weigh Lot Scan" }),

    waityToastyFactory(ATTACHMENT.CREATE_WITH_PLACEHOLDER, {
      requestText: "Creating Placeholder Attachment...",
      successText: "Success!",
      failureText: "Failed!",
    }),

    waityToastyFactory(INTEGRATION_CREDENTIAL.SYNC_DOCUMENTS, {
      requestText: "Syncing Documents...",
      successText: "Syncing Success",
      failureText: "Failed To Sync Documents",
    }),

    waityToastyFactory(INTEGRATION_CREDENTIAL.PUSH_LEDGER_ACCOUNTS, {
      requestText: "Pushing Ledger Accounts to Xero...",
      successText: "Success",
      // Failure text handled with a toast error factory to provide more detail.
    }),

    generalToastFactory(BILLING_DOCUMENT, { objectText: "Billing Document" }),
    generalToastFactory(LEDGER_ACCOUNT, { objectText: "Ledger Account" }),
    generalToastFactory(SALE_VENDOR_SPLIT, { objectText: "Vendor Split" }),
    generalToastFactory(DEFAULT_VENDOR_SPLIT, { objectText: "Vendor Split" }),
    generalToastFactory(BUSINESS, { objectText: "Business" }),
    generalToastFactory(ECONTRACT, { objectText: "eContract" }),
    generalToastFactory(MANUAL_ADJUSTMENT, { objectText: "Sundry" }),
    waityToastyFactory(ECONTRACT.VOID, {
      requestText: "Voiding eContract...",
      successText: "Success!",
      failureText: "Failed!",
    }),
    waityToastyFactory(ECONTRACT.EMAIL, {
      requestText: "Sending eContract...",
      successText: "Success!",
      failureText: "Failed to send eContract!",
    }),
    waityToastyFactory(REPORT_JOB.EMAIL, {
      requestText: "Sending Report ...",
      successText: "Success!",
      failureText: "Failed to send Report!",
    }),
    waityToastyFactory(REPORT_JOB.CREATE_BULK, {
      requestText: "Creating Reports ...",
    }),
    waityToastyFactory(REPORT_JOB.FETCH_SOME, {
      requestText: "Loading More Reports ...",
    }),
    waityToastyFactory(BILLING_DOCUMENT.BULK_ACCEPT_CHANGES, {
      requestText: "Accepting Changes on Billing Documents...",
    }),
    yield takeEvery(
      REPORT_JOB.CREATE_BULK.FAILURE,
      toastErrorFactory("Reports", "created"),
    ),
  ]);
}
