import * as Yup from "yup";

import { ALL_PAYMENT_METHODS } from "constants/payments";

const validObject = Yup.object().shape({
  paymentMethod: Yup.mixed().required("required").oneOf(ALL_PAYMENT_METHODS),
  paymentRef: Yup.string(),
  paymentDate: Yup.date(),
  totalAmountCents: Yup.number().required("required").integer(),
  notes: Yup.string(),
});

export const ValidationSchema = validObject;
