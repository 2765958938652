import React, { memo } from "react";

import { useFormikContext } from "formik";

import { SubtleBadge } from "components/Badge";
import { CollapseLabel, FormCollapse } from "components/Form";
import { AddressForm } from "components/Form/Fields";
import { Row } from "components/Layout";

import { formatAddress, getAddressIn } from "lib/address";
import { sectionHasErrors } from "lib/form";

function BusinessAddressHeaderComponent(props) {
  const { namespace: ns = "" } = props;
  const formikProps = useFormikContext();
  const { values } = formikProps;

  const address = getAddressIn(ns, values);

  const formattedAddress = formatAddress(address) || "No Address";

  const hasErrorBadge = sectionHasErrors(formikProps, ["address"]);

  return (
    <Row>
      <div>
        <SubtleBadge hasErrorBadge={hasErrorBadge}>Address</SubtleBadge>
      </div>
      <CollapseLabel>{formattedAddress}</CollapseLabel>
    </Row>
  );
}
const BusinessAddressHeader = memo(BusinessAddressHeaderComponent);

function BusinessAddressTableComponent(props) {
  const {
    isOpen,
    namespace: ns = "",
    onToggle,
    defaultAddressSearch,
    businessId,
  } = props;
  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<BusinessAddressHeader namespace={ns} />}
      dataTour="addressCollapse"
    >
      <AddressForm
        namespace={ns}
        label="Business Address"
        defaultAddressSearch={defaultAddressSearch}
        businessId={businessId}
      />
    </FormCollapse>
  );
}

export default memo(BusinessAddressTableComponent);
