import React from "react";

import { useSelector } from "react-redux";

import { SelectField } from "components/Form/FormikControls";

import { sortPenByOrder } from "lib/auctionPens";

import {
  getAuctionPens,
  getHasMultipleDeploymentsInCurrentSale,
  getPenIdsByRoundId,
  selectHeadCountByAuctionPenIdLookup,
  selectMultiDeploymentOptionLabelByPenId,
  selectSingleDeploymentOptionLabelByPenId,
} from "selectors";

export function RoundPenSelect(props) {
  const { label, name, onChangeExtra, roundId } = props;
  const roundAuctionPenIds = useSelector(getPenIdsByRoundId(roundId));
  const headCountByAuctionPenIdLookup = useSelector(
    selectHeadCountByAuctionPenIdLookup,
  );

  const pens = useSelector(getAuctionPens);

  const labelByAuctionPenIdLookup = useSelector(state =>
    getHasMultipleDeploymentsInCurrentSale(state)
      ? selectMultiDeploymentOptionLabelByPenId(state)
      : selectSingleDeploymentOptionLabelByPenId(state),
  );

  const auctionPenOptions = sortPenByOrder(
    Object.values(pens).filter(
      pen =>
        roundAuctionPenIds.includes(pen.id) &&
        headCountByAuctionPenIdLookup[pen.id],
    ),
  ).map(auctionPen => ({
    label: labelByAuctionPenIdLookup[auctionPen.id],
    value: auctionPen.id,
  }));

  return (
    <SelectField
      label={label}
      name={name}
      options={auctionPenOptions}
      onChangeExtra={onChangeExtra}
      required
    />
  );
}
