import React, { useState } from "react";

import {
  faCopy,
  faCrosshairs,
  faCut,
  faDollarSign,
  faPencilAlt,
  faPhotoVideo,
  faPlus,
  faPrint,
  faRedoAlt,
  faTruck,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faContainerStorage } from "@fortawesome/pro-regular-svg-icons";
import { faEye, faLock } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  deleteConsignment,
  patchSaleLot,
  printPlacard,
  printSaleLotWeightSheet,
} from "actions";

import { IconTextButton } from "components/Button";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import Dialog from "components/Dialog";
import { CurrentSaleReadOnly } from "components/ReadOnly";
import TabletDropdown from "components/TabletDropdown";

import { PenTypes } from "constants/auctionPens";
import { ExportSites } from "constants/exportSites";
import { ModalTypes, SaleLotModalSection } from "constants/navigation";
import {
  ConsignmentPermissions,
  DeploymentPermissions,
  SaleLotPermissions,
} from "constants/permissions";
import { auctionPenActions, saleLotStatuses } from "constants/sale";

import { ForSaleLotListedWith } from "containers/ForSaleLotListedWith";
import { ForLivestockAuction, ForLivestockSale } from "containers/ForSaleType";
import { ForCattle } from "containers/ForSpecies";

import {
  getCurrentSaleyardAuctionRoute,
  getEditSaleLotHashRoute,
  getScanRoute,
  getSplitSaleLotHashRoute,
  openDeliverSaleLotModal,
  openModalLink,
} from "lib/navigation";
import { hasPermission } from "lib/permissions";
import { getSaleLotStatus } from "lib/saleLot";

import {
  getConsignmentById,
  getHasWriteAccessInCurrentSale,
  getSaleLotById,
  getScannedCountBySaleLotId,
  selectRoleCurrentDeployments,
} from "selectors";

import { useSomeHasPermission } from "hooks/useHasPermission";

export const SaleLotActionOptions = ({ saleLotId }) => {
  const [isUnsellDialog, setIsUnsellDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const saleLot = useSelector(getSaleLotById(saleLotId));
  const consignment = useSelector(getConsignmentById(saleLot.consignment_id));
  const status = getSaleLotStatus(saleLot, consignment);

  const deliver = () => {
    openDeliverSaleLotModal(saleLotId);
  };

  const undeliver = () => {
    dispatch(
      patchSaleLot(
        { quantity_delivered: null, id: saleLotId },
        { actionText: "un-delivered", changeReason: "Bulk un-delivered" },
      ),
    );
  };

  const unsell = () => {
    const patch = {
      total_price_cents: 0,
      buyer_id: null,
      buyer_way: null,
      id: saleLotId,
    };
    dispatch(patchSaleLot(patch, { changeReason: "Bulk unsold" }));
  };

  const unPen = () => {
    saleLot.lot_number = saleLot.lotNumber;
    const patch = { auction_pen_id: null, id: saleLot.id };
    dispatch(
      patchSaleLot(
        patch,
        { actionText: "unpenned" },
        { changeReason: "Bulk unpenned" },
      ),
    );
  };

  const doPrintSaleLotWeightSheet = () => {
    dispatch(printSaleLotWeightSheet(saleLotId, true));
  };

  const doPrintPlacard = () => {
    dispatch(printPlacard(saleLotId, undefined, true));
  };

  const {
    id,
    auction_pen_id: auctionPenId,
    consignment_id: consignmentId,
    sale_round_id: saleRoundId,
    total_mass_grams: totalMassGrams,
  } = saleLot;

  const scannedCount = useSelector(getScannedCountBySaleLotId(id));

  const hasEditSaleLotPermission = hasPermission(
    saleLot,
    SaleLotPermissions.update,
  );

  const hasAddSaleLotPermission = hasPermission(
    consignment,
    ConsignmentPermissions.canAddSaleLot,
  );

  const hasManualChargePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureManualCharges,
  );

  const sellPath = getCurrentSaleyardAuctionRoute(saleRoundId, auctionPenId);

  const scanPath =
    scannedCount > 0
      ? `#${getEditSaleLotHashRoute(
          id,
          SaleLotModalSection.SCANNING,
          null,
          consignmentId,
        )}`
      : `#${getScanRoute(
          null,
          id,
          true,
          null,
          null,
          null,
          null,
          null,
          PenTypes.SELLING,
        )}`;

  return (
    <>
      <TabletDropdown data-tour="penOption" align="right">
        <IconTextButton
          dataTour="edit"
          as={Link}
          icon={hasEditSaleLotPermission ? faPencilAlt : faEye}
          color="white"
          to={`#${getEditSaleLotHashRoute(id, SaleLotModalSection.GENERAL)}`}
        >
          {hasEditSaleLotPermission ? "View/Edit" : "View"} Lot
        </IconTextButton>
        {hasManualChargePermission && saleLot.buyer_id && (
          <IconTextButton
            data-tour="add-buyer-manual"
            onClick={() =>
              openModalLink(ModalTypes.ManualAdjustmentForm, {
                fromBusinessId: saleLot.buyer_id,
              })
            }
            icon={faPlus}
            color="white"
          >
            Add Buyer Sundry
          </IconTextButton>
        )}
        {hasManualChargePermission && saleLot.invoiceToBusinessId && (
          <IconTextButton
            data-tour="add-buyer-manual"
            onClick={() =>
              openModalLink(ModalTypes.ManualAdjustmentForm, {
                fromBusinessId: saleLot.invoiceToBusinessId,
              })
            }
            icon={faPlus}
            color="white"
          >
            Add Invoice To Manual Charge
          </IconTextButton>
        )}
        {hasManualChargePermission && saleLot.thirdPartyId && (
          <IconTextButton
            data-tour="add-buyer-manual"
            onClick={() =>
              openModalLink(ModalTypes.ManualAdjustmentForm, {
                fromBusinessId: saleLot.thirdPartyId,
              })
            }
            icon={faPlus}
            color="white"
          >
            Add Third Party Sundry
          </IconTextButton>
        )}

        <ForLivestockSale>
          <CurrentSaleReadOnly>
            <IconTextButton
              dataTour="scan"
              as={Link}
              icon={faCrosshairs}
              color="white"
              to={scanPath}
            >
              Scan
            </IconTextButton>
          </CurrentSaleReadOnly>
        </ForLivestockSale>
        <CurrentSaleReadOnly showLock={false}>
          {hasAddSaleLotPermission ? (
            <IconTextButton
              dataTour="copyDraft"
              as={Link}
              icon={faCopy}
              color="white"
              to={`#${getEditSaleLotHashRoute(
                null,
                SaleLotModalSection.GENERAL,
                null,
                consignmentId,
                id,
              )}`}
            >
              Copy draft
            </IconTextButton>
          ) : null}
        </CurrentSaleReadOnly>
        {hasEditSaleLotPermission ? (
          <ForSaleLotListedWith
            saleLotId={id}
            listedWith={ExportSites.AUCTIONS_PLUS}
          >
            <IconTextButton
              dataTour="addMedia"
              as={Link}
              icon={faPhotoVideo}
              color="white"
              to={`#${getEditSaleLotHashRoute(
                id,
                SaleLotModalSection.MEDIA,
                null,
                null,
                null,
              )}`}
            >
              Add Media
            </IconTextButton>
          </ForSaleLotListedWith>
        ) : null}
        <CurrentSaleReadOnly showLock={false}>
          {hasAddSaleLotPermission ? (
            <IconTextButton
              dataTour="splitLot"
              as={Link}
              icon={faCut}
              color="white"
              to={`#${getSplitSaleLotHashRoute(id, null, null, null)}`}
            >
              Split Lot
            </IconTextButton>
          ) : null}
        </CurrentSaleReadOnly>
        <ForLivestockAuction>
          {[saleLotStatuses.PENNED].includes(status) && (
            <CurrentSaleReadOnly>
              <IconTextButton
                dataTour="repen"
                as={Link}
                icon={faContainerStorage}
                color="white"
                to={`#${getEditSaleLotHashRoute(
                  id,
                  SaleLotModalSection.AUCTION_PEN,
                  auctionPenActions.RE_PEN,
                )}`}
              >
                Re-Pen
              </IconTextButton>
              <IconTextButton
                dataTour="unpen"
                icon={faRedoAlt}
                color="white"
                onClick={unPen}
              >
                Unpen
              </IconTextButton>
            </CurrentSaleReadOnly>
          )}
          {status === saleLotStatuses.NOT_PENNED && (
            <CurrentSaleReadOnly>
              <IconTextButton
                dataTour="pen"
                as={Link}
                icon={faContainerStorage}
                color="white"
                to={`#${getEditSaleLotHashRoute(
                  id,
                  SaleLotModalSection.AUCTION_PEN,
                  auctionPenActions.RE_PEN,
                  consignmentId,
                )}`}
              >
                Pen
              </IconTextButton>
            </CurrentSaleReadOnly>
          )}
        </ForLivestockAuction>
        {status === saleLotStatuses.PENNED && (
          <CurrentSaleReadOnly>
            <IconTextButton
              dataTour="sell"
              as={Link}
              icon={faDollarSign}
              color="white"
              to={sellPath}
            >
              Sell
            </IconTextButton>
          </CurrentSaleReadOnly>
        )}
        {[saleLotStatuses.SOLD, saleLotStatuses.NO_SALE].includes(status) && (
          <>
            <CurrentSaleReadOnly>
              <IconTextButton
                as={Link}
                icon={faDollarSign}
                color="white"
                to={sellPath}
              >
                View sale
              </IconTextButton>
            </CurrentSaleReadOnly>
            <CurrentSaleReadOnly>
              <IconTextButton
                dataTour="unsell"
                icon={faRedoAlt}
                color="white"
                onClick={() => setIsUnsellDialog(true)}
                disabled={!hasEditSaleLotPermission}
              >
                Unsell
              </IconTextButton>
              <IconTextButton
                dataTour="deliver"
                icon={faTruck}
                color="white"
                onClick={deliver}
              >
                Deliver
              </IconTextButton>
            </CurrentSaleReadOnly>
          </>
        )}
        {hasWriteAccessInCurrentSale &&
          status === saleLotStatuses.DELIVERED && (
            <CurrentSaleReadOnly>
              <IconTextButton
                dataTour="undeliver"
                icon={hasEditSaleLotPermission ? faRedoAlt : faLock}
                color="white"
                onClick={undeliver}
                disabled={!hasEditSaleLotPermission}
              >
                Undeliver
              </IconTextButton>
            </CurrentSaleReadOnly>
          )}
        {totalMassGrams > 0 && (
          <IconTextButton
            icon={faPrint}
            color="white"
            onClick={doPrintSaleLotWeightSheet}
          >
            Print Weight Sheet
          </IconTextButton>
        )}
        <ForCattle>
          <IconTextButton
            icon={faPrint}
            color="white"
            onClick={doPrintPlacard}
            dataTour="printPlacard"
          >
            Placard
          </IconTextButton>
        </ForCattle>
      </TabletDropdown>
      {isUnsellDialog && hasEditSaleLotPermission && (
        <Dialog
          closeSelf={() => setIsUnsellDialog(false)}
          handleSubmit={unsell}
          title="Unsell lot?"
          subtitle="Are you sure you want to undo this sale?"
          submitButtonText="Unsell"
          rejectButtonText="Cancel"
          icon={faUndoAlt}
        />
      )}
      <ConfirmDialog
        isOpen={showDeleteDialog}
        title={createModalTitle("this Consignment")}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {
          dispatch(deleteConsignment(consignment));
          setShowDeleteDialog(false);
        }}
      />
    </>
  );
};
