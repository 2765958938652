// Hardcoded mapping of hex to human readable colours used in SaleLot marking
// feature. Any changes made here must be made in backend code too.
export const MARKING_COLOUR_HEX_TO_NAME_MAP = {
  "#4A4A4A": "Black",
  "#D54141": "Red",
  "#FF5252": "Light Red",
  "#FE98F2": "Pink",
  "#52A2A2": "Teal",
  "#609A26": "Green",
  "#B8E986": "Light Green",
  "#81CFD8": "Aqua",
  "#4A90E2": "Blue",
  "#31619A": "Navy",
  "#BB62CD": "Purple",
  "#C0C0C0": "Light Gray",
  "#808080": "Gray",
  "#CCC138": "Gold",
  "#FFE800": "Yellow",
  "#FFFFFF": "White",
  "#FFA500": "Orange",
};
