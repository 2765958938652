import { intersection, uniq } from "lodash";
import { createSelector } from "reselect";

import { ExportSites } from "constants/exportSites";
import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { getConsignmentCode } from "lib/consignments";
import {
  doesSaleLotHaveOverflowPen,
  getBuyerHashFromSaleLot,
  isSaleLotBalanced,
  isSaleLotCounted,
  isSaleLotDelivered,
  isSaleLotInProgress,
} from "lib/saleLot";

import {
  getConsignments,
  getCurrentDeploymentSalesList,
  getGlobalSearchBySearchField,
  getPenScanLots,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScans,
  selectAreSomeSaleLotsSoldByPenScanLotIdLookup,
  selectCanSaleLotsHaveProgenyByPenScanLotIdLookup,
  selectConsignmentIdBySaleLotIdLookup,
  selectDeploymentByDeploymentSaleIdLookup,
  selectEidsBySaleLotIdLookup,
  selectExceptionsByConsignmentIdLookup,
  selectExceptionsBySaleLotIdLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
  selectIsWeighedByPenScanLotIdLookup,
  selectPenScanLotIdByEidLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByDeliveryPenIdLookup,
  selectSaleLotIdsByPenScanLotIdLookup,
  selectSaleLotsScanStatusesByPenScanLotIdLookup,
  selectEidsByPenScanLotIdLookup,
  selectReceivalLotIdByEidLookup,
  getReceivalLots,
  selectAuctionPenIdBySaleLotIdLookup,
  selectSaleLotHasImageLookup,
  selectSaleLotHasVideoLookup,
  selectConsignmentHasImageLookup,
  selectConsignmentHasVideoLookup,
} from "selectors";

import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";

import { isObjectChangedAfter } from "./lib";

// sitting here instead of selectors/penScanLots.js because of circular dependancies ;'(
export const selectPenScanLotIdsBySaleLotIdLookup = createSelector(
  [selectPenScanLotIdByEidLookup, selectEidsBySaleLotIdLookup],
  (penScanLotIdByEidLookup, eidsBySaleLotIdLookup) => {
    return Object.entries(eidsBySaleLotIdLookup).reduce(
      (acc, [saleLotId, saleLotEids]) => {
        acc[saleLotId] = uniq(
          saleLotEids.map(eid => penScanLotIdByEidLookup[eid]),
        );
        return acc;
      },
      {},
    );
  },
);

// sitting here instead of selectors/penScanLots.js because of circular dependancies ;'(
export const selectPenScanLotIdsByDeliveryPenIdLookup = createSelector(
  [selectSaleLotIdsByDeliveryPenIdLookup, selectPenScanLotIdsBySaleLotIdLookup],
  (saleLotIdsByDeliveryPenIdLookup, penScanLotIdsBySaleLotIdLookup) => {
    return Object.entries(saleLotIdsByDeliveryPenIdLookup).reduce(
      (acc, [deliveryPenId, saleLotIds]) => {
        acc[deliveryPenId] = uniq(
          saleLotIds
            .map(saleLotId => penScanLotIdsBySaleLotIdLookup[saleLotId])
            .flat(),
        );
        return acc;
      },
      {},
    );
  },
);

// sitting here instead of selectors/penScanLots.js because of circular dependancies ;'(
export const selectPenScanLotIdsByConsignmentIdLookup = createSelector(
  [selectPenScanLotIdsBySaleLotIdLookup, selectConsignmentIdBySaleLotIdLookup],
  (penScanLotIdsBySaleLotIdLookup, consignmentIdBySaleLotIdLookup) =>
    Object.entries(penScanLotIdsBySaleLotIdLookup).reduce(
      (acc, [saleLotId, penScanLotIds]) => {
        const consignmentId = consignmentIdBySaleLotIdLookup[saleLotId];
        if (!acc[consignmentId]) {
          acc[consignmentId] = [...penScanLotIds];
        } else {
          acc[consignmentId].push([...penScanLotIds]);
        }
        return acc;
      },
      {},
    ),
);

export const selectUnfilteredPenScanLotIds = createSelector(
  [getPenScanLots],
  penScanLots => Object.keys(penScanLots).map(penScanLotId => penScanLotId),
);

/**
 * Return all pen scan lot ids by deployment sale id via their linked
 * deployment ids
 */
export const selectPenScanlotIdsByDeploymentSaleIdLookup = createSelector(
  [
    getCurrentDeploymentSalesList,
    getPenScanLots,
    selectDeploymentByDeploymentSaleIdLookup,
  ],
  (deploymentSales, penScanLots, deploymentByDeploymentSaleIdLookup) => {
    return Object.values(deploymentSales).reduce((acc, deploymentSale) => {
      const deploymentSaleId = deploymentSale.deployment_sale_id;
      acc[deploymentSaleId] = Object.values(penScanLots)
        .filter(
          penScanLot =>
            penScanLot.deploymentId ===
            deploymentByDeploymentSaleIdLookup[deploymentSaleId]?.id,
        )
        .filter(Boolean)
        .map(penScanLot => penScanLot.id);
      return acc;
    }, {});
  },
);

/**
 * Return all pen scan lot ids associated with deployment sales that are linked
 * to the filtered agency ids
 */
const selectAgencyFilteredPenScanLotIds = createSelector(
  [
    getCurrentDeploymentSalesList,
    getGlobalSearchBySearchField(GlobalSearchFields.Agency),
    selectUnfilteredPenScanLotIds,
    selectPenScanlotIdsByDeploymentSaleIdLookup,
  ],
  (
    deploymentSales,
    agencyIds,
    unfilteredPenScanLotIds,
    penScanLotIdsByDeploymentSaleIdLookup,
  ) => {
    const value =
      agencyIds === null
        ? unfilteredPenScanLotIds
        : deploymentSales
            // check the selected agency id against their deployment sales agency id
            .filter(deploymentSale =>
              agencyIds.includes(deploymentSale.livestock_agency_id),
            )
            // get the pen scan lot ids from the deployment sales
            .map(
              deploymentSale =>
                penScanLotIdsByDeploymentSaleIdLookup[
                  deploymentSale.deployment_sale_id
                ],
            )
            .flat();
    return value;
  },
);

/**
 * Return pen scan lot ids for filtered auction pens based on their linked scans
 * in the associated sale lots
 */
const selectAuctionPenFilteredPenScanLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.AuctionPen),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByAuctionPenIdLookup,
    selectEidsBySaleLotIdLookup,
    getScans,
  ],
  (
    auctionPenIds,
    unfilteredPenScanLotIds,
    saleLotIdsByAuctionPenIdLookup,
    eidsBySaleLotIdLookup,
    scansByEidLookup,
  ) => {
    const filteredPenScanLotIds = auctionPenIds
      ?.map(auctionPenId => {
        // get the sale lots that are linked to the auction pen
        const saleLotIds = saleLotIdsByAuctionPenIdLookup[auctionPenId];

        // get the scans in those sale lots
        const eids = saleLotIds
          .map(saleLotId => eidsBySaleLotIdLookup[saleLotId])
          .flat();

        // get the pen scan lot ids based off the scans
        const penScanLotIds = eids
          .map(eid => scansByEidLookup[eid]?.pen_scan_lot_id)
          .flat();

        return penScanLotIds;
      })
      .flat();

    return auctionPenIds === null
      ? unfilteredPenScanLotIds
      : filteredPenScanLotIds;
  },
);

/**
 * filter and return pen scan lot ids that have the filtered buyer ids
 * associated to them
 */
const selectBuyerFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Buyer),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (saleLots, buyerIds, unfilteredValues, saleLotIdsByPenScanLotIdLookup) =>
    buyerIds === null
      ? unfilteredValues
      : // filter sale lot ids based on if the buyer is included
        // in the global search field
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              buyerIds.includes(saleLots[saleLotId]?.buyer_id),
            ),
          )
          // return pen scan lot ids
          .map(([penScanLotId, ignored]) => penScanLotId),
);

/**
 * filter and return pen scan lot ids that have the filtered buyer and buyer way
 * combinations (buyer hash) associated to them
 */
const selectBuyerAndBuyerWayFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerAndBuyerWay),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (saleLots, buyerHashes, unfilteredValues, saleLotIdsByPenScanLotIdLookup) =>
    buyerHashes === null
      ? unfilteredValues
      : // filter sale lots based on if their buyer and buyer way combination
        // matches the global search field
        uniq(
          Object.entries(saleLotIdsByPenScanLotIdLookup)
            .filter(([ignored, saleLotIds]) =>
              saleLotIds?.some(saleLotId =>
                buyerHashes.includes(
                  getBuyerHashFromSaleLot(saleLots[saleLotId]),
                ),
              ),
            )
            // return pen scan lot ids
            .map(([penScanLotId, ignored]) => penScanLotId),
        ),
);

/**
 * Filter and return pen scan lot ids that have the filtered delivery pen ids
 * associated to them
 */
const selectDeliveryPenFilteredPenScanLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.DeliveryPen),
    selectUnfilteredPenScanLotIds,
    selectPenScanLotIdsByDeliveryPenIdLookup,
  ],
  (
    deliveryPenIds,
    unfilteredPenScanLotIds,
    penScanLotIdsByDeliveryPenIdLookup,
  ) => {
    // create a list for pen scan lot ids
    const filteredPenScanLotIds = [];
    // filter through delivery pens linked to pen scan lots returning
    // delivery pens that are present in the global search field
    Object.entries(penScanLotIdsByDeliveryPenIdLookup)
      .filter(([deliveryPenId, ignored]) =>
        deliveryPenIds?.includes(deliveryPenId),
      )
      // populate a list of filtered pen scan lot ids
      .forEach(([ignored, penScanLotIds]) =>
        penScanLotIds.forEach(penScanLotId => {
          filteredPenScanLotIds.push(penScanLotId);
        }),
      );
    return deliveryPenIds === null
      ? unfilteredPenScanLotIds
      : filteredPenScanLotIds;
  },
);

/**
 * Filter and return pen scan lot ids based on their linked consignments thats
 * has arrived boolean matches the global search
 */
const selectHasArrivedFilteredPenScanLotIds = createSelector(
  [
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.HasArrived),
    selectUnfilteredPenScanLotIds,
    selectPenScanLotIdsByConsignmentIdLookup,
  ],
  (
    consignments,
    hasArrived,
    unfilteredPenScanLotIds,
    penScanLotIdsByConsignmentIdLookup,
  ) =>
    hasArrived === null
      ? unfilteredPenScanLotIds
      : Object.values(consignments)
          // filter consignments based on if the has arrived boolean
          // matches the global search boolean
          .filter(consignment => consignment.hasArrived === hasArrived[0])
          // return pen scan lot ids associated to the filtered consignments
          .reduce(
            (acc, consignment) =>
              acc
                .concat(
                  penScanLotIdsByConsignmentIdLookup[consignment.id]?.map(
                    penScanLotId => penScanLotId,
                  ),
                )
                .filter(Boolean),
            [],
          ),
);

/**
 * Filter and return pen scan lot ids based on their linked sale lots
 * that have buyer exceptions based on the global search boolean
 */
const selectHasBuyerExceptionsFilteredPenScanLotIds = createSelector(
  [
    selectExceptionsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasBuyerExceptions),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
    selectPenScanLotIdsBySaleLotIdLookup,
  ],
  (
    exceptionsBySaleLotIdLookup,
    hasBuyerExceptions,
    unfilteredPenScanLotIds,
    saleLotIdsByPenScanLotIdLookup,
    penScanLotIdsBySaleLotIdLookup,
  ) => {
    // go through sale lot ids linked to pen scan lots
    // filter them based on if they have exceptions
    // equal to the global search boolean
    const filteredPenScanLotIds = Object.entries(saleLotIdsByPenScanLotIdLookup)
      .map(([ignored, saleLotIds]) => saleLotIds)
      .flat()
      .filter(saleLotId => {
        return (
          (exceptionsBySaleLotIdLookup[saleLotId]?.length === 0) ===
          hasBuyerExceptions?.[0]
        );
      })
      // return pen scan ids linked to the filtered sale lots
      .map(saleLotId => penScanLotIdsBySaleLotIdLookup[saleLotId])
      .flat()
      .filter(Boolean);

    return hasBuyerExceptions === null
      ? unfilteredPenScanLotIds
      : filteredPenScanLotIds;
  },
);

/**
 * Filter and return pen scan lot ids based on if their scans are
 * consignment scanned
 */
export const selectHasConsignmentScansFilteredPenScanLotIds = createSelector(
  [
    getSaleyardScanSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasConsignmentScans),
    selectUnfilteredPenScanLotIds,
    getScans,
  ],
  (
    saleyardScanSaleLots,
    eidsBySaleLotIdLookup,
    hasConsignmentScans,
    unfilteredPenScanLotIds,
    scanByEidLookup,
  ) =>
    hasConsignmentScans === null
      ? unfilteredPenScanLotIds
      : // go through eids linked to sale lots
        // filter for saleyard scans exist based on
        // the global search boolean
        Object.entries(eidsBySaleLotIdLookup)
          .filter(
            ([saleLotId, ignored]) =>
              Boolean(saleyardScanSaleLots[saleLotId]) ===
              hasConsignmentScans[0],
          )
          // return pen scan lot ids from the filtered eids
          .reduce(
            (acc, [ignored, eids]) =>
              acc.concat([
                ...eids.map(eid => scanByEidLookup[eid]?.pen_scan_lot_id),
              ]),
            [],
          ),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots have overflow pens
 */
const selectHasOverflowFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.HasOverflow),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (saleLots, hasOverflow, unfilteredValues, saleLotIdsByPenScanLotIdLookup) =>
    hasOverflow === null
      ? unfilteredValues
      : Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                doesSaleLotHaveOverflowPen(saleLots[saleLotId]) ===
                hasOverflow[0],
            ),
          )
          .map(([penScanLotId, ignored]) => penScanLotId),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots have progeny
 */
const selectHasProgenyFilteredPenScanLotIds = createSelector(
  [
    getPenScanLots,
    selectCanSaleLotsHaveProgenyByPenScanLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasProgeny),
    selectUnfilteredPenScanLotIds,
  ],
  (
    penScanLots,
    saleLotsCanHaveProgenyByPenScanLotIdLookup,
    hasProgeny,
    unfilteredPenScanLotIds,
  ) =>
    hasProgeny === null
      ? unfilteredPenScanLotIds
      : // go through each pen scan lot and filter according to
        // the has progeny global filter boolean
        Object.values(penScanLots)
          .filter(
            penScanLot =>
              saleLotsCanHaveProgenyByPenScanLotIdLookup[penScanLot.id] ===
              hasProgeny[0],
          )
          // return pen scan lot ids
          .map(penScanLot => penScanLot.id),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots have vendor exceptions
 */
export const selectHasVendorExceptionsFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    selectExceptionsByConsignmentIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasVendorExceptions),
    selectUnfilteredPenScanLotIds,
    getScans,
  ],
  (
    saleLots,
    exceptionsByConsignmentIdLookup,
    eidsBySaleLotIdLookup,
    hasVendorExceptions,
    unfilteredEids,
    scanByEidLookup,
  ) =>
    hasVendorExceptions === null
      ? unfilteredEids
      : // go through each sale lot and check if the linked consignment
        // has vendor exceptions equal to the global search boolean
        Object.values(saleLots)
          .filter(
            saleLot =>
              (exceptionsByConsignmentIdLookup[saleLot.consignment_id]
                .length !==
                0) ===
              hasVendorExceptions[0],
          )
          // return pen scan lot ids on filtered sale lots eids
          .reduce(
            (acc, saleLot) =>
              acc.concat([
                ...(eidsBySaleLotIdLookup[saleLot.id]
                  ?.map(eid => scanByEidLookup[eid]?.pen_scan_lot_id)
                  .flat() || []),
              ]),
            [],
          ),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots or scans are weighed
 */
const selectHasWeightFilteredPenScanLotIds = createSelector(
  [
    selectIsWeighedByPenScanLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredPenScanLotIds,
    getPenScanLots,
  ],
  (
    isWeighedByPenScanLotIdLookup,
    hasWeight,
    unfilteredPenScanLotIds,
    penScanLots,
  ) => {
    return hasWeight === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if they have a weight
        Object.values(penScanLots)
          .filter(
            penScanLot =>
              isWeighedByPenScanLotIdLookup[penScanLot.id] === hasWeight[0],
          )
          .map(penScanLot => penScanLot.id)
          .filter(Boolean);
  },
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots are balanced
 */
const selectIsBuyerBalancedFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsBuyerBalanced),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    isBuyerBalanced,
    unfilteredValues,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    isBuyerBalanced === null
      ? unfilteredValues
      : // filter sale lots on whether they are balanced based on
        // the global search field
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                isSaleLotBalanced(saleLots[saleLotId]) === isBuyerBalanced[0],
            ),
          )
          // return the pen scan lot ids
          .map(([penScanLotId, ignored]) => penScanLotId),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots are counted
 */
const selectIsCountedFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsCounted),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (saleLots, isCounted, unfilteredValues, saleLotIdsByPenScanLotIdLookup) =>
    isCounted === null
      ? unfilteredValues
      : // filter sale lots based on if they are counted according to
        // the global search boolean
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                isSaleLotCounted(saleLots[saleLotId]) === isCounted[0],
            ),
          )
          // return the pen scan lot ids
          .map(([penScanLot, ignored]) => penScanLot),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots are delivered
 */
const selectIsDeliveredFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsDelivered),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (saleLots, isDelivered, unfilteredValues, saleLotIdsByPenScanLotIdLookup) =>
    isDelivered === null
      ? unfilteredValues
      : // filter sale lots by weigh lot, checking if any linked sale lots
        // are delivered according the the global search boolean
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                isSaleLotDelivered(saleLots[saleLotId]) === isDelivered[0],
            ),
          )
          // return pen scan lot ids
          .map(([penScanLot, ignored]) => penScanLot),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots are counted
 */
const selectIsEidCompliantFilteredPenScanLotIds = createSelector(
  [
    selectSaleLotsScanStatusesByPenScanLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsEidCompliant),
    selectUnfilteredPenScanLotIds,
    getPenScanLots,
  ],
  (
    saleLotScanStatusesByPenScanLotIdLookup,
    isEidCompliant,
    unfilteredPenScanLotIds,
    penScanLots,
  ) =>
    isEidCompliant === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if their sale lot scan status is equal to
        // the global search boolean
        Object.values(penScanLots)
          .filter(
            penScanLot =>
              saleLotScanStatusesByPenScanLotIdLookup[penScanLot.id].includes(
                ScanStatus.PASS,
              ) === isEidCompliant[0],
          )
          // return the pen scan lots
          .map(penScanLot => penScanLot.id),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots are in progress
 */
const selectIsInProgressFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getPenScanLots,
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    penScanLots,
    consignments,
    isInProgress,
    unfilteredPenScanLotIds,
    saleLotIdsByPenScanLotIdLookup,
  ) => {
    return isInProgress === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if any of their associated sale lots
        // are in progress
        Object.values(penScanLots)
          .filter(penScanLot =>
            saleLotIdsByPenScanLotIdLookup[penScanLot.id]
              ?.map(saleLotId =>
                isSaleLotInProgress(
                  saleLots[saleLotId],
                  consignments[saleLots[saleLotId]?.consignment_id],
                ),
              )
              .includes(isInProgress[0]),
          )
          // return pen scan lot ids
          .map(penScanLot => penScanLot.id);
  },
);

/**
 * Filter and return pen scan lot ids based on if any of their linked
 * sale lots are sold
 */
const selectIsSoldFilteredPenScanLotIds = createSelector(
  [
    getPenScanLots,
    selectAreSomeSaleLotsSoldByPenScanLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSold),
    selectUnfilteredPenScanLotIds,
  ],
  (
    penScanLots,
    areSomeSoldByPenScanLotIdLookup,
    isSold,
    unfilteredPenScanLotIds,
  ) =>
    isSold === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots by if any of their associated sale lots are
        // sold based on the global search boolean
        Object.values(penScanLots)
          .filter(
            penScanLot =>
              areSomeSoldByPenScanLotIdLookup[penScanLot.id] === isSold[0],
          )
          // return pen scan lot ids
          .map(penScanLot => penScanLot.id),
);

/**
 * Returns a lookup for pen scan lot ids keyed by consignment ids
 * based on the linked scans
 */
const selectConsignmentIdsByPenScanLotIdLookup = createSelector(
  [
    getPenScanLots,
    selectEidsByPenScanLotIdLookup,
    selectReceivalLotIdByEidLookup,
    getScans,
    getReceivalLots,
  ],
  (
    penScanLots,
    eidsByPenScanLotIdLookup,
    receivalLotIdLookupByEidLookup,
    scansLookup,
    receivalLotLookup,
  ) =>
    Object.values(penScanLots).reduce((acc, penScanLot) => {
      const eids = eidsByPenScanLotIdLookup[penScanLot.id] || [];
      const eidsWithReceivalLots = eids.filter(
        eid => !!scansLookup[eid]?.receival_lot_id,
      );
      const consignmentIds = uniq(
        eidsWithReceivalLots.map(eid => {
          const receivalLotId = receivalLotIdLookupByEidLookup[eid];
          return receivalLotLookup[receivalLotId]?.consignmentId;
        }),
      );
      acc[penScanLot.id] = consignmentIds || [];
      return acc;
    }, {}),
);

/**
 * Filter and return pen scan lot ids based on if any of their linked
 * consignments are pre sale balanced
 */
const selectIsVendorPreSaleBalancedFilteredPenScanLotIds = createSelector(
  [
    getPenScanLots,
    selectIsPreSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPreSaleVendorBalanced),
    selectUnfilteredPenScanLotIds,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    penScanLots,
    isPreSaleBalancedByConsignmentId,
    isVendorPresaleBalanced,
    unfilteredPenScanLotIds,
    consignmentIdsByPenScanLotIdLookup,
  ) =>
    isVendorPresaleBalanced === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if the linked consignment is pre sale balanced
        // in accordance with the global search boolean
        Object.values(penScanLots)
          .filter(
            penScanLot =>
              isPreSaleBalancedByConsignmentId[
                consignmentIdsByPenScanLotIdLookup[penScanLot.id]?.[0]
              ] === isVendorPresaleBalanced[0],
          )
          // return filtered pen scan lot ids
          .map(penScanLot => penScanLot.id),
);

/**
 * Filter and return pen scan lot ids based on if any of their linked
 * consignments are post sale balanced
 */
const selectIsVendorPostSaleBalancedFilteredPenScanLotIds = createSelector(
  [
    getPenScanLots,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPostSaleVendorBalanced),
    selectUnfilteredPenScanLotIds,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    penScanLots,
    isPostSaleBalancedByConsignmentIdLookup,
    isVendorBalanced,
    unfilteredPenScanLotIds,
    consignmentIdsByPenScanLotIdLookup,
  ) => {
    return isVendorBalanced === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if the linked consignment is post sale balanced
        // in accordance with the global search boolean
        Object.values(penScanLots)
          .filter(
            penScanLot =>
              isPostSaleBalancedByConsignmentIdLookup[
                consignmentIdsByPenScanLotIdLookup[penScanLot.id]?.[0]
              ] === isVendorBalanced[0],
          )
          // return filtered pen scan lot ids
          .map(penScanLot => penScanLot.id);
  },
);

/**
 * Filter and return pen scan lot ids based on if any of their linked
 * sale lot's labels are included in the global search
 */
const selectLabelFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Label),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (saleLots, labels, unfilteredValues, saleLotIdsByPenScanLotIdLookup) =>
    labels === null
      ? unfilteredValues
      : // filter sale lots based on if they have labels and if the labels
        // are in the global search field
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                saleLots[saleLotId].labels?.length > 0 &&
                labels.some(
                  labelIds =>
                    intersection(saleLots[saleLotId].labels, labelIds).length >
                    0,
                ),
            ),
          )
          // return pen scan lot ids from filtered sale lots
          .map(([penScanLotId, ignored]) => penScanLotId),
);

/**
 * Filter and return pen scan lot ids based on if any of their linked
 * sale lot's auction pen ids are included in the global search
 * lanes auction pen ids
 */
const selectLaneFilteredPenScanLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.Lane),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
  ],
  (
    lanes,
    unfilteredValues,
    saleLotIdsByPenScanLotIdLookup,
    auctionPenIdBySaleLotIdLookup,
  ) =>
    lanes === null
      ? unfilteredValues
      : // filter sale lots for auction pen ids available in the
        // lanes global search field
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              lanes.some(lane =>
                lane.includes(auctionPenIdBySaleLotIdLookup[saleLotId]),
              ),
            ),
          )
          // return pen scan lot ids from the filtered sale lots
          .map(([penScanLotId, ignored]) => penScanLotId),
);

/**
 * Filter and return pen scan lot ids based on if any of their linked
 * sale lot's overflow pens are included in the overflow pen global
 * search
 */
const selectOverflowPenFilteredPenScanLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.OverflowPen),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (overFlowPens, unfilteredValues, saleLotIdsByPenScanLotIdLookup) =>
    overFlowPens === null
      ? unfilteredValues
      : // filter for any sale lots linked to each pen scan lot that
        // has an overflow pen
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId => overFlowPens.includes(saleLotId)),
          )
          // return the pen scan lot ids
          .map(([penScanLot, ignored]) => penScanLot),
);

/**
 * Filter and return pen scan lot ids based on if any of their linked
 * sale lot's pricing type is in the global search's pricing types
 */
const selectPricingTypeFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.PricingType),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (saleLots, pricingTypes, unfilteredValues, saleLotIdsByPenScanLotIdLookup) =>
    pricingTypes === null
      ? unfilteredValues
      : // filter for any sale lots linked to each pen scan lot thats
        // pricing type is included in the global search field
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              pricingTypes.includes(saleLots[saleLotId].pricing_type_id),
            ),
          )
          // return the pen scan lot ids
          .map(([penScanLot, ignored]) => penScanLot),
);

/**
 * Filter and return pen scan lot ids based on if their sale round
 * is in the global search's sale rounds
 */
export const selectSaleRoundFilteredPenScanLotIds = createSelector(
  [
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.SaleRound),
    selectUnfilteredPenScanLotIds,
  ],
  (penScanLots, saleRoundIds, unfilteredPenScanLotIds) =>
    saleRoundIds === null
      ? unfilteredPenScanLotIds
      : // filter sale lots linked to pen scan lots based on if their sale round
        // ids exist in the global search
        Object.entries(penScanLots)
          .filter(([_ignored, penScanLot]) => {
            return saleRoundIds.includes(penScanLot.saleRoundId);
          })
          // return pen scan lot ids
          .map(([_ignored, penScanLot]) => {
            return penScanLot.id;
          }),
);

/**
 * Filter and return pen scan lot ids based on if their eids
 * is in the global search's eids
 */
const selectScanFilteredPenScanLotIds = createSelector(
  [
    selectPenScanLotIdByEidLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Scan),
    selectUnfilteredPenScanLotIds,
  ],
  (penScanLotIdByEidLookup, eids, unfilteredPenScanLotIds) =>
    eids === null
      ? unfilteredPenScanLotIds
      : // return the pen scan lot ids that have the associated
        // eid from the gloabl search field
        uniq(eids.map(eid => penScanLotIdByEidLookup[eid])).filter(Boolean),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lot's third party is in the global search third parties
 */
const selectThirdPartyFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.ThirdParty),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    thirdPartyIds,
    unfilteredValues,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    thirdPartyIds === null
      ? unfilteredValues
      : // filter sale lots associated to pen scan lot ids for any sale lots
        // include the third parties in the global search field
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              thirdPartyIds.includes(saleLots[saleLotId].thirdPartyId),
            ),
          )
          // return the filtered pen scan lot ids
          .map(([penScanLotId, ignored]) => penScanLotId),
);

export const selectVendorIdsByPenScanLotIdLookup = createSelector(
  [selectConsignmentIdsByPenScanLotIdLookup, getConsignments],
  (consignmentIdsByPenScanLotIdLookup, consignmentByIdLookup) => {
    return Object.entries(consignmentIdsByPenScanLotIdLookup).reduce(
      (acc, [penScanLotId, consignmentIds]) => {
        acc[penScanLotId] = consignmentIds.map(
          consignmentId => consignmentByIdLookup[consignmentId]?.vendor_id,
        );
        return acc;
      },
      {},
    );
  },
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * vendor is in the global search's vendors
 */
const selectVendorFilteredPenScanLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.Vendor),
    selectUnfilteredPenScanLotIds,
    selectVendorIdsByPenScanLotIdLookup,
  ],
  (vendorIds, unfilteredPenScanLotIds, vendorIdsByPenScanLotIdLookup) =>
    vendorIds === null
      ? unfilteredPenScanLotIds
      : // filter vendor ids linked to pen scan lots that are present
        // in the global search field
        Object.entries(vendorIdsByPenScanLotIdLookup)
          .filter(
            ([ignored, penScanLotVendorIds]) =>
              intersection(vendorIds, penScanLotVendorIds).length,
          )
          // return pen scan lot ids
          .map(([penScanLot, ignored]) => penScanLot),
);

/**
 * Create a lookup for pen scan lot ids associated to vendor
 * numbers on consignments
 */
const selectVendorNumbersByPenScanLotIdLookup = createSelector(
  [selectConsignmentIdsByPenScanLotIdLookup, getConsignments],
  (consignmentIdsByPenScanLotIdLookup, consignmentByIdLookup) => {
    return Object.entries(consignmentIdsByPenScanLotIdLookup).reduce(
      (acc, [penScanLotId, consignmentIds]) => {
        acc[penScanLotId] = consignmentIds.map(consignmentId =>
          getConsignmentCode(consignmentByIdLookup[consignmentId]),
        );
        return acc;
      },
      {},
    );
  },
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * consignments vendor number is in the global search's vendor
 * numbers
 */
const selectVendorNumberFilteredPenScanLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.VendorNumber),
    selectUnfilteredPenScanLotIds,
    selectVendorNumbersByPenScanLotIdLookup,
  ],
  (
    vendorNumbers,
    unfilteredPenScanLotIds,
    vendorNumbersByPenScanLotIdLookup,
  ) =>
    vendorNumbers === null
      ? unfilteredPenScanLotIds
      : // filter vendor numbers linked to pen scan lots that are present
        // in the global search field
        Object.entries(vendorNumbersByPenScanLotIdLookup)
          .filter(
            ([_ignored, penScanLotVendorNumbers]) =>
              intersection(vendorNumbers, penScanLotVendorNumbers).length,
          )
          // return pen scan lot ids
          .map(([penScanLot, _ignored]) => penScanLot),
);

/**
 * Create a lookup for pen scan lot ids associated to vendor
 * PICs on consignments
 */
const selectVendorPicsByPenScanLotIdLookup = createSelector(
  [selectConsignmentIdsByPenScanLotIdLookup, getConsignments],
  (consignmentIdsByPenScanLotIdLookup, consignmentByIdLookup) => {
    return Object.entries(consignmentIdsByPenScanLotIdLookup).reduce(
      (acc, [penScanLotId, consignmentIds]) => {
        acc[penScanLotId] = consignmentIds.map(
          consignmentId =>
            consignmentByIdLookup[consignmentId]?.vendor_property_id,
        );
        return acc;
      },
      {},
    );
  },
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * consignments vendor PICs are in the global search's vendor
 * PICs
 */
const selectVendorPicFilteredPenScanLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.VendorPic),
    selectUnfilteredPenScanLotIds,
    selectVendorPicsByPenScanLotIdLookup,
  ],
  (vendorPics, unfilteredPenScanLotIds, vendorPicsByPenScanLotId) =>
    vendorPics === null
      ? unfilteredPenScanLotIds
      : // filter vendor PICs linked to pen scan lots that are present
        // in the global search field
        Object.entries(vendorPicsByPenScanLotId)
          .filter(
            ([_ignored, penScanLotVendorPics]) =>
              intersection(vendorPics, penScanLotVendorPics).length,
          )
          // return pen scan lot ids
          .map(([penScanLot, _ignored]) => penScanLot),
);

/**
 * Filter and return pen scan lot ids based on if their linked
 * sale lots buyer PIC is in the global search's buyer PIC
 */
const selectBuyerPicFilteredPenScanLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerPic),
    selectUnfilteredPenScanLotIds,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (saleLots, propertyIds, unfilteredValues, saleLotIdsByPenScanLotIdLookup) =>
    propertyIds === null
      ? unfilteredValues
      : // filter sale lots associated to pen scan lots thats destination properties
        // are also in the global search field
        Object.entries(saleLotIdsByPenScanLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              propertyIds.includes(saleLots[saleLotId].destination_property_id),
            ),
          )
          // return the associated pen scan lot ids
          .map(([penScanLotId, ignored]) => penScanLotId),
);

/**
 * Filter and return pen scan lot ids based on if they were
 * created or modified after the global filtered checkpoints
 */
const selectCheckpointFilteredPenScanLotIds = createSelector(
  [
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Checkpoint),
    selectUnfilteredPenScanLotIds,
  ],
  (penScanLots, checkpoints, unfilteredValues) =>
    checkpoints === null
      ? unfilteredValues
      : // filter pen scan lots that have been updated or created after
        // the selected checkpoint
        Object.values(penScanLots)
          .filter(penScanLot =>
            isObjectChangedAfter(penScanLot, checkpoints[0]),
          )
          // return the pen scan lot ids
          .map(penScanLot => penScanLot.id),
);

/**
 * Filter and return pen scan lot ids based on if any of their
 * linked sale lots are auctions plus compliant
 */
const selectIsAuctionsPlusCompliantFilteredPenScanLotIds = createSelector(
  [
    getPenScanLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredPenScanLotIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsAuctionsPlusCompliant),
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    penScanLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredPenScanLotIds,
    searchField,
    saleLotIdsByPenScanLotIdLookup,
  ) => {
    return searchField === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on their linked sale lots being
        // auction plus compliant in line with the global search value
        Object.values(penScanLots)
          .filter(penScanLot =>
            saleLotIdsByPenScanLotIdLookup[penScanLot.id]?.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.AUCTIONS_PLUS
                ] === searchField[0],
            ),
          )
          // return the pen scan lot ids
          .map(penScanLot => penScanLot.id);
  },
);

/**
 * Filter and return pen scan lot ids based on if any of their
 * linked sale lots are stock live compliant
 */
const selectIsStockLiveCompliantFilteredPenScanLotIds = createSelector(
  [
    getPenScanLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredPenScanLotIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsStockLiveCompliant),
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    penScanLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredPenScanLotIds,
    searchField,
    saleLotIdsByPenScanLotIdLookup,
  ) => {
    return searchField === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on their linked sale lots being
        // stock live compliant in line with the global search value
        Object.values(penScanLots)
          .filter(penScanLot =>
            saleLotIdsByPenScanLotIdLookup[penScanLot.id]?.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.STOCK_LIVE
                ] === searchField[0],
            ),
          )
          // return the pen scan lot ids
          .map(penScanLot => penScanLot.id);
  },
);

/**
 * Filter and return pen scan lot ids based on if any of their
 * linked sale lots have images uploaded
 */
const selectIsSaleLotImageUploadedPenScanLotIds = createSelector(
  [
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotImageUploaded),
    selectUnfilteredPenScanLotIds,
    selectSaleLotHasImageLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    penScanLots,
    IsSaleLotImageUploaded,
    unfilteredPenScanLotIds,
    SaleLotHasImageLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    IsSaleLotImageUploaded === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if their linked sale lot has an image uploaded
        // in line with the global search boolean
        Object.values(penScanLots)
          .filter(penScanLot =>
            saleLotIdsByPenScanLotIdLookup[penScanLot.id]?.some(
              saleLotId =>
                SaleLotHasImageLookup[saleLotId] === IsSaleLotImageUploaded[0],
            ),
          )
          // return the pen scan lot ids
          .map(penScanLot => penScanLot.id),
);

/**
 * Filter and return pen scan lot ids based on if any of their
 * linked sale lots have videos uploaded
 */
const selectIsSaleLotVideoUploadedPenScanLotIds = createSelector(
  [
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotVideoUploaded),
    selectUnfilteredPenScanLotIds,
    selectSaleLotHasVideoLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    penScanLots,
    isSaleLotVideoUploaded,
    unfilteredPenScanLotIds,
    saleLotHasVideoLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    isSaleLotVideoUploaded === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if their linked sale lot has a video uploaded
        // in line with the global search boolean
        Object.values(penScanLots)
          .filter(penScanLot =>
            saleLotIdsByPenScanLotIdLookup[penScanLot.id]?.some(
              saleLotId =>
                saleLotHasVideoLookup[saleLotId] === isSaleLotVideoUploaded[0],
            ),
          )
          // return the pen scan lot ids
          .map(penScanLot => penScanLot.id),
);

/**
 * Filter and return pen scan lot ids based on if any of their
 * linked consignments have images uploaded
 */
const selectIsConsignmentImageUploadedPenScanLotIds = createSelector(
  [
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsConsignmentImageUploaded),
    selectUnfilteredPenScanLotIds,
    selectConsignmentHasImageLookup,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    penScanLots,
    isConsignmentImageUploaded,
    unfilteredPenScanLotIds,
    consignmentHasImageLookup,
    consignmentIdsByPenScanLotIdLookup,
  ) =>
    isConsignmentImageUploaded === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if their linked consignment has an image uploaded
        // in line with the global search boolean
        Object.values(penScanLots)
          .filter(penScanLot =>
            consignmentIdsByPenScanLotIdLookup[penScanLot.id]?.some(
              consignmentId =>
                consignmentHasImageLookup[consignmentId] ===
                isConsignmentImageUploaded[0],
            ),
          )
          // return the pen scan lot ids
          .map(penScanLot => penScanLot.id),
);

/**
 * Filter and return pen scan lot ids based on if any of their
 * linked consignments have videos uploaded
 */
const selectIsConsignmentVideoUploadedPenScanLotIds = createSelector(
  [
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsConsignmentVideoUploaded),
    selectUnfilteredPenScanLotIds,
    selectConsignmentHasVideoLookup,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    penScanLots,
    isConsignmentVideoUploaded,
    unfilteredPenScanLotIds,
    consignmentHasVideoLookup,
    consignmentIdsByPenScanLotIdLookup,
  ) =>
    isConsignmentVideoUploaded === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if their linked consignment has a video uploaded
        // in line with the global search boolean
        Object.values(penScanLots)
          .filter(penScanLot =>
            consignmentIdsByPenScanLotIdLookup[penScanLot.id]?.some(
              consignmentId =>
                consignmentHasVideoLookup[consignmentId] ===
                isConsignmentVideoUploaded[0],
            ),
          )
          // return the pen scan lot ids
          .map(penScanLot => penScanLot.id),
);

/**
 * Filter and return pen scan lot ids based on if any of their
 * linked sale lots have marks included in the global search's
 * marks filter
 */
const selectMarksFilteredPenScanLotIds = createSelector(
  [
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredPenScanLotIds,
  ],
  (penScanLots, marks, unfilteredPenScanLotIds) =>
    marks === null
      ? unfilteredPenScanLotIds
      : // filter pen scan lots based on if any of their associated sale lots
        // have marks that are included in the global search field
        Object.values(penScanLots)
          .filter(
            penScanLot =>
              intersection(
                marks,
                penScanLot.marks.map(mark => mark.location),
              ).length > 0,
          )
          // return the pen scan lot ids
          .map(penScanLot => penScanLot.id),
);

export const selectFilteredPenScanLotIds = createSelector(
  [
    selectAgencyFilteredPenScanLotIds,
    selectAuctionPenFilteredPenScanLotIds,
    selectBuyerFilteredPenScanLotIds,
    selectBuyerAndBuyerWayFilteredPenScanLotIds,
    selectDeliveryPenFilteredPenScanLotIds,
    selectHasArrivedFilteredPenScanLotIds,
    selectHasBuyerExceptionsFilteredPenScanLotIds,
    selectHasConsignmentScansFilteredPenScanLotIds,
    selectHasOverflowFilteredPenScanLotIds,
    selectHasProgenyFilteredPenScanLotIds,
    selectHasVendorExceptionsFilteredPenScanLotIds,
    selectHasWeightFilteredPenScanLotIds,
    selectIsBuyerBalancedFilteredPenScanLotIds,
    selectIsCountedFilteredPenScanLotIds,
    selectIsDeliveredFilteredPenScanLotIds,
    selectIsEidCompliantFilteredPenScanLotIds,
    selectIsInProgressFilteredPenScanLotIds,
    selectIsSoldFilteredPenScanLotIds,
    selectIsVendorPreSaleBalancedFilteredPenScanLotIds,
    selectIsVendorPostSaleBalancedFilteredPenScanLotIds,
    selectLabelFilteredPenScanLotIds,
    selectLaneFilteredPenScanLotIds,
    selectOverflowPenFilteredPenScanLotIds,
    selectPricingTypeFilteredPenScanLotIds,
    selectSaleRoundFilteredPenScanLotIds,
    selectScanFilteredPenScanLotIds,
    selectThirdPartyFilteredPenScanLotIds,
    selectVendorFilteredPenScanLotIds,
    selectVendorNumberFilteredPenScanLotIds,
    selectVendorPicFilteredPenScanLotIds,
    selectBuyerPicFilteredPenScanLotIds,
    selectCheckpointFilteredPenScanLotIds,
    selectIsAuctionsPlusCompliantFilteredPenScanLotIds,
    selectIsStockLiveCompliantFilteredPenScanLotIds,
    selectIsSaleLotImageUploadedPenScanLotIds,
    selectIsSaleLotVideoUploadedPenScanLotIds,
    selectIsConsignmentImageUploadedPenScanLotIds,
    selectIsConsignmentVideoUploadedPenScanLotIds,
    selectMarksFilteredPenScanLotIds,
  ],
  (
    agencyFilteredPenScanLotIds,
    auctionPenFilteredPenScanLotIds,
    buyerFilteredPenScanLotIds,
    buyerAndBuyerWayFilteredPenScanLotIds,
    deliveryPenFilteredPenScanLotIds,
    hasArrivedFilteredPenScanLotIds,
    hasBuyerExceptionsFilteredPenScanLotIds,
    hasConsignmentScansFilteredPenScanLotIds,
    hasOverflowFilteredPenScanLotIds,
    hasProgenyFilteredPenScanLotIds,
    hasVendorExceptionsFilteredPenScanLotIds,
    hasWeightFilteredPenScanLotIds,
    isBuyerBalancedFilteredPenScanLotIds,
    isCountedFilteredPenScanLotIds,
    isDeliveredFilteredPenScanLotIds,
    isEidCompliantFilteredPenScanLotIds,
    isInProgressFilteredPenScanLotIds,
    isSoldFilteredPenScanLotIds,
    isVendorPreSaleBalancedFilteredPenScanLotIds,
    isVendorPostSaleBalancedFilteredPenScanLotIds,
    labelFilteredPenScanLotIds,
    laneFilteredPenScanLotIds,
    overflowPenFilteredPenScanLotIds,
    pricingTypeFilteredPenScanLotIds,
    saleRoundFilteredPenScanLotIds,
    scanFilteredPenScanLotIds,
    thirdPartyFilteredPenScanLotIds,
    vendorFilteredPenScanLotIds,
    vendorNumberFilteredPenScanLotIds,
    vendorPicFilteredPenScanLotIds,
    buyerPicFilteredPenScanLotIds,
    checkpointFilteredPenScanLotIds,
    isAuctionsPlusCompliantFilteredPenScanLotIds,
    isStockLiveCompliantFilteredPenScanLotIds,
    isSaleLotImageUploadedFilteredPenScanLotIds,
    isSaleLotVideoUploadedFilteredPenScanLotIds,
    isConsignmentImageUploadedPenScanLotIds,
    isConsignmentVideoUploadedPenScanLotIds,
    marksFilteredPenScanLotIds,
  ) => {
    return intersection(
      agencyFilteredPenScanLotIds,
      auctionPenFilteredPenScanLotIds,
      buyerFilteredPenScanLotIds,
      buyerAndBuyerWayFilteredPenScanLotIds,
      deliveryPenFilteredPenScanLotIds,
      hasArrivedFilteredPenScanLotIds,
      hasBuyerExceptionsFilteredPenScanLotIds,
      hasConsignmentScansFilteredPenScanLotIds,
      hasOverflowFilteredPenScanLotIds,
      hasProgenyFilteredPenScanLotIds,
      hasVendorExceptionsFilteredPenScanLotIds,
      hasWeightFilteredPenScanLotIds,
      isBuyerBalancedFilteredPenScanLotIds,
      isCountedFilteredPenScanLotIds,
      isDeliveredFilteredPenScanLotIds,
      isEidCompliantFilteredPenScanLotIds,
      isInProgressFilteredPenScanLotIds,
      isSoldFilteredPenScanLotIds,
      isVendorPreSaleBalancedFilteredPenScanLotIds,
      isVendorPostSaleBalancedFilteredPenScanLotIds,
      labelFilteredPenScanLotIds,
      laneFilteredPenScanLotIds,
      overflowPenFilteredPenScanLotIds,
      pricingTypeFilteredPenScanLotIds,
      saleRoundFilteredPenScanLotIds,
      scanFilteredPenScanLotIds,
      thirdPartyFilteredPenScanLotIds,
      vendorFilteredPenScanLotIds,
      vendorNumberFilteredPenScanLotIds,
      vendorPicFilteredPenScanLotIds,
      buyerPicFilteredPenScanLotIds,
      checkpointFilteredPenScanLotIds,
      isAuctionsPlusCompliantFilteredPenScanLotIds,
      isStockLiveCompliantFilteredPenScanLotIds,
      isSaleLotImageUploadedFilteredPenScanLotIds,
      isSaleLotVideoUploadedFilteredPenScanLotIds,
      isConsignmentImageUploadedPenScanLotIds,
      isConsignmentVideoUploadedPenScanLotIds,
      marksFilteredPenScanLotIds,
    );
  },
);
