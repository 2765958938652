import queryString from "query-string";

import { MASTER_RULE, RULE } from "constants/actionTypes";

import {
  offlineActionCreator,
  passthroughSerializer,
  urlCheckAndAddIdAndAction,
} from "./lib";

const ruleUrlCreator = ({ action, changesSince, id } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/rules/", id, action),
    query: {
      changesSince,
    },
  });

export const RuleAction = offlineActionCreator(
  RULE,
  passthroughSerializer,
  ruleUrlCreator,
  false,
);

RuleAction.copy = (ruleId, options) => ({
  type: RULE.COPY.ACTION,
  ruleId,
  options,
});

RuleAction.moveUp = (ruleId, options) => ({
  type: RULE.MOVE_UP.ACTION,
  ruleId,
  options,
});

RuleAction.moveDown = (ruleId, options) => ({
  type: RULE.MOVE_DOWN.ACTION,
  ruleId,
  options,
});

const masterRuleUrlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/master-rules/", id, action),
    query: {
      changesSince,
    },
  });

export const MasterRuleAction = offlineActionCreator(
  MASTER_RULE,
  passthroughSerializer,
  masterRuleUrlCreator,
  false,
);

MasterRuleAction.copy = (masterRuleId, options) => ({
  type: MASTER_RULE.COPY.ACTION,
  masterRuleId,
  options,
});

MasterRuleAction.moveUp = (masterRuleId, options) => ({
  type: MASTER_RULE.MOVE_UP.ACTION,
  masterRuleId,
  options,
});

MasterRuleAction.moveDown = (masterRuleId, options) => ({
  type: MASTER_RULE.MOVE_DOWN.ACTION,
  masterRuleId,
  options,
});
