import * as Sentry from "@sentry/react";
import { isObject, sumBy } from "lodash";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import {
  addAuctionPen,
  addBidderRegistrationWithoutIncrementingBidderNumber,
  addConsignment,
  addSaleLot,
  bulkMoveScansOffline,
  bulkUpdateSaleLotsSerializer,
  BusinessAction,
  deleteSaleLotNoLongerExists,
  patchConsignment,
  patchSaleLot,
  patchSaleLotOffline,
  receiveSaleLot,
  requestAttachments,
  SaleLotAction,
  updateDeploymentAttributes,
} from "actions";

import {
  ADD_SALE_LOT_COMMIT,
  ADD_SALE_LOT_OFFLINE,
  COPY_SALE_LOT_TO_SALE,
  DELETE_SALE_LOT_COMMIT,
  DELETE_SALE_LOT_ROLLBACK,
  DEPLOYMENT_SALE,
  MERGE_SALE_LOT_COMMIT,
  MERGE_SALE_LOT_ROLLBACK,
  SALE_LOT,
  SEND_EMAIL_COMMIT,
  UPDATE_DEPLOYMENT_LABELS,
} from "constants/actionTypes";
import { PenTypes } from "constants/auctionPens";
import { UNKNOWN_BUSINESS_NAME } from "constants/businesses";
import {
  CLEARING_SALE_BUYER_REPORTS,
  CLEARING_SALE_VENDOR_REPORTS,
  getReportSentLabel,
} from "constants/clearingSaleAttributes";

import { calculateTotalPriceCents, calculateUnitPrice } from "lib";

import { getAuctionPenPayload } from "lib/auctionPens";
import { getLivestockSaleId, isOnWeighBridge } from "lib/navigation";
import { pluralize } from "lib/pluralize";
import {
  getBuyerHashFromSaleLot,
  getDeliverPatchInfo,
  hasBuyer,
  hasDestinationProperty,
  isSaleLotDelivered,
} from "lib/saleLot";
import { getDraftInformationFieldsFromScan, isScanWeighed } from "lib/scans";
import { isSentryActive } from "lib/sentry/config";
import toast from "lib/toast";
import { isUUID } from "lib/uuid";

import {
  getAgencyIdByConsignmentId,
  getAuctionPenById,
  getBidders,
  getConsignmentById,
  getCurrentSale,
  getDeliveryPenIdsByBuyerHash,
  getDeploymentIdBySaleLotId,
  getDeploymentSaleById,
  getDeviceLookup,
  getDraftingInformationIdByHash,
  getIsAnimalsInAutoDraftBySaleLotId,
  getLabels,
  getSaleLotById,
  getSaleLotIdsByBuyerId,
  getSaleLotIdsByVendorId,
  getSaleLots,
  getScansBySaleLotId,
  getSingleWeighById,
  getUnassignedScans,
  getUnknownBusinessIdsByAgencyId,
  getVendorSplitSaleLotById,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleLotIdsByDeploymentSaleIdLookup,
} from "selectors";

import { chainActionFactory } from "sagas/lib";

import { api } from "./api";

export function* fetchSaleLot(action) {
  try {
    const saleLotPromise = yield call(
      api.get,
      `/v2/salelots/${action.saleLotId}/`,
    );
    const saleLot = yield saleLotPromise;
    yield put(receiveSaleLot(saleLot));
  } catch (e) {
    yield call(api.handleFetchError, e, "sale lot", action);
  }
}

// ADD_SALE_LOT_COMMIT
function onAddSaleLot(action) {
  const { payload } = action;
  toast.success(`Salelot #${payload.lot_number} created successfully`);
}

// PATCH_SALE_LOT_COMMIT
function updateSaleLots(action) {
  const { meta, disabledToast } = action;
  if (!disabledToast) {
    const { actionText, lotNumber } = meta;
    try {
      toast.success(`Lot #${lotNumber || ""} ${actionText} successfully`);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
}

// PATCH_SALE_LOT_ROLLBACK
function* rollbackSaleLot(action) {
  const { meta } = action;
  try {
    const { saleLotId, lotNumber } = meta;
    if (meta.statusCode === 404) {
      // Sale lot does not exist
      toast.error(
        `Lot #${lotNumber} does not exist anymore, so could not be updated.`,
      );
      yield put(deleteSaleLotNoLongerExists(saleLotId));
    } else {
      yield call(fetchSaleLot, { saleLotId });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

function* ensureConsignmentArrived(consignmentId) {
  const state = yield select();
  const consignment = getConsignmentById(consignmentId)(state);
  if (consignment && !consignment.hasArrived) {
    yield put(
      patchConsignment({ hasArrived: true }, consignmentId, {
        changeReason: "Marked arrived",
      }),
    );
  }
}

function* updateConsignmentArrived(saleLotId, newConsignmentId) {
  const state = yield select();
  // At this point the reducer hasn't run, so we can check the state for the currently stored (previous) `consignment_id`.
  // If we're updating a non-saleyard auction type consignment, we might not have a value here.
  const existingConsignmentId =
    getSaleLotById(saleLotId)(state)?.consignment_id;
  if (newConsignmentId === existingConsignmentId || !existingConsignmentId) {
    return;
  }

  const consignment = getConsignmentById(existingConsignmentId)(state);

  // Check that the Consignment exists in the state - this is a bit awkward, but hey.
  if (consignment) {
    const consignmentSaleLotIds =
      selectSaleLotIdsByConsignmentIdLookup(state)[existingConsignmentId];
    // When there was only one Sale Lot in the Consignment, and it's just been moved to a different Consignment
    // and the consignment doesn't have a ReceivingPen
    if (consignmentSaleLotIds.length === 1 && !consignment.receiving_pen) {
      yield put(
        patchConsignment({ hasArrived: false }, existingConsignmentId, {
          changeReason: "Marked not arrived",
        }),
      );
    }
  }
}

function* onPatchSaleLot(action) {
  const { patch, options = {} } = action;
  const {
    ignored = false,
    actionText = "updated",
    disabledToast = false,
    changeReason = null,
  } = options;

  let extraChangeReason = null;
  const saleLotId = patch.id;

  const state = yield select();

  const saleLot =
    getSaleLotById(saleLotId)(state) ||
    getVendorSplitSaleLotById(saleLotId)(state);

  // Last gasp attempt to ensure that no changes can be made to the pens of a lot that is in auto draft pen - moving
  // it could make the Single Weigh open or close a gate, which, is not fun at all if you're in there.
  // We can't blanket block because the single weigh itself will update the weight of the lot as each animal
  // comes through.
  if (
    (patch.hasOwnProperty("deliveryPenId") ||
      patch.hasOwnProperty("auction_pen_id")) &&
    getIsAnimalsInAutoDraftBySaleLotId(saleLotId)(state)
  ) {
    toast.error(
      `Error updating Sale Lot - this Sale Lot has Animals in an Auto Draft Pen.`,
    );
    return;
  }

  // When the patch contains a new Consignment id, use that
  if (patch.consignment_id) {
    // Flag the new arrived status of the Consignment
    yield ensureConsignmentArrived(patch.consignment_id);

    // Revert the arrived status of the old Consignment if necessary
    yield updateConsignmentArrived(saleLotId, patch.consignment_id);
  }

  const saleRoundId = patch.sale_round_id || saleLot.sale_round_id;

  if (isObject(patch.auction_pen) && !patch.auction_pen.start_pen) {
    // If the start pen is removed from the lot, remove the auction pen.
    // This is handled a bit strangely, but is required because the start_pen input is type number which onChange
    // does not fire on empty values.
    patch.auction_pen_id = null;
  } else if (
    patch.auction_pen ||
    (patch.sale_round_id && patch.sale_round_id !== saleLot.sale_round_id)
  ) {
    let penData = getAuctionPenById(saleLot.auction_pen_id)(state) || null;
    if (patch.auction_pen) {
      // From the auction screen, auction_pen is actually the auction_pen_id - this is a bit(lot?)
      // of an ugly hack to handle that scenario.
      if (typeof patch.auction_pen === "object") {
        penData = { ...penData, ...patch.auction_pen };
      } else {
        penData = patch.auction_pen;
      }
    } else if (patch.auction_pen_id) {
      penData = getAuctionPenById(patch.auction_pen_id)(state);
    }

    // Explicit values for the pen found - just push it.
    const sellingPenPayload = getAuctionPenPayload(
      { saleRoundId, auction_pen: penData },
      "auction_pen",
      penData?.penType || PenTypes.SELLING,
    );
    if (sellingPenPayload && sellingPenPayload.start_pen) {
      patch.auction_pen_id = uuidv4();
      yield put(addAuctionPen(sellingPenPayload, patch.auction_pen_id));
      delete patch.auction_pen;
    }
  }

  if (
    patch.deliveryPen ||
    (patch.sale_round_id && patch.sale_round_id !== saleLot.sale_round_id)
  ) {
    let penData = null;
    if (patch.deliveryPen) {
      penData = patch.deliveryPen;
    } else if (patch.deliveryPenId) {
      penData = getAuctionPenById(patch.deliveryPenId)(state);
    } else if (saleLot.deliveryPenId) {
      penData = getAuctionPenById(saleLot.deliveryPenId)(state);
    }

    // Explicit values for the pen found - just push it.
    const deliveryPenPayload = getAuctionPenPayload(
      { saleRoundId, deliveryPen: penData },
      "deliveryPen",
      PenTypes.DELIVERY,
    );
    if (deliveryPenPayload && deliveryPenPayload.start_pen) {
      patch.deliveryPenId = uuidv4();
      yield put(addAuctionPen(deliveryPenPayload, patch.deliveryPenId));
      delete patch.deliveryPen;
    }
    // If there isn't a start pen, that means it has been removed, we should then remove the whole pen
    if (!deliveryPenPayload.start_pen) {
      patch.deliveryPenId = null;
      delete patch.deliveryPen;
    }
  }

  // When the buyer changes, we need to do a few checks

  // if the lot WAS previously sold, and is being set to a
  // new buyer, check for an existing delivery pen against
  // the new buyer/buyer way, and set if found.
  // If not found, REMOVE the existing delivery pen.

  // if the lot was NOT previously sold, and is being set
  // to a new buyer, check for an existing delivery pen
  // against the new buyer/buyerway,
  // and set if found.

  // If a buyer is being removed from a lot,
  // REMOVE the existing delivery pen.

  const wasSoldAndIsStillSold = patch.buyer_id && saleLot.buyer_id;
  const wasNotSoldAndIsNow = patch.buyer_id && !saleLot.buyer_id;
  const buyerHash = getBuyerHashFromSaleLot(patch);

  if (
    typeof patch.buyer_id !== "undefined" &&
    getBuyerHashFromSaleLot(patch) !== getBuyerHashFromSaleLot(saleLot)
  ) {
    const deliveryPenIds = getDeliveryPenIdsByBuyerHash(buyerHash)(state);

    if (wasSoldAndIsStillSold) {
      extraChangeReason = "Updated Buyer";
      if (!patch.deliveryPenId && deliveryPenIds.length > 0) {
        patch.deliveryPenId = deliveryPenIds.slice(-1)[0];
      } else {
        patch.deliveryPenId = null;
      }
    } else if (wasNotSoldAndIsNow) {
      extraChangeReason = "Sold";
      if (!patch.deliveryPenId && deliveryPenIds.length > 0) {
        patch.deliveryPenId = deliveryPenIds.slice(-1)[0];
      }
    } else {
      patch.deliveryPenId = null;
    }
  }

  if (
    patch.total_mass_grams !== undefined &&
    patch.total_price_cents === undefined
  ) {
    if (isSentryActive) {
      Sentry.captureMessage(
        "Attempted to update Sale Lot Weight without updating Price",
        {
          fingerprint: [SALE_LOT.UPDATE.ACTION, "missing-price"],
        },
      );
    }
  }

  const curentSingleWeigh = getSingleWeighById(
    state.singleWeighs.currentSingleWeighId,
  )(state);

  yield put(
    patchSaleLotOffline(
      patch,
      saleLot,
      ignored,
      actionText,
      disabledToast,
      curentSingleWeigh,
      isOnWeighBridge(),
      [changeReason, extraChangeReason].filter(Boolean).join(" - "),
    ),
  );
}

function* onSellSaleLotWithBidderRegistration(action) {
  const { patch, options } = action;

  const state = yield select();
  const bidderLookup = getBidders(state);
  const currentSaleCode = getCurrentSale(state)?.sale_code || "";

  const { bidderNumber } = patch;

  // look for a bidder with associated business
  const bidder =
    Object.values(bidderLookup).find(
      bidder =>
        String(bidder.registrationNumber) === String(bidderNumber) &&
        !!bidder.businessId,
    ) || null;

  // if we don't find a bidder, create a temporary business for the sale
  if (!bidder) {
    const businessId = uuidv4();
    yield put(
      BusinessAction.create({
        id: businessId,
        name: `Temporary Bidder [${bidderNumber}] - [${currentSaleCode}]`,
        isTemporary: true,
      }),
    );
    // create the bidder registration
    const bidderId = uuidv4();
    yield put(
      addBidderRegistrationWithoutIncrementingBidderNumber(bidderId, {
        businessId,
        registrationNumber: bidderNumber,
      }),
    );
    // Set the newly created business as the buyer to use.
    patch.buyer_id = businessId;
  } else if (
    !patch.buyer_id &&
    !patch.buyer_way &&
    !patch.destination_property_id
  ) {
    // if we have a bidder and no buyer information set the buyer from the existing bidder information
    // as this is called in an iteration thats first step has created the buyer
    patch.buyer_id = bidder.businessId;
  }

  yield put(patchSaleLot(patch, options));
}

function* onCreateSaleLot(action) {
  yield ensureConsignmentArrived(action.payload.consignment_id);
}

const getLotNumber = action =>
  action?.meta?.saleLot?.lotNumber || action?.meta?.saleLot?.lot_number;

// DELETE_SALE_LOT_COMMIT
function deleteSaleLotCommit(action) {
  toast.success(`Sale lot #${getLotNumber(action)} deleted successfully`);
}

function* deleteSaleLotRolllBack(action) {
  const { id } = action.meta.saleLot;
  yield call(fetchSaleLot, { saleLotId: id });
  toast.error(`${action.payload.response.error}`);
}

function* copySaleLotCommit(action) {
  // we want to fetch sale lots changes straight after a copy is made
  const { livestockSaleId } = action.meta;
  const state = yield select();
  if (livestockSaleId === getLivestockSaleId()) {
    // Most of the time when we're copying a salelot - that lot is moving to another sale
    // but in the instance that we're duplicating into the current sale we're pulling down
    // the salelot changes and the attachments to reflect the change
    const currentSale = getCurrentSale(state);
    action.sale = currentSale;

    yield SaleLotAction.requestChanges({
      changesSince: state.saleLots.lastModifiedTimestamp,
    });
    yield put(requestAttachments(action.sale));
  }
  toast.success(`Sale Lot copied successfully`);
}

function* rollbackSaleLotMerge(action) {
  const { fromSaleLotId, toSaleLotId } = action.meta;
  yield call(fetchSaleLot, { saleLotId: fromSaleLotId });
  yield call(fetchSaleLot, { saleLotId: toSaleLotId });
  toast.error(`Failed to merge Lot`);
}

function commitSaleLotMerge() {
  toast.success(`Sale Lots merged`);
}

export function* onCreateSaleLotAction(action) {
  const { saleLotId, payload } = action;

  const state = yield select();

  let consignmentId = payload.consignment_id;
  let vendorId = payload.vendor_id;
  let agencyId;

  const vendorPropertyId = payload.vendor_property_id || null;

  if (!isUUID(consignmentId)) {
    // if we pass in an agency id - use it as the agency id
    if (payload.agency_id) {
      agencyId = payload.agency_id;
    } else if (
      // else if we get a number for consignment id, interpret it as the agency id.
      // We'll need to create a consignment, either for an unknown business, or for the vendor if its provided.
      !vendorId
    ) {
      agencyId = consignmentId;

      // Make sure we have the unknown business.
      const unknownBusinessIds =
        getUnknownBusinessIdsByAgencyId(agencyId)(state);

      let unknownBusinessId = unknownBusinessIds?.[0];
      if (!unknownBusinessId) {
        // Go create it.
        unknownBusinessId = uuidv4();
        vendorId = unknownBusinessId;
        yield put(
          BusinessAction.create({
            id: unknownBusinessId,
            name: UNKNOWN_BUSINESS_NAME,
          }),
        );
      } else {
        vendorId = unknownBusinessId;
      }
    }

    const currentSale = getCurrentSale(state);
    consignmentId = uuidv4();
    // Create the consignment.

    yield put(
      addConsignment(
        consignmentId,
        {
          quantity: 0,
          agency: agencyId,
          vendor_id: vendorId,
          vendor_property_id: vendorPropertyId,
        },
        currentSale,
      ),
    );
  } else {
    // We need to find an agency id for the submission - we can do that from the consignment id.
    agencyId = getAgencyIdByConsignmentId(consignmentId)(state);
  }

  // If pen payloads are provided, (possibly) add them and apply them to the sale lot.
  let penId = null;

  const penPayload = getAuctionPenPayload(
    payload,
    "auction_pen",
    payload?.auction_pen?.penType === PenTypes.TEMP
      ? PenTypes.TEMP
      : PenTypes.SELLING,
  );
  if (penPayload && penPayload.start_pen) {
    penId = uuidv4();
    yield put(addAuctionPen(penPayload, penId));
  }

  let deliveryPenId = null;
  const deliveryPenPayload = getAuctionPenPayload(
    payload,
    "deliveryPen",
    PenTypes.DELIVERY,
  );
  if (deliveryPenPayload && deliveryPenPayload.start_pen) {
    deliveryPenId = uuidv4();
    yield put(addAuctionPen(deliveryPenPayload, deliveryPenId));
  }

  yield put(
    addSaleLot(
      {
        ...payload,
        deliveryPenId,
        auction_pen_id: penId,
        consignment_id: consignmentId,
        agency: agencyId,
      },
      saleLotId,
    ),
  );
}

function* onSendEmail(action) {
  /**
   * This is an interim solution to add a label to all buyers salelots after
   * a receipt or invoice has been sent to them.
   * The 'proper' solution would be to link the email object to the salelot
   * and store the report type on it aswell and build this from that data rather than use labels.
   */

  const { reportName, businessId } = action.meta;

  let saleLotIds = [];
  let state = yield select();

  if (CLEARING_SALE_VENDOR_REPORTS.includes(reportName)) {
    saleLotIds = getSaleLotIdsByVendorId(businessId)(state);
  } else if (CLEARING_SALE_BUYER_REPORTS.includes(reportName)) {
    saleLotIds = getSaleLotIdsByBuyerId(businessId)(state);
  }

  if (saleLotIds.length > 0) {
    const sale = getCurrentSale(state);
    const labelName = getReportSentLabel(reportName);

    for (const saleLotId of saleLotIds) {
      // Refresh the state at every iteration incase a label has been
      // created so we dont double up locally.
      state = yield select();
      const labels = Object.values(getLabels(state));
      const saleLot = getSaleLotById(saleLotId)(state);
      const deploymentId = getDeploymentIdBySaleLotId(saleLotId)(state);

      const label = labels.find(
        label =>
          label.deployment_id === deploymentId &&
          label.name === labelName &&
          label.species_id === sale.species_id,
      );
      if (label) {
        if (!saleLot.labels.includes(label)) {
          yield put(
            patchSaleLot(
              {
                labels: [...saleLot.labels, label.id],
                id: saleLot.id,
              },
              { changeReason: "Manual email report sent" },
            ),
          );
        }
      } else {
        const labelId = uuidv4();
        const payload = [
          {
            order: 0,
            deployment_id: deploymentId,
            name: labelName,
            species_id: sale.species_id,
            is_used: true,
            id: labelId,
            quick_select: false,
          },
        ];
        yield put(
          updateDeploymentAttributes(UPDATE_DEPLOYMENT_LABELS, payload),
        );
        yield put(
          patchSaleLot(
            {
              labels: [...saleLot.labels, labelId],
              id: saleLot.id,
            },
            { changeReason: "Manual email report sent" },
          ),
        );
      }
    }
  }
}

function* onSplitSingleWeighedSaleLot(action) {
  /**
   *  Splits all single weighed sale lots off the sale lot provided by sale lot id.
   *  It will create either one, or many new lots for the single weighed eid, with the total weight being that of the split, single weighed eids.
   *  It will create without a time_weighed in order to effectively clear the bulk weighed state, clear comments, and adjust
   *  lot quantity and price accordingly
   */

  const { saleLotId, shouldSplitToMultipleLots, shouldSubtractFromLotWeight } =
    action;

  const state = yield select();
  const saleLot = getSaleLotById(saleLotId)(state);
  const scans = getScansBySaleLotId(saleLotId)(state);

  const singleWeighedScans = scans.filter(isScanWeighed);

  // We shouldn't ever be here, but this will save trying to add 'no scans' to a sale lot.
  if (singleWeighedScans.length === 0) {
    return;
  }

  const totalWeightBeingMoved = sumBy(singleWeighedScans, "total_mass_grams");
  const unitPrice = calculateUnitPrice(saleLot);

  // Remove comments, lot number and time weighed from the existing lot.
  // id, the new weight, quantity and price will need to be done
  const newLotBasePayload = {
    ...saleLot,
    commentIds: [],
    lot_number: undefined,
    time_weighed: undefined,
  };

  if (shouldSplitToMultipleLots) {
    for (const scan of singleWeighedScans) {
      const newSaleLotId = uuidv4();
      const payload = {
        ...newLotBasePayload,
        id: newSaleLotId,
        total_mass_grams: scan.total_mass_grams,
        quantity: 1,
        total_price_cents: calculateTotalPriceCents({
          unitPrice,
          total_mass_grams: scan.total_mass_grams,
          pricing_type_id: saleLot.pricing_type_id,
          quantity: 1,
        }),
      };
      yield put(addSaleLot(payload, newSaleLotId));
      yield put(
        bulkMoveScansOffline([scan.EID], newSaleLotId, {
          [scan.EID]: scan.sale_lot_id,
        }),
      );
    }
  } else {
    const saleLotId = uuidv4();
    const payload = {
      ...saleLot,
      id: saleLotId,
      total_mass_grams: totalWeightBeingMoved,
      quantity: singleWeighedScans.length,
      total_price_cents: calculateTotalPriceCents({
        unitPrice,
        pricing_type_id: saleLot.pricing_type_id,
        total_mass_grams: totalWeightBeingMoved,
        quantity: singleWeighedScans.length,
      }),
    };
    yield put(addSaleLot(payload, saleLotId));
    const currentSaleLotMap = singleWeighedScans.reduce((map, scan) => {
      map[scan.EID] = scan.sale_lot_id;
      return map;
    }, {});
    yield put(
      bulkMoveScansOffline(
        singleWeighedScans.map(scan => scan.EID),
        saleLotId,
        currentSaleLotMap,
      ),
    );
  }

  const patchPayload = {
    id: saleLotId,
    quantity: Math.max(saleLot.quantity - singleWeighedScans.length, 0),
  };
  if (shouldSubtractFromLotWeight) {
    patchPayload.total_mass_grams = Math.max(
      saleLot.total_mass_grams - totalWeightBeingMoved,
      0,
    );
  } else {
    patchPayload.total_mass_grams = saleLot.total_mass_grams;
  }
  patchPayload.total_price_cents = calculateTotalPriceCents({
    unitPrice,
    pricing_type_id: saleLot.pricing_type_id,
    total_mass_grams: patchPayload.total_mass_grams,
    quantity: patchPayload.quantity,
  });

  yield put(patchSaleLot(patchPayload), {
    changeReason: "Single weigh split.",
  });
}

function* onUpdateDeploymentSale(action) {
  // If the user has changed export sites on the deployment sale, they
  // can also update all salelots in that sale using the same action.
  if (action.options?.updateExistingSaleLotExportSites) {
    const state = yield select();

    const { deploymentSaleId } = action.meta;

    const exportSites =
      getDeploymentSaleById(deploymentSaleId)(state).export_sites;
    const payload = selectSaleLotIdsByDeploymentSaleIdLookup(state)[
      deploymentSaleId
    ]?.map(id => ({ id, exportSites }));
    yield put(bulkUpdateSaleLotsSerializer(payload));
  }
}

function* onDraftSightingAction(action) {
  const { saleLotId, eid } = action;

  // Find this scan in the on-screen-holding-bit.
  const state = yield select();
  const unassignedScans = getUnassignedScans(state);
  if (unassignedScans[eid]) {
    // Find the draft related to it.
    const deviceLookup = getDeviceLookup(state);
    const { draftingInformationHash } = getDraftInformationFieldsFromScan(
      unassignedScans[eid],
      deviceLookup,
    );
    const draftingInformationId = getDraftingInformationIdByHash(
      draftingInformationHash,
    )(state);

    if (draftingInformationId) {
      // And fire.
      yield put(
        SaleLotAction.doDraftSighting({
          id: saleLotId,
          lastSeenAtTime: new Date(),
          lastSeenAtDraftId: draftingInformationId,
        }),
      );
    }
  }
}

function* onDeliverAllSaleLots() {
  const state = yield select();

  const saleLots = getSaleLots(state);

  const saleLotsWithBuyer = Object.values(saleLots).filter(saleLot =>
    hasBuyer(saleLot),
  );

  const deliverableSaleLots = saleLotsWithBuyer.filter(
    saleLot => !isSaleLotDelivered(saleLot) && hasDestinationProperty(saleLot),
  );

  const undeliveredSaleLotsWithoutDestinationProperties =
    saleLotsWithBuyer.filter(
      saleLot =>
        !isSaleLotDelivered(saleLot) && !hasDestinationProperty(saleLot),
    );

  const bulkPayload = deliverableSaleLots.map(sl => ({
    id: sl.id,
    ...getDeliverPatchInfo(sl),
  }));

  yield put(
    bulkUpdateSaleLotsSerializer(bulkPayload, {
      actionText: "delivered",
      changeReason: "Bulk delivered",
    }),
  );

  if (undeliveredSaleLotsWithoutDestinationProperties.length) {
    toast.info(
      `There ${
        undeliveredSaleLotsWithoutDestinationProperties.length === 1
          ? "is"
          : "are"
      } ${undeliveredSaleLotsWithoutDestinationProperties.length} ${pluralize(
        "sale lot",
        undeliveredSaleLotsWithoutDestinationProperties.length,
      )} without set PICs that could not be delivered`,
    );
  }
}

export default function* saleLotSaga() {
  yield takeEvery(ADD_SALE_LOT_OFFLINE, onCreateSaleLot);

  yield takeLatest(ADD_SALE_LOT_COMMIT, onAddSaleLot);
  yield takeLatest(SALE_LOT.UPDATE.ACTION, onPatchSaleLot);
  yield takeEvery(SALE_LOT.UPDATE.SUCCESS, updateSaleLots);

  yield takeEvery(SALE_LOT.UPDATE.FAILURE, rollbackSaleLot);
  yield takeEvery(DELETE_SALE_LOT_COMMIT, deleteSaleLotCommit);
  yield takeEvery(DELETE_SALE_LOT_ROLLBACK, deleteSaleLotRolllBack);

  yield takeEvery(COPY_SALE_LOT_TO_SALE.COMMIT, copySaleLotCommit);

  yield takeEvery(MERGE_SALE_LOT_ROLLBACK, rollbackSaleLotMerge);
  yield takeEvery(MERGE_SALE_LOT_COMMIT, commitSaleLotMerge);

  yield takeEvery(
    SALE_LOT.SPLIT_SINGLE_WEIGHED.ACTION,
    onSplitSingleWeighedSaleLot,
  );

  yield takeEvery(SALE_LOT.CREATE.ACTION, onCreateSaleLotAction);
  yield takeEvery(SEND_EMAIL_COMMIT, onSendEmail);
  yield takeEvery(DEPLOYMENT_SALE.UPDATE.REQUEST, onUpdateDeploymentSale);
  yield takeEvery(
    DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.SUCCESS,
    chainActionFactory(SaleLotAction.requestChanges),
  );
  yield takeEvery(
    SALE_LOT.UPDATE_BULK.FAILURE,
    chainActionFactory(SaleLotAction.request),
  );
  yield takeEvery(
    SALE_LOT.SELL_WTH_BIDDER_REGISTRATION.ACTION,
    onSellSaleLotWithBidderRegistration,
  );
  yield takeEvery(SALE_LOT.BULK_DELIVER_ALL.ACTION, onDeliverAllSaleLots);

  yield takeLatest(SALE_LOT.DRAFT_SIGHTING.ACTION, onDraftSightingAction);
}
