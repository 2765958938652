import styled from "styled-components/macro";

import Card from "./Card";

const RowCard = styled(Card)`
  padding: ${({ theme }) => theme.space[1]}px;
  flex-direction: row;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? "100%;" : "auto")};
`;

export default RowCard;
