import { OFFLINE_SCHEDULE_RETRY } from "@redux-offline/redux-offline/lib/constants";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

import { pluralize } from "lib/pluralize";
import toast from "lib/toast";

import { getChangesOutboxSize } from "selectors";

import discard from "offline/discard";
import effect from "offline/effect";
import retry from "offline/retry";

const NOTIFY_MS = 5000;
function* notifyOfflineEffect(action) {
  // do not report offline at first retry (after NOTIFY_MS milliseconds), since this retry
  // might not due to device offline, but some other internal stuff

  const state = yield select();
  const changesOutboxSize = getChangesOutboxSize(state);

  if (action.payload.delay > NOTIFY_MS && changesOutboxSize > 0) {
    toast.info(
      `Device is currently experiencing connectivity issues.\n  There ${
        changesOutboxSize === 1 ? "is" : "are"
      } ${changesOutboxSize} unsaved ${pluralize(
        "change",
        changesOutboxSize,
      )}.`,
    );
  }
}

function* onHandleOfflineSkipQueue(action) {
  /*
    Listen for offline actions that are flagged for skipping the queue.

    Mostly uses the existing offline machinery,
    - effect -> run the request
    - discard -> categorise failures as retryable or not.
    - retry -> get the retry delay
   */
  if (action.meta?.skipQueue) {
    try {
      const payload = yield call(effect, action.meta.offline.effect, action);
      yield put({
        ...action.meta.offline.commit,
        payload,
      });
    } catch (e) {
      const tryAgain = !discard(e, action, action.tries);
      if (tryAgain) {
        const retryDelay = retry(action, action.tries);
        yield delay(retryDelay);
        yield put({
          ...action,
          tries: action.tries ? action.tries + 1 : 1,
        });
      } else {
        yield put({ ...action.meta.offline.rollback });
      }
    }
  }
}

function* rootSaga() {
  yield takeEvery(OFFLINE_SCHEDULE_RETRY, notifyOfflineEffect);
  yield takeEvery("*", onHandleOfflineSkipQueue);
}

export default rootSaga;
