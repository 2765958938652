import React from "react";

import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { AuctionPenAction } from "actions";

import { PenScanLotAction } from "actions/penScanLots";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { IconTextButton } from "components/Button";
import { ClickableCommentIcon } from "components/Comments/Icon";
import CreateSaveCancelFooter from "components/Footer/CreateSaveCancelFooter";
import {
  DeploymentSelectField,
  OtherMarkingField,
  SaleRoundPickerField,
} from "components/Form/Fields";
import MarkingField from "components/Form/Fields/MarkingField";
import { Input, Switch } from "components/Form/FormikControls";
import { PenPicker } from "components/Form/FormikControls/PenPicker";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { extendedPenScanLotValidationSchema } from "components/PenScanning/PenScanLot/validationSchema";
import { ScanLotDeleteDialog } from "components/ScanLotDeleteDialog";

import { PenTypes } from "constants/auctionPens";
import { AuditLogTypes } from "constants/auditLog";
import { CommentTypes } from "constants/comments";
import { ApiModel } from "constants/loading";
import {
  LivestockSalePermissions,
  PenScanLotPermissions,
} from "constants/permissions";

import { ForSaleyardAdmin } from "containers/ForUserType";

import { deepObjectChanges } from "lib/compare";
import { getLivestockSaleId } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import {
  getEidsByPenScanLotId,
  getPenScanLotById,
} from "selectors/penScanLots";

import {
  useBoolean,
  useFieldValue,
  useHasLivestockSalePermission,
  useTheme,
} from "hooks";

function PenScanLotForm(props) {
  const { readOnly } = props;

  const theme = useTheme();

  // Only allow editing of fields prior to locking.
  const isLocked = useFieldValue("isLocked");

  return (
    <>
      <Grid item xs={6}>
        <DeploymentSelectField
          label="Agency"
          name="deploymentId"
          disabled={readOnly || isLocked}
          retrictToSaleDeployments
        />
      </Grid>
      <Grid item xs={6}>
        <SaleRoundPickerField
          name="saleRoundId"
          required
          disabled={readOnly || isLocked}
        />
      </Grid>
      <Grid item xs={8}>
        <PenPicker
          label="Selling Pen"
          idName="sellingPenId"
          isCreateEnabled
          isCreateFreeFormEnabled
          isCreateArchetypeDerivedEnabled
          dataName="sellingPenData"
          penType={PenTypes.SELLING}
          required
        />
      </Grid>
      <ForSaleyardAdmin>
        <Grid item container xs={4}>
          <Grid item xs={6}>
            <Switch label="Is Locked" name="isLocked" disabled={readOnly} />
          </Grid>
        </Grid>
      </ForSaleyardAdmin>

      <Grid item xs={12}>
        <MarkingField
          name="marks"
          label="Marks"
          disabled={readOnly}
          useAllDeployments
        />
        <OtherMarkingField
          name="marks"
          label="Custom Marks"
          useAllDeployments
          defaultColor={theme.colors.black}
        />
      </Grid>

      <Grid item xs={8}>
        <Input
          label="Head Count"
          name="quantity"
          disabled={readOnly}
          type="number"
        />
      </Grid>
      <Grid item xs={4}>
        <Input label="Scanned" name="scanCount" disabled />
      </Grid>
    </>
  );
}

export function EditPenScanLotLotModal({ penScanLotId, onClose }) {
  const penScanLot = useSelector(getPenScanLotById(penScanLotId));
  const [isConfirmDeleteVisible, showConfirmDelete, hideConfirmDelete] =
    useBoolean(false);

  const scanCount = useSelector(state =>
    penScanLotId ? getEidsByPenScanLotId(penScanLotId)(state).length : 0,
  );

  const hasUpdatePermission = hasPermission(
    penScanLot,
    PenScanLotPermissions.update,
  );
  const hasCreatePermission = useHasLivestockSalePermission(
    LivestockSalePermissions.canAddPenScanLot,
  );
  const isReadOnly = penScanLotId ? !hasUpdatePermission : !hasCreatePermission;

  const dispatch = useDispatch();

  const initialValues = {
    isLocked: false,
    scanCount,
    quantity: 1,
    marks: null,
    ...penScanLot,
  };

  const onSubmit = values => {
    const { sellingPenId, sellingPenData } = values;
    if (sellingPenData) {
      dispatch(AuctionPenAction.create(sellingPenData));
    }

    if (penScanLotId) {
      dispatch(
        PenScanLotAction.update(
          {
            id: penScanLotId,
            ...deepObjectChanges(initialValues, values),
          },

          { changeReason: "Updated from edit pen scan lot modal." },
        ),
      );
    } else {
      dispatch(
        PenScanLotAction.create(
          {
            livestockSaleId: getLivestockSaleId(),
            ...values,
            sellingPenId,
          },
          {
            changeReason: "Created from edit pen scan lot modal.",
          },
        ),
      );
    }
    onClose();
  };

  const onDelete = () => {
    dispatch(PenScanLotAction.delete(penScanLotId));
    onClose();
  };

  return (
    <ZoomyDialog open onClose={onClose}>
      <WaitForSync
        requiredData={[
          ApiModel.AGENCIES,
          ApiModel.PEN_SCAN_LOTS,
          ApiModel.AUCTION_PENS,
          ApiModel.PEN_ARCHETYPES,
        ]}
      >
        <DialogTitle onClose={onClose}>
          <Row justifyBetween alignCenter>
            <Row alignCenter>
              {penScanLotId && (
                <AuditLogLink
                  auditLogType={AuditLogTypes.PEN_SCAN_LOT}
                  dataId={penScanLotId}
                  returnTo={window.location.hash}
                />
              )}
              &nbsp; Edit Pen Scan Lot
            </Row>
            {penScanLotId && (
              <ClickableCommentIcon
                commentType={CommentTypes.PEN_SCAN_LOT}
                commentTypeId={penScanLotId}
                returnTo={window.location.hash}
              />
            )}
          </Row>
        </DialogTitle>
        <Formik
          onSubmit={onSubmit}
          validationSchema={extendedPenScanLotValidationSchema}
          initialValues={initialValues}
          enableReinitialize
          validateOnBlur
        >
          <Form>
            <DialogContent dividers>
              <Grid spacing={2} container>
                <PenScanLotForm
                  readOnly={isReadOnly}
                  penScanLotId={penScanLotId}
                />

                {penScanLotId ? (
                  <IconTextButton
                    icon={faTrash}
                    color="gray40"
                    onClick={showConfirmDelete}
                  >
                    Delete Pen Scan Lot
                  </IconTextButton>
                ) : null}
              </Grid>
            </DialogContent>

            <CreateSaveCancelFooter
              onClose={onClose}
              isReadOnly={isReadOnly}
              isCreate={!penScanLotId}
            />
          </Form>
        </Formik>

        <ScanLotDeleteDialog
          penType={PenTypes.SELLING}
          isOpen={isConfirmDeleteVisible}
          onCancel={hideConfirmDelete}
          onDelete={onDelete}
          scanCount={scanCount}
        />
      </WaitForSync>
    </ZoomyDialog>
  );
}
