import React from "react";

import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import LoadingSpinner from "components/LoadingSpinner";
import { BigScreenView } from "components/SaleWatcher/BigScreenView";
import { DefaultView } from "components/SaleWatcher/DefaultView";
import {
  LastWeighedDetailedView,
  LastWeighedView,
} from "components/SaleWatcher/LastWeighed";
import ModeSwitcher from "components/SaleWatcher/ModeSwitcher";
import { OnScalesView } from "components/SaleWatcher/OnScales";
import {
  RingSellingByPriceView,
  RingSellingByWeightView,
  RingSellingByWeightWithNextView,
} from "components/SaleWatcher/RingSelling";
import { SalePreview } from "components/SaleWatcher/SalePreview";
import {
  StudWatcherView,
  StudWatcherWithBuyerWayView,
} from "components/SaleWatcher/StudWatcher";

import { Settings } from "constants/settings";
import { ViewModes } from "constants/watcher";

import {
  getIsFetchingAuctionPens,
  getIsFetchingProperties,
  getIsFetchingSaleLots,
  getIsFetchingScans,
  getSetting,
} from "selectors";

import "./transitions.css";

import {
  RingSellingBriefByPriceView,
  RingSellingBriefBySoldThenWeight,
  RingSellingBriefByWeightView,
} from "./RingSellingBrief";

// Full height - height of header

const LoadingWrapper = styled.div`
  height: calc(100vh - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullPage = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ViewModeComponentMap = {
  [ViewModes.DEFAULT]: DefaultView,
  [ViewModes.RING_SELLING_BY_WEIGHT]: RingSellingByWeightView,
  [ViewModes.RING_SELLING_BY_PRICE_THEN_WEIGHT]:
    RingSellingByWeightWithNextView,
  [ViewModes.RING_SELLING_BY_PRICE]: RingSellingByPriceView,
  [ViewModes.RING_SELLING_BRIEF_BY_WEIGHT]: RingSellingBriefByWeightView,
  [ViewModes.RING_SELLING_BRIEF_BY_PRICE_THEN_WEIGHT]:
    RingSellingBriefBySoldThenWeight,
  [ViewModes.RING_SELLING_BRIEF_BY_PRICE]: RingSellingBriefByPriceView,
  [ViewModes.BIG_SCREEN]: BigScreenView,
  [ViewModes.SALE_PREVIEW]: SalePreview,
  [ViewModes.STUD_WATCHER]: StudWatcherView,
  [ViewModes.STUD_WATCHER_WITH_BUYER_WAY]: StudWatcherWithBuyerWayView,
  [ViewModes.LAST_WEIGHED]: LastWeighedView,
  [ViewModes.LAST_WEIGHED_DETAILED]: LastWeighedDetailedView,
  [ViewModes.ON_SCALES]: OnScalesView,
};

export const SaleWatcher = () => {
  const isLoadingData = useSelector(state => {
    return (
      getIsFetchingSaleLots(state) ||
      getIsFetchingProperties(state) ||
      getIsFetchingAuctionPens(state) ||
      getIsFetchingScans(state)
    );
  });

  const viewMode =
    useSelector(getSetting(Settings.watcherViewMode)) || ViewModes.DEFAULT;

  const DisplayComponent = ViewModeComponentMap[viewMode] || DefaultView;

  return (
    <FullPage>
      {isLoadingData ? (
        <LoadingWrapper item>
          <LoadingSpinner />
        </LoadingWrapper>
      ) : (
        <>
          <DisplayComponent />
          <ModeSwitcher />
        </>
      )}
    </FullPage>
  );
};
