import { useSelector } from "react-redux";

import {
  getActiveLivestockAgentDeployment,
  getActiveSaleyardAdminSaleyard,
  getIsLivestockAgent,
} from "selectors";

export const useGetParentObjectFromActiveRole = () => {
  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const parentObjectSelector = isLivestockAgent
    ? getActiveLivestockAgentDeployment
    : getActiveSaleyardAdminSaleyard;

  const parentObject = useSelector(parentObjectSelector);

  return parentObject;
};
