import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const WeighLotScanSerializerMap = {
  created: "created",
  id: "id",
  eid: "eid",
  status: "status",
  totalMassGrams: "total_mass_grams",
  weighLotId: "weigh_lot_id",
  lastModified: "last_modified",
};

export const serializeWeighLotScan = buildSerializer(
  WeighLotScanSerializerMap,
  "WeighLotScan",
);

export const deserializeWeighLotScan = buildDeserializer(
  WeighLotScanSerializerMap,
  "WeighLotScan",
);
