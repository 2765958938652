import React from "react";

import { Grid } from "@material-ui/core";
import { groupBy, keyBy, orderBy, uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { NominationAction } from "actions";

import { MultiButton } from "components/Button";
import StatusCard from "components/Card/StatusCard";
import { PreventPropagation } from "components/Events";
import { Column, Row } from "components/Layout";
import { ReadOnly } from "components/ReadOnly";

import { ConsignmentModalSection } from "constants/navigation";
import { NominationPermissions } from "constants/permissions";
import {
  NominationStatusColor,
  NominationStatus,
  NominationStatusText,
} from "constants/sale";

import {
  openEditConsignmentModal,
  openEditNominationModal,
} from "lib/navigation";
import { hasPermission } from "lib/permissions";

import {
  getNominationById,
  getNominationTermsBySaleyardId,
  getStatusByNominationId,
  selectCurrentSaleyard,
} from "selectors";

const NominationsDetailsCard = styled(StatusCard)(
  ({ theme }) => `
    background-color: ${theme.colors.white};
  `,
);

const CardOptionsMenu = ({ options }) => (
  <PreventPropagation>
    <MultiButton buttons={options} />
  </PreventPropagation>
);

export const NominationDetail = ({ id }) => {
  const dispatch = useDispatch();
  const nomination = useSelector(getNominationById(id));
  const saleyardId = useSelector(state => selectCurrentSaleyard(state).id);
  const nominationTerms = useSelector(
    getNominationTermsBySaleyardId(saleyardId),
  );
  const status = useSelector(getStatusByNominationId(id));
  const readOnly = !hasPermission(nomination, NominationPermissions.update);

  const { hasArrived } = nomination;

  const options = [
    {
      title: readOnly ? "View" : "Edit",
      onClick: () => openEditNominationModal(id),
      dataTour: "editNomination",
    },
    {
      title: "Create Consignment",
      onClick: () =>
        openEditConsignmentModal(
          null,
          ConsignmentModalSection.GENERAL,
          false,
          false,
          null,
          id,
        ),
      dataTour: "createConsignmentFromNomination",
      isDisabled: readOnly,
    },
    !hasArrived && {
      title: (
        <>
          <ReadOnly readOnly={readOnly} showLock />
          Set as {NominationStatusText[NominationStatus.ARRIVED]}
        </>
      ),
      onClick: () =>
        dispatch(NominationAction.update({ hasArrived: true, id })),
      dataTour: "setArrived",
      isDisabled: readOnly,
    },
    hasArrived && {
      title: (
        <>
          <ReadOnly readOnly={readOnly} showLock />
          Set as {NominationStatusText[NominationStatus.NOMINATED]}
        </>
      ),
      onClick: () =>
        dispatch(NominationAction.update({ hasArrived: false, id })),
      dataTour: "setNotArrived",
      isDisabled: readOnly,
    },
  ].filter(Boolean);

  const termsInUse = uniq(
    nomination.nominationDetails.map(detail => detail.nominationTermId),
  ).map(termId => nominationTerms[termId]);

  const nominationDetailsByTermId = keyBy(
    nomination.nominationDetails,
    "nominationTermId",
  );

  const ordered = orderBy(termsInUse, "order");
  const grouped = groupBy(ordered, "groupName");

  return (
    <NominationsDetailsCard status={NominationStatusColor[status]}>
      <Grid container>
        <Grid item xs={8}>
          {Object.entries(grouped).map(([group, terms]) => {
            return (
              <Column key={group}>
                <h3>{group}</h3>
                {terms.map(({ id, name }) => {
                  const { quantity, notes } =
                    nominationDetailsByTermId[id] || {};
                  return (
                    <Row key={id}>
                      {name}: {quantity} {notes && `- ${notes}`}
                    </Row>
                  );
                })}
              </Column>
            );
          })}
        </Grid>
        <Grid container justifyContent="flex-end" item xs={4}>
          <Grid item>
            <CardOptionsMenu options={options} />
          </Grid>
        </Grid>
      </Grid>
    </NominationsDetailsCard>
  );
};
