import { combineReducers } from "redux";

import { DEFAULT_VENDOR_SPLIT, SALE_VENDOR_SPLIT } from "constants/actionTypes";

import { offlineCrudReducer } from "lib/reducers";
import { deserializeVendorSplit as deserializer } from "lib/serializers/vendorSplits";

export default combineReducers({
  default: offlineCrudReducer(DEFAULT_VENDOR_SPLIT, {
    deserializer,
  }),

  sale: offlineCrudReducer(SALE_VENDOR_SPLIT, { deserializer }),
});
