import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

export const Icon = styled(FontAwesomeIcon)`
  ${({ theme, color }) => `
    color: ${theme.colors[color] || theme.colors.white};
    font-size: ${theme.fontSizes.delta}px;
    margin-right: ${theme.space[1]}px;
  `};
`;
export const Error = styled.div`
  margin-left: auto;
  align-self: center;
  color: ${({ theme }) => theme.colors.error};
`;
export const To = styled.div`
  margin: ${({ theme }) => theme.space[2]}px;
  align-self: center;
`;
