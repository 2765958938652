import React, { memo, useCallback, useState } from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { patchDeploymentSale } from "actions/offline/deploymentSale";

import Badge from "components/Badge";
import SlimButton from "components/Button/SlimButton";
import { ConfirmDialog } from "components/ConfirmDialog";
import { ExclamationIcon } from "components/Icons";
import { Column, Row } from "components/Layout";
import TabSelector from "components/TabSelector";
import { SmallText, SmHeading } from "components/Text";

import { ExportSites } from "constants/exportSites";
import { LivestockSalePermissions } from "constants/permissions";

import {
  getLivestockSaleId,
  openEditDeploymentSaleOptions,
} from "lib/navigation";

import {
  getAgencyByDeploymentSaleId,
  getCurrentRoundsList,
  getCurrentSale,
  getDeploymentByDeploymentSaleId,
  getHasExceptionsByDeploymentSaleId,
  getHasWarningsByDeploymentSaleId,
  getHasWriteAccessInCurrentSale,
  getIsBalancedByDeploymentSaleId,
  getRounds,
  getSaleLotIdsWithUnresolvedCommentsByDeploymentSaleId,
} from "selectors";

import { useHasPermission } from "hooks";
import LogoIcon from "img/AgriNous-icon";
import AuctionsPlusLogo from "img/AuctionsPlus";
import StockliveLogoSmall from "img/StockliveLogoSmall";

import {
  NotDraftedCount,
  ReceivedCount,
  RoundDeliveredCount,
  RoundNoSaleCount,
  RoundNotPennedCount,
  RoundPennedCount,
  RoundSoldCount,
} from "./Counts";

const Table = styled.table(
  ({ theme, status }) => `
    border-spacing: 0;
    border-left: ${theme.space[1]}px ${theme.colors[status]} solid;
    width: 100%;
    padding: ${theme.space[1]}px ${theme.space[0]}px;

    td {
      padding: ${theme.space[1]}px;
    }
    .agencyName {
      width: 232px;
      text-align: left;
      padding-left: ${theme.space[2]}px;
      cursor: pointer;
    }
    .round {
      width: 124px;
    }
    .balancedStatus {
      text-align: center;
    }
    .received,
    .notDrafted,
    .notPenned,
    .penned,
    .noSale,
    .sold,
    .delivered {
      width: 100px;
    }
    .exceptions {
      width: 150px;
    }
    `,
);

const ExportSiteIcons = ({ exportSites }) => (
  <Row>
    {exportSites.includes(ExportSites.AUCTIONS_PLUS) && (
      <AuctionsPlusLogo color="success" />
    )}
    {exportSites.includes(ExportSites.STOCK_LIVE) && (
      <StockliveLogoSmall color="success" />
    )}
    {exportSites.includes(ExportSites.AGRINOUS) && <LogoIcon />}
  </Row>
);

function DeploymentSaleSummaryTableComponent(props) {
  const { deploymentSaleId, isExceptionsSelected, onClickExceptions } = props;
  const [isConfirmSaleVisible, setIsConfirmSaleVisible] = useState(false);

  const setConfirmSaleVisible = useCallback(
    () => setIsConfirmSaleVisible(true),
    [setIsConfirmSaleVisible],
  );
  const setConfirmSaleHidden = useCallback(
    () => setIsConfirmSaleVisible(false),
    [setIsConfirmSaleVisible],
  );

  const isBalanced = useSelector(
    getIsBalancedByDeploymentSaleId(deploymentSaleId),
  );
  const agency = useSelector(getAgencyByDeploymentSaleId(deploymentSaleId));
  const deployment = useSelector(
    getDeploymentByDeploymentSaleId(deploymentSaleId),
  );

  const roundList = useSelector(getCurrentRoundsList);
  const rounds = useSelector(getRounds);

  const sale = useSelector(getCurrentSale);

  const deploymentSale = sale.deployment_sales.find(
    o => o.deployment_sale_id === deploymentSaleId,
  );

  const { is_sale_locked: isSaleLocked } = deploymentSale;

  const hasExceptions = useSelector(
    getHasExceptionsByDeploymentSaleId(deploymentSaleId),
  );
  const hasWarnings = useSelector(
    getHasWarningsByDeploymentSaleId(deploymentSaleId),
  );

  const hasUnresolvedSaleLotComments = useSelector(
    state =>
      getSaleLotIdsWithUnresolvedCommentsByDeploymentSaleId(deploymentSaleId)(
        state,
      ).length > 0,
  );

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const rowSpanCount = roundList.length + 1;

  const status = isBalanced ? "ready" : "ongoing";
  const statusText = isBalanced ? "Balanced" : "Ongoing";
  const dispatch = useDispatch();

  const onConfirmSaleClick = () => {
    dispatch(
      patchDeploymentSale(
        { is_confirmed: true },
        deploymentSaleId,
        getLivestockSaleId(),
      ),
    );
    setConfirmSaleHidden();
  };

  const hasForceConfirmSalePermission = useHasPermission(
    getCurrentSale,
    LivestockSalePermissions.canForceConfirmSales,
  );

  const showConfirmButton =
    !deploymentSale.is_confirmed &&
    (hasForceConfirmSalePermission ||
      (hasWriteAccessInCurrentSale && isBalanced && !hasExceptions));

  // Deployment name is not a required field, but agency can be ambiguous.
  // Show the deployment name if we have it, falling back to an agency name.
  const primaryAgencyIdentifier = deployment.name || agency?.shortName;
  // Show the agency name if it's different to the primary name
  const secondaryAgencyIdentifier =
    agency?.shortName !== primaryAgencyIdentifier ? agency?.shortName : null;

  return (
    <Table status={status}>
      <tbody>
        {roundList.map((roundId, index) => (
          <tr key={roundId}>
            {index === 0 && (
              <>
                <th
                  className="agencyName"
                  rowSpan={rowSpanCount}
                  onClick={() => {
                    openEditDeploymentSaleOptions();
                  }}
                >
                  <Column>
                    <Row>
                      <SmHeading bold>
                        {primaryAgencyIdentifier}
                        {secondaryAgencyIdentifier ? (
                          <>
                            <br />
                            <SmallText>{secondaryAgencyIdentifier}</SmallText>
                          </>
                        ) : null}
                      </SmHeading>
                    </Row>
                    <ExportSiteIcons
                      exportSites={deploymentSale?.export_sites || []}
                    />
                  </Column>
                </th>

                <td
                  className="received"
                  rowSpan={rowSpanCount}
                  data-tour="received"
                >
                  <ReceivedCount
                    deploymentSaleId={deploymentSaleId}
                    roundIndex={index}
                  />
                </td>
                <td
                  className="notDrafted"
                  rowSpan={rowSpanCount}
                  data-tour="notDrafted"
                >
                  <NotDraftedCount deploymentSaleId={deploymentSaleId} />
                </td>
              </>
            )}
            <td className="round" data-tour="round">
              <Badge bold>{rounds[roundId]?.name.toUpperCase()}</Badge>
            </td>
            <td className="notPenned" data-tour="notPenned">
              <RoundNotPennedCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
            <td className="penned" data-tour="penned">
              <RoundPennedCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
            <td className="noSale font-bold" data-tour="noSale">
              <RoundNoSaleCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
            <td className="sold" data-tour="sold">
              <RoundSoldCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
            <td className="delivered" data-tour="delivered">
              <RoundDeliveredCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
            {index === 0 && (
              <>
                <td className="exceptions" rowSpan={rowSpanCount}>
                  <Column alignEnd>
                    {(hasExceptions ||
                      hasWarnings ||
                      hasUnresolvedSaleLotComments) && (
                      <TabSelector
                        selected={isExceptionsSelected}
                        onClick={onClickExceptions}
                        color={hasExceptions ? "error" : "gold"}
                      >
                        <ExclamationIcon className="px-1" fontSize="delta" />
                        {(hasExceptions && "EXCEPTIONS") ||
                          (hasWarnings && "WARNINGS") ||
                          (hasUnresolvedSaleLotComments && "COMMENTS")}
                      </TabSelector>
                    )}
                  </Column>
                </td>
                <td className="balancedStatus" rowSpan={rowSpanCount}>
                  <Column justifyAround>
                    <Badge
                      data-tour="badge"
                      color={status}
                      uppercase
                      margin={1}
                    >
                      {statusText}
                    </Badge>

                    {deploymentSale.is_confirmed && (
                      <Badge
                        data-tour="badge"
                        color={status}
                        uppercase
                        margin={1}
                      >
                        Confirmed
                      </Badge>
                    )}
                    {isSaleLocked && (
                      <Badge
                        data-tour="deploymentSaleisLocked"
                        color="ready"
                        uppercase
                        margin={1}
                      >
                        Locked
                      </Badge>
                    )}

                    {showConfirmButton && (
                      <>
                        <SlimButton
                          onClick={setConfirmSaleVisible}
                          className="whitespace-nowrap"
                        >
                          {hasExceptions && (
                            <>
                              <ExclamationIcon />{" "}
                            </>
                          )}
                          Confirm
                        </SlimButton>
                        <ConfirmDialog
                          isOpen={isConfirmSaleVisible}
                          onCancel={setConfirmSaleHidden}
                          onDelete={onConfirmSaleClick}
                          buttonMessage="Confirm"
                          messageIcon={hasExceptions && faExclamationCircle}
                          question={`Are you sure you want to confirm this sale${
                            hasExceptions ? " with exceptions" : ""
                          }?`}
                          message={
                            hasExceptions
                              ? "Confirming your sale will trigger the sending of post-sale reports to relevant parties - this may result in incomplete or incorrect information sent to Buyers. "
                              : " Confirming your sale will trigger the sending of post-sale reports to relevant parties"
                          }
                          title={`Confirm Sale ${
                            hasExceptions ? " With Exceptions" : ""
                          }`}
                        />
                      </>
                    )}
                  </Column>
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

DeploymentSaleSummaryTableComponent.propTypes = {
  deploymentSaleId: PropTypes.number.isRequired,
  onClickExceptions: PropTypes.func,
  isExceptionsSelected: PropTypes.bool,
};

export default memo(DeploymentSaleSummaryTableComponent);
