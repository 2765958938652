import React from "react";

import Grid from "@material-ui/core/Grid";
import { createFilterOptions } from "@material-ui/lab";
import { useSelector } from "react-redux";

import { withNamespace, Autocomplete } from "components/Form/FormikControls";
import { FileImportField } from "components/Importer/FileImportField";

import { getCurrentSaleyard } from "selectors";

import { useFieldSetter, useFieldState } from "hooks";

const ScaleNameSaleyardNameMap = {
  Leongatha: ["Super", "Bulk", "Single"],
  Omeo: ["Bulk"],
  DEFAULT: ["Bulk", "Single"],
};

const ScaleNameOptionsSaleyardNameMap = Object.entries(
  ScaleNameSaleyardNameMap,
).reduce((acc, [saleyardName, values]) => {
  acc[saleyardName] = values.map(value => ({ label: value, value }));
  return acc;
}, {});

const filterOptions = createFilterOptions();
function getOptionValue(option) {
  return option.value;
}
export function ImportWeighLotsForm(props) {
  const { namespace: ns = "" } = props;
  const saleyard = useSelector(getCurrentSaleyard) || {};
  const [file, setFile] = useFieldState(withNamespace(ns, "file"));
  const setFileContents = useFieldSetter(withNamespace(ns, "fileContents"));
  const fileName = file && file.name;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          label="Scale Name"
          labelPosition="top"
          filterOptions={filterOptions}
          getOptionValue={getOptionValue}
          name={withNamespace(ns, "scaleName")}
          options={
            ScaleNameOptionsSaleyardNameMap[saleyard.name] ||
            ScaleNameOptionsSaleyardNameMap.DEFAULT
          }
          required
          value={null}
          disableClearable
        />
      </Grid>
      <Grid item xs={12}>
        <FileImportField
          accept={["text/csv", "application/csv", ".csv"]}
          existingFileName={fileName}
          fileDescription="Weigh Lot CSV"
          processFile={(file, fileContents) => {
            setFile(file);
            setFileContents(fileContents);
          }}
          color="grayF7"
          disallowReupload
        />
      </Grid>
    </Grid>
  );
}
