import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const serializeMap = {
  id: "id",
  accountType: "account_type",
  code: "code",
  defaultTaxType: "default_tax_type",
  description: "description",
  name: "name",
};

export const serializeMasterLedgerAccount = buildSerializer(
  serializeMap,
  "masterLedgerAccount",
);
export const deserializeMasterLedgerAccount = buildDeserializer(
  serializeMap,
  "masterLedgerAccount",
);
