import Commands from "@agrinous/epson-escpos";
import { commandCombiner } from "@agrinous/epson-escpos/lib/utils";

import { fieldAndValuePair, cutMark, hr, receiptHeader } from "./common";

const {
  setFontSize,
  setTabstops,
  printAndFeedLines,
  setFontMode,
  lineFeed,
  text,
  setJustification,
  FONT_MODE,
  JUSTIFICATION_MODE,
  horizontalTab,
} = Commands;

/**
 * @typedef {Object} RoundSummary
 * @property {String} roundName
 * @property {Number} totalHeadcount
 * @property {Number} totalValue
 * @property {Number} avgHeadcount
 * @property {Number} avgValue
 * @property {Array<PenSummary>} penList
 */

/**
 * @typedef {Object} PenSummary
 * @property {String} penName
 * @property {Number} headCount
 * @property {Number} unitPrice
 * @property {boolean} isNoSale
 */

/**
 *
 * @param {PenSummary} penSummary
 * @returns {ArrayBuffer}
 */
function penSummaryListItem(penSummary) {
  const { penName, headCount, unitPrice, isNoSale } = penSummary;
  return commandCombiner([
    setFontSize(2, 2),
    text(penName),
    horizontalTab(),
    text(headCount.toString()),
    setFontSize(1, 1),
    horizontalTab(),
    text(unitPrice ? `$${unitPrice.toFixed(2)}` : isNoSale ? "-" : ""),
    lineFeed(),
  ]);
}

/**
 *
 * @param {String} saleyardName
 * @param {String} stockAgent
 * @param {String} user
 * @param {Number} saleId
 * @param {String} vendorName
 * @param {Array<RoundSummary>} roundList
 * @param {Number} totalHeadcount
 * @param {Number} totalValue
 * @param {Number} avgValue
 * @param {String} pricingType
 * @returns {ArrayBuffer}
 */
export default function vendorSummaryTemplate(
  saleyardName,
  stockAgent,
  user,
  saleId,
  vendorName,
  roundList,
  totalHeadcount,
  totalValue,
  avgValue,
  pricingType,
) {
  let commandList = [
    receiptHeader("Vendor Summary", saleyardName, saleId, stockAgent, user),
    fieldAndValuePair("Vendor", vendorName),
    printAndFeedLines(3),
    setFontSize(1, 1),
  ];
  roundList.forEach(roundSummary => {
    commandList = commandList.concat([
      hr(),
      lineFeed(),
      setJustification(JUSTIFICATION_MODE.JUSTIFICATION_LEFT),
      setTabstops([14]),
      setFontSize(2, 2),
      text(roundSummary.roundName),
      setFontMode(FONT_MODE.FONT_B),
      horizontalTab(),
      text(
        `(${roundSummary.avgHeadcount.toString()} @ $${roundSummary.avgValue.toFixed(
          2,
        )} avg.)`,
      ),
      printAndFeedLines(3),
      setTabstops([11, 27]),
      setFontSize(1, 1),
      text(`Pen\tHd\t${pricingType}`),
      setFontMode(FONT_MODE.FONT_A),
      lineFeed(),
    ]);
    const penSummaryCommands = roundSummary.penList.map(penSummary =>
      penSummaryListItem(penSummary),
    );

    commandList = commandList.concat(penSummaryCommands, [
      lineFeed(),
      setTabstops([32, 37]),
      horizontalTab(),
      fieldAndValuePair("Value", `$${roundSummary.totalValue.toFixed(2)}`),
      printAndFeedLines(2),
    ]);
  });

  commandList = commandList.concat(
    hr(),
    lineFeed(),
    setTabstops([2]),
    setFontSize(2, 2),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_RIGHT),
    fieldAndValuePair("Total Hd", totalHeadcount.toString()),
    setFontSize(1, 1),
    printAndFeedLines(2),
    fieldAndValuePair("Total Value", ` $${totalValue.toFixed(2)}`),
    lineFeed(),
    fieldAndValuePair(`Average ${pricingType}`, ` $${avgValue.toFixed(2)}`),
    lineFeed(),
    setFontMode(FONT_MODE.FONT_B),
    text("* gross prices only, subject to change."),
    setFontMode(FONT_MODE.FONT_A),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_LEFT),
    lineFeed(),
    cutMark(),
  );

  return commandCombiner(commandList);
}
