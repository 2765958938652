function createScriptEl(rootDoc, url) {
  const scriptEl = rootDoc.createElement("script");
  scriptEl.src = url;
  scriptEl.type = "text/javascript";
  scriptEl.async = true;
  scriptEl.defer = true;

  return scriptEl;
}

// used to store the external handler of onLoad and onError events
/**
 * @type {Map<Element, Set<function(): void, function(error: Error): void>>}
 */
const listeners = new Map();

export function loadScript(url, onLoaded, onLoadFailed, rootDoc = document) {
  const scriptEl = createScriptEl(rootDoc, url);

  const onLoadListeners = new Set();
  const onLoadFailedListeners = new Set();
  listeners.set(scriptEl, [onLoadListeners, onLoadFailedListeners]);

  typeof onLoaded === "function" && onLoadListeners.add(onLoaded);
  typeof onLoadFailed === "function" && onLoadFailedListeners.add(onLoadFailed);

  // forward declare function to avoid linting problems,
  // not strictly an issue, here.
  let removeScriptEventListeners;

  function onLoadScript() {
    removeScriptEventListeners();

    // Index 0 is the Set of onLoadListeners
    const onLoadListeners = listeners.get(scriptEl)[0].values();

    // remove the script from the lookup
    listeners.delete(scriptEl);
    for (const listener of onLoadListeners) {
      listener(scriptEl);
    }
  }

  function onErrorScript(error) {
    removeScriptEventListeners();

    // Index 1 is the Set of onLoadFailedListeners
    const onLoadFailedListeners = listeners.get(scriptEl)[1].values();

    // remove the script from the lookup
    listeners.delete(scriptEl);

    for (const listener of onLoadFailedListeners) {
      listener(scriptEl, error);
    }
  }

  removeScriptEventListeners = function removeEventListeners() {
    scriptEl.removeEventListener("load", onLoadScript);
    scriptEl.removeEventListener("error", onErrorScript);
  };

  scriptEl.addEventListener("load", onLoadScript);
  scriptEl.addEventListener("error", onErrorScript);

  // attach the script element to the document
  rootDoc.body.appendChild(scriptEl);
  return scriptEl;
}
