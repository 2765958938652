import styled from "styled-components/macro";

export const UnsoldGrid = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 39px 39px 1fr 1fr 60px;
  grid-column-gap: 12px;
  grid-row-gap: 0px;
  flex: 1;
  justify-content: center;
  align-items: center;

  ${({ hasModeInfo }) =>
    hasModeInfo
      ? `
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "checkbox state-info state-info state-info state-info state-info state-info"
    "checkbox . . . . . .";
    `
      : "  grid-template-rows: 1fr;"}
`;

export const SoldGrid = styled(UnsoldGrid)(
  ({ theme, hasModeInfo }) => `
  @media (max-width: ${theme.breakpoints[0]}px) {
    grid-template-columns:
      50px
      minmax(50px, 1fr)
      30px
      50px
      minmax(50px, 1fr)
      minmax(40px, 1fr)
      minmax(50px, 1fr)
      minmax(50px, 1fr);
    ${
      hasModeInfo
        ? `
    grid-template-areas:
      "checkbox state-info state-info state-info state-info state-info state-info state-info"
      "checkbox . . . . . . .";
      `
        : ""
    }
  }
  grid-template-columns:
    40px
    minmax(50px, 1fr)
    30px
    20px
    minmax(50px, 1fr)
    minmax(40px, 1fr)
    50px
    minmax(50px, 1fr)
    minmax(50px, 1fr)
    minmax(50px, 1fr);
  ${
    hasModeInfo
      ? `
  grid-template-areas:
    "checkbox state-info state-info state-info state-info state-info state-info state-info state-info state-info"
    "checkbox . . . . . . . . . ";
    `
      : ""
  }
      
`,
);

export const StyledHeadcount = styled.div`
  text-align: center;
  padding-right: 1rem;
`;

export const GridStateInfo = styled.div(
  ({ theme }) => `
  grid-area: state-info;
  font-size: ${theme.fontSizes.alpha}px;
  color: ${theme.colors.titleLight};
  text-align: center; 
  
`,
);
