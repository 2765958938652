import { BIDDER_REGISTRATION } from "constants/actionTypes";

import { serialiseBidderRegistration } from "lib/bidders";
import { getSaleUrl } from "lib/navigation";
import { getApiSale } from "lib/sale";

/**
 *
 * @param {BidderRegistration} bidderRegistration
 * @param {string} tempId UUID to be used as the local UUID until the server
 * has responded with the server side id
 */
export function addBidderRegistration(tempId, bidderRegistration) {
  const meta = {
    tempId,
  };

  const saleUrl = getSaleUrl(getApiSale());

  const payload = {
    ...bidderRegistration,
    id: tempId,
  };

  return {
    type: BIDDER_REGISTRATION.CREATE.REQUEST,
    payload: bidderRegistration,

    meta: {
      tempId,
      offline: {
        effect: {
          url: `${saleUrl}/registered-bidders/`,
          method: "POST",
          body: JSON.stringify(serialiseBidderRegistration(payload)),
        },
        commit: {
          type: BIDDER_REGISTRATION.CREATE.SUCCESS,
          meta,
        },
        rollback: {
          type: BIDDER_REGISTRATION.CREATE.FAILURE,
          meta,
        },
      },
    },
  };
}

/**
 * When PUTing against the base url, we're interested in performing the same function as a
 * POST - that is to create a new bidder registration.
 * BUT - we want to keep the bidder number the same, vs the default behavior where the system
 * will increment that bidder number if it finds a duplicate.
 * @param {BidderRegistration} bidderRegistration
 * @param {string} tempId
 */
export function addBidderRegistrationWithoutIncrementingBidderNumber(
  tempId,
  bidderRegistration,
) {
  const meta = {
    tempId,
  };

  const saleUrl = getSaleUrl(getApiSale());

  const payload = {
    ...bidderRegistration,
    id: tempId,
  };

  return {
    type: BIDDER_REGISTRATION.CREATE_WITHOUT_INCREMENTING_BIDDER_NUMBER.REQUEST,
    payload: bidderRegistration,

    meta: {
      tempId,
      offline: {
        effect: {
          url: `${saleUrl}/registered-bidders/`,
          method: "PUT",
          body: JSON.stringify(serialiseBidderRegistration(payload)),
        },
        commit: {
          type: BIDDER_REGISTRATION.CREATE_WITHOUT_INCREMENTING_BIDDER_NUMBER
            .SUCCESS,
          meta,
        },
        rollback: {
          type: BIDDER_REGISTRATION.CREATE_WITHOUT_INCREMENTING_BIDDER_NUMBER
            .FAILURE,
          meta,
        },
      },
    },
  };
}

/**
 * @param {BidderRegistration} payload A patch object containing the values to update the BidderRegistration with
 * @param {string} id The offline UUID of the Bidder Registration to update
 * @param {object} [options]
 */

export function updateBidderRegistration(id, payload, options = null) {
  return {
    type: BIDDER_REGISTRATION.UPDATE.ACTION,
    id,
    payload,
    options,
  };
}

export function patchBidderRegistrationOffline(
  id,
  bidderRegistration,
  options = null,
) {
  const meta = {
    tempId: id,
  };

  const saleUrl = getSaleUrl(getApiSale());

  const payload = {
    ...bidderRegistration,
    id,
  };

  return {
    type: BIDDER_REGISTRATION.UPDATE.REQUEST,
    payload: bidderRegistration,
    options,
    meta: {
      tempId: id,
      offline: {
        effect: {
          url: `${saleUrl}/registered-bidders/${id}/`,
          method: "PATCH",
          body: JSON.stringify(serialiseBidderRegistration(payload)),
        },
        commit: {
          type: BIDDER_REGISTRATION.UPDATE.SUCCESS,
          meta,
        },
        rollback: {
          type: BIDDER_REGISTRATION.UPDATE.FAILURE,
          meta,
        },
      },
    },
  };
}

/**
 * @param {string} id The UUID of the Bidder Registration to delete
 * @param {object} [options]
 */

export function deleteBidderRegistration(id, options = null) {
  const url = `${getSaleUrl(getApiSale())}/registered-bidders/${id}/`;

  const meta = {
    id,
  };

  return {
    type: BIDDER_REGISTRATION.DELETE.REQUEST,
    options,
    meta: {
      id,
      offline: {
        effect: {
          url,
          method: "DELETE",
        },
        commit: {
          type: BIDDER_REGISTRATION.DELETE.SUCCESS,
          meta,
        },
        rollback: {
          type: BIDDER_REGISTRATION.DELETE.FAILURE,
          meta,
        },
      },
    },
  };
}
