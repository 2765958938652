import reduceReducers from "reduce-reducers";

import { PEN_SCAN_LOT } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineCommentReducer,
  bulkUpdateCases,
} from "lib/reducers";
import { deserializePenScanLot } from "lib/serializers/penScanLots";

const create = apiModelOfflineCreateReducer(PEN_SCAN_LOT, {
  deserializer: deserializePenScanLot,
});

const deleteReducer = apiModelOfflineDeleteReducer(PEN_SCAN_LOT);

const fetch = apiModelOfflineFetchReducer(PEN_SCAN_LOT, {
  deserializer: deserializePenScanLot,
});

const update = apiModelOfflineUpdateReducer(PEN_SCAN_LOT, {
  deserializer: deserializePenScanLot,
});

const comment = apiModelOfflineCommentReducer(PEN_SCAN_LOT);

function bulkUpdateAndCreateReducer(state, action) {
  switch (action.type) {
    case PEN_SCAN_LOT.BULK_UPDATE_OR_CREATE.REQUEST: {
      return bulkUpdateCases(state, action, "byId", deserializePenScanLot);
    }
    default:
      return state;
  }
}

const penScanLotReducer = reduceReducers(
  create,
  comment,
  deleteReducer,
  fetch,
  update,
  bulkUpdateAndCreateReducer,
);

export default penScanLotReducer;
