import React from "react";

import { CellButton } from "components/DeploymentSaleSummaries/CellButton";

import { ConsignmentModalSection } from "constants/navigation";

import { openEditConsignmentModal } from "lib/navigation";

function OpenModalCellCreator(consignmentModalSection) {
  return function OpenConsignmentModalCell(props) {
    const { original, value } = props;
    return (
      <CellButton
        onClick={() =>
          openEditConsignmentModal(
            original.consignmentId,
            consignmentModalSection,
            true,
          )
        }
      >
        {value}
      </CellButton>
    );
  };
}

export const OpenGeneralCell = OpenModalCellCreator(
  ConsignmentModalSection.GENERAL,
);

export const OpenSaleLotsCell = OpenModalCellCreator(
  ConsignmentModalSection.SALE_LOTS,
);
