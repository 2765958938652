import React from "react";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faRedo, faSignIn, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import startCase from "lodash/startCase";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { requestLogout } from "actions";

import { Clickable } from "components/Button";
import Dialog from "components/Dialog";
import { Column, Row } from "components/Layout";

import { openSocialAccountManagement, reloadApplication } from "lib/navigation";

import {
  getCurrentUser,
  getEmail,
  getIsOnline,
  getName,
  getOnlineStatus,
} from "selectors";

import { useToggle } from "hooks";

const CircleButton = styled(Row)`
  width: ${({ theme }) => theme.space[5]}px;
  height: ${({ theme }) => theme.space[4]}px;
  align-items: center;
  justify-content: center;
  border-radius: 12%;
`;
const ButtonText = styled.span`
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: ${({ theme }) => theme.space[3]}px;
  margin-top: ${({ theme }) => theme.space[1]}px;
  ${({ padLeft }) => (padLeft ? "margin-left: 12px;" : "")};
`;
const WrapButton = styled(Clickable)(
  ({ theme }) => `
  background-color: ${theme.colors.gray8C};
  border-radius: 2px;
  color: ${theme.colors.white};
  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.blueHighlight};
    color: ${theme.colors.gray40};
  }
  transition: ${theme.transitions[0]};
`,
);
const UserActionButtonComponent = ({
  icon,
  background,
  isMobile,
  onClick,
  children,
}) => {
  return (
    <WrapButton onClick={onClick} data-tour={children}>
      <Column alignCenter>
        <CircleButton
          background={background}
          style={{ marginBottom: background === "none" && !isMobile ? -12 : 0 }}
        >
          <FontAwesomeIcon icon={icon} />
        </CircleButton>
        <ButtonText padLeft={isMobile && background !== "none"}>
          {children}
        </ButtonText>
      </Column>
    </WrapButton>
  );
};

const UserActionButton = React.memo(UserActionButtonComponent);

UserActionButton.propTypes = {
  icon: PropTypes.object.isRequired,
  background: PropTypes.string,
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

const Text = styled.span(
  ({ theme }) => `
  font-size: ${theme.fontSizes.beta}px;
  font-weight: normal;
`,
);

const NameText = styled(Text)(
  ({ theme }) => `
  color: ${theme.colors.white};
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  margin: ${theme.space[1]}px;
`,
);

const ButtonSpace = styled(Row)(
  ({ theme }) => `
  text-align: center;
  margin: ${theme.space[1]}px;
`,
);

function RolePickerHeader() {
  const isOnline = useSelector(getIsOnline);

  const name = useSelector(getName);
  const email = useSelector(getEmail);
  const user = useSelector(getCurrentUser);

  const onlineState = useSelector(getOnlineStatus);
  const [showConfirm, toggleConfirm] = useToggle();

  const dispatch = useDispatch();

  const logout = () => dispatch(requestLogout());

  const onClickLogout = () => {
    onlineState.changesOutboxSize > 0 ? toggleConfirm() : logout();
  };

  return (
    <Row justifyBetween alignCenter>
      <NameText>
        Hi <strong>{startCase(name) || email}</strong>
      </NameText>
      {isOnline && (
        <Row>
          <ButtonSpace justifyEnd>
            {user.allow_allauth_sso && (
              <UserActionButton
                onClick={openSocialAccountManagement}
                icon={faUsers}
                background="none"
              >
                &nbsp;Login Profiles&nbsp;
              </UserActionButton>
            )}
            &nbsp;
            <UserActionButton
              onClick={reloadApplication}
              icon={faRedo}
              background="none"
            >
              Refresh
            </UserActionButton>
            &nbsp;
            <UserActionButton
              onClick={onClickLogout}
              icon={faSignIn}
              background="none"
            >
              LogOut
            </UserActionButton>
          </ButtonSpace>
        </Row>
      )}
      {showConfirm && (
        <Dialog
          closeSelf={toggleConfirm}
          title="Pending changes will be lost on logout"
          warningText="If you continue, your local changes will be discarded"
          submitButtonText="Continue"
          rejectButtonText="Cancel"
          handleSubmit={logout}
          icon={faTrash}
        />
      )}
    </Row>
  );
}

export default RolePickerHeader;
