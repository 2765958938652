import React, { memo } from "react";

import {
  DeliveredDesktopCard,
  DeliveredMobileCard,
  NoSaleDesktopCard,
  NoSaleMobileCard,
  NotDraftedDesktopCard,
  NotDraftedMobileCard,
  NotPennedDesktopCard,
  NotPennedMobileCard,
  PennedDesktopCard,
  PennedMobileCard,
  ReceivedDesktopCard,
  ReceivedMobileCard,
  SoldDesktopCard,
  SoldMobileCard,
} from "./Cards";
import { CompactCardStack, ExpandedCardStack } from "./Common";
import { SaleActionsButton } from "./SaleActionsButton";

function SaleSummaryCardsMobileComponent() {
  return (
    <>
      <CompactCardStack>
        <ReceivedMobileCard />
        <NotDraftedMobileCard />
        <NotPennedMobileCard />
        <PennedMobileCard />
        <NoSaleMobileCard />
        <SoldMobileCard />
      </CompactCardStack>
      <CompactCardStack>
        <DeliveredMobileCard />
      </CompactCardStack>
    </>
  );
}

function SaleSummaryCardsDesktopComponent() {
  return (
    <ExpandedCardStack>
      <ReceivedDesktopCard />
      <NotDraftedDesktopCard />
      <NotPennedDesktopCard />
      <PennedDesktopCard />
      <NoSaleDesktopCard />
      <SoldDesktopCard />
      <DeliveredDesktopCard />
      <SaleActionsButton />
    </ExpandedCardStack>
  );
}

export const SaleSummaryCardsMobile = memo(SaleSummaryCardsMobileComponent);
export const SaleSummaryCardsDesktop = memo(SaleSummaryCardsDesktopComponent);
