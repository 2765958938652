import { isEmpty } from "lodash";
import { createSelector } from "reselect";

import { ValueSource } from "constants/ruleBooks";
import { RuleCustomisableFields } from "constants/rules";

import { getMasterRuleStatus } from "lib/rules";

import {
  getBillingTags,
  getMasterRuleBooks,
  getMasterRules,
  getRules,
  selectRuleIdsByRuleBookIdLookup,
} from "selectors";

import { selectMappedLedgerAccountByCodeLookup } from "selectors/ledgerAccounts";

export const selectParsedRuleContentByRuleIdLookup = createSelector(
  [getRules],
  ruleLookup =>
    Object.values(ruleLookup).reduce((acc, { id, content }) => {
      acc[id] = JSON.parse(content) || {};
      return acc;
    }, {}),
);

export const selectCustomisationsByRuleIdLookup = createSelector(
  [getRules, getMasterRules],
  (ruleByIdLookup, masterRuleByIdLookup) => {
    return Object.entries(ruleByIdLookup).reduce((acc, [ruleId, rule]) => {
      if (rule.master_rule_id) {
        const masterRule = masterRuleByIdLookup[rule.master_rule_id];
        acc[ruleId] = RuleCustomisableFields.reduce((acc, field) => {
          if (masterRule[field] !== rule[field]) {
            acc[field] = masterRule[field];
          }
          return acc;
        }, {});
      }
      return acc;
    }, {});
  },
);

export const selectRulesAggridDataByRuleBookIdLookup = createSelector(
  [
    selectRuleIdsByRuleBookIdLookup,
    getRules,
    getMasterRules,
    getMasterRuleBooks,
    getBillingTags,
    selectMappedLedgerAccountByCodeLookup,
    selectParsedRuleContentByRuleIdLookup,
    selectCustomisationsByRuleIdLookup,
  ],
  (
    ruleIdsByRuleBookIdLookup,
    ruleLookup,
    masterRuleLookup,
    masterRuleBookLookup,
    billingTagLookup,
    mappedLedgerAccountByCodeLookup,
    parsedRuleContentByRuleIdLookup,
    customisationsByRuleIdLookup,
  ) => {
    return Object.entries(ruleIdsByRuleBookIdLookup).reduce(
      (acc, [ruleBookId, ruleIds]) => {
        acc[ruleBookId] = ruleIds.map(ruleId => {
          const rule = ruleLookup[ruleId];
          const masterRule =
            masterRuleLookup[ruleLookup[ruleId]?.master_rule_id];
          const parsedContent = parsedRuleContentByRuleIdLookup[ruleId];

          parsedContent.billing_tags = parsedContent.billing_tags?.map(
            billingTagId => billingTagLookup[billingTagId]?.name,
          );
          const masterRuleStatus = getMasterRuleStatus(rule, masterRule);
          const parsedRule = Object.assign({}, rule, { parsedContent });

          return {
            isCustomised:
              !isEmpty(customisationsByRuleIdLookup[rule.id]) || null, // return true if customised or null
            rule: parsedRule,
            masterRule,
            masterRuleStatus,
            masterRuleBook: masterRuleBookLookup[masterRule?.rule_book_id],
            ledgerAccount:
              rule.gl_code?.source === ValueSource.CONSTANT
                ? mappedLedgerAccountByCodeLookup[rule.gl_code.value]
                : null,
          };
        });
        return acc;
      },
      {},
    );
  },
);
