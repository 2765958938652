import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  Info as BaseInfo,
  Label,
  Text as BaseText,
} from "components/LotCard/Elements";
import MarkingInfo from "components/MarkingInfo";

import { formatWeightKg } from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { getAverageWeightFormatted } from "lib/saleLot";

import {
  getAuctionPenById,
  getBuyerDisplayNameBySaleLotId,
  getSaleLotById,
} from "selectors";

const Info = styled(BaseInfo)`
  margin: 0 ${({ theme }) => theme.space[3]}px;
  max-width: 30%;
`;
const Text = styled(BaseText)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

function MicroLotCardComponent({ saleLotId }) {
  const saleLot = useSelector(getSaleLotById(saleLotId));

  const sellingPen = useSelector(getAuctionPenById(saleLot?.auction_pen_id));
  const deliveryPen = useSelector(getAuctionPenById(saleLot?.deliveryPenId));
  const buyerName = useSelector(getBuyerDisplayNameBySaleLotId(saleLotId));

  if (!saleLot) {
    return null;
  }

  const { marks = [] } = saleLot;

  const averageWeightFormatted = getAverageWeightFormatted(saleLot);
  const existingWeightFormatted =
    saleLot && saleLot.total_mass_grams
      ? formatWeightKg(saleLot.total_mass_grams)
      : "-";
  return (
    <>
      <Info>
        <Label>Hd:</Label>
        <Text>
          {typeof saleLot?.quantity === "undefined" ? "-" : saleLot?.quantity}
        </Text>
      </Info>
      <Info>
        <Label>Marks:</Label>
        <Text>
          {marks.map(mark => (
            <MarkingInfo
              key={`${mark.color}-${mark.location}`}
              color={mark.color}
              location={mark.location}
            />
          ))}
        </Text>
      </Info>
      <Info>
        <Label>Auction Pen</Label>{" "}
        <Text>{sellingPen ? getAuctionPenDisplayName(sellingPen) : "-"}</Text>
      </Info>
      <Info>
        <Label>Buyer</Label> <Text>{buyerName || "-"}</Text>
      </Info>
      <Info>
        <Label>Delivery Pen</Label>
        <Text>{deliveryPen ? getAuctionPenDisplayName(deliveryPen) : "-"}</Text>
      </Info>
      <Info>
        <Label>Existing Weight</Label>
        <Text>
          {saleLot?.total_mass_grams
            ? `${existingWeightFormatted} (Avg: ${averageWeightFormatted})`
            : "-"}
        </Text>
      </Info>
    </>
  );
}
export const MicroLotCard = React.memo(MicroLotCardComponent);
