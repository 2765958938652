import React from "react";

import Grid from "@material-ui/core/Grid";

import { CollapseHeader, FormCollapse } from "components/Form";
import { PercentageInput } from "components/Form/FormikControls";

import { FinanceSubModalSection } from "constants/settings";

import { useSectionHelper } from "hooks";

export const InsuranceSettings = ({ sectionHelper }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.INSURANCE,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<CollapseHeader title="Insurance" />}
    >
      <Grid item xs={12} md={4}>
        <PercentageInput
          label="Default Debtor Insurance"
          name="defaultDebtorInsurance"
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <PercentageInput
          label="Default Transit Insurance"
          name="defaultTransitInsurance"
          required
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <PercentageInput
          label="Default Transit Interstate Insurance"
          name="defaultInterstateTransitInsurance"
          required
        />
      </Grid>
    </FormCollapse>
  );
};
