export const NewUsed = {
  NEW: "New",
  USED: "Used",
};

export const ItemCondition = {
  EXCELLENT: "Excellent",
  GOOD: "Good",
  FAIR: "Fair",
  POOR: "Poor",
  WRECKER: "Wrecker",
};

export const SellSafeCategory = {
  GREEN: "1 Green",
  ORANGE: "2 Orange",
  RED: "3 Red",
};

export const LotStatus = {
  PENDING: "Pending",
  APPROVED: "Approved",
  ACTIVE: "Active",
  // The item was removed from the sale before the auction started
  WITHDRAWN: "Withdrawn",
  // The item went for auction, but didn't meet the reserve price and was therefore not sold
  PASSED_IN: "Passed In",
  SOLD: "Sold",
  PAID: "Paid",
  FOR_SALE: "For Sale",
  // The item received no bids at auction. This is also known as No Sale in the AgriNous terminology
  // N.B. The term "No Sale" in the AgriNous terminology may also be used to refer to Withdrawn, and Passed In items
  NO_BIDS: "No Bids",
  WANTED: "Wanted",
};

export const LotStatusAsOptions = Object.values(LotStatus).map(ls => ({
  label: ls,
  value: ls,
}));

export const BuyerStatus = {
  PAID: "Paid",
  PARTIALLY_PAID: "Partially Paid",
  SOLD: "Sold",
};

export const TAX_RECEIPT = "Tax Receipt";
export const TAX_INVOICE = "Tax Invoice";
export const RCTI = "Recipient Created Tax Invoice";

export const getReportSentLabel = report => `${report} Sent`;

export const CLEARING_SALE_VENDOR_REPORTS = [RCTI];
export const CLEARING_SALE_BUYER_REPORTS = [TAX_RECEIPT, TAX_INVOICE];

export const VENDOR_LABELS =
  CLEARING_SALE_VENDOR_REPORTS.map(getReportSentLabel);
export const BUYER_LABELS = CLEARING_SALE_BUYER_REPORTS.map(getReportSentLabel);
