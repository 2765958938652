import styled from "styled-components/macro";

export const BulkCreateSaleLotsLayout = styled.div(
  ({ theme }) => `
  overflow: hidden;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "picker selected";
  grid-gap: ${theme.space[1]}px;
`,
);

export const PickerLayout = styled.div(
  ({ theme }) => `
  padding: ${theme.space[1]}px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr;
  grid-template-areas: "summary" "consignments";
  grid-area: picker;
`,
);

export const SelectedLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: "title" "sale-lot-form";
  grid-area: selected;
`;
