export const RegisteredBusinessStatus = {
  ERROR: "ERROR",
  INCOMPLETE: "INCOMPLETE",
  REGISTERED: "REGISTERED",
  CALCULATING: "CALCULATING",
};

export const RegisteredBusinessStatusColourMap = {
  [RegisteredBusinessStatus.ERROR]: "inputError",
  [RegisteredBusinessStatus.CALCULATING]: "grayDisabled",
  [RegisteredBusinessStatus.INCOMPLETE]: "warning",
  [RegisteredBusinessStatus.REGISTERED]: "primary",
};

export const RegisteredBusinessStatustTitleMap = {
  [RegisteredBusinessStatus.ERROR]: "Error",
  [RegisteredBusinessStatus.CALCULATING]: "Working",
  [RegisteredBusinessStatus.INCOMPLETE]: "Incomplete",
  [RegisteredBusinessStatus.REGISTERED]: "Registered",
};
