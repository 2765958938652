import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

export const envdSerializeMap = {
  id: "id",
  consigneeName: "consignee_name",
  consignmentNumber: "consignment_number",
  declarationName: "declaration_name",
  deploymentSaleId: "deployment_sale_id",
  formType: "form_type",
  fromPIC: "from_PIC",
  fromPropertyName: "from_property_name",
  livestockSaleId: "livestock_sale_id",
  nlisCreatedAt: "nlis_created_at",
  nlisUpdatedAt: "nlis_updated_at",
  ownerName: "owner_name",
  pdfUrl: "pdf_url",
  quantity: "quantity",
  serialNumber: "serial_number",
  speciesId: "species_id",
  status: "status",
  toPIC: "to_PIC",
  toPropertyName: "to_property_name",
  isMissingSignature: "is_missing_signature",
  isMissingAnimalCount: "is_missing_animal_count",
  isMissingAnswers: "is_missing_answers",
  isComplete: "is_complete",
};

export const serializeEnvdDocument = buildSerializer(
  envdSerializeMap,
  "ENVDDocument",
);

export const deserializeEnvdDocument = buildDeserializer(
  envdSerializeMap,
  "ENVDDocument",
);
