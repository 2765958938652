import React, { useState } from "react";

import {
  faAddressCard,
  faFilePdf,
  faPrint,
  faTruck,
  faUndo,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faWarehouseAlt } from "@fortawesome/pro-light-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { uniqBy } from "lodash";
import sortBy from "lodash/sortBy";
import { useDispatch, useSelector } from "react-redux";

import {
  bulkUpdateSaleLotsSerializer,
  patchSaleLot,
  printBuyerSummary,
} from "actions";

import { IconTextButton } from "components/Button";
import Dialog from "components/Dialog";
import { CurrentSaleReadOnly } from "components/ReadOnly";
import TabletDropdown from "components/TabletDropdown";

import { BusinessModalSection } from "constants/navigation";
import { SaleLotPermissions } from "constants/permissions";
import { ReportDriver, Reports } from "constants/reports";
import { saleLotStatuses } from "constants/sale";

import { ForEveryoneExceptBusinessUsers } from "containers/ForUserType";

import {
  getLivestockSaleId,
  getSaleyardName,
  openDeliveryPenModal,
  openEditBusinessModal,
} from "lib/navigation";
import { hasPermission } from "lib/permissions";
import { getReportIcon, getReportUrl } from "lib/reports";
import { getDeliverPatchInfo } from "lib/saleLot";

import {
  getActiveRole,
  getBidders,
  getCurrentSale,
  getFavouritedOrFlaggedReportConfigsByDriver,
  selectAgencyByConsignmentIdLookup,
  selectAreStudFeaturesEnabled,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
} from "selectors";

export const BuyerCardActionOptions = ({
  buyerInfo,
  saleLots,
  buyerReportUrl,
  deliveryReportUrl,
}) => {
  const [isUnsellDialog, setIsUnsellDialog] = useState(false);
  const dispatch = useDispatch();

  const businesses = useSelector(
    selectPropertyEnrichedBusinessByBusinessIdLookup,
  );
  const bidderByIdLookup = useSelector(getBidders);

  const roleSlug = useSelector(state => getActiveRole(state).slug);

  const groupAction = (list, action) => () => {
    list.forEach(element => action(element));
  };

  const bulkDeliver = () => {
    const bulkPayload = saleLots.map(sl => ({
      id: sl.id,
      ...getDeliverPatchInfo(sl, sl.buyer, sl.saleyardId),
    }));

    dispatch(
      bulkUpdateSaleLotsSerializer(bulkPayload, {
        actionText: "delivered",
        changeReason: "Bulk delivered",
      }),
    );
  };

  const bulkUndeliver = () => {
    const bulkPayload = saleLots.map(sl => ({
      id: sl.id,
      quantity_delivered: null,
    }));
    dispatch(
      bulkUpdateSaleLotsSerializer(bulkPayload, {
        actionText: "un-delivered",
        changeReason: "Bulk un-delivered",
      }),
    );
  };

  const unsell = saleLot => {
    const patch = {
      total_price_cents: 0,
      buyer_id: null,
      buyer_way: null,
      id: saleLot.id,
    };
    dispatch(patchSaleLot(patch, { changeReason: "Bulk unsold" }));
  };

  const setDeliveryPen = () => {
    openDeliveryPenModal(saleLots.map(s => s.id));
  };

  const doPrintBuyerSummary = () => {
    dispatch(printBuyerSummary(buyerInfo));
  };

  const doOpenEditBusiness = () => {
    openEditBusinessModal(
      buyerInfo.saleLots[0].buyerId,
      undefined,
      BusinessModalSection.EMAIL_RECIPIENTS,
    );
  };

  const { status } = buyerInfo;

  const hasBulkUpdatePermission = saleLots.every(s =>
    hasPermission(s, SaleLotPermissions.update),
  );

  const favouriteBuyerReports = useSelector(
    getFavouritedOrFlaggedReportConfigsByDriver(ReportDriver.BUYER),
  );

  const sortedBuyerReports = sortBy(favouriteBuyerReports, "title");
  const favouriteBidderReports = useSelector(
    getFavouritedOrFlaggedReportConfigsByDriver(ReportDriver.BIDDER),
  );

  if (
    !favouriteBidderReports.some(
      report => report.slug === Reports.IndividualBidderDetailReport.slug,
    )
  ) {
    favouriteBidderReports.push(Reports.IndividualBidderDetailReport);
  }

  if (
    !favouriteBidderReports.some(
      report => report.slug === Reports.IndividualBidderInvoiceReport.slug,
    )
  ) {
    favouriteBidderReports.push(Reports.IndividualBidderInvoiceReport);
  }

  const isUsingRegisteredBidders =
    useSelector(getCurrentSale)?.using_registered_bidders;

  const relatedBidders = buyerInfo.saleLotBidders;
  const studFeaturesEnabled = useSelector(selectAreStudFeaturesEnabled);
  const agenciesByConsignmentId = useSelector(
    selectAgencyByConsignmentIdLookup,
  );

  const agencies = uniqBy(
    saleLots.map(lot => agenciesByConsignmentId[lot.consignmentId]),
    "id",
  );

  const createOpenReportButton = (title, extraArgs, report) => {
    return (
      <IconTextButton
        icon={getReportIcon(report)}
        color="white"
        onClick={() =>
          window.open(
            getReportUrl(report, {
              saleyardName: getSaleyardName(),
              livestockSaleId: getLivestockSaleId(),
              ...extraArgs,
            }),
            "_blank",
          )
        }
      >
        {title}
      </IconTextButton>
    );
  };

  return (
    <>
      <TabletDropdown data-tour="dropDown" align="right">
        <CurrentSaleReadOnly>
          <ForEveryoneExceptBusinessUsers>
            {(status === saleLotStatuses.SOLD ||
              status === saleLotStatuses.NO_SALE) && (
              <IconTextButton
                icon={hasBulkUpdatePermission ? faTruck : faLock}
                color="white"
                dataTour="deliverAll"
                onClick={bulkDeliver}
                disabled={!hasBulkUpdatePermission}
              >
                Deliver All
              </IconTextButton>
            )}
            <IconTextButton
              icon={hasBulkUpdatePermission ? faWarehouseAlt : faLock}
              color="white"
              onClick={setDeliveryPen}
              disabled={!hasBulkUpdatePermission}
            >
              Set Delivery Pen
            </IconTextButton>
            {status === saleLotStatuses.PARTIALLY_DELIVERED && (
              <IconTextButton
                icon={hasBulkUpdatePermission ? faTruck : faLock}
                color="white"
                onClick={groupAction(saleLots, bulkDeliver)}
                disabled={!hasBulkUpdatePermission}
              >
                Deliver Remaining
              </IconTextButton>
            )}
            {status === saleLotStatuses.DELIVERED && (
              <IconTextButton
                icon={hasBulkUpdatePermission ? faUndo : faLock}
                color="white"
                dataTour="undeliverAll"
                onClick={bulkUndeliver}
                disabled={!hasBulkUpdatePermission}
              >
                Undeliver All
              </IconTextButton>
            )}
          </ForEveryoneExceptBusinessUsers>
        </CurrentSaleReadOnly>
        <ForEveryoneExceptBusinessUsers>
          <IconTextButton
            icon={faPrint}
            color="white"
            onClick={doPrintBuyerSummary}
          >
            Buyer Summary
          </IconTextButton>
        </ForEveryoneExceptBusinessUsers>
        {buyerInfo.saleLots && (
          <ForEveryoneExceptBusinessUsers>
            <IconTextButton
              icon={faAddressCard}
              color="white"
              onClick={doOpenEditBusiness}
              data-tour="email"
            >
              Email Recipients
            </IconTextButton>
          </ForEveryoneExceptBusinessUsers>
        )}
        {buyerReportUrl && (
          <IconTextButton
            icon={faFilePdf}
            color="white"
            onClick={() => window.open(buyerReportUrl, "_blank")}
          >
            Buyer Report
          </IconTextButton>
        )}
        {deliveryReportUrl && (
          <IconTextButton
            icon={faFilePdf}
            color="white"
            onClick={() => window.open(deliveryReportUrl, "_blank")}
          >
            Delivery Report
          </IconTextButton>
        )}

        {sortedBuyerReports.map(report => {
          const url = getReportUrl(report, {
            saleyardName: getSaleyardName(),
            livestockSaleId: getLivestockSaleId(),
            buyerId: businesses[buyerInfo.saleLots[0].buyerId].pk,
            userRole: roleSlug,
            static: true,
            async: true,
          });
          return (
            <IconTextButton
              key={url}
              icon={getReportIcon(report)}
              color="white"
              onClick={() => window.open(url, "_blank")}
            >
              {report.title}
            </IconTextButton>
          );
        })}
        {relatedBidders.map(bidderId => {
          if (!isUsingRegisteredBidders || !studFeaturesEnabled) {
            return [];
          }
          return favouriteBidderReports.map(report => {
            const bidder = bidderByIdLookup[bidderId] || {};
            const buyer = businesses[buyerInfo.saleLots[0].buyerId];
            const buyerWay = buyer.buyerWays.find(
              bw => bw.name === bidder.buyerWay,
            );

            if (report.isAgencyDriven) {
              return agencies.map(agency => {
                return createOpenReportButton(
                  `${agency.shortName} - ${report.title} (${bidder.registrationNumber})`,
                  {
                    bidderId,
                    userRole: roleSlug,
                    buyerId: buyer.pk,
                    agencyShortName: agency.shortName,
                    buyerWayId: buyerWay?.id,
                  },
                  report,
                );
              });
            } else {
              return createOpenReportButton(
                `${report.title} (${bidder.registrationNumber})`,
                { bidderId, userRole: roleSlug },
                report,
              );
            }
          });
        })}
      </TabletDropdown>
      {isUnsellDialog && (
        <Dialog
          closeSelf={() => setIsUnsellDialog(false)}
          handleSubmit={() => unsell(buyerInfo)}
          title="Unsell lot?"
          subtitle="Are you sure you want to undo this sale?"
          submitButtonText="Unsell"
          rejectButtonText="Cancel"
          icon={faUndoAlt}
        />
      )}
    </>
  );
};
