import React from "react";

import PropTypes from "prop-types";
import { withTheme } from "styled-components";

const Logo = ({ color, dashed, theme, dark }) => {
  const logoColor = color || theme.colors.primary;
  const altColor = dark ? "#8D9D9A" : "#FFF";

  const commonProps = {
    fill: dashed ? undefined : logoColor,
    stroke: dashed ? logoColor : undefined,
    strokeDasharray: dashed ? "5 2" : undefined,
  };
  return (
    <svg width="25" height="126" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M14.57 83.143c1.188.771 1.963 2.174 1.963 3.718 0 2.582-2.158 4.654-4.819 4.654-2.66 0-4.818-2.061-4.818-4.644 0-1.544.774-2.957 1.963-3.728h5.71zm-7.658 0c-.856 1.028-1.37 2.293-1.37 3.718 0 3.307 2.764 5.968 6.172 5.968 3.409 0 6.172-2.65 6.172-5.958 0-1.424-.514-2.7-1.37-3.728h1.37v-1.286H5.74v1.286h1.171zM6.816 64.839h-1.11v1.247H17.2v-1.247H8.658c-1.125-.748-1.913-2.15-1.913-3.647 0-.229.055-.592.055-.592H5.624s-.081.302-.081.613c0 1.382.462 2.629 1.273 3.626M17.886 57.171V55.8H6.229v1.371zM14.172 70.116c1.136.773 1.877 2.166 1.877 3.713 0 2.586-2.063 4.656-4.607 4.656-2.543 0-4.606-2.057-4.606-4.643 0-1.546.74-2.953 1.877-3.726h5.459zm-7.32 0a5.884 5.884 0 0 0-1.31 3.713c0 3.312 2.642 5.971 5.9 5.971 3.259 0 5.9-2.645 5.9-5.958a5.92 5.92 0 0 0-1.31-3.726h2.53c2.094 0 3.627 1.51 3.627 4.132 0 1.534-.92 3.204-2.134 3.92l.736 1.114c1.61-.95 2.58-2.936 2.58-4.969 0-3.477-2.033-5.484-4.809-5.484H5.732v1.287h1.12zM1.429 56.485a1.372 1.372 0 1 0 2.743 0 1.372 1.372 0 0 0-2.743 0"
          fill={altColor}
        />
        <path
          d="M13.815 15.64c.645.618 1.343 1.553 1.343 2.725 0 1.837-1.014 2.85-2.47 2.85h-6.46v3.042h6.269c4.371 0 5.385-3.294 5.385-5.322a5.927 5.927 0 0 0-.99-3.294h.994V12.6H6.229v3.04h7.586M14.92 32.829a3.215 3.215 0 0 1-3.206 3.223 3.215 3.215 0 0 1-3.206-3.223 3.215 3.215 0 0 1 3.206-3.224 3.215 3.215 0 0 1 3.207 3.224zm-9.377 0c0 3.597 2.763 6.514 6.171 6.514 3.409 0 6.172-2.917 6.172-6.514 0-3.598-2.763-6.515-6.172-6.515-3.408 0-6.171 2.917-6.171 6.515zM10.435 4.567c-.11.55-.499 2.335-1.432 2.335-.74 0-.95-1.325-.95-1.787 0-.683.439-1.785.769-2.402L6.54 1.36c-.528 1.168-.998 2.717-.998 3.995 0 2.467 1.352 4.714 3.54 4.714 2.189 0 3.05-1.896 3.38-3.152.33-1.255.66-2.682 1.802-2.682.837 0 .982 1.13.982 1.635 0 .992-.587 2.36-1.16 3.173l2.206 1.499c.933-1.354 1.593-2.935 1.593-4.542 0-2.622-1.095-5.058-3.67-5.058-1.979 0-3.298 1.514-3.781 3.624M5.543 46.722c0 1.302.442 2.461 1.032 3.294h-.83v3.041h12.14v-3.04H9.779c-.67-.618-1.398-1.553-1.398-2.725 0-1.837 1.056-2.851 2.574-2.851h6.932V41.4h-6.734c-4.553 0-5.61 3.294-5.61 5.322"
          {...commonProps}
        />
        <path
          d="M4.171 108.666l2.394 2.362c2.475-2.442 6.502-2.442 8.977 0a6.214 6.214 0 0 1 0 8.858c-2.475 2.442-6.502 2.442-8.977 0l-2.394 2.363c3.795 3.744 9.97 3.744 13.764 0a9.504 9.504 0 0 0 2.7-5.141h3.422v-3.34H20.63a9.505 9.505 0 0 0-2.694-5.102c-3.795-3.745-9.969-3.745-13.764 0"
          {...commonProps}
        />
        <path
          d="M13.771 115.457c0-1.515-1.268-2.743-2.832-2.743-.923 0-1.741.43-2.258 1.092H.057v3.302h8.624a2.857 2.857 0 0 0 2.258 1.092c1.564 0 2.832-1.228 2.832-2.743"
          {...commonProps}
        />
      </g>
    </svg>
  );
};

Logo.propTypes = {
  color: PropTypes.string,
  dashed: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

Logo.defaultProps = {
  dashed: false,
  dark: false,
};

export default withTheme(Logo);
