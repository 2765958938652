import React from "react";

import {
  faExclamationCircle,
  faFileCsv,
  faPencil,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import {
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Badge as MaterialBadge,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { isEmpty, orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { BillingRunAction } from "actions";

import { openSaleExport } from "components/BillingWorkFlow/SaleExport";
import { Warnings } from "components/BillingWorkFlow/Warnings";
import { MultiButton } from "components/Button";
import { SecondaryMultiButton } from "components/Button/MultiButton";
import { Chip } from "components/Chip";
import { SmallFaIcon } from "components/FaIcon";
import { Button } from "components/Form";
import { Column, Row } from "components/Layout";
import MessageBox from "components/MessageBox";
import { Paper } from "components/Paper";
import { DescriptiveOption, ReactSelect } from "components/SearchableSelector";
import { ExtraSmallText, Text } from "components/Text";

import { ModalTypes } from "constants/navigation";

import {
  getLivestockSaleId,
  openModalLink,
  openSaleModal,
} from "lib/navigation";
import {
  formatDateTimeUTC,
  formatUTCToLocalDateTimeString,
} from "lib/timeFormats";

import {
  getActiveBillingRunId,
  getActiveRole,
  getBillingRuns,
  getCurrentDeploymentSalesList,
  getIsStaff,
  selectBillingDocumentsWithWarningsAggridDataById,
} from "selectors";

import { getCurrentDefaultTradingTerms } from "selectors/tradingTerms";

import { LedgerEntryDetail } from "./LedgerEntryDetail";

const useStyles = makeStyles(() => ({
  wrapper: {
    flexDirection: "row-reverse",
    justifyContent: "space-around",
  },
}));

const WarningCountBadge = ({ count }) => {
  if (count < 1) {
    return null;
  }
  return <MaterialBadge badgeContent={count} showZero color="error" />;
};

export const BillingRunSelector = () => {
  const dispatch = useDispatch();
  const activeBillingRunId = useSelector(getActiveBillingRunId);
  const userRoleSlug = useSelector(getActiveRole).slug;
  const allBillingRuns = useSelector(getBillingRuns);
  const location = useLocation();
  const isStaff = useSelector(getIsStaff);
  const billingRun = allBillingRuns[activeBillingRunId] || {};
  const openEditRuleBook = () =>
    openModalLink(ModalTypes.RuleBook, {
      id: billingRun.ruleBookId,
    });

  const selectBillingRunId = billingRunId => {
    if (!billingRunId) {
      dispatch(BillingRunAction.unsubscribe());
    } else {
      dispatch(BillingRunAction.subscribe(billingRunId));
    }
  };
  const selectOptions = orderBy(
    Object.values(allBillingRuns),
    "created",
    "desc",
  ).map(billingRun => {
    return {
      label: billingRun.name,
      value: billingRun.id,
      description: `Created: ${billingRun.created ? formatDateTimeUTC(billingRun.created) : ""} Last Run: ${billingRun.ledgerEntriesGeneratedDateTime ? formatDateTimeUTC(billingRun.ledgerEntriesGeneratedDateTime) : ""}`,
    };
  });

  let selectedOption = selectOptions.find(
    option => option.value === activeBillingRunId,
  );

  if (!activeBillingRunId || isEmpty(billingRun)) {
    // If we have no billing run, we should select the first.
    // dispatch(BillingRunAction.unsubscribe());
    selectedOption = selectOptions[0];
    if (selectedOption) {
      selectBillingRunId(selectedOption.value);
    }
  }

  const openBillingRunForm = billingRunId => {
    openModalLink(
      ModalTypes.EditBillingRun,
      {
        billingRunId,
      },
      location.pathname + location.search,
    );
  };

  const buttons = [
    {
      dataTour: "editBillingRun",
      title: "Edit Billing Run",
      onClick: () => openBillingRunForm(billingRun.id),
      default: true,
    },
    {
      dataTour: "createBillingRun",
      title: "Create new Billing Run",
      onClick: () => openBillingRunForm(null),
      icon: faPlus,
    },
    {
      dataTour: "editRuleBook",
      title: "Edit Rule Book",
      onClick: openEditRuleBook,
      icon: faPencil,
    },
    {
      dataTour: "editTradingTerms",
      title: "Edit Trading Terms",
      onClick: () => openSaleModal(getLivestockSaleId()),
      icon: faPencil,
    },
    isStaff && {
      dataTour: "saleExport",
      title: "Sale Export",
      onClick: () => openSaleExport(userRoleSlug),
      icon: faFileCsv,
    },
  ].filter(Boolean);

  return (
    <>
      <Text className="whitespace-nowrap flex items-center">Billing Run</Text>
      <Row fullWidth justifyCenter alignCenter padding={2} gridGap={2}>
        <Column full>
          <ReactSelect
            options={selectOptions}
            name="billingRunOptions"
            value={selectedOption}
            isClearable={false}
            onChange={({ value }) => selectBillingRunId(value)}
            menuPortalTarget={document.body}
            components={{ Option: DescriptiveOption }}
            disabled={selectOptions.length === 0}
          />
        </Column>
        <span className="min-w-160">
          <MultiButton
            buttons={buttons}
            ButtonComponent={SecondaryMultiButton}
          />
        </span>
      </Row>
    </>
  );
};

const RenderedTable = ({ selectedTab }) => {
  if (selectedTab === "ledger-entries") {
    return <LedgerEntryDetail />;
  }
  return <Warnings />;
};

export const Overview = React.memo(() => {
  const [selectedTab, setSelectedTab] = React.useState("ledger-entries");
  const activeBillingRunId = useSelector(getActiveBillingRunId);
  const allBillingRuns = useSelector(getBillingRuns);
  const billingRun = allBillingRuns[activeBillingRunId] || {};
  const billingDocumentsWithWarningsCount = useSelector(
    selectBillingDocumentsWithWarningsAggridDataById,
  ).length;

  const openBillingRunForm = () => {
    openModalLink(ModalTypes.EditBillingRun, null);
  };

  // Get active terms from the sale.
  const deploymentSale = useSelector(getCurrentDeploymentSalesList)?.[0];
  const { buyer_terms: buyerTerms, vendor_terms: vendorTerms } = deploymentSale;

  // default terms for the sale
  const { buyerTerms: defaultBuyerTerms, vendorTerms: defaultVendorTerms } =
    useSelector(getCurrentDefaultTradingTerms) || {};

  const defaultTermsOverridden =
    (defaultBuyerTerms && buyerTerms !== defaultBuyerTerms) ||
    (defaultVendorTerms && vendorTerms !== defaultVendorTerms);

  const BillingOverviewTabs = [
    {
      label: "Ledger Entries",
      value: "ledger-entries",
    },
    {
      label: `Warnings`,
      value: "warnings",
      icon: <WarningCountBadge count={billingDocumentsWithWarningsCount} />,
    },
  ];

  const handleChange = (_ignored, value) => {
    setSelectedTab(value);
  };

  const classes = useStyles();

  if (isEmpty(allBillingRuns)) {
    return (
      <Paper>
        <MessageBox>
          <Column>
            No Billing Runs have been created for this sale yet.
            <Button onClick={() => openBillingRunForm()}>Create one now</Button>
          </Column>
        </MessageBox>
      </Paper>
    );
  }

  return (
    <>
      <Paper spacing={0} style={{ paddingBottom: "0px" }}>
        <Row fullWidth>
          <BillingRunSelector />
        </Row>
        <Row>
          <ExtraSmallText
            className="justify-end"
            style={{ paddingRight: "15px", marginTop: "-10px" }}
          >
            {`Last Run: ${billingRun && billingRun.ledgerEntriesGeneratedDateTime ? formatDateTimeUTC(billingRun.ledgerEntriesGeneratedDateTime) : ""}`}
          </ExtraSmallText>
        </Row>
        <Row fullWidth>
          <Grid item xs={6}>
            <Tabs
              variant="scrollable"
              onChange={handleChange}
              value={selectedTab}
            >
              {BillingOverviewTabs.map(({ label, value, icon }) => (
                <Tab
                  icon={icon}
                  iconPosition="start"
                  key={value}
                  label={label}
                  value={value}
                  classes={{
                    wrapper: classes.wrapper,
                  }}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Row alignCenter>
              <Text className="m-4">Terms </Text>
              <Chip className="m-4">Vendor: {vendorTerms}</Chip>
              <Chip className="m-4">Buyer: {buyerTerms}</Chip>
              {!defaultTermsOverridden && (
                <Tooltip
                  title="Terms differ from Sale defaults"
                  className="m-4"
                >
                  <SmallFaIcon icon={faExclamationCircle} color="warning" />
                </Tooltip>
              )}
              <Chip className="m-4">{billingRun.status}</Chip>
              {billingRun.status === "EXPORTED" && (
                <ExtraSmallText className="m-4">
                  {billingRun.exportedAtDateTime
                    ? formatUTCToLocalDateTimeString(
                        billingRun.exportedAtDateTime,
                      )
                    : "-"}
                </ExtraSmallText>
              )}
            </Row>
          </Grid>
        </Row>
      </Paper>
      <Grid item xs={12}>
        <RenderedTable selectedTab={selectedTab} />
      </Grid>
    </>
  );
});
