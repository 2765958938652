import { takeEvery, put, select } from "redux-saga/effects";

import { SaleAction } from "actions";

import { NOMINATION } from "constants/actionTypes";

import { openSale } from "lib/navigation";
import toast from "lib/toast";

import {
  getConsignableSaleById,
  getSaleById,
  selectSaleyardNameBySaleyardIdLookup,
} from "selectors";

function* onCreateNominationSucceeded(action) {
  const { options = {}, payload } = action;
  const { livestock_sale_id: livestockSaleId } = payload;
  const { redirectToSale } = options;
  toast.success("Nomination Created");
  if (redirectToSale) {
    const state = yield select();

    const livestockSale = getSaleById(livestockSaleId)(state);
    const saleyardName = !livestockSale
      ? getConsignableSaleById(livestockSaleId)(state)?.saleyardName
      : selectSaleyardNameBySaleyardIdLookup(state)[livestockSale.saleyard_id];

    openSale({
      saleyard_name: saleyardName,
      livestocksale_id: livestockSaleId,
    });
  }
}
function* onCreateNomination(action) {
  if (action.payload.livestock_sale_id) {
    const state = yield select();
    if (!getSaleById(action.payload.livestock_sale_id)(state)) {
      yield put(SaleAction.request());
    }
  }
}

function onUpdateNominationSucceeded() {
  try {
    toast.success("Nomination Updated");
  } catch {
    toast.error("Failed To Update Nomination");
  }
}

export default function* rootSaga() {
  yield takeEvery(NOMINATION.CREATE.SUCCESS, onCreateNominationSucceeded);
  yield takeEvery(NOMINATION.CREATE.REQUEST, onCreateNomination);
  yield takeEvery(NOMINATION.UPDATE.SUCCESS, onUpdateNominationSucceeded);
}
