import React from "react";

import DeliverSaleLotModal from "components/DeliverSaleLotModal";

import { ModalTypes } from "constants/navigation";

function DeliverSaleLotModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.DeliverSaleLot] || null;
  const [saleLotId] = JSON.parse(hashParams);

  const { returnTo } = hash;

  return (
    <DeliverSaleLotModal
      saleLotId={saleLotId}
      fullScreen={false}
      returnTo={returnTo}
    />
  );
}

export default DeliverSaleLotModalAdapter;
