import React, { useCallback, useMemo } from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { BillingRunAction } from "actions";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { BillingRunForm } from "components/Billing/BillingRun/Form";
import { DefaultInitialValues } from "components/Billing/BillingRun/lib";
import { ValidationSchema } from "components/Billing/BillingRun/validationSchema";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { AuditLogTypes } from "constants/auditLog";
import { BillingRunPermissions } from "constants/billingRuns";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import { getLivestockSaleId } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import {
  getBillingRunById,
  getEffectiveDeploymentId,
  selectRoleCurrentDeployments,
} from "selectors";

import {
  useBoolean,
  useHasSaleyardOrDeploymentPermission,
  useModalAdapter,
  useSomeHasPermission,
} from "hooks";

export function BillingRunModalAdapter(props) {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.EditBillingRun,
    props,
  );

  const { billingRunId = null, ruleBookId = null } = hashParams || {};

  const hasBillingRunFeaturePermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureBilling,
    SaleyardPermissions.featureBilling,
  );

  if (!hasBillingRunFeaturePermission) {
    return null;
  }

  return (
    <WaitForSync requiredData={[ApiModel.BILLING_RUNS]}>
      <BillingRunModal
        billingRunId={billingRunId}
        onClose={onClose}
        returnTo={returnTo}
        ruleBookId={ruleBookId}
      />
    </WaitForSync>
  );
}

export function Footer(props) {
  const { billingRunId, onClose, onDelete } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useBoolean();
  const billingRun = useSelector(getBillingRunById(billingRunId));
  const isEdit = Boolean(billingRun);

  const hasCreatePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.canCreateBillingRun,
  );

  const hasDeletePermission =
    isEdit && hasPermission(billingRun, BillingRunPermissions.delete);

  const hasUpdatePermission =
    isEdit && hasPermission(billingRun, BillingRunPermissions.update);

  useSubmitHandler(false, setIsSubmitEnabled);

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      {isEdit ? (
        <>
          {hasDeletePermission && (
            <DeleteButton
              data-tour="delete"
              type="button"
              onClick={openConfirmDialog}
            >
              Delete
            </DeleteButton>
          )}
          {hasUpdatePermission && (
            <Button data-tour="save" type="submit" disabled={!isSubmitEnabled}>
              Save
            </Button>
          )}
        </>
      ) : (
        <>
          {hasCreatePermission && (
            <Button type="submit" disabled={!isSubmitEnabled}>
              Create
            </Button>
          )}
        </>
      )}

      <ConfirmDialog
        title="Confirm Delete Billing Run"
        isOpen={isConfirmDialogOpen}
        onCancel={closeConfirmDialog}
        onDelete={onDelete}
      />
    </>
  );
}

export function BillingRunModal(props) {
  const { billingRunId, onClose } = props;
  const dispatch = useDispatch();
  const billingRun = useSelector(getBillingRunById(billingRunId));
  const deploymentId = useSelector(getEffectiveDeploymentId);

  const isEdit = Boolean(billingRun);

  const initialValues = useMemo(
    () => ({
      // Apply defaults,
      ...DefaultInitialValues,
      id: uuidv4(),
      // TODO MultiSale Billing Runs will need to figure this out
      deploymentId,
      livestockSaleIds: [getLivestockSaleId()],
      // Apply values from the existing Billing Run
      ...billingRun,
      // Apply information passing in by args
    }),
    [billingRun, deploymentId],
  );

  const onClickDelete = useCallback(() => {
    dispatch(BillingRunAction.delete(billingRunId));
    onClose();
  }, [dispatch, billingRunId, onClose]);

  function onSubmit(values) {
    if (isEdit) {
      dispatch(
        BillingRunAction.update(values, {
          changeReason: "Updated from Edit Billing Run modal",
        }),
      );
    } else {
      dispatch(BillingRunAction.create(values));
    }
    onClose();
  }

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>
        {billingRunId && (
          <AuditLogLink
            auditLogType={AuditLogTypes.BILLING_RUN}
            dataId={billingRunId}
            returnTo={window.location.hash}
          />
        )}
        {isEdit ? "Edit" : "Create"} Billing Run
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
      >
        <Form data-tour="billing-run-form">
          <DialogContent dividers>
            <BillingRunForm billingRunId={billingRunId} />
          </DialogContent>
          <DialogActions>
            <Footer
              billingRunId={billingRunId}
              onDelete={onClickDelete}
              onClose={onClose}
            />
          </DialogActions>
        </Form>
      </Formik>
    </ZoomyDialog>
  );
}
