import { intersection } from "lodash";
import { createSelector } from "reselect";

import { ExportSiteHeaders } from "constants/exportSites";

import { EMPTY_ARRAY } from "lib";

import {
  createIdByKeySelectorAndGetter,
  createLookupCombiner,
  createLookupSelectors,
  getCurrentSale,
  getDeployments,
  selectCurrentDeploymentSales,
  getConsignments,
  getSaleLots,
  selectSaleLotIdsByDeploymentSaleIdLookup,
  selectDeploymentSaleIdBySaleLotIdLookup,
  getAuctionPens,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";

// Drives what options are available when editing a deployment sale.
export const [
  selectExportSitesByDeploymentIdLookup,
  getExportSitesByDeploymentId,
] = createIdByKeySelectorAndGetter(getDeployments, "id", "export_sites");

// Options & Default values to assign to a salelot
export const [
  selectExportSitesByDeploymentSaleIdLookup,
  getExportSitesByDeploymentSaleId,
] = createIdByKeySelectorAndGetter(
  selectCurrentDeploymentSales,
  "deployment_sale_id",
  "export_sites",
);

export const toExportSiteOption = exportSite => ({
  value: exportSite,
  label: ExportSiteHeaders[exportSite],
});

// Current options applicable to a salelot.
const exportSiteOptionsByConsignmentIdCombiner = (id, currentSale) =>
  currentSale.deployment_sales
    .find(s => s.deployment_sale_id === id)
    ?.export_sites?.map(toExportSiteOption) || EMPTY_ARRAY;

export const [
  selectDeploymentSaleIdByConsignmentIdLookup,
  getDeploymentSaleIdByConsignmentId,
] = createIdByKeySelectorAndGetter(getConsignments, "id", "deployment_sale");

export const [
  selectExportSiteOptionsByConsignmentIdLookup,
  getExportSiteOptionsByConsignmentId,
] = createLookupSelectors(
  [selectDeploymentSaleIdByConsignmentIdLookup, getCurrentSale],
  createLookupCombiner(exportSiteOptionsByConsignmentIdCombiner),
);

function getAreExportSitesInSync(aSites, bSites) {
  return (
    Array.isArray(aSites) &&
    Array.isArray(bSites) &&
    aSites.length === bSites.length &&
    intersection(aSites, bSites).length === aSites.length
  );
}

export const [
  selectIsExportSitesInSyncBySaleLotIdLookup,
  getIsExportSitesInSyncBySaleLotId,
] = createLookupSelectors(
  [
    getSaleLots,
    selectDeploymentSaleIdBySaleLotIdLookup,
    selectExportSitesByDeploymentSaleIdLookup,
  ],
  createLookupCombiner(
    (saleLot, deploymentSaleIdBySaleLotId, exportSitesByDeploymentSaleId) => {
      const deploymentSaleExportSites =
        exportSitesByDeploymentSaleId[deploymentSaleIdBySaleLotId[saleLot.id]];
      return getAreExportSitesInSync(
        saleLot.exportSites,
        deploymentSaleExportSites,
      );
    },
  ),
);

export const [
  selectIsSaleLotExportSitesInSyncByDeploymentSaleIdLookup,
  getIsSaleLotExportSitesInSyncByDeploymentSaleIdLookup,
] = createLookupSelectors(
  [
    selectSaleLotIdsByDeploymentSaleIdLookup,
    selectIsExportSitesInSyncBySaleLotIdLookup,
  ],
  createLookupCombiner((saleLotIds, isExportSitesInSyncBySaleLotId) =>
    saleLotIds.every(saleLotId => isExportSitesInSyncBySaleLotId[saleLotId]),
  ),
);

export const selectExportSitesByAuctionPenIdLookup = createSelector(
  [getAuctionPens, selectSaleLotIdsByAuctionPenIdLookup, getSaleLots],
  (auctionPens, saleLotIdsByAuctionPenIdLookup, saleLots) =>
    Object.values(auctionPens).reduce((acc, auctionPen) => {
      const exportSites = [];
      saleLotIdsByAuctionPenIdLookup[auctionPen.id]?.forEach(saleLotId => {
        saleLots[saleLotId].exportSites.forEach(exportSite => {
          if (!exportSites.includes(exportSite)) {
            exportSites.push(exportSite);
          }
        });
      });
      acc[auctionPen.id] = exportSites;
      return acc;
    }, {}),
);

export const getExportSitesByAuctionPenId = auctionPenId => state =>
  selectExportSitesByAuctionPenIdLookup(state)[auctionPenId] || [];
