import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import BuyerCards from "components/BuyerCards";

import { saleIdOnly } from "lib/sale";

import { getSaleLotsByBuyer, getSaleLotsByBuyerByRound } from "selectors";

const mapStateToProps = (state, props) => ({
  thirdParties: getSaleLotsByBuyer(state, saleIdOnly(props)),
  thirdPartiesByRound: getSaleLotsByBuyerByRound(state, saleIdOnly(props)),
  basePath: props.match.url,
});

export default compose(connect(mapStateToProps), withRouter)(BuyerCards);
