import { RegisteredBusinessStatus } from "constants/bidders";

import { deserializeAddress, serializeAddress } from "lib/serializers/address";

export function registrationNumberSort(a, b) {
  return b.registrationNumber - a.registrationNumber;
}

export function getRegisteredBidderStatus(
  businessId,
  businessName,
  isBusinessLoading,
) {
  if (businessId) {
    if (businessName) {
      return RegisteredBusinessStatus.REGISTERED;
    } else if (isBusinessLoading) {
      return RegisteredBusinessStatus.CALCULATING;
    }
  } else {
    return RegisteredBusinessStatus.INCOMPLETE;
  }
  return RegisteredBusinessStatus.ERROR;
}

/**
 * @param {BidderRegistration} bidderRegistration
 */
export function serialiseBidderRegistration(bidderRegistration) {
  return {
    business: bidderRegistration.businessId,
    buyer_way: bidderRegistration.buyerWay,
    default_property_id: bidderRegistration.defaultPropertyId,
    id: bidderRegistration.id,
    notes: bidderRegistration.notes,
    registration_number: bidderRegistration.registrationNumber,
    use_business_address: bidderRegistration.useBusinessAddress,
    use_business_contact: bidderRegistration.useBusinessContact,

    first_name: bidderRegistration.firstName,
    last_name: bidderRegistration.lastName,
    phone_number: bidderRegistration.phoneNumber,
    email: bidderRegistration.email,

    postal_address: serializeAddress(bidderRegistration.postalAddress),
    delivery_address: serializeAddress(bidderRegistration.deliveryAddress),

    // intentionally omitted
    // provisional_data,
  };
}

function parseProvisionalData(provisionalData) {
  try {
    return JSON.parse(provisionalData) || {};
  } catch (err) {
    /* eslint-disable no-console */
    console.error(
      "Error parsing JSON from provisional data: ",
      provisionalData,
    );
    return {};
  }
}
/**
 * @returns {BidderRegistration}
 */
export function deserialiseRegistration({
  business,
  buyer_way,
  default_property_id,
  id,
  notes,
  registration_number,
  use_business_address,
  use_business_contact,
  provisional_data,
  first_name,
  last_name,
  phone_number,
  email,
  postal_address,
  delivery_address,
}) {
  return {
    id,
    notes,
    businessId: business,
    buyerWay: buyer_way,
    defaultPropertyId: default_property_id,
    registrationNumber: registration_number,
    useBusinessAddress: use_business_address,
    useBusinessContact: use_business_contact,
    provisionalData: parseProvisionalData(provisional_data),
    firstName: first_name,
    lastName: last_name,
    phoneNumber: phone_number,
    email,
    postalAddress: deserializeAddress(postal_address),
    deliveryAddress: deserializeAddress(delivery_address),
  };
}
