import React, { useState } from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addConsignment, deleteConsignment, patchConsignment } from "actions";

import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import {
  FormikAwareBusinessPICSelector,
  useSubmitHandler,
} from "components/Form/FormikControls";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { VENDOR } from "constants/businesses";
import { ConsignmentType } from "constants/consignments";
import { ApiModel } from "constants/loading";

import { getConsignmentPayload } from "lib/consignments";

import {
  getAgencyIdByConsignmentId,
  getBusinessById,
  getConsignmentById,
  getCurrentSale,
  getVendorSplitConsignmentById,
  getVendorSplitConsignmentIdsByParentConsignmentId,
  selectVendorSplitSaleLotIdsByConsignmentIdLookup,
} from "selectors";

import { useBoolean } from "hooks";

import { validationSchema } from "./validationSchema";

const businessRoles = [VENDOR];

function EditVendorSplitConsignmentForm({ setIsSubmitEnabled }) {
  useSubmitHandler(true, setIsSubmitEnabled);
  // TODO AG0-3595 add percentage prefill here.
  return (
    <FormikAwareBusinessPICSelector
      allowBusinessOnly
      businessFieldName="vendor_id"
      label="Vendor"
      businessRoles={businessRoles}
    />
  );
}

function DeleteConsignment(props) {
  const { closeSelf, consignmentId, otherVendorSplitConsignmentIds } = props;

  const vendorSplitConsignment =
    useSelector(getVendorSplitConsignmentById(consignmentId)) || {};

  const dispatch = useDispatch();

  // If we're one of only two vendor splits, deleting this one will delete the other.
  const isSecondToLastVendorSplit = otherVendorSplitConsignmentIds.length === 2;

  const otherVendorSplitToDelete = useSelector(state => {
    if (isSecondToLastVendorSplit) {
      return getVendorSplitConsignmentById(
        otherVendorSplitConsignmentIds.find(cid => cid !== consignmentId),
      )(state);
    } else {
      return null;
    }
  });

  const saleLotIdsByConsignmentIdLookup = useSelector(
    selectVendorSplitSaleLotIdsByConsignmentIdLookup,
  );

  // Don't allow deletes if:
  // - This is a creation
  // - There are lots attached to this consignment
  // - We would be deleting the other consignment, and there are lots attached to it.
  const deleteDisabled =
    consignmentId === null ||
    saleLotIdsByConsignmentIdLookup[consignmentId]?.length > 0 ||
    (otherVendorSplitToDelete &&
      saleLotIdsByConsignmentIdLookup[otherVendorSplitToDelete.id]?.length > 0);
  const [isConfirmDeleteOpen, setConfirmDeleteOpen, setConfirmDeleteClosed] =
    useBoolean(false);

  const deleteConfirmationMessage = otherVendorSplitToDelete
    ? "As this is the second to last Vendor Split, deleting this Vendor Split will delete all Vendor Splits attached to this consignment."
    : "This will delete the Vendor Split and cannot be undone.";
  const onDelete = () => {
    dispatch(deleteConsignment(vendorSplitConsignment));
    if (otherVendorSplitToDelete) {
      dispatch(deleteConsignment(otherVendorSplitToDelete));
    }
    closeSelf();
  };

  return (
    <>
      <DeleteButton
        disabled={deleteDisabled}
        onClick={setConfirmDeleteOpen}
        type="button"
        title="There must be no Sale Lots attributed to this Vendor Split to remove it."
      >
        Delete
      </DeleteButton>
      <ConfirmDialog
        title={createModalTitle("this Vendor Split")}
        message={deleteConfirmationMessage}
        isOpen={isConfirmDeleteOpen}
        onCancel={setConfirmDeleteClosed}
        onDelete={onDelete}
      />
    </>
  );
}

export function EditVendorSplitConsignmentModal({
  parentConsignmentId,
  consignmentId,
  onClose,
}) {
  const dispatch = useDispatch();
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const vendorSplitConsignment =
    useSelector(getVendorSplitConsignmentById(consignmentId)) || {};
  const parentConsignment =
    useSelector(getConsignmentById(parentConsignmentId)) || {};
  const parentVendor =
    useSelector(getBusinessById(parentConsignment?.vendor_id)) || {};
  const agencyId = useSelector(getAgencyIdByConsignmentId(parentConsignmentId));
  const selectedSale = useSelector(getCurrentSale);
  const otherVendorSplitConsignmentIds = useSelector(
    getVendorSplitConsignmentIdsByParentConsignmentId(parentConsignmentId),
  );

  // If this is the first vendor split, we will create a companion primary vendor split
  const shouldCreatePrimary = otherVendorSplitConsignmentIds.length === 0;

  const vendorNumberAgencyShortCode = `${
    parentConsignment.livestock_agency_code || ""
  }${parentConsignment.vendorNumber || "#?"}`;

  const initialValues = { vendor_id: null };
  if (consignmentId) {
    initialValues.vendor_id = vendorSplitConsignment.vendor_id;
  }

  const handleSubmit = values => {
    if (consignmentId) {
      dispatch(
        patchConsignment(
          {
            ...values,
          },
          consignmentId,
          { changeReason: "Updated from edit Vendor Split." },
        ),
      );
    } else {
      dispatch(
        addConsignment(
          uuidv4(),
          getConsignmentPayload({
            ...values,
            consignmentType: ConsignmentType.VENDOR_SPLIT,
            parentId: parentConsignmentId,
            quantity: parentConsignment.quantity,
          }),
          selectedSale,
          null,
          null,
          null,
          agencyId,
        ),
      );
      if (shouldCreatePrimary) {
        // If this is the first split, we want to create a mirror of the primary lot, with the same vendor id.
        dispatch(
          addConsignment(
            uuidv4(),
            getConsignmentPayload({
              consignmentType: ConsignmentType.VENDOR_SPLIT,
              parentId: parentConsignmentId,
              quantity: parentConsignment.quantity,
              vendor_id: parentConsignment.vendor_id,
            }),
            selectedSale,
            null,
            null,
            null,
            agencyId,
          ),
        );
      }
    }

    // TODO AG-3595 - go and split lots if this consignment exists.
    onClose();
  };

  return (
    <ZoomyDialog open onClose={onClose}>
      <WaitForSync requiredData={[ApiModel.CONSIGNMENTS, ApiModel.BUSINESSES]}>
        <DialogTitle onClose={onClose}>
          {consignmentId === null ? "Create" : "Edit"} Vendor Split <br />
          Consignment {vendorNumberAgencyShortCode} - {parentVendor.name} (Hd{" "}
          {parentConsignment.quantity})<br />
        </DialogTitle>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
        >
          <Form>
            <DialogContent dividers>
              <EditVendorSplitConsignmentForm
                setIsSubmitEnabled={setIsSubmitEnabled}
              />
            </DialogContent>

            <DialogActions>
              <SecondaryButton onClick={onClose}>Back</SecondaryButton>

              <DeleteConsignment
                closeSelf={onClose}
                otherVendorSplitConsignmentIds={otherVendorSplitConsignmentIds}
                consignmentId={consignmentId}
              />
              <Button
                data-tour={consignmentId === null ? "create" : "save"}
                type="submit"
                disabled={!isSubmitEnabled}
              >
                {consignmentId === null ? "Create" : "Save"}
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </WaitForSync>
    </ZoomyDialog>
  );
}
