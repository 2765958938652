import { SCREEN_RESIZE } from "constants/actionTypes";
import { ScreenTypes } from "constants/screen";
import { breakpoints } from "constants/theme";

const initialWidth = typeof window === "object" ? window.innerWidth : null;

const initialState = {
  width: initialWidth,
  type:
    initialWidth > breakpoints[2] ? ScreenTypes.DESKTOP : ScreenTypes.MOBILE,
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case SCREEN_RESIZE:
      return {
        ...state,
        width: action.width,
        type:
          action.width >= breakpoints[2]
            ? ScreenTypes.DESKTOP
            : ScreenTypes.MOBILE,
      };
    default:
      return state;
  }
}
