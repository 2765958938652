import reduceReducers from "reduce-reducers";

import { INTEGRATION_BUSINESS } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
} from "lib/reducers";
import { deserializeIntegrationBusiness } from "lib/serializers/integrationBusinesses";

const create = apiModelOfflineCreateReducer(INTEGRATION_BUSINESS, {
  deserializer: deserializeIntegrationBusiness,
});

const fetch = apiModelOfflineFetchReducer(INTEGRATION_BUSINESS, {
  deserializer: deserializeIntegrationBusiness,
  clearOnRequest: true,
});

const update = apiModelOfflineUpdateReducer(INTEGRATION_BUSINESS, {
  deserializer: deserializeIntegrationBusiness,
});

const deleteReducer = apiModelOfflineDeleteReducer(INTEGRATION_BUSINESS);

const integrationBusinessReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
);

export default integrationBusinessReducer;
