import {
  GET_PROPERTIES,
  GET_PROPERTIES_CHANGES,
  GET_PROPERTIES_CHANGES_SUCCESS,
  GET_PROPERTIES_FAILURE,
  GET_PROPERTIES_SUCCESS,
  UPDATE_PROPERTIES,
} from "constants/actionTypes";

export function requestProperties() {
  return {
    type: GET_PROPERTIES,
  };
}

export function requestPropertiesChanges() {
  return {
    type: GET_PROPERTIES_CHANGES,
  };
}

export function receiveProperties(properties, lastModifiedTimestamp) {
  return {
    type: GET_PROPERTIES_SUCCESS,
    properties,
    lastModifiedTimestamp,
  };
}

export function receivePropertiesChanges(properties, lastModifiedTimestamp) {
  return {
    type: GET_PROPERTIES_CHANGES_SUCCESS,
    properties,
    lastModifiedTimestamp,
  };
}

export function requestPropertiesError() {
  return {
    type: GET_PROPERTIES_FAILURE,
  };
}

export const updateProperties = message => {
  const properties = message;
  return {
    type: UPDATE_PROPERTIES,
    properties,
  };
};
