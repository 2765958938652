import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import { getIn, useFormikContext } from "formik";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import { CollapseLabel, FormCollapse } from "components/Form";
import { AddressForm } from "components/Form/Fields";
import {
  DateInput,
  Switch,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { formatAddress } from "lib/address";

import {
  getBusinessById,
  getCurrentSale,
  getNameByDeploymentId,
  getIsConsigningAgent,
} from "selectors";

function ClearingSalePickupDetailsHeaderComponent(props) {
  const { namespace: ns, vendorId } = props;
  const { values } = useFormikContext();

  const useVendorAddressAsPickupAddress = getIn(
    values,
    withNamespace(ns, "useVendorAddressAsPickupAddress"),
  );

  const address = useSelector(state =>
    useVendorAddressAsPickupAddress
      ? getBusinessById(vendorId)(state)?.address
      : getIn(values, withNamespace(ns, "pickupAddress")),
  );

  const latestPickupDate = getIn(values, withNamespace(ns, "latestPickupDate"));

  const formattedAddress = isEmpty(address)
    ? "No Address"
    : formatAddress(address);

  return (
    <Row justifyAround data-tour="pickupDetails">
      <div>
        <SubtleBadge>Pickup Details</SubtleBadge>
      </div>
      <CollapseLabel>
        {formattedAddress} (
        {useVendorAddressAsPickupAddress ? "Business" : "Vendor"})
      </CollapseLabel>
      {latestPickupDate && (
        <CollapseLabel>Before {latestPickupDate}</CollapseLabel>
      )}
    </Row>
  );
}

const ClearingSalePickupDetailsHeader = memo(
  ClearingSalePickupDetailsHeaderComponent,
);

function ClearingSalePickupDetailsForm(props) {
  const { namespace: ns, vendorId, readOnly } = props;

  const { values } = useFormikContext();

  const isConsigning = useSelector(getIsConsigningAgent);

  const sale = useSelector(getCurrentSale);
  const deploymentId = sale?.deployment_sales[0]?.deployment_id || null;
  const deploymentName = useSelector(getNameByDeploymentId(deploymentId));

  const vendorName =
    useSelector(state => getBusinessById(vendorId)(state)?.name) || "";

  const useVendorAddressAsPickupAddress = getIn(
    values,
    withNamespace(ns, "useVendorAddressAsPickupAddress"),
  );

  const showUseVendorAddress = Boolean(vendorId);

  const showMangedByText =
    isConsigning && useVendorAddressAsPickupAddress && deploymentName;

  return (
    <>
      <Grid item xs={6}>
        <DateInput
          label="Earliest Pickup Date"
          name={withNamespace(ns, "earliestPickupDate")}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          label="Latest Pickup Date"
          name={withNamespace(ns, "latestPickupDate")}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12}>
        <TextArea
          label="Pickup Notes"
          name={withNamespace(ns, "notes")}
          rows={3}
          maxLength={200}
          disabled={readOnly}
        />
      </Grid>

      {showUseVendorAddress && (
        <Grid item xs={12}>
          <Switch
            label={`Use ${vendorName}'s address as Pickup Address.`}
            name={withNamespace(ns, "useVendorAddressAsPickupAddress")}
            disabled={readOnly}
          />
          {showMangedByText && (
            <div>Vendor address managed by {deploymentName}</div>
          )}
        </Grid>
      )}

      {!useVendorAddressAsPickupAddress && (
        <AddressForm
          label="Pickup Address"
          namespace={withNamespace(ns, "pickupAddress")}
          businessId={vendorId}
          disabled={readOnly}
        />
      )}
    </>
  );
}

ClearingSalePickupDetailsForm.propTypes = {
  namespace: PropTypes.string,
  vendorId: PropTypes.string,
  readOnly: PropTypes.bool,
};

function ClearingSalePickupDetailsComponent(props) {
  const { isOpen, namespace: ns = "", onToggle, vendorId, readOnly } = props;

  const header = (
    <ClearingSalePickupDetailsHeader namespace={ns} vendorId={vendorId} />
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      dataTour={isOpen ? "hidePickupDetails" : "showPickupDetails"}
    >
      <ClearingSalePickupDetailsForm
        namespace={ns}
        vendorId={vendorId}
        readOnly={readOnly}
      />
    </FormCollapse>
  );
}

export default memo(ClearingSalePickupDetailsComponent);
