import { BillingRunPermissions } from "constants/billingRuns";

import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const serializeMap = {
  created: "created",
  comments: "comments",
  deploymentId: "deployment_id",
  exportedAtDateTime: "exported_at_datetime",
  hasChanges: "has_changes",
  id: "id",
  ledgerEntriesGeneratedDateTime: "ledger_entries_generated_datetime",
  livestockSaleIds: "livestock_sale_ids",
  name: "name",
  permissions: "permissions",
  ruleBookId: "rule_book_id",
  status: "status",
};

export const serializeBillingRun = buildSerializer(serializeMap, "billingRun");

export const deserializeBillingRun = buildDeserializer(
  serializeMap,
  "billingRun",
  null,
  {
    permissions: [
      BillingRunPermissions.delete,
      BillingRunPermissions.read,
      BillingRunPermissions.update,
    ],
  },
);
