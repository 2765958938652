import { TRANSFER_STATUS } from "constants/nlis";

import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const nlisFileSerializeMap = {
  asynchronous: "asynchronous",
  errorNotes: "error_notes",
  livestockSaleId: "livestock_sale_id",
  isManualTransfer: "is_manual_transfer",
  processed: "processed",
  saleDate: "sale_date",
  status: "status",
  submitted: "submitted",
  transactionId: "transaction_id",
  transfers: "transfers",
  transferSaleyardId: "transfer_saleyard_id",
  user: "user",
  created: "created",
  id: "id",
  transferType: "transfer_type",
};

const takeFileSerializeMap = {
  ...nlisFileSerializeMap,
  consignments: "consignments",
};

const takeReversalSerializeMap = {
  ...takeFileSerializeMap,
  reversalOf: "reversal_of",
};

export const serializeTakeFile = buildSerializer(
  takeFileSerializeMap,
  "takeFile",
  {},
  {
    consignments: [],
    pending: true,
    transfers: [],
    status: TRANSFER_STATUS.PREPARING_TRANSFER,
  },
);

export const deserializeTakeFile = buildDeserializer(
  takeFileSerializeMap,
  "takeFile",
  null,
  {
    consignments: [],
    isManualTransfer: false,
    transfers: [],
    status: TRANSFER_STATUS.PENDING,
  },
);

export const serializeTakeReversal = buildSerializer(
  takeReversalSerializeMap,
  "takeReversal",
  {},
  { status: TRANSFER_STATUS.UNSUBMITTED, pending: true },
);
export const deserializeTakeReversal = buildDeserializer(
  takeReversalSerializeMap,
  "takeReversal",
);

const sellFileSerializeMap = {
  ...nlisFileSerializeMap,
  saleLots: "sale_lots",
};

const sellReversalSerializeMap = {
  ...sellFileSerializeMap,
  reversalOf: "reversal_of",
};

export const serializeSellFile = buildSerializer(
  sellFileSerializeMap,
  "sellFile",
  {},
  {
    status: TRANSFER_STATUS.PREPARING_TRANSFER,
    pending: true,
    transfers: [],
  },
);
export const deserializeSellFile = buildDeserializer(
  sellFileSerializeMap,
  "sellFile",
  {},
  { isManualTransfer: false, status: TRANSFER_STATUS.PENDING },
);

export const serializeSellReversal = buildSerializer(
  sellReversalSerializeMap,
  "sellReversal",
  {},
  { status: TRANSFER_STATUS.UNSUBMITTED, pending: true },
);
export const deserializeSellReversal = buildDeserializer(
  sellReversalSerializeMap,
  "sellReversal",
);

const sightingsSerializeMap = {
  EID: "EID",
  messageGroupId: "message_group_id",
  status: "status",
};

const sightingFileSerializeMap = {
  ...nlisFileSerializeMap,
  sightings: sightingsSerializeMap,
};

const nestedSightingsFileSerializeMap = {
  nomination_details: "nominationDetails",
};

export const serializeSighting = buildSerializer(
  sightingFileSerializeMap,
  "sighting",
  nestedSightingsFileSerializeMap,
);
export const deserializeSighting = buildDeserializer(
  sightingFileSerializeMap,
  "sighting",
  nestedSightingsFileSerializeMap,
);

const p2pFileSerializeMap = {
  ...nlisFileSerializeMap,
  authoriserFirstName: "authoriser_firstname",
  authoriserLastName: "authoriser_lastname",
  authorityLevel: "authority_level",
  disclaimerAccepted: "disclaimer_accepted",
  saleLots: "sale_lots",
};

export const serializeP2PFile = buildSerializer(p2pFileSerializeMap, "p2p");
export const deserializeP2PFile = buildDeserializer(p2pFileSerializeMap, "p2p");

const nlisMessageGroupSerializeMap = {
  messageNo: "message_no",
  text: "text",
  action: "action",
};

export const serializeNlisMessageGroup = buildSerializer(
  nlisMessageGroupSerializeMap,
  "nlisMessageGroup",
);
export const deserializeNlisMessageGroup = buildDeserializer(
  nlisMessageGroupSerializeMap,
  "nlisMessageGroup",
);

const p2pReversalSerializeMap = {
  ...p2pFileSerializeMap,
  reversalOf: "reversal_of",
};
export const serializeP2PReversal = buildSerializer(
  p2pReversalSerializeMap,
  "p2pReversal",
  {},
  { status: TRANSFER_STATUS.PREPARING_TRANSFER, transfers: [] },
);

export const deserializeP2PReversal = buildDeserializer(
  p2pReversalSerializeMap,
  "p2pReversal",
);
