import React from "react";

import PropTypes from "prop-types";
import { UnmountClosed as ReactCollapse } from "react-collapse";
import styled from "styled-components/macro";

export const CollapsibleArea = styled.div(
  ({ unstyled, theme }) => `
    ${
      !unstyled
        ? `
    background-color:  ${theme.colors.collapseBackground};
    box-shadow: inset 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
  `
        : ""
    }
      padding: 1rem 0;
`,
);

const Hr = styled.div`
  border-top: 1px solid #e9e9e9;
`;

const CollapseTransitioner = styled.div`
  & .ReactCollapse--collapse {
    transition: height 200ms;
  }
`;

const Collapse = ({ isOpen, children, unstyled }) => (
  <CollapseTransitioner>
    {!unstyled && <Hr />}
    <ReactCollapse isOpened={isOpen}>
      <CollapsibleArea unstyled={unstyled}>{children}</CollapsibleArea>
    </ReactCollapse>
  </CollapseTransitioner>
);

Collapse.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  unstyled: PropTypes.bool,
};

export default Collapse;
