import { isEmpty } from "lodash";
import { createSelector } from "reselect";

import { SCAN_NLIS_STATUS } from "constants/nlis";

import { EMPTY_ARRAY } from "lib";

import {
  getActiveNLISSaleyardCredentials,
  getBusinesses,
  getConsignments,
  getCurrentSale,
  getCurrentUser,
  getDraftingInformation,
  getLabels,
  getProperties,
  getSaleLotsBySale,
  getSaleyardScanSaleLots,
  getScans,
  getSellFiles,
  getTakeFiles,
  getWeighLots,
  getWeighLotScans,
  selectAgencyByDeploymentIdLookup,
  selectAgencyBySaleLotIdLookup,
  selectBuyerBySaleLotIdLookup,
  selectConsignmentByReceivalLotIdLookup,
  selectFilteredEids,
  selectIsP2PTransferrableByEidLookup,
  selectIsSellableByEid,
  selectIsTakeableByEid,
  selectResolvedSaleLotCommentCountBySaleLotIdLookup,
  selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
} from "selectors";

import { selectPenScanLotAggridDataByIdLookup } from "./penScanLots";
import { selectReceivalLotAggridDataByIdLookup } from "./receivalLots";

const getTransferByTransactionAndEidLookup = nlisFiles =>
  Object.entries(nlisFiles).reduce((acc, [nlisFileId, nlisFile]) => {
    acc[nlisFileId] = nlisFile.transfers.reduce((eidAcc, transfer) => {
      for (const eid of transfer.eids) {
        eidAcc[eid.EID] = Object.assign({}, eid, transfer);
      }
      return eidAcc;
    }, {});
    return acc;
  }, {});

const selectCurrentTakeTransferByTransactionAndEidLookup = createSelector(
  [getTakeFiles],
  getTransferByTransactionAndEidLookup,
);

const selectCurrentSellTransferByTransactionAndEidLookup = createSelector(
  [getSellFiles],
  getTransferByTransactionAndEidLookup,
);

export const scanList = createSelector(
  [
    getScans,
    getSaleLotsBySale,
    getSaleyardScanSaleLots,
    getConsignments,
    getDraftingInformation,
    selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
    selectResolvedSaleLotCommentCountBySaleLotIdLookup,
    selectAgencyBySaleLotIdLookup,
    selectFilteredEids,
    getTakeFiles,
    getSellFiles,
    selectBuyerBySaleLotIdLookup,
    selectCurrentTakeTransferByTransactionAndEidLookup,
    selectCurrentSellTransferByTransactionAndEidLookup,
    selectIsSellableByEid,
    selectIsTakeableByEid,
    getActiveNLISSaleyardCredentials,
    getCurrentSale,
    getCurrentUser,
    selectAgencyByDeploymentIdLookup,
    selectReceivalLotAggridDataByIdLookup,
    selectPenScanLotAggridDataByIdLookup,
    getWeighLotScans,
    selectConsignmentByReceivalLotIdLookup,
    getProperties,
    getBusinesses,
    getWeighLots,
    getLabels,
  ],
  (
    scans,
    saleLots,
    saleyardScansSaleLots,
    consignments,
    draftingInformation,
    unresolvedSaleLotCommentCountBySaleLotIdLookup,
    resolvedSaleLotCommentCountBySaleLotIdLookup,
    agencyBySaleLotIdLookup,
    filteredEids,
    takeFiles,
    sellFiles,
    buyerBySaleLotIdLookup,
    currentTakeTransferByTransactionAndEidLookup,
    currentSellTransferByTransactionAndEidLookup,
    isSellableByEid,
    isTakeableByEid,
    nlisSaleyardCredentials,
    livestockSale,
    currentUser,
    agencyByDeploymentIdLookup,
    receivalLotAggridDataByIdLookup,
    penScanLotAggridDataByIdLookup,
    weighLotScanByIdLookup,
    consignmentByReceivalLotIdLookup,
    properties,
    businesses,
    weighLotByIdLookup,
    labelsLookup,
  ) => {
    const saleLotsById = {};
    for (const saleLot of saleLots) {
      saleLotsById[saleLot.id] = saleLot;
    }

    const result = [];

    for (const eid of filteredEids) {
      const scan = scans[eid];
      const receivalLotId = scan.receival_lot_id;
      let saleLot = saleLotsById[scan.sale_lot_id] || {};
      let consignment = {};
      let livestockAgency = {};
      if (!isEmpty(saleLot)) {
        // eslint-disable-next-line prefer-destructuring
        consignment = saleLot.consignment;
      } else {
        saleLot = saleyardScansSaleLots[scan.sale_lot_id] || {};
        if (!isEmpty(saleLot)) {
          consignment = consignments[saleLot.consignmentId] || {};
        } else if (receivalLotId) {
          consignment = consignmentByReceivalLotIdLookup[receivalLotId] || {};
        }
      }

      const scanDraftingInformation = draftingInformation[scan.drafting_id];

      livestockAgency = scan.deployment_id
        ? agencyByDeploymentIdLookup[scan.deployment_id]
        : agencyBySaleLotIdLookup[scan.sale_lot_id];

      const latestTakeFileTransactionId =
        takeFiles?.[scan.latest_take_file_id]?.transactionId || "";

      const latestSellFileTransactionId =
        sellFiles?.[scan.latest_sell_file_id]?.transactionId || "";

      const latestTakeTransfer =
        currentTakeTransferByTransactionAndEidLookup?.[
          scan.latest_take_file_id
        ]?.[scan.EID];

      const latestSellTransfer =
        currentSellTransferByTransactionAndEidLookup?.[
          scan.latest_sell_file_id
        ]?.[scan.EID];

      const isTransferred =
        scan.nlis_sell_status === SCAN_NLIS_STATUS.GOOD &&
        scan.nlis_take_status === SCAN_NLIS_STATUS.GOOD
          ? "Yes"
          : "No";

      const isSellable = isSellableByEid[scan.EID];
      const isTakeable = isTakeableByEid[scan.EID];

      const resolvedSaleLotCommentCount = scan.sale_lot_id
        ? resolvedSaleLotCommentCountBySaleLotIdLookup[scan.sale_lot_id]
        : undefined;
      const unresolvedSaleLotCommentCount = scan.sale_lot_id
        ? unresolvedSaleLotCommentCountBySaleLotIdLookup[scan.sale_lot_id]
        : undefined;

      const buyer = scan.sale_lot_id
        ? buyerBySaleLotIdLookup[scan.sale_lot_id]
        : undefined;

      const penScanLotId = scan.pen_scan_lot_id;

      const weighLotScanId = scan.weigh_lot_scan_id;

      const devicePic = scan.current_pic;
      const vendorProperty = properties[consignment.vendor_property_id];
      const vendorPic = vendorProperty?.PIC;

      const vendorPicExistsInAnotherConsignment = !!Object.values(
        consignments,
      ).filter(
        consignment =>
          properties[consignment.vendor_property_id]?.PIC === devicePic,
      ).length;

      let rowWarning = null;

      if (devicePic && vendorPic) {
        if (devicePic !== vendorPic) {
          if (vendorPicExistsInAnotherConsignment) {
            rowWarning =
              "Vendor PIC does not equal device PIC and another Consignment has this Device PIC as a Vendor PIC";
          } else {
            rowWarning =
              "Vendor PIC does not equal Device PIC and no Vendor has this Device PIC";
          }
        }
      }

      const weighLotScan = weighLotScanByIdLookup[weighLotScanId] || {};

      const weighLot = weighLotByIdLookup[weighLotScan.weighLotId];

      const rowData = {
        buyer,
        consignment: Object.assign({}, consignment, {
          vendor_property: properties[consignment.vendor_property_id] || {},
        }),
        currentUser,
        draftingAttributes: saleLot.draftingAttributes,
        saleLot: Object.assign({}, saleLot, {
          vendor_name:
            saleLot?.vendor_name ||
            (businesses[consignment.vendor_id] || {}).name ||
            "",
          labels: saleLot.labels?.map(id => labelsLookup[id]?.name || "") || [],
        }),
        resolvedSaleLotCommentCount,
        unresolvedSaleLotCommentCount,
        isSellable,
        isTakeable,
        isTransferred,
        livestockSale,
        latestSellTransfer,
        latestTakeTransfer,
        latestSellFileTransactionId,
        latestTakeFileTransactionId,
        livestockAgency,
        nlisSaleyardCredentials,
        scan,
        scanDraftingInformation,
        receivalLot: receivalLotAggridDataByIdLookup[receivalLotId] || {},
        penScanLot: penScanLotAggridDataByIdLookup[penScanLotId] || {},
        weighLotScan,
        weighLot,
        rowWarning,
      };
      result.push(rowData);
    }

    return result;
  },
);

export const selectScansByNlisTypeAndStatus = createSelector(
  [scanList],
  scanRows => {
    const take = {};
    const sell = {};

    for (const scanRow of scanRows) {
      const { scan } = scanRow;

      if (take[scan.nlis_take_status]) {
        take[scan.nlis_take_status].push(scanRow);
      } else {
        take[scan.nlis_take_status] = [scanRow];
      }

      if (sell[scan.nlis_sell_status]) {
        sell[scan.nlis_sell_status].push(scanRow);
      } else {
        sell[scan.nlis_sell_status] = [scanRow];
      }
    }

    return { take, sell };
  },
);

export const getTakeCorrectableScans = state =>
  selectScansByNlisTypeAndStatus(state).take[
    SCAN_NLIS_STATUS.NEEDS_CORRECTING
  ] || EMPTY_ARRAY;

export const getSellCorrectableScans = state =>
  selectScansByNlisTypeAndStatus(state).sell[
    SCAN_NLIS_STATUS.NEEDS_CORRECTING
  ] || EMPTY_ARRAY;

export const getTakeableScans = createSelector([scanList], scans =>
  scans.filter(scan => scan.isTakeable),
);

export const getSellableScans = createSelector([scanList], scans =>
  scans.filter(scan => scan.isSellable),
);

export const getScansForP2PTransfer = createSelector(
  [getScans, selectIsP2PTransferrableByEidLookup],
  (scans, isTransferrableByEidLookup) => {
    return Object.values(scans)
      .filter(scan => isTransferrableByEidLookup[scan.EID])
      .map(scan => ({
        scan,
      }));
  },
);
