const ERP_PROGRAMS_CODES = {
  AllC: "All Programs used",
  AVAV1:
    "Assigned to cattle that have been vaccinated against anthrax.Status lapses 42 days after date of vaccination and then converts to an AV2 status. Note: Anthrax vaccine has a 42 day WHP.",
  BBBBH:
    "Cattle vaccinated against bovine brucellosis and fit for human consumption.",
  BBBBV:
    "Cattle vaccinated against bovine brucellosis and unfit for human consumption.",
  BTVBTH:
    "Cattle vaccinated against bluetongue virus and fit for human consumption.",
  BTVBTV:
    "Cattle vaccinated against bluetongue virus and unfit for human consumption.",
  CTCTA: "Assigned to cattle that have or may have accessed cotton trash",
  FMDFMH:
    "Cattle vaccinated against foot and mouth disease and fit for human consumption.",
  FMDFMI:
    "Cattle infected with foot and mouth disease and unfit for human consumption.",
  FMDFMV:
    "Cattle vaccinated against foot and mouth disease and unfit for human consumption.",
  IMPOIM1: "Cattle imported from a country where BSE has been diagnosed.",
  JDJD1: "Cattle identified as non-clinical reactors to bovine Johnes disease.",
  JDJD2: "Cattle identified as clinical cases of bovine Johnes disease.",
  JDJDV: "Cattle vaccinated against bovine Johnes disease.",
  LEADPB1:
    "This animal is not fit for human (or animal) consumption. Do not slaughter. Contact your local animal health authority or state food authority for further advice.",
  LEADPB2:
    "This animal is fit for human consumption. The liver and kidneys must be condemned. Contact your local animal health authority or state food authority for further advice.",
  LPANL2:
    "Assigned to devices that have moved to an LPA A or LPA A1 PIC from a non LPA PIC with an OC status and have resided on the LPA A or LPA A1 PIC for less than 6 months.",
  NARMK1F:
    "Cattle where urine and kidneys must be tested for antibacterial residues at slaughter.",
  NARMK1V:
    "Cattle where urine and kidneys must be tested for antibacterial residues at slaughter.",
  NARMK3:
    "Cattle treated by antibiotics such as streptomycin within the last 2 years. Cattle with a K3 status may have antibacterial residues and so are unfit for human consumption.",
  NLSB: "Breeding Bull",
  NLSD1: "Device Damaged Beyond Recongnition",
  NLSIA: "IA Inactive device",
  NLSL1: "Lost Device",
  NLSLT:
    "Lifeitme traceability status for system administrator can upload LT statuses",
  NLSN: "No longer eligible for EU",
  NLSR1: "Replaced Device",
  NLSS: "Cattle reported to police as stolen.",
  OCN1F:
    "Cattle grazed on high-risk contaminated properties and must be tested for organo-chlorines at slaughter.",
  OCN1V:
    "Cattle grazed on high-risk contaminated properties and must be tested for organo-chlorines at slaughter.",
  OCN2F:
    "Cattle grazed on low-risk properties to check for evidence of organo-chlorines at slaughter.",
  OCN2V:
    "Cattle grazed on low-risk properties to check for evidence of organo-chlorines at slaughter.",
  RAMF1:
    "Cattle exposed to imported/unknown restricted animal material (RAM) within the last 30 months. The date of first-known RAM must also be recorded.",
  RAMF2:
    "Cattle exposed to imported/unknown restricted animal material (RAM) more than 30 months ago.",
  RAMF3:
    "Cattle exposed to restricted animal material (RAM) of Australian origin.",
  RVFRVH:
    "Cattle vaccinated against rift valley fever and fit for human consumption.",
  RVFRVI:
    "Cattle infected with rift valley fever and unfit for human consumption.",
  RVFRVV:
    "Cattle vaccinated against rift valley fever and unfit for human consumption",
  AQAQ: "AQ - Anthrax quarantine. Not for human consumption.",
  CTCTP:
    "Assigned to properties that have had cotton trash delivered for use as mulch or stock feed.",
  CTCTW:
    "Assigned to Producer and Feedlot PICs when livestock that have a CTA status are moved on to the PIC.",
  ENDOE10: "E10 - TEST 1 LOT IN 10 FOR HOLD ALL",
  ENDOE30: "E30 - TEST 1 LOT IN 10 AND RELEASE ALL",
  EWEW1: "This PIC may have devices registered with a status of interest",
  JDJDP:
    "Assigned to properties that have had cattle vaccinated against bovine Johnes disease.",
  LPAA: "Accredited",
  LPAA1: "LPA QA Accredited",
  LPAN: "Not accredited",
  NARMKV:
    "All calves in KV lot to be urine sampled for antibacterial testing. Identify all calves from consigning PIC in accordance with processor?s requirements. (i.e. paint mark)",
  NARMKV2: "All calves in KV2 lot -Sample urine for antibacterial testing",
  NFASA: "Accredited",
  NFASN: "Not accredited",
  NLSEU:
    "Assigned to properties that are accredited under the European Union Cattle Accreditation Scheme (EUCAS)",
  OCM: "M - LOT TEST FOR OCs AND RELEASE ALL",
  OCT1F:
    "T1F - Test 1 animal in every lot for OCs. Sampled carcase and companions can be released.",
  OCT1V:
    "T1V - Test 1 animal in every lot for OCs. Sampled carcase and companions can be released.",
  OCT2F:
    "T2F - Test 1 animal in every lot for OCs. Sampled carcase and companions can be released.",
  OCT2V:
    "T2V - Test 1 animal in every lot for OCs. Sampled carcase and companions can be released.",
  OCT3F:
    "T3F - Lot test for OCs. Hold all cattle pending results recommended. (#Domestic abattoirs may test and release test carcase and/or companion animals subject to state meat hygiene authority approval.)",
  OCT3V:
    "T3V - Lot test for OCs. Hold all cattle pending results recommended. (#Domestic abattoirs may test and release test carcase and/or companion animals subject to state meat hygiene authority approval.)",
  OCT4: "T4 - Subject to lot size test 1 or more animals for OCs and hold all. (# Domestic abattoirs may test and release test carcase and/or companion animals subject to state meat hygiene authority approval.)",
  OCX: "X - Do not sell or slaughter. Contact relevant State/Territory Animal Health Authority.",
  PICXM:
    "XM TAS - Do not sell or slaughter. Contact relevant State/Territory Animal Health Authority. Contact Primary Industries TAS on 03 6165 3240.",
  PICXQ:
    "XQ QLD - Do not sell or slaughter. Contact relevant State/Territory Animal Health Authority. Contact Primary Industries QLD on 07 4121 1866.",
  PICXS:
    "XS SA - Do not sell or slaughter. Contact relevant State/Territory Animal Health Authority. Contact Primary Industries SA on 08 8207 7919.",
  PICXV:
    "XV VIC - Do not sell or slaughter. Contact relevant State/Territory Animal Health Authority. Contact Primary Industries VIC on 1800 678 779.",
};

const INVALID_TRANSFER_MESSAGE = "Unknown result.";

export function getStatus(erpCode) {
  return ERP_PROGRAMS_CODES[erpCode] || INVALID_TRANSFER_MESSAGE;
}
