import { createSelector } from "reselect";

import { getCurrentSale } from "selectors/currentSale";
import { selectTradingTermIdsByDeploymentIdLookup } from "selectors/indexes";
import { createLookupCombiner, createLookupSelectors } from "selectors/lib";
import { getTradingTerms } from "selectors/root";

export const [
  selectTradingTermsByDeploymentIdLookup,
  getTradingTermsByDeploymentId,
] = createLookupSelectors(
  [selectTradingTermIdsByDeploymentIdLookup, getTradingTerms],
  createLookupCombiner((tradingTermsIds, tradingTermsByIdLookup) =>
    tradingTermsIds.map(tradingTermId => tradingTermsByIdLookup[tradingTermId]),
  ),
);

export const getCurrentDefaultTradingTerms = createSelector(
  [getCurrentSale, getTradingTerms],
  (currentSale, tradingTermsLookup) => {
    // Get the relevant trading terms for this sale.

    let termForSaleTypeAndSpecies = null;
    let termForSaleType = null;
    let termForDeployment = null;

    const saleSubType = currentSale.sale_sub_type_id;
    const speciesId = currentSale.species_id;
    const saleType = currentSale.sale_type;

    //  Check if we have specific terms for this sale sub type
    Object.values(tradingTermsLookup).forEach(term => {
      // for term in self.deployment.trading_terms.all():
      if (term.saleSubTypeId === saleSubType) {
        // We aren't going to do better than this, so return early.
        return term;
      }

      if (term.saleType === saleType) {
        if (term.speciesId === speciesId) {
          termForSaleTypeAndSpecies = term;
        } else {
          termForSaleType = term;
        }
      }

      if (!term.saleSubType && !term.species && !term.saleType) {
        termForDeployment = term;
      }
    });

    return termForSaleTypeAndSpecies || termForSaleType || termForDeployment;
  },
);
