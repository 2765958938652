import React from "react";

import { Autocomplete, getOptionAsValue } from "components/Form/FormikControls";

import { PricingTypes } from "constants/pricingTypes";

const options = PricingTypes.all();

export const PricingTypeField = ({ name, label, disabled }) => {
  return (
    <Autocomplete
      name={name}
      label={label}
      options={options}
      getOptionValue={getOptionAsValue}
      disabled={disabled}
    />
  );
};
