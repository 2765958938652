import React from "react";

import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

import { Tooltip } from "components/Form/FormikControls/Tooltip";

import { DeploymentPermissions } from "constants/permissions";
import { colors } from "constants/theme";

import { AlternativesToolTipComponent } from "lib/agGrid/tooltips";
import { openEditBusinessModal, openSelectSyncDataModal } from "lib/navigation";

import {
  getOutOfSyncFieldNamesByBusinessId,
  selectRoleCurrentDeployments,
} from "selectors";

import { useSomeHasPermission } from "hooks/useHasPermission";

export const VendorAlternativesCellRenderer = ({ data }) => {
  const hasSyncAllPermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureSyncAllAlternativeBusinessFields,
  );

  const fieldsWithDifferentValues = useSelector(
    getOutOfSyncFieldNamesByBusinessId(data?.vendor.id),
  );
  if (!data || !fieldsWithDifferentValues) {
    return null;
  }
  const { vendor, vendorDataOutOfSync } = data;
  if (!vendor?.alternatives) {
    return null;
  }

  const color =
    vendorDataOutOfSync && vendorDataOutOfSync.length
      ? colors.warningOrange
      : colors.primary;

  const tooltipComponent = (
    <AlternativesToolTipComponent
      fieldsWithDifferentValues={fieldsWithDifferentValues}
    />
  );

  const onClick = () =>
    hasSyncAllPermission
      ? openSelectSyncDataModal(vendor.id)
      : openEditBusinessModal(vendor.id);

  if (fieldsWithDifferentValues && fieldsWithDifferentValues.length) {
    return (
      <Tooltip title={tooltipComponent}>
        <FontAwesomeIcon
          icon={faLink}
          color={color}
          onClick={onClick}
          className="cursor-pointer"
        />
      </Tooltip>
    );
  } else {
    return (
      <FontAwesomeIcon
        icon={faLink}
        color={color}
        onClick={onClick}
        className="cursor-pointer"
      />
    );
  }
};

export const BuyerAlternativesCellRenderer = ({ data }) => {
  const hasSyncAllPermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureSyncAllAlternativeBusinessFields,
  );

  const fieldsWithDifferentValues = useSelector(
    getOutOfSyncFieldNamesByBusinessId(data?.buyer.id),
  );
  if (!data || !fieldsWithDifferentValues) {
    return null;
  }
  const { buyer, buyerDataOutOfSync } = data;
  if (!buyer) {
    return null;
  }

  const color =
    buyerDataOutOfSync && buyerDataOutOfSync.length
      ? colors.warningOrange
      : colors.primary;

  const tooltipComponent = (
    <AlternativesToolTipComponent
      fieldsWithDifferentValues={fieldsWithDifferentValues}
    />
  );

  const onClick = () =>
    hasSyncAllPermission
      ? openSelectSyncDataModal(buyer.id)
      : openEditBusinessModal(buyer.id);

  if (fieldsWithDifferentValues && fieldsWithDifferentValues.length) {
    return (
      <Tooltip title={tooltipComponent}>
        <FontAwesomeIcon
          icon={faLink}
          color={color}
          onClick={onClick}
          className="cursor-pointer"
        />
      </Tooltip>
    );
  } else {
    return (
      <FontAwesomeIcon
        icon={faLink}
        color={color}
        onClick={onclick}
        className="cursor-pointer"
      />
    );
  }
};
