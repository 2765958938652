import React, { memo } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  addSaleyardScans,
  deleteFileImportedScans,
  keepFileImportedScans,
  uploadScansAndDraftingInformation,
  uploadUnallocatedScans,
} from "actions";

import {
  buildDuplicatePenScanEidsList,
  buildDuplicateTakeableEidList,
} from "components/ScanningScreen/ScanningColumn";
import StandardScanningColumn from "components/ScanningScreen/StandardScanningColumn";

import { userTypes } from "constants/users";

import { scanLotConfig } from "lib/scanLot";

import {
  getActiveRole,
  getConsignments,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScans,
  getUploadedScans,
  selectTakeFilesByConsignmentIdLookup,
} from "selectors";

function mapScanToSaleLotScan(unassignedScan) {
  const { eid, fileName, created, sessionName, ignoreDuplicate } =
    unassignedScan;
  return {
    EID: eid,
    created: new Date(created).toISOString(),
    device_id: "file",
    device_name: fileName,
    draft_name: sessionName,
    remove_from_salelot: ignoreDuplicate,
  };
}

function FileImportScanningColumnComponent(props) {
  const {
    consignmentId,
    sessionName,
    isSelected,
    onOpenCreateBulkModal,
    onOpenCreateModal,
    onOpenClearModal,
    onOpenClearSavedModal,
    onOpenSelectModal,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    scanListType,
    isScanDiscrepancyDialogVisible,
    showScanDiscrepancyDialog,
    hideScanDiscrepancyDialog,
    setDuplicateEids,
  } = props;

  const { lotAction, selectLotIdByEidLookup } = scanLotConfig(penType);

  const scanLotIdByEid = useSelector(selectLotIdByEidLookup);

  const consignments = useSelector(getConsignments);
  const userRole = useSelector(getActiveRole);
  const dispatch = useDispatch();
  const uploadedScans = useSelector(getUploadedScans);
  const saleLotScans = useSelector(getScans);
  const saleLots = useSelector(getSaleLots);
  const saleyardScanSaleLots = useSelector(getSaleyardScanSaleLots);
  const takeFiles = useSelector(selectTakeFilesByConsignmentIdLookup);

  const disallowTaken = userRole.type === userTypes.SCALE_OPERATOR;

  const draftScansList = Object.values(uploadedScans)
    .filter(scans => scans.sessionName === sessionName)
    .sort((a, b) => b.created - a.created);

  const isForReceivalOrPenScanning = !!penArchetypeId || !!penId || !!scanLotId;

  const { duplicateEids, eids, keepableEids, hiddenEids } =
    isForReceivalOrPenScanning
      ? buildDuplicatePenScanEidsList(
          draftScansList,
          saleLotScans,
          "eid",
          "ignoreDuplicate",
          penType,
          scanLotId,
          scanLotIdByEid,
        )
      : buildDuplicateTakeableEidList(
          draftScansList,
          saleLotScans,
          saleLots,
          saleyardScanSaleLots,
          consignments,
          takeFiles,
          "eid",
          "ignoreDuplicate",
          disallowTaken,
          consignmentId,
        );

  React.useEffect(
    () => setDuplicateEids(duplicateEids),
    [sessionName, duplicateEids, setDuplicateEids],
  );

  function uploadScansAction(eids, saleLotId) {
    return uploadScansAndDraftingInformation(
      eids.map(eid => mapScanToSaleLotScan(uploadedScans[eid])),
      saleLotId,
    );
  }

  function uploadUnallocatedScansAction(eids, sale) {
    return uploadUnallocatedScans(
      eids.map(eid => mapScanToSaleLotScan(uploadedScans[eid])),
      sale,
    );
  }

  const scans = eids.map(eid => mapScanToSaleLotScan(uploadedScans[eid]));

  const duplicateScans = duplicateEids.map(eid =>
    mapScanToSaleLotScan(uploadedScans[eid]),
  );

  function uploadLotScansAction(
    eids,
    scanLotId,
    penId,
    penArchetypeId,
    scanLotValues,
  ) {
    return lotAction.updateOrCreateWithPenAndScans(
      scanLotId,
      penId,
      penArchetypeId,
      scanLotValues,
      eids.map(eid => mapScanToSaleLotScan(uploadedScans[eid])),
    );
  }

  function saleyardScanAction(eids) {
    return addSaleyardScans(
      eids.map(eid => mapScanToSaleLotScan(uploadedScans[eid])),
      consignmentId,
    );
  }

  function onAfterEidsActions(actionedEids) {
    if (Array.isArray(actionedEids)) {
      // clear all of the eids which have been actioned
      dispatch(deleteFileImportedScans(actionedEids));
    } else {
      // clear all of the eids (which are not duplicate, or un-keepable)
      dispatch(deleteFileImportedScans(eids));
    }
  }

  return (
    <StandardScanningColumn
      consignmentId={consignmentId}
      draftName={sessionName}
      duplicateEids={duplicateEids}
      eids={eids}
      getConsignmentUploadAction={saleyardScanAction}
      getDeleteAction={deleteFileImportedScans}
      getKeepAction={keepFileImportedScans}
      getUploadAction={uploadScansAction}
      getUploadUnallocatedAction={uploadUnallocatedScansAction}
      getLotUploadAction={uploadLotScansAction}
      isSelected={isSelected}
      keepableEids={keepableEids}
      onAfterUploadAction={onAfterEidsActions}
      onAfterDeleteAction={onAfterEidsActions}
      onOpenCreateBulkModal={onOpenCreateBulkModal}
      onOpenCreateModal={onOpenCreateModal}
      onOpenClearModal={onOpenClearModal}
      onOpenClearSavedModal={onOpenClearSavedModal}
      onOpenSelectModal={onOpenSelectModal}
      saleLotId={saleLotId}
      penArchetypeId={penArchetypeId}
      penId={penId}
      scanLotId={scanLotId}
      scanListType={scanListType}
      penType={penType}
      showScanDiscrepancyDialog={showScanDiscrepancyDialog}
      isScanDiscrepancyDialogVisible={isScanDiscrepancyDialogVisible}
      hideScanDiscrepancyDialog={hideScanDiscrepancyDialog}
      scans={scans}
      duplicateScans={duplicateScans}
      hiddenEids={hiddenEids}
    />
  );
}

export default memo(FileImportScanningColumnComponent);
