import { doesSaleLotHaveOverflowPen } from "lib/saleLot";

export function overflowTriStateFilter(fieldValue, selectedValue, row) {
  const hasOverflow = doesSaleLotHaveOverflowPen(row);
  if (selectedValue > 0) {
    return hasOverflow;
  } else if (selectedValue < 0) {
    return !hasOverflow;
  }
  return true;
}
