import React, { memo, useCallback } from "react";

import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Column } from "components/Layout";
import TabRow from "components/TabSelector/TabRow";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { ScreenTypes } from "constants/screen";
import { userTypes } from "constants/users";

import { SecurityTrimmedNavigationTab } from "containers/LivestockSales/SecurityTrimmedNavigationTab";

import { isUserOfType } from "lib/auth";
import {
  openSaleModal,
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
} from "lib/navigation";
import { hasPermission, someHasPermission } from "lib/permissions";

import {
  getAuth,
  getCurrentSale,
  selectCurrentSaleyard,
  selectRoleCurrentDeployments,
  getHasWriteAccessInCurrentSale,
} from "selectors";

const getMobileClearingSaleTabs = (
  auth,
  usingRegisteredBidders,
  hasWriteAccessInCurrentSale,
) => [
  {
    id: "edit-sale",
    title: <FontAwesomeIcon icon={faPencil} />,
    display: true,
    onClick: () => openSaleModal(getLivestockSaleId()),
  },
  {
    id: "vendors",
    title: "Vendors",
    display: true,
  },
  {
    id: "bidder-cards",
    title: "Bidders",
    display:
      hasWriteAccessInCurrentSale &&
      usingRegisteredBidders &&
      isUserOfType([userTypes.LIVESTOCK_AGENT], auth),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featureBidders,
      ),
  },
  {
    id: "catalogue",
    title: "Catalogue",
    display: true,
  },
  {
    id: "buyers",
    title: "Buyers",
    display: true,
  },
  {
    id: "reports",
    title: "Reports",
    display: true,
  },
];

const getDesktopClearingSaleTabs = (
  auth,
  usingRegisteredBidders,
  hasWriteAccessInCurrentSale,
) => [
  {
    id: "edit-sale",
    title: <FontAwesomeIcon icon={faPencil} />,
    display: true,
    onClick: () => openSaleModal(getLivestockSaleId()),
  },
  {
    id: "vendors",
    title: "Vendors",
    display: true,
  },
  {
    id: "bidder-cards",
    title: "Bidders",
    display:
      hasWriteAccessInCurrentSale &&
      usingRegisteredBidders &&
      isUserOfType([userTypes.LIVESTOCK_AGENT], auth),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featureBidders,
      ),
  },
  {
    id: "salelots",
    title: "Sale Lots",
    display: true,
  },
  {
    id: "catalogue",
    title: "Catalogue",
    display: true,
  },
  {
    id: "buyers",
    title: "Buyers",
    display: true,
  },
  {
    id: "reports",
    title: "Reports",
    display: true,
  },
  {
    id: "billing",
    title: "Billing",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(selectRoleCurrentDeployments(state), [
        DeploymentPermissions.featureBilling,
        DeploymentPermissions.featureManualCharges,
      ]),
  },
];

function ClearingSaleTabsComponent() {
  const sale = useSelector(getCurrentSale);
  const screenType = useSelector(state => state.screen.type);
  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const auth = useSelector(getAuth);
  const { params } = useRouteMatch();
  const history = useHistory();

  const { using_registered_bidders: usingRegisteredBidders } = sale;

  const setSection = useCallback(
    section => {
      history.push(
        `${getSaleyardAuctionRoute(
          getSaleyardName(),
          getLivestockSaleId(),
        )}/${section}`,
      );
    },
    [history],
  );
  const { section } = params;

  const isDesktop = screenType === ScreenTypes.DESKTOP;

  const tabs = (
    isDesktop ? getDesktopClearingSaleTabs : getMobileClearingSaleTabs
  )(auth, usingRegisteredBidders, hasWriteAccessInCurrentSale);

  return (
    <Column printHidden>
      <TabRow
        tabs={tabs}
        selectedTab={section}
        setSelectedTab={setSection}
        backgroundColor="gray95"
      />
    </Column>
  );
}

export default memo(ClearingSaleTabsComponent);
