import React from "react";

import { Grid } from "@material-ui/core";
import { withFormik } from "formik";
import sumBy from "lodash/sumBy";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { Warning } from "components/ErrorMessage";
import { Button, SecondaryButton } from "components/Form";
import { FullPageForm, Input } from "components/Form/FormikControls";
import { Column } from "components/Layout";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import PenningSaleLotForm, { PenningMode } from "components/PenningSaleLotForm";
import penningFormValidationSchema from "components/PenningSaleLotForm/validationSchema";

import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { shallowObjectChanges } from "lib";

import { expandAuctionPen, getAuctionPenPayload } from "lib/auctionPens";

const Form = ({
  handleSubmit,
  submitCount,
  isValid,
  closeSelf,
  values: { saleRoundId, overflowPen },
  groupedSaleLots,
  dirty,
}) => {
  const totalHeadCount = sumBy(groupedSaleLots, "quantity");
  const totalDrafts = groupedSaleLots.length;

  const overflowDisabled = Array.isArray(overflowPen) && overflowPen.length > 1;
  return (
    <ZoomyDialog onClose={closeSelf} open>
      <FullPageForm onSubmit={handleSubmit}>
        <DialogTitle onClose={closeSelf}>
          {`Pen Multiple (${totalDrafts} Sale Lots/${totalHeadCount} Hd)`}
        </DialogTitle>
        <DialogContent dividers form="true">
          <Column fullHeight padding={2}>
            <Grid container spacing={2}>
              <PenningSaleLotForm
                label="Auction Pen"
                autoFocusStartPen
                saleRoundId={saleRoundId}
                penningMode={PenningMode.ADVANCED}
              />
              <Grid item xs={6}>
                <Input
                  label="Overflow Pen"
                  name="overflowPen"
                  disabled={overflowDisabled}
                />
              </Grid>

              {overflowDisabled && (
                <Warning>
                  The selected <TranslatedSaleTypeLabel label="Sale Lot" />s
                  contain multiple Overflow Pens. You must edit the Overflow Pen
                  on each one individually.
                </Warning>
              )}
            </Grid>
          </Column>
        </DialogContent>

        <DialogActions>
          <SecondaryButton type="button" onClick={closeSelf}>
            Cancel
          </SecondaryButton>
          <Button
            data-tour="submit"
            type="submit"
            disabled={!dirty || submitCount > 0 || !isValid}
          >
            Submit
          </Button>
        </DialogActions>
      </FullPageForm>
    </ZoomyDialog>
  );
};

Form.propTypes = {
  closeSelf: PropTypes.func.isRequired,
  groupedSaleLots: PropTypes.arrayOf(PropTypes.shape()),
};

const PenMultipleLotsModal = withFormik({
  validationSchema: Yup.object(penningFormValidationSchema),
  mapPropsToValues: props => {
    const requiredFields = {
      start_pen: "",
    };
    const { startPen, endPen, saleRoundId, groupedSaleLots } = props;
    const auctionPen = {
      ...expandAuctionPen({
        start_pen: startPen,
        end_pen: endPen,
      }),
    };
    let agency_id;
    const agencies = [...new Set(groupedSaleLots.map(s => s.agency?.id))];
    if (agencies.length === 1) {
      agency_id = agencies[0];
    }

    return {
      saleRoundId,
      ...requiredFields,
      ...auctionPen,
      ...props.initialValues,
      agency_id,
    };
  },
  handleSubmit: (values, formikBag) => {
    const {
      initialValues,
      patchSaleLot,
      closeSelf,
      addAuctionPen,
      groupedSaleLots,
    } = formikBag.props;

    const formChanges = shallowObjectChanges(values, initialValues);
    if (formChanges) {
      const auctionPenPayload = getAuctionPenPayload(
        values,
        null,
        values.penType,
      );
      // This will cause a server submission for the pen
      // This means it works offline, but may have a changed id if the pen already exists for the subsequently
      // submitted lot submissions.
      // (By rights we could search through state and find the pen id if it's available locally... but the search
      // and cancellation logic is on the backend - leave it there.)
      const auctionPenId = uuidv4();
      addAuctionPen(auctionPenPayload, auctionPenId);

      const payload = {};
      payload.auction_pen_id = auctionPenId;
      payload.overflowPen = values.overflowPen;

      groupedSaleLots.forEach(saleLot => {
        patchSaleLot({ id: saleLot.id, ...payload });
      });
    }

    closeSelf();
  },
})(Form);

export default PenMultipleLotsModal;
