import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { pluralize } from "lib/pluralize";

import { getSingleWeighById } from "selectors";

export const EidWaitTimeoutAction = ({ singleWeighId }) => {
  const singleWeigh = useSelector(getSingleWeighById(singleWeighId));
  const { noEidDelay } = singleWeigh;
  return (
    <Grid container spacing={2}>
      {`No EID was detected after ${noEidDelay} ${pluralize(
        "seconds",
        noEidDelay,
      )}; the system has been paused for your safety.`}
    </Grid>
  );
};
