import React from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { compose } from "redux";

import PageShell from "components/PageShell";

import { Settings } from "constants/settings";

import AuctionPen from "containers/AuctionPenSale/AuctionPen";
import AuctionPenList from "containers/AuctionPenSale/AuctionPenList";

import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
} from "lib/navigation";

import { getAuctionPens, getBusinesses, getSales } from "selectors";

class AuctionPenSale extends React.Component {
  UNSAFE_componentWillMount() {
    this.redirectToRound();
  }

  shouldComponentUpdate(nextProps) {
    const { hasAuctionPens, businesses, match } = this.props;
    return (
      (!hasAuctionPens && nextProps.hasAuctionPens) ||
      (!businesses.businesses && nextProps.businesses.businesses) ||
      !match.params.auctionPenId ||
      match.params.auctionPenId !== nextProps.match.params.auctionPenId
    );
  }

  redirectToRound = () => {
    const { history, sales, round, hasAuctionPens } = this.props;
    const saleyard = getSaleyardName();
    const saleId = getLivestockSaleId();

    if (
      hasAuctionPens &&
      `${getSaleyardAuctionRoute(saleyard, saleId)}/firstround` ===
        history.location.pathname
    ) {
      const sale = sales[saleId];
      const { rounds } = sale;

      if (rounds) {
        const goToRound = rounds.includes(round) ? round : rounds[0];
        const roundRoute = getSaleyardAuctionRoute(saleyard, saleId, goToRound);
        history.replace(roundRoute);
      }
    }
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path={getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
            ":auctionPenId",
          )}
          component={PageShell(AuctionPen)}
        />
        <Route
          exact
          path={getSaleyardAuctionRoute(":saleyard", ":saleId", ":roundId")}
          component={AuctionPenList}
        />
      </Switch>
    );
  }
}

// TODO - I don't think most of these are used??
const mapStateToProps = state => ({
  hasAuctionPens: Object.keys(getAuctionPens(state)).length !== 0,
  businesses: getBusinesses(state),
  sales: getSales(state),
  saleLots: state.saleLots,
  round: state.settings[Settings.round],
  state,
});

AuctionPenSale.propTypes = {
  hasAuctionPens: PropTypes.bool.isRequired,
  businesses: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sales: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps), withRouter)(AuctionPenSale);
