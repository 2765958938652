import React, { memo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

import { getCurrentDraftingDecisionBySingleWeighById } from "selectors";

import { PopoverLocation } from "./AnimalDetailPopover";
import AnimalDetails from "./AnimalDetails";

function CrushDetails(props) {
  const { singleWeighId } = props;
  const currentDraftingDecision =
    useSelector(getCurrentDraftingDecisionBySingleWeighById(singleWeighId)) ||
    {};

  if (!currentDraftingDecision) {
    return <LoadingSpinner />;
  }

  const {
    eid,
    buyerId,
    buyerWayName,
    totalMassGrams,
    totalMassGramsRecorded,
    draftPenId,
    destinationPenId,
    overrideWeight,
    areMultipleEidsDetected,
  } = currentDraftingDecision;

  return (
    <AnimalDetails
      destinationPenId={destinationPenId}
      popoverLocation={PopoverLocation.WITH_WEIGHT}
      draftPenId={draftPenId}
      eid={eid}
      buyerId={buyerId}
      buyerWayName={buyerWayName}
      totalMassGrams={totalMassGrams}
      totalMassGramsRecorded={totalMassGramsRecorded}
      overrideWeight={overrideWeight}
      areMultipleEidsDetected={areMultipleEidsDetected}
    />
  );
}

CrushDetails.propTypes = {
  singleWeighId: PropTypes.string.isRequired,
};

export default memo(CrushDetails);
