import React, { memo, useCallback } from "react";

import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Column } from "components/Layout";
import TabRow from "components/TabSelector/TabRow";

import { DeploymentPermissions } from "constants/permissions";
import { ScreenTypes } from "constants/screen";
import { userTypes } from "constants/users";

import { SecurityTrimmedNavigationTab } from "containers/LivestockSales/SecurityTrimmedNavigationTab";
import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { isUserOfType } from "lib/auth";
import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
  openSaleModal,
} from "lib/navigation";
import { someHasPermission } from "lib/permissions";

import {
  getAuth,
  selectCurrentDeployments,
  selectRoleCurrentDeployments,
} from "selectors";

const getPrivateSaleDesktopTabs = auth => [
  {
    id: "edit-sale",
    title: <FontAwesomeIcon icon={faPencil} />,
    display: true,
    onClick: () => openSaleModal(getLivestockSaleId()),
  },
  {
    id: "overview",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
  },
  {
    id: "consignments",
    title: <TranslatedSaleTypeLabel label="Consignments" />,
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureConsignments,
      ),
  },
  {
    id: "salelots",
    title: "Sale Lots",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureSaleLots,
      ),
  },
  {
    id: "eids",
    title: "EIDs",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureScans,
      ),
  },
  {
    id: "econtracts",
    title: "eContract",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(selectRoleCurrentDeployments(state), [
        DeploymentPermissions.featureEContracts,
      ]),
  },
  {
    id: "reports",
    title: "Sale Reports",
    display: true,
  },
  {
    id: "nlis",
    title: "NLIS",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
  },
  {
    id: "billing",
    title: "Billing",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(selectRoleCurrentDeployments(state), [
        DeploymentPermissions.featureBilling,
        DeploymentPermissions.featureManualCharges,
      ]),
  },
];

const getMobilePrivateSaleDesktopTabs = auth => [
  {
    id: "edit-sale",
    title: <FontAwesomeIcon icon={faPencil} />,
    display: true,
    onClick: () => openSaleModal(getLivestockSaleId()),
  },
  {
    id: "overview",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
  },
  {
    id: "consignment-cards",
    title: <TranslatedSaleTypeLabel label="Consignments" />,
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureConsignments,
      ),
  },
  {
    id: "salelots",
    title: "Sale Lots",
    display: isUserOfType(
      [userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN],
      auth,
    ),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(
        selectCurrentDeployments(state),
        DeploymentPermissions.featureSaleLots,
      ),
  },
  {
    id: "econtracts",
    title: "eContract",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(selectRoleCurrentDeployments(state), [
        DeploymentPermissions.featureEContracts,
      ]),
  },
  {
    id: "reports",
    title: "Sale Reports",
    display: true,
  },
  {
    id: "nlis",
    title: "NLIS",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
  },
  {
    id: "billing",
    title: "Billing",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(selectRoleCurrentDeployments(state), [
        DeploymentPermissions.featureBilling,
        DeploymentPermissions.featureManualCharges,
      ]),
  },
];

function PrivateSaleTabsComponent() {
  const screenType = useSelector(state => state.screen.type);

  const auth = useSelector(getAuth);
  const { params } = useRouteMatch();
  const history = useHistory();

  const setSection = useCallback(
    section => {
      history.push(
        `${getSaleyardAuctionRoute(
          getSaleyardName(),
          getLivestockSaleId(),
        )}/${section}`,
      );
    },
    [history],
  );
  const { section } = params;

  const isDesktop = screenType === ScreenTypes.DESKTOP;

  const [tabsByUser, showTabs] = React.useMemo(() => {
    const tabsByUser = isDesktop
      ? getPrivateSaleDesktopTabs(auth)
      : getMobilePrivateSaleDesktopTabs(auth);

    const showTabs = tabsByUser.filter(t => t.display).length > 0;
    return [tabsByUser, showTabs];
  }, [auth, isDesktop]);

  return (
    <>
      {showTabs && (
        <Column printHidden>
          <TabRow
            tabs={tabsByUser}
            selectedTab={section}
            setSelectedTab={setSection}
            backgroundColor="gray95"
          />
        </Column>
      )}
    </>
  );
}
export default memo(PrivateSaleTabsComponent);
