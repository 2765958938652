import React from "react";

import {
  faPauseCircle,
  faPlayCircle,
  faRetweet,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { resetSingleWeigh, SingleWeighAction } from "actions";

import Button, { MultiButton } from "components/Button";
import { Label } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import ReactSelect from "components/SearchableSelector/ReactSelect";
import { WeightOverrideDialog } from "components/SingleWeighView/WeightOverrideDialog";

import { AUCTION_PEN } from "constants/actionTypes";
import { SingleWeighModeOptions } from "constants/singleWeigh";

import {
  getAutoDraftPensBySingleWeighIdLookup,
  getIsResetDisabledByAutoDraftPenId,
  getSingleWeighById,
} from "selectors";

import { useToggle } from "hooks";

export const ButtonPanel = ({ singleWeighId }) => {
  const singleWeigh = useSelector(getSingleWeighById(singleWeighId));
  const [isOverrideWeightOpen, toggleIsOverrideWeightOpen] = useToggle(false);

  const dispatch = useDispatch();
  function handleResetSingleWeigh() {
    dispatch(resetSingleWeigh(singleWeighId));
  }

  const autoDraftPens = useSelector(
    getAutoDraftPensBySingleWeighIdLookup(singleWeigh.id),
  );

  const penIds = autoDraftPens.map(pen => pen.id);

  const resetDraftingPensDisabled = useSelector(state =>
    penIds.every(penId => getIsResetDisabledByAutoDraftPenId(penId)(state)),
  );

  const handleResetDraftingPens = () =>
    dispatch({
      type: AUCTION_PEN.BULK_RESET_AUTO_DRAFT.REQUEST,
      penIds,
    });

  function togglePaused() {
    dispatch(
      SingleWeighAction.update({
        isPaused: !singleWeigh.isPaused,
        id: singleWeighId,
      }),
    );
  }

  function handleChangeMode({ value }) {
    dispatch(
      SingleWeighAction.update({
        mode: value,
        id: singleWeighId,
      }),
    );
  }

  const currentMode = SingleWeighModeOptions.find(
    option => option.value === singleWeigh.mode,
  );

  const resetButtons = [
    {
      title: "Reset Decision",
      dataTour: "resetDecision",
      icon: faRetweet,
      onClick: handleResetSingleWeigh,
    },
    {
      title: "Reset All Pens",
      dataTour: "resetAllPens",
      icon: faRetweet,
      onClick: handleResetDraftingPens,
      isDisabled: resetDraftingPensDisabled,
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} sm={6} md={12} direction="column">
          <Button
            type="button"
            data-tour={singleWeigh.isPaused ? "Start" : "Pause"}
            onClick={togglePaused}
            fullWidth
          >
            <Row alignCenter justifyCenter>
              <FontAwesomeIcon
                icon={singleWeigh.isPaused ? faPlayCircle : faPauseCircle}
                size="2x"
              />
              &nbsp;{singleWeigh.isPaused ? "Start" : "Pause"}
            </Row>
          </Button>
        </Grid>
        <Grid item container xs={12} sm={6} md={12}>
          <Button
            type="button"
            data-tour="manualWeigh"
            onClick={toggleIsOverrideWeightOpen}
            fullWidth
          >
            <Row alignCenter justifyCenter>
              <FontAwesomeIcon size="2x" icon={faUserEdit} />
              &nbsp; Manual Weigh
            </Row>
          </Button>
        </Grid>

        <Grid dataTour="resetButtons" item container xs={12} sm={6} md={12}>
          <MultiButton
            buttons={resetButtons}
            ButtonComponent={Button}
            inline={false}
            fullWidth
          />
        </Grid>

        <Grid item data-tour="mode" container xs={12} sm={6} md={12}>
          <Column fullWidth>
            <Label>Mode</Label>
            <ReactSelect
              options={SingleWeighModeOptions}
              name="Mode"
              defaultValue={currentMode}
              isClearable={false}
              onChange={handleChangeMode}
              menuPortalTarget={document.body}
            />
          </Column>
        </Grid>
      </Grid>

      <WeightOverrideDialog
        open={isOverrideWeightOpen}
        singleWeighId={singleWeighId}
        toggleOpen={toggleIsOverrideWeightOpen}
      />
    </>
  );
};
