import { buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  id: "id",
  name: "name",
  defaultPricingType: "default_pricing_type",
};

export const deserializeSpecies = buildDeserializer(
  serializeMap,
  "lib.serializers.species",
);
