import React, { useRef } from "react";

import { Switch as MaterialSwitch } from "@material-ui/core";
import { useField, useFormikContext } from "formik";

import { Column } from "components/Layout";

import { Error } from "./Error";
import { Label } from "./Label";

export const Switch = ({
  color = "primary",
  colProps = {},
  disabled,
  label,
  name,
  onChangeExtra,
  required,
  tooltip,
  altTooltip,
  altColor,
  innerRef,
}) => {
  const [field, meta] = useField(name);
  const inputRef = useRef(null);

  const { setFieldTouched, setFieldValue } = useFormikContext();

  function onChange() {
    const nextValue = !field.value;
    setFieldTouched(name, true, false);
    setFieldValue(name, nextValue);
    typeof onChangeExtra === "function" && onChangeExtra(nextValue);
  }

  return (
    <Column {...colProps} fullWidth>
      {(label || tooltip) && (
        <Label
          htmlFor={name}
          required={required}
          tooltip={tooltip}
          altTooltip={altTooltip}
          altColor={altColor}
          justifyStart
          error={!!meta.error}
        >
          {label}
        </Label>
      )}
      <MaterialSwitch
        data-tour={field.name}
        labelledby={name}
        name={name}
        onBlur={field.onBlur}
        onChange={onChange}
        disabled={disabled}
        required={required}
        inputRef={innerRef || inputRef}
        checked={field.value || false}
        color={color}
      />
      {meta.error && <Error>{meta.error}</Error>}
    </Column>
  );
};
