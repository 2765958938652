import React from "react";

import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { Input } from "components/Form/FormikControls";

import { getBuyerInformationString } from "lib/businesses";

import {
  getBidders,
  getBusinesses,
  selectBidderByBuyerInformation,
} from "selectors";

import { useHasChanged } from "hooks/useHasChanged";

export const BidderNumberInput = ({ disabled, onExistingBidderChanged }) => {
  const bidders = useSelector(getBidders);
  const businessesLookup = useSelector(getBusinesses);
  const formikProps = useFormikContext();
  const bidderByBuyerInformationLookup = useSelector(
    selectBidderByBuyerInformation,
  );

  const { values, setErrors, setFieldValue } = formikProps;

  const buyerId = values.buyer_id || values.buyerId;
  const { bidderNumber } = values;

  const destinationPropertyId =
    values.destination_property_id || values.destinationPropertyId;

  const buyerWayName = values.buyer_way?.name || values.buyerWay?.name;

  const buyerInformationString = getBuyerInformationString(
    buyerId,
    destinationPropertyId,
    buyerWayName,
  );

  const bidder = bidderByBuyerInformationLookup[buyerInformationString];

  const hasBidderChanged = useHasChanged(bidder);
  const hasBidderNumberChanged = useHasChanged(bidderNumber);

  React.useEffect(() => {
    // if the bidder (referencing current values buyer/way/pic combination) has changed we need to update the bidder
    // number input to show the bidder number associated to that bidder.
    // if there isn't a bidder number associated, clear it.
    if (hasBidderChanged) {
      if (!bidder) {
        setFieldValue("bidderNumber", null);
      } else {
        setFieldValue("bidderNumber", bidder.registrationNumber);
      }
    }
  }, [hasBidderChanged, bidder, values.bidderNumber, setFieldValue]);

  const existingBidder = Object.values(bidders).find(
    bidder => String(bidder.registrationNumber) === values.bidderNumber,
  );

  const overlappingBidders = Object.values(bidders).filter(bidder => {
    // check if another bidder exists with the same registration number that
    // the user has input, and does not belong to this buyer/way/pic combination
    // used to show a warning and block adding a bidder number that will be over written anyway
    return (
      buyerId &&
      String(bidder.registrationNumber) === values.bidderNumber &&
      (bidder.businessId !== buyerId ||
        (bidder.buyerWay || "") !== (buyerWayName || "") ||
        bidder.defaultPropertyId !== destinationPropertyId)
    );
  });

  React.useEffect(() => {
    if (overlappingBidders.length > 0) {
      setErrors({
        bidderNumber: `Warning: Bidder number 
      ${overlappingBidders.map(bidder => `${bidder.registrationNumber}`)}
       is already taken by 
      ${overlappingBidders.map(
        bidder =>
          `${businessesLookup[bidder.businessId]?.name} ${
            bidder.buyerWay ? bidder.buyerWay : ""
          }`,
      )}`,
      });
    } else if (existingBidder && hasBidderNumberChanged) {
      onExistingBidderChanged(existingBidder);
    }
  }, [
    existingBidder,
    overlappingBidders,
    values.bidderNumber,
    bidder,
    businessesLookup,
    setErrors,
    onExistingBidderChanged,
    hasBidderNumberChanged,
  ]);

  return (
    <Input label="Bidder Number" name="bidderNumber" disabled={disabled} />
  );
};
