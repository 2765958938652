import {
  Column,
  COMMERCIAL_COLUMNS_BLACKLIST,
  COMPLIANCE_COLUMNS_BLACKLIST,
  LIVESTOCK_AGENT_COLUMNS_BLACKLIST,
} from "constants/columns";
import { userTypes } from "constants/users";

import { BaseColumnDefinitions } from "lib/agGrid/columnDefinitions";

const ALL_REPORTS_COLUMNS = [
  BaseColumnDefinitions[Column.AGENCY_NAME],
  BaseColumnDefinitions[Column.QUANTITY_DELIVERED],
  BaseColumnDefinitions[Column.PEN],
  BaseColumnDefinitions[Column.VENDOR_NAME],
  BaseColumnDefinitions[Column.BUYER_NAME],
  BaseColumnDefinitions[Column.BUYER_WAY_NAME],
  BaseColumnDefinitions[Column.BUYER_SHORT_CODE],
  BaseColumnDefinitions[Column.BUYER_SHORT_CODE_SALEYARD],
  BaseColumnDefinitions[Column.DESTINATION_PIC],
  BaseColumnDefinitions[Column.NVD],
  BaseColumnDefinitions[Column.VENDOR_NUM],
  BaseColumnDefinitions[Column.VENDOR_PIC],
  BaseColumnDefinitions[Column.TOTAL_PRICE],
  BaseColumnDefinitions[Column.WEIGHT],
  BaseColumnDefinitions[Column.AVERAGE_WEIGHT],
  BaseColumnDefinitions[Column.LOT_NUMBER_COMBINED],
  BaseColumnDefinitions[Column.UNIT_PRICE],
  BaseColumnDefinitions[Column.SCANNED_COUNT],
  BaseColumnDefinitions[Column.SCANNED_PERCENTAGE],
  BaseColumnDefinitions[Column.QUANTITY],
  BaseColumnDefinitions[Column.DIFF],
  BaseColumnDefinitions[Column.BREED_NAME],
  BaseColumnDefinitions[Column.SEX_NAME],
  BaseColumnDefinitions[Column.AGE_GROUP_NAME],
  BaseColumnDefinitions[Column.CATEGORY_NAME],
  BaseColumnDefinitions[Column.GRADE_NAME],
  BaseColumnDefinitions[Column.SALE_ROUND_NAME],
  BaseColumnDefinitions[Column.STATUS],
  BaseColumnDefinitions[Column.THIRD_PARTY_NAME],
  {
    // We don't want the edit business popup being linked off here - just the name of the business.
    ...BaseColumnDefinitions[Column.INVOICE_TO_NAME],
    cellRenderer: null,
  },
];

const COMMERCIAL_USER_COLUMNS = ALL_REPORTS_COLUMNS.filter(
  colDef => !COMMERCIAL_COLUMNS_BLACKLIST.includes(colDef.field),
);
const COMPLIANCE_USER_COLUMNS = ALL_REPORTS_COLUMNS.filter(
  colDef => !COMPLIANCE_COLUMNS_BLACKLIST.includes(colDef.field),
);
const LIVESTOCK_AGENT_USER_COLUMNS = ALL_REPORTS_COLUMNS.filter(
  colDef => !LIVESTOCK_AGENT_COLUMNS_BLACKLIST.includes(colDef.field),
);

const OTHER_USER_COLUMNS = [];

export const getBusinessUserSaleReportsColumns = (
  isCommercialUser,
  isComplianceUser,
) => {
  if (isCommercialUser) {
    return COMMERCIAL_USER_COLUMNS;
  } else if (isComplianceUser) {
    return COMPLIANCE_USER_COLUMNS;
  } else {
    return OTHER_USER_COLUMNS;
  }
};

export const getSaleReportsColumns = (
  userType,
  isCommercialUser = false,
  isComplianceUser = false,
) => {
  if (userType === userTypes.BUSINESS_USER) {
    return getBusinessUserSaleReportsColumns(
      isCommercialUser,
      isComplianceUser,
    );
  } else if (userType === userTypes.LIVESTOCK_AGENT) {
    return LIVESTOCK_AGENT_USER_COLUMNS;
  } else if (userType === userTypes.SALEYARD_ADMIN) {
    return ALL_REPORTS_COLUMNS;
  } else {
    return OTHER_USER_COLUMNS;
  }
};
