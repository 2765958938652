import React from "react";

import AgGrid from "components/AgGrid/AgGrid";
import { AutoComplete } from "components/AgGrid/AutoComplete";
import { CompositeFilter } from "components/AgGrid/filters/compositeFilter";
import TableWrapper from "components/AgGrid/TableWrapper";

const DEFAULT_COLUMN_DEFINITION = {
  sortable: true,
  resizable: true,
  suppressKeyboardEvent: params => params.editing,
};

const components = {
  [CompositeFilter.componentName]: CompositeFilter,
  autoCompleteEditorDeprecated: AutoComplete,
};

export const BaseTable = ({
  columns,
  context,
  data,
  onCellValueChanged,
  onGridReady,
  onCellEditRequest,
  getRowId,
  defaultColDefOverride = {},
  immutableData,
  readOnlyEdit,
  getContextMenuItems = undefined,
}) => {
  return (
    <TableWrapper>
      <AgGrid
        rowData={data}
        pagination
        paginationPageSize={400}
        columnDefs={columns}
        defaultColDef={{
          ...DEFAULT_COLUMN_DEFINITION,
          ...defaultColDefOverride,
        }}
        getRowId={getRowId}
        immutableData={immutableData}
        components={components}
        onCellEditRequest={onCellEditRequest}
        onCellValueChanged={onCellValueChanged}
        onGridReady={onGridReady}
        readOnlyEdit={readOnlyEdit}
        stopEditingWhenCellsLoseFocus
        singleClickEdit
        suppressScrollOnNewData
        context={context}
        getContextMenuItems={getContextMenuItems}
      />
    </TableWrapper>
  );
};
