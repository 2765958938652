import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { BookingCardLandscape, BookingCardPortrait } from "components/Pdfs";

import { sortPenByOrder } from "lib/auctionPens";
import { BookingCardVersions, getPensByAgentId } from "lib/pdf";
import { saleIdOnly, saleIdRoundIdOnly } from "lib/sale";

import {
  getAuctionPensWithSaleLotsBySaleAndRound,
  currentSaleSelector,
  getOrganizationName,
  currentRoundSelector,
  selectDeploymentPlacardLogoUrlByDeploymentId,
  getCurrentSale,
  getSalePlacardLogo,
} from "selectors";

const mapStateToProps = (state, props) => ({
  auctionPens: props.match.params?.agentId
    ? getPensByAgentId(
        sortPenByOrder(
          getAuctionPensWithSaleLotsBySaleAndRound(
            state,
            saleIdRoundIdOnly(props),
          ),
        ),
        props.match.params?.agentId,
      )
    : getAuctionPensWithSaleLotsBySaleAndRound(state, saleIdRoundIdOnly(props)),
  sale: currentSaleSelector(state, saleIdOnly(props)),
  agencyName: getOrganizationName(state),
  round: currentRoundSelector(state, saleIdRoundIdOnly(props)),
  extraSpacing:
    props.match.path.includes("bookingcardspacedlandscape") ||
    (props.match.path.includes("bookingcardspacedportrait") &&
      props.match.params.version !== BookingCardVersions.SignOffCompact),
  version: props.match.params.version,
  deploymentLogo:
    selectDeploymentPlacardLogoUrlByDeploymentId(state)[
      getCurrentSale(state).deployment_sales?.filter(
        deploymentSale =>
          deploymentSale.livestock_agency_id.toString() ===
          props.match.params?.agentId,
      )[0]?.deployment_id
    ],
  saleLogo: getSalePlacardLogo(state),
});

const mapDispatchToProps = {};

const BookingCard = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
);
export const LandScape = BookingCard(BookingCardLandscape);
export const Portrait = BookingCard(BookingCardPortrait);
