import Commands from "@agrinous/epson-escpos";
import { commandCombiner } from "@agrinous/epson-escpos/lib/utils";

import { agriNousLogo } from "./agriNousLogo";

const {
  initialisePrinter,
  printGraphics,
  printAndFeedLines,
  resetLineSpacing,
  setBoldMode,
  setFontMode,
  setGraphicsDataMonochrome,
  setJustification,
  setUnderscoreMode,
  lineFeed,
  setTabstops,
  soundBuzzer,
  text,
  setSmoothingMode,
  BOLD_MODE,
  FONT_MODE,
  JUSTIFICATION_MODE,
  SOUND_MODE,
  UNDERSCORE_MODE,
  SMOOTHING_MODE,
} = Commands;

export function fieldAndValuePair(title, value) {
  return commandCombiner([
    setFontMode(FONT_MODE.FONT_B),
    setBoldMode(BOLD_MODE.BOLD_ON),
    text(`${title}:\t`),
    setBoldMode(BOLD_MODE.BOLD_OFF),
    setFontMode(FONT_MODE.FONT_A),
    text(value),
  ]);
}

export function hr() {
  const BITS_PER_COLUMN = 12;
  const TEXT_COLUMNS_COUNT = 48;
  const bitmap = [[]];
  for (let i = 0; i < TEXT_COLUMNS_COUNT * BITS_PER_COLUMN; i += 1) {
    bitmap[0].push(1);
  }
  return commandCombiner([
    setGraphicsDataMonochrome("00", bitmap),
    printGraphics("00", 1, 1),
  ]);
}

export function cutMark() {
  return commandCombiner([
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_CENTER),
    setUnderscoreMode(UNDERSCORE_MODE.UNDERSCORE_DOUBLE),
    text("                                                "),
    text("                    CUT HERE                    "),

    printAndFeedLines(3),
    resetLineSpacing(),

    soundBuzzer(SOUND_MODE.SOUND_1320HZ_200MS_ON_OFF_X2, 1, 10),
  ]);
}

export function receiptHeader(title, saleyardName, saleId, stockAgent, user) {
  const printTime = new Date();
  const formattedPrintTime = printTime.toLocaleString("en-au", {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  let commandList = [
    initialisePrinter(),
    setGraphicsDataMonochrome("AA", agriNousLogo),
    setSmoothingMode(SMOOTHING_MODE.SMOOTHING_ON),
    printGraphics("AA", 1, 1),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_CENTER),
    setBoldMode(BOLD_MODE.BOLD_ON),
    text(title),
    printAndFeedLines(2),
    setBoldMode(BOLD_MODE.BOLD_OFF),
  ];
  commandList = commandList.concat([
    fieldAndValuePair("Printed", formattedPrintTime),
    printAndFeedLines(3),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_LEFT),
    setTabstops([10]),
    fieldAndValuePair("Sale", `${saleyardName} (${saleId.toString()})`),
    printAndFeedLines(2),
    fieldAndValuePair("Stock Agent", stockAgent),
    lineFeed(),
    fieldAndValuePair("User", user),
    printAndFeedLines(2),
  ]);
  return commandCombiner(commandList);
}
