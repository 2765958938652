import { ExportSites } from "constants/exportSites";

export const isAuctionPlusCompliantBySaleLotComparator =
  isAuctionsPlusCompliant =>
  ([ignored, isAuctionsPlusCompliantBySaleLot]) =>
    isAuctionsPlusCompliantBySaleLot[ExportSites.AUCTIONS_PLUS] ===
    isAuctionsPlusCompliant[0];

export const isStockLiveCompliantBySaleLotComparator =
  isStockLiveCompliant =>
  ([ignored, isStockLiveCompliantBySaleLot]) =>
    isStockLiveCompliantBySaleLot[ExportSites.STOCK_LIVE] ===
    isStockLiveCompliant[0];
