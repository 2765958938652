import {
  TLABPLC_DRAFTING_DECISION,
  TLABPLC_DRAFTING_QUERY,
  TLABPLC_DRAFTING_RESET,
  TLABPLC_HANDSHAKE,
} from "constants/actionTypes";

export function receiveTlabAutoDraftPlcHandshakeRequest(deviceId) {
  return {
    type: TLABPLC_HANDSHAKE.REQUEST,
    deviceId,
  };
}

export function receiveTlabAutoDraftPlcDraftingReset(deviceId) {
  return {
    type: TLABPLC_DRAFTING_RESET.SUCCESS,
    deviceId,
  };
}

export function receiveTlabAutoDraftPlcDraftingQuery(deviceId) {
  return {
    type: TLABPLC_DRAFTING_QUERY.REQUEST,
    deviceId,
  };
}

export function sendTlabAutoDraftPlcDraftingDecision(deviceId, decision) {
  return {
    type: TLABPLC_DRAFTING_DECISION.REQUEST,
    deviceId,
    decision,
  };
}
