import React from "react";

import isArray from "lodash/isArray";
import { useSelector } from "react-redux";

import { Species } from "constants/species";

import { currentSaleSelector } from "selectors";

export const ForSpecies = React.memo(({ children, species }) => {
  const speciesId = useSelector(state => currentSaleSelector(state).species_id);

  let visible = false;
  if (isArray(species)) {
    visible = species.includes(speciesId);
  } else if (typeof species === "number") {
    visible = species === speciesId;
  }
  return visible ? children : null;
});

export const ForSheep = ({ selectedSaleType, children }) => (
  <ForSpecies selectedSaleType={selectedSaleType} species={[Species.SHEEP]}>
    {children}
  </ForSpecies>
);

export const ForCattle = ({ selectedSaleType, children }) => (
  <ForSpecies selectedSaleType={selectedSaleType} species={[Species.CATTLE]}>
    {children}
  </ForSpecies>
);

export default ForSpecies;
