import React, { memo, useState } from "react";

import { faShare } from "@fortawesome/free-solid-svg-icons";
import {
  faAddressCard,
  faAngleDown,
  faAngleUp,
  faChartColumn,
  faCogs,
  faFileChartLine,
  faGavel,
  faHeadset,
  faHouse,
  faInbox,
  faPlus,
  faWallet,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { userTypes } from "constants/users";

import {
  getAnalyticsRoute,
  getDashboardRoute,
  getInboxRoute,
  getManageBusinessRoute,
  getMoneyTableRoute,
  getReportJobList,
  getSaleyardAuctionRoute,
  getSettingsRoute,
  openConsignToOtherSaleModal,
  openHelpAndSupport,
  openSaleModal,
} from "lib/navigation";

import {
  getCurrentSaleyard,
  getHasEnteredNlisCreds,
  getIsLivestockAgent,
  getIsScaleOperator,
  getSaleyards,
  getCurrentUser,
  selectCanSeeAgencyGroups,
  selectCanSeeOtherDeployments,
  selectIsUserOfType,
} from "selectors";

import { useIsMobile } from "hooks";
import {
  useHasDeploymentPermission,
  useHasEmailListPermission,
  useHasMultiSaleReportPermission,
  useHasSaleyardPermission,
} from "hooks/useHasPermission";

import SidebarButton from "./SidebarButton";
import {
  SideBarLabel,
  SidebarListItem,
  SidebarNestedListItem,
} from "./SidebarListItem";

const SideBarNestedIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
`;

function DefaultSidebarContentComponent(props) {
  const { closeSidebar } = props;
  const history = useHistory();

  // Try and pull the current saleyard out
  const currentSaleyard = useSelector(getCurrentSaleyard);
  // Also pull out all of our saleyards
  const saleyards = useSelector(getSaleyards);
  // Set the saleyard to be the current name if it exits
  // If it doesn't exist, but we do have other saleyards belonging to us then just use the first one for now, until we navigate into a sale.
  // This allows tabs like businesses/contacts/settings to work when on urls without a saleyard like the dashboard.
  const saleyard = (currentSaleyard || Object.values(saleyards)?.[0])?.name;

  const [isAddSectionExpanded, setIsAddSectionExpanded] = useState(false);

  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const areNlisCredentialsRequired = useSelector(getIsScaleOperator);
  const areNlisCredentialsProvided = useSelector(getHasEnteredNlisCreds);
  const canSeeOtherDeployments = useSelector(selectCanSeeOtherDeployments);
  const canSeeAgencyGroups = useSelector(selectCanSeeAgencyGroups);

  const canViewEnvds = useHasSaleyardPermission(
    SaleyardPermissions.featureEnvds,
  );

  const canSeeMultiSaleReports = useHasMultiSaleReportPermission();
  const canSeeBilling = useHasDeploymentPermission(
    DeploymentPermissions.featureBilling,
  );

  const canViewEmail = useHasEmailListPermission();

  const showInbox = canViewEnvds || canViewEmail;

  const isMobile = useIsMobile();

  const nlisUpdateNeeded =
    areNlisCredentialsRequired && !areNlisCredentialsProvided;
  const allowSales = !nlisUpdateNeeded;

  const isNLISAwareUser = useSelector(
    selectIsUserOfType([
      userTypes.SCALE_OPERATOR,
      userTypes.SALEYARD_ADMIN,
      userTypes.LIVESTOCK_AGENT,
    ]),
  );

  const user = useSelector(getCurrentUser);

  const allowAddSale = !nlisUpdateNeeded && isNLISAwareUser;

  const allowSetNLISCredentials = isNLISAwareUser;

  const allowConsignToOtherSale =
    canSeeOtherDeployments && canSeeAgencyGroups && isLivestockAgent;

  const canAccessAnalytics = user.allow_analytics;

  const onCloseSidebar = () => {
    setIsAddSectionExpanded(false);
    closeSidebar();
  };

  const handleClick = () => {
    setIsAddSectionExpanded(!isAddSectionExpanded);
  };

  const goToBusinesses = () => {
    onCloseSidebar();
    history.push(getManageBusinessRoute(saleyard));
  };

  const goToSales = () => {
    onCloseSidebar();
    history.push(getSaleyardAuctionRoute(saleyard));
  };

  const goToDashboard = () => {
    onCloseSidebar();
    history.push(getDashboardRoute());
  };

  const goToProfile = () => {
    onCloseSidebar();
    history.push(getSettingsRoute());
  };

  const goToInbox = () => {
    onCloseSidebar();
    history.push(getInboxRoute());
  };

  const onClickAddSale = () => {
    onCloseSidebar();
    openSaleModal();
  };

  const onClickConsignToOtherSale = () => {
    onCloseSidebar();
    openConsignToOtherSaleModal();
  };

  const goToMoneyTable = () => {
    onCloseSidebar();
    history.push(getMoneyTableRoute());
  };

  const goToReportJobList = () => {
    onCloseSidebar();
    history.push(getReportJobList());
  };

  const goToAnalytics = () => {
    onCloseSidebar();
    history.push(getAnalyticsRoute());
  };

  return (
    <>
      <SidebarListItem
        onClick={goToDashboard}
        icon={faHouse}
        isMobile={isMobile}
        label="Dashboard"
      />
      {showInbox && (
        <SidebarListItem
          onClick={goToInbox}
          icon={faInbox}
          isMobile={isMobile}
          label="Inbox"
        />
      )}

      {allowSales && (
        <SidebarListItem
          onClick={goToSales}
          icon={faGavel}
          isMobile={isMobile}
          label="Sales"
        />
      )}

      {allowAddSale && (
        <>
          <ListItem button onClick={handleClick} data-tour="Add">
            <SidebarButton icon={faPlus} isMobile={isMobile} />
            <SideBarLabel primary="Add" />
            <SideBarNestedIcon
              icon={isAddSectionExpanded ? faAngleUp : faAngleDown}
            />
          </ListItem>
          <Collapse in={isAddSectionExpanded} timeout="auto">
            <List component="div" disablePadding>
              <SidebarNestedListItem
                button
                onClick={onClickAddSale}
                data-tour="Add Sale"
              >
                <SideBarLabel primary="Add Sale" />
              </SidebarNestedListItem>
            </List>
          </Collapse>
        </>
      )}

      {allowSetNLISCredentials && saleyard && (
        <>
          {allowConsignToOtherSale && (
            <SidebarListItem
              onClick={onClickConsignToOtherSale}
              icon={faShare}
              isMobile={isMobile}
              label="Consign To"
            />
          )}
          <SidebarListItem
            onClick={goToBusinesses}
            icon={faAddressCard}
            isMobile={isMobile}
            label="Businesses"
          />
          {canSeeMultiSaleReports && (
            <SidebarListItem
              onClick={goToReportJobList}
              icon={faFileChartLine}
              isMobile={isMobile}
              label="Reports"
            />
          )}
          {canAccessAnalytics && (
            <SidebarListItem
              onClick={goToAnalytics}
              icon={faChartColumn}
              isMobile={isMobile}
              label="Analytics"
            />
          )}
          {canSeeBilling && (
            <SidebarListItem
              onClick={goToMoneyTable}
              icon={faWallet}
              isMobile={isMobile}
              label="Money"
            />
          )}

          <SidebarListItem
            onClick={goToProfile}
            icon={faCogs}
            isMobile={isMobile}
            label="Settings"
          />
        </>
      )}

      <SidebarListItem
        onClick={openHelpAndSupport}
        icon={faHeadset}
        isMobile={isMobile}
        label="Help &amp; Support"
      />
    </>
  );
}

export default memo(DefaultSidebarContentComponent);
