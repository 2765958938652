import React from "react";

import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { SlimButton } from "components/Button";
import { Button } from "components/Form";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { pluralize } from "lib/pluralize";

import { useToggle } from "hooks";

import { NLISCorrectionGrid } from "./NLISCorrectionsGrid";

export const RollBackAlert = ({
  correctableTransfers,
  handleCorrection,
  nlisFileType,
  disableAction,
}) => {
  const [open, toggleOpen] = useToggle();

  const numCorrectable = correctableTransfers?.length;

  const confirmCorrection = () => {
    handleCorrection();
    toggleOpen();
  };

  const tooltipText = disableAction
    ? "Processing data, please wait"
    : "Submit rollback request";
  const CorrectionAction = (
    <Tooltip title={tooltipText}>
      <SlimButton onClick={toggleOpen} disabled={disableAction}>
        Rollback Transfers
      </SlimButton>
    </Tooltip>
  );

  if (numCorrectable === 0) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Alert severity="warning" action={CorrectionAction}>
        We have detected changes made affecting {numCorrectable}{" "}
        {pluralize("transfer", numCorrectable)} since the last NLIS Transaction.
      </Alert>

      <Dialog open={open} onClose={toggleOpen} maxWidth="md" fullWidth>
        <DialogTitle>
          Are you sure you wish to rollback the following transfers?
        </DialogTitle>
        <DialogContent>
          <CollapseTableWrapper>
            <NLISCorrectionGrid
              rowData={correctableTransfers}
              nlisFileType={nlisFileType}
            />
          </CollapseTableWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen}>Cancel</Button>
          <Button onClick={confirmCorrection}>Submit</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
