import React, { memo } from "react";

import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Divider, Grid, styled } from "@material-ui/core";
import Big from "big.js";
import { useField, useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { SubtleBadge } from "components/Badge";
import {
  AlternateFieldTextComponent,
  getAlternateColor,
} from "components/BusinessForm/lib";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Switch, withNamespace } from "components/Form/FormikControls";
import { InterestSettingForm } from "components/Form/FormikControls/Interest";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";

import { DeploymentPermissions } from "constants/permissions";

import { getGSTStatusText } from "lib/businesses";

import {
  getActiveLivestockAgentDeployment,
  getBusinessById,
  getIsSaleyardAdmin,
} from "selectors";

import { useHasDeploymentPermission } from "hooks";

import AbrDetailsForm from "./AbrDetailsForm";
import BankingDetailsForm from "./BankingDetailsForm";
import { DefaultVendorSplits } from "./DefaultVendorSplits";

const Header = ({ namespace: ns }) => {
  const [{ value: isGstRegistered }] = useField(
    withNamespace(ns, "isGSTRegistered"),
  );
  const [{ value: abrLastChecked }] = useField(
    withNamespace(ns, "abrLastChecked"),
  );

  const [{ value: shouldChargeGSTOverride }] = useField(
    withNamespace(ns, "shouldChargeGSTOverride"),
  );

  return (
    <Row>
      <SubtleBadge>Finance & Accounting</SubtleBadge>
      <CollapseLabel>
        GST:{" "}
        {getGSTStatusText(
          isGstRegistered,
          abrLastChecked,
          shouldChargeGSTOverride,
        )}
      </CollapseLabel>
    </Row>
  );
};

const StyledFaIcon = styled(FaIcon)`
  font-size: 16px;
`;

const TooltipChildren = ({ alternatives, field, values }) => {
  const color = getAlternateColor(alternatives, values, field);

  return <StyledFaIcon icon={faLink} color={color} />;
};

const AlternateToolTip = ({ alternatives, field, values }) => (
  <Tooltip title={AlternateFieldTextComponent(alternatives, field)} clickable>
    <TooltipChildren
      alternatives={alternatives}
      field={field}
      values={values}
    />
  </Tooltip>
);

const FinanceAndAccountingComponent = ({
  namespace: ns,
  isOpen,
  onToggle,
  abn,
  businessId,
  abrLastChecked,
}) => {
  const alternatives =
    useSelector(state => getBusinessById(businessId)(state)?.alternatives) ||
    [];

  const formikProps = useFormikContext();
  const deployment = useSelector(getActiveLivestockAgentDeployment) || {};
  const saleyardAdmin = useSelector(getIsSaleyardAdmin);

  const hasVendorSplitFeature = useHasDeploymentPermission(
    DeploymentPermissions.featurePercentageVendorSplits,
  );
  const hasInterestFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureInterest,
  );

  const {
    defaultDebtorInsurance,
    defaultTransitInsurance,
    deploymentSettings,
  } = deployment;

  const showBpay = deploymentSettings?.bpayBillerCode;
  const interestEnabled =
    hasInterestFeature && deploymentSettings?.enableInterest;

  const getInsuranceLabel = (insuranceName, insuranceRate) => {
    const baseLabel = `${insuranceName} Insurance`;
    if (!insuranceRate || saleyardAdmin) {
      return baseLabel;
    }
    const displayedRate = Big(insuranceRate).mul(100).toLocaleString();
    return `${baseLabel} (${displayedRate}%)`;
  };

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<Header namespace={ns} hasAbn={!!abn} />}
      dataTour="financeAccountingCollapse"
    >
      <Grid item xs={6} md={4}>
        <Row>
          <AlternateToolTip
            alternatives={alternatives}
            field="hasTransitInsurance"
            values={formikProps.values}
          />{" "}
          <Switch
            label={getInsuranceLabel("Transit", defaultTransitInsurance)}
            name={withNamespace(ns, "hasTransitInsurance")}
          />
        </Row>
      </Grid>
      <Grid item xs={6} md={4}>
        <Row>
          <AlternateToolTip
            alternatives={alternatives}
            field="hasDebtorInsurance"
            values={formikProps.values}
          />{" "}
          <Switch
            label={getInsuranceLabel("Debtor", defaultDebtorInsurance)}
            name={withNamespace(ns, "hasDebtorInsurance")}
          />
        </Row>
      </Grid>
      <Grid item xs={6} md={4}>
        <Switch
          label="Hobby Farmer"
          name={withNamespace(ns, "isHobbyFarmer")}
          tooltip="When set this business will be flagged to not charge GST by default."
        />
      </Grid>
      <BankingDetailsForm
        namespace={withNamespace(ns, "bankingDetails")}
        showBpay={showBpay}
      />
      <AbrDetailsForm
        namespace={ns}
        businessId={businessId}
        abn={abn}
        abrLastChecked={abrLastChecked}
      />
      {hasVendorSplitFeature && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <DefaultVendorSplits businessId={businessId} />
        </>
      )}
      {interestEnabled && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <InterestSettingForm
            namespace={ns}
            toolip="Enter interest settings specific to this business. These will override the defaults set for your agency."
            useBusinessCheckbox
          />
        </>
      )}
    </FormCollapse>
  );
};

export default memo(FinanceAndAccountingComponent);
