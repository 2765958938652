export enum ReportJobStatus {
  INITIALIZING = "INITIALIZING",
  QUEUED = "QUEUED",
  STARTED = "STARTED",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export const ReportJobStatusColorMap = {
  [ReportJobStatus.INITIALIZING]: "pending",
  [ReportJobStatus.QUEUED]: "approved",
  [ReportJobStatus.STARTED]: "activeColour",
  [ReportJobStatus.SUCCESS]: "successGreen",
  [ReportJobStatus.ERROR]: "errorRed",
};
