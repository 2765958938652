import { dateTimeStringToDateTime, formatDateYear } from "lib/timeFormats";

export function yearFormatter(props) {
  const { value, data } = props;
  if (!data) {
    return null;
  }

  if (value) {
    return formatDateYear(dateTimeStringToDateTime(value));
  } else {
    return "-";
  }
}
