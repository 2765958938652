import { ConsignmentExceptions, SaleLotException } from "./sale";

export const NLISCredentialType = {
  AGENT: "AGENT",
  SALEYARD: "SALEYARD",
  SCALE_OPERATOR: "SCALE_OPERATOR",
};

// NLISFile.status
export const TRANSFER_STATUS = {
  ACCEPTED: "Accepted",
  FAILED: "Error",
  PENDING_ROLLBACK: "Pending Rollback",
  PENDING: "Pending",
  ROLLED_BACK: "Rolled Back",
  SUCCESS: "Success",
  SYNCING: "Syncing", // Used on the front end only.
  PREPARING_TRANSFER: "Preparing Transfer", // Used on the front end only.
  UNSUBMITTED: "UnSubmitted",
};

// values are the eid status
export const EID_TRANSFER_STATUS = {
  SUCCESS: "Success",
  WARNING: "Warning",
  ERROR: "Failure",
  PENDING: "Pending",
  ROLLED_BACK: "Rolled Back",
};

export const logoStatus = {
  [EID_TRANSFER_STATUS.SUCCESS]: "success",
  [EID_TRANSFER_STATUS.WARNING]: "warning",
  [EID_TRANSFER_STATUS.ERROR]: "error",
  [EID_TRANSFER_STATUS.PENDING]: "pending",
  [EID_TRANSFER_STATUS.ROLLED_BACK]: "rolled_back",
};

export const SCAN_NLIS_STATUS = {
  UNSUBMITTED: "UnSubmitted",
  GOOD: "Good",
  CORRECTED_BUT_NEEDS_CORRECTING: "Corrected but Needs Correcting",
  NEEDS_CORRECTING: "Needs Correcting",
  ERROR: "Error",
  DECEASED: "Deceased",
  PENDING: "Pending",
  BLOCKED: "Blocked",
};

// String returned (spacing is relevant...) from NLIS for animal ERP status
export const CLEAR_NO_TEST = "CLEAR NO TEST;LPA  A  ";
export const CLEAR_NO_TEST_LPA1 = "CLEAR NO TEST;LPA  A1 ";
export const NLIS_NOT_FOUND = "NLIS_NOT_FOUND";
export const CLEAR_NO_TEST_ONLY = "CLEAR NO TEST";
export const LPA_N = "CLEAR NO TEST;LPA  N  ";
export const NLIS_PENDING = "Pending";
export const DISBANDED_LPA_N = "DISBANDED;LPA  N";

export const LPA_ACCREDITED_PROGRAMS = [CLEAR_NO_TEST, CLEAR_NO_TEST_LPA1];

export const GOOD_STATUSES = [
  CLEAR_NO_TEST,
  CLEAR_NO_TEST_LPA1,
  NLIS_NOT_FOUND,
  CLEAR_NO_TEST_ONLY,
];

const INVALID_TRANSFER_MESSAGE = "Unknown result.";

export function containsOnlyGoodStatuses(erpMessages) {
  return erpMessages.every(elem => GOOD_STATUSES.includes(elem));
}

export const NlisProgram = {
  AQ: "AQ",
  ENDO: "ENDO",
  EW: "EW",
  LPA: "LPA",
  NARM: "NARM",
  OC: "OC",
  PI: "PI",
  PIC: "PIC",
};

export const NlisProgramName = {
  [NlisProgram.AQ]: "Anthrax Warning",
  [NlisProgram.ENDO]: "Endosulfan Warning",
  [NlisProgram.EW]: "Property Early Warning",
  [NlisProgram.NARM]: "NRS Warning",
  [NlisProgram.OC]: "OC Warning",
  [NlisProgram.PI]: "PI Warning",
  [NlisProgram.PIC]: "PIC Warning",
};

export const WARNING_PROGRAMS = [
  NlisProgram.AQ,
  NlisProgram.ENDO,
  NlisProgram.EW,
  NlisProgram.NARM,
  NlisProgram.OC,
  NlisProgram.PI,
  NlisProgram.PIC,
];

const TRANSFER_MESSAGES = {
  [-9]: "You are not authorised to access this information.",
  [null]: "Please notify the NLIS helpdesk immediately.",
  0: "All information in the file has been entered on the database.",
  1: "This device is not registered on the database. This information has not been recorded.",
  2: "This NLIS ID is not registered on the database. This information has not been recorded.",
  3: "This animal is recorded as deceased. This information has not been recorded on the database.",
  4: "The From PIC you have submitted is invalid. This transaction has not been processed.",
  5: "The To PIC you have submitted is invalid. This transaction has not been processed.",
  6: "The FROM or TO PIC is not registered on the database. This transfer has been recorded.",
  7: "This device was not registered to the PIC you transferred FROM. This device has been transferred and has lost its lifetime traceability status.",
  8: "This device was not registered to the PIC you transferred FROM. This device has been transferred and has lost its lifetime traceability status.",
  9: "The From and To PICs you have submitted are the same. This transaction has not been processed.",
  10: "The date is not in the correct format. The format should be DD/MM/YYYY. The information has not been recorded on the database.",
  11: "This device is not EU eligible and it has been transferred to an EU PIC.",
  12: "This device has been transferred to an EU PIC without using an EU NVD. This device is no longer EU eligible.",
  13: "This device is currently registered to a Saleyard. The device has not been transferred.",
  14: "You are not authorised to transfer devices for the PICs you are transferring TO or FROM. This device has not been transferred.",
  15: "This device was transferred. If a Waybill was used, please ignore this message. If an EUVD was used then please contact DAFF as you have used an EUVD for a non-EU animal",
  16: "This account is not authorized to transfer devices on behalf of the FROM PIC or the TO PIC.",
  17: "This device is already registered to the saleyard.",
  19: "This device has not been transferred into the saleyard. Transfer out cannot occur until the animal is transferred into the saleyard. The device has not been transferred.",
  20: "The NVD in the Saleyard Out file is different to the Saleyard In file for this device. This device has not been transferred.",
  21: "The Saleyard ID you submitted is not authorised to your account. This device has not been transferred.",
  23: "This NLIS ID is already registered on the database. This device has not been uploaded.",
  24: "The following RFID(s) you have submitted are already registered on the database. These device(s) have not been uploaded.",
  26: "This body number is outside the acceptable range of 0 to 9999. This line has not been recorded on the database.",
  29: "Carcase feedback has already been uploaded for this device. Modifying carcase feedback requires a change to the record. This record has not been updated on the database.",
  30: "This PIC is not valid. This information has not been recorded on the database.",
  37: "This device has a status of S  Stolen animal. This device has been transferred. Please advise police for further action.",
  38: "This device was registered to another saleyard. This device has been transferred and has lost its lifetime traceability status.",
  50: "The movement date you have submitted is in the future. This transaction has not been processed.",
  51: "This device has a status of L1  Lost or stolen device. This device has been transferred.",
  52: "This device has a status of R1  Replaced. This device has not been transferred.",
  53: "This device has a status of D1  Damaged. This device has been transferred.",
  54: "This device has a status of S1  Lost animal. This device has been transferred.",
  55: "This device has a status of X1. This device has been transferred.",
  56: "A device which is not EU eligible has been transferred to an EU PIC.",
  58: "This device has been transferred from a non EU saleyard to an EU PIC. This device is not EU eligible.",
  59: "This device was registered to a saleyard. This device has been transferred and has lost its lifetime traceability status.",
  62: "This device is not registered to the PIC you are transferring from. This device has been transferred and has lost its lifetime traceability status.",
  66: "Johne's Disease Vaccines are issued to following PICs. Status JDP is applied.",
  93: "This device already has this status applied. This device status has not been changed on the database.",
  94: "The status code uploaded is not associated with the program code. This information has not been updated on the database.",
  95: "The issue date you have submitted is invalid. These devices have not been uploaded.",
  98: "This date is prior to when the system commenced (before 01/01/1999). This device has not been transferred.",
  99: "The establishment number is not authorised for this account. This information has not been recorded on the database.",
  100: "The device you are replacing does not exist on the database. This replacement has not been recorded.",
  101: "The replacement device does not exist on the database. This replacement has not been recorded.",
  102: "The replacement device number is the same as the device being replaced. This replacement has not been recorded on the database.",
  103: "This device is recorded as deceased on the database. This replacement has not been recorded.",
  104: "The old device and the new device are not registered to the same PIC on the database. This replacement has not been recorded.",
  105: "This device has a Lost or Stolen status and an attempt was made to replace the device. This replacement has not been recorded on the database.",
  106: "This device cannot be used as a replacement as it has been transferred or it has an extended status. This replacement has not been recorded on the database.",
  107: "This device is already registered in the database as a replaced device. This replacement has not been recorded.",
  108: 'The 12th character of an NLIS ID cannot be "I" or "O". This NLIS ID is invalid. This device has not been uploaded to the database.',
  109: "The database has not been able to process your file due to a database system error. This information has not been recorded on the database.",
  110: "The old TAG has a status that prevents it from being replaced. This replacement has not been recorded.",
  111: "This device is not replacement device. This transfer can not be rolled back.",
  114: "The PIC in the NLIS ID is not the same as the PIC it is being uploaded to. This device has not been uploaded to the database.",
  115: "The RFID manufacturer code you have submitted is invalid. These device(s) have not been uploaded.",
  116: "This body number is outside the acceptable range of 0 to 9999. This information has not been recorded on the database.",
  117: "The body number and date provided are invalid. This information has not been recorded on the database.",
  118: "This body number has already been used on this date. This information has not been recorded on the database.",
  119: "Carcase feedback has already been uploaded for this device. This record has not been updated on the database.",
  120: "The database has not been able to process your file due to a database system error. This information has not been recorded on the database.",
  121: "This account is not authorised to transfer devices to the AAAAAAAA PIC. This device has not been transferred.",
  122: "The authoriser first name or last name has not been provided. The movement has not been recorded on the database.",
  123: "This account is not authorised to record third party non written authority movements. This information has not been updated on the database.",
  124: "This PIC is not valid. This record has not been uploaded to the database.",
  125: "The PIC status has been set to blank as it is not Active, Inactive or Disbanded.",
  126: "This account is not authorised to query information relating to this PIC.",
  127: "This account is not authorised to change this status. This status has not been updated on the database.",
  128: "This NVD has already been used in a mob based transfer.",
  129: "The mob based in Transfer ID does not exist. This transfer has not been recorded on the database.",
  130: "This field must be completed. This record has not been updated on the database.",
  131: "Information should not be entered in this field. This record has not been updated on the database.",
  132: "The database has not been able to process this record due to a system error. This record has not been updated on the database.",
  133: "This body number has already been used for this date (the kill key already exists). This record has not been uploaded to the database.",
  134: "The body number and kill date do not exist on the database (the kill key does not exist). This record has not been modified on the database.",
  135: "The database has not been able to process this record due to a system error. This record has not been updated on the database.",
  136: "This account is not authorised to use this Establishment Number. This information has not been updated on the database.",
  137: "The Tag Type provided is invalid. This record has not been uploaded to the database.",
  138: "The Tag Type provided is invalid. This record has not been updated on the database.",
  139: "This device has already been recorded as deceased on the database. This record has not been updated on the database.",
  140: "The NLIS ID associated with the RFID in the kill file is different to the RFID it is associated with on the database. This record has not been updated on the database.",
  141: "The RFID associated with the NLIS ID in the kill file is different to the NLIS ID it is associated with on the database. This record has not been updated on the database.",
  142: "The database has not been able to process this record due to a system error. This record has not been updated on the database.",
  143: "An NLIS ID has been submitted. This transaction only allows RFIDs to be transferred. This device has not been transferred.",
  144: "Inactive transaction (Deceased cattle transfer)",
  145: "Inactive transaction (Deceased cattle transfer and Rollback transfer)",
  146: "Inactive transaction (Deceased cattle transfer)",
  147: "Inactive transaction (Deceased cattle transfer)",
  148: "Inactive transaction (Deceased cattle transfer)",
  149: "Inactive transaction (Deceased cattle transfer)",
  150: "Inactive transaction (Deceased cattle transfer)",
  151: "Inactive transaction (Deceased cattle transfer)",
  152: "This device was slaughtered more than 28 days after the saleyard out transfer. This device has not been transferred.",
  153: "This animal has been slaughtered and the Destination PIC that it was transferred to differs from the PIC where the animal was killed.",
  154: "This animal has been slaughtered. The animal cannot be transferred from or to the DECEASED PIC. This device has not been transferred.",
  155: "This device is recorded as deceased. This device has been transferred.",
  156: "The database has not been able to process your file due to a database system error. This information has not been recorded on the database.",
  157: "This account is not authorised to record this transaction type. This information has not been updated on the database.",
  158: "This device was not transferred by this Upload ID. This transfer cannot be rolled back.",
  159: "This device is not registered to this Saleyard, it is registered to another saleyard. This device has not been transferred.",
  160: "This transaction is not the last recorded movement of this device. You must rollback subsequent movements before this transaction can be rolled back. This transaction has not been rolled back.",
  161: "The selected transaction type differs from the transaction type of the upload you are attempting to roll back. This transaction has not been rolled back.",
  162: "The NLIS ID and RFID do not match an individual device on the database. This information has not been updated on the database.",
  163: "This device cannot be deleted as it has a transaction history.",
  164: "At least one of PIC Name, PIC Address Line 1, PIC Address Line 2 or PIC Address Line 3 should be provided. This PIC has been updated on the database.",
  165: "This device is not registered to the PIC you are transferring from. This device has not been transferred.",
  166: "Devices cannot be transferred FROM or TO an EU accredited PIC using this transaction type. This transfer has not been recorded on the database.",
  167: "This device has an extended status and cannot be transferred using TAGTRAN.",
  168: "The FROM or TO PIC is invalid. This device has not been transferred.",
  169: "An animal with a %%s status has been slaughtered",
  170: "One or more PICs with a status of %%s have been used in a transfer on the NLIS database.",
  171: "A device with a status of %%s has been transferred on the NLIS database",
  172: "This status has already been assigned to this property. This information will be updated on the database.",
  175: "You are not authorised to change this status. This information has not been updated on the database.",
  176: "This PIC does not exist on the central PIC register. This information has been updated on the database.",
  177: "This PIC has an Inactive status on the central PIC register.",
  178: "This PIC has a Disbanded status on the central PIC register.",
  179: "This status type cannot be assigned a status duration. This information has not been updated on the database.",
  180: "The status expiration date is not a future date. This information has not been updated on the database.",
  181: "This status has already been applied to this PIC. This information has not been updated on the database.",
  182: "This Status is already set on this PIC with a different percentage. This information has been updated on the database.",
  183: "This account is only authorized to transfer devices to the DECEASED PIC. This transfer has not been recorded on the database.",
  184: "This account type is not authorised to submit transactions involving the EEEEEEEE PIC. This PIC can only be used for Live Export transfers. This information has not been updated on the database.",
  185: "The status date provided is in the future. This status has not been updated on the database.",
  186: "This device was not registered to the PIC you were transferring FROM, it was registered to an EU PIC. This device has been transferred and has lost its lifetime traceability and EU status.",
  187: "This device is not registered on the database.",
  188: "This device is recorded as deceased on the database. This EU status change has not been recorded on the database.",
  189: "This device is already recorded as non EU. This EU status change has not been recorded on the database.",
  190: "This device has a status of Lost, Stolen, Damaged or Replaced. This EU status change has not been recorded on the database.",
  191: "This account type is not authorised to change the EU status to non EU. The EU status change has not been recorded on the database.",
  192: "This device or PIC does not exist on the database.",
  193: "This PIC is not valid.",
  194: "This device is registered to an abattoir PIC. Transfers from an abattoir must use the abattoir PIC as the Source. This device has not been transferred.",
  195: "This animal has been slaughtered and the Destination PIC that you are transferring to differs from the PIC where the animal was killed. This animal has not been transferred.",
  196: "The kill upload for this device was submitted by a different abattoir to the abattoir that it is currently registered to. This kill record has been updated on the database.",
  197: "This device has had an extended status assigned to it. This device cannot be deleted.",
  198: "PIC register contact details (PIC address, town, postcode and state) should be provided if the PIC status is not Inactive.",
  199: "This PIC has a Blocked status on the central PIC register. This information has not been updated on the database.",
  200: "This account is not authorised to run this report.",
  201: "A breeder device should not be used to replace a post breeder device. The replacement has been recorded on the database.",
  202: "This device is already assigned a status within the Program Code that was submitted. To change the status, remove the current status and then resubmit the new status code.",
  203: "This status has already been assigned or removed from this device.",
  204: "The New device has been transferred into the Saleyard and cannot be used to replace the Old device. The replacement has not been recorded on the database.",
  205: "The New device is not registered to the Saleyard PIC (the PIC that is linked to the Saleyard account). The replacement has not been recorded on the database.",
  206: "This device is registered to a Saleyard. You are not authorized to replace this device. The replacement has not been recorded on the database.",
  207: "The New device has already been used as a replacement device. The replacement has not been recorded on the database.",
  208: "The New device has already been replaced and cannot be used as a replacement device. The replacement has not been recorded on the database.",
  209: "This device has been used to replace another device on the database. The transfer date submitted is earlier than the replacement date. The transfer has not been recorded.",
  210: "The transfer date is earlier than the last transfer recorded against the device. This transfer has not been recorded on the database.",
  211: "This account is not authorised to change the EU status of this PIC. This information has not been updated on the database.",
  212: "The Vendor Declaration number used in the Saleyard In transfer differs to the Vendor Declaration number used in the Saleyard Out transfer.",
  213: "You are not authorised to transfer livestock from the FROM PIC that you submitted. This transfer has not been recorded on the database.",
  214: "An animal has been slaughtered using a PIC with a status of %%s",
  215: "The Upload ID that you submitted could not be found in the database. The correction has not been recorded.",
  216: "You are not authorised to correct the transaction that was submitted.",
  217: "The transfer you specified could not be found on the database. The transfer correction has not been recorded.",
  218: "The transfer you are attempting to correct does not belong to your jurisdiction.",
  219: "The New Number of livestock value that you submitted is different to the Number of livestock value recorded against the linked mob based in or mob based out transfer. The transfer correction has not been recorded on the database.",
  220: "The transfer you selected could not be deleted as it would cause a break in traceability.",
  221: "The New transfer data that you submitted did not pass validation. The transfer correction has not been recorded on the database.",
  222: "The From PIC or To PIC could not be updated as there are subsequent transfers recorded against the device. The transfer correction has not been recorded on the database.",
  223: "The transfer correction type you submitted was an Unknown Transfer Correction and the original transfer was not an XXXXXXXX or AAAAAAAA transfer. The transfer correction has not been recorded on the database.",
  224: "The transfer correction type you submitted was an Unknown Transfer Correction. The device submitted in the first transfer is not the same as the device submitted in the second transfer. The transfer correction has not been recorded on the database.",
  225: "The transfer correction type you submitted was an Unknown Transfer Correction. The second transfer is not immediately subsequent to the first transfer. The transfer correction has not been recorded on the database.",
  226: "The transfer date submitted is earlier than the transfer date of the previous transfer. The transfer correction has not been recorded on the database.",
  227: "The transfer correction type you submitted was an Unknown Transfer Correction. The new destination PIC does not match the Source PIC of the subsequent transfer. The transfer correction has not been recorded on the database.",
  228: "The new transfer date is greater than the transfer date of the subsequent transfer. The transfer correction has not been recorded on the database.",
  229: "The transfer correction type you submitted was an Unknown Transfer Correction. The Destination PIC of the first transfer is not the same as the Source PIC of the second transfer. The transfer correction has not been recorded on the database.",
  230: "The new transfer date is greater than the transfer date of a subsequent transfer recorded against the device. The transfer correction has not been recorded on the database.",
  231: "The transfer date of the in transfer is greater than the transfer date of the out transfer. This information has not been recorded on the database.",
  232: "The transfer you submitted could not be removed as it is not the latest transfer for the device. The transfer correction has not been recorded on the database.",
  233: "The transfer you submitted cannot be removed as the device has been killed by an abattoir.",
  234: "The transfer correction type you submitted was an Unknown Transfer Correction. The out transfer cannot be deleted as there is no subsequent transfer recorded against the device. The transfer correction has not been recorded on the database.",
  235: "The Source PIC or the Destination PIC cannot be XXXXXXXX or AAAAAAAA. The transfer correction has not been recorded on the database.",
  236: "The transfer correction type you submitted was an Unknown Transfer Correction. There are multiple matching pairs of in and out transfers recorded agains this Upload ID. The transfer correction has not been recorded on the database.",
  237: "The format of the Vendor Declaration Number has been changed from EU to Non EU or from Non EU to EU. The transfer correction has been recorded on the database.",
  238: "This device was transferred more than once by the original Upload ID. The transfer correction has not been recorded on the database.",
  239: "You are not authorised to correct a transfer more than once. You will need to lodge a request with the NLIS helpdesk or your local State Department of Primary Industries.",
  240: "The EU Status of this device has been changed by a transfer correction/deletion.",
  241: "You are not authorised to correct a transfer more than once. You need to lodge a Transfer Correction request with the NLIS Helpdesk or your local State Department of Primary Industries.",
  242: "The original PICs or new PICs cannot be updated by using Transfer Corrections. You need to lodge a Transfer Correction request with the NLIS Helpdesk.",
  243: "The Third Party (non-written authority) disclaimer must be agreed to. This movement has not been recorded on the database.",
  244: "The transfer date submitted is earlier than the date that the device manufacturer issued this device. The device has been transferred. If you submitted an incorrect transfer date you can use Transfer Corrections to edit the transfer date.",
  245: "The kill date submitted is earlier than the last transfer recorded against this device. The kill record has been recorded on the database.",
  246: "The Start Character must be blank for National Vendor Declarations and must not be blank for EU Vendor Declarations. The Serial Number range has not been updated on the database.",
  247: "An NVD Serial Number range already exists with a Serial Number within the range submitted. The Serial Number range has not been updated on the database.",
  248: "The NVD Serial Number has already been used in a transfer on the database. The Serial Number range has been recorded on the database.",
  249: "The NVD Serial start number is greater than the end number. The Serial Number range has not been updated on the database.",
  250: "The NVD Issue PIC does not exist on the Central PIC Register. The Serial Number range has been recorded on the database however you should verify the PIC with the relevant State Department of Primary Industries.",
  251: "This NVD serial number has been previously used in a transfer with either a different date or a different consigning PIC.",
  252: "The consigning PIC is not the same as the PIC the vendor declaration was issued to.",
  253: "The consigning PIC is not LPA fully or provisionally accredited (Program status is not æAÆ or æPÆ).",
  254: "LPA validation was not successful.",
  255: "Can not remove inactive status (IA) from the device automatically.",
  256: "Can not remove M statuses from the device automatically.",
  257: "An animal with a status of %%s has been replaced",
  258: "This device was transferred FROM or TO a PIC linked to your account. The device was not registered to the FROM PIC that was submitted. The device has been transferred and has lost its lifetime traceability status.",
  259: "This device was not registered to the PIC it was transferred FROM, it was registered to a PIC linked to your account. This device has been transferred off your PIC.",
  260: "This device was transferred FROM or TO a PIC linked to your account. The device was not registered to the FROM PIC that was submitted. The device has been transferred and has lost its lifetime traceability status.",
  261: "This device was not registered to the PIC it was transferred FROM, it was registered to a PIC linked to your account. This device has been transferred off your PIC.",
  262: "This device was transferred FROM or TO a PIC linked to your account. The device was transferred from a non EU PIC to an EU PIC. The device has been transferred and is not EU eligible.",
  263: "This device was attempted to be transferred FROM or TO a PIC linked to your account. The account that submitted the transfer was not authorized to record the transfer. This device has not been transferred.",
  264: "The device that is being replaced must be registered to a PIC that is linked to your account. This replacement has not been recorded on the database.",
  265: "This device was transferred FROM or TO a PIC linked to your account. This device was transferred using an EU NVD from a non EU PIC.",
  266: "This device was transferred using an EUVD from a non-EU PIC. This device has been transferred",
  267: "PIC information is not unique.",
  268: "A system kill transfer to the abattoir PIC has been done successfully.",
  269: "This status can not be updated through this transaction. This information is not updated in the database.",
  270: "A device with a non-EU breeder status has been transferred to an EU PIC. This device has been transferred",
  272: "The device is not registered to the PICs linked to your account. You are not authorised to update this information.",
  273: "The Date of Birth can only be entered for breeder devices registered to the PIC of issue. This information has not been recorded on the database.",
  274: "The Date of Birth can only be modified by the user that originally recorded it. This information has not been recorded on the database.",
  275: "The date range for the Date of Birth must be less than 12 months. This information has not been recorded on the database.",
  276: "When entering the date range for the Date of Birth, ensure that the FROM date is before the TO date. This information has not been recorded on the database.",
  277: "You cannot modify or delete optional animal information as it does not exist for this device. Please choose another option or submit another device. ",
  278: "You cannot add optional animal information as it has already been entered for this device. Please choose another option or submit another device.",
  279: "This is not a valid breed code.",
  280: "Unable to record audit information. Please contact helpdesk.",
  281: "You must agree to the disclaimer in order to proceed.",
  282: "The date is not in the correct format. Please refer to the Help section for the accepted date formats. This information has not been recorded on the database.",
  283: "The device number you have entered is in the incorrect format.",
  284: "You must enter TAGTRAN in the NVD field when using the Tag transfer function",
  285: "Only saleyards, agents, feedlots, abattoirs, producers and third parties are allowed to submit NVD data.",
  286: "The NVD information cannot be uploaded as the source PIC supplied is classified as deceased.",
  287: "User is not authorized to transfer livestock from or to 'AAAAAAAA'",
  288: "The number of head on NVD does not match the sum of the number of head transferred to each PIC.",
  289: "The Transfer date submitted is before the NVD project commencement date of 1/1/2008.",
  290: "The selling agent you have provided does not exist on the NLIS Database.",
  291: "The selling agent you have provided does not have an 'Agent' account type.",
  292: "The NVD serial number you have submitted already exists in the NVD database.",
  293: "The name of the NVD image must be a maximum of 150 characters and consist of alphanumeric characters plus spaces, dashes, underlines and parenthesis.",
  294: "The NVD serial number you have submitted has already been submitted by another party.",
  295: "Only users that originally recorded NVD information and SAs can modify or delete it.",
  296: "The NVD information you wish to delete does not exist.",
  297: "An NVD image you have previously viewed has been deleted.",
  299: "Only the following users are authorised to view NVD information: users who originally recorded the NVD information, accounts linked to the FROM and TO PIC, selling agents, government participants, AHA, LPA and LPA auditors.",
  300: "This account is not authorised for the saleyardID.",
  301: "You must provide a valid saleyardID or saleyardPIC.",
  302: "The body record could not be found in the database. The correction has not been recorded.",
  303: "Post-breeder device transfers may only be removed. This transaction has not been processed.",
  304: "The NLS-EU2 PIC status cannot be assigned to non EU-accredited properties. This information has not been recorded.",
  305: "The NLS-EUX PIC status cannot be assigned to EU-accredited properties. This information has not been recorded.",
  306: "The replaced device does not exist on the database. The rollback has not been recorded",
  307: "The replacement device does not exist on the database. The rollback has not been recorded",
  308: 'The "old" device and the "new" device are not registered to the same PIC on the database. The rollback has not been recorded',
  309: "The replacement device is the same as the device being replaced. The rollback has not been recorded",
  310: "The replaced device does not contain a status of R1. The rollback has not been recorded",
  311: "Only SDA account holders are allowed to submit Sighted transactions.",
  312: "The PIC where the animal is sighted cannot be AAAAAAAA, EEEEEEEE, XXXXXXXX or DECEASED.",
  313: "The sighting PIC is not within your state.",
  314: "Only uploading accounts and SDA Heavy users with access to the sighted PIC can modify or delete Sighted information.",
  315: "In order to edit sighted information, the Upload ID, original data and new data must be supplied.",
  316: "In order to delete the sighted information, the Upload ID and original data must be supplied.",
  317: "The sighting date must occur on or after the program commencement date of 1/1/1999.",
  318: "The sighting date must occur on or after the issue date of the device.",
  319: "The sighting date must not be in the future.",
  320: "PIC does not exist in PIC register",
  321: "This device was replaced in the saleyard. This device has been transferred and has lost its lifetime traceability.",
  322: "The device has a status of stolen.",
  323: "The device has a status of Lost/Strayed.",
  324: "The device has a status of Lost/Stolen.",
  325: "The device has a status of Damaged.",
  326: "The device has a status of Replaced.",
  359: "One or more devices with a %%s status code have been moved onto your PIC. This message is for information only and requires no further action",
  360: "A PIC (Property Identification Code), you assigned as a PI (PIC of Interest), has been involved in a transfer or slaughter event.",
  406: "The Carcase side group (left/right/both) does not require carcase side action (D|d) for the new entry.  This information has not been updated on the database.",
  407: "The Carcase side group (left/right/both) is provided with the carcase side action(D|d). But Provided Carcase side group (left/right/both) does not exist in to the Database. This side group information has not been recorded on the database.",
  410: "Only System Administrator accounts are allowed access to the PBT serial number upload transaction",
  411: "The PBT serial number you have uploaded is in the incorrect format. It must be alphanumeric and maximum 10 characteras long.",
  412: "The PBT serial number you have uploaded already exists in the database",
  413: "The PBT serial number you have entered has not been paid for.This information has not been updated on the database.",
  414: "The PBT serial number you have entered has already been used to Movements livestock.This information has not been updated on the database.",
  415: "When setting a [NARM K3] or [JD JDV] status code, do not include the status duration.",
  416: "The new tag has a sighted cattle transaction recorded against it and cannot be used as a replacement. This replacement has not been recorded on the database.",
  417: "Cannot assign EU status for Breeder (B1) devices",
  430: "The device has had a status set using the device status for property transaction. The device status may need to be removed manually.",
  435: "Only SA and SDA accounts allowed to access to the Replacement Rollback transaction",
  441: "The Form PIC does not reside in the state of the SDA account.",
  442: "The To PIC does not reside in the state of the SDA account.",
  462: "The authority type is not valid. This information has not been updated on the database.",
  470: "An identical movement exists in same upload file. This movement has been recorded in the database.",
  480: "This PIC is not authorised for the saleyardPIC.",
  490: "If the vendor has owned the livestock since birth, the length of time owned must be empty.",
  491: "This device already has the recovered device status applied. This device status has not been changed on the database.",
  493: "The following RFID(s) you have submitted do not have a recovered device status. These devices have not been uploaded.",
  494: "The kill date submitted is before 01/01/2009.",
  495: "The source type is Saleyard but the from PIC is not a valid Saleyard PIC. This information has been accepted.",
  496: "The source type is not Saleyard but the from PIC does not pass the PIC algorithm test. This information has not been recorded.",
  497: "The uploading account does not have authority for the processor PIC.",
  498: "The uploading account is not linked with the processor PIC.",
  499: "The processor PIC used in the transaction is not within the jurisdiction of the uploading account.",
  500: "The movement already exists in the NLIS database. This record has not been uploaded to the database.",
  501: "The information you provided has already been used. This record has not been uploaded to the database.",
  502: "If the vendor has not owned the livestock since birth, the length of time owned must not be empty.",
  503: "The original transaction has already been rolled back.",
  504: "You are trying to roll back a transaction, but the transaction details you submitted do not match the details for the transaction held on the database. The transaction has not been rolled back.",
  505: "From PIC or To PIC should be within your jurisdiction.",
  506: "The from PIC you provided is not linked to your account.",
  507: "The To PIC you provided is not linked to your account.",
  510: "The following RFID(s) have been recorded as deceased on a date later than the submitted issue date. These device(s) have not been uploaded.",
  520: "The OTHER PIC/Brand you are transferring FROM is invalid. This transaction has been rejected.",
  521: "The OTHER PIC/Brand you are transferring FROM is invalid. The transaction is recorded.",
  601: "Provided Species Code does not exist in the NLIS Database.  The PIC details have been updated/inserted, but the brand details have  not been updated/inserted in the Database.",
  608: "The device that is being replaced must be registered to a PIC within your jurisdiction. This replacement has not been recorded on the database.",
  610: "This PIC has a status of Blocked on the central PIC register. These devices have been uploaded however the PIC should be verified",
  650: "Status CTP is already applied to PICs.This information has not been recorded.",
  651: "Following PICs are registered has accessed cotton trash. Status CTP is applied.",
  652: "Following PICs are registered to, no longer has Cotton trash available as stock feed. Status CTP is removed.",
  653: "Following Livestock are registered as may have had access to cotton trash. Status CTA is applied",
  654: "Status CTA will be removed for following Livestock.",
  655: "Livestock have been transferred onto the Destination PIC from a PIC with CTP (has accessed cotton trash) status. Please confirm the status of animals in this consignment with the consignor.",
  656: "CTA status ( May have had access to cotton trash) beast has been sold / slaughtered.",
  657: "The tag status is only applicable to cattle tags.",
  666: "CTA status (Cotton trash contaminated) beast has been transferred on to your property. CTW (Cotton Trash Warning) status has now been applied.This status will be removed when there are no CTA status animals resident on your property.",
  701: "The device is not eligible for EU accreditation. This information has not been recorded.",
  702: "The device has been previously transferred. This information has not been recorded.",
  703: "The Month of Birth can only be modified by the user that originally recorded it. This information has not been recorded on the database.",
  704: "The month-of-birth provided is in the future. This information has not been recorded on the database.",
  705: "The month-of-birth can only be entered for cattle breeder devices registered to the PIC of issue. This information has not been recorded on the database.",
  710: "The time format is not valid. This information has been recorded on the database without the time record.",
  711: "The kill date submitted is earlier than the provided transfer date. The kill record has  not been recorded on the database.",
  712: "The NLS-P device status cannot be assigned to the following device(s). This information has not been recorded.",
  713: "The NLS-EU2 PIC status cannot be removed from PICs which have devices with the NLS-P status registered to them. This information has not been recorded.",
  714: "You have assigned a device status to a device with the NLS-P status which conflicts with the pending EU-eligibility of this animal. This animal will not gain EU-eligibility once its NLS-P status is removed. This information has been recorded.",
  715: "The EU status of this PIC cannot be changed because it has an NLS-EU2 or NLS-EUX PIC status assigned to it. This information has not been recorded.",
  716: "The following device(s) may not have their EU status downgraded as they have the NLS-P device status (Pending EU-eligible animal) assigned to them. This information has been recorded.",
  717: "A movement has been recorded between a property with the NLS-EUX PIC status and a property with the NLS-EU2 PIC status. This information has been recorded.",
  718: "A movement has been recorded between a property which is not LPA-accredited and a property with the NLS-EU2 PIC status. This information has been recorded.",
  719: "The following device(s) with the NLS-P status have been moved before their 60-day 'EU-eligibility pending' period expires. These animals remain EU-ineligible and the NLS-P status has been removed from these device(s). This information has been recorded.",
  720: "The NLS-P status could not be automatically removed from the following device(s), which were moved before their 60-day æEU-eligibility pendingÆ period expires. The NLS-P status is still assigned to these device(s).",
  727: "One or more saleyard ID(s) that you have submitted do not exist in the database. This information has not been recorded.",
  760: "Cattle have been transferred onto the Destination PIC from a PIC with JDP (Johne's Disease affected) status. Confirm the status of animals in this consignment with the consignor.",
  762: "Following Livestock are registered as Silirium (Johne's Disease) vaccinated.Status JDV (Johne's Disease Vaccinates) is applied.",
  763: "Status JDV (Johne's Disease Vaccinates) is removed for following Livestock.",
  764: "JDV status (Johne's Disease vaccinated) beast has been sold.",
  800: "This device is not registered to the saleyard you are transferring from. You must transfer a device into the Saleyard before you can transfer it out. This device has not been transferred.",
  801: "The transfer date is earlier than the last transfer recorded against the device. This transfer has not been recorded on the database.",
  803: "This device has been previously transferred into the saleyard using the same Vendor Declaration number. This device has been transferred.",
  804: "This device is already registered to the PIC that you are transferring TO. This device has already been transferred.",
  805: "No transactions recorded against this upload ID can be corrected using this transaction.",
  807: "Following PICs have JDP status removed.",
  826: "The following RFID(s) you have submitted are already registered as non-NLIS devices. These devices have not been uploaded.",
  827: "The following device(s) have been successfully uploaded, but there were non-NLIS device(s) linked to these devices' RFID numbers on the database. The non-NLIS device(s) that are linked to the RFID number(s) you uploaded have now been deactivated.",
  830: "The device is not registered to a property which is linked to your account. You are not authorised to replace this device. This replacement has not been recorded on the database.",
  831: "The device is not a post-breeder device. This transaction has not been recorded on the database.",
  833: "The PIC you have submitted cannot be processed. Please resubmit this transaction with a saleyard ID. This device has not been transferred.",
  835: "The saleyard ID you have submitted cannot be processed. Please resubmit this transaction with a PIC. This device has not been transferred.",
  849: "The Vendor PIC you have submitted is invalid. This device has not been transferred.",
  890: "Status cannot be assigned/removed for a past date.",
  901: "Mob(s) cannot be moved using this transaction when they were moved onto an agent PIC prior to 15/03/2013. This transaction has not been processed.",
  902: "Mob(s) cannot be moved when the movement onto an agent PIC cannot be found for this movement date and origin information key. This transaction has not been processed.",
  905: "Mob(s) cannot be moved when the number of head to be moved off an agent PIC is greater than the number of head from the mob on the agent PIC's current holdings. This transaction has not been processed.",
  906: "Mob(s) cannot be moved from one agent PIC to another. This transaction has not been processed.",
  907: "Mob(s) cannot be moved when the movement date off an agent PIC for one or more mobs is before the movement date onto the agent PIC. This transaction has not been processed.",
  908: "Mob(s) cannot be moved when the specified PIC is either not an agent PIC or is not linked to an agent's account. This transaction has not been processed. ",
  910: "You are not authorised to record mob-based movements off an agent PIC. This transaction has not been processed.",
  920: "Mob(s) cannot be moved using this transaction when the From PIC you have submitted is an agent PIC and the movement date you have submitted is after 15/03/2013. This transaction has not been processed.",
  925: "This movement cannot be deleted as a subsequent movement off an agent PIC has been recorded against this movement's NVD/Waybill number.",
  930: "The following RFID(s) you have submitted are not NLIS-accredited device(s). This information has not been recorded.",
  998: "The database has not been able to confirm processing result due to a database system error.",
  999: "An undefined error has occurred. This information has not been updated on the database.",
  1003: "NLIS has recorded the movement of these animals in the database. However NLIS had the animals registered to a PIC different to the PIC of consignment (From PIC) nominated by the vendor. Lifetime traceability of these animals has been lost.",
  1008: "The species type provided in your upload is different to the species type associated with the NLIS devices. This information has been uploaded to the NLIS database successfully.",
  1009: "You are not authorised to take possession of this animal as it has been recorded as slaughtered from a different saleyard. This animal has not been transferred.",
  1010: "The transfer date submitted is later than the kill date. The transfer has not been recorded on the database.",
};

function getTransferMessageOrDefault(messageNumber) {
  return TRANSFER_MESSAGES[messageNumber] || INVALID_TRANSFER_MESSAGE;
}

export function getTransferMessage(messageNumber) {
  return getTransferMessageOrDefault(messageNumber);
}

export const NLISFileTypes = {
  NLISSellFile: "NLISSellFile",
  NLISSellReversalFile: "NLISSellReversalFile",
  NLISTakeFile: "NLISTakeFile",
  NLISTakeReversalFile: "NLISTakeReversalFile",
  NLISSightingFile: "NLISSightingFile",
  NLISP2PTransferFile: "NLISP2PTransferFile",
  NLISP2PTransferReversalFile: "NLISP2PTransferReversalFile",
};

export const NLISFileTypeHeadings = {
  [NLISFileTypes.NLISSellFile]: "Sell Possession",
  [NLISFileTypes.NLISSellReversalFile]: "Sell Reversal",
  [NLISFileTypes.NLISTakeFile]: "Take Possession",
  [NLISFileTypes.NLISTakeReversalFile]: "Take Reversal",
  [NLISFileTypes.NLISSightingFile]: "Sighting",
};

export const NLISTakeTypes = [NLISFileTypes.NLISTakeFile];

export const NLISSellTypes = [NLISFileTypes.NLISSellFile];

export const NLISReversalTypes = [
  NLISFileTypes.NLISSellReversalFile,
  NLISFileTypes.NLISSellReversalFile,
];

export const SellBlockingExceptions = [
  SaleLotException.NO_PIC,
  SaleLotException.RESTRICTED_PIC,
  SaleLotException.BLOCKED_INACTIVE_PIC,
];

export const TakeBlockingExceptions = [
  ConsignmentExceptions.NO_PIC,
  ConsignmentExceptions.NVD_ID_MISSING,
  ConsignmentExceptions.UNREGISTERED_EID,
  ConsignmentExceptions.RESTRICTED_PIC,
  ConsignmentExceptions.BLOCKED_INACTIVE_PIC,
];

// The following NLIS message numbers come back as failures,
// but they indicate that either nothing needs to be done or
// the transfer was successful.
export const FORCE_SUCCESS_CODES = [
  3,
  17,
  56,
  155,
  195,
  321,
  803, // This device has been previously transferred into the saleyard using the same Vendor Declaration number. This device has been transferred.
  804,
];
