import React from "react";

import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import SlimButton from "components/Button/SlimButton";
import CheckAuth from "components/CheckAuth";
import { Column, Row } from "components/Layout";
import { Version } from "components/Version";
import WatcherEntry from "components/WatcherEntry";

import ConfirmModalContainer from "containers/ConfirmModal";
import Home from "containers/Home";
import RedirectToDashboard from "containers/Home/RedirectToDashboard";
import Login from "containers/Login";
import PrivateRoute from "containers/PrivateRoute";

import Logo from "img/AgriNous-logo";

function ReportNotFound() {
  return (
    <Row fullWidth justifyCenter>
      <Column fullHeight alignCenter>
        <div
          style={{
            position: "relative",
            zIndex: -1,
            transform: "rotate(90deg) scale(2)",
          }}
        >
          <Logo dark />
        </div>
        <h1>That report has either expired, or no longer exists.</h1>
        <p className="text-center p-2">
          Contact the saleyard to have the report re-sent.
        </p>
        <div className="p-2">
          <SlimButton
            as="a"
            href="https://support.agrinous.com.au/invalid-reports"
          >
            Visit AgriNous Knowledge Base
          </SlimButton>
        </div>
        <p className="text-center p-2">
          For further support, contact us via email at{" "}
          <a href="mailto:support@agrinous.com.au">support@agrinous.com.au</a>
          <br /> or call us on <a href="tel:0480 039 299">0480 039 299</a>.
        </p>
      </Column>
    </Row>
  );
}

const Root = ({ store }) => (
  <Provider store={store}>
    <Switch>
      <Route path="/version" component={Version} />
      <Route
        path="/watch/:livestocksale_id/:verification/"
        component={WatcherEntry}
      />
      <Route exact path="/login">
        <RedirectToDashboard>
          <Login />
        </RedirectToDashboard>
      </Route>
      <Route path="/reports" component={ReportNotFound} />
      <Route path="/check-auth/">
        <RedirectToDashboard>
          <CheckAuth />
        </RedirectToDashboard>
      </Route>
      <PrivateRoute path="/" component={Home} />
      <Redirect to={{ pathname: "/login" }} />
    </Switch>
    <ToastContainer autoClose={3000} />
    <ConfirmModalContainer />
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
