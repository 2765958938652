import { useField } from "formik";

export function useFieldInputProps(propsOrFieldName) {
  return useField(propsOrFieldName)[0];
}

export function useFieldValue(propsOrFieldName) {
  return useFieldInputProps(propsOrFieldName).value;
}

export function useFieldMeta(propsOrFieldName) {
  return useField(propsOrFieldName)[1];
}

export function useFieldHelpers(propsOrFieldName) {
  return useField(propsOrFieldName)[2];
}

export function useFieldSetter(propsOrFieldName) {
  return useFieldHelpers(propsOrFieldName).setValue;
}

export function useFieldState(propsOrFieldName) {
  const field = useField(propsOrFieldName);
  return [field[0].value, field[2].setValue];
}
