import { NLIS_MESSAGE_GROUPS } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeNlisMessageGroup } from "lib/serializers/nlis";

const fetchMessageGroupsFiles = apiModelOfflineFetchReducer(
  NLIS_MESSAGE_GROUPS,
  {
    deserializer: deserializeNlisMessageGroup,
  },
);

export default fetchMessageGroupsFiles;
