import React from "react";

import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

const BlurContainer = styled.div`
  position: relative;
`;

const EdgeBlur = styled.div`
  position: absolute;
  height: 100%;
  width: ${({ theme }) => theme.space[1]}px;
  top: 0;
  ${props => (props.left ? "left: 0" : "right: 0")};
  background: linear-gradient(
    ${({ left }) => (left ? "to right" : "to left")},
    ${({ backgroundColor, theme }) => theme.colors[backgroundColor]},
    rgba(255, 255, 255, 0)
  );
`;

const ScrollBox = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
`;

const HorizontalScrollBox = ({ children, backgroundColor }) => (
  <BlurContainer data-tour="scrollBox">
    {children.length > 3 && <EdgeBlur left backgroundColor={backgroundColor} />}
    <ScrollBox>{children}</ScrollBox>
    {children.length > 3 && <EdgeBlur backgroundColor={backgroundColor} />}
  </BlurContainer>
);

HorizontalScrollBox.propTypes = {
  children: PropTypes.any,
  backgroundColor: PropTypes.string,
};

HorizontalScrollBox.defaultProps = {
  backgroundColor: "#fff",
};

export default HorizontalScrollBox;
