import * as Yup from "yup";

import penningValidationSchema from "components/PenningSaleLotForm/validationSchema";

const validObjectBase = {
  destination_property_id: Yup.string().nullable(),
  buyer_id: Yup.string().nullable(),
  round_id: Yup.number().required("Required!"),
  ...penningValidationSchema,
};
validObjectBase.start_pen = validObjectBase.start_pen.required("Required!");

const validObjectWithAgency = {
  agency_id: Yup.number(),
  ...validObjectBase,
};

export const ValidationSchema = Yup.object().shape(validObjectBase);
export const ValidationSchemaWithAgency = Yup.object().shape(
  validObjectWithAgency,
);
