import React from "react";

import { Modal } from "components/Sundry/Form/Modal";

import { ModalTypes } from "constants/navigation";
import { DeploymentPermissions } from "constants/permissions";

import { selectRoleCurrentDeployments } from "selectors";

import { useSomeHasPermission } from "hooks/useHasPermission";
import { useModalAdapter } from "hooks/useModalAdapter";

export const SundryModalApapter = props => {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.ManualAdjustmentForm,
    props,
  );

  const {
    id,
    fromBusinessId,
    toBusinessId,
    labels,
    gstMethod,
    readOnlyFields,
  } = hashParams;

  const hasManualChargePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureManualCharges,
  );

  if (hasManualChargePermission) {
    return (
      <Modal
        id={id}
        returnTo={returnTo}
        onClose={onClose}
        fromBusinessId={fromBusinessId}
        toBusinessId={toBusinessId}
        labels={labels}
        gstMethod={gstMethod}
        readOnlyFields={readOnlyFields}
      />
    );
  }
};
