import { createSelector } from "reselect";

import {
  createLookupCombiner,
  createLookupSelectors,
  getScans,
  selectEidsByAuctionPenIdLookup,
  selectSaleLotIdsByVendorIdLookup,
  selectScansBySaleLotIdLookup,
} from "selectors";

export const selectNotAngusVerifiedCountBySaleLotIdLookup = createSelector(
  [selectScansBySaleLotIdLookup],
  scansBySaleLotIdLookup =>
    Object.entries(scansBySaleLotIdLookup).reduce((acc, [saleLotId, scans]) => {
      const verifiedCount = scans.filter(
        s => s.animal?.is_angus_verified,
      ).length;
      acc[saleLotId] = verifiedCount > 0 ? scans.length - verifiedCount : null;

      return acc;
    }, {}),
);

export const getNotAngusVerifiedCountBySaleLotId = saleLotId => state =>
  selectNotAngusVerifiedCountBySaleLotIdLookup(state)[saleLotId];

export const selectNotAngusVerifiedCountByPenId = createSelector(
  [selectEidsByAuctionPenIdLookup, getScans],
  (eidsByAuctionPenIdLookup, scans) =>
    Object.entries(eidsByAuctionPenIdLookup).reduce(
      (acc, [auctionPenId, eids]) => {
        const verifiedCount = eids.filter(
          eid => scans[eid]?.animal.is_angus_verified,
        ).length;
        acc[auctionPenId] = verifiedCount ? eids.length - verifiedCount : null;
        return acc;
      },
      {},
    ),
);

export const getNotAngusVerifiedCountByPenId = auctionPenId => state =>
  selectNotAngusVerifiedCountByPenId(state)[auctionPenId];

const notAngusVerifiedCountByVendorIdReducer = (
  saleLotIds,
  scansBySaleLotId,
) => {
  if (saleLotIds.length > 0) {
    const totalScans = saleLotIds.reduce(
      (acc, saleLotId) => acc + scansBySaleLotId[saleLotId]?.length || 0,
      0,
    );
    const totalVerifiedScans = saleLotIds.reduce(
      (acc, saleLotId) =>
        acc +
          scansBySaleLotId[saleLotId]?.filter(s => s.animal.is_angus_verified)
            .length || 0,
      0,
    );
    if (!totalVerifiedScans) {
      return null;
    }
    return totalScans - totalVerifiedScans;
  }
  return null;
};

export const [
  selectNotAngusVerifiedCountByVendorIdLookup,
  getNotAngusVerifiedCountByVendorId,
] = createLookupSelectors(
  [selectSaleLotIdsByVendorIdLookup, selectScansBySaleLotIdLookup],
  createLookupCombiner(notAngusVerifiedCountByVendorIdReducer),
);
