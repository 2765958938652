import { HubMessageEvent } from "./HubMessageEvent";
import {
  HubConnection,
  HubRequest,
  OnConnectionEstablishedCallback,
  OnConnectionFailedCallback,
} from "./HubConnection";

type EventTargetWithHubContext = EventTarget & { context: HubConnection };

export class WebSocketHubConnection extends HubConnection {
  private _socket: WebSocket & { context?: WebSocketHubConnection } = null;

  protected _url: URL;

  constructor(urlString: string, minVersion: string) {
    super(minVersion);
    this._url = new URL(urlString);
  }

  /**
   * Waits for an established connection, and calls onConnectionEstablished
   * parameter or alternatively will attempt to establish a connection before
   * calling onConnectionEstablished. If a the connection cannot be established
   * the onConnectionFailed will be called
   */
  public ensureConnectionEstablished(
    onConnectionEstablished: OnConnectionEstablishedCallback,
    onConnectionFailed: OnConnectionFailedCallback,
  ) {
    if (!this.isConnected()) {
      if (!this.isConnecting()) {
        this.connect();
      }
    }
    this.waitForEstablishedConnection(
      onConnectionEstablished,
      onConnectionFailed,
    );
  }

  /**
   * Sends the `data` sting in the `request` object to the websocket in the provided `hubConnection`.
   * If a connection is not yet established, it attempts to establish one. In the
   * event it a connection cannot be established OR the message fails to send, the onError` callback function
   * will be invoked (if provided), otherwise the `onSuccess` callback will be
   * called (if provided). Does not throw.
   */
  public submitRequest(
    request: HubRequest,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) {
    const hubConnection = this;

    function onConnectionEstablished() {
      const err = hubConnection.sendRequest(request);
      if (err !== "") {
        if (typeof onError === "function") {
          onError(err);
        }
      } else if (typeof onSuccess === "function") {
        onSuccess();
      }
    }

    function onConnectionFailed(errString) {
      if (typeof onError === "function") {
        onError(errString);
      }
    }

    this.ensureConnectionEstablished(
      onConnectionEstablished,
      onConnectionFailed,
    );
  }

  /**
   * Checks whether the hub understands ping
   * The Windows hub has (had?) a limitation where it would send back a generic error message stating it did
   * not understand a ping.  As we NEED pings for the mobile app, we can't just use a new version number.
   * Similarly - we don't have a delineation of WHAT this hub is - we know the Windows hub is all that handles weigh bridges
   * so, IF we have supportedFeatures and it contains weighbridge - prior to version XXX that means no PING.
   */
  public doesHubSupportPing() {
    // TODO - BAU-1755 - when the Windows hub handles PING make sure it's included in supportedFeatures
    if (
      Array.isArray(this._supportedFeatures) &&
      this._supportedFeatures.includes("ping")
    ) {
      // Categorically supported.
      return true;
    }
    if (
      Array.isArray(this._supportedFeatures) &&
      this._supportedFeatures.includes("weighbridge")
    ) {
      // It's a Windows hub (we know this because it supports weighbridge), but has no ping so is older.
      return false;
    }
    // Fallback to a true state - the current worst that happens is we get a bunch of "I don't understand" responses.
    return true;
  }

  /**
   * Closes the socket, removes event handlers and removes the socket
   */
  public disconnect() {
    if (!this._socket) {
      return;
    }
    // prevent memory leaks by removing dangling listeners
    this._socket.removeEventListener(
      "message",
      WebSocketHubConnection.messageDeserialiserEvent,
    );
    this._socket.removeEventListener(
      "close",
      WebSocketHubConnection.closeEvent,
    );
    this._socket.removeEventListener(
      "error",
      WebSocketHubConnection.logSocketError,
    );

    if (this.isConnected() || this.isConnecting()) {
      try {
        this._socket.close();
      } catch {
        // something has gone "drastically" wrong, but we don't worry, we're
        // disposing of the socket anyway
      }
    }
    this._socket = null;
    this._connectedVersion = null;
  }

  /**
   * The default OnMessage Event handler for the hub websocket. Processes
   * messages received from the hub and re-dispatches as DOM events
   */
  private static messageDeserialiserEvent(messageEvent: HubMessageEvent) {
    const websocket = messageEvent.target as EventTargetWithHubContext;
    const hubConnection = websocket.context;

    let messageData = null;
    try {
      messageData = JSON.parse(messageEvent.data);
    } catch (e) {
      // TODO fix the hub it returns a lot of non-JSON responses.
      // once all responses are JSON, this catch block may be useful
    }

    if (messageData) {
      const dataEvent = new HubMessageEvent("data");
      dataEvent.data = messageData;
      hubConnection.dispatchEvent(dataEvent);
    }
  }

  private static closeEvent(closeEventObject: HubMessageEvent) {
    const websocket = closeEventObject.target as EventTargetWithHubContext;
    const hubConnection = websocket.context;
    hubConnection.dispatchEvent(new HubMessageEvent("closed"));
  }

  /**
   * Handler for when a websocket error occurs.  Just tell the console, for now.
   *
   */
  private static logSocketError(event) {
    // eslint-disable-next-line no-console
    console.warn("Socket received error event: ", event);
  }

  /**
   * Handler for the first message emitted from the Hub, which contains an info
   * block containing the connected hub's version. This is then validated against
   * the minVersion set in the hubConnection which is connected to this request.
   * Upon determining the version, emits a "version" event. When the connected hub
   * version is lower than the minVersion, the connection is dropped and a
   * "connectionFailed" event is emitted.
   * Emits "version", "connected" and "connectionFailed" events
   */
  private static messageVersionFilterEvent(messageEvent: HubMessageEvent) {
    const websocket = messageEvent.target as EventTargetWithHubContext;
    const hubConnection = websocket.context;
    const hubVersion = WebSocketHubConnection.getVersionFromResponseData(
      messageEvent.data,
    );
    hubConnection.connectedVersion = hubVersion;

    const hubFeatures =
      WebSocketHubConnection.getHubSupportedFeaturesFromResponseData(
        messageEvent.data,
      );
    hubConnection.supportedFeatures = hubFeatures;

    const versionEvent = new HubMessageEvent("version");
    versionEvent.minVersion = hubConnection.minVersion;
    versionEvent.connectingVersion = hubVersion;

    hubConnection.dispatchEvent(versionEvent);

    if (hubVersion >= hubConnection.minVersion) {
      hubConnection.connectedVersion = hubVersion;
      websocket.addEventListener(
        "message",
        WebSocketHubConnection.messageDeserialiserEvent,
      );
      hubConnection.dispatchEvent(new HubMessageEvent("connected"));
    } else {
      const connectionFailedEvent = new HubMessageEvent("connectionFailed");
      connectionFailedEvent.reason = "AgriNous Hub version is incompatible.";
      hubConnection.dispatchEvent(connectionFailedEvent);
      hubConnection.disconnect();
    }
  }

  /**
   * A WebSocket `open` event handler used to register the version filter
   * handler, which is the first message sent from the hub on connection
   */
  private static filterOpenEvent(openEvent: HubMessageEvent) {
    const websocket = openEvent.target;
    // Stupid eslint: when a function is declared using the `function` keyword,
    // the definition is hoisted to the top on the scope
    // eslint-disable-next-line no-use-before-define
    websocket.removeEventListener(
      "close",
      WebSocketHubConnection.filterCloseEvent,
    );
    websocket.addEventListener(
      "message",
      WebSocketHubConnection.messageVersionFilterEvent,
      {
        once: true,
      },
    );
    websocket.addEventListener("close", WebSocketHubConnection.closeEvent);
  }

  private static filterCloseEvent(closeEvent: HubMessageEvent) {
    // eslint-disable-next-line no-console
    console.log("Socket received close event: ", closeEvent);

    const websocket = closeEvent.target as EventTargetWithHubContext;
    const hubConnection = websocket.context;
    websocket.removeEventListener(
      "open",
      WebSocketHubConnection.filterOpenEvent,
    );

    const connectionFailedEvent = new HubMessageEvent("connectionFailed");
    connectionFailedEvent.reason = "Connection to AgriNous Hub failed.";
    hubConnection.dispatchEvent(connectionFailedEvent);
  }

  private static isHubDataPreversioning(data: string) {
    // Prior to version 511 AgriNous hub didn't return a version object
    return typeof data === "string" && data === "Welcome to the server!";
  }

  private static getVersionFromResponseData(data: string) {
    if (WebSocketHubConnection.isHubDataPreversioning(data)) {
      return 510;
    }
    let hubInfo = null;
    try {
      hubInfo = JSON.parse(data);
      // eslint-disable-next-line no-empty
    } catch {}
    if (hubInfo) {
      return (hubInfo.AgriNousHub && hubInfo.AgriNousHub.version) || 0;
    }
    return 0;
  }

  private static getHubSupportedFeaturesFromResponseData(data: string) {
    if (WebSocketHubConnection.isHubDataPreversioning(data)) {
      return [];
    }
    let hubInfo = null;
    try {
      hubInfo = JSON.parse(data);
      // eslint-disable-next-line no-empty
    } catch {}
    if (hubInfo) {
      return (
        (hubInfo.AgriNousHub && hubInfo.AgriNousHub.supportedFeatures) || []
      );
    }
    return [];
  }

  /**
   * Establishes a connection to the url in the HubConnection url. This may cause
   * any of the following events to be emitted immediately afterwards: connecting,
   * version, connected or connectionFailed.
   */
  private connect() {
    this.disconnect();

    this.dispatchEvent(new HubMessageEvent("connecting"));

    this._socket = new WebSocket(this._url.toString());
    this._socket.context = this;
    this._socket.addEventListener(
      "open",
      WebSocketHubConnection.filterOpenEvent,
      {
        once: true,
      },
    );
    this._socket.addEventListener(
      "close",
      WebSocketHubConnection.filterCloseEvent,
      {
        once: true,
      },
    );
    this._socket.addEventListener(
      "error",
      WebSocketHubConnection.logSocketError,
    );
  }

  /**
   * Returns whether or not the socket in the hubConnection is in the "OPEN" state
   */
  private isConnected(): boolean {
    return !!(this._socket && this._socket.readyState === WebSocket.OPEN);
  }

  /**
   * Returns whether or not the socket in the hubConnection is in the "CONNECTING"
   * state
   */
  private isConnecting(): boolean {
    return !!(this._socket && this._socket.readyState === WebSocket.CONNECTING);
  }

  /**
   *  Returns a reason text on error
   */
  private sendRequest(request: HubRequest): string {
    if (!this.isConnected()) {
      return "Not connected to AgriNous Hub";
    }
    try {
      this._socket.send(request);
      return "";
    } catch (e) {
      const domError = e as DOMException;
      return domError.message;
    }
  }

  /**
   * Calls the onConnectionEstablished callback when the a connection is
   * established, on the onConnectionFailed callback is there was an error when
   * attempting to establish a connection. If the connection is already
   * established it will short circuit to just calling the onConnectionEstablished
   * callback.
   */
  private waitForEstablishedConnection(
    onConnectionEstablished: OnConnectionEstablishedCallback,
    onConnectionFailed: OnConnectionFailedCallback,
  ) {
    function onErrorHandler(connectionFailedError) {
      // Stupid eslint: when a function is declared using the `function` keyword,
      // the definition is hoisted to the top on the scope
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      this.removeEventListener("connected", onOpenHandler);
      onConnectionFailed(connectionFailedError.reason);
    }

    function onOpenHandler() {
      this.removeEventListener("connectionFailed", onErrorHandler);
      onConnectionEstablished();
    }

    if (this.isConnected()) {
      onConnectionEstablished();
    } else {
      this.addEventListener("connected", onOpenHandler, {
        once: true,
      });
      this.addEventListener("connectionFailed", onErrorHandler, {
        once: true,
      });
    }
  }
}
