import React from "react";

import { faGavel } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

const Icon = styled(FontAwesomeIcon).attrs({
  icon: faGavel,
})(
  ({ theme, color, margin }) => `
    color: ${theme.colors[color] || theme.colors.primary};
    font-size: ${theme.fontSizes.epsilon}px;
    margin: ${
      typeof margin === "number" ? theme.space[margin] : theme.space[1]
    }px;
`,
);

export const ComplianceEmailRecipientIcon = ({ color }) => (
  <span title="Compliance Report Recipient">
    <Icon color={color} />
  </span>
);
