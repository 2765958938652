import { SingleWeighStatus } from "constants/singleWeigh";

import {
  HubNotConnectedAction,
  PlcNotConnectedAction,
  ScannerNotConnectedAction,
  WeighbridgeNotConnectedAction,
} from "./DisconnectedActions";
import { DraftPensUnavailableAction } from "./DraftPensUnavailableAction";
import { EidWaitTimeoutAction } from "./EidWaitTimeoutAction";
import { DeliveryPenLockedAction } from "./LockedPenAction";
import { MultipleEidsAction } from "./MultipleEidsAction";
import { NoDeliveryPenAction, NoSellingPenAction } from "./NoPenAction";
import { PenSelectionAction } from "./PenSelectionAction";
import { ReWeighAction } from "./ReweighAction";
import { SteadyWeightTimeoutAction } from "./SteadyWeightTimeoutAction";
import { UnknownEidAction } from "./UnknownEidAction";

export const SingleWeighStatusComponentMap = {
  [SingleWeighStatus.ANIMAL_ALREADY_WEIGHED]: ReWeighAction,
  [SingleWeighStatus.DRAFT_PENS_UNAVAILABLE]: DraftPensUnavailableAction,
  [SingleWeighStatus.EID_WAIT_TIMEOUT]: EidWaitTimeoutAction,
  [SingleWeighStatus.HUB_NOT_CONNECTED]: HubNotConnectedAction,
  [SingleWeighStatus.MULTIPLE_EIDS]: MultipleEidsAction,
  [SingleWeighStatus.NO_DELIVERY_PEN]: NoDeliveryPenAction,
  [SingleWeighStatus.DELIVERY_PEN_LOCKED]: DeliveryPenLockedAction,
  [SingleWeighStatus.NO_SELLING_PEN]: NoSellingPenAction,
  [SingleWeighStatus.PLC_NOT_CONNECTED]: PlcNotConnectedAction,
  [SingleWeighStatus.SCALES_NOT_CONNECTED]: WeighbridgeNotConnectedAction,
  [SingleWeighStatus.SCANNER_NOT_CONNECTED]: ScannerNotConnectedAction,
  [SingleWeighStatus.STEADY_WAIT_TIMEOUT]: SteadyWeightTimeoutAction,
  [SingleWeighStatus.UNKNOWN_EID]: UnknownEidAction,
  [SingleWeighStatus.WAITING_FOR_PEN_SELECTION]: PenSelectionAction,
};
