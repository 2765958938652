import React from "react";

import { useDispatch } from "react-redux";

import { updateProducts } from "actions";

import { BaseTable } from "components/Importer/BaseTable";
import { productColumns } from "components/Importer/columnDefinitions";

export function ProductsImporter({
  importProducts,
  agrinousProducts,
  onCellValueChanged,
}) {
  const dispatch = useDispatch();

  const updateDeploymentProduct = data => {
    const payload = {
      age_id: data.age_id || undefined,
      sale_round_id: data.sale_round || undefined,
      sex_id: data.sex_id || undefined,
      breed_id: data.breed_id || undefined,
      quick_code: data.quick_code || data.strgSexCode,
      code: data.code || data.strgSexCode,
      deployment_id: data.deployment_id,
      id: data.id,
      name: data.name.label,
      order: data.order,
      quick_select: data.quick_select,
      saleyard_code: data.strgSexCode,
      species_id: data.species_id,
    };
    dispatch(updateProducts([payload]));
  };

  const productOptions = agrinousProducts.filter(Boolean).map(b => ({
    label: b.name || "",
    value: b.id || "",
  }));

  const productData = importProducts.map(p => {
    const agrinousData = agrinousProducts.find(
      dp => dp.saleyard_code === p.strgSexCode,
    );
    return {
      ...p,
      ...agrinousData,
      name: {
        label: agrinousData?.name,
        value: agrinousData?.id,
      },
    };
  });

  return (
    <BaseTable
      columns={productColumns(productOptions)}
      data={productData}
      onCellValueChanged={onCellValueChanged(agrinousProducts)}
      context={{
        update: updateDeploymentProduct,
        mappingColumn: "strgSexCode",
      }}
    />
  );
}
