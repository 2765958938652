import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const concurrentUserBlockSerializeMap = {
  reason: "reason",
  information: "information",
  maximumUsers: "maximum_users",
  timeout: "timeout",
  activeUsers: {
    name: "name",
    lastSeen: "last_seen",
    userAgent: "user_agent",
    unique: "unique",
  },
};

const nestedObjectMap = {
  active_users: "activeUsers",
};

export const deserializeConcurrentUserBlock = buildDeserializer(
  concurrentUserBlockSerializeMap,
  "concurrentUserBlock",
  nestedObjectMap,
);

const currentUserMap = {
  id: "id",
  date_joined: "date_joined",
  default_role_slug: "default_role_slug",
  allow_allauth_sso: "allow_allauth_sso",
  email: "email",
  first_name: "first_name",
  is_staff: "is_staff",
  last_login: "last_login",
  last_name: "last_name",
  user_id: "user_id",
};

const currentUserNestedObjectMap = {};

export const serializeCurrentUser = buildSerializer(
  currentUserMap,
  "Current User",
  currentUserNestedObjectMap,
);

export const deserializeCurrentUser = buildDeserializer(
  currentUserMap,
  "Current User",
  currentUserNestedObjectMap,
);

const saleyardAdminRoleMap = {
  id: "id",
  branches: "branches",
  channels: "channels",
  date_joined: "date_joined",
  default_role: "default_role",
  deployments: "deployments",
  email: "email",
  email_confirmed: "email_confirmed",
  first_name: "first_name",
  reportFavourites: "report_favourites",
  invitation_sent: "invitation_sent",
  is_active: "is_active",
  last_name: "last_name",
  name: "name",
  nlis_saleyard_credentials: "nlis_saleyard_credentials",
  phone_number: "phone_number",
  role_invitation_accepted_date: "role_invitation_accepted_date",
  role_invitation_sent_date: "role_invitation_sent_date",
  saleyards: "saleyards",
  slug: "slug",
  type: "type",
  username: "username",
};

const saleyardAdminNestedObjectMap = {};

export const serializeSaleyardAdminRole = buildSerializer(
  saleyardAdminRoleMap,
  "Saleyard Admin",
  saleyardAdminNestedObjectMap,
);

export const deserializeSaleyardAdminRole = buildDeserializer(
  saleyardAdminRoleMap,
  "Saleyard Admin",
  saleyardAdminNestedObjectMap,
);

const livestockAgentRoleMap = {
  id: "id",
  agencies: "agencies",
  channels: "channels",
  date_joined: "date_joined",
  default_role: "default_role",
  deployments: "deployments",
  email: "email",
  email_confirmed: "email_confirmed",
  first_name: "first_name",
  reportFavourites: "report_favourites",
  invitation_sent: "invitation_sent",
  is_active: "is_active",
  last_name: "last_name",
  name: "name",
  nlis_agent_credentials: "nlis_agent_credentials",
  nlis_saleyard_credentials: "nlis_saleyard_credentials",
  phone_number: "phone_number",
  role_invitation_accepted_date: "role_invitation_accepted_date",
  role_invitation_sent_date: "role_invitation_sent_date",
  saleyards: "saleyards",
  slug: "slug",
  type: "type",
  username: "username",
};

const livestockAgentNestedObjectMap = {};

export const serializeLivestockAgentRole = buildSerializer(
  livestockAgentRoleMap,
  "Livestock Agent Role",
  livestockAgentNestedObjectMap,
);

export const deserializeLivestockAgentRole = buildDeserializer(
  livestockAgentRoleMap,
  "Livestock Agent Role",
  livestockAgentNestedObjectMap,
);

const saleWatcherRoleMap = {
  id: "id",
  default_role: "default_role",
  channels: "channels",
  name: "name",
  slug: "slug",
  type: "type",
  username: "username",
  livestock_sales: "livestock_sales",
};

const saleWatcherNestedObjectMap = {};

export const serializeSaleWatcherRole = buildSerializer(
  saleWatcherRoleMap,
  "Sale Watcher Role",
  saleWatcherNestedObjectMap,
);

export const deserializeSaleWatcherRole = buildDeserializer(
  saleWatcherRoleMap,
  "Sale Watcher Role",
  saleWatcherNestedObjectMap,
);

const scaleOperatorRoleMap = {
  id: "id",
  channels: "channels",
  date_joined: "date_joined",
  default_role: "default_role",
  default_saleyard_id: "default_saleyard_id",
  deployments: "deployments",
  email: "email",
  first_name: "first_name",
  invitation_sent: "invitation_sent",
  is_active: "is_active",
  is_paid_user: "is_paid_user",
  last_buyer_id: "last_buyer_id",
  last_name: "last_name",
  name: "name",
  nlis_saleyard_credentials: "nlis_saleyard_credentials",
  phone_number: "phone_number",
  role_invitation_accepted_date: "role_invitation_accepted_date",
  role_invitation_sent_date: "role_invitation_sent_date",
  saleyards: "saleyards",
  slug: "slug",
  type: "type",
  username: "username",
};

const scaleOperatorNestedObjectMap = {};

export const serializeScaleOperatorRole = buildSerializer(
  scaleOperatorRoleMap,
  "Scale Operator Role",
  scaleOperatorNestedObjectMap,
);

export const deserializeScaleOperatorRole = buildDeserializer(
  scaleOperatorRoleMap,
  "Scale Operator Role",
  scaleOperatorNestedObjectMap,
);

const businessUserRoleMap = {
  id: "id",
  business: "business",
  email: "email",
  first_name: "first_name",
  is_active: "is_active",
  is_commercial_user: "is_commercial_user",
  is_compliance_user: "is_compliance_user",
  last_name: "last_name",
  name: "name",
  phone_number: "phone_number",
  slug: "slug",
  type: "type",
  username: "username",
};

const businessUserNestedObjectMap = {};

export const serializeBusinessUserRole = buildSerializer(
  businessUserRoleMap,
  "Business User",
  businessUserNestedObjectMap,
);

export const deserializeBusinessUserRole = buildDeserializer(
  businessUserRoleMap,
  "Business User",
  businessUserNestedObjectMap,
);
