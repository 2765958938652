import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";

import {
  selectLottedHeadCount,
  selectProgenyHeadCount,
  selectSoldLiveWeightHeadCount,
  selectSoldLiveWeightProgenyCount,
  selectSoldOpenAuctionHeadCount,
  selectSoldOpenAuctionProgenyCount,
} from "selectors/counts";
import { PricingTypes } from "constants/pricingTypes";
import { getProgenyDisplayCount } from "lib/saleLot";

export function SaleHeadCounts(): React.JSX.Element {
  const soldOpenAuctionHeadCount = useSelector(selectSoldOpenAuctionHeadCount);
  const soldOpenAuctionProgenyCount = useSelector(
    selectSoldOpenAuctionProgenyCount,
  );
  const soldLiveWeightHeadCount = useSelector(selectSoldLiveWeightHeadCount);
  const soldLiveWeightProgenyCount = useSelector(
    selectSoldLiveWeightProgenyCount,
  );
  const headCount = useSelector(selectLottedHeadCount);
  const progenyHeadCount = useSelector(selectProgenyHeadCount);

  return (
    <>
      <Grid item xs={6}>
        <h3
          title={`The total quantity of animals sold as ${PricingTypes.toString(
            PricingTypes.PER_KILO,
          )}`}
        >
          Live Weight Hd
        </h3>
        <span>
          {`${soldLiveWeightHeadCount} ${getProgenyDisplayCount(
            soldLiveWeightProgenyCount,
          )}`}
        </span>
      </Grid>
      <Grid item xs={6}>
        <h3
          title={`The total quantity of animals sold as ${PricingTypes.toString(
            PricingTypes.PER_HEAD,
          )} or ${PricingTypes.toString(PricingTypes.GROSS)}`}
        >
          Open Auction Hd
        </h3>
        <span>
          {`${soldOpenAuctionHeadCount} ${getProgenyDisplayCount(
            soldOpenAuctionProgenyCount,
          )}`}
        </span>
      </Grid>

      <Grid item xs={6}>
        <h3 title="The total quantity of animals lotted in the sale">
          Lotted Hd
        </h3>
        <span>
          {`${headCount} ${getProgenyDisplayCount(progenyHeadCount)}`}
        </span>
      </Grid>
    </>
  );
}
