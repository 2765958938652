import { faLock } from "@fortawesome/pro-solid-svg-icons";

export const disableReadOnlyActions = action => {
  if (action.readOnly) {
    return {
      ...action,
      default: false,
      icon: faLock,
      isDisabled: true,
      onClick: null,
    };
  } else {
    return action;
  }
};
