import React from "react";

import { intersection } from "lodash";
import flatten from "lodash/flatten";
import { useSelector } from "react-redux";

import { Chip } from "components/Chip";
import { Row } from "components/Layout";

import { colors } from "constants/theme";

import { getLabels, getSaleLots } from "selectors";

export const CommonLabels = React.memo(({ saleLotIds, limitTo = [] }) => {
  const saleLotsLabels = useSelector(state => {
    const all = getSaleLots(state);
    return flatten(saleLotIds.map(id => all[id].labels));
  });
  const labels = useSelector(getLabels);

  let commonLabels = intersection(saleLotsLabels)
    .map(labelId => labels[labelId]?.name || "")
    .filter(Boolean);
  if (limitTo.length > 0) {
    commonLabels = commonLabels.filter(label => limitTo.includes(label));
  }

  if (commonLabels.length > 0) {
    return (
      <Row flexWrap>
        {commonLabels.map(label => (
          <Chip backgroundColor={colors.blueHighlight} key={label}>
            {label}
          </Chip>
        ))}
      </Row>
    );
  } else {
    return null;
  }
});
