import React from "react";

import {
  GenericMultiFileTypeImporter,
  GenericMultiFileTypeImporterSection,
} from "components/Importer/GenericImporter";
import { Column } from "components/Layout";

import { PRE_SALE_CSV } from "constants/actionTypes";
import { IMPORTER_TYPES } from "constants/importer";

const preSaleCsvSections = [
  GenericMultiFileTypeImporterSection.BUYERS,
  GenericMultiFileTypeImporterSection.PRODUCTS,
  GenericMultiFileTypeImporterSection.SALE_ROUNDS,
  GenericMultiFileTypeImporterSection.VENDORS,
];

const HelpTextComponent = () => (
  <Column>
    <ul>
      <li>
        The format for entering Scans is using pipes as separators: 951
        123xxxxxx|951 123xxxxxx|951 123xxxxxx
      </li>
      <li>The required fields are: Lot Number, Pen, Quantity, Vendor Name.</li>
      <li>
        If you do not need Buyers, you can leave all Buyer columns blank, or
        remove them.
      </li>
      <li>
        If you have entered a Buyer Email or Phone Number then the Buyer First
        Name and Last Name are also required.
      </li>
      <li>
        To import Pricing Type as $/Hd, you can use $/ea in all columns.
        Otherwise, the Type will default to the current Sale&apos;s Pricing
        Type.
      </li>
    </ul>
  </Column>
);

export function PreSaleCsvImporter() {
  return (
    <GenericMultiFileTypeImporter
      importName={IMPORTER_TYPES.PRE_SALE_CSV}
      fileTypes={["text/csv", "application/csv", ".csv"]}
      fileDescription="Sale Import CSV"
      type={PRE_SALE_CSV}
      sections={preSaleCsvSections}
      HelpTextComponent={HelpTextComponent}
    />
  );
}
