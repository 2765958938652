import React from "react";

import { sortBy } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Table from "components/Table";

import { REPORT_TYPE, REPORT_DISPLAY_NAME } from "constants/reports";

import { filterAll } from "lib";

import { getFavouritesReportsListByTab } from "selectors";

import { agentReport } from "./columns";

const ReportTitle = styled.h3`
  margin-left: ${({ theme }) => theme.space[1]}px;
`;
const {
  columnsGeneralFavourites,
  columnsAgenciesFavourites,
  columnsBuyersFavourites,
  columnsVendorsFavourites,
} = agentReport;

const columns = {
  [REPORT_TYPE.AGENCIES]: columnsAgenciesFavourites,
  [REPORT_TYPE.AGENTS]: columnsAgenciesFavourites,
  [REPORT_TYPE.BRANCHES]: columnsGeneralFavourites,
  [REPORT_TYPE.BUYERS]: columnsBuyersFavourites,
  [REPORT_TYPE.CARDS]: columnsAgenciesFavourites,
  [REPORT_TYPE.VENDORS]: columnsVendorsFavourites,
  [REPORT_TYPE.SALEYARD]: columnsGeneralFavourites,
  [REPORT_TYPE.SALE_FILES]: columnsGeneralFavourites,
  [REPORT_TYPE.SALE_EXPORT]: columnsGeneralFavourites,
  [REPORT_TYPE.NLIS]: columnsGeneralFavourites,
  [REPORT_TYPE.STUD]: columnsGeneralFavourites,
};

export const FavouritesTables = ({ searchTerm }) => {
  const favouritesReportList = useSelector(getFavouritesReportsListByTab);
  const filteredReports = React.useMemo(
    () =>
      Object.entries(favouritesReportList).reduce((acc, [tab, reportsList]) => {
        acc[tab] = filterAll(reportsList, searchTerm);
        return acc;
      }, {}),
    [searchTerm, favouritesReportList],
  );
  return (
    <>
      {sortBy(
        Object.keys(filteredReports),
        tabKey => REPORT_DISPLAY_NAME[tabKey],
      ).map((tabKey, idx) => {
        const reportsList = filteredReports[tabKey];
        return (
          reportsList.length > 0 && (
            <div key={idx}>
              <ReportTitle>{REPORT_DISPLAY_NAME[tabKey]} Reports</ReportTitle>
              <Table
                data={reportsList}
                columns={columns[tabKey]}
                suppressNoResult
              />
            </div>
          )
        );
      })}
    </>
  );
};
