/**
 * @typedef {string} BSBSixDigit
 * A 6 digit representation of the BSB.
 */
/**
 * @typedef {string} BSBMnemonic
 * The mnemonic for the BSB's financial institution.
 */
/**
 * @typedef {string} BSBName
 * The name of the BSB.
 */
/**
 * @typedef {[ BSBSixDigit, BSBMnemonic, BSBName ]} BSBEntry
 * A 3 element Tuple containing the six digit BSB, bank mnemonic, and bsb name
 */

/**
 * @return {Array<BSBEntry>}
 */
function getNodeAusPaymentsNetworkBsbs() {
  return [];
}

/**
 * @return {Array<BSBEntry>}
 */
function getBrowserAusPaymentsNetworkBsbs() {
  return window.AusPayNetBsbs || [];
}

const nodeExports = {
  getAusPaymentsNetworkBsbs: getNodeAusPaymentsNetworkBsbs,
};

const browserExports = {
  getAusPaymentsNetworkBsbs: getBrowserAusPaymentsNetworkBsbs,
};

window === undefined
  ? (module.exports = nodeExports)
  : (module.exports = browserExports);
