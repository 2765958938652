import React from "react";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

import { ImportSummaryModal } from "./ImporterSummary";

export function ImportSummaryAdapter(props) {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.ImportSummary,
    props,
  );

  const {
    action = {},
    errors = [],
    iterateErrorMessage = false,
    type = "Failure",
  } = hashParams;
  return (
    <ImportSummaryModal
      action={action}
      errors={errors}
      iterateErrorMessage={iterateErrorMessage}
      type={type}
      onClose={onClose}
      returnTo={returnTo}
    />
  );
}
