import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const integrationBusinessPhoneSerializeMap = {
  type: "type",
  phoneNumber: "phone_number",
};

const integrationBusinessContactSerializeMap = {
  emailAddress: "email_address",
  firstName: "first_name",
  lastName: "last_name",
  phone: "phone",
};

const integrationBusinessAddressSerializeMap = {
  addressee: "addressee",
  city: "city",
  country: "country",
  line1: "line_1",
  line2: "line_2",
  postCode: "post_code",
  state: "state",
  type: "type",
};

export const integrationBusinessSerializeMap = {
  accountNumber: "account_number",
  addresses: integrationBusinessAddressSerializeMap,
  bsb: "bsb",
  contacts: integrationBusinessContactSerializeMap,
  deploymentBusinessId: "deployment_business_id",
  emailAddress: "email_address",
  id: "id",
  integrationId: "integration_id",
  integrationIdType: "integration_id_type",
  names: "names",
  permissions: "permissions",
  phones: integrationBusinessPhoneSerializeMap,
  type: "type",
};

export const serializeIntegrationBusiness = buildSerializer(
  integrationBusinessSerializeMap,
  "integrationBusiness",
);
export const deserializeIntegrationBusiness = buildDeserializer(
  integrationBusinessSerializeMap,
  "integrationBusiness",
);
/*

const INTEGRATION_BUSINESS_PHONE_COLUMN_DEFINITIONS = [
  { field: "phone_name" },
  { field: "type" },
  { field: "phone_number" },
];

const INTEGRATION_BUSINESS_CONTACT_COLUMN_DEFINITIONS = [
  { field: "email_address" },
  { field: "first_name" },
  { field: "last_name" },
  { field: "phone" },
];

const INTEGRATION_BUSINESS_ADDRESS_COLUMN_DEFINITIONS = [
  { field: "addressee" },
  { field: "line_1" },
  { field: "line_2" },
  { field: "city" },
  { field: "state" },
  { field: "post_code" },
  { field: "country" },
  { field: "type" },
];

const INTEGRATION_BUSINESS_COLUMN_DEFINITIONS = [
  { field: "addresses" },
  { field: "contacts" },
  { field: "id" },
  { field: "names" },
  { field: "phones" },
  { field: "type" },
];

* */
