import { LIVESTOCK_AGENT } from "constants/actionTypes";

import { serializeLivestockAgent } from "lib/serializers/livestockAgents";

import {
  offlineActionCreator,
  urlCheckAndAddIdAndAction,
  reportFavorouriteActionCreator,
} from "./lib";

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/livestock-agents/", id, action);

export const LivestockAgentAction = offlineActionCreator(
  LIVESTOCK_AGENT,
  serializeLivestockAgent,
  urlCreator,
  false,
);

LivestockAgentAction.reinvite = function reinvite(id) {
  return {
    type: LIVESTOCK_AGENT.REINVITE.REQUEST,
    meta: {
      offline: {
        effect: {
          url: urlCreator({ id, action: "reinvite" }),
          method: "POST",
        },
        commit: {
          type: LIVESTOCK_AGENT.REINVITE.SUCCESS,
        },
        rollback: {
          type: LIVESTOCK_AGENT.REINVITE.FAILURE,
        },
      },
    },
  };
};

LivestockAgentAction.addReportFavourite = reportFavorouriteActionCreator(
  LIVESTOCK_AGENT.ADD_REPORT_FAVOURITE,
  "POST",
  "livestock-agents",
);
LivestockAgentAction.removeReportFavourite = reportFavorouriteActionCreator(
  LIVESTOCK_AGENT.REMOVE_REPORT_FAVOURITE,
  "DELETE",
  "livestock-agents",
);
