import { createSelector } from "reselect";

import { PropertyStatus } from "constants/properties";
import {
  ConsignmentExceptions,
  SaleLotException,
  WarningLevel,
} from "constants/sale";
import { ScanStatus } from "constants/scanner";
import { Species } from "constants/species";

import { EMPTY_ARRAY } from "lib";

import { getLivestockSaleId } from "lib/navigation";
import { getCombinedLotNumber } from "lib/saleLot";

import {
  createLookupCombiner,
  createLookupSelectors,
  getAuctionPens,
  getBusinesses,
  getConsignments,
  getCurrentSale,
  getCurrentSpeciesId,
  getProperties,
  getSaleLots,
  selectAreWeightAndDressingFeaturesEnabled,
  selectConsignmentIdsByDeploymentSaleIdLookup,
  selectConsignmentIdsByVendorIdLookup,
  selectCurrentDeploymentSaleIdsList,
  selectDeploymentIdBySaleLotIdLookup,
  selectHasNvdUploadByConsignmentIdLookup,
  selectHasUnregisteredEidsBySaleLotIdLookup,
  selectIsDeclarationCompleteByConsignmentIdLookup,
  selectIsNoSaleBySaleLotIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleLotIdsByDeploymentSaleIdLookup,
  selectScanStatusBySaleLotIdLookup,
} from "selectors";

import {
  selectIsBalancedBySaleLotIdLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
} from "selectors/balances";
import { selectReservedPropertyIds } from "selectors/properties";
import { selectValidationOverridesByDeploymentIdLookup } from "selectors/validations";

const isScanRateCompliantByConsignment = (
  consignment,
  saleLotIdsByConsignmentId,
  scanStatusBySaleLotId,
) => {
  const saleLotIds = saleLotIdsByConsignmentId[consignment.id] || [];
  return saleLotIds.every(
    saleLotId => scanStatusBySaleLotId[saleLotId] === ScanStatus.PASS,
  );
};

export const [
  /**
   * Returns the Scan Status, keyed by Sale Lot Id
   * Requires:
   *  - `Consignments`
   *  - `Sale Lots`
   *  - `Sales`
   *  - `Scans`
   * @type {function(state): Object<string, number>}
   */
  selectIsScanRateCompliantByConsignmentIdLookup,
  getIsScanRateCompliantByConsignmentId,
] = createLookupSelectors(
  [
    getConsignments,
    selectSaleLotIdsByConsignmentIdLookup,
    selectScanStatusBySaleLotIdLookup,
  ],
  createLookupCombiner(isScanRateCompliantByConsignment),
);

const isException = (validation, saleLot) =>
  validation &&
  validation.validationLevel === WarningLevel.EXCEPTION &&
  saleLot.quantity > validation.headThreshold;

export const selectHasSexExceptionBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
  ],
  (saleLots, deploymentIdBySaleLotId, deploymentValidations) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      acc[saleLotId] =
        isException(validations?.missingSex, saleLot) && !saleLot.sex_id;
      return acc;
    }, {}),
);

export const selectHasBreedExceptionBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
  ],
  (saleLots, deploymentIdBySaleLotId, deploymentValidations) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      acc[saleLotId] =
        isException(validations?.missingBreed, saleLot) && !saleLot.breed_id;
      return acc;
    }, {}),
);

export const selectHasWeightRangeExceptionBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
    getCurrentSale,
    selectAreWeightAndDressingFeaturesEnabled,
  ],
  (
    saleLots,
    deploymentIdBySaleLotId,
    deploymentValidations,
    sale,
    featureEnabled,
  ) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      const isSheepSale = sale.species_id === Species.SHEEP;
      acc[saleLotId] =
        isException(validations?.missingWeightRange, saleLot) &&
        !saleLot.estimatedAverageWeightId &&
        isSheepSale &&
        featureEnabled;
      return acc;
    }, {}),
);

export const getHasWeightRangeExceptionBySaleLotId = lotId => state =>
  selectHasWeightRangeExceptionBySaleLotId(state)[lotId];

export const selectHasAgeExceptionBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
    getCurrentSale,
  ],
  (saleLots, deploymentIdBySaleLotId, deploymentValidations, currentSale) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      const isCattleSale = currentSale.species_id === Species.CATTLE;
      acc[saleLotId] =
        isCattleSale &&
        isException(validations?.missingAge, saleLot) &&
        !saleLot.age_id;
      return acc;
    }, {}),
);

export const selectHasDentitionExceptionBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
  ],
  (saleLots, deploymentIdBySaleLotId, deploymentValidations) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      acc[saleLotId] =
        isException(validations?.missingDentition, saleLot) &&
        !saleLot.dentitionId;
      return acc;
    }, {}),
);

export const getHasDentitionExceptionBySaleLotId = lotId => state =>
  selectHasDentitionExceptionBySaleLotId(state)[lotId];
/**
 * Returns a List of Sale Lot Exceptions, keyed by Sale Lot Id
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 *  - `Properties`
 * @type {function(state): Object<string, Array<string>>}
 */
export const selectExceptionsBySaleLotIdLookup = createSelector(
  [
    getSaleLots,
    selectIsSoldBySaleLotIdLookup,
    selectIsNoSaleBySaleLotIdLookup,
    selectIsBalancedBySaleLotIdLookup,
    selectReservedPropertyIds,
    selectHasSexExceptionBySaleLotId,
    selectHasBreedExceptionBySaleLotId,
    selectHasAgeExceptionBySaleLotId,
    selectHasDentitionExceptionBySaleLotId,
    selectHasWeightRangeExceptionBySaleLotId,
    getProperties,
  ],
  (
    saleLots,
    isSoldBySaleLotId,
    isNoSaleBySaleLotId,
    isBalancedBySaleLotId,
    reservedPropertyIds,
    hasSexExceptionBySaleLotId,
    hasBreedExceptionBySaleLotId,
    hasAgeExceptionBySaleLotId,
    hasDentitionExceptionBySaleLotId,
    hasWeightRangeExceptionByLotId,
    propertyByIdLookup,
  ) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const exceptionsList = [];

      const isSold = isSoldBySaleLotId[saleLotId];
      const isNoSale = isNoSaleBySaleLotId[saleLotId];

      const destinationPropertyId = saleLot.destination_property_id;

      if (hasSexExceptionBySaleLotId[saleLotId]) {
        exceptionsList.push(SaleLotException.NO_SEX);
      }
      if (hasBreedExceptionBySaleLotId[saleLotId]) {
        exceptionsList.push(SaleLotException.NO_BREED);
      }
      if (hasAgeExceptionBySaleLotId[saleLotId]) {
        exceptionsList.push(SaleLotException.NO_AGE);
      }
      if (hasWeightRangeExceptionByLotId[saleLotId]) {
        exceptionsList.push(SaleLotException.NO_WEIGHT_RANGE);
      }
      if (hasDentitionExceptionBySaleLotId[saleLotId]) {
        exceptionsList.push(SaleLotException.NO_DENTITION);
      }

      if ((isSold || isNoSale) && !destinationPropertyId) {
        exceptionsList.push(SaleLotException.NO_PIC);
      } else if (destinationPropertyId) {
        if (reservedPropertyIds.indexOf(destinationPropertyId) > -1) {
          exceptionsList.push(SaleLotException.RESTRICTED_PIC);
        }
        const propertyStatus =
          propertyByIdLookup[destinationPropertyId]?.activeStatus;
        if (
          propertyStatus === PropertyStatus.INACTIVE ||
          propertyStatus === PropertyStatus.BLOCKED
        ) {
          exceptionsList.push(SaleLotException.BLOCKED_INACTIVE_PIC);
        }
      }

      if (!isBalancedBySaleLotId[saleLotId]) {
        exceptionsList.push(SaleLotException.UNBALANCED);
      }

      acc[saleLotId] = exceptionsList.length > 0 ? exceptionsList : EMPTY_ARRAY;
      return acc;
    }, {}),
);

const hasUnregisteredEidsByConsignmentIdCombiner = (
  saleLotIds,
  hasUnregisteredEidsBySaleLotId,
) => saleLotIds.some(saleLotId => hasUnregisteredEidsBySaleLotId[saleLotId]);

export const [
  selectHasUnregisteredEidsByConsignmentIdLookup,
  getHasUnregisteredEidsByConsignmentId,
] = createLookupSelectors(
  [
    selectSaleLotIdsByConsignmentIdLookup,
    selectHasUnregisteredEidsBySaleLotIdLookup,
  ],
  createLookupCombiner(hasUnregisteredEidsByConsignmentIdCombiner),
);

const hasUnregisteredEidsByVendorIdCombiner = (
  consignmentIds,
  hasUnregisteredEidsByConsignmentId,
) =>
  consignmentIds.some(
    consignmentId => hasUnregisteredEidsByConsignmentId[consignmentId],
  );

export const [
  selectHasUnregisteredEidsByVendorIdLookup,
  getHasUnregisteredEidsByVendorId,
] = createLookupSelectors(
  [
    selectConsignmentIdsByVendorIdLookup,
    selectHasUnregisteredEidsByConsignmentIdLookup,
  ],
  createLookupCombiner(hasUnregisteredEidsByVendorIdCombiner),
);

/**
 * Returns a List of Consignment Exceptions, keyed by Consignment Id
 * Requires:
 *  - `Attachments`
 *  - `Consignments`
 *  - `Properties`
 * @type {function(state): Object<string, Array<string>>}
 */
export const selectExceptionsByConsignmentIdLookup = createSelector(
  [
    selectHasNvdUploadByConsignmentIdLookup,
    getConsignments,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    selectIsDeclarationCompleteByConsignmentIdLookup,
    selectIsScanRateCompliantByConsignmentIdLookup,
    selectReservedPropertyIds,
    getCurrentSpeciesId,
    getProperties,
  ],
  (
    hasNvdUploadByConsignmentId,
    consignments,
    isPostSaleBalancedByConsignmentId,
    isDeclarationCompleteByConsignmentId,
    isScanRateCompliantByConsignmentId,
    reservedPropertyIds,
    speciesId,
    propertyByIdLookup,
  ) =>
    Object.entries(consignments).reduce((acc, [consignmentId, consignment]) => {
      const exceptionsList = [];
      const vendorPropertyId = consignment.vendor_property_id;
      if (!vendorPropertyId) {
        exceptionsList.push(ConsignmentExceptions.NO_PIC);
      } else {
        if (reservedPropertyIds.indexOf(vendorPropertyId) > -1) {
          exceptionsList.push(ConsignmentExceptions.RESTRICTED_PIC);
        }
        const propertyStatus =
          propertyByIdLookup[consignment.vendor_property_id]?.activeStatus;
        if (
          propertyStatus === PropertyStatus.INACTIVE ||
          propertyStatus === PropertyStatus.BLOCKED
        ) {
          exceptionsList.push(ConsignmentExceptions.BLOCKED_INACTIVE_PIC);
        }
      }

      if (!consignment.NVD) {
        exceptionsList.push(ConsignmentExceptions.NVD_ID_MISSING);
      }

      if (!hasNvdUploadByConsignmentId[consignmentId]) {
        exceptionsList.push(ConsignmentExceptions.NVD_SCAN_MISSING);
      }

      if (!isPostSaleBalancedByConsignmentId[consignmentId]) {
        exceptionsList.push(ConsignmentExceptions.UNBALANCED);
      }

      if (!isDeclarationCompleteByConsignmentId[consignmentId]) {
        exceptionsList.push(ConsignmentExceptions.NVD_INCOMPLETE);
      }

      if (speciesId === Species.CATTLE) {
        // Scan Rate Compliance is an exception for Cattle, but a warning for other species
        if (!isScanRateCompliantByConsignmentId[consignmentId]) {
          exceptionsList.push(ConsignmentExceptions.SCAN_RATE_COMPLIANCE);
        }
      }

      acc[consignmentId] =
        exceptionsList.length > 0 ? exceptionsList : EMPTY_ARRAY;
      return acc;
    }, {}),
);

/**
 * Returns a list of Consignment Exceptions, and the associated Consignment Id for each applicable Consignment, keyed by Deployment Sale Id
 * Requires:
 *  - `Attachments`
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<{ consignmentId: string, exceptions: Array<string> }>>}
 */
export const selectConsignmentExceptionsByDeploymentSaleIdLookup =
  createSelector(
    [
      selectCurrentDeploymentSaleIdsList,
      selectConsignmentIdsByDeploymentSaleIdLookup,
      selectExceptionsByConsignmentIdLookup,
    ],
    (
      deploymentSaleIds,
      consignmentIdsByDeploymentSaleId,
      exceptionsByConsignmentId,
    ) =>
      deploymentSaleIds.reduce((acc, deploymentSaleId) => {
        const consignmentIds =
          consignmentIdsByDeploymentSaleId[deploymentSaleId] || [];

        const consignmentExceptionsList = consignmentIds.reduce(
          (acc, consignmentId) => {
            const exceptions = exceptionsByConsignmentId[consignmentId];
            if (exceptions.length > 0) {
              acc.push({ consignmentId, exceptions });
            }
            return acc;
          },
          [],
        );

        acc[deploymentSaleId] =
          consignmentExceptionsList.length > 0
            ? consignmentExceptionsList
            : EMPTY_ARRAY;

        return acc;
      }, {}),
  );

/**
 * Returns a list of Sale Lot Exceptions, and the associated Sale Lot Id for each applicable Sale Lot, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Properties`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<{ saleLotId: string, exceptions: Array<string> }>>}
 */
export const selectSaleLotExceptionsByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    selectSaleLotIdsByDeploymentSaleIdLookup,
    selectExceptionsBySaleLotIdLookup,
  ],
  (deploymentSaleIds, saleLotIdsByDeploymentSale, exceptionsBySaleLotId) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      const saleLotIds = saleLotIdsByDeploymentSale[deploymentSaleId] || [];

      const saleLotExceptionsList = saleLotIds.reduce((acc, saleLotId) => {
        const exceptions = exceptionsBySaleLotId[saleLotId];
        if (exceptions.length > 0) {
          acc.push({ saleLotId, exceptions });
        }
        return acc;
      }, []);

      acc[deploymentSaleId] =
        saleLotExceptionsList.length > 0 ? saleLotExceptionsList : EMPTY_ARRAY;
      return acc;
    }, {}),
);

/**
 * Returns a list of Sale Lot Exceptions, and the associated Sale Lot Id for each applicable Sale Lot, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Properties`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<{ saleLotId: string, exceptions: Array<string> }>>}
 */
export const selectLegacySaleLotExceptionsByDeploymentSaleIdLookup =
  createSelector(
    [
      selectSaleLotExceptionsByDeploymentSaleIdLookup,
      getBusinesses,
      getSaleLots,
      getAuctionPens,
    ],
    (exceptionByDeploymentSaleId, businesses, saleLots, auctionPens) =>
      Object.entries(exceptionByDeploymentSaleId).reduce(
        (acc, [deploymentSaleId, exceptions]) => {
          const exceptionsList = exceptions.map(({ saleLotId, exceptions }) => {
            const saleLot = saleLots[saleLotId] || {};
            const buyerId = saleLot.buyer_id;
            const auctionPen = auctionPens[saleLot.auction_pen_id] || {};
            return {
              saleLotId,
              exceptions,
              businessId: buyerId,
              businessName: businesses[buyerId]?.name,
              buyerWayName: saleLot.buyerWay?.name,
              penNumber: auctionPen.start_pen,
              lotNumber: getCombinedLotNumber(saleLot),
              quantity: saleLot.quantity,
            };
          });
          acc[deploymentSaleId] =
            exceptionsList.length > 0 ? exceptionsList : EMPTY_ARRAY;
          return acc;
        },
        {},
      ),
  );

/**
 * Returns a list of annotated Consignment Exceptions for the Venodors tab in the Deployment Sale ExceptionsView Component, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Properties`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<{ saleLotId: string, exceptions: Array<string> }>>}
 */
export const selectLegacyConsignmentExceptionsByDeploymentSaleIdLookup =
  createSelector(
    [
      selectConsignmentExceptionsByDeploymentSaleIdLookup,
      getBusinesses,
      getConsignments,
    ],
    (exceptionByDeploymentSaleId, businesses, consignments) =>
      Object.entries(exceptionByDeploymentSaleId).reduce(
        (acc, [deploymentSaleId, exceptions]) => {
          const exceptionsList = exceptions.map(
            ({ consignmentId, exceptions }) => {
              const consignment = consignments[consignmentId] || {};
              const vendorId = consignment?.vendor_id;
              return {
                consignmentId,
                exceptions,
                businessId: vendorId,
                businessName: businesses[vendorId]?.name,
                NVD: consignmentId[consignmentId]?.NVD,
              };
            },
          );
          acc[deploymentSaleId] =
            exceptionsList.length > 0 ? exceptionsList : EMPTY_ARRAY;
          return acc;
        },
        {},
      ),
  );

export const getConsignmentExceptionsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectConsignmentExceptionsByDeploymentSaleIdLookup(
      state,
      getLivestockSaleId(),
    )[deploymentSaleId];

export const getSaleLotExceptionsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectSaleLotExceptionsByDeploymentSaleIdLookup(
      state,
      getLivestockSaleId(),
    )[deploymentSaleId];

export const getHasConsignmentExceptionsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectConsignmentExceptionsByDeploymentSaleIdLookup(
      state,
      getLivestockSaleId(),
    )[deploymentSaleId].length > 0;

export const getHasSaleLotExceptionsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectSaleLotExceptionsByDeploymentSaleIdLookup(
      state,
      getLivestockSaleId(),
    )[deploymentSaleId].length > 0;

export const getHasExceptionsByDeploymentSaleId = deploymentSaleId => state =>
  getHasConsignmentExceptionsByDeploymentSaleId(deploymentSaleId)(state) ||
  getHasSaleLotExceptionsByDeploymentSaleId(deploymentSaleId)(state);
