import { GET_AUDIT_LOG } from "constants/actionTypes";

const initialState = {
  isFetching: false,
  auditLogEntries: {},
};

const auditLog = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUDIT_LOG.REQUEST: {
      const { dataType, dataId } = action;
      return {
        ...state,
        isFetching: true,
        [dataType]: {
          ...state?.[dataType],
          [dataId]: undefined,
        },
      };
    }
    case GET_AUDIT_LOG.SUCCESS: {
      const { auditLogEntries, dataType, dataId } = action;
      return {
        ...state,
        [dataType]: {
          ...state?.[dataType],
          [dataId]: auditLogEntries,
        },
        isFetching: false,
      };
    }
    case GET_AUDIT_LOG.FAILURE:
      const { dataType, dataId } = action;
      // Explicitly set to NULL so we know it's failed.  Hai Sam.
      return {
        ...state,
        [dataType]: {
          ...state?.[dataType],
          [dataId]: null,
        },
        isFetching: false,
      };

    default:
      return state;
  }
};

export default auditLog;
