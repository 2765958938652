import React from "react";

import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faBrush as faBrushSolid } from "@fortawesome/pro-duotone-svg-icons";
import {
  faBrush as faBrushOutlined,
  faTruck,
} from "@fortawesome/pro-light-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import { uniq } from "lodash";
import flatten from "lodash/flatten";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import Property from "components/Property";

import { Accreditation } from "constants/draftingAttributes";
import { getStatus } from "constants/erpCodes";
import {
  GOOD_STATUSES,
  LPA_N,
  NlisProgramName,
  WARNING_PROGRAMS,
} from "constants/nlis";
import { Species } from "constants/species";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { getNvdCattleWarnings, getNvdSheepWarnings } from "lib/nlisId";
import { pluralize } from "lib/pluralize";
import { getEstimatedAlternativeCostPerUnit } from "lib/saleLot";

import {
  currentSaleSelector,
  getAuctionPens,
  getBusinessById,
  getDraftingInformationById,
  getDraftingInformationIdsBySaleLotId,
  getHasUnregisteredEidsByAuctionPenId,
  getHasUnregisteredEidsByConsignmentId,
  getHasUnregisteredEidsBySaleLotId,
  getHasUnregisteredEidsByVendorId,
  getIsManuallyWeighedBySaleLotId,
  getLabelById,
  getNotAngusVerifiedCountByPenId,
  getNotAngusVerifiedCountBySaleLotId,
  getNotAngusVerifiedCountByVendorId,
  getPropertyById,
  getSaleLotById,
  getSaleLotsBySale,
  getScanByEid,
  getVendorIdByConsignmentId,
  selectDeliveryPenIdBySaleLotIdLookup,
  selectPropertyIdByPICLookup,
} from "selectors";

export const ChipElement = styled.div(
  ({
    theme,
    $color,
    $backgroundColor,
    $borderColor,
    $italic,
    $borderLeftColor,
  }) => `
  background: ${
    theme.colors[$backgroundColor] || $backgroundColor || theme.colors.gray95
  };
  color: ${$color ? theme.colors[$color] || theme.colors.white : "inherit"};
  
  border-radius: ${theme.radii[1]}px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors[$borderColor] || $borderColor || "transparent"};
  padding: 1px 6px;
  margin: 2px;
  ${$italic ? "font-style: italic;" : ""}
  display: flex;
  align-items: center;
  ${
    $borderLeftColor &&
    `border-left: solid thick ${
      theme.colors[$borderLeftColor] || $borderLeftColor
    };`
  };
`,
);

// This allows for chips to be long pressed on mobile to reveal tooltips
export const ChipWrapper = styled.div`
  display: flex;
`;

export const Chip = React.memo(
  ({
    children,
    tooltip,
    color,
    backgroundColor,
    borderColor,
    italic,
    borderLeftColor,
    dataTour,
    className,
  }) =>
    tooltip ? (
      <Tooltip title={tooltip} aria-label={tooltip}>
        <ChipElement
          data-tour={dataTour}
          $color={color}
          $backgroundColor={backgroundColor}
          $borderColor={borderColor}
          $italic={italic}
          $borderLeftColor={borderLeftColor}
          className={className}
        >
          {children}
        </ChipElement>
      </Tooltip>
    ) : (
      <ChipElement
        data-tour={dataTour}
        $color={color}
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
        $italic={italic}
        $borderLeftColor={borderLeftColor}
        className={className}
      >
        {children}
      </ChipElement>
    ),
);

export const ChipBag = styled.div(
  ({ theme }) => `
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: justify-start;
    border: 1px solid ${theme.colors.gray95};
    border-radius: ${theme.radii[1]}px;
    padding: ${theme.space[1]}px;
    
    @media (min-width: ${theme.breakpoints[0]}px) {
      margin:  ${theme.space[1]}px;
    }
    min-height: ${theme.space[4]}px
    `,
);

export const PICChip = ({ businessId, PICs }) => {
  const propertyByPicLookup = useSelector(selectPropertyIdByPICLookup);

  return Array.isArray(PICs) && PICs.length ? (
    <Chip
      color="PICChipText"
      backgroundColor="PICChipBackground"
      tooltip={PICs.length > 1 && PICs.join(", ")}
    >
      {PICs.length === 1 ? (
        <Property
          businessId={businessId}
          propertyId={propertyByPicLookup[PICs[0]]}
        />
      ) : (
        `${PICs.length} PICs`
      )}
    </Chip>
  ) : null;
};

export const ERPChip = saleLots => {
  let hasERPWarnings;
  let badErpMessages = null;
  if (saleLots.erpMessages) {
    badErpMessages = [...new Set(saleLots.erpMessages)].filter(
      m => !GOOD_STATUSES.includes(m),
    );
    hasERPWarnings = badErpMessages.length > 0;
  } else {
    const erpMessages = [];
    if (saleLots.saleLots && saleLots.saleLots.length > 0) {
      saleLots.saleLots.forEach(saleLot => {
        if (saleLot.scans) {
          saleLot.scans.forEach(scan => {
            if (scan.ERP_status) {
              erpMessages.push(scan.ERP_status);
            }
          });
        }
      });
    }
    badErpMessages = [...new Set(erpMessages)].filter(
      m => !GOOD_STATUSES.includes(m),
    );
    hasERPWarnings = badErpMessages.length > 0;
  }

  const onlyLpaWarnings = badErpMessages
    .filter(Boolean)
    .some(message => message.includes("LPA  N  "));

  const mixedWarnings =
    badErpMessages.includes(LPA_N) &&
    badErpMessages.length > 1 &&
    !badErpMessages.every(value => value === LPA_N);

  let warningText;
  if (onlyLpaWarnings) {
    warningText = "LPA WARNING";
  } else if (mixedWarnings) {
    warningText = "ERP & LPA WARNING";
  } else {
    warningText = "ERP WARNING";
  }

  return hasERPWarnings ? (
    <ChipWrapper>
      <Chip color backgroundColor="warning" tooltip={badErpMessages}>
        <FaIcon icon={faExclamationCircle} />
        &nbsp;{warningText}
      </Chip>
    </ChipWrapper>
  ) : null;
};

export const EidsNotRegisteredChip = props => {
  const { hasUnregisteredEids } = props;
  if (!hasUnregisteredEids) {
    return null;
  }
  return (
    <ChipWrapper>
      <Chip
        color
        backgroundColor="warning"
        tooltip="Not all EIDs are registered on the NLIS Database"
      >
        <FaIcon icon={faExclamationCircle} />
        &nbsp;Unregistered EIDs
      </Chip>
    </ChipWrapper>
  );
};

export const SaleLotEidsNotRegisteredChip = props => {
  const { saleLotId } = props;
  const hasUnregisteredEids = useSelector(
    getHasUnregisteredEidsBySaleLotId(saleLotId),
  );
  if (!hasUnregisteredEids) {
    return null;
  }
  return <EidsNotRegisteredChip hasUnregisteredEids={hasUnregisteredEids} />;
};

export const AuctionPenEidsNotRegisteredChip = props => {
  const { auctionPenId } = props;
  const hasUnregisteredEids = useSelector(
    getHasUnregisteredEidsByAuctionPenId(auctionPenId),
  );
  if (!hasUnregisteredEids) {
    return null;
  }
  return <EidsNotRegisteredChip hasUnregisteredEids={hasUnregisteredEids} />;
};
export const ConsignmentEidsNotRegisteredChip = props => {
  const { consignmentId } = props;
  const hasUnregisteredEids = useSelector(
    getHasUnregisteredEidsByConsignmentId(consignmentId),
  );
  if (!hasUnregisteredEids) {
    return null;
  }
  return <EidsNotRegisteredChip hasUnregisteredEids={hasUnregisteredEids} />;
};

export const VendorEidsNotRegisteredChip = props => {
  const { vendorId } = props;
  const hasUnregisteredEids = useSelector(
    getHasUnregisteredEidsByVendorId(vendorId),
  );
  if (!hasUnregisteredEids) {
    return null;
  }
  return <EidsNotRegisteredChip hasUnregisteredEids={hasUnregisteredEids} />;
};

export const AuctionPenERPChip = React.memo(({ auctionPenId }) => {
  const saleLots = useSelector(getSaleLotsBySale);
  return (
    <ERPChip
      saleLots={saleLots.filter(sl => sl.auction_pen_id === auctionPenId)}
    />
  );
});

const NVDChipComponent = props => {
  const { declarations, speciesId, vendorBred, euEligible } = props;
  let warnings = [];

  if (speciesId === Species.SHEEP && declarations.length > 0) {
    warnings = flatten(declarations.map(getNvdSheepWarnings));
  } else if (speciesId === Species.CATTLE && declarations.length > 0) {
    warnings = flatten(declarations.map(getNvdCattleWarnings));
  }

  const flattenedVendorBred = flatten(vendorBred);

  const flattenedEuEligible = flatten(euEligible);

  const areAllVendorBred =
    warnings.includes("Vendor Bred") ||
    (flattenedVendorBred.length > 0 && flattenedVendorBred.every(Boolean));

  const areAllEuEligible =
    flattenedEuEligible.length > 0 && flattenedEuEligible.every(Boolean);

  const warningString = warnings
    .filter(warning => warning !== "Vendor Bred")
    .join(", ");
  if (warnings) {
    return (
      <ChipWrapper>
        {areAllVendorBred && (
          <Chip color backgroundColor="success" tooltip="Vendor Bred">
            VB
          </Chip>
        )}
        {areAllEuEligible && (
          <Chip color backgroundColor="success" tooltip="EU Eligible">
            EU
          </Chip>
        )}
        {warningString && (
          <Chip color backgroundColor="warning" tooltip={warningString}>
            <FaIcon icon={faExclamationCircle} />
            &nbsp; NVD WARNING
          </Chip>
        )}
      </ChipWrapper>
    );
  } else {
    return null;
  }
};

export const NVDChip = React.memo(NVDChipComponent);

export const PicWarningChip = props => {
  const { programs } = props;
  return programs
    .filter(program => WARNING_PROGRAMS.includes(program.code))
    .map(({ code, status }) => (
      <ChipWrapper key={`${code}${status}`}>
        <Chip
          color
          backgroundColor="warning"
          tooltip={getStatus(`${code}${status}`)}
        >
          <FaIcon icon={faExclamationCircle} />
          &nbsp;&nbsp;
          {NlisProgramName[code]}
        </Chip>
      </ChipWrapper>
    ));
};

export const DeviceChip = ({ tooltip, children }) => (
  <Chip backgroundColor="white" borderColor="gray13" tooltip={tooltip}>
    {children}
  </Chip>
);

export const DraftChip = ({ draftingInformationId }) => {
  const draftingInformation = useSelector(
    getDraftingInformationById(draftingInformationId),
  );
  return draftingInformation ? (
    <DeviceChip tooltip={draftingInformation.deviceId}>
      {draftingInformation.common_name || draftingInformation.deviceName}
      {draftingInformation.draftName && `: ${draftingInformation.draftName} `}
    </DeviceChip>
  ) : null;
};

export const DraftChips = React.memo(({ saleLotId }) => {
  const draftingInformationIds = useSelector(
    getDraftingInformationIdsBySaleLotId(saleLotId),
  );
  if (draftingInformationIds && draftingInformationIds.size) {
    return Array.from(draftingInformationIds).map(draftingInformationId => (
      <DraftChip
        draftingInformationId={draftingInformationId}
        key={draftingInformationId}
      />
    ));
  }
  return null;
});

export const ManuallyWeighedChip = React.memo(({ saleLotId }) => {
  const isManuallyWeighed = useSelector(
    getIsManuallyWeighedBySaleLotId(saleLotId),
  );

  if (isManuallyWeighed) {
    return (
      <Chip
        color
        backgroundColor="warning"
        tooltip="Some animal weights have been manually entered."
      >
        Manual Weights Found
      </Chip>
    );
  }

  return null;
});

export const MoneyChip = ({ tooltip, children }) => (
  <Chip backgroundColor="gold" tooltip={tooltip} color>
    {children}
  </Chip>
);

export const AlternativeMoneyChip = ({ tooltip, saleLot }) => {
  const estimates = getEstimatedAlternativeCostPerUnit(saleLot);
  if (estimates) {
    return (
      <Chip backgroundColor="lavender" tooltip={tooltip} color>
        ~ {estimates.price} {estimates.priceUnits}
      </Chip>
    );
  } else {
    return null;
  }
};

export const WeightChip = ({ tooltip, children, dataTour }) => (
  <Chip dataTour={dataTour} backgroundColor="blueHighlight" tooltip={tooltip}>
    {children}
  </Chip>
);

export const MarkChips = ({ marks, showNoMarks }) => {
  if (!Array.isArray(marks) || marks.length === 0) {
    return showNoMarks ? <Chip>No Marks</Chip> : null;
  }
  return marks.map(({ color, location }, index) => (
    <Chip key={`mark${index} `}>
      <span className="text-lg">{location}</span> &nbsp;
      <FaIcon icon={color ? faBrushSolid : faBrushOutlined} color={color} />
    </Chip>
  ));
};

export const ConsignmentChip = ({ consignmentId }) => {
  const vendorId = useSelector(getVendorIdByConsignmentId(consignmentId));
  const vendor = useSelector(getBusinessById(vendorId));

  // consignments always have a vendor but not when consignments havent loaded yet
  if (!consignmentId || !vendor) {
    return null;
  }
  return (
    <Chip>
      {vendor.shortCode} &nbsp;
      <FaIcon icon={faTruck} />
    </Chip>
  );
};

export const NoteChip = ({ note }) => {
  if (!note) {
    return null;
  }
  return (
    <Chip color={false} backgroundColor="warningOrange" italic tooltip={note}>
      {note.length > 50 ? `${note.substring(0, 50)}...` : note}
    </Chip>
  );
};

export const NLISChip = ({ children }) => (
  <Chip backgroundColor="white" borderColor="gray13">
    {children}
  </Chip>
);

export const NLISChips = ({ propertyId }) => {
  const lpaStatus = useSelector(
    state => getPropertyById(propertyId)(state)?.lpaStatus || "",
  );

  if (lpaStatus === "") {
    return null;
  }
  return (
    <NLISChip key="lpa">
      LPA{" "}
      {lpaStatus === "A" ? (
        <FaIcon icon={faCheck} color="success" />
      ) : (
        <FaIcon icon={faExclamationCircle} color="warning" />
      )}
    </NLISChip>
  );
};

export const SpeciesChips = React.memo(
  ({ ageName, breedName, categoryName, gradeName, sexName }) => [
    breedName && (
      <Chip key="breed" backgroundColor="blueHighlight">
        {breedName}
      </Chip>
    ),
    ageName && (
      <Chip key="age" backgroundColor="blueHighlight">
        {ageName}
      </Chip>
    ),
    sexName && (
      <Chip key="sex" backgroundColor="blueHighlight">
        {sexName}
      </Chip>
    ),
    gradeName && (
      <Chip key="grade" backgroundColor="blueHighlight">
        {gradeName}
      </Chip>
    ),
    categoryName && (
      <Chip key="category" backgroundColor="blueHighlight">
        {categoryName}
      </Chip>
    ),
  ],
);

export const TotalWeightChip = ({ children }) => (
  <Chip tooltip="Total weight in kg" backgroundColor="blueHighlight">
    {children}
  </Chip>
);

export const AverageWeightChip = ({ children }) => (
  <Chip tooltip="Average weight in kg" backgroundColor="blueHighlight">
    {children}
  </Chip>
);

export const ImagesChip = ({ count }) => {
  const currentSaleIsOnline = useSelector(currentSaleSelector).show_online;

  if (!currentSaleIsOnline && count === 0) {
    return null;
  } else {
    const backgroundColor = currentSaleIsOnline && !count ? "red" : "white";
    return (
      <Chip
        backgroundColor={backgroundColor}
        borderColor="gray13"
        color={currentSaleIsOnline && !count}
      >
        {count} {pluralize("Image", count)}
      </Chip>
    );
  }
};

export const VideosChip = ({ hasVideo }) =>
  hasVideo && (
    <Chip backgroundColor="white" borderColor="gray13">
      1 Video
    </Chip>
  );

export const PenTotalChip = React.memo(
  ({ lotHeadCount, penHeadCount, penLotCount }) => {
    if (lotHeadCount === penHeadCount) {
      return null;
    }
    return (
      <Chip>
        {lotHeadCount} of {penHeadCount} Hd in pen ({penLotCount} Sale Lots)
      </Chip>
    );
  },
);

export const LabelChip = ({ labelId }) => {
  const label = useSelector(getLabelById(labelId));
  if (label) {
    return <Chip>{label.name}</Chip>;
  } else {
    return null;
  }
};

const ACCREDITATION = {
  // NLIS/MLA accreditation programs
  accreditationAntibioticFree: "Antibiotic Free",
  accreditationEU: "EU",
  accreditationGrassFed: "Grass Fed",
  accreditationJBAS: "JBAS",
  accreditationPCAS: "PCAS",
  accreditationPTIC: "PTIC",
  accreditationNEE: "NEE",
  // AgriNous internal flag for when a user has explicitly flagged a salelot as vendor bred
  vendorBredOverride: "Vendor Bred",
  // Sheep advanced drafting acceditations
  animalHealthStatementOverride: "AHS",
  sixInOne: "6 IN 1",
  drenched: "Drenched",
  ojdVac: "OJD Vac",
  gudairApproved: "Gudair Approved",
  b12Vac: "B12 Vac",
  backline: "Backline/Dipped",
  MAndTS: "M&TS",
  SAEligible: "SA Eligible",
  painRelief: "Pain Relief",
};

const ALL_ACCREDITATIONS = Object.keys(ACCREDITATION);

function shouldShowAccreditation(value) {
  // A string of the ALL type, or a number specifically for jbas
  return (
    value &&
    ((typeof value === "string" && value === Accreditation.ALL) ||
      typeof value !== "string")
  );
}

export const AccreditationsChips = ({ saleLotId }) => {
  const { draftingAttributes = {} } =
    useSelector(getSaleLotById(saleLotId)) || {};

  const getAccreditationChipText = (draftingAttributes, accreditationName) => {
    if (ACCREDITATION[accreditationName] === ACCREDITATION.accreditationJBAS) {
      return `JBAS: ${draftingAttributes?.[accreditationName]}`;
    } else {
      return ACCREDITATION[accreditationName];
    }
  };

  return (
    <>
      {ALL_ACCREDITATIONS.map(accreditationName =>
        shouldShowAccreditation(draftingAttributes?.[accreditationName]) ? (
          <Chip backgroundColor="successGreen" key={accreditationName}>
            {getAccreditationChipText(draftingAttributes, accreditationName)}
          </Chip>
        ) : null,
      )}
    </>
  );
};

export const FullOrPartial = ({ label, values, positive }) => {
  const isSet = values instanceof Set;
  const isArray = Array.isArray(values);

  if ((isArray && values.length === 0) || (isSet && values.size === 0)) {
    return null;
  }
  // If we have multiple entries, it's a mixed lot.
  if ((isArray && values.length > 1) || (isSet && values.size > 1)) {
    return <NLISChip>{label} Partial</NLISChip>;
  }
  // Single value, get it and report it.
  const { value } = values.values().next();
  if (value === positive) {
    return <NLISChip>{label} All</NLISChip>;
  }
  return <NLISChip>{label} None</NLISChip>;
};

export const DeliveryPenChip = ({ saleLotIds }) => {
  const deliveryPenIdBySaleLotIdLookup = useSelector(
    selectDeliveryPenIdBySaleLotIdLookup,
  );

  const auctionPensLookup = useSelector(getAuctionPens);

  const deliveryPens = React.useMemo(() => {
    return uniq(
      saleLotIds.map(
        saleLotId =>
          auctionPensLookup[deliveryPenIdBySaleLotIdLookup[saleLotId]],
      ),
    )
      .filter(Boolean)
      .map(getAuctionPenDisplayName);
  }, [saleLotIds, deliveryPenIdBySaleLotIdLookup, auctionPensLookup]);

  return (
    deliveryPens.length > 0 && (
      <Chip
        backgroundColor="deliveryPenBackground"
        tooltip={deliveryPens.length > 1 && deliveryPens.join(", ")}
      >
        {deliveryPens.length === 1
          ? `DPen ${deliveryPens[0]}`
          : `${deliveryPens.length} DPens`}
      </Chip>
    )
  );
};

export const VendorBredChip = ({ value }) => {
  let message;
  if (value === null) {
    return false;
  } else if (value === Boolean(true)) {
    message = "Ven. Bred";
  } else {
    message = `Not Ven. Bred - ${value}`;
  }
  return (
    <Chip backgroundColor="white" borderColor="gray13">
      {message}
    </Chip>
  );
};

const AngusNotVerifiedChip = ({ value }) => {
  // Only render if we have a number
  if (typeof value === "number") {
    return (
      <Chip
        backgroundColor="angusAustraliaBackground"
        color="angusAustraliaText"
      >
        {value ? `${value} Not Angus Verified` : "Angus Verified"}
      </Chip>
    );
  }
  return null;
};

export const AngusVerifiedSaleLotChip = ({ saleLotId }) => {
  const value = useSelector(getNotAngusVerifiedCountBySaleLotId(saleLotId));
  return <AngusNotVerifiedChip value={value} />;
};

export const AngusVerifiedAuctionPenChip = ({ auctionPenId }) => {
  const value = useSelector(getNotAngusVerifiedCountByPenId(auctionPenId));
  return <AngusNotVerifiedChip value={value} />;
};

export const AngusVerifiedVendorChip = ({ vendorId }) => {
  const value = useSelector(getNotAngusVerifiedCountByVendorId(vendorId));
  return <AngusNotVerifiedChip value={value} />;
};

export const AngusVerifiedEIDChip = ({ EID }) => {
  const value = useSelector(
    state => getScanByEid(EID)(state)?.animal?.is_angus_verified,
  );
  if (value === null) {
    return null;
  }
  return (
    <Chip backgroundColor="angusAustraliaBackground" color="angusAustraliaText">
      {value ? "Angus Verified" : "Not Angus Verified"}
    </Chip>
  );
};

export const StudSaleChip = ({ saleLotId }) => {
  const { draftingAttributes = {} } =
    useSelector(getSaleLotById(saleLotId)) || {};

  if (!draftingAttributes) {
    return null;
  }

  const { tagNumber, animalName } = draftingAttributes;

  return [
    tagNumber && (
      <Chip backgroundColor="successGreen" key="tagNumber">
        {`Tag # ${tagNumber}`}
      </Chip>
    ),
    animalName && (
      <Chip backgroundColor="successGreen" key="animalName">
        {animalName}
      </Chip>
    ),
  ].filter(Boolean);
};
