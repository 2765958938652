import Commands from "@agrinous/epson-escpos";
import { commandCombiner } from "@agrinous/epson-escpos/lib/utils";

import {
  getReceiptPriceString,
  getReceiptPricingTypeString,
} from "constants/pricingTypes";

import { formatSaleLotOverflow } from "lib/saleLot";

import { fieldAndValuePair, cutMark, hr, receiptHeader } from "./common";

const {
  setFontSize,
  setTabstops,
  printAndFeedLines,
  setFontMode,
  lineFeed,
  text,
  setJustification,
  FONT_MODE,
  JUSTIFICATION_MODE,
  horizontalTab,
} = Commands;

/**
 * @typedef {Object} BuyerWaySaleLotSummary
 * @property {String} overflowPenName
 * @property {Number} overflowHdCount
 * @property {Number} pricingTypeId
 * @property {Number} unitPriceCents
 * @property {Number} hdCount
 */

/**
 * @typedef {Object} BuyeyWayPenSummary
 * @property {Number} buyerWayPenCents the total value of the purchases in the Auction Pen
 * @property {Number} buyerWayPenHdCount the total number of head purchased in the Auction Pen
 * @property {String} penName the display name of the Auction Pen
 * @property {Array<BuyerWaySaleLotSummary>} saleLotSummaries a summary of the purchased Sale Lots in the Auction Pen
 * @property {number} totalPenHdCount the total number of head in the Auction Pen
 */

function getPenSummaryPrefix(penName, buyerWayPenHdCount, totalPenHdCount) {
  const partPenHdCountText =
    buyerWayPenHdCount !== totalPenHdCount ? `/${totalPenHdCount}` : "";

  return [
    setFontSize(2, 2),
    setTabstops([10, 17]),
    text(penName),
    horizontalTab(),
    text(buyerWayPenHdCount.toString()),
    setFontSize(1, 1),
    text(partPenHdCountText),
  ];
}

/**
 *
 * @param {BuyerWaySaleLotSummary} saleLotSummary
 * @param {number} defaultPricingTypeId the default pricing type for the sale
 * @returns {ArrayBuffer}
 */
function saleLotSummaryListItem(saleLotSummary, defaultPricingTypeId) {
  const {
    hdCount,
    overflowHdCount,
    overflowPenName,
    pricingTypeId,
    unitPriceCents,
  } = saleLotSummary;

  const priceText = getReceiptPriceString(
    unitPriceCents,
    pricingTypeId,
    defaultPricingTypeId,
  );

  const overflowText = formatSaleLotOverflow(overflowPenName, overflowHdCount);

  return [
    setFontSize(1, 1),
    setTabstops([20, 34]),
    text(overflowText),
    horizontalTab(),
    text(hdCount.toString()),
    horizontalTab(),
    text(priceText),
    lineFeed(),
  ];
}

function singleSaleLotPenSummary(
  penName,
  buyerWayPenHdCount,
  totalPenHdCount,
  { overflowHdCount, overflowPenName, pricingTypeId, unitPriceCents },
  defaultPricingTypeId,
) {
  const priceText = getReceiptPriceString(
    unitPriceCents,
    pricingTypeId,
    defaultPricingTypeId,
  );

  const penSummary = getPenSummaryPrefix(
    penName,
    buyerWayPenHdCount,
    totalPenHdCount,
  ).concat([horizontalTab(), text(priceText), lineFeed()]);

  if (overflowHdCount || overflowPenName) {
    const overflowText = formatSaleLotOverflow(
      overflowPenName,
      overflowHdCount,
    );
    return penSummary.concat([
      setFontSize(1, 1),
      text(overflowText),
      lineFeed(),
    ]);
  }

  return penSummary;
}

function multipleSaleLotPenSummary(
  penName,
  buyerWayPenHdCount,
  totalPenHdCount,
  saleLotSummaries,
  defaultPricingTypeId,
) {
  const penSummaryCommands = getPenSummaryPrefix(
    penName,
    buyerWayPenHdCount,
    totalPenHdCount,
  ).concat([lineFeed()]);

  return saleLotSummaries.reduce(
    (commandList, saleLotSummary) =>
      commandList.concat(
        saleLotSummaryListItem(saleLotSummary, defaultPricingTypeId),
      ),
    penSummaryCommands,
  );
}

/**
 *
 * @param {BuyeyWayPenSummary} penSummary
 * @param {number} defaultPricingTypeId the default pricing type for the sale
 * @returns {ArrayBuffer}
 */
function penSummaryListItem(penSummary, defaultPricingTypeId) {
  const { buyerWayPenHdCount, penName, saleLotSummaries, totalPenHdCount } =
    penSummary;

  if (saleLotSummaries.length === 1) {
    return singleSaleLotPenSummary(
      penName,
      buyerWayPenHdCount,
      totalPenHdCount,
      saleLotSummaries[0],
      defaultPricingTypeId,
    );
  }

  return multipleSaleLotPenSummary(
    penName,
    buyerWayPenHdCount,
    totalPenHdCount,
    saleLotSummaries,
    defaultPricingTypeId,
  );
}

/**
 *
 * @param {String} saleyardName
 * @param {Number} livestockSaleId
 * @param {number} pricingTypeId
 * @param {String} agencyName
 * @param {String} user
 * @param {String} buyerName
 * @param {String} buyerWayName
 * @param {Number} avgCents
 * @param {Array<BuyeyWayPenSummary>} penSummaries
 * @param {Number} totalHdCount
 * @param {Number} totalCents
 * @returns {ArrayBuffer}
 */
export default function buyerWayPickListTemplate(
  saleyardName,
  livestockSaleId,
  pricingTypeId,
  agencyName,
  user,
  buyerName,
  buyerWayName,
  avgCents,
  penSummaries,
  totalHdCount,
  totalCents,
) {
  let trimmedBuyerName = buyerName;
  if (buyerName.length > 18) {
    // reduce the number of characters printed, as these are printed very large on narrow paper
    trimmedBuyerName = `${buyerName.slice(0, 17)}.`;
  }

  let commandList = [
    receiptHeader(
      "Buyer Way Pick List",
      saleyardName,
      livestockSaleId,
      agencyName,
      user,
    ),
    setFontSize(2, 2),
    setTabstops([14]),
    fieldAndValuePair(trimmedBuyerName, buyerWayName || "-"),
    printAndFeedLines(3),
    setFontSize(1, 1),
    setTabstops([18, 34]),
    setFontMode(FONT_MODE.FONT_B),
    // Will only print Livestock Sale (Not Clearing Sale) Pricing Type display names
    text(`Pen\tHead Count\t${getReceiptPricingTypeString(pricingTypeId)}`),
    setFontMode(FONT_MODE.FONT_A),
    lineFeed(),
  ];

  penSummaries.forEach(penSummary => {
    commandList = commandList.concat(
      penSummaryListItem(penSummary, pricingTypeId),
      [lineFeed()],
    );
  });

  commandList = commandList.concat([
    lineFeed(),
    hr(),
    lineFeed(),
    setTabstops([2]),
    setFontSize(2, 2),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_RIGHT),
    fieldAndValuePair("Total HC", totalHdCount.toString()),
    setFontSize(1, 1),
    printAndFeedLines(2),
    fieldAndValuePair("Total Value", ` $${(totalCents / 100).toFixed(2)}`),
    lineFeed(),
    fieldAndValuePair("Average Value", ` $${(avgCents / 100).toFixed(2)}`),
    lineFeed(),
    setFontMode(FONT_MODE.FONT_B),
    text("* gross prices only, subject to change."),
    setFontMode(FONT_MODE.FONT_A),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_LEFT),
    lineFeed(),
    cutMark(),
  ]);

  return commandCombiner(commandList);
}
