import { sum } from "lodash";
import { createSelector } from "reselect";

import { SaleLotType } from "constants/saleLots";

import {
  getAuctionPens,
  getBusinesses,
  getCurrentSale,
  getLabels,
  getPenScanLots,
  getSaleLotsBySale,
  getSaleSubTypes,
  getSpecies,
  selectAgencyBySaleLotIdLookup,
  selectDeploymentIdByConsignmentIdLookup,
  selectDeploymentMarkOrderLookup,
  selectDisplayedAgentByVendorId,
  selectEidsByPenScanLotIdLookup,
  selectEidsBySaleLotIdLookup,
  selectFilteredSaleLotIds,
  selectNotAngusVerifiedCountBySaleLotIdLookup,
  selectOutOfSyncFieldNamesByBusinessId,
  selectPenScanLotIdsBySaleLotIdLookup,
  selectResolvedSaleLotCommentCountBySaleLotIdLookup,
  selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
  selectXeroBusinessesBySaleLotIdLookup,
} from "selectors";

const selectEnhancedCurrentSale = createSelector(
  [getCurrentSale, getSaleSubTypes, getSpecies],
  (sale, saleSubTypes, species) =>
    Object.assign({}, sale, {
      species: species[sale.species_id],
      saleSubType: saleSubTypes[sale.sale_sub_type_id],
    }),
);

export const selectGeneralSaleLotsAggridData = createSelector(
  [
    getSaleLotsBySale,
    selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
    selectResolvedSaleLotCommentCountBySaleLotIdLookup,
    selectAgencyBySaleLotIdLookup,
    selectDisplayedAgentByVendorId,
    selectNotAngusVerifiedCountBySaleLotIdLookup,
    getLabels,
    selectFilteredSaleLotIds,
    getBusinesses,
    selectOutOfSyncFieldNamesByBusinessId,
    selectXeroBusinessesBySaleLotIdLookup,
    selectPenScanLotIdsBySaleLotIdLookup,
    getPenScanLots,
    getAuctionPens,
    selectEidsByPenScanLotIdLookup,
    selectEidsBySaleLotIdLookup,
    selectEnhancedCurrentSale,
    selectDeploymentMarkOrderLookup,
    selectDeploymentIdByConsignmentIdLookup,
  ],
  (
    saleLots,
    unresolvedSaleLotCommentCountBySaleLotIdLookup,
    resolvedSaleLotCommentCountBySaleLotIdLookup,
    agencyBySaleLotIdLookup,
    relationshipAgent,
    notAngusVerifiedCountBySaleLotIdLookup,
    labelsLookup,
    filteredSaleLotIds,
    businesses,
    outOfSyncByBusinessIdLookup,
    xeroBusinessesBySaleLotIdLookup,
    penScanLotIdsBySaleLotId,
    penScanLotByIdLookup,
    auctionPens,
    eidsByPenScanLotIdLookup,
    eidsBySaleLotIdLookup,
    currentSale,
    deploymentMarkOrderLookup,
    deploymentIdByConsignmentIdLookup,
  ) =>
    saleLots
      .filter(
        saleLot =>
          filteredSaleLotIds.includes(saleLot.id) &&
          saleLot.saleLotType !== SaleLotType.VENDOR_SPLIT,
      )
      .map(saleLot => {
        const { buyer = {}, vendor = {}, id: saleLotId } = saleLot;
        const xeroBusinesses = xeroBusinessesBySaleLotIdLookup[saleLot.id];
        const vendorId = vendor?.id;
        const relationshipAgentText = relationshipAgent[vendorId];
        const penScanLots = penScanLotIdsBySaleLotId[saleLot.id]?.map(
          penScanLotId => {
            const penScanLot = penScanLotByIdLookup[penScanLotId];
            const sellingPen = auctionPens[penScanLot?.sellingPenId];
            const scansCount =
              eidsByPenScanLotIdLookup[penScanLotId]?.length || 0;
            return Object.assign(
              {
                title: `${sellingPen?.start_pen} ${scansCount} / ${penScanLot?.quantity}`,
              },
              penScanLotByIdLookup?.[penScanLotId],
            );
          },
        );

        const saleLotPenScanLotIds = penScanLotIdsBySaleLotId[saleLotId] || [];

        const saleLotHasPenScanLots = !!saleLotPenScanLotIds?.length;

        const saleLotHasMoreThanOnePenScanLot = saleLotPenScanLotIds.length > 1;

        const saleLotEids = eidsBySaleLotIdLookup[saleLotId] || [];

        const saleLotEidsCount = saleLotEids.length;

        const saleLotPenScanLotEidsCount = sum(
          saleLotPenScanLotIds.map(
            penScanLotId => eidsByPenScanLotIdLookup[penScanLotId].length,
          ),
        );

        const saleLotQuantityDoesNotEqualSaleLotEidsCount =
          saleLot.quantity !== saleLotEidsCount;

        let rowWarning = null;

        // if the sale lot is associated to more than one pen scan lot
        // or the amount of sale lot eids is not equal to the the count
        // of pen scan lot eids

        if (saleLotHasPenScanLots) {
          if (saleLotHasMoreThanOnePenScanLot) {
            rowWarning = "Sale Lot has more than one Pen Scan Lot";
          } else if (saleLotEidsCount !== saleLotPenScanLotEidsCount) {
            rowWarning =
              "Sale Lot Scans count does not equal Pen Scan Lot Scans count";
          } else if (saleLotQuantityDoesNotEqualSaleLotEidsCount) {
            rowWarning =
              "Sale Lot Scans count does not equal Sale Lot hd count";
          }
        }

        const deploymentId =
          deploymentIdByConsignmentIdLookup[saleLot.consignment_id];

        const marksWithOrder = saleLot.marks.map(mark => ({
          ...mark,
          order: deploymentMarkOrderLookup[deploymentId]?.[mark.location],
        }));

        return Object.assign({}, saleLot, {
          marks: marksWithOrder,
          agency: agencyBySaleLotIdLookup[saleLot.id],
          invoiceToBusiness: businesses[saleLot.invoiceToBusinessId] || {},
          notAngusVerifiedCount:
            notAngusVerifiedCountBySaleLotIdLookup[saleLot.id],
          labels: saleLot.labels?.map(id => labelsLookup[id]?.name || "") || [],
          livestockSale: currentSale,
          relationshipAgent: relationshipAgentText,
          resolvedSaleLotCommentCount:
            resolvedSaleLotCommentCountBySaleLotIdLookup[saleLot.id],
          unresolvedSaleLotCommentCount:
            unresolvedSaleLotCommentCountBySaleLotIdLookup[saleLot.id],
          vendor,
          xeroBuyer: xeroBusinesses.buyer,
          xeroVendor: xeroBusinesses.vendor,
          vendorDataOutOfSync: outOfSyncByBusinessIdLookup[vendor?.id],
          buyerDataOutOfSync: outOfSyncByBusinessIdLookup[buyer?.id],
          penScanLots,
          rowWarning,
        });
      }),
);
