import React from "react";

import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

export const ExclamationIcon = styled(
  ({ fontSize: ignored, color: ignored2, ...iconAttrs }) => (
    <FontAwesomeIcon icon={faExclamationCircle} {...iconAttrs} />
  ),
)(
  ({ theme, color = "warningOrange", fontSize }) => `
        color: ${theme.colors[color]};
        ${
          typeof fontSize === "number"
            ? `font-size: ${theme.fontSizes[fontSize]}px`
            : ""
        };
    `,
);
