import { flatten, isEmpty, sortBy, uniq } from "lodash";
import { createSelector } from "reselect";

import { AdvancedDraftingValidationStates } from "constants/draftingAttributes";
import { SystemSaleyards } from "constants/sale";

import { EMPTY_ARRAY, EMPTY_OBJECT } from "lib";

import { advancedDraftingValidation } from "lib/advancedDraftingValidation";
import {
  agencyLogoLookup,
  agrinousLogo,
  baseImageURL,
  deploymentLocationString,
  isLocalEnvironment,
} from "lib/deployments";
import { getSaleyardName } from "lib/navigation";

import {
  getActiveRole,
  getAgencies,
  getBusinesses,
  getConsignableSaleById,
  getConsignments,
  getCurrentDeploymentSalesList,
  getDeployments,
  getRounds,
  getSaleById,
  getSaleLots,
  selectCurrentDeploymentSales,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";

import { getCurrentSale } from "./currentSale";
import { getIsOnline } from "./root";

export const selectNameByDeploymentIdLookup = createSelector(
  [getDeployments, getAgencies],
  (deploymentsById, agenciesById) =>
    Object.entries(deploymentsById).reduce(
      (acc, [deploymentId, deployment]) => {
        acc[deploymentId] =
          deployment.name ||
          `${
            agenciesById[deployment.livestockAgencyId]?.shortName || ""
          }: ${deploymentLocationString(deployment)}`;
        return acc;
      },
      {},
    ),
);

export const getDeploymentNameByDeploymentId = deploymentId => state =>
  selectNameByDeploymentIdLookup(state)[deploymentId] || null;

// Fetch all deployments available, excluding any that are private/hooks/system only.
export const selectDeploymentOptions = createSelector(
  [getAgencies, getDeployments, selectNameByDeploymentIdLookup],
  (agencies, deployments, deploymentNameByDeploymentIdLookup) =>
    sortBy(
      Object.values(deployments)
        .filter(deployment =>
          deployment.locations.some(
            location => !SystemSaleyards.includes(location.name),
          ),
        )
        .map(deployment => ({
          label: deploymentNameByDeploymentIdLookup[deployment.id],
          value: deployment.id,
        })),
      ["label"],
    ),
);

// Fetch all deployments I can see and pull the saleyards out - this is functionally all the saleyards I can consign to.
export const selectConsignableSaleyardNameLookup = createSelector(
  [getDeployments],
  deployments =>
    Object.values(deployments).reduce((acc, deployment) => {
      deployment.locations.forEach(saleyard => {
        acc[saleyard.saleyard_id] = saleyard.name;
      });
      return acc;
    }, {}),
);

export const selectConsignableSaleyardsAsOptions = createSelector(
  [selectConsignableSaleyardNameLookup],
  consignableSaleyardNameLookup =>
    sortBy(
      Object.entries(consignableSaleyardNameLookup).map(([value, label]) => ({
        value: parseInt(value, 10),
        label,
      })),
      "label",
    ),
);

export const selectCurrentDeploymentIds = createSelector(
  [getCurrentDeploymentSalesList],
  deploymentsList =>
    deploymentsList?.map(deployment => deployment.deployment_id) || EMPTY_ARRAY,
);

export const selectShortCodeSaleYardByBusinessIdLookup = createSelector(
  [getBusinesses],
  businesses =>
    Object.entries(businesses).reduce((acc, [businessId, business]) => {
      acc[businessId] = business.shortCodeSaleyard || null;
      return acc;
    }, {}),
);

export const selectCanSeeOtherDeployments = createSelector(
  [getDeployments],
  deployments => Object.keys(deployments).length > 1,
);

export const getDeploymentById = deploymentId => state =>
  getDeployments(state)[deploymentId] || null;

export const getNameByDeploymentId = deploymentId => state =>
  selectNameByDeploymentIdLookup(state)[deploymentId] || "";

export const selectDeploymentByDeploymentSaleIdLookup = createSelector(
  [getDeployments, getCurrentDeploymentSalesList],
  (deployments, deploymentSales) =>
    deploymentSales?.reduce((result, deploymentSale) => {
      result[deploymentSale.deployment_sale_id] =
        deployments[deploymentSale.deployment_id];
      return result;
    }, {}) || EMPTY_OBJECT,
);

export const getDeploymentByDeploymentSaleId = deploymentId => state =>
  selectDeploymentByDeploymentSaleIdLookup(state)[deploymentId] || {};

export const selectDeploymentCodeByConsignmentIdLookup = createSelector(
  [selectDeploymentByDeploymentSaleIdLookup, getConsignments],
  (deployments, consignments) =>
    Object.values(consignments).reduce((result, consignment) => {
      result[consignment.id] = deployments[consignment.deployment_sale]?.code;
      return result;
    }, {}),
);

export const selectDeploymentsByAgencyIdLookup = createSelector(
  [getAgencies, getDeployments],
  (agencies, deployments) =>
    Object.values(agencies).reduce((acc, agency) => {
      acc[agency.id] = Object.values(deployments).filter(
        d => d.livestockAgencyId === agency.id,
      );
      return acc;
    }, {}),
);

export const selectDeploymentCodeByAgencyIdLookup = createSelector(
  [getAgencies, getDeployments],
  (agencies, deployments) =>
    Object.values(agencies).reduce((acc, agency) => {
      acc[agency.id] =
        Object.values(deployments).find(d => d.livestockAgencyId === agency.id)
          ?.code || "";
      return acc;
    }, {}),
);
// This is a bit of a misnomer, but required for the create business as a SY
// admin - if ANY deployment within the agency can add business relations,
// allow it for 'all' deployments.
// Generally a SY admin should only SEE one deployment per yard (business set)
const selectAgencyAllowsBusinessRelationsByAgencyIdLookup = createSelector(
  [selectDeploymentsByAgencyIdLookup],
  deploymentsByAgencyIdLookup =>
    Object.entries(deploymentsByAgencyIdLookup).reduce(
      (acc, [agencyId, deployments]) => {
        acc[agencyId] = deployments.some(d => d.allowBusinessRelations);
        return acc;
      },
      {},
    ),
);

export const getAgencyAllowsBusinessRelationsByAgencyId = agencyId => state =>
  selectAgencyAllowsBusinessRelationsByAgencyIdLookup(state)[agencyId] || false;

export const selectIntegrationErrorsOrStatusBySaleLotIdLookup = createSelector(
  [selectCurrentDeploymentSales, getSaleLots, getConsignments, getRounds],
  (currentDeploymentSales, saleLots, consignments, rounds) => {
    return Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      acc[saleLotId] = advancedDraftingValidation(
        saleLot,
        currentDeploymentSales,
        consignments,
        rounds,
      );
      return acc;
    }, {});
  },
);

export const getIntegrationErrorsOrStatusBySaleLotId = saleLotId => state =>
  selectIntegrationErrorsOrStatusBySaleLotIdLookup(state)[saleLotId] || {};

export const selectIntegrationErrorsByAuctionPenIdLookup = createSelector(
  [
    selectIntegrationErrorsOrStatusBySaleLotIdLookup,
    selectSaleLotIdsByAuctionPenIdLookup,
  ],
  (integrationInfo, saleLotIdsByAuctionPenId) => {
    return Object.entries(saleLotIdsByAuctionPenId).reduce(
      (acc, [auctionPenId, saleLotIds]) => {
        const auctionPenExportSites = uniq(
          flatten(saleLotIds.map(id => Object.keys(integrationInfo[id]))),
        );

        acc[auctionPenId] = auctionPenExportSites.reduce(
          (sites, exportSite) => {
            const siteStatuses = saleLotIds.map(
              s => integrationInfo[s][exportSite],
            );
            let disabledCount = 0;

            // If all lots have the same status, we have an exact match, set it.
            const exactMatch = Object.values(
              AdvancedDraftingValidationStates,
            ).find(status =>
              siteStatuses.every(saleLotStatus => saleLotStatus === status),
            );

            if (exactMatch) {
              sites[exportSite] = {
                status: exactMatch,
                disabledCount,
              };
              return sites;
            }

            // If its not an exact match, get the count of not exported sale lots so we can
            // populate the badge on the icon.
            disabledCount = siteStatuses.filter(
              status => status === AdvancedDraftingValidationStates.DISABLED,
            ).length;

            // Otherwise, ignore the disabled lots and try to find an exact match.
            const exactMatchExcludingDisabled = Object.values(
              AdvancedDraftingValidationStates,
            ).find(status =>
              siteStatuses.every(saleLotStatus =>
                [status, AdvancedDraftingValidationStates.DISABLED].includes(
                  saleLotStatus,
                ),
              ),
            );

            if (exactMatchExcludingDisabled) {
              sites[exportSite] = {
                status: exactMatchExcludingDisabled,
                disabledCount,
              };
              return sites;
            }

            sites[exportSite] = {
              status: AdvancedDraftingValidationStates.WARNING,
              disabledCount,
            };
            return sites;
          },
          {},
        );

        return acc;
      },
      {},
    );
  },
);

export const getIntegrationErrorsByAuctionPenId = auctionPenId => state =>
  selectIntegrationErrorsByAuctionPenIdLookup(state)[auctionPenId] || {};

const selectCurrentDeploymentsBySaleyardName = createSelector(
  [getDeployments],
  deployments => {
    // I should only have one deployment for the current (or any) saleyard, but
    // if I have multiple, it will grab the first (which isn't really more or less important, just, first).
    return Object.values(deployments).reduce((acc, deployment) => {
      deployment.locations.forEach(saleyard => {
        if (!acc[saleyard.name]) {
          acc[saleyard.name] = deployment;
        }
      });
      return acc;
    }, {});
  },
);

// WARNING - do not use this for permission checks - it's actually pretty redundant.
// We should be using getActiveLivestockAgentDeployment
export const getCurrentDeployment = state =>
  selectCurrentDeploymentsBySaleyardName(state)[getSaleyardName()] ||
  EMPTY_OBJECT;

export const getCurrentDeploymentMasterBusinesses = createSelector(
  [selectCurrentDeploymentIds, getBusinesses],
  (deploymentIds, businesses) =>
    Object.values(businesses).reduce((acc, business) => {
      if (deploymentIds.includes(business.businessDeploymentId)) {
        acc[business.businessDeploymentId] = business;
      }
      return acc;
    }, {}),
);

export const getActiveLivestockAgentDeployment = createSelector(
  [getActiveRole, getDeployments],
  (activeRole, deployments) => {
    // Livestock Agents should only have one deployment attached to their role.
    const activeRoleDeploymentId = activeRole?.deployments?.[0]?.id;
    return deployments[activeRoleDeploymentId] || EMPTY_OBJECT;
  },
);

export function getSellingDeploymentIdByLivestockSaleId(livestockSaleId) {
  return state => {
    // Try to find from my sales first.
    const deploymentSales =
      getSaleById(livestockSaleId)(state)?.deployment_sales;
    if (!isEmpty(deploymentSales)) {
      return deploymentSales[0].deployment_id;
    }

    // Then try consignable sales
    const consignableDeploymentSales =
      getConsignableSaleById(livestockSaleId)(state)?.deploymentSales;
    if (!isEmpty(consignableDeploymentSales)) {
      return consignableDeploymentSales[0].deployment_id;
    }
    return null;
  };
}

export function getConsigningDeploymentIdByLivestockSaleId(livestockSaleId) {
  return state => {
    const saleyardId =
      getSaleById(livestockSaleId)(state)?.saleyard_id ||
      getConsignableSaleById(livestockSaleId)(state).saleyardId;
    const deployments = getDeployments(state);

    const saleyardDeployments = Object.values(deployments).filter(deployment =>
      deployment.locations.filter(
        saleyard => saleyard.saleyard_id === saleyardId,
      ),
    );

    // Get the UserRole's first Deployment which can operate in the yard which the livestock sale is in
    const activeRole = getActiveRole(state);
    if (!activeRole || !activeRole.deployments) {
      return null;
    }
    const deployment = activeRole.deployments?.find(({ id: deploymentId }) =>
      saleyardDeployments.find(
        candidateDeployment => candidateDeployment.id === deploymentId,
      ),
    );

    if (deployment) {
      return deployment.id;
    }

    // The UserRole is not aware of any other Deployments at this SaleYard, which means they ARE the consigning Deployment
    return activeRole.deployments[0].id;
  };
}

export const selectDeploymentPlacardLogoUrlByDeploymentId = createSelector(
  [getDeployments, getCurrentSale, getIsOnline, getAgencies],
  (deployments, sale, isOnline, agencies) =>
    Object.values(deployments).reduce((acc, deployment) => {
      let placardLogo = agrinousLogo;
      if (isLocalEnvironment) {
        placardLogo = agrinousLogo;
      } else if (!isOnline) {
        const agencyShortCode =
          agencies[deployment.livestockAgencyId]?.shortName;
        if (agencyLogoLookup?.[agencyShortCode]) {
          placardLogo = `data:image/jpeg;base64,${agencyLogoLookup?.[agencyShortCode]}`;
        }
      } else if (deployment?.placards_logo_url) {
        // This is backwards compared to the how logos are used in the backend
        // This is because we want the deployment logo on the placards at highest priority
        // so it can be shown when the placard is displayed in a saleyard.
        placardLogo = `${baseImageURL}${deployment.placards_logo_url}`;
      } else if (sale?.placard_logo_url) {
        placardLogo = `${baseImageURL}${sale.placard_logo_url}`;
      }

      acc[deployment.id] = placardLogo;
      return acc;
    }, {}),
);
