import { put, takeEvery } from "redux-saga/effects";

import { sendMessage, updateDeviceProtocolStatus } from "actions";

import {
  TLABPLC_HANDSHAKE,
  TLABPLC_DRAFTING_QUERY,
  TLABPLC_DRAFTING_DECISION,
  TLABPLC_DRAFTING_RESET,
} from "constants/actionTypes";
import { PLCProtocolStatus } from "constants/singleWeigh";

import { draftingDecisionRequest, handshakeRequest } from "lib/deviceDrivers";

function* handleHandshakeRequest({ deviceId }) {
  // TODO - pull the unitNumber out of settings.

  yield put(sendMessage(deviceId, handshakeRequest(1)));

  yield put(updateDeviceProtocolStatus(deviceId, PLCProtocolStatus.CONNECTING));
}

function* handleHandshakeSuccess({ deviceId }) {
  yield put(updateDeviceProtocolStatus(deviceId, PLCProtocolStatus.CONNECTED));
}

function* handleQueryDraftingDecision({ deviceId }) {
  yield put(
    updateDeviceProtocolStatus(
      deviceId,
      PLCProtocolStatus.WAITING_FOR_DRAFTING_DECISION,
    ),
  );
}

function* handleDraftingDecision({ deviceId, decision }) {
  // Send the message to the PLC.
  yield put(sendMessage(deviceId, draftingDecisionRequest(decision)));

  yield put(updateDeviceProtocolStatus(deviceId, PLCProtocolStatus.CONNECTED));
}

export default function* rootSaga() {
  yield takeEvery(TLABPLC_HANDSHAKE.REQUEST, handleHandshakeRequest);
  yield takeEvery(TLABPLC_DRAFTING_RESET.SUCCESS, handleHandshakeSuccess);
  yield takeEvery(TLABPLC_DRAFTING_QUERY.REQUEST, handleQueryDraftingDecision);
  yield takeEvery(TLABPLC_DRAFTING_DECISION.REQUEST, handleDraftingDecision);
}
