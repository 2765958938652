import { useCallback } from "react";

import { getIn, useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { getDefaultPropertyId } from "lib/properties";

import { getBusinessById, getCurrentSaleyardId } from "selectors";

import { useFieldState, useFieldValue } from "hooks/useFormik";

export function useFormikSetDefaultPropertyOnBuyerWayChanged(
  buyerIdFieldName,
  propertyIdFieldName,
) {
  const [propertyId, setPropertyId] = useFieldState(propertyIdFieldName);
  const buyerId = useFieldValue(buyerIdFieldName);
  const buyer = useSelector(getBusinessById(buyerId));
  const saleyardId = useSelector(getCurrentSaleyardId);

  const { touched, initialValues } = useFormikContext();

  return useCallback(
    buyerWay => {
      const buyerWayName = buyerWay?.name || null;

      const defaultPropertyId = getDefaultPropertyId(
        buyer,
        saleyardId,
        buyerWayName,
      );

      const isTouched = getIn(touched, propertyIdFieldName);
      const hasInitialValue = getIn(initialValues, propertyIdFieldName);
      if (
        // The user hasn't made any changes to the destination property and there wasn't one set in the first place
        ((!isTouched && !hasInitialValue) ||
          // The user had made a change to the destination property and that change was clearing it out
          (isTouched && !propertyId)) &&
        // the default property is different to the current destination property
        defaultPropertyId !== propertyId
      ) {
        setPropertyId(defaultPropertyId);
      }
    },
    [
      buyer,
      initialValues,
      propertyId,
      propertyIdFieldName,
      saleyardId,
      setPropertyId,
      touched,
    ],
  );
}
