import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { patchSaleLot } from "actions";

import { BaseTable } from "components/Importer/BaseTable";
import { saleLotColumns } from "components/Importer/columnDefinitions";

import { getSaleLots, selectSaleLotIdByLotNumberLookup } from "selectors";

export function LotImporter({ importLots }) {
  const saleLotIdsByLotNumberLookup = useSelector(
    selectSaleLotIdByLotNumberLookup,
  );
  const agrinousLots = useSelector(getSaleLots);

  const dispatch = useDispatch();

  const updateLot = data => {
    const patch = {
      lot_number: data.number,
      id: agrinousLots[data.agrinousLotNumber.value].id,
    };
    dispatch(patchSaleLot(patch));
  };

  const lotOptions = useMemo(
    () =>
      Object.values(agrinousLots).map(lot => ({
        label: `${lot.lot_number}: ${lot.clearingSaleAttributes?.title}`,
        value: lot.id,
      })),
    [agrinousLots],
  );

  const lotData = importLots.map(importLot => ({
    ...importLot,
    agrinousLot: agrinousLots[saleLotIdsByLotNumberLookup[importLot.number]],
    agrinousLotNumber: {
      label:
        agrinousLots[saleLotIdsByLotNumberLookup[importLot.number]]?.lot_number,
      value: saleLotIdsByLotNumberLookup[importLot.number],
    },
  }));

  const onCellValueChanged = e => {
    // Fired when a option is selected to merge in the selected attributes data into the rowdata.
    const selectedLot = agrinousLots?.[e.newValue.value];
    e.node.setData({
      ...e.node.data,
      agrinousLot: selectedLot,
      agrinousLotNumber: {
        label: selectedLot?.lot_number,
        value: selectedLot?.id,
      },
    });
    e.api.redrawRows(e.node);
  };

  return (
    <BaseTable
      columns={saleLotColumns(lotOptions)}
      data={lotData}
      onCellValueChanged={onCellValueChanged}
      context={{
        update: updateLot,
      }}
    />
  );
}
