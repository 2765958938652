import React from "react";

import { useDispatch } from "react-redux";

import { EnvdDocumentAction } from "actions/envd";

import { EnvdDocuments } from "components/Importer/eNVD/EnvdDocuments";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";

import { useMountEffect } from "hooks";

function ENVDDocumentImporter() {
  const dispatch = useDispatch();

  useMountEffect(() => {
    dispatch(EnvdDocumentAction.request());
  });

  return (
    <WaitForSync requiredData={[ApiModel.ENVD_DOCUMENTS]}>
      <Row fullWidth fullHeight flexGrow>
        <EnvdDocuments />
      </Row>
    </WaitForSync>
  );
}

export default ENVDDocumentImporter;
