import React from "react";

import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import HashRoute from "components/HashRoute";
import {
  CattlePenCard,
  PenWeighCardLandscape,
  PenWeighCardPortrait,
} from "components/Pdfs";
import SplitSaleLotModal from "components/SplitLotModal/Adapter";

import { ModalTypes } from "constants/navigation";

import AuctionPenSale from "containers/AuctionPenSale";
import { LandScape, Portrait } from "containers/Pdfs/BookingCard";
import CheckingCard from "containers/Pdfs/CheckingCard";
import DeliveryCard from "containers/Pdfs/DeliveryCard";
import {
  CattleSpottingCard,
  SheepSpottingCard,
} from "containers/Pdfs/SpottingCard";

import { getSaleyardAuctionRoute } from "lib/navigation";

import { currentSaleSelector } from "selectors";

export const AuctionRoutes = () => {
  const { saleyard_id: saleyardId } = useSelector(currentSaleSelector);
  return (
    <>
      <Switch>
        <Route
          exact
          path={getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
            ":auctionPenId",
          )}
          component={AuctionPenSale}
        />
        <Route
          exact
          path={getSaleyardAuctionRoute(":saleyard", ":saleId", ":roundId")}
          component={AuctionPenSale}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/bookingcardportrait/:agentId?`}
          component={Portrait}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/bookingcardlandscape/:version/:agentId?`}
          component={LandScape}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/bookingcardspacedportrait/:version/:agentId?`}
          component={Portrait}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/bookingcardspacedlandscape/:version/:agentId?`}
          component={LandScape}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/penweighcardportrait/:agentId?`}
          component={PenWeighCardPortrait}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/penweighcardlandscape/:agentId?`}
          component={PenWeighCardLandscape}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/deliverycard/:agentId?`}
          component={DeliveryCard}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/checkingcard/:agentId?`}
          component={CheckingCard}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
            ":auctionPenId",
          )}/cattlepencard/:agentId?`}
          component={CattlePenCard}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/sheepspottingcard/:agentId?`}
          component={SheepSpottingCard}
        />
        <Route
          exact
          path={`${getSaleyardAuctionRoute(
            ":saleyard",
            ":saleId",
            ":roundId",
          )}/cattlespottingcard/:agentId?`}
          component={CattleSpottingCard}
        />
      </Switch>

      <HashRoute
        component={SplitSaleLotModal}
        hash={ModalTypes.SplitSaleLot}
        componentProps={{ saleyardId }}
      />
    </>
  );
};
