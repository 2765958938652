import React, { memo } from "react";

import { isEqual } from "lodash";
import PropTypes from "prop-types";

import ScanRow from "./ScanRow";

export function ScanRowListComponent(props) {
  const {
    destinationConsignmentId,
    disallowTaken,
    eidsOrNlisIds = [],
    onKeep,
    onIgnore,
    onEdit,
    onSelect,
    showDetailed,
    showDuplicate,
    showEdit,
    showIgnore,
    showKeep,
    showSelect,
    isArrivalOrPenScanning,
    penType,
    scanLotId,
  } = props;

  return (
    <>
      {eidsOrNlisIds.map(eidOrNlisId => (
        <ScanRow
          key={eidOrNlisId}
          eidOrNlisId={eidOrNlisId}
          onKeep={onKeep}
          onIgnore={onIgnore}
          onEdit={onEdit}
          onSelect={onSelect}
          showDuplicate={showDuplicate}
          showEdit={showEdit}
          showIgnore={showIgnore}
          showKeep={showKeep}
          showSelect={showSelect}
          disallowTaken={disallowTaken}
          destinationConsignmentId={destinationConsignmentId}
          isExpanded={showDetailed}
          isArrivalOrPenScanning={isArrivalOrPenScanning}
          penType={penType}
          scanLotId={scanLotId}
        />
      ))}
    </>
  );
}

ScanRowListComponent.propTypes = {
  destinationConsignmentId: PropTypes.string,
  disallowTaken: PropTypes.bool,
  eidsOrNlisIds: PropTypes.arrayOf(PropTypes.string),
  onKeep: PropTypes.func,
  onIgnore: PropTypes.func,
  onEdit: PropTypes.func,
  onSelect: PropTypes.func,
  showDetailed: PropTypes.bool,
  showDuplicate: PropTypes.bool,
  showEdit: PropTypes.bool,
  showIgnore: PropTypes.bool,
  showKeep: PropTypes.bool,
  showSelect: PropTypes.bool,
};

export default memo(ScanRowListComponent, isEqual);
