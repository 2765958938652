import React from "react";
import { Grid } from "@material-ui/core";
import { BulkReportFormProps } from "./BulkReportFormCommon";

export function ReportNotSupportedForm(
  props: BulkReportFormProps,
): React.JSX.Element {
  const { reportSlug } = props;
  return (
    <Grid item xs={12}>
      Report type &quot;{reportSlug}&quot; does not yet support generating
      multiple reports.
    </Grid>
  );
}
