export const isMobileSafariType = () =>
  [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes("Mac") && "ontouchend" in document);

// Whether the PWA is running embedded in native iOS app web view component
export const isIOSApp = () => window.webkit?.messageHandlers?.hub;

// Whether the PWA is running embedded in native mobile app web view component
export const isNativeAppWebView = isIOSApp;
