import React, { memo } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import MultiButton from "components/Button/MultiButton";
import { StatusCard } from "components/Card";
import { CardGrid, CardMetaItem } from "components/Card/Elements";

import { pluralize } from "lib/pluralize";

import { getBusinessById } from "selectors";

import DuplicateBusinessSuggestion from "./DuplicateBusinessSuggestion";

const SuggestionsEntriesContainer = styled.div`
  padding-left: 12px;
  margin-left: 24px;
  border-left: 2px solid black;
`;

function DuplicateListingComponent(props) {
  const {
    businessId,
    dismissDuplicate,
    dismissDuplicates,
    duplicates,
    reviewDuplicate,
  } = props;

  const { name = "" } = useSelector(getBusinessById(businessId)) || {};

  function onClickDismissAllDuplicates() {
    dismissDuplicates(businessId);
  }

  return (
    <>
      <StatusCard status="primary">
        <CardGrid
          areas={[["businessName", "businessName", "businessName", "actions"]]}
        >
          <CardMetaItem gridArea="businessName" left start>
            <h4>
              {name} ({duplicates.length} possible{" "}
              {pluralize("duplicate", duplicates.length)})
            </h4>
          </CardMetaItem>
          <CardMetaItem gridArea="actions" right start>
            <MultiButton
              buttons={[
                { onClick: onClickDismissAllDuplicates, title: "Dismiss All" },
              ]}
            />
          </CardMetaItem>
        </CardGrid>
      </StatusCard>
      <SuggestionsEntriesContainer>
        {duplicates.map((duplicate, index) => (
          <DuplicateBusinessSuggestion
            key={`${index}_${duplicate.relatedMasterBusinessId}`}
            businessId={duplicate.relatedMasterBusinessId}
            dismissDuplicate={dismissDuplicate}
            duplicateValues={duplicate.duplicateValues}
            mergeIntoBusinessId={businessId}
            reviewDuplicate={reviewDuplicate}
          />
        ))}
      </SuggestionsEntriesContainer>
      <hr />
    </>
  );
}
export default memo(DuplicateListingComponent);
