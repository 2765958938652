import React from "react";

import omitBy from "lodash/omitBy";

import { SaleLotModalTypes } from "components/SaleLotForms/constants";
import { ExternalAgentSaleModal } from "components/SaleLotForms/ExternalAgentSale";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks/useModalAdapter";

const FormTypeComponentMap = {
  [SaleLotModalTypes.EXTERNAL_AGENT_SALE]: ExternalAgentSaleModal,
};

const FormDialogDefaultsMap = {
  [SaleLotModalTypes.EXTERNAL_AGENT_SALE]: ({
    id,
    vendor_id,
    buyer_id,
    pricing_type_id,
    breed_id,
    sex_id,
    age_id,
    round_id,
  }) =>
    omitBy(
      {
        id,
        vendor_id,
        buyer_id,
        pricing_type_id,
        breed_id,
        sex_id,
        age_id,
        round_id,
      },
      v => v === undefined,
    ),
};

export const SaleLotModalAdapter = props => {
  const [hashParams, _ignoredReturnTo, onClose] = useModalAdapter(
    ModalTypes.SaleLot,
    props,
  );
  if (!hashParams.type) {
    return null;
  }

  const ModalComponent = FormTypeComponentMap[hashParams.type];
  const formDialogDefaults = FormDialogDefaultsMap[hashParams.type](hashParams);
  return (
    <ModalComponent
      onClose={onClose}
      formDialogDefaults={formDialogDefaults}
      key={hashParams.id || hashParams.key}
    />
  );
};
