import reduceReducers from "reduce-reducers";

import { GLOBAL_BUSINESSES } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeGlobalBusinesses } from "lib/serializers/businesses";

const fetch = apiModelOfflineFetchReducer(GLOBAL_BUSINESSES, {
  deserializer: deserializeGlobalBusinesses,
  clearOnRequest: false,
});

const globalBusinessesReducer = reduceReducers(fetch);

export default globalBusinessesReducer;
