import React from "react";

import styled from "styled-components/macro";

import { OutlineButton } from "components/Button";
import LargeCard from "components/Card/LargeCard";
import { ExternalLink } from "components/Link";

import { openNewAuthBackend } from "lib/navigation";

import LogoIcon from "img/AgriNous-icon";

const Container = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.gray95};
  padding-left: ${({ theme }) => theme.space[2]}px;
  padding-right: ${({ theme }) => theme.space[2]}px;
  font-size: 16px;
`;
const FormSection = styled.section`
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.space[3]}px;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints[0]}px) {
    padding-top: 10.125rem;
  }
  ${LargeCard} {
    @media only screen and (min-width: ${({ theme }) =>
        theme.breakpoints[0]}px) {
      width: 27rem;
    }
  }
`;
const Terms = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
  line-height: ${({ theme }) => theme.fontSizes.beta}px;
  margin-top: ${({ theme }) => theme.space[3]}px;
  color: ${({ theme }) => theme.colors.gray7A};
  text-align: center;
`;
Terms.displayName = "Terms";
const InlineLink = styled(ExternalLink)`
  display: inline;
  text-decoration: underline;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.gray7A};
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
`;
InlineLink.displayName = "InlineLink";
const LoginColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space[2]}px;
`;

const Title = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const LoginCard = ({
  children,
  showForgotten,
  title = "Login to AgriNous",
  disableLoginButton = false,
}) => (
  <Container>
    <FormSection>
      <LargeCard>
        <LoginColumn>
          <Title>
            <LogoIcon />
            {title || ""}
          </Title>
          {children}
          {showForgotten && (
            <ExternalLink
              href="/auth/password_reset/"
              style={{ textAlign: "center" }}
            >
              Forgot your password?
            </ExternalLink>
          )}
          <Terms>
            By logging in you agree to the{" "}
            <InlineLink
              href="https://agrinous.com/terms-of-service/"
              target="_blank"
            >
              AgriNous Terms of Service
            </InlineLink>
          </Terms>
          <OutlineButton
            color="primary"
            onClick={openNewAuthBackend}
            style={{ marginTop: "1rem", height: "48px" }}
            disabled={disableLoginButton}
          >
            Other Login Methods
          </OutlineButton>
        </LoginColumn>
      </LargeCard>
    </FormSection>
  </Container>
);
