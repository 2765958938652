import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { IntegrationBusinessAction } from "actions";

import { IntegrationCredentialAction } from "actions/integrationCredentials";

import AgGrid from "components/AgGrid/AgGrid";
import { RowSelectionMode } from "components/AgGrid/constants";
import { ModalTableWrapper } from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import MessageBox from "components/MessageBox";

import { AgGridTables } from "constants/aggrid";
import { IntegrationTypes } from "constants/integrations";
import { ApiModel } from "constants/loading";
import { DeploymentPermissions } from "constants/permissions";

import {
  openCreateIntegrationBusinessModal,
  openHelpAndSupport,
  openIntegrationSettings,
} from "lib/navigation";

import {
  getActiveLivestockAgentDeployment,
  getBusinessById,
  selectIntegrationBusinessesList,
  selectIntegrationCredentialIdByTypeAndDeploymentIdLookup,
} from "selectors";

import { useCloseDialog, useDebounceSelector, useMountEffect } from "hooks";
import { useHasDeploymentPermission } from "hooks/useHasPermission";

import { Footer } from "./Footer";
import {
  INTEGRATION_BUSINESS_COLUMN_DEFINITIONS,
  INTEGRATION_BUSINESS_DEFAULT_COLUMN_DEFINITION,
} from "./integrationBusinessColumns";

const ContactLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

function IntegrationCredentialsButton(props) {
  const { integrationCredentialType } = props;
  const dispatch = useDispatch();
  const { id: deploymentId } = useSelector(getActiveLivestockAgentDeployment);

  const integrationCredentialId = useSelector(
    state =>
      selectIntegrationCredentialIdByTypeAndDeploymentIdLookup(state)[
        integrationCredentialType
      ]?.[deploymentId],
  );

  function onClickRefresh() {
    dispatch(
      IntegrationCredentialAction.loadBusinesses(integrationCredentialId),
    );
  }

  function onClickAddCredentials() {
    openIntegrationSettings();
  }

  if (integrationCredentialId) {
    return (
      <WaitForSync requiredData={[ApiModel.INTEGRATION_CREDENTIALS]}>
        <Button inline type="button" onClick={onClickRefresh}>
          Refresh {integrationCredentialType} Businesses
        </Button>
      </WaitForSync>
    );
  } else {
    return (
      <WaitForSync requiredData={[ApiModel.INTEGRATION_CREDENTIALS]}>
        <Button inline type="button" onClick={onClickAddCredentials}>
          Add {integrationCredentialType} Credentials
        </Button>
      </WaitForSync>
    );
  }
}

function EditIntegrationBusinessModalModalTitleComponent(props) {
  const { integrationCredentialType, businessId, onClose } = props;

  const { name } = useSelector(getBusinessById(businessId)) || {};

  return (
    <DialogTitle onClose={onClose}>
      <Row justifyBetween>
        Link {IntegrationTypes[integrationCredentialType]} Business{" "}
        {name && ` to ${name}`}
      </Row>
    </DialogTitle>
  );
}

const EditIntegrationBusinessModalModalTitle = memo(
  EditIntegrationBusinessModalModalTitleComponent,
);
EditIntegrationBusinessModalModalTitle.propTypes = {
  integrationCredentialType: PropTypes.string,
  businessId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

const getRowId = params => params.data.id;

/**
 * @param {Object} props
 * @param {string|null} [props.deploymentId]
 * @param {string|null} [props.integrationCredentialType]
 * @param {string|null} [props.masterBusinessId]]
 * @param {string} [props.returnTo]
 * @returns {*}
 * @constructor
 */
export function EditIntegrationBusinessModal(props) {
  const { integrationCredentialType, businessId, returnTo } = props;
  const rowData = useDebounceSelector(selectIntegrationBusinessesList);

  const hasFeaturePermission = useHasDeploymentPermission(
    DeploymentPermissions.featureExternalIntegrations,
  );

  const business = useSelector(getBusinessById(businessId)) || {
    integrationBusinesses: [],
  };

  const integrationBusinesses = business.integrationBusinesses || [];
  const existingIntegrationBusiness =
    integrationBusinesses.find(
      integrationBusiness =>
        integrationBusiness.type === integrationCredentialType,
    ) || null;
  const existingIntegrationBusinessId =
    existingIntegrationBusiness && existingIntegrationBusiness.id;

  const gridRef = useRef();

  const dispatch = useDispatch();

  const [integrationBusinessId, setIntegrationBusinessId] = useState(null);

  const canLinkExternalIntegration = useHasDeploymentPermission(
    DeploymentPermissions.canLinkExternalIntegration,
  );

  const onClose = useCloseDialog(returnTo);

  function onSubmitForm() {
    dispatch(
      IntegrationBusinessAction.linkToBusiness(
        integrationBusinessId,
        businessId,
      ),
    );
    onClose();
  }

  const onGridReady = useCallback(agGridInstance => {
    gridRef.current = agGridInstance;
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      setIntegrationBusinessId(selectedRows[0].id);
    } else {
      setIntegrationBusinessId(null);
    }
  }, [gridRef]);

  useMountEffect(() => {
    dispatch(IntegrationBusinessAction.request());
  });

  useEffect(() => {
    // Automatically select the existing Deployment Business link when everything becomes ready, and nothing is already selected
    if (
      existingIntegrationBusinessId &&
      gridRef.current &&
      integrationBusinessId === null
    ) {
      const selectedRows = gridRef.current.api.getSelectedRows();
      if (selectedRows?.length === 0) {
        const existingNode = gridRef.current.api.getRowNode(
          existingIntegrationBusinessId,
        );
        if (existingNode) {
          existingNode.setSelected(true, true);
        }
      }
    }
  }, [existingIntegrationBusinessId, integrationBusinessId, rowData]);

  const createNew = () =>
    openCreateIntegrationBusinessModal(
      integrationCredentialType,
      businessId,
      window.location.hash,
    );

  function onClickUnlink() {
    dispatch(
      IntegrationBusinessAction.unlinkFromBusiness(
        integrationBusinessId,
        businessId,
      ),
    );
  }

  return (
    <Dialog fullWidth fullScreen maxWidth="lg" open onClose={onClose}>
      <EditIntegrationBusinessModalModalTitle
        integrationCredentialType={integrationCredentialType}
        businessId={businessId}
        onClose={onClose}
      />
      <form onSubmit={onSubmitForm}>
        <DialogContent dividers>
          <WaitForSync requiredData={[ApiModel.DEPLOYMENTS]}>
            {hasFeaturePermission ? (
              <Column fullWidth fullHeight>
                {canLinkExternalIntegration && (
                  <Row justifyBetween alignCenter padding={1}>
                    <div>
                      Select a {integrationCredentialType} business below to
                      link.
                    </div>
                    <div>
                      <IntegrationCredentialsButton
                        integrationCredentialType={integrationCredentialType}
                      />
                    </div>
                  </Row>
                )}
                <ModalTableWrapper>
                  <AgGrid
                    columnDefs={INTEGRATION_BUSINESS_COLUMN_DEFINITIONS}
                    defaultColDef={
                      INTEGRATION_BUSINESS_DEFAULT_COLUMN_DEFINITION
                    }
                    getRowId={getRowId}
                    onGridReady={onGridReady}
                    onSelectionChanged={onSelectionChanged}
                    pagination
                    paginationPageSize={100}
                    rowData={rowData}
                    rowSelection={RowSelectionMode.SINGLE}
                    tableName={AgGridTables.INTEGRATION_BUSINESSES}
                  />
                </ModalTableWrapper>
              </Column>
            ) : (
              <MessageBox>
                <FontAwesomeIcon icon={faLock} />
                &nbsp;
                <div>
                  This feature is currently unavailable.
                  <br />
                  Please{" "}
                  <ContactLink onClick={openHelpAndSupport}>
                    contact AgriNous
                  </ContactLink>{" "}
                  to discuss options with external integration.
                </div>
              </MessageBox>
            )}
          </WaitForSync>
        </DialogContent>
        <DialogActions>
          <Footer
            integrationBusinessId={integrationBusinessId}
            integrationCredentialType={integrationCredentialType}
            existingIntegrationBusinessId={existingIntegrationBusinessId}
            canLinkExternalIntegration={canLinkExternalIntegration}
            hasFeaturePermission={hasFeaturePermission}
            onClose={onClose}
            onClickUnlink={onClickUnlink}
            createNew={createNew}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}

EditIntegrationBusinessModal.propTypes = {
  integrationCredentialType: PropTypes.string,
  businessId: PropTypes.string,
  options: PropTypes.shape({
    presets: PropTypes.shape({
      livestockSaleId: PropTypes.string,
      vendorId: PropTypes.string.isRequired,
    }),
  }),
  returnTo: PropTypes.string,
};
