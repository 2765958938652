import React from "react";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";

import ConsignmentCards from "./ConsignmentCards";

const Component = React.memo(() => (
  <WaitForSync
    requiredData={[
      ApiModel.BUSINESSES,
      ApiModel.CONSIGNMENTS,
      ApiModel.DEPLOYMENTS,
      ApiModel.SALE_LOTS,
    ]}
  >
    <ConsignmentCards />
  </WaitForSync>
));

export default Component;
