import React, { useCallback, useState } from "react";

import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { addEidsToUnknownSaleLot } from "actions";

import { SecondaryButton } from "components/Form";
import { Button } from "components/Form/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { InlineCreateSaleLotComponent } from "components/SaleLotForms/InlineCreateSaleLotForm";
import { SelectSaleLotForEids } from "components/SelectSaleLotForEids";

import { getConsignmentCode } from "lib/consignments";

import {
  getAgencyByConsignmentIdLookup,
  getAgencyIdByEid,
  getBusinessById,
  getConsignmentById,
  getCurrentDraftingDecisionBySingleWeighById,
  getCurrentSingleWeigh,
  getLastDraftingDecisionBySingleWeighId,
  getSaleLotByEid,
  getUnknownConsignmentIdsByAgencyId,
  getVendorIdByConsignmentId,
} from "selectors";

function SelectLotModal({ eid, onClose }) {
  return (
    <Dialog open onClose={onClose} maxWidth="lg">
      <DialogTitle onClose={onClose}>Editing {eid}</DialogTitle>
      <SelectSaleLotForEids eids={[eid]} onClose={onClose} />
    </Dialog>
  );
}

function CreateSaleLotModal({ eid, onClose }) {
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [values, setValues] = useState(null);

  // If there is a sale lot here, it was consignment scanned - use that as the 'default sale lot'
  const consignmentScanConsignmentId = useSelector(
    state => getSaleLotByEid(eid)(state)?.consignmentId,
  );

  // Pull the agency from the last decision out - agency is a bit of a crapshoot
  // but there's (probably) a higher likelihood this is going to be the same agency as the
  // last beast.  Default the consignment to the unknown consignment for that agency, or
  // the 'create new unknown consignment' for that agency.
  const currentSingleWeighId = useSelector(
    state => getCurrentSingleWeigh(state)?.id,
  );
  const lastDecision = useSelector(
    getLastDraftingDecisionBySingleWeighId(currentSingleWeighId),
  );
  const lastAgencyId = useSelector(getAgencyIdByEid(lastDecision?.eid));

  const unknownConsignmentId = useSelector(
    state => getUnknownConsignmentIdsByAgencyId(lastAgencyId)(state)?.[0],
  );
  const defaultConsignmentId =
    consignmentScanConsignmentId ||
    unknownConsignmentId ||
    lastAgencyId ||
    null;

  const dispatch = useDispatch();
  function handleCreateSaleLot() {
    dispatch(addEidsToUnknownSaleLot([eid], values));
    onClose();
  }

  const updateSaleLot = useCallback(
    (values, isValid = false, setDirty = true) => {
      setIsValid(isValid);
      setIsDirty(setDirty);
      setValues(values);
    },
    [setIsValid, setIsDirty, setValues],
  );

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle onClose={onClose}>Create Sale Lot For {eid}</DialogTitle>

      <DialogContent>
        <InlineCreateSaleLotComponent
          contextUpdateSaleLot={updateSaleLot}
          initialValues={{
            quantity: 1,
            consignment_id: defaultConsignmentId,
          }}
          options={{
            includeCreateUnknownConsignment: true,
            includeCreateConsignmentButton: false,
            showDeliveryPen: true,
            showAuctionPenAtTop: true,
          }}
        />
      </DialogContent>

      <DialogActions shrink={0}>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <Button
          data-tour="createSaleLot"
          disabled={!isValid || !isDirty}
          onClick={handleCreateSaleLot}
        >
          Create Sale Lot
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const UnknownEidActionModals = {
  None: null,
  SelectSaleLot: "Select Sale Lot",
  CreateSaleLot: "Create Sale Lot",
};
const ShowingModalMap = {
  [UnknownEidActionModals.SelectSaleLot]: SelectLotModal,
  [UnknownEidActionModals.CreateSaleLot]: CreateSaleLotModal,
};

export function UnknownEidAction({ singleWeighId }) {
  const currentDecision = useSelector(
    getCurrentDraftingDecisionBySingleWeighById(singleWeighId),
  );

  const { eid } = currentDecision;

  // If we get to here, and we DO have a sale lot - it's consignment scanned.
  // Note this lot has serialized fields - don't try and use the same ones as lots!
  const consignmentScanSaleLot = useSelector(getSaleLotByEid(eid)) || {};
  const consignment = useSelector(
    getConsignmentById(consignmentScanSaleLot.consignmentId),
  );
  const agency = useSelector(
    getAgencyByConsignmentIdLookup(consignmentScanSaleLot.consignmentId),
  );
  const vendorId = useSelector(
    getVendorIdByConsignmentId(consignmentScanSaleLot.consignmentId),
  );
  const vendor = useSelector(getBusinessById(vendorId));

  const [showingModal, setShowingModal] = useState(UnknownEidActionModals.None);

  const onClose = () => {
    setShowingModal(UnknownEidActionModals.None);
  };

  const handleSelectSaleLot = () => {
    setShowingModal(UnknownEidActionModals.SelectSaleLot);
  };

  const handleCreateNewSaleLot = () => {
    setShowingModal(UnknownEidActionModals.CreateSaleLot);
  };

  const ShowingModal = ShowingModalMap[showingModal] || null;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          That animal has not yet been scanned into a sale lot.
        </Grid>

        {consignment ? (
          <Grid item xs={12}>
            It was scanned into consignment{" "}
            <h3>
              {getConsignmentCode(consignment)} {agency.name} - {vendor.name}{" "}
              (Hd {consignment.quantity})
            </h3>
          </Grid>
        ) : null}
        <Grid item xs={6} container>
          <Button data-tour="selectASaleLot" onClick={handleSelectSaleLot}>
            Select A Sale Lot
          </Button>
        </Grid>
        <Grid item xs={6} container>
          <Button
            data-tour="createANewSaleLot"
            onClick={handleCreateNewSaleLot}
          >
            Create A New Sale Lot
          </Button>
        </Grid>
      </Grid>
      {ShowingModal ? <ShowingModal eid={eid} onClose={onClose} /> : null}
    </>
  );
}
