export const columnState = [
  {
    colId: "transferType",
  },
  {
    colId: "transactionId",
  },
  {
    colId: "status",
  },
  {
    colId: "eidStatus",
  },
  {
    colId: "vendorDisplay",
  },
  {
    colId: "buyerDisplay",
  },
  {
    colId: "created",
  },
  {
    colId: "submitted",
  },
  {
    colId: "processed",
  },
  {
    colId: "actions",
  },
];
