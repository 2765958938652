import { OFFLINE_BUSY } from "@redux-offline/redux-offline/lib/constants";
import {
  all,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import {
  addAuctionPenFromPenArchetype,
  initializeSingleWeighFailure,
  initializeSingleWeighSuccess,
  patchSaleLot,
  patchScan,
  SaleLotAction,
  sendDraftingDecision,
  setSaleLotOfflineValues,
  SingleWeighAction,
  updateCurrentDraftingDecision,
} from "actions";

import {
  ADD_SCAN_FROM_SCANNER,
  ADD_WEIGHT_FROM_SCALES,
  API_RESPONSE,
  DRAFTING_DECISION,
  SINGLE_WEIGH,
} from "constants/actionTypes";
import { SingleWeighPenTypes } from "constants/auctionPens";
import { SaleLotType } from "constants/saleLots";
import {
  DEFAULT_DEBOUNCE,
  DEFAULT_MIN_WEIGHT,
  DEFAULT_STABLE_SAMPLE_COUNT,
  SingleWeighModeConfiguration,
} from "constants/singleWeigh";

import {
  calculateTotalPriceCents,
  calculateUnitPrice,
  formatWeightKg,
} from "lib";

import { getIsSteadySampleWeight } from "lib/singleWeigh";
import {
  formatDateTimeString,
  formatLocalTimestampToDateTimeString,
} from "lib/timeFormats";

import {
  getContextBySingleWeighId,
  getCurrentDecisionUpdatesBySingleWeighId,
  getCurrentDraftingDecisionBySingleWeighById,
  getCurrentSingleWeighId,
  getPenArchetypesBySingleWeighId,
  getSaleLotByEid,
  getScanByEid,
  getShouldAutomaticallyDispatchBySingleWeighId,
  getSingleWeighById,
  selectIsPLCReady,
  selectPenIdsByPenArchetypeIdLookup,
} from "selectors";

function* initializeSingleWeigh(action) {
  const { singleWeighId, options } = action;
  const { onSuccess, onError } = {
    ...options,
  };

  const state = yield select();

  try {
    const auctionPensByArchetypeIdLookup =
      selectPenIdsByPenArchetypeIdLookup(state);

    const penArchetypes = getPenArchetypesBySingleWeighId(singleWeighId)(state);

    const uncreatedArchetypeIds = Object.values(penArchetypes).reduce(
      (acc, penArchetype) => {
        if (
          SingleWeighPenTypes.includes(penArchetype.penType) &&
          (!auctionPensByArchetypeIdLookup[penArchetype.id] ||
            auctionPensByArchetypeIdLookup[penArchetype.id].length === 0)
        ) {
          acc.push(penArchetype.id);
        }
        return acc;
      },
      [],
    );

    yield all(
      uncreatedArchetypeIds.map(penArchetypeId =>
        put(
          addAuctionPenFromPenArchetype(penArchetypeId, uuidv4(), {
            autoDraftState: {},
          }),
        ),
      ),
    );

    typeof onSuccess === "function" && onSuccess(singleWeighId);
  } catch (e) {
    try {
      typeof onError === "function" && onError(e);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("There was an error", e);
    }
    // eslint-disable-next-line no-console
    console.log("There was an error", e);
    return yield put(initializeSingleWeighFailure(e));
  }

  yield put(initializeSingleWeighSuccess(singleWeighId));
}

function* onDraftingDecision(action) {
  // When a drafting decision is made, move the scan in the current decision state into the draft pen.
  const { draftingDecision, singleWeighId } = action;

  const {
    eid,
    draftPenId,
    overrideWeight,
    totalMassGrams,
    destinationPenId,
    detectedEids,
  } = draftingDecision;

  const state = yield select();
  const scan = getScanByEid(eid)(state);

  if (scan) {
    const newComments = [];
    const saleLot = getSaleLotByEid(eid)(state);

    const timeWeighed = new Date();

    const scanPatch = {
      current_auction_pen_id: draftPenId,
      total_mass_grams: totalMassGrams,
      is_manually_weighed: !!overrideWeight,
      time_weighed: timeWeighed.toISOString(),
      recalculate_salelot_weight: true,
    };

    if (
      saleLot &&
      saleLot.saleLotType === SaleLotType.AUCTION &&
      saleLot.timeWeighed
    ) {
      // If the sale lot has a timeWeighed, it has been over the bulk weigh - if this is the case we don't want to change that
      // value automatically - add a comment to that affect.
      scanPatch.recalculate_salelot_weight = false;

      newComments.push(
        `EID ${eid} was weighed on single weigh at ${formatWeightKg(
          totalMassGrams,
          true,
        )} at ${formatDateTimeString(
          timeWeighed,
        )}.\r\nA previous bulk weight was found as ${formatWeightKg(
          saleLot.total_mass_grams,
          true,
        )} at ${formatLocalTimestampToDateTimeString(
          saleLot.timeWeighed,
        )}.\r\nThe single weigh weight was NOT added to total weight of lot.`,
      );
    } else {
      // Fire an action that will tickle the sale lots weight offline - this will be (possibly?) be overridden by a
      // refresh from the server, driven by the  recalculate_salelot_weight action on the scan.
      // Most of the time - these should be the same - but out of sync states seem to make this an unreliable equation.

      const adjustedSaleLotWeight =
        // If the scan already has a weight, remove that from the adjusted weight first before adding the new weight.
        // Make sure we dont let the weight go negative.
        Math.max(0, saleLot.total_mass_grams - (scan.total_mass_grams || 0)) +
        totalMassGrams;
      // Recalculate the salelot weight and price
      yield put(
        setSaleLotOfflineValues(saleLot.id, {
          total_mass_grams: adjustedSaleLotWeight,
          total_price_cents: calculateTotalPriceCents({
            ...saleLot,
            unitPrice: calculateUnitPrice(saleLot),
            total_mass_grams: adjustedSaleLotWeight,
          }),
        }),
      );
    }

    yield put(patchScan(scan, scanPatch));

    const singleWeigh = getSingleWeighById(singleWeighId)(state);
    // Make sure that the salelot has the delivery pen set if we are in post sale auto.
    if (
      SingleWeighModeConfiguration[singleWeigh.mode].useDefaultDeliveryPen &&
      saleLot.deliveryPenId !== destinationPenId
    ) {
      yield put(
        patchSaleLot(
          {
            id: saleLot.id,
            deliveryPenId: destinationPenId,
          },
          {
            disabledToast: true,
            changeReason:
              "Set delivery pen after single weigh drafting decision",
          },
        ),
      );
    }

    // When multiple EIDs are in the decision, keep a note of them.
    if (detectedEids.length > 1) {
      const unusedEids = detectedEids.filter(otherEid => otherEid !== eid);
      unusedEids.forEach(otherEid => {
        newComments.push(
          `${otherEid} was also detected on ${eid} at Single Weigh`,
        );
      });
    }

    for (const comment of newComments) {
      yield put(
        SaleLotAction.comment(saleLot.id, {
          comment,
        }),
      );
    }
  }
}

function* startNoEidTimer(action) {
  const { singleWeighId, noEidDelay } = action;

  const result = yield race({
    cancel: take(SINGLE_WEIGH.NO_EID_TIMER.CANCEL),
    timeout: delay(noEidDelay * 1000),
  });

  if (result.timeout) {
    yield all([
      put(
        updateCurrentDraftingDecision(singleWeighId, {
          eidWaitTimeout: true,
        }),
      ),
      put(
        SingleWeighAction.update({
          isPaused: true,
          id: singleWeighId,
        }),
      ),
    ]);
  }
}

export function* onCheckForDraftingDecisionChanges(action) {
  // avoid recursive loops.
  if (
    action.type === DRAFTING_DECISION.UPDATE.REQUEST ||
    action.type === OFFLINE_BUSY
  ) {
    return;
  }

  const state = yield select();

  const singleWeighId = getCurrentSingleWeighId(state);
  if (!singleWeighId) {
    return;
  }

  const currentDecisionUpdates =
    getCurrentDecisionUpdatesBySingleWeighId(singleWeighId)(state);
  if (currentDecisionUpdates) {
    // Unsteady Weight Timer Start
    if (currentDecisionUpdates.unsteadyWeightTimeoutStarted) {
      const singleWeigh = getSingleWeighById(singleWeighId)(state);
      const { unsteadyWeightDelay } = singleWeigh;
      yield put({
        type: SINGLE_WEIGH.UNSTEADY_WEIGHT_TIMER.START,
        singleWeighId,
        unsteadyWeightDelay,
      });
    }

    // Unsteady Weight Timer Stop
    if (currentDecisionUpdates.unsteadyWeightTimeoutStarted === null) {
      yield put({ type: SINGLE_WEIGH.UNSTEADY_WEIGHT_TIMER.CANCEL });
    }

    // No EID Timer Start
    if (currentDecisionUpdates.eidWaitTimeoutStarted) {
      const singleWeigh = getSingleWeighById(singleWeighId)(state);
      const { noEidDelay } = singleWeigh;
      yield put({
        type: SINGLE_WEIGH.NO_EID_TIMER.START,
        singleWeighId,
        noEidDelay,
      });
    }

    // No EID Timer Stop
    if (currentDecisionUpdates.eidWaitTimeoutStarted === null) {
      yield put({ type: SINGLE_WEIGH.NO_EID_TIMER.CANCEL });
    }

    // Tell the reducers about the change.
    yield put(
      updateCurrentDraftingDecision(singleWeighId, currentDecisionUpdates),
    );
  }
}

export function* onCheckDispatchDraftingDecision(action) {
  // This function fires on every action, and considers whether the resulting change should necessitate
  // firing off some SingleWeigh instructions, updates to the backend, and updates state
  // If there are multiple actions in the store that will affect this, and they are actioned before the
  // subsequent updates to the store that would result in  getShouldAutomaticallyDispatchBySingleWeighId blocking,
  // we may fire the drafting decision mulitple times (once for each action coming in before the [changes to the store]).

  // Don't consider re-sending if the triggering action is a ACTION_DRAFTING_DECISION_REQUEST - we're literally
  // already actioning a dispatch!
  if (!action?.type || action.type === DRAFTING_DECISION.ACTION.REQUEST) {
    return;
  }

  // Checks whether we should actually send the drafting decision.
  const state = yield select();

  const singleWeighId = getCurrentSingleWeighId(state);
  if (!singleWeighId) {
    return;
  }

  if (getShouldAutomaticallyDispatchBySingleWeighId(singleWeighId)(state)) {
    const currentDraftingDecision =
      getCurrentDraftingDecisionBySingleWeighById(singleWeighId)(state);

    yield put(sendDraftingDecision(singleWeighId, currentDraftingDecision));
  }
}

export function* onAddScanFromScanner(action) {
  // When a scan come in, add it to our drafting updates.
  const state = yield select();

  const singleWeighId = getCurrentSingleWeighId(state);
  if (!singleWeighId) {
    return;
  }

  const currentDecision =
    getCurrentDraftingDecisionBySingleWeighById(singleWeighId)(state);

  const { scan } = action;

  const detectedEids = Array.from(
    new Set([...currentDecision.detectedEids, scan.EID]),
  );

  yield put(
    updateCurrentDraftingDecision(singleWeighId, {
      eid: scan.EID,
      detectedEids,
      areMultipleEidsDetected: detectedEids.length > 1,
    }),
  );
}

export function* onChooseEid(action) {
  // When the single weigh sees multiple eids, then a user selects a specific eid:
  // - Adjust the decision to have an eid of THIS ONE
  // - Remove the 'seen duplicates' flag
  // (Note - this is kinda temporary - If another EID comes in we should end up with the multiple eids selected being flagged again)

  const { singleWeighId, eid } = action;

  yield put(
    updateCurrentDraftingDecision(singleWeighId, {
      eid,
      areMultipleEidsDetected: false,
    }),
  );
}

function* startUnsteadyWeightTimer(action) {
  const { singleWeighId, unsteadyWeightDelay } = action;

  const result = yield race({
    cancel: take(SINGLE_WEIGH.UNSTEADY_WEIGHT_TIMER.CANCEL),
    timeout: delay(unsteadyWeightDelay * 1000),
  });

  if (result.timeout) {
    yield put(
      updateCurrentDraftingDecision(singleWeighId, {
        unsteadyWeightTimeout: true,
      }),
    );
    yield put(
      SingleWeighAction.update({
        isPaused: true,
        id: singleWeighId,
      }),
    );
  }
}

export function* onAddWeighFromScales(action) {
  const { timestamp, weightGrams, isStable } = action;

  const state = yield select();

  // Not interested if we don't have an active single weigh.
  const singleWeighId = getCurrentSingleWeighId(state);
  if (!singleWeighId) {
    return;
  }
  const singleWeighContext = getContextBySingleWeighId(singleWeighId)(state);
  if (!singleWeighContext) {
    return;
  }

  const singleWeigh = getSingleWeighById(singleWeighId)(state);
  const { currentDecision } = singleWeighContext;
  const { hasGoneBelowMinimumWeight, overrideWeight, totalMassGramsRecorded } =
    currentDecision;

  // If below a certain threshold, don't bother.
  const minWeight = singleWeigh.minStableWeightGrams || DEFAULT_MIN_WEIGHT;
  if (weightGrams < minWeight) {
    // If we've reached below the min weight, and the current decision doens't know that yet, let it.
    // Note that this will allow an UNSTEADY weight to be flagged as below minimum.
    if (!hasGoneBelowMinimumWeight) {
      yield put(
        updateCurrentDraftingDecision(singleWeighId, {
          hasGoneBelowMinimumWeight: true,
        }),
      );
    }
    return;
  }

  // Not interested in progressing any further if it's not stable, or we don't have an active single weigh.
  if (!isStable) {
    return;
  }

  // Don't even consider saving weights until it's gone below the minimum weight AND the PLC is ready
  // Test minimum weight flag explicitly for false, this is set to `false` on a drafting decision and is set to `null` on a reset
  if (hasGoneBelowMinimumWeight === false || !selectIsPLCReady(state)) {
    return;
  }

  // If the weight has been explicitly overwritten, don't bother.
  if (overrideWeight) {
    return;
  }

  // Only grab every (debounce) milliseconds to save unnecessary state updates, and if it's greater than
  // our defined min weight.
  const debounce = singleWeigh.stableWeightDebounce || DEFAULT_DEBOUNCE;

  const timeStampMs = timestamp.valueOf();
  if (
    totalMassGramsRecorded &&
    timeStampMs - totalMassGramsRecorded < debounce
  ) {
    return;
  }

  // Append the new weight to the list of samples, whilst removing as many from the start
  // of the list as necessary to meet the number specified by `stableWeightSampleCount`

  const stableWeightSampleCount =
    typeof singleWeigh.stableWeightSampleCount === "number"
      ? singleWeigh.stableWeightSampleCount
      : DEFAULT_STABLE_SAMPLE_COUNT;

  const weightSamples = [
    ...currentDecision.weightSamples.slice(
      // return 1 less than the desired sample count, as we are about to add one more sample
      Math.max(
        0,
        currentDecision.weightSamples.length - stableWeightSampleCount + 1,
      ),
    ),
    weightGrams,
  ];

  const patch = {
    weightSamples,
  };

  if (getIsSteadySampleWeight(singleWeigh, weightGrams, weightSamples)) {
    // only store a steady weight when it has met the "steady sample weight" criteria
    patch.totalMassGrams = weightGrams;
    patch.totalMassGramsRecorded = timeStampMs;
  }

  yield put(updateCurrentDraftingDecision(singleWeighId, patch));
}

function* onFailPauseSingleWeigh(action) {
  const { error, action: originalAction } = action;

  // Ignoring SINGLE_WEIGH.UPDATE.REQUEST here so that on pause/unpause it doesn't fire cauing it to unpause/pause rapidly
  if (
    error.status === 409 &&
    originalAction.type !== SINGLE_WEIGH.UPDATE.REQUEST
  ) {
    const state = yield select();
    const currentSingleWeigh = getSingleWeighById(
      state.singleWeighs.currentSingleWeighId,
    )(state);
    if (currentSingleWeigh) {
      yield put(
        SingleWeighAction.update({
          isPaused: !currentSingleWeigh.isPaused,
          id: currentSingleWeigh.id,
        }),
      );
    }
  }
}

export default function* rootSaga() {
  yield takeEvery(SINGLE_WEIGH.INITIALIZE.REQUEST, initializeSingleWeigh);

  // Take only the LATEST decision action - if many events are coming in quickly (eg, weigh events) there is a possibility
  // the drafting decision may get fired multiple times (that is, the 'onCheckDispatchDraftingDecision' has picked up
  // the weigh actions, and subsequent, propagated actions that adjust state, progressing the status of the single weigh, from getStatusBySingleWeighId, to a not-ready-for-another-result state. )
  yield takeLatest(DRAFTING_DECISION.ACTION.REQUEST, onDraftingDecision);

  yield takeEvery(ADD_SCAN_FROM_SCANNER, onAddScanFromScanner);
  yield takeEvery(ADD_WEIGHT_FROM_SCALES, onAddWeighFromScales);
  yield takeEvery(SINGLE_WEIGH.CHOOSE_EID.ACTION, onChooseEid);

  // Timers
  yield takeEvery(
    SINGLE_WEIGH.UNSTEADY_WEIGHT_TIMER.START,
    startUnsteadyWeightTimer,
  );
  yield takeEvery(SINGLE_WEIGH.NO_EID_TIMER.START, startNoEidTimer);

  // On any kind of [thing that may affect drafting decisions], see if we can deduce a draftingPenId, and set it.
  yield takeEvery("*", onCheckForDraftingDecisionChanges);

  yield takeEvery("*", onCheckDispatchDraftingDecision);
  yield takeEvery(API_RESPONSE.UPDATE.FAILURE, onFailPauseSingleWeigh);
}
