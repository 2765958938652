export const BillingRunStatus = {
  DRAFT: "DRAFT",
  EXPORTED: "EXPORTED",
  FINISHED: "FINISHED",
  DELETED: "DELETED",
};

export const BillingRunStatusOptions = [
  {
    label: "Draft",
    value: BillingRunStatus.DRAFT,
  },
  {
    label: "Exported",
    value: BillingRunStatus.EXPORTED,
  },
  {
    label: "Deleted",
    value: BillingRunStatus.DELETED,
  },
  {
    label: "Finished",
    value: BillingRunStatus.FINISHED,
  },
];

export const BillingRunPermissions = {
  delete: "BillingRunPermissions.delete",
  read: "BillingRunPermissions.read",
  update: "BillingRunPermissions.update",
};
