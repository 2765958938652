import React from "react";

import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { UnstyledLink } from "components/Link";

import { getAuditLogModalHashRoute } from "lib/navigation";

const AuditLogLink = ({
  auditLogType,
  dataId,
  linkText = "",
  returnTo,
  queryParams = {},
}) => (
  <UnstyledLink
    data-tour="auditLog"
    to={`#${getAuditLogModalHashRoute(
      auditLogType,
      dataId,
      returnTo,
      queryParams,
    )}`}
  >
    <FontAwesomeIcon icon={faHistory} />
    {linkText}
  </UnstyledLink>
);

export default AuditLogLink;
