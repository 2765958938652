import React, { memo } from "react";

import { isEqual } from "lodash";
import PropTypes from "prop-types";

function IdListItemComponent(props) {
  const {
    orderedIds,
    selectedIds = [],
    component: Component,
    options = null,
    emptyComponent: EmptyComponent,
    provideIndex = false,
  } = props;

  const unusedSelectedIds = new Set(selectedIds);

  if (!orderedIds.length) {
    return <EmptyComponent />;
  }

  return orderedIds.map((id, index) => (
    <Component
      key={`${id}_${index}`}
      id={id}
      index={provideIndex ? index : undefined}
      {...options}
      isSelected={unusedSelectedIds.has(id)}
    />
  ));
}

const IdConnectedList = memo(
  IdListItemComponent,
  (a, b) =>
    isEqual(a.selectedIds, b.selectedIds) &&
    isEqual(a.orderedIds, b.orderedIds) &&
    a.emptyComponent === b.emptyComponent &&
    a.component === b.component &&
    a.options === b.options,
);
IdConnectedList.propTypes = {
  component: PropTypes.elementType.isRequired,
  emptyComponent: PropTypes.elementType.isRequired,
  options: PropTypes.object,
  orderedIds: PropTypes.array.isRequired,
  selectedIds: PropTypes.array,
};

export default IdConnectedList;
