// we need this to be static so we can scroll inside independently
import React from "react";

import { Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { useSelector } from "react-redux";
import { Waypoint } from "react-waypoint";
import styled from "styled-components/macro";

import { FullScreenContainer } from "components/FullScreenContainer";
import Averages from "components/SaleWatcher/Averages";
import LotList from "components/SaleWatcher/LotList";
import {
  ScrollingColumn,
  ScrollingGrid,
} from "components/SaleWatcher/ScrollingGrid";
import SellingNow from "components/SaleWatcher/SellingNow";
import VideoEmbed from "components/SaleWatcher/VideoEmbed";

import { colors } from "constants/theme";

import {
  currentSaleSelector,
  getNextUpSaleLotsInOrder,
  getSkippedSaleLots,
  getSoldSaleLotsInSoldOrder,
} from "selectors";

import { useIsMobile } from "hooks";

const WrapItem = styled(Grid)`
  flex: 1;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}px) {
    overflow: hidden;
  }
`;
const SellingNowGridItem = styled(Grid)`
  padding: ${({ theme }) => theme.space[2]}px;
`;
const Divider = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-transform: uppercase;

  ${({ theme, roundCorner }) => `
    background-color: ${theme.colors.grayF7};
    box-shadow: ${theme.shadows[2]};
    border: 1px solid ${theme.colors.black};
    padding: ${theme.space[1]}px;
    font-weight: ${theme.fontWeights.bold};
    ${roundCorner && `border-top-right-radius: ${theme.radii[4]}px`};
  `}
`;
const ListWrapper = styled.div`
  max-height: ${({ isHidden }) => (isHidden ? 0 : 5000)}px;
  overflow: hidden;
  transition: max-height 1s;
`;
const NavButton = styled(Grid)`
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  ${({ isActive, theme }) => `
      background-color:  ${theme.colors.gray95};
      color: ${theme.colors.primary};
      border-bottom: ${isActive ? "4px" : "1px"} solid blue;
      padding: ${theme.space[1]}px 0;
  `}
`;
const NEXT_UP = "NEXTUP";
const SELLING_NOW = "SELLINGNOW";
const RECENTLY_SOLD = "RECENTLYSOLD";
export const DefaultView = () => {
  const currentSale = useSelector(state => {
    return currentSaleSelector(state);
  });
  const nextUpCount = useSelector(getNextUpSaleLotsInOrder).length;
  const skippedCount = useSelector(getSkippedSaleLots).length;
  const [skippedShowing, setShowingSkipped] = React.useState(false);
  const toggleSkipped = () => {
    setShowingSkipped(!skippedShowing);
  };

  // TODO - not quite happy with waypoint detection.  tempted to debounce an OnScroll and find the first entry with a
  // postive offset.  But, this works ok for now.
  const [waypoint, setWaypoint] = React.useState(NEXT_UP);
  const handleEnter = section => {
    setWaypoint(section);
  };

  const nextUpRef = React.useRef();
  const sellingNowRef = React.useRef();
  const soldRef = React.useRef();
  const scrollToRef = ref => {
    if (ref?.current?.offsetTop) {
      window.scrollTo(0, ref.current.offsetTop - 100);
    }
  };

  const isMobile = useIsMobile();
  const wrap = isMobile ? "wrap" : "nowrap";

  return (
    <>
      {isMobile && (
        <AppBar position="sticky">
          <Grid container>
            <NavButton
              item
              xs={4}
              isActive={waypoint === SELLING_NOW}
              onClick={() => scrollToRef(sellingNowRef)}
            >
              Selling Now
            </NavButton>
            <NavButton
              item
              xs={4}
              isActive={waypoint === NEXT_UP}
              onClick={() => scrollToRef(nextUpRef)}
            >
              Next Up
            </NavButton>
            <NavButton
              item
              xs={4}
              isActive={waypoint === RECENTLY_SOLD}
              onClick={() => scrollToRef(soldRef)}
            >
              Recently Sold
            </NavButton>
          </Grid>
        </AppBar>
      )}
      <FullScreenContainer wrap={wrap} direction="column">
        <WrapItem item>
          <ScrollingGrid container wrap={wrap}>
            <Waypoint onEnter={() => handleEnter(NEXT_UP)} bottomOffset="-50%">
              <ScrollingColumn
                title="Next Up Sale Lots"
                columnProps={{ xs: 12, md: 3, mobileOrder: 2 }}
                roundedSide="right"
                backgroundColor={colors.gray8C}
              >
                <span ref={nextUpRef} />
                <Divider onClick={toggleSkipped} roundCorner>
                  Skipped ({skippedCount})
                </Divider>
                <ListWrapper isHidden={!skippedShowing}>
                  <LotList
                    selector={getSkippedSaleLots}
                    transitionName="nextUp"
                  />
                </ListWrapper>

                <Divider>Upcoming ({nextUpCount})</Divider>
                <ListWrapper>
                  <LotList
                    selector={getNextUpSaleLotsInOrder}
                    transitionName="nextUp"
                  />
                </ListWrapper>
              </ScrollingColumn>
            </Waypoint>
            <Waypoint
              onEnter={() => handleEnter(SELLING_NOW)}
              topOffset="100%"
              bottomOffset="100%"
            >
              <ScrollingColumn
                title="Selling Now"
                columnProps={{ xs: 12, md: 6, mobileOrder: 1 }}
              >
                <span ref={sellingNowRef} />
                <Grid container>
                  <SellingNowGridItem item xs={12}>
                    <SellingNow largeImages={!currentSale.streaming_video} />
                  </SellingNowGridItem>
                  {currentSale.streaming_video ? (
                    <SellingNowGridItem item xs={12}>
                      <VideoEmbed videoUrl={currentSale.streaming_video} />
                    </SellingNowGridItem>
                  ) : null}
                  <SellingNowGridItem item xs={12}>
                    <Averages />
                  </SellingNowGridItem>
                </Grid>
              </ScrollingColumn>
            </Waypoint>
            <Waypoint
              onEnter={() => handleEnter(RECENTLY_SOLD)}
              topOffset="-40%"
            >
              <ScrollingColumn
                title="Recently Sold"
                columnProps={{ xs: 12, md: 3, mobileOrder: 3 }}
                roundedSide="left"
                backgroundColor={colors.gray8C}
              >
                <span ref={soldRef} />

                <LotList
                  selector={getSoldSaleLotsInSoldOrder}
                  transitionName="recentlySold"
                />
              </ScrollingColumn>
            </Waypoint>
          </ScrollingGrid>
        </WrapItem>
      </FullScreenContainer>
    </>
  );
};
