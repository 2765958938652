import React from "react";

import { get } from "lodash";

import { NominationPermissions } from "constants/permissions";

import { openEditNominationModal } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import { ClickableColumn } from "./columnStyles";

export const nominationDetailsValueGetter = params => {
  const value = get(params.data, params.colDef.field) || [];
  return value.reduce(
    (termsString, nomination, idx) =>
      nomination.quantity > 0
        ? termsString + (idx > 0 ? ", " : " ") + nomination.termName
        : null,
    "",
  );
};

export const nominationDetailsCellRenderer = ({ data, value }) => {
  if (!value) {
    return null;
  }

  const readOnly = !hasPermission(
    data.nomination,
    NominationPermissions.update,
  );
  const { nominationId } = data;
  const onClickEdit = value
    ? () => {
        openEditNominationModal(nominationId);
      }
    : null;

  return !readOnly ? (
    <ClickableColumn onClick={onClickEdit}>{value}</ClickableColumn>
  ) : (
    value
  );
};

export const nominationHdGetter = ({ data }) => {
  const { nomination } = data;
  const { nominationDetails } = nomination;

  return nominationDetails?.reduce((acc, nomDetail) => {
    return (acc += nomDetail.quantity);
  }, 0);
};
