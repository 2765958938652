import { intersection, max } from "lodash";
import { createSelector } from "reselect";

import { ExportSites } from "constants/exportSites";
import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { isSaleLotInProgress, getSortedMarkDetails } from "lib/saleLot";

import {
  getNominations,
  getConsignments,
  getSaleLots,
  selectAuctionPenIdsByConsignmentIdLookup,
  selectCanHaveProgenyBySaleLotIdLookup,
  selectConsignmentHasImageLookup,
  selectConsignmentHasVideoLookup,
  selectConsignmentIdByEidLookup,
  selectDeliveryPenIdsByConsignmentIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectSaleLotHasImageLookup,
  selectSaleLotHasVideoLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectScanStatusBySaleLotIdLookup,
  selectIsWeighedBySaleLotIdLookup,
} from "selectors";

import { selectAgencyIdByNominationIdLookup } from "selectors/agencies";
import {
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
} from "selectors/balances";
import {
  selectExceptionsBySaleLotIdLookup,
  selectExceptionsByConsignmentIdLookup,
} from "selectors/exceptions";
import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";
import { getGlobalSearchBySearchField } from "selectors/settings";

import {
  saleLotsFilteredBuyerIdsComparator,
  saleLotsFilteredThirdPartyIdsComparator,
  saleLotsFilteredBuyerHashesComparator,
  saleLotsFilteredSaleRoundIdsComparator,
  saleLotsFilteredIsBuyerBalancedComparator,
  saleLotsFilteredIsCountedComparator,
  saleLotsFilteredHaveOverflowPenCompararator,
  saleLotsFilteredIsDeliveredComparator,
  saleLotsFilteredLabelsComparator,
  saleLotsFilteredPricingTypesComparator,
  saleLotsFilteredPropertyIdComparator,
  nominationsFilteredVendorIdsComparator,
  nominationFilteredHasArrivedComparator,
  auctionPensFilteredComparator,
  deliveryPensFilteredComparator,
  saleLotsOverflowPensComparator,
  auctionPensLanesComparator,
  consignmentFilteredVendorNumberComparator,
  consignmentFilteredVendorPicsComparator,
  consignmentFilteredCheckpointComparator,
  consignmentFilteredReceivalPenComparator,
} from "./comparators";
import {
  filterAndMapObjectIdsWithLookup,
  lookupFilterSelectorFactory,
  lookupNestedFilterSelectorFactory,
  rawObjectFilterSelectorFactory,
  nestedRawObjectFilterSelectorFactory,
} from "./lib";

const selectUnfilteredNominationIds = createSelector(
  [getNominations],
  nominations => Object.keys(nominations),
);

const selectBuyerFilteredNominationIds = nestedRawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Buyer,
  selectUnfilteredNominationIds,
  saleLotsFilteredBuyerIdsComparator,
  "nominationId",
  getConsignments,
  "consignment_id",
);

const selectThirdPartyFilteredNominationIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.ThirdParty,
    selectUnfilteredNominationIds,
    saleLotsFilteredThirdPartyIdsComparator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectBuyerAndBuyerWayFilteredNominationIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.BuyerAndBuyerWay,
    selectUnfilteredNominationIds,
    saleLotsFilteredBuyerHashesComparator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectVendorFilteredNominationIds = rawObjectFilterSelectorFactory(
  getNominations,
  GlobalSearchFields.Vendor,
  selectUnfilteredNominationIds,
  nominationsFilteredVendorIdsComparator,
);

const selectSaleRoundFilteredNominationIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.SaleRound,
    selectUnfilteredNominationIds,
    saleLotsFilteredSaleRoundIdsComparator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectAgencyFilteredNominationIds = lookupFilterSelectorFactory(
  selectAgencyIdByNominationIdLookup,
  GlobalSearchFields.Agency,
  selectUnfilteredNominationIds,
  agencyIds =>
    ([_ignored, agencyId]) =>
      agencyIds.includes(agencyId),
);

const selectHasArrivedFilteredNominationIds = rawObjectFilterSelectorFactory(
  getNominations,
  GlobalSearchFields.HasArrived,
  selectUnfilteredNominationIds,
  nominationFilteredHasArrivedComparator,
);

const selectHasBuyerExceptionsFilteredNominationIds = createSelector(
  [
    selectExceptionsBySaleLotIdLookup,
    selectSaleLotIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasBuyerExceptions),
    selectUnfilteredNominationIds,
    getConsignments,
  ],
  (
    exceptionsBySaleLotIdLookup,
    saleLotIdsByConsignmentIdLookup,
    hasBuyerExceptions,
    unfilteredNominationIds,
    consignments,
  ) => {
    return hasBuyerExceptions === null
      ? unfilteredNominationIds
      : Object.entries(saleLotIdsByConsignmentIdLookup)
          .filter(([_ignored, saleLotIds]) =>
            Boolean(
              saleLotIds.find(
                saleLotId =>
                  (exceptionsBySaleLotIdLookup[saleLotId].length === 0) ===
                  hasBuyerExceptions[0],
              ),
            ),
          )
          .map(
            ([consignmentId, _ignored]) =>
              consignments[consignmentId]?.nominationId,
          );
  },
);

const selectHasConsignmentScansFilteredNominationIds =
  selectUnfilteredNominationIds;
const selectHasOverflowFilteredNominationIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.HasOverflow,
    selectUnfilteredNominationIds,
    saleLotsFilteredHaveOverflowPenCompararator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectHasVendorExceptionsFilteredNominationIds =
  lookupNestedFilterSelectorFactory(
    selectExceptionsByConsignmentIdLookup,
    GlobalSearchFields.HasVendorExceptions,
    selectUnfilteredNominationIds,
    hasVendorExceptions =>
      ([_ignored, exceptions]) =>
        (exceptions.length !== 0) === hasVendorExceptions[0],
    getConsignments,
    "nominationId",
  );

const selectHasWeightFilteredNominationIds = createSelector(
  [
    getSaleLots,
    selectIsWeighedBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredNominationIds,
    getConsignments,
  ],
  (
    saleLots,
    isWeighedBySaleLotIdLookup,
    hasWeight,
    unfilteredNominationIds,
    consignments,
  ) => {
    return hasWeight === null
      ? unfilteredNominationIds
      : Object.values(saleLots)
          .filter(
            saleLot => isWeighedBySaleLotIdLookup[saleLot.id] === hasWeight[0],
          )
          .map(lot => consignments[lot.consignment_id].nominationId);
  },
);

const selectIsBuyerBalancedFilteredNominationIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.IsBuyerBalanced,
    selectUnfilteredNominationIds,
    saleLotsFilteredIsBuyerBalancedComparator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectIsCountedFilteredNominationIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.IsCounted,
    selectUnfilteredNominationIds,
    saleLotsFilteredIsCountedComparator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectIsDeliveredFilteredNominationIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.IsDelivered,
    selectUnfilteredNominationIds,
    saleLotsFilteredIsDeliveredComparator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectIsEidCompliantFilteredNominationIds = createSelector(
  [
    getConsignments,
    selectScanStatusBySaleLotIdLookup,
    selectSaleLotIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsEidCompliant),
    selectUnfilteredNominationIds,
  ],
  (
    consignments,
    scanStatusBySaleLotIdLookup,
    saleLotIdsByConsignmentIdLookup,
    isEidCompliant,
    unfilteredNominationIds,
  ) =>
    isEidCompliant === null
      ? unfilteredNominationIds
      : Object.values(consignments)
          .filter(consignment => {
            const saleLotIds = saleLotIdsByConsignmentIdLookup[consignment.id];
            // If we don't have any sale lots yet... we're not eid compliant.  Or we are... because they're 100% scanned? ;\
            if (!saleLotIds) {
              return isEidCompliant[0];
            }
            const allScanStatuses = saleLotIds.map(
              saleLotId =>
                scanStatusBySaleLotIdLookup[saleLotId] || ScanStatus.PASS,
            );
            const consignmentScanStatus = max(allScanStatuses);
            return (
              (consignmentScanStatus === ScanStatus.PASS) === isEidCompliant[0]
            );
          })
          .map(consignment => consignment.nominationId),
);

const selectIsInProgressFilteredNominationIds = createSelector(
  [
    getSaleLots,
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredNominationIds,
  ],
  (saleLots, consignments, isInProgress, unfilteredNominationIds) =>
    isInProgress === null
      ? unfilteredNominationIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isSaleLotInProgress(
                saleLot,
                consignments[saleLot.consignment_id],
              ) === isInProgress[0],
          )
          .map(saleLot => consignments[saleLot.consignment_id].nominationId),
);

const selectIsSoldFilteredNominationIds = createSelector(
  [
    getConsignments,
    selectIsSoldBySaleLotIdLookup,
    selectSaleLotIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSold),
    selectUnfilteredNominationIds,
  ],
  (
    consignments,
    isSoldBySaleLotIdLookup,
    saleLotIdsByConsignmentIdLookup,
    isSoldFieldValue,
    unfilteredNominationIds,
  ) =>
    isSoldFieldValue === null
      ? unfilteredNominationIds
      : Object.values(consignments)
          .filter(consignment => {
            const saleLotIds = saleLotIdsByConsignmentIdLookup[consignment.id];
            // If we don't have any sale lots yet... none are sold.
            if (!saleLotIds) {
              return isSoldFieldValue[0];
            }
            return saleLotIds.some(
              saleLotId =>
                isSoldBySaleLotIdLookup[saleLotId] === isSoldFieldValue[0],
            );
          })
          .map(consignment => consignment.nominationId),
);

const selectIsVendorPostSaleBalancedFilteredNominationIds =
  lookupNestedFilterSelectorFactory(
    selectIsPostSaleBalancedByConsignmentIdLookup,
    GlobalSearchFields.IsPostSaleVendorBalanced,
    selectUnfilteredNominationIds,
    isVendorBalanced =>
      ([_ignored, isBalanced]) =>
        isBalanced === isVendorBalanced[0],
    getConsignments,
    "nominationId",
  );

const selectIsVendorPreSaleBalancedFilteredNominationIds =
  lookupNestedFilterSelectorFactory(
    selectIsPreSaleBalancedByConsignmentIdLookup,
    GlobalSearchFields.IsPreSaleVendorBalanced,
    selectUnfilteredNominationIds,
    isVendorPreSaleBalanced =>
      ([_ignored, isBalanced]) =>
        isBalanced === isVendorPreSaleBalanced[0],
    getConsignments,
    "nominationId",
  );

const selectAuctionPenFilteredNominationIds = lookupNestedFilterSelectorFactory(
  selectAuctionPenIdsByConsignmentIdLookup,
  GlobalSearchFields.AuctionPen,
  selectUnfilteredNominationIds,
  auctionPensFilteredComparator,
  getConsignments,
  "nominationId",
);
const selectDeliveryPenFilteredNominationIds =
  lookupNestedFilterSelectorFactory(
    selectDeliveryPenIdsByConsignmentIdLookup,
    GlobalSearchFields.DeliveryPen,
    selectUnfilteredNominationIds,
    deliveryPensFilteredComparator,
    getConsignments,
    "nominationId",
  );

const selectOverflowPenFilteredNominationIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.OverflowPen,
    selectUnfilteredNominationIds,
    saleLotsOverflowPensComparator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectLaneFilteredNominationIds = lookupNestedFilterSelectorFactory(
  selectAuctionPenIdsByConsignmentIdLookup,
  GlobalSearchFields.Lane,
  selectUnfilteredNominationIds,
  auctionPensLanesComparator,
  getConsignments,
  "nominationId",
);

const selectLabelFilteredNominationIds = nestedRawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Label,
  selectUnfilteredNominationIds,
  saleLotsFilteredLabelsComparator,
  "nominationId",
  getConsignments,
  "consignment_id",
);

const selectPricingTypeFilteredNominationIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.PricingType,
    selectUnfilteredNominationIds,
    saleLotsFilteredPricingTypesComparator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectHasProgenyFilteredNominationIds = createSelector(
  [
    getSaleLots,
    selectCanHaveProgenyBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasProgeny),
    selectUnfilteredNominationIds,
    getConsignments,
  ],
  (
    saleLots,
    canHaveProgenyBySaleLotIdLookup,
    hasProgeny,
    unfilteredNominationIds,
    consignments,
  ) =>
    hasProgeny === null
      ? unfilteredNominationIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              canHaveProgenyBySaleLotIdLookup[saleLot.id] === hasProgeny[0],
          )
          .map(saleLot => consignments[saleLot.consignment_id].nominationId),
);

const selectScanFilteredNominationIds = createSelector(
  [
    selectConsignmentIdByEidLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Scan),
    selectUnfilteredNominationIds,
    getConsignments,
  ],
  (consignmentIdByEidLookup, eids, unfilteredNominationIds, consignments) =>
    eids === null
      ? unfilteredNominationIds
      : eids
          .map(eid => consignments[consignmentIdByEidLookup[eid]].nominationId)
          .filter(Boolean),
);
const selectVendorNumberFilteredNominationsIds = rawObjectFilterSelectorFactory(
  getConsignments,
  GlobalSearchFields.VendorNumber,
  selectUnfilteredNominationIds,
  consignmentFilteredVendorNumberComparator,
  "nominationId",
);

const selectVendorPicFilteredNominationsIds = rawObjectFilterSelectorFactory(
  getConsignments,
  GlobalSearchFields.VendorPic,
  selectUnfilteredNominationIds,
  consignmentFilteredVendorPicsComparator,
  "nominationId",
);

const selectBuyerPicFilteredNominationsIds =
  nestedRawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.BuyerPic,
    selectUnfilteredNominationIds,
    saleLotsFilteredPropertyIdComparator,
    "nominationId",
    getConsignments,
    "consignment_id",
  );

const selectCheckpointFilteredNominationsIds = rawObjectFilterSelectorFactory(
  getConsignments,
  GlobalSearchFields.Checkpoint,
  selectUnfilteredNominationIds,
  consignmentFilteredCheckpointComparator,
  "nominationId",
);

const selectIsAuctionsPlusCompliantNominationIds = createSelector(
  [
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredNominationIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsAuctionsPlusCompliant),
    getConsignments,
    selectSaleLotIdsByConsignmentIdLookup,
  ],
  (
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredNominationIds,
    searchField,
    consignments,
    saleLotIdsByConsignmentIdLookup,
  ) =>
    searchField === null
      ? unfilteredNominationIds
      : Object.values(consignments)
          .filter(consignment => {
            const saleLotIds = saleLotIdsByConsignmentIdLookup[consignment.id];
            if (!saleLotIds) {
              return searchField[0];
            }
            return saleLotIds.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.AUCTIONS_PLUS
                ] === searchField[0],
            );
          })
          .map(consignment => consignment.nominationId),
);

const selectIsStockLiveCompliantNominationIds = createSelector(
  [
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredNominationIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsStockLiveCompliant),
    getConsignments,
    selectSaleLotIdsByConsignmentIdLookup,
  ],
  (
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredNominationIds,
    searchField,
    consignments,
    saleLotIdsByConsignmentIdLookup,
  ) =>
    searchField === null
      ? unfilteredNominationIds
      : Object.values(consignments)
          .filter(consignment => {
            const saleLotIds = saleLotIdsByConsignmentIdLookup[consignment.id];
            if (!saleLotIds) {
              return searchField[0];
            }
            return saleLotIds.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.STOCK_LIVE
                ] === searchField[0],
            );
          })
          .map(consignment => consignment.nominationId),
);

const selectIsSaleLotImageUploadedNominationsIds =
  filterAndMapObjectIdsWithLookup(
    getSaleLots,
    GlobalSearchFields.IsSaleLotImageUploaded,
    "consignment_id",
    selectUnfilteredNominationIds,
    getConsignments,
    selectSaleLotHasImageLookup,
    (lot, objectKey, consignments) => {
      const consignment = consignments[lot?.consignment_id];
      return consignment ? consignment.nominationId : null;
    },
  );

const selectIsConsignmentImageUploadedNominationIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentImageUploaded,
    "nominationId",
    selectUnfilteredNominationIds,
    getSaleLots,
    selectConsignmentHasImageLookup,
  );

const selectIsSaleLotVideoUploadedNominationsIds =
  filterAndMapObjectIdsWithLookup(
    getSaleLots,
    GlobalSearchFields.IsSaleLotVideoUploaded,
    "consignment_id",
    selectUnfilteredNominationIds,
    getConsignments,
    selectSaleLotHasVideoLookup,
    (lot, objectKey, consignments) => {
      const consignment = consignments[lot?.consignment_id];
      return consignment ? consignment.nominationId : null;
    },
  );

const selectIsConsignmentVideoUploadedNominationIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentVideoUploaded,
    "nominationId",
    selectUnfilteredNominationIds,
    getSaleLots,
    selectConsignmentHasVideoLookup,
  );

const selectReceivalPenNominationsIds = rawObjectFilterSelectorFactory(
  getConsignments,
  GlobalSearchFields.ReceivalPen,
  selectUnfilteredNominationIds,
  consignmentFilteredReceivalPenComparator,
  "nominationId",
);

const selectMarksFilteredNominationsIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredNominationIds,
    getConsignments,
  ],
  (saleLots, marks, unfilteredNominationIds, consignments) =>
    marks === null
      ? unfilteredNominationIds
      : Object.values(saleLots)
          .filter(saleLot =>
            marks.some(mark => mark === getSortedMarkDetails(saleLot)),
          )
          .map(lot => consignments[lot.consignment_id].nominationId),
);

export const selectFilteredNominationIds = createSelector(
  [
    selectAgencyFilteredNominationIds,
    selectAuctionPenFilteredNominationIds,
    selectBuyerFilteredNominationIds,
    selectBuyerAndBuyerWayFilteredNominationIds,
    selectDeliveryPenFilteredNominationIds,
    selectHasArrivedFilteredNominationIds,
    selectHasBuyerExceptionsFilteredNominationIds,
    selectHasConsignmentScansFilteredNominationIds,
    selectHasOverflowFilteredNominationIds,
    selectHasProgenyFilteredNominationIds,
    selectHasVendorExceptionsFilteredNominationIds,
    selectHasWeightFilteredNominationIds,
    selectIsBuyerBalancedFilteredNominationIds,
    selectIsCountedFilteredNominationIds,
    selectIsDeliveredFilteredNominationIds,
    selectIsEidCompliantFilteredNominationIds,
    selectIsInProgressFilteredNominationIds,
    selectIsSoldFilteredNominationIds,
    selectIsVendorPreSaleBalancedFilteredNominationIds,
    selectIsVendorPostSaleBalancedFilteredNominationIds,
    selectLabelFilteredNominationIds,
    selectLaneFilteredNominationIds,
    selectOverflowPenFilteredNominationIds,
    selectPricingTypeFilteredNominationIds,
    selectSaleRoundFilteredNominationIds,
    selectScanFilteredNominationIds,
    selectThirdPartyFilteredNominationIds,
    selectVendorFilteredNominationIds,
    selectVendorNumberFilteredNominationsIds,
    selectVendorPicFilteredNominationsIds,
    selectBuyerPicFilteredNominationsIds,
    selectCheckpointFilteredNominationsIds,
    selectIsAuctionsPlusCompliantNominationIds,
    selectIsStockLiveCompliantNominationIds,
    selectIsSaleLotImageUploadedNominationsIds,
    selectIsConsignmentImageUploadedNominationIds,
    selectIsSaleLotVideoUploadedNominationsIds,
    selectIsConsignmentVideoUploadedNominationIds,
    selectReceivalPenNominationsIds,
    selectMarksFilteredNominationsIds,
  ],
  (
    agencyFilteredNominationIds,
    auctionPenFilteredNominationIds,
    buyerFilteredNominationIds,
    buyerAndBuyerWayFilteredNominationIds,
    deliveryPenFilteredNominationIds,
    hasArrivedFilteredNominationIds,
    hasBuyerExceptionsFilteredNominationIds,
    hasConsignmentScansFilteredNominationIds,
    hasOverflowFilteredNominationIds,
    hasProgenyFilteredNominationIds,
    hasVendorExceptionsFilteredNominationIds,
    hasWeightFilteredNominationIds,
    isBuyerBalancedFilteredNominationIds,
    isCountedFilteredNominationIds,
    isDeliveredFilteredNominationIds,
    isEidCompliantFilteredNominationIds,
    isInProgressFilteredNominationIds,
    isSoldFilteredNominationIds,
    isVendorPreSaleBalancedFilteredNominationIds,
    isVendorPostSaleBalancedFilteredNominationIds,
    labelFilteredNominationIds,
    laneFilteredNominationIds,
    overflowPenFilteredNominationIds,
    pricingTypeFilteredNominationIds,
    saleRoundFilteredNominationIds,
    scanFilteredNominationIds,
    selectThirdPartyFilteredNominationIds,
    vendorFilteredNominationIds,
    vendorNumberFilteredNominationsIds,
    vendorPicFilteredNominationsIds,
    buyerPicFilteredNominationsIds,
    checkpointFilteredNominationsIds,
    isAuctionsPlusCompliantNominationIds,
    isStockLiveCompliantNominationIds,
    isSaleLotImageUploadedNominationIds,
    isConsignmentImageUploadedNominationIds,
    isSaleLotVideoUploadedNominationsIds,
    isConsignmentVideoUploadedNominationIds,
    receivalPenNominationsIds,
    marksFilteredNominationsIds,
  ) =>
    intersection(
      agencyFilteredNominationIds,
      auctionPenFilteredNominationIds,
      buyerFilteredNominationIds,
      buyerAndBuyerWayFilteredNominationIds,
      deliveryPenFilteredNominationIds,
      hasArrivedFilteredNominationIds,
      hasBuyerExceptionsFilteredNominationIds,
      hasConsignmentScansFilteredNominationIds,
      hasOverflowFilteredNominationIds,
      hasProgenyFilteredNominationIds,
      hasVendorExceptionsFilteredNominationIds,
      hasWeightFilteredNominationIds,
      isBuyerBalancedFilteredNominationIds,
      isCountedFilteredNominationIds,
      isDeliveredFilteredNominationIds,
      isEidCompliantFilteredNominationIds,
      isInProgressFilteredNominationIds,
      isSoldFilteredNominationIds,
      isVendorPreSaleBalancedFilteredNominationIds,
      isVendorPostSaleBalancedFilteredNominationIds,
      labelFilteredNominationIds,
      laneFilteredNominationIds,
      overflowPenFilteredNominationIds,
      pricingTypeFilteredNominationIds,
      saleRoundFilteredNominationIds,
      scanFilteredNominationIds,
      selectThirdPartyFilteredNominationIds,
      vendorFilteredNominationIds,
      vendorNumberFilteredNominationsIds,
      vendorPicFilteredNominationsIds,
      buyerPicFilteredNominationsIds,
      checkpointFilteredNominationsIds,
      isAuctionsPlusCompliantNominationIds,
      isStockLiveCompliantNominationIds,
      isSaleLotImageUploadedNominationIds,
      isConsignmentImageUploadedNominationIds,
      isSaleLotVideoUploadedNominationsIds,
      isConsignmentVideoUploadedNominationIds,
      receivalPenNominationsIds,
      marksFilteredNominationsIds,
    ),
);
