import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import {
  NominationAction,
  patchConsignment,
  receiveConsignments,
  receiveConsignmentsChanges,
  requestConsignmentsChanges,
  requestConsignmentsError,
  setAdditionalPICs,
  setSetting,
  updateAttachment,
  updateDeclaration,
  uploadRequest,
  uploadScansAndDraftingInformation,
} from "actions";

import { addConsignment, addSaleLot } from "actions/offline";

import {
  ADD_CONSIGNMENT_COMMIT,
  ADD_CONSIGNMENT_OFFLINE,
  ADD_CONSIGNMENT_ROLLBACK,
  ADD_CONSIGNMENT_SALEYARD_SCANS,
  ADD_CONSIGNMENT_TO_OTHER_SALE,
  ADD_SALE_LOT_COMMIT,
  ADD_SALE_LOT_ROLLBACK,
  DELETE_CONSIGNMENT_COMMIT,
  DELETE_CONSIGNMENT_ROLLBACK,
  GET_CONSIGNMENTS,
  GET_CONSIGNMENTS_CHANGES,
  PATCH_CONSIGNMENT_COMMIT,
  PATCH_CONSIGNMENT_ROLLBACK,
  UPDATE_CONSIGNMENT_MEGA,
  UPDATE_DECLARATION_FAILURE,
  UPDATE_DECLARATION_SUCCESS,
} from "constants/actionTypes";
import { SaleTypes } from "constants/sale";
import { SaleLotType } from "constants/saleLots";
import { Settings } from "constants/settings";

import { convertType } from "lib";

import {
  getLivestockSaleId,
  getSaleUrl,
  openEditConsignmentModal,
} from "lib/navigation";
import toast from "lib/toast";

import {
  currentSaleSelector,
  getBusinessById,
  getConsignments,
  getSales,
  getSaleyardScanSaleLots,
} from "selectors";

import { api } from "./api";

export function* fetchConsignment(id, livestockSaleId = null) {
  // Fetch a single consignment

  try {
    const consignmentsPromise = yield call(api.get, `/v2/consignment/${id}/`);
    // TODO: There should probably be a singular version of this function.
    yield put(
      receiveConsignments(
        livestockSaleId,
        [yield consignmentsPromise],
        undefined,
        id,
      ),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("There was an error", e);
    yield put(requestConsignmentsError(e.statusText));
  }
}

function* fetchConsignments(action) {
  // Fetch all consignments for a given sale
  try {
    const { sale } = action;
    if (sale?.livestocksale_id && sale?.saleyard_name) {
      const saleUrl = getSaleUrl(sale);
      const consignmentsResponsePromise = yield call(
        api.get,
        `${saleUrl}/consignments/`,
      );
      const consignmentsResponse = yield consignmentsResponsePromise;
      const consignments = yield consignmentsResponse.JSON;

      const { lastModifiedTimestamp, cacheHit } = consignmentsResponse;
      yield put(
        receiveConsignments(
          sale.livestocksale_id,
          consignments,
          lastModifiedTimestamp,
        ),
      );

      if (cacheHit) {
        yield put(requestConsignmentsChanges(sale));
      }
    }
  } catch (e) {
    yield call(api.handleFetchError, e, "consignments", action);
    yield put(requestConsignmentsError(e.statusText));
  }
}

function* fetchConsignmentChanges(action) {
  // Fetch changed consignments for a given sale
  try {
    const { sale } = action;
    if (sale?.livestocksale_id && sale?.saleyard_name) {
      const state = yield select();
      const saleUrl = getSaleUrl(sale);
      const changesSince = state.consignments.lastModifiedTimestamp;

      const consignmentsResponsePromise = yield call(
        api.get,
        `${saleUrl}/consignments/?changesSince=${changesSince}`,
      );
      const consignmentsResponse = yield consignmentsResponsePromise;
      const consignments = yield consignmentsResponse.JSON;
      const { lastModifiedTimestamp } = consignmentsResponse;
      yield put(
        receiveConsignmentsChanges(
          sale.livestocksale_id,
          consignments,
          lastModifiedTimestamp,
        ),
      );
    }
  } catch (e) {
    yield call(api.handleFetchError, e, "consignments", action);
    yield put(requestConsignmentsError(e.statusText));
  }
}

function* updateTempConsignments(action) {
  const { id, vendor_number } = action.payload;
  const { attachmentParams } = action.meta;
  try {
    if (vendor_number) {
      toast.success("Consignment created and vendor number assigned.");
    } else {
      toast.success("Consignment created.");
    }
    if (attachmentParams) {
      const { nvdFile } = attachmentParams;
      const formData = {
        consignment: id,
        type: "NVD",
      };
      yield put(uploadRequest(nvdFile, formData));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  yield;
}

// PATCH_CONSIGNMENT_COMMIT
function* updateConsignment(action) {
  const { initial_vendor_number } = action.meta;
  const { vendor_number } = action.payload;
  try {
    if (
      initial_vendor_number &&
      // Lyle - check the type of vendor_number `!vendorNumber` could indicate either the value wasn't updated, or it was removed.
      // We need to check that it was updated before checking if it was removed
      vendor_number !== undefined &&
      !vendor_number
    ) {
      // Info, so they need to acknowledge it.
      toast.info(`Consignment updated successfully and vendor number removed.`);
    } else if (!initial_vendor_number && vendor_number) {
      toast.success(
        `Consignment updated successfully and vendor number assigned.`,
      );
    } else if (
      initial_vendor_number &&
      vendor_number &&
      initial_vendor_number !== vendor_number
    ) {
      toast.success(
        `Consignment updated successfully and vendor number updated.`,
      );
    } else {
      toast.success(`Consignment updated successfully`);
    }
    yield;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

function* deleteConsignmentCommit(action) {
  const { consignment } = action.meta;

  const { vendor_id } = consignment;

  yield select();
  const vendorName = getBusinessById(vendor_id)?.name || "";

  toast.success(
    `${vendorName} consignment deleted successfully (HC ${consignment.quantity})`,
  );
}

function* deleteConsignmentRolllBack(action) {
  const { id } = action.meta;
  const { livestocksale_id } = action.meta.consignment;
  yield call(fetchConsignment, id, livestocksale_id);
  toast.error(`${action.payload.response.join(", ")}`);
}

function* updateConsignmentMega(action) {
  // Updates the declaration, consignment, attachment and consignment additional pics.
  const values = action.payload;
  const { changeReason = null } = action.options;

  const declaration = Object.keys(values.declaration).reduce((acc, cur) => {
    acc[cur] = convertType(values.declaration[cur]);
    return acc;
  }, {});
  // Update the attachment to link to the consignment
  yield put(
    updateAttachment(
      {
        ...values.selectedAttachment,
        consignment: values.id,
        type: values.documentType,
        branch_id: values.vendor.branchId,
      },
      values.vendor,
    ),
  );

  // Add the declaration responses
  yield put(updateDeclaration(values.id, declaration));

  const { success: updateDeclarationSuccess } = yield race({
    success: take(UPDATE_DECLARATION_SUCCESS),
    error: take(UPDATE_DECLARATION_FAILURE),
    timeout: delay(10000),
  });
  // Wait for that request to return successfully before patching the consignment
  if (updateDeclarationSuccess) {
    // In this context, we just want to update NVD (Number), vendor_property_id, branch_id
    const patchValues = {
      vendor_property_id: values.vendor_property_id,
      vendor_id: values.vendor_id,
      NVD: values.NVD,
      branch_id: values.vendor.branchId,
      brands: values.brands,
    };

    yield put(patchConsignment(patchValues, values.id, { changeReason }));
  }

  const { success: patchSuccess } = yield race({
    success: take(PATCH_CONSIGNMENT_COMMIT),
    error: take(PATCH_CONSIGNMENT_ROLLBACK),
    timeout: delay(10000),
  });
  // Wait for the patch to return successfully then add any additional properties.
  if (patchSuccess) {
    yield put(setAdditionalPICs(values.id, values.additional_properties));
  }
}

function* addConsignmentRequest(action) {
  const state = yield select();
  const currentSale = currentSaleSelector(state);

  const { nomination_id: nominationId } = action.payload;

  if (nominationId) {
    yield put(NominationAction.update({ has_arrived: true, id: nominationId }));
  }
  // For clearing sales, we want to edit the consignment after adding.
  if ([SaleTypes.CLEARING].includes(currentSale.sale_type)) {
    openEditConsignmentModal(action.payload.id, undefined, true);
  }

  yield put(setSetting(Settings.lastAddedConsignmentId, action.payload.id));
}

function* ensureSaleyardScansSaleLot(action) {
  const { consignmentId, scans } = action;
  const state = yield select();

  const consignments = getConsignments(state);
  const sales = getSales(state);

  const livestockSaleId = getLivestockSaleId();

  const consignment = consignments[consignmentId];
  // We need the sale to pull out a sale round and pricing type, as the API requires them.
  const sale = sales[livestockSaleId];

  if (!consignment) {
    toast.error(
      "Failed adding Scans to Consignment. Could not find Consignment.",
    );
    // eslint-disable-next-line no-console
    console.error("Could not find consignment '%s'", consignmentId);
    return;
  }
  const saleyardScanSaleLots = getSaleyardScanSaleLots(state);

  const consignmentSaleyardScanSaleLots = Object.values(
    saleyardScanSaleLots,
  ).filter(saleLot => saleLot.consignmentId === consignmentId);

  let saleLotId = uuidv4();
  if (consignmentSaleyardScanSaleLots.length === 0) {
    // The consignment doesn't have a Saleyard Scan Sale Lot yet, so we need to create it
    yield put(
      addSaleLot(
        {
          consignmentId,
          livestockSaleId,
          pricingTypeId: sale.pricing_type_id,
          quantity: consignment.quantity,
          saleRoundId: sale.rounds[0],
          saleLotType: SaleLotType.SALEYARD_SCAN,
        },
        saleLotId,
      ),
    );
    // Wait for either a success or a failure
    let requestSucceeded = null;
    do {
      const { success, failure } = yield race({
        success: take(ADD_SALE_LOT_COMMIT),
        failure: take(ADD_SALE_LOT_ROLLBACK),
      });
      if (success) {
        if (success.meta.tempId === saleLotId) {
          requestSucceeded = true;
          // update the saleLot Id from the API response, this id is very unlikely to
          // have changed, but we should be sure to capture the change anyway
          saleLotId = success.payload.id;
        }
      } else if (failure) {
        if (failure.meta.tempId === saleLotId) {
          requestSucceeded = false;
        }
      }
    } while (requestSucceeded === null);

    // Notify the user of a failure
    if (!requestSucceeded) {
      toast.error(
        "Failed adding Scans to Consignment. Could not create Sale Lot.",
      );
      return;
    }
  } else if (consignmentSaleyardScanSaleLots.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(
      "More than one Saleyard Scan Sale Lot was found attached to this consignment, using first.",
      consignmentId,
      consignmentSaleyardScanSaleLots,
    );

    // Use the first Saleyard Scan Sale Lot
    saleLotId = consignmentSaleyardScanSaleLots[0].id;
  } else {
    // Use the existing Saleyard Scan Sale Lot
    saleLotId = consignmentSaleyardScanSaleLots[0].id;
  }
  yield put(uploadScansAndDraftingInformation(scans, saleLotId));
}

function* synchronousAddConsignment(action) {
  const { consignmentPayload, selectedSale, agencyId, options = {} } = action;
  const { onSuccess, onError, onTimeout } = options;

  // Dispatch, then wait for either success or failure.
  const tempId = uuidv4();
  yield put(
    addConsignment(
      tempId,
      consignmentPayload,
      selectedSale,
      null,
      null,
      null,
      agencyId,
    ),
  );

  const { success, error, timeout } = yield race({
    success: take(ADD_CONSIGNMENT_COMMIT),
    error: take(ADD_CONSIGNMENT_ROLLBACK),
    timeout: delay(10000),
  });

  success && typeof onSuccess === "function" && onSuccess(success);
  error && typeof onError === "function" && onError(error);
  timeout && typeof onTimeout === "function" && onTimeout(timeout);
}

export default function* consignmentSagas() {
  yield takeEvery(GET_CONSIGNMENTS, fetchConsignments);
  yield takeLeading(GET_CONSIGNMENTS_CHANGES, fetchConsignmentChanges);
  yield takeEvery(ADD_CONSIGNMENT_COMMIT, updateTempConsignments);
  yield takeEvery(PATCH_CONSIGNMENT_COMMIT, updateConsignment);
  yield takeEvery(DELETE_CONSIGNMENT_COMMIT, deleteConsignmentCommit);
  yield takeEvery(DELETE_CONSIGNMENT_ROLLBACK, deleteConsignmentRolllBack);
  yield takeEvery(UPDATE_CONSIGNMENT_MEGA, updateConsignmentMega);
  yield takeEvery(ADD_CONSIGNMENT_OFFLINE, addConsignmentRequest);
  yield takeEvery(ADD_CONSIGNMENT_SALEYARD_SCANS, ensureSaleyardScansSaleLot);
  yield takeEvery(ADD_CONSIGNMENT_TO_OTHER_SALE, synchronousAddConsignment);
}
