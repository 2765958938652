import React from "react";

import {
  SundryPartyType,
  SundryPartyTypeLookup,
} from "constants/sundryTemplates";

const { getDollarPriceStringFromCents } = require("lib");

const getPaymentDescription = amount => (amount > 0 ? "credited" : "debited");

export const getSundryLabelByTemplateAndValue = (
  template,
  amount,
  paidFromBusiness,
  paidToBusiness,
) => {
  const { label } = template;
  const amountString = `$${getDollarPriceStringFromCents(Math.abs(amount))}`;

  const { fromType, toType } = SundryPartyTypeLookup[label];

  return (
    <ul className="text-left">
      {toType !== SundryPartyType.DEPLOYMENT && (
        <li>
          {`${paidToBusiness} will be ${getPaymentDescription(amount)} ${amountString} on their ${toType}`}
        </li>
      )}
      {fromType !== SundryPartyType.DEPLOYMENT && (
        <li>
          {`${paidFromBusiness} will be ${getPaymentDescription(amount * -1)}  ${amountString} on their ${fromType}`}
        </li>
      )}
    </ul>
  );
};
