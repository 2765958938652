import React from "react";

import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import PropTypes from "prop-types";

import { ChangeHandler, SubmitHandler } from "components/Form";
import ScanToPenForm from "components/ScanToPenModal/ScanToPenForm";
import { ValidationSchema } from "components/ScanToPenModal/validationSchema";

import { getStartPenComponents } from "lib/auctionPens";

import { SelectedLayout } from "./Layout";

export function SaleLotDefaults(props) {
  const { onChange, penName = null, setIsSubmitEnabled } = props;

  const initialValues = {
    ...getStartPenComponents(penName),
  };

  return (
    <SelectedLayout>
      <Formik initialValues={initialValues} validationSchema={ValidationSchema}>
        <div>
          <Grid container spacing={2}>
            <SubmitHandler setIsSubmitEnabled={setIsSubmitEnabled} />
            <ChangeHandler onChange={onChange} />
            <ScanToPenForm />
          </Grid>
        </div>
      </Formik>
    </SelectedLayout>
  );
}
SaleLotDefaults.propTypes = {
  onChange: PropTypes.func,
  penName: PropTypes.string,
  setIsSubmitEnabled: PropTypes.func,
};
