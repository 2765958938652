import { simpleUserSerializerMap } from "lib/serializers/simpleUser";
import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

export const commentSerializerMap = {
  id: "id",
  created: "created",
  creatingUser: simpleUserSerializerMap,
  comment: "comment",
  resolvedDate: "resolved_date",
  resolvingUser: simpleUserSerializerMap,
};

const nestedObjectMap = {
  resolving_user: "resolvingUser",
  creating_user: "creatingUser",
};

export const serializeComment = buildSerializer(
  commentSerializerMap,
  "Comment",
  nestedObjectMap,
);

export const deserializeComment = buildDeserializer(
  commentSerializerMap,
  "Comment",
  nestedObjectMap,
);
