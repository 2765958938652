import { sortBy, sum, uniq } from "lodash";
import flatten from "lodash/flatten";
import { createSelector } from "reselect";

import {
  EID_TRANSFER_STATUS,
  NLISFileTypes,
  TRANSFER_STATUS,
} from "constants/nlis";

import { EMPTY_OBJECT } from "lib";

import { pluralize, uniqOrCount } from "lib/pluralize";
import {
  formatDateTimeUTC,
  formatUTCToLocalDateString,
  formatUTCToLocalTimeString,
} from "lib/timeFormats";

import {
  createLookupCombiner,
  getBusinesses,
  getP2PFiles,
  getConsignments,
  getNLISFiles,
  getSellFiles,
  getSellReversals,
  getTakeFiles,
  getTakeReversals,
  getP2PReversals,
  getScans,
  getSightingFiles,
  selectVendorIdByConsignmentIdLookup,
} from "selectors";

import {
  selectBuyerDisplayNameBySaleLotIdLookup,
  selectVendorNameBySaleLotIdLookup,
} from "selectors/businesses";
import { selectSaleLotIdsByConsignmentIdLookup } from "selectors/indexes";

export const selectSellFileBySaleLotIdLookup = createSelector(
  [getSellFiles],
  sellFiles =>
    sortBy(Object.values(sellFiles), "created")
      .reverse()
      .reduce((acc, sf) => {
        (sf.saleLots || []).forEach(saleLotId => {
          if (!acc[saleLotId]) {
            acc[saleLotId] = [sf];
          } else {
            acc[saleLotId].push(sf);
          }
        });
        return acc;
      }, {}),
);

export const getSellFileBySaleLotId = (sellFiles, saleLotId) => {
  if (Array.isArray(sellFiles[saleLotId]) && sellFiles[saleLotId].length > 0) {
    return sellFiles[saleLotId][0];
  }
  return null;
};

export const selectTakeFilesByConsignmentIdLookup = createSelector(
  [getConsignments, getTakeFiles],
  (consignments, takeFiles) =>
    sortBy(Object.values(takeFiles), "created")
      .reverse()
      .reduce(
        (acc, tf) => {
          tf.consignments.forEach(consignmentId => {
            if (!acc[consignmentId]) {
              acc[consignmentId] = [tf];
            } else {
              acc[consignmentId].push(tf);
            }
          });
          return acc;
        },
        Object.keys(consignments).reduce((acc, consignmentId) => {
          acc[consignmentId] = [];
          return acc;
        }, {}),
      ),
);

export const selectTakeReversalByTakeFileIdLookup = createSelector(
  [getTakeReversals],
  takeReversals =>
    Object.values(takeReversals).reduce((acc, tr) => {
      acc[tr.reversalOf] = tr;
      return acc;
    }, {}),
);

export const selectTakeReversalByTakeFileId = takeFileId => state =>
  selectTakeReversalByTakeFileIdLookup(state)[takeFileId] || null;

export const getTakeFilesWithExtraInfo = createSelector(
  [selectTakeFilesByConsignmentIdLookup, selectTakeReversalByTakeFileIdLookup],
  (takeFiles, takeReversals) => {
    const completedCalcs = {};
    return Object.entries(takeFiles).reduce(
      (takeFileObj, [id, theseTakeFiles]) => {
        const takeFileArray = Array.isArray(theseTakeFiles)
          ? theseTakeFiles
          : [theseTakeFiles];
        if (takeFileArray) {
          takeFileObj[id] = takeFileArray.map(takeFile => {
            if (!completedCalcs[takeFile.id]) {
              const eids = flatten(
                [].concat(
                  (takeFile.transfers || []).map(transfer =>
                    transfer.eids.map(eid => eid.EID),
                  ),
                ),
              );
              const eidErrors = flatten(
                [].concat(
                  (takeFile.transfers || []).map(transfer =>
                    transfer.eids.filter(
                      eid => eid.response_type === EID_TRANSFER_STATUS.ERROR,
                    ),
                  ),
                ),
              );

              const eidWarnings = flatten(
                [].concat(
                  (takeFile.transfers || []).map(transfer =>
                    transfer.eids.filter(
                      eid => eid.response_type === EID_TRANSFER_STATUS.WARNING,
                    ),
                  ),
                ),
              );
              const takeReversal = takeReversals[takeFile.id];
              const eidCount =
                takeFile.pending ||
                takeFile.status === TRANSFER_STATUS.UNSUBMITTED
                  ? "-"
                  : eids.length;
              completedCalcs[takeFile.id] = Object.assign({}, takeFile, {
                consignmentCount: takeFile.consignments
                  ? takeFile.consignments.length
                  : 0,
                eids,
                eidErrors,
                eidWarnings,
                eidCount,
                formattedCreated: takeFile.created
                  ? formatDateTimeUTC(takeFile.created)
                  : "-",
                formattedSubmitted: takeFile.submitted
                  ? formatDateTimeUTC(takeFile.submitted)
                  : "-",
                formattedProcessed: takeFile.processed
                  ? formatDateTimeUTC(takeFile.processed)
                  : "-",
                takeReversal,
              });
            }
            return completedCalcs[takeFile.id];
          });
        }

        return takeFileObj;
      },
      {},
    );
  },
);

export const selectReversableTakeFileIds = createSelector(
  [
    getTakeFiles,
    selectSaleLotIdsByConsignmentIdLookup,
    selectSellFileBySaleLotIdLookup,
  ],
  (takeFiles, saleLotIdsByConsignmentIdLookup, sellFiles) =>
    // A take file is reversable if:
    // it is success (XXX - or warning?)
    // None of the sale lots, within the consignments, are SOLD (ie, sell files exist, and
    // latest sell file is in any state other than rolled back XXX)
    Object.values(takeFiles)
      .filter(takeFile => {
        if (takeFile.status !== TRANSFER_STATUS.SUCCESS) {
          return false;
        }
        // Rummage out the take files, for the sale lots, for each of these consignments; if they
        // exist, and are not rolled back (XXX failed?) allow rollback of the corresponding take.
        return (
          (takeFile.consignments || []).filter(
            consignmentId =>
              saleLotIdsByConsignmentIdLookup[consignmentId].filter(
                saleLotId =>
                  !sellFiles[saleLotId] ||
                  sellFiles[saleLotId].length === 0 ||
                  sortBy(sellFiles[saleLotId], "created").reverse()[0]
                    .status === TRANSFER_STATUS.ROLLED_BACK,
              ).length === 0,
          ).length === 0
        );
      })
      .map(takeFile => takeFile.id),
);

export const getIsTakeFileReversable = takeFileId => state => {
  return selectReversableTakeFileIds(state).includes(takeFileId);
};

export const selectSellReversalBySellFileIdLookup = createSelector(
  [getSellReversals],
  sellReversals =>
    Object.values(sellReversals).reduce((acc, tr) => {
      acc[tr.reversalOf] = tr;
      return acc;
    }, {}),
);

export const selectSellReversalBySellFileId = sellFileId => state =>
  selectSellReversalBySellFileIdLookup(state)[sellFileId] || null;

export const selectReversableSellFileIds = createSelector(
  [getSellFiles],
  sellFiles =>
    Object.values(sellFiles)
      .filter(sellFile => sellFile.status === TRANSFER_STATUS.SUCCESS)
      .map(sellFile => sellFile.id),
);

export const getIsSellFileReversable = sellFileId => state => {
  return selectReversableSellFileIds(state).includes(sellFileId);
};

const getTakeFileVendorDisplay = (
  consignments,
  vendorIdByConsignmentIdLookup,
  businesses,
) => {
  if (consignments) {
    if (consignments.length > 1) {
      return `${consignments.length} Consignments`;
    } else {
      const vendorId = vendorIdByConsignmentIdLookup[consignments[0]];
      const vendorName = businesses[vendorId]?.name;
      return [consignments[0]?.vendorNumber, vendorName]
        .filter(Boolean)
        .join(" ");
    }
  } else {
    return undefined;
  }
};

const getSellFileBuyerDisplay = (
  saleLots,
  buyerDisplayNameBySaleLotIdLookup,
) => {
  let buyerDisplay = "";
  if (saleLots) {
    const numLots = saleLots.length;
    const lotText = `${numLots} ${pluralize("Lot", numLots)}`;
    const buyers = uniq(
      saleLots.map(saleLotId => buyerDisplayNameBySaleLotIdLookup[saleLotId]) ||
        [],
    );
    const numBuyers = buyers.length;

    if (numBuyers === 1) {
      buyerDisplay = `${buyers[0]} : ${lotText}`;
    } else {
      buyerDisplay = `${numBuyers} ${pluralize(
        "Buyer",
        numBuyers,
      )} : ${lotText}`;
    }
  }
  return buyerDisplay;
};

const formatNLISFile =
  (
    vendorIdByConsignmentIdLookup,
    businesses,
    nlisFileLookup,
    buyerDisplayNameBySaleLotIdLookup = {},
  ) =>
  nlisFile => {
    return Object.assign({}, nlisFile, {
      submitted: nlisFile.submitted
        ? formatDateTimeUTC(nlisFile.submitted)
        : "-",
      created: nlisFile.created ? formatDateTimeUTC(nlisFile.created) : "-",
      createdDate: nlisFile.created
        ? formatUTCToLocalDateString(nlisFile.created)
        : "-",
      createdTime: nlisFile.created
        ? formatUTCToLocalTimeString(nlisFile.created)
        : "-",
      processed: nlisFile.processed
        ? formatDateTimeUTC(nlisFile.processed)
        : "-",
      eidCount: sum(nlisFile.transfers?.map(t => t.eids.length)),
      eidSuccessCount: nlisFile.transfers?.reduce((acc, transfer) => {
        acc += transfer.eids.filter(
          e => e.response_type === EID_TRANSFER_STATUS.SUCCESS,
        ).length;
        return acc;
      }, 0),
      vendorDisplay: getTakeFileVendorDisplay(
        nlisFile.consignments,
        vendorIdByConsignmentIdLookup,
        businesses,
      ),
      buyerDisplay: getSellFileBuyerDisplay(
        nlisFile.saleLots,
        buyerDisplayNameBySaleLotIdLookup,
      ),
      relatedTransaction: nlisFile.reversalOf
        ? nlisFileLookup[nlisFile.reversalOf]
        : null,
    });
  };

export const selectNLISTransactionByTypeByIdLookup = createSelector(
  [getNLISFiles, selectVendorIdByConsignmentIdLookup, getBusinesses],
  (nlisFiles, vendorIdByConsignmentIdLookup, businesses) => {
    const formatFunction = formatNLISFile(
      vendorIdByConsignmentIdLookup,
      businesses,
      nlisFiles,
    );

    return Object.values(nlisFiles).reduce((acc, cur) => {
      Object.values(cur.byId).forEach(nlisFile => {
        if (acc[nlisFile.transferType]) {
          acc[nlisFile.transferType][nlisFile.id] = formatFunction(nlisFile);
        } else {
          acc[nlisFile.transferType] = {
            [nlisFile.id]: formatFunction(nlisFile),
          };
        }
      });
      return acc;
    }, {});
  },
);

export const getNLISTransactionByTypeById = (type, id) => state => {
  return (
    selectNLISTransactionByTypeByIdLookup(state)?.[type]?.[id] || EMPTY_OBJECT
  );
};

export const selectIsRollbackAvailableByTypeById = createSelector(
  [
    getTakeFiles,
    getTakeReversals,
    getSellFiles,
    getSellReversals,
    getP2PFiles,
    getP2PReversals,
    getScans,
  ],
  (
    nlisTakeFileByIdLookup,
    nlisTakeReversalFileByIdLookup,
    nlisSellFileByIdLookup,
    nlisSellReversalFileByIdLookup,
    nlisP2PTransferFileByIdLookup,
    nlisP2PTransferReversalFileByIdLookup,
    scanByEidLookup,
  ) => ({
    [NLISFileTypes.NLISTakeFile]: createLookupCombiner(
      (nlisTakeFile, nlisSellFileByIdLookup, scanByEidLookup) => {
        // An NLIS Take File can be rolled back when all EIDs in it are in effectively not transferred out of the yard
        // i.e. No Sell File exits, or the Sell File has been rolled back, or the Sell File didn't submit successfully and is in the ERROR state
        const nlisTransfers = nlisTakeFile.transfers || [];
        const areSellsRolledBack = nlisTransfers.every(nlisTransfer =>
          nlisTransfer.eids.every(nlisTransferEid => {
            const scan = scanByEidLookup[nlisTransferEid.EID] || {};
            if (!scan.latest_sell_file_id) {
              return true;
            }
            const sellFile = nlisSellFileByIdLookup[scan.latest_sell_file_id];
            return (
              sellFile &&
              (sellFile.status === TRANSFER_STATUS.ROLLED_BACK ||
                sellFile.status === TRANSFER_STATUS.FAILED)
            );
          }),
        );
        return (
          nlisTakeFile.status === TRANSFER_STATUS.SUCCESS && areSellsRolledBack
        );
      },
    )(nlisTakeFileByIdLookup, nlisSellFileByIdLookup, scanByEidLookup),
    [NLISFileTypes.NLISTakeReversalFile]: createLookupCombiner(
      // NLISTakeReversalFile are not able to be rolled back
      () => false,
    )(nlisTakeReversalFileByIdLookup),
    [NLISFileTypes.NLISSellFile]: createLookupCombiner(
      nlisSellFile =>
        // An NLIS Sell File can only be rolled back when the transfer was successful
        nlisSellFile.status === TRANSFER_STATUS.SUCCESS,
    )(nlisSellFileByIdLookup),
    [NLISFileTypes.NLISSellReversalFile]: createLookupCombiner(
      // NLISSellReversalFile are not able to be rolled back
      () => false,
    )(nlisSellReversalFileByIdLookup),
    [NLISFileTypes.NLISP2PTransferFile]: createLookupCombiner(
      nlisP2PTransferFile =>
        // An NLIS P2P Transfer File can only be rolled back when the transfer was successful
        nlisP2PTransferFile.status === TRANSFER_STATUS.SUCCESS,
    )(nlisP2PTransferFileByIdLookup),
    [NLISFileTypes.NLISP2PTransferReversalFile]: createLookupCombiner(
      // NLISP2PTransferReversalFile are not able to be rolled back
      () => false,
    )(nlisP2PTransferReversalFileByIdLookup),
  }),
);

export const getIsRollbackAvailableByTypeById = (type, id) => state =>
  selectIsRollbackAvailableByTypeById(state)[type]?.[id] || false;

export const selectTransferOutTransactions = createSelector(
  [
    getSellFiles,
    getSellReversals,
    selectVendorIdByConsignmentIdLookup,
    getBusinesses,
    selectBuyerDisplayNameBySaleLotIdLookup,
  ],
  (
    sellFiles,
    sellReversals,
    vendorIdByConsignmentIdLookup,
    businesses,
    buyerDisplayNameBySaleLotIdLookup,
  ) => {
    const formatFunction = formatNLISFile(
      vendorIdByConsignmentIdLookup,
      businesses,
      sellFiles,
      buyerDisplayNameBySaleLotIdLookup,
    );
    return [].concat
      .apply([], [Object.values(sellFiles), Object.values(sellReversals)])
      .map(formatFunction);
  },
);

export const selectTransferInTransactions = createSelector(
  [
    getTakeFiles,
    getTakeReversals,
    selectVendorIdByConsignmentIdLookup,
    getBusinesses,
  ],
  (takeFiles, takeReversals, vendorIdByConsignmentIdLookup, businesses) => {
    const formatFunction = formatNLISFile(
      vendorIdByConsignmentIdLookup,
      businesses,
      takeFiles,
    );

    return [].concat
      .apply([], [Object.values(takeFiles), Object.values(takeReversals)])
      .map(formatFunction);
  },
);

export const selectSightingTransactions = createSelector(
  [getSightingFiles],
  sightingFiles =>
    Object.values(sightingFiles).map(sf => ({
      ...sf,
      eidCount: sf.sightings.length,
      eidSuccessCount: sf.sightings.filter(
        e => e.status === EID_TRANSFER_STATUS.SUCCESS,
      ).length,
    })),
);

export const selectP2PTransactions = createSelector(
  [
    getP2PFiles,
    getP2PReversals,
    selectBuyerDisplayNameBySaleLotIdLookup,
    selectVendorNameBySaleLotIdLookup,
  ],
  (
    p2pFiles,
    p2pReversals,
    buyerDisplayNameBySaleLotIdLookup,
    vendorNameBySaleLotIdLookup,
  ) => {
    const formatFunction = transferFile => {
      const p2pFile =
        (transferFile.reversalOf
          ? p2pFiles[transferFile.reversalOf]
          : transferFile) || {};
      const transfers = p2pFile.transfers || [];
      const saleLots = p2pFile.saleLots || [];

      const transferEids = flatten(transfers.map(transfer => transfer.eids));

      return {
        ...transferFile,
        vendorDisplay: uniqOrCount(
          saleLots.map(saleLotId => vendorNameBySaleLotIdLookup[saleLotId]),
          "Buyer",
        ),
        buyerDisplay: getSellFileBuyerDisplay(
          saleLots,
          buyerDisplayNameBySaleLotIdLookup,
        ),

        eidCount: transferEids.length,
        eidSuccessCount: transferEids.filter(
          e => e.response_type === EID_TRANSFER_STATUS.SUCCESS,
        ).length,
      };
    };
    return [].concat
      .apply([], [Object.values(p2pFiles), Object.values(p2pReversals)])
      .map(formatFunction);
  },
);
