export const CommissionColId = {
  SALE_DEFAULT: "96cfa6da-0969-40e6-9603-4c18cfd67148",
  CUSTOM: "7a9ecda4-4110-426d-ae63-4a6873c5271d",
};

export const CommissionColumnDef = {
  [CommissionColId.SALE_DEFAULT]: {
    colId: CommissionColId.SALE_DEFAULT,
    field: "commission.deploymentSaleCommissionValue",
    enableRowGroup: true,
    headerName: "Sale Rate",
  },
  [CommissionColId.CUSTOM]: {
    colId: CommissionColId.CUSTOM,
    field: "commission.customCommissionValue",
    enableRowGroup: true,
    headerName: "Vendor Rate",
  },
};
