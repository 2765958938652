import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import {
  DateInput,
  Input,
  IntegerPriceInput,
  SelectField,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";

import { PAYMENT_METHOD_OPTIONS } from "constants/payments";

function EditPaymentFormComponent(props) {
  const { namespace: ns } = props;
  return (
    <>
      <Grid item sm={6}>
        <IntegerPriceInput
          label="Amount"
          name={withNamespace(ns, "totalAmountCents")}
          required
        />
      </Grid>
      <Grid item sm={6}>
        <SelectField
          label="Method"
          name={withNamespace(ns, "paymentMethod")}
          required
          options={PAYMENT_METHOD_OPTIONS}
        />
      </Grid>
      <Grid item sm={6}>
        <Input label="Reference" name={withNamespace(ns, "paymentRef")} />
      </Grid>
      <Grid item sm={6}>
        <DateInput
          label="Payment Date"
          name={withNamespace(ns, "paymentDate")}
        />
      </Grid>
      <Grid item sm={12}>
        <TextArea label="Notes" name={withNamespace(ns, "notes")} />
      </Grid>
    </>
  );
}

export const EditPaymentForm = memo(EditPaymentFormComponent);
EditPaymentForm.propTypes = {
  namespace: PropTypes.string,
};
