import React from "react";

import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import Clickable from "components/Button/Clickable";

import { openEditConsignmentModal } from "lib/navigation";

import history from "appHistory";

export function NVDRecRenderer(props) {
  const { value, data: consignment } = props;

  if (!consignment) {
    return null;
  }

  return (
    <Clickable
      onClick={() => {
        history.push(`attachments?consignment=${consignment.id}`);
      }}
    >
      {value !== false && !consignment.hasNVDExceptions ? (
        <FaIcon icon={faCheck} color="success" />
      ) : (
        <FaIcon icon={faExclamationCircle} color="warning" />
      )}
    </Clickable>
  );
}

export function NVDRenderer(props) {
  if (!props.data) {
    return null;
  }
  const { value } = props;
  if (value) {
    return value;
  } else {
    return <FaIcon icon={faExclamationCircle} color="warning" />;
  }
}

export function NVDIDRenderer(props) {
  const { value, data } = props;

  if (!data) {
    return null;
  }

  let consignmentId = null;

  if (data.consignment) {
    consignmentId = data.consignment.id;
  } else {
    consignmentId = data.id;
  }

  function onClickEdit() {
    if (consignmentId) {
      openEditConsignmentModal(consignmentId, undefined, true, false);
    }
  }

  if (value) {
    return value;
  } else {
    return (
      <FaIcon
        icon={faExclamationCircle}
        color="warning"
        onClick={onClickEdit}
        style={{ cursor: "pointer" }}
      />
    );
  }
}
