import {
  ADD_CONSIGNMENT_TO_OTHER_SALE,
  DELETE_CONSIGNMENT_NO_LONGER_EXISTS,
  GET_CONSIGNMENTS,
  GET_CONSIGNMENTS_CHANGES,
  GET_CONSIGNMENTS_CHANGES_SUCCESS,
  GET_CONSIGNMENTS_FAILURE,
  GET_CONSIGNMENTS_SUCCESS,
  UPDATE_CONSIGNMENT_MEGA,
} from "constants/actionTypes";

export function requestConsignments(sale) {
  return {
    type: GET_CONSIGNMENTS,
    sale,
  };
}
export function requestConsignmentsChanges(sale) {
  return {
    type: GET_CONSIGNMENTS_CHANGES,
    sale,
  };
}
export function receiveConsignments(
  livestockSaleId,
  consignments,
  lastModifiedTimestamp,
  consignmentId = undefined,
) {
  return {
    type: GET_CONSIGNMENTS_SUCCESS,
    consignments,
    lastModifiedTimestamp,
    consignmentId,
    meta: {
      livestockSaleId,
      livestockSaleSensitive: true,
    },
  };
}
export function receiveConsignmentsChanges(
  livestockSaleId,
  consignments,
  lastModifiedTimestamp,
) {
  return {
    type: GET_CONSIGNMENTS_CHANGES_SUCCESS,
    consignments,
    lastModifiedTimestamp,
    meta: {
      livestockSaleId,
      livestockSaleSensitive: true,
    },
  };
}
export function requestConsignmentsError() {
  return {
    type: GET_CONSIGNMENTS_FAILURE,
  };
}

export const deleteConsignmentNoLongerExists = id => ({
  type: DELETE_CONSIGNMENT_NO_LONGER_EXISTS,
  meta: {
    saleLot: {
      id,
    },
  },
});

export function updateConsignmentMega(payload, options) {
  return {
    type: UPDATE_CONSIGNMENT_MEGA,
    payload,
    options,
  };
}

export const synchronousAddConsignment = (
  consignmentPayload,
  selectedSale,
  agencyId,
  options,
) => {
  return {
    type: ADD_CONSIGNMENT_TO_OTHER_SALE,
    consignmentPayload,
    selectedSale,
    agencyId,
    options,
  };
};
