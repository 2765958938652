import { JSONPath } from "jsonpath-plus";

function flattenObject(saleData, object, rootKey, result) {
  /*
  Interrogates a saleData object in order to follow all `rel:xxx` relationships, and push them up to
  a key on the result object.
   
  */
  // Go through each key.
  Object.entries(object).forEach(([key, value]) => {
    // If it's a relationship
    if (key.startsWith("rel:")) {
      const nonRelKey = key.slice(4); // rel:lot -> lot
      if (value) {
        // find it!
        const relatedObject = JSONPath({
          json: saleData,
          path: value,
        })?.[0];
        flattenObject(saleData, relatedObject, nonRelKey, result);
      } else {
        result[nonRelKey] = {};
      }

      // resultObject["nonRelKey"] =
    } else if (key.startsWith("rels:")) {
      // too hard basket? :grimace:
    } else {
      // Dump it on the root key.
      if (!result[rootKey]) {
        result[rootKey] = {};
      }
      result[rootKey][key] = value;
    }
  });

  return result;
}

function flattenWithRegardsTo(saleData, iteratorKey, rootKey) {
  return saleData[iteratorKey].map(obj => {
    const result = {};
    flattenObject(saleData, obj, rootKey, result);
    return result;
  });
}

export function flattenSaleDataWithRegardsToLots(saleData) {
  return flattenWithRegardsTo(saleData, "lots", "lot");
}

export function flattenSaleDataWithRegardsToConsignments(saleData) {
  return flattenWithRegardsTo(saleData, "consignments", "consignment");
}

export function flattenSaleDataWithRegardsToBuyers(saleData) {
  return flattenWithRegardsTo(saleData, "buyers", "buyer");
}
export function flattenSaleDataWithRegardsToVendors(saleData) {
  return flattenWithRegardsTo(saleData, "vendors", "vendor");
}

export function flattenSaleDataWithRegardsToAnimals(saleData) {
  return flattenWithRegardsTo(saleData, "animals", "animal");
}
