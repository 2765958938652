import reduceReducers from "reduce-reducers";

import { DEPLOYMENT_SALE, SALE } from "constants/actionTypes";

import { defaultInitialState, offlineCrudReducer } from "lib/reducers";
import {
  deserializeConsignableSale,
  deserializeSale,
} from "lib/serializers/sale";

const initialState: SaleStateSlice = {
  ...defaultInitialState,
  consignableSales: {
    isFetching: false,
    data: {},
  },
  errorMessage: "",
};

const reducerProps = {
  deserializer: deserializeSale,
  clearOnRequest: false,
  initialState,
  deleteAfterSuccess: true,
};

const getLivestockSaleIdByDeploymentSaleId =
  (deploymentSaleId: number) => (state: SaleStateSlice) => {
    const sales = state.byId;
    const livestockSale = Object.values(sales || {}).find(sale =>
      sale.deployment_sales.find(
        deploymentSale =>
          deploymentSale.deployment_sale_id === deploymentSaleId,
      ),
    );
    return livestockSale ? livestockSale.livestocksale_id : null;
  };

const saleCrudReducer = offlineCrudReducer(SALE, reducerProps);

function fetchConsignableReducer(state, action) {
  switch (action.type) {
    case SALE.FETCH_CONSIGNABLE.REQUEST:
      return {
        ...state,
        consignableSales: {
          ...initialState.consignableSales,
          isFetching: true,
        },
      };

    case SALE.FETCH_CONSIGNABLE.SUCCESS: {
      const { payload } = action;
      const consignableSales = payload.reduce((acc, sale) => {
        acc[sale.livestocksale_id] = deserializeConsignableSale(sale);
        return acc;
      }, {});
      return {
        ...state,
        consignableSales: {
          isFetching: false,
          data: consignableSales,
        },
      };
    }

    case SALE.FETCH_CONSIGNABLE.FAILURE:
      return {
        ...state,
        consignableSales: {
          ...initialState.consignableSales,
          isFetching: false,
        },
      };

    default:
      return state;
  }
}

function deploymentSaleReducer(state, action) {
  switch (action.type) {
    case DEPLOYMENT_SALE.UPDATE.REQUEST: {
      const { deploymentSaleId, deploymentSale, livestockSaleId } = action.meta;
      return {
        ...state,
        byId: {
          ...state.byId,
          [livestockSaleId]: {
            ...state.byId[livestockSaleId],
            deployment_sales: state.byId[livestockSaleId].deployment_sales.map(
              ds =>
                ds.deployment_sale_id === deploymentSaleId
                  ? { ...ds, ...deploymentSale }
                  : ds,
            ),
          },
        },
      };
    }

    case DEPLOYMENT_SALE.COMMENT.REQUEST: {
      const { payload, id } = action;
      const livestockSaleId = getLivestockSaleIdByDeploymentSaleId(id)(state);
      const updatedLivestockSale = {
        ...state.byId[livestockSaleId],
        deployment_sales: state.byId[livestockSaleId].deployment_sales.map(
          ds =>
            ds.deployment_sale_id === id
              ? {
                  ...ds,
                  comment_ids: ds.comment_ids
                    ? [...ds.comment_ids, payload.id]
                    : [payload.id],
                }
              : ds,
        ),
      };

      return {
        ...state,
        byId: {
          ...state.byId,
          [livestockSaleId]: updatedLivestockSale,
        },
      };
    }

    case DEPLOYMENT_SALE.DELETE.REQUEST: {
      const { deploymentSaleId, livestockSaleId } = action.meta;
      return {
        ...state,
        byId: {
          ...state.byId,
          [livestockSaleId]: {
            ...state.byId[livestockSaleId],
            deployment_sales: state.byId[
              livestockSaleId
            ].deployment_sales.filter(
              ds => ds.deployment_sale_id !== deploymentSaleId,
            ),
          },
        },
      };
    }

    default:
      return state;
  }
}

const salesReducer = reduceReducers(
  saleCrudReducer,
  fetchConsignableReducer,
  deploymentSaleReducer,
);

export default salesReducer;
