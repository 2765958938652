import { useMemo } from "react";

import { useLocation, useRouteMatch } from "react-router-dom";

export const useGetSelectedTabFromRoute = tabs => {
  const match = useRouteMatch();
  const location = useLocation();

  const currentTab = useMemo(() => {
    const tab = tabs.find(({ label, path }) =>
      location.pathname.startsWith(
        `${match.path}${path === undefined ? label : path}`,
      ),
    );
    if (!tab) {
      return false;
    }
    return (tab.path === undefined ? tab.label : tab.path) || false;
  }, [location.pathname, match.path, tabs]);
  return currentTab;
};
