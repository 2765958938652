export const SingleWeighStatus = {
  FRONT_GATE_CLOSED_BACK_GATE_CLOSED: "FRONT_GATE_CLOSED_BACK_GATE_CLOSED",
  FRONT_GATE_CLOSED_BACK_GATE_OPEN: "FRONT_GATE_CLOSED_BACK_GATE_OPEN",
  FRONT_GATE_CLOSED_BACK_GATE_LOCKED: "FRONT_GATE_CLOSED_BACK_GATE_LOCKED",

  FRONT_GATE_LOCKED_BACK_GATE_CLOSED: "FRONT_GATE_LOCKED_BACK_GATE_CLOSED",
  FRONT_GATE_LOCKED_BACK_GATE_OPEN: "FRONT_GATE_LOCKED_BACK_GATE_OPEN",
  FRONT_GATE_LOCKED_BACK_GATE_LOCKED: "FRONT_GATE_LOCKED_BACK_GATE_LOCKED",

  FRONT_GATE_OPEN_BACK_GATE_CLOSED: "FRONT_GATE_OPEN_BACK_GATE_CLOSED",
  FRONT_GATE_OPEN_BACK_GATE_OPEN: "FRONT_GATE_OPEN_BACK_GATE_OPEN",
  FRONT_GATE_OPEN_BACK_GATE_LOCKED: "FRONT_GATE_OPEN_BACK_GATE_LOCKED",

  UNKNOWN: "UNKNOWN",
};
