export const LedgerAccountTypes = {
  "Bank Account": "BANK",
  "Current Asset account": "CURRENT",
  "Current Liability account": "CURRLIAB",
  "Depreciation account": "DEPRECIATN",
  "Direct Costs account": "DIRECTCOSTS",
  "Equity account": "EQUITY",
  "Expense account": "EXPENSE",
  "Fixed Asset account": "FIXED",
  "Inventory Asset account": "INVENTORY",
  "Liability account": "LIABILITY",
  "Non-current Asset account": "NONCURRENT",
  "Other Income account": "OTHERINCOME",
  "Overhead account": "OVERHEADS",
  "Prepayment account": "PREPAYMENT",
  "Revenue account": "REVENUE",
  "Sale account": "SALES",
  "Non-current Liability account": "TERMLIAB",
};

export const LedgerAccountTypeOptions = Object.entries(LedgerAccountTypes).map(
  ([label, value]) => ({
    label,
    value,
  }),
);

export const LedgerAccountStatuses = {
  Active: "ACTIVE",
  Archived: "ARCHIVED",
};

export const LedgerAccountStatusOptions = Object.entries(
  LedgerAccountStatuses,
).map(([label, value]) => ({
  label,
  value,
}));
