import { useRef, useEffect } from "react";

export function useTimeoutRef() {
  const ref = useRef();

  // Clean up on unmount.
  useEffect(() => () => ref.current && clearTimeout(ref.current), []);

  return ref;
}
