import Button from "./Button";
import Clickable from "./Clickable";
import EditButton from "./EditButton";
import FontAwesomeButton from "./FontAwesomeButton";
import HideShowButton from "./HideShowButton";
import IconButton from "./IconButton";
import IconTextButton from "./IconTextButton";
import MultiButton from "./MultiButton";
import OutlineButton from "./OutlineButton";
import SlimButton, { SlimSecondaryButton } from "./SlimButton";
import UnstyledButton from "./UnstyledButton";

export {
  EditButton,
  Clickable,
  MultiButton,
  OutlineButton,
  HideShowButton,
  IconTextButton,
  SlimButton,
  SlimSecondaryButton,
  FontAwesomeButton,
  IconButton,
  UnstyledButton,
};

export default Button;
