import { isEmpty, sortBy } from "lodash";
import { intersection, uniq } from "lodash/array";
import sumBy from "lodash/sumBy";
import { createSelector } from "reselect";

import { PenTypes } from "constants/auctionPens";
import { SaleLotType } from "constants/saleLots";
import {
  autoDraftPenInitialState,
  AutoDraftPenStatus,
  SingleWeighMode,
  SingleWeighModeConfiguration,
  SingleWeighStatus,
} from "constants/singleWeigh";

import { EMPTY_ARRAY } from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { getBuyerHashFromSaleLot } from "lib/saleLot";
import {
  canSendDraftingDecision,
  getAutoDraftPenStatus,
  getDraftingPenDecision,
  isAnimalAlreadyWeighed,
  isSingleWeighCurrent,
  isSingleWeighInitialized,
  shouldAutomaticallySendDraftingDecision,
} from "lib/singleWeigh";

import {
  createIdByKeySelector,
  createLookupCombiner,
  createLookupSelectors,
  getAuctionPenById,
  getAuctionPens,
  getCurrentSingleWeighId,
  getIsHubConnected,
  getIsOnline,
  getPenArchetypes,
  getPenIdsByPenType,
  getSaleLots,
  getScans,
  getSingleWeighs,
  getSingleWeighsContext,
  selectAuctionPenIdBySaleLotIdLookup,
  selectAutoDraftPenByIdLookup,
  selectBuyerHashesByDeliveryPenIdLookup,
  selectDeliveryPenByEidLookup,
  selectEidsByAuctionPenIdLookup,
  selectEidsByCurrentAuctionPenIdLookup,
  selectEidsBySaleLotIdLookup,
  selectHeadCountByAuctionPenIdLookup,
  selectIsConnectedPLC,
  selectIsConnectedScanner,
  selectIsConnectedWeighBridge,
  selectIsNoSaleBySaleLotIdLookup,
  selectIsPLCReady,
  selectIsSoldBySaleLotIdLookup,
  selectOwnedPenIdsByBuyerHashLookup,
  selectPenIdsByPenArchetypeIdLookup,
  selectSaleLotByEidLookup,
  selectSaleLotIdByEidLookup,
  selectSaleLotIdsByBuyerHashLookup,
  selectScansBySaleLotIdLookup,
} from "selectors";

export const getContextBySingleWeighId = singleWeighId => state =>
  getSingleWeighsContext(state)[singleWeighId] || null;

export const getSingleWeighById = singleWeighId => state =>
  getSingleWeighs(state)[singleWeighId] || null;

export const getCurrentDraftingDecisionBySingleWeighById =
  singleWeighId => state =>
    getContextBySingleWeighId(singleWeighId)(state).currentDecision || null;

export const getDraftingDecisionHistoryBySingleWeighById =
  singleWeighId => state =>
    getContextBySingleWeighId(singleWeighId)(state)?.decisionHistory ||
    EMPTY_ARRAY;

export const getLastDraftingDecisionBySingleWeighId = singleWeighId => state =>
  getContextBySingleWeighId(singleWeighId)(state)?.decisionHistory?.[0] || null;

export const getCurrentSingleWeigh = state =>
  getSingleWeighs(state)[getCurrentSingleWeighId(state)] || null;

export const getDraftingDecisionBySingleWeighById =
  (singleWeighId, index) => state =>
    getDraftingDecisionHistoryBySingleWeighById(singleWeighId)(state)[index] ||
    null;

export const selectPenArchetypesBySingleWeighIdLookup = createSelector(
  [getSingleWeighs, getPenArchetypes],
  (singleWeighs, penArchetypes) =>
    Object.values(singleWeighs).reduce((acc, cur) => {
      acc[cur.id] = cur.penArchetypes.map(
        penArchetypeId => penArchetypes[penArchetypeId],
      );
      return acc;
    }, {}),
);

export const getPenArchetypesBySingleWeighId = id => state =>
  selectPenArchetypesBySingleWeighIdLookup(state)[id] || null;

export const selectPensBySingleWeighIdLookup = createSelector(
  [getSingleWeighs, getAuctionPens, selectPenIdsByPenArchetypeIdLookup],
  (singleWeighs, auctionPens, auctionPenIdsByPenArchetypeIdLookup) =>
    Object.entries(singleWeighs).reduce(
      (lookup, [singleWeighId, singleWeigh]) => {
        const singleWeighPenIds = singleWeigh.penArchetypes.reduce(
          (acc, penArchetypeId) => {
            const penIds =
              auctionPenIdsByPenArchetypeIdLookup[penArchetypeId] || [];
            return acc.concat(penIds);
          },
          [],
        );
        const singleWeighPens = singleWeighPenIds.map(
          penId => auctionPens[penId],
        );
        lookup[singleWeighId] = sortBy(singleWeighPens, "order");
        return lookup;
      },
      {},
    ),
);

export const getPensBySingleWeighId = singleWeighId => state =>
  selectPensBySingleWeighIdLookup(state)[singleWeighId] || null;

export const selectAutoDraftPensBySingleWeighIdLookup = createSelector(
  [selectPensBySingleWeighIdLookup],
  pensBySingleWeighIdLookup =>
    Object.entries(pensBySingleWeighIdLookup).reduce(
      (lookup, [singleWeighId, pens]) => {
        lookup[singleWeighId] = pens.filter(
          pen => pen.penType === PenTypes.AUTO_DRAFT,
        );
        return lookup;
      },
      {},
    ),
);

export const getAutoDraftPensBySingleWeighIdLookup = singleWeighId => state =>
  selectAutoDraftPensBySingleWeighIdLookup(state)[singleWeighId] || null;

export const selectAutoDraftPensAsOptionsBySingleWeighIdLookup = createSelector(
  [selectAutoDraftPensBySingleWeighIdLookup],
  pensBySingleWeighIdLookup =>
    Object.entries(pensBySingleWeighIdLookup).reduce(
      (lookup, [singleWeighId, pens]) => {
        lookup[singleWeighId] = pens.map(pen => ({
          label: getAuctionPenDisplayName(pen), // TODO - include head count, etc, etc, etc?
          value: pen.id,
        }));
        return lookup;
      },
      {},
    ),
);

export const getAutoDraftPensAsOptionsBySingleWeighId =
  singleWeighId => state =>
    selectAutoDraftPensAsOptionsBySingleWeighIdLookup(state)[singleWeighId] ||
    null;

const selectDestinationPenIdByAutoDraftPenId = createIdByKeySelector(
  createSelector([selectAutoDraftPenByIdLookup], autoDraftPenByIdLookup =>
    Object.entries(autoDraftPenByIdLookup).reduce(
      (acc, [autoDraftPenId, autoDraftPen]) => {
        acc[autoDraftPenId] = {
          autoDraftPenId,
          destinationPenId: autoDraftPen.autoDraftState?.destinationPenId,
        };
        return acc;
      },
      {},
    ),
  ),
  "autoDraftPenId",
  "destinationPenId",
);

/**
 * Post Sale Sale Lots are determined by the list of Buyer and Buyer Way combinations from any of the Sale Lots in the Autodraft Pen's Destination Pen's Sale Lots.
 * i.e.
 *  1. Get the Auto Draft Pen's Destination Pen (which is assumed to be the Delivery Pen is Post Sale)
 *  2. Get all Sale Lots currently in the Delivery Pen
 *  3. Determine all of the unique Buyer and Buyer Way combinations on all of the Sale Lots from 2.
 *   - Only count Sale Lots with a Buyer - No Sale is currently TBC
 *  4. Get all Sale Lots in the Sale which match any of the unique Buyer and Buyer Way combinations identified in 3.
 */
const selectPostSaleSaleLotIdsByAutoDraftPenId = createSelector(
  [
    selectDestinationPenIdByAutoDraftPenId,
    selectBuyerHashesByDeliveryPenIdLookup,
    selectSaleLotIdsByBuyerHashLookup,
    selectIsSoldBySaleLotIdLookup,
    selectIsNoSaleBySaleLotIdLookup,
  ],
  (
    destinationPenIdByAutoDraftPenId,
    buyerAndBuyerWayHashesByDeliveryPenIdLookup,
    saleLotIdsByBuyerHashLookup,
    isSoldBySaleLotIdLookup,
    isNoSaleBySaleLotIdLookup,
  ) =>
    Object.entries(destinationPenIdByAutoDraftPenId).reduce(
      (acc, [autoDraftPenId, destinationPenId]) => {
        if (destinationPenId === null) {
          acc[autoDraftPenId] = EMPTY_ARRAY;
          return acc;
        }

        const buyerAndBuyerWayHashes =
          buyerAndBuyerWayHashesByDeliveryPenIdLookup[destinationPenId] || [];

        acc[autoDraftPenId] = buyerAndBuyerWayHashes.reduce(
          (acc, buyerAndBuyerWayHash) => {
            const saleLotIds =
              saleLotIdsByBuyerHashLookup[buyerAndBuyerWayHash] || [];
            return acc.concat(
              saleLotIds.filter(
                saleLotId =>
                  isSoldBySaleLotIdLookup[saleLotId] ||
                  isNoSaleBySaleLotIdLookup[saleLotId],
              ),
            );
          },
          [],
        );
        return acc;
      },
      {},
    ),
);

const selectPostSaleEidsByAutoDraftPenId = createSelector(
  [selectPostSaleSaleLotIdsByAutoDraftPenId, selectEidsBySaleLotIdLookup],
  (postSaleSaleLotIdsByAutoDraftPenId, eidsBySaleLotIdLookup) =>
    Object.entries(postSaleSaleLotIdsByAutoDraftPenId).reduce(
      (acc, [autoDraftPenId, saleLotIds]) => {
        if (saleLotIds.length === null) {
          acc[autoDraftPenId] = EMPTY_ARRAY;
          return acc;
        }
        acc[autoDraftPenId] = saleLotIds.reduce((acc, saleLotId) => {
          const eids = eidsBySaleLotIdLookup[saleLotId] || [];
          return acc.concat(eids);
        }, []);
        return acc;
      },
      {},
    ),
);

export const selectStatusBySingleWeighIdLookup = createSelector(
  [
    getSingleWeighsContext,
    getSingleWeighs,
    getIsHubConnected,
    selectIsConnectedPLC,
    selectIsConnectedWeighBridge,
    selectIsConnectedScanner,
    selectIsPLCReady,
    getScans,
    selectSaleLotByEidLookup,
    getAuctionPens,
  ],
  (
    singleWeighContexts,
    singleWeighs,
    isConnectedHub,
    isConnectedPLC,
    isConnectedWeighBridge,
    isConnectedScanner,
    isPlcReady,
    scans,
    saleLotByEidLookup,
    auctionPens,
  ) => {
    return Object.entries(singleWeighContexts).reduce((acc, [id, context]) => {
      const { currentDecision = {} } = context;
      const singleWeigh = singleWeighs[id];
      const { isPaused, mode } = singleWeigh;

      const {
        areMultipleEidsDetected,
        totalMassGrams,
        draftPenId,
        destinationPenId,
        eid,
        eidWaitTimeout,
        // ownerBusinessId,
        unsteadyWeightTimeout,
      } = currentDecision;

      if (!isConnectedHub) {
        acc[id] = SingleWeighStatus.HUB_NOT_CONNECTED;
      } else if (!isConnectedPLC) {
        acc[id] = SingleWeighStatus.PLC_NOT_CONNECTED;
      } else if (!isConnectedWeighBridge) {
        // TODO There is an instance where the weights may NOT be needed, so we probably don't need to fall into
        // this status.
        acc[id] = SingleWeighStatus.SCALES_NOT_CONNECTED;
      } else if (!isConnectedScanner) {
        acc[id] = SingleWeighStatus.SCANNER_NOT_CONNECTED;
      } else if (unsteadyWeightTimeout) {
        acc[id] = SingleWeighStatus.STEADY_WAIT_TIMEOUT;
      } else if (isPaused) {
        acc[id] = SingleWeighStatus.PAUSED;
      } else if (mode === SingleWeighMode.DISABLED) {
        acc[id] = SingleWeighStatus.DISABLED;
      } else if (eidWaitTimeout) {
        acc[id] = SingleWeighStatus.EID_WAIT_TIMEOUT;
      } else if (!eid) {
        acc[id] = SingleWeighStatus.WAITING_FOR_EID;
      } else if (areMultipleEidsDetected) {
        acc[id] = SingleWeighStatus.MULTIPLE_EIDS;
      } else if (
        !scans[eid]?.sale_lot_id ||
        saleLotByEidLookup[eid]?.saleLotType !== SaleLotType.AUCTION ||
        // Waiting for the Scan to save probably, so should be a fairly transient state?
        !scans[eid]?.id
      ) {
        // If the EID has never been seen, or isn't selling lot lot'd
        acc[id] = SingleWeighStatus.UNKNOWN_EID;
      } else if (isAnimalAlreadyWeighed(scans[eid], currentDecision)) {
        acc[id] = SingleWeighStatus.ANIMAL_ALREADY_WEIGHED;
      } else if (!totalMassGrams) {
        acc[id] = SingleWeighStatus.WAITING_FOR_STEADY_WEIGHT;
      } else if (!isPlcReady) {
        acc[id] = SingleWeighStatus.WAITING_FOR_PLC;
      } else if (
        !destinationPenId &&
        SingleWeighModeConfiguration[mode].auctionPenAsDestinationPen
      ) {
        // At this point we know we have an EID, but can't find a destination pen for it.
        acc[id] = SingleWeighStatus.NO_SELLING_PEN;
      } else if (
        !destinationPenId &&
        SingleWeighModeConfiguration[mode].deliveryPenAsDestinationPen
      ) {
        // At this point we know we have an EID, but can't find a destination pen for it.
        acc[id] = SingleWeighStatus.NO_DELIVERY_PEN;
      } else if (auctionPens[destinationPenId]?.isLocked) {
        // At this point we know we have an EID, and a destination pen, but it is locked
        acc[id] = SingleWeighStatus.DELIVERY_PEN_LOCKED;
      } else if (
        !draftPenId &&
        SingleWeighModeConfiguration[mode].sendAutoDecision
      ) {
        // We're required to send the result, but there's no results to send!
        acc[id] = SingleWeighStatus.DRAFT_PENS_UNAVAILABLE;
      } else if (SingleWeighModeConfiguration[mode].manualDecision) {
        // They're in a mode that requires the user to submit a manual draft decision, but they haven't done so yet.
        acc[id] = SingleWeighStatus.WAITING_FOR_PEN_SELECTION;
      } else {
        // We have a drafting decision, and we're in an auto mode - we should actually never see this status on
        // screen, as the saga should pick up what's happening in the background.
        acc[id] = SingleWeighStatus.READY_TO_AUTO_DRAFT;
      }
      return acc;
    }, {});
  },
);

export const getStatusBySingleWeighId = singleWeighId => state =>
  selectStatusBySingleWeighIdLookup(state)[singleWeighId] || "UNKNOWN";

const selectSellingPenByEidLookup = createSelector(
  [selectSaleLotIdByEidLookup, selectAuctionPenIdBySaleLotIdLookup],
  (saleLotIdByEidLookup, auctionPenIdBySaleLotIdLookup) =>
    Object.entries(saleLotIdByEidLookup).reduce((lookup, [eid, saleLotId]) => {
      lookup[eid] = auctionPenIdBySaleLotIdLookup[saleLotId] || null;
      return lookup;
    }, {}),
);

export const getCanSendDraftingDecisionBySingleWeighId =
  singleWeighId => state =>
    canSendDraftingDecision(
      getSingleWeighsContext(state)[singleWeighId]?.currentDecision,
      getSingleWeighs(state)[singleWeighId],
      selectIsPLCReady(state),
    );

export const getShouldAutomaticallyDispatchBySingleWeighId =
  singleWeighId => state =>
    shouldAutomaticallySendDraftingDecision(
      getStatusBySingleWeighId(singleWeighId)(state),
      getCanSendDraftingDecisionBySingleWeighId(singleWeighId)(state),
      getSingleWeighs(state)[singleWeighId].mode,
    );

export const selectIsSingleWeighSelectedAndInitializedBySingleWeighIdLookup =
  createSelector(
    [getSingleWeighs, getSingleWeighsContext, getCurrentSingleWeighId],
    (singleWeighs, singleWeighsContext, currentSingleWeighId) =>
      Object.keys(singleWeighs).reduce((lookup, singleWeighId) => {
        lookup[singleWeighId] =
          singleWeighId === currentSingleWeighId &&
          singleWeighsContext[singleWeighId];
        return lookup;
      }, {}),
  );

export const getIsSingleWeighCurrentBySingleWeighId = singleWeighId => state =>
  isSingleWeighCurrent(singleWeighId, getCurrentSingleWeighId(state));

export const getIsSingleWeighInitializedBySingleWeighId =
  singleWeighId => state =>
    isSingleWeighInitialized(
      getSingleWeighs(state)[singleWeighId],
      getSingleWeighsContext(state)[singleWeighId],
    );

export const selectHasSingleWeighInCurrentSale = createSelector(
  [getSingleWeighs],
  singleWeighs => !isEmpty(singleWeighs),
);

const selectSellingPennedEidsByAutoDraftPenIdLookup = createSelector(
  [selectEidsByAuctionPenIdLookup, selectAutoDraftPensBySingleWeighIdLookup],
  (eidsByAuctionPenIdLookup, autoDraftPensBySingleWeighIdLookup) =>
    Object.values(autoDraftPensBySingleWeighIdLookup).reduce(
      (lookup, autoDraftPens) => {
        autoDraftPens.forEach(autoDraftPen => {
          lookup[autoDraftPen.id] = [];
          if (autoDraftPen?.autoDraftState?.destinationPenId) {
            lookup[autoDraftPen.id] = lookup[autoDraftPen.id].concat(
              eidsByAuctionPenIdLookup[
                autoDraftPen.autoDraftState.destinationPenId
              ],
            );
          }
        });
        return lookup;
      },
      {},
    ),
);

const selectProcessedSellingPennedEidCountByAutoDraftPenIdLookup =
  createSelector(
    [selectSellingPennedEidsByAutoDraftPenIdLookup, getScans],
    (sellingPennedEidsByAutoDraftPenIdLookup, scans) =>
      Object.entries(sellingPennedEidsByAutoDraftPenIdLookup).reduce(
        (lookup, [autoDraftPenId, eids]) => {
          lookup[autoDraftPenId] = eids.filter(
            eid => scans[eid]?.total_mass_grams,
          ).length;
          return lookup;
        },
        {},
      ),
  );

export const getProcessedSellingPennedEidCountByAutoDraftPenId =
  autoDraftPenId => state =>
    selectProcessedSellingPennedEidCountByAutoDraftPenIdLookup(state)[
      autoDraftPenId
    ];

const selectSellingPennedHeadCountByAutoDraftPenIdLookup = createSelector(
  [
    selectHeadCountByAuctionPenIdLookup,
    selectAutoDraftPensBySingleWeighIdLookup,
  ],
  (headCountByAuctionPenIdLookup, autoDraftPensBySingleWeighIdLookup) =>
    Object.values(autoDraftPensBySingleWeighIdLookup).reduce(
      (lookup, autoDraftPens) => {
        autoDraftPens.forEach(autoDraftPen => {
          lookup[autoDraftPen.id] = autoDraftPen.autoDraftState
            ?.destinationPenId
            ? headCountByAuctionPenIdLookup[
                autoDraftPen.autoDraftState?.destinationPenId
              ] || 0
            : 0;
        });
        return lookup;
      },
      {},
    ),
);

export const getSellingPennedHeadCountByAutoDraftPenId =
  autoDraftPenId => state =>
    selectSellingPennedHeadCountByAutoDraftPenIdLookup(state)[autoDraftPenId];

export const selectIsProcessedByEidLookup = createSelector([getScans], scans =>
  Object.entries(scans).reduce((lookup, [eid, scan]) => {
    lookup[eid] = Boolean(scan?.total_mass_grams);
    return lookup;
  }, {}),
);

const selectProcessedPostSaleCountByAutoDraftPenId = createSelector(
  [
    getPenIdsByPenType(PenTypes.AUTO_DRAFT),
    selectPostSaleEidsByAutoDraftPenId,
    selectIsProcessedByEidLookup,
  ],
  (autoDraftPenIds, postSaleEidsByAutoDraftPenId, isProcessedByEidLookup) =>
    autoDraftPenIds.reduce((acc, autoDraftPenId) => {
      const eids = postSaleEidsByAutoDraftPenId[autoDraftPenId] || [];
      acc[autoDraftPenId] = 0;
      if (eids.length > 0) {
        acc[autoDraftPenId] = eids.reduce(
          (acc, eid) => (isProcessedByEidLookup[eid] ? acc + 1 : acc),
          0,
        );
      }
      return acc;
    }, {}),
);
export const getProcessedPostSaleCountByAutoDraftPenId =
  autoDraftPenId => state =>
    selectProcessedPostSaleCountByAutoDraftPenId(state)[autoDraftPenId];

const selectTotalPostSaleCountByAutoDraftPenId = createSelector(
  [selectPostSaleSaleLotIdsByAutoDraftPenId, getSaleLots],
  (postSaleSaleLotIdsByAutoDraftPenId, saleLots) =>
    Object.entries(postSaleSaleLotIdsByAutoDraftPenId).reduce(
      (acc, [autoDraftPenId, saleLotIds]) => {
        acc[autoDraftPenId] = sumBy(
          saleLotIds.map(saleLotId => saleLots[saleLotId]),
          "quantity",
        );
        return acc;
      },
      {},
    ),
);
export const getTotalPostSaleCountByAutoDraftPenId = autoDraftPenId => state =>
  selectTotalPostSaleCountByAutoDraftPenId(state)[autoDraftPenId];

export const selectIsManuallyWeighedBySaleLotId = createSelector(
  [getSaleLots, selectScansBySaleLotIdLookup],
  (saleLots, scansBySaleLotIdLookup) =>
    Object.keys(saleLots).reduce((acc, saleLotId) => {
      acc[saleLotId] =
        scansBySaleLotIdLookup[saleLotId]?.some(
          scan => scan.is_manually_weighed,
        ) || false;
      return acc;
    }, {}),
);

export const getIsManuallyWeighedBySaleLotId = saleLotId => state =>
  selectIsManuallyWeighedBySaleLotId(state)[saleLotId];

export const selectIsResetDisabledByAutoDraftPenId = createSelector(
  [getIsOnline, getAuctionPens, selectHeadCountByAuctionPenIdLookup],
  (isOnline, autoDraftPens, headCountByAuctionPenIdLookup) => {
    return Object.values(autoDraftPens).reduce((acc, autoDraftPen) => {
      // The drafting pen is resettable if its state is different than the initial state.
      const pensResettable =
        Object.entries(autoDraftPenInitialState).some(
          ([key, value]) => autoDraftPen.autoDraftState?.[key] !== value,
        ) || headCountByAuctionPenIdLookup[autoDraftPen.id] > 0;

      acc[autoDraftPen.id] = !(
        isOnline &&
        !autoDraftPen.syncing &&
        pensResettable
      );
      return acc;
    }, {});
  },
);

export const getIsResetDisabledByAutoDraftPenId = autoDraftPenId => state =>
  selectIsResetDisabledByAutoDraftPenId(state)[autoDraftPenId];

export const getStatusByAutoDraftPenId = autoDraftPenId => state => {
  const { mode } = getCurrentSingleWeigh(state);

  const { autoDraftState = {} } =
    getAuctionPenById(autoDraftPenId)(state) || {};

  if (SingleWeighModeConfiguration[mode].auctionPenAsDestinationPen) {
    return getAutoDraftPenStatus(
      autoDraftState,
      getProcessedSellingPennedEidCountByAutoDraftPenId(autoDraftPenId)(state),
      getSellingPennedHeadCountByAutoDraftPenId(autoDraftPenId)(state),
    );
  } else if (SingleWeighModeConfiguration[mode].deliveryPenAsDestinationPen) {
    return getAutoDraftPenStatus(
      autoDraftState,
      getProcessedPostSaleCountByAutoDraftPenId(autoDraftPenId)(state),
      getTotalPostSaleCountByAutoDraftPenId(autoDraftPenId)(state),
    );
  }
  return AutoDraftPenStatus.UNKNOWN;
};

export const selectDeliveryPenIdsByBuyerHashLookup = createSelector(
  [selectOwnedPenIdsByBuyerHashLookup, getPenIdsByPenType(PenTypes.DELIVERY)],
  (ownedPenIdsByBuyerHashLookup, deliveryPenIds) =>
    Object.entries(ownedPenIdsByBuyerHashLookup).reduce(
      (acc, [buyerHash, penIds]) => {
        acc[buyerHash] = uniq(intersection(penIds, deliveryPenIds));
        return acc;
      },
      {},
    ),
);

export const getDeliveryPenIdsByBuyerHash = buyerHash => state =>
  selectDeliveryPenIdsByBuyerHashLookup(state)[buyerHash] || EMPTY_ARRAY;

export const selectDeliveryPenAsOptionsByBuyerHashLookup = createSelector(
  [selectDeliveryPenIdsByBuyerHashLookup, getAuctionPens],
  (deliveryPenIdsyBuyerHashLookup, auctionPens) =>
    Object.entries(deliveryPenIdsyBuyerHashLookup).reduce(
      (lookup, [buyerHash, penIds]) => {
        lookup[buyerHash] = penIds.map(penId => ({
          label: getAuctionPenDisplayName(auctionPens[penId]),
          value: penId,
        }));
        return lookup;
      },
      {},
    ),
);

export const getDeliveryPenAsOptionsByBuyerHash = buyerHash => state =>
  selectDeliveryPenAsOptionsByBuyerHashLookup(state)[buyerHash] || EMPTY_ARRAY;

export const selectCurrentDecisionSaleLotBySingleWeighIdLookup = createSelector(
  [getSingleWeighsContext, selectSaleLotByEidLookup],
  (singleWeighContext, saleLotByEidLookup) =>
    Object.entries(singleWeighContext).reduce(
      (lookup, [singleWeighId, { currentDecision = {} }]) => {
        const { eid } = currentDecision;
        lookup[singleWeighId] = saleLotByEidLookup[eid];
        return lookup;
      },
      {},
    ),
);

export const getCurrentDecisionSaleLotBySingleWeighId =
  singleWeighId => state =>
    selectCurrentDecisionSaleLotBySingleWeighIdLookup(state)[singleWeighId];

export const selectDefaultDeliveryPenIdByBuyerHashLookup = createSelector(
  [selectDeliveryPenIdsByBuyerHashLookup],
  deliveryPenIdsyBuyerHashLookup => {
    return Object.entries(deliveryPenIdsyBuyerHashLookup).reduce(
      (acc, [buyerHash, deliveryPenIds]) => {
        // Default to the last penId
        acc[buyerHash] = deliveryPenIds[deliveryPenIds.length - 1];
        return acc;
      },
      {},
    );
  },
);
export const getDefaultDeliveryPenIdByBuyer = buyerHash => state =>
  selectDefaultDeliveryPenIdByBuyerHashLookup(state)[buyerHash] || null;

// The decidingAttributes packet that should live with `sendDraftingDecision`
const selectCurrentDecisionUpdatesBySingleWeighIdLookup = createSelector(
  [
    getSingleWeighs,
    getSingleWeighsContext,
    selectSellingPenByEidLookup,
    selectDeliveryPenByEidLookup,
    selectAutoDraftPensBySingleWeighIdLookup,
    selectEidsByCurrentAuctionPenIdLookup,
    selectSaleLotByEidLookup,
    selectIsPLCReady,
    selectDefaultDeliveryPenIdByBuyerHashLookup,
  ],
  (
    singleWeighs,
    singleWeighsContext,
    sellingPenByEidLookup,
    deliveryPenIdByEidLookup,
    autoDraftPensBySingleWeighIdLookup,
    eidsByCurrentAuctionPenIdLookup,
    saleLotByEidLookup,
    isPlcReady,
    defaultDeliveryPenIdByBuyerHashLookup,
  ) =>
    Object.entries(singleWeighsContext).reduce(
      (lookup, [singleWeighId, singleWeighContext]) => {
        const { currentDecision } = singleWeighContext;
        const { mode, unsteadyWeightDelay, noEidDelay } =
          singleWeighs[singleWeighId];

        // We should not return changes to:
        // - eid
        // - totalMassGrams
        // - totalMassGramsRecorded
        // (if anything about PLC state ever exists in hree)
        // as these are sourced from other means; only the ones calculated
        // from other pieces of state (like, a destination pen)

        const decisionUpdates = {};
        const { eid } = currentDecision;
        if (eid) {
          // If we have an EID there's a chance of finding more.

          // Find the destination based on mode - either the selling auction pen (pre sale) or
          // delivery pen (post sale);  Note that this may correctly reset the value to null.

          let destinationPenId = null;
          let buyerId = null;
          let buyerWayName = null;
          if (SingleWeighModeConfiguration[mode].auctionPenAsDestinationPen) {
            destinationPenId = sellingPenByEidLookup[eid];
          } else if (
            SingleWeighModeConfiguration[mode].deliveryPenAsDestinationPen
          ) {
            destinationPenId = deliveryPenIdByEidLookup[eid];

            // If we are in auto mode, try to find a default pen for the buyer
            if (
              !destinationPenId &&
              SingleWeighModeConfiguration[mode].useDefaultDeliveryPen
            ) {
              destinationPenId =
                defaultDeliveryPenIdByBuyerHashLookup[
                  getBuyerHashFromSaleLot(saleLotByEidLookup[eid] || {})
                ];
            }

            if (saleLotByEidLookup[eid]) {
              buyerId = saleLotByEidLookup[eid].buyer_id;
              buyerWayName = saleLotByEidLookup[eid].buyer_way?.name;
            }
          }

          if (currentDecision.destinationPenId !== destinationPenId) {
            decisionUpdates.destinationPenId = destinationPenId;
          }
          if (currentDecision.buyerId !== buyerId) {
            decisionUpdates.buyerId = buyerId;
          }

          if (currentDecision.buyerWayName !== buyerWayName) {
            decisionUpdates.buyerWayName = buyerWayName;
          }

          // If we're in a mode where there should be an auto drafting decision made,
          // and the user hasn't overridden that value, try and work out where to put it.
          if (
            SingleWeighModeConfiguration[mode].makeAutoDecision &&
            !currentDecision.manualDraftPenDecision
          ) {
            const draftPenId = getDraftingPenDecision(
              destinationPenId,
              autoDraftPensBySingleWeighIdLookup[singleWeighId],
              eidsByCurrentAuctionPenIdLookup,
            );
            if (currentDecision.draftPenId !== draftPenId) {
              decisionUpdates.draftPenId = draftPenId;
            }
          }
          if (currentDecision.eidWaitTimeoutStarted) {
            decisionUpdates.eidWaitTimeoutStarted = null;
          }
        } else if (
          !currentDecision.eidWaitTimeoutStarted &&
          !currentDecision.eidWaitTimeout &&
          noEidDelay > 0 &&
          (isPlcReady || currentDecision.totalMassGrams) &&
          !currentDecision.eid
        ) {
          decisionUpdates.eidWaitTimeoutStarted = Date.now();
        }

        if (
          !currentDecision.unsteadyWeightTimeoutStarted &&
          !currentDecision.unsteadyWeightTimeout &&
          unsteadyWeightDelay > 0 &&
          (isPlcReady || currentDecision.eid) &&
          !currentDecision.totalMassGrams
        ) {
          decisionUpdates.unsteadyWeightTimeoutStarted = Date.now();
        }
        // If we get a weight clear the timer
        if (
          currentDecision.totalMassGrams &&
          currentDecision.unsteadyWeightTimeoutStarted
        ) {
          decisionUpdates.unsteadyWeightTimeoutStarted = null;
        }

        lookup[singleWeighId] = isEmpty(decisionUpdates)
          ? null
          : decisionUpdates;
        return lookup;
      },
      {},
    ),
);

export const getCurrentDecisionUpdatesBySingleWeighId =
  singleWeighId => state =>
    selectCurrentDecisionUpdatesBySingleWeighIdLookup(state)[singleWeighId] ||
    null;

const isAnimalsInAutoDraftBySaleLotIdLookup = (scans, autoDraftPenByIdLookup) =>
  scans.some(
    ({ current_auction_pen_id }) =>
      autoDraftPenByIdLookup[current_auction_pen_id],
  );
export const [
  selectIsAnimalsInAutoDraftBySaleLotIdLookup,
  getIsAnimalsInAutoDraftBySaleLotId,
] = createLookupSelectors(
  [selectScansBySaleLotIdLookup, selectAutoDraftPenByIdLookup],
  createLookupCombiner(isAnimalsInAutoDraftBySaleLotIdLookup),
);
