import reduceReducers from "reduce-reducers";

import { PERMISSION_GROUP } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeGroup } from "lib/serializers/permissionGroup";

const fetch = apiModelOfflineFetchReducer(PERMISSION_GROUP, {
  deserializer: deserializeGroup,
  clearOnRequest: true,
});

const permissionGroupReducer = reduceReducers(fetch);

export default permissionGroupReducer;
