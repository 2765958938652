import React from "react";

import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { SubtleBadge } from "components/Badge";
import { FormCollapse } from "components/Form";
import {
  Input,
  Label,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { getActiveLivestockAgentDeployment } from "selectors";

import { DeploymentSettingsModalSection } from ".";

const Thumbnail = styled.img`
  max-height: 35px;
  max-width: 35px;
`;

export function GeneralSection(props) {
  const { sectionHelper, ns } = props;
  const deployment = useSelector(getActiveLivestockAgentDeployment);
  const header = (
    <Row>
      <SubtleBadge>General</SubtleBadge>
    </Row>
  );
  const modalSection = DeploymentSettingsModalSection.GENERAL;

  const [section, setSection] = sectionHelper;

  const isOpen = section === modalSection;
  const onToggle = event => {
    event.stopPropagation();
    setSection(modalSection);
  };

  return (
    <FormCollapse isOpen={isOpen} onToggle={onToggle} header={header}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Input label="ABN" name={withNamespace(ns, "abn")} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input label="Web Address" name={withNamespace(ns, "webAddress")} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextArea
            label="Transit Insurance Information"
            name={withNamespace(ns, "transitInsuranceText")}
          />
        </Grid>
        <Grid item xs={12} md={11}>
          <Input
            label="Corporate Colour"
            name="corporateColor"
            type="color"
            tooltip="This colour is used in the header of several reports."
            required
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Label tooltip="Contact Agrinous staff to update this logo">
            Logo
          </Label>
          <Thumbnail
            src={deployment?.deploymentSettings?.financeReportLogoUrl}
          />
        </Grid>
      </Grid>
    </FormCollapse>
  );
}
