import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { FullWidthSelect } from "components/SearchableSelector/ReactSelect";

import { getReceiptPricingTypeString } from "constants/pricingTypes";

import { formatDecimal, getDollarPriceStringFromCents } from "lib";

import {
  getAgencyBySaleLotIdLookup,
  saleWatcherSummaryByRound,
  saleWatcherSummaryBySex,
  selectSaleWatcherSummary,
} from "selectors";

const Grouper = styled(Grid)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.charlie}px;
`;

const Label = styled(Grid)`
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizes.charlie}px;
  font-style: italic;
`;

const Data = styled(Grid)`
  font-size: ${({ theme }) => theme.fontSizes.charlie}px;
`;

const TopUnitPrice = ({ saleLot }) => {
  const agencyName = useSelector(getAgencyBySaleLotIdLookup(saleLot?.id))?.name;

  if (saleLot) {
    return (
      <>
        <div>{saleLot.unitPriceFormatted}</div>
        <div>{agencyName}</div>
        <div>{`Pen ${saleLot.penName}`}</div>
      </>
    );
  }

  return "-";
};

const TopHdPrice = ({ saleLot }) => {
  const agencyName = useSelector(getAgencyBySaleLotIdLookup(saleLot?.id))?.name;

  if (saleLot) {
    return (
      <>
        <div>${saleLot.dollar_per_head}</div>
        <div>{agencyName}</div>
        <div>{`Pen ${saleLot.penName}`}</div>
      </>
    );
  }

  return "-";
};

const SummaryTypes = {
  BY_ROUND: "Summary By Round",
  BY_ROUND_WITH_TOTAL: "Summary By Round With Total",
  BY_SEX: "Summary By Sex",
  BY_SEX_WITH_TOTAL: "Summary By Sex With Total",
};

const SummaryTypeOptions = Object.values(SummaryTypes).map(st => ({
  label: st,
  value: st,
}));

const SummaryTypeSelectors = {
  [SummaryTypes.BY_SEX]: saleWatcherSummaryBySex,
  [SummaryTypes.BY_SEX_WITH_TOTAL]: saleWatcherSummaryBySex,
  [SummaryTypes.BY_ROUND]: saleWatcherSummaryByRound,
  [SummaryTypes.BY_ROUND_WITH_TOTAL]: saleWatcherSummaryByRound,
};

function SummarySelector(props) {
  const { summaryType, setSummaryType } = props;
  const onChange = e => {
    setSummaryType(e.value);
  };

  const selectedValue = SummaryTypeOptions.find(
    sto => sto.value === summaryType,
  );

  return (
    <FullWidthSelect
      isClearable={false}
      value={selectedValue}
      options={SummaryTypeOptions}
      onChange={onChange}
      menuPortalTarget={document.body}
    />
  );
}

function SummaryBlock() {
  const totalSummary = useSelector(selectSaleWatcherSummary);

  return (
    <Grid item xs={12} container justifyContent="center">
      <Grid item md={10} lg={6} container justifyContent="space-around">
        <Grouper item xs={12} container justifyContent="center">
          Sale Totals
        </Grouper>
        <Label item xs={3}>
          Lots
        </Label>
        <Data item xs={8}>
          {totalSummary.lotsSold} / {totalSummary.lots} Sold
        </Data>
        <Label item xs={3}>
          Hd
        </Label>
        <Data item xs={8}>
          {totalSummary.headSold} / {totalSummary.head} Sold
        </Data>

        <Label item xs={3}>
          Clearance Rate
        </Label>
        <Data item xs={8}>
          {formatDecimal(totalSummary.clearanceRate)}%
        </Data>
        <Label item xs={3}>
          Avg $/Hd
        </Label>
        <Data item xs={8}>
          {totalSummary.headSold > 0 ? `$${totalSummary.averagePerHead}` : "-"}
        </Data>
        <Label item xs={3}>
          {`Top ${
            totalSummary.topUnitPrice
              ? getReceiptPricingTypeString(
                  totalSummary.topUnitPrice.pricing_type_id,
                )
              : ""
          }`}
        </Label>
        <Data item xs={8}>
          <TopUnitPrice saleLot={totalSummary.topUnitPrice} />
        </Data>
        <Label item xs={3}>
          Gross
        </Label>
        <Data item xs={8}>
          $
          {getDollarPriceStringFromCents(
            totalSummary.totalPriceCents,
            "0",
            true,
          )}
        </Data>
      </Grid>
    </Grid>
  );
}

const Averages = () => {
  const [summaryType, setSummaryType] = useState(SummaryTypes.BY_ROUND);
  const groupedSummary = useSelector(SummaryTypeSelectors[summaryType]);

  return (
    <Card raised>
      <SummarySelector
        summaryType={summaryType}
        setSummaryType={setSummaryType}
      />
      <CardContent>
        <Grid container spacing={2} justifyContent="space-evenly">
          {groupedSummary.map(summaryGroup => (
            <Grid item md={10} lg={6} key={summaryGroup.grouper}>
              <Grid container justifyContent="space-around">
                <Grouper item xs={12} container justifyContent="center">
                  {summaryGroup.grouper}
                </Grouper>
                <Label item xs={3}>
                  Lots
                </Label>
                <Data item xs={8}>
                  {summaryGroup.lotsSold} / {summaryGroup.lots} Sold
                </Data>
                <Label item xs={3}>
                  Hd
                </Label>
                <Data item xs={8}>
                  {summaryGroup.headSold} / {summaryGroup.head} Sold
                </Data>

                <Label item xs={3}>
                  Avg $/Hd
                </Label>
                <Data item xs={8}>
                  {summaryGroup.headSold > 0
                    ? `$${summaryGroup.averagePerHead}`
                    : "-"}
                </Data>
                <Label item xs={3}>
                  Top $/Hd
                </Label>
                <Data item xs={8}>
                  <TopHdPrice saleLot={summaryGroup.topUnitPrice} />
                </Data>
              </Grid>
            </Grid>
          ))}
          {[
            SummaryTypes.BY_ROUND_WITH_TOTAL,
            SummaryTypes.BY_SEX_WITH_TOTAL,
          ].includes(summaryType) ? (
            <SummaryBlock />
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Averages;
