import React, { memo } from "react";

import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { SelfTitledField } from "components/Card/Elements";
import {
  CheckpointType,
  ManualCheckpointType,
} from "components/Checkpoints/Constants";
import { Label, SelectField, TextArea } from "components/Form/FormikControls";
import Subtitle from "components/Text/Subtitle";

const CheckpointTypes = Object.values(ManualCheckpointType);
const CheckpointTypeOptions = CheckpointTypes.map(checkpointType => ({
  value: checkpointType,
  label: checkpointType,
}));

function EditCheckpointFormComponent({ hasUpdatesSince, isEdit }) {
  const formikProps = useFormikContext();
  const { type = "" } = formikProps.values;

  const wasManuallyCreated =
    type === CheckpointType.MANUAL_COMPLIANCE ||
    type === CheckpointType.MANUAL_COMMERCIAL;

  return (
    <>
      <Grid item xs={12}>
        {hasUpdatesSince && (
          <Subtitle>
            Changes have been made since this Checkpoint was created
          </Subtitle>
        )}
        <TextArea label="Comments" name="comments" disabled={hasUpdatesSince} />
      </Grid>
      <Grid item xs={12}>
        {wasManuallyCreated || !isEdit ? (
          <SelectField
            label="Type"
            name="type"
            options={CheckpointTypeOptions}
            disabled={hasUpdatesSince}
            menuPortalTarget={document.body}
          />
        ) : (
          <>
            <Label>Type</Label>
            <SelfTitledField title={type} />
          </>
        )}
      </Grid>
      <hr />
    </>
  );
}

export const EditCheckpointForm = memo(EditCheckpointFormComponent);
EditCheckpointForm.propTypes = {
  namespace: PropTypes.string,
  speciesId: PropTypes.number,
  checkpointId: PropTypes.string,
};
