import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import { Button, SecondaryButton } from "components/Form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { BusinessComparePanel } from "./BusinessComparePanel";

export function ReviewDuplicateDialog(props) {
  const { firstBusinessId, secondBusinessId, onClose, onMerge } = props;

  const initialBusinessId = firstBusinessId;

  const [destinationBusinessId, setDestinationBusinessId] =
    useState(initialBusinessId);

  function onClickClose() {
    typeof onClose === "function" && onClose();
  }

  function onClickFirstBusiness() {
    setDestinationBusinessId(firstBusinessId);
  }

  function onClickSecondBusiness() {
    setDestinationBusinessId(secondBusinessId);
  }

  function onClickMerge() {
    typeof onMerge === "function" &&
      onMerge(
        destinationBusinessId,
        destinationBusinessId === firstBusinessId
          ? secondBusinessId
          : firstBusinessId,
      );
  }

  useEffect(() => {
    // if the available businessIds change while the component is mounted, reset them to the initial values
    if (![firstBusinessId, secondBusinessId].includes(destinationBusinessId)) {
      setDestinationBusinessId(initialBusinessId);
    }
  }, [
    destinationBusinessId,
    firstBusinessId,
    initialBusinessId,
    secondBusinessId,
    setDestinationBusinessId,
  ]);

  return (
    <Dialog open onClose={onClickClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClickClose}>Select Business to Keep</DialogTitle>
      <DialogContent dividers form="true">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <BusinessComparePanel
              businessId={firstBusinessId}
              isSelected={firstBusinessId === destinationBusinessId}
              onClick={onClickFirstBusiness}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BusinessComparePanel
              businessId={secondBusinessId}
              isSelected={secondBusinessId === destinationBusinessId}
              onClick={onClickSecondBusiness}
            />
          </Grid>
        </Grid>
        <section>
          <h3>What happens when I merge two Businesses?</h3>
          <p>
            All data from the selected Business will be kept. Any additional
            data from the unselected Business will be combined and transferred
            to the existing Business. All of the following links to this
            Business will be updated to reflect the change:
          </p>
          <ul>
            <li>Addresses</li>
            <li>Bidder Registrations</li>
            <li>Buyer Sale Lots</li>
            <li>Buyer Ways</li>
            <li>Carrier Charges</li>
            <li>Email Addresses</li>
            <li>Sundry</li>
            <li>Payments</li>
            <li>PICs</li>
            <li>Third Party Buyer Sale Lots</li>
            <li>Vendor Consignments</li>
          </ul>
        </section>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClickClose}>Close</SecondaryButton>
        <Button data-tour="merge" onClick={onClickMerge}>
          Merge Business
        </Button>
      </DialogActions>
    </Dialog>
  );
}
ReviewDuplicateDialog.propTypes = {
  firstBusinessId: PropTypes.string,
  secondBusinessId: PropTypes.string,
  onClose: PropTypes.func,
  onMerge: PropTypes.func,
};
