import React from "react";

import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import { Formik, useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { SubtleBadge } from "components/Badge";
import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import {
  IconTextButton,
  SlimButton,
  SlimSecondaryButton,
} from "components/Button";
import { FormCollapse } from "components/Form";
import { PercentageInput, SelectField } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import Table from "components/Table";

import {
  BUSINESS_RELATION_OPTIONS,
  BUSINESS_RELATIONS_BUSINESS_ROLE,
} from "constants/businesses";

import { validatePercentage } from "lib/validators";

import { getBusinesses } from "selectors";

const Label = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-left: 30px;
`;

const businessRelationshipValidation = Yup.object().shape({
  relatedToId: Yup.string().nullable().required("Required"),
  relationType: Yup.string().required("Required"),
  percentage: validatePercentage(9, 6),
});

function BusinessRelationshipsForm(props) {
  const { cancelEditRelationship, agencyId } = props;
  const formikProps = useFormikContext();

  const { values, touched, errors } = formikProps;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SelectField
          name="relationType"
          label="Relation Type"
          options={BUSINESS_RELATION_OPTIONS}
          menuPortalTarget={document.querySelector(".MuiDialog-container")}
        />
      </Grid>
      {values.relationType && (
        <>
          <Grid item xs={12}>
            <StateAwareBusinessPICSelector
              label={values.relationType}
              businessFieldName="relatedToId"
              formikProps={formikProps}
              error={touched.relatedToId && errors.relatedToId}
              allowBusinessOnly
              agencyId={agencyId}
              businessRoles={[
                BUSINESS_RELATIONS_BUSINESS_ROLE[values.relationType],
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <PercentageInput
              name="percentage"
              label="Percentage"
              tooltip="% of Gross Commission Income (GCI) on sale proceeds"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <SlimSecondaryButton fullWidth onClick={cancelEditRelationship}>
          Cancel
        </SlimSecondaryButton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SlimButton
          fullWidth
          data-tour="save"
          type="submit"
          onClick={formikProps.handleSubmit}
          disabled={!(formikProps.isValid || formikProps.submitCount === 0)}
        >
          Save
        </SlimButton>
      </Grid>
    </Grid>
  );
}

const BusinessRelationshipsTable = props => {
  const businesses = useSelector(getBusinesses);

  const {
    relationships,
    readOnly,
    onToggle,
    isOpen,
    editingId,
    cancelEditRelationship,
    saveRelationship,
    addRelationship,
    editRelationship,
    deleteRelationship,
    agencyId,
  } = props;

  const actionAccessor = row => {
    if (readOnly) {
      return null;
    }
    return (
      <>
        <IconTextButton
          icon={faTrash}
          onClick={() => {
            deleteRelationship(row.original.id);
          }}
        />
        <IconTextButton
          icon={faPencilAlt}
          onClick={() => {
            editRelationship(row.original.id);
          }}
        />
      </>
    );
  };

  const numRelationships = relationships?.length || 0;
  const header = (
    <Row>
      <SubtleBadge hasErrorBadge={numRelationships === 0}>
        Relationships
      </SubtleBadge>
      <Row alignCenter flexWrap>
        <Label>
          {numRelationships} Relationship
          {numRelationships !== 1 ? "s" : ""}
        </Label>
      </Row>
    </Row>
  );

  const initialValues = editingId
    ? relationships.find(r => r.id === editingId) || {}
    : {};

  return (
    <FormCollapse
      isOpen={isOpen}
      dataTour={`${isOpen ? "hide" : "show"}Relationships`}
      onToggle={onToggle}
      header={header}
    >
      {editingId ? (
        <Formik
          validationSchema={businessRelationshipValidation}
          onSubmit={saveRelationship}
          initialValues={initialValues}
        >
          <BusinessRelationshipsForm
            cancelEditRelationship={cancelEditRelationship}
            agencyId={agencyId}
          />
        </Formik>
      ) : (
        <Column fullWidth>
          <Table
            data={relationships}
            columns={[
              {
                id: "relationType",
                Header: "Type",
                accessor: "relationType",
              },
              {
                id: "relatedTo",
                Header: "Related To",
                accessor: row => businesses[row.relatedToId]?.name,
              },
              {
                id: "percentage",
                Header: "Percentage",
                accessor: row => row.percentage * 100,
              },
              {
                id: "actions",
                Header: "",
                accessor: "",
                Cell: actionAccessor,
                className: "center",
              },
            ]}
          />
          {!readOnly && (
            <SlimButton onClick={addRelationship} data-tour="addNew">
              Add New Relationship
            </SlimButton>
          )}
        </Column>
      )}
    </FormCollapse>
  );
};

BusinessRelationshipsTable.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  relationships: PropTypes.array,
  addRelationship: PropTypes.func,
  saveRelationship: PropTypes.func,
  deleteRelationship: PropTypes.func,
  editRelationship: PropTypes.func,
  cancelEditRelationship: PropTypes.func,
  readOnly: PropTypes.bool,
  agencyId: PropTypes.number,
};

export default BusinessRelationshipsTable;
