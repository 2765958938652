import { isNil } from "lodash";
import { buffers, END, eventChannel } from "redux-saga";

export function createUploadFileChannel(
  url,
  file,
  formData,
  auth,
  method = "POST",
) {
  return eventChannel(emitter => {
    // Preparing the xhr and the data
    const xhr = new XMLHttpRequest();
    const data = new FormData();
    data.append("file", file);

    if (formData) {
      Object.keys(formData).forEach(key => {
        if (!isNil(formData[key])) {
          data.append(key, formData[key]);
        }
      });
    }

    // Adding the listeners
    const onProgress = e => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = evt => {
      // May be blank on the 'default' failure (eg, abort etc)
      const { status_code } = evt;
      emitter({ err: "Upload failed", status_code });
      emitter(END);
    };

    xhr.upload.addEventListener("progress", onProgress);
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, response } = xhr;
      if (readyState === 4) {
        if ([200, 201].indexOf(status) !== -1) {
          const jsonResponse = JSON.parse(response);
          emitter({ success: true, response: jsonResponse });
          emitter(END);
        } else {
          onFailure({ status_code: status });
        }
      }
    };

    // Sending the file
    xhr.open(method, url, true);
    const userRoleSlug = auth.activeRole ? auth.activeRole.slug : "";
    if (userRoleSlug) {
      xhr.setRequestHeader("X-User-Role", userRoleSlug);
    }
    xhr.send(data);
    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
