import React from "react";

import { NLISSightingReceiptModal } from "components/ReceiptModals/NLISSightingReceipt";
import { NLISTransferReceiptModal } from "components/ReceiptModals/NLISTransferReceipt";

import { ModalTypes } from "constants/navigation";
import { NLISFileTypes } from "constants/nlis";

export const NLISReceiptModalAdapter = ({ hash }) => {
  const hashParams = hash[ModalTypes.NLISTransferReceipt] || null;
  const [id, transferType] = JSON.parse(hashParams);
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";

  if (transferType === NLISFileTypes.NLISSightingFile) {
    return (
      <NLISSightingReceiptModal
        decodedReturnTo={decodedReturnTo}
        id={id}
        transferType={transferType}
      />
    );
  }

  return (
    <NLISTransferReceiptModal
      decodedReturnTo={decodedReturnTo}
      id={id}
      transferType={transferType}
    />
  );
};
