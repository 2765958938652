import React from "react";

import { Grid } from "@material-ui/core";
import { getIn, useFormikContext } from "formik";
import { useSelector } from "react-redux";

import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import {
  DateInput,
  Input,
  IntegerPriceInput,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";

import { TRANSPORTER } from "constants/businesses";
import { SaleTypes } from "constants/sale";

import { getCurrentSale } from "selectors";

const businessRoles = [TRANSPORTER];

export function CarrierChargeForm({ namespace: ns, optional = false }) {
  const formikProps = useFormikContext();
  const { touched, errors } = formikProps;
  const currentSaleType = useSelector(state => getCurrentSale(state).sale_type);
  const isPaddockSale = currentSaleType === SaleTypes.PADDOCK;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <StateAwareBusinessPICSelector
          label="Carrier"
          businessFieldName={withNamespace(ns, "carrier")}
          formikProps={formikProps}
          allowPropertyOnly={false}
          allowBusinessOnly
          optional={isPaddockSale || !optional}
          error={
            getIn(touched, withNamespace(ns, "carrier")) &&
            getIn(errors, withNamespace(ns, "carrier"))
          }
          businessRoles={businessRoles}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <DateInput
          label="Delivery Date"
          name={withNamespace(ns, "deliveryDate")}
          showErrorUntouched
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <Input
          label="Invoice Number"
          name={withNamespace(ns, "invoiceNumber")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <IntegerPriceInput
          label="Invoice Amount"
          name={withNamespace(ns, "invoiceAmount")}
          min={0}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextArea label="Delivery Notes" name={withNamespace(ns, "notes")} />
      </Grid>
    </>
  );
}
