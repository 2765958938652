import React from "react";

import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import styled from "styled-components/macro";

import { MainContentWrapper, Row } from "components/Layout";
import NoUserRoleDisplay from "components/NoUserRoleDisplay";
import RolePicker from "components/RolePicker";

import {
  getActiveRole,
  getAvailableSaleyards,
  numberOfRolesSelector,
} from "selectors";

const NoRoleWrapper = styled.div`
  margin: ${({ theme }) => theme.space[3]}px;
  background-color: ${({ theme }) => theme.colors.gray95};
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin: 0;
  }
`;

function UserOverview() {
  const activeRole = useSelector(getActiveRole);
  const userHasNoRoles = activeRole.slug === null;
  // if we are displaying the No Role content, we want the background color to  be light grey
  // to match the parent container background
  const Wrapper = userHasNoRoles ? NoRoleWrapper : MainContentWrapper;
  // TODO - put sales summary info here
  return (
    <Wrapper>
      <Row>
        <RolePicker embedded />
      </Row>
      {userHasNoRoles && <NoUserRoleDisplay />}
    </Wrapper>
  );
}

const mapStateToProps = (state, props) => ({
  numberOfRoles: numberOfRolesSelector(state, props),
  availableSaleyards: getAvailableSaleyards(state, props),
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(UserOverview);
