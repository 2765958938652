import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import { isSentryActive } from "lib/sentry/config";

import { getLogRocketAppId, isLogRocketActive } from "./config";

if (isLogRocketActive) {
  LogRocket.init(getLogRocketAppId(), {
    network: {
      requestSanitizer: request => {
        if (request.url.toLowerCase().indexOf("/auth/login") !== -1) {
          request.body = null;
        }
        return request;
      },
    },
  });
  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL(sessionURL => {
    // Once we have a session URL add it to out Sentry scope.
    if (isSentryActive) {
      Sentry.getCurrentScope().setExtra("sessionURL", sessionURL);
    }
  });
}
