import React from "react";

import { useTheme } from "hooks";

export const FrontGateOpenBackGateClosed = ({ color, size }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.black;

  const svgSize = size || theme.fontSizes.gamma;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox="0 0 27500 27500"
    >
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="979.7"
        y1="9975.3"
        x2="6952"
        y2="17727"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21861.1"
        y1="13620.7"
        x2="21861.1"
        y2="6166.1"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6952"
        y1="21075.2"
        x2="6952"
        y2="17727"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="14406.6"
        y1="21075.2"
        x2="21861.1"
        y2="21075.2"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21861.1"
        y1="21075.2"
        x2="21861.1"
        y2="13620.7"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="14406.6"
        y1="6166.1"
        x2="21861.1"
        y2="6166.1"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6952"
        y1="21075.2"
        x2="14406.6"
        y2="21075.2"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6952"
        y1="6166.1"
        x2="14406.6"
        y2="6166.1"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6952"
        cy="17727"
        r="335.5"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6952.1"
        cy="6166.1"
        r="335.5"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6952.1"
        cy="21075.2"
        r="335.5"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="14406.6"
        cy="21075.2"
        r="335.5"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="14406.6"
        cy="6166.1"
        r="335.5"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21861.1"
        cy="13620.7"
        r="335.5"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21861.1"
        cy="21075.2"
        r="335.5"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21861.1"
        cy="6166.1"
        r="335.5"
      />
      <path
        fill={svgColor}
        stroke={svgColor}
        d="M4825.6 11472.2c419.7-30.3 698.4 133.2 1120.5 44.9l-2197.5-2765.8 436.6-368.7 2212.6 2775.3c178-409.9 65.5-706.9 214.3-1077l472.6 16.4c68 272-144.1 1785.1-199.8 2125.9-299.5 14.1-1882.3-144-2140.3-285.1l81.2-465.9z"
      />
    </svg>
  );
};

export default FrontGateOpenBackGateClosed;
