import { all, call, put, takeEvery } from "redux-saga/effects";

import {
  requestDeploymentAttributeFailure,
  requestDeploymentAttributeSuccess,
} from "actions";

import {
  GET_DEPLOYMENT_AGES,
  GET_DEPLOYMENT_ATTRIBUTES,
  GET_DEPLOYMENT_BREEDS,
  GET_DEPLOYMENT_DENTITION,
  GET_DEPLOYMENT_LABELS,
  GET_DEPLOYMENT_MARKS,
  GET_DEPLOYMENT_SEXES,
} from "constants/actionTypes";

import { api } from "./api";

function* fetchDeploymentAttributes(action) {
  try {
    const [
      deployment_breeds,
      deployment_sexes,
      deployment_ages,
      deployment_labels,
      deployment_marks,
      deployment_dentition,
    ] = yield all([
      yield call(api.get, "/v2/deployment_breeds/"),
      yield call(api.get, "/v2/deployment_sexes/"),
      yield call(api.get, "/v2/deployment_ages/"),
      yield call(api.get, "/v2/deployment_labels/"),
      yield call(api.get, "/v2/deployment_marks/"),
      yield call(api.get, "/v2/deployment_dentition/"),
    ]);

    yield put(
      requestDeploymentAttributeSuccess(
        GET_DEPLOYMENT_BREEDS,
        deployment_breeds,
      ),
    );
    yield put(
      requestDeploymentAttributeSuccess(GET_DEPLOYMENT_SEXES, deployment_sexes),
    );
    yield put(
      requestDeploymentAttributeSuccess(GET_DEPLOYMENT_AGES, deployment_ages),
    );
    yield put(
      requestDeploymentAttributeSuccess(
        GET_DEPLOYMENT_LABELS,
        deployment_labels,
      ),
    );
    yield put(
      requestDeploymentAttributeSuccess(GET_DEPLOYMENT_MARKS, deployment_marks),
    );
    yield put(
      requestDeploymentAttributeSuccess(
        GET_DEPLOYMENT_DENTITION,
        deployment_dentition,
      ),
    );
  } catch (e) {
    yield call(api.handleFetchError, e, "deployment attributes", action);
    yield put(requestDeploymentAttributeFailure());
  }
}

function* rootSaga() {
  yield takeEvery(GET_DEPLOYMENT_ATTRIBUTES, fetchDeploymentAttributes);
}

export default rootSaga;
