import { sum } from "lodash";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import {
  SaleLotAction,
  ScanAction,
  WeighLotScanAction,
  patchSaleLot,
  patchScan,
  setSaleLotOfflineValues,
} from "actions";

import { SCAN, WEIGH_LOT_SCAN } from "constants/actionTypes";
import { UNALLOCATED } from "constants/scanner";
import { WeighLotScanStatus } from "constants/weighScanning";

import {
  calculateTotalPriceCents,
  calculateUnitPrice,
  calculateUnitPriceDollars,
} from "lib";

import { getLivestockSaleId } from "lib/navigation";

import {
  getSaleLotById,
  getSaleLots,
  getScanByEid,
  getScans,
  getScansBySaleLotId,
  getWeighLotScanById,
  getWeighLotScanIdsByEid,
  getWeighLotScans,
  selectScanByWeighLotScanIdLookup,
  selectWeighLotIdsByEidLookup,
} from "selectors";

/**
 * Selecting keep on a Weigh Lot Scan will set other Weigh Lot Scans that are not deduplicated
 * as ignored. The user is essentially decided they want this Weigh Lot Scan, not the others.
 * If the Weigh Lot Scan is a reweigh (the EID exists in another Weigh Lot) - We will set the
 * status to re-weighed otherwise we will set the Weigh Lot Scan status as mapped.
 *
 * Then we will link the kept Weigh Lot Scan to the associated Scan by their EID
 *
 * @param {String} weighLotScanId
 */
function* onHandleKeepWeighLotScan(action) {
  const { weighLotScanId } = action;

  const state = yield select();

  const weighLotScan = getWeighLotScanById(weighLotScanId)(state);

  const weighLotScanByIdLookup = getWeighLotScans(state);

  const associatedScan = getScans(state)[weighLotScan.eid];

  const weighLotScanIdsSharingSameEid = getWeighLotScanIdsByEid(
    weighLotScan.eid,
  )(state);

  const weighLotIdsByEidlookup = selectWeighLotIdsByEidLookup(state);

  const weighLotScanIdsWithSameEid = weighLotScanIdsSharingSameEid.filter(
    weighLotScanId =>
      weighLotScanByIdLookup[weighLotScanId]?.status !==
      WeighLotScanStatus.DEDUPLICATED,
  );

  const mappableWeighLotScanIds = [];
  const ignorableWeighLotScanIds = [];

  for (const wlsId of weighLotScanIdsWithSameEid) {
    if (wlsId === weighLotScanId) {
      mappableWeighLotScanIds.push(wlsId);
    } else {
      ignorableWeighLotScanIds.push(wlsId);
    }

    for (const wlsId of mappableWeighLotScanIds) {
      const wls = weighLotScanByIdLookup[wlsId];
      // if an eid exists in more than one weigh lot - it was a reweigh
      // we want to show unresolved weigh lot scans that exist in more
      // than on lot as a reweigh
      const isReweigh = weighLotIdsByEidlookup[wls.eid].length > 1;

      yield put(
        WeighLotScanAction.update({
          id: wlsId,
          status: isReweigh
            ? WeighLotScanStatus.REWEIGHED
            : WeighLotScanStatus.MAPPED,
        }),
      );
    }

    for (const wlsId of ignorableWeighLotScanIds) {
      yield put(
        WeighLotScanAction.update({
          id: wlsId,
          status: WeighLotScanStatus.IGNORED,
        }),
      );
    }
  }
  if (associatedScan) {
    // if we have a linked scan - set the weigh lot scan id to this weigh lot scan id and update the weight
    yield put(
      patchScan(associatedScan, {
        weigh_lot_scan_id: weighLotScanId,
        recalculate_salelot_weight: true,
        total_mass_grams: weighLotScan.totalMassGrams,
        sale_lot_id:
          associatedScan.sale_lot_id !== UNALLOCATED
            ? associatedScan.sale_lot_id
            : null,
      }),
    );
  }
}

/**
 * Selecting ignore on a Weigh Lot Scan will set the status of the Weigh Lot Scan to ignored
 * and remove the link between the Weigh Lot Scan and the Scan it is associated to.
 *
 * The user is essentially deciding they do not want to use this Weigh Lot Scan in this sale.
 * @param {String} weighLotScanId
 */
function* onHandleIgnoreWeighLotScan(action) {
  const { weighLotScanId } = action;

  const state = yield select();

  const associatedScan =
    selectScanByWeighLotScanIdLookup(state)[weighLotScanId];

  yield put(
    WeighLotScanAction.update({
      id: weighLotScanId,
      status: WeighLotScanStatus.IGNORED,
    }),
  );
  if (associatedScan) {
    yield put(
      patchScan(associatedScan, {
        weigh_lot_scan_id: null,
        total_mass_grams: null,
        recalculate_salelot_weight: true,
        sale_lot_id:
          associatedScan.sale_lot_id !== UNALLOCATED
            ? associatedScan.sale_lot_id
            : null,
      }),
    );
  }
}

function* onHandleAddScanToSale(action) {
  const { weighLotScanId } = action;

  const state = yield select();

  const weighLotScan = getWeighLotScanById(weighLotScanId)(state);

  const { eid, totalMassGrams } = weighLotScan;

  yield put(
    ScanAction.create([
      {
        id: uuidv4(),
        livestock_sale_id: getLivestockSaleId(),
        EID: eid,
        weigh_lot_scan_id: weighLotScanId,
        total_mass_grams: totalMassGrams,
        recalculate_salelot_weight: true,
      },
    ]),
  );
  yield put(
    WeighLotScanAction.update({
      id: weighLotScanId,
      status: WeighLotScanStatus.MAPPED,
    }),
  );
}

function* onHandleManuallyMapToScan(action) {
  const { weighLotScanId, scan } = action;

  const state = yield select();

  const weighLotScan = getWeighLotScanById(weighLotScanId)(state);

  const weighLotScanByIdLookup = getWeighLotScans(state);

  // we want to get the asssociated weigh lot scan ids that have the same eid as the selected scan
  const weighLotScanIdsSharingSameEid = getWeighLotScanIdsByEid(scan.EID)(
    state,
  );
  const ignorableWeighLotScanIds = weighLotScanIdsSharingSameEid.filter(
    weighLotScanId =>
      weighLotScanId !== weighLotScan.id &&
      weighLotScanByIdLookup[weighLotScanId]?.status !==
        WeighLotScanStatus.DEDUPLICATED,
  );

  // if the scan already has a weigh lot scan - set that weigh lot scan as ignored
  if (scan.weigh_lot_scan_id && scan.weigh_lot_scan_id !== weighLotScanId) {
    yield put(
      WeighLotScanAction.update({
        id: scan.weigh_lot_scan_id,
        status: WeighLotScanStatus.IGNORED,
      }),
    );
  }
  // add the new weight to the scan and link the select weigh lot scan
  yield put(
    patchScan(scan, {
      weigh_lot_scan_id: weighLotScanId,
      total_mass_grams: weighLotScan.totalMassGrams,
      recalculate_salelot_weight: true,
      sale_lot_id: scan.sale_lot_id !== UNALLOCATED ? scan.sale_lot_id : null,
    }),
  );
  // updated the selected weigh lot scan status to manually mapped
  yield put(
    WeighLotScanAction.update({
      id: weighLotScanId,
      status: WeighLotScanStatus.MANUALLY_MAPPED,
    }),
  );

  // set asssociated weigh lot scan ids that have the same eid as the selected scan as ignored
  for (const id of ignorableWeighLotScanIds) {
    yield put(
      WeighLotScanAction.update({
        id,
        status: WeighLotScanStatus.IGNORED,
      }),
    );
  }
}

function* onHandleAddWeightToSaleLot(action) {
  const { weighLotScanId, saleLotId } = action;

  const state = yield select();

  const saleLot = getSaleLots(state)[saleLotId];

  const weighLotScan = getWeighLotScans(state)[weighLotScanId];

  const newPrice = calculateTotalPriceCents({
    total_mass_grams: weighLotScan.totalMassGrams,
    pricing_type_id: saleLot.pricing_type_id,
    quantity: saleLot.quantity,
    unitPrice: calculateUnitPriceDollars(saleLot),
  });

  const comment = `This Sale Lot weight has been overwritten by a weigh lot scan with the EID: ${weighLotScan.eid}.\n\nThe price has been updated from ${saleLot.total_price_cents} to ${newPrice}. \n\nThe Weight has been updated from ${saleLot.total_mass_grams} to ${weighLotScan.totalMassGrams}`;

  yield put(
    patchSaleLot({
      ...saleLot,
      total_mass_grams: weighLotScan.totalMassGrams,
      total_price_cents: newPrice,
    }),
  );

  yield put(
    WeighLotScanAction.update({
      id: weighLotScanId,
      status: WeighLotScanStatus.MAPPED_TO_SALE_LOT,
    }),
  );

  yield put(SaleLotAction.comment(saleLotId, { comment }));
}

function* calculateOfflineSaleLotWeightValues(saleLotIds) {
  // for each sale lot get the total weight of scans associated to the sale lot
  // and apply that weight to the sale lot while updating the price
  const state = yield select();
  for (const saleLotId of saleLotIds) {
    const saleLot = getSaleLotById(saleLotId)(state);
    // saleLotId may be UNALLOCATED :sadparrot
    if (saleLot) {
      const saleLotScans = getScansBySaleLotId(saleLotId)(state);

      const adjustedSaleLotWeight = sum(
        saleLotScans.map(scan =>
          scan.total_mass_grams ? parseInt(scan.total_mass_grams, 10) : 0,
        ),
      );

      yield put(
        setSaleLotOfflineValues(saleLot.id, {
          total_mass_grams: adjustedSaleLotWeight,
          total_price_cents: calculateTotalPriceCents({
            ...saleLot,
            unitPrice: calculateUnitPrice(saleLot),
            total_mass_grams: adjustedSaleLotWeight,
          }),
        }),
      );
    }
  }
}

function* handleBulkOfflineAllocation(action) {
  // if we pass recalculate_salelot_weight into the scans payload, we want to calculate the
  // weight of all the scans associated the each sale lot affected and apply that weight to
  // to each sale lot
  const { payload: scansList } = action;

  if (!Array.isArray(scansList) || !scansList.length) {
    return;
  }

  const state = yield select();

  const saleLotIdsToUpdate = new Set();

  // build a list of affected sale lots - this includes the new sale lot and stored sale lots
  if (scansList.length) {
    const firstScan = scansList[0];
    if (firstScan.recalculate_salelot_weight) {
      for (const scan of scansList) {
        const oldScan = getScanByEid(scan.EID)(state) || {};
        saleLotIdsToUpdate.add(scan.sale_lot_id || null);
        saleLotIdsToUpdate.add(oldScan.sale_lot_id || null);
      }
    }

    saleLotIdsToUpdate.delete(null);

    if (saleLotIdsToUpdate.size) {
      yield call(calculateOfflineSaleLotWeightValues, saleLotIdsToUpdate);
    }
  }
}

function* handleSingleOfflineAllocation(action) {
  // if we pass recalculate_salelot_weight into a scan payload, we want to calculate the
  // weight of all the scans associated the each sale lot affected and apply that weight to
  // to each sale lot
  const {
    meta: { payload: scan },
  } = action;

  if (scan) {
    if (scan.recalculate_salelot_weight && scan.sale_lot_id) {
      yield call(calculateOfflineSaleLotWeightValues, [scan.sale_lot_id]);
    }
  }
}

export default function* weighLotScanSaga() {
  yield takeEvery(WEIGH_LOT_SCAN.KEEP.ACTION, onHandleKeepWeighLotScan);
  yield takeEvery(WEIGH_LOT_SCAN.IGNORE.ACTION, onHandleIgnoreWeighLotScan);
  yield takeEvery(
    WEIGH_LOT_SCAN.ADD_SCAN_TO_SALE.ACTION,
    onHandleAddScanToSale,
  );
  yield takeEvery(
    WEIGH_LOT_SCAN.MANUALLY_MAP_TO_SCAN.ACTION,
    onHandleManuallyMapToScan,
  );
  yield takeEvery(
    WEIGH_LOT_SCAN.ADD_WEIGH_TO_SALE_LOT.ACTION,
    onHandleAddWeightToSaleLot,
  );
  yield takeEvery(SCAN.CREATE.REQUEST, handleBulkOfflineAllocation);
  yield takeEvery(SCAN.UPDATE.REQUEST, handleSingleOfflineAllocation);
}
