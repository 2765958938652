import React, { createRef } from "react";

import styled from "styled-components/macro";

const FilterWrapper = styled.div`
  padding: 6px;
`;

export class CompositeFilter extends React.Component {
  static componentName = "agrinousCompositeFilter";

  constructor(props) {
    super(props);
    const { components } = this.props;
    this.state = {
      componentRefs: components.map(createRef),
    };
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    } else {
      const { componentRefs } = this.state;
      return componentRefs.map(ref => ref.current.getValue());
    }
  }

  setModel(model) {
    const { componentRefs } = this.state;
    if (model === null) {
      componentRefs.map(ref => ref.current.setValue(null));
    } else {
      return model.forEach((modelValue, i) =>
        componentRefs[i].current.setValue(modelValue),
      );
    }
  }

  doesFilterPass(params) {
    const { valueGetter } = this.props;
    const value = valueGetter(params);

    const { componentRefs } = this.state;
    return componentRefs.every(ref => {
      if (!ref.current.isActive()) {
        return true;
      } else {
        return ref.current.filter(value, params.data);
      }
    });
  }

  isFilterActive() {
    const { componentRefs } = this.state;
    return componentRefs.some(ref => ref.current.isActive());
  }

  render() {
    const { components, filterChangedCallback } = this.props;
    const { componentRefs } = this.state;

    return (
      <FilterWrapper>
        {components.map(({ params, filterComponent: FilterComponent }, i) => {
          return (
            <FilterComponent
              key={i}
              ref={componentRefs[i]}
              onValueChanged={filterChangedCallback}
              {...params}
            />
          );
        })}
      </FilterWrapper>
    );
  }
}
