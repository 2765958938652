import React from "react";

export default ({ children, onClick, onChange }) => {
  return (
    <span
      style={{ display: "contents" }}
      onClick={e => {
        e.stopPropagation();
        typeof onClick === "function" && onClick(e);
      }}
      onChange={e => {
        e.stopPropagation();
        typeof onChange === "function" && onChange(e);
      }}
    >
      {children}
    </span>
  );
};
