import React, { useEffect } from "react";

import sortBy from "lodash/sortBy";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { printPenWeighCardPortrait } from "actions";

import OpenedInNewWindow from "components/Pdfs/OpenedInNewWindow";

import { Species } from "constants/species";

import { isSoldOrNoSale } from "lib/auctionPens";
import { breakword, createAndOpenPdf } from "lib/pdf";
import { getAverageWeightKg, getCombinedLotNumber } from "lib/saleLot";
import { formatTime, getAUFormattedDate } from "lib/timeFormats";

import {
  getIsFetchingAuctionPens,
  getIsFetchingBusinesses,
  getIsFetchingConsignments,
  getIsFetchingSaleLots,
  getIsFetchingSales,
  getIsFetchingScans,
} from "selectors";

import logo from "img/AgriNous-logo-base64";

const pageMargin = 12;
const pageMarginBottom = 40;

const getFooterText = ({ saleyardName, roundName, startPen, saleDate }) => {
  return `${saleyardName} ${(
    roundName || ""
  ).toUpperCase()} Sale ${getAUFormattedDate(
    saleDate,
  )} - Pen: ${startPen}  Printed: ${formatTime(Date.now())}`;
};

const getFooter = (weighPenCardData, pageMargin) => currentPage => ({
  columns: [
    {
      image: `data:image/jpeg;base64,${logo}`,
      width: 100,
    },
    {
      width: "auto",
      alignment: "right",
      text: weighPenCardData[currentPage - 1]
        ? getFooterText(weighPenCardData[currentPage - 1].footerData)
        : "",
      marginTop: 4,
      marginLeft: 10,
    },
  ],
  margin: [pageMargin + 3, 0],
});

const getContent = weighPenCardData => {
  const contents = weighPenCardData.map((data, index, array) =>
    [
      {
        layout: "noBorders",
        table: {
          widths: ["*", 75, "*"],
          body: [
            [
              {
                fontSize: 20,
                text: "Pen",
                alignment: "center",
              },
              { width: 90, fontSize: 20, text: "", alignment: "center" },
              {
                fontSize: 20,
                text: "Mark",
                alignment: "center",
              },
            ],
            [
              {
                text: data.penName,
                fontSize: 80,
                alignment: "center",
                bold: true,
              },
              { fontSize: 20, text: "", alignment: "center" },
              {
                text: data.marks,
                fontSize: 80,
                alignment: "center",
                bold: true,
              },
            ],
          ],
        },
      },
      {
        layout: "noBorders",
        table: {
          widths: [60, "*"],
          body: [
            [
              {
                text: "Agent:",
                fontSize: 20,
                alignment: "left",
                bold: true,
                margin: [0, 15, 0, 0],
              },
              {
                text: data.agencyName,
                fontSize: 35,
                alignment: "center",
                bold: true,
              },
            ],
          ],
        },
      },
      {
        layout: "noBorders",
        table: {
          widths: [70, "*"],
          body: [
            [
              {
                text: "District:",
                fontSize: 20,
                alignment: "left",
                bold: true,
                margin: [0, 10, 0, 0],
              },
              {
                text: data.district,
                fontSize: 35,
                alignment: "center",
                bold: true,
              },
            ],
          ],
        },
      },
      data.speciesId === Species.CATTLE && {
        layout: "noBorders",
        margin: [0, 0, 0, 1],
        table: {
          widths: [80, 105, 105, 75, 75, 75],
          heights: [10, 10, 10, 10, 10, 10],
          body: [
            [
              {
                text: "HGP",
                alignment: "center",
                fontSize: 20,
              },
              { text: "VB", alignment: "center", fontSize: 20 },
              { text: "LT", alignment: "center", fontSize: 20 },
              {
                text: "LPA",
                alignment: "center",
                fontSize: 20,
              },
              { text: "RE", alignment: "center", fontSize: 20 },
              { text: "SAE", alignment: "center", fontSize: 20 },
            ],
            [
              {
                text: data.isHgp,
                fontSize: 55,
                alignment: "center",
                bold: true,
              },
              {
                text: data.isVb,
                fontSize: 55,
                alignment: "center",
                bold: true,
              },
              {
                text: data.isLt,
                fontSize: 55,
                alignment: "center",
                bold: true,
              },
              {
                text: data.isLpa,
                fontSize: 55,
                alignment: "center",
                bold: true,
              },
              {
                text: data.isRussianEligible,
                fontSize: 55,
                alignment: "center",
                bold: true,
              },
              {
                text: data.isSaudiEligible,
                fontSize: 55,
                alignment: "center",
                bold: true,
              },
            ],
          ],
        },
      },
      !data.hasDeclarations && {
        layout: "noBorders",
        table: {
          widths: [70],
          body: [
            [
              {
                text: "*No NVD",
                fontSize: 12,
                alignment: "center",
                bold: true,
              },
            ],
          ],
        },
      },
      !data.hasScans && {
        layout: "noBorders",
        table: {
          widths: [70],
          body: [
            [
              {
                text: "*No EIDs",
                fontSize: 12,
                alignment: "center",
                bold: true,
              },
            ],
          ],
        },
      },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 568, y2: 0, lineWidth: 1 }],
      },
      {
        margin: [0, 0, 0, 1],
        layout: "noBorders",
        table: {
          widths: [15, 150, "*"],
          body: [
            [
              { text: "" },
              {
                fontSize: 55,
                text: "Head",
                alignment: "left",
                bold: true,
                margin: [0, 15, 0, 0],
              },
              {
                fontSize: 80,
                text: data.headCount,
                alignment: "right",
                bold: true,
              },
            ],
            [
              { text: "" },
              {
                fontSize: 55,
                text: "Avg.",
                alignment: "left",
                bold: true,
                margin: [0, 15, 0, 0],
              },
              {
                fontSize: 80,
                text: data.averageWeight,
                alignment: "right",
                bold: true,
              },
            ],
            [
              { text: "" },
              {
                fontSize: 55,
                text: "Total",
                alignment: "left",
                bold: true,
                margin: [0, 15, 0, 0],
              },
              {
                fontSize: 80,
                text: data.totalWeight,
                alignment: "right",
                bold: true,
              },
            ],
            [
              { text: "" },
              {
                fontSize: 55,
                text: "Sex",
                alignment: "left",
                bold: true,
              },
              {
                fontSize: 50,
                text: data.sexName,
                alignment: "right",
                bold: true,
                margin: [0, 5, 0, 0],
              },
            ],
          ],
        },
      },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 568, y2: 0, lineWidth: 1 }],
      },
      {
        layout: "noBorders",
        table: {
          widths: [30, 30, 50, 30, "*", "*", 50, 50],
          body: [
            [
              {
                text: `Lot #`,
                alignment: "center",
                bold: true,
              },
              {
                text: `Head`,
                alignment: "center",
                bold: true,
              },
              {
                text: `Mark`,
                alignment: "center",
                bold: true,
              },
              {
                text: `Sex`,
                alignment: "center",
                bold: true,
              },
              {
                text: `Breed`,
                alignment: "center",
                bold: true,
              },
              {
                text: `Vendor`,
                alignment: "center",
                bold: true,
              },
              {
                text: `Weight`,
                alignment: "center",
                bold: true,
              },
              {
                text: `Method`,
                alignment: "center",
                bold: true,
              },
            ],
            ...sortBy(data.saleLots, "lot_number").map(s => {
              const weight = Math.round(getAverageWeightKg(s));

              let weighingMethod = "BW";
              if (
                s?.scans?.some(
                  scan =>
                    typeof scan.total_mass_grams === "number" &&
                    scan.total_mass_grams > 0,
                )
              ) {
                weighingMethod = "SW";
              } else if (!isSoldOrNoSale(s)) {
                weighingMethod = "-";
              }

              return [
                {
                  text: getCombinedLotNumber(s),
                  alignment: "center",
                },

                { text: s.quantity, alignment: "center" },
                {
                  text: data.saleLotsInMarkOrder[s.id].map(
                    mark => mark.mark.location,
                  ),
                  alignment: "center",
                },
                { text: s.sex.code || "-", alignment: "center" },

                { text: s.breed_name, alignment: "center" },
                {
                  text: breakword(s.vendor.name, 18),
                  alignment: "center",
                },

                {
                  text: weight,
                  alignment: "center",
                },
                {
                  text: weighingMethod,
                  alignment: "center",
                },
              ];
            }),
          ],
        },
        pageBreak: array.length - 1 !== index ? "after" : undefined,
      },
      {
        columns: [],
      },
    ].filter(Boolean),
  );
  return contents;
};

/**
 *
 * @param {Object} saleLot
 * @param {Object} sale
 * @param {String} agentId
 * @return {Document}
 */
export const generatePenWeighCardPortraitPdf = (
  weighPenCardData,
  sale,
  suppressBack,
) => {
  const pdfDefinition = {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [pageMargin, pageMargin, pageMargin, pageMarginBottom],
    content: getContent(weighPenCardData),
    footer: getFooter(weighPenCardData, pageMargin),
    styles: {
      headerStyle: {
        fontSize: 40,
        margin: [15, 15, 15, 15],
      },
      contentStyle: {
        fontSize: 40,
        overflow: "hidden",
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin: [15, 30, 15, 15],
      },
    },
  };

  createAndOpenPdf(pdfDefinition, undefined, suppressBack);
};

export const PenWeighCardPortrait = () => {
  const isLoading = useSelector(
    state =>
      getIsFetchingSaleLots(state) ||
      getIsFetchingConsignments(state) ||
      getIsFetchingAuctionPens(state) ||
      getIsFetchingScans(state) ||
      getIsFetchingSales(state) ||
      getIsFetchingBusinesses(state),
  );
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { saleLotId, agentId } = match.params;
  useEffect(() => {
    if (!isLoading) {
      dispatch(printPenWeighCardPortrait(saleLotId, false, agentId));
    }
  }, [dispatch, isLoading, saleLotId, agentId]);

  return (
    <OpenedInNewWindow message="The Pen Weigh Card was opened in a new window." />
  );
};
