import React, { useMemo } from "react";

import { get } from "lodash";
import { useSelector } from "react-redux";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { AlmostFullScreenModalTableWrapper } from "components/AgGrid/TableWrapper";

import { AgGridTables } from "constants/aggrid";
import {
  ExtendedLedgerEntryColumnDef,
  LedgerEntryColId,
} from "constants/columnDefinitions";

import { getBillingTags, getBusinesses } from "selectors";

const rowSelectionId = "ledgerEntry.id";

const getRowId = params => get(params.data, rowSelectionId);

const columnDefs = [
  ExtendedLedgerEntryColumnDef[LedgerEntryColId.RULE_NAME],
  ExtendedLedgerEntryColumnDef[LedgerEntryColId.INVOICE_LINE_ITEM],
  ExtendedLedgerEntryColumnDef[LedgerEntryColId.TITLE],
  ExtendedLedgerEntryColumnDef[LedgerEntryColId.REFERENCE_OBJECT],
  ExtendedLedgerEntryColumnDef[LedgerEntryColId.TOTAL_INC],
  ExtendedLedgerEntryColumnDef[LedgerEntryColId.TOTAL_EX],
  ExtendedLedgerEntryColumnDef[LedgerEntryColId.GST],

  ExtendedLedgerEntryColumnDef[LedgerEntryColId.GL_CODE],
  ExtendedLedgerEntryColumnDef[LedgerEntryColId.TAX_TYPE],

  ExtendedLedgerEntryColumnDef[LedgerEntryColId.BILLING_TAGS],
  ExtendedLedgerEntryColumnDef[LedgerEntryColId.CATEGORY],

  {
    colId: LedgerEntryColId.FROM_BUSINESS_NAME,
    field: "fromBusiness.name",
    headerName: "From Business",
    enableRowGroup: true,
  },

  {
    colId: LedgerEntryColId.TO_BUSINESS_NAME,
    field: "toBusiness.name",
    headerName: "To Business",
    enableRowGroup: true,
  },
];

export function LedgerEntryResults(props) {
  const { results } = props;

  const businesses = useSelector(getBusinesses);
  const billingTags = useSelector(getBillingTags);

  // As we don't have the result objects in redux state, this is somewhat of a selector, as we'd normally know it -
  // take some data, enrich it for display.
  const rowData = useMemo(() => {
    return results.ledgerEntries.map(ledgerEntry => ({
      ledgerEntry,
      fromBusiness: businesses[ledgerEntry.fromBusinessId],
      toBusiness: businesses[ledgerEntry.toBusinessId],
    }));
  }, [results, businesses]);

  return (
    <AgGridTable
      columnDefs={columnDefs}
      getRowId={getRowId}
      rowData={rowData}
      showGlobalSearchFilter={false}
      WrapperComponent={AlmostFullScreenModalTableWrapper}
      tableName={AgGridTables.TEST_RULEBOOK}
      context={{ billingTags }}
    />
  );
}
