import React, { memo } from "react";

import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import arrayMove from "array-move";
import Downshift from "downshift";
import { useField } from "formik";
import { components } from "react-select";
import styled from "styled-components/macro";

import { Label, StyledCheckbox } from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";
import { Column, Row } from "components/Layout";

import { getNumberWithOrdinal, toTitleCase } from "lib";

import { Expander, SelectInput } from "./Downshift";

const SquareIcon = styled(IconButton)`
  height: 20px;
  width: 20px;
`;

const FaIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: white;
`;

const Action = styled.div`
  cursor: pointer;
  visibility: ${({ hide }) => (hide ? "hidden" : "inherit")};
  justify-self: center;
`;

const OptionComponent = styled(Row)(
  ({ theme }) => `
  background-color: ${theme.colors.primary};
  border-radius: ${theme.radii[3]}px;
  color: ${theme.colors.white};
  transition: ${theme.transitions[0]};
  &:hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.primaryHighlight};
  }
  text-align: center;
  margin: 2px;
  height: 30px;
  align-items: center;
`,
);

const Item = styled.li`
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  font-size: 14px;
  text-transform: none;
  padding: 0px 12px;
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primaryActive : "white"};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.white : theme.colors.titleGray};
  &:hover {
    background ${({ theme }) => theme.colors.primaryHighlight};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Menu = styled.ul`
  background: white;
  margin: 8px 0px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray78};
  position: absolute;
  z-index: 10;
  width: 100%;
  padding: 4px 0;
  box-shadow:
    0 0 0 1px hsla(0, 0%, 0%, 0.1),
    0 4px 11px hsla(0, 0%, 0%, 0.1);
`;

const OrderableMultiSelectComponent = ({
  label,
  name,
  options,
  required,
  tooltip,
  disabled,
  dataTour,
  placeHolder,
}) => {
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;
  const currentValue = field.value;

  const currentValueIds = currentValue?.map(r => r.value) || [];

  const moveUp = valueId => {
    const currentIndex = currentValueIds.findIndex(
      currentValue => currentValue === valueId,
    );

    if (currentIndex - 1 >= 0) {
      const newValues = arrayMove(
        currentValueIds,
        currentIndex,
        currentIndex - 1,
      );
      helpers.setValue(
        newValues.map(valueId =>
          options.find(option => option.value === valueId),
        ),
      );
    }
  };

  const moveDown = valueId => {
    const currentIndex = currentValueIds.findIndex(
      currentValue => currentValue === valueId,
    );
    if (currentIndex + 1 < currentValueIds.length) {
      const newValues = arrayMove(
        currentValueIds,
        currentIndex,
        currentIndex + 1,
      );
      helpers.setValue(
        newValues.map(valueId =>
          options.find(option => option.value === valueId),
        ),
      );
    }
  };

  const SelectedOption = props => {
    const { index, item, selectedItem } = props;

    return (
      <OptionComponent>
        <Action hide={index === 0}>
          <SquareIcon
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              moveUp(item.value);
            }}
            disabled={disabled}
          >
            <FaIcon icon={faAngleLeft} />
          </SquareIcon>
        </Action>
        <div>{getNumberWithOrdinal(index + 1)}</div> &nbsp;
        <strong>{toTitleCase(item.label)}</strong>
        <Action hide={index === selectedItem.length - 1}>
          <SquareIcon
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              moveDown(item.value);
            }}
            disabled={disabled}
          >
            <FaIcon icon={faAngleRight} />
          </SquareIcon>
        </Action>
      </OptionComponent>
    );
  };

  const handleChange = item => {
    if (currentValueIds.includes(item.value)) {
      helpers.setValue(currentValue.filter(r => r.value !== item.value));
    } else {
      helpers.setValue([...currentValue, item]);
    }
  };

  return (
    <Column fullWidth>
      <Label
        htmlFor={name}
        error={!!error}
        required={required}
        tooltip={tooltip}
      >
        {label}
      </Label>
      <Downshift
        selectedItem={currentValue}
        onChange={handleChange}
        itemToString={item => item.label}
      >
        {({
          getToggleButtonProps,
          getMenuProps,
          isOpen,
          selectedItem,
          getItemProps,
          highlightedIndex,
          toggleMenu,
        }) => {
          const mapSelectedOptions = (item, index) => (
            <SelectedOption
              key={item.value}
              item={item}
              selectedItem={selectedItem}
              index={index}
            />
          );

          return (
            // Must be a dom node, not a styled component and must have position
            // relative for the absolutley positioned menu to be styled correctly.
            <div style={{ position: "relative" }}>
              <SelectInput
                onClick={toggleMenu}
                data-tour={dataTour}
                disabled={disabled}
              >
                <Row flexWrap>
                  {selectedItem.length > 0
                    ? selectedItem.map(mapSelectedOptions)
                    : placeHolder}
                </Row>
                <Expander
                  {...getToggleButtonProps({
                    // prevents the menu from immediately toggling
                    // closed (due to our custom click handler above).
                    onClick(event) {
                      event.stopPropagation();
                    },
                  })}
                >
                  <components.DownChevron />
                </Expander>
              </SelectInput>
              {!disabled && isOpen && (
                <Menu {...getMenuProps({ isOpen })}>
                  {isOpen &&
                    options.map((item, index) => {
                      const isSelected = currentValueIds.includes(item.value);
                      const isActive = highlightedIndex === index;
                      return (
                        <Item
                          key={item.value}
                          item={item}
                          data-tour={item.label}
                          {...getItemProps({
                            item,
                            index,
                            isActive,
                            isSelected,
                          })}
                        >
                          <Row justifyBetween alignCenter>
                            {item.label}
                            <StyledCheckbox checked={isSelected} />
                          </Row>
                        </Item>
                      );
                    })}
                </Menu>
              )}
            </div>
          );
        }}
      </Downshift>

      {error && <Error>{error}</Error>}
    </Column>
  );
};

export const OrderableMultiSelect = memo(OrderableMultiSelectComponent);
