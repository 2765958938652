import React, { useCallback, useEffect, useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import { getIn, useField, useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import { AlternateLabel } from "components/BusinessForm/lib";
import { SlimButton, SlimSecondaryButton } from "components/Button";
import { Warning } from "components/ErrorMessage";
import { CollapseLabel } from "components/Form";
import { withNamespace, withNamespaces } from "components/Form/FormikControls";
import { EmailRecipientIconList } from "components/Icons/EmailRecipientIconList";
import { PrimaryContactIcon } from "components/Icons/PrimaryContactIcon";
import { CenteredGreedy, Row } from "components/Layout";
import Table, { CellRightAlign } from "components/Table";

import { AlternativeType } from "constants/businesses";
import { userTypes } from "constants/users";

import { pluralize } from "lib/pluralize";

import {
  getBusinessById,
  getDeploymentById,
  getSaleyards,
  selectIsUserOfType,
} from "selectors";

import AgriNousActiveLogo from "img/AgriNousActiveLogo";

import DeploymentEmailRecipientForm from "./DeploymentEmailRecipientForm";

const UserTypes = {
  EMAIL: "EMAIL",
  USER: "USER",
};

const PrimaryContactWarning = ({ email, fallbackEmail }) => {
  const fallbackEmailWarning = fallbackEmail
    ? `${fallbackEmail} will be used as the primary contact for now.`
    : "";
  const emailWarning = email
    ? `${email} is not a known email recipient.`
    : `No primary contact set. ${fallbackEmailWarning}`;
  return (
    <CenteredGreedy>
      <Warning>
        {emailWarning} Please set one of the email recipients below as the
        primary contact!
      </Warning>
    </CenteredGreedy>
  );
};

const SaleYardsRenderer = row =>
  Array.isArray(row.value) && row.value.length > 0 ? row.value.join(", ") : "-";

const ManagedByRenderer = props => {
  const sourceName = useSelector(state => {
    if (props.value && props.row.userType === UserTypes.EMAIL) {
      if (props.value.type === AlternativeType.Deployment) {
        return getDeploymentById(props.value.id)(state)?.name || "";
      } else if (props.value.type === AlternativeType.Saleyard) {
        return getSaleyards(state)[props.value.id]?.name || "";
      }
    }
    return null;
  });
  if (props.row.userType === UserTypes.USER) {
    return "AgriNous";
  }
  return sourceName;
};

const UserTypeCellRenderer = row => (
  <CenteredGreedy>
    <AgriNousActiveLogo isActive={row.value === UserTypes.USER} />
  </CenteredGreedy>
);

const ActionsCellRendererFactory =
  (
    onSetPrimaryContactEmail,
    onEditDeploymentEmailRecipientIndex,
    onRemoveDeploymentEmailRecipientIndex,
    isUserSaleyardAdmin,
    isUserLivestockAgent,
    primaryContactEmail,
  ) =>
  ({ original, row }) => {
    const { email, id: emailRecipientId, userType } = original;
    const { managedBy } = row;

    const displayEmailRecipientButtons =
      userType === UserTypes.EMAIL &&
      ((managedBy?.type === AlternativeType.Deployment &&
        isUserLivestockAgent) ||
        (managedBy?.type === AlternativeType.Saleyard && isUserSaleyardAdmin));

    return (
      <CellRightAlign>
        <SlimButton
          disabled={email === primaryContactEmail}
          type="button"
          onClick={() => onSetPrimaryContactEmail(email)}
        >
          Set Primary
        </SlimButton>{" "}
        {displayEmailRecipientButtons ? (
          <SlimButton
            type="button"
            onClick={() =>
              onEditDeploymentEmailRecipientIndex(emailRecipientId)
            }
          >
            Edit
          </SlimButton>
        ) : null}{" "}
        {displayEmailRecipientButtons ? (
          <SlimButton
            color="deleteRed"
            type="button"
            data-tour="delete"
            onClick={() =>
              onRemoveDeploymentEmailRecipientIndex(emailRecipientId)
            }
          >
            Delete
          </SlimButton>
        ) : null}
      </CellRightAlign>
    );
  };

const PrimaryContactRenderer =
  (fallbackContactEmail, hasPrimaryContact, primaryContactEmail) =>
  ({ original }) => {
    const { email } = original;
    const isPrimaryContact = email === primaryContactEmail;
    const isFallbackContact = email === fallbackContactEmail;
    if (isPrimaryContact || (isFallbackContact && !hasPrimaryContact)) {
      return (
        <CenteredGreedy>
          <PrimaryContactIcon
            color={isPrimaryContact ? "primary" : "pending"}
            title={
              isPrimaryContact ? "Primary Contact" : "Temporary Primary Contact"
            }
          />
        </CenteredGreedy>
      );
    }
    return null;
  };

const DEFAULT_SORT = [
  {
    id: "firstName",
    direction: "asc",
  },
];

export function DeploymentEmailRecipientsHeader(props) {
  const { masterBusinessId, namespace: ns, primaryContactEmail } = props;
  const [{ value }] = useField(ns);

  const numEmailRecipients = value.length;
  const numBusinessUsers = useSelector(
    state =>
      getBusinessById(masterBusinessId)(state)?.businessUsers.length || 0,
  );

  return (
    <Row>
      <SubtleBadge
        hasErrorBadge={numEmailRecipients === 0 || !primaryContactEmail}
      >
        Email Recipients
      </SubtleBadge>
      <Row alignCenter flexWrap>
        <CollapseLabel>
          {numEmailRecipients} Email{" "}
          {pluralize("Recipient", numEmailRecipients)}
        </CollapseLabel>
        <CollapseLabel>
          {numBusinessUsers} AgriNous {pluralize("User", numBusinessUsers)}
        </CollapseLabel>
        <CollapseLabel>
          Primary Contact: {primaryContactEmail || "Not Set"}
        </CollapseLabel>
      </Row>
    </Row>
  );
}

export function DeploymentEmailRecipientsTable(props) {
  const {
    masterBusinessId,
    primaryContactEmail,
    namespace: ns,
    onEndEditing,
    onStartEditing,
    onSetPrimaryContactEmail,
  } = props;
  const formikContext = useFormikContext();
  const { dirty, isValid, values, setFieldValue, setTouched } = formikContext;

  const businessUsers = useSelector(
    state => getBusinessById(masterBusinessId)(state)?.businessUsers,
  );

  const isUserSaleyardAdmin = useSelector(
    selectIsUserOfType([userTypes.SALEYARD_ADMIN]),
  );
  const isUserLivestockAgent = useSelector(
    selectIsUserOfType([userTypes.LIVESTOCK_AGENT]),
  );

  const deploymentEmailRecipients = getIn(values, withNamespace(ns, "values"));

  // to find equivalent of old server side fallback to self.email_recipients.first()
  // filter out my own type of EmailRecipients as serializer jams both types together
  const ownDeploymentEmailRecipients = deploymentEmailRecipients.filter(
    recipient =>
      (isUserLivestockAgent &&
        recipient.source.type === AlternativeType.Deployment) ||
      (isUserSaleyardAdmin &&
        recipient.source.type === AlternativeType.Saleyard),
  );
  const firstEmail = ownDeploymentEmailRecipients.length
    ? ownDeploymentEmailRecipients[0].email
    : null;

  // Adapt the data to be show in the table, including both:
  //  - the DeploymentEmailRecipients, and
  //  - the Master Businesses Registered Business Users (which are retrieved straight from the state for the most up-to-date data)
  const tableData = useMemo(
    () => [
      // Add the Deployment Email Recipients to the table data, with an additional `userType` attribute set to `UserTypes.EMAIL`
      ...(deploymentEmailRecipients || []).map(deploymentEmailRecipient => ({
        ...deploymentEmailRecipient,
        userType: UserTypes.EMAIL,
      })),
      // Add the Registered Business Users to the table data, with an additional `userType` attribute set to `UserTypes.EMAIL`
      ...(businessUsers || []).map(businessUser => ({
        ...businessUser,
        userType: UserTypes.USER,
      })),
    ],
    [businessUsers, deploymentEmailRecipients],
  );

  const emailRecipientsCount = tableData.length;
  const hasEmailRecipients = Boolean(emailRecipientsCount);
  // any emailRecipient OR businessUser can be used as the primary Contact
  const hasPrimaryContact =
    primaryContactEmail &&
    tableData.some(recipient => recipient.email === primaryContactEmail);

  // Get the current Deployment Business's DeploymentEmailRecipients from the Formik state
  const editingDeploymentEmailRecipient = getIn(
    values,
    withNamespace(ns, "editingValue"),
  );

  // Determine if the form is editing a DeploymentEmailRecipient which already exists in the Formik state.
  // Note: this may not be the Redux state. This could be dirty data from the root Formik state, see DeploymentBusinessForm,
  // or the direct parent Formik state, see DeploymentRecipientTable
  const isEditingExistingDeploymentEmailRecipient =
    editingDeploymentEmailRecipient &&
    Array.isArray(deploymentEmailRecipients) &&
    deploymentEmailRecipients.some(
      deploymentEmailRecipient =>
        deploymentEmailRecipient.id === editingDeploymentEmailRecipient.id,
    );

  const onEditDeploymentEmailRecipientIndex = useCallback(
    deploymentEmailRecipientId => {
      // Move a copy of the selected DeploymentEmailRecipient to the `editingValues` field to fill in the form
      setFieldValue(withNamespace(ns, "editingValue"), {
        ...deploymentEmailRecipients.find(
          ({ id }) => id === deploymentEmailRecipientId,
        ),
      });

      // Clear any Formik validation from being displayed
      setTouched({});
      onStartEditing();

      // This could be a long table, so scroll back to the _email_ `input`
      const inputName = withNamespaces([ns, "editingValue"], "email");
      const inputEl = document.querySelector(`input[name="${inputName}"]`);
      if (inputEl) {
        inputEl.scrollIntoView({ behavior: "smooth" });
      }
    },
    [deploymentEmailRecipients, ns, onStartEditing, setFieldValue, setTouched],
  );

  const onRemoveDeploymentEmailRecipientIndex = useCallback(
    deploymentEmailRecipientId => {
      const newState = deploymentEmailRecipients.slice();
      newState.splice(
        newState.findIndex(({ id }) => id === deploymentEmailRecipientId),
        1,
      );
      setFieldValue(withNamespace(ns, "values"), newState, false);

      onStartEditing();
    },
    [deploymentEmailRecipients, ns, onStartEditing, setFieldValue],
  );

  function onClickCommitDeploymentEmailRecipientChanges() {
    const newState = deploymentEmailRecipients.slice();
    const existingIndex = newState.findIndex(
      deploymentEmailRecipient =>
        deploymentEmailRecipient.id === editingDeploymentEmailRecipient.id,
    );
    if (existingIndex === -1) {
      // When this is a new DeploymentEmailRecipient
      newState.splice(newState.length, 1, {
        ...editingDeploymentEmailRecipient,
        source: {
          type: isUserLivestockAgent
            ? AlternativeType.Deployment
            : AlternativeType.Saleyard,
        },
        // Add a temporary (offline) id to allow the front end to track the changes to this entry.
        // Once submitted, and a response is returned, this will have and actual database primary key in here.
        id: -Date.now(),
      });

      if (emailRecipientsCount === 0 && editingDeploymentEmailRecipient.email) {
        // adding the first contact - make it primary
        onSetPrimaryContactEmail(editingDeploymentEmailRecipient.email);
      }
    } else {
      // When the user is editing an existing DeploymentEmailRecipient
      newState.splice(existingIndex, 1, editingDeploymentEmailRecipient);

      if (emailRecipientsCount === 1 && editingDeploymentEmailRecipient.email) {
        // edited the only contact - make it primary
        onSetPrimaryContactEmail(editingDeploymentEmailRecipient.email);
      }
    }

    setFieldValue(withNamespace(ns, "values"), newState, false);
    setFieldValue(withNamespace(ns, "editingValue"), null, true);

    setTouched({});
    return false;
  }

  function onClickClearDeploymentEmailRecipientChanges() {
    setFieldValue(withNamespace(ns, "editingValue"), null, true);

    setTouched({});
  }

  useEffect(() => {
    // When something in the Formik context, or any of the props have changed, and it results in the form swapping from clean to dirty, or dirty to clean,
    // including all of the DeploymentEmailRecipients and the new/edit form values, let the parent that is being edited
    dirty ? onStartEditing() : onEndEditing();
  }, [dirty, onEndEditing, onStartEditing]);

  const columns = useMemo(
    () => [
      {
        id: "userType",
        Header: "",
        accessor: "userType",
        Cell: UserTypeCellRenderer,
        width: 80,
      },
      {
        Cell: PrimaryContactRenderer(
          firstEmail,
          hasPrimaryContact,
          primaryContactEmail,
        ),
        id: "primaryContact",
        Header: "Primary",
        width: 80,
      },
      {
        id: "firstName",
        Header: "First Name",
        accessor: "firstName",
        Cell: props => (
          <AlternateLabel
            props={props}
            altFieldName="first_name"
            overrideFormikField="editingValue.firstName"
            disabledButton={!isEditingExistingDeploymentEmailRecipient}
            values={values}
          />
        ),
      },
      {
        id: "lastName",
        Header: "Last Name",
        accessor: "lastName",
        Cell: props => (
          <AlternateLabel
            props={props}
            altFieldName="last_name"
            overrideFormikField="editingValue.lastName"
            disabledButton={!isEditingExistingDeploymentEmailRecipient}
            values={values}
          />
        ),
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
        Cell: props => (
          <AlternateLabel
            props={props}
            altFieldName="email"
            overrideFormikField="editingValue.email"
            disabledButton={!isEditingExistingDeploymentEmailRecipient}
            values={values}
          />
        ),
      },
      {
        id: "phoneNumber",
        Header: "Phone Number",
        accessor: "phoneNumber",
        Cell: props => (
          <AlternateLabel
            props={props}
            altFieldName="phone_number"
            overrideFormikField="editingValue.phoneNumber"
            disabledButton={!isEditingExistingDeploymentEmailRecipient}
            values={values}
          />
        ),
      },
      {
        id: "recipientFor",
        Header: "Recipient For",
        accessor: row => {
          return <EmailRecipientIconList recipient={row} />;
        },
      },
      {
        id: "saleYards",
        Header: "Sale Yards",
        accessor: "saleYards",
        Cell: SaleYardsRenderer,
        style: { whiteSpace: "unset" },
      },
      {
        accessor: "source",
        Cell: ManagedByRenderer,
        id: "managedBy",
        Header: "Managed By",
      },
      {
        Cell: ActionsCellRendererFactory(
          onSetPrimaryContactEmail,
          onEditDeploymentEmailRecipientIndex,
          onRemoveDeploymentEmailRecipientIndex,
          isUserSaleyardAdmin,
          isUserLivestockAgent,
          primaryContactEmail,
        ),
        Header: "Actions",
        id: "actions",
        width: 250,
      },
    ],
    [
      firstEmail,
      hasPrimaryContact,
      isEditingExistingDeploymentEmailRecipient,
      isUserLivestockAgent,
      isUserSaleyardAdmin,
      onSetPrimaryContactEmail,
      onEditDeploymentEmailRecipientIndex,
      onRemoveDeploymentEmailRecipientIndex,
      primaryContactEmail,
      values,
    ],
  );

  const isClearEnabled = editingDeploymentEmailRecipient !== null;
  const isAddUpdateEnabled =
    editingDeploymentEmailRecipient !== null && dirty && isValid;

  const isRevertEnabled = dirty;
  const isSubmitEnabled =
    dirty && isValid && editingDeploymentEmailRecipient === null;

  return (
    <>
      <Grid container spacing={2} alignItems="flex-end">
        <DeploymentEmailRecipientForm
          namespace={withNamespace(ns, "editingValue")}
        />
        <Grid item xs={7} md={4}>
          <SlimSecondaryButton
            disabled={!isClearEnabled}
            onClick={onClickClearDeploymentEmailRecipientChanges}
            type="button"
          >
            Clear
          </SlimSecondaryButton>
          <SlimButton
            disabled={!isAddUpdateEnabled}
            onClick={onClickCommitDeploymentEmailRecipientChanges}
            type="button"
            data-tour="emailAdd"
          >
            {isEditingExistingDeploymentEmailRecipient ? "Update" : "Add"} Email
            Recipient
          </SlimButton>
        </Grid>
      </Grid>
      {hasEmailRecipients && !hasPrimaryContact ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PrimaryContactWarning
              email={primaryContactEmail}
              fallbackEmail={firstEmail}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={tableData}
            defaultSorted={DEFAULT_SORT}
            noResultsMessage="This Business does not have any Email Recipients yet."
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid container item xs={12}>
          <SlimSecondaryButton type="reset" disabled={!isRevertEnabled}>
            Revert Changes
          </SlimSecondaryButton>
          <SlimButton
            type="submit"
            disabled={!isSubmitEnabled}
            data-tour="emailSubmit"
          >
            Accept Changes
          </SlimButton>
        </Grid>
      </Grid>
    </>
  );
}
