import React, { memo, useCallback, useMemo, useState } from "react";

import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { addScansFromManualInput, ScanAction } from "actions";

import { Label } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { AddDetails } from "components/NlisAddDetails";
import ConsignmentDetailHeader from "components/ScanningScreen/ConsignmentDetailHeader";
import SaleLotDetailHeader from "components/ScanningScreen/SaleLotDetailHeader";
import ScanLotScanningHeader from "components/ScanningScreen/ScanLotScanningHeader";
import {
  buildDuplicatePenScanEidsList,
  buildDuplicateTakeableEidList,
} from "components/ScanningScreen/ScanningColumn";
import { StatusText } from "components/Text";

import { EIDPattern } from "constants/scanner";

import {
  getLivestockSaleId,
  getReturnTo,
  openAnimalModal,
} from "lib/navigation";
import { parseNlisId } from "lib/nlisId";
import { scanLotConfig } from "lib/scanLot";

import {
  getConsignments,
  getHasEnteredNlisCreds,
  getIsOnline,
  getIsScaleOperator,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScans,
  selectScanByNlisIdLookup,
  selectTakeFilesByConsignmentIdLookup,
} from "selectors";

import { useBoolean } from "hooks";
import { useGetReceivalScannedDeploymentIds } from "hooks/useGetReceivalScannedDeploymentIds";

import ManualInputColumn, {
  ManualInputType,
  transformManualInputToScan,
} from "./ManualInputColumn";

const ACCEPTED_CHARS_PATTERN = /([\dA-Za-z ]{16})/gm;

/**
 * Accepts multiline string and extracts all of the EIDs and NLIS Ids, also return any leftover text from the string
 * @param {string} text a multiline string
 * @returns {{parsedInput: Array<{ type: number, nlisId?: string, eid?: string}>, remainingText: string}}
 */
function parseManualInput(text, allowNlisIds) {
  const parsedInputs = [];

  let remainingText = "";

  ACCEPTED_CHARS_PATTERN.lastIndex = 0;

  let match = ACCEPTED_CHARS_PATTERN.exec(text);
  let end = 0;

  while (match) {
    const value = match[1];
    const sanitisedValue = value.toUpperCase().trim();
    const rejectedValue = text.substring(
      match.index,
      ACCEPTED_CHARS_PATTERN.lastIndex,
    );

    remainingText += text.substring(end, match.index).trimLeft();

    if (sanitisedValue.length === 16) {
      if (EIDPattern.SIXTEEN_DIGIT_PATTERN.test(sanitisedValue)) {
        parsedInputs.push({ eid: sanitisedValue, type: ManualInputType.EID });
      } else if (allowNlisIds && parseNlisId(sanitisedValue)) {
        parsedInputs.push({
          nlisId: sanitisedValue,
          type: ManualInputType.NLIS_ID,
        });
      } else {
        remainingText += rejectedValue;
      }
    } else {
      remainingText += rejectedValue;
    }

    end = match.index + value.length;
    match = ACCEPTED_CHARS_PATTERN.exec(text);
  }

  remainingText += text.substring(end);
  return { parsedInputs, remainingText: remainingText.trimLeft() };
}

function resolveNlisIdsToEids(manualInputs, scanByNlisId) {
  return manualInputs.map(manualInput => {
    const result = { ...manualInput, source: manualInput };
    if (result.type === ManualInputType.NLIS_ID) {
      const { nlisId } = result;
      const scan = scanByNlisId[nlisId];
      if (scan) {
        result.eid = scan.EID;
      }
    }
    return result;
  });
}

export function ManualInputComponent(props) {
  const {
    consignmentId,
    onCancel,
    onOpenClearModal,
    onOpenClearSavedModal,
    onOpenCreateBulkModal,
    onOpenCreateModal,
    onOpenSelectModal,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    scanListType,
  } = props;
  const match = useRouteMatch();

  const dispatch = useDispatch();

  const [
    isScanDiscrepancyDialogVisible,
    showScanDiscrepancyDialog,
    hideScanDiscrepancyDialog,
  ] = useBoolean(false);

  const { getEidsByLotId, scanReference, selectLotIdByEidLookup } =
    scanLotConfig(penType);

  const scanLotIdByEid = useSelector(selectLotIdByEidLookup);

  const scanLotEids = useSelector(getEidsByLotId(scanLotId)).filter(Boolean);

  const [textValue, setTextValue] = useState("");
  const [manualInputs, setManualInputs] = useState([]);
  const isOnline = useSelector(getIsOnline);
  const hasEnteredNlisCreds = useSelector(getHasEnteredNlisCreds);

  const consignments = useSelector(getConsignments);
  const saleLotScans = useSelector(getScans);
  const saleLots = useSelector(getSaleLots);
  const saleyardScanSaleLots = useSelector(getSaleyardScanSaleLots);
  const takeFiles = useSelector(selectTakeFilesByConsignmentIdLookup);

  const isPenOrReceivalScanning = !!penArchetypeId || !!penId || !!scanLotId;

  const isScaleOperator = useSelector(getIsScaleOperator);
  const disallowTaken = isScaleOperator;

  const scanByNlisIdLookup = useSelector(selectScanByNlisIdLookup);

  // Resolve all known NLIS Ids to their EID counterpart - used for determining if an EID is duplicate
  const resolvedManualInputs = useMemo(
    () => resolveNlisIdsToEids(manualInputs, scanByNlisIdLookup),
    [manualInputs, scanByNlisIdLookup],
  );
  const resolvedUniqueManualInputs = useMemo(
    () =>
      // Now that we have resolved as many NLIS Ids to EIDs as we can, and EID may have been provided which is the same as a resolved NLIS ID, deduplicate them
      resolvedManualInputs.filter((manualInput, index, array) =>
        manualInput.eid
          ? // when we have an EID, or have been able to resolve the EID from an NLIS Id, make sure to filter out duplicate EIDS,
            array.findIndex(b => b.eid === manualInput.eid) === index
          : // if there is no EID, assume there is an NLIS Id, and the we can't determine if it's duplicate or not
            array.findIndex(b => b.nlisId === manualInput.nlisId) === index,
      ),
    [resolvedManualInputs],
  );

  const { eids, keepableEids, duplicateEids, hiddenEids } = useMemo(() => {
    // Because buildDuplicateTakeableEidList expects a list of EIDs, collapse both NLIS Id and EID onto the same property name (`eidOrNlisId`).
    // Any NLIS Ids which can't be map back to an EID must be treated as new/unseeen EIDs anyway.
    // The ScanningColumn Component is able to determine if it's an NLIS Id, or and EID and display it appropriately.
    const idsForDuplicateTakeable = resolvedUniqueManualInputs.map(
      ({ eid, nlisId, keep }) => ({
        eidOrNlisId: eid || nlisId,
        keep,
      }),
    );

    let values = {};
    if (isPenOrReceivalScanning) {
      values = buildDuplicatePenScanEidsList(
        idsForDuplicateTakeable,
        saleLotScans,
        "eidOrNlisId",
        "keep",
        penType,
        scanLotId,
        scanLotIdByEid,
      );
    } else {
      values = buildDuplicateTakeableEidList(
        idsForDuplicateTakeable,
        saleLotScans,
        saleLots,
        saleyardScanSaleLots,
        consignments,
        takeFiles,
        "eidOrNlisId",
        "keep",
        disallowTaken,
        consignmentId,
      );
    }
    dispatch(addScansFromManualInput(values.eids));
    return values;
  }, [
    resolvedUniqueManualInputs,
    saleLotScans,
    saleLots,
    saleyardScanSaleLots,
    consignments,
    takeFiles,
    disallowTaken,
    consignmentId,
    isPenOrReceivalScanning,
    penType,
    scanLotId,
    scanLotIdByEid,
    dispatch,
  ]);

  const clearHidden = useCallback(() => {
    // remove hidden eids that are already present on this scan lot
    const newManualInputs = manualInputs.filter(
      manualInput => !hiddenEids.includes(manualInput.eid),
    );

    setManualInputs(newManualInputs);
  }, [manualInputs, hiddenEids, setManualInputs]);

  const actionableUniqueInputs = resolvedUniqueManualInputs.filter(
    ({ eid, nlisId }) => eids.indexOf(eid) > -1 || eids.indexOf(nlisId) > -1,
  );
  const scans = actionableUniqueInputs.map(transformManualInputToScan);

  const receivalScannedDeploymentIds = useGetReceivalScannedDeploymentIds(
    scanLotEids.concat(scans.map(scan => scan.EID)),
  );

  const canEnterNlisIds = isOnline && hasEnteredNlisCreds;

  function onChangeTextArea(event) {
    const { value } = event.target;

    const { parsedInputs, remainingText } = parseManualInput(
      value,
      canEnterNlisIds,
    );

    if (parsedInputs.length > 0) {
      setManualInputs([].concat(manualInputs, parsedInputs));
    }

    if (remainingText !== textValue) {
      setTextValue(remainingText);
    }
  }

  const onAfterActioned = useCallback(
    actionedManualInputs => {
      const newManualInputs = manualInputs.slice();
      actionedManualInputs.forEach(actionedManualInput => {
        const index = newManualInputs.indexOf(actionedManualInput);
        newManualInputs.splice(index, 1);
      });
      setManualInputs(newManualInputs);
    },
    [manualInputs, setManualInputs],
  );

  const onEdit = useCallback(
    eid => {
      openAnimalModal(match.url, eid, getReturnTo());
    },
    [match.url],
  );
  const onKeep = useCallback(
    keptManualInputs => {
      const newManualInputs = manualInputs.slice();
      keptManualInputs.forEach(keptManualInput => {
        newManualInputs.find(
          manualInput => manualInput.eid === keptManualInput.eid,
        ).keep = true;
      });
      setManualInputs(newManualInputs);
    },
    [manualInputs, setManualInputs],
  );

  const onClear = useCallback(
    doNotShowMessage => {
      // clear hidden duplicate eids
      clearHidden();
      if (doNotShowMessage) {
        setManualInputs([]);
      } else {
        typeof onOpenClearModal === "function" &&
          onOpenClearModal({
            draftName: "Manual EID/NLIS Id input",
            onDelete: () => {
              setManualInputs([]);
            },
          });
      }
    },
    [onOpenClearModal, setManualInputs, clearHidden],
  );

  const onClearDuplicates = () => {
    setManualInputs(
      manualInputs.filter(
        manualInput => !duplicateEids.includes(manualInput.eid),
      ),
    );
  };

  const onClearSaved = useCallback(() => {
    // clear hidden duplicate eids
    clearHidden();
    // clear saved eids
    typeof onOpenClearSavedModal === "function" &&
      onOpenClearSavedModal({
        penType,
        onDelete: () => {
          dispatch(
            ScanAction.create(
              scanLotEids.map(eid => ({
                EID: eid,
                [scanReference]: null,
                livestock_sale_id: getLivestockSaleId(),
              })),
            ),
          );
        },
      });
  }, [
    onOpenClearSavedModal,
    dispatch,
    penType,
    scanLotEids,
    scanReference,
    clearHidden,
  ]);

  return (
    <>
      <ConsignmentDetailHeader consignmentId={consignmentId} />
      <SaleLotDetailHeader saleLotId={saleLotId} />
      <ScanLotScanningHeader
        penArchetypeId={penArchetypeId}
        penId={penId}
        scanLotId={scanLotId}
        penType={penType}
        duplicateEids={duplicateEids}
        showScanDiscrepancyDialog={showScanDiscrepancyDialog}
        receivalScannedDeploymentIds={receivalScannedDeploymentIds}
      />
      <Column padding={2}>
        <Label htmlFor="eidTextArea">
          Type or paste EIDs {canEnterNlisIds && "and NLIS Ids "}below.
        </Label>
        <textarea
          name="eidTextArea"
          data-tour="eidTextArea"
          onChange={onChangeTextArea}
          rows={3}
          value={textValue}
        />
      </Column>
      {!hasEnteredNlisCreds && (
        <Column padding={2}>
          <Row justifyBetween>
            <StatusText status="warning">
              NLIS Credentials must be provided to lookup NLIS Ids
            </StatusText>
            <AddDetails isVisible />
          </Row>
        </Column>
      )}
      {!isOnline && (
        <Column padding={2}>
          <Row justifyBetween>
            <StatusText status="warning">
              You must be online to lookup NLIS Ids
            </StatusText>
          </Row>
        </Column>
      )}
      <ManualInputColumn
        consignmentId={consignmentId}
        saleLotId={saleLotId}
        penArchetypeId={penArchetypeId}
        penId={penId}
        scanLotId={scanLotId}
        penType={penType}
        manualInputsList={manualInputs}
        onAfterActioned={onAfterActioned}
        onCancel={onCancel}
        onClear={onClear}
        onClearSaved={onClearSaved}
        onClearDuplicates={onClearDuplicates}
        onEdit={onEdit}
        onIgnore={onAfterActioned}
        onKeep={onKeep}
        clearHidden={clearHidden}
        onCreateNew={onOpenCreateModal}
        onCreateNewBulk={onOpenCreateBulkModal}
        onSelectExisting={onOpenSelectModal}
        scanListType={scanListType}
        showScanDiscrepancyDialog={showScanDiscrepancyDialog}
        eids={eids}
        keepableEids={keepableEids}
        duplicateEids={duplicateEids}
        isScanDiscrepancyDialogVisible={isScanDiscrepancyDialogVisible}
        hideScanDiscrepancyDialog={hideScanDiscrepancyDialog}
        scans={scans}
        resolvedManualInputs={resolvedManualInputs}
        resolvedUniqueManualInputs={resolvedUniqueManualInputs}
      />
    </>
  );
}
ManualInputComponent.propTypes = {
  consignmentId: PropTypes.string,
  onOpenClearModal: PropTypes.func,
  onOpenCreateModal: PropTypes.func,
  onOpenSelectModal: PropTypes.func,
  saleLotId: PropTypes.string,
};

export default memo(ManualInputComponent);
