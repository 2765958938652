import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { getAutoDraftPenStatusColour } from "lib/singleWeigh";

import {
  getCurrentSingleWeigh,
  getAutoDraftPensBySingleWeighIdLookup,
  getStatusByAutoDraftPenId,
} from "selectors";

import { SingleWeighTableConfiguration } from "./Columns";

function DefaultCell({ children, className }) {
  return <td className={className}>{children}</td>;
}

function SingleWeighTableBodyRow(props) {
  const { autoDraftPenId, config } = props;

  const status = useSelector(getStatusByAutoDraftPenId(autoDraftPenId));

  const borderColor = getAutoDraftPenStatusColour(status);

  return (
    <SingleWeighTableBodyTr
      data-tour="draftPen"
      borderColor={borderColor}
      className={config.rowClasses}
    >
      {config.columns.map(({ Cell = DefaultCell, Content, name }) => (
        <Cell autoDraftPenId={autoDraftPenId} className={name} key={name}>
          <Content autoDraftPenId={autoDraftPenId} />
        </Cell>
      ))}
    </SingleWeighTableBodyTr>
  );
}

function SingleWeighTableHeadingRow(props) {
  const { config } = props;
  return (
    <tr className={config.rowClasses}>
      {config.columns.map(({ name, span, title }) => (
        <th key={name} colSpan={span} className={name}>
          {title}
        </th>
      ))}
    </tr>
  );
}

const SingleWeighTableBodyTr = styled.tr(
  ({ theme, borderColor }) => `border-color: ${theme.colors[borderColor]};`,
);

const AutoDraftTable = styled.table(
  ({ theme }) => `
  border-spacing: 0px 4px; 
  height: 100%;
  table-layout: fixed;
  width: 100%;

  table {
    border-spacing: ${theme.space[0]}px ${theme.space[1]}px;
  }
  tr.data-row {
    box-shadow: ${theme.shadows[1]};
    background-color: ${theme.colors.white};
  }

  tr.data-row td {
    border-bottom: 4px solid;
    border-top: 4px solid;
    border-bottom-color: inherit;
    border-top-color: inherit;
    padding: ${theme.space[0]}px ${theme.space[2]}px;
    max-height: 150px;
  }

  tr.data-row > :first-child {
    border-left: 4px solid;
    border-left-color: inherit;
  }

  tr.data-row > :last-child {
    border-right: 4px solid;
    border-right-color: inherit;
  }
  
  tr.group-heading-row th,
  tr.heading-row th {
    border-right: 1px solid ${theme.colors.surfaceBorder};
    padding: ${theme.space[0]}px ${theme.space[1]}px;
  }

  tr.group-heading-row > :first-child,
  tr.heading-row > :first-child {
    border-left: 1px solid ${theme.colors.surfaceBorder};
  }

  td.draft-pen-name,
  td.weighed-hd,
  td.dest-pen,
  td.buyer,
  td.draft-pen-hd {
    font-size: x-large;
    text-align: center;
  }

  td.buyer {
    line-height: 1em;
  }

  td.actions {
    text-align: center;
  }

  tr.post-sale.heading-row {
    th.draft-pen-name {
      width: 8%;
    }
    th.weighed-hd,
    th.dest-pen {
      width: 12%;
    }
    th.buyer {
      width: 23%;
    }
    th.actions {
      width: 9%;
    }
    th.entry,
    th.draft-pen-hd,
    th.exit {
      width: 12%;
    }
  }
  tr.pre-sale.heading-row {
    th.draft-pen-name {
      width: 13%;
    }
    th.weighed-hd{
      width: 17%;
    }
    th.dest-pen{
      width: 25%;
    }
    th.actions{
      width: 9%;
    }
    th.entry,
    th.draft-pen-hd,
    th.exit {
      width: 12%;
    }
  }
`,
);

function AutoDraftPensList() {
  const singleWeigh = useSelector(state => getCurrentSingleWeigh(state));
  const pens = useSelector(
    getAutoDraftPensBySingleWeighIdLookup(singleWeigh.id),
  );

  const penIds = pens.map(pen => pen.id);

  const columnConfig = SingleWeighTableConfiguration[singleWeigh.mode];

  return (
    <AutoDraftTable>
      <thead>
        <SingleWeighTableHeadingRow config={columnConfig.headings} />
      </thead>
      <tbody>
        {penIds.map(autoDraftPenId => (
          <SingleWeighTableBodyRow
            autoDraftPenId={autoDraftPenId}
            config={columnConfig.body}
            key={autoDraftPenId}
          />
        ))}
      </tbody>
    </AutoDraftTable>
  );
}

export default AutoDraftPensList;
