import { createContext, useCallback, useContext } from "react";

import { sortBy, uniq, uniqBy } from "lodash";
import { useSelector } from "react-redux";

import { SundryTemplateLabel } from "constants/sundryTemplates";

import {
  getAges,
  getCategories,
  getLabels,
  getMasterLabels,
  getRounds,
  getSaleSubTypes,
  getSexes,
  getSpecies,
} from "selectors";

import { LivestockSaleFieldSchema } from "./livestockSaleSchema";

const RuleFieldSchemaContext = createContext([]);
export const { Provider: RuleFieldSchema } = RuleFieldSchemaContext;

export function useRuleFieldSchema() {
  return useContext(RuleFieldSchemaContext);
}

export const PRICING_TYPES = [
  "Price per Gross",
  "Price per Kilo",
  "Price per Head",
];

export function useRuleFieldSchemaGenerator() {
  const ages = useSelector(getAges);
  const categories = useSelector(getCategories);
  const rounds = useSelector(getRounds);
  const sexes = useSelector(getSexes);
  const species = useSelector(getSpecies);
  const subTypes = useSelector(getSaleSubTypes);

  const masterLabels = useSelector(getMasterLabels);
  const deploymentLabels = useSelector(getLabels);

  return useCallback(
    isMasterRule => {
      LivestockSaleFieldSchema.find(
        field => field.id === "age",
      ).properties.find(
        property => typeof property === "object" && property.name === "name",
      ).set = Object.values(ages)
        .sort((a, b) => a.order - b.order)
        .map(age => [age.name, age.name]);

      LivestockSaleFieldSchema.find(
        field => field.id === "round",
      ).properties.find(
        property => typeof property === "object" && property.name === "name",
      ).set = Object.values(rounds)
        .sort((a, b) => a.order - b.order)
        .map(round => [round.name, round.name]);

      LivestockSaleFieldSchema.find(
        field => field.id === "sex",
      ).properties.find(
        property => typeof property === "object" && property.name === "name",
      ).set = Object.values(sexes)
        .sort((a, b) => a.order - b.order)
        .map(sex => [sex.name, sex.name]);

      LivestockSaleFieldSchema.find(
        field => field.id === "sale",
      ).properties.find(
        property =>
          typeof property === "object" && property.name === "sub_type",
      ).set = uniqBy(Object.values(subTypes), "name")
        .sort((a, b) => a.order - b.order)
        .map(subType => {
          const speciesLabelText = Object.values(subTypes)
            .filter(sub => sub.name === subType.name)
            .map(sub => species[sub.speciesId]?.name)
            .sort()
            .join("/");
          return [`${speciesLabelText} - ${subType.name}`, subType.name];
        });

      LivestockSaleFieldSchema.find(
        field => field.id === "species",
      ).properties.find(
        property => typeof property === "object" && property.name === "name",
      ).set = Object.values(species)
        .sort((a, b) => a.order - b.order)
        .map(species => [species.name, species.name]);

      LivestockSaleFieldSchema.find(
        field => field.id === "lot",
      ).properties.find(
        property =>
          typeof property === "object" &&
          property.name === "commercial_category",
      ).set = Object.values(categories)
        .sort((a, b) => a.order - b.order)
        .map(category => [category.name, category.name]);

      LivestockSaleFieldSchema.find(
        field => field.id === "lot",
      ).properties.find(
        property =>
          typeof property === "object" && property.name === "pricing_type",
      ).set = PRICING_TYPES.map(pricingType => [pricingType, pricingType]);

      // Add the static list of label values to both the lot and the manual adjustments.
      // On a master rule, this is selectable from the MASTER LABEL list.
      // On a custom rule, this is selectable from the DEPLOYMENT LABEL list.
      // Both are stored as string values, as compared to id driven relationships.

      const labelOptions = uniq(
        Object.values(isMasterRule ? masterLabels : deploymentLabels).map(
          label => label.name,
        ),
      ).map(name => [name, name]);
      LivestockSaleFieldSchema.find(
        field => field.id === "lot",
      ).properties.find(
        property => typeof property === "object" && property.name === "labels",
      ).set = labelOptions;

      // For a manual adjustment/sundry also include the sundry template labels for master rules.
      const manualAdjustmentLabelOptions = [
        ...labelOptions,
        ...(isMasterRule ? Object.keys(SundryTemplateLabel) : []).map(name => [
          name,
          name,
        ]),
      ];

      LivestockSaleFieldSchema.find(
        field => field.id === "manual_adjustment",
      ).properties.find(
        property => typeof property === "object" && property.name === "labels",
      ).set = manualAdjustmentLabelOptions;

      return sortBy(LivestockSaleFieldSchema, "id");
    },
    [
      ages,
      categories,
      rounds,
      sexes,
      species,
      subTypes,
      masterLabels,
      deploymentLabels,
    ],
  );
}
