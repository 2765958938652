import React, { useMemo } from "react";

import AgGrid from "components/AgGrid/AgGrid";

import { Column } from "constants/columns";
import { NLISFileTypes } from "constants/nlis";

import { withNestedField } from "lib/agGrid";
import { BaseColumnDefinitions } from "lib/agGrid/columnDefinitions";

const defaultColDef = {
  sortable: true,
  flex: true,
  resizable: true,
  enableCellChangeFlash: true,
};

const getColumnDefs = nlisFileType => {
  return [
    {
      headerName: "EID",
      field: "scan.EID",
      minWidth: 90,
    },
    {
      headerName: "AgriNous",
      marryChildren: true,
      children: [
        BaseColumnDefinitions[Column.NVD],
        withNestedField(Column.CURRENT_PIC, "scan"),
        BaseColumnDefinitions[Column.VENDOR_PIC],
        withNestedField(Column.DESTINATION_PIC, "saleLot"),
      ],
    },
    nlisFileType === NLISFileTypes.NLISTakeFile
      ? {
          headerName: "NLIS Take",
          marryChildren: true,
          children: [
            BaseColumnDefinitions[Column.NLIS_TAKE_TRANSFER_VENDOR_PIC],
            BaseColumnDefinitions[Column.NLIS_TAKE_TRANSFER_NVD],
          ],
        }
      : null,
    nlisFileType === NLISFileTypes.NLISSellFile
      ? {
          headerName: "NLIS Sell",
          marryChildren: true,
          children: [
            BaseColumnDefinitions[Column.NLIS_SELL_TRANSFER_BUYER_PIC],
          ],
        }
      : null,
  ].filter(Boolean);
};

export const NLISCorrectionGrid = ({ rowData, nlisFileType }) => {
  const columnDefs = useMemo(() => getColumnDefs(nlisFileType), [nlisFileType]);

  return (
    <AgGrid
      rowData={rowData}
      columnDefs={columnDefs}
      pagination
      paginationPageSize={10}
      defaultColDef={defaultColDef}
      enableCellTextSelection
    />
  );
};
