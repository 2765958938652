import queryString from "query-string";

import { VENDOR_COMMISSION_BAND } from "constants/actionTypes";

import { serializeVendorCommissionBand } from "lib/serializers/vendorCommissionBands";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/vendor-commission-bands/", id, action),
    query: {
      changesSince,
    },
  });

export const VendorCommissionBandAction = offlineActionCreator(
  VENDOR_COMMISSION_BAND,
  serializeVendorCommissionBand,
  urlCreator,
  false,
);
