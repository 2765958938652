import React, { memo, useCallback } from "react";

import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Column } from "components/Layout";
import TabRow from "components/TabSelector/TabRow";

import { DeploymentPermissions } from "constants/permissions";
import { filterTabsBySaleType } from "constants/sale";
import { ScreenTypes } from "constants/screen";
import { userTypes } from "constants/users";

import { SecurityTrimmedNavigationTab } from "containers/LivestockSales/SecurityTrimmedNavigationTab";

import { isUserOfType } from "lib/auth";
import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
  openSaleModal,
} from "lib/navigation";
import { someHasPermission } from "lib/permissions";

import {
  getAuth,
  getCurrentSale,
  selectRoleCurrentDeployments,
} from "selectors";

const getMobileTabs = auth => [
  {
    id: "edit-sale",
    title: <FontAwesomeIcon icon={faPencil} />,
    display: true,
    onClick: () => openSaleModal(getLivestockSaleId()),
  },
  {
    id: "billing",
    title: "Billing",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(selectRoleCurrentDeployments(state), [
        DeploymentPermissions.featureBilling,
        DeploymentPermissions.featureManualCharges,
      ]),
  },
];

const getDesktopTabs = auth => [
  {
    id: "edit-sale",
    title: <FontAwesomeIcon icon={faPencil} />,
    display: true,
    onClick: () => openSaleModal(getLivestockSaleId()),
  },
  {
    id: "billing",
    title: "Billing",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(selectRoleCurrentDeployments(state), [
        DeploymentPermissions.featureBilling,
        DeploymentPermissions.featureManualCharges,
      ]),
  },
];

function SundrySaleTabsComponent() {
  const auth = useSelector(getAuth);
  const sale = useSelector(getCurrentSale);

  const screenType = useSelector(state => state.screen.type);

  const { params } = useRouteMatch();
  const history = useHistory();

  const setSection = useCallback(
    section => {
      history.push(
        `${getSaleyardAuctionRoute(
          getSaleyardName(),
          getLivestockSaleId(),
        )}/${section}`,
      );
    },
    [history],
  );
  const { section } = params;
  const { sale_type: saleType } = sale;

  const isDesktop = screenType === ScreenTypes.DESKTOP;

  const [tabsByUser, showTabs] = React.useMemo(() => {
    const tabsByUser = isDesktop
      ? getDesktopTabs(auth).filter(
          filterTabsBySaleType(saleType, ScreenTypes.DESKTOP),
        )
      : getMobileTabs(auth).filter(
          filterTabsBySaleType(saleType, ScreenTypes.MOBILE),
        );

    const showTabs = tabsByUser.filter(t => t.display).length > 0;
    return [tabsByUser, showTabs];
  }, [isDesktop, saleType, auth]);

  return (
    <>
      {showTabs && (
        <Column printHidden>
          <TabRow
            tabs={tabsByUser}
            selectedTab={section}
            setSelectedTab={setSection}
            backgroundColor="gray95"
          />
        </Column>
      )}
    </>
  );
}

export default memo(SundrySaleTabsComponent);
