import reduceReducers from "reduce-reducers";

import { MASTER_LEDGER_ACCOUNT } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
} from "lib/reducers";
import { deserializeMasterLedgerAccount } from "lib/serializers/masterLedgerAccounts";

const create = apiModelOfflineCreateReducer(MASTER_LEDGER_ACCOUNT, {
  deserializer: deserializeMasterLedgerAccount,
});

const fetch = apiModelOfflineFetchReducer(MASTER_LEDGER_ACCOUNT, {
  deserializer: deserializeMasterLedgerAccount,
});

const update = apiModelOfflineUpdateReducer(MASTER_LEDGER_ACCOUNT, {
  deserializer: deserializeMasterLedgerAccount,
});

const deleteReducer = apiModelOfflineDeleteReducer(MASTER_LEDGER_ACCOUNT);

const masterLedgerAccountReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
);

export default masterLedgerAccountReducer;
