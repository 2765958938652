import { ADD_TEMP_BUYER_WAY } from "constants/actionTypes";

export function addTempBuyerWay(tempBuyerWayId, buyerId, buyerWayName) {
  const buyerWay = {
    name: buyerWayName,
    id: tempBuyerWayId,
  };
  return {
    type: ADD_TEMP_BUYER_WAY,
    buyerId,
    buyerWay,
  };
}
