import React from "react";

import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import { updateDeploymentAttributes, deleteDeploymentAttribute } from "actions";

import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";

import {
  UPDATE_DEPLOYMENT_MARKS,
  DELETE_DEPLOYMENT_MARKS,
} from "constants/actionTypes";
import { ACTION_COLUMN_NAME } from "constants/aggrid";

import ExtendableTable from "containers/Settings/ExtendableTable";
import { HeaderBulkSelectCellRenderer } from "containers/Settings/Renderers";

import { getActiveDeploymentAttributes } from "selectors";

import { ActionsColumn } from "./Actions";

const columnDefs = [
  {
    headerName: "Order",
    field: "order",
    editable: false,
    rowDrag: true,
  },
  {
    headerName: "Short Code",
    field: "short_code",
  },
  { headerName: "Description", field: "description" },
  {
    headerName: "Use",
    field: "is_used",
    cellRenderer: "checkboxRenderer",
    editable: false,
    headerComponentFramework: HeaderBulkSelectCellRenderer,
  },
  {
    headerName: "Quick Select",
    field: "quick_select",
    cellRenderer: "checkboxRenderer",
    editable: false,
    headerComponentFramework: HeaderBulkSelectCellRenderer,
  },
  {
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: ActionsColumn,
    editable: false,
  },
];

const validationSchema = Yup.object().shape({
  description: Yup.string().required(),
  short_code: Yup.string().max(3, "Must be 3 or less characters"),
});

const blankRowDefaults = Object.freeze({
  name: "",
  quick_select: true,
  is_used: true,
});

export const MarkSettings = () => {
  const deploymentData = useSelector(getActiveDeploymentAttributes).marks;

  const dispatch = useDispatch();

  const handleUpdate = payload => {
    const marksPayload = payload.map(p => ({
      id: p.id,
      order: p.order,
      deployment_id: p.deployment_id,
      species_id: p.species_id,
      is_used: p.is_used || false,
      quick_select: p.quick_select,
      short_code: p.short_code,
      description: p.description,
    }));

    dispatch(updateDeploymentAttributes(UPDATE_DEPLOYMENT_MARKS, marksPayload));
  };

  const deleteMark = id =>
    dispatch(deleteDeploymentAttribute(DELETE_DEPLOYMENT_MARKS, id));
  const [deleteId, setDeleteId] = React.useState(null);
  const openDeleteDialog = productId => setDeleteId(productId);
  const closeDeleteDialog = () => setDeleteId(null);

  const onDelete = () => {
    deleteMark(deleteId);
    closeDeleteDialog();
  };
  return (
    <>
      <ExtendableTable
        deploymentData={deploymentData}
        handleUpdate={handleUpdate}
        columnDefs={columnDefs}
        validationSchema={validationSchema}
        blankRowDefaults={blankRowDefaults}
        context={{ openDeleteDialog }}
      />
      <ConfirmDialog
        title={createModalTitle("this mark")}
        isOpen={!!deleteId}
        onCancel={closeDeleteDialog}
        onDelete={onDelete}
      />
    </>
  );
};
