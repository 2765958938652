import React from "react";

import { ConsignToSaleModal } from "components/ConsignToSaleModal/ConsignToSaleModal";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

const ConsignToSaleModalAdapter = props => {
  const { hash } = props;
  const { returnTo } = hash;

  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  return <ConsignToSaleModal closeSelf={closeSelf} />;
};

export default ConsignToSaleModalAdapter;
