import React from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Row, PaddedIcon } from "components/Layout";

const Wrapper = styled(Row)`
  margin: 12px;
  ${({ disabled }) => disabled && "opacity: 0.33; cursor: default;"};
`;

const BuyerCard = styled(Row)`
  box-shadow: ${({ theme }) => theme.shadows[2]};
  background-color: #fff;
  flex-grow: 1;
  align-items: center;
  margin: 0;
`;

const Label = styled.div`
  color: #777;
  font-weight: 300;
  font-size: 24px;
  padding: 36px 24px;
  text-transform: uppercase;
`;

const Value = styled.div`
  font-weight: 700;
  font-size: ${props => (props.large ? 48 : 24)}px;
  padding: ${({ isNoSale }) => (isNoSale ? "42px 36px" : "0 36px")};
  color: ${({ theme, isNoSale }) =>
    isNoSale ? theme.colors.error : "#4a4a4a"};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UnsetContainer = styled(Row)`
  justify-content: flex-end;
  align-items: center;
  min-width: 100px;
  padding: 24px;
  font-size: 36px;
`;

const BuyerDetail = ({ way, value, isNoSale, unsetDetail, disabled }) => (
  <Wrapper justifyBetween disabled={disabled}>
    {isNoSale ? (
      <BuyerCard justifyCenter>
        <Value isNoSale>NO SALE</Value>
      </BuyerCard>
    ) : (
      <BuyerCard justifyBetween>
        <Label>{way ? "Way" : "Buyer"}</Label>
        <Value large={value?.length < 4} title={value}>
          {value}
        </Value>
      </BuyerCard>
    )}
    <UnsetContainer>
      <PaddedIcon
        style={{ cursor: !disabled && "pointer" }}
        onClick={e => !disabled && unsetDetail && unsetDetail(e)}
      >
        <FontAwesomeIcon
          style={{ fontSize: 32 }}
          icon={faTimes}
          data-tour={way ? "removeBuyerWay" : "removeBuyer"}
        />
      </PaddedIcon>
    </UnsetContainer>
  </Wrapper>
);

BuyerDetail.propTypes = {
  way: PropTypes.bool,
  value: PropTypes.node,
  isNoSale: PropTypes.bool,
  unsetDetail: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BuyerDetail;
