export const WeighLotStatus = {
  RESOLVED: "RESOLVED",
  UNRESOLVED: "UNRESOLVED",
};

export const WeighLotScanStatus = {
  REWEIGH: "REWEIGH",
  DEDUPLICATED: "DEDUPLICATED",
  IGNORED: "IGNORED",
  REWEIGHED: "REWEIGHED",
  MAPPED: "MAPPED",
  MANUALLY_MAPPED: "MANUALLY_MAPPED",
  UNRESOLVED: "UNRESOLVED",
  MAPPED_TO_SALE_LOT: "MAPPED_TO_SALE_LOT",
  NEW_EID: "NEW_EID",
};

export const WeighLotScanStatusColor = {
  [WeighLotScanStatus.DEDUPLICATED]: "inProgress",
  [WeighLotScanStatus.IGNORED]: "success",
  [WeighLotScanStatus.REWEIGHED]: "success",
  [WeighLotScanStatus.MAPPED]: "success",
  [WeighLotScanStatus.MANUALLY_MAPPED]: "success",
  [WeighLotScanStatus.UNRESOLVED]: "error",
  [WeighLotScanStatus.MAPPED_TO_SALE_LOT]: "success",
  [WeighLotScanStatus.NEW_EID]: "error",
  [WeighLotScanStatus.REWEIGH]: "error",
};

export const WeighLotScanStatusText = {
  [WeighLotScanStatus.DEDUPLICATED]: "De-Duplicated",
  [WeighLotScanStatus.IGNORED]: "Ignored",
  [WeighLotScanStatus.REWEIGHED]: "Re-Weighed",
  [WeighLotScanStatus.MAPPED]: "Mapped",
  [WeighLotScanStatus.MANUALLY_MAPPED]: "Manually Mapped",
  [WeighLotScanStatus.UNRESOLVED]: "Unresolved",
  [WeighLotScanStatus.MAPPED_TO_SALE_LOT]: "Mapped To Sale Lot",
  [WeighLotScanStatus.NEW_EID]: "New EID",
  [WeighLotScanStatus.REWEIGH]: "Re-Weigh",
};

export const HandledNonMappedStatuses = [
  WeighLotScanStatus.DEDUPLICATED,
  WeighLotScanStatus.IGNORED,
  WeighLotScanStatus.MAPPED_TO_SALE_LOT,
  WeighLotScanStatus.MANUALLY_MAPPED,
];

export const NonEidLinkStatuses = [
  WeighLotScanStatus.MAPPED_TO_SALE_LOT,
  WeighLotScanStatus.MANUALLY_MAPPED,
];

export const UnhandledHandledStatuses = [
  WeighLotScanStatus.UNRESOLVED,
  WeighLotScanStatus.NEW_EID,
  WeighLotScanStatus.REWEIGH,
];

export const UnIgnorableStatuses = [
  WeighLotScanStatus.IGNORED,
  WeighLotScanStatus.DEDUPLICATED,
];

export const UnKeepableStatuses = [
  WeighLotScanStatus.REWEIGHED,
  WeighLotScanStatus.MAPPED,
  WeighLotScanStatus.MANUALLY_MAPPED,
  WeighLotScanStatus.MAPPED_TO_SALE_LOT,
];
