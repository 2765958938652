import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

import LogoIcon from "img/AgriNous-icon";

const IconWrapper = styled.div`
  ${({ theme, backgroundColor, size }) => `
  width: ${size}px;
  height: ${size}px;
  border-radius: ${size / 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors[backgroundColor]};
  margin: auto ${theme.space[1]}px;
`}
`;

export const AgriNousLogo = ({
  backgroundColor,
  color,
  envColor,
  scale = 0.7,
  size = 36,
}) => {
  return (
    <IconWrapper backgroundColor={backgroundColor} size={size}>
      <LogoIcon color={color} envColor={color || envColor} scale={scale} />
    </IconWrapper>
  );
};

const AgriNousActiveLogoComponent = ({ isActive, scale = 0.7, size = 36 }) => {
  // Simple wrapper around the AgriNousLogo that takes a boolean instead of a color.
  const backgroundColor = isActive ? "successGreen" : "grayDisabled";
  const color = isActive ? "white" : "gray9C";
  return (
    <AgriNousLogo
      color={color}
      backgroundColor={backgroundColor}
      scale={scale}
      size={size}
    />
  );
};

AgriNousActiveLogoComponent.propTypes = {
  isActive: PropTypes.bool,
  scale: PropTypes.number,
};

const AgriNousActiveLogo = React.memo(AgriNousActiveLogoComponent);

export default AgriNousActiveLogo;
