import React from "react";

import Grid from "@material-ui/core/Grid";

import Button from "components/Button/Button";
import { Column, Row } from "components/Layout";

export function TemporaryBusinessMessage({ setNotIsTemporary }) {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6}>
        <Column
          marginHorizontal={3}
          marginVertical={1}
          padding={2}
          background="gray95"
        >
          <p>
            You are editing a Temporary Business. This Business will not be
            searchable or selectable outside of the Sale it was created in.{" "}
          </p>
          <p>
            You can Convert it to a regular Business to use it throughout
            AgriNous.
          </p>
          <Row paddingVertical={0}>
            <Button inline padding={5} onClick={setNotIsTemporary}>
              Convert Business
            </Button>
          </Row>
        </Column>
      </Grid>
    </Grid>
  );
}
