import {
  GET_ALL_NVD_UPLOADS,
  GET_NVD_UPLOAD,
  REFRESH_NVD_UPLOAD,
  CLEAR_NVD_UPLOADS,
  REFRESH_SELL_FILE,
  REFRESH_SELL_REVERSAL,
  REFRESH_TAKE_FILE,
  REFRESH_TAKE_REVERSAL,
  SELL_FILE,
  SELL_REVERSAL,
  TAKE_FILE,
  TAKE_REVERSAL,
  SYNC_NLIS,
  P2P_FILE,
} from "constants/actionTypes";

export function requestTakeStatus(consignment, sale) {
  return {
    type: TAKE_FILE.FETCH.REQUEST,
    consignment,
    sale,
  };
}

export function requestTakeStatusSuccess(takeFiles) {
  return {
    type: TAKE_FILE.FETCH.SUCCESS,
    takeFiles,
  };
}

export function requestTakeStatusError(error) {
  return {
    type: TAKE_FILE.FETCH.FAILURE,
    error,
  };
}

export function requestAllTakeStatuses(sale) {
  return {
    type: TAKE_FILE.FETCH_BULK.REQUEST,
    sale,
  };
}

export function requestAllTakeStatusesSuccess(takeFiles) {
  return {
    type: TAKE_FILE.FETCH_BULK.SUCCESS,
    takeFiles,
  };
}

export function requestAllTakeStatusesError(error) {
  return {
    type: TAKE_FILE.FETCH_BULK.FAILURE,
    error,
  };
}

export function requestSellFile(sale, saleLot) {
  return {
    type: SELL_FILE.FETCH.REQUEST,
    sale,
    saleLot,
  };
}

export function refreshSellFile(payload) {
  return {
    type: REFRESH_SELL_FILE,
    sellFileId: payload.object.id,
    sale: {
      livestocksale_id: payload.object.livestock_sale_id,
      saleyard_name: payload.object.saleyard_name,
    },
  };
}

export function receiveSellFile(sellFiles) {
  return {
    type: SELL_FILE.FETCH.SUCCESS,
    sellFiles,
  };
}

export function requestSellFileFailed(error) {
  return {
    type: SELL_FILE.FETCH.FAILURE,
    error,
  };
}

export function requestAllSellFiles(sale) {
  return {
    type: SELL_FILE.FETCH_BULK.REQUEST,
    sale,
  };
}

export function receiveAllSellFiles(sellFiles) {
  return {
    type: SELL_FILE.FETCH_BULK.SUCCESS,
    sellFiles,
  };
}

export function requestAllSellFilesFailed(error) {
  return {
    type: SELL_FILE.FETCH_BULK.FAILURE,
    error,
  };
}

export function refreshTakeFile(payload) {
  return {
    type: REFRESH_TAKE_FILE,
    takeFileId: payload.object.id,
    sale: {
      livestocksale_id: payload.object.livestock_sale_id,
      saleyard_name: payload.object.saleyard_name,
    },
  };
}

export function refreshTakeReversal(payload) {
  return {
    type: REFRESH_TAKE_REVERSAL,
    takeReversalId: payload.object.id,
    sale: {
      livestocksale_id: payload.object.livestock_sale_id,
      saleyard_name: payload.object.saleyard_name,
    },
  };
}

export function refreshSellReversal(payload) {
  return {
    type: REFRESH_SELL_REVERSAL,
    sellReversalId: payload.object.id,
    sale: {
      livestocksale_id: payload.object.livestock_sale_id,
      saleyard_name: payload.object.saleyard_name,
    },
  };
}

export function requestAllNvdUploads(sale) {
  return {
    type: GET_ALL_NVD_UPLOADS.REQUEST,
    sale,
  };
}

export function requestAllNvdUploadsSuccess(uploads) {
  return {
    type: GET_ALL_NVD_UPLOADS.SUCCESS,
    uploads,
  };
}

export function requestAllNvdUploadsError(error) {
  return {
    type: GET_ALL_NVD_UPLOADS.FAILURE,
    error,
  };
}

export function refreshNVDUpload(payload) {
  return {
    type: REFRESH_NVD_UPLOAD,
    nvdUploadId: payload.object.id,
    sale: {
      livestocksale_id: payload.object.livestock_sale_id,
      saleyard_name: payload.object.saleyard_name,
    },
  };
}

export function requestNVDUpload(consignment, sale) {
  return {
    type: GET_NVD_UPLOAD.REQUEST,
    consignment,
    sale,
  };
}

export function requestNVDUploadSuccess(status) {
  return {
    type: GET_NVD_UPLOAD.SUCCESS,
    status,
  };
}

export function requestNVDUploadError(error) {
  return {
    type: GET_NVD_UPLOAD.FAILURE,
    error,
  };
}

export function requestAllTakeReversals(sale) {
  return {
    type: TAKE_REVERSAL.FETCH_BULK.REQUEST,
    sale,
  };
}

export function requestAllTakeReversalsSuccess(takeReversals) {
  return {
    type: TAKE_REVERSAL.FETCH_BULK.SUCCESS,
    takeReversals,
  };
}

export function requestAllTakeReversalsFailure(error) {
  return {
    type: TAKE_REVERSAL.FETCH_BULK.FAILURE,
    error,
  };
}

export function requestTakeReversal(id) {
  return {
    type: TAKE_REVERSAL.FETCH.REQUEST,
    id,
  };
}

export function requestTakeReversalSuccess(takeReversal) {
  return {
    type: TAKE_REVERSAL.FETCH.SUCCESS,
    takeReversals: [takeReversal],
  };
}

export function requestTakeReversalError(error) {
  return {
    type: TAKE_REVERSAL.FETCH.FAILURE,
    error,
  };
}

export function requestAllSellReversals(sale) {
  return {
    type: SELL_REVERSAL.FETCH_BULK.REQUEST,
    sale,
  };
}

export function requestAllSellReversalsSuccess(sellReversals) {
  return {
    type: SELL_REVERSAL.FETCH_BULK.SUCCESS,
    sellReversals,
  };
}

export function requestAllSellReversalsFailure(error) {
  return {
    type: SELL_REVERSAL.FETCH_BULK.FAILURE,
    error,
  };
}

export function requestSellReversal(id) {
  return {
    type: SELL_REVERSAL.FETCH.REQUEST,
    id,
  };
}

export function requestSellReversalSuccess(sellReversal) {
  return {
    type: SELL_REVERSAL.FETCH.SUCCESS,
    sellReversals: [sellReversal],
  };
}

export function requestSellReversalError(error) {
  return {
    type: SELL_REVERSAL.FETCH.FAILURE,
    error,
  };
}

export function clearTakeFiles() {
  return {
    type: TAKE_FILE.FETCH_BULK.RESET,
  };
}

export function clearTakeReversals() {
  return {
    type: TAKE_REVERSAL.FETCH_BULK.RESET,
  };
}

export function clearNvdUploads() {
  return {
    type: CLEAR_NVD_UPLOADS,
  };
}

export function clearSellFiles() {
  return {
    type: SELL_FILE.FETCH_BULK.RESET,
  };
}

export function clearSellReversals() {
  return {
    type: SELL_REVERSAL.FETCH_BULK.RESET,
  };
}

export function clearP2PFiles() {
  return {
    type: P2P_FILE.FETCH_BULK.RESET,
  };
}

export const syncNlis = () => ({
  type: SYNC_NLIS,
});
