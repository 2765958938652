import React, { useEffect, useRef } from "react";

import sortBy from "lodash/sortBy";
import { useSelector } from "react-redux";

import SingleComment from "components/Comments/components/SingleComment";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { CommentTypes } from "constants/comments";
import { ApiModel } from "constants/loading";

import {
  getCommentsByNominationId,
  getCommentsByPenScanLotId,
  getCommentsByReceivalLotId,
  getCommentsByDeploymentSaleId,
  getCommentsBySaleLotId,
  getCommentsByWeighLotId,
} from "selectors";

const CommentTypeGetByIdSelector = {
  [CommentTypes.DEPLOYMENT_SALE]: getCommentsByDeploymentSaleId,
  [CommentTypes.SALE_LOT]: getCommentsBySaleLotId,
  [CommentTypes.NOMINATION]: getCommentsByNominationId,
  [CommentTypes.RECEIVAL_LOT]: getCommentsByReceivalLotId,
  [CommentTypes.PEN_SCAN_LOT]: getCommentsByPenScanLotId,
  [CommentTypes.WEIGH_LOT]: getCommentsByWeighLotId,
};

function BottomAnchor() {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
}

function CommentList({ commentType, commentTypeId }) {
  // Get the actual comments, because we want to sort etc.
  const comments = useSelector(
    CommentTypeGetByIdSelector[commentType](commentTypeId),
  );

  // Note the filter boolean - we may have got the update for the sale lot with a list
  // of ids, but not actioned a change to the comments
  return (
    <WaitForSync requiredData={[ApiModel.COMMENTS]}>
      {sortBy(comments.filter(Boolean), "created").map(comment => (
        <SingleComment
          key={comment.id}
          commentId={comment.id}
          commentType={commentType}
        />
      ))}
      <BottomAnchor />
    </WaitForSync>
  );
}

export default CommentList;
