import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const DressingRangeSerializeMap = {
  dressingRangeMinPercent: "dressing_range_min_percent",
  dressingRangeMaxPercent: "dressing_range_max_percent",
  id: "id",
  order: "order",
};

export const serializeDressingRanges = buildSerializer(
  DressingRangeSerializeMap,
  "dressingRange",
);

export const deserializeDressingRanges = buildDeserializer(
  DressingRangeSerializeMap,
  "dressingRange",
);
