import React from "react";

import { DialogActions } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { RuleBookAction } from "actions";

import { Button, SecondaryButton } from "components/Form";
import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";
import { RuleBookTable } from "components/RuleBookTable/index";

import { ModalTypes } from "constants/navigation";

import {
  getIsLivestockAgent,
  getIsSaleyardAdmin,
  getRuleBookById,
} from "selectors";

import { useBoolean, useIsMobile, useModalAdapter } from "hooks";
import { useGetParentObjectFromActiveRole } from "hooks/useGetParentObjectFromActiveRole";

import { RuleBookSummaryForm } from "./RuleBookSummaryForm";

const validationSchema = Yup.object().shape({
  name: Yup.mixed().required("Name is required"),
  description: Yup.string().nullable(),
  reports: Yup.array().nullable(),
  effective_start_date: Yup.date().nullable(),
  effective_end_date: Yup.date().nullable(),
  sale_sub_type_ids: Yup.array().nullable(),
  is_archived: Yup.boolean(),
});

const Modal = ({ onClose = undefined, ruleBookId = null }) => {
  const isCreate = !ruleBookId;
  const title = `${isCreate ? "Create" : "Edit"} Rule Book`;

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [isEditSettingsOpen, openEditSettings, closeEditSettings] =
    useBoolean();

  const isLivestockAgent = useSelector(getIsLivestockAgent);
  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  const parentObject = useGetParentObjectFromActiveRole() || {};

  const deploymentId = (isLivestockAgent && parentObject.id) || null;
  const saleyardName = (isSaleyardAdmin && parentObject.name) || null;

  const handleClose = isEditSettingsOpen ? closeEditSettings : onClose;

  function onSubmit(values) {
    if (isCreate) {
      dispatch(
        RuleBookAction.create(
          { id: uuidv4(), ...values },
          {
            deploymentId,
            saleyardName,
          },
        ),
      );
    } else {
      dispatch(RuleBookAction.update(values));
    }
    handleClose();
  }

  const ruleBook = useSelector(getRuleBookById(ruleBookId)) || {};

  const initialValues = ruleBook;

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      fullScreen={!isCreate || isMobile}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <DialogContent dividers>
            {isCreate ? (
              <RuleBookSummaryForm />
            ) : (
              <RuleBookTable
                ruleBookId={ruleBookId}
                isEditSettingsOpen={isEditSettingsOpen}
                openEditSettings={openEditSettings}
              />
            )}
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={handleClose} type="button">
              {isEditSettingsOpen ? "Cancel" : "Back"}
            </SecondaryButton>
            <Button
              data-tour={isCreate ? "create" : "save"}
              type="submit"
              disabled={!isCreate && !isEditSettingsOpen}
            >
              {isCreate ? "Create" : "Save"}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export function RuleBookModalAdapter(props) {
  const [hashParams, _ignored, onClose] = useModalAdapter(
    ModalTypes.RuleBook,
    props,
  );

  const { id: ruleBookId } = hashParams;

  return <Modal ruleBookId={ruleBookId} onClose={onClose} />;
}
