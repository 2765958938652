import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useHotkeys } from "react-hotkeys-hook";
import styled from "styled-components/macro";

import { Tooltip } from "components/Form/FormikControls/Tooltip";

const StyledFloatingActionButton = styled.button(
  ({ theme }) => `
  position: sticky;
  bottom: ${theme.space[2]}px;
  left: ${theme.space[2]}px;
  text-decoration: none;
  border: 2px solid ${theme.colors.primary};
  border-radius: 27px;
  background-color: ${theme.colors.primary};
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.4);
  color: ${theme.colors.white};
  display: inline-block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: ${theme.fontSizes.beta}px;
  font-weight: ${theme.fontWeights.medium};
  padding: ${theme.space[2]}px;F
  margin-top: ${theme.space[2]}px;
  & > ${FontAwesomeIcon} {
    padding-right: ${theme.space[2]}px;
  }
  
  @media print {
    display: none;
  }
`,
);

const FloatingActionButton = ({
  dataTour,
  disabled,
  hotKeys,
  icon,
  onClick,
  text,
}) => {
  useHotkeys(hotKeys.keys, onClick);

  return (
    <StyledFloatingActionButton
      data-tour={dataTour}
      disabled={disabled}
      type="button"
      onClick={onClick}
    >
      <Tooltip title={`${hotKeys.text} : ${text}`}>
        <FontAwesomeIcon icon={icon} />
        &nbsp;{text}
      </Tooltip>
    </StyledFloatingActionButton>
  );
};

FloatingActionButton.propTypes = {
  dataTour: PropTypes.string,
  disabled: PropTypes.bool,
  hotKeys: PropTypes.object.isRequired,
  icon: PropTypes.any.isRequired, // IconDefinition from "@fortawesome/fontawesome-common-types",
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default FloatingActionButton;
