import { createSelector } from "reselect";

import { SEXES } from "constants/sexes";

import { getCurrentSale } from "./currentSale";
import { getSexes } from "./root";

export const getSkinsSexId = createSelector(
  [getSexes, getCurrentSale],
  (sexes, sale) =>
    Object.values(sexes).find(
      sex => sex.species_id === sale.species_id && sex.name === SEXES.SKINS,
    )?.id,
);
