import React from "react";

import PropTypes from "prop-types";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import VirtualizedList from "react-virtualized/dist/commonjs/List";
import styled from "styled-components/macro";

const List = ({
  border,
  listStyle,
  optionComponents,
  rowHeight,
  optionListHeight,
  options,
  renderFooter,
  displayDropdown,
  isMulti,
  expandedIndex,
  expandedHeight,
  setRef,
}) => {
  const getRowHeight = ({ index }) =>
    expandedIndex >= 0 && index === expandedIndex ? expandedHeight : rowHeight;
  return (
    <AutoSizer>
      {({ width }) => {
        const itemWidth = border ? width - 2 : width;
        return (
          <>
            <VirtualizedList
              id="virtualized-dropdown-list"
              style={{ outline: "none", ...listStyle }}
              rowCount={optionComponents.length}
              rowRenderer={({ key, style, index }) => (
                <div key={key} style={style}>
                  {optionComponents[index]}
                </div>
              )}
              rowHeight={getRowHeight}
              height={optionListHeight}
              overscanRowCount={10}
              width={itemWidth}
              ref={setRef ? setRef() : null}
            >
              {options && optionComponents}
            </VirtualizedList>
            {renderFooter ? (
              <FooterWrapper
                displayDropdown={displayDropdown || isMulti}
                itemWidth={itemWidth}
                rowHeight={rowHeight}
                optionListHeight={optionListHeight}
              >
                {renderFooter()}
              </FooterWrapper>
            ) : null}
          </>
        );
      }}
    </AutoSizer>
  );
};

List.propTypes = {
  border: PropTypes.bool,
  listStyle: PropTypes.object,
  optionComponents: PropTypes.array,
  rowHeight: PropTypes.number,
  optionListHeight: PropTypes.number,
  options: PropTypes.array,
  renderFooter: PropTypes.func,
  displayDropdown: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default List;

const FooterWrapper = styled.div`
  position: absolute;
  top: ${({ optionListHeight }) => optionListHeight}px;
  width: ${({ itemWidth }) => itemWidth}px;
  height: ${({ displayDropdown, rowHeight }) =>
    displayDropdown ? rowHeight : 0}px;
  transition: ${({ displayDropdown }) =>
    displayDropdown ? "height 0.05s linear" : ""};
  transition-delay: ${({ displayDropdown }) =>
    displayDropdown ? "0.2s" : "0"};
  overflow: hidden;
`;
