import { intersection, max } from "lodash";
import { createSelector } from "reselect";

import { ExportSites } from "constants/exportSites";
import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { isSaleLotInProgress, getSortedMarkDetails } from "lib/saleLot";

import {
  getConsignments,
  getGlobalSearchBySearchField,
  getSaleLots,
  selectAuctionPenIdsByConsignmentIdLookup,
  selectCanHaveProgenyBySaleLotIdLookup,
  selectConsignmentHasImageLookup,
  selectConsignmentHasVideoLookup,
  selectConsignmentIdByEidLookup,
  selectDeliveryPenIdsByConsignmentIdLookup,
  selectExceptionsByConsignmentIdLookup,
  selectExceptionsBySaleLotIdLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectSaleLotHasImageLookup,
  selectSaleLotHasVideoLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectScanStatusBySaleLotIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
} from "selectors";

import { selectAgencyIdByConsignmentIdLookup } from "selectors/consignmentsCalculated";
import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";

import {
  saleLotsFilteredBuyerIdsComparator,
  saleLotsFilteredThirdPartyIdsComparator,
  saleLotsFilteredBuyerHashesComparator,
  saleLotsFilteredSaleRoundIdsComparator,
  saleLotsFilteredIsBuyerBalancedComparator,
  saleLotsFilteredIsCountedComparator,
  saleLotsFilteredHaveOverflowPenCompararator,
  saleLotsFilteredIsDeliveredComparator,
  saleLotsFilteredLabelsComparator,
  saleLotsFilteredPricingTypesComparator,
  saleLotsFilteredPropertyIdComparator,
  auctionPensFilteredComparator,
  deliveryPensFilteredComparator,
  consignmentFilteredVendorNumberComparator,
  consignmentFilteredVendorPicsComparator,
  consignmentFilteredCheckpointComparator,
  consignmentFilteredReceivalPenComparator,
  saleLotsOverflowPensComparator,
  auctionPensLanesComparator,
} from "./comparators";
import {
  filterAndMapObjectIdsWithLookup,
  lookupFilterSelectorFactory,
  rawObjectFilterSelectorFactory,
} from "./lib";

const selectUnfilteredConsignmentIds = createSelector(
  [getConsignments],
  consignments => Object.keys(consignments),
);

const selectBuyerFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Buyer,
  selectUnfilteredConsignmentIds,
  saleLotsFilteredBuyerIdsComparator,
  "consignment_id",
);

const selectThirdPartyFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.ThirdParty,
  selectUnfilteredConsignmentIds,
  saleLotsFilteredThirdPartyIdsComparator,
  "consignment_id",
);

const selectBuyerAndBuyerWayFilteredConsignmentIds =
  rawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.BuyerAndBuyerWay,
    selectUnfilteredConsignmentIds,
    saleLotsFilteredBuyerHashesComparator,
    "consignment_id",
  );

const selectVendorFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getConsignments,
  GlobalSearchFields.Vendor,
  selectUnfilteredConsignmentIds,
  vendorIds => consignment => vendorIds.includes(consignment.vendor_id),
);

const selectSaleRoundFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.SaleRound,
  selectUnfilteredConsignmentIds,
  saleLotsFilteredSaleRoundIdsComparator,
  "consignment_id",
);

const selectAgencyFilteredConsignmentIds = lookupFilterSelectorFactory(
  selectAgencyIdByConsignmentIdLookup,
  GlobalSearchFields.Agency,
  selectUnfilteredConsignmentIds,
  agencyIds =>
    ([ignored, agencyId]) =>
      agencyIds.includes(agencyId),
);

const selectHasArrivedFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getConsignments,
  GlobalSearchFields.HasArrived,
  selectUnfilteredConsignmentIds,
  hasArrived => consignment => consignment.hasArrived === hasArrived[0],
);

const selectHasBuyerExceptionsFilteredConsignmentIds = createSelector(
  [
    selectExceptionsBySaleLotIdLookup,
    selectSaleLotIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasBuyerExceptions),
    selectUnfilteredConsignmentIds,
  ],
  (
    exceptionsBySaleLotIdLookup,
    saleLotIdsByConsignmentIdLookup,
    hasBuyerExceptions,
    unfilteredConsignmentIds,
  ) =>
    hasBuyerExceptions === null
      ? unfilteredConsignmentIds
      : Object.entries(saleLotIdsByConsignmentIdLookup)
          .filter(([ignored, saleLotIds]) =>
            Boolean(
              saleLotIds.find(
                saleLotId =>
                  (exceptionsBySaleLotIdLookup[saleLotId].length === 0) ===
                  hasBuyerExceptions[0],
              ),
            ),
          )
          .map(([consignmentId, ignored]) => consignmentId),
);

const selectHasConsignmentScansFilteredConsignmentIds =
  selectUnfilteredConsignmentIds;
const selectHasOverflowFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.HasOverflow,
  selectUnfilteredConsignmentIds,
  saleLotsFilteredHaveOverflowPenCompararator,
  "consignment_id",
);

const selectHasVendorExceptionsFilteredConsignmentIds =
  lookupFilterSelectorFactory(
    selectExceptionsByConsignmentIdLookup,
    GlobalSearchFields.HasVendorExceptions,
    selectUnfilteredConsignmentIds,
    hasVendorExceptions =>
      ([ignored, exceptions]) =>
        (exceptions.length !== 0) === hasVendorExceptions[0],
  );

const selectHasWeightFilteredConsignmentIds = createSelector(
  [
    getSaleLots,
    selectIsWeighedBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredConsignmentIds,
  ],
  (
    saleLots,
    isWeighedBySaleLotIdLookup,
    hasWeight,
    unfilteredConsignmentIds,
  ) => {
    return hasWeight === null
      ? unfilteredConsignmentIds
      : Object.values(saleLots)
          .filter(
            saleLot => isWeighedBySaleLotIdLookup[saleLot.id] === hasWeight[0],
          )
          .map(lot => lot.consignment_id);
  },
);

const selectIsBuyerBalancedFilteredConsignmentIds =
  rawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.IsBuyerBalanced,
    selectUnfilteredConsignmentIds,
    saleLotsFilteredIsBuyerBalancedComparator,
    "consignment_id",
  );

const selectIsCountedFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.IsCounted,
  selectUnfilteredConsignmentIds,
  saleLotsFilteredIsCountedComparator,
  "consignment_id",
);

const selectIsDeliveredFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.IsDelivered,
  selectUnfilteredConsignmentIds,
  saleLotsFilteredIsDeliveredComparator,
  "consignment_id",
);

const selectIsEidCompliantFilteredConsignmentIds = createSelector(
  [
    getConsignments,
    selectScanStatusBySaleLotIdLookup,
    selectSaleLotIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsEidCompliant),
    selectUnfilteredConsignmentIds,
  ],
  (
    consignments,
    scanStatusBySaleLotIdLookup,
    saleLotIdsByConsignmentIdLookup,
    isEidCompliant,
    unfilteredConsignmentIds,
  ) =>
    isEidCompliant === null
      ? unfilteredConsignmentIds
      : Object.values(consignments)
          .filter(consignment => {
            const saleLotIds = saleLotIdsByConsignmentIdLookup[consignment.id];
            // If we don't have any sale lots yet... we're not eid compliant.  Or we are... because they're 100% scanned? ;\
            if (!saleLotIds) {
              return isEidCompliant[0];
            }
            const allScanStatuses = saleLotIds.map(
              saleLotId =>
                scanStatusBySaleLotIdLookup[saleLotId] || ScanStatus.PASS,
            );
            const consignmentScanStatus = max(allScanStatuses);
            return (
              (consignmentScanStatus === ScanStatus.PASS) === isEidCompliant[0]
            );
          })
          .map(consignment => consignment.id),
);

const selectIsInProgressFilteredConsignmentIds = createSelector(
  [
    getSaleLots,
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredConsignmentIds,
  ],
  (saleLots, consignments, isInProgress, unfilteredConsignmentIds) =>
    isInProgress === null
      ? unfilteredConsignmentIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isSaleLotInProgress(
                saleLot,
                consignments[saleLot.consignment_id],
              ) === isInProgress[0],
          )
          .map(saleLot => saleLot.consignment_id),
);

const selectIsSoldFilteredConsignmentIds = createSelector(
  [
    getConsignments,
    selectIsSoldBySaleLotIdLookup,
    selectSaleLotIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSold),
    selectUnfilteredConsignmentIds,
  ],
  (
    consignments,
    isSoldBySaleLotIdLookup,
    saleLotIdsByConsignmentIdLookup,
    isSoldFieldValue,
    unfilteredConsignmentIds,
  ) =>
    isSoldFieldValue === null
      ? unfilteredConsignmentIds
      : Object.values(consignments)
          .filter(consignment => {
            const saleLotIds = saleLotIdsByConsignmentIdLookup[consignment.id];
            // If we don't have any sale lots yet... none are sold.
            if (!saleLotIds) {
              return isSoldFieldValue[0];
            }
            return saleLotIds.some(
              saleLotId =>
                isSoldBySaleLotIdLookup[saleLotId] === isSoldFieldValue[0],
            );
          })
          .map(consignment => consignment.id),
);

const selectIsVendorPostSaleBalancedFilteredConsignmentIds =
  lookupFilterSelectorFactory(
    selectIsPostSaleBalancedByConsignmentIdLookup,
    GlobalSearchFields.IsPostSaleVendorBalanced,
    selectUnfilteredConsignmentIds,
    isVendorBalanced =>
      ([ignored, isBalanced]) =>
        isBalanced === isVendorBalanced[0],
  );

const selectIsVendorPreSaleBalancedFilteredConsignmentIds =
  lookupFilterSelectorFactory(
    selectIsPreSaleBalancedByConsignmentIdLookup,
    GlobalSearchFields.IsPreSaleVendorBalanced,
    selectUnfilteredConsignmentIds,
    isVendorPreSaleBalanced =>
      ([ignored, isBalanced]) =>
        isBalanced === isVendorPreSaleBalanced[0],
  );

const selectAuctionPenFilteredConsignmentIds = lookupFilterSelectorFactory(
  selectAuctionPenIdsByConsignmentIdLookup,
  GlobalSearchFields.AuctionPen,
  selectUnfilteredConsignmentIds,
  auctionPensFilteredComparator,
);
const selectDeliveryPenFilteredConsignmentIds = lookupFilterSelectorFactory(
  selectDeliveryPenIdsByConsignmentIdLookup,
  GlobalSearchFields.DeliveryPen,
  selectUnfilteredConsignmentIds,
  deliveryPensFilteredComparator,
);

const selectOverflowPenFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.OverflowPen,
  selectUnfilteredConsignmentIds,
  saleLotsOverflowPensComparator,
  "consignment_id",
);

const selectLaneFilteredConsignmentIds = lookupFilterSelectorFactory(
  selectAuctionPenIdsByConsignmentIdLookup,
  GlobalSearchFields.Lane,
  selectUnfilteredConsignmentIds,
  auctionPensLanesComparator,
);

const selectLabelFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Label,
  selectUnfilteredConsignmentIds,
  saleLotsFilteredLabelsComparator,
  "consignment_id",
);

const selectPricingTypeFilteredConsignmentIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.PricingType,
  selectUnfilteredConsignmentIds,
  saleLotsFilteredPricingTypesComparator,
  "consignment_id",
);

const selectHasProgenyFilteredConsignmentIds = createSelector(
  [
    getSaleLots,
    selectCanHaveProgenyBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasProgeny),
    selectUnfilteredConsignmentIds,
  ],
  (
    saleLots,
    canHaveProgenyBySaleLotIdLookup,
    hasProgeny,
    unfilteredConsignmentIds,
  ) =>
    hasProgeny === null
      ? unfilteredConsignmentIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              canHaveProgenyBySaleLotIdLookup[saleLot.id] === hasProgeny[0],
          )
          .map(saleLot => saleLot.consignment_id),
);

const selectScanFilteredConsignmentIds = createSelector(
  [
    selectConsignmentIdByEidLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Scan),
    selectUnfilteredConsignmentIds,
  ],
  (consignmentIdByEidLookup, eids, unfilteredConsignmentIds) =>
    eids === null
      ? unfilteredConsignmentIds
      : eids.map(eid => consignmentIdByEidLookup[eid]).filter(Boolean),
);
const selectVendorNumberFilteredConsignmentsIds =
  rawObjectFilterSelectorFactory(
    getConsignments,
    GlobalSearchFields.VendorNumber,
    selectUnfilteredConsignmentIds,
    consignmentFilteredVendorNumberComparator,
  );

const selectVendorPicFilteredConsignmentsIds = rawObjectFilterSelectorFactory(
  getConsignments,
  GlobalSearchFields.VendorPic,
  selectUnfilteredConsignmentIds,
  consignmentFilteredVendorPicsComparator,
);

const selectBuyerPicFilteredConsignmentsIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.BuyerPic,
  selectUnfilteredConsignmentIds,
  saleLotsFilteredPropertyIdComparator,
  "consignment_id",
);

const selectCheckpointFilteredConsignmentsIds = rawObjectFilterSelectorFactory(
  getConsignments,
  GlobalSearchFields.Checkpoint,
  selectUnfilteredConsignmentIds,
  consignmentFilteredCheckpointComparator,
);

const selectIsAuctionsPlusCompliantConsignmentIds = createSelector(
  [
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredConsignmentIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsAuctionsPlusCompliant),
    getConsignments,
    selectSaleLotIdsByConsignmentIdLookup,
  ],
  (
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredConsignmentIds,
    searchField,
    consignments,
    saleLotIdsByConsignmentIdLookup,
  ) =>
    searchField === null
      ? unfilteredConsignmentIds
      : Object.values(consignments)
          .filter(consignment => {
            const saleLotIds = saleLotIdsByConsignmentIdLookup[consignment.id];
            if (!saleLotIds) {
              return searchField[0];
            }
            return saleLotIds.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.AUCTIONS_PLUS
                ] === searchField[0],
            );
          })
          .map(consignment => consignment.id),
);

const selectIsStockLiveCompliantConsignmentIds = createSelector(
  [
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredConsignmentIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsStockLiveCompliant),
    getConsignments,
    selectSaleLotIdsByConsignmentIdLookup,
  ],
  (
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredConsignmentIds,
    searchField,
    consignments,
    saleLotIdsByConsignmentIdLookup,
  ) =>
    searchField === null
      ? unfilteredConsignmentIds
      : Object.values(consignments)
          .filter(consignment => {
            const saleLotIds = saleLotIdsByConsignmentIdLookup[consignment.id];
            if (!saleLotIds) {
              return searchField[0];
            }
            return saleLotIds.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.STOCK_LIVE
                ] === searchField[0],
            );
          })
          .map(consignment => consignment.id),
);

const selectIsSaleLotImageUploadedConsignmentsIds =
  filterAndMapObjectIdsWithLookup(
    getSaleLots,
    GlobalSearchFields.IsSaleLotImageUploaded,
    "consignment_id",
    selectUnfilteredConsignmentIds,
    getConsignments,
    selectSaleLotHasImageLookup,
  );

const selectIsConsignmentImageUploadedConsignmentsIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentImageUploaded,
    "id",
    selectUnfilteredConsignmentIds,
    getSaleLots,
    selectConsignmentHasImageLookup,
  );

const selectIsSaleLotVideoUploadedConsignmentsIds =
  filterAndMapObjectIdsWithLookup(
    getSaleLots,
    GlobalSearchFields.IsSaleLotVideoUploaded,
    "consignment_id",
    selectUnfilteredConsignmentIds,
    getConsignments,
    selectSaleLotHasVideoLookup,
  );

const selectIsConsignmentVideoUploadedConsignmentsIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentVideoUploaded,
    "id",
    selectUnfilteredConsignmentIds,
    getSaleLots,
    selectConsignmentHasVideoLookup,
  );

const selectReceivalPenConsignmentsIds = rawObjectFilterSelectorFactory(
  getConsignments,
  GlobalSearchFields.ReceivalPen,
  selectUnfilteredConsignmentIds,
  consignmentFilteredReceivalPenComparator,
  "id",
);

const selectMarksFilteredConsignmentsIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredConsignmentIds,
  ],
  (saleLots, marks, unfilteredConsignmentIds) =>
    marks === null
      ? unfilteredConsignmentIds
      : Object.values(saleLots)
          .filter(saleLot =>
            marks.some(mark => mark === getSortedMarkDetails(saleLot)),
          )
          .map(lot => lot.consignment_id),
);

export const selectFilteredConsignmentIds = createSelector(
  [
    selectAgencyFilteredConsignmentIds,
    selectAuctionPenFilteredConsignmentIds,
    selectBuyerFilteredConsignmentIds,
    selectBuyerAndBuyerWayFilteredConsignmentIds,
    selectDeliveryPenFilteredConsignmentIds,
    selectHasArrivedFilteredConsignmentIds,
    selectHasBuyerExceptionsFilteredConsignmentIds,
    selectHasConsignmentScansFilteredConsignmentIds,
    selectHasOverflowFilteredConsignmentIds,
    selectHasProgenyFilteredConsignmentIds,
    selectHasVendorExceptionsFilteredConsignmentIds,
    selectHasWeightFilteredConsignmentIds,
    selectIsBuyerBalancedFilteredConsignmentIds,
    selectIsCountedFilteredConsignmentIds,
    selectIsDeliveredFilteredConsignmentIds,
    selectIsEidCompliantFilteredConsignmentIds,
    selectIsInProgressFilteredConsignmentIds,
    selectIsSoldFilteredConsignmentIds,
    selectIsVendorPreSaleBalancedFilteredConsignmentIds,
    selectIsVendorPostSaleBalancedFilteredConsignmentIds,
    selectLabelFilteredConsignmentIds,
    selectLaneFilteredConsignmentIds,
    selectOverflowPenFilteredConsignmentIds,
    selectPricingTypeFilteredConsignmentIds,
    selectSaleRoundFilteredConsignmentIds,
    selectScanFilteredConsignmentIds,
    selectThirdPartyFilteredConsignmentIds,
    selectVendorFilteredConsignmentIds,
    selectVendorNumberFilteredConsignmentsIds,
    selectVendorPicFilteredConsignmentsIds,
    selectBuyerPicFilteredConsignmentsIds,
    selectCheckpointFilteredConsignmentsIds,
    selectIsAuctionsPlusCompliantConsignmentIds,
    selectIsStockLiveCompliantConsignmentIds,
    selectIsSaleLotImageUploadedConsignmentsIds,
    selectIsConsignmentImageUploadedConsignmentsIds,
    selectIsSaleLotVideoUploadedConsignmentsIds,
    selectIsConsignmentVideoUploadedConsignmentsIds,
    selectReceivalPenConsignmentsIds,
    selectMarksFilteredConsignmentsIds,
  ],
  (
    agencyFilteredConsignmentIds,
    auctionPenFilteredConsignmentIds,
    buyerFilteredConsignmentIds,
    buyerAndBuyerWayFilteredConsignmentIds,
    deliveryPenFilteredConsignmentIds,
    hasArrivedFilteredConsignmentIds,
    hasBuyerExceptionsFilteredConsignmentIds,
    hasConsignmentScansFilteredConsignmentIds,
    hasOverflowFilteredConsignmentIds,
    hasProgenyFilteredConsignmentIds,
    hasVendorExceptionsFilteredConsignmentIds,
    hasWeightFilteredConsignmentIds,
    isBuyerBalancedFilteredConsignmentIds,
    isCountedFilteredConsignmentIds,
    isDeliveredFilteredConsignmentIds,
    isEidCompliantFilteredConsignmentIds,
    isInProgressFilteredConsignmentIds,
    isSoldFilteredConsignmentIds,
    isVendorPreSaleBalancedFilteredConsignmentIds,
    isVendorPostSaleBalancedFilteredConsignmentIds,
    labelFilteredConsignmentIds,
    laneFilteredConsignmentIds,
    overflowPenFilteredConsignmentIds,
    pricingTypeFilteredConsignmentIds,
    saleRoundFilteredConsignmentIds,
    scanFilteredConsignmentIds,
    selectThirdPartyFilteredConsignmentIds,
    vendorFilteredConsignmentIds,
    vendorNumberFilteredConsignmentsIds,
    vendorPicFilteredConsignmentsIds,
    buyerPicFilteredConsignmentsIds,
    checkpointFilteredConsignmentsIds,
    isAuctionsPlusCompliantConsignmentIds,
    isStockLiveCompliantConsignmentIds,
    isSaleLotImageUploadedConsignmentIds,
    isConsignmentImageUploadedInConsignmentIds,
    isSaleLotVideoUploadedConsignmentsIds,
    isConsignmentVideoUploadedConsignmentsIds,
    receivalPenConsignmentsIds,
    marksFilteredConsignmentsIds,
  ) =>
    intersection(
      agencyFilteredConsignmentIds,
      auctionPenFilteredConsignmentIds,
      buyerFilteredConsignmentIds,
      buyerAndBuyerWayFilteredConsignmentIds,
      deliveryPenFilteredConsignmentIds,
      hasArrivedFilteredConsignmentIds,
      hasBuyerExceptionsFilteredConsignmentIds,
      hasConsignmentScansFilteredConsignmentIds,
      hasOverflowFilteredConsignmentIds,
      hasProgenyFilteredConsignmentIds,
      hasVendorExceptionsFilteredConsignmentIds,
      hasWeightFilteredConsignmentIds,
      isBuyerBalancedFilteredConsignmentIds,
      isCountedFilteredConsignmentIds,
      isDeliveredFilteredConsignmentIds,
      isEidCompliantFilteredConsignmentIds,
      isInProgressFilteredConsignmentIds,
      isSoldFilteredConsignmentIds,
      isVendorPreSaleBalancedFilteredConsignmentIds,
      isVendorPostSaleBalancedFilteredConsignmentIds,
      labelFilteredConsignmentIds,
      laneFilteredConsignmentIds,
      overflowPenFilteredConsignmentIds,
      pricingTypeFilteredConsignmentIds,
      saleRoundFilteredConsignmentIds,
      scanFilteredConsignmentIds,
      selectThirdPartyFilteredConsignmentIds,
      vendorFilteredConsignmentIds,
      vendorNumberFilteredConsignmentsIds,
      vendorPicFilteredConsignmentsIds,
      buyerPicFilteredConsignmentsIds,
      checkpointFilteredConsignmentsIds,
      isAuctionsPlusCompliantConsignmentIds,
      isStockLiveCompliantConsignmentIds,
      isSaleLotImageUploadedConsignmentIds,
      isConsignmentImageUploadedInConsignmentIds,
      isSaleLotVideoUploadedConsignmentsIds,
      isConsignmentVideoUploadedConsignmentsIds,
      receivalPenConsignmentsIds,
      marksFilteredConsignmentsIds,
    ),
);
