import { partition, sortBy } from "lodash";
import { createSelector } from "reselect";

import {
  getCurrentOrFirstSale,
  getMasterRuleBooks,
  getRuleBooks,
} from "selectors";

export const getMasterRuleBookById = ruleBookId => state =>
  getMasterRuleBooks(state)[ruleBookId] || null;

export const getRuleBookById = ruleBookId => state =>
  getRuleBooks(state)[ruleBookId] || null;

/**
 * We want to group our rulebooks by the sub type and dates:
 *
 * Suggested Rulebook(s)
 * not archived
 * have the correct sale sub type
 * the selected sale falls within the selected rule books date range
 * or has no date range
 *
 * Expired Rulebooks
 * not archived
 * have the correct sale sub type
 * the selected sale DOES NOT fall within the rulebooks date range
 *
 * Incorrect Sale Type Rulebooks
 * not archived
 * have an INCORRECT correct sale sub types
 * the selected sale falls within the selected rulebooks date range
 *
 * Other Rulebooks
 * not archived
 * have an INCORRECT sale sub type
 * and the selected sale DOES NOT fall within the rulebooks date range
 * or has no date range
 *
 *
 * Archived
 * Show the Archived ones at the bottom
 *
 * Then return them grouped to display in dropdown.
 */
export const selectRuleBookOptions = createSelector(
  [getRuleBooks, getCurrentOrFirstSale],
  (ruleBookByIdLookup, sale) => {
    const currentSubTypeId = sale.sale_sub_type_id;
    const saleDate = new Date(sale.date);
    const [archivedRuleBooks, activeRuleBooks] = partition(
      ruleBookByIdLookup,
      rulebook => rulebook.is_archived,
    );

    const lt = (x, y) => x < y;
    const gt = (x, y) => x > y;

    const dateFilter = (dateString, compare, allowNull = false) => {
      if (!dateString) {
        if (allowNull) {
          return true;
        }
      }
      return compare(new Date(dateString), saleDate);
    };

    const suggestedRuleBooks = Object.values(activeRuleBooks).filter(
      rulebook =>
        rulebook.sale_sub_type_ids.includes(currentSubTypeId) &&
        dateFilter(rulebook.effective_start_date, lt, true) &&
        dateFilter(rulebook.effective_end_date, gt, true),
    );

    const expiredRuleBooks = Object.values(activeRuleBooks).filter(
      rulebook =>
        rulebook.sale_sub_type_ids.includes(currentSubTypeId) &&
        (dateFilter(rulebook.effective_start_date, gt) ||
          dateFilter(rulebook.effective_end_date, lt)),
    );

    const incorrectSubTypeRuleBooks = Object.values(activeRuleBooks).filter(
      rulebook =>
        !rulebook.sale_sub_type_ids.includes(currentSubTypeId) &&
        dateFilter(rulebook.effective_start_date, lt) &&
        dateFilter(rulebook.effective_end_date, gt),
    );

    const otherRuleBooks = Object.values(activeRuleBooks).filter(
      rulebook =>
        !rulebook.sale_sub_type_ids.includes(currentSubTypeId) &&
        (dateFilter(rulebook.effective_start_date, gt, true) ||
          dateFilter(rulebook.effective_end_date, lt, true)),
    );

    return [
      {
        label: "Suggested Rulebook(s)",
        options: sortBy(
          Object.values(suggestedRuleBooks).map(ruleBook => ({
            label: ruleBook.name,
            value: ruleBook.id,
          })),
          "label",
        ),
      },
      {
        label: "Expired Rulebook(s)",
        options: sortBy(
          Object.values(expiredRuleBooks).map(ruleBook => ({
            label: ruleBook.name,
            value: ruleBook.id,
          })),
          "label",
        ),
      },
      {
        label: "Incorrect Type Rulebook(s)",
        options: sortBy(
          Object.values(incorrectSubTypeRuleBooks).map(ruleBook => ({
            label: ruleBook.name,
            value: ruleBook.id,
          })),
          "label",
        ),
      },
      {
        label: "Other Rulebook(s)",
        options: sortBy(
          Object.values(otherRuleBooks).map(ruleBook => ({
            label: ruleBook.name,
            value: ruleBook.id,
          })),
          "label",
        ),
      },
      {
        label: "Archived Rulebook(s)",
        options: sortBy(
          Object.values(archivedRuleBooks).map(ruleBook => ({
            label: ruleBook.name,
            value: ruleBook.id,
          })),
          "label",
        ),
      },
    ];
  },
);
