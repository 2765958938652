import React from "react";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { EContractAction } from "actions";

import {
  EmailForm,
  validationSchema,
} from "components/EmailReportModal/EmailForm";
import { ZoomyDialog } from "components/MaterialDialog";

import { EContractParty } from "constants/eContract";

import { toTitleCase } from "lib";

import {
  getBuyerByEContractId,
  getEContractById,
  getVendorByEContractId,
} from "selectors";

export const EContractSendEmailModal = props => {
  const { eContractId, onClose, party } = props;

  const dispatch = useDispatch();
  const eContract = useSelector(getEContractById(eContractId));
  const buyerBusiness = useSelector(getBuyerByEContractId(eContractId));
  const vendorBusiness = useSelector(getVendorByEContractId(eContractId));

  let business = null;
  if (party === EContractParty.BUYER) {
    business = buyerBusiness;
  } else if (party === EContractParty.VENDOR) {
    business = vendorBusiness;
  }

  const initialValues = {
    rawEmailAddress: "",
    businessUserIds: [],
    emailRecipients: [],
  };
  // pre-select primary contact
  if (business) {
    const { primaryContactEmail, emailRecipients, businessUsers } = business;
    if (primaryContactEmail) {
      const primaryEmailRecipient = emailRecipients.find(
        er => er.email === primaryContactEmail,
      );
      const primaryBusinessUser = businessUsers.find(
        bu => bu.email === primaryContactEmail,
      );
      if (primaryEmailRecipient) {
        initialValues.emailRecipients.push({
          id: primaryEmailRecipient.id,
          type: primaryEmailRecipient.source.type,
        });
      } else if (primaryBusinessUser) {
        initialValues.businessUserIds.push(primaryBusinessUser.id);
      }
    } else if ((emailRecipients?.length || 0) > 0) {
      const recipient = emailRecipients[0];
      initialValues.emailRecipients.push({
        id: recipient.id,
        type: recipient.source.type,
      });
    } else if ((businessUsers?.length || 0) > 0) {
      initialValues.businessUserIds.push(businessUsers[0].id);
    }
  }

  const handleSubmit = values => {
    const { emailRecipients, businessUserIds, rawEmailAddress } = values;
    const payload = {
      econtract_id: eContractId,
      party,
      email_recipients: emailRecipients,
      raw_email_address: rawEmailAddress,
      business_user_ids: businessUserIds,
    };
    dispatch(EContractAction.email(payload));

    onClose();
  };

  const partyDisplay = toTitleCase(party);

  return (
    <ZoomyDialog open onClose={onClose} scroll="paper" fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <EmailForm
          onClose={onClose}
          description={`eContract - v${eContract.version} to ${partyDisplay}`}
          businessId={business?.id}
          deploymentId={null}
        />
      </Formik>
    </ZoomyDialog>
  );
};
