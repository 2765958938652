import {
  REQUEST_NEW_JWT_TOKEN_COMMIT,
  REQUEST_NEW_JWT_TOKEN_OFFLINE,
  REQUEST_NEW_JWT_TOKEN_ROLLBACK,
} from "constants/actionTypes";

export function refreshJWT(clientId) {
  const payload = {
    client_id: clientId,
    api_type: "agrinous",
    hide_token: true,
  };

  return {
    type: REQUEST_NEW_JWT_TOKEN_OFFLINE,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/v2/auth/token-refresh/`,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: REQUEST_NEW_JWT_TOKEN_COMMIT,
        },
        rollback: {
          type: REQUEST_NEW_JWT_TOKEN_ROLLBACK,
        },
      },
    },
  };
}
