import {
  receiveTlabAutoDraftPlcDraftingQuery,
  receiveTlabAutoDraftPlcHandshakeRequest,
  receiveTlabAutoDraftPlcDraftingReset,
  sendTlabAutoDraftPlcDraftingDecision,
} from "actions/deviceDrivers/tlabAutoDraftPlc";

export const THOMPSON_LONGHORN_ALLEN_BRADLEY_PLC_DRIVER_NAME =
  "tlab-autodraft-plc";

export const TlabAutoDraftPlcProtocolStatus = {
  UNKNOWN: 1,
  CONNECTING: 2,
  RUNNING: 3,
  WAITING_FOR_DRAFTING_DECISION: 4,
};

function createResponse(payload) {
  return `{${payload}}\r\n`;
}

function createRequest(payload) {
  return `[${payload}]\r`;
}

export const TlabAutoDraftPlc = {
  sendDraftingDecision: sendTlabAutoDraftPlcDraftingDecision,
  /* resetDevice: ... */
};

/**
 * @param {number} draftPenNumber The draft pen which you would like the PLC to open, a number between 0 and 999
 * @return {string}
 */
export function draftingDecisionRequest(draftPenNumber) {
  const draftPenNumberPadded = `000${draftPenNumber}`.slice(-3);
  return createRequest(`D${draftPenNumberPadded}`);
}

/**
 * @param {number} unitNumber The unit on the bus which you would like to address, a number between 1 and 10
 * @return {string}
 */
export function handshakeRequest(unitNumber) {
  const unitNumberPadded = `00${unitNumber}`.slice(-2);
  return createRequest(`PS${unitNumberPadded}`);
}

const TLABResponseMessage = {
  HANDSHAKE_QUERY: createResponse("TL"),
  DRAFTING_QUERY: createResponse("D?"),
  READY: createResponse("RR"),
};

export function tlabAutoDraftPlcProcessMessage(
  device,
  _ignoredType,
  status,
  serializedPayload,
) {
  const { deviceId } = device;
  switch (serializedPayload) {
    case TLABResponseMessage.HANDSHAKE_QUERY:
      return receiveTlabAutoDraftPlcHandshakeRequest(deviceId);
    case TLABResponseMessage.READY:
      return receiveTlabAutoDraftPlcDraftingReset(deviceId);
    case TLABResponseMessage.DRAFTING_QUERY:
      return receiveTlabAutoDraftPlcDraftingQuery(deviceId);
    default:
      // eslint-disable-next-line no-console
      console.warn("Unknown payload received in Allen Bradley PLC Driver: ", {
        deviceId,
        serializedPayload,
      });
  }
}
