import {
  CLEAR_SOCKET_BUFFER,
  QUEUE_SOCKET_MESSAGE,
} from "constants/actionTypes";

const initialState = {
  buffer: [],
};

const socket = (state = initialState, action) => {
  switch (action.type) {
    case QUEUE_SOCKET_MESSAGE:
      return {
        ...state,
        buffer: state.buffer.concat([action]),
      };
    case CLEAR_SOCKET_BUFFER:
      return {
        ...state,
        buffer: [],
      };
    default:
      return state;
  }
};

export default socket;
