import React, { Component } from "react";

import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { includes } from "lodash";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import queryString from "query-string";
import styled from "styled-components/macro";

import { SearchInput } from "components/Form";
import { Label } from "components/Form/FormikControls";
import { MainContentWrapper } from "components/Layout";
import MessageBox from "components/MessageBox";
import { ReactSelect } from "components/SearchableSelector";
import { SendVendorReportsButton } from "components/SendVendorReportsModal/sendVendorReportsButton";
import Table from "components/Table";
import TabRow from "components/TabSelector/TabRow";

import {
  ALL_REPORT_TYPES,
  REPORT_DISPLAY_NAME,
  REPORT_TYPE,
} from "constants/reports";

import { filterAll } from "lib";

import { agentReport } from "./columns";
import { FavouritesTables } from "./ReportFavouritesTables";

const PaddedGrid = styled(Grid)`
  padding: 0 ${({ theme }) => theme.space[1]}px;
`;

const urlParam = "tab";

const {
  columnsGenericFile,
  columnsAgentWithAgency,
  columnsBranches,
  columnsBuyers,
  columnsVendors,
  columnsSaleFiles,
  columnsNLIS,
  columnsAgent,
} = agentReport;

const reportTabStyle = { fontSize: 12, margin: 0, padding: "12px 6px 0 6px" };

export class AgentReports extends Component {
  state = {
    selectedTab:
      queryString.parse(window.location.search)[urlParam] ||
      REPORT_TYPE.FAVOURITES ||
      REPORT_TYPE.AGENCIES,
    filteredData: [],
    businesses: [],
    searchTerm: "",
  };

  componentDidMount() {
    return this.updateStore();
  }

  componentDidUpdate(prevProps) {
    const { reportData, reportFavourites } = this.props;
    if (!isEqual(prevProps.reportData, reportData)) {
      this.updateStore();
    }
    if (!isEqual(prevProps.reportFavourites, reportFavourites)) {
      this.handleFavourites();
    }
  }

  showAgencyFilter = () => {
    const { selectedTab } = this.state;
    return includes(
      [
        REPORT_TYPE.AGENCIES,
        REPORT_TYPE.SALE_FILES,
        REPORT_TYPE.SALE_EXPORT,
        REPORT_TYPE.STUD,
        REPORT_TYPE.CARDS,
      ],
      selectedTab,
    );
  };

  showAgentFilter = () => {
    const { selectedTab } = this.state;
    return includes([REPORT_TYPE.AGENTS], selectedTab);
  };

  updateStore = () => {
    const { reportData, filters } = this.props;
    let { selectedTab } = this.state;
    // If selectedTab isn't in the tab list (ie agencies is default), get the
    // first one that is.  If we don't have any tabs, don't do any updates...
    // they'll come, later, probably.
    if (!reportData[selectedTab] || reportData[selectedTab].length === 0) {
      const tabs = this.getTabs();
      if (tabs.length === 0) {
        return;
      }
      selectedTab = tabs[0].id;
    }

    this.setState({
      filteredData: reportData[selectedTab],
      selectedTab,
      selectedAgency:
        selectedTab === REPORT_TYPE.AGENCIES && filters[selectedTab].length > 0
          ? filters[selectedTab]?.[0]
          : "",
      selectedAgent:
        selectedTab === REPORT_TYPE.AGENTS && filters[selectedTab].length > 0
          ? filters[selectedTab]?.[0]
          : "",
      selectedBusiness:
        includes(
          [
            REPORT_TYPE.AGENCIES,
            REPORT_TYPE.BRANCHES,
            REPORT_TYPE.SALE_FILES,
            REPORT_TYPE.NLIS,
            REPORT_TYPE.SALE_EXPORT,
            REPORT_TYPE.SALEYARD,
          ],
          selectedTab,
        ) || filters[selectedTab]?.length === 0
          ? ""
          : filters[selectedTab]?.[0].value || null,
      businesses:
        includes(
          [
            REPORT_TYPE.AGENCIES,
            REPORT_TYPE.BRANCHES,
            REPORT_TYPE.SALE_FILES,
            REPORT_TYPE.NLIS,
            REPORT_TYPE.SALE_EXPORT,
            REPORT_TYPE.SALEYARD,
          ],
          selectedTab,
        ) || filters[selectedTab]?.length === 0
          ? []
          : filters[selectedTab],
    });
  };

  handleFavourites = () => {
    const { selectedTab } = this.state;
    const { reportFavourites, reportData } = this.props;
    // favourites tab dissapears when no reports are favourited,
    // this is preventing users staring at a blank page
    if (selectedTab === REPORT_TYPE.FAVOURITES && reportFavourites.length < 1) {
      this.setState({
        selectedTab: REPORT_TYPE.AGENCIES,
        filteredData: reportData.agencies,
      });
    }
  };

  handleChangeSearch = searchTerm => {
    const { searchTerm: oldSearchTerm } = this.state;
    if (searchTerm !== oldSearchTerm) {
      this.setState(
        {
          searchTerm,
        },
        () => this.filterData(),
      );
    }
  };

  handleOnChangeFilterBusiness = option => {
    const { selectedBusiness } = this.state;
    if (option.value !== selectedBusiness.value) {
      this.setState(
        {
          selectedBusiness: option,
        },
        () => this.filterData(),
      );
    }
  };

  handleOnChangeFilterAgency = option => {
    const { selectedAgency } = this.state;
    if (option.value !== selectedAgency.value) {
      this.setState(
        {
          selectedAgency: option,
        },
        () => this.filterData(),
      );
    }
  };

  handleOnChangeFilterAgent = option => {
    const { selectedAgent } = this.state;
    if (option.value !== selectedAgent.value) {
      this.setState(
        {
          selectedAgent: option,
        },
        () => this.filterData(),
      );
    }
  };

  filterData = () => {
    const { reportData } = this.props;
    const {
      searchTerm,
      selectedTab,
      selectedBusiness,
      selectedAgency,
      selectedAgent,
    } = this.state;

    // Business with id -1 is "ALL businesses"
    const selectedBusinessValue = selectedBusiness?.value || -1;
    const selectedAgencyValue = selectedAgency?.value;
    const selectedAgentValue = selectedAgent?.value;
    if (this.showAgencyFilter() && selectedAgencyValue) {
      this.setState({
        filteredData: filterAll(
          reportData[selectedTab].filter(
            row => row.agency === selectedAgencyValue,
          ),
          searchTerm,
        ),
      });
    } else if (this.showAgentFilter()) {
      // If we have picked a agent then filter the vendor and reports to show
      if (selectedAgentValue) {
        this.setState({
          filteredData: filterAll(
            reportData[selectedTab].filter(
              row =>
                row.business_name === selectedAgentValue ||
                row.related_agent === selectedAgentValue,
            ),
            searchTerm,
          ),
        });
      } else {
        // Otherwise just show the agents
        this.setState({
          filteredData: reportData[selectedTab].filter(
            row => !row?.related_agent,
          ),
        });
      }
    } else if (
      selectedBusinessValue === -1 ||
      selectedTab === REPORT_TYPE.AGENCIES
    ) {
      this.setState({
        filteredData: filterAll(reportData[selectedTab], searchTerm),
      });
    } else if (
      !includes(
        [
          REPORT_TYPE.BRANCHES,
          REPORT_TYPE.SALE_FILES,
          REPORT_TYPE.NLIS,
          REPORT_TYPE.SALE_EXPORT,
        ],
        selectedTab,
      )
    ) {
      const filteredByBusiness = reportData[selectedTab].filter(
        row => row.business_id === selectedBusinessValue,
      );
      const filterBySearchTerm = filterAll(filteredByBusiness, searchTerm);
      this.setState({
        filteredData: filterBySearchTerm,
      });
    } else {
      this.setState({
        filteredData: reportData[selectedTab],
      });
    }
  };

  handleChangeTab = selected => {
    const { selectedTab } = this.state;
    const { filters } = this.props;
    const url = new URL(window.location);
    url.searchParams.set(urlParam, selected);
    window.history.pushState(null, "", url);
    if (selectedTab !== selected) {
      this.setState(
        {
          selectedTab: selected,
          searchTerm: "",
          selectedAgency:
            !filters[selected] || filters[selected].length === 0
              ? ""
              : filters[selected][0],
          selectedAgent:
            !filters[selected] || filters[selected].length === 0
              ? ""
              : filters[selected][0],
          selectedBusiness:
            includes(
              [
                REPORT_TYPE.AGENCIES,
                REPORT_TYPE.BRANCHES,
                REPORT_TYPE.SALE_FILES,
                REPORT_TYPE.NLIS,
                REPORT_TYPE.SALE_EXPORT,
              ],
              selected,
            ) ||
            !filters[selected] ||
            filters[selected].length === 0
              ? ""
              : filters[selected][0],
          businesses:
            includes(
              [
                REPORT_TYPE.AGENCIES,
                REPORT_TYPE.BRANCHES,
                REPORT_TYPE.SALE_FILES,
                REPORT_TYPE.NLIS,
                REPORT_TYPE.SALE_EXPORT,
              ],
              selected,
            ) ||
            !filters[selected] ||
            filters[selected].length === 0
              ? []
              : filters[selected],
        },
        () => {
          this.filterData();
        },
      );
    }
  };

  selectColumns = (selectedOption, selectedAgent) => {
    if (selectedOption === REPORT_TYPE.AGENCIES) {
      const { filters } = this.props;
      if (filters.agencies.length > 0) {
        return columnsAgentWithAgency;
      } else {
        return columnsGenericFile;
      }
    } else if (selectedOption === REPORT_TYPE.BRANCHES) {
      return columnsBranches;
    } else if (selectedOption === REPORT_TYPE.SALE_FILES) {
      return columnsSaleFiles;
    } else if (selectedOption === REPORT_TYPE.CARDS) {
      return columnsAgentWithAgency;
    } else if (selectedOption === REPORT_TYPE.SALEYARD) {
      return columnsGenericFile;
    } else if (selectedOption === REPORT_TYPE.NLIS) {
      return columnsNLIS;
    } else if (selectedOption === REPORT_TYPE.BUYERS) {
      return columnsBuyers;
    } else if (selectedOption === REPORT_TYPE.SALE_EXPORT) {
      return columnsNLIS;
    } else if (selectedOption === REPORT_TYPE.VENDORS) {
      return columnsVendors;
    } else if (selectedOption === REPORT_TYPE.STUD) {
      return columnsAgentWithAgency;
    } else if (selectedOption === REPORT_TYPE.AGENTS) {
      return columnsAgent(selectedAgent);
    } else {
      return columnsGenericFile;
    }
  };

  displayTable = () => {
    const {
      filteredData,
      selectedBusiness,
      selectedAgency,
      selectedAgent,
      selectedTab,
      searchTerm,
      businesses,
    } = this.state;
    const { filters } = this.props;
    // For columns that have an explicit accessor, if we dont have any keys in our
    // data for that accessor, remove the column.  This is quick workaround for
    // hiding columns that arent required for specific sale types (Clearing sales).
    // TODO: Refactor this into a functional component and do better column
    // definitons based on the sale type.
    const possibleColumns = Object.keys(
      filteredData.reduce((result, obj) => {
        return Object.assign(result, obj);
      }, {}),
    );
    const columns = this.selectColumns(selectedTab, selectedAgent).filter(
      colDef =>
        colDef.accessor ? possibleColumns.includes(colDef.accessor) : true,
    );

    const areFiltersAvailable =
      filters.agencies?.length > 0 || businesses?.length > 0;
    return (
      <>
        <PaddedGrid container spacing={2} alignItems="flex-end">
          {areFiltersAvailable && (
            <Grid item xs={12} sm={6}>
              <Label>Showing reports from</Label>
              {this.showAgencyFilter() && (
                <ReactSelect
                  value={selectedAgency}
                  options={filters.agencies}
                  onChange={this.handleOnChangeFilterAgency}
                  menuPortalTarget={document.body}
                />
              )}
              {businesses?.length > 0 && !this.showAgentFilter() && (
                <ReactSelect
                  value={selectedBusiness}
                  options={businesses}
                  onChange={this.handleOnChangeFilterBusiness}
                  menuPortalTarget={document.body}
                />
              )}
              {this.showAgentFilter() && (
                <ReactSelect
                  value={selectedAgent}
                  options={filters.agents}
                  onChange={this.handleOnChangeFilterAgent}
                  menuPortalTarget={document.body}
                />
              )}
            </Grid>
          )}
          <Grid container item xs={12} sm={6} justifyContent="flex-end">
            <SearchInput
              onChange={this.handleChangeSearch}
              value={searchTerm}
            />
          </Grid>
        </PaddedGrid>

        {selectedTab === REPORT_TYPE.VENDORS && <SendVendorReportsButton />}

        {selectedTab === REPORT_TYPE.FAVOURITES ? (
          <FavouritesTables searchTerm={searchTerm} />
        ) : (
          <Table data={filteredData} columns={columns} suppressNoResult />
        )}
      </>
    );
  };

  getTabs = () => {
    const { reportData, reportFavourites } = this.props;
    const tabs = ALL_REPORT_TYPES.filter(tabId =>
      tabId === REPORT_TYPE.FAVOURITES
        ? reportFavourites?.length
        : reportData[tabId] &&
          (reportData[tabId]?.length || reportData[`${tabId}_force_display`]),
    );

    return tabs.map(tabId => ({
      id: tabId,
      title: REPORT_DISPLAY_NAME[tabId],
      icon: tabId === REPORT_TYPE.FAVOURITES ? faStar : null,
    }));
  };

  render() {
    const { selectedTab } = this.state;
    const { reportData } = this.props;
    const tabs = this.getTabs();

    const displayTable =
      selectedTab !== REPORT_TYPE.FAVOURITES &&
      reportData[selectedTab] &&
      reportData[selectedTab]?.length === 0 &&
      !reportData[`${selectedTab}_force_display`];

    return (
      <MainContentWrapper>
        <TabRow
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={this.handleChangeTab}
          color="#666"
          style={reportTabStyle}
        />

        {displayTable ? (
          <MessageBox>
            Reports will be available once the sale is finalised and confirmed.
          </MessageBox>
        ) : (
          this.displayTable()
        )}
      </MainContentWrapper>
    );
  }
}

AgentReports.propTypes = {
  reportData: PropTypes.shape({
    agencies: PropTypes.array,
    branches: PropTypes.array,
    vendors: PropTypes.array,
    buyers: PropTypes.array,
    sale_files: PropTypes.array,
    nlis: PropTypes.array,
    saleyard: PropTypes.array,
  }),
  filters: PropTypes.shape({
    vendors: PropTypes.array,
    buyers: PropTypes.array,
    agency: PropTypes.array,
    agents: PropTypes.array,
  }),
};

AgentReports.defaultProps = {
  reportData: {
    agencies: [],
    branches: [],
    vendors: [],
    buyers: [],
    sale_files: [],
    nlis: [],
    stud: [],
  },
  filters: {
    vendors: [],
    buyers: [],
    agencies: [],
    agents: [],
  },
};
