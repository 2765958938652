import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";

import { AgGridActionButtons } from "constants/columnDefinitions/actions";

export const actionsColumnRenderer = props => {
  const { data, context } = props;

  if (!data) {
    return null;
  }

  const { openEditAnimal, openEditScans } = context;

  const onEditAnimalClick = () => {
    openEditAnimal(data.scan.EID);
  };
  const onEditScanClick = () => {
    openEditScans(data.scan.EID);
  };

  const showEditAnimal = typeof openEditAnimal === "function";
  const showEditScan = typeof openEditScans === "function";

  if (!showEditAnimal && !showEditScan) {
    return null;
  }

  const actions = [
    {
      onClick: onEditAnimalClick,
      dataTour: "action-edit-animal",
      icon: faPencil,
      title: " Edit Animal",
      hide: !showEditAnimal,
    },
    {
      onClick: onEditScanClick,
      dataTour: `action-edit-scan`,
      icon: faPencil,
      title: " Edit EID",
      hide: !showEditScan,
    },
  ];

  return <AgGridActionButtons actions={actions} />;
};
