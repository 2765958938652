import * as Yup from "yup";

import { requiredWhenSet } from "lib/validation";

export const baseValidationShape = {
  start_pen_prefix: Yup.string().matches(/\D$/, "Must end with a character"),
  start_pen_suffix: Yup.string().matches(/\D/, "Can't contain numbers"),
  end_pen_suffix: Yup.string().matches(/\D/, "Can't contain numbers"),
  end_pen: Yup.number()
    .when("end_pen_suffix", requiredWhenSet)
    .min(Yup.ref("start_pen"), "Must be more than or equal to Start Pen")
    .nullable(),
  start_pen: Yup.number()
    .typeError("Must be a number")
    .when(["start_pen_prefix", "start_pen_suffix", "end_pen", "buyer_id"], {
      is: (prefix, suffix, endPen, buyerId) =>
        prefix || suffix || endPen || buyerId,
      then: Yup.number().required("Start Pen is required"),
    })
    .when("end_pen", {
      is: endPen => endPen !== undefined && endPen !== null,
      then: Yup.number().max(
        Yup.ref("end_pen"),
        "Must be less than or equal to End Pen",
      ),
    })
    .positive("Must be greater than 0")
    .nullable(),
};

export default baseValidationShape;
