import React from "react";

import { useField } from "formik";

import { Input } from "components/Form/FormikControls";

import { getGSTStatusText } from "lib/businesses";

export function GSTField(props) {
  const { name, abrLastChecked } = props;

  const [{ value }] = useField(name);

  return (
    <Input
      disabled
      label="GST Status"
      name={name}
      overrideValue={getGSTStatusText(value, abrLastChecked)}
      tooltip="GST Status is automatically filled from the ABR after adding or updating an ABN."
    />
  );
}
