import React, { memo } from "react";

import PropTypes from "prop-types";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";

import { DeploymentProperty } from "./DeploymentProperty";
import { MasterProperty } from "./MasterProperty";

function PropertyComponent(props) {
  const { highlightedNames, businessId, propertyId } = props;

  if (!propertyId) {
    return null;
  }

  if (businessId) {
    return (
      <WaitForSync requiredData={[ApiModel.BUSINESSES, ApiModel.PROPERTIES]}>
        <DeploymentProperty
          highlightedNames={highlightedNames}
          businessId={businessId}
          propertyId={propertyId}
        />
      </WaitForSync>
    );
  }
  return (
    <WaitForSync requiredData={[ApiModel.BUSINESSES, ApiModel.PROPERTIES]}>
      <MasterProperty
        highlightedNames={highlightedNames}
        propertyId={propertyId}
      />
    </WaitForSync>
  );
}
PropertyComponent.propTypes = {
  highlightedNames: PropTypes.array,
  businessId: PropTypes.string,
  propertyId: PropTypes.string.isRequired,
};

export default memo(PropertyComponent);
