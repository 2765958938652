import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import { SlimSecondaryButton } from "components/Button";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Row } from "components/Layout";

import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";

import {
  getActiveLivestockAgentDeployment,
  getConsignmentIdBySaleLotId,
  getDescriptionBySplitId,
  getVendorIdBySaleLotId,
} from "selectors";

import { useGetBestMatchingVendorSplit } from "hooks/useGetBestMatchingVendorSplit";

function VendorSplitHeader({ splitDescription }) {
  return (
    <Row>
      <SubtleBadge>Vendor Splits</SubtleBadge>
      <CollapseLabel>{splitDescription}</CollapseLabel>
    </Row>
  );
}

export const VendorSplitCollapse = ({ isOpen, onToggle, saleLotId }) => {
  const vendorId = useSelector(getVendorIdBySaleLotId(saleLotId));

  const primaryBusinessId = useSelector(getVendorIdBySaleLotId(saleLotId));
  const deploymentId = useSelector(getActiveLivestockAgentDeployment)?.id;
  const consignmentId = useSelector(getConsignmentIdBySaleLotId(saleLotId));

  const split = useGetBestMatchingVendorSplit({
    primaryBusinessId,
    deploymentId,
    consignmentId,
    saleLotId,
  });
  const splitDescription = useSelector(getDescriptionBySplitId(split?.id));

  const onClickCreateVendorSplit = () =>
    openModalLink(ModalTypes.VendorSplit, {
      saleLotId,
      primaryBusinessId: vendorId,
    });

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<VendorSplitHeader splitDescription={splitDescription} />}
      dataTour={isOpen ? "hideVendorSplits" : "showVendorSplits"}
    >
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <SlimSecondaryButton
            type="button"
            onClick={onClickCreateVendorSplit}
            data-tour="createVendorSplit"
          >
            {split ? "Edit" : "Create"} Vendor Split
          </SlimSecondaryButton>
        </Grid>
      </Grid>
    </FormCollapse>
  );
};
