import queryString from "query-string";

import { INTEGRATION_CREDENTIAL } from "constants/actionTypes";

import { serializeIntegrationBusiness } from "lib/serializers/integrationBusinesses";
import { serializeIntegrationCredential } from "lib/serializers/integrationCredentials";

import {
  offlineActionCreator,
  offlineSubActionCreator,
  urlCheckAndAddIdAndAction,
} from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/integration-credentials/", id, action),
    query: {
      changesSince,
    },
  });

export const IntegrationCredentialAction = offlineActionCreator(
  INTEGRATION_CREDENTIAL,
  serializeIntegrationCredential,
  urlCreator,
  false,
);

IntegrationCredentialAction.activate = (integrationCredentialId, tenantId) => ({
  type: INTEGRATION_CREDENTIAL.ACTIVATE.REQUEST,
  id: integrationCredentialId,
  tenantId,
});

IntegrationCredentialAction.syncLedgerAccounts = (
  integrationCredentialId,
  ignoredOptions,
) =>
  IntegrationCredentialAction.action(
    INTEGRATION_CREDENTIAL.SYNC_LEDGER_ACCOUNTS,
    offlineSubActionCreator(
      INTEGRATION_CREDENTIAL.SYNC_LEDGER_ACCOUNTS,
      urlCreator,
    ),
    { id: integrationCredentialId, action: "sync-ledger-accounts" },
  );

const pushLedgerAccountUrlCreator = ({
  changesSince,
  id,
  action,
  ledgerAccountIds,
} = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/integration-credentials/", id, action),
    query: {
      changesSince,
      ledger_account_ids: ledgerAccountIds,
    },
  });

IntegrationCredentialAction.pushLedgerAccount = (
  integrationCredentialId,
  ledgerAccountIds,
) => {
  return IntegrationCredentialAction.action(
    INTEGRATION_CREDENTIAL.PUSH_LEDGER_ACCOUNTS,
    offlineSubActionCreator(
      INTEGRATION_CREDENTIAL.PUSH_LEDGER_ACCOUNTS,
      pushLedgerAccountUrlCreator,
    ),
    {
      id: integrationCredentialId,
      action: "push-ledger-accounts",
      ledgerAccountIds,
    },
  );
};

IntegrationCredentialAction.createTaxRates = (
  integrationCredentialId,
  ignoredOptions,
) => ({
  type: INTEGRATION_CREDENTIAL.CREATE_TAX_RATES.REQUEST,
  meta: {
    offline: {
      effect: {
        url: urlCreator({
          id: integrationCredentialId,
          action: "create-tax-rates",
        }),
        method: "POST",
      },
      commit: {
        type: INTEGRATION_CREDENTIAL.CREATE_TAX_RATES.SUCCESS,
      },
      rollback: {
        type: INTEGRATION_CREDENTIAL.CREATE_TAX_RATES.FAILURE,
      },
    },
  },
});

IntegrationCredentialAction.loadBusinesses = (
  integrationCredentialId,
  ignoredOptions,
) =>
  IntegrationCredentialAction.action(
    INTEGRATION_CREDENTIAL.LOAD_BUSINESSES,
    offlineSubActionCreator(INTEGRATION_CREDENTIAL.LOAD_BUSINESSES, urlCreator),
    { id: integrationCredentialId, action: "load-businesses" },
  );

IntegrationCredentialAction.syncDocuments = (
  integrationCredentialId,
  ignoredOptions,
) =>
  IntegrationCredentialAction.action(
    INTEGRATION_CREDENTIAL.SYNC_DOCUMENTS,
    offlineSubActionCreator(INTEGRATION_CREDENTIAL.SYNC_DOCUMENTS, urlCreator),
    { id: integrationCredentialId, action: "sync-documents" },
  );

IntegrationCredentialAction.createBusiness = (
  integrationCredentialId,
  deserializedPayload,
) => {
  const payload = serializeIntegrationBusiness(deserializedPayload);
  return IntegrationCredentialAction.action(
    INTEGRATION_CREDENTIAL.CREATE_BUSINESS,
    offlineSubActionCreator(
      INTEGRATION_CREDENTIAL.CREATE_BUSINESS,
      urlCreator,
      "POST",
    ),
    { id: integrationCredentialId, action: "create-business", ...payload },
  );
};
