import * as React from "react";
import { useEffect } from "react";

import { getIn, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  OptionTogglerField,
  SelectField,
} from "components/Form/FormikControls";

import {
  getAgenciesOptions,
  getCurrentAgenciesOptions,
  getIsLivestockAgent,
} from "selectors";

export const AgencyInputField = ({
  name,
  required,
  isCurrentSaleOnly = false,
  disabled = false,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const allAgencies = useSelector(getAgenciesOptions(false));
  const saleAgencies = useSelector(getCurrentAgenciesOptions(false));
  const isLivestockAgent = useSelector(getIsLivestockAgent);

  // If the user is a livestock agent, they should only see their agencies.
  const agencies = isLivestockAgent
    ? saleAgencies
    : isCurrentSaleOnly
      ? saleAgencies
      : allAgencies;

  const selectedAgency = getIn(values, name);
  const agencyIsSet = Boolean(selectedAgency);

  // Automatically select the first Agency when that is the only one available, and non have been selected previously
  useEffect(() => {
    if (!agencyIsSet && agencies.length === 1) {
      setFieldValue(name, agencies[0].value);
    }
  }, [agencies, agencyIsSet, name, setFieldValue]);

  // Don't show any form of picker when something has been picked and there are no other options
  if (agencyIsSet && agencies.length === 1) {
    return null;
  }

  return agencies.length <= 3 ? (
    <OptionTogglerField
      label="Agency"
      name={name}
      labelPosition="top"
      options={agencies}
      required={required}
      disabled={disabled}
    />
  ) : (
    <SelectField
      label="Agency"
      name={name}
      options={agencies}
      required={required}
      menuPortalTarget={document.body}
      disabled={disabled}
    />
  );
};
AgencyInputField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  isCurrentSaleOnly: PropTypes.bool,
};
