import React, { useCallback } from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import AgGrid from "components/AgGrid/AgGrid";
import AgGridTable from "components/AgGrid/AgGridContainer";
import { ModalTableWrapper } from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { columnDefs } from "components/Sundry/Table/columns";

import { AgGridTables } from "constants/aggrid";
import { ApiModel } from "constants/loading";
import { BusinessModalSection, ModalTypes } from "constants/navigation";

import { openEditBusinessModal, openModalLink } from "lib/navigation";

import {
  selectActiveLivestockAgentDeploymentMasterBusinessId,
  selectManualAdjustmentsData,
} from "selectors";

const getRowId = params => params.data.manualAdjustment.id;

export const Table = () => {
  const additionalActions = [
    {
      title: "Add",
      icon: faPlus,
      onClick: () => openModalLink(ModalTypes.ManualAdjustmentForm),
      default: true,
      dataTour: "add-manual-charge",
    },
  ];
  const rowData = useSelector(selectManualAdjustmentsData);

  const activeLivestockAgentDeploymentMasterBusinessId = useSelector(
    selectActiveLivestockAgentDeploymentMasterBusinessId,
  );

  const getContextMenuItems = useCallback(
    params => {
      const contextMenu = Array.isArray(params.defaultItems)
        ? [...params.defaultItems]
        : [];
      contextMenu.push({
        name: "Add Relationship",
        action: () =>
          openEditBusinessModal(
            activeLivestockAgentDeploymentMasterBusinessId,
            undefined,
            BusinessModalSection.BUSINESS_RELATIONSHIPS,
          ),
      });

      return contextMenu;
    },
    [activeLivestockAgentDeploymentMasterBusinessId],
  );

  return (
    <WaitForSync requiredData={[ApiModel.BUSINESSES]}>
      <AgGridTable
        additionalActions={additionalActions}
        columnDefs={columnDefs}
        getRowId={getRowId}
        rowData={rowData}
        tableName={AgGridTables.MANUAL_ADJUSTMENT}
        showGlobalSearchFilter={false}
        getContextMenuItems={getContextMenuItems}
        headerJustifyContent="space-between"
      />
    </WaitForSync>
  );
};

export const Modal = ({ onClose }) => {
  const rowData = useSelector(selectManualAdjustmentsData);

  return (
    <Dialog fullWidth fullScreen maxWidth="lg" open onClose={onClose}>
      <DialogTitle onClose={onClose}>Sundry</DialogTitle>
      <DialogContent dividers>
        <WaitForSync requiredData={[ApiModel.BUSINESSES]}>
          <ModalTableWrapper>
            <AgGrid
              columnDefs={columnDefs}
              getRowId={getRowId}
              rowData={rowData}
              tableName={AgGridTables.MANUAL_ADJUSTMENT}
            />
          </ModalTableWrapper>
        </WaitForSync>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
