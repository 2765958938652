export const SundryTemplateLabel = {
  RCTI_DEPLOYMENT: "RCTI_DEPLOYMENT",
  INVOICE_DEPLOYMENT: "INVOICE_DEPLOYMENT",
  DEPLOYMENT_RCTI: "DEPLOYMENT_RCTI",
  DEPLOYMENT_INVOICE: "DEPLOYMENT_INVOICE",
  RCTI_INVOICE: "RCTI_INVOICE",
  INVOICE_RCTI: "INVOICE_RCTI",
  RCTI_RCTI: "RCTI_RCTI",
  INVOICE_INVOICE: "INVOICE_INVOICE",
};

export const SundryPartyType = {
  DEPLOYMENT: "Deployment",
  INVOICE: "Invoice",
  RCTI: "RCTI",
};

export const sundryPartyTypeOptions = Object.entries(SundryPartyType).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export const SundryPartyTypeLookup = {
  [SundryTemplateLabel.RCTI_DEPLOYMENT]: {
    fromType: SundryPartyType.RCTI,
    toType: SundryPartyType.DEPLOYMENT,
  },
  [SundryTemplateLabel.INVOICE_DEPLOYMENT]: {
    fromType: SundryPartyType.INVOICE,
    toType: SundryPartyType.DEPLOYMENT,
  },
  [SundryTemplateLabel.DEPLOYMENT_RCTI]: {
    fromType: SundryPartyType.DEPLOYMENT,
    toType: SundryPartyType.RCTI,
  },
  [SundryTemplateLabel.DEPLOYMENT_INVOICE]: {
    fromType: SundryPartyType.DEPLOYMENT,
    toType: SundryPartyType.INVOICE,
  },
  [SundryTemplateLabel.RCTI_INVOICE]: {
    fromType: SundryPartyType.RCTI,
    toType: SundryPartyType.INVOICE,
  },
  [SundryTemplateLabel.INVOICE_RCTI]: {
    fromType: SundryPartyType.INVOICE,
    toType: SundryPartyType.RCTI,
  },
  [SundryTemplateLabel.RCTI_RCTI]: {
    fromType: SundryPartyType.RCTI,
    toType: SundryPartyType.RCTI,
  },
  [SundryTemplateLabel.INVOICE_INVOICE]: {
    fromType: SundryPartyType.INVOICE,
    toType: SundryPartyType.INVOICE,
  },
};

export const MANUAL_TEMPLATE_NAME = "Manual";
export const MANUAL_TEMPLATE_ID = -1;
