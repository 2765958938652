import React, { useMemo } from "react";

import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { updateDeploymentAttribute, updateDeploymentAttributes } from "actions";

import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";

import { UPDATE_DEPLOYMENT_LABELS } from "constants/actionTypes";
import { ACTION_COLUMN_NAME } from "constants/aggrid";
import { DeploymentPermissions } from "constants/permissions";

import ExtendableTable from "containers/Settings/ExtendableTable";
import { HeaderBulkSelectCellRenderer } from "containers/Settings/Renderers";

import { isAgriNousManagedGetter } from "lib/agGrid/getters";
import { AgriNousManagedRenderer } from "lib/agGrid/renderers/tickRenderer";

import { getActiveDeploymentAttributes, getIsLivestockAgent } from "selectors";

import { useHasDeploymentPermission } from "hooks";

import { labelActionsColumnRenderer } from "./actions";

function getIsNameEditable({ data }) {
  // Name is editable if it's not a agrinous managed label.
  return !get(data, "master_label_id");
}

const baseColumnDefs = [
  {
    headerName: "AgriNous Managed?",
    field: "master_label_id",
    rowDrag: true,
    valueGetter: isAgriNousManagedGetter,
    cellRenderer: AgriNousManagedRenderer,
    editable: false,
  },

  { headerName: "Name", field: "name", editable: getIsNameEditable },
  {
    headerName: "Sale Lot",
    field: "quick_select",
    cellRenderer: "checkboxRenderer",
    editable: false,
    headerComponentFramework: HeaderBulkSelectCellRenderer,
  },
  {
    headerName: "Sundries",
    field: "is_manual_charge_label",
    cellRenderer: "checkboxRenderer",
    editable: false,
    headerComponentFramework: HeaderBulkSelectCellRenderer,
  },
  {
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: labelActionsColumnRenderer,
    editable: false,
    valueGetter: () => null,
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
});

const blankRowDefaults = { name: "", is_used: true, quick_select: true };

export const LabelSettings = () => {
  const [deleteLabel, setDeleteLabel] = React.useState(null);
  const deployments = useSelector(getActiveDeploymentAttributes);
  const deploymentData = deployments.labels;
  const dispatch = useDispatch();

  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const hasBillingPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureBilling,
  );

  const showBillingColumn = hasBillingPermission && isLivestockAgent;

  const columnDefs = useMemo(
    () =>
      baseColumnDefs.filter(
        column =>
          showBillingColumn || column.field !== "is_manual_charge_label",
      ),
    [showBillingColumn],
  );

  const handleOpenDeleteDialog = label => setDeleteLabel(label);

  const handleCloseDeleteDialog = () => setDeleteLabel(null);

  const handleDeleteLabel = () => {
    const labelPayload = {
      ...deleteLabel,
      deprecated: true,
    };
    dispatch(updateDeploymentAttribute(UPDATE_DEPLOYMENT_LABELS, labelPayload));
  };

  const onDelete = payload => {
    handleDeleteLabel(payload);
    handleCloseDeleteDialog();
  };

  const handleUpdate = payload => {
    const labelsPayload = payload.map(p => ({
      id: p.id,
      order: p.order,
      deployment_id: p.deployment_id,
      species_id: p.species_id,
      is_used: p.is_used || false,
      code: p.code,
      name: p.name,
      quick_select: p.quick_select || false,
      // Don't touch this if we don't have the billing permission (eg a field agent vs office staff)
      is_manual_charge_label: showBillingColumn
        ? p.is_manual_charge_label || false
        : undefined,

      deprecated: false,
    }));

    dispatch(
      updateDeploymentAttributes(UPDATE_DEPLOYMENT_LABELS, labelsPayload),
    );
  };

  return (
    <>
      <ExtendableTable
        deploymentData={deploymentData}
        handleUpdate={handleUpdate}
        columnDefs={columnDefs}
        context={{ handleOpenDeleteDialog }}
        validationSchema={validationSchema}
        blankRowDefaults={blankRowDefaults}
      />
      <ConfirmDialog
        title={createModalTitle("this label")}
        isOpen={!!deleteLabel}
        onCancel={handleCloseDeleteDialog}
        onDelete={onDelete}
      />
    </>
  );
};
