import React, { memo, useContext } from "react";

import queryString from "query-string";

import {
  WeighBridgeSaleContextProvider,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";
import WeighbridgeSaleView from "./WeighBridgeSaleView";

function WeighBridgeSaleStateConsumer() {
  const { saleMode, initialSaleLotId, weightCaptureMode } = useContext(
    WeighBridgeSaleStateContext,
  );
  return (
    <WeighbridgeSaleView
      initialSaleLotId={initialSaleLotId}
      saleMode={saleMode}
      weightCaptureMode={weightCaptureMode}
    />
  );
}

function WeighbridgeSaleViewRouteComponent() {
  const { saleLotId } = queryString.parse(window.location.search);

  return (
    <WeighBridgeSaleContextProvider initialSaleLotId={saleLotId}>
      <WeighBridgeSaleStateConsumer />
    </WeighBridgeSaleContextProvider>
  );
}

export default memo(WeighbridgeSaleViewRouteComponent, () => true);
