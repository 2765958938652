import { get } from "lodash";

import { PricingTypes } from "constants/pricingTypes";

const unitPriceHeader = params => {
  const { api } = params;
  const { rowModel } = api;

  if (rowModel.getRowCount() > 0) {
    const defaultPricingType = get(
      rowModel.getRow(0).data,
      "livestockSale.pricing_type_id",
    );
    return PricingTypes.toString(defaultPricingType);
  } else {
    return "Unit Price";
  }
};

export default { unitPriceHeader };
