import React from "react";

import {
  faQuestionCircle,
  faExclamationCircle,
  faTimesCircle,
  faCheckCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { EID_TRANSFER_STATUS, logoStatus } from "constants/nlis";
import { colors } from "constants/theme";

import NLISLogoImage from "img/NLIS-logo.png";

const getStatusObject = status => {
  switch (status) {
    case logoStatus[EID_TRANSFER_STATUS.SUCCESS]: {
      return {
        icon: faCheckCircle,
        color: colors.successGreen,
      };
    }
    case logoStatus[EID_TRANSFER_STATUS.ERROR]: {
      return {
        icon: faTimesCircle,
        color: colors.errorRed,
      };
    }
    case logoStatus[EID_TRANSFER_STATUS.WARNING]: {
      return {
        icon: faExclamationCircle,
        color: colors.warningOrange,
      };
    }
    case logoStatus[EID_TRANSFER_STATUS.PENDING]: {
      return {
        icon: faQuestionCircle,
        color: colors.gray8C,
      };
    }
    case logoStatus[EID_TRANSFER_STATUS.ROLLED_BACK]: {
      return {
        icon: faUndo,
        color: colors.gray8C,
      };
    }
    default:
      return undefined;
  }
};

const NLISLogo = ({ status }) => {
  const statusObject = getStatusObject(status);
  return (
    <Wrapper data-tour="nlisLogo">
      <NLISImg />
      {statusObject ? <StatusIcon {...statusObject} /> : null}
    </Wrapper>
  );
};

export default NLISLogo;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const NLISImg = styled.img.attrs({
  src: NLISLogoImage,
  alt: "NLIS-Logo",
})`
  height: 28px;
  width: 44px;
`;

export const StatusIcon = styled(FontAwesomeIcon).attrs(props => ({
  icon: props.icon,
}))`
  position: absolute;
  top: -13px;
  right: -10px;
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
  color: ${({ color }) => color};
`;
