import React, { createContext, useCallback, useMemo, useReducer } from "react";

import { formatWeightKg } from "lib";

import toast from "lib/toast";

const STATE_ACTION = {
  ADD_ACCUMULATED_WEIGHT: 1,
  EIDS_CHANGED: 2,
  INDICATED_WEIGHT_CHANGED: 4,
  RESET: 8,
  SALE_LOT_CHANGED: 16,
  SELECTED_SALE_LOT_ID_CHANGED: 32,
  WEIGHT_CHANGED: 64,
  FILTER_CHANGED: 128,
  RECENTLY_WEIGHED_SALE_LOT_IDS_CHANGED: 256,
  REMOVE_ACCUMULATED_WEIGHT: 512,
  CLEAR_ACCUMULATED_WEIGHTS: 1024,
  SALE_LOT_SELLING_DETAILS_CHANGED: 2048,
  CLEAR_SALE_LOT_FORM_DIRTY: 4096,
  UPDATE_STORED_DRAFTING_ATTRIBUTES: 8192,
};

function weighBridgeSaleStateReducer(oldState, action) {
  const state = { ...oldState };

  const updatedSellingDetails = action.sellingDetails || {};
  const existingSellingDetails = state.sellingDetails || {};

  // when a buyer or price is updated the key "price" or "buyer" will be added to the
  // action.sellingDetails. If the field is not touched it wont exist within the object.
  // so to check for the latest value we check the action for the keys, then check state for keys
  // to get the value

  const price =
    "price" in updatedSellingDetails
      ? updatedSellingDetails.price
      : existingSellingDetails.price;

  const buyer =
    "buyerId" in updatedSellingDetails
      ? updatedSellingDetails.buyerId
      : existingSellingDetails.buyerId;

  const buyerAndPriceIsValid = price && price > 0 ? !!buyer : true;

  switch (action.type) {
    case STATE_ACTION.ADD_ACCUMULATED_WEIGHT:
      toast.success(`Captured ${formatWeightKg(action.accumulatedWeight)}`);
      state.accumulatedWeights = [
        ...state.accumulatedWeights,
        action.accumulatedWeight,
      ];
      state.isAccumulatedWeightsDirty = true;
      state.isAccumulatedWeightsValid = state.accumulatedWeights.every(
        weight => weight >= 0,
      );
      break;

    case STATE_ACTION.CLEAR_ACCUMULATED_WEIGHTS:
      state.accumulatedWeights = [];
      state.isAccumulatedWeightsDirty = false;
      state.isAccumulatedWeightsValid = true;
      break;
    case STATE_ACTION.EIDS_CHANGED:
      state.eids = action.eids;
      state.isEidsDirty = action.setDirty;
      state.isEidsValid = action.isValid;
      break;
    case STATE_ACTION.FILTER_CHANGED:
      state.filters = action.filters;
      break;
    case STATE_ACTION.INDICATED_WEIGHT_CHANGED:
      state.indicatedWeight = action.indicatedWeight;
      break;
    case STATE_ACTION.RECENTLY_WEIGHED_SALE_LOT_IDS_CHANGED:
      state.recentlyWeighedSaleLotIds = [
        action.recentlyWeighedSaleLotIds,
        ...state.recentlyWeighedSaleLotIds.slice(0, 4),
      ];
      break;
    case STATE_ACTION.REMOVE_ACCUMULATED_WEIGHT:
      state.accumulatedWeights = state.accumulatedWeights.slice();
      state.accumulatedWeights.splice(action.index, 1);
      state.isAccumulatedWeightsDirty = state.accumulatedWeights.length > 0;
      state.isAccumulatedWeightsValid = state.accumulatedWeights.every(
        weight => weight >= 0,
      );
      break;
    case STATE_ACTION.RESET:
      state.accumulatedWeights = [];
      state.isAccumulatedWeightsDirty = false;
      state.isAccumulatedWeightsValid = true;
      state.indicatedWeight = 0;
      state.key = Date.now(); // Used for force a remount of the Formik component
      state.saleLot = null;
      state.selectedSaleLotId = null;
      state.sellingDetails = {};
      state.isSaleLotDirty = false;
      state.isSaleLotValid = false;
      state.isSellingDetailsDirty = false;
      break;
    case STATE_ACTION.SALE_LOT_CHANGED:
      state.saleLot = action.saleLot;
      state.isSaleLotDirty = action.setDirty;
      state.isSaleLotValid = action.isValid;
      // if lot has passed validation - check price and buyer info
      if (state.isSaleLotValid) {
        state.isSaleLotValid = buyerAndPriceIsValid;
      }
      break;
    case STATE_ACTION.SALE_LOT_SELLING_DETAILS_CHANGED:
      const nextSellingDetails = [
        "buyerId",
        "buyerWayId",
        "price",
        "pricingTypeId",
        "propertyId",
      ].reduce(
        (sellingDetails, key) => {
          const property = action.sellingDetails[key];
          if (property !== undefined) {
            sellingDetails[key] = property;
          }
          return sellingDetails;
        },
        { ...state.sellingDetails },
      );
      state.sellingDetails = nextSellingDetails;
      state.isSellingDetailsDirty = action.setDirty;
      state.isSaleLotDirty = action.setDirty;
      // validate buyer and price info
      state.isSaleLotValid = buyerAndPriceIsValid;
      break;

    case STATE_ACTION.UPDATE_STORED_DRAFTING_ATTRIBUTES:
      state.areDraftingAttributesRemembered =
        action.areDraftingAttributesRemembered;
      state.storedDraftingAttributes = action.storedDraftingAttributes;
      break;

    case STATE_ACTION.SELECTED_SALE_LOT_ID_CHANGED:
      if (
        Boolean(action.selectedSaleLotId) &&
        state.selectedSaleLotId === action.selectedSaleLotId
      ) {
        return oldState;
      }
      state.selectedSaleLotId = action.selectedSaleLotId;
      state.isSaleLotDirty = false;
      state.isSaleLotValid = false;
      state.isSellingDetailsDirty = false;
      state.saleLot = null;
      state.sellingDetails = {};
      state.key = Date.now();
      break;
    case STATE_ACTION.CLEAR_SALE_LOT_FORM_DIRTY:
      state.isSaleLotDirty = false;
      state.isSaleLotValid = false;
      state.isSellingDetailsDirty = false;
      break;

    default:
      // eslint-disable-next-line no-console
      console.error("Unknown action type %s", action.type, action);
      return oldState;
  }
  return state;
}

/**
 * "Higher order function" which creates a memoised dispatch action function, bound to an action.
 * This function must only be called inside of a React Functional Component
 * @param {React.Dispatch} dispatch
 * @param {string} valueName
 * @param {STATE_ACTION} action
 */
function useMemoDispatch(dispatch, valueName, action) {
  /* eslint-disable */
  return useMemo(
    () => value => dispatch({ type: action, [valueName]: value }),
    [], // dispatch is guaranteed by React to have referential equality, avoid unnecessary dep evaluation
  );
  /* eslint-enable */
}

export const WeighBridgeSaleStateContext = createContext(undefined, undefined);
export const WeighBridgeSaleDispatchContext = createContext(
  undefined,
  undefined,
);
const { Provider: StateProvider } = WeighBridgeSaleStateContext;
const { Provider: DispatchProvider } = WeighBridgeSaleDispatchContext;

export function WeighBridgeSaleContextProvider(props) {
  const { children, initialSaleLotId } = props;

  const [state, dispatch] = useReducer(
    weighBridgeSaleStateReducer,
    {
      accumulatedWeights: [],
      areDraftingAttributesRemembered: false,
      isAccumulatedWeightsDirty: false,
      isAccumulatedWeightsValid: true,
      storedDraftingAttributes: null,
      eids: [],
      isEidsDirty: false,
      isEidsValid: true,
      indicatedWeight: 0,
      key: Date.now(), // used to remount the Inline Create Sale Lot Formik Form on submission
      filters: { keyword: "", penned: true },
      recentlyWeighedSaleLotIds: [],
      saleLot: null, // used to store the values from the Inline Create Sale Lot Formik Form
      selectedSaleLotId: initialSaleLotId || null,
      sellingDetails: {},
      isSellingDetailsDirty: false,
      isSaleLotDirty: false,
      isSaleLotValid: false,
    },
    undefined,
  );

  const addAccumulatedWeight = useMemoDispatch(
    dispatch,
    "accumulatedWeight",
    STATE_ACTION.ADD_ACCUMULATED_WEIGHT,
  );
  // eslint-disable-next-line
  const clearAccumulatedWeights = useMemo(
    () => () => dispatch({ type: STATE_ACTION.CLEAR_ACCUMULATED_WEIGHTS }),
    [],
  );
  const removeAccumulatedWeight = useMemoDispatch(
    dispatch,
    "index",
    STATE_ACTION.REMOVE_ACCUMULATED_WEIGHT,
  );
  // eslint-disable-next-line
  const reset = useCallback(() => dispatch({ type: STATE_ACTION.RESET }), []);
  const updateEids = useCallback(
    (eids, isValid, setDirty = true) =>
      dispatch({
        type: STATE_ACTION.EIDS_CHANGED,
        eids,
        setDirty,
        isValid,
      }),
    [],
  );
  const updateFilters = useMemoDispatch(
    dispatch,
    "filters",
    STATE_ACTION.FILTER_CHANGED,
  );
  const updateIndicatedWeight = useMemoDispatch(
    dispatch,
    "indicatedWeight",
    STATE_ACTION.INDICATED_WEIGHT_CHANGED,
  );
  const updateRecentlyWeighedSaleLotIds = useMemoDispatch(
    dispatch,
    "recentlyWeighedSaleLotIds",
    STATE_ACTION.RECENTLY_WEIGHED_SALE_LOT_IDS_CHANGED,
  );
  const contextUpdateSaleLot = useCallback(
    (saleLot, isValid = false, setDirty = true) =>
      dispatch({
        type: STATE_ACTION.SALE_LOT_CHANGED,
        saleLot,
        setDirty,
        isValid,
      }),
    [],
  );
  const updateSaleLotSellingDetails = useCallback(
    (sellingDetails, setDirty = true) =>
      dispatch({
        type: STATE_ACTION.SALE_LOT_SELLING_DETAILS_CHANGED,
        sellingDetails,
        setDirty,
      }),
    [],
  );
  const updateSelectedSaleLotId = useMemoDispatch(
    dispatch,
    "selectedSaleLotId",
    STATE_ACTION.SELECTED_SALE_LOT_ID_CHANGED,
  );

  const clearSaleLotFormDirty = useMemoDispatch(
    dispatch,
    "clearSaleLotFormDirty",
    STATE_ACTION.CLEAR_SALE_LOT_FORM_DIRTY,
  );

  const updateStoredDraftingAttributes = useCallback(
    (storedDraftingAttributes, areDraftingAttributesRemembered) =>
      dispatch({
        type: STATE_ACTION.UPDATE_STORED_DRAFTING_ATTRIBUTES,
        areDraftingAttributesRemembered,
        storedDraftingAttributes,
      }),
    [],
  );

  /* eslint-disable */
  // eslint has a fit about the dependency list, but React guarantees referential equality
  const dispatchValue = useMemo(
    () => ({
      dispatch,
      reset,
      addAccumulatedWeight,
      clearAccumulatedWeights,
      removeAccumulatedWeight,
      updateEids,
      updateFilters,
      updateIndicatedWeight,
      updateRecentlyWeighedSaleLotIds,
      contextUpdateSaleLot,
      updateSaleLotSellingDetails,
      updateSelectedSaleLotId,
      updateStoredDraftingAttributes,
      clearSaleLotFormDirty,
    }),
    [], // Once these are created, they are never going to change for this instance of the context
  );
  /* eslint-enable */

  return (
    <DispatchProvider value={dispatchValue}>
      <StateProvider value={state}>{children}</StateProvider>
    </DispatchProvider>
  );
}
