import React from "react";

import { PropTypes } from "prop-types";

import { sortPenByOrder } from "lib/auctionPens";
import {
  createAndOpenPdf,
  getSaleAndDocumentInformation,
  getPenDetails,
  getQuantityDetails,
} from "lib/pdf";

const columnWidths = [45, 32, 45, "*"];
const pageMargin = 12;
const pageMarginBottom = 40;
const rowsPerColumn = 9;
const columnsPerPage = 3;

const getPensByColumnsAndPages = auctionPens => {
  let page = 0;
  let column = 0;
  let row = 0;
  const pages = [[[]]];

  sortPenByOrder(auctionPens).forEach((auctionPen, i) => {
    // if new lane or full page, add new page
    if (
      (i > 0 && auctionPen.isLaneStart) ||
      (column >= columnsPerPage - 1 && row >= rowsPerColumn)
    ) {
      page += 1;
      column = 0;
      row = 0;
      pages.push([[]]);
      // if full column, new column
    } else if (row >= rowsPerColumn) {
      column += 1;
      row = 0;
      pages[page].push([]);
    }

    pages[page][column].push(auctionPen);
    row += 1;
  });
  return pages;
};

const getContent = auctionPens =>
  getPensByColumnsAndPages(auctionPens).map((page, i) => ({
    pageBreak: i > 0 ? "before" : null,
    columnGap: 10,
    columns: page.map(column => ({
      width: "33%",
      table: {
        widths: columnWidths,
        heights(row) {
          return row === 0 ? "auto" : 48;
        },
        body: [
          [
            { text: "PEN", style: "tableHeader" },
            { text: "HC", style: "tableHeader" },
            { text: "PRICE", style: "tableHeader" },
            { text: "BUYER", style: "tableHeader" },
          ],
          ...column.map(auctionPen => [
            getPenDetails(auctionPen),
            getQuantityDetails(auctionPen),
            "",
            "",
          ]),
        ],
      },
      layout: {
        hLineColor: "#D0021B",
        vLineColor: "#D0021B",
      },
    })),
  }));

export const CheckingCard = ({
  auctionPens,
  sale,
  agencyName,
  round,
  history,
}) => {
  const pdfDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [pageMargin, pageMargin, pageMargin, pageMarginBottom],
    content: getContent(auctionPens),
    footer: getSaleAndDocumentInformation(
      auctionPens,
      sale,
      agencyName,
      round,
      pageMargin,
    ),
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 11,
      },
      mainText: {
        bold: true,
        fontSize: 16,
      },
      smallText: {
        fontSize: 12,
      },
    },
  };

  createAndOpenPdf(pdfDefinition, history);

  return <div />;
};

CheckingCard.propTypes = {
  auctionPens: PropTypes.array,
  sale: PropTypes.object,
  history: PropTypes.object,
  agencyName: PropTypes.string,
  round: PropTypes.object,
};
