import React from "react";

import { AuditLogModal } from "components/AuditLog/AuditLogModal";

import { ModalTypes } from "constants/navigation";

function AuditLogModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.AuditLog] || null;
  const [dataType, dataId, queryParams] = JSON.parse(hashParams);
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <AuditLogModal
      dataType={dataType}
      dataId={dataId}
      returnTo={decodedReturnTo}
      queryParams={queryParams}
    />
  );
}

export default AuditLogModalAdapter;
