import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Divider, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { SubtleBadge } from "components/Badge";
import { Warning } from "components/ErrorMessage";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Row } from "components/Layout";
import PenningSaleLotForm from "components/PenningSaleLotForm";
import SaleLotOverflowForm from "components/PenningSaleLotForm/SaleLotOverflowForm";

import { PenTypes } from "constants/auctionPens";
import { auctionPenActions } from "constants/sale";

import { getExpandedAuctionPenDisplayName } from "lib/auctionPens";
import { sectionHasErrors } from "lib/form";
import { formatSaleLotOverflow, getBuyerHashFromSaleLot } from "lib/saleLot";

import { getIsAnimalsInAutoDraftBySaleLotId } from "selectors";

export const PenCollapse = ({
  isOpen,
  commonProps,
  onToggle,
  action,
  readOnly,
}) => {
  const { values } = commonProps;
  const { id, overflowPen, overflowQuantity, sale_round_id, auction_pen } =
    values;

  const isAnimalsInAutoDraft = useSelector(
    getIsAnimalsInAutoDraftBySaleLotId(id),
  );

  const hasErrorBadge = sectionHasErrors(commonProps, [
    "auction_pen.start_pen_prefix",
    "auction_pen.start_pen",
    "auction_pen.start_pen_suffix",
    "auction_pen.end_pen",
    "auction_pen.end_pen_suffix",
    "overflowPen",
    "overflowQuantity",
  ]);
  const isTempPen = auction_pen.penType === PenTypes.TEMP;
  const buyerHash = getBuyerHashFromSaleLot(values);

  const penName = getExpandedAuctionPenDisplayName(values.auction_pen || {});
  const overflowDisplayValue = formatSaleLotOverflow(
    overflowPen,
    overflowQuantity,
  );

  const header = (
    <Row data-tour="Pen">
      <SubtleBadge hasErrorBadge={hasErrorBadge}>Pen</SubtleBadge>
      <Row alignCenter flexWrap>
        <CollapseLabel>{penName}</CollapseLabel>
        {overflowDisplayValue && (
          <CollapseLabel>{overflowDisplayValue}</CollapseLabel>
        )}
        {isTempPen && <FaIcon color="warning" icon={faExclamationCircle} />}
      </Row>
    </Row>
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      hasErrorBadge={hasErrorBadge}
      header={header}
      id="collapse-pen"
      dataTour={isOpen ? "hidePen" : "showPen"}
    >
      {isAnimalsInAutoDraft ? (
        <Warning>
          This Sale Lot has Animals already drafted into an Auto Draft Pen and
          cannot be edited at this time.
        </Warning>
      ) : null}
      <PenningSaleLotForm
        label="Auction Pen"
        saleRoundId={sale_round_id}
        saleLotId={id}
        autoFocusStartPen={action === auctionPenActions.RE_PEN}
        namespace="auction_pen"
        penType={PenTypes.SELLING}
        required
        readOnly={isAnimalsInAutoDraft || readOnly}
      />
      <SaleLotOverflowForm readOnly={readOnly} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <PenningSaleLotForm
        label="Delivery Pen"
        saleRoundId={sale_round_id}
        saleLotId={id}
        namespace="deliveryPen"
        penType={PenTypes.DELIVERY}
        showOwnerPrompt
        buyerHash={buyerHash}
        readOnly={isAnimalsInAutoDraft || readOnly}
      />
    </FormCollapse>
  );
};
