import reduceReducers from "reduce-reducers";

import { NOMINATION } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineCommentReducer,
} from "lib/reducers";
import { deserializeNomination } from "lib/serializers/nominations";

const create = apiModelOfflineCreateReducer(NOMINATION, {
  deserializer: deserializeNomination,
});

const deleteReducer = apiModelOfflineDeleteReducer(NOMINATION);

const fetch = apiModelOfflineFetchReducer(NOMINATION, {
  deserializer: deserializeNomination,
});

const update = apiModelOfflineUpdateReducer(NOMINATION, {
  deserializer: deserializeNomination,
});

const comment = apiModelOfflineCommentReducer(NOMINATION);

const nominationReducer = reduceReducers(
  create,
  comment,
  deleteReducer,
  fetch,
  update,
);

export default nominationReducer;
