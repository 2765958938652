import React, { Component } from "react";

function getCellValue(rowNode, params) {
  const { api, colDef, column, columnApi, context } = params;
  return params.valueGetter({
    api,
    colDef,
    column,
    columnApi,
    context,
    data: rowNode.data,
    getValue: field => rowNode.data[field],
    node: rowNode,
  });
}

export class BooleanFilterComponent extends Component {
  static componentName = "anBooleanColumnFilter";

  constructor(props) {
    super(props);
    this.state = { selectedValue: "" };
  }

  onChangeSelectedValue = event => {
    this.setState(
      {
        selectedValue:
          event.target.value === "null" ||
          event.target.value === "true" ||
          event.target.value === "false"
            ? JSON.parse(event.target.value)
            : "",
      },
      () => this.props.filterChangedCallback(),
    );
  };

  getModel() {
    return this.state;
  }

  setModel(filterModel) {
    this.setState(filterModel);
  }

  doesFilterPass(params) {
    const model = this.getModel();

    if (model === null) {
      return true;
    }

    const { selectedValue } = model;

    const cellValue = getCellValue(params.node, this.props);

    return cellValue === selectedValue;
  }

  isFilterActive() {
    return this.state.selectedValue !== "";
  }

  render() {
    const {
      allDisplayText = "All",
      indeterminateDisplayText = "Unknown",
      trueDisplayText = "Yes",
      falseDisplayText = "No",
      showIndeterminate = false,
    } = this.props;

    const { selectedValue = "" } = this.state;

    return (
      <div className="p-2">
        <div className="px-2 py-1">
          <div className="ag-label-align-right ag-input-field">
            <label className="ag-label" htmlFor="booleanFilterOptionDisabled">
              {allDisplayText}
            </label>

            <div
              className={`ag-wrapper ag-input-wrapper ag-radio-button-input-wrapper ${
                selectedValue === "" ? " ag-checked" : ""
              }`}
            >
              <input
                checked={selectedValue === ""}
                id="booleanFilterOptionDisabled"
                name="booleanFilterOption"
                onChange={this.onChangeSelectedValue}
                type="radio"
                value=""
              />
            </div>
          </div>
        </div>
        {showIndeterminate && (
          <div className="px-2 py-1">
            <div className="ag-label-align-right ag-input-field">
              <label
                className="ag-label"
                htmlFor="booleanFilterOptionIndeterminate"
              >
                {indeterminateDisplayText}
              </label>

              <div
                className={`ag-wrapper ag-input-wrapper ag-radio-button-input-wrapper ${
                  selectedValue === null ? " ag-checked" : ""
                }`}
              >
                <input
                  checked={selectedValue === null}
                  id="booleanFilterOptionIndeterminate"
                  name="booleanFilterOption"
                  onChange={this.onChangeSelectedValue}
                  type="radio"
                  value="null"
                />
              </div>
            </div>
          </div>
        )}
        <div className="px-2 py-1">
          <div className="ag-label-align-right ag-input-field">
            <label className="ag-label" htmlFor="booleanFilterOptionTrue">
              {trueDisplayText}
            </label>

            <div
              className={`ag-wrapper ag-input-wrapper ag-radio-button-input-wrapper ${
                selectedValue === true ? " ag-checked" : ""
              }`}
            >
              <input
                checked={selectedValue === true}
                id="booleanFilterOptionTrue"
                name="booleanFilterOption"
                onChange={this.onChangeSelectedValue}
                type="radio"
                value="true"
              />
            </div>
          </div>
        </div>
        <div className="px-2 py-1">
          <div className="ag-label-align-right ag-input-field">
            <label className="ag-label" htmlFor="booleanFilterOptionFalse">
              {falseDisplayText}
            </label>
            <div
              className={`ag-wrapper ag-input-wrapper ag-radio-button-input-wrapper ${
                selectedValue === false ? " ag-checked" : ""
              }`}
            >
              <input
                checked={selectedValue === false}
                id="booleanFilterOptionFalse"
                name="booleanFilterOption"
                onChange={this.onChangeSelectedValue}
                type="radio"
                value="false"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
