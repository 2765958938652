import React, { useState } from "react";

import { faBluetoothB } from "@fortawesome/free-brands-svg-icons";
import {
  faDownload,
  faTrash,
  faRecycle,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { Button } from "components/Form";
import { Column, Row } from "components/Layout";

import { PenTypes } from "constants/auctionPens";
import { scannerMessages } from "constants/scanner";

import { getScanRoute } from "lib/navigation";

import { getConnectedDevice, getScannerCapabilities } from "selectors";

import { useTheme } from "hooks";
import Scanner from "img/scanner";
import SearchId from "img/search-id";

const nlisRegex = new RegExp(/^[0-9a-zA-Z]/);

const ScannerActions = ({
  connectedDeviceId,
  scannerCapabilities,
  handleEraseSavedScans,
  handleUpdateDeviceTime,
  handleScannerImport,
  handleResetDevice,
}) => {
  const supportsImport = scannerCapabilities.includes(
    scannerMessages.READ_SAVED_SCANS,
  );
  const supportsEraseSavedScans = scannerCapabilities.includes(
    scannerMessages.ERASE_SAVED_SCANS,
  );
  const supportsUpdateDeviceTime = scannerCapabilities.includes(
    scannerMessages.UPDATE_DEVICE_TIME,
  );
  const supportsResetDevice =
    supportsUpdateDeviceTime && supportsEraseSavedScans;

  const scannerImport = () => handleScannerImport(connectedDeviceId);
  const updateDeviceTime = () => handleUpdateDeviceTime(connectedDeviceId);
  const eraseScans = () => handleEraseSavedScans(connectedDeviceId);
  const resetDevice = () => handleResetDevice(connectedDeviceId);

  const [showExtraButtons, setShowExtraButtons] = useState(false);

  const scannerHasActions =
    Boolean(supportsEraseSavedScans && handleEraseSavedScans) ||
    Boolean(supportsImport && handleScannerImport) ||
    Boolean(supportsUpdateDeviceTime && handleUpdateDeviceTime) ||
    Boolean(supportsResetDevice && handleResetDevice);
  if (!scannerHasActions) {
    return null;
  }
  return (
    <>
      <ButtonContainer>
        {supportsImport && handleScannerImport && (
          <ButtonStyled onClick={scannerImport}>
            <Icon icon={faDownload} />
            Scanner Import
          </ButtonStyled>
        )}
        {!showExtraButtons && (
          <ExpandButton onClick={setShowExtraButtons}>
            <Icon icon={faPlus} />
            More Scanner Actions
          </ExpandButton>
        )}
      </ButtonContainer>

      {showExtraButtons && (
        <ButtonContainer>
          {supportsUpdateDeviceTime && handleUpdateDeviceTime && (
            <ButtonStyled onClick={updateDeviceTime}>
              <Icon icon={faClock} />
              Update Device Time
            </ButtonStyled>
          )}
          {supportsEraseSavedScans && handleEraseSavedScans && (
            <ButtonStyled onClick={eraseScans}>
              <Icon icon={faTrash} />
              Clear Scanner Sessions
            </ButtonStyled>
          )}
          {supportsResetDevice && (
            <ButtonStyled onClick={resetDevice}>
              <Icon icon={faRecycle} />
              Clear Sessions &amp; Update Time
            </ButtonStyled>
          )}
          <ExpandButton onClick={() => setShowExtraButtons(false)}>
            <Icon icon={faMinus} />
            Less Scanner Actions
          </ExpandButton>
        </ButtonContainer>
      )}
    </>
  );
};

const NLISInput = ({
  handleDisconnectClick,
  handleFindClick,
  connectedMessage,
  disconnectedMessage,
  handleEraseSavedScans,
  handleUpdateDeviceTime,
  handleScannerImport,
  handleResetDevice,
  showConnect = true,
}) => {
  const [scan, setScan] = useState(showConnect);
  const [nlis, setNLIS] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints[0]}px)`);

  const connectedDevice = useSelector(getConnectedDevice);
  const scannerCapabilities = useSelector(getScannerCapabilities);

  const disconnectClick = () => handleDisconnectClick(connectedDevice.deviceId);

  const connected = !!connectedDevice;

  const handleNLISChange = e => {
    const { value } = e.target;
    if (nlisRegex.test(value)) {
      setNLIS(value.toUpperCase());
    } else if (value.length === 0) {
      setNLIS("");
    }
  };

  return (
    <>
      <Wrapper id="NLIS-input">
        <ScannerStyled scanning={scan} onClick={() => setScan(true)} />
        {scan ? (
          <ScanView isMobile={isMobile}>
            <Column flexGrow>
              {connected ? (
                <>
                  <TopMessage>{connectedMessage}</TopMessage>
                  <BottomMessage connected>
                    <Icon icon={faBluetoothB} /> Connected to scanner
                    <DisconnectButton type="button" onClick={disconnectClick}>
                      Disconnect
                    </DisconnectButton>
                  </BottomMessage>
                </>
              ) : (
                <>
                  <TopMessage>{disconnectedMessage}</TopMessage>
                  <BottomMessage>
                    <Icon icon={faBluetoothB} /> Not Connected
                    <LinkStyled
                      style={{ color: "white" }}
                      to={`#${getScanRoute(
                        null,
                        null,
                        false,
                        null,
                        window.location.pathname,
                        null,
                        null,
                        null,
                        PenTypes.SELLING,
                      )}`}
                      data-tour="scanToUnallocated"
                    >
                      Scan to unallocated
                    </LinkStyled>
                  </BottomMessage>
                </>
              )}
            </Column>
          </ScanView>
        ) : (
          <>
            <StyledInput
              placeholder="Type NLIS ID here to add manually"
              onChange={handleNLISChange}
              value={nlis}
              maxLength={16}
            />
            {nlis.length ? (
              <FindText onClick={() => handleFindClick(nlis)}>Find</FindText>
            ) : null}
          </>
        )}

        <SearchIdStyled onClick={() => setScan(false)} scanning={scan} />
      </Wrapper>
      {connected && (
        <ScannerActions
          connectedDeviceId={connectedDevice.deviceId}
          scannerCapabilities={scannerCapabilities}
          handleEraseSavedScans={handleEraseSavedScans}
          handleUpdateDeviceTime={handleUpdateDeviceTime}
          handleScannerImport={handleScannerImport}
          handleResetDevice={handleResetDevice}
        />
      )}
    </>
  );
};

NLISInput.propTypes = {
  handleDisconnectClick: PropTypes.func,
  handleFindClick: PropTypes.func,
  handleEraseSavedScans: PropTypes.func,
  handleUpdateDeviceTime: PropTypes.func,
  handleScannerImport: PropTypes.func,
  handleResetDevice: PropTypes.func,
};

NLISInput.defaultProps = {
  handleFindClick: () => {},
};

export default NLISInput;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border: 1px solid
    ${props =>
      props.isInvalid ? props.theme.colors.error : props.theme.colors.gray78};
  border-radius: ${({ theme }) => theme.radii[4]}px;
  background-color: ${({ theme }) => theme.colors.gray95};
`;

const StyledInput = styled.input`
  height: 3.5rem;
  width: 100%;
  border-color: transparent;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii[4]}px;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.space[2]}px;
  padding-right: ${({ theme }) => theme.space[5]}px;
  margin-left: ${({ theme }) => theme.space[5]}px;
`;

const ScanView = styled.div`
  ${({ isMobile }) => (isMobile && `height: 5.5rem`) || `height: 3.5rem`};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: ${({ theme }) => theme.space[5]}px;
  padding-left: ${({ theme }) => theme.space[5]}px;
  border-radius: ${({ theme }) => theme.radii[4]}px;
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
`;

const TopMessage = styled.span`
  font-weight: 500;
`;

const BottomMessage = styled(Row).attrs({
  alignCenter: true,
})`
  color: ${({ connected, theme }) =>
    connected ? theme.colors.primary : theme.colors.grayAE};
  a,
  button {
    color: ${({ connected, theme }) =>
      connected ? theme.colors.grayAE : theme.colors.primary};
  }
`;

const ScannerStyled = styled(Scanner).attrs(({ theme, scanning }) => ({
  color: scanning ? theme.colors.primary : theme.colors.gray40,
}))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ theme }) => theme.space[2]}px;
  cursor: pointer;
`;

const SearchIdStyled = styled(SearchId).attrs(({ theme, scanning }) => ({
  color: scanning ? theme.colors.gray40 : theme.colors.primary,
}))`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ theme }) => theme.space[2]}px;
  cursor: pointer;
`;

const LinkStyled = styled(Link)`
  margin-right: 6px;
  text-align: center;
  margin-bottom: 2px;
  text-decoration: none;
  text-align: center;
  background: ${({ theme }) => theme.colors.primary};
  padding: 2px 8px 2px 8px;
  border-radius: 8px;
  font-weight: bold;
  margin-left: 8px;
  display: inline-block;
  :hover {
    background: ${({ theme }) => theme.colors.primaryHighlight};
  }
`;

const DisconnectButton = styled.button`
  cursor: pointer;
  border: 0;
  text-decoration: underline;
  background: transparent;
  flex-grow: 1;
  text-align: left;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme }) => theme.space[1]}px;
`;

const FindText = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.space[5]}px;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
`;

const ButtonContainer = styled(Column)`
  padding: ${({ theme }) => theme.space[0]}px ${({ theme }) => theme.space[3]}px;
`;

const ButtonStyled = styled(Button)`
  margin: ${({ theme }) => theme.space[2]}px;
  ${({ theme, showMargin }) =>
    showMargin && `margin-left: ${theme.space[3]}px;`}
`;

const ExpandButton = styled.div`
  text-align: center;
  cursor: pointer;
`;
