import React, { memo } from "react";

import { faCheck, faPlus, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { Divider } from "@material-ui/core";
import { isEqual } from "lodash";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { SlimSecondaryButton } from "components/Button";
import { Row } from "components/Layout";

import { ConsignmentPermissions } from "constants/permissions";
import { ScanListType, UNALLOCATED } from "constants/scanner";

import { scanLotConfig } from "lib/scanLot";

import { getConsignmentById, getSaleLotIdsByConsignmentId } from "selectors";

import { useHasPermission } from "hooks/useHasPermission";

import { ScanList } from "./Elements";
import ScanListFooter from "./ScanListFooter";
import ScanListHeader from "./ScanListHeader";
import ScanRowList from "./ScanRowList";
import StoredScanRowList from "./StoredScanRowList";

const getConsignmentHasSaleLots = consignmentId => state => {
  const consignmentSaleLotIds =
    getSaleLotIdsByConsignmentId(consignmentId)(state);
  return (
    Array.isArray(consignmentSaleLotIds) && consignmentSaleLotIds.length > 0
  );
};

export function ScanningColumnComponent(props) {
  const {
    consignmentId,
    draftName,
    disallowTaken,
    duplicateEidsOrNlisIds,
    eidsOrNlisIds,
    isExpanded,
    isFooterDisabled,
    isSaleLotSelected,
    onCancel,
    onClear,
    onClearSaved,
    onEdit,
    onIgnore,
    onIgnoreAll,
    onKeep,
    onKeepAll,
    onAddToConsignment,
    onAddToCurrent,
    onAddToSale,
    onAddToScanLot,
    onCreateNew,
    onCreateNewBulk,
    onSelectExisting,
    onSelect,
    showEdit = false,
    showFooter: forceShowFooter,
    showIgnore = true,
    showIgnoreAll = true,
    showKeep = true,
    showKeepAll = true,
    showSelect = false,
    scanListType = ScanListType.SaleLot,
    hasPrevPen = false,
    hasNextPen = false,
    penType,
    scanLotId,
    penId,
    penArchetypeId,
    showScanDiscrepancyDialog,
  } = props;

  const isArrivalOrPenScanning = !!penArchetypeId || !!penId || !!scanLotId;

  const consignmentHasSaleLots = useSelector(
    getConsignmentHasSaleLots(consignmentId),
  );
  const hasAddSaleLotPermission = useHasPermission(
    getConsignmentById(consignmentId),
    ConsignmentPermissions.canAddSaleLot,
  );
  const isCreateNewDisabled = !hasAddSaleLotPermission || !consignmentId;
  const isFooterVisible =
    isArrivalOrPenScanning ||
    (typeof forceShowFooter === "boolean" ? forceShowFooter : isExpanded);

  return (
    <>
      <Row justifyBetween paddingHorizontal={2}>
        {typeof onClearSaved === "function" &&
        scanListType === ScanListType.ScanLot ? (
          <SlimSecondaryButton
            data-tour="clearSavedEIDs"
            type="button"
            icon={faTrashAlt}
            color="red"
            onClick={onClearSaved}
            disabled={false}
          >
            <FaIcon icon={faCheck} /> Clear Saved EIDs
          </SlimSecondaryButton>
        ) : null}
        {typeof onClear === "function" &&
        scanListType === ScanListType.ScanLot ? (
          <SlimSecondaryButton
            data-tour="clearNewEIDs"
            type="button"
            icon={faTrashAlt}
            color="red"
            onClick={onClear}
            disabled={false}
          >
            <FaIcon icon={faPlus} /> Clear New EIDs
          </SlimSecondaryButton>
        ) : null}
      </Row>
      <ScanListHeader
        duplicateCount={duplicateEidsOrNlisIds.length}
        eidCount={eidsOrNlisIds.length}
        isExpanded={isExpanded}
        draftName={draftName}
        onKeepAll={onKeepAll}
        onIgnoreAll={onIgnoreAll}
        showKeepAll={!isArrivalOrPenScanning && showKeepAll}
        showIgnoreAll={!isArrivalOrPenScanning && showIgnoreAll}
        isArrivalOrPenScanning={isArrivalOrPenScanning}
        duplicateEidsOrNlisIds={duplicateEidsOrNlisIds}
        scanLotId={scanLotId}
        penType={penType}
      />
      <ScanList marginBottom={(isArrivalOrPenScanning && 80) || 0}>
        <ScanRowList
          destinationConsignmentId={consignmentId}
          disallowTaken={disallowTaken}
          eidsOrNlisIds={duplicateEidsOrNlisIds}
          onEdit={onEdit}
          onIgnore={onIgnore}
          onKeep={onKeep}
          onSelect={onSelect}
          showDetailed
          showDuplicate
          showEdit={showEdit}
          showIgnore={showIgnore}
          showKeep={showKeep}
          showSelect={showSelect}
          isArrivalOrPenScanning={isArrivalOrPenScanning}
          penType={penType}
          scanLotId={scanLotId}
        />
        <ScanRowList
          destinationConsignmentId={consignmentId}
          eidsOrNlisIds={eidsOrNlisIds}
          showDetailed
          isArrivalOrPenScanning={isArrivalOrPenScanning}
          penType={penType}
        />
        {scanLotId && (
          <>
            <Divider />
            <StoredScanRowList scanLotId={scanLotId} penType={penType} />
          </>
        )}
      </ScanList>
      {isFooterVisible && (
        <ScanListFooter
          isCreateNewDisabled={isCreateNewDisabled}
          isDisabled={isFooterDisabled}
          isSaleLotSelected={isSaleLotSelected}
          isSelectExistingDisabled={!consignmentHasSaleLots}
          onClear={onClear}
          onAddToConsignment={onAddToConsignment}
          onAddToCurrent={onAddToCurrent}
          onAddToSale={onAddToSale}
          onAddToScanLot={onAddToScanLot}
          onSelectExisting={onSelectExisting}
          onCancel={onCancel}
          onCreateNew={onCreateNew}
          onCreateNewBulk={onCreateNewBulk}
          scanListType={scanListType}
          hasPrevPen={hasPrevPen}
          hasNextPen={hasNextPen}
          scanLotId={scanLotId}
          penId={penId}
          penArchetypeId={penArchetypeId}
          eidsOrNlisIds={eidsOrNlisIds}
          penType={penType}
          duplicateEidsOrNlisIds={duplicateEidsOrNlisIds}
          showScanDiscrepancyDialog={showScanDiscrepancyDialog}
        />
      )}
    </>
  );
}
ScanningColumnComponent.propTypes = {
  draftName: PropTypes.string,
  disallowTaken: PropTypes.bool,

  duplicateEidsOrNlisIds: PropTypes.array.isRequired,
  eidsOrNlisIds: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool,
  isFooterDisabled: PropTypes.bool,
  isSaleLotSelected: PropTypes.bool,

  onAddToConsignment: PropTypes.func,
  onAddToCurrent: PropTypes.func,
  onAddToSale: PropTypes.func,
  onAddToScanLot: PropTypes.func,
  onClear: PropTypes.func,
  onCreateNew: PropTypes.func,
  onCreateNewBulk: PropTypes.func,
  onEdit: PropTypes.func,
  onIgnore: PropTypes.func,
  onIgnoreAll: PropTypes.func,
  onKeep: PropTypes.func,
  onKeepAll: PropTypes.func,
  onSelectExisting: PropTypes.func,
  onSelect: PropTypes.func,

  showEdit: PropTypes.bool,
  showFooter: PropTypes.bool,
  showIgnore: PropTypes.bool,
  showIgnoreAll: PropTypes.bool,
  showKeep: PropTypes.bool,
  showKeepAll: PropTypes.bool,
  showSelect: PropTypes.bool,
};

export function buildDuplicateTakeableEidList(
  scansList,
  saleLotScans,
  saleLots,
  saleyardScanSaleLots,
  consignments,
  takeFilesByConsignmentId,
  eidProperty,
  ignoredDuplicatesProperty,
  disallowTaken,
  destinationConsignmentId,
) {
  const duplicateEids = [];
  const eids = [];
  const keepableEids = [];

  scansList.forEach(scan => {
    const eid = scan[eidProperty];
    const conflictingScan = saleLotScans[eid];

    // consider it not a duplicate if we have already flagged this eid as kept
    // (indicated by the ignoredProperty on the scan), also ignore scans already
    // scanned in to the sale as "duplicates". Finally, don't consider scans from
    // a Saleyard Scan Sale Lot to be duplicate, unless the scan is from a different consignment
    if (!conflictingScan) {
      eids.push(eid);
      return;
    }

    // If the duplicate status of the scan is flagged as ignore, don't flag it as duplicate. Duh!
    if (scan[ignoredDuplicatesProperty]) {
      eids.push(eid);
      return;
    }

    // Also don't flag it as duplicate if it's unallocated
    if (conflictingScan.sale_lot_id === UNALLOCATED) {
      eids.push(eid);
      return;
    }
    // Check if the conflict is a due to a Saleyard scanning event
    const saleyardScanSaleLot =
      saleyardScanSaleLots[conflictingScan.sale_lot_id];

    // When The conflict is a Saleyard Scan and is from a different consignment.
    // This has to do with NLIS Saleyard In transfers and the NVD being attached to the consignment.
    if (
      saleyardScanSaleLot &&
      saleyardScanSaleLot.consignmentId === destinationConsignmentId
    ) {
      // When the user is attempting to migrate the Scan to another Sale Lot in the same Consignment,
      // and the conflicting Sale Lot is a Saleyard Scan Sale Lot from the same consignment, don't flah it as duplicate
      eids.push(eid);
      return;
    }

    // By now it's established that this is definitely a duplicate Scan, all we need to do is figure out if it can be kept
    duplicateEids.push(eid);

    const conflictingSaleLot = saleLots[conflictingScan.sale_lot_id];
    if (disallowTaken) {
      if (conflictingSaleLot) {
        // We need to check for the existence of the conflicting sale lot just in case the
        // Sale Lot state is dirty, either loading, or the user is switching sales.
        // This means that you cannot keep until the Sale Lot, and Consignment are in the state.

        const consignmentId = conflictingSaleLot.consignment_id;
        // we need to check if this eid is taken, before we can allow it it be kept
        const scanConsignment = consignments[consignmentId];

        // don't allow keep all if the consignment doesn't yet exist - wait for consignments to finish loading
        if (scanConsignment && scanConsignment.nlis_take_file_status === null) {
          const consignmentTakeFiles = takeFilesByConsignmentId[consignmentId];
          if (!consignmentTakeFiles || consignmentTakeFiles.length === 0) {
            keepableEids.push(eid);
          }
        }
      }
    } else {
      // We don't care about the Take status of the consignment, just allow it to be Kept
      keepableEids.push(eid);
    }
  });
  return { eids, keepableEids, duplicateEids, hiddenEids: [] };
}

export const buildDuplicatePenScanEidsList = (
  scansList,
  scans,
  eidProperty,
  ignoredDuplicatesProperty,
  penType,
  scanLotId,
  scanLotIdByEid,
) => {
  const { scanReference } = scanLotConfig(penType);
  const duplicateEids = [];
  const eids = [];
  const keepableEids = [];
  const hiddenEids = [];
  scansList.forEach(scan => {
    const eid = scan[eidProperty];
    const conflictingScan = scans[eid];

    // make sure that scans that are associated to the lot we are scanning to are not shown
    if (scanLotId && scanLotIdByEid[eid] === scanLotId) {
      hiddenEids.push(eid);
      return;
    }

    // Don't flag if the scan is not associated to a receival/pen scan lot
    if (!conflictingScan?.[scanReference]) {
      eids.push(eid);
      return;
    }

    // If the duplicate status of the scan is flagged as ignore, don't flag it as duplicate. Duh!
    if (scan[ignoredDuplicatesProperty]) {
      eids.push(eid);
      return;
    }

    // By now it's established that this is definitely a duplicate Scan.
    duplicateEids.push(eid);
  });
  return { eids, keepableEids, duplicateEids, hiddenEids };
};

export default memo(ScanningColumnComponent, isEqual);
