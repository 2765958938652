import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { Settings } from "constants/settings";

import { openPenScanning } from "lib/navigation";
import { scanLotConfig } from "lib/scanLot";

import { getSetting } from "selectors";

export function useHandleAfterSaveScanPen(
  penArchetypeId,
  penId,
  scanLotId,
  penType,
) {
  // Provides a handler function to call after saving a (receival/selling) pen - this may move
  // navigation to the next pen, the previous pen, or simply clear the form ready for the
  // next (receival/pen scan) lot.
  const { resetForm } = useFormikContext();

  const {
    getNextPenByPenIdAndArchetypeId,
    getPreviousPenByPenIdAndArchetypeId,
  } = scanLotConfig(penType);

  const nextPen = useSelector(
    getNextPenByPenIdAndArchetypeId(penId, penArchetypeId),
  );

  const prevPen = useSelector(
    getPreviousPenByPenIdAndArchetypeId(penId, penArchetypeId),
  );

  const currentMode = useSelector(getSetting(Settings.preferredScanningMode));

  // Limitiation - we are NOT setting a returnTo
  // This could, and by rights should, be passed down from the URL and sent into the
  // open function.
  // In current reality, we we always be returning to the not-hash-routed page, so,
  // we can leave it blank.
  return options => {
    if (options && options.moveToNext) {
      // Move the url forward one.
      openPenScanning(
        nextPen.penArchetypeId,
        nextPen.penId,
        null,
        false,
        currentMode,
        penType,
        // returnTo
      );
      resetForm({ values: {} });
    } else if (options && options.moveToPrev) {
      // Move the url back one.
      openPenScanning(
        prevPen.penArchetypeId,
        prevPen.penId,
        null,
        false,
        currentMode,
        penType,
        // returnTo
      );
      resetForm({ values: {} });
    } else if (scanLotId) {
      // If we have a selected lot id, bunt them to the url with null, which will trigger a reset.
      openPenScanning(
        penArchetypeId,
        penId,
        null,
        false,
        currentMode,
        penType,
        // returnTo
      );
    } else {
      // Keep on the null url, and clear the form.
      resetForm({ values: {} });
    }
  };
}
