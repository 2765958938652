import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Button, SecondaryButton, DeleteButton } from "components/Form/Button";

import { IntegrationBusinessPermissions } from "constants/permissions";

import { hasPermission } from "lib/permissions";

import { getIntegrationBusinessById } from "selectors";

export function Footer(props) {
  const {
    existingIntegrationBusinessId,
    integrationBusinessId,
    onClose,
    integrationCredentialType,
    createNew,
    onClickUnlink,
    hasFeaturePermission,
  } = props;

  const canEditIntegrationBusiness = useSelector(state =>
    integrationBusinessId
      ? hasPermission(
          getIntegrationBusinessById(integrationBusinessId)(state) || {},
          IntegrationBusinessPermissions.update,
        )
      : false,
  );

  const hasIntegrationBusinessChanged =
    existingIntegrationBusinessId !== integrationBusinessId;

  const isLinkEnabled =
    hasFeaturePermission &&
    canEditIntegrationBusiness &&
    hasIntegrationBusinessChanged;

  const isCreateEnabled =
    hasFeaturePermission && !existingIntegrationBusinessId;

  const isUnlinkEnabled =
    hasFeaturePermission &&
    canEditIntegrationBusiness &&
    existingIntegrationBusinessId === integrationBusinessId;

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>

      <Button type="button" onClick={createNew} disabled={!isCreateEnabled}>
        Create in {integrationCredentialType}
      </Button>

      {isUnlinkEnabled ? (
        <DeleteButton type="button" onClick={onClickUnlink}>
          Unlink Business
        </DeleteButton>
      ) : (
        <Button type="submit" disabled={!isLinkEnabled}>
          Link to Business
        </Button>
      )}
    </>
  );
}

Footer.propTypes = {
  existingIntegrationBusinessId: PropTypes.number,
  integrationBusinessId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};
