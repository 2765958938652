import React, { memo, useEffect, useState } from "react";

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { setSetting } from "actions";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import BaseButton, { IconTextButton, MultiButton } from "components/Button";
import { DeleteButton } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { ScanListType } from "constants/scanner";
import { Settings } from "constants/settings";
import { userTypes } from "constants/users";

import { ForScaleOperator, ForUserType } from "containers/ForUserType";

import { scanLotConfig } from "lib/scanLot";

import {
  getReceivalLots,
  getSetting,
  selectReceivalLotIdsByReceivalPenIdLookup,
} from "selectors";

import { useFieldValue, useTranslatedSaleTypeText } from "hooks";

const Wrapper = styled.div`
  white-space: nowrap;
  overflow-x: hidden;
  border-top: 1px solid rgba(151, 151, 151, 0.25);
  padding: 6px;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: ${({ justify }) => justify || "end"};

  flex-wrap: wrap;
`;

const Button = styled(BaseButton)`
  padding: ${({ wide }) => (wide ? "0 36px" : "0 12px")};
  margin: 6px;
  min-height: 36px;
`;

const AddScansToSaleLotButtons = {
  add: "add",
  addAndClose: "addAndClose",
};

const ScanPenOrdering = {
  forward: "forward",
  reverse: "reverse",
};

function SaleLotScanListFooter(props) {
  const {
    isDisabled,
    isSaleLotSelected,
    isSelectExistingDisabled,
    isCreateNewDisabled,
    onClear,
    onCreateNew,
    onCreateNewBulk,
    onAddToConsignment,
    onAddToCurrent,
    onAddToSale,
    onCancel,
    onSelectExisting,
  } = props;

  const saleTypeText = useTranslatedSaleTypeText("Sale Lot");

  const dispatch = useDispatch();

  function onClickClear() {
    typeof onClear === "function" && onClear();
  }
  function onClickSelectExisting() {
    typeof onSelectExisting === "function" && onSelectExisting();
  }
  function onClickAddNew() {
    typeof onCreateNew === "function" && onCreateNew();
  }
  function onClickAddToConsignment() {
    typeof onAddToConsignment === "function" && onAddToConsignment();
  }
  function onClickAddToCurrent() {
    typeof onAddToCurrent === "function" && onAddToCurrent();
    dispatch(
      setSetting(
        Settings.addScansToSaleLotButton,
        AddScansToSaleLotButtons.add,
      ),
    );
  }
  function onClickAddToCurrentAndClose() {
    typeof onAddToCurrent === "function" && onAddToCurrent();
    typeof onCancel === "function" && onCancel();
    dispatch(
      setSetting(
        Settings.addScansToSaleLotButton,
        AddScansToSaleLotButtons.addAndClose,
      ),
    );
  }
  function onClickAddToSale() {
    typeof onAddToSale === "function" && onAddToSale();
  }

  const saleLotNotSelectedButtons = [
    {
      title: "Add to existing Sale Lot",
      onClick: onClickSelectExisting,
      default: true,
      isDisabled: isSelectExistingDisabled || isDisabled,
      dataTour: "addToExistingSaleLot",
    },
    {
      title: "Add to Consignment",
      onClick: onClickAddToConsignment,
      default: true,
      isDisabled: isCreateNewDisabled || isDisabled,
      dataTour: "addToConsignment",
    },
    {
      title: "Add to Sale",
      onClick: onClickAddToSale,
      default: true,
      isDisabled,
      dataTour: "addToSale",
    },
  ];

  const createButtons = [
    {
      title: "Create new Sale Lot",
      onClick: onClickAddNew,
      default: true,
      isDisabled: isCreateNewDisabled || isDisabled,
      dataTour: "createNewSaleLot",
    },
    {
      title: "Create new Sale Lots (Bulk)",
      onClick: onCreateNewBulk,
      default: false,
      isDisabled: isCreateNewDisabled || isDisabled,
      dataTour: "creteaNewSaleLotsBulk",
    },
  ];

  return (
    <Wrapper>
      <IconTextButton
        dataTour="clearEIDs"
        type="button"
        icon={faTrashAlt}
        color="grey40"
        onClick={onClickClear}
        disabled={isDisabled}
      >
        Clear EIDs
      </IconTextButton>
      {isSaleLotSelected ? (
        <Row justifyEnd>
          <Button
            data-tour={`addTo${saleTypeText.replaceAll(" ", "")}`}
            onClick={onClickAddToCurrent}
            disabled={isDisabled}
          >
            {`Add to ${saleTypeText}`}{" "}
          </Button>
          <Button
            onClick={onClickAddToCurrentAndClose}
            disabled={isDisabled || typeof onCancel !== "function"}
            data-tour="addToSaleLotAndClose"
          >
            {`Add to ${saleTypeText} & Close`}
          </Button>
        </Row>
      ) : (
        <Row>
          <Row>
            <ForUserType
              userType={[userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN]}
            >
              <Row data-tour="scanningFooter" padding={1}>
                <MultiButton
                  buttons={saleLotNotSelectedButtons}
                  preferEnabledAsDefault
                />
              </Row>

              <Row padding={1}>
                <MultiButton buttons={createButtons} />
              </Row>
            </ForUserType>
            <ForScaleOperator>
              <Button
                data-tour="addUnallocated"
                wide
                disabled={isDisabled}
                onClick={onClickAddToSale}
              >
                Add Unallocated
              </Button>
            </ForScaleOperator>
          </Row>
        </Row>
      )}
    </Wrapper>
  );
}

SaleLotScanListFooter.propTypes = {
  isCreateNewDisabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSelectExistingDisabled: PropTypes.bool,
  isSaleLotSelected: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  onCreateNew: PropTypes.func,
  onCreateNewBulk: PropTypes.func,
  onAddToConsignment: PropTypes.func,
  onAddToCurrent: PropTypes.func,
  onAddToSale: PropTypes.func,
  onCancel: PropTypes.func,
  onSelectExisting: PropTypes.func,
};

function ScanLotScanListFooter(props) {
  const {
    onAddToScanLot,
    onCancel,
    hasNextPen,
    hasPrevPen,
    scanLotId,
    penId,
    penArchetypeId,
    eidsOrNlisIds,
    penType,
    showScanDiscrepancyDialog,
    duplicateEidsOrNlisIds,
  } = props;

  const {
    getNextPenTitleByPenIdOrPenArchetypeId,
    getPreviousPenTitleByPenIdAndPenArchetypeId,
  } = scanLotConfig(penType);

  const nextPenTitle = useSelector(
    getNextPenTitleByPenIdOrPenArchetypeId(penId, penArchetypeId),
  );

  const prevPenTitle = useSelector(
    getPreviousPenTitleByPenIdAndPenArchetypeId(penId, penArchetypeId),
  );

  const [allowFormSubmit, setAllowFormSubmit] = useState(false);

  const {
    setFieldValue,
    values: { scansCount, hasMarksConflict, deploymentId, saleRoundId },
  } = useFormikContext();

  useEffect(() => {
    if (eidsOrNlisIds.length || duplicateEidsOrNlisIds.length) {
      // acknowledge duplicate eids to allow submit - we can only deal with duplicates once we move forward
      setFieldValue(
        "scansCount",
        (eidsOrNlisIds.length || 0) + (duplicateEidsOrNlisIds.length || 0),
      );
    }
  }, [
    setFieldValue,
    eidsOrNlisIds.length,
    duplicateEidsOrNlisIds.length,
    scansCount,
  ]);

  useSubmitHandler(
    !!scanLotId && (eidsOrNlisIds.length || duplicateEidsOrNlisIds.length),
    setAllowFormSubmit,
  );

  const isSaveDisabled = !allowFormSubmit;

  const receivalLotLookup = useSelector(getReceivalLots);

  const dispatch = useDispatch();

  const penOrdering = useSelector(getSetting(Settings.scanPenOrdering));

  const mark = useFieldValue("mark");

  const receivalLotIdsInCurrentPen = useSelector(
    selectReceivalLotIdsByReceivalPenIdLookup,
  )[penId];

  const markInPenConflictIds = React.useMemo(
    () =>
      receivalLotIdsInCurrentPen?.filter(
        rLotId =>
          receivalLotLookup[rLotId].mark === mark && rLotId !== scanLotId,
      ),
    [receivalLotIdsInCurrentPen, receivalLotLookup, mark, scanLotId],
  );

  const hasConflictingEids =
    duplicateEidsOrNlisIds && duplicateEidsOrNlisIds.length;

  const hasConflict = markInPenConflictIds?.length > 0;

  useEffect(() => {
    if (hasConflict) {
      setFieldValue("hasMarksConflict", true);
    } else {
      setFieldValue("hasMarksConflict", false);
    }
  }, [hasConflict, hasMarksConflict, mark, setFieldValue]);

  function onClickSave() {
    if (hasConflictingEids) {
      showScanDiscrepancyDialog();
    } else {
      typeof onAddToScanLot === "function" && onAddToScanLot();

      dispatch(setSetting(Settings.savedScanLotRoundId, saleRoundId));

      dispatch(setSetting(Settings.savedScanLotDeploymentId, deploymentId));
    }
  }

  function onClickSaveAndNext() {
    if (hasConflictingEids) {
      showScanDiscrepancyDialog();
    } else {
      typeof onAddToScanLot === "function" &&
        onAddToScanLot({ moveToNext: true });

      dispatch(setSetting(Settings.scanPenOrdering, ScanPenOrdering.forward));

      dispatch(setSetting(Settings.savedScanLotRoundId, saleRoundId));

      dispatch(setSetting(Settings.savedScanLotDeploymentId, deploymentId));
    }
  }

  function onClickSaveAndPrev() {
    if (hasConflictingEids) {
      showScanDiscrepancyDialog();
    } else {
      typeof onAddToScanLot === "function" &&
        onAddToScanLot({ moveToPrev: true });
      dispatch(setSetting(Settings.scanPenOrdering, ScanPenOrdering.reverse));

      dispatch(setSetting(Settings.savedScanLotRoundId, saleRoundId));

      dispatch(setSetting(Settings.savedScanLotDeploymentId, deploymentId));
    }
  }

  function onClickClose() {
    typeof onCancel === "function" && onCancel();
  }

  const saveAndMoveButtons = [
    {
      title: (
        <>
          Save <FaIcon icon={faAngleRight} /> {nextPenTitle}
        </>
      ),
      onClick: onClickSaveAndNext,
      default: penOrdering === ScanPenOrdering.forward,
      isDisabled: isSaveDisabled || !hasNextPen,
      dataTour: "saveAndNext",
    },
    {
      title: (
        <>
          Save <FaIcon icon={faAngleLeft} /> {prevPenTitle}
        </>
      ),
      onClick: onClickSaveAndPrev,
      default: penOrdering === ScanPenOrdering.reverse,
      isDisabled: isSaveDisabled || !hasPrevPen,
      dataTour: "saveAndPrev",
    },
  ];

  return (
    <Wrapper
      justify="space-between"
      className="w-full absolute bottom-0 bg-white"
    >
      <Row minWidth="25%">
        <DeleteButton
          data-tour="close"
          onClick={onClickClose}
          disabled={isSaveDisabled}
          fullWidth
        >
          Close
        </DeleteButton>
      </Row>
      <Row minWidth="25%">
        <BaseButton
          data-tour="save"
          onClick={onClickSave}
          disabled={isSaveDisabled}
          fullWidth
        >
          Save
        </BaseButton>
      </Row>
      <Row minWidth="25%">
        <MultiButton buttons={saveAndMoveButtons} fullWidth />
      </Row>
    </Wrapper>
  );
}

ScanLotScanListFooter.propTypes = {
  onAddToScanLot: PropTypes.func,
  onCancel: PropTypes.func,
};

export function ScanListFooterComponent(props) {
  const { scanListType } = props;
  if (scanListType === ScanListType.SaleLot) {
    return <SaleLotScanListFooter {...props} />;
  } else if (scanListType === ScanListType.ScanLot) {
    return <ScanLotScanListFooter {...props} />;
  }
  return null;
}
export default memo(ScanListFooterComponent);
