import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  name: "name",
  id: "id",
};

export const serializeBillingTag = buildSerializer(serializeMap, "billingTag");

export const deserializeBillingTag = buildDeserializer(
  serializeMap,
  "billingTag",
);
