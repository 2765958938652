import { keyBy } from "lodash";

import {
  ACCEPT_IMPORT_PENDING_SCANS,
  ADD_SCAN_FROM_SCANNER,
  ADD_SCANS_FROM_BUFFER,
  ADD_SCANS_FROM_FILE,
  ADD_SCANS_FROM_MANUAL_INPUT,
  ADD_SCANS_FROM_SOCKET,
  ADD_WEIGHT_FROM_SCALES,
  AUCTION_PEN,
  BULK_MOVE_SCANS_OFFLINE,
  BULK_MOVE_SCANS_ROLLBACK,
  CLEAR_IMPORTED_SCANS,
  CONNECT_TO_DEVICE,
  DELETE_SALE_LOT_SCANS_OFFLINE,
  DELETE_SCAN_FROM_SOCKET,
  DELETE_SCANS_FROM_DEVICE_IMPORT,
  DELETE_SCANS_FROM_FILE_IMPORT,
  DELETE_SCANS_FROM_UNASSIGNED,
  DEVICE_CONNECTED,
  DISCONNECT_FROM_DEVICE,
  GET_DEVICE_TIME_RESULT,
  GET_SCANS,
  GET_SCANS_CHANGES_SUCCESS,
  GET_SCANS_FAILURE,
  GET_SCANS_SUCCESS,
  HUB_CONNECTED,
  HUB_DISCONNECTED,
  IMPORT_MT_HOST_SESSIONS,
  KEEP_SCANS_IN_DEVICE_IMPORT,
  KEEP_SCANS_IN_FILE_IMPORT,
  KEEP_SCANS_IN_UNASSIGNED,
  ADD_SCANS_IN_UNASSIGNED,
  MERGE_SALE_LOT_OFFLINE,
  READ_SAVED_SCANS,
  READ_SAVED_SCANS_RESULT,
  SALE_LOT,
  SCAN,
  SELL_FILE,
  SELL_REVERSAL,
  SET_ANIMAL_DECEASED_COMMIT,
  SET_ANIMAL_DECEASED_OFFLINE,
  SET_ANIMAL_DECEASED_ROLLBACK,
  SET_AVAILABLE_DEVICES,
  SET_DEVICE_STATUS,
  SET_WEIGHING_SESSIONS,
  TAKE_FILE,
  TAKE_REVERSAL,
  UPDATE_DEVICE_PROTOCOL_STATUS,
  UPDATE_SCAN_NLIS_COMMIT,
  UPLOAD_NLIS_ID_COMMIT,
  UPLOAD_NLIS_ID_OFFLINE,
  UPLOAD_NLIS_ID_ROLLBACK,
  UPLOAD_SCANS_COMMIT,
  UPLOAD_SCANS_OFFLINE,
  UPLOAD_SCANS_ROLLBACK,
  WEIGH_LOT,
} from "constants/actionTypes";
import { SCAN_NLIS_STATUS } from "constants/nlis";
import { UNALLOCATED } from "constants/scanner";
import { UNIX_START_TIME } from "constants/time";

import {
  MT_HOST_DRIVERS,
  PLC_DRIVERS,
  WEIGHBRIDGE_DRIVERS,
} from "lib/deviceDrivers";
import toast from "lib/toast";

const MAX_WEIGHT_HISTORY_COUNT = 20;

const initialState = {
  scans: {},
  scanBuffer: [],
  unassignedScans: {},
  weightHistory: [],
  context: {
    connectedScales: [],
    connectedPLCs: [],
    connectedMtHostSessions: { byDeviceId: {}, isImporting: false },
  },
  availableDevices: [],
  connectedDeviceId: null,
  isHubConnected: false,
  lastModifiedTimestamp: UNIX_START_TIME,
  importedScans: {},
  importPendingScans: {},
  uploadedScans: {},
  manualInputScans: {},
  importRunning: false,
  deviceTimes: {},
  isFetching: false,
  draftingInformation: {},
};

const getScansFromAction = action => {
  if ([SCAN.CREATE.REQUEST, SCAN.CREATE.SUCCESS].includes(action.type)) {
    return action.payload;
  } else if (Array.isArray(action.scans)) {
    return action.scans;
  } else {
    return action.scans.scans;
  }
};

const scanners = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCANS:
      return {
        ...state,
        scans: {},
        isFetching: true,
      };

    case ADD_SCANS_FROM_BUFFER: {
      // Shortcircuit if the buffer is empty - saves a bunch of updates!
      if (state.scanBuffer.length === 0) {
        return state;
      }

      // Move everything from the buffer into the unassignedScans blob.
      const existingScans = state.scans;
      const updatedScans = {};
      state.scanBuffer.forEach(scan => {
        const duplicateScan = existingScans[scan.EID];
        if (duplicateScan && duplicateScan.sale_lot_id) {
          updatedScans[scan.EID] = {
            ...scan,
            duplicateSaleLotId: duplicateScan.sale_lot_id,
          };
        } else {
          updatedScans[scan.EID] = { ...scan };
        }
      });

      return {
        ...state,
        unassignedScans: {
          ...state.unassignedScans,
          ...updatedScans,
        },
        scanBuffer: [],
      };
    }
    case CONNECT_TO_DEVICE: {
      const availableDevices = state.availableDevices.map(device => ({
        ...device,
        isConnecting:
          device.deviceId === action.deviceId ? true : device.isConnecting,
      }));

      return {
        ...state,
        availableDevices,
      };
    }

    case ADD_SCANS_FROM_FILE: {
      const { scans, fileName } = action;
      const newState = { ...state };
      newState.uploadedScans = scans.reduce((acc, scan) => {
        acc[scan.EID] = {
          sessionName: scan.draftName,
          eid: scan.EID,
          fileName,
          created: Date.parse(scan.created),
        };
        return acc;
      }, {});
      return newState;
    }
    case ADD_SCANS_FROM_MANUAL_INPUT: {
      const { scans } = action;
      const newState = { ...state };
      newState.manualInputScans = scans.reduce((acc, scan) => {
        acc[scan.EID] = {
          eid: scan.EID,
        };
        return acc;
      }, {});
      return newState;
    }
    case ADD_SCAN_FROM_SCANNER: {
      // Stick things on the end of a list (that isn't rendered!)
      const { scan } = action;
      const scanBuffer = state.scanBuffer.concat([
        {
          deviceId: state.connectedDeviceId,
          deviceName: state.availableDevices.find(
            device => device.deviceId === state.connectedDeviceId,
          )?.name,
          ...scan,
        },
      ]);

      return {
        ...state,
        scanBuffer,
      };
    }

    case KEEP_SCANS_IN_UNASSIGNED: {
      const { eids } = action;
      const newState = { ...state };
      newState.unassignedScans = { ...newState.unassignedScans };
      eids.forEach(
        eid => (newState.unassignedScans[eid].ignoreDuplicate = true),
      );
      return newState;
    }

    // Put the scans back into unallocated, used when allocating to lot failed
    case ADD_SCANS_IN_UNASSIGNED: {
      const { scans } = action;
      const newState = { ...state };
      newState.unassignedScans = { ...newState.unassignedScans };
      newState.scans = { ...newState.scans };
      scans.forEach(scan => {
        newState.unassignedScans[scan.EID] = scan;
        delete newState.scans[scan.EID];
      });
      return newState;
    }
    case KEEP_SCANS_IN_DEVICE_IMPORT: {
      const { eids } = action;
      const newState = { ...state };
      newState.importedScans = { ...newState.importedScans };
      eids.forEach(eid => (newState.importedScans[eid].ignoreDuplicate = true));
      return newState;
    }
    case KEEP_SCANS_IN_FILE_IMPORT: {
      const { eids } = action;
      const newState = { ...state };
      newState.uploadedScans = { ...newState.uploadedScans };
      eids.forEach(eid => (newState.uploadedScans[eid].ignoreDuplicate = true));
      return newState;
    }

    case DELETE_SCANS_FROM_UNASSIGNED: {
      const { eids } = action;
      const newState = { ...state };
      eids.forEach(id => {
        delete newState.unassignedScans[id];
      });
      newState.unassignedScans = { ...newState.unassignedScans };
      return newState;
    }
    case DELETE_SCANS_FROM_DEVICE_IMPORT: {
      const { eids } = action;
      const newState = { ...state };
      eids.forEach(id => {
        delete newState.importedScans[id];
      });
      newState.importedScans = { ...newState.importedScans };
      return newState;
    }
    case DELETE_SCANS_FROM_FILE_IMPORT: {
      const { eids } = action;
      const newState = { ...state };
      eids.forEach(id => {
        delete newState.uploadedScans[id];
      });
      newState.uploadedScans = { ...newState.uploadedScans };
      return newState;
    }
    case DEVICE_CONNECTED: {
      // This event is emitted when a device is either connected, or
      // disconnected. It's more of a connection status then an OnConnectEvent

      const isConnected = action.object.device.status === "connected";

      const availableDevices = [];

      // Get a handle to the device
      let device = null;
      for (const availableDevice of state.availableDevices) {
        if (availableDevice.deviceId === action.deviceId) {
          device = availableDevice;
          // Update the device type with the new one (if present) The hub may be able to
          // determine this is a new device type on connection, and subsequently pushes
          // the new data
          if (
            action.object.deviceType &&
            device.deviceType !== action.object.deviceType
          ) {
            device.deviceType = action.object.deviceType;
          }
        }

        availableDevices.push({
          ...availableDevice,
          status:
            availableDevice.deviceId === action.deviceId
              ? action.object.device.status
              : availableDevice.status,
          isConnecting:
            availableDevice.deviceId === action.deviceId
              ? undefined
              : availableDevice.isConnecting,
        });
      }

      // If we aren't aware of the device relating to the action, there's nothing that we can do
      if (!device) {
        return state;
      }
      const isScaleDevice = WEIGHBRIDGE_DRIVERS.indexOf(device.deviceType) > -1;
      let connectedScales = [...state.context.connectedScales];

      const isPLCDevice = PLC_DRIVERS.indexOf(device.deviceType) > -1;
      let connectedPLCs = [...state.context.connectedPLCs];

      const connectedMtHostSessions = {
        ...state.context.connectedMtHostSessions,
      };

      let { connectedDeviceId } = state;

      // Clear the isConnecting flag from the device state
      device.isConnecting = false;

      if (isConnected) {
        if (isScaleDevice) {
          connectedScales.push(device.deviceId);
        } else if (isPLCDevice) {
          connectedPLCs.push(device.deviceId);
        } else {
          connectedDeviceId = device.deviceId;
        }
      } else if (isScaleDevice) {
        // Handle a weighbridge disconnection
        connectedScales = connectedScales.filter(
          deviceId => deviceId !== device.deviceId,
        );
      } else if (isPLCDevice) {
        // Handle a PLC disconnection
        connectedPLCs = connectedPLCs.filter(
          deviceId => deviceId !== device.deviceId,
        );
      } else if (connectedDeviceId === device.deviceId) {
        // Handle a scanner disconnection
        // Set the device as not connected
        connectedDeviceId = null;
      }

      return {
        ...state,
        availableDevices,
        connectedDeviceId,
        context: {
          ...state.context,
          connectedScales,
          connectedPLCs,
          connectedMtHostSessions,
        },
      };
    }

    case SET_DEVICE_STATUS: {
      const { deviceId, status } = action;
      return {
        ...state,
        availableDevices: state.availableDevices.map(d => {
          return {
            ...d,
            status: d.deviceId === deviceId ? status : d.status,
          };
        }),
      };
    }

    case GET_DEVICE_TIME_RESULT: {
      // When a device sends over it's time, save it.
      const deviceId = state.connectedDeviceId;
      const { deviceTime } = action;
      if (deviceId) {
        return {
          ...state,
          deviceTimes: {
            ...state.deviceTimes,
            [deviceId]: {
              deviceTime,
              readTime: Date(),
            },
          },
        };
      }
      return state;
    }

    case CLEAR_IMPORTED_SCANS: {
      const newState = { ...state };
      newState.importedScans = {};
      return newState;
    }

    case READ_SAVED_SCANS: {
      return {
        ...state,
        importRunning: true,
      };
    }
    case READ_SAVED_SCANS_RESULT: {
      // Result of pulling scans of a scan import.
      const { success, importedScans } = action;
      const importPendingScans = Object.keys(importedScans).reduce(
        (acc, curr) => {
          // Don't commit empty groups to the state
          if (importedScans[curr].length) {
            // Convert the scanTime from a string to an easily comparable date
            acc[curr] = importedScans[curr].map(imported => ({
              EID: imported.EID,
              scanTime: Date.parse(imported.scanTime),
              deviceName: state.availableDevices.find(
                device => device.deviceId === state.connectedDeviceId,
              )?.name,
            }));
            acc[curr].sort((a, b) => b.scanTime - a.scanTime);
          }
          return acc;
        },
        {},
      );
      const newState = { ...state };
      newState.importRunning = false;
      newState.importPendingScans = success ? importPendingScans : {};
      return newState;
    }

    case UPLOAD_SCANS_OFFLINE: {
      const { scans, saleLotId } = action.payload;

      const updatedScans = {};
      scans.forEach(scan => {
        if (!scan.EID) {
          return;
        }
        const { animal = {} } = state.scans[scan.EID] || {};
        updatedScans[scan.EID] = {
          ...scan,
          animal,
          sale_lot_id: saleLotId || UNALLOCATED,
        };
      });
      return {
        ...state,
        scans: {
          ...state.scans,
          ...updatedScans,
        },
      };
    }

    case SALE_LOT.UPDATE.REQUEST: {
      // If the patch contains updated scan information, suck that in.
      if (action.payload.patch.scans) {
        const updatedScans = action.payload.patch.scans.reduce((acc, cur) => {
          acc[cur.EID] = {
            ...state.scans[cur.EID],
            ...cur,
          };
          return acc;
        }, {});

        return {
          ...state,
          scans: {
            ...state.scans,
            ...updatedScans,
          },
        };
      }
      return state;
    }

    case BULK_MOVE_SCANS_OFFLINE: {
      const { eids, saleLotId } = action.payload;

      const updatedScans = {};
      eids.forEach(eid => {
        updatedScans[eid] = {
          ...state.scans[eid],
          sale_lot_id: saleLotId,
        };
      });

      return {
        ...state,
        scans: {
          ...state.scans,
          ...updatedScans,
        },
      };
    }

    case BULK_MOVE_SCANS_ROLLBACK: {
      const { eids, currentSaleLotIdMap } = action.meta;

      const updatedScans = {};
      eids.forEach(eid => {
        updatedScans[eid] = {
          ...state.scans[eid],
          sale_lot_id: currentSaleLotIdMap[eid],
        };
      });

      return {
        ...state,
        scans: {
          ...state.scans,
          ...updatedScans,
        },
      };
    }

    case ACCEPT_IMPORT_PENDING_SCANS: {
      const { deviceId, selections } = action;
      const newState = { ...state };
      newState.importedScans = selections.reduce((acc, curr) => {
        const importGroup = newState.importPendingScans[curr.groupName];
        const importedEid = importGroup.find(
          imported => imported.EID === curr.eid,
        );
        acc[curr.eid] = {
          created: importedEid.scanTime,
          deviceId,
          deviceName: importedEid.deviceName,
          eid: importedEid.EID,
          removeFromSaleLot: false,
          sessionName: curr.groupName,
        };
        return acc;
      }, {});
      newState.importPendingScans = {};
      return newState;
    }

    case UPLOAD_SCANS_ROLLBACK: {
      const { scans } = action.meta;
      const originalScans = scans.reduce((acc, scan) => {
        if (scan.EID) {
          acc[scan.EID] = scan;
        }
        return acc;
      }, {});
      return {
        ...state,
        scans: {
          ...state.scans,
          ...originalScans,
        },
      };
    }
    case UPLOAD_SCANS_COMMIT:
    case UPDATE_SCAN_NLIS_COMMIT: {
      const { payload } = action;
      const scan_payload = Array.isArray(payload) ? payload : payload.scans;
      const updatedScans = scan_payload.reduce((acc, scan) => {
        acc[scan.EID] = {
          ...state.scans[scan.EID],
          ...scan,
          animal: { ...scan.animal },
          sale_lot_id: scan.sale_lot_id || UNALLOCATED,
        };
        return acc;
      }, {});

      return {
        ...state,
        scans: {
          ...state.scans,
          ...updatedScans,
        },
      };
    }
    case DELETE_SALE_LOT_SCANS_OFFLINE: {
      const { saleLotId } = action.payload;

      const changedScans = Object.values(state.scans).reduce((acc, scan) => {
        if (scan.sale_lot_id === saleLotId) {
          acc[scan.EID] = {
            ...scan,
            sale_lot_id: null,
          };
        }
        return acc;
      }, {});

      return {
        ...state,
        scans: {
          ...state.scans,
          ...changedScans,
        },
      };
    }

    case SET_AVAILABLE_DEVICES:
      let connectedDeviceId = null;
      const connectedScales = [...state.context.connectedScales];
      const connectedPLCs = [...state.context.connectedPLCs];
      const connectedMtHostSessions = {
        ...state.context.connectedMtHostSessions,
      };

      for (const device of action.devices) {
        const isScaleDevice =
          WEIGHBRIDGE_DRIVERS.indexOf(device.deviceType) > -1;

        const isPLCDevice = PLC_DRIVERS.indexOf(device.deviceType) > -1;

        const isMtHostLocation =
          MT_HOST_DRIVERS.indexOf(device.deviceType) > -1;

        if (isScaleDevice) {
          if (device.status === "connected") {
            connectedScales.push(device.deviceId);
          } else {
            const existingIndex = connectedScales.indexOf(device.deviceId);
            if (existingIndex > -1) {
              connectedScales.splice(existingIndex, 1);
            }
          }
        } else if (isPLCDevice) {
          if (device.status === "connected") {
            connectedPLCs.push(device.deviceId);
          } else {
            const existingIndex = connectedPLCs.indexOf(device.deviceId);
            if (existingIndex > -1) {
              connectedPLCs.splice(existingIndex, 1);
            }
          }
        } else if (isMtHostLocation) {
          if (device.status === "connected") {
            connectedMtHostSessions.byDeviceId[device.deviceId] = [];
          } else if (action.deviceId in connectedMtHostSessions.byDeviceId) {
            delete connectedMtHostSessions.byDeviceId[action.deviceId];
          }
        } else if (device.status === "connected") {
          connectedDeviceId = device.deviceId;
        }
      }

      return {
        ...state,
        availableDevices: action.devices,
        connectedDeviceId,
        context: {
          ...state.context,
          connectedScales,
          connectedPLCs,
          connectedMtHostSessions,
        },
      };

    case IMPORT_MT_HOST_SESSIONS:
      return {
        ...state,
        context: {
          ...state.context,
          connectedMtHostSessions: {
            ...state.context.connectedMtHostSessions,
            isImporting: true,
          },
        },
      };

    case WEIGH_LOT.IMPORT.ACTION:
      return {
        ...state,
        context: {
          ...state.context,
          connectedMtHostSessions: {
            ...state.context.connectedMtHostSessions,
            isImporting: false,
          },
        },
      };

    case GET_SCANS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case ADD_SCANS_FROM_SOCKET:
    case GET_SCANS_SUCCESS:
    case GET_SCANS_CHANGES_SUCCESS:
    case SCAN.CREATE.REQUEST:
    case SCAN.CREATE.SUCCESS: {
      // if scan exists locally and on server, take server's saleLotId and
      // mark duplicate
      const localScans =
        action.type === GET_SCANS_SUCCESS ? {} : { ...state.scans };

      // Manage either response type pusher still pushes us lists, as
      // did old endpoints; "new" endpoints now give us scans on an
      // attribute; this allows for additional information (eg NLIS
      // errors) to propagate.

      const scans = getScansFromAction(action);

      // Don't change the state when nothing has changed, also handle set isFetching to false
      // when handling GET_SCANS_SUCCESS, otherwise isFetching may never be set to false
      if (scans.length === 0 && !state.isFetching) {
        return state;
      }

      const newScans = scans.filter(scan => scan.deleted !== true);
      const deletedScanIds = scans
        .filter(scan => scan.deleted === true)
        .map(scan => scan.id);

      const remainScans = keyBy(
        Object.values(localScans).filter(
          scan => !deletedScanIds.includes(scan.id),
        ),
        scan => scan.EID,
      );

      const updatedScans = newScans.reduce((acc, scan) => {
        acc[scan.EID] = {
          ...(state.scans[scan.EID] || {}), // Keep the old data if it's already in state
          ...scan, // Add our new data.
          sale_lot_id: scan.sale_lot_id || UNALLOCATED,
        };
        return acc;
      }, {});

      // If any of the EIDs are in our current scan screen, mark them out.
      const unassignedScans = { ...state.unassignedScans };
      Object.keys(updatedScans).forEach(EID => {
        if (unassignedScans[EID] && updatedScans[EID].sale_lot_id) {
          unassignedScans[EID].duplicateSaleLotId =
            updatedScans[EID].sale_lot_id;
        }
      });

      // Don't let pusher dictate the last change we saw.
      let { lastModifiedTimestamp } = state;
      if (action.type !== ADD_SCANS_FROM_SOCKET) {
        lastModifiedTimestamp =
          action.lastModifiedTimestamp ||
          action.scans?.lastModifiedTimestamp ||
          action.meta?.lastModifiedTimestamp ||
          lastModifiedTimestamp;
      }

      return {
        ...state,
        scans: {
          ...remainScans,
          ...updatedScans,
        },
        unassignedScans,
        lastModifiedTimestamp,
        isFetching: false,
      };
    }
    case DISCONNECT_FROM_DEVICE:
      // Only disconnect if this device is the primary connected device
      if (state.connectedDeviceId === action.deviceId) {
        return {
          ...state,
          connectedDeviceId: null,
          importPendingScans: {},
        };
      }

      // Maybe the device was a set of scales?
      const scaleIndex = state.context.connectedScales.findIndex(
        device => device.deviceId === action.deviceId,
      );
      if (scaleIndex > -1) {
        // It sure was a set of scales!
        const connectedScales = state.context.connectedScales.slice();
        connectedScales.splice(scaleIndex, 1);
        return {
          ...state,
          context: {
            ...state.context,
            connectedScales,
          },
        };
      }

      // Maybe the device was a PLC?
      const plcIndex = state.context.connectedPLCs.findIndex(
        device => device.deviceId === action.deviceId,
      );
      if (plcIndex > -1) {
        // It sure was a PLC!
        const connectedPLCs = state.context.connectedPLCs.slice();
        connectedPLCs.splice(scaleIndex, 1);
        return {
          ...state,
          availableDevices: state.availableDevices.map(device => ({
            ...device,
            status:
              device.deviceId === action.deviceId
                ? action.object.device.status
                : device.status,
          })),
          context: {
            ...state.context,
            connectedPLCs,
          },
        };
      }

      return state;

    case SET_WEIGHING_SESSIONS:
      const mtHostSessions = action.weighingSessions.map(weighingSession => ({
        deviceId: action.deviceId,
        fileName: weighingSession,
      }));

      return {
        ...state,
        context: {
          ...state.context,
          connectedMtHostSessions: {
            ...state.context.connectedMtHostSessions,
            byDeviceId: {
              ...state.context.connectedMtHostSessions.byDeviceId,
              [action.deviceId]: mtHostSessions,
            },
          },
        },
      };

    case HUB_CONNECTED:
      return {
        ...state,
        isHubConnected: true,
      };
    case HUB_DISCONNECTED:
      return {
        ...state,
        connectedDeviceId: null,
        availableDevices: [],
        context: {
          ...state.context,
          connectedScales: [],
          connectedPLCs: [],
          connectedMtHostSessions: { byDeviceId: {}, isImporting: false },
        },
        isHubConnected: false,
        importPendingScans: {},
      };
    case DELETE_SCAN_FROM_SOCKET: {
      const { EID } = action;
      // Don't update state if nothings actually changed.
      if (!state.scans[EID]) {
        return state;
      }
      const updatedScans = {
        ...state.scans,
      };
      delete updatedScans[EID];
      return {
        ...state,
        scans: updatedScans,
      };
    }
    case SCAN.DELETE.REQUEST: {
      const { scan } = action.payload;
      const newScans = { ...state.scans };
      delete newScans[scan.EID];

      return {
        ...state,
        scans: newScans,
      };
    }

    case SCAN.DELETE.FAILURE: {
      const { scan } = action.meta;
      return {
        ...state,
        scans: {
          ...state.scans,
          [scan.EID]: scan,
        },
      };
    }

    case UPLOAD_NLIS_ID_OFFLINE: {
      const {
        payload: { payload: scans },
      } = action;

      const newState = { ...state };
      const scansStore = { ...newState.scans };
      newState.scans = scansStore;

      scans.forEach(scan => {
        const eid = `(${scan.NLISID})`;

        scansStore[eid] = {
          ...scan,
          EID: eid,
          sale_lot_id: UNALLOCATED,
        };
      });
      return newState;
    }
    case UPLOAD_NLIS_ID_ROLLBACK: {
      const { meta } = action;
      const { scans } = meta;

      const newState = { ...state };
      const scansStore = { ...newState.scans };
      newState.scans = scansStore;

      scans.forEach(scan => {
        const eid = `(${scan.NLISID})`;
        delete scansStore[eid];
      });

      return newState;
    }
    case UPLOAD_NLIS_ID_COMMIT: {
      const { payload } = action;

      const newState = { ...state };
      const scansStore = { ...newState.scans };
      newState.scans = scansStore;

      payload.scans.forEach(scan => {
        const offlineEID = `(${scan.animal.nlis_id})`;
        delete scansStore[offlineEID];

        scansStore[scan.EID] = scan;
        scansStore[scan.EID].sale_lot_id = UNALLOCATED;
      });

      return newState;
    }

    case SET_ANIMAL_DECEASED_COMMIT: {
      const {
        meta: { scan },
      } = action;
      toast.success(`Successfully updated deceased state for EID: ${scan.EID}`);
      return state;
    }
    case SET_ANIMAL_DECEASED_ROLLBACK: {
      const {
        meta: { scan },
      } = action;
      toast.error(`Error updating deceased state: ${scan.EID}`);
      return {
        ...state,
        scans: {
          ...state.scans,
          [scan.EID]: {
            ...state.scans[scan.EID],
            animal: {
              ...state.scans[scan.EID].animal,
              marked_deceased: scan.animal.marked_deceased,
            },
          },
        },
      };
    }
    case SET_ANIMAL_DECEASED_OFFLINE: {
      const {
        payload: { scan, is_deceased },
      } = action;
      return {
        ...state,
        scans: {
          ...state.scans,
          [scan.EID]: {
            ...state.scans[scan.EID],
            animal: {
              ...state.scans[scan.EID].animal,
              marked_deceased: is_deceased ? new Date().toISOString() : null,
            },
          },
        },
      };
    }

    case MERGE_SALE_LOT_OFFLINE: {
      const { fromSaleLotId, toSaleLotId } = action.payload;

      const unassignedScans = Object.values(state.unassignedScans).reduce(
        (acc, scan) => {
          if (scan.duplicateSaleLotId === fromSaleLotId) {
            acc[scan.EID] = { ...scan, duplicateSaleLotId: toSaleLotId };
          } else {
            acc[scan.EID] = scan;
          }
          return acc;
        },
        {},
      );

      const reAssignedScans = Object.values(state.scans).reduce((acc, scan) => {
        if (scan.sale_lot_id === fromSaleLotId) {
          acc[scan.EID] = { ...scan, sale_lot_id: toSaleLotId };
        } else {
          acc[scan.EID] = scan;
        }
        return acc;
      }, {});

      return {
        ...state,
        scans: reAssignedScans,
        unassignedScans,
      };
    }

    case ADD_WEIGHT_FROM_SCALES: {
      const { deviceId, timestamp, weightGrams, isStable } = action;
      const weightHistory = [
        ...state.weightHistory,
        {
          deviceId,
          timestamp,
          weightGrams,
          isStable,
        },
      ];
      if (weightHistory.length > MAX_WEIGHT_HISTORY_COUNT) {
        weightHistory.shift();
      }
      return {
        ...state,
        weightHistory,
      };
    }

    case SCAN.UPDATE.REQUEST: {
      const {
        meta: { EID, payload },
      } = action;

      return {
        ...state,
        scans: {
          ...state.scans,
          [EID]: { ...state.scans[EID], ...payload },
        },
      };
    }

    case SCAN.UPDATE.SUCCESS: {
      const { payload } = action;
      const { EID } = payload;
      return {
        ...state,
        scans: {
          ...state.scans,
          [EID]: payload,
        },
      };
    }

    case AUCTION_PEN.MOVE_SCANS.REQUEST:
      const {
        meta: { toPenId, eids },
      } = action;

      const updatedScans = eids.reduce(
        (acc, eid) => {
          acc[eid] = {
            ...state.scans[eid],
            current_auction_pen_id: toPenId,
          };
          return acc;
        },
        { ...state.scans },
      );
      return {
        ...state,
        scans: updatedScans,
      };

    case UPDATE_DEVICE_PROTOCOL_STATUS:
      return {
        ...state,
        availableDevices: state.availableDevices.map(device =>
          device.deviceId === action.deviceId
            ? {
                ...device,
                protocolStatus: action.protocolStatus,
              }
            : device,
        ),
      };

    case TAKE_FILE.CREATE_BULK.REQUEST: {
      const { payload } = action;
      const newState = { ...state, scans: { ...state.scans } };
      for (const takePossession of payload) {
        const transfers = takePossession.transfers || [];
        for (const transfer of transfers) {
          for (const transferEid of transfer.eids) {
            const newScan = {
              ...newState.scans[transferEid.EID],
              latest_take_file_id: takePossession.id,
            };
            newState.scans[transferEid.EID] = newScan;
          }
        }
      }
      return newState;
    }
    case SELL_FILE.CREATE_BULK.REQUEST: {
      const { payload } = action;
      const newState = { ...state, scans: { ...state.scans } };
      for (const sellPossession of payload) {
        const transfers = sellPossession.transfers || [];
        for (const transfer of transfers) {
          for (const transferEid of transfer.eids) {
            const newScan = {
              ...newState.scans[transferEid.EID],
              latest_sell_file_id: sellPossession.id,
            };
            newState.scans[transferEid.EID] = newScan;
          }
        }
      }
      return newState;
    }

    case SELL_REVERSAL.CREATE_BULK.REQUEST: {
      // When the reversal is initiated, set all scans that would be corrected/touched to pending to prevent multiple reversals.
      const newState = { ...state, scans: { ...state.scans } };
      for (const scan of Object.values(state.scans)) {
        if (scan.nlis_sell_status === SCAN_NLIS_STATUS.NEEDS_CORRECTING) {
          newState.scans[scan.EID] = {
            ...scan,
            nlis_sell_status: SCAN_NLIS_STATUS.PENDING,
          };
        }
      }
      return newState;
    }

    case TAKE_REVERSAL.CREATE_BULK.REQUEST: {
      // When the reversal is initiated, set all scans that would be corrected/touched to pending to prevent multiple reversals.
      const newState = { ...state, scans: { ...state.scans } };
      for (const scan of Object.values(state.scans)) {
        if (scan.nlis_take_status === SCAN_NLIS_STATUS.NEEDS_CORRECTING) {
          newState.scans[scan.EID] = {
            ...scan,
            nlis_take_status: SCAN_NLIS_STATUS.PENDING,
          };
        }
      }
      return newState;
    }

    default:
      return state;
  }
};
export default scanners;
