import React from "react";

export default ({ color, className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <title>1FB08F79-D6F4-42A6-A1FC-AED62143F74E</title>
    <desc>Created with sketchtool.</desc>
    <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Scanning-states"
        transform="translate(-325.000000, -260.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g
          id="Scanner-Row/Tag-Lookup/1.-Normal-Copy-4"
          transform="translate(0.000000, 233.000000)"
        >
          <g
            id="icons/search/deselected"
            transform="translate(325.000000, 19.000000)"
          >
            <g id="Group-2-Copy-5">
              <path
                d="M23.8358807,29.9797035 C24.0562018,30.1999788 24.0562018,30.5562554 23.8358807,30.7765766 L22.7765265,31.8359307 C22.5562054,32.0562519 22.1999288,32.0562519 21.9796534,31.8359307 L16.2890237,26.145301 C16.1858898,26.0374979 16.1249616,25.8968732 16.1249616,25.7468644 L16.1249616,25.1281525 C14.4140281,26.6047115 12.1874709,27.5000036 9.74997675,27.5000036 C4.36403378,27.5000036 0,23.1359698 0,17.7500268 C0,12.3640839 4.36403378,8.00005007 9.74997675,8.00005007 C15.1359197,8.00005007 19.4999535,12.3640839 19.4999535,17.7500268 C19.4999535,20.187521 18.6046615,22.4140782 17.1281025,24.1250116 L17.7468144,24.1250116 C17.8968232,24.1250116 18.0374478,24.1812706 18.1452509,24.2890737 L23.8358807,29.9797035 Z M9.74997675,25.2500089 C13.8937352,25.2500089 17.2499589,21.8937853 17.2499589,17.7500268 C17.2499589,13.6062684 13.8937352,10.2500447 9.74997675,10.2500447 C5.60621832,10.2500447 2.24999464,13.6062684 2.24999464,17.7500268 C2.24999464,21.8937853 5.60621832,25.2500089 9.74997675,25.2500089 Z"
                id=""
              />
              <path
                d="M7.59277344,21 L6.0546875,21 L6.0546875,14.6015625 L7.59277344,14.6015625 L7.59277344,21 Z M9.16503906,21 L9.16503906,14.6015625 L11.2260742,14.6015625 C11.7915067,14.6015625 12.2998024,14.729735 12.7509766,14.986084 C13.2021507,15.2424329 13.5544421,15.6042457 13.8078613,16.0715332 C14.0612806,16.5388207 14.1894531,17.0624971 14.1923828,17.6425781 L14.1923828,17.9370117 C14.1923828,18.5229521 14.0686048,19.0480934 13.8210449,19.5124512 C13.5734851,19.976809 13.2248558,20.3400866 12.7751465,20.6022949 C12.3254372,20.8645033 11.8237332,20.9970703 11.2700195,21 L9.16503906,21 Z M10.7075195,15.7924805 L10.7075195,19.8134766 L11.2436523,19.8134766 C11.6860374,19.8134766 12.0258777,19.6560074 12.2631836,19.3410645 C12.5004895,19.0261215 12.6191406,18.5581086 12.6191406,17.9370117 L12.6191406,17.6601562 C12.6191406,17.0419891 12.5004895,16.5761734 12.2631836,16.2626953 C12.0258777,15.9492172 11.6801781,15.7924805 11.2260742,15.7924805 L10.7075195,15.7924805 Z"
                id="ID"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
