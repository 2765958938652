import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import IdConnectedList from "components/IdConnectedList";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";
import { Nomination } from "components/Nominations/Cards/Nomination";
import { GlobalSearchHeader } from "components/SearchInput/GlobalSearch";

import { ApiModel } from "constants/loading";
import { DeploymentPermissions } from "constants/permissions";

import {
  selectOrderedNominationIds,
  selectRoleCurrentDeployments,
} from "selectors";

import { useSomeHasPermission } from "hooks/useHasPermission";

import { NominationOverview } from "./NominationOverview";

export const NominationHdContainer = styled.div(
  ({ theme }) => `
  font-size: ${theme.fontSizes.gamma}px;
  font-weight: ${theme.fontWeights.bold};
  margin: ${theme.space[2]}px;
`,
);

function EmptyNominations() {
  const hasCreatePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.canNominateFor,
  );
  return (
    <MessageBox>
      No Nominations yet.
      {hasCreatePermission && " Click Create Nomination to get started"}
    </MessageBox>
  );
}

export const NominationCards = () => {
  const nominationIds = useSelector(selectOrderedNominationIds);

  return (
    <WaitForSync
      requiredData={[
        ApiModel.SALE_LOTS,
        ApiModel.CONSIGNMENTS,
        ApiModel.BUSINESSES,
        ApiModel.NOMINATIONS,
      ]}
    >
      <GlobalSearchHeader searchSize={12} buttonSize={3} />
      <NominationOverview />
      <IdConnectedList
        component={Nomination}
        emptyComponent={EmptyNominations}
        orderedIds={nominationIds}
      />
    </WaitForSync>
  );
};
