import { put, takeEvery } from "redux-saga/effects";

import { LedgerEntryAction } from "actions";

import { BILLING_RUN, LEDGER_ENTRY } from "constants/actionTypes";

function* onSubscribeBillingRun(action) {
  const { id } = action;
  yield put(LedgerEntryAction.request({ documentBillingRunId: id }));
}

function* rollbackLedgerEntry(action) {
  const { id } = action;
  if (id) {
    yield put(LedgerEntryAction.requestOne({ id }));
  }
}

export default function* legerEntrySagas() {
  yield takeEvery(BILLING_RUN.SUBSCRIBE.ACTION, onSubscribeBillingRun);
  yield takeEvery(LEDGER_ENTRY.DELETE.FAILURE, rollbackLedgerEntry);
  yield takeEvery(LEDGER_ENTRY.UPDATE.FAILURE, rollbackLedgerEntry);
}
