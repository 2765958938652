import React from "react";

import { PropTypes } from "prop-types";

import EmptyState from "./EmptyState";

const RenderData = ({ children, test, ...rest }) => {
  if (test) {
    return children;
  }
  return <EmptyState {...rest} />;
};

RenderData.propTypes = {
  children: PropTypes.any.isRequired,
  test: PropTypes.bool.isRequired,
};

export default RenderData;
