import React from "react";

import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";

import { Row } from "components/Layout";

import { FaIcon } from "./Elements";

const Wrapper = styled.div`
  padding: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StoredScanRow = ({ eid }) => {
  const displayEid = eid || "(EID Pending)";
  return (
    <Wrapper>
      <Row justifyBetween alignCenter>
        <Row baseline>
          <FaIcon icon={faCheck} color="success" title="This EID is saved." />
          {displayEid}
        </Row>
      </Row>
    </Wrapper>
  );
};

export default StoredScanRow;
