import { EXTERNAL_AGENT_XML } from "constants/actionTypes";

export function processExternalAgentXmlFile(file, fileContents) {
  return { type: EXTERNAL_AGENT_XML.PROCESS.ACTION, file, fileContents };
}

export function importFileSucceeded(_, type, errors = []) {
  return {
    type: type.IMPORT.SUCCESS,
    errors,
  };
}

export function importFileFailed(payload, type) {
  return {
    type: type.IMPORT.FAILURE,
    payload,
  };
}

export function processImportFile(file, fileContents, type) {
  return { type: type.PROCESS.ACTION, file, fileContents };
}

export function updateFileImport(payload, type) {
  return {
    type: type.UPDATE.REQUEST,
    payload,
  };
}

export function updateFileProductMapping(updatedProducts, type) {
  return updateFileImport({ products: updatedProducts }, type);
}

export function updateFileVendorMapping(updatedVendors, type) {
  return updateFileImport({ vendors: updatedVendors }, type);
}

export function updateFileBuyerMapping(updatedBuyers, type) {
  return updateFileImport({ buyers: updatedBuyers }, type);
}

export function updateFileRoundMapping(updateRounds, type) {
  return updateFileImport({ rounds: updateRounds }, type);
}

export function importFile(type) {
  return {
    type: type.IMPORT.ACTION,
  };
}
