import { uniq } from "lodash";
import { intersection } from "lodash/array";
import { createSelector } from "reselect";

import { IMAGE_FILE_EXTENSIONS, VIDEO_FILE_EXTENSIONS } from "constants/files";
import { NLIS_NOT_FOUND } from "constants/nlis";
import { UNALLOCATED } from "constants/scanner";

import {
  getIsWeighed,
  getSaleLotScannedStatus,
  getScanStatusThreshold,
  isLabelledDeceased,
  isNoSale,
  isPenned,
  isSold,
  isUnsold,
  isLabelledWithdrawn,
} from "lib/saleLot";

import {
  createIdByKeySelector,
  getActiveRole,
  getCurrentRoundsList,
  getCurrentSale,
  getCurrentSaleyard,
  getCurrentSpeciesId,
  getFiles,
  getLabels,
  getPenScanLots,
  getSaleLots,
  getScans,
  getSexes,
  getVendorSplitSaleLots,
  selectConsignmentIdsByDeploymentSaleIdLookup,
  selectCurrentDeploymentSaleIdsList,
  selectDeploymentIdByConsignmentIdLookup,
  selectEidsBySaleLotIdLookup,
  selectObjectHasMediaAttachmentLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleLotIdsByRoundIdLookup,
  selectVendorIdBySaleLotIdLookup,
  selectAttachmentIdsBySaleLotIdLookup,
  selectEidsByPenScanLotIdLookup,
  selectSaleLotIdByEidLookup,
  getProperties,
} from "selectors";

import { createLookupCombiner, createLookupSelectors } from "./lib";

export const getVendorIdBySaleLotId = saleLotId => state =>
  selectVendorIdBySaleLotIdLookup(state)[saleLotId] || null;

/**
 * Returns whether or not a Sale Lot has been sold, keyed by Sale Lot Id
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsSoldBySaleLotIdLookup = createIdByKeySelector(
  createSelector(
    [getSaleLots, selectVendorIdBySaleLotIdLookup],
    (saleLots, vendorIdBySaleLotId) =>
      Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
        acc[saleLotId] = {
          saleLotId,
          isSold: isSold(saleLot.buyer_id, vendorIdBySaleLotId[saleLotId]),
        };
        return acc;
      }, {}),
  ),
  "saleLotId",
  "isSold",
);

/**
 * Returns whether a Sale Lot has not been sold, keyed by Sale Lot Id
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsUnsoldBySaleLotIdLookup = createIdByKeySelector(
  createSelector([getSaleLots], saleLots =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      acc[saleLotId] = {
        saleLotId,
        isUnsold: isUnsold(saleLot.buyer_id),
      };
      return acc;
    }, {}),
  ),
  "saleLotId",
  "isUnsold",
);
/**
 * Returns whether or not a Sale Lot has been sold, keyed by Sale Lot Id
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsNoSaleBySaleLotIdLookup = createIdByKeySelector(
  createSelector(
    [selectVendorIdBySaleLotIdLookup, getSaleLots],
    (vendorIdBySaleLotId, saleLots) =>
      Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
        acc[saleLotId] = {
          saleLotId,
          isNoSale: isNoSale(vendorIdBySaleLotId[saleLotId], saleLot.buyer_id),
        };
        return acc;
      }, {}),
  ),
  "saleLotId",
  "isNoSale",
);

export const selectDeploymentLabelsBySaleLotIdLookup = createSelector(
  [getSaleLots, getLabels],
  createLookupCombiner((saleLot, labelByIdLookup) => {
    return saleLot.labels.map(labelId => labelByIdLookup[labelId]);
  }),
);

/**
 * Returns whether or not a Sale Lot has flagged as Withdrawn from Sale, keyed by Sale Lot Id
 * Requires:
 *  - `Sale Lots`
 *  - `Deployment Labels`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsWithdrawnBySaleLotIdLookup = createSelector(
  [selectDeploymentLabelsBySaleLotIdLookup],
  createLookupCombiner(deploymentLabels =>
    isLabelledWithdrawn(deploymentLabels),
  ),
);

/**
 * Returns whether a Sale Lot has flagged as Deceased, either via its Destination Property or a user added Deployment Label, keyed by Sale Lot Id
 * Requires:
 *  - `Sale Lots`
 *  - `Deployment Labels`
 *  - `Properties`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsDeceasedBySaleLotIdLookup = createSelector(
  [getSaleLots, selectDeploymentLabelsBySaleLotIdLookup, getProperties],
  createLookupCombiner(
    (saleLot, deploymentLabelsBySaleLotIdLookup, propertyByIdLookup) => {
      const deploymentLabels =
        deploymentLabelsBySaleLotIdLookup[saleLot.id] || [];
      const destinationProperty =
        propertyByIdLookup[saleLot.destination_property_id] || {};
      return (
        destinationProperty.PIC === "DECEASED" ||
        isLabelledDeceased(deploymentLabels)
      );
    },
  ),
);

/**
 * Returns whether or not a Sale Lot has been penned, keyed by Sale Lot Id
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsPennedBySaleLotIdLookup = createIdByKeySelector(
  createSelector([getSaleLots], saleLots =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      acc[saleLotId] = {
        saleLotId,
        isPenned: isPenned(saleLot.auction_pen_id),
      };
      return acc;
    }, {}),
  ),
  "saleLotId",
  "isPenned",
);

/**
 * Returns the Sale Lot Ids, keyed by Deployment Sale Id
 * Requires:
 *  - `Sales`
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): Object<string, string>}
 */
export const selectSaleLotIdsByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    selectSaleLotIdsByConsignmentIdLookup,
    selectConsignmentIdsByDeploymentSaleIdLookup,
  ],
  (
    deploymentSaleIds,
    saleLotIdsByConsignmentId,
    consignmentIdsByDeploymentSaleId,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      const consignmentIds =
        consignmentIdsByDeploymentSaleId[deploymentSaleId] || [];
      acc[deploymentSaleId] = consignmentIds.reduce((acc, consignmentId) => {
        const saleLotIds = saleLotIdsByConsignmentId[consignmentId] || [];
        return acc.concat(saleLotIds);
      }, []);
      return acc;
    }, {}),
);

/**
 * Returns a list of Sale Lot Ids, in their Sale Round Indexes, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<string>}
 */
export const selectRoundSaleLotIdsByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    getCurrentRoundsList,
    selectSaleLotIdsByDeploymentSaleIdLookup,
    selectSaleLotIdsByRoundIdLookup,
  ],
  (
    deploymentSaleIds,
    saleRoundIds,
    saleLotIdsByDeploymentSaleId,
    saleLotIdsByRoundId,
  ) => {
    return deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      // Build a list of Sale Lot Ids in this Deployment Sale for each Sale Round
      acc[deploymentSaleId] = saleRoundIds.map(saleRoundId =>
        intersection(
          // Get all of the Sale Lot Ids in this Deployment Sale
          saleLotIdsByDeploymentSaleId[deploymentSaleId] || [],

          // Get all of the Sale Lot Ids in this Sale Round
          saleLotIdsByRoundId[saleRoundId] || [],
        ),
      );
      return acc;
    }, {});
  },
);

const totalHdCountBySaleLotIdReducer = saleLots =>
  Object.values(saleLots).reduce(
    (acc, { id: saleLotId, quantity, quantityProgeny }) => {
      acc[saleLotId] = {
        saleLotId,
        totalHdCount: quantity + quantityProgeny,
      };
      return acc;
    },
    {},
  );

/**
 * Returns the total head count, including progeny count (`quantityProgeny`) and selling count (`quantity`), keyed by Sale Lot Id
 * Requires:
 *  - `Sale Lots`
 *  - `Scans`
 * @type {function(state): Object<string, number>}
 */
export const selectTotalHdCountBySaleLotIdLookup = createIdByKeySelector(
  createSelector([getSaleLots], totalHdCountBySaleLotIdReducer),
  "saleLotId",
  "totalHdCount",
);

/**
 * Returns the total head count, including progeny count (`quantityProgeny`) and selling count (`quantity`), keyed by Sale Lot Id from the Vendor Split sub-state.
 * Requires:
 *  - `Sale Lots`
 *  - `Scans`
 * @type {function(state): Object<string, number>}
 */
export const selectTotalHdCountByVendorSplitSaleLotIdLookup =
  createIdByKeySelector(
    createSelector([getVendorSplitSaleLots], totalHdCountBySaleLotIdReducer),
    "saleLotId",
    "totalHdCount",
  );

/**
 * Returns the scanned count, keyed by Sale Lot Id
 * Requires:
 *  - `Sale Lots`
 *  - `Scans`
 * @type {function(state): Object<string, number>}
 */
export const selectScannedCountBySaleLotIdLookup = createIdByKeySelector(
  createSelector(
    [getSaleLots, selectEidsBySaleLotIdLookup],
    (saleLots, eidsBySaleLotId) =>
      Object.values(saleLots).reduce((acc, { id: saleLotId }) => {
        const eids = eidsBySaleLotId[saleLotId] || [];
        acc[saleLotId] = { saleLotId, scannedCount: eids.length };
        return acc;
      }, {}),
  ),
  "saleLotId",
  "scannedCount",
);

export const getScannedCountBySaleLotId = saleLotId => state =>
  selectScannedCountBySaleLotIdLookup(state)[saleLotId] || null;

/**
 * Returns the scanned percentage, keyed by Sale Lot Id
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): Object<string, number>}
 */
export const selectScannedPercentBySaleLotIdLookup = createSelector(
  [getSaleLots, selectScannedCountBySaleLotIdLookup],
  (saleLots, scannedCountBySaleLotId) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const { quantityProgeny } = saleLot;
      const scannedCount = scannedCountBySaleLotId[saleLotId];
      const totalQuantity = quantityProgeny + saleLot.quantity;
      acc[saleLotId] = totalQuantity > 0 ? scannedCount / totalQuantity : 0;
      return acc;
    }, {}),
);

export const getScannedPercentBySaleLotId = saleLotId => state =>
  selectScannedPercentBySaleLotIdLookup(state)[saleLotId] || null;

export const selectIsEditableBySaleLotIdLookup = createSelector(
  [getSaleLots, selectDeploymentIdByConsignmentIdLookup, getActiveRole],
  (saleLots, deploymentIdByConsignmentIdLookup, role) =>
    Object.entries(saleLots).reduce(
      (acc, [saleLotId, { consignment_id: consignmentId }]) => {
        acc[saleLotId] = role.deployments?.some(
          deployment =>
            deployment.id === deploymentIdByConsignmentIdLookup[consignmentId],
        );
        return acc;
      },
      {},
    ),
);

export const getIsEditableBySaleLotId = saleLotId => state =>
  selectIsEditableBySaleLotIdLookup(state)[saleLotId];

function deploymentIdBySaleLotIdLookup(
  saleLot,
  deploymentIdByConsignmentIdLookup,
) {
  return deploymentIdByConsignmentIdLookup[saleLot.consignment_id];
}

export const [selectDeploymentIdBySaleLotIdLookup, getDeploymentIdBySaleLotId] =
  createLookupSelectors(
    [getSaleLots, selectDeploymentIdByConsignmentIdLookup],
    createLookupCombiner(deploymentIdBySaleLotIdLookup),
  );

function scanStatusBySaleLotIdLookup(
  saleLot,
  scannedCountBySaleLotIdLookup,
  speciesId,
  currentSaleYard,
) {
  return getSaleLotScannedStatus(
    saleLot,
    scannedCountBySaleLotIdLookup[saleLot.id],
    getScanStatusThreshold(speciesId, currentSaleYard),
  );
}

export const [
  /**
   * Returns the Scan Status, keyed by Sale Lot Id
   * Requires:
   *  - `Sale Lots`
   *  - `Sales`
   *  - `Scans`
   * @type {function(state): Object<string, number>}
   */
  selectScanStatusBySaleLotIdLookup,
  getScanStatusBySaleLotIdLookup,
] = createLookupSelectors(
  [
    getSaleLots,
    selectScannedCountBySaleLotIdLookup,
    state => getCurrentSale(state).species_id,
    getCurrentSaleyard,
  ],
  createLookupCombiner(scanStatusBySaleLotIdLookup),
);

function hasUnregisteredEidsBySaleLotIdCombiner(eids, scanByEidLookup) {
  return eids
    .map(eid => scanByEidLookup[eid])
    .some(scan => scan.animal?.nlis_id === NLIS_NOT_FOUND);
}

export const [
  /**
   * Returns the whether any Scans were not registered on the NLIS Database after querying for their NLIS Id, keyed by Sale Lot Id
   * Requires:
   *  - `Scans`
   * @type {function(state): Object<string, Boolean>}
   */
  selectHasUnregisteredEidsBySaleLotIdLookup,
  getHasUnregisteredEidsBySaleLotId,
] = createLookupSelectors(
  [selectEidsBySaleLotIdLookup, getScans],
  createLookupCombiner(hasUnregisteredEidsBySaleLotIdCombiner),
  false,
);

const canHaveProgenyBySaleLotIdLookup = (saleLot, sexes) =>
  sexes?.[saleLot.sex_id]?.hasProgeny || false;

export const [
  /**
   * Returns whether the Sale Lot may have progeny, based on the selected Sex, keyed by Sale Lot Id
   * Requires:
   *  - `Sale Lots`
   *  - `Sexes`
   * @type {function(state): Object<string, number>}
   */
  selectCanHaveProgenyBySaleLotIdLookup,
  getCanHaveProgenyBySaleLotIdLookup,
] = createLookupSelectors(
  [getSaleLots, getSexes],
  createLookupCombiner(canHaveProgenyBySaleLotIdLookup),
);

export const selectSaleLotHasImageLookup = selectObjectHasMediaAttachmentLookup(
  getSaleLots,
  getFiles,
  selectAttachmentIdsBySaleLotIdLookup,
  IMAGE_FILE_EXTENSIONS,
);

export const selectSaleLotHasVideoLookup = selectObjectHasMediaAttachmentLookup(
  getSaleLots,
  getFiles,
  selectAttachmentIdsBySaleLotIdLookup,
  VIDEO_FILE_EXTENSIONS,
  (saleLot, value) => {
    return saleLot.youtube_link ? true : value;
  },
);

export const selectIsWeighedBySaleLotIdLookup = createSelector(
  [getSaleLots, selectEidsBySaleLotIdLookup, getScans],
  (saleLots, eidsBySaleLotId, scansLookup) => {
    return Object.values(saleLots).reduce((acc, saleLot) => {
      const eids = eidsBySaleLotId[saleLot.id] || [];
      const scans = eids.map(eid => scansLookup[eid]);
      acc[saleLot.id] = getIsWeighed(saleLot, scans);
      return acc;
    }, {});
  },
);

export const selectSaleLotIdsByPenScanLotIdLookup = createSelector(
  [selectSaleLotIdByEidLookup, selectEidsByPenScanLotIdLookup],
  (saleLotIdByEidLookup, eidsByPenScanLotIdLookup) => {
    return Object.entries(eidsByPenScanLotIdLookup).reduce(
      (acc, [penScanLotId, penScanLotEids]) => {
        acc[penScanLotId] = uniq(
          penScanLotEids
            .map(eid =>
              saleLotIdByEidLookup[eid] !== UNALLOCATED
                ? saleLotIdByEidLookup[eid]
                : null,
            )
            .filter(Boolean),
        );
        return acc;
      },
      {},
    );
  },
);

export const selectAreSomeSaleLotsSoldByPenScanLotIdLookup =
  createIdByKeySelector(
    createSelector(
      [
        getPenScanLots,
        getSaleLots,
        selectSaleLotIdsByPenScanLotIdLookup,
        selectVendorIdBySaleLotIdLookup,
      ],
      (
        penScanLots,
        saleLots,
        saleLotIdsByPenScanLotIdLookup,
        vendorIdBySaleLotId,
      ) =>
        Object.keys(penScanLots).reduce((acc, penScanLotId) => {
          acc[penScanLotId] = {
            penScanLotId,
            isSomeSold: saleLotIdsByPenScanLotIdLookup[penScanLotId]?.some(
              saleLotId =>
                isSold(
                  saleLots[saleLotId]?.buyer_id,
                  vendorIdBySaleLotId[saleLotId],
                ),
            ),
          };
          return acc;
        }, {}),
    ),
    "penScanLotId",
    "isSomeSold",
  );

function saleLotsScanStatusByPenScanLotId(
  penScanLot,
  scannedCountBySaleLotIdLookup,
  saleLotIdsByPenScanLotIdLookup,
  saleLotLookup,
  speciesId,
  currentSaleYard,
) {
  const saleLotIds = saleLotIdsByPenScanLotIdLookup[penScanLot.id] || [];

  return saleLotIds.map(saleLotId => {
    const saleLot = saleLotLookup[saleLotId];
    return getSaleLotScannedStatus(
      saleLot,
      scannedCountBySaleLotIdLookup[saleLotId],
      getScanStatusThreshold(speciesId, currentSaleYard),
    );
  });
}

export const [
  selectSaleLotsScanStatusesByPenScanLotIdLookup,
  getSaleLotsScanStatusesByPenScanLotId,
] = createLookupSelectors(
  [
    getPenScanLots,
    selectScannedCountBySaleLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
    getSaleLots,
    getCurrentSpeciesId,
    getCurrentSaleyard,
  ],
  createLookupCombiner(saleLotsScanStatusByPenScanLotId),
);

export const selectIsWeighedByPenScanLotIdLookup = createSelector(
  [
    getPenScanLots,
    selectSaleLotIdsByPenScanLotIdLookup,
    getScans,
    selectEidsByPenScanLotIdLookup,
    getSaleLots,
  ],
  createLookupCombiner(
    (
      penScanLot,
      saleLotIdsByPenScanLotIdLookup,
      scansLookup,
      eidsByPenScanLotIdLookup,
      saleLots,
    ) => {
      const eids = eidsByPenScanLotIdLookup[penScanLot.id] || [];
      const scans = eids.map(eid => scansLookup[eid]);
      const saleLotIds = saleLotIdsByPenScanLotIdLookup[penScanLot.id] || [];
      return saleLotIds.some(saleLotId =>
        getIsWeighed(saleLots[saleLotId], scans),
      );
    },
  ),
);

const canSaleLotsHaveProgenyByPenScanLotIdLookup = (
  saleLotIds,
  saleLots,
  sexes,
) => {
  return saleLotIds.some(
    saleLotId => sexes?.[saleLots[saleLotId]?.sex_id]?.hasProgeny || false,
  );
};

export const [
  selectCanSaleLotsHaveProgenyByPenScanLotIdLookup,
  getCanSaleLotsHaveProgenyByPenScanLotId,
] = createLookupSelectors(
  [selectSaleLotIdsByPenScanLotIdLookup, getSaleLots, getSexes],
  createLookupCombiner(canSaleLotsHaveProgenyByPenScanLotIdLookup),
);
