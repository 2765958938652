import React from "react";

import { faDesktop, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { screenResize, setSetting } from "actions";

import { MultiButton } from "components/Button";
import GroupedConsignmentCard from "components/GroupedConsignmentCard";
import RenderData from "components/RenderData";
import { GlobalSearchHeader } from "components/SearchInput/GlobalSearch";

import { ConsignmentGroupByOptions } from "constants/consignments";
import { SaleyardPermissions } from "constants/permissions";
import { LivestockAuctionSaleTypes, SaleTypes } from "constants/sale";
import { Settings } from "constants/settings";

import {
  getLivestockSaleId,
  getSaleRoute,
  getSaleyardName,
  openEditConsignmentModal,
} from "lib/navigation";
import { disableReadOnlyActions } from "lib/readOnly";

import {
  getCurrentSale,
  getCurrentSaleyard,
  getHasWriteAccessInCurrentSale,
  getIsConsigningAgentAndSaleUnlocked,
  getNestedConsignmentsByReceivingPen,
  getNestedConsignmentsByVendor,
  getSetting,
  getSettings,
  reportsByBusinessSelector,
  selectNestedConsignmentsByVendorNumberLookup,
} from "selectors";

import { useTheme, useTranslatedSaleTypeText } from "hooks";
import {
  useHasAddConsignmentPermission,
  useHasPermission,
} from "hooks/useHasPermission";

const groupBySelectorMap = {
  [ConsignmentGroupByOptions.VENDOR]: getNestedConsignmentsByVendor,
  [ConsignmentGroupByOptions.VENDOR_NUMBER]:
    selectNestedConsignmentsByVendorNumberLookup,
  [ConsignmentGroupByOptions.RECEIVING_PEN]:
    getNestedConsignmentsByReceivingPen,
};

function ConsignmentCardsComponent() {
  const saleTypeText = useTranslatedSaleTypeText("Consignment");
  const noConsignmentsText = `No ${saleTypeText}s Yet`;
  const addConsignmentText = saleTypeText;

  const theme = useTheme();

  const dispatch = useDispatch();

  const groupBy = useSelector(
    state =>
      getSettings(state)[Settings.consignmentGroupBy] ||
      ConsignmentGroupByOptions.VENDOR,
  );
  const setGroupBy = newGroupBy => {
    dispatch(setSetting(Settings.consignmentGroupBy, newGroupBy));
  };

  const showStepper = useSelector(getSetting(Settings.showConsignmentStepper));

  const toggleShowStepper = () => {
    dispatch(setSetting(Settings.showConsignmentStepper, !showStepper));
  };

  const consignments = useSelector(groupBySelectorMap[groupBy]);

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );
  const isConsigningAgentAndSaleUnlocked = useSelector(
    getIsConsigningAgentAndSaleUnlocked,
  );

  const vendorReports = useSelector(reportsByBusinessSelector).vendors;
  const currentSale = useSelector(getCurrentSale);
  const { sale_type: saleType, species_id: speciesId } = currentSale;
  const isLivestockAuction = LivestockAuctionSaleTypes.includes(saleType);

  const isPaddockOrHooksSale =
    saleType === SaleTypes.PADDOCK || saleType === SaleTypes.OVER_HOOKS;

  const history = useHistory();

  const hasAddConsignmentPermission = useHasAddConsignmentPermission();

  const readOnly =
    (!hasWriteAccessInCurrentSale && !isConsigningAgentAndSaleUnlocked) ||
    !hasAddConsignmentPermission;

  const hasReceivalLotPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featureReceivalLots,
  );

  const consignmentActions = [
    {
      title: addConsignmentText,
      isDisabled: false,
      onClick: () => {
        openEditConsignmentModal();
      },
      default: true,
      icon: faPlus,
      dataTour: "addConsignment",
      // if it is a consigning agent and the sale is unlocked
      // it should not be read only, otherwise use the permission check
      readOnly: isConsigningAgentAndSaleUnlocked
        ? false
        : !hasAddConsignmentPermission,
    },
    isLivestockAuction
      ? {
          title: "Group By Vendor",
          isDisabled: false,
          onClick: () => {
            setGroupBy(ConsignmentGroupByOptions.VENDOR);
          },
          default: false,
          icon: faCaretRight,
          iconColor:
            groupBy === ConsignmentGroupByOptions.VENDOR
              ? "black"
              : "transparent",
          dataTour: "groupByVendor",
        }
      : null,
    isLivestockAuction
      ? {
          title: "Group By Vendor No",
          isDisabled: false,
          onClick: () => {
            setGroupBy(ConsignmentGroupByOptions.VENDOR_NUMBER);
          },
          default: false,
          icon: faCaretRight,
          iconColor:
            groupBy === ConsignmentGroupByOptions.VENDOR_NUMBER
              ? "black"
              : "transparent",
          dataTour: "groupByVendorNumber",
        }
      : null,
    // don't show receival pen grouping when receival lot permissions are enabled
    isLivestockAuction && !hasReceivalLotPermission
      ? {
          title: "Group By Receiving Pen",
          isDisabled: false,
          onClick: () => {
            setGroupBy(ConsignmentGroupByOptions.RECEIVING_PEN);
          },
          default: false,
          icon: faCaretRight,
          iconColor:
            groupBy === ConsignmentGroupByOptions.RECEIVING_PEN
              ? "black"
              : "transparent",
          dataTour: "groupByReceivingPen",
          dividerAfter: true,
        }
      : null,

    {
      title: "Toggle Balance Bar",
      isDisabled: isPaddockOrHooksSale,
      onClick: toggleShowStepper,
      default: false,
      icon: showStepper ? faEye : faEyeSlash,
      dataTour: "toggleBalanceBar",
    },
    {
      title: "Desktop View",
      icon: faDesktop,
      onClick: () => {
        dispatch(screenResize(theme.breakpoints[2]));
        history.push(
          `${getSaleRoute(
            getSaleyardName(),
            getLivestockSaleId(),
          )}/consignments`,
        );
      },
      dataTour: "desktopView",
    },
  ]
    .filter(Boolean)
    .map(disableReadOnlyActions);

  const onAddConsignment =
    hasWriteAccessInCurrentSale && !readOnly
      ? () => {
          openEditConsignmentModal();
        }
      : null;

  const actionText = readOnly ? "" : `Add ${addConsignmentText}`;

  return (
    <RenderData
      test={!!consignments.length}
      infoText={noConsignmentsText}
      actionText={actionText}
      dataTour="addConsignment"
      onClick={onAddConsignment}
      desktopRedirect="consignments"
    >
      <GlobalSearchHeader
        actionButton={<MultiButton buttons={consignmentActions} />}
        searchSize={9}
        buttonSize={3}
      />

      {consignments.map(consignment => (
        <GroupedConsignmentCard
          hasWriteAccessInCurrentSale={hasWriteAccessInCurrentSale}
          groupedBy={groupBy}
          vendorReports={vendorReports[consignment.vendorId]}
          consignment={consignment}
          speciesId={speciesId}
          showStepper={showStepper}
          data-tour="consignmentCard"
          key={`${consignment.vendorId}${consignment.receivingPen}`}
          {...consignment}
        />
      ))}
    </RenderData>
  );
}

const ConsignmentCards = React.memo(ConsignmentCardsComponent);
export default ConsignmentCards;
