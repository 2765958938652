import React from "react";

import { faSkullCow } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";

import Button from "components/Button";
import { Input } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import { Paper } from "components/Paper";
import { MediumText as Heading } from "components/Text";

import { Error, Icon } from "./components";

export const HornSection = ({
  errorMessage,
  quantity,
  onlyHornedOrPolled,
  readOnly,
}) => {
  const { setFieldValue } = useFormikContext();
  const setAllPolled = () => {
    setFieldValue("draftingAttributes.hornPolled", quantity, false);
    setFieldValue("draftingAttributes.hornHorned", 0, false);
    if (!onlyHornedOrPolled) {
      setFieldValue("draftingAttributes.hornDehorned", 0, false);
      setFieldValue("draftingAttributes.hornTipped", 0, false);
      setFieldValue("draftingAttributes.hornScurred", 0, false);
    }
  };

  return (
    <Grid item xs={12}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Row flexGrow>
              <Icon icon={faSkullCow} color="gray44" />
              <Heading>Horn (hd)</Heading>
              {errorMessage && <Error>{errorMessage}</Error>}
            </Row>
          </Grid>

          <Grid
            item
            xs={4}
            sm={2}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <Button type="button" onClick={setAllPolled} disabled={readOnly}>
              All Polled
            </Button>
          </Grid>
          <Grid item xs={4} sm>
            <Input
              label="Polled"
              name="draftingAttributes.hornPolled"
              type="number"
              emptyValue={null}
              disabled={readOnly}
            />
          </Grid>
          {!onlyHornedOrPolled && (
            <Grid item xs={4} sm>
              <Input
                label="Dehorned"
                name="draftingAttributes.hornDehorned"
                type="number"
                emptyValue={null}
                disabled={readOnly}
              />
            </Grid>
          )}
          <Grid item xs={4} sm>
            <Input
              label="Horned"
              name="draftingAttributes.hornHorned"
              type="number"
              emptyValue={null}
              disabled={readOnly}
            />
          </Grid>
          {!onlyHornedOrPolled && (
            <>
              <Grid item xs={4} sm>
                <Input
                  label="Tipped"
                  name="draftingAttributes.hornTipped"
                  type="number"
                  emptyValue={null}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={4} sm>
                <Input
                  label="Scurred"
                  name="draftingAttributes.hornScurred"
                  type="number"
                  emptyValue={null}
                  disabled={readOnly}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};
