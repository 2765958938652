import React from "react";

import { faTvAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Slider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { setSetting } from "actions";

import { Column, Row } from "components/Layout";

import { Settings } from "constants/settings";

import { getCombinedLotNumber } from "lib/saleLot";

import {
  getSellingNowByPriceSaleLot,
  getSetting,
  getSoldSaleLotsInSoldOrder,
} from "selectors";

import { useToggle } from "hooks";

import { DollarsPerHead } from "./WatcherComponents";

const ViewContent = styled.div(
  ({ theme, paddingString, scale }) => `
  overflow: hidden;
  font-size: 6vh;
  color: ${theme.colors.highContrastDefault};
  padding: ${paddingString || "inherit"};
  width: 100%;
  height: 100%;
  transform: scale(${scale / 100});
  `,
);

const Label = styled.div(
  ({ theme }) => `color: ${theme.colors.highContrastDefault}`,
);

const Value = styled.div(
  ({ theme }) =>
    `color: ${theme.colors.highContrastLastSold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `,
);

const PriceValue = styled(Value)(
  ({ theme }) => `
    color: ${theme.colors.highContrastCurrent};
    font-size: 8vh;
    font-weight: bold;
`,
);

const FloatingPadContainer = styled.div(
  ({ expanded }) => `
  position: absolute;
  width: ${expanded ? "20vw" : "auto"};
  top: 12px;
  right: 12px;
  padding: 12px;
  background: white;
  z-index: 5000;
`,
);

const Separator = styled.hr`
  border: 2px solid ${({ theme }) => theme.colors.highContrastCurrent};
  width: 100%;
`;

const StudWatcher = ({ showBuyerWay = false }) => {
  const sellingNow = useSelector(getSellingNowByPriceSaleLot) || {};
  const lastSold = useSelector(getSoldSaleLotsInSoldOrder)?.[0] || {};

  const padding = useSelector(getSetting(Settings.studWatcher)) || {};

  const paddingString = `${padding.top || 0}px ${
    padding.right || 0
  }px ${padding.bottom || 0}px ${padding.left || 0}px`;

  return (
    <ViewContent paddingString={paddingString} scale={padding.scale}>
      <Column padding={2}>
        <Row justifyBetween>
          <Label>Current Lot</Label>
          <Value>#{getCombinedLotNumber(sellingNow) || "N/A"}</Value>
        </Row>
        <Label>Vendor</Label>
        <Value>{sellingNow.vendor?.name || "-"}</Value>
      </Column>
      <Separator />
      <Column padding={2}>
        <Row justifyBetween>
          <Label>Previous Lot</Label>
          <Value>#{getCombinedLotNumber(lastSold) || "N/A"}</Value>
        </Row>
        <Label>Vendor</Label>
        <Value>{lastSold.vendor?.name || "-"}</Value>

        <Label>Buyer</Label>
        <Value>
          {lastSold.buyer?.name}
          {showBuyerWay && lastSold.buyer_way
            ? ` - ${lastSold.buyer_way.name}`
            : ""}
        </Value>
      </Column>
      <Column padding={2}>
        <PriceValue>
          <DollarsPerHead saleLot={lastSold} />
        </PriceValue>
      </Column>
    </ViewContent>
  );
};

const PaddingControl = () => {
  const dispatch = useDispatch();
  const values = useSelector(getSetting(Settings.studWatcher)) || {};

  const [expanded, toggleExpanded] = useToggle(false);

  const onChange = setting => (event, newValue) => {
    dispatch(
      setSetting(Settings.studWatcher, {
        ...values,
        [setting]: newValue,
      }),
    );
  };

  return (
    <FloatingPadContainer expanded={expanded}>
      <Row onClick={toggleExpanded} justifyEnd>
        <FontAwesomeIcon icon={faTvAlt} />
      </Row>
      {expanded && (
        <Column>
          <div>Top Padding</div>
          <Slider
            value={values.top}
            onChange={onChange("top")}
            max={window.innerHeight}
            valueLabelDisplay="auto"
          />
          <div>Bottom Padding</div>
          <Slider
            value={values.bottom}
            onChange={onChange("bottom")}
            max={window.innerHeight}
            valueLabelDisplay="auto"
          />

          <div>Left Padding</div>
          <Slider
            value={values.left}
            onChange={onChange("left")}
            max={window.innerWidth}
            valueLabelDisplay="auto"
          />
          <div>Right Padding</div>
          <Slider
            value={values.right}
            onChange={onChange("right")}
            max={window.innerWidth}
            valueLabelDisplay="auto"
          />
          <div>Scale</div>
          <Slider
            value={values.scale || 100}
            onChange={onChange("scale")}
            max={200}
            valueLabelDisplay="auto"
          />
        </Column>
      )}
    </FloatingPadContainer>
  );
};

const Screen = styled.div(
  ({ theme }) => `
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  flex:1;
  background: ${theme.colors.gray13};
`,
);

export const StudWatcherView = () => {
  return (
    <Screen>
      <PaddingControl />
      <StudWatcher />
    </Screen>
  );
};

export const StudWatcherWithBuyerWayView = () => {
  return (
    <Screen>
      <PaddingControl />
      <StudWatcher showBuyerWay />
    </Screen>
  );
};
