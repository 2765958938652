import reduceReducers from "reduce-reducers";

import { DRAFTING_INFORMATION } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineFetchReducer,
} from "lib/reducers";
import { deserializeDraftingInformation } from "lib/serializers/draftingInformation";

const fetch = apiModelOfflineFetchReducer(DRAFTING_INFORMATION, {
  deserializer: deserializeDraftingInformation,
});

const create = apiModelOfflineCreateReducer(DRAFTING_INFORMATION, {
  deserializer: deserializeDraftingInformation,
});

const draftingInformation = reduceReducers(fetch, create);

export default draftingInformation;
