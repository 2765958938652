import React from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { PaddedIcon } from "components/Layout";

import { sortNumericalStrings } from "lib";

import { getCurrentSaleyardId } from "selectors";

const buyerWayNameKeySelector = obj => obj.name;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px 6px;
`;

const Card = styled.div`
  box-shadow: ${({ theme }) => theme.shadows[2]};
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  margin: 6px;
  padding: 12px;
  background-color: #fff;
`;

const AddBuyerWay = styled(FontAwesomeIcon)`
  margin: 6px 6px 6px 6px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 24px;
  cursor: pointer;
`;

const BlueText = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

const BuyerWays = ({ ways, setBuyerWayId, onClickAddBuyerWay, loading }) => {
  const saleyardId = useSelector(getCurrentSaleyardId);
  const wayCards = sortNumericalStrings(
    uniqBy(
      ways.filter(
        buyerWay =>
          buyerWay.isShown &&
          // This buyer way is not associated with any saleyard
          (buyerWay.saleyardIds.length === 0 ||
            // This buyer way is for the current saleyard
            buyerWay.saleyardIds.includes(saleyardId)),
      ),
      "name",
    ),
    buyerWayNameKeySelector,
  ).map(way => (
    <Card
      data-tour={way.name}
      key={way.id}
      // the id itself isn't so important, it's only used to infer the name for the buyer way
      onClick={() => setBuyerWayId(way.id)}
    >
      {way.name}
    </Card>
  ));

  return (
    <Wrapper>
      {loading && <Card>Loading...</Card>}
      {wayCards}
      <PaddedIcon onClick={onClickAddBuyerWay}>
        <AddBuyerWay data-tour="addBuyerWay" icon={faPlus} />
        {wayCards.length === 0 && <BlueText>Add Buyer Way</BlueText>}
      </PaddedIcon>
    </Wrapper>
  );
};

BuyerWays.propTypes = {
  ways: PropTypes.array,
  setBuyerWayId: PropTypes.func.isRequired,
  onClickAddBuyerWay: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default BuyerWays;
