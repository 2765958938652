import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const WeighLotSerializerMap = {
  created: "created",
  id: "id",
  lotNumber: "lot_number",
  weighDateTime: "weigh_datetime",
  fileName: "file_name",
  livestockSaleId: "livestock_sale_id",
  scaleName: "scale_name",
  totalMassGrams: "total_mass_grams",
  permissions: "permissions",
  lastModified: "last_modified",
  commentIds: "comment_ids",
};

export const serializeWeighLot = buildSerializer(
  WeighLotSerializerMap,
  "WeighLot",
);

export const deserializeWeighLot = buildDeserializer(
  WeighLotSerializerMap,
  "WeighLot",
  {},
  {
    commentIds: [],
  },
);
