import React from "react";

import { Box, Card, CardActions } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";

import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";

import { ValueSource } from "constants/ruleBooks";

import { getSchemaField } from "containers/Settings/RuleBooks/lib";
import { useRuleFieldSchema } from "containers/Settings/RuleBooks/schemaContext";

import { getCheckGroupTemplate } from "./CheckGroups";
import { CriterionRelationField } from "./Criterion";

export function AddCriterionModal(props) {
  const { addCriterion, closeModal, isOpen, parentFieldId } = props;

  const schema = useRuleFieldSchema();
  const schemaField = getSchemaField(parentFieldId, schema);

  function onSubmit(formValues) {
    const { query } = formValues;
    addCriterion({ checkGroups: [getCheckGroupTemplate()], query });
    closeModal();
  }

  function onClickAddFilter() {
    addCriterion({ checkGroups: [getCheckGroupTemplate()] });
    closeModal();
  }

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={closeModal}>
      <DialogTitle onClose={closeModal}>Add Criteria</DialogTitle>

      <Formik
        initialValues={{ query: { fieldId: "", source: ValueSource.FIELD } }}
        onSubmit={onSubmit}
      >
        <Form>
          <DialogContent dividers>
            <Grid container spacing={2} direction="row">
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    Filter based on data from the {schemaField.singular}
                  </CardContent>
                  <CardActions>
                    <button type="button" onClick={onClickAddFilter}>
                      Add Criteria
                    </button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    Filter based on related data from the {schemaField.singular}
                    &apos;s:
                    <br />
                    <CriterionRelationField
                      namespace="query"
                      parentFieldId={parentFieldId}
                    />
                  </CardContent>
                  <CardActions>
                    <Box justifyContent="flex-end">
                      <button type="submit">Add Related Data Criteria</button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <br />
          </DialogContent>
        </Form>
      </Formik>
    </Dialog>
  );
}
