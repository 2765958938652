import {
  createAggregateIdsByKeySelector,
  createIdByKeySelector,
} from "selectors/lib";

import {
  getAuctionPens,
  getBidders,
  getBillingDocuments,
  getBusinesses,
  getConsignments,
  getDefaultVendorSplits,
  getDeployments,
  getDraftingInformation,
  getFiles,
  getIntegrationCredentials,
  getInterestSettings,
  getLabels,
  getLedgerEntries,
  getManualAdjustments,
  getMasterRules,
  getPayments,
  getPenArchetypes,
  getPenScanLots,
  getProperties,
  getReceivalLots,
  getRules,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScans,
  getTradingTerms,
  getVendorCommissionBands,
  getVendorSplitConsignments,
  getVendorSplitSaleLots,
  getWeighLots,
  getWeighLotScans,
} from "./root";

// -----------------------------------------------
// Archetypes Indexes
// -----------------------------------------------
export const selectPenArchetypeIdByAuctionPenIdLookup = createIdByKeySelector(
  getAuctionPens,
  "id",
  "penArchetypeId",
);

export const selectPenArchetypeIdsByPenTypeLookup =
  createAggregateIdsByKeySelector(getPenArchetypes, "penType");

// -----------------------------------------------
// Auction Pen Indexes
// -----------------------------------------------
export const selectPenIdsByPenArchetypeIdLookup =
  createAggregateIdsByKeySelector(getAuctionPens, "penArchetypeId");

export const selectPenIdsByRoundIdLookup = createAggregateIdsByKeySelector(
  getAuctionPens,
  "saleRoundId",
);

export const selectPenIdsByPenTypeLookup = createAggregateIdsByKeySelector(
  getAuctionPens,
  "penType",
);

// -----------------------------------------------
// Bidder Registration Indexes
// -----------------------------------------------
export const selectBidderIdsByRegistrationNumberLookup =
  createAggregateIdsByKeySelector(getBidders, "registrationNumber");

// -----------------------------------------------
// LedgerEntry Indexes
// -----------------------------------------------
export const selectBillingDocumentIdsByBillingRunIdLookup =
  createAggregateIdsByKeySelector(getBillingDocuments, "billingRunId");

export const selectBillingLedgerEntryIdsByBillingDocumentIdLookup =
  createAggregateIdsByKeySelector(getLedgerEntries, "documentId");

// -----------------------------------------------
// Consignment Indexes
// -----------------------------------------------
export const selectConsignmentIdsByDeploymentSaleIdLookup =
  createAggregateIdsByKeySelector(getConsignments, "deployment_sale");

export const selectConsignmentIdsByVendorIdLookup =
  createAggregateIdsByKeySelector(getConsignments, "vendor_id");

export const selectConsignmentIdsByNvd = createAggregateIdsByKeySelector(
  getConsignments,
  "NVD",
);

export const selectConsignmentIdsByVendorPropertyIdLookup =
  createAggregateIdsByKeySelector(getConsignments, "vendor_property_id");

export const selectVendorSplitConsignmentIdsByParentConsignmentIdLookup =
  createAggregateIdsByKeySelector(getVendorSplitConsignments, "parentId");

// -----------------------------------------------
// Auction Sale Lot Indexes
// -----------------------------------------------
export const selectSaleLotIdsByConsignmentIdLookup =
  createAggregateIdsByKeySelector(getSaleLots, "consignment_id");

export const selectVendorSplitSaleLotIdsByConsignmentIdLookup =
  createAggregateIdsByKeySelector(getVendorSplitSaleLots, "consignment_id");

export const selectSaleLotIdsByAuctionPenIdLookup =
  createAggregateIdsByKeySelector(getSaleLots, "auction_pen_id");

export const selectSaleLotIdsByDeliveryPenIdLookup =
  createAggregateIdsByKeySelector(getSaleLots, "deliveryPenId");

export const selectSaleLotIdsByRoundIdLookup = createAggregateIdsByKeySelector(
  getSaleLots,
  "sale_round_id",
);
// This selector is somewhat of a misnomer - there is no hard rule about unique lot numbers, especially with
// suffix and sub lot numbers thrown in.  For the places this is used, though (importers) it is a safe assumption that
// it is unique.

export const selectSaleLotIdByLotNumberLookup = createIdByKeySelector(
  getSaleLots,
  "lot_number",
  "id",
);

export const selectDeliveryPenIdBySaleLotIdLookup = createIdByKeySelector(
  getSaleLots,
  "id",
  "deliveryPenId",
);

export const selectAuctionPenIdBySaleLotIdLookup = createIdByKeySelector(
  getSaleLots,
  "id",
  "auction_pen_id",
);

export const selectReceivalPenByIdByReceivalLotIdLookup = createIdByKeySelector(
  getReceivalLots,
  "id",
  "receivalPenId",
);

export const selectVendorSplitSaleLotIdsByParentSaleLotIdLookup =
  createAggregateIdsByKeySelector(getVendorSplitSaleLots, "parentId");

export const selectSaleLotIdsByLastSeenAtDraftIdLookup =
  createAggregateIdsByKeySelector(getSaleLots, "lastSeenAtDraftId");

// -----------------------------------------------
// Deployment Indexes
// -----------------------------------------------
export const selectDeploymentIdsByAgencyIdLookup =
  createAggregateIdsByKeySelector(getDeployments, "livestockAgencyId");

export const selectBusinessIdsByMasterBusinessDeploymentIdLookup =
  createAggregateIdsByKeySelector(getBusinesses, "businessDeploymentId", "id");

export const selectMasterBusinessIdByDeploymentBusinessIdLookup =
  createIdByKeySelector(getBusinesses, "deploymentBusinessId", "id");

export const selectBusiessIdsByNameLookup = createAggregateIdsByKeySelector(
  getBusinesses,
  "name",
  "id",
);

// -----------------------------------------------
// Saleyard Scan Sale Lot Indexes
// -----------------------------------------------
export const selectSaleyardScanSaleLotIdsByConsignmentIdLookup =
  createAggregateIdsByKeySelector(getSaleyardScanSaleLots, "consignmentId");

// -----------------------------------------------
// EID Indexes
// -----------------------------------------------
export const selectEidsBySaleLotIdLookup = createAggregateIdsByKeySelector(
  getScans,
  "sale_lot_id",
  "EID",
);

export const selectEidsByReceivalLotIdLookup = createAggregateIdsByKeySelector(
  getScans,
  "receival_lot_id",
  "EID",
);

export const selectEidsByPenScanLotIdLookup = createAggregateIdsByKeySelector(
  getScans,
  "pen_scan_lot_id",
  "EID",
);

export const selectReceivalLotIdByEidLookup = createIdByKeySelector(
  getScans,
  "EID",
  "receival_lot_id",
);

export const selectPenScanLotIdByEidLookup = createIdByKeySelector(
  getScans,
  "EID",
  "pen_scan_lot_id",
);

export const selectEidsByDeploymentSaleIdLookup =
  createAggregateIdsByKeySelector(getScans, "deployment_sale_id", "EID");

export const selectEidsByDeploymentIdLookup = createAggregateIdsByKeySelector(
  getScans,
  "deployment_id",
  "EID",
);

export const selectEidsByCurrentAuctionPenIdLookup =
  createAggregateIdsByKeySelector(getScans, "current_auction_pen_id", "EID");

export const selectSaleLotIdByEidLookup = createIdByKeySelector(
  getScans,
  "EID",
  "sale_lot_id",
);

// -----------------------------------------------
// Attachment/File Indexes
// -----------------------------------------------
export const selectAttachmentIdsBySaleLotIdLookup =
  createAggregateIdsByKeySelector(getFiles, "sale_lot");
export const selectAttachmentIdsByConsignmentIdLookup =
  createAggregateIdsByKeySelector(getFiles, "consignment");

// -----------------------------------------------
// Business Indexes
// -----------------------------------------------

export const selectVendorIdByConsignmentIdLookup = createIdByKeySelector(
  getConsignments,
  "id",
  "vendor_id",
);

export const selectSaleLotIdsByBuyerIdLookup = createAggregateIdsByKeySelector(
  getSaleLots,
  "buyer_id",
);

export const selectBuyerIdBySaleLotIdLookup = createIdByKeySelector(
  getSaleLots,
  "id",
  "buyer_id",
);

export const selectVendorIdBySaleLotIdLookup = createIdByKeySelector(
  getSaleLots,
  "id",
  "vendor_id",
);

export const selectorInvoiceToBusinessIdBySaleLotIdLookup =
  createIdByKeySelector(getSaleLots, "id", "invoiceToBusinessId");

export const selectorThirdPartyBusinessIdBySaleLotIdLookup =
  createIdByKeySelector(getSaleLots, "id", "thirdPartyId");

export const selectBusinessIdBySaleyardCodeLookup = createIdByKeySelector(
  getBusinesses,
  "shortCodeSaleyard",
  "id",
);

export const selectBusinessIdByAuctionsPlusCodeLookup = createIdByKeySelector(
  getBusinesses,

  "shortCodeAuctionsPlus",
  "id",
);

export const selectPaymentIdsByFromBusinessIdLookup =
  createAggregateIdsByKeySelector(getPayments, "fromBusinessId");

export const selectBusinessIdsByAgencyIdLookup =
  createAggregateIdsByKeySelector(getBusinesses, "agencyId", "id");

export const selectBusinessIdByDeploymentBusinessIdLookup =
  createIdByKeySelector(getBusinesses, "deploymentBusinessId");

// -----------------------------------------------
// Label Indexes
// -----------------------------------------------
export const selectLabelEntryIdsByLabelNameLookup =
  createAggregateIdsByKeySelector(getLabels, "name");

// -----------------------------------------------
// LedgerEntry Indexes
// -----------------------------------------------
export const selectLedgerEntryIdsByMincenousIdLookup =
  createAggregateIdsByKeySelector(getLedgerEntries, "mincenousId");
export const selectLedgerEntryIdsByBillingDocumentIdLookup =
  createAggregateIdsByKeySelector(getLedgerEntries, "documentId");
// -----------------------------------------------
// Nomination Indexes
// -----------------------------------------------
export const selectConsignmentIdsByNominationIdLookup =
  createAggregateIdsByKeySelector(getConsignments, "nominationId");

// -----------------------------------------------
// Property Indexes
// -----------------------------------------------
export const selectPropertyIdByPICLookup = createIdByKeySelector(
  getProperties,
  "PIC",
  "id",
);

// -----------------------------------------------
//  Integration Credential Types
// -----------------------------------------------
export const selectIntegrationCredentialIdsByTypeLookup =
  createAggregateIdsByKeySelector(getIntegrationCredentials, "type");

export const selectIntegrationCredentialIdsByDeploymentIdLookup =
  createAggregateIdsByKeySelector(getIntegrationCredentials, "deploymentId");

// -----------------------------------------------
//  Vendor Commission Bands
// -----------------------------------------------
export const selectVendorCommissionBandIdsByDeploymentIdLookup =
  createAggregateIdsByKeySelector(getVendorCommissionBands, "deploymentId");

export const selectVendorCommissionBandIdsByDeploymentBusinessIdLookup =
  createAggregateIdsByKeySelector(
    getVendorCommissionBands,
    "deploymentBusinessId",
  );

// -----------------------------------------------
//  Interest
// -----------------------------------------------
export const selectInterestSettingsIdsByDeploymentIdLookup =
  createAggregateIdsByKeySelector(getInterestSettings, "deploymentId");

export const selectInterestSettingsIdsByBusinessIdLookup =
  createAggregateIdsByKeySelector(getInterestSettings, "businessId");

// -----------------------------------------------
//  Manual Adjustments / Sundries
// -----------------------------------------------

export const selectManualAdjustmentIdsByLivestockSaleId =
  createAggregateIdsByKeySelector(getManualAdjustments, "livestockSaleId");

export const selectDeploymentIdByManualAdjustmentIdLookup =
  createIdByKeySelector(getManualAdjustments, "id", "deploymentId");

export const selectFromBusinessIdByManualAdjustmentIdLookup =
  createIdByKeySelector(getManualAdjustments, "id", "fromBusinessId");

export const selectToBusinessIdByManualAdjustmentIdLookup =
  createIdByKeySelector(getManualAdjustments, "id", "toBusinessId");

// -----------------------------------------------
//  Drafting Information Indexes
// -----------------------------------------------
export const selectDraftingInformationIdsBySelectableOnSaleWatcher =
  createAggregateIdsByKeySelector(
    getDraftingInformation,
    "selectableOnSaleWatcher",
  );

// -----------------------------------------------
//  Drafting Information Indexes
// -----------------------------------------------
export const selectReceivalLotIdsByReceivalPenIdLookup =
  createAggregateIdsByKeySelector(getReceivalLots, "receivalPenId");

// -----------------------------------------------
// Receival Lot Indexes
// -----------------------------------------------
export const selectReceivalLotIdsByConsignmentIdLookup =
  createAggregateIdsByKeySelector(getReceivalLots, "consignmentId");

export const selectReceivalLotIdsByPenIdLookup =
  createAggregateIdsByKeySelector(getReceivalLots, "receivalPenId");
// -----------------------------------------------
// Rule Indexes
// -----------------------------------------------

export const selectMasterRuleIdsByMasterRuleBookIdLookup =
  createAggregateIdsByKeySelector(getMasterRules, "rule_book_id");

export const selectMasterRuleBookIdByMasterRuleIdLookup = createIdByKeySelector(
  getMasterRules,
  "id",
  "rule_book_id",
);

export const selectRuleIdsByRuleBookIdLookup = createAggregateIdsByKeySelector(
  getRules,
  "rule_book_id",
);

export const selectRuleIdsByMasterRuleIdLookup =
  createAggregateIdsByKeySelector(getRules, "master_rule_id");

export const selectRuleBookIdByRuleIdLookup = createIdByKeySelector(
  getRules,
  "id",
  "rule_book_id",
);

// -----------------------------------------------
// Pen Scan Lot Indexes
// -----------------------------------------------
export const selectPenScanLotIdsBySellingPenIdLookup =
  createAggregateIdsByKeySelector(getPenScanLots, "sellingPenId");

// -----------------------------------------------
// Weigh Lot Indexes
// -----------------------------------------------
export const selectWeighLotIdsByScaleNameLookup =
  createAggregateIdsByKeySelector(getWeighLots, "scaleName");

export const selectWeighLotIdsByLotNumberLookup =
  createAggregateIdsByKeySelector(getWeighLots, "lotNumber");

// -----------------------------------------------
// Weigh Lot Scan Indexes
// -----------------------------------------------
export const selectWeighLotScanIdsByWeighLotIdLookup =
  createAggregateIdsByKeySelector(getWeighLotScans, "weighLotId");

export const selectEidsByWeighLotIdLookup = createAggregateIdsByKeySelector(
  getWeighLotScans,
  "weighLotId",
  "eid",
);

export const selectWeighLotScanIdsByEidLookup = createAggregateIdsByKeySelector(
  getWeighLotScans,
  "eid",
);

export const selectWeighLotIdsByEidLookup = createAggregateIdsByKeySelector(
  getWeighLotScans,
  "eid",
  "weighLotId",
);

export const selectEidByWeighLotScanIdLookup = createAggregateIdsByKeySelector(
  getScans,
  "weigh_lot_scan_id",
  "EID",
);

export const selectDefaultVendorSplitIdsByBusinessIdLookup =
  createAggregateIdsByKeySelector(getDefaultVendorSplits, "primaryBusinessId");

export const selectTradingTermIdsByDeploymentIdLookup =
  createAggregateIdsByKeySelector(getTradingTerms, "deploymentId");
