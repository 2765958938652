import React from "react";

import isEmpty from "lodash/isEmpty";
import { PropTypes } from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import {
  addSaleLotWithPens,
  deleteSaleLot,
  patchSaleLot,
  addBidderRegistration,
} from "actions";

import { addAuctionPen } from "actions/auctionPens";

import DetailedSaleLotModal from "components/DetailedSaleLotModal";

import { Accreditation } from "constants/draftingAttributes";
import { SaleLotModalSection } from "constants/navigation";
import { SaleTypes } from "constants/sale";
import { Species } from "constants/species";

import { getDollarPriceStringFromCents } from "lib";

import {
  closeAllHashModals,
  openEditSaleLotModal,
  returnToLocation,
} from "lib/navigation";
import { generateListingId } from "lib/saleLot";

import {
  currentSaleSelector,
  getConsignmentFromIdOrSaleLotId,
  getExportSitesByDeploymentSaleId,
  getIsSaleyardAdmin,
  getMinimalFormattedSaleLotById,
  getNestedConsignments,
  getPreviousSaleLotById,
  getPrimaryAgency,
  getSaleLots,
  getSaleLotsBySale,
  getSpeciesAttributeOptions,
} from "selectors";

// TODO remove this component and place all of the logic directly into the
//  selector
const SaleLot = ({
  action,
  closeSelf,
  copiedSaleLot,
  previousSaleLot,
  speciesAttributes,
  saleLot,
  saleLotId,
  sale,
  section,
  patchSaleLot,
  addBidderRegistration,
  addSaleLotWithPens,
  deleteSaleLot,
  addAuctionPen,
  primaryAgency,
  onBeforeClose,
  saleLots,
  consignment,
  consignmentId,
  changeSection,
  returnTo,
  isSaleyardAdmin,
}) => {
  const defaultPricingType = sale.pricing_type_id;

  function onClose() {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }

  const [refreshKey, setRefreshKey] = React.useState(
    saleLot.listingId ? saleLot.listingId : generateListingId(),
  );

  function onAfterClose(saleLotId, createAnother) {
    if (createAnother) {
      // When create another is ticked we want to get a new key to force a remount
      setRefreshKey(generateListingId());
      // use the copy draft functionality to show a new form
      openEditSaleLotModal(
        null,
        SaleLotModalSection.GENERAL,
        null,
        consignmentId,
        saleLotId,
        returnTo,
      );
    } else if (
      saleLotId &&
      isEmpty(saleLot) &&
      sale.sale_type === SaleTypes.CLEARING
    ) {
      // Keep it open for clearing sales.
      openEditSaleLotModal(
        saleLotId,
        SaleLotModalSection.GENERAL,
        null,
        consignmentId,
        null,
        returnTo,
      );
    }
  }

  // For a sale lot, default the accreditation defaults to OFF (there maybe further defaults
  // pulled from other lots, properties, or the consignment, soon)
  const initialAccreditations = {
    accreditationAntibioticFree: Accreditation.NONE,
    accreditationBackgrounded: Accreditation.NONE,
    accreditationEU: Accreditation.NONE,
    accreditationGrassFed: Accreditation.NONE,
    accreditationNEE: Accreditation.NONE,
    accreditationOrganic: Accreditation.NONE,
    accreditationPCAS: Accreditation.NONE,
    accreditationPTE: Accreditation.NONE,
    accreditationPTIC: Accreditation.NONE,
    b12Vac: Accreditation.NONE,
    backline: Accreditation.NONE,
    drenched: Accreditation.NONE,
    feeder: Accreditation.NONE,
    gudairApproved: Accreditation.NONE,
    MAndTS: Accreditation.NONE,
    notStationMated: Accreditation.NONE,
    painRelief: Accreditation.NONE,
    SAEligible: Accreditation.NONE,
    sixInOne: Accreditation.NONE,
    stationMated: Accreditation.NONE,
  };

  const consignmentAccreditationDefaults = {};
  Object.keys(initialAccreditations).forEach(accredition => {
    const consignmentValue = consignment?.draftingAttributes?.[accredition];
    if (
      consignmentValue === Accreditation.NONE ||
      consignmentValue === Accreditation.ALL
    ) {
      consignmentAccreditationDefaults[accredition] = consignmentValue;
    }
  });

  const propertyAccreditationDefaults = {};
  if (
    consignment?.vendor_property &&
    sale.species_id === Species.CATTLE &&
    !saleLotId
  ) {
    if (consignment.vendor_property.accreditationEu !== null) {
      propertyAccreditationDefaults.accreditationEU = consignment
        .vendor_property.accreditationEu
        ? Accreditation.ALL
        : Accreditation.NONE;
    }
    if (consignment.vendor_property.accreditationPcas !== null) {
      propertyAccreditationDefaults.accreditationPCAS = consignment
        .vendor_property.accreditationPcas
        ? Accreditation.ALL
        : Accreditation.NONE;
    }
    if (consignment.vendor_property.accreditationJbas !== null) {
      propertyAccreditationDefaults.accreditationJBAS =
        consignment.vendor_property.accreditationJbas;
    }
    if (consignment.vendor_property.accreditationNee !== null) {
      propertyAccreditationDefaults.accreditationNEE = consignment
        .vendor_property.accreditationNee
        ? Accreditation.ALL
        : Accreditation.NONE;
    }
  }
  const activeExportSites = useSelector(
    getExportSitesByDeploymentSaleId(consignment.deployment_sale),
  );

  // Add extra info to the initial values
  const initialValues = {
    // Set quantity before getting saleLot so if quantity is undefined in salelots
    // it will be 1, else, it will be the saleLot.quantity

    quantity: sale.sale_type === SaleTypes.CLEARING ? 1 : undefined,
    ...saleLot,
    cents_per_kg: saleLot.unit_price,
    price_per_head: getDollarPriceStringFromCents(
      saleLot.total_price_cents / saleLot.quantity,
    ),
    listingId: saleLot.listingId ? saleLot.listingId : generateListingId(),
    price_gross: getDollarPriceStringFromCents(saleLot.total_price_cents),
    pricing_type_id: saleLot.pricing_type_id || defaultPricingType,
    createAnother: Boolean(copiedSaleLot),
    draftingAttributes: {
      ...initialAccreditations,
      ...propertyAccreditationDefaults,
      ...consignmentAccreditationDefaults,
      ...(saleLot?.draftingAttributes
        ? saleLot?.draftingAttributes
        : // Note that this is different to NONE - "unset" is a valid value for this, meaning refer to the consignment, or in turn the NVD,
          // whereas the others are essentially on or off with a default loaded from [somewhere]
          { vendorBredOverride: null }),
    },
    auction_pen: saleLot.auction_pen || {},
    deliveryPen: saleLot.deliveryPen || {},
    pickupAddress: saleLot.pickupAddress || null,
    exportSites: saleLot.exportSites || activeExportSites,
  };
  // Set the agency in the fields if this is an admin - it will be needed
  // if we need to add the auction pen.
  if (isSaleyardAdmin) {
    initialValues.agency_id = consignment.agencyId;
  }

  const handleAddAuctionPen = (auctionPen, tempId) =>
    addAuctionPen(auctionPen, tempId, [saleLotId]);

  const handleDeleteSaleLot = () => deleteSaleLot(saleLot);

  return (
    <DetailedSaleLotModal
      closeSelf={closeSelf || onClose}
      consignment={consignment}
      consignmentAccreditationDefaults={consignmentAccreditationDefaults}
      copiedSaleLot={copiedSaleLot}
      previousSaleLot={previousSaleLot}
      section={section}
      showCreateAnother={!saleLotId}
      changeSection={changeSection}
      action={action}
      speciesAttributes={speciesAttributes}
      initialValues={initialValues}
      consignmentId={consignmentId}
      patchSaleLot={patchSaleLot}
      addSaleLotWithPens={addSaleLotWithPens}
      addBidderRegistration={addBidderRegistration}
      addAuctionPen={handleAddAuctionPen}
      saleLots={saleLots}
      deleteSaleLot={handleDeleteSaleLot}
      primaryAgency={primaryAgency}
      onBeforeClose={onBeforeClose}
      onAfterClose={onAfterClose}
      saleLotId={saleLotId}
      sale={sale}
      key={refreshKey}
    />
  );
};

SaleLot.propTypes = {
  addAuctionPen: PropTypes.func,
  addSaleLotWithPens: PropTypes.func,
  closeSelf: PropTypes.func,
  consignment: PropTypes.object,
  copiedSaleLot: PropTypes.object,
  deleteSaleLot: PropTypes.func,
  onBeforeClose: PropTypes.func,
  patchSaleLot: PropTypes.func,
  primaryAgency: PropTypes.object,
  sale: PropTypes.object,
  saleLot: PropTypes.object,
  saleLots: PropTypes.array,
  speciesAttributes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { saleLotId, consignmentId, copyFrom } = props;

  return {
    consignment: getConsignmentFromIdOrSaleLotId(
      getNestedConsignments(state),
      getSaleLotsBySale(state),
      { saleLotId: saleLotId || copyFrom, consignmentId },
    ),
    copiedSaleLot: getSaleLots(state)[copyFrom],
    previousSaleLot: getPreviousSaleLotById(state, props),
    speciesAttributes: getSpeciesAttributeOptions(state),
    saleLot: getMinimalFormattedSaleLotById(state, props),
    saleLots: getSaleLotsBySale(state),
    sale: currentSaleSelector(state),
    primaryAgency: getPrimaryAgency(state),
    isSaleyardAdmin: getIsSaleyardAdmin(state),
  };
};

const mapDispatchToProps = {
  patchSaleLot,
  addSaleLotWithPens,
  addBidderRegistration,
  deleteSaleLot,
  addAuctionPen,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(SaleLot);
