import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "@material-ui/core";

import { ColoredFontAwesomeIcon } from "components/MaterialDialog";
import { BoldText } from "components/Text";

export const FoundPremiumFeature = ({ anchorEl, setAnchorEl }) => {
  React.useEffect(() => {
    if (anchorEl) {
      setAnchorEl(anchorEl);
    }
  }, [anchorEl, setAnchorEl]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className="m-12 capitalize">
        <BoldText>
          <ColoredFontAwesomeIcon color="warning" icon={faExclamationCircle} />{" "}
          You&apos;ve Discovered A Premium Feature
        </BoldText>
        <p>Please Contact AgriNous Support To Upgrade Your Account.</p>
      </div>
    </Popover>
  );
};
