import React, { useCallback, useMemo } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import partition from "lodash/partition";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { addSaleLot, patchConsignment, patchSaleLot } from "actions";

import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, SecondaryButton } from "components/Form";
import { KillSheetForm } from "components/KillSheet/KillSheetForm";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { PricingTypes } from "constants/pricingTypes";
import { Species } from "constants/species";

import {
  calculateTotalPriceCents,
  calculateUnitPrice,
  formatDecimal,
} from "lib";

import {
  getConsignmentById,
  getCurrentSale,
  getSaleLotById,
  getSaleLotIdsByConsignmentId,
} from "selectors";

import { getSkinsSexId } from "selectors/sexes";

import { useBoolean } from "hooks";

const decimalValidation = Yup.number()
  .positive("must be a > 0")
  .typeError("invalid decimal");

const quantityValidation = Yup.number()
  .typeError("invalid number")
  .integer("must be a whole number")
  .required("required");

const validationSchema = Yup.object().shape({
  saleLots: Yup.array().of(
    Yup.object().shape({
      quantity: quantityValidation,
      total_mass_grams: decimalValidation,
      unitPrice: decimalValidation,
    }),
  ),
});

function Modal({ onClose, consignmentId }) {
  const consignment = useSelector(getConsignmentById(consignmentId));

  const saleLotIds =
    useSelector(getSaleLotIdsByConsignmentId(consignmentId)) || [];
  const skinsSexId = useSelector(getSkinsSexId);

  const consignmentLots = useSelector(state =>
    saleLotIds.map(saleLotId => getSaleLotById(saleLotId)(state)),
  );

  const [skinsLots, saleLots] = partition(
    consignmentLots,
    saleLot => saleLot.sex_id === skinsSexId,
  );

  const dispatch = useDispatch();

  const dialogTitle = `Kill Sheet Entry - Hd ${consignment?.quantity}`;

  const sale = useSelector(getCurrentSale);

  const defaultSaleLot = useMemo(
    () => ({
      quantity: null,
      total_mass_grams: null,
      cents_per_kilo: null,
      total_price_cents: null,
      pricing_type_id: sale.pricing_type_id,
      consignment_id: consignmentId,
      sale_round_id: sale.rounds[0],
      buyer_id: sale.default_buyer_id,
    }),
    [consignmentId, sale.default_buyer_id, sale.pricing_type_id, sale.rounds],
  );

  const withUnitPrice = saleLot => ({
    ...saleLot,
    unitPrice: formatDecimal(calculateUnitPrice(saleLot)),
  });

  const initialValues = useMemo(() => {
    const saleLotFormInitialValues =
      saleLots.length > 0 ? saleLots : [defaultSaleLot];

    const skinsLotFormInitialValues =
      skinsLots.length === 0 && sale.species_id === Species.SHEEP
        ? [
            {
              ...defaultSaleLot,
              sex_id: skinsSexId,
              pricing_type_id: PricingTypes.PER_HEAD,
              total_mass_grams: 0,
            },
          ]
        : skinsLots;
    return {
      saleLots: saleLotFormInitialValues.map(withUnitPrice),
      skinsLots: skinsLotFormInitialValues.map(withUnitPrice),
      consignment,
    };
  }, [
    defaultSaleLot,
    sale.species_id,
    saleLots,
    skinsLots,
    skinsSexId,
    consignment,
  ]);

  const handleSubmit = ({ saleLots, skinsLots, consignment }) => {
    [...saleLots, ...skinsLots]
      .filter(saleLot => saleLot.quantity > 0)
      .forEach(saleLot => {
        const totalPriceCents = calculateTotalPriceCents(saleLot);

        const payload = {
          ...saleLot,
          total_price_cents: totalPriceCents,
        };

        if (payload.id) {
          // patching
          dispatch(patchSaleLot(payload));
        } else {
          const tempSaleLotId = uuidv4();
          dispatch(addSaleLot(payload, tempSaleLotId));
        }
      });

    if (consignment.billingReference) {
      dispatch(
        patchConsignment(
          { billingReference: consignment.billingReference },
          consignmentId,
        ),
      );
    }
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <FormInner
        onClose={onClose}
        dialogTitle={dialogTitle}
        defaultSaleLot={defaultSaleLot}
      />
    </Formik>
  );
}

const FormInner = ({ onClose, dialogTitle, defaultSaleLot }) => {
  const submitDisabled = false;

  const formikContext = useFormikContext();
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useBoolean();

  const onCloseConfirmWrapper = useCallback(() => {
    if (formikContext.dirty) {
      openConfirmDialog();
    } else {
      onClose();
    }
  }, [formikContext.dirty, onClose, openConfirmDialog]);

  return (
    <>
      <ZoomyDialog open onClose={onCloseConfirmWrapper} maxWidth="lg" fullWidth>
        <Form>
          <DialogTitle onClose={onCloseConfirmWrapper}>
            {dialogTitle}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container item xs={12} spacing={2}>
              <KillSheetForm defaultSaleLot={defaultSaleLot} />
            </Grid>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={onCloseConfirmWrapper} type="button">
              Close
            </SecondaryButton>
            <Button type="submit" disabled={submitDisabled}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </ZoomyDialog>
      <ConfirmDialog
        title="There are changes to the form that will be lost, are you sure?"
        isOpen={isConfirmDialogOpen}
        onCancel={closeConfirmDialog}
        onDelete={onClose}
        buttonMessage="Yes"
      />
    </>
  );
};

export default Modal;
