import React from "react";

import truncate from "lodash/truncate";
import styled from "styled-components/macro";

import { Column } from "components/Layout";

const ActionText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const ShowMore = ({ limit, text, extraWhenExpanded, showAll }) => {
  const [expanded, setExpanded] = React.useState(text.length < limit);

  React.useEffect(() => {
    setExpanded(showAll);
  }, [showAll]);

  const toggle = () => {
    setExpanded(!expanded);
  };

  if (text.length < limit && !extraWhenExpanded) {
    return <div>{text}</div>;
  }

  const Action = () => (
    <ActionText>{`  Show ${expanded ? "Less" : "More"}`}</ActionText>
  );

  // If not expanded "show more" at the end of the truncated text.
  // If expanded "show less on a new line after everything."

  return (
    <Column onClick={toggle}>
      {expanded ? (
        text
      ) : (
        <div>
          {truncate(text, { length: limit, separator: " " })}
          <Action />
        </div>
      )}

      {expanded && (
        <>
          {extraWhenExpanded}
          <Action />
        </>
      )}
    </Column>
  );
};
