import React, { useMemo } from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { ActionButton } from "components/AgGrid/ActionButton";
import AgGrid from "components/AgGrid/AgGrid";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { SubtleBadge } from "components/Badge";
import Button from "components/Button";
import { FormCollapse, CollapseLabel } from "components/Form";
import { Row } from "components/Layout";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";

import { formatters, getters } from "lib/agGrid";
import { openModalLink } from "lib/navigation";

import { getManualAdjustmentAggridDataByConsignmentId } from "selectors";

const defaultColDef = {
  sortable: true,
  resizable: true,
  enableCellChangeFlash: true,
  flex: true,
};

const actionsColumnRenderer = ({ data, context }) => {
  const { readOnly } = context;
  return (
    <ActionButton
      data-tour="action-edit-manual-adjustment"
      type="button"
      disabled={readOnly}
      onClick={() =>
        openModalLink(ModalTypes.ManualAdjustmentForm, { id: data.id })
      }
    >
      <FontAwesomeIcon icon={faPencil} />
      &nbsp;Edit
    </ActionButton>
  );
};
const columns = [
  {
    headerName: "To",
    field: "toBusinessName",
  },
  {
    headerName: "From",
    field: "fromBusinessName",
  },
  {
    headerName: "Amount",
    field: "totalAmountCents",
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "Note",
    field: "note",
  },
  {
    colId: ACTION_COLUMN_ID,
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: actionsColumnRenderer,
    pinned: "right",
    width: 80,
  },
];

export const FeesAndChargesCollapse = React.memo(
  ({ isOpen, onToggle, consignmentId, readOnly }) => {
    const rowData = useSelector(
      getManualAdjustmentAggridDataByConsignmentId(consignmentId),
    );
    const header = useMemo(() => {
      const extraInfo =
        rowData.length > 0
          ? `${rowData.length} Adjustment${rowData.length > 1 ? "s" : ""}`
          : "";

      return (
        <Row data-tour="feesAndCharges">
          <SubtleBadge>Fees and Charges</SubtleBadge>
          <Row alignCenter flexWrap>
            <CollapseLabel>{extraInfo}</CollapseLabel>
          </Row>
        </Row>
      );
    }, [rowData]);

    const openManualChargeFormModal = () => {
      const modalProps = {
        id: null,
        fromBusinessId: null,
        toBusinessId: null,
      };
      openModalLink(ModalTypes.ManualAdjustmentForm, modalProps);
    };

    return (
      <FormCollapse isOpen={isOpen} onToggle={onToggle} header={header}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Button
              data-tour="manualAdjustment"
              type="button"
              onClick={openManualChargeFormModal}
              disabled={readOnly}
            >
              Add Sundry
            </Button>
          </Grid>

          {rowData.length > 0 && (
            <Grid item xs={12}>
              <CollapseTableWrapper>
                <AgGrid
                  rowData={rowData}
                  suppressColumnVirtualisation
                  suppressScrollOnNewData
                  columnDefs={columns}
                  rowBuffer={10}
                  defaultColDef={defaultColDef}
                  context={{ readOnly }}
                />
              </CollapseTableWrapper>
            </Grid>
          )}
        </Grid>
      </FormCollapse>
    );
  },
);
