import React, { useCallback, useMemo } from "react";

import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 } from "uuid";

import { openDispatchingModal } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import TableWrapper from "components/AgGrid/TableWrapper";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { ruleColumnDefs } from "components/RuleBookTable/columns";

import { AgGridTables } from "constants/aggrid";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import { RuleBookPermissions } from "constants/permissions";
import { ValueSource } from "constants/ruleBooks";
import { colors } from "constants/theme";

import { getGstFee } from "containers/Settings/RuleBooks/Rules/CustomRules/ValueClause";

import { openModalLink } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import { getRuleBookById } from "selectors";

import { selectRulesAggridDataByRuleBookIdLookup } from "selectors/aggrid/rules";

import { RuleBookSummary } from "./RuleBookSummary";
import { RuleBookSummaryForm } from "./RuleBookSummaryForm";

export const InfoSpan = styled.span`
  color: ${colors.gray40};
`;

const RuleBookSummaryGrid = styled(Grid)`
  background: ${({ theme }) => theme.colors.grayF3};
  border-radius: 4px;
  padding: ${({ theme }) => theme.space[2]}px;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;

const getRowId = params => params.data.rule.id;

const RuleBookSummaryComponent = ({
  ruleBookId,
  openEditSettings,
  isEditSettingsOpen,
  readOnly,
}) => {
  return (
    <RuleBookSummaryGrid container xs={12}>
      {isEditSettingsOpen ? (
        <RuleBookSummaryForm />
      ) : (
        <RuleBookSummary
          ruleBookId={ruleBookId}
          readOnly={readOnly}
          openEditSettings={openEditSettings}
        />
      )}
    </RuleBookSummaryGrid>
  );
};

export const RuleBookTable = ({
  ruleBookId,
  isEditSettingsOpen,
  openEditSettings,
}) => {
  const rowData = useSelector(selectRulesAggridDataByRuleBookIdLookup)[
    ruleBookId
  ];

  const ruleBook = useSelector(getRuleBookById(ruleBookId));

  const readOnly = !hasPermission(ruleBook, RuleBookPermissions.update);

  const dispatch = useDispatch();

  const onClickAddCustomRule = useCallback(() => {
    dispatch(
      openDispatchingModal(ModalTypes.EditRule, window.location.hash, {
        rule: {
          criteria: [],
          category: "",
          comment: "",
          gl_code: { source: ValueSource.CONSTANT, value: "" },
          billing_tags: [],
          from_business: { source: "", attribute: "", fieldId: "", index: "" },
          id: v4(),
          input_field: { attribute: "", fieldId: "" },
          price_multiplier: 1,
          name: "",
          to_business: { source: "", attribute: "", fieldId: "", index: "" },
          value: {
            amount: { source: ValueSource.CONSTANT, value: 0 },
            offset: { source: ValueSource.CONSTANT, value: 0 },
            rounding_value: 0,
            unit_offset: { source: ValueSource.CONSTANT, value: 0 },
            unit_limit: { source: ValueSource.CONSTANT, value: 0 },
            units: { source: "", attribute: "", fieldId: "", index: "" },
            fees: [getGstFee("exclusive")],
          },
          include_if_zero: false,
          rule_book_id: ruleBookId,
        },
      }),
    );
  }, [dispatch, ruleBookId]);

  const addManagedRule = useCallback(
    () =>
      dispatch(
        openDispatchingModal(
          ModalTypes.RuleLibrary,
          window.location.hash,
          { ruleBookId },
          { ruleBookId },
        ),
      ),
    [dispatch, ruleBookId],
  );

  const handleOpenRuleTester = useCallback(() => {
    // Stash the rule data in state - it's (probably) too big for the URL method.

    openModalLink(
      ModalTypes.RuleTester,
      [[], ruleBookId, false],
      window.location.hash,
    );
  }, [ruleBookId]);

  const additionalActions = useMemo(
    () => [
      {
        title: "Add Managed Rule",
        icon: faMobile,
        onClick: addManagedRule,
        dataTour: "addManagedRule",
      },
      {
        title: "Add Custom Rule",
        icon: faMobile,
        onClick: onClickAddCustomRule,
        dataTour: "addCustomRule",
      },
      {
        title: "Test Rules",
        icon: faMobile,
        onClick: handleOpenRuleTester,
        dataTour: "testRules",
      },
    ],
    [addManagedRule, handleOpenRuleTester, onClickAddCustomRule],
  );

  return (
    <Column fullHeight>
      <Row>
        <RuleBookSummaryComponent
          ruleBookId={ruleBookId}
          openEditSettings={openEditSettings}
          isEditSettingsOpen={isEditSettingsOpen}
          readOnly={readOnly}
        />
      </Row>
      <WaitForSync requiredData={[ApiModel.RULES, ApiModel.RULE_BOOKS]}>
        <AgGridTable
          additionalActions={additionalActions}
          columnDefs={ruleColumnDefs}
          getRowId={getRowId}
          rowData={rowData}
          showGlobalSearchFilter={false}
          WrapperComponent={TableWrapper}
          tableName={AgGridTables.RULE_BOOK}
          enableBrowserTooltips
        />
      </WaitForSync>
    </Column>
  );
};
