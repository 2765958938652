import React from "react";

import Grid from "@material-ui/core/Grid";

import { CollapseHeader, FormCollapse } from "components/Form";
import { Input, TextArea, withNamespace } from "components/Form/FormikControls";

import { FinanceSubModalSection } from "constants/settings";

import { useSectionHelper } from "hooks";

export const PaymentSettings = ({ sectionHelper, namespace: ns }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.PAYMENTS,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<CollapseHeader title="Payments" />}
    >
      <Grid item xs={12} md={12}>
        <TextArea
          label="Payment Directions"
          name={withNamespace(ns, "paymentDirections")}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          label="BPay Biller Code"
          name={withNamespace(ns, "bpayBillerCode")}
          maxLength={10}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          label="BPay Short Name"
          name={withNamespace(ns, "bpayBillerShortName")}
          disabled
          tooltip="BPay short name is automatically filled after adding a Biller Code."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="BPay Long Name"
          name={withNamespace(ns, "bpayBillerLongName")}
          disabled
          tooltip="BPay long name is automatically filled after adding a Biller Code."
        />
      </Grid>
    </FormCollapse>
  );
};
