import queryString from "query-string";

import { MASTER_LEDGER_ACCOUNT } from "constants/actionTypes";

import { serializeLedgerAccount } from "lib/serializers/ledgerAccounts";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/master-ledger-accounts/", id, action),
    query: {
      changesSince,
    },
  });

export const MasterLedgerAccountAction = offlineActionCreator(
  MASTER_LEDGER_ACCOUNT,
  serializeLedgerAccount,
  urlCreator,
  false,
);
