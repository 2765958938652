import { PEN_ARCHETYPE } from "constants/actionTypes";

import { serializePenArchetype } from "lib/serializers/penArchetypes";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ livestockSaleId, id, action } = {}) =>
  urlCheckAndAddIdAndAction(
    `/v2/pen-archetypes/livestocksale/${livestockSaleId}/`,
    id,
    action,
  );

export const PenArchetypeAction = offlineActionCreator(
  PEN_ARCHETYPE,
  serializePenArchetype,
  urlCreator,
  true,
);
