export const CheckpointType = {
  NLIS_TAKE: "NLIS Take",
  NLIS_SELL: "NLIS Sell",
  NVD_UPLOAD: "NVD Upload",
  XERO_EXPORT: "Xero Export",
  SALE_CONFRIMED: "Sale Confrimed",
  MANUAL_COMPLIANCE: "Manual Compliance",
  MANUAL_COMMERCIAL: "Manual Commercial",
  REPORT_DOWNLOADED: "Report Downloaded",
};

export const ManualCheckpointType = {
  MANUAL_COMPLIANCE: "Manual Compliance",
  MANUAL_COMMERCIAL: "Manual Commercial",
};
