import React from "react";

import { faFileImage } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import Card from "components/Card";
import {
  CardGrid,
  CardMetaItem,
  SecondaryCardButton,
  SelfTitledField,
} from "components/Card/Elements";

import { formatDateString } from "lib/timeFormats";

const CardRow = styled(CardGrid)`
  grid-template-columns: 0.3fr 1.6fr 1fr 1fr 1fr auto;
  grid-template-areas: "attachment-icon carrier-name invoice-no invoice-amount delivery-date select-action";
`;

export function CarrierChargeSelectItem(props) {
  const {
    attachmentId,
    id,
    carrierName,
    invoiceAmount,
    invoiceNumber,
    deliveryDate,
    onSelectCarrierCharge,
  } = props;

  function onClickSelect() {
    onSelectCarrierCharge && onSelectCarrierCharge(id);
  }

  return (
    <Card>
      <CardRow>
        <CardMetaItem gridArea="attachment-icon">
          {attachmentId && <FontAwesomeIcon icon={faFileImage} size="2x" />}
        </CardMetaItem>
        <SelfTitledField
          title="Carrier"
          value={carrierName}
          gridArea="carrier-name"
        />
        <SelfTitledField
          title="Invoice #"
          value={invoiceNumber}
          gridArea="invoice-no"
        />
        <SelfTitledField
          title="Invoice Amount"
          value={invoiceAmount}
          gridArea="invoice-amount"
        />
        <SelfTitledField
          title="Delivery Date"
          value={deliveryDate ? formatDateString(deliveryDate) : "-"}
          gridArea="delivery-date"
        />
        <CardMetaItem gridArea="select-action">
          <SecondaryCardButton type="button" onClick={onClickSelect}>
            Select
          </SecondaryCardButton>
        </CardMetaItem>
      </CardRow>
    </Card>
  );
}

export function CarrierChargePicker(props) {
  const { carrierCharges = [], onSelectCarrierCharge } = props;
  return carrierCharges.map(
    ({
      attachmentId,
      carrierName,
      deliveryDate,
      id,
      invoiceAmount,
      invoiceNumber,
    }) => (
      <CarrierChargeSelectItem
        key={id}
        attachmentId={attachmentId}
        carrierName={carrierName}
        deliveryDate={deliveryDate}
        id={id}
        invoiceNumber={invoiceNumber}
        invoiceAmount={invoiceAmount}
        onSelectCarrierCharge={onSelectCarrierCharge}
      />
    ),
  );
}
