export const scannerMessages = {
  GET_AVAILABLE_DEVICES: "get_available_devices",
  NEW_SCAN: "new_scan",
  CONNECTION_STATUS: "connection_status",
  CONNECT_TO_DEVICE: "connect_to_device",
  DISCONNECT_FROM_DEVICE: "disconnect_from_device",
  START_DRAFT: "start_draft",
  RESEND_SCAN: "resend_scan",
  READ_SAVED_SCANS: "read_saved_scans",
  ERASE_SAVED_SCANS: "erase_saved_scans",
  UPDATE_DEVICE_TIME: "update_device_time",
  READ_DEVICE_TIME: "read_device_time",
  PING_FROM_APP: "ping_from_app",
  AVAILABLE_DEVICES: "available_devices",
  SAVED_SCANS: "saved_scans",
  DEVICE_TIME: "device_time",
  PRINT_RECEIPT: "print_receipt",
  MESSAGE: "message",
  SEND_RAW_PAYLOAD: "send_raw_payload",
  LIST_WEIGHING_SESSIONS: "list_weighing_sessions",
  WEIGHING_SESSIONS: "weighing_sessions",
  READ_WEIGHING_SESSION: "read_weighing_session",
  WEIGHING_SESSION: "weighing_session",
};

export const ScanningMode = {
  REALTIME: 0,
  DEVICE_IMPORT: 1,
  FILE_IMPORT: 2,
  MANUAL_INPUT: 3,
};

export const MIN_HUB_VERSION = 762;
export const MIN_IOS_HUB_VERSION = 1;

// TODO - BAU-1755 - when the Windows hub handles PING, drop this to the correct value.
export const MIN_HUB_VERSION_FOR_PING = 10000;

// 20 minutes.
export const DEVICE_TIME_DIFFERENCE_ERROR_THRESHOLD_ENGLISH = "20 minutes";
export const DEVICE_TIME_DIFFERENCE_ERROR_THRESHOLD = 1000 * 60 * 20;

export const ScanStatus = {
  PASS: 0,
  WARNING: 1,
  ERROR: 2,
};

export const ScanStatusColor = {
  [ScanStatus.PASS]: "success",
  [ScanStatus.WARNING]: "error",
  [ScanStatus.ERROR]: "errorRed",
};

export const UNALLOCATED = "UNALLOCATED";

export const EIDPattern = {
  FIFTEEN_DIGIT_PATTERN: /(\d{3}\d{12})/,
  SIXTEEN_DIGIT_PATTERN: /(\d{3} \d{12})/,
  HEX_EID_FORMAT: /[A-Za-z\d]{4}([A-Za-z\d]{12})/,
  ISO_26_DIGIT_PATTERN: /[AR] \d{5} \d (\d{3} \d{12})/,
  ISO_27_DIGIT_PATTERN: /[AR] \d{5} \d \d(\d{3} \d{12})/,
  ISO_24631_PATTERN: /\02\d{7}(\d{3})(\d{12})/,
  ISO_24631_W_TIMESTAMP_PATTERN:
    /\02\d{7}(\d{3})(\d{12})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
};

// When a user is typing, see if their input
export const PossibleSixteenDigitEidInput = /(\d{3} \d{0,12})|(\d{3, 15})/;
export const ScanListType = {
  SaleLot: "SaleLot",
  ScanLot: "ScanLot",
};
