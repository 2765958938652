import React from "react";

import Grid from "@material-ui/core/Grid";

import { SubtleBadge } from "components/Badge";
import { FormCollapse } from "components/Form";
import { TradingTerms } from "components/Form/FormikControls/TradingTerms";
import { Row } from "components/Layout";

import { FinanceSubModalSection } from "constants/settings";

import { useSectionHelper } from "hooks";

export const TradingTermsSection = ({ sectionHelper }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.TRADING_TERMS,
  );

  const header = (
    <Row>
      <SubtleBadge>Trading Terms </SubtleBadge>
    </Row>
  );

  return (
    <FormCollapse isOpen={isOpen} onToggle={onToggle} header={header}>
      <Grid item xs={12}>
        <TradingTerms tooltip="Enter the default sale terms for a combination of sale type, species & sale sub type.  These defaults will be applied on sale creation and can be subsequently edited if required." />
      </Grid>
    </FormCollapse>
  );
};
