import React from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { SidebarBranding } from "./SidebarBranding";

const CloseButton = styled(FontAwesomeIcon)(
  ({ theme }) => `
  color: ${theme.colors.white};
  position: absolute;
  right: ${theme.space[4]}px;
  top: ${theme.space[3]}px;
  font-size: 26px;
  ${theme.fontSizes.epsilon}px;
`,
);

const useStyles = makeStyles(theme => ({
  drawerOpen: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: "80%",
  },
  drawerClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "0px",
  },
}));

const MobileSideBar = ({
  ContentElement,
  isOpen,
  setIsOpen,
  isBrandingVisible = true,
  onAfterCloseSidebar,
}) => {
  const styles = useStyles();

  const handleDrawerClose = () => {
    setIsOpen(false);
    typeof onAfterCloseSidebar === "function" && onAfterCloseSidebar();
  };

  return (
    <Drawer
      variant="temporary"
      className={clsx({
        [styles.drawerOpen]: isOpen,
        [styles.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx({
          [styles.drawerOpen]: isOpen,
          [styles.drawerClose]: !isOpen,
        }),
      }}
      open={isOpen}
    >
      <CloseButton onClick={handleDrawerClose} icon={faTimes} />

      {isBrandingVisible && (
        <SidebarBranding closeSidebar={handleDrawerClose} isOpen={isOpen} />
      )}
      {ContentElement && (
        <ContentElement closeSidebar={handleDrawerClose} isOpen={isOpen} />
      )}
    </Drawer>
  );
};

MobileSideBar.propTypes = {
  ContentElement: PropTypes.elementType,
  isBrandingVisible: PropTypes.bool,
  isOpen: PropTypes.bool,
  onAfterCloseSidebar: PropTypes.func,
  setIsOpen: PropTypes.func,
};

export default React.memo(MobileSideBar);
