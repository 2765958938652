import React from "react";

import { Box, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { createFilterOptions } from "@material-ui/lab";
import { Form, Formik, useFormikContext } from "formik";
import { uniq } from "lodash";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { AgGridPreview } from "components/AgGrid/AgGridPreview";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import { Autocomplete, Input, Switch } from "components/Form/FormikControls";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { AgGridTables } from "constants/aggrid";
import { Column as AgGridColumn } from "constants/columns";

import { withNestedField } from "lib/agGrid";
import { BaseColumnDefinitions } from "lib/agGrid/columnDefinitions";

import { getTakeableScans } from "selectors";

const columnDefs = [
  {
    headerName: "EID",
    field: "scan.EID",
    minWidth: 90,
  },
  BaseColumnDefinitions[AgGridColumn.NVD],
  withNestedField(AgGridColumn.VENDOR_NAME, "saleLot"),

  BaseColumnDefinitions[AgGridColumn.VENDOR_PIC],
  withNestedField(AgGridColumn.BUYER_NAME, "saleLot"),
  withNestedField(AgGridColumn.DESTINATION_PIC, "saleLot"),
];

const P2PAuthorisationLevel = {
  VENDOR: 1,
  BUYER: 2,
  BOTH_OR_OTHER: 3,
};

const validationSchema = Yup.object().shape({
  authoriserFirstName: Yup.string().max(50).required("Required"),
  authoriserLastName: Yup.string().max(50).required("Required"),
  disclaimerAccepted: Yup.boolean()
    .required("The disclaimer must be accepted.")
    .oneOf([true], "The disclaimer must be accepted."),
  authorityLevel: Yup.number().typeError("Required").required("Required"),
});

const Disclaimer = () => {
  const { values } = useFormikContext();

  const { authoriserFirstName, authoriserLastName, authorityLevel } = values;
  if (authoriserFirstName && authoriserLastName && authorityLevel) {
    return (
      <>
        <Grid item xs={9}>
          {`I warrant that I am authorised by ${authoriserFirstName} ${authoriserLastName} to 
          notify MLA of this transfer on their behalf. I acknowledge that I am liable for all
          losses and damages arising out of this warrant being incorrect and
          indemnify MLA for those losses or damages.`}
        </Grid>
        <Grid item xs={3}>
          <Switch name="disclaimerAccepted" label="Accept Disclaimer" />
        </Grid>
      </>
    );
  }
};

export const ConfirmTransfer = ({ toggleShowConfirm, handleTransfer }) => {
  const rowData = useSelector(getTakeableScans);

  const initialValues = {
    authoriserFirstName: "",
    authoriserLastName: "",
    authorityLevel: null,
    disclaimerAccepted: false,
  };

  // If we are doing a P2P for many different buyers and vendors at once, avoid using the wrong authority
  const buyerCount = uniq(rowData.map(row => row.saleLot.buyer_id)).length;
  const vendorCount = uniq(
    rowData.map(row => row.consignment.vendor_id),
  ).length;

  const options = [
    {
      value: P2PAuthorisationLevel.VENDOR,
      label: "Vendor",
      disabled: vendorCount !== 1,
    },
    {
      value: P2PAuthorisationLevel.BUYER,
      label: "Buyer",
      disabled: buyerCount !== 1,
    },
    { value: P2PAuthorisationLevel.BOTH_OR_OTHER, label: "Both/Other" },
  ];

  return (
    <Dialog open onClose={toggleShowConfirm} maxWidth="lg" fullWidth>
      <DialogTitle onClose={toggleShowConfirm}>
        Are you sure you wish to Transfer the following EIDs?
      </DialogTitle>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleTransfer}
      >
        <Form>
          <DialogContent dividers>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Autocomplete
                  filterOptions={createFilterOptions()}
                  freeSolo={false}
                  label="Authority"
                  name="authorityLevel"
                  options={options}
                  required
                  getOptionValue={option => option?.value}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  label="Authoriser's First Name"
                  name="authoriserFirstName"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  label="Authoriser's Last Name"
                  name="authoriserLastName"
                  required
                />
              </Grid>
              <Disclaimer />
            </Grid>
            <Box p={2}>
              <Divider />
            </Box>
            <CollapseTableWrapper>
              <AgGridPreview
                rowData={rowData}
                columnDefs={columnDefs}
                tableName={AgGridTables.NLIS_CONFIRM_TRANSFER}
              />
            </CollapseTableWrapper>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleShowConfirm}>Cancel</Button>
            <Button data-tour="submit" type="submit">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};
