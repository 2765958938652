export function onCellEditRequestFactory(
  colId,
  actionCreator,
  dataKey,
  dispatch,
  type,
) {
  return params => {
    const { api, column, node, value } = params;
    const buyers = [];
    api.forEachNode(rowNode => {
      if (column.getId() === colId) {
        if (node.id !== rowNode.id) {
          buyers.push(rowNode.data.fileData);
        } else {
          buyers.push({ ...rowNode.data.fileData, [dataKey]: value });
        }
      }
    });
    dispatch(actionCreator(buyers, type));
  };
}
export function getRowId(params) {
  return params.data.fileData.id;
}
