import React from "react";

import { useSelector } from "react-redux";

import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { DialogTitle, ZoomyDialog } from "components/MaterialDialog";
import MessageBox from "components/MessageBox";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";

import { getContextByModalType } from "selectors";

import { Summary } from "./Summary";

export function AllocatePenScanLotsModal(props) {
  const { onClose } = props;

  const { mapping } =
    useSelector(getContextByModalType(ModalTypes.AllocatePenScanLots)) || {};

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="lg" fullWidth>
      <WaitForSync
        requiredData={[
          ApiModel.AUCTION_PENS,
          ApiModel.PEN_SCAN_LOTS,
          ApiModel.SALE_LOTS,
          ApiModel.RECEIVAL_LOTS,
          ApiModel.PROPERTIES,
          ApiModel.CONSIGNMENTS,
          ApiModel.PEN_ARCHETYPES,
        ]}
      >
        <DialogTitle onClose={onClose}>
          <Row justifyBetween alignCenter>
            <Row alignCenter>Allocate Pen Scans to Sale Lots</Row>
          </Row>
        </DialogTitle>
        {!mapping || Object.keys(mapping).length === 0 ? (
          <MessageBox>
            You must select at least one mappable Pen Scan Lot
          </MessageBox>
        ) : (
          <Summary onClose={onClose} />
        )}
      </WaitForSync>
    </ZoomyDialog>
  );
}
