import React, { memo } from "react";

import { isEqual } from "lodash";
import { connect, useSelector } from "react-redux";

import { CompactLotCard } from "components/LotCard/CompactLotCard";

import { getConsignmentCode } from "lib/consignments";

import {
  getBusinessById,
  getConsignmentById,
  getSaleLotsBySale,
} from "selectors";

const MESSAGE_EMPTY_SALE_LOTS = "There are no Sale Lots to display";

const getSaleLotsBySaleById = saleLotId => state =>
  getSaleLotsBySale(state).find(saleLot => saleLot.id === saleLotId);

const ConnectedCompactLotCard = ({
  onSelectSaleLot,
  isSelected,
  saleLotId,
}) => {
  const saleLot = useSelector(getSaleLotsBySaleById(saleLotId)) || {};
  const consignment =
    useSelector(getConsignmentById(saleLot.consignment_id)) || {};
  const vendor = useSelector(getBusinessById(consignment.vendor_id)) || {};

  const consignmentCode = getConsignmentCode(consignment);
  const vendorName = vendor.name;

  function handleSelectSaleLot() {
    typeof onSelectSaleLot === "function" && onSelectSaleLot(saleLotId);
  }

  return (
    <CompactLotCard
      {...saleLot}
      consignmentCode={consignmentCode}
      title={vendorName}
      selected={isSelected}
      onClick={handleSelectSaleLot}
    />
  );
};

function CompactSaleLotCardListComponent(props) {
  const {
    saleLots,
    selectedIds,
    onSelectSaleLot,
    emptyMessage = MESSAGE_EMPTY_SALE_LOTS,
  } = props;
  const unusedSelectedIds = new Set(selectedIds);

  if (!saleLots.length) {
    return emptyMessage;
  }

  return saleLots.map(saleLot => {
    const isSelected = unusedSelectedIds.has(saleLot.id);
    unusedSelectedIds.delete(saleLot.id);
    return (
      <ConnectedCompactLotCard
        key={saleLot.id}
        saleLotId={saleLot.id}
        isSelected={isSelected}
        onSelectSaleLot={onSelectSaleLot}
      />
    );
  });
}

const CompactSaleLotCardList = memo(CompactSaleLotCardListComponent, (a, b) => {
  return (
    isEqual(a.selectedIds, b.selectedIds) &&
    isEqual(a.orderedIds, b.orderedIds) &&
    a.emptyMessage === b.emptyMessage
  );
});

function mapStateToProps(state, props) {
  const { orderedIds = null } = props;
  let saleLots = [];

  if (Array.isArray(orderedIds)) {
    if (orderedIds.length) {
      const saleLotsList = getSaleLotsBySale(state, props);
      const saleLotIndex = new Map();
      for (const saleLot of saleLotsList) {
        saleLotIndex.set(saleLot.id, saleLot);
      }

      for (const saleLotId of orderedIds) {
        const saleLot = saleLotIndex.get(saleLotId);
        if (saleLot) {
          saleLots.push(saleLot);
        }
      }
    }
  } else {
    saleLots = getSaleLotsBySale(state, props);
  }

  return {
    saleLots,
  };
}

export default connect(mapStateToProps)(CompactSaleLotCardList);
