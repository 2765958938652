import { connect } from "react-redux";

import { patchConsignment, patchSaleLot } from "actions";

import { CarrierChargePicker } from "components/CarrierChargePicker";

import { getBusinesses, getCarrierChargesListSelector } from "selectors";

function attachCarrierNameToCharge(businessesByIdMap) {
  return function attachCarrierName(carrierCharge) {
    const carrierBusiness = businessesByIdMap[carrierCharge.carrier];
    if (carrierBusiness) {
      carrierCharge.carrierName = carrierBusiness.name;
    } else {
      carrierCharge.carrierName = "";
    }
    return carrierCharge;
  };
}

function mapStateToProps(state, props) {
  const businesses = getBusinesses(state);
  return {
    carrierCharges: getCarrierChargesListSelector(state, props).map(
      attachCarrierNameToCharge(businesses),
    ),
    businesses,
  };
}

function mergeProps(
  { carrierCharges },
  { patchConsignment, patchSaleLot },
  { target, id, onAfterSelectCarrierCharge },
) {
  function onSelectCarrierCharge(carrierChargeId) {
    if (target === "CONSIGNMENT") {
      patchConsignment({ id, carrierChargeId }, id);
    } else {
      throw new Error("Target not yet supported");

      // TODO this is not yet implemented
      // eslint-disable-next-line no-unreachable
      patchSaleLot({ id, carrierChargeId });
    }

    onAfterSelectCarrierCharge();
  }

  return {
    carrierCharges,
    onSelectCarrierCharge,
  };
}
export default connect(
  mapStateToProps,
  { patchConsignment, patchSaleLot },
  mergeProps,
)(CarrierChargePicker);
