import React, { useState } from "react";

import { Tab, Tabs } from "@material-ui/core";

import { DialogContent, DialogTitle } from "components/MaterialDialog";
import { LedgerEntryResults } from "components/RuleTester/Results/LedgerEntries";
import {
  ResultConfigs,
  SaleDataResults,
} from "components/RuleTester/Results/SaleData";
import { TabPanel } from "components/TabSelector";

export function RuleTestResults(props) {
  const { results } = props;

  const tabList = [
    {
      label: "Ledger Entries",
      tabContents: <LedgerEntryResults results={results} />,
    },
  ].concat(
    Object.values(ResultConfigs).map(renderConfigKey => ({
      label: renderConfigKey,
      tabContents: (
        <SaleDataResults results={results} renderConfigKey={renderConfigKey} />
      ),
    })),
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <DialogTitle>
        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable">
          {tabList.map(({ label }, idx) => (
            <Tab label={label} key={`tab-${idx}`} data-tour={label} />
          ))}
        </Tabs>
      </DialogTitle>
      <DialogContent>
        {tabList.map(({ tabContents }, idx) => (
          <TabPanel value={selectedTab} index={idx} key={`tab-panel-${idx}`}>
            {tabContents}
          </TabPanel>
        ))}
      </DialogContent>
    </>
  );
}
