import React from "react";

import isArray from "lodash/isArray";
import { useSelector } from "react-redux";

import { userTypes } from "constants/users";

import { getActiveRole, getIsStaff } from "selectors";

export const ForUserType = React.memo(({ userType, children }) => {
  const activeRole = useSelector(getActiveRole);
  if (!activeRole) {
    return null;
  }

  let visible = false;
  if (isArray(userType)) {
    visible = userType.includes(activeRole.type);
  } else if (typeof userType === "string") {
    visible = userType === activeRole.type;
  }

  return visible ? children : null;
});

export const ForUserTypeOrCondition = React.memo(
  ({ userType, conditionUserType, condition, children }) => {
    const activeRole = useSelector(getActiveRole);
    if (!activeRole) {
      return null;
    }
    let visible = false;
    if (
      (condition && activeRole.type === conditionUserType) ||
      userType === activeRole.type
    ) {
      visible = true;
    }

    return visible ? children : null;
  },
);

export const ForSaleyardAdmin = ({ children }) => (
  <ForUserType userType={userTypes.SALEYARD_ADMIN}>{children}</ForUserType>
);

export const ForLivestockAgent = ({ children }) => (
  <ForUserType userType={userTypes.LIVESTOCK_AGENT}>{children}</ForUserType>
);

export const ForScaleOperator = ({ children }) => (
  <ForUserType userType={userTypes.SCALE_OPERATOR}>{children}</ForUserType>
);

export const ForStaff = ({ children }) => {
  const isStaff = useSelector(getIsStaff);
  return isStaff ? children : null;
};

export const ForNotUserType = React.memo(({ userType, children }) => {
  const activeRole = useSelector(getActiveRole);
  if (!activeRole) {
    return null;
  }
  let visible = true;
  if (isArray(userType)) {
    visible = !userType.includes(activeRole.type);
  } else if (typeof userType === "string") {
    visible = userType !== activeRole.type;
  }
  return visible ? children : null;
});
export const ForEveryoneExceptBusinessUsers = ({ children }) => (
  <ForNotUserType userType={userTypes.BUSINESS_USER}>{children}</ForNotUserType>
);

export const ForEveryoneExceptBusinessUsersAndSaleWatcher = ({ children }) => (
  <ForNotUserType userType={[userTypes.BUSINESS_USER, userTypes.SALE_WATCHER]}>
    {children}
  </ForNotUserType>
);

export const ForEveryoneExceptSaleWatcher = ({ children }) => (
  <ForNotUserType userType={userTypes.SALE_WATCHER}>{children}</ForNotUserType>
);

export default ForUserType;
