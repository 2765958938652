import React, { useEffect, useState } from "react";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { faFilter, faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { EmailAction } from "actions/email";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { FilteringModal } from "components/Email/FilteringModal";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { SmallText } from "components/Text";

import { AgGridTables } from "constants/aggrid";
import { EmailColId } from "constants/columnDefinitions";
import { LivestockSaleColId } from "constants/columns";
import { ApiModel } from "constants/loading";

import { createNamespacedColumnDefinitions } from "lib/agGrid";
import { livestockSaleColumnDefCreator } from "lib/agGrid/columnDefinitions";
import { EmailColumnDef } from "lib/agGrid/columnDefinitions/email";
import {
  dateXDaysAgo,
  formatDateTimeString,
  formatUTCToLocalDateTimeInputString,
} from "lib/timeFormats";

import { getEmailLastModified } from "selectors";

import { selectEmailAggridData } from "selectors/aggrid/email";

import { useBoolean } from "hooks";

const getRowId = row => row.data.id;

const EmailColumn = createNamespacedColumnDefinitions(EmailColumnDef, "email");

const columnDefs = [
  {
    headerName: "Details",
    children: [
      livestockSaleColumnDefCreator(LivestockSaleColId.TITLE),
      EmailColumn[EmailColId.FORMATTED_RECIPIENT],
      EmailColumn[EmailColId.REASON],
      EmailColumn[EmailColId.SUBJECT],
      EmailColumn[EmailColId.FORMATTED_SENDER],
      EmailColumn[EmailColId.ATTACHMENTS],
      EmailColumn[EmailColId.CREATED],
    ],
  },
  {
    headerName: "Status",
    children: [
      EmailColumn[EmailColId.AUTORESPONDED_TIME],
      EmailColumn[EmailColId.DISPATCHED],
      EmailColumn[EmailColId.REJECT_REASON],
      EmailColumn[EmailColId.ERROR_REASON],
      EmailColumn[EmailColId.QUEUED_TIME],
      EmailColumn[EmailColId.SENT_TIME],
      EmailColumn[EmailColId.REJECTED_TIME],
      EmailColumn[EmailColId.FAILED_TIME],
      EmailColumn[EmailColId.BOUNCED_TIME],
      EmailColumn[EmailColId.DEFERRED_TIME],
      EmailColumn[EmailColId.DELIVERED_TIME],
      // Pretty neutral really... but I don't think we'll ever even set this status?
      EmailColumn[EmailColId.AUTORESPONDED_TIME],
      EmailColumn[EmailColId.OPENED_TIME],
      EmailColumn[EmailColId.CLICKED_TIME],
      EmailColumn[EmailColId.COMPLAINED_TIME],
      EmailColumn[EmailColId.UNSUBSCRIBED_TIME],
      EmailColumn[EmailColId.ERROR_TIME],
    ],
  },
];

export function EmailTable() {
  const dispatch = useDispatch();

  const emailData = useSelector(selectEmailAggridData);
  const lastModified = useSelector(getEmailLastModified);

  const [isFiltersShowing, showFilters, hideFilters] = useBoolean(false);

  // Should this be global state?
  // Default to 7 days ago.
  // The dates here should be in the DATETIME_INPUT_FORMAT so they display in the input correctly
  const [filters, setFilters] = useState({
    createdGte: formatUTCToLocalDateTimeInputString(dateXDaysAgo(7)),
    createdLte: null,
    livestockSaleId: null,
    recipientEmail: null,
  });

  // Fetch emails on load, or change of settings.
  useEffect(() => {
    const filterData = {
      createdGte: filters.createdGte ? filters.createdGte : null,
      createdLte: filters.createdLte ? filters.createdLte : null,
      livestockSaleId: filters.livestockSaleId ? filters.livestockSaleId : null,
      recipientEmail: filters.recipientEmail ? filters.recipientEmail : null,
    };
    dispatch(EmailAction.request(filterData));
  }, [dispatch, filters]);

  const additionalActions = [
    {
      title: (
        <>
          Refresh
          {lastModified ? (
            <SmallText>
              Last Checked:{" "}
              {formatDateTimeString(new Date(lastModified * 1000))}
            </SmallText>
          ) : null}
        </>
      ),
      icon: faSync,
      onClick: () => {
        const filterData = {
          createdGte: filters.createdGte ? filters.createdGte : null,
          createdLte: filters.createdLte ? filters.createdLte : null,
          livestockSaleId: filters.livestockSaleId
            ? filters.livestockSaleId
            : null,
          recipientEmail: filters.recipientEmail
            ? filters.recipientEmail
            : null,
        };
        dispatch(EmailAction.request(filterData));
      },
      default: true,
      dataTour: "refreshEmail",
    },
    {
      title: "Change Filters",
      icon: faFilter,
      isDisabled: false,
      onClick: showFilters,
    },
    {
      title: "Email Help and Information",
      icon: faQuestion,
      isDisabled: false,
      onClick: () => {
        window.open("https://support.agrinous.com.au/sent-emails", "_blank");
      },
    },
  ];

  return (
    <WaitForSync requiredData={[ApiModel.EMAIL]}>
      <AgGridTable
        // TODO filter details in filename?
        downloadFilename="email.csv"
        columnDefs={columnDefs}
        rowData={emailData}
        tableName={AgGridTables.EMAIL}
        getRowId={getRowId}
        additionalActions={additionalActions}
        csvExportOptions={{ skipColumnGroupHeaders: true }}
        headerJustifyContent="space-between"
      />
      {isFiltersShowing ? (
        <FilteringModal
          closeSelf={hideFilters}
          setFilters={setFilters}
          filters={filters}
        />
      ) : null}
    </WaitForSync>
  );
}
