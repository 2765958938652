import {
  CLOSE_CONFIRM_MODAL,
  MODAL_CONTEXT,
  OPEN_CONFIRM_MODAL,
} from "constants/actionTypes";

/**
 * A modal action
 * @typedef {Object} ModalAction
 * @property {string} label - the button label for this action
 * @property {() => void=} onClick - callback event handler when the button is clicked
 * @property {() => Action=} onClickAction - callback event handler that executes the returned handler when the button is clicked
 * @property {Boolean=} secondary - indicates whether the button should take on the secondary button styling
 */

/**
 * Properties used to configure the look and behaviour of the Confirm Modal
 * @typedef {Object} ModalProps
 * @property {string} title - The title
 * @property {string} message - message to display
 * @property {Number=} timeOut - if set the time to display the modal for
 * @property {Boolean=} nlisSubmitting - Some kind of hack :[
 * @property {ModalAction[]} actions - The year
 */

/**
 * Returns a fully configured Open Confirm Modal action
 * @param {ModalProps} modalProps
 * @returns {{modalProps: ModalProps, type: {string}}}
 */
export function openConfirmModal(modalProps) {
  return {
    type: OPEN_CONFIRM_MODAL,
    modalProps,
  };
}
export function closeConfirmModal() {
  return {
    type: CLOSE_CONFIRM_MODAL,
  };
}

export function updateModalContext(modalType, context) {
  return {
    type: MODAL_CONTEXT.UPDATE.ACTION,
    modalType,
    context,
  };
}

export function openDispatchingModal(
  modalType,
  returnTo,
  context,
  modalProps = null,
) {
  return {
    type: MODAL_CONTEXT.OPEN.ACTION,
    modalType,
    returnTo,
    context,
    ...modalProps,
  };
}

export function setModalContext(modalType, context) {
  return {
    type: MODAL_CONTEXT.SET.ACTION,
    modalType,
    context,
  };
}
