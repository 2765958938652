export enum EContractType {
  STANDARD = "STANDARD",
  ALPA = "ALPA",
}

export enum EContractTypeLabel {
  STANDARD = "Standard Private Sale",
  ALPA = "ALPA Private Sale",
}

export enum EContractParty {
  BUYER = "buyer",
  VENDOR = "vendor",
}
