import { put, select, takeEvery } from "redux-saga/effects";

import { invalidateTableView, setTableSetting } from "actions";

import {
  INVALIDATE_TABLE_VIEW,
  SAVED_VIEW,
  SET_TABLE_VIEW,
  UPDATE_TABLE_VIEW,
} from "constants/actionTypes";
import { Settings } from "constants/settings";

import { getSavedViewById, getSetting, getTableSetting } from "selectors";

function* applySavedView(action) {
  yield put(
    setTableSetting(action.payload.table, {
      viewId: action.payload.id,
      state: JSON.parse(action.payload.content) || [],
      filters: action.payload.filters || {},
    }),
  );
}

function* onSetTableView(action) {
  const { viewId, tableName } = action;

  const state = yield select();
  const savedView = getSavedViewById(viewId)(state);

  const existingTableSetting = getTableSetting(tableName)(state);
  yield put(
    setTableSetting(tableName, {
      ...existingTableSetting,
      viewId: savedView.id,
      state: JSON.parse(savedView.content),
      filters: savedView.filters || {},
    }),
  );
  yield put(invalidateTableView(tableName));
}

function* onInvalidateTableView(action) {
  const { tableName } = action;

  const state = yield select();
  const existingTableSetting = getTableSetting(tableName)(state);

  const viewInvalidationCount =
    typeof existingTableSetting.viewInvalidationCount === "number"
      ? // When the view has been invalidated before, increment the count to invalidate it again
        existingTableSetting.viewInvalidationCount + 1
      : // When the view has never been invalidated, provision a sensible default
        0;

  yield put(
    setTableSetting(tableName, {
      ...existingTableSetting,
      viewInvalidationCount,
    }),
  );
}

function* onUpdateTableView(action) {
  const { tableName, payload } = action;

  const state = yield select();
  const tableView = getSetting(Settings.tableViews)(state)[tableName];
  yield put(
    setTableSetting(tableName, {
      ...tableView,
      ...payload,
    }),
  );
}

export default function* rootSaga() {
  yield takeEvery(SAVED_VIEW.CREATE.REQUEST, applySavedView);
  yield takeEvery(SAVED_VIEW.CREATE.SUCCESS, applySavedView);
  yield takeEvery(SET_TABLE_VIEW, onSetTableView);
  yield takeEvery(INVALIDATE_TABLE_VIEW, onInvalidateTableView);
  yield takeEvery(UPDATE_TABLE_VIEW, onUpdateTableView);
}
