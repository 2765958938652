import React from "react";

import { useField } from "formik";

import { DateInput as DateInputBase } from "components/Form/DateInput";
import { Column } from "components/Layout";

import { Error } from "./Error";
import { Label } from "./Label";

export const DateInput = ({
  disabled,
  label,
  name,
  onChangeExtra,
  output,
  required,
  tooltip,
  includeTime,
  showErrorUntouched,
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const error = (showErrorUntouched || meta.touched) && meta.error;

  const dateString =
    field.value instanceof Date &&
    // Handles case when the field value is a date, but is an invalid one
    // eslint-disable-next-line no-self-compare
    field.value.getTime() === field.value.getTime()
      ? field.value.toISOString().substr(0, 10)
      : field.value || "";

  function onChangeDateInput(event) {
    let { value } = event.target;
    if (output === "date") {
      // When the date is not valid, e.g. the user has deleted the dd, mm, or
      // YYYY sections of the input
      if (value === "") {
        value = null;
      } else {
        value = new Date(value);
      }
    }
    setValue(value).then(() => {
      setTouched(true);
    });
    typeof onChangeExtra === "function" && onChangeExtra(value);
  }

  return (
    <Column fullWidth>
      <Label
        htmlFor={name}
        required={required}
        error={!!error}
        tooltip={tooltip}
      >
        {label}
      </Label>
      <DateInputBase
        data-tour={field.name}
        value={dateString}
        labelledBy={name}
        onBlur={field.onBlur}
        onChange={onChangeDateInput}
        disabled={disabled}
        required={required}
        name={name}
        includeTime={includeTime}
      />
      {error && <Error>{error}</Error>}
    </Column>
  );
};
