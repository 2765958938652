import React from "react";

import { QuestionnaireRow } from "components/AttachmentForm/Components";
import {
  NVDOptionToggler,
  DatePurchasedOptionsToggler,
  NVD_OPTIONS,
} from "components/AttachmentForm/NVDOptionToggles";
import { Input } from "components/Form/FormikControls";
import { Column } from "components/Layout";

export const BC0720 = ({ formikProps }) => (
  <Column>
    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.qa_accredited" label="1." />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.owned_since_birth" label="2." />
      {formikProps.values.declaration.owned_since_birth === NVD_OPTIONS.NO && (
        <QuestionnaireRow>
          <DatePurchasedOptionsToggler
            name="declaration.owned_since_birth_duration"
            label=""
          />
        </QuestionnaireRow>
      )}
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.consumed_material_in_withholding_period"
        label="3."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.veterinary_treatment" label="4." />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.veterinary_treatment_milk"
        label="5."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.chemical_residue_restrictions"
        label="6."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <Input
        name="declaration.additional_information"
        label="7."
        suggestedValueFieldName="declaration.additional_information_suggested"
      />
    </QuestionnaireRow>
  </Column>
);
