import React, { useEffect, useRef, useState } from "react";

import groupBy from "lodash/groupBy";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { StyledInput as Input } from "components/Form/FormikControls/layout";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { OptionToggler } from "components/OptionToggler";

import { ApiModel } from "constants/loading";
import { getRoleDisplayName, ROLE_TYPES } from "constants/users";

import {
  getIsFetchingRoles,
  getIsStaff,
  selectAuthSearchIndex,
  selectAvailableRolesList,
} from "selectors";

import { useIsSmallMobile } from "hooks";

import { RoleList } from "./RoleList";

const RoleGroupTitle = styled.legend(
  ({ theme }) => `
display: block;
font-weight: 500;
text-align: center;
width: 100%;
padding: ${theme.space[1]}px ${theme.space[2]}px;
font-size: ${theme.fontSizes.delta}px;
border-top: 1px solid ${theme.colors.gray7A};
border-bottom: 1px solid ${theme.colors.gray7A};
background-color: ${theme.colors.white};
`,
);

const FilterInput = styled(Input)`
  text-align: center;
  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }
  justify-self: center;
`;

const FilterRow = styled(Row)`
  padding: 1rem;
  background: ${({ theme }) => theme.colors.grayF3};
`;

const StillLoadingRow = styled(Row)`
  color: ${({ theme }) => theme.colors.syncing};
`;

const ApiModelByRoleType = {
  [ROLE_TYPES.BUSINESS_USER]: [ApiModel.BUSINESS_USER_ROLES],
  [ROLE_TYPES.SALE_WATCHER]: [ApiModel.SALE_WATCHER_ROLES],
  [ROLE_TYPES.SALEYARD_OPERATOR]: [ApiModel.SALEYARD_ADMIN_ROLES],
  [ROLE_TYPES.SCALE_OPERATOR]: [ApiModel.SCALE_OPERATOR_ROLES],
  [ROLE_TYPES.STOCK_AGENT]: [ApiModel.LIVESTOCK_AGENT_ROLES],
};

export const RoleTypeGroups = React.memo(({ onRoleChange }) => {
  const [filterRoleType, setFilterRoleType] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterValue, setFilterValue] = React.useState("");
  const [searchTimeout, setSearchTimeout] = React.useState(undefined);

  const fuse = useSelector(selectAuthSearchIndex);
  const roles = useSelector(selectAvailableRolesList);

  const isFetchingRoles = useSelector(getIsFetchingRoles);
  const isStaff = useSelector(getIsStaff);

  const handleChangeFilter = event => {
    const { value } = event.target;
    setSearchText(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const newFilterValue = value.trim().toLowerCase();
    if (!filterValue) {
      setFilterValue(newFilterValue);
    } else {
      setSearchTimeout(
        setTimeout(() => {
          setFilterValue(newFilterValue);
        }, 400),
      );
    }
  };

  const rolesByType = React.useMemo(() => {
    const results = filterValue
      ? fuse.search(searchText).map(fuseResult => fuseResult.item)
      : roles;
    return groupBy(results, "type");
  }, [searchText, filterValue, fuse, roles]);

  // Show staff results in all sections while things are still loading,  but discimrate by the available types for everyone else.
  const roleGroups = Object.keys(
    isStaff && isFetchingRoles ? ApiModelByRoleType : rolesByType,
  );
  const roleOptions = roleGroups.map(roleType => ({
    label: getRoleDisplayName(roleType),
    value: roleType,
  }));
  const inputRef = useRef(null);
  const notMobile = !useIsSmallMobile();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <FilterRow justifyCenter>
        <Column>
          {notMobile && (
            <OptionToggler
              options={roleOptions}
              name="roleFilter"
              onChange={setFilterRoleType}
              value={filterRoleType}
            />
          )}
          <FilterInput
            value={searchText}
            onChange={handleChangeFilter}
            placeholder="Search"
            ref={inputRef}
          />
          {isFetchingRoles && (
            <StillLoadingRow fullWidth justifyCenter>
              Still loading some roles...
            </StillLoadingRow>
          )}
        </Column>
      </FilterRow>

      {roleGroups.map(roleType => {
        const rolesOfType = rolesByType[roleType] || [];
        const roleTypeDisplayName = getRoleDisplayName(roleType);
        if (filterRoleType && filterRoleType !== roleType) {
          return null;
        }

        const requiredData = [ApiModelByRoleType[roleType] || ApiModel.ROLES];

        return (
          <Column key={roleType}>
            <RoleGroupTitle>
              Available {roleTypeDisplayName} Roles
            </RoleGroupTitle>
            <WaitForSync requiredData={requiredData}>
              <RoleList roles={rolesOfType} onRoleChange={onRoleChange} />
            </WaitForSync>
          </Column>
        );
      })}
    </>
  );
});
