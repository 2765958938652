import * as Sentry from "@sentry/react";
import createSagaMiddleware from "redux-saga";

import { isSentryActive } from "lib/sentry/config";

export const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    /* eslint-disable no-console */
    console.error(error);
    console.debug(sagaStack);
    /* eslint-enable no-console */
    if (isSentryActive) {
      Sentry.captureException(error);
    }
  },
});
