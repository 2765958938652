import { TEST_RULEBOOK } from "constants/actionTypes";

export function requestTestRuleBook(
  livestockSaleIds,
  ruleBookId,
  ruleBookData,
  options,
) {
  return {
    type: TEST_RULEBOOK.REQUEST,
    livestockSaleIds,
    ruleBookId,
    ruleBookData,
    options,
  };
}
