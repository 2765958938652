import { MASTER_LABEL } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeMasterLabel } from "lib/serializers/masterLabels";

const fetch = apiModelOfflineFetchReducer(MASTER_LABEL, {
  deserializer: deserializeMasterLabel,
  clearOnRequest: true,
});

export default fetch;
