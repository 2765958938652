import React, { useState, useMemo, memo } from "react";

import Grid from "@material-ui/core/Grid";
import { Formik } from "formik";
import Fuse from "fuse.js";
import { isEqual, uniqBy } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { EIDListDetailed } from "components/EIDList";
import { Button } from "components/Form";
import { SelectField, Input } from "components/Form/FormikControls";

const fuseOptions = {
  shouldSort: true,
  keys: ["EID", "animal.nlis_id"],
  distance: 200,
  threshold: 0.1,
};

const SHOW_NUM = 10;

const CenteredText = styled.div`
  text-align: center;
`;

const sortScans = (s1, s2) => {
  // Move scans that have issues to the top.
  if (s1.hasIssues && !s2.hasIssues) {
    return -1;
  }
  if (!s1.hasIssues && s2.hasIssues) {
    return 1;
  }

  // Move EU cattle to the top
  if (s1.EU_status === "Y" && s2.EU_status === "N") {
    return -1;
  }
  if (s1.EU_status === "N" && s2.EU_status === "Y") {
    return 1;
  }

  return 0;
};

export const CollapseSection = memo(
  ({ scans = [], readOnly }) => {
    const [showAll, setShowAll] = useState(false);

    const erpFilterOptions = useMemo(
      () =>
        uniqBy(scans, "ERP_status")
          .filter(scan => scan.ERP_status)
          .map(scan => ({
            value: scan.ERP_status,
            label: scan.ERP_status,
          })),
      [scans],
    );

    const ltFilterOptions = useMemo(
      () =>
        uniqBy(scans, "lifetime_traceability")
          .filter(scan => scan.lifetime_traceability)
          .map(scan => ({
            value: scan.lifetime_traceability,
            label: scan.lifetime_traceability,
          })),
      [scans],
    );

    const fuseIndex = useMemo(() => {
      return new Fuse(scans, fuseOptions);
    }, [scans]);

    const initialValues = {
      erpFilter: null,
      searchText: "",
      ltFilter: null,
    };

    const filterAndSortScans = ({ erpFilter, searchText, ltFilter }) => {
      let filteredScans =
        searchText !== initialValues.searchText
          ? fuseIndex.search(searchText).map(fuseResult => fuseResult.item)
          : scans;

      if (erpFilter || ltFilter) {
        filteredScans = filteredScans.filter(
          scan =>
            (erpFilter ? scan.ERP_status === erpFilter : true) &&
            (ltFilter ? scan.lifetime_traceability === ltFilter : true),
        );
      }

      filteredScans = filteredScans.sort(sortScans);

      const filteredCount = filteredScans.length;

      if (!showAll) {
        filteredScans = filteredScans.slice(0, SHOW_NUM);
      }

      return [filteredScans, filteredCount];
    };

    const showERPFilter = erpFilterOptions.length > 0;
    const showLtFilter = ltFilterOptions.length > 0;
    let filterGridWidth = 12;

    if (showERPFilter && showLtFilter) {
      filterGridWidth = 4;
    } else if (showERPFilter || showLtFilter) {
      filterGridWidth = 6;
    }

    return (
      <Formik initialValues={initialValues}>
        {({ values }) => {
          const [scans, count] = filterAndSortScans(values);
          return (
            <>
              <Grid item xs={12} sm={filterGridWidth}>
                <Input
                  label="EID/NLIS ID"
                  name="searchText"
                  placeholder="Search"
                  disabled={readOnly}
                />
              </Grid>
              {showERPFilter && (
                <Grid item xs={12} sm={filterGridWidth}>
                  <SelectField
                    label="ERP Status"
                    name="erpFilter"
                    options={erpFilterOptions}
                    isClearable
                    disabled={readOnly}
                  />
                </Grid>
              )}

              {showLtFilter > 0 && (
                <Grid item xs={12} sm={filterGridWidth}>
                  <SelectField
                    label="Lifetime Traceability"
                    name="ltFilter"
                    options={ltFilterOptions}
                    isClearable
                    disabled={readOnly}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid container spacing={2} direction="column">
                  <EIDListDetailed
                    eids={scans.map(({ EID }) => EID)}
                    emptyMessage=""
                  />
                  <CenteredText>
                    Showing {Math.min(count, showAll ? count : SHOW_NUM)} of{" "}
                    {count} scans matching the search criteria.
                  </CenteredText>
                  {!showAll && SHOW_NUM < count && (
                    <Button onClick={() => setShowAll(true)}>Show All</Button>
                  )}
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    );
  },
  (a, b) => isEqual(a.scans, b.scans),
);

CollapseSection.propTypes = {
  scans: PropTypes.arrayOf(PropTypes.object),
};
