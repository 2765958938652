import {
  GET_SPECIES_ATTRIBUTES,
  GET_BREEDS_SUCCESS,
  GET_SEXES_SUCCESS,
  GET_AGES_SUCCESS,
  GET_GRADES_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  GET_EXEMPTIONS_SUCCESS,
  GET_ROUNDS,
  GET_SPECIES_ATTRIBUTES_FAILURE,
  GET_SALE_DEFINITIONS_SUCCESS,
  GET_SPECIES,
  GET_DENTITION,
} from "constants/actionTypes";

export const requestSpeciesAttributes = () => ({
  type: GET_SPECIES_ATTRIBUTES,
});

export const requestSpeciesAttributesFailure = () => ({
  type: GET_SPECIES_ATTRIBUTES_FAILURE,
});

export const receiveBreedsSuccess = breeds => ({
  type: GET_BREEDS_SUCCESS,
  breeds,
});

export const receiveSexesSuccess = sexes => ({
  type: GET_SEXES_SUCCESS,
  sexes,
});

export const receiveAgesSuccess = ages => ({
  type: GET_AGES_SUCCESS,
  ages,
});

export const receiveGradesSuccess = grades => ({
  type: GET_GRADES_SUCCESS,
  grades,
});

export const receiveCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  categories,
});

export const receiveExemptionsSuccess = exemptions => ({
  type: GET_EXEMPTIONS_SUCCESS,
  exemptions,
});

export const receiveRoundsSuccess = rounds => ({
  type: GET_ROUNDS.SUCCESS,
  rounds,
});

export const receiveSpeciesSuccess = species => ({
  type: GET_SPECIES.SUCCESS,
  species,
});

export const receiveDentitionSuccess = dentition => ({
  type: GET_DENTITION.SUCCESS,
  dentition,
});

export const receiveSaleDefinitionsSuccess = saleDefinitions => ({
  type: GET_SALE_DEFINITIONS_SUCCESS,
  ...saleDefinitions,
});
