import queryString from "query-string";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "actions/lib";

import { BIDDER_REGISTRATION } from "constants/actionTypes";

import { serialiseBidderRegistration } from "lib/bidders";

const urlCreator = ({
  changesSince,
  saleyardName,
  livestockSaleId,
  id,
  action,
} = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction(
      `/v2/saleyards/${saleyardName}/sales/${livestockSaleId}/registered-bidders/`,
      id,
      action,
    ),
    query: {
      changesSince,
    },
  });

export const BidderRegistrationAction = offlineActionCreator(
  BIDDER_REGISTRATION,
  serialiseBidderRegistration,
  urlCreator,
  true,
);
