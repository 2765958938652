import { sendMessage } from "actions";

import { scannerMessages } from "constants/scanner";

import { formatTime } from "lib/timeFormats";

export const ALEIS_90X0_SCANNER_DRIVER_NAME = "aleis90x0-scanner";
export const ALEIS_90X0_CAPABILITIES = [
  scannerMessages.READ_SAVED_SCANS,
  scannerMessages.START_DRAFT,
  scannerMessages.RESEND_SCAN,
];

function writeSession(sessionName) {
  const sessionNameString =
    typeof sessionName === "string" && sessionName.length > 0
      ? sessionName.replace(/,/g, "")
      : // Ideally we would set the session name to the an local ISO8601
        // date time string, however the screen size of the 9060 can
        // only display 12 characters, or 10 characters comfortably
        formatTime(new Date());

  return `write_header 0,"${sessionNameString}",\r\n`;
}

function downloadScans() {
  return `SA\r\n`;
}

/**
 * Allows an Aleis Scanner to resend an and EID before the timeout expired
 * TIRIS = Texas Instruments Radio Identification System
 * @return {string}
 */
function clearTirisCache() {
  return `tcc\r\n`;
}

function startDraftHandler({ deviceId, draftName }) {
  return sendMessage(deviceId, writeSession(draftName));
}

function readSavedScansHandler({ deviceId }) {
  return sendMessage(deviceId, downloadScans());
}

function resendScan({ deviceId }) {
  return sendMessage(deviceId, clearTirisCache());
}

export const ALEIS_90X0_HANDLER = {
  [scannerMessages.READ_SAVED_SCANS]: readSavedScansHandler,
  [scannerMessages.START_DRAFT]: startDraftHandler,
  [scannerMessages.RESEND_SCAN]: resendScan,
};
