import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components/macro";

import SidebarButton from "./SidebarButton";

export const SideBarLabel = styled(ListItemText)`
  padding-left: 30px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 32px;
  white-space: nowrap;
`;

export const SidebarListItem = ({ onClick, icon, label, dataTour = label }) => {
  return (
    <ListItem button onClick={onClick} data-tour={dataTour}>
      <SidebarButton icon={icon} />
      <SideBarLabel primary={label} />
    </ListItem>
  );
};

export const SidebarNestedListItem = styled(ListItem)`
  padding-left: ${({ theme }) => theme.space[6]}px;
`;
