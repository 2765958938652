import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

const HeaderWrapper = styled.div`
  ${({ color, theme }) => `
    background-color: ${theme.colors[color] || theme.colors.gray44};
    max-height: 100px;
    padding-right: ${theme.space[3]}px;
    font-size: ${theme.fontSizes.epsilon}px;
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.white};
  `};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Header = ({ color, style, children, isMobile }) => (
  <HeaderWrapper color={color} style={style} isMobile={isMobile}>
    {children}
  </HeaderWrapper>
);

Header.propTypes = {
  color: PropTypes.string,
  isMobile: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Header;
export { default as HeaderDropdown } from "./Dropdown";
