import React, { useEffect, useMemo, useReducer } from "react";

import { isEmpty, omit, without } from "lodash";
import xor from "lodash/xor";
import { useDispatch, useSelector } from "react-redux";

import { BillingDocumentAction } from "actions";

import { RecipientList } from "components/BillingWorkFlow/Send/RecipientList";
import { ReviewAndSend } from "components/BillingWorkFlow/Send/ReviewAndSend";
import { BottomSticky, Button, SecondaryButton } from "components/Form";
import { Column, Row } from "components/Layout";
import MessageBox from "components/MessageBox";
import { AbsolutePaper } from "components/Paper";

import { EMPTY_OBJECT } from "lib";

import { caseInsensitiveCompare } from "lib/compare";

import { selectEmailRecipientsByBillingDocumentIdLookup } from "selectors";

import { useToggle } from "hooks";

const toggleRecipientReducer = (state, action) => {
  const {
    billingDocumentId,
    businessUser,
    emailRecipient,
    rawEmailAddress,
    add,
    remove,
  } = action;

  const existingDocumentState = state[billingDocumentId] || EMPTY_OBJECT;

  if (add || remove) {
    // Handle re-init / user selecting more documents to dispatch.
    const newState = {
      ...state,
      ...add,
    };
    return omit(newState, remove);
  }

  const businessUsers = businessUser
    ? xor(existingDocumentState.businessUsers, [businessUser])
    : existingDocumentState.businessUsers;

  const emailRecipients = emailRecipient
    ? xor(existingDocumentState.emailRecipients, [emailRecipient])
    : existingDocumentState.emailRecipients;

  return {
    ...state,
    [billingDocumentId]: {
      ...existingDocumentState,
      businessUsers,
      emailRecipients,
      rawEmailAddress: rawEmailAddress || existingDocumentState.rawEmailAddress,
    },
  };
};

export const SelectRecipients = ({ billingDocuments, onClose }) => {
  const emailRecipientsByBillingDocumentIdLookup = useSelector(
    selectEmailRecipientsByBillingDocumentIdLookup,
  );

  const [toggleRecipientState, toggleRecipientAction] = useReducer(
    toggleRecipientReducer,
    billingDocuments.reduce((acc, row) => {
      const { id } = row.billingDocument;
      const defaults = {
        businessUsers: emailRecipientsByBillingDocumentIdLookup[
          id
        ].businessUsers.filter(bu => bu.isAccountsRecipient),
        emailRecipients: emailRecipientsByBillingDocumentIdLookup[
          id
        ].emailRecipients.filter(er => er.isAccountsRecipient),
      };
      acc[id] = defaults;
      return acc;
    }, {}),
  );

  const sortKey = billingDocument =>
    `${billingDocument.type} ${billingDocument.number}`;
  const sortedBillingDocuments = useMemo(() => {
    return billingDocuments.sort((a, b) =>
      caseInsensitiveCompare(sortKey(a), sortKey(b)),
    );
  }, [billingDocuments]);

  useEffect(() => {
    // Add any new ones.
    const additions = billingDocuments.reduce((acc, { billingDocument }) => {
      if (isEmpty(toggleRecipientState[billingDocument.id])) {
        const defaults = {
          businessUsers: emailRecipientsByBillingDocumentIdLookup[
            billingDocument.id
          ].businessUsers.filter(bu => bu.isAccountsRecipient),
          emailRecipients: emailRecipientsByBillingDocumentIdLookup[
            billingDocument.id
          ].emailRecipients.filter(er => er.isAccountsRecipient),
        };
        acc[billingDocument.id] = defaults;
      }
      return acc;
    }, {});

    // Remove any non-existent ones.
    const inValidKeys = without(
      Object.keys(toggleRecipientState),
      ...billingDocuments.map(({ billingDocument }) => billingDocument.id),
    );

    if (!isEmpty(inValidKeys) || !isEmpty(additions)) {
      toggleRecipientAction({ remove: inValidKeys, add: additions });
    }

    // keep the rest the same
  }, [
    billingDocuments,
    emailRecipientsByBillingDocumentIdLookup,
    toggleRecipientState,
  ]);

  const dispatch = useDispatch();

  const onSend = emailTemplateText => {
    const payload = Object.entries(toggleRecipientState)
      .map(([billingDocumentId, destinations]) => ({
        billing_document_id: billingDocumentId,
        email_recipients: destinations.emailRecipients.map(er => ({
          id: er.id,
          type: er.source.type,
        })),
        raw_email_address: destinations.rawEmailAddress,
        email_template_text: emailTemplateText,
        business_user_ids: destinations.businessUsers.map(bu => bu.id),
      }))
      .filter(
        ({ email_recipients, raw_email_address, business_user_ids }) =>
          email_recipients.length > 0 ||
          raw_email_address ||
          business_user_ids.length > 0,
      );
    dispatch(BillingDocumentAction.email(payload));
    onClose();
  };

  const [showConfirm, toggleShowConfirm] = useToggle(false);

  if (billingDocuments.length === 0) {
    return (
      <AbsolutePaper>
        <Column full>
          <MessageBox>Select documents to send</MessageBox>
        </Column>
      </AbsolutePaper>
    );
  }

  return (
    <AbsolutePaper>
      <form
        // Use a html form so we get email validation for free.
        onSubmit={e => {
          toggleShowConfirm();
          e.preventDefault();
        }}
      >
        <h1>Select Email Recipients</h1>

        {sortedBillingDocuments.map(({ billingDocument }) => {
          return (
            <RecipientList
              key={billingDocument.id}
              billingDocumentId={billingDocument.id}
              toggleRecipientState={
                toggleRecipientState[billingDocument.id] || EMPTY_OBJECT
              }
              toggleRecipientAction={toggleRecipientAction}
            />
          );
        })}

        <BottomSticky>
          <Row full background="white">
            <SecondaryButton type="button" onClick={onClose}>
              Cancel
            </SecondaryButton>
            <Button type="submit">Next Step (Review Message)</Button>
          </Row>
        </BottomSticky>
      </form>
      {showConfirm && (
        <ReviewAndSend onSend={onSend} onClose={toggleShowConfirm} />
      )}
    </AbsolutePaper>
  );
};
