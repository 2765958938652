import React from "react";

import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import { updateCurrentDraftingDecision } from "actions";

import Button from "components/Button";
import { Input } from "components/Form/FormikControls";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

interface FormValues {
  totalMassGrams: string;
}

interface WeightOverrideDialogProps {
  open: boolean;
  toggleOpen: () => void;
  singleWeighId: string;
}

const initialValues: FormValues = { totalMassGrams: "0" };

export const WeightOverrideDialog: React.FC<WeightOverrideDialogProps> = ({
  open,
  toggleOpen,
  singleWeighId,
}) => {
  const dispatch = useDispatch();

  function handleManualWeight({ totalMassGrams }: FormValues): void {
    dispatch(
      updateCurrentDraftingDecision(singleWeighId, {
        totalMassGrams: Number(totalMassGrams),
        overrideWeight: true,
      }),
    );
    toggleOpen();
  }

  return (
    <Dialog id="override-weight" open={open} onClose={toggleOpen} maxWidth="lg">
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={handleManualWeight}
      >
        <Form>
          <DialogTitle onClose={toggleOpen}>Set Manual Weight</DialogTitle>
          <DialogContent>
            <Input
              label="Weight (kg)"
              name="totalMassGrams"
              type="number"
              multiplier={1000}
              afterSymbol="Kg"
              decimal
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button data-tour="cancel" type="button" onClick={toggleOpen}>
              Cancel
            </Button>
            <Button data-tour="save" type="submit">
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};
