import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.div``;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 100%;
`;

const MobileImageViewer = ({ imageUrl }) => (
  <Wrapper>
    <Image src={imageUrl} alt="" />
  </Wrapper>
);

MobileImageViewer.propTypes = {
  imageUrl: PropTypes.string,
};

MobileImageViewer.defaultProps = {
  imageUrl: "",
};

export default MobileImageViewer;
