export const MAX_TO_SHOW_IN_DROPDOWN = 12;
export const AGRINOUS_ADMIN = "AgriNous Admin";

export const userTypes = {
  SALEYARD_ADMIN: "saleyard_admin",
  LIVESTOCK_AGENT: "livestock_agent",
  BUSINESS_USER: "business_user",
  SCALE_OPERATOR: "scale_operator",
  SALE_WATCHER: "sale_watcher",
};

const userTypeNames = {
  [userTypes.SALEYARD_ADMIN]: "Saleyard Admin",
  [userTypes.LIVESTOCK_AGENT]: "Livestock Agent",
  [userTypes.BUSINESS_USER]: "Business User",
  [userTypes.SCALE_OPERATOR]: "Scale Operator",
  [userTypes.SALE_WATCHER]: "Sale Watcher",
};

const userTypeNamesPlural = {
  [userTypes.SALEYARD_ADMIN]: "Saleyard Admins",
  [userTypes.LIVESTOCK_AGENT]: "Livestock Agents",
  [userTypes.BUSINESS_USER]: "Business Users",
  [userTypes.SCALE_OPERATOR]: "Scale Operators",
  [userTypes.SALE_WATCHER]: "Sale Watchers",
};

export const ROLE_TYPES = {
  BUSINESS_USER: "business_user",
  PROCESSOR: "PROCESSOR",
  PRODUCER: "PRODUCER",
  SALE_WATCHER: "sale_watcher",
  SALEYARD_OPERATOR: "saleyard_admin",
  SCALE_OPERATOR: "scale_operator",
  STOCK_AGENT: "livestock_agent",
  TRANSPORTER: "TRANSPORTER",
  STAFF: "STAFF",
  UNKNOWN_ROLE: "UNKNOWN_ROLE",
};

export const ROLE_NAMES = {
  [ROLE_TYPES.BUSINESS_USER]: "Business",
  [ROLE_TYPES.PROCESSOR]: "Processor",
  [ROLE_TYPES.PRODUCER]: "Producer",
  [ROLE_TYPES.SALE_WATCHER]: "Sale Watcher",
  [ROLE_TYPES.SALEYARD_OPERATOR]: "Saleyard Operator",
  [ROLE_TYPES.SCALE_OPERATOR]: "Scale Operator",
  [ROLE_TYPES.STOCK_AGENT]: "Stock Agent",
  [ROLE_TYPES.TRANSPORTER]: "Transporter",
  [ROLE_TYPES.STAFF]: "AgriNous Staff",
  [ROLE_TYPES.UNKNOWN_ROLE]: "Unknown Role",
} as const;

export type RoleName = ValueInObj<typeof ROLE_NAMES>;

export const ALL_ROLES = [
  ROLE_TYPES.BUSINESS_USER,
  ROLE_TYPES.PROCESSOR,
  ROLE_TYPES.PRODUCER,
  ROLE_TYPES.SALE_WATCHER,
  ROLE_TYPES.SALEYARD_OPERATOR,
  ROLE_TYPES.SCALE_OPERATOR,
  ROLE_TYPES.STOCK_AGENT,
  ROLE_TYPES.TRANSPORTER,
  ROLE_TYPES.STAFF,
];

/**
 * Returns the title case display text for the given role
 * @param {Symbol} Role
 * @return {String}
 */
export function getRoleDisplayName(role: RoleName) {
  return ROLE_NAMES[role] || ROLE_NAMES[ROLE_TYPES.UNKNOWN_ROLE];
}

/**
 * Returns the title case display text for the given user type
 * @param {Symbol} UserType
 * @return {String}
 */
export function getUserTypeDisplayName(userType): string {
  return userTypeNames[userType] || "-";
}

/**
 * Returns the pluralized title case display text for the given user type
 * @param {Symbol} UserType
 * @return {String}
 */
export function getUserTypeDisplayNamePlural(userType) {
  return userTypeNamesPlural[userType] || "-";
}
