import React from "react";

import { Column } from "components/Layout";
import { ExternalFileLink } from "components/Link";

import { RESIZABLE_IMAGE_EXTENSIONS } from "constants/files";

import { extractFileExtensionFromURL } from "lib";

import { Crop } from "./Cropper";

export const Preview = ({ file, handleDelete, handleReplace }) => {
  if (!file || !file.image_url) {
    return null;
  }

  if (
    RESIZABLE_IMAGE_EXTENSIONS.includes(
      extractFileExtensionFromURL(file.image_url),
    )
  ) {
    return (
      <Crop
        file={file}
        handleDelete={handleDelete}
        handleReplace={handleReplace}
        key={file?.id}
      />
    );
  }

  return (
    <Column justifyCenter alignCenter>
      <ExternalFileLink imageUrl={file.image_url} />
    </Column>
  );
};
