import { createSelector } from "reselect";

import { EMPTY_ARRAY } from "lib";

import { isCommentResolved, isCommentUnresolved } from "lib/comments";
import { getCombinedLotNumber } from "lib/saleLot";

import {
  getAuctionPens,
  getBusinesses,
  getComments,
  getNominations,
  getPenScanLots,
  getReceivalLots,
  getSaleLots,
  getSales,
  getWeighLots,
  selectDeploymentSaleIdBySaleLotIdLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByBuyerHashLookup,
  selectSaleLotIdsByBuyerIdLookup,
  selectSaleLotIdsByConsignmentIdLookup,
} from "selectors";

import { createLookupCombiner, createLookupSelectors } from "selectors/lib";

const commentsByObjIdLookup = (objIdLookup, isResolved) =>
  Object.entries(objIdLookup).reduce((acc, [id, comments]) => {
    acc[id] =
      comments?.length > 0 &&
      comments.some(isResolved ? isCommentResolved : isCommentUnresolved);
    return acc;
  }, {});

export const getCommentById = commentId => state =>
  getComments(state)[commentId] || null;

export const [selectCommentsBySaleLotIdLookup, getCommentsBySaleLotId] =
  createLookupSelectors(
    [getSaleLots, getComments],
    createLookupCombiner((saleLot, comments) => {
      return saleLot.commentIds
        .map(commentId => comments[commentId])
        .filter(Boolean);
    }),
    EMPTY_ARRAY,
  );

// Cant use the createLookupCombiner because it will use the id of the sale, not the deployment sale.
export const selectCommentsByDeploymentSaleIdLookup = createSelector(
  [getSales, getComments],
  (sales, comments) => {
    return Object.values(sales).reduce((acc, sale) => {
      sale.deployment_sales.forEach(deploymentSale => {
        acc[deploymentSale.deployment_sale_id] =
          deploymentSale.comment_ids?.map(commentId => comments[commentId]) ||
          [];
      });
      return acc;
    }, {});
  },
);

export const getCommentsByDeploymentSaleId = id => state =>
  selectCommentsByDeploymentSaleIdLookup(state)[id] || [];

export const [selectCommentsByReceivalLotIdLookup, getCommentsByReceivalLotId] =
  createLookupSelectors(
    [getReceivalLots, getComments],
    createLookupCombiner((receivalLot, comments) =>
      receivalLot.commentIds.map(commentId => comments[commentId]),
    ),
    EMPTY_ARRAY,
  );

export const [selectCommentsByPenScanLotIdLookup, getCommentsByPenScanLotId] =
  createLookupSelectors(
    [getPenScanLots, getComments],
    createLookupCombiner((penScanLot, comments) =>
      penScanLot.commentIds.map(commentId => comments[commentId]),
    ),
    EMPTY_ARRAY,
  );

export const [selectCommentsByWeighLotIdLookup, getCommentsByWeighLotId] =
  createLookupSelectors(
    [getWeighLots, getComments],
    createLookupCombiner((weighLot, comments) =>
      weighLot.commentIds.map(commentId => comments[commentId]),
    ),
    EMPTY_ARRAY,
  );

const selectHasResolvedCommentsBySaleLotIdLookup = createSelector(
  [selectCommentsBySaleLotIdLookup],
  commentsBySaleLotIdLookup =>
    commentsByObjIdLookup(commentsBySaleLotIdLookup, true),
);

export const getHasResolvedCommentsBySaleLotId = saleLotId => state =>
  selectHasResolvedCommentsBySaleLotIdLookup(state)[saleLotId] || false;

const selectHasUnresolvedCommentsBySaleLotIdLookup = createSelector(
  [selectCommentsBySaleLotIdLookup],
  commentsBySaleLotIdLookup =>
    commentsByObjIdLookup(commentsBySaleLotIdLookup, false),
);

export const getHasUnresolvedCommentsBySaleLotId = saleLotId => state =>
  selectHasUnresolvedCommentsBySaleLotIdLookup(state)[saleLotId] || false;

const selectHasResolvedCommentsByDeploymentSaleIdLookup = createSelector(
  [selectCommentsByDeploymentSaleIdLookup],
  commentsBySaleIdLookup => commentsByObjIdLookup(commentsBySaleIdLookup, true),
);

export const getHasResolvedCommentsByDeploymentSaleId = sale => state =>
  selectHasResolvedCommentsByDeploymentSaleIdLookup(state)[sale] || false;

const selectHasUnresolvedCommentsByDeploymentSaleIdLookup = createSelector(
  [selectCommentsByDeploymentSaleIdLookup],
  commentsBySaleIdLookup =>
    commentsByObjIdLookup(commentsBySaleIdLookup, false),
);

export const getHasUnresolvedCommentsByDeploymentSaleId = sale => state =>
  selectHasUnresolvedCommentsByDeploymentSaleIdLookup(state)[sale] || false;

const selectHasResolvedCommentsByReceivalLotIdLookup = createSelector(
  [selectCommentsByReceivalLotIdLookup],
  commentsByReceivalLotIdLookup =>
    commentsByObjIdLookup(commentsByReceivalLotIdLookup, true),
);

export const getHasResolvedCommentsByReceivalLotId = receivalLotId => state =>
  selectHasResolvedCommentsByReceivalLotIdLookup(state)[receivalLotId] || false;

const selectHasUnresolvedCommentsByRecievalLotIdLookup = createSelector(
  [selectCommentsByReceivalLotIdLookup],
  commentsByReceivalLotIdLookup =>
    commentsByObjIdLookup(commentsByReceivalLotIdLookup, false),
);

export const getHasUnresolvedCommentsByRecevialLotId = receivalLotId => state =>
  selectHasUnresolvedCommentsByRecievalLotIdLookup(state)[receivalLotId] ||
  false;

const selectHasResolvedCommentsByPenScanLotIdLookup = createSelector(
  [selectCommentsByPenScanLotIdLookup],
  commentsByPenScanLotIdLookup =>
    commentsByObjIdLookup(commentsByPenScanLotIdLookup, true),
);

export const getHasResolvedCommentsByPenScanLotId = penScanLotId => state =>
  selectHasResolvedCommentsByPenScanLotIdLookup(state)[penScanLotId] || false;

const selectHasUnresolvedCommentsByPenScanLotIdLookup = createSelector(
  [selectCommentsByPenScanLotIdLookup],
  commentsByPenScanLotIdLookup =>
    commentsByObjIdLookup(commentsByPenScanLotIdLookup, false),
);

export const getHasUnresolvedCommentsByPenScanLotId = penScanLotId => state =>
  selectHasUnresolvedCommentsByPenScanLotIdLookup(state)[penScanLotId] || false;

const selectHasResolvedCommentsByWeighLotIdLookup = createSelector(
  [selectCommentsByWeighLotIdLookup],
  commentsByWeighLotIdLookup =>
    commentsByObjIdLookup(commentsByWeighLotIdLookup, true),
);

export const getHasResolvedCommentsByWeighLotId = weighLotId => state =>
  selectHasResolvedCommentsByWeighLotIdLookup(state)[weighLotId] || false;

const selectHasUnresolvedCommentsByWeighLotIdLookup = createSelector(
  [selectCommentsByWeighLotIdLookup],
  commentsByWeighLotIdLookup =>
    commentsByObjIdLookup(commentsByWeighLotIdLookup, false),
);

export const getHasUnresolvedCommentsByWeighLotId = weighLotId => state =>
  selectHasUnresolvedCommentsByWeighLotIdLookup(state)[weighLotId] || false;

const selectCommentTitleBySaleLotId = createSelector([getSaleLots], saleLots =>
  Object.entries(saleLots).reduce((lookup, [saleLotId, saleLot]) => {
    lookup[saleLotId] = `Sale Lot ${getCombinedLotNumber(saleLot)}`;
    return lookup;
  }, {}),
);

export const getCommentTitleBySaleLotId = saleLotId => state =>
  selectCommentTitleBySaleLotId(state)[saleLotId] || "";

const selectCommentTitleByDeploymentSaleId = createSelector([getSales], sales =>
  Object.entries(sales).reduce((lookup, [ignored, sale]) => {
    const deploymentSales = sale.deployment_sales || [];
    deploymentSales.forEach(deploymentSale => {
      const saleTitle = sale.sale_title || sale.saleyard_name;
      lookup[deploymentSale.deployment_sale_id] = `Sale - ${saleTitle}`;
    });
    return lookup;
  }, {}),
);

export const getCommentTitleByDeploymentSaleId = saleId => state =>
  selectCommentTitleByDeploymentSaleId(state)[saleId] || "";

const selectCommentTitleByReceivalLotId = createSelector(
  [getReceivalLots, getAuctionPens],
  (receivalLots, pens) =>
    Object.entries(receivalLots).reduce(
      (lookup, [receivalLotId, receivalLot]) => {
        lookup[receivalLotId] = `Receival Lot ${
          pens[receivalLot.receivalPenId]?.start_pen || ""
        }`;
        return lookup;
      },
      {},
    ),
);

export const getCommentTitleByReceivalLotId = receivalLotId => state =>
  selectCommentTitleByReceivalLotId(state)[receivalLotId] || "";

const selectCommentTitleByPenScanLotId = createSelector(
  [getPenScanLots, getAuctionPens],
  (penScanLots, pens) =>
    Object.entries(penScanLots).reduce((lookup, [penScanLotId, penScanLot]) => {
      lookup[penScanLotId] = `Pen Scan Lot ${
        pens[penScanLot.sellingPenId]?.start_pen || ""
      }`;
      return lookup;
    }, {}),
);

export const getCommentTitleByPenScanLotId = penScanLotId => state =>
  selectCommentTitleByPenScanLotId(state)[penScanLotId] || "";

const selectCommentTitleByWeighLotId = createSelector(
  [getWeighLots],
  weighLots =>
    Object.entries(weighLots).reduce((lookup, [weighLotId, weighLot]) => {
      lookup[weighLotId] = `Weigh Lot ${weighLot.lotNumber || ""} from ${
        weighLot.scaleName
      }`;
      return lookup;
    }, {}),
);

export const getCommentTitleByWeighLotId = weighLotId => state =>
  selectCommentTitleByWeighLotId(state)[weighLotId] || "";

function unresolvedObjectCommentCountByObjectIdReducer(
  object,
  commentsByObjectIdLookup,
) {
  return commentsByObjectIdLookup[object.id]
    ?.filter(Boolean)
    .filter(isCommentUnresolved).length;
}

export const [
  selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
  getUnresolvedSaleLotCommentCountBySaleLotId,
] = createLookupSelectors(
  [getSaleLots, selectCommentsBySaleLotIdLookup],
  createLookupCombiner(unresolvedObjectCommentCountByObjectIdReducer),
);

function resolvedObjectCommentCountByObjectReducer(
  object,
  commentsByObjectIdLookup,
) {
  return commentsByObjectIdLookup[object.id]?.filter(isCommentResolved).length;
}

export const [
  selectResolvedSaleLotCommentCountBySaleLotIdLookup,
  getResolvedSaleLotCommentCountBySaleLotId,
] = createLookupSelectors(
  [getSaleLots, selectCommentsBySaleLotIdLookup],
  createLookupCombiner(resolvedObjectCommentCountByObjectReducer),
);

function unresolvedCommentCountByIdsReducer(ids, commentsByIdLookup) {
  return ids.reduce(
    (acc, id) =>
      acc + commentsByIdLookup[id].filter(isCommentUnresolved).length,
    0,
  );
}

export const [
  selectUnresolvedSaleLotCommentCountByBuyerIdLookup,
  getUnresolvedSaleLotCommentCountByBuyerId,
] = createLookupSelectors(
  [selectSaleLotIdsByBuyerIdLookup, selectCommentsBySaleLotIdLookup],
  createLookupCombiner(unresolvedCommentCountByIdsReducer),
);

export const [
  selectUnresolvedSaleLotCommentCountByConsignmentIdLookup,
  getUnresolvedSaleLotCommentCountByConsignmentId,
] = createLookupSelectors(
  [selectSaleLotIdsByConsignmentIdLookup, selectCommentsBySaleLotIdLookup],
  createLookupCombiner(unresolvedCommentCountByIdsReducer),
);

export const [
  selectUnresolvedSaleLotCommentCountByAuctionPenIdLookup,
  getUnresolvedSaleLotCommentCountByAuctionPenId,
] = createLookupSelectors(
  [selectSaleLotIdsByAuctionPenIdLookup, selectCommentsBySaleLotIdLookup],
  createLookupCombiner(unresolvedCommentCountByIdsReducer),
);

export const [
  selectUnresolvedSaleLotCommentCountByBuyerHashLookup,
  getUnresolvedSaleLotCommentCountByBuyerHash,
] = createLookupSelectors(
  [selectSaleLotIdsByBuyerHashLookup, selectCommentsBySaleLotIdLookup],
  createLookupCombiner(unresolvedCommentCountByIdsReducer),
);

function resolvedCommentCountByIdsReducer(ids, commentsByIdLookup) {
  return ids.reduce(
    (acc, id) => acc + commentsByIdLookup[id].filter(isCommentResolved).length,
    0,
  );
}

export const [
  selectResolvedSaleLotCommentCountByConsignmentIdLookup,
  getResolvedSaleLotCommentCountByConsignmentId,
] = createLookupSelectors(
  [selectSaleLotIdsByConsignmentIdLookup, selectCommentsBySaleLotIdLookup],
  createLookupCombiner(resolvedCommentCountByIdsReducer),
);

export const selectSaleLotIdsWithUnresolvedCommentsByDeploymentSaleIdLookup =
  createSelector(
    [
      selectHasUnresolvedCommentsBySaleLotIdLookup,
      selectDeploymentSaleIdBySaleLotIdLookup,
    ],
    (
      hasUnresolvedCommentsBySaleLotIdLookup,
      deploymentSaleIdBySaleLotIdLookup,
    ) =>
      Object.entries(hasUnresolvedCommentsBySaleLotIdLookup).reduce(
        (acc, [saleLotId, hasUnresolvedComments]) => {
          if (hasUnresolvedComments) {
            const deploymentSaleId =
              deploymentSaleIdBySaleLotIdLookup[saleLotId];
            if (!acc[deploymentSaleId]) {
              acc[deploymentSaleId] = [];
            }
            acc[deploymentSaleId].push(saleLotId);
          }
          return acc;
        },
        {},
      ),
  );

export const getSaleLotIdsWithUnresolvedCommentsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectSaleLotIdsWithUnresolvedCommentsByDeploymentSaleIdLookup(state)[
      deploymentSaleId
    ] || EMPTY_ARRAY;

export const [selectCommentsByNominationIdLookup, getCommentsByNominationId] =
  createLookupSelectors(
    [getNominations, getComments],
    createLookupCombiner((nomination, comments) =>
      nomination.commentIds.map(commentId => comments[commentId]),
    ),
    EMPTY_ARRAY,
  );

export const [
  selectResolvedCommentCountByNominationIdLookup,
  getResolvedCommentCountByNominationId,
] = createLookupSelectors(
  [selectCommentsByNominationIdLookup],
  createLookupCombiner(comments => comments.filter(isCommentResolved).length),
  false,
);

export const [
  selectHasResolvedCommentsByNominationIdLookup,
  getHasResolvedCommentsByNominationId,
] = createLookupSelectors(
  [selectCommentsByNominationIdLookup],
  createLookupCombiner(
    comments => comments.length > 0 && comments.some(isCommentResolved),
  ),
  false,
);

export const [
  selectUnresolvedCommentCountByNominationIdLookup,
  getUnresolvedCommentCountByNominationId,
] = createLookupSelectors(
  [selectCommentsByNominationIdLookup],
  createLookupCombiner(comments => comments.filter(isCommentUnresolved).length),
  false,
);

export const [
  selectHasUnresolvedCommentsByNominationIdLookup,
  getHasUnresolvedCommentsByNominationId,
] = createLookupSelectors(
  [selectCommentsByNominationIdLookup],
  createLookupCombiner(
    comments => comments.length > 0 && comments.some(isCommentUnresolved),
  ),
);

export const [selectCommentTitleByNominationId, getCommentTitleByNominationId] =
  createLookupSelectors(
    [getNominations, getBusinesses],
    createLookupCombiner(
      (nomination, businessById) =>
        `Nomination ${businessById[nomination.vendorId]?.name}`,
    ),
  );

export const [
  selectResolvedCommentCountByReceivalLotIdLookup,
  getResolvedCommentCountByReceivalLotId,
] = createLookupSelectors(
  [selectCommentsByReceivalLotIdLookup],
  createLookupCombiner(comments => comments.filter(isCommentResolved).length),
  false,
);

export const [
  selectUnresolvedCommentCountByReceivalLotIdLookup,
  getUnresolvedCommentCountByReceivalLotId,
] = createLookupSelectors(
  [selectCommentsByReceivalLotIdLookup],
  createLookupCombiner(comments => comments.filter(isCommentUnresolved).length),
  false,
);

export const [
  selectResolvedCommentCountByPenScanLotLotIdLookup,
  getResolvedCommentCountByPenScanLotLotId,
] = createLookupSelectors(
  [selectCommentsByPenScanLotIdLookup],
  createLookupCombiner(comments => comments.filter(isCommentResolved).length),
  false,
);

export const [
  selectUnresolvedCommentCountByPenScanLotLotIdLookup,
  getUnresolvedCommentCountByPenScanLotId,
] = createLookupSelectors(
  [selectCommentsByPenScanLotIdLookup],
  createLookupCombiner(comments => comments.filter(isCommentUnresolved).length),
  false,
);

export const [
  selectResolvedCommentCountByWeighLotIdLookup,
  getResolvedCommentCountByWeighLotIdLookup,
] = createLookupSelectors(
  [selectCommentsByWeighLotIdLookup],
  createLookupCombiner(comments => comments.filter(isCommentResolved).length),
);

export const [
  selectunresolvedCommentCountByWeighLotIdLookup,
  getUnresolvedCommentCountByWeighLotIdLookup,
] = createLookupSelectors(
  [selectCommentsByWeighLotIdLookup],
  createLookupCombiner(comments => comments.filter(isCommentUnresolved).length),
);
