import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addTempBuyerWay } from "actions";

import { getBusinesses } from "selectors";

export const useGetBuyerWayFromBidder = () => {
  const dispatch = useDispatch();
  const businessByIdLookup = useSelector(getBusinesses);
  return bidder => {
    const buyerId = bidder.businessId;
    const buyer = businessByIdLookup[buyerId];

    const buyerWayName = bidder.buyerWay;
    let buyerWayId = null;

    if (buyerWayName) {
      buyerWayId = uuidv4();
      const existingBuyerWays = buyer.buyerWays || [];
      const existingBuyerWay = existingBuyerWays.find(
        ({ name }) => name === buyerWayName,
      );

      if (!existingBuyerWay && buyerWayName) {
        dispatch(addTempBuyerWay(buyerWayId, buyerId, buyerWayName));
      } else {
        buyerWayId = existingBuyerWay.id;
      }
    }
    return {
      id: buyerWayId,
      name: buyerWayName,
    };
  };
};
