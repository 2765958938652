import React from "react";

import { InlineCreateSaleLotModal } from "components/InlineCreateSaleLotModal/index";

import { ModalTypes } from "constants/navigation";

function InlineCreateSaleLotModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.InlineCreateSaleLot] || null;
  const { returnTo, initialValues, options } = JSON.parse(hashParams);

  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <InlineCreateSaleLotModal
      returnTo={decodedReturnTo}
      initialValues={initialValues}
      options={options}
    />
  );
}

export default InlineCreateSaleLotModalAdapter;
