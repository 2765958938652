import { v4 as uuidv4 } from "uuid";

import {
  ADD_CONSIGNMENT_COMMIT,
  ADD_CONSIGNMENT_FROM_SOCKET,
  ADD_CONSIGNMENT_OFFLINE,
  ADD_CONSIGNMENT_ROLLBACK,
  DELETE_CONSIGNMENT_COMMIT,
  DELETE_CONSIGNMENT_OFFLINE,
  DELETE_CONSIGNMENT_ROLLBACK,
  PATCH_CONSIGNMENT_COMMIT,
  PATCH_CONSIGNMENT_OFFLINE,
  PATCH_CONSIGNMENT_ROLLBACK,
} from "constants/actionTypes";

import { getSaleUrl } from "lib/navigation";
import { serializeConsignment } from "lib/serializers/consignments";

/**
 * @param {{ shouldUpdateVendorDefaultConsigningDeployment: Boolean }} options
 */
export const addConsignment = (
  tempId,
  consignment,
  sale,
  attachmentParams = null,
  initial_branch_id = null,
  saleLot = null,
  agencyId = null,
  options = {},
) => {
  const saleUrl = getSaleUrl(sale);
  const meta = {
    agencyId,
    tempId,
    tempCarrierChargeId: uuidv4(),
    livestockSaleId: sale.livestocksale_id,
    sale,
    attachmentParams,
    vendor_id: consignment.vendor_id,
    initial_branch_id,
    saleLot,
  };
  const url = `${saleUrl}/consignments/${
    agencyId ? `?agency=${agencyId}` : ""
  }`;
  const payload = serializeConsignment({ ...consignment, id: tempId });

  return {
    type: ADD_CONSIGNMENT_OFFLINE,
    options,
    payload: {
      ...payload,
      livestocksale_id: sale.livestocksale_id,
      attachmentParams,
    },

    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: ADD_CONSIGNMENT_COMMIT,
          meta,
        },
        rollback: {
          type: ADD_CONSIGNMENT_ROLLBACK,
          meta,
        },
      },
    },
  };
};

export const patchConsignment = (
  consignment,
  cbid,
  options = { changeReason: null },
) => {
  /* eslint-disable */
  const {
    id,
    scanned,
    vendor,
    deployment_sale,
    additional_properties,
    answers,
    attachments,
    declaration,
    ...payloadValues
  } = consignment;

  const { changeReason = null } = options;

  const payload = serializeConsignment(payloadValues);
  /* eslint-enable */
  const { vendorNumber } = consignment;
  // If vendor_property_id is negative set to null
  if (payload.vendor_property_id && payload.vendor_property_id < 0) {
    payload.vendor_property_id = null;
  }
  const meta = {
    id: cbid,
    initial_vendor_number: vendorNumber,
    initial_branch_id: vendor ? vendor.branchId : null,
    vendor_id: payload.vendor_id,
  };
  return {
    type: PATCH_CONSIGNMENT_OFFLINE,
    payload,
    options,
    meta: {
      id: cbid,
      offline: {
        effect: {
          url: `/v2/consignment/${cbid}/`,
          method: "PATCH",
          body: JSON.stringify(payload),
          changeReason,
        },
        commit: {
          type: PATCH_CONSIGNMENT_COMMIT,
          meta,
        },
        rollback: {
          type: PATCH_CONSIGNMENT_ROLLBACK,
          meta,
        },
      },
    },
  };
};

export const addConsignmentFromSocket = message => {
  const consignment = message.object;
  return {
    type: ADD_CONSIGNMENT_FROM_SOCKET,
    payload: consignment,
    meta: {
      tempId: consignment.id,
      livestockSaleId: consignment.livestocksale_id, // TODO this is important and will be addded
    },
  };
};

export const deleteConsignment = consignment => {
  const meta = { id: consignment.id, consignment };
  return {
    type: DELETE_CONSIGNMENT_OFFLINE,
    meta: {
      offline: {
        effect: {
          url: `/v2/consignment/${consignment.id}/`,
          method: "DELETE",
        },
        commit: {
          type: DELETE_CONSIGNMENT_COMMIT,
          meta,
        },
        rollback: {
          type: DELETE_CONSIGNMENT_ROLLBACK,
          meta,
        },
      },
    },
  };
};
