import React, { useState } from "react";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { deleteOfflineAction } from "actions/offline";

import { PhoneSupportButton } from "components/Button/PhoneSupportButton";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Column, Row } from "components/Layout";
import {
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { ModalTypes } from "constants/navigation";
import { colors } from "constants/theme";

import { useModalAdapter } from "hooks";

export function PendingChangesModal(props) {
  const [ignored1, ignored2, onClose] = useModalAdapter(
    ModalTypes.PendingChanges,
    props,
  );
  return <Modal onClose={onClose} />;
}

function Modal({ onClose }) {
  const dispatch = useDispatch();

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(null);

  const { outbox, retryCount } = useSelector(state => state.offline);

  const onClickShowConfirmDelete = event => {
    event.stopPropagation();
    setIsConfirmDeleteOpen(true);
  };

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>
        Pending Changes Queue ({outbox.length})
      </DialogTitle>
      <DialogContent dividers>
        <Column>
          <Row>
            <Column justifyCenter alignCenter padding={2}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                color={colors.warning}
              />
            </Column>
            <Column>
              This screen allows you to remove actions performed if they are
              impeding progress. It should only be used in extreme circumstances
              and will result in a loss of data.
              <div>
                AgriNous Support
                <PhoneSupportButton />
              </div>
            </Column>
          </Row>
          <Divider />
          {outbox.map((change, index) => {
            const topOfQueue = index === 0;
            const onClickDelete = () => {
              dispatch(deleteOfflineAction(change.meta.transaction));
              setIsConfirmDeleteOpen(false);
            };
            const key = change.meta.transaction;

            if (topOfQueue) {
              return (
                <React.Fragment key={key}>
                  <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                      style={{ background: `${colors.warning}50` }}
                    >
                      <Row justifyBetween fullWidth alignCenter>
                        <strong>{change.type}</strong> {retryCount} Attempts{" "}
                        <IconButton>
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            onClick={onClickShowConfirmDelete}
                          />
                        </IconButton>
                      </Row>
                    </AccordionSummary>
                    <AccordionDetails>
                      <pre>{JSON.stringify(change, null, 2)}</pre>
                    </AccordionDetails>
                  </Accordion>
                  <ConfirmDialog
                    title="Confirm Delete Pending Change"
                    isOpen={isConfirmDeleteOpen}
                    onCancel={setIsConfirmDeleteOpen}
                    onDelete={onClickDelete}
                    message="This action is irreversible."
                  />
                </React.Fragment>
              );
            } else {
              return (
                <Accordion TransitionProps={{ unmountOnExit: true }} key={key}>
                  <AccordionSummary>{change.type}</AccordionSummary>
                  <AccordionDetails>
                    <pre>{JSON.stringify(change, null, 2)}</pre>
                  </AccordionDetails>
                </Accordion>
              );
            }
          })}
        </Column>
      </DialogContent>
    </ZoomyDialog>
  );
}
