import React, { memo, useCallback, useContext, useMemo, useState } from "react";

import { Popover } from "@material-ui/core";
import { sortBy } from "lodash";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { OutlineButton } from "components/Button";
import { SearchInput } from "components/Form";
import { FullScreenContainer } from "components/FullScreenContainer";
import IdConnectedList from "components/IdConnectedList";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { MinimalSaleLotCard } from "components/LotCard/MinimalSaleLotCard";
import { SaleLotEmptyPlaceholder } from "components/LotCard/SaleLotEmptyPlaceholder";
import GlobalSearch from "components/SearchInput/GlobalSearch";

import { ApiModel } from "constants/loading";

import { EMPTY_ARRAY } from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { getConsignmentCode } from "lib/consignments";

import {
  auctionPensSellingOrderSelector,
  getAuctionPens,
  getBusinesses,
  getConsignments,
  getIsFetchingSaleLots,
  getSaleLots,
  selectCurrentSaleRounds,
  selectFilteredSaleLotIds,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";

import {
  ExistingLotsLayout,
  SearchLayout,
  SelectionLayout,
  SelectionLayoutItem,
  WeighingLayout,
} from "./Layout";
import RecentlyWeighedTable from "./RecentlyWeighedTable";
import WeighBridgeCurrentLot from "./WeighBridgeCurrentLot";
import WeighBridgeFilters from "./WeighBridgeFilters";
import {
  WeighBridgeSaleDispatchContext,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";

const FilterSpacer = styled(Row)(
  ({ theme }) => `
  padding: 10px;
  border-right: 2px solid ${theme.colors.separatorBlue};
  flex-direction: column;
`,
);

const FilterRecentRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
`;

const SearchSpacer = styled.div`
  padding: 2px;
`;

function GroupedIdConnectedList({ selectedIds, orderedIds = [], options }) {
  return (
    <IdConnectedList
      component={MinimalSaleLotCard}
      emptyComponent={SaleLotEmptyPlaceholder}
      orderedIds={orderedIds}
      selectedIds={selectedIds}
      options={options}
    />
  );
}

function weighBridgeSaleLotKeywordFilter(
  keyword,
  consignment = {},
  vendor = {},
  auctionPen = {},
) {
  const normalisedKeywords = keyword ? keyword.toLowerCase().split(" ") : [];
  if (normalisedKeywords.length === 0) {
    return true;
  }
  const consignmentCode = getConsignmentCode(consignment).toLowerCase();
  const auctionPenName = getAuctionPenDisplayName(auctionPen).toLowerCase();
  const vendorName = vendor.name || "";
  return normalisedKeywords.every(
    normalisedKeyword =>
      vendorName.toLowerCase().indexOf(normalisedKeyword) > -1 ||
      consignmentCode.indexOf(normalisedKeyword) > -1 ||
      auctionPenName.indexOf(normalisedKeyword) > -1,
  );
}

function WeighbridgeSaleViewComponent(props) {
  const { filters, saleLotId } = props;
  const { updateSelectedSaleLotId, reset } = useContext(
    WeighBridgeSaleDispatchContext,
  );
  const saleLots = useSelector(getSaleLots);
  const filterdSaleLotIds = useSelector(selectFilteredSaleLotIds);
  const consignments = useSelector(getConsignments);
  const businesses = useSelector(getBusinesses);
  const auctionPens = useSelector(getAuctionPens);
  const isFetchingSaleLots = useSelector(getIsFetchingSaleLots);
  const auctionPenSellingOrder = useSelector(auctionPensSellingOrderSelector);
  const saleLotIdsByAuctionPenId = useSelector(
    selectSaleLotIdsByAuctionPenIdLookup,
  );
  const [recentButtonEl, setRecentButtonEl] = useState(null);

  const weighingOrderByRound = useMemo(() => {
    if (isFetchingSaleLots) {
      return EMPTY_ARRAY;
    }
    const unpennedSaleLotIds = saleLotIdsByAuctionPenId.null || [];
    let sellingOrder = [...unpennedSaleLotIds];

    auctionPenSellingOrder.forEach(auctionPenId => {
      // Append all other Sale Lots in their selling order
      const saleLotIds = saleLotIdsByAuctionPenId[auctionPenId];
      if (Array.isArray(saleLotIds) && saleLotIds.length > 0) {
        sellingOrder = sellingOrder.concat(saleLotIds);
      }
    });
    return sellingOrder
      .map(saleLotId => saleLots[saleLotId])
      .filter(saleLot => {
        // filter out anything that doesn't match the penned
        if (filters.penned !== Boolean(saleLot.auction_pen_id)) {
          return false;
        }
        // Filter out anything that isn't in the filtered global pens and
        // Filter out anything that doesn't match the keyword search criteria
        const consignment = consignments[saleLot.consignment_id] || {};
        return (
          filterdSaleLotIds.includes(saleLot.id) &&
          weighBridgeSaleLotKeywordFilter(
            filters.keyword,
            consignment,
            businesses[consignment.vendor_id],
            auctionPens[saleLot.auction_pen_id],
          )
        );
      })
      .reduce((acc, saleLot) => {
        if (acc[saleLot.sale_round_id]) {
          acc[saleLot.sale_round_id].push(saleLot.id);
        } else {
          acc[saleLot.sale_round_id] = [saleLot.id];
        }
        return acc;
      }, {});
  }, [
    filterdSaleLotIds,
    consignments,
    businesses,
    auctionPens,
    auctionPenSellingOrder,
    isFetchingSaleLots,
    filters.keyword,
    filters.penned,
    saleLotIdsByAuctionPenId,
    saleLots,
  ]);

  const onNextSaleLot = useCallback(() => {
    Object.values(weighingOrderByRound).forEach(weighingOrder => {
      const i = weighingOrder.indexOf(saleLotId);
      if (i > -1) {
        reset();
        updateSelectedSaleLotId(weighingOrder[i + 1]);
      }
    });
  }, [updateSelectedSaleLotId, reset, saleLotId, weighingOrderByRound]);

  const [cardEl, setCardEl] = useState(null);

  const listItemOptions = useMemo(
    () => ({
      onSelectSaleLot: updateSelectedSaleLotId,
      cardEl,
      setCardEl,
    }),
    [cardEl, updateSelectedSaleLotId],
  );

  const isRecentVisible = Boolean(recentButtonEl);

  function toggleRecentSaleLotsVisible(event) {
    setRecentButtonEl(isRecentVisible ? null : event.target);
  }

  function closeRecentSaleLots() {
    setRecentButtonEl(null);
  }

  function onClickCreateNewSaleLot() {
    updateSelectedSaleLotId(null);
  }

  const { updateFilters } = useContext(WeighBridgeSaleDispatchContext);

  function onChangeKeywordFilter(value) {
    updateFilters({ ...filters, keyword: value });
  }

  const orderedSaleRounds = useSelector(selectCurrentSaleRounds);

  return (
    <FullScreenContainer tabsVisible>
      <WaitForSync
        requiredData={[
          ApiModel.AGENCIES,
          ApiModel.DEPLOYMENTS,
          ApiModel.SPECIES,
        ]}
      >
        <WeighingLayout>
          <SelectionLayout>
            <SelectionLayoutItem gridArea="recent-lots">
              <Popover
                open={isRecentVisible}
                anchorEl={recentButtonEl}
                onClose={closeRecentSaleLots}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                <RecentlyWeighedTable />
              </Popover>
              <FilterRecentRow>
                <OutlineButton
                  title="Show Recent Sale Lots"
                  onClick={toggleRecentSaleLotsVisible}
                >
                  Show Recent
                </OutlineButton>
                <OutlineButton
                  title="Create a new Sale Lot"
                  onClick={onClickCreateNewSaleLot}
                >
                  Create new Sale Lot
                </OutlineButton>
                <WeighBridgeFilters />
              </FilterRecentRow>
            </SelectionLayoutItem>

            <SearchLayout>
              <FilterSpacer>
                <SearchInput
                  value={filters.keyword}
                  onChange={onChangeKeywordFilter}
                />
                <SearchSpacer />
                <GlobalSearch overridePlaceHolder="Filter" />
              </FilterSpacer>
            </SearchLayout>
            <ExistingLotsLayout>
              <Scrollbars thumbMinSize={60}>
                {sortBy(Object.values(orderedSaleRounds), "order").map(
                  saleRound => (
                    <GroupedIdConnectedList
                      key={saleRound.id}
                      selectedIds={[saleLotId]}
                      orderedIds={weighingOrderByRound[saleRound.id]}
                      title={saleRound.name}
                      options={listItemOptions}
                    />
                  ),
                )}
              </Scrollbars>
            </ExistingLotsLayout>
          </SelectionLayout>

          <WeighBridgeCurrentLot onNextSaleLot={onNextSaleLot} />
        </WeighingLayout>
      </WaitForSync>
    </FullScreenContainer>
  );
}

const WeighbridgeSaleView = memo(
  WeighbridgeSaleViewComponent,
  (a, b) =>
    a.filters.keyword === b.filters.keyword &&
    a.filters.penned === b.filters.penned &&
    a.saleLotId === b.saleLotId,
);
WeighbridgeSaleView.propTypes = {
  filters: PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    penned: PropTypes.bool.isRequired,
  }),
  saleLotId: PropTypes.string,
};

function StateAdapter() {
  const { filters, selectedSaleLotId } = useContext(
    WeighBridgeSaleStateContext,
  );
  return (
    <WeighbridgeSaleView filters={filters} saleLotId={selectedSaleLotId} />
  );
}

export default memo(StateAdapter);
