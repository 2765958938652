import React from "react";

import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

const FilterLabel = styled.label`
  display: block;
  & > span {
    cursor: pointer;
  }
`;

const FilterRange = styled.input.attrs({
  type: "range",
  min: -1,
  max: 1,
  step: 1,
})`
  -webkit-appearance: none;
  background: transparent;
  margin: ${({ theme }) => theme.space[2]}px;
  width: 38%;
  display: inline;
  vertical-align: middle;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.radii[4] * 2}px;
    background: ${({ theme }) => theme.colors.ready};
  }

  &::-moz-range-track {
    width: 100%;
    cursor: pointer;
    height: ${({ theme }) => theme.radii[4] * 4}px;
    border-radius: ${({ theme }) => theme.radii[4] * 2}px;
    background: ${({ theme }) => theme.colors.ready};
  }

  &::-webkit-slider-thumb {
    height: ${({ theme }) => theme.radii[4] * 4}px;
    width: ${({ theme }) => theme.radii[4] * 4}px;
    border-radius: ${({ theme }) => theme.radii[4] * 2}px;
    background: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    transition: all 2s;
    -webkit-appearance: none;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.gray78};
  }

  &::-moz-range-thumb {
    height: ${({ theme }) => theme.radii[4] * 4}px;
    width: ${({ theme }) => theme.radii[4] * 4}px;
    border-radius: ${({ theme }) => theme.radii[4] * 2}px;
    background: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    transition: all 2s;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.gray78};
  }

  &.filter-shown::-moz-range-track {
    background: ${({ theme }) => theme.colors.successGreen};
  }

  &.filter-shown::-webkit-slider-runnable-track {
    background: ${({ theme }) => theme.colors.successGreen};
  }

  &.filter-hidden::-moz-range-track {
    background: ${({ theme }) => theme.colors.inputError};
  }

  &.filter-hidden::-webkit-slider-runnable-track {
    background: ${({ theme }) => theme.colors.inputError};
  }
`;

export class TriStateToggleFilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
    this.fieldName = `triStateToggle_${uuidv4()}}`;
  }

  static getClassForValue(value) {
    if (value > 0) {
      return "filter-shown";
    } else if (value < 0) {
      return "filter-hidden";
    }
    return "";
  }

  componentDidUpdate(ignored, prevState) {
    const { value } = this.state;
    const { onValueChanged } = this.props;
    if (value !== prevState.value) {
      onValueChanged();
    }
  }

  setValueInternal(newValue) {
    const { value } = this.state;
    if (value !== newValue) {
      this.setState({ value: newValue });
    }
  }

  onRangeChange = event => {
    event.stopPropagation();
    this.setValueInternal(+event.target.value);
  };

  onRangeClick = event => {
    event.stopPropagation();
    const inputEl = event.target.control;
    if (!inputEl) {
      return;
    }
    const newValue = +inputEl.value + 1 > 1 ? -1 : +inputEl.value + 1;
    this.setValueInternal({ value: newValue });
  };

  onHiddenClick = event => {
    event.stopPropagation();
    this.setValueInternal(-1);
  };

  onShownClick = event => {
    event.stopPropagation();
    this.setValueInternal(1);
  };

  getValue() {
    const { value } = this.state;
    return value;
  }

  setValue(value) {
    this.setState({ value });
  }

  isActive() {
    const { value } = this.state;
    return value !== 0;
  }

  filter(fieldValue, row) {
    const { filter } = this.props;
    if (typeof filter === "function") {
      const { value } = this.state;
      return filter(fieldValue, value, row);
    } else {
      throw new Error(
        "filter is not a function, be sure to provide it in filter the column definition filterParams",
      );
    }
  }

  render() {
    const { title } = this.props;
    const { value } = this.state;

    const className = TriStateToggleFilterComponent.getClassForValue(value);

    return (
      <FilterLabel
        onChange={this.onRangeChange}
        onClick={this.onRangeClick}
        htmlFor={this.fieldName}
      >
        {title}
        <br />
        <span onClick={this.onHiddenClick}>Hidden&nbsp;</span>
        <FilterRange
          onChange={this.onRangeChange}
          className={className}
          onClick={this.onRangeClick}
          value={value}
          name={this.fieldName}
        />
        <span onClick={this.onShownClick}>&nbsp;Shown</span>
      </FilterLabel>
    );
  }
}
