import React from "react";

import { useSelector } from "react-redux";

import { Warning } from "components/ErrorMessage";
import { Input, withNamespace } from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { getCombinedLotNumber } from "lib/saleLot";

import { getSaleLotIdByCombinedLotNumber } from "selectors";

import { useFieldValue } from "hooks";

export const LotNumber = ({ readOnly = false, ns = "", fullWidth = false }) => {
  const lotNumber = useFieldValue(withNamespace(ns, "lot_number"));
  const lotNumberSuffix = useFieldValue(withNamespace(ns, "lotNumberSuffix"));
  const saleLotId = useFieldValue(withNamespace(ns, "id"));

  const combinedLotNumber = getCombinedLotNumber({
    lotNumber,
    lotNumberSuffix,
  });

  const saleLotIdWithCombinedLotNumber = useSelector(
    getSaleLotIdByCombinedLotNumber(combinedLotNumber),
  );

  return (
    <>
      <Row alignStretch fullWidth={fullWidth}>
        <Input
          label="Lot Number"
          type="number"
          name={withNamespace(ns, "lot_number")}
          placeholder="Automatically Assigned"
          disabled={readOnly}
        />
        <Input
          label=""
          name={withNamespace(ns, "lotNumberSuffix")}
          disabled={readOnly}
          columnProps={{ maxWidth: "20%", justifyEnd: true }}
        />
      </Row>

      {lotNumber &&
        saleLotIdWithCombinedLotNumber &&
        saleLotIdWithCombinedLotNumber !== saleLotId && (
          <Warning>
            Lot number must be unique - this lot will be automatically
            re-assigned in the case of a conflict.
          </Warning>
        )}
    </>
  );
};
