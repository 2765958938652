import React, { useRef, useEffect } from "react";

import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { HelpText } from "components/Form/FormikControls/HelpText";
import { Column } from "components/Layout";

import { Label } from "./FormikControls/Label";
import { StyledInput, Required } from "./FormikControls/layout";

const CurrencySign = styled.span`
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  ${({ after, theme }) =>
    after ? `right: ${theme.space[2]}px;` : `left: ${theme.space[2]}px`}
`;

const InputWrapper = styled.div`
  position: relative;
`;

const FaIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  ${({ theme, color }) => {
    if (color) {
      return `color: ${theme.colors[color]}`;
    }
  }};
`;

const CloseIcon = styled(FaIcon)`
  font-size: 14px;
  margin-right: 8px;
`;

export const SearchInput = ({
  name,
  label,
  disabled,
  value,
  onChange,
  selectOnFocus = true,
  type = "text",
  required,
  autoFocus = false,
  maxLength = undefined,
  placeholder = "Search",
  beforeSymbol = undefined,
  afterSymbol = "A",
  hideSpinner = true,
  helpText = undefined,
  height = undefined,
}) => {
  const inputRef = useRef(null);
  const onFocus = e => selectOnFocus && e.target.select();
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);
  const hasLabel = !!label;

  const handleChange = e => onChange(e.target.value);
  const clearInput = () => value && onChange("");
  return (
    <Column fullWidth>
      <Label htmlFor={name} required={hasLabel && required}>
        {label}
      </Label>
      <InputWrapper>
        {beforeSymbol && <CurrencySign>{beforeSymbol}</CurrencySign>}
        <StyledInput
          data-tour={name || "search"}
          value={value}
          labelledBy={name}
          onChange={handleChange}
          disabled={disabled}
          onFocus={onFocus}
          type={type}
          required={required}
          ref={inputRef}
          maxLength={maxLength}
          placeholder={placeholder}
          beforeSymbol={beforeSymbol}
          afterSymbol={afterSymbol}
          hideSpinner={hideSpinner}
          height={height}
        />
        {required && !hasLabel && <Required />}
        <CurrencySign after onClick={clearInput}>
          {value && <CloseIcon icon={faTimes} />}
          <FaIcon icon={faSearch} style={{ fontSize: 15 }} />
        </CurrencySign>
      </InputWrapper>
      {helpText && <HelpText>{helpText}</HelpText>}
    </Column>
  );
};
