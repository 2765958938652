import {
  sortByLastSeenTime,
  sortByWeighTime,
  sortPenByOrder,
} from "lib/auctionPens";

export const AuctionScreenMode = {
  Selling: "Selling",
  Weigh: "Weigh",
  LastSeen: "Scan",
};

export const AuctionScreenModeOptions = [
  {
    value: AuctionScreenMode.Selling,
    label: "Selling Order Mode",
    description:
      "Pens will be sold in the default pen order.  Pens will be automatically selected as a whole.",
  },
  {
    value: AuctionScreenMode.Weigh,
    label: "Weigh Order Mode",
    description:
      "Pens will be presented in the order of weigh time. First weighed, single Lots will be automatically selected within each Pen.",
  },
  // Removed for but may be expanded on and added back in later
  // {
  //   value: AuctionScreenModes.LastSeen,
  //   label: "Last Seen Order Mode",
  //   help:
  //     "Pens will be presented in order they were last seen on a bulk weigh.  First scanned, single Lots will be automatically selected within each Pen",
  // },
];
export const sortFunctionLookup = {
  [AuctionScreenMode.Selling]: sortPenByOrder,
  [AuctionScreenMode.Weigh]: sortByWeighTime,
  [AuctionScreenMode.LastSeen]: sortByLastSeenTime,
};
