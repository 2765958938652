import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import { SlimSecondaryButton } from "components/Button";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Row } from "components/Layout";

import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";

import {
  getActiveLivestockAgentDeployment,
  getDescriptionBySplitId,
  getVendorIdByConsignmentId,
} from "selectors";

import { useGetBestMatchingVendorSplit } from "hooks/useGetBestMatchingVendorSplit";

function VendorSplitHeader({ splitDescription }) {
  return (
    <Row>
      <SubtleBadge>Vendor Splits</SubtleBadge>
      <CollapseLabel>{splitDescription}</CollapseLabel>
    </Row>
  );
}

export const VendorSplitSection = ({ isOpen, onToggle, consignmentId }) => {
  const primaryVendorId = useSelector(
    getVendorIdByConsignmentId(consignmentId),
  );

  const primaryBusinessId = useSelector(
    getVendorIdByConsignmentId(consignmentId),
  );
  const deploymentId = useSelector(getActiveLivestockAgentDeployment)?.id;

  const split = useGetBestMatchingVendorSplit({
    primaryBusinessId,
    deploymentId,
    consignmentId,
  });
  const splitDescription = useSelector(getDescriptionBySplitId(split?.id));

  const onClickCreateVendorSplit = () =>
    openModalLink(ModalTypes.VendorSplit, {
      consignmentId,
      primaryBusinessId: primaryVendorId,
    });

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<VendorSplitHeader splitDescription={splitDescription} />}
      dataTour={isOpen ? "hideVendorSplits" : "showVendorSplits"}
    >
      <Grid item xs={12} container justifyContent="center">
        <SlimSecondaryButton
          type="button"
          onClick={onClickCreateVendorSplit}
          data-tour="createVendorSplit"
        >
          {split ? "Edit" : "Create"} Vendor Split
        </SlimSecondaryButton>
      </Grid>
    </FormCollapse>
  );
};
