import React from "react";

import { ModalTypes } from "constants/navigation";

import Dialog from "./dialog";

function Adapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.MapBusiness] || "";
  const searchCriteria = JSON.parse(hashParams);
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";

  return <Dialog returnTo={decodedReturnTo} {...searchCriteria} />;
}

export default Adapter;
