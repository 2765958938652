import React, { useRef, useState } from "react";

import { Grid } from "@material-ui/core";
import { Formik, useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SubtleBadge } from "components/Badge";
import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { MetadataField, SecondaryCardButton } from "components/Card/Elements";
import {
  Button,
  CollapseLabel,
  DeleteButton,
  FormCollapse,
  SecondaryButton,
} from "components/Form";
import { AddressForm } from "components/Form/Fields";
import {
  CheckBox,
  Input,
  Switch,
  TextArea,
} from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";

import { SaleTypes } from "constants/sale";

import { formatAddress } from "lib/address";
import { sectionHasErrors } from "lib/form";

import { getBusinessById, getCurrentSale } from "selectors";

import { useBoolean } from "hooks/useBoolean";

const FormColumn = styled(Column)(
  ({ theme }) => `
  flex: 1;
  padding: ${theme.space[1]}px;
  &:first-of-type {
    padding-left: 0px;
  }
  &:last-of-type {
    padding-right: 0px;
  }
`,
);

const SubmitRow = styled(Row)(
  ({ theme }) => `
  padding: ${theme.space[2]}px 0 0;
  `,
);
export const BidderRegistrationModalSection = {
  PROVISIONAL: "PROVISIONAL",
  GENERAL: "GENERAL",
  CONTACT: "CONTACT",
  DELIVERY: "DELIVERY",
  POSTAL: "POSTAL",
};

function extractAddressFactory(defaultKey, subKeyPrefix) {
  return provisionalData => {
    // Old, single text field.
    if (provisionalData[defaultKey]) {
      return provisionalData[defaultKey];
    }
    // Otherwise, just join each of the possible delivery_ prefaced fields (note this could
    // go through a formatAddress, but it's not serialized, and, noisy.
    return (
      [
        `${subKeyPrefix}__addressing_information`,
        `${subKeyPrefix}__street_number`,
        `${subKeyPrefix}__route`,
        `${subKeyPrefix}__locality`,
        `${subKeyPrefix}__state`,
      ]
        .map(key => provisionalData[key])
        .filter(Boolean)
        .join(" ") || "-"
    );
  };
}

const extractDeliveryDetails = extractAddressFactory(
  "delivery_details",
  "delivery",
);
const extractPostalDetails = extractAddressFactory("postal_address", "postal");

function ProvisionalCollapse(props) {
  const { isOpen, onToggle, provisionalData } = props;

  const invoiceTo = provisionalData.invoice_to || provisionalData.stock_agency;

  const header = (
    <Row>
      <SubtleBadge>Registration Information</SubtleBadge>
      <CollapseLabel>
        {provisionalData.name} {invoiceTo}
      </CollapseLabel>
    </Row>
  );

  // Pull out a formatted address if available, fallback to the old field, or blank.

  // This ordering and titles mirror the sign up form, but acknowledges old data, too.
  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      dataTour={
        isOpen ? "hideRegistrationInformation" : "showRegistrationInformation"
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MetadataField
            title="Contact First Name"
            value={provisionalData.first_name || provisionalData.contact_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetadataField
            title="Contact Last Name"
            value={provisionalData.last_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetadataField
            title="Contact Email"
            value={provisionalData.email || provisionalData.buyer_email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetadataField
            title="Contact Phone"
            value={provisionalData.phone_number || provisionalData.buyer_phone}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetadataField
            title="Buyers Account / Trading Name"
            value={provisionalData.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetadataField title="Stock Agency / Invoice To" value={invoiceTo} />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetadataField
            title="Destination PIC"
            value={provisionalData.destination_pic}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetadataField
            title="Delivery Details"
            value={extractDeliveryDetails(provisionalData)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetadataField
            title="Postal Address"
            value={extractPostalDetails(provisionalData)}
          />
        </Grid>
        <Grid item xs={12}>
          <MetadataField title="Notes" value={provisionalData.notes} />
        </Grid>
      </Grid>
    </FormCollapse>
  );
}

function GeneralCollapse(props) {
  const {
    isOpen,
    onToggle,
    searchPrefill,
    searchPrefillDefault,
    lastOpenInstance,
    onClickFindBusiness,
    focusControl,
  } = props;
  const buyerWayRef = useRef(null);
  const businessAddressSwitchRef = useRef(null);

  const formikProps = useFormikContext();
  const { touched, errors, values } = formikProps;
  const { businessId } = values;

  const business = useSelector(getBusinessById(businessId));
  const saleType = useSelector(getCurrentSale)?.sale_type;

  const hasErrorBadge = sectionHasErrors(formikProps, [
    "businessId",
    "buyerWay",
    "useBusinessAddress",
    "notes",
  ]);

  const header = (
    <Row>
      <SubtleBadge hasErrorBadge={hasErrorBadge}>General</SubtleBadge>
      <CollapseLabel>
        {business ? business.name : "No Business Mapped"}{" "}
      </CollapseLabel>
    </Row>
  );

  const focusNextInput = () => {
    const nextInputRef =
      saleType !== SaleTypes.CLEARING ? buyerWayRef : businessAddressSwitchRef;
    nextInputRef.current?.focus();
  };

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      dataTour={isOpen ? "hideGeneral" : "showGeneral"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            type="number"
            label="Registration Number"
            name="registrationNumber"
          />
        </Grid>
        <Grid item xs={12}>
          <StateAwareBusinessPICSelector
            label="Invoice to"
            businessFieldName="businessId"
            propertyFieldName="defaultPropertyId"
            formikProps={formikProps}
            required
            error={touched.businessId && errors.defaultPropertyId}
            searchPrefill={searchPrefill}
            openInstance={lastOpenInstance}
            style={{ margin: "0px 0px 6px" }}
            focusControl={focusControl}
            focusNextInput={focusNextInput}
          />
          {searchPrefillDefault && !businessId ? (
            <Row justifyBetween>
              <SecondaryCardButton type="button" onClick={onClickFindBusiness}>
                Find/Create Business ({searchPrefillDefault})
              </SecondaryCardButton>
            </Row>
          ) : null}
        </Grid>
        {saleType !== SaleTypes.CLEARING && (
          <Grid item xs={12}>
            <Input
              label="Buyer Account"
              name="buyerWay"
              innerRef={buyerWayRef}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Switch
            label="Use 'Invoice To' Business Address"
            name="useBusinessAddress"
            tooltip="Bidder Driven Reports will use this address."
            disabled={!values.businessId}
            innerRef={businessAddressSwitchRef}
          />
        </Grid>
        <Grid item xs={6}>
          <Switch
            label="Use 'Invoice To' Business Contact"
            name="useBusinessContact"
            tooltip="Bidder Driven Reports will use this contact."
            disabled={!values.businessId}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea name="notes" label="Notes" rows={4} />
        </Grid>
      </Grid>
    </FormCollapse>
  );
}

function ContactCollapse(props) {
  const { isOpen, onToggle } = props;

  const formikProps = useFormikContext();
  const { values } = formikProps;
  const { firstName, lastName, businessId, useBusinessContact } = values;
  const business = useSelector(getBusinessById(businessId));
  const emailRecipient = business?.emailRecipients[0] || {};

  const header = (
    <Row>
      <SubtleBadge>Contact Details</SubtleBadge>
      <CollapseLabel>
        {useBusinessContact
          ? `${emailRecipient?.firstName || ""} ${
              emailRecipient?.lastName || ""
            }`
          : `${firstName || ""} ${lastName || ""}`}
      </CollapseLabel>
      <CollapseLabel>
        {`${
          businessId && useBusinessContact
            ? "Using Business Details"
            : "Using Bidder Registration Details"
        }`}
      </CollapseLabel>
    </Row>
  );
  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      dataTour={isOpen ? "hideContactDetails" : "showContactDetails"}
    >
      <Grid item xs={12} md={6}>
        <MetadataField
          title="Contact First Name"
          value={emailRecipient.firstName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetadataField
          title="Contact Last Name"
          value={emailRecipient.lastName}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetadataField title="Contact Email" value={emailRecipient.email} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetadataField
          title="Contact Phone"
          value={emailRecipient.phoneNumber}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Input label="First Name" name="firstName" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input label="Last Name" name="lastName" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input label="Email" name="email" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input label="Phone Number" name="phoneNumber" />
        </Grid>
      </Grid>
    </FormCollapse>
  );
}

function DeliveryAddressCollapse(props) {
  const { isOpen, onToggle } = props;

  const { errors, values } = useFormikContext();
  const hasErrorBadge = "deliveryAddress" in errors;
  const { businessId, deliveryAddress } = values;

  const business = useSelector(getBusinessById(businessId));
  const address = business?.address || {};
  const usingBusinessAddress = values.useBusinessAddress;

  const value = usingBusinessAddress ? address : deliveryAddress;

  const header = (
    <Row>
      <SubtleBadge hasErrorBadge={hasErrorBadge}>Delivery Address</SubtleBadge>
      {value ? <CollapseLabel>{formatAddress(value)}</CollapseLabel> : null}
      <CollapseLabel>
        {`${
          businessId && usingBusinessAddress
            ? "Using Business Details"
            : "Using Bidder Registration Details"
        }`}
      </CollapseLabel>
    </Row>
  );
  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      dataTour={isOpen ? "hideDeliveryAddress" : "showDeliveryAddress"}
    >
      <Grid item xs={12} md={6}>
        <MetadataField
          title="Contact/Place Name"
          value={address.addressingInformation}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetadataField title="Street Number" value={address.streetNumber} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetadataField title="Street Name" value={address.route} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetadataField title="City" value={address.locality} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetadataField title="State" value={address.state} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MetadataField title="Postcode" value={address.postalCode} />
      </Grid>
      <Grid container spacing={2}>
        <AddressForm label="Delivery Address" namespace="deliveryAddress" />
      </Grid>
    </FormCollapse>
  );
}

function PostalAddressCollapse(props) {
  const { isOpen, onToggle } = props;

  const [{ value }] = useField("postalAddress");
  const header = (
    <Row>
      <SubtleBadge>Postal Address</SubtleBadge>
      {value ? <CollapseLabel>{formatAddress(value)}</CollapseLabel> : null}
    </Row>
  );
  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      dataTour={isOpen ? "hidePostalAddress" : "showPostalAddress"}
    >
      <Grid container spacing={2}>
        <AddressForm label="Postal Address" namespace="postalAddress" />
      </Grid>
    </FormCollapse>
  );
}

function Form({
  handleDelete,
  handleSubmit,
  initialValues,
  isLoading,
  onCancel,
  provisionalData,
  showCreateAnother,
  validationSchema,
  biddersList,
}) {
  const [searchPrefill, setSearchPrefill] = useState(null);

  const default_section = BidderRegistrationModalSection.GENERAL;
  const [section, setSection] = useState(default_section);

  // FIXME HACK This is used to re-open the business pic selector modal
  const [lastOpenInstance, setLastOpenInstance] = useState(undefined);

  const [focus, setFocus, clearFocus] = useBoolean(true);
  const focusControl = {
    focus,
    clearFocus,
  };

  const { name, stock_agency, invoice_to, destination_pic, property_name } =
    provisionalData;

  // Suck out all the old data.
  const searchPrefillDefault = [
    name,
    stock_agency,
    invoice_to,
    destination_pic,
    property_name,
  ]
    .filter(Boolean)
    .join(" ");

  const onClickFindBusiness = () => {
    setSection(BidderRegistrationModalSection.GENERAL);
    setSearchPrefill(searchPrefillDefault);
    setLastOpenInstance(Date.now().toString());
  };

  const onClickClose = () => {
    onCancel && onCancel();
  };

  function onSubmit(values, { resetForm }) {
    const { createAnother, registrationNumber } = values;

    handleSubmit && handleSubmit(values);

    if (createAnother) {
      // submitted, but keeping modal open

      const lastBidderRegistration = biddersList[0];

      const nextRegistrationNumber =
        Math.max(
          lastBidderRegistration?.registrationNumber || 0,
          registrationNumber,
        ) + 1;

      resetForm({
        values: { createAnother, registrationNumber: nextRegistrationNumber },
      });
      setSection(default_section);
      setFocus();
    }
  }

  if (isLoading) {
    return <LoadingSpinner size={24} />;
  }

  const toggleSection = newSection => {
    if (newSection === section) {
      setSection(null);
    } else {
      setSection(newSection);
    }
  };

  const toggleProvisional = () =>
    toggleSection(BidderRegistrationModalSection.PROVISIONAL);
  const toggleGeneral = () =>
    toggleSection(BidderRegistrationModalSection.GENERAL);
  const toggleContact = () =>
    toggleSection(BidderRegistrationModalSection.CONTACT);
  const toggleDelivery = () =>
    toggleSection(BidderRegistrationModalSection.DELIVERY);
  const togglePostal = () =>
    toggleSection(BidderRegistrationModalSection.POSTAL);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {props => {
        const { handleSubmit } = props;

        const submitEnabled = props.isValid && props.dirty;

        return (
          <Column>
            <Row>
              <FormColumn>
                <form onSubmit={handleSubmit}>
                  <ProvisionalCollapse
                    isOpen={
                      section === BidderRegistrationModalSection.PROVISIONAL
                    }
                    onToggle={toggleProvisional}
                    provisionalData={provisionalData}
                  />

                  <GeneralCollapse
                    isOpen={section === BidderRegistrationModalSection.GENERAL}
                    onToggle={toggleGeneral}
                    searchPrefillDefault={searchPrefillDefault}
                    searchPrefill={searchPrefill}
                    lastOpenInstance={lastOpenInstance}
                    onClickFindBusiness={onClickFindBusiness}
                    focusControl={focusControl}
                  />

                  <ContactCollapse
                    isOpen={section === BidderRegistrationModalSection.CONTACT}
                    onToggle={toggleContact}
                  />
                  <DeliveryAddressCollapse
                    isOpen={section === BidderRegistrationModalSection.DELIVERY}
                    onToggle={toggleDelivery}
                  />

                  <PostalAddressCollapse
                    isOpen={section === BidderRegistrationModalSection.POSTAL}
                    onToggle={togglePostal}
                  />

                  {showCreateAnother && (
                    <Row justifyEnd alignEnd marginVertical={2}>
                      <CheckBox
                        name="createAnother"
                        label="Create another on submit"
                      />
                    </Row>
                  )}

                  <SubmitRow>
                    <SecondaryButton type="button" onClick={onClickClose}>
                      Cancel
                    </SecondaryButton>
                    {typeof handleDelete === "function" ? (
                      <DeleteButton type="button" onClick={handleDelete}>
                        Delete
                      </DeleteButton>
                    ) : null}
                    <Button
                      data-tour="submit"
                      type="submit"
                      disabled={!submitEnabled}
                    >
                      Submit
                    </Button>
                  </SubmitRow>
                </form>
              </FormColumn>
            </Row>
          </Column>
        );
      }}
    </Formik>
  );
}

Form.propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  provisionalData: PropTypes.object.isRequired,
};

export default Form;
