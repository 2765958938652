import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BusinessModalSection } from "constants/navigation";

import { openEditBusinessModal } from "lib/navigation";

import { ClickableColumn } from "./columnStyles";

export function RelationshipAgentRenderer({ data }) {
  if (!data) {
    return null;
  }

  const { vendor_id, relationshipAgent } = data;
  const onClickEdit = vendor_id
    ? () => {
        openEditBusinessModal(
          vendor_id,
          "",
          BusinessModalSection.BUSINESS_RELATIONSHIPS,
        );
      }
    : null;
  return (
    <ClickableColumn onClick={onClickEdit}>
      {relationshipAgent}
      <FontAwesomeIcon icon={faPencil} />
    </ClickableColumn>
  );
}
