import React from "react";

import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";
import styled from "styled-components/macro";

import {
  Input,
  PercentageInput,
  withNamespace,
} from "components/Form/FormikControls";

import { CheckBox } from "./CheckBox";
import { HelpText } from "./HelpText";
import { Label } from "./Label";

const CheckboxGrid = styled(Grid)`
  align-self: flex-end;
  text-align: center;
`;

const AgencyCheckbox = () => {
  return (
    <CheckBox
      label="Enable Interest"
      name={withNamespace("deploymentSettings", "enableInterest")}
    />
  );
};

const BusinessCheckbox = ({ ns }) => {
  return (
    <CheckBox
      name={withNamespace(ns, "overrideInterestSettings")}
      label="Override Interest settings from my Agency settings"
    />
  );
};

export function InterestSettingForm(props) {
  const {
    namespace = "",
    name = "interestSettings",
    label = "Interest",
    tooltip,
    useBusinessCheckbox = false,
  } = props;

  const { values } = useFormikContext();
  const { overrideInterestSettings, deploymentSettings = {} } = values;

  const { enableInterest } = deploymentSettings;

  const showInterestForm = useBusinessCheckbox
    ? overrideInterestSettings
    : enableInterest;

  const inputGridSize = useBusinessCheckbox ? 6 : 5;

  return (
    <>
      <Grid item xs={12}>
        {label ? <Label tooltip={tooltip}>{label}</Label> : null}
      </Grid>
      <Grid item xs={12}>
        {useBusinessCheckbox ? (
          <BusinessCheckbox ns={namespace} />
        ) : (
          <AgencyCheckbox />
        )}
      </Grid>
      {showInterestForm && (
        <>
          <Grid item xs={inputGridSize} md={inputGridSize}>
            <Input
              name={`${withNamespace(namespace, name)}.0.daysOverdueThreshold`}
              label="Days Overdue Threshold"
              type="number"
              afterSymbol="days"
              tooltip="A threshold of days overdue from which time interest charges are suggested"
            />
          </Grid>
          <Grid item xs={inputGridSize} md={inputGridSize}>
            <PercentageInput
              name={`${withNamespace(namespace, name)}.0.interestRate`}
              label="Interest Rate"
            />
          </Grid>

          {!useBusinessCheckbox && (
            <CheckboxGrid item xs={12} md={2}>
              <CheckBox
                label="Compound?"
                name={withNamespace(
                  "deploymentSettings",
                  "enableCompoundInterest",
                )}
                tooltip="Include overdue interest charges with the original overdue amount when calculating interest"
              />
            </CheckboxGrid>
          )}
          <Grid item xs={12}>
            <HelpText>
              Applies to transactions that are past a threshold of days overdue
              and suggests chargeable interest from the due date at a daily rate
              charged monthly.
            </HelpText>
          </Grid>
        </>
      )}
    </>
  );
}
