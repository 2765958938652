import { addressSerializeMap } from "lib/serializers/address";
import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const contactSerializeMap = {
  name: "name",
  email: "email",
  phone: "phone",
};

const trackingSeralizeMap = {
  name: "name",
  option: "option",
};

const genericDataSerializeMap = {
  id: "id",
};

const genericBusinessSerializeMap = {
  id: "id",
  name: "name",
};

const serializeMap = {
  contact: contactSerializeMap,
  xeroBusinessName: "xero_business_name",
  poAddress: addressSerializeMap,
  saAddress: addressSerializeMap,
  invoiceNumber: "invoice_number",
  reference: "reference",
  invoiceDate: "invoice_date",
  dueDate: "due_date",
  plannedDate: "planned_date",
  total: "total",
  taxTotal: "tax_total",
  invoiceAmountPaid: "invoice_amount_paid",
  invoiceAmountDue: "invoice_amount_due",
  inventoryItemCode: "inventory_item_code",
  description: "description",
  quantity: "quantity",
  unitAmount: "unit_amount",
  discount: "discount",
  lineAmount: "line_amount",
  accountCode: "account_code",
  taxType: "tax_type",
  taxAmount: "tax_amount",
  tracking1: trackingSeralizeMap,
  tracking2: trackingSeralizeMap,
  currency: "currency",
  type: "type",
  //  Supporting data for Agrinous data types
  saleLot: genericDataSerializeMap,
  consignment: genericDataSerializeMap,
  manualAdjustment: genericDataSerializeMap,
  scan: genericDataSerializeMap,
  targetBusiness: genericBusinessSerializeMap,
  initiatingBusiness: genericBusinessSerializeMap,
  rule: genericDataSerializeMap,
};

const nestedObjectMap = {
  contact: "contact",
  sale_lot: "saleLot",
  consignment: "consignment",
  target_business: "targetBusiness",
  initiating_business: "initiatingBusiness",
  rule: "rule",
  tracking_1: "tracking1",
  tracking_2: "tracking2",
  manual_adjustment: "manualAdjustment",
  scan: "scan",
  po_address: "poAddress",
  sa_address: "saAddress",
};

export const serializeBillingData = buildSerializer(
  serializeMap,
  "billingData",
  nestedObjectMap,
);

export const deserializeBillingData = buildDeserializer(
  serializeMap,
  "billingData",
  nestedObjectMap,
);
