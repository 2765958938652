import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import Button from "./Button";

const FontAwesomeButtonComponent = styled(Button)(
  ({ background, color, size, theme, margin, padding, minHeight }) => `
  ${
    typeof size === "number"
      ? `min-height: ${theme.space[size]}px;
  min-width: ${theme.space[size]}px;`
      : ` min-height: ${minHeight || theme.space[4]}px;
  min-width: ${theme.space[4]}px;`
  }
  
  ${
    typeof background === "string"
      ? `background-color: ${theme.colors[background]};`
      : ""
  }
    ${typeof color === "string" ? `color: ${theme.colors[color]};` : ""}
  margin: ${margin || "0"}px;
  padding: ${padding ? `${padding}px` : "16px"};
  display: flex;
  `,
);
FontAwesomeButtonComponent.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};
export default FontAwesomeButtonComponent;

export const ButtonIcon = styled(FontAwesomeIcon)(
  ({ color, fontSize, theme }) => `
  font-size: ${
    typeof fontSize === "string" ? `${theme.fontSizes[fontSize]}` : "1rem"
  };
  color: ${typeof color === "string" ? theme.colors[color] : "white"};
`,
);
ButtonIcon.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  icon: PropTypes.any,
};
