// we need this to be static so we can scroll inside independently
import React, { useEffect, useState } from "react";

import { Grid, useMediaQuery } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import styled from "styled-components/macro";

import { FullScreenContainer } from "components/FullScreenContainer";
import Averages from "components/SaleWatcher/Averages";
import LotList from "components/SaleWatcher/LotList";
import {
  ScrollingColumn,
  ScrollingGrid,
} from "components/SaleWatcher/ScrollingGrid";
import VideoEmbed from "components/SaleWatcher/VideoEmbed";

import { breakpoints, colors } from "constants/theme";

import { formatUTCToLocalDateString } from "lib/timeFormats";

import { currentSaleSelector, getSaleLotsInLikelyOrder } from "selectors";

import SaleLotCard from "./SaleLotCard";

import "./transitions.css";

const WrapItem = styled(Grid)`
  flex: 1;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}px) {
    overflow: hidden;
  }
`;
const SellingNowGridItem = styled(Grid)`
  padding: ${({ theme }) => theme.space[2]}px;
`;

export const SalePreview = () => {
  const saleLotsInOrder = useSelector(getSaleLotsInLikelyOrder);

  const [currentLotId, setCurrentLotId] = useState(saleLotsInOrder?.[0]?.id);

  const currentIndex = saleLotsInOrder.findIndex(sl => sl.id === currentLotId);

  const lotsInCurrentOrder = state => {
    const lots = getSaleLotsInLikelyOrder(state);
    // Find the index of the current lot and:
    // put everything above it at the end
    // everything below it at the start
    // remove it.
    return [...lots.slice(currentIndex + 1), ...lots.slice(0, currentIndex)];
  };

  const currentLot = saleLotsInOrder[currentIndex];

  useEffect(() => {
    setTimeout(() => {
      if (saleLotsInOrder.length === 0) {
        setCurrentLotId(null);
      } else if (currentIndex >= saleLotsInOrder.length - 1) {
        setCurrentLotId(saleLotsInOrder[0].id);
      } else {
        setCurrentLotId(saleLotsInOrder[currentIndex + 1].id);
      }
    }, 20000);
  });

  const currentSale = useSelector(currentSaleSelector);

  const isMobile = useMediaQuery(`(max-width: ${breakpoints[2]}px)`);
  const wrap = isMobile ? "wrap" : "nowrap";

  return (
    <FullScreenContainer wrap={wrap} direction="column">
      <WrapItem item>
        <ScrollingGrid container wrap={wrap}>
          <ScrollingColumn
            title=""
            columnProps={{ xs: 12, md: 3, mobileOrder: 2 }}
            roundedSide="right"
            backgroundColor={colors.gray8C}
          >
            <LotList
              selector={lotsInCurrentOrder}
              transitionName="nextUp"
              showImages={false}
            />
          </ScrollingColumn>

          <ScrollingColumn
            title={`${currentSale.sale_title} ${formatUTCToLocalDateString(
              currentSale.date,
            )}`}
            columnProps={{ xs: 12, md: 9, mobileOrder: 1 }}
          >
            <Grid container>
              {currentLot ? (
                <>
                  <SellingNowGridItem item xs={12}>
                    <SwitchTransition mode="out-in">
                      <CSSTransition
                        key={currentLotId}
                        timeout={2000}
                        appear
                        classNames="sellingNow"
                      >
                        <Container key={currentLotId}>
                          <SaleLotCard saleLot={currentLot} largeImages />
                        </Container>
                      </CSSTransition>
                    </SwitchTransition>
                  </SellingNowGridItem>
                  <SellingNowGridItem item xs={12}>
                    <Averages />
                  </SellingNowGridItem>
                </>
              ) : null}
              {currentSale.streaming_video ? (
                <SellingNowGridItem item xs={12}>
                  <VideoEmbed videoUrl={currentSale.streaming_video} />
                </SellingNowGridItem>
              ) : null}
            </Grid>
          </ScrollingColumn>
        </ScrollingGrid>
      </WrapItem>
    </FullScreenContainer>
  );
};
