import React, { memo } from "react";

import { useSelector } from "react-redux";

import { Column } from "components/Layout";
import { SmHeading, SmallText } from "components/Text";

import { getProgenyDisplayCount } from "lib/saleLot";

import {
  getDraftedCountByDeploymentSaleId,
  getReceivedCountByDeploymentSaleId,
  getRoundDeliveredCountByDeploymentSaleId,
  getRoundNoSaleCountByDeploymentSaleId,
  getRoundNotPennedCountByDeploymentSaleId,
  getRoundPennedCountByDeploymentSaleId,
  getRoundSoldCountByDeploymentSaleId,
  getIsRoundBalancedByDeploymentSaleId,
  getStatusAndRoundProgenyCountByDeploymentSaleId,
} from "selectors";

function RoundCountCreator(
  deploymentSaleRoundCountSelector,
  TextComponent,
  showProgeny,
) {
  return memo(({ deploymentSaleId, roundIndex }) => {
    const progenyCount = useSelector(
      getStatusAndRoundProgenyCountByDeploymentSaleId(
        deploymentSaleId,
        roundIndex,
      ),
    );
    const progeny = progenyCount.sold;
    const count = useSelector(
      deploymentSaleRoundCountSelector(deploymentSaleId, roundIndex),
    );
    if (showProgeny) {
      return (
        <Column>
          <TextComponent>{count - progeny}</TextComponent>
          <SmallText>{getProgenyDisplayCount(progeny)}</SmallText>
        </Column>
      );
    } else {
      return <TextComponent>{count}</TextComponent>;
    }
  });
}

export const ReceivedCount = memo(({ deploymentSaleId }) => {
  const receivedCount = useSelector(
    getReceivedCountByDeploymentSaleId(deploymentSaleId),
  );
  return (
    <Column>
      <SmHeading>{receivedCount}</SmHeading>
    </Column>
  );
});

export const RoundNoSaleCount = RoundCountCreator(
  getRoundNoSaleCountByDeploymentSaleId,
  SmHeading,
);
export const RoundSoldCount = RoundCountCreator(
  getRoundSoldCountByDeploymentSaleId,
  SmHeading,
  true,
);

export const NotDraftedCount = memo(({ deploymentSaleId }) => {
  const draftedCount = useSelector(
    getDraftedCountByDeploymentSaleId(deploymentSaleId),
  );
  const receivedCount = useSelector(
    getReceivedCountByDeploymentSaleId(deploymentSaleId),
  );
  const notDraftedCount = receivedCount - draftedCount;
  let color = "";
  if (notDraftedCount !== 0) {
    color = "error";
  } else if (notDraftedCount === 0) {
    color = "success";
  }
  return <SmHeading color={color}>{notDraftedCount}</SmHeading>;
});

export const RoundNotPennedCount = memo(({ deploymentSaleId, roundIndex }) => {
  const notPennedCount = useSelector(
    getRoundNotPennedCountByDeploymentSaleId(deploymentSaleId, roundIndex),
  );
  const progenyCount = useSelector(
    getStatusAndRoundProgenyCountByDeploymentSaleId(
      deploymentSaleId,
      roundIndex,
    ),
  );
  const progeny = progenyCount.notPenned;
  const color = notPennedCount === 0 ? "success" : "error";
  return (
    <Column>
      <SmHeading color={color}>{notPennedCount - progeny}</SmHeading>
      <SmallText>{getProgenyDisplayCount(progeny)}</SmallText>
    </Column>
  );
});

export const RoundPennedCount = memo(({ deploymentSaleId, roundIndex }) => {
  const pennedCount = useSelector(
    getRoundPennedCountByDeploymentSaleId(deploymentSaleId, roundIndex),
  );
  const progenyCount = useSelector(
    getStatusAndRoundProgenyCountByDeploymentSaleId(
      deploymentSaleId,
      roundIndex,
    ),
  );
  const progeny = progenyCount.penned;
  const notPennedCount = useSelector(
    getRoundNotPennedCountByDeploymentSaleId(deploymentSaleId, roundIndex),
  );
  let color = "";
  if (pennedCount > 0 && notPennedCount === 0) {
    color = "success";
  } else if (pennedCount > 0 && notPennedCount !== 0) {
    color = "error";
  }
  return (
    <Column>
      <SmHeading color={color}>{pennedCount - progeny}</SmHeading>
      <SmallText>{getProgenyDisplayCount(progeny)}</SmallText>
    </Column>
  );
});

export const RoundDeliveredCount = memo(({ deploymentSaleId, roundIndex }) => {
  const deliveredCount = useSelector(
    getRoundDeliveredCountByDeploymentSaleId(deploymentSaleId, roundIndex),
  );
  const isRoundBalanced = useSelector(
    getIsRoundBalancedByDeploymentSaleId(deploymentSaleId, roundIndex),
  );
  let color = "";
  if (deliveredCount > 0 && isRoundBalanced) {
    color = "success";
  } else if (!isRoundBalanced) {
    color = "error";
  }
  return (
    <Column>
      <SmHeading color={color}>{deliveredCount}</SmHeading>
    </Column>
  );
});
