// Value formatters allow you to format values for display.

import Big from "big.js";
import { get } from "lodash";

import { BillingDocumentType } from "constants/billingDocuments";
import { getTransferMessage } from "constants/nlis";
import { PricingTypes } from "constants/pricingTypes";

import { formatDecimal, getDollarsPriceString } from "lib";

import { aggregateText } from "lib/textUtils";
import {
  formatDateString,
  formatDateTimeString,
  getAUFormattedDate,
} from "lib/timeFormats";

export const dateFormatter = ({ value }) => getAUFormattedDate(value);

export const localDateTimeFormatter = ({ value }) => {
  if (value == null) {
    return "";
  }
  return formatDateTimeString(value);
};

export const localDateFormatter = ({ value }) => {
  if (value == null) {
    return "";
  }
  return formatDateString(value);
};

const kilogramsFormatter = ({ value }) =>
  typeof value === "number" && value > 0 ? formatDecimal(value, 2) : "";

const unitPriceFormatter = ({ colDef, data, value }) => {
  const saleLot = get(data, colDef.field);

  if (!saleLot?.pricing_type_id) {
    return "";
  }

  const defaultPricingTypeId = get(data, "livestockSale.pricing_type_id");
  const output = [];
  if (defaultPricingTypeId !== saleLot.pricing_type_id) {
    output.push(PricingTypes.toString(saleLot.pricing_type_id));
  }

  const priceString =
    saleLot.pricing_type_id === PricingTypes.PER_KILO
      ? `\xA2 ${value}`
      : `$${value}`;
  output.push(priceString);

  return output.filter(Boolean).join(" ");
};

// This must be used with dollars getter or it will be displayed in cents.
const dollarsFormatter = ({ value }) =>
  value ? `$${getDollarsPriceString(value)}` : "";

const percentageFormatter = ({ value }) => (value ? `${value}%` : "-");

function minTwoDecimalPlaces(value) {
  return value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
  });
}
export const floatFormatter = params => {
  const { column, value } = params;
  const { valueFormatterParams } = column.colDef;
  const {
    decimalPlaces = null,
    defaultValue,
    formatToMinTwoDecimalPlaces = false,
  } = valueFormatterParams || {};
  if (typeof value !== "number") {
    if (typeof defaultValue === "function") {
      return defaultValue(params);
    }
    return null;
  }
  if (typeof decimalPlaces !== "number") {
    return value;
  }

  if (formatToMinTwoDecimalPlaces) {
    return minTwoDecimalPlaces(value);
  }
  return new Big(value).toFixed(decimalPlaces);
};

const nlisTransferMessageFormatter = ({ value }) =>
  value ? getTransferMessage(value) : "";

const picFormatter = ({ value }) => {
  if (!Array.isArray(value)) {
    return null;
  }
  return aggregateText(value, v => v, "PICs");
};
export const dollarTotalsFilterValueGetter = params => {
  const total =
    typeof params.value === "object" ? params.value?.value : params.value;
  return total / 100 || 0;
};
const dollarTotalsFormatter = param => {
  // If the total column is AVG, it returns an object with the min/max and value
  const total =
    typeof param.value === "object" ? param?.value?.value : param?.value;
  return `$${total ? total.toFixed(2) : 0}`;
};

const centsTotalsFormatter = ({ value, colDef }) => {
  if (colDef.valueFormatterParams?.returnDollars && value !== null) {
    return `$${formatDecimal(value / 100)}`;
  }

  return value !== null ? `${value}¢` : "-";
};

const listCountFormatter = ({ value }) => {
  if (value == null) {
    return 0;
  }
  return value.length;
};

const dollarsCreditDebitFormatter = ({ value, data = {}, column }) => {
  const { billingDocument = {} } = data;
  const suffix =
    billingDocument.type === BillingDocumentType.INVOICE ? "Cr" : "Dr";
  const { valueFormatterParams } = column.colDef;
  const { decimalPlaces } = valueFormatterParams || {};

  if (decimalPlaces) {
    return value ? `$${value.toFixed(decimalPlaces)} ${suffix}` : "";
  }

  return value ? `$${minTwoDecimalPlaces(value)} ${suffix}` : "";
};

export default {
  dateFormatter,
  dollarsFormatter,
  floatFormatter,
  kilogramsFormatter,
  localDateTimeFormatter,
  localDateFormatter,
  nlisTransferMessageFormatter,
  percentageFormatter,
  picFormatter,
  unitPriceFormatter,
  dollarTotalsFormatter,
  centsTotalsFormatter,
  listCountFormatter,
  dollarsCreditDebitFormatter,
};
