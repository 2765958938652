import React from "react";

import { faEnvelopeCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

const Icon = styled(FontAwesomeIcon).attrs({
  icon: faEnvelopeCircleCheck,
})(({ theme, color, margin }) => {
  return `
    color: ${theme.colors[color] || theme.colors.primary};
    font-size: ${theme.fontSizes.epsilon}px;
    margin: ${
      typeof margin === "number" ? theme.space[margin] : theme.space[1]
    }px;
`;
});

export const PrimaryContactIcon = ({ color, title = "Primary Contact" }) => {
  return <Icon color={color} title={title} />;
};
