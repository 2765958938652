import React from "react";

import { useTheme } from "hooks";

export const FrontGateOpenBackGateLocked = ({ color, size }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.black;
  const svgSize = size || theme.fontSizes.gamma;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox="0 0 27500 27500"
    >
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="1356"
        y1="9676"
        x2="6967.6"
        y2="17704.9"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21709.1"
        y1="13758.3"
        x2="21709.1"
        y2="6391"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6974.5"
        y1="21125.6"
        x2="6974.5"
        y2="17442"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="14341.8"
        y1="21125.6"
        x2="21709.1"
        y2="21125.6"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21709.1"
        y1="21125.6"
        x2="21709.1"
        y2="13758.3"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="14341.8"
        y1="6391"
        x2="21709.1"
        y2="6391"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6974.5"
        y1="21125.6"
        x2="14341.8"
        y2="21125.6"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6974.5"
        y1="6391"
        x2="14341.8"
        y2="6391"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6953"
        cy="17612"
        r="331.5"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6974.5"
        cy="6391"
        r="331.5"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6974.5"
        cy="21125.6"
        r="331.5"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="14341.8"
        cy="21125.6"
        r="331.5"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="14341.8"
        cy="6391"
        r="331.5"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21709.1"
        cy="13758.3"
        r="331.5"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21709.1"
        cy="21125.6"
        r="331.5"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21709.1"
        cy="6391"
        r="331.5"
      />
      <path
        stroke="none"
        d="M5133.4 11761.6c531.7-36.5 884 171.7 1418.9 61.8l-2771-3512.5 554.5-465.1 2790 3524.7c227.3-518.3 86.1-895.1 276.2-1363.1l598.4 22.8c84.9 344.8-190.3 2260.2-262.4 2691.6-379.3 16.5-2383.2-190.7-2709.4-370.5l104.9-589.7z"
      />
      <path
        fill={svgColor}
        d="M24577.2 13604.6c-13.8-119.6-1.5-311.8-1.4-439.4 0.1-154.4-6.7-256.9 52.4-388.7 47.5-106 113.6-176.5 202.1-238.8 202.2-142.2 464.1-135.2 666.9 2.4 238 161.5 250.3 381.2 250.2 632.8 0 132.2 8.4 304.1-2.5 431.7l-1167.7 0.1zm-647.3-620.4l-1.8 624.2-204.9-1.5c-166.4-10.7-299.8 117.1-346.9 223.2-58.4 131.4-36.8 373.2-37 529.4-0.5 377.8 0.4 755.7-0.1 1133.5-0.6 505.1 393.4 452.6 688.5 452.6l2266.9-0.2c219.2 0 522.4 45.3 644-211.4 40.6-85.7 44.5-138.5 44.4-241-0.5-377.8-0.1-755.6 0.1-1133.5 0.1-163.8 22.9-395.4-40.3-534-46.6-102.2-184.5-229.2-344.8-218.1l-203.2-1.8-0.8-618.3c12.3-225.2-102.1-512.2-229.8-688-39.2-54-98.8-127.3-139.9-164.3l-166.8-137.6c-187.9-131.6-455.1-213.8-696.3-213.5-317.6 0.4-550.8 104.3-782.7 279.3l-220 236.5c-121.8 149.7-239.7 469.9-228.6 684.8z"
      />
    </svg>
  );
};

export default FrontGateOpenBackGateLocked;
