import React from "react";

import { Grid } from "@material-ui/core";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";

import { Button } from "components/Form";
import { Input } from "components/Form/FormikControls";

import { validatePIC } from "lib/PICValidator";

function businessOrPICValidator() {
  return this.parent.businessName || this.parent.PIC;
}

export const AddRow = ({
  defaultBusinessName,
  defaultPIC,
  defaultPICName,
  handleAddBusinessPIC,
  disableBusiness = false,
  showProperty = false,
}) => {
  const validationSchema = Yup.object().shape({
    businessName: !showProperty
      ? Yup.string().required("Business Name is required.")
      : Yup.string().test(
          "Buyer/Vendor",
          "Business Name or PIC required.",
          businessOrPICValidator,
        ),
    PIC: Yup.string()
      .test(
        "Buyer/Vendor",
        "Business Name or PIC required.",
        businessOrPICValidator,
      )
      .test("PIC", "Invalid PIC", value => !value || validatePIC(value)),
  });

  const onSubmit = values => {
    handleAddBusinessPIC(
      values.businessName,
      values.PIC,
      values.PICName || null,
    );
  };

  const AddBusinessPICForm = () => {
    const formikProps = useFormikContext();
    const { handleSubmit, isValid, submitCount } = formikProps;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            label="Business Name"
            name="businessName"
            disabled={disableBusiness}
          />
        </Grid>

        {showProperty && (
          <>
            <Grid item xs={12}>
              <Input
                label="PIC"
                name="PIC"
                valueTransformer={v => v.toUpperCase()}
                maxLength={8}
                disabled={disableBusiness}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="PIC Name"
                name="PICName"
                disabled={disableBusiness}
              />
            </Grid>
          </>
        )}

        <Button
          data-tour="addBusinessPic"
          type="submit"
          onClick={handleSubmit}
          disabled={!(isValid || submitCount === 0)}
        >
          Submit
        </Button>
      </Grid>
    );
  };

  const initialValues = {
    businessName: defaultBusinessName || "",
    PIC: defaultPIC || "",
    PICName: defaultPICName || "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <AddBusinessPICForm />
    </Formik>
  );
};
