import React from "react";

import { ModalTypes } from "constants/navigation";

import { EditPaymentModal } from "./Modal";

export function Adapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.EditPayment] || null;
  const [paymentId, options] = JSON.parse(hashParams);
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";

  return (
    <EditPaymentModal
      paymentId={paymentId}
      options={options}
      returnTo={decodedReturnTo}
    />
  );
}
