import React from "react";

import { faBookDead, faEye } from "@fortawesome/free-solid-svg-icons";
import { faPencil, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";

import { AgGridActionButtons } from "constants/columnDefinitions/actions";
import { ConsignmentPermissions } from "constants/permissions";
import { SaleTypes } from "constants/sale";

import {
  openEditConsignmentModal,
  openKillSheetHashModal,
} from "lib/navigation";
import { hasPermission } from "lib/permissions";

import { getCurrentSale } from "selectors";

export function UploadEditButton(props) {
  const { data, context } = props;

  const saleType = useSelector(getCurrentSale)?.sale_type;

  if (!data) {
    return null;
  }

  const { id, vendor_name } = data;
  const { readOnly, openUploadModal } = context;

  const hasChangeConsignmentPermission = hasPermission(
    data,
    ConsignmentPermissions.update,
  );

  function onClickUpload() {
    openUploadModal(id, null);
  }
  function onClickEdit() {
    openEditConsignmentModal(id, undefined, true, false);
  }
  function onClickKillSheet() {
    openKillSheetHashModal(id, null);
  }

  const actions = [
    hasChangeConsignmentPermission && {
      onClick: onClickUpload,
      dataTour: "action-upload",
      icon: faUpload,
      title: " Upload",
      hide: readOnly,
    },
    {
      onClick: onClickEdit,
      dataTour: `action-edit-${vendor_name}`,
      icon: hasChangeConsignmentPermission ? faPencil : faEye,
      title: hasChangeConsignmentPermission ? " Edit" : " View",
    },
    {
      onClick: onClickKillSheet,
      dataTour: `action-kill-sheet-${vendor_name}`,
      icon: faBookDead,
      title: " Kill Sheet",
      hide: saleType !== SaleTypes.OVER_HOOKS,
    },
  ];

  return <AgGridActionButtons actions={actions} />;
}
