import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const serializeMap = {
  id: "id",
  created: "created",
  user: "user",
  submitted: "submitted",
  processed: "processed",
  status: "status",
  errorNotes: "error_notes",
  asynchronous: "asynchronous",
  transactionId: "transaction_id",
  transfers: "transfers",
  transferSaleyardId: "transfer_saleyard_id",
  livestockSaleId: "livestock_sale_id",
  saleDate: "sale_date",
  consignments: "consignments",
};

export const serializeNVDUpload = buildSerializer(serializeMap, "nvdUpload");
export const deserializeNVDUpload = buildDeserializer(
  serializeMap,
  "nvdUpload",
);
