import React, { useMemo } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import { uniqBy } from "lodash";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { InputDateRange } from "components/DateRangePicker/dateRangePicker";
import { Button } from "components/Form";
import { BusinessField, OrderableMultiSelect } from "components/Form/Fields";
import {
  CheckBox,
  Label,
  SelectField,
  useSubmitHandler,
} from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";
import { SaleSelectField } from "components/Form/FormikControls/SaleSelectField";
import { Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { BillingRunSummary } from "components/MultiSaleReports/BillingRunSummary";
import {
  getEndDateValidation,
  getStartDateValidation,
} from "components/MultiSaleReports/commonValidation";
import ReactSelect, {
  findOptionByValue,
} from "components/SearchableSelector/ReactSelect";

import { ApiModel } from "constants/loading";
import {
  MultiSaleReportOptions,
  ReportQueryParam,
  Reports,
} from "constants/reports";
import { SaleTypes } from "constants/sale";

import { getReportUrl } from "lib/reports";
import { formatISO8601DateString } from "lib/timeFormats";

import {
  getActiveRole,
  getRuleBooks,
  getSaleSubTypes,
  getSpecies,
} from "selectors";

export const yearOptionAllowed = reportSlug =>
  [
    Reports.BusinessSummaryReport,
    Reports.FinancialStatementReport,
    Reports.FinancialStatementZipReport,
  ].some(report => report.slug === reportSlug);

const groupBys = {
  age: "Age",
  breed: "Breed",
  buyer: "Buyer",
  period_day: "Period - Day",
  period_fin_year: "Period - Financial Year",
  period_month: "Period - Month",
  period_quarter: "Period - Quarter",
  period_week: "Period - Week",
  period_year: "Period - Year",
  round: "Round",
  sale_subtype: "Sale Sub Type",
  sale_type: "Sale Type",
  sale_yard: "Sale Yard",
  sex: "Sex",
  species: "Species",
  vendor: "Vendor",
  vendor_pic_town: "Vendor PIC Town",
  vendor_pic_state: "Vendor PIC State",
  vendor_pic_shire_code: "Vendor PIC Shire Code",
  buyer_pic_town: "Buyer PIC Town",
  buyer_pic_state: "Buyer PIC State",
  buyer_pic_shire_code: "Buyer PIC Shire Code",
};

const GroupByOptions = Object.entries(groupBys).map(([value, label]) => ({
  value,
  label,
}));

const OutputFormat = {
  csv: "CSV",
  pdf: "PDF",
};

const OutputFormatOptions = Object.entries(OutputFormat).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

function MultiSaleReportFormBody(props) {
  const { reportSlug } = props;
  const { queryMap } = Reports[reportSlug];
  const saleSubTypes = useSelector(getSaleSubTypes);
  const subTypeOptions = uniqBy(
    Object.values(saleSubTypes).map(s => ({
      label: s.name,
      value: s.id,
    })),
    "label",
  );

  const saleTypesFromState = useSelector(
    state => state.saleDefinitions.saleTypes,
  );

  const saleTypes = Object.values(SaleTypes)
    .filter(saleType => saleTypesFromState.includes(saleType))
    .map(saleType => ({
      label: saleType,
      value: saleType,
    }));

  const speciesOptions = useSelector(state =>
    Object.values(getSpecies(state)),
  ).map(s => ({
    label: s.name,
    value: s.id,
  }));

  const showLivestockSaleFilter = queryMap[ReportQueryParam.livestockSaleIdIn];

  const showSaleDateFilter =
    queryMap[ReportQueryParam.livestockSaleDateGte] ||
    queryMap[ReportQueryParam.livestockSaleDateLte];

  const showSaleTypeFilter =
    queryMap[ReportQueryParam.livestockSaleSaleTypeNameIn];

  const showSaleSubTypeFilter =
    queryMap[ReportQueryParam.livestockSaleSaleSubTypeIdIn];

  const showGeneralDateFilter =
    queryMap[ReportQueryParam.startDate] || queryMap[ReportQueryParam.endDate];

  const showBusinessFilter = queryMap[ReportQueryParam.businessId];

  const showGroupBys = queryMap[ReportQueryParam.groupBys];

  const showSpeciesId = queryMap[ReportQueryParam.speciesId];
  const showOnlySold = queryMap[ReportQueryParam.onlySold];
  const showOutputFormat = queryMap[ReportQueryParam.outputFormat];

  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  useSubmitHandler(true, setIsSubmitEnabled);

  const { setValues, values, errors } = useFormikContext();

  const updateLivestockSaleDateFilters = (startDate, endDate) => {
    const updates = {
      livestockSaleDateGte: startDate,
      livestockSaleDateLte: endDate,
    };
    setValues({
      ...values,
      ...updates,
    });
  };

  const updateGeneralDateFilter = (startDate, endDate) => {
    const updates = {
      startDate,
      endDate,
    };
    setValues({
      ...values,
      ...updates,
    });
  };

  return (
    <Grid container spacing={2}>
      {errors && errors.anyFields ? (
        <Grid item xs={12}>
          <Error>{errors.anyFields}</Error>
        </Grid>
      ) : null}
      {showSaleDateFilter && (
        <InputDateRange
          updateDates={updateLivestockSaleDateFilters}
          startDateFieldName="livestockSaleDateGte"
          endDateFieldName="livestockSaleDateLte"
          includeTime={false}
        />
      )}

      {showLivestockSaleFilter && (
        <Grid item xs={12}>
          <SaleSelectField name="livestockSaleIdIn" label="Sale" />
        </Grid>
      )}

      {showSaleTypeFilter && (
        <Grid item xs={12}>
          <SelectField
            label="Sale Type"
            name="livestockSaleSaleTypeNameIn"
            options={saleTypes}
            isClearable
            isMulti
          />
        </Grid>
      )}
      {showSaleSubTypeFilter && (
        <Grid item xs={12}>
          <SelectField
            label="Sale Subtype"
            name="livestockSaleSaleSubTypeIdIn"
            options={subTypeOptions}
            isClearable
            isMulti
          />
        </Grid>
      )}
      {showGeneralDateFilter && (
        <InputDateRange
          updateDates={updateGeneralDateFilter}
          startDateFieldName="startDate"
          endDateFieldName="endDate"
          includeTime={false}
          lastDayInclusive={false}
          showYearOptions={yearOptionAllowed(reportSlug)}
        />
      )}
      {showBusinessFilter && (
        <Grid item xs={12}>
          <BusinessField
            name="businessId"
            label="Business"
            allowAddNew={false}
          />
        </Grid>
      )}

      {showSpeciesId && (
        <Grid item xs={12}>
          <SelectField
            label="Filter Species"
            name="speciesId"
            options={speciesOptions}
            isClearable
          />
        </Grid>
      )}

      {showOnlySold && (
        <Grid item xs={12}>
          <CheckBox name="onlySold" label="Only Show Sold Lots" />
        </Grid>
      )}

      {showGroupBys && (
        <Grid item xs={12}>
          <OrderableMultiSelect
            options={GroupByOptions}
            name="groupBys"
            label="Group By"
            required
            placeHolder="Enter at least one field to group by."
          />
        </Grid>
      )}
      {showOutputFormat && (
        <Grid item xs={12}>
          <SelectField
            label="Output Format"
            name="outputFormat"
            options={OutputFormatOptions}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <CheckBox name="shouldCreateAnother" label="Create another" />
      </Grid>

      <Grid item xs={12}>
        <Button data-tour="submit" type="submit" disabled={!isSubmitEnabled}>
          Run Report
        </Button>
      </Grid>
    </Grid>
  );
}

export function MultiSaleReportForm(props) {
  const { allowedReports, onClose } = props;

  const options = useMemo(
    () =>
      !Array.isArray(allowedReports)
        ? MultiSaleReportOptions
        : MultiSaleReportOptions.filter(option =>
            allowedReports.includes(option.value),
          ),
    [allowedReports],
  );

  const [reportSlug, setReportSlug] = React.useState(
    options.length > 0 ? options[0].value : null,
  );

  const { queryMap, showBillingRunSummary = true } = Reports[reportSlug];

  const ruleBookByIdLookup = useSelector(getRuleBooks);

  const initialValues = {
    rulebookId: Object.values(ruleBookByIdLookup)?.[0]?.id,
    groupBys: [],
    outputFormat: OutputFormatOptions[0].value,
    shouldCreateAnother: false,
  };
  const roleSlug = useSelector(state => getActiveRole(state).slug);

  function onSubmit(values, formHelpers) {
    if (values.livestockSaleDateGte) {
      values.livestockSaleDateGte = formatISO8601DateString(
        new Date(values.livestockSaleDateGte),
      );
    }
    if (values.livestockSaleDateLte) {
      values.livestockSaleDateLte = formatISO8601DateString(
        new Date(values.livestockSaleDateLte),
      );
    }
    const injectedValues = {
      userRole: roleSlug,
    };
    if ("static" in queryMap) {
      injectedValues.static = 1;
    }

    if ("async" in queryMap) {
      injectedValues.async = 1;
    }

    injectedValues.groupBys = Array.isArray(values.groupBys)
      ? values.groupBys.map(groupBy => groupBy.value)
      : undefined;

    const reportUrl = getReportUrl(Reports[reportSlug], {
      ...values,
      ...injectedValues,
    });
    if (values.shouldCreateAnother) {
      formHelpers.resetForm({
        values: { ...initialValues, shouldCreateAnother: true },
      });
    } else {
      onClose();
    }
    window.open(reportUrl, "_blank");
  }

  const showLivestockSaleFilter = queryMap[ReportQueryParam.livestockSaleIdIn];

  const showSaleDateFilter =
    queryMap[ReportQueryParam.livestockSaleDateGte] ||
    queryMap[ReportQueryParam.livestockSaleDateLte];

  const showSaleTypeFilter =
    queryMap[ReportQueryParam.livestockSaleSaleTypeNameIn];

  const showSaleSubTypeFilter =
    queryMap[ReportQueryParam.livestockSaleSaleSubTypeIdIn];

  const showGeneralDateFilter =
    queryMap[ReportQueryParam.startDate] || queryMap[ReportQueryParam.endDate];

  const showBusinessFilter = queryMap[ReportQueryParam.businessId];

  const showGroupBys = queryMap[ReportQueryParam.groupBys];

  const validationShape = {};

  if (showLivestockSaleFilter) {
    // TODO: double check casing on id, leave a comment here if this is correct, fix if wrong
    validationShape.livestockSaleidIn = Yup.array().of(Yup.number()).nullable();
  }

  if (showSaleDateFilter) {
    validationShape.livestockSaleDateLte = getEndDateValidation();
    validationShape.livestockSaleDateGte = getStartDateValidation(
      "livestockSaleDateLte",
      { isYearOptionAllowed: yearOptionAllowed(reportSlug) },
    );
  }
  if (showSaleTypeFilter) {
    validationShape.livestockSaleSaleTypeNameIn = Yup.array()
      .of(Yup.string())
      .nullable();
  }
  if (showSaleSubTypeFilter) {
    validationShape.livestockSaleSaleSubTypeIdIn = Yup.array()
      .of(Yup.number())
      .nullable();
  }
  if (showGeneralDateFilter) {
    validationShape.endDate = getEndDateValidation().required("Date required.");
    validationShape.startDate = getStartDateValidation("endDate", {
      isYearOptionAllowed: yearOptionAllowed(reportSlug),
    }).required("Date required.");
  }

  if (showBusinessFilter) {
    validationShape.businessId = Yup.string()
      .nullable()
      .required("Business is required.");
  }

  if (showGroupBys) {
    validationShape.groupBys = Yup.array()
      .of(Yup.object())
      .min(1, "At least 1 grouped field required.")
      .max(5, "Maximum of 5 grouped fields.");
  }

  const validationSchema = Yup.lazy(() => Yup.object().shape(validationShape));

  function onChangeReportSlug(option) {
    setReportSlug(option.value);
  }

  return (
    <WaitForSync requiredData={[ApiModel.RULE_BOOKS]}>
      <Grid xs={12} item container>
        <Column fullWidth>
          <Label>Report</Label>
          <ReactSelect
            options={options}
            onChange={onChangeReportSlug}
            value={findOptionByValue(options, reportSlug)}
          />
        </Column>
      </Grid>
      {reportSlug && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form data-tour="settings-form">
            <MultiSaleReportFormBody reportSlug={reportSlug} />
            {showBillingRunSummary ? <BillingRunSummary /> : null}
          </Form>
        </Formik>
      )}
    </WaitForSync>
  );
}
