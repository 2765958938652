import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Gallery } from "components/SaleWatcher/Gallery";
import WatcherChips from "components/SaleWatcher/WatcherChips";

import { PricingTypes } from "constants/pricingTypes";

import { calculateUnitPriceDollars, formatDecimal, formatWeightKg } from "lib";

import { formatUTCToLocalTimeString } from "lib/timeFormats";

import { getAgencyBySaleLotIdLookup } from "selectors";

const PrimaryData = styled.div`
  ${({ theme, bigScreen }) => `
    color: ${theme.colors.titleDark};
    font-size: ${bigScreen ? theme.fontSizes.zeta : theme.fontSizes.gamma}px;
    font-weight: ${theme.fontWeights.bold}px;
    line-height: ${theme.fontSizes.delta}px;
    margin: 0;
    margin-bottom: ${theme.space[1]}px;
  `}
`;
const SubData = styled.div`
  ${({ theme, bigScreen }) => `
    color: ${theme.colors.titleDark};
    font-size: ${
      bigScreen ? theme.fontSizes.epsilon : theme.fontSizes.charlie
    }px;
    line-height:${bigScreen ? theme.fontSizes.zeta : theme.fontSizes.gamma}px;
    margin: 0;
    margin-bottom: ${theme.space[1]}px;
  `}
`;

const SoldData = styled.div`
  ${({ theme, bigScreen }) => `
    color: ${theme.colors.titleDark};
    font-size:${bigScreen ? theme.fontSizes.eta : theme.fontSizes.delta}px;
    font-weight: ${theme.fontWeights.bold};    
    line-height: ${bigScreen ? theme.fontSizes.eta : theme.fontSizes.epsilon}px;
    margin: ${theme.space[2]}px 0;
  `}
`;

const SaleCard = styled(Card)`
  ${({ status, theme }) => `
  border-left: 4px solid ${theme.statusColors[status]};
  border-bottom: 4px solid ${theme.statusColors[status]};
  `}
  transition: border 0.3s ease-in-out;
`;

const SaleLotCard = ({
  saleLot,
  largeImages = false,
  showChips = true,
  bigScreen = false,
  showImages = true,
}) => {
  const agencyName = useSelector(getAgencyBySaleLotIdLookup(saleLot.id))?.name;
  return (
    <SaleCard raised status={saleLot.status}>
      <CardContent>
        <Grid container alignContent="space-around">
          <Grid item xs={6} align="left">
            <PrimaryData bigScreen={bigScreen}>
              Pen: {saleLot.penName}
            </PrimaryData>
          </Grid>
          <Grid item xs={6} align="right">
            <PrimaryData bigScreen={bigScreen}>
              Hd: {saleLot.quantity}
            </PrimaryData>
          </Grid>
          {!bigScreen && (
            <>
              <Grid item xs={6} align="left">
                <SubData>{agencyName}</SubData>
              </Grid>
              <Grid item xs={6} align="right">
                <SubData>{saleLot?.sale_round?.name}</SubData>
              </Grid>
            </>
          )}
        </Grid>
        <Grid container alignContent="space-around" justifyContent="center">
          {saleLot.total_price_cents ? (
            <Grid xs={12} item align="center">
              <SoldData bigScreen={bigScreen}>
                {PricingTypes.forOutput(
                  saleLot.pricing_type_id,
                  formatDecimal(calculateUnitPriceDollars(saleLot)),
                )}
              </SoldData>
            </Grid>
          ) : null}
          {saleLot.total_mass_grams ? (
            <Grid xs={6} item align="center">
              <SubData bigScreen={bigScreen}>
                {saleLot.total_weight}&nbsp;Kg
                {saleLot.quantity > 1 ? (
                  <>
                    <br />
                    (Avg &nbsp;{saleLot.averageWeightFormatted})
                  </>
                ) : null}
              </SubData>
            </Grid>
          ) : null}
          {!saleLot.total_mass_grams && saleLot.estimatedAverageMassGrams ? (
            <Grid xs={6} item align="center">
              <SubData bigScreen={bigScreen}>
                Ind Wt.&nbsp; ~
                {formatWeightKg(
                  saleLot.estimatedAverageMassGrams * saleLot.quantity,
                  false,
                  saleLot.quantity === 1,
                )}
                &nbsp;Kg
                {saleLot.quantity > 1 ? (
                  <>
                    <br />
                    (Avg&nbsp;
                    {formatWeightKg(saleLot.estimatedAverageMassGrams, false)})
                  </>
                ) : null}
              </SubData>
            </Grid>
          ) : null}
          {saleLot.buyer_id && saleLot.first_sold ? (
            <Grid xs={6} item align="center">
              <SubData bigScreen={bigScreen}>
                Sold: {formatUTCToLocalTimeString(saleLot.first_sold)}
              </SubData>
              {saleLot.indPricePerKilo ? (
                <SubData>Ind. $/Kg: ~{saleLot.indPricePerKilo}</SubData>
              ) : null}
            </Grid>
          ) : null}
        </Grid>

        {showChips && <WatcherChips saleLot={saleLot} bigScreen={bigScreen} />}

        {!bigScreen && showImages && (
          <Gallery
            attachments={saleLot.attachments}
            largeImages={largeImages}
            title={`${agencyName}: Pen ${saleLot.penName} - ${saleLot.quantity} Head`}
          />
        )}
      </CardContent>
    </SaleCard>
  );
};

export default SaleLotCard;
