import { createSelector } from "reselect";

import { Settings } from "constants/settings";

import { EMPTY_OBJECT } from "lib";

import { getSettings } from "selectors";

export const getSetting = setting => state => getSettings(state)[setting];

export const getTableSetting = tableName => state =>
  getSettings(state)[Settings.tableViews][tableName];

export const getSaleProgressSettings = state =>
  getSetting(Settings.saleProgress)(state);

export const getSaleProgressSetting = saleProgressSetting => state =>
  getSaleProgressSettings(state)[saleProgressSetting];

export const selectSelectedViewIdByTableLookup = createSelector(
  [getSettings],
  settings => {
    const tableSettings = settings[Settings.tableViews];
    return Object.entries(tableSettings).reduce((acc, [tableName, val]) => {
      acc[tableName] = val.viewId;
      return acc;
    }, {});
  },
);

export const getSelectedViewIdByTable = table => state =>
  selectSelectedViewIdByTableLookup(state)[table];

export const selectGlobalSearchBySearchFieldLookup = state =>
  getSetting(Settings.globalSearch)(state) || EMPTY_OBJECT;

export const getGlobalSearchBySearchField = field => state => {
  // results can be falsey (eg, false, 0) so an || won't cut it.
  const result = selectGlobalSearchBySearchFieldLookup(state)[field];
  if (result !== undefined) {
    return result;
  }
  return null;
};

export const selectSaleSearchBySearchFieldLookup = state =>
  getSetting(Settings.saleSearch)(state) || EMPTY_OBJECT;

export const getSaleSearchBySearchField = field => state => {
  // results can be falsey (eg, false, 0) so an || won't cut it.
  const result = selectSaleSearchBySearchFieldLookup(state)[field];
  if (result !== undefined) {
    return result;
  }
  return null;
};

export const getShowDeduplicatedWeighLotScans = state =>
  getSettings(state)[Settings.showDeduplicatedWeighLotScans] || false;

export const getMtHostSettings = state =>
  getSettings(state)[Settings.mtHostSettings] || {};

export const getMtHostSettingsByDeviceId = deviceId => state =>
  getSettings(state)[Settings.mtHostSettings][deviceId] || null;
