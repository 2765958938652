import React from "react";

import { AverageWeight } from "./WatcherComponents";
import "./transitions.css";

export function NextLotFooter({ saleLot, colSpan }) {
  return (
    <tfoot>
      <tr>
        <th className="next">NEXT UP</th>
        <td
          colSpan={colSpan}
          className="scaled next fadeIn"
          key={saleLot?.last_modified}
        >
          {saleLot ? (
            <>
              {saleLot?.quantity} x {saleLot.age?.name} {saleLot.sex?.name}{" "}
              <AverageWeight saleLot={saleLot} />
            </>
          ) : (
            "-"
          )}
        </td>
      </tr>
    </tfoot>
  );
}
