import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";

import { AuctionPenAction } from "actions";

import { ReceivalLotAction } from "actions/receivalLots";

import { deepObjectChanges } from "lib/compare";
import { getLivestockSaleId } from "lib/navigation";

export const useHandleReceivalLotSubmission = () => {
  const formikProps = useFormikContext();

  const { initialValues, values } = formikProps;

  const dispatch = useDispatch();

  const submitReceivalLot = ({
    receivalPenData,
    receivalLotId,
    receivalPenId,
    changeReasonLocation,
    selectedReceivalLotId,
    newReceivalLotId,
    allowCreate, // this check is to ensure the create only fires where we intend it to, not on initialization
  }) => {
    if (receivalPenData) {
      dispatch(AuctionPenAction.create(receivalPenData));
    }

    if (receivalLotId) {
      dispatch(
        ReceivalLotAction.update(
          {
            id: receivalLotId,
            ...deepObjectChanges(initialValues, values),
            receivalPenId,
          },

          { changeReason: `Updated from ${changeReasonLocation}.` },
        ),
      );
    } else if (
      allowCreate &&
      (!selectedReceivalLotId || selectedReceivalLotId === newReceivalLotId)
    ) {
      dispatch(
        ReceivalLotAction.create(
          {
            livestockSaleId: getLivestockSaleId(),
            ...values,
            receivalPenId,
            id: newReceivalLotId,
          },
          { changeReason: `Created from ${changeReasonLocation}.` },
        ),
      );
    }
  };
  return submitReceivalLot;
};
