import { useLayoutEffect, useRef } from "react";

import { useCounter } from "hooks/useCounter";

import {
  cancelServiceRequest,
  getPlaceAutocompleteService,
  getPlaceAutocompleteSessionToken,
  getPlaceDetailsService,
  requestPlaceAutocompleteService,
  requestPlaceAutocompleteSessionToken,
  requestPlaceDetailsService,
} from "./serviceManager";

function serviceFactory(getService, requestService) {
  return function useGoogleMapsService() {
    // store the `setState` function as a hook to invoke a re-render
    const invokeRender = useCounter()[1];

    const serviceRef = useRef(getService());

    useLayoutEffect(
      () => {
        function onServiceAvailable() {
          serviceRef.current = getService();
          invokeRender();
        }

        function onServiceError(error) {
          // eslint-disable-next-line no-console
          console.error(error);
          serviceRef.current = getService();
          invokeRender();
        }
        // only request a new service in case of service not available
        if (serviceRef.current === null) {
          requestService(onServiceAvailable, onServiceError);
          // if the component that this hook was called from is unmounted before the suggestion service has loaded,
          return () => {
            // clear the handlers to avoid memory leaks
            cancelServiceRequest(onServiceAvailable, onServiceError);
          };
        }
      },
      // Run only on init
      [invokeRender, serviceRef],
    );

    return serviceRef.current;
  };
}

/**
 * @type {function(): google.maps.places.AutocompleteService}
 */
export const usePlaceAutocompleteService = serviceFactory(
  getPlaceAutocompleteService,
  requestPlaceAutocompleteService,
);

/**
 * @type {function(): google.maps.places.PlacesService}
 */
export const usePlaceDetailsService = serviceFactory(
  getPlaceDetailsService,
  requestPlaceDetailsService,
);

/**
 * @type {function(): google.maps.places.AutocompleteSessionToken}
 */
export const usePlaceAutocompleteSessionToken = serviceFactory(
  getPlaceAutocompleteSessionToken,
  requestPlaceAutocompleteSessionToken,
);
