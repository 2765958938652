import React from "react";

import { ModalTypes } from "constants/navigation";

import ScanningScreenView from "containers/Scans";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

const ScanningModalAdapter = props => {
  const { hash } = props;
  const hashParams = hash[ModalTypes.Scan] || null;
  const {
    consignmentId,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    resume,
    mode,
    penType,
  } = JSON.parse(hashParams);
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";

  const closeSelf = () => {
    if (decodedReturnTo) {
      returnToLocation(decodedReturnTo);
    } else {
      closeAllHashModals();
    }
  };

  return (
    <ScanningScreenView
      closeSelf={closeSelf}
      consignmentId={consignmentId}
      mode={mode}
      resume={resume}
      saleLotId={saleLotId}
      penArchetypeId={penArchetypeId}
      penId={penId}
      scanLotId={scanLotId}
      penType={penType}
    />
  );
};

export default ScanningModalAdapter;
