import styled from "styled-components/macro";

import { Row } from "components/Layout";
import { BoldText } from "components/Text";

export const Percentage = styled(BoldText)(
  ({ theme, color }) => `
  color: ${theme.colors[color]};
  padding-left: ${theme.space[1]}px;
  text-align: right;
`,
);

export const ScanCount = styled(BoldText)`
  text-align: right;
`;
export const ScanCountWrapper = styled(Row)(
  ({ theme, color }) => `
padding: ${theme.space[0]}px ${theme.space[1]}px;
border-radius: ${theme.radii[2]}px;
background-color: ${theme.colors[color] || "initial"}; 
`,
);
