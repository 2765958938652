import { SALEYARD_ADMIN } from "constants/actionTypes";

import { serializeSaleyardAdmin } from "lib/serializers/saleyardAdmin";

import {
  offlineActionCreator,
  urlCheckAndAddIdAndAction,
  reportFavorouriteActionCreator,
} from "./lib";

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/saleyard-admins/", id, action);

export const SaleyardAdminAction = offlineActionCreator(
  SALEYARD_ADMIN,
  serializeSaleyardAdmin,
  urlCreator,
  false,
);

SaleyardAdminAction.reinvite = function reinvite(id) {
  return {
    type: SALEYARD_ADMIN.REINVITE.REQUEST,
    meta: {
      offline: {
        effect: {
          url: urlCreator({ id, action: "reinvite" }),
          method: "POST",
        },
        commit: {
          type: SALEYARD_ADMIN.REINVITE.SUCCESS,
        },
        rollback: {
          type: SALEYARD_ADMIN.REINVITE.FAILURE,
        },
      },
    },
  };
};

SaleyardAdminAction.addReportFavourite = reportFavorouriteActionCreator(
  SALEYARD_ADMIN.ADD_REPORT_FAVOURITE,
  "POST",
  "saleyard-admins",
);
SaleyardAdminAction.removeReportFavourite = reportFavorouriteActionCreator(
  SALEYARD_ADMIN.REMOVE_REPORT_FAVOURITE,
  "DELETE",
  "saleyard-admins",
);
