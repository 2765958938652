export function nvdHCFormatter({ value, data }) {
  if (!data) {
    return null;
  }

  const hc = parseInt(value, 10);
  if (Number.isNaN(hc) === false) {
    return hc;
  } else {
    return "-";
  }
}
