import React, { memo, useCallback, useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import styled from "styled-components/macro";

import { Column } from "components/Layout";
import ConsignmentDetailHeader from "components/ScanningScreen/ConsignmentDetailHeader";
import { EmptyStateText } from "components/ScanningScreen/Elements";
import SaleLotDetailHeader from "components/ScanningScreen/SaleLotDetailHeader";
import ScanLotScanningHeader from "components/ScanningScreen/ScanLotScanningHeader";
import ScannerSelect from "components/ScanningScreen/ScannerSelect";
import {
  buildDuplicatePenScanEidsList,
  buildDuplicateTakeableEidList,
} from "components/ScanningScreen/ScanningColumn";
import ScanningSummaryHeader from "components/ScanningScreen/ScanningSummaryHeader";

import { scannerMessages } from "constants/scanner";
import { userTypes } from "constants/users";

import { scanLotConfig } from "lib/scanLot";

import {
  getActiveRole,
  getConnectedDeviceId,
  getConsignments,
  getImportedScans,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScannerCapabilities,
  getScans,
  selectTakeFilesByConsignmentIdLookup,
} from "selectors";

import { useBoolean } from "hooks";
import { useGetReceivalScannedDeploymentIds } from "hooks/useGetReceivalScannedDeploymentIds";

import SessionImportColumn from "./SessionImportColumn";
import ImportView from "./SessionImportView";

const MESSAGE_IMPORT_NOT_SUPPORTED =
  "Please connect to a supported device to import sessions.";
const MESSAGE_NO_SCANS_IMPORTED = "No scans were selected to import.";

const IMPORT_MODE = {
  IMPORT: 0,
  DISPLAY: 1,
};

const getImportHasScans = createSelector(
  [getImportedScans],
  importedScans => Object.values(importedScans).length > 0,
);

const getScannerSupportsImport = createSelector(
  [getScannerCapabilities],
  scannerCapabilities =>
    scannerCapabilities.includes(scannerMessages.READ_SAVED_SCANS),
);

const ImportContainer = styled(Column).attrs({
  flexGrow: 1,
  background: "white",
})`
  height: calc(100vh - 80px);
  overflow: hidden;
`;

export function SessionImportComponent(props) {
  const {
    consignmentId,
    onCancel,
    onOpenClearModal,
    onOpenClearSavedModal,
    onOpenCreateBulkModal,
    onOpenCreateModal,
    onOpenSelectModal,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    scanListType,
  } = props;
  const connectedDeviceId = useSelector(getConnectedDeviceId);
  const scannerSupportsImport = useSelector(getScannerSupportsImport);
  const importHasSessions = useSelector(getImportHasScans);

  const [mode, setMode] = useState(IMPORT_MODE.IMPORT);

  const [
    isScanDiscrepancyDialogVisible,
    showScanDiscrepancyDialog,
    hideScanDiscrepancyDialog,
  ] = useBoolean(false);

  const consignments = useSelector(getConsignments);
  const userRole = useSelector(getActiveRole);
  const importedScans = useSelector(getImportedScans);
  const saleLotScans = useSelector(getScans);
  const saleLots = useSelector(getSaleLots);
  const saleyardScanSaleLots = useSelector(getSaleyardScanSaleLots);
  const takeFiles = useSelector(selectTakeFilesByConsignmentIdLookup);

  const isForReceivalOrPenScanning = !!penArchetypeId || !!penId || !!scanLotId;

  const { selectLotIdByEidLookup, getEidsByLotId } = scanLotConfig(penType);

  const scanLotIdByEid = useSelector(selectLotIdByEidLookup);
  const scanLotEids = useSelector(getEidsByLotId(scanLotId)).filter(Boolean);

  const disallowTaken = userRole.type === userTypes.SCALE_OPERATOR;
  const { duplicateEids, eids, keepableEids, hiddenEids } =
    isForReceivalOrPenScanning
      ? buildDuplicatePenScanEidsList(
          Object.values(importedScans),
          saleLotScans,
          "eid",
          "ignoreDuplicate",
          penType,
          scanLotId,
          scanLotIdByEid,
        )
      : buildDuplicateTakeableEidList(
          Object.values(importedScans),
          saleLotScans,
          saleLots,
          saleyardScanSaleLots,
          consignments,
          takeFiles,
          "eid",
          "ignoreDuplicate",
          disallowTaken,
          consignmentId,
        );

  const onEidsSelected = useCallback(
    () => setMode(IMPORT_MODE.DISPLAY),
    [setMode],
  );

  const receivalScannedDeploymentIds = useGetReceivalScannedDeploymentIds(
    scanLotEids.concat(eids),
  );

  useEffect(() => {
    if (connectedDeviceId && scannerSupportsImport && !importHasSessions) {
      setMode(IMPORT_MODE.IMPORT);
    }
  }, [connectedDeviceId, importHasSessions, scannerSupportsImport]);

  if (!connectedDeviceId) {
    return <ScannerSelect resumeScan onCancel={onCancel} />;
  }

  if (connectedDeviceId && !scannerSupportsImport) {
    return (
      <>
        <ConsignmentDetailHeader consignmentId={consignmentId} />
        <SaleLotDetailHeader saleLotId={saleLotId} />

        <ScanLotScanningHeader
          penArchetypeId={penArchetypeId}
          penId={penId}
          scanLotId={scanLotId}
          penType={penType}
          duplicateEids={duplicateEids}
          showScanDiscrepancyDialog={showScanDiscrepancyDialog}
          receivalScannedDeploymentIds={receivalScannedDeploymentIds}
        />
        <ScanningSummaryHeader showTotal={false} />
        <EmptyStateText>{MESSAGE_IMPORT_NOT_SUPPORTED}</EmptyStateText>
      </>
    );
  }

  if (mode === IMPORT_MODE.IMPORT) {
    return (
      <ImportContainer>
        <ConsignmentDetailHeader consignmentId={consignmentId} />
        <SaleLotDetailHeader saleLotId={saleLotId} />
        <ScanLotScanningHeader
          penArchetypeId={penArchetypeId}
          penId={penId}
          scanLotId={scanLotId}
          penType={penType}
          duplicateEids={duplicateEids}
          showScanDiscrepancyDialog={showScanDiscrepancyDialog}
          receivalScannedDeploymentIds={receivalScannedDeploymentIds}
        />
        <ImportView onEidsSelected={onEidsSelected} />
      </ImportContainer>
    );
  }

  if (mode === IMPORT_MODE.DISPLAY) {
    return (
      <>
        <ConsignmentDetailHeader consignmentId={consignmentId} />
        <SaleLotDetailHeader saleLotId={saleLotId} />
        <ScanLotScanningHeader
          penArchetypeId={penArchetypeId}
          penId={penId}
          scanLotId={scanLotId}
          penType={penType}
          duplicateEids={duplicateEids}
          showScanDiscrepancyDialog={showScanDiscrepancyDialog}
          receivalScannedDeploymentIds={receivalScannedDeploymentIds}
        />
        <ScanningSummaryHeader showTotal={false} />
        {importHasSessions ? (
          <SessionImportColumn
            consignmentId={consignmentId}
            closeSelf={onCancel}
            onOpenCreateBulkModal={onOpenCreateBulkModal}
            onOpenCreateModal={onOpenCreateModal}
            onOpenClearModal={onOpenClearModal}
            onOpenClearSavedModal={onOpenClearSavedModal}
            onOpenSelectModal={onOpenSelectModal}
            saleLotId={saleLotId}
            scanListType={scanListType}
            penArchetypeId={penArchetypeId}
            penId={penId}
            scanLotId={scanLotId}
            penType={penType}
            eids={eids}
            keepableEids={keepableEids}
            duplicateEids={duplicateEids}
            isScanDiscrepancyDialogVisible={isScanDiscrepancyDialogVisible}
            showScanDiscrepancyDialog={showScanDiscrepancyDialog}
            hideScanDiscrepancyDialog={hideScanDiscrepancyDialog}
            hiddenEids={hiddenEids}
          />
        ) : (
          <EmptyStateText>{MESSAGE_NO_SCANS_IMPORTED}</EmptyStateText>
        )}
      </>
    );
  }
  // eslint-disable-next-line no-console
  console.error("Invalid Session Import display mode '%s'", mode);
  return null;
}
SessionImportComponent.propTypes = {
  consignmentId: PropTypes.string,
  onCancel: PropTypes.func,
  onOpenClearModal: PropTypes.func,
  onOpenCreateModal: PropTypes.func,
  onOpenSelectModal: PropTypes.func,
  saleLotId: PropTypes.string,
};

export default memo(SessionImportComponent);
