import reduceReducers from "reduce-reducers";

import { BILLING_RUN, EMAIL } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeEmail } from "lib/serializers/email";

const fetch = apiModelOfflineFetchReducer(EMAIL, {
  deserializer: deserializeEmail,
});

function fetchFiltersReducer(state, action) {
  switch (action.type) {
    case EMAIL.FETCH_BULK.REQUEST:
      return {
        ...state,
        filters: action.meta.offline.commit.meta.options,
      };
    case BILLING_RUN.SUBSCRIBE.ACTION:
      return {
        ...state,
        filters: {
          billing_document_email_jobs__billing_document__billing_run__cbid:
            action.id,
        },
      };
    case BILLING_RUN.UNSUBSCRIBE.ACTION:
      return {
        ...state,
        filters: null,
      };
    default:
      return state;
  }
}

const emailReducer = reduceReducers(fetch, fetchFiltersReducer);

export default emailReducer;
