import React, { memo } from "react";

import {
  CurrentLotBodyLayout,
  CurrentLotLayoutItem,
  LotDetailsAreaLayout,
} from "./Layout";
import WeighBridgeCurrentLotForm from "./WeighBridgeCurrentLotForm";
import WeighBridgeCurrentLotView from "./WeighBridgeCurrentLotView";
import WeighBridgeScanning from "./WeighBridgeScanning";
import WeighBridgeWeighing from "./WeighBridgeWeighing";

function WeighBridgeCurrentLotBodyComponent() {
  return (
    <CurrentLotBodyLayout>
      <CurrentLotLayoutItem gridArea="hub-weighing">
        <WeighBridgeWeighing />
      </CurrentLotLayoutItem>

      <LotDetailsAreaLayout id="current-lot-details-layout">
        <CurrentLotLayoutItem gridArea="lot-form">
          <WeighBridgeCurrentLotView />
          <WeighBridgeCurrentLotForm />
        </CurrentLotLayoutItem>
        <CurrentLotLayoutItem gridArea="hub-scanning">
          <WeighBridgeScanning />
        </CurrentLotLayoutItem>
      </LotDetailsAreaLayout>
    </CurrentLotBodyLayout>
  );
}

export default memo(WeighBridgeCurrentLotBodyComponent);
