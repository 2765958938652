import { intersection, uniq } from "lodash";
import { createSelector } from "reselect";

import { ValueSource } from "constants/ruleBooks";

import {
  getLedgerAccounts,
  getMasterLedgerAccounts,
  getRuleBooks,
  getRules,
  selectUnmappedMasterLedgerAccountIds,
} from "selectors";

const invertByCode = (acc, ledgerAccount) => {
  acc[ledgerAccount.code] = ledgerAccount;
  return acc;
};

// Find the codes that are actually required based on use in rules.
const selectLedgerAccountCodesFromRules = createSelector(
  [getRules, getRuleBooks],
  (rulesLookup, ruleBookLookup) =>
    uniq(
      Object.values(rulesLookup)
        .filter(rule => !ruleBookLookup[rule.rule_book_id].is_archived)
        .map(rule => rule.gl_code)
        .filter(
          glCode => glCode.source === ValueSource.CONSTANT && glCode.value,
        )
        .map(glCode => glCode.value),
    ),
);

export const selectUnusedMasterLedgerAccountsAgGridData = createSelector(
  [
    getMasterLedgerAccounts,
    selectUnmappedMasterLedgerAccountIds,
    selectLedgerAccountCodesFromRules,
  ],
  (
    masterLedgerAccountsLookup,
    unmappedMasterLedgerAccountIds,
    ledgerAccountCodesInUse,
  ) =>
    unmappedMasterLedgerAccountIds
      .map(id => {
        const masterAccount = masterLedgerAccountsLookup[id];
        if (!ledgerAccountCodesInUse.includes(masterAccount.code)) {
          return {
            ...masterAccount,
            inUse: false,
            id: null,
            code: null,
            masterLedgerAccountCodes: [masterAccount.code],
            masterLedgerAccountIds: [masterAccount.id],
          };
        } else {
          return null;
        }
      })
      .filter(Boolean),
);

export const selectLedgerAccountsAgGridData = createSelector(
  [
    getLedgerAccounts,
    getMasterLedgerAccounts,
    selectLedgerAccountCodesFromRules,
    selectUnusedMasterLedgerAccountsAgGridData,
  ],
  (
    ledgerAccountsLookup,
    masterLedgerAccountsLookup,
    ledgerAccountCodesInUse,
    unusedMasterLedgerAccountsAgGridData,
  ) => {
    const ledgerAccountByCodeLookup = Object.values(
      ledgerAccountsLookup,
    ).reduce(invertByCode, {});

    const masterLedgerAccountByCodeLookup = Object.values(
      masterLedgerAccountsLookup,
    ).reduce(invertByCode, {});

    // Marry these up with the ledger accounts I have created.
    const myLedgerAccountCodes = Object.keys(ledgerAccountByCodeLookup);

    const accountCodes = uniq(
      ledgerAccountCodesInUse.concat(myLedgerAccountCodes),
    );

    const mappedMasterLedgerAccountCodes = Object.values(
      ledgerAccountsLookup,
    ).reduce((acc, ledgerAccount) => {
      ledgerAccount.masterLedgerAccountIds.forEach(masterLedgerAccountId => {
        if (masterLedgerAccountsLookup[masterLedgerAccountId]) {
          acc.push(masterLedgerAccountsLookup[masterLedgerAccountId].code);
        }
      });
      return acc;
    }, []);

    return unusedMasterLedgerAccountsAgGridData.concat(
      accountCodes
        .map(code => {
          const myAccount = ledgerAccountByCodeLookup[code];
          const masterAccount = masterLedgerAccountByCodeLookup[code];
          const mappedLedgerAccounts =
            myAccount?.masterLedgerAccountIds.map(
              masterLedgerAccountId =>
                masterLedgerAccountsLookup[masterLedgerAccountId],
            ) || [];
          // Is this code used in a rule and does it exist in either a ledgerAccount or a Mapped masterLedgerAccount
          const inUse =
            intersection(ledgerAccountCodesInUse, [
              code,
              ...mappedLedgerAccounts
                .map(masterLedgerAccount => masterLedgerAccount.code)
                .filter(Boolean),
            ]).length > 0;

          if (myAccount) {
            return {
              ...myAccount,
              inUse,
              masterLedgerAccountCodes: mappedLedgerAccounts.map(
                mappedLedgerAccount => mappedLedgerAccount.code,
              ),
            };
          } else if (mappedMasterLedgerAccountCodes.includes(code)) {
            // If the code has been mapped, we don't need to create a blank row for it.
            return null;
          } else if (masterAccount) {
            // The code exists in a rule in and has a master ledger account, but not local one.
            return {
              ...masterAccount,
              inUse,
              id: null,
              code: null,
              masterLedgerAccountCodes: [code],
              masterLedgerAccountIds: [masterAccount.id],
            };
          } else {
            // The code has somehow got itself into a rule.
            return { code, inUse };
          }
        })
        .filter(Boolean),
    );
  },
);
