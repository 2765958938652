export const deserializeAgency = agency => {
  const { id, name, short_name, agency_groups } = agency;

  return {
    id,
    name,
    shortName: short_name,
    agencyGroups: agency_groups,
  };
};
