import React from "react";

import queryString from "query-string";

/**
 * Renders the `component` property Component with the props provided by
 * `componentProps` as well as those provided by a routed component including
 * match, staticContext, location, history.
 * @param {React.Component} props.component
 * @param {Object} props.componentProps
 * @param {String} props.hash e.g. "edit-sale-lot"
 * @returns {React.Component}
 */
function HashRoute(props) {
  const { component: Component, componentProps, hash: hashMatch } = props;
  // Spread the result onto a new object, as the returned value has a `null`
  // prototype and therefore doesn't have the `hasOwnProperty` method on it
  const hash = {
    ...queryString.parse(
      window.location.hash,
      {
        parseBooleans: true,
        parseNumbers: true,
      },
      {
        arrayFormat: "comma",
      },
    ),
  };
  const renderComponentProps = {
    hash,
    modalType: hashMatch,
    ...componentProps,
  };

  if (!hash.hasOwnProperty(hashMatch)) {
    return null;
  }
  return <Component {...renderComponentProps} />;
}

export default HashRoute;
