import React, { useState } from "react";

import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { Popover } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

import FontAwesomeButton, {
  ButtonIcon,
} from "components/Button/FontAwesomeButton";
import { StyledCheckbox } from "components/Form/FormikControls";

import { ALL_DUPLICATE_ATTRIBUTES, DuplicateAttributeType } from "./util";

export function Filters(props) {
  const { onAfterFiltersChanged } = props;

  const [activeFilters, setActiveFilters] = useState(ALL_DUPLICATE_ATTRIBUTES);

  const [filtersButtonEl, setFiltersButtonEl] = useState(null);

  const isFiltersVisible = Boolean(filtersButtonEl);

  function toggleFiltersVisible(event) {
    setFiltersButtonEl(isFiltersVisible ? null : event.target);
  }

  function onMuiCheckboxChanged(event) {
    const index = activeFilters.indexOf(event.target.name);

    const nextActiveFilters = [...activeFilters];
    if (index > -1) {
      nextActiveFilters.splice(index, 1);
    } else {
      nextActiveFilters.push(event.target.name);
    }
    setActiveFilters(nextActiveFilters);

    typeof onAfterFiltersChanged === "function" &&
      onAfterFiltersChanged(nextActiveFilters);
  }

  function closeFilters() {
    setFiltersButtonEl(null);
  }

  const addressCheckboxControl = (
    <StyledCheckbox
      checked={activeFilters.includes(
        DuplicateAttributeType.GOOGLE_MAPS_PLACE_ID,
      )}
      onChange={onMuiCheckboxChanged}
      name={DuplicateAttributeType.GOOGLE_MAPS_PLACE_ID}
    />
  );
  const abnCheckboxControl = (
    <StyledCheckbox
      checked={activeFilters.includes(DuplicateAttributeType.ABN)}
      onChange={onMuiCheckboxChanged}
      name={DuplicateAttributeType.ABN}
    />
  );
  const auctionsPlusCheckboxControl = (
    <StyledCheckbox
      checked={activeFilters.includes(
        DuplicateAttributeType.AUCTION_PLUS_SHORT_CODE,
      )}
      onChange={onMuiCheckboxChanged}
      name={DuplicateAttributeType.AUCTION_PLUS_SHORT_CODE}
    />
  );
  const emailCheckboxControl = (
    <StyledCheckbox
      checked={activeFilters.includes(DuplicateAttributeType.EMAIL_RECIPIENT)}
      onChange={onMuiCheckboxChanged}
      name={DuplicateAttributeType.EMAIL_RECIPIENT}
    />
  );
  const nameCheckboxControl = (
    <StyledCheckbox
      checked={activeFilters.includes(DuplicateAttributeType.NAME)}
      onChange={onMuiCheckboxChanged}
      name={DuplicateAttributeType.NAME}
    />
  );
  const propertyCheckboxControl = (
    <StyledCheckbox
      checked={activeFilters.includes(DuplicateAttributeType.PROPERTY)}
      onChange={onMuiCheckboxChanged}
      name={DuplicateAttributeType.PROPERTY}
    />
  );
  return (
    <>
      <FontAwesomeButton onClick={toggleFiltersVisible}>
        <ButtonIcon icon={faFilter} />
      </FontAwesomeButton>
      <Popover
        open={isFiltersVisible}
        anchorEl={filtersButtonEl}
        onClose={closeFilters}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>Include matches for:</h3>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={addressCheckboxControl}
              label="Address"
            />
            <FormControlLabel control={abnCheckboxControl} label="ABN" />
            <FormControlLabel
              control={auctionsPlusCheckboxControl}
              label="AuctionsPlus Short Code"
            />
            <FormControlLabel
              control={emailCheckboxControl}
              label="Email Address"
            />
            <FormControlLabel control={nameCheckboxControl} label="Name" />
            <FormControlLabel control={propertyCheckboxControl} label="PIC" />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
