import React, { useState } from "react";

import styled from "styled-components";

import { Button } from "components/Form";
import { StyledTextArea } from "components/Form/FormikControls";
import { Column } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

const TextArea = styled(StyledTextArea)`
  height: auto;
  flex: 1;
`;

export const ReviewAndSend = ({ onSend, onClose }) => {
  const [emailText, setEmailText] = useState("");

  const sendWithText = () => {
    onSend(emailText);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>
        Add a message to your customers. This will be sent with the invoice.
      </DialogTitle>
      <DialogContent dividers>
        <Column full minHeight="30vh">
          <TextArea onChange={e => setEmailText(e.target.value)} />
        </Column>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={sendWithText}>Send</Button>
      </DialogActions>
    </Dialog>
  );
};
