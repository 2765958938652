import React from "react";

import { Grid } from "@material-ui/core";

export const DraftPensUnavailableAction = () => {
  return (
    <Grid container spacing={2}>
      Empty a draft pen to continue.
    </Grid>
  );
};
