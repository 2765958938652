import { sortBy } from "lodash";
import { createSelector } from "reselect";

import { DocumentTypes } from "constants/documentTypes";
import { RESIZABLE_IMAGE_EXTENSIONS } from "constants/files";

import { extractFileExtensionFromURL } from "lib";

import {
  getFiles,
  selectAttachmentIdsByConsignmentIdLookup,
  selectAttachmentIdsBySaleLotIdLookup,
  selectIsDeclarationCompleteByConsignmentIdLookup,
} from "selectors";

export const selectIsCompleteByAttachmentIdLookup = createSelector(
  [getFiles, selectIsDeclarationCompleteByConsignmentIdLookup],
  (files, isDeclarationCompleteByConsignmentId) =>
    Object.entries(files).reduce((acc, [fileId, file]) => {
      let isComplete = Boolean(file.type);

      if (file.type === DocumentTypes.NVD) {
        isComplete = Boolean(
          file.consignment &&
            isDeclarationCompleteByConsignmentId[file.consignment],
        );
      } else if (
        file.type === DocumentTypes.ANIMAL_HEALTH_STATEMENT ||
        file.type === DocumentTypes.OTHER
      ) {
        isComplete = Boolean(file.consignment);
      }

      acc[fileId] = isComplete;
      return acc;
    }, {}),
);

export const getFileById = fileId => state => getFiles(state)[fileId] || null;

export const selectAttachmentsBySaleLotId = createSelector(
  [selectAttachmentIdsBySaleLotIdLookup, getFiles],
  (attachmentsIdsBySaleLotId, attachmentByIdLookup) =>
    Object.entries(attachmentsIdsBySaleLotId).reduce(
      (acc, [saleLotId, attachmentIds]) => {
        const attachments = attachmentIds.map(
          attachmentId => attachmentByIdLookup[attachmentId],
        );

        const sortedAttachments = sortBy(attachments, "order");
        acc[saleLotId] = sortedAttachments;
        return acc;
      },
      {},
    ),
);

export const selectAttachmentsByConsignmentId = createSelector(
  [selectAttachmentIdsByConsignmentIdLookup, getFiles],
  (attachmentsIdsByConsignmentId, attachmentByIdLookup) =>
    Object.entries(attachmentsIdsByConsignmentId).reduce(
      (acc, [consignmentId, attachmentIds]) => {
        const attachments = attachmentIds.map(
          attachmentId => attachmentByIdLookup[attachmentId],
        );

        const sortedAttachments = sortBy(attachments, "order");
        acc[consignmentId] = sortedAttachments;
        return acc;
      },
      {},
    ),
);

export const selectThumbnailUrlBySaleLotIdLookup = createSelector(
  [selectAttachmentIdsBySaleLotIdLookup, getFiles],
  (attachmentsIdsBySaleLotId, attachmentByIdLookup) =>
    Object.entries(attachmentsIdsBySaleLotId).reduce(
      (acc, [saleLotId, attachmentIds]) => {
        acc[saleLotId] = sortBy(
          attachmentIds
            .map(attachmentId => attachmentByIdLookup[attachmentId])
            .filter(
              attachment =>
                attachment &&
                attachment.isFinished !== false &&
                attachment.type === DocumentTypes.IMAGE &&
                RESIZABLE_IMAGE_EXTENSIONS.includes(
                  extractFileExtensionFromURL(attachment.image_url),
                ),
            ),
          "order",
        )?.[0]?.thumbnail_url;
        return acc;
      },
      {},
    ),
);

export const getThumbnailUrlBySaleLotId = saleLotId => state =>
  selectThumbnailUrlBySaleLotIdLookup(state)[saleLotId] || null;
