import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";

import {
  P2P_FILE,
  P2P_REVERSAL,
  SELL_FILE,
  SELL_REVERSAL,
  SIGHTING_FILE,
  TAKE_FILE,
  TAKE_REVERSAL,
} from "constants/actionTypes";
import { TRANSFER_STATUS } from "constants/nlis";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelReducer,
} from "lib/reducers";
import {
  deserializeP2PFile,
  deserializeP2PReversal,
  deserializeSellFile,
  deserializeSellReversal,
  deserializeSighting,
  deserializeTakeFile,
  deserializeTakeReversal,
} from "lib/serializers/nlis";

const takeFileFetchBulk = apiModelReducer("takeFiles", TAKE_FILE.FETCH_BULK, {
  deserializer: deserializeTakeFile,
  includeOrder: false,
  clearOnRequest: true,
});

const takeFileFetch = apiModelReducer("takeFiles", TAKE_FILE.FETCH, {
  deserializer: deserializeTakeFile,
  includeOrder: false,
  clearOnRequest: false,
});

const takeFileOfflineCreate = apiModelOfflineCreateReducer(TAKE_FILE, {
  deserializer: deserializeTakeFile,
});

const takeFileOfflineDelete = apiModelOfflineDeleteReducer(TAKE_FILE);

const takeFiles = reduceReducers(
  takeFileFetchBulk,
  takeFileFetch,
  takeFileOfflineCreate,
  takeFileOfflineDelete,
);

const takeReversalsFetchBulk = apiModelReducer(
  "takeReversals",
  TAKE_REVERSAL.FETCH_BULK,
  {
    deserializer: deserializeTakeReversal,
    includeOrder: false,
    clearOnRequest: true,
  },
);

const takeReversalsFetch = apiModelReducer(
  "takeReversals",
  TAKE_REVERSAL.FETCH,
  {
    deserializer: deserializeTakeReversal,
    includeOrder: false,
    clearOnRequest: false,
  },
);

const takeReversalsOfflineCreate = apiModelOfflineCreateReducer(TAKE_REVERSAL, {
  deserializer: deserializeTakeReversal,
});

const takeReversals = reduceReducers(
  takeReversalsFetch,
  takeReversalsFetchBulk,
  takeReversalsOfflineCreate,
);

const sellFileFetchBulk = apiModelReducer("sellFiles", SELL_FILE.FETCH_BULK, {
  deserializer: deserializeSellFile,
  includeOrder: false,
  clearOnRequest: true,
});

const sellFileFetch = apiModelReducer("sellFiles", SELL_FILE.FETCH, {
  deserializer: deserializeSellFile,
  includeOrder: false,
  clearOnRequest: false,
});

const sellFileOfflineCreate = apiModelOfflineCreateReducer(SELL_FILE, {
  deserializer: deserializeSellFile,
});

const sellFileOfflineDelete = apiModelOfflineDeleteReducer(SELL_FILE, {
  deserializer: deserializeSellFile,
});

const sellFiles = reduceReducers(
  sellFileFetchBulk,
  sellFileFetch,
  sellFileOfflineCreate,
  sellFileOfflineDelete,
);

const sellReversalsFetchBulk = apiModelReducer(
  "sellReversals",
  SELL_REVERSAL.FETCH_BULK,
  {
    deserializer: deserializeSellReversal,
    includeOrder: false,
    clearOnRequest: true,
  },
);

const sellReversalsFetch = apiModelReducer(
  "sellReversals",
  SELL_REVERSAL.FETCH,
  {
    deserializer: deserializeSellReversal,
    includeOrder: false,
    clearOnRequest: false,
  },
);

const sellReversalsOfflineCreate = apiModelOfflineCreateReducer(SELL_REVERSAL, {
  deserializer: deserializeSellReversal,
});

const sellReversals = reduceReducers(
  sellReversalsFetch,
  sellReversalsFetchBulk,
  sellReversalsOfflineCreate,
);

const createSightingFile = apiModelOfflineCreateReducer(SIGHTING_FILE, {
  deserializer: deserializeSighting,
});

const fetchSightingFiles = apiModelOfflineFetchReducer(SIGHTING_FILE, {
  deserializer: deserializeSighting,
});

const sightingFiles = reduceReducers(createSightingFile, fetchSightingFiles);

const createP2PFiles = apiModelOfflineCreateReducer(P2P_FILE, {
  deserializer: deserializeP2PFile,
});

const deleteP2PFiles = apiModelOfflineDeleteReducer(P2P_FILE, {
  deserializer: deserializeP2PFile,
});
const fetchP2PFiles = apiModelOfflineFetchReducer(P2P_FILE, {
  deserializer: deserializeP2PFile,
});

const p2pFileReversalReducer = (state, action) => {
  switch (action.type) {
    case P2P_REVERSAL.CREATE.REQUEST:
      // Custom handler to flag the P2P as pending rollback when a reversal is created.
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.reversal_of]: {
            ...state.byId[action.payload.reversal_of],
            status: TRANSFER_STATUS.PENDING_ROLLBACK,
          },
        },
      };
    default:
      return state;
  }
};

const p2pFiles = reduceReducers(
  createP2PFiles,
  fetchP2PFiles,
  deleteP2PFiles,
  p2pFileReversalReducer,
);

const createP2PReversals = apiModelOfflineCreateReducer(P2P_REVERSAL, {
  deserializer: deserializeP2PReversal,
});

const deleteP2PReversals = apiModelOfflineDeleteReducer(P2P_REVERSAL, {
  deserializer: deserializeP2PReversal,
});
const fetchP2PReversals = apiModelOfflineFetchReducer(P2P_REVERSAL, {
  deserializer: deserializeP2PReversal,
});

const p2pReversals = reduceReducers(
  createP2PReversals,
  deleteP2PReversals,
  fetchP2PReversals,
);

const nlisFiles = combineReducers({
  takeFiles,
  sellFiles,
  takeReversals,
  sellReversals,
  sightingFiles,
  p2pFiles,
  p2pReversals,
});

export default nlisFiles;
