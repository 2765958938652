import React from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { addSaleLotWithPens, patchSaleLot } from "actions";

import { Button } from "components/Form/Button";
import { useFormikSubmit } from "components/Form/FormikControls";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { SaleLotModalTypes } from "components/SaleLotForms/constants";
import { ExternalAgentSaleForm } from "components/SaleLotForms/ExternalAgentSale/Form";

import { ModalTypes } from "constants/navigation";

import { calculateTotalPriceCents } from "lib";

import { openModalLink } from "lib/navigation";

import {
  getActiveLivestockAgentDeployment,
  getCurrentSale,
  getSaleLotById,
} from "selectors";

import { useBoolean } from "hooks";

const validationSchema = Yup.lazy(values => {
  const buyerOrAccountNoteValidation = Yup.string()
    .nullable()
    .test("requiredGroup", "Buyer or Account Note required.", () => {
      return values.buyer_id || values.accountNote;
    });

  return Yup.object().shape({
    quantity: Yup.number()
      .integer("Head count must be a whole number.")
      .min(0, "Head count must be 0 or more.")
      .required("Head count required."),
    buyer_id: buyerOrAccountNoteValidation,
    accountNote: buyerOrAccountNoteValidation,
    auction_pen: Yup.object().shape({
      start_pen: Yup.string().required("Pen required."),
    }),
  });
});

const FormActions = ({ setCreateAnotherTrue }) => {
  const { isSubmitEnabled, handleSubmit } = useFormikSubmit(true);
  const save = () => {
    handleSubmit();
  };

  return (
    <>
      <Button disabled={!isSubmitEnabled} onClick={save}>
        Save & Close
      </Button>
      <Button
        disabled={!isSubmitEnabled}
        onClick={() => {
          setCreateAnotherTrue();
          save();
        }}
      >
        Save & New
      </Button>
    </>
  );
};

export const ExternalAgentSaleModal = ({ onClose, formDialogDefaults }) => {
  const dispatch = useDispatch();
  const sale = useSelector(getCurrentSale);

  const [createAnother, setCreateAnotherTrue] = useBoolean(false);

  const onSubmit = values => {
    const valuesWithPrice = {
      ...values,
      total_price_cents: calculateTotalPriceCents({
        unitPrice: values.unit_price ? parseFloat(values.unit_price) : 0,
        quantity: values.quantity,
        pricing_type_id: values.pricing_type_id,
        total_mass_grams: values.total_mass_grams,
      }),
    };
    if (!values.id) {
      const tempId = uuidv4();
      dispatch(addSaleLotWithPens(tempId, valuesWithPrice));
    } else {
      const patch = { id: values.id, ...valuesWithPrice };
      dispatch(patchSaleLot(patch));
    }

    if (createAnother) {
      openModalLink(
        ModalTypes.SaleLot,
        {
          type: SaleLotModalTypes.EXTERNAL_AGENT_SALE,
          vendor_id: values.vendor_id,
          buyer_id: values.buyer_id,
          pricing_type_id: values.pricing_type_id,
          breed_id: values.breed_id,
          sex_id: values.sex_id,
          age_id: values.age_id,
          round_id: values.round_id,
          key: uuidv4(),
        },
        null,
      );
    } else {
      onClose();
    }
  };

  const saleLotId = formDialogDefaults.id;

  const existingValues = useSelector(getSaleLotById(saleLotId)) || {};

  // Default the consignmentId to agencyId to automatically create unknown consignment if required.
  const agencyId = useSelector(
    getActiveLivestockAgentDeployment,
  )?.livestockAgencyId;

  const initialValues = {
    // Defaults for required values
    accountNote: "",
    buyer_id: null,
    quantity: undefined,
    vendor_id: null,
    consignment_id: agencyId,
    // Default from sale
    sale_round_id: sale?.rounds?.[0],
    pricing_type_id: sale?.pricing_type_id,
    // Existing Values
    ...existingValues,
    // Defaults from the hash url.
    ...formDialogDefaults,
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <ZoomyDialog open onClose={onClose}>
        <Form>
          <DialogTitle onClose={onClose}>
            {saleLotId ? "Edit Lot" : "Create Lot"}
          </DialogTitle>
          <DialogContent dividers>
            <ExternalAgentSaleForm />
          </DialogContent>
          <DialogActions>
            <FormActions setCreateAnotherTrue={setCreateAnotherTrue} />
          </DialogActions>
        </Form>
      </ZoomyDialog>
    </Formik>
  );
};
