import React, { memo } from "react";

import PropTypes from "prop-types";

import { useIsMobile } from "hooks";

import DesktopSideBar from "./DesktopSideBar";
import MobileSideBar from "./MobileSideBar";

const ResponsiveSideBar = ({
  ContentElement,
  isOpen,
  setIsOpen,
  isBrandingVisible = true,
  onAfterCloseSidebar,
}) => {
  const SidebarComponent = useIsMobile() ? MobileSideBar : DesktopSideBar;
  return (
    <SidebarComponent
      ContentElement={ContentElement}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isBrandingVisible={isBrandingVisible}
      onAfterCloseSidebar={onAfterCloseSidebar}
    />
  );
};

ResponsiveSideBar.propTypes = {
  ContentElement: PropTypes.elementType,
  isBrandingVisible: PropTypes.bool,
  isOpen: PropTypes.bool,
  onAfterCloseSidebar: PropTypes.func,
  setIsOpen: PropTypes.func,
};

export default memo(ResponsiveSideBar);
