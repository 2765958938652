import React, { useMemo, useState } from "react";

import { Grid } from "@material-ui/core";
import memoizeOne from "memoize-one";
import Headroom from "react-headroom";
import { useDispatch, useSelector } from "react-redux";
import { components, createFilter } from "react-select";
import styled from "styled-components/macro";

import { setSetting } from "actions";

import { HideShowButton } from "components/Button";
import { HeaderRow } from "components/Form";
import { StyledCheckbox } from "components/Form/FormikControls";
import { Row, InformationPanel } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import Select from "components/SearchableSelector/ReactSelect";

import {
  FieldToGroupLabelDescriptionMap,
  FieldToLabelDescriptionMap,
  GlobalIntegrationsBooleanFields,
  GlobalSearchBooleanFields,
  GlobalSearchBooleanLabels,
  GlobalSearchFields,
  GlobalSearchPlaceholder,
  MiscellaneousLabel,
} from "constants/globalSearch";
import { ApiModel } from "constants/loading";
import { PossibleSixteenDigitEidInput } from "constants/scanner";
import { Settings } from "constants/settings";

import { RoundSelector } from "containers/ConnectedSelector";

import { parseNlisId } from "lib/nlisId";

import {
  getSetting,
  selectGlobalSearchOptions,
  selectSaleSearchOptions,
} from "selectors";

import { useIsDesktop, useTheme, useToggle } from "hooks";

const HeaderContainer = styled(Grid)`
  flex-flow: wrap-reverse !important;
`;

const ComponentContainer = styled(Grid).withConfig({
  shouldForwardProp: prop => prop !== "includeroundselector",
})(
  ({ includeroundselector }) =>
    `
  flex-basis: auto !important;
  flex-flow: ${includeroundselector ? "row" : "row-reverse"};
  `,
);

export function GlobalSearchHeader(props) {
  const {
    actionButton,
    searchSize,
    buttonSize,
    includeRoundSelector,
    onBasicSearchTextChanged,
    showAllRounds = false,
    showBasicSearch = false,
    showFacetedSearch = true,
  } = props;
  const [pinned, setPinned] = useState(false);
  const isDesktop = useIsDesktop();
  const { colors } = useTheme();

  function onChangedBasicSearchText(event) {
    const { value } = event.target;
    const valueNormalised = value.trim();
    typeof onBasicSearchTextChanged === "function" &&
      onBasicSearchTextChanged(valueNormalised);
  }

  return (
    <Headroom
      onPin={() => setPinned(true)}
      onUnfix={() => setPinned(false)}
      style={{
        boxShadow: "1px 1px 1px rgba(0,0,0,0.25)",
        backgroundColor: colors.surfaceBackground,
        paddingLeft: isDesktop && pinned ? "80px" : "5px",
        paddingRight: "5px",
        zIndex: 100,
      }}
      pinStart={100} // offset the header
      parent={() => document.getElementById("main-wrapper")}
    >
      <HeaderRow>
        <HeaderContainer
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          container
          spacing={1}
        >
          {showFacetedSearch && (
            <Grid
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              container
              item
              xs={12}
              sm={includeRoundSelector ? 12 : searchSize}
            >
              <GlobalSearch />
            </Grid>
          )}
          <ComponentContainer
            includeroundselector={includeRoundSelector}
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            container
            item
            xs={12}
            sm={includeRoundSelector ? 12 : buttonSize}
            spacing={1}
          >
            {includeRoundSelector && (
              <WaitForSync requiredData={[ApiModel.ROUNDS]}>
                <Grid item>
                  <RoundSelector includeAll={showAllRounds} />
                </Grid>
              </WaitForSync>
            )}
            {showBasicSearch && (
              <input
                className="p-2"
                name="basicSearch"
                onChange={onChangedBasicSearchText}
                type="text"
              />
            )}
            {actionButton && <Grid item>{actionButton}</Grid>}
          </ComponentContainer>
        </HeaderContainer>
      </HeaderRow>
    </Headroom>
  );
}

function MultiValueLabel(props) {
  const {
    data: {
      label,
      value: { field },
    },
  } = props;

  // TODO - should we colour code these?
  return (
    <components.MultiValueLabel {...props}>
      {FieldToLabelDescriptionMap[field]}
      {label}
    </components.MultiValueLabel>
  );
}

const StyledGroupHeading = styled(Row)(
  ({ theme }) => `
        padding: ${theme.space[1]}px ${theme.space[2]}px;
        font-weight: ${theme.bold};
        cursor: pointer;
`,
);

const GroupHeading = ({ children, onClick }) => {
  return (
    <StyledGroupHeading justifyBetween onClick={onClick}>
      {children}
    </StyledGroupHeading>
  );
};

const GroupContent = styled.div(
  ({ theme }) => `  
  background-color:  ${theme.colors.collapseBackground};
  text-align: left;
`,
);

const NoGroupResultsMessage = styled.div(
  ({ theme }) =>
    `padding: ${theme.space[2]}px;
  background-color: "transparent";
`,
);

const InformationPanelContainer = props => {
  const { MenuListFooter = null } = props.selectProps.components;

  return (
    <components.MenuList {...props}>
      {props.children}
      {props.children.length && MenuListFooter}
    </components.MenuList>
  );
};

const InformationPanelFooter = ({ showing, footerTextAndAction }) =>
  !showing ? null : <InformationPanel>{footerTextAndAction}</InformationPanel>;

const InfoPanel = styled(InformationPanelContainer)`
  padding-bottom: 0px !important;
`;

function Group(props) {
  const {
    children,
    className,
    cx,
    innerProps,
    label,
    selectProps: { inputValue },
    options,
    data,
  } = props;

  // If there is a maximum number of results (eg EIDs) block rendering of them - the
  // select clags down with too many options (eg 60K sheep)
  // But if filtered down, seems to do pretty well - we may want to tweak those max values
  // for other data types if performance becomes an issue.
  const { maxResults } = data;
  const hasSearchFilter = Boolean(inputValue);

  const [isManualOpen, toggleOpen] = useToggle(false);

  const selectedCount = useMemo(
    () => options.filter(o => o.isSelected).length,
    [options],
  );

  // If we've got the placeholder, it'll be the first child - don't render it!
  const hasPlaceholder =
    options.length > 0 && options[0].value === GlobalSearchPlaceholder;
  let renderableChildren = children;
  if (hasPlaceholder) {
    renderableChildren = children.slice(1);
  }

  const hasTooManyResults =
    maxResults && renderableChildren.length > maxResults;
  if (hasTooManyResults) {
    // If there are too many results, don't show any - they slow down rendering a lot.  Perhaps we could show some, though?
    renderableChildren = [];
  }

  // This will only be shown if we have nothing to render (ie, too many results, or 0 results with a placeholder)
  const noResultsMessage = hasTooManyResults
    ? `More than ${maxResults} results - please refine your search.`
    : `No ${label} found - try extending your search.`;

  const isOpen =
    isManualOpen ||
    (hasSearchFilter && options.length <= 5) ||
    hasTooManyResults ||
    false;

  const isMiscSection = data.field === GlobalSearchFields.Miscellaneous;
  const isIntegrationSection = data.field === GlobalSearchFields.ExportSites;
  return (
    <div className={cx({ group: true }, className)} {...innerProps}>
      <GroupHeading onClick={toggleOpen} isOpen={isOpen}>
        <div>
          {label} ({selectedCount} / {renderableChildren.length} selected)
        </div>
        <div>
          <HideShowButton isOpen={isOpen} />
        </div>
      </GroupHeading>

      {isOpen ? (
        <GroupContent>
          {renderableChildren.length > 0 ? (
            isMiscSection || isIntegrationSection ? (
              <Grid container> {renderableChildren} </Grid>
            ) : (
              renderableChildren
            )
          ) : (
            <NoGroupResultsMessage>{noResultsMessage}</NoGroupResultsMessage>
          )}
        </GroupContent>
      ) : null}
    </div>
  );
}

const CachedStyledCheckbox = React.memo(StyledCheckbox);

const StyledOption = styled.div`
  ${({ theme, isFocused }) => `
  padding: 3px ${theme.space[2]}px 3px ${theme.space[3]}px;
  cursor: pointer;
  background-color: ${isFocused ? theme.colors.primaryActive : "transparent"};
  color: ${isFocused ? theme.colors.white : "inherit"};
`}
`;

const StyledHeader = styled(Grid)`
  padding: 10px;
`;

function OptionComponent({
  children,
  data: { trueText = "Yes", falseText = "No" },
  ...props
}) {
  const { isSelected, innerRef, innerProps, isFocused, value, label } = props;

  const isMiscSection = Object.values(GlobalSearchBooleanFields).includes(
    value.field,
  );
  const isIntegrationSection = Object.values(
    GlobalIntegrationsBooleanFields,
  ).includes(value.field);

  if (isMiscSection || isIntegrationSection) {
    if (value.value) {
      return (
        <>
          <StyledHeader xs={12}> {label}</StyledHeader>
          <Grid data-tour={label} xs={6}>
            <StyledOption isFocused={isFocused} ref={innerRef} {...innerProps}>
              {trueText}
              <CachedStyledCheckbox checked={isSelected} />
            </StyledOption>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid data-tour={label} xs={6}>
          <StyledOption isFocused={isFocused} ref={innerRef} {...innerProps}>
            {falseText}
            <CachedStyledCheckbox checked={isSelected} />
          </StyledOption>
        </Grid>
      );
    }
  } else {
    return (
      <StyledOption
        data-tour={children}
        isFocused={isFocused}
        ref={innerRef}
        {...innerProps}
      >
        <CachedStyledCheckbox checked={isSelected} />
        {children}
      </StyledOption>
    );
  }
}

const Option = React.memo(OptionComponent);

const GlobalSearchWrapper = styled.div`
  width: 100%;
`;

const overrideComponents = {
  Group,
  MultiValueLabel,
  Option,
};
const overrideStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 20, // Make sure this renders above badges and status cards.
  }),
};

function coalesceSelectValueToSetting(selectValue) {
  // Coalesce into a dict of value.field -> array of values.
  return selectValue.reduce((acc, option) => {
    if (!Array.isArray(acc[option.value.field])) {
      acc[option.value.field] = [];
    }
    acc[option.value.field].push(option.value.value);
    return acc;
  }, {});
}

const defaultFilterOption = createFilter({
  stringify: option =>
    ""
      .concat(option.label, " ")
      .concat(
        GlobalSearchBooleanLabels[option.data.value.field]
          ? MiscellaneousLabel
          : FieldToGroupLabelDescriptionMap[option.data.value.field],
      ),
});

const isSearchPossibleScanSearch = memoizeOne(
  inputValue =>
    PossibleSixteenDigitEidInput.test(inputValue) || parseNlisId(inputValue),
);

const getFieldOptionFromNestedBooleanField = field => {
  if (Object.values(GlobalSearchBooleanFields).includes(field)) {
    return GlobalSearchFields.Miscellaneous;
  } else if (Object.values(GlobalIntegrationsBooleanFields).includes(field)) {
    return GlobalSearchFields.ExportSites;
  } else {
    return null;
  }
};

function OptionsSearch({
  overridePlaceHolder,
  options = [],
  settingName,
  overrideComponents,
  showFooter,
  footerTextAndAction,
}) {
  // On first load select the selected options.
  const currentSearch = useSelector(getSetting(settingName));

  // Seem a bit ugly.  But we need the object that is the value, so spelunk through the options and see if it's defined
  // in our current search.
  const getDefaultValue = () => {
    if (currentSearch) {
      return Object.entries(currentSearch).reduce((acc, [field, values]) => {
        // miscellaneous and export site fields have nested fields, so they need to be handled differently

        const optionWithNestedBooleanFields = options.find(
          optionGroup =>
            optionGroup.field === getFieldOptionFromNestedBooleanField(field),
        );

        const nestedFieldOption = optionWithNestedBooleanFields?.options?.find(
          option => option.value.field === field,
        );
        let fieldOption;

        if (nestedFieldOption?.value?.field === field) {
          fieldOption = optionWithNestedBooleanFields;
        } else {
          fieldOption = options.find(
            optionGroup => optionGroup.field === field,
          ) || { options: [] };
        }

        return acc.concat(
          values.map(
            value =>
              fieldOption.options.find(
                option =>
                  option.value.field === field && option.value.value === value,
              ) || {
                label: "(Selection not in Sale)",
                value: { field, value },
              },
          ),
        );
      }, []);
    }
    return [];
  };

  const defaultValue = useMemo(getDefaultValue, [currentSearch, options]);

  const dispatch = useDispatch();

  // onChange sends the new data, followed by a reducer-able action of the diff.
  // I don't think a partial change gets us THAT much - we're updating the search state anyway - but if
  // performance/re-render becomes an issue this is a good spot to look - we might be able to affect less of
  // the state with each change.
  const onChange = (newValue, changed) => {
    // if they have selected one of the boolean options, and the inverse is already selected
    // unselect that one. The OptionGroup and Option components help populate the the true/false while this
    // does the work of only allowing the user to pick one at a time.

    let normalizedValue = newValue;

    function fieldSetsWithNestedBooleanFields(field, sets) {
      return sets.some(set => Object.values(set).includes(field));
    }

    if (
      fieldSetsWithNestedBooleanFields(changed?.option?.value.field, [
        GlobalSearchBooleanFields,
        GlobalIntegrationsBooleanFields,
      ])
    ) {
      normalizedValue = newValue.filter(
        option =>
          option?.value.field !== changed?.option?.value.field ||
          option?.value.value === changed?.option?.value.value,
      );
    }

    dispatch(
      setSetting(settingName, coalesceSelectValueToSetting(normalizedValue)),
    );
  };

  const filterOption = (option, inputValue) => {
    // Always return placeholder so we show the group.
    if (option.value === GlobalSearchPlaceholder) {
      return true;
    }
    // Shortcircuit when the option is a scan, and the inputValue doesn't look anything like an EID or an NLIS id.
    if (
      option.value.field === GlobalSearchFields.Scan &&
      !isSearchPossibleScanSearch(inputValue)
    ) {
      return false;
    }
    return defaultFilterOption(option, inputValue);
  };

  const getOptionValue = option => {
    if (typeof option.getOptionValue === "function") {
      return option.getOptionValue(option);
    }
    return option.value;
  };

  return (
    <GlobalSearchWrapper data-tour="globalSearch">
      <Select
        getOptionValue={getOptionValue}
        isMulti
        isClearable
        closeMenuOnSelect
        isSearchable
        placeholder={overridePlaceHolder || "Search..."}
        components={{
          ...overrideComponents,
          MenuList: InfoPanel,
          MenuListFooter: (
            <InformationPanelFooter
              showing={showFooter}
              footerTextAndAction={footerTextAndAction}
            />
          ),
        }}
        styles={overrideStyles}
        menuShouldScrollIntoView
        openMenuOnFocus
        options={options}
        hideSelectedOptions={false}
        // menuIsOpen // Useful for styling.
        defaultValue={defaultValue}
        onChange={onChange}
        maxMenuHeight={600} // We can only specify pixels.
        filterOption={filterOption}
        value={defaultValue} // updates the filter value based on what's in Settings.globalSearch
      />
    </GlobalSearchWrapper>
  );
}

function GlobalSearch({ overridePlaceHolder }) {
  const options = useSelector(selectGlobalSearchOptions);

  return (
    <OptionsSearch
      options={options}
      settingName={Settings.globalSearch}
      overridePlaceHolder={overridePlaceHolder}
      overrideComponents={overrideComponents}
    />
  );
}

export function SaleSearch({
  overridePlaceHolder,
  showFooter,
  footerTextAndAction,
}) {
  const options = useSelector(selectSaleSearchOptions);

  return (
    <OptionsSearch
      options={options}
      settingName={Settings.saleSearch}
      overridePlaceHolder={overridePlaceHolder}
      overrideComponents={overrideComponents}
      showFooter={showFooter}
      footerTextAndAction={footerTextAndAction}
    />
  );
}

export default React.memo(GlobalSearch);
