export const suggestedValueMap = {
  NVD: "selectedAttachment.envd.serialNumber",

  vendor_property_id: "suggestedVendorPropertyId",
  id: "suggestedId",
  "declaration.VERSION": "selectedAttachment.draftDeclaration.version",

  // Sheep Q1
  "declaration.qa_accredited":
    "selectedAttachment.draftDeclaration.qaAccredited",

  // Cattle Q1
  "declaration.treated_with_hgp":
    "selectedAttachment.draftDeclaration.treatedWithHgp",

  // Sheep Q2
  "declaration.treated_for_scabby_mouth":
    "selectedAttachment.draftDeclaration.treatedForScabbyMouth",

  // Sheep Q3 / Cattle Q3
  "declaration.owned_since_birth":
    "selectedAttachment.draftDeclaration.ownedSinceBirth",
  "declaration.owned_since_birth_duration":
    "selectedAttachment.draftDeclaration.ownedSinceBirthDuration",

  // Sheep Q4 / Cattle Q6
  "declaration.veterinary_treatment":
    "selectedAttachment.draftDeclaration.veterinaryTreatment",

  // Cattle 04
  "declaration.fed_byproduct_stockfeeds":
    "selectedAttachment.draftDeclaration.fedByproductStockfeeds",

  // Sheep Q5 / Cattle Q7
  "declaration.consumed_material_in_withholding_period":
    "selectedAttachment.draftDeclaration.consumedMaterialInWithholdingPeriod",

  // Cattle Q5
  "declaration.chemical_residue_restrictions":
    "selectedAttachment.draftDeclaration.chemicalResidueRestrictions",

  // Sheep Q6 / Cattle Q2
  "declaration.fed_feed_containing_animal_fats":
    "selectedAttachment.draftDeclaration.fedFeedContainingAnimalFats",

  // Sheep Q7
  "declaration.additional_information":
    "declaration.additional_information_suggested",

  // Cattle Q8
  "declaration.spray_drift_risk":
    "selectedAttachment.draftDeclaration.sprayDriftRisk",
};
