import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";

import { SaleLotModalSection } from "constants/navigation";
import { saleLotStatuses } from "constants/sale";

import { openEditSaleLotModal } from "lib/navigation";

export function actionsColumnRenderer(props) {
  const { saleLot } = props.data;

  const onOpenEditSaleLotModal = () => {
    openEditSaleLotModal(
      saleLot.id,
      saleLot?.status === saleLotStatuses.SOLD
        ? SaleLotModalSection.SELLING
        : SaleLotModalSection.GENERAL,
    );
  };
  return (
    <ActionButtonContainer>
      <ActionButton
        data-tour="action-edit-lot"
        onClick={onOpenEditSaleLotModal}
      >
        <FontAwesomeIcon icon={faPencil} />
        &nbsp;Edit
      </ActionButton>
    </ActionButtonContainer>
  );
}
