import React from "react";

import { faCrosshairs } from "@fortawesome/pro-light-svg-icons";
import { faHistory, faSignOut } from "@fortawesome/pro-solid-svg-icons";
import { sum, uniq } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { MultiButton } from "components/Button";
import { Chip, ChipBag } from "components/Chip";
import { Row } from "components/Layout";
import { Paper } from "components/Paper";
import {
  AddScanLotRowButton,
  LotRow,
} from "components/ScanningPenCards/LotCard/Row";
import { MediumText } from "components/Text";

import { ScanningPenStatusColourMap } from "constants/auctionPens";
import { AuditLogTypes } from "constants/auditLog";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import {
  openAuditLogModal,
  openEditScanningPenModal,
  openPenScanning,
} from "lib/navigation";
import { scanLotConfig } from "lib/scanLot";

import {
  getAuctionPenById,
  getPenArchetypeById,
  getPenIdsByPenArchetypeId,
  getRounds,
  selectNameByDeploymentIdLookup,
} from "selectors";

import { useIsExtraSmallMobile, useIsMobile } from "hooks";

import { StatusWrapper } from "./elements";

const PenTitle = styled.h2`
  width: 5rem;
`;

const EmbeddedPaper = styled(Paper)`
  min-height: 140px;
`;

function ScanningPenCardComponent(props) {
  const { penId, penArchetypeId, penType } = props;

  const {
    getLotIdsByPenId,
    getPenStatusByPenIdLookup,
    selectLotByIdLookup,
    selectEidsByScanLotIdLookup,
  } = scanLotConfig(penType);

  const eidsByScanLotIdLookup = useSelector(selectEidsByScanLotIdLookup);

  const scanLotByIdLookup = useSelector(selectLotByIdLookup);

  const pen = useSelector(getAuctionPenById(penId));

  const penArchetype = useSelector(getPenArchetypeById(penArchetypeId)) || null;

  const scanLotIds = useSelector(getLotIdsByPenId(penId));

  const deploymentLookup = useSelector(selectNameByDeploymentIdLookup);
  const roundLookup = useSelector(getRounds);

  const deployments = uniq(
    scanLotIds.map(
      scanLotId => deploymentLookup[scanLotByIdLookup[scanLotId].deploymentId],
    ),
  ).join(", ");

  const rounds = uniq(
    scanLotIds.map(
      scanLotId =>
        roundLookup[scanLotByIdLookup[scanLotId].saleRoundId]?.name || "",
    ),
  ).join(", ");

  const penQuantity =
    sum(scanLotIds.map(scanLotId => scanLotByIdLookup[scanLotId].quantity)) ||
    0;

  const penScannedQuantity = scanLotIds.reduce(
    (acc, scanLotId) =>
      (acc += eidsByScanLotIdLookup[scanLotId]?.filter(Boolean)?.length || 0),
    0,
  );

  const penStatus = useSelector(getPenStatusByPenIdLookup(penId));

  const onClickScan = e => {
    if (e.target === e.currentTarget) {
      openPenScanning(penArchetypeId, penId, null, null, null, penType);
    }
  };

  const onClickRepenAll = () => {
    openEditScanningPenModal(penId, null, penType, true);
  };

  const onClickOpenPenAuditLog = () =>
    openAuditLogModal(AuditLogTypes.PEN, penId);

  const isExtraSmallMobile = useIsExtraSmallMobile();
  const isMobile = useIsMobile();
  const buttons = [
    {
      title: isExtraSmallMobile ? "" : "Scan",
      onClick: onClickScan,
      default: true,
      dataTour: "scan",
      icon: faCrosshairs,
    },
    {
      title: "Repen All",
      onClick: onClickRepenAll,
      default: false,
      dataTour: "repenAll",
      icon: faSignOut,
      isDisabled: !scanLotIds.length,
    },
    {
      title: "Audit Log",
      onClick: onClickOpenPenAuditLog,
      default: false,
      dataTour: "receivalLotAuditLog",
      icon: faHistory,
      disabled: !penId,
    },
  ];

  return (
    <StatusWrapper
      data-tour={`pen:${penArchetype?.penName}`}
      color={ScanningPenStatusColourMap[penStatus]}
      boxShadow={2}
    >
      <Paper backgroundColor="white">
        <Row justifyBetween alignStretch>
          <h2>{getAuctionPenDisplayName(pen, penArchetype?.penName)}</h2>

          <Row
            data-tour="lotContainer"
            marginHorizontal={1}
            padding={1}
            justifyBetween
            flexWrap
            flexGrow
            background="gray95"
            width="60%"
            onClick={onClickScan}
            className="cursor-pointer"
            minWidth="225px" // This is the min size of the paper within
          >
            {scanLotIds.map(scanLotId => (
              <LotRow scanLotId={scanLotId} key={scanLotId} penType={penType} />
            ))}
          </Row>

          <Row justifyEnd alignStart minWidth="15%">
            <MultiButton
              buttons={buttons}
              orientation={isMobile ? "vertical" : "horizontal"}
            />
          </Row>
        </Row>
        <Row justifyCenter alignCenter>
          <ChipBag>
            <Chip>{penQuantity} Hd</Chip>
            <Chip>{penScannedQuantity} Scanned</Chip>
            {deployments && <Chip>{deployments}</Chip>}
            {rounds && <Chip>{rounds}</Chip>}
          </ChipBag>
        </Row>
      </Paper>
    </StatusWrapper>
  );
}

function EmbeddedScanningPenCardComponent(props) {
  const { penId, penArchetypeId, selectedLotId, penType } = props;

  const {
    penTitle,
    getLotIdsByPenId,
    getPenStatusByPenIdLookup,
    selectEidsByScanLotIdLookup,
    selectLotByIdLookup,
  } = scanLotConfig(penType);

  const scanLotByIdLookup = useSelector(selectLotByIdLookup);

  const eidsByScanLotIdLookup = useSelector(selectEidsByScanLotIdLookup);

  const penIds = useSelector(getPenIdsByPenArchetypeId(penArchetypeId));

  // resolve penId from archetype if pen id is not available
  const resolvedPenId =
    penId ||
    (penArchetypeId && Array.isArray(penIds) && penIds.length > 0 && penIds[0]);

  const pen = useSelector(getAuctionPenById(resolvedPenId)) || null;
  const penArchetype = useSelector(getPenArchetypeById(penArchetypeId)) || null;

  const status = useSelector(getPenStatusByPenIdLookup(resolvedPenId));

  const scanLotIds = useSelector(getLotIdsByPenId(resolvedPenId));

  const penQuantity =
    sum(scanLotIds.map(scanLotId => scanLotByIdLookup[scanLotId].quantity)) ||
    0;

  const penScannedQuantity = scanLotIds.reduce(
    (acc, scanLotId) =>
      (acc += eidsByScanLotIdLookup[scanLotId]?.filter(Boolean)?.length || 0),
    0,
  );

  const onClickNew = () => {
    openPenScanning(penArchetypeId, resolvedPenId, null, true, null, penType);
  };

  return (
    <div data-tour="penCard" className="w-full max-h-72 overflow-scroll">
      <StatusWrapper color={ScanningPenStatusColourMap[status]}>
        <EmbeddedPaper min>
          <Row alignCenter justifyBetween>
            <Row>
              {resolvedPenId ? (
                <MediumText>
                  <AuditLogLink
                    auditLogType={AuditLogTypes.PEN}
                    dataId={resolvedPenId}
                    linkText={` ${penTitle}`}
                    returnTo={window.location.hash}
                  />{" "}
                </MediumText>
              ) : null}
            </Row>
          </Row>
          <Row>
            <PenTitle data-tour="penName">
              {getAuctionPenDisplayName(pen, penArchetype?.penName)}
            </PenTitle>
            <Row justifyBetween flexWrap flexGrow>
              <AddScanLotRowButton
                onClickNew={onClickNew}
                isLotSelected={!!selectedLotId}
              />
              {scanLotIds.map(scanLotId => (
                <LotRow
                  scanLotId={scanLotId}
                  selected={selectedLotId === scanLotId}
                  key={scanLotId}
                  penType={penType}
                />
              ))}
            </Row>
          </Row>
          <Row justifyCenter alignCenter>
            <ChipBag>
              <Chip>{penQuantity} Hd</Chip>
              <Chip>{penScannedQuantity} Scanned</Chip>
            </ChipBag>
          </Row>
        </EmbeddedPaper>
      </StatusWrapper>
    </div>
  );
}

export const ScanningPenCard = React.memo(ScanningPenCardComponent);
export const EmbeddedScanningPenCard = React.memo(
  EmbeddedScanningPenCardComponent,
);
