import { createSelector } from "reselect";

import { EMPTY_ARRAY } from "lib";

import {
  getBusinesses,
  getLabels,
  getManualAdjustments,
  selectManualAdjustmentIdsByTypeLookup,
} from "selectors";

export const selectManualAdjustmentAggridDataByConsignmentId = createSelector(
  [selectManualAdjustmentIdsByTypeLookup, getBusinesses],
  (manualAdjustmentIdsByTypeLookup, businesses) => {
    const manualAdjustmentIdsByConsignment =
      manualAdjustmentIdsByTypeLookup.consignment || {};

    return (
      Object.entries(manualAdjustmentIdsByConsignment).reduce(
        (acc, [consignmentId, manualAdjustments]) => {
          acc[consignmentId] = manualAdjustments.map(manualAdjustment =>
            Object.assign({}, manualAdjustment, {
              toBusinessName:
                businesses[manualAdjustment.toBusinessId].name || "",
              fromBusinessName:
                businesses[manualAdjustment.fromBusinessId].name || "",
            }),
          );
          return acc;
        },
        {},
      ) || EMPTY_ARRAY
    );
  },
);

export const getManualAdjustmentAggridDataByConsignmentId =
  consignmetnId => state =>
    selectManualAdjustmentAggridDataByConsignmentId(state)[consignmetnId] || [];

export const selectManualAdjustmentsData = createSelector(
  [getManualAdjustments, getBusinesses, getLabels],
  (manualAdjustments, businesses, labelsLookup) =>
    Object.values(manualAdjustments).map(manualAdjustment => {
      return {
        manualAdjustment,
        labels: manualAdjustment?.labels?.map(l => labelsLookup[l]?.name) || [],
        fromBusiness: businesses[manualAdjustment.fromBusinessId],
        toBusiness: businesses[manualAdjustment.toBusinessId],
      };
    }),
);
