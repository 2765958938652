import React from "react";

import styled from "styled-components/macro";

import { StatusStyles } from "components/Card/StatusCard";
import { Paper } from "components/Paper";

// This should be small enough that it site in the margin
const More = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0; // Relies on parent being position relative.
  cursor: pointer;
`;

const RelativePaper = styled(Paper)`
  position: relative;
  cursor: pointer;
  ${StatusStyles}
`;

export const ExpandablePaper = ({ expanded, onClick, children, status }) => (
  <RelativePaper onClick={onClick} status={status}>
    {children}
    {!expanded && <More>...</More>}
  </RelativePaper>
);

// export const ExpandableStatusPaper = styled(ExpandablePaper)(StatusStyles);
