import queryString from "query-string";

import { RECEIVAL_LOT } from "constants/actionTypes";

import { serializeReceivalLot } from "lib/serializers/receivalLots";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, livestockSaleId, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/receival-lots/", id, action),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const ReceivalLotAction = offlineActionCreator(
  RECEIVAL_LOT,
  serializeReceivalLot,
  urlCreator,
  true,
);

ReceivalLotAction.updateOrCreateWithPenAndScans = (
  receivalLotId,
  receivalPenId,
  receivalPenArchetypeId,
  receivalLotValues,
  scans,
  duplicateScans,
) => ({
  type: RECEIVAL_LOT.UPDATE_OR_CREATE_WITH_PEN,
  receivalLotId,
  receivalPenId,
  receivalPenArchetypeId,
  receivalLotValues,
  scans,
  duplicateScans,
});
