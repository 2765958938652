import React, { useCallback, useEffect, useMemo, useState } from "react";

import { number, object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { AgGridPanels } from "constants/aggrid";
import { ApiModel } from "constants/loading";
import { v4 as uuidv4 } from "uuid";

import {
  getBidders,
  getConsignments,
  getCurrentRoundsList,
  getCurrentSale,
  selectBidderByBuyerInformation,
  selectBidderOptions,
  selectClearingSaleConsignmentOptions,
} from "selectors";

import {
  useCounter,
  useFieldValue,
  useIsAgGridToolPanelVisible,
  useTranslatedSaleTypeText,
} from "hooks";
import { GridApi } from "ag-grid-community";
import { Form, Formik, getIn, setIn, useFormikContext } from "formik";
import {
  Autocomplete,
  CheckBox,
  CheckBoxBase,
  FILTER_LIMIT,
  Input,
  SelectField,
  useSubmitHandler,
  withNamespace,
} from "components/Form/FormikControls";
import Grid from "@material-ui/core/Grid";
import { Paper } from "components/Paper";
import { PricingType } from "components/SellingSaleLotForm/PricingType";
import { PricingTypes } from "constants/pricingTypes";
import { FormikContextType, FormikHelpers } from "formik/dist/types";
import { LotStatus } from "constants/clearingSaleAttributes";
import { Button, SecondaryButton } from "components/Form";
import { addBidderRegistration, addSaleLotWithPens } from "actions";
import { InformationPanel, Row } from "components/Layout";

import { BusinessField } from "components/Form/Fields";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete/useAutocomplete";
import { createFilterOptions } from "@material-ui/lab";
import { getBuyerInformationString } from "lib/businesses";
import { calculateTotalPriceCents } from "lib";

import { SellSafeOptionToggler } from "components/SellSafe/SellSafe";
import { Divider } from "@material-ui/core";

interface SaleLotBuyerFormProps {
  bidderIdFieldName: string;
  buyerIdFieldName: string;
  buyerWayFieldName: string;
  destinationPropertyIdFieldName: string;
  namespace: string;
}

interface BidderAutocompleteOption {
  label: string;
  value?: string;
  isNewOption?: boolean;
  hide?: boolean;
  disabled?: boolean;
  registrationNumber?: number;
}

interface CalculatePriceOption {
  unitPrice: number;
  quantity: number;
  pricing_type_id: number;
  total_mass_grams: number;
}

const addNewOption = {
  label: "",
  isNewOption: true,
  hide: true,
  registrationNumber: null,
};

const filter = createFilterOptions<BidderAutocompleteOption>();

function filterBidderOptions(
  options: BidderAutocompleteOption[],
  state: FilterOptionsState<BidderAutocompleteOption>,
) {
  // Suggest the creation of a new value
  if (state.inputValue !== "") {
    const registrationNumber = parseInt(state.inputValue, 10);
    if (
      !Number.isNaN(registrationNumber) &&
      !options.some(
        o => o.registrationNumber === registrationNumber && o !== addNewOption,
      )
    ) {
      addNewOption.hide = false;
      addNewOption.label = `Add "${state.inputValue}"`;
      addNewOption.registrationNumber = registrationNumber;
    } else {
      addNewOption.hide = true;
    }
  } else {
    addNewOption.hide = true;
  }

  let filtered = filter(options, state).filter(option => !option.hide);
  if (filtered.length > FILTER_LIMIT) {
    filtered = [
      {
        disabled: true,
        label: `Showing ${FILTER_LIMIT} of ${filtered.length} options`,
      },
      ...filtered.slice(0, FILTER_LIMIT),
    ];
  }

  return filtered;
}

function SaleLotBuyerForm(props: SaleLotBuyerFormProps): React.JSX.Element {
  const {
    namespace: ns = "",
    buyerIdFieldName,
    buyerWayFieldName = null,
    destinationPropertyIdFieldName = null,
    bidderIdFieldName,
  } = props;

  const [isCreatingNewBidder, setIsCreatingNewBidder] = useState(false);

  const { values, setValues } = useFormikContext();
  const nsValues = ns ? getIn(values, ns) : values;
  const bidderId = getIn(nsValues, bidderIdFieldName);
  const buyerId = getIn(nsValues, buyerIdFieldName);
  const buyerWayName = getIn(nsValues, buyerWayFieldName);
  const destinationPropertyId = getIn(nsValues, destinationPropertyIdFieldName);

  const currentSale = useSelector(getCurrentSale);
  const bidderByIdLookup = useSelector(getBidders);

  const isUsingRegisteredBidders = currentSale
    ? currentSale.using_registered_bidders
    : false;

  const baseBidderOptions = useSelector(selectBidderOptions);
  const bidderOptions = useMemo(
    () => [...baseBidderOptions, addNewOption],
    [baseBidderOptions],
  );
  const bidderByBuyerInformationLookup: {
    [key: string]: BidderRegistration;
  } = useSelector(selectBidderByBuyerInformation);

  const onChangeBuyerFields = useCallback(
    (fieldName, newValue) => {
      // when the buyer, destination property or buyer way changes, check to see if the combination matches an existing bidder registration
      // if it does, set the bidder number field in the form except for when the bidder number field is already set, in which case we the user is trying to create a new bidder
      const buyerTemplate = {
        [buyerIdFieldName]: buyerId,
        [destinationPropertyIdFieldName]: destinationPropertyId,
        [buyerWayFieldName]: buyerWayName,
      };

      // apply the new value to the buyerTemplate
      buyerTemplate[fieldName] = newValue;

      const newBuyerInformationString = getBuyerInformationString(
        buyerTemplate[buyerIdFieldName],
        buyerTemplate[destinationPropertyIdFieldName],
        buyerTemplate[buyerWayFieldName],
      );

      const bidder =
        bidderByBuyerInformationLookup[newBuyerInformationString] || null;

      let newNsValues = { ...nsValues };

      if (bidder && !bidderId) {
        // The values entered into the form are a match for an existing bidder - populate the bidder number field as though they had selected it from there
        newNsValues = setIn(nsValues, bidderIdFieldName, bidder.id);
      } else if (!bidder && bidderId) {
        // The user has changed the buyer, destination property or buyer way fields which no loner matches a bidder registration, clear the bidder number field for them
        newNsValues = setIn(newNsValues, bidderIdFieldName, null);
      }
      setValues(ns === "" ? newNsValues : setIn(values, ns, newNsValues));
    },
    [
      buyerIdFieldName,
      buyerWayName,
      buyerWayFieldName,
      bidderByBuyerInformationLookup,
      bidderId,
      bidderIdFieldName,
      buyerId,
      destinationPropertyId,
      destinationPropertyIdFieldName,
      ns,
      nsValues,
      setValues,
      values,
    ],
  );

  const getOnChangeBuyerField = fieldName => newValue =>
    onChangeBuyerFields(fieldName, newValue);

  const onChangeBidderNumber = useCallback(
    (_ignored, newValue: any) => {
      // when the bidder number changes, check to see if the bidder number matches an existing bidder registration
      // if it does, set the buyer, destination property and buyer way fields in the form
      let newNsValues = { ...nsValues };

      if (newValue === addNewOption) {
        // The user is attempting to create a new bidder registration
        newNsValues = setIn(newNsValues, bidderIdFieldName, null);
        newNsValues = setIn(newNsValues, buyerIdFieldName, null);
        newNsValues = setIn(newNsValues, destinationPropertyIdFieldName, null);
        newNsValues = setIn(newNsValues, buyerWayFieldName, null);
        newNsValues = setIn(
          newNsValues,
          "bidderRegistration.registrationNumber",
          newValue.registrationNumber,
        );

        setValues(ns === "" ? newNsValues : setIn(values, ns, newNsValues));
        setIsCreatingNewBidder(true);
        return;
      }

      setIsCreatingNewBidder(false);

      if (!newValue) {
        // the user has cleared the bidder number field, in response clear the buyer, destination property and buyer way fields for them
        newNsValues = setIn(newNsValues, bidderIdFieldName, null);
        newNsValues = setIn(newNsValues, buyerIdFieldName, null);
        newNsValues = setIn(newNsValues, destinationPropertyIdFieldName, null);
        newNsValues = setIn(newNsValues, buyerWayFieldName, null);
        newNsValues = setIn(newNsValues, "bidderRegistration", undefined);
        setValues(ns === "" ? newNsValues : setIn(values, ns, newNsValues));
        // Nothing more to do here
        return;
      }

      const bidder = bidderByIdLookup[newValue.value] || null;
      newNsValues = setIn(newNsValues, bidderIdFieldName, newValue.value);
      newNsValues = setIn(newNsValues, buyerIdFieldName, bidder.businessId);
      newNsValues = setIn(
        newNsValues,
        destinationPropertyIdFieldName,
        bidder.defaultPropertyId,
      );
      newNsValues = setIn(
        newNsValues,
        buyerWayFieldName,
        bidder.buyerWay ? { name: bidder.buyerWay } : null,
      );
      newNsValues = setIn(newNsValues, "bidderRegistration", undefined);
      setValues(ns === "" ? newNsValues : setIn(values, ns, newNsValues));
    },
    [
      buyerIdFieldName,
      buyerWayFieldName,
      bidderByIdLookup,
      bidderIdFieldName,
      destinationPropertyIdFieldName,
      ns,
      nsValues,
      setValues,
      values,
    ],
  );

  const getSelectedOption = useCallback(
    (value: string | null, autoCompleteProps) => {
      const { options } = autoCompleteProps as {
        options: BidderAutocompleteOption[];
      };

      if (isCreatingNewBidder) {
        return addNewOption;
      }
      if (value === null) {
        return null;
      }
      return options.find(option => option.value === value);
    },
    [isCreatingNewBidder],
  );

  return (
    <>
      {isUsingRegisteredBidders && (
        <Grid item xs={12}>
          <Autocomplete
            clearOnBlur
            filterOptions={filterBidderOptions}
            getOptionValue={option => option.value}
            getSelectedOption={getSelectedOption}
            handleHomeEndKeys
            label="Bidder Number"
            name={withNamespace(ns, bidderIdFieldName)}
            options={bidderOptions}
            onChange={onChangeBidderNumber}
            selectOnFocus
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <BusinessField
          name={withNamespace(ns, buyerIdFieldName)}
          label="Buyer"
          onChangeExtra={getOnChangeBuyerField(buyerIdFieldName)}
          activeOnly
        />
      </Grid>
    </>
  );
}

interface QuickCreateFormProps {
  namespace: string;
  stateValue: any;
  setStateValue(value: boolean): any;
  setSettingsValue(value: boolean): any;
  stateValueFieldName: string;
  settingsValue: boolean;
}

function QuickCreateForm(props: QuickCreateFormProps): React.JSX.Element {
  const {
    namespace: ns = "",
    stateValue,
    setStateValue,
    stateValueFieldName = "",
    settingsValue,
    setSettingsValue,
  } = props;

  const quantity: number = useFieldValue(withNamespace(ns, "quantity"));

  const quantityLabel: string = useTranslatedSaleTypeText("Head Count");
  const consignmentOptions = useSelector(selectClearingSaleConsignmentOptions);

  const { values, setFieldValue }: FormikContextType<FormValues> =
    useFormikContext();

  const { is_gst_exempt: isGstExempt }: FormValues = values;

  useEffect(() => {
    if (settingsValue) {
      setFieldValue(stateValueFieldName, stateValue);
    }
  }, [
    setFieldValue,
    setStateValue,
    stateValue,
    stateValueFieldName,
    settingsValue,
  ]);

  const informText = `GST will ${
    isGstExempt ? "not" : ""
  } be included for this item`;

  const updateSetting = () => {
    setSettingsValue(!settingsValue);
  };

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Lot Number"
          type="number"
          name={withNamespace(ns, "lot_number")}
          placeholder="Automatically Assigned"
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label={quantityLabel}
          name={withNamespace(ns, "quantity")}
          autoFocus
          required
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          name="consignment_id"
          options={consignmentOptions}
          label="Vendor"
          placeholder="Select Vendor"
          required
          isClearable
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="Lot Title"
          name={withNamespace(ns, "clearingSaleAttributes.title")}
          required
        />
      </Grid>
      <CheckBox
        name={withNamespace(ns, "is_gst_exempt")}
        label="Is GST Exempt?"
        tooltip={null}
        disabled={false}
        dataTour={undefined}
        onChangeExtra={(value: boolean) => setStateValue(value)}
      />

      <Grid item xs={12}>
        <InformationPanel>{informText}</InformationPanel>
      </Grid>

      <Grid container item xs={12}>
        <CheckBoxBase
          onChange={updateSetting}
          value={settingsValue}
          tooltip="Setting this will remember what has been set for GST exempt when creating the next sale lot"
          label="Remember GST exempt?"
          dataTour="rememberGstExempt"
          disabled={false}
          name="rememberGstExempt"
        />
      </Grid>
      <SaleLotBuyerForm
        buyerIdFieldName="buyer_id"
        buyerWayFieldName="buyer_way"
        bidderIdFieldName="bidderId"
        destinationPropertyIdFieldName="destination_property_id"
        namespace={ns}
      />
      <Grid item xs={12}>
        <PricingType quantity={quantity} namespace="" />
      </Grid>
      <Grid item xs={12}>
        <SellSafeOptionToggler
          name={withNamespace(ns, "clearingSaleAttributes.sellSafeCategory")}
        />
      </Grid>
    </>
  );
}

function FormButtons(): React.JSX.Element {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  useSubmitHandler(isSubmitEnabled, setIsSubmitEnabled);
  return (
    <Row>
      <SecondaryButton type="reset">Clear</SecondaryButton>
      <Button type="submit" disabled={!isSubmitEnabled}>
        Create
      </Button>
    </Row>
  );
}

const validationSchema = object().shape({
  consignment_id: string().required("Required").typeError("Required"),
  clearingSaleAttributes: object().shape({
    title: string().required("Required"),
  }),
  quantity: number().required("Required"),
  buyer_id: string().when("bidderRegistration", {
    is: bidderRegistration =>
      typeof bidderRegistration === "object" && Boolean(bidderRegistration),
    then: string()
      .required("Headcount required")
      .typeError("required when new bidder added"),
    otherwise: string().nullable(),
  }),
});

type FormValues = Partial<SaleLot> & {
  bidderId: string;
  bidderRegistration: BidderRegistration;
  unit_price: number;
  is_gst_exempt: boolean;
};

function QuickCreateClearingSaleSaleLotPanel(): React.JSX.Element {
  const dispatch = useDispatch();
  const currentRounds = useSelector(getCurrentRoundsList);
  const consignmentsById = useSelector(getConsignments);
  const consignmentIds = Object.keys(consignmentsById);
  const [invalidationCount, invalidate] = useCounter();
  // TODO: when we implement quick sell settings - we can move these to redux state settings
  const [isGstExempt, setIsGstExempt] = useState(false);
  const [rememberGstSetting, setRememberGstSetting] = useState(false);

  const initialValues: FormValues = {
    bidderId: null,
    buyer_id: null,
    // if there is only one vendor in the sale - default to that vendor
    consignment_id: consignmentIds.length === 1 ? consignmentIds[0] : null,
    clearingSaleAttributes: {
      title: "",
      status: LotStatus.ACTIVE,
      sellSafeCategory: null,
    },
    pricing_type_id: PricingTypes.GROSS,
    quantity: 1, // Seems like a sensible default
    sale_round_id: currentRounds[0], // Clearing Sales only have one round - CLEARING SALE
    bidderRegistration: null,
    unit_price: null,
    is_gst_exempt: isGstExempt,
  };

  const onSubmit = useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      let bidderId = null;
      if (
        values.bidderRegistration &&
        values.bidderRegistration.registrationNumber
      ) {
        const newBidderId = uuidv4();
        dispatch(
          addBidderRegistration(uuidv4(), {
            ...values.bidderRegistration,
            businessId: values.buyer_id,
          }),
        );
        bidderId = newBidderId;
      }
      const newSaleLotId = uuidv4();
      const priceObject: CalculatePriceOption = {
        unitPrice: values.unit_price,
        quantity: values.quantity,
        pricing_type_id: values.pricing_type_id,
        total_mass_grams: values.total_mass_grams,
      };
      const totalPriceCents = calculateTotalPriceCents(priceObject);
      dispatch(
        addSaleLotWithPens(newSaleLotId, {
          ...values,
          total_price_cents: totalPriceCents,
          bidderId,
        }),
      );
      formikHelpers.resetForm();
    },
    [dispatch],
  );

  const onReset = useCallback(() => {
    // Invalidate will cause the QuickCreateForm to re-mount and allow it to present
    // as a new form, including focus on the preferred input and any other initial presentation logic.
    invalidate();
  }, [invalidate]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={validationSchema}
    >
      <Form>
        <Paper>
          <Grid container spacing={2} justifyContent="center">
            <Grid item container xs={12} spacing={1}>
              <QuickCreateForm
                namespace=""
                key={invalidationCount}
                stateValue={isGstExempt}
                setStateValue={setIsGstExempt}
                setSettingsValue={setRememberGstSetting}
                stateValueFieldName="is_gst_exempt"
                settingsValue={rememberGstSetting}
              />
            </Grid>
            <Grid item xs={12}>
              <FormButtons />
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </Formik>
  );
}

interface LoadingWrapperProps {
  api: GridApi;
}

function LoadingWrapper(props: LoadingWrapperProps): React.JSX.Element {
  const { api } = props;

  const isToolPanelVisible = useIsAgGridToolPanelVisible(
    api,
    AgGridPanels.QUICK_CREATE,
  );
  if (isToolPanelVisible) {
    return (
      <WaitForSync
        requiredData={[
          ApiModel.DEPLOYMENTS,
          ApiModel.SALEYARDS,
          ApiModel.BUSINESSES,
          ApiModel.SALE_LOTS,
          ApiModel.ROUNDS,
          ApiModel.SALES,
        ]}
      >
        <QuickCreateClearingSaleSaleLotPanel />
      </WaitForSync>
    );
  }
  // When the tool panel is not visible, we need to return something to
  // for AgGrid for it to be willing to show the tool panel when the user opens it
  return <p />;
}

export default LoadingWrapper;
