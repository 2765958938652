import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { CheckpointType } from "components/Checkpoints/Constants";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, DeleteButton, SecondaryButton } from "components/Form/Button";
import { ReadOnly } from "components/ReadOnly";

import { useToggle } from "hooks";

export function Footer(props) {
  const formikProps = useFormikContext();
  const { type = "" } = formikProps.values;
  const wasManuallyCreated =
    type === CheckpointType.MANUAL_COMPLIANCE ||
    type === CheckpointType.MANUAL_COMMERCIAL;
  const { checkpointId, onClose, onDelete } = props;
  const [isConfirmDialogOpen, toggleIsConfirmDialogOpen] = useToggle();

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      <DeleteButton
        data-tour="delete"
        type="button"
        onClick={toggleIsConfirmDialogOpen}
        disabled={!wasManuallyCreated}
      >
        <ReadOnly readOnly={false} showLock /> Delete
      </DeleteButton>
      <Button data-tour={checkpointId ? "update" : "create"} type="submit">
        {checkpointId ? "Update" : "Create"}
      </Button>
      <ConfirmDialog
        title="Confirm Delete Checkpoint"
        isOpen={isConfirmDialogOpen}
        onCancel={toggleIsConfirmDialogOpen}
        onDelete={onDelete}
      />
    </>
  );
}

Footer.propTypes = {
  checkpointId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};
