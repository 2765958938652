import React from "react";

import { faBalanceScaleRight } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { Warning } from "components/ErrorMessage";
import { Input, SelectField } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import { Paper } from "components/Paper";
import { MediumText as Heading } from "components/Text";

import {
  selectDeploymentBreedsByDeploymentIdLookup,
  selectDeploymentIdByConsignmentIdLookup,
} from "selectors";

import { Error, Icon } from "./components";

export const BreedSection = ({
  errorMessage,
  children,
  showFullDrafting,
  onChangeBreed,
  readOnly,
}) => {
  const {
    values: { consignment_id },
    errors,
  } = useFormikContext();
  const deploymentId = useSelector(selectDeploymentIdByConsignmentIdLookup)[
    consignment_id
  ];

  const breedOptions = useSelector(selectDeploymentBreedsByDeploymentIdLookup)[
    deploymentId
  ];

  if (showFullDrafting) {
    return (
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Row flexGrow>
                <Icon icon={faBalanceScaleRight} color="gray44" />
                <Heading>Breed (hd)</Heading>
                <Warning>{errors.advancedBreed}</Warning>
                {errorMessage && <Error>{errorMessage}</Error>}
              </Row>
            </Grid>

            {[1, 2, 3].map(i => (
              <React.Fragment key={`br-${i}`}>
                <Grid item xs={9} sm={6}>
                  <SelectField
                    label="Breed"
                    name={`draftingAttributes.advancedBreed${i}Id`}
                    options={breedOptions}
                    isClearable
                    onChangeExtra={i === 1 && onChangeBreed}
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label="Hd"
                    name={`draftingAttributes.advancedBreed${i}Hd`}
                    type="number"
                    disabled={readOnly}
                  />
                </Grid>
              </React.Fragment>
            ))}
            {children}
          </Grid>
        </Paper>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Row flexGrow>
                <Icon icon={faBalanceScaleRight} color="gray44" />
                <Heading>Breed</Heading>
                <Warning>{errors.advancedBreed}</Warning>
                {errorMessage && <Error>{errorMessage}</Error>}
              </Row>
            </Grid>
            {children}
          </Grid>
        </Paper>
      </Grid>
    );
  }
};
