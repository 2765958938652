import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const receivalLotSerializerMap = {
  commentIds: "comment_ids",
  consignmentId: "consignment_id",
  created: "created",
  id: "id",
  lastModified: "last_modified",
  livestockSaleId: "livestock_sale_id",
  mark: "mark",
  permissions: "permissions",
  quantity: "quantity",
  receivalPenId: "receival_pen_id",
};

export const serializeReceivalLot = buildSerializer(
  receivalLotSerializerMap,
  "receivalLot",
);

export const deserializeReceivalLot = buildDeserializer(
  receivalLotSerializerMap,
  "receivalLot",
  {},
  {
    commentIds: [],
  },
);
