import React from "react";

import { EditVendorSplitConsignmentModal } from "components/VendorSplits/VendorSplitConsignmentModal/index";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

function VendorSplitConsignmentModalAdapter(props) {
  const [hashParams, ignored, onClose] = useModalAdapter(
    ModalTypes.VendorSplitConsignment,
    props,
  );
  const { consignmentId = null, parentConsignmentId } = hashParams;
  return (
    <EditVendorSplitConsignmentModal
      parentConsignmentId={parentConsignmentId}
      consignmentId={consignmentId}
      onClose={onClose}
    />
  );
}

export default VendorSplitConsignmentModalAdapter;
