import React, { useCallback, useState } from "react";

import PropTypes from "prop-types";

import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, SecondaryButton } from "components/Form/Button";

export function Footer(props) {
  const { onClose, onDelete, isEdit = false } = props;
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);

  const openConfirmDialog = useCallback(() => {
    setIsOpenConfirmDialog(true);
  }, [setIsOpenConfirmDialog]);

  const closeConfirmDialog = useCallback(() => {
    setIsOpenConfirmDialog(false);
  }, [setIsOpenConfirmDialog]);

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      {isEdit && (
        <Button data-tour="delete" type="button" onClick={openConfirmDialog}>
          Delete
        </Button>
      )}
      <Button type="submit">{isEdit ? "Update" : "Create"}</Button>
      <ConfirmDialog
        title="Confirm Delete Payment"
        isOpen={isOpenConfirmDialog}
        onCancel={closeConfirmDialog}
        onDelete={onDelete}
      />
    </>
  );
}

Footer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isEdit: PropTypes.bool.isRequired,
};
