import React, { memo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getPropertyById } from "selectors";

import { Property } from "./Property";

export const MasterProperty = memo(props => {
  const { propertyId } = props;

  if (typeof propertyId === "string" && propertyId.length === 8) {
    // eslint-disable-next-line no-console
    console.error(
      "The provided propertyId argument (%s) was likely a PIC, instead of a Property Id",
      propertyId,
    );
  }

  const masterProperty = useSelector(getPropertyById(propertyId)) || {};

  const names = [masterProperty?.name || ""];

  return <Property pic={masterProperty.PIC} names={names} />;
});
MasterProperty.propTypes = {
  propertyId: PropTypes.string.isRequired,
};
