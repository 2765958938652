import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import Button from "components/Button";
import { Row } from "components/Layout";

import { SaleLotModalSection } from "constants/navigation";

import { openEditSaleLotModal } from "lib/navigation";

import { getCurrentDecisionSaleLotBySingleWeighId } from "selectors";

export const DeliveryPenLockedAction = ({ singleWeighId }) => {
  const saleLot = useSelector(
    getCurrentDecisionSaleLotBySingleWeighId(singleWeighId),
  );

  const handleAssignPen = () =>
    openEditSaleLotModal(saleLot.id, SaleLotModalSection.AUCTION_PEN);

  return (
    <Row justifyAround fullWidth alignCenter>
      <Grid justifyContent="center" container spacing={2}>
        <Button onClick={handleAssignPen}>
          Assign a different Delivery Pen or Unlock
        </Button>
      </Grid>
    </Row>
  );
};
