import queryString from "query-string";

import { AUCTION_PEN, BILLING_DOCUMENT, SALE_LOT } from "constants/actionTypes";
import {
  BulkUpdateModelActions,
  BulkUpdateModels,
} from "constants/bulkUpdateModels";

import { getLivestockSaleId } from "lib/navigation";
import { serializeAuctionPen } from "lib/serializers/auctionPens";
import { serializeBillingDocument } from "lib/serializers/billingDocuments";
import { serializeBulkUpdateSaleLot } from "lib/serializers/saleLot";

export const bulkUpdate = (
  model,
  payload,
  baseAction,
  options = {
    changeReason: null,
    suppressToast: false,
    suppressErrorToast: false,
    suppressSuccessToast: false,
  },
) => {
  const livestockSaleId = getLivestockSaleId();
  return {
    type: baseAction.REQUEST,
    model,
    payload,
    meta: {
      offline: {
        effect: {
          url: queryString.stringifyUrl({
            url: `/v2/${model}/bulk_update/`,
            query: {
              livestock_sale_id: livestockSaleId,
            },
          }),
          method: "PATCH",
          body: JSON.stringify(payload),
          options,
          changeReason: options.changeReason,
        },
        commit: {
          type: baseAction.SUCCESS,
          model,
          options,
        },
        rollback: {
          type: baseAction.FAILURE,
          model,
          options,
        },
      },
    },
  };
};

export const bulkUpdateAuctionPen = auctionPens => {
  const model = BulkUpdateModels.AUCTION_PEN;
  const objects = auctionPens.map(serializeAuctionPen);
  const baseAction = AUCTION_PEN.UPDATE_BULK;
  return bulkUpdate(model, objects, baseAction);
};

export const bulkUpdateSaleLots = saleLots => {
  const model = BulkUpdateModels.SALE_LOT;
  const objects = saleLots.map(serializeBulkUpdateSaleLot);
  const baseAction = SALE_LOT.UPDATE_BULK;
  return bulkUpdate(model, objects, baseAction);
};
/** This is a Bulk Update Sale Lot Action Creator, not a serializer.
 * @param {Array<SaleLot>} saleLots
 */
export const bulkUpdateSaleLotsSerializer = (saleLots, options) => {
  const model = BulkUpdateModels.SALE_LOT_SERIALIZER;
  const objects = saleLots.map(serializeBulkUpdateSaleLot);
  const baseAction = SALE_LOT.UPDATE_BULK;
  return bulkUpdate(model, objects, baseAction, options);
};

export const bulkUpdateBillingDocuments = billingDocuments => {
  const model = BulkUpdateModels.BILLING_DOCUMENT;
  const objects = billingDocuments.map(serializeBillingDocument);
  const baseAction = BILLING_DOCUMENT.UPDATE_BULK;
  return bulkUpdate(model, objects, baseAction);
};

export const bulkUpdateFromSocket = message => {
  const { model, objects } = message.object;
  const baseAction = BulkUpdateModelActions[model];
  return {
    type: baseAction.FROM_SOCKET,
    model,
    payload: objects,
  };
};
