import React, { memo, useEffect } from "react";

import { faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Drawer, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";
import DraftingDecisionHistoryItemComponent from "components/SingleWeighView/SingleWeighDraftingDecision/DraftingDecisionHistoryItem";

import { getDraftingDecisionHistoryBySingleWeighById } from "selectors";

import { useToggle, useTimeoutRef } from "hooks";

const TopBorderedColumn = styled(Column)(
  ({ theme }) => `
  box-shadow: ${theme.shadows[1]};
  cursor: pointer;
  &:hover {
    background: ${theme.colors.primaryHighlight};
    color: ${theme.colors.white};
  }
  transition: ${theme.transitions[0]};
  z-index: 100;
`,
);

function SingleWeighRecentDrafts({ singleWeighId }) {
  const [open, toggleOpen] = useToggle();

  const recentDraftCount = useSelector(
    state =>
      getDraftingDecisionHistoryBySingleWeighById(singleWeighId)(state).length,
  );

  const [showLastDecision, setShowLastDecision] = React.useState(false);

  const refTimeout = useTimeoutRef();

  useEffect(() => {
    if (recentDraftCount > 0) {
      setShowLastDecision(true);
      refTimeout.current = setTimeout(() => setShowLastDecision(false), 10000);
    }
  }, [recentDraftCount, refTimeout]);

  const handleClick = () => {
    toggleOpen();
    setShowLastDecision(false);
  };

  return (
    <TopBorderedColumn background="controlBackground" flex={false} padding={2}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        onClick={handleClick}
        spacing={2}
      >
        <Divider />

        {showLastDecision ? (
          <DraftingDecisionHistoryItemComponent
            index={0}
            singleWeighId={singleWeighId}
          />
        ) : (
          <>
            <FontAwesomeIcon icon={faArrowUp} />
            <Row padding={1}>Show Recently Drafted</Row>
            <FontAwesomeIcon icon={faArrowUp} />
          </>
        )}
      </Grid>

      <Drawer anchor="bottom" open={open} onClose={toggleOpen}>
        <Paper maxHeight="50vh">
          {recentDraftCount === 0 ? (
            <Box textAlign="center">
              <h1>No previous drafting decisions available</h1>
            </Box>
          ) : (
            [...Array(recentDraftCount)].map((_ignored, index) => (
              <React.Fragment key={index}>
                <Row justifyBetween alignCenter>
                  <DraftingDecisionHistoryItemComponent
                    key={index}
                    index={index}
                    singleWeighId={singleWeighId}
                  />
                </Row>
                <Divider />
              </React.Fragment>
            ))
          )}
        </Paper>
      </Drawer>
    </TopBorderedColumn>
  );
}

export default memo(SingleWeighRecentDrafts);
