import React from "react";

import styled from "styled-components/macro";

import { CenteredUnstyledButton } from "components/Button/UnstyledButton";
import { CommentIcon } from "components/Comments/Icon";
import { Column } from "components/Layout";

export const saleLotCommentsColumnId = "saleLotCommentCount";

const SizedColumn = styled(Column)`
  font-size: 0.8em;
  line-height: 30px;
`;

export function saleLotCommentCountColumnRenderer(props) {
  const { value, data, context } = props;
  if (value === undefined) {
    return null;
  }
  const { handleClickSaleLotComments } = context || {};
  const { resolvedSaleLotCommentCount } = data;
  let body = (
    <CommentIcon
      hasUnresolvedComments={value > 0}
      hasResolvedComments={resolvedSaleLotCommentCount > 0}
      internalText={value}
      size="2x"
    />
  );

  if (typeof handleClickSaleLotComments === "function") {
    body = (
      <CenteredUnstyledButton onClick={() => handleClickSaleLotComments(data)}>
        {body}
      </CenteredUnstyledButton>
    );
  }
  return (
    <SizedColumn justifyCenter alignCenter fullHeight fullWidth>
      {body}
    </SizedColumn>
  );
}
