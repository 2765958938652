import { ColumnType } from "components/AgGrid/constants";

import { ModalTypes } from "constants/navigation";

export const PenScanLotColumnId = {
  CREATED: "a2cde426-0287-4cb4-9ae1-b1487c63d061",
  EDIT: "b0174e77-e8d7-4682-8342-cc4a188a8ff0",
  IS_LOCKED: "576d88e8-59ec-460b-bea5-281d7426db4a",
  LAST_MODIFIED: "1dcba384-e7ee-4cee-9a0a-b98a5e2e0cd6",
  MARKS: "ed3bceee-3d7f-4c17-a199-9151dee47d9d",
  PEN: "07dc4181-6025-4290-9a4b-6d37e2b674d0",
  QUANTITY: "fcadbf5d-4a99-499a-b4a9-9999fb903d59",
  SCANS_COUNT: "2eadc25a-84b0-4100-b3d6-4d36174492e1",
};

export const PenScanLotColumnDefinitions = {
  [PenScanLotColumnId.CREATED]: {
    field: "penScanLot.created",
    colId: PenScanLotColumnId.CREATED,
    headerName: "Created",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    width: 130,
  },
  [PenScanLotColumnId.LAST_MODIFIED]: {
    field: "penScanLot.lastModified",
    colId: PenScanLotColumnId.LAST_MODIFIED,
    headerName: "Last Modified",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    width: 130,
  },
  [PenScanLotColumnId.MARKS]: {
    colId: PenScanLotColumnId.MARKS,
    field: "penScanLot.marks",
    headerName: "Marks",
    width: 110,
    type: ColumnType.STRING,
  },
  [PenScanLotColumnId.PEN]: {
    colId: PenScanLotColumnId.PEN,
    field: "penScanLot.penDisplayName",
    headerName: "Pen",
    width: 90,
    type: ColumnType.STRING,
  },
  [PenScanLotColumnId.IS_LOCKED]: {
    colId: PenScanLotColumnId.IS_LOCKED,
    field: "penScanLot.isLocked",
    headerName: "Is Locked",
    width: 90,
    type: ColumnType.BOOLEAN,
  },
  [PenScanLotColumnId.SCANS_COUNT]: {
    colId: PenScanLotColumnId.SCANS_COUNT,
    field: "penScanLot.scansCount",
    headerName: "Scan Count",
    width: 110,
    type: ColumnType.NUMERIC,
  },
  [PenScanLotColumnId.QUANTITY]: {
    colId: PenScanLotColumnId.QUANTITY,
    field: "penScanLot.quantity",
    headerName: "Quantity",
    width: 90,
    type: ColumnType.NUMERIC,
  },
  [PenScanLotColumnId.EDIT]: {
    colId: PenScanLotColumnId.EDIT,
    field: "penScanLot.id",
    type: ColumnType.EDIT,
    headerName: "Actions",
    cellRendererParams: {
      idKey: "penScanLotId",
      modalType: ModalTypes.EditPenScanLot,
    },
  },
};
