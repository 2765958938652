import { useCallback } from "react";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

export const useCloseDialog = returnTo => {
  const closeDialog = useCallback(() => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }, [returnTo]);
  return closeDialog;
};
