import React from "react";

import styled from "styled-components/macro";

import SearchableSelector from "components/SearchableSelector";

const SaleyardSelect = styled(SearchableSelector).attrs(({ theme }) => ({
  large: true,
  maxDropdownHeight: 500,
  listStyle: {
    border: `1px solid ${theme.colors.gray78}`,
    borderRadius: theme.radii[1],
  },
  searchRowStyle: {
    fontSize: theme.fontSizes.gamma,
    color: theme.colors.gray40,
    border: `1px solid ${theme.colors.gray78}`,
    borderBottom: "none",
    fontWeight: 400,
    paddingLeft: "8px",
  },
  rowStyle: {
    fontSize: theme.fontSizes.gamma,
    color: theme.colors.gray40,
    borderBottom: `1px solid ${theme.colors.gray78}`,
    padding: 0,
    fontWeight: 400,
  },
  topRowStyle: {
    padding: `${theme.space[2]}px ${theme.space[1]}px`,
    fontWeight: theme.fontWeights.normal,
    fontSize: theme.fontSizes.gamma,
  },
  displayIconStyle: {
    marginLeft: theme.space[2],
  },
}))``;

export default React.memo(SaleyardSelect);
