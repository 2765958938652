import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  branches: "branches",
  channels: "channels",
  dateJoined: "date_joined",
  defaultRole: "default_role",
  deployments: "deployments",
  email: "email",
  emailConfirmed: "email_confirmed",
  firstName: "first_name",
  groupIds: "group_ids",
  id: "id",
  invitationSentDate: "invitation_sent_date",
  isActive: "is_active",
  lastName: "last_name",
  name: "name",
  nlisSaleyardCredentials: "nlis_saleyard_credentials",
  phoneNumber: "phone_number",
  reportFavourites: "report_favourites",
  roleInvitationAcceptedDate: "role_invitation_accepted_date",
  roleInvitationSentDate: "role_invitation_sent_date",
  saleyards: "saleyards",
  saleyardIds: "sale_yard_ids", // ick.
  slug: "slug",
  type: "type",
  userLevelId: "user_level_id",
  username: "username",
};

export const serializeSaleyardAdmin = buildSerializer(
  serializeMap,
  "saleyardAdmin",
);
export const deserializeSaleyardAdmin = buildDeserializer(
  serializeMap,
  "saleyardAdmin",
);
