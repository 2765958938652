import {
  BusinessAction,
  deleteBusinessNoLongerExists,
  deleteConsignmentNoLongerExists,
  deleteSaleLotNoLongerExists,
} from "actions";

import { BUSINESS } from "constants/actionTypes";

import { fetchConsignment } from "sagas/consignments";
import { fetchSaleLot } from "sagas/saleLots";

export const SYSTEM_PUSHER_CHANNEL = "agrinous";

export const defaultEffectType = {
  type: "",
  id: "",
  fetch: null,
  delete: null,
};

const saleLotEffects = id => {
  return {
    type: "Lot",
    id,
    fetch: fetchSaleLot,
    delete: deleteSaleLotNoLongerExists,
  };
};

const consignmentEffects = id => {
  return {
    type: "Consignment",
    id,
    fetch: fetchConsignment,
    deleteFunction: deleteConsignmentNoLongerExists,
  };
};
const businessEffects = id => {
  return {
    type: "Business",
    id,
    fetch: BusinessAction.request,
    deleteFunction: deleteBusinessNoLongerExists,
  };
};

export const effectMap = (saleLotId, consignmentId = "", businessId = "") => {
  return {
    PATCH_SALELOT: saleLotEffects(saleLotId),
    UPDATE_SALE_LOT_REQUEST: saleLotEffects(saleLotId),
    ADD_SALE_LOT_OFFLINE: saleLotEffects(saleLotId),
    DELETE_SALE_LOT_OFFLINE: saleLotEffects(saleLotId),
    ADD_CONSIGNMENT: consignmentEffects(consignmentId),
    PATCH_CONSIGNMENT_OFFLINE: consignmentEffects(consignmentId),
    ADD_CONSIGNMENT_OFFLINE: consignmentEffects(consignmentId),
    DELETE_CONSIGNMENT_OFFLINE: consignmentEffects(consignmentId),
    PATCH_BUSINESS_OFFLINE: businessEffects(businessId),
    [BUSINESS.UPDATE.REQUEST]: businessEffects(businessId),
    [BUSINESS.FETCH_BULK.REQUEST]: businessEffects(businessId),
    [BUSINESS.CREATE.REQUEST]: businessEffects(businessId),
    [BUSINESS.DELETE.REQUEST]: businessEffects(businessId),
  };
};

export const methodsMap = {
  PATCH: "Updating",
  POST: "Creating",
  DELETE: "Deleting",
};

export const appUpdateStatus = {
  LOGOUT_REQUIRED: "Logout Required",
  REFRESH_REQUIRED: "Refresh Required",
  REFRESH_OPTIONAL: "Refresh Optional",
  INVISIBLE: "Invisible",
  UP_TO_DATE: "Up to date",
};

export const appUpdateType = {
  PATCH: "patch",
  PRERELEASE: "prerelease",
  PREPATCH: "prepatch",
  PREMINOR: "preminor",
  MINOR: "minor",
  PREMAJOR: "premajor",
  MAJOR: "major",
};
