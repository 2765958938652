import React from "react";

import sumBy from "lodash/sumBy";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { PricingTypes } from "constants/pricingTypes";
import { SaleTypes } from "constants/sale";

import { calculateUnitPrice } from "lib";

import { getBestMatchingProductId } from "lib/products";

import {
  getBusinesses,
  getBuyerIdsFromSaleLots,
  getConsignments,
  getConsignmentSaleLotsSortedByWeight,
  getSales,
  getScans,
  selectActiveProducts,
} from "selectors";

import {
  getProductsBySpecies,
  getSpeciesAttributeOptions,
} from "selectors/speciesAttributes";

import { PrivateSaleOverview } from "./Overview";

const selectConsignment = createSelector(
  state => getConsignments(state),
  (_, livestocksale_id) => livestocksale_id,
  (consignments, livestocksale_id) => {
    const liveStockSaleConsignments = Object.values(consignments).filter(
      c => c.livestocksale_id === livestocksale_id,
    );
    // Assuming for now that there is only one.
    return liveStockSaleConsignments[0];
  },
);

export const PaddockSale = React.memo(
  ({ match }) => {
    const { saleId } = match.params;
    const sale = useSelector(state => getSales(state)[saleId]);

    const speciesAttributes = useSelector(getSpeciesAttributeOptions);
    const products = useSelector(getProductsBySpecies);
    const businesses = useSelector(getBusinesses);
    const buyerIds = Object.keys(useSelector(getBuyerIdsFromSaleLots));
    const moreThanOneBuyerInSale = buyerIds.length > 1;

    const consignment = useSelector(state => {
      return selectConsignment(state, sale.livestocksale_id);
    });

    const scans = Object.values(useSelector(getScans));

    const saleLots = useSelector(state => {
      if (!consignment?.id) {
        return [];
      }
      const { files } = state.files;
      const products = Object.values(selectActiveProducts(state));

      return getConsignmentSaleLotsSortedByWeight(state, consignment.id).map(
        sl => {
          let { pricing_type_id } = sl;

          if (sl.lot_number === 1 && sale.sale_type === SaleTypes.OVER_HOOKS) {
            pricing_type_id = PricingTypes.PER_KILO;
          }

          return {
            ...sl,
            dollars_per_kilo: +(
              sl.total_price_cents /
              100 /
              sl.total_mass_grams /
              1000
            ).toFixed(2),
            buyer: businesses[sl.buyer_id] || {},
            total_mass_grams: sl.total_mass_grams,
            product_id: getBestMatchingProductId(sl, products, null),
            attachments: sl.attachments?.map(id => files[id]) || [],
            quantity_scanned: scans.filter(s => s.sale_lot_id === sl.id).length,
            unit_price: calculateUnitPrice({ ...sl, pricing_type_id }),
          };
        },
      );
    });

    const deploymentSales = sale?.deployment_sales;

    const notes = consignment?.notes || sale?.notes;

    const overviewBuyer =
      businesses?.[saleLots?.[0]?.buyer_id || sale.default_buyer_id] || {};

    const buyerName = moreThanOneBuyerInSale
      ? "Many"
      : overviewBuyer.name || "Unknown Buyer";

    const overviewVendor =
      businesses?.[consignment?.vendor_id || sale.default_vendor_id] || {};
    const vendorName = overviewVendor.name || "Unknown Vendor";

    const product =
      (saleLots?.[0]?.product_id &&
        products.find(p => p.id === saleLots[0].product_id)?.name) ||
      "-";
    const carrierName = businesses?.[consignment?.carrierCharge?.carrier]?.name;

    const breed =
      (saleLots?.[0]?.breed_id &&
        speciesAttributes.breeds.find(
          breed => breed.value === saleLots[0].breed_id,
        )?.label) ||
      "-";

    const quantity =
      sumBy(saleLots, "quantity") || consignment?.quantity || "-";

    return (
      <WaitForSync
        requiredData={[
          ApiModel.CONSIGNMENTS,
          ApiModel.SALE_LOTS,
          ApiModel.DEPLOYMENTS,
          ApiModel.BUSINESSES,
        ]}
      >
        <PrivateSaleOverview
          sale={sale}
          notes={notes}
          buyerName={buyerName}
          vendorName={vendorName}
          product={product}
          breed={breed}
          carrierName={carrierName}
          quantity={quantity}
          deploymentSales={deploymentSales}
        />
      </WaitForSync>
    );
  },
  (a, b) => {
    return a.match.params.saleId === b.match.params.saleId;
  },
);
