import React from "react";

import { useTheme } from "hooks";

export const FrontGateClosedBackGateOpen = ({ color, size }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.black;

  const svgSize = size || theme.fontSizes.gamma;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox="0 0 27500 27500"
    >
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6325.8"
        y1="6277"
        x2="6325.8"
        y2="17814.7"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21273.4"
        y1="10013.9"
        x2="21273.4"
        y2="6277"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6325.8"
        y1="21224.5"
        x2="6325.8"
        y2="17814.7"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13799.6"
        y1="21224.5"
        x2="21273.4"
        y2="21224.5"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="26957.6"
        y1="17712.3"
        x2="21273.4"
        y2="9974.6"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13799.6"
        y1="6277"
        x2="21273.4"
        y2="6277"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6325.8"
        y1="21224.5"
        x2="13799.6"
        y2="21224.5"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6325.8"
        y1="6277"
        x2="13799.6"
        y2="6277"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6325.8"
        cy="17814.7"
        r="336.3"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6325.8"
        cy="6277"
        r="336.3"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6325.8"
        cy="21224.5"
        r="336.3"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13799.6"
        cy="21224.5"
        r="336.3"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13799.6"
        cy="6277"
        r="336.3"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21273.4"
        cy="9974.6"
        r="336.3"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21273.4"
        cy="21224.5"
        r="336.3"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21273.4"
        cy="6277"
        r="336.3"
      />
      <path
        stroke={svgColor}
        fill={svgColor}
        d="M21564.4 17426.8c418.1-56.4 707.2 89.7 1124-24.9l-2371.3-2630.6 413.9-396.2 2387 2639.2c152.6-421.3 21.5-711.5 147.3-1091l473.9-13.1c85 268-32.9 1795.3-67.5 2139.7-298.8 32.8-1892.4-26.8-2159.5-151.9l52.3-471.2z"
      />
    </svg>
  );
};

export default FrontGateClosedBackGateOpen;
