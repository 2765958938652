import React from "react";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Button from "components/Button";
import OutlineButton from "components/Button/OutlineButton";
import { Column, Row } from "components/Layout";
import { ModalBg } from "components/Modal";

const styles = {
  backgroundModal: {
    backgroundColor: "rgba(102,102,102,0.5)",
  },
  contentModal: {
    margin: "12px auto",
    height: "auto",
    maxWidth: "552px",
    padding: "24px 24px 24px 36px",
    backgroundColor: "white",
  },
};

const MainWrapper = styled(Column)``;

const CircleIcon = styled(FontAwesomeIcon)`
  font-size: 36px;
  color: ${({ theme }) => theme.colors.lightCoral};
  height: 24px;
  width: 29px !important;
`;

const WrapperIcon = styled(Row)`
  background-color: ${({ theme }) => theme.colors.gray95};
  padding: 30px;
  height: 84px;
  width: 84px;
  border-radius: 50%;
  margin-top: 12px;
`;

const WrapperContent = styled(Column)`
  margin-top: 46px;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.gray40};
  font-size: ${({ theme }) => theme.fontSizes.zeta}px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 38px;
  padding-bottom: 12px;
`;
const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.gray40};
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  line-height: 24px;
  padding-bottom: 12px;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.lightCoral};
  font-size: ${({ theme }) => theme.fontSizes.beta}px;
  letter-spacing: -0.09px;
  line-height: 16px;
`;

const ButtonsWrapper = styled(Row)`
  margin-top: 46px;
`;

const SubmitButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  min-width: 180px;
  max-height: 48px;
  cursor: pointer;
`;

const RejectButton = styled(OutlineButton)`
  color: ${({ theme }) => theme.colors.gray40};
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  margin-right: 24px;
  min-width: 96px;
  max-height: 48px;
`;

const Dialog = ({
  closeSelf,
  title,
  subtitle,
  warningText,
  rejectButtonText,
  submitButtonText,
  handleSubmit,
  icon,
}) => {
  return (
    <ModalBg
      closeSelf={closeSelf}
      backgroundStyle={styles.backgroundModal}
      contentStyle={styles.contentModal}
      expandHeight
    >
      <MainWrapper>
        <Row justifyBetween>
          <WrapperIcon justifyCenter alignCenter>
            <CircleIcon icon={icon} />
          </WrapperIcon>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={closeSelf}
            style={{ fontSize: 30, color: "#c2c2c2", cursor: "pointer" }}
          />
        </Row>
        <WrapperContent>
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {warningText && <Text>{warningText}</Text>}
          <ButtonsWrapper justifyEnd>
            <RejectButton onClick={closeSelf} color="gray40">
              {rejectButtonText}
            </RejectButton>
            <SubmitButton
              data-tour="submit"
              onClick={() => {
                closeSelf();
                handleSubmit();
              }}
              color="inputError"
            >
              {submitButtonText}
            </SubmitButton>
          </ButtonsWrapper>
        </WrapperContent>
      </MainWrapper>
    </ModalBg>
  );
};

Dialog.propTypes = {
  closeSelf: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  warningText: PropTypes.string,
  submitButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  handleSubmit: PropTypes.func,
  icon: PropTypes.any,
};

export default Dialog;
