import React from "react";

import { faExchangeAlt, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ActionButton } from "components/AgGrid/ActionButton";

export function actionsColumnRenderer(props) {
  const { data, setConfirmOverride, mapBusiness, checkIsOverride } = props;
  return checkIsOverride(data) ? (
    <ActionButton
      data-tour="action-override-shortcode"
      onClick={() => setConfirmOverride(data)}
    >
      <FontAwesomeIcon icon={faExchangeAlt} />
      &nbsp;Map (Override)
    </ActionButton>
  ) : (
    <ActionButton
      data-tour="action-map-business"
      onClick={() => mapBusiness(data)}
    >
      <FontAwesomeIcon icon={faPencil} />
      &nbsp;Map (Set)
    </ActionButton>
  );
}
