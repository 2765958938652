import React, { memo, useCallback } from "react";

import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { connectToHub } from "actions";

import IconTextButton from "components/Button/IconTextButton";

import { getIsHubConnected } from "selectors";

const MESSAGE_CONNECTED = "Connected to AgriNous Hub.";
const MESSAGE_NOT_CONNECTED =
  "Not connected to AgriNous Hub. Click to retry connection.";

export function HubStatusIndicatorComponent() {
  const isHubConnected = useSelector(getIsHubConnected);

  const dispatch = useDispatch();

  const onClickIndicator = useCallback(() => {
    if (!isHubConnected) {
      dispatch(connectToHub());
    }
  }, [dispatch, isHubConnected]);

  const statusColour = isHubConnected ? "success" : "warning";

  return (
    <IconTextButton
      inline
      icon={faCircle}
      color={statusColour}
      isHubConnected={isHubConnected}
      tooltip={isHubConnected ? MESSAGE_CONNECTED : MESSAGE_NOT_CONNECTED}
      onClick={onClickIndicator}
    />
  );
}

export default memo(HubStatusIndicatorComponent, () => true);
