import toast from "lib/toast";
import React from "react";
import styled from "styled-components/macro";

const Text = styled.span`
  font-size: 12px;
  font-weight: normal;
  text-decoration: underline;
  margin-left: 8px;
  text-align: right;
  color: ${({ theme }) => theme.colors.newUpdateText};
  border-radius: 6px;
  background: var(
    --activeGreen,
    ${({ theme }) => theme.colors.newUpdateBackground}
  );
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
`;
function UpdateStatus(): React.JSX.Element {
  return (
    <Text
      onClick={() =>
        toast.optionalRefreshPrompt("Refresh to see the latest updates.")
      }
    >
      New Updates
    </Text>
  );
}

export default UpdateStatus;
