import { OwnerTypes } from "constants/aggrid";

export const OrderedSavedViewOwnerTypes = [
  OwnerTypes.GLOBAL,
  OwnerTypes.SALEYARD,
  OwnerTypes.DEPLOYMENT,
  OwnerTypes.USER,
];

export const sortSavedViewCategories = (a, b) => {
  return (
    OrderedSavedViewOwnerTypes.indexOf(a) -
    OrderedSavedViewOwnerTypes.indexOf(b)
  );
};
