import { createSelector } from "reselect";

import { IntegrationTypes } from "constants/integrations";

import {
  getActiveLivestockAgentDeployment,
  getBusinesses,
  selectResolvedInterestSettingsByBusinessIdLookup,
  selectVendorCommissionBandsByDeploymentBusinessIdLookup,
} from "selectors";

export const selectBusinessesAggridData = createSelector(
  [
    getBusinesses,
    selectResolvedInterestSettingsByBusinessIdLookup,
    selectVendorCommissionBandsByDeploymentBusinessIdLookup,
    getActiveLivestockAgentDeployment,
  ],
  (
    businessByIdLookup,
    resolvedInterestSettingsByBusinessId,
    vendorCommissionsByDeploymentBusinessId,
    activeLivestockAgentDeployment,
  ) => {
    return Object.values(businessByIdLookup).map(business => {
      const vendorCommissionBands =
        vendorCommissionsByDeploymentBusinessId[
          business?.deploymentBusinessId
        ] || [];

      const interestSettings =
        resolvedInterestSettingsByBusinessId[business.id];

      return {
        business: {
          ...business,
          // Filter hidden things.
          properties: business.properties.filter(
            businessProperty => businessProperty.isShown,
          ),
          buyerWays: business.buyerWays.filter(buyerWay => buyerWay.isShown),
        },
        deployment: activeLivestockAgentDeployment,
        interestSettings,
        vendorCommissionBands,
        xeroIntegrationBusiness: business.integrationBusinesses?.find(
          ib => ib.type === IntegrationTypes.Xero,
        ),
      };
    });
  },
);
