import { isEmpty, isObject, omit, transform } from "lodash";
import { flatten } from "lodash/array";
import isEqual from "lodash/isEqual";

import { extractFileNameFromURL, sortByKey } from "lib";

/**
 * For case insensitive comparisons, this is much faster than using a.localeCompare(b) while still handing numbers.
 */
export const caseInsensitiveCompare = new Intl.Collator("en", {
  numeric: true,
  sensitivity: "base",
}).compare;

// Wrapper function to make the debugging ability easier to expose.
export const allFieldsEqual = (a, b, fields) =>
  fields.every(field => {
    if (a[field] !== b[field]) {
      // Useful for troubleshooting
      // console.log(`${field} changed - "${a[field]}" -> "${b[field]}"`);
      return false;
    } else {
      return true;
    }
  });

const listsAreSameLength = (list1, list2) => {
  if (!(list1 && list1.length)) {
    return !(list2 && list2.length);
  }
  if (!(list2 && list2.length)) {
    return false;
  }

  return list1.length === list2.length;
};

const marksAreEqual = (marks1, marks2) => {
  if (!Array.isArray(marks1) || !Array.isArray(marks2)) {
    return false;
  }
  if (!listsAreSameLength(marks1, marks2)) {
    return false;
  }

  const sortKey = "location";
  const sorted1 = sortByKey(marks1, sortKey);
  const sorted2 = sortByKey(marks2, sortKey);
  return sorted1.every(
    (_, i) =>
      sorted2[i] &&
      allFieldsEqual(sorted1[i], sorted2[i], ["location", "color"]),
  );
};

/**
 * Determine if salelot changes have occured for the purpose
 * of limiting re-rendering in shouldComponentUpdate
 *
 * @param {object} saleLot1
 * @param {object} saleLot2
 * @returns {boolean}
 */
export const saleLotIsEqual = (saleLot1, saleLot2) => {
  const updateOnFields = [
    // Head count
    "quantity",
    "quantityDelivered",
    // Buyer
    "buyerName",
    "buyerWayName",
    // Auction Pen
    "auctionPenId",
    // Breed etc
    "gradeName",
    "sexName",
    "ageName",
    "breedName",
    // Scanning
    "scannedCount",
    "scannedStatus",
    // Price
    "priceUnits",
    "price",
    "labels",
    "markOrder",
  ];
  const equalityChecks = [
    () => allFieldsEqual(saleLot1, saleLot2, updateOnFields),
    () => marksAreEqual(saleLot1.marks, saleLot2.marks),
  ];
  const isEqual = equalityChecks.every(checkFunction => checkFunction());
  // Useful for debugging
  // if (!isEqual) {
  //   console.log("Salelot changed");
  // }
  return isEqual;
};

/**
 * compare two lists of salelots for any change for the purpose
 * of limiting re-rendering in shouldComponentUpdate
 *
 * @param {array} saleLotList1
 * @param {array} saleLotList2
 * @returns {boolean}
 */
export const saleLotsListsAreEqual = (saleLotList1, saleLotList2) => {
  if (!listsAreSameLength(saleLotList1, saleLotList2)) {
    return false;
  }
  return saleLotList1.every(
    (sl, i) => saleLotList2[i] && saleLotIsEqual(sl, saleLotList2[i]),
  );
};

/**
 * compare two lists of auction pens for any change for the purpose
 * of limiting re-rendering in shouldComponentUpdate
 *
 * @param {array} list1
 * @param {array} list2
 * @returns {boolean}
 */
export const auctionPenListsAreEqual = (list1, list2) => {
  if (!listsAreSameLength(list1, list2)) {
    return false;
  }
  return list1.every(
    (ap, i) =>
      list2[i] &&
      ap.status === list2[i].status &&
      ap.saleRoundId === list2[i].saleRoundId &&
      ap.order === list2[i].order &&
      ap.endPen === list2[i].endPen &&
      ap.startPen === list2[i].startPen &&
      ap.isLaneStart === list2[i].isLaneStart &&
      ap.vendorName === list2[i].vendorName &&
      saleLotsListsAreEqual(ap.saleLots, list2[i].saleLots),
  );
};

/**
 * compare two lists of consignments for any change for the purpose
 * of limiting re-rendering in shouldComponentUpdate
 *
 * @param {array} list1
 * @param {array} list2
 * @returns {boolean}
 */
export const consignmentListsAreEqual = (list1, list2) => {
  if (!listsAreSameLength(list1, list2)) {
    return false;
  }

  return list1.every(
    (c, i) =>
      list2[i] &&
      c.status === list2[i].status &&
      c.vendor === list2[i].vendor &&
      saleLotsListsAreEqual(c.saleLots, list2[i].saleLots),
  );
};

/**
 * compare two lists of vendor lists for any change for the purpose
 * of limiting re-rendering in shouldComponentUpdate
 *
 * @param {array} list1
 * @param {array} list2
 * @returns {boolean}
 */
export const vendorListsAreEqual = (list1, list2) => {
  if (!listsAreSameLength(list1, list2)) {
    return false;
  }

  return list1.every(
    (v, i) =>
      list2[i] &&
      v.id === list2[i].id &&
      v.quantity === list2[i].quantity &&
      v.scannedCount === list2[i].scannedCount &&
      v.consignmentsScannedCount === list2[i].consignmentsScannedCount &&
      consignmentListsAreEqual(v.consignments, list2[i].consignments),
  );
};

/*
  compare two date strings - for use in an array.sort(compareDates)

 * @param {string} a
 * @param {string} b
 * @returns {integer}
 */
export const compareDates = (a, b) => {
  const l = new Date(a);
  const r = new Date(b);
  if (l < r) {
    return -1;
  } else if (l > r) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * A flat comparison of field.value (generally a formikd field) to option.value (a react select option).
 * Returns a function that can be used in a find, filter, etc.
 *
 * @param {object} field
 * @param {object} option
 * @returns {function}
 */
export const simpleOptionComparer = field => {
  return option => option.value === field.value;
};

/**
 * A comparison of field.value.id (generally a formikd field) to option.value.id (a react select option).
 * Returns a function that can be used in a find, filter, etc.
 *
 * @param {object} field
 * @param {object} option
 * @returns {function}
 */
export const idOptionComparer = field => {
  return option =>
    field?.value?.id && option?.value?.id && option.value.id === field.value.id;
};

/**
 * Compare Attachments for sorting
 * - assigned a type at the end.
 * - being uploaded in the middle.
 * - uploaded at the top.
 * - then by id.
 * @param {Attachment} a
 * @param {Attachment} b
 * @returns {number}
 */
export const compareAttachments = (a, b) => {
  if (a.type && !b.type) {
    return 1;
  }
  if (!a.type && b.type) {
    return -1;
  }
  if (a.progress > b.progress) {
    return -1;
  }
  if (a.progress < b.progress) {
    return 1;
  }
  if (a.isFinished === false && b.isFinished !== false) {
    return 1;
  }
  if (a.isFinished !== false && b.isFinished === false) {
    return -1;
  }
  if (a.image_url && b.image_url) {
    const aFileName = extractFileNameFromURL(a.image_url);
    const bFileName = extractFileNameFromURL(b.image_url);
    if (aFileName !== bFileName) {
      return caseInsensitiveCompare(aFileName, bFileName);
    }
  } else if (!a.image_url) {
    return -1;
  } else if (!b.image_url) {
    return 1;
  }
  if (a.id > b.id) {
    return 1;
  }
  if (a.id < b.id) {
    return -1;
  } else {
    return 0;
  }
};

// Recursively compare the all of the properties in the `object` Object against the `base` Object
const changes = (object, base) =>
  transform(object, (result, value, key) => {
    // Compare each property on the in the current level of the `object` Object to the `base` Object
    if (!isEqual(value, base[key])) {
      result[key] =
        // When the two values differ, and key is typeof "object", recursively call `changes` on it
        isObject(value) && isObject(base[key])
          ? changes(value, base[key])
          : // When the value for the current key is not an object, assign the value from the `object` Object to the equivalent path in the difference/result object
            value;
    }
  });

/**
 * Deeply compares two objects, `base` and `object`.
 * Returns a _patch_ object containing only the differences between the two objects,
 */
export const difference = (object, base) => {
  return changes(object, base);
};

export const floatIsEqual = (value1, value2) =>
  isEqual(parseFloat(value1), parseFloat(value2));

export const compareAgGridColumnStates = (state1, state2, autoSize) => {
  if (autoSize) {
    return isEqual(
      state1.map(col => omit(col, "width")),

      state2.map(col => omit(col, "width")),
    );
  }
  if (Array.isArray(state1) && Array.isArray(state2)) {
    const effectiveState1 = state1.filter(colState => !colState.hide);
    const effectiveState2 = state2.filter(colState => !colState.hide);
    return isEqual(effectiveState1, effectiveState2);
  }
  return false;
};

export const caselessCompare = (string1, string2) => {
  /**
   * Returns a boolean values based on the comparison of two strings
   * regardless of casing
   */
  const normalisedString1 =
    typeof string1 === "string" ? string1.toLowerCase() : "";
  const normalisedString2 =
    typeof string2 === "string" ? string2.toLowerCase() : "";

  if (normalisedString1 && normalisedString2) {
    return normalisedString1 === normalisedString2;
  }
  return false;
};

function getFieldInInterdependentFields(interdependentFieldSets, field) {
  return flatten(
    interdependentFieldSets.filter(fieldSet => fieldSet.includes(field)),
  );
}

function setFieldValue(
  intoObject,
  key,
  value,
  fromObject,
  interdependentFieldSets,
) {
  intoObject[key] = value;

  // If this key is one that may affect others, flag the others as changes, too.
  getFieldInInterdependentFields(interdependentFieldSets, key).forEach(
    field => {
      intoObject[field] = fromObject[field];
    },
  );
}

/**
 * Returns a new object that contains all values that have changed between initial and updated value sets - used
 * to trim data that isn't needed.  If there are any field changes that necessitate flagging other fields for changes (specifically, but not limited to quantity vs total price)
 * they can be passed into a nested array of interdependentFieldsets
 * @param {Object} initialValues
 * @param {Object} updatedValues
 * @param {Array} interdependentFieldSets
 * @return {Object}
 */
export function deepObjectChanges(
  initialValues,
  updatedValues,
  interdependentFieldSets = [],
) {
  // Go through all keys in the updated values, and see if they differ from the initial values - if so we want to
  // return them.
  const diff = Object.entries(updatedValues).reduce((acc, [key, val]) => {
    if (Array.isArray(val)) {
      // if the lengths are different, index comparison could get messy.  Pull in all data.
      if (val.length !== initialValues[key]?.length) {
        acc[key] = val;
      } else {
        // If the lengths are the same, we want to compare each and update
        const subDiff = val.map((subVal, idx) => {
          const subChanges = deepObjectChanges(
            initialValues[key]?.[idx] || {},
            subVal,
            interdependentFieldSets,
          );
          if (isEmpty(subChanges)) {
            return null;
          }
          return subChanges;
        });
        // If all of them are null, nothing has actually changed, nothing to do.
        if (!subDiff.every(obj => obj === null)) {
          // Otherwise, null values are the original, changed are the changed.
          acc[key] = subDiff.map((obj, idx) =>
            obj === null ? updatedValues[key]?.[idx] : obj,
          );
        }
      }
    } else if (typeof val === "object" && val !== null) {
      // If the item wasn't in the initial dataset, include in the result
      if (!initialValues[key]) {
        acc[key] = updatedValues[key];
      } else {
        // Compare what was in the initial vs this one.
        const subDiff = deepObjectChanges(
          initialValues[key],
          val,
          interdependentFieldSets,
        );
        if (!isEmpty(subDiff)) {
          setFieldValue(
            acc,
            key,
            subDiff,
            updatedValues,
            interdependentFieldSets,
          );
        }
      }
    } else if (!isEqual(initialValues[key], updatedValues[key])) {
      setFieldValue(
        acc,
        key,
        updatedValues[key],
        updatedValues,
        interdependentFieldSets,
      );
    }
    return acc;
  }, {});

  return diff;
}
