import Commands from "@agrinous/epson-escpos";
import { commandCombiner } from "@agrinous/epson-escpos/lib/utils";

import { fieldAndValuePair, cutMark, hr, receiptHeader } from "./common";

const {
  setBoldMode,
  setFontSize,
  setTabstops,
  printAndFeedLines,
  setFontMode,
  lineFeed,
  text,
  setJustification,
  BOLD_MODE,
  FONT_MODE,
  JUSTIFICATION_MODE,
  horizontalTab,
} = Commands;

/**
 * @typedef {Object} LotSummary
 * @property {String} buyerWay
 * @property {String} destinationProperty
 * @property {Number} headCount
 * @property {Number} totalPrice
 * @property {Number} avgPrice
 */

/**
 *
 * @param {LotSummary} lotInfo
 * @returns {Array<ArrayBuffer>}
 */
function lotSummary(lotInfo) {
  const { buyerWay, destinationProperty, headCount, totalPrice, avgPrice } =
    lotInfo;

  return [
    hr(),
    lineFeed(),
    setFontSize(2, 2),
    setTabstops([14]),
    setFontMode(FONT_MODE.FONT_B),
    setBoldMode(BOLD_MODE.BOLD_ON),
    text("Buyer Way:\t"),
    setBoldMode(BOLD_MODE.BOLD_OFF),
    setFontMode(FONT_MODE.FONT_A),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_RIGHT),
    text(buyerWay),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_LEFT),
    lineFeed(),
    setFontMode(FONT_MODE.FONT_B),
    setBoldMode(BOLD_MODE.BOLD_ON),
    text("Total Head Count:"),
    horizontalTab(),
    setBoldMode(BOLD_MODE.BOLD_OFF),
    setFontMode(FONT_MODE.FONT_A),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_RIGHT),
    text(headCount.toString()),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_LEFT),
    lineFeed(),
    setFontSize(1, 1),
    setTabstops([14, 24, 36]),
    fieldAndValuePair("Destination PIC", destinationProperty),
    horizontalTab(),
    fieldAndValuePair("Total Price", `$${totalPrice.toFixed(2)}`),
    lineFeed(),
    fieldAndValuePair("Average Price", `$${avgPrice.toFixed(2)}`),
    lineFeed(),
    printAndFeedLines(1),
  ];
}

/**
 *
 * @param {String} saleyardName
 * @param {String} agencyName
 * @param {String} user
 * @param {Number} livestockSaleId
 * @param {String} buyerName
 * @param {Array<LotSummary>} lotSummaryList
 * @param {Number} totalHeadcount
 * @param {Number} totalValue
 * @param {Number} avgValue
 * @returns {ArrayBuffer}
 */
export default function buyerSummaryTemplate(
  saleyardName,
  livestockSaleId,
  agencyName,
  user,
  buyerName,
  lotSummaryList,
  totalHeadcount,
  totalValue,
  avgValue,
) {
  let commandList = [
    receiptHeader(
      "Buyer Summary",
      saleyardName,
      livestockSaleId,
      agencyName,
      user,
    ),
    fieldAndValuePair("Buyer", buyerName),
  ];

  lotSummaryList.forEach(lotInfo => {
    const lotSummaryCommands = lotSummary(lotInfo);
    commandList = commandList.concat(lotSummaryCommands);
  });

  commandList = commandList.concat(
    hr(),
    lineFeed(),
    setTabstops([2]),
    setFontSize(2, 2),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_RIGHT),
    fieldAndValuePair("Total HC", totalHeadcount.toString()),
    setFontSize(1, 1),
    printAndFeedLines(2),
    fieldAndValuePair("Total Value", ` $${totalValue.toFixed(2)}`),
    lineFeed(),
    fieldAndValuePair("Average Value", ` $${avgValue.toFixed(2)}`),
    lineFeed(),
    setFontMode(FONT_MODE.FONT_B),
    text("* gross prices only, subject to change."),
    setFontMode(FONT_MODE.FONT_A),
    setJustification(JUSTIFICATION_MODE.JUSTIFICATION_LEFT),
    lineFeed(),
    cutMark(),
  );

  return commandCombiner(commandList);
}
