import { useState } from "react";

import useChange from "@react-hook/change";

import { useTimeoutRef } from "./useTimeoutRef";

export const useChangeFlash = (value, delayMs = 1000) => {
  const [changedClass, setChangedClass] = useState(null);

  const timerRef = useTimeoutRef(null);

  useChange(value, () => {
    setChangedClass("changeFlash");
    timerRef.current = setTimeout(() => {
      setChangedClass(null);
    }, delayMs);
  });

  return changedClass;
};
