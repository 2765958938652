import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const reportJobSerializerMap = {
  businessIds: "business_ids",
  created: "created",
  deploymentId: "deployment_id",
  emailIds: "email_ids",
  id: "id",
  lastModified: "last_modified",
  livestockSaleId: "livestock_sale_id",
  renderStatus: "render_status",
  report: "report",
  saleyardId: "saleyard_id",
  status: "status",
  title: "title",
};

export const serializeReportJob = buildSerializer(
  reportJobSerializerMap,
  "reportJob",
);

export const deserializeReportJob = buildDeserializer(
  reportJobSerializerMap,
  "reportJob",
  {},
);

const bulkCreateReportJobSerializerMap = {
  businessIds: "business_id",
  startDate: "start_date",
  endDate: "end_date",
};

export const serializeReportJobBulkCreate = buildSerializer(
  bulkCreateReportJobSerializerMap,
  "reportJob",
);
