import { isEqual } from "lodash";
import reduceReducers from "reduce-reducers";

import { AUCTION_PEN } from "constants/actionTypes";
import { PenTypes } from "constants/auctionPens";

import { sortPenByOrder } from "lib/auctionPens";
import {
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  bulkUpdateCases,
  reduceById,
} from "lib/reducers";
import {
  deserializeAuctionPen,
  deserializePenOwner,
} from "lib/serializers/auctionPens";

const create = apiModelOfflineCreateReducer(AUCTION_PEN, {
  deserializer: deserializeAuctionPen,
});

const fetch = apiModelOfflineFetchReducer(AUCTION_PEN, {
  deserializer: deserializeAuctionPen,
});

const update = apiModelOfflineUpdateReducer(AUCTION_PEN, {
  deserializer: deserializeAuctionPen,
});

const deleteReducer = apiModelOfflineDeleteReducer(AUCTION_PEN);

function bulkPenReducer(state, action) {
  switch (action.type) {
    case AUCTION_PEN.UPDATE_BULK.REQUEST:
    case AUCTION_PEN.UPDATE_BULK.SUCCESS:
    case AUCTION_PEN.UPDATE_BULK.FROM_SOCKET: {
      return bulkUpdateCases(state, action, "byId", deserializeAuctionPen);
    }
    default:
      return state;
  }
}

function penOwnerReducer(state, action) {
  // Creating or deleting a pen owner returns a serialized pen, with the change incorporated.
  switch (action.type) {
    case AUCTION_PEN.OWNER.CREATE.REQUEST: {
      const { auctionPenId } = action;
      const updatedPen = {
        ...state.byId[auctionPenId],
        penOwners: [
          ...state.byId[auctionPenId].penOwners,
          deserializePenOwner(action.payload),
        ],
      };
      return {
        ...state,
        byId: {
          ...state.byId,
          [auctionPenId]: updatedPen,
        },
      };
    }
    case AUCTION_PEN.OWNER.DELETE.REQUEST: {
      // TODO - this is not tested in anger, as it's not currently available as an action
      const { auctionPenId } = action;
      const ownerToDelete = deserializePenOwner(action.payload);

      const penOwners = state.byId[auctionPenId].penOwners.filter(
        owner => !isEqual(ownerToDelete, owner),
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [auctionPenId]: {
            ...state.byId[auctionPenId],
            penOwners,
          },
        },
      };
    }
    case AUCTION_PEN.OWNER.CREATE.SUCCESS:
    case AUCTION_PEN.OWNER.DELETE.SUCCESS:
      const data = deserializeAuctionPen(action.payload);
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: data,
        },
      };
    default:
      return state;
  }
}

function spliceOrderedPen(
  auctionPenById,
  auctionPenId,
  targetPenId,
  insertOffset = 0,
) {
  const orderedAuctionPens = sortPenByOrder(
    Object.values(auctionPenById).filter(
      pen => pen.penType === PenTypes.SELLING,
    ),
  );

  const removeIndex = orderedAuctionPens.findIndex(
    auctionPen => auctionPen.id === auctionPenId,
  );

  const insertIndex = orderedAuctionPens.findIndex(
    auctionPen => auctionPen.id === targetPenId,
  );

  const destIndex =
    insertOffset + insertIndex + (removeIndex < insertIndex ? 0 : 1);

  const auctionPens = orderedAuctionPens;
  const removedItem = auctionPens.splice(removeIndex, 1)[0];
  auctionPens.splice(destIndex, 0, removedItem);

  return [destIndex, removeIndex, auctionPens];
}

function resolveReorder(
  auctionPenById,
  auctionPenId,
  targetPenId,
  insertOffset = 0,
) {
  const [insertIndex, removeIndex, auctionPens] = spliceOrderedPen(
    auctionPenById,
    auctionPenId,
    targetPenId,
    insertOffset,
  );

  if (insertIndex === -1 || removeIndex === -1) {
    return auctionPenById;
  }

  const firstOrder = insertIndex === 0 ? 0 : auctionPens[0].order;

  return auctionPens.reduce(
    (newState, auctionPen) => {
      newState.auctionPenById[auctionPen.id] = {
        ...auctionPen,
        order: ++newState.lastOrder,
      };
      return newState;
    },
    { auctionPenById: { ...auctionPenById }, lastOrder: firstOrder },
  ).auctionPenById;
}

function reorderPenReducer(state, action) {
  switch (action.type) {
    case AUCTION_PEN.ORDER_AFTER.REQUEST: {
      const { afterPenId, auctionPenId } = action;
      return {
        ...state,
        byId: resolveReorder(state.byId, auctionPenId, afterPenId),
      };
    }
    case AUCTION_PEN.ORDER_BEFORE.REQUEST: {
      const { beforePenId, auctionPenId } = action;
      return {
        ...state,
        byId: resolveReorder(state.byId, auctionPenId, beforePenId, -1),
      };
    }
    case AUCTION_PEN.SET_SELLING_ORDER.REQUEST: {
      return bulkUpdateCases(state, action, "byId", deserializeAuctionPen);
    }

    case AUCTION_PEN.ORDER_BEFORE.SUCCESS:
    case AUCTION_PEN.ORDER_AFTER.SUCCESS:
    case AUCTION_PEN.SET_SELLING_ORDER.SUCCESS:
    case AUCTION_PEN.ORDER_SELLING_NOW.SUCCESS: {
      const lastModifiedTimestamp =
        action.meta?.lastModifiedTimestamp || state.lastModifiedTimestamp;
      return {
        ...state,
        isFetching: false,
        byId: reduceById(action.payload.map(deserializeAuctionPen)),
        lastModifiedTimestamp,
      };
    }
    default:
      return state;
  }
}
const auctionPensReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
  bulkPenReducer,
  penOwnerReducer,
  reorderPenReducer,
);

export default auctionPensReducer;
