import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import BusinessPICSelector from "components/BusinessPICSelector";
import ErrorMessage from "components/ErrorMessage";
import { AgencyInputField, SaleRoundPickerField } from "components/Form/Fields";
import PenningSaleLotForm from "components/PenningSaleLotForm";

import { BUYER } from "constants/businesses";

const businessRoles = [BUYER];

export function ScanToPenFormComponent(props) {
  const { showAgencyPicker = false } = props;

  const formikProps = useFormikContext();

  const { values, errors } = formikProps;

  const { round_id: roundId } = values;

  return (
    <>
      {errors.system && (
        <Grid item xs={12}>
          <ErrorMessage visible>{errors.system}</ErrorMessage>
        </Grid>
      )}
      {showAgencyPicker && (
        <Grid item xs={12}>
          <AgencyInputField name="agency_id" required isCurrentSaleOnly />
        </Grid>
      )}
      <>
        <Grid item xs={12}>
          <SaleRoundPickerField name="round_id" required />
        </Grid>

        <PenningSaleLotForm saleRoundId={roundId} autoFocusStartPen />

        <Grid item xs={12}>
          <BusinessPICSelector
            allowBusinessOnly
            allowPropertyOnly={false}
            businessFieldName="buyer_id"
            businessRoles={businessRoles}
            label="Buyer"
            propertyFieldName="destination_property_id"
            formikProps={formikProps}
            optional
          />
        </Grid>
      </>
    </>
  );
}
ScanToPenFormComponent.propTypes = {
  showAgencyPicker: PropTypes.bool,
};

export default memo(ScanToPenFormComponent);
