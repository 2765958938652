import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Column } from "components/Layout";
import ProgressBar from "components/ProgressBar";

const Wrapper = styled(Column)`
  margin-bottom: 6px;
  margin-right: 10px;
`;
const TextContainer = styled.div`
  color: #58c1ac;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
`;

const ProgressBarSales = ({ sold, total }) => {
  const progress = (sold * 100) / total;
  return (
    <Wrapper>
      <TextContainer>{`${sold} sold`}</TextContainer>
      <ProgressBar
        style={{
          height: "6px",
          width: "200px",
        }}
        progress={progress}
      />
    </Wrapper>
  );
};

ProgressBarSales.propTypes = {
  total: PropTypes.number.isRequired,
  sold: PropTypes.number.isRequired,
};

export default ProgressBarSales;
