import { uniq } from "lodash";
import { createSelector } from "reselect";

import {
  getBillingTags,
  getRuleBooks,
  getSaleSubTypes,
  selectCustomisationsByRuleIdLookup,
  selectParsedRuleContentByRuleIdLookup,
  selectRulesByRuleBookIdLookup,
} from "selectors";

export const selectRuleBooksAggridData = createSelector(
  [
    getRuleBooks,
    selectRulesByRuleBookIdLookup,
    selectParsedRuleContentByRuleIdLookup,
    getBillingTags,
    getSaleSubTypes,
    selectCustomisationsByRuleIdLookup,
  ],
  (
    ruleBookByIdLookup,
    rulesByRuleBookIdLookup,
    parsedRuleContentByRuleIdLookup,
    billingTagLookup,
    saleSubTypeByIdLookup,
    customisationsByRuleIdLookup,
  ) =>
    Object.entries(ruleBookByIdLookup).map(
      ([ruleBookId, ruleBook]: [string, RuleBook]) => {
        const rules: Rule[] = rulesByRuleBookIdLookup[ruleBookId] || [];
        const parsedRules =
          rules.map(rule => parsedRuleContentByRuleIdLookup[rule.id]) || [];

        const tagIds = [];

        parsedRules.forEach(rule => {
          const billingTagsIds = rule.billing_tags || [];
          billingTagsIds.forEach(tagId => tagIds.push(tagId));
        });

        const categories = uniq(parsedRules.map(rule => rule.category));

        const billingTags = uniq(tagIds.map(tagId => billingTagLookup[tagId]));

        const { sale_sub_type_ids: subSaleTypeIds = [] } = ruleBook;

        const saleSubTypes = subSaleTypeIds.map(
          saleSubTypeId => saleSubTypeByIdLookup[saleSubTypeId],
        );

        const isCustomised =
          rules.some(rule => customisationsByRuleIdLookup[rule.id]) || null;

        return {
          isCustomised,
          billingTags,
          categories,
          ruleBook,
          rulesCount: rules.length,
          saleSubTypes,
        };
      },
    ),
);
