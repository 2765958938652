import { put, select, takeEvery } from "redux-saga/effects";

import { BillingRunAction } from "actions";

import { BILLING_RUN } from "constants/actionTypes";

import {
  getActiveBillingRunId,
  getBillingRuns,
  getIsReadOnlyByBillingRunId,
} from "selectors";

function* onEnableBillingRun(action) {
  const { livestockSaleIds } = action;
  yield put(BillingRunAction.request({ livestockSalesIdIn: livestockSaleIds }));
}

function* onCreateBillingRunSuccess(action) {
  yield put(BillingRunAction.subscribe(action.payload.id));
}

function* onSubscribeBillingRun(action) {
  const { id } = action;
  const state = yield select();
  const readOnly = getIsReadOnlyByBillingRunId(id)(state);
  if (readOnly === false) {
    yield put(BillingRunAction.checkForChanges(id));
  }
}

function* onFetchSuccess(action) {
  // If there is only one BillingRun, make it active.
  if (!action.meta.cacheHit) {
    const state = yield select();
    const billingRuns = getBillingRuns(state);
    if (Object.keys(billingRuns).length === 1) {
      const onlyBillingRunId = Object.keys(billingRuns)[0];
      const activeBillingRunId = getActiveBillingRunId(state);
      if (activeBillingRunId !== onlyBillingRunId) {
        yield put(BillingRunAction.subscribe(onlyBillingRunId));
      }
    }
  }
}

export default function* billingRunSagas() {
  yield takeEvery(BILLING_RUN.ENABLE.ACTION, onEnableBillingRun);
  yield takeEvery(BILLING_RUN.CREATE.SUCCESS, onCreateBillingRunSuccess);
  yield takeEvery(BILLING_RUN.SUBSCRIBE.ACTION, onSubscribeBillingRun);
  yield takeEvery(BILLING_RUN.FETCH_BULK.SUCCESS, onFetchSuccess);
  yield takeEvery(BILLING_RUN.FETCH_CHANGES.SUCCESS, onFetchSuccess);
}
