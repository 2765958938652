import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "constants/actionTypes";

import { reduceById } from "lib/reducers";

const initialState = {
  isFetching: false,
  products: {},
};

function products(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS.REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };

    case GET_PRODUCTS.SUCCESS:
      return {
        ...state,
        products: reduceById(action.products),
        isFetching: false,
      };

    case GET_PRODUCTS.FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case UPDATE_PRODUCT.REQUEST: {
      const updatedProducts = reduceById(action.payload, { syncing: true });
      return {
        ...state,
        products: {
          ...state.products,
          ...updatedProducts,
        },
      };
    }

    case ADD_PRODUCT.REQUEST: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.id]: {
            ...action.payload,
            syncing: true,
          },
        },
      };
    }

    case ADD_PRODUCT.SUCCESS:
    case ADD_PRODUCT.FROM_SOCKET: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.id]: action.payload,
        },
      };
    }

    case UPDATE_PRODUCT.SUCCESS:
    case UPDATE_PRODUCT.FROM_SOCKET: {
      const updatedProducts = reduceById(action.payload);
      return {
        ...state,
        products: {
          ...state.products,
          ...updatedProducts,
        },
      };
    }

    case UPDATE_PRODUCT.FAILURE:
    case ADD_PRODUCT.FAILURE: {
      const id = action.meta.tempId;
      const newProducts = { ...state.products };

      if (newProducts[id]) {
        newProducts[id].syncing = false;
        newProducts[id].syncError = true;
      }
      return {
        ...state,
        products: newProducts,
      };
    }

    case DELETE_PRODUCT.REQUEST:
      const { productId } = action.meta;
      return {
        ...state,
        products: {
          ...state.products,
          [productId]: {
            ...state.products[productId],
            deleted: true,
          },
        },
      };

    case DELETE_PRODUCT.SUCCESS:
    case DELETE_PRODUCT.FROM_SOCKET: {
      const { productId } = action.meta;
      const newProducts = { ...state.products };
      delete newProducts[productId];
      return {
        ...state,
        products: newProducts,
      };
    }

    case DELETE_PRODUCT.FAILURE: {
      const { productId } = action.meta;
      return {
        ...state,
        products: {
          ...state.products,
          [productId]: {
            ...state.products[productId],
            deleted: undefined,
            syncError: true,
          },
        },
      };
    }

    default:
      return state;
  }
}

export default products;
