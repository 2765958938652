import { Column, ColumnDisplayName } from "constants/columns";
import { PricingTypes } from "constants/pricingTypes";

export function unitPriceRenderer({ data }) {
  if (!data) {
    return null;
  }

  return data[Column.UNIT_PRICE_FORMATTED];
}

export function unitPriceHeaderGetter(params) {
  const { api, data } = params;
  const { rowModel } = api;
  if (data && rowModel.getRowCount() > 0) {
    return PricingTypes.toString(rowModel.getRow(0).data[Column.PRICING_TYPE]);
  }

  return ColumnDisplayName[Column.UNIT_PRICE];
}
