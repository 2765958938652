import { flatten, uniq } from "lodash";
import { createSelector } from "reselect";

import { MAX_PEN_RANGE, PenTypes } from "constants/auctionPens";

import { getTakenPens, sortPenByOrder } from "lib/auctionPens";

import {
  getAuctionPens,
  selectAuctionPenIdBySaleLotIdLookup,
  selectDeliveryPenIdBySaleLotIdLookup,
  selectEidsBySaleLotIdLookup,
  selectSaleLotIdByEidLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleLotIdsByDeploymentSaleIdLookup,
} from "selectors";

export const selectAuctionPenIdsByDeploymentSaleIdLookup = createSelector(
  [
    selectSaleLotIdsByDeploymentSaleIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
  ],
  (saleLotIdsByDeploymentSaleIdLookup, auctionPenIdBySaleLotIdLookup) =>
    Object.entries(saleLotIdsByDeploymentSaleIdLookup).reduce(
      (acc, [deploymentSaleId, saleLotIds]) => {
        acc[deploymentSaleId] = saleLotIds.map(
          saleLotId => auctionPenIdBySaleLotIdLookup[saleLotId],
        );
        return acc;
      },
      {},
    ),
);
export const getAuctionPenIdsByDeploymentSaleId = deploymentSaleId => state =>
  selectAuctionPenIdsByDeploymentSaleIdLookup(state)[deploymentSaleId];

export const selectDeliveryPenIdsByDeploymentSaleIdLookup = createSelector(
  [
    selectSaleLotIdsByDeploymentSaleIdLookup,
    selectDeliveryPenIdBySaleLotIdLookup,
  ],
  (saleLotIdsByDeploymentSaleIdLookup, deliveryPenIdBySaleLotIdLookup) =>
    Object.entries(saleLotIdsByDeploymentSaleIdLookup).reduce(
      (acc, [deploymentSaleId, saleLotIds]) => {
        acc[deploymentSaleId] = saleLotIds.map(
          saleLotId => deliveryPenIdBySaleLotIdLookup[saleLotId],
        );
        return acc;
      },
      {},
    ),
);
export const getDeliveryPenIdsByDeploymentSaleId = deploymentSaleId => state =>
  selectDeliveryPenIdsByDeploymentSaleIdLookup(state)[deploymentSaleId];

export const selectAuctionPenIdsByConsignmentIdLookup = createSelector(
  [selectAuctionPenIdBySaleLotIdLookup, selectSaleLotIdsByConsignmentIdLookup],
  (auctionPenIdBySaleLotIdLookup, saleLotIdsByConsignmentIdLookup) =>
    Object.entries(saleLotIdsByConsignmentIdLookup).reduce(
      (acc, [consignmentId, saleLotIds]) => {
        acc[consignmentId] = saleLotIds.reduce(
          (slAcc, saleLotId) =>
            slAcc.concat(auctionPenIdBySaleLotIdLookup[saleLotId]),
          [],
        );
        return acc;
      },
      {},
    ),
);
export const getAuctionPenIdsByConsignmentId = consignmentId => state =>
  selectAuctionPenIdsByConsignmentIdLookup(state)[consignmentId];

export const selectDeliveryPenIdsByConsignmentIdLookup = createSelector(
  [selectDeliveryPenIdBySaleLotIdLookup, selectSaleLotIdsByConsignmentIdLookup],
  (deliveryPenIdBySaleLotIdLookup, saleLotIdsByConsignmentIdLookup) =>
    Object.entries(saleLotIdsByConsignmentIdLookup).reduce(
      (acc, [consignmentId, saleLotIds]) => {
        acc[consignmentId] = saleLotIds.reduce(
          (slAcc, saleLotId) =>
            slAcc.concat(deliveryPenIdBySaleLotIdLookup[saleLotId]),
          [],
        );
        return acc;
      },
      {},
    ),
);

export const getDeliveryPenIdsByConsignmentId = consignmentId => state =>
  selectDeliveryPenIdsByConsignmentIdLookup(state)[consignmentId];

export const selectAuctionPensByRoundIdLookup = createSelector(
  [getAuctionPens],
  auctionPens =>
    Object.values(auctionPens)
      .filter(
        auctionPen =>
          auctionPen.penType === PenTypes.SELLING && auctionPen.saleRoundId,
      )
      .reduce((acc, auctionPen) => {
        if (!Array.isArray(acc[auctionPen.saleRoundId])) {
          acc[auctionPen.saleRoundId] = [];
        }
        acc[auctionPen.saleRoundId].push(auctionPen);
        return acc;
      }, {}),
);

export const selectPenRangesByAuctionPenIdLookup = createSelector(
  [selectAuctionPensByRoundIdLookup],
  auctionPensByRoundIdLookup => {
    const lookup = {};

    Object.values(auctionPensByRoundIdLookup).forEach(auctionPens => {
      // Goes through a list of auction pens and attempts to deduce which
      // Pens are between this, and the previous pen, then in turn whether they
      // are missing, or pens that are just put out of order.
      const sortedAuctionPens = sortPenByOrder(auctionPens);
      const takenPens = uniq(flatten(sortedAuctionPens.map(getTakenPens)));

      sortedAuctionPens.forEach((auctionPen, idx) => {
        const previousAuctionPen =
          idx > 0 ? sortedAuctionPens[idx - 1] : undefined;
        let penRangeStart;
        let penRangeEnd;
        if (auctionPen.startPenNumeric && previousAuctionPen) {
          const previousPenNumber =
            previousAuctionPen.endPenNumeric ||
            previousAuctionPen.startPenNumeric;
          if (
            previousPenNumber &&
            previousPenNumber + 1 < auctionPen.startPenNumeric
          ) {
            penRangeStart = previousPenNumber + 1;
            penRangeEnd = auctionPen.startPenNumeric - 1;
          }
        }
        let anyEmpty = false;
        let anyOutOfOrder = false;
        // Stupid shortcircuit - if there's a range of > MAX_PEN_RANGE (1000), it's likely not an actual pen.  Report empties.
        if (penRangeEnd - penRangeStart > MAX_PEN_RANGE) {
          anyEmpty = true;
        } else {
          for (
            let penIndex = penRangeStart;
            penIndex <= penRangeEnd;
            penIndex++
          ) {
            const penExists = takenPens.includes(penIndex);

            // Have we already found one that exists, OR does this one exist.
            if (penExists) {
              anyOutOfOrder = true;
            } else {
              anyEmpty = true;
            }
            // Bail if we've found both of what we're looking for
            if (anyEmpty && anyOutOfOrder) {
              break;
            }
          }
        }

        lookup[auctionPen.id] = {
          penRangeStart,
          penRangeEnd,
          anyOutOfOrder,
          anyEmpty,
        };
      });
    });

    return lookup;
  },
);

export const selectAuctionPensByLane = createSelector(
  [selectAuctionPensByRoundIdLookup],
  auctionPenByRoundIdLookup =>
    Object.entries(auctionPenByRoundIdLookup).reduce(
      (acc, [saleRoundId, auctionPens]) => {
        const sortedAuctionPens = sortPenByOrder(auctionPens);
        let currentStart;
        let currentList = [];
        const lanes = sortedAuctionPens.reduce((apAcc, auctionPen, idx) => {
          if (auctionPen.start_pen) {
            // If it's the first pen, or a new lane.
            if (typeof currentStart === "undefined") {
              currentStart = auctionPen.start_pen;
              currentList = [auctionPen.id];
            } else if (auctionPen.isLaneStart) {
              // The previous one was the end of the lane
              const previousauctionPen = sortedAuctionPens[idx - 1];
              apAcc[
                `${currentStart} -> ${
                  previousauctionPen.end_pen || previousauctionPen.start_pen
                }`
              ] = currentList;

              // And reset .
              currentStart = auctionPen.start_pen;
              currentList = [auctionPen.id];
            } else {
              currentList.push(auctionPen.id);
            }
          }
          return apAcc;
        }, {});

        // If we have left over, use the last one.
        if (currentList.length > 0) {
          const lastAuctionPen =
            sortedAuctionPens[sortedAuctionPens.length - 1];
          lanes[`${currentStart} -> ${lastAuctionPen.start_pen}`] = currentList;
        }
        acc[saleRoundId] = lanes;
        return acc;
      },
      {},
    ),
);
export const selectEidsByAuctionPenIdLookup = createSelector(
  [selectEidsBySaleLotIdLookup, selectAuctionPenIdBySaleLotIdLookup],
  (eidsBySaleLotIdLookup, auctionPenIdBySaleLotIdLookup) =>
    Object.entries(auctionPenIdBySaleLotIdLookup).reduce(
      (lookup, [saleLotId, auctionPenId]) => {
        if (!lookup[auctionPenId]) {
          lookup[auctionPenId] = [];
        }
        lookup[auctionPenId] = lookup[auctionPenId].concat(
          eidsBySaleLotIdLookup[saleLotId],
        );
        return lookup;
      },
      {},
    ),
);

export const selectAuctionPenIdByEidLookup = createSelector(
  [selectSaleLotIdByEidLookup, selectAuctionPenIdBySaleLotIdLookup],
  (saleLotIdByEidLookup, auctionPenIdBySaleLotIdLookup) =>
    Object.entries(saleLotIdByEidLookup).reduce((acc, [eid, saleLotId]) => {
      acc[eid] = auctionPenIdBySaleLotIdLookup[saleLotId] || null;
      return acc;
    }, {}),
);

export const selectEidsByDeliveryPenIdLookup = createSelector(
  [selectEidsBySaleLotIdLookup, selectDeliveryPenIdBySaleLotIdLookup],
  (eidsBySaleLotIdLookup, deliveryPenIdBySaleLotIdLookup) =>
    Object.entries(deliveryPenIdBySaleLotIdLookup).reduce(
      (lookup, [saleLotId, deliveryPenId]) => {
        if (!lookup[deliveryPenId]) {
          lookup[deliveryPenId] = [];
        }
        lookup[deliveryPenId] = lookup[deliveryPenId].concat(
          eidsBySaleLotIdLookup[saleLotId],
        );
        return lookup;
      },
      {},
    ),
);

export const selectDeliveryPenIdByEidLookup = createSelector(
  [selectSaleLotIdByEidLookup, selectDeliveryPenIdBySaleLotIdLookup],
  (saleLotIdByEidLookup, deliveryPenIdBySaleLotIdLookup) =>
    Object.entries(saleLotIdByEidLookup).reduce((acc, [eid, saleLotId]) => {
      acc[eid] = deliveryPenIdBySaleLotIdLookup[saleLotId] || null;
      return acc;
    }, {}),
);
