import React, { useCallback, useEffect, useState } from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import DOMPurify from "dompurify";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import { compose } from "redux";
import styled from "styled-components/macro";

import {
  closeConfirmModal,
  openConfirmModal,
  setCurrentLivestockSaleId,
  setSetting,
} from "actions";

import FailedRequestModal from "components/FailedRequestModal";
import HashRoute from "components/HashRoute";
import { Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import MessageBox from "components/MessageBox";
import { PendingChangesModal } from "components/PendingChanges";
import DefaultSidebarContent from "components/Sidebar/DefaultSidebarContent";
import ResponsiveSideBar from "components/Sidebar/ResponsiveSideBar";

import { ModalTypes } from "constants/navigation";
import { ScreenTypes } from "constants/screen";
import { Settings as SettingsEnum } from "constants/settings";
import { userTypes } from "constants/users";

import { AnalyticsHome } from "containers/Analytics";
import { NLISPanel } from "containers/Settings/NLIS";

import {
  getAnalyticsRoute,
  getDashboardRoute,
  getLivestockSaleId,
  getManageBusinessRoute,
  getMoneyTableRoute,
  getReportJobList,
  getSaleyardName,
  getSettingsRoute,
  openSaleModal,
} from "lib/navigation";
import { formatISO8601DateString } from "lib/timeFormats";
import toast from "lib/toast";

import {
  getAvailableSaleyards,
  getGlobalAnnouncement,
  getHasEnteredNlisCreds,
  getIsFetchingAuth,
  getIsOnline,
  getIsScaleOperator,
  getPrimaryAgency,
  getSales,
  getSettings,
  selectIsUserOfType,
} from "selectors";

import { useIsFullScreenWithRouteCheck } from "hooks";

import SaleyardHeader from "./SaleyardHeader";
import { SaleyardRoutes } from "./SaleyardRoutes";

const SaleyardHome = ({
  history,
  sales,
  screenType,
  stickySale,
  nlisRequired,
  nlisProvided,
  isUpdatingSales,
  saleyards,
  isOnline,
}) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isNotificationsBarVisible, setIsNotificationsBarVisible] =
    useState(true);

  const match = useRouteMatch();
  const saleyard = getSaleyardName();
  const location = useLocation();
  const canUserAddSale = useSelector(
    selectIsUserOfType([
      userTypes.LIVESTOCK_AGENT,
      userTypes.SALEYARD_ADMIN,
      userTypes.SCALE_OPERATOR,
    ]),
  );

  // TODO - should this check roles, etc?
  const isAuthFetching = useSelector(getIsFetchingAuth);

  const globalAnnouncement = useSelector(getGlobalAnnouncement);

  // using DOMpurify to help secure the innerHTML from the globalNotification
  // https://github.com/cure53/DOMPurify
  const sanitizer = DOMPurify.sanitize;

  useEffect(() => {
    // if globalAnnouncement changes - make sure to show the message
    if (globalAnnouncement) {
      setIsNotificationsBarVisible(true);
    }
  }, [globalAnnouncement]);

  const saleId = getLivestockSaleId();
  const dispatch = useDispatch();

  const goToSale = useCallback(
    saleId => {
      dispatch(setCurrentLivestockSaleId(saleId, true, false));
    },
    [dispatch],
  );

  const changeSaleyard = saleyard => {
    const { saleyard: oldSaleyard } = match.params;
    const newUrl = location.pathname.replace(oldSaleyard, saleyard);
    history.push(newUrl);
  };

  const newSale = useCallback(() => {
    if (isOnline) {
      openSaleModal();
    } else {
      toast.info("Cannot create new sale in offline mode.");
    }
  }, [isOnline]);

  const isFullScreen = useIsFullScreenWithRouteCheck();

  if (isAuthFetching) {
    return (
      <MessageBox>
        <LoadingSpinner size={24} />
      </MessageBox>
    );
  }

  const today = formatISO8601DateString(new Date());

  const nlisUpdateNeeded = nlisRequired && !nlisProvided && !isFullScreen;
  const forceNLISUpdate = nlisUpdateNeeded;
  const currentSale =
    stickySale && stickySale.stickySetAt === today
      ? sales[stickySale.livestockSaleId]
      : null;

  const showSaleSelector = ![
    getSettingsRoute(),
    getManageBusinessRoute(),
    getDashboardRoute(),
    getMoneyTableRoute(),
    getReportJobList(),
    getAnalyticsRoute(),
  ].some(page => window.location.pathname.startsWith(page));

  const hasNoSales = isEmpty(sales);

  function toggleSidebar() {
    setIsSidebarVisible(!isSidebarVisible);
  }

  const isMobile = screenType !== ScreenTypes.DESKTOP;

  const allowAddSale = !nlisUpdateNeeded && canUserAddSale;
  return (
    <>
      <Row fullHeight>
        {!isFullScreen && (
          <ResponsiveSideBar
            isOpen={isSidebarVisible}
            setIsOpen={setIsSidebarVisible}
            ContentElement={DefaultSidebarContent}
          />
        )}
        <MainWrapper id="main-wrapper">
          {globalAnnouncement && isNotificationsBarVisible && (
            <Row padding={2} justifyBetween alignCenter>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sanitizer(globalAnnouncement),
                }}
              />
              <div>
                <IconButton>
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => setIsNotificationsBarVisible(false)}
                  />
                </IconButton>
              </div>
            </Row>
          )}
          {!isFullScreen && (
            <SaleyardHeader
              isMobile={isMobile}
              onClickHamburgerMenu={toggleSidebar}
              showSaleSelector={showSaleSelector}
              saleyards={saleyards}
              newSale={allowAddSale && newSale}
              goToSale={goToSale}
              saleId={+saleId}
              changeSaleyard={changeSaleyard}
              saleyard={saleyard}
            />
          )}
          {forceNLISUpdate ? (
            <NLISPanel />
          ) : (
            <>
              {/* Always render the Analytics route i.e. outside of a <Switch> to prevent
               the component from un-mounting and causing the iframe to re-load */}
              <Route exact path={getAnalyticsRoute()}>
                {({ match }) => <AnalyticsHome isMatch={match} />}
              </Route>

              <SaleyardRoutes
                currentSale={currentSale}
                isUpdatingSales={isUpdatingSales}
                hasNoSales={hasNoSales}
              />
            </>
          )}
          <HashRoute
            component={PendingChangesModal}
            hash={ModalTypes.PendingChanges}
          />
        </MainWrapper>
      </Row>
      <FailedRequestModal />
    </>
  );
};

const mapStateToProps = state => ({
  nlisRequired: getIsScaleOperator(state),
  nlisProvided: getHasEnteredNlisCreds(state),
  isOnline: getIsOnline(state),
  sales: getSales(state),
  stickySale: getSettings(state)[SettingsEnum.stickySale],
  saleyards: getAvailableSaleyards(state),
  screenType: state.screen.type,
});

const mapDispatchToProps = {
  getPrimaryAgency,
  openConfirmModal,
  closeConfirmModal,
  setSetting,
};

SaleyardHome.propTypes = {
  history: PropTypes.object,
  sales: PropTypes.object,
  saleyards: PropTypes.array,
  screenType: PropTypes.string,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SaleyardHome,
);

const MainWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;
