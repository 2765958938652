import React, { memo, useMemo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { StatusCard } from "components/Card";
import {
  MetadataField,
  PrimaryHeading,
  SelfTitledField,
} from "components/Card/Elements";
import IdConnectedList from "components/IdConnectedList";
import LoadingSpinner from "components/LoadingSpinner";

import { UNKNOWN_BUSINESS_NAME } from "constants/businesses";

import { getConsignmentCode } from "lib/consignments";

import {
  getConsignmentById,
  getBusinessById,
  getIsFetchingBusinesses,
} from "selectors";

import { PickerLayout } from "./Layout";

const CardLayout = styled.div(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-template-rows: 1fr;
  grid-template-areas: "vendor code head eids";
  grid-gap: ${theme.space[1]}px;
`,
);

const Card = styled(StatusCard)`
  margin: 0;
`;

function ConsignmentsListItemComponent(props) {
  const { id: consignmentId, summary } = props;
  const consignment = useSelector(getConsignmentById(consignmentId));
  const vendor = useSelector(getBusinessById(consignment.vendor_id)) || {};
  const isFetchingBusinesses = useSelector(getIsFetchingBusinesses);

  const consignmentCode = getConsignmentCode(consignment);

  let vendorNameEl = UNKNOWN_BUSINESS_NAME;
  if (vendor) {
    vendorNameEl = vendor.name;
  } else if (isFetchingBusinesses) {
    vendorNameEl = <LoadingSpinner size={12} subjectName="Businesses" />;
  }
  const eidCount = summary[consignmentId].length;
  const consignmentHd = consignment.quantity;

  return (
    <Card>
      <CardLayout>
        <SelfTitledField
          gridArea="vendor"
          title="Vendor"
          value={vendorNameEl}
        />
        <MetadataField gridArea="code" title="Code" value={consignmentCode} />
        <MetadataField gridArea="head" title="Hd" value={consignmentHd} />
        <MetadataField gridArea="eids" title="EIDs">
          <PrimaryHeading color="success">{eidCount}</PrimaryHeading>
        </MetadataField>
      </CardLayout>
    </Card>
  );
}

const ConsignmentsListItem = memo(ConsignmentsListItemComponent);

function EmptyConsignmentsComponent() {
  return "No EIDs could be automatically allocated to Sale Lots";
}

export function ConsignmentsList(props) {
  const { summary } = props;
  const consignmentIds = useMemo(
    () =>
      Object.keys(summary).sort(
        (a, b) => summary[b].length - summary[a].length,
      ),
    [summary],
  );

  const options = useMemo(() => ({ summary }), [summary]);

  return (
    <PickerLayout>
      <h2>Consignments</h2>
      <div>
        <IdConnectedList
          component={ConsignmentsListItem}
          orderedIds={consignmentIds}
          emptyComponent={EmptyConsignmentsComponent}
          options={options}
        />
      </div>
    </PickerLayout>
  );
}
ConsignmentsList.propTypes = {
  summary: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};
