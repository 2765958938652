import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const WeightRangeSerializeMap = {
  saleRoundId: "sale_round_id",
  speciesId: "species_id",
  saleSubType: "sale_sub_type",
  id: "id",
  weightRangeMinKg: "weight_range_min_kg",
  weightRangeMaxKg: "weight_range_max_kg",
  shortCode: "short_code",
  details: "details",
  order: "order",
};

export const serializeWeightRanges = buildSerializer(
  WeightRangeSerializeMap,
  "weightRange",
);

export const deserializeWeightRanges = buildDeserializer(
  WeightRangeSerializeMap,
  "weightRange",
);
