import { GateStatus } from "./auctionPens";

export const SingleWeighStatus = {
  // Done - allow reconnection
  HUB_NOT_CONNECTED: "Hub not connected",
  PLC_NOT_CONNECTED: "PLC not connected",
  SCANNER_NOT_CONNECTED: "Scanner not connected",
  SCALES_NOT_CONNECTED: "Scales not connected",

  // Done - timeout
  EID_WAIT_TIMEOUT: "EID Timeout",
  STEADY_WAIT_TIMEOUT: "Steady Weight Timeout",

  ANIMAL_ALREADY_WEIGHED: "Animal already weighed",

  // TODO - prompt for reset?
  MULTIPLE_EIDS: "Multiple EIDs",

  // Done - relatively innocuous
  PAUSED: "Paused",
  DISABLED: "Disabled", // Whats the difference between paused and disabled?
  WAITING_FOR_EID: "Waiting for EID",
  WAITING_FOR_STEADY_WEIGHT: "Waiting for steady weight",
  WAITING_FOR_PLC: "Waiting for PLC",
  DRAFT_PENS_UNAVAILABLE: "No available draft pen", // Transient status - that should change after a time.

  // First time seeing this animal.
  UNKNOWN_EID: "Unknown EID",

  NO_DELIVERY_PEN: "No Delivery Pen Found",
  NO_SELLING_PEN: "No Selling Pen Found",
  DELIVERY_PEN_LOCKED: "Delivery Pen Locked",

  // Users shouldn't reallllly see this one, as the saga will reset our state.
  READY_TO_AUTO_DRAFT: "Ready To Auto Draft",

  // Actions created
  WAITING_FOR_PEN_SELECTION: "Waiting for pen selection",
};
/*
 Presale
   - show different columns on the table
   - destination pen is the AUCTION pen
   - requires action on no AUCTION pen
 Post sale
   - show different columns on the table
   - destination pen is the DELIVERY pen
   - requires action on no DELIVERY pen

  Auto
   - We send drafting decisions as soon as we can
  Semi Auto
   - We try and deduce the draft pen, and allocate pens, etc, but wait for the user to confirm the allocation, and allow them to override.
  Manual
   - We do not try and deduce the draft pen, and make the user decide where and when the animal goes.

 */

export const SingleWeighMode = {
  PRESALE_AUTO: "Presale - Auto",
  PRESALE_SEMI_AUTO: "Presale - Semi-Auto",
  PRESALE_MANUAL: "Presale - Manual",
  POST_SALE_AUTO: "Post Sale - Auto",
  POST_SALE_SEMI_AUTO: "Post Sale - Semi-Auto",
  POST_SALE_MANUAL: "Post Sale - Manual",
  DISABLED: "Disabled",
};

export const SingleWeighModeConfiguration = {
  [SingleWeighMode.PRESALE_AUTO]: {
    sendAutoDecision: true,
    makeAutoDecision: true,
    manualDecision: false,

    auctionPenAsDestinationPen: true,
    deliveryPenAsDestinationPen: false,
  },
  [SingleWeighMode.POST_SALE_AUTO]: {
    sendAutoDecision: true,
    makeAutoDecision: true,
    manualDecision: false,

    auctionPenAsDestinationPen: false,
    deliveryPenAsDestinationPen: true,
  },

  [SingleWeighMode.PRESALE_SEMI_AUTO]: {
    sendAutoDecision: false,
    makeAutoDecision: true,
    manualDecision: true,

    auctionPenAsDestinationPen: true,
    deliveryPenAsDestinationPen: false,
  },
  [SingleWeighMode.POST_SALE_SEMI_AUTO]: {
    sendAutoDecision: false,
    makeAutoDecision: true,
    manualDecision: true,

    auctionPenAsDestinationPen: false,
    deliveryPenAsDestinationPen: true,
  },
  [SingleWeighMode.PRESALE_MANUAL]: {
    sendAutoDecision: false,
    makeAutoDecision: false,
    manualDecision: true,

    auctionPenAsDestinationPen: true,
    deliveryPenAsDestinationPen: false,
  },

  [SingleWeighMode.POST_SALE_MANUAL]: {
    sendAutoDecision: false,
    makeAutoDecision: false,
    manualDecision: true,

    auctionPenAsDestinationPen: false,
    deliveryPenAsDestinationPen: true,
  },
  [SingleWeighMode.DISABLED]: {
    sendAutoDecision: false,
    makeAutoDecision: false,
    manualDecision: false,

    auctionPenAsDestinationPen: false,
    deliveryPenAsDestinationPen: false,
  },
};

export const ALL_SINGLE_WEIGH_MODES = Object.values(SingleWeighMode);

export const SingleWeighModeOptions = ALL_SINGLE_WEIGH_MODES.map(mode => ({
  label: mode,
  value: mode,
}));

export const PLCProtocolStatus = {
  UNKNOWN: 0,
  CONNECTING: 1,
  CONNECTED: 2,
  WAITING_FOR_DRAFTING_DECISION: 3,
};

// TODO - remove this when the options are actually available.
export const DEFAULT_DEBOUNCE = 300;
export const DEFAULT_MIN_WEIGHT = 0;
export const DEFAULT_STABLE_SAMPLE_THRESHOLD_GRAMS = 6000; // 6 kg
export const DEFAULT_STABLE_SAMPLE_COUNT = 5; // 5 samples

export const autoDraftPenInitialState = {
  destinationPenId: null,
  entryGateStatus: GateStatus.CLOSED,
  exitGateStatus: GateStatus.CLOSED,
};

export const AutoDraftPenStatus = {
  UNKNOWN: 0,
  FILLING: 1,
  READY_TO_EMPTY: 2,
  EMPTYING_OR_PASSTHROUGH: 3,
  DISABLED: 4,
  OVER_SCANNED: 5,
};

export const AutoDraftPenStatusColorMap = {
  [AutoDraftPenStatus.UNKNOWN]: "white",
  [AutoDraftPenStatus.FILLING]: "primary",
  [AutoDraftPenStatus.READY_TO_EMPTY]: "successGreen",
  [AutoDraftPenStatus.EMPTYING_OR_PASSTHROUGH]: "warningOrange",
  [AutoDraftPenStatus.DISABLED]: "gray78",
  [AutoDraftPenStatus.OVER_SCANNED]: "warningOrange",
};
