import { getLivestockSaleId } from "lib/navigation";
import { getBestMatchingVendorSplit } from "lib/vendorSplits";

import { getVendorSplitSummary } from "selectors";

export const ConsignmentVendorSplitGetter = ({ data, context }) => {
  const { deployment, vendorSplitByKeyLookup } = context;

  if (!data || !deployment || !vendorSplitByKeyLookup) {
    return "";
  }

  const livestockSaleId = getLivestockSaleId();
  const { id: deploymentId } = deployment;
  const { id: consignmentId, vendor_id: primaryBusinessId } = data;

  const vendorSplit = getBestMatchingVendorSplit({
    vendorSplitByKeyLookup,
    primaryBusinessId,
    deploymentId,
    livestockSaleId,
    consignmentId,
  });

  return getVendorSplitSummary(vendorSplit);
};

export const SaleLotVendorSplitGetter = ({ data, context }) => {
  const { deployment, vendorSplitByKeyLookup } = context;

  if (!data || !deployment || !vendorSplitByKeyLookup) {
    return "";
  }

  const livestockSaleId = getLivestockSaleId();
  const { id: deploymentId } = deployment;
  const {
    consignment_id: consignmentId,
    id: saleLotId,
    vendor_id: primaryBusinessId,
  } = data;

  const vendorSplit = getBestMatchingVendorSplit({
    vendorSplitByKeyLookup,
    primaryBusinessId,
    deploymentId,
    livestockSaleId,
    consignmentId,
    saleLotId,
  });

  return getVendorSplitSummary(vendorSplit);
};

export const PrivateSaleLotVendorSplitGetter = ({ data, context }) => {
  return SaleLotVendorSplitGetter({ data: data?.saleLot, context });
};

export const BusinessDefaultVendorSplitGetter = params => {
  const { data, context } = params;
  const { defaultSplitByBusinessIdLookup } = context;

  if (!data || !defaultSplitByBusinessIdLookup) {
    return "";
  }

  const { id: businessId } = data.business;
  const defaultVendorSplit = defaultSplitByBusinessIdLookup[businessId];

  return getVendorSplitSummary(defaultVendorSplit);
};
