import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

export const tradingTermSerializeMap = {
  id: "id",
  deploymentId: "deployment_id",
  buyerTerms: "buyer_terms",
  vendorTerms: "vendor_terms",
  saleSubTypeId: "sale_sub_type_id",
  speciesId: "species_id",
  saleType: "sale_type",
};

export const serializeTradingTerm = buildSerializer(
  tradingTermSerializeMap,
  "trading term",
);

export const deserializeTradingTerm = buildDeserializer(
  tradingTermSerializeMap,
  "trading term",
);
