import React from "react";

import { Formik } from "formik";
import { useSelector } from "react-redux";

import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";

import { ModalTypes } from "constants/navigation";

import { ModalContent } from "containers/Settings/RuleBooks/Library/ModalContent";

import { getContextByModalType } from "selectors";

export function RuleLibraryModalAdapter(props) {
  const { onClose } = props;

  const context =
    useSelector(getContextByModalType(ModalTypes.RuleLibrary)) || {};
  const { ruleBookId, masterRuleId } = context;

  if (!ruleBookId) {
    return null;
  }

  const initialSearchValues = {
    effectiveEndDate: null,
    filteredSaleSubTypes: [],
    searchText: null,
  };

  return (
    <Dialog open onClose={onClose} fullScreen maxWidth="md">
      <DialogTitle onClose={onClose}>Rule Library</DialogTitle>
      <Formik enableReinitialize initialValues={initialSearchValues}>
        <DialogContent>
          <ModalContent ruleBookId={ruleBookId} masterRuleId={masterRuleId} />
        </DialogContent>
      </Formik>
    </Dialog>
  );
}
