import maxBy from "lodash/maxBy";

export const productFields = ["sex_id", "breed_id", "age_id"];

export const getBestMatchingProductId = (
  saleLot,
  products,
  currentProductId,
) => {
  if (!saleLot) {
    return;
  }

  // Filter the list of products by products that have attributes defined and match with the given sale lot.
  const possibleProducts = products.filter(product =>
    productFields.every(f => {
      if (product[f]) {
        return product[f] === saleLot[f];
      }
      return true;
    }),
  );
  // Out of the possible products, we need to score the ones that do have common attributes.
  // For example a product could have only age defined, so it would be possible, but another
  // product could have age and breed defined and it would be possible.
  // We should prefer the possibility with the most matches.
  const scored = possibleProducts.map(p => {
    let score = 0;
    productFields.forEach(f => {
      if (p[f]) {
        // If the product has a value specified, move the score up or down depending on match.
        score += p[f] === saleLot[f] ? 1 : -1;
      }
    });
    return {
      ...p,
      score,
    };
  });

  const highestScore = maxBy(scored, "score");
  // If the currently selected product has the equal highest score, return it.
  if (
    highestScore &&
    scored.find(
      p => p.score === highestScore.score && p.id === currentProductId,
    )
  ) {
    return currentProductId;
  }

  return highestScore?.id;
};
