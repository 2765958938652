import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useCombobox } from "downshift";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Label } from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";
import { Column, Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";

import {
  AUSTRALIA_GEODETIC_COORDINATES,
  AUSTRALIA_RADIUS,
  PlacesServiceStatus,
  usePlaceAutocompleteService,
  usePlaceAutocompleteSessionToken,
} from "lib/googleMaps";

import { getIsOnline } from "selectors";

import { StyledInput } from "./layout";

const ListRelativeWrapper = styled.div(
  ({ isOpen }) => `
  position: relative;
  height: ${isOpen ? "auto" : "0"}px;
`,
);

const TIMEOUT = 400;

const SearchState = {
  NONE: 0,
  SEARCHING: 1,
  SEARCH_COMPLETE: 2,
  ERROR: 3,
};

function itemToString(item) {
  return item.label;
}

function GoogleMapsAutoCompleteSuggestionList(props) {
  const { disabled, onPlaceIdSelected, defaultAddressSearch } = props;
  const [items, setItems] = useState([]);
  const [searchState, setSearchState] = useState(SearchState.NONE);
  const [cleanedValue, setCleanedValue] = useState("");
  const timeoutRef = useRef(null);
  const placeAutocompleteService = usePlaceAutocompleteService();
  const sessionToken = usePlaceAutocompleteSessionToken();
  const isOnline = useSelector(getIsOnline);

  const onChangeInput = useCallback(
    a => {
      const { inputValue, isOpen, selectedItem } = a;
      if (!isOpen && selectedItem) {
        // An event is fired when an item is selected (and the input is closed) which we don't need to send and autocomplete request off for.
        return;
      }
      const nextCleanedValue = (inputValue || "").trim();

      if (nextCleanedValue !== cleanedValue) {
        // if the search value has changed

        // updated the search value
        setCleanedValue(nextCleanedValue);

        // check that there is an effective search value
        if (nextCleanedValue) {
          // clear the search results state
          setSearchState(SearchState.NONE);

          // clear any existing timeouts
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          // debouce the autocomplete suggestions request
          timeoutRef.current = setTimeout(() => {
            // notify the UI of background activity
            setSearchState(SearchState.SEARCHING);

            // Submit a suggestions request for the cleaned search value
            placeAutocompleteService.getPlacePredictions(
              {
                // TODO use the user's current location instead of just Australia
                location: {
                  lat: () => AUSTRALIA_GEODETIC_COORDINATES.latitude,
                  lng: () => AUSTRALIA_GEODETIC_COORDINATES.longitude,
                },
                radius: AUSTRALIA_RADIUS,
                input: nextCleanedValue,
                sessionToken,
              },
              (predictions, status) => {
                // Check that the response was successful
                if (status === PlacesServiceStatus.OK) {
                  setSearchState(SearchState.SEARCH_COMPLETE);

                  // Process the search result suggestions
                  setItems(
                    predictions.map(prediction => ({
                      label: prediction.description,
                      value: prediction.place_id,
                    })),
                  );
                } else if (status === PlacesServiceStatus.ZERO_RESULTS) {
                  setSearchState(SearchState.SEARCH_COMPLETE);
                  setItems([]);
                } else {
                  // eslint-disable-next-line no-console
                  console.error(status);

                  // notify the UI of an error, this will happen if there are request issues, such as an invalid api key
                  setSearchState(SearchState.ERROR);
                }
              },
            );
          }, TIMEOUT);
        } else if (items.length !== 0) {
          // We have a new search value, clear out the existing results
          setItems([]);
        }
      }
    },
    [
      placeAutocompleteService,
      setSearchState,
      sessionToken,
      cleanedValue,
      items.length,
    ],
  );

  const useComboBoxOptions = {
    items,
    onInputValueChange: onChangeInput,
    onSelectedItemChange: ({ selectedItem }) => {
      typeof onPlaceIdSelected === "function" &&
        onPlaceIdSelected(selectedItem.value);
    },
    itemToString,
  };

  if (defaultAddressSearch) {
    useComboBoxOptions.defaultInputValue = defaultAddressSearch;
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
  } = useCombobox(useComboBoxOptions);

  let inputTitle = "";
  let isInputDisabled = true;
  if (!isOnline) {
    inputTitle = "You must be online to use this feature";
  } else if (!placeAutocompleteService) {
    inputTitle = "Loading...";
  } else {
    isInputDisabled = disabled;
  }

  // If a predefined search is applied, do it once the placeAutoCompleteService
  // is inialized.
  useEffect(() => {
    if (defaultAddressSearch) {
      if (placeAutocompleteService) {
        openMenu();
        onChangeInput({
          inputValue: defaultAddressSearch,
          isOpen: true,
          selectedItem: null,
        });
      }
    }
  }, [placeAutocompleteService, onChangeInput, openMenu, defaultAddressSearch]);

  return (
    <div>
      <div {...getComboboxProps()}>
        <Row fullWidth>
          <StyledInput
            {...getInputProps()}
            disabled={isInputDisabled}
            title={inputTitle}
            autocomplete="chrome-off"
          />
          {placeAutocompleteService && (
            <button
              type="button"
              {...getToggleButtonProps()}
              disabled={disabled || !placeAutocompleteService}
              aria-label="toggle menu"
            >
              &#8595;
            </button>
          )}
          {!placeAutocompleteService && <LoadingSpinner size={14} />}
        </Row>
      </div>
      <ListRelativeWrapper>
        <List
          {...getMenuProps()}
          dense
          style={{
            width: "100%",
            background: "white",
            maxWidth: 300,
            maxHeight: 250,
            overflowY: "auto",
            position: "absolute",
            margin: 0,
            borderTop: 0,
            zIndex: 1000,
          }}
        >
          {isOpen &&
            searchState === SearchState.ERROR &&
            "An error occurred finding suggestions"}

          {isOpen && searchState === SearchState.SEARCHING && (
            <LoadingSpinner />
          )}
          {isOpen &&
            searchState === SearchState.SEARCH_COMPLETE &&
            ((items.length > 0 &&
              items.map((item, index) => (
                <ListItem
                  key={item.value}
                  {...getItemProps({
                    item,
                    index,
                  })}
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              ))) ||
              (cleanedValue && `No results matching "${cleanedValue}"`))}
        </List>
      </ListRelativeWrapper>
    </div>
  );
}

function GoogleMapsPlaceIdAutoCompleteField(props) {
  const {
    disabled,
    error,
    label,
    name,
    onChangeExtra,
    required,
    tooltip,
    defaultAddressSearch,
  } = props;
  return (
    <Column fullWidth>
      <Label
        htmlFor={name}
        error={!!error}
        required={required}
        tooltip={tooltip}
      >
        {label}
      </Label>

      <GoogleMapsAutoCompleteSuggestionList
        disabled={disabled}
        onPlaceIdSelected={onChangeExtra}
        defaultAddressSearch={defaultAddressSearch}
      />
      {error && <Error>{error}</Error>}
    </Column>
  );
}

export const GoogleMapsPlaceIdAutoComplete = memo(
  GoogleMapsPlaceIdAutoCompleteField,
);
