import React from "react";

import { Grid, useMediaQuery } from "@material-ui/core";
import Fuse from "fuse.js";
import { partition } from "lodash/collection";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { createBusinessFromImport, patchBusinessFromImport } from "actions";

import { Chip } from "components/Chip";
import { Button, SecondaryButton } from "components/Form";
import { Row } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import SearchInput from "components/SearchInput";

import { closeAllHashModals, returnToLocation } from "lib/navigation";
import toast from "lib/toast";
import { validateEmail } from "lib/validators";

import { selectPropertyEnrichedBusinessByBusinessIdLookup } from "selectors";

import { useTheme } from "hooks";

import BusinessTable from "./Table";

const fuseOptions = {
  keys: [
    "name",
    "properties.PIC",
    "name",
    "emailRecipients.email",
    "emailRecipients.phone_number",
  ],
};

function Modal(props) {
  const {
    businessShortCode,
    businessName,
    returnTo,
    businessPIC,
    auctionsPlusId,
    tableConfiguration,
    locality,
    state,
    email = "",
  } = props;

  const theme = useTheme();

  // Only import valid emails.
  const [validEmails, invalidEmails] = partition(
    email.split(/,|\s+|;/).map(e => e.trim()),
    validateEmail,
  );

  if (invalidEmails.length < 0) {
    toast.error(
      `The following invalid ${
        invalidEmails.length === 1 ? "email was" : "emails were"
      } skipped: ${invalidEmails.join(", ")}`,
    );
  }

  const businessPayload = { ...props, email: validEmails.join(", ") };

  const [searchText, setSearchText] = React.useState(
    [businessShortCode, auctionsPlusId, businessName].filter(Boolean).join(" "),
  );

  const deploymentBusinesses = useSelector(
    selectPropertyEnrichedBusinessByBusinessIdLookup,
  );
  const dispatch = useDispatch();

  function onClose() {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }

  const mapBusiness = business => {
    dispatch(patchBusinessFromImport(business.id, businessPayload));
    onClose();
  };

  const createBusiness = () => {
    dispatch(createBusinessFromImport(businessPayload));
    onClose();
  };

  const fuseIndex = React.useMemo(
    () => new Fuse(Object.values(deploymentBusinesses), fuseOptions),
    [deploymentBusinesses],
  );

  const searchResult = fuseIndex
    .search(searchText)
    .slice(0, 20)
    .map(fuseResult => fuseResult.item);
  const fullScreen = useMediaQuery(`(max-width: ${theme.breakpoints[1]}px)`);

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
      height="80vh"
    >
      <DialogTitle onClose={onClose}>
        <Row alignCenter>
          Mapping
          {businessShortCode ? <Chip>Code: {businessShortCode}</Chip> : null}
          {auctionsPlusId ? <Chip>Code: {auctionsPlusId}</Chip> : null}
          <Chip>Name: {businessName}</Chip>
          {businessPIC ? <Chip>PIC: {businessPIC}</Chip> : null}
          {locality ? <Chip>Town: {locality}</Chip> : null}
          {state ? <Chip>State: {state}</Chip> : null}
        </Row>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SearchInput
              value={searchText}
              handleChange={setSearchText}
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid item xs={12}>
            <BusinessTable
              data={searchResult}
              mapBusiness={mapBusiness}
              importData={props}
              tableConfiguration={tableConfiguration}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <Button onClick={createBusiness}>Create New</Button>
      </DialogActions>
    </Dialog>
  );
}

Modal.propTypes = {
  returnTo: PropTypes.string,
};

export default Modal;
