import React, { useEffect, useRef, useState } from "react";

import uniq from "lodash/uniq";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SaleAction } from "actions";

import { CreateConsignment } from "components/ConsignToSaleModal/CreateConsignment";
import { LivestockSaleCard } from "components/ConsignToSaleModal/LivestockSaleCard";
import { Button, SecondaryButton } from "components/Form";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { OptionToggler } from "components/OptionToggler";
import { ReactSelect } from "components/SearchableSelector";

import { ApiModel } from "constants/loading";
import { Species } from "constants/species";

import {
  selectConsignableSaleyardSaleIds,
  getConsignableSales,
  getSpecies,
} from "selectors";

const SelectWrapper = styled.div`
  width: 150px;
`;

export const ConsignToSaleModal = ({ closeSelf }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SaleAction.fetchConsignableSales({}));
  }, [dispatch]);
  const formRef = useRef();

  const saleIds = useSelector(selectConsignableSaleyardSaleIds);
  const sales = useSelector(getConsignableSales);
  const speciesTypes = useSelector(getSpecies);

  const [deploymentSaleId, setDeploymentSaleId] = useState(null);
  const [livestockSaleId, setLivestockSaleId] = useState(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [filter, setFilter] = useState(null);

  const createConsignment = () => {
    formRef.current.submitForm();
  };

  // Species for the main bar, these won't appear in dropdown
  const topSpecies = [
    { value: Species.SHEEP, label: "Sheep" },
    { value: Species.CATTLE, label: "Cattle" },
  ];

  // Sort species anto alpha ordered array.
  const sortedSpecies = uniq(
    Object.values(sales).map(sale => speciesTypes[sale.speciesId].id),
  ).sort();

  // Options for dropdown, maps into an object, filters out the topSpecies
  const options = sortedSpecies
    .map(speciesId => ({
      value: speciesId,
      label: speciesTypes[speciesId].name,
    }))
    .filter(option => !topSpecies.some(x => x.value === option.value));

  // the option toggler returns a string, the dropdown returns an object
  // Check what it is and return a string
  const speciesFilter =
    typeof filter === "object" && filter !== null ? filter.value : filter;

  return (
    <Dialog
      id="consign-to-sale"
      open
      onClose={closeSelf}
      maxWidth="lg"
      fullScreen
    >
      <DialogTitle onClose={closeSelf}>Consign To a Sale</DialogTitle>
      <Row>
        <OptionToggler
          width="100%"
          options={topSpecies}
          name="saleTypeFilter"
          onChange={setFilter}
          allowToggleOff
          value={speciesFilter}
        />
        <SelectWrapper>
          <ReactSelect
            isClearable
            value={filter}
            options={options}
            onChange={setFilter}
          />
        </SelectWrapper>
      </Row>
      <DialogContent>
        {deploymentSaleId ? (
          <CreateConsignment
            livestockSaleId={livestockSaleId}
            deploymentSaleId={deploymentSaleId}
            setIsSubmitEnabled={setIsSubmitEnabled}
            formRef={formRef}
          />
        ) : (
          <WaitForSync
            requiredData={[
              ApiModel.AGENCIES,
              ApiModel.CONSIGNABLE_SALES,
              ApiModel.DEPLOYMENTS,
            ]}
          >
            {saleIds.map(livestockSaleId => (
              <LivestockSaleCard
                key={livestockSaleId}
                livestockSaleId={livestockSaleId}
                setDeploymentSaleId={deploymentSaleId => {
                  setDeploymentSaleId(deploymentSaleId);
                  setLivestockSaleId(livestockSaleId);
                }}
                speciesFilter={speciesFilter}
              />
            ))}
          </WaitForSync>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={closeSelf}>Close</SecondaryButton>
        {deploymentSaleId && (
          <>
            <SecondaryButton onClick={() => setDeploymentSaleId(null)}>
              Back
            </SecondaryButton>
            <Button onClick={createConsignment} disabled={!isSubmitEnabled}>
              Create Consignment
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
