import { intersection, uniq } from "lodash";
import { createSelector } from "reselect";

import { ExportSites } from "constants/exportSites";
import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { getConsignmentCode } from "lib/consignments";
import {
  doesSaleLotHaveOverflowPen,
  getBuyerHashFromSaleLot,
  getSortedMarkDetails,
  isSaleLotBalanced,
  isSaleLotCounted,
  isSaleLotDelivered,
  isSaleLotInProgress,
} from "lib/saleLot";

import {
  getConsignments,
  getCurrentDeploymentSalesList,
  getGlobalSearchBySearchField,
  getPenArchetypes,
  getPenScanLots,
  getReceivalLots,
  getSaleLots,
  selectAreSomeSaleLotsSoldByPenScanLotIdLookup,
  selectAreSomeSaleLotsSoldByReceivalLotIdLookup,
  selectAuctionPenIdByEidLookup,
  selectAuctionPenIdBySaleLotIdLookup,
  selectAuctionPenIdsByConsignmentIdLookup,
  selectCanHaveProgenyBySaleLotIdLookup,
  selectCanSaleLotsHaveProgenyByPenScanLotIdLookup,
  selectCanSaleLotsHaveProgenyByReceivalLotIdLookup,
  selectConsignmentHasImageLookup,
  selectConsignmentHasVideoLookup,
  selectExceptionsBySaleLotIdLookup,
  selectHasConsignmentScansFilteredPenScanLotIds,
  selectHasConsignmentScansFilteredReceivalLotIds,
  selectHasVendorExceptionsFilteredPenScanLotIds,
  selectHasVendorExceptionsFilteredReceivalLotIds,
  selectHasVendorExceptionsFilteredSaleLotIds,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectIsWeighedByPenScanLotIdLookup,
  selectIsWeighedByReceivalLotIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectPenArchetypeIdByAuctionPenIdLookup,
  selectPenScanLotIdByEidLookup,
  selectPenScanLotIdsByConsignmentIdLookup,
  selectPenScanLotIdsByDeliveryPenIdLookup,
  selectPenScanlotIdsByDeploymentSaleIdLookup,
  selectReceivalIdsByDeploymentSaleIdLookup,
  selectReceivalLotIdByEidLookup,
  selectReceivalLotIdsByConsignmentIdLookup,
  selectReceivalLotIdsByDeliveryPenIdLookup,
  selectReceivalLotIdsBySaleLotIdLookup,
  selectSaleLotHasImageLookup,
  selectSaleLotHasVideoLookup,
  selectSaleLotIdsByDeliveryPenIdLookup,
  selectSaleLotIdsByDeploymentSaleIdLookup,
  selectSaleLotIdsByPenScanLotIdLookup,
  selectSaleLotIdsByReceivalLotIdLookup,
  selectSaleLotsScanStatusesByPenScanLotIdLookup,
  selectSaleLotsScanStatusesByReceivalLotIdLookup,
  selectSaleRoundFilteredPenScanLotIds,
  selectSaleRoundFilteredReceivalLotIds,
  selectSaleRoundFilteredSaleLotIds,
  selectScanStatusBySaleLotIdLookup,
  selectVendorIdByReceivalLotIdLookup,
  selectVendorIdBySaleLotIdLookup,
  selectVendorIdsByPenScanLotIdLookup,
} from "selectors";

import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";
import { selectConsignmentIdsByPenScanLotIdLookup } from "selectors/penScanLots";

import { isObjectChangedAfter } from "./lib";
import { selectPenScanLotIdsBySaleLotIdLookup } from "./penScanLotFilters";

export const selectUnfilteredPenArchetypeIds = createSelector(
  [getPenArchetypes],
  penArchetypes =>
    // penArchetypeIds are not cbids - they are simple straight numbers - need to ensure they are not stringified from the key
    Object.keys(penArchetypes).map(penArchetypeId =>
      parseInt(penArchetypeId, 10),
    ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked deployment sale ids
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
export const selectPenArchetypeIdsByDeploymentSaleIdLookup = createSelector(
  [
    selectSaleLotIdsByDeploymentSaleIdLookup,
    selectPenScanlotIdsByDeploymentSaleIdLookup,
    selectReceivalIdsByDeploymentSaleIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
    getPenScanLots,
    getReceivalLots,
    selectPenArchetypeIdByAuctionPenIdLookup,
  ],
  (
    saleLotIdsByDeploymentSaleIdLookup,
    penScanLotIdsByDeploymentSaleIdLookup,
    receivalLotIdsByDeploymentSaleIdLookup,
    auctionPenIdBySaleLotIdLookup,
    penScanLotByIdLookup,
    receivalLotByIdLookup,
    penArchetypeIdByPenIdLookup,
  ) =>
    Object.entries(saleLotIdsByDeploymentSaleIdLookup).reduce(
      (acc, [deploymentSaleId, saleLotIds]) => {
        acc[deploymentSaleId] = saleLotIds
          // add pen archetype ids based on sale lot auction pens
          .map(
            saleLotId =>
              penArchetypeIdByPenIdLookup[
                auctionPenIdBySaleLotIdLookup[saleLotId]
              ],
          )
          // add pen archetype ids based on pen scan lot selling pen ids
          .concat(
            penScanLotIdsByDeploymentSaleIdLookup[deploymentSaleId].map(
              penScanLotId =>
                penArchetypeIdByPenIdLookup[
                  penScanLotByIdLookup[penScanLotId]?.sellingPenId
                ],
            ),
          )
          // add pen archetype ids based on receival lots recevial pen ids
          .concat(
            receivalLotIdsByDeploymentSaleIdLookup[deploymentSaleId].map(
              receivalLotId =>
                penArchetypeIdByPenIdLookup[
                  receivalLotByIdLookup[receivalLotId]?.receivalPenId
                ],
            ),
          )
          .filter(Boolean);

        return acc;
      },
      {},
    ),
);

/**
 * Return all pen archetype ids associated with deployment sales that are linked
 * to the filtered agency ids
 */
const selectAgencyFilteredPenArchetypeIds = createSelector(
  [
    getCurrentDeploymentSalesList,
    getGlobalSearchBySearchField(GlobalSearchFields.Agency),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdsByDeploymentSaleIdLookup,
  ],
  (
    deploymentSales,
    agencyIds,
    unfilteredPenArchetypeIds,
    penArchetypeIdsByDeploymentSaleIdLookup,
  ) => {
    const value =
      agencyIds === null
        ? unfilteredPenArchetypeIds
        : deploymentSales
            // check the selected agency id against their deployment sales
            .filter(deploymentSale =>
              agencyIds.includes(deploymentSale.livestock_agency_id),
            )
            // get the pen archetype ids from the deployment sales
            .map(
              deploymentSale =>
                penArchetypeIdsByDeploymentSaleIdLookup[
                  deploymentSale.deployment_sale_id
                ],
            )
            .flat();
    return value;
  },
);

/**
 * Return pen archetype ids for filtered auction pens(auction|receival|selling) pen
 */
const selectAuctionPenFilteredPenArchetypeIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.AuctionPen),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
  ],
  (
    auctionPenIds,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
  ) => {
    return auctionPenIds === null
      ? unfilteredPenArchetypeIds
      : auctionPenIds.map(
          auctionPenId =>
            penArchetypeIdByAuctionPenIdLookup[auctionPenId] || [],
        );
  },
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for buyer ids that match the global search combinations.
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectBuyerFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Buyer),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    buyerIds,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    buyerIds === null
      ? unfilteredValues
      : // check sale lots' buyer ids are included in the filter
        Object.values(saleLots)
          .filter(saleLot => buyerIds.includes(saleLot.buyer_id))
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered receival lots by whether their sale lots' buyer ids are in the filter via receival lots
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  buyerIds.includes(saleLots[saleLotId]?.buyer_id),
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          )
          // add filtered pen scan lots by whether their sale lots' buyer ids are in the filter via pen scan lots
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  buyerIds.includes(saleLots[saleLotId]?.buyer_id),
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for buyer and buyer way combinations
 * that match the global search combinations.
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectBuyerAndBuyerWayFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerAndBuyerWay),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    buyerHashes,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    buyerHashes === null
      ? unfilteredValues
      : uniq(
          // filter sale lots' ids by the global search buyer hash
          Object.values(saleLots)
            .filter(saleLot =>
              buyerHashes.includes(getBuyerHashFromSaleLot(saleLot)),
            )
            // return pen archetype ids based off the sale lot auction pen
            .map(
              saleLot =>
                penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
            )
            // add filtered sale lots' associated to receival lots by the global search buyer hash
            .concat(
              Object.entries(saleLotIdsByReceivalLotIdLookup)
                .filter(([ignored, saleLotIds]) =>
                  saleLotIds?.some(saleLotId =>
                    buyerHashes.includes(
                      getBuyerHashFromSaleLot(saleLots[saleLotId]),
                    ),
                  ),
                )
                // return pen archetype ids based off the receival lots' receival pen
                .map(
                  ([receivalLotId, ignored]) =>
                    penArchetypeIdByAuctionPenIdLookup[
                      receivalLots[receivalLotId]?.receivalPenId
                    ],
                ),
            )
            // add filtered sale lots' associated to pen scan lots by the global search buyer hash
            .concat(
              Object.entries(saleLotIdsByPenScanLotIdLookup)
                .filter(([ignored, saleLotIds]) =>
                  saleLotIds?.some(saleLotId =>
                    buyerHashes.includes(
                      getBuyerHashFromSaleLot(saleLots[saleLotId]),
                    ),
                  ),
                )
                // return pen archetype ids based off the pen scan lot selling pen
                .map(
                  ([penScanLotId, ignored]) =>
                    penArchetypeIdByAuctionPenIdLookup[
                      penScanLots[penScanLotId]?.sellingPenId
                    ],
                ),
            ),
        ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for delivery pen ids that match the
 * global searched delivery id
 */
const selectDeliveryPenFilteredPenArchetypeIds = createSelector(
  [
    getReceivalLots,
    getPenScanLots,
    selectAuctionPenIdBySaleLotIdLookup,
    selectSaleLotIdsByDeliveryPenIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.DeliveryPen),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectReceivalLotIdsByDeliveryPenIdLookup,
    selectPenScanLotIdsByDeliveryPenIdLookup,
  ],
  (
    receivalLots,
    penScanLots,
    auctionPenIdBySaleLotIdLookup,
    saleLotIdsByDeliveryPenIdLookup,
    deliveryPenIds,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    receivalLotIdsByDeliveryPenIdLookup,
    penScanLotIdsByDeliveryPenIdLookup,
  ) => {
    const receivalLotPenArchetypeIds = [];
    const penScanLotPenArchetypeIds = [];

    // build a filtered list of pen archetype ids
    // filtered by the global search delivery ids
    // that are linked to receival lots
    Object.entries(receivalLotIdsByDeliveryPenIdLookup)
      .filter(([deliveryPenId, ignored]) =>
        deliveryPenIds?.includes(deliveryPenId),
      )
      .forEach(([ignored, receivalLotIds]) =>
        receivalLotIds.forEach(receivalLotId => {
          receivalLotPenArchetypeIds.push(
            penArchetypeIdByAuctionPenIdLookup[
              receivalLots[receivalLotId].receivalPenId
            ],
          );
        }),
      );

    // build a filtered list of pen archetype ids
    // filtered by the global search delivery ids
    // that are linked to pen scan lots
    Object.entries(penScanLotIdsByDeliveryPenIdLookup)
      .filter(([deliveryPenId, ignored]) =>
        deliveryPenIds?.includes(deliveryPenId),
      )
      .forEach(([ignored, penScanLotIds]) =>
        penScanLotIds.forEach(penScanLotId => {
          penScanLotPenArchetypeIds.push(
            penArchetypeIdByAuctionPenIdLookup[
              penScanLots[penScanLotId]?.sellingPenId
            ],
          );
        }),
      );

    return deliveryPenIds === null
      ? unfilteredPenArchetypeIds
      : // check sale lots for filtered delivery pens
        Object.entries(saleLotIdsByDeliveryPenIdLookup)
          .filter(([deliveryPenId, ignored]) =>
            deliveryPenIds.includes(deliveryPenId),
          )
          .map(([ignored, saleLotIds]) => {
            return saleLotIds.forEach(
              saleLotId =>
                penArchetypeIdByAuctionPenIdLookup[
                  auctionPenIdBySaleLotIdLookup[saleLotId]
                ],
            );
          })
          // include the filtered pen archetypes based on receival lots
          .concat(receivalLotPenArchetypeIds)
          // include the filtered pen archetypes based on pen scan lots
          .concat(penScanLotPenArchetypeIds);
  },
);

/**
 * Go over each consignment that has arrived return their pen archetype Ids
 * linked to the (auction|receival|selling)
 */
const selectHasArrivedFilteredPenArchetypeIds = createSelector(
  [
    getConsignments,
    selectAuctionPenIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasArrived),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectReceivalLotIdsByConsignmentIdLookup,
    selectPenScanLotIdsByConsignmentIdLookup,
    getReceivalLots,
    getPenScanLots,
  ],
  (
    consignments,
    auctionPenIdsByConsignmentIdLookup,
    hasArrived,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    receivalLotIdsByConsignmentIdLookup,
    penScanLotIdsByConsignmentIdLookup,
    receivalLotByIdLookup,
    penScanLotByIdLookup,
  ) =>
    hasArrived === null
      ? unfilteredPenArchetypeIds
      : Object.values(consignments)
          // filter all consignments based on the global search has arrived boolean
          .filter(consignment => consignment.hasArrived === hasArrived[0])
          .reduce(
            (acc, consignment) =>
              // return pen archetype ids linked to auction pens based off the filtered consignments
              acc.concat(
                auctionPenIdsByConsignmentIdLookup[consignment.id]
                  .map(
                    auctionPenId =>
                      penArchetypeIdByAuctionPenIdLookup[auctionPenId],
                  )
                  // return pen archetype ids linked to receival pens based off the filtered consignments
                  .concat(
                    receivalLotIdsByConsignmentIdLookup[consignment.id]?.map(
                      receivalLotId =>
                        penArchetypeIdByAuctionPenIdLookup[
                          receivalLotByIdLookup[receivalLotId]?.receivalPenId
                        ],
                    ),
                  )
                  // return pen archetype ids linked to selling pens based off the filtered consignments
                  .concat(
                    penScanLotIdsByConsignmentIdLookup[consignment.id]?.map(
                      penScanLotId =>
                        penArchetypeIdByAuctionPenIdLookup[
                          penScanLotByIdLookup[penScanLotId]?.sellingPenId
                        ],
                    ),
                  )
                  .filter(Boolean),
              ),
            [],
          ),
);

/**
 * Go over each sale lot, recevial lot, and pen scan lot
 * based on their linked sale lots' having buyer exceptions
 * and return their pen archetype Ids linked to the
 * (auction|receival|selling) pen
 */
const selectHasBuyerExceptionsFilteredPenArchetypeIds = createSelector(
  [
    selectExceptionsBySaleLotIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasBuyerExceptions),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
    getReceivalLots,
    getPenScanLots,
    selectReceivalLotIdsBySaleLotIdLookup,
    selectPenScanLotIdsBySaleLotIdLookup,
  ],
  (
    exceptionsBySaleLotIdLookup,
    auctionPenIdBySaleLotIdLookup,
    hasBuyerExceptions,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
    receivalLotByIdLookup,
    penScanLotByIdLookup,
    receivalLotIdsBySaleLotIdLookup,
    penScanLotIdsBySaleLotIdLookup,
  ) => {
    // build filtered list of receival lot ids to find buyer exceptions in sale lots
    // linked to receival lots based on the global search boolean
    const filteredReceivalLotIds = Object.entries(
      saleLotIdsByReceivalLotIdLookup,
    )
      .map(([ignored, saleLotIds]) => saleLotIds)
      .flat()
      .filter(saleLotId => {
        return (
          (exceptionsBySaleLotIdLookup[saleLotId]?.length === 0) ===
          hasBuyerExceptions?.[0]
        );
      })
      .map(saleLotId => receivalLotIdsBySaleLotIdLookup[saleLotId])
      .flat()
      .filter(Boolean);

    // build filtered list of of pen scan lot ids find buyer exceptions in sale lots
    // linked to pen scan lots based on the global search boolean
    const filteredPenScanLotIds = Object.entries(saleLotIdsByPenScanLotIdLookup)
      .map(([ignored, saleLotIds]) => saleLotIds)
      .flat()
      .filter(saleLotId => {
        return (
          (exceptionsBySaleLotIdLookup[saleLotId]?.length === 0) ===
          hasBuyerExceptions?.[0]
        );
      })
      .map(saleLotId => penScanLotIdsBySaleLotIdLookup[saleLotId])
      .flat()
      .filter(Boolean);

    return hasBuyerExceptions === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots thats buyer exception boolean matches the global search
        Object.entries(auctionPenIdBySaleLotIdLookup)
          .filter(
            ([saleLotId, ignored]) =>
              (exceptionsBySaleLotIdLookup[saleLotId]?.length === 0) ===
              hasBuyerExceptions[0],
          )
          // return pen archetype ids based on the filter sale lots' auction pens
          .map(
            ([ignored, auctionPenId]) =>
              penArchetypeIdByAuctionPenIdLookup[auctionPenId],
          )
          // add pen archetype ids from the filter receival lot ids
          .concat(
            filteredReceivalLotIds.map(
              receivalLotId =>
                penArchetypeIdByAuctionPenIdLookup[
                  receivalLotByIdLookup[receivalLotId].receivalPenId
                ],
            ),
          )
          // add pen archetype ids from the filter pen scan lot ids
          .concat(
            filteredPenScanLotIds.map(
              penScanLotId =>
                penArchetypeIdByAuctionPenIdLookup[
                  penScanLotByIdLookup[penScanLotId]?.sellingPenId
                ],
            ),
          )
          .filter(Boolean);
  },
);

/**
 * Go over each recevial lot, then pen scan lot
 * (sale lots do not have a filter for this) based on
 * their global has conignment scans filters and return their
 * pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectHasConsignmentScansFilteredPenArchetypeIds = createSelector(
  [
    selectHasConsignmentScansFilteredReceivalLotIds,
    selectHasConsignmentScansFilteredPenScanLotIds,
    getGlobalSearchBySearchField(GlobalSearchFields.HasConsignmentScans),
    selectPenArchetypeIdByAuctionPenIdLookup,
    getReceivalLots,
    getPenScanLots,
    selectUnfilteredPenArchetypeIds,
  ],
  (
    hasConsignmentScannedFilteredReceivalLotIds,
    hasConsignmentScannedFilteredPenScanLotIds,
    hasConsignmentScans,
    penArchetypeIdByAuctionPenIdLookup,
    receivalLotByIdLookup,
    penScanLotByIdLookup,
    unfilteredPenArchetypeIds,
  ) =>
    hasConsignmentScans === null
      ? unfilteredPenArchetypeIds
      : // get pen archetype ids off filtered receival lot ids
        [
          ...hasConsignmentScannedFilteredReceivalLotIds.map(
            receivalLotId =>
              penArchetypeIdByAuctionPenIdLookup[
                receivalLotByIdLookup[receivalLotId]?.receivalPenId
              ],
          ),
          // get pen archetype ids off filtered pen scan lot ids
          ...hasConsignmentScannedFilteredPenScanLotIds.map(
            penScanLotId =>
              penArchetypeIdByAuctionPenIdLookup[
                penScanLotByIdLookup[penScanLotId]?.sellingPenId
              ],
          ),
        ],
);

/**
 * Go over each sale lot, then recevial lot, and pen scan lot
 * to see if the assocaited sale lots have an overflow pen
 * and return their pen archetype Ids linked to the
 * (auction|receival|selling) pen
 */
const selectHasOverflowFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.HasOverflow),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    hasOverflow,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    hasOverflow === null
      ? unfilteredValues
      : // filter sale lots that have overflow equal to the global search boolean
        Object.values(saleLots)
          .filter(
            saleLot => doesSaleLotHaveOverflowPen(saleLot) === hasOverflow[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered receival lots that are linked to sale lots based on if they have any overflow equal to the global search filter
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    doesSaleLotHaveOverflowPen(saleLots[saleLotId]) ===
                    hasOverflow[0],
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          )
          // add filtered pen scan lots that are linked to sale lots based on if they have any overflow equal to the global search filter
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    doesSaleLotHaveOverflowPen(saleLots[saleLotId]) ===
                    hasOverflow[0],
                ),
              )
              // return pen archetype ids based off the pen scan lots' selling pen
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, then recevial lot, and pen scan lot
 * to see if there the assocaited sale lots can have progeny
 * and return their pen archetype Ids linked to the
 * (auction|receival|selling) pen
 */
const selectHasProgenyFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    selectCanHaveProgenyBySaleLotIdLookup,
    selectCanSaleLotsHaveProgenyByReceivalLotIdLookup,
    selectCanSaleLotsHaveProgenyByPenScanLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasProgeny),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    canHaveProgenyBySaleLotIdLookup,
    saleLotsCanHaveProgenyByReceivalLotIdLookup,
    saleLotsCanHaveProgenyByPenScanLotIdLookup,
    hasProgeny,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
  ) =>
    hasProgeny === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots based on if they have progeny equal to has progeny boolean in global search
        Object.values(saleLots)
          .filter(
            saleLot =>
              canHaveProgenyBySaleLotIdLookup[saleLot.id] === hasProgeny[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered receival lots by linked sale lots have progeny equal to the global search filter
          .concat(
            Object.values(receivalLots)
              .filter(
                receivalLot =>
                  saleLotsCanHaveProgenyByReceivalLotIdLookup[
                    receivalLot.id
                  ] === hasProgeny[0],
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots by linked sale lots have progeny equal to the global search filter
          .concat(
            Object.values(penScanLots)
              .filter(
                penScanLot =>
                  saleLotsCanHaveProgenyByPenScanLotIdLookup[penScanLot.id] ===
                  hasProgeny[0],
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          ),
);

/**
 * Go over each sale lot, recevial lot, and pen scan lot
 * to see if there are vendor exceptions based on their
 * vendor exception filters and return their pen archetype
 * Ids linked to the (auction|receival|selling) pen
 */
const selectHasVendorExceptionsFilteredPenArchetypeIds = createSelector(
  [
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectUnfilteredPenArchetypeIds,
    selectHasVendorExceptionsFilteredSaleLotIds,
    selectHasVendorExceptionsFilteredPenScanLotIds,
    selectHasVendorExceptionsFilteredReceivalLotIds,
    getGlobalSearchBySearchField(GlobalSearchFields.HasVendorExceptions),
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
  ],
  (
    penArchetypeIdByAuctionPenIdLookup,
    unfilteredPenArchetypeIds,
    hasVendorExceptionsFilteredSaleLotIds,
    hasVendorExceptionsFilteredPenScanLotIds,
    hasVendorExceptionsFilteredReceivalLotIds,
    hasVendorExceptions,
    saleLotByIdLookup,
    receivalLotByIdLookup,
    penScanLotByIdLookup,
  ) =>
    hasVendorExceptions === null
      ? unfilteredPenArchetypeIds
      : // check exceptions filtered sale lot Ids
        [
          ...hasVendorExceptionsFilteredSaleLotIds.map(
            saleLotId =>
              penArchetypeIdByAuctionPenIdLookup[
                saleLotByIdLookup[saleLotId]?.auction_pen_id
              ],
          ),
          // check exceptions filtered receival lot Ids
          ...hasVendorExceptionsFilteredReceivalLotIds.map(
            receivalLotId =>
              penArchetypeIdByAuctionPenIdLookup[
                receivalLotByIdLookup[receivalLotId]?.receivalPenId
              ],
          ),
          // check exceptions filtered pen scan lot Ids
          ...hasVendorExceptionsFilteredPenScanLotIds.map(
            penScanLotId =>
              penArchetypeIdByAuctionPenIdLookup[
                penScanLotByIdLookup[penScanLotId]?.sellingPenId
              ],
          ),
        ],
);

/**
 * Go over each sale lot, then recevial lot, then pen scan lot
 * to see if there is a weight against the global search boolean
 * and return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectHasWeightFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    selectIsWeighedBySaleLotIdLookup,
    selectIsWeighedByReceivalLotIdLookup,
    selectIsWeighedByPenScanLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    getReceivalLots,
    getPenScanLots,
  ],
  (
    saleLots,
    isWeighedBySaleLotIdLookup,
    isWeighedByReceivalLotIdLookup,
    isWeighedByPenScanLotIdLookup,
    hasWeight,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    receivalLots,
    penScanLots,
  ) => {
    return hasWeight === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots based on the has weight global search
        Object.values(saleLots)
          .filter(
            saleLot => isWeighedBySaleLotIdLookup[saleLot.id] === hasWeight[0],
          )
          // return pen archetype ids based off the sale lots' auction pen
          .map(lot => penArchetypeIdByAuctionPenIdLookup[lot.auction_pen_id])
          // add filtered receival lots by the is weighed boolean in the global search
          .concat(
            Object.values(receivalLots)
              .filter(
                receivalLot =>
                  isWeighedByReceivalLotIdLookup[receivalLot.id] ===
                  hasWeight[0],
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                lot => penArchetypeIdByAuctionPenIdLookup[lot.receivalPenId],
              ),
          )
          // add filtered pen scan lots by the is weighed boolean on the global search
          .concat(
            Object.values(penScanLots)
              .filter(
                penScanLot =>
                  isWeighedByPenScanLotIdLookup[penScanLot.id] === hasWeight[0],
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(lot => penArchetypeIdByAuctionPenIdLookup[lot.sellingPenId]),
          )
          .filter(Boolean);
  },
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for if the buyer is balanced against the
 * global search boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsBuyerBalancedFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsBuyerBalanced),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isBuyerBalanced,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    isBuyerBalanced === null
      ? unfilteredValues
      : // filter sale lots by the is balanced boolean in the global search
        Object.values(saleLots)
          .filter(saleLot => isSaleLotBalanced(saleLot) === isBuyerBalanced[0])
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered receival lots by their linked sale lots based on if any are equal to the 'is balanced' boolean in global search
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    isSaleLotBalanced(saleLots[saleLotId]) ===
                    isBuyerBalanced[0],
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          )
          // add filtered pen scan lots by their linked sale lots based on if any are equal to the 'is balanced' boolean in global search
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    isSaleLotBalanced(saleLots[saleLotId]) ===
                    isBuyerBalanced[0],
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for if the sale lots is counted against the
 * global search boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsCountedFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsCounted),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isCounted,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    isCounted === null
      ? unfilteredValues
      : // filter sale lots by the is counted boolean in global search
        Object.values(saleLots)
          .filter(saleLot => isSaleLotCounted(saleLot) === isCounted[0])
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered receival lots by their linked sale lots based on if any are equal to the 'is counted' boolean in global search
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    isSaleLotCounted(saleLots[saleLotId]) === isCounted[0],
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          )
          // add filtered pen scan lots by their linked sale lots based on if any are equal to the 'is counted' boolean in global search
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    isSaleLotCounted(saleLots[saleLotId]) === isCounted[0],
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for if the sale lots are delivered against the
 * global search boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsDeliveredFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsDelivered),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isDelivered,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    isDelivered === null
      ? unfilteredValues
      : // filter sale lots by the is delivered boolean in global search
        Object.values(saleLots)
          .filter(saleLot => isSaleLotDelivered(saleLot) === isDelivered[0])
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered pen scan lots based on their linked sale lots by the is delivered boolean in global search
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    isSaleLotDelivered(saleLots[saleLotId]) === isDelivered[0],
                ),
              )
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          )
          // add filtered receival lots based on their linked sale lots by the is delivered boolean in global search
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    isSaleLotDelivered(saleLots[saleLotId]) === isDelivered[0],
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for if the sale lots are in progress against the
 * global search boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsInProgressFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    consignments,
    isInProgress,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) => {
    return isInProgress === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots by the is in progress boolean in global search
        Object.values(saleLots)
          .filter(
            saleLot =>
              isSaleLotInProgress(
                saleLot,
                consignments[saleLot.consignment_id],
              ) === isInProgress[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered receival lots based on their linked sale lots by the in progress boolean in global search
          .concat(
            Object.values(receivalLots)
              .filter(receivalLot =>
                saleLotIdsByReceivalLotIdLookup[receivalLot.id]?.some(
                  saleLotId =>
                    isSaleLotInProgress(
                      saleLots[saleLotId],
                      consignments[saleLots[saleLotId]?.consignment_id],
                    ) === isInProgress[0],
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLot?.receivalPenId
                  ],
              ),
          )
          // add filtered pen scan lots based on their linked sale lots by the in progress boolean in global search
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                saleLotIdsByPenScanLotIdLookup[penScanLot.id]?.some(
                  saleLotId => {
                    const saleLotInProgress = isSaleLotInProgress(
                      saleLots[saleLotId],
                      consignments[saleLots[saleLotId]?.consignment_id],
                    );
                    return saleLotInProgress === isInProgress[0];
                  },
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot?.sellingPenId],
              ),
          );
  },
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for if the sale lots scan status is compliant
 *  against the global search boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsEidCompliantFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    selectScanStatusBySaleLotIdLookup,
    selectSaleLotsScanStatusesByReceivalLotIdLookup,
    selectSaleLotsScanStatusesByPenScanLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsEidCompliant),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    getReceivalLots,
    getPenScanLots,
  ],
  (
    saleLots,
    scanStatusBySaleLotIdLookup,
    saleLotScanStatusesByReceivalLotIdLookup,
    saleLotScanStatusesByPenScanLotIdLookup,
    isEidCompliant,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    receivalLots,
    penScanLots,
  ) =>
    isEidCompliant === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots by the is eid compliant boolean in global search
        Object.values(saleLots)
          .filter(
            saleLot =>
              ((scanStatusBySaleLotIdLookup[saleLot.id] || ScanStatus.PASS) ===
                ScanStatus.PASS) ===
              isEidCompliant[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          .concat(
            // add filtered recevial lots based on their linked sale lots by the eid compliant boolean in global search
            Object.values(receivalLots)
              .filter(
                receivalLot =>
                  saleLotScanStatusesByReceivalLotIdLookup[
                    receivalLot.id
                  ].includes(ScanStatus.PASS) === isEidCompliant[0],
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                lot => penArchetypeIdByAuctionPenIdLookup[lot.receivalPenId],
              ),
          )
          // add filtered pen scan lots based on their linked sale lots by the eid compliant boolean in global search
          .concat(
            Object.values(penScanLots)
              .filter(
                penScanLot =>
                  saleLotScanStatusesByPenScanLotIdLookup[
                    penScanLot.id
                  ].includes(ScanStatus.PASS) === isEidCompliant[0],
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(lot => penArchetypeIdByAuctionPenIdLookup[lot.sellingPenId]),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for if the sale lots are sold against the
 * global search boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsSoldFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    selectIsSoldBySaleLotIdLookup,
    selectAreSomeSaleLotsSoldByReceivalLotIdLookup,
    selectAreSomeSaleLotsSoldByPenScanLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSold),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isSoldBySaleLotIdLookup,
    isSomeSoldByReceivalLotIdLookup,
    isSomeSoldByPenScanLotIdLookup,
    isSold,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
  ) =>
    isSold === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots by the is sold boolean in global search
        Object.values(saleLots)
          .filter(saleLot => isSoldBySaleLotIdLookup[saleLot.id] === isSold[0])
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered recevial lots based on the is sold global search boolean
          .concat(
            Object.values(receivalLots)
              .filter(
                receivalLot =>
                  isSomeSoldByReceivalLotIdLookup[receivalLot.id] === isSold[0],
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots based on the is sold global search boolean
          .concat(
            Object.values(penScanLots)
              .filter(
                penScanLot =>
                  isSomeSoldByPenScanLotIdLookup[penScanLot.id] === isSold[0],
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked consignments for if the consignments are pre sale
 * balanced against the global search boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsVendorPreSaleBalancedFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    selectIsPreSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPreSaleVendorBalanced),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isPreSaleBalancedByConsignmentId,
    isVendorPresaleBalanced,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    consignmentIdsByPenScanLotIdLookup,
  ) =>
    isVendorPresaleBalanced === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots by their consignments against the is pre sale balanced boolean in global search
        Object.values(saleLots)
          .filter(
            saleLot =>
              isPreSaleBalancedByConsignmentId[saleLot.consignment_id] ===
              isVendorPresaleBalanced[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered receival lots based on if the consignment is balanced depending on the is vendor balanced global search boolean
          .concat(
            Object.values(receivalLots)
              .filter(
                receivalLot =>
                  isPreSaleBalancedByConsignmentId[
                    receivalLot.consignmentId
                  ] === isVendorPresaleBalanced[0],
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots based on if the consignment is balanced depending on the is vendor balanced global search boolean
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                consignmentIdsByPenScanLotIdLookup[penScanLot.id]?.some(
                  consignmentId =>
                    isPreSaleBalancedByConsignmentId[consignmentId] ===
                    isVendorPresaleBalanced[0],
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.penScanLotId],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked consignments for if the consignments are post sale
 * balanced against the global search boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsVendorPostSaleBalancedFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPostSaleVendorBalanced),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isPostSaleBalancedByConsignmentIdLookup,
    isVendorBalanced,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    consignmentIdsByPenScanLotIdLookup,
  ) => {
    return isVendorBalanced === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots by their consignments against the is post sale balanced boolean in global search
        Object.values(saleLots)
          .filter(
            saleLot =>
              isPostSaleBalancedByConsignmentIdLookup[
                saleLot.consignment_id
              ] === isVendorBalanced[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered receival lots based on if the consignment is post sale balanced depending on the is vendor post sale balanced global search boolean
          .concat(
            Object.values(receivalLots)
              .filter(
                receivalLot =>
                  isPostSaleBalancedByConsignmentIdLookup[
                    receivalLot.consignmentId
                  ] === isVendorBalanced[0],
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots based on if the consignment is post sale balanced depending on the is vendor post sale balanced global search boolean
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                consignmentIdsByPenScanLotIdLookup[penScanLot.id]?.some(
                  consignmentId =>
                    isPostSaleBalancedByConsignmentIdLookup[consignmentId] ===
                    isVendorBalanced[0],
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.penScanLotId],
              ),
          );
  },
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * against linked sale lots for if the sale lots' labels are included
 * in the global search filter
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectLabelFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Label),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    labels,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    labels === null
      ? unfilteredValues
      : // filter sale lots by their labels matching the global search labels
        Object.values(saleLots)
          .filter(
            saleLot =>
              saleLot.labels?.length > 0 &&
              labels.some(
                labelIds => intersection(saleLot.labels, labelIds).length > 0,
              ),
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered pen scan lots based on if their linked sale lot labels include the global search labels
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    saleLots[saleLotId].labels?.length > 0 &&
                    labels.some(
                      labelIds =>
                        intersection(saleLots[saleLotId].labels, labelIds)
                          .length > 0,
                    ),
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          )
          // add filtered receival lots based on if their linked sale lot labels include the global search labels
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(
                  saleLotId =>
                    saleLots[saleLotId].labels?.length > 0 &&
                    labels.some(
                      labelIds =>
                        intersection(saleLots[saleLotId].labels, labelIds)
                          .length > 0,
                    ),
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * their linked sale lots' auction pens against the filtered
 * lanes
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectLaneFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Lane),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    lanes,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
    auctionPenIdBySaleLotIdLookup,
  ) =>
    lanes === null
      ? unfilteredValues
      : // filter sale lots by their auction pen lanes including the global search lanes
        Object.values(saleLots)
          .filter(saleLot =>
            lanes.some(lane =>
              lane.includes(auctionPenIdBySaleLotIdLookup[saleLot.id]),
            ),
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // check sale lots' auction pens via pen scan lot
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  lanes.some(lane =>
                    lane.includes(auctionPenIdBySaleLotIdLookup[saleLotId]),
                  ),
                ),
              )
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          )
          // check sale lots' auction pens via receival lot
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  lanes.some(lane =>
                    lane.includes(auctionPenIdBySaleLotIdLookup[saleLotId]),
                  ),
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * their linked sale lots' overflow pens against the global search
 * overflow pens
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectOverflowPenFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.OverflowPen),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    overFlowPens,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    overFlowPens === null
      ? unfilteredValues
      : // filter sale lots by their overflow pens included to the global search over flow pens
        Object.values(saleLots)
          .filter(saleLot => overFlowPens.includes(saleLot.id))
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered pen scan lots based on their linked sale lots having the same overflow pens as global search
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId => overFlowPens.includes(saleLotId)),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          )
          // add filtered receival lots based on their linked sale lots having the same overflow pens as global search
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId => overFlowPens.includes(saleLotId)),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * their linked sale lots' pricing types against the global search
 * pricing types
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectPricingTypeFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.PricingType),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    pricingTypes,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    pricingTypes === null
      ? unfilteredValues
      : // filter sale lots by their pricing types included in the globsl search pricing types
        Object.values(saleLots)
          .filter(saleLot => pricingTypes.includes(saleLot.pricing_type_id))
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered pen scan lots based on their linked sale lots having the same pricing type as global search
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  pricingTypes.includes(saleLots[saleLotId].pricing_type_id),
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          )
          // add filtered receival lots based on their linked sale lots having the same pricing type as global search
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  pricingTypes.includes(saleLots[saleLotId].pricing_type_id),
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot ids, receival lot ids and pen scan lot ids using their sale round
 * fitlered ids. Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectSaleRoundFilteredPenArchetypeIds = createSelector(
  [
    selectSaleRoundFilteredSaleLotIds,
    selectSaleRoundFilteredReceivalLotIds,
    selectSaleRoundFilteredPenScanLotIds,
    getGlobalSearchBySearchField(GlobalSearchFields.SaleRound),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
    getReceivalLots,
    getPenScanLots,
  ],
  (
    filteredSaleLotIds,
    filteredReceivalLotIds,
    filteredPenScanLotIds,
    saleRoundIds,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    auctionPenIdBysaleLotIdLookup,
    receivalLotByIdLookup,
    penScanLotByIdLookup,
  ) => {
    // go over filtered sale lot ids
    const penArchetypeIds = [
      ...filteredSaleLotIds.map(
        saleLotId =>
          penArchetypeIdByAuctionPenIdLookup[
            auctionPenIdBysaleLotIdLookup[saleLotId]
          ],
      ),
      // go over filtered receival lot ids
      ...filteredReceivalLotIds.map(receivalLotId => {
        const receivalLot = receivalLotByIdLookup[receivalLotId];
        return penArchetypeIdByAuctionPenIdLookup[receivalLot?.receivalPenId];
      }),
      // go over filtered pen scan lot ids
      ...filteredPenScanLotIds.map(penScanLotId => {
        const penScanLot = penScanLotByIdLookup[penScanLotId];
        return penArchetypeIdByAuctionPenIdLookup[penScanLot?.sellingPenId];
      }),
    ].filter(Boolean);

    return saleRoundIds === null ? unfilteredPenArchetypeIds : penArchetypeIds;
  },
);

/**
 * get eids based on the global search field then return pen archetype Ids based off
 * sale lots, receival lots and pen scan lots linked to the (auction|receival|selling) pen
 */
const selectScanFilteredPenArchetypeIds = createSelector(
  [
    getReceivalLots,
    getPenScanLots,
    selectAuctionPenIdByEidLookup,
    selectReceivalLotIdByEidLookup,
    selectPenScanLotIdByEidLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Scan),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
  ],
  (
    receivalLots,
    penScanLots,
    auctionPenIdByEidLookup,
    receivalLotIdByEidLookup,
    penScanLotIdByEidLookup,
    eids,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
  ) =>
    eids === null
      ? unfilteredPenArchetypeIds
      : eids
          // get pen archetype id from the sale lot auction pen linked to the searched eid
          .map(
            eid =>
              penArchetypeIdByAuctionPenIdLookup[auctionPenIdByEidLookup[eid]],
          )
          // get pen archetype id from the receival lots receival pen linked to the searched eid
          .concat(
            uniq(
              eids.map(
                eid =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotIdByEidLookup[eid]]?.receivalPenId
                  ],
              ),
            ),
          )
          // get pen archetype id from the pen scan lots selling pen linked to the searched eid
          .concat(
            uniq(
              eids.map(
                eid =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotIdByEidLookup[eid]]?.sellingPenId
                  ],
              ),
            ),
          )
          .filter(Boolean),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * their linked sale lots' third parties against the global search
 * third parties
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectThirdPartyFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.ThirdParty),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    thirdPartyIds,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    thirdPartyIds === null
      ? unfilteredValues
      : Object.values(saleLots)
          // filter sale lots by their third party ids included in the global search third party ids
          .filter(saleLot => thirdPartyIds.includes(saleLot.thirdPartyId))
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered pen scan lots based on their linked sale lots having the same third parties as global search
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  thirdPartyIds.includes(saleLots[saleLotId].thirdPartyId),
                ),
              )
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          )
          // add filtered receival lots based on their linked sale lots having the same third parties as global search
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  thirdPartyIds.includes(saleLots[saleLotId].thirdPartyId),
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * their linked sale lots' vendor against the global search
 * vendor
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectVendorFilteredPenArchetypeIds = createSelector(
  [
    selectVendorIdBySaleLotIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Vendor),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectVendorIdByReceivalLotIdLookup,
    selectVendorIdsByPenScanLotIdLookup,
    getReceivalLots,
    getPenScanLots,
  ],
  (
    vendorIdBySaleLotIdLookup,
    auctionPenIdBySaleLotIdLookup,
    vendorIds,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    vendorIdByReceivalLotIdLookup,
    vendorIdsByPenScanLotIdLookup,
    receivalLotByIdLookup,
    penScanLotByIdLookup,
  ) =>
    vendorIds === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots by their vendor ids included in the global search vendor ids
        Object.entries(vendorIdBySaleLotIdLookup)
          .filter(([ignored, vendorId]) => vendorIds.includes(vendorId))
          // return pen archetype ids based off the sale lot auction pen
          .map(
            ([saleLotId, ignored]) =>
              penArchetypeIdByAuctionPenIdLookup[
                auctionPenIdBySaleLotIdLookup[saleLotId]
              ],
          )
          // add filtered receival lots based on their linked vendors having the same vendor as global search
          .concat(
            Object.entries(vendorIdByReceivalLotIdLookup)
              .filter(([ignored, vendorId]) => vendorIds.includes(vendorId))
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLotByIdLookup[receivalLotId].receivalPenId
                  ],
              ),
          )
          // add filtered pen scan lots based on their linked vendors having the same vendor as global search
          .concat(
            Object.entries(vendorIdsByPenScanLotIdLookup)
              .map(([penScanLotId, penScanLotVendorIds]) => {
                if (
                  penScanLotVendorIds.filter(penScanVendorId =>
                    vendorIds?.includes(penScanVendorId),
                  ).length
                ) {
                  // return pen archetype ids based off the pen scan lots' selling pen
                  return penArchetypeIdByAuctionPenIdLookup[
                    penScanLotByIdLookup[penScanLotId]?.sellingPenId
                  ];
                }
                return null;
              })
              .filter(Boolean),
          )
          .filter(Boolean),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * for linked consignments for vendor number against global searched
 * vendor numbers
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectVendorNumberFilteredPenArchetypeIds = createSelector(
  [
    getConsignments,
    getReceivalLots,
    getPenScanLots,
    selectAuctionPenIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorNumber),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    consignments,
    receivalLots,
    penScanLots,
    auctionPenIdsByConsignmentIdLookup,
    vendorNumbers,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    consignmentIdsByPenScanLotIdLookup,
  ) =>
    vendorNumbers === null
      ? unfilteredPenArchetypeIds
      : // filter cosnignments that have a vendor number included in the global search vendor numbers
        Object.values(consignments)
          .filter(consignment =>
            vendorNumbers.includes(getConsignmentCode(consignment)),
          )
          // return pen archetype ids based off the consignments auction pens
          .map(consignment =>
            auctionPenIdsByConsignmentIdLookup[consignment.id].forEach(
              auctionPenId => penArchetypeIdByAuctionPenIdLookup[auctionPenId],
            ),
          )
          // add filtered receival lots based on their linked vendor numbers having the same vendor number as global search
          .concat(
            Object.values(receivalLots)
              .filter(receivalLot =>
                vendorNumbers.includes(
                  getConsignmentCode(consignments[receivalLot.consignmentId]),
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots based on their linked vendor numbers having the same vendor number as global search
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                vendorNumbers.find(vendorNumber =>
                  consignmentIdsByPenScanLotIdLookup[penScanLot.id]
                    .map(consignmentId =>
                      getConsignmentCode(consignments[consignmentId]),
                    )
                    .includes(vendorNumber),
                ),
              )
              // return pen archetype ids based off the pen scan lots' selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          ),
);

/**
 * Go over each consignment and return their pen archetype Ids linked
 * to the (auction|receival|selling) pen
 */
const selectVendorPicFilteredPenArchetypeIds = createSelector(
  [
    getConsignments,
    getReceivalLots,
    getPenScanLots,
    selectAuctionPenIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorPic),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    consignments,
    receivalLots,
    penScanLots,
    auctionPenIdsByConsignmentIdLookup,
    vendorPic,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
    consignmentIdsByPenScanLotIdLookup,
  ) =>
    vendorPic === null
      ? unfilteredPenArchetypeIds
      : // filter consignments that vendor propert is equal to the global search vendor properties
        Object.values(consignments)
          .filter(consignment =>
            vendorPic.includes(consignment.vendor_property_id),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(
                auctionPenIdsByConsignmentIdLookup[consignment.id].map(
                  auctionPenId =>
                    penArchetypeIdByAuctionPenIdLookup[auctionPenId],
                ),
              ),
            [],
          )
          // add filtered receival lots based on their linked consignments vendor properties having the same vendor PIC as global search
          .concat(
            Object.values(receivalLots)
              .filter(receivalLot =>
                vendorPic.includes(
                  consignments[receivalLot.consignmentId]?.vendor_property_id,
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots based on their linked consignments vendor properties having the same vendor PIC as global search
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                vendorPic.find(vendorP =>
                  consignmentIdsByPenScanLotIdLookup[penScanLot.id]
                    ?.map(
                      consignmentId =>
                        consignments[consignmentId].vendor_property_id,
                    )
                    .includes(vendorP),
                ),
              )
              // return pen archetype ids based off the pen scan lots' selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * for linked sale lots destination propertys PIC against global searched
 * buyer pic
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectBuyerPicFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerPic),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    propertyIds,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    propertyIds === null
      ? unfilteredValues
      : // filter sale lots that have destination property ids included in the the global search destination proerty ids
        Object.values(saleLots)
          .filter(saleLot =>
            propertyIds.includes(saleLot.destination_property_id),
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered pen scan lots linked to sale lots that have destination property ids included in the the global search destination proerty ids
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  propertyIds.includes(
                    saleLots[saleLotId].destination_property_id,
                  ),
                ),
              )
              // return pen archetype ids based off the pen scan lots' selling pen
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          )
          // add filtered receival lots linked to sale lots that have destination property ids included in the the global search destination proerty ids
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  propertyIds.includes(
                    saleLots[saleLotId].destination_property_id,
                  ),
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * for linked sale lots that have been updated against global searched
 * checkpoint
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectCheckpointFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Checkpoint),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    checkpoints,
    unfilteredValues,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    checkpoints === null
      ? unfilteredValues
      : // filter sale lots that have been updated after checkpoints selected in global search
        Object.values(saleLots)
          .filter(saleLot => isObjectChangedAfter(saleLot, checkpoints[0]))
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered pen scan lots linked to sale lots that have been updated after checkpoints selected in global search
          .concat(
            Object.entries(saleLotIdsByPenScanLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  isObjectChangedAfter(saleLots[saleLotId], checkpoints[0]),
                ),
              )
              .map(
                ([penScanLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    penScanLots[penScanLotId]?.sellingPenId
                  ],
              ),
          )
          // add filtered receival lots linked to sale lots that have been updated after checkpoints selected in global search
          .concat(
            Object.entries(saleLotIdsByReceivalLotIdLookup)
              .filter(([ignored, saleLotIds]) =>
                saleLotIds?.some(saleLotId =>
                  isObjectChangedAfter(saleLots[saleLotId], checkpoints[0]),
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                ([receivalLotId, ignored]) =>
                  penArchetypeIdByAuctionPenIdLookup[
                    receivalLots[receivalLotId]?.receivalPenId
                  ],
              ),
          ),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * for linked sale lots' auctions plus compliance against global searched
 * is auctions plus compliant boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsAuctionsPlusCompliantPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredPenArchetypeIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsAuctionsPlusCompliant),
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredPenArchetypeIds,
    searchField,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) => {
    return searchField === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots that's auction plus compliance is equal to the global search auctions plus compliance
        Object.values(saleLots)
          .filter(
            saleLot =>
              isIntegrationCompliantBySaleLotIdLookup[saleLot.id][
                ExportSites.AUCTIONS_PLUS
              ] === searchField[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered receival lots linked to sale lots that's auction plus compliance is equal to the global search auctions plus compliance
          .concat(
            Object.values(receivalLots)
              .filter(receivalLot =>
                saleLotIdsByReceivalLotIdLookup[receivalLot.id]?.some(
                  saleLotId =>
                    isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                      ExportSites.AUCTIONS_PLUS
                    ] === searchField[0],
                ),
              )
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots linked to sale lots that's auction plus compliance is equal to the global search auctions plus compliance
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                saleLotIdsByPenScanLotIdLookup[penScanLot.id]?.some(
                  saleLotId =>
                    isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                      ExportSites.AUCTIONS_PLUS
                    ] === searchField[0],
                ),
              )
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          )
          .filter(Boolean);
  },
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * for linked sale lots' stock live compliance against global searched
 * is stock live compliant boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsStockLiveCompliantPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredPenArchetypeIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsStockLiveCompliant),
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredPenArchetypeIds,
    searchField,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) => {
    return searchField === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots thats stock live compliance is equal to the global search stock live compliance
        Object.values(saleLots)
          .filter(
            saleLot =>
              isIntegrationCompliantBySaleLotIdLookup[saleLot.id][
                ExportSites.STOCK_LIVE
              ] === searchField[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(
            saleLot =>
              penArchetypeIdByAuctionPenIdLookup[saleLot.auction_pen_id],
          )
          // add filtered pen scan lots linked to sale lots that's stock live compliance is equal to the global search stock live compliance
          .concat(
            Object.values(receivalLots)
              .filter(receivalLot =>
                saleLotIdsByReceivalLotIdLookup[receivalLot.id]?.some(
                  saleLotId =>
                    isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                      ExportSites.STOCK_LIVE
                    ] === searchField[0],
                ),
              )
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots linked to sale lots that's stock live compliance is equal to the global search stock live compliance
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                saleLotIdsByPenScanLotIdLookup[penScanLot.id]?.some(
                  saleLotId =>
                    isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                      ExportSites.STOCK_LIVE
                    ] === searchField[0],
                ),
              )
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          );
  },
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * for linked sale lots that have an image uplaoded against global searched
 * has image boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsSaleLotImageUploadedPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotImageUploaded),
    selectUnfilteredPenArchetypeIds,
    selectSaleLotHasImageLookup,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isImageUploaded,
    unfilteredPenArchetypeIds,
    saleLotHasImageLookup,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    isImageUploaded === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots that have an image uploaded based on the global search boolean
        Object.values(saleLots)
          .filter(
            saleLot => saleLotHasImageLookup[saleLot.id] === isImageUploaded[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(lot => penArchetypeIdByAuctionPenIdLookup[lot.auction_pen_id])
          // add filtered receival lots thats linked sale lots that have an image uploaded based on the global search boolean
          .concat(
            Object.values(receivalLots)
              .filter(receivalLot =>
                saleLotIdsByReceivalLotIdLookup[receivalLot.id]?.some(
                  saleLotId =>
                    saleLotHasImageLookup[saleLotId] === isImageUploaded[0],
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots thats linked sale lots that have an image uploaded based on the global search boolean
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                saleLotIdsByPenScanLotIdLookup[penScanLot.id]?.some(
                  saleLotId =>
                    saleLotHasImageLookup[saleLotId] === isImageUploaded[0],
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          )
          .filter(Boolean),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * for linked sale lots that have an video uplaoded against global searched
 * has video boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsSaleLotVideoUploadedPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotVideoUploaded),
    selectUnfilteredPenArchetypeIds,
    selectSaleLotHasVideoLookup,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectSaleLotIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isVideoUploaded,
    unfilteredPenArchetypeIds,
    saleLotHasVideoLookup,
    penArchetypeIdByAuctionPenIdLookup,
    saleLotIdsByReceivalLotIdLookup,
    saleLotIdsByPenScanLotIdLookup,
  ) =>
    isVideoUploaded === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots that have an video uploaded based on the global search boolean
        Object.values(saleLots)
          .filter(
            saleLot => saleLotHasVideoLookup[saleLot.id] === isVideoUploaded[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(lot => penArchetypeIdByAuctionPenIdLookup[lot.auction_pen_id])
          // add filtered pen scan lots thats linked sale lots that have a video uploaded based on the global search boolean
          .concat(
            Object.values(receivalLots)
              .filter(receivalLot =>
                saleLotIdsByReceivalLotIdLookup[receivalLot.id]?.some(
                  saleLotId =>
                    saleLotHasVideoLookup[saleLotId] === isVideoUploaded[0],
                ),
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots thats linked sale lots that have a video uploaded based on the global search boolean
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                saleLotIdsByPenScanLotIdLookup[penScanLot.id]?.some(
                  saleLotId =>
                    saleLotHasVideoLookup[saleLotId] === isVideoUploaded[0],
                ),
              )
              // return pen archetype ids based off the pen scan lot selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          )
          .filter(Boolean),
);

/**
 * Go over each consignment and check for linked consignments
 * that have an image uploaded against global searched
 * has image boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsConsignmentImageUploadedPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsConsignmentImageUploaded),
    selectUnfilteredPenArchetypeIds,
    selectConsignmentHasImageLookup,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isImageUploaded,
    unfilteredPenArchetypeIds,
    consignmentHasImageLookup,
    penArchetypeIdByAuctionPenIdLookup,
    consignmentIdsByPenScanLotIdLookup,
  ) =>
    isImageUploaded === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots by their consignments that have an image uploaded based on the global search boolean
        Object.values(saleLots)
          .filter(
            saleLot =>
              consignmentHasImageLookup[saleLot.consignment_id] ===
              isImageUploaded[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(lot => penArchetypeIdByAuctionPenIdLookup[lot.auction_pen_id])
          // add filtered recevial lots thats linked consignments have an image uploaded based on the global search boolean
          .concat(
            Object.values(receivalLots)
              .filter(
                receivalLot =>
                  consignmentHasImageLookup[receivalLot.consignmentId] ===
                  isImageUploaded[0],
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots thats linked consignments have an image uploaded based on the global search boolean
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                consignmentIdsByPenScanLotIdLookup[penScanLot.id]?.some(
                  consignmentId =>
                    consignmentHasImageLookup[consignmentId] ===
                    isImageUploaded[0],
                ),
              )
              // return pen archetype ids based off the pen scan lots' selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          )
          .filter(Boolean),
);

/**
 * Go over each consignment and check for linked consignments
 * that have a video uploaded against global searched
 * has video boolean
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectIsConsignmentVideoUploadedPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsConsignmentVideoUploaded),
    selectUnfilteredPenArchetypeIds,
    selectConsignmentHasVideoLookup,
    selectPenArchetypeIdByAuctionPenIdLookup,
    selectConsignmentIdsByPenScanLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    isVideoUploaded,
    unfilteredPenArchetypeIds,
    consignmentHasVideoLookup,
    penArchetypeIdByAuctionPenIdLookup,
    consignmentIdsByPenScanLotIdLookup,
  ) =>
    isVideoUploaded === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots by their consignments that have a video uploaded based on the global search boolean
        Object.values(saleLots)
          .filter(
            saleLot =>
              consignmentHasVideoLookup[saleLot.consignment_id] ===
              isVideoUploaded[0],
          )
          // return pen archetype ids based off the sale lot auction pen
          .map(lot => penArchetypeIdByAuctionPenIdLookup[lot.auction_pen_id])
          // add filtered receival lots thats linked consignments have a video uploaded based on the global search boolean
          .concat(
            Object.values(receivalLots)
              .filter(
                receivalLot =>
                  consignmentHasVideoLookup[receivalLot.consignmentId] ===
                  isVideoUploaded[0],
              )
              // return pen archetype ids based off the receival lots' receival pen
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots thats linked consignments have a video uploaded based on the global search boolean
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                consignmentIdsByPenScanLotIdLookup[penScanLot.id]?.some(
                  consignmentId =>
                    consignmentHasVideoLookup[consignmentId] ===
                    isVideoUploaded[0],
                ),
              )
              // return pen archetype ids based off the pen scan lots' selling pen
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          )
          .filter(Boolean),
);

/**
 * Go over each sale lot, receival lot and pen scan lot and check
 * for linked sale lots that have marks that are included in the
 * global searched marks
 *
 * Then return their pen archetype Ids linked to the (auction|receival|selling) pen
 */
const selectMarksFilteredPenArchetypeIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getPenScanLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredPenArchetypeIds,
    selectPenArchetypeIdByAuctionPenIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    penScanLots,
    marks,
    unfilteredPenArchetypeIds,
    penArchetypeIdByAuctionPenIdLookup,
  ) =>
    marks === null
      ? unfilteredPenArchetypeIds
      : // filter sale lots that have the same marks as the global search filter
        Object.values(saleLots)
          .filter(saleLot =>
            marks.some(mark => mark === getSortedMarkDetails(saleLot)),
          )
          .map(lot => penArchetypeIdByAuctionPenIdLookup[lot.auction_pen_id])
          // add filtered receival lots have the same mark as the global search filter
          .concat(
            Object.values(receivalLots)
              .filter(receivalLot => marks.includes(receivalLot.mark))
              .map(
                receivalLot =>
                  penArchetypeIdByAuctionPenIdLookup[receivalLot.receivalPenId],
              ),
          )
          // add filtered pen scan lots have the same marks as the global search filter
          .concat(
            Object.values(penScanLots)
              .filter(penScanLot =>
                marks.some(mark => mark === getSortedMarkDetails(penScanLot)),
              )
              .map(
                penScanLot =>
                  penArchetypeIdByAuctionPenIdLookup[penScanLot.sellingPenId],
              ),
          ),
);

export const selectFilteredPenArchetypeIds = createSelector(
  [
    selectAgencyFilteredPenArchetypeIds,
    selectAuctionPenFilteredPenArchetypeIds,
    selectBuyerFilteredPenArchetypeIds,
    selectBuyerAndBuyerWayFilteredPenArchetypeIds,
    selectDeliveryPenFilteredPenArchetypeIds,
    selectHasArrivedFilteredPenArchetypeIds,
    selectHasBuyerExceptionsFilteredPenArchetypeIds,
    selectHasConsignmentScansFilteredPenArchetypeIds,
    selectHasOverflowFilteredPenArchetypeIds,
    selectHasProgenyFilteredPenArchetypeIds,
    selectHasVendorExceptionsFilteredPenArchetypeIds,
    selectHasWeightFilteredPenArchetypeIds,
    selectIsBuyerBalancedFilteredPenArchetypeIds,
    selectIsCountedFilteredPenArchetypeIds,
    selectIsDeliveredFilteredPenArchetypeIds,
    selectIsEidCompliantFilteredPenArchetypeIds,
    selectIsInProgressFilteredPenArchetypeIds,
    selectIsSoldFilteredPenArchetypeIds,
    selectIsVendorPreSaleBalancedFilteredPenArchetypeIds,
    selectIsVendorPostSaleBalancedFilteredPenArchetypeIds,
    selectLabelFilteredPenArchetypeIds,
    selectLaneFilteredPenArchetypeIds,
    selectOverflowPenFilteredPenArchetypeIds,
    selectPricingTypeFilteredPenArchetypeIds,
    selectSaleRoundFilteredPenArchetypeIds,
    selectScanFilteredPenArchetypeIds,
    selectThirdPartyFilteredPenArchetypeIds,
    selectVendorFilteredPenArchetypeIds,
    selectVendorNumberFilteredPenArchetypeIds,
    selectVendorPicFilteredPenArchetypeIds,
    selectBuyerPicFilteredPenArchetypeIds,
    selectCheckpointFilteredPenArchetypeIds,
    selectIsAuctionsPlusCompliantPenArchetypeIds,
    selectIsStockLiveCompliantPenArchetypeIds,
    selectIsSaleLotImageUploadedPenArchetypeIds,
    selectIsSaleLotVideoUploadedPenArchetypeIds,
    selectIsConsignmentImageUploadedPenArchetypeIds,
    selectIsConsignmentVideoUploadedPenArchetypeIds,
    selectMarksFilteredPenArchetypeIds,
  ],
  (
    agencyFilteredPenArchetypeIds,
    auctionPenFilteredPenArchetypeIds,
    buyerFilteredPenArchetypeIds,
    buyerAndBuyerWayFilteredPenArchetypeIds,
    deliveryPenFilteredPenArchetypeIds,
    hasArrivedFilteredPenArchetypeIds,
    hasBuyerExceptionsFilteredPenArchetypeIds,
    hasConsignmentScansFilteredPenArchetypeIds,
    hasOverflowFilteredPenArchetypeIds,
    hasProgenyFilteredPenArchetypeIds,
    hasVendorExceptionsFilteredPenArchetypeIds,
    hasWeightFilteredPenArchetypeIds,
    isBuyerBalancedFilteredPenArchetypeIds,
    isCountedFilteredPenArchetypeIds,
    isDeliveredFilteredPenArchetypeIds,
    isEidCompliantFilteredPenArchetypeIds,
    isInProgressFilteredPenArchetypeIds,
    isSoldFilteredPenArchetypeIds,
    isVendorPreSaleBalancedFilteredPenArchetypeIds,
    isVendorPostSaleBalancedFilteredPenArchetypeIds,
    labelFilteredPenArchetypeIds,
    laneFilteredPenArchetypeIds,
    overflowPenFilteredPenArchetypeIds,
    pricingTypeFilteredPenArchetypeIds,
    saleRoundFilteredPenArchetypeIds,
    scanFilteredPenArchetypeIds,
    thirdPartyFilteredPenArchetypeIds,
    vendorFilteredPenArchetypeIds,
    vendorNumberFilteredPenArchetypeIds,
    vendorPicFilteredPenArchetypeIds,
    buyerPicFilteredPenArchetypeIds,
    checkpointFilteredPenArchetypeIds,
    isAuctionsPlusCompliantPenArchetypeIds,
    isStockLiveCompliantPenArchetypeIds,
    isSaleLotImageUploadedPenArchetypeIds,
    isSaleLotVideoUploadedPenArchetypeIds,
    isConsignmentImageUploadedPenArchetypeIds,
    isConsignmentVideoUploadedPenArchetypeIds,
    marksFilteredPenArchetypeIds,
  ) =>
    intersection(
      agencyFilteredPenArchetypeIds,
      auctionPenFilteredPenArchetypeIds,
      buyerFilteredPenArchetypeIds,
      buyerAndBuyerWayFilteredPenArchetypeIds,
      deliveryPenFilteredPenArchetypeIds,
      hasArrivedFilteredPenArchetypeIds,
      hasBuyerExceptionsFilteredPenArchetypeIds,
      hasConsignmentScansFilteredPenArchetypeIds,
      hasOverflowFilteredPenArchetypeIds,
      hasProgenyFilteredPenArchetypeIds,
      hasVendorExceptionsFilteredPenArchetypeIds,
      hasWeightFilteredPenArchetypeIds,
      isBuyerBalancedFilteredPenArchetypeIds,
      isCountedFilteredPenArchetypeIds,
      isDeliveredFilteredPenArchetypeIds,
      isEidCompliantFilteredPenArchetypeIds,
      isInProgressFilteredPenArchetypeIds,
      isSoldFilteredPenArchetypeIds,
      isVendorPreSaleBalancedFilteredPenArchetypeIds,
      isVendorPostSaleBalancedFilteredPenArchetypeIds,
      labelFilteredPenArchetypeIds,
      laneFilteredPenArchetypeIds,
      overflowPenFilteredPenArchetypeIds,
      pricingTypeFilteredPenArchetypeIds,
      saleRoundFilteredPenArchetypeIds,
      scanFilteredPenArchetypeIds,
      thirdPartyFilteredPenArchetypeIds,
      vendorFilteredPenArchetypeIds,
      vendorNumberFilteredPenArchetypeIds,
      vendorPicFilteredPenArchetypeIds,
      buyerPicFilteredPenArchetypeIds,
      checkpointFilteredPenArchetypeIds,
      isAuctionsPlusCompliantPenArchetypeIds,
      isStockLiveCompliantPenArchetypeIds,
      isSaleLotImageUploadedPenArchetypeIds,
      isSaleLotVideoUploadedPenArchetypeIds,
      isConsignmentImageUploadedPenArchetypeIds,
      isConsignmentVideoUploadedPenArchetypeIds,
      marksFilteredPenArchetypeIds,
    ),
);
