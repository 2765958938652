import React from "react";

import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { getBillingDocumentById, getBusinessById } from "selectors";

export function ViewDocumentForm(props) {
  const { documentId } = props;

  const { issuerBusinessId, recipientBusinessId, number, type } =
    useSelector(getBillingDocumentById(documentId)) || {};

  const issuerBusiness = useSelector(getBusinessById(issuerBusinessId)) || {
    name: "",
  };
  const recipientBusiness = useSelector(
    getBusinessById(recipientBusinessId),
  ) || { name: "" };

  return (
    <>
      <Grid item xs={6}>
        <strong>Document Type:</strong>
        <div className="p-2">{type}</div>
      </Grid>
      <Grid item xs={6}>
        <strong>Document Number:</strong>
        <div className="p-2">{number}</div>
      </Grid>
      <Grid item xs={6}>
        <strong>Issuer:</strong>
        <div className="p-2">{issuerBusiness.name}</div>
      </Grid>
      <Grid item xs={6}>
        <strong>Recipient:</strong>
        <div className="p-2">{recipientBusiness.name}</div>
      </Grid>
    </>
  );
}
