import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const serializeMap = {
  accountType: "account_type",
  code: "code",
  deploymentId: "deployment_id",
  description: "description",
  id: "id",
  name: "name",
  masterLedgerAccountIds: "master_ledger_account_ids",
  saleyardId: "saleyard_id",
  status: "status",
  syncing: "syncing",
  xeroAccountId: "xero_account_id",
};

export const serializeLedgerAccount = buildSerializer(
  serializeMap,
  "ledgerAccount",
);
export const deserializeLedgerAccount = buildDeserializer(
  serializeMap,
  "ledgerAccount",
);
