import isEqual from "lodash/isEqual";
import sumBy from "lodash/sumBy";
import shallowCompare from "shallow-compare";

import { formatPricingTypeOutput, PricingTypes } from "constants/pricingTypes";
import { PricingEntryTypes } from "constants/saleLots";

import { caseInsensitiveCompare } from "./compare";

export const getPropertyList = (obj, getObjName) =>
  Object.values(obj).map(value => ({ value, label: getObjName(value) }));

/**
 * Create a filtered array of objects.
 *
 * @param {array} data
 * @param {string} searchTerm
 */
export const filterAll = (data, searchTerm) =>
  data.filter(
    data =>
      !!Object.keys(data).find(dataKey =>
        String(data[dataKey])
          .toLowerCase()
          .includes(String(searchTerm).toLowerCase()),
      ),
  );

/**
 * Converts a number to a string, rounding to the provided decimal places, and adding
 * the thousands separator. It will also prepend a positive and negative sign where
 * required and set
 * @param {Number} amount
 * @param {Number} [decimalCount=2]
 * @param {String} [decimal="."]
 * @param {String} [thousands=","]
 * @param {Boolean} [showNegativeSign=true]
 * @param {Boolean} [showPositiveSign=false]
 * @returns {string}
 */
export const formatDecimal = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ",",
  showNegativeSign = true,
  showPositiveSign = false,
) => {
  let workingAmount = amount;
  if (typeof workingAmount !== "number") {
    // TODO Remove all calls to this function where amount is a string
    // eslint-disable-next-line no-console
    console.error("Parameter 'amount' is not a number. Attempting conversion.");
    workingAmount = +workingAmount;
  }

  const amountNormalised =
    typeof amount === "number" && !Number.isNaN(workingAmount)
      ? workingAmount
      : 0;

  const isPositive = amountNormalised >= 0;
  const positiveSign = showPositiveSign ? "+" : "";
  const negativeSign = showNegativeSign ? "-" : "";

  // Math.abs used to remove the leading "-" character from the return of toFixed
  // as this would circumvent the prefixNegativeSign and prefixPositiveSign options.
  // It also messes with the thousand comma calculation
  const sAmountAbs = isPositive
    ? amountNormalised.toFixed(decimalCount)
    : Math.abs(amountNormalised).toFixed(decimalCount);

  // 0 chars when decimalCount is 0, or 1 char (for decimal point) plus decimalCount
  const decimalCharsCount = decimalCount && decimalCount + 1;

  const sAmountInteger = sAmountAbs.substr(
    0,
    sAmountAbs.length - decimalCharsCount,
  );

  const sAmountDecimal = sAmountAbs.substr(
    sAmountAbs.length - decimalCount,
    decimalCount,
  );

  let sResult = isPositive ? positiveSign : negativeSign;

  for (let i = 0; i < sAmountInteger.length; i++) {
    // every three characters, but not the last or first character
    const j = sAmountInteger.length - i;
    if (j % 3 === 0 && i !== sAmountInteger.length - 1 && i) {
      // Append a thousands separator to the output
      sResult += thousands;
    }
    sResult += sAmountInteger[i];
  }

  // Add the decimal point and the remaining decimal fraction
  if (decimalCount) {
    sResult += decimal + sAmountDecimal;
  }

  return sResult;
};

export const sortNumericalStrings = (objArray, selectKey) =>
  objArray.sort((a, b) => caseInsensitiveCompare(selectKey(a), selectKey(b)));

export const sortByKey = (objs, key) =>
  objs.sort((a, b) => (a[key] || "").localeCompare(b[key] || ""));

export const getDollarPriceStringFromCents = (
  cents,
  defaultValue = "0",
  format = false,
) => {
  if (!cents) {
    return defaultValue;
  }

  const priceDollars = cents / 100;

  return getDollarsPriceString(priceDollars, format);
};

export const getDollarsPriceString = (priceDollars, format = false) => {
  // returns the mantissa (cents)
  // only show the cents if its not a whole number
  const decimalCount = priceDollars % 1 === 0 ? 0 : 2;
  return format
    ? formatDecimal(priceDollars, decimalCount)
    : priceDollars.toFixed(decimalCount);
};

export const formatNumber = (number, maximumFractionDigits = 2) =>
  number.toLocaleString("en-AU", {
    maximumFractionDigits,
  });

export const formatDollarNumberWithExtraDecimals = dollars =>
  dollars.toLocaleString("en-AU", {
    style: "currency",
    currency: "AUD",
    maximumFractionDigits: 4,
  });

export const calculateUnitPriceCents = ({
  total_price_cents,
  total_mass_grams,
  pricing_type_id,
  quantity,
}) => {
  // When per kilo, and no mass is provided, it is assumed the pricing type is
  // actually gross.
  if (
    pricing_type_id === PricingTypes.PER_KILO &&
    typeof total_mass_grams === "number" &&
    total_mass_grams > 0
  ) {
    return (total_price_cents / total_mass_grams) * 1000;
  }

  const normalisedQuantity =
    // avert a divide by zero
    pricing_type_id === PricingTypes.PER_HEAD ? quantity || 1 : 1;

  return total_price_cents / normalisedQuantity;
};

export const calculateUnitPriceDollars = ({
  total_price_cents,
  total_mass_grams,
  pricing_type_id,
  quantity,
}) => {
  return (
    calculateUnitPriceCents({
      total_price_cents,
      total_mass_grams,
      pricing_type_id,
      quantity,
    }) / 100
  );
};

export const calculateUnitPrice = ({
  total_price_cents,
  total_mass_grams,
  pricing_type_id,
  quantity,
}) => {
  const unitPriceCents = calculateUnitPriceCents({
    total_price_cents,
    total_mass_grams,
    pricing_type_id,
    quantity,
  });
  let unitPrice = 0;

  if (
    pricing_type_id === PricingTypes.PER_HEAD ||
    pricing_type_id === PricingTypes.GROSS
  ) {
    unitPrice = unitPriceCents / 100;
  } else if (pricing_type_id === PricingTypes.PER_KILO) {
    unitPrice = unitPriceCents;
  }

  return unitPrice;
};

export const getUnitPriceString = ({
  total_price_cents,
  total_mass_grams,
  pricing_type_id,
  quantity,
}) => {
  const unitPrice = calculateUnitPriceCents({
    total_price_cents,
    total_mass_grams,
    pricing_type_id,
    quantity,
  });
  // For c/Kg, return the value in cents
  if (pricing_type_id === PricingTypes.PER_KILO) {
    return Number(unitPrice.toFixed(2)) % 1 === 0
      ? // don't show show fractions of cent when sold @ whole cent values
        unitPrice.toFixed(0)
      : // show fractions of cent when sold @ fractions of a cent
        unitPrice.toFixed(2);
  }

  // For all other pricing types return the value in dollars
  return getDollarPriceStringFromCents(unitPrice);
};

export const roundNumber = (number, places) =>
  +`${Math.round(`${number}e+${places}`)}e-${places}`;

export const calculateTotalPriceCents = ({
  unitPrice,
  total_mass_grams,
  pricing_type_id,
  quantity,
}) => {
  const unitPriceCents =
    pricing_type_id === PricingTypes.PER_KILO ? unitPrice : unitPrice * 100;

  const normalisedQuantity =
    pricing_type_id === PricingTypes.PER_HEAD ? quantity : 1;

  // When per kilo, and no mass is provided, it is assumed the pricing type is
  // actually gross.
  const totalMassGramsAsNumber = +total_mass_grams;
  if (
    pricing_type_id === PricingTypes.PER_KILO &&
    Number.isInteger(totalMassGramsAsNumber) &&
    totalMassGramsAsNumber > 0
  ) {
    return roundNumber((unitPriceCents * total_mass_grams) / 1000, 2);
  }
  return roundNumber(unitPriceCents * normalisedQuantity, 2);
};

/**
 * Returns a new object that only contains keys that exist in both objects but have a different value
 * @param {Object} obj1
 * @param {Object} obj2
 * @return {Object}
 */
export const shallowObjectChanges = (obj1, obj2) =>
  Object.keys(obj1).reduce((map, key) => {
    obj1[key] !== obj2[key] && (map[key] = obj1[key]);
    return map;
  }, {});

/**
 * Returns a new object from the input, with any values === -1 replaced with null
 * @param {Object} obj
 * @return {Object}
 */
export const neg1ToNulls = obj =>
  Object.keys(obj).reduce((map, key) => {
    map[key] = obj[key] === -1 ? null : obj[key];
    return map;
  }, {});

export const removeNulls = obj =>
  Object.keys(obj).reduce((map, key) => {
    if (obj[key] !== null) {
      map[key] = obj[key];
    }
    return map;
  }, {});

export const removeBlankString = obj =>
  Object.keys(obj).reduce((map, key) => {
    if (obj[key] !== "") {
      map[key] = obj[key];
    }
    return map;
  }, {});

/**
 * Return the string in title case for proper nouns or headings
 * @param {string} str
 * @return {string}
 */
export const toTitleCase = str =>
  str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
/**
 * @param {number} decimal between 0 and 1
 * @return {string} as a round down number
 */
export const formatPercentage = (
  decimal,
  { includeSymbol = true, decimalPlaces, roundingMethod = Math.round } = {},
) => {
  let result =
    typeof roundingMethod === "function"
      ? roundingMethod(decimal * 100)
      : decimal * 100;
  if (typeof decimalPlaces === "number") {
    result = result.toFixed(decimalPlaces);
  }
  if (includeSymbol) {
    result = `${result}%`;
  }

  return result;
};

/**
 *
 * @param {object} instance this
 * @param {object} nextProps
 * @param {object} nextState
 * @param {string} checkObjectName
 * @return {boolean}
 */
export const shallowCompareWithObjectIds = (
  instance,
  nextProps,
  nextState,
  checkObjectName,
) => {
  // if nothing's changed, don't update
  if (!shallowCompare(instance, nextProps, nextState)) {
    return false;
  }

  const checkObjectIds = instance.props[checkObjectName].map(obj => obj.id);
  const nextCheckObjectIds = nextProps[checkObjectName].map(obj => obj.id);

  const haveCheckObjectIdsChanged = !isEqual(
    checkObjectIds,
    nextCheckObjectIds,
  );

  const propsWithoutCheckObject = { ...instance.props };
  delete propsWithoutCheckObject[checkObjectName];
  const nextPropsWithoutCheckObject = { ...nextProps };
  delete nextPropsWithoutCheckObject[checkObjectName];

  const instanceWithoutCheckObject = {
    state: instance.state,
    props: propsWithoutCheckObject,
  };

  const haveOtherPropsChanged = shallowCompare(
    instanceWithoutCheckObject,
    nextPropsWithoutCheckObject,
    nextState,
  );

  if (haveOtherPropsChanged || haveCheckObjectIdsChanged) {
    return true;
  }
  return false;
};

export const flattenObjectArrays = obj =>
  Object.values(obj).reduce((acc, valueArray) => [...acc, ...valueArray], []);

export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];

function strictEqual(a, b) {
  return a === b;
}

/**
 * Returns the first item in the array if all items are equal, otherwise returns the default value.
 * @param arr the array of values to compare
 * @param defaultValue the value to return if the array is empty or not all items are equal
 * @param predicate the function to use to compare the items in the array
 * @returns {*}
 */
export const firstIfAllEqual = (arr, defaultValue, predicate = strictEqual) => {
  if (Array.isArray(arr) && arr.length < 1) {
    return defaultValue;
  }

  const firstItem = arr[0];

  for (let i = 1; i < arr.length; i++) {
    if (!predicate(firstItem, arr[i])) {
      return defaultValue;
    }
  }
  return firstItem;
};

export const getNumberWithOrdinal = n => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const convertType = value => {
  if (value === "undefined") {
    return undefined;
  }
  if (value === "null") {
    return null;
  }
  if (value === "true") {
    return true;
  }
  if (value === "false") {
    return false;
  } else {
    return value;
  }
};

export const calculateSplitPricingData = (
  saleLot,
  splitCount,
  shouldMoveWeights,
) => {
  // When splitting a a pen, we need to recalculate the total_price_cents
  // and the total_mass_grams based on the original saleLot and changed headCount.
  const currentUnitPrice = calculateUnitPrice({
    total_price_cents: saleLot.total_price_cents,
    total_mass_grams: saleLot.total_mass_grams,
    pricing_type_id: saleLot.pricing_type_id,
    quantity: saleLot.quantity,
  });
  const currentUnitWeight = saleLot.total_mass_grams
    ? saleLot.total_mass_grams / saleLot.quantity
    : 0;

  const aQuantity = saleLot.quantity - splitCount;
  const aTotalMassGrams = shouldMoveWeights
    ? Math.round(currentUnitWeight * aQuantity)
    : saleLot.total_mass_grams;
  const aTotalPriceCents = calculateTotalPriceCents({
    unitPrice: currentUnitPrice,
    quantity: aQuantity,
    pricing_type_id: saleLot.pricing_type_id,
    total_mass_grams: aTotalMassGrams,
  });

  const bQuantity = splitCount;
  const bTotalMassGrams = shouldMoveWeights
    ? saleLot.total_mass_grams - aTotalMassGrams
    : 0;
  const bTotalPriceCents = calculateTotalPriceCents({
    unitPrice: currentUnitPrice,
    quantity: bQuantity,
    pricing_type_id: saleLot.pricing_type_id,
    total_mass_grams: bTotalMassGrams,
  });

  const lotA = {
    total_mass_grams: aTotalMassGrams,
    total_price_cents: aTotalPriceCents,
    quantity: aQuantity,
  };

  const lotB = {
    total_mass_grams: bTotalMassGrams,
    total_price_cents: bTotalPriceCents,
    quantity: bQuantity,
  };

  // Put the lower headcount into the new lot so that less rescanning is required, unless we're not moving
  // weights - they should remain with the EIDs.
  if (aQuantity > bQuantity || !shouldMoveWeights) {
    return {
      splitLot: lotB,
      originalLot: lotA,
    };
  } else {
    return {
      splitLot: lotA,
      originalLot: lotB,
    };
  }
};

export const toSelectOption = option => ({
  label: option.name,
  value: option.id,
});

/**
 * Converts a weight in grams to a display friendly Kg weight. Result contains
 * thousands commas and two decimal places
 * @param {number} weightGrams the raw value to be converted for display
 * @param {boolean} includeUnits Controls whether "` kg`" is to be appended to the
 * end of the string
 * @param {boolean} showDecimals Whether to show an integer or a floating point number
 * @returns {string}
 */
export const formatWeightKg = (
  weightGrams,
  includeUnits = true,
  showDecimals = true,
) => {
  // Always ensure weight is a valid number
  const normalisedWeight =
    typeof weightGrams === "number" && !Number.isNaN(weightGrams)
      ? weightGrams / 1000
      : 0;
  const weightKgString = formatDecimal(normalisedWeight, showDecimals ? 2 : 0);
  return includeUnits ? `${weightKgString} kg` : weightKgString;
};

export const calculateAverageWeightAndFormat = saleLots => {
  // Only calculate average weight from weighed lots.
  const weighedSaleLots = saleLots.filter(s => s.totalMassGrams);
  const averageWeight =
    sumBy(weighedSaleLots, "totalMassGrams") /
    sumBy(weighedSaleLots, "quantity");
  return formatWeightKg(averageWeight);
};

export const calculateAverageWeightAndFormatFromRawSaleLots = saleLots =>
  calculateAverageWeightAndFormat(
    saleLots.map(saleLot => ({
      ...saleLot,
      totalMassGrams: saleLot.total_mass_grams,
    })),
  );

export const calculateAveragePriceAndFormat = (saleLots, pricingType) => {
  const priceType =
    pricingType || saleLots[0].pricingType || saleLots[0].pricing_type_id;

  const filteredSaleLots =
    priceType === PricingTypes.PER_KILO
      ? saleLots.filter(s => s.totalMassGrams || s.total_mass_grams)
      : saleLots;

  const totalGroupPrice = sumBy(
    filteredSaleLots,
    ({ priceCents, quantity }) => priceCents * quantity,
  );
  const totalGroupQuantity = sumBy(filteredSaleLots, "quantity");

  if (totalGroupQuantity === 0) {
    return 0;
  }
  return formatPricingTypeOutput(
    priceType,
    priceType === PricingTypes.PER_KILO
      ? (totalGroupPrice / totalGroupQuantity / 100).toFixed(2)
      : Math.round(totalGroupPrice / totalGroupQuantity / 100),
  );
};

export const calculateAveragePriceAndFormatFromRawSaleLots = (
  saleLots,
  pricingType,
) =>
  calculateAveragePriceAndFormat(
    saleLots.map(saleLot => ({
      ...saleLot,
      priceCents: calculateUnitPriceCents(saleLot),
      pricingType: saleLot.pricing_type_id,
    })),
    pricingType,
  );

export function b64ToArrayBuffer(b64String) {
  const decodedString = atob(b64String);
  const buffer = new ArrayBuffer(decodedString.length);
  const arrayView = new Uint8Array(buffer);

  for (let i = 0; i < decodedString.length; i++) {
    arrayView[i] = decodedString.charCodeAt(i);
  }
  return buffer;
}

export function extractFileNameFromURL(url) {
  const filePath = url.split(/[?|#]/)[0];
  return filePath.split("/").pop();
}

export function extractFileExtensionFromURL(url) {
  const fileName = extractFileNameFromURL(url);
  return fileName.split(".").pop();
}

export function splitName(fullName) {
  // Attempt to split a full name into first and last.
  // Puts extra name bits in the first name, puts a single name into a last name
  // Harry Kane -> Harry / Kane
  // Hueng Min Song -> Hueng Min / Song
  // Ronaldo -> Ronaldo

  const nameBits = fullName.split(" ");
  const lastName = nameBits[nameBits.length - 1];
  const firstName = nameBits.length > 1 ? nameBits.slice(0, -1).join(" ") : "";

  return {
    firstName,
    lastName,
  };
}

export const getGrossSkinPrice = objWithPricingEntries => {
  return (
    objWithPricingEntries?.pricing_entries?.find(
      p => p.pricing_type === PricingEntryTypes.TYPE_SKIN_GROSS,
    )?.price_cents || 0
  );
};

export const getSkinPrice = consignment => {
  return (
    consignment?.pricing_entries?.find(
      p => p.pricing_type === PricingEntryTypes.TYPE_SKIN_PER_HEAD,
    )?.price_cents || 0
  );
};

export function calc5NumberSummary(sortedDataList, valueGetter) {
  const actualValueGetter =
    typeof valueGetter === "function" ? valueGetter : val => val;

  const valuesList = sortedDataList.map(actualValueGetter);

  const count = sortedDataList.length;

  const median = valuesList[Math.round((count - 1) / 2)];
  const q3Index = Math.ceil((count - 1) * 0.75);
  const q1Index = Math.floor((count - 1) * 0.25);
  const lowerQuartile = valuesList[q1Index];
  const upperQuartile = valuesList[q3Index];

  const minimum = Math.min(valuesList);
  const maximum = Math.max(valuesList);

  return {
    minimum,
    lowerQuartile,
    median,
    upperQuartile,
    maximum,
  };
}

export function findOutlierParameters(
  sortedDataList,
  valueGetter = null,
  outlierFactor = 1.5,
) {
  const fiveNumSum = calc5NumberSummary(sortedDataList, valueGetter);
  const { lowerQuartile, upperQuartile } = fiveNumSum;
  const iqr = upperQuartile - lowerQuartile;

  const lowerLimit = lowerQuartile - outlierFactor * iqr;
  const upperLimit = upperQuartile + outlierFactor * iqr;

  return {
    ...fiveNumSum,
    iqr,
    upperLimit,
    lowerLimit,
  };
}

export const roundAgGridNumber = number =>
  number === 0 || typeof number === "string" ? number : Math.round(number);

export function downloadFileBlob(fileName, blob) {
  const blobUrl = window.URL.createObjectURL(blob);
  const aElement = document.createElement("a");
  aElement.href = blobUrl;
  aElement.target = "_blank";
  aElement.style.display = "none";
  // set the file name
  aElement.setAttribute("download", fileName);

  document.body.appendChild(aElement);
  aElement.click();
  document.body.removeChild(aElement);
  // avoid a memory leak
  window.URL.revokeObjectURL(blobUrl);
}

// https://stackoverflow.com/questions/9539513/is-there-a-reliable-way-in-javascript-to-obtain-the-number-of-decimal-places-of
export function decimalPlaces(n) {
  function hasFraction(n) {
    return Math.abs(Math.round(n) - n) > 1e-10;
  }

  let count = 0;
  // multiply by increasing powers of 10 until the fractional part is ~ 0
  // eslint-disable-next-line no-restricted-globals
  while (hasFraction(n * 10 ** count) && isFinite(10 ** count)) {
    count++;
  }
  return count;
}
