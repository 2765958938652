import React, { memo } from "react";

import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  addSaleyardScans,
  uploadScansAction,
  uploadUnallocatedScans,
} from "actions";

import { useHandleAfterSaveScanPen } from "components/ScanningScreen/handleNextScanPenHook";
import { ScanDiscrepancyDialog } from "components/ScanningScreen/ScanDiscrepancyDialog";
import ScanningColumn from "components/ScanningScreen/ScanningColumn";

import { getApiSale } from "lib/sale";
import { scanLotConfig } from "lib/scanLot";

import { getIsScaleOperator, getScans } from "selectors";

export const ManualInputType = {
  EID: 0,
  NLIS_ID: 1,
};

export function transformManualInputToScan(manualInput) {
  return {
    created: new Date().toISOString(),
    device_id: "manual",
    device_name: "Manual Input",
    draft_name: "",
    EID: manualInput.eid || manualInput.EID,
    NLISID: manualInput.nlisId,
    remove_from_salelot: manualInput.keep,
  };
}

function ManualInputScanningColumnComponent(props) {
  const {
    consignmentId,
    onAfterActioned,
    onClear,
    onClearSaved,
    onClearDuplicates,
    onEdit,
    onIgnore,
    onKeep,
    onCancel,
    onCreateNew,
    onCreateNewBulk,
    onSelectExisting,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    scanListType,
    isScanDiscrepancyDialogVisible,
    showScanDiscrepancyDialog,
    hideScanDiscrepancyDialog,
    eids,
    keepableEids,
    duplicateEids,
    resolvedManualInputs,
    resolvedUniqueManualInputs,
    scans,
    clearHidden,
  } = props;

  const {
    lotAction,
    getNextPenByPenIdAndArchetypeId,
    getPreviousPenByPenIdAndArchetypeId,
  } = scanLotConfig(penType, scanLotId);

  const isScaleOperator = useSelector(getIsScaleOperator);

  const scanByEidLookup = useSelector(getScans);

  const dispatch = useDispatch();

  const {
    values: scanLotValues,
    resetForm,
    initialValues,
  } = useFormikContext();

  const showEdit = isScaleOperator;
  const disallowTaken = isScaleOperator;

  const handleAfterAddScanPen = useHandleAfterSaveScanPen(
    penArchetypeId,
    penId,
    scanLotId,
    penType,
  );

  const hasNextPen = useSelector(
    getNextPenByPenIdAndArchetypeId(penId, penArchetypeId),
  );

  const hasPrevPen = useSelector(
    getPreviousPenByPenIdAndArchetypeId(penId, penArchetypeId),
  );

  const actionedManualInputs = resolvedManualInputs
    .filter(
      ({ eid, nlisId }) => eids.indexOf(eid) > -1 || eids.indexOf(nlisId) > -1,
    )
    .map(({ source }) => source);

  function onEditEid(eid) {
    // EIDs will only show edit if they were an EID Manual input, or they were and NLIS Id input which could be resolved to an EID
    onEdit(
      resolvedUniqueManualInputs.find(manualInput => manualInput.eid === eid)
        .eid,
    );
  }

  function onAddEidsToConsignment() {
    dispatch(addSaleyardScans(scans, consignmentId));

    typeof onAfterActioned === "function" &&
      onAfterActioned(actionedManualInputs);
  }

  function onAddEidsToCurrent() {
    dispatch(uploadScansAction(scans, saleLotId));
    typeof onAfterActioned === "function" &&
      onAfterActioned(actionedManualInputs);
  }

  function onAddEidsToSale() {
    dispatch(uploadUnallocatedScans(scans, getApiSale()));

    typeof onAfterActioned === "function" &&
      onAfterActioned(actionedManualInputs);
  }

  function onAddToScanLot(options) {
    // clear hidden duplicate eids
    clearHidden();
    // save new eids
    dispatch(
      lotAction.updateOrCreateWithPenAndScans(
        scanLotId,
        penId,
        penArchetypeId,
        scanLotValues,
        options?.scans || scans, // allow scans to be passed in as an argument
      ),
    );

    typeof onAfterActioned === "function" &&
      onAfterActioned(actionedManualInputs);
    handleAfterAddScanPen(options);
    resetForm({ values: initialValues });
  }

  function onIgnoreAllEids() {
    onIgnore(
      resolvedManualInputs
        .filter(manualInput => duplicateEids.indexOf(manualInput.eid) > -1)
        // Transform all of the duplicate EIDs back to their Manual entry counterparts
        .map(({ source }) => source),
    );
  }

  function onIgnoreEid(eid) {
    // Transform the ignored EID back to its Manual entry counterpart(s)
    onIgnore(
      resolvedManualInputs
        .filter(manualInput => manualInput.eid === eid)
        .map(({ source }) => source),
    );
  }

  function onKeepAllEids() {
    onKeep(
      resolvedManualInputs
        .filter(manualInput => keepableEids.indexOf(manualInput.eid) > -1)
        // Transform all of the keepable EIDs back to their Manual entry counterparts
        .map(({ source }) => source),
    );
  }

  function onKeepEid(eid) {
    // Transform the kept EID back to its Manual entry counterpart(s)
    onKeep(
      resolvedManualInputs
        // EIDs will only show keep if they were an EID Manual input, or they were and NLIS Id input which could be resolved to an EID
        .filter(manualInput => manualInput.eid === eid)
        // Map all of the keepable EIDs to their respective Manual Inputs
        .map(({ source }) => source),
    );
  }

  function onCreateNewSaleLot() {
    typeof onCreateNew === "function" &&
      onCreateNew({
        onNewSaleLotId: saleLotId => {
          if (saleLotId) {
            dispatch(uploadScansAction(scans, saleLotId));
            typeof onAfterActioned === "function" &&
              onAfterActioned(actionedManualInputs);
          }
        },
      });
  }

  function onCreateNewSaleLotsBulk() {
    typeof onCreateNewBulk === "function" &&
      onCreateNewBulk({
        eids,
        onAfterSave: actionedEids => {
          const actionedManualInputs = resolvedManualInputs
            .filter(({ eid }) => actionedEids.indexOf(eid) > -1)
            .map(({ source }) => source);

          typeof onAfterActioned === "function" &&
            onAfterActioned(actionedManualInputs);
        },
      });
  }

  function onSelectExistingSaleLot() {
    typeof onSelectExisting === "function" &&
      onSelectExisting({
        onNewSaleLotId: saleLotId => {
          if (saleLotId) {
            dispatch(uploadScansAction(scans, saleLotId));
            typeof onAfterActioned === "function" &&
              onAfterActioned(actionedManualInputs);
          }
        },
      });
  }

  return (
    <>
      <ScanningColumn
        onCancel={onCancel}
        consignmentId={consignmentId}
        draftName="Manual Entry"
        disallowTaken={disallowTaken}
        duplicateEidsOrNlisIds={duplicateEids}
        eidsOrNlisIds={eids}
        isExpanded
        isFooterDisabled={eids.length === 0}
        isSaleLotSelected={Boolean(saleLotId)}
        onClear={onClear}
        onClearSaved={onClearSaved}
        onClearDuplicates={onClearDuplicates}
        onEdit={onEditEid}
        onIgnore={onIgnoreEid}
        onIgnoreAll={onIgnoreAllEids}
        onKeep={onKeepEid}
        onKeepAll={onKeepAllEids}
        onAddToConsignment={onAddEidsToConsignment}
        onAddToCurrent={onAddEidsToCurrent}
        onAddToSale={onAddEidsToSale}
        onAddToScanLot={onAddToScanLot}
        onCreateNew={onCreateNewSaleLot}
        onCreateNewBulk={onCreateNewSaleLotsBulk}
        onSelectExisting={onSelectExistingSaleLot}
        showEdit={showEdit}
        showKeepAll={keepableEids.length > 1}
        scanListType={scanListType}
        hasNextPen={hasNextPen}
        hasPrevPen={hasPrevPen}
        penType={penType}
        scanLotId={scanLotId}
        penId={penId}
        penArchetypeId={penArchetypeId}
        showScanDiscrepancyDialog={showScanDiscrepancyDialog}
      />
      <ScanDiscrepancyDialog
        isOpen={isScanDiscrepancyDialogVisible}
        onClose={hideScanDiscrepancyDialog}
        duplicateScans={duplicateEids.map(duplicateEid =>
          transformManualInputToScan(scanByEidLookup[duplicateEid]),
        )}
        eids={eids}
        scans={scans}
        penType={penType}
        scanLotId={scanLotId}
        penId={penId}
        penArchetypeId={penArchetypeId}
        scanLotValues={scanLotValues}
        onClear={onClear}
        onClearDuplicates={onClearDuplicates}
        onAddToScanLot={onAddToScanLot}
      />
    </>
  );
}

export default memo(ManualInputScanningColumnComponent);
