import React, { useEffect, useMemo } from "react";

import { Paper, Tab, Tabs } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { UserRoleSettings } from "components/UserRoles";

import { ApiModel } from "constants/loading";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import { Billing } from "containers/Settings/Billing";
import EditDeployment from "containers/Settings/Deployment";
import { NLISPanel } from "containers/Settings/NLIS";
import SystemSettings from "containers/Settings/System";

import { getSettingsRoute } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import {
  getActiveSaleyardAdminSaleyard,
  getIsRolePermissionsEnabled,
  getLivestockAgentHasNlisSaleyard,
  selectRoleDeployments,
} from "selectors";

import history from "appHistory";
import { useGetSelectedTabFromRoute } from "hooks/useGetSelectedTabFromRoute";
import {
  useHasDeploymentPermission,
  useHasSaleyardOrDeploymentPermission,
  useSomeHasPermission,
} from "hooks/useHasPermission";

import { AgeSettings } from "./Ages";
import { BreedSettings } from "./Breeds";
import { DentitionSettings } from "./Dentition";
import { IntegrationSettings } from "./Integrations";
import { LabelSettings } from "./Labels";
import { MarkSettings } from "./Marks";
import { ProductSettings } from "./Products";
import { RuleBooks } from "./RuleBooks";
import { SexSettings } from "./Sexes";

const Settings = () => {
  const hasNlisPermission = useSelector(state =>
    getIsRolePermissionsEnabled(state)
      ? getLivestockAgentHasNlisSaleyard(state) ||
        hasPermission(
          getActiveSaleyardAdminSaleyard(state),
          SaleyardPermissions.featureNlis,
        )
      : true,
  );

  const hasBreedsPermission = useSomeHasPermission(
    selectRoleDeployments,
    DeploymentPermissions.featureBreeds,
  );

  const hasSexesPermission = useSomeHasPermission(
    selectRoleDeployments,
    DeploymentPermissions.featureSexes,
  );

  const hasAgesPermission = useSomeHasPermission(
    selectRoleDeployments,
    DeploymentPermissions.featureAges,
  );

  const hasProductsPermission = useSomeHasPermission(
    selectRoleDeployments,
    DeploymentPermissions.featureProducts,
  );

  const hasLabelsPermission = useSomeHasPermission(
    selectRoleDeployments,
    DeploymentPermissions.featureLabels,
  );

  const hasMarksPermission = useSomeHasPermission(
    selectRoleDeployments,
    DeploymentPermissions.featureMarks,
  );

  const hasUserManagementPermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureUserManagement,
    SaleyardPermissions.featureUserManagement,
  );

  const hasExternalIntegrationPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureExternalIntegrations,
  );

  const hasRuleBookPermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureRuleBook,
    SaleyardPermissions.featureRuleBook,
  );

  const hasDeploymentUpdatePermission = useHasDeploymentPermission(
    DeploymentPermissions.featureEditDeployment,
  );

  const hasDentitionPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureDentition,
  );

  const settingsOptions = useMemo(
    () =>
      [
        hasNlisPermission && {
          label: "NLIS",
          component: NLISPanel,
        },
        hasBreedsPermission && {
          label: "Breeds",
          component: BreedSettings,
        },
        hasSexesPermission && {
          label: "Sexes",
          component: SexSettings,
        },
        hasAgesPermission && {
          label: "Ages",
          component: AgeSettings,
        },
        hasProductsPermission && {
          label: "Products",
          component: ProductSettings,
        },
        hasLabelsPermission && {
          label: "Labels",
          component: LabelSettings,
        },
        hasMarksPermission && {
          label: "Marks",
          component: MarkSettings,
        },
        hasUserManagementPermission && {
          label: "Users",
          component: UserRoleSettings,
        },
        hasExternalIntegrationPermission && {
          label: "Integrations",
          component: IntegrationSettings,
        },
        hasRuleBookPermission && {
          label: "Billing Rules",
          component: RuleBooks,
        },
        hasRuleBookPermission && {
          label: "Billing",
          component: Billing,
        },
        hasDeploymentUpdatePermission && {
          label: "Agency",
          component: EditDeployment,
        },
        hasDentitionPermission && {
          label: "Dentition",
          component: DentitionSettings,
        },
        {
          label: "System",
          component: SystemSettings,
        },
      ].filter(Boolean),
    [
      hasAgesPermission,
      hasBreedsPermission,
      hasDentitionPermission,
      hasDeploymentUpdatePermission,
      hasExternalIntegrationPermission,
      hasLabelsPermission,
      hasMarksPermission,
      hasNlisPermission,
      hasProductsPermission,
      hasRuleBookPermission,
      hasSexesPermission,
      hasUserManagementPermission,
    ],
  );
  const match = useRouteMatch();
  const tabMap = ({ label }) => (
    <Tab
      label={label}
      key={`tab-${label}`}
      value={label}
      data-tour={label}
      component={Link}
      to={`${match.path}${label}/`}
    />
  );

  const currentTab = useGetSelectedTabFromRoute(settingsOptions);
  const routeMap = ({ label, component }) => (
    <Route path={`${match.path}${label}/`} component={component} key={label} />
  );

  useEffect(() => {
    // If we aren't on a tab, open the first.
    if (!currentTab && settingsOptions[0]?.label) {
      history.push(getSettingsRoute(settingsOptions[0].label));
    }
  }, [currentTab, match.path, settingsOptions]);

  return (
    <>
      <Paper>
        <Tabs value={currentTab} variant="scrollable">
          {settingsOptions.map(tabMap)}
        </Tabs>
      </Paper>

      <Switch>{settingsOptions.map(routeMap)}</Switch>
    </>
  );
};

const SettingsLoadingWrapper = () => (
  <WaitForSync requiredData={[ApiModel.AGENCIES, ApiModel.DEPLOYMENTS]}>
    <Settings />
  </WaitForSync>
);

export default SettingsLoadingWrapper;
