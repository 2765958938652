import React from "react";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import {
  ConfirmDialog,
  createModalTitle,
  MESSAGE_CANT_BE_UNDONE,
} from "components/ConfirmDialog";

import { scanLotConfig } from "lib/scanLot";

export const ScanLotDeleteDialog = ({
  penType,
  isOpen,
  onCancel,
  onDelete,
  scanCount,
}) => {
  const { lotTitle } = scanLotConfig(penType);

  return (
    <ConfirmDialog
      title={createModalTitle(`this ${lotTitle}`)}
      isOpen={isOpen}
      messageIcon={scanCount > 0 ? faExclamationTriangle : null}
      message={
        scanCount > 0 ? (
          <>
            This {lotTitle} contains Scans. These scans will remain in the sale,
            but no longer be associated with a {lotTitle}. Are you sure you want
            to delete it? {MESSAGE_CANT_BE_UNDONE}
          </>
        ) : (
          MESSAGE_CANT_BE_UNDONE
        )
      }
      onCancel={onCancel}
      onDelete={onDelete}
    />
  );
};
