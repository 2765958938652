import React, { memo, useCallback } from "react";

import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge as MaterialBadge } from "@material-ui/core";
import { uniqBy } from "lodash/array";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { BusinessModalSection } from "constants/navigation";

import { getSaleyardName, openEditBusinessModal } from "lib/navigation";

import { getBusinessById } from "selectors";

const PaddedBadge = styled(MaterialBadge)`
  /* Same as the default \`word-spacing\` to allow embedding inline in text */
  margin: 0.25em;
  z-index: 0;
`;

const EmailRecipientCount = React.memo(({ recipientCount, onClick }) => {
  const color = recipientCount ? "primary" : "error";
  return (
    <PaddedBadge
      data-tour="emailRecipientBadge"
      badgeContent={recipientCount}
      showZero
      color={color}
    >
      <FontAwesomeIcon icon={faEnvelope} size="2x" onClick={onClick} />
    </PaddedBadge>
  );
});

export const MasterBusinessEmailRecipientCount = memo(props => {
  const { masterBusinessId, saleyardName = getSaleyardName() } = props;

  const recipientCount = useSelector(state => {
    const primaryDeploymentBusiness =
      getBusinessById(masterBusinessId)(state) || {};
    const supplementaryIds = primaryDeploymentBusiness.supplementary_ids || [];

    const emailRecipients = primaryDeploymentBusiness.emailRecipients || [];
    const businessUsers = primaryDeploymentBusiness.businessUsers || [];

    const saleyardBusinessUsers = businessUsers.filter(businessUser =>
      businessUser.saleYards.includes(saleyardName),
    );

    return uniqBy(
      supplementaryIds.reduce(
        (acc, deploymentBusinessId) =>
          acc.concat(
            getBusinessById(deploymentBusinessId)(state).emailRecipients || [],
          ),
        [].concat(emailRecipients, saleyardBusinessUsers),
      ),
      "email",
    ).length;
  });

  const onClickRecipientCount = useCallback(
    () =>
      openEditBusinessModal(
        masterBusinessId,
        null,
        BusinessModalSection.EMAIL_RECIPIENTS,
      ),
    [masterBusinessId],
  );

  return (
    <EmailRecipientCount
      onClick={onClickRecipientCount}
      recipientCount={recipientCount}
    />
  );
});
