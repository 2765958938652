import React from "react";

import { Tooltip } from "components/Form/FormikControls/Tooltip";

import { formatDate, formatTime, getTimeSince } from "lib/timeFormats";

export const TimeSince = ({
  utcDateTimeStr,
  tooltipPlacement = "top",
  addSuffix = true,
}) => {
  return (
    <Tooltip
      title={`${formatDate(utcDateTimeStr)} ${formatTime(utcDateTimeStr)}`}
      placement={tooltipPlacement}
    >
      {getTimeSince(utcDateTimeStr, addSuffix)}
    </Tooltip>
  );
};
