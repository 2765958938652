import React from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { Button, SecondaryButton, DeleteButton } from "components/Form";
import { Row } from "components/Layout";

/*
  This is for create + edit
  To switch modes, a handleDelete function must be passed into the component
*/
const BobbyCalfSaleLotForm = ({ handleClose, isEdit, handleDeleteClick }) => {
  const formikProps = useFormikContext();
  const { isValid, handleSubmit } = formikProps;

  return (
    <>
      <Row>
        <StateAwareBusinessPICSelector
          label="Buyer"
          businessFieldName="buyer"
          propertyFieldName="buyerPIC"
          formikProps={formikProps}
          error={formikProps.touched.buyer && formikProps.errors.buyer}
        />
      </Row>

      <ButtonWrapper>
        {isEdit ? (
          <DeleteButton data-tour="delete" onClick={handleDeleteClick}>
            Delete
          </DeleteButton>
        ) : (
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        )}
        <ButtonStyled
          data-tour="save&Close"
          type="submit"
          onClick={handleSubmit}
          disabled={!isValid}
        >
          Save & Close
        </ButtonStyled>
      </ButtonWrapper>
    </>
  );
};

BobbyCalfSaleLotForm.propTypes = {
  handleClose: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default BobbyCalfSaleLotForm;

const ButtonWrapper = styled(Row)`
  margin: 40px 10px 0;
  bottom: 0;
`;

const ButtonStyled = styled(Button)`
  margin-left: ${({ theme }) => theme.space[2]}px;
`;
