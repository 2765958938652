import {
  UPDATE_DECLARATION,
  UPDATE_DECLARATION_SUCCESS,
  UPDATE_DECLARATION_FAILURE,
  SET_ADDITIONAL_PICS,
  SET_ADDITIONAL_PICS_SUCCESS,
} from "constants/actionTypes";

export function updateDeclaration(consignmentId, declaration) {
  return {
    type: UPDATE_DECLARATION,
    consignmentId,
    declaration,
  };
}

export function updateDeclarationSuccess(consignmentId, answers) {
  return {
    type: UPDATE_DECLARATION_SUCCESS,
    consignmentId,
    answers,
  };
}

export function updateDeclarationError() {
  return {
    type: UPDATE_DECLARATION_FAILURE,
  };
}

export function setAdditionalPICs(consignmentId, pics) {
  return {
    type: SET_ADDITIONAL_PICS,
    consignmentId,
    pics,
  };
}

export function setAdditionalPICsSuccess(consignmentId, pics) {
  return {
    type: SET_ADDITIONAL_PICS_SUCCESS,
    consignmentId,
    pics,
  };
}
