import React from "react";

import ScanToPenModal from "components/ScanToPenModal";

function ScanToPenModalAdapter(props) {
  const { hash } = props;
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return <ScanToPenModal returnTo={decodedReturnTo} />;
}

export default ScanToPenModalAdapter;
