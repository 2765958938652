import React from "react";

import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { checkWatcherEntry } from "actions";

import ErrorMessage from "components/ErrorMessage";
import { Button } from "components/Form";
import { Input } from "components/Form/FormikControls";
import { CenteredGreedy } from "components/Layout";
import { LoginCard } from "components/Layout/Login";
import LoadingSpinner from "components/LoadingSpinner";

import { WATCHER_STATES } from "constants/watcher";

import {
  dateTimeStringToDateTime,
  formatLongDateString,
} from "lib/timeFormats";

import { getAuthentication } from "selectors";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required.")
    .email("Must be a valid email address"),
});

const LoginButton = styled(Button)`
  width: 100%;
  margin: 0;
`;

const LargeErrorMessage = styled(ErrorMessage)`
  visibility: visible;
  font-size: ${({ theme }) => theme.fontSizes.charlie}px;
`;

const CenteredGrid = styled(Grid)`
  text-align: center;
`;

const WatcherEntry = ({ match }) => {
  const { livestocksale_id, verification } = match.params;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(checkWatcherEntry(livestocksale_id, verification));
  }, [dispatch, livestocksale_id, verification]);

  const { watcherFetching, watcherStatus, watcherErrorMessage, watcherSale } =
    useSelector(getAuthentication);

  const handleSubmit = values => {
    dispatch(checkWatcherEntry(livestocksale_id, verification, values.email));
  };

  const loginLink = `/login?returnTo=/watch/${livestocksale_id}/${verification}/`;

  return (
    <LoginCard title="Watch an AgriNous Sale">
      {watcherFetching || !watcherStatus ? (
        <CenteredGreedy>
          <LoadingSpinner subjectName="Sale" />
        </CenteredGreedy>
      ) : (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
        >
          {watcherStatus === WATCHER_STATES.SALE_NOT_FOUND ? (
            <Grid item xs={12}>
              <LargeErrorMessage>
                That sale could not be found. Please check the link and try
                again.
              </LargeErrorMessage>
            </Grid>
          ) : (
            <>
              <CenteredGrid item xs={12}>
                <h1>
                  {watcherSale?.saleyard_name}
                  {" - "}
                  {watcherSale?.date &&
                    formatLongDateString(
                      dateTimeStringToDateTime(watcherSale?.date),
                    )}
                </h1>
                <h4>
                  Enter your email below to watch the live sale.
                  <br />
                  Already an AgriNous user?{" "}
                  <Link to={loginLink}>Login Here</Link>
                </h4>
              </CenteredGrid>
              {watcherStatus === WATCHER_STATES.ERROR && (
                <CenteredGrid item xs={12}>
                  <LargeErrorMessage visible>
                    Error:{" "}
                    {watcherErrorMessage ||
                      "There was an unknown error.  Please refresh and try again."}
                  </LargeErrorMessage>
                </CenteredGrid>
              )}
              {watcherStatus === WATCHER_STATES.EMAIL_EXISTS && (
                <CenteredGrid item xs={12}>
                  <LargeErrorMessage visible>
                    That email is registered to an active Agrinous user.
                    <Link to={loginLink}>Please login here</Link>
                  </LargeErrorMessage>
                </CenteredGrid>
              )}
              {watcherStatus === WATCHER_STATES.INVALID_EMAIL && (
                <CenteredGrid item xs={12}>
                  <LargeErrorMessage visible>
                    That email is invalid. Please try again.
                  </LargeErrorMessage>
                </CenteredGrid>
              )}
              <Grid item xs={12}>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({ dirty, isValid, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Input label="Email Address" name="email" />
                        </Grid>
                        <Grid item xs={12}>
                          <LoginButton
                            type="submit"
                            disabled={!(dirty && isValid)}
                          >
                            Login
                          </LoginButton>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </LoginCard>
  );
};

export default WatcherEntry;
