import React from "react";

import { useTheme } from "hooks";

export const FrontGateOpenBackGateOpen = ({ color, size }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.black;
  const svgSize = size || theme.fontSizes.gamma;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox="0 0 27500 27500"
    >
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="451.4"
        y1="9915.3"
        x2="6371.6"
        y2="17695.3"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21131.5"
        y1="10068.3"
        x2="21131.5"
        y2="6378.4"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6371.6"
        y1="21138.3"
        x2="6371.6"
        y2="17695.3"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13751.6"
        y1="21138.3"
        x2="21131.5"
        y2="21138.3"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13751.6"
        y1="6378.4"
        x2="21131.5"
        y2="6378.4"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6371.6"
        y1="21138.3"
        x2="13751.6"
        y2="21138.3"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6371.6"
        y1="6378.4"
        x2="13751.6"
        y2="6378.4"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6371.6"
        cy="17695.3"
        r="332.1"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6371.6"
        cy="6378.4"
        r="332.1"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6371.6"
        cy="21138.3"
        r="332.1"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13751.6"
        cy="21138.3"
        r="332.1"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13751.6"
        cy="6378.4"
        r="332.1"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21131.5"
        cy="10068.3"
        r="332.1"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21131.5"
        cy="21138.3"
        r="332.1"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21131.5"
        cy="6378.4"
        r="332.1"
      />
      <path
        stroke={svgColor}
        fill={svgColor}
        d="M21686.7 17922.2c412.8-55.7 698.3 88.6 1109.9-24.6l-2341.5-2597.6 408.7-391.2 2357 2606.1c150.7-416 21.2-702.6 145.5-1077.4l467.9-12.9c83.9 264.6-32.5 1772.7-66.6 2112.9-295 32.4-1868.7-26.5-2132.4-150l51.6-465.3z"
      />
      <path
        stroke={svgColor}
        fill={svgColor}
        d="M4930.1 12100.2c412.8-55.7 698.3 88.6 1109.9-24.6l-2341.5-2597.6 408.7-391.2 2357 2606.1c150.7-416 21.2-702.5 145.5-1077.3l467.9-12.9c83.9 264.6-32.5 1772.7-66.6 2112.9-295 32.3-1868.7-26.5-2132.4-150l51.6-465.3z"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="27032"
        y1="17726.9"
        x2="21111.8"
        y2="9946.8"
      />
    </svg>
  );
};

export default FrontGateOpenBackGateOpen;
