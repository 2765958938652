import React from "react";

import Badge from "components/Badge";

import { formatDecimal } from "lib";

export function diffRenderer(props) {
  const { value, valueFormatted, data } = props;
  if (!data) {
    return null;
  }
  if (value !== 0) {
    return (
      <Badge inline color="warning">
        {valueFormatted}
      </Badge>
    );
  } else {
    return <>{valueFormatted}</>;
  }
}

export function diffFormatter(params) {
  if (!params.data) {
    return null;
  }
  if (params.value > 0) {
    return `+${params.value}`;
  } else if (params.value < 0) {
    return `${params.value}`;
  } else {
    return "-";
  }
}

export function kilogramDiffFormatter(params) {
  const { value, data } = params;
  if (!data) {
    return null;
  }

  const formattedValue = formatDecimal(Number(value) / 1000, 2);

  if (value > 0) {
    return `+${formattedValue}`;
  } else if (value < 0) {
    return `${formattedValue}`;
  } else {
    return "-";
  }
}
