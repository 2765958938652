import styled from "styled-components/macro";

export const Card = styled.div`
  box-shadow: ${({ theme }) => theme.shadows[1]};
  border: 1px solid ${({ theme }) => theme.colors.gray78};
  margin: ${({ theme }) => theme.space[2]}px;
`;

export const SectionContainer = styled(Card)`
  flex: 1;
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;
