import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { Button } from "components/Form";
import { Column, Row } from "components/Layout";

export const Wrapper = styled(Column).attrs({ flexGrow: true })`
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  height: calc(100vh - 48px);
  left: 0;
  right: 0;
`;
export const Container = styled(Column)`
  padding: ${({ theme }) => theme.space[2]}px;
`;
export const TotalTextContainer = styled.div`
  margin: ${({ theme }) => theme.space[2]}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: center;
`;
export const ButtonContainer = styled(Row)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray78};
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[3]}px;
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  ${({ theme, showMargin }) =>
    showMargin && `margin-left: ${theme.space[3]}px;`}
`;

export const DraftColumn = styled(Column)(
  ({ theme }) => `
  transition: all 250ms ease-out;
  overflow: hidden;
  border-left: 1px solid ${theme.colors.controlBorder};
  min-width: 80px;
`,
);

export const ScanList = styled.div(
  ({ theme, marginBottom }) => `
  min-height: 45px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  background-color: ${theme.colors.controlBackground};
  margin-bottom: ${marginBottom || 0}px
`,
);

export const ScanListInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const EmptyStateText = styled.div`
  text-align: center;
  margin-top: 48px;
  font-size: 18px;
  opacity: 0.33;
`;

export const Title = styled.h2`
  font-size: 36px;
  font-weight: 100;
  margin: 24px 12px;
`;

export const ExplanationText = styled.div`
  font-size: 12px;
  text-align: center;
  margin: 60px 30px;
  max-width: 550px;
`;

export const FaIcon = styled(FontAwesomeIcon)(
  ({ theme, color }) => `
  margin-right: 9px;
  color: ${theme.colors[color]};
`,
);
