export const IdType = {
  STRING: "String",
  INTEGER: "Integer",
  UUID: "UUID",
};

export const PhoneType = {
  OTHER: "Other",
  PRIMARY: "Primary",
  MOBILE: "Mobile",
  WORK: "Work",
  OFFICE: "Office",
  FAX: "Fax",
};

export const AddressType = {
  OTHER: "Other",
  PRIMARY: "Primary",
  POST_BOX: "PostBox",
  POSTAL: "Postal",
  RESIDENTIAL: "Residential",
  OFFICE: "Office",
};

export const NameType = {
  NAME: "Name",
  FIRST: "First",
  LAST: "Last",
};
