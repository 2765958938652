import { SET_SALEYARD, SALEYARD } from "constants/actionTypes";

import { serializeSaleyard } from "lib/serializers/saleyard";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

export function setSaleyard(saleyard) {
  return {
    type: SET_SALEYARD,
    saleyard,
  };
}

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/saleyards/", id, action);

export const SaleyardAction = offlineActionCreator(
  SALEYARD,
  serializeSaleyard,
  urlCreator,
  false,
);
