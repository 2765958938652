import React from "react";

import { useSelector } from "react-redux";

import { ReadOnly } from "components/ReadOnly";
import { DefaultTab } from "components/TabSelector/TabRow";

import { getIsRolePermissionsEnabled } from "selectors";

export const SecurityTrimmedNavigationTab = props => {
  const { hasPermissionSelector, title, ...tabProps } = props;
  const hasPermission = useSelector(state =>
    // Not all Roles user permissions yet. Only perform permission tests for Roles with the permissions machinery enabled on them
    getIsRolePermissionsEnabled(state) ? hasPermissionSelector(state) : true,
  );
  if (!hasPermission) {
    return null;
  }

  const tabText = (
    <>
      <ReadOnly readOnly={!hasPermission} showLock /> {title}
    </>
  );

  return <DefaultTab {...tabProps} disabled={!hasPermission} title={tabText} />;
};
