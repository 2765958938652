import styled from "styled-components/macro";

export const ClickableColumn = styled.div`
  ${({ theme }) => `
cursor: pointer;
color: ${theme.colors.primary};
svg {
  margin-left: ${theme.space[1]}px;
  display: none;
}
&:hover {
  text-decoration: underline;
  svg {
    display: inline-block;
  }
}`}
`;

export const IconClickableColumn = styled(ClickableColumn)`
  svg {
    display: block;
    width: 25px;
  }
  height: 25px;
  display: flex;
  align-items: center;
`;
