import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

export const addressSerializeMap = {
  addressingInformation: "addressing_information",
  country: "country",
  googleMapsPlaceId: "google_maps_place_id",
  isPrefillAddress: "is_prefill_address",
  latitude: "latitude",
  locality: "locality",
  longitude: "longitude",
  postalCode: "postal_code",
  route: "route",
  streetNumber: "street_number",
  state: "state",
};

const DEFAULT_ADDRESS = {
  addressingInformation: "",
  country: "Australia",
  googleMapsPlaceId: "",
  isPrefillAddress: false,
  latitude: null,
  locality: "",
  longitude: null,
  postalCode: "",
  route: "",
  streetNumber: "",
  state: "",
};

export const deserializeAddress = buildDeserializer(
  addressSerializeMap,
  "address",
  null,
  DEFAULT_ADDRESS,
);

export const serializeAddress = buildSerializer(
  addressSerializeMap,
  "address",
  null,
);
