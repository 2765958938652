import React from "react";

import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Clickable from "components/Button/Clickable";

const Key = styled(Clickable)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme, small }) =>
    small ? theme.fontSizes.epsilon : theme.fontSizes.eta}px;
  font-weight: ${({ theme }) => theme.fontWeights.thin};
  line-height: 0;
  min-width: ${({ theme }) => theme.space[7]}px;
  min-height: ${({ theme }) => theme.space[6]}px;
  background-color: #fff;
  transition: all ease-out 200ms;
  user-select: none;
  &:active {
    box-shadow: inset 0px 0px 3px 0px rgba(200, 200, 200, 0.59);
    background: rgba(0, 133, 255, 0.1);
    color: black;
  }
  ${({ disabled }) => disabled && "opacity: 0.33; cursor: default;"};
`;

const KeyPadWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(4, ${({ theme }) => theme.space[6]}px);
  grid-template-columns: repeat(3, ${({ theme }) => theme.space[7]}px);
  grid-gap: 1px;
`;

const NumberPad = ({ handleNumPress, handleDeletePress, disabled }) => {
  const numbers = "123456789.0".split("").map(char => (
    <Key
      disabled={disabled}
      key={char}
      onClick={() => !disabled && handleNumPress(char)}
      data-tour={`numpad:${char}`}
    >
      {char}
    </Key>
  ));
  const backKey = (
    <Key
      disabled={disabled}
      small
      key="delete"
      onClick={e => !disabled && handleDeletePress(e)}
      data-testid="numpad:delete"
      data-tour="numpad:delete"
    >
      <FontAwesomeIcon icon={faLongArrowAltLeft} />
    </Key>
  );
  return <KeyPadWrapper>{[...numbers, backKey]}</KeyPadWrapper>;
};

NumberPad.propTypes = {
  handleNumPress: PropTypes.func.isRequired,
  handleDeletePress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(NumberPad);
