import React from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { ModalBg, ModalHeader } from "components/Modal";

import { neg1ToNulls, removeNulls } from "lib";

import { getAuctionPenPayload } from "lib/auctionPens";

import { getSpeciesById } from "selectors";

import { SaleLotForm } from ".";
import { SaleLotValidationSchema } from "./SaleLotValidationSchema";

const styles = {
  backgroundModal: {
    backgroundColor: "rgba(102,102,102,0.5)",
  },
  contentModal: {
    height: "100%",
    width: "100%",
    padding: "12px",
    backgroundColor: "#fff",
    overflowY: "auto",
  },
};

const SaleLotModal = ({
  selectedSale,
  consignments,
  saleLotToEdit,
  saleLots,
  nextAvailableAuctionPen,
  addAuctionPen,
  patchSaleLot,
  addSaleLot,
  deleteSaleLot,
  closeSelf,
  openConfirmModal,
  closeConfirmModal,
  scans,
  auctionPen,
  auctionPens,
  saleRoundId,
}) => {
  const isEdit = !!saleLotToEdit;
  const initialValues = isEdit
    ? {
        buyer: saleLotToEdit.buyer.id,
        buyerPIC: saleLotToEdit.destinationProperty
          ? saleLotToEdit.destinationProperty.id
          : "",
      }
    : {
        buyer: selectedSale.default_buyer_id,
        buyerPIC: selectedSale.default_property_id,
      };

  const { defaultPricingType } = useSelector(
    getSpeciesById(selectedSale.species_id),
  );

  const getOrCreateAuctionPen = (buyerId, buyer_property_id) => {
    const saleLot = saleLots.find(
      l =>
        l.buyer_id === buyerId &&
        l.destination_property_id === buyer_property_id,
    );

    if (saleLot && saleLot.auction_pen && saleLot.auction_pen.id) {
      return saleLot.auction_pen.id;
    } else {
      const newAuctionPenId = uuidv4();

      const values = {
        start_pen_prefix: "",
        start_pen: nextAvailableAuctionPen,
        start_pen_suffix: "",
        end_pen: nextAvailableAuctionPen,
        end_pen_suffix: "",
        sale_round_id: saleRoundId,
      };
      const auctionPenPayload = getAuctionPenPayload(values);
      addAuctionPen(auctionPenPayload, newAuctionPenId, [], true);

      return newAuctionPenId;
    }
  };

  const handleSubmit = (values, { setErrors, setSubmitting }) => {
    // Make sure we don't already have a sale lot with those details.
    const existingSaleLot = saleLots.find(
      l =>
        l.buyer_id === values.buyer &&
        l.destination_property_id === values.buyerPIC &&
        (!isEdit || l.id !== saleLotToEdit.id),
    );
    if (existingSaleLot) {
      setErrors({
        buyer:
          "A sale lot already exists for that Buyer/Buyer PIC combination.",
      });
      setSubmitting(false);
      return;
    }

    // A BC sale lot is functionally a combination of buyer/buyer PIC,
    // so when creating or editing we see if there is an existing entry for
    // that combo.
    // If not, create it.
    // If so, raise error...

    // TODO - handle this very similarly for an edit animal.
    // ie - if we're setting a buyer/pic that doesn't exist for the consignment
    // the selected animal is in, we need to create it.
    const auctionPenId = getOrCreateAuctionPen(
      values.buyer,
      values.destination_property,
    );
    if (isEdit) {
      const patch = {
        buyer_id: values.buyer,
        destination_property_id: values.buyerPIC,
        auction_pen_id: auctionPenId,
      };
      for (const saleLot of auctionPen.saleLots) {
        patchSaleLot({ id: saleLot.id, ...patch });
      }
    } else {
      // Create a sale lot for the first consignment.
      const saleLotId = uuidv4();
      const saleLotPayload = {
        created: new Date().toUTCString(),
        id: saleLotId,
        pricing_type_id: defaultPricingType,
        livestocksale_id: selectedSale.livestocksale_id,
        buyer_id: values.buyer,
        destination_property_id: values.buyerPIC,
        auction_pen_id: auctionPenId,
        consignment_id: consignments[0].id, // TODO - this needs to check if it's taken/exists/etc.
        sale_round_id: saleRoundId,
        quantity: 0,
      };
      addSaleLot(removeNulls(neg1ToNulls(saleLotPayload)), saleLotId);
    }
    closeSelf();
  };

  // check each and every sale lot in an auction pen has scans in them
  // disabled deletion if one or more sale lots have scans in them
  const getSaleLotsWithScans = () => {
    const saleLotsWithScans = [];

    for (const saleLot of auctionPen.saleLots) {
      const saleLotScans = scans[saleLot.id] || [];

      if (saleLotScans.length > 0) {
        saleLotsWithScans.push(saleLot);
      }
    }

    return saleLotsWithScans;
  };

  // delete sale lot and its related resources
  const deleteAuctionPen = auctionPen => {
    // foreach sale lot in auction pen
    auctionPen.saleLots.forEach(saleLot => {
      // if consignment has other sale lots
      const countOtherSaleLotsInSameConsignment = consignments
        .find(consignment => consignment.id === saleLot.consignmentId)
        .saleLots.filter(
          currentSaleLot => currentSaleLot.id !== saleLot.id,
        ).length;

      // we cannot delete the last sale lot in a consignment, as there would be nowhere for scans to go
      // so check if this is the last one, and create a new one with the default buyer if necessary
      if (countOtherSaleLotsInSameConsignment === 0) {
        // find the first untaken auction pen that is not the current one
        const newDefaultAuctionPenForConsignment = auctionPens.find(
          currentAuctionPen =>
            !currentAuctionPen.sellFile &&
            auctionPen.auctionPenId !== currentAuctionPen.auctionPenId,
        );
        const templateSaleLot = newDefaultAuctionPenForConsignment.saleLots[0];
        const saleLotId = uuidv4();
        const saleLotPayload = {
          created: new Date().toUTCString(),
          id: saleLotId,
          pricing_type_id: defaultPricingType,
          livestocksale_id: selectedSale.livestocksale_id,
          buyer_id: templateSaleLot.buyerId,
          destination_property_id: templateSaleLot.destinationPropertyId,
          auction_pen_id: templateSaleLot.auctionPenId,
          consignment_id: saleLot.consignmentId,
          sale_round_id: saleRoundId,
          quantity: 0,
        };
        addSaleLot(removeNulls(neg1ToNulls(saleLotPayload)), saleLotId);
      }

      // delete these sale lots
      deleteSaleLot(saleLot);
    });
  };

  const handleDeleteClick = () => {
    const otherUntakenAuctionPens = auctionPens.filter(
      currentAuctionPen =>
        !currentAuctionPen.sellStatus &&
        currentAuctionPen.auctionPenId !== auctionPen.auctionPenId,
    );
    // check that we don't have a sell file
    if (auctionPen && auctionPen.sellFile) {
      return openConfirmModal({
        title: "Cannot Delete Sale Lot",
        message:
          "Animal(s) within the sale lot have been transferred on the NLIS. You cannot delete this sale lot.",
      });
    }
    // check that we don't have associated scans
    else if (getSaleLotsWithScans().length > 0) {
      return openConfirmModal({
        title: "Cannot Delete Sale Lot",
        message:
          "This sale lot contains scanned animals. Reallocate all animals to another sale lot or unallocate them before attempting to delete this sale lot.",
      });
    }
    // check that this isn't the last auction pen
    else if (otherUntakenAuctionPens.length === 0) {
      return openConfirmModal({
        title: "Cannot Delete Sale Lot",
        message: "You cannot delete the last sale lot on a sale day. ",
      });
    }
    // We have passed all of the criteria which could prevent an auction pen's deletion
    else {
      return openConfirmModal({
        title: "Are you sure?",
        message: "Are you sure you want to delete this sale lot?",
        actions: [
          {
            label: "No",
            secondary: true,
            onClick: () => {
              closeConfirmModal();
            },
          },
          {
            label: "Yes",
            onClick: () => {
              deleteAuctionPen(auctionPen);
              closeConfirmModal();
              closeSelf();
            },
          },
        ],
      });
    }
  };

  return (
    <ModalBg
      backgroundStyle={styles.backgroundModal}
      contentStyle={styles.contentModal}
      closeSelf={null}
      closeOnClickOutside
    >
      <ModalHeader
        title={isEdit ? "Edit Sale Lot" : "New Sale Lot"}
        closeSelf={closeSelf}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SaleLotValidationSchema}
        isInitialValid={props =>
          SaleLotValidationSchema.isValidSync(props.initialValues)
        }
        onSubmit={handleSubmit}
      >
        <SaleLotForm
          openConfirmModal={openConfirmModal}
          closeConfirmModal={closeConfirmModal}
          handleClose={closeSelf}
          handleDeleteClick={handleDeleteClick}
          isEdit={isEdit}
        />
      </Formik>
    </ModalBg>
  );
};

SaleLotModal.propTypes = {
  addAuctionPen: PropTypes.func,
  patchSaleLot: PropTypes.func,
  deleteSaleLot: PropTypes.func,
};

export default SaleLotModal;
