import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { SmallCard } from "components/Card";
import { CardGrid, MetadataField } from "components/Card/Elements";
import { BusinessGSTStatus } from "components/ClearingSaleCards/BusinessComponents";
import { Label } from "components/Form/FormikControls";
import { Column } from "components/Layout";

import { AddressDisplayFormat, formatAddress } from "lib/address";

import { getBusinessById } from "selectors";

function ClearingSaleVendorDetailsComponent(props) {
  const { vendorId } = props;
  const { address, businessUsers } =
    useSelector(getBusinessById(vendorId)) || {};
  const businessUsersCount = businessUsers ? businessUsers.length : 0;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Column fullWidth>
          <BusinessGSTStatus businessId={vendorId} />
        </Column>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Column fullWidth>
          <Label>Address</Label>
          <div>
            {address
              ? formatAddress(address, AddressDisplayFormat.FULL)
                  .split("\n")
                  .map(addressLine => [addressLine, <br key={addressLine} />])
              : "(No Address)"}
          </div>
        </Column>
      </Grid>
      <Grid item xs={12}>
        <Label>{`Email Recipients (${businessUsersCount})`}</Label>
        {businessUsersCount === 0 &&
          "Vendor has no email recipients associated"}
        {businessUsers &&
          businessUsers.map((businessUser, i) => (
            <SmallCard key={i}>
              <CardGrid
                areas={[
                  ["name", "phone"],
                  ["email", "email"],
                ]}
              >
                <MetadataField
                  title="Name"
                  value={
                    businessUser.firstName || businessUser.lastName
                      ? `${businessUser.firstName} ${businessUser.lastName}`
                      : "(Not Recorded)"
                  }
                  gridArea="name"
                />
                <MetadataField
                  title="Phone"
                  value={businessUser.phoneNumber || "-"}
                  gridArea="phone"
                />
                <MetadataField
                  title="Email"
                  value={businessUser.email || "-"}
                  gridArea="email"
                />
              </CardGrid>
            </SmallCard>
          ))}
      </Grid>
    </>
  );
}
export default memo(ClearingSaleVendorDetailsComponent);
