import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { addProduct, updateProducts, deleteProduct } from "actions";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";

import { getProductAttributeBySpeciesOptions } from "selectors";

import { ProductsTable } from "./ProductsTable";

export const ProductSettings = () => {
  const productAttributeOptions = useSelector(
    getProductAttributeBySpeciesOptions,
  );

  const dispatch = useDispatch();

  const handleAddProduct = payload => dispatch(addProduct(payload));
  const handleUpdateProducts = payload => dispatch(updateProducts(payload));
  const handleDeleteProduct = productId => dispatch(deleteProduct(productId));

  return (
    <WaitForSync requiredData={[ApiModel.SPECIES, ApiModel.ROUNDS]}>
      <ProductsTable
        productAttributes={productAttributeOptions}
        addProduct={handleAddProduct}
        updateProducts={handleUpdateProducts}
        deleteProduct={handleDeleteProduct}
      />
    </WaitForSync>
  );
};
