import { useSelector } from "react-redux";

import { getSaleyardName } from "lib/navigation";

import { getSaleyards } from "selectors";

export const useGetSaleyardName = saleyardId => {
  const saleyardsLookup = useSelector(getSaleyards);
  let saleyardName = getSaleyardName();
  if (
    !saleyardName &&
    Object.values(saleyardsLookup).length > 0 &&
    saleyardId
  ) {
    saleyardName = saleyardsLookup[saleyardId]?.name;
  }
  return saleyardName;
};
