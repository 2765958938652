export enum RuleValueFormat {
  CENTS = "CENTS",
  DOLLARS = "DOLLARS",
  GRAMS = "GRAMS",
  HIDDEN = "HIDDEN",
  KILOGRAMS = "KILOGRAMS",
  PERCENT = "PERCENT",
  UNITS = "UNITS",
}

export const RuleValueFormatDisplayName = {
  [RuleValueFormat.CENTS]: "Cents (\xA2)",
  [RuleValueFormat.DOLLARS]: "Dollars ($)",
  [RuleValueFormat.GRAMS]: "Grams (g)",
  [RuleValueFormat.HIDDEN]: "Not Shown",
  [RuleValueFormat.KILOGRAMS]: "Kilograms (kg)",
  [RuleValueFormat.PERCENT]: "Percent (%)",
  [RuleValueFormat.UNITS]: "Units",
};

export const RuleValueFormatByRawValueFormat = {
  [RuleValueFormat.CENTS]: [
    RuleValueFormat.CENTS,
    RuleValueFormat.DOLLARS,
    RuleValueFormat.HIDDEN,
    RuleValueFormat.UNITS,
  ],

  [RuleValueFormat.DOLLARS]: [
    RuleValueFormat.CENTS,
    RuleValueFormat.DOLLARS,
    RuleValueFormat.HIDDEN,
    RuleValueFormat.UNITS,
  ],

  [RuleValueFormat.GRAMS]: [
    RuleValueFormat.GRAMS,
    RuleValueFormat.HIDDEN,
    RuleValueFormat.KILOGRAMS,
    RuleValueFormat.UNITS,
  ],

  [RuleValueFormat.KILOGRAMS]: [
    RuleValueFormat.GRAMS,
    RuleValueFormat.HIDDEN,
    RuleValueFormat.KILOGRAMS,
    RuleValueFormat.UNITS,
  ],

  [RuleValueFormat.PERCENT]: [
    RuleValueFormat.HIDDEN,
    RuleValueFormat.PERCENT,
    RuleValueFormat.UNITS,
  ],

  [RuleValueFormat.UNITS]: [
    RuleValueFormat.CENTS,
    RuleValueFormat.DOLLARS,
    RuleValueFormat.GRAMS,
    RuleValueFormat.HIDDEN,
    RuleValueFormat.KILOGRAMS,
    RuleValueFormat.PERCENT,
    RuleValueFormat.UNITS,
  ],
};

export const RuleValueFormatOptionsByRawValueFormat = Object.entries(
  RuleValueFormatByRawValueFormat,
).reduce(
  (acc, [rawValueFormat, ruleValueFormats]) => {
    acc[rawValueFormat] = ruleValueFormats.map(
      (ruleValueFormat): SelectFieldOption => ({
        value: ruleValueFormat,
        label: RuleValueFormatDisplayName[ruleValueFormat],
      }),
    );
    return acc;
  },
  {} as Record<string, SelectFieldOption[]>,
);

export const ValueSource = {
  CONSTANT: "CONSTANT",
  FIELD: "FIELD",
  GLOBAL_BUSINESS: "GLOBAL_BUSINESS",
  SUM: "SUM",
  COUNT: "COUNT",
};
