import React from "react";

import { Paper, Tab, Tabs } from "@material-ui/core";
import { useSelector } from "react-redux";

import EmailTable from "components/Email";
import ENVDDocumentImporter from "components/Importer/eNVD";
import { Column } from "components/Layout";

import { SaleyardPermissions } from "constants/permissions";

import { someHasPermission } from "lib/permissions";

import { getSaleyards } from "selectors";

import { useHasEmailListPermission } from "hooks/useHasPermission";

const TabPanel = ({ children, value, index }) => {
  return (
    <Column
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      flexGrow
      fullHeight
      fullWidth
      flex={value === index}
    >
      {value === index && children}
    </Column>
  );
};

export function Inbox() {
  const saleyards = useSelector(getSaleyards);

  // If any of the saleyards I can see have the envd feature, I can use this feature.
  // Note: Deployments also use the "SaleyardPermissions.featureEnvds"
  const canViewEnvds = someHasPermission(
    Object.values(saleyards),
    SaleyardPermissions.featureEnvds,
  );

  const canViewEmail = useHasEmailListPermission();
  const [selectedTab, setSelectedTab] = React.useState(0);

  if (!canViewEnvds && !canViewEmail) {
    return null;
  }

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabs = [
    canViewEmail && {
      label: "Email",
      key: "email",
      TabComponent: EmailTable,
    },
    canViewEnvds && {
      label: "eNVDs",
      key: "envds",
      TabComponent: ENVDDocumentImporter,
    },
  ].filter(Boolean);

  return (
    <>
      <Paper>
        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable">
          {tabs.map(({ label, key }) => (
            <Tab label={label} key={key} />
          ))}
        </Tabs>
      </Paper>
      {tabs.map(({ key, TabComponent }, index) => (
        <TabPanel value={selectedTab} index={index} key={key}>
          <TabComponent />
        </TabPanel>
      ))}
    </>
  );
}
