import React from "react";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { sendReport } from "actions/offline";

import { ZoomyDialog } from "components/MaterialDialog";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

import { getCurrentSale } from "selectors";

import { EmailForm, validationSchema } from "./EmailForm";

const initialValues = {
  rawEmailAddress: "",
  businessUserIds: [],
  emailRecipients: [],
};

const EmailReportModal = ({
  reportUrl,
  businessId,
  reportName,
  deploymentId,
  returnTo,
}) => {
  const dispatch = useDispatch();
  const currentSale = useSelector(getCurrentSale);
  const onClose = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const handleSubmit = values => {
    const { emailRecipients, businessUserIds, rawEmailAddress } = values;
    dispatch(
      sendReport(
        currentSale,
        reportUrl,
        reportName,
        emailRecipients,
        businessUserIds,
        rawEmailAddress,
        businessId,
        deploymentId,
      ),
    );

    onClose();
  };

  return (
    <ZoomyDialog open onClose={onClose} scroll="paper" fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <EmailForm
          onClose={onClose}
          description={reportName}
          businessId={businessId}
          deploymentId={deploymentId}
        />
      </Formik>
    </ZoomyDialog>
  );
};

export default EmailReportModal;
