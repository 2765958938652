import queryString from "query-string";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "actions/lib";

import {
  ADD_SALE_LOT_COMMIT,
  ADD_SALE_LOT_OFFLINE,
  ADD_SALE_LOT_ROLLBACK,
  COPY_SALE_LOT_TO_SALE,
  DELETE_SALE_LOT_COMMIT,
  DELETE_SALE_LOT_OFFLINE,
  DELETE_SALE_LOT_ROLLBACK,
  SALE_LOT,
} from "constants/actionTypes";
import { SaleLotType } from "constants/saleLots";

import {
  serializeDraftSighting,
  serializeSaleLot,
  serializeSaleyardScanSaleLot,
} from "lib/serializers/saleLot";

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/salelots/", id, action);

/**
 *
 * @param {Object} patch a Sale Lot object with the properties and their updated values
 * @param {Object} saleLot a Sale Lot object, with at least the id
 * @param {Any} ignored not used.
 * @param {Object} [destinationProperty] a Property object with at least the id set
 * @param {String} [actionText=updated] the text to display on a lot card and in the toast message
 * @param {Boolean} disabledToast whether to show a toast on success
 * @param {Boolean} ignoredCurrentSingleWeigh not used
 * @param {Boolean} ignoredIsOnWeighBridge not used
 * @param {String} [changeReason=null] the reason we are performing this action - this will be added to the audit log entry on the backend.
 */
export function patchSaleLotOffline(
  patch,
  saleLot,
  ignored,
  actionText = "updated",
  disabledToast = false,
  ignoredCurrentSingleWeigh,
  ignoredIsOnWeighBridge,
  changeReason = null,
) {
  const meta = {
    lotNumber: saleLot.lot_number || saleLot.lotNumber,
    livestockSaleId: saleLot.livestocksale_id,
    saleLotId: saleLot.id,
    actionText,
    buyerWayId: patch.buyer_way && patch.buyer_way.id,
  };

  const payload = serializeSaleLot(patch);
  const options = { id: saleLot.id };

  return {
    type: SALE_LOT.UPDATE.REQUEST,
    payload: {
      patch,
      saleLot,
    },
    meta: {
      saleyardId: saleLot.saleyardId,
      offline: {
        effect: {
          url: urlCreator(options),
          method: "PATCH",
          body: JSON.stringify(payload),
          changeReason,
        },
        commit: {
          type: SALE_LOT.UPDATE.SUCCESS,
          meta,
          disabledToast,
        },
        rollback: {
          type: SALE_LOT.UPDATE.FAILURE,
          meta,
        },
      },
    },
  };
}
/**
 *
 * @param {SaleyardScanSaleLot|SaleLotRequest} saleLot
 * @param {string} tempId
 * @returns {Object}
 *
 * Prefer not using this action directly - instead use actions/saleLots.js: addSaleLotsWithPens
 * which will handle nested, related data (specifically pens)
 */
export const addSaleLot = (saleLot, tempId) => {
  let payload = null;
  const saleLotType = saleLot.saleLotType || SaleLotType.AUCTION;

  if ([SaleLotType.VENDOR_SPLIT, SaleLotType.AUCTION].includes(saleLotType)) {
    payload = serializeSaleLot(saleLot);
  } else if (saleLotType === SaleLotType.SALEYARD_SCAN) {
    payload = serializeSaleyardScanSaleLot(saleLot); // serialised  ;)
  } else {
    throw new Error(`Unknown Sale Lot type ${saleLotType}`);
  }

  // ensure the temp id is honoured, even if one was set in the sale lot
  payload.id = tempId;

  const meta = {
    tempId,
    consignmentId: payload.consignment_id,
    livestockSaleId: payload.livestocksale_id,
  };

  return {
    type: ADD_SALE_LOT_OFFLINE,
    payload,
    meta: {
      offline: {
        effect: {
          url: urlCreator(),
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: ADD_SALE_LOT_COMMIT,
          meta,
        },
        rollback: {
          type: ADD_SALE_LOT_ROLLBACK,
          meta,
        },
      },
    },
  };
};

export const deleteSaleLot = saleLot => {
  const options = { id: saleLot.id };
  const meta = { saleLot };
  return {
    type: DELETE_SALE_LOT_OFFLINE,

    meta: {
      offline: {
        effect: {
          url: urlCreator(options),
          method: "DELETE",
        },
        commit: {
          type: DELETE_SALE_LOT_COMMIT,
          meta,
        },
        rollback: {
          type: DELETE_SALE_LOT_ROLLBACK,
          meta,
        },
      },
    },
  };
};

export const copySaleLot = (saleLotId, livestockSaleId) => {
  const options = { id: saleLotId, action: `copy-to-sale/${livestockSaleId}` };
  const meta = { livestockSaleId };
  const url = urlCreator(options);

  return {
    type: COPY_SALE_LOT_TO_SALE.OFFLINE,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
        },
        commit: {
          type: COPY_SALE_LOT_TO_SALE.COMMIT,
          meta,
        },
        rollback: {
          type: COPY_SALE_LOT_TO_SALE.ROLLBACK,
          meta,
        },
      },
    },
  };
};

// Note - only comment is in use here, others to be migrated later...
export const SaleLotAction = offlineActionCreator(
  SALE_LOT,
  serializeSaleLot,
  urlCreator,
  true,
);

// The url endpoint for fetching salelots from a sale is different than the
// endpoint for interacting with a single sale lot :/
const bulkUrlCreator = ({
  changesSince,
  saleyardName,
  livestockSaleId,
  id,
  action,
} = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction(
      `/v2/saleyards/${saleyardName}/sales/${livestockSaleId}/salelots/`,
      id,
      action,
    ),
    query: {
      changesSince,
    },
  });
const BulkSaleLotAction = offlineActionCreator(
  SALE_LOT,
  serializeSaleLot,
  bulkUrlCreator,
  true,
);
SaleLotAction.request = BulkSaleLotAction.request;
SaleLotAction.requestChanges = BulkSaleLotAction.requestChanges;

SaleLotAction.draftSighting = (saleLotId, eid) => ({
  type: SALE_LOT.DRAFT_SIGHTING.ACTION,
  saleLotId,
  eid,
});

SaleLotAction.doDraftSighting = (deserializedPayload, options = {}) => {
  const payload = serializeDraftSighting(deserializedPayload);
  const REDUX_OFFLINE_TIMESTAMP = Date.now();

  return {
    type: SALE_LOT.DRAFT_SIGHTING.REQUEST,
    payload,
    REDUX_OFFLINE_TIMESTAMP,
    meta: {
      offline: {
        effect: {
          url: urlCreator({ id: payload.id, action: "draft_sighting" }),
          method: "POST",
          body: JSON.stringify(payload),
          options,
        },
        commit: {
          type: SALE_LOT.DRAFT_SIGHTING.SUCCESS,
        },
        rollback: {
          type: SALE_LOT.DRAFT_SIGHTING.FAILURE,
        },
      },
    },
  };
};

SaleLotAction.bulkDeliverAll = () => {
  return {
    type: SALE_LOT.BULK_DELIVER_ALL.ACTION,
  };
};
