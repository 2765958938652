import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setActiveRole, setCurrentLivestockSaleId } from "actions";

import { CenteredGreedy } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";

import { getRoleBySlug, getSaleById } from "selectors";

export const RedirectToSale = ({ match }) => {
  const { livestockSaleId, userRole } = match.params;
  const role = useSelector(getRoleBySlug(userRole));
  const dispatch = useDispatch();
  const sale = useSelector(getSaleById(+livestockSaleId));

  useEffect(() => {
    if (role) {
      dispatch(setActiveRole(role));
    }
    if (sale) {
      dispatch(setCurrentLivestockSaleId(sale.livestocksale_id, true, false));
    }
  }, [dispatch, role, userRole, sale]);

  return (
    <CenteredGreedy>
      <LoadingSpinner actionName="Loading" subjectName="role and sales" />
    </CenteredGreedy>
  );
};
