import React from "react";

import { PropTypes } from "prop-types";

import { CenteredUnstyledButton } from "components/Button/UnstyledButton";
import { CommentIcon } from "components/Comments/Icon";

import { openCommentsModal } from "lib/navigation";

export const CommentRenderer = props => {
  const { value } = props;
  if (!value) {
    return null;
  }

  const onClick = () =>
    openCommentsModal(
      value.commentType,
      value.commentTypeId,
      window.location.hash,
    );

  return (
    <CenteredUnstyledButton onClick={onClick}>
      <CommentIcon
        hasUnresolvedComments={!!value.unresolvedCommentCount}
        hasResolvedComments={!!value.resolvedCommentCount}
        internalText={value.unresolvedCommentCount}
      />
    </CenteredUnstyledButton>
  );
};

CommentRenderer.propTypes = {
  value: PropTypes.shape({
    commentType: PropTypes.string.isRequired,
    commentTypeId: PropTypes.string.isRequired,
    resolvedCommentCount: PropTypes.number.isRequired,
    unresolvedCommentCount: PropTypes.number.isRequired,
  }),
};
