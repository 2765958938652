import React from "react";

import { useMediaQuery } from "@material-ui/core";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { Dialog, DialogTitle, DialogContent } from "components/MaterialDialog";

import { PenTypes } from "constants/auctionPens";

import { neg1ToNulls, removeNulls } from "lib";

import { getAuctionPenPayload } from "lib/auctionPens";
import { getVendorPropertyConsignmentWithoutTake } from "lib/consignments";
import { openScanModal } from "lib/navigation";
import toast from "lib/toast";

import { useTheme } from "hooks";

import ConsignmentForm from "./ConsignmentForm";
import { ConsignmentValidationSchema } from "./ConsignmentValidationSchema";

const consignmentExistsToast = () => {
  toast.error("A consignment is already created with this vendor and PIC");
};

const ConsignmentModal = ({
  history,
  consignments,
  consignmentToEdit,
  openConfirmModal,
  closeSelf,
  selectedSale,
  addConsignment,
  patchConsignment,
  deleteConsignment,
  deleteSaleLot,
  closeConfirmModal,
  addAuctionPen,
  addSaleLot,
  existingAuctionPenId,
  nextAvailableAuctionPen,
  saleRoundId,
  scans,
}) => {
  const match = useRouteMatch();
  const { saleyard } = match.params;

  const theme = useTheme();

  const isEdit = !!consignmentToEdit;
  const initialValues = isEdit
    ? {
        NVD: consignmentToEdit.NVD,
        vendor_id: consignmentToEdit.vendorId,
        vendor_property_id: consignmentToEdit.vendorPropertyId,
        quantity: consignmentToEdit.quantity,
        notes: consignmentToEdit.notes,
      }
    : {
        tempId: uuidv4(),
        quantity: 0,
        notes: "",
        vendor_id: null,
      };

  const getOrCreateAuctionPen = () => {
    if (existingAuctionPenId) {
      return existingAuctionPenId;
    } else {
      const newAuctionPenId = uuidv4();
      const values = {
        start_pen_prefix: "",
        start_pen: nextAvailableAuctionPen,
        start_pen_suffix: "",
        end_pen: nextAvailableAuctionPen,
        end_pen_suffix: "",
        sale_round_id: saleRoundId,
      };
      const auctionPenPayload = getAuctionPenPayload(values);
      addAuctionPen(auctionPenPayload, newAuctionPenId, [], true);
      return newAuctionPenId;
    }
  };

  const handleSubmit = (values, scan) => {
    const { NVD, vendor_id, vendor_property_id, quantity, tempId, notes } =
      values;
    const consignmentPayload = {
      NVD: NVD?.toString(),
      vendor_id,
      vendor_property_id,
      quantity,
      notes,
    };
    const saleLotId = uuidv4();

    // check if consignment exists with vendor & vendor PIC
    const vendorPICConsignment = getVendorPropertyConsignmentWithoutTake(
      { vendor_id, vendor_property_id },
      consignments,
    );

    const vendorPICConsignmentExistsAndNotTaken = Boolean(vendorPICConsignment);

    if (isEdit) {
      const { id } = consignmentToEdit;
      // Check if consignment with vendor & vendor PIC exists and has not been taken
      // Check if consignment with vendor & vendor PIC exists is the current consignment to edit
      if (
        !vendorPICConsignmentExistsAndNotTaken ||
        (vendorPICConsignmentExistsAndNotTaken &&
          vendorPICConsignment.id === id)
      ) {
        patchConsignment({ id, ...consignmentPayload }, id);
      } else {
        // if a consignment with vendor and vendor PIC already exists without a Take, do not update
        consignmentExistsToast();
        return;
      }
    } else {
      if (vendorPICConsignmentExistsAndNotTaken) {
        // if a consignment with vendor and vendor PIC already exists without a Take, do not create
        consignmentExistsToast();
        return;
      }
      addConsignment(tempId, consignmentPayload, selectedSale);

      const auctionPenId = getOrCreateAuctionPen();

      const saleLotPayload = {
        created: new Date().toUTCString(),
        id: saleLotId,
        pricing_type_id: selectedSale.pricing_type_id,
        livestocksale_id: selectedSale.livestocksale_id,
        buyer_id: selectedSale.default_buyer_id,
        destination_property_id: selectedSale.default_property_id,
        auction_pen_id: auctionPenId,
        consignment_id: tempId,
        sale_round_id: saleRoundId,
        quantity: consignmentPayload.quantity,
      };
      // Do we just hope that the response from create consignment with the
      // real Id has been received and is in the lookup?
      addSaleLot(removeNulls(neg1ToNulls(saleLotPayload)), saleLotId);
    }

    if (scan === true) {
      // START hack
      // FIXME hack to close the consignment modal when opening the scanning screen
      // Remove me when BC Consignment Modal is moved to Hash Route
      const removeEndIndex = match.url.length - "/create".length;
      const returnTo = match.url.substr(0, removeEndIndex);
      history.push(returnTo);
      // END hack

      openScanModal(tempId, saleLotId, false, null, PenTypes.SELLING, returnTo);
    } else {
      closeSelf();
    }
  };

  const hasScansAttached = () => {
    for (const saleLot of consignmentToEdit.saleLots) {
      const saleLotScans = scans[saleLot.id];

      if (saleLotScans && saleLotScans.length) {
        return true;
      }
    }
    return false;
  };

  const deleteConsignmentAndRelatedResources = () => {
    const { saleLots } = consignmentToEdit;

    if (saleLots && saleLots.length) {
      for (let counter = saleLots.length - 1; counter >= 0; counter -= 1) {
        deleteSaleLot(saleLots[counter]);
      }
    }

    // Wait a little but here so we dont get conflicts :(
    setTimeout(() => deleteConsignment(consignmentToEdit), 500);
  };

  const handleScanClick = values => {
    handleSubmit(values, true);
  };

  const handleDeleteClick = () => {
    if (hasScansAttached()) {
      return openConfirmModal({
        title: "Consignment Deletion",
        message: "Sale lots with scans found. Consignment cannot be deleted.",
      });
    } else {
      openConfirmModal({
        title: "Are you sure?",
        message: "Are you sure you want to delete this consignment?",
        actions: [
          {
            label: "No",
            secondary: true,
            onClick: () => {
              closeConfirmModal();
            },
          },
          {
            label: "Yes",
            onClick: () => {
              deleteConsignmentAndRelatedResources();
              closeConfirmModal();
              history.push(
                `/saleyard/${saleyard}/sale/${match.params.saleId}/take`,
              );
            },
          },
        ],
      });
    }
  };
  const fullScreen = useMediaQuery(`(max-width: ${theme.breakpoints[1]}px)`);
  return (
    <Dialog
      open
      onClose={closeSelf}
      maxWidth="sm"
      fullWidth
      scroll="body"
      fullScreen={fullScreen}
    >
      <DialogTitle onClose={closeSelf}>
        {isEdit ? "Edit Consignment" : "New Consignment"}
      </DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={initialValues}
          validationSchema={ConsignmentValidationSchema}
          onSubmit={handleSubmit}
        >
          <ConsignmentForm
            isEdit={isEdit}
            handleScanClick={handleScanClick}
            handleDeleteClick={handleDeleteClick}
          />
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ConsignmentModal.propTypes = {
  consignments: PropTypes.array,
  history: PropTypes.object,
  consignmentToEdit: PropTypes.object,
  openConfirmModal: PropTypes.func,
  closeConfirmModal: PropTypes.func,
  closeSelf: PropTypes.func,
  addConsignment: PropTypes.func,
  patchConsignment: PropTypes.func,
  deleteConsignment: PropTypes.func,
  selectedSale: PropTypes.object,
  addAuctionPen: PropTypes.func,
  addSaleLot: PropTypes.func,
};

export default ConsignmentModal;
