import React from "react";

import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import Card from "components/Card";
import { Row } from "components/Layout";

import { useTheme } from "hooks";

export const Container = styled.div`
  display: inline-flex;
  background: ${({ theme }) => theme.colors.grayF3};
`;

const getBackground = ({ selected, touched, reordered, hasLots, theme }) => {
  let color;

  if (!hasLots) {
    color = theme.colors.inactiveStep;
  } else if (selected) {
    color = theme.colors.ready;
  } else if (touched) {
    color = theme.colors.success;
  } else if (reordered) {
    color = theme.colors.warning;
  } else {
    color = theme.colors.white;
  }
  return `radial-gradient(circle, ${color}98, ${color}95);`;
};

export const LaneTitle = styled.div`
  margin: auto;
  font-weight: bold;
`;

export const StyledPen = styled(Card)(
  props => `
  min-width: 60px;
  padding: ${props.theme.space[1]}px;
  margin: 3px;
  justify-content: center;
  align-items: center;
  cursor: ${props.isDisabled ? "inherit" : "pointer"};
  transition: ${props.theme.transitions[1]};
  background-image: ${getBackground(props)};
  box-shadow: ${
    props?.deployments?.length === 1
      ? `0 2px 4px 0 ${props.deployments[0].corporateColor}; `
      : props.theme.shadows[1]
  };
  height: 30px;  
`,
);

export const PenContainer = styled(Card)`
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  height: min-content;
  align-self: center;
  padding-top: 6px;
`;

export const SortablePens = styled.div`
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  user-select: none;
`;

export const LaneControlButton = styled(StyledPen)`
  background: #427db3;
  color: #fff;
`;

export const ListWrapper = styled.div`
  padding-bottom: 100px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ActionsWrapper = styled(Row)`
  position: fixed;
  bottom: 0;
  background-color: white;
  box-shadow: 0 -2px 4px 0 rgba(200, 200, 200, 0.6);
  height: 92px;
  padding: 24px;
  width: 100%;
  display: flex;
`;

export const CloseButton = styled.button`
  border: none;
  position: absolute;
  right: 0;
  padding: 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray40};
  font-size: 2rem;
  cursor: pointer;
`;

export const LaneActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5rem;
`;

export const LaneReset = styled.div`
  padding: 0.5rem;
  border: 1px solid;
  ${({ disabled, theme }) => `
    ${
      disabled
        ? `color: ${theme.colors.gray9C}; border-color: ${theme.colors.grayDisabled};`
        : `border-color: ${theme.colors.gray40};`
    }
  `}

  background: white;
  text-align: center;
  cursor: pointer;
`;

export const LaneMover = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  padding: 1rem;
  font-size: 1.5rem;
  ${({ hide, disabled, theme }) => `
    ${hide ? "visibility: hidden;" : ""}
    ${disabled ? `color: ${theme.colors.gray9C};` : ""}
    `}
`;

const IconWithMargin = styled(FontAwesomeIcon)`
  margin: 1px;
`;

export const Legend = ({ auctionPenLanes }) => {
  const theme = useTheme();
  const hasUnownedOrEmptyPens = Object.values(auctionPenLanes).some(lane =>
    lane?.some(e => e.hasLots === false),
  );
  const hasUnsavedPens = Object.values(auctionPenLanes).some(lane =>
    lane?.some(e => e.touched),
  );
  const hasOutOfNaturalOrderPens = Object.values(auctionPenLanes).some(lane =>
    lane?.some(e => e.reordered),
  );
  const hasAgencyAssigned = Object.values(auctionPenLanes).some(lane =>
    lane?.some(e => e.deployments.length > 0),
  );

  return (
    <>
      <Row flexWrap alignCenter justifyCenter>
        {hasUnsavedPens && (
          <StyledPen touched hasLots>
            Unsaved pen
          </StyledPen>
        )}
        {hasOutOfNaturalOrderPens && (
          <StyledPen reordered hasLots>
            Out of natural order
          </StyledPen>
        )}
        {hasUnownedOrEmptyPens && <StyledPen>Unowned or Empty</StyledPen>}
        {hasAgencyAssigned && (
          <StyledPen
            deployments={[
              {
                corporateColor: theme.colors.success,
                agencyName: "Sample Agency",
              },
            ]}
            hasLots
          >
            Agency assigned
          </StyledPen>
        )}
      </Row>
      <Row alignCenter justifyCenter>
        <LaneMover
          style={{
            margin: 3,
            flex: "none",
            fontSize: 14,
            padding: 0,
          }}
        >
          <IconWithMargin icon={faArrowUp} />
          <IconWithMargin icon={faArrowDown} />
          <div>Move entire lane up/down</div>
        </LaneMover>
      </Row>
    </>
  );
};
