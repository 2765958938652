import React, { useCallback, useMemo, useRef } from "react";

import { faFileCsv, faMobile } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { screenResize } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { Button } from "components/Form";
import { Column as LayoutColumn } from "components/Layout";

import { AgGridTables } from "constants/aggrid";
import { Column, NominationDetailColId } from "constants/columns";
import { ModalTypes } from "constants/navigation";
import { NOMINATION_CLOSED_TEXT } from "constants/nominations";
import { DeploymentPermissions } from "constants/permissions";

import {
  getLivestockSaleId,
  getNominationCardsRoute,
  getSaleyardName,
  openEditNominationModal,
  openModalLink,
} from "lib/navigation";
import toast from "lib/toast";

import {
  getIsNominationsLocked,
  getNominationTerms,
  selectActiveNominationTermIds,
  selectNominationColumns,
  selectNominationData,
  selectRoleCurrentDeployments,
} from "selectors";

import { useDebounceSelector, useTheme } from "hooks";
import { useSomeHasPermission } from "hooks/useHasPermission";

export const tryAndOpenNominationsModal = (
  nominationsLocked,
  presets = {},
  nominationId = null,
) => {
  if (nominationsLocked) {
    toast.warning(NOMINATION_CLOSED_TEXT, { autoClose: false });
  } else {
    openEditNominationModal(nominationId, presets);
  }
};

const getRowId = params =>
  `${params.data.nomination.id}_${params.data.nominationDetail.nominationTermId}`;

export const NominationTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rowData = useDebounceSelector(selectNominationData);
  const columnDefs = useSelector(selectNominationColumns);
  const nominationTermIds = useSelector(selectActiveNominationTermIds);
  const nominationTermByIdLookup = useSelector(getNominationTerms);
  const nominationsLocked = useSelector(getIsNominationsLocked);
  const agGridRef = useRef();
  const hasCreatePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.canNominateFor,
  );
  const theme = useTheme();

  const hasManualChargePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureManualCharges,
  );

  const createButton = hasCreatePermission ? (
    <LayoutColumn padding={1}>
      <Button
        data-tour="createNomination"
        inline
        onClick={() => tryAndOpenNominationsModal(nominationsLocked)}
      >
        Create Nomination
      </Button>
    </LayoutColumn>
  ) : null;

  const onClickExportWithTotals = useCallback(() => {
    // aggrid (at the time of writing) does not support the export of the bottom row totals
    // this is appending a bottom row onto the CSV before exporting.
    const nominationColIds = nominationTermIds.map(
      nominationTermId => `nominationTerm.${nominationTermId}`,
    );
    const agGrid = agGridRef.current;

    function processCellCallback(params) {
      const { column, node, value, api, context } = params;
      if (
        nominationColIds.includes(column.colId) ||
        column.colId === Column.NOMINATION_GRAND_TOTAL ||
        column.colId.includes("groupedTermTotal")
      ) {
        if (node.group && node.expanded) {
          return "";
        }
      } else if (column.colId === NominationDetailColId.NOMINATION_TERM) {
        if (node.group && node.expanded) {
          return "";
        }
        const nominationTermId = api.valueService.getValue(column, node);
        const nominationTerm =
          context.nominationTermByIdLookup[nominationTermId];
        return `${nominationTerm?.groupName || ""} - ${
          nominationTerm?.name || ""
        }`;
      }
      return value;
    }

    agGrid.exportDataAsCsv({
      processCellCallback,
    });
  }, [nominationTermIds]);

  const getContextMenuItems = useCallback(
    params => {
      const contextMenu = Array.isArray(params.defaultItems)
        ? [...params.defaultItems]
        : [];
      const rowData = params.node.data;
      if (hasManualChargePermission) {
        contextMenu.push({
          name: "Charge Vendor (Add Sundry)",
          action: () => {
            const modalProps = {
              id: null,
              fromBusinessId: null,
              toBusinessId: rowData.vendor_id,
              note: "",
            };
            openModalLink(ModalTypes.ManualAdjustmentForm, modalProps);
          },
        });
        contextMenu.push({
          name: "Credit Vendor (Add Sundry)",
          action: () => {
            const modalProps = {
              id: null,
              fromBusinessId: rowData.vendor_id,
              toBusinessId: null,
              note: "",
            };
            openModalLink(ModalTypes.ManualAdjustmentForm, modalProps);
          },
        });
      }
      return contextMenu;
    },
    [hasManualChargePermission],
  );

  function onGridReady({ api }) {
    agGridRef.current = api;
    api.addEventListener("rowGroupOpened", event => {
      // When a group is opened, it only re-draws the child leaf nodes (rows)
      // Our preferred functionality is to hide the aggregated values on the group nodes when the children are expanded
      // to do this, we must redraw the group node, which is not done by default
      api.redrawRows({ rowNodes: [event.node] });
    });
  }

  const customExport = [
    {
      dataTour: "exportCSV",
      title: "Export CSV",
      icon: faFileCsv,
      onClick: onClickExportWithTotals,
    },
  ];

  const additionalActions = useMemo(
    () => [
      {
        title: "Mobile View",
        icon: faMobile,
        onClick: () => {
          dispatch(screenResize(theme.breakpoints[2] - 1));
          history.push(
            getNominationCardsRoute(getSaleyardName(), getLivestockSaleId()),
          );
        },
        dataTour: "mobileView",
      },
    ],
    [dispatch, history, theme.breakpoints],
  );

  return (
    <AgGridTable
      columnDefs={columnDefs}
      context={{ nominationTermByIdLookup }}
      extraHeaderComponents={createButton}
      onGridReady={onGridReady}
      rowData={rowData}
      getRowId={getRowId}
      tableName={AgGridTables.NOMINATIONS}
      getContextMenuItems={getContextMenuItems}
      customExport={customExport}
      groupIncludeTotalFooter
      additionalActions={additionalActions}
      isGroupOpenByDefault={params =>
        params.rowGroupColumn.colId === Column.TOTALS
      }
    />
  );
};
