import * as Yup from "yup";

const livestockSaleIdsValidation = {
  livestockSaleIds: Yup.array()
    .of(Yup.number())
    .min(1, "Required!")
    .required("Required!"),
};
export const ValidationSchema = Yup.object().shape(livestockSaleIdsValidation);
export const ValidationSchemaWithRuleBookId = Yup.object().shape({
  ...livestockSaleIdsValidation,
  ruleBookId: Yup.string().required("Required!"),
});
