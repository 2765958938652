import React from "react";

import Badge from "components/Badge";

export function consignmentDiffRenderer(props) {
  if (!props.data) {
    return null;
  }
  const { value, valueFormatted } = props;
  if (typeof value === "undefined") {
    return null;
  } else if (value !== 0) {
    return (
      <Badge inline color="warning">
        {valueFormatted}
      </Badge>
    );
  } else {
    return <>{valueFormatted}</>;
  }
}

export function consignmentDiffFormatter(params) {
  if (params.value < 0) {
    return `+${Math.abs(params.value)}`;
  } else if (params.value > 0) {
    return `-${params.value}`;
  } else {
    return "-";
  }
}
