import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { DesktopCard, MediumMobileCard } from "./Cards";
import {
  CompactCardStack,
  createCardFromSummaryData,
  ExpandedCardStack,
} from "./Common";

const consignmentItems = ["consignments", "consignments_in_progress"];

const animalItems = ["booked_in", "scanned", "taken", "sold"];

const summaryItems = ["failed", "pending", "deceased"];

const WrappingExpandedCardStack = styled(ExpandedCardStack)`
  flex-wrap: wrap;
`;

function ScaleOperatorSaleSummaryCards({ summary, isMobile }) {
  const consignmentCards = createCardFromSummaryData(summary, consignmentItems);
  const animalCards = createCardFromSummaryData(summary, animalItems);
  const summaryCards = createCardFromSummaryData(summary, summaryItems);

  if (isMobile) {
    return (
      <>
        <CompactCardStack>
          {consignmentCards.map(({ title, value }) => (
            <MediumMobileCard
              key={title}
              title={title}
              value={value}
              isError={value < 0}
            />
          ))}
        </CompactCardStack>
        <CompactCardStack>
          {animalCards.map(({ title, value }) => (
            <MediumMobileCard
              key={title}
              title={title}
              value={value}
              isError={value < 0}
            />
          ))}
        </CompactCardStack>
        <CompactCardStack>
          {summaryCards.map(({ title, value }) => (
            <MediumMobileCard
              key={title}
              title={title}
              value={value}
              isError={value < 0}
            />
          ))}
        </CompactCardStack>
      </>
    );
  } else {
    return (
      <>
        <WrappingExpandedCardStack>
          {consignmentCards.map(({ title, value, additionalInfo }) => (
            <DesktopCard
              key={title}
              title={title}
              value={value}
              additionalInfo={additionalInfo}
              isError={value < 0}
            />
          ))}
        </WrappingExpandedCardStack>
        <WrappingExpandedCardStack>
          {animalCards.map(({ title, value, additionalInfo }) => (
            <DesktopCard
              key={title}
              title={title}
              value={value}
              additionalInfo={additionalInfo}
              isError={value < 0}
            />
          ))}
        </WrappingExpandedCardStack>
        <WrappingExpandedCardStack>
          {summaryCards.map(({ title, value, additionalInfo }) => (
            <DesktopCard
              key={title}
              title={title}
              value={value}
              additionalInfo={additionalInfo}
              isError={value < 0}
            />
          ))}
        </WrappingExpandedCardStack>
      </>
    );
  }
}

ScaleOperatorSaleSummaryCards.propTypes = {
  summary: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default ScaleOperatorSaleSummaryCards;
