/* eslint-disable no-bitwise */

export const STX = 0x02;
export const CR = 0x0d;

// const Exponent = {
//   EXPONENT_TWO: 0,
//   EXPONENT_ONE: 1,
//   EXPONENT_ZERO: 2,
//   EXPONENT_N_ONE: 3,
//   EXPONENT_N_TWO: 4,
//   EXPONENT_N_THREE: 5,
//   EXPONENT_N_FOUR: 6,
//   EXPONENT_N_FIVE: 7,
// };

const EXPONENT_SCALAR = [1, 1, 1, 0.1, 0.01, 0.001, 0.0001, 0.00001];

// const Rounding = { ONE: 1, TWO: 2, FIVE: 3 };

export const UnitB = { POUNDS: 0, KILOGRAMS: 1 };

const UNITB_SCALAR = [453.59237, 1000];

const Stability = { STABLE: 0, MOVEMENT: 1 };

// const Range = { IN_RANGE: 0, OUT_OF_RANGE: 1 };

export const Sign = { POSITIVE: 0, NEGATIVE: 1 };

// const MeasurementMode = { GROSS_MASS: 0, NET_MASS: 1 };

export const UnitC = {
  USE_UNIT_B: 0,
  GRAMS: 1,
  TONNES: 2,
  OUNCES: 3,
  TROY_OUNCES: 4,
  PENNYWEIGHT: 5,
  TON: 6,
  FREE_UNIT: 7,
};

const UNITC_SCALAR = [
  0, 1, 1000000, 28.349492544, 31.103476, 1.5551738, 907184.7, 1,
];

export class ContinuousOutputView {
  static INVALID_BUFFER = new ArrayBuffer(18);

  static SWA_OFFSET = 1;

  static SWA_SIZE = 1;

  static SWB_OFFSET = 2;

  static SWB_SIZE = 1;

  static SWC_OFFSET = 3;

  static SWC_SIZE = 1;

  static DISPLAYED_OFFSET = 4;

  static DISPLAYED_SIZE = 6;

  static TARE_OFFSET = 11;

  static TARE_SIZE = 6;

  constructor(abBytes, byteOffset = 0) {
    this.view = new DataView(abBytes, byteOffset);

    let arrayBuffer = abBytes;
    let workingOffset = byteOffset;

    const isLongFormat =
      this.view.byteLength === 17 || this.view.byteLength === 18;
    const isShortFormat =
      this.view.byteLength === 11 || this.view.byteLength === 12;

    // Perform a preliminary check on the data, if it doesn't look valid, swap
    // out the byte buffer for a blank one
    // TODO implement the check digit validation
    if (
      this.view.getUint8(0) !== STX ||
      // Handle Long Format (where both Tare Weight and Displayed Weight are transmitted)
      (isLongFormat && this.view.getUint8(16) !== CR) ||
      // Handle Short Format (where only Displayed Weight is transmitted)
      (isShortFormat && this.view.getUint8(10) !== CR) ||
      // Make sure it's an expected length in some way, shape, or form
      !(isLongFormat || isShortFormat)
    ) {
      this.view = new DataView(ContinuousOutputView.INVALID_BUFFER, 0);
      arrayBuffer = ContinuousOutputView.INVALID_BUFFER;
      workingOffset = 0;
    }

    this.swaView = new Uint8Array(
      arrayBuffer,
      ContinuousOutputView.SWA_OFFSET + workingOffset,
      ContinuousOutputView.SWA_SIZE,
    );
    this.swbView = new Uint8Array(
      arrayBuffer,
      ContinuousOutputView.SWB_OFFSET + workingOffset,
      ContinuousOutputView.SWB_SIZE,
    );
    this.swcView = new Uint8Array(
      arrayBuffer,
      ContinuousOutputView.SWC_OFFSET + workingOffset,
      ContinuousOutputView.SWC_SIZE,
    );
    this.displayedWeightView = new Uint8Array(
      arrayBuffer,
      ContinuousOutputView.DISPLAYED_OFFSET + workingOffset,
      ContinuousOutputView.DISPLAYED_SIZE,
    );
    if (isLongFormat) {
      this.tareWeightView = new Uint8Array(
        arrayBuffer,
        ContinuousOutputView.TARE_OFFSET + workingOffset,
        ContinuousOutputView.TARE_SIZE,
      );
    } else {
      this.tareWeightView = null;
    }
  }

  exponent() {
    return this.swaView[0] & 0x07;
  }

  // rounding() {
  //   return (this.swaView[0] >> 3) & 0x03;
  // }

  unitB() {
    return (this.swbView[0] >> 4) & 0x01;
  }

  stability() {
    return (this.swbView[0] >> 3) & 0x01;
  }

  // loadCondition() {
  //   return (this.swbView[0] >> 2) & 0x01;
  // }

  sign() {
    return (this.swbView[0] >> 1) & 0x01;
  }

  // measurementType() {
  //   return this.swbView[0] & 0x01;
  // }

  // resolution() {
  //   // TODO Implement this, whatever it does
  //   return (this.swcView[0] >> 3) & 0x01;
  // }

  // printStatus() {
  //   // TODO Implement this, whatever it does
  //   return (this.swcView[0] >> 2) & 0x01;
  // }

  unitC() {
    return this.swcView[0] & 0x07;
  }

  displayedWeightRaw() {
    return String.fromCharCode.apply(null, this.displayedWeightView);
  }

  // tareWeightRaw() {
  //   return String.fromCharCode.apply(null, this.tareWeightView);
  // }

  // tareWeightRaw() {
  //   return String.fromCharCode.apply(null, this.tareWeightView);
  // }

  isValid() {
    // TODO add checksum calculation in here.
    return this.view.buffer !== ContinuousOutputView.INVALID_BUFFER;
  }

  unitGramsScalar() {
    if (this.unitC() === UnitC.USE_UNIT_B) {
      return UNITB_SCALAR[this.unitB()];
    }
    return UNITC_SCALAR[this.unitC()];
  }

  unitScalar() {
    return EXPONENT_SCALAR[this.exponent()];
  }

  isPositive() {
    return this.sign() === Sign.POSITIVE;
  }

  isStable() {
    return this.stability() === Stability.STABLE;
  }

  displayedWeightGrams() {
    const baseWeight = parseInt(
      // Only allow 0-9 characters, which is fine as only digits, spaces and NULL characters should be present,
      // 0-9 and spaces under normal circumstances and NULL when the buffer has been swapped to the INVALID_BUFFER
      this.displayedWeightRaw().replace(/[^\d]/g, ""),
      10,
    );
    const negation = this.isPositive() ? 1 : -1;
    return negation * baseWeight * this.unitScalar() * this.unitGramsScalar();
  }

  // tareWeightGrams() {
  //   const baseWeight = parseInt(this.tareWeightRaw().trimLeft(), 10);
  //   const negation = this.isPositive() ? 1 : -1;
  //   return negation * baseWeight * this.unitScalar() * this.unitGramsScalar();
  // }
}
