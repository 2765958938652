import { LivestockAgentAction } from "actions/livestockAgents";
import { SaleyardAdminAction } from "actions/saleyardAdmins";

import { ApiModel } from "constants/loading";
import {
  DeploymentPermissions,
  LivestockAgentPermissions,
  SaleyardAdminPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { userTypes } from "constants/users";

import {
  getActiveLivestockAgentDeployment,
  getActiveSaleyardAdminSaleyard,
  getLivestockAgents,
  getSaleyardAdmins,
  selectLivestockAgentsAggridData,
  selectSaleyardAdminsAggridData,
} from "selectors";

export const userTypeConfigMap = {
  [userTypes.SALEYARD_ADMIN]: {
    action: SaleyardAdminAction,
    getDataSelector: getSaleyardAdmins,
    tableDataSelector: selectSaleyardAdminsAggridData,
    requiredData: [
      ApiModel.SALEYARD_ADMINS,
      ApiModel.SALEYARDS,
      ApiModel.PERMISSION_GROUPS,
    ],
    updatePermission: SaleyardAdminPermissions.update,
    deletePermission: SaleyardAdminPermissions.delete,
    createPermission: SaleyardPermissions.canAddSaleyardAdmin,
    createPermissionObjectSelector: getActiveSaleyardAdminSaleyard,
    showDeployments: false,
    showSaleyards: true,
  },
  [userTypes.LIVESTOCK_AGENT]: {
    action: LivestockAgentAction,
    getDataSelector: getLivestockAgents,
    tableDataSelector: selectLivestockAgentsAggridData,
    requiredData: [
      ApiModel.LIVESTOCK_AGENTS,
      ApiModel.DEPLOYMENTS,
      ApiModel.PERMISSION_GROUPS,
    ],
    updatePermission: LivestockAgentPermissions.update,
    deletePermission: LivestockAgentPermissions.delete,
    createPermission: DeploymentPermissions.canAddLivestockAgent,
    createPermissionObjectSelector: getActiveLivestockAgentDeployment,
    showDeployments: true,
    showSaleyards: false,
  },
};

export const ContentTypeNames = {
  [userTypes.SALEYARD_ADMIN]: "saleyardadmin",
  [userTypes.LIVESTOCK_AGENT]: "livestockagent",
};
