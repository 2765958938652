import React, { Component } from "react";

export default class CheckboxCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);
  }

  checkedHandler(event) {
    const { column, node } = this.props;
    const { checked } = event.target;
    const { colId } = column;

    node.setDataValue(colId, checked);
    node.setData({
      ...node.data,
      touched: true,
    });
  }

  render() {
    const { value } = this.props;
    return (
      <input type="checkbox" onChange={this.checkedHandler} checked={value} />
    );
  }
}
