import React, { useMemo } from "react";

import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { getDashboardRoute, getUserOverviewRoute } from "lib/navigation";

import { getActiveRole, getIsAuthenticated } from "selectors";

const RedirectToDashboard = ({ children }) => {
  const location = useLocation();
  const { from } = location.state || {};
  const isAuthenticated = useSelector(getIsAuthenticated);
  const activeRole = useSelector(getActiveRole);
  const userHasNoRoles = activeRole.slug === null;

  const redirectTo = useMemo(() => {
    if (isAuthenticated && !isEmpty(activeRole)) {
      if (from && from.pathname !== "/") {
        return from.pathname;
      } else if (!userHasNoRoles) {
        return getDashboardRoute();
      } else {
        return getUserOverviewRoute();
      }
    }
  }, [activeRole, from, isAuthenticated, userHasNoRoles]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return children;
};

export default RedirectToDashboard;
