import React from "react";

import { useSelector } from "react-redux";

import {
  AuctionPenEidsNotRegisteredChip,
  AuctionPenERPChip,
} from "components/Chip";
import { UnresolvedCommentIcon } from "components/Comments/Icon";
import { IntegrationIcon } from "components/Icons";
import { Column, Row } from "components/Layout";

import { auctionPenStatuses } from "constants/sale";

import { ForEveryoneExceptBusinessUsers } from "containers/ForUserType";

import { getOverridePenStatus } from "lib/auctionPens";

import {
  getAuctionPenById,
  getPriceNameByAuctionPenId,
  getStatusByAuctionPenId,
  getUnresolvedSaleLotCommentCountByAuctionPenId,
} from "selectors";

import CardInfo from "./CardInfo";
import { ChangesPending, ErpChipContainer, Price, TopRow } from "./elements";
import PrimaryInfoSet from "./PrimaryInfoSet";

function GroupSummary({ auctionPenId }) {
  const offlinePatching = useSelector(
    state => getAuctionPenById(state)[auctionPenId]?.syncing,
  );
  const penStatus = useSelector(getStatusByAuctionPenId(auctionPenId));
  const auctionPen = useSelector(getAuctionPenById(auctionPenId));

  const status = getOverridePenStatus(auctionPen, auctionPenId, penStatus);
  const unresolvedSaleLotCommentCount = useSelector(
    getUnresolvedSaleLotCommentCountByAuctionPenId(auctionPenId),
  );

  const price = useSelector(getPriceNameByAuctionPenId(auctionPenId));

  return (
    <>
      <TopRow>
        <Column>
          <Row alignCenter>
            <UnresolvedCommentIcon
              unresolvedCommentCount={unresolvedSaleLotCommentCount}
              horizontalPadding={1}
              size="2x"
            />
          </Row>
          <ForEveryoneExceptBusinessUsers>
            <IntegrationIcon auctionPenId={auctionPenId} />
          </ForEveryoneExceptBusinessUsers>
        </Column>
        <PrimaryInfoSet auctionPenId={auctionPenId} />

        <Price>{status === auctionPenStatuses.NOT_SOLD ? "" : price}</Price>
        <ChangesPending offlinePatching={offlinePatching}>
          &bull;
        </ChangesPending>
      </TopRow>
      <Row justifyBetween flexWrap>
        <CardInfo auctionPenId={auctionPenId} />
        <ErpChipContainer>
          <AuctionPenEidsNotRegisteredChip auctionPenId={auctionPenId} />
          <AuctionPenERPChip auctionPenId={auctionPenId} />
        </ErpChipContainer>
      </Row>
    </>
  );
}

export default React.memo(GroupSummary);
