import {
  ALLOCATE_CONSIGNMENT_EIDS_TO_PEN,
  ALLOCATE_CONSIGNMENT_EIDS_TO_PEN_FAILURE,
  ALLOCATE_CONSIGNMENT_EIDS_TO_PEN_OFFLINE,
  CREATE_CONSIGNMENT_PEN,
  CREATE_CONSIGNMENT_PEN_FAILURE,
  CREATE_CONSIGNMENT_PEN_OFFLINE,
  CREATE_CONSIGNMENT_PEN_SUCCESS,
  CREATE_UNKNOWN_CONSIGNMENT_PEN,
  CREATE_UNKNOWN_CONSIGNMENT_PEN_FAILURE,
  CREATE_UNKNOWN_CONSIGNMENT_PEN_SUCCESS,
} from "constants/actionTypes";

/**
 * @typedef {object} LivestockSaleRequest
 * @property {number} [agency] used to determine which Deployment Sale the request intended to act upon, required for Saleyard Admins, whom have more than one agency
 */

/**
 * @typedef {Object} CreateConsignmentPenOptions
 * @property {boolean} [forceCreateSaleLot=false] determines whether to create a new Sale Lot regardless of the presence of an existing matching one
 * @property {any} [contextId] a caller generated id to be associated with this action
 * @property {function} [onError=null] callback function to be invoked in case of an error
 * @property {function} [onSuccess=null] callback function to be invoked after the pen is created
 * @property {string[]} [ignoredSaleLotAttributes=null] a list of properties to ignore on the existing Sale Lots when attempting to match them
 */

/**
 * @typedef {Object} CreateConsignmentPenAction
 * @property {CREATE_CONSIGNMENT_PEN} type
 * @property {string} agencyId
 * @property {AuctionPen} auctionPenValues
 * @property {string} consignmentId
 * @property {CreateConsignmentPenOptions} options
 * @property {string} roundId
 * @property {SaleLot} saleLotValues
 */

/**
 * Start the appropriate chain of events to ensure that an Auction Pen (with the given values) exists on the given Consignment (via ID).
 * When an appropriately matching Sale Lot or Auction Pen does not exist, each will be created respectively. If the Sale Lot and
 * Auction Pen already exist, it will not perform any work. the contextId parameter will be placed on the meta prop of all resulting actions.
 * @param {string} consignmentId The consignment to create the new Sale Lot on (or find from in case they already exist)
 * @param {string} agencyId The Livestock Agency context to create all of the values in, this is used for determining the deployment sale
 * @param {string} roundId The Round context for which to create the Sale Lot and Auction Pen (or find from in case they already exist)
 * @param {SaleLotRequest} saleLotValues The properties/values to create the new Sale Lot with (or match against an existing one)
 * @param {AuctionPenRequest} auctionPenValues The properties/values to create the new Auction Pen with (or match against an existing one)
 * @param {?CreateConsignmentPenOptions} [options=null]
 * @returns {CreateConsignmentPenAction}
 */
export function createConsignmentPen(
  consignmentId,
  agencyId,
  roundId,
  saleLotValues,
  auctionPenValues,
  options = null,
) {
  return {
    type: CREATE_CONSIGNMENT_PEN,
    auctionPenValues,
    consignmentId,
    agencyId,
    roundId,
    saleLotValues,
    options,
  };
}

export function createConsignmentPenOffline(
  consignmentId,
  saleLotId,
  auctionPenId,
  meta,
) {
  return {
    type: CREATE_CONSIGNMENT_PEN_OFFLINE,
    auctionPenId,
    consignmentId,
    meta,
    saleLotId,
  };
}

export function createConsignmentPenSuccess(
  consignmentId,
  saleLotId,
  auctionPenId,
  meta,
) {
  return {
    type: CREATE_CONSIGNMENT_PEN_SUCCESS,
    auctionPenId,
    consignmentId,
    meta,
    saleLotId,
  };
}

export function createConsignmentPenFailure(errorMessage, meta) {
  return {
    type: CREATE_CONSIGNMENT_PEN_FAILURE,
    errorMessage,
    meta,
  };
}

/**
 * @typedef {Object} CreateUnknownConsignmentPenAction
 * @property {CREATE_CONSIGNMENT_PEN} type
 * @property {string} agencyId
 * @property {number} roundId
 * @property {AuctionPen} auctionPenValues
 * @property {CreateConsignmentPenOptions} options
 * @property {SaleLot} saleLotValues
 */

/**
 * Start the appropriate chain of events to ensure that an Auction Pen (with the given values) exists on the "Unknown Business" Consignment.
 * @param {string} agencyId The Livestock Agency context to create all of the values in, this is used for determining the deployment sale.
 * @param {string} roundId The Round context for which to create the Sale Lot and Auction Pen (or find from in case they already exist)
 * @param {SaleLotRequest} saleLotValues The properties/values to create the new Sale Lot with (or match against an existing one)
 * @param {AuctionPenRequest} auctionPenValues The properties/values to create the new Auction Pen with (or match against an existing one)
 * @param {?CreateConsignmentPenOptions} [options]
 * @returns {CreateConsignmentPenAction}
 */
export function createUnknownConsignmentPen(
  agencyId,
  roundId,
  saleLotValues,
  auctionPenValues,
  options = null,
) {
  return {
    type: CREATE_UNKNOWN_CONSIGNMENT_PEN,
    auctionPenValues,
    agencyId,
    roundId,
    saleLotValues,
    options,
  };
}

export function createUnknownConsignmentPenSuccess(
  consignmentId,
  saleLotId,
  auctionPenId,
  meta,
) {
  return {
    type: CREATE_UNKNOWN_CONSIGNMENT_PEN_SUCCESS,
    auctionPenId,
    consignmentId,
    saleLotId,
    meta,
  };
}

export function createUnknownConsignmentPenFailure(errorMessage, meta) {
  return {
    type: CREATE_UNKNOWN_CONSIGNMENT_PEN_FAILURE,
    errorMessage,
    meta,
  };
}

/**
 * @typedef {Object} AllocateConsignmentEidsToPenOptions
 * @property {function} [onError=null] callback function to be invoked in case of an error
 * @property {function} [onSuccess=null] callback function to be invoked after the pen is created
 * @property {string[]} [ignoredSaleLotAttributes=null] a list of properties to ignore on the existing Sale Lots when attempting to match them
 */

/**
 * @typedef {Object} AllocateConsignmentEidsToPenAction
 * @property {ALLOCATE_CONSIGNMENT_EIDS_TO_PEN} type
 * @property {Array<ConsignmentEidAllocation>} allocations
 * @property {?AllocateConsignmentEidsToPenOptions} options
 */

/**
 * @typedef {Object} ConsignmentEidAllocation
 * @property {string} consignmentId
 * @property {Array<string>} eids
 * @property {AuctionPenRequest} auctionPenMatch
 * @property {SaleLotRequest} saleLotMatch
 */

/**
 * Start the appropriate chain of events to ensure that an Auction Pen (with the given values) exists on the given Consignment (via ID),
 * then moves the given EIDs to their respective Sale Lots. Not that this will only move EIDs and will not create scans.
 * @param {Array<ConsignmentEidAllocation>} allocations The allocations to perform
 * @param {?AllocateConsignmentEidsToPenOptions} [options=null]
 * @returns {AllocateConsignmentEidsToPenAction}
 */
export function allocateConsignmentEidsToPen(allocations, options = null) {
  return {
    type: ALLOCATE_CONSIGNMENT_EIDS_TO_PEN,
    allocations,
    options,
  };
}

export function allocateConsignmentEidsToPenFailure(errorMessage, meta) {
  return {
    type: ALLOCATE_CONSIGNMENT_EIDS_TO_PEN_FAILURE,
    errorMessage,
    meta,
  };
}

export function allocateConsignmentEidsToPenOffline(meta) {
  return {
    type: ALLOCATE_CONSIGNMENT_EIDS_TO_PEN_OFFLINE,
    meta,
  };
}
