import { put } from "redux-saga/effects";

import { MODAL_CONTEXT } from "constants/actionTypes";

export const chainActionFactory = nextAction =>
  function* fn() {
    yield put(nextAction());
  };

export function modalUpdateActionPattern(modalType) {
  return action =>
    action.type === MODAL_CONTEXT.UPDATE.ACTION &&
    action.modalType === modalType;
}

export function* synchronousActionHandler(
  action,
  handler,
  successHandler,
  errorHandler,
) {
  const { options = {} } = action;
  const { onSuccess, onError } = options;
  try {
    const result = yield handler(action);
    try {
      typeof onSuccess === "function" && onSuccess(result);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        "There was an error executing the synchronous success handler for %s",
        action.type,
        e,
      );
      try {
        typeof onError === "function" && onError(e);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(
          "There was an error executing the synchronous error handler for %s",
          action.type,
          e,
        );
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      "There was an error executing the handler for %s",
      action.type,
      e,
    );
    if (typeof errorHandler === "function") {
      return yield errorHandler(action, e);
    }
    return;
  }
  if (typeof successHandler === "function") {
    return yield successHandler(action);
  }
}
