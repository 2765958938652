import React, { useMemo, useState } from "react";

import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { FatFingerButton } from "components/Button/FatFingers";

import { isNoSale } from "lib/saleLot";

import { getBusinesses, getCurrentSale, getBidders } from "selectors";

import { useGetBuyerWayFromBidder } from "hooks";

import BidderSelect from "./BidderSelect";
import BuyerDetail from "./BuyerDetail";
import BuyerSelect from "./BuyerSelect";
import BuyerTypeTabs, { BuyerTypes } from "./BuyerTypeTabs";
import { SectionContainer } from "./Layout";

function BuyerPicker(props) {
  const {
    agencyId,
    bidderSearchValue,
    buyerId,
    buyerWayId,
    isDisabled,
    isBidderSearchSelected,
    onFocusBidderNumberSearch,
    onBlurBidderNumberSearch,
    openSearch,
    onOpenAddBuyerWay,
    onSelectBuyerValues,
    propertyId,
    vendorId,
  } = props;

  const {
    using_registered_bidders: usingRegisteredBidders,
    species_id: speciesId,
  } = useSelector(getCurrentSale);

  const businessByIdLookup = useSelector(getBusinesses);
  const bidderByIdLookup = useSelector(getBidders);
  const getBuyerWayFromBidder = useGetBuyerWayFromBidder();

  const [selectedBuyerType, setSelectedBuyerType] = useState(
    usingRegisteredBidders ? BuyerTypes.REGISTERED : BuyerTypes.RECENT,
  );

  const biddersList = useMemo(
    () => Object.values(bidderByIdLookup),
    [bidderByIdLookup],
  );

  const buyer = businessByIdLookup[buyerId];

  const buyerWay =
    buyer && buyerWayId
      ? buyer.buyerWays.find(({ id }) => id === buyerWayId)
      : null;

  const buyerWayName = buyerWay ? buyerWay.name : "";

  const bidder = usingRegisteredBidders
    ? biddersList.find(
        bidder =>
          // The Bidder Registration must be complete to match against it. Where incomplete means the Bidder Rego has a `null` `businessId` attribute.
          // Without this, any  unsold Sale Lot (a Sale Lot with `null` `buyerId`) will ALWAYS match any Bidder Rego with a `null` `businessId`
          bidder.businessId &&
          buyerId === bidder.businessId &&
          propertyId === bidder.defaultPropertyId &&
          buyerWayName === bidder.buyerWay,
      )
    : null;

  const bidderId = bidder ? bidder.id : null;

  function onSelectBuyerType(buyerType) {
    setSelectedBuyerType(buyerType);
  }

  function onSelectBidder(bidderId) {
    const newBidder = bidderByIdLookup[bidderId];

    const buyerId = newBidder.businessId;

    const buyerWay = getBuyerWayFromBidder(newBidder);

    onSelectBuyerValues({
      buyerId,
      buyerWayId: buyerWay.id,
      propertyId: newBidder.defaultPropertyId,
    });
  }

  const iseSelectedBuyerNoSale = isNoSale(vendorId, buyerId);

  return (
    <>
      <BuyerTypeTabs
        selected={selectedBuyerType}
        onSetSelected={onSelectBuyerType}
        onOpenSearch={openSearch}
        disabled={isDisabled}
      />

      {iseSelectedBuyerNoSale && (
        <BuyerDetail
          disabled={isDisabled}
          isNoSale
          unsetDetail={() =>
            onSelectBuyerValues({
              buyerId: null,
              buyerWayId: null,
              propertyId: null,
            })
          }
        />
      )}

      {selectedBuyerType === BuyerTypes.REGISTERED &&
        !iseSelectedBuyerNoSale && (
          <>
            {!bidderId && (
              <SectionContainer>
                <FatFingerButton
                  description="Bidder number search"
                  text={bidderSearchValue}
                  onFocus={onFocusBidderNumberSearch}
                  onClick={onFocusBidderNumberSearch}
                  onBlur={onBlurBidderNumberSearch}
                  isSelected={isBidderSearchSelected}
                  isDisabled={isDisabled}
                />
              </SectionContainer>
            )}
            <BidderSelect
              bidderId={bidderId}
              bidderFilter={bidderSearchValue}
              onClearBidder={() =>
                onSelectBuyerValues({
                  buyerId: null,
                  buyerWayId: null,
                  propertyId: null,
                })
              }
              onSelectBidder={onSelectBidder}
              isDisabled={isDisabled}
            />
          </>
        )}

      {selectedBuyerType === BuyerTypes.RECENT && !iseSelectedBuyerNoSale && (
        <BuyerSelect
          agencyId={agencyId}
          buyerId={buyerId}
          buyerWayId={buyerWayId}
          disabled={isDisabled}
          onOpenAddBuyerWay={onOpenAddBuyerWay}
          setBuyerValues={onSelectBuyerValues}
          speciesId={speciesId}
        />
      )}
    </>
  );
}
BuyerPicker.propTypes = {
  agencyId: PropTypes.number,
  bidderSearchValue: PropTypes.string,
  buyerId: PropTypes.string,
  buyerWayId: PropTypes.number,
  isDisabled: PropTypes.bool,
  isBidderSearchSelected: PropTypes.bool,
  onBlurBidderNumberSearch: PropTypes.func,
  onFocusBidderNumberSearch: PropTypes.func,
  openSearch: PropTypes.func.isRequired,
  onSelectBuyerValues: PropTypes.func.isRequired,
  propertyId: PropTypes.string,
};

export default BuyerPicker;
