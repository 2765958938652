import React from "react";

import { printPlacardV3 } from "actions";

import { Accreditation } from "constants/draftingAttributes";
import { PageSize, PlacardVersions } from "constants/pdf";

import { toTitleCase } from "lib";

import { sortByRoundAndPen } from "lib/auctionPens";
import { groupedBoxes, fontSize, getVendorName } from "lib/pdf";
import { getMarksLocationString } from "lib/saleLot";
import { formatTime } from "lib/timeFormats";

import { useQuery } from "hooks/useQuery";
import agrinousLogo from "img/AgriNous-logo-black-white-base64";

import { RenderPlacard } from "./RenderPlacard";

export const getContentForPlacardV3 = (
  saleLots,
  deploymentIdBySaleLotIdLookup,
  deployments,
  vendorDeploymentBusinessBySaleLotIdLookup,
  isVendorBredBySaleLotIdLookup,
  includeWeight,
  size,
) => {
  const isA4 = size === PageSize.A4;
  const contents = [];
  sortByRoundAndPen(saleLots).forEach(saleLot => {
    const deploymentBusiness =
      vendorDeploymentBusinessBySaleLotIdLookup[saleLot.id];

    const vendorName = getVendorName(saleLot, deploymentBusiness);
    const district = saleLot.vendor?.properties?.[0]?.district;
    const sex = saleLot.sex.name;

    const quantityAndMark = `${saleLot.quantity}${getMarksLocationString(
      saleLot,
    )}`;
    const averageWeight = saleLot.averageWeightFormattedRounded
      ? `AVG. ${saleLot.averageWeightFormattedRounded}kg`
      : "";

    const deploymentId = deploymentIdBySaleLotIdLookup[saleLot.id];
    const deployment = deployments[deploymentId];
    const isVendorBred = isVendorBredBySaleLotIdLookup[saleLot.id];

    const draftingAttributes = saleLot.draftingAttributes || {};
    const {
      bloodline,
      publicDescription: placardText,
      isWeaned,
      accreditationGrassFed,
    } = draftingAttributes;
    const isGrassFed = accreditationGrassFed === Accreditation.ALL;

    contents.push([
      {
        layout: "noBorders",
        table: {
          widths: ["100%"],
          body: [
            [""],
            [
              {
                image: `logo_${deploymentId}`,
                absolutePosition: { x: isA4 ? 60 : 100, y: 40 },
                width: isA4 ? 600 * 0.8 : 800 * 0.8,
                height: isA4 ? 90 * 0.8 : 110 * 0.8,
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                width: 90,
                text: `${vendorName.substring(0, 24)}`,
                fontSize: fontSize(vendorName, isA4),
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: district || "",
                fontSize: isA4 ? 30 : 60,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: quantityAndMark,
                fontSize: isA4 ? 110 : 180,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: includeWeight ? averageWeight : "",
                fontSize: isA4 ? 40 : 80,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: sex,
                fontSize: isA4 ? 40 : 80,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: bloodline,
                fontSize: isA4 ? 40 : 60,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: placardText,
                fontSize: isA4 ? 30 : 40,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                image: `data:image/jpeg;base64,${agrinousLogo}`,
                width: isA4 ? 50 : 100,
                absolutePosition: isA4 ? { x: 25, y: 792 } : { x: 65, y: 1120 },
              },
            ],
            [
              {
                text: `${toTitleCase(
                  saleLot.livestockAgency ? saleLot.livestockAgency.name : "",
                )} Pen: ${saleLot.penName ? saleLot.penName : ""} ${
                  saleLot.vendor.shortCode ? saleLot.vendor.shortCode : ""
                } ${
                  saleLot.vendor_name ? saleLot.vendor_name : ""
                }  ${formatTime(
                  Date.now(),
                )}    -    TO BE SOLD AS DESCRIBED BY THE VENDOR, PARTICULARS FOR INFORMATION ONLY BUT NOT GUARANTEED.`,
                fontSize: isA4 ? 6 : 8,
                absolutePosition: isA4
                  ? { x: 80, y: 794 }
                  : { x: 180, y: 1125 },
              },
            ],
            [
              groupedBoxes(
                ["Vendor \nBred", "Weaned", "Grass \nFed"],
                isA4 ? 700 : 1000,
                deployment,
                isA4 ? 30 : 60,
                isA4 ? 35 : 65,
                isA4 ? 130 : 230,
                false,
                isVendorBred,
                isWeaned,
                isGrassFed,
                isA4,
              ),
            ],
          ],
        },
      },
    ]);
  });
  return contents;
};

export const RenderA3PlacardV3 = () => {
  const urlQueryString = useQuery();
  return (
    <RenderPlacard
      action={printPlacardV3}
      version={PlacardVersions.V3}
      includeWeight={!urlQueryString.get("without_weight")}
      size={PageSize.A3}
    />
  );
};

export const RenderA4PlacardV3 = () => {
  const urlQueryString = useQuery();
  return (
    <RenderPlacard
      action={printPlacardV3}
      version={PlacardVersions.V3}
      includeWeight={!urlQueryString.get("without_weight")}
      size={PageSize.A4}
    />
  );
};
