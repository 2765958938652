import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const penOwnersSerializeMap = {
  businessId: "business_id",
  buyerWay: {
    id: "id",
    name: "name",
  },
};
const serializeMap = {
  id: "id",
  capacity: "capacity",
  deploymentId: "deployment_id",
  end_pen: "end_pen",
  isLaneStart: "is_lane_start",
  isLocked: "is_locked",
  livestockSaleId: "livestock_sale_id",
  order: "order",
  penArchetypeId: "pen_archetype_id",
  penType: "pen_type",
  saleRoundId: "sale_round_id",
  start_pen: "start_pen",

  autoDraftState: {
    entryGateStatus: "entry_gate_status",
    exitGateStatus: "exit_gate_status",
    destinationPenId: "destination_pen_id",
    filters: {
      buyerId: "buyer_id",
      vendorId: "vendor_id",
      buyerWay: {
        id: "id",
        name: "name",
      },
    },
  },
  deployments: {
    id: "id",
    corporateColor: "corporate_color",
    code: "code",
    agencyName: "agency_name",
  },
  penOwners: penOwnersSerializeMap,
};

const nestedObjectMap = {
  auto_draft_state: "autoDraftState",
  pen_owners: "penOwners",
  buyer_way: "buyerWay",
};

export const serializeAuctionPen = buildSerializer(
  serializeMap,
  "auctionPen",
  nestedObjectMap,
);

export const deserializeAuctionPen = buildDeserializer(
  serializeMap,
  "auctionPen",
  nestedObjectMap,
  { penOwners: [] },
);

export const serializePenOwner = buildSerializer(
  penOwnersSerializeMap,
  "penOwner",
  nestedObjectMap,
);

export const deserializePenOwner = buildDeserializer(
  penOwnersSerializeMap,
  "penOwner",
  nestedObjectMap,
);
