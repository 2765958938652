import React from "react";

import Divider from "@material-ui/core/Divider";
import styled from "styled-components/macro";

import { logoColor } from "constants/theme";

import { useIsMobile } from "hooks";

import {
  LogoFull,
  LogoSmall,
  ChristmasLogoFull,
  ChristmasLogoSmall,
  SidebarLogo,
} from "./SidebarLogo";

const LogoWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;
  margin-bottom: 40px;
`;

const PreviewWrapper = styled.div`
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  color: white;
  top: 38px;
  left: 15px;
`;

const SidebarBrandingLogo = ({
  isOpen,
  LogoSmall,
  LogoFull,
  isRotatedLogo,
}) => {
  const isMobile = useIsMobile();
  return (
    <>
      <LogoWrapper>
        <SidebarLogo isMobile={isMobile} isRotatedLogo={isRotatedLogo}>
          {isOpen ? (
            <LogoFull isMobile={isMobile} color={logoColor} dashed={false} />
          ) : (
            <LogoSmall isMobile={isMobile} color={logoColor} dashed={false} />
          )}
        </SidebarLogo>
      </LogoWrapper>
      {(process.env.REACT_APP_PREVIEW || "") === "true" && (
        <PreviewWrapper>PREVIEW</PreviewWrapper>
      )}
      <Divider />
    </>
  );
};

export function SidebarBranding(props) {
  const { isOpen } = props;
  const date = new Date();
  const isChristmasPeriod = date.getMonth() === 11;

  return (
    <SidebarBrandingLogo
      isOpen={isOpen}
      LogoFull={isChristmasPeriod ? ChristmasLogoFull : LogoFull}
      LogoSmall={isChristmasPeriod ? ChristmasLogoSmall : LogoSmall}
      isRotatedLogo={!isChristmasPeriod}
    />
  );
}
