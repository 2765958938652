import { takeEvery } from "redux-saga/effects";

import { ECONTRACT } from "constants/actionTypes";
import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";

function onCreateSuccess({ payload }) {
  openModalLink(ModalTypes.EContract, { id: payload.id });
}

export default function* eContractSaga() {
  yield takeEvery(ECONTRACT.CREATE.SUCCESS, onCreateSuccess);
}
