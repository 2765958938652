import styled from "styled-components/macro";

const TabSelector = styled.div`
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 24px;
  padding-bottom: 3px;
  transition: border 200ms ease-in-out;
  min-width: fit-content;
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  ${({ theme }) => `${theme.active}`}
  :not(:active) {
    ${({ theme }) => `${theme.transitions[0]}`}
  }
  ${({ selected, color, theme, bold }) => {
    const borderHeight = bold ? "4px" : "3px";
    return `
    font-weight: ${bold ? "700" : "500"};
    margin-left: ${theme.space[3]}px;
    color: ${theme.colors[color] || theme.colors.primary};
    border-bottom: ${`${borderHeight} solid ${
      selected ? theme.colors[color] || theme.colors.primary : "transparent"
    }`};
`;
  }};
  &:first-child {
    margin: 0;
  }
`;

TabSelector.displayName = "TabSelector";

export default TabSelector;
export { TabPanel } from "components/TabSelector/TabPanel";
