import reduceReducers from "reduce-reducers";

import { MASTER_RULE_BOOK, RULE_BOOK } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  passThroughDeserializer,
} from "lib/reducers";

const createReducer = apiModelOfflineCreateReducer(RULE_BOOK, {
  deserializer: passThroughDeserializer,
});

const deleteReducer = apiModelOfflineDeleteReducer(RULE_BOOK);

const fetchReducer = apiModelOfflineFetchReducer(RULE_BOOK, {
  deserializer: passThroughDeserializer,
});

const updateReducer = apiModelOfflineUpdateReducer(RULE_BOOK, {
  deserializer: passThroughDeserializer,
});

const masterCreateReducer = apiModelOfflineCreateReducer(MASTER_RULE_BOOK, {
  deserializer: passThroughDeserializer,
});

const masterDeleteReducer = apiModelOfflineDeleteReducer(MASTER_RULE_BOOK);

const masterFetchReducer = apiModelOfflineFetchReducer(MASTER_RULE_BOOK, {
  deserializer: passThroughDeserializer,
});

const masterUpdateReducer = apiModelOfflineUpdateReducer(MASTER_RULE_BOOK, {
  deserializer: passThroughDeserializer,
});

export const ruleBookReducer = reduceReducers(
  createReducer,
  deleteReducer,
  fetchReducer,
  updateReducer,
);

export const masterRuleBookReducer = reduceReducers(
  masterCreateReducer,
  masterDeleteReducer,
  masterFetchReducer,
  masterUpdateReducer,
);
