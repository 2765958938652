import React from "react";

import { useDispatch } from "react-redux";

import { GlobalBusinessesAction } from "actions";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";

import { useMountEffect } from "hooks";
import { useHasCreateRuleBookPermission } from "hooks/useHasCreateRuleBookPermission";

import { RuleBooksManager } from "./RuleBooks";

export function RuleBooks() {
  const dispatch = useDispatch();
  useMountEffect(() => {
    dispatch(GlobalBusinessesAction.request());
  });

  const hasCreatePermissions = useHasCreateRuleBookPermission();

  return (
    <WaitForSync
      requiredData={[
        ApiModel.ROUNDS,
        ApiModel.SPECIES,
        ApiModel.MASTER_RULE_BOOKS,
        ApiModel.MASTER_RULES,
        ApiModel.RULE_BOOKS,
        ApiModel.RULES,
      ]}
    >
      <RuleBooksManager hasCreatePermissions={hasCreatePermissions} />
    </WaitForSync>
  );
}
