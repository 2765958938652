export const AccessBlockReasons = {
  GEO_BLOCK: "GEO_BLOCK",
  CONCURRENT_USER_BLOCK: "CONCURRENT_USERS",
};
export const GeoBlockReasons = {
  NO_LOCATION_DATA: "Location data not found",
  INVALID_LOCATION_DATA: "Invalid location data",
  LOCATION_NOT_ENABLED: "Location services not enabled",
  OUTSIDE_RESTRICTION: "Outside Geofence",
};
