import { getConsignmentCode } from "lib/consignments";

import { isObjectChangedAfter } from "selectors/globalSearch/lib";

export const consignmentFilteredVendorNumberComparator =
  vendorNums => consignment =>
    vendorNums.includes(getConsignmentCode(consignment));

export const consignmentFilteredVendorPicsComparator =
  vendorPics => consignment =>
    vendorPics.includes(consignment.vendor_property_id);

export const consignmentFilteredCheckpointComparator =
  checkpoints => consignment =>
    isObjectChangedAfter(consignment, checkpoints[0]);

export const consignmentFilteredReceivalPenComparator =
  receivalPens => consignment =>
    receivalPens.includes(consignment.receiving_pen);
