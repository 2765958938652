import { isEmpty } from "lodash";
import { createSelector } from "reselect";

import { EMPTY_OBJECT, toSelectOption } from "lib";

import { getCurrentRoundsList, getRounds } from "selectors";

const getIncludeAll = (state, includeAll = false) => includeAll;

const selectSaleRoundOptions = createSelector(
  [getCurrentRoundsList, getRounds, getIncludeAll],
  (currentRounds, rounds, includeAll) => {
    if (!isEmpty(rounds)) {
      return [
        includeAll ? { label: "All", value: null } : null,
        ...currentRounds.map(rId => toSelectOption(rounds[rId])),
      ].filter(Boolean);
    } else {
      return [];
    }
  },
);

export const getSaleRoundsOptions =
  (includeAll = false) =>
  state =>
    selectSaleRoundOptions(state, includeAll);

export const getSaleRoundById = roundId => state =>
  getRounds(state)[roundId] || EMPTY_OBJECT;
