import { IMPORT_DEPLOYMENT_SALE } from "constants/actionTypes";

export const importDeploymentSale = (payload, id) => {
  return {
    type: IMPORT_DEPLOYMENT_SALE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/v2/deployment-sale/${id}/`,
          method: "PUT",
          body: JSON.stringify(payload),
        },
        commit: {
          type: IMPORT_DEPLOYMENT_SALE.SUCCESS,
        },
        rollback: {
          type: IMPORT_DEPLOYMENT_SALE.FAILURE,
        },
      },
    },
  };
};
