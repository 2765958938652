import React from "react";

import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";

import Button from "components/Button";
import { CenteredGreedy } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";

import {
  ACCEPTED_FILE_TYPES,
  DEFAULT_MAX_FILE_SIZE,
  GLOBAL_MAX_FILE_SIZE,
  MAX_IMAGE_HEIGHT,
  MAX_IMAGE_WIDTH,
  MAX_VIDEO_FILE_SIZE,
  RESIZABLE_IMAGE_TYPES,
} from "constants/files";

const checkFileSize = (type, size) => {
  if (type.startsWith("video/")) {
    return size < MAX_VIDEO_FILE_SIZE;
  }
  return size < DEFAULT_MAX_FILE_SIZE;
};

export const DropContainer = styled.div(
  ({ theme, disabled }) => `
  margin: 1rem;
  padding: 1rem;
  color:  ${disabled ? theme.colors.grayAE : theme.colors.black};
  background: ${disabled ? theme.colors.grayF7 : theme.colors.white};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: ${theme.colors.gray78}
  border-style: ${disabled ? `solid` : `dashed`}
  &:hover {
    color: ${disabled ? theme.colors.grayAE : theme.colors.white};
    background-color: ${disabled ? theme.colors.grayF7 : theme.colors.primary};
  }
  text-align: center;
  cursor: pointer;
  `,
);

const InfoText = styled.div`
  padding-top: 1rem;
  color: #666666;
  font-size: 12px;
  text-align: center;
`;

const FileSummary = styled.div`
  padding: ${({ theme }) => theme.space[3]}px;
  background: ${({ theme }) => theme.colors.gray95};
`;

const FileSummaryName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
const FileSummaryRejectionReasons = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.thin};
`;

function FileSummaryItem(props) {
  const { name, size, type, allowedFileTypes } = props;

  const reasonsRejected = [];
  if (!checkFileSize(type, size)) {
    reasonsRejected.push(`file is too large`);
  }
  if (allowedFileTypes.indexOf(type) === -1) {
    reasonsRejected.push("file format is not compatible");
  }
  return (
    <li key={name}>
      <FileSummaryName>{name}</FileSummaryName>
      <br />
      <FileSummaryRejectionReasons>
        {reasonsRejected.join(", ")}
      </FileSummaryRejectionReasons>
    </li>
  );
}
FileSummaryItem.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  allowedFileTypes: PropTypes.array.isRequired,
};

function FileSummaryList(props) {
  const { acceptedFiles, rejectedFiles, onActionClick, allowedFileTypes } =
    props;
  const rejectedUsePlural = rejectedFiles.length !== 1;
  const approvedUsePlural = acceptedFiles.length !== 1;
  const canContinue = acceptedFiles.length > 0;
  return (
    <FileSummary>
      <p>
        {acceptedFiles.length} file{approvedUsePlural ? "s were" : " was"}{" "}
        accepted.
      </p>
      <p>
        The following ({rejectedFiles.length}) file
        {rejectedUsePlural ? "s are" : " is"} not currently compatible:
      </p>
      <InfoText>
        (Files must be an image or document under{" "}
        {DEFAULT_MAX_FILE_SIZE / 1000000}MB, or a video under{" "}
        {MAX_VIDEO_FILE_SIZE / 1000000}
        MB. NVDs may only be JPG images.)
      </InfoText>
      <ul>
        {rejectedFiles
          .map(({ name, size, type }) => ({
            name,
            size,
            type,
            allowedFileTypes,
          }))
          .map(FileSummaryItem)}
      </ul>
      <CenteredGreedy>
        <Button type="button" onClick={onActionClick}>
          {canContinue ? "Continue" : "OK"}
        </Button>
      </CenteredGreedy>
    </FileSummary>
  );
}
FileSummaryList.propTypes = {
  acceptedFiles: PropTypes.array.isRequired,
  rejectedFiles: PropTypes.array.isRequired,
  onActionClick: PropTypes.func.isRequired,
  allowedFileTypes: PropTypes.array.isRequired,
};

const UnderlineText = styled.p`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.success};
  margin: 0;
`;

// Prepares a list of files ready for upload; resizes to a max of 2048x2048, and if rotate is truthy rotates by 270 degrees.
const prepareForUpload = async (
  files,
  rotate,
  maxWidth = MAX_IMAGE_WIDTH,
  maxHeight = MAX_IMAGE_HEIGHT,
) => {
  return files.map(async file => {
    if (!RESIZABLE_IMAGE_TYPES.includes(file.type)) {
      return file;
    }
    const img = document.createElement("img");
    img.src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => resolve(e.target.result);
      reader.readAsDataURL(file);
    });

    // TODO: Capture and maintain EXIF data using piexifjs
    // const exif = piexifjs.load(img.src);

    /* eslint-disable-next-line no-promise-executor-return */ // FIXME?
    await new Promise(resolve => (img.onload = resolve));
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // used to store the image's x axis scale factor
    let xScale = 1;

    // used to store the image's y axis scale factor
    let yScale = 1;

    const initialWidth = img.naturalWidth;
    const initialHeight = img.naturalHeight;

    // check if the image is wider than the maximum width allowed
    if (initialWidth > maxWidth) {
      xScale = maxWidth / (initialWidth || 1); // Protect against a 0 x 0 px image
    }

    // check if the image is taller than the maximum height allowed
    if (initialHeight > maxHeight) {
      yScale = maxHeight / (initialHeight || 1); // Protect against a 0 x 0 px image
    }

    // used to store the final x and y axis scale factor
    // has the effect of Scaling the whole image by the largest axis (relative to the preferred width),
    // additionally if the image is smaller than the preferred dimensions, it will not upsample it
    const downsampleScale = Math.min(xScale, yScale, 1);

    const downsampledWidth = initialWidth * downsampleScale;

    const downsampledHeight = initialHeight * downsampleScale;

    // Historic image scanning behavior for NVDs requires that we rotate the image 270 degrees.
    // When there is time, this function should accept a rotation degrees instead of a boolean and this code should ha\ve no reference to NVDs
    const rotationRadians = ((rotate ? 270 : 0) * Math.PI) / 180;

    // Calculate the new width and height of the canvas based on the scale and rotation of the image
    canvas.width = Math.abs(
      Math.cos(rotationRadians) * downsampledWidth -
        Math.sin(rotationRadians) * downsampledHeight,
    );
    canvas.height = Math.abs(
      Math.sin(rotationRadians) * downsampledWidth +
        Math.cos(rotationRadians) * downsampledHeight,
    );

    ctx.rotate(rotationRadians);
    ctx.drawImage(
      img,
      // Source/Sample Rect
      0, // sample top left x coord
      0, // sample top left y coord
      img.naturalWidth, // sample bottom right x coord
      img.naturalHeight, // sample bottom right y coord
      // Destination Rect
      // TODO this should be properly calculated, but hotfix and time constraints
      rotate ? -downsampledWidth : 0, // destination top left x coord
      0, // destination top left y coord
      downsampledWidth, // destination bottom right x coord
      downsampledHeight, // destination bottom right y coord
    );

    ctx.save();

    const blob = await new Promise(resolve => {
      canvas.toBlob(resolve, "image/jpeg", 0.8);
    });
    return new File([blob], file.name);
  });
};

export const Dropzone = ({ onDrop, allowedFileTypes = null, disabled }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFileTypes,
    disabled,
  });
  return (
    <DropContainer {...getRootProps()} disabled={disabled}>
      <input {...getInputProps()} data-tour="dropzone" />

      {isDragActive ? (
        <div>Drop the file here ...</div>
      ) : (
        <span>
          Drop files to upload <br /> or{" "}
          <UnderlineText>Select from your device</UnderlineText>
        </span>
      )}
    </DropContainer>
  );
};

function disposeFileObjectURL(file) {
  window.URL.revokeObjectURL(file.preview);
}

export const FileInput = ({
  upload,
  closeSelf,
  rotate,
  allowedFileTypes = ACCEPTED_FILE_TYPES,
  disabled,
}) => {
  const [accepted, setAccepted] = React.useState([]);
  const [rejected, setRejected] = React.useState([]);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const prepareAndUpload = async files => {
    setIsProcessing(true);
    const resizedImages = await prepareForUpload(files, rotate);

    resizedImages.forEach(async image => upload(await image));
    setIsProcessing(false);
  };

  async function onRejectionsAcknowledged(accepted, rejected, closeSelf) {
    setAccepted([]);
    setRejected([]);
    await prepareAndUpload(accepted);
    closeSelf && closeSelf();
  }

  const onDismissRejected = () =>
    onRejectionsAcknowledged(accepted, rejected, closeSelf);

  const onFilesSelected = async (accepted, rejected) => {
    accepted.forEach(disposeFileObjectURL);
    rejected.forEach(disposeFileObjectURL);
    setAccepted(accepted);
    setRejected(rejected);

    if (accepted.length && !rejected.length) {
      onRejectionsAcknowledged(accepted, rejected);
    }
  };

  const showPrompt = rejected.length === 0 && accepted.length === 0;
  const showSummary = !showPrompt;
  return isProcessing ? (
    <CenteredGreedy>
      <LoadingSpinner actionName="Processing" />
    </CenteredGreedy>
  ) : (
    <>
      <Dropzone
        onDrop={onFilesSelected}
        allowedFileTypes={allowedFileTypes}
        disabled={disabled}
      />

      {showSummary && (
        <FileSummaryList
          onActionClick={onDismissRejected}
          rejectedFiles={rejected}
          acceptedFiles={accepted}
          allowedFileTypes={allowedFileTypes}
          maxSize={GLOBAL_MAX_FILE_SIZE}
        />
      )}
    </>
  );
};
