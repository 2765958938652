import React from "react";

import { Formik } from "formik";
import { isEmpty } from "lodash";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";

import { clearLoginFailure, requestLogin } from "actions";

import { LoginCard } from "components/Layout/Login";

import { appUpdateStatus } from "constants/system";

import { getAppUpdateStatus, getIsFetchingAuth } from "selectors";

import { useMountEffect } from "hooks";
import { checkForSWUpdates } from "registerServiceWorker";

import InnerForm from "./InnerForm";

const initialValues = {
  email: "",
  password: "",
};

function Login() {
  const dispatch = useDispatch();

  const isFetching = useSelector(getIsFetchingAuth);
  const loginError = useSelector(
    state => state.auth.authentication.errorMessage,
  );
  const currentAppUpdateStatus = useSelector(getAppUpdateStatus);

  function checkForUpdates() {
    if (navigator.serviceWorker?.getRegistrations()) {
      checkForSWUpdates();
    }
  }
  function validate(values) {
    const errors = {};
    if (!values.email) {
      errors.email = "Email address is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    // We're being asked to validate the form again - it's changed - clear out the
    // server response data
    if (!isEmpty(loginError)) {
      dispatch(clearLoginFailure());
    }
    return errors;
  }

  useMountEffect(() => {
    checkForUpdates();
    dispatch(clearLoginFailure());
  });

  function handleSubmit(values) {
    const query = queryString.parse(window.location.search);
    dispatch(requestLogin(values, query?.returnTo));
  }

  const updateRequired = [
    appUpdateStatus.LOGOUT_REQUIRED,
    appUpdateStatus.REFRESH_REQUIRED,
  ].includes(currentAppUpdateStatus);

  const disableButton = isFetching || updateRequired;

  return (
    <LoginCard showForgotten disableLoginButton={disableButton}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <InnerForm loginError={loginError} disableButton={disableButton} />
      </Formik>
    </LoginCard>
  );
}

export default Login;
