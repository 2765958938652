import React from "react";

import { useSelector } from "react-redux";

import AgGrid from "components/AgGrid/AgGrid";

import { getNlisMessageGroups } from "selectors";

const colDef = [
  {
    headerName: "EID",
    field: "EID",
    minWidth: 80,
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 40,
  },
  {
    headerName: "Text",
    field: "text",
  },
  {
    headerName: "Action",
    field: "action",
  },
];

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  floatingFilter: true,
};

export const SightingsGrid = ({ sightings }) => {
  const onGridReady = agGridInstance => {
    // Auto size the columns when the grid is ready.
    agGridInstance.columnApi.autoSizeAllColumns(false);
  };

  const nlisMessageGroups = useSelector(getNlisMessageGroups);
  const rowData = React.useMemo(() => {
    return sightings.map(s => ({
      ...s,
      ...nlisMessageGroups[s.messageGroupId],
    }));
  }, [nlisMessageGroups, sightings]);

  return (
    <AgGrid
      rowData={rowData}
      columnDefs={colDef}
      pagination
      paginationPageSize={100}
      defaultColDef={defaultColDef}
      enableCellTextSelection
      onGridReady={onGridReady}
      suppressColumnVirtualisation
    />
  );
};
