import { sumBy } from "lodash";
import { createSelector } from "reselect";

import {
  ConsignmentWarnings,
  SaleLotException,
  SaleLotWarning,
  WarningLevel,
} from "constants/sale";
import { Species } from "constants/species";
import { userTypes } from "constants/users";

import { EMPTY_ARRAY } from "lib";

import { getLivestockSaleId, getSaleyardName } from "lib/navigation";

import {
  getCurrentSale,
  getActiveRole,
  getBusinesses,
  getSaleLots,
  selectDeploymentIdBySaleLotIdLookup,
  selectHasUnregisteredEidsBySaleLotIdLookup,
  selectAreWeightAndDressingFeaturesEnabled,
  getVendorSplitSaleLots,
  getVendorSplitConsignments,
  getConsignments,
  selectSingleWeighWeightBySaleLotIdLookup,
  selectCurrentDeploymentSaleIdsList,
  selectSaleLotIdsByDeploymentSaleIdLookup,
  selectConsignmentIdsByDeploymentSaleIdLookup,
  selectIsScanRateCompliantByConsignmentIdLookup,
  selectSaleyardScanEidsByConsignmentIdLookup,
  selectHasUnregisteredEidsByConsignmentIdLookup,
  selectDeploymentByDeploymentSaleIdLookup,
} from "selectors";

import { selectValidationOverridesByDeploymentIdLookup } from "selectors/validations";

const isWarning = (validation, saleLot) =>
  validation &&
  [WarningLevel.WARNING, WarningLevel.WARNING_AND_BLOCK].includes(
    validation.validationLevel,
  ) &&
  saleLot.quantity > validation.headThreshold;

export const selectHasAgeWarningBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    getCurrentSale,
    selectValidationOverridesByDeploymentIdLookup,
  ],
  (saleLots, deploymentIdBySaleLotId, currentSale, deploymentValidations) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      const isCattleSale = currentSale.species_id === Species.CATTLE;
      acc[saleLotId] =
        isCattleSale &&
        isWarning(validations?.missingAge, saleLot) &&
        !saleLot.age_id;
      return acc;
    }, {}),
);

export const selectHasBreedWarningBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
  ],
  (saleLots, deploymentIdBySaleLotId, deploymentValidations) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      acc[saleLotId] =
        isWarning(validations?.missingBreed, saleLot) && !saleLot.breed_id;
      return acc;
    }, {}),
);

export const selectHasSexWarningBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
  ],
  (saleLots, deploymentIdBySaleLotId, deploymentValidations) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      acc[saleLotId] =
        isWarning(validations?.missingSex, saleLot) && !saleLot.sex_id;
      return acc;
    }, {}),
);

export const selectHasVendorSplitsQuantityWarningBySaleLotId = createSelector(
  [getSaleLots, getVendorSplitSaleLots],
  (saleLots, vendorSplitLots) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const saleLotVendorSplits = Object.values(vendorSplitLots).filter(
        sl => sl.parentId === saleLotId,
      );
      const saleLotVendorSplitsQuantity = sumBy(
        saleLotVendorSplits,
        "quantity",
      );
      acc[saleLotId] =
        saleLotVendorSplits.length > 0 &&
        saleLot.quantity !== saleLotVendorSplitsQuantity;
      return acc;
    }, {}),
);

export const selectHasVendorSplitsMissingVendorWarningBySaleLotId =
  createSelector(
    [
      getSaleLots,
      getConsignments,
      getVendorSplitSaleLots,
      getVendorSplitConsignments,
    ],
    (saleLots, consignments, vendorSplitLots, vendorSplitConsignments) =>
      Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
        const saleLotVendorSplits = Object.values(vendorSplitLots).filter(
          sl => sl.parentId === saleLotId,
        );
        const vendorSplitLotsVendorIds = saleLotVendorSplits.map(
          svs => vendorSplitConsignments[svs.consignment_id]?.vendor_id,
        );

        acc[saleLotId] =
          saleLotVendorSplits.length > 0 &&
          !vendorSplitLotsVendorIds.includes(
            consignments[saleLot.consignment_id].vendor_id,
          );
        return acc;
      }, {}),
  );

export const selectHasWeightRangeWarningBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
    getCurrentSale,
    selectAreWeightAndDressingFeaturesEnabled,
  ],
  (
    saleLots,
    deploymentIdBySaleLotId,
    deploymentValidations,
    sale,
    featureEnabled,
  ) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      const isSheepSale = sale.species_id === Species.SHEEP;
      acc[saleLotId] =
        isWarning(validations?.missingEstimatedWeightRange, saleLot) &&
        !saleLot.estimatedAverageWeightId &&
        saleLot.quantity !== 1 &&
        isSheepSale &&
        featureEnabled;
      return acc;
    }, {}),
);

export const getHasWeightRangeWarningBySaleLotId = lotId => state =>
  selectHasWeightRangeWarningBySaleLotId(state)[lotId];

export const selectHasDentitionWarningBySaleLotId = createSelector(
  [
    getSaleLots,
    selectDeploymentIdBySaleLotIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
  ],
  (saleLots, deploymentIdBySaleLotId, deploymentValidations) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const deploymentId = deploymentIdBySaleLotId[saleLotId];
      const validations = deploymentValidations[deploymentId];
      acc[saleLotId] =
        isWarning(validations?.missingDentition, saleLot) &&
        !saleLot.dentitionId;
      return acc;
    }, {}),
);

export const getHasDentitionWarningBySaleLotId = lotId => state =>
  selectHasDentitionWarningBySaleLotId(state)[lotId];

/**
 * Returns a List of Sale Lot Warnings, keyed by Sale Lot Id
 * Requires:
 *  - `Businesses`
 *  - `Consignments`
 *  - `DeploymentSales`
 *  - `Sale Lots`
 *  - `Properties`
 *  - `Scans`
 * @type {function(state): Object<string, Array<string>>}
 */
export const selectWarningsBySaleLotIdLookup = createSelector(
  [
    getSaleyardName,
    getSaleLots,
    getBusinesses,
    selectSingleWeighWeightBySaleLotIdLookup,
    getActiveRole,
    selectHasUnregisteredEidsBySaleLotIdLookup,
    selectHasSexWarningBySaleLotId,
    selectHasBreedWarningBySaleLotId,
    selectHasAgeWarningBySaleLotId,
    selectHasWeightRangeWarningBySaleLotId,
    selectHasDentitionWarningBySaleLotId,
    selectHasVendorSplitsQuantityWarningBySaleLotId,
    selectHasVendorSplitsMissingVendorWarningBySaleLotId,
  ],
  (
    saleyardName,
    saleLots,
    businesses,
    singleWeighWeight,
    role,
    hasUnregisteredEidBySaleLotId,
    hasSexWarningBySaleLotId,
    hasBreedWarningBySaleLotId,
    hasAgeWarningBySaleLotId,
    hasWeightRangeWarningBySaleLotId,
    hasDentitionWarningBySaleLotId,
    hasVendorSplitsQuantityWarningBySaleLotId,
    hasVendorSplitsMissingVendorWarningBySaleLotId,
  ) =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const warningsList = [];
      if (
        hasVendorSplitsQuantityWarningBySaleLotId[saleLotId] &&
        role.type === userTypes.LIVESTOCK_AGENT
      ) {
        warningsList.push(SaleLotWarning.VENDOR_SPLIT_LOTS_UNBALANCED);
      }

      if (
        hasVendorSplitsMissingVendorWarningBySaleLotId[saleLotId] &&
        role.type === userTypes.LIVESTOCK_AGENT
      ) {
        warningsList.push(
          SaleLotWarning.PRIMARY_VENDOR_IS_NOT_PART_OF_VENDOR_SPLIT_LOTS,
        );
      }

      if (hasSexWarningBySaleLotId[saleLotId]) {
        warningsList.push(SaleLotWarning.NO_SEX);
      }
      if (
        hasBreedWarningBySaleLotId[saleLotId] &&
        role.type === userTypes.LIVESTOCK_AGENT
      ) {
        warningsList.push(SaleLotWarning.NO_BREED);
      }
      if (hasAgeWarningBySaleLotId[saleLotId]) {
        warningsList.push(SaleLotWarning.NO_AGE);
      }
      if (
        hasDentitionWarningBySaleLotId[saleLotId] &&
        role.type === userTypes.LIVESTOCK_AGENT
      ) {
        warningsList.push(SaleLotWarning.NO_DENTITION);
      }
      if (
        hasWeightRangeWarningBySaleLotId[saleLotId] &&
        role.type === userTypes.LIVESTOCK_AGENT
      ) {
        warningsList.push(SaleLotWarning.NO_WEIGHT_RANGE);
      }

      if (
        saleLot.buyer_id &&
        businesses[saleLot.buyer_id] &&
        businesses[saleLot.buyer_id].emailRecipients.length === 0 &&
        businesses[saleLot.buyer_id].businessUsers.filter(bu =>
          bu.saleYards.includes(saleyardName),
        ).length === 0
      ) {
        warningsList.push(SaleLotWarning.NO_RECIPIENTS);
      }

      if (saleLot.thirdPartyId) {
        const businessId = saleLot.thirdPartyId;
        const { emailRecipients = [], businessUsers = [] } =
          businesses[businessId] || {};
        if (
          businessUsers.filter(businessUser =>
            businessUser.saleYards.includes(saleyardName),
          ).length === 0 &&
          emailRecipients.length === 0
        ) {
          warningsList.push(SaleLotWarning.THIRD_PARTY_EMAIL_MISSING);
        }
      }

      const totalSingleWeighedWeight = singleWeighWeight[saleLotId];
      const saleLotWeight = saleLot.total_mass_grams;
      if (
        totalSingleWeighedWeight > 0 &&
        totalSingleWeighedWeight !== saleLotWeight &&
        role.type === userTypes.SALEYARD_ADMIN
      ) {
        warningsList.push(SaleLotWarning.SINGLE_WEIGH_SUM);
      }

      if (hasUnregisteredEidBySaleLotId[saleLotId]) {
        warningsList.push(SaleLotException.UNREGISTERED_EID);
      }

      acc[saleLotId] = warningsList;

      return acc;
    }, {}),
);

/**
 * Returns a list of Sale Lot Exceptions, and the associated Sale Lot Id for each applicable Sale Lot, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Properties`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<{ saleLotId: string, exceptions: Array<string> }>>}
 */
export const selectSaleLotWarningsByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    selectSaleLotIdsByDeploymentSaleIdLookup,
    selectWarningsBySaleLotIdLookup,
  ],
  (deploymentSaleIds, saleLotIdsByDeploymentSale, warningsBySaleLotId) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      const saleLotIds = saleLotIdsByDeploymentSale[deploymentSaleId] || [];

      const saleLotWarningsList = saleLotIds.reduce((acc, saleLotId) => {
        const warnings = warningsBySaleLotId[saleLotId];
        if (warnings.length > 0) {
          acc.push({ saleLotId, warnings });
        }
        return acc;
      }, []);

      acc[deploymentSaleId] =
        saleLotWarningsList.length > 0 ? saleLotWarningsList : EMPTY_ARRAY;
      return acc;
    }, {}),
);

/**
 * Returns a List of Consignment Warnings, keyed by Consignment Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 * @type {function(state): Object<string, Array<string>>}
 */
export const selectWarningsByConsignmentIdLookup = createSelector(
  [
    getConsignments,
    state => getCurrentSale(state).species_id,
    selectIsScanRateCompliantByConsignmentIdLookup,
    selectSaleyardScanEidsByConsignmentIdLookup,
    selectHasUnregisteredEidsByConsignmentIdLookup,
  ],
  (
    consignments,
    speciesId,
    isScanRateCompliantByConsignmentId,
    saleyardScanEidsByConsignmentIdLookup,
    hasUnregisteredEidsByConsignmentId,
  ) =>
    Object.entries(consignments).reduce((acc, [consignmentId, consignment]) => {
      const warningsList = [];

      const nvdQuantity = consignment.quantity_NVD;
      if (
        typeof nvdQuantity === "number" &&
        consignment.quantity !== nvdQuantity
      ) {
        warningsList.push(ConsignmentWarnings.NVD_HC_MISMATCH);
      }

      if (speciesId !== Species.CATTLE) {
        // Scan Rate Compliance is an exception for Cattle, but a warning for other species
        if (!isScanRateCompliantByConsignmentId[consignmentId]) {
          warningsList.push(ConsignmentWarnings.SCAN_RATE_COMPLIANCE);
        }
      }

      const saleyardScans =
        saleyardScanEidsByConsignmentIdLookup[consignmentId];

      // The selector above sometimes returns `[undefined]`
      const hasSaleyardScans =
        saleyardScans?.length > 0 && saleyardScans.some(s => s);

      if (hasSaleyardScans) {
        warningsList.push(ConsignmentWarnings.CONSIGNMENT_SCANS_FOUND);
      }

      if (hasUnregisteredEidsByConsignmentId[consignmentId]) {
        warningsList.push(ConsignmentWarnings.UNREGISTERED_EID);
      }

      acc[consignmentId] = warningsList.length > 0 ? warningsList : EMPTY_ARRAY;
      return acc;
    }, {}),
);

/**
 * Returns a list of Consignment Warnings, and the associated Consignment Id for each applicable Consignment, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 * @type {function(state): Object<string, Array<{ consignmentId: string, exceptions: Array<string> }>>}
 */
export const selectConsignmentWarningsByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    selectConsignmentIdsByDeploymentSaleIdLookup,
    selectWarningsByConsignmentIdLookup,
  ],
  (
    deploymentSaleIds,
    consignmentIdsByDeploymentSaleId,
    warningsByConsignmentId,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      const consignmentIds =
        consignmentIdsByDeploymentSaleId[deploymentSaleId] || [];

      const warningsByConsignmentList = consignmentIds.reduce(
        (acc, consignmentId) => {
          const warnings = warningsByConsignmentId[consignmentId];
          if (warnings.length > 0) {
            acc.push({ consignmentId, warnings });
          }
          return acc;
        },
        [],
      );

      acc[deploymentSaleId] =
        warningsByConsignmentList.length > 0
          ? warningsByConsignmentList
          : EMPTY_ARRAY;

      return acc;
    }, {}),
);

export const getConsignmentWarningsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectConsignmentWarningsByDeploymentSaleIdLookup(
      state,
      getLivestockSaleId(),
    )[deploymentSaleId];

export const getSaleLotWarningsByDeploymentSaleId = deploymentSaleId => state =>
  selectSaleLotWarningsByDeploymentSaleIdLookup(state, getLivestockSaleId())[
    deploymentSaleId
  ];

export const getHasConsignmentWarningsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectConsignmentWarningsByDeploymentSaleIdLookup(
      state,
      getLivestockSaleId(),
    )[deploymentSaleId].length > 0;

export const getHasSaleLotWarningsByDeploymentSaleId =
  deploymentSaleId => state =>
    selectSaleLotWarningsByDeploymentSaleIdLookup(state, getLivestockSaleId())[
      deploymentSaleId
    ].length > 0;

export const getHasWarningsByDeploymentSaleId = deploymentSaleId => state =>
  getHasConsignmentWarningsByDeploymentSaleId(deploymentSaleId)(state) ||
  getHasSaleLotWarningsByDeploymentSaleId(deploymentSaleId)(state);

export const selectWarningsForEqxDataByDeploymentSaleId = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    selectSaleLotWarningsByDeploymentSaleIdLookup,
    selectDeploymentByDeploymentSaleIdLookup,
    selectValidationOverridesByDeploymentIdLookup,
  ],
  (
    deploymentSaleIds,
    lotWarningsByDeploymentSaleId,
    deploymentByDeploymentSaleId,
    deploymentValidationsByDeploymentId,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      const deployment = deploymentByDeploymentSaleId[deploymentSaleId];
      const validations =
        deploymentValidationsByDeploymentId[deployment?.id] || [];
      const lotWarnings = lotWarningsByDeploymentSaleId[deploymentSaleId];

      const hasBlockedDentitionWarning =
        validations?.missingDentition?.validationLevel ===
          WarningLevel.WARNING_AND_BLOCK &&
        lotWarnings.find(lotWarning =>
          lotWarning.warnings?.includes(SaleLotWarning.NO_DENTITION),
        );

      const hasBlockedWeightRangeWarning =
        validations?.missingEstimatedWeightRange?.validationLevel ===
          WarningLevel.WARNING_AND_BLOCK &&
        lotWarnings.find(lotWarning =>
          lotWarning.warnings?.includes(SaleLotWarning.NO_WEIGHT_RANGE),
        );
      const warnings = [];
      if (hasBlockedDentitionWarning) {
        warnings.push("Dentition");
      }
      if (hasBlockedWeightRangeWarning) {
        warnings.push("Est Avg Weight");
      }
      acc[deploymentSaleId] = warnings;
      return acc;
    }, {}),
);
