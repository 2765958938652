import { useSelector } from "react-redux";

import { SaleTypeVocabulary } from "constants/sale";

import { getCurrentSale } from "selectors";

const lookupSaleTypeVocabulary = (saleType, text) => {
  return SaleTypeVocabulary[saleType]?.[text] || text;
};

export const useTranslatedSaleTypeText = (label, selectedSaleType = null) => {
  const currentSaleType = useSelector(
    state => getCurrentSale(state)?.sale_type || null,
  );
  const saleType = selectedSaleType || currentSaleType;

  if (saleType === null) {
    throw new Error(
      'You must provide a "selectedSaleType" argument when using this hook outside of a Livestock Sale',
    );
  }
  return saleType === null ? null : lookupSaleTypeVocabulary(saleType, label);
};
