import React, { memo } from "react";

import { has } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { connectToHub, updateAvailableDevices } from "actions";

import { receiveTlabAutoDraftPlcDraftingQuery } from "actions/deviceDrivers/tlabAutoDraftPlc";

import { getAvailableDevices, getIsHubConnected } from "selectors";

import SingleWeighControls from "./SingleWeighControlScreen";
import { SingleWeigh } from "./SingleWeighSelector";

function SingleWeighRoutes() {
  const match = useRouteMatch();

  const availableDevices = useSelector(getAvailableDevices);
  const isHubConnected = useSelector(getIsHubConnected);

  const dispatch = useDispatch();

  // Connect to hub, then  refresh the list of all available devices
  React.useEffect(() => {
    if (!isHubConnected) {
      dispatch(connectToHub());
    } else if (availableDevices.length === 0) {
      dispatch(updateAvailableDevices());
    }

    // In the real world, when connected to a PLC, the PLC will send through the
    // handshake every three seconds on connection until a handshake has been completed,
    // or  if the users press the reset button on the pendant (which hard resets the
    // device - we assume) and drafting query request will be sent every time the entry
    // gate is closed.
    // When connected to the hub simulator, this needs to be triggered.
    const connectedPlcSimulator = availableDevices.find(
      d => d.deviceId === "tlab-autodraft-plc0://123456789",
    );
    if (
      connectedPlcSimulator &&
      !has(connectedPlcSimulator, "protocolStatus")
    ) {
      dispatch(
        receiveTlabAutoDraftPlcDraftingQuery(connectedPlcSimulator.deviceId),
      );
    }
  }, [availableDevices, dispatch, isHubConnected]);
  return (
    <Switch>
      <Route
        path={`${match.path}/:singleWeighId(\\d+)`}
        component={SingleWeighControls}
      />
      <Route component={SingleWeigh} />
    </Switch>
  );
}

export default memo(SingleWeighRoutes);
