import React, { useState } from "react";

import { DialogActions } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Fuse from "fuse.js";
import { useDispatch, useSelector } from "react-redux";

import { ScanAction } from "actions";

import { Warning } from "components/ErrorMessage";
import { Button, SearchInput, SecondaryButton } from "components/Form";
import { Row } from "components/Layout";
import { DialogContent } from "components/MaterialDialog";
import { Paper } from "components/Paper";
import { StatusWrapper } from "components/ScanningPenCards/Card/elements";
import { LotRow } from "components/ScanningPenCards/LotCard/Row";

import { PenTypes, ScanningPenStatusColourMap } from "constants/auctionPens";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { getLivestockSaleId } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import {
  getAuctionPens,
  getBusinesses,
  getConsignments,
  getReceivalLots,
  getScans,
  selectOrderedReceivalLotIds,
  selectStatusByReceivalPenIdLookup,
} from "selectors";

export const SelectReceivalLotForEids = ({ eids, onBack, onClose }) => {
  const [selectedReceivalLotId, setSelectedReceivalLotId] = useState(null);

  const receivalLotById = useSelector(getReceivalLots);
  const receivalLotIdOrder = useSelector(selectOrderedReceivalLotIds);
  const penById = useSelector(getAuctionPens);
  const scanByEid = useSelector(getScans);
  const consignmentById = useSelector(getConsignments);
  const businessById = useSelector(getBusinesses);
  const statusByReceivalPenId = useSelector(selectStatusByReceivalPenIdLookup);

  const receivalLotsUsed = new Set(
    eids.map(eid => scanByEid[eid]?.receival_lot_id).filter(Boolean),
  );
  const [searchText, setSearchText] = useState("");
  const [orderedReceivalLotIds, setOrderedReceivalLotIds] = useState([]);

  const dispatch = useDispatch();
  const updateReceivalLot = () => {
    const scans = eids.map(eid => ({
      EID: eid,
      livestock_sale_id: getLivestockSaleId(),
      receival_lot_id: selectedReceivalLotId,
    }));

    if (scans.length > 0) {
      dispatch(ScanAction.create(scans));
    }
    onClose();
  };

  const searchValues = React.useMemo(
    () =>
      Object.values(receivalLotById).map(receivalLot => {
        const consignment = consignmentById[receivalLot.consignmentId] || {};
        const vendor = businessById[consignment.vendor_id] || {};
        const pen = penById[receivalLot.receivalPenId] || {};
        return {
          id: receivalLot.id,
          penName: pen.start_pen,
          nvd: consignment.NVD || "",
          vendorName: vendor.name || "",
          vendorAgentCode: vendor.shortCode || "",
          vendorSaleyardCode: vendor.shortCodeSaleyard || "",
        };
      }),
    [consignmentById, businessById, penById, receivalLotById],
  );

  const searchIndex = React.useMemo(
    () =>
      new Fuse(searchValues, {
        threshold: 0.1,
        findAllMatches: true,
        keys: [
          {
            name: "penName",
            weight: 4,
          },
          {
            name: "vendorName",
            weight: 2,
          },
          {
            name: "vendorSaleyardCode",
            weight: 2,
          },
          "vendorAgentCode",
        ],
      }),
    [searchValues],
  );

  React.useEffect(() => {
    Promise.resolve().then(() =>
      setOrderedReceivalLotIds(
        (searchText
          ? searchIndex.search(searchText).map(fuseResult => fuseResult.item)
          : Object.values(receivalLotById).sort(
              (a, b) =>
                receivalLotIdOrder.indexOf(a.id) -
                receivalLotIdOrder.indexOf(b.id),
            )
        ).map(receivalLot => receivalLot.id),
      ),
    );
  }, [
    receivalLotById,
    receivalLotIdOrder,
    searchIndex,
    searchText,
    setOrderedReceivalLotIds,
  ]);

  const scansPlural = pluralize("scans", eids.length);
  return (
    <>
      <DialogContent minHeight="20vh" shrink={1} dividers>
        <DialogContentText>
          Tap to select which Receival Lot to move the selected {scansPlural}{" "}
          to.
        </DialogContentText>
        {receivalLotsUsed.size > 1 && (
          <Warning>
            Warning: These EIDs are currently assigned to multiple Receival Lots
            and will be assigned to the single, selected Receival Lot after this
            operation.
          </Warning>
        )}
        <SearchInput onChange={setSearchText} />
        {orderedReceivalLotIds.map((receivalLotId, index) => {
          const { receivalPenId } = receivalLotById[receivalLotId];
          const penStatus = statusByReceivalPenId[receivalPenId];
          const receivalPen = penById[receivalPenId];

          return (
            <StatusWrapper
              color={ScanningPenStatusColourMap[penStatus]}
              boxShadow={2}
            >
              <Paper backgroundColor="white">
                <Row justifyBetween alignStretch>
                  <h2>{getAuctionPenDisplayName(receivalPen)}</h2>

                  <Row
                    marginHorizontal={1}
                    padding={1}
                    justifyBetween
                    flexWrap
                    flexGrow
                    background="gray95"
                    width="60%"
                    className="cursor-pointer"
                  >
                    <LotRow
                      key={`${receivalLotId}_${index}`}
                      scanLotId={receivalLotId}
                      selected={receivalLotId === selectedReceivalLotId}
                      penType={PenTypes.RECEIVING}
                      showExtraDetails
                      isCreate={false}
                      onClickAlt={setSelectedReceivalLotId}
                      showActions={false}
                    />
                  </Row>
                </Row>
              </Paper>
            </StatusWrapper>
          );
        })}
      </DialogContent>
      <DialogActions shrink={0}>
        {typeof onBack === "function" ? (
          <SecondaryButton onClick={onBack}>Back</SecondaryButton>
        ) : null}
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <Button
          onClick={updateReceivalLot}
          disabled={selectedReceivalLotId === null}
        >
          Move to Receival Lot
        </Button>
      </DialogActions>
    </>
  );
};
