import React from "react";

import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { useField } from "formik";

import { withArrayNamespace } from "components/Form/FormikControls";

import { TitledRule } from "containers/Settings/RuleBooks/Rules/Layout";

import { CheckGroup, getCheckTemplate } from "./CheckGroup";

export function getCheckGroupTemplate() {
  return [getCheckTemplate()];
}

export function CheckGroups(props) {
  const { criteriaOptions, namespace: ns, parentFieldId } = props;
  const [
    { value: checkGroups },
    // eslint-disable-next-line no-unused-vars
    ignored,
    { setValue },
  ] = useField(ns);

  function onClickAddGroup() {
    setValue([...checkGroups, getCheckGroupTemplate()]);
  }

  function onRemoveGroup(index) {
    const newValue = checkGroups.slice();
    newValue.splice(index, 1);
    setValue(newValue);
  }

  return (
    <>
      {checkGroups.map((group, index) => (
        <div key={index}>
          {index > 0 && (
            <TitledRule>
              OR
              <IconButton
                aria-label="delete"
                onClick={() => onRemoveGroup(index)}
              >
                <FontAwesomeIcon icon={faTrash} size="xs" />
              </IconButton>
            </TitledRule>
          )}
          <CheckGroup
            key={index}
            criteriaOptions={criteriaOptions}
            namespace={withArrayNamespace(ns, index)}
            onRemoveLastCondition={() => {
              onRemoveGroup(index);
            }}
            parentFieldId={parentFieldId}
          />
        </div>
      ))}
      <IconButton aria-label="Add Group" onClick={onClickAddGroup} size="small">
        <FontAwesomeIcon icon={faPlus} size="xs" />
        &nbsp;Group
      </IconButton>
    </>
  );
}
