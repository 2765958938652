import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const splitsMap = {
  businessId: "business_id",
  amount: "amount",
};

export const vendorSplitSerializerMap = {
  id: "id",
  consignmentId: "consignment_id",
  livestockSaleId: "livestock_sale_id",
  deploymentId: "deployment_id",
  includeFees: "include_fees",
  isActive: "is_active",
  isDefault: "is_default",
  primaryBusinessId: "primary_business_id",
  saleLotId: "sale_lot_id",
  type: "type",
  splits: splitsMap,
};

export const serializeVendorSplit = buildSerializer(
  vendorSplitSerializerMap,
  "vendorSplit",
  { splits: "splits" },
);

export const deserializeVendorSplit = buildDeserializer(
  vendorSplitSerializerMap,
  "vendorSplit",
  { splits: "splits" },
);
