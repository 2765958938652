import React from "react";

import { ListItemIcon } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelector } from "react-redux";

import {
  Label,
  StyledCheckbox,
  StyledInput,
} from "components/Form/FormikControls";
import { EmailRecipientIconList } from "components/Icons/EmailRecipientIconList";
import { Column, Row } from "components/Layout";

import { getBillingDocumentsAggridDataById } from "selectors";

export const RecipientList = React.memo(
  ({ billingDocumentId, toggleRecipientAction, toggleRecipientState }) => {
    const { billingDocument, business } = useSelector(
      getBillingDocumentsAggridDataById(billingDocumentId),
    );
    // Flatten the email recipients into just their ids.
    // (We need it to be not be flat because we need the source (Deployment or Saleyard) aswell as the id.)
    const selectedEmailRecipientIds =
      toggleRecipientState.emailRecipients?.map(er => er.id) || [];

    const selectedBusinessUserIds =
      toggleRecipientState.businessUsers?.map(er => er.id) || [];

    return (
      <React.Fragment key={billingDocumentId}>
        <h1>
          {billingDocument.type}
          {billingDocument.number} - {business.name}
        </h1>

        <List>
          {business.businessUsers?.map(bu => {
            const checked = selectedBusinessUserIds.includes(bu.id);
            const onClick = () =>
              toggleRecipientAction({
                billingDocumentId,
                businessUser: bu,
              });

            const labelId = `checkbox-list-label-${billingDocumentId}`;

            const recipientText = (
              <Row alignCenter justifyBetween>
                {bu.firstName} {bu.lastName}: {bu.email}{" "}
                <EmailRecipientIconList recipient={bu} />
              </Row>
            );

            return (
              <ListItem
                key={bu.id}
                dense
                disableGutters
                button
                onClick={onClick}
              >
                <ListItemIcon>
                  <StyledCheckbox
                    edge="start"
                    checked={!!checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={recipientText} />
              </ListItem>
            );
          })}
        </List>

        <List>
          {business.emailRecipients?.map(er => {
            const checked = selectedEmailRecipientIds.includes(er.id);
            const onClick = () => {
              toggleRecipientAction({
                billingDocumentId,
                emailRecipient: er,
              });
            };

            const labelId = `checkbox-list-label-${billingDocumentId}`;
            const recipientText = (
              <Row alignCenter justifyBetween>
                {er.firstName} {er.lastName}: {er.email}{" "}
                <EmailRecipientIconList recipient={er} />
              </Row>
            );

            return (
              <ListItem
                key={er.id}
                dense
                disableGutters
                button
                onClick={onClick}
              >
                <ListItemIcon>
                  <StyledCheckbox
                    edge="start"
                    checked={!!checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={recipientText} />
              </ListItem>
            );
          })}
        </List>
        <Column>
          <Label tooltip="Send to specific email address.">Email address</Label>
          <StyledInput
            type="email"
            onChange={e =>
              toggleRecipientAction({
                billingDocumentId,
                rawEmailAddress: e.target.value,
              })
            }
          />
        </Column>
        <hr />
      </React.Fragment>
    );
  },
);
