import { useState } from "react";

import { useDispatch } from "react-redux";

import { requestTestRuleBook } from "actions";

import { PriceComponentsName } from "constants/billingLedgerEntries";

import toast from "lib/toast";

const deserializeRawLedgerEntry = rawLedgerEntry => ({
  billingTags: rawLedgerEntry.billing_tags,
  fromBusinessId: rawLedgerEntry.from_business,
  glCode: rawLedgerEntry.gl_code,
  id: rawLedgerEntry.id,
  invoiceLineItem: rawLedgerEntry.invoice_line_item,
  quantity: rawLedgerEntry.quantity,
  referenceObject: rawLedgerEntry.reference_object,
  ruleName: rawLedgerEntry.rule_name,
  subtotal: rawLedgerEntry.price_components.find(
    pc => pc.name === PriceComponentsName.Subtotal,
  )?.value,
  tagIds: rawLedgerEntry.billing_tags, // ref lib.serializers.ledgerEntries.js serializeMap
  taxAmount: rawLedgerEntry.price_components.find(
    pc => pc.name === PriceComponentsName.GST,
  )?.value,
  taxType: rawLedgerEntry.tax_type,
  title: rawLedgerEntry.title,
  toBusinessId: rawLedgerEntry.to_business,
  unitAmount: rawLedgerEntry.price_cents,
  ruleId: rawLedgerEntry.rule_id,
  itemId: rawLedgerEntry.item_id,
  itemType: rawLedgerEntry.item_type,
});

const deserializeResults = result => ({
  ledgerEntries: result.ledger_entries.map(deserializeRawLedgerEntry),
  saleData: result.sale_data,
  rulebook: result.rulebook,
});

export function useRuleTester() {
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  function onTestRules(
    livestockSaleIds,
    ruleBookId,
    ruleBookData,
    onSuccess,
    onError,
  ) {
    dispatch(
      requestTestRuleBook(livestockSaleIds, ruleBookId, ruleBookData, {
        onSuccess,
        onError,
      }),
    );
  }

  function testRules(livestockSaleIds, ruleBookId, ruleBookData) {
    onTestRules(
      livestockSaleIds,
      ruleBookId,
      ruleBookData,

      results => {
        setResults(deserializeResults(results));
        setIsLoading(false);
      },
      () => {
        toast.error("An error occurred testing the Rule Books.");
        setIsLoading(false);
      },
    );
    setIsLoading(true);
  }

  return [results, isLoading, testRules];
}
