import React from "react";

import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";

import { ClickableColumn } from "lib/agGrid/columns/columnStyles";
import { openSale } from "lib/navigation";

export function SaleRenderer({ data = {}, colDef }) {
  if (data) {
    const sales = get(data, colDef.namespace, []).filter(Boolean);
    return sales.map(sale => (
      <ClickableColumn onClick={() => openSale(sale)}>
        {sale.sale_code}
        <FontAwesomeIcon icon={faPencil} />
      </ClickableColumn>
    ));
  }
}
