import React from "react";

import { MergeSalelotModal } from "components/MergeSaleLotModal";

import { ModalTypes } from "constants/navigation";

function MergeSaleLotModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.MergeSaleLot] || "";
  const [saleLotId] = hashParams.split(",");
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <MergeSalelotModal
      returnTo={decodedReturnTo}
      saleLotId={saleLotId}
      {...props}
    />
  );
}

export default MergeSaleLotModalAdapter;
