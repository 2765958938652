import { useEffect, useState } from "react";

function DeferredRender({
  children,
  deferRenderBy = null,
  placeHolder = null,
}) {
  const [render, setRender] = useState(deferRenderBy === null);

  useEffect(() => {
    if (deferRenderBy) {
      const toId = setTimeout(() => {
        setRender(true);
      }, deferRenderBy);
      return () => {
        clearTimeout(toId);
      };
    }
  }, [deferRenderBy]);

  // If we're re-rendering without changes, don't delay - do the render.
  useEffect(() => {
    if (!deferRenderBy && !render) {
      setRender(true);
    }
  }, [setRender, render, deferRenderBy]);

  if (!render) {
    return placeHolder;
  }

  return children;
}

export default DeferredRender;
