import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { requeryABNForBusiness } from "actions";

import { SlimButton } from "components/Button";
import { GSTField } from "components/Form/Fields";
import {
  Input,
  OptionTogglerField,
  withNamespace,
} from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { DeploymentPermissions } from "constants/permissions";

import { getSaleyardName } from "lib/navigation";
import { formatDateTimeUTC } from "lib/timeFormats";

import { useHasDeploymentPermission } from "hooks";

const AbrLastCheckedText = styled.p(
  ({ theme }) => `
        font-size: ${theme.fontSizes.alpha}px;
        margin-left: ${theme.space[2]}px;  
    `,
);

const GSTOverrideOptions = [
  {
    value: null,
    label: "No Override",
  },
  {
    value: true,
    label: "GST",
  },
  {
    value: false,
    label: "No GST",
  },
];

const AbrDetailsFormComponent = ({
  namespace: ns,
  businessId,
  abn,
  abrLastChecked,
}) => {
  const formikProps = useFormikContext();

  const dispatch = useDispatch();
  const { dirty } = formikProps;
  const isEdit = !!businessId;
  const allowCheckAbn = !!abn && !dirty;
  const recheckGstStatus = () => {
    dispatch(requeryABNForBusiness(businessId, getSaleyardName()));
  };

  const hasGSTOverridePermission = useHasDeploymentPermission(
    DeploymentPermissions.featureGSTOverride,
  );

  return (
    <>
      <Grid item xs={6}>
        <Input
          label="ABN"
          name={withNamespace(ns, "abn")}
          type="number"
          tooltip="Adding an ABN will automatically retrieve the GST Status, ACN and Trading Name from the ABR."
          emptyValue={null}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label="ACN"
          name={withNamespace(ns, "acn")}
          type="number"
          disabled
          tooltip="ACN is automatically filled from the ABR after adding an ABN."
        />
      </Grid>
      <Grid item xs={8}>
        <Input
          label="Trading Name"
          name={withNamespace(ns, "tradingName")}
          disabled
          tooltip="Trading Name is automatically filled from the ABR after adding an ABN."
        />
      </Grid>
      <Grid item container xs={4}>
        <GSTField
          name={withNamespace(ns, "isGSTRegistered")}
          abrLastChecked={abrLastChecked}
        />
      </Grid>
      {isEdit && (
        <Grid item xs={12}>
          <Row fullWidth justifyBetween>
            <SlimButton onClick={recheckGstStatus} disabled={!allowCheckAbn}>
              Check GST Status
            </SlimButton>
            <AbrLastCheckedText>
              {abrLastChecked &&
                `Last Checked: ${formatDateTimeUTC(abrLastChecked)}`}
            </AbrLastCheckedText>
            {hasGSTOverridePermission ? (
              <Row>
                <OptionTogglerField
                  name="shouldChargeGSTOverride"
                  label="Manual GST Override"
                  options={GSTOverrideOptions}
                  tooltip="Allows manual override of the business's GST status.   If no override is in place Hobby Farmer and GST Status derived from the ABN are used."
                  labelPosition="top"
                />
              </Row>
            ) : null}
          </Row>
          {!!abn && dirty && (
            <Row fullWidth>
              <p>
                Your GST status will be checked when you submit your changes
              </p>
            </Row>
          )}
        </Grid>
      )}
    </>
  );
};

export default memo(AbrDetailsFormComponent);
