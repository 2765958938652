import React, { useState } from "react";

import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { MasterRuleAction } from "actions/rules";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { Button, SecondaryButton } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { AuditLogTypes } from "constants/auditLog";
import { ModalTypes } from "constants/navigation";

import { convertToExternalRule } from "containers/Settings/RuleBooks/export";
import { convertToInternalRule } from "containers/Settings/RuleBooks/import";
import { RuleForm } from "containers/Settings/RuleBooks/Rules/CustomRules";
import {
  RuleFieldSchema,
  useRuleFieldSchemaGenerator,
} from "containers/Settings/RuleBooks/schemaContext";

import {
  getContextByModalType,
  getMasterRuleById,
  selectBillingTagOptions,
} from "selectors";

function MasterRuleFooter(props) {
  const { onClose } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  useSubmitHandler(true, setIsSubmitEnabled);

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      <Button type="submit" disabled={!isSubmitEnabled}>
        Save
      </Button>
    </>
  );
}

export function MasterRuleModal(props) {
  const { onClose } = props;

  const dispatch = useDispatch();
  const context =
    useSelector(getContextByModalType(ModalTypes.EditMasterRule)) || {};
  const { masterRuleId } = context;

  const billingTags = useSelector(selectBillingTagOptions);

  const masterRule = useSelector(getMasterRuleById(masterRuleId)) || null;

  const getSchema = useRuleFieldSchemaGenerator();
  const schema = getSchema(true);

  function onSubmit(values) {
    const externalRule = convertToExternalRule({ ...values }, schema);

    dispatch(
      MasterRuleAction.update(externalRule, {
        changeReason: "Updated from Master Rule Modal",
      }),
    );

    onClose();
  }

  if (!masterRuleId) {
    return null;
  }

  const initialValues = convertToInternalRule({ ...masterRule }, schema);

  return (
    <Dialog open onClose={onClose} fullScreen>
      <DialogTitle onClose={onClose}>
        <AuditLogLink
          auditLogType={AuditLogTypes.MASTER_RULE}
          dataId={masterRuleId}
          returnTo={window.location.hash}
        />
        &nbsp; Edit Master Rule
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        key={masterRuleId}
      >
        <Form>
          <DialogContent>
            <RuleFieldSchema value={schema}>
              <RuleForm isMasterRule billingTags={billingTags} />
            </RuleFieldSchema>
          </DialogContent>
          <DialogActions>
            <MasterRuleFooter onClose={onClose} />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}
