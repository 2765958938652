export function balancedTriStateFilter(props, selectedValue) {
  const hasdiff = props === 0;
  if (selectedValue < 0) {
    return hasdiff;
  } else if (selectedValue > 0) {
    return !hasdiff;
  } else {
    return true;
  }
}
