import sum from "lodash/sum";
import * as Yup from "yup";

import {
  AdvancedDraftingValidationStates,
  PregStatuses,
} from "constants/draftingAttributes";
import { ExportSites } from "constants/exportSites";
import { Species } from "constants/species";

import { dateTimeStringToDateTime } from "lib/timeFormats";

export const fatScoresAllocation = saleLot =>
  saleLot.quantity -
  sum(
    [
      saleLot.draftingAttributes?.fatScore1,
      saleLot.draftingAttributes?.fatScore2,
      saleLot.draftingAttributes?.fatScore3,
      saleLot.draftingAttributes?.fatScore4,
      saleLot.draftingAttributes?.fatScore5,
      saleLot.draftingAttributes?.fatScore6,
    ].filter(Boolean),
  );
export const muscleScoresAllocation = saleLot =>
  saleLot.quantity -
  sum(
    [
      saleLot.draftingAttributes?.muscleScore1,
      saleLot.draftingAttributes?.muscleScore2,
      saleLot.draftingAttributes?.muscleScore3,
      saleLot.draftingAttributes?.muscleScore4,
      saleLot.draftingAttributes?.muscleScore5,
    ].filter(Boolean),
  );

export const hornAllocation = saleLot =>
  saleLot.quantity -
  sum(
    [
      saleLot.draftingAttributes?.hornPolled,
      saleLot.draftingAttributes?.hornHorned,
      saleLot.draftingAttributes?.hornDehorned,
      saleLot.draftingAttributes?.hornTipped,
      saleLot.draftingAttributes?.hornScurred,
    ].filter(Boolean),
  );

export const breedAllocation = saleLot =>
  saleLot.quantity -
  sum(
    [
      saleLot.draftingAttributes?.advancedBreed1Hd,
      saleLot.draftingAttributes?.advancedBreed2Hd,
      saleLot.draftingAttributes?.advancedBreed3Hd,
    ].filter(Boolean),
  );

export const dentitionAllocation = saleLot =>
  saleLot.draftingAttributes?.isNotMouthed ||
  saleLot.quantity -
    sum(
      [
        saleLot.draftingAttributes?.dentition0,
        saleLot.draftingAttributes?.dentition2,
        saleLot.draftingAttributes?.dentition4,
        saleLot.draftingAttributes?.dentition6,
        saleLot.draftingAttributes?.dentition8,
        saleLot.draftingAttributes?.dentitionBroken,
      ].filter(Boolean),
    );

/* Tests whether a preg status has all (or sufficient) data related to it where needed. */
const pregStatusValidation = {
  pregStatus: Yup.string()
    .typeError("Preg Status information incomplete.")
    .required("Preg Status information incomplete.")
    .test(
      "pregStatusTest",
      "SIL Must be provided when preg status is SIL.",
      (value, context) => {
        if (!context) {
          return false;
        }
        if (value !== PregStatuses.SIL) {
          return true;
        }
        return Boolean(context.parent.sil);
      },
    )
    .test(
      "validPregStatus",
      "Missing joined and despastured information.",
      (value, context) => {
        if (!context) {
          return;
        }
        if (
          [
            PregStatuses.SIL,
            PregStatuses.STATION_MATED,
            PregStatuses.NOT_STATION_MATED,
          ].includes(value)
        ) {
          // We don't need this data, so no further validation here.
          return true;
        }
        // otherwise we need joined start, joined end, depasturedId, and make sure the start < end.
        const { joinedStart, joinedEnd, despasturedId } = context.parent;
        if (!joinedStart || !joinedEnd || !despasturedId) {
          return false;
        }
        const joinedStartDateTime = dateTimeStringToDateTime(joinedStart);
        const joinedEndDateTime = dateTimeStringToDateTime(joinedEnd);
        const now = new Date();
        return (
          joinedStartDateTime < now &&
          joinedEndDateTime < now &&
          joinedStartDateTime <= joinedEnd
        );
      },
    ),
};

/* Validation using the allocation functions above */
const fatScoreValidation = {
  fatScoreComplete: Yup.number().test(
    "fatScoretest",
    "Fat Score incomplete.",
    (_ignored, context) => {
      if (!context) {
        return false;
      }
      return fatScoresAllocation(context.from[1].value) === 0;
    },
  ),
};
const hornValidation = {
  hornedComplete: Yup.number().test(
    "hornedTest",
    "Horned incomplete.",
    (_ignored, context) => {
      if (!context) {
        return false;
      }
      return hornAllocation(context.from[1].value) === 0;
    },
  ),
};

// We don't need to have
const breedAllocationValidation = {
  advancedBreedComplete: Yup.number().test(
    "breedAllocationTest",
    "Breed Allocation incomplete.",
    (_ignored, context) => {
      if (!context) {
        return false;
      }
      return breedAllocation(context.from[1].value) === 0;
    },
  ),
};

/* Simple required fields */

const sheepFieldsRequired = {
  age_id: Yup.number().typeError("Age is required").required("Age is required"),

  breed_id: Yup.number()
    .typeError("Breed is required")
    .required("Breed is required"),
  sex_id: Yup.number().typeError("Sex is required").required("Sex is required"),
};

const sheepDraftingAttributesRequired = {
  dropStart: Yup.string()
    .typeError("Drop Start is required.")
    .required("Drop start required."),
  dropEnd: Yup.string()
    .typeError("Drop End is required.")
    .required("Drop end required."),
};

const vendorRequiredValidation = {
  consignment: Yup.object({
    vendor_id: Yup.string()
      .typeError("Vendor is required.")
      .required("Vendor is required."),
    vendor_property_id: Yup.string()
      .typeError("Vendor Property is required.")
      .required("Vendor Property is required."),
  }).typeError("Vendor Details incomplete."),
};

const cattleAgeRequiredValidation = {
  minAge: Yup.number()
    .typeError("Min Age is required")
    .required("Min Age is required"),
  maxAge: Yup.number()
    .typeError("Max Age is required")
    .required("Max Age is required"),
  ageRangeTimeUnit: Yup.string()
    .typeError("Age Range Unit is required")
    .required("Age Range Unit is required"),
};

const cattleRequiredFieldsValidation = {
  breed_id: Yup.number()
    .typeError("Breed is required")
    .required("Breed is required"),
};

/* Rolled up validators for each of the 4 use cases - a+ cattle & sheep, stocklive cattle & sheep */

const auctionsPlusSheepSchema = Yup.object().shape({
  ...vendorRequiredValidation,
  ...sheepFieldsRequired,

  estimatedAverageMassGrams: Yup.string()
    .typeError("Estimated Average Mass is required.")
    .required("Estimated Average Mass required."),

  draftingAttributes: Yup.object({
    ...sheepDraftingAttributesRequired,
    ...fatScoreValidation,
    ...pregStatusValidation,

    weighLocation: Yup.string()
      .typeError("Weigh Location is required.")
      .required("Weigh Location required."),
  }).typeError("Advanced Drafting Details incomplete"),
});

const stockLiveSheepSchema = Yup.object().shape({
  ...vendorRequiredValidation,
  ...sheepFieldsRequired,
  draftingAttributes: Yup.object({
    ...sheepDraftingAttributesRequired,
    ...pregStatusValidation,
  }).typeError("Advanced Drafting Details incomplete"),
});

const auctionsPlusCattleSchema = Yup.object().shape({
  ...vendorRequiredValidation,
  ...cattleRequiredFieldsValidation,

  draftingAttributes: Yup.object({
    ...breedAllocationValidation,
    ...hornValidation,
    ...cattleAgeRequiredValidation,
    ...fatScoreValidation,
  }).typeError("Advanced Drafting Details incomplete"),
});

const stockLiveCattleSchema = Yup.object().shape({
  draftingAttributes: Yup.object({
    ...hornValidation,
    ...cattleAgeRequiredValidation,
  }).typeError("Advanced Drafting Details incomplete"),
});

function doValidate(schema, saleLot) {
  try {
    // Abort early is a hard one.  We want to get ALL the errors, so we need to set it to false when using validatedSync.
    // But, it means the validators are a bit sensitive - the .test calls may not have context, etc.
    schema.validateSync(saleLot, { abortEarly: false });
  } catch (e) {
    return e.inner.reduce((acc, error) => {
      acc[error.path] = error.message;
      return acc;
    }, {});
  }
}

export const getAuctionsPlusValidationErrorsOrStatus = saleLot => {
  if (!saleLot.exportSites?.includes(ExportSites.AUCTIONS_PLUS)) {
    return AdvancedDraftingValidationStates.DISABLED;
  }

  if (!saleLot.sale_round) {
    return {
      sale_round: "No round selected",
    };
  }

  let validationSchema = null;

  if (saleLot.sale_round.species_id === Species.CATTLE) {
    validationSchema = auctionsPlusCattleSchema;
  } else if (saleLot.sale_round.species_id === Species.SHEEP) {
    validationSchema = auctionsPlusSheepSchema;
  }

  if (validationSchema) {
    return (
      doValidate(validationSchema, saleLot) ||
      AdvancedDraftingValidationStates.SUCCESS
    );
  }
  return AdvancedDraftingValidationStates.DISABLED;
};

export const getStockliveValidationErrorsOrStatus = saleLot => {
  if (!saleLot.exportSites?.includes(ExportSites.STOCK_LIVE)) {
    return AdvancedDraftingValidationStates.DISABLED;
  }

  if (!saleLot.sale_round) {
    return {
      sale_round: "No round selected",
    };
  }

  let validationSchema = null;
  if (saleLot.sale_round.species_id === Species.CATTLE) {
    validationSchema = stockLiveCattleSchema;
  } else if (saleLot.sale_round.species_id === Species.SHEEP) {
    validationSchema = stockLiveSheepSchema;
  }

  if (validationSchema) {
    return (
      doValidate(validationSchema, saleLot) ||
      AdvancedDraftingValidationStates.SUCCESS
    );
  }
  return AdvancedDraftingValidationStates.DISABLED;
};
