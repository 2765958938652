import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  id: "id",
  name: "name",
  description: "description",
};

export const serializeUserLevel = buildSerializer(serializeMap, "userLevel");
export const deserializeUserLevel = buildDeserializer(
  serializeMap,
  "userLevel",
);
