import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { updateDeploymentAttributes } from "actions";

import { UPDATE_DEPLOYMENT_BREEDS } from "constants/actionTypes";

import Table from "containers/Settings/DeploymentAttributesTable";
import { HeaderBulkSelectCellRenderer } from "containers/Settings/Renderers";

import { getDeploymentAttributes } from "selectors";

import { getActiveSpeciesAttributes } from "selectors/speciesAttributes";

const columnDefs = [
  { headerName: "Name", field: "name", rowDrag: true, editable: false },
  {
    headerName: "Import Code",
    field: "code",
  },
  {
    headerName: "Saleyard Import Code",
    field: "saleyard_code",
  },

  {
    headerName: "Use",
    field: "is_used",
    cellRenderer: "checkboxRenderer",
    editable: false,
    headerComponentFramework: HeaderBulkSelectCellRenderer,
  },

  {
    headerName: "Quick Select",
    children: [
      {
        headerName: "Code",
        field: "quick_code",
      },
      {
        headerName: "Auction",
        field: "quick_select",
        cellRenderer: "checkboxRenderer",
        editable: false,
        headerComponentFramework: HeaderBulkSelectCellRenderer,
      },
      {
        headerName: "Hooks",
        field: "quick_select_hooks",
        cellRenderer: "checkboxRenderer",
        editable: false,
        headerComponentFramework: HeaderBulkSelectCellRenderer,
      },
      {
        headerName: "Paddock",
        field: "quick_select_paddock",
        cellRenderer: "checkboxRenderer",
        editable: false,
        headerComponentFramework: HeaderBulkSelectCellRenderer,
      },
    ],
  },
];

export const BreedSettings = () => {
  const breeds = Object.values(useSelector(getActiveSpeciesAttributes).breeds);
  const deploymentData = Object.values(
    useSelector(getDeploymentAttributes).breeds,
  );

  const dispatch = useDispatch();

  const handleUpdate = payload =>
    dispatch(updateDeploymentAttributes(UPDATE_DEPLOYMENT_BREEDS, payload));

  return (
    <Table
      dataKey="breed_id"
      baseData={breeds}
      deploymentData={deploymentData}
      handleUpdate={handleUpdate}
      columnDefs={columnDefs}
    />
  );
};
