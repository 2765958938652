// 10MB
export const DEFAULT_MAX_FILE_SIZE = 10000000;
// 100MB
export const MAX_VIDEO_FILE_SIZE = 100000000;
export const GLOBAL_MAX_FILE_SIZE = Math.max(
  DEFAULT_MAX_FILE_SIZE,
  MAX_VIDEO_FILE_SIZE,
);
export const ACCEPTED_FILE_TYPES = [
  "application/gzip",
  "application/msword",
  "application/pdf",
  "application/rtf",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/x-tar",
  "application/xhtml+xml",
  "application/zip",
  "audio/aac",
  "audio/mpeg",
  "audio/ogg",
  "audio/opus",
  "audio/wav",
  "audio/webm",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "text/csv",
  "text/html",
  "text/plain",
  "video/3gpp",
  "video/3gpp2",
  "video/mp2t",
  "video/mp4",
  "video/mpeg",
  "video/ogg",
  "video/quicktime",
  "video/webm",
  "video/x-msvideo",
  "video/x-ms-wmv",
];
export const RESIZABLE_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"];
export const RESIZABLE_IMAGE_EXTENSIONS = ["jpeg", "jpg", "png", "gif"];

export const IMAGE_FILE_EXTENSIONS = [
  "jpeg",
  "jpg",
  "png",
  "gif",
  "bmp",
  "svg",
  "tiff",
  "webp",
];
export const VIDEO_FILE_EXTENSIONS = [
  "3gpp",
  "3gpp2",
  "mp2t",
  "mp4",
  "mpeg",
  "ogg",
  "quicktime",
  "webm",
  "x-msvideo",
  "x-ms-wmv",
];

export const NVD_ALLOWED_TYPES = RESIZABLE_IMAGE_TYPES;

export const MAX_IMAGE_WIDTH = 1792;
export const MAX_IMAGE_HEIGHT = 1792;
