import { createSelector } from "reselect";

import {
  getIntegrationCredentials,
  selectIntegrationCredentialIdsByDeploymentIdLookup,
  selectIntegrationCredentialIdsByTypeLookup,
} from "selectors";

export const getIntegrationCredentialById = integrationCredentialId => state =>
  getIntegrationCredentials(state)[integrationCredentialId] || null;

export const getIntegrationCredentialIdsByType = type => state =>
  selectIntegrationCredentialIdsByTypeLookup(state)[type] || null;

export const getIntegrationCredentialIdsByDeploymentId =
  deploymentId => state =>
    selectIntegrationCredentialIdsByDeploymentIdLookup(state)[deploymentId] ||
    null;

export const selectIntegrationCredentialIdByTypeAndDeploymentIdLookup =
  createSelector([getIntegrationCredentials], integrationCredentials =>
    Object.values(integrationCredentials).reduce(
      (lookup, integrationCredential) => {
        const { type, deploymentId, id } = integrationCredential;
        if (lookup[type]) {
          lookup[type][deploymentId] = id;
        } else {
          lookup[type] = {
            [deploymentId]: id,
          };
        }
        return lookup;
      },
      {},
    ),
  );
