import { useDispatch, useSelector } from "react-redux";

import { setSetting } from "actions";

import { Settings } from "constants/settings";

import { getSetting } from "selectors";

export const useToggleFullScreen = () => {
  const isFullScreen = useSelector(getSetting(Settings.fullScreen));
  const dispatch = useDispatch();
  const toggleFullScreen = () =>
    dispatch(setSetting(Settings.fullScreen, !isFullScreen));

  return [isFullScreen, toggleFullScreen];
};
