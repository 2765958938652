import styled from "styled-components/macro";

const Button = styled.button(
  ({
    theme,
    color,
    disabled,
    inline,
    margin,
    padding,
    fullWidth = false,
    flex,
    minWidth,
    borderRadius = 1,
  }) => `
  text-decoration: none;
  border: 0;
  ${typeof flex !== "undefined" ? `flex: ${flex};` : ""}
  appearance: none;
  &:active {
    background: rgba(200, 200, 200, 0.59);
  }
  :not(:active) {
    transition: all linear 100ms;
  }
  opacity: ${disabled ? 0.25 : 1};
  cursor: 
  ${disabled ? "not-allowed;" : "pointer;"};
  background: ${
    color && theme.colors[color] ? theme.colors[color] : theme.colors.primary
  };
  
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  };
  
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.white};
  border-radius: ${
    typeof borderRadius === "number"
      ? `${theme.radii[borderRadius]}px`
      : borderRadius
  };
  ${fullWidth ? "width: 100%;" : ""}
  ${
    inline
      ? `min-height: 2.25rem;
    ${
      typeof margin === "number"
        ? `margin-left: ${theme.space[margin]}px; margin-right: ${theme.space[margin]}px;`
        : ""
    }
  
  ${
    typeof padding === "number"
      ? `padding-left: ${theme.space[padding]}px; padding-right: ${theme.space[padding]}px;`
      : ""
  }
  `
      : `min-height: 3rem;
      ${typeof margin === "number" ? `margin: ${theme.space[margin]}px;` : ""}
  ${typeof padding === "number" ? `padding: ${theme.space[padding]}px;` : ""}`
  }
  &:hover:enabled {
    background: ${theme.colors.primaryHighlight};
  }
  transition: ${theme.transitions[0]};
  ${typeof minWidth !== "undefined" ? `min-width: ${minWidth};` : ""} 
`,
);

export default Button;
