import React from "react";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

import { EditReceivalLotModal } from ".";

function EditReceivalLotModalAdapter(props) {
  const [hashParams, ignored, onClose] = useModalAdapter(
    ModalTypes.EditReceivalLot,
    props,
  );
  const {
    receivalLotId = null,
    consignmentId = null,
    newPenName,
    hideConsignmentInput = false,
  } = hashParams;

  return (
    <EditReceivalLotModal
      receivalLotId={receivalLotId}
      consignmentId={consignmentId}
      newPenName={newPenName}
      hideConsignmentInput={hideConsignmentInput}
      onClose={onClose}
    />
  );
}

export default EditReceivalLotModalAdapter;
