import * as Sentry from "@sentry/react";
import {
  call,
  delay,
  put as sagaPut,
  race,
  select as sagaSelect,
  take,
} from "redux-saga/effects";

import {
  apiResponseGetFailure,
  apiResponseGetSuccess,
  apiResponsePatchFailure,
  apiResponsePatchSuccess,
  notifyFetchRequestRetrying,
  refreshJWT,
  requestLogout,
  sendToUserOverview,
  setConcurrentUserBlock,
  setGeoBlock,
} from "actions";

import {
  REQUEST_NEW_JWT_TOKEN_COMMIT,
  REQUEST_NEW_JWT_TOKEN_ROLLBACK,
  RETRYABLE_ACTIONS,
} from "constants/actionTypes";
import { AccessBlockReasons } from "constants/auth";
import { SentrySeverityError } from "constants/sentry";

import { prepareFetch, ResponseError, responseToJson } from "lib/fetch";
import { isSentryActive } from "lib/sentry/config";

import { getAuthentication, getCurrentUser } from "selectors";

function* put(url, body) {
  const { auth } = yield sagaSelect();
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  const response = yield prepareFetch(url, options, auth);
  return yield responseToJson(response);
}

function* get(url) {
  const { auth } = yield sagaSelect();
  const options = {
    method: "GET",
  };
  const response = yield prepareFetch(url, options, auth);

  if (response.ok) {
    yield sagaPut(apiResponseGetSuccess(response));
  } else {
    yield sagaPut(apiResponseGetFailure(response));
  }

  return yield responseToJson(response);
}

function* post(url, body) {
  const { auth } = yield sagaSelect();
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  const response = yield prepareFetch(url, options, auth);
  return yield responseToJson(response);
}

function* patch(url, body) {
  const { auth } = yield sagaSelect();
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  const response = yield prepareFetch(url, options, auth);
  if (response.ok) {
    yield sagaPut(apiResponsePatchSuccess(response));
  } else {
    yield sagaPut(apiResponsePatchFailure(response));
  }

  return yield responseToJson(response);
}

function* destroy(url, body) {
  const { auth } = yield sagaSelect();
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  const response = yield prepareFetch(url, options, auth);
  return yield responseToJson(response);
}

export function* refreshJWTAndRetry(action) {
  const state = yield sagaSelect();
  const authentication = getAuthentication(state);
  const { user_id } = getCurrentUser(state);
  if (authentication.isAuthenticated) {
    yield sagaPut(refreshJWT(authentication.JWTRefreshToken, user_id));
    let tries = 0;
    while (tries < 5) {
      const { success } = yield race({
        success: take(REQUEST_NEW_JWT_TOKEN_COMMIT),
        failure: take(REQUEST_NEW_JWT_TOKEN_ROLLBACK),
        timeout: delay(5000),
      });
      if (success) {
        const newAuthentication = getAuthentication(yield sagaSelect());
        if (!newAuthentication.requestingNewJWTToken) {
          if (newAuthentication.isAuthenticated) {
            yield sagaPut(action);
            break;
          }
        }
      }

      tries += 1;
    }
  }
}

function* retryFailedRequest(action) {
  // Re-perform an action until we know it worked, or it's failed 5 times.
  // Use a bit of a ghetto exponential back-off so we don't end up trampling our own server.

  // patch the number of tries onto the action.
  if (!action.tries) {
    action.tries = 0;
  }
  action.tries += 1;

  if (action.tries > 5) {
    // Total failure, alert the user. Send sentry Error
    if (isSentryActive) {
      const { type, ...payload } = action;
      Sentry.withScope(scope => {
        scope.setTag("action", type);
        scope.setExtra("payload", payload);
        Sentry.captureMessage("Retry Request Failed", SentrySeverityError);
      });
    }
  } else {
    const delayMs = 1000 * 2 ** action.tries;
    yield sagaPut(notifyFetchRequestRetrying());
    yield delay(delayMs);
    yield sagaPut(action);
  }
}

/**
 *
 * @param {ResponseError} error
 * @param {Object} action
 */
function* handleResponseError(error, action) {
  if (error.code === 401) {
    yield call(refreshJWTAndRetry, action);
  } else if (error.code === 403) {
    yield sagaPut(requestLogout());
  } else if (error.code === 428) {
    if (error?.errors?.reason === AccessBlockReasons.GEO_BLOCK) {
      yield sagaPut(
        setGeoBlock({
          error: error.errors.information,
          allowableZones: error.errors.allowable_zones || null,
        }),
      );
      yield sagaPut(sendToUserOverview());
    } else if (
      error?.errors?.reason === AccessBlockReasons.CONCURRENT_USER_BLOCK
    ) {
      yield sagaPut(setConcurrentUserBlock(error.errors));
      yield sagaPut(sendToUserOverview());
    }
  } else if (error.code === 404) {
    // eslint-disable-next-line no-console
    console.log(`${action.type} not found`);
  } else if (
    // If the error was something likely to be transient (server overload, timeout, ...)
    // and of a type that we CAN retry, do so.
    [502, 503, 504].includes(error.code) &&
    RETRYABLE_ACTIONS.includes(action.type)
  ) {
    yield call(retryFailedRequest, action);
    // if a none of above must(?) be mutative and send sentry error
  } else if (isSentryActive) {
    const { type, ...payload } = action;
    Sentry.withScope(scope => {
      scope.setTag("action", type);
      scope.setExtra("url", error.url);
      scope.setExtra("payload", payload);
      Sentry.captureException(error);
    });
  }
}

/**
 * @param {TypeError} error
 * @param {Object} action
 */
function* handleTypeError(error, action) {
  // Don't capture
  // - TypeError: cancelled - when user navigates away
  // - TypeError: WebKit encountered an internal error - when Safari exists
  if (
    error.message === "cancelled" ||
    error.message === "WebKit encountered an internal error" ||
    error.message === "Failed to fetch" ||
    error.message === "Fetch event is destroyed."
  ) {
    yield;
  } else if (isSentryActive) {
    const { type, ...payload } = action;
    Sentry.withScope(scope => {
      scope.setTag("action", type);
      scope.setExtra("payload", payload);
      Sentry.captureException(error);
    });
  }
}

/**
 * @param {Error} error
 * @param {String} objectName
 * @param {Object} action
 */
function* handleFetchError(error, objectName, action) {
  if (error instanceof ResponseError) {
    yield handleResponseError(error, action);
  } else if (error instanceof TypeError) {
    yield handleTypeError(error, action);
  } else {
    // eslint-disable-next-line no-console
    console.error(`Error fetching ${objectName}`, action);
    if (isSentryActive) {
      const { type, ...payload } = action;
      Sentry.withScope(scope => {
        scope.setTag("action", type);
        scope.setExtra("payload", payload);
        Sentry.captureException(error);
      });
    }
  }
}

export const api = {
  get,
  post,
  put,
  patch,
  destroy,
  handleFetchError,
};
