import React from "react";

import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { SelectField } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";

import { selectLivestockSaleConsignmentOptions } from "selectors";

const Vendor = ({ handleReInit }) => {
  const consignmentOptions = useSelector(selectLivestockSaleConsignmentOptions);
  const formikProps = useFormikContext();

  return (
    <Column justifyCenter full>
      <SelectField
        name="consignment_id"
        options={consignmentOptions}
        onChangeExtra={consignment_id =>
          handleReInit({ ...formikProps.values, consignment_id })
        }
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </Column>
  );
};

export const ConsignmentSelector = ({ handleReInit }) => {
  return (
    <Row justifyBetween>
      <Vendor handleReInit={handleReInit} />
    </Row>
  );
};
