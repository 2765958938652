import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { updateDeploymentAttributes } from "actions";

import { UPDATE_DEPLOYMENT_AGES } from "constants/actionTypes";

import Table from "containers/Settings/DeploymentAttributesTable";
import { HeaderBulkSelectCellRenderer } from "containers/Settings/Renderers";

import { getDeploymentAttributes } from "selectors";

import { getActiveSpeciesAttributes } from "selectors/speciesAttributes";

const columnDefs = [
  { headerName: "Name", field: "name", rowDrag: true, editable: false },
  {
    headerName: "Code",
    field: "code",
  },
  {
    headerName: "Use",
    field: "is_used",
    cellRenderer: "checkboxRenderer",
    editable: false,
    headerComponentFramework: HeaderBulkSelectCellRenderer,
  },
  {
    headerName: "Quick Select",
    field: "quick_select",
    cellRenderer: "checkboxRenderer",
    editable: false,
    headerComponentFramework: HeaderBulkSelectCellRenderer,
  },
];

export const AgeSettings = () => {
  const ages = Object.values(useSelector(getActiveSpeciesAttributes).ages);
  const deploymentData = Object.values(
    useSelector(getDeploymentAttributes).ages,
  );
  const dispatch = useDispatch();

  const handleUpdate = payload =>
    dispatch(updateDeploymentAttributes(UPDATE_DEPLOYMENT_AGES, payload));

  return (
    <Table
      dataKey="age_id"
      baseData={ages}
      deploymentData={deploymentData}
      handleUpdate={handleUpdate}
      columnDefs={columnDefs}
    />
  );
};
