import queryString from "query-string";

import { BILLING_TAGS } from "constants/actionTypes";

import { serializeBillingTag } from "lib/serializers/billingTags";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/billing-tags/", id, action),
    query: {
      changesSince,
    },
  });

export const BillingTagsAction = offlineActionCreator(
  BILLING_TAGS,
  serializeBillingTag,
  urlCreator,
  false,
);
