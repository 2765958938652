import React, { useMemo } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { AuctionPenAction } from "actions";

import { Button, SecondaryButton } from "components/Form";
import { PenPicker } from "components/Form/FormikControls/PenPicker";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { ApiModel } from "constants/loading";

import { openPenScanning } from "lib/navigation";
import { scanLotConfig } from "lib/scanLot";

import { getAuctionPenById } from "selectors";

const validationSchema = Yup.object().shape({
  penId: Yup.string().required("You must select a pen."),
});

function SelectScanningPenForm(props) {
  const { onClose, penType, isBulkRePen, penId } = props;
  const { handleSubmit, values, isValid } = useFormikContext();

  const { penId: newPenId } = values;

  const { penTitle } = scanLotConfig(penType);

  const pen = useSelector(getAuctionPenById(values?.penId));

  const isNewPenSelected = newPenId !== penId;

  const penExists = !!pen;

  const isDisabled = !isValid || (isBulkRePen && !isNewPenSelected);

  const submitText = useMemo(() => {
    if (isBulkRePen) {
      return "Re-Pen";
    }
    if (!penExists) {
      return "Create & Scan";
    }
    return "Scan";
  }, [isBulkRePen, penExists]);

  return (
    <Form onSubmit={handleSubmit}>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            <PenPicker
              label={penTitle}
              idName="penId"
              isCreateEnabled
              isCreateFreeFormEnabled
              isCreateArchetypeDerivedEnabled
              dataName="penData"
              penType={penType}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Back</SecondaryButton>
        <Button data-tour={submitText} type="submit" disabled={isDisabled}>
          {submitText}
        </Button>
      </DialogActions>
    </Form>
  );
}

export function EditScanningPenModal({ penId, penType, isBulkRePen, onClose }) {
  const dispatch = useDispatch();
  const { lotAction, lotPenIdReference, penTitle, selectLotIdsByPenIdLookup } =
    scanLotConfig(penType);

  const scanLotIds = useSelector(selectLotIdsByPenIdLookup)[penId];

  const onSubmit = values => {
    const { penId: newPenId, penData } = values;

    if (penData) {
      dispatch(AuctionPenAction.create(penData));
    }
    if (isBulkRePen) {
      // bulk update all lots in pen to selected pen
      if (newPenId !== penId) {
        // bulk update if the new pen is different
        scanLotIds.forEach(scanLotId => {
          dispatch(
            lotAction.update(
              {
                id: scanLotId,
                [lotPenIdReference]: newPenId,
              },
              { changeReason: "Updated from Re Pen All" },
            ),
          );
        });
        onClose();
      } else {
        // pen hasn't changed. Not much to do here
        onClose();
      }
    } else {
      // if we're not repenning, we're going straight into scanning
      openPenScanning(null, newPenId, null, null, null, penType);
    }
  };

  const titleAction = useMemo(() => {
    if (isBulkRePen) {
      return "Re-Pen";
    }
    if (penId) {
      return "Edit";
    }
    return "Select";
  }, [isBulkRePen, penId]);

  return (
    <ZoomyDialog open onClose={onClose}>
      <WaitForSync
        requiredData={[ApiModel.RECEIVAL_LOTS, ApiModel.AUCTION_PENS]}
      >
        <DialogTitle onClose={onClose}>
          {titleAction} {penTitle} <br />
        </DialogTitle>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            penId,
          }}
          validateOnMount
          validationSchema={validationSchema}
        >
          <SelectScanningPenForm
            onClose={onClose}
            penType={penType}
            isBulkRePen={isBulkRePen}
            penId={penId}
          />
        </Formik>
      </WaitForSync>
    </ZoomyDialog>
  );
}
