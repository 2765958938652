import styled from "styled-components/macro";

export const StatusWrapper = styled.div`
  ${({ theme, marginBottom = 1, boxShadow, color }) => `
  border-left: 6px solid ${theme.colors[color || "grey"]};
  margin-bottom: ${theme.space[marginBottom]}px; 
    ${
      typeof boxShadow === "number"
        ? `box-shadow: ${theme.shadows[boxShadow]};`
        : ""
    }

  `}
`;
