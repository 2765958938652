import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { sumBy } from "lodash";
import { useSelector } from "react-redux";

import { ActionButton } from "components/AgGrid/ActionButton";
import AgGrid from "components/AgGrid/AgGrid";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { SubtleBadge } from "components/Badge";
import { SlimSecondaryButton } from "components/Button";
import { Warning } from "components/ErrorMessage";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Error } from "components/Form/FormikControls/Error";
import { Row } from "components/Layout";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import {
  getSaleLotById,
  getSaleLotModalVendorSplitSaleLotsAggridDataByParentSaleLotId,
  getVendorIdBySaleLotId,
  getVendorSplitConsignmentIdsByParentConsignmentId,
  getVendorSplitSaleLotIdsByParentSaleLotId,
} from "selectors";

const onClickCreateVendorSplitSaleLot = parentSaleLotId =>
  openModalLink(ModalTypes.VendorSplitSaleLot, { parentSaleLotId });

const defaultColDef = {
  sortable: true,
  resizable: true,
  enableCellChangeFlash: true,
  flex: true,
};

function VendorSplitHeader({ saleLotId }) {
  const vendorSplitSaleLotIds = useSelector(
    getVendorSplitSaleLotIdsByParentSaleLotId(saleLotId),
  );
  return (
    <Row>
      <SubtleBadge>Legacy Vendor Splits</SubtleBadge>
      <CollapseLabel>
        {vendorSplitSaleLotIds.length}{" "}
        {pluralize("Split", vendorSplitSaleLotIds.length)}
      </CollapseLabel>
    </Row>
  );
}

const onClickEditSubSaleLot = (saleLotId, parentSaleLotId) => {
  openModalLink(ModalTypes.VendorSplitSaleLot, {
    saleLotId,
    parentSaleLotId,
  });
};

const actionsColumnRenderer = ({ data }) => (
  <ActionButton
    data-tour="action-goto-salelot"
    type="button"
    onClick={() => onClickEditSubSaleLot(data.id, data.saleLot.parentId)}
  >
    <FontAwesomeIcon icon={faPencil} />
    &nbsp;Edit
  </ActionButton>
);

const columns = [
  {
    headerName: "Lot #",
    field: "saleLot.fullLotNumber",
    width: 80,
  },
  {
    headerName: "Hd",
    field: "saleLot.quantity",
    width: 80,
  },
  // TODO - weight?  $?
  {
    headerName: "Vendor",
    field: "vendor.name",
  },

  {
    headerName: ACTION_COLUMN_NAME,
    colId: ACTION_COLUMN_ID,
    cellRenderer: actionsColumnRenderer,
    pinned: "right",
    width: 130,
  },
];

export const LegacyVendorSplitCollapse = ({ isOpen, onToggle, saleLotId }) => {
  const subSaleLots = useSelector(
    getSaleLotModalVendorSplitSaleLotsAggridDataByParentSaleLotId(saleLotId),
  );

  const enteredQuantity = sumBy(subSaleLots, "saleLot.quantity");

  const { quantity: parentQuantity, consignment_id: parentConsignmentId } =
    useSelector(getSaleLotById(saleLotId));

  const hasVendorSplitConsignments = useSelector(
    state =>
      getVendorSplitConsignmentIdsByParentConsignmentId(parentConsignmentId)(
        state,
      ).length > 0,
  );
  const vendorId = useSelector(getVendorIdBySaleLotId(saleLotId));

  const onClickCreateVendorSplit = () =>
    openModalLink(ModalTypes.VendorSplit, {
      saleLotId,
      primaryBusinessId: vendorId,
    });

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<VendorSplitHeader saleLotId={saleLotId} />}
      dataTour={isOpen ? "hideVendorSplits" : "showVendorSplits"}
    >
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <SlimSecondaryButton
            type="button"
            onClick={onClickCreateVendorSplit}
            data-tour="createVendorSplit"
          >
            Create Vendor Split
          </SlimSecondaryButton>
        </Grid>

        {subSaleLots.length > 0 ? (
          <>
            {enteredQuantity !== parentQuantity ? (
              <Grid item container justifyContent="center">
                <Error>
                  Warning: You have entered a total of {enteredQuantity} head
                  for the Vendor Splits, but the total Head Count for the Sale
                  Lot is {parentQuantity}. This may result in billing errors.
                </Error>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <CollapseTableWrapper>
                <AgGrid
                  rowData={subSaleLots}
                  suppressColumnVirtualisation
                  suppressScrollOnNewData
                  columnDefs={columns}
                  rowBuffer={10}
                  defaultColDef={defaultColDef}
                />
              </CollapseTableWrapper>
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <SlimSecondaryButton
          type="button"
          onClick={() => onClickCreateVendorSplitSaleLot(saleLotId)}
          data-tour="createVendorSplit"
          disabled={!hasVendorSplitConsignments}
        >
          Create Vendor Split
        </SlimSecondaryButton>
        {!hasVendorSplitConsignments ? (
          <Warning>
            You must create Vendor Splits for the Consignment before splitting
            the Sale Lot
          </Warning>
        ) : null}
      </Grid>
    </FormCollapse>
  );
};
