import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  faFileAlt,
  faFileImport,
  faLock,
  faMobile,
  faPlus,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  invalidateTableView,
  requestPropertyERPCheck,
  screenResize,
  setSetting,
  uploadRequest,
} from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { UploadModal } from "components/UploadModal";

import { AgGridTables } from "constants/aggrid";
import { Column } from "constants/columns";
import { ApiModel } from "constants/loading";
import {
  ConsignmentModalSection,
  ModalTypes,
  SaleLotModalSection,
} from "constants/navigation";
import {
  ConsignmentPermissions,
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { SaleTypes } from "constants/sale";
import { Settings } from "constants/settings";
import { userTypes } from "constants/users";

import {
  getLivestockSaleId,
  getSaleRoute,
  getSaleyardName,
  openAttachments,
  openCsvSaleImport,
  openEditConsignmentModal,
  openEditSaleLotModal,
  openImportXml,
  openModalLink,
} from "lib/navigation";
import { hasPermission } from "lib/permissions";
import { disableReadOnlyActions } from "lib/readOnly";

import {
  currentSaleSelector,
  getActiveLivestockAgentDeployment,
  getCurrentAgenciesOptions,
  getCurrentSale,
  getCurrentSaleyard,
  getHasWriteAccessInCurrentSale,
  getIsConsigningAgent,
  getIsConsigningAgentAndSaleUnlocked,
  getIsLivestockAgent,
  getIsSaleyardAdmin,
  getSetting,
  selectCurrentDeployments,
  selectGeneralConsignmentsAggridData,
  selectIsUserOfType,
  selectRoleCurrentDeployments,
  selectVendorSplitByKeyLookup,
} from "selectors";

import { useTheme } from "hooks";
import {
  useHasAddConsignmentPermission,
  useHasDeploymentPermission,
  useHasPermission,
  useSomeHasPermission,
} from "hooks/useHasPermission";

import { defaultConsignmentColumns } from "./columnDefs";

const getRowId = params => params.data.id;

const rowClassRules = {
  "row-warning": params => {
    const { api, node } = params;
    const rowWarning = api.getValue(Column.Consignment.WARNING, node);
    return !!rowWarning;
  },
};

const ConsignmentsTable = () => {
  const dispatch = useDispatch();
  const rowData = useSelector(selectGeneralConsignmentsAggridData);
  const isHooksSale =
    useSelector(getCurrentSale)?.sale_type === SaleTypes.OVER_HOOKS;
  const theme = useTheme();
  const hasReceivalLotPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featureReceivalLots,
  );

  const tableName = AgGridTables.CONSIGNMENT;
  const showCurrentAgencyFilter = useSelector(
    selectIsUserOfType([userTypes.BUSINESS_USER, userTypes.SALEYARD_ADMIN]),
  );
  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);
  const sale = useSelector(currentSaleSelector);
  const agencyId = useSelector(getSetting(Settings.agencyId));
  const agencyIdRef = useRef(agencyId);

  useEffect(() => {
    if (agencyIdRef.current !== agencyId) {
      dispatch(invalidateTableView(AgGridTables.CONSIGNMENT));
      agencyIdRef.current = agencyId;
    }
  }, [agencyId, agencyIdRef, dispatch]);

  const doesExternalFilterPass = useCallback(({ data }) => {
    return (
      agencyIdRef.current === null ||
      data.livestockAgency?.id === agencyIdRef.current
    );
  }, []);

  const isExternalFilterPresent = useCallback(
    () => agencyIdRef.current !== null,
    [],
  );

  const handleClickSaleLotComments = ({ id }) => {
    openEditConsignmentModal(id, ConsignmentModalSection.SALE_LOTS, true);
  };
  const [uploadingConsignmentId, setUploadingConsignmentId] =
    useState(undefined);

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );
  const isConsigningAgentAndSaleUnlocked = useSelector(
    getIsConsigningAgentAndSaleUnlocked,
  );
  const isConsigningAgent = useSelector(getIsConsigningAgent);

  const readOnly =
    (!hasWriteAccessInCurrentSale && !isConsigningAgentAndSaleUnlocked) ||
    isConsigningAgent;

  const hasNominationsFeaturePermission = useSomeHasPermission(
    selectCurrentDeployments,
    DeploymentPermissions.featureNominations,
  );

  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const deployment = useSelector(getActiveLivestockAgentDeployment);

  const vendorSplitByKeyLookup = useSelector(selectVendorSplitByKeyLookup);

  const externalIntegrationPermission = isLivestockAgent
    ? DeploymentPermissions.featureExternalIntegrations
    : null;

  const hasExternalIntegrationPermission = useHasDeploymentPermission(
    externalIntegrationPermission,
  );

  const context = useMemo(
    () => ({
      deployment,
      showCurrentAgencyFilter,
      hasWriteAccess: true,
      handleClickSaleLotComments,
      openUploadModal: setUploadingConsignmentId,
      readOnly,
      vendorSplitByKeyLookup,
    }),

    [deployment, readOnly, showCurrentAgencyFilter, vendorSplitByKeyLookup],
  );
  const {
    date: saleDate,
    saleyard_name: saleyard,
    species_id: speciesId,
  } = sale;

  const handleUpload = useCallback(
    file => {
      const formData = {
        consignment: uploadingConsignmentId || undefined,
        agency: agencyId !== null ? agencyId : undefined,
      };

      dispatch(uploadRequest(file, formData));

      setUploadingConsignmentId(null);
      if (uploadingConsignmentId) {
        openAttachments({
          consignment: uploadingConsignmentId,
          selectedAgency: agencyId,
        });
      } else {
        openAttachments({ selectedAgency: agencyId });
      }
    },
    [agencyId, dispatch, uploadingConsignmentId],
  );

  const agencyOptions = useSelector(getCurrentAgenciesOptions(false));

  const hasAddConsignmentPermission = useHasAddConsignmentPermission();
  const hasManualChargePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureManualCharges,
  );

  const allowBulkUpload =
    (!isSaleyardAdmin || agencyId !== null) && rowData && rowData.length > 0;

  const hasCreateConsignmentPermission = useHasDeploymentPermission(
    DeploymentPermissions.canAddConsignment,
  );

  const bulkUploadActions = useMemo(() => {
    if (!isSaleyardAdmin || agencyId) {
      return [
        {
          title: "Bulk Upload Attachments",
          icon: hasWriteAccessInCurrentSale ? faUpload : faLock,
          onClick: () => {
            setUploadingConsignmentId(null);
          },
          default: true,
          dataTour: "bulkUpload",
          isDisabled: !hasCreateConsignmentPermission,
        },
        {
          title: "View Attachments",
          icon: faFileAlt,
          onClick: () => openAttachments({ selectedAgency: agencyId }),
          default: true,
          dataTour: "viewAttachments",
          isDisabled: !allowBulkUpload || !hasCreateConsignmentPermission,
        },
      ];
    } else {
      return [
        {
          title: "Bulk Upload Attachments",
          icon: hasWriteAccessInCurrentSale ? faUpload : faLock,
          default: true,
          dataTour: "bulkUpload",
          isDisabled: !hasCreateConsignmentPermission,
          onClick:
            agencyOptions.length === 1
              ? () => {
                  dispatch(
                    setSetting(Settings.agencyId, agencyOptions[0].value),
                  );
                  setUploadingConsignmentId(null);
                }
              : null,
          subNavItems:
            agencyOptions.length > 1
              ? agencyOptions.map(agency => ({
                  title: agency.label,
                  dataTour: `bulkUpload:${agency.label}`,
                  onClick: () => {
                    dispatch(setSetting(Settings.agencyId, agency.value));
                    setUploadingConsignmentId(null);
                  },
                }))
              : [],
        },
        {
          title: "View Attachments",
          icon: faFileAlt,
          default: true,
          dataTour: "viewAttachments",
          isDisabled: !hasCreateConsignmentPermission,
          onClick:
            agencyOptions.length === 1
              ? () => {
                  dispatch(
                    setSetting(Settings.agencyId, agencyOptions[0].value),
                  );
                  openAttachments({ selectedAgency: agencyOptions[0].value });
                }
              : null,
          subNavItems:
            agencyOptions.length > 1
              ? agencyOptions.map(agency => ({
                  title: agency.label,
                  dataTour: `viewAttachments:${agency.label}`,
                  onClick: () => {
                    dispatch(setSetting(Settings.agencyId, agency.value));
                    openAttachments({ selectedAgency: agency.value });
                  },
                }))
              : [],
        },
      ];
    }
  }, [
    agencyId,
    agencyOptions,
    allowBulkUpload,
    dispatch,
    hasWriteAccessInCurrentSale,
    isSaleyardAdmin,
    hasCreateConsignmentPermission,
  ]);
  const history = useHistory();
  const additionalActions = useMemo(
    () =>
      [
        {
          title: "Mobile View",
          icon: faMobile,
          onClick: () => {
            dispatch(screenResize(theme.breakpoints[2] - 1));
            history.push(
              `${getSaleRoute(
                getSaleyardName(),
                getLivestockSaleId(),
              )}/consignment-cards`,
            );
          },
          dataTour: "mobileView",
        },
        {
          title: "Consignment",
          icon: faPlus,
          onClick: () => openEditConsignmentModal(),
          default: true,
          dataTour: "addConsignment",
          // if it is a consigning agent and the sale is unlocked
          // it should not be read only, otherwise use the permission check
          readOnly: isConsigningAgentAndSaleUnlocked
            ? false
            : !hasAddConsignmentPermission,
        },
        ...bulkUploadActions,

        !isSaleyardAdmin && {
          title: "Import Sale XML",
          icon: faFileImport,
          onClick: () => openImportXml(sale),
          dataTour: "import",
          isDisabled: !hasCreateConsignmentPermission,
        },
        !isSaleyardAdmin && {
          title: "Import Sale CSV",
          icon: hasCreateConsignmentPermission ? faFileImport : faLock,
          onClick: () => openCsvSaleImport(),
          dataTour: "importCsv",
          isDisabled: !hasCreateConsignmentPermission,
        },
      ]
        .filter(Boolean)
        .map(disableReadOnlyActions),
    [
      isConsigningAgentAndSaleUnlocked,
      hasAddConsignmentPermission,
      bulkUploadActions,
      isSaleyardAdmin,
      dispatch,
      history,
      sale,
      theme.breakpoints,
      hasCreateConsignmentPermission,
    ],
  );

  const extraHeaderComponents = useMemo(
    () =>
      [
        uploadingConsignmentId !== undefined && (
          <UploadModal
            closeSelf={() => setUploadingConsignmentId(undefined)}
            handleUpload={handleUpload}
            key="uploadModal"
          />
        ),
      ].filter(Boolean),
    [uploadingConsignmentId, handleUpload],
  );

  const getContextMenuItems = useCallback(
    params => {
      const contextMenu = Array.isArray(params.defaultItems)
        ? [...params.defaultItems]
        : [];
      // If they actually clicked a row, vs whitespace.
      if (params.node) {
        const rowData = params.node.data;
        const hasUpdateConsignmentPermission = hasPermission(
          rowData,
          ConsignmentPermissions.update,
        );

        if (params.column.colId === "nlisPrograms") {
          const propertyId = rowData.vendor_property_id;
          contextMenu.push({
            name: "Refresh NLIS Status",
            disabled: !propertyId,
            action: () => dispatch(requestPropertyERPCheck(propertyId)),
          });
        }

        if (hasManualChargePermission) {
          contextMenu.push({
            name: "Charge Vendor (Add Sundry)",
            action: () => {
              const modalProps = {
                id: null,
                fromBusinessId: null,
                toBusinessId: rowData.vendor_id,
                note: "",
              };
              openModalLink(ModalTypes.ManualAdjustmentForm, modalProps);
            },
          });
          contextMenu.push({
            name: "Credit Vendor (Add Sundry)",
            action: () => {
              const modalProps = {
                id: null,
                fromBusinessId: rowData.vendor_id,
                toBusinessId: null,
                note: "",
              };
              openModalLink(ModalTypes.ManualAdjustmentForm, modalProps);
            },
          });
        }

        const consignmentReadOnly = readOnly || !hasUpdateConsignmentPermission;
        const hasAddSaleLotPermission = hasPermission(
          rowData,
          ConsignmentPermissions.canAddSaleLot,
        );

        contextMenu.push({
          name: consignmentReadOnly ? "View Consignment" : "Edit Consignment",
          action: () =>
            openEditConsignmentModal(
              params.node.data.id,
              undefined,
              true,
              false,
            ),
        });

        if (!consignmentReadOnly && hasAddSaleLotPermission) {
          contextMenu.push({
            name: "Add Lot",
            action: () =>
              openEditSaleLotModal(
                null,
                SaleLotModalSection.GENERAL,
                "",
                rowData.id,
                null,
                window.location.hash,
              ),
          });
        }
      }

      return contextMenu;
    },
    [dispatch, hasManualChargePermission, readOnly],
  );

  const onGridReady = agGridInstance => {
    const columnDefs = agGridInstance.api.getColumnDefs();
    if (!hasNominationsFeaturePermission) {
      const nominationChildren =
        Object.values(columnDefs).find(
          columnDef => columnDef.colId === Column.Consignment.NOMINATION,
        )?.children || [];
      nominationChildren.forEach(child => {
        child.suppressColumnsToolPanel = true;
        child.hide = true;
      });
      agGridInstance.api.setColumnDefs(columnDefs);
    }
    if (!hasExternalIntegrationPermission) {
      const abnCheckColumn = Object.values(columnDefs).find(
        columnDef => columnDef.colId === Column.Consignment.ABN_CHECK,
      );
      abnCheckColumn.suppressColumnsToolPanel = true;
      abnCheckColumn.hide = true;
      agGridInstance.api.setColumnDefs(columnDefs);
    }
  };

  const columnDefs = useMemo(
    () =>
      defaultConsignmentColumns(
        isHooksSale,
        hasReceivalLotPermission,
        speciesId,
      ),
    [isHooksSale, hasReceivalLotPermission, speciesId],
  );
  return (
    <AgGridTable
      additionalActions={additionalActions}
      columnDefs={columnDefs}
      context={context}
      doesExternalFilterPass={doesExternalFilterPass}
      downloadFilename={`${saleDate}-${saleyard}-Consignments.csv`}
      extraHeaderComponents={extraHeaderComponents}
      getContextMenuItems={getContextMenuItems}
      getRowId={getRowId}
      isExternalFilterPresent={isExternalFilterPresent}
      rowData={rowData}
      showSimpleAgencyFilter
      tableName={tableName}
      onGridReady={onGridReady}
      rowClassRules={hasReceivalLotPermission ? rowClassRules : undefined}
    />
  );
};

const LoadingWrapper = props => (
  <WaitForSync
    requiredData={[
      ApiModel.SALE_LOTS,
      ApiModel.CONSIGNMENTS,
      ApiModel.DEPLOYMENTS,
      ApiModel.SALEYARDS,
      ApiModel.ROUNDS,
      ApiModel.SPECIES,
    ]}
    subjectName="consignments"
  >
    <ConsignmentsTable {...props} />
  </WaitForSync>
);

export default LoadingWrapper;
