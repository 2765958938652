import queryString from "query-string";

import { DRESSING_RANGE } from "constants/actionTypes";

import { serializeDressingRanges } from "lib/serializers/dressingRanges";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/dressing-ranges/", id, action),
    query: {},
  });

export const DressingRangesAction = offlineActionCreator(
  DRESSING_RANGE,
  serializeDressingRanges,
  urlCreator,
  false,
);
