import React, { memo, useMemo } from "react";

import { Form, Formik } from "formik";

import { FormCollapse } from "components/Form";
import { Column } from "components/Layout";

const TransactionsComponent = ({
  masterBusinessId,
  namespace: ns,
  isOpen,
  onToggle,
  headerComponent: HeaderComponent,
  tableComponent: TableComponent,
  validationSchema,
  initialValuesList,
  dataTour,
}) => {
  const initialValues = useMemo(
    () => ({
      editingValue: null,
      values: initialValuesList,
    }),
    [initialValuesList],
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<HeaderComponent />}
      id={ns}
      dataTour={dataTour}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Column fullWidth>
          <Form>
            <TableComponent masterBusinessId={masterBusinessId} />
          </Form>
        </Column>
      </Formik>
    </FormCollapse>
  );
};

export default memo(TransactionsComponent);
