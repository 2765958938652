import * as Yup from "yup";

import generalFormValidationSchema, {
  positiveWhenSet,
} from "components/GeneralSaleLotForm/validationSchema";
import penningFormValidationSchema from "components/PenningSaleLotForm/validationSchema";

import { SaleTypes } from "constants/sale";
import { SaleLotNamespaces } from "constants/saleLots";

import { requiredWhenSet } from "lib/validation";

export const baseValidationShape = {
  lot_number: Yup.number()
    .nullable()
    .typeError("This field must be a number")
    .integer("This field must be integer")
    .max(100000, "Must be <= 100000"),
  total_mass_grams: Yup.number()
    .nullable()
    .min(0, "Total Mass must be greater than 0")
    .integer("Only 3 decimals allowed"),
  buyer_id: Yup.string().nullable().when("unit_price", requiredWhenSet),
  buyer_way: Yup.object()
    .shape({
      id: Yup.string(),
      string: Yup.string(),
    })
    .nullable(),
  destination_property_id: Yup.string().nullable(),
  set_as_default: Yup.boolean().nullable(),
  quantity_delivered: Yup.number()
    .nullable()
    .typeError("This field must be a number")
    .integer("This field must be integer"),
  quantityTagsUsed: Yup.number()
    .nullable()
    .typeError("This field must be a number")
    .integer("This field must be integer"),
  unit_price: Yup.number().nullable().typeError("This field must be a number"),
  skin_quantity: Yup.number().when("skin_pricing_type", requiredWhenSet),
  skin_price: Yup.number().when("skin_pricing_type", requiredWhenSet),
  draftingAttributes: Yup.object().shape({
    minAge: Yup.number()
      .nullable()
      .typeError("This field must be a number")
      .integer("This field must be a whole number"),
    maxAge: Yup.number()
      .nullable()
      .typeError("This field must be a number")
      .integer("This field must be a whole number"),
  }),
  bidderNumber: Yup.number()
    .nullable()
    .typeError("This field must be a number"),
  ...generalFormValidationSchema,
};

const hooksValidationSchema = Yup.object().shape(baseValidationShape);
const defaultValidationSchema = Yup.object().shape({
  ...baseValidationShape,
  auction_pen: Yup.object().shape({
    ...penningFormValidationSchema,

    start_pen: penningFormValidationSchema.start_pen.when("buyer_id", {
      is: value => value,
      then: Yup.number().required("Must be Penned when Sold"),
    }),
  }),
  deliveryPen: Yup.object().shape(penningFormValidationSchema),
});
const clearingValidationSchema = Yup.object().shape({
  ...baseValidationShape,
  [SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES]: Yup.object().shape({
    status: Yup.string().required("Status is required"),
  }),
  listingId: Yup.string().required("Listing Id is required"),
  quantity: Yup.number()
    .integer("Quantity/Units must be a whole number.")
    .min(0, "Quantity/Units must be 0 or more.")
    .required("Quantity/Units required.")
    .when("unit_price", positiveWhenSet),
  pickupAddress: Yup.object().when(
    [
      "useConsignmentPickupDetails",
      "earliestPickupDate",
      "latestPickupDate",
      "pickupNotes",
    ],
    {
      is: (
        useConsignmentPickupDetails,
        earliestPickupDate,
        latestPickupDate,
        pickupNotes,
      ) =>
        // When the user is not using the Consignment's pickup details, and they have entered a non-default value into any of the `earliestPickupDate`, `latestPickupDate` or `pickupNotes` fields,
        // then the `pickupAddress` field is required
        !useConsignmentPickupDetails &&
        (earliestPickupDate !== null ||
          latestPickupDate !== null ||
          pickupNotes !== ""),
      then: a => a.nullable().required("You must provide a Pickup Address"),
      otherwise: a => a.nullable(),
    },
  ),
});

export const getDetailedSaleLotValidationSchema = saleType => {
  switch (saleType) {
    case SaleTypes.OVER_HOOKS:
      return hooksValidationSchema;
    case SaleTypes.PADDOCK:
      return hooksValidationSchema;
    case SaleTypes.CLEARING:
      return clearingValidationSchema;
    default:
      return defaultValidationSchema;
  }
};
