import React from "react";

import { PropTypes } from "prop-types";

import Badge from "components/Badge";

import { MasterRuleStatusTooltip } from "constants/rules";

import { AgriNousLogo } from "img/AgriNousActiveLogo";

export const StatusRenderer = props => {
  const { value, node } = props;
  if (!value) {
    return null;
  }

  // If it is grouped, we need to pull out the statusRenderProps a different way
  const accessor = node?.rowGroupColumn?.colDef?.statusRendererProps
    ? node.rowGroupColumn.colDef.statusRendererProps
    : props.colDef.statusRendererProps;

  const { colorMap = {} } = accessor || {};
  return <Badge color={colorMap[value]}>{value.replace(/_/g, " ")}</Badge>;
};

StatusRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  colDef: PropTypes.shape({
    statusRendererProps: PropTypes.shape({
      colorMap: PropTypes.object.isRequired,
    }),
  }),
};

export function RuleSyncStatusRenderer(props) {
  const { data, value: status } = props;

  if (!data) {
    return null;
  }
  const { color, backgroundColor } = MasterRuleStatusTooltip[status];

  return (
    <AgriNousLogo
      backgroundColor={backgroundColor}
      color={color}
      scale={0.4}
      size={25}
    />
  );
}
