export const ApiModel = {
  AGENCIES: "AGENCIES",
  AUCTION_PENS: "AUCTION_PENS",
  BIDDERS: "BIDDERS",
  BILLING_DATA: "BILLING_DATA",
  BILLING_DOCUMENTS: "BILLING_DOCUMENTS",
  BILLING_RUNS: "BILLING_RUNS",
  BUSINESS_USER_ROLES: "BUSINESS_USER_ROLES",
  BUSINESSES: "BUSINESSES",
  CHECKPOINTS: "CHECKPOINTS",
  COMMENTS: "COMMENTS",
  CONSIGNABLE_SALES: "CONSIGNABLE_SALES",
  CONSIGNMENTS: "CONSIGNMENTS",
  DEPLOYMENTS: "DEPLOYMENTS",
  DRAFTING_INFORMATION: "DRAFTING_INFORMATION",
  ECONTRACTS: "ECONTRACTS",
  EMAIL: "EMAIL",
  ENVD_DOCUMENTS: "ENVD_DOCUMENTS",
  FILES: "FILES",
  INTEGRATION_BUSINESSES: "INTEGRATION_BUSINESSES",
  INTEGRATION_CREDENTIALS: "INTEGRATION_CREDENTIALS",
  LEDGER_ACCOUNTS: "LEDGER_ACCOUNTS",
  LEDGER_ENTRIES: "LEDGER_ENTRIES",
  LIVESTOCK_AGENT_ROLES: "LIVESTOCK_AGENT_ROLES", // Note - this is different to LIVESTOCK_AGENTS, in that this is MY roles
  LIVESTOCK_AGENTS: "LIVESTOCK_AGENTS",
  MANUAL_ADJUSTMENTS: "MANUAL_ADJUSTMENTS",
  MASTER_LEDGER_ACCOUNTS: "MASTER_LEDGER_ACCOUNTS",
  MASTER_RULE_BOOKS: "MASTER_RULE_BOOKS",
  MASTER_RULES: "MASTER_RULES",
  NLIS_P2P_FILES: "NLIS_P2P_FILES",
  NLIS_SELL_FILES: "NLIS_SELL_FILES",
  NLIS_SELL_REVERSALS: "NLIS_SELL_REVERSALS",
  NLIS_TAKE_FILES: "NLIS_TAKE_FILES",
  NLIS_TAKE_REVERSALS: "NLIS_TAKE_REVERSALS",
  NOMINATIONS: "NOMINATIONS",
  NVD_UPLOADS: "NVD_UPLOADS",
  PAYMENTS: "PAYMENTS",
  PEN_ARCHETYPES: "PEN_ARCHETYPES",
  PEN_SCAN_LOTS: "PEN_SCAN_LOTS",
  PERMISSION_GROUPS: "PERMISSION_GROUPS",
  PROPERTIES: "PROPERTIES",
  RECEIVAL_LOTS: "RECEIVAL_LOTS",
  REPORT_JOBS: "REPORT_JOBS",
  ROLES: "ROLES",
  ROUNDS: "ROUNDS",
  RULE_BOOKS: "RULE_BOOKS",
  RULES: "RULES",
  SALE_DEFINITIONS: "SALE_DEFINITIONS",
  SALE_LOTS: "SALE_LOTS",
  SALE_VENDOR_SPLITS: "SALE_VENDOR_SPLITS",
  SALE_WATCHER_ROLES: "SALE_WATCHER_ROLES",
  SALES: "SALES",
  SALEYARD_ADMIN_ROLES: "SALEYARD_ADMIN_ROLES", // Note - this is different to SALEYARD_ADMINS, in that this is MY roles
  SALEYARD_ADMINS: "SALEYARD_ADMINS",
  SALEYARDS: "SALEYARD",
  SCALE_OPERATOR_ROLES: "SCALE_OPERATOR_ROLES",
  SCANS: "SCANS",
  SINGLE_WEIGH: "SINGLE_WEIGH",
  SPECIES: "SPECIES",
  TRADING_TERMS: "TRADING_TERMS",
  WEIGH_LOT_SCANS: "WEIGH_LOT_SCANS",
  WEIGH_LOTS: "WEIGH_LOTS",
};
