import * as diff from "diff";
import { createSelector } from "reselect";

import { PenTypes } from "constants/auctionPens";

import { sortByStartPen, sortPenByOrder } from "lib/auctionPens";

import {
  getAuctionPens,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";

const roundSelector = (state, props) => props.round;
const selectedPenIdsSelector = (state, props) => props.selectedPenIds;
const tempOrderSelector = (state, props) => props.tempOrder;

export const getAuctionPensByRoundSelector = createSelector(
  [
    getAuctionPens,
    roundSelector,
    selectedPenIdsSelector,
    tempOrderSelector,
    selectSaleLotIdsByAuctionPenIdLookup,
  ],
  (
    auctionPens,
    round,
    selectedPenIds,
    tempOrder,
    saleLotIdsByAuctionPenIdLookup,
  ) => {
    const saleRoundPens = Object.values(auctionPens).filter(
      pen => pen.saleRoundId === round && pen.penType === PenTypes.SELLING,
    );

    const naturalOrder = sortByStartPen(saleRoundPens);
    const overrideOrder = sortPenByOrder(saleRoundPens);
    const justId = thing => thing.id;

    const reorderedPenIds = [
      ...new Set(
        diff
          .diffArrays(overrideOrder.map(justId), naturalOrder.map(justId))
          .filter(diff => diff.added || diff.removed)
          .map(diff => diff.value)
          .flat(),
      ),
    ];

    const touchedPenIds =
      tempOrder &&
      diff
        .diffArrays(tempOrder.map(justId), overrideOrder.map(justId))
        .filter(diff => diff.added || diff.removed)
        .map(diff => diff.value)
        .flat();

    const enhancePen = (auctionPen, index) => ({
      ...auctionPen,
      selected: selectedPenIds.includes(auctionPen.id),
      touched: touchedPenIds && touchedPenIds.includes(auctionPen.id),
      reordered: reorderedPenIds.includes(auctionPen.id),
      hasLots: (saleLotIdsByAuctionPenIdLookup[auctionPen.id]?.length || 0) > 0,
      index,
    });

    return (
      (tempOrder && tempOrder.map(enhancePen)) || overrideOrder.map(enhancePen)
    );
  },
);
