import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

import { ConsignmentPermissions } from "constants/permissions";

import { openEditConsignmentModal } from "lib/navigation";
import { hasPermission } from "lib/permissions";

export function PICRenderer(props) {
  const { value, data } = props;
  if (!data) {
    return null;
  }

  function onClickEdit() {
    openEditConsignmentModal(data.id, undefined, true, false);
  }

  const hasChangeConsignmentPermission = hasPermission(
    data,
    ConsignmentPermissions.update,
  );

  if (value !== undefined) {
    return value;
  } else {
    return (
      <FaIcon
        icon={faExclamationCircle}
        color="warning"
        onClick={hasChangeConsignmentPermission ? onClickEdit : undefined}
        style={
          hasChangeConsignmentPermission ? { cursor: "pointer" } : undefined
        }
      />
    );
  }
}
