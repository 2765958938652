import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { EContractAction } from "actions";

import { PDFPreview } from "components/BillingWorkFlow/PDFPreview";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, DeleteButton } from "components/Form";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Dialog, DialogActions, DialogTitle } from "components/MaterialDialog";

import { EContractParty } from "constants/eContract";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  EContractPermissions,
} from "constants/permissions";

import { getEContractStatus } from "lib/econtracts";
import { getReportJobUrl } from "lib/navigation";
import { hasPermission } from "lib/permissions";
import toast from "lib/toast";

import { getActiveRole, getEContractById } from "selectors";

import history from "appHistory";
import {
  useBoolean,
  useHasDeploymentPermission,
  useHasPermission,
  useIsMobile,
  useModalAdapter,
} from "hooks";

import { EContractSendEmailModal } from "./sendEmailModal";

const EContractModalContent = props => {
  const { eContractId, onClose } = props;

  const dispatch = useDispatch();
  const { slug: activeRoleSlug } = useSelector(getActiveRole);
  const eContract = useSelector(getEContractById(eContractId));
  const [isVoidDialogShowing, showVoidDialog, hideVoidDialog] =
    useBoolean(false);

  const [emailDialogParty, setEmailDialogParty] = useState(null);

  const canVoid = hasPermission(eContract, EContractPermissions.void);
  const canAccept = hasPermission(eContract, EContractPermissions.accept);

  const voidEContract = () => {
    dispatch(EContractAction.void({ id: eContract.id }));
    hideVoidDialog();
  };

  const { voidedOn, agentAcceptedOn } = eContract;
  const eContractStatus = getEContractStatus(eContract);

  const documentPreviewUrl = getReportJobUrl(
    eContract.documentId,
    activeRoleSlug,
  );

  return (
    <>
      <DialogTitle onClose={onClose}>eContract - {eContractStatus}</DialogTitle>
      <PDFPreview url={documentPreviewUrl} />
      <DialogActions>
        <DeleteButton disabled={voidedOn || !canVoid} onClick={showVoidDialog}>
          Void eContract
        </DeleteButton>
        <Button
          disabled={!canAccept}
          onClick={() => setEmailDialogParty(EContractParty.BUYER)}
        >
          {agentAcceptedOn || voidedOn ? "" : "Accept and "} Send to Buyer
        </Button>
        <Button
          disabled={!canAccept}
          onClick={() => setEmailDialogParty(EContractParty.VENDOR)}
        >
          {agentAcceptedOn || voidedOn ? "" : "Accept and "} Send to Vendor
        </Button>
      </DialogActions>
      {isVoidDialogShowing ? (
        <ConfirmDialog
          buttonMessage="Void"
          isOpen={isVoidDialogShowing}
          onCancel={hideVoidDialog}
          onDelete={voidEContract}
          title="Confirm you want to void this eContract"
        />
      ) : null}
      {emailDialogParty ? (
        <EContractSendEmailModal
          eContractId={eContractId}
          onClose={() => setEmailDialogParty(null)}
          party={emailDialogParty}
        />
      ) : null}
    </>
  );
};

export const EContractModal = props => {
  const { eContractId, onClose, returnTo } = props;

  const fullScreen = useIsMobile();

  const hasFeaturePermission = useHasDeploymentPermission(
    DeploymentPermissions.featureEContracts,
  );
  const hasReadPermission = useHasPermission(
    getEContractById(eContractId),
    EContractPermissions.read,
  );

  if (!hasFeaturePermission || !hasReadPermission) {
    // don't display model if feature permission missing
    toast.error("You are unable to view this eContract");
    if (returnTo) {
      history.push(returnTo);
    }
    return null;
  }

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xl"
      height="100%"
      fullWidth
      fullScreen={fullScreen}
    >
      <WaitForSync
        requiredData={[
          ApiModel.BUSINESSES,
          ApiModel.ECONTRACTS,
          ApiModel.LIVESTOCK_AGENTS,
        ]}
        subjectName="eContract"
      >
        <EContractModalContent onClose={onClose} eContractId={eContractId} />
      </WaitForSync>
    </Dialog>
  );
};

export function EContractModalAdapter(props) {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.EContract,
    props,
  );
  const { id: eContractId } = hashParams;

  return (
    <WaitForSync requiredData={[ApiModel.DEPLOYMENTS, ApiModel.ECONTRACTS]}>
      <EContractModal
        eContractId={eContractId}
        onClose={onClose}
        returnTo={returnTo}
      />
    </WaitForSync>
  );
}
