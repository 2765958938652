import { ModalTypes } from "constants/navigation";

import CarrierChargeModalAdapterFactory from "./adapterFactory";

const ConsignmentLotAdapter = CarrierChargeModalAdapterFactory(
  "CONSIGNMENT",
  ModalTypes.ConsignmentCarrierCharge,
);

export default ConsignmentLotAdapter;
