/**
 * Business Fields
 */
import { buildSerializer } from "lib/serializerUtils";

export const tblClientFields = [
  "strgClientName",
  "strgClientPIC",
  "strgClientAddress",
  "strgClientAddress1",
  "strgClientLocation",
  "strgClientState",
  "strgClientPostCode",
  "strgClientPhone",
  "strgClientEmail",
  "strgClientMobile",
  "strgContact",
  "strgClientCode",
  "strgClientABN",
];

const tblClientFieldsSerializerSerializeMap = {
  strgClientName: "businessName",
  strgClientPIC: "businessPIC",
  strgClientAddress: "addressingInformation",
  strgClientAddress1: "",
  strgClientLocation: "locality",
  strgClientState: "state",
  strgClientPostCode: "postalCode",
  strgClientPhone: "fixedPhoneNumber",
  strgClientEmail: "email",
  strgClientMobile: "phoneNumber",
  strgContact: "contactFullName",
  strgClientCode: "businessShortCode",
  strgClientABN: "abn",
};

export const tblClientFieldsSerializer = buildSerializer(
  tblClientFieldsSerializerSerializeMap,
);

/**
 * Breed Fields
 */
export const tblBredFields = [
  "strgBreedAnimalType", // Species
  "strgBreedCode", // code
  "strgBreedDesc", // name
];

/**
 * Product Fields
 */
export const tblSexFields = [
  "strgSexAnimalType", // = species
  "strgSexCode", // = import code
  "strgSexDesc", // = description
  // intLMAQCode ?
];

/**
 * Consignment Fields
 */
export const tblSaleTagsFields = [
  // <longSaleID>1</longSaleID>
  "autoSaleTags", // ID <autoSaleTags>071</autoSaleTags>
  "strgVenDecNo", // NVD // <strgVenDecNo>21547393</strgVenDecNo>
  "strgVenPIC", // Vendor PIC // <strgVenPIC>3MRKK055</strgVenPIC>
  // <boolEU>false</boolEU>
  // <strgAgentCode>D</strgAgentCode>
  "strgVendorCode", // vendor short_code_saleyard // <strgVendorCode>FAC</strgVendorCode>
  "intVendorNo", // vendor number // <intVendorNo>46</intVendorNo>
  // <strgYardTag />
  "intTotal", // quantity // <intTotal>1</intTotal>
  // <intHGPTreated />
  // <intHGPFree />
  // <strgHGP />
  "strgQ1", // <strgQ1>N</strgQ1>
  "strgQ2", // <strgQ2>N</strgQ2>
  "strgQ3", // <strgQ3>C</strgQ3>
  "strgQ4", // <strgQ4>N</strgQ4>
  "strgQ5", // <strgQ5>N</strgQ5>
  "strgQ6", // <strgQ6>N</strgQ6>
  "strgQ7", // <strgQ7>N</strgQ7>
  "strgQ8", // <strgQ8>N</strgQ8>
  // <strgChem />
  // <boolLPA>true</boolLPA>
  // <strgTimeArrival>00:00</strgTimeArrival>
  // <strgXPic1 />
  // <strgXPic2 />
  // <strgXPic3 />
  // <strgXPic4 />
  // <boolERPCheck />
  // <strgNLISSaleTags>CLEAR NO TEST; LPA  A  </strgNLISSaleTags>
  // <strgSexCode />
  // <lngLotCount />
  // <lngRFIDCount />
  // <intAgentStat />
  // <strgAgentAuthFirst />
  // <strgAgentAuthLast />
  // <intAuthority />
  // <strgAuthority1 />
  // <strgAuthority2 />
  // <intDisclaimer />
  // <boolSE>true</boolSE>
  // <boolRE>true</boolRE>
  // <boolMSA>False</boolMSA>
  // <boolBO>False</boolBO>
  "dateTimeArrival", // <dateTimeArrival>22/Jul/2020 00:00</dateTimeArrival>
  // <strgCurfewPen />
  // <strgMisc1 />
  // <strgMisc2 />
  // <dblClearCommission />
  // <eldersAccountNo />
  // <intNVDSource>2</intNVDSource>
  // <LastModified />
  "NVDVersionNo", // NVD Version // <NVDVersionNo>C0413</NVDVersionNo>
  // <NLISUploadID />
  // <NLISUploadStatus />
  // <NLISUploadMessage />
  // <NLISSvcUploadID />
  // <LEUploadStatus />
  "intNVDTotal", // NVD HC // <intNVDTotal>1</intNVDTotal>
  // <boolEUClient />
  // <strgVDBrand />
  // <BJDScore />
];

/**
 * SaleLot Fields
 */
export const tblTranFields = [
  // <longSaleID>1</longSaleID>
  "longSaleTagsID", // Also consignment id
  "autoSaleTags", // consignment id
  "autoTran", // 'id'
  // <autoTran>136</autoTran>
  // <longCtrlLink>1</longCtrlLink>
  "strgLotBuyer", // Buyer

  "strgLotBuyerAccount", // buyer way // <strgLotBuyerAccount />
  "strgLotBuyerPIC", // buyer pic
  // <strgLotPrefix />
  // <intLotNo>136</intLotNo>
  "intLotHead", // quantity

  "strgLotSex", // product code
  "strgLotBreed", // Breed code
  "strgLotPaint", // marks
  // <strgLotPaint>NM</strgLotPaint>
  // <strgLotBrand />
  // <strgLotYardTags />

  "boolLotOpenAuction", // c/Kg if false else $/hd // <boolLotOpenAuction>True</boolLotOpenAuction>
  // <boolLotPassedIn>False</boolLotPassedIn>
  // <boolLotCanceled>False</boolLotCanceled>
  "dblLotWeight", // <dblLotWeight>0.0000</dblLotWeight>
  // <dblLotAvgWeight>0.000000000000000</dblLotAvgWeight>
  // <currLotCentsPerKg />
  // <currLotDollar>10260.0000</currLotDollar>
  "currLotDollar", // "total_price_dollars"

  // <currLotAvgDollar>1140.0000</currLotAvgDollar>
  // <dateLotTimeWeighed />
  // <strgLotAnimalType>CATTLE</strgLotAnimalType>
  "strgLotAnimalType", // species"

  // <strgLotBuyerRef />
  // <intLotFatScore />
  // <boolLotOR />
  // <strgLotAudit />
  // <boolLotAgentSalePrinted />
  // <longChequeNo />
  // <boolLockTotal />
  // <boolLotGrainfed />
  "currLotGST",
  // <currLotGSTRetain />
  // <boolLotGSTExempt />
  "strgLotPen", // auction pen
  // <strgLotPen>087</strgLotPen>
  // <longLotBuyerNumber />
  // <intLotRingStatus />
  // <strgFreeText />
  // <boolDoTransfer />
  // <lngRFIDCount>9</lngRFIDCount>
  // <intLotHead1 />
  // <boolMarket />
  // <boolAgentLock />
  // <strgLotDescription>Angus &amp; Blk Baldy HEIFERS</strgLotDescription>
  // <dblTranCommission />
  // <boolLotNoBid />
  // <ccurLotReserve />
  // <boolLotPaid />
  // <currCommission />
  // <currCommissionGST />
  // <lotPaymentMethod />
  // <lotPaymentAmount />
  // <lotPaymentDesc />
  // <lotSelectForPayment />
  // <currLotTotalIncGST />
  // <boolBuyerFinal>False</boolBuyerFinal>
  // <boolAgentFinal>False</boolAgentFinal>
  // <LotSex />
  // <SalesResult />
  // <SalesMethod />

  "boolDeceased", // <boolDeceased>False</boolDeceased>
  // <boolLotWithdrawn>False</boolLotWithdrawn>
  // <PaymentID>0</PaymentID>
  // <boolInclude />
  // <boolPaidDirect />
  // <LastModified />
  // <boolBulkWeigh />
  // <ExhibitorID />
  // <ShowClass />
  // <longBulkPaymentID />
  // <intSplit />
];

export const tblRFIDFields = [
  //   <newRecord TableName="tblRFID">
  // <longSaleID>20001038</longSaleID>
  "longSaleTagsID", // consignment id // <longSaleTagsID>20204665</longSaleTagsID>
  "longTranID", // Sale lot id // <longTranID>20715624</longTranID>
  "strgRFID", // 'eid' // <strgRFID>951 010000981070</strgRFID>
  // <autoRFID>21507163</autoRFID>
  // <strgLastPIC>ND230030LBN06150</strgLastPIC>
  // <boolEligible>False</boolEligible>
  // <boolEUChecked>False</boolEUChecked>
  // <boolLT>True</boolLT>
  // <boolLTChecked>True</boolLTChecked>
  // <boolPossession>False</boolPossession>
  // <boolTransferred>False</boolTransferred>
  // <boolPicToPicTransfer>False</boolPicToPicTransfer>
  // <strgNLISEligible/>
  // <strgNLISLT>Y</strgNLISLT>
  // <strgNLISPossession/>
  // <strgNLISTransfer/>
  // <strgNLISPicToPicTransfer/>
  // <lngPosID>1500</lngPosID>
  // <lngTransID>0</lngTransID>
  // <dblLiveWeight>0</dblLiveWeight>
  // <strgVisualID/>
  // <strgPaint/>
  // <intTransferWarning>0</intTransferWarning>
  // <intPossessionWarning>0</intPossessionWarning>
  // <boolExtChecked>False</boolExtChecked>
  // <strgNLISExt/>
  // <boolManualPossession>False</boolManualPossession>
  // <datelPossession>02/Jul/2018 11:55</datelPossession>
  // <boolManualTransfer>False</boolManualTransfer>
  // <boolManualTransfer>False</boolManualTransfer>
  // <dateTransfer>02/Jul/2018 11:55</dateTransfer>
  // <dateTimeofArrival>02/Jul/2018 11:55</dateTimeofArrival>
  // <boolPDA>False</boolPDA>
  // </newRecord>
];

export const tblFeeGroup = [
  "strgFeeGroup",
  "strgFeeDesc",
  // <strgFeeGroup>AUCTIONS+</strgFeeGroup>
  // <strgFeeDesc>AuctionsPlus Fees</strgFeeDesc>
  "bytFeePayBy",
  "bytFeePayTo",
  // <bytFeePayBy>1</bytFeePayBy>
  // <bytFeePayTo>2</bytFeePayTo>
  // <boolAccountSale>True</boolAccountSale>
  // <boolInvoice>False</boolInvoice>
  // <boolSaleyard>False</boolSaleyard>
  // <boolAutoApply>False</boolAutoApply>
  // <strgQBAccount/>
  // <strgQB3PAccount/>
  // <strgQBGSTAccount/>
  // <boolGSTEx>False</boolGSTEx>
  // <currBalance>0</currBalance>
  // <boolGSTInc>False</boolGSTInc>
  // <boolBulkCollect>False</boolBulkCollect>
  // <dateLastUpdated>30/Aug/2022 14:36</dateLastUpdated>
  // <boolSellingAgentFee>False</boolSellingAgentFee>
];

// export const tblFeeDetail = [
//   "",
//   //   <autoFeeDetID>12</autoFeeDetID>
//   //   <strgFeeDetGroup>COMMWORKS</strgFeeDetGroup>
//   //   <strgFeeDetComment>Vendor Commission</strgFeeDetComment>
//   //   <strgFeeDetSQL>strgSaleSCCode = 'PRI'</strgFeeDetSQL>
//   //   <strgFeeCalc>0</strgFeeCalc>
//   //   <intFeeType>0</intFeeType>
// ];

export const tblSaleFees = [
  // <autoSaleFees>23342</autoSaleFees>
  // <longSaleFeesSaleId>268</longSaleFeesSaleId>
  "strgSaleFeesFeeGroup",
  // <strgSaleFeesFeeGroup>AUCTIONS+</strgSaleFeesFeeGroup>
  // <strgSaleFeesAgent>A</strgSaleFeesAgent>
  "strgSaleFeesAgent",
  "strgSaleFeesVendor",
  // <strgSaleFeesVendor>MOSLEY</strgSaleFeesVendor>
  "strgSaleFeesBuyer",
  // <strgSaleFeesBuyer></strgSaleFeesBuyer>
  "currSaleFeesAmount",
  "currSaleFeesGST",
  "currSaleFeesTotal",
  // <currSaleFeesAmount>1140.75</currSaleFeesAmount>
  // <currSaleFeesGST>114.08</currSaleFeesGST>
  // <currSaleFeesTotal>1140.75</currSaleFeesTotal>
  // <boolAutomatic>False</boolAutomatic>
  // <strgSaleFees3P></strgSaleFees3P>
  // <strgMemo></strgMemo>
  // <longCtrlLink>1</longCtrlLink>
  // <PaymentID>0</PaymentID>
  // <boolInclude>True</boolInclude>
  // <boolPaidDirect>False</boolPaidDirect>
  // <boolSpare>False</boolSpare>
  // <longBuyerNumber></longBuyerNumber>
  // <strgBuyerAccount></strgBuyerAccount>
  // <PaidStatusOfThisFee>False</PaidStatusOfThisFee>
  // <intFeeHeadCount>0</intFeeHeadCount>
];
