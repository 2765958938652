import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import uniqBy from "lodash/uniqBy";
import { useSelector } from "react-redux";

import { ActionButton } from "components/AgGrid/ActionButton";
import AgGrid from "components/AgGrid/AgGrid";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { SlimSecondaryButton } from "components/Button";
import { FormCollapse } from "components/Form";
import { CurrentSaleReadOnly } from "components/ReadOnly";
import { saleLotCommentsColumnId } from "components/SaleLotsTable/columns/commentsColumn";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import { CommentTypes } from "constants/comments";
import { SaleLotModalSection } from "constants/navigation";
import { ConsignmentPermissions } from "constants/permissions";

import { BaseColumnDefinitions } from "lib/agGrid/columnDefinitions";
import { openCommentsModal, openEditSaleLotModal } from "lib/navigation";

import {
  getConsignmentById,
  selectConsignmentModalSaleLotsAggridData,
} from "selectors";

import { useHasPermission } from "hooks/useHasPermission";

const onClickDraftLot = (consignmentId, saleLotId = null) =>
  openEditSaleLotModal(
    saleLotId,
    SaleLotModalSection.GENERAL,
    "",
    consignmentId,
    null,
    window.location.hash,
  );

const EditFirstLotButton = ({ data }) => {
  if (data.length === 1) {
    return (
      <Grid item xs={6} sm={6}>
        <SlimSecondaryButton
          type="button"
          onClick={() => onClickDraftLot(data[0].consignment_id, data[0].id)}
          data-tour="action-goto-salelot"
        >
          Edit first Sale Lot
        </SlimSecondaryButton>
      </Grid>
    );
  } else {
    return null;
  }
};

const actionsColumnRenderer = ({ data }) => (
  <ActionButton
    data-tour={`action-goto-salelot:${data.lot_number}`}
    type="button"
    onClick={() => onClickDraftLot(data.consignment_id, data.id)}
  >
    <FontAwesomeIcon icon={faPencil} />
    &nbsp;Edit
  </ActionButton>
);

const defaultColDef = {
  sortable: true,
  resizable: true,
  enableCellChangeFlash: true,
  flex: true,
};

export const SaleLotSection = ({
  isOpen,
  onToggle,
  header,
  consignmentId,
  isCreate,
  isPaddock,
}) => {
  const saleLots = useSelector(state =>
    selectConsignmentModalSaleLotsAggridData(state, consignmentId),
  );

  const columns = React.useMemo(() => {
    const showPen = saleLots.some(s => s.auction_pen?.penName);

    const singlePricingType = uniqBy(saleLots, "pricing_type_id").length === 1;

    return [
      showPen && {
        headerName: "Pen",
        field: "auction_pen.penName",
      },
      {
        headerName: "Hd",
        field: "quantity",
      },
      {
        headerName: "Buyer",
        field: "buyer.name",
      },
      {
        headerName: singlePricingType ? saleLots[0].price_unit : "Price",
        field: "unitPriceFormatted",
      },
      {
        colId: ACTION_COLUMN_ID,
        headerName: ACTION_COLUMN_NAME,
        cellRenderer: actionsColumnRenderer,
        pinned: "right",
        width: 80,
      },
      BaseColumnDefinitions[saleLotCommentsColumnId],
    ].filter(Boolean);
  }, [saleLots]);

  const handleClickSaleLotComments = ({ id }) =>
    openCommentsModal(CommentTypes.SALE_LOT, id, window.location.hash);

  const context = {
    handleClickSaleLotComments,
  };

  const hasAddSaleLotPermission = useHasPermission(
    getConsignmentById(consignmentId),
    ConsignmentPermissions.canAddSaleLot,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      dataTour={isOpen ? "hideSaleLots" : "showSaleLots"}
    >
      <Grid container justifyContent="center" spacing={2}>
        {isPaddock && <EditFirstLotButton data={saleLots} />}
        <CurrentSaleReadOnly>
          {!isCreate && hasAddSaleLotPermission && (
            <Grid item xs={6} sm={6} container justifyContent="center">
              <SlimSecondaryButton
                type="button"
                onClick={() => onClickDraftLot(consignmentId)}
                data-tour="draftNew"
              >
                Draft new Sale Lot
              </SlimSecondaryButton>
            </Grid>
          )}
        </CurrentSaleReadOnly>
        {saleLots.length > 0 && (
          <Grid item xs={12}>
            <CollapseTableWrapper>
              <AgGrid
                rowData={saleLots}
                suppressColumnVirtualisation
                suppressScrollOnNewData
                columnDefs={columns}
                rowBuffer={10}
                defaultColDef={defaultColDef}
                context={context}
              />
            </CollapseTableWrapper>
          </Grid>
        )}
      </Grid>
    </FormCollapse>
  );
};
