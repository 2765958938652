import React from "react";

import { faCheck, faLink, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import OutlineButton from "components/Button/OutlineButton";
import { SearchInput, SecondaryButton } from "components/Form";
import { CenteredGreedy, Column, Row } from "components/Layout";
import {
  DialogContent,
  DialogTitle,
  ZoomyDialog as Dialog,
} from "components/MaterialDialog";
import Property from "components/Property";
import { BoldText } from "components/Text";

import {
  BUSINESS_ROLE_FILTERS,
  BUSINESS_ROLE_PLURAL,
  VENDOR,
} from "constants/businesses";
import { Key } from "constants/hotKey";
import { Settings } from "constants/settings";

import { ForSaleyardAdmin } from "containers/ForUserType";

import { validatePIC } from "lib/PICValidator";
import { getDefaultPropertyId } from "lib/properties";

import { getAgencyCodesByBusinessId } from "selectors";

import { AddRow } from "./AddBusinessPIC";
import { TopRow } from "./TopRow";

const ResultsBlock = styled(Column)`
  flex-grow: 1;
  min-height: 20%;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.gray78};
  margin-top: 6px;
  & > div:nth-last-child(even) {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const ResultRow = styled(Row)`
  width: 100%;
  min-height: ${({ theme }) => theme.space[4]}px;
  align-items: center;
  padding: 0 12px;
`;

const ResultText = styled(ResultRow)`
  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const CollapsibleRow = styled(Column)`
  margin: 0 -12px;
  padding: ${({ theme }) => `${theme.space[1]}px ${theme.space[2]}px`};
  flex-grow: 2;
  min-height: 100px;
  ${({ theme, isOpen }) => `
      ${
        isOpen
          ? "overflow-y: auto; opacity: 1;"
          : "height: 0px; overflow-y: hidden; opacity: 0;"
      }
      transition: ${theme.transitions[0]};
  `}
`;

export const ActionIcon = styled(FontAwesomeIcon)`
  margin: 0 ${({ theme }) => theme.space[1]}px;
`;

export const ActionText = styled.span`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints[1]}px) {
    display: none;
  }
`;

export const ActionButton = styled(OutlineButton)`
  margin: 0 ${({ theme }) => theme.space[2]}px;
  white-space: nowrap;
`;

const FlagMissingText = styled.span`
  ${({ theme }) => `color: ${theme.colors.lightCoral}`};
  margin-left: ${({ theme }) => theme.space[1]}px;
`;

const AgencyCodes = ({ businessId, showAgencyCodes }) => {
  const agencyCodes = useSelector(getAgencyCodesByBusinessId(businessId));

  if (!showAgencyCodes || agencyCodes.length === 0) {
    return null;
  }
  const agencyCodesText =
    agencyCodes?.length > 3
      ? `*MANY*`
      : agencyCodes?.map(code => code).join(", ");
  return (
    <CenteredGreedy>
      <BoldText color="primary">{agencyCodesText}</BoldText>
    </CenteredGreedy>
  );
};

function normaliseKeyword(keyword) {
  return (
    keyword
      .toLowerCase()
      // remove all non-alphanumeric characters, and replace them with spaces
      .replace(/[^a-z0-9]+/g, " ")
      // replace any double spaces with a single space
      .replace(/\s{2,}/g, " ")
      .trim()
  );
}

export function filterResults(tokens, lookup, config) {
  if (tokens.length === 0) {
    return [];
  }
  const {
    businessRoles = [],
    businessRoleFilterValue,
    buyerWayName,
    saleyardId,
  } = config;

  let matchingResults = [];

  const resultSet = lookup;

  const searchIndex = Object.keys(resultSet);
  const searchInputKeywords = tokens.map(normaliseKeyword);
  const activeFilters = businessRoles.filter(
    businessRole => businessRoleFilterValue[businessRole],
  );

  // If there is a business attr filter active, and this result has a business that matches, show it.
  // also show those that have no business.
  const businessRoleFilterFilter = result => {
    if (!result.business) {
      return true;
    }
    if (activeFilters.length > 0) {
      return activeFilters.some(
        businessRole => result.business[BUSINESS_ROLE_FILTERS[businessRole]],
      );
    }
    return true;
  };

  for (const keyword of searchIndex) {
    const normalisedIndex = normaliseKeyword(keyword);
    for (let i = 0; i < searchInputKeywords.length; i += 1) {
      const searchCriteria = searchInputKeywords[i];
      if (normalisedIndex.indexOf(searchCriteria) > -1) {
        const rank =
          i === searchInputKeywords.length - 1
            ? searchCriteria.length * 4
            : searchCriteria.length;
        const toAdd = resultSet[keyword].filter(businessRoleFilterFilter);
        matchingResults = matchingResults.concat(
          toAdd.map(v => ({ ...v, rank })),
        );
      }
    }
  }

  const actualResults = {};
  matchingResults.forEach(res => {
    const { business, property, rank, matches } = res;
    const key = `${business ? business.id : "."}__${
      property ? property.id : "."
    }`;
    // Rank the default property a smidge above the other results on the same
    // level, so it's first among the similar-results.
    const defaultPropertyModifier =
      property &&
      getDefaultPropertyId(business, saleyardId, buyerWayName) === property.id
        ? 0.5
        : 0;
    if (actualResults[key]) {
      actualResults[key].rank += rank + defaultPropertyModifier;
    } else {
      actualResults[key] = {
        business,
        property,
        matches,
        rank: rank + defaultPropertyModifier,
      };
    }
  });

  return Object.values(actualResults).sort((a, b) => {
    // Sort by rank, business name, then property PIC.
    if (a.rank > b.rank) {
      return -1;
    } else if (a.rank < b.rank) {
      return 1;
    }
    if (a.business && b.business) {
      if (a.business.name < b.business.name) {
        return -1;
      } else if (a.business.name > b.business.name) {
        return 1;
      }
    }
    if (a.property && b.property) {
      if (a.property.PIC < b.property.PIC) {
        return -1;
      } else if (a.property.PIC > b.property.PIC) {
        return 1;
      }
    }
    return 0;
  });
}

function textToTokens(text) {
  return text.split(/\s+/).filter(t => t !== "");
}

export function textToSearchTokens(text) {
  const tokens = textToTokens(text).filter(t => t.length >= 2);
  if (text.length >= 2) {
    tokens.push(text.toLowerCase());
  }
  return tokens;
}

const ResultString = ({ business, property, saleyardId }) => {
  const result = [""];
  if (business) {
    result[0] += business.name;
  }
  if (business && property) {
    result[0] += " - ";
  }
  if (property) {
    result.push(
      <Property
        key="property"
        propertyId={property.id}
        businessId={business?.id}
      />,
    );
    if (getDefaultPropertyId(business, saleyardId, null) === property.id) {
      result.push("*");
    }
  }
  let missingText = null;
  if (business && !property) {
    missingText = "(Business Only)";
  } else if (property && !business) {
    missingText = "(PIC Only)";
  }
  return (
    <Row justifyCenter alignCenter paddingHorizontal={1}>
      {result}
      {missingText ? <FlagMissingText>{missingText}</FlagMissingText> : null}
    </Row>
  );
};

const Result = ({
  business,
  property,
  handleSelection,
  handlePrefill,
  businessPrefill,
  picPrefill,
  showProperty,
  businessRoles,
  saleyardId,
}) => {
  return (
    <ResultText justifyBetween>
      <Row justifyBetween>
        <ForSaleyardAdmin>
          <AgencyCodes
            businessId={business?.id}
            showAgencyCodes={businessRoles?.includes(VENDOR)}
          />
        </ForSaleyardAdmin>
        <ResultString
          business={business}
          property={property}
          saleyardId={saleyardId}
        />
      </Row>
      <Row justifyBetween>
        {!(business && property) && showProperty ? (
          <ActionButton
            onClick={() => {
              handlePrefill(business || null, property || null);
            }}
            type="button"
          >
            <ActionIcon
              icon={
                (!property && picPrefill) || (!business && businessPrefill)
                  ? faLink
                  : faPlus
              }
            />{" "}
            {!property ? picPrefill || "PIC" : businessPrefill || "BUSINESS"}
          </ActionButton>
        ) : (
          ""
        )}
        <ActionButton
          data-tour="use"
          onClick={() => {
            handleSelection(
              business ? business.id : null,
              property ? property.id : null,
            );
          }}
          type="button"
        >
          <ActionIcon icon={faCheck} />
          <ActionText>USE</ActionText>
        </ActionButton>
      </Row>
    </ResultText>
  );
};

const initialBusinessPropertiesLimit = 5;

const initialPropertyLimit = 5;

const initialBusinessesLimit = 5;

const additionalBusinessPropertiesLimit = 20;

const additionalBusinessesLimit = 10;

const additionalPropertiesLimit = 10;

export class BusinessPICSelectorModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchText: props.searchPrefill || "",
      businessNamePrefill: "",
      PICPrefill: "",
      PICNamePrefill: "",
      activeSection: "search",
      filteredBusinessProperties: [],
      filteredBusinesses: [],
      filteredPICs: [],
      businessPropertyLimit: 0,
      businessLimit: 0,
      propertyLimit: 0,
      isSearching: false,
    };
    this.searchTimeout = null;
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    // Perform a search of the component was mounted with a search criteria
    const { searchText } = this.state;

    const { openOnFocus } = this.props;

    if (openOnFocus) {
      document.addEventListener("keydown", this.handleKeyDown, false);
    }

    if (searchText) {
      this.doSearch(searchText);
    }
  }

  componentWillUnmount() {
    const { openOnFocus } = this.props;

    if (openOnFocus) {
      document.removeEventListener("keydown", this.handleKeyDown, false);
    }
  }

  handleSearch = searchTerm => {
    const { searchText } = this.state;
    // only perform a search if the searchable text has updated
    if (searchTerm.trim() !== searchText.trim()) {
      this.doSearch(searchTerm);
    } else {
      this.setState({
        searchText: searchTerm,
      });
    }
  };

  showMoreBusinessProperties = () => {
    const { businessPropertyLimit } = this.state;
    this.setState({
      businessPropertyLimit:
        businessPropertyLimit + additionalBusinessPropertiesLimit,
    });
  };

  showMoreBusinesses = () => {
    const { businessLimit } = this.state;
    this.setState({
      businessLimit: businessLimit + additionalBusinessesLimit,
    });
  };

  showMoreProperties = () => {
    const { propertyLimit } = this.state;
    this.setState({
      propertyLimit: propertyLimit + additionalPropertiesLimit,
    });
  };

  doSearch = searchText => {
    // Go through the tokens in our search - if any are valid PICs,
    // add them to the PIC field. :D
    const { allowBusinessOnly, allowPropertyOnly } = this.props;

    const businessName = [];
    const propertyName = [];
    let pic = "";
    let foundPIC = false;
    const tokens = textToTokens(searchText);
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];
      if (!foundPIC) {
        if (!validatePIC(token.toUpperCase())) {
          businessName.push(token);
        } else {
          pic = token.toUpperCase();
          foundPIC = true;
        }
      } else {
        propertyName.push(token);
      }
    }

    const searchTokens = textToSearchTokens(searchText);

    clearTimeout(this.searchTimeout);
    // clear the results list and debounce the user input so as not to search unnecessarily
    this.setState({
      searchText,
      businessNamePrefill: businessName.join(" "),
      PICPrefill: pic,
      PICNamePrefill: propertyName.join(" "),
      filteredBusinessProperties: [],
      filteredBusinesses: [],
      filteredPICs: [],
      isSearching: false,
    });
    // debounce the user input (for 380ms)
    this.searchTimeout = setTimeout(() => {
      // Update the state to inform the user that a long running task is in progress
      this.setState({
        isSearching: true,
      });
      // queue/schedule the search to take place, defer to the next tick to prevent interaction issues
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.setState({
          filteredBusinessProperties:
            this.filterBusinessProperties(searchTokens),
          filteredBusinesses: allowBusinessOnly
            ? this.filterBusinesses(searchTokens)
            : [],
          filteredPICs: allowPropertyOnly ? this.filterPICs(searchTokens) : [],
          isSearching: false,
          businessPropertyLimit: initialBusinessPropertiesLimit,
          businessLimit: initialBusinessesLimit,
          propertyLimit: initialPropertyLimit,
        });
      }, 0);
    }, 380);
  };

  clearInput = () => {
    this.setState({
      searchText: "",
      businessNamePrefill: "",
      PICPrefill: "",
      PICNamePrefill: "",
      filteredBusinessProperties: [],
      filteredBusinesses: [],
      filteredPICs: [],
    });
  };

  filterBusinessProperties = tokens => {
    const {
      keywordLookups: { businessPICs },
      businessRoles,
      businessRoleFilterValue,
      buyerWayName,
      saleyardId,
    } = this.props;
    return filterResults(tokens, businessPICs, {
      businessRoles,
      businessRoleFilterValue,
      buyerWayName,
      saleyardId,
    });
  };

  filterBusinesses = tokens => {
    const {
      keywordLookups: { businesses },
      businessRoles,
      businessRoleFilterValue,
      saleyardId,
    } = this.props;
    return filterResults(tokens, businesses, {
      businessRoles,
      businessRoleFilterValue,
      saleyardId,
    });
  };

  filterPICs = tokens => {
    const {
      keywordLookups: { PICs },
      businessRoles,
      businessRoleFilterValue,
      saleyardId,
    } = this.props;
    return filterResults(tokens, PICs, {
      businessRoles,
      businessRoleFilterValue,
      saleyardId,
    });
  };

  handlePrefill = (business, property) => {
    const { PICPrefill, businessNamePrefill, PICNamePrefill } = this.state;
    const stateUpdate = {
      activeSection: "add",
    };
    stateUpdate.businessNamePrefill = business
      ? business.name
      : businessNamePrefill || "";
    stateUpdate.PICPrefill = property || PICPrefill;
    stateUpdate.PICNamePrefill = PICNamePrefill || "";
    if (property) {
      stateUpdate.PICPrefill = property.PIC;
      if (property.name && !PICNamePrefill) {
        stateUpdate.PICNamePrefill = property.name;
      }
    }

    this.setState(stateUpdate);
  };

  toggleSection = sectionName => {
    this.setState({ activeSection: sectionName });
  };

  resetBusinessRoleFilter = businessRole => event => {
    const { searchText } = this.state;
    const { setSetting } = this.props;

    setSetting(
      Settings.businessPICSelectorAttrFilters[businessRole],
      !!event.target.checked,
    );
    if (searchText) {
      this.doSearch(searchText);
    }
  };

  handleKeyDown = e => {
    const {
      filteredBusinessProperties,
      filteredBusinesses,
      filteredPICs,
      businessLimit,
      businessPropertyLimit,
      propertyLimit,
    } = this.state;

    const { toggleOpen, handleSelection } = this.props;

    const clampedBusinessProperties = filteredBusinessProperties.slice(
      0,
      businessPropertyLimit,
    );
    const clampedBusinesses = filteredBusinesses.slice(0, businessLimit);
    const clampedProperties = filteredPICs.slice(0, propertyLimit);
    const results = [].concat.apply(
      [],
      [clampedBusinessProperties, clampedBusinesses, clampedProperties],
    );

    // if we only have one result - pressing Enter selects it
    if (results.length === 1) {
      if (e.key === Key.ENTER) {
        const { business, property } = results[0];

        handleSelection(
          business ? business.id : null,
          property ? property.id : null,
        );
        toggleOpen();
      }
    }
  };

  render() {
    const {
      searchText,
      businessNamePrefill,
      PICPrefill,
      PICNamePrefill,
      activeSection,
      filteredBusinessProperties,
      filteredBusinesses,
      filteredPICs,
      businessPropertyLimit,
      businessLimit,
      propertyLimit,
      isSearching,
    } = this.state;
    const {
      handleSelection,
      handleAddBusinessPIC,
      allowBusinessOnly,
      allowPropertyOnly,
      selectedBusiness,
      selectedProperty,
      label,
      toggleOpen,
      businessRoles,
      businessRoleFilterValue,
      saleyardId,
      showProperty,
      showPrefillSummary,
    } = this.props;

    const clampedBusinessProperties = filteredBusinessProperties.slice(
      0,
      businessPropertyLimit,
    );
    const clampedBusinesses = filteredBusinesses.slice(0, businessLimit);
    const clampedProperties = filteredPICs.slice(0, propertyLimit);

    const businessPropertyDiff =
      filteredBusinessProperties.length - clampedBusinessProperties.length;
    const businessDiff = filteredBusinesses.length - clampedBusinesses.length;
    const picDiff = filteredPICs.length - clampedProperties.length;
    const showBusinessPropertiesMore = businessPropertyDiff > 0;
    const showBusinessesMore = businessDiff > 0;
    const showPICMore = picDiff > 0;

    return (
      <Dialog open onClose={toggleOpen} maxWidth="md" fullWidth>
        <DialogTitle onClose={toggleOpen}>
          {`${
            selectedBusiness || selectedProperty ? "Change" : "Select"
          } ${label}`}
        </DialogTitle>

        <DialogContent dividers>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={businessRoles?.length === 1 ? 9 : 12}
              md={businessRoles?.length === 1 ? 10 : 12}
            >
              <SearchInput
                id="searchBusiness"
                value={searchText}
                placeholder={`Search Business${
                  showProperty ? " or PIC" : ""
                }...`}
                onChange={this.handleSearch}
                onFocus={() => {
                  this.toggleSection("search");
                }}
                autoComplete="off"
                autoFocus
                height={38}
              />
            </Grid>

            {businessRoles?.length > 0 && (
              <Grid
                container
                item
                sm={businessRoles.length === 1 ? 3 : 12}
                md={businessRoles.length === 1 ? 2 : 12}
                justifyContent="flex-end"
              >
                {businessRoles.map(businessRole => (
                  <Grid
                    container
                    item
                    key={businessRole}
                    xs={businessRoles.length === 1 ? 12 : 6}
                    sm={businessRoles.length === 1 ? 12 : 4}
                    md={businessRoles.length === 1 ? 12 : 3}
                    border={1}
                    justifyContent="flex-end"
                  >
                    <label htmlFor={`businessRoleFilter-${businessRole}`}>
                      {BUSINESS_ROLE_PLURAL[businessRole]}
                      <Switch
                        checked={businessRoleFilterValue[businessRole]}
                        onChange={this.resetBusinessRoleFilter(businessRole)}
                        color="primary"
                        id={`businessRoleFilter-${businessRole}`}
                      />
                    </label>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          <CollapsibleRow isOpen={activeSection === "search"}>
            {showPrefillSummary && (
              <TopRow
                business={selectedBusiness}
                property={selectedProperty}
                doSearch={this.doSearch}
                handlePrefill={this.handlePrefill}
                label={label}
              />
            )}

            <ResultsBlock>
              {isSearching && <ResultRow>filtering results...</ResultRow>}
              {showProperty && (
                <>
                  {clampedBusinessProperties.map((businessProperty, idx) => {
                    const { business, property } = businessProperty;
                    return (
                      <Result
                        key={idx}
                        business={business}
                        property={property}
                        handleSelection={handleSelection}
                        handlePrefill={this.handlePrefill}
                        businessPrefill={businessNamePrefill}
                        saleyardId={saleyardId}
                        showProperty={showProperty}
                        businessRoles={businessRoles}
                        label={label}
                      />
                    );
                  })}
                  {showBusinessPropertiesMore && (
                    <ResultRow justifyCenter>
                      {filteredBusinessProperties.length}&nbsp;results found.
                      <ActionButton
                        type="button"
                        onClick={this.showMoreBusinessProperties}
                      >
                        Show&nbsp;
                        {Math.min(
                          businessPropertyDiff,
                          additionalBusinessPropertiesLimit,
                        )}
                        &nbsp;more...
                      </ActionButton>
                    </ResultRow>
                  )}
                </>
              )}
              {allowBusinessOnly &&
                clampedBusinesses.map((businessProperty, idx) => {
                  const { business } = businessProperty;
                  return (
                    <Result
                      key={idx}
                      business={business}
                      handleSelection={handleSelection}
                      handlePrefill={this.handlePrefill}
                      picPrefill={PICPrefill}
                      showProperty={showProperty}
                    />
                  );
                })}
              {showBusinessesMore && (
                <ResultRow justifyCenter>
                  {filteredBusinesses.length}&nbsp;results found.
                  <ActionButton type="button" onClick={this.showMoreBusinesses}>
                    Show&nbsp;
                    {Math.min(businessDiff, additionalBusinessesLimit)}
                    &nbsp;more...
                  </ActionButton>
                </ResultRow>
              )}
              {showProperty && (
                <>
                  {allowPropertyOnly &&
                    clampedProperties.map((businessProperty, idx) => {
                      const { property } = businessProperty;
                      return (
                        <Result
                          key={idx}
                          property={property}
                          handleSelection={handleSelection}
                          handlePrefill={this.handlePrefill}
                          businessPrefill={businessNamePrefill}
                        />
                      );
                    })}
                  {showPICMore && (
                    <ResultRow justifyCenter>
                      {filteredPICs.length}&nbsp;results found.
                      <ActionButton
                        type="button"
                        onClick={this.showMoreProperties}
                      >
                        Show&nbsp;
                        {Math.min(picDiff, additionalPropertiesLimit)}
                        &nbsp;more.
                      </ActionButton>
                    </ResultRow>
                  )}
                </>
              )}
            </ResultsBlock>
          </CollapsibleRow>

          <SecondaryButton
            data-tour="addBusiness"
            onClick={() => {
              this.toggleSection("add");
            }}
            type="button"
          >
            Add new Business{showProperty && "/PIC"}
          </SecondaryButton>
          <CollapsibleRow isOpen={activeSection === "add"}>
            <AddRow
              defaultBusinessName={businessNamePrefill}
              defaultPIC={PICPrefill}
              defaultPICName={PICNamePrefill}
              handleAddBusinessPIC={handleAddBusinessPIC}
              handleSelection={handleSelection}
              showProperty={showProperty}
            />
          </CollapsibleRow>
        </DialogContent>
      </Dialog>
    );
  }
}
