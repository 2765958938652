import {
  faCow,
  faDeer,
  faDog,
  faHatCowboySide,
  faHorse,
  faMoneyBill,
  faPig,
  faSheep,
  faTractor,
} from "@fortawesome/pro-duotone-svg-icons";

const SPECIES = {
  SHEEP: 1,
  CATTLE: 2,
  PIG: 3,
  DOG: 4,
  HORSE: 5,
  GOAT: 6,
  CLEARING_SALE: 7,
  MIXED: 8,
  ALPACA: 9,
  SUNDRY_SALE: 10,
};

const TOP_BUYER_FILTERS = {
  [SPECIES.SHEEP]: b => b.isTopBuyerSheep,
  [SPECIES.CATTLE]: b => b.isTopBuyerCattle,
};

const SPECIES_ICONS = {
  [SPECIES.SHEEP]: faSheep,
  [SPECIES.CATTLE]: faCow,
  [SPECIES.PIG]: faPig,
  [SPECIES.DOG]: faDog,
  [SPECIES.HORSE]: faHorse,
  // Joel Rockes:
  // That will be fine.
  // You won’t know the diff.
  [SPECIES.GOAT]: faDeer,
  [SPECIES.CLEARING_SALE]: faTractor,
  [SPECIES.SUNDRY_SALE]: faMoneyBill,
};

const SPECIES_MIXED_BREED_NAME = {
  [SPECIES.SHEEP]: "MIXED",
  [SPECIES.CATTLE]: "MIXED",
};

export const getMixedBreedNameForSpecies = speciesId =>
  SPECIES_MIXED_BREED_NAME[speciesId] || null;

Object.freeze(SPECIES);

export const getIconForSpeciesId = speciesId =>
  SPECIES_ICONS[speciesId] || faHatCowboySide;
const getSpeciesTopBuyerFilter = id => TOP_BUYER_FILTERS[id] || (() => false);

export const Species = {
  ...SPECIES,
  topBuyerFilter: getSpeciesTopBuyerFilter,
};
