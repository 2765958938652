import React from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { InputDateRange } from "components/DateRangePicker/dateRangePicker";
import { Button, SecondaryButton } from "components/Form";
import {
  Input,
  SelectField,
  useSubmitHandler,
} from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import {
  dateXDaysAgo,
  formatUTCToLocalDateTimeInputString,
} from "lib/timeFormats";

import { selectSaleOptionsV2 } from "selectors";

const validationSchema = Yup.lazy(values =>
  Yup.object().shape({
    // Silly field, because you can't validate a form, just fields.
    anyFields: Yup.string().test(
      "anyFields",
      "You must have select at least one filter.",
      () => {
        return !(
          !values.livestockSaleId &&
          !values.recipientEmail &&
          !values.createdGte
        );
      },
    ),
    livestockSaleId: Yup.number().nullable(),
    recipientEmail: Yup.string()
      .email("Must be a valid email address.")
      .nullable(),
    createdLte: Yup.date().nullable(),
    createdGte: Yup.date()
      .nullable()
      .when("createdLte", createdLte => {
        // If we have an end date, compare the two, and make sure it's less than 90 days.
        if (createdLte) {
          return Yup.date()
            .nullable()
            .test(
              "withinRange",
              "Maximum date range of 3 months.",
              createdGte => {
                // Only validate it if it's set, even if it's already nullable.  :'(
                if (!createdGte) {
                  return true;
                }
                return createdLte - createdGte <= 93 * 24 * 60 * 60 * 1000;
              },
            );
        } else {
          // If we don't have an end date, make sure it's less than 90 days ago.
          return Yup.date()
            .nullable()
            .test(
              "withinRange",
              "Maximum date range of 3 months.",
              createdGte => {
                // Only validate it if it's set, even if it's already nullable.  :'(
                if (!createdGte) {
                  return true;
                }
                return createdGte >= dateXDaysAgo(93);
              },
            );
        }
      }),
  }),
);

function FilterForm({ handleClose }) {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  useSubmitHandler(true, setIsSubmitEnabled);

  const { setValues, values, errors } = useFormikContext();

  const livestockSaleOptions = useSelector(selectSaleOptionsV2);

  const updateDates = (createdGte, createdLte) => {
    const updates = {
      createdLte,
      createdGte,
    };
    setValues({
      ...values,
      ...updates,
    });
  };

  return (
    <Dialog
      id="settings-modal"
      open
      scroll="body"
      maxWidth="md"
      fullWidth
      onClose={handleClose}
    >
      <Form data-tour="settings-form">
        <DialogTitle onClose={handleClose}>Email Filter</DialogTitle>
        <DialogContent dividers>
          <>
            <Grid container spacing={2}>
              {errors && errors.anyFields ? (
                <Grid item xs={12}>
                  <Error>{errors.anyFields}</Error>
                </Grid>
              ) : null}
              <InputDateRange
                updateDates={updateDates}
                startDateFieldName="createdGte"
                endDateFieldName="createdLte"
              />
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Input
                  name="recipientEmail"
                  label="Recipient Email"
                  tooltip="The exact email address of a recipient."
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  isClearable
                  name="livestockSaleId"
                  label="Sale"
                  options={livestockSaleOptions}
                  tooltip="Select the sale you want to see emails for."
                  menuPortalTarget={document.body}
                />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <Button data-tour="submit" type="submit" disabled={!isSubmitEnabled}>
            Save
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

export function FilteringModal(props) {
  const { closeSelf, setFilters, filters = {} } = props;

  const onSubmit = values => {
    setFilters(values);
    closeSelf();
  };

  // Serialize to inputty type values.
  const initialValues = {
    livestockSaleId: filters.livestockSaleId || null,
    recipientEmail: filters.recipientEmail || null,
    createdGte: filters.createdGte
      ? formatUTCToLocalDateTimeInputString(filters.createdGte)
      : null,
    createdLte: filters.createdLte
      ? formatUTCToLocalDateTimeInputString(filters.createdLte)
      : null,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FilterForm handleClose={closeSelf} />
    </Formik>
  );
}
