import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Button from "components/Button";
import { FileInput } from "components/Form/FormikControls";
import { Dialog } from "components/MaterialDialog";

import { NVD_ALLOWED_TYPES } from "constants/files";

const InfoText = styled.div`
  padding-top: 1rem;
  color: #666666;
  font-size: 12px;
  text-align: center;
`;

const FileSummary = styled.div`
  padding: ${({ theme }) => theme.space[3]}px;
  background: ${({ theme }) => theme.colors.gray95};
`;

const FileSummaryName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
const FileSummaryRejectionReasons = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.thin};
`;

function FileSummaryItem(props) {
  const { name, size, type, maxSize, allowedFileTypes } = props;
  const reasonsRejected = [];
  if (size > maxSize) {
    reasonsRejected.push(`file is too large`);
  }
  if (allowedFileTypes.indexOf(type) === -1) {
    reasonsRejected.push("file format is not compatible");
  }
  return (
    <li key={name}>
      <FileSummaryName>{name}</FileSummaryName>
      <br />
      <FileSummaryRejectionReasons>
        {reasonsRejected.join(", ")}
      </FileSummaryRejectionReasons>
    </li>
  );
}
FileSummaryItem.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  maxSize: PropTypes.number.isRequired,
  allowedFileTypes: PropTypes.array.isRequired,
};

function FileSummaryList(props) {
  const {
    acceptedFiles,
    rejectedFiles,
    onActionClick,
    maxSize,
    allowedFileTypes,
  } = props;
  const rejectedUsePlural = rejectedFiles.length !== 1;
  const approvedUsePlural = acceptedFiles.length !== 1;
  const canContinue = acceptedFiles.length > 0;
  return (
    <FileSummary>
      <p>
        {acceptedFiles.length} file{approvedUsePlural ? "s were" : " was"}{" "}
        accepted.
      </p>
      <p>
        The following ({rejectedFiles.length}) file
        {rejectedUsePlural ? "s are" : " is"} not currently compatible:
      </p>
      <InfoText>
        (Files must be *.jpeg or *.png and under {maxSize / 1000000}
        MB)
      </InfoText>
      <ul>
        {rejectedFiles
          .map(({ name, size, type }) => ({
            name,
            size,
            type,
            maxSize,
            allowedFileTypes,
          }))
          .map(FileSummaryItem)}
      </ul>
      <Button type="button" onClick={onActionClick}>
        {canContinue ? "Continue" : "Go Back"}
      </Button>
    </FileSummary>
  );
}
FileSummaryList.propTypes = {
  acceptedFiles: PropTypes.array.isRequired,
  rejectedFiles: PropTypes.array.isRequired,
  onActionClick: PropTypes.func.isRequired,
  maxSize: PropTypes.number.isRequired,
  allowedFileTypes: PropTypes.array.isRequired,
};

export const UploadModal = React.memo(({ handleUpload, closeSelf }) => {
  return (
    <Dialog onClose={closeSelf} open>
      <FileInput
        upload={handleUpload}
        closeSelf={closeSelf}
        rotate
        allowedFileTypes={NVD_ALLOWED_TYPES}
      />
    </Dialog>
  );
});

UploadModal.propTypes = {
  closeSelf: PropTypes.func,
  handleUpload: PropTypes.func.isRequired,
};
