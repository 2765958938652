import React from "react";

import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

export function trueIcon({ value, data }) {
  if (!data) {
    return null;
  }

  if (value === true) {
    return <FaIcon icon={faCheck} color="success" />;
  } else {
    return "";
  }
}
