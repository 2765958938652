import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

import { hasPermission, someHasPermission } from "lib/permissions";

import {
  getCurrentSaleyard,
  getIsRolePermissionsEnabled,
  selectCurrentDeployments,
  selectRoleCurrentDeployments,
} from "selectors";

export function FeatureRoute(props) {
  const {
    children,
    saleyardPermissions = [],
    deploymentPermissions = [],
    roleDeploymentPermissions = [],
  } = props;

  // check any of the saleyardPermissions list
  // are present in the CurrentSaleyard permissions
  const saleyard = useSelector(getCurrentSaleyard);

  // check any of deploymentPermissions list
  // are present in any of the CurrentDeployments permissions
  //   i.e. the deployments within the context of the current livestock sale that I can see
  const deployments = useSelector(selectCurrentDeployments);

  // check any of the roleDeploymentPermissions list
  // are present in any of the RoleCurrentDeployments permissions
  //   i.e. the deployments within the context of the intersection of my role and the current sale
  const roleDeployments = useSelector(selectRoleCurrentDeployments);

  // Only perform the permissions test when the Active Role uses permissions
  // Assume they have permission when the Active Role doesn't use the permissions machinery
  if (useSelector(getIsRolePermissionsEnabled)) {
    const hasSaleyardPermission = saleyardPermissions.some(perm =>
      hasPermission(saleyard, perm),
    );

    const hasDeploymentPermission = someHasPermission(
      deployments,
      deploymentPermissions,
    );

    const roleHasPermission = someHasPermission(
      roleDeployments,
      roleDeploymentPermissions,
    );

    if (
      !hasSaleyardPermission &&
      !hasDeploymentPermission &&
      !roleHasPermission
    ) {
      return null;
    }
  }

  return <Route {...props}>{children}</Route>;
}

FeatureRoute.propTypes = {
  children: PropTypes.node,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  sensitive: PropTypes.bool,
  strict: PropTypes.bool,
  roleDeploymentPermissions: PropTypes.array,
  deploymentPermissions: PropTypes.array,
  saleyardPermissions: PropTypes.array,
};
