import React from "react";

import { faCopy, faUserCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { getLivestockSaleId } from "lib/navigation";
import toast from "lib/toast";

import { getActiveRole } from "selectors";

import { useBoolean } from "hooks";

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .MuiSpeedDial-actions {
    position: absolute;
    top: 36px;
  }
`;

export const StaffActions = () => {
  const [open, handleOpen, handleClose] = useBoolean(false);

  const userRole = useSelector(getActiveRole).slug;
  const copySaleToClipboard = () => {
    const link = `${
      window.location.origin
    }/redir/${getLivestockSaleId()}/${userRole}/`;
    navigator.clipboard.writeText(link);
    toast.success("Sale link copied to clipboard.");
  };

  const actions = [
    {
      icon: <FontAwesomeIcon icon={faCopy} />,
      name: "Copy Sale Link",
      onClick: copySaleToClipboard,
    },
  ];

  if (!getLivestockSaleId()) {
    return null;
  }

  return (
    <Container>
      <SpeedDial
        ariaLabel="Staff SpeedDial"
        icon={<FontAwesomeIcon icon={faUserCrown} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="down"
        FabProps={{ size: "small" }}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>
    </Container>
  );
};
