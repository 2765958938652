import { intersection, uniq } from "lodash";
import { createSelector } from "reselect";

import { PenTypes } from "constants/auctionPens";
import { ModalTypes } from "constants/navigation";
import { PenScanLotStatus } from "constants/penScanLots";
import { UNALLOCATED } from "constants/scanner";

import { EMPTY_ARRAY } from "lib";

import { selectScanLotStatusByIdReducer } from "lib/auctionPens";

import {
  getAuctionPens,
  getContextByModalType,
  getPenScanLots,
  getReceivalLots,
  getScans,
  selectDeploymentIdByConsignmentIdLookup,
  selectEidsByPenScanLotIdLookup,
  selectPenScanLotIdsBySellingPenIdLookup,
  selectReceivalLotIdByEidLookup,
} from "selectors";

import { selectFilteredPenScanLotIds } from "selectors/globalSearch/penScanLotFilters";

import { createLookupCombiner, createLookupSelectors } from "./lib";

export const getPenScanLotById = penScanLotId => state =>
  getPenScanLots(state)[penScanLotId] || null;

export const [selectPenScanLotStatusByIdLookup, getPenScanLotStatusById] =
  createLookupSelectors(
    [getPenScanLots, selectEidsByPenScanLotIdLookup],
    createLookupCombiner(selectScanLotStatusByIdReducer),
    EMPTY_ARRAY,
  );

export const selectFilteredPenScanLotIdsBySellingPenId = createSelector(
  [selectFilteredPenScanLotIds, selectPenScanLotIdsBySellingPenIdLookup],
  (filteredPenScanLotIds, penScanLotIdsBySellingPenIdLookup) => {
    return Object.entries(penScanLotIdsBySellingPenIdLookup).reduce(
      (acc, [sellingPenId, penScanLotIds]) => {
        acc[sellingPenId] = intersection(filteredPenScanLotIds, penScanLotIds);
        return acc;
      },
      {},
    );
  },
);

export const getPenScanLotsIdsBySellingPenId = penScanLotId => state =>
  selectFilteredPenScanLotIdsBySellingPenId(state)[penScanLotId] || EMPTY_ARRAY;

export const selectUsedPenScanLotIdsBySellingPenId = createSelector(
  [getPenScanLots, getAuctionPens],
  (penScanLots, auctionPens) =>
    Object.values(auctionPens)
      .filter(pen => pen.penType === PenTypes.SELLING)
      .reduce((acc, pen) => {
        acc[pen.id] = Object.values(penScanLots).filter(
          lot => lot.sellingPenId === pen.id,
        );
        return acc;
      }, []),
);

export const getPenScanLotIdsBySellingPenId = sellingPenId => state =>
  selectPenScanLotIdsBySellingPenIdLookup(state)[sellingPenId] || EMPTY_ARRAY;

export const getEidsByPenScanLotId = penScanLotId => state =>
  selectEidsByPenScanLotIdLookup(state)[penScanLotId] || EMPTY_ARRAY;

/** *
 * Determining the status of the penScanLot for the admin staff to resolve where needed
 *
 * if all eids do not have a receival lot - or their receival lot does not have a consignment or there are NO EIDS or the pen scan lot does not have a deployment and round = not mappable
 * if no eids have a link to a sale lot - and eids exist within this lot with a receival lot and consignment - not mapped
 * if the eid count that is mapped to a sale lot is the same as the quantity on the lot = mapped
 * if the eid count that is mapped to a sale lot is not zero and is not the same as the quantity on the lot = partially mapped
 */
export const selectMappingStatusByPenScanLotIdLookup = createSelector(
  [
    getPenScanLots,
    selectEidsByPenScanLotIdLookup,
    selectReceivalLotIdByEidLookup,
    getScans,
    getReceivalLots,
  ],
  (
    penScanLots,
    eidsByPenScanLotIdLookup,
    receivalLotIdLookupByEidLookup,
    scansLookup,
    receivalLotLookup,
  ) => {
    return Object.values(penScanLots).reduce((acc, penScanLot) => {
      let status = PenScanLotStatus.NOT_MAPPED;

      const penScanLotQuantity = penScanLot.quantity;

      const eids = eidsByPenScanLotIdLookup[penScanLot.id] || [];

      const eidsWithReceivalLots = eids.filter(
        eid => !!scansLookup[eid]?.receival_lot_id,
      );

      const eidsWithReceivalLotConsignments =
        eidsWithReceivalLots.filter(eid => {
          const receivalLotId = receivalLotIdLookupByEidLookup[eid];
          return !!receivalLotLookup[receivalLotId]?.consignmentId;
        }) || [];

      const eidsLinkedToSaleLots =
        eids.filter(
          eid =>
            scansLookup[eid]?.sale_lot_id &&
            scansLookup[eid]?.sale_lot_id !== UNALLOCATED,
        ) || [];

      if (eidsLinkedToSaleLots.length === penScanLotQuantity) {
        status = PenScanLotStatus.MAPPED;
      } else if (penScanLot.isLocked) {
        // in the case the lot is locked but not mapped - tell the user why we cant map
        status = PenScanLotStatus.IS_LOCKED;
      }
      if (
        eids.length !== penScanLotQuantity ||
        (!eidsWithReceivalLotConsignments.length &&
          (!penScanLot.saleRoundId || !penScanLot.deploymentId))
      ) {
        status = PenScanLotStatus.NOT_MAPPABLE;
      }
      if (
        eidsLinkedToSaleLots.length > 0 &&
        eidsLinkedToSaleLots.length !== penScanLotQuantity
      ) {
        status = PenScanLotStatus.PARTIALLY_MAPPED;
      }

      acc[penScanLot.id] = status;
      return acc;
    }, {});
  },
);

export const selectConsignmentIdsByPenScanLotIdLookup = createSelector(
  [
    getPenScanLots,
    selectEidsByPenScanLotIdLookup,
    selectReceivalLotIdByEidLookup,
    getScans,
    getReceivalLots,
  ],
  (
    penScanLots,
    eidsByPenScanLotIdLookup,
    receivalLotIdLookupByEidLookup,
    scansLookup,
    receivalLotLookup,
  ) =>
    Object.values(penScanLots).reduce((acc, penScanLot) => {
      const eids = eidsByPenScanLotIdLookup[penScanLot.id] || [];
      const eidsWithReceivalLots = eids.filter(
        eid => !!scansLookup[eid]?.receival_lot_id,
      );
      const consignmentIds = uniq(
        eidsWithReceivalLots.map(eid => {
          const receivalLotId = receivalLotIdLookupByEidLookup[eid];
          return receivalLotLookup[receivalLotId]?.consignmentId;
        }),
      );
      acc[penScanLot.id] = consignmentIds || [];
      return acc;
    }, {}),
);

export const selectReceivalLotIdsByPenScanLotIdLookup = createSelector(
  [
    getPenScanLots,
    selectEidsByPenScanLotIdLookup,
    selectReceivalLotIdByEidLookup,
    getScans,
  ],
  (
    penScanLots,
    eidsByPenScanLotIdLookup,
    receivalLotIdLookupByEidLookup,
    scansLookup,
  ) => {
    return Object.values(penScanLots).reduce((acc, penScanLot) => {
      const eids = eidsByPenScanLotIdLookup[penScanLot.id] || [];
      const eidsWithReceivalLots = eids.filter(
        eid => !!scansLookup[eid]?.receival_lot_id,
      );
      acc[penScanLot.id] =
        uniq(
          eidsWithReceivalLots.map(eid => receivalLotIdLookupByEidLookup[eid]),
        ) || [];
      return acc;
    }, {});
  },
);

export const selectReceivalLotMarksByPenScanLotLookup = createSelector(
  [getPenScanLots, selectReceivalLotIdsByPenScanLotIdLookup, getReceivalLots],
  (penScanLots, receivalLotIdsByPenScanLotIdLookup, receivalLotLookup) => {
    return Object.values(penScanLots).reduce((acc, penScanLot) => {
      acc[penScanLot.id] = uniq(
        receivalLotIdsByPenScanLotIdLookup[penScanLot.id].map(
          receivalLotId => receivalLotLookup[receivalLotId]?.mark || "",
        ),
      );
      return acc;
    }, {});
  },
);

export const selectReceivalPenIdsFromPenScanLotLookup = createSelector(
  [getPenScanLots, selectReceivalLotIdsByPenScanLotIdLookup, getReceivalLots],
  (penScanLots, receivalLotIdsByPenScanLotIdLookup, receivalLotLookup) => {
    return Object.values(penScanLots).reduce((acc, penScanLot) => {
      acc[penScanLot.id] =
        uniq(
          receivalLotIdsByPenScanLotIdLookup[penScanLot.id].map(
            receivalLotId => receivalLotLookup[receivalLotId].receivalPenId,
          ),
        ) || [];
      return acc;
    }, {});
  },
);

export const getPenScanLotMappings = state =>
  getContextByModalType(ModalTypes.AllocatePenScanLots)(state).mapping;

export const getPenScanLotSaleLotData = state =>
  getContextByModalType(ModalTypes.AllocatePenScanLots)(state).saleLotData;

export const getSaleLotMappingGroupsByPenScanLotId = penScanLotId => state =>
  getPenScanLotMappings(state)[penScanLotId];

export const getSaleLotMappingGroupByIndex =
  (penScanLotId, saleLotMappingGroupIndex) => state =>
    getSaleLotMappingGroupsByPenScanLotId(penScanLotId)(state)[
      saleLotMappingGroupIndex
    ];

export const getScanMappingByIndex =
  (penScanLotId, saleLotMappingGroupIndex, scanMappingIndex) => state =>
    getSaleLotMappingGroupByIndex(
      penScanLotId,
      saleLotMappingGroupIndex,
    )(state)[scanMappingIndex];

export const selectReceivalScanDeploymentIdByEidLookup = createSelector(
  [
    selectReceivalLotIdByEidLookup,
    getReceivalLots,
    selectDeploymentIdByConsignmentIdLookup,
  ],
  (receivalLotIdByEidLookup, receivalLots, deploymentIdByConsignmentIdLookup) =>
    Object.entries(receivalLotIdByEidLookup).reduce(
      (acc, [eid, receivalLotId]) => {
        const receivalLot = receivalLots[receivalLotId];
        if (receivalLot?.consignmentId) {
          acc[eid] =
            deploymentIdByConsignmentIdLookup[receivalLot.consignmentId];
        }
        return acc;
      },
      {},
    ),
);
