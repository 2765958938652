import React from "react";

import { faBrush as faBrushSolid } from "@fortawesome/pro-duotone-svg-icons";
import { faBrush as faBrushOutlined } from "@fortawesome/pro-light-svg-icons";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

const Wrapper = styled.p`
  margin: 0;
  display: flex;
  align-items: baseline;
`;

const MarkIcon = styled(FaIcon)`
  display: inline-block;
  height: 12px;
  width: 12px;
`;

const BodyPartText = styled.span`
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MarkingInfo = ({ color, location, style }) => (
  <Wrapper style={{ ...style }}>
    <MarkIcon icon={color ? faBrushSolid : faBrushOutlined} color={color} />
    <BodyPartText style={{ width: "8ch" }} data-tour="mark">
      {location}
    </BodyPartText>
  </Wrapper>
);

MarkingInfo.propTypes = {
  color: PropTypes.string.isRequired,
  location: PropTypes.string,
  style: PropTypes.shape(),
};

export default MarkingInfo;
