import { USER_LEVEL } from "constants/actionTypes";

import { serializeUserLevel } from "lib/serializers/userLevel";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/user-levels/", id, action);

export const UserLevelAction = offlineActionCreator(
  USER_LEVEL,
  serializeUserLevel,
  urlCreator,
  false,
);
