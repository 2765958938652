import intersection from "lodash/intersection";
import { all, put, race, select, take, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import {
  addSaleLot,
  allocateConsignmentEidsToPenFailure,
  allocateConsignmentEidsToPenOffline,
  createConsignmentPen,
  createConsignmentPenFailure,
  createConsignmentPenOffline,
  createConsignmentPenSuccess,
  createUnknownConsignmentPenFailure,
  createUnknownConsignmentPenSuccess,
  uploadScansAndDraftingInformation,
} from "actions";

import { addAuctionPen } from "actions/auctionPens";

import {
  ADD_SALE_LOT_COMMIT,
  ADD_SALE_LOT_ROLLBACK,
  ALLOCATE_CONSIGNMENT_EIDS_TO_PEN,
  AUCTION_PEN,
  CREATE_CONSIGNMENT_PEN,
  CREATE_CONSIGNMENT_PEN_FAILURE,
  CREATE_CONSIGNMENT_PEN_OFFLINE,
  CREATE_CONSIGNMENT_PEN_SUCCESS,
  CREATE_UNKNOWN_CONSIGNMENT_PEN,
} from "constants/actionTypes";
import { UNKNOWN_BUSINESS_NAME } from "constants/businesses";

import {
  getAuctionPens,
  getBusinesses,
  getConsignments,
  getSaleLotIdsByAuctionPenId,
  getSaleLotIdsByConsignmentId,
  getSaleLotIdsByRoundId,
  getSaleLots,
  getScans,
  selectAgencyIdByConsignmentIdLookup,
} from "selectors";

function* waitForId(successType, failureType, getActionId, offlineId) {
  // Wait for either a success or a failure
  let requestSucceeded = null;
  let result = null;
  do {
    const { success, failure } = yield race({
      success: take(successType),
      failure: take(failureType),
    });
    if (success) {
      if (getActionId(success) === offlineId) {
        requestSucceeded = true;
        result = success;
      }
    } else if (failure) {
      if (getActionId(failure) === offlineId) {
        requestSucceeded = false;
        result = failure;
      }
    }
  } while (requestSucceeded === null);
  return [requestSucceeded, result];
}

function* putAndWaitForId(
  action,
  successType,
  failureType,
  getActionId,
  offlineId,
) {
  yield put(action);
  return yield waitForId(successType, failureType, getActionId, offlineId);
}

const DefaultCreateConsignmentPenOptions = {
  forceCreateSaleLot: false,
  onError: null,
  onSuccess: null,
  ignoredSaleLotAttributes: [],
};

/**
 * Locates a Consignment attached to the business named by `UNKNOWN_BUSINESS_NAME` and dispatches a CREATE_CONSIGNMENT_PEN action for the Consignment Id.
 * When a Consignment cannot be identified it will call the onError callback given in the `options` parameter
 * When more than one Vendor/Consignment combination is present, the action is dispatched for the first result.
 * @param {CreateUnknownConsignmentPenAction} action
 */
function* onCreateUnknownConsignmentPen(action) {
  const { agencyId, auctionPenValues, options, roundId, saleLotValues } =
    action;

  const { onError } = {
    ...DefaultCreateConsignmentPenOptions,
    ...options,
  };

  const state = yield select();

  const businesses = getBusinesses(state);
  const consignments = getConsignments(state);
  const agencyIdByConsignmentIdLookup =
    selectAgencyIdByConsignmentIdLookup(state);

  const { id: consignmentId } =
    Object.values(consignments).find(consignment => {
      if (agencyIdByConsignmentIdLookup[consignment.id] === agencyId) {
        return businesses[consignment.vendor_id].name === UNKNOWN_BUSINESS_NAME;
      }
      return false;
    }) || {};

  if (!consignmentId) {
    // TODO when the Unknown Vendor Consignment does not exist, create it.
    const errorMessage = `Could not find a Consignment with Vendor "${UNKNOWN_BUSINESS_NAME}"`;
    typeof onError === "function" && onError(errorMessage);
    yield put(createUnknownConsignmentPenFailure(errorMessage));
    return;
  }

  const contextId = uuidv4();

  const [isSuccess, result] = yield putAndWaitForId(
    createConsignmentPen(
      consignmentId,
      agencyId,
      roundId,
      saleLotValues,
      auctionPenValues,
      { ...options, contextId },
    ),
    CREATE_CONSIGNMENT_PEN_SUCCESS,
    CREATE_CONSIGNMENT_PEN_FAILURE,
    action => action.meta.contextId,
    contextId,
  );

  if (isSuccess) {
    const { consignmentId, saleLotId, auctionPenId } = result;
    yield put(
      createUnknownConsignmentPenSuccess(
        consignmentId,
        saleLotId,
        auctionPenId,
      ),
    );
  } else {
    const { errorMessage } = result;
    typeof onError === "function" && onError(errorMessage);
    yield put(createUnknownConsignmentPenFailure(errorMessage));
  }
}

function* getOrCreateAuctionPenId(state, agencyId, roundId, auctionPenValues) {
  const auctionPenKeys = Object.keys(auctionPenValues);

  const auctionPens = getAuctionPens(state);

  const potentialAuctionPenIds = Object.keys(auctionPens);

  // Find an existing Auction Pen matching the submitted values
  const auctionPenId = potentialAuctionPenIds.find(auctionPenId => {
    const auctionPen = auctionPens[auctionPenId];

    return auctionPenKeys.every(
      key =>
        auctionPenValues[key] === auctionPen[key] ||
        ((auctionPenValues[key] === null ||
          auctionPenValues[key] === undefined) &&
          (auctionPen[key] === null || auctionPen[key] === undefined)),
    );
  });

  if (auctionPenId) {
    return [auctionPenId, false];
  }

  // Allocate a temporary Auction Pen Id
  const tempAuctionPenId = uuidv4();

  const auctionPen = {
    ...auctionPenValues,
    saleRoundId: roundId,
    agency: agencyId,
  };

  yield put(addAuctionPen(auctionPen, tempAuctionPenId));

  return [tempAuctionPenId, true];
}

function* getOrCreateSaleLotId(
  state,
  agencyId,
  roundId,
  consignmentId,
  auctionPenId,
  saleLotValues,
  ignoredAttributes = [],
  forceCreateSaleLot = false,
) {
  const saleLotKeys = Object.keys(saleLotValues);

  const saleLots = getSaleLots(state);

  const saleLotsIdsInRound = getSaleLotIdsByRoundId(roundId)(state) || [];
  const saleLotsIdsInConsignment =
    getSaleLotIdsByConsignmentId(consignmentId)(state) || [];
  const saleLotsIdsInAuctionPen =
    getSaleLotIdsByAuctionPenId(auctionPenId)(state) || [];

  // Select the non-unique values from all of the lists.
  const potentialSaleLotIds = intersection(
    saleLotsIdsInRound,
    saleLotsIdsInConsignment,
    saleLotsIdsInAuctionPen,
  );

  // Find an existing Sale Lot matching the submitted values
  const saleLotId = potentialSaleLotIds.find(saleLotId => {
    const saleLot = saleLots[saleLotId];

    return saleLotKeys.every(
      key =>
        saleLotValues[key] === saleLot[key] ||
        ignoredAttributes.includes(key) ||
        ((saleLotValues[key] === null || saleLotValues[key] === undefined) &&
          (saleLot[key] === null || saleLot[key] === undefined)),
    );
  });

  if (!saleLotId || forceCreateSaleLot) {
    const tempSaleLotId = uuidv4();

    const saleLot = {
      ...saleLotValues,
      agency: agencyId,
      auction_pen_id: auctionPenId,
      consignment_id: consignmentId,
      sale_round_id: roundId,
    };

    yield put(addSaleLot(saleLot, tempSaleLotId));

    return [tempSaleLotId, true];
  }

  return [saleLotId, false];
}

/**
 * @param {CreateConsignmentPenAction} action
 */
function* onCreateConsignmentPen(action) {
  const {
    agencyId,
    auctionPenValues,
    consignmentId,
    options,
    roundId,
    saleLotValues,
  } = action;

  const {
    contextId,
    forceCreateSaleLot,
    onError,
    onSuccess,
    ignoredSaleLotAttributes,
  } = {
    ...DefaultCreateConsignmentPenOptions,
    ...options,
  };

  const meta = { contextId };

  let auctionPenId = null;
  let saleLotId = null;

  const state = yield select();

  const [offlineAuctionPenId, createdAuctionPen] =
    yield getOrCreateAuctionPenId(state, agencyId, roundId, auctionPenValues);

  const [offlineSaleLotId, createdSaleLot] = yield getOrCreateSaleLotId(
    state,
    agencyId,
    roundId,
    consignmentId,
    offlineAuctionPenId,
    saleLotValues,
    ignoredSaleLotAttributes,
    forceCreateSaleLot,
  );

  yield put(
    createConsignmentPenOffline(
      consignmentId,
      offlineSaleLotId,
      offlineAuctionPenId,
      meta,
    ),
  );

  if (createdAuctionPen) {
    const [requestSucceeded, auctionPen] = yield waitForId(
      AUCTION_PEN.CREATE.SUCCESS,
      AUCTION_PEN.CREATE.FAILURE,
      action => action.meta.tempId,
      offlineAuctionPenId,
    );

    if (!requestSucceeded) {
      const errorMessage = "Failed to create Auction Pen";
      typeof onError === "function" && onError(errorMessage);
      yield put(createConsignmentPenFailure(errorMessage, meta));
      return;
    }

    auctionPenId = auctionPen.payload.id;
  } else {
    auctionPenId = offlineAuctionPenId;
  }

  if (createdSaleLot) {
    const [requestSucceeded, saleLot] = yield waitForId(
      ADD_SALE_LOT_COMMIT,
      ADD_SALE_LOT_ROLLBACK,
      action => action.meta.tempId,
      offlineSaleLotId,
    );
    if (!requestSucceeded) {
      const errorMessage = "Failed to create Sale Lot";
      typeof onError === "function" && onError(errorMessage);
      yield put(createConsignmentPenFailure(errorMessage, meta));
      return;
    }
    saleLotId = saleLot.payload.id;
  } else {
    saleLotId = offlineSaleLotId;
  }
  typeof onSuccess === "function" &&
    onSuccess(consignmentId, saleLotId, auctionPenId);

  yield put(
    createConsignmentPenSuccess(consignmentId, saleLotId, auctionPenId, meta),
  );
}

/**
 * @param {AllocateConsignmentEidsToPenAction} action
 */
function* onAllocateConsignmentEidsToPen(action) {
  const { allocations, options } = action;
  const { contextId, onSuccess, onError } = {
    ...options,
  };

  const meta = { contextId };

  const state = yield select();

  const agencyIdByConsignmentId = selectAgencyIdByConsignmentIdLookup(state);

  const scans = getScans(state);

  // TODO call the onError function when actionableAllocations.length !== allocation.length && isSaleyardAdmin

  const tasks = allocations.map(function* allocation(allocation) {
    const { consignmentId, eids, saleLotMatch, auctionPenMatch } = allocation;
    const { round_id: roundId } = saleLotMatch;

    const contextId = uuidv4();

    const agencyId = agencyIdByConsignmentId[consignmentId];

    // if the Sale Lot doesn't exist, automatically set the quantity to the scan count
    saleLotMatch.quantity = eids.length;

    const [isSuccess, consignmentPen] = yield putAndWaitForId(
      createConsignmentPen(
        consignmentId,
        agencyId,
        roundId,
        saleLotMatch,
        auctionPenMatch,

        {
          ignoredSaleLotAttributes: ["quantity", "pricing_type_id"],
          contextId,
        },
      ),
      CREATE_CONSIGNMENT_PEN_OFFLINE,
      CREATE_CONSIGNMENT_PEN_FAILURE,
      ({ meta }) => meta.contextId,
      contextId,
    );

    if (!isSuccess) {
      const errorMessage = "Failed to create Sale Lot";
      typeof onError === "function" && onError(errorMessage);
      yield put(allocateConsignmentEidsToPenFailure(errorMessage, meta));
      return;
    }

    const { saleLotId } = consignmentPen;

    const scansPayload = eids.map(eid => scans[eid]);

    yield put(uploadScansAndDraftingInformation(scansPayload, saleLotId));
  });

  yield all(tasks);

  typeof onSuccess === "function" && onSuccess();

  yield put(allocateConsignmentEidsToPenOffline(meta));
}

export default function* penningSaga() {
  yield takeEvery(
    CREATE_UNKNOWN_CONSIGNMENT_PEN,
    onCreateUnknownConsignmentPen,
  );
  yield takeEvery(CREATE_CONSIGNMENT_PEN, onCreateConsignmentPen);
  yield takeEvery(
    ALLOCATE_CONSIGNMENT_EIDS_TO_PEN,
    onAllocateConsignmentEidsToPen,
  );
}
