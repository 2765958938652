import React from "react";

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

const IconWrapper = styled(FontAwesomeIcon)`
  font-size: 16px;
  ${({ color, theme }) => color && `color: ${theme.colors[color]}`};
`;

interface IProps {
  color: string;
}
export function Circle({ color }: IProps) {
  return <IconWrapper icon={faCircle} color={color} />;
}
