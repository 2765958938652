import queryString from "query-string";

import { BILLING_RUN } from "constants/actionTypes";

import { serializeBillingRun } from "lib/serializers/billingRuns";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

export const billingRunUrlCreator = ({
  action,
  changesSince,
  id,
  livestockSalesIdIn,
  userRole,
  livestockSaleDateGte,
  livestockSaleDateLte,
  livestockSaleSaleTypeNameIn,
  livestockSaleSaleSubTypeIdIn,
}) => {
  return queryString.stringifyUrl(
    {
      url: urlCheckAndAddIdAndAction("/v2/billing-runs/", id, action),
      query: {
        changesSince,
        livestock_sales__id__in: livestockSalesIdIn,
        user_role: userRole,
        livestock_sales__date__gte: livestockSaleDateGte,
        livestock_sales__date__lte: livestockSaleDateLte,
        livestock_sales__sale_type__name__in: livestockSaleSaleTypeNameIn,
        livestock_sales__sale_sub_type_id__in: livestockSaleSaleSubTypeIdIn,
      },
    },
    { arrayFormat: "comma" },
  );
};

export const BillingRunAction = offlineActionCreator(
  BILLING_RUN,
  serializeBillingRun,
  billingRunUrlCreator,
  false,
);

BillingRunAction.disable = () => {
  return {
    type: BILLING_RUN.DISABLE.ACTION,
  };
};

BillingRunAction.enable = livestockSaleIds => {
  return {
    type: BILLING_RUN.ENABLE.ACTION,
    livestockSaleIds,
  };
};

BillingRunAction.checkForChanges = (id, options) => {
  return {
    type: BILLING_RUN.CHECK_FOR_CHANGES.REQUEST,
    meta: {
      offline: {
        effect: {
          url: billingRunUrlCreator({ id, action: "check-version" }),
          method: "GET",
        },
        commit: {
          type: BILLING_RUN.CHECK_FOR_CHANGES.SUCCESS,
          meta: {
            options,
          },
        },
        rollback: {
          type: BILLING_RUN.CHECK_FOR_CHANGES.FAILURE,
        },
      },
    },
  };
};

BillingRunAction.subscribe = id => {
  return {
    type: BILLING_RUN.SUBSCRIBE.ACTION,
    id,
  };
};

BillingRunAction.unsubscribe = id => {
  return {
    type: BILLING_RUN.UNSUBSCRIBE.ACTION,
    id,
  };
};
