import React from "react";

import { useField } from "formik";

import { Column } from "components/Layout";

import { Error } from "./Error";
import { Label } from "./Label";
import { StyledTextArea, StyledTextAreaAutoSize } from "./layout";

export const TextArea = ({
  name,
  label,
  disabled = false,
  selectOnFocus = true,
  rows = undefined,
  tooltip = undefined,
  maxLength = undefined,
  placeholder = undefined,
}) => {
  const [field, meta] = useField(name);
  const onFocus = e => selectOnFocus && e.target.select();
  const error = meta.touched && meta.error;
  // When a mounted React `Textarea` component value changes from a string
  // value e.g. `"my text"` to a non string value, such as `null` or
  // `undefined`, the contents DOM `<textarea>` Element is not updated. This is
  // here to coerce the value back to an empty string to ensure the DOM
  // Element's value reflects the React value
  const sanitisedValue = field.value || "";

  return (
    <Column fullWidth>
      <Label htmlFor={name} tooltip={tooltip} error={!!error}>
        {label}
      </Label>
      <StyledTextArea
        data-tour={name}
        labelledBy={name}
        name={name}
        onBlur={field.onBlur}
        onChange={field.onChange(name)}
        disabled={disabled}
        onFocus={onFocus}
        rows={rows}
        maxLength={maxLength}
        value={sanitisedValue}
        placeholder={placeholder}
      />
      {meta.error && <Error>{meta.error}</Error>}
    </Column>
  );
};

export const AutoSizeTextArea = ({
  name,
  label,
  disabled,
  selectOnFocus = true,
  tooltip,
  maxLength = undefined,
  rows,
}) => {
  const [field, meta] = useField(name);
  const onFocus = e => selectOnFocus && e.target.select();
  const error = meta.touched && meta.error;
  // When a mounted React `Textarea` component value changes from a string
  // value e.g. `"my text"` to a non string value, such as `null` or
  // `undefined`, the contents DOM `<textarea>` Element is not updated. This is
  // here to coerce the value back to an empty string to ensure the DOM
  // Element's value reflects the React value
  const sanitisedValue = field.value || "";

  return (
    <Column fullWidth>
      <Label htmlFor={name} tooltip={tooltip} error={!!error}>
        {label}
      </Label>
      <StyledTextAreaAutoSize
        data-tour={name}
        aria-labelledby={name}
        name={name}
        onBlur={field.onBlur}
        onChange={field.onChange(name)}
        disabled={disabled}
        onFocus={onFocus}
        maxLength={maxLength}
        value={sanitisedValue}
        maxRows={rows}
      />
      {meta.error && <Error>{meta.error}</Error>}
    </Column>
  );
};
