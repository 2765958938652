export const aggridNominationTermTotalAccumulator = (terms, params) => {
  return terms.reduce((acc, nominationTerm) => {
    return (
      acc +
      (params.api.getValue(
        `nominationTerm.${nominationTerm.id}`,
        params.node,
      ) || 0)
    );
  }, 0);
};
