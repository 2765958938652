import React from "react";

import {
  faCheckCircle,
  faCircle,
  faTimesCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { ClickableCommentIcon } from "components/Comments/Icon";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";

import { CommentTypes } from "constants/comments";

import {
  getCurrentDraftingDecisionBySingleWeighById,
  getSaleLotIdByEid,
  getSingleWeighById,
  getStatusBySingleWeighId,
  selectIsPLCReady,
} from "selectors";

import { SingleWeighStatusComponentMap } from "./StatusActions";

const StyledStatusIcon = styled(FontAwesomeIcon)(
  ({ color, theme }) => `
  color: ${theme.colors[color]};
  margin-right: ${theme.space[1]}px;
`,
);

const StatusIconDisplayMap = {
  true: {
    icon: faCheckCircle,
    color: "success",
  },
  false: {
    icon: faTimesCircle,
    color: "error",
  },
  DEFAULT: {
    icon: faCircle,
    color: "unknown",
  },
};

const StatusIcon = ({ status }) => {
  const { color, icon } =
    StatusIconDisplayMap[status] || StatusIconDisplayMap.DEFAULT;
  return <StyledStatusIcon icon={icon} color={color} />;
};

const StatusItem = ({ text, status }) => (
  <Grid data-tour={`${text}-${status}`} item xs={6} sm={4} key={text}>
    <StatusIcon status={status} />
    {text}
  </Grid>
);

const UglyPaper = styled(({ alert: ignored, ...paperProps }) => (
  <Paper {...paperProps} />
))(({ alert, theme }) => {
  const color = alert ? theme.colors.warning : "transparent";
  return `
    border-left: 20px solid ${color};
    border-right: 20px solid ${color};
    border-top: 4px solid ${color};
    border-bottom: 4px solid ${color};     
  `;
});

export const Status = ({ singleWeighId }) => {
  const currentStatus = useSelector(getStatusBySingleWeighId(singleWeighId));

  const currentDecision = useSelector(
    getCurrentDraftingDecisionBySingleWeighById(singleWeighId),
  );

  const { draftPenId, eid, totalMassGrams, areMultipleEidsDetected } =
    currentDecision || {};

  const saleLotId = useSelector(getSaleLotIdByEid(eid));

  const isPlcReady = useSelector(selectIsPLCReady);

  const singleWeigh = useSelector(getSingleWeighById(singleWeighId));
  const { isPaused } = singleWeigh || {};

  // TODO - there is duplication between selectCanSendDraftingDecisionBySingleWeighIdLookup vs this.
  // Each of these should probably get moved to a selector, that makes up the composition of selectCanSendDraftingDecisionBySingleWeighIdLookup
  const statusItems = {
    "Steady Weight": !!totalMassGrams,
    "PLC Ready": !!isPlcReady,
    EID: !!eid && !areMultipleEidsDetected,
    "Not Paused": !isPaused,
    // TODO - this should block if that pen is locked (or full?)
    "Drafting Pen Available": !!draftPenId,
  };

  const ActionComponent = SingleWeighStatusComponentMap[currentStatus] || null;

  return (
    <UglyPaper alert={!!ActionComponent}>
      <Column justifyBetween fullHeight fullWidth>
        {saleLotId ? (
          <Row justifyEnd>
            {" "}
            <ClickableCommentIcon
              commentType={CommentTypes.SALE_LOT}
              commentTypeId={saleLotId}
            />
          </Row>
        ) : null}
        <Row flexGrow fullWidth justifyCenter alignCenter>
          <h2>{currentStatus || "Could not determine Single Weigh state."}</h2>
        </Row>
        {ActionComponent && (
          <Row flexGrow fullWidth>
            <ActionComponent singleWeighId={singleWeighId} />
          </Row>
        )}
        <Row>
          <Grid container>
            {Object.entries(statusItems).map(([text, status]) => (
              <StatusItem
                key={`${text}${status}`}
                text={text}
                status={status}
              />
            ))}
          </Grid>
        </Row>
      </Column>
    </UglyPaper>
  );
};
