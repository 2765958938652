import styled from "styled-components";

export const Fieldset = styled.fieldset(
  ({ flexDirection = "row" }) => `
  display: flex;
  flex-direction: ${flexDirection};
  flex: 1;
  padding: 0;
  margin: 0;
  border: 0;
`,
);
