import reduceReducers from "reduce-reducers";

import {
  MASTER_RULE,
  MASTER_RULE_BOOK,
  RULE,
  RULE_BOOK,
} from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  passThroughDeserializer,
} from "lib/reducers";

const createReducer = apiModelOfflineCreateReducer(RULE, {
  deserializer: passThroughDeserializer,
});

const deleteReducer = apiModelOfflineDeleteReducer(RULE);

const fetchReducer = apiModelOfflineFetchReducer(RULE, {
  deserializer: passThroughDeserializer,
});

const updateReducer = apiModelOfflineUpdateReducer(RULE, {
  deserializer: passThroughDeserializer,
});

const masterCreateReducer = apiModelOfflineCreateReducer(MASTER_RULE, {
  deserializer: passThroughDeserializer,
});

const masterDeleteReducer = (state, action) => {
  switch (action.type) {
    case MASTER_RULE.DELETE.REQUEST: {
      // Deleting a master rule is actually just sticking a `is_deleted` flag on it!
      const { id } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            is_deleted: true,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

const masterFetchReducer = apiModelOfflineFetchReducer(MASTER_RULE, {
  deserializer: passThroughDeserializer,
});

const masterUpdateReducer = apiModelOfflineUpdateReducer(MASTER_RULE, {
  deserializer: passThroughDeserializer,
});

const createRuleBookReducerCreator = type => (state, action) => {
  switch (action.type) {
    case type.CREATE.REQUEST: {
      const { rules } = action.payload;
      if (!Array.isArray(rules) || !rules.length) {
        return state;
      }
      // Add any newly created rules to the state when a Rule Book is created
      return {
        ...state,
        byId: rules.reduce(
          (acc, rule) => {
            acc[rule.id] = {
              ...rule,
              rule_book_id: action.payload.id,
              syncing: true,
            };
            return acc;
          },
          { ...state.byId },
        ),
      };
    }
    default:
      return state;
  }
};

export const ruleReducer = reduceReducers(
  createReducer,
  deleteReducer,
  fetchReducer,
  updateReducer,
  createRuleBookReducerCreator(RULE_BOOK),
);

export const masterRuleReducer = reduceReducers(
  masterCreateReducer,
  masterDeleteReducer,
  masterFetchReducer,
  masterUpdateReducer,
  createRuleBookReducerCreator(MASTER_RULE_BOOK),
);
