import { GateStatus } from "constants/auctionPens";
import {
  AutoDraftPenStatus,
  AutoDraftPenStatusColorMap,
  DEFAULT_STABLE_SAMPLE_COUNT,
  DEFAULT_STABLE_SAMPLE_THRESHOLD_GRAMS,
  SingleWeighMode,
  SingleWeighModeConfiguration,
  SingleWeighStatus,
} from "constants/singleWeigh";

export function getDraftingPenDecision(
  destinationPenId,
  draftingPens,
  numberInPenByPenIdLookup,
) {
  // Uses the available data to find out the best pen to put this animal in.

  // TODOs
  // - check the filters - if some explicit filters have been set, and they don't apply
  // here, then we can't use it... (I think we'll need more variables into the function for that...)
  // - check exit gate status - default is currently open, so we'd need to manually close them
  // all before ANY drafting would happen... blehhh

  // First check if there is a pen already setup for this destination pen..
  const alreadySetupPen = draftingPens
    .filter(draftingPen => Boolean(draftingPen.autoDraftState))
    .find(
      draftingPen =>
        // Already assigned to this pen.
        draftingPen.autoDraftState.destinationPenId === destinationPenId &&
        // Entry gate locked
        draftingPen.autoDraftState.entryGateStatus !== GateStatus.LOCKED &&
        // and we don't have capacity limits; or we're under them.
        (!numberInPenByPenIdLookup ||
          draftingPen.capacity < 0 ||
          !numberInPenByPenIdLookup[draftingPen.id] ||
          (draftingPen.capacity >= 0 &&
            numberInPenByPenIdLookup[draftingPen.id].length <
              draftingPen.capacity)),
    );

  if (alreadySetupPen) {
    return alreadySetupPen.id;
  }

  // No luck?  Try and find one that doesn't have a destination pen yet - this should
  // be devoid of any capacity concerns, as it's empty!
  const unDraftedPen = draftingPens.find(
    draftingPen =>
      // No state initalized yet, or...
      draftingPen.autoDraftState === null ||
      // no destination applied yet
      (!draftingPen.autoDraftState.destinationPenId &&
        // And not locked
        draftingPen.autoDraftState.entryGateStatus !== GateStatus.LOCKED),
  );

  if (unDraftedPen) {
    return unDraftedPen.id;
  }

  // Nowhere to put it!
  return null;
}

export function canSendDraftingDecision(
  draftingDecision,
  singleWeigh,
  isPlcReady,
) {
  return (
    singleWeigh &&
    !singleWeigh.isPaused &&
    singleWeigh.mode !== SingleWeighMode.DISABLED &&
    draftingDecision &&
    draftingDecision.draftPenId &&
    draftingDecision.totalMassGrams &&
    draftingDecision.eid &&
    isPlcReady
  );
}

export function shouldAutomaticallySendDraftingDecision(
  status,
  canSendDraftingDecision,
  mode,
) {
  return (
    status === SingleWeighStatus.READY_TO_AUTO_DRAFT &&
    canSendDraftingDecision &&
    SingleWeighModeConfiguration[mode].sendAutoDecision
  );
}

export function isSingleWeighCurrent(singleWeighId, currentSingleWeighId) {
  return (
    singleWeighId &&
    currentSingleWeighId &&
    parseInt(singleWeighId, 10) === parseInt(currentSingleWeighId, 10)
  );
}

export function isSingleWeighInitialized(singleWeigh, singleWeighContext) {
  return Boolean(singleWeigh) && Boolean(singleWeighContext);
}

export function isAnimalAlreadyWeighed(scan, currentDecision) {
  return scan?.total_mass_grams && !currentDecision.ignoreAlreadyWeighedCheck;
}

export function getAutoDraftPenStatus(autoDraftState, processedHd, totalHd) {
  const { destinationPenId, entryGateStatus, exitGateStatus } =
    autoDraftState || {};

  if (exitGateStatus === GateStatus.OPEN) {
    return AutoDraftPenStatus.EMPTYING_OR_PASSTHROUGH;
  } else if (processedHd > totalHd) {
    return AutoDraftPenStatus.OVER_SCANNED;
  } else if (totalHd === processedHd && totalHd > 0) {
    return AutoDraftPenStatus.READY_TO_EMPTY;
  } else if (entryGateStatus === GateStatus.LOCKED) {
    return AutoDraftPenStatus.DISABLED;
  } else if (destinationPenId) {
    return AutoDraftPenStatus.FILLING;
  }
  return AutoDraftPenStatus.UNKNOWN;
}

export function getAutoDraftPenStatusColour(autoDraftPenStatus) {
  return (
    AutoDraftPenStatusColorMap[autoDraftPenStatus] ||
    AutoDraftPenStatusColorMap[AutoDraftPenStatus.UNKNOWN]
  );
}

/**
 * returns whether the provided samples satisfty the weight sampling criteria specified by the given Single Weigh instance
 * @param {SingleWeigh} singleWeigh An instance of a Single Weigh model
 * @param {number} currentWeightGrams The weight, in grams, to test the against
 * @param {Array<number>} weightSamples The list of sampled weights, in grams, to test
 * @return {boolean}
 */
export function getIsSteadySampleWeight(
  singleWeigh,
  currentWeightGrams,
  weightSamples,
) {
  const stableWeightSameThresholdGrams =
    typeof singleWeigh.stableWeightSampleThresholdGrams === "number"
      ? singleWeigh.stableWeightSampleThresholdGrams
      : DEFAULT_STABLE_SAMPLE_THRESHOLD_GRAMS;

  const stableWeightSampleCount =
    typeof singleWeigh.stableWeightSampleCount === "number"
      ? singleWeigh.stableWeightSampleCount
      : DEFAULT_STABLE_SAMPLE_COUNT;

  const hasEnoughSamples = weightSamples.length >= stableWeightSampleCount;

  const areAllSamplesWithinThreshold = weightSamples.every(
    sampledWeightGrams => {
      return (
        Math.abs(currentWeightGrams - sampledWeightGrams) <=
        stableWeightSameThresholdGrams
      );
    },
  );

  return hasEnoughSamples && areAllSamplesWithinThreshold;
}
