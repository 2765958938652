import React, { memo } from "react";

import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";

import {
  OpenGeneralCell,
  OpenSaleLotsCell,
} from "components/DeploymentSaleSummaries/ConsignmentCells";
import { SummaryReactTable } from "components/DeploymentSaleSummaries/SummaryTable";

import {
  getBusinesses,
  getConsignmentExceptionsByDeploymentSaleId,
  getConsignments,
  getProperties,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsBalancedBySaleLotIdLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleLotQuantityByConsignmentIdLookup,
} from "selectors";

import { OpenGeneralExceptionsCell } from "./CellButton";

// const OpenNlisCell = OpenModalCellCreator(ConsignmentModalSection.NLIS);

const CONSIGNMENT_EXCEPTIONS_COLUMNS = [
  {
    Header: <div style={{ paddingLeft: 6 }}>Vendor Name</div>,
    accessor: "vendorName",
    style: { paddingLeft: 12 },
    Cell: OpenGeneralCell,
  },
  {
    Header: "PIC",
    accessor: "pic",
    Cell: OpenGeneralCell,
  },
  {
    Header: "NVD Number",
    accessor: "nvdNumber",
    Cell: OpenGeneralCell,
  },
  {
    Header: "NVD HC",
    accessor: "nvdCount",
    Cell: OpenGeneralCell,
  },
  {
    Header: "Actual/Received HC",
    accessor: "receivedCount",
    Cell: OpenGeneralCell,
  },
  {
    Header: "Sale Lots Total",
    accessor: "saleLotsCount",
  },
  {
    Header: "Consignment Balanced",
    accessor: "consignmentBalanced",
    Cell: OpenGeneralCell,
  },
  {
    Header: "Sale Lots Balanced",
    accessor: "saleLotsBalanced",
    Cell: OpenSaleLotsCell,
  },
  // {
  //   Header: "Compliance",
  //   accessor: "compliance",
  // },
  // {
  //   Header: "NLIS",
  //   accessor: "nlis",
  //   Cell: OpenNlisCell,
  // },
  {
    Header: "Exceptions",
    accessor: "exceptions",
    Cell: OpenGeneralExceptionsCell,
  },
];

function ConsignmentExceptionsTableComponent(props) {
  const { deploymentSaleId } = props;
  const consignmentExceptions = useSelector(
    getConsignmentExceptionsByDeploymentSaleId(deploymentSaleId),
  );
  const consignments = useSelector(getConsignments);
  const businesses = useSelector(getBusinesses);
  const properties = useSelector(getProperties);
  const saleLotQuantityByConsignmentId = useSelector(
    selectSaleLotQuantityByConsignmentIdLookup,
  );
  const isPostSaleBalancedByConsignmentId = useSelector(
    selectIsPostSaleBalancedByConsignmentIdLookup,
  );
  const saleLotIdsByConsignmentId = useSelector(
    selectSaleLotIdsByConsignmentIdLookup,
  );
  const isBalancedBySaleLotId = useSelector(selectIsBalancedBySaleLotIdLookup);

  const data = consignmentExceptions.map(({ consignmentId, exceptions }) => {
    const consignment = consignments[consignmentId] || {};
    const saleLotIds = saleLotIdsByConsignmentId[consignmentId] || [];
    const areSaleLotsBalanced = saleLotIds.every(
      saleLotId => isBalancedBySaleLotId[saleLotId],
    );

    return {
      consignmentId,
      exceptions,
      pic: properties[consignment.vendor_property_id]?.PIC,
      nvdNumber: consignment.NVD,
      nvdCount: consignment.quantity_NVD,
      receivedCount: consignment.quantity,
      saleLotsCount: saleLotQuantityByConsignmentId[consignmentId],
      consignmentBalanced: isPostSaleBalancedByConsignmentId[consignmentId]
        ? "Balanced"
        : "Unbalanced",
      saleLotsBalanced: areSaleLotsBalanced ? "Balanced" : "Unbalanced",
      // compliance: ,
      // nlis: ,
      vendorName: businesses[consignment.vendor_id]?.name,
    };
  });

  return (
    <SummaryReactTable data={data} columns={CONSIGNMENT_EXCEPTIONS_COLUMNS} />
  );
}

ConsignmentExceptionsTableComponent.propTypes = {
  deploymentSaleId: PropTypes.number.isRequired,
};

export default memo(ConsignmentExceptionsTableComponent);
