import React, { useState } from "react";

import {
  faCrosshairs,
  faFilePdf,
  faPencilAlt,
  faPlus,
  faPrint,
  faTrashAlt,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { deleteConsignment, patchSaleLot, printVendorSummary } from "actions";

import { IconTextButton } from "components/Button";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import Dialog from "components/Dialog";
import { CurrentSaleReadOnly } from "components/ReadOnly";
import TabletDropdown from "components/TabletDropdown";

import { ConsignmentModalSection, ModalTypes } from "constants/navigation";
import { DeploymentPermissions } from "constants/permissions";

import { ForLivestockSale } from "containers/ForSaleType";
import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { getEditConsignmentHashRoute, openModalLink } from "lib/navigation";
import toast from "lib/toast";

import {
  getHasWriteAccessInCurrentSale,
  selectRoleCurrentDeployments,
} from "selectors";

import { useSomeHasPermission } from "hooks/useHasPermission";

export const ConsignmentCardActionOptions = ({
  mainObject,
  consignment,
  saleLots,
  vendorReportUrl,
  basePath,
}) => {
  const [isUnsellDialog, setIsUnsellDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const unsell = saleLot => {
    const patch = {
      total_price_cents: 0,
      buyer_id: null,
      buyer_way: null,
      id: saleLot.id,
    };
    dispatch(patchSaleLot(patch, { changeReason: "Bulk unsold" }));
  };

  const doDeleteConsignment = () => {
    saleLots.length === 0
      ? setShowDeleteDialog(true)
      : toast.error("Consignment cannot be deleted when it has sale lots.");
  };

  const doPrintVendorSummary = () => {
    dispatch(printVendorSummary(mainObject.id));
  };

  const { id, resumeScanUrl } = mainObject;
  const manualChargesModalProps = { fromBusinessId: consignment.vendor_id };

  const hasManualChargePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureManualCharges,
  );

  return (
    <>
      <TabletDropdown data-tour="consignmentDropdown" align="right">
        <IconTextButton
          dataTour="edit"
          as={Link}
          icon={faPencilAlt}
          color="white"
          to={`#${getEditConsignmentHashRoute(
            id,
            ConsignmentModalSection.GENERAL,
            true,
            false,
            basePath,
          )}`}
        >
          View/Edit{" "}
          <TranslatedSaleTypeLabel data-tour="edit" label="Consignment" />
        </IconTextButton>
        <CurrentSaleReadOnly>
          <IconTextButton
            data-tour="delete"
            onClick={doDeleteConsignment}
            disabled={saleLots.length > 0}
            icon={faTrashAlt}
            color="white"
          >
            Delete <TranslatedSaleTypeLabel label="Consignment" />
          </IconTextButton>
        </CurrentSaleReadOnly>
        <ForLivestockSale>
          {hasWriteAccessInCurrentSale ? (
            <IconTextButton
              data-tour="resume"
              as={Link}
              icon={faCrosshairs}
              color="white"
              to={resumeScanUrl}
            >
              Resume Scanning
            </IconTextButton>
          ) : (
            <IconTextButton
              data-tour="resume"
              as={Link}
              icon={faLock}
              color="white"
              disabled
            >
              Resume Scanning
            </IconTextButton>
          )}
        </ForLivestockSale>
        {hasManualChargePermission && (
          <IconTextButton
            data-tour="add-manual"
            onClick={() =>
              openModalLink(
                ModalTypes.ManualAdjustmentForm,
                manualChargesModalProps,
              )
            }
            icon={faPlus}
            color="white"
          >
            Add Manual Charge
          </IconTextButton>
        )}
        <IconTextButton
          icon={faPrint}
          color="white"
          onClick={doPrintVendorSummary}
          data-tour="vendor"
        >
          Vendor Summary
        </IconTextButton>
        {vendorReportUrl && (
          <IconTextButton
            icon={faFilePdf}
            color="white"
            onClick={() => window.open(vendorReportUrl, "_blank")}
            data-tour="report"
          >
            Vendor Report
          </IconTextButton>
        )}
      </TabletDropdown>
      {isUnsellDialog && (
        <Dialog
          closeSelf={() => setIsUnsellDialog(false)}
          handleSubmit={() => unsell(mainObject)}
          title="Unsell lot?"
          subtitle="Are you sure you want to undo this sale?"
          submitButtonText="Unsell"
          rejectButtonText="Cancel"
          icon={faUndoAlt}
        />
      )}
      <ConfirmDialog
        isOpen={showDeleteDialog}
        title={createModalTitle("this Consignment")}
        onCancel={() => setShowDeleteDialog(false)}
        onDelete={() => {
          dispatch(deleteConsignment(consignment));
          setShowDeleteDialog(false);
        }}
      />
    </>
  );
};
