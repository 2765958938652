import {
  CLEAR_SOCKET_BUFFER,
  PROCESS_SOCKET_MESSAGE,
  QUEUE_SOCKET_MESSAGE,
} from "constants/actionTypes";

export function queueSocketMessage(message_type, payload) {
  return {
    type: QUEUE_SOCKET_MESSAGE,
    message_type,
    payload,
  };
}

export function clearSocketBuffer() {
  return {
    type: CLEAR_SOCKET_BUFFER,
  };
}

export function processSocketMessage(message) {
  return {
    type: PROCESS_SOCKET_MESSAGE,
    message,
  };
}
