import { orderBy } from "lodash";
import { createSelector } from "reselect";

import {
  getBusinesses,
  getCurrentDeploymentSaleIds,
  getEContracts,
} from "selectors";

export const getEContractById = eContractId => state =>
  getEContracts(state)[eContractId] || null;

export const selectEContracts = createSelector(
  [getCurrentDeploymentSaleIds, getEContracts],
  (deploymentSaleIds, eContracts) => {
    const eContractsInSale = Object.values(eContracts).filter(ec =>
      deploymentSaleIds.includes(ec.deploymentSaleId),
    );
    return orderBy(eContractsInSale, ["version"], ["desc"]);
  },
);

export const selectEContractsByTypeLookup = createSelector(
  [selectEContracts],
  eContracts =>
    eContracts.reduce((accumulator, eContract) => {
      if (!Array.isArray(accumulator[eContract.type])) {
        accumulator[eContract.type] = [];
      }
      accumulator[eContract.type].push(eContract);
      return accumulator;
    }, {}),
);

export const hasValidEContract = createSelector(
  [selectEContracts],
  eContracts => {
    return Object.values(eContracts).some(ec => !ec.voidedOn);
  },
);

export const getBuyerByEContractId = eContractId => state => {
  const eContract = getEContractById(eContractId)(state);
  if (!eContract) {
    return {};
  }
  return getBusinesses(state)[eContract.buyerBusinessId];
};

export const getVendorByEContractId = eContractId => state => {
  const eContract = getEContractById(eContractId)(state);
  if (!eContract) {
    return {};
  }
  return getBusinesses(state)[eContract.vendorBusinessId];
};
