import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { CenteredGreedy } from "components/Layout";

import LargeErrorMessage from "./LargeErrorMessage";

const Wrapper = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.error};
  line-height: 1.5rem;
`;

const ErrorMessage = ({ children, ...rest }) => (
  // If no error message present, still render some text
  // this is done to reserve the space in the dom for the error message
  <Wrapper {...rest}>{children || "hidden text"}</Wrapper>
);

export const BoldErrorMessage = ({ icon, children }) => (
  <CenteredGreedy>
    <Wrapper>
      <h2>
        {icon && (
          <>
            <FontAwesomeIcon icon={icon} />{" "}
          </>
        )}
        {children}
      </h2>
    </Wrapper>
  </CenteredGreedy>
);

export { LargeErrorMessage };
export default ErrorMessage;

export const Warning = styled.div`
  ${({ theme }) => `
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 2px 14px;
  font-size: 14px;
  font-weight: bold;
  color: ${theme.colors.error}
  `}
`;
