import React, { memo, useState } from "react";

import { SaleLotModalSection } from "constants/navigation";

import SaleLot from "containers/SaleLot";

export function ScanningSaleLotModalComponent(props) {
  const { closeSelf, consignmentId, modalProps } = props;
  const { onNewSaleLotId } = modalProps;
  const [openSaleLotSection, setOpenSaleLotSection] = useState(
    SaleLotModalSection.GENERAL,
  );
  const { location } = window;
  function changeSection(fromSection, toSection) {
    if (fromSection === toSection) {
      setOpenSaleLotSection(null);
    } else {
      setOpenSaleLotSection(toSection);
    }
  }
  return (
    <SaleLot
      returnTo={`${location.pathname}${location.search}`}
      closeSelf={closeSelf}
      consignmentId={consignmentId}
      onBeforeClose={onNewSaleLotId}
      changeSection={changeSection}
      section={openSaleLotSection}
    />
  );
}

export default memo(ScanningSaleLotModalComponent);
