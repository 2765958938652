import { ColumnType } from "components/AgGrid/constants";

import { getters } from "lib/agGrid";
import { RuleOrderRenderer } from "lib/agGrid/renderers";

export const RuleColId = {
  COMMENT: "f40e6131-5a54-42eb-899f-7b3589e58e3b",
  CREATED: "cda8afc2-378d-401a-9de2-008520e03da5",
  GL_CODE: "da11dfd0-f411-46a8-9132-82c07ff570d2",
  INVOICE_LINE_ITEM_TEMPLATE: "02ced62a-2185-460b-be5b-d5b71af606f9",
  LAST_MODIFIED: "7f929fc4-71e7-4018-b105-40cd645820cb",
  MASTER_RULE_ID: "4a63d862-6c6c-4788-98c8-8fa447627d1e",
  NAME: "f0227e96-3188-457f-8b9a-98935bd6ee75",
  ORDER: "f6c9b793-e641-4631-8f52-32b622922027",
  RULE_BOOK_ID: "4019dbf4-4096-4774-90b7-30eb77cfc8f9",
  TITLE_TEMPLATE: "dbe46a99-58d2-4da7-85be-9f9f71818b44",
  BILLING_TAGS: "d022e976-a76e-43fa-8465-31188982b87a",
  CATEGORY: "95b37795-5aaa-49d4-8f0f-0466555cbe4a",
  INCLUDE_IF_ZERO: "c8f2a0ae-d9d9-4250-8465-a77db1d8e29f",
  INPUT_FIELD: "d3551e5f-be32-4f02-878e-5fcaecc70db1",
  PRICE_MULTIPLIER: "ef8b6674-fc02-4265-8912-f8bf2c1bc740",
  TAX_TYPE: "9f406190-dd07-47d3-9b91-700cd7cdc3b5",
};

export const RuleColumnDefinitions = {
  [RuleColId.NAME]: {
    colId: RuleColId.NAME,
    field: "rule.name",
    headerName: "Name",
    width: 90,
    type: ColumnType.STRING,
  },
  [RuleColId.GL_CODE]: {
    colId: RuleColId.GL_CODE,
    field: "rule.gl_code",
    headerName: "GL Code",
    width: 90,
    type: ColumnType.STRING,
    valueGetter: getters.autoFieldGetter,
    filterValueGetter: getters.autoFieldGetter,
  },
  [RuleColId.CREATED]: {
    colId: RuleColId.CREATED,
    field: "rule.created",
    headerName: "Created",
    width: 90,
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },
  [RuleColId.LAST_MODIFIED]: {
    colId: RuleColId.LAST_MODIFIED,
    field: "rule.last_modified",
    headerName: "Last Modified",
    width: 90,
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },
  [RuleColId.INVOICE_LINE_ITEM_TEMPLATE]: {
    colId: RuleColId.INVOICE_LINE_ITEM_TEMPLATE,
    field: "rule.invoice_line_item_template",
    headerName: "Grouped Title",
    width: 90,
    type: ColumnType.STRING,
  },
  [RuleColId.TITLE_TEMPLATE]: {
    colId: RuleColId.TITLE_TEMPLATE,
    field: "rule.title_template",
    headerName: "Title",
    width: 90,
    type: ColumnType.STRING,
  },
  [RuleColId.ORDER]: {
    colId: RuleColId.ORDER,
    field: "rule.order",
    headerName: "Order",
    width: 90,
    type: ColumnType.NUMERIC,
    cellRenderer: RuleOrderRenderer,
  },
  [RuleColId.BILLING_TAGS]: {
    colId: RuleColId.BILLING_TAGS,
    field: "rule.parsedContent.billing_tags",
    headerName: "Tags",
    width: 90,
    type: ColumnType.STRING,
  },
  [RuleColId.CATEGORY]: {
    colId: RuleColId.CATEGORY,
    field: "rule.parsedContent.category",
    headerName: "Category",
    width: 90,
    type: ColumnType.STRING,
  },
  [RuleColId.INCLUDE_IF_ZERO]: {
    colId: RuleColId.INCLUDE_IF_ZERO,
    field: "rule.parsedContent.include_if_zero",
    headerName: "Include If Zero?",
    width: 90,
    type: ColumnType.BOOLEAN,
  },
  [RuleColId.INPUT_FIELD]: {
    colId: RuleColId.INPUT_FIELD,
    field: "rule.parsedContent.input_field",
    headerName: "Based On",
    width: 90,
    type: ColumnType.STRING,
    valueGetter: getters.ruleInputField,
  },
  [RuleColId.TAX_TYPE]: {
    colId: RuleColId.TAX_TYPE,
    field: "rule.parsedContent.tax_type",
    headerName: "Tax Type",
    width: 90,
    type: ColumnType.STRING,
    valueGetter: getters.autoFieldGetter,
    filterValueGetter: getters.autoFieldGetter,
  },
  [RuleColId.PRICE_MULTIPLIER]: {
    colId: RuleColId.PRICE_MULTIPLIER,
    field: "rule.parsedContent.price_multiplier",
    headerName: "Price Multiplier",
    width: 90,
    type: ColumnType.NUMERIC,
  },
};
