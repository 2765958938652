import React, { memo } from "react";

import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SelfTitledField } from "components/Card/Elements";
import { Row } from "components/Layout";

import { getScanBuffer, getUnassignedScans } from "selectors";

import ScannerSelectInline from "./ScannerSelectInline";

const BufferCountComponent = () => {
  const bufferCount = useSelector(getScanBuffer).length;

  return bufferCount > 0 ? (
    <span>&nbsp; Processing: {bufferCount}</span>
  ) : (
    "\u00A0"
  );
};

const BufferCount = memo(BufferCountComponent, () => true);

const ScanningSummaryHeaderComponent = props => {
  const { showTotal = true } = props;
  const processedScans = useSelector(getUnassignedScans);
  const processedScanCount = Object.keys(processedScans).length;
  return (
    <Header>
      <Row alignCenter basis="270px">
        <ScannerSelectInline showIndicator showFailedMessage />
      </Row>

      {showTotal && (
        <SelfTitledField
          title={`Total EIDs: ${processedScanCount}`}
          value={<BufferCount />}
        />
      )}
    </Header>
  );
};
ScanningSummaryHeaderComponent.propTypes = {
  showTotal: PropTypes.bool,
};

export default memo(ScanningSummaryHeaderComponent);

const Header = styled(Row)(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  padding: ${theme.space[1]}px ${theme.space[2]}px;
  justify-content: space-between;
  align-items: center;`,
);
