import { envdSerializeMap } from "lib/serializers/envd";
import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const attachmentSerializeMap = {
  consignment: "consignment",
  deploymentSale: "deployment_sale",
  draftDeclaration: {
    version: "VERSION",
    additionalInformation: "additional_information",
    chemicalResidueRestrictions: "chemical_residue_restrictions",
    chemicalResidueRestrictionsDetails: "chemical_residue_restrictions_details",
    consumedMaterialInWithholdingPeriod:
      "consumed_material_in_withholding_period",
    consumedMaterialInWithholdingPeriodDateApplied:
      "consumed_material_in_withholding_period_date_applied",
    consumedMaterialInWithholdingPeriodDateCeased:
      "consumed_material_in_withholding_period_date_ceased",
    consumedMaterialInWithholdingPeriodDateFirstFed:
      "consumed_material_in_withholding_period_date_first_fed",
    consumedMaterialInWithholdingPeriodGrazingWhp:
      "consumed_material_in_withholding_period_grazing_whp",
    consumedMaterialInWithholdingPeriodProduct:
      "consumed_material_in_withholding_period_product",
    fedByproductStockfeeds: "fed_byproduct_stockfeeds",
    fedFeedContainingAnimalFats: "fed_feed_containing_animal_fats",
    id: "id",
    ownedSinceBirth: "owned_since_birth",
    ownedSinceBirthDuration: "owned_since_birth_duration",
    sprayDriftRisk: "spray_drift_risk",
    sprayDriftRiskDate: "spray_drift_risk_date",
    treatedWithHgp: "treated_with_hgp",
    veterinaryTreatment: "veterinary_treatment",
    veterinaryTreatmentDate: "veterinary_treatment_date",
    veterinaryTreatmentEsi: "veterinary_treatment_esi",
    veterinaryTreatmentWhp: "veterinary_treatment_whp",
    qaAccredited: "qa_accredited",
    treatedForScabbyMouth: "treated_for_scabby_mouth",
  },
  envd: envdSerializeMap,

  id: "id",
  image_url: "image_url",
  livestock_sale: "livestock_sale",
  order: "order",
  sale_lot: "sale_lot",
  thumbnail_url: "thumbnail_url",
  type: "type",
};

const nestedObjectMap = {
  draft_declaration: "draftDeclaration",
  envd: "envd",
};

export const deserializeAttachment = buildDeserializer(
  attachmentSerializeMap,
  "attachment",
  nestedObjectMap,
);

export const serializeAttachment = buildSerializer(
  attachmentSerializeMap,
  "attachment",
  nestedObjectMap,
);
