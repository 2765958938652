import React from "react";

import { useDispatch } from "react-redux";

import { addScansFromFile } from "actions";

import { Dropzone } from "components/Form/FormikControls";
import { CenteredGreedy } from "components/Layout";
import { ExplanationText, Title } from "components/ScanningScreen/Elements";

import { EIDPattern } from "constants/scanner";

import toast from "lib/toast";

const AleisSessionPattern =
  /^(Session \d{1,4},[^,]{0,3},\d{1,2},[01],[^,]{0,3},\d{1,2},[01],[^,]{0,3},\d{1,2},[01],[^,]{0,3},\d{1,2},[01](,[\dA-F]{4})?\r?\n?([0-9]{5},[AR] \d{5} \d \d{4} \d{12},\d{12},[^,]{0,16},[^,]{0,16},[^,]{0,16},[^,]{0,16}(,[\dA-F]{4})?\r?\n?)*)+$/;
const AleisSessionHeaderPattern =
  /^(Session \d{1,4}),([^,]{0,3}),(\d{1,2}),([01]),([^,]{0,3}),(\d{1,2}),([01]),([^,]{0,3}),(\d{1,2}),([01]),([^,]{0,3}),(\d{1,2}),([01])(,[\dA-F]{4})?$/;
const AleisSessionBodyPattern =
  /(\d{5}),[AR] \d{5} \d \d(\d{3} \d{12}),(\d{12}),([^,]{0,16}),([^,]{0,16}),([^,]{0,16}),([^,]{0,16})(,[\dA-F]{4})?/;

const EolPattern = /\r\n|\r|\n/;
const EidExtractor = new RegExp(EIDPattern.SIXTEEN_DIGIT_PATTERN, "gm");

const parseAleisSessions = data => {
  // Try and detect a format like this:
  // Session 1,PEN,16,1,AGT,16,1,PIC,16,1,MRK,16,1
  // 00001,A 00000 0 0942 000032465626,041219060837,34,,,
  // 00002,A 00000 0 0942 000032465322,041219060838,34,,,
  // 00003,A 00000 0 0942 000032465484,041219060838,34,,,
  // Session 2,PEN,16,1,AGT,16,1,PIC,16,1,MRK,16,1
  // 00001,A 00000 0 0942 000032464933,041219061755,35,,,
  // 00002,A 00000 0 0942 000032465082,041219061756,35,,,
  // 00003,A 00000 0 0942 000032464562,041219061757,35,,,

  // In theory the data should only ever be terminated by a newline, however if the file is ever
  // opened and saved in a text editor or copied and pasted (e.g. from an email)
  // on Windows, the newlines will likely be converted to \r\n,
  // on unix (it probably won't change them, but) \n and
  // on _OLD_ osx versions \r
  const sessions = [];
  const dataRows = data.split(EolPattern);

  let currentSession = null;
  for (const row of dataRows) {
    // Check if the current row is a session header
    const headerMatch = AleisSessionHeaderPattern.exec(row);

    if (headerMatch) {
      // Allocate another session descriptor
      currentSession = {
        name: headerMatch[1],
        metadata: [
          { name: headerMatch[2], value: "" },
          { name: headerMatch[5], value: "" },
          { name: headerMatch[8], value: "" },
          { name: headerMatch[11], value: "" },
        ],
        scans: [],
      };

      // Store the new session descriptor
      sessions.push(currentSession);
    } else {
      // The current is not a session header, so extract the body row
      const bodyMatch = AleisSessionBodyPattern.exec(row);

      if (bodyMatch) {
        // The current row is a body row, if bodyMatch is ever null, then we have a problem
        // Also, if the current session is ever null, then there is
        // A: a problem with the data, and
        // B: a problem with the the `AleisSessionPattern`, as it shouldn't have matched to begin with

        const timestampString = bodyMatch[3];

        const scan = {
          eid: bodyMatch[2],
          // timestamp format is DDMMYYHHmmSS
          timestamp: new Date(
            2000 + parseInt(timestampString.slice(4, 6), 10),
            parseInt(timestampString.slice(2, 4), 10) - 1,
            +timestampString.slice(0, 2),
            +timestampString.slice(6, 8),
            +timestampString.slice(8, 10),
            +timestampString.slice(10, 12),
          ),
        };
        // There are 4 metadata items which may be attached to an EID
        for (let i = 0; i < 4; i++) {
          // Get the metadata attached to this EID
          const metadataValue = bodyMatch[i + 4];
          // Obtain the first found metadata value the EIDs into the associated session value,
          // apply logic for each of the four metadata values available
          if (currentSession.metadata[i].value === "" && metadataValue !== "") {
            currentSession.metadata[i].value = metadataValue;
          }
        }
        currentSession.scans.push(scan);
      }
    }
  }
  return sessions;
};

const transformAleisSessionsToScans = (sessions, filename) => {
  const scans = [];
  sessions.forEach(session => {
    const sessionName = session.metadata
      .filter(metadata => metadata.value.trim().length > 0)
      .map(metadata => `${metadata.name}: ${metadata.value}`)
      .join(", ");

    session.scans.forEach(scan => {
      scans.push({
        deviceId: "file",
        deviceName: filename,
        draftName: sessionName || session.name,
        EID: scan.eid,
        created: scan.timestamp,
      });
    });
  });
  return scans;
};

const extractScansFromAleisSessions = (data, filename) => {
  const sessions = parseAleisSessions(data);

  if (sessions.length > 0) {
    return transformAleisSessionsToScans(sessions, filename);
  }
  return [];
};

const extractScans = (data, fileType, filename = "FileUpload") => {
  const is9060Format = AleisSessionPattern.test(data);
  if (is9060Format) {
    const scans = extractScansFromAleisSessions(data, filename);
    return scans.length > 0 ? scans : null;
  }

  if (typeof data !== "string") {
    return null;
  }

  // If we still don't have data, just iterate through the file and pull out
  // anything that looks like an eid.
  const scans = [];
  for (const match of data.matchAll(EidExtractor)) {
    scans.push({
      deviceId: "file",
      deviceName: filename,
      draftName: filename,
      EID: match[0],
      created: new Date().toISOString(),
    });
  }
  return scans.length > 0 ? scans : null;
};

export const UploadScanFile = ({ onFile }) => {
  const dispatch = useDispatch();

  const handleUpload = file => {
    let reader;
    onFile && onFile(file.path);

    const handleRead = () => {
      const scans = extractScans(reader.result, file.type, file.name);
      if (Array.isArray(scans) && scans.length > 0) {
        dispatch(addScansFromFile(file.path, scans));
      } else {
        toast.info("Could not find any scans in that file.");
      }
    };

    reader = new FileReader();
    reader.onloadend = handleRead;
    reader.readAsText(file);
  };
  return (
    <CenteredGreedy>
      <Title>Upload File</Title>
      <div>
        <Dropzone onDrop={files => handleUpload(files[0])} />
      </div>
      <ExplanationText>
        <p>Select or drag and drop a session file to import EIDs.</p>
      </ExplanationText>
    </CenteredGreedy>
  );
};
