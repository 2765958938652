import React from "react";

import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { NominationAction, SaleLotAction, WeighLotAction } from "actions";

import { AddDeploymentSaleCommentAction } from "actions/offline/deploymentSale";
import { PenScanLotAction } from "actions/penScanLots";
import { ReceivalLotAction } from "actions/receivalLots";

import { ActionColumn } from "components/Comments/components/ActionColumn";
import { Button } from "components/Form";
import { TextArea, useSubmitHandler } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";

import { CommentTypes } from "constants/comments";

const AddCommentActionMap = {
  [CommentTypes.SALE_LOT]: SaleLotAction.comment,
  [CommentTypes.NOMINATION]: NominationAction.comment,
  [CommentTypes.RECEIVAL_LOT]: ReceivalLotAction.comment,
  [CommentTypes.PEN_SCAN_LOT]: PenScanLotAction.comment,
  [CommentTypes.DEPLOYMENT_SALE]: AddDeploymentSaleCommentAction.comment,
  [CommentTypes.WEIGH_LOT]: WeighLotAction.comment,
};
const commentValidation = Yup.object().shape({
  comment: Yup.string().required(""),
});

function CommentFormBody() {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  useSubmitHandler(false, setIsSubmitEnabled);
  return (
    <Column fullHeight justifyEnd>
      <Form>
        <Paper>
          <Row fullWidth alignEnd justifyBetween>
            <Row fullWidth>
              <TextArea name="comment" label="Add a comment..." rows={3} />
            </Row>
            <ActionColumn alignCenter justifyEnd>
              <Button type="submit" disabled={!isSubmitEnabled}>
                Add Comment
              </Button>
            </ActionColumn>
          </Row>
        </Paper>
      </Form>
    </Column>
  );
}

function CommentForm({ commentType, commentTypeId }) {
  const dispatch = useDispatch();

  function handleSubmit(values, { resetForm }) {
    dispatch(AddCommentActionMap[commentType](commentTypeId, values));
    resetForm();
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ comment: "" }}
      validationSchema={commentValidation}
    >
      <CommentFormBody />
    </Formik>
  );
}

export default CommentForm;
