import React from "react";

import { useSelector } from "react-redux";

import Badge from "components/Badge";
import { AuctionPenERPChip } from "components/Chip";
import { Checkbox } from "components/Form";
import { Row } from "components/Layout";

import { auctionPenStatuses } from "constants/sale";

import { getStatusByAuctionPenId } from "selectors";

import CardInfo from "./CardInfo";
import { ErpChipContainer, StatusWrapper, TopRow } from "./elements";
import PrimaryInfoSet from "./PrimaryInfoSet";

export function SelectableAuctionPenCard({
  auctionPenId,
  handleBlur,
  setFieldValue,
  isLaneStart,
}) {
  const status = useSelector(getStatusByAuctionPenId(auctionPenId));

  const notPenned = status && status === auctionPenStatuses.NOT_PENNED;
  const notCounted = status && status === auctionPenStatuses.NOT_COUNTED;
  let badgeColor = status;
  if (notCounted) {
    badgeColor = "inputError";
  } else if (notPenned) {
    badgeColor = "warning";
  }

  const handleChange = event => {
    event.preventDefault();
    setFieldValue(auctionPenId, !isLaneStart);
  };
  return (
    <StatusWrapper
      color="gray40"
      style={{ flexDirection: "row" }}
      selected={!!isLaneStart}
      onClick={handleChange}
    >
      <TopRow>
        <Badge
          data-tour={status}
          color={badgeColor}
          uppercase
          partially={
            !![
              auctionPenStatuses.PARTIALLY_SOLD,
              auctionPenStatuses.PARTIALLY_DELIVERED,
            ].includes(status)
          }
        >
          {status}
        </Badge>
        <PrimaryInfoSet auctionPenId={auctionPenId} />
      </TopRow>
      <Row justifyBetween flexWrap>
        <Checkbox
          id={`${auctionPenId}`}
          checked={!!isLaneStart}
          value={!!isLaneStart}
          handleChange={handleChange}
          handleBlur={handleBlur}
          useAlternative
        />
        <CardInfo auctionPenId={auctionPenId} />
        <ErpChipContainer>
          <AuctionPenERPChip auctionPenId={auctionPenId} />
        </ErpChipContainer>
      </Row>
    </StatusWrapper>
  );
}
