import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { CattleSpottingCardPortrait } from "components/Pdfs/CattleSpottingCardPortrait";
import { SheepSpottingCardPortrait } from "components/Pdfs/SheepSpottingCardPortrait";

import { sortPenByOrder } from "lib/auctionPens";
import { getPensByAgentId } from "lib/pdf";
import { saleIdOnly, saleIdRoundIdOnly } from "lib/sale";

import {
  getAuctionPensWithSaleLotsBySaleAndRound,
  currentSaleSelector,
  getOrganizationName,
  currentRoundSelector,
} from "selectors";

const mapStateToProps = (state, props) => ({
  auctionPens: props.match.params?.agentId
    ? getPensByAgentId(
        sortPenByOrder(
          getAuctionPensWithSaleLotsBySaleAndRound(
            state,
            saleIdRoundIdOnly(props),
          ),
        ),
        props.match.params?.agentId,
      )
    : getAuctionPensWithSaleLotsBySaleAndRound(state, saleIdRoundIdOnly(props)),
  sale: currentSaleSelector(state, saleIdOnly(props)),
  agencyName: getOrganizationName(state),
  round: currentRoundSelector(state, saleIdRoundIdOnly(props)),
  version: props.match.params.version,
});

const mapDispatchToProps = {};

const SpottingCard = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
);

export const CattleSpottingCard = SpottingCard(CattleSpottingCardPortrait);
export const SheepSpottingCard = SpottingCard(SheepSpottingCardPortrait);
