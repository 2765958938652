export const EmailColId = {
  ATTACHMENTS: "29afe046-f3c9-411e-84cc-192b43730378",
  AUTORESPONDED_TIME: "66b87fbb-1bef-4e88-87ed-5499727dcd40",
  BOUNCED_TIME: "e9a96e72-832d-40a8-85d7-16aefbf2834e",
  CLICKED_TIME: "134eeedd-cca8-466c-86c4-0c94b7b0f29b",
  COMPLAINED_TIME: "e804bb4a-3162-4a75-a2db-5ed7eb099d6d",
  CREATED: "754ab9d5-a806-4986-ae47-0aaf2a3c787a",
  DEFERRED_TIME: "5e821c15-f17e-423c-aeed-7febf9ed1a13",
  DELIVERED_TIME: "a856eb90-1bf4-4843-8461-933779685f69",
  DISPATCHED: "92e93d45-6b40-4915-b64f-720f1b947222",
  ERROR_REASON: "872c3c3f-7d9a-4836-be7a-a6fa46f9cc85",
  ERROR_TIME: "38358323-69a3-4384-9f66-cb82fbbc4de0",
  FAILED_TIME: "17109948-e0b1-45aa-8368-2b5765d44542",
  FORMATTED_RECIPIENT: "1c37e39f-8348-4b2c-bb34-3baf80878ee0",
  FORMATTED_SENDER: "6a135464-7ada-4dc3-9310-981233bebae7",
  OPENED_TIME: "e9d97c52-0276-4acb-9ba5-63e47c64b221",
  QUEUED_TIME: "a224ccc2-98c5-41f2-a15d-a7bed1e1330b",
  REASON: "9292c8a3-146b-4de6-ae5e-f3de7a8680d6",
  REJECTED_TIME: "bd0424ad-6362-4980-b42c-3a71a426bdec",
  REJECT_REASON: "509fc537-1742-4428-80ce-cd03d78d8b1a",
  SENT_TIME: "0f2cd124-6d70-4b50-9073-edb0614866e4",
  STATUS: "8128fc90-ff68-46b0-bb70-c6a297d2c7f2",
  SUBJECT: "46087c74-0d49-4491-be97-1d2dbcd4dc6a",
  UNSUBSCRIBED_TIME: "5e206518-2f4f-4327-bd88-08a95215aec9",
};
