import React, { memo } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  addSaleyardScans,
  deleteUnassignedScans,
  keepUnassignedScans,
  uploadScansAndDraftingInformation,
  uploadUnallocatedScans,
} from "actions";

import {
  buildDuplicatePenScanEidsList,
  buildDuplicateTakeableEidList,
} from "components/ScanningScreen/ScanningColumn";
import StandardScanningColumn from "components/ScanningScreen/StandardScanningColumn";

import { userTypes } from "constants/users";

import { scanLotConfig } from "lib/scanLot";
import { mapUnassignedScanToSaleLotScan } from "lib/scans";

import {
  getActiveRole,
  getConsignments,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScans,
  getUnassignedScans,
  selectTakeFilesByConsignmentIdLookup,
} from "selectors";

function RealtimeScanningColumnComponent(props) {
  const {
    consignmentId,
    draftName,
    isSelected,
    onCancel,
    onOpenCreateBulkModal,
    onOpenCreateModal,
    onOpenClearModal,
    onOpenClearSavedModal,
    onOpenSelectModal,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    scanListType,
    storedDuplicateEids,
    setDuplicateEids,
    isScanDiscrepancyDialogVisible,
    showScanDiscrepancyDialog,
    hideScanDiscrepancyDialog,
  } = props;

  const { lotAction, selectLotIdByEidLookup } = scanLotConfig(
    penType,
    scanLotId,
  );

  const consignments = useSelector(getConsignments);
  const userRole = useSelector(getActiveRole);
  const dispatch = useDispatch();
  const unassignedScans = useSelector(getUnassignedScans);
  const saleLotScans = useSelector(getScans);
  const saleLots = useSelector(getSaleLots);
  const saleyardScanSaleLots = useSelector(getSaleyardScanSaleLots);
  const takeFiles = useSelector(selectTakeFilesByConsignmentIdLookup);
  const scanLotIdByEid = useSelector(selectLotIdByEidLookup);

  const disallowTaken = userRole.type === userTypes.SCALE_OPERATOR;

  const isForReceivalOrPenScanning = !!penArchetypeId || !!penId || !!scanLotId;

  const realtimeEidsList = Object.values(unassignedScans)
    .filter(scan => scan.draftName === draftName)
    .sort((a, b) => new Date(b.created) - new Date(a.created));

  const { duplicateEids, eids, keepableEids, hiddenEids } =
    isForReceivalOrPenScanning
      ? buildDuplicatePenScanEidsList(
          realtimeEidsList,
          saleLotScans,
          "EID",
          "ignoreDuplicate",
          penType,
          scanLotId,
          scanLotIdByEid,
        )
      : buildDuplicateTakeableEidList(
          realtimeEidsList,
          saleLotScans,
          saleLots,
          saleyardScanSaleLots,
          consignments,
          takeFiles,
          "EID",
          "ignoreDuplicate",
          disallowTaken,
          consignmentId,
        );

  React.useEffect(() => {
    if (storedDuplicateEids.length !== duplicateEids.length) {
      setDuplicateEids(duplicateEids);
    }
  }, [duplicateEids, setDuplicateEids, storedDuplicateEids.length]);

  function uploadScansAction(eids, saleLotId) {
    return uploadScansAndDraftingInformation(
      eids.map(eid => mapUnassignedScanToSaleLotScan(unassignedScans[eid])),
      saleLotId,
    );
  }

  function uploadUnallocatedScansAction(eids, sale) {
    return uploadUnallocatedScans(
      eids.map(eid => mapUnassignedScanToSaleLotScan(unassignedScans[eid])),
      sale,
    );
  }

  function saleyardScanAction(eids) {
    return addSaleyardScans(
      eids.map(eid => mapUnassignedScanToSaleLotScan(unassignedScans[eid])),
      consignmentId,
    );
  }

  const scans = eids.map(eid =>
    mapUnassignedScanToSaleLotScan(unassignedScans[eid]),
  );

  const duplicateScans = duplicateEids.map(eid =>
    mapUnassignedScanToSaleLotScan(unassignedScans[eid]),
  );

  function uploadLotScansAction(
    eids,
    scanLotId,
    penId,
    penArchetypeId,
    lotValues,
  ) {
    return lotAction.updateOrCreateWithPenAndScans(
      scanLotId,
      penId,
      penArchetypeId,
      lotValues,
      eids.map(eid => mapUnassignedScanToSaleLotScan(unassignedScans[eid])),
    );
  }

  function onAfterEidsActions(actionedEids) {
    if (Array.isArray(actionedEids)) {
      // clear all of the eids which have been actioned
      dispatch(deleteUnassignedScans(actionedEids));
    } else {
      // clear all of the eids (which are not duplicate, or un-keepable)
      dispatch(deleteUnassignedScans(eids));
    }

    if (userRole.type === userTypes.SCALE_OPERATOR) {
      if (!duplicateEids.length) {
        // Scale operators _should_ only have one draft
        // There's a bug here of a scale operator is using multiway drafting:
        // The scanning screen will close with eids unassigned, in other drafts (columns)
        onCancel();
      }
    }
  }

  return (
    <StandardScanningColumn
      consignmentId={consignmentId}
      draftName={draftName}
      duplicateEids={duplicateEids}
      eids={eids}
      getConsignmentUploadAction={saleyardScanAction}
      getDeleteAction={deleteUnassignedScans}
      getKeepAction={keepUnassignedScans}
      getUploadAction={uploadScansAction}
      getUploadUnallocatedAction={uploadUnallocatedScansAction}
      getLotUploadAction={uploadLotScansAction}
      isSelected={isSelected}
      keepableEids={keepableEids}
      onAfterUploadAction={onAfterEidsActions}
      onAfterDeleteAction={onAfterEidsActions}
      onCancel={onCancel}
      onOpenCreateBulkModal={onOpenCreateBulkModal}
      onOpenCreateModal={onOpenCreateModal}
      onOpenClearModal={onOpenClearModal}
      onOpenClearSavedModal={onOpenClearSavedModal}
      onOpenSelectModal={onOpenSelectModal}
      saleLotId={saleLotId}
      penArchetypeId={penArchetypeId}
      penId={penId}
      scanLotId={scanLotId}
      penType={penType}
      scanListType={scanListType}
      showScanDiscrepancyDialog={showScanDiscrepancyDialog}
      isScanDiscrepancyDialogVisible={isScanDiscrepancyDialogVisible}
      hideScanDiscrepancyDialog={hideScanDiscrepancyDialog}
      scans={scans}
      duplicateScans={duplicateScans}
      hiddenEids={hiddenEids}
    />
  );
}

export default memo(RealtimeScanningColumnComponent);
