import React from "react";

import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { ScanAction } from "actions";

import { useHandleAfterSaveScanPen } from "components/ScanningScreen/handleNextScanPenHook";

import {
  getLivestockSaleId,
  getReturnTo,
  getSaleyardName,
  openAnimalModal,
} from "lib/navigation";
import { scanLotConfig } from "lib/scanLot";

import { getIsScaleOperator } from "selectors";

import { ScanDiscrepancyDialog } from "./ScanDiscrepancyDialog";
import ScanningColumn from "./ScanningColumn";

function StandardScanningColumnComponent(props) {
  const {
    consignmentId,
    draftName,
    duplicateEids,
    eids,
    getKeepAction,
    getUploadAction,
    getUploadUnallocatedAction,
    getConsignmentUploadAction,
    getLotUploadAction,
    getDeleteAction,
    isSelected,
    keepableEids,
    onAfterDeleteAction,
    onAfterUploadAction,
    onOpenCreateBulkModal,
    onOpenCreateModal,
    onOpenClearModal,
    onOpenClearSavedModal,
    onOpenSelectModal,
    saleLotId,
    onCancel,
    penArchetypeId,
    penId,
    scanLotId,
    scanListType,
    penType,
    isScanDiscrepancyDialogVisible,
    showScanDiscrepancyDialog,
    hideScanDiscrepancyDialog,
    scans,
    duplicateScans,
    hiddenEids,
  } = props;

  const {
    getNextPenByPenIdAndArchetypeId,
    getPreviousPenByPenIdAndArchetypeId,
    getEidsByLotId,
    scanReference,
  } = scanLotConfig(penType);

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    values: scanLotValues,
    resetForm,
    initialValues,
  } = useFormikContext();

  const isScaleOperator = useSelector(getIsScaleOperator);
  const showEdit = isScaleOperator;
  const disallowTaken = isScaleOperator;

  const scanLotEids = useSelector(getEidsByLotId(scanLotId)).filter(Boolean);

  const handleAfterSaveScanPen = useHandleAfterSaveScanPen(
    penArchetypeId,
    penId,
    scanLotId,
    penType,
  );

  const clearHidden = () => {
    // remove hidden eids that are already present on this scan lot
    dispatch(getDeleteAction([...hiddenEids]));
  };

  const hasNextPen = useSelector(state =>
    Boolean(getNextPenByPenIdAndArchetypeId(penId, penArchetypeId)(state)),
  );

  const hasPrevPen = useSelector(state =>
    Boolean(getPreviousPenByPenIdAndArchetypeId(penId, penArchetypeId)(state)),
  );

  function onAddToSale() {
    if (isScaleOperator) {
      const livestockSaleId = getLivestockSaleId();
      const saleyardName = getSaleyardName();
      const sale = {
        livestocksale_id: livestockSaleId,
        saleyard_name: saleyardName,
      };
      dispatch(getUploadUnallocatedAction(eids, sale));
      typeof onAfterUploadAction === "function" && onAfterUploadAction();
    } else {
      // eslint-disable-next-line no-console
      console.warn("Non Scale Operator attempting to add scans to sale");
    }
  }

  // Handle add all eids to current Sale Lot
  function onAddToCurrent() {
    dispatch(getUploadAction(eids, saleLotId));
    typeof onAfterUploadAction === "function" && onAfterUploadAction();
  }

  function onAddToConsignment() {
    dispatch(getConsignmentUploadAction(eids));
    typeof onAfterUploadAction === "function" && onAfterUploadAction();
  }

  function onAddToScanLot(options = {}) {
    dispatch(
      getLotUploadAction(eids, scanLotId, penId, penArchetypeId, scanLotValues),
    );
    clearHidden();
    handleAfterSaveScanPen(options);

    typeof onAfterUploadAction === "function" && onAfterUploadAction();

    resetForm({ values: initialValues });
  }

  function onEdit(eid) {
    if (isScaleOperator) {
      openAnimalModal(match.url, eid, getReturnTo());
    } else {
      // eslint-disable-next-line no-console
      console.warn("Non Scale Operator attempting to edit an eid");
    }
  }

  function onIgnore(eid) {
    dispatch(getDeleteAction([eid]));
  }

  function onIgnoreAll() {
    dispatch(getDeleteAction(duplicateEids));
  }

  function onKeep(eid) {
    dispatch(getKeepAction([eid]));
  }

  function onKeepAll() {
    dispatch(getKeepAction(keepableEids));
  }

  function onClear(doNotShowMessage) {
    if (doNotShowMessage) {
      dispatch(getDeleteAction([...duplicateEids, ...eids, ...hiddenEids]));
      typeof onAfterDeleteAction === "function" && onAfterDeleteAction();
    } else {
      typeof onOpenClearModal === "function" &&
        onOpenClearModal({
          draftName,
          onDelete: () => {
            dispatch(
              getDeleteAction([...duplicateEids, ...eids, ...hiddenEids]),
            );
            typeof onAfterDeleteAction === "function" && onAfterDeleteAction();
          },
        });
    }
  }

  const onClearDuplicates = () => {
    dispatch(getDeleteAction([...duplicateEids, ...hiddenEids]));
  };

  function onClearSaved() {
    clearHidden();
    onOpenClearSavedModal({
      penType,
      onDelete: () => {
        dispatch(
          ScanAction.create(
            scanLotEids.map(eid => ({
              EID: eid,
              [scanReference]: null,
              livestock_sale_id: getLivestockSaleId(),
            })),
          ),
        );
      },
    });
  }

  function onCreateNewBulk() {
    typeof onOpenCreateBulkModal === "function" &&
      onOpenCreateBulkModal({
        eids,
        onAfterSave: actionedEids => {
          typeof onAfterUploadAction === "function" &&
            onAfterUploadAction(actionedEids);
        },
      });
  }

  function onCreateNew() {
    typeof onOpenCreateModal === "function" &&
      onOpenCreateModal({
        onNewSaleLotId: saleLotId => {
          if (saleLotId) {
            dispatch(getUploadAction(eids, saleLotId));
            typeof onAfterUploadAction === "function" && onAfterUploadAction();
          }
        },
      });
  }

  function onSelectExisting() {
    typeof onOpenSelectModal === "function" &&
      onOpenSelectModal({
        onNewSaleLotId: saleLotId => {
          if (saleLotId) {
            dispatch(getUploadAction(eids, saleLotId));
            typeof onAfterUploadAction === "function" && onAfterUploadAction();
          }
        },
      });
  }

  return (
    <>
      <ScanningColumn
        consignmentId={consignmentId}
        disallowTaken={disallowTaken}
        draftName={draftName}
        duplicateEidsOrNlisIds={duplicateEids}
        eidsOrNlisIds={eids}
        isExpanded={isSelected}
        isFooterDisabled={eids.length === 0}
        isSaleLotSelected={Boolean(saleLotId)}
        onAddToConsignment={onAddToConsignment}
        onAddToCurrent={onAddToCurrent}
        onAddToSale={onAddToSale}
        onAddToScanLot={onAddToScanLot}
        onCancel={onCancel}
        onClear={onClear}
        onClearSaved={onClearSaved}
        onCreateNewBulk={onCreateNewBulk}
        onCreateNew={onCreateNew}
        onEdit={onEdit}
        onIgnore={onIgnore}
        onIgnoreAll={onIgnoreAll}
        onKeep={onKeep}
        onKeepAll={onKeepAll}
        onSelectExisting={onSelectExisting}
        showEdit={showEdit}
        showKeep
        showKeepAll={keepableEids.length > 0}
        showIgnore
        scanListType={scanListType}
        hasNextPen={hasNextPen}
        hasPrevPen={hasPrevPen}
        penType={penType}
        scanLotId={scanLotId}
        penId={penId}
        penArchetypeId={penArchetypeId}
        showScanDiscrepancyDialog={showScanDiscrepancyDialog}
      />
      <ScanDiscrepancyDialog
        isOpen={isScanDiscrepancyDialogVisible}
        onClose={hideScanDiscrepancyDialog}
        eids={eids}
        scans={scans}
        duplicateScans={duplicateScans}
        penType={penType}
        scanLotId={scanLotId}
        penId={penId}
        penArchetypeId={penArchetypeId}
        scanLotValues={scanLotValues}
        onClear={onClear}
        onClearDuplicates={onClearDuplicates}
        onAddToScanLot={onAddToScanLot}
      />
    </>
  );
}
export default StandardScanningColumnComponent;
