import React, { useEffect } from "react";

import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { BillingRunAction } from "actions";

import AgGrid from "components/AgGrid/AgGrid";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { LivestockSalesColumnDef } from "constants/columnDefinitions";
import {
  BillingRunColId,
  BillingRunColumnDefs,
} from "constants/columnDefinitions/billingRun";
import { ApiModel } from "constants/loading";

import { formatISO8601DateString } from "lib/timeFormats";

import { selectBillingRunTableData } from "selectors";

const getRowId = params => params.data.billingRun.id;
export const columnDefs = [
  {
    headerName: "BillingRun",
    children: [
      BillingRunColumnDefs[BillingRunColId.STATUS],
      BillingRunColumnDefs[BillingRunColId.NAME],
      BillingRunColumnDefs[BillingRunColId.ACTIONS],
    ],
  },
  {
    headerName: "Sale(s)",
    children: Object.values(LivestockSalesColumnDef),
  },
];

export const BillingRunSummary = () => {
  const formikContext = useFormikContext();
  const { values } = formikContext;
  const dispatch = useDispatch();
  useEffect(() => {
    const query = {};
    if (values.livestockSaleDateGte) {
      query.livestockSaleDateGte = formatISO8601DateString(
        new Date(values.livestockSaleDateGte),
      );
    }
    if (values.livestockSaleDateLte) {
      query.livestockSaleDateLte = formatISO8601DateString(
        new Date(values.livestockSaleDateLte),
      );
    }
    query.livestockSalesIdIn = values.livestockSaleIdIn;
    query.livestockSaleSaleTypeNameIn = values.livestockSaleSaleTypeNameIn;
    query.livestockSaleSaleSubTypeIdIn = values.livestockSaleSaleSubTypeIdIn;

    if (
      (query.livestockSaleDateLte && query.livestockSaleDateGte) ||
      query.livestockSalesIdIn
    ) {
      dispatch(BillingRunAction.request(query));
    }
  }, [dispatch, values]);
  const rowData = useSelector(selectBillingRunTableData);

  if (rowData.length === 0) {
    return null;
  }

  return (
    <WaitForSync requiredData={[ApiModel.BILLING_RUNS]}>
      <h3>
        The following Billing Runs have been found using the criteria above.
        Billing Runs in Draft status will not be included in reports.
      </h3>
      <CollapseTableWrapper>
        <AgGrid columnDefs={columnDefs} getRowId={getRowId} rowData={rowData} />
      </CollapseTableWrapper>
    </WaitForSync>
  );
};
