import React from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Tooltip } from "components/Form/FormikControls/Tooltip";

import { XeroIntegrationToolTip } from "lib/agGrid/tooltips";
import {
  openEditBusinessModal,
  openEditIntegrationBusinessModal,
} from "lib/navigation";

import XeroLogo from "img/Xero";

import { ClickableColumn, IconClickableColumn } from "./columnStyles";

export function BusinessRenderer({
  value,
  data,
  businessKey,
  complainIfMissing = false,
  node,
}) {
  const businessName = value;
  let businessId = null;

  if (node.group) {
    businessId = get(node.allLeafChildren[0].data, businessKey);
    // Grouping by another column, just return the value, no need to make it clickable - its probably blank.
    if (!businessId) {
      return value;
    }
  }
  if (data) {
    businessId = get(data, businessKey);
  }

  const onClickEdit = businessId
    ? () => {
        openEditBusinessModal(businessId);
      }
    : null;

  if (typeof businessName === "string" && businessName !== "" && businessId) {
    return (
      <ClickableColumn onClick={onClickEdit}>
        {businessName}
        <FontAwesomeIcon icon={faPencil} />
      </ClickableColumn>
    );
  } else if (businessId && complainIfMissing) {
    return (
      <FaIcon
        icon={faExclamationCircle}
        color="warning"
        onClick={onClickEdit}
        style={onClickEdit ? { cursor: "pointer" } : {}}
      />
    );
  } else {
    return null;
  }
}

export function SyRefBusinessRenderer({ value, data, businessKey }) {
  if (!data) {
    return "";
  }
  const businessId = get(data, businessKey);
  const onClickEdit = businessId
    ? () => {
        openEditBusinessModal(businessId);
      }
    : null;

  if (!value) {
    return (
      <FaIcon
        icon={faExclamationCircle}
        color="warning"
        onClick={onClickEdit}
        style={onClickEdit ? { cursor: "pointer" } : {}}
      />
    );
  }
  return (
    <ClickableColumn onClick={onClickEdit}>
      {value}
      <FontAwesomeIcon icon={faPencil} />
    </ClickableColumn>
  );
}

export function IntegrationBusinessRenderer({
  businessKeys,
  colDef,
  complainIfMissing = true,
  data,
  integrationType,
  useLogoAndTooltip,
  // TODO actually get value out of formattedValue
  // value,
}) {
  if (!data) {
    return null;
  }

  const deploymentBusinessIntegrationBusiness = get(data, colDef.field) || {};

  let business = null;
  if (businessKeys) {
    business =
      businessKeys
        .map(businessKey =>
          businessKey === null ? data : get(data, businessKey),
        )
        .filter(business => business && !isEmpty(business))[0] || {};
  } else {
    business = data;
  }

  const hasBusiness = Boolean(business.id);

  const onClickEdit = hasBusiness
    ? () => {
        openEditIntegrationBusinessModal(integrationType, business.id);
      }
    : null;

  const { name } = deploymentBusinessIntegrationBusiness;

  const tooltipComponent = (
    <XeroIntegrationToolTip
      deploymentBusinessIntegrationBusiness={
        deploymentBusinessIntegrationBusiness
      }
    />
  );

  if (typeof name === "string" && name !== "") {
    if (useLogoAndTooltip) {
      return (
        <Tooltip title={tooltipComponent}>
          <IconClickableColumn onClick={onClickEdit}>
            <XeroLogo />
          </IconClickableColumn>
        </Tooltip>
      );
    } else {
      return (
        <ClickableColumn onClick={onClickEdit}>
          {name}
          <FontAwesomeIcon icon={faPencil} />
        </ClickableColumn>
      );
    }
  } else if (hasBusiness && complainIfMissing) {
    return (
      <IconClickableColumn onClick={onClickEdit}>
        <FaIcon icon={faExclamationCircle} color="warning" />
      </IconClickableColumn>
    );
  } else {
    return null;
  }
}
