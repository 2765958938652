import flatten from "lodash/flatten";
import { all, put, takeEvery } from "redux-saga/effects";

import { BillingDocumentAction, LedgerEntryAction } from "actions";

import { LedgerAccountAction } from "actions/ledgerAccounts";

import {
  BILLING_DOCUMENT,
  LEDGER_ACCOUNT,
  LEDGER_ENTRY,
} from "constants/actionTypes";

// Array of [ACTION_TYPE, ACTION_FUNCTION] for actions that use the offlineActionCreator and that we want to re-fetch
// the failed object on update/delete failure.
const actionMap = [
  [LEDGER_ACCOUNT, LedgerAccountAction],
  [LEDGER_ENTRY, LedgerEntryAction],
  [BILLING_DOCUMENT, BillingDocumentAction],
];

function rollbackFactory(actionType, actionFunction) {
  function* rollback(action) {
    const { id } = action;
    if (id) {
      yield put(actionFunction.requestOne({ id }));
    }
  }

  return rollback;
}

export default function* rollbackFailureSaga() {
  yield all(
    flatten(
      actionMap.map(([actionType, actionFunction]) => [
        takeEvery(
          actionType.UPDATE.FAILURE,
          rollbackFactory(actionType, actionFunction),
        ),
        takeEvery(actionType.DELETE.FAILURE, rollbackFactory(actionFunction)),
      ]),
    ),
  );
}
