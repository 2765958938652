import React, { memo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { XsHeading } from "components/Text";

import { getCurrentDeploymentSaleIds } from "selectors";

import DeploymentSaleSummary from "./DeploymentSaleSummary";
import DeploymentSaleSummaryMobile from "./DeploymentSaleSummaryMobile";

const HeadingTable = styled.table(
  ({ theme }) => `
  border-spacing: 0;
  th.agency,
  th.balanced {
    width: 250px;
  }
  th.round {
    width: 124px;
  }
  th.agency,
  th.round,
  th.balanced { 
    visibility: hidden;
  }
  th {
    width: 100px;
    text-align: left;
    padding: ${theme.space[1]}px;
  }`,
);

const DesktopSummaryScroller = styled.div`
  width: 1330px;
  x-overflow: scroll;
`;

const AgentSales = ({ isMobile = true }) => {
  const deploymentSaleIds = useSelector(getCurrentDeploymentSaleIds);
  if (isMobile) {
    return deploymentSaleIds.map(deploymentSaleId => (
      <DeploymentSaleSummaryMobile
        key={deploymentSaleId}
        deploymentSaleId={deploymentSaleId}
      />
    ));
  }

  return (
    <DesktopSummaryScroller>
      <HeadingTable>
        <thead>
          <tr>
            <th className="agency">
              <XsHeading bold>Agency</XsHeading>
            </th>
            <th>
              <XsHeading bold>Received</XsHeading>
            </th>
            <th>
              <XsHeading bold>Not Drafted</XsHeading>
            </th>
            <th className="round">
              <XsHeading bold>Round</XsHeading>
            </th>
            <th>
              <XsHeading bold>Not Penned</XsHeading>
            </th>
            <th>
              <XsHeading bold>Penned</XsHeading>
            </th>
            <th>
              <XsHeading bold>No Sale</XsHeading>
            </th>
            <th>
              <XsHeading bold>Sold</XsHeading>
            </th>
            <th>
              <XsHeading bold>Delivered</XsHeading>
            </th>
            <th className="balanced">
              <XsHeading bold>Balanced</XsHeading>
            </th>
          </tr>
        </thead>
      </HeadingTable>
      {deploymentSaleIds.map(deploymentSaleId => (
        <DeploymentSaleSummary
          key={deploymentSaleId}
          deploymentSaleId={deploymentSaleId}
        />
      ))}
    </DesktopSummaryScroller>
  );
};

AgentSales.propTypes = {
  isMobile: PropTypes.bool,
};

export default memo(AgentSales);
