import { sortedUniqBy } from "lodash";
import { createSelector } from "reselect";

import { EMPTY_OBJECT } from "lib";

import { getCurrentSale, getSaleSubTypes, getSpecies } from "selectors";

export const getSaleSubTypeById = saleSubTypeId => state =>
  getSaleSubTypes(state)[saleSubTypeId] || null;

export const getCurrentSaleSubType = createSelector(
  [getCurrentSale, getSaleSubTypes],
  (currentSale, saleSubTypes) =>
    saleSubTypes[currentSale.sale_sub_type_id] || EMPTY_OBJECT,
);

export const selectAreStudFeaturesEnabled = createSelector(
  [getCurrentSaleSubType],
  currentSaleSubType => currentSaleSubType.enableStudFeatures || false,
);

export const selectIsSaleSubTypeDentitionEnabled = createSelector(
  [getCurrentSaleSubType],
  currentSaleSubType => currentSaleSubType.enableDentition || false,
);

export const selectAreWeightAndDressingFeaturesEnabled = createSelector(
  [getCurrentSaleSubType],
  currentSaleSubType =>
    currentSaleSubType.enableWeightRangeAndDressing || false,
);

export const getSaleSubTypeWithSpeciesOptions = createSelector(
  [getSaleSubTypes, getSpecies],
  (saleSubTypesById, speciesById) => {
    return sortedUniqBy(
      Object.values(saleSubTypesById).map(saleSubType => {
        const species = speciesById[saleSubType.speciesId];
        return {
          label: `${saleSubType.name} - ${species.name} - ${saleSubType.saleType}`,
          value: saleSubType.id,
        };
      }),
      "label",
    );
  },
);
