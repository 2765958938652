import {
  createLookupCombiner,
  createLookupSelectors,
  getPenArchetypes,
  selectPenArchetypeIdsByPenTypeLookup,
} from "selectors";

import { selectPenIdsByPenArchetypeIdLookup } from "./indexes";

export const getPenArchetypeById = penArchetypeId => state =>
  getPenArchetypes(state)[penArchetypeId] || null;

function reducePenArchetypesByPenType(penArchetypeIds, penArchetypeByIdLookup) {
  return penArchetypeIds.map(
    penArchetypeId => penArchetypeByIdLookup[penArchetypeId],
  );
}

export const [selectPenArchetypesByPenType, getPenArchetypesByPenType] =
  createLookupSelectors(
    [selectPenArchetypeIdsByPenTypeLookup, getPenArchetypes],
    createLookupCombiner(reducePenArchetypesByPenType),
  );

export const getPenIdsByPenArchetypeId = penArchetypeId => state =>
  selectPenIdsByPenArchetypeIdLookup(state)[penArchetypeId];
