import { combineReducers } from "redux";

import {
  EXTERNAL_AGENT_XML,
  PRE_SALE_CSV,
  SET_ACTIVE_ROLE,
  SET_CURRENT_LIVESTOCKSALE,
} from "constants/actionTypes";
import { IMPORTER_TYPES } from "constants/importer";

const defaultExternalAgentXmlState = {
  file: null,
  isImporting: false,
  isDataLossAcknowledged: false,
  selectedTab: 0,
};

// When adding a new importer, just add the name and type to this array
const importerTypes = [
  { name: IMPORTER_TYPES.PRE_SALE_CSV, type: PRE_SALE_CSV },
  { name: IMPORTER_TYPES.EXTERNAL_AGENT_XML, type: EXTERNAL_AGENT_XML },
];

const createImporterReducers = () => {
  const obj = {};
  importerTypes.forEach(typeObject => {
    const { name, type } = typeObject;
    obj[name] = (state = defaultExternalAgentXmlState, action) => {
      switch (action.type) {
        case type.UPDATE.ACTION:
          return {
            ...state,
            ...action.payload,
          };
        case type.PROCESS.FAILURE:
          return {
            ...defaultExternalAgentXmlState,
            isDataLossAcknowledged: state.isDataLossAcknowledged,
            selectedTab: state.selectedTab,
          };
        case type.IMPORT.ACTION:
          return {
            ...state,
            isImportRunning: true,
          };
        case type.IMPORT.FAILURE:
          return {
            ...state,
            isImportRunning: false,
          };
        case SET_ACTIVE_ROLE.REQUEST:
        case SET_CURRENT_LIVESTOCKSALE.SUCCESS:
        case type.IMPORT.SUCCESS:
          return {
            ...defaultExternalAgentXmlState,
          };
        default:
          return state;
      }
    };
  });
  return obj;
};

export default combineReducers(createImporterReducers());
