import React from "react";

import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { ExclamationIcon } from "components/Icons";

import { ConsignmentModalSection } from "constants/navigation";
import { colors } from "constants/theme";

import { formatAddress } from "lib/address";
import { getGSTStatusText } from "lib/businesses";
import { openEditConsignmentModal } from "lib/navigation";

import {
  getBusinessById,
  getConsignmentById,
  getCurrentSale,
  getIsConsigningAgent,
  getNameByDeploymentId,
} from "selectors";

export const DeploymentBusinessName = React.memo(({ businessId }) => {
  const businessName =
    useSelector(state => getBusinessById(businessId)(state)?.name) || "";

  return <b data-tour="cardBusinessName">{businessName}</b>;
});

export const BusinessGSTStatus = React.memo(({ businessId }) => {
  const business = useSelector(getBusinessById(businessId)) || {};

  const status = getGSTStatusText(
    business.isGSTRegistered,
    business.abrLastChecked,
    business.shouldChargeGSTOverride,
  );

  return <div>{`GST Status: ${status}`}</div>;
});

export const DeploymentBusinessAddress = React.memo(({ businessId }) => {
  const formattedAddress = useSelector(state => {
    const address = getBusinessById(businessId)(state)?.address;
    return address ? formatAddress(address) : "Unknown";
  });

  return <div>{formattedAddress}</div>;
});

export const InvoiceNumber = React.memo(({ businessId, consignment }) => {
  const business = useSelector(getBusinessById(businessId));
  return consignment && business ? (
    <div>{`Invoice: ${consignment.livestocksale_id}-${business.pk}`}</div>
  ) : null;
});

export const DeploymentBusinessLocality = React.memo(({ businessId }) => {
  const locality =
    useSelector(getBusinessById(businessId))?.address?.locality || "";

  return <div>{locality}</div>;
});

export const ConsignmentLocality = React.memo(({ consignmentId }) => {
  const consignment = useSelector(getConsignmentById(consignmentId)) || {};
  const sale = useSelector(getCurrentSale);
  const deploymentId = sale?.deployment_sales[0]?.deployment_id || null;
  const deploymentName = useSelector(getNameByDeploymentId(deploymentId));
  const isConsigning = useSelector(getIsConsigningAgent);
  const editConsignment = () =>
    openEditConsignmentModal(
      consignmentId,
      ConsignmentModalSection.PICKUP_DETAILS,
      true,
    );

  const vendor = useSelector(getBusinessById(consignment.vendor_id)) || {};

  const matchingAddress = isEqual(consignment.pickupAddress, vendor.address);
  if (
    isConsigning &&
    consignment.useVendorAddressAsPickupAddress &&
    deploymentName
  ) {
    return (
      <Tooltip title={`Address manged by ${deploymentName}`}>
        <IconButton onClick={editConsignment} tooltip="yes">
          <FaIcon icon={faQuestionCircle} color={colors.primary} />{" "}
        </IconButton>
      </Tooltip>
    );
  } else if (
    isConsigning &&
    consignment.useVendorAddressAsPickupAddress &&
    !matchingAddress
  ) {
    return (
      <Tooltip title={`Address differs from ${deploymentName}'s`}>
        <IconButton onClick={editConsignment} tooltip="yes">
          <ExclamationIcon color="warning" />{" "}
        </IconButton>
      </Tooltip>
    );
  }
  if (!consignment.useVendorAddressAsPickupAddress) {
    if (consignment.pickupAddress && consignment.pickupAddress.locality) {
      return <div>{consignment.pickupAddress.locality}</div>;
    } else {
      return <div />;
    }
  }
  return <DeploymentBusinessLocality businessId={consignment.vendor_id} />;
});
