import React from "react";

import {
  faCheck,
  faExclamationCircle,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEqual from "lodash/isEqual";
import styled from "styled-components/macro";

import { FieldName } from "components/AuditLog/Fields";

import { AuditLogTypes } from "constants/auditLog";
import { AlternativeComparableKeys } from "constants/businesses";

import { formatAddress } from "lib/address";

const FullWidthTable = styled.table`
  width: 100%;
  .third {
    width: 33.3%;
  }
  .icon,
  .bold {
    width: 20px;
  }
  .leftAlign {
    text-align: left;
  }
`;

const HasChangesIcon = styled(FontAwesomeIcon)(
  ({ theme }) => `
  color: ${theme.colors.warningOrange};
  font-size: ${theme.fontSizes.gamma}px;
`,
);

const ALTERNATIVE_FORMATTERS = {
  address: formatAddress,
};

const getRenderableAlternativeValue = (key, value) => {
  if (value === true) {
    return <FontAwesomeIcon icon={faCheck} />;
  } else if (value === false) {
    return <FontAwesomeIcon icon={faTimes} />;
  } else if (!value) {
    return "-";
  } else if (key in ALTERNATIVE_FORMATTERS) {
    return ALTERNATIVE_FORMATTERS[key](value);
  } else {
    return value;
  }
};

export const AlternativesTable = ({ business, alternatives }) => {
  return (
    <FullWidthTable>
      <tbody>
        <tr>
          <td className="third">Field</td>
          <td className="third">Currently</td>
          <td className="icon" />
          <td>Update To</td>
        </tr>
        {Object.entries(alternatives).map(
          ([alternativeKey, alternative], idx) => {
            if (AlternativeComparableKeys.includes(alternativeKey)) {
              const hasChanges = !isEqual(
                business[alternativeKey],
                alternative,
              );
              return (
                <tr key={idx}>
                  <td className="bold">
                    <FieldName
                      fieldName={alternativeKey}
                      dataType={AuditLogTypes.ALTERNATIVES}
                    />
                  </td>
                  <td>
                    {getRenderableAlternativeValue(
                      alternativeKey,
                      business[alternativeKey],
                    )}
                  </td>
                  <td className="icon">
                    {hasChanges && (
                      <HasChangesIcon icon={faExclamationCircle} />
                    )}
                  </td>
                  <td>
                    {getRenderableAlternativeValue(alternativeKey, alternative)}
                  </td>
                </tr>
              );
            }
            return null;
          },
        )}
      </tbody>
    </FullWidthTable>
  );
};

export const NestedAlternativesTable = ({
  business,
  alternatives,
  alternativesField,
  title,
  propertiesLookup,
}) => {
  return (
    <>
      {business[alternativesField].map((property, idx) => {
        const propertyAlternatives = property.alternatives?.find(
          alternative => alternative.source.id === alternatives.source.id,
        );

        return (
          <FullWidthTable key={idx}>
            {propertyAlternatives ? (
              <>
                <thead className="leftAlign">
                  <tr>
                    <th>
                      {idx === 0 && <h3>{title}</h3>}
                      <h3>
                        {propertiesLookup &&
                          `${propertiesLookup[property.id].PIC} ${
                            property.name ? property.name : ""
                          }`}
                      </h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="third">Field</td>
                    <td className="third">Currently</td>
                    <td className="icon" />
                    <td>Update To</td>
                  </tr>
                  {Object.entries(propertyAlternatives).map(
                    ([altKey, altValue], idx) => {
                      if (AlternativeComparableKeys.includes(altKey)) {
                        const hasChanges = !isEqual(property[altKey], altValue);
                        return (
                          <tr key={idx}>
                            <td className="bold">
                              <FieldName
                                fieldName={altKey}
                                dataType={AuditLogTypes.ALTERNATIVES}
                              />
                            </td>
                            <td>
                              {getRenderableAlternativeValue(altKey, altValue)}
                            </td>
                            <td className="icon">
                              {hasChanges && (
                                <HasChangesIcon icon={faExclamationCircle} />
                              )}
                            </td>
                            <td>
                              {getRenderableAlternativeValue(
                                altKey,
                                property[altKey],
                              )}
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    },
                  )}
                </tbody>
              </>
            ) : null}
          </FullWidthTable>
        );
      })}
    </>
  );
};
