import React from "react";

import EmailReportModal from "components/EmailReportModal";

import { ModalTypes } from "constants/navigation";

function EmailReportModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.EmailReportModal] || null;
  const { reportUrl, reportName, businessId, deploymentId } =
    JSON.parse(hashParams);
  const { returnTo } = hash;

  return (
    <EmailReportModal
      reportUrl={atob(reportUrl)}
      businessId={businessId}
      reportName={atob(reportName)}
      deploymentId={deploymentId}
      returnTo={returnTo ? atob(returnTo) : null}
    />
  );
}

export default EmailReportModalAdapter;
