import React, { memo } from "react";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useMediaQuery } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import FontAwesomeButton, {
  ButtonIcon,
} from "components/Button/FontAwesomeButton";
import { ClickableCommentIcon } from "components/Comments/Icon";
import { DataTour } from "components/DataTour";
import { Column, Row } from "components/Layout";
import { OptionToggler } from "components/OptionToggler";
import { ReactSelect } from "components/SearchableSelector";
import { MediumText } from "components/Text";

import { CommentTypes } from "constants/comments";
import { ScanningMode } from "constants/scanner";

import { scanLotConfig } from "lib/scanLot";

import {
  getImportedScans,
  getManualInputScans,
  getPenIdsByPenArchetypeId,
  getUnassignedScans,
  getUploadedScans,
} from "selectors";

import { useHasReceivalOrPenScanLotsPermission, useTheme } from "hooks";

const ScanningModeOptions = [
  { label: "Real-Time", value: ScanningMode.REALTIME },
  { label: "Session Import", value: ScanningMode.DEVICE_IMPORT },
  { label: "File Import", value: ScanningMode.FILE_IMPORT },
  { label: "Manual Input", value: ScanningMode.MANUAL_INPUT },
];

const SmallScreeScanningModeOptions = [
  { label: "Real-Time", value: ScanningMode.REALTIME },
  { label: "Import", value: ScanningMode.DEVICE_IMPORT },
  { label: "File", value: ScanningMode.FILE_IMPORT },
  { label: "Manual", value: ScanningMode.MANUAL_INPUT },
];

function ScanningHeaderComponent(props) {
  const {
    onBack,
    scanningMode,
    onScanningModeChanged,
    saleLotId,
    penId,
    penType,
    penArchetypeId,
  } = props;

  const unassignedScans = Object.values(useSelector(getUnassignedScans)).length;
  const importedScans = Object.values(useSelector(getImportedScans)).length;
  const uploadedScans = Object.values(useSelector(getUploadedScans)).length;
  const manualInputScans = Object.values(
    useSelector(getManualInputScans),
  ).length;

  let scanQuantity = 0;
  if (scanningMode === ScanningMode.REALTIME) {
    scanQuantity = unassignedScans;
  } else if (scanningMode === ScanningMode.DEVICE_IMPORT) {
    scanQuantity = importedScans;
  } else if (scanningMode === ScanningMode.FILE_IMPORT) {
    scanQuantity = uploadedScans;
  } else if (scanningMode === ScanningMode.MANUAL_INPUT) {
    scanQuantity = manualInputScans;
  }

  const { getLotIdsByPenId, selectEidsByScanLotIdLookup } =
    scanLotConfig(penType);

  const eidsByScanLotIdLookup = useSelector(selectEidsByScanLotIdLookup);

  const penIds = useSelector(getPenIdsByPenArchetypeId(penArchetypeId));

  const resolvedPenId =
    penId ||
    (penArchetypeId && Array.isArray(penIds) && penIds.length > 0 && penIds[0]);

  const scanLotIds = useSelector(getLotIdsByPenId(resolvedPenId));

  const penScannedQuantity = scanLotIds.reduce(
    (acc, scanLotId) =>
      (acc += eidsByScanLotIdLookup[scanLotId]?.filter(Boolean)?.length || 0),
    0,
  );

  const totalScans = penScannedQuantity + scanQuantity;

  const isReceivalOrPenScanning = useHasReceivalOrPenScanLotsPermission();

  function onScanningModeSelectChanged({ value }) {
    onScanningModeChanged(value);
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints[1]}px)`);
  const scanningModeOptions = isMobile
    ? SmallScreeScanningModeOptions
    : ScanningModeOptions;

  return (
    <Header>
      <Column data-tour="scanning-mode-options" maxWidth="75%" minWidth="50%">
        {isMobile ? (
          <DataTour dataTour="scanningSelect">
            <ReactSelect
              options={scanningModeOptions}
              value={scanningModeOptions.find(v => v.value === scanningMode)}
              onChange={onScanningModeSelectChanged}
            />
          </DataTour>
        ) : (
          <OptionToggler
            name="scanning-mode"
            options={scanningModeOptions}
            value={scanningMode}
            onChange={onScanningModeChanged}
          />
        )}
      </Column>
      {isReceivalOrPenScanning && <MediumText>{totalScans} Scanned</MediumText>}
      <Row>
        {saleLotId ? (
          <ClickableCommentIcon
            commentType={CommentTypes.SALE_LOT}
            commentTypeId={saleLotId}
            returnTo={window.location.hash}
          />
        ) : null}
        <FontAwesomeButton
          data-tour="close-scanning-screen"
          background="gray95"
          size={2}
          onClick={onBack}
        >
          <ButtonIcon icon={faTimes} color="black" />
        </FontAwesomeButton>
      </Row>
    </Header>
  );
}

ScanningHeaderComponent.propTypes = {
  saleLotId: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onScanningModeChanged: PropTypes.func.isRequired,
  scanningMode: PropTypes.number,
};

export default memo(ScanningHeaderComponent);

const Header = styled(Row)(
  ({ theme }) => `
  padding: ${theme.space[1]}px ${theme.space[2]}px;
  background-color: ${theme.colors.gray95};
  justify-content: space-between;
  align-items: center;
`,
);
