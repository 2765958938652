import React, { useEffect, useMemo, useState } from "react";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { deleteAttachment, setSetting } from "actions";

import { updateAttachments, uploadRequest } from "actions/files";

import { SubtleBadge } from "components/Badge";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { CollapseLabel, FormCollapse } from "components/Form";
import { FileInput, Input } from "components/Form/FormikControls";
import { CenteredGreedy, Row } from "components/Layout";
import { LightBox } from "components/LightBox";
import { ExternalFileLink } from "components/Link";
import LoadingSpinner from "components/LoadingSpinner";
import { OptionToggler } from "components/OptionToggler";
import { SortableItem } from "components/SortableItem";
import { BigText, MediumText } from "components/Text";

import { LotStatus } from "constants/clearingSaleAttributes";
import {
  ConsignmentAttachmentTypeOptions,
  DocumentTypes,
  NVDRelatedDocumentTypeValues,
  SaleLotAttachmentTypeOptions,
} from "constants/documentTypes";
import {
  ACCEPTED_FILE_TYPES,
  NVD_ALLOWED_TYPES,
  RESIZABLE_IMAGE_EXTENSIONS,
  VIDEO_FILE_EXTENSIONS,
} from "constants/files";
import { SaleLotNamespaces } from "constants/saleLots";
import { Settings } from "constants/settings";

import {
  EMPTY_ARRAY,
  extractFileExtensionFromURL,
  formatPercentage,
} from "lib";

import { filterBySaleType } from "lib/attachments";

import {
  getHasWriteAccessInCurrentSale,
  getIsConsigningAgent,
  getSaleLotById,
  getSettings,
  selectAttachmentsByConsignmentId,
  selectAttachmentsBySaleLotId,
} from "selectors";

const Gallery = styled.div`
  ${({ theme }) => `
  padding: 0 ${theme.space[2]}px;
  display: grid;
  flex: 1;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${theme.space[2]}px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${theme.space[1]}px;
  }
  
  transition: all 0.2s ease-in-out;
  > * {
    max-width: 100%;
    box-shadow:  ${theme.shadows[1]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
`}
`;

const ConstrainedImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const MediaWrapper = styled.div`
  min-height: 120px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(FontAwesomeIcon).attrs({ icon: faTimesCircle })`
  color: ${({ theme }) => theme.colors.deleteRed};
  font-size: 32px;
  position: absolute;
  top: -16px;
  right: -10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.3);
  }
`;

const DeletableWrapper = styled(Row)`
  position: relative;
`;

const Deletable = ({ children, handleDelete }) => {
  return (
    <DeletableWrapper justifyCenter alignCenter>
      <DeleteButton onClick={handleDelete} />
      {children}
    </DeletableWrapper>
  );
};

const Thumbnail = ({ file, setPreview }) => {
  const onClick = () => setPreview(file.id);

  const fileExtension = extractFileExtensionFromURL(file.image_url);
  if (RESIZABLE_IMAGE_EXTENSIONS.includes(fileExtension)) {
    return (
      <MediaWrapper onClick={onClick}>
        <ConstrainedImage
          key={file.id}
          alt=""
          src={file.thumbnail_url || file.image_url}
        />
      </MediaWrapper>
    );
  }

  return (
    <MediaWrapper>
      <ExternalFileLink imageUrl={file.image_url} />
    </MediaWrapper>
  );
};

const DeletableThumbnail = ({ file, setPreview, handleDelete, readOnly }) => {
  if (readOnly) {
    return <Thumbnail file={file} setPreview={setPreview} />;
  } else {
    return (
      <Deletable handleDelete={handleDelete}>
        <Thumbnail file={file} setPreview={setPreview} />
      </Deletable>
    );
  }
};

export const MediaCollapse = ({
  isOpen,
  onToggle,
  saleLotId,
  consignmentId,
  readOnly,
  saleType,
}) => {
  const attachments =
    useSelector(state =>
      saleLotId
        ? selectAttachmentsBySaleLotId(state)[saleLotId]
        : selectAttachmentsByConsignmentId(state)[consignmentId],
    ) || [];

  const youtube_link =
    useSelector(getSaleLotById(saleLotId))?.youtube_link || "";

  const documentAttachmentsCount = attachments.filter(attachment =>
    NVDRelatedDocumentTypeValues.includes(attachment.type),
  )?.length;

  const imageAttachmentsCount = attachments.filter(
    attachment => attachment.type === DocumentTypes.IMAGE,
  )?.length;

  const videoAttachmentsCount = attachments.filter(
    attachment => attachment.type === DocumentTypes.VIDEO,
  )?.length;

  const imageCountSummary = `${imageAttachmentsCount || 0} Image${
    imageAttachmentsCount === 1 ? "" : "s"
  }`;

  const videoCountSummary = `${videoAttachmentsCount} Video${
    videoAttachmentsCount === 1 ? "" : "s"
  }`;

  const documentCountSummary = `${documentAttachmentsCount} Document${
    documentAttachmentsCount === 1 ? "" : "s"
  }`;
  const enableYoutubeLink = !!saleLotId;
  const videoSummary = enableYoutubeLink
    ? `${youtube_link ? "" : "No "}External Video Link`
    : "";

  const mediaCollapseLabel = [
    imageCountSummary,
    videoCountSummary,
    documentCountSummary,
    videoSummary,
  ].join(", ");

  const header = (
    <Row>
      <SubtleBadge>Media</SubtleBadge>
      <Row alignCenter flexWrap>
        <CollapseLabel>{mediaCollapseLabel}</CollapseLabel>
      </Row>
    </Row>
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      id="collapse-media"
      dataTour={isOpen ? "hideMedia" : "showMedia"}
    >
      {isOpen && (
        <MediaCollapseBody
          saleLotId={saleLotId}
          consignmentId={consignmentId}
          readOnly={readOnly}
          saleType={saleType}
        />
      )}
    </FormCollapse>
  );
};

export const MediaCollapseBody = React.memo(
  ({ saleLotId, consignmentId, readOnly, saleType }) => {
    const [preview, setPreview] = React.useState(null);
    const [activeFile, setActiveFile] = useState(null);
    const dispatch = useDispatch();

    const attachmentTypeOptions = useMemo(() => {
      const options = saleLotId
        ? SaleLotAttachmentTypeOptions
        : ConsignmentAttachmentTypeOptions;

      return options.filter(filterBySaleType(saleType));
    }, [saleLotId, saleType]);

    const attachmentTypeSetting = saleLotId
      ? Settings.consignmentAttachmentTypeSelection
      : Settings.saleLotAttachmentTypeSelection;

    const attachmentType =
      useSelector(getSettings)[attachmentTypeSetting] || DocumentTypes.IMAGE;

    const resolveAttachmentType = file => {
      // if a file contains a video extension, it should register as a video
      // regardless of which tab it is uplaoded to
      if (
        VIDEO_FILE_EXTENSIONS.some(extension => file.name.includes(extension))
      ) {
        return DocumentTypes.VIDEO;
      }
      return attachmentType;
    };

    const setAttachmentType = value => {
      dispatch(setSetting(attachmentTypeSetting, value));
    };

    const attachments =
      useSelector(state =>
        saleLotId
          ? selectAttachmentsBySaleLotId(state)[saleLotId]
          : selectAttachmentsByConsignmentId(state)[consignmentId],
      ) || EMPTY_ARRAY;

    const filteredAttachments = useMemo(
      () =>
        attachments.filter(attachment => attachment.type === attachmentType),
      [attachmentType, attachments],
    );

    const [sortableAttachments, setSortableAttachments] =
      useState(filteredAttachments);

    useEffect(() => {
      setSortableAttachments(filteredAttachments);
    }, [filteredAttachments]);

    const hasWriteAccessInCurrentSale = useSelector(
      getHasWriteAccessInCurrentSale,
    );
    const isConsigningAgent = useSelector(getIsConsigningAgent);
    // If this is used on the consignment modal, the value here should be null.
    const saleLot = useSelector(getSaleLotById(saleLotId));

    // Allow addition of new files if we have write access, or it's a consigning agent editing a Pending salelot.
    const allowUpload =
      hasWriteAccessInCurrentSale ||
      (isConsigningAgent &&
        saleLot &&
        saleLot[SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES]?.status ===
          LotStatus.PENDING);

    const [deleteDialogFile, setDeleteFileDialog] = React.useState(null);
    const deleteFile = () => {
      dispatch(deleteAttachment(deleteDialogFile));
      setDeleteFileDialog(null);
    };

    const enableYoutubeLink = !!saleLotId;

    const sensors = useSensors(
      useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
      useSensor(TouchSensor, { activationConstraint: { distance: 0 } }),
    );

    function handleDragEnd(event) {
      const { active, over } = event;
      if (active.id !== over.id) {
        setSortableAttachments(sortableAttachments => {
          const oldIndex = sortableAttachments.findIndex(
            attachment => attachment.id === active.id,
          );
          const newIndex = sortableAttachments.findIndex(
            attachment => attachment.id === over.id,
          );
          const newItems = arrayMove(sortableAttachments, oldIndex, newIndex);
          dispatch(
            updateAttachments(
              newItems.map((attachment, index) => ({
                id: attachment.id,
                order: index,
              })),
            ),
          );
          setActiveFile(null);
          return newItems;
        });
      }
    }

    function handleDragCancel() {
      setActiveFile(null);
    }

    function handleDragStart(event) {
      const file = sortableAttachments.find(
        attachment => attachment.id === event.active.id,
      );
      setActiveFile(file);
    }

    const upload = file => {
      const formData = {
        sale_lot: saleLotId,
        consignment: consignmentId,
        type: resolveAttachmentType(file),
        order: attachments.length,
      };
      dispatch(uploadRequest(file, formData));
    };

    const closePreview = () => setPreview(null);

    let uploadBlock = null;
    if (
      attachmentType === DocumentTypes.NVD &&
      filteredAttachments.length > 0
    ) {
      uploadBlock = (
        <CenteredGreedy>
          <BigText>Only one NVD can be uploaded per consignment.</BigText>
          <MediumText>
            Secondary NVDs can be uploaded as &quot;Other&quot;.
          </MediumText>
        </CenteredGreedy>
      );
    } else if (allowUpload && !readOnly) {
      uploadBlock = (
        <FileInput
          upload={upload}
          rotate={NVDRelatedDocumentTypeValues.includes(attachmentType)}
          allowedFileTypes={
            attachmentType === DocumentTypes.NVD
              ? NVD_ALLOWED_TYPES
              : ACCEPTED_FILE_TYPES
          }
        />
      );
    }

    const getAttachementNameFromURL = url => {
      const strings = url.split("/");
      const attachmentName = strings[strings.length - 1];
      return attachmentName;
    };

    return (
      <>
        <>
          <Grid item xs={12}>
            {/* TODO: Handle attachments that dont have a type yet. */}
            <OptionToggler
              name="Attachment Type"
              options={attachmentTypeOptions}
              value={attachmentType}
              onChange={setAttachmentType}
              width="100%"
            />
          </Grid>
          <Grid item xs={12}>
            {uploadBlock}
          </Grid>
          <Grid item xs={12}>
            <Gallery>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                onDragCancel={handleDragCancel}
              >
                <SortableContext
                  items={sortableAttachments}
                  strategy={rectSortingStrategy}
                >
                  {sortableAttachments?.map((attachment, index) =>
                    attachment.isFinished === false ? (
                      <CenteredGreedy key={attachment.id}>
                        <LoadingSpinner
                          actionName="Uploading"
                          subjectName={formatPercentage(
                            attachment.progress || "",
                          )}
                        />
                      </CenteredGreedy>
                    ) : (
                      <SortableItem
                        key={attachment.id}
                        id={attachment.id}
                        data-tour={getAttachementNameFromURL(
                          attachment.image_url,
                        )}
                      >
                        <DeletableThumbnail
                          key={attachment.id}
                          handleDelete={() => {
                            setDeleteFileDialog(attachment);
                          }}
                          file={attachment}
                          index={index}
                          setPreview={setPreview}
                          readOnly={readOnly}
                        />
                      </SortableItem>
                    ),
                  )}
                </SortableContext>
                <DragOverlay>
                  {activeFile ? <Thumbnail file={activeFile} /> : null}
                </DragOverlay>
              </DndContext>
            </Gallery>
          </Grid>
          {enableYoutubeLink && (
            <Grid item xs={12}>
              <Input
                label="Youtube Link"
                name="youtube_link"
                disabled={readOnly}
              />
            </Grid>
          )}
        </>
        {attachments?.length > 0 && preview && (
          <LightBox
            defaultId={preview}
            onClose={closePreview}
            images={attachments}
            attachmentTypeOptions={attachmentTypeOptions}
            readOnly={readOnly}
          />
        )}
        {deleteDialogFile && (
          <ConfirmDialog
            title={createModalTitle("this Attachment")}
            isOpen={deleteDialogFile}
            onCancel={() => setDeleteFileDialog(null)}
            onDelete={deleteFile}
          />
        )}
      </>
    );
  },
);
