import { ColumnType } from "components/AgGrid/constants";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import {
  CommentColId,
  CommentColumnDef,
  ReceivalLotActionsColumnRenderer,
} from "constants/columnDefinitions";
import { Column } from "constants/columns";
import { CommentTypes } from "constants/comments";

import { ConsignmentColumnDefinitions } from "lib/agGrid/columnDefinitions";
import { naturalSortStringComparator } from "lib/agGrid/columns/comparators";

export const ReceivalLotColumnId = {
  PEN: "e3c5c97b-4263-46e6-ba1f-c8c7d5d74915",
  MARK: "bf66bd86-7373-4157-916c-4185d2ef2366",
  SCANS_COUNT: "4c27b445-5a17-457d-b203-9bf0f04715f0",
  QUANTITY: "5760bcdd-4998-4acc-b078-d6bb6a968840",
  CONSIGNMENT: "3945d814-b1cf-43bb-9c64-effd58b25ad4",
  CONSIGNMENT_VENDOR: "59ea7567-c3f6-4f04-ba6c-564191b6a466",
  CONSIGNMENT_QUANTITY: "ab524f10-2505-4fe3-9a1c-8aaf463e050a",
  CONSIGNMENT_NVD_ID: "2f72d7be-9501-4477-aee7-aa81f0d23c03",
  CONSIGNMENT_: "2fe2e2c7-7d92-4b09-8559-205b162e5e9d",
  EDIT: "cf0e8b0e-4829-4e47-8677-ff26d298d0d1",
};

export const receivalLotColumns = [
  {
    colId: ReceivalLotColumnId.PEN,
    enablePivot: true,
    enableRowGroup: true,
    field: "penDisplayName",
    headerName: "Pen",
    width: 90,
    pinned: "left",
    type: ColumnType.STRING,
    comparator: naturalSortStringComparator,
  },
  {
    colId: ReceivalLotColumnId.MARK,
    enablePivot: true,
    enableRowGroup: true,
    field: "mark",
  },
  {
    colId: ReceivalLotColumnId.QUANTITY,
    field: "quantity",
    enablePivot: true,
    enableRowGroup: true,
  },
  {
    colId: ReceivalLotColumnId.SCANS_COUNT,
    field: "scansCount",
    headerName: "Scan Hd",
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  {
    colId: ReceivalLotColumnId.CONSIGNMENT,
    headerName: "Consignment",
    children: [
      {
        colId: ReceivalLotColumnId.CONSIGNMENT_VENDOR,
        headerName: "Vendor",
        field: "consignment.vendor.name",
        width: 100,
      },
      {
        colId: ReceivalLotColumnId.CONSIGNMENT_QUANTITY,
        headerName: "Quantity",
        field: "consignment.quantity",
        width: 100,
      },
      {
        ...ConsignmentColumnDefinitions[Column.Consignment.NVD_ID],
        width: 100,
      },
    ],
  },
  {
    colId: ReceivalLotColumnId.EDIT,
    headerName: ACTION_COLUMN_NAME,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: ReceivalLotActionsColumnRenderer,
    suppressCellFlash: true,
    width: 200,
    pinned: "right",
  },
  {
    ...CommentColumnDef[CommentColId.COMMENTS],
    pinned: "right",
    valueGetter: params => {
      if (!params.data) {
        return null;
      }

      const { unresolvedCommentCount, resolvedCommentCount, id } = params.data;

      return {
        commentType: CommentTypes.RECEIVAL_LOT,
        commentTypeId: id,
        unresolvedCommentCount,
        resolvedCommentCount,
      };
    },
  },
];
