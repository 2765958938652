import React from "react";

import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import { checkUserAuth } from "actions";

import { OutlineButton } from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import { CenteredGreedy } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";

import { getAuthentication } from "selectors";

import history from "appHistory";

const NavigateButton = styled(OutlineButton)`
  margin: 1rem;
  height: 48px;
`;

const CheckAuth = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { search } = location;

  React.useEffect(() => {
    // On mount, dispatch action to GET /v2/auth/check-jwt/. Subsequent action will ingest tokens, and either set the `isAuthenticated` variable, or fail and redirect to a login page (specified by errorUrl URL parameter).
    const { errorUrl } = queryString.parse(search);
    dispatch(checkUserAuth(errorUrl));
  }, [dispatch, search]);

  const { isFetching, errorMessage } = useSelector(getAuthentication);

  return (
    <>
      {!isFetching && errorMessage ? (
        <CenteredGreedy>
          <ErrorMessage>{errorMessage?.message}</ErrorMessage>
          <NavigateButton
            color="primary"
            onClick={() => history.push("/login")}
          >
            Go to Login
          </NavigateButton>
        </CenteredGreedy>
      ) : (
        <CenteredGreedy>
          <LoadingSpinner actionName="Logging In" subjectName="User" />
        </CenteredGreedy>
      )}
    </>
  );
};

export default CheckAuth;
