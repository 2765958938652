import { put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { IntegrationBusinessAction } from "actions";

import {
  INTEGRATION_BUSINESS,
  INTEGRATION_CREDENTIAL,
} from "constants/actionTypes";

import {
  getBusinessById,
  getIntegrationBusinessById,
  selectBusinessIdByDeploymentBusinessIdLookup,
} from "selectors";

function* onLinkToBusiness(action) {
  const { id, businessId, options } = action;

  const state = yield select();

  const integrationBusiness = getIntegrationBusinessById(id)(state);

  const newBusiness = getBusinessById(businessId)(state);

  if (
    integrationBusiness.deploymentBusinessId &&
    integrationBusiness.deploymentBusinessId !==
      newBusiness.deploymentBusinessId
  ) {
    const oldBusinessId =
      selectBusinessIdByDeploymentBusinessIdLookup(state)[
        integrationBusiness.deploymentBusinessId
      ];
    if (oldBusinessId) {
      yield put(
        IntegrationBusinessAction.unlinkFromBusiness(id, oldBusinessId, {
          localUnlinkOnly: true,
        }),
      );
    }
  }

  yield put(
    IntegrationBusinessAction.update(
      {
        // Add some of the attributes of the integration business so that the
        // Business V2 Reducer can easily capture and changes to its related Integration Businesses
        deploymentBusinessId: newBusiness.deploymentBusinessId,
        id,
        integrationId: integrationBusiness.integrationId,
        lastSuccessfulPull: integrationBusiness.last_successful_pull,
        name: integrationBusiness.names[0].value,
        type: integrationBusiness.type,
      },
      options,
    ),
  );
}

function* onUnlinkFromBusiness(action) {
  const { id, options } = action;

  const { localUnlinkOnly = false } = options || {};
  if (localUnlinkOnly) {
    return;
  }

  yield put(
    IntegrationBusinessAction.update(
      {
        id,
        deploymentBusinessId: null,
      },
      options,
    ),
  );
}

function* onCredentialsRefreshed() {
  yield put(IntegrationBusinessAction.request());
}

function* onBusinessCreated() {
  yield put(IntegrationBusinessAction.request());
}

export default function* saga() {
  yield takeEvery(
    INTEGRATION_BUSINESS.LINK_TO_BUSINESS.ACTION,
    onLinkToBusiness,
  );
  yield takeEvery(
    INTEGRATION_BUSINESS.UNLINK_FROM_BUSINESS.ACTION,
    onUnlinkFromBusiness,
  );
  yield takeLatest(
    INTEGRATION_CREDENTIAL.LOAD_BUSINESSES.SUCCESS,
    onCredentialsRefreshed,
  );
  yield takeLatest(
    INTEGRATION_CREDENTIAL.CREATE_BUSINESS.SUCCESS,
    onBusinessCreated,
  );
}
