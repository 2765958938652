import reduceReducers from "reduce-reducers";

import { WEIGH_LOT } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineCommentReducer,
  bulkUpdateCases,
} from "lib/reducers";
import { deserializeWeighLot } from "lib/serializers/weighLots";

const create = apiModelOfflineCreateReducer(WEIGH_LOT, {
  deserializer: deserializeWeighLot,
});

const deleteReducer = apiModelOfflineDeleteReducer(WEIGH_LOT);

const fetch = apiModelOfflineFetchReducer(WEIGH_LOT, {
  deserializer: deserializeWeighLot,
});

const update = apiModelOfflineUpdateReducer(WEIGH_LOT, {
  deserializer: deserializeWeighLot,
});

const comment = apiModelOfflineCommentReducer(WEIGH_LOT);

function bulkUpdateAndCreateReducer(state, action) {
  switch (action.type) {
    case WEIGH_LOT.BULK_UPDATE_OR_CREATE.REQUEST: {
      return bulkUpdateCases(state, action, "byId", deserializeWeighLot);
    }
    default:
      return state;
  }
}

const weighLotReducer = reduceReducers(
  create,
  comment,
  deleteReducer,
  fetch,
  update,
  bulkUpdateAndCreateReducer,
);

export default weighLotReducer;
