import React from "react";

import {
  faBirthdayCake,
  faCow,
  faFileCertificate,
  faWeight,
} from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import intersection from "lodash/intersection";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  CheckBox,
  Input,
  Label,
  MutuallyExclusiveInput,
  OptionTogglerField,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";
import { Paper } from "components/Paper";
import { useResponsiveText } from "components/ResponsiveText";
import { MediumText as Heading } from "components/Text";

import {
  Accreditation,
  FrameStructure,
  VendorBredOptions,
} from "constants/draftingAttributes";
import { ExportSites } from "constants/exportSites";

import {
  breedAllocation,
  dentitionAllocation,
  fatScoresAllocation,
  hornAllocation,
  muscleScoresAllocation,
} from "lib/advancedDrafting";

import { selectAreStudFeaturesEnabled } from "selectors";

import { BreedSection } from "./Breed";
import { Error, Icon, To } from "./components";
import { DentitionSection } from "./Dentition";
import { FatScoreSection } from "./FatScore";
import { HornSection } from "./Horn";
import { MuscleScoreSection } from "./MuscleScore";
import { TimeUnitChoices, toErrorMessage } from "./util";
import { VisibilitySection } from "./Visibility";

const TooltipHeading = styled(Heading)`
  margin-right: 5px;
`;

const withAttributeNamespace = name =>
  withNamespace("draftingAttributes", name);

const AgeSection = ({ readOnly }) => {
  return (
    <Grid item xs={12}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Icon icon={faBirthdayCake} color="gray44" />
            <Heading>Age Range</Heading>
          </Grid>
          <Grid item xs={6}>
            <Row>
              <Input
                label=""
                name={withAttributeNamespace("minAge")}
                type="number"
                emptyValue={null}
                disabled={readOnly}
              />
              <To>To</To>
              <Input
                label=""
                name={withAttributeNamespace("maxAge")}
                type="number"
                emptyValue={null}
                disabled={readOnly}
              />
            </Row>
          </Grid>

          <Grid item xs={6} align="center">
            <OptionTogglerField
              options={TimeUnitChoices}
              name={withAttributeNamespace("ageRangeTimeUnit")}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={12}>
            <CheckBox
              label="Weaned (weaners only)"
              name={withAttributeNamespace("isWeaned")}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const FrameSection = ({ errorMessage, readOnly }) => (
  <Grid item xs={12}>
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Row flexGrow>
            <Icon icon={faCow} color="gray44" />
            <Heading>Frame Structure</Heading>
            {errorMessage && <Error>{errorMessage}</Error>}
          </Row>
        </Grid>
        <Grid item xs={12}>
          <OptionTogglerField
            name={withAttributeNamespace("frameStructure")}
            options={Object.values(FrameStructure)}
            allowToggleOff
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);

const AttributesSection = ({ errorMessage, readOnly }) => {
  const areStudFeaturesEnabled = useSelector(selectAreStudFeaturesEnabled);
  return (
    <Grid item xs={12}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Icon icon={faFileCertificate} color="gray44" />
            <Heading>Attributes</Heading>
            {errorMessage && <Error>{errorMessage}</Error>}
          </Grid>

          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("accreditationEU")}
              label="EU Eligible"
              tooltip="European Union Cattle Accreditation Scheme.  This value is prefilled from the EU status on the Vendor's PIC"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("accreditationPTIC")}
              label="PTIC"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
              tooltip="Refer to Additional Information on NVD"
              mutuallyExclusiveWithName={withAttributeNamespace(
                "accreditationPTE",
              )}
              mutuallyExclusiveWithFalseValue={Accreditation.NONE}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("accreditationPTE")}
              label="PTE"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
              tooltip="Refer to Additional Information on NVD"
              mutuallyExclusiveWithName={withAttributeNamespace(
                "accreditationPTIC",
              )}
              mutuallyExclusiveWithFalseValue={Accreditation.NONE}
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("accreditationPCAS")}
              label="PCAS"
              tooltip="Pasturefed Cattle Assurance System.  This value is prefilled from the PCAS status on the Vendor's PIC"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("accreditationAntibioticFree")}
              label="Antibiotic Free"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("accreditationOrganic")}
              label="Organic"
              tooltip="Refer to Additional Information on NVD"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("accreditationNEE")}
              label="Never Ever"
              tooltip="Greenham NEVER EVER Beef Program.  This value is prefilled from the NEE status on the Vendor's PIC"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("stationMated")}
              label="Station Mated"
              tooltip="Refer to Additional Information on NVD"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
              mutuallyExclusiveWithName={withAttributeNamespace(
                "notStationMated",
              )}
              mutuallyExclusiveWithFalseValue={Accreditation.NONE}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("notStationMated")}
              label="Not Station Mated"
              tooltip="Refer to Additional Information on NVD"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
              mutuallyExclusiveWithName={withAttributeNamespace("stationMated")}
              mutuallyExclusiveWithFalseValue={Accreditation.NONE}
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("accreditationBackgrounded")}
              label="Backgrounded"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
              tooltip="Refer to Additional Information on NVD"
              mutuallyExclusiveWithName={withAttributeNamespace("feeder")}
              mutuallyExclusiveWithFalseValue={Accreditation.NONE}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("feeder")}
              label="Feeder"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
              tooltip="Agent Assessed as a Feeder"
              mutuallyExclusiveWithName={withAttributeNamespace(
                "accreditationBackgrounded",
              )}
              mutuallyExclusiveWithFalseValue={Accreditation.NONE}
            />
          </Grid>

          <Grid item xs={2} sm={1} container alignItems="center">
            <Label>JBAS</Label>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Row alignCenter>
              <Input
                name={withAttributeNamespace("accreditationJBAS")}
                maxLength={1}
                type="number"
                width="50%"
                columnProps={{ fullWidth: true, alignEnd: true }}
                disabled={readOnly}
              />
              &nbsp;
              <Tooltip title="This value is prefilled from the JBAS status on the Vendor's PIC" />
            </Row>
          </Grid>

          <Grid item xs={6} sm={4}>
            <CheckBox
              name={withAttributeNamespace("accreditationGrassFed")}
              label="Grass Fed"
              trueValue={Accreditation.ALL}
              falseValue={Accreditation.NONE}
              disabled={readOnly}
              mutuallyExclusiveWithName={withAttributeNamespace("grainFedDays")}
              mutuallyExclusiveWithFalseValue={0}
            />
          </Grid>
          <Grid item xs={2} sm={1} container alignItems="center">
            <Label>Grain Fed</Label>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Row alignCenter>
              <MutuallyExclusiveInput
                name={withAttributeNamespace("grainFedDays")}
                maxLength={4}
                type="number"
                width="50%"
                columnProps={{ fullWidth: true, alignEnd: true }}
                disabled={readOnly}
                mutuallyExclusiveWithName={withAttributeNamespace(
                  "accreditationGrassFed",
                )}
                mutuallyExclusiveWithFalseValue={Accreditation.NONE}
              />
              &nbsp;
              <Tooltip title="Number of Days on Feed" />
            </Row>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextArea
            label="Comments (Placard Text)"
            name={withAttributeNamespace("publicDescription")}
            tooltip="Any other details you wish to provide."
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            label="Fault Description"
            name={withAttributeNamespace("publicFaultDescription")}
            tooltip="Anything you would declare in an assessment."
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Pen may be sold as part of a run"
            name={withAttributeNamespace("partOfRun")}
            tooltip="Other pens that may be sold as part of a run."
            disabled={readOnly}
          />
        </Grid>
        {!areStudFeaturesEnabled && (
          <Grid item xs={12}>
            <Input
              label="Tag #"
              name={withAttributeNamespace("tagNumber")}
              disabled={readOnly}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <OptionTogglerField
            label="Vendor Bred"
            labelPosition="top"
            name={withAttributeNamespace("vendorBredOverride")}
            options={VendorBredOptions}
            disabled={readOnly}
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

const CattleBreedSection = ({
  errorMessage,
  showFullDrafting,
  onChangeBreed,
  readOnly,
}) => (
  <BreedSection
    errorMessage={errorMessage}
    showFullDrafting={showFullDrafting}
    onChangeBreed={onChangeBreed}
    readOnly={readOnly}
  >
    <Grid item xs={12}>
      <Input
        label="Bloodline"
        name={withAttributeNamespace("bloodline")}
        tooltip="Breeding information."
        disabled={readOnly}
      />
    </Grid>
  </BreedSection>
);

const CattleWeightSection = ({ readOnly }) => {
  const estCarcassWeightLabel = useResponsiveText({
    mobile: "Cwt",
    tablet: "Est Carcass Weight",
    desktop: "Est Carcass Weight",
  });
  const estLiveWeightLabel = useResponsiveText({
    mobile: "Lwt",
    tablet: "Est Live Weight",
    desktop: "Est Live Weight",
  });
  const dressingPercentLabel = useResponsiveText({
    mobile: "Dressing %",
    tablet: "Dressing Percent",
    desktop: "Dressing Percent",
  });
  return (
    <Grid item xs={12}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Icon icon={faWeight} color="gray44" />
            <TooltipHeading>Weight</TooltipHeading>
            <Tooltip title="Declare the weight, location and accuracy of your weighing" />
          </Grid>
          <Grid item xs={4}>
            <Input
              label={estCarcassWeightLabel}
              name={withAttributeNamespace("estCarcassWeight")}
              type="number"
              afterSymbol="Kg"
              multiplier={1000}
              decimalPlaces={2}
              decimal
              tooltip="Estimated Carcass Weight"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label={estLiveWeightLabel}
              name="estimatedAverageMassGrams"
              type="number"
              afterSymbol="Kg"
              multiplier={1000}
              decimalPlaces={3}
              decimal
              tooltip="Estimated Live Weight"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label={dressingPercentLabel}
              name={withAttributeNamespace("dressingPercent")}
              type="number"
              afterSymbol="%"
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

function isVisibleFor(visibleExportSites, selectedExportSites = []) {
  return (
    selectedExportSites.length === 0 ||
    intersection(selectedExportSites, visibleExportSites).length > 0
  );
}

export const Cattle = ({ saleLot, setFieldValue, onChangeBreed, readOnly }) => {
  const allocations = {
    fatScores: fatScoresAllocation(saleLot),
    muscleScores: muscleScoresAllocation(saleLot),
    dentition: dentitionAllocation(saleLot),
    breed: breedAllocation(saleLot),
    horn: hornAllocation(saleLot),
  };

  // If every site is stock live or angus aus we want to show a minimal drafting view
  // else we want to show the full view
  const exportSites = saleLot.exportSites || [];

  const showFor = visibleExportSites =>
    isVisibleFor(visibleExportSites, exportSites);

  return (
    <>
      <VisibilitySection
        consignmentId={saleLot.consignment_id}
        readOnly={readOnly}
      />
      {showFor([
        ExportSites.AGRINOUS,
        ExportSites.ANGUS_AUSTRALIA,
        ExportSites.AUCTIONS_PLUS,
        ExportSites.LIST_2_SELL,
        ExportSites.STOCK_LIVE,
      ]) && (
        <CattleBreedSection
          errorMessage={toErrorMessage(allocations.breed)}
          showFullDrafting={showFor([
            ExportSites.AGRINOUS,
            ExportSites.AUCTIONS_PLUS,
            ExportSites.LIST_2_SELL,
          ])}
          onChangeBreed={onChangeBreed}
          readOnly={readOnly}
        />
      )}
      {showFor([
        ExportSites.AUCTIONS_PLUS,
        ExportSites.STOCK_LIVE,
        ExportSites.AGRINOUS,
        ExportSites.LIST_2_SELL,
      ]) && (
        <AgeSection
          setFieldValue={setFieldValue}
          values={saleLot}
          readOnly={readOnly}
        />
      )}
      {showFor([
        ExportSites.AUCTIONS_PLUS,
        ExportSites.AGRINOUS,
        ExportSites.LIST_2_SELL,
      ]) && (
        <DentitionSection
          errorMessage={toErrorMessage(allocations.dentition)}
          readOnly={readOnly}
        />
      )}
      {showFor([
        ExportSites.AUCTIONS_PLUS,
        ExportSites.AGRINOUS,
        ExportSites.LIST_2_SELL,
      ]) && (
        <FatScoreSection
          errorMessage={toErrorMessage(allocations.fatScores)}
          maxScore={6}
          readOnly={readOnly}
        />
      )}
      {showFor([
        ExportSites.AUCTIONS_PLUS,
        ExportSites.AGRINOUS,
        ExportSites.LIST_2_SELL,
      ]) && (
        <MuscleScoreSection
          errorMessage={toErrorMessage(allocations.muscleScores)}
          maxScore={5}
          readOnly={readOnly}
        />
      )}
      {showFor([
        ExportSites.AUCTIONS_PLUS,
        ExportSites.AGRINOUS,
        ExportSites.LIST_2_SELL,
      ]) && <FrameSection readOnly={readOnly} />}
      {showFor([
        ExportSites.AUCTIONS_PLUS,
        ExportSites.STOCK_LIVE,
        ExportSites.AGRINOUS,
        ExportSites.LIST_2_SELL,
      ]) && (
        <HornSection
          errorMessage={toErrorMessage(allocations.horn)}
          quantity={saleLot.quantity}
          readOnly={readOnly}
        />
      )}
      {showFor([
        ExportSites.AUCTIONS_PLUS,
        ExportSites.AGRINOUS,
        ExportSites.LIST_2_SELL,
      ]) && <CattleWeightSection readOnly={readOnly} />}
      {showFor([
        ExportSites.AUCTIONS_PLUS,
        ExportSites.STOCK_LIVE,
        ExportSites.AGRINOUS,
        ExportSites.LIST_2_SELL,
      ]) && <AttributesSection readOnly={readOnly} />}
    </>
  );
};
