import React from "react";

import {
  faExclamationTriangle,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import UAParser from "ua-parser-js";

import { SaleAction, setConcurrentUserBlock } from "actions";

import { IconButton } from "components/Form";
import { HelpText } from "components/Form/FormikControls/HelpText";
import { Column } from "components/Layout";
import Table from "components/Table";
import { MediumText } from "components/Text";

import {
  formatUTCToLocalDateTimeString,
  secondsToDuration,
} from "lib/timeFormats";

import { getConcurrentUserBlock } from "selectors";

import { useTheme } from "hooks";

const UserAgentDescription = React.memo(({ userAgent }) => {
  const parser = new UAParser(userAgent);
  const uaDetails = parser.getResult();

  const runningOn = uaDetails.device.vendor
    ? `${uaDetails.device.vendor} ${uaDetails.device.type}`
    : uaDetails.os.name;
  return `${uaDetails.browser.name} on ${runningOn}`;
});

const TableWrapper = styled(Column)`
  color: ${({ theme }) => theme.colors.titleMedium};
`;

const ConcurrentUserBlockNotification = () => {
  const concurrentUserBlock = useSelector(getConcurrentUserBlock);

  const dispatch = useDispatch();
  const theme = useTheme();

  const recheckGeoBlock = () => {
    dispatch(setConcurrentUserBlock(null));
    dispatch(SaleAction.request());
  };

  if (!concurrentUserBlock) {
    return null;
  }

  return (
    <Box p={3}>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={3} container alignItems="center" justifyContent="center">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="3x"
            color={theme.colors.warning}
          />
        </Grid>
        <Grid item xs={9}>
          <MediumText>
            Your access has been limited due to exceeding the maximum number of
            concurrent users.
          </MediumText>
        </Grid>

        {concurrentUserBlock.activeUsers ? (
          <Grid item md={9} xs={12}>
            <HelpText>
              The following users are currently active - you must have{" "}
              {concurrentUserBlock.maximumUsers} or less users active in any
              given window of{" "}
              {secondsToDuration(parseInt(concurrentUserBlock.timeout, 10))}.
              Logout, or contact AgriNous support to upgrade your account.
            </HelpText>
            <TableWrapper fullWidth>
              <Table
                data={concurrentUserBlock.activeUsers}
                columns={[
                  {
                    id: "name",
                    Header: "Name",
                    accessor: "name",
                    Cell: ({ original: { name, unique } }) => {
                      return <span title={unique}>{name}</span>;
                    },
                  },
                  {
                    id: "lastSeen",
                    Header: "Last Seen",
                    accessor: "lastSeen",
                    Cell: ({ value }) => formatUTCToLocalDateTimeString(value),
                  },
                  {
                    id: "device",
                    Header: "Device",
                    accessor: "userAgent",
                    Cell: ({ value }) => (
                      <UserAgentDescription userAgent={value} />
                    ),
                  },
                ]}
              />
            </TableWrapper>
          </Grid>
        ) : null}

        <Grid
          item
          xs={8}
          md={4}
          container
          alignItems="center"
          justifyContent="center"
        >
          <IconButton icon={faSync} onClick={recheckGeoBlock}>
            Check Availability Now
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConcurrentUserBlockNotification;
