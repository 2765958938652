import Fuse from "fuse.js";
import { createSelector } from "reselect";

import { Settings } from "constants/settings";

import {
  getProperties,
  getSaleLots,
  getSetting,
  selectAvailableRolesList,
  selectDeliveryPenIds,
  selectPenIdsByRoundIdLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectStatusByAuctionPenIdLookup,
  selectVendorIdBySaleLotIdLookup,
} from "selectors";

import { getAuctionPens, getBusinesses } from "selectors/root";

export const selectDeliveryPenSearchIndex = createSelector(
  [selectDeliveryPenIds, getAuctionPens, getBusinesses],
  (deliveryPenIds, auctionPens, businesses) => {
    const searchIndex = Object.values(deliveryPenIds).map(deliveryPenId => ({
      id: deliveryPenId,
      startPen: auctionPens[deliveryPenId].start_pen,
      endPen: auctionPens[deliveryPenId].end_pen,
      penOwners: auctionPens[deliveryPenId].penOwners.map(penOwner => {
        const business = businesses[penOwner.businessId];
        return {
          businessName: business?.name || "",
          buyerWayName: penOwner.buyerWay?.name || "",
        };
      }),
    }));

    return new Fuse(searchIndex, {
      shouldSort: true,
      keys: [
        "startPen",
        "endPen",
        "penOwners.businessName",
        "penOwners.buyerWayName",
      ],
      distance: 200,
      threshold: 0.1,
    });
  },
);
export const selectAuthSearchIndex = createSelector(
  [selectAvailableRolesList],
  roles =>
    new Fuse(roles, {
      keys: ["name", "username"],
      distance: 200,
      threshold: 0.2,
    }),
);

export const selectAuctionPenSearchIndex = createSelector(
  [
    getAuctionPens,
    getSaleLots,
    getBusinesses,
    getProperties,
    selectSaleLotIdsByAuctionPenIdLookup,
    selectVendorIdBySaleLotIdLookup,
    selectStatusByAuctionPenIdLookup,
    selectPenIdsByRoundIdLookup,
    getSetting(Settings.round),
  ],
  (
    auctionPens,
    saleLots,
    businesses,
    properties,
    saleLotIdsByAuctionPenIdLookup,
    vendorIdBySaleLotIdLookup,
    statusByAuctionPenIdLookup,
    penIdsByRoundIdLookup,
    roundId,
  ) => {
    const NOT_PENNED = [{ id: null }];
    const penIds =
      roundId !== null
        ? penIdsByRoundIdLookup[roundId] || []
        : Object.keys(auctionPens);
    const searchIndex = penIds
      .map(penId => auctionPens[penId])
      .concat(NOT_PENNED)
      .map(auctionPen => ({
        id: auctionPen.id,
        startPen: auctionPen.start_pen,
        endPen: auctionPen.end_pen,
        status: statusByAuctionPenIdLookup[auctionPen.id],
        saleLots: saleLotIdsByAuctionPenIdLookup[auctionPen.id]?.map(
          saleLotId => ({
            overflowPen: saleLots[saleLotId].overflowPen,
            buyerWay: saleLots[saleLotId].buyer_way?.name,
            buyerName: businesses[saleLots[saleLotId].buyer_id]?.name,
            buyerPublicDisplayName:
              businesses[saleLots[saleLotId].buyer_id]?.publicDisplayName,
            destinationProperty:
              properties[saleLots[saleLotId].destination_property_id]?.name,
            vendorName: businesses[vendorIdBySaleLotIdLookup[saleLotId]]?.name,
            vendorPublicDisplayName:
              businesses[vendorIdBySaleLotIdLookup[saleLotId]]
                ?.publicDisplayName,
          }),
        ),
      }));

    return new Fuse(searchIndex, {
      shouldSort: true,
      keys: [
        "startPen",
        "endPen",
        "status",
        "saleLots.overflowPen",
        "saleLots.buyerWay",
        "saleLots.buyerName",
        "saleLots.buyerPublicDisplayName",
        "saleLots.destinationProperty",
        "saleLots.vendorName",
        "saleLots.vendorPublicDisplayName",
      ],
      distance: 200,
      threshold: 0.1,
    });
  },
);
