import React, { memo } from "react";

import { CurrentLotLayout } from "./Layout";
import WeighBridgeCurrentLotBody from "./WeighBridgeCurrentLotBody";
import WeighBridgeCurrentLotFooter from "./WeighBridgeCurrentLotFooter";

function WeighBridgeCurrentLotComponent(props) {
  const { onNextSaleLot } = props;
  return (
    <CurrentLotLayout>
      <WeighBridgeCurrentLotBody />
      <WeighBridgeCurrentLotFooter onNextSaleLot={onNextSaleLot} />
    </CurrentLotLayout>
  );
}

export default memo(WeighBridgeCurrentLotComponent);
