import queryString from "query-string";

import { NOMINATION } from "constants/actionTypes";

import { serializeNomination } from "lib/serializers/nominations";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, livestockSaleId, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/nominations/", id, action),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const NominationAction = offlineActionCreator(
  NOMINATION,
  serializeNomination,
  urlCreator,
  true,
);
