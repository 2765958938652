import { SaleTypes } from "constants/sale";

export const NVDRelatedDocumentTypes = {
  NVD: "NVD",
  ANIMAL_HEALTH_STATEMENT: "Animal Health Statement",
  OTHER: "Other",
  KILL_SHEET: "Feedback/Kill Sheet",
  INSURANCE_CLAIM: "Insurance Claim",
};

export const NVDDocumentTypeOptions = [
  {
    label: NVDRelatedDocumentTypes.NVD,
    value: NVDRelatedDocumentTypes.NVD,
  },
  {
    label: NVDRelatedDocumentTypes.ANIMAL_HEALTH_STATEMENT,
    value: NVDRelatedDocumentTypes.ANIMAL_HEALTH_STATEMENT,
  },
  {
    label: NVDRelatedDocumentTypes.OTHER,
    value: NVDRelatedDocumentTypes.OTHER,
  },
  {
    label: NVDRelatedDocumentTypes.KILL_SHEET,
    value: NVDRelatedDocumentTypes.KILL_SHEET,
    saleTypes: [SaleTypes.OVER_HOOKS],
  },
  {
    label: NVDRelatedDocumentTypes.INSURANCE_CLAIM,
    value: NVDRelatedDocumentTypes.INSURANCE_CLAIM,
  },
];

export const NVDRelatedDocumentTypeValues = Object.values(
  NVDRelatedDocumentTypes,
);

export const MediaDocumentTypes = {
  IMAGE: "image",
  VIDEO: "video",
};

export const OtherDocumentTypes = {
  SUPPORTING_DOCUMENTATION: "Supporting Documentation",
};

export const DocumentTypes = {
  ...NVDRelatedDocumentTypes,
  ...MediaDocumentTypes,
  ...OtherDocumentTypes,
};

export const ConsignmentAttachmentTypeOptions = [
  {
    label: DocumentTypes.NVD,
    value: DocumentTypes.NVD,
  },
  {
    label: DocumentTypes.ANIMAL_HEALTH_STATEMENT,
    value: DocumentTypes.ANIMAL_HEALTH_STATEMENT,
  },
  {
    label: "Image/Media",
    value: DocumentTypes.IMAGE,
  },
  {
    label: "Video",
    value: DocumentTypes.VIDEO,
  },
  {
    label: OtherDocumentTypes.SUPPORTING_DOCUMENTATION,
    value: OtherDocumentTypes.SUPPORTING_DOCUMENTATION,
  },
  {
    label: DocumentTypes.OTHER,
    value: DocumentTypes.OTHER,
  },
  {
    label: NVDRelatedDocumentTypes.KILL_SHEET,
    value: NVDRelatedDocumentTypes.KILL_SHEET,
    saleTypes: [SaleTypes.OVER_HOOKS],
  },
  {
    label: NVDRelatedDocumentTypes.INSURANCE_CLAIM,
    value: NVDRelatedDocumentTypes.INSURANCE_CLAIM,
  },
];

export const SaleLotAttachmentTypeOptions = [
  {
    label: "Image/Media",
    value: DocumentTypes.IMAGE,
  },
  {
    label: "Video",
    value: DocumentTypes.VIDEO,
  },
  {
    label: OtherDocumentTypes.SUPPORTING_DOCUMENTATION,
    value: OtherDocumentTypes.SUPPORTING_DOCUMENTATION,
  },
];

export const DeclarationTypes = {
  S0413: "S0413",
  S0720: "S0720",
  C0413: "C0413",
  C0720: "C0720",
  E0413: "E0413",
  E0720: "E0720",
  BC0720: "BC0720",
  G0720: "G0720",
};

export const EXPIRED_DECLARATION_VERSIONS = [
  DeclarationTypes.S0413,
  DeclarationTypes.C0413,
  DeclarationTypes.E0413,
];
