import {
  addDays,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInHours,
  differenceInMilliseconds,
  formatDistanceToNow,
  formatDuration,
  intervalToDuration,
  isValid,
  parseISO,
  subMonths,
  subYears,
} from "date-fns";
import format from "date-fns/format";
import { toDate } from "date-fns-tz";
import { isDate } from "lodash";

const TIME_FORMAT = "h:mm a";
const DATE_FORMAT = "dd/MM/yyyy";
const LONG_DATE_FORMAT = "EEEE, MMMM do, yyyy"; // Monday, January 4th, 2020
export const ISO8601_DATE_FORMAT = "yyyy-MM-dd";
const VERBOSE_DAY_MONTH = "ddd Do MMM";
const DATETIME_INPUT_FORMAT = "yyyy-MM-dd'T'HH:mm";
const HIGH_RES_DAY_TIME_FORMAT = "EEEE, h:mm:ss a";

const DASHBOARD_DATE_FORMAT = `do LLL (E)`; // 6th May (Wed)

const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const formatDateStringForDashboard = dateString =>
  format(parseISO(dateString), DASHBOARD_DATE_FORMAT);

const formatToLocalString = (utcDateTimeStr, outputFormat) => {
  const utcDateTime = toDate(utcDateTimeStr, { timeZone: "UTC" });
  return isValid(utcDateTime) ? format(utcDateTime, outputFormat) : "-";
};

export const formatUTCToLocalTimeString = utcDateTimeStr =>
  formatToLocalString(utcDateTimeStr, TIME_FORMAT);

export const formatUTCToLocalDateString = utcDateTimeStr =>
  formatToLocalString(utcDateTimeStr, DATE_FORMAT);

export const formatUTCToLocalDateTimeString = utcDateTimeStr =>
  formatToLocalString(utcDateTimeStr, DATETIME_FORMAT);

export const formatUTCToLocalHighResDayTimeString = utcDateTimeStr =>
  formatToLocalString(utcDateTimeStr, HIGH_RES_DAY_TIME_FORMAT);

export const convertDateTimeToUTCDateTime = dateTime => {
  return new Date(dateTime).toISOString().replace("Z", "");
};

export const formatUTCToLocalDateTimeInputString = utcDateTimeStr =>
  formatToLocalString(utcDateTimeStr, DATETIME_INPUT_FORMAT);

export const formatUTCToLocalNamedDayMonth = utcDate =>
  formatToLocalString(utcDate, VERBOSE_DAY_MONTH);

export const formatDateTimeString = date => format(date, DATETIME_FORMAT);

/**
 * @param {Date} date
 * @returns {string} string in the AU display format of "DD/MM/YYYY"
 */
export const formatDateString = date => format(date, DATE_FORMAT);

/**
 * @param {Date} date
 * @returns {string} string of just the year
 */
export const formatDateYear = date => format(date, "yyyy");

/**
 * @param {Date} date
 * @returns {string} string in the format of "YYYY-MM-DD" as prescribed by ISO 8601
 */
export const formatISO8601DateString = date =>
  format(date, ISO8601_DATE_FORMAT);

/**
 * @param {String} dateString string in the format of "YYYY-MM-DD" as prescribed by ISO 8601
 * @returns {Date}
 */
export const getDateFromISO8601DateString = dateString => new Date(dateString);

export const formatLocalTimestampToDateTimeString = timestamp =>
  format(new Date(timestamp), DATETIME_FORMAT);

export const dateTimeStringToDateTime = str => {
  // Sometimes we get strings, sometimes we get objects.  The previous
  // incarnation of date-fns allowed parse to handle either, this one
  // doesn't...   TODO - perhaps a deprecation warning?
  if (typeof str === "string") {
    return parseISO(str);
  }
  return str;
};

export const formatLongDateString = date => format(date, LONG_DATE_FORMAT);

export const formatHeaderDateString = date => format(date, "iii dd/MM");
/**
 * Format a date string or object to time string
 * @param {(Date|string|number)} date
 */
export const formatTime = date => format(new Date(date), "h:mm:ss a");

/**
 * Return the string formatted as a date in AgriNous style
 * @param {(Date|string|number)} date
 * @return {string}
 */
export const formatDate = date => format(parseISO(date), "dd/MM/yyyy");
/**
 * Return the string formatted as a UTC date and time in AgriNous style
 * as a local date time. Use with datetime string from server
 * @param {string} date
 * @return {string}
 */
export const formatDateTimeUTC = date =>
  format(parseISO(`${date}Z`), "dd/MM/yyyy h:mma");

// creates the sale date options starting from today and going ahead one week
export const getSaleDateOptions = () => {
  const options = [];
  for (let i = 0; i < 7; i += 1) {
    const date = addDays(new Date(), i);
    let label = format(date, "do MMMM yyyy");
    label =
      i === 0 ? `Today - ${label}` : i === 1 ? `Tomorrow - ${label}` : label;
    options.push({ value: formatISO8601DateString(date), label });
  }

  return options;
};

export const getTimeSince = (datetime, addSuffix = false) => {
  return formatDistanceToNow(parseISO(datetime), { addSuffix });
};

export const getSecondsSince = timestamp => {
  const difference = Math.round((Date.now() - timestamp) / 1000);
  if (difference <= 1) {
    return "Now";
  }
  return `${difference} Seconds Ago`;
};

// Gets now, but lops off the Z in order to make it
export const getNowNoTimezone = () => {
  return new Date().toISOString().replace("Z", "");
};

export const formatAUDate = (day, month, year) => {
  return `${day}/${month}/${year}`;
};

export const getAUFormattedDate = date => {
  return formatAUDate(
    date.getDate(),
    date.getMonth() + 1,
    date.getYear() - 100,
  );
};

export const placardDateFormat = (date, getYear) => {
  if (!date) {
    return null;
  }
  if (getYear) {
    const formattedDate = format(new Date(date), "MMM yyyy");
    return formattedDate;
  } else {
    const formattedDate = format(new Date(date), "MMM");
    return formattedDate;
  }
};

export const secondsToDuration = seconds =>
  formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }));

// Find the date exactly X days ago.
export const dateXDaysAgo = daysAgo =>
  new Date(new Date().setDate(new Date().getDate() - daysAgo));

export const getHourDifferenceFromDateTimeFrames = (
  startDateTime,
  endDateTime,
) => differenceInHours(new Date(endDateTime), new Date(startDateTime)) || 0;

export function timeToDecimal(timeString) {
  // convert hours:minutes string to decimal
  // translates to better time calculatations and shows time (in hours)
  const arr = timeString.split(":");
  const dec = parseInt((arr[1] / 6) * 10, 10);

  return parseFloat(`${parseInt(arr[0], 10)}.${dec < 10 ? "0" : ""}${dec}`);
}

export const getDecimalDifferenceFromDateTimeFrames = (
  startDateTime,
  endDateTime,
) => {
  const milliseconds =
    differenceInMilliseconds(new Date(endDateTime), new Date(startDateTime)) ||
    0;
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds %= 60;
  minutes = seconds >= 30 ? minutes + 1 : minutes;
  minutes %= 60;
  hours %= 24;

  return timeToDecimal(`${hours}:${minutes}`);
};

export const getStringOfLastXMonths = months => {
  return [...Array(months)] // Create an array from the passed in number [empty x 2], then spread it into [undefined, undefined] so it can be mapped.
    .map((_unused, index) => format(subMonths(new Date(), index + 1), "LLLL")) // LLLL gets the month name from a date
    .reverse() // Put the list into the right order
    .join(", ");
};

export const getStringOfLast12Months = () => {
  const today = new Date();
  const startMonth = subYears(today, 1);
  const endMonth = subMonths(today, 1);
  return `${format(startMonth, "LLLL")} ${startMonth.getFullYear()} - ${format(
    endMonth,
    "LLLL",
  )} ${endMonth.getFullYear()}`;
};

/**
 * Returns the number of days difference between two ISO date stamps.
 * @param {string|Date} date1
 * @param {string|Date} date2
 * @return {int}
 */
export function getDaysDifferent(date1, date2) {
  return differenceInCalendarDays(new Date(date1), new Date(date2));
}

/**
 * Returns the number of months difference between two ISO date stamps.
 * @param {string|Date} date1
 * @param {string|Date} date2
 * @return {int}
 */
export function getMonthsDifferent(date1, date2) {
  return differenceInCalendarMonths(new Date(date1), new Date(date2));
}

/**
 * Returns the date from a string of various formats
 * checks for patterns
 * dd mm yyyy || dd.mm.yyyy || dd/mm/yyyy || dd-mm-yyyy ||
 * dd mmm yyyy || dd.mmm.yyyy || dd/mmm/yyyy || dd-mmm-yyyy ||
 * yyyy mm dd || yyyy.mm.dd || yyyy/mm/dd || yyyy-mm-dd ||
 * yyyy mmm dd || yyyy.mmm.dd || yyyy/mmm/dd || yyyy-mmm-dd
 * @param {string} date
 * @return {Date|null}
 */
export const getDateFromString = date => {
  const isDayMonthYearDateFormat =
    date.match("[0-9]{2}([-/ .])[A-z]{3}[-/ .][0-9]{4}") ||
    date.match("[0-9]{2}([-/ .])[0-9]{2}[-/ .][0-9]{4}");

  const isYearMonthDayDateFormat =
    date.match("[0-9]{4}([-/ .])[A-z]{3}[-/ .][0-9]{2}") ||
    date.match("[0-9]{4}([-/ .])[0-9]{2}[-/ .][0-9]{2}");

  if (isDayMonthYearDateFormat || isYearMonthDayDateFormat) {
    const result = isDayMonthYearDateFormat || isYearMonthDayDateFormat;

    const dateSplit = result[0].split(result[1]);

    const day = isDayMonthYearDateFormat ? dateSplit[0] : dateSplit[2];
    const month = dateSplit[1];
    const year = isDayMonthYearDateFormat ? dateSplit[2] : dateSplit[0];

    return `${year}/${month}/${day}`;
  }

  return null;
};

/**
 * Sorts options by formatted dates in their labels,
 * if dates are not available from the label string
 * The values remain in original order
 * @param {string} a
 * @param {string} b
 * @return {Date|Number|null}
 */
export const sortByDateInOptionsLabel = (aOption, bOption) => {
  // cut out the bits that are a date then sort
  const aDate = new Date(getDateFromString(aOption.label));
  const bDate = new Date(getDateFromString(bOption.label));

  if (isDate(aDate) && isDate(bDate)) {
    return bDate - aDate;
  }

  if (isDate(aDate)) {
    return aDate;
  }

  if (isDate(bDate)) {
    return bDate;
  }

  return null;
};
