import React from "react";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";

import SingleWeighSettingsModal from "containers/SingleWeighSettingsModal";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

const SingleWeighSettingsModalAdapter = props => {
  const { hash } = props;
  const { returnTo } = hash;
  const hashParams = hash[ModalTypes.SingleWeighSettings] || null;
  const [singleWeighId] = JSON.parse(hashParams);

  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  return (
    <WaitForSync requiredData={[ApiModel.SINGLE_WEIGH]}>
      <SingleWeighSettingsModal
        closeSelf={closeSelf}
        singleWeighId={singleWeighId}
      />
    </WaitForSync>
  );
};

export default SingleWeighSettingsModalAdapter;
