import React from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { setSetting } from "actions";

import { MultiButton } from "components/Button";
import { DeliveryPenCard } from "components/DeliveryPens/ListView/DeliveryPenCard";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { GlobalSearchHeader } from "components/SearchInput/GlobalSearch";

import { PenTypes } from "constants/auctionPens";
import { ApiModel } from "constants/loading";
import { Settings } from "constants/settings";

import { sortPenByOrder } from "lib/auctionPens";
import { openDeliveryPenOwnerModal } from "lib/navigation";
import { disableReadOnlyActions } from "lib/readOnly";

import {
  getAuctionPens,
  getHasWriteAccessInCurrentSale,
  selectDeliveryPenIds,
} from "selectors";

function DeliveryPensList() {
  const deliveryPenIds = useSelector(selectDeliveryPenIds);
  const auctionPens = useSelector(getAuctionPens);

  const sortedPenIds = sortPenByOrder(
    deliveryPenIds.map(penId => auctionPens[penId]),
  ).map(pen => pen.id);

  return (
    <div>
      {sortedPenIds.map(deliveryPenId => (
        <DeliveryPenCard deliveryPenId={deliveryPenId} key={deliveryPenId} />
      ))}
    </div>
  );
}

function DeliveryPensListView() {
  const dispatch = useDispatch();
  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const buttons = [
    {
      title: "Add New",
      isDisabled: false,
      onClick: () => {
        openDeliveryPenOwnerModal(null);
      },
      default: true,
      icon: faPlus,
      readOnly: !hasWriteAccessInCurrentSale,
    },

    {
      title: "Selling Pen View",
      isDisabled: false,
      onClick: () => {
        dispatch(setSetting(Settings.penView, PenTypes.SELLING));
      },
      default: false,
      icon: faCaretRight,
      iconColor: "transparent",
      dataTour: "auctionPenView",
    },

    {
      title: "Delivery Pen View",
      isDisabled: false,
      onClick: () => {
        dispatch(setSetting(Settings.penView, PenTypes.DELIVERY));
      },
      default: false,
      icon: faCaretRight,
      iconColor: "black",
      dataTour: "deliveryPenView",
      dividerAfter: true,
    },
  ].map(disableReadOnlyActions);

  return (
    <>
      <GlobalSearchHeader
        actionButton={<MultiButton buttons={buttons} />}
        searchSize={9}
        buttonSize={3}
      />
      <WaitForSync
        requiredData={[
          ApiModel.CONSIGNMENTS,
          ApiModel.DEPLOYMENTS,
          ApiModel.BUSINESSES,
          ApiModel.AUCTION_PENS,
          ApiModel.SALE_LOTS,
        ]}
      >
        <DeliveryPensList />
      </WaitForSync>
    </>
  );
}

export default DeliveryPensListView;
