import { cloneDeep, get, set } from "lodash";

import { BaseColumnDefinitions } from "./columnDefinitions";
import filterComparators from "./filterComparators";
import formatters from "./formatters";
import getters from "./getters";
import headers from "./headers";

export const getColumnState = (columnStates, columnDefinitions) => {
  // Central place to apply column state, to handle our hide logic and pinned not
  // being returned in getColumnState.
  if (!columnStates) {
    return columnDefinitions;
  }

  // We need to apply column states first so that we get the order maintained.
  const columns = columnStates
    .map(colState => {
      const colDef = columnDefinitions.find(
        c => c.field === colState.colId || c.colId === colState.colId,
      );

      if (colDef) {
        return {
          pinned: false,
          ...colDef,
          ...colState,
          // If the definition explicitly hides it, hide it.
          hide: colDef.hide || colState.hide,
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);

  // Hide all other columnDefinitions in the definition.
  const implicitlyHidden = columnDefinitions
    .filter(
      colDef =>
        !columns.find(
          c => c.field === colDef.colId || c.colId === colDef.colId,
        ),
    )
    .map(c => ({ ...c, hide: true }));

  return columns.concat(implicitlyHidden);
};

/** *
 * Intermediate utility function to facilitate re-using of existing column definitions
 * that are based mostly on flat objects with nested objects.
 *
 * Note, its important to just change the field and not the colId, as that will break savedViews/require a painful migration.
 *
 * Eg
 * Old: { EID: "1234"}
 * New: {scan : { EID: "1234"}}
 */
export const withNestedField = (columnName, path) => ({
  ...BaseColumnDefinitions[columnName],
  field: [path, BaseColumnDefinitions[columnName].field]
    .filter(Boolean)
    .join("."),
});

/*
Add namespacing to the field of a column definition.

Example:

With data like:
{ saleLot: vendor: email: { field: "email" } }

Call the function:
createNamespacedColumnDefintionFields(EmailColumnDefinition, "saleLot.vendor.email")

To produce:
[EmailColId.ATTACHMENTS]: {
...
field: "saleLot.vendor.email.attachments",
...
},
 */

const fieldsToNamespace = ["field", "cellRendererParams.businessKey"];
export const createNamespacedColumnDefinitions = (
  columnDefinitions,
  namespace,
  colIdNamespace = null,
) =>
  Object.values(columnDefinitions).reduce((acc, columnDefinition) => {
    const namespacedColumnDefinition = cloneDeep(columnDefinition);

    fieldsToNamespace.forEach(field => {
      const value = get(columnDefinition, field);
      if (value) {
        const nameSpaced = [namespace, value].filter(Boolean).join(".");
        set(namespacedColumnDefinition, field, nameSpaced);
      }
    });

    // Allows avoiding clashes if the same columns are used in different context - ie vendor property vs buyer property.
    if (colIdNamespace) {
      namespacedColumnDefinition.colId = [
        colIdNamespace,
        namespacedColumnDefinition.colId,
      ].join(".");
    }

    acc[columnDefinition.colId] = namespacedColumnDefinition;

    return acc;
  }, {});

export { filterComparators, formatters, getters, headers };

export const makeSelectable = columnDefinition => {
  return {
    ...columnDefinition,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    enableRowGroup: true,
    headerCheckboxSelectionFilteredOnly: true,
  };
};
