import queryString from "query-string";

import { WEIGHT_RANGE } from "constants/actionTypes";

import { serializeWeightRanges } from "lib/serializers/weightRanges";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/weight-ranges/", id, action),
    query: {},
  });

export const WeightRangesAction = offlineActionCreator(
  WEIGHT_RANGE,
  serializeWeightRanges,
  urlCreator,
  false,
);
