import React, { useCallback } from "react";

import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Paper,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Big from "big.js";
import { Formik, useFormikContext } from "formik";
import groupBy from "lodash/groupBy";
import omit from "lodash/omit";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { BusinessAction, importDeploymentSale } from "actions";

import { Button, DeleteButton } from "components/Form";
import { DeploymentSelectField } from "components/Form/Fields";
import { CheckBoxBase } from "components/Form/FormikControls";
import { BreedImporter } from "components/Importer/Breeds";
import { BusinessImporter } from "components/Importer/Businesses";
import { xmlBusinessColumns } from "components/Importer/columnDefinitions";
import { UploadIcon } from "components/Importer/components";
import { SessionStorageFileImportField } from "components/Importer/FileImportField";
import { ProductsImporter } from "components/Importer/Products";
import { suckFields } from "components/Importer/SG8/util";
import {
  tblBredFields,
  tblClientFields,
  tblClientFieldsSerializer,
  tblFeeGroup,
  tblRFIDFields,
  tblSaleFees,
  tblSaleTagsFields,
  tblSexFields,
  tblTranFields,
} from "components/Importer/SG8/xmlFieldConstants";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";
import { BigText } from "components/Text";

import { SG8_XML_FILENAME_KEY, SG8_XML_STORAGE_KEY } from "constants/importer";
import { ApiModel } from "constants/loading";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { PricingTypes } from "constants/pricingTypes";

import { ForMultipleDeploymentsInCurrentSale } from "containers/FeatureToggles";
import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { openConsignmentsTable } from "lib/navigation";
import toast from "lib/toast";

import {
  currentSaleSelector,
  getActiveRole,
  getCurrentDeploymentMasterBusinesses,
  getRounds,
  getSpeciesAttributes,
  selectAllAttributesByDeploymentIdLookup,
  selectBusinessIdBySaleyardCodeLookup,
  selectCurrentSpecies,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
} from "selectors";

import {
  useBoolean,
  useHasSaleyardOrDeploymentPermission,
  useToggle,
} from "hooks";

const ImportTab = {
  ACKNOWLEDGEMENT: 0,
  BUSINESSES: 1,
  BREEDS: 2,
  PRODUCTS: 3,
  OPTIONS: 4,
  IMPORT: 5,
};

export function SG8Importer() {
  const deployments = useSelector(getActiveRole)?.deployments;
  if (deployments) {
    return (
      <WaitForSync
        requiredData={[
          ApiModel.DEPLOYMENTS,
          ApiModel.AGENCIES,
          ApiModel.ROUNDS,
          // ApiModel.SPECIES,
        ]}
      >
        <Formik initialValues={{ deployment: deployments[0].id }}>
          <XMLImporter />
        </Formik>
      </WaitForSync>
    );
  }
}

const suckFees = root => {
  const feeGroups = [
    ...root.querySelectorAll("newRecord[TableName=tblFeeGroup]"),
  ].map(suckFields(tblFeeGroup));
  const feeGroupByNameLookup = groupBy(feeGroups, "strgFeeGroup");

  const fees = [...root.querySelectorAll("newRecord[TableName=tblSaleFees]")]
    .map(suckFields(tblSaleFees))
    .map(saleFee => {
      const description =
        feeGroupByNameLookup[saleFee.strgSaleFeesFeeGroup]?.[0]?.strgFeeDesc ||
        saleFee.strgSaleFeesFeeGroup;

      // Convert all fees to ex GST
      const { currSaleFeesAmount, currSaleFeesGST, currSaleFeesTotal } =
        saleFee;
      const total = new Big(currSaleFeesTotal);
      const taxAmount = new Big(currSaleFeesGST);
      let subtotal = new Big(currSaleFeesAmount);
      if (!subtotal.plus(taxAmount).eq(total)) {
        subtotal = subtotal.minus(taxAmount);
      }

      return {
        description,
        quantity: 1,
        // Fees that are positive amounts in LE are negative on the ledger, so multiply by -1.
        subtotal_cents: subtotal.times(100).times(-1).valueOf(),
        gst_cents: taxAmount.times(100).times(-1).valueOf(),
        total_cents: total.times(100).times(-1).valueOf(),
        // These will be transformed to a business id later.
        strgSaleFeesVendor: saleFee.strgSaleFeesVendor,
        strgSaleFeesBuyer: saleFee.strgSaleFeesBuyer,
        boolInclude: saleFee.boolInclude,
      };
    });

  return fees;
};

function XMLFileImportField({ setImportData }) {
  const processXml = useCallback(
    fileContents => {
      const parser = new DOMParser();
      const root = parser.parseFromString(fileContents, "application/xml");

      if (!root.querySelector("LEExport")) {
        toast.error(
          "Unsupported filetype - please select a SG8 XML file to import.",
        );
        return;
      }

      const sl = [...root.querySelectorAll("newRecord[TableName=tblTran]")].map(
        suckFields(tblTranFields),
      );
      // Find the products and sexes actually used that will require import.
      const actualUsed = {
        breeds: new Set(),
        products: new Set(),
      };
      sl.forEach(saleLot => {
        actualUsed.breeds.add(saleLot.strgLotBreed);
        actualUsed.products.add(saleLot.strgLotSex);
      });

      const businessesLookup = [
        ...root.querySelectorAll("newRecord[TableName=tblClient]"),
      ]
        .map(suckFields(tblClientFields))
        .map(tblClientFieldsSerializer)
        .reduce((acc, business) => {
          acc[business.businessShortCode] = business;
          return acc;
        }, {});

      const saleLots = [
        ...root.querySelectorAll("newRecord[TableName=tblTran]"),
      ].map(suckFields(tblTranFields));

      const consignments = [
        ...root.querySelectorAll("newRecord[TableName=tblSaleTags]"),
      ].map(suckFields(tblSaleTagsFields));

      // We don't have any assurance that the tblClient list has an entry for all entries in the
      // tblTran or tblSaleTags set, so push in the vendor/buyer codes to be mapped.
      saleLots.forEach(saleLot => {
        if (!businessesLookup[saleLot.strgLotBuyer]) {
          businessesLookup[saleLot.strgLotBuyer] = {
            businessShortCode: saleLot.strgLotBuyer,
            businessPIC: saleLot.strgLotBuyerPIC,
          };
        } else if (!businessesLookup[saleLot.strgLotBuyer].businessPIC) {
          // see if the client entry has a PIC - add it if it doesn't, add it.
          businessesLookup[saleLot.strgLotBuyer].businessPIC =
            saleLot.strgLotBuyerPIC;
        }
      });
      consignments.forEach(consignment => {
        if (!businessesLookup[consignment.strgVendorCode]) {
          businessesLookup[consignment.strgVendorCode] = {
            businessShortCode: consignment.strgVendorCode,
            businessPIC: consignment.strgVenPIC,
          };
        } else if (!businessesLookup[consignment.strgVendorCode].businessPIC) {
          businessesLookup[consignment.strgVendorCode].businessPIC =
            consignment.strgVenPIC;
        }
      });

      const businesses = Object.values(businessesLookup);

      setImportData({
        sale_round: root.querySelector("SaleCategory").textContent,
        species: root.querySelector("newRecord[TableName=tblAT] strgATDesc")
          .textContent,
        breeds: [...root.querySelectorAll("newRecord[TableName=tblBred]")]
          .map(suckFields(tblBredFields))
          .filter(record => actualUsed.breeds.has(record.strgBreedCode)),
        products: [...root.querySelectorAll("newRecord[TableName=tblSex]")]
          .map(suckFields(tblSexFields))
          .filter(record => actualUsed.products.has(record.strgSexCode)),
        businesses,
        saleLots,
        consignments,

        scans: [...root.querySelectorAll("newRecord[TableName=tblRFID]")].map(
          suckFields(tblRFIDFields),
        ),
        fees: suckFees(root),
      });
    },
    [setImportData],
  );

  return (
    <SessionStorageFileImportField
      accept={["text/xml", "application/xml"]}
      storageKey={SG8_XML_STORAGE_KEY}
      storageFilenameKey={SG8_XML_FILENAME_KEY}
      processFile={processXml}
      fileReadyToast="The following businesses need to be created. Please double check before proceeding with the import."
      fileDescription="XML"
    />
  );
}

function useGetRound(importSpeciesName, importSaleRoundName) {
  const rounds = useSelector(getRounds);
  const currentSpecies = useSelector(selectCurrentSpecies);
  return React.useMemo(() => {
    const importSpecies = currentSpecies.find(
      s => s.name.toLowerCase() === importSpeciesName?.toLowerCase(),
    );
    if (!importSpecies) {
      return null;
    }
    const filteredRounds = Object.values(rounds).filter(
      r => r.species_id === importSpecies.id,
    );
    const existingRound = filteredRounds.find(
      r => r.name === importSaleRoundName,
    );
    if (existingRound) {
      return existingRound;
    } else {
      // If there is no round in agrinous with the specified round,
      // use the species name as the round.
      return filteredRounds.find(
        r => r.name.toLowerCase() === importSpeciesName.toLowerCase(),
      );
    }
  }, [rounds, currentSpecies, importSpeciesName, importSaleRoundName]);
}

function XMLImporter() {
  const [importData, setImportData] = React.useState({
    businesses: [],
    breeds: [],
    products: [],
    species: null,
  });

  const currentSpecies = useSelector(selectCurrentSpecies);
  const currentSale = useSelector(currentSaleSelector);

  const importSpecies = currentSpecies.find(
    s => s.name.toLowerCase() === importData.species?.toLowerCase(),
  );

  const importRound = useGetRound(importData.species, importData.sale_round);

  if (importData.businesses.length === 0) {
    return <XMLFileImportField setImportData={setImportData} />;
  }

  if (importSpecies && currentSale.species_id !== importSpecies?.id) {
    const currentSaleSpeciesName = currentSpecies.find(
      s => s.id === currentSale.species_id,
    ).name;
    return (
      <>
        <XMLFileImportField setImportData={setImportData} />
        <MessageBox>
          <BigText color="error">
            The selected file contains a {importSpecies?.name} sale, but the
            currently selected sale is a {currentSaleSpeciesName} sale. The
            species must match for the import to be successful.
          </BigText>
        </MessageBox>
      </>
    );
  }

  if (!importRound) {
    return (
      <>
        <XMLFileImportField setImportData={setImportData} />
        <MessageBox>
          <BigText color="error">
            The selected file cannot be mapped to an AgriNous round. The round
            specified in the file is &quot;{importData.sale_round}&quot;.
            Attempting to fallback to the species round &quot;
            {importData.species}&quot; also failed.
          </BigText>
        </MessageBox>
      </>
    );
  }

  return (
    <Component
      importData={importData}
      setImportData={setImportData}
      importSpeciesId={importSpecies.id}
      currentSale={currentSale}
    />
  );
}

function Component({
  importData,
  setImportData,
  importSpeciesId,
  currentSale,
}) {
  const dispatch = useDispatch();

  React.useEffect(
    // Make sure we have the full set of businesses loaded for the saleyard.
    () => {
      dispatch(BusinessAction.request());
    },
    [dispatch],
  );

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isDataLossAcknowledged, setDataLossAcknowledged] = useBoolean();
  const [includeBilling, setIncludeBilling] = useToggle(false);

  const [importerRunning, setImporterRunning] = useBoolean();

  const formik = useFormikContext();

  const hasBillingRunFeaturePermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureBilling,
    SaleyardPermissions.featureBilling,
  );

  // Figure out the actual sale round id from the sale round.
  const saleRound = useGetRound(importData.species, importData.sale_round);

  const { deployment } = formik.values;

  const agenciesBusiness = useSelector(getCurrentDeploymentMasterBusinesses)[
    deployment
  ];

  const deploymentAttributeData =
    useSelector(selectAllAttributesByDeploymentIdLookup)?.[deployment] || {};
  const speciesAttributes = useSelector(getSpeciesAttributes);
  const agrinousBreeds =
    deploymentAttributeData.breeds
      ?.map(db => ({
        ...speciesAttributes.breeds[db.breed_id],
        ...db,
      }))
      .filter(db => db.species_id === importSpeciesId) || [];
  const agrinousProducts =
    deploymentAttributeData.products?.filter(
      dp =>
        dp.species_id === importSpeciesId &&
        // If the product has a sale round, make sure it matches.
        (dp.sale_round ? dp.sale_round === saleRound?.id : true),
    ) || [];

  const onCellValueChanged = agrinousData => e => {
    // Fired when a option is selected to merge in the selected attributes data into the rowdata.
    const data = agrinousData.find(b => b.id === e.newValue.value);
    e.node.setData({
      ...e.node.data,
      ...data,
      name: {
        label: data?.name,
        value: data?.id,
      },
    });
    e.api.redrawRows(e.node);
  };

  const businessIdBySaleyardCode = useSelector(
    selectBusinessIdBySaleyardCodeLookup,
  );

  const businessByIdLookup = useSelector(
    selectPropertyEnrichedBusinessByBusinessIdLookup,
  );

  const breedBySaleyardCode = agrinousBreeds.reduce((acc, agrinousBreed) => {
    acc[agrinousBreed.saleyard_code] = agrinousBreed;
    return acc;
  }, {});

  const productBySaleyardCode = agrinousProducts.reduce(
    (acc, agrinousProduct) => {
      acc[agrinousProduct.saleyard_code] = agrinousProduct;
      return acc;
    },
    {},
  );

  const handleImportSale = () => {
    setImporterRunning();
    const transformConsignment = c => {
      const vendorId = businessIdBySaleyardCode[c.strgVendorCode];

      if (!vendorId) {
        return null;
      }
      const vendor = businessByIdLookup[vendorId];

      return {
        id: c.autoSaleTags,
        NVD: c.strgVenDecNo,
        vendor_id: vendor?.id,
        consigned_from_id: vendor?.defaultConsigningDeploymentId || null,
        vendor_property: c.strgVenPIC,
        vendor_number: c.intVendorNo,
        has_arrived: !!c.dateTimeArrival,
        quantity: c.intTotal,
        quantity_NVD: c.intNVDTotal || null,
        declaration: {
          version: c.NVDVersionNo,
          // THis is a bit of a bitch here because the xml has Q1 and we want to get owned_since_birth
        },
      };
    };

    const transformSaleLot = s => {
      const product = productBySaleyardCode[s.strgLotSex];
      const breed = breedBySaleyardCode[s.strgLotBreed];
      const buyer_id = businessIdBySaleyardCode[s.strgLotBuyer];
      const totalPriceCents = (+s.currLotDollar * 100).toFixed(2);
      const totalGstCents = (+s.currLotGST * 100).toFixed(2);

      const pricingTypeId =
        s.boolLotOpenAuction.toLowerCase() === "true"
          ? PricingTypes.PER_HEAD
          : PricingTypes.PER_KILO;

      return {
        buyer_id,
        buyer_way: s.strgLotBuyerAccount,
        destination_property: s.strgLotBuyerPIC,
        quantity: s.intLotHead,
        quantity_delivered: s.intLotHead,
        marks: [s.strgLotPaint],
        total_price_cents: totalPriceCents,
        auction_pen: s.strgLotPen,
        consignment_id:
          s.autoSaleTags !== "" ? s.autoSaleTags : s.longSaleTagsID,
        id: s.autoTran,
        pricing_type_id: pricingTypeId,
        age_id: product.age_id,
        sex_id: product.sex_id,
        breed_id: breed?.breed_id || undefined,
        sale_round: saleRound.name,
        total_mass_grams: Math.round(+s.dblLotWeight * 1000) || 0,
        total_gst_cents: totalGstCents,
        cbid: uuidv4(),
      };
    };

    const transformScan = s => ({
      sale_lot_id: s.longTranID,
      eid: s.strgRFID,
    });

    const consignments =
      importData.consignments?.map(transformConsignment).filter(Boolean) || [];
    const saleLots = importData.saleLots?.map(transformSaleLot) || [];

    const scans = importData.scans?.map(transformScan) || [];
    const saleLotsByConsignment = groupBy(saleLots, "consignment_id");
    const scansBySaleLot = groupBy(scans, "sale_lot_id");
    // Perform a group by on these

    // Join the data together and omit the bits of data used to do the joining.
    const consignmentPayload = consignments?.map(c => {
      const saleLots = saleLotsByConsignment[c.id]?.map(s => {
        return omit(
          {
            ...s,
            scans: scansBySaleLot[s.id]?.map(s => s.eid) || [],
          },
          ["id", "consignment_id"],
        );
      });

      return omit(
        {
          ...c,
          sale_lots: saleLots,
        },
        "id",
      );
    });

    let billing_data;
    if (hasBillingRunFeaturePermission && includeBilling) {
      // Create 3 sets of ledger data
      // 1. the fees (eg yard fees) as paid by the vendor from the LE ledger entries
      // 2. the proceeds for a vendor, based on the price of the stock sold
      // 3. the charges for a buyer, based on the price of the stock sold

      const feesPayload = importData.fees?.map(fee => {
        if (fee.strgSaleFeesVendor) {
          fee.from_business_id =
            businessIdBySaleyardCode[fee.strgSaleFeesVendor];
          fee.to_business_id = agenciesBusiness.id;
        }
        return fee;
      });

      const transactionPayload = consignmentPayload.reduce(
        (acc, consignment) => {
          const vendorId = consignment.vendor_id;

          consignment.sale_lots?.forEach(saleLot => {
            const {
              buyer_id,
              total_price_cents,
              quantity,
              total_gst_cents,
              cbid,
            } = saleLot;

            const baseTransaction = {
              subtotal_cents: total_price_cents - total_gst_cents,
              gst_cents: total_gst_cents,
              total_cents: total_price_cents,
              quantity,
              sale_lot_id: cbid,
            };

            // Push the buyer transaction.
            acc.push({
              from_business_id: vendorId,
              to_business_id: agenciesBusiness.id,
              ...baseTransaction,
            });
            //   Push the vendor transaction.
            acc.push({
              to_business_id: buyer_id,
              from_business_id: agenciesBusiness.id,
              ...baseTransaction,
            });
          });
          return acc;
        },
        [],
      );

      billing_data = { ledger_entries: transactionPayload.concat(feesPayload) };
    }

    const payload = {
      consignments: consignmentPayload,
      deployment,
      livestock_sale: currentSale.livestocksale_id,
      manual_vendor_numbers: true,
      billing_data,
    };
    dispatch(
      importDeploymentSale(
        payload,
        currentSale.deployment_sales.find(ds => ds.deployment_id === deployment)
          .deployment_sale_id,
      ),
    );
  };

  const getProgress = (mapped, total) => `${mapped} / ${total}`;
  const mappedCounts = {
    totalBusinesses: importData.businesses.length,
    mappedBusinesses: importData.businesses.filter(
      importBusiness =>
        businessByIdLookup?.[
          businessIdBySaleyardCode?.[importBusiness.businessShortCode]
        ],
    ).length,

    totalBreeds: importData.breeds.length,
    mappedBreeds: importData.breeds.filter(
      importBreed => breedBySaleyardCode?.[importBreed.strgBreedCode],
    ).length,

    totalProducts: importData.products.length,
    mappedProducts: importData.products.filter(
      importProduct => productBySaleyardCode?.[importProduct.strgSexCode],
    ).length,
  };
  const completed = {
    businesses: mappedCounts.totalBusinesses === mappedCounts.mappedBusinesses,
    breeds: mappedCounts.totalBreeds === mappedCounts.mappedBreeds,
    products: mappedCounts.totalProducts === mappedCounts.mappedProducts,
  };

  const importEnabled =
    Object.values(completed).every(Boolean) && isDataLossAcknowledged;

  const selectTab = tabIndex => {
    if (isDataLossAcknowledged) {
      setSelectedTab(tabIndex);
    }
  };

  return (
    <>
      <ForMultipleDeploymentsInCurrentSale>
        <Paper square>
          <DeploymentSelectField
            label="Deployment"
            name="deployment"
            restrictToOwnDeployments
          />
        </Paper>
      </ForMultipleDeploymentsInCurrentSale>
      <Paper square>
        <XMLFileImportField setImportData={setImportData} />

        <Stepper nonLinear activeStep={selectedTab} alternativeLabel>
          <Step key={ImportTab.ACKNOWLEDGEMENT}>
            <StepButton completed={isDataLossAcknowledged}>
              Acknowledgement
            </StepButton>
          </Step>
          <Step key={ImportTab.BUSINESSES} expanded err>
            <StepButton
              optional={getProgress(
                mappedCounts.mappedBusinesses,
                mappedCounts.totalBusinesses,
              )}
              onClick={() => selectTab(ImportTab.BUSINESSES)}
              completed={completed.businesses}
            >
              Map Businesses
            </StepButton>
          </Step>
          <Step key={ImportTab.BREEDS}>
            <StepButton
              optional={getProgress(
                mappedCounts.mappedBreeds,
                mappedCounts.totalBreeds,
              )}
              onClick={() => selectTab(ImportTab.BREEDS)}
              completed={completed.breeds}
            >
              Map Breeds
            </StepButton>
          </Step>
          <Step key={ImportTab.PRODUCTS}>
            <StepButton
              optional={getProgress(
                mappedCounts.mappedProducts,
                mappedCounts.totalProducts,
              )}
              onClick={() => selectTab(ImportTab.PRODUCTS)}
              completed={completed.products}
            >
              Map Products
            </StepButton>
          </Step>
          {hasBillingRunFeaturePermission && (
            <Step key={ImportTab.OPTIONS}>
              <StepButton
                onClick={() => selectTab(ImportTab.OPTIONS)}
                optional
                completed
              >
                Extra Options
              </StepButton>
            </Step>
          )}
          <Step key={ImportTab.IMPORT}>
            {importEnabled ? (
              <StepButton
                icon={
                  importerRunning ? (
                    <CircularProgress />
                  ) : (
                    <UploadIcon icon={faCloudUploadAlt} />
                  )
                }
                onClick={importerRunning ? null : handleImportSale}
              >
                Import to &quot;{saleRound.name}&quot; round.
              </StepButton>
            ) : (
              <StepLabel error optional="Complete Mapping">
                Import to &quot;{saleRound.name}&quot; round.
              </StepLabel>
            )}
          </Step>
        </Stepper>
      </Paper>
      {selectedTab === ImportTab.ACKNOWLEDGEMENT && (
        <Container>
          <Card>
            <CardContent>
              <h2>Remove and replace all content in this Sale</h2>
              <p>
                Importing a Sale will replace all existing information in the
                Sale including:
              </p>
              <ul>
                <li>Consignments</li>
                <li>Media and Attachments (including NVDs)</li>
                <li>Sale Lots</li>
                <li>Scans</li>
              </ul>
              <CardActions>
                <Button onClick={() => openConsignmentsTable()}>
                  Return to <TranslatedSaleTypeLabel label="Consignments" />
                </Button>
                <DeleteButton
                  onClick={() => {
                    setDataLossAcknowledged();
                    setSelectedTab(ImportTab.BUSINESSES);
                  }}
                >
                  Progress to Import
                </DeleteButton>
              </CardActions>
            </CardContent>
          </Card>
        </Container>
      )}
      {selectedTab === ImportTab.BUSINESSES && (
        <BusinessImporter
          importBusinesses={importData.businesses}
          columns={xmlBusinessColumns}
          agrinousBusinessLookupSelector={selectBusinessIdBySaleyardCodeLookup}
          lookupKey="businessShortCode"
        />
      )}
      {selectedTab === ImportTab.BREEDS && (
        <BreedImporter
          importBreeds={importData.breeds}
          agrinousBreeds={agrinousBreeds}
          onCellValueChanged={onCellValueChanged}
        />
      )}
      {selectedTab === ImportTab.PRODUCTS && (
        <ProductsImporter
          importProducts={importData.products}
          agrinousProducts={agrinousProducts}
          onCellValueChanged={onCellValueChanged}
        />
      )}
      {selectedTab === ImportTab.OPTIONS && (
        <Container>
          <Card>
            <CardContent>
              <CheckBoxBase
                label="Create Billing Data"
                onChange={setIncludeBilling}
                value={includeBilling}
              />
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
}
