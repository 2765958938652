import React from "react";

import { faPhone } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DialogContent, Grid } from "@material-ui/core";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import { Button } from "components/Form";
import { Column, Row } from "components/Layout";
import { Dialog, DialogActions, DialogTitle } from "components/MaterialDialog";

export const ShareModal = ({ onClose, messageTitle, messageContent }) => (
  <Dialog open onClose={onClose}>
    <DialogTitle onClose={onClose}>Share NLIS Receipt</DialogTitle>
    <DialogContent dividers>
      <Grid container direction="column">
        <AnchorStyled
          data-tour="shareViaEmailLink"
          href={`mailto:?subject=${encodeURI(messageTitle)}&body=${encodeURI(
            messageContent,
          )}`}
        >
          <ButtonStyled>Share via Email</ButtonStyled>
        </AnchorStyled>
      </Grid>
    </DialogContent>

    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

ShareModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export const HelpModal = ({ onClose }) => (
  <Dialog open onClose={onClose}>
    <DialogTitle onClose={onClose}>Get Help</DialogTitle>
    <DialogContent dividers>
      <Grid container direction="column">
        <HelpModalButton number="1800 654 743" name="NLIS Helpdesk" />
        <HelpModalButton number="1800 678 779" name="Vic Gov NLIS Line" />
        <HelpModalButton number="0480 039 299" name="AgriNous Helpdesk" />
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

HelpModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const HelpModalButton = ({ number, name }) => (
  <AnchorStyled data-tour={name} href={`tel:${number}`}>
    <ButtonStyled>
      <ButtonRow justifyBetween alignCenter fullWidth>
        <Column>
          <Top>Call the {name}</Top>
          <Bottom>{number}</Bottom>
        </Column>
        <TelephoneIcon />
      </ButtonRow>
    </ButtonStyled>
  </AnchorStyled>
);

const AnchorStyled = styled.a`
  flex: 1;
  text-decoration: none;
`;

const ButtonRow = styled(Row)`
  text-align: left;
`;

const Top = styled.span`
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const Bottom = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
  line-height: ${({ theme }) => theme.fontSizes.alpha}px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

const TelephoneIcon = styled(FontAwesomeIcon).attrs({
  icon: faPhone,
})`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[2]}px;
  margin-bottom: ${({ theme }) => theme.space[2]}px;
`;
