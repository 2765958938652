import { createSelector } from "reselect";

import { BillingDocumentType } from "constants/billingDocuments";
import { BillingLedgerEntryStatus } from "constants/billingLedgerEntries";

import {
  getLedgerEntries,
  selectBillingDocumentsAggridDataById,
} from "selectors";

const getCreditAndDebitAmount = (ledgerEntry, billingDocument = {}) => {
  // Categorise the ledger entry as a debit or credit (From the point of view of the LivestockAgent)
  // based on the billing document type and total amount.
  const { subtotal, taxAmount } = ledgerEntry;
  const total = parseFloat(subtotal) + parseFloat(taxAmount);
  let debitAmount = 0;
  let creditAmount = 0;

  if (billingDocument.type === BillingDocumentType.INVOICE) {
    // If it's an invoice (to a buyer) with a positive amount it's a credit.
    if (total > 0) {
      creditAmount = total;
    } else {
      debitAmount = Math.abs(total);
    }
  } else if (billingDocument.type === BillingDocumentType.RCTI) {
    // If it's an RCTI (from a vendor) with a negative amount it's a credit.
    if (total > 0) {
      debitAmount = total;
    } else {
      creditAmount = Math.abs(total);
    }
  }
  return { creditAmount, debitAmount };
};

export const selectLedgerEntryData = createSelector(
  [getLedgerEntries, selectBillingDocumentsAggridDataById],

  (ledgerEntryByIdLookup, billingDocumentDataByIdLookup) =>
    Object.values(ledgerEntryByIdLookup).map(ledgerEntry => {
      const { billingDocument, business, commission } =
        billingDocumentDataByIdLookup[ledgerEntry.documentId] || {};

      const creditAndDebitAmount = getCreditAndDebitAmount(
        ledgerEntry,
        billingDocument,
      );

      return {
        commission,
        billingDocument,
        business,
        ledgerEntry: Object.assign({}, ledgerEntry, creditAndDebitAmount),
      };
    }),
);

export const selectCommittedLedgerEntryData = createSelector(
  [selectLedgerEntryData],
  ledgerEntryData =>
    ledgerEntryData.filter(
      ({ ledgerEntry }) =>
        ledgerEntry.status === BillingLedgerEntryStatus.COMMITTED,
    ),
);
