import { put, takeEvery, call, all } from "redux-saga/effects";

import { requestProductsSuccess, requestProductsFailure } from "actions";

import { GET_PRODUCTS } from "constants/actionTypes";

import { api } from "./api";

function* fetchProducts(action) {
  try {
    const [products] = yield all([yield call(api.get, "/v2/products/")]);
    yield put(requestProductsSuccess(products));
  } catch (e) {
    yield call(api.handleFetchError, e, "products", action);
    // If the request was cancelled we get a typeerror.
    if (!(e instanceof TypeError) && (!e.status || e.status !== 401)) {
      yield put(requestProductsFailure());
    }
  }
}

function* rootSaga() {
  yield takeEvery(GET_PRODUCTS.REQUEST, fetchProducts);
}

export default rootSaga;
