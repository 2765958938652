import React, { useEffect } from "react";

import { DialogActions, DialogContent } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import { sum, uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  ScanAction,
  closeConfirmModal,
  openConfirmModal,
  patchScan,
} from "actions";

import { Warning as ErrorMessage } from "components/ErrorMessage";
import { Button, SecondaryButton } from "components/Form";
import { CheckBox, Input } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";

import { SaleyardPermissions } from "constants/permissions";

import { getLivestockSaleId } from "lib/navigation";

import {
  getCurrentSaleyard,
  getScanByEid,
  getScans,
  getWeighLotById,
  getWeighLotScansByWeighLotId,
  selectSaleLotByEidLookup,
  selectWeighLotIdsByEidLookup,
} from "selectors";

import { useHasPermission } from "hooks";

const Warning = styled(ErrorMessage)`
  padding: 0px;
`;

const SingleWeightForm = ({ eids, onBack }) => {
  const scan = useSelector(getScanByEid(eids[0]));
  const initialValues = {
    total_mass_grams: scan.total_mass_grams,
    is_manually_weighed: scan.is_manually_weighed,
  };

  const dispatch = useDispatch();

  const submit = scanPatch => {
    dispatch(
      patchScan(scan, { ...scanPatch, recalculate_salelot_weight: true }),
    );
    onBack();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submit}>
      <Form>
        <InnerFields />
        <DialogActions shrink={0}>
          <SecondaryButton type="button" onClick={onBack}>
            Cancel
          </SecondaryButton>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Form>
    </Formik>
  );
};

const MultiWeightForm = ({ eids, onBack }) => {
  const scanByEidLookup = useSelector(getScans);
  const totalWeight = sum(
    eids.map(eid => scanByEidLookup[eid].total_mass_grams),
  );

  const areAnyManuallyWeighed = eids
    .map(eid => scanByEidLookup[eid].is_manually_weighed)
    .some(Boolean);

  const initialValues = {
    totalWeight,
    is_manually_weighed: areAnyManuallyWeighed,
  };
  const dispatch = useDispatch();

  const hasWeighLotPermissions = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featureWeighLots,
  );

  const weighLotIdsByEidLookup = useSelector(selectWeighLotIdsByEidLookup);
  const saleLotByEidLookup = useSelector(selectSaleLotByEidLookup);

  const weighLotIds = uniq(eids.map(eid => weighLotIdsByEidLookup[eid]).flat());

  const hasSelectedFromMultipleWeighLots = weighLotIds.length > 1;

  const hasSelectedFromMultipleSaleLots =
    uniq(eids.map(eid => saleLotByEidLookup[eid])).length > 1;

  const firstWeighLot = useSelector(getWeighLotById(weighLotIds[0]));

  const weighLotScansFromFirstWeighLot =
    useSelector(getWeighLotScansByWeighLotId(weighLotIds[0])) || [];

  const hasSelectedAllWeighLotScansInTheWeighLot =
    weighLotScansFromFirstWeighLot.length > 0 &&
    weighLotScansFromFirstWeighLot.length === eids.length;

  let warning = null;

  if (hasWeighLotPermissions) {
    if (hasSelectedFromMultipleWeighLots) {
      warning = "The selected Scans are from multiple weigh lots";
    } else if (hasSelectedFromMultipleSaleLots) {
      warning = "The selected Scans are from multiple sale lots";
    } else if (!hasSelectedAllWeighLotScansInTheWeighLot) {
      warning = `You are only updating a portion of Scans from Weigh Lot ${firstWeighLot.scaleName} ${firstWeighLot.lotNumber}`;
    }
  }

  const submit = scanPatch => {
    const { totalWeight, is_manually_weighed } = scanPatch;
    const scans = eids.map(eid => scanByEidLookup[eid]);
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: `Are you sure you want to update the Scan weights to ${Math.round(
          totalWeight / scans.length / 1000,
        )} kg each?  ${warning ? `\n \n Note: ${warning}` : ``}`,
        actions: [
          {
            label: "No",
            secondary: true,
            onClick: () => {
              dispatch(closeConfirmModal());
              onBack();
            },
          },
          {
            label: "Yes",
            onClick: () => {
              dispatch(
                // divide the total weight between all the scans selected
                ScanAction.create(
                  scans.map(scan => ({
                    id: scan.id,
                    EID: scan.EID,
                    total_mass_grams: Math.round(totalWeight / scans.length),
                    livestock_sale_id: getLivestockSaleId(),
                    is_manually_weighed,
                    recalculate_salelot_weight: true,
                  })),
                ),
              );
              dispatch(closeConfirmModal());
              onBack();
            },
          },
        ],
      }),
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submit}>
      <Form>
        <p className="mb-12">
          The weight input in this form will be averaged across all of the
          selected Scans. This will overwrite the weight currently stored on the
          Scans.
        </p>
        <MultiInnerFields warning={warning} />
        <DialogActions shrink={0}>
          <SecondaryButton type="button" onClick={onBack}>
            Cancel
          </SecondaryButton>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Form>
    </Formik>
  );
};

export const EditScanWeight = values => {
  const { onBack, eids } = values;

  return (
    <DialogContent dividers>
      {eids.length > 1 ? (
        <MultiWeightForm eids={eids} onBack={onBack} />
      ) : (
        <SingleWeightForm eids={eids} onBack={onBack} />
      )}
    </DialogContent>
  );
};

const InnerFields = () => {
  const formikProps = useFormikContext();
  const { values, initialValues, setFieldValue } = formikProps;
  const { total_mass_grams: totalMassGrams } = values;
  const { total_mass_grams: currentTotalMassGrams } = initialValues;

  useEffect(() => {
    // When a scan already has a weight, and the user changes the weigh value, mark it as manually weighed.
    if (totalMassGrams && currentTotalMassGrams !== totalMassGrams) {
      setFieldValue("is_manually_weighed", true);
    }
  }, [totalMassGrams, currentTotalMassGrams, setFieldValue]);

  return (
    <Column>
      <Input
        label="Weight (kg)"
        name="total_mass_grams"
        type="number"
        multiplier={1000}
        afterSymbol="kg"
        decimal
        autoFocus
      />
      <Row justifyEnd>
        <CheckBox name="is_manually_weighed" label="Manual Weighed?" />
      </Row>
    </Column>
  );
};

const MultiInnerFields = ({ warning }) => {
  const formikProps = useFormikContext();
  const { values, initialValues, setFieldValue } = formikProps;
  const { totalWeight: newTotalWeight } = values;
  const { totalWeight: currentTotalWeight } = initialValues;

  useEffect(() => {
    // When a scan already has a weight, and the user changes the weigh value, mark it as manually weighed.
    if (newTotalWeight && currentTotalWeight !== newTotalWeight) {
      setFieldValue("is_manually_weighed", true);
    }
  }, [newTotalWeight, currentTotalWeight, setFieldValue]);

  return (
    <Column>
      <Input
        label="Weight (kg)"
        name="totalWeight"
        type="number"
        multiplier={1000}
        afterSymbol="kg"
        decimal
        autoFocus
      />
      {warning && <Warning>{warning}</Warning>}
      <Row justifyEnd>
        <CheckBox name="is_manually_weighed" label="Manual Weighed?" />
      </Row>
    </Column>
  );
};
