import React from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "components/Form";

import { openInlineCreateSaleLotModal } from "lib/navigation";

export const AddSaleLotButton = ({
  initialValues = {},
  options = {},
  returnTo = "",
}) => {
  const openCreateSaleLotModal = () => {
    openInlineCreateSaleLotModal(initialValues, options, returnTo);
  };

  return (
    <Button type="button" onClick={openCreateSaleLotModal} fullWidth inLine>
      <FontAwesomeIcon icon={faPlus} /> Sale Lot
    </Button>
  );
};
