import React from "react";

import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

import {
  bulkCreateP2pTransfer,
  P2PFileAction,
  P2PReversalAction,
  requestScans,
} from "actions";

import TableWrapper from "components/AgGrid/TableWrapper";
import { SlimButton } from "components/Button";
import { Action, ActionIcon } from "components/Button/Actions";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { TRANSFER_STATUS } from "constants/nlis";

import { NLISFileGrid } from "containers/NLISFiles/NLISFileGrid";
import { columnState } from "containers/NLISFiles/P2P/columnState";
import { ConfirmTransfer } from "containers/NLISFiles/P2P/ConfirmTransfer";

import { pluralize } from "lib/pluralize";

import {
  currentSaleSelector,
  getIsFetchingNLISInformation,
  getScansForP2PTransfer,
  selectP2PTransactions,
} from "selectors";

import { useDebounceSelector, useMountEffect, useToggle } from "hooks";

export const NLISP2PFileInfo = () => {
  const dispatch = useDispatch();

  const sale = useSelector(currentSaleSelector);
  const isFetching = useSelector(getIsFetchingNLISInformation);
  const rowData = useSelector(selectP2PTransactions);
  const [showConfirm, toggleShowConfirm] = useToggle(false);
  const handleRefresh = React.useCallback(() => {
    if (!isFetching) {
      dispatch(P2PFileAction.request());
      dispatch(P2PReversalAction.request());
      dispatch(requestScans(sale));
    }
  }, [isFetching, sale, dispatch]);

  useMountEffect(() => {
    // If we have no rows on mount, refresh from the backend.
    // (If we run this when rowData changes, it will continually refresh if there is actually no data.)
    if (rowData.length === 0) {
      handleRefresh();
    }
  });

  const transferableScans = useDebounceSelector(getScansForP2PTransfer);

  // If there are any unsubmitted transactions, don't allow the user to action any more.
  const allowAction = !rowData.some(
    nlisTransaction => nlisTransaction.status === TRANSFER_STATUS.UNSUBMITTED,
  );

  const handleTransfer = authority => {
    dispatch(
      bulkCreateP2pTransfer(
        transferableScans.map(scanRow => scanRow.scan.EID),
        authority,
      ),
    );
    toggleShowConfirm();
  };

  const AlertAction = (
    <SlimButton data-tour="transferNow" onClick={toggleShowConfirm}>
      Transfer Now
    </SlimButton>
  );

  const transferText = `There are  ${
    transferableScans.length
  } transferable ${pluralize("scan", transferableScans.length)}`;

  return (
    <WaitForSync requiredData={[ApiModel.NLIS_P2P_FILES, ApiModel.SCANS]}>
      <Row flexGrow justifyBetween>
        <Column fullWidth padding={2}>
          {allowAction && transferableScans.length > 0 && (
            <Grid item xs={12}>
              <Alert severity="error" action={AlertAction}>
                {transferText}
              </Alert>
            </Grid>
          )}
        </Column>

        <Action onClick={handleRefresh} disabled={isFetching}>
          <ActionIcon icon={faRedo} />
          Refresh
        </Action>
      </Row>

      <Column fullHeight>
        <TableWrapper>
          <NLISFileGrid rowData={rowData} columnState={columnState} />
        </TableWrapper>
      </Column>
      {showConfirm && (
        <ConfirmTransfer
          toggleShowConfirm={toggleShowConfirm}
          handleTransfer={handleTransfer}
        />
      )}
    </WaitForSync>
  );
};
