import React from "react";

import { DialogActions } from "@material-ui/core";
import { uniq } from "lodash";
import groupBy from "lodash/groupBy";
import { startCase } from "lodash/string";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { LedgerEntryAction } from "actions";

import Badge from "components/Badge";
import { SlimButton } from "components/Button";
import { Button } from "components/Form";
import { Column } from "components/Layout";
import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";

import { AuditLogTypes } from "constants/auditLog";
import { BillingLedgerEntryStatus } from "constants/billingLedgerEntries";

import { openAuditLogModal } from "lib/navigation";

import {
  getBillingDocumentsAggridDataById,
  getBillingTags,
  getLedgerEntriesByDocumentId,
} from "selectors";

const Table = styled.table(
  ({ theme }) => `
  border: 1px solid black;
  width: 800px;

  .highlight {
    background: ${theme.colors.primaryHighlight}50;
  }
  border-collapse: collapse;
  td, th {
    padding: 4px;
    border: 1px solid ${theme.colors.controlBorder};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 0px;
    text-align: left;
  }
`,
);

const fieldHandlers = {
  category: {
    valueGetter: v => v,
  },
  description: {
    valueGetter: v => v,
  },
  ruleName: {
    valueGetter: v => v,
  },
  glCode: {
    valueGetter: v => v,
  },

  includeIfZero: {
    valueGetter: v => (v ? "Yes" : "No"),
  },
  invoiceCategory: {
    valueGetter: v => v,
  },

  isModified: {
    valueGetter: v => (v ? "Yes" : "No"),
  },
  notes: {
    valueGetter: v => v,
  },
  quantity: {
    valueGetter: v =>
      (+v).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      }),
  },
  subtotal: {
    valueGetter: v =>
      `$${(+v).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })}`,
  },
  taxAmount: {
    valueGetter: v =>
      `$${(+v / 100).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })}`,
  },
  taxType: {
    valueGetter: v => v,
  },
  unitAmount: {
    valueGetter: v =>
      `$${(+v / 100).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })}`,
  },
  tags: {
    valueGetter: v => v.join(", "),
  },

  documentId: {
    skip: true,
  },
  id: {
    skip: true,
  },
  mincenousId: {
    skip: true,
  },
  itemId: {
    skip: true,
  },
  itemType: {
    skip: true,
  },
  status: {
    skip: true,
  },
  tagIds: {
    skip: true,
  },
};

const DuplicateLedgerEntriesTable = ({
  ledgerEntries,
  committedLedgerEntryIdForHistory,
}) => {
  const billingTags = useSelector(getBillingTags);

  const ledgerEntriesWithTags = ledgerEntries.map(d => {
    return {
      ...d.ledgerEntry,
      tags: d.ledgerEntry.tagIds.map(tagId => billingTags[tagId].name),
    };
  });
  const keys = Object.keys(ledgerEntriesWithTags[0]);
  const dispatch = useDispatch();

  function handleDeduplicate(id) {
    dispatch(
      LedgerEntryAction.deduplicate(id, {
        changeReason: "Duplicates resolved from Ledger Entries Table",
      }),
    );
  }

  const isInProgress = ledgerEntriesWithTags.some(le => le.isFetching);

  return (
    <Table>
      <colgroup>
        <col width="150" />
        <col width="375" />
        <col width="375" />
      </colgroup>
      <thead>
        <tr>
          <th>Field</th>
          <th>Current Changed Value</th>
          <th>Latest Generated Output</th>
        </tr>
      </thead>
      <tbody>
        {keys.map(key => {
          const fieldHandler = fieldHandlers[key];

          if (!fieldHandler || fieldHandler?.skip) {
            return;
          }
          const values = ledgerEntriesWithTags.map(ledgerEntry => {
            const rawValue = ledgerEntry[key];
            const value = fieldHandler.valueGetter(rawValue);
            return typeof value !== "undefined" ? value : rawValue;
          });
          const isDifferent = uniq(values).length > 1;
          return (
            <tr key={key}>
              <th>{startCase(key)}</th>
              {values.map(value => (
                <td className={isDifferent ? "highlight" : ""}>{value}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td />
          <td>
            <SlimButton
              onClick={() => {
                openAuditLogModal(
                  AuditLogTypes.LEDGER_ENTRY,
                  committedLedgerEntryIdForHistory,
                  window.location.hash,
                );
              }}
            >
              View History
            </SlimButton>
          </td>
          <td />
        </tr>

        <tr>
          <td />
          <th colSpan={2}>
            What do you want to do with the change you previously made?
          </th>
        </tr>
        <tr>
          <td />
          <td>
            <SlimButton
              disabled={isInProgress}
              onClick={() => handleDeduplicate(ledgerEntriesWithTags[0].id)}
            >
              Keep
            </SlimButton>
          </td>
          <td>
            <SlimButton
              disabled={isInProgress}
              onClick={() => handleDeduplicate(ledgerEntriesWithTags[1].id)}
            >
              Overwrite
            </SlimButton>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

const BillingDocumentTitle = ({ id }) => {
  const { billingDocument, business } = useSelector(
    getBillingDocumentsAggridDataById(id),
  );

  return (
    <span>
      <Badge>
        {billingDocument.type} {billingDocument.number} - {business.name}
      </Badge>
      &nbsp;
    </span>
  );
};

export const ResolveConflicts = ({ onClose, id }) => {
  // When reviewing the billing document,
  const ledgerEntries = useSelector(getLedgerEntriesByDocumentId(id));

  const ledgerEntriesGroupedByStatus = groupBy(
    ledgerEntries,
    "ledgerEntry.status",
  );

  const tempLedgerEntriesGroupedByMincenousId = groupBy(
    ledgerEntriesGroupedByStatus[BillingLedgerEntryStatus.TEMPORARY],
    "ledgerEntry.mincenousId",
  );

  const committedLedgerEntriesGroupedByMincenousId = groupBy(
    ledgerEntriesGroupedByStatus[BillingLedgerEntryStatus.COMMITTED],
    "ledgerEntry.mincenousId",
  );

  const duplicatesGroupedByMinceNousId = Object.entries(
    tempLedgerEntriesGroupedByMincenousId,
  ).reduce((acc, [mincenousId, ledgerEntries]) => {
    if (ledgerEntries.length > 1) {
      acc[mincenousId] = ledgerEntries;
    }
    return acc;
  }, {});

  const duplicateCount = Object.values(duplicatesGroupedByMinceNousId).length;

  return (
    <Dialog open onClose={onClose} maxWidth="lg" fullWidth fullScreen>
      <DialogTitle onClose={onClose}>
        <BillingDocumentTitle id={id} />
        Resolve Conflicting Ledger Entries
      </DialogTitle>
      <DialogContent dividers>
        We have detected that some manually edited ledger entries have changed
        since they were edited. Please indicate what you would like to do with
        them.
        <div>
          {Object.entries(duplicatesGroupedByMinceNousId).map(
            ([mincenousId, duplicateLedgerEntries], index) => {
              const committedLedgerEntries =
                committedLedgerEntriesGroupedByMincenousId[mincenousId] || [];
              return (
                <Column alignCenter key={`${mincenousId}_${index}`}>
                  <h1>
                    Conflicting Ledger Entry {index + 1} of {duplicateCount}
                  </h1>
                  <DuplicateLedgerEntriesTable
                    ledgerEntries={duplicateLedgerEntries}
                    committedLedgerEntryIdForHistory={
                      committedLedgerEntries[0]?.ledgerEntry.id
                    }
                  />
                </Column>
              );
            },
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
