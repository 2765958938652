import React from "react";

import {
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

import { requestLogout, setSetting } from "actions";

import { MultiButton, SlimButton, UnstyledButton } from "components/Button";
import { PhoneSupportButton } from "components/Button/PhoneSupportButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { Settings } from "constants/settings";

import {
  openClearHistoryCache,
  openHelpAndSupport,
  reloadApplication,
} from "lib/navigation";

import { getSetting } from "selectors";

import { useToggle } from "hooks";
import { checkForSWUpdates } from "registerServiceWorker";

function FailedRequestModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector(getSetting(Settings.isFailedRequestsModalShowing));

  const [isShowingMore, toggleIsShowingMore] = useToggle(false);
  if (!isOpen) {
    return null;
  }

  const logout = () => {
    dispatch(requestLogout());
  };

  const checkForUpdates = () => {
    checkForSWUpdates();
  };

  const onDismiss = (dismissForMinutes = null) => {
    dispatch(setSetting(Settings.isFailedRequestsModalShowing, false));
    if (dismissForMinutes) {
      const dismissUntil = Date.now() + dismissForMinutes * 1000 * 60;
      dispatch(
        setSetting(Settings.failedRequestModalDismissedUntil, dismissUntil),
      );
    }
  };

  const onRefresh = () => {
    // Dismiss first, so the setting goes away - otherwise this will popup straight away!
    onDismiss();
    reloadApplication();
  };

  const buttons = [
    {
      title: "Dismiss",
      onClick: onDismiss,
    },
    {
      title: "Dismiss for 30 seconds",
      onClick: () => onDismiss(0.5),
    },
    {
      title: "Dismiss for 2 minutes",
      onClick: () => onDismiss(2),
    },
    {
      title: "Dismiss for 5 minutes",
      onClick: () => onDismiss(5),
    },
    {
      title: "Dismiss for 30 minutes",
      onClick: () => onDismiss(30),
    },
    {
      title: "Dismiss for an hour",
      onClick: () => onDismiss(60),
    },
  ];

  return (
    <Dialog open={isOpen}>
      <DialogTitle icon={faExclamationCircle} iconColor="warning">
        Error fetching some data
      </DialogTitle>
      <DialogContent dividers form>
        <h3>We are having trouble loading your data... </h3>

        {isShowingMore ? (
          <>
            <h3>
              After several retries there were some errors retrieving the data
              needed for AgriNous to function properly.
            </h3>
            <p>
              This usually indicates an issue communicating with the AgriNous
              server, or with your account. It is often temporary and may
              resolve itself.
            </p>
            <p>
              You can dismiss this alert and continue working, but there may be
              some unexpected or undesirable behaviour (eg a sale without scans,
              or missing business names).
            </p>
            <p>
              If this continues, check your internet connection. Otherwise,
              there are a few things you can try to resolve the problem.
            </p>
            <ul>
              <li>
                <SlimButton onClick={onRefresh}>
                  Refresh the application
                </SlimButton>
              </li>
              <li>
                <SlimButton onClick={checkForUpdates}>
                  Check if you have missed any updates
                </SlimButton>
              </li>
              <li>
                <SlimButton onClick={logout}>
                  Logout and log back in again
                </SlimButton>
              </li>

              <li>
                <SlimButton onClick={openClearHistoryCache}>
                  Clear your application data and try again
                </SlimButton>
              </li>
            </ul>

            <h4>
              If this problem persists please{" "}
              <SlimButton onClick={openHelpAndSupport}>
                contact support
              </SlimButton>{" "}
              or call us on <PhoneSupportButton />
            </h4>
            <p>
              <UnstyledButton
                className="cursor-pointer"
                onClick={toggleIsShowingMore}
              >
                <FontAwesomeIcon icon={faChevronUp} />
                &nbsp; Show less information
              </UnstyledButton>
            </p>
          </>
        ) : (
          <div>
            <SlimButton onClick={reloadApplication}>
              Refresh the application
            </SlimButton>

            <p>
              <UnstyledButton
                className="cursor-pointer"
                onClick={toggleIsShowingMore}
              >
                <FontAwesomeIcon icon={faChevronDown} />
                &nbsp; Show more information...
              </UnstyledButton>
            </p>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <MultiButton buttons={buttons} />
      </DialogActions>
    </Dialog>
  );
}

export default FailedRequestModal;
