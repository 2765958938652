import React from "react";

import { Grid } from "@material-ui/core";
import flatten from "lodash/flatten";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Badge from "components/Badge";
import StatusCard from "components/Card/StatusCard";
import {
  AngusVerifiedVendorChip,
  ERPChip,
  NVDChip,
  PicWarningChip,
  VendorEidsNotRegisteredChip,
} from "components/Chip";
import { PlainCollapse } from "components/Collapse";
import { UnresolvedCommentIcon } from "components/Comments/Icon";
import { OpenIndicator } from "components/Icons/CollapseIcon";
import { Column, Row } from "components/Layout";
import ScannedCount from "components/ScannedCount";
import { ScanCountWrapper } from "components/ScannedCount/Elements";
import GroupedConsignmentStepper from "components/Stepper/Stepper";
import { BoldText, Subtitle } from "components/Text";
import StatusText from "components/Text/StatusText";

import { ConsignmentGroupByOptions } from "constants/consignments";
import { SaleyardPermissions } from "constants/permissions";
import { VENDOR_DETAILS_REPORT_NAME } from "constants/reports";
import { SaleTypes } from "constants/sale";

import { ForLivestockSale, ForNotHooksOrPaddock } from "containers/ForSaleType";
import { ForSaleyardAdmin } from "containers/ForUserType";

import { consignmentListsAreEqual, saleLotsListsAreEqual } from "lib/compare";

import {
  getConsignmentById,
  getCurrentSale,
  getCurrentSaleyard,
  selectIsEuEligibleBySaleLotIdLookup,
  selectIsVendorBredBySaleLotIdLookup,
  selectUnresolvedSaleLotCommentCountByConsignmentIdLookup,
} from "selectors";

import { useHasPermission, useToggle } from "hooks";
import AgriNousActiveLogo from "img/AgriNousActiveLogo";

import ConsignmentCard from "./ConsignmentCard";

const HeadCountNumber = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  margin-right: 4px;
`;

const PenNumber = styled.span`
  color: ${({ theme }) => theme.colors.gray40};
`;

const TogglePlainCollapse = styled.div`
  cursor: pointer;
  overflow: hidden;
`;

const RecievingPenSubtitle = styled(Subtitle)`
  margin-left: 20px;
  margin-bottom: 20px;
`;

function cardStatus(offlinePatching, isBalanced) {
  const balanceStatus = isBalanced ? "success" : "warning";
  return offlinePatching ? "inputError" : balanceStatus;
}

const areEqual = (prevProps, nextProps) => {
  // Display props
  if (prevProps.hasExceptions !== nextProps.hasExceptions) {
    return false;
  }
  if (prevProps.quantity !== nextProps.quantity) {
    return false;
  }
  if (prevProps.receivingPen !== nextProps.receivingPen) {
    return false;
  }
  if (prevProps.scannedCount !== nextProps.scannedCount) {
    return false;
  }
  if (prevProps.groupedBy !== nextProps.groupedBy) {
    return false;
  }
  if (prevProps.vendorName !== nextProps.vendorName) {
    return false;
  }
  if (prevProps.vendorNumber !== nextProps.vendorNumber) {
    return false;
  }
  if (prevProps.vendorNumbers !== nextProps.vendorNumbers) {
    return false;
  }
  if (prevProps.showStepper !== nextProps.showStepper) {
    return false;
  }
  if (
    prevProps.consignmentsScannedCount !== nextProps.consignmentsScannedCount
  ) {
    return false;
  }
  const saleLotsEqual = saleLotsListsAreEqual(
    prevProps.consignments[0].saleLots,
    nextProps.consignments[0].saleLots,
  );
  if (!saleLotsEqual) {
    return false;
  }
  const areEqual = consignmentListsAreEqual(
    prevProps.consignments,
    nextProps.consignments,
  );
  // Code below left in as very useful for debugging/optimisation of rendering
  // console.log(`GroupedConsignmentCard ${areEqual}`);
  return areEqual;
};

const ScanCount = React.memo(
  ({
    consignmentsScannedCount,
    scannedPercentage,
    scannedCount,
    scannedStatus,
  }) => (
    <ForLivestockSale>
      <div>
        <ScanCountWrapper>
          <Subtitle>EIDs</Subtitle>
        </ScanCountWrapper>
        <ScannedCount
          count={consignmentsScannedCount}
          percentage={scannedPercentage}
          saleLotScansCount={scannedCount}
          status={scannedStatus}
        />
      </div>
    </ForLivestockSale>
  ),
);

const GroupedConsignmentCard = ({
  groupedBy,
  vendorReports,
  consignment,
  speciesId,
  showStepper,
}) => {
  const [isOpen, toggleIsOpen] = useToggle();
  const collapseRef = React.useRef();

  React.useEffect(() => {
    if (isOpen) {
      collapseRef.current.scrollIntoView(true, {
        behavior: "smooth",
      });
    }
  }, [isOpen]);

  const {
    vendorName,
    vendorNumbers,
    isBalanced,
    consignments,
    consignmentsScannedCount,
    scannedCount,
    scannedPercentage,
    scannedStatus,
    quantity,
    hasExceptions,
    receivingPen,
    livestock_agency_code,
    vendorId,
    quantityUndrafted,
  } = consignment;

  const saleType = useSelector(getCurrentSale)?.sale_type;

  const isPaddockOrHooksSale =
    saleType === SaleTypes.PADDOCK || saleType === SaleTypes.OVER_HOOKS;

  const offlinePatching = useSelector(state => {
    return consignment.consignments.some(
      c => getConsignmentById(c.id)(state).syncing,
    );
  });

  const unresolvedSaleLotCommentsByConsignmentId = useSelector(
    selectUnresolvedSaleLotCommentCountByConsignmentIdLookup,
  );
  const totalUnresolvedSaleLotComments = consignments.reduce(
    (acc, consignment) =>
      acc + unresolvedSaleLotCommentsByConsignmentId[consignment.id],
    0,
  );

  const declarations = consignments
    .map(consignment => consignment.declaration)
    .filter(Boolean);

  const isVendorBredBySaleLotIdLookup = useSelector(
    selectIsVendorBredBySaleLotIdLookup,
  );

  const isEuEligibleLookup = useSelector(selectIsEuEligibleBySaleLotIdLookup);

  const groupedBredStatus = consignments.map(consignment =>
    consignment.saleLots.map(
      saleLot => isVendorBredBySaleLotIdLookup[saleLot.id],
    ),
  );

  const groupedEuStatus = consignments.map(consignment =>
    consignment.saleLots.map(saleLot => isEuEligibleLookup[saleLot.id]),
  );

  const saleLots = flatten(
    consignments.map(consignment => consignment.saleLots),
  );

  const hasAgriNousUser = consignment.consignments.some(
    consignment => consignment.vendor?.businessUsers?.length,
  );

  const programs = flatten(
    consignments.map(consignment => consignment.nlisPrograms).filter(Boolean),
  );

  const hasReceivalLotPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featureReceivalLots,
  );

  return (
    <StatusCard
      ref={collapseRef}
      status={
        isPaddockOrHooksSale
          ? "success"
          : cardStatus(offlinePatching, isBalanced)
      }
      data-tour={`consignmentCards.${vendorName}`}
    >
      <TogglePlainCollapse onClick={toggleIsOpen}>
        <Row>
          <Row full justifyBetween flexWrap data-tour="consignmentCards">
            {groupedBy === ConsignmentGroupByOptions.RECEIVING_PEN &&
              // hide receival pen data when receival lot permission is enabled
              !hasReceivalLotPermission && (
                <Row alignCenter>
                  <RecievingPenSubtitle>
                    Receiving Pen <PenNumber>{receivingPen || "-"}</PenNumber>
                  </RecievingPenSubtitle>
                </Row>
              )}

            <Grid container>
              <Grid item xs={7}>
                <Row alignCenter>
                  <AgriNousActiveLogo isActive={hasAgriNousUser} />
                  <div>
                    <Row>
                      <Subtitle display="flex" alignItems="center">
                        Vendor{" "}
                        <ForSaleyardAdmin>
                          {livestock_agency_code}
                        </ForSaleyardAdmin>
                        {vendorNumbers.join("/")}
                        <ForNotHooksOrPaddock>
                          {hasExceptions && <StatusText status="error" />}
                        </ForNotHooksOrPaddock>
                      </Subtitle>
                      <UnresolvedCommentIcon
                        unresolvedCommentCount={totalUnresolvedSaleLotComments}
                        horizontalPadding={1}
                        size="2x"
                      />
                    </Row>
                    <BoldText>{vendorName}</BoldText>
                  </div>
                </Row>
              </Grid>
              <Grid item xs={3}>
                <div>
                  <Subtitle>Hd</Subtitle>
                  <BoldText>
                    <HeadCountNumber>{quantity}</HeadCountNumber>
                    {quantityUndrafted !== 0 && (
                      <Badge color="warning">
                        {quantityUndrafted < 0 && "+"}
                        {-quantityUndrafted}
                      </Badge>
                    )}
                  </BoldText>
                </div>
              </Grid>
              <Grid item xs={2}>
                <ScanCount
                  consignmentsScannedCount={consignmentsScannedCount}
                  scannedPercentage={scannedPercentage}
                  scannedCount={scannedCount}
                  scannedStatus={scannedStatus}
                />
              </Grid>
            </Grid>
          </Row>
          <Column>
            <OpenIndicator isOpen={isOpen} />
          </Column>
        </Row>
        <ForNotHooksOrPaddock>
          {showStepper || isOpen ? (
            <Row>
              <GroupedConsignmentStepper open={isOpen} vendorId={vendorId} />
            </Row>
          ) : null}
        </ForNotHooksOrPaddock>
        <Row>
          <NVDChip
            declarations={declarations}
            speciesId={speciesId}
            vendorBred={groupedBredStatus}
            euEligible={groupedEuStatus}
          />
          <ERPChip saleLots={saleLots} />
          <PicWarningChip programs={programs} />
          <AngusVerifiedVendorChip vendorId={vendorId} />

          <VendorEidsNotRegisteredChip vendorId={vendorId} />
        </Row>
      </TogglePlainCollapse>
      <PlainCollapse isOpen={isOpen}>
        {consignments.map(consignment => {
          let vendorReportUrl = null;
          if (vendorReports) {
            const vendorDetailsReport = vendorReports.links.find(
              report => report.name === VENDOR_DETAILS_REPORT_NAME,
            );
            vendorReportUrl = vendorDetailsReport && vendorDetailsReport.url;
          }
          return (
            <ConsignmentCard
              consignment={consignment}
              id={consignment.id}
              key={consignment.id}
              totalConsignmentsQuantity={consignments.length}
              vendorReportUrl={vendorReportUrl}
            />
          );
        })}
      </PlainCollapse>
    </StatusCard>
  );
};

GroupedConsignmentCard.propTypes = {
  vendorName: PropTypes.string,
  vendorNumbers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  vendorReports: PropTypes.object,
  isBalanced: PropTypes.bool,
  consignments: PropTypes.arrayOf(PropTypes.shape()),
  scannedCount: PropTypes.number,
  scannedPercentage: PropTypes.number,
  quantity: PropTypes.number,
  scannedStatus: PropTypes.number,
  hasExceptions: PropTypes.bool,
  receivingPen: PropTypes.string,
  groupedBy: PropTypes.string,
};

export default React.memo(GroupedConsignmentCard, areEqual);
