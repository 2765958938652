import React from "react";

import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import Button from "components/Button";
import { Column, Row } from "components/Layout";
import Property from "components/Property";

import { UNKNOWN_BUSINESS_NAME } from "constants/businesses";

const InlineButton = styled(Button)`
  margin: ${({ theme }) => theme.space[1]}px ${({ theme }) => theme.space[2]}px;
  padding: 0 ${({ theme }) => theme.space[2]}px;
  min-height: 2rem;
`;
const TopRowWrapper = styled(Row)`
  padding: 0px 24px 0 12px;
  margin: -6px 0;
`;

const ActionIcon = styled(FontAwesomeIcon)`
  margin: 0 ${({ theme }) => theme.space[1]}px;
`;

export const TopRow = ({
  business,
  property,
  doSearch,
  handlePrefill,
  showProperty,
}) => {
  const businessAction =
    business && business.name !== UNKNOWN_BUSINESS_NAME ? (
      <InlineButton onClick={() => doSearch(business.name)} type="button">
        <ActionIcon icon={faSearch} /> {business.name}
      </InlineButton>
    ) : property ? (
      <InlineButton onClick={() => handlePrefill(null, property)} type="button">
        <ActionIcon icon={faPlus} />
        Add Business
      </InlineButton>
    ) : (
      ""
    );
  const propertyAction = property ? (
    <InlineButton onClick={() => doSearch(property.PIC)} type="button">
      <ActionIcon icon={faSearch} />
      <Property businessId={business?.id} propertyId={property.id} />
    </InlineButton>
  ) : business && showProperty ? (
    <InlineButton onClick={() => handlePrefill(business, null)} type="button">
      <ActionIcon icon={faPlus} />
      Add PIC
    </InlineButton>
  ) : (
    ""
  );

  return (
    <TopRowWrapper alignCenter justifyAround>
      {businessAction && <Column>{businessAction}</Column>}
      {propertyAction && <Column>{propertyAction}</Column>}
    </TopRowWrapper>
  );
};
