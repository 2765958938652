import React, { useMemo, useState } from "react";

import { Field, useField } from "formik";
import { useSelector } from "react-redux";

import { SelectField, withNamespace } from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { RuleTaxTypeOptions } from "constants/billing";
import { ValueSource } from "constants/ruleBooks";

import {
  FeeOperator,
  FieldType,
  FieldValueType,
  GST_FEE_EXCLUSIVE_MULTIPLIER,
  GST_FEE_FIXED_FIELD,
  GST_FEE_INCLUSIVE_MULTIPLIER,
  GST_FEE_NAME,
  GstPricingType,
} from "containers/Settings/RuleBooks/constants";
import { DataSource } from "containers/Settings/RuleBooks/DataSource";
import {
  compareDynamicFieldOption,
  getSchemaFieldValues,
  mapOptionToConstantFieldOption,
  RoundingMethod,
  RoundingMethodOptions,
} from "containers/Settings/RuleBooks/lib";
import { TitledRule } from "containers/Settings/RuleBooks/Rules/Layout";
import { useRuleFieldSchema } from "containers/Settings/RuleBooks/schemaContext";

import {
  selectLedgerAccountOptionsForRuleBuilder,
  selectMasterLedgerAccountOptionsForRuleBuilder,
} from "selectors";

function getGstPricingType(fees) {
  if (Array.isArray(fees) && fees.length === 1) {
    const fee = fees[0];

    if (
      fee.enabledQuery?.source === ValueSource.CONSTANT &&
      fee.operationInput.attribute === GST_FEE_FIXED_FIELD &&
      fee.operation === FeeOperator.ASSIGN
    ) {
      return GstPricingType.FIELD_GST_CENTS;
    }

    if (
      fee.name !== GST_FEE_NAME ||
      fee.operationInput.source !== ValueSource.CONSTANT
    ) {
      return GstPricingType.NOT_SET;
    }
    if (fee.enabledQuery) {
      if (
        fee.enabledQuery.source === ValueSource.FIELD &&
        (fee.enabledQuery.attribute === "should_charge_gst" ||
          fee.enabledQuery.attribute === "is_gst_registered")
      ) {
        return GstPricingType.AUTO;
      } else if (
        fee.apportionFromSource &&
        fee.operationInput.value === GST_FEE_INCLUSIVE_MULTIPLIER
      ) {
        return GstPricingType.INCLUSIVE;
      } else if (
        fee.operation === FeeOperator.MULTIPLY &&
        fee.operationInput.value === GST_FEE_EXCLUSIVE_MULTIPLIER
      ) {
        return GstPricingType.EXCLUSIVE;
      } else if (fee.operationInput.value === 0) {
        return GstPricingType.EXEMPT;
      }
    }
  }
  return GstPricingType.NOT_SET;
}

export function getGstFee(gstPricingType, sourceIndex = 0) {
  if (gstPricingType === GstPricingType.FIELD_GST_CENTS) {
    return {
      apportionFromSource: false,
      enabledQuery: {
        source: ValueSource.CONSTANT,
        value: true,
      },
      name: GST_FEE_NAME,
      operation: FeeOperator.ASSIGN,
      operationInput: {
        source: ValueSource.FIELD,
        attribute: GST_FEE_FIXED_FIELD,
        customQuery: "",
        fieldId: "",
      },
      sourceIndex,
    };
  } else if (gstPricingType === GstPricingType.AUTO) {
    return {
      apportionFromSource: false,
      enabledQuery: {
        source: ValueSource.FIELD,
        attribute: "should_charge_gst",
      },
      name: GST_FEE_NAME,
      operation: FeeOperator.MULTIPLY,
      operationInput: {
        source: ValueSource.CONSTANT,
        value: GST_FEE_EXCLUSIVE_MULTIPLIER,
      },
      sourceIndex: 0,
    };
  } else if (gstPricingType === GstPricingType.EXCLUSIVE) {
    return {
      apportionFromSource: false,
      enabledQuery: { source: ValueSource.CONSTANT, value: true },
      name: GST_FEE_NAME,
      operation: FeeOperator.MULTIPLY,
      operationInput: {
        source: ValueSource.CONSTANT,
        value: GST_FEE_EXCLUSIVE_MULTIPLIER,
      },
      sourceIndex,
    };
  } else if (gstPricingType === GstPricingType.INCLUSIVE) {
    return {
      apportionFromSource: true,
      enabledQuery: { source: ValueSource.CONSTANT, value: true },
      name: GST_FEE_NAME,
      operation: FeeOperator.MULTIPLY,
      operationInput: {
        source: ValueSource.CONSTANT,
        value: GST_FEE_INCLUSIVE_MULTIPLIER,
      },
      sourceIndex,
    };
  } else if (gstPricingType === GstPricingType.EXEMPT) {
    return {
      apportionFromSource: false,
      enabledQuery: { source: ValueSource.CONSTANT, value: true },
      name: GST_FEE_NAME,
      operation: FeeOperator.ADD,
      operationInput: { source: ValueSource.CONSTANT, value: 0 },
    };
  }
  return null;
}

function Fees(props) {
  const {
    // criteriaOptions, TODO Enable the the user to source the GST Status from more than just the 1st Criteria
    isMasterRule,
    namespace: ns,
    parentFieldId,
  } = props;

  const LedgerAccountOptions = useSelector(state => {
    // If we are editing a master rule (as opposed to customising a master rule), we should only see master ledger accounts.
    return isMasterRule
      ? selectMasterLedgerAccountOptionsForRuleBuilder(state)
      : selectLedgerAccountOptionsForRuleBuilder(state);
  });

  const [{ value: fees }, , { setValue }] = useField(ns);

  const [pricingType, setPricingType] = useState(getGstPricingType(fees));
  const schema = useRuleFieldSchema();

  // Auto GST can be used when the "parentField" in the schema has a `gst_applicability_boolean` type property.
  // Auto GST currently has the limitation of only using the `should_charge_gst` field
  const isAutoGstEnabled = getSchemaFieldValues(
    parentFieldId,
    schema,
    false,
    false,
  ).find(
    schemaField =>
      schemaField.type === FieldValueType.GST_APPLICABILITY_BOOLEAN,
  );

  // Auto GL Code can be used when the "parentField" in the schema has a `gl_code` type property.
  const glCodeProperty = useMemo(
    () =>
      getSchemaFieldValues(parentFieldId, schema, false, false).find(
        schemaField => schemaField.type === FieldValueType.GL_CODE,
      ),
    [schema, parentFieldId],
  );

  const automaticGlCodeOptions = useMemo(
    () =>
      glCodeProperty
        ? [
            {
              label: "Automatically determined",
              value: {
                source: ValueSource.FIELD,
                value: glCodeProperty.via || glCodeProperty.id,
              },
            },
          ]
        : [],
    [glCodeProperty],
  );

  const allLedgerAccountOptions = useMemo(
    () =>
      automaticGlCodeOptions.concat(
        LedgerAccountOptions.map(mapOptionToConstantFieldOption),
      ),
    [automaticGlCodeOptions, LedgerAccountOptions],
  );

  // Auto Tax Type can be used when the "parentField" in the schema has a `tax_type` type property.
  const taxTypeProperty = useMemo(
    () =>
      getSchemaFieldValues(parentFieldId, schema, false, false).find(
        schemaField => schemaField.type === FieldValueType.TAX_TYPE,
      ),
    [schema, parentFieldId],
  );

  const automaticTaxTypeOptions = useMemo(
    () =>
      taxTypeProperty
        ? [
            {
              label: "Automatically determined",
              value: {
                source: ValueSource.FIELD,
                value: taxTypeProperty.via || taxTypeProperty.id,
              },
            },
          ]
        : [],
    [taxTypeProperty],
  );

  const allTaxTypeOptions = useMemo(
    () =>
      automaticTaxTypeOptions.concat(
        RuleTaxTypeOptions.map(mapOptionToConstantFieldOption),
      ),
    [automaticTaxTypeOptions],
  );

  function onChangeGstField(event) {
    setPricingType(event.target.value);
    const gstFee = getGstFee(event.target.value);
    if (gstFee) {
      setValue([gstFee]);
    } else {
      setValue([]);
    }
  }

  const isManualAdjustment = parentFieldId === "manual_adjustment";

  return (
    <>
      <TitledRule>Taxes and Accounts</TitledRule>
      <p>
        <span>GST Status: </span>
        <select
          data-tour="gstStatus"
          className="p-2"
          name="gst"
          required
          onChange={onChangeGstField}
          value={pricingType}
        >
          <option value={GstPricingType.NOT_SET} disabled>
            Select GST pricing
          </option>
          {isManualAdjustment && (
            <option value={GstPricingType.FIELD_GST_CENTS}>
              Value from Sundry
            </option>
          )}
          {isAutoGstEnabled && (
            <option value={GstPricingType.AUTO}>
              Automatically determined
            </option>
          )}
          <option value={GstPricingType.EXCLUSIVE}>GST Exclusive</option>
          <option value={GstPricingType.INCLUSIVE}>GST Inclusive</option>
          <option value={GstPricingType.EXEMPT}>Exempt from GST</option>
        </select>
      </p>

      {pricingType === GstPricingType.AUTO && (
        <p>
          When automatically calculating the GST status, all gross pricing is
          assumed to be exclusive of GST.
        </p>
      )}
      <Row full alignCenter paddingVertical={1}>
        <span>GL Code:</span>&nbsp;
        <SelectField
          comparer={compareDynamicFieldOption}
          data-tour="glCode"
          name="gl_code"
          required
          options={allLedgerAccountOptions}
          menuPortalTarget={document.body}
          tooltip="To create a new account, go to the account settings page."
        />
      </Row>
      <Row full alignCenter paddingVertical={1}>
        <span>Tax Type: </span>
        <SelectField
          comparer={compareDynamicFieldOption}
          data-tour="taxType"
          name="tax_type"
          required
          options={allTaxTypeOptions}
          menuPortalTarget={document.body}
        />
      </Row>
    </>
  );
}

function Subtotal(props) {
  const { namespace: ns } = props;
  const valueNs = withNamespace(ns, "value");
  const roundingStrategyFieldName = withNamespace(valueNs, "rounding_strategy");

  const [{ value: roundingMethod }] = useField(roundingStrategyFieldName);

  return (
    <>
      <TitledRule>Subtotal</TitledRule>
      <p>
        <span>Price Multiplier: </span>
        <Field
          data-tour="priceMultiplier"
          name={withNamespace(ns, "price_multiplier")}
          className="p-2"
          type="number"
        />
      </p>
      <p>
        <span>Rounding Strategy: </span>
        <Field
          data-tour="roundingStrategy"
          as="select"
          className="p-2"
          name={roundingStrategyFieldName}
          required
        >
          <option disabled value="">
            Select a Rounding Method
          </option>
          {RoundingMethodOptions.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </Field>
      </p>
      {roundingMethod !== RoundingMethod.NONE && (
        <p>
          <span>To Nearest: </span>
          <Field
            data-tour="toNearest"
            className="p-2"
            name={withNamespace(valueNs, "rounding_value")}
            required
            type="number"
          />
          &#x00A2;
        </p>
      )}
    </>
  );
}

function BasePrice(props) {
  const { criteriaOptions, namespace: ns, parentFieldId } = props;

  const unitPriceName = withNamespace(ns, "amount");
  const totalAmountAdjustmentName = withNamespace(ns, "offset");
  const multiplierOffsetName = withNamespace(ns, "unit_offset");
  const multiplierName = withNamespace(ns, "units");
  const multiplierLimitName = withNamespace(ns, "unit_limit");

  return (
    <>
      <p>
        <span>Units: </span>
        <DataSource
          dataTour="units"
          criteriaOptions={criteriaOptions}
          fieldType={FieldType.NUMERIC}
          namespace={multiplierName}
          parentFieldId={parentFieldId}
        />
      </p>
      <details>
        <summary>Advanced Units Calculation</summary>
        <p>
          <span>Unit Offset: </span>
          <DataSource
            criteriaOptions={criteriaOptions}
            fieldType={FieldType.NUMERIC}
            namespace={multiplierOffsetName}
            parentFieldId={parentFieldId}
          />
        </p>
        <p>
          <span>Unit Limit: </span>
          <DataSource
            criteriaOptions={criteriaOptions}
            fieldType={FieldType.NUMERIC}
            namespace={multiplierLimitName}
            parentFieldId={parentFieldId}
          />
        </p>
        <p>
          <span>Output as 1 Unit: </span>
          <Field
            name={withNamespace(ns, "output_as_single_unit")}
            className="p-2"
            type="checkbox"
          />
        </p>
      </details>
      <p>
        <span>Unit Price: </span>
        <DataSource
          dataTour="unitPrice"
          criteriaOptions={criteriaOptions}
          fieldType={FieldType.NUMERIC}
          namespace={unitPriceName}
          parentFieldId={parentFieldId}
        />
      </p>
      <p>
        <span>Price Adjustment (&#x00A2;): </span>
        <DataSource
          dataTour="priceAdjustment"
          criteriaOptions={criteriaOptions}
          fieldType={FieldType.NUMERIC}
          namespace={totalAmountAdjustmentName}
          parentFieldId={parentFieldId}
        />
      </p>
    </>
  );
}

export function ValueClause(props) {
  const { criteria, isMasterRule, namespace: ns, parentFieldId } = props;

  const criteriaOptions = useMemo(
    () =>
      (criteria || []).map((criterion, i) => ({
        name: `Criteria ${i + 1}`,
        fieldId: criterion.query?.fieldId || parentFieldId,
      })),
    [criteria, parentFieldId],
  );

  return (
    <fieldset className="p-2">
      <legend className="p-2">Value</legend>
      <BasePrice
        criteriaOptions={criteriaOptions}
        namespace={withNamespace(ns, "value")}
        parentFieldId={parentFieldId}
      />

      <Subtotal namespace={ns} />

      <Fees
        criteriaOptions={criteriaOptions}
        namespace={withNamespace(ns, "fees")}
        isMasterRule={isMasterRule}
        parentFieldId={parentFieldId}
      />
    </fieldset>
  );
}
