import React from "react";

import {
  faAddressCard,
  faBriefcase,
  faMailbox,
  faPiggyBank,
} from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { IntegrationCredentialAction } from "actions/integrationCredentials";

import {
  BsbInput,
  normaliseBsb,
} from "components/BusinessForm/FinanceAndAccounting/BankingDetailsForm";
import { Icon } from "components/DetailedSaleLotModal/AdvancedDrafting/components";
import { Button, SecondaryButton } from "components/Form";
import { AddressForm } from "components/Form/Fields";
import { Input } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { Paper } from "components/Paper";
import { MediumText as Heading } from "components/Text";

import {
  AddressType,
  NameType,
  PhoneType,
} from "constants/integrationBusiness";
import { ApiModel } from "constants/loading";

import { getPrimaryContactFromBusiness } from "lib/businesses";
import { closeAllHashModals, openIntegrationSettings } from "lib/navigation";
import { ABNValidator } from "lib/validators";

import {
  getActiveLivestockAgentDeployment,
  getBusinessById,
  selectIntegrationCredentialIdByTypeAndDeploymentIdLookup,
} from "selectors";

import { useCloseDialog } from "hooks";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  abn: ABNValidator,
});

export const CreateIntegrationBusinessModal = ({
  integrationCredentialType,
  businessId,
  returnTo,
}) => {
  const onClose = useCloseDialog(returnTo);

  const business = useSelector(getBusinessById(businessId)) || {};
  const { id: deploymentId } = useSelector(getActiveLivestockAgentDeployment);

  const primaryContact = getPrimaryContactFromBusiness(business) || {};

  const initialValues = {
    abn: business.abn,
    accountNumber: business.bankingDetails?.accountNumber,
    address: business.address,
    bsb: business.bankingDetails?.bsb,
    emailAddress: primaryContact.email,
    firstName: primaryContact.firstName,
    lastName: primaryContact.lastName,
    name: business.tradingName || business.name || "",
    phoneNumber: primaryContact.phoneNumber,
  };

  const integrationCredentialId = useSelector(
    state =>
      selectIntegrationCredentialIdByTypeAndDeploymentIdLookup(state)[
        integrationCredentialType
      ]?.[deploymentId],
  );

  const transformAddress = address => ({
    addressee: address.addressingInformation,
    city: address.locality,
    line1: `${address.streetNumber} ${address.route}`,
    postCode: address.postalCode,
    state: address.state,
    type: AddressType.POSTAL,
  });

  const dispatch = useDispatch();
  const onSave = formPayload => {
    const payload = {
      names: [
        formPayload.name && {
          type: NameType.NAME,
          value: formPayload.name,
        },
        formPayload.firstName && {
          type: NameType.FIRST,
          value: formPayload.firstName,
        },
        formPayload.lastName && {
          type: NameType.LAST,
          value: formPayload.lastName,
        },
      ].filter(Boolean),
      phones: [
        formPayload.phoneNumber && {
          type: PhoneType.MOBILE,
          phoneNumber: formPayload.phoneNumber,
        },
      ].filter(Boolean),
      deploymentBusinessId: business.deploymentBusinessId,
      bsb: normaliseBsb(formPayload.bsb),
      accountNumber: formPayload.accountNumber,
      emailAddress: formPayload.emailAddress,
      abn: formPayload.abn,
      addresses: [
        formPayload.address ? transformAddress(formPayload.address) : null,
      ].filter(Boolean),
    };

    dispatch(
      IntegrationCredentialAction.createBusiness(
        integrationCredentialId,
        payload,
      ),
    );
    closeAllHashModals();
  };

  function onClickAddCredentials() {
    openIntegrationSettings();
  }

  if (!integrationCredentialId) {
    return (
      <Dialog fullWidth maxWidth="md" open onClose={onClose}>
        <DialogTitle onClose={onClose}>
          <Row justifyBetween>
            No {integrationCredentialType} Integration Credentials found.
          </Row>
        </DialogTitle>
        <DialogContent dividers>
          <WaitForSync requiredData={[ApiModel.INTEGRATION_CREDENTIALS]}>
            <Button inline type="button" onClick={onClickAddCredentials}>
              Add {integrationCredentialType} Credentials
            </Button>
          </WaitForSync>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog fullWidth fullScreen maxWidth="lg" open onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <Row justifyBetween>Create Business in {integrationCredentialType}</Row>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={onSave}
        validationSchema={validationSchema}
      >
        <Form>
          <DialogContent dividers>
            <Grid spacing={2} container>
              <Grid item xs={12}>
                Note: This will not make any modifications to this business in
                AgriNous
              </Grid>

              <Grid item xs={12} md={6} container>
                <Paper>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Row flexGrow>
                        <Icon icon={faBriefcase} color="gray44" />
                        <Heading>Business</Heading>
                      </Row>
                    </Grid>

                    <Grid item xs={6}>
                      <Input name="name" label="Business Name" required />
                    </Grid>
                    <Grid item xs={6}>
                      <Input disabled name="abn" label="ABN" />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Row flexGrow>
                        <Icon icon={faAddressCard} color="gray44" />
                        <Heading>Primary Contact</Heading>
                      </Row>
                    </Grid>
                    <Grid item xs={6}>
                      <Input disabled name="firstName" label="First Name" />
                    </Grid>

                    <Grid item xs={6}>
                      <Input disabled name="lastName" label="Last Name" />
                    </Grid>

                    <Grid item xs={6}>
                      <Input
                        disabled
                        name="emailAddress"
                        label="Email Address"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Input disabled name="phoneNumber" label="Phone Number" />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Row flexGrow>
                        <Icon icon={faPiggyBank} color="gray44" />
                        <Heading>Financial Information</Heading>
                      </Row>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <BsbInput disabled name="bsb" label="BSB" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Input
                        disabled
                        label="Account Number"
                        maxLength={20}
                        name="accountNumber"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Row flexGrow>
                        <Icon icon={faMailbox} color="gray44" />
                        <Heading>Postal Address</Heading>
                      </Row>
                    </Grid>
                    <AddressForm
                      businessId={businessId}
                      disabled
                      label="Postal Address"
                      namespace="address"
                      showLabel={false}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SecondaryButton type="button" onClick={onClose}>
              Cancel
            </SecondaryButton>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};
