import React, { useCallback, useMemo, useState } from "react";
import { Label } from "components/Form/FormikControls";
import { Grid } from "@material-ui/core";
import { BulkCreateReportOptions, ReportDriver } from "constants/reports";

import { Column } from "components/Layout";

import ReactSelect, {
  findOptionByValue,
} from "components/SearchableSelector/ReactSelect";
import { CreateFinancialStatementReportJobsForm } from "./FinancialStatementReports";
import { BulkReportFormProps } from "./BulkReportFormCommon";
import { ReportNotSupportedForm } from "./ReportNotSupportedForm";

const ReportSpecificFormMap = {
  FinancialStatementReport: CreateFinancialStatementReportJobsForm,
  FinancialStatementZipReport: CreateFinancialStatementReportJobsForm,
};

const GenericFormMap = {
  [ReportDriver.AGENT]: ReportNotSupportedForm,
  [ReportDriver.AGENCY]: ReportNotSupportedForm,
  [ReportDriver.BRANCH_AND_AGENCY]: ReportNotSupportedForm,
  [ReportDriver.BUYER_AND_SALE_ROUND]: ReportNotSupportedForm,
  [ReportDriver.BIDDER]: ReportNotSupportedForm,
  [ReportDriver.BUYER]: ReportNotSupportedForm,
  [ReportDriver.BUYER_BUYER_WAY]: ReportNotSupportedForm,
  [ReportDriver.BUYER_DESTINATION_PIC]: ReportNotSupportedForm,
  [ReportDriver.BUYER_NAME_RANGE]: ReportNotSupportedForm,
  [ReportDriver.CONSIGNED_FROM]: ReportNotSupportedForm,
  [ReportDriver.DEPLOYMENT]: ReportNotSupportedForm,
  [ReportDriver.MULTI_SALE]: ReportNotSupportedForm,
  [ReportDriver.RULEBOOK]: ReportNotSupportedForm,
  [ReportDriver.SALE_ROUND]: ReportNotSupportedForm,
  [ReportDriver.SALE_ROUND_AND_AGENCY]: ReportNotSupportedForm,
  [ReportDriver.VENDOR]: ReportNotSupportedForm,
};

interface IProps {
  allowedReports: string[];
  onClose: () => void;
}

export function ManyReportsForm(props: IProps): React.JSX.Element {
  const { allowedReports, onClose } = props;

  const options = useMemo(
    () =>
      !Array.isArray(allowedReports)
        ? BulkCreateReportOptions
        : BulkCreateReportOptions.filter(option =>
            allowedReports.includes(option.value),
          ),
    [allowedReports],
  );

  const [reportSlug, setReportSlug] = useState<string>(
    options.length > 0 ? options[0].value : null,
  );

  const onChangeReportSlug = useCallback(
    option => {
      setReportSlug(option.value);
    },
    [setReportSlug],
  );

  const ReportFormComponent: React.FC<BulkReportFormProps> =
    ReportSpecificFormMap[reportSlug] ||
    GenericFormMap[reportSlug] ||
    ReportNotSupportedForm;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Column fullWidth>
            <Label>Report</Label>
            <ReactSelect
              options={options}
              onChange={onChangeReportSlug}
              value={findOptionByValue(options, reportSlug)}
            />
          </Column>
        </Grid>
      </Grid>
      {reportSlug && (
        <ReportFormComponent
          reportSlug={reportSlug}
          onClose={onClose}
          key={reportSlug}
        />
      )}
    </>
  );
}
