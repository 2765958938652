import React, { useRef } from "react";

import { Form as FormikForm, Formik, useFormikContext } from "formik";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { ClickableCommentIcon } from "components/Comments/Icon";
import { LegacyVendorSplitCollapse } from "components/DetailedSaleLotModal/LegacyVendorSplitCollapse";
import { VendorSplitCollapse } from "components/DetailedSaleLotModal/VendorSplitCollapse";
import { Button, SecondaryButton } from "components/Form";
import { CheckBox, withNamespace } from "components/Form/FormikControls";
import { IntegrationIcon } from "components/Icons";
import { IntegrationCollapse } from "components/IntegrationCollapse";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { MediaCollapse } from "components/MediaCollapse";
import { CurrentSaleReadOnly } from "components/ReadOnly";

import { AuditLogTypes } from "constants/auditLog";
import { LotStatus } from "constants/clearingSaleAttributes";
import { CommentTypes } from "constants/comments";
import { ExportSites } from "constants/exportSites";
import { ApiModel } from "constants/loading";
import { SaleLotModalSection } from "constants/navigation";
import {
  DeploymentPermissions,
  SaleLotPermissions,
} from "constants/permissions";
import { SaleTypes } from "constants/sale";
import { IGNORED_DRAFTING_ATTRIBUTES } from "constants/saleLots";
import { Species } from "constants/species";

import {
  ForClearingSale,
  ForLivestockAuction,
  ForLivestockSale,
  ForNotClearingSale,
  ForNotHooks,
} from "containers/ForSaleType";

import { calculateTotalPriceCents } from "lib";

import { getBuyerInformationString } from "lib/businesses";
import { deepObjectChanges } from "lib/compare";
import { generateListingId, getCombinedLotNumber } from "lib/saleLot";

import {
  getBidders,
  getConsignmentById,
  getHasWriteAccessInCurrentSale,
  getIsConsigningAgent,
  getIsConsigningAgentAndSaleUnlocked,
  getSaleLotById,
  getVendorSplitSaleLotIdsByParentSaleLotId,
  selectBidderByBuyerInformation,
  selectBidderIdBySaleLotIdLookup,
  selectCurrentMixedBreedId,
} from "selectors";

import {
  useHasDeploymentPermission,
  useHasPermission,
} from "hooks/useHasPermission";

import { ActionsCollapse } from "./ActionsCollapse";
import { AdvancedDraftingCollapse } from "./AdvancedDrafting";
import ClearingSaleSaleLotPickupDetails from "./ClearingSaleSaleLotPickupDetails";
import { getDetailedSaleLotValidationSchema } from "./detailedSaleLotValidationSchema";
import EIDsCollapse from "./EIDsCollapse";
import GeneralCollapse from "./GeneralCollapse";
import { ConsignmentSectionHeader } from "./Header";
import { PenCollapse } from "./PenCollapse";
import SaleCollapse from "./SaleCollapse";
import ScanningCollapse from "./ScanningCollapse";

const isNew = initialValues => !("id" in initialValues);

const alwaysEditableFields = ["invoiceToBusinessId", "dentitionId"];

const Form = ({
  section,
  changeSection,
  action,
  closeSelf,
  consignment,
  deleteSaleLot,
  initialValues,
  onAfterClose,
  onBeforeClose,
  saleLotId,
  showCreateAnother,
  speciesAttributes,
  rounds,
  sale,
  namespace: ns,
  hasChangeSaleLotPermission,
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    setTouched,
    setFieldTouched,
    setFieldValue,
    submitCount,
    touched,
    values,
  } = useFormikContext();

  // TODO - set defaults for accredittions.
  const {
    breed_id: breedId,
    consignment_id: selectedConsignmentId,
    quantity,
    quantityProgeny,
    draftingAttributes: {
      advancedBreed1Hd,
      advancedBreed1Id,
      advancedBreed2Id,
      advancedBreed2Hd,
      advancedBreed3Hd,
    },
    clearingSaleAttributes: { status },
  } = values;

  const selectedConsignment = useSelector(
    getConsignmentById(selectedConsignmentId),
  );

  const mixedBreedId = useSelector(selectCurrentMixedBreedId);

  const hasWriteAccessInCurrentSale = useSelector(
    state =>
      getHasWriteAccessInCurrentSale(state) ||
      getIsConsigningAgentAndSaleUnlocked(state),
  );

  const isReadOnly =
    !hasWriteAccessInCurrentSale ||
    (!isNew(initialValues) && !hasChangeSaleLotPermission);

  const isConsigningAgent = useSelector(getIsConsigningAgent);

  const hasFeatureVendorSplitsPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureVendorSplits,
  );
  const hasFeaturePercentageVendorSplitsPermission = useHasDeploymentPermission(
    DeploymentPermissions.featurePercentageVendorSplits,
  );
  const hasVendorSplitsPermission =
    hasFeatureVendorSplitsPermission ||
    hasFeaturePercentageVendorSplitsPermission;

  const vendorSplitSaleLotIds = useSelector(
    getVendorSplitSaleLotIdsByParentSaleLotId(saleLotId),
  );

  // Allow submission if:
  //  - we have write access
  //  - it's a consigning agent creating
  //  - or editing a PENDING salelot.
  //  - ONLY specific fields have been touched.

  const onlyWritableFieldsTouched =
    Object.keys(touched).length > 0 &&
    Object.keys(touched).every(touchedField =>
      alwaysEditableFields.includes(touchedField),
    );

  const allowSubmit =
    hasWriteAccessInCurrentSale ||
    (sale.sale_type === SaleTypes.CLEARING &&
      isConsigningAgent &&
      (status === LotStatus.PENDING || !saleLotId)) ||
    (onlyWritableFieldsTouched && hasChangeSaleLotPermission);

  // When the breed and quantity is set, and the advanced drafting fields are not, set them.
  React.useEffect(() => {
    if (breedId && advancedBreed1Id === null) {
      setFieldValue("draftingAttributes.advancedBreed1Id", breedId, true);
    }
  }, [breedId, advancedBreed1Id, setFieldValue]);

  React.useEffect(() => {
    if (
      quantity &&
      !advancedBreed2Hd &&
      !advancedBreed3Hd &&
      advancedBreed1Hd !== quantity
    ) {
      setFieldValue("draftingAttributes.advancedBreed1Hd", quantity, true);
    }
  }, [
    quantity,
    advancedBreed1Hd,
    advancedBreed2Hd,
    advancedBreed3Hd,
    setFieldValue,
  ]);

  // If the advanced breeds 1 and 2 are set, the primary breed should flip to mixed
  React.useEffect(() => {
    if (
      mixedBreedId &&
      breedId !== mixedBreedId &&
      !!advancedBreed1Id &&
      !!advancedBreed2Id
    ) {
      setFieldValue("breed_id", mixedBreedId, true);
    }
  }, [
    breedId,
    advancedBreed1Id,
    advancedBreed2Id,
    mixedBreedId,
    setFieldValue,
  ]);

  // change the main breed by the advanced drafting 1
  const onChangeBreed = field => {
    setFieldValue("breed_id", field, true);
  };

  // if breedId is changed and different from advanced breed1 and there is only one advanced breed, change breed1 to the main breed
  React.useEffect(() => {
    if (breedId !== advancedBreed1Id && !advancedBreed2Id) {
      setFieldValue(
        withNamespace(ns, "draftingAttributes.advancedBreed1Id"),
        breedId,
        true,
      );
    }
  }, [
    breedId,
    advancedBreed1Id,
    advancedBreed2Id,
    mixedBreedId,
    ns,
    setFieldValue,
  ]);

  const toggleSection = sectionName => () => {
    changeSection(section, sectionName);
  };

  const commonProps = {
    values,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
    setTouched,
  };

  const deploymentSaleId =
    selectedConsignment?.deployment_sale || consignment.deployment_sale;
  const deploymentId = sale.deployment_sales?.find(
    ds => ds.deployment_sale_id === deploymentSaleId,
  )?.deployment_id;
  const onClose = () => {
    onBeforeClose && onBeforeClose(null);
    closeSelf();
    onAfterClose && onAfterClose(null, false);
  };

  return (
    <ZoomyDialog open onClose={onClose} fullWidth scroll="paper">
      <FormikForm data-tour="salelot-modal">
        <DialogTitle onClose={onClose}>
          <Row justifyBetween alignCenter>
            <Row alignCenter>
              {saleLotId && (
                <AuditLogLink
                  auditLogType={AuditLogTypes.SALE_LOT}
                  dataId={saleLotId}
                  returnTo={window.location.hash}
                />
              )}
              &nbsp;
              {isNew(initialValues)
                ? "Create Lot"
                : `Edit Lot ${getCombinedLotNumber(values) || ""}`}
            </Row>
            <Row>
              <IntegrationIcon saleLotValues={values} />
              {saleLotId ? (
                <ClickableCommentIcon
                  commentType={CommentTypes.SALE_LOT}
                  commentTypeId={saleLotId}
                  returnTo={window.location.hash}
                />
              ) : null}
            </Row>
          </Row>
        </DialogTitle>
        <DialogContent dividers form>
          <ConsignmentSectionHeader readOnly={isReadOnly} />

          <GeneralCollapse
            isOpen={section === SaleLotModalSection.GENERAL}
            onToggle={toggleSection(SaleLotModalSection.GENERAL)}
            speciesAttributes={speciesAttributes}
            isNew={isNew(initialValues)}
            deleteSaleLot={deleteSaleLot}
            closeSelf={onClose}
            rounds={rounds}
            deploymentId={deploymentId}
            {...commonProps}
            readOnly={isReadOnly}
            hasChangeSaleLotPermission={hasChangeSaleLotPermission}
          />

          <ForNotClearingSale>
            <AdvancedDraftingCollapse
              isOpen={section === SaleLotModalSection.INTERFACES}
              onToggle={toggleSection(SaleLotModalSection.INTERFACES)}
              saleLot={values}
              setFieldValue={setFieldValue}
              commonProps={commonProps}
              onChangeBreed={onChangeBreed}
              readOnly={isReadOnly}
            />
          </ForNotClearingSale>
          <ForClearingSale>
            <ClearingSaleSaleLotPickupDetails
              consignmentId={selectedConsignmentId}
              isOpen={section === SaleLotModalSection.PICKUP_DETAILS}
              onToggle={toggleSection(SaleLotModalSection.PICKUP_DETAILS)}
            />
          </ForClearingSale>

          <ForLivestockAuction>
            <PenCollapse
              isOpen={section === SaleLotModalSection.AUCTION_PEN}
              onToggle={toggleSection(SaleLotModalSection.AUCTION_PEN)}
              commonProps={commonProps}
              action={action}
              readOnly={isReadOnly}
            />
          </ForLivestockAuction>
          <ForLivestockSale>
            {!isNew(initialValues) && (
              <ScanningCollapse
                isOpen={section === SaleLotModalSection.SCANNING}
                onToggle={toggleSection(SaleLotModalSection.SCANNING)}
                saleLotId={initialValues.id}
                quantity={quantity}
                quantityProgeny={quantityProgeny}
                readOnly={isReadOnly}
              />
            )}
          </ForLivestockSale>
          <SaleCollapse
            deploymentSaleId={consignment.deployment_sale}
            isOpen={section === SaleLotModalSection.SELLING}
            onToggle={toggleSection(SaleLotModalSection.SELLING)}
            quantity={values.quantity}
            readOnly={isReadOnly}
          />

          {!isNew(initialValues) && (
            <>
              <ForNotHooks>
                <MediaCollapse
                  isOpen={section === SaleLotModalSection.MEDIA}
                  onToggle={toggleSection(SaleLotModalSection.MEDIA)}
                  saleLotId={initialValues.id}
                  saleType={sale.sale_type}
                  readOnly={isReadOnly}
                />
              </ForNotHooks>

              <ForClearingSale>
                <IntegrationCollapse
                  isOpen={section === SaleLotModalSection.INTEGRATIONS}
                  onToggle={toggleSection(SaleLotModalSection.INTEGRATIONS)}
                  consignmentId={selectedConsignmentId}
                />
              </ForClearingSale>

              <ForLivestockSale>
                <EIDsCollapse
                  isOpen={section === SaleLotModalSection.EIDS}
                  onToggle={toggleSection(SaleLotModalSection.EIDS)}
                  saleLotId={saleLotId}
                  vendorPropertyId={
                    initialValues.consignment?.vendor_property_id
                  }
                  readOnly={isReadOnly}
                />
                {hasVendorSplitsPermission &&
                  vendorSplitSaleLotIds.length > 0 && (
                    <LegacyVendorSplitCollapse
                      isOpen={
                        section === SaleLotModalSection.LEGACY_VENDOR_SPLITS
                      }
                      onToggle={toggleSection(
                        SaleLotModalSection.LEGACY_VENDOR_SPLITS,
                      )}
                      saleLotId={saleLotId}
                      readOnly={isReadOnly}
                    />
                  )}

                {hasVendorSplitsPermission && (
                  <VendorSplitCollapse
                    isOpen={section === SaleLotModalSection.VENDOR_SPLITS}
                    onToggle={toggleSection(SaleLotModalSection.VENDOR_SPLITS)}
                    saleLotId={saleLotId}
                    readOnly={isReadOnly}
                  />
                )}
              </ForLivestockSale>
              <CurrentSaleReadOnly>
                <WaitForSync requiredData={[ApiModel.SALE_LOTS]}>
                  <ActionsCollapse
                    isOpen={section === SaleLotModalSection.ACTIONS}
                    onToggle={toggleSection(SaleLotModalSection.ACTIONS)}
                    toggleEidsSection={toggleSection(SaleLotModalSection.EIDS)}
                    saleLotId={saleLotId}
                    onClose={onClose}
                    readOnly={isReadOnly}
                  />
                </WaitForSync>
              </CurrentSaleReadOnly>
            </>
          )}

          {showCreateAnother && (
            <Row justifyEnd alignEnd style={{ marginTop: 20 }}>
              <CheckBox name="createAnother" label="Create another" />
            </Row>
          )}
        </DialogContent>
        <DialogActions>
          <SecondaryButton data-tour="cancel" type="button" onClick={onClose}>
            Cancel
          </SecondaryButton>
          {allowSubmit ? (
            <Button
              data-tour="submit"
              type="submit"
              disabled={
                (submitCount > 0 && !isValid) ||
                (!isNew(initialValues) && !isValid)
              }
            >
              {sale.sale_type === SaleTypes.CLEARING && isNew(initialValues)
                ? "Save & Continue"
                : "Submit"}
            </Button>
          ) : null}
        </DialogActions>
      </FormikForm>
    </ZoomyDialog>
  );
};

Form.propTypes = {
  closeSelf: PropTypes.func.isRequired,
  speciesAttributes: PropTypes.object,
  initialValues: PropTypes.object,
  deleteSaleLot: PropTypes.func,
};

function validateDraftingAttributes(values, mixedBreedId) {
  if (values.exportSites?.includes(ExportSites.AUCTIONS_PLUS)) {
    const {
      draftingAttributes: {
        advancedBreed1Id,
        advancedBreed2Id,
        advancedBreed1Hd,
        advancedBreed2Hd,
        advancedBreed3Hd,
        advancedBreed3Id,
      },
      breed_id,
      quantity,
    } = values;

    const breedCount = [
      advancedBreed1Hd,
      advancedBreed2Hd,
      advancedBreed3Hd,
    ].reduce((acc, breed) => {
      if (breed) {
        return (acc += breed);
      } else {
        return acc;
      }
    }, 0);

    if (breedCount !== quantity) {
      return {
        advancedBreed:
          "Advanced breed head counts have to be equal to sale lot quantity",
      };
    }
    if (
      !advancedBreed2Id &&
      !advancedBreed3Id &&
      advancedBreed1Id !== breed_id
    ) {
      return {
        advancedBreed:
          "Main breed has to be the same as advanced drafting breed",
      };
    }
    if ((advancedBreed2Id || advancedBreed3Id) && breed_id !== mixedBreedId) {
      return {
        advancedBreed:
          "Main breed has to be set to mixed if different breeds are present",
      };
    }
  }
  return null;
}

const DetailedSaleLotModal = props => {
  const {
    addSaleLotWithPens,
    closeSelf,
    consignmentId,
    consignmentAccreditationDefaults,
    copiedSaleLot,
    initialValues,
    onBeforeClose,
    onAfterClose,
    patchSaleLot,
    addBidderRegistration,
    previousSaleLot,
    sale,
    section,
    changeSection,
    saleLotId,
  } = props;

  const initialValuesRef = useRef(initialValues);

  const bidderIdBySaleLotIdLookup = useSelector(
    selectBidderIdBySaleLotIdLookup,
  );

  const bidderLookup = useSelector(getBidders);

  const bidderId = bidderIdBySaleLotIdLookup[saleLotId];

  const bidder = bidderLookup[bidderId];

  const bidderByBuyerInformationLookup = useSelector(
    selectBidderByBuyerInformation,
  );

  const hasChangeSaleLotPermission = useHasPermission(
    getSaleLotById(saleLotId),
    SaleLotPermissions.update,
  );
  const openAdvancedDraftingSection = () => {
    if (section !== SaleLotModalSection.INTERFACES) {
      changeSection(section, SaleLotModalSection.INTERFACES);
    }
  };

  const mixedBreedId = useSelector(selectCurrentMixedBreedId);
  const handleSubmit = (values, { setErrors, setSubmitting }) => {
    if (!isNew(initialValues) && !hasChangeSaleLotPermission) {
      return null;
    }

    if (sale.sale_type !== SaleTypes.CLEARING) {
      const draftingErrors = validateDraftingAttributes(values, mixedBreedId);
      if (draftingErrors) {
        setErrors(draftingErrors);
        openAdvancedDraftingSection();
        setSubmitting(false);
        return;
      }
    }

    if (
      sale.using_registered_bidders &&
      values.bidderNumber &&
      values.bidderNumber !== initialValues.bidderNumber
    ) {
      const buyerInformationString = getBuyerInformationString(
        values.buyer_id,
        values.destination_property_id,
        values.buyer_way?.name,
      );

      const associatedBidder =
        bidderByBuyerInformationLookup[buyerInformationString];

      if (!associatedBidder) {
        // if a bidder does not exist for the current buyer/way/pic combination, create one
        addBidderRegistration(uuidv4(), {
          registrationNumber: values.bidderNumber,
          businessId: values.buyer_id,
          defaultPropertyId: values.destination_property_id,
          buyerWay: values?.buyer_way?.name,
        });
      }
    }

    // Pull the values out into a provisional patch.
    const { buyer: ignored, ...patch } = { ...values };

    // Munge the price back together.
    patch.total_price_cents = calculateTotalPriceCents({
      unitPrice: values.unit_price ? parseFloat(values.unit_price) : 0,
      quantity: values.quantity,
      pricing_type_id: values.pricing_type_id,
      total_mass_grams: values.total_mass_grams,
    });

    if (isNew(initialValues)) {
      patch.created = new Date().toUTCString();
      const newSaleLotId = uuidv4();
      addSaleLotWithPens(newSaleLotId, patch);

      onBeforeClose && onBeforeClose(newSaleLotId);
      closeSelf();
      onAfterClose && onAfterClose(newSaleLotId, values.createAnother);
    } else {
      // Compare what we've actually changed, to what was there when we opened the form (note, this is potentially
      // different to what's in state, too!)
      const { buyer: ignored2, ...initialValuesWithoutBuyer } =
        initialValuesRef.current;
      const deepPatch = deepObjectChanges(initialValuesWithoutBuyer, patch, [
        [
          "total_mass_grams",
          "quantity",
          "total_price_cents",
          "pricing_type_id",
        ],
        ["buyer_id", "buyer_way"],
      ]);

      // The deepObjectChanges algorithm is too general to handle the intricacies of all the fields on a Sale Lot,
      // manage all the misses here.  Non simple fields (objects/arrays) that require the whole object if any part of it has changed.
      const complexFields = ["exportSites", "auction_pen", "deliveryPen"];
      complexFields.forEach(fieldName => {
        if (!isEqual(initialValuesWithoutBuyer[fieldName], patch[fieldName])) {
          deepPatch[fieldName] = patch[fieldName];
        }
      });

      // Use a purely replace algorithm for Labels
      if (
        Array.isArray(patch.labels) &&
        patch.labels !== initialValuesWithoutBuyer.labels
      ) {
        deepPatch.labels = patch.labels;
      }

      patchSaleLot(
        { id: initialValues.id, ...deepPatch },
        { changeReason: "Updated from edit sale lot" },
      );

      onBeforeClose && onBeforeClose(values.id);
      closeSelf();
      onAfterClose && onAfterClose(values.id, false);
    }
  };

  const getInitialValues = () => {
    // Required fields need to go in initialValues or they will not have visible
    // errors until they are touched by the user.
    const requiredFields = {
      quantity: 0,
      sale_round_id: -1,
      clearingSaleAttributes: {
        status: LotStatus.ACTIVE,
      },
    };

    // Auto select the round if there is only one.
    if (sale.rounds.length === 1) {
      requiredFields.sale_round_id = sale.rounds[0].id;
    }
    const templateSaleLot = copiedSaleLot || previousSaleLot;
    const newSaleLot = isNew(props.initialValues);
    // Only copy fields if creating a new lot i.e. has no ID yet
    const copiedFields =
      newSaleLot && templateSaleLot
        ? {
            sale_round_id: templateSaleLot.sale_round_id,
            breed_id: templateSaleLot.breed_id,
            sex_id: templateSaleLot.sex_id,
            age_id: templateSaleLot.age_id,
            exemption_id: templateSaleLot.exemption_id,
            pricing_type_id: templateSaleLot.pricing_type_id,
            listingId: generateListingId(),
            draftingAttributes: templateSaleLot.draftingAttributes
              ? Object.keys(templateSaleLot.draftingAttributes).reduce(
                  (result, k) => {
                    // Never copy some of the variables; if the variables have been loaded from a consignment default, don't
                    // drag the template version over.
                    if (
                      !IGNORED_DRAFTING_ATTRIBUTES.includes(k) &&
                      !consignmentAccreditationDefaults[k]
                    ) {
                      result[k] = templateSaleLot.draftingAttributes[k];
                    }
                    return result;
                  },
                  {},
                )
              : {},
          }
        : {};

    const advancedDraftingDefaults =
      newSaleLot && sale.species_id === Species.CATTTLE
        ? {
            ageRangeTimeUnit: initialValues.ageRangeTimeUnit || "months",
            advancedBreed1Id: initialValues.breed_id,
            advancedBreed1Hd:
              initialValues.advancedBreed1Hd || initialValues.quantity,
          }
        : {};

    return {
      bidderNumber: bidder ? bidder.registrationNumber : null,
      createAnother: false,
      useConsignmentPickupDetails: initialValues.pickupAddress === null,
      consignment_id: consignmentId,
      buyer_id: sale.default_buyer_id,
      destination_property_id: sale.default_property_id,
      ...requiredFields,
      ...initialValues,
      ...copiedFields,
      draftingAttributes: {
        ...initialValues.draftingAttributes,
        ...advancedDraftingDefaults,
        ...copiedFields.draftingAttributes,
      },
      clearingSaleAttributes: {
        ...requiredFields.clearingSaleAttributes,
        ...initialValues.clearingSaleAttributes,
        ...copiedFields.clearingSaleAttributes,
      },
    };
  };
  const validationSchema = getDetailedSaleLotValidationSchema(sale.sale_type);
  const formInitialValues = getInitialValues();

  return (
    <Formik
      key={formInitialValues.id}
      onSubmit={handleSubmit}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
    >
      <Form
        {...props}
        hasChangeSaleLotPermission={hasChangeSaleLotPermission}
      />
    </Formik>
  );
};

export default DetailedSaleLotModal;
