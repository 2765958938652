import React, { memo, useContext, useState } from "react";

import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { Popover } from "@material-ui/core";
import PropTypes from "prop-types";

import FontAwesomeButton, {
  ButtonIcon,
} from "components/Button/FontAwesomeButton";
import { Row } from "components/Layout";
import { OptionToggler } from "components/OptionToggler";

import { FiltersOptionsLayout } from "./Layout";
import {
  WeighBridgeSaleDispatchContext,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";

const PenningFilterOptions = [
  {
    value: true,
    label: "Penned",
  },
  {
    value: false,
    label: "Not Penned",
  },
];

export function WeighBridgeFiltersComponent(props) {
  const { filters } = props;

  const { updateFilters } = useContext(WeighBridgeSaleDispatchContext);

  const [filtersButtonEl, setFiltersButtonEl] = useState(null);

  const isFiltersVisible = Boolean(filtersButtonEl);

  function onChangePennedFilter(value) {
    updateFilters({ ...filters, penned: value });
  }

  function toggleFiltersVisible(event) {
    setFiltersButtonEl(isFiltersVisible ? null : event.target);
  }

  function closeFilters() {
    setFiltersButtonEl(null);
  }

  return (
    <Row>
      <FontAwesomeButton
        onClick={toggleFiltersVisible}
        inline
        size={2}
        padding={10}
        data-tour="filter"
      >
        <ButtonIcon icon={faFilter} />
      </FontAwesomeButton>

      <Popover
        open={isFiltersVisible}
        anchorEl={filtersButtonEl}
        onClose={closeFilters}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <FiltersOptionsLayout>
          <OptionToggler
            name="Sale Lots Visible"
            value={filters.penned}
            onChange={onChangePennedFilter}
            options={PenningFilterOptions}
          />
        </FiltersOptionsLayout>
      </Popover>
    </Row>
  );
}

const WeighBridgeFilters = memo(
  WeighBridgeFiltersComponent,
  (a, b) => a.filters.penned === b.filters.penned,
);
WeighBridgeFilters.propTypes = {
  filters: PropTypes.shape({
    penned: PropTypes.bool.isRequired,
  }),
};

function StateAdapter() {
  const { filters } = useContext(WeighBridgeSaleStateContext);

  return <WeighBridgeFilters filters={filters} />;
}

export default memo(StateAdapter);
