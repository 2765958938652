import React from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import {
  addSaleLot,
  closeConfirmModal,
  deleteConsignment,
  deleteSaleLot,
  openConfirmModal,
  patchSaleLot,
} from "actions";

import { addAuctionPen } from "actions/auctionPens";

import { SaleLotModal } from "components/SaleLotBobbyCalf";

import { SaleRoundName } from "constants/sale";

import { saleIdOnly } from "lib/sale";

import {
  currentSaleSelector,
  getAuctionPensByRound,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
  getNestedConsignments,
  getNextAvailableAuctionPen,
  getSaleLotsBySale,
  getSaleRoundIdByName,
  selectEidsBySaleLotIdLookup,
} from "selectors";

const BobbyCalfSaleLot = props => {
  const { history } = props;

  return (
    <SaleLotModal
      closeSelf={() => {
        history.goBack();
      }}
      {...props}
    />
  );
};

const mapStateToProps = (state, props) => {
  // saleyard name
  const { match } = props;
  const { params } = match;
  const { saleyard } = params;

  // saleLot to edit
  const saleLots = Object.values(getSaleLotsBySale(state, saleIdOnly(props)));
  const saleLotToEdit = saleLots.find(
    saleLot => saleLot.id === params.saleLotId,
  );
  const saleRoundId = getSaleRoundIdByName(state, SaleRoundName.BobbyCalf);

  const auctionPens =
    getAuctionPensByRound(state, saleIdOnly(props)).get(
      saleRoundId.toString(),
    ) || [];

  const auctionPen =
    saleLotToEdit && auctionPens.length
      ? auctionPens.find(ap => ap.auctionPenId === saleLotToEdit.auction_pen_id)
      : {};

  return {
    selectedSale: currentSaleSelector(state),
    consignments: Object.values(
      getNestedConsignments(state, saleIdOnly(props)),
    ),
    buyers: Object.values(
      selectPropertyEnrichedBusinessByBusinessIdLookup(state, props),
    ),
    saleLotToEdit,
    saleyard,
    saleLots,
    nextAvailableAuctionPen: getNextAvailableAuctionPen(
      state,
      saleIdOnly(props),
    ),
    scans: selectEidsBySaleLotIdLookup(state),
    auctionPens,
    auctionPen,
    saleRoundId,
  };
};

const mapDispatchToProps = {
  openConfirmModal,
  closeConfirmModal,
  addAuctionPen,
  addSaleLot,
  patchSaleLot,
  deleteSaleLot,
  deleteConsignment,
};

BobbyCalfSaleLot.propTypes = {
  selectedSale: PropTypes.object.isRequired,
  consignments: PropTypes.array,
  buyers: PropTypes.array,
  saleLotToEdit: PropTypes.object,
  saleyard: PropTypes.string.isRequired,
  saleLots: PropTypes.array,
  nextAvailableAuctionPen: PropTypes.number,
  scans: PropTypes.array,
  soldPen: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(BobbyCalfSaleLot);
