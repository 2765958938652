import React from "react";

import { faBackspace, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { Field, getIn, setIn, useField } from "formik";

import { Warning } from "components/ErrorMessage";
import {
  withArrayNamespace,
  withNamespace,
} from "components/Form/FormikControls";
import { Tooltip } from "components/Form/FormikControls/Tooltip";

import { ValueSource } from "constants/ruleBooks";

import { CheckComparatorDisplayNameMap } from "containers/Settings/RuleBooks/constants";
import { DataSource } from "containers/Settings/RuleBooks/DataSource";
import {
  getInputPropsForPropertyType,
  getParentFieldId,
  getPropertyType,
  getSchemaField,
  PropertyType,
  PropertyTypeCheckComparatorMap,
  useAutoSizeInput,
} from "containers/Settings/RuleBooks/lib";
import { useRuleFieldSchema } from "containers/Settings/RuleBooks/schemaContext";

export function Check(props) {
  const { criteriaOptions, namespace: ns, parentFieldId } = props;

  const schema = useRuleFieldSchema();

  const comparatorRef = useAutoSizeInput();
  const valueRef = useAutoSizeInput();

  const [
    { value },
    // eslint-disable-next-line no-unused-vars
    ignored,
    { setValue },
  ] = useField(ns);

  const valuesFieldName = withNamespace(ns, "values");
  const comparatorFieldName = withNamespace(ns, "comparator");
  const values = getIn(value, "values");

  const query = getIn(value, "field");
  const attribute = getIn(value, "field.attribute");

  const valueParentId = getParentFieldId(query, parentFieldId);
  const parentField = getSchemaField(valueParentId, schema);
  const fieldProperties = parentField?.properties || [];

  const selectedProperty = fieldProperties.find(property =>
    typeof property === "string"
      ? property === attribute
      : property.name === attribute,
  );

  const valuePresets = selectedProperty?.set;

  // If we have value presets, and we have values that are not in that list show a warning.
  // Note, that this is only an end user issue if you are changing the fields.  The values are still stored and submitted.
  const valuePresetValues = valuePresets?.map(valuePreset => valuePreset[0]);
  const valuesNotInPresets = valuePresetValues
    ? values.filter(value => !valuePresetValues.includes(value))
    : null;
  const warning =
    valuesNotInPresets && valuesNotInPresets.length > 0
      ? "There are values in your criteria that are no longer available for selection."
      : null;

  function onClickAddAnotherValue() {
    setValue(setIn(value, "values", [...values, ""]));
  }

  function removeValueIndex(index) {
    const nextValues = values.slice();
    nextValues.splice(index, 1);
    setValue(setIn(value, "values", nextValues));
  }

  function onAfterFieldChanged(field) {
    setValue({ ...value, field, comparator: "", values: [""] });
  }

  const propertyType =
    query && query.source === ValueSource.FIELD
      ? getPropertyType(selectedProperty)
      : // Give the users as much precision as possible for sum and count
        PropertyType.CURRENCY;

  return (
    <section>
      {warning ? <Warning>{warning}</Warning> : null}
      <p>
        <span>Data Source: </span>
        <DataSource
          criteriaOptions={criteriaOptions}
          namespace={withNamespace(ns, "field")}
          onAfterChanged={onAfterFieldChanged}
          parentFieldId={parentFieldId}
        />
      </p>
      <p>
        <span>Comparator: </span>
        <Field
          as="select"
          className="p-2"
          innerRef={comparatorRef}
          name={comparatorFieldName}
          required
        >
          <option disabled value="">
            Select a Comparator
          </option>
          {PropertyTypeCheckComparatorMap[propertyType].map((comparator, i) => (
            <option key={i} value={comparator}>
              {CheckComparatorDisplayNameMap[comparator]}
            </option>
          ))}
        </Field>
      </p>
      <p>
        <span>Compare To: </span>

        {values.map((_ignored, index, array) => {
          function onClickRemoveValueIndex() {
            removeValueIndex(index);
          }

          return (
            <React.Fragment key={index}>
              {Array.isArray(valuePresets) ? (
                <Field
                  as="select"
                  className="p-2"
                  name={withArrayNamespace(valuesFieldName, index)}
                  placeholder="(value)"
                  innerRef={valueRef}
                  required
                  {...getInputPropsForPropertyType(propertyType)}
                >
                  <option disabled value="">
                    Select a Value
                  </option>
                  {valuePresets.map(([name, value], index) => (
                    <option value={value} key={index}>
                      {name}
                    </option>
                  ))}
                </Field>
              ) : (
                <Field
                  className="p-2"
                  name={withArrayNamespace(valuesFieldName, index)}
                  placeholder="(value)"
                  innerRef={valueRef}
                  required
                  {...getInputPropsForPropertyType(propertyType)}
                />
              )}

              {index > 0 && (
                <Tooltip title="Remove this value">
                  <IconButton onClick={onClickRemoveValueIndex}>
                    <FontAwesomeIcon icon={faBackspace} size="xs" />
                  </IconButton>
                </Tooltip>
              )}
              {index < array.length - 1 && <span className="p-2"> or </span>}
              {index === array.length - 1 && (
                <IconButton onClick={onClickAddAnotherValue}>
                  <FontAwesomeIcon icon={faPlus} size="xs" /> value
                </IconButton>
              )}
            </React.Fragment>
          );
        })}
      </p>
    </section>
  );
}
