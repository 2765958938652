import { createSelector } from "reselect";

import { getEmails } from "selectors/root";
import { selectSaleTitleByIdLookup } from "selectors/sales";

export const selectEmailAggridData = createSelector(
  [getEmails, selectSaleTitleByIdLookup],
  (emails, saleTitleByIdLookup) =>
    Object.values(emails).map(email => ({
      email,
      id: email.id,
      livestockSale: {
        title: saleTitleByIdLookup[email.livestockSaleId],
      },
    })),
);
