import React from "react";

import PropTypes from "prop-types";

const PlainCollapse = ({ isOpen, children }) => <>{isOpen && children}</>;

PlainCollapse.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
};

export default PlainCollapse;
