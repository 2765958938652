import { useState, useEffect, useCallback } from "react";

import { useSelector } from "react-redux";

import {
  ACTION_COLUMN_NAME,
  SELECT_COLUMN_ID,
  SELECT_COLUMN_NAME,
} from "constants/aggrid";
import { SelectColumnDefinition } from "constants/columnDefinitions/actions";

import { getSelectedViewIdByTable } from "selectors";

import { useIsMobile } from "./useScreenSize";

export const useHandleAgGridColumnDefs = (
  columnDefs,
  agGridInstance,
  tableName,
  rowsSelectable = false,
) => {
  const [handledColumnDefs, setHandledColumnDefs] = useState(columnDefs);

  const selectedViewId =
    useSelector(getSelectedViewIdByTable(tableName)) || null;

  const isMobile = useIsMobile();

  const shouldLockPinned = useCallback(
    colDef => (isMobile ? true : colDef.lockPinned),
    [isMobile],
  );

  const shouldBePinned = useCallback(
    colDef =>
      isMobile && colDef.headerName !== ACTION_COLUMN_NAME
        ? false
        : colDef.pinned,
    [isMobile],
  );

  const shouldBeHidden = useCallback(
    colDef => {
      if (isMobile) {
        if (colDef.showOnMobile) {
          return false;
        }
        if (colDef.hideOnMobile) {
          return true;
        }
        // always want to show actions and the select columns
        return colDef.headerName !== ACTION_COLUMN_NAME ||
          colDef.headerName !== SELECT_COLUMN_NAME
          ? colDef.hide
          : false;
      }
      return colDef.hide;
    },
    [isMobile],
  );

  const buildChildren = useCallback(
    colDef => {
      if (isMobile && colDef.children) {
        return colDef.children.map(childColDef => {
          return {
            ...childColDef,
            ...((childColDef.hideOnMobile || colDef.hideOnMobile) && {
              hide: true,
            }),
          };
        });
      }

      return colDef.children;
    },
    [isMobile],
  );

  const setColumnWidth = useCallback(
    colDef => {
      if (isMobile) {
        return colDef.headerName === ACTION_COLUMN_NAME
          ? colDef.mobileWidth || 60
          : colDef.width;
      }
      return colDef.width;
    },
    [isMobile],
  );

  const setColumnFlex = useCallback(
    colDef => (isMobile && colDef.flexOnMobile ? 1 : null),
    [isMobile],
  );

  useEffect(() => {
    if (agGridInstance) {
      const instanceColumnDefs = agGridInstance.api.getColumnDefs();

      // reset row heights on grid ready - to ensure mobile heights are adhered to
      agGridInstance.api.resetRowHeights();

      const referenceColumnDefs = instanceColumnDefs.length
        ? instanceColumnDefs
        : columnDefs;

      // if we pass in rowsSelectable - add a select column to the left of the table
      if (rowsSelectable) {
        const columnDefsHaveSelectColumn = referenceColumnDefs.find(
          colDef => colDef.colId === SELECT_COLUMN_ID,
        );
        if (!columnDefsHaveSelectColumn) {
          referenceColumnDefs.unshift(SelectColumnDefinition);
        }
      }

      // handle custom mobileValues on column defs
      const updatedColumnDefs = Object.values(referenceColumnDefs).map(
        colDef => ({
          ...colDef,
          lockPinned: shouldLockPinned(colDef),
          pinned: shouldBePinned(colDef),
          hide: shouldBeHidden(colDef),
          children: buildChildren(colDef),
          flex: setColumnFlex(colDef),
          width: setColumnWidth(colDef),
        }),
      );
      setHandledColumnDefs(updatedColumnDefs);
      agGridInstance.api.refreshCells();
    }
  }, [
    rowsSelectable,
    isMobile,
    agGridInstance,
    columnDefs,
    selectedViewId,
    buildChildren,
    setColumnFlex,
    setColumnWidth,
    shouldBeHidden,
    shouldBePinned,
    shouldLockPinned,
  ]);
  return handledColumnDefs;
};
