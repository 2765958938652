import React from "react";

import {
  faCheck,
  faExclamationCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

export function euStatusRenderer(props) {
  if (!props.data) {
    return null;
  }
  const { value } = props;
  if (value) {
    if (value === "Y") {
      return <FaIcon icon={faCheck} color="success" />;
    } else if (value === "N") {
      return <FaIcon icon={faExclamationCircle} color="warning" />;
    }
  }
  return <FaIcon icon={faQuestionCircle} color="primary" />;
}
