import { ENVD_DOCUMENT } from "constants/actionTypes";

import { serializeEnvdDocument } from "lib/serializers/envd";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/envd-documents/", id, action);

export const EnvdDocumentAction = offlineActionCreator(
  ENVD_DOCUMENT,
  serializeEnvdDocument,
  urlCreator,
  false,
);

EnvdDocumentAction.refresh = (options = {}) => {
  const optionsWithParams = { action: "refresh", ...options };
  return {
    type: ENVD_DOCUMENT.REFRESH.REQUEST,
    meta: {
      offline: {
        effect: {
          url: urlCreator(optionsWithParams),
          method: "PATCH",
        },
        commit: {
          type: ENVD_DOCUMENT.REFRESH.SUCCESS,
        },
        rollback: {
          type: ENVD_DOCUMENT.REFRESH.FAILURE,
        },
      },
    },
  };
};
