import { css } from "styled-components/macro";

export const SecondaryStyles = css(
  ({ theme, color }) => `
  color: ${color ? theme.colors[color] : theme.colors.primary};
  background-color: ${theme.colors.white};
  border: 1px solid ${color ? theme.colors[color] : theme.colors.primary};

  &:hover:enabled {
    color: ${theme.colors.white};
    background-color: ${color ? theme.colors[color] : theme.colors.primary};
  }
`,
);
