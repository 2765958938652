import {
  CustomTerminationChar,
  DecimalPointModifier,
  ErrorStateOutputModifier,
  getStaticTokenLength,
  getWeightDataLength,
  isControlToken,
  isLiteralToken,
  isStatusDataToken,
  isWeightDataToken,
  LeadingZeroBlankingModifier,
  SignModifier,
  StatusCharCasingModifier,
  StatusDataOutput,
  WeightDataType,
  WeightModifier,
} from "./rinstrumBasicFormatSpecifier";

export const Sign = {
  NEGATIVE: 0,
  POSITIVE: 1,
};

export const Stability = {
  UNSTABLE: 0,
  STABLE: 1,
};

export const Units = {
  KILOGRAMS: 0,
  TONS: 1,
  GRAMS: 2,
  POUNDS: 3,
};

const Capacity = {
  IN_RANGE: 0,
  UNDERLOAD: 1,
  OVERLOAD: 2,
};

const UnitScalar = [1, 1000, 0.001, 0.45359237];

export const FormatDefaultsStartChar = 0x02;
export const FormatDefaultsEndChar1 = 0x03;
export const FormatDefaultsEndChar2 = 0x00;
export const FormatDefaultsUnits = Units.KILOGRAMS;

export class RinstrumBasicView {
  static InvalidBuffer = new ArrayBuffer(0);

  /**
   *
   * @param {{ format: number[], endChar1?: string, endChar2?: string, startChar?: string }} config
   * @param {ArrayBuffer} abBytes
   */
  constructor(config, abBytes) {
    this.arrayBuffer = abBytes;
    if (!(Array.isArray(config.format) && config.format.length > 0)) {
      throw Error(
        `Invalid format specifier ${typeof config.format}, "${config.format}"`,
      );
    }

    this.config = config;

    this.weightModifiers = {
      weightMode: WeightModifier.WEIGHT_8_CHARS,
      signMode: SignModifier.SIGN_SPACE_MINUS,
    };
    this.decimalPointMode = DecimalPointModifier.DECIMAL_POINT_FULL_STOP;
    this.leadingZeroMode = LeadingZeroBlankingModifier.LEADING_ZERO_SPACE;
    this.weightErrorMode = ErrorStateOutputModifier.ERROR_STATE_SEND_WEIGHT;
    this.weightTypeMode = WeightDataType.GROSS;
    this.casingMode = StatusCharCasingModifier.STATUS_CHARS_UPPERCASE;

    this.stability = Stability.STABLE;
    this.sign = Sign.POSITIVE;
    this.units = this.getDefaultUnits();
    this.capacity = Capacity.IN_RANGE;

    this.processFormat();
    this.processData();
  }

  processFormat() {
    for (const token of this.getFormatSpecifier()) {
      switch (token) {
        case WeightModifier.WEIGHT_NONE:
        case WeightModifier.WEIGHT_5_CHARS:
        case WeightModifier.WEIGHT_6_CHARS:
        case WeightModifier.WEIGHT_7_CHARS:
        case WeightModifier.WEIGHT_8_CHARS:
        case WeightModifier.WEIGHT_9_CHARS:
          this.weightModifiers.weightMode = token;
          break;

        case SignModifier.SIGN_NONE:
        case SignModifier.SIGN_SPACE_MINUS:
        case SignModifier.SIGN_PLUS_MINUS:
        case SignModifier.SIGN_ZERO_MINUS:
          this.weightModifiers.signMode = token;
          break;

        case DecimalPointModifier.DECIMAL_POINT_NONE:
        case DecimalPointModifier.DECIMAL_POINT_FULL_STOP:
        case DecimalPointModifier.DECIMAL_POINT_COMMA:
          this.decimalPointMode = token;
          break;

        case LeadingZeroBlankingModifier.LEADING_ZERO_ZERO:
        case LeadingZeroBlankingModifier.LEADING_ZERO_SPACE:
          this.leadingZeroMode = token;
          break;

        case ErrorStateOutputModifier.ERROR_STATE_SEND_WEIGHT:
        case ErrorStateOutputModifier.ERROR_STATE_SEND_BLANK:
        case ErrorStateOutputModifier.ERROR_STATE_SEND_DASHES:
          this.weightErrorMode = token;
          break;

        case StatusCharCasingModifier.STATUS_CHARS_UPPERCASE:
        case StatusCharCasingModifier.STATUS_CHARS_LOWERCASE:
          this.casingMode = token;
          break;

        case WeightDataType.SELECTED:
          this.weightTypeMode = WeightDataType.GROSS; // Default to gross until we know more information
          break;
        case WeightDataType.DISPLAYED:
        case WeightDataType.GROSS:
        case WeightDataType.NET:
        case WeightDataType.TARE:
        case WeightDataType.TOTAL:
          this.weightTypeMode = token;
          break;

        default:
          break;
      }
    }
  }

  processData() {
    const view = new Uint8Array(this.arrayBuffer);

    const payloadFormatTokens = [
      this.getStartChar(),
      ...this.getFormatSpecifier(),
      this.getEndChar1(),
      this.getEndChar2(),
    ];

    let offset = 0;
    for (const token of payloadFormatTokens) {
      if (isLiteralToken(token)) {
        if (view[offset] !== token) {
          // eslint-disable-next-line no-console
          console.error(
            `Invalid literal encountered at index ${offset}, expected "${token}", found "${
              view[0]
            }"`,
          );
          this.arrayBuffer = RinstrumBasicView.InvalidBuffer;
          return;
        }
        offset += getStaticTokenLength(token);
      } else if (isControlToken(token)) {
        if (token === CustomTerminationChar.NULL_CHAR) {
          if (view[offset] !== 0) {
            // eslint-disable-next-line no-console
            console.error(
              `Invalid terminator character encountered at index ${offset}, expected "${token}", found "${
                view[0]
              }"`,
            );
            this.arrayBuffer = RinstrumBasicView.InvalidBuffer;
            return;
          }
        }

        offset += getStaticTokenLength(token);
      } else if (isWeightDataToken(token)) {
        const dataSize = getWeightDataLength(this.weightModifiers);
        switch (token) {
          case WeightDataType.SELECTED:
          case WeightDataType.DISPLAYED:
          case WeightDataType.GROSS:
          case WeightDataType.NET:
          case WeightDataType.TARE:
          case WeightDataType.TOTAL:
            this.weightView = view.slice(offset, offset + dataSize);
            if (
              this.weightModifiers.signMode !== SignModifier.SIGN_NONE &&
              this.weightView[0] === 0x2d
            ) {
              this.sign = Sign.NEGATIVE;
            }
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(`Unknown weight type "${token}"`);
        }
        offset += dataSize;
      } else if (isStatusDataToken(token)) {
        const dataSize = getStaticTokenLength(token);
        const data = view.slice(offset, offset + dataSize);
        switch (token) {
          case StatusDataOutput.UNITS:
          case StatusDataOutput.UNITS_SPACE_ON_MOTION:
            if (!this.processUnitsData(data)) {
              // eslint-disable-next-line no-console
              console.error(`Unknown unit type "${data}"`);
              this.arrayBuffer = RinstrumBasicView.InvalidBuffer;
              return;
            }
            break;
          case StatusDataOutput.COMBINED_STATUS_NO_MOTION:
          case StatusDataOutput.COMBINED_STATUS_GROSS_NET_ONLY:
          case StatusDataOutput.COMBINED_STATUS_STANDARD:
            if (!this.processCombinedStatusData(data)) {
              // eslint-disable-next-line no-console
              console.error(`Unknown combined status type "${data}"`);
              this.arrayBuffer = RinstrumBasicView.InvalidBuffer;
              return;
            }
            break;
          case StatusDataOutput.STABILITY_ST_US_OVERLOAD_OL:
            if (!this.processMotionConditionStatusData(data)) {
              // eslint-disable-next-line no-console
              console.error(`Unknown unit type "${data}"`);
              this.arrayBuffer = RinstrumBasicView.InvalidBuffer;
              return;
            }
            break;
          case StatusDataOutput.MOTION_M_SPACE:
            if (!this.processMotionMSpaceStatusData(data)) {
              // eslint-disable-next-line no-console
              console.error(`Unknown motion status type "${data}"`);
              this.arrayBuffer = RinstrumBasicView.InvalidBuffer;
              return;
            }
            break;
          case StatusDataOutput.MOTION_M_S:
            if (!this.processMotionMSStatusData(data)) {
              // eslint-disable-next-line no-console
              console.error(`Unknown motion status type "${data}"`);
              this.arrayBuffer = RinstrumBasicView.InvalidBuffer;
              return;
            }
            break;
          case StatusDataOutput.CAPACITY_CONDITION_M_C_SPACE:
          case StatusDataOutput.CAPACITY_CONDITION_M_I_O_SPACE:
          case StatusDataOutput.LOAD_CONDITION_I_O_U:
          case StatusDataOutput.ZERO_Z_SPACE:
          case StatusDataOutput.RANGE_SINGLE_1_2:
          case StatusDataOutput.TIME:
          case StatusDataOutput.DATE:
            // TODO implement me
            break;
          default:
            break;
        }
        offset += dataSize;
      }
    }
  }

  processCombinedStatusData(arrayBuffer) {
    const statusString = String.fromCharCode
      .apply(null, arrayBuffer)
      .toLowerCase();
    switch (statusString) {
      case "g":
        this.weightTypeMode = WeightDataType.GROSS;
        break;
      case "n":
        this.weightTypeMode = WeightDataType.NET;
        break;
      case "e":
        this.units = Units.POUNDS;
        break;
      case "o":
        this.capacity = Capacity.OVERLOAD;
        break;
      case "u":
        this.capacity = Capacity.UNDERLOAD;
        break;
      case "m":
        this.stability = Stability.UNSTABLE;
        break;
      default:
        return false;
    }
    return true;
  }

  processMotionConditionStatusData(arrayBuffer) {
    const statusData1 = arrayBuffer[0];
    const statusData2 = arrayBuffer[1];
    if (this.casingMode === StatusCharCasingModifier.STATUS_CHARS_LOWERCASE) {
      if (statusData1 === 0x6f && statusData2 === 0x6c) {
        this.capacity = Capacity.OVERLOAD;
        return true;
      } else if (statusData1 === 0x73 && statusData2 === 0x74) {
        this.stability = Stability.STABLE;
        return true;
      } else if (statusData1 === 0x75 && statusData2 === 0x73) {
        this.stability = Stability.UNSTABLE;
        return true;
      }
    } else if (
      this.casingMode === StatusCharCasingModifier.STATUS_CHARS_UPPERCASE
    ) {
      if (statusData1 === 0x4f && statusData2 === 0x4c) {
        this.capacity = Capacity.OVERLOAD;
        return true;
      } else if (statusData1 === 0x53 && statusData2 === 0x54) {
        this.stability = Stability.STABLE;
        return true;
      } else if (statusData1 === 0x55 && statusData2 === 0x53) {
        this.stability = Stability.UNSTABLE;
        return true;
      }
    }
    return false;
  }

  processMotionMSpaceStatusData(arrayBuffer) {
    const statusData = arrayBuffer[0];
    if (statusData === 0x20) {
      this.stability = Stability.STABLE;
    } else if (
      (this.casingMode === StatusCharCasingModifier.STATUS_CHARS_LOWERCASE &&
        statusData === 0x6d) ||
      (this.casingMode === StatusCharCasingModifier.STATUS_CHARS_UPPERCASE &&
        statusData === 0x4d)
    ) {
      this.stability = Stability.UNSTABLE;
    } else {
      return false;
    }
    return true;
  }

  processMotionMSStatusData(arrayBuffer) {
    const statusData = arrayBuffer[0];
    if (
      (this.casingMode === StatusCharCasingModifier.STATUS_CHARS_LOWERCASE &&
        statusData === 0x73) ||
      (this.casingMode === StatusCharCasingModifier.STATUS_CHARS_UPPERCASE &&
        statusData === 0x53)
    ) {
      this.stability = Stability.STABLE;
    } else if (
      (this.casingMode === StatusCharCasingModifier.STATUS_CHARS_LOWERCASE &&
        statusData === 0x6d) ||
      (this.casingMode === StatusCharCasingModifier.STATUS_CHARS_UPPERCASE &&
        statusData === 0x4d)
    ) {
      this.stability = Stability.UNSTABLE;
    } else {
      return false;
    }
    return true;
  }

  processUnitsData(arrayBuffer) {
    const unitString = String.fromCharCode
      .apply(null, arrayBuffer)
      .toLowerCase();
    switch (unitString) {
      case " kg":
        this.units = Units.KILOGRAMS;
        break;
      case "  t":
        this.units = Units.TONS;
        break;
      case "  g":
        this.units = Units.GRAMS;
        break;
      case " lb":
        this.units = Units.POUNDS;
        break;
      case "   ":
        this.stability = Stability.UNSTABLE;
        break;
      default:
        return false;
    }
    return true;
  }

  getStartChar() {
    return typeof this.config.startChar === "number"
      ? this.config.startChar
      : FormatDefaultsStartChar;
  }

  getEndChar1() {
    return typeof this.config.endChar1 === "number"
      ? this.config.endChar1
      : FormatDefaultsEndChar1;
  }

  getEndChar2() {
    return typeof this.config.endChar2 === "number"
      ? this.config.endChar2
      : FormatDefaultsEndChar2;
  }

  getDefaultUnits() {
    return typeof this.config.units === "number"
      ? this.config.units
      : FormatDefaultsUnits;
  }

  getFormatSpecifier() {
    return this.config.format;
  }

  unitScalar() {
    return UnitScalar[this.units];
  }

  isPositive() {
    return this.sign === Sign.POSITIVE;
  }

  isStable() {
    return this.stability === Stability.STABLE;
  }

  weightRaw() {
    return String.fromCharCode.apply(null, this.weightView);
  }

  displayedWeightGrams() {
    let weightString = this.weightRaw();
    if (this.decimalPointMode === DecimalPointModifier.DECIMAL_POINT_COMMA) {
      weightString = weightString.replace(/,/g, ".");
    }
    const baseWeight = parseFloat(weightString.replace(/[^\d.]/g, ""));
    const negation = this.isPositive() ? 1 : -1;
    return negation * baseWeight * this.unitScalar() * 1000;
  }

  isValid() {
    return this.arrayBuffer !== RinstrumBasicView.InvalidBuffer;
  }
}
