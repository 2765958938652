import React from "react";

import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { FieldArray, useField } from "formik";

import { SlimButton } from "components/Button";
import { ButtonIcon } from "components/Button/FontAwesomeButton";
import { PreventPropagation } from "components/Events";
import { Button } from "components/Form";
import { BusinessField } from "components/Form/Fields";
import {
  ControlledLifeCycleInput,
  DecimalStringInputProps,
  PercentageInputProps,
  withNamespace,
} from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";

import { VendorSplitType } from "constants/vendorSplits";

import { useFieldValue } from "hooks";

export const Splits = ({ namespace = "" }) => {
  const [field, meta] = useField(withNamespace(namespace, "splits"));
  const splits = field.value || [];
  const { error } = meta;
  const type = useFieldValue(withNamespace(namespace, "type"));

  const amountProps =
    type === VendorSplitType.Head
      ? DecimalStringInputProps
      : PercentageInputProps;

  return (
    <FieldArray name={withNamespace(namespace, "splits")}>
      {arrayHelpers => {
        const addNew = () => {
          arrayHelpers.push({});
        };

        return (
          <>
            {splits.map((split, i) => {
              return (
                <React.Fragment key={split.id || i}>
                  <Grid item xs={6}>
                    <BusinessField
                      label="Vendor"
                      name={withNamespace(namespace, `splits.${i}.businessId`)}
                      activeOnly
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <ControlledLifeCycleInput
                      {...amountProps}
                      label="Amount"
                      name={withNamespace(namespace, `splits.${i}.amount`)}
                      required
                    />
                  </Grid>
                  <Grid item container xs={1} alignItems="flex-end">
                    <SlimButton
                      onClick={() => arrayHelpers.remove(i)}
                      color="deleteRed"
                    >
                      <ButtonIcon icon={faTrash} />
                    </SlimButton>
                  </Grid>
                </React.Fragment>
              );
            })}
            {error && (
              <>
                <Grid item xs={6} />
                <Grid item xs={5}>
                  <Error>{error}</Error>
                </Grid>
              </>
            )}
            <PreventPropagation onClick={addNew}>
              <Button data-tour="add" type="button">
                Add
              </Button>
            </PreventPropagation>
          </>
        );
      }}
    </FieldArray>
  );
};
