import { all, put, select, takeEvery } from "redux-saga/effects";

import { connectToDevice } from "actions";

import {
  CONNECT_TO_PLC,
  CONNECT_TO_SCALES,
  DEVICE_MESSAGE_RESPONSE,
  DRAFTING_DECISION,
} from "constants/actionTypes";

import {
  MT_SERIAL_DRIVER_NAME,
  MT_TCP_DRIVER_NAME,
  mtSerialProcessMessage,
  mtTcpProcessMessage,
  PLC_DRIVERS,
  rinstrum5000ProcessMessage,
  RINSTRUM_5000_DRIVER_NAME,
  THOMPSON_LONGHORN_ALLEN_BRADLEY_PLC_DRIVER_NAME,
  TlabAutoDraftPlc,
  tlabAutoDraftPlcProcessMessage,
  WEIGHBRIDGE_DRIVERS,
} from "lib/deviceDrivers";
import {
  MT_HOST_FILE_SESSION_DRIVER_NAME,
  mtHostProcessMessage,
} from "lib/deviceDrivers/mtHost";

import {
  getAutoDraftDecisionCodeByAuctionPenIdLookup,
  getAvailableDevices,
  selectConnectedPLC,
} from "selectors";

const DeviceMessageHandler = {
  [MT_SERIAL_DRIVER_NAME]: mtSerialProcessMessage,
  [MT_TCP_DRIVER_NAME]: mtTcpProcessMessage,
  [RINSTRUM_5000_DRIVER_NAME]: rinstrum5000ProcessMessage,
  [THOMPSON_LONGHORN_ALLEN_BRADLEY_PLC_DRIVER_NAME]:
    tlabAutoDraftPlcProcessMessage,
  [MT_HOST_FILE_SESSION_DRIVER_NAME]: mtHostProcessMessage,
};

const AutoDraftHandler = {
  [THOMPSON_LONGHORN_ALLEN_BRADLEY_PLC_DRIVER_NAME]: TlabAutoDraftPlc,
};

function* getDevice(deviceId) {
  const state = yield select();

  return getAvailableDevices(state).find(
    device => device.deviceId === deviceId,
  );
}

function* getDeviceMessageHandler(deviceId) {
  const device = yield getDevice(deviceId);
  if (!device) {
    return;
  }

  return DeviceMessageHandler[device.deviceType];
}

function* onDeviceMessage(action) {
  const {
    deviceId,
    object: { type, status, payload },
  } = action;

  const handler = yield getDeviceMessageHandler(deviceId);
  if (!handler) {
    return;
  }

  const device = yield getDevice(deviceId);

  const chainedAction = handler(device, type, status, payload);
  if (!chainedAction) {
    return;
  }

  yield put(chainedAction);
}

function* getAutoDraftHandler(deviceId) {
  const device = yield getDevice(deviceId);

  if (!device) {
    return;
  }

  return AutoDraftHandler[device.deviceType].sendDraftingDecision;
}

function* onSendDraftingDecision(action) {
  const { draftingDecision } = action;
  const { draftPenId } = draftingDecision;

  const state = yield select();
  const connectedPLC = selectConnectedPLC(state);
  if (connectedPLC) {
    const { deviceId } = connectedPLC;
    const decision =
      getAutoDraftDecisionCodeByAuctionPenIdLookup(draftPenId)(state);

    const sendDraftingDecisionHandler = yield getAutoDraftHandler(deviceId);

    if (!sendDraftingDecisionHandler) {
      return;
    }

    const chainedAction = sendDraftingDecisionHandler(deviceId, decision);
    if (!chainedAction) {
      return;
    }

    yield put(chainedAction);
  }
}

function* onConnectToScales() {
  const state = yield select();
  yield all(
    getAvailableDevices(state)
      .filter(device => WEIGHBRIDGE_DRIVERS.indexOf(device.deviceType) > -1)
      .map(device => put(connectToDevice(device.deviceId))),
  );
}

function* onConnectToPLC() {
  const state = yield select();
  yield all(
    getAvailableDevices(state)
      .filter(device => PLC_DRIVERS.indexOf(device.deviceType) > -1)
      .map(device => put(connectToDevice(device.deviceId))),
  );
}

function* rootSaga() {
  yield takeEvery(DEVICE_MESSAGE_RESPONSE, onDeviceMessage);
  yield takeEvery(CONNECT_TO_SCALES, onConnectToScales);
  yield takeEvery(CONNECT_TO_PLC, onConnectToPLC);
  yield takeEvery(DRAFTING_DECISION.ACTION.REQUEST, onSendDraftingDecision);
}

export default rootSaga;
