import { NameType } from "constants/integrationBusiness";

import { formatAddress } from "lib/address";

function formatIntegrationBusinessAddress(address) {
  return formatAddress({
    addressingInformation: address.addressee,
    country: address.country,
    locality: address.city,
    postalCode: address.postCode,
    route: [address.line1, address.line2].filter(Boolean).join("\n"),
    state: address.state,
  }).trim();
}

function formatIntegrationBusinessContact(contact) {
  return `${[
    [contact.firstName, contact.lastName].filter(Boolean).join(" "),
    contact.emailAddress,
    contact.phone,
  ]
    .filter(Boolean)
    .join(", ")}`;
}

function formatIntegrationBusinessPhone(phone) {
  return `${[phone.phoneName, phone.phoneNumber].filter(Boolean).join(", ")}`;
}

function formatFilter(formatter, array) {
  return array
    .map((item, index, array) => [item, formatter(item, index, array)])
    .filter(([_ignored, itemFormatted]) => Boolean(itemFormatted));
}

export const INTEGRATION_BUSINESS_COLUMN_DEFINITIONS = [
  {
    checkboxSelection: true,
    field: "names",
    headerName: "Name",
    sortable: true,
    width: 300,
    filterValueGetter: params => {
      const names = params.getValue(params.colDef.field, params.node) || [];
      return names.map(n => n.value);
    },
    valueFormatter: params => {
      const names = params.value;
      if (!Array.isArray(names) || names.length === 0) {
        return "";
      }
      return names.find(n => n.type === NameType.NAME).value || names[0].value;
    },
  },
  {
    field: "addresses",
    filterValueGetter: params => {
      const addresses = params.getValue(params.colDef.field, params.node) || [];
      return addresses.map(formatIntegrationBusinessAddress);
    },
    valueFormatter: params => {
      const addresses = params.value;
      if (!Array.isArray(addresses) || addresses.length === 0) {
        return "";
      }
      const completedAddresses = formatFilter(
        formatIntegrationBusinessAddress,
        addresses,
      );
      if (completedAddresses.length === 0) {
        return "";
      } else if (completedAddresses.length === 1) {
        return completedAddresses[0][1];
      }
      const firstOrPrimaryAddress =
        completedAddresses.find(([address]) => address.type === "Primary") ||
        completedAddresses[0];
      return `${firstOrPrimaryAddress[1]}; +${
        completedAddresses.length - 1
      } more...`;
    },
    width: 280,
  },
  {
    field: "contacts",
    filterValueGetter: params => {
      const contacts = params.getValue(params.colDef.field, params.node) || [];
      return contacts.map(formatIntegrationBusinessContact);
    },
    valueFormatter: params => {
      const contacts = params.value;
      if (!Array.isArray(contacts) || contacts.length === 0) {
        return "";
      }

      const completedContacts = formatFilter(
        formatIntegrationBusinessContact,
        contacts,
      );
      if (completedContacts.length === 0) {
        return "";
      } else if (completedContacts.length === 1) {
        return completedContacts[0][1];
      }
      const firstOrPrimaryContact =
        completedContacts.find(([contact]) => contact.type === "Primary") ||
        completedContacts[0];
      return `${firstOrPrimaryContact[1]}; +${
        completedContacts.length - 1
      } more...`;
    },
  },
  {
    field: "phones",
    filterValueGetter: params => {
      const phones = params.getValue(params.colDef.field, params.node) || [];
      return phones.map(
        phone =>
          `${[phone.phoneName, phone.phoneNumber.replaceAll(/[^\d]+/g, "")]
            .filter(Boolean)
            .join(", ")}`,
      );
    },
    valueFormatter: params => {
      const phones = params.value;
      if (!Array.isArray(phones) || phones.length === 0) {
        return "";
      }
      const completedPhones = formatFilter(
        formatIntegrationBusinessPhone,
        phones,
      );
      if (completedPhones.length === 0) {
        return "";
      } else if (completedPhones.length === 1) {
        return completedPhones[0][1];
      }
      const firstOrPrimaryPhone =
        completedPhones.find(([phone]) => phone.type === "Primary") ||
        completedPhones[0];
      return `${firstOrPrimaryPhone[1]}; +${
        completedPhones.length - 1
      } more...`;
    },

    width: 160,
  },
];

export const INTEGRATION_BUSINESS_DEFAULT_COLUMN_DEFINITION = {
  filter: "agTextColumnFilter",
  draggable: true,
  filterable: true,
  floatingFilter: true,
  resizable: true,
};
