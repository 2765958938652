import React, { memo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Clickable } from "components/Button";
import { Row } from "components/Layout";

const CircleSidebarButton = memo(
  styled(Row)(
    ({ theme, background = "primary" }) => `
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${theme.colors[background]};
`,
  ),
);

const ButtonText = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 32px;
`;

const SidebarButton = ({ icon, background, onClick, children }) => {
  const Wrapper = Row;
  return (
    <Clickable
      onClick={onClick}
      style={{ marginBottom: 6 }}
      data-tour={children}
    >
      <Wrapper alignCenter>
        <CircleSidebarButton background={background}>
          <FontAwesomeIcon icon={icon} style={{ fontSize: "18px" }} />
        </CircleSidebarButton>
        <ButtonText>{children}</ButtonText>
      </Wrapper>
    </Clickable>
  );
};

SidebarButton.propTypes = {
  icon: PropTypes.object.isRequired,
  background: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default React.memo(SidebarButton);
