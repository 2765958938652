import React from "react";

import {
  faExclamationCircle,
  faTimes,
  faCheckCircle,
  faInfoCircle,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { appUpdateComplete } from "actions";

import Clickable from "components/Button/Clickable";

const Box = styled.div`
  display: flex;
  position: relative;
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor]};
  color: ${({ theme }) => theme.colors.gray40};
  line-height: 21px;
  font-size: 14px;
  font-family: inherit;
  max-height: 96px;
  border-style: solid;
  border-width: 0 0 0 6px;
  border-color: ${({ theme, color }) => theme.colors[color]};
  box-shadow: 0 1px 4px 0 #666666;
`;

const IconCell = styled.div`
  color: ${({ theme, color }) => theme.colors[color]};
  display: flex;
  align-items: center;
  max-height: 96px;
  width: 48px;
  border-style: none;
  text-align: center;
  padding: 18px;
`;

const TextCell = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  max-height: 96px;
  border-style: none;
  text-align: left;
  padding: 0 15px 0 5px;
`;

const DismissCell = styled(Clickable)`
  display: flex;
  align-items: center;
  text-align: middle;
  max-height: 96px;
  width: 48px;
  border-style: solid;
  border-color: #dbd9d8;
  border-width: 0 0 0 0.5px;
  padding: 18px;
`;

const RefreshText = styled(Clickable)`
  display: flex;
  flex-grow: 1;
  align-items: right;
  max-height: 96px;
  border-style: none;
  text-align: right;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 16px;
  text-decoration: underline;
`;

const Notification = ({
  type,
  message,
  dismiss,
  dismissMessage = null,
  dismissAction = null,
}) => {
  let color = type;
  let backgroundColor = "white";
  let icon;
  let showRefresh = false;
  let showCloseButton = true;
  let boxClickAction = dismissAction;
  const dispatch = useDispatch();
  const refreshAppAction = () => {
    dispatch(appUpdateComplete());
    window.location.href = `/?${new Date().getTime()}`;
  };
  switch (type) {
    case "error":
      icon = faExclamationCircle;
      showRefresh = dismissMessage && dismissAction;
      break;
    case "update":
      showRefresh = true;
      showCloseButton = false;
      dismissAction = refreshAppAction;
      dismissMessage = "REFRESH";
      icon = faExclamationCircle;
      backgroundColor = "warning";
      break;
    case "optionalUpdate":
      showRefresh = true;
      showCloseButton = true;
      dismissAction = refreshAppAction;
      dismissMessage = "REFRESH";
      boxClickAction = dismiss;
      icon = faInfoCircle;
      break;
    case "warning":
      icon = faExclamationCircle;
      break;
    case "syncing":
      icon = faSyncAlt;
      break;
    case "success":
      icon = faCheckCircle;
      break;
    case "info":
    default:
      color = "gray40";
      icon = faInfoCircle;
      break;
  }
  return (
    <Box
      color={color}
      backgroundColor={backgroundColor}
      onClick={boxClickAction}
    >
      <IconCell color={color}>
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: 18 }}
          spin={type === "syncing"}
          data-tour={`toast.${type}`}
        />
      </IconCell>
      <TextCell>{message || "Error"}</TextCell>
      {showRefresh && (
        <RefreshText
          onClick={() => {
            dismissAction();
            dismiss();
          }}
        >
          {dismissMessage}
        </RefreshText>
      )}
      {showCloseButton && (
        <DismissCell onClick={dismiss}>
          <FontAwesomeIcon icon={faTimes} style={{ fontSize: 20 }} />
        </DismissCell>
      )}
    </Box>
  );
};

Notification.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  dismiss: PropTypes.func.isRequired,
};

export default Notification;
