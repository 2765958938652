import { addWeight } from "actions/scales"; // Directly imported to avert circular import dependencies

import { b64ToArrayBuffer } from "lib";

import { RinstrumBasicView } from "lib/rinstrumBasic";
import {
  FormatADefaults,
  FormatBDefaults,
  FormatCDefaults,
  FormatDDefaults,
  FormatEDefaults,
} from "lib/rinstrumBasicFormatSpecifier";

export const RINSTRUM_5000_DRIVER_NAME = "rinstrum-5000";

const Rinstrum5000WeightFormat = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  E: "E",
  F: "CUSTOM",
};

const Rinstrum5000InbuiltWeightFormatMap = {
  [Rinstrum5000WeightFormat.A]: FormatADefaults,
  [Rinstrum5000WeightFormat.B]: FormatBDefaults,
  [Rinstrum5000WeightFormat.C]: FormatCDefaults,
  [Rinstrum5000WeightFormat.D]: FormatDDefaults,
  [Rinstrum5000WeightFormat.E]: FormatEDefaults,
};

export function rinstrum5000ProcessMessage(
  device,
  type,
  status,
  serialisedPayload,
) {
  const { deviceId, config } = device;
  const formatSpecifier = config["custom-format-specifier"];
  const formatTemplate = config["format-template"];

  // Default to format Type B when not specified
  const weightFormat = formatTemplate || Rinstrum5000InbuiltWeightFormatMap.B;
  const format =
    weightFormat === Rinstrum5000WeightFormat.F
      ? formatSpecifier
      : Rinstrum5000InbuiltWeightFormatMap[weightFormat];

  const dataArrayBuffer = b64ToArrayBuffer(serialisedPayload);

  const rinstrumBasicView = new RinstrumBasicView(
    {
      format,
      startChar: config["start-char"],
      endChar1: config["end-char-1"],
      endChar2: config["end-char-2"],
      units: config.units,
    },
    dataArrayBuffer,
  );

  if (!rinstrumBasicView.isValid()) {
    return;
  }

  return addWeight(
    deviceId,
    new Date(),
    rinstrumBasicView.displayedWeightGrams(),
    rinstrumBasicView.isStable(),
  );
}
