import React from "react";

import { Modal } from "components/Sundry/Table/Modal";

import { ModalTypes } from "constants/navigation";
import { DeploymentPermissions } from "constants/permissions";

import { selectRoleCurrentDeployments } from "selectors";

import { useSomeHasPermission } from "hooks/useHasPermission";
import { useModalAdapter } from "hooks/useModalAdapter";

export const Adapter = props => {
  const [_ignored, returnTo, onClose] = useModalAdapter(
    ModalTypes.ManualAdjustmentTable,
    props,
  );

  const hasManualChargePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.featureManualCharges,
  );

  if (hasManualChargePermission) {
    return <Modal returnTo={returnTo} onClose={onClose} />;
  }
};
