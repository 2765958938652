import { v4 as uuidv4 } from "uuid";

import {
  GET_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "constants/actionTypes";

export const requestProducts = () => ({
  type: GET_PRODUCTS.REQUEST,
});

export const requestProductsSuccess = products => ({
  type: GET_PRODUCTS.SUCCESS,
  products,
});

export const requestProductsFailure = () => ({
  type: GET_PRODUCTS.FAILURE,
});

export const addProduct = product => {
  // If the create fails the first time, it will be given a tempId anyway, and
  // we should use that tempId on the retry.
  const payload = {
    ...product,
    id: product.id || uuidv4(),
    notCreated: true,
  };
  const meta = { tempId: payload.id };
  return {
    type: ADD_PRODUCT.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url: `/v2/products/`,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: ADD_PRODUCT.SUCCESS,
          meta,
        },
        rollback: {
          type: ADD_PRODUCT.FAILURE,
          meta,
        },
      },
    },
  };
};

export const deleteProduct = productId => {
  const meta = { productId };
  return {
    type: DELETE_PRODUCT.REQUEST,
    meta: {
      productId,
      offline: {
        effect: {
          url: `/v2/products/${productId}/`,
          method: "DELETE",
        },
        commit: {
          type: DELETE_PRODUCT.SUCCESS,
          meta,
        },
        rollback: {
          type: DELETE_PRODUCT.FAILURE,
          meta,
        },
      },
    },
  };
};

export const updateProducts = products => {
  const meta = products;
  return {
    type: UPDATE_PRODUCT.REQUEST,
    payload: products,
    meta: {
      offline: {
        effect: {
          url: `/v2/products/`,
          method: "PUT",
          body: JSON.stringify(products),
        },
        commit: {
          type: UPDATE_PRODUCT.SUCCESS,
          meta,
        },
        rollback: {
          type: UPDATE_PRODUCT.FAILURE,
          meta,
        },
      },
    },
  };
};

export const addProductFromSocket = message => {
  return {
    type: ADD_PRODUCT.FROM_SOCKET,
    payload: message.object,
  };
};

export const updateProductFromSocket = message => {
  return {
    type: UPDATE_PRODUCT.FROM_SOCKET,
    payload: message.object,
  };
};

export const deleteProductFromSocket = message => ({
  type: DELETE_PRODUCT.FROM_SOCKET,
  meta: {
    product: {
      id: message.id,
    },
  },
});
