import { createSelector } from "reselect";

import {
  ExportSites,
  ExportSitesStatuses,
  ExportSiteValidationErrorOrStatusGetter,
} from "constants/exportSites";

import { getConsignments, getRounds, getSaleLots } from "selectors";

export const selectIsIntegrationCompliantBySaleLotIdLookup = createSelector(
  [getSaleLots, getConsignments, getRounds],
  (saleLots, consignments, rounds) => {
    return Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      const consignment = consignments[saleLot.consignment_id] || {};
      const saleLotWithConsignment = {
        consignment,
        ...saleLot,
        sale_round:
          // The Sale Round is embedded in the Sale Lot in the serializer from the backend
          saleLot.sale_round ||
          // The result of ADD_SALE_LOT_COMMIT doesn't have the SaleRound on the SaleLot
          rounds[saleLot.sale_round_id] ||
          // SaleRounds have not yet loaded
          {},
        quantity: saleLot.quantity,
        id: saleLot.id,
      };
      acc[saleLotId] = Object.values(ExportSites).reduce(
        (sites, exportSite) => {
          sites[exportSite] =
            ExportSiteValidationErrorOrStatusGetter[exportSite](
              saleLotWithConsignment,
            ) === ExportSitesStatuses.SUCCESS;
          return sites;
        },
        {},
      );
      return acc;
    }, []);
  },
);
