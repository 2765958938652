import { currentSaleFilter } from "actions/lib";

import { getLivestockSaleId } from "lib/navigation";

import { getChangesOutboxSize, getIsOnline } from "selectors";

export const crashReporterMiddleware = store => next => action => {
  // Middleware to log exceptions when processing actions (Ie provides visiblity of JS_ERROR errors)
  try {
    return next(action);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Caught an exception!", err, store.getState(), action);
    throw err;
  }
};

export const wrongSaleDiscarderMiddleware = () => next => action => {
  if (action.meta?.offline) {
    // Offline actions need to get filtered in the effect, and
    // some actions just need to be acknowledged.
    return next(action);
  }

  if (!currentSaleFilter(action)) {
    // eslint-disable-next-line no-console
    console.warn(
      `Discarding network response for non-offline middlware ${
        action.type
      } from sale ${
        action.meta.livestockSaleId
      } while in ${getLivestockSaleId()}`,
    );
    return;
  }

  return next(action);
};

export const concurrentGetMiddleware = store => next => action => {
  // Conservatively, double check offline actions that are flagged as skipQueue to make sure that no actions
  // are currently in the queue and the app is online
  if (action?.meta?.skipQueue) {
    const state = store.getState();
    const isOnline = getIsOnline(state);
    const changesOutboxSize = getChangesOutboxSize(state);

    if (!isOnline || changesOutboxSize !== 0) {
      action.meta.skipQueue = false;
    }
  }
  return next(action);
};
