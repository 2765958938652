import { sortBy } from "lodash";
import { createSelector } from "reselect";

import {
  MANUAL_TEMPLATE_ID,
  MANUAL_TEMPLATE_NAME,
} from "constants/sundryTemplates";

import { getSundryTemplates } from "selectors";

export const selectSundryTemplateOptions = createSelector(
  [getSundryTemplates],
  sundryTemplates => {
    const sundryTemplateOptions = sortBy(
      Object.values(sundryTemplates).map(sundryTemplate => ({
        label: sundryTemplate.name,
        value: sundryTemplate.id,
      })),
      "label",
    );
    sundryTemplateOptions.push({
      label: MANUAL_TEMPLATE_NAME,
      value: MANUAL_TEMPLATE_ID,
    });
    return sundryTemplateOptions;
  },
);

export const getSundryTemplateById = sundryTemplateId => state =>
  getSundryTemplates(state)[sundryTemplateId] || {};
