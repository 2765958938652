import React from "react";

import { useMediaQuery } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Badge from "components/Badge";

import { getProgenyDisplayCount } from "lib/saleLot";

import {
  getQuantityArrivedByVendorId,
  getQuantityByVendorId,
  getQuantityDeliveredByVendorId,
  getQuantityPennedByVendorId,
  getQuantityProgenyPennedByVendorId,
  getQuantityProgenySoldByVendorId,
  getQuantityProgenyNoSaleByVendorId,
  getQuantitySoldByVendorId,
  getQuantityNoSaleByVendorId,
  getQuantityNominatedByvendorId,
} from "selectors";

import { useTheme } from "hooks";

import {
  ArrivedIcon,
  ConsignedIcon,
  DeliveredIcon,
  IconWrapper,
  PennedIcon,
  SoldIcon,
} from "./Icon";

const TopLabel = styled.p(
  ({ theme, isMobile }) => `
    text-align: center;
    color: ${theme.colors.black};
    position: relative;
    left: ${theme.space[0]}px;
    right: ${theme.space[0]}px;    
    z-index: 2;
    bottom: 93px;      
    ${isMobile ? `bottom: 101px;` : ""}
    `,
);

const Label = styled.p(
  ({ theme }) => `
  color: ${theme.colors.black};
  position: relative;
  left: 50%;
  transform: translate(-50%);
  bottom: 30px;
  `,
);

const ProgenyLabel = styled(Label)(
  ({ theme }) => `
    font-size: ${theme.fontSizes.alpha}px;
  `,
);

const AdaptiveStepper = styled(Stepper)(
  ({ open }) => `
${open ? `margin-top: 20px;` : ""}
  &.MuiPaper-root {
    background-color: transparent;
    padding-top: 18px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  `,
);

const ConsignmentStepperWrapper = styled.div(
  ({ open }) => `
  ${open ? `height: 170px;` : ""}
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  left: 50%;
  transform: translate(-50%);
`,
);

const DiffBadge = styled(Badge)(
  ({ theme, hasProgeny, open, isMobile }) => `
  background-color: ${theme.colors.warning};
  position: relative;
  text-transform: none;
  z-index: 1;
  left: 9vw;
  overflow: hidden;

  ${
    isMobile && !open
      ? "top: -74px;"
      : isMobile && !hasProgeny
        ? "top: -144px;"
        : !open
          ? "top: -47px;"
          : `bottom: ${hasProgeny ? "144px" : "115px"};`
  } 
  
  @media (max-width: ${theme.breakpoints[0]}px) {    
    background: ${theme.colors.warning};
    left: 8.3vw; 
    bottom: ${hasProgeny ? "173px" : "105px"}; 
    font-size: ${theme.fontSizes.alpha}px; 
    color: ${theme.colors.white};
  }
`,
);

const StyledStep = styled(Step)`
  margin-bottom: -12px;
  margin-top: 12px;
`;

const connectorWarnings = {
  none: 0,
  both: 1,
  back: 2,
};

const Connector = styled(({ connectorCheck: ignored, ...rest }) => (
  <StepConnector {...rest} />
))`
  border: 0;
  color: none;
  margin-top: 6px;
  height: 3px;
  ${({ theme, active, completed, connectorCheck }) => {
    if (
      (completed && connectorCheck !== connectorWarnings.none) ||
      (active && connectorCheck === connectorWarnings.both)
    ) {
      return `background-color: ${theme.colors.warning};`;
    } else if (
      (active && connectorCheck === connectorWarnings.back) ||
      active ||
      completed
    ) {
      return `background-color: ${theme.colors.primary};`;
    } else {
      return `background-color: ${theme.colors.inactiveStep};`;
    }
  }}

  .MuiStepConnector-line {
    height: ${({ theme }) => theme.radii[1]}px;
    border: ${({ active, completed }) => (active || completed ? `0` : `1`)};
    border-width: ${({ theme }) => theme.radii[1]}px;
  }
`;

function WrappedIcon({
  icon,
  valueTotal,
  nextValue,
  nextValueTotal,
  prevValue,
  prevValueTotal,
}) {
  const idle =
    (valueTotal === 0 && nextValueTotal === 0) ||
    (valueTotal === 0 && nextValue === undefined);
  const warning = prevValue !== undefined && valueTotal !== prevValueTotal;
  const warningRight = nextValueTotal && nextValueTotal !== valueTotal;

  const wrapperStyling = {
    borderColor: "primary",
    color: "primary",
    rightBorderColor: null,
  };

  if (idle) {
    wrapperStyling.borderColor = "inactiveStep";
    wrapperStyling.color = "inactiveStep";
  } else if (warning) {
    wrapperStyling.borderColor = "warning";
    wrapperStyling.color = "primary";
  } else if (warningRight) {
    wrapperStyling.borderColor = "primary";
    wrapperStyling.color = "primary";
    wrapperStyling.rightBorderColor = "warning";
  }

  return <IconWrapper {...wrapperStyling}>{icon}</IconWrapper>;
}

const GroupedConsignmentStepper = React.memo(({ open, vendorId }) => {
  // For all consignments for the supplied vendor, find the:
  // total quantity
  // quantity marked arrived  (including progeny)
  // quantity penned  (including progeny)
  // quantity sold  (including progeny)
  // quantity delivered  (including progeny)

  // Find the active value, as defined by the furthest step (eg anything is 'sold', that is the current step)

  // Display each as it's unique icon.

  // for each of the icons
  // If the current value is 0, and the next steps icon is 0 display as 'idle'
  // If the current value is different to the previous step, display as 'warning'
  // If the current value active step is past this one, if is different to the next step show as 'warning right'
  // Otherwise show as a success.

  // For connectors between each step, if there is a difference between the values, show that in a yellow box.
  // (*** progeny?)

  // Underneath each step, when the card is opened, show the quantity.

  // If progeny are defined on a sale lot, show this as (x 33) underneath the quantity on the expanded view.
  // This is only shown for the Penned, Sold and Delivered items

  const theme = useTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints[0]}px)`);

  const quantity = useSelector(getQuantityByVendorId(vendorId));
  const quantityNominated = useSelector(
    getQuantityNominatedByvendorId(vendorId),
  );
  const quantityArrived = useSelector(getQuantityArrivedByVendorId(vendorId));
  const quantityPenned = useSelector(getQuantityPennedByVendorId(vendorId));
  const quantitySold = useSelector(getQuantitySoldByVendorId(vendorId));
  const quantityNoSale = useSelector(getQuantityNoSaleByVendorId(vendorId));
  const quantityDelivered = useSelector(
    getQuantityDeliveredByVendorId(vendorId),
  );

  const quantityProgenyPenned = useSelector(
    getQuantityProgenyPennedByVendorId(vendorId),
  );
  const quantityProgenySold = useSelector(
    getQuantityProgenySoldByVendorId(vendorId),
  );
  const quantityProgenyNoSale = useSelector(
    getQuantityProgenyNoSaleByVendorId(vendorId),
  );

  // This is a bit funky, but MaterialUI seems pretty adament on grouping these components in a single
  // component - abstracting <StyledStep> grouping down to it's own component makes for broken times.
  const steps = React.useMemo(
    () => [
      {
        icon: <ConsignedIcon />,
        value: quantityNominated || quantity,
        nextValue: quantityArrived,
        valueProgeny: null,
        nextValueProgeny: null,
        prevValueProgeny: null,
        label: isMobile ? "Nom" : "Nominated",
      },
      {
        icon: <ArrivedIcon color={quantity > 0 ? "primary" : "inactiveStep"} />,
        value: quantityArrived,
        valueProgeny: null,
        nextValue: quantityPenned,
        nextValueProgeny: quantityProgenyPenned,
        prevValue: quantityNominated || quantity,
        prevValueProgeny: null,
        label: isMobile ? "Arr" : "Arrived",
      },
      {
        icon: <PennedIcon />,
        value: quantityPenned,
        valueProgeny: quantityProgenyPenned,
        nextValue: quantitySold + quantityNoSale,
        nextValueProgeny: quantityProgenySold + quantityProgenyNoSale,
        prevValue: quantityArrived,
        prevValueProgeny: null,
        label: isMobile ? "Pen" : "Penned",
      },

      {
        icon: <SoldIcon />,
        value: quantitySold + quantityNoSale,
        valueProgeny: quantityProgenySold + quantityProgenyNoSale,
        nextValue: quantityDelivered,
        prevValue: quantityPenned,
        prevValueProgeny: quantityProgenyPenned,
        label: isMobile ? "Sld" : "Sold",
      },

      {
        icon: (
          <DeliveredIcon
            color={quantityDelivered > 0 ? "primary" : "inactiveStep"}
          />
        ),
        value: quantityDelivered,
        nextValueProgeny: null,
        prevValue: quantitySold + quantityNoSale,
        prevValueProgeny: quantityProgenySold + quantityProgenyNoSale,
        label: isMobile ? "Del" : "Delivered",
        diff: null,
      },
    ],
    [
      quantity,
      quantityNominated,
      quantityArrived,
      quantityPenned,
      quantitySold,
      quantityNoSale,
      quantityDelivered,
      quantityProgenyPenned,
      quantityProgenySold,
      quantityProgenyNoSale,
      isMobile,
    ],
  );

  // Active step is the first non 0; all are non zero, we're finished so active is the last step (4)
  const firstZero = steps.findIndex(step => step.value === 0);
  const activeStep = firstZero === -1 ? 4 : firstZero - 1;

  return (
    <ConsignmentStepperWrapper open={open}>
      <AdaptiveStepper alternativeLabel activeStep={activeStep} open={open}>
        {steps.map(step => {
          const {
            icon,
            value,
            valueProgeny,
            nextValue,
            nextValueProgeny,
            prevValue,
            prevValueProgeny,
            label,
          } = step;
          const valueTotal = value + (valueProgeny || 0);
          const nextValueTotal = nextValue + (nextValueProgeny || 0);
          const prevValueTotal = prevValue + (prevValueProgeny || 0);

          // only show diff if next value exists and is not 0
          const diff = !nextValue ? null : nextValueTotal - valueTotal;

          let connectorCheck = connectorWarnings.none;
          if (prevValueTotal !== valueTotal && valueTotal !== nextValueTotal) {
            connectorCheck = connectorWarnings.both;
          } else if (valueTotal !== prevValueTotal) {
            connectorCheck = connectorWarnings.back;
          }

          const labelIcon = (
            <WrappedIcon
              icon={icon}
              valueTotal={valueTotal}
              nextValue={nextValue}
              nextValueTotal={nextValueTotal}
              prevValue={prevValue}
              prevValueTotal={prevValueTotal}
            />
          );

          return (
            <StyledStep
              connector={<Connector connectorCheck={connectorCheck} />}
              key={label}
            >
              <StepLabel icon={labelIcon}>
                {open ? (
                  <>
                    <TopLabel
                      isMobile={isMobile}
                      hasProgeny={Boolean(valueProgeny)}
                      hasDiff={Boolean(diff)}
                    >
                      {label}
                    </TopLabel>
                    <Label>{value}</Label>
                    <ProgenyLabel>
                      {getProgenyDisplayCount(valueProgeny)}
                    </ProgenyLabel>
                  </>
                ) : null}

                {diff ? (
                  <DiffBadge
                    isMobile={isMobile}
                    open={open}
                    hasProgeny={Boolean(valueProgeny)}
                  >
                    {diff > 0 ? "+" : ""}
                    {diff}
                  </DiffBadge>
                ) : null}
              </StepLabel>
            </StyledStep>
          );
        })}
      </AdaptiveStepper>
    </ConsignmentStepperWrapper>
  );
});

GroupedConsignmentStepper.propTypes = {
  open: PropTypes.bool.isRequired,
  vendorId: PropTypes.string.isRequired,
};

export default GroupedConsignmentStepper;
