import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const nominationTermSerializeMap = {
  deprecated: "deprecated",
  groupName: "group_name",
  id: "id",
  name: "name",
  order: "order",
  speciesId: "species_id",
  saleyardId: "saleyard_id",
};

export const serializeNominationTerm = buildSerializer(
  nominationTermSerializeMap,
  "nominationTerm",
);

export const deserializeNominationTerm = buildDeserializer(
  nominationTermSerializeMap,
  "nominationTerm",
);
