import React from "react";

import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faCommentAltEdit } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { ContextMenuPopoverButton } from "components/Button/MultiButton";
import { ScanLotDeleteDialog } from "components/ScanLotDeleteDialog";

import { openCommentsModal, openModalLink } from "lib/navigation";
import { scanLotConfig } from "lib/scanLot";

import { useBoolean, useHasPermission } from "hooks";

export const LotActionButton = ({ scanLotId, penType, isLocked }) => {
  const {
    commentType,
    editLotModalType,
    lotAction,
    getLotById,
    lotIdReference,
    getEidsByLotId,
    permissions,
  } = scanLotConfig(penType);

  const eids = useSelector(getEidsByLotId(scanLotId)).filter(Boolean);

  const scanCount = eids?.length || 0;

  const hasDeleteLotPermission = useHasPermission(
    getLotById(scanLotId),
    permissions.delete,
  );

  const hasEditLotPermission = useHasPermission(
    getLotById(scanLotId),
    permissions.update,
  );

  const dispatch = useDispatch();

  const [isConfirmDeleteOpen, openConfirmDelete, closeConfirmDelete] =
    useBoolean(false);

  const onDeleteLot = () => {
    dispatch(lotAction.delete(scanLotId));
    closeConfirmDelete();
  };

  const onEditLot = () => {
    openModalLink(editLotModalType, { [lotIdReference]: scanLotId });
  };

  const onComment = () => {
    openCommentsModal(commentType, scanLotId, null);
  };

  const buttons = [
    {
      title: "View/Edit",
      onClick: onEditLot,
      dataTour: "viewEdit",
      icon: faPencilAlt,
      isDisabled: isLocked || !hasEditLotPermission,
    },
    {
      title: "Comment",
      onClick: onComment,
      dataTour: "comment",
      icon: faCommentAltEdit,
    },
    {
      title: "Delete",
      onClick: openConfirmDelete,
      dataTour: "delete",
      icon: faTrashAlt,
      isDisabled: isLocked || !hasDeleteLotPermission,
    },
  ];

  return (
    <>
      <ContextMenuPopoverButton
        buttons={buttons}
        buttonProps={{ borderRadius: 0 }}
      />

      <ScanLotDeleteDialog
        penType={penType}
        isOpen={isConfirmDeleteOpen}
        onCancel={closeConfirmDelete}
        onDelete={onDeleteLot}
        scanCount={scanCount}
      />
    </>
  );
};
