import { SystemSaleyards } from "constants/sale";

import awnLogo from "img/agency-logos/awn-base64";
import eldersLogo from "img/agency-logos/elders-logo-base64";
import mkmgLogo from "img/agency-logos/mkmg-base64";
import nutrienLogo from "img/agency-logos/nutrien-logo-base64";
import logo from "img/AgriNous-logo-base64";

export const agrinousLogo = `data:image/jpeg;base64,${logo}`;
export const isLocalEnvironment = process.env.REACT_APP_ENVIRONMENT === "local";
export const baseImageURL = isLocalEnvironment ? window.location.origin : "";

export const deploymentLocationString = deployment =>
  deployment.locations
    .map(location => location.name)
    .filter(name => !SystemSaleyards.includes(name))
    .join("/");

// When adding more logos, use the agency short code as the key,
// and set the logo up like the others (placard logo in base64).
export const agencyLogoLookup = {
  NUTRIEN: nutrienLogo,
  AWN: awnLogo,
  ELDERS: eldersLogo,
  MKMG: mkmgLogo,
};
