import { groupBy, mapValues } from "lodash";
import { readString } from "react-papaparse";
import {
  all,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import {
  bulkUpdateSaleLotsSerializer,
  importFileFailed,
  importFileSucceeded,
} from "actions";

import { ImportStatus } from "components/Importer/constants";

import {
  EXTERNAL_AGENT_XML,
  IMPORT_AUCTIONS_PLUS_CSV,
  IMPORT_DEPLOYMENT_SALE,
  PRE_SALE_CSV,
} from "constants/actionTypes";
import {
  BulkUpdateModelActions,
  BulkUpdateModels,
} from "constants/bulkUpdateModels";
import { DeclarationTypes } from "constants/documentTypes";
import {
  AUCTIONS_PLUS_CSV_FILENAME_KEY,
  AUCTIONS_PLUS_CSV_STORAGE_KEY,
  SG8_XML_FILENAME_KEY,
  SG8_XML_STORAGE_KEY,
} from "constants/importer";
import { ModalTypes } from "constants/navigation";
import { DISBANDED_LPA_N, NLIS_NOT_FOUND, NLIS_PENDING } from "constants/nlis";
import { PricingTypes } from "constants/pricingTypes";
import { EIDPattern } from "constants/scanner";
import { Species } from "constants/species";

import { calculateTotalPriceCents, downloadFileBlob, EMPTY_OBJECT } from "lib";

import {
  getLivestockSaleId,
  openCurrentSale,
  openModalLink,
  openOverview,
} from "lib/navigation";
import { validatePIC } from "lib/PICValidator";
import { getDefaultPropertyId } from "lib/properties";
import toast from "lib/toast";

import {
  getActiveRoleDeployments,
  getCurrentRoundsList,
  getCurrentSale,
  getCurrentSaleyardId,
  getCurrentSpeciesId,
  getProperties,
  selectActiveProducts,
  selectBusinessIdsByMasterBusinessDeploymentIdLookup,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
} from "selectors";

import { api } from "./api";

const isWhitespaceString = str => !/\S/.test(str);
const lotNumberRegex = /(\D*?)?(\d+)(.*)/;

function* handleImportAuctionsPlusCSV({ payload }) {
  yield put(bulkUpdateSaleLotsSerializer(payload));

  // Then wait for success or failure.
  const bulkUpdateAction =
    BulkUpdateModelActions[BulkUpdateModels.SALE_LOT_SERIALIZER];
  const { updateSuccess, updateError } = yield race({
    updateSuccess: take(bulkUpdateAction.SUCCESS),
    updateError: take(bulkUpdateAction.FAILURE),
    timeout: delay(240000),
  });

  if (updateSuccess) {
    toast.success("Import Successful");
  } else if (updateError) {
    toast.error("Importing Failed, contact support@agrinous.com for help.");
  } else {
    toast.error("Importing timed out - please check status later.");
  }

  sessionStorage.removeItem(AUCTIONS_PLUS_CSV_STORAGE_KEY);
  sessionStorage.removeItem(AUCTIONS_PLUS_CSV_FILENAME_KEY);
  // Not sure if there's an instance where we don't want to do this?
  openCurrentSale();
}

function getTagOrThrow(el, tagName, reason = null) {
  const elements = el.getElementsByTagName(tagName);
  if (elements.length === 0) {
    throw new Error(
      reason == null ? `Could not find ${tagName} element in file` : reason,
    );
  } else if (elements.length > 1) {
    throw new Error(
      reason == null ? `Found ${elements.length} ${tagName} elements` : reason,
    );
  }
  return elements[0];
}

function getAttributeOrThrow(el, attributeName, reason = null) {
  if (!el.hasAttribute(attributeName)) {
    throw new Error(
      reason == null ? `Could not find ${attributeName} attribute` : reason,
    );
  }
  return el.getAttribute(attributeName);
}

function* onProcessExternalAgentXml(action) {
  const { file, fileContents } = action;
  const state = yield select();
  const currentSale = getCurrentSale(state);

  const parser = new DOMParser();
  const documentEl = parser.parseFromString(fileContents, "application/xml");

  // create a placeholder variable to be filled out from the contents of the XML document
  const sale = {
    saleDate: null,
    saleyardId: null,
    buyerGroups: [],
  };
  try {
    // Process the XML into the structured "sale" variable
    const saleEl = getTagOrThrow(
      documentEl,
      "Sale",
      "Could not find a Sale in the file!",
    );
    getAttributeOrThrow(saleEl, "SaleFileVersion", "Unsupported file format.");
    sale.saleDate = getTagOrThrow(saleEl, "SaleDate").textContent;
    if (sale.saleDate !== currentSale.date.replace(/-/g, "")) {
      const formattedDate = `${sale.saleDate.slice(0, 4)}-${sale.saleDate.slice(
        4,
        6,
      )}-${sale.saleDate.slice(6, 8)}`;
      toast.info(`Sale date in file (${formattedDate}) does not match`);
    }

    sale.saleyardId = getTagOrThrow(saleEl, "SaleyardID").textContent;

    const buyerGroupEls = saleEl.getElementsByTagName("BuyerGroup");
    for (const buyerGroupEl of buyerGroupEls) {
      const buyerGroup = {
        purchaserPic: getAttributeOrThrow(buyerGroupEl, "PurchaserPIC"),
        purchaserAccount: getAttributeOrThrow(buyerGroupEl, "PurchaserAccount"),
        vendorGroups: [],
      };

      const vendorGroupEls = buyerGroupEl.getElementsByTagName("VendorGroup");
      for (const vendorGroupEl of vendorGroupEls) {
        const vendorGroup = {
          additionalPics: [],
          // agentCode Not mapped
          agentCode: getTagOrThrow(vendorGroupEl, "AgentCode").textContent,
          agentName: getTagOrThrow(vendorGroupEl, "AgentName").textContent,
          brand: getTagOrThrow(vendorGroupEl, "Brand").textContent,
          erpResult: getTagOrThrow(vendorGroupEl, "ERPResult").textContent,
          lotGroups: [],
          // Unsure where this should go
          lpa: getTagOrThrow(vendorGroupEl, "LPA").textContent,
          nvd: getAttributeOrThrow(vendorGroupEl, "NVD"),
          nvdQ1_8: getTagOrThrow(vendorGroupEl, "NVDQ1_8").textContent,
          qa: getTagOrThrow(vendorGroupEl, "QA").textContent,
          vendorId: getTagOrThrow(vendorGroupEl, "VendorID").textContent,
          vendorPic: getAttributeOrThrow(vendorGroupEl, "VendorPIC"),
        };

        const additionalPics = new Set();
        const additionalPicsEls =
          vendorGroupEl.getElementsByTagName("AdditionalPICs");
        for (const additionalPicsEl of additionalPicsEls) {
          const picEls = additionalPicsEl.getElementsByTagName("PIC");
          for (const picEl of picEls) {
            additionalPics.add(picEl.textContent);
          }
        }

        const lotGroupEls = vendorGroupEl.getElementsByTagName("LotGroup");
        for (const lotGroupEl of lotGroupEls) {
          const lotGroup = {
            lotNumber: getAttributeOrThrow(lotGroupEl, "Lot"),
            paint: getAttributeOrThrow(lotGroupEl, "Paint"),

            // Although this field is extracted, it is not used to send to the backend.
            // We calculate c/kg, instead of storing it.
            centsPerKg: getTagOrThrow(lotGroupEl, "CentsPerKg").textContent,
            description: getTagOrThrow(lotGroupEl, "Description").textContent,
            extraHead: getTagOrThrow(lotGroupEl, "ExtraHead").textContent,
            head: getTagOrThrow(lotGroupEl, "Head").textContent,
            pen: getTagOrThrow(lotGroupEl, "Pen").textContent,
            rfidGroups: [],
            totalDollar: getTagOrThrow(lotGroupEl, "TotalDollar").textContent,
            totalKg: getTagOrThrow(lotGroupEl, "TotalKg").textContent,
          };

          const rfidGroupEls = lotGroupEl.getElementsByTagName("RFIDGroup");
          for (const rfidGroupEl of rfidGroupEls) {
            const rfidGroup = {
              euEligible: getTagOrThrow(rfidGroupEl, "EUEligible").textContent,
              lifetimeTraceable: getTagOrThrow(rfidGroupEl, "LifeTimeTraceable")
                .textContent,
              nlisId: getTagOrThrow(rfidGroupEl, "NLISID").textContent,
              rfid: getTagOrThrow(rfidGroupEl, "RFID").textContent,
            };
            lotGroup.rfidGroups.push(rfidGroup);
          }
          vendorGroup.lotGroups.push(lotGroup);
        }
        buyerGroup.vendorGroups.push(vendorGroup);
      }
      sale.buyerGroups.push(buyerGroup);
    }
  } catch (e) {
    yield put({
      type: EXTERNAL_AGENT_XML.PROCESS.FAILURE,
      payload: { response: { detail: e.message } },
    });
    return;
  }
  const vendorMap = new Map();
  const productMap = new Map();

  const existingProductMapping =
    state.importers.externalAgentXml?.products || [];
  const existingVendorMapping = state.importers.externalAgentXml?.vendors || [];

  sale.buyerGroups.forEach(buyerGroup => {
    buyerGroup.vendorGroups.forEach(vendorGroup => {
      // Extract the vendors
      if (!vendorMap.has(vendorGroup.agentName)) {
        const vendorId =
          existingVendorMapping.find(
            vendor => vendor.vendorName === vendorGroup.agentName,
          )?.vendorId || null;

        vendorMap.set(vendorGroup.agentName, {
          id: uuidv4(),
          shortCode: vendorGroup.agentCode,
          vendorId,
          vendorName: vendorGroup.agentName,
        });
      }

      // Extract the Products
      vendorGroup.lotGroups.forEach(lotGroup => {
        if (lotGroup.description) {
          const productId =
            existingProductMapping.find(
              product => product.description === lotGroup.description,
            )?.productId || null;

          productMap.set(lotGroup.description, {
            description: lotGroup.description,
            id: uuidv4(),
            productId,
          });
        }
      });
    });
  });

  const payload = {
    file: {
      name: file.name,
      lastModified: file.lastModified,
      type: file.type,
      rawContent: fileContents,
      sale,
    },
    vendors: Array.from(vendorMap.values()),
    products: Array.from(productMap.values()),
    isDataLossAcknowledged: true,
  };

  yield put({ type: EXTERNAL_AGENT_XML.UPDATE.REQUEST, payload });
}

function transformNvdAnswer(answer) {
  if (answer === "Y") {
    return true;
  } else if (answer === "N") {
    return false;
  }
  return null;
}

function transformNvdQa(nvdQa, nvd, speciesId) {
  const answers = nvdQa.split("");
  const declaration = {};
  if (speciesId === Species.SHEEP) {
    declaration.VERSION = DeclarationTypes.S0720;
    declaration.qa_accredited = transformNvdAnswer(answers[0]);
    declaration.treated_for_scabby_mouth = transformNvdAnswer(answers[1]);
    declaration.owned_since_birth = null;
    declaration.owned_since_birth_duration = "";
    if (answers[2] === "Y" || answers[2] === "N") {
      declaration.owned_since_birth = transformNvdAnswer(answers[2]);
    } else if (["A", "B", "C", "D"].includes(answers[2])) {
      declaration.owned_since_birth = false;
      declaration.owned_since_birth_duration = answers[2];
    }
    declaration.veterinary_treatment = transformNvdAnswer(answers[3]);
    declaration.consumed_material_in_withholding_period = transformNvdAnswer(
      answers[4],
    );
    declaration.fed_feed_containing_animal_fats = transformNvdAnswer(
      answers[5],
    );
    declaration.additional_information = "";
    declaration.additional_information_suggested = "";
    declaration.post_breeder_tags = null;
  } else if (speciesId === Species.CATTLE) {
    declaration.VERSION = nvd.startsWith("E")
      ? DeclarationTypes.E0720
      : DeclarationTypes.C0720;
    declaration.treated_with_hgp = transformNvdAnswer(answers[0]);
    declaration.fed_feed_containing_animal_fats = transformNvdAnswer(
      answers[1],
    );
    declaration.owned_since_birth = null;
    declaration.owned_since_birth_duration = "";
    if (answers[2] === "Y" || answers[2] === "N") {
      declaration.owned_since_birth = transformNvdAnswer(answers[2]);
    } else if (["A", "B", "C", "D"].includes(answers[2])) {
      declaration.owned_since_birth = false;
      declaration.owned_since_birth_duration = answers[2];
    }
    declaration.fed_byproduct_stockfeeds = transformNvdAnswer(answers[3]);
    declaration.chemical_residue_restrictions = transformNvdAnswer(answers[4]);
    declaration.veterinary_treatment = transformNvdAnswer(answers[5]);
    declaration.consumed_material_in_withholding_period = transformNvdAnswer(
      answers[6],
    );
    declaration.spray_drift_risk = transformNvdAnswer(answers[7]);
    declaration.additional_information = "";
    declaration.additional_information_suggested = "";
  }
  return declaration;
}

function* onImportExternalAgentXml() {
  const state = yield select();
  const speciesId = getCurrentSpeciesId(state);
  const roundId = getCurrentRoundsList(state)[0];
  const pricingTypeId = getCurrentSale(state).pricing_type_id;
  const deploymentId = getActiveRoleDeployments(state)[0].id;

  const buyerIds =
    selectBusinessIdsByMasterBusinessDeploymentIdLookup(state)[deploymentId];
  const buyerId = buyerIds ? buyerIds[0] : null;

  if (!buyerId) {
    yield put(
      importFileFailed(
        {
          errors: [
            "Could not find a master business linked to this deployment",
            "Contact AgriNous for support",
          ],
        },
        EXTERNAL_AGENT_XML,
      ),
    );
    return;
  }

  const {
    file: { sale: importData },
    products,
    vendors,
  } = state.importers.externalAgentXml;

  const consignmentMap = new Map();
  const skippableErrors = [];

  const lotNumbers = [];

  for (const buyerGroup of importData.buyerGroups) {
    for (const vendorGroup of buyerGroup.vendorGroups) {
      const { vendorId } = vendors.find(
        vendorMapping => vendorMapping.vendorName === vendorGroup.agentName,
      );
      const { vendorPic, nvd } = vendorGroup;
      const consignmentKey = `${vendorId}_${
        vendorPic ? vendorPic.toUpperCase() : ""
      }_${nvd}`;
      if (!consignmentMap.has(consignmentKey)) {
        const additionalProperties = vendorGroup.additionalPics.map(
          (additionalPic, index) => ({
            property: { PIC: additionalPic },
            position: index,
          }),
        );

        const declaration = transformNvdQa(vendorGroup.nvdQ1_8, nvd, speciesId);
        const nlisPrograms = vendorGroup.erpResult
          ? vendorGroup.erpResult
              .split(",")
              .map(erpResultItem => {
                // The Disbaned result is formatted a bit differently than the others,
                // Manually check and create the object for it.
                if (erpResultItem === DISBANDED_LPA_N) {
                  return {
                    code: "DISBANDED",
                    status: "D",
                  };
                }
                const splitPrograms = erpResultItem
                  .split(" ")
                  .map(str => str.trim())
                  .filter(Boolean);
                // Sometimes the file contains "Pending", so we need to check if it has split properly.
                if (splitPrograms[0] && splitPrograms[1]) {
                  return {
                    code: splitPrograms[0],
                    status: splitPrograms[1],
                  };
                } else {
                  return null;
                }
              })
              .filter(Boolean)
          : null;

        const baseConsignmentObj = {
          additional_properties: additionalProperties,
          brands: [],
          NVD: nvd,
          quantity: 0,
          sale_lots: [],
          vendor: { cbid: vendorId },
        };

        if (declaration !== EMPTY_OBJECT) {
          baseConsignmentObj.declaration = declaration;
        } else {
          skippableErrors.push(
            `Declaration invalid for Consignment from Agency: ${vendorGroup.agentName}`,
          );
        }

        if (vendorPic) {
          baseConsignmentObj.vendor_property = { PIC: vendorPic.toUpperCase() };
        } else {
          skippableErrors.push(
            `Vendor PIC missing for Consignment from Agency: ${vendorGroup.agentName}`,
          );
        }

        if (nlisPrograms) {
          baseConsignmentObj.nlis_programs = nlisPrograms;
        } else {
          skippableErrors.push(
            `ERP Result or NLIS Programs missing for Consignment from Agency: ${vendorGroup.agentName}`,
          );
        }

        consignmentMap.set(consignmentKey, baseConsignmentObj);
      }
      const consignment = consignmentMap.get(consignmentKey);

      if (
        vendorGroup.brand &&
        !consignment.brands.includes(vendorGroup.brand.trim())
      ) {
        consignment.brands.push(vendorGroup.brand.trim());
      }

      for (const lotGroup of vendorGroup.lotGroups) {
        // if a lot with this lot number already exists append the length of the amount
        // of times it's occured in this import as a sub lot number.
        // The reason why is because the backend validation does not allow
        // multiple lots within the same deployment sale to have the same lot number.
        const subLotNumber =
          lotNumbers.filter(lotNumber => lotNumber === lotGroup.lotNumber)
            .length || null;
        // Split an imported lot number into an array containing 4 things.
        // First element is the original lot number
        // Second element is the prefix text (which we don't currenly support)
        // Third element is the lot number without prefix or suffix (as a string)
        // Fourth element is the suffix text
        // Currently we only expect to run into lot numbers like: 100, 100a, 100A, 56, 56tt, 56tt
        // There may be a chance in the future we run into numbers with prefixes, but we do not support that,
        // so those prefixes will be dropped: pre55a will become 55a, pre55 -> 55
        // We may also hit pen ranges: zz45a-zz47b -> 45 a-zz47b, 45a-47b -> 45 a-47b
        const lotNumberMatch = lotNumberRegex.exec(lotGroup.lotNumber);
        const [, , lotNumber, lotNumberSuffix] = lotNumberMatch;
        const marks = lotGroup.paint ? [{ location: lotGroup.paint }] : [];
        const auctionPen = lotGroup.pen
          ? {
              sale_round_id: roundId,
              start_pen: lotGroup.pen.trim(),
            }
          : {
              sale_round_id: roundId,
              start_pen: lotGroup.lotNumber, // If there is no pen in the file, just use the lot number
            };
        const { breedId, productId } =
          products.find(
            productMapping =>
              productMapping.description === lotGroup.description,
          ) || {};
        const {
          age_id: ageId,
          sex_id: sexId,
          // Breed from product is ignored, users must use the Breed column in the UI
          // breed_id: productBreedId,
        } = productId
          ? selectActiveProducts(state)[productId]
          : { age_id: null, sex_id: null };
        const saleLot = {
          age_id: ageId,
          auction_pen: auctionPen,
          breed_id: breedId,
          buyer: { cbid: buyerId },
          buyer_way: buyerGroup.purchaserAccount
            ? { business: { cbid: buyerId }, name: buyerGroup.purchaserAccount }
            : null,
          destination_property: { PIC: buyerGroup.purchaserPic },
          lot_number: parseInt(lotNumber, 10),
          lot_number_suffix: lotNumberSuffix,
          sub_lot_number: subLotNumber,
          marks,
          pricing_type_id: pricingTypeId,
          quantity: +lotGroup.head,
          quantity_progeny: lotGroup.extraHead ? +lotGroup.extraHead : 0,
          sale_round_id: roundId,
          sex_id: sexId,
          total_price_cents: lotGroup.totalDollar
            ? (100 * lotGroup.totalDollar).toFixed(0)
            : 0,
          total_mass_grams: lotGroup.totalKg
            ? (1000 * lotGroup.totalKg).toFixed(0)
            : 0,
          scans: [],
        };
        for (const rfidGroup of lotGroup.rfidGroups) {
          if (
            rfidGroup.rfid &&
            EIDPattern.SIXTEEN_DIGIT_PATTERN.test(rfidGroup.rfid)
          ) {
            const scan = {
              animal: {
                EID: rfidGroup.rfid,
                nlis_id: [NLIS_NOT_FOUND, NLIS_PENDING].includes(
                  rfidGroup.nlisId,
                )
                  ? null
                  : rfidGroup.nlisId,
              },
              EU_status: "",
              lifetime_traceability: "",
            };
            if (rfidGroup.euEligible === "NO") {
              scan.EU_status = "N";
            } else if (rfidGroup.euEligible === "YES") {
              scan.EU_status = "Y";
            }
            if (rfidGroup.lifetimeTraceable === "YES") {
              scan.lifetime_traceability = "Y";
            } else if (rfidGroup.lifetimeTraceable === "NO") {
              scan.lifetime_traceability = "N";
            }
            saleLot.scans.push(scan);
          } else {
            skippableErrors.push(`Invalid EID: ${rfidGroup.rfid}`);
          }
        }
        consignment.quantity += saleLot.quantity;
        consignment.sale_lots.push(saleLot);
        // append lot numbers to the list so we know which lot numbers have occured
        // in this import. This is used to calculate the sub lot number.
        lotNumbers.push(lotGroup.lotNumber);
      }
    }
  }

  for (const consignment of consignmentMap.values()) {
    // Convert the array of brands (where applicable) to a CSV string
    consignment.brands = consignment.brands.join(", ");
  }
  const payload = {
    id: getLivestockSaleId(),
    // Consider prompting the user, and update the existing Livestock Sale date if they so choose
    // (As per JR suggestion)
    // date: importData.saleDate,
    deployment_sales: [
      {
        deployment_id: deploymentId,
        consignments: Array.from(consignmentMap.values()),
      },
    ],
    errors: skippableErrors,
  };
  yield put({ type: EXTERNAL_AGENT_XML.IMPORT.REQUEST, payload });
}

const createDescription = value => {
  return `${value.age}, ${value.breed}, ${value.sex}`;
};

const validationSchema = Yup.object().shape({
  age: Yup.string(),
  animalName: Yup.string(),
  breed: Yup.string(),
  buyerName: Yup.string().when(
    [
      "buyerWay",
      "buyerPic",
      "buyerContactFirstName",
      "buyerContactLastName",
      "buyerContactEmail",
      "buyerContactPhone",
      "unitPriceDollars",
    ],
    {
      is: (
        buyerWay,
        pic,
        contactFirstName,
        contactLastName,
        contactEmail,
        contactPhone,
        unitPrice,
      ) =>
        Boolean(
          buyerWay ||
            pic ||
            contactFirstName ||
            contactLastName ||
            contactEmail ||
            contactPhone ||
            unitPrice,
        ),
      then: schema => schema.required(),
    },
  ),
  buyerWay: Yup.string(),
  buyerPic: Yup.string().test(
    "buyerPic",
    "Invalid Buyer PIC",
    value => !value || validatePIC(value),
  ),
  buyerContactFirstName: Yup.string().when(
    ["buyerContactPhone", "buyerContactEmail"],
    {
      is: (contactPhone, contactEmail) => Boolean(contactEmail || contactPhone),
      then: schema => schema.required(),
    },
  ),
  buyerContactLastName: Yup.string().when(
    ["buyerContactPhone", "buyerContactEmail"],
    {
      is: (contactPhone, contactEmail) => Boolean(contactEmail || contactPhone),
      then: schema => schema.required(),
    },
  ),
  buyerContactPhone: Yup.string(),
  buyerContactEmail: Yup.string(),
  lotNumber: Yup.string().required(),
  mark: Yup.string(),
  nvd: Yup.string(),
  pen: Yup.string().required(),
  pricingType: Yup.mixed().when(["unitPriceDollars"], {
    is: Boolean,
    then: schema =>
      schema
        .oneOf([
          // use $/ea instead of $/hd for sanity in clearing sales
          "$/ea",
          // These can be added later
          /* "c/kg", "$ gross" */
        ])
        .required(),
  }),
  quantity: Yup.string().required(),
  roundName: Yup.string(),
  scans: Yup.array()
    .of(
      // Matches pipe delimited EIDs in 16 digit format (`900 123456789012|900 123456789011`...)
      Yup.string()
        .matches(/^(\d{3} \d{12}(\|\d{3} \d{12})*){0,1}$/)
        .required(),
    )
    .nullable(),
  sex: Yup.string(),
  tag: Yup.string(),
  unitPriceDollars: Yup.string(),
  vendorName: Yup.string().required(),
  vendorPic: Yup.string().test(
    "vendorPic",
    "Invalid Vendor PIC",
    value => !value || validatePIC(value),
  ),
});
function* onProcessPreSaleCSV(action) {
  const { file, fileContents } = action;
  const state = yield select();
  const currentSale = getCurrentSale(state);

  const errors = [];
  let lineCount = 0;
  const lots = [];
  readString(fileContents, {
    header: true,
    skipEmptyLines: true,
    step: results => {
      lineCount++;
      if (results.errors.length > 0) {
        results.errors.forEach(parseError => {
          errors.push({ line: parseError.row + 1, error: parseError.message });
        });
      }

      try {
        const data = mapValues(results.data, value =>
          isWhitespaceString(value) ? "" : value,
        );
        validationSchema.validateSync(data);
        lots.push(data);
      } catch (e) {
        errors.push({ line: lineCount, error: e.message });
      }
    },
  });

  const hasErrorMessage = errors.length > 0;
  if (hasErrorMessage) {
    yield put(importFileFailed({}, PRE_SALE_CSV));
    let errorMessage = "";
    if (errors.length > 4) {
      errorMessage = errors
        .slice(0, 4)
        .map(error => `Line ${error.line}: ${error.error}`)
        .join(". \n");
      errorMessage += `. Plus ${errors.length - 4} more issues.`;
    } else {
      errorMessage = errors
        .map(error => `Line ${error.line}: ${error.error}`)
        .join(". \n");
    }
    toast.error(errorMessage);
    return;
  }

  const vendorMap = new Map();
  const buyerMap = new Map();
  const roundMap = new Map();
  const productMap = new Map();

  const vendorGroups = groupBy(lots, "vendorName");
  const buyerGroups = groupBy(lots, "buyerName");
  const roundGroups = groupBy(lots, "roundName");

  const existingProductMapping = state.importers.preSaleCsv?.products || [];
  const existingVendorMapping = state.importers.preSaleCsv?.vendors || [];
  const existingBuyerMapping = state.importers.preSaleCsv?.buyers || [];
  const existingRoundMapping = state.importers.preSaleCsv?.rounds || [];

  Object.values(vendorGroups).forEach(vendor => {
    const vendorId =
      existingVendorMapping.find(
        existingVendor => existingVendor.vendorName === vendor[0].vendorName,
      )?.vendorId || null;
    vendorMap.set(vendor[0].vendorName, {
      id: uuidv4(),
      shortCode: null,
      vendorId,
      vendorName: vendor[0].vendorName,
    });
  });

  lots.forEach(lotData => {
    const description = createDescription(lotData);
    const productId =
      existingProductMapping.find(
        product => product.description === description,
      )?.productId || null;
    productMap.set(description, {
      description,
      age: lotData.age,
      sex: lotData.sex,
      breed: lotData.breed,
      id: uuidv4(),
      productId,
    });

    if (typeof lotData.scans === "string" && lotData.scans.length > 0) {
      lotData.scans = lotData.scans
        .split("|")
        .map(eid => eid.trim())
        .filter(Boolean);
    }
  });

  Object.values(buyerGroups).forEach(buyer => {
    const { buyerName } = buyer[0];
    if (!buyerName) {
      return;
    }
    const buyerId =
      existingBuyerMapping.find(
        existingBuyer => existingBuyer.buyerName === buyerName,
      )?.buyerId || null;

    buyerMap.set(buyerName, {
      id: uuidv4(),
      shortCode: null,
      buyerId,
      buyerName,
    });
  });

  Object.values(roundGroups).forEach(round => {
    const { roundName } = round[0];
    if (!roundName) {
      return;
    }

    const roundId =
      existingRoundMapping.find(
        existingRound => existingRound.roundName === roundName,
      )?.roundId || null;

    roundMap.set(roundName, {
      id: uuidv4(),
      roundId,
      roundName,
    });
  });

  const payload = {
    buyers: Array.from(buyerMap.values()),
    file: {
      name: file.name,
      lastModified: file.lastModified,
      type: file.type,
      rawContent: fileContents,
      vendorGroups,
      buyerGroups,
      roundGroups,
      sale: {
        saleDate: currentSale.date,
        saleyardId: currentSale.id,
      },
    },
    isDataLossAcknowledged: true,
    products: Array.from(productMap.values()),
    vendors: Array.from(vendorMap.values()),
    rounds: Array.from(roundMap.values()),
  };

  yield put({ type: PRE_SALE_CSV.UPDATE.REQUEST, payload });
}

function getContactKey(lotData) {
  if (
    lotData.buyerContactFirstName ||
    lotData.buyerContactLastName ||
    lotData.buyerContactEmail ||
    lotData.buyerContactEmail
  ) {
    return `${lotData.buyerContactFirstName}__${lotData.buyerContactLastName}__${lotData.buyerContactEmail}__${lotData.buyerContactEmail}`;
  }
  return null;
}
function getEmailRecipientKey(emailRecipient) {
  return `${emailRecipient.first_name}__${emailRecipient.last_name}__${emailRecipient.email}__${emailRecipient.phone_number}`;
}
function* onImportPreSaleCsv() {
  const state = yield select();
  const defaultRoundId = getCurrentRoundsList(state)[0];
  const defaultPricingTypeId = getCurrentSale(state).pricing_type_id;
  const deploymentId = getActiveRoleDeployments(state)[0].id;
  const propertyByIdLookup = getProperties(state);
  const saleyardId = getCurrentSaleyardId(state);
  const businesses = selectPropertyEnrichedBusinessByBusinessIdLookup(state);

  const {
    buyers,
    file: { buyerGroups, vendorGroups },
    products,
    rounds,
    vendors,
  } = state.importers.preSaleCsv;

  const consignmentMap = new Map();

  const buyerMap = new Map();
  buyerMap.set("", null);

  for (const buyerGroup of Object.values(buyerGroups)) {
    if (buyerGroup[0].buyerName) {
      const { buyerId } = buyers.find(
        buyerMapping => buyerMapping.buyerName === buyerGroup[0].buyerName,
      );
      const buyerKey = `${buyerGroup[0].buyerName}`;
      if (!buyerMap.has(buyerKey)) {
        buyerMap.set(buyerKey, {
          deployment_business_set: [],
          cbid: buyerId,
        });
      }

      const buyer = buyerMap.get(buyerKey);
      for (const lotData of buyerGroup) {
        const contactKey = getContactKey(lotData);
        if (contactKey) {
          if (buyer.deployment_business_set.length < 1) {
            buyer.deployment_business_set.push({
              deployment_id: deploymentId,
              business: { cbid: buyerId },
              email_recipients: [],
            });
          }
          if (
            !buyer.deployment_business_set[0].email_recipients
              .map(getEmailRecipientKey)
              .find(emailRecipientKey => emailRecipientKey === contactKey)
          ) {
            const emailRecipient = {
              first_name: lotData.buyerContactFirstName,
              last_name: lotData.buyerContactLastName,
              email: lotData.buyerContactEmail,
              phone_number: lotData.buyerContactPhone,
            };
            buyer.deployment_business_set[0].email_recipients.push(
              emailRecipient,
            );
          }
        }
      }
    }
  }

  for (const vendorGroup of Object.values(vendorGroups)) {
    const { vendorPic, nvd, vendorName } = vendorGroup[0];
    const { vendorId } = vendors.find(
      vendorMapping => vendorMapping.vendorName === vendorName,
    );

    const defaultPropertyId = getDefaultPropertyId(
      businesses[vendorId],
      saleyardId,
    );
    const defaultPic = propertyByIdLookup[defaultPropertyId]?.PIC;

    // If a PIC is passed in, we should use it, otherwise use the default on the selected business
    const defaultVendorProperty = vendorPic || defaultPic;

    const consignmentKey = `${vendorId}__${defaultVendorProperty}__${nvd}`;
    if (!consignmentMap.has(consignmentKey)) {
      consignmentMap.set(consignmentKey, {
        additional_properties: [],
        quantity: 0,
        sale_lots: [],
        vendor: { cbid: vendorId },
        brands: "",
        nvd,
        vendor_property: defaultVendorProperty
          ? { PIC: defaultVendorProperty }
          : null,
      });
    }

    const consignment = consignmentMap.get(consignmentKey);
    for (const lotData of vendorGroup) {
      const marks = lotData.mark ? [{ location: lotData.mark }] : [];
      const { roundId } = rounds.find(
        roundMapping => roundMapping.roundName === lotData.roundName,
      ) || { roundId: defaultRoundId };

      const auctionPen = lotData.pen;
      const { breedId, productId } =
        products.find(
          productMapping =>
            productMapping.description === createDescription(lotData),
        ) || {};
      const {
        age_id: ageId,
        sex_id: sexId,
        // Breed from product is ignored, users must use the Breed column in the UI
        // breed_id: productBreedId,
      } = productId
        ? selectActiveProducts(state)[productId]
        : { age_id: null, sex_id: null };

      const quantity = +parseInt(lotData.quantity, 10);

      const pricingTypeId =
        lotData.pricingType === "$/ea"
          ? PricingTypes.PER_HEAD
          : // TODO
            /* lotData.pricingType === "c/kg" ? PricingTypes.PER_KILO : lotData.pricingType ===  "$ gross" ? PricingTypes.GROSS : */
            defaultPricingTypeId;

      let totalPriceCents = 0;
      if (lotData.unitPriceDollars) {
        totalPriceCents = calculateTotalPriceCents({
          pricing_type_id: pricingTypeId,
          quantity,
          unitPrice: +lotData.unitPriceDollars,
          // TODO
          // total_mass_grams: lotData.totalWeightKg
        });
      }
      const buyer = buyerMap.get(lotData.buyerName) || null;
      const defaultBuyerPropertyId = buyer
        ? getDefaultPropertyId(
            businesses[buyer.cbid],
            saleyardId,
            lotData.buyerWay,
          )
        : null;

      const defaultExistingPic =
        propertyByIdLookup[defaultBuyerPropertyId]?.PIC || null;

      const formattedExistingProperty = defaultExistingPic
        ? { PIC: defaultExistingPic }
        : null;
      const buyerWay = lotData.buyerWay
        ? { business: { cbid: buyer.cbid }, name: lotData.buyerWay }
        : null;
      const destinationProperty = lotData.buyerPic
        ? { PIC: lotData.buyerPic }
        : null;
      // If a PIC is passed in, we should use it, otherwise use the default on the selected business
      const defaultDestinationProperty =
        destinationProperty || formattedExistingProperty;

      const saleLot = {
        age_id: ageId,
        auction_pen: { start_pen: auctionPen, sale_round_id: roundId },
        breed_id: breedId,
        buyer,
        buyer_way: buyerWay,
        destination_property: defaultDestinationProperty,
        lot_number: lotData.lotNumber,
        marks,
        pricing_type_id: pricingTypeId,
        quantity,
        quantity_progeny: 0,
        sale_round_id: roundId,
        sex_id: sexId,
        total_price_cents: totalPriceCents,
        total_mass_grams: 0,
      };

      if (Array.isArray(lotData.scans)) {
        saleLot.scans = lotData.scans.map(eid => {
          return {
            animal: {
              EID: eid,
              nlis_id: null,
            },
            ERP_status: null,
            EU_status: null,
            lifetime_traceability: null,
          };
        });
      }

      if (lotData.tag || lotData.animalName) {
        saleLot.drafting_attributes = {
          tag_number: lotData.tag,
          animal_name: lotData.animalName,
          cbid: uuidv4(),
        };
      }

      consignment.quantity += saleLot.quantity;
      consignment.sale_lots.push(saleLot);
    }
  }

  const payload = {
    id: getLivestockSaleId(),
    deployment_sales: [
      {
        deployment_id: deploymentId,
        consignments: Array.from(consignmentMap.values()),
      },
    ],
  };

  yield put({ type: PRE_SALE_CSV.IMPORT.REQUEST, payload });
}

function* onUpdatePreSaleCsv(action) {
  const state = yield select();
  const payload = { ...action.payload };
  if (Array.isArray(action.payload.products)) {
    // When the user selects a new Product, apply the Breed from that Product
    payload.products = action.payload.products.map(productMapping => {
      const existingMapping =
        state.importers.preSaleCsv &&
        Array.isArray(state.importers.preSaleCsv.products) &&
        state.importers.preSaleCsv.products.find(
          existingProductMapping =>
            existingProductMapping.description === productMapping.description,
        );
      const productHasChanged =
        !existingMapping ||
        existingMapping.productId !== productMapping.productId;

      const product = selectActiveProducts(state)[productMapping.productId];
      if (productMapping.productId && productHasChanged) {
        return {
          ...productMapping,
          breedId: product.breed_id,
        };
      }
      return productMapping;
    });
  }

  yield put({ type: PRE_SALE_CSV.UPDATE.ACTION, payload });
}

function* onExternalAgentRequest({ payload }) {
  try {
    const url = `/v2/sales/${payload.id}/import/`;
    const response = yield api.put(url, payload);
    yield put(
      importFileSucceeded(response, EXTERNAL_AGENT_XML, payload.errors),
    );
  } catch (e) {
    yield put(importFileFailed(e, EXTERNAL_AGENT_XML));
  }
}

function* onPreSaleCsvRequest({ payload }) {
  try {
    const url = `/v2/sales/${payload.id}/import/`;
    const response = yield api.put(url, payload);
    yield put(importFileSucceeded(response, PRE_SALE_CSV));
  } catch (e) {
    yield put(importFileFailed(e, PRE_SALE_CSV));
  }
}

function* onUpdateExternalAgentXml(action) {
  const state = yield select();
  const payload = { ...action.payload };
  if (Array.isArray(action.payload.products)) {
    // When the user selects a new Product, apply the Breed from that Product
    payload.products = action.payload.products.map(productMapping => {
      const existingMapping =
        state.importers.externalAgentXml &&
        Array.isArray(state.importers.externalAgentXml.products) &&
        state.importers.externalAgentXml.products.find(
          existingProductMapping =>
            existingProductMapping.description === productMapping.description,
        );
      const productHasChanged =
        !existingMapping ||
        existingMapping.productId !== productMapping.productId;

      const product = selectActiveProducts(state)[productMapping.productId];
      if (productMapping.productId && productHasChanged) {
        return {
          ...productMapping,
          breedId: product.breed_id,
        };
      }
      return productMapping;
    });
  }

  yield put({ type: EXTERNAL_AGENT_XML.UPDATE.ACTION, payload });
}

function onDefaultFileSuccess({ errors = [] }) {
  openOverview();
  if (errors.length > 0) {
    openModalLink(ModalTypes.ImportSummary, {
      errors,
      type: ImportStatus.WARNING,
    });
  }
}

function onDownloadPreSaleCsvSampleRequest() {
  const data = `${Object.keys(validationSchema.fields).join(",")}\n`;

  downloadFileBlob(
    "AgriNousCsvSaleImport.csv",
    new Blob([data], { type: "text/csv" }),
  );
}

const handlers = {
  [IMPORT_DEPLOYMENT_SALE.SUCCESS]() {
    toast.success("Import Successful");
    sessionStorage.removeItem(SG8_XML_STORAGE_KEY);
    sessionStorage.removeItem(SG8_XML_FILENAME_KEY);
    openCurrentSale();
  },
  [IMPORT_DEPLOYMENT_SALE.FAILURE]() {
    toast.error("Importing Failed, contact support@agrinous.com for help.");
  },
  [IMPORT_AUCTIONS_PLUS_CSV]: handleImportAuctionsPlusCSV,
  [EXTERNAL_AGENT_XML.PROCESS.ACTION]: onProcessExternalAgentXml,
  [PRE_SALE_CSV.PROCESS.ACTION]: onProcessPreSaleCSV,
};

export default function* importSagas() {
  yield all(
    Object.entries(handlers).map(([action, handle]) =>
      takeLatest(action, handle),
    ),
  );
  yield takeEvery(EXTERNAL_AGENT_XML.IMPORT.ACTION, onImportExternalAgentXml);
  yield takeEvery(EXTERNAL_AGENT_XML.IMPORT.REQUEST, onExternalAgentRequest);
  yield takeEvery(EXTERNAL_AGENT_XML.UPDATE.REQUEST, onUpdateExternalAgentXml);
  yield takeEvery(EXTERNAL_AGENT_XML.IMPORT.SUCCESS, onDefaultFileSuccess);
  yield takeEvery(PRE_SALE_CSV.IMPORT.ACTION, onImportPreSaleCsv);
  yield takeEvery(PRE_SALE_CSV.IMPORT.REQUEST, onPreSaleCsvRequest);
  yield takeEvery(PRE_SALE_CSV.UPDATE.REQUEST, onUpdatePreSaleCsv);
  yield takeEvery(PRE_SALE_CSV.IMPORT.SUCCESS, onDefaultFileSuccess);
  yield takeEvery(
    PRE_SALE_CSV.DOWNLOAD_SAMPLE.REQUEST,
    onDownloadPreSaleCsvSampleRequest,
  );
}
