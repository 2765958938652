import { addWeight } from "actions/scales"; // Directly imported to avert circular import dependencies

import { b64ToArrayBuffer } from "lib";

import { ContinuousOutputView } from "lib/mtDemand";

export const MT_SERIAL_DRIVER_NAME = "mt-serial";

export function mtSerialProcessMessage(
  device,
  type,
  status,
  serialisedPayload,
) {
  const { deviceId } = device;
  const dataArrayBuffer = b64ToArrayBuffer(serialisedPayload);
  const continuousOutputView = new ContinuousOutputView(dataArrayBuffer);

  if (!continuousOutputView.isValid()) {
    return;
  }

  return addWeight(
    deviceId,
    new Date(),
    continuousOutputView.displayedWeightGrams(),
    continuousOutputView.isStable(),
  );
}
