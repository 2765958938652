import React from "react";

import { Tab, Tabs } from "@material-ui/core";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import AgGrid from "components/AgGrid/AgGrid";
import CheckboxCellRenderer from "components/AgGrid/CheckboxCellRenderer";
import { CompositeFilter } from "components/AgGrid/filters/compositeFilter";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";
import { SpeciesSelector } from "components/SpeciesSelector";

import { Settings } from "constants/settings";
import { colors } from "constants/theme";

import { getActiveRole, getSetting } from "selectors";

const getRowStyle = row => {
  const { data } = row;
  if (data.syncing) {
    return {
      background: `${colors.primaryActive}50`,
    };
  }
  if (data.syncError) {
    return {
      background: colors.errorRed,
    };
  }
};

// The blank row at the end of these tables to add new rows causes issues when sorting,
// we want the bottom null row to always be at the bottom, otherwise we get issues.
export const blankRowComparator = (a, b, nodeA, nodeB, isInverted) => {
  if (a === "null") {
    return isInverted ? -1 : 1;
  } else if (b === "null") {
    return isInverted ? 1 : -1;
  } else {
    return a?.toString().localeCompare(b?.toString(), "en", { numeric: true });
  }
};

const SaveButton = styled(Button)`
  flex: 0;
  margin-left: auto;
  align-self: center;
`;

const components = {
  [CompositeFilter.componentName]: CompositeFilter,
  checkboxRenderer: CheckboxCellRenderer,
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  flex: 1,
  editable: true,
  enableCellChangeFlash: true,
  comparator: blankRowComparator,
};

const getRowId = params =>
  params.data.id || params.data.name || params.data.rowId;

const ExtendableTable = ({
  columnDefs,
  deploymentData,
  handleUpdate,
  validationSchema,
  blankRowDefaults,
  context,
}) => {
  const selectedSpeciesId = useSelector(getSetting(Settings.speciesId));

  const { deployments } = useSelector(getActiveRole);

  const [selectedDeploymentId, setSelectedDeploymentId] = React.useState(
    deployments[0].id,
  );

  const agGridInstance = React.useRef();

  const addBlankRowIfNeeded = () => {
    const api = agGridInstance.current?.api;
    if (!api) {
      return;
    }
    // We always want to have one blank row available at the bottom of the table.
    const lastIndex = api.getLastDisplayedRow();
    const last = api.getDisplayedRowAtIndex(lastIndex);
    if (
      last?.data.touched ||
      last?.data.name ||
      last?.data.short_code ||
      lastIndex === -1
    ) {
      api.applyTransaction({
        add: [{ ...blankRowDefaults, id: uuidv4() }],
      });
    }
  };

  const onGridReady = params => {
    agGridInstance.current = params;
    addBlankRowIfNeeded();
  };

  const handleSetSelectedDeploymentId = (_event, newValue) =>
    setSelectedDeploymentId(newValue);

  const save = () => {
    const updates = [];

    let valid = true;

    const rowCount = agGridInstance.current.api.getDisplayedRowCount();

    agGridInstance.current.api.forEachNode((rowNode, index) => {
      const { data } = rowNode;
      if (index + 1 !== rowCount) {
        try {
          validationSchema.validateSync(data);
        } catch {
          rowNode.setData({
            ...rowNode.data,
            syncError: true,
          });
          valid = false;
        }
      }

      const payload = {
        ...data,
        id: data.id || uuidv4(),
        deployment_id: selectedDeploymentId,
        order: index,
        species_id: selectedSpeciesId,
      };

      updates.push(payload);
    });

    // Remove the last (always blank) row.
    updates.pop();

    valid && handleUpdate(updates);
  };

  // Filter the deployment data by selected deployment
  const data = deploymentData.filter(
    attr =>
      attr.deployment_id === selectedDeploymentId &&
      attr.species_id === selectedSpeciesId,
  );

  const rowData = sortBy(data, ["deploymentOrder", "order"]);

  const onCellValueChanged = e => {
    // Add a touched parameter to the rowNode if it has changed.
    if (!isEqual(e.newValue, e.oldValue)) {
      e.node.setData({
        ...e.node.data,
        touched: true,
      });
    }
    // Automatically add a new blank row.
    addBlankRowIfNeeded();
  };

  return (
    <Row flexGrow>
      {deployments.length > 1 && (
        <Column margin={2}>
          <Paper square elevation={2}>
            <Tabs
              orientation="vertical"
              value={selectedDeploymentId}
              onChange={handleSetSelectedDeploymentId}
            >
              {deployments.map(deployment => (
                <Tab
                  label={deployment.channel}
                  value={deployment.id}
                  key={deployment.id}
                />
              ))}
            </Tabs>
          </Paper>
        </Column>
      )}
      <Column full>
        <Row>
          <SpeciesSelector />
          <SaveButton data-tour="save" onClick={save}>
            Save
          </SaveButton>
        </Row>
        <CollapseTableWrapper>
          <AgGrid
            getRowId={getRowId}
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
            onRowDataUpdated={addBlankRowIfNeeded}
            rowData={rowData}
            undoRedoCellEditing
            undoRedoCellEditingLimit={200}
            suppressColumnVirtualisation
            suppressScrollOnNewData
            singleClickEdit
            columnDefs={columnDefs}
            rowDragManaged
            getRowStyle={getRowStyle}
            defaultColDef={defaultColDef}
            rowBuffer={10}
            components={components}
            stopEditingWhenCellsLoseFocus
            context={context}
          />
        </CollapseTableWrapper>
      </Column>
    </Row>
  );
};

export default ExtendableTable;
