import { cloneDeep } from "lodash";
import uniq from "lodash/uniq";
import { createSelector } from "reselect";

import { REPORT_TYPE, ReportDriver, Reports } from "constants/reports";

import {
  getActiveRole,
  getAuctionPens,
  getConsignmentsWithVendorProperties,
  getCurrentSpeciesAttributes,
  getProperties,
  getReportFavourites,
  getReports,
  selectAgencyByConsignmentIdLookup,
  selectSaleSummary,
} from "selectors";

export const DENTITION_REASONING = "dentition_weight_complete";

/**
 * This is selector will crap out if a business (buyer or vendor) ever has
 * access to consignments. That is, when a business has two reports (from
 * different agencies) for the same type (buyer/vendor), only the report from
 * the last agency will be selected
 * @type {function: { buyers: {}, vendors: {} }}
 */
export const reportsByBusinessSelector = createSelector(
  [getReports],
  reports => {
    const reportsByBusiness = {
      [REPORT_TYPE.BUYERS]: {},
      [REPORT_TYPE.VENDORS]: {},
    };
    for (const reportType of [REPORT_TYPE.BUYERS, REPORT_TYPE.VENDORS]) {
      if (reports[reportType] && reports[reportType].rows) {
        reports[reportType].rows.forEach(report => {
          reportsByBusiness[reportType][report.business_cbid] = report;
        });
      }
    }
    return reportsByBusiness;
  },
);

export const prepareBusinessReportData = createSelector(
  [
    getReports,
    getConsignmentsWithVendorProperties,
    getProperties,
    getCurrentSpeciesAttributes,
    getAuctionPens,
    selectAgencyByConsignmentIdLookup,
  ],
  reports => {
    const links = {
      vendor: reports?.vendor?.links || [],
      buyer: reports?.buyer?.links || [],
    };
    return { links };
  },
);

const prepareRestrictedReports = (rows, saleSummary) =>
  rows.map(row => {
    if (row.restriction) {
      if (
        row.restriction === "consignments_complete" &&
        (saleSummary.received === 0 || !saleSummary.allConsignmentsHasPICAndNVD)
      ) {
        row.unavailable = true;
        row.unavailable_reason = "Vendor Exceptions present";
      } else if (
        row.restriction === "sale_complete" &&
        (!saleSummary.balanced ||
          saleSummary.exceptions.vendors.length > 0 ||
          saleSummary.exceptions.buyers.length > 0)
      ) {
        row.unavailable = true;
        row.unavailable_reason = "Vendor or Buyer Exceptions present";
      } else if (
        row.restriction === DENTITION_REASONING &&
        saleSummary.warningsForEqxReport.length > 0
      ) {
        row.unavailable = true;
        row.unavailable_reason = DENTITION_REASONING;
        row.unavailable_params = saleSummary.warningsForEqxReport;
      }
    }
    return row;
  });

const prepareCardReports = cardData => {
  return cardData.map(row => {
    if (row.agency) {
      return row;
    } else {
      row.agency = "CONSOLIDATED";
      return row;
    }
  });
};

export const prepareAgentReportData = createSelector(
  [getReports, selectSaleSummary],
  (reports, saleSummary) => {
    const {
      agencies,
      agents,
      branches,
      buyers,
      cards,
      vendors,
      stud,
      sale_files,
      nlis,
      sale_export,
      saleyard,
      favourites,
    } = reports;
    const reportData = {
      agencies: agencies?.rows || [],
      agencies_force_display: agencies?.force_display,
      agents: agents?.rows || [],
      agents_force_display: agents?.force_display,
      branches: branches?.rows || [],
      branches_force_display: branches?.force_display,
      buyers: buyers?.rows || [],
      buyers_force_display: buyers?.force_display,
      cards: prepareCardReports(cards?.rows || []),
      stud: stud?.rows || [],
      vendors: vendors?.rows || [],
      vendors_force_display: vendors?.force_display,
      sale_files:
        sale_files &&
        prepareRestrictedReports(sale_files?.rows || [], saleSummary),
      sale_files_force_display: sale_files?.force_display,
      nlis: nlis && prepareRestrictedReports(nlis.rows || [], saleSummary),
      nlis_force_display: nlis?.force_display,
      sale_export: sale_export?.rows || [],
      saleyard: saleyard?.rows || [],
      favourites: favourites?.rows || [],
    };

    const filters = {
      vendors: [],
      buyers: [],
      agencies: [],
      agents: [],
    };
    if (buyers && buyers.rows.length > 0) {
      filters.buyers = reports.buyers.rows.reduce(
        (accum, { business_id, business_name }) => {
          if (!accum.find(business => business.id === business_id)) {
            accum.push({ label: business_name, value: business_id });
          }
          return accum;
        },
        [],
      );

      if (filters.buyers.length > 1) {
        filters.buyers.unshift({ value: -1, label: "ALL BUSINESSES" });
      }
    }

    if (vendors && vendors.rows.length > 0) {
      filters.vendors = reports.vendors.rows.reduce(
        (accum, { business_id, business_name }) => {
          if (!accum.find(business => business.id === business_id)) {
            accum.push({ label: business_name, value: business_id });
          }
          return accum;
        },
        [],
      );

      if (filters.vendors.length > 1) {
        filters.vendors.unshift({ value: -1, label: "ALL BUSINESSES" });
      }
    }

    if (agencies && agencies.rows.length > 0) {
      // Only add the agents filter if there are multiple agencies.
      const uniqueAgents = uniq(
        agencies.rows
          .map(row => row.agency)
          .filter(Boolean)
          .sort(),
      );

      if (uniqueAgents.length > 1) {
        filters.agencies = [
          { value: null, label: "ALL AGENCIES" },
          ...uniqueAgents.map(agency => ({ label: agency, value: agency })),
        ];
      }
    }

    if (agents && agents.rows.length > 0) {
      const uniqAgents = uniq(
        agents.rows
          .filter(row => row.business_cbid)
          .map(row => row.business_name)
          .filter(Boolean)
          .sort(),
      );
      if (uniqAgents.length > 1) {
        filters.agents = [
          { value: null, label: "ALL AGENTS" },
          ...uniqAgents.map(agent => ({ label: agent, value: agent })),
        ];
      }
    }

    // We want to match vendor reports up with thier related agents.
    reportData?.vendors.forEach(baseVendor => {
      const vendor = cloneDeep(baseVendor);
      // For the current vendor,
      // see if an agent is related to it and return the index
      const index = reportData.agents.findIndex(agent =>
        agent?.related?.includes(vendor.business_id),
      );

      // If there is a relation, try and add the vendor
      if (index >= 0 && reportData.agents[index]) {
        // Prevent duplicates of vendors being added
        // by checking if the vendor has aleady been added
        if (
          !reportData.agents.some(
            agent =>
              agent.vendor_name === vendor.business_name ||
              agent.business_cbid === vendor.business_cbid,
          )
        ) {
          // Copy the business name to vendor_name for displaying in table
          vendor.vendor_name = vendor.business_name;
          // Clear the business name as it has been copied
          vendor.business_name = null;
          // Add the related agent to the vendor so it can be matched later
          vendor.related_agent = reportData.agents[index].business_name;
          // Add the vendor just below the agent in the reportdata array
          reportData.agents.splice(index + 1, 0, vendor);
        }
      }
    });
    return {
      filters,
      reportData,
    };
  },
);

export const getFavouritesReportsListByTab = createSelector(
  [getReports, getReportFavourites],
  (reports, reportFavourites) => {
    const addReport = (reportsByTab, report) => {
      if (reportFavourites.includes(report.slug)) {
        if (!reportsByTab[report.tab]) {
          reportsByTab[report.tab] = [];
        }
        reportsByTab[report.tab].push(report);
      }
    };
    return Object.values(reports).reduce((reportsByTab, tab) => {
      tab.rows.forEach(row => {
        if (row.links) {
          row.links.forEach(link => {
            addReport(reportsByTab, link);
          });
        } else {
          addReport(reportsByTab, row);
        }
      });
      return reportsByTab;
    }, {});
  },
);

export const selectFavouritedOrFlaggedReportConfigByDriverLookup =
  createSelector(
    [getReportFavourites, getActiveRole],
    (reportFavourites = [], activeRole) =>
      Object.values(Reports).reduce((acc, reportConfig) => {
        if (reportConfig.getAllowRenderReport) {
          if (reportConfig.getAllowRenderReport(activeRole) === false) {
            return acc;
          }
        }

        if (!(ReportDriver.BUYER in acc)) {
          acc[ReportDriver.BUYER] = [];
        }
        if (!(ReportDriver.BIDDER in acc)) {
          acc[ReportDriver.BIDDER] = [];
        }

        if (
          reportFavourites.includes(reportConfig.slug) ||
          reportConfig.showOnBuyerCard
        ) {
          if (reportConfig?.isBuyerDriven) {
            acc[ReportDriver.BUYER].push(reportConfig);
          }
          if (reportConfig?.isBidderDriven) {
            acc[ReportDriver.BIDDER].push(reportConfig);
          }
        }
        return acc;
      }, {}),
  );

export const getFavouritedOrFlaggedReportConfigsByDriver = driver => state =>
  selectFavouritedOrFlaggedReportConfigByDriverLookup(state)[driver];
