import React, { memo, useCallback } from "react";

import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Column } from "components/Layout";
import TabRow from "components/TabSelector/TabRow";

import { ScreenTypes } from "constants/screen";

import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
} from "lib/navigation";

const MobileBobbyCalfSaleTabs = [
  {
    id: "overview",
    display: true,
  },
  {
    id: "take",
    display: true,
  },
  {
    id: "sell",
    display: true,
  },
];
const DesktopBobbyCalfSaleTabs = [
  {
    id: "overview",
    display: true,
  },
  {
    id: "take",
    display: true,
  },
  {
    id: "sell",
    display: true,
  },
  {
    id: "eids",
    title: "EIDs",
    display: true,
  },
  {
    id: "nlis",
    title: "NLIS",
    display: true,
  },
];

function BobbyCalfSaleTabsComponent() {
  const screenType = useSelector(state => state.screen.type);
  const { params } = useRouteMatch();
  const history = useHistory();

  const setSection = useCallback(
    section => {
      history.push(
        `${getSaleyardAuctionRoute(
          getSaleyardName(),
          getLivestockSaleId(),
        )}/${section}`,
      );
    },
    [history],
  );
  const { section } = params;

  const isDesktop = screenType === ScreenTypes.DESKTOP;
  const tabs = isDesktop ? DesktopBobbyCalfSaleTabs : MobileBobbyCalfSaleTabs;

  return (
    <Column printHidden>
      <TabRow
        tabs={tabs}
        selectedTab={section}
        setSelectedTab={setSection}
        backgroundColor="gray95"
      />
    </Column>
  );
}

export default memo(BobbyCalfSaleTabsComponent);
