import React from "react";

import { faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import {
  faContainerStorage,
  faSort,
  faPrint,
  faHistory,
} from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { IconTextButton } from "components/Button";
import { CurrentSaleReadOnly } from "components/ReadOnly";
import TabletDropdown from "components/TabletDropdown";

import { AuditLogTypes } from "constants/auditLog";
import { Settings } from "constants/settings";

import { ForCattle } from "containers/ForSpecies";
import { ForStaff } from "containers/ForUserType";

import {
  getCurrentSaleyardAuctionRoute,
  openAuditLogModal,
} from "lib/navigation";

import { getSetting } from "selectors";

import { useHasSaleLotPermission } from "hooks";

function Actions({
  auctionPenId,
  onClickReorder,
  onClickRepen,
  onClickAddMedia,
}) {
  const saleRoundId = useSelector(getSetting(Settings.round));
  const sellPath = getCurrentSaleyardAuctionRoute(saleRoundId, auctionPenId);
  const hasChangeSaleLotPermission = useHasSaleLotPermission();

  if (!auctionPenId) {
    return null;
  }

  return (
    <TabletDropdown align="right" data-tour="drop">
      <CurrentSaleReadOnly>
        {onClickRepen ? (
          <IconTextButton
            dataTour="repenAll"
            icon={faContainerStorage}
            color="white"
            onClick={onClickRepen}
            disabled={!hasChangeSaleLotPermission}
          >
            Re-Pen All
          </IconTextButton>
        ) : null}
      </CurrentSaleReadOnly>
      <CurrentSaleReadOnly>
        {onClickReorder ? (
          <IconTextButton
            dataTour="reorderPen"
            icon={faSort}
            color="white"
            onClick={onClickReorder}
            disabled={!hasChangeSaleLotPermission}
          >
            Re-Order Pen
          </IconTextButton>
        ) : null}
      </CurrentSaleReadOnly>
      {onClickAddMedia && (
        <IconTextButton
          dataTour="addMedia"
          icon={faPhotoVideo}
          color="white"
          onClick={onClickAddMedia}
        >
          Add Media
        </IconTextButton>
      )}
      <ForCattle>
        <IconTextButton
          to={`${sellPath}/cattlepencard`}
          as={Link}
          color="white"
          dataTour="cattlePenCard"
          icon={faPrint}
        >
          Cattle Pen Card
        </IconTextButton>
      </ForCattle>
      <ForStaff>
        <IconTextButton
          dataTour="auditLog"
          color="white"
          icon={faHistory}
          onClick={() => openAuditLogModal(AuditLogTypes.PEN, auctionPenId)}
        >
          Audit Log
        </IconTextButton>
      </ForStaff>
    </TabletDropdown>
  );
}

export default React.memo(Actions);
