import styled from "styled-components/macro";

const NestedCard = styled.div(
  ({
    theme,
    bordered,
    lightBackground,
    whiteBackground,
    smallSpacing,
    slim,
  }) => `
  margin-top: 10px;
  border-radius: ${theme.radii[2]}px;
  border: ${bordered ? `1px solid ${theme.colors.gray85}` : "none"};
  background-color: ${
    lightBackground
      ? "rgba(255,255,255,0.38)"
      : whiteBackground
        ? theme.colors.white
        : theme.colors.gray95
  };
  padding: ${smallSpacing ? "6px" : `${theme.space[2]}px`};

  ${
    slim
      ? `padding-left: ${theme.space[1]}px; padding-right: ${theme.space[1]}px;`
      : ""
  }

`,
);

export default NestedCard;
