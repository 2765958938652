import {
  createLookupCombiner,
  createLookupSelectors,
  getMasterRules,
  getRules,
  selectMasterRuleIdsByMasterRuleBookIdLookup,
  selectRuleBookIdByRuleIdLookup,
  selectRuleIdsByMasterRuleIdLookup,
  selectRuleIdsByRuleBookIdLookup,
} from "selectors";

export const getMasterRuleById = ruleBookId => state =>
  getMasterRules(state)[ruleBookId] || null;
export const getRuleById = ruleId => state => getRules(state)[ruleId] || null;
export const getRuleIdsByRuleBookId = ruleBookId => state =>
  selectRuleIdsByRuleBookIdLookup(state)[ruleBookId] || null;

export const getRuleIdsByMasterRuleId = masterRuleId => state =>
  selectRuleIdsByMasterRuleIdLookup(state)[masterRuleId];

export const getMasterRuleIdsByMasterRuleBookId = masterRuleBookId => state =>
  selectMasterRuleIdsByMasterRuleBookIdLookup(state)[masterRuleBookId];

export const [
  selectMasterRulesByMasterRuleBookIdLookup,
  getMasterRulesByMasterRuleBookId,
] = createLookupSelectors(
  [selectMasterRuleIdsByMasterRuleBookIdLookup, getMasterRules],
  createLookupCombiner((masterRuleIds, masterRuleByIdLookup) =>
    masterRuleIds
      .map(masterRuleId => masterRuleByIdLookup[masterRuleId])
      .sort(
        (masterRuleA, masterRuleB) => masterRuleA.order - masterRuleB.order,
      ),
  ),
);

export const [
  selectRuleBookIdsByMasterRuleBookIdLookup,
  getRuleBookIdsByMasterRuleBookId,
] = createLookupSelectors(
  [
    selectMasterRuleIdsByMasterRuleBookIdLookup,
    selectRuleIdsByMasterRuleIdLookup,
    selectRuleBookIdByRuleIdLookup,
  ],
  (
    masterRuleIdsByMasterRuleBookId,
    ruleIdsByMasterRuleIdLookup,
    ruleBookIdByRuleIdLookup,
  ) =>
    Object.entries(masterRuleIdsByMasterRuleBookId).reduce(
      (acc, [masterRuleBookId, masterRuleIds]) => {
        if (!acc[masterRuleBookId]) {
          acc[masterRuleBookId] = [];
        }
        masterRuleIds.forEach(masterRuleId => {
          const ruleIds = ruleIdsByMasterRuleIdLookup[masterRuleId] || [];
          ruleIds.forEach(ruleId => {
            const ruleBookId = ruleBookIdByRuleIdLookup[ruleId];
            if (!acc[masterRuleBookId].includes(ruleBookId)) {
              acc[masterRuleBookId].push(ruleBookId);
            }
          });
        });
        return acc;
      },
      {},
    ),
);

export const [selectRulesByRuleBookIdLookup, getRulesByRuleBookId] =
  createLookupSelectors(
    [selectRuleIdsByRuleBookIdLookup, getRules],
    createLookupCombiner((ruleIds, ruleByIdLookup) =>
      ruleIds
        .map(ruleId => ruleByIdLookup[ruleId])
        .sort((ruleA, ruleB) => ruleA.order - ruleB.order),
    ),
  );

const isMoveUpEnabledReducer = (
  ruleOrMasterRule,
  rulesOrMasterRulesByRuleBookIdOrMasterRuleBookId,
) => {
  const ruleBookRules =
    rulesOrMasterRulesByRuleBookIdOrMasterRuleBookId[
      ruleOrMasterRule.rule_book_id
    ] || [];
  return !(ruleBookRules[0]?.id === ruleOrMasterRule.id);
};

export const [selectIsMoveUpEnabledByRuleIdLookup, getIsMoveUpEnabledByRuleId] =
  createLookupSelectors(
    [getRules, selectRulesByRuleBookIdLookup],
    createLookupCombiner(isMoveUpEnabledReducer),
  );

export const [
  selectIsMoveUpEnabledByMasterRuleIdLookup,
  getIsMoveUpEnabledByMasterRuleId,
] = createLookupSelectors(
  [getRules, selectRulesByRuleBookIdLookup],
  createLookupCombiner(isMoveUpEnabledReducer),
);

const isMoveDownEnabledReducer = (
  ruleOrMasterRule,
  rulesOrMasterRulesByRuleBookIdOrMasterRuleBookId,
) => {
  const ruleBookRules =
    rulesOrMasterRulesByRuleBookIdOrMasterRuleBookId[
      ruleOrMasterRule.rule_book_id
    ] || [];
  return !(ruleBookRules[ruleBookRules.length - 1]?.id === ruleOrMasterRule.id);
};

export const [
  selectIsMoveDownEnabledByMasterRuleIdLookup,
  getIsMoveDownEnabledByMasterRuleId,
] = createLookupSelectors(
  [getMasterRules, selectMasterRulesByMasterRuleBookIdLookup],
  createLookupCombiner(isMoveDownEnabledReducer),
);

export const [
  selectIsMoveDownEnabledByRuleIdLookup,
  getIsMoveDownEnabledByRuleId,
] = createLookupSelectors(
  [getRules, selectRulesByRuleBookIdLookup],
  createLookupCombiner(isMoveDownEnabledReducer),
);

const lastOrderReducer = rulesOrMasterRules => {
  const { order } = rulesOrMasterRules[rulesOrMasterRules.length - 1];
  return typeof order === "number" ? order : -1;
};

export const [selectLastOrderByRuleBookIdLookup, getLastOrderByRuleBookId] =
  createLookupSelectors(
    [selectRulesByRuleBookIdLookup],
    createLookupCombiner(lastOrderReducer),
  );

export const [
  selectLastOrderByMasterRuleBookIdLookup,
  getLastOrderByMasterRuleBookId,
] = createLookupSelectors(
  [selectMasterRulesByMasterRuleBookIdLookup],
  createLookupCombiner(lastOrderReducer),
);
