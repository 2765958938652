/**
 * @param {string} vendor_id
 * @param {string} vendor_property_id
 * @param {array} consignments
 */
import { TRANSFER_STATUS } from "constants/nlis";
import { consignmentStatuses } from "constants/sale";

import { neg1ToNulls } from "lib/index";

import { EuDeclarationVersions } from "./nvd";

export const getVendorPropertyConsignmentWithoutTake = (
  { vendor_id, vendor_property_id },
  consignments,
) =>
  consignments.find(
    c =>
      c.vendorId === vendor_id &&
      c.vendorPropertyId === vendor_property_id &&
      (!c.takeStatus ||
        c.takeStatus.filter(ts => ts.status !== TRANSFER_STATUS.ROLLED_BACK)
          .length === 0),
  );

export const getConsignmentPayload = values => {
  // Make sure that various values within the values dict are of the correct
  // type and not overridden to a blank string.
  const nulledValues = neg1ToNulls(values);
  if (nulledValues.quantity_NVD === "") {
    nulledValues.quantity_NVD = null;
  }
  // Remove stuff we don't need.
  delete nulledValues.nlis_take_file_status;
  return nulledValues;
};

export const getConsignmentStatus = consignment =>
  consignment.hasArrived
    ? consignmentStatuses.ARRIVED
    : consignmentStatuses.BOOKED;

export function getConsignmentCode(consignment) {
  const { vendorNumber, livestock_agency_code: livestockAgencyCode } =
    consignment || {};
  const vendorDisplayNumber = vendorNumber || "-";
  const displayLivestockAgencyCode = livestockAgencyCode || "-";
  return `${displayLivestockAgencyCode}${vendorDisplayNumber}`;
}

/**
 * Retrieves the Consignment Id for a given Eid. Inputs for Sale Lots and Saleyard Sale Lots and Scans should be the
 * raw values from the state. Omitting either the saleLots parameter or the saleyardScanSaleLots parameter, when operating on a
 * list and combined with a filter on the resulting list has the effect of filtering scans by Auction Sale Lot
 * type or Saleyard Scan Sale Lot type
 * @param {Scan} scan
 * @param {Object.<string, SaleLot>} saleLots
 * @param {Object.<string, SaleyardScanSaleLot>} saleyardScanSaleLots
 * @returns {?string}
 */
export function getConsignmentIdForScan(
  scan,
  saleLots = {},
  saleyardScanSaleLots = {},
) {
  if (!scan) {
    return null;
  }
  const saleLot = saleLots[scan.sale_lot_id];
  if (saleLot) {
    return saleLot.consignment_id;
  }
  const saleyardScanSaleLot = saleyardScanSaleLots[scan.sale_lot_id];
  if (saleyardScanSaleLot) {
    return saleyardScanSaleLot.consignmentId;
  }
  return null;
}

export function isConsignmentBalanced(
  consignmentQuantity,
  consignmentSaleLotsQuantity,
  areAllConsignmentSaleLotsPennedAndBalanced,
) {
  return (
    consignmentQuantity === consignmentSaleLotsQuantity &&
    areAllConsignmentSaleLotsPennedAndBalanced
  );
}

/**
 * Retrieve the HGP text based on a list of consignments
 *
 * If there are no declarations -> blank
 * If all consignments are declared treated with HGP => "Y"
 * If all consignments are declared NOT treated with HGP, OR are using an EU NVD => "N"
 * Otherwise => "?"
 * @param {Array} consignments
 * @returns {string}
 */
export const getConsignmentsHgpDetails = (consignments = []) => {
  const declarations = consignments.map(consignment => consignment.declaration);

  const hasDeclarations = !declarations.every(
    declaration => declaration === null,
  );

  if (!hasDeclarations) {
    return "";
  }

  const allConsignmentsHaveDeclarations = declarations.every(
    declaration => declaration !== null,
  );

  const allConsignmentsAreTreatedWithHGP =
    allConsignmentsHaveDeclarations &&
    declarations.every(declaration => declaration?.treated_with_hgp);

  const allConsignmentsAreNotTreatedWithHGP =
    allConsignmentsHaveDeclarations &&
    declarations.every(declaration => !declaration?.treated_with_hgp);

  const areUsingAnEuNvd = Boolean(
    declarations.filter(declaration =>
      EuDeclarationVersions.includes(declaration?.VERSION),
    ).length,
  );

  if (allConsignmentsAreTreatedWithHGP) {
    return "Y";
  }

  if (areUsingAnEuNvd || allConsignmentsAreNotTreatedWithHGP) {
    return "N";
  }

  return "?";
};

export function formatConsignmentLabel(consignment, livestockAgencyName) {
  const { vendorName, quantity } = consignment;
  const consignmentCode = getConsignmentCode(consignment);
  const vendorDisplayName = vendorName || "Unknown";
  return `${consignmentCode} ${livestockAgencyName} - ${vendorDisplayName} (Hd ${quantity})`;
}
