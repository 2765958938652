import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";
import { Status } from "components/SingleWeighView/SingleWeighControlScreen/Status";
import Crush from "components/SingleWeighView/SingleWeighDraftingDecision/Crush";

import { ButtonPanel } from "./ButtonPanel";

const FormSectionGrid = styled(Grid)`
  && {
    width: inherit;
    margin: inherit;
    min-height: 100%;
  }
`;

function SingleWeighControlsComponent({ singleWeighId }) {
  return (
    <Row padding={1} yOverflow="auto" flex={false} basis="0" flexGrow>
      <FormSectionGrid spacing={2} container>
        {/* Controls */}
        <Grid container item sm={12} md={2} alignContent="flex-start">
          <ButtonPanel singleWeighId={singleWeighId} />
        </Grid>

        {/* Status */}
        <Grid item container sm={6}>
          <Status singleWeighId={singleWeighId} />
        </Grid>

        {/* Gate Status */}
        <Grid container item sm={6} md={4} direction="column">
          <Paper>
            <Column padding={2} borderColor="surfaceBorder" alignCenter>
              <Grid item>Entry Gate</Grid>
            </Column>
            <Column padding={2} full justifyCenter>
              <Crush singleWeighId={singleWeighId} />
            </Column>
            <Column padding={2} borderColor="surfaceBorder" alignCenter>
              <Grid item>Exit Gate</Grid>
            </Column>
          </Paper>
        </Grid>
      </FormSectionGrid>
    </Row>
  );
}
SingleWeighControlsComponent.propTypes = {
  singleWeighId: PropTypes.string.isRequired,
};

export default memo(SingleWeighControlsComponent);
