import Big from "big.js";
import { createSelector } from "reselect";

import { VendorSplitType } from "constants/vendorSplits";

import { EMPTY_ARRAY } from "lib";

import { getVendorSplitKey } from "lib/vendorSplits";

import {
  createLookupCombiner,
  createLookupSelectors,
  getBusinesses,
  getDefaultVendorSplits,
  getSaleVendorSplits,
  selectDefaultVendorSplitIdsByBusinessIdLookup,
} from "selectors";

const mapper = (vendorSplitIds, VendorSplitLookup) =>
  vendorSplitIds?.map(id => VendorSplitLookup[id])?.[0];

export const [
  selectDefaultVendorSplitByBusinessIdLookup,
  getDefaultVendorSplitByBusinessId,
] = createLookupSelectors(
  [selectDefaultVendorSplitIdsByBusinessIdLookup, getDefaultVendorSplits],
  createLookupCombiner(mapper),
);

const sortSplitsForDisplay = vendorSplit => {
  // Primary vendor first, then by decreasing amount
  return (
    vendorSplit?.splits.sort((a, b) => {
      if (a.businessId === vendorSplit.primaryBusinessId) {
        return -1;
      }
      if (b.businessId === vendorSplit.primaryBusinessId) {
        return 1;
      }
      return -a.amount.localeCompare(b.amount);
    }) || []
  );
};

export const getSplitDescription = (vendorSplit, businessLookup) => {
  return sortSplitsForDisplay(vendorSplit)
    .map(split => {
      const businessName = businessLookup[split.businessId]?.name || "unknown";
      const amount = new Big(split.amount);
      if (vendorSplit.type === VendorSplitType.Head) {
        return `${amount} hd to ${businessName}`;
      } else {
        return `${amount.times(100)}% to ${businessName}`;
      }
    })
    .join(", ");
};

export const [selectDescriptionBySplitIdLookup, getDescriptionBySplitId] =
  createLookupSelectors(
    [getSaleVendorSplits, getBusinesses],
    createLookupCombiner(getSplitDescription),
    EMPTY_ARRAY,
  );

export const getVendorSplitSummary = vendorSplit => {
  return sortSplitsForDisplay(vendorSplit)
    .map(split => {
      const amount = new Big(split.amount);
      if (vendorSplit.type === VendorSplitType.Head) {
        return `${amount}hd `;
      } else {
        return `${amount.times(100)}%`;
      }
    })
    .join("/");
};

export const selectVendorSplitByKeyLookup = createSelector(
  [getSaleVendorSplits],
  saleVendorSplitLookup => {
    return Object.values(saleVendorSplitLookup).reduce((acc, split) => {
      const splitKey = getVendorSplitKey(split);
      acc[splitKey] = split;
      return acc;
    }, {});
  },
);

export const getVendorSplitByKey = vendorSplitKey => state =>
  selectVendorSplitByKeyLookup(state)[vendorSplitKey] || null;
