import styled from "styled-components/macro";

import AgrinousChristmasIcon from "img/AgriNous-christmas-icon";
import ChristmasLogo from "img/AgriNous-christmas-logo";
import Logo from "img/AgriNous-logo";
import LogoIcon from "img/AgriNous-logo-icon";

export const SidebarLogo = styled.div`
  ${({ allowDashboard }) => (allowDashboard ? "cursor: pointer;" : "")}
  ${({ isRotatedLogo }) =>
    isRotatedLogo
      ? "transform: rotate(90deg) scaleY(1.2) scaleX(1.2);  left: 83px; top: -20px;"
      : "left: 8px; top: 0px;"}
  position: absolute;
  overflow-x: hidden;
`;

export const ChristmasLogoFull = styled(ChristmasLogo)`
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 30px;
`;

export const ChristmasLogoSmall = styled(AgrinousChristmasIcon)`
  transition: all 2s;
  overflow: hidden;
`;

export const LogoFull = styled(Logo)`
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 30px;
`;

export const LogoSmall = styled(LogoIcon)`
  transition: all 2s;
  overflow: hidden;
`;
