import React from "react";

import { useSelector } from "react-redux";

import CommentForm from "components/Comments/components/CommentForm";
import CommentList from "components/Comments/components/CommentList";
import { SecondaryButton } from "components/Form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { CommentTypes } from "constants/comments";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

import {
  getCommentTitleByNominationId,
  getCommentTitleBySaleLotId,
  getCommentTitleByReceivalLotId,
  getCommentTitleByPenScanLotId,
  getCommentTitleByDeploymentSaleId,
  getCommentTitleByWeighLotId,
} from "selectors";

const CommentTypeTitleByIdSelector = {
  [CommentTypes.SALE_LOT]: getCommentTitleBySaleLotId,
  [CommentTypes.NOMINATION]: getCommentTitleByNominationId,
  [CommentTypes.RECEIVAL_LOT]: getCommentTitleByReceivalLotId,
  [CommentTypes.PEN_SCAN_LOT]: getCommentTitleByPenScanLotId,
  [CommentTypes.DEPLOYMENT_SALE]: getCommentTitleByDeploymentSaleId,
  [CommentTypes.WEIGH_LOT]: getCommentTitleByWeighLotId,
};

function CommentModal({ returnTo, commentType, commentTypeId }) {
  const title = useSelector(
    CommentTypeTitleByIdSelector[commentType](commentTypeId),
  );

  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  return (
    <Dialog open onClose={closeSelf} maxWidth="lg" fullWidth>
      <DialogTitle onClose={closeSelf}>Comments on {title}</DialogTitle>
      <DialogContent shrink={1}>
        <CommentList commentType={commentType} commentTypeId={commentTypeId} />
      </DialogContent>
      <DialogContent shrink={0} minHeight="150px">
        <CommentForm commentType={commentType} commentTypeId={commentTypeId} />
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={closeSelf}>Close</SecondaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default CommentModal;
