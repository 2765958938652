import { orderBy, range, sortBy, uniq } from "lodash";
import isNaN from "lodash/isNaN";
import isNumber from "lodash/isNumber";
import maxBy from "lodash/maxBy";
import min from "lodash/min";
import startCase from "lodash/startCase";
import stableSort from "stable";
import { v4 as uuidv4 } from "uuid";

import {
  MAX_PEN_RANGE,
  PenScanningGroupingType,
  PenTypes,
  PenTypesWithRounds,
  ScanLotStatus,
  ScanningPenStatus,
} from "constants/auctionPens";
import { auctionPenStatuses, saleLotStatuses } from "constants/sale";

import { EMPTY_ARRAY } from "lib/index";

import { caseInsensitiveCompare } from "./compare";
import { formatSaleLotOverflow } from "./saleLot";
import { serializeAuctionPen } from "./serializers/auctionPens";

/**
 * @typedef {Object} StartPenName
 * @property {string} start_pen
 */
/**
 * @typedef {Object} EndPenName
 * @property {string} end_pen
 */

/**
 * @typedef {Object & StartPenName & EndPenName} AuctionPen
 * @property {number} deploymentSaleId
 * @property {string} id UUID
 * @property {boolean} isLaneStart
 * @property {string} livestocksale_id
 * @property {string} order
 * @property {string} sale_round
 */

/**
 * @typedef {Object & StartPenName & EndPenName} SerialisedAuctionPen
 * @property {number} deployment_sale_id
 * @property {string} id UUID
 * @property {boolean} isLaneStart
 * @property {string} livestocksale_id
 * @property {string} order
 * @property {string} saleRoundId
 */

/**
 * @typedef {Object} StartPenComponents
 * @property {number} start_pen
 * @property {string} start_pen_prefix
 * @property {string} start_pen_suffix
 */

/**
 * @typedef {Object} EndPenComponents
 * @property {number} end_pen
 * @property {string} end_pen_suffix
 */

/**
 * @typedef {AuctionPen & LivestockSaleRequest} AuctionPenRequest
 */

const comparableValue = val => {
  // Convert objects or undefined/null to empty strings so it can be compared
  // with other strings.
  if (typeof val === "object") {
    return "";
  } else if (typeof val === "number" && !Number.isNaN(val)) {
    return `${val}`;
  }
  return val || "";
};

export const getNumber = s => {
  const n = s.match(/\D*(\d+)\D*/);
  return n && n.length ? parseInt(n[1], 10) : NaN;
};

export const comparePens = (pen0, pen1) => caseInsensitiveCompare(pen0, pen1);

export const comparePensAndArchetypes = (pen0, pen1) =>
  pen0.penArchetypeId ? pen0 - 1 : pen1.penArchetypeId ? pen1 - 1 : 0;

/**
 * Get a function to compare two salelots for sorting purposes
 *
 * @param {object} sortBykey
 * @returns {function}
 */
export const getCompareSaleLots = sortBykey => (lot0, lot1) => {
  if (sortBykey === "startPen") {
    // Sort by auction pen if pen, newest lots to top (lot number desc)
    const penResult = comparePens(lot0.startPen, lot1.startPen);
    return penResult || lot1.lotNumber - lot0.lotNumber;
  }
  if (sortBykey === "lotNumber") {
    return lot0.lotNumber - lot1.lotNumber;
  }

  // Otherwise compare and then by lot number asc
  const result = comparableValue(lot0[sortBykey]).localeCompare(
    comparableValue(lot1[sortBykey]),
  );
  return result || lot0.lotNumber - lot1.lotNumber;
};

function discardUnsortables(a, b) {
  if (!a && !b) {
    return 0;
  }
  if (!b) {
    return 1;
  }
  if (!a) {
    return -1;
  }

  return null;
}

/**
 Manual Sort order, then by natural sort

 +-------+-----------+-------+--------------------------------------------------------------------------------------------+
 | Index | Pen Name  | Order | Notes                                                                                      |
 +-------+-----------+-------+--------------------------------------------------------------------------------------------+
 | 0     | 20        | 10    | Explicitly order items come before naturally sorted items                                  |
 | 1     | 20a       | 10    | Explicitly sorted item collisions are resolved with a natural sort on the pen name         |
 | 2     | 1a200     | None  | Anything with a `number-character` prefix pair goes to the top                             |
 | 3     | 99z50dead | None  |                                                                                            |
 | 4     | 9         | None  | Pen names with no prefix will naturally appear after ones with a `number-character` prefix |
 | 5     | 10        | None  |                                                                                            |
 | 6     | 10a       | None  | Naturally sorted items with a suffix after a collision with the same pen name              |
 | 7     | 10b       | None  |                                                                                            |
 | 8     | 22        | None  |                                                                                            |
 | 9     | a400      | None  | Anything with a `character` prefix goes to the bottom, and is naturally sorted             |
 +-------+-----------+-- ----+--------------------------------------------------------------------------------------------+
 */
export function auctionPenSellingOrderSort(a, b) {
  const unsortable = discardUnsortables(a, b);
  if (unsortable !== null) {
    return unsortable;
  }
  // If there is no pen number (ie it has been unpenned)
  // move to the top.
  if (!b.startPen && !b.start_pen) {
    return 1;
  }
  if (!a.startPen && !a.start_pen) {
    return -1;
  }

  const isANum = Number.isInteger(a.order);
  const isBNum = Number.isInteger(b.order);

  // Sometimes start_pen, sometime startPen
  const aStart = a.start_pen || a.startPen;
  const bStart = b.start_pen || b.startPen;

  // If neither pen has an order, sort by start pen.
  if (!isANum && !isBNum) {
    return comparePens(aStart, bStart);
  }
  // If only B has an order.
  if (!isANum) {
    return 1;
  }
  // If only A has an order.
  if (!isBNum) {
    return -1;
  }
  // Both have order, so sort as normal.
  if (a.order > b.order) {
    return 1;
  }
  if (b.order > a.order) {
    return -1;
  }
  // Order is the same, compare by start pen.
  return comparePens(aStart, bStart);
}

export function getPenFirstWeighed(pen) {
  return min(
    pen.sale_lots.filter(l => l.timeWeighed).map(l => new Date(l.timeWeighed)),
  );
}

// Sorts the pen by the first weighed lot in the pen, in reverse order - oldest first.
// Unweighed pens should fall to the bottom.
// Weighed at identical times (unlikely) and unweighed lots fall back to default pen sorting.
function auctionPenWeighTimeOrderSort(a, b) {
  const unsortable = discardUnsortables(a, b);
  if (unsortable !== null) {
    return unsortable;
  }

  const aWeighTime = getPenFirstWeighed(a);
  const bWeighTime = getPenFirstWeighed(b);

  // Neither are weighed - default sort order.
  if (!aWeighTime && !bWeighTime) {
    return auctionPenSellingOrderSort(a, b);
  }
  // b not weighed, to the bottom with thee
  if (!bWeighTime) {
    return -1;
  }
  // a not weighed, to the bottom with thee
  if (!aWeighTime) {
    return 1;
  }

  // Compare them!
  if (aWeighTime > bWeighTime) {
    return 1;
  } else if (bWeighTime > aWeighTime) {
    return -1;
  }
  // Identical weigh times - fallback to normal order.
  return auctionPenSellingOrderSort(a, b);
}

export function getPenLastSeenAtTime(pen) {
  return min(
    pen.sale_lots
      .filter(l => l.lastSeenAtTime)
      .map(l => new Date(l.lastSeenAtTime)),
  );
}

// Sorts the pen by the last seen ot time, from the weighbridge.
// Unweighed pens should fall to the bottom.
// Weighed at identical times (unlikely) and unweighed lots fall back to default pen sorting.
function auctionPenLastSeenOrderSort(a, b) {
  const unsortable = discardUnsortables(a, b);
  if (unsortable !== null) {
    return unsortable;
  }

  const aSeenTime = getPenLastSeenAtTime(a);
  const bSeenTime = getPenLastSeenAtTime(b);

  // Neither have been seen - default sort order.
  if (!aSeenTime && !bSeenTime) {
    return auctionPenSellingOrderSort(a, b);
  }
  // b not seen, to the bottom with thee
  if (!bSeenTime) {
    return -1;
  }
  // a not seen, to the bottom with thee
  if (!aSeenTime) {
    return 1;
  }
  // Compare them!
  if (aSeenTime > bSeenTime) {
    return 1;
  } else if (bSeenTime > aSeenTime) {
    return -1;
  }
  // Identical seen times - fallback to normal order.
  return auctionPenSellingOrderSort(a, b);
}

export const sortPenByOrder = auctionPens => {
  return stableSort(auctionPens, auctionPenSellingOrderSort);
};

export const sortByWeighTime = auctionPens => {
  return stableSort(auctionPens, auctionPenWeighTimeOrderSort);
};

export const sortByLastSeenTime = auctionPens => {
  return stableSort(auctionPens, auctionPenLastSeenOrderSort);
};

export const getSortedRoundsFromAuctionPens = auctionPens => {
  const rounds = {};

  const auctionPenOrder = sortPenByOrder(auctionPens);

  for (const auctionPen of auctionPenOrder) {
    for (const sale_lot of auctionPen.sale_lots) {
      const {
        sale_round: { id: saleRoundId, name: nameRound },
      } = sale_lot;
      if (!rounds[saleRoundId]) {
        rounds[saleRoundId] = { auctionPens: [], name: nameRound };
      }
      if (!rounds[saleRoundId].auctionPens.includes(auctionPen.id)) {
        rounds[saleRoundId].auctionPens.push(auctionPen.id);
      }
    }
  }
  return rounds;
};

export const isSoldOrIsDeliveredWithNonZeroPrice = saleLot => {
  const { buyer, total_price_cents, status } = saleLot;
  if (status) {
    return status === saleLotStatuses.SOLD;
  }
  return buyer && buyer.id && total_price_cents;
};

export const isSoldOrNoSale = saleLot =>
  [
    saleLotStatuses.NO_SALE,
    saleLotStatuses.SOLD,
    saleLotStatuses.DELIVERED,
  ].includes(saleLot.status);

export const isSoldOrDelivered = saleLot =>
  [saleLotStatuses.SOLD, saleLotStatuses.DELIVERED].includes(saleLot.status);

export const getSoldSaleLotQuantity = saleLots =>
  saleLots &&
  saleLots
    .filter(isSoldOrIsDeliveredWithNonZeroPrice)
    .reduce((sum, lot) => sum + parseInt(lot.quantity, 10), 0);

export const getTotalSaleLotQuantity = saleLots =>
  saleLots &&
  saleLots.reduce((sum, lot) => sum + parseInt(lot.quantity, 10), 0);

const getSaleLotStatus = saleLot => {
  if (!(saleLot.buyer && saleLot.buyer.id)) {
    return "UNSOLD";
  } else if (saleLot.quantity_delivered > 0) {
    return "DELIVERED";
  } else if (saleLot.buyer.id === saleLot.vendor.id) {
    return "NO SALE";
  } else if (!saleLot.quantity_delivered) {
    return "SOLD";
  } else {
    return "UNKNOWN";
  }
};

export const getSalesOrderedByStatus = saleLots =>
  saleLots.reduce((accum, saleLot) => {
    const status = getSaleLotStatus(saleLot);
    if (!accum[status]) {
      accum[status] = [];
    }
    accum[status].push(saleLot);
    return accum;
  }, {});

/**
 * Create a string based describing the selected sale lots or lot
 *
 * @param {array} saleLots
 * @param {array} selectedSaleLotIds
 */
export const getSaleLotDescription = (saleLots, selectedSaleLotIds) => {
  const numSelectedSaleLots = selectedSaleLotIds.length;
  const firstSelectedSaleLotId = selectedSaleLotIds[0];
  const firstSelectedSaleLot = saleLots.find(
    saleLot => saleLot.id === firstSelectedSaleLotId,
  );

  if (numSelectedSaleLots === 1) {
    const speciesInfo = [];
    ["sale_round", "breed", "sex_group"].forEach(group => {
      if (
        firstSelectedSaleLot &&
        firstSelectedSaleLot[group] &&
        firstSelectedSaleLot[group].name
      ) {
        const groupText =
          group === "sale_round"
            ? firstSelectedSaleLot[group].name.toUpperCase()
            : startCase(firstSelectedSaleLot[group].name);
        speciesInfo.push(groupText);
      }
    });
    return speciesInfo.join(" - ");
  } else if (numSelectedSaleLots > 1) {
    try {
      return `${firstSelectedSaleLot.sale_round.name.toUpperCase()} - ${numSelectedSaleLots} drafts`;
    } catch (e) {
      /* do nothing */
    }
  }
  return "";
};

const penString = (prefix = "", num = "", suffix = "") => {
  const value = `${prefix}${num || ""}${suffix}`;
  return value === "" ? undefined : value;
};

/**
 * @param {StartPenName & EndPenName} auctionPen
 * @param {string} [defaultValue="N/A"]
 * @returns {string}
 */
export const getAuctionPenDisplayName = (auctionPen, defaultValue = "N/A") => {
  const {
    start_pen: startPen,
    startPen: startPenAlt,
    end_pen: endPen,
    endPen: endPenAlt,
  } = auctionPen || {};
  if (startPen || endPen || startPenAlt || endPenAlt) {
    return `${startPen || startPenAlt}${
      endPen || endPenAlt ? `-${endPen || endPenAlt}` : ""
    }`;
  }
  return defaultValue;
};

export const getExpandedStartPenDisplayName = ({
  start_pen_prefix = "",
  start_pen = "",
  start_pen_suffix = "",
}) => penString(start_pen_prefix, start_pen, start_pen_suffix);

export const getExpandedEndPenDisplayName = ({
  end_pen = "",
  end_pen_suffix = "",
}) => penString("", end_pen, end_pen_suffix);

/**
 * @param {StartPenComponents & EndPenComponents} auctionPen
 * @param {string} [defaultValue="N/A"]
 * @returns {string}
 */
export const getExpandedAuctionPenDisplayName = (
  expandedAuctionPen,
  defaultValue = "N/A",
) => {
  return getAuctionPenDisplayName(
    {
      start_pen: getExpandedStartPenDisplayName(expandedAuctionPen),
      end_pen: getExpandedEndPenDisplayName(expandedAuctionPen),
    },
    defaultValue,
  );
};

/**
 * @param {StartPenComponents & EndPenComponents & LivestockSaleRequest} values
 * @returns {StartPenName & EndPenName & LivestockSaleRequest}
 */
export const getAuctionPenPayload = (
  values,
  namespace,
  penType = PenTypes.SELLING,
) => {
  const penValues = (namespace ? values[namespace] : values) || {};
  const { agency_id, sale_round_id, saleRoundId } = penValues;

  const agencyId = agency_id || values.agency_id;

  const actualSaleRoundId =
    values.sale_round_id || values.saleRoundId || sale_round_id || saleRoundId;

  const payload = {
    start_pen: getExpandedStartPenDisplayName(penValues),
    end_pen: getExpandedEndPenDisplayName(penValues) || null,
    isLocked: penValues.isLocked,
    agency: agencyId,
    penType,
  };

  if (PenTypesWithRounds.includes(penType)) {
    payload.saleRoundId = actualSaleRoundId;
  } else {
    payload.saleRoundId = null;
  }
  return payload;
};

// The validation rules that is the prefix must end in a non-numeric, the pen number itself must be numeric, and the suffix cannot contain numbers.
// So, these should all be valid:
// X1234Y  -> X  123  Y
// FFABC232 -> FFABC 232
// **123?$ -> ** 123 ?$
// !123!123 -> !123! 123
// 55-77 -> 55- 77   (THIS COULD GET VERY CONFUSING FOR PEN RANGES)
const auctionPenRegex = /(.*?\D)?(\d+)(\D*)/;

export const getIdOfPenObject = (ns, values) => {
  // The Pen object could be
  // - {ns}Id (deliveryPenId)
  // - {ns}_id (auction_pen_id)
  // - auction_pen_id
  // TODO: When auctionPenId is refactored throughout the app, clean this up.
  return values[`${ns}_id`] || values[`${ns}Id`] || values.auction_pen_id;
};

/**
 * @param startPenDisplayName
 * @returns {StartPenComponents|null}
 */
export const getStartPenComponents = startPenDisplayName => {
  const match = auctionPenRegex.exec(startPenDisplayName);
  if (match) {
    const [, start_pen_prefix, start_pen, start_pen_suffix] = match;
    return {
      start_pen_prefix,
      start_pen: +start_pen,
      start_pen_suffix,
    };
  }
  return null;
};

export const getEndPenComponents = endPenDisplayName => {
  const match = auctionPenRegex.exec(endPenDisplayName);
  if (match) {
    const [, , end_pen, end_pen_suffix] = match;
    return {
      end_pen: +end_pen,
      end_pen_suffix,
    };
  }
  return null;
};

/**
 * @param {AuctionPen|null} auctionPen
 * @returns {StartPenComponents & EndPenComponents}
 */
export const expandAuctionPen = (auctionPen, penType = PenTypes.SELLING) => {
  if (auctionPen) {
    return {
      ...getStartPenComponents(auctionPen.start_pen),
      ...getEndPenComponents(auctionPen.end_pen),
      penType: auctionPen.penType,
      isLocked: auctionPen.isLocked,
    };
  }
  return {
    start_pen_prefix: "",
    start_pen: "",
    start_pen_suffix: "",
    end_pen: "",
    end_pen_suffix: "",
    penType,
  };
};

export const alphaNumeric = key => obj => {
  // Uses the first number component of a string for us
  // primarily in sorting by start pen.
  const v = getNumber(obj[key]);
  return isNaN(v) ? obj[key] : v;
};

export const sortByStartPen = auctionPens => {
  const sorted = stableSort(auctionPens, (a, b) => {
    const result = comparePens(
      a.start_pen || a.startPen,
      b.start_pen || b.startPen,
    );

    return result;
  });
  return sorted;
};

export const compareSaleRoundAndPen = (a, b) => {
  if (isNumber(a.sale_round?.order) && isNumber(b.sale_round?.order)) {
    return (
      a.sale_round.order - b.sale_round.order ||
      auctionPenSellingOrderSort(a.auction_pen, b.auction_pen)
    );
  } else {
    return 0;
  }
};

export const sortByRoundAndPen = saleLots => {
  return stableSort(saleLots, compareSaleRoundAndPen);
};

export const getAuctionPenOrder = (auctionPens, newPen) => {
  const startPen = newPen.start_pen || newPen.startPen;

  // If the new pen exists in the auctionPens, return the same order.
  const existingEntry = auctionPens.find(
    ap => (ap.start_pen || ap.startPen) === startPen,
  );
  if (existingEntry) {
    return existingEntry.order;
  }

  // Natural sort all the auctionpens including the one to insert.
  const natSorted = sortByStartPen([...auctionPens, { startPen }]);

  const insertAfterPenIndex =
    natSorted.map(p => p.startPen || p.start_pen).indexOf(startPen) - 1;

  // If the new pen should go at the start.
  if (insertAfterPenIndex < 1) {
    return 0;
  }

  // If the new pen should go at the end.
  if (insertAfterPenIndex + 1 === natSorted.length - 1) {
    return (
      maxBy(
        natSorted.filter(p => p.order),
        "order",
      )?.order + 1 || 0
    );
  }

  // Give the new pen the same order as the pen that would go before it,
  // and allow the sort algorithm (by order then pen number) to sort it correctly.
  return natSorted[insertAfterPenIndex].order;
};

export function getOverridePenStatus(auctionPen, auctionPenId, penStatus) {
  if (auctionPenId === null) {
    return saleLotStatuses.NOT_PENNED;
  } else if (auctionPen.penType === PenTypes.TEMP) {
    return auctionPenStatuses.TEMP_PEN;
  } else {
    return penStatus;
  }
}

export function penNameToPenComponents(penName = "") {
  const [startPen = "", endPen = ""] =
    typeof penName === "string" ? penName?.split("-") : [];
  const endPenComponents = getEndPenComponents(endPen);
  const startPenComponents = getStartPenComponents(startPen);
  return {
    penName,
    endPen,
    endPenNumber: endPenComponents ? endPenComponents.end_pen : null,
    endPenSuffix: endPenComponents ? endPenComponents.end_pen_suffix : "",
    startPen,
    startPenNumber: startPenComponents ? startPenComponents.start_pen : null,
    startPenPrefix: startPenComponents
      ? startPenComponents.start_pen_prefix
      : null,
    startPenSuffix: startPenComponents
      ? startPenComponents.start_pen_suffix
      : null,
  };
}

export const splicePensWithArchetypes = (pens, penArchetypes) => {
  /** merge pens and archetypes together into one list.
   *
   * do this by sorting archetypes by order (if the archetype has a pen, use the pen).
   *
   * all pens not associated to an archetype should be natually sorted at the end
   */

  const archetypes = [];

  const orderedArchetypes = sortBy(penArchetypes, "order");

  // if archetype has a corresponding pen, add it, else add the archetype
  orderedArchetypes.forEach(archetype => {
    if (pens.filter(pen => pen.penArchetypeId === archetype.id).length) {
      archetypes.push(
        pens.filter(pen => pen.penArchetypeId === archetype.id)[0],
      );
    } else {
      archetypes.push(archetype);
    }
  });

  // then order pens that do not have an archetype
  const orderedPens = pens
    .filter(pen => !pen.penArchetypeId)
    .sort((penA, penB) =>
      comparePens(
        // condiering that some auction pens are not serialised
        penA.startPen || penA.start_pen,
        penB.startPen || penB.start_pen,
      ),
    );

  // place archetypes in front and ordered free from pens afterward
  const merged = [...archetypes, ...orderedPens];

  return merged;
};

export function resolvePenGroupStatus(statuses) {
  // For a list of status's of pens show a summarized status.
  const uniqStatuses = uniq(statuses);
  if (uniqStatuses.length === 0) {
    return ScanningPenStatus.NONE_SCANNED;
  } else if (uniqStatuses.length === 1) {
    return uniqStatuses[0];
  } else if (
    uniqStatuses.length &&
    ((uniqStatuses.includes(ScanningPenStatus.ALL_SCANNED) &&
      uniqStatuses.includes(ScanningPenStatus.NONE_SCANNED)) ||
      uniqStatuses.includes(ScanningPenStatus.ALL_SCANNED_WITH_EMPTY)) &&
    !uniqStatuses.includes(ScanningPenStatus.PARTIALLY_SCANNED)
  ) {
    return ScanningPenStatus.ALL_SCANNED_WITH_EMPTY;
  } else {
    return ScanningPenStatus.PARTIALLY_SCANNED;
  }
}

export const buildScanningPenGroups = (
  penArchetypes,
  penIdsByPenArchetypeIdLookup,
  statusByPenIdLookup,
  freeFormPens,
  scanLotIdsByReceivalPenIdLookup,
  scanLotByIdLookup,
  groupedBy,
) => {
  const grouped = penArchetypes.reduce((acc, archetype) => {
    const { start_pen_prefix: startPenPrefix, start_pen: startPen } =
      getStartPenComponents(archetype.penName);

    const initialValues = {
      status: ScanningPenStatus.NONE_SCANNED,
      statuses: [],
      prefixes: new Set(),
      firstPenNumber: startPen,
      lastPenNumber: startPen,
      emptyPenCount: 0,
      totalPenCount: 0,
      pens: [],
      isFreeForm: false,
      quantity: 0,
    };

    // Find if the current prefix exists already, and get the index
    const existingPrefixLocation = acc.findIndex(val =>
      Array.from(val.prefixes).some(
        prefix => prefix?.toLowerCase() === startPenPrefix?.toLowerCase(),
      ),
    );

    const isPrefixGrouped = groupedBy === PenScanningGroupingType.PREFIX;

    const groupedByCheck = isPrefixGrouped
      ? existingPrefixLocation === -1 // if prefix grouping, check if the group doesn't exist
      : archetype.isLaneStart; // if lane grouping, check if it is the start of a lane

    // If it is the first one and meets the groupedByCheck add the first one
    if (acc.length === 0 || groupedByCheck) {
      acc.push(initialValues);
    }

    // Get the Default Group
    let group = acc[acc.length - 1];

    // But if we are grouping by prefix and have an index, get the existing group
    if (isPrefixGrouped && existingPrefixLocation !== -1) {
      group = acc[existingPrefixLocation];
    }

    // And add this pen to the collection .
    const penId = penIdsByPenArchetypeIdLookup[archetype.id]?.[0] || null;

    // Find the pen status and influence the parent status from that.
    const penStatus =
      statusByPenIdLookup[penId] || ScanningPenStatus.NONE_SCANNED;
    // Add this pen's status, then find out the groups aggregated status, including that.
    if (!group.statuses.includes(penStatus)) {
      group.statuses.push(penStatus);
    }
    group.status = resolvePenGroupStatus(group.statuses);
    if (penStatus === ScanningPenStatus.NONE_SCANNED) {
      group.emptyPenCount += 1;
    }
    group.totalPenCount += 1;

    // Always override the last pen number with this.
    group.lastPenNumber = startPen;
    group.prefixes.add(startPenPrefix);
    group.pens.push({
      penArchetypeId: archetype.id,
      penId,
    });

    group.quantity +=
      scanLotIdsByReceivalPenIdLookup[penId]?.reduce(
        (acc, scanLotId) => (acc += scanLotByIdLookup[scanLotId].quantity),
        0,
      ) || 0;

    return acc;
  }, []);

  if (freeFormPens.length) {
    grouped.push({
      status: ScanningPenStatus.NONE_SCANNED,
      statuses: [],
      prefixes: new Set(),
      firstPenNumber: null,
      lastPenNumber: null,
      emptyPenCount: 0,
      totalPenCount: 0,
      pens: [],
      isFreeForm: true,
      quantity: 0,
    });

    const otherPens = grouped[grouped.length - 1];

    sortPenByOrder(freeFormPens).forEach(auctionPen => {
      const { start_pen_prefix: startPenPrefix, start_pen: startPen } =
        getStartPenComponents(auctionPen.start_pen);

      const penStatus = statusByPenIdLookup[auctionPen.id];

      if (!otherPens.statuses.includes(penStatus)) {
        otherPens.statuses.push(penStatus);
      }

      otherPens.totalPenCount += 1;
      if (penStatus === ScanningPenStatus.NONE_SCANNED) {
        otherPens.emptyPenCount += 1;
      }

      otherPens.prefixes.add(startPenPrefix);
      if (!otherPens.firstPenNumber) {
        otherPens.firstPenNumber = startPen;
      }
      otherPens.lastPenNumber = startPen;

      otherPens.pens.push({
        penArchetypeId: null,
        penId: auctionPen.id,
      });

      otherPens.quantity +=
        scanLotIdsByReceivalPenIdLookup[auctionPen.id]?.reduce(
          (acc, scanLotId) => (acc += scanLotByIdLookup[scanLotId].quantity),
          0,
        ) || 0;
    });

    otherPens.status = resolvePenGroupStatus(otherPens.statuses);
  }
  return grouped;
};

export function selectScanLotStatusByIdReducer(lot, eidsByLotId) {
  if (!eidsByLotId[lot.id]?.length) {
    return ScanLotStatus.NONE_SCANNED;
  } else if (lot.quantity === eidsByLotId[lot.id]?.filter(Boolean)?.length) {
    // All animals are scanned and accounted for
    return ScanLotStatus.ALL_SCANNED;
  } else {
    // Some of the animals are scanned.
    return ScanLotStatus.PARTIALLY_SCANNED;
  }
}

export const generateNewPenPayload = (
  newPenName,
  penType,
  penArchetypeId = null,
) => {
  const newId = uuidv4();
  const newPen = {
    id: newId,
    capacity: -1,
    isLaneStart: false,
    order: null,
    penArchetypeId,
    penType,
    ...penNameToPenComponents(newPenName),
  };

  return {
    ...serializeAuctionPen(newPen),
    start_pen: newPen.startPen,
    end_pen: newPen.endPen,
  };
};

export const sortPenScanLotsByGroupingName = (
  penScanGroupingType,
  penScanGroups,
) => {
  if (penScanGroupingType === PenScanningGroupingType.PREFIX) {
    return orderBy(
      penScanGroups,
      penScanGroup =>
        penScanGroup.isFreeForm
          ? undefined
          : Array.from(penScanGroup.prefixes).filter(Boolean)[0]?.toLowerCase(),
      "asc",
    );
  } else {
    return penScanGroups;
  }
};

/* Build a string for the complete pen name making sure 
start pen, end pen and overflow is broken up by a 
line break. */
export const buildLineBreakingAuctionPenDisplayName = (
  auctionPen,
  overflowPen,
  overflowQuantity,
  defaultValue = "N/A",
) => {
  const {
    start_pen: startPen,
    startPen: startPenAlt,
    end_pen: endPen,
    endPen: endPenAlt,
  } = auctionPen || {};

  let penDisplayName = "";

  if (startPen || startPenAlt) {
    penDisplayName = startPen || startPenAlt;
  }
  if (endPen || endPenAlt) {
    penDisplayName += "-\n";
    penDisplayName += endPen || endPenAlt;
  }
  if (overflowPen) {
    penDisplayName += "\n";
    penDisplayName += formatSaleLotOverflow(overflowPen, overflowQuantity);
  }

  return penDisplayName || defaultValue;
};

// Given an auction pen return pen numbers which are used/taken based on the start and end pen range.
export const getTakenPens = ({ start_pen, end_pen }) => {
  const start_pen_number = getStartPenComponents(start_pen)?.start_pen;
  const end_pen_number = getEndPenComponents(end_pen)?.end_pen;

  if (!start_pen_number) {
    return EMPTY_ARRAY;
  } else if (!end_pen_number) {
    return [start_pen_number];
  } else {
    const difference = Math.abs(start_pen_number - end_pen_number);
    // Make sure the range is not excessive
    const range_end =
      difference > MAX_PEN_RANGE
        ? start_pen_number + MAX_PEN_RANGE
        : end_pen_number;
    return range(start_pen_number, range_end).concat(end_pen_number);
  }
};
