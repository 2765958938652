import React, { memo } from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import { ActionButton } from "components/AgGrid/ActionButton";
import AgGrid from "components/AgGrid/AgGrid";

import { ACTION_COLUMN_NAME } from "constants/aggrid";

import { formatters, getters } from "lib/agGrid";
import {
  dateRenderer,
  dateFormatter,
  dateGetter,
} from "lib/agGrid/columns/date";
import { openEditPaymentModal } from "lib/navigation";

import { getPayments } from "selectors";

function ActionsColumnRenderer({ data }) {
  return (
    <ActionButton
      data-tour="actionEditBusinessPayment"
      type="button"
      onClick={() => openEditPaymentModal(data.id)}
    >
      <FontAwesomeIcon icon={faPencil} />
      &nbsp;Edit
    </ActionButton>
  );
}

const getRowId = params => params.data.id;

const defaultColDef = {
  sortable: true,
  resizable: true,
  enableCellChangeFlash: true,
};

const columnsDefs = [
  {
    headerName: "Payment Date",
    field: "paymentDate",
    valueGetter: dateGetter,
    valueFormatter: dateFormatter,
    cellRenderer: dateRenderer,
    // filter: "agDateColumnFilter", TODO Fix aggrid date filtering something to do with GMT and AEDT/AEST and ===
    type: "dateColumn",
    width: 110,
  },
  {
    headerName: "$ Amount",
    field: "totalAmountCents",
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
    filter: "agNumberColumnFilter",
    type: "numericColumn",
    width: 95,
  },
  {
    headerName: "Method",
    field: "paymentMethod",
    width: 95,
  },
  {
    headerName: "Ref",
    field: "paymentRef",
    width: 110,
  },
  {
    headerName: "Notes",
    field: "notes",
    flex: true,
  },
  {
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: ActionsColumnRenderer,
    pinned: "right",
    width: 80,
  },
];

const selectBusinessPaymentsList = createSelector(
  [getPayments, (_, businessId) => businessId],
  (payments, businessId) =>
    Object.values(payments).filter(
      payment => payment.fromBusinessId === businessId,
    ),
);

function BusinessPaymentsTableComponent(props) {
  const { businessId } = props;

  const rowData = useSelector(state =>
    selectBusinessPaymentsList(state, businessId),
  );

  return (
    <AgGrid
      rowData={rowData}
      suppressColumnVirtualisation
      suppressScrollOnNewData
      columnDefs={columnsDefs}
      rowBuffer={50}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
    />
  );
}

export const BusinessPaymentsTable = memo(BusinessPaymentsTableComponent);
BusinessPaymentsTable.propTypes = {
  businessId: PropTypes.string.isRequired,
};
