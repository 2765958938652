import React from "react";

import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import intersection from "lodash/intersection";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";

import { RuleAction } from "actions";

import Button from "components/Button";
import { withArrayNamespace } from "components/Form/FormikControls";

import { ValueSource } from "constants/ruleBooks";

import { MasterRuleLibraryCard } from "containers/Settings/RuleBooks/Library/MasterRuleLibraryCard";

import {
  getMasterRulesByMasterRuleBookId,
  getRulesByRuleBookId,
  selectRuleIdsByMasterRuleIdLookup,
  selectRuleIdsByRuleBookIdLookup,
} from "selectors";

export function CustomiseManagedRuleBookLibraryForm(props) {
  const {
    masterRuleBookId,
    onAfterSave,
    ruleBookId,
    masterRuleId,
    showSoftDeleted,
  } = props;

  const masterRules =
    useSelector(getMasterRulesByMasterRuleBookId(masterRuleBookId)) || [];
  const ruleIdsByMasterRuleIdLookup =
    useSelector(selectRuleIdsByMasterRuleIdLookup) || {};
  const ruleIdsByRuleBookIdLookup =
    useSelector(selectRuleIdsByRuleBookIdLookup) || {};

  const ruleBookRules = useSelector(getRulesByRuleBookId(ruleBookId)) || [];

  const dispatch = useDispatch();

  const ruleBookRuleIdsByMasterRuleId = masterRules.reduce(
    (acc, masterRule) => {
      acc[masterRule.id] = intersection(
        ruleIdsByMasterRuleIdLookup[masterRule.id],
        ruleIdsByRuleBookIdLookup[ruleBookId],
      );
      return acc;
    },
    {},
  );

  function onSubmit(values) {
    values.forEach(rule => {
      const { editValues } = rule;
      delete rule.editValues;
      if (!editValues.id) {
        // Create missing Managed Rules
        dispatch(RuleAction.create({ ...rule, ...editValues, id: v4() }));
      } else {
        // Update existing Managed Rules
        dispatch(RuleAction.update(editValues));
      }
    });
    typeof onAfterSave === "function" && onAfterSave();
  }

  const initialValues = masterRules.reduce((acc, masterRule) => {
    const existingManagedRule = ruleBookRules.find(
      rule => rule.master_rule_id === masterRule.id,
    );

    if (existingManagedRule) {
      acc.push({
        ...masterRule,
        editValues: {
          id: existingManagedRule.id,
          comment: existingManagedRule.comment || "",
          gl_code: existingManagedRule.gl_code || {
            source: ValueSource.CONSTANT,
            value: "",
          },
          invoice_line_item_template:
            existingManagedRule.invoice_line_item_template || "",
          name: existingManagedRule.name || "",
          quantity_output_format: existingManagedRule.quantity_output_format,
          title_template: existingManagedRule.title_template || "",
          unit_amount_output_format:
            existingManagedRule.unit_amount_output_format,
          quantity_raw_format: existingManagedRule.quantity_raw_format || "",
          unit_amount_raw_format:
            existingManagedRule.unit_amount_raw_format || "",
        },
      });
      return acc;
    }
    if (showSoftDeleted || !masterRule.is_deleted) {
      // Only show not-deleted rules, unless explicitly showing them!
      acc.push({
        ...masterRule,
        editValues: {
          rule_book_id: ruleBookId,
          master_rule_id: masterRule.id,
        },
      });
    }
    return acc;
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {formikProps => {
        const { values } = formikProps;
        return (
          <Form>
            {values.map(masterRule => {
              return (
                <MasterRuleLibraryCard
                  focusOnMount={masterRuleId === masterRule.id}
                  key={masterRule.id}
                  masterRuleId={masterRule.id}
                  ruleBookId={ruleBookId}
                  subscribedRuleId={
                    ruleBookRuleIdsByMasterRuleId[masterRule.id]?.[0] || null
                  }
                  namespace={`${withArrayNamespace(
                    "",
                    values.findIndex(value => value.id === masterRule.id),
                  )}.editValues`}
                />
              );
            })}
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button type="submit">Save</Button>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
