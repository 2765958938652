import React from "react";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { SubtleBadge } from "components/Badge";
import { Warning } from "components/ErrorMessage";
import { CollapseLabel, FormCollapse } from "components/Form";
import { DeploymentSelectField } from "components/Form/Fields";
import {
  AutoSizeTextArea,
  Input,
  SelectField,
  Switch,
  withNamespace,
} from "components/Form/FormikControls";

import { sectionHasErrors } from "lib/form";
import { pluralize } from "lib/pluralize";

import { getBusinessById, getBusinesses } from "selectors";

import { useTheme } from "hooks";

import { AlternateFieldTextComponent, getAlternateColor } from "./lib";

export const GeneralCollapse = props => {
  const businesses = useSelector(getBusinesses);
  const { isOpen, onToggle, branches, namespace: ns, businessId } = props;
  const business = useSelector(getBusinessById(businessId));
  const formikProps = useFormikContext();
  const { values } = formikProps;
  const { breakpoints } = useTheme();
  const {
    shortCode: agentCode,
    branchName,
    isTopBuyerCattle,
    isTopBuyerSheep,
    isVendor,
    shortCode,
    shortCodeSaleyard,
  } = values;

  const duplicateShortCodes = Object.values(businesses).filter(
    b => b.id !== businessId && b.shortCode === shortCode,
  );

  const duplicateSyRefs = Object.values(businesses).filter(
    b => b.id !== businessId && b.shortCodeSaleyard === shortCodeSaleyard,
  );

  const isMobile = useMediaQuery(`(max-width: ${breakpoints[1]}px)`);
  function topBuyerCheck() {
    if (isTopBuyerSheep || isTopBuyerCattle) {
      return (
        <>
          {isMobile ? "TB" : "Top Buyer"}
          <FaIcon icon={faCheck} color="success" />
        </>
      );
    } else {
      return "";
    }
  }
  const hasErrorBadge = sectionHasErrors(formikProps, [
    "name",
    "shortCode",
    "shortCodeSaleyard",
    "branchId",
  ]);

  const header = (
    <Grid container>
      <SubtleBadge hasErrorBadge={hasErrorBadge}>General</SubtleBadge>
      <CollapseLabel> {topBuyerCheck()} </CollapseLabel>
      <CollapseLabel>{agentCode}</CollapseLabel>
      <CollapseLabel>{branchName}</CollapseLabel>
    </Grid>
  );

  const branchOptions = branches.map(b => ({
    value: b.id,
    label: b.name,
  }));

  const alternatives = business?.alternatives || [];
  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      hasErrorBadge={hasErrorBadge}
      header={header}
      dataTour="generalCollapse"
    >
      <Grid item xs={12} sm={12}>
        <Input
          label="Name"
          name={withNamespace(ns, "name")}
          required
          altTooltip={AlternateFieldTextComponent(alternatives, "name")}
          altColor={getAlternateColor(alternatives, values, "name")}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Input
          label="Public Display Name"
          name={withNamespace(ns, "publicDisplayName")}
          altTooltip={AlternateFieldTextComponent(
            alternatives,
            "publicDisplayName",
          )}
          altColor={getAlternateColor(
            alternatives,
            values,
            "publicDisplayName",
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Agent Reference"
          name={withNamespace(ns, "shortCode")}
          altTooltip={AlternateFieldTextComponent(alternatives, "shortCode")}
          altColor={getAlternateColor(alternatives, values, "shortCode")}
        />
        {shortCode && duplicateShortCodes.length > 0 && (
          <Warning>
            {duplicateShortCodes.length} other{" "}
            {pluralize("business", duplicateShortCodes.length)} with the same
            reference found
          </Warning>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Saleyard Reference"
          name={withNamespace(ns, "shortCodeSaleyard")}
          altTooltip={AlternateFieldTextComponent(
            alternatives,
            "shortCodeSaleyard",
          )}
          altColor={getAlternateColor(
            alternatives,
            values,
            "shortCodeSaleyard",
          )}
        />
        {shortCodeSaleyard && duplicateSyRefs.length > 0 && (
          <Warning>
            {duplicateSyRefs.length} other{" "}
            {pluralize("business", duplicateSyRefs.length)} with the same
            reference found
          </Warning>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="List2Sell Agent Ref"
          name={withNamespace(ns, "slug")}
          tooltip="Agent reference code in list2sell listing"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="AuctionsPlus Short Code"
          name={withNamespace(ns, "shortCodeAuctionsPlus")}
          tooltip=" The business' unique Auctions+ User ID"
          altTooltip={AlternateFieldTextComponent(
            alternatives,
            "shortCodeAuctionsPlus",
          )}
          altColor={getAlternateColor(
            alternatives,
            values,
            "shortCodeAuctionsPlus",
          )}
        />
      </Grid>
      {branches.length > 0 && (
        <Grid item xs={isVendor ? 6 : 12}>
          <SelectField
            label="Branch"
            name={withNamespace(ns, "branchId")}
            options={branchOptions}
          />
        </Grid>
      )}
      {isVendor && (
        <Grid item xs={6}>
          <DeploymentSelectField
            label="Default Consigning Branch"
            name={withNamespace(ns, "defaultConsigningDeploymentId")}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <AutoSizeTextArea
          label="Notes"
          name={withNamespace(ns, "notes")}
          multiline
          tooltip="Add any additional notes here"
          rows={7}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Brands"
          name={withNamespace(ns, "brands")}
          tooltip="This brand will be automatically populated into the NVD Mapping Screen.  Any changes are shared across the system."
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={4} md>
          <Switch
            label="Active"
            name={withNamespace(ns, "isActive")}
            altTooltip={AlternateFieldTextComponent(alternatives, "isActive")}
            altColor={getAlternateColor(alternatives, values, "isActive")}
          />
        </Grid>
        <Grid item xs={4} md>
          <Switch label="Vendor" name={withNamespace(ns, "isVendor")} />
        </Grid>
        <Grid item xs={4} md>
          <Switch
            label="Transporter"
            name={withNamespace(ns, "isTransporter")}
          />
        </Grid>
        <Grid item xs={4} md>
          <Switch label="Buyer" name={withNamespace(ns, "isBuyer")} />
        </Grid>
        <Grid item xs={4} md>
          <Switch label="Published" name={withNamespace(ns, "isPublished")} />
        </Grid>
        <Grid item xs={4} md>
          <Switch
            label="Meat Processor"
            name={withNamespace(ns, "isMeatProcessorCommercialBuyer")}
          />
        </Grid>
        <Grid item xs={6} md>
          <Switch
            label="Top Buyer Sheep"
            name={withNamespace(ns, "isTopBuyerSheep")}
            altTooltip={AlternateFieldTextComponent(
              alternatives,
              "isTopBuyerSheep",
            )}
            altColor={getAlternateColor(
              alternatives,
              values,
              "isTopBuyerSheep",
            )}
          />
        </Grid>
        <Grid item xs={6} md>
          <Switch
            label="Top Buyer Cattle"
            name={withNamespace(ns, "isTopBuyerCattle")}
            altTooltip={AlternateFieldTextComponent(
              alternatives,
              "isTopBuyerCattle",
            )}
            altColor={getAlternateColor(
              alternatives,
              values,
              "isTopBuyerCattle",
            )}
          />
        </Grid>
      </Grid>
    </FormCollapse>
  );
};
