export const VendorCommissionBandTypes = {
  Percentage: "Percentage",
  DollarsPerHead: "$/Head",
};

export const VendorCommissionBandOptions = [
  {
    value: VendorCommissionBandTypes.Percentage,
    label: VendorCommissionBandTypes.Percentage,
  },
  {
    value: VendorCommissionBandTypes.DollarsPerHead,
    label: VendorCommissionBandTypes.DollarsPerHead,
  },
];
