import React, { useState } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Button, SecondaryButton } from "components/Form";
import { HasDeploymentMarks, OtherMarkingField } from "components/Form/Fields";
import MarkingField from "components/Form/Fields/MarkingField";
import { HelpText } from "components/Form/FormikControls/HelpText";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { SplitDiff } from "components/Split";

import { SplitType } from "constants/saleLots";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

import { getDeploymentIdBySaleLotId } from "selectors";

export function SplitLotModal({
  returnTo,
  existingHead,
  splitSaleLot,
  fullScreen,
  splitType,
  shouldMoveWeights,
  doesLotHaveWeight,
  saleLot,
}) {
  const [isError, setIsError] = useState(false);

  function onClose() {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }
  const deploymentId = useSelector(getDeploymentIdBySaleLotId(saleLot.id));

  const initialValues = {
    splitCount: 1,
    marks: saleLot.marks,
  };

  const onSubmitForm = formValues => {
    // we're closing the modals when there's no returnTo because on the auction screen
    // the split modal gets a bit glitchy when rerendering during the requests.
    // Waiting on the patch sale lot, post new sale lot and post comment before closing wasn't reacting well.
    // So closing the modal before the network requests for a smoother user experience there

    // TODO: wrap the modal in a loader when requests are firing

    if (!returnTo) {
      closeAllHashModals();
    }

    const { splitCount, marks } = formValues;

    splitSaleLot(splitCount, { marks });
    if (returnTo) {
      returnToLocation(returnTo);
    }
  };

  const title =
    splitType && splitType !== SplitType.STANDARD
      ? `Split Sale Lot ${splitType}`
      : `Split Sale Lot`;

  let weightHelpText = "";
  if (doesLotHaveWeight) {
    weightHelpText = shouldMoveWeights
      ? "The proportional weight of the animals will be moved to the new lot."
      : "No weights will be moved to the new lot.";
  }

  return (
    <Dialog fullScreen={fullScreen} open onClose={onClose}>
      <Formik initialValues={initialValues} onSubmit={onSubmitForm}>
        <Form>
          <DialogTitle onClose={onClose}>{title}</DialogTitle>
          <DialogContent dividers>
            <>
              <SplitDiff
                onError={setIsError}
                existingCount={existingHead}
                name="splitCount"
              />

              {deploymentId && (
                <>
                  <HelpText>
                    Selected marks will be applied to the split lot
                  </HelpText>

                  <HasDeploymentMarks deploymentId={deploymentId}>
                    <Grid item xs={12}>
                      <MarkingField deploymentId={deploymentId} name="marks" />
                    </Grid>
                  </HasDeploymentMarks>

                  <Grid item xs={12}>
                    <OtherMarkingField
                      deploymentId={deploymentId}
                      name="marks"
                    />
                  </Grid>
                </>
              )}
            </>

            <HelpText>
              No scans will be moved in this split. <br />
              {weightHelpText}
            </HelpText>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={onClose}>Close</SecondaryButton>
            <Button data-tour="split" disabled={isError} type="submit">
              Split
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}
SplitLotModal.propTypes = {
  returnTo: PropTypes.string,
  existingHead: PropTypes.number.isRequired,
  fullScreen: PropTypes.bool,
  splitSaleLot: PropTypes.func.isRequired,
  splitType: PropTypes.string,
  shouldMoveWeights: PropTypes.bool,
  doesLotHaveWeight: PropTypes.bool,
  saleLot: PropTypes.object.isRequired,
};

export default SplitLotModal;
