import queryString from "query-string";
import { call, put, takeEvery } from "redux-saga/effects";

import { receiveAuditLog, requestAuditLogError } from "actions/auditLog";

import { GET_AUDIT_LOG } from "constants/actionTypes";

import { api } from "./api";

function* requestAuditLog(action) {
  try {
    const { dataType, dataId, queryParams } = action;

    const url = queryString.stringifyUrl({
      url: `/v2/auditlog/${dataType}/${dataId}/`,
      query: queryParams,
    });

    const auditLogPromise = yield call(api.get, url);

    yield put(receiveAuditLog(yield auditLogPromise, dataType, dataId));
  } catch (e) {
    yield call(api.handleFetchError, e, "audit log", action);
    yield put(requestAuditLogError(e.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_AUDIT_LOG.REQUEST, requestAuditLog);
}
