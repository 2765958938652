import React from "react";

import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import { colors } from "constants/theme";

import { validatePIC } from "lib/PICValidator";

import { VerticalQuestion, BodyArea, ErrorArea, SubGrid } from "./Grid";

export const AddButton = styled.div`
  margin-left: 6px;
  font-size: 12px;
  color: ${colors.steelBlue};
  font-weight: bold;
`;

const ClickContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: auto;
  align-self: center;
  height: 24px;
  width: 15px;
  color: ${({ theme }) => theme.colors.gray40};
  font-size: 18px;
  cursor: pointer;
`;

const StyledInput = styled.input`
  min-width: 0px;
  width: 280px;
  height: 36px;
  padding: ${({ theme }) => `${theme.space[1]}px ${theme.space[2]}px;`}
  color: ${({ warning, theme }) => (warning ? "inherit" : theme.colors.gray40)};
  border: ${({ theme }) => `1px solid ${theme.colors.gray78}`};
`;

const StyledBodyArea = styled(BodyArea)`
  display: flex;
  flex: 1;
`;

const PIC = ({ value, warning, handleDelete, handleChange }) => (
  <VerticalQuestion>
    <StyledBodyArea>
      <StyledInput
        onChange={handleChange}
        value={value}
        spellCheck="false"
        maxLength={8}
      />
      <StyledIcon icon={faTimes} onClick={handleDelete} />
    </StyledBodyArea>
    {warning && <ErrorArea color={colors.warning}>Invalid PIC</ErrorArea>}
  </VerticalQuestion>
);

PIC.propTypes = {
  warning: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export const AddPIC = ({ formikProps, label }) => {
  const value = formikProps.values[label] || [];
  const touched = formikProps.touched[label] || false;
  const warning =
    touched && formikProps.status && formikProps.status.addPicFieldWarning;

  const handleChange = newValues => {
    const { setStatus, setFieldValue, setFieldTouched } = formikProps;
    const temp = newValues.map(x => x.toUpperCase());
    setFieldValue(label, temp);
    setFieldTouched(label, true, false);
    const isAllPICFieldsValid = newValues.every(value => validatePIC(value));
    setStatus({ addPicFieldWarning: !isAllPICFieldsValid });
  };

  const addPic = () => {
    handleChange([...value, ""]);
  };

  const deletePic = index => {
    const pics = value.filter((pic, pic_index) => index !== pic_index);
    handleChange(pics);
  };

  const updatePic = (picIndex, newValue) => {
    const pics = value.map((value, index) => {
      if (index === picIndex) {
        return newValue;
      } else {
        return value;
      }
    });
    handleChange(pics);
  };

  return (
    <SubGrid>
      {value.map((pic, index) => (
        <PIC
          key={index}
          warning={warning && !validatePIC(pic)}
          value={pic}
          handleDelete={() => deletePic(index)}
          handleChange={event => updatePic(index, event.target.value)}
        />
      ))}
      <ClickContainer onClick={addPic}>
        <FontAwesomeIcon icon={faPlus} style={{ color: "#427db3" }} />
        <AddButton>Add PIC</AddButton>
      </ClickContainer>
    </SubGrid>
  );
};

AddPIC.propTypes = {
  label: PropTypes.string.isRequired,
  formikProps: PropTypes.object.isRequired,
};
