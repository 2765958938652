import React from "react";

import { useFormikContext } from "formik";

import StateAwareBusinessPICSelector from "components/BusinessPICSelector";

import { useGetSaleyardName } from "hooks/useGetSaleyardName";

export const FormikAwareBusinessPICSelector = props => {
  const formikProps = useFormikContext();
  const { saleyardId } = formikProps.values;
  const { businessFieldName, propertyFieldName } = props;
  const error =
    (formikProps.touched[businessFieldName] &&
      formikProps.errors[businessFieldName]) ||
    (formikProps.touched[propertyFieldName] &&
      formikProps.errors[propertyFieldName]);
  const saleyardName = useGetSaleyardName(saleyardId);

  return (
    <StateAwareBusinessPICSelector
      {...props}
      error={error}
      formikProps={formikProps}
      saleyardName={saleyardName}
    />
  );
};
