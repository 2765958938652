import { useMediaQuery } from "@material-ui/core";

import { useTheme } from "hooks";

export function useResponsiveText({ mobile, tablet, desktop }) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${breakpoints[0]}px)`);
  const isTablet = useMediaQuery(`(max-width: ${breakpoints[1]}px)`);
  const desktopText = [desktop, tablet, mobile].find(
    text => typeof text === "string",
  );
  const tabletText = [tablet, mobile, desktop].find(
    text => typeof text === "string",
  );
  const mobileText = [mobile, tablet, desktop].find(
    text => typeof text === "string",
  );
  if (isMobile) {
    return mobileText;
  } else if (isTablet) {
    return tabletText;
  } else {
    return desktopText;
  }
}

export const ResponsiveText = ({ mobile, tablet, desktop }) => {
  return useResponsiveText({ mobile, tablet, desktop });
};
