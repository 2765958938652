import React from "react";

import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { ExternalLink } from "components/Link";

export function externalLinkFormatter(props) {
  const { value } = props;
  if (value) {
    return (
      <ExternalLink href={value} target="_blank">
        <FaIcon icon={faExternalLink} />
      </ExternalLink>
    );
  }
  return "-";
}
