import React, { useMemo } from "react";

import { Paper, Tab, Tabs } from "@material-ui/core";
import get from "lodash/get";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import AgGrid from "components/AgGrid/AgGrid";
import {
  AutoComplete,
  AutoCompleteV2,
  contextOptionCellEditorParamsGetter,
  contextOptionFormatter,
  listCellEditorParamsGetter,
  listValueFormatter,
} from "components/AgGrid/AutoComplete";
import CheckboxCellRenderer from "components/AgGrid/CheckboxCellRenderer";
import { CompositeFilter } from "components/AgGrid/filters/compositeFilter";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Button } from "components/Form";
import { Column, Row } from "components/Layout";
import { SpeciesSelector } from "components/SpeciesSelector";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import { Settings } from "constants/settings";
import { colors } from "constants/theme";

import { blankRowComparator } from "containers/Settings/ExtendableTable";
import { HeaderBulkSelectCellRenderer } from "containers/Settings/Renderers";

import { getActiveRole, getProductsSelector, getSetting } from "selectors";

import { productActionsColumnRenderer } from "./actions";

const SaveButton = styled(Button)`
  flex: 0;
  margin-left: auto;
  align-self: center;
`;

const components = {
  [CompositeFilter.componentName]: CompositeFilter,
  checkboxRenderer: CheckboxCellRenderer,
  autoCompleteEditorDeprecated: AutoComplete,
  autoCompleteV2: AutoCompleteV2,
};

const defaultColDef = {
  editable: true,
  enableCellChangeFlash: true,
  flex: 1,
  resizable: true,
  sortable: true,
  suppressKeyboardEvent: params => params.editing,
  comparator: blankRowComparator,
};

const getRowStyle = row => {
  const { data } = row;
  if (data.syncError) {
    return {
      background: colors.errorRed,
    };
  }
  if (data.touched) {
    return {
      background: `${colors.primaryActive}50`,
    };
  }
  if (data.syncing) {
    return {
      background: `${colors.primaryActive}50`,
    };
  }
};

const uniqCombo = ["sale_round", "breed_id", "age_id", "sex_id"];

const uniqueFunction = (rows, p) =>
  rows.filter(r => uniqCombo.every(a => get(r.data, a) === get(p.data, a)));

const columnDefs = [
  {
    headerName: "Import Code",
    field: "code",
    rowDrag: true,
  },
  {
    headerName: "Saleyard Import Code",
    field: "saleyard_code",
  },
  {
    headerName: "Quick Code",
    field: "quick_code",
  },

  { headerName: "Description", field: "name" },
  {
    cellEditor: AutoCompleteV2,
    cellEditorParams: listCellEditorParamsGetter(
      contextOptionCellEditorParamsGetter("rounds"),
    ),
    editable: true,
    field: "sale_rounds",
    headerName: "Round(s)",
    valueFormatter: listValueFormatter(contextOptionFormatter("rounds")),
    valueGetter: params => params.data.sale_rounds || [],
  },
  {
    cellEditor: "autoCompleteV2",
    cellEditorParams: contextOptionCellEditorParamsGetter("ages"),
    editable: true,
    field: "age_id",
    headerName: "Age",
    valueFormatter: contextOptionFormatter("ages"),
  },
  {
    cellEditor: "autoCompleteV2",
    cellEditorParams: contextOptionCellEditorParamsGetter("sexes"),
    editable: true,
    field: "sex_id",
    headerName: "Sex",
    valueFormatter: contextOptionFormatter("sexes"),
  },
  {
    cellEditor: "autoCompleteV2",
    cellEditorParams: contextOptionCellEditorParamsGetter("breeds"),
    editable: true,
    field: "breed_id",
    headerName: "Breed",
    valueFormatter: contextOptionFormatter("breeds"),
  },
  {
    headerName: "Quick Select",
    children: [
      {
        headerName: "Auction",
        field: "quick_select",
        cellRenderer: "checkboxRenderer",
        editable: false,
        headerComponentFramework: HeaderBulkSelectCellRenderer,
      },
      {
        headerName: "Hooks",
        field: "quick_select_hooks",
        cellRenderer: "checkboxRenderer",
        editable: false,
        headerComponentFramework: HeaderBulkSelectCellRenderer,
      },
      {
        headerName: "Paddock",
        field: "quick_select_paddock",
        cellRenderer: "checkboxRenderer",
        editable: false,
        headerComponentFramework: HeaderBulkSelectCellRenderer,
      },
    ],
  },
  {
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: productActionsColumnRenderer,
    editable: false,
    valueGetter: () => null,
  },
];

export const ProductsTable = ({
  productAttributes,
  addProduct,
  updateProducts,
  deleteProduct,
}) => {
  const { deployments } = useSelector(getActiveRole);

  const speciesId = useSelector(getSetting(Settings.speciesId));
  const [deploymentId, setDeploymentId] = React.useState(deployments[0].id);

  const rowData = useSelector(state =>
    getProductsSelector(state, speciesId, deploymentId),
  );

  const speciesAttributes = useMemo(
    () => productAttributes[speciesId] || {},
    [productAttributes, speciesId],
  );
  const { ages, breeds, rounds, sexes } = speciesAttributes;

  const [deleteProductId, setDeleteProductId] = React.useState(null);

  const agGridInstance = React.useRef();

  const addBlankRowIfNeeded = () => {
    const api = agGridInstance.current?.api;
    if (!api) {
      return;
    }
    // We always want to have one blank row available at the bottom of the table.
    const lastIndex = api.getLastDisplayedRow();
    const last = api.getDisplayedRowAtIndex(lastIndex);

    if (last?.data.touched || last?.data.id || lastIndex === -1) {
      api.applyTransaction({ add: [{ code: "" }] });
    }
  };

  const onGridReady = params => {
    agGridInstance.current = params;
    addBlankRowIfNeeded(params);
  };

  const handleChangeDeployment = (_event, deploymentId) =>
    setDeploymentId(deploymentId);

  const makePayload = ({
    sale_rounds,
    age_id,
    sex_id,
    breed_id,
    name,
    code,
    saleyard_code,
    quick_code,
    id,
    order,
    quick_select,
    quick_select_hooks,
    quick_select_paddock,
  }) => {
    const payload = {
      id: id || undefined,
      deployment_id: deploymentId,
      species_id: speciesId,
      name,
      code,
      saleyard_code,
      sale_rounds: sale_rounds || undefined,
      age_id,
      sex_id,
      breed_id,
      order,
      quick_code,
      quick_select,
      quick_select_hooks,
      quick_select_paddock,
    };
    return payload;
  };

  const onClickSave = () => {
    const updates = [];

    const rows = [];
    agGridInstance.current.api.forEachNode(rowNode => {
      rows.push(rowNode);
    });
    // Pop the blank
    rows.pop();

    let errors = false;

    rows.forEach((rowNode, index) => {
      const { data } = rowNode;

      const duplicates = uniqueFunction(rows, rowNode);
      if (duplicates.length !== 1) {
        errors = true;
        duplicates.map(node =>
          node.setData({
            ...node.data,
            syncError: true,
          }),
        );

        return;
      }

      const reordered = data.order !== index;

      const product = {
        ...data,
        order: index,
        touched: data.touched || reordered,
      };

      // If we have added a new product we wont have an id yet.
      // notCreated gets set on ADD request and cleared on ADD success we can use
      // this to determine if a previous add was unsuccessful and we need to send
      // the add request again, instead of an update.
      if (
        product.notCreated ||
        (!product.id && product.touched && product.code)
      ) {
        const payload = makePayload(product);
        addProduct(payload);
      } else if (product.id && product.touched) {
        const payload = makePayload(product);
        updates.push(payload);
      }
    });

    // Possibly could sort the columns if there is a duplication error so its easier to spot if a lot of data has been entered.
    !errors && updates.length > 0 && updateProducts(updates);
  };

  const handleOpenDeleteDialog = productId => setDeleteProductId(productId);

  const handleCloseDeleteDialog = () => setDeleteProductId(null);

  const onDelete = () => {
    deleteProduct(deleteProductId);
    handleCloseDeleteDialog();
  };

  const onRowValueChanged = e => {
    // Add a touched parameter to the rowNode after the user has finished editing the row.
    e.node.setData({
      ...e.node.data,
      touched: true,
    });
    // Automatically add a new blank row.
    addBlankRowIfNeeded(e);
  };
  return (
    <Row flexGrow>
      {deployments.length > 1 && (
        <Column margin={2}>
          <Paper square elevation={2}>
            <Tabs
              orientation="vertical"
              value={deploymentId}
              onChange={handleChangeDeployment}
            >
              {deployments.map(deployment => (
                <Tab
                  label={deployment.channel}
                  value={deployment.id}
                  key={deployment.id}
                />
              ))}
            </Tabs>
          </Paper>
        </Column>
      )}
      <Column full>
        <Row>
          <SpeciesSelector />
          <SaveButton data-tour="save" onClick={onClickSave}>
            Save
          </SaveButton>
        </Row>
        <CollapseTableWrapper>
          <AgGrid
            columnDefs={columnDefs}
            components={components}
            context={{ handleOpenDeleteDialog, ages, sexes, breeds, rounds }}
            defaultColDef={defaultColDef}
            editType="fullRow"
            getRowStyle={getRowStyle}
            key={`${deploymentId}_${speciesId}`}
            onGridReady={onGridReady}
            onRowValueChanged={onRowValueChanged}
            onRowDataUpdated={addBlankRowIfNeeded}
            cellEditingStopped={addBlankRowIfNeeded}
            cellEditingStarted={addBlankRowIfNeeded}
            rowEditingStarted={addBlankRowIfNeeded}
            rowData={rowData}
            rowDragManaged
            stopEditingWhenCellsLoseFocus
            undoRedoCellEditing
            undoRedoCellEditingLimit={200}
          />
        </CollapseTableWrapper>
      </Column>

      <ConfirmDialog
        title={createModalTitle("this product")}
        isOpen={!!deleteProductId}
        onCancel={handleCloseDeleteDialog}
        onDelete={onDelete}
      />
    </Row>
  );
};

export default ProductsTable;
