import React from "react";

import { Table } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { isEqual } from "lodash";
import sortBy from "lodash/sortBy";
import styled from "styled-components/macro";

import {
  FieldName,
  FieldNameTableCell,
  FieldValue,
} from "components/AuditLog/Fields";

import { AuditLogFieldNames } from "constants/auditLog";

const ChangesSummaryWrapper = styled.div(
  ({ theme }) => `
  padding-left: ${theme.space[3]}px; 
`,
);
export const ChangesSummary = ({ data, dataType, previousData }) => {
  const changes = sortBy(
    Object.entries(data).reduce((acc, [field, value]) => {
      if (!previousData || !isEqual(value, previousData[field])) {
        acc.push({
          field,
          value,
        });
      }
      return acc;
    }, []),
    change => AuditLogFieldNames[dataType]?.[change.field] || change.field,
  );

  return (
    <ChangesSummaryWrapper>
      <Table size="small">
        <TableBody>
          {changes.length > 0 ? (
            changes.map(change => (
              <TableRow key={change.field}>
                <FieldNameTableCell>
                  <FieldName fieldName={change.field} dataType={dataType} />
                </FieldNameTableCell>
                <TableCell>
                  <FieldValue
                    fieldName={change.field}
                    dataType={dataType}
                    fieldValue={change.value}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>No changes to display.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </ChangesSummaryWrapper>
  );
};
