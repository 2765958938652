import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { InputDateRange } from "components/DateRangePicker/dateRangePicker";
import {
  CheckBox,
  Input,
  SelectField,
  TextArea,
} from "components/Form/FormikControls";
import { Column } from "components/Layout";

import { Reports } from "constants/reports";

import { getSaleSubTypeWithSpeciesOptions } from "selectors";

const billingReportOptions = Object.values(Reports)
  .filter(report => report.isRulebookDriven)
  .map(report => ({
    value: report.slug,
    label: report.title,
  }));

const getEmptyValue = () => [];

export const RuleBookSummaryForm = () => {
  const saleSubTypeOptions = useSelector(getSaleSubTypeWithSpeciesOptions);
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Input label="Name" name="name" required />
      </Grid>
      <Grid item xs={12}>
        <TextArea label="Description" name="description" />
      </Grid>
      <Grid item xs={12}>
        <Column textAlignCenter>
          <SelectField
            name="reports"
            label="Rulebook Reports"
            options={billingReportOptions}
            tooltip="Select the reports you would like this rulebook to run on"
            isMulti
          />
        </Column>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <InputDateRange
          startDateFieldName="effective_start_date"
          endDateFieldName="effective_end_date"
          includeTime={false}
          showYearOptions
          showRangeOptions={false}
          startDateLabel="Effective Start Date"
          endDateLabel="Effective End Date"
        />
      </Grid>
      <Grid spacing={1} item xs={12} container direction="column">
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item xs={6}>
            <SelectField
              label="Sale Subtypes"
              name="sale_sub_type_ids"
              options={saleSubTypeOptions}
              getEmptyValue={getEmptyValue}
              isClearable
              isMulti
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item>
            <CheckBox label="Is Archived?" name="is_archived" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
