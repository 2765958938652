import { createSelector } from "reselect";

import { EMPTY_OBJECT } from "lib";

import { getBillingRuns, getSales } from "selectors";

export const selectBillingRunTableData = createSelector(
  [getBillingRuns, getSales],
  (billingRunByIdLookup, saleByIdLookup) => {
    return Object.values(billingRunByIdLookup).map(billingRun => {
      const livestockSales = billingRun.livestockSaleIds.map(
        livestockSaleId => saleByIdLookup?.[livestockSaleId] || EMPTY_OBJECT,
      );
      return {
        billingRun,
        livestockSales,
      };
    });
  },
);
