import React from "react";

import { PropTypes } from "prop-types";

import { Dialog, DialogTitle, DialogContent } from "components/MaterialDialog";

import BidderRegistrationForm from "containers/BidderRegistrationForm";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

function Modal({ bidderId, fullScreen, returnTo, saleId, saleyardName }) {
  function onClose() {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }

  const isEdit = Boolean(bidderId);
  const titleActionString = isEdit ? "Edit" : "Create";
  const dialogTitle = `${titleActionString} Bidder Registration`;

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle onClose={onClose}>{dialogTitle}</DialogTitle>
      <DialogContent>
        <BidderRegistrationForm
          bidderId={bidderId}
          saleId={saleId}
          saleyardName={saleyardName}
          onCancel={onClose}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}

Modal.propTypes = {
  bidderId: PropTypes.string,
  saleId: PropTypes.number.isRequired,
  saleyardName: PropTypes.string.isRequired,
  fullScreen: PropTypes.bool,
  returnTo: PropTypes.string,
};

export default Modal;
