import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { createSelector } from "reselect";

import { getMixedBreedNameForSpecies } from "constants/species";

import { EMPTY_ARRAY } from "lib";

import { reduceById } from "lib/reducers";
import {
  attributeOrderSort,
  attributeSpeciesFilter,
} from "lib/speciesAttributes";

import {
  getAges,
  getBreeds,
  getCategories,
  getDeployments,
  getDeploymentAttributes,
  getExemptions,
  getGrades,
  getLabels,
  getRounds,
  getSexes,
  getSpecies,
  getSpeciesAttributes,
  getMarks,
  getActiveRole,
  getCurrentSale,
  selectActiveNominationTermByIdLookup,
  createLookupCombiner,
  createAggregateIdsByKeySelector,
  getCurrentSpeciesId,
} from "selectors";

import {
  getAllProducts,
  getDentition,
  getDeploymentDentition,
} from "selectors/root";

export const getOptions = attributes =>
  sortBy(attributes, "order").map(a => {
    return {
      value: a.id,
      label: a.name,
    };
  });

const attributes = [
  { key: "breeds", id: "breed_id" },
  { key: "sexes", id: "sex_id" },
  { key: "ages", id: "age_id" },
  { key: "grades", id: "grade_id" },
  { key: "rounds", id: "round_id" },
  { key: "nominationTerms", id: "nomination_term_id" },
];

// Only return products that are not deleted.
export const selectActiveProducts = createSelector([getAllProducts], products =>
  reduceById(Object.values(products).filter(p => !p.deleted)),
);
export const getProductAttributes = createSelector(
  [
    getBreeds,
    getSexes,
    getAges,
    getGrades,
    getRounds,
    getDeploymentAttributes,
    selectActiveProducts,
  ],
  (breeds, sexes, ages, grades, rounds, deploymentAttributes, products) => {
    const base = {
      breeds,
      sexes,
      ages,
      grades,
      rounds,
    };

    // If there are any deployment options, just show the in_use ones,
    // otherwise show all the underlying attributes as options.
    attributes.forEach(attr => {
      const dA = Object.values(deploymentAttributes[attr.key] || {});
      if (dA.length > 0) {
        const explicitlyUsed = sortBy(
          dA.filter(a => a.is_used),
          "order",
        ).map(a => a[attr.id]);
        // Make sure that if a attribute is used in a product it is also included.
        const implicitlyUsed = Object.values(products).map(p => p[attr.id]);

        const used = [...explicitlyUsed, ...implicitlyUsed];
        base[attr.key] = used.reduce((acc, cur, index) => {
          acc[cur] = {
            ...base[attr.key][cur],
            order: index,
          };
          return acc;
        }, {});
      }
    });

    return base;
  },
);

export const selectCurrentSpecies = createSelector(
  [getActiveRole, getDeployments, getSpecies],
  (activeRole, deployments, species) => {
    const activeRoleDeployments = activeRole.deployments.map(
      d => deployments[d.id],
    );
    return uniq(flatten(activeRoleDeployments.map(d => d?.species)))
      .map(s => species[s])
      .filter(Boolean);
  },
);

export const getProductAttributeBySpeciesOptions = createSelector(
  [getProductAttributes, selectCurrentSpecies],
  (attributes, species) =>
    species.reduce((acc, species) => {
      acc[species.id] = Object.keys(attributes).reduce((a, attribute) => {
        a[attribute] = getOptions(
          Object.values(attributes[attribute]).filter(
            v => v.species_id === species.id,
          ),
        );
        return a;
      }, {});
      return acc;
    }, {}),
);

export const getProductsSelector = createSelector(
  [
    selectActiveProducts,
    getProductAttributes,
    (_ignored, speciesId) => speciesId,
    (_ignored0, _ignored1, deploymentId) => deploymentId,
  ],
  (products, speciesAttributes, speciesId, deploymentId) =>
    Object.values(products)
      .filter(
        product =>
          (!speciesId || product.species_id === speciesId) &&
          (!deploymentId || product.deployment_id === deploymentId),
      )
      .sort(attributeOrderSort),
);

// filters by current species - needs saleId in match in props
export const getCurrentSpeciesAttributes = createSelector(
  [
    getBreeds,
    getSexes,
    getAges,
    getGrades,
    getCategories,
    getExemptions,
    selectActiveNominationTermByIdLookup,
    getRounds,
    getCurrentSale,
  ],
  (
    breeds,
    sexes,
    ages,
    grades,
    categories,
    exemptions,
    nominationTerms,
    rounds,
    sale,
  ) => {
    const speciesId = sale.species_id;
    const filterBySpecies = attributes =>
      Object.values(attributes)
        .filter(attributeSpeciesFilter(speciesId))
        .sort(attributeOrderSort);

    return {
      breeds: filterBySpecies(breeds),
      sexes: filterBySpecies(sexes),
      ages: filterBySpecies(ages),
      grades: filterBySpecies(grades),
      categories: filterBySpecies(categories),
      exemptions: filterBySpecies(exemptions),
      nominationTerms: filterBySpecies(nominationTerms),
      rounds: filterBySpecies(rounds),
    };
  },
);

// Return all active/non deprecated attributes.
export const getActiveSpeciesAttributes = createSelector(
  [getSpeciesAttributes],
  speciesAttributes => {
    const activeAttributes = Object.keys(speciesAttributes).reduce(
      (acc, cur) => {
        acc[cur] = reduceById(
          Object.values(speciesAttributes[cur])
            .filter(a => !a.deprecated)
            .sort(attributeOrderSort),
        );

        return acc;
      },
      {},
    );

    return activeAttributes;
  },
);

export const getSpeciesAttributeOptions = createSelector(
  [
    getBreeds,
    getSexes,
    getAges,
    getGrades,
    getCategories,
    getExemptions,
    selectActiveNominationTermByIdLookup,
    getRounds,
    getCurrentSale,
  ],
  (
    breeds,
    sexes,
    ages,
    grades,
    categories,
    exemptions,
    nominationTerms,
    rounds,
    sale,
  ) => {
    const speciesId = sale?.species_id;
    const filterBySpecies = attributes =>
      Object.values(attributes)
        .filter(attributeSpeciesFilter(speciesId))
        .sort(attributeOrderSort);

    return {
      breeds: getOptions(filterBySpecies(breeds)),
      sexes: getOptions(filterBySpecies(sexes)),
      ages: getOptions(filterBySpecies(ages)),
      grades: getOptions(filterBySpecies(grades)),
      categories: getOptions(filterBySpecies(categories)),
      exemptions: getOptions(filterBySpecies(exemptions)),
      nominationTerms: getOptions(filterBySpecies(nominationTerms)),
      rounds: getOptions(filterBySpecies(rounds)),
    };
  },
);

export const selectSpeciesAttributeOptionsBySpeciesId = createSelector(
  [
    getBreeds,
    getSexes,
    getAges,
    getGrades,
    getCategories,
    getExemptions,
    selectActiveNominationTermByIdLookup,
    getRounds,
    getCurrentSale,
    getSpecies,
  ],
  (
    breeds,
    sexes,
    ages,
    grades,
    categories,
    exemptions,
    nominationTerms,
    rounds,
    sale,
    species,
  ) => {
    const filterBySpecies = (attributes, speciesId) =>
      Object.values(attributes)
        .filter(attributeSpeciesFilter(speciesId))
        .sort(attributeOrderSort);

    return Object.values(species).reduce((acc, species) => {
      acc[species.id] = {
        breeds: getOptions(filterBySpecies(breeds, species.id)),
        sexes: getOptions(filterBySpecies(sexes, species.id)),
        ages: getOptions(filterBySpecies(ages, species.id)),
        grades: getOptions(filterBySpecies(grades, species.id)),
        categories: getOptions(filterBySpecies(categories, species.id)),
        exemptions: getOptions(filterBySpecies(exemptions, species.id)),
        nominationTerms: getOptions(
          filterBySpecies(nominationTerms, species.id),
        ),
        rounds: getOptions(filterBySpecies(rounds, species.id)),
      };
      return acc;
    }, {});
  },
);

export const getProductsBySpecies = createSelector(
  [selectActiveProducts, getCurrentSale],
  (products, sale) =>
    Object.values(products).filter(p => p.species_id === sale.species_id),
);

const selectDeploymentProductsByDeploymentIdLookup = createSelector(
  [getProductsBySpecies],
  products => groupBy(products, "deployment_id"),
);

export const getProductsByDeploymentId = deploymentId => state =>
  selectDeploymentProductsByDeploymentIdLookup(state)[deploymentId] ||
  EMPTY_ARRAY;

export const getLabelsBySpecies = createSelector(
  [getLabels, getCurrentSale],
  (labels, sale) =>
    Object.values(labels).filter(
      p => p.species_id === sale.species_id && !p.deleted,
    ),
);

export const getMarksBySpecies = createSelector(
  [getMarks, getCurrentSale],
  (marks, sale) =>
    Object.values(marks).filter(
      mark => mark.species_id === sale.species_id && !mark.deleted,
    ),
);

export const getLabelById = labelId => state =>
  getLabels(state)[labelId] || null;

export const getSpeciesById = id => state => getSpecies(state)[id] || null;

export const selectSpeciesIdsBySaleyardIdLookup = createSelector(
  [getDeployments],
  deployments => {
    return Object.values(deployments).reduce((acc, deployment) => {
      deployment.locations.forEach(location => {
        if (acc[location.saleyard_id]) {
          acc[location.saleyard_id] = uniq([
            ...acc[location.saleyard_id],
            ...deployment.species,
          ]);
        } else {
          acc[location.saleyard_id] = deployment.species;
        }
      });
      return acc;
    }, {});
  },
);

export const selectSpeciesBySaleyardIdLookup = createSelector(
  [
    selectSpeciesIdsBySaleyardIdLookup,
    getSpecies,
    (_, saleyardId) => saleyardId,
  ],
  (lookup, species, saleyardId) => {
    return (
      lookup[saleyardId]?.map(speciesId => species[speciesId]) || EMPTY_ARRAY
    );
  },
);

export const selectMarkOptionsByDeploymentIdLookup = createSelector(
  [getMarksBySpecies],
  marks => groupBy(marks, "deployment_id"),
);

export const getMarkoptionsByDeploymentId = deploymentId => state =>
  selectMarkOptionsByDeploymentIdLookup(state)[deploymentId];

export const selectFormattedDeploymentMarksByDeploymentId = createSelector(
  [selectMarkOptionsByDeploymentIdLookup],
  createLookupCombiner(deploymentOptions =>
    deploymentOptions.map(option => ({
      location: option.short_code,
      color: "",
    })),
  ),
);

export const selectDeploymentMarkOrderLookup = createSelector(
  [getMarksBySpecies],
  marks =>
    marks.reduce((acc, cur) => {
      if (!acc[cur.deployment_id]) {
        acc[cur.deployment_id] = {
          [cur.short_code]: cur.order,
        };
      } else {
        acc[cur.deployment_id][cur.short_code] = cur.order;
      }
      return acc;
    }, {}),
);

export const selectCurrentMixedBreedId = createSelector(
  [getBreeds, getCurrentSale],
  (breeds, { species_id: speciesId }) => {
    const speciesFilter = attributeSpeciesFilter(speciesId);
    const mixedBreedName = getMixedBreedNameForSpecies(speciesId);
    return (
      Object.values(breeds).find(
        breed => speciesFilter(breed) && breed.name === mixedBreedName,
      )?.id || null
    );
  },
);

export const selectDeploymentDentitionByDentitionIdLookup =
  createAggregateIdsByKeySelector(getDeploymentDentition, "dentition_id");

export const selectAvaliableDentitions = createSelector(
  [
    getDentition,
    selectDeploymentDentitionByDentitionIdLookup,
    getDeploymentDentition,
    getCurrentSpeciesId,
  ],
  (
    globalDentitions,
    deploymentDentitionsLookup,
    deploymentDentitions,
    speciesId,
  ) =>
    sortBy(
      Object.values(globalDentitions).filter(dentition => {
        if (dentition.species_id === speciesId) {
          const deploymentDentition =
            deploymentDentitions[deploymentDentitionsLookup[dentition.id]];
          // If there is a deploymentDentition use that
          if (deploymentDentition) {
            return deploymentDentition.is_used;
          }
          return dentition.default;
        } else {
          return false;
        }
      }),
      "order",
    ),
);

export const selectDentitionAsOptionsByAgeIdLookup = createSelector(
  [selectAvaliableDentitions],
  dentitions =>
    dentitions.reduce((lookup, dentition) => {
      if (!lookup[dentition.age_id]) {
        lookup[dentition.age_id] = [];
      }
      lookup[dentition.age_id].push({
        value: dentition.id,
        label: dentition.display_value,
      });
      return lookup;
    }, {}),
);

export const getDentitionOptionsByAgeId = ageId => state =>
  selectDentitionAsOptionsByAgeIdLookup(state)[ageId] || EMPTY_ARRAY;
