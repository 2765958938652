import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

export const manualAdjustmentSerializeMap: SerializerMap<ManualAdjustment> = {
  billingReference: "billing_reference",
  created: "created",
  createdFromDocumentId: "created_from_document_id",
  deploymentId: "deployment_id",
  fromBusinessId: "from_business_id",
  glCode: "gl_code",
  gstCents: "gst_cents",
  gstMethod: "gst_method",
  id: "id",
  labels: "labels",
  livestockSaleId: "livestock_sale_id",
  note: "note",
  subtotalCents: "subtotal_cents",
  sundryTemplateId: "sundry_template_id",
  taxType: "tax_type",
  toBusinessId: "to_business_id",
  totalCents: "total_cents",
};

export const serializeManualAdjustment = buildSerializer(
  manualAdjustmentSerializeMap,
  "manualAdjustment",
);
export const deserializeManualAdjustment = buildDeserializer(
  manualAdjustmentSerializeMap,
  "manualAdjustment",
);
