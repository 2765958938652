import LogRocket from "logrocket";

import { LOGROCKET_IGNORE_ACTIONS } from "constants/actionTypes";

import { isLogRocketActive } from "./config";

export const getLogRocketMiddleware = () => {
  if (!isLogRocketActive) {
    return null;
  }

  return LogRocket.reduxMiddleware({
    stateSanitizer() {
      // Clean out all the data in state and actions - they're too big for LogRocket!
      return {};
    },
    actionSanitizer(action) {
      // Just log the TYPE of action - there's a bunch of data embedded in there, and it's
      // (seemingly) causnig logrocket to stop working.
      const { type } = action;
      if (LOGROCKET_IGNORE_ACTIONS.includes(type)) {
        return null;
      } else {
        return {
          type,
        };
      }
    },
  });
};
