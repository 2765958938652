import React from "react";

import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";
import intersection from "lodash/intersection";
import { useDispatch, useSelector } from "react-redux";

import { MasterRuleBookAction } from "actions";

import Button from "components/Button";
import {
  CheckBoxBase,
  DateInput,
  Input,
  SelectField,
} from "components/Form/FormikControls";
import { SpaceBetweenRow } from "components/Layout";

import { ForStaff } from "containers/ForUserType";
import { MasterRuleBookLibraryCard } from "containers/Settings/RuleBooks/Library/MasterRuleBookLibraryCard";

import { sortByKey } from "lib";

import {
  getMasterRuleBooks,
  getSaleSubTypeWithSpeciesOptions,
} from "selectors";

import { useBoolean } from "hooks";

export function ModalContent(props) {
  const { ruleBookId, masterRuleId } = props;

  const { values } = useFormikContext();

  const showSoftDeletedBag = useBoolean(false);
  const showSoftDeleted = showSoftDeletedBag[0];
  const toggleShowSoftDeleted = showSoftDeletedBag[4];

  const { effectiveEndDate, filteredSaleSubTypes, searchText } = values;

  const dispatch = useDispatch();

  const saleSubTypeOptions = useSelector(getSaleSubTypeWithSpeciesOptions);

  function onClickAddMasterRuleBook() {
    dispatch(MasterRuleBookAction.create({ name: "New Master Rule Book" }));
  }

  let masterRuleBooks = Object.values(useSelector(getMasterRuleBooks) || {});

  if (effectiveEndDate) {
    masterRuleBooks = Object.values(masterRuleBooks).filter(
      rulebook =>
        new Date(rulebook.effective_end_date) > new Date(effectiveEndDate),
    );
  }

  if (filteredSaleSubTypes?.length > 0) {
    masterRuleBooks = Object.values(masterRuleBooks).filter(
      rulebook =>
        intersection(rulebook.sale_sub_type_ids, filteredSaleSubTypes).length >
        0,
    );
  }

  if (searchText) {
    masterRuleBooks = Object.values(masterRuleBooks).filter(
      rulebook =>
        rulebook.name.toLowerCase().includes(searchText.toLowerCase()) ||
        rulebook.description.toLowerCase().includes(searchText.toLowerCase()),
    );
  }

  return (
    <>
      <Grid
        spacing={1}
        container
        alignItems="flex-end"
        justifyContent="space-around"
      >
        <Grid item xs={3}>
          <Input label="Search" name="searchText" />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            label="Sale Subtypes"
            name="filteredSaleSubTypes"
            options={saleSubTypeOptions}
            isClearable
            isMulti
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={2}>
          <DateInput label="Valid At" name="effectiveEndDate" />
        </Grid>
        <ForStaff>
          <Grid item container xs={2}>
            <Grid item xs={12}>
              <CheckBoxBase
                label="Show Soft Deleted Rules?"
                value={showSoftDeleted}
                onChange={toggleShowSoftDeleted}
              />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={onClickAddMasterRuleBook}>
                Add Master Rule Book
              </Button>
            </Grid>
          </Grid>
        </ForStaff>
      </Grid>
      <SpaceBetweenRow />
      {sortByKey(Object.values(masterRuleBooks), "name").map(masterRuleBook => (
        <MasterRuleBookLibraryCard
          key={masterRuleBook.id}
          masterRuleBookId={masterRuleBook.id}
          ruleBookId={ruleBookId}
          masterRuleId={masterRuleId}
          showSoftDeleted={showSoftDeleted}
        />
      ))}
    </>
  );
}
