import { isObject } from "lodash";

export function hasPermission(object, permission) {
  // console.log("Checking for ", permission, " on ", object);
  return (
    typeof object === "object" &&
    object !== null &&
    Array.isArray(object.permissions) &&
    object.permissions.length > 0 &&
    object.permissions.includes(permission)
  );
}

const hasPermissionFilterFactory = permission => permissableModel =>
  hasPermission(permissableModel, permission);

export function someHasPermission(permissableModels, permission) {
  if (Array.isArray(permissableModels)) {
    if (Array.isArray(permission)) {
      return permission.some(perm =>
        permissableModels.some(hasPermissionFilterFactory(perm)),
      );
    } else {
      return permissableModels.some(hasPermissionFilterFactory(permission));
    }
  }

  if (isObject(permissableModels)) {
    // eslint-disable-next-line no-console
    console.warn(
      `Attempted to check someHasPermission(${permission}) on an object that isnt an Array.`,
    );
  }

  return false;
}
