import React, { useCallback, useState } from "react";

import { Divider, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addSaleLotWithPens, setSetting } from "actions";

import { SecondaryButton } from "components/Form";
import { Button } from "components/Form/Button";
import { CheckBoxBase } from "components/Form/FormikControls";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { InlineCreateSaleLotComponent } from "components/SaleLotForms/InlineCreateSaleLotForm";

import { PenTypes } from "constants/auctionPens";
import { ExportSites } from "constants/exportSites";
import { SaleLotModalSection } from "constants/navigation";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { Settings } from "constants/settings";

import {
  closeAllHashModals,
  getInlineCreateSaleLotModalRoute,
  openEditSaleLotModal,
  openInlineCreateSaleLotModal,
  openScanModal,
  returnToLocation,
} from "lib/navigation";

import {
  getCurrentSaleyard,
  getExportSitesByDeploymentSaleId,
  getSetting,
  selectDeploymentSaleIdByConsignmentIdLookup,
  selectRoleDeployments,
} from "selectors";

import { useHasPermission, useSomeHasPermission, useToggle } from "hooks";

export function InlineCreateSaleLotModal({
  returnTo,
  initialValues = {},
  options,
}) {
  const storedSaleLotFormValues = useSelector(
    getSetting(Settings.storedSaleLotFormData),
  );

  const { includeStoredValues = false } = options;

  const hasDeploymentAllowRememberLastDraftingAttributesPermission =
    useSomeHasPermission(
      selectRoleDeployments,
      DeploymentPermissions.featureAllowRememberLastDraftingAttributes,
    );

  const hasSaleYardAllowRememberLastDraftingAttributesPermission =
    useHasPermission(
      getCurrentSaleyard,
      SaleyardPermissions.featureAllowRememberLastDraftingAttributes,
    );

  const allowRememberDraftingAttributes =
    includeStoredValues &&
    (hasDeploymentAllowRememberLastDraftingAttributesPermission ||
      hasSaleYardAllowRememberLastDraftingAttributesPermission);

  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [values, setValues] = useState(null);

  const [rememberDraftingAttributes, toggleRememberDraftingAttributes] =
    useToggle(includeStoredValues && storedSaleLotFormValues);

  const onChangeRememberSettings = () => toggleRememberDraftingAttributes();

  const deploymentSaleId = useSelector(
    selectDeploymentSaleIdByConsignmentIdLookup,
  )[values?.consignment_id];

  const exportSites = useSelector(
    getExportSitesByDeploymentSaleId(deploymentSaleId),
  );

  const hasAuctionsPlusEnabled = exportSites?.includes(
    ExportSites.AUCTIONS_PLUS,
  );

  const hasStockLiveEnabled = exportSites?.includes(ExportSites.STOCK_LIVE);

  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  const dispatch = useDispatch();
  function handleCreateSaleLot(
    afterCreateOptions = {
      openScanModal: false,
      resetForm: false,
      openDetailedModal: false,
      detailedModalSection: null,
      rememberDraftingAttributes: false,
    },
  ) {
    const { newId = null } = options;

    const newSaleLotId = newId || uuidv4();
    dispatch(addSaleLotWithPens(newSaleLotId, values));

    if (rememberDraftingAttributes) {
      dispatch(setSetting(Settings.storedSaleLotFormData, values));
    } else {
      dispatch(setSetting(Settings.storedSaleLotFormData, null));
    }

    closeSelf();
    if (afterCreateOptions.resetForm) {
      // Re-open self, but force a reset of the form with the timeout - otherwise
      // it just remounts with the same data.
      setTimeout(() => {
        openInlineCreateSaleLotModal(
          { sale_round_id: values.sale_round_id },
          options,
          returnTo,
        );
      }, 0);
    } else if (afterCreateOptions.openScanModal) {
      const newInlineSaleLotModalRoute = `#${getInlineCreateSaleLotModalRoute(
        { sale_round_id: values.sale_round_id },
        options,
        returnTo,
      )}`;
      openScanModal(
        values.consignment_id,
        newSaleLotId,
        false,
        null,
        PenTypes.SELLING,
        newInlineSaleLotModalRoute,
      );
    } else if (afterCreateOptions.openDetailedModal) {
      openEditSaleLotModal(
        newSaleLotId,
        afterCreateOptions.detailedModalSection,
      );
    }
  }

  function handleCreateSaleLotAndScan() {
    handleCreateSaleLot({ openScanModal: true, rememberDraftingAttributes });
  }

  function handleCreateSaleLotAndAssess() {
    handleCreateSaleLot({
      openDetailedModal: true,
      detailedModalSection: SaleLotModalSection.INTERFACES,
      rememberDraftingAttributes,
    });
  }

  function handleCreateSaleLotAndNew() {
    handleCreateSaleLot({ resetForm: true, rememberDraftingAttributes });
  }

  const contextUpdateSaleLot = useCallback(
    (values, isValid = false, setDirty = true) => {
      setIsValid(isValid);
      setIsDirty(setDirty);
      setValues(values);
    },
    [setIsValid, setIsDirty, setValues],
  );

  const saveDisabled = !isValid || !isDirty;

  if (rememberDraftingAttributes && storedSaleLotFormValues) {
    initialValues.breed_id = storedSaleLotFormValues.breed_id;
    initialValues.sex_id = storedSaleLotFormValues.sex_id;
    initialValues.age_id = storedSaleLotFormValues.age_id;
    initialValues.description_id = storedSaleLotFormValues.description_id;
  }

  return (
    <Dialog open onClose={closeSelf}>
      <DialogTitle onClose={closeSelf}>Create New Sale Lot</DialogTitle>
      <DialogContent>
        <InlineCreateSaleLotComponent
          contextUpdateSaleLot={contextUpdateSaleLot}
          initialValues={initialValues}
          options={{ ...options, ignoreHeadCountForVendorSearch: true }}
        />
      </DialogContent>

      <DialogActions shrink={0}>
        <SecondaryButton data-tour="cancel" onClick={closeSelf}>
          Cancel
        </SecondaryButton>
        <Button
          data-tour="saveAndClose"
          disabled={saveDisabled}
          onClick={handleCreateSaleLot}
        >
          Save &amp; Close
        </Button>

        <Button
          data-tour="saveAndScan"
          disabled={saveDisabled}
          onClick={handleCreateSaleLotAndScan}
        >
          Save &amp; Scan
        </Button>
        {(hasAuctionsPlusEnabled || hasStockLiveEnabled) && (
          <Button
            data-tour="saveAndAssess"
            disabled={saveDisabled}
            onClick={handleCreateSaleLotAndAssess}
          >
            Save &amp; Assess
          </Button>
        )}
        <Button
          data-tour="saveAndNew"
          disabled={saveDisabled}
          onClick={handleCreateSaleLotAndNew}
        >
          Save &amp; New
        </Button>
      </DialogActions>
      {allowRememberDraftingAttributes && (
        <>
          <Divider />
          <DialogActions>
            <Grid item xs={12}>
              <CheckBoxBase
                label="Remember Drafting Attributes"
                onChange={onChangeRememberSettings}
                value={rememberDraftingAttributes}
              />
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
