import React from "react";

import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { chooseSingleWeighEid, resetSingleWeigh } from "actions";

import { Button } from "components/Form/Button";
import { Label } from "components/Form/FormikControls";
import { ReactSelect } from "components/SearchableSelector";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import {
  getAuctionPens,
  getCurrentDraftingDecisionBySingleWeighById,
  getScans,
  selectAuctionPenIdBySaleLotIdLookup,
  selectBuyerDisplayNameBySaleLotIdLookup,
  selectDeliveryPenIdBySaleLotIdLookup,
  selectSaleLotByEidLookup,
} from "selectors";

export function MultipleEidsAction({ singleWeighId }) {
  const dispatch = useDispatch();

  const currentDecision = useSelector(
    getCurrentDraftingDecisionBySingleWeighById(singleWeighId),
  );
  const saleLotByEidLookup = useSelector(selectSaleLotByEidLookup);
  const auctionPenIdBySaleLotId = useSelector(
    selectAuctionPenIdBySaleLotIdLookup,
  );
  const deliveryPenIdBySaleLotId = useSelector(
    selectDeliveryPenIdBySaleLotIdLookup,
  );
  const auctionPens = useSelector(getAuctionPens);

  const buyerDisplayNameBySaleLotId = useSelector(
    selectBuyerDisplayNameBySaleLotIdLookup,
  );
  const scans = useSelector(getScans);

  const { detectedEids } = currentDecision;
  const eidOptions = detectedEids.map(eid => {
    const label = [eid];
    const saleLot = saleLotByEidLookup[eid];

    if (saleLot) {
      if (buyerDisplayNameBySaleLotId[saleLot.id]) {
        label.push(`Buyer: ${buyerDisplayNameBySaleLotId[saleLot.id]}`);
      } else {
        label.push("No Buyer");
      }
      if (deliveryPenIdBySaleLotId[saleLot.id]) {
        label.push(
          `DPen: ${getAuctionPenDisplayName(
            auctionPens[auctionPenIdBySaleLotId[saleLot.id]],
          )}`,
        );
      } else {
        label.push("No Delivery Pen");
      }
      if (auctionPenIdBySaleLotId[saleLot.id]) {
        label.push(
          `Auction Pen: ${getAuctionPenDisplayName(
            auctionPens[auctionPenIdBySaleLotId[saleLot.id]],
          )}`,
        );
      } else {
        label.push("No Auction Pen");
      }
    } else if (scans[eid]) {
      label.push("Unassigned in sale.");
    } else {
      label.push("Not in sale");
    }

    return {
      value: eid,
      label: label.join(" - "),
    };
  });

  // default is the first one we find with a sale lot
  const defaultSelection = eidOptions.find(
    option => saleLotByEidLookup[option.value],
  );
  const [selectedEidOption, setSelectedEidOption] =
    React.useState(defaultSelection);

  function handleResetSingleWeigh() {
    dispatch(resetSingleWeigh(singleWeighId));
  }

  function handleUseEid() {
    dispatch(chooseSingleWeighEid(singleWeighId, selectedEidOption.value));
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        Multiple EIDs have been detected in the crush, unable to make a drafting
        decision.
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <Button onClick={handleResetSingleWeigh}>Reset Decision</Button>
      </Grid>

      <Grid item xs={12}>
        <Label>Select which EID to use</Label>
      </Grid>
      <Grid data-tour="eidToUse" item xs={6}>
        <ReactSelect
          options={eidOptions}
          name="eidToUse"
          value={selectedEidOption}
          isClearable={false}
          onChange={setSelectedEidOption}
          menuPortalTarget={document.body}
        />
      </Grid>
      <Grid item xs={6} container alignItems="center">
        <Button onClick={handleUseEid} disabled={!selectedEidOption} fullWidth>
          {selectedEidOption ? `Use EID ${selectedEidOption.label}` : "Use EID"}{" "}
        </Button>
      </Grid>
    </Grid>
  );
}
