import { createSelector } from "reselect";

import { REQUEST_NEW_JWT_TOKEN_OFFLINE } from "constants/actionTypes";

import { getChangesOutbox, getIsOnline } from "selectors";

// Dont include actions that start with the text in this array in the changes.
const ignoredActions = [REQUEST_NEW_JWT_TOKEN_OFFLINE, "FETCH"];

export const getChangesOutboxSize = createSelector(
  [getChangesOutbox],
  changesOutbox =>
    changesOutbox.filter(
      c => !ignoredActions.some(action => c.type.startsWith(action)),
    ).length,
);

export const getOnlineStatus = createSelector(
  [getIsOnline, getChangesOutboxSize],
  (status, changesOutboxSize) => ({
    status,
    changesOutboxSize,
  }),
);
