import {
  GET_DEPLOYMENT_ATTRIBUTES,
  GET_DEPLOYMENT_ATTRIBUTES_FAILURE,
} from "constants/actionTypes";

export const requestDeploymentAttributes = () => ({
  type: GET_DEPLOYMENT_ATTRIBUTES,
});

export const requestDeploymentAttributeFailure = () => ({
  type: GET_DEPLOYMENT_ATTRIBUTES_FAILURE,
});

export const requestDeploymentAttributeSuccess = (actionType, payload) => ({
  type: actionType.SUCCESS,
  payload,
});

export const updateDeploymentAttributes = (actionType, updates) => {
  const meta = {
    request: updates,
  };
  return {
    type: actionType.OFFLINE,
    payload: updates,
    meta: {
      offline: {
        effect: {
          url: actionType.url,
          method: "PUT",
          body: JSON.stringify(updates),
        },
        commit: {
          type: actionType.COMMIT,
          meta,
        },
        rollback: {
          type: actionType.ROLLBACK,
          meta,
        },
      },
    },
  };
};

export const updateDeploymentAttribute = (actionType, updates) => {
  const meta = {
    request: [updates],
  };
  const { id } = updates;
  return {
    type: actionType.OFFLINE,
    payload: updates,
    meta: {
      offline: {
        effect: {
          url: `${actionType.url}${id}/`,
          method: "PUT",
          body: JSON.stringify([updates]),
        },
        commit: {
          type: actionType.COMMIT,
          meta,
        },
        rollback: {
          type: actionType.ROLLBACK,
          meta,
        },
      },
    },
  };
};

export const deleteDeploymentAttribute = (actionType, id) => {
  const meta = {
    id,
  };
  return {
    type: actionType.OFFLINE,
    meta: {
      id,
      offline: {
        effect: {
          url: `${actionType.url}${id}/`,
          method: "DELETE",
        },
        commit: {
          type: actionType.COMMIT,
          meta,
        },
        rollback: {
          type: actionType.ROLLBACK,
          meta,
        },
      },
    },
  };
};
