import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { FullScreenContainer } from "components/FullScreenContainer";
import { NextLotFooter } from "components/SaleWatcher/NextLot";

import {
  getSellingNowByPriceSaleLot,
  getSoldSaleLotsInSoldOrder,
  selectUnsoldWeighedSaleLotsInWeighedOrderAfterCurrentSold,
  selectWeighedSaleLotsInWeighedOrder,
} from "selectors";

import {
  AverageWeight,
  BoundingBox,
  CentsPerKilo,
  DollarsPerHead,
  TotalWeight,
} from "./WatcherComponents";

const HighContrastTable = styled.table`
  ${({ theme, showNext }) => `
  border-collapse: collapse;
  font-size: ${showNext ? theme.fontSizes.eta : theme.fontSizes.theta}px;
  background-color: ${theme.colors.gray13};
  color: ${theme.colors.highContrastDefault};
  
  width: 100%;
  height: 100%;
  th {
    width: 33%;
    color: ${theme.colors.white};
  }
  td {
    border: 1px solid ${theme.colors.grayAE};
    width: 33%;
    padding 0 ${theme.space[2]}px;
    font-weight: bold; 
  }

  tfoot td, 
  tfoot th {
    color: ${theme.colors.highContrastNextUp};
    border: none;
    text-align: center;
  }

  
  .current {
      color: ${theme.colors.highContrastCurrent};
  }
  
  .lastSold {
      color: ${theme.colors.highContrastLastSold};
  }
  
  `}
`;

const RingSellingTableBody = ({ leftSaleLot, rightSaleLot }) => (
  <tbody>
    <tr>
      <td>Pen & Mark</td>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        {leftSaleLot.penName || "-"}{" "}
        {leftSaleLot.marks?.map(mark => mark.location).join("/")}
      </td>
      <td className="lastSold fadeIn" key={rightSaleLot.last_modified}>
        {rightSaleLot.penName || "-"}
        {rightSaleLot.marks?.map(mark => mark.location).join("/")}
      </td>
    </tr>
    <tr>
      <td>Head</td>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        {leftSaleLot.quantity || "-"}
      </td>
      <td className="lastSold fadeIn" key={rightSaleLot.last_modified}>
        {rightSaleLot.quantity || "-"}
      </td>
    </tr>
    <tr>
      <td>Sex</td>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        {leftSaleLot.sex_name || "-"}
      </td>
      <td className="lastSold fadeIn" key={rightSaleLot.last_modified}>
        {rightSaleLot.sex_name || "-"}
      </td>
    </tr>
    <tr>
      <td>Age</td>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        {leftSaleLot.age_group_name || "-"}
      </td>
      <td className="lastSold fadeIn" key={rightSaleLot.last_modified}>
        {rightSaleLot.age_group_name || "-"}
      </td>
    </tr>
    <tr>
      <td>Total Weight</td>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        <TotalWeight saleLot={leftSaleLot} />
      </td>
      <td className="lastSold fadeIn" key={rightSaleLot.last_modified}>
        <TotalWeight saleLot={rightSaleLot} />
      </td>
    </tr>
    <tr>
      <td>Avg. Weight</td>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        <AverageWeight saleLot={leftSaleLot} />
      </td>
      <td className="lastSold fadeIn" key={rightSaleLot.last_modified}>
        <AverageWeight saleLot={rightSaleLot} />
      </td>
    </tr>
    <tr>
      <td>&#162;/kg</td>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        <CentsPerKilo saleLot={leftSaleLot} />
      </td>
      <td className="lastSold fadeIn" key={rightSaleLot.last_modified}>
        <CentsPerKilo saleLot={rightSaleLot} />
      </td>
    </tr>
    <tr>
      <td>$/Head</td>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        <DollarsPerHead saleLot={leftSaleLot} />
      </td>
      <td className="lastSold fadeIn" key={rightSaleLot.last_modified}>
        <DollarsPerHead saleLot={rightSaleLot} />
      </td>
    </tr>
  </tbody>
);

function RingSellingView({
  rightSaleLot,
  rightSaleLotTitle,
  leftSaleLot,
  leftSaleLotTitle,
  nextLot,
  showNext = false,
}) {
  return (
    <FullScreenContainer>
      <BoundingBox>
        <HighContrastTable showNext={showNext}>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th className="current">{leftSaleLotTitle}</th>
              <th className="lastSold">{rightSaleLotTitle}</th>
            </tr>
          </thead>
          <RingSellingTableBody
            leftSaleLot={leftSaleLot}
            rightSaleLot={rightSaleLot}
          />
          {showNext ? <NextLotFooter saleLot={nextLot} colSpan={2} /> : null}
        </HighContrastTable>
      </BoundingBox>
    </FullScreenContainer>
  );
}

export const RingSellingByPriceView = () => {
  const sellingNow = useSelector(getSellingNowByPriceSaleLot) || {};
  const lastSold = useSelector(getSoldSaleLotsInSoldOrder)?.[0] || {};
  return (
    <RingSellingView
      rightSaleLot={lastSold}
      rightSaleLotTitle="LAST SOLD"
      leftSaleLot={sellingNow}
      leftSaleLotTitle="CURRENT"
    />
  );
};
export const RingSellingByWeightView = () => {
  const weighedLots = useSelector(selectWeighedSaleLotsInWeighedOrder) || [];

  const latestTwo = weighedLots.slice(-2);
  const currentSaleLot = latestTwo.pop() || {};
  const previousSaleLot = latestTwo.pop() || {};
  return (
    <RingSellingView
      rightSaleLot={previousSaleLot}
      rightSaleLotTitle="PREVIOUS"
      leftSaleLot={currentSaleLot}
      leftSaleLotTitle="JUST WEIGHED"
    />
  );
};

export const RingSellingByWeightWithNextView = () => {
  const lastSold = useSelector(getSoldSaleLotsInSoldOrder)?.[0] || {};
  const weighedUnsoldLots =
    useSelector(selectUnsoldWeighedSaleLotsInWeighedOrderAfterCurrentSold) ||
    [];

  // Next two unsold, by weigh time after last sold.
  const currentSaleLot = weighedUnsoldLots?.[0] || {};
  const nextSaleLot = weighedUnsoldLots?.[1] || null;

  return (
    <RingSellingView
      leftSaleLot={lastSold}
      leftSaleLotTitle="JUST SOLD"
      rightSaleLot={currentSaleLot}
      rightSaleLotTitle="SELLING NOW"
      showNext
      nextLot={nextSaleLot}
    />
  );
};
