import React from "react";

import styled from "styled-components/macro";

import { useChangeFlash } from "hooks";

const FlasherComponent = styled.div(
  ({ changed, theme }) => `
    background: ${changed ? theme.colors.highContrastCurrent : "inherit"};
    transition: ${theme.transitions[0]};
    display: inherit;
    box-shadow: ${
      changed ? `0px 0px 6px 4px ${theme.colors.highContrastCurrent}` : "none"
    };
`,
);
export const Flasher = ({ value, children }) => {
  const changedClass = useChangeFlash(value);
  return (
    <FlasherComponent changed={!!changedClass}>{children}</FlasherComponent>
  );
};
