import React from "react";

import { Box, Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import { useDispatch } from "react-redux";

import { Button, SecondaryButton } from "components/Form";
import { CheckBox, LabelText } from "components/Form/FormikControls";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { pluralize } from "lib/pluralize";

import { useFieldValue } from "hooks";

export const checkboxFieldNameSuffix = "_includeInChangeSet";

export const getCheckboxFieldName = fieldName =>
  `${fieldName}${checkboxFieldNameSuffix}`;

const BulkUpdateFieldsFormRow = props => {
  const { details, fieldName, index } = props;
  const { Component, componentProps = null } = details;

  const isFieldEnabled =
    useFieldValue(getCheckboxFieldName(fieldName)) === true;

  return (
    <React.Fragment key={fieldName}>
      <Grid item xs={1}>
        {index === 0 ? <LabelText>Update</LabelText> : <br />}
        <CheckBox name={getCheckboxFieldName(fieldName)} />
      </Grid>
      <Grid item xs={11}>
        <Component
          name={fieldName}
          {...componentProps}
          disabled={!isFieldEnabled}
        />
      </Grid>
    </React.Fragment>
  );
};

const BulkUpdateFieldsForm = ({ fieldsAndComponentsMap }) => {
  return (
    <Grid container spacing={2}>
      {Object.entries(fieldsAndComponentsMap).map(
        ([fieldName, details], index) => (
          <BulkUpdateFieldsFormRow
            key={fieldName}
            index={index}
            fieldName={fieldName}
            details={details}
          />
        ),
      )}
    </Grid>
  );
};

const BulkUpdateFieldsActions = props => {
  const { onClose } = props;
  const { values } = useFormikContext();
  const isDisabled = Object.keys(values)
    .filter(fieldName => fieldName.endsWith(checkboxFieldNameSuffix))
    .every(fieldName => values[fieldName] !== true);
  return (
    <>
      <SecondaryButton onClick={onClose}>Close</SecondaryButton>
      <Button
        data-tour="bulkUpdateFieldsSubmit"
        disabled={isDisabled}
        type="submit"
      >
        Bulk Update
      </Button>
    </>
  );
};

export const BulkUpdateOptionalFieldsModal = ({
  bulkUpdateActionCreator,
  fieldsAndComponentsMap,
  initialValues,
  modelIds,
  modelName,
  onClose,
  validationSchema,
}) => {
  const fieldNames = Object.keys(fieldsAndComponentsMap);

  const dispatch = useDispatch();

  if (!fieldNames.length || !modelIds.length) {
    return null;
  }

  const onSubmit = values => {
    const updatedValues = fieldNames.reduce((acc, fieldName) => {
      const includeInChangeSetFieldName = getCheckboxFieldName(fieldName);
      const shouldIncludeInChangeSet =
        values[includeInChangeSetFieldName] === true;
      if (shouldIncludeInChangeSet) {
        acc[fieldName] = values[fieldName];
      }
      return acc;
    }, {});

    const payload = modelIds.map(id => ({
      id,
      ...updatedValues,
    }));
    dispatch(bulkUpdateActionCreator(payload));
    onClose();
  };
  const modelCount = modelIds.length;

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>
        Editing {modelCount} {pluralize(modelName, modelCount)}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <DialogContent>
            <Box marginY={2}>
              <strong>
                You are bulk editing {modelCount}{" "}
                {pluralize("record", modelCount)}. Any changes made to checked
                fields will be applied to these records. This action cannot be
                undone.
              </strong>
            </Box>
            <Box padding={2}>
              <BulkUpdateFieldsForm
                fieldsAndComponentsMap={fieldsAndComponentsMap}
                onClose={onClose}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <BulkUpdateFieldsActions onClose={onClose} />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};
