export const PenScanLotStatus = {
  MAPPED: "Mapped",
  PARTIALLY_MAPPED: "Partially Mapped",
  NOT_MAPPED: "Not Mapped",
  NOT_MAPPABLE: "Not Mappable",
  IS_LOCKED: "Is Locked",
};

export const PenScanLotStatusColors = {
  [PenScanLotStatus.MAPPED]: "success",
  [PenScanLotStatus.PARTIALLY_MAPPED]: "inProgress",
  [PenScanLotStatus.NOT_MAPPED]: "warning",
  [PenScanLotStatus.NOT_MAPPABLE]: "error",
  [PenScanLotStatus.IS_LOCKED]: "gray78",
};
