import React from "react";

import styled, { css } from "styled-components/macro";

import StatusCard from "components/Card/StatusCard";
import { Column, Row } from "components/Layout";

export const ChangesPending = React.memo(styled.div`
  color: #ff0000;
  visibility: ${({ offlinePatching }) =>
    offlinePatching ? "visible" : "hidden"};
  font-size: 40px;
  width: 40px;
`);
export const StatusWrapper = React.memo(styled(StatusCard)`
  box-shadow: 0 1px 3px 0 rgba(200, 200, 200, 0.59);
  background-color: #ffffff;
  padding: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  ${({ color, selected, theme }) => {
    const selectedColor = color && theme.colors[color];
    return `
        color: ${selectedColor || "#4a4a4a"};
        ${selected && `box-shadow: 0 0 1px 2px #4A90E2`}
    `;
  }};
`);
export const ErpChipContainer = styled.div`
  display: inline-block;
`;
export const TopRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
`;
export const AgencyName = React.memo(styled.div`
  max-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
`);
export const primaryInfoStyle = css`
  ${({ theme }) => `
  font-size: ${theme.fontSizes.epsilon}px;
  line-height: ${theme.fontSizes.epsilon}px;
  font-weight: ${theme.fontWeights.bold};
  padding: 0 ${theme.space[1]}px;
  text-align: center;
  @media (max-width: ${theme.breakpoints[0]}px) {
    font-size: ${theme.fontSizes.delta}px;
    line-height: ${theme.fontSizes.delta}px;
  }
  `}
`;
export const Price = React.memo(styled(Column)`
  ${primaryInfoStyle}
`);
