import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { faFileCsv } from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@material-ui/core";
import { debounce, get, isEmpty, sortBy } from "lodash";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setTableView, updateTableView } from "actions";

import AgGrid from "components/AgGrid/AgGrid";
import { BooleanFilterComponent } from "components/AgGrid/filterComponents/booleanFilter";
import { CompositeFilter } from "components/AgGrid/filters/compositeFilter";
import TableWrapper from "components/AgGrid/TableWrapper";
import { MultiButton, SlimSecondaryButton } from "components/Button";
import { DefaultMultiButton } from "components/Button/MultiButton";
import { SearchInput } from "components/Form";
import { Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import GlobalSearch from "components/SearchInput/GlobalSearch";

import { AgGridPanels, AgGridTables } from "constants/aggrid";
import { ApiModel } from "constants/loading";

import { CurrentAgencySelector } from "containers/ConnectedSelector";

import { EMPTY_ARRAY, EMPTY_OBJECT } from "lib";

import { getLivestockSaleId } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import { getSavedViewsByTable, getTableSetting } from "selectors";

import { useIsMobile } from "hooks";
import { useHandleAgGridColumnDefs } from "hooks/useHandleAgGridColumnDefs";

import { AutoComplete } from "./AutoComplete";
import SavedViewsPanel from "./panels/SavedViewsPanel";
import { ViewSelector } from "./ViewSelector";

export const globalDefaultColDef = {
  sortable: true,
  resizable: true,
  filter: "agMultiColumnFilter",
  enableCellChangeFlash: true,
  floatingFilter: true,
};

const components = {
  [CompositeFilter.frameworkComponentName]: CompositeFilter,
  [BooleanFilterComponent.frameworkComponentName]: BooleanFilterComponent,
  autoCompleteEditorDeprecated: AutoComplete,
};

const ignoredSaveEventSources = [
  "api",
  "autosizeColumns",
  "gridOptionsChanged",
];

const selectableTables = [
  AgGridTables.MONEY,
  AgGridTables.BILLING_DOCUMENTS_EXPORT,
  AgGridTables.REPORT_JOB,
];

const excelStyles = [
  {
    id: "horizontal-center",
    alignment: {
      horizontal: "Center",
    },
  },

  {
    id: "bold",
    font: {
      bold: true,
    },
  },
];

function applyTableStateChanges(api, state, filterModel) {
  if (!api) {
    return;
  }
  const { columnModel, filterManager } = api;

  columnModel.applyColumnState({
    state,
    applyOrder: true,
    defaultState: { rowGroup: false, hide: true }, // If the column isn't defined in the saved view, this is what it falls to.
  });

  // Allow setting null - it means nothing, or an old saved view with no filters.
  filterManager.setFilterModel(filterModel);

  // Additionally flag an external filters as changed, so that the grid will reapply the external filter.
  filterManager.onFilterChanged();
}

function useAgGridViewStateStore(agGridInstance, tableName) {
  const dispatch = useDispatch();
  const tableSettings = useSelector(getTableSetting(tableName));
  const tableViewInvalidationCount = tableSettings?.viewInvalidationCount;
  const tableState = tableSettings?.state;
  const tableFilters = tableSettings?.filters;
  const tableViewId = tableSettings?.viewId || null;

  const { id: defaultSavedViewId = null } =
    useSelector(state =>
      getSavedViewsByTable(tableName)(state)?.find(view => view.isGlobal),
    ) || {};

  const lastViewIdRef = useRef(tableViewId || null);
  const lastViewSettingsRef = useRef(tableSettings);
  const lastTableViewInvalidationCountRef = useRef(tableViewInvalidationCount);

  useEffect(() => {
    if (!agGridInstance?.api) {
      return;
    }

    // When the view (id) changes or when the view has explicitly been invalidated, apply the view in the redux state.
    if (
      lastViewIdRef.current !== tableViewId ||
      lastTableViewInvalidationCountRef.current !== tableViewInvalidationCount
    ) {
      // We are only interested in firing off the hook if the `invalidationCount` or the `viewId` has changed.
      // If they do, that's when we forcibly apply the view state (incl. filters) to the agGrid instance
      applyTableStateChanges(agGridInstance.api, tableState, tableFilters);

      lastViewIdRef.current = tableViewId;
      lastViewSettingsRef.current = tableSettings;
      lastTableViewInvalidationCountRef.current = tableViewInvalidationCount;
    }
  }, [
    agGridInstance,
    dispatch,
    lastTableViewInvalidationCountRef,
    lastViewIdRef,
    tableFilters,
    tableName,
    tableSettings,
    tableState,
    tableViewId,
    tableViewInvalidationCount,
  ]);

  // Additional callback to set the view state stored in Redux to Ag Grid when the GridReady event is fired.
  const onGridReady = useCallback(
    agGridInstance => {
      // When the users has an existing view, apply the view state (incl. filters) to the agGrid instance
      if (tableState || tableFilters || tableViewId) {
        applyTableStateChanges(agGridInstance.api, tableState, tableFilters);
      } else if (defaultSavedViewId) {
        // Otherwise apply the table's default
        dispatch(setTableView(tableName, defaultSavedViewId));
      }
    },
    [
      defaultSavedViewId,
      dispatch,
      tableFilters,
      tableName,
      tableState,
      tableViewId,
    ],
  );

  const saveTableState = useCallback(
    event => {
      // If the grid is ready and we get an event that is from the user, save the new column state.
      if (agGridInstance && !ignoredSaveEventSources.includes(event.source)) {
        const colState = agGridInstance.columnApi.getColumnState();
        const filterModel = agGridInstance.api.getFilterModel();
        // Dispatch the change to state if our columns or filters have changed.
        dispatch(
          updateTableView(tableName, tableViewId, {
            state: colState,
            filters: isEmpty(filterModel) ? {} : filterModel,
          }),
        );
      }
    },
    [agGridInstance, dispatch, tableName, tableViewId],
  );

  return {
    onGridReady,
    saveTableState,
  };
}

const AgGridTable = ({
  additionalActions,
  aggFuncs,
  doesExternalFilterPass,
  context = EMPTY_OBJECT,
  defaultColDef = globalDefaultColDef,
  columnDefs,
  isExternalFilterPresent = undefined,
  isRowSelectable = undefined,
  rowData,
  downloadFilename,
  tableName,
  rowSelection = "single",
  rowMultiSelectWithClick = false,
  onRowSelectionChanged = null,
  suppressRowClickSelection = false,
  editSelected = null,
  // This parameter should be retired. We can use the available `id` attribute of the rowNode
  rowSelectionId = "id",
  getRowId = undefined,
  onFilterChangedExtra,
  extraHeaderComponents = EMPTY_ARRAY,
  getRowStyle,
  groupDisplayType,
  groupIncludeFooter = false,
  groupIncludeTotalFooter = false,
  suppressAggFuncInHeader = true,
  getContextMenuItems = undefined,
  onCellValueChanged = undefined,
  onGridReady,
  pagination = true,
  paginationPageSize = 200,
  paginationAutoPageSize = false,
  statusBar = undefined,
  stopEditingWhenCellsLoseFocus = undefined,
  singleClickEdit = undefined,
  showGlobalSearchFilter = true,
  showTextFilter = false,
  showSimpleAgencyFilter = false,
  panels = null,
  rowClassRules = undefined,
  csvExportOptions = EMPTY_OBJECT,
  isGroupOpenByDefault,
  headerJustifyContent = "space-around",
  customExport = null,
  hideHeader = false,
  WrapperComponent = TableWrapper,
  masterDetail = false,
  detailCellRendererParams = EMPTY_OBJECT,
  hideSideBar = false,
  groupSelectsChildren = false,
  embedFullWidthRows = false,
  getRowHeight = undefined,
  rowBuffer = 10,
  suppressColumnVirtualisation = false,
  cacheQuickFilter = false,
  hasExportCsvPermissions = true,
  suppressCellSelection = false,
  hideSavedViews = false,
  multiSelectActionOnly = false,
  defaultCsvExportParams = EMPTY_OBJECT,
  rowsSelectable = false,
  enableBrowserTooltips = undefined,
  defaultToolPanel = null,
  multiButtonComponent = DefaultMultiButton,
}) => {
  const [agGridInstance, setAgGridInstance] = useState(null);
  const { onGridReady: tableStateOnGridReady, saveTableState } =
    useAgGridViewStateStore(agGridInstance, tableName);

  const isMobile = useIsMobile();

  const quickFilterDebounceTime = 400;

  const sortedColumnDefs = useMemo(() => {
    return sortBy(columnDefs, "headerName");
  }, [columnDefs]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [quickFilterText, setQuickFilterText] = useState("");
  const [quickFilterValue, setQuickFilterValue] = useState("");
  const onQuickFilterChange = value => {
    setQuickFilterText(value);
    debounce(() => setQuickFilterValue(value), quickFilterDebounceTime)();
  };

  // update column defs based if we are on mobile
  const handledColumnDefs = useHandleAgGridColumnDefs(
    columnDefs,
    agGridInstance,
    tableName,
    // TODO - https://agrinous.atlassian.net/browse/RD-456 - remove tableName check, after which any table that needs check boxes only needs to have rowsSelectable passed in
    rowsSelectable && selectableTables.includes(tableName),
  );

  const sideBar = useMemo(() => {
    if (hideSideBar) {
      return null;
    }
    const sideBarPanels = {
      toolPanels: [
        {
          id: AgGridPanels.COLUMNS,
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            // tool panel columns won't move when columns are reordered in the grid
            suppressSyncLayoutWithGrid: true,
            // prevents columns being reordered from the columns tool panel
            suppressColumnMove: true,
          },
        },
        {
          id: AgGridPanels.FILTERS,
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },

        {
          id: AgGridPanels.SAVED_VIEWS,
          labelDefault: "Views",
          labelKey: "savedViews",
          iconKey: "save",
          toolPanel: SavedViewsPanel,
          toolPanelParams: {
            tableName,
          },
        },
      ],
      defaultToolPanel,
    };
    if (panels) {
      sideBarPanels.toolPanels.push(...panels);
    }
    return sideBarPanels;
  }, [hideSideBar, tableName, panels, defaultToolPanel]);

  const onInternalGridReady = useCallback(
    agGrid => {
      setAgGridInstance(agGrid);

      tableStateOnGridReady(agGrid);

      typeof onGridReady === "function" && onGridReady(agGrid);

      if (sideBar) {
        const columnsToolPanel = agGrid.api.getToolPanelInstance("columns");
        columnsToolPanel.setColumnLayout(sortedColumnDefs);
      }
    },
    [onGridReady, sideBar, sortedColumnDefs, tableStateOnGridReady],
  );

  const onFilterChanged = useCallback(
    event => {
      typeof onFilterChangedExtra === "function" && onFilterChangedExtra(event);
      saveTableState(event);
    },
    [onFilterChangedExtra, saveTableState],
  );

  const handleSelectionChanged = useCallback(() => {
    // Pull the range of rows out, and pass it upward.
    if (agGridInstance) {
      // The selected nodes are not ordered as they are displayed
      const selectedNodes = agGridInstance.api.getSelectedNodes();

      // We will need to map the selected nodes to the ordered node list
      const selectedNodesById = selectedNodes.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      const selectedSortedRows = [];
      agGridInstance.api.forEachNodeAfterFilterAndSort(node => {
        if (selectedNodesById[node.id]) {
          selectedSortedRows.push(node.data);
        }
      });

      setSelectedRowIds(
        selectedSortedRows.map(sr => get(sr, rowSelectionId)).filter(Boolean),
      );
      typeof onRowSelectionChanged === "function" &&
        onRowSelectionChanged(selectedSortedRows);
    }
  }, [agGridInstance, onRowSelectionChanged, rowSelectionId]);

  const handleModelUpdated = useCallback(() => {
    // If the underlying data "changes" (that is, it's the same data/same id, but the object presenting it is changed),
    // make sure any selected items  had some items selected, make sure they are still
    // selected.
    if (agGridInstance && selectedRowIds.length > 0) {
      agGridInstance.api.forEachNode(node => {
        if (node.data) {
          node.setSelected(
            selectedRowIds.includes(get(node.data, rowSelectionId)),
          );
        }
      });
    }
  }, [agGridInstance, rowSelectionId, selectedRowIds]);

  const onBtnExport = useCallback(() => {
    const params = {
      fileName: downloadFilename,
      ...csvExportOptions,
    };
    if (agGridInstance.api.getLastDisplayedRow() !== -1) {
      agGridInstance.api.exportDataAsCsv(params);
    }
  }, [agGridInstance, downloadFilename, csvExportOptions]);

  const additionalActionButtons = useMemo(() => {
    const actions = [...(additionalActions || [])];
    if (hasExportCsvPermissions) {
      actions.push(
        ...(customExport || [
          {
            title: "Export CSV",
            isDisabled: !agGridInstance,
            onClick: onBtnExport,
            icon:
              multiButtonComponent === DefaultMultiButton ? faFileCsv : null,
          },
        ]),
      );
    }
    return actions;
  }, [
    agGridInstance,
    additionalActions,
    onBtnExport,
    customExport,
    hasExportCsvPermissions,
    multiButtonComponent,
  ]);

  const sharedContext = useMemo(
    () => ({
      ...context,
      rowsSelectable,
      tableName,
    }),
    [context, rowsSelectable, tableName],
  );

  return (
    <>
      <Box
        display={hideHeader ? "none" : "flex"}
        flexWrap="wrap"
        justifyContent={headerJustifyContent}
      >
        {!hideSavedViews && (
          <Column data-tour="table-view" alignStart padding={1}>
            <ViewSelector tableName={tableName} />
          </Column>
        )}

        {showGlobalSearchFilter && getLivestockSaleId() && (
          <Column minWidth="160px" flexGrow padding={1} alignCenter>
            <GlobalSearch />
          </Column>
        )}

        {extraHeaderComponents && extraHeaderComponents}

        <Column padding={1} alignEnd>
          <MultiButton
            buttons={additionalActionButtons}
            actionsOnly={multiSelectActionOnly}
            ButtonComponent={multiButtonComponent}
          />
        </Column>

        {showSimpleAgencyFilter && (
          <WaitForSync requiredData={[ApiModel.AGENCIES]}>
            <Column padding={1}>
              <CurrentAgencySelector includeAll />
            </Column>
          </WaitForSync>
        )}

        {showTextFilter && (
          <Column padding={1} data-tour="quick-filter">
            <SearchInput
              value={quickFilterText}
              onChange={onQuickFilterChange}
            />
          </Column>
        )}

        {typeof editSelected === "function" && (
          <Column padding={1}>
            <SlimSecondaryButton
              data-tour={pluralize(
                `edit${selectedRowIds.length}SelectedItem`,
                selectedRowIds.length,
              )}
              onClick={editSelected}
              disabled={selectedRowIds.length === 0}
            >
              Edit {selectedRowIds.length} selected item
              {selectedRowIds.length !== 1 && "s"}
            </SlimSecondaryButton>
          </Column>
        )}
      </Box>

      <WrapperComponent>
        <AgGrid
          autoSizePadding={2}
          aggFuncs={aggFuncs}
          columnDefs={handledColumnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          getRowStyle={getRowStyle}
          quickFilterText={quickFilterValue}
          groupDisplayType={groupDisplayType}
          groupIncludeFooter={groupIncludeFooter}
          groupIncludeTotalFooter={groupIncludeTotalFooter}
          isGroupOpenByDefault={isGroupOpenByDefault}
          isExternalFilterPresent={isExternalFilterPresent}
          isRowSelectable={isRowSelectable}
          doesExternalFilterPass={doesExternalFilterPass}
          rowBuffer={rowBuffer}
          components={components}
          // Listen for events that we can save in the column def.
          onColumnMoved={saveTableState}
          onColumnVisible={saveTableState}
          onColumnPinned={saveTableState}
          onColumnPivotChanged={saveTableState}
          onColumnResized={debounce(saveTableState, 200)}
          onColumnRowGroupChanged={saveTableState}
          onColumnValueChanged={saveTableState}
          onFilterChanged={onFilterChanged}
          onFilterModified={saveTableState}
          onGridReady={onInternalGridReady}
          onSortChanged={saveTableState}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationAutoPageSize={paginationAutoPageSize}
          rowData={rowData === undefined ? EMPTY_ARRAY : rowData}
          statusBar={statusBar}
          suppressScrollOnNewData
          suppressAggFuncInHeader={suppressAggFuncInHeader}
          rowSelection={rowSelection}
          suppressRowClickSelection={suppressRowClickSelection}
          onSelectionChanged={handleSelectionChanged}
          rowMultiSelectWithClick={rowMultiSelectWithClick}
          onModelUpdated={handleModelUpdated}
          enableCellTextSelection
          context={sharedContext}
          sideBar={sideBar}
          rowGroupPanelShow="onlyWhenGrouping"
          getContextMenuItems={getContextMenuItems}
          onCellValueChanged={onCellValueChanged}
          stopEditingWhenCellsLoseFocus={stopEditingWhenCellsLoseFocus}
          singleClickEdit={singleClickEdit}
          rowClassRules={rowClassRules}
          masterDetail={masterDetail}
          detailCellRendererParams={detailCellRendererParams}
          groupSelectsChildren={groupSelectsChildren}
          // TODO: Remove When AgGrid enable a way to use property names properly
          // https://github.com/ag-grid/ag-grid/issues/2320
          suppressPropertyNamesCheck
          embedFullWidthRows={embedFullWidthRows}
          getRowHeight={getRowHeight}
          suppressColumnVirtualisation={suppressColumnVirtualisation}
          cacheQuickFilter={cacheQuickFilter}
          excelStyles={excelStyles}
          suppressCellSelection={suppressCellSelection}
          suppressDragLeaveHidesColumns={!!isMobile}
          suppressCsvExport={!hasExportCsvPermissions}
          suppressExcelExport={!hasExportCsvPermissions}
          defaultCsvExportParams={defaultCsvExportParams}
          enableBrowserTooltips={enableBrowserTooltips}
        />
      </WrapperComponent>
    </>
  );
};

AgGridTable.propTypes = {
  rowData: PropTypes.any,
  context: PropTypes.object,
  downloadFilename: PropTypes.string,
  columnDefs: PropTypes.array,
  isExternalFilterPresent: PropTypes.func,
  doesExternalFilterPass: PropTypes.func,
  tableName: PropTypes.string,
};

export default AgGridTable;
