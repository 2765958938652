import React from "react";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { LargeFaIcon } from "components/FaIcon";
import { Column } from "components/Layout";
import { Paper } from "components/Paper";
import { BigText } from "components/Text";

import { ScanningPenStatusColourMap } from "constants/auctionPens";

import { scanLotConfig } from "lib/scanLot";

import { StatusWrapper } from "./elements";
import { ScanningPenCard } from "./Singular";

export function PenGroup(props) {
  const { index, viewAllPens, penType, isOpen, setOpenCard } = props;
  const {
    getPenGroupByIndex,
    getUsedPenGroupByIndex,
    selectUsedLotIdsByPenIdLookup,
  } = scanLotConfig(penType);

  const penGroup = useSelector(getPenGroupByIndex(index));
  const usedPenGroup = useSelector(getUsedPenGroupByIndex(index));

  const {
    pens,
    prefixes,
    status,
    firstPenNumber,
    lastPenNumber,
    emptyPenCount,
    totalPenCount,
    isFreeForm,
    quantity,
  } = viewAllPens ? penGroup : usedPenGroup;

  const usedLotIdsByPenIdLookup = useSelector(selectUsedLotIdsByPenIdLookup);

  const populatedPens = pens.filter(
    pen => usedLotIdsByPenIdLookup[pen.penId]?.length,
  );

  const visiblePens = viewAllPens ? pens : populatedPens;

  const handleOpenCard = () => {
    if (isOpen) {
      setOpenCard(undefined);
    } else {
      setOpenCard(index);
    }
  };

  return (
    <StatusWrapper color={ScanningPenStatusColourMap[status]}>
      <Paper>
        <Grid container alignItems="center" onClick={handleOpenCard}>
          <Grid item xs={2}>
            <BigText>
              {isFreeForm
                ? "Free Form"
                : Array.from(prefixes).filter(Boolean).join(", ")}
            </BigText>
          </Grid>
          <Grid item xs={3}>
            <BigText>
              {isFreeForm ? "" : `${firstPenNumber} - ${lastPenNumber}`}
            </BigText>
          </Grid>
          <Grid item xs={3}>
            <h4>{quantity} Hd</h4>
          </Grid>
          <Grid item xs={3}>
            <h4>
              {emptyPenCount} / {totalPenCount} Empty
            </h4>
          </Grid>
          <Grid
            item
            xs={1}
            container
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <LargeFaIcon
              icon={isOpen ? faChevronUp : faChevronDown}
              color="titleMedium"
            />
          </Grid>
        </Grid>
        {isOpen ? (
          <Column padding={2} background="gray95">
            {visiblePens.map(penDetails => {
              const { penId, penArchetypeId } = penDetails;
              return (
                <ScanningPenCard
                  penId={penId}
                  penArchetypeId={penArchetypeId}
                  key={`${penId}__${penArchetypeId}`}
                  penType={penType}
                />
              );
            })}
          </Column>
        ) : null}
      </Paper>
    </StatusWrapper>
  );
}
