import reduceReducers from "reduce-reducers";

import { MANUAL_ADJUSTMENT } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
} from "lib/reducers";
import { deserializeManualAdjustment } from "lib/serializers/manualAdjustments";

const create = apiModelOfflineCreateReducer(MANUAL_ADJUSTMENT, {
  deserializer: deserializeManualAdjustment,
});

const fetch = apiModelOfflineFetchReducer(MANUAL_ADJUSTMENT, {
  deserializer: deserializeManualAdjustment,
});

const update = apiModelOfflineUpdateReducer(MANUAL_ADJUSTMENT, {
  deserializer: deserializeManualAdjustment,
});

const deleteReducer = apiModelOfflineDeleteReducer(MANUAL_ADJUSTMENT);

const manualAdjustmentsReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
);

export default manualAdjustmentsReducer;
