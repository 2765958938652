import React from "react";

import { useSelector } from "react-redux";

import SingleWeighStatusIcon from "components/Icons/SingleWeighStatusIcon";

import { GateStatus } from "constants/auctionPens";
import { SingleWeighStatus } from "constants/singleWeighIconStatus";

import { getAuctionPenById } from "selectors";

export function SingleWeighIcon({ autoDraftPenId }) {
  const autoDraftState = useSelector(
    state => getAuctionPenById(autoDraftPenId)(state).autoDraftState,
  );
  let status = SingleWeighStatus.UNKNOWN;
  if (!autoDraftState) {
    status = SingleWeighStatus.UNKNOWN;
  } else if (
    autoDraftState.entryGateStatus === GateStatus.CLOSED &&
    autoDraftState.exitGateStatus === GateStatus.CLOSED
  ) {
    status = SingleWeighStatus.FRONT_GATE_CLOSED_BACK_GATE_CLOSED;
  } else if (
    autoDraftState.entryGateStatus === GateStatus.CLOSED &&
    autoDraftState.exitGateStatus === GateStatus.OPEN
  ) {
    status = SingleWeighStatus.FRONT_GATE_CLOSED_BACK_GATE_OPEN;
  } else if (
    autoDraftState.entryGateStatus === GateStatus.CLOSED &&
    autoDraftState.exitGateStatus === GateStatus.LOCKED
  ) {
    status = SingleWeighStatus.FRONT_GATE_CLOSED_BACK_GATE_LOCKED;
  } else if (
    autoDraftState.entryGateStatus === GateStatus.LOCKED &&
    autoDraftState.exitGateStatus === GateStatus.CLOSED
  ) {
    status = SingleWeighStatus.FRONT_GATE_LOCKED_BACK_GATE_CLOSED;
  } else if (
    autoDraftState.entryGateStatus === GateStatus.LOCKED &&
    autoDraftState.exitGateStatus === GateStatus.OPEN
  ) {
    status = SingleWeighStatus.FRONT_GATE_LOCKED_BACK_GATE_OPEN;
  } else if (
    autoDraftState.entryGateStatus === GateStatus.LOCKED &&
    autoDraftState.exitGateStatus === GateStatus.LOCKED
  ) {
    status = SingleWeighStatus.FRONT_GATE_LOCKED_BACK_GATE_LOCKED;
  } else if (
    autoDraftState.entryGateStatus === GateStatus.OPEN &&
    autoDraftState.exitGateStatus === GateStatus.CLOSED
  ) {
    status = SingleWeighStatus.FRONT_GATE_OPEN_BACK_GATE_CLOSED;
  } else if (
    autoDraftState.entryGateStatus === GateStatus.OPEN &&
    autoDraftState.exitGateStatus === GateStatus.OPEN
  ) {
    status = SingleWeighStatus.FRONT_GATE_OPEN_BACK_GATE_OPEN;
  } else if (
    autoDraftState.entryGateStatus === GateStatus.OPEN &&
    autoDraftState.exitGateStatus === GateStatus.LOCKED
  ) {
    status = SingleWeighStatus.FRONT_GATE_OPEN_BACK_GATE_LOCKED;
  }

  return <SingleWeighStatusIcon status={status} size={50} />;
}
