import React, { memo, useEffect, useState } from "react";

import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";

import ConsignmentDetailHeader from "components/ScanningScreen/ConsignmentDetailHeader";
import { EmptyStateText } from "components/ScanningScreen/Elements";
import SaleLotDetailHeader from "components/ScanningScreen/SaleLotDetailHeader";
import ScanLotScanningHeader from "components/ScanningScreen/ScanLotScanningHeader";

import { scanLotConfig } from "lib/scanLot";

import { getUploadedScans } from "selectors";

import { useBoolean } from "hooks";
import { useGetReceivalScannedDeploymentIds } from "hooks/useGetReceivalScannedDeploymentIds";

import FileImportColumn from "./FileImportColumn";
import { UploadScanFile } from "./UploadScanFile";

const MESSAGE_NO_EIDS = "No EIDs were imported";

export function FileImportComponent(props) {
  const {
    consignmentId,
    onCancel,
    onOpenClearModal,
    onOpenClearSavedModal,
    onOpenCreateBulkModal,
    onOpenCreateModal,
    onOpenSelectModal,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    scanListType,
  } = props;
  const [openSessionName, setOpenSessionName] = useState(null);
  const uploadedScans = useSelector(getUploadedScans);
  const uploadedScansList = Object.values(uploadedScans);

  const { getEidsByLotId } = scanLotConfig(penType);

  const scanLotEids = useSelector(getEidsByLotId(scanLotId)).filter(Boolean);

  const [duplicateEids, setDuplicateEids] = React.useState([]);

  const [
    isScanDiscrepancyDialogVisible,
    showScanDiscrepancyDialog,
    hideScanDiscrepancyDialog,
  ] = useBoolean(false);

  const sessionNames = uploadedScansList.reduce((acc, curr) => {
    if (acc.indexOf(curr.sessionName) === -1) {
      acc.push(curr.sessionName);
    }
    return acc;
  }, []);

  const hasEids = sessionNames.length > 0;

  React.useEffect(() => {
    // if there are no duplicate eids and duplicate eids are set in state - clear them
    if (!hasEids && duplicateEids.length) {
      setDuplicateEids([]);
    }
  }, [hasEids, duplicateEids]);

  // Open up a draft if there isn't one set
  useEffect(() => {
    // when a draft is selected, but is not in the list of available ones,
    // e.g. after a draft has been deleted
    const selectedSessionNoLongerExists =
      openSessionName !== null &&
      sessionNames.length > 0 &&
      !sessionNames.includes(openSessionName);

    // when no draft is selected, but there is one available to select
    const noDefaultSessionSelected =
      openSessionName === null && sessionNames.length > 0;

    if (selectedSessionNoLongerExists || noDefaultSessionSelected) {
      // get the first available draft name
      const sessionName = sessionNames[0];
      setOpenSessionName(sessionName);
    }
  }, [sessionNames, openSessionName, setOpenSessionName]);

  const receivalScannedDeploymentIds = useGetReceivalScannedDeploymentIds(
    scanLotEids.concat(Object.keys(uploadedScans)),
  );

  if (!hasEids) {
    return (
      <>
        <ConsignmentDetailHeader consignmentId={consignmentId} />
        <SaleLotDetailHeader saleLotId={saleLotId} />

        <ScanLotScanningHeader
          penArchetypeId={penArchetypeId}
          penId={penId}
          scanLotId={scanLotId}
          penType={penType}
          showScanDiscrepancyDialog={showScanDiscrepancyDialog}
          duplicateEids={duplicateEids}
          receivalScannedDeploymentIds={receivalScannedDeploymentIds}
        />
        <UploadScanFile />
      </>
    );
  }

  return (
    <>
      <ConsignmentDetailHeader consignmentId={consignmentId} />
      <SaleLotDetailHeader saleLotId={saleLotId} />
      <ScanLotScanningHeader
        penArchetypeId={penArchetypeId}
        penId={penId}
        scanLotId={scanLotId}
        penType={penType}
        showScanDiscrepancyDialog={showScanDiscrepancyDialog}
        duplicateEids={duplicateEids}
        receivalScannedDeploymentIds={receivalScannedDeploymentIds}
      />
      {hasEids ? (
        sessionNames.map(sessionName => (
          <FileImportColumn
            consignmentId={consignmentId}
            closeSelf={onCancel}
            isSelected={openSessionName === sessionName}
            onOpenCreateBulkModal={onOpenCreateBulkModal}
            onOpenCreateModal={onOpenCreateModal}
            onOpenClearModal={onOpenClearModal}
            onOpenClearSavedModal={onOpenClearSavedModal}
            onOpenSelectModal={onOpenSelectModal}
            sessionName={sessionName}
            saleLotId={saleLotId}
            penArchetypeId={penArchetypeId}
            penId={penId}
            scanLotId={scanLotId}
            scanListType={scanListType}
            penType={penType}
            selectColumn={setOpenSessionName}
            isScanDiscrepancyDialogVisible={isScanDiscrepancyDialogVisible}
            showScanDiscrepancyDialog={showScanDiscrepancyDialog}
            hideScanDiscrepancyDialog={hideScanDiscrepancyDialog}
            setDuplicateEids={setDuplicateEids}
          />
        ))
      ) : (
        <EmptyStateText>{MESSAGE_NO_EIDS}</EmptyStateText>
      )}
    </>
  );
}
FileImportComponent.propTypes = {
  onCancel: PropTypes.func,
  onOpenClearModal: PropTypes.func,
  onOpenCreateModal: PropTypes.func,
  onOpenSelectModal: PropTypes.func,
  saleLotId: PropTypes.string,
};

export default memo(FileImportComponent);
