import React, { memo } from "react";

import PropTypes from "prop-types";

import { SubtleBadge } from "components/Badge";
import { CollapseSection } from "components/EIDsSection/CollapseSection";
import { FormCollapse } from "components/Form";
import { Row } from "components/Layout";

const header = (
  <Row>
    <SubtleBadge>EIDs</SubtleBadge>
  </Row>
);

function EIDsSectionComponent(props) {
  const { isOpen, onToggle, scans } = props;

  return (
    <FormCollapse isOpen={isOpen} onToggle={onToggle} header={header}>
      <CollapseSection scans={scans} />
    </FormCollapse>
  );
}
EIDsSectionComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  scans: PropTypes.arrayOf(PropTypes.object),
};

export default memo(EIDsSectionComponent);
