import React from "react";

import Dialog from "./dialog";

function CarrierChargeModalAdapterFactory(target, hashParamAccessor) {
  return function CarrierChargeModalAdapter(props) {
    const { hash } = props;
    const hashParams = hash[hashParamAccessor] || null;
    const [id, fullScreen] = JSON.parse(hashParams);
    const { returnTo } = hash;
    const decodedReturnTo = returnTo ? atob(returnTo) : "";
    return (
      <Dialog
        id={id}
        target={target}
        returnTo={decodedReturnTo}
        fullScreen={fullScreen}
        {...props}
      />
    );
  };
}

export default CarrierChargeModalAdapterFactory;
