import { uniq } from "lodash";
import { call, put, takeEvery } from "redux-saga/effects";

import {
  requestAllNvdUploadsError,
  requestAllNvdUploadsSuccess,
  requestNVDUploadError,
  requestNVDUploadSuccess,
} from "actions";

import {
  GET_ALL_NVD_UPLOADS,
  REFRESH_NVD_UPLOAD,
  SUBMIT_NVD_UPLOAD_OFFLINE,
} from "constants/actionTypes";

import { findErrorMessagesFromResponse } from "lib/errors";
import { getSaleUrl } from "lib/navigation";
import toast from "lib/toast";

import { api } from "./api";

function* getAllNvdUploads(action) {
  const saleUrl = getSaleUrl(action.sale);
  try {
    const promise = yield call(api.get, `${saleUrl}/nvd-upload/`);
    yield put(requestAllNvdUploadsSuccess(yield promise));
  } catch (e) {
    yield put(requestAllNvdUploadsError(e.statusText));
    toast.error(`Could not fetch NLIS NVD Uploads`);
  }
}

function* refreshNvdUpload(action) {
  const { nvdUploadId, sale } = action;
  const saleUrl = getSaleUrl(sale);
  try {
    const promise = yield call(
      api.get,
      `${saleUrl}/nvd-upload/${nvdUploadId}/`,
    );
    yield put(requestNVDUploadSuccess(yield promise));
  } catch (e) {
    yield put(requestNVDUploadError(e.statusText));
    toast.error(`Could not fetch NLIS NVD upload`);
  }
}

function alertNVDUploadFailure(e) {
  const errors = uniq(
    findErrorMessagesFromResponse(e.payload.response, [], [], false),
  );
  toast.error(
    `There was an error submitting the NVDs for upload: ${errors.join(", ")}`,
  );
}

function alertNVDUploadSuccess(action) {
  const { payload } = action;
  toast.info(
    `Created ${payload.length} NVD Upload${payload.length === 1 ? "" : "s"}`,
  );
}

export default function* nvdUploadSaga() {
  yield takeEvery(GET_ALL_NVD_UPLOADS.REQUEST, getAllNvdUploads);
  yield takeEvery(SUBMIT_NVD_UPLOAD_OFFLINE.FAILURE, alertNVDUploadFailure);
  yield takeEvery(SUBMIT_NVD_UPLOAD_OFFLINE.SUCCESS, alertNVDUploadSuccess);

  yield takeEvery(REFRESH_NVD_UPLOAD, refreshNvdUpload);
}
