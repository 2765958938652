import React from "react";

import ReactSelect, { components } from "react-select";
import ReactCreatableSelect from "react-select/creatable";
import { css } from "styled-components";
import styled from "styled-components/macro";

import { SmallText } from "components/Text";

export const selectControlContainerStyles = css`
  position: relative;
  box-sizing: border-box;
`;

export const selectMenuContainerStyles = css(
  ({ isHidden, theme }) => `
  background: ${theme.colors.white};
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #c8c8c8;
  ${isHidden ? "visibility: hidden;" : ""}
`,
);

export const selectMenuStyles = css(
  ({ theme }) => `
  background: ${theme.colors.white};
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: absolute;
  width: 100%;
  border: 2px solid ${theme.colors.grayF3};
  border-radius: 0px 0px 6px 6px;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1;
`,
);

export const selectItemSelectedStyles = css`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`;

export const selectItemDisabledStyles = css`
  background: ${({ theme }) => theme.colors.grayDisabled};
`;

export const selectItemActiveStyles = css`
  background: ${({ theme }) => theme.colors.primaryActive};
  color: ${({ theme }) => theme.colors.white};
`;

export const selectItemFocussedStyles = css`
  background: ${({ theme }) => theme.colors.primaryHighlight};
  color: ${({ theme }) => theme.colors.white};
`;

export const selectItemBaseStyles = css`
  list-style: none;
  margin-block: 0;
  margin-inline: 0;
  padding-inline: 0;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
`;

export const selectInputContainerStyles = css`
  flex: 1;
  display: flex;
`;

export const selectStyles = css`
  .react-select__control {
    flex: 1;
    border-radius: 2px;
    height: auto;
    min-height: 36px;

    color: ${({ theme }) => theme.colors.gray40};
    background: ${({ theme }) => theme.colors.white};
    ${({ error, theme }) =>
      error && `border: 1px solid ${theme.colors.inputError}`}

    border: 1px solid ${({ theme }) => theme.colors.gray78};
  }
  .react-select__control--is-focused {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  .react-select__value-container {
    color: ${({ theme }) => theme.colors.titleDark};
  }
  .react-select__control > .react-select__value .react-select__single-value {
    color: ${({ theme }) => theme.colors.titleDark};
  }

  .react-select__menu-portal {
    // Needs to be higher than the dialog zindex (1300)
    z-index: 2000 !important;
  }

  .react-select__menu {
    ${selectMenuContainerStyles}

    .react-select__option {
      transition: ${({ theme }) => theme.transitions[1]};
    }
    .react-select__option--is-focused {
      ${selectItemFocussedStyles}
    }
    .react-select__option--is-selected {
      ${selectItemSelectedStyles}
    }
    .react-select__control--is-disabled {
      ${selectItemDisabledStyles}
    }

    .react-select__option--is-focused:active,
    .react-select__option--is-selected:active,
    .react-select__option:active {
      ${selectItemActiveStyles}
    }
  }
`;

const inputDrowdownButtonStyles = css(
  ({ theme }) => `
  appearance: none;
  border-radius: 0 2px 2px 0;
  height: 36px;
  color: ${theme.colors.gray40};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray78};
  border-left: none;
  width: 36px;
`,
);

const commonOptionInputStyles = css(
  ({ error, theme }) => `
    flex: 1;
    border-radius: 2px;
    height: 36px;
    min-height: 36px;
    color: ${theme.colors.titleDark};
    background: ${theme.colors.white};
    padding: 0px 10px;
    ${error ? `border: 1px solid ${theme.colors.inputError};` : ""}
      
    border: 1px solid ${theme.colors.gray78};
    
    :focus {
      outline: none;
      border: 1px solid ${theme.colors.primary};
    }
    :disabled { 
      background: ${theme.colors.grayDisabled};
    }
  `,
);

export const InputSelect = styled.input(commonOptionInputStyles);
export const InputDropdownSelect = styled(InputSelect)`
  border-radius: 2px 0 0 2px;
  border-right: none;
  :focus {
    border-right: none;
  }
  /* Fix for iOS, search inputs have a round appearance */
  &[type="search"] {
    -webkit-appearance: none;
  }
`;
export const InputDrowdownButton = styled.input(inputDrowdownButtonStyles);
export const SelectControlContainer = styled.div`
  ${selectControlContainerStyles}
  &:focus-within ${InputDrowdownButton} {
    border: 1px solid #427db3;
    border-left: none;
  }
`;
export const SelectMenuContainer = styled.div(selectMenuContainerStyles);
export const SelectMenu = styled.ul(selectMenuStyles);
export const SelectItem = styled.li`
  ${selectItemBaseStyles}
  &.highlighted {
    ${selectItemActiveStyles}
  }
`;
export const SelectItemNoOptions = styled(SelectItem)`
  text-align: center;
`;

export function DescriptiveOption(props) {
  const {
    data: { label, description },
  } = props;
  return (
    <components.Option {...props}>
      {label}
      <br />
      <SmallText>{description}</SmallText>
    </components.Option>
  );
}

export const SelectSelect = styled.select(commonOptionInputStyles);

export const InputContainer = styled.div(selectInputContainerStyles);

const defaultReactSelectProps = props => {
  // TODO: One day when https://github.com/JedWatson/react-select/issues/4088 is fixed.
  // 2022-11-30 Looks as though it's fixed in react-select@5.5.0
  // const menuPortalTarget =
  //   props.menuPortalTarget ||
  //   document.getElementsByClassName("MuiDialog-root")?.[0] ||
  //   document.body;

  return {
    ...props,
    classNamePrefix: "react-select",
    // menuPortalTarget,
  };
};

/**
 * Finds an Option object (used by React Select in an array by its value.
 *
 * @typedef {Object} Option
 * @property {*} value - The value of the option.
 * @property {string} label - The label of the option.
 *
 * @param {Option[]} options - The array of option objects.
 * @param {*} value - The value to search for in the option objects.
 * @returns {Object|undefined} - The option object with the matching value, or undefined if not found.
 */
export function findOptionByValue(options, value) {
  return options.find(option => option.value === value);
}

const Select = props => <ReactSelect {...defaultReactSelectProps(props)} />;

export const CreatableSelect = props => (
  <ReactCreatableSelect {...defaultReactSelectProps(props)} />
);

export const FullWidthSelect = styled(ReactSelect)`
  width: 100%;
`;

export default Select;
