import { get } from "lodash";

import { saleLotCommentsColumnId } from "components/SaleLotsTable/columns/commentsColumn";
import {
  WeighLotColumnId,
  WeighLotScanColumnId,
  weighLotScanColumns,
} from "components/WeighScanning/Table/columnDefinitions";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import {
  PenScanLotColumnDefinitions,
  PenScanLotColumnId,
  ReceivalLotColId,
  ReceivalLotColumnDefinitions,
} from "constants/columnDefinitions";
import {
  PropertyColumnDefinitions,
  PropertyNamespaceColumnId,
} from "constants/columnDefinitions/property";
import { Column } from "constants/columns";
import { SaleyardPermissions } from "constants/permissions";
import { TransactionalSaleTypes } from "constants/sale";

import { createNamespacedColumnDefinitions, withNestedField } from "lib/agGrid";
import {
  BaseColumnDefinitions,
  speciesAccreditationChildColumns,
} from "lib/agGrid/columnDefinitions";
import { booleanRenderer } from "lib/agGrid/renderers/tickRenderer";
import {
  enableHeaderSelection,
  enableRowSelection,
} from "lib/agGrid/rowSelection";
import { hasPermission } from "lib/permissions";

import { actionsColumnRenderer } from "./columns/actionsColumn";

const scanSpeciesAccreditationChildColumns = speciesId =>
  speciesAccreditationChildColumns(speciesId, BaseColumnDefinitions);

export default function getScanColumnDefs(
  isBusinessUser,
  hasWriteAccessInSale,
  currentSaleyard,
  currentSale,
) {
  const saleType = currentSale?.saleType;
  const speciesId = currentSale?.species_id;
  const includeAllColumns = !TransactionalSaleTypes.includes(saleType);
  const hasReceivalLotPermission = hasPermission(
    currentSaleyard,
    SaleyardPermissions.featureReceivalLots,
  );
  const hasPenScanLotPermission = hasPermission(
    currentSaleyard,
    SaleyardPermissions.featurePenScanLots,
  );
  const hasWeighLotPermission = hasPermission(
    currentSaleyard,
    SaleyardPermissions.featureWeighLots,
  );

  let columns = [
    withNestedField(Column.PEN, "saleLot"),
    BaseColumnDefinitions[Column.AGENCY_NAME],
  ];

  if (!isBusinessUser) {
    columns.push(withNestedField(Column.VENDOR_NAME, "saleLot"));
    columns.push({
      headerName: "Vendor Property",
      children: Object.values(
        createNamespacedColumnDefinitions(
          PropertyColumnDefinitions,
          "saleLot.consignment.vendor_property",
          PropertyNamespaceColumnId.VENDOR_PROPERTY,
        ),
      ),
    });
  }

  if (hasWriteAccessInSale) {
    columns.push({
      headerName: ACTION_COLUMN_NAME,
      cellRenderer: actionsColumnRenderer,
      colId: ACTION_COLUMN_ID,
      width: 180,
      mobileWidth: 100,
      pinned: "right",
      menuTabs: ["columnsMenuTab"],
      checkboxSelection: enableRowSelection,
      headerCheckboxSelection: enableHeaderSelection,
      headerCheckboxSelectionFilteredOnly: true,
      suppressCellFlash: true,
    });
  }

  columns = columns.concat(
    [
      withNestedField(Column.VENDOR_NUM, "consignment"),
      BaseColumnDefinitions[Column.VENDOR_PIC],
      {
        ...BaseColumnDefinitions[Column.BUSINESS_DISPLAY_NAME],
        field: "saleLot.vendor.publicDisplayName",
        hide: true,
      },
      includeAllColumns
        ? withNestedField(Column.PIC_PROGRAMS, "consignment")
        : null,
      includeAllColumns
        ? withNestedField(Column.LPA_STATUS, "consignment")
        : null,
      BaseColumnDefinitions[Column.NVD],
      withNestedField(Column.QUANTITY, "saleLot"),
      {
        ...BaseColumnDefinitions[Column.TOTAL_MASS_GRAMS],
        field: `scan.total_mass_grams`,
      },
      {
        ...BaseColumnDefinitions[Column.TOTAL_MASS_GRAMS_OUTLIER],
        field: `scan.total_mass_grams`,
      },
      withNestedField(Column.AVERAGE_WEIGHT, "saleLot"),
      withNestedField(Column.AVERAGE_WEIGHT_OUTLIER, "saleLot"),
      withNestedField(Column.IS_MANUALLY_WEIGHED, "scan"),
      withNestedField(Column.SCANNED_COUNT, "saleLot"),
      withNestedField(Column.SCANNED_PERCENTAGE, "saleLot"),
      withNestedField(Column.EXEMPTION_REASON, "saleLot"),
      withNestedField(Column.EID, "scan"),
      withNestedField(Column.NLIS_ID, "scan.animal"),
      withNestedField(Column.SCAN_ERP_STATUS, "scan"),
      withNestedField(Column.EU_STATUS, "scan"),
      withNestedField(Column.CURRENT_PIC, "scan"),
      withNestedField(Column.LIFETIME_TRACEABILITY, "scan"),
      withNestedField(Column.BUYER_NAME, "saleLot"),
      withNestedField(Column.BUYER_WAY_NAME, "saleLot"),
      withNestedField(Column.SaleLot.PRICING_TYPE, "saleLot"),
      withNestedField(Column.SaleLot.UNIT_PRICE, "saleLot"),
      withNestedField(Column.LABELS, "saleLot"),
      withNestedField(Column.STATUS, "saleLot"),
      withNestedField(Column.DESTINATION_PIC, "saleLot"),
      includeAllColumns
        ? withNestedField(Column.DELIVERY_PEN, "saleLot")
        : null,
      withNestedField(Column.IS_NLIS_SIGHTED, "scan"),
      withNestedField(Column.SALEYARD_NAME, "livestockSale"),
      includeAllColumns ? BaseColumnDefinitions[Column.IS_TRANSFERRED] : null,
      includeAllColumns
        ? {
            headerName: "NLIS Take",
            marryChildren: true,
            children: [
              BaseColumnDefinitions[Column.NLIS_TAKE_ID],
              BaseColumnDefinitions[Column.NLIS_TAKE_TRANSFER_STATUS],
              BaseColumnDefinitions[Column.NLIS_TAKE_TRANSFER_MESSAGE],
              BaseColumnDefinitions[Column.NLIS_TAKE_STATUS],
              BaseColumnDefinitions[Column.NLIS_TAKE_TRANSFER_VENDOR_PIC],
              BaseColumnDefinitions[Column.NLIS_TAKE_TRANSFER_NVD],
              BaseColumnDefinitions[Column.IS_TAKEABLE],
            ],
          }
        : null,
      includeAllColumns
        ? {
            headerName: "NLIS Sell",
            marryChildren: true,
            children: [
              BaseColumnDefinitions[Column.NLIS_SELL_ID],
              BaseColumnDefinitions[Column.NLIS_SELL_TRANSFER_STATUS],
              BaseColumnDefinitions[Column.NLIS_SELL_TRANSFER_MESSAGE],
              BaseColumnDefinitions[Column.NLIS_SELL_STATUS],
              BaseColumnDefinitions[Column.NLIS_SELL_TRANSFER_BUYER_PIC],
              BaseColumnDefinitions[Column.IS_SELLABLE],
            ],
          }
        : null,
      {
        ...BaseColumnDefinitions[Column.DRAFT_DEVICE],
        field: "scanDraftingInformation",
      },
      {
        ...BaseColumnDefinitions[Column.DRAFT_NAME],
        field: `scanDraftingInformation.draftName`,
      },
      withNestedField(Column.CREATED, "scan"),
      withNestedField(Column.SCAN_TIME_WEIGHED, "scan"),
      withNestedField(Column.TIME_BULK_WEIGHED, "saleLot"),
      withNestedField(Column.BREED_NAME, "saleLot"),
      withNestedField(Column.SEX_NAME, "saleLot"),
      withNestedField(Column.AGE_GROUP_NAME, "saleLot"),
      withNestedField(Column.MARKS, "saleLot"),
      withNestedField(Column.LOT_NUMBER, "saleLot"),
      withNestedField(Column.LOT_NUMBER_SUFFIX, "saleLot"),
      withNestedField(Column.LOT_NUMBER_COMBINED, "saleLot"),
      withNestedField(Column.SALE_ROUND_NAME, "saleLot"),
      withNestedField(Column.BRANDS, "consignment"),
      {
        cellRenderer: booleanRenderer,
        enableRowGroup: true,
        colId: "buyer.isMeatProcessorCommercialBuyer",
        field: "buyer.isMeatProcessorCommercialBuyer",
        filter: "anBooleanColumnFilter",
        headerName: "Is Meat Buyer",
        width: 100,
      },
      {
        ...BaseColumnDefinitions[Column.IS_ANGUS_VERIFIED],
        field: "scan.animal.is_angus_verified",
      },
      !isBusinessUser && BaseColumnDefinitions[saleLotCommentsColumnId],
      {
        field: "nlisSaleyardCredentials.nlis_saleyard_id",
        headerName: "Saleyard PIC",
        hide: true,
      },
      {
        colId: "saleDate",
        headerName: "Sale Date",
        hide: true,
        field: "livestockSale.date",
        valueGetter: params => {
          const { colDef, data } = params;
          if (!data) {
            return null;
          }
          const value = get(data, colDef.field);

          return value
            ? `${value.slice(0, 4)}${value.slice(5, 7)}${value.slice(8, 10)}`
            : "";
        },
      },
      includeAllColumns
        ? {
            colId: "sellFile.saleType",
            headerName: "Sell File Sale Type",
            hide: true,
            valueGetter: () => "S",
          }
        : null,
      includeAllColumns
        ? {
            colId: "takeFile.saleType",
            hide: true,
            headerName: "Take File Sale Type",
            valueGetter: () => "P",
          }
        : null,

      {
        headerName: "P2P NWA Transfer",
        children: [
          {
            colId: "p2pFile.authorisationLevel",
            hide: true,
            headerName: "Authorisation Type",
            valueGetter: () => "3", // TODO link to user type
            valueFormatter: ({ value }) =>
              ({
                1: "Vendor Authorisation",
                2: "Buyer Authorisation",
                3: "Agent/Other Authorisation",
              })[value],
          },
          {
            colId: "p2pFile.declarationAccepted",
            hide: true,
            headerName: "Disclaimer Accepted",
            valueGetter: () => "1", // Todo prompt for MLA indemnity
            valueFormatter: ({ value }) =>
              ({
                0: "No",
                1: "Yes",
              })[value],
          },
        ],
      },
      {
        headerName: "Current User",
        children: [
          {
            colId: "currentUser.firstName",
            field: "currentUser.first_name",
            headerName: "First Name",
            hide: true,
          },
          {
            colId: "currentUser.lastName",
            field: "currentUser.last_name",
            headerName: "Last Name",
            hide: true,
          },
        ],
      },
      {
        headerName: "Drafting Attributes",
        children: scanSpeciesAccreditationChildColumns(speciesId),
      },
      {
        headerName: "Buyer Property",
        children: Object.values(
          createNamespacedColumnDefinitions(
            PropertyColumnDefinitions,
            "saleLot.destinationProperty",
            PropertyNamespaceColumnId.BUYER_PROPERTY,
          ),
        ),
      },
    ].filter(Boolean),
  );

  if (hasReceivalLotPermission) {
    columns.push({
      headerName: "Receival Lot",
      children: [
        ReceivalLotColumnDefinitions[ReceivalLotColId.PEN],
        ReceivalLotColumnDefinitions[ReceivalLotColId.MARK],
        ReceivalLotColumnDefinitions[ReceivalLotColId.SCANS_COUNT],
        ReceivalLotColumnDefinitions[ReceivalLotColId.QUANTITY],
        ReceivalLotColumnDefinitions[ReceivalLotColId.VENDOR_NAME],
        ReceivalLotColumnDefinitions[ReceivalLotColId.AGENCY],
        ReceivalLotColumnDefinitions[ReceivalLotColId.NVD],
        ReceivalLotColumnDefinitions[ReceivalLotColId.EDIT],
      ],
    });
  }

  if (hasPenScanLotPermission) {
    columns.push({
      headerName: "Pen Scan Lot",
      children: [
        PenScanLotColumnDefinitions[PenScanLotColumnId.PEN],
        PenScanLotColumnDefinitions[PenScanLotColumnId.MARKS],
        PenScanLotColumnDefinitions[PenScanLotColumnId.IS_LOCKED],
        PenScanLotColumnDefinitions[PenScanLotColumnId.SCANS_COUNT],
        PenScanLotColumnDefinitions[PenScanLotColumnId.QUANTITY],
        PenScanLotColumnDefinitions[PenScanLotColumnId.EDIT],
      ],
    });
  }

  if (hasWeighLotPermission) {
    columns.push({
      headerName: "Weigh Lot Scan",
      children: [
        weighLotScanColumns[WeighLotScanColumnId.STATUS],
        weighLotScanColumns[WeighLotScanColumnId.EID],
        weighLotScanColumns[WeighLotScanColumnId.TOTAL_MASS_GRAMS],
        weighLotScanColumns[WeighLotColumnId.WEIGH_LOT_NUMBER],
        weighLotScanColumns[WeighLotColumnId.SCALE_NAME],
      ],
    });
  }

  if (hasPenScanLotPermission || hasReceivalLotPermission) {
    columns.push({
      colId: "e2e693c1-2db0-42d9-a424-2a5e0d5dd435",
      field: "rowWarning",
      headerName: "Warning",
      width: 500,
    });
  }
  return columns;
}
