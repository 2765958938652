import React, { useState } from "react";

import { DialogActions } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { bulkUpdateSaleLotsSerializer } from "actions";

import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, SecondaryButton } from "components/Form";
import { DialogContent } from "components/MaterialDialog";

import { pluralize } from "lib/pluralize";

import { getSaleLots, getScans } from "selectors";

import { useToggle } from "hooks";

import { ConsignmentSelector } from "./SelectConsignment";

const defaultValues = { consignment_id: null };

export const SelectConsignmentForEids = ({ eids, onBack, onClose }) => {
  const [selectedConsignmentId, setSelectedConsignmentId] = useState(null);
  const [showConfirmUpdate, toggleShowConfirmUpdate] = useToggle(false);
  const allSaleLots = useSelector(getSaleLots);
  const dispatch = useDispatch();
  const scans = useSelector(getScans);
  const saleLotIds = new Set(
    eids.map(eid => scans[eid]?.sale_lot_id).filter(Boolean),
  );
  const saleLots = Array.from(saleLotIds)
    .map(saleLotId => allSaleLots[saleLotId])
    .filter(Boolean);
  const scansAffected = Object.values(scans).filter(s =>
    saleLotIds.has(s.sale_lot_id),
  );

  const totalQuantity = saleLots.reduce(
    (prev, curr) => prev + curr.quantity,
    0,
  );

  const onSelect = values => {
    setSelectedConsignmentId(values?.consignment_id);
  };
  const bulkUpdate = () => {
    const bulkPayload = saleLots.map(sl => ({
      id: sl.id,
      consignment_id: selectedConsignmentId,
    }));
    dispatch(
      bulkUpdateSaleLotsSerializer(bulkPayload, {
        actionText: "Consignment Bulk Update",
        changeReason: "Bulk updated by EID filter",
      }),
    );
    onClose();
  };
  const scansPlural = pluralize("Scan", eids.length);
  const affectedScansPlural = pluralize("Scan", scansAffected.length);
  const affectedLotssPlural = pluralize("Sale Lot", saleLots.length);
  const affectedLotsPre = saleLots.length > 1 ? "these" : "this";
  /*
  ”
  */
  return (
    <>
      <ConfirmDialog
        isOpen={showConfirmUpdate}
        onCancel={toggleShowConfirmUpdate}
        onDelete={() => {
          bulkUpdate();
          toggleShowConfirmUpdate();
        }}
        buttonMessage="Confirm"
        message={`WARNING: ${scansAffected.length} ${affectedScansPlural} in total will be affected.
            Applying this action for the ${eids.length} selected ${scansPlural} will affect ${saleLots.length} ${affectedLotssPlural}.
            The consignment for ${affectedLotsPre} ${saleLots.length} ${affectedLotssPlural} will be updated. Do you want to continue?`}
        title="Update Sale Lots"
      />
      <Formik initialValues={defaultValues} onSubmit={() => {}}>
        <Form>
          <DialogContent minHeight="20vh" shrink={1} dividers>
            <DialogContentText>
              Select the consignment to update all sale lots connected with the
              above {scansPlural}
            </DialogContentText>
            <ConsignmentSelector handleReInit={onSelect} />
            <DialogContentText>
              This will update {saleLots.length} sale lot(s) (total head:{" "}
              {totalQuantity})
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {typeof onBack === "function" ? (
              <SecondaryButton onClick={onBack}>Back</SecondaryButton>
            ) : null}
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
            <Button
              data-tour="updateSalelotsConsignment"
              onClick={toggleShowConfirmUpdate}
              disabled={selectedConsignmentId === null}
            >
              Move to consignment
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </>
  );
};
