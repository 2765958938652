import React from "react";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";

import SaleModal from "containers/SaleModal";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

const SaleModalAdapter = props => {
  const { hash } = props;
  const { returnTo } = hash;
  const hashParams = hash[ModalTypes.Sale] || null;
  const [saleId] = JSON.parse(hashParams);

  const closeSelf = () => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  };

  return (
    <WaitForSync
      requiredData={[
        ApiModel.ROUNDS,
        ApiModel.SPECIES,
        ApiModel.DEPLOYMENTS,
        ApiModel.SALE_DEFINITIONS,
        ApiModel.AGENCIES,
      ]}
    >
      <SaleModal closeSelf={closeSelf} saleId={saleId} />
    </WaitForSync>
  );
};

export default SaleModalAdapter;
