import React from "react";

import { SelectItem } from "components/SearchableSelector/ReactSelect";

const MAX_ITEMS = 200;

export function AuctionsPlusMakeResultsList(props) {
  const { getItemProps, highlightedIndex, inputItems } = props;

  const renderedItems = [];

  const renderItemCount = Math.min(inputItems.length, MAX_ITEMS);

  for (let index = 0; index < renderItemCount; index++) {
    const result = inputItems[index];

    renderedItems.push(
      <SelectItem
        className={index === highlightedIndex ? "highlighted" : ""}
        key={`${index}-${result}`}
        {...getItemProps({ index })}
      >
        {result}
      </SelectItem>,
    );
  }
  return renderedItems;
}
