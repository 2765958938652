import React from "react";

import { isArray, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { FullScreenContainer } from "components/FullScreenContainer";
import { ReactSelect } from "components/SearchableSelector";

import { Settings } from "constants/settings";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import {
  getLastSeenSaleLotsByDraftingId,
  getSetting,
  getAuctionPens,
} from "selectors";

import DraftingInformationSwitcher from "./DraftingInformationSelector";
import {
  AverageWeight,
  BoundingBox,
  CentsPerKilo,
  DollarsPerHead,
} from "./WatcherComponents";

const HighContrastTable = styled.table(
  ({ theme }) => `
  border-collapse: collapse;
  font-size: ${theme.fontSizes.theta}px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  
  width: 100%;
  height: 100%;
  th {
    width: 33%;
    color: ${theme.colors.black};
  }
  td {
    border: 1px solid ${theme.colors.black};
    width: 33%;
    padding 0 ${theme.space[2]}px;
    font-weight: bold; 
  }
  
  `,
);

const SizedSelect = styled(ReactSelect)`
  width: 100%;
`;

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
};

const penSelectionOptions = [
  { label: "PEN", value: "auction_pen_id" },
  { label: "D-PEN", value: "deliveryPenId" },
  { label: "PEN | D-PEN", value: ["auction_pen_id", "deliveryPenId"] },
];
const resolvePenDisplay = (saleLot, selectedOption, auctionPenLookup) => {
  const { value: selectedOptionValue } = selectedOption;
  if (saleLot && !isEmpty(saleLot)) {
    if (isArray(selectedOptionValue)) {
      return selectedOptionValue.map((value, idx) =>
        "".concat(
          `${
            getAuctionPenDisplayName(auctionPenLookup[saleLot[value]]) || "-"
          } ${selectedOptionValue.length > 1 && idx === 0 ? " | " : ""}`,
        ),
      );
    } else {
      return `${
        getAuctionPenDisplayName(
          auctionPenLookup[saleLot[selectedOptionValue]],
        ) || "-"
      }`;
    }
  } else {
    return "-";
  }
};

const PenViewSelector = ({ selectedOption, setSelectedOption }) => {
  return (
    <SizedSelect
      isClearable={false}
      value={selectedOption}
      options={penSelectionOptions}
      onChange={selectedOption => setSelectedOption(selectedOption)}
      menuPlacement="top"
      menuPortalTarget={document.body}
      styles={customStyles}
    />
  );
};

const LastWeighedTableBody = ({
  onScalesLot,
  lastWeighedLot,
  detailed = false,
}) => {
  const [selectedOption, setSelectedOption] = React.useState(
    penSelectionOptions[0],
  );
  const auctionPenLookup = useSelector(getAuctionPens);

  return (
    <tbody>
      <tr>
        <td style={{ padding: "0px" }}>
          <PenViewSelector
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </td>
        <td className="current">
          {resolvePenDisplay(onScalesLot, selectedOption, auctionPenLookup) ||
            "-"}
        </td>
        <td className="lastSold">
          {resolvePenDisplay(
            lastWeighedLot,
            selectedOption,
            auctionPenLookup,
          ) || "-"}
        </td>
      </tr>

      <tr>
        <td>MARK</td>
        <td className="current">
          {onScalesLot.marks?.map(mark => mark.location).join("/") || "-"}
        </td>
        <td className="lastSold">
          {lastWeighedLot.marks?.map(mark => mark.location).join("/") || "-"}
        </td>
      </tr>
      <tr>
        <td>HEAD</td>
        <td className="current">{onScalesLot.quantity || "-"}</td>
        <td className="lastSold">{lastWeighedLot.quantity || "-"}</td>
      </tr>
      <tr>
        <td>BUYER</td>
        <td className="current">{onScalesLot.buyer?.name || "-"}</td>
        <td className="lastSold">{lastWeighedLot.buyer?.name || "-"}</td>
      </tr>
      <tr>
        <td>ACCOUNT</td>
        <td className="current">{onScalesLot.buyer_way?.name || "-"}</td>
        <td className="lastSold">{lastWeighedLot.buyer_way?.name || "-"}</td>
      </tr>
      {detailed ? (
        <>
          <tr>
            <td>&cent;/KG</td>
            <td className="current">
              <CentsPerKilo saleLot={onScalesLot} noSymbol />
            </td>
            <td className="lastSold">
              <CentsPerKilo saleLot={lastWeighedLot} noSymbol />
            </td>
          </tr>
          <tr>
            <td>AVG KG</td>
            <td className="current">
              <AverageWeight saleLot={onScalesLot} includeUnits={false} />
            </td>
            <td className="lastSold">
              <AverageWeight saleLot={lastWeighedLot} includeUnits={false} />
            </td>
          </tr>
          <tr>
            <td>AVG $</td>
            <td className="current">
              <DollarsPerHead saleLot={onScalesLot} noSymbol />
            </td>
            <td className="lastSold">
              <DollarsPerHead saleLot={lastWeighedLot} noSymbol />
            </td>
          </tr>
        </>
      ) : null}
    </tbody>
  );
};

export const LastWeighedView = ({ detailed = false }) => {
  const draftingInformationId = useSelector(
    getSetting(Settings.saleWatcherDraftingInformationId),
  );

  const lastSeenLots = useSelector(
    getLastSeenSaleLotsByDraftingId(draftingInformationId),
  );
  const onScalesLot = lastSeenLots?.[0] || {};
  const lastWeighedLot = lastSeenLots?.[1] || {};

  return (
    <FullScreenContainer>
      <BoundingBox>
        <HighContrastTable>
          <thead>
            <tr>
              <th>
                <DraftingInformationSwitcher />
              </th>
              <th className="current">ON SCALE</th>
              <th className="lastSold">LAST WEIGHED</th>
            </tr>
          </thead>
          <LastWeighedTableBody
            onScalesLot={onScalesLot}
            lastWeighedLot={lastWeighedLot}
            detailed={detailed}
          />
        </HighContrastTable>
      </BoundingBox>
    </FullScreenContainer>
  );
};

export const LastWeighedDetailedView = () => <LastWeighedView detailed />;
