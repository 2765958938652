import React from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  activateSingleWeigh,
  deactivateSingleWeigh,
  initializeSingleWeigh,
} from "actions";

import { CenteredGreedy } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

import {
  getIsSingleWeighCurrentBySingleWeighId,
  getIsSingleWeighInitializedBySingleWeighId,
} from "selectors";

import { useMountEffect } from "hooks";

import SingleWeighControls from "./SingleWeighControls";
import SingleWeighPensStatus from "./SingleWeighPensStatus";
import SingleWeighRecentDrafts from "./SingleWeighRecentDrafts";

function SingleWeighControlScreenComponent({ match }) {
  const { singleWeighId } = match.params;
  const isSingleWeighInitialized = useSelector(
    getIsSingleWeighInitializedBySingleWeighId(singleWeighId),
  );
  const isSingleWeighCurrent = useSelector(
    getIsSingleWeighCurrentBySingleWeighId(singleWeighId),
  );

  const dispatch = useDispatch();

  // On mount, make sure this single weigh is initialized - if you came from the selector you should be
  // good to go after that.
  // On unmount, deactivate this single weigh.
  useMountEffect(() => {
    // useEffect(() => {
    if (!isSingleWeighInitialized) {
      dispatch(initializeSingleWeigh(singleWeighId));
    } else if (!isSingleWeighCurrent) {
      dispatch(activateSingleWeigh(parseInt(singleWeighId, 10)));
    }
    return () => {
      dispatch(deactivateSingleWeigh());
    };
  });

  if (!isSingleWeighInitialized || !isSingleWeighCurrent) {
    return (
      <CenteredGreedy>
        <LoadingSpinner actionName="Initializing SingleWeigh" />
      </CenteredGreedy>
    );
  }

  return (
    <>
      <SingleWeighControls singleWeighId={singleWeighId} />
      <SingleWeighPensStatus />
      <SingleWeighRecentDrafts singleWeighId={singleWeighId} />
    </>
  );
}

export default SingleWeighControlScreenComponent;
