import React, { memo } from "react";

import isEqual from "lodash/isEqual";
import { sumBy } from "lodash/math";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Warning } from "components/ErrorMessage";

import { PenTypes } from "constants/auctionPens";

import { expandAuctionPen } from "lib/auctionPens";
import { pluralize } from "lib/pluralize";
import { getBuyerHashFromSaleLot } from "lib/saleLot";

import {
  getAuctionPens,
  getBusinesses,
  getBuyerHashBySaleLotId,
  getPenOwnersByStartPen,
  getSaleLots,
  selectSaleLotIdsByPenId,
} from "selectors";

function PenningConflictNoticeComponent(props) {
  const {
    endPenNumber: endPenNumberProp,
    penType,
    saleLotId,
    saleRoundId,
    startPenNumber,
    startPenPrefix,
    startPenSuffix,
    endPenSuffix,
    showOwnerPrompt,
    isLocked,
  } = props;

  const auctionPens = useSelector(getAuctionPens);
  const saleLots = useSelector(getSaleLots);
  const businesses = useSelector(getBusinesses);
  const buyerHash = useSelector(getBuyerHashBySaleLotId(saleLotId));

  const endPenNumber = endPenNumberProp || startPenNumber;
  const startPen = `${startPenPrefix}${startPenNumber}${startPenSuffix}`;

  const owners = useSelector(getPenOwnersByStartPen(startPen));
  const otherOwners = owners.filter(
    owner =>
      buyerHash !==
      getBuyerHashFromSaleLot({
        buyer_id: owner.businessId,
        buyer_way: owner.buyerWay,
      }),
  );

  // When start_pen is undefined (from initialValues), startPen will be NaN.
  // comparing other start_pen and end_pen fields (using < and >) to NaN, they
  // will always return true. Nothing overlaps NaN.
  const overlappingPens = Number.isNaN(startPenNumber)
    ? []
    : Object.values(auctionPens).filter(pen => {
        const expandedPenName = expandAuctionPen(pen);
        const b1 = expandedPenName.start_pen;
        const b2 = expandedPenName.end_pen || b1;
        const b3 = expandedPenName.start_pen_prefix || "";
        const b4 = expandedPenName.start_pen_suffix || "";
        const b5 = expandedPenName.end_pen_suffix || "";

        const saleRoundEqual =
          pen.penType === PenTypes.DELIVERY
            ? true
            : saleRoundId === pen.saleRoundId;

        return (
          pen.penType === penType &&
          saleRoundEqual &&
          !(endPenNumber < b1 || startPenNumber > b2) &&
          (isEqual([startPenPrefix, startPenSuffix], [b3, b4]) ||
            (endPenSuffix && endPenSuffix === b5))
        );
      });

  // Are there other sale lots in this pen.
  const saleLotIdsByPenIdLookup = useSelector(selectSaleLotIdsByPenId);
  const overlappingSaleLots = overlappingPens
    .map(pen =>
      saleLotIdsByPenIdLookup[pen.id]
        .filter(id => id !== saleLotId)
        .map(id => saleLots[id]),
    )
    .flat();
  const overlapQty = sumBy(overlappingSaleLots, "quantity");

  return (
    <>
      {overlappingSaleLots.length > 0 && (
        <Warning>
          {`Warning: There's already ${overlapQty} hd in ${pluralize(
            "pen",
            overlappingPens.length,
          )} `}
          {overlappingPens
            .map(pen => [pen.start_pen, pen.end_pen].filter(Boolean).join("-"))
            .join(", ")}
          .
          <br />
        </Warning>
      )}

      {showOwnerPrompt && otherOwners.length > 0 ? (
        <Warning>
          This pen is currently associated with {otherOwners.length} other{" "}
          {pluralize("buyer", otherOwners.length)}
          <br />
          {owners.map((owner, i) => (
            <React.Fragment key={`owner-${i}`}>
              {businesses[owner.businessId]?.name} -{" "}
              {owner.buyerWay?.name || "No Buyer Way"}
              <br />
            </React.Fragment>
          ))}
        </Warning>
      ) : null}
      {isLocked && <Warning>This Delivery Pen Is Locked</Warning>}
    </>
  );
}

PenningConflictNoticeComponent.propTypes = {
  saleLotId: PropTypes.string,
  saleRoundId: PropTypes.number,
  endPenNumber: PropTypes.number,
  penType: PropTypes.string,
  startPenNumber: PropTypes.number,
  showOwnerPrompt: PropTypes.bool,
  isLocked: PropTypes.bool,
  startPenPrefix: PropTypes.string,
  startPenSuffix: PropTypes.string,
  endPenSuffix: PropTypes.string,
};

export default memo(PenningConflictNoticeComponent);
