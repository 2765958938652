import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

export const StackedIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.error};
  display: block;
`;

export const Container = styled.div`
  position: relative;
  svg {
    display: block;
  }
`;
