import React, { useState } from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import isNaN from "lodash/isNaN";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import styled from "styled-components/macro";

import FloatingActionButton from "components/FloatingActionButton";
import { Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import MessageBox from "components/MessageBox";
import { ReactSelect } from "components/SearchableSelector";
import SearchInputTable from "components/SearchInput";

import {
  RegisteredBusinessStatus,
  RegisteredBusinessStatustTitleMap,
} from "constants/bidders";
import { HotKey } from "constants/hotKey";

import { getRegisteredBidderStatus } from "lib/bidders";
import { openEditBidderRegistrationModal } from "lib/navigation";

import {
  getIsFetchingBusinesses,
  getProperties,
  selectBiddersList,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
} from "selectors";

import { BidderCard } from "./BidderCard";

const ViewSelector = styled(ReactSelect)`
  width: 350px;
  border-radius: 0px;
`;

const ListOptions = styled(Row)`
  padding: ${({ theme }) => theme.space[2]}px;
`;

const RegisteredBiddersList = styled.div(
  ({ theme }) => `
  padding-bottom: ${theme.space[2]}px;
`,
);

const bidderStatusfilterOptions = [
  { label: "All", value: -1, filter: () => true },
  {
    label:
      RegisteredBusinessStatustTitleMap[RegisteredBusinessStatus.REGISTERED],
    value: RegisteredBusinessStatus.REGISTERED,
    filter: bidder => bidder.status === RegisteredBusinessStatus.REGISTERED,
  },
  {
    label: "Other",
    value: "",
    filter: bidder => bidder.status !== RegisteredBusinessStatus.REGISTERED,
  },
];

const BidderProvisionalDataSearchFields = [
  "account",
  "contact_name",
  "destination_pic",
  "buyer_email",
  "buyer_phone",
  "name",
  "property_name",
  "stock_agency",
  "stock_agent",
];
const BidderTextSearchFields = [
  "businessName",
  "buyerWay",
  "notes",
  "property",
];

const BidderNumberSearchFields = ["registrationNumber"];

function RegisteredBiddersView(props) {
  const { bidders, isLoading } = props;

  const [bidderFilter, setBidderFilter] = useState(
    bidderStatusfilterOptions[0],
  );

  const [textFilter, setTextFilter] = useState("");

  function bidderTextFilter(bidder) {
    if (!textFilter.trim()) {
      return true;
    }
    const searchValue = textFilter.toLowerCase().trim();
    if (!isNaN(Number(searchValue))) {
      return BidderNumberSearchFields.some(
        key => String(bidder[key]) === searchValue,
      );
    }

    return (
      BidderTextSearchFields.some(
        key => String(bidder[key]).toLowerCase().indexOf(searchValue) > -1,
      ) ||
      BidderProvisionalDataSearchFields.some(
        key =>
          bidder.provisionalData[key] &&
          String(bidder.provisionalData[key])
            .toLowerCase()
            .indexOf(searchValue) > -1,
      )
    );
  }

  function onClickAddBidderRegistration() {
    openEditBidderRegistrationModal();
  }

  let content = (
    <MessageBox>No bidders are registered for this sale.</MessageBox>
  );

  if (isLoading) {
    content = (
      <MessageBox>
        <LoadingSpinner size={24} />
      </MessageBox>
    );
  }

  if (bidders.length) {
    content = bidders
      .filter(bidderFilter.filter)
      .filter(bidderTextFilter)
      .map(bidder => <BidderCard {...bidder} key={bidder.id} />);
  }

  return (
    <RegisteredBiddersList data-tour="registeredBiddersList">
      <ListOptions justifyBetween printHidden>
        <ViewSelector
          value={bidderFilter}
          options={bidderStatusfilterOptions}
          onChange={setBidderFilter}
        />
        <SearchInputTable handleChange={setTextFilter} value={textFilter} />
      </ListOptions>
      {content}
      <FloatingActionButton
        dataTour="addBidderRegistration"
        disabled={isLoading}
        hotKeys={HotKey.CREATE_NEW}
        icon={faPlus}
        onClick={onClickAddBidderRegistration}
        text="Add Bidder Registration"
      />
    </RegisteredBiddersList>
  );
}

function transformRegistration(
  registration,
  business,
  isBusinessLoading,
  properties,
) {
  return {
    ...registration,
    businessName: business?.name,
    property: properties[registration.defaultPropertyId]?.PIC,
    status: getRegisteredBidderStatus(
      registration.businessId,
      business?.name,
      isBusinessLoading,
    ),
  };
}

const getBiddersWithBusinessValues = createSelector(
  [
    selectBiddersList,
    selectPropertyEnrichedBusinessByBusinessIdLookup,
    getIsFetchingBusinesses,
    getProperties,
  ],
  (bidders, businesses, businessesLoading, properties) =>
    bidders.map(bidder =>
      transformRegistration(
        bidder,
        businesses[bidder.businessId],
        businessesLoading,
        properties,
      ),
    ),
);

function mapStateToProps(state, props) {
  return {
    bidders: getBiddersWithBusinessValues(state, props),
    isLoading: state.bidderRegistrations.isFetching,
  };
}

export default connect(mapStateToProps)(RegisteredBiddersView);
