import { addWeight } from "actions/scales";
import { sendMessage } from "actions/scanners";

import { b64ToArrayBuffer } from "lib";

import { ContinuousOutputView } from "lib/mtDemand";

export const MT_TCP_DRIVER_NAME = "mt-tcp";

export const ResponseStatus = {
  SUCCESS: 0,
  ACCESS_OK: 12,
  BAD_FIELD_NAME: 15,
  ENTER_PASSWORD: 51,
  READY_FOR_LOGIN: 53,
  PARAMETER_SYNTAX_FAILURE: 81,
  ACCESS_FAILURE: 93,
  FAILURE: 99,
};

export const CommandType = {
  INVALID: 0,
  SYSTEM: 1, // "S"
  COMMAND: 2, // " "
  READ: 3, // "R"
  WRITE: 4, // "W"
  GROUP: 5, // "G"
  XCALLBACK: 6, // "X"
  CALLBACK: 7, // "C"
  OK: 8, // "O"
  CALLBACK_RESPONSE: 0, // "B"
};

export const CommandTypeMap = {
  1: CommandType.SYSTEM,
  2: CommandType.COMMAND,
  3: CommandType.READ,
  4: CommandType.WRITE,
  5: CommandType.GROUP,
  6: CommandType.XCALLBACK,
  7: CommandType.CALLBACK,
  8: CommandType.OK,
  9: CommandType.CALLBACK_RESPONSE,
};

export function mtTcpProcessMessage(device, type, status, serialisedPayload) {
  const { deviceId } = device;
  const commandType = CommandTypeMap[type] || CommandType.INVALID;
  switch (commandType) {
    case CommandType.COMMAND: {
      switch (status) {
        case ResponseStatus.READY_FOR_LOGIN:
          return sendMessage(deviceId, btoa("user admin\n"));
        case ResponseStatus.ACCESS_OK:
          return sendMessage(deviceId, btoa("contout\n"));
        default:
      }
      break;
    }
    case CommandType.CALLBACK: {
      switch (status) {
        case ResponseStatus.SUCCESS: {
          const dataArrayBuffer = b64ToArrayBuffer(serialisedPayload);

          // Continuous output may or may not have a checksum byte on the end of it
          // The data is offset by the prefixed 3 digit "sequence number" string this is left for better interoperability with other responses
          if (
            dataArrayBuffer.byteLength === 20 ||
            dataArrayBuffer.byteLength === 21
          ) {
            const continuousOutputView = new ContinuousOutputView(
              dataArrayBuffer,
              3,
            );
            if (!continuousOutputView.isValid()) {
              return;
            }
            return addWeight(
              deviceId,
              new Date(),
              continuousOutputView.displayedWeightGrams(),
              continuousOutputView.isStable(),
            );
          }
          break;
        }
        default:
          break;
      }
      break;
    }
    case CommandType.INVALID: {
      break;
    }
    default:
      // eslint-disable-next-line no-console
      console.warn("Unknown command type %s", commandType);
  }
}
