import * as Yup from "yup";

export const SaleLotValidationSchema = Yup.object().shape({
  buyer: Yup.string()
    .notOneOf(["-1", -1], "The buyer is required")
    .required("The buyer is required"),
  buyerPIC: Yup.mixed()
    .notOneOf(["-1", -1], "The Buyer PIC is required")
    .required("The Buyer PIC is required"),
});
