import React from "react";

import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { patchSaleLot } from "actions";

import { Button } from "components/Form";
import { BusinessField, BuyerAwareBuyerWayField } from "components/Form/Fields";
import { FormikAwareBusinessPICSelector } from "components/Form/FormikControls";
import { QuickSellBidderNumberInput } from "components/Form/QuickSellBidderNumberInput";
import { Row } from "components/Layout";
import { PricingType } from "components/SellingSaleLotForm/PricingType";

import { BUYER } from "constants/businesses";
import { LotStatus } from "constants/clearingSaleAttributes";

import { calculateTotalPriceCents, firstIfAllEqual } from "lib";

import { requiredWhenSet } from "lib/validation";

import {
  getBidders,
  getCurrentSale,
  getSaleLots,
  selectBidderIdBySaleLotIdLookup,
} from "selectors";

const validationSchema = Yup.object().shape({
  bidderNumber: Yup.number()
    .nullable()
    .typeError("This field must be a number"),
  buyerId: Yup.string().nullable().when("unit_price", requiredWhenSet),
  buyerWay: Yup.object()
    .shape({
      id: Yup.string(),
      string: Yup.string(),
    })
    .nullable(),
  unit_price: Yup.number()
    .nullable()
    .typeError("This field must be a number")
    .required("Please enter a price"),
});

export function ClearingSaleForm(props) {
  const { saleLotIds = [], onSave, readOnly } = props;
  const dispatch = useDispatch();

  const saleLotByIdLookup = useSelector(getSaleLots);
  const bidderIdBySaleLotIdLookup = useSelector(
    selectBidderIdBySaleLotIdLookup,
  );
  const bidderByIdLookup = useSelector(getBidders);
  const clearingSale = useSelector(getCurrentSale);
  const isUsingRegisteredBidders = clearingSale
    ? clearingSale.using_registered_bidders
    : false;

  const saleLots = saleLotIds.map(id => saleLotByIdLookup[id]);

  const defaultPricingTypeId = clearingSale.pricing_type_id;

  const onSubmit = values => {
    saleLots.forEach(saleLot => {
      const totalPriceCents = calculateTotalPriceCents({
        unitPrice: values.unit_price ? parseFloat(values.unit_price) : 0,
        quantity: saleLot.quantity,
        pricing_type_id: values.pricing_type_id,
      });

      dispatch(
        patchSaleLot(
          {
            total_price_cents: totalPriceCents,
            buyer_id: values.buyerId || null,
            buyer_way: values.buyerWay || null,
            pricing_type_id: values.pricing_type_id,
            id: saleLot.id,
          },
          {
            changeReason: "Quick Sell Clearing Sale Lot",
          },
        ),
      );
    });

    onSave();
  };

  const onClickPassedIn = () => {
    saleLots.forEach(saleLot => {
      dispatch(
        patchSaleLot(
          {
            id: saleLot.id,
            clearingSaleAttributes: {
              status: LotStatus.PASSED_IN,
            },
          },
          {
            changeReason: "Quick Sell Clearing Sale Lot - Passed In",
          },
        ),
      );
    });
    onSave();
  };

  const onClickNoBids = () => {
    saleLots.forEach(saleLot => {
      dispatch(
        patchSaleLot(
          {
            id: saleLot.id,
            clearingSaleAttributes: {
              status: LotStatus.NO_BIDS,
            },
          },
          {
            changeReason: "Quick Sell Clearing Sale Lot - No Bids",
          },
        ),
      );
    });
    onSave();
  };

  const pricingTypeId = firstIfAllEqual(
    saleLots.map(saleLot => saleLot.pricing_type_id),
    defaultPricingTypeId,
  );

  const buyerId = firstIfAllEqual(
    saleLots.map(saleLot => saleLot.buyer_id),
    null,
  );

  const buyerWayName = firstIfAllEqual(
    saleLots.map(saleLot => saleLot.buyer_way?.name || null),
    null,
  );

  const bidderNumber = firstIfAllEqual(
    saleLotIds.map(
      saleLotId =>
        bidderByIdLookup[bidderIdBySaleLotIdLookup[saleLotId]]
          ?.registrationNumber,
    ),
    null,
  );

  const quantity = firstIfAllEqual(
    saleLots.map(saleLot => saleLot.quantity),
    null,
  );

  const initialValues = {
    bidderNumber,
    buyerId,
    buyerWay: buyerWayName === null ? null : { name: buyerWayName },
    pricing_type_id: pricingTypeId,
    unit_price: null,
    quantity: null,
  };

  const formKey = saleLotIds.join("-");
  return (
    <Formik
      key={formKey}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Grid spacing={2} container>
          {isUsingRegisteredBidders ? (
            <>
              <Grid item xs={12}>
                <QuickSellBidderNumberInput autoFocus />
              </Grid>
              <Grid item xs={12}>
                <FormikAwareBusinessPICSelector
                  businessFieldName="buyerId"
                  label="Buyer"
                  optional
                  readOnly
                  disabled
                  tooltip="Buyer"
                />

                <Grid item xs={12}>
                  <BuyerAwareBuyerWayField
                    name="buyerWay"
                    label="Account"
                    buyerField="buyerId"
                    readOnly
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <BusinessField
                  name="buyerId"
                  label="Buyer"
                  autoFocus
                  disabled={readOnly}
                  sortRecentBusinessType={BUYER}
                  activeOnly
                />
              </Grid>
              <Grid item xs={12}>
                <BuyerAwareBuyerWayField
                  name="buyerWay"
                  label="Account"
                  buyerField="buyerId"
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <PricingType quantity={quantity} namespace="" />
          </Grid>
          <Grid item xs={12}>
            <Row fullWidth reverse>
              <Button
                type="submit"
                disabled={readOnly}
                title="Ensure screen is sorted by lot number for best results."
              >
                Save &amp; Next
              </Button>
              <Button type="button" onClick={onClickNoBids} disabled={readOnly}>
                No Bids
              </Button>
              <Button
                type="button"
                onClick={onClickPassedIn}
                disabled={readOnly}
              >
                Passed In
              </Button>
            </Row>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}
