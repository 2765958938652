import React from "react";

import { groupBy, isArray, isEqual, sumBy } from "lodash";
import { useSelector } from "react-redux";

import Badge from "components/Badge";

import { LotStatus, BuyerStatus } from "constants/clearingSaleAttributes";

import { EMPTY_ARRAY, formatDecimal } from "lib";

import {
  getSaleLotIdsByConsignmentId,
  selectConsignmentIdsSaleLotIdsByBuyerIdLookup,
  getSaleLotById,
} from "selectors";

export const ConsignmentSaleLotSummary = React.memo(
  ({ consignmentId, buyerId, condensed = false }) => {
    const saleLotIds =
      useSelector(state =>
        buyerId
          ? selectConsignmentIdsSaleLotIdsByBuyerIdLookup(state)[buyerId][
              consignmentId
            ]
          : getSaleLotIdsByConsignmentId(consignmentId)(state),
      ) || EMPTY_ARRAY;

    return <SaleLotSummary saleLotIds={saleLotIds} condensed={condensed} />;
  },
);

export const SaleLotSummary = React.memo(
  ({ saleLotIds, condensed = false }) => {
    const saleLotsGroupedByStatus = useSelector(state => {
      // If we are only intereted in the condensed summary (a count of lots)
      /// Short circuit.
      if (condensed) {
        return true;
      }
      const saleLots = saleLotIds.map(id => getSaleLotById(id)(state));
      return groupBy(saleLots, "clearingSaleAttributes.status");
    }, isEqual);

    if (condensed) {
      return `${saleLotIds.length} Lots`;
    } else {
      return (
        <div>
          {Object.values(LotStatus)
            .filter(status => isArray(saleLotsGroupedByStatus[status]))
            .map(status => (
              <div key={status}>
                {`${status}: ${
                  saleLotsGroupedByStatus[status].length
                } ($${formatDecimal(
                  sumBy(saleLotsGroupedByStatus[status], "total_price_cents") /
                    100,
                )})`}
              </div>
            ))}
        </div>
      );
    }
  },
);

const BuyerStatusColorMap = {
  [BuyerStatus.PAID]: "paid",
  [BuyerStatus.PARTIALLY_PAID]: "warning",
  [BuyerStatus.SOLD]: "sold",
};

export const BuyerLotStatus = ({ saleLotIds }) => {
  const status = useSelector(state => {
    const saleLotsStatuses = saleLotIds.map(
      id => getSaleLotById(id)(state).clearingSaleAttributes?.status,
    );
    if (saleLotsStatuses.every(s => s === LotStatus.PAID)) {
      return BuyerStatus.PAID;
    } else if (saleLotsStatuses.includes(LotStatus.PAID)) {
      return BuyerStatus.PARTIALLY_PAID;
    } else {
      return BuyerStatus.SOLD;
    }
  });

  return <Badge color={BuyerStatusColorMap[status]}>{status}</Badge>;
};
