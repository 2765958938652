import { intersection, isEmpty } from "lodash";
import { createSelector } from "reselect";

import { UNALLOCATED } from "constants/scanner";
import {
  UnhandledHandledStatuses,
  WeighLotScanStatus,
  WeighLotStatus,
} from "constants/weighScanning";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { calculateWeighLotScanStatus } from "lib/weighLotScan";

import {
  selectEidsByReceivalLotIdLookup,
  getAuctionPens,
  getPenScanLots,
  getReceivalLots,
  getSaleLots,
  getScans,
  getWeighLots,
  selectPenScanLotIdsByWeighLotIdlookup,
  selectReceivalLotIdsByWeighLotIdlookup,
  selectSaleLotIdsByWeighLotIdLookup,
  selectUniqueWeighLotScansStatusesByWeighLotId,
  selectWeighLotScansByWeighLotId,
  selectPenScanLotIdByWeighLotScanId,
  selectReceivalLotIdByWeighLotScanId,
  selectSaleLotIdByWeighLotScanId,
  selectResolvedCommentCountByWeighLotIdLookup,
  selectunresolvedCommentCountByWeighLotIdLookup,
  selectWeighLotIdsByEidLookup,
  getShowDeduplicatedWeighLotScans,
  selectEidsByPenScanLotIdLookup,
} from "selectors";

import { selectFilteredWeighLotIds } from "selectors/globalSearch/weighLotFilters";

export const getWeighLotAggridData = createSelector(
  [
    selectFilteredWeighLotIds,
    getWeighLots,
    selectWeighLotScansByWeighLotId,
    selectUniqueWeighLotScansStatusesByWeighLotId,
    selectReceivalLotIdsByWeighLotIdlookup,
    selectPenScanLotIdsByWeighLotIdlookup,
    selectSaleLotIdsByWeighLotIdLookup,
    getReceivalLots,
    getPenScanLots,
    getSaleLots,
    getAuctionPens,
    selectEidsByReceivalLotIdLookup,
    selectEidsByPenScanLotIdLookup,
    selectReceivalLotIdByWeighLotScanId,
    selectPenScanLotIdByWeighLotScanId,
    selectSaleLotIdByWeighLotScanId,
    getScans,
    selectWeighLotIdsByEidLookup,
    selectResolvedCommentCountByWeighLotIdLookup,
    selectunresolvedCommentCountByWeighLotIdLookup,
    getShowDeduplicatedWeighLotScans,
  ],
  (
    filteredWeighLotIds,
    weighLotByIdLookup,
    weighLotScansByWeighLotId,
    uniqueWeighLotScanStatusesByWeighLotIdLookup,
    receivalLotIdsByWeighLotIdlookup,
    penScanLotIdsByWeighLotIdlookup,
    saleLotIdsByWeighLotIdLookup,
    receivalLotsByIdLookup,
    penScanLotsByIdLookup,
    saleLotsByIdLookup,
    auctionPens,
    eidsByReceivalLotIdLookup,
    eidsByPenScanLotIdLookup,
    receivalLotIdByWeighLotScanId,
    penScanLotIdByWeighLotScanId,
    saleLotIdByWeighLotScanId,
    scans,
    weighLotIdsByEidlookup,
    resolvedCommentCountByWeighLotIdLookup,
    unresolvedCommentCountByWeighLotIdLookup,
    showDeduplicatedWeighLotScans,
  ) =>
    Object.values(filteredWeighLotIds).map(weighLotId => {
      const weighLot = weighLotByIdLookup[weighLotId];
      const receivalLotIds =
        receivalLotIdsByWeighLotIdlookup[weighLot.id] || [];

      const penScanLotIds = penScanLotIdsByWeighLotIdlookup[weighLot.id] || [];

      const saleLotIds =
        saleLotIdsByWeighLotIdLookup[weighLot.id].filter(
          scanLotId => scanLotId !== UNALLOCATED,
        ) || [];

      const saleLots = saleLotIds.map(
        saleLotId => saleLotsByIdLookup[saleLotId],
      );

      const { lotNumber, totalMassGrams, scaleName, weighDateTime, fileName } =
        weighLot;

      const receivalLots = receivalLotIds.map(receivalLotId => {
        const receivalLot = receivalLotsByIdLookup[receivalLotId] || {};
        const receivalPen = auctionPens[receivalLot.receivalPenId] || {};
        const scansCount =
          eidsByReceivalLotIdLookup[receivalLotId]?.length || 0;
        return {
          title: `${getAuctionPenDisplayName(receivalPen)} ${scansCount} ${
            receivalLot.mark
          } / ${receivalLot.quantity}`,
          ...receivalLot,
        };
      });

      const penScanLots = penScanLotIds.map(penScanLotId => {
        const penScanLot = penScanLotsByIdLookup[penScanLotId] || {};
        const sellingPen = auctionPens[penScanLot?.sellingPenId] || {};
        const scansCount = eidsByPenScanLotIdLookup[penScanLotId]?.length || 0;
        return {
          title: `${getAuctionPenDisplayName(sellingPen)} ${scansCount} / ${
            penScanLot.quantity
          }`,
          ...penScanLot,
        };
      });

      const filteredWeighLotScans = showDeduplicatedWeighLotScans
        ? weighLotScansByWeighLotId[weighLot.id]
        : weighLotScansByWeighLotId[weighLot.id].filter(
            weighLotScan =>
              weighLotScan.status !== WeighLotScanStatus.DEDUPLICATED,
          );

      const weighLotScans = filteredWeighLotScans.map(weighLotScan => {
        const receivalLot =
          receivalLotsByIdLookup[
            receivalLotIdByWeighLotScanId[weighLotScan.id]
          ] || {};
        const receivalPen = auctionPens[receivalLot.receivalPenId] || {};
        const receivalLotScansCount =
          eidsByReceivalLotIdLookup[receivalLot.id]?.length || 0;

        const penScanLot =
          penScanLotsByIdLookup[
            penScanLotIdByWeighLotScanId[weighLotScan.id]
          ] || {};
        const sellingPen = auctionPens[penScanLot.sellingPenId];
        const penScanLotScansCount =
          eidsByPenScanLotIdLookup[penScanLot.id]?.length || 0;

        const saleLot =
          saleLotsByIdLookup[saleLotIdByWeighLotScanId[weighLotScan.id]];

        const scanStatus = calculateWeighLotScanStatus(
          weighLotScan,
          scans,
          weighLotIdsByEidlookup,
        );

        return {
          receivalLot: !isEmpty(receivalLot)
            ? {
                title: `${getAuctionPenDisplayName(
                  receivalPen,
                )} ${receivalLotScansCount} ${receivalLot.mark} / ${
                  receivalLot.quantity
                }`,
                ...receivalLot,
              }
            : null,
          penScanLot: !isEmpty(penScanLot)
            ? {
                title: `${getAuctionPenDisplayName(
                  sellingPen,
                )} ${penScanLotScansCount} / ${penScanLot.quantity}`,
                ...penScanLot,
              }
            : null,
          saleLot,
          ...weighLotScan,
          status: scanStatus,
        };
      });

      const weighLotScanStatuses = uniqueWeighLotScanStatusesByWeighLotIdLookup[
        weighLot.id
      ].filter(
        weighLotScanStatus =>
          weighLotScanStatus !== WeighLotScanStatus.DEDUPLICATED,
      );

      let status = WeighLotStatus.RESOLVED;

      if (intersection(weighLotScanStatuses, UnhandledHandledStatuses).length) {
        status = WeighLotStatus.UNRESOLVED;
      }

      return {
        weighLotId: weighLot.id,
        status,
        weighLot,
        lotNumber,
        totalMassGrams,
        scaleName,
        fileName,
        weighDateTime,
        weighLotScanStatuses,
        weighLotScans,
        receivalLots,
        penScanLots,
        saleLots,
        resolvedCommentCount:
          resolvedCommentCountByWeighLotIdLookup[weighLot.id],
        unresolvedCommentCount:
          unresolvedCommentCountByWeighLotIdLookup[weighLot.id],
      };
    }),
);
