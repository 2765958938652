import React, { useMemo } from "react";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { EnvdDocumentAction } from "actions/envd";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { EnvdDocumentColumns } from "components/Importer/columnDefinitions";
import { Column } from "components/Layout";

import { AgGridTables } from "constants/aggrid";

import {
  getSales,
  selectDeploymentSaleOptionsByLivestockSaleId,
  selectSaleOptions,
} from "selectors";

import { selectEnvdDocumentsAggridData } from "selectors/aggrid/envd";

import { useDebounceSelector } from "hooks";

const getRowId = params => params.data.envdDocument.id;

export function EnvdDocuments() {
  const fullSaleOptions = useSelector(selectSaleOptions);
  const sales = useSelector(getSales);
  const deploymentSaleOptions = useSelector(
    selectDeploymentSaleOptionsByLivestockSaleId,
  );

  const rowData = useDebounceSelector(selectEnvdDocumentsAggridData);

  const dispatch = useDispatch();

  const updateEnvdDocument = data => {
    const patch = {
      id: data.envdDocument.id,
      livestockSaleId: data.livestockSaleOption.value,
      deploymentSaleId: data.deploymentSaleOption?.value || null, // Optional, but make sure it's cleared if not provided.
    };
    dispatch(EnvdDocumentAction.update(patch));
  };

  const columnDefs = useMemo(() => {
    const saleOptions = fullSaleOptions.map(sale => ({
      label: `${sale.preText}${sale.name}`,
      value: sale.id,
    }));

    const columnOptions = {
      saleOptions,
      deploymentSaleOptions,
    };
    return EnvdDocumentColumns(columnOptions);
  }, [deploymentSaleOptions, fullSaleOptions]);

  const onCellValueChanged = e => {
    if (e.colDef.field === "livestockSale" && e.newValue !== e.oldValue) {
      // If the livestock sale value changed, clear the deployment sale values.
      e.node.setData({
        ...e.node.data,
        deploymentSale: undefined,
      });
    }
    e.api.redrawRows(e.node);
  };

  const context = {
    update: updateEnvdDocument,
    sales,
  };

  const additionalActions = [
    {
      title: "Refresh",
      icon: faSync,
      onClick: () => dispatch(EnvdDocumentAction.refresh()),
      default: true,
      dataTour: "refreshEnvd",
    },
  ];

  return (
    <Column fullWidth>
      <AgGridTable
        additionalActions={additionalActions}
        columnDefs={columnDefs}
        context={context}
        rowData={rowData}
        tableName={AgGridTables.ENVD_MAPPING}
        getRowId={getRowId}
        singleClickEdit
        stopEditingWhenCellsLoseFocus
        onCellValueChanged={onCellValueChanged}
      />
    </Column>
  );
}
