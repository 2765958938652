import { GET_AUDIT_LOG } from "constants/actionTypes";

export function requestAuditLog(dataType, dataId, queryParams) {
  return {
    type: GET_AUDIT_LOG.REQUEST,
    dataType,
    dataId,
    queryParams,
  };
}

export function receiveAuditLog(auditLogEntries, dataType, dataId) {
  return {
    type: GET_AUDIT_LOG.SUCCESS,
    auditLogEntries,
    dataType,
    dataId,
  };
}

export function requestAuditLogError() {
  return {
    type: GET_AUDIT_LOG.FAILURE,
  };
}
