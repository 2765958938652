import { createSelector } from "reselect";

import { SaleTypes } from "constants/sale";

import { EMPTY_OBJECT } from "lib";

import { getLivestockSaleId, getSaleyardName } from "lib/navigation";

import {
  createIdByKeySelectorAndGetter,
  getSales,
  getSaleyards,
} from "selectors";

export const getCurrentSale = state =>
  getSales(state)[getLivestockSaleId()] || null;

// This can be used in locations where we don't have a saleid in our url, but still need a sale.
export const getCurrentOrFirstSale = state =>
  getLivestockSaleId()
    ? getSales(state)[getLivestockSaleId()]
    : Object.values(getSales(state))[0] || null;

export const getCurrentDeploymentSalesList = state =>
  getCurrentSale(state)?.deployment_sales || null;

export const getCurrentRoundsList = state =>
  getCurrentSale(state).rounds || null;

export const getCurrentSpeciesId = state =>
  getCurrentSale(state)?.species_id || null;

export const getCurrentSaleType = state =>
  getCurrentSale(state)?.sale_type || null;

export const getIsCurrentSaleSaleType = saleType => state =>
  getCurrentSaleType(state) === saleType;

export const getIsCurrentSaleClearingSale = state =>
  getIsCurrentSaleSaleType(SaleTypes.CLEARING)(state);

export const selectCurrentDeploymentSales = createSelector(
  getCurrentSale,
  currentSale =>
    currentSale?.deployment_sales.reduce((acc, deploymentSale) => {
      acc[deploymentSale.deployment_sale_id] = deploymentSale;
      return acc;
    }, {}) || EMPTY_OBJECT,
);

export const getDeploymentSaleById = deploymentSaleId => state =>
  selectCurrentDeploymentSales(state)[deploymentSaleId] || null;

export const selectCurrentDeploymentSaleIdsList = createSelector(
  getCurrentSale,
  currentSale =>
    currentSale.deployment_sales?.map(
      deploymentSale => deploymentSale.deployment_sale_id,
    ),
);

export const getCurrentDeploymentSaleIds = state =>
  selectCurrentDeploymentSaleIdsList(state, getLivestockSaleId());

export const getHasSaleWatcherInCurrentSale = createSelector(
  getCurrentSale,
  currentSale => !!currentSale?.watch_link,
);

export const [selectIdBySaleyardNameLookup, getIdBySaleyardName] =
  createIdByKeySelectorAndGetter(getSaleyards, "name");

export const getCurrentSaleyardId = state =>
  getCurrentSale(state)?.saleyard_id ||
  getIdBySaleyardName(getSaleyardName())(state) ||
  null;

export const getCurrentSaleyard = state =>
  getSaleyards(state)[getCurrentSaleyardId(state)];
