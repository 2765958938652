import { GET_AGENCIES } from "constants/actionTypes";

export const requestAgencies = () => ({
  type: GET_AGENCIES.REQUEST,
});

export const receiveAgencies = agencies => ({
  type: GET_AGENCIES.SUCCESS,
  agencies,
});

export const requestAgenciesFailure = error => ({
  type: GET_AGENCIES.FAILURE,
  error,
});
