import { ModalTypes } from "constants/navigation";

import CarrierChargeModalAdapterFactory from "./adapterFactory";

const SaleLotAdapter = CarrierChargeModalAdapterFactory(
  "SALE_LOT",
  ModalTypes.EditSaleLotCarrierCharge,
);

export default SaleLotAdapter;
