import { put, takeEvery } from "redux-saga/effects";

import { setModalContext } from "actions";

import { MODAL_CONTEXT } from "constants/actionTypes";

import { openModalLink } from "lib/navigation";

function* onOpenAdvancedModal(action) {
  const { context, modalType, returnTo, type: ignored, ...modalProps } = action;
  yield put(setModalContext(modalType, context));
  openModalLink(modalType, modalProps, returnTo);
}

export default function* modalSagas() {
  yield takeEvery(MODAL_CONTEXT.OPEN.ACTION, onOpenAdvancedModal);
}
