import React, { useMemo, useRef } from "react";

import { faMobile } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { screenResize } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";

import { AgGridTables } from "constants/aggrid";

import {
  getLivestockSaleId,
  getReceivalScanLotCardsRoute,
  getSaleyardName,
} from "lib/navigation";

import { getReceivalLotAggridData } from "selectors/aggrid/receivalLots";

import { useDebounceSelector, useTheme } from "hooks";

import { receivalLotColumns } from "./columnDefinitions";

export const ReceivalLotsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rowData = useDebounceSelector(getReceivalLotAggridData);
  const agGridApiRef = useRef();
  const theme = useTheme();

  function onGridReady({ api }) {
    agGridApiRef.current = api;
  }

  const additionalActions = useMemo(
    () => [
      {
        title: "Mobile View",
        icon: faMobile,
        onClick: () => {
          dispatch(screenResize(theme.breakpoints[2] - 1));
          history.push(
            getReceivalScanLotCardsRoute(
              getSaleyardName(),
              getLivestockSaleId(),
            ),
          );
        },
        dataTour: "mobileView",
      },
    ],
    [dispatch, history, theme.breakpoints],
  );

  return (
    <AgGridTable
      additionalActions={additionalActions}
      columnDefs={receivalLotColumns}
      onGridReady={onGridReady}
      rowData={Object.values(rowData)}
      tableName={AgGridTables.RECEIVAL_LOT}
    />
  );
};
