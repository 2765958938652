import { camelCase, startCase } from "lodash";

import { AGRINOUS } from "constants/string";

const toTitleCase = string => {
  // a bit of a hack - using xyz to not Start Case the word AgriNous
  // because camelCase and startCase remove special characters
  const splitString = startCase(camelCase(string.replace(AGRINOUS, "xyz")));
  return splitString.replace("Xyz", AGRINOUS);
};

export function findErrorMessagesFromResponse(
  obj,
  path,
  result,
  includeParent = true,
) {
  // We want to dig through the array of objects to find our nested error messages
  // The errors are returned in the following format but with data point from the import:
  // {deployment_sales: [0: {consignments: [0: {sale_lots: [0: {scans: [0: animal: {EID: ["Must be valid EID"]}]} ]}}]}
  // We want to iterate through each of these objects until we find the string, which is our error message.
  // We also want to include the parent fields this error message has come from if includeParent is true.
  if (typeof obj === "string") {
    result.push(
      `${includeParent ? `${path.join(" - ")} - ` : ""}${toTitleCase(obj)}`,
    );
  } else if (typeof obj === "object") {
    for (const key in obj) {
      const value = obj[key];
      if (!Array.isArray(obj)) {
        path.push(`${toTitleCase(key)}`);
        findErrorMessagesFromResponse(value, path, result, includeParent);
        path.pop();
      } else {
        findErrorMessagesFromResponse(value, path, result, includeParent);
      }
    }
  }
  return result;
}

export const debugServiceWorkerLog = log => {
  const showLog = process.env.REACT_APP_DEBUG_SERVICE_WORKER === "true";
  if (showLog) {
    // eslint-disable-next-line no-console
    console.log(log);
  }
};
