import { createSelector } from "reselect";

import { getIntegrationBusinesses } from "selectors";

export const getIntegrationBusinessById = integrationBusinessId => state =>
  getIntegrationBusinesses(state)[integrationBusinessId] || null;

export const selectIntegrationBusinessesList = createSelector(
  [getIntegrationBusinesses],
  Object.values,
);
