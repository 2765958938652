import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { SelectField } from "components/Form/FormikControls";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Paper } from "components/Paper";
import { MediumText as Heading } from "components/Text";

import { EMPTY_ARRAY } from "lib";

import { getExportSiteOptionsByConsignmentId } from "selectors";

export const VisibilitySection = ({ consignmentId, readOnly }) => {
  const exportSiteOptions =
    useSelector(getExportSiteOptionsByConsignmentId(consignmentId)) ||
    EMPTY_ARRAY;

  return (
    <Grid item xs={12}>
      <Paper>
        <Grid container spacing={2} alignContent="center">
          <Grid item container alignItems="center" xs={9}>
            <Heading>Visibility</Heading>
          </Grid>
          <Grid item container alignItems="center" xs={1}>
            <Tooltip title="Should this listing be published on the external integration." />
          </Grid>

          <Grid item xs={12}>
            <SelectField
              isMulti
              options={exportSiteOptions}
              name="exportSites"
              label="Export To"
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
