import React from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { ReportJobAction } from "actions/reportJob";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { Warning, LargeErrorMessage } from "components/ErrorMessage";
import { Button, SecondaryButton } from "components/Form";
import { CheckBox } from "components/Form/FormikControls";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { AgGridTables } from "constants/aggrid";
import { ReportJobBulkEmailSummaryColumnDef } from "constants/columnDefinitions/reportJob";

import { getBulkEmailLimit, getBusinesses } from "selectors";

import { selectEmailRecipientsByFlagByBusinessIdLookup } from "selectors/businesses";

const columnDefs = Object.values(ReportJobBulkEmailSummaryColumnDef);

const initialFlags = {
  primary: true,
  accounts: false,
  commercial: false,
  compliance: false,
  envd: false,
};

const processingWarningLimit = 50;

/**
 * This look like it should be a selector - and it probably could be,
 * however it is unlikely to have any performance gains by creating a selector over
 * potentially hundreds of thousands of report jobs,
 * when we should only be dealing with a couple hundred at most here.
 */
const filterRecipients = (
  reportJobs,
  recipientsByFlagByBusinessIdLookup,
  flags,
) => {
  const recipientsByBusinessId = {};

  reportJobs.forEach(reportJob => {
    reportJob.businessIds.forEach(businessId => {
      const businessUsersById = {};
      const emailRecipientsById = {};
      const recipientsByFlag = recipientsByFlagByBusinessIdLookup[businessId];
      if (!recipientsByFlag) {
        return;
      }
      Object.entries(recipientsByFlag).forEach(
        ([recipientFlag, recipients]) => {
          const { businessUsers, emailRecipients } = recipients;
          if (flags[recipientFlag]) {
            businessUsers.forEach(businessUser => {
              businessUsersById[businessUser.id] = businessUser;
            });
            emailRecipients.forEach(emailRecipient => {
              emailRecipientsById[emailRecipient.id] = emailRecipient;
            });
          }
        },
      );
      if (businessId in recipientsByBusinessId) {
        recipientsByBusinessId[businessId].reportJobs.push(reportJob);
      } else {
        recipientsByBusinessId[businessId] = {
          businessIds: [businessId], // for summary AgGrid rendering
          reportJobs: [reportJob],
          emailRecipients: Object.values(emailRecipientsById),
          businessUsers: Object.values(businessUsersById),
        };
      }
    });
  });

  return Object.values(recipientsByBusinessId);
};

const BulkSendForm = ({ toggleShowBulkSend, rowData }) => {
  const { values: flags } = useFormikContext();

  const bulkEmailLimit = useSelector(getBulkEmailLimit);
  const businessByIdLookup = useSelector(getBusinesses);
  const recipientsByFlagByBusinessIdLookup = useSelector(
    selectEmailRecipientsByFlagByBusinessIdLookup,
  );

  const reportJobs = rowData.map(({ reportJob }) => reportJob);
  const selectedRecipients = filterRecipients(
    reportJobs,
    recipientsByFlagByBusinessIdLookup,
    flags,
  );

  const selectedBusinessUserIds = new Set();
  const selectedEmailRecipientIds = new Set();
  const unsentReports = new Set();
  Object.values(selectedRecipients).forEach(
    ({ reportJobs, emailRecipients, businessUsers }) => {
      // prep payload data for each related business
      businessUsers.forEach(businessUser => {
        selectedBusinessUserIds.add(businessUser.id);
      });
      emailRecipients.forEach(emailRecipient => {
        selectedEmailRecipientIds.add(emailRecipient.id);
      });
      if (businessUsers.length === 0 && emailRecipients.length === 0) {
        reportJobs.map(reportJob => unsentReports.add(reportJob.id));
      }
    },
  );
  const selectedCount =
    selectedBusinessUserIds.size + selectedEmailRecipientIds.size;

  const showOverLimitError = selectedCount > bulkEmailLimit;
  const showProcessingWarning =
    !showOverLimitError && selectedCount > processingWarningLimit;
  const showUnsentReportsWarning =
    !showOverLimitError && unsentReports.size !== 0;

  return (
    <Form>
      <DialogTitle onClose={toggleShowBulkSend}>
        Bulk send ({rowData.length}) reports
      </DialogTitle>

      <DialogContent dividers>
        {showOverLimitError ? (
          <LargeErrorMessage>
            Too many email recipients - over the limit of {bulkEmailLimit}{" "}
            emails!
            <br />
            Reduce the number of reports selected for sending.
          </LargeErrorMessage>
        ) : null}
        {showProcessingWarning ? (
          <Warning>
            It may take some time to process and send {selectedCount} emails,
            they will be queued and sent as soon as possible.
          </Warning>
        ) : null}
        {showUnsentReportsWarning ? (
          <Warning>
            {unsentReports.size} reports will not be sent, as the related
            businesses do not have recipients matching the selected filters.
          </Warning>
        ) : null}
        <h3>Which email recipients would you like to send reports to?</h3>
        <Grid item xs={12}>
          <CheckBox name="primary" label="Primary Email Recipients" />
        </Grid>
        <Grid item xs={12}>
          <CheckBox name="accounts" label="Accounts Recipients" />
        </Grid>
        <Grid item xs={12}>
          <CheckBox name="commercial" label="Commercial Recipients" />
        </Grid>
        <Grid item xs={12}>
          <CheckBox name="compliance" label="Compliance Recipients" />
        </Grid>
        <Grid item xs={12}>
          <CheckBox name="envd" label="eNVD Recipients" />
        </Grid>
        <h3>We&apos;ll send reports to {selectedCount} recipients:</h3>
        <AgGridTable
          columnDefs={columnDefs}
          context={{ businessByIdLookup }}
          defaultColDef={{
            flex: true,
            filter: false,
            floatingFilter: false,
            resizable: true,
            sortable: true,
          }}
          hideHeader
          hideSideBar
          paginationPageSize={20}
          rowData={selectedRecipients}
          showGlobalSearchFilter={false}
          suppressColumnVirtualisation
          tableName={AgGridTables.REPORT_JOB_BULK_EMAIL_SUMMARY}
          WrapperComponent={CollapseTableWrapper}
        />
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={toggleShowBulkSend}>Cancel</SecondaryButton>
        <Button type="submit" disabled={showOverLimitError}>
          Send ({selectedCount}) Emails
        </Button>
      </DialogActions>
    </Form>
  );
};

export const BulkSendModal = ({ toggleShowBulkSend, rowData }) => {
  const dispatch = useDispatch();

  // lookup the recipients by flag, for each related business on each selected report.
  const recipientsByFlagByBusinessIdLookup = useSelector(
    selectEmailRecipientsByFlagByBusinessIdLookup,
  );

  const onSubmit = flags => {
    const payload = [];

    const reportJobs = rowData.map(({ reportJob }) => reportJob);
    const selectedRecipients = filterRecipients(
      reportJobs,
      recipientsByFlagByBusinessIdLookup,
      flags,
    );

    Object.values(selectedRecipients).forEach(
      ({ reportJobs, emailRecipients, businessUsers }) => {
        // prep payload data for each related business' recipients
        const businessUserIds = businessUsers.map(
          businessUser => businessUser.id,
        );
        const emailRecipientData = emailRecipients.map(recipient => {
          return { id: recipient.id, type: recipient.source.type };
        });
        const reportJobIds = reportJobs.map(reportJob => reportJob.id);

        if (
          reportJobIds.length !== 0 &&
          (businessUserIds.length !== 0 || emailRecipientData.length !== 0)
        ) {
          payload.push({
            business_user_ids: businessUserIds,
            email_recipients: emailRecipientData,
            email_template_text: "", // not used for now
            report_job_ids: reportJobIds,
            raw_email_address: "", // not used for now
          });
        }
      },
    );

    dispatch(ReportJobAction.email(payload));

    toggleShowBulkSend();
  };

  return (
    <ZoomyDialog open onClose={toggleShowBulkSend} maxWidth="lg" fullWidth>
      <Formik initialValues={initialFlags} onSubmit={onSubmit}>
        <BulkSendForm
          toggleShowBulkSend={toggleShowBulkSend}
          rowData={rowData}
        />
      </Formik>
    </ZoomyDialog>
  );
};
