import React from "react";

import { printPlacardV5 } from "actions";

import { Accreditation } from "constants/draftingAttributes";
import { PageSize, PlacardVersions } from "constants/pdf";

import { toTitleCase } from "lib";

import { sortByRoundAndPen } from "lib/auctionPens";
import {
  addSpaces,
  blackBox,
  breakword,
  calculateEstimateOfHowManyLines,
  getBreedAgeSexText,
  getVendorName,
  tick,
} from "lib/pdf";
import { formatTime } from "lib/timeFormats";

import agrinousLogo from "img/AgriNous-logo-black-white-base64";

import { RenderPlacard } from "./RenderPlacard";

export const getContentForPlacardV5 = (
  saleLots,
  deploymentIdBySaleLotIdLookup,
  deployments,
  vendorDeploymentBusinessBySaleLotIdLookup,
  isVendorBredBySaleLotIdLookup,
) => {
  const contents = [];
  sortByRoundAndPen(saleLots).forEach((saleLot, index) => {
    const deploymentBusiness =
      vendorDeploymentBusinessBySaleLotIdLookup[saleLot.id];
    const vendorName = getVendorName(saleLot, deploymentBusiness);
    const deploymentId = deploymentIdBySaleLotIdLookup[saleLot.id];
    const deployment = deployments[deploymentId];
    const publicDescription =
      saleLot?.draftingAttributes?.publicDescription || "";
    let descriptionLines = publicDescription.split(/\r\n|\r|\n/).length;

    if (descriptionLines === 0) {
      descriptionLines = calculateEstimateOfHowManyLines(publicDescription, 36);
    }

    const vendorNameLines = calculateEstimateOfHowManyLines(
      breakword(vendorName, 24),
      18,
    );
    const breedAgeSexLines = calculateEstimateOfHowManyLines(
      getBreedAgeSexText(saleLot),
      20,
    );

    let spaces = 0;
    const totalLines = vendorNameLines + breedAgeSexLines + descriptionLines;

    if (totalLines <= 5) {
      spaces = 2;
    }
    if (totalLines === 6) {
      spaces = 1;
    }

    contents.push([
      // Having this at the start, allows the text to overlap without causing z-index issues
      {
        image: `data:image/jpeg;base64,${agrinousLogo}`,
        width: 100,
        absolutePosition: { x: 30, y: 778 },
      },
      {
        layout: "noBorders",
        table: {
          widths: [829],
          body: [
            [""],
            [
              {
                text: " ",
              },
            ],
            [
              {
                image: `logo_${deployment.id}`,
                width: 800,
                height: 110,
                alignment: "center",
              },
            ],

            [
              {
                width: 90,
                text: breakword(vendorName, 24),
                fontSize: 60,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: saleLot.quantity ? saleLot.quantity : "-",
                fontSize: 110,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: getBreedAgeSexText(saleLot),
                fontSize: 55,
                alignment: "center",
                bold: true,
                margin: [20, 0, 0, 20],
              },
            ],
            [
              {
                text: ` ${
                  saleLot.averageWeightFormattedRounded
                    ? ` @ ${saleLot.averageWeightFormattedRounded} Kg`
                    : ""
                }`,
                fontSize: 80,
                alignment: "center",
                bold: true,
              },
            ],
            ...addSpaces(spaces),
            [
              {
                text: `${
                  saleLot.draftingAttributes?.publicDescription
                    ? saleLot.draftingAttributes.publicDescription.toUpperCase()
                    : ""
                }`,
                fontSize: 40,
                alignment: "center",
                margin: [20, 0, 0, 35],
              },
            ],
          ],
        },
      },
      {
        columns: [
          {
            canvas: [
              {
                type: "rect",
                x: 20,
                y: 15,
                w: 1136,
                h: 790,
                lineWidth: 10,
                lineColor: deployment.corporateColor
                  ? deployment.corporateColor
                  : "#000000",
              },
            ],
            absolutePosition: { x: 4, y: 4 },
          },
          blackBox(
            "Vendor \nBred",
            34,
            40,
            deployment,
            840,
            845,
            1050,
            false,
            210,
            115,
          ),
          tick(isVendorBredBySaleLotIdLookup[saleLot.id] === true, 1052, 44),
          blackBox(
            "PTIC",
            164,
            200,
            deployment,
            840,
            845,
            1050,
            false,
            210,
            115,
          ),
          tick(
            saleLot.draftingAttributes?.accreditationPTIC === Accreditation.ALL,
            1052,
            164,
          ),
          blackBox(
            "PCAS \nEligible",
            294,
            310,
            deployment,
            840,
            845,
            1050,
            false,
            210,
            115,
          ),
          tick(
            saleLot.draftingAttributes?.accreditationPCAS === Accreditation.ALL,
            1052,
            304,
          ),
          blackBox(
            "Grass Fed",
            424,
            450,
            deployment,
            840,
            845,
            1050,
            false,
            210,
            115,
          ),
          tick(
            saleLot.draftingAttributes?.accreditationGrassFed ===
              Accreditation.ALL,
            1052,
            434,
          ),
          blackBox(
            "Antibiotic \nFree",
            554,
            570,
            deployment,
            840,
            845,
            1050,
            false,
            210,
            115,
          ),
          tick(
            saleLot.draftingAttributes?.accreditationAntibioticFree ===
              Accreditation.ALL,
            1052,
            564,
          ),
          blackBox(
            "Weaned",
            684,
            715,
            deployment,
            840,
            845,
            1050,
            false,
            210,
            115,
          ),
          tick(saleLot.draftingAttributes?.isWeaned, 1052, 704),
          {
            text: `${toTitleCase(
              saleLot.livestockAgency ? saleLot.livestockAgency.name : "",
            )} Pen: ${saleLot.penName ? saleLot.penName : ""} ${
              saleLot.vendor.shortCode ? saleLot.vendor.shortCode : ""
            } ${getVendorName(saleLot, deploymentBusiness)}  ${formatTime(
              Date.now(),
            )}    -    TO BE SOLD AS DESCRIBED BY THE VENDOR, PARTICULARS FOR INFORMATION ONLY BUT NOT GUARANTEED.`,
            fontSize: 7,
            absolutePosition: { x: 140, y: 788 },
            // Insert a page break if there is another page.
            pageBreak: saleLots.length > index + 1 ? "after" : undefined,
          },
        ],
      },
    ]);
  });
  return contents;
};

export const RenderPlacardV5 = () => {
  return (
    <RenderPlacard
      action={printPlacardV5}
      version={PlacardVersions.V5}
      size={PageSize.A4}
    />
  );
};
