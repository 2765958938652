import React, { useState, forwardRef, useImperativeHandle } from "react";

import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import { sortBy } from "lodash";

const valueFormatter = ({ value }) => {
  if (Array.isArray(value)) {
    return value.map(v => v.label).join(", ");
  }

  return value?.label || "";
};

export const autoCompleteColumn = (
  headerName,
  field,
  options,
  multiple = false,
  optionsExtractor = null,
  pinned = null,
) => {
  return {
    headerName,
    field,
    cellEditor: "autoCompleteEditorDeprecated",
    cellEditorParams: {
      options,
      multiple,
      optionsExtractor,
    },
    editable: true,
    valueFormatter,
    pinned,
  };
};

/**
 * DEPRECATED. use AutoCompleteV2 instead
 */
export const AutoComplete = forwardRef((props, ref) => {
  const [pendingValue, setPendingValue] = useState("");

  const {
    filterOptions,
    multiple,
    options: providedOptions,
    data,
    value,
    optionsExtractor,
  } = props;

  // If provided, allow a callback to derive the options from a data driven filter.
  const options = optionsExtractor
    ? optionsExtractor(providedOptions, data)
    : providedOptions;

  function onChangeHandler(e, value) {
    setPendingValue(value);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return pendingValue;
      },
      afterGuiAttached: () => {
        setPendingValue(value);
      },
    };
  });
  // Because its an array of values, the option wont match with the value unless its a string,
  // or derived from the option.
  let defaultValue = value;
  if (multiple) {
    // When creating a new entry, the `value` prop will be `undefined`, check if it's an array before attempting to map it
    defaultValue = Array.isArray(value)
      ? options.filter(o => value.map(v => v.label).includes(o.label))
      : [];
  }
  return (
    <MuiAutocomplete
      style={{ width: 300 }}
      defaultValue={defaultValue}
      options={options}
      filterOptions={filterOptions}
      openOnFocus
      multiple={multiple}
      onChange={onChangeHandler}
      getOptionLabel={o => o?.label || ""}
      disableClearable
      renderInput={params => {
        return (
          <div ref={params.InputProps.ref}>
            {params.InputProps.startAdornment
              ?.map(selectedOption => selectedOption.props.label)
              .join(", ")}
            <input
              type="text"
              {...params.inputProps}
              className="ag-input-field-input ag-text-field-input"
            />
          </div>
        );
      }}
    />
  );
});

export function contextOptionFormatter(contextKey) {
  return function formatValue(params) {
    const options = params.context[contextKey] || [];
    return (
      options.find(option => {
        return option.value === params.value;
      })?.label || ""
    );
  };
}

export function listValueFormatter(valueFormatter) {
  return function formatListValue(params) {
    return Array.isArray(params.value)
      ? params.value.map(value => valueFormatter({ ...params, value }))
      : valueFormatter(params);
  };
}

export function listCellEditorParamsGetter(cellEditorParamsGetter) {
  return function getCellEditorParams(params) {
    return { ...cellEditorParamsGetter(params), multiple: true };
  };
}

export function contextOptionCellEditorParamsGetter(contextKey) {
  return function getCellEditorParams(params) {
    const options = params.context[contextKey] || [];
    return {
      values: sortBy(options, "label").map(option => option.value),
      isClearable: true,
    };
  };
}

export const AutoCompleteV2 = forwardRef((props, ref) => {
  const {
    filterOptions,
    multiple,
    options: providedOptions,
    renderOption,
    value,
    values,
  } = props;
  const [pendingValue, setPendingValue] = useState(value);

  const options = values || providedOptions;

  function onChangeHandler(e, value) {
    setPendingValue(props.parseValue(value));
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return pendingValue;
      },
    };
  });
  return (
    <MuiAutocomplete
      style={{ width: 300 }}
      filterOptions={filterOptions}
      value={pendingValue}
      options={options}
      openOnFocus
      multiple={multiple}
      onChange={onChangeHandler}
      getOptionLabel={props.formatValue}
      disableClearable={!props.isClearable}
      renderInput={params => {
        return (
          <div ref={params.InputProps.ref}>
            {params.InputProps.startAdornment
              ?.map(selectedOption => selectedOption.props.label)
              .join(", ")}
            <input
              type="text"
              tabIndex={-1}
              {...params.inputProps}
              className="ag-input-field-input ag-text-field-input"
            />
          </div>
        );
      }}
      renderOption={renderOption}
    />
  );
});
