import React from "react";

import PropTypes from "prop-types";

import { SubtleBadge } from "components/Badge";
import { FormCollapse } from "components/Form";
import { TextArea } from "components/Form/FormikControls/TextArea";
import { Row } from "components/Layout";

const header = (
  <Row>
    <SubtleBadge>Notes</SubtleBadge>
  </Row>
);

const NotesSection = React.memo(({ isOpen, onToggle, readOnly }) => {
  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      enableReinitialize
    >
      <Row margin={12} fullWidth>
        <TextArea
          name="vendorNote"
          type="text"
          label="Vendor Note"
          disabled={readOnly}
        />
      </Row>
    </FormCollapse>
  );
});
NotesSection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default NotesSection;
