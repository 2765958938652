import React from "react";

import { useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addPropertyToBusiness } from "actions/offline";

import { AddRow } from "components/BusinessPICSelector/AddBusinessPIC";
import { Dialog, DialogTitle, DialogContent } from "components/MaterialDialog";

import { getPropertiesArray } from "selectors";

import { useTheme } from "hooks";

export const AddPICModal = ({
  show,
  closeSelf,
  business,
  defaultPICName,
  handleAfterAdd,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const properties = useSelector(getPropertiesArray);
  const fullScreen = useMediaQuery(`(max-width: ${theme.breakpoints[1]}px)`);
  if (!show) {
    return null;
  }

  const handleAddPropertyToBusiness = (businessName, PIC, name) => {
    const propertyId = properties.find(p => p.PIC === PIC)?.id || uuidv4();
    dispatch(
      addPropertyToBusiness(
        {
          id: propertyId,
          PIC,
          name,
        },
        business.id,
      ),
    );
    // Even if the id changes (which it generally shouldn't) it should
    // pass through actions and offline lookup.
    handleAfterAdd && handleAfterAdd(propertyId);
    closeSelf();
  };

  return (
    <Dialog
      open={show}
      onClose={closeSelf}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle onClose={closeSelf}>Add PIC to {business.name}</DialogTitle>
      <DialogContent>
        <AddRow
          defaultBusinessName={business.name}
          defaultPICName={defaultPICName}
          handleAddBusinessPIC={handleAddPropertyToBusiness}
          showProperty
        />
      </DialogContent>
    </Dialog>
  );
};

AddPICModal.propTypes = {
  show: PropTypes.bool,
  closeSelf: PropTypes.func,
  business: PropTypes.object,
  defaultPICName: PropTypes.string,
  handleAfterAdd: PropTypes.func,
};
