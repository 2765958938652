import React from "react";

import PropTypes from "prop-types";
import { withTheme } from "styled-components";

const DeliveredLogo = ({ color, theme, dark }) => {
  const logoColor = color || theme.colors.primary;
  const altColor = dark ? theme.colors.black : theme.colors.white;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3318.92 3318.92"
      width="33px"
      height="33px"
    >
      <defs />
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2519574723248">
          <path
            fill={altColor}
            stroke="#427DB3"
            strokeWidth="105.83"
            strokeMiterlimit="22.9256"
            d="M1384.39 159.82c-1989.07,374.51 -1457.83,3378.41 568.4,3012.62 1955.31,-352.98 1441.4,-3391 -568.4,-3012.62z"
          />
          <g>
            <g>
              <path
                fill={logoColor}
                d="M2096.13 1840.71c47.72,-7.87 83.07,10.92 112.57,31.44 3.97,2.76 5.84,5.7 9.59,9.16 23.49,21.71 38.63,52.72 42.9,84.86 11.43,86 -50.85,155.48 -124.83,165.36 -88.01,11.74 -154.39,-49.77 -166.06,-124.45 -7.2,-46.13 9.56,-85.77 31.34,-113.82 22.82,-29.39 56.11,-46.21 94.49,-52.55zm-274.61 -663.17l551.2 0.09 -0.03 -110.14 -584.33 0c-18.46,0 -31.89,3.98 -44.84,12.86 -42.9,29.4 -32.06,81.07 -32.05,138.88l0.02 703.8c-75.87,21.48 -153.18,41.55 -229.57,61.73 -77.25,20.4 -152.64,41.08 -229.79,61.67 -76.37,20.39 -152.92,40.06 -229.83,61.6 -131.7,36.87 -128.09,16.97 -108.85,88.15 16.33,60.36 7.86,52.07 85.39,32.13 19.17,-4.93 37.92,-10.38 57.12,-15.39l688.58 -184.43c37.84,-10.41 77.41,-22.03 115.22,-30.77 3.84,77.61 40.53,138.88 79.49,174.79 24.25,22.34 44.1,36.38 75.94,50.33 31.07,13.61 67.5,20.51 106.3,20.14 208.12,-1.98 331.93,-249.76 191.23,-422.47 -2.24,-2.75 -4.62,-4.9 -6.99,-7.73 -2.31,-2.74 -3.83,-4.53 -6.35,-7.15 -9.06,-9.44 -20.28,-17.73 -30.45,-25.94 -75.22,-60.73 -198.61,-66.27 -278.92,-17.62 -23.21,14.06 -37.46,24.68 -55.5,42.56 -16.55,16.4 -30.22,35.53 -42.15,55.9 -6.67,11.38 -5.52,15.26 -11.32,15.26l-59.37 -0.05 -0.02 -362.34c-0.72,-99.39 1.44,-205.47 -0.14,-303.57l0.01 -32.29z"
              />
            </g>
            <path
              fill={logoColor}
              d="M1409.21 1687.42c-5.29,7.65 -6.14,10.57 -10.26,16.27 -5.58,7.72 -4.78,7.94 -7.64,12.49l-8.25 12.15c-0.97,1.65 -1.27,2.86 -2.3,4.47 -0.91,1.44 -1.63,2.36 -2.64,3.77 -3.8,5.31 -6.48,10.88 -10.28,16.25 -8.76,12.37 -21.84,35.42 -31.11,49.13 -3.06,4.52 -7.6,10.27 -8.82,17.14 -1.43,8.09 0.41,15.25 5.43,21.79 3.81,4.98 25.78,17.25 32.21,21.05 5.52,3.27 9.12,6.63 18.43,6.66 18,0.07 25.55,-17.45 30.2,-25.48 12.99,-22.39 26.47,-44.1 39.79,-66.35l108.95 -181.82c3.07,-5.32 5.34,-11.92 3.04,-20.61 -2.23,-8.41 -6.89,-12.52 -13.04,-16.56 -5.42,-3.55 -10.9,-6.53 -16.49,-9.9l-165.24 -99.19c-22.08,-13.25 -43.66,-26.73 -65.92,-39.6 -8.84,-5.11 -21.75,-16.85 -38.92,-7.6 -7.03,3.79 -10.25,9.91 -13.05,14.58 -4.97,8.28 -16.48,25.09 -18.02,34.03 -1.3,7.53 1.37,16.38 6.16,21.73 4.85,5.41 10.43,7.11 15.92,10.83l42.33 23.8c3.01,1.57 5.25,3.3 8.22,4.94l42 23.48c2.91,1.52 5.26,3.42 8.17,5.03 3.04,1.69 5.51,2.82 8.55,4.45 2.93,1.58 5.23,3.46 8.17,5.04 3.02,1.62 5.53,2.84 8.55,4.47 2.92,1.57 5.25,3.46 8.16,5.05 3.02,1.65 5.53,2.83 8.56,4.45 2.67,1.44 5.49,3.46 7.98,4.98 -5.21,2.07 -183.88,46.22 -188.77,47.44 -31.4,7.85 -62.82,15.63 -94.23,23.48 -14.93,3.73 -31.23,5.27 -39.72,16.06 -9.33,11.85 -4.6,22.34 -0.36,39.84 1.55,6.41 3.7,17.49 6.53,22.91 11.83,22.65 36.76,11.63 55.64,6.91l235.54 -58.8c15.08,-3.77 32.12,-8.9 47.15,-11.71l-20.62 32.95z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

DeliveredLogo.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

DeliveredLogo.defaultProps = {
  dark: false,
};

export default withTheme(DeliveredLogo);
