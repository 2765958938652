import React from "react";

import sumBy from "lodash/sumBy";
import { PropTypes } from "prop-types";

import { sortPenByOrder } from "lib/auctionPens";
import {
  createAndOpenPdf,
  getSaleAndDocumentInformation,
  getPenDetails,
} from "lib/pdf";

const columnWidths = [90, "*", 72, 60, 60];

const pageMargin = 12;
const pageMarginBottom = 40;
const blankRowHeight = 24;

// In order to keep the auction pen data and the following blank
// row (which is used for note-taking) together, these are rendered
// as a nested table, taking advantage of the outer table's
// dontBreakRows property.
const tableData = auctionPens =>
  auctionPens.reduce((arr, auctionPen) => {
    const rows = [];
    const quantity = {
      text: `${sumBy(auctionPen.sale_lots, "quantity")}`,
      style: "mainText",
    };

    rows.push([getPenDetails(auctionPen), "", "", quantity, ""]);
    rows.push([{ text: "", colSpan: columnWidths.length }]);
    const rowTable = {
      border: [false, false, false, false],
      marginTop: -6,
      table: {
        headerRows: 0,
        widths: columnWidths,
        heights(row) {
          return row === 0 ? "auto" : blankRowHeight;
        },
        body: rows,
      },
    };
    arr.push([rowTable]);
    return arr;
  }, []);

// It would have been easier to insert a page break when hitting the
// is lane_start, however page break in nested tables doesn't work wth
// PDFmake.  So we create a new content block for each lane.
const getLanes = auctionPens => {
  let lane = 0;
  const lanes = [[]];
  sortPenByOrder(auctionPens).forEach((auctionPen, i) => {
    if (i > 0 && auctionPen.isLaneStart) {
      lane += 1;
      lanes.push([]);
    }
    lanes[lane].push(auctionPen);
  });
  return lanes;
};

// Render the outer tables, one for each lane.
const getContent = auctionPens =>
  getLanes(auctionPens).map((lane, i) => ({
    pageBreak: i > 0 ? "before" : null,
    table: {
      headerRows: 1,
      dontBreakRows: true,
      widths: ["*"],
      body: [
        [
          {
            border: [false, false, false, false],
            table: {
              headerRows: 0,
              dontBreakRows: true,
              widths: columnWidths,
              body: [
                [
                  { text: "PEN", style: "tableHeader" },
                  { text: "PURCHASER", style: "tableHeader" },
                  { text: "PRICE $", style: "tableHeader" },
                  { text: "IN", style: "tableHeader" },
                  { text: "OUT", style: "tableHeader" },
                ],
              ],
            },
          },
        ],
        ...tableData(lane),
      ],
    },
  }));

export const DeliveryCard = ({
  auctionPens,
  sale,
  agencyName,
  round,
  history,
}) => {
  const pdfDefinition = {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [pageMargin, pageMargin, pageMargin, pageMarginBottom],
    content: getContent(auctionPens),
    footer: getSaleAndDocumentInformation(
      auctionPens,
      sale,
      agencyName,
      round,
      pageMargin,
    ),
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 20,
      },
      mainText: {
        bold: true,
        fontSize: 20,
      },
    },
  };
  createAndOpenPdf(pdfDefinition, history);

  return <div />;
};

DeliveryCard.propTypes = {
  auctionPens: PropTypes.array,
  sale: PropTypes.object,
  history: PropTypes.object,
  agencyName: PropTypes.string,
  round: PropTypes.object,
};
