export const BusinessInSaleColId = {
  ROLE_IN_SALE: "7ae7b238-4c4f-470f-9ea3-7e0657200bac",
};
export const BusinessInSaleColumnDef = {
  [BusinessInSaleColId.ROLE_IN_SALE]: {
    colId: BusinessInSaleColId.ROLE_IN_SALE,
    field: "roleInSale",
    headerName: "Role In Sale",
  },
};
