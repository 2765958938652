import React from "react";

import { useField } from "formik";
import { orderBy, uniq } from "lodash";
import partition from "lodash/partition";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ToggleButtons } from "components/Form/FormikControls";

import { colors } from "constants/theme";

import { getMarkoptionsByDeploymentId, getMarksBySpecies } from "selectors";

const MarkingField = ({
  name = undefined,
  label = undefined,
  tooltip = null,
  deploymentId = null,
  disabled = false,
  useAllDeployments = false,
}) => {
  // MarkingField allows users to select from their managed marks.
  const [field, ignored, helpers] = useField(name);

  const deploymentMarkOptions = useSelector(
    getMarkoptionsByDeploymentId(deploymentId),
  );

  const allMarks = useSelector(getMarksBySpecies);

  const markOptions = orderBy(
    useAllDeployments ? allMarks : deploymentMarkOptions,
    "order",
  );

  const [selectedMarks, customMarks] = React.useMemo(() => {
    const currentValue = field.value || [];
    return partition(currentValue, m =>
      markOptions.find(mo => mo.short_code === m.location),
    );
  }, [field.value, markOptions]);

  const handleChange = React.useCallback(
    (event, newMarkLocations) => {
      const newMarks = newMarkLocations.map(location => ({
        location,
        color: colors.black,
      }));
      helpers.setValue([...newMarks, ...customMarks].filter(Boolean));
      helpers.setTouched(true, false);
    },
    [helpers, customMarks],
  );

  // Toggle buttons use referential equality to determine if a option is selected..
  // So map it to just location (which is the short_code)
  const toggleValues = selectedMarks.map(m => m?.location) || [];
  const toggleOptions = uniq(markOptions.map(o => o.short_code));

  if (toggleOptions.length === 0) {
    return null;
  }

  return (
    <ToggleButtons
      label={label}
      tooltip={tooltip}
      onChange={handleChange}
      options={toggleOptions}
      value={toggleValues}
      disabled={disabled}
    />
  );
};

MarkingField.propTypes = {
  name: PropTypes.string,
  tooltip: PropTypes.string,
  label: PropTypes.string,
  deploymentId: PropTypes.number.isRequired,
};

MarkingField.defaultProps = {
  name: "marks",
  label: "Marks",
};

export default React.memo(MarkingField);
