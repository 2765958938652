import React from "react";

import { useTheme } from "hooks";

export const Delivered = ({ color }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.inactiveStep;

  return (
    <svg
      width="15"
      height="128"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 467.4 467.4"
    >
      <g fill={svgColor}>
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_1680828675248">
          <g>
            <path
              className="fil0"
              d="M375.99 296.05c15.18,-2.5 26.43,3.47 35.81,10 1.26,0.88 1.86,1.82 3.05,2.92 7.47,6.9 12.29,16.76 13.65,26.99 3.63,27.36 -16.18,49.46 -39.71,52.6 -28,3.73 -49.11,-15.83 -52.82,-39.59 -2.29,-14.67 3.04,-27.28 9.97,-36.2 7.26,-9.35 17.84,-14.7 30.05,-16.72zm-87.35 -210.95l175.33 0.03 -0.01 -35.03 -185.87 0c-5.87,0 -10.14,1.26 -14.26,4.09 -13.65,9.35 -10.2,25.79 -10.19,44.18l0 223.87c-24.13,6.83 -48.72,13.21 -73.02,19.63 -24.58,6.49 -48.55,13.07 -73.1,19.62 -24.29,6.48 -48.64,12.74 -73.1,19.59 -41.89,11.73 -40.75,5.4 -34.63,28.04 5.2,19.2 2.5,16.57 27.16,10.22 6.1,-1.57 12.07,-3.3 18.18,-4.89l219.03 -58.67c12.03,-3.31 24.62,-7 36.65,-9.78 1.22,24.68 12.89,44.17 25.28,55.59 7.71,7.11 14.03,11.58 24.16,16.01 9.88,4.33 21.47,6.53 33.81,6.41 66.2,-0.63 105.58,-79.45 60.83,-134.38 -0.71,-0.88 -1.47,-1.56 -2.23,-2.46 -0.73,-0.88 -1.21,-1.44 -2.02,-2.28 -2.88,-3 -6.45,-5.64 -9.68,-8.25 -23.93,-19.32 -63.18,-21.08 -88.72,-5.6 -7.39,4.47 -11.92,7.85 -17.66,13.53 -5.26,5.22 -9.61,11.31 -13.41,17.78 -2.12,3.63 -1.75,4.86 -3.59,4.86l-18.89 -0.02 -0.01 -115.25c-0.23,-31.62 0.46,-65.36 -0.04,-96.57l0 -10.27z"
            />
          </g>
          <path
            className="fil0"
            d="M157.49 247.29c-1.68,2.44 -1.95,3.36 -3.26,5.18 -1.78,2.45 -1.53,2.52 -2.43,3.97l-2.63 3.86c-0.31,0.53 -0.4,0.91 -0.73,1.43 -0.29,0.45 -0.52,0.75 -0.84,1.19 -1.21,1.7 -2.06,3.47 -3.27,5.17 -2.79,3.94 -6.95,11.27 -9.9,15.63 -0.97,1.44 -2.41,3.27 -2.8,5.45 -0.46,2.58 0.13,4.86 1.72,6.94 1.22,1.58 8.21,5.48 10.25,6.69 1.76,1.04 2.9,2.11 5.86,2.12 5.73,0.02 8.13,-5.55 9.61,-8.1 4.13,-7.13 8.42,-14.03 12.66,-21.11l34.65 -57.84c0.98,-1.69 1.7,-3.79 0.97,-6.55 -0.71,-2.68 -2.19,-3.98 -4.15,-5.27 -1.72,-1.13 -3.47,-2.08 -5.24,-3.15l-52.56 -31.55c-7.03,-4.22 -13.89,-8.5 -20.97,-12.6 -2.81,-1.62 -6.92,-5.35 -12.38,-2.41 -2.24,1.2 -3.26,3.15 -4.15,4.63 -1.58,2.64 -5.25,7.99 -5.74,10.83 -0.41,2.4 0.44,5.21 1.96,6.91 1.55,1.72 3.32,2.26 5.07,3.45l13.46 7.57c0.96,0.5 1.67,1.04 2.62,1.57l13.36 7.47c0.92,0.48 1.67,1.09 2.59,1.6 0.97,0.53 1.76,0.89 2.73,1.41 0.93,0.5 1.66,1.1 2.59,1.61 0.96,0.51 1.76,0.9 2.72,1.42 0.93,0.5 1.67,1.1 2.6,1.6 0.96,0.53 1.76,0.9 2.72,1.42 0.85,0.46 1.75,1.1 2.54,1.58 -1.66,0.66 -58.49,14.7 -60.05,15.09 -9.98,2.5 -19.98,4.98 -29.97,7.47 -4.75,1.19 -9.93,1.68 -12.63,5.11 -2.97,3.77 -1.47,7.11 -0.12,12.67 0.5,2.04 1.18,5.57 2.08,7.29 3.76,7.2 11.69,3.7 17.7,2.2l74.92 -18.7c4.8,-1.2 10.22,-2.84 15,-3.73l-6.56 10.48z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Delivered;
