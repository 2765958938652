import React from "react";

import {
  faCheck,
  faExclamationCircle,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SubtleBadge } from "components/Badge";
import { SmallFaIcon } from "components/FaIcon";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Row } from "components/Layout";
import { FaIcon } from "components/ScanningScreen/Elements";

import { getMtHostSessionFileNameOptionsByDeviceId } from "selectors";

import { useFieldValue } from "hooks";

import WeightSettingsForm from "./Form";

const DeviceWarningIcon = styled(FaIcon)`
  margin-left: 21px;
  margin-top: 2px;
`;

const DeviceCollapseSection = ({ device, isOpen, onToggle, id }) => {
  const { deviceId, name } = device;

  const deviceValues = useFieldValue(deviceId);

  const fileOptions = useSelector(getMtHostSessionFileNameOptionsByDeviceId)[
    deviceId
  ];

  const filesNotAvailable = !fileOptions.length;

  const header = (
    <Row>
      <SubtleBadge>{name}</SubtleBadge>
      <Row alignCenter flexWrap>
        <CollapseLabel>
          {deviceValues?.inUse ? (
            <SmallFaIcon icon={faCheck} color="success" />
          ) : (
            <SmallFaIcon icon={faTimes} color="error" />
          )}
          {filesNotAvailable && (
            <>
              <DeviceWarningIcon color="warning" icon={faExclamationCircle} />
              &nbsp;No Files Available
            </>
          )}
        </CollapseLabel>
      </Row>
    </Row>
  );

  return (
    <FormCollapse isOpen={isOpen} onToggle={onToggle} header={header} id={id}>
      <WeightSettingsForm deviceId={deviceId} />
    </FormCollapse>
  );
};

export default DeviceCollapseSection;
