import React from "react";

import { faCodeMerge } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

import {
  WeighLotScanAction,
  closeConfirmModal,
  openConfirmModal,
} from "actions";

import { MESSAGE_CANT_BE_UNDONE } from "components/ConfirmDialog";
import { Button } from "components/Form";
import { Column as LayoutColumn } from "components/Layout";

import { AgGridTables } from "constants/aggrid";

import { getDollarPriceStringFromCents, formatWeightKg } from "lib";

import { getSaleLotById, getScanByEid, getWeighLotScanById } from "selectors";

export const MapWeighLotScanButton = ({
  tableName,
  saleLotId,
  eid,
  weighLotScanId,
  closeSelf,
}) => {
  const saleLot = useSelector(getSaleLotById(saleLotId));

  const scan = useSelector(getScanByEid(eid));

  const weighLotScan = useSelector(getWeighLotScanById(weighLotScanId));

  const dispatch = useDispatch();

  const addWeighToSaleLot = saleLotId => {
    dispatch(WeighLotScanAction.addWeightToSaleLot(weighLotScanId, saleLotId));
    dispatch(closeConfirmModal());
    closeSelf();
  };

  const confirmAddWeightToSaleLot = () => {
    const saleLotHasWeight = !!saleLot.total_mass_grams;
    const saleLotHasPrice = !!saleLot.total_price_cents;

    let message = `This will set ${formatWeightKg(
      weighLotScan.totalMassGrams,
    )} to Sale Lot ${saleLot.lot_number}. `;

    if (saleLotHasWeight) {
      message += `\n\nThis sale lot already has a weight of ${formatWeightKg(
        saleLot.total_mass_grams,
      )}, which will be overwritten. `;
    }

    if (saleLotHasPrice) {
      message += `\n\nThis sale lot already has a price of ${`$${getDollarPriceStringFromCents(
        saleLot.total_price_cents,
      )}`}, which will be overwritten`;
    }

    message += `\n\n${MESSAGE_CANT_BE_UNDONE}`;

    dispatch(
      openConfirmModal({
        title: "Set weight of this Weigh Lot Scan to Sale Lot Weight?",
        message,
        actions: [
          {
            label: "Cancel",
            secondary: true,
            onClick: () => dispatch(closeConfirmModal()),
          },
          {
            label: "Confirm",
            onClick: () => addWeighToSaleLot(saleLotId),
          },
        ],
      }),
    );
  };

  const linkWeighLotScanToScan = () => {
    dispatch(WeighLotScanAction.manaullyMapToScan(weighLotScanId, scan));
    closeSelf();
  };

  return (
    <LayoutColumn padding={1}>
      <Button
        inline
        onClick={
          tableName === AgGridTables.SCAN
            ? linkWeighLotScanToScan
            : confirmAddWeightToSaleLot
        }
        disabled={!saleLot && !scan}
      >
        <FontAwesomeIcon icon={faCodeMerge} /> Map To{" "}
        {tableName === AgGridTables.SCAN ? "Scan" : "Sale Lot"}
      </Button>
    </LayoutColumn>
  );
};
