import { intersection, orderBy, spread } from "lodash";
import { createSelector } from "reselect";

import { SaleSearchFields } from "constants/globalSearch";
import { SaleStatus } from "constants/sale";

import { getDateDrivenSaleStatus, getSaleStatus } from "lib/sale";

import { getActiveRole, getSales, getSaleSearchBySearchField } from "selectors";

const applyFilter = (sales, filterField, filterValues) => {
  const selectedSaleIds = Object.values(sales).map(
    sale => sale.livestocksale_id,
  );
  const filteredSaleIds = filterValues
    ? selectedSaleIds.filter(saleId =>
        filterValues.includes(sales[saleId][filterField]),
      )
    : selectedSaleIds;
  return filteredSaleIds;
};

const createFilterSelector = (filterField, searchField) =>
  createSelector(
    getSales,
    getSaleSearchBySearchField(searchField),
    (sales, filterValues) => applyFilter(sales, filterField, filterValues),
  );

export const selectSaleIdFilteredSaleIds = createFilterSelector(
  "livestocksale_id",
  SaleSearchFields.SaleId,
);

export const selectSpeciesFilteredSaleIds = createFilterSelector(
  "species_id",
  SaleSearchFields.Species,
);
export const selectSaleyardFilteredSaleIds = createFilterSelector(
  "saleyard_name",
  SaleSearchFields.Saleyard,
);
export const selectSaleTypeFilteredSaleIds = createFilterSelector(
  "sale_type",
  SaleSearchFields.SaleType,
);
export const selectSaleTitleFilteredSaleIds = createFilterSelector(
  "sale_title",
  SaleSearchFields.Title,
);
export const selectSaleDateFilteredSaleIds = createFilterSelector(
  "date",
  SaleSearchFields.Date,
);

export const selectSaleStatusFilteredSaleIds = createSelector(
  getSales,
  getSaleSearchBySearchField(SaleSearchFields.Status),
  getActiveRole,
  (sales, filterValues, role) => {
    const selectedSaleIds = filterValues
      ? Object.values(sales).reduce((result, sale) => {
          const saleStatus = getSaleStatus(sale, role);
          if (filterValues.includes(saleStatus)) {
            result.push(sale.livestocksale_id);
          }
          return result;
        }, [])
      : Object.values(sales).map(sale => sale.livestocksale_id);

    return selectedSaleIds;
  },
);

export const selectFilteredSaleIds = createSelector(
  [
    selectSaleIdFilteredSaleIds,
    selectSaleTitleFilteredSaleIds,
    selectSpeciesFilteredSaleIds,
    selectSaleyardFilteredSaleIds,
    selectSaleTypeFilteredSaleIds,
    selectSaleDateFilteredSaleIds,
    selectSaleStatusFilteredSaleIds,
  ],
  (...filterResults) => {
    const combinedSaleIds = spread(intersection)(filterResults);
    return combinedSaleIds;
  },
);

export const selectSaleIdsGroupedByPastAndFuture = createSelector(
  [getSales, selectFilteredSaleIds],
  (sales, filteredSaleIds) => {
    const pastSaleIds = [];
    const futureSaleIds = [];

    const filteredSales =
      filteredSaleIds && filteredSaleIds.length > 0
        ? filteredSaleIds.map(saleId => sales[saleId]).filter(Boolean)
        : [];

    const orderedSales = orderBy(filteredSales, "date", "desc");

    Object.values(orderedSales).forEach(sale => {
      const saleStatus = getDateDrivenSaleStatus(new Date(sale.date));

      if (saleStatus === SaleStatus.PAST) {
        pastSaleIds.push(sale.livestocksale_id);
      } else if (
        saleStatus === SaleStatus.FUTURE ||
        saleStatus === SaleStatus.IN_PROGRESS
      ) {
        futureSaleIds.push(sale.livestocksale_id);
      }
    });

    return [pastSaleIds, futureSaleIds];
  },
);
