import React, { useState } from "react";

import { DialogActions } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Fuse from "fuse.js";
import { useDispatch, useSelector } from "react-redux";

import { bulkMoveScans, uploadScansAction } from "actions";

import { Button, SearchInput, SecondaryButton } from "components/Form";
import { DialogContent } from "components/MaterialDialog";

import CompactSaleLotCardList from "containers/WeighbridgeSaleView/CompactSaleLotCardList";

import { auctionPenSellingOrderSort } from "lib/auctionPens";
import { pluralize } from "lib/pluralize";

import { getSaleLotsBySale, getScans, selectConnectedScanner } from "selectors";

export const SelectSaleLotForEids = ({ eids, onBack, onClose }) => {
  const [selectedSaleLotId, setSelectedSaleLotId] = useState(null);

  const deviceId = useSelector(
    state => selectConnectedScanner(state)?.device_id || null,
  );
  const allLots = useSelector(getSaleLotsBySale);
  const scans = useSelector(getScans);
  const lotsUsed = new Set(
    eids.map(eid => scans[eid]?.sale_lot_id).filter(Boolean),
  );
  const [searchText, setSearchText] = useState("");
  const [orderedLotIds, setOrderedLotIds] = useState([]);

  const dispatch = useDispatch();
  const updateSaleLot = () => {
    // If any of the scans don't exist, create them
    const eidsToBulkMove = eids.filter(eid => scans[eid]);
    const eidsToUpload = eids
      .filter(eid => !scans[eid])
      .map(eid => ({
        EID: eid,
        created: new Date().toISOString(),
        device_id: deviceId,
      }));
    // Then move the rest.
    if (eidsToBulkMove.length > 0) {
      dispatch(bulkMoveScans(eidsToBulkMove, selectedSaleLotId));
    }
    if (eidsToUpload) {
      dispatch(uploadScansAction(eidsToUpload, selectedSaleLotId));
    }
    onClose();
  };

  const searchValues = React.useMemo(
    () =>
      allLots.map(formattedSaleLot => ({
        id: formattedSaleLot.id,
        penName: formattedSaleLot.penName,
        vendorName: formattedSaleLot.vendor_name,
        vendorDisplayName: formattedSaleLot.vendorDisplayName,
        buyerName: formattedSaleLot.buyer_name,
      })),
    [allLots],
  );

  const searchIndex = React.useMemo(
    () =>
      new Fuse(searchValues, {
        threshold: 0.1,
        findAllMatches: true,
        keys: [
          {
            name: "penName",
            weight: 4,
          },
          {
            name: "vendorName",
            weight: 2,
          },
          "vendorDisplayName",
          "buyerName",
        ],
      }),
    [searchValues],
  );

  React.useEffect(() => {
    Promise.resolve().then(() =>
      setOrderedLotIds(
        (searchText
          ? searchIndex.search(searchText).map(fuseResult => fuseResult.item)
          : allLots.sort(auctionPenSellingOrderSort)
        ).map(lot => lot.id),
      ),
    );
  }, [allLots, searchIndex, searchText, setOrderedLotIds]);

  const scansPlural = pluralize("scan", eids.length);
  return (
    <>
      <DialogContent minHeight="20vh" shrink={1} dividers>
        <DialogContentText>
          Tap to select which lot to move the selected {scansPlural} to. This
          may also change the consignment the {scansPlural}{" "}
          {eids.length === 1 ? "is" : "are"} a part of.
        </DialogContentText>
        {lotsUsed.size > 1 && (
          <DialogContentText>
            Warning: These EIDs are currently assigned to multiple sale lots,
            and will be assigned to the single, selected sale lot after this
            operation.
          </DialogContentText>
        )}
        <SearchInput onChange={setSearchText} />
        <CompactSaleLotCardList
          orderedIds={orderedLotIds}
          onSelectSaleLot={setSelectedSaleLotId}
          selectedIds={[selectedSaleLotId]}
        />
      </DialogContent>
      <DialogActions shrink={0}>
        {typeof onBack === "function" ? (
          <SecondaryButton onClick={onBack}>Back</SecondaryButton>
        ) : null}
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <Button
          data-tour="moveToSaleLot"
          onClick={updateSaleLot}
          disabled={selectedSaleLotId === null}
        >
          Move to Sale Lot
        </Button>
      </DialogActions>
    </>
  );
};
