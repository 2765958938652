import React from "react";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

/* To Remove DOM Errors of passing expanded down */
// eslint-disable-next-line no-unused-vars
const FilteredComponent = ({ expanded, ...others }) => (
  <FontAwesomeIcon {...others} />
);

export default styled(FilteredComponent).attrs(props => ({
  icon: props.expanded ? faChevronUp : faChevronDown,
  className: props.className,
}))`
  cursor: pointer;
`;
