import { useEffect } from "react";

import { useField, useFormikContext } from "formik";

export function withNamespace(namespace, name) {
  return namespace ? `${namespace}.${name}` : name;
}

export function withArrayNamespace(namespace, index) {
  return namespace ? `${namespace}[${index}]` : `[${index}]`;
}

export function withNamespaces(namespaces, name) {
  return namespaces.concat(name).filter(Boolean).reduce(withNamespace, "");
}

export function useSubmitHandler(allowInitialSubmit, setIsSubmitEnabled) {
  const { isValid, dirty, submitCount } = useFormikContext();

  // Allows for forms in create mode to enable the submit button, regardless of
  // the validation state until the user has attempted to submit the form as least once

  const isSubmitEnabled =
    (submitCount === 0 && allowInitialSubmit) || (dirty && isValid);

  useEffect(() => {
    setIsSubmitEnabled(isSubmitEnabled);
  }, [isSubmitEnabled, setIsSubmitEnabled]);
}

export function useFormikSubmit(allowInitialSubmit) {
  const { isValid, submitCount, handleSubmit } = useFormikContext();
  const isSubmitEnabled = isValid || (allowInitialSubmit && submitCount === 0);
  return { isSubmitEnabled, handleSubmit };
}

function referentialInequality(a, b) {
  return a !== b;
}

export function useChangeHandler(
  onChange,
  getIsFormDirty = referentialInequality,
) {
  const { initialValues, isValid, touched, values } = useFormikContext();

  const touchedCount = Object.keys(touched).length;
  const isDirty = getIsFormDirty(initialValues, values);
  useEffect(() => {
    if (touchedCount > 0 && isDirty) {
      onChange(values, isValid, isDirty);
    } else {
      onChange(null, false, false);
    }
  }, [isDirty, isValid, touchedCount, onChange, values]);
}

export function useSuggestedValue(suggestedValueFieldName, currentValue) {
  const [suggestedValueField] = useField(suggestedValueFieldName || "EMPTY");

  return suggestedValueFieldName &&
    suggestedValueField &&
    suggestedValueField.value !== currentValue
    ? suggestedValueField.value
    : undefined;
}
