import React, { memo } from "react";

import { useField } from "formik";
import * as Yup from "yup";

import DeploymentBusinessTable from "components/BusinessForm/DeploymentBusinessTable";
import { withNamespace } from "components/Form/FormikControls";

import {
  DeploymentEmailRecipientsHeader,
  DeploymentEmailRecipientsTable,
} from "./BusinessEmailRecipientsTable";
import { deploymentEmailRecipientBaseValidation } from "./DeploymentEmailRecipientForm";

export const deploymentEmailRecipientValidationFactory = () =>
  Yup.lazy(value =>
    Yup.object().shape({
      editingValue: deploymentEmailRecipientBaseValidation
        .shape({
          email: Yup.string()
            .email("Invalid Email")
            .required("Required")
            .test("is-email-unique", "That email already exists", email => {
              // Test the value in the email field against
              //   - the other DeploymentEmailRecipients in the dirty Formik state
              // ignore BusinessUsers - allow local version of BusinessUser email
              const emailIdPairList = [...value.values].map(
                ({ email, id }) => ({ email, id }),
              );

              return (
                // When there is an email address entered
                Boolean(email) &&
                emailIdPairList
                  // Find all of the emails that match the entered value
                  .filter(emailId => emailId.email === email.trim())
                  // and check that every match is the existing entry
                  .every(emailId => emailId.id === value.editingValue.id)
              );
            }),
        })
        .nullable(),
    }),
  );

const DeploymentBusinessEmailRecipientsComponent = ({
  masterBusinessId,
  primaryContactEmail,
  namespace: ns,
  isOpen,
  onEndEditing,
  onStartEditing,
  onSetPrimaryContactEmail,
  onToggle,
}) => {
  const emailRecipientsNs = withNamespace(ns, "emailRecipients");
  const emailRecipients = useField(emailRecipientsNs)[0].value;

  return (
    <DeploymentBusinessTable
      headerComponent={DeploymentEmailRecipientsHeader}
      initialValuesList={emailRecipients}
      isOpen={isOpen}
      masterBusinessId={masterBusinessId}
      primaryContactEmail={primaryContactEmail}
      namespace={emailRecipientsNs}
      onEndEditing={onEndEditing}
      onStartEditing={onStartEditing}
      onSetPrimaryContactEmail={onSetPrimaryContactEmail}
      onToggle={onToggle}
      tableComponent={DeploymentEmailRecipientsTable}
      validationSchema={deploymentEmailRecipientValidationFactory}
      dataTour="emailCollapse"
    />
  );
};

export default memo(DeploymentBusinessEmailRecipientsComponent);
