export enum SystemLabelName {
  // The animals in this lot have been manually flagged as having a health issue (Thanks GitHub Copilot)
  DECEASED = "Deceased",
  // The item was removed from the sale before the auction started
  WITHDRAWN = "Withdrawn",
  // The item went for auction, but didn't meet the reserve price and was therefore not sold
  PASSED_IN = "Passed In",
  // For flagging a sundry as a rounding adjustment.
  RCTI_ROUNDING_ADJUSTMENT = "RCTI Rounding Adjustment",
  INVOICE_ROUNDING_ADJUSTMENT = "Invoice Rounding Adjustment",
}
