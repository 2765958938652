import React from "react";

import { useSelector } from "react-redux";

import { Button } from "components/Form";
import { Row } from "components/Layout";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import { openSendVendorReportsModal } from "lib/navigation";

import { getIsLivestockAgent, getIsSaleyardAdmin } from "selectors";

import {
  useHasDeploymentPermission,
  useHasSaleyardPermission,
} from "hooks/useHasPermission";

export const SendVendorReportsButton = () => {
  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);
  const isLivestockAgent = useSelector(getIsLivestockAgent);

  let allowed = false;

  const hasSaleyardPermission = useHasSaleyardPermission(
    SaleyardPermissions.canSendVendorReportEmail,
  );
  const hasDeploymentPermission = useHasDeploymentPermission(
    DeploymentPermissions.canSendVendorReportEmail,
  );

  if (isSaleyardAdmin) {
    allowed = hasSaleyardPermission;
  }

  if (isLivestockAgent) {
    allowed = hasDeploymentPermission;
  }

  if (allowed) {
    return (
      <Row justifyEnd>
        <Button onClick={() => openSendVendorReportsModal()}>
          Send All Vendor Reports
        </Button>
      </Row>
    );
  }
  return null;
};
