import React from "react";

import { EditVendorSplitSaleLotModal } from "components/VendorSplits/VendorSplitSaleLotModal/index";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

function VendorSplitSaleLotModalAdapter(props) {
  const [hashParams, ignored, onClose] = useModalAdapter(
    ModalTypes.VendorSplitSaleLot,
    props,
  );
  const { saleLotId = null, parentSaleLotId } = hashParams;
  return (
    <EditVendorSplitSaleLotModal
      parentSaleLotId={parentSaleLotId}
      saleLotId={saleLotId}
      onClose={onClose}
    />
  );
}

export default VendorSplitSaleLotModalAdapter;
