import React from "react";

import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { BusinessField } from "components/Form/Fields";
import {
  Input,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";
import { GstMethodOptions } from "components/Sundry/Form/SundryPricingForm";

import { BaseTaxTypeOptions } from "constants/billing";
import { businessRoleFilterOptions } from "constants/businesses";
import { sundryPartyTypeOptions } from "constants/sundryTemplates";

import { selectLedgerAccountOptionsForLedgerEntryForm } from "selectors";

export function SundryTemplateForm(props) {
  const { namespace: ns = "" } = props;

  const ledgerAccountOptions = useSelector(
    selectLedgerAccountOptionsForLedgerEntryForm,
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input name={withNamespace(ns, "name")} label="Name" required />
      </Grid>

      <Grid item xs={12}>
        <Input
          name={withNamespace(ns, "note")}
          label="Note"
          required
          tooltip="Default note to include on the line item for this sundry."
        />
      </Grid>

      <Grid item xs={6} container>
        <h2>Paid From</h2>
        <Grid item xs={12}>
          <SelectField
            required
            name={withNamespace(ns, "paidFromType")}
            label="Output"
            options={sundryPartyTypeOptions}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={withNamespace(ns, "paidFromSearchFilter")}
            label="Search Filter"
            options={businessRoleFilterOptions}
            menuPortalTarget={document.body}
            isClearable
          />
        </Grid>
        <Grid item xs={12}>
          <BusinessField
            label="Business"
            name={withNamespace(ns, "paidFromBusinessId")}
          />
        </Grid>
      </Grid>

      <Grid item xs={6} container>
        <h2>Paid To</h2>
        <Grid item xs={12}>
          <SelectField
            name={withNamespace(ns, "paidToType")}
            label="Output"
            options={sundryPartyTypeOptions}
            menuPortalTarget={document.body}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={withNamespace(ns, "paidToSearchFilter")}
            label="Search Filter"
            options={businessRoleFilterOptions}
            menuPortalTarget={document.body}
            isClearable
          />
        </Grid>
        <Grid item xs={12}>
          <BusinessField
            label="Business"
            name={withNamespace(ns, "paidToBusinessId")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={4}>
        <SelectField
          name={withNamespace(ns, "glCode")}
          label="Account / GL Code"
          options={ledgerAccountOptions}
          menuPortalTarget={document.body}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <SelectField
          name={withNamespace(ns, "taxType")}
          label="Tax Type"
          options={BaseTaxTypeOptions}
          menuPortalTarget={document.body}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <SelectField
          name={withNamespace(ns, "gstMethod")}
          label="GST Method"
          options={GstMethodOptions}
          menuPortalTarget={document.body}
          required
        />
      </Grid>
    </Grid>
  );
}
