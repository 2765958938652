/* global google */
import * as queryString from "query-string";

import { loadScript } from "./scripts";

const serviceManager = {
  apiKey: null,
  isLoaded: false,
  libraries: [],
  pendingCallbackHandlers: [],
  scriptElement: null,
  services: {},
};

export const GoogleMapsService = {
  PLACE_AUTOCOMPLETE: "PLACE_AUTOCOMPLETE",
  PLACE_AUTOCOMPLETE_SESSION_TOKEN: "PLACE_AUTOCOMPLETE_SESSION_TOKEN",
  PLACE_DETAILS: "PLACE_DETAIL",
};

export const PlacesServiceStatus = {
  INVALID_REQUEST: "INVALID_REQUEST",
  NOT_FOUND: "NOT_FOUND",
  OK: "OK",
  OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",
  REQUEST_DENIED: "REQUEST_DENIED",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  ZERO_RESULTS: "ZERO_RESULTS",
};

export function getServiceFactory(serviceName) {
  return function getService() {
    return serviceManager.services[serviceName] || null;
  };
}

export function provisionPlaceAutocompleteService() {
  serviceManager.services[GoogleMapsService.PLACE_AUTOCOMPLETE] =
    new google.maps.places.AutocompleteService();
}

// The PlacesService requires a div - I think it's meant to be a map of
// some sort... but this show no ill side affects
const PLACEHOLDER_EL = document.createElement("div");

export function provisionPlaceDetailsService() {
  serviceManager.services[GoogleMapsService.PLACE_DETAILS] =
    new google.maps.places.PlacesService(PLACEHOLDER_EL);
}

export function provisionPlateAutocompleteSessionToken() {
  serviceManager.services[GoogleMapsService.PLACE_AUTOCOMPLETE_SESSION_TOKEN] =
    new google.maps.places.AutocompleteSessionToken();
}

const ServiceProvisionMap = {
  [GoogleMapsService.PLACE_AUTOCOMPLETE]: provisionPlaceAutocompleteService,
  [GoogleMapsService.PLACE_AUTOCOMPLETE_SESSION_TOKEN]:
    provisionPlateAutocompleteSessionToken,
  [GoogleMapsService.PLACE_DETAILS]: provisionPlaceDetailsService,
};

export const getPlaceDetailsService = getServiceFactory(
  GoogleMapsService.PLACE_DETAILS,
);

export const getPlaceAutocompleteService = getServiceFactory(
  GoogleMapsService.PLACE_AUTOCOMPLETE,
);

export const getPlaceAutocompleteSessionToken = getServiceFactory(
  GoogleMapsService.PLACE_AUTOCOMPLETE_SESSION_TOKEN,
);

function provisionService(serviceName) {
  ServiceProvisionMap[serviceName]();
}

export function notifyLibraryHandlersAvailable() {
  serviceManager.isLoaded = true;
  // update all of the callback handlers waiting on this service
  serviceManager.pendingCallbackHandlers.forEach(callbackHandler => {
    // check if the requested service has already been provisioned
    if (!serviceManager.services[callbackHandler.serviceName]) {
      // provisioned the requested service
      provisionService(callbackHandler.serviceName);
    }

    // call the callback onAvailable handler (if it's present,
    // the caller may have only registered an onError handler)
    typeof callbackHandler.onAvailable === "function" &&
      callbackHandler.onAvailable();
  });

  // clear all of the handlers
  serviceManager.pendingCallbackHandlers = [];
}

export function notifyLibraryHandlersError(error) {
  // update all of the callback handlers waiting on this service
  serviceManager.pendingCallbackHandlers.forEach(callbackHandler => {
    // call the callback onError handler (if it's present,
    // the caller may have only registered an onAvailable handler)
    typeof callbackHandler.onError === "function" &&
      callbackHandler.onError(error);
  });

  // clear all of the handlers
  serviceManager.pendingCallbackHandlers = [];
}

export function cancelServiceRequest(
  registeredOnAvailableCallback,
  registeredOnErrorCallback,
) {
  serviceManager.pendingCallbackHandlers =
    serviceManager.pendingCallbackHandlers.filter(
      callbackDesc =>
        // remove all instances of callbacks where:
        // the `onAvailable` callback is `registeredOnAvailableCallback` or
        // the `onError` callback is `registeredOnErrorCallback`
        callbackDesc.onAvailable !== registeredOnAvailableCallback &&
        callbackDesc.onError !== registeredOnErrorCallback,
    );
}

export function setGoogleMapsApiKey(apiKey) {
  serviceManager.apiKey = apiKey;
}

export function setGoogleMapsApiLibraries(libraries) {
  serviceManager.libraries = libraries;
}

export const BASE_GOOGLE_MAPS_API_URL =
  "https://maps.googleapis.com/maps/api/js";

export function getGoogleMapsApiScriptUrl(apiKey, libraries) {
  return queryString.stringifyUrl(
    {
      url: BASE_GOOGLE_MAPS_API_URL,
      query: {
        key: apiKey,
        libraries,
      },
    },
    { arrayFormat: "comma" },
  );
}

export function requestServiceFactory(serviceName, getService) {
  return function requestService(onAvailable, onError) {
    // immediately call the available handler if the service is already available
    const serviceInstance = getService();
    if (serviceInstance !== null) {
      onAvailable();
      return;
    }

    if (!serviceManager.scriptElement) {
      // No scripts have been added for the given libraries, add it in.
      serviceManager.scriptElement = loadScript(
        getGoogleMapsApiScriptUrl(
          serviceManager.apiKey,
          serviceManager.libraries,
        ),
        notifyLibraryHandlersAvailable,
        notifyLibraryHandlersError,
      );
    }

    // register for a callback after all of the pending scripts have loaded
    serviceManager.pendingCallbackHandlers.push({
      serviceName,
      onAvailable,
      onError,
    });

    // manually invoke the handlers when the script was loaded, but the service was not provisioned,
    // as there were no pending Callback handlers waiting for the requested service.
    // e.g. A request was placed and the script started loading, the request was cancelled and the script finished loading in that order
    if (serviceManager.isLoaded) {
      notifyLibraryHandlersAvailable();
    }
  };
}

export const requestPlaceAutocompleteService = requestServiceFactory(
  GoogleMapsService.PLACE_AUTOCOMPLETE,
  getPlaceAutocompleteService,
);

export const requestPlaceDetailsService = requestServiceFactory(
  GoogleMapsService.PLACE_DETAILS,
  getPlaceDetailsService,
);

export const requestPlaceAutocompleteSessionToken = requestServiceFactory(
  GoogleMapsService.PLACE_AUTOCOMPLETE_SESSION_TOKEN,
  getPlaceAutocompleteSessionToken,
);
