import React, { useCallback, useState } from "react";

import {
  faExclamationTriangle,
  faPlus,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { faCrosshairs } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { deleteSaleLotScansAction } from "actions";

import { SubtleBadge } from "components/Badge";
import { IconTextButton } from "components/Button";
import {
  ConfirmDialog,
  MESSAGE_CANT_BE_UNDONE,
} from "components/ConfirmDialog";
import { CollapseLabel, FormCollapse } from "components/Form";
import { Input } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { CurrentSaleReadOnly } from "components/ReadOnly";
import { ResponsiveText } from "components/ResponsiveText";

import { PenTypes } from "constants/auctionPens";
import { colors } from "constants/theme";

import { getScanRoute, openScanModal } from "lib/navigation";
import { getApiSale } from "lib/sale";
import {
  getCombinedLotNumber,
  getSaleLotScannedStatus,
  getScanStatusThreshold,
} from "lib/saleLot";
import { getScanPercentDisplayValue, getScanStatusColour } from "lib/scans";

import {
  getCurrentSale,
  getCurrentSaleyard,
  getEidsBySaleLotId,
  getSaleLotById,
} from "selectors";

const Percentage = styled.span`
  font-weight: 500;
  color: ${({ theme, color }) => theme.colors[color]};
`;

function ScanningCollapse(props) {
  const {
    isOpen,
    onToggle,
    saleLotId,
    quantity = 0,
    quantityProgeny = 0,
    readOnly,
  } = props;
  const [isRescanDialogOpen, setIsRescanDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const saleLot = useSelector(getSaleLotById(saleLotId)) || {};
  const speciesId = useSelector(state => getCurrentSale(state).species_id);
  const scannedCount = useSelector(
    state => (getEidsBySaleLotId(saleLotId)(state) || []).length,
  );

  const expectedScanCount = quantity + quantityProgeny;

  const saleyard = useSelector(getCurrentSaleyard);
  const lotNumber = getCombinedLotNumber(saleLot);

  const scannedStatus = getSaleLotScannedStatus(
    saleLot,
    scannedCount,
    getScanStatusThreshold(speciesId, saleyard),
  );

  const hasScanningStarted = scannedCount > 0;

  const scannedPercentDisplayValue = getScanPercentDisplayValue(
    expectedScanCount,
    scannedCount,
  );

  const header = (
    <Row>
      <SubtleBadge>Scanning</SubtleBadge>
      <Row alignCenter flexWrap>
        <CollapseLabel>{`${scannedCount}/${expectedScanCount} scanned`}</CollapseLabel>
        <Percentage color={getScanStatusColour(scannedStatus)}>
          {scannedPercentDisplayValue}
        </Percentage>
      </Row>
    </Row>
  );

  const { pathname, search, hash } = window.location;
  const returnTo = `${pathname}${search}${hash}`;

  const scanUrl = `#${getScanRoute(
    null,
    saleLot.id,
    true,
    null,
    returnTo,
    null,
    null,
    null,
    PenTypes.SELLING,
  )}`;

  const openRescanDialog = useCallback(
    () => setIsRescanDialogOpen(true),
    [setIsRescanDialogOpen],
  );

  const closeRescanDialog = useCallback(
    () => setIsRescanDialogOpen(false),
    [setIsRescanDialogOpen],
  );

  const onRescan = useCallback(() => {
    const sale = getApiSale();
    dispatch(deleteSaleLotScansAction(saleLotId, sale));

    const { pathname, search, hash } = window.location;
    const returnTo = `${pathname}${search}${hash}`;

    openScanModal(null, saleLotId, false, null, PenTypes.SELLING, returnTo);
  }, [dispatch, saleLotId]);

  const lotNumberString = lotNumber
    ? `Sale Lot #${lotNumber}`
    : "this Sale Lot";

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      id="collapse-scanning"
      dataTour={isOpen ? "hideScanning" : "showScanning"}
    >
      <Row justifyBetween style={{ padding: "0 12px 12px 12px" }} full>
        <Column>
          <CollapseLabel padding>
            <ResponsiveText
              mobile="Scanned Hd"
              tablet="Scanned Hd"
              desktop="Scanned Head Count"
            />
          </CollapseLabel>
          {hasScanningStarted ? (
            <div>{`${scannedCount}/${expectedScanCount}`}</div>
          ) : (
            <CollapseLabel>-</CollapseLabel>
          )}
        </Column>
        <Column>
          <CollapseLabel padding>
            <ResponsiveText
              mobile="%"
              tablet="%"
              desktop="Percentage Scanned"
            />
          </CollapseLabel>
          {hasScanningStarted ? (
            <Percentage status={scannedStatus}>
              {scannedPercentDisplayValue}
            </Percentage>
          ) : (
            <CollapseLabel>-</CollapseLabel>
          )}
        </Column>
        <CurrentSaleReadOnly>
          {hasScanningStarted ? (
            <Row alignEnd justifyEnd>
              <IconTextButton
                dataTour="addToScan"
                icon={faPlus}
                as={Link}
                to={scanUrl}
                disabled={readOnly}
              >
                Add to Scan
              </IconTextButton>
              <IconTextButton
                dataTour="rescan"
                icon={faRedo}
                onClick={openRescanDialog}
                disabled={readOnly}
              >
                Re-scan
              </IconTextButton>
            </Row>
          ) : (
            <Row data-tour="scanSaleLot" alignEnd justifyEnd>
              <IconTextButton
                icon={faCrosshairs}
                as={Link}
                to={scanUrl}
                disabled={readOnly}
              >
                Scan Sale Lot
              </IconTextButton>
            </Row>
          )}
        </CurrentSaleReadOnly>
      </Row>
      <Grid container>
        <Grid item xs={6}>
          <Input
            label="Tags Used Quantity"
            name="quantityTagsUsed"
            tooltip="The number of tags required (including replacement and new tags) for this Sale Lot. "
            type="number"
            disabled={readOnly}
          />
        </Grid>
      </Grid>

      <ConfirmDialog
        title="Confirm you want to Clear Scans"
        isOpen={isRescanDialogOpen}
        onCancel={closeRescanDialog}
        message={
          scannedCount > 0 ? (
            <div>
              <FontAwesomeIcon
                color={colors.warningOrange}
                icon={faExclamationTriangle}
              />{" "}
              Are you sure you want to clear all scans from {lotNumberString}?{" "}
              {MESSAGE_CANT_BE_UNDONE}{" "}
            </div>
          ) : (
            MESSAGE_CANT_BE_UNDONE
          )
        }
        buttonMessage="Clear Scans"
        onDelete={onRescan}
      />
    </FormCollapse>
  );
}

ScanningCollapse.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  saleLotId: PropTypes.string.isRequired,
};

export default ScanningCollapse;
