import queryString from "query-string";

import { MANUAL_ADJUSTMENT } from "constants/actionTypes";

import { serializeManualAdjustment } from "lib/serializers/manualAdjustments";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, livestockSaleId, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/manual_adjustments/", id, action),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const ManualAdjustmentAction = offlineActionCreator(
  MANUAL_ADJUSTMENT,
  serializeManualAdjustment,
  urlCreator,
  true,
);
