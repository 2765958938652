import * as Yup from "yup";

import { BillingRunStatus } from "constants/billingRuns";

export const ValidationSchema = Yup.object().shape({
  deploymentId: Yup.number().required("Required!"),
  exportedAtDateTime: Yup.date().nullable(),
  livestockSaleIds: Yup.array()
    .of(Yup.number())
    .min(1, "Required!")
    .required("Required!"),
  name: Yup.string().required("Required!"),
  ruleBookId: Yup.string().required("Required!"),
  status: Yup.string()
    .oneOf(Object.values(BillingRunStatus))
    .required("Required!"),
});
