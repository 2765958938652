import React, { memo, useCallback } from "react";

import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { CheckpointAction } from "actions";

import { ManualCheckpointType } from "components/Checkpoints/Constants";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  ZoomyDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { ApiModel } from "constants/loading";

import {
  closeAllHashModals,
  getLivestockSaleId,
  returnToLocation,
} from "lib/navigation";
import {
  convertDateTimeToUTCDateTime,
  formatUTCToLocalDateTimeString,
} from "lib/timeFormats";

import {
  getActiveRole,
  getIsSaleyardAdmin,
  getSellingDeploymentIdByLivestockSaleId,
} from "selectors";

import { getCheckpointById } from "selectors/checkpoints";

import { EditCheckpointForm } from "./EditCheckpointsForm";
import { Footer } from "./Footer";
import { ValidationSchema } from "./validationSchema";

function EditCheckpointModalTitleComponent(props) {
  const { checkpointId, onClose, checkpointTime } = props;

  return (
    <DialogTitle onClose={onClose}>
      <Row justifyBetween>
        {checkpointId ? "Edit" : "Create"} Checkpoint {checkpointTime}
      </Row>
    </DialogTitle>
  );
}

const EditCheckpointModalTitle = memo(EditCheckpointModalTitleComponent);
EditCheckpointModalTitle.propTypes = {
  checkpointId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  checkpointTime: PropTypes.string,
};

/**
 *
 * @param {Object} props
 * @param {Object} [props.options]
 * @param {Checkpoint|null} [props.options.presets]
 * @param {string|null} [props.checkpointId]
 * @param {string} [props.returnTo]
 * @returns {*}
 * @constructor
 */
export function EditCheckpointModal(props) {
  const { options, checkpointId, returnTo } = props;
  const presets = options?.presets || {};

  const { livestockSaleId = getLivestockSaleId() } = presets;

  const checkpoint = useSelector(getCheckpointById(checkpointId));
  const checkpointTime = checkpoint?.timestamp
    ? formatUTCToLocalDateTimeString(
        convertDateTimeToUTCDateTime(checkpoint?.timestamp),
      )
    : "";
  const deploymentId =
    useSelector(getSellingDeploymentIdByLivestockSaleId(livestockSaleId)) ||
    null;

  const user = useSelector(getActiveRole);
  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  const dispatch = useDispatch();

  const isEdit = checkpointId !== null;

  const onClose = useCallback(() => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }, [returnTo]);

  const onDelete = useCallback(() => {
    dispatch(CheckpointAction.delete(checkpointId));
    onClose();
  }, [dispatch, onClose, checkpointId]);

  const onSubmitForm = useCallback(
    formValues => {
      const {
        deploymentId,
        id,
        timestamp,
        livestockSaleId,
        hasUpdatesSince,
        comments,
        type,
        user_id,
      } = formValues;

      const payload = {
        deploymentId,
        id,
        timestamp,
        livestockSaleId,
        hasUpdatesSince,
        comments,
        isCommercialCheck: type === ManualCheckpointType.MANUAL_COMMERCIAL,
        isComplianceCheck: type === ManualCheckpointType.MANUAL_COMPLIANCE,
        type,
        user_id,
      };
      if (isEdit) {
        dispatch(CheckpointAction.update(payload));

        onClose();
      } else {
        dispatch(
          CheckpointAction.create(payload, {
            redirectToSale: livestockSaleId !== getLivestockSaleId(),
          }),
        );
        closeAllHashModals();
      }
    },
    [dispatch, isEdit, onClose],
  );

  const initialValues = {
    deploymentId: isSaleyardAdmin ? null : deploymentId,
    id: uuidv4(),
    timestamp: new Date(),
    livestockSaleId,
    comments: "",
    isCommercialCheck: false,
    isComplianceCheck: false,
    type: null,
    user_id: user.id,
    hasUpdatesSince: false,
  };
  if (checkpoint) {
    initialValues.deploymentId = checkpoint.deploymentId || deploymentId;
    initialValues.id = checkpoint.id;
    initialValues.timestamp = checkpoint.timestamp;
    initialValues.livestockSaleId =
      checkpoint.livestockSaleId || livestockSaleId;
    initialValues.comments = checkpoint.comments;
    initialValues.isCommercialCheck = checkpoint.isCommercialCheck;
    initialValues.isComplianceCheck = checkpoint.isComplianceCheck;
    initialValues.type = checkpoint.type;
    initialValues.user_id = checkpoint.user_id;
    initialValues.hasUpdatesSince = checkpoint.hasUpdatesSince;
  }

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="md" fullWidth>
      <WaitForSync
        requiredData={[
          ApiModel.BUSINESSES,
          ApiModel.DEPLOYMENTS,
          ApiModel.CHECKPOINTS,
        ]}
      >
        <EditCheckpointModalTitle
          checkpointId={checkpointId}
          onClose={onClose}
          checkpointTime={checkpointTime}
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validationSchema={ValidationSchema}
        >
          <Form>
            <DialogContent dividers>
              <Grid spacing={2} container>
                <EditCheckpointForm
                  hasUpdatesSince={checkpoint?.hasUpdatesSince}
                  isEdit={isEdit}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Footer
                checkpointId={checkpointId}
                onDelete={onDelete}
                onClose={onClose}
              />
            </DialogActions>
          </Form>
        </Formik>
      </WaitForSync>
    </ZoomyDialog>
  );
}
EditCheckpointModal.propTypes = {
  checkpointId: PropTypes.string,
  options: PropTypes.shape({
    presets: PropTypes.shape({
      livestockSaleId: PropTypes.string,
    }),
  }),
  returnTo: PropTypes.string,
};
