import { ACTION_COLUMN_NAME } from "constants/aggrid";
import {
  BusinessAddressColId,
  BusinessAddressGroupDef,
  BusinessBankDetailsColId,
  BusinessBankDetailsGroupDef,
  BusinessColId,
  BusinessColumnDef,
} from "constants/columnDefinitions";
import {
  DeploymentColId,
  DeploymentColumnDef,
} from "constants/columnDefinitions/deployment";

import BusinessListActionColumn from "./BusinessListActionColumn";

export const columnsDefs = (
  isLivestockAgent,
  isInterestEnabled,
  hasMyobExportsFeature,
) => {
  const columns = [
    BusinessColumnDef[BusinessColId.ABN],
    BusinessColumnDef[BusinessColId.ABR_LAST_CHECKED],
    BusinessColumnDef[BusinessColId.ACN],
    BusinessColumnDef[BusinessColId.BRANDS],
    BusinessColumnDef[BusinessColId.HAS_DEBTOR_INSURANCE],
    BusinessColumnDef[BusinessColId.HAS_TRANSIT_INSURANCE],
    BusinessColumnDef[BusinessColId.IS_ACTIVE],
    BusinessColumnDef[BusinessColId.IS_BUYER],
    BusinessColumnDef[BusinessColId.IS_DEPLOYMENT],
    BusinessColumnDef[BusinessColId.IS_GST_REGISTERED],
    BusinessColumnDef[BusinessColId.IS_HOBBY_FARMER],
    BusinessColumnDef[BusinessColId.IS_LIVESTOCK_AGENT],
    BusinessColumnDef[BusinessColId.IS_MEAT_PROCESSOR_COMMERCIAL_BUYER],
    BusinessColumnDef[BusinessColId.IS_PUBLISHED],
    BusinessColumnDef[BusinessColId.IS_TEMPORARY],
    BusinessColumnDef[BusinessColId.IS_TOP_BUYER_CATTLE],
    BusinessColumnDef[BusinessColId.IS_TOP_BUYER_SHEEP],
    BusinessColumnDef[BusinessColId.IS_TRANSPORTER],
    BusinessColumnDef[BusinessColId.IS_VENDOR],
    BusinessColumnDef[BusinessColId.LAST_MODIFIED],
    BusinessColumnDef[BusinessColId.LAST_REVIEWED],
    BusinessColumnDef[BusinessColId.NAME],
    BusinessColumnDef[BusinessColId.PHONE_NUMBERS_SUMMARY],
    BusinessColumnDef[BusinessColId.EMAIL_RECIPIENTS_SUMMARY],
    BusinessColumnDef[BusinessColId.PUBLIC_DISPLAY_NAME],
    BusinessColumnDef[BusinessColId.SHORT_CODE],
    BusinessColumnDef[BusinessColId.SHORT_CODE_AUCTIONS_PLUS],
    BusinessColumnDef[BusinessColId.SHORT_CODE_SALEYARD],
    BusinessColumnDef[BusinessColId.SLUG],
    BusinessColumnDef[BusinessColId.TRADING_NAME],
    {
      ...BusinessColumnDef[BusinessColId.XERO_INTEGRATION_BUSINESS],
      field: "xeroIntegrationBusiness",
    },
    BusinessColumnDef[BusinessColId.RELATIONSHIPS],
    BusinessBankDetailsGroupDef[BusinessBankDetailsColId.BANKING_DETAILS_GROUP],
    BusinessColumnDef[BusinessColId.PROPERTIES_SUMMARY],
    BusinessAddressGroupDef[BusinessAddressColId.ADDRESS_GROUP],
    BusinessColumnDef[BusinessColId.BUYER_WAYS_SUMMARY],
    BusinessColumnDef[BusinessColId.CREATED],
    BusinessColumnDef[BusinessColId.VENDOR_COMMISSION],
    BusinessColumnDef[BusinessColId.BPAY_CRN],
    BusinessColumnDef[BusinessColId.PRIMARY_CONTACT_EMAIL],
    {
      headerName: ACTION_COLUMN_NAME,
      colId: BusinessColId.ACTIONS,
      field: "business.id",
      pinned: "right",
      cellRenderer: BusinessListActionColumn,
      width: 110,
      hideOnMobile: true,
    },
    BusinessColumnDef[BusinessColId.DEFAULT_VENDOR_SPLITS],
  ];

  // deployment fields are only available to livestock agents
  // because the deployment reference is pointing to the
  // the active agents deployment
  if (isLivestockAgent) {
    columns.push(DeploymentColumnDef[DeploymentColId.BUYER_TERMS]);
    columns.push(DeploymentColumnDef[DeploymentColId.VENDOR_TERMS]);
  }

  if (isInterestEnabled) {
    columns.push(BusinessColumnDef[BusinessColId.INTEREST]);
  }

  if (hasMyobExportsFeature) {
    columns.push(BusinessColumnDef[BusinessColId.MYOB_CUSTOMER_CARD_ID]);
    columns.push(BusinessColumnDef[BusinessColId.MYOB_SUPPLIER_CARD_ID]);
  }

  return columns;
};
