import React from "react";

import { useSelector } from "react-redux";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { FlexWrapper } from "components/AgGrid/TableWrapper";

import { AgGridTables } from "constants/aggrid";
import { BillingLedgerEntryStatus } from "constants/billingLedgerEntries";
import { LedgerEntryColumnDef } from "constants/columnDefinitions";

import { avgAggFunction } from "lib/agGrid/aggregators";

import {
  getBillingTags,
  getIsReadOnlyByBillingDocumentId,
  getLedgerEntriesByDocumentId,
} from "selectors";

const getRowId = params => params.data.ledgerEntry.id;
const aggFuncs = { anAggFunc: avgAggFunction };
const columnDefs = [
  {
    headerName: "Ledger Entry",
    children: Object.values(LedgerEntryColumnDef),
  },
];

export const LedgerEntriesTable = ({
  billingDocumentId,
  status = BillingLedgerEntryStatus.COMMITTED,
}) => {
  const rowData = useSelector(
    getLedgerEntriesByDocumentId(billingDocumentId),
  ).filter(({ ledgerEntry }) => ledgerEntry.status === status);

  const isReadOnly = useSelector(
    getIsReadOnlyByBillingDocumentId(billingDocumentId),
  );

  const billingTags = useSelector(getBillingTags);

  return (
    <AgGridTable
      aggFuncs={aggFuncs}
      columnDefs={columnDefs}
      getRowId={getRowId}
      rowData={rowData}
      showGlobalSearchFilter={false}
      hideHeader
      WrapperComponent={FlexWrapper}
      pagination={false}
      tableName={AgGridTables.BILLING_LEDGER_ENTRIES}
      context={{ isReadOnly, billingTags }}
    />
  );
};
