import React from "react";

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faSync } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

import { Column, Row } from "components/Layout";

import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";

const MainWrapper = styled(Row)`
  margin: 0px 4px;
`;

const WrapperOnlineStatus = styled(Column)`
  margin-left: 12px;
`;

const Text = styled.span`
  font-size: 12px;
  font-weight: normal;
  margin-left: 8px;
  font-style: italic;
  color: ${({ theme }) => theme.colors.white};
`;

const IconWrapper = styled(FontAwesomeIcon)`
  font-size: 8px;
  ${({ color, theme }) => color && `color: ${theme.colors[color]}`};
`;

const getStatusText = (status, pendingChanges, isFetching) => {
  if (status) {
    if (pendingChanges.length > 0 || isFetching) {
      return "Syncing";
    }
    return "Online";
  }
  return "Offline";
};

const OnlineStatus = ({ status, pendingChangesCount, isFetching }) => {
  const pendingChangesText =
    pendingChangesCount > 0
      ? `${pendingChangesCount} pending change${
          pendingChangesCount === 1 ? "" : "s"
        }`
      : "";

  const onClick = () => {
    if (pendingChangesCount > 0) {
      openModalLink(ModalTypes.PendingChanges);
    }
  };

  return (
    <MainWrapper justifyContent alignCenter>
      <WrapperOnlineStatus data-tour="onlineStatus">
        <Row justifyEnd alignCenter>
          <IconWrapper
            icon={
              isFetching || (status && pendingChangesCount > 0)
                ? faSync
                : faCircle
            }
            color={
              status && !pendingChangesCount > 0 ? "activeGreen" : "inputError"
            }
            onClick={onClick}
          />
          <Text>{getStatusText(status, pendingChangesCount, isFetching)}</Text>
        </Row>
        <Row justifyEnd>
          <Text>{pendingChangesText}</Text>
        </Row>
      </WrapperOnlineStatus>
    </MainWrapper>
  );
};

OnlineStatus.propTypes = {
  status: PropTypes.bool.isRequired,
  pendingChangesCount: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default React.memo(OnlineStatus);
