import React from "react";

import { useLocation } from "react-router-dom";

export const useQuery = () => {
  // get search from useLocation hook, rather than window.location.search
  // because the hook is part of reacts lifecycle while the global location
  // object is not
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
