import React from "react";

import { DialogActions } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteScanAction,
  openConfirmModal,
  closeConfirmModal,
  WeighLotScanAction,
} from "actions";

import { DeleteButton, SecondaryButton } from "components/Form";
import { DialogContent } from "components/MaterialDialog";

import { TRANSFER_STATUS } from "constants/nlis";
import { WeighLotScanStatus } from "constants/weighScanning";

import toast from "lib/toast";

import { getWeighLotScanById } from "selectors";

export const DeleteScan = ({ scan, onBack, onClose }) => {
  const dispatch = useDispatch();

  const associatedWeighLotScan = useSelector(
    getWeighLotScanById(scan.weigh_lot_scan_id),
  );

  const deleteScan = () => {
    if (
      scan.nlis_sell_status !== TRANSFER_STATUS.UNSUBMITTED ||
      scan.nlis_take_status !== TRANSFER_STATUS.UNSUBMITTED
    ) {
      toast.error("Cannot delete scan that has interacted with the NLIS");
    } else {
      dispatch(
        openConfirmModal({
          title: "Are you sure?",
          message:
            "Are you sure you want to delete this EID? This will remove all information about the scan, including any single weigh weights, audit log history, or connection to the Vendor, Sale Lot, Agency, and any rolled back NLIS transactions.  This cannot be undone.",
          actions: [
            {
              label: "No",
              secondary: true,
              onClick: () => {
                dispatch(closeConfirmModal());
              },
            },
            {
              label: "Yes",
              onClick: () => {
                dispatch(deleteScanAction(scan));
                if (associatedWeighLotScan) {
                  dispatch(
                    WeighLotScanAction.update({
                      ...associatedWeighLotScan,
                      status: WeighLotScanStatus.UNRESOLVED,
                    }),
                  );
                }
                dispatch(closeConfirmModal());
              },
            },
          ],
        }),
      );
    }

    onClose();
  };

  return (
    <>
      <DialogContent minHeight="2vh" shrink={1} dividers />
      <DialogActions shrink={0}>
        {typeof onBack === "function" ? (
          <SecondaryButton onClick={onBack}>Back</SecondaryButton>
        ) : null}
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <DeleteButton onClick={deleteScan}>Delete EID</DeleteButton>
      </DialogActions>
    </>
  );
};
