import defaultQueue from "@redux-offline/redux-offline/lib/defaults/queue";
import * as Sentry from "@sentry/react";
import { isEqual, omit } from "lodash";

import {
  DELETE_OFFLINE_ACTION,
  REQUEST_NEW_JWT_TOKEN_OFFLINE,
} from "constants/actionTypes";
import { SentrySeverityWarning } from "constants/sentry";

import { isSentryActive } from "lib/sentry/config";

const queue = {
  ...defaultQueue,
  enqueue: (currentQueue, action) => {
    if (action.type === DELETE_OFFLINE_ACTION) {
      const { deleteTransaction } = action.meta.offline;
      return currentQueue.filter(
        queuedAction => queuedAction.meta.transaction !== deleteTransaction,
      );
    }

    if (currentQueue.length > 100) {
      // Log a warning, stuffs about to get bad.
      // eslint-disable-next-line no-console
      console.warn(
        `Warning: Adding more actions to already large queue! Length: ${currentQueue.length}  Action: ${action}`,
      );
      // Send a Sentry error every 250 - the queue should generally be near 0.  Maybe one or two GET actions per
      // data type, and pending changes.  If they're offline and have 250 changes pending, they might have a bad
      // time anyway.
      if (currentQueue.length % 250 === 0) {
        // eslint-disable-next-line no-console
        console.error(
          `Queue size over limit - currently   ${currentQueue.length}`,
        );
        if (isSentryActive) {
          Sentry.captureMessage(
            `Queue size over limit - currently   ${currentQueue.length}`,
            {
              level: SentrySeverityWarning,
            },
          );
        }
      }
    }

    // There is no benefit to queueing the exact same action multiple times.  This should improve initial load.
    const actionAlreadyQueued = currentQueue
      .filter(queuedAction => queuedAction.type === action.type)
      .find(queuedAction =>
        isEqual(
          omit(queuedAction, "meta.transaction"),
          omit(action, "meta.transaction"),
        ),
      );

    if (actionAlreadyQueued) {
      // eslint-disable-next-line no-console
      console.warn(`Discarding duplicate action ${action.type}`);
      return currentQueue;
    }

    if (action.type === REQUEST_NEW_JWT_TOKEN_OFFLINE) {
      if (
        currentQueue.filter(
          arrayItem => arrayItem.type === REQUEST_NEW_JWT_TOKEN_OFFLINE,
        ).length
      ) {
        return currentQueue;
      }
      // requests for new JWT always have higher priority, since other offline
      // requests need refreshed JWT in order to proceed.
      return [action, ...currentQueue];
    }

    if (action.meta.skipQueue) {
      return currentQueue;
    }

    return [...currentQueue, action];
  },
};

export default queue;
