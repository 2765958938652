/* eslint-disable import/no-cycle */
import { createSelector } from "reselect";
import { SaleProgressSetting } from "constants/saleProgress";
import {
  getSaleLots,
  getSaleProgressSettings,
  selectIsDeceasedBySaleLotIdLookup,
  selectIsNoSaleBySaleLotIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectIsUnsoldBySaleLotIdLookup,
  selectIsWithdrawnBySaleLotIdLookup,
} from "selectors";
import sumBy from "lodash/sumBy";

const selectSaleProgressSaleLotIds = createSelector(
  [
    getSaleLots,
    getSaleProgressSettings,
    selectIsNoSaleBySaleLotIdLookup,
    selectIsDeceasedBySaleLotIdLookup,
    selectIsWithdrawnBySaleLotIdLookup,
  ],
  (
    saleLotByIdLookup,
    saleProgressSettings,
    isNoSaleBySaleLotIdLookup,
    isDeceasedBySaleLotIdLookup,
    isWithdrawnBySaleLotIdLookup,
  ): Array<string> =>
    Object.keys(saleLotByIdLookup).reduce((acc, saleLotId) => {
      const passesNoSaleFilter =
        !saleProgressSettings[SaleProgressSetting.ExcludeNoSaleSaleLots] ||
        !isNoSaleBySaleLotIdLookup[saleLotId];
      const passesDeceasedFilter =
        !saleProgressSettings[SaleProgressSetting.ExcludeDeceasedSaleLots] ||
        !isDeceasedBySaleLotIdLookup[saleLotId];
      const passesWithdrawnFilter =
        !saleProgressSettings[SaleProgressSetting.ExcludeWithdrawnSaleLots] ||
        !isWithdrawnBySaleLotIdLookup[saleLotId];

      if (passesNoSaleFilter && passesDeceasedFilter && passesWithdrawnFilter) {
        acc.push(saleLotId);
      }
      return acc;
    }, []),
);
export const selectSaleProgressSaleLots = createSelector(
  [getSaleLots, selectSaleProgressSaleLotIds],
  (saleLots, saleLotIds): Array<SaleLot> =>
    saleLotIds.map(saleLotId => saleLots[saleLotId]),
);
export const selectSaleProgressCents = createSelector(
  [selectSaleProgressSaleLots, selectIsUnsoldBySaleLotIdLookup],
  (saleLots, isUnsoldBySaleLotIdLookup): number =>
    sumBy(
      saleLots.filter(saleLot => !isUnsoldBySaleLotIdLookup[saleLot.id]),
      "total_price_cents",
    ),
);

export const selectSaleProgressHeadCount = createSelector(
  [selectSaleProgressSaleLots, selectIsUnsoldBySaleLotIdLookup],
  (saleLots, isUnsoldBySaleLotIdLookup): number =>
    sumBy(
      saleLots.filter(saleLot => !isUnsoldBySaleLotIdLookup[saleLot.id]),
      "quantity",
    ),
);

export const selectSoldSaleLotIds = createSelector(
  [getSaleLots, selectIsSoldBySaleLotIdLookup],
  (saleLotByIdLookup, isSoldBySaleLotIdLookup): Array<string> =>
    Object.keys(saleLotByIdLookup).filter(
      saleLotId => isSoldBySaleLotIdLookup[saleLotId],
    ),
);

export const selectNoSaleSaleLotIds = createSelector(
  [getSaleLots, selectIsNoSaleBySaleLotIdLookup],
  (saleLotByIdLookup, isNoSaleBySaleLotIdLookup): Array<string> =>
    Object.keys(saleLotByIdLookup).filter(
      saleLotId => isNoSaleBySaleLotIdLookup[saleLotId],
    ),
);

export const selectSaleProgressWeighedGrams = createSelector(
  [selectSaleProgressSaleLots, selectIsUnsoldBySaleLotIdLookup],
  (saleLots, isUnsoldBySaleLotIdLookup): number =>
    sumBy(
      saleLots.filter(
        saleLot =>
          saleLot.total_mass_grams && !isUnsoldBySaleLotIdLookup[saleLot.id],
      ),
      "total_mass_grams",
    ),
);

export const selectSaleProgressWeighedCents = createSelector(
  [selectSaleProgressSaleLots, selectIsUnsoldBySaleLotIdLookup],
  (saleLots, isUnsoldBySaleLotIdLookup): number =>
    sumBy(
      saleLots.filter(
        saleLot =>
          saleLot.total_mass_grams && !isUnsoldBySaleLotIdLookup[saleLot.id],
      ),
      "total_price_cents",
    ),
);

export const selectSoldTotalPriceCents = createSelector(
  [getSaleLots, selectIsUnsoldBySaleLotIdLookup],
  (
    saleLotByIdLookup: LookupSelectorResult<SaleLot>,
    isUnsoldBySaleLotIdLookup,
  ) =>
    sumBy(
      Object.values(saleLotByIdLookup).filter(
        saleLot => !isUnsoldBySaleLotIdLookup[saleLot.id],
      ),
      "total_price_cents",
    ),
);
