import { createSelector } from "reselect";

import { isConsignmentBalanced } from "lib/consignments";
import { getLivestockSaleId } from "lib/navigation";
import { isRoundBalanced } from "lib/rounds";
import { isDeploymentSaleBalanced } from "lib/sale";
import { isSaleLotBalanced } from "lib/saleLot";

import {
  getCurrentRoundsList,
  selectReceivedCountByDeploymentSaleIdLookup,
  selectSaleLotQuantityByConsignmentIdLookup,
  selectRoundNotPennedCountByDeploymentSaleIdLookup,
  selectCurrentDeploymentSaleIdsList,
  selectConsignmentIdsByDeploymentSaleIdLookup,
  getSaleLots,
  getConsignments,
  selectSaleLotIdsByConsignmentIdLookup,
  selectRoundSaleLotIdsByDeploymentSaleIdLookup,
  selectIsPennedBySaleLotIdLookup,
} from "selectors";

/**
 * Returns whether or not a Sale Lot's Quantity and Delivered Quantity are balanced, keyed by Sale Lot Id
 * Requires:
 *  - `Sale Lots`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsBalancedBySaleLotIdLookup = createSelector(
  [getSaleLots],
  saleLots =>
    Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      acc[saleLotId] = isSaleLotBalanced(saleLot);
      return acc;
    }, {}),
);
/**
 * Returns whether or not all Sale Lots are Balanced, and that there are no unpenned Sale Lots, in their Sale Round Indexes, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, Array<bool>}
 */
export const selectRoundIsBalancedByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    getCurrentRoundsList,
    selectRoundSaleLotIdsByDeploymentSaleIdLookup,
    selectIsBalancedBySaleLotIdLookup,
    selectRoundNotPennedCountByDeploymentSaleIdLookup,
  ],
  (
    deploymentSaleIds,
    saleRoundIds,
    saleRoundSaleLotIdsListByDeploymentSaleId,
    isBalancedBySaleLotId,
    roundNotPennedCountByByDeploymentSaleId,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      // Get all of the Sale Lot Ids in this Deployment Sale, grouped by their sale round index
      const roundSaleLotIdsList =
        saleRoundSaleLotIdsListByDeploymentSaleId[deploymentSaleId];
      const roundNotPennedCount =
        roundNotPennedCountByByDeploymentSaleId[deploymentSaleId];

      acc[deploymentSaleId] = saleRoundIds.map((_, index) =>
        isRoundBalanced(
          roundSaleLotIdsList[index].every(
            saleLotId => isBalancedBySaleLotId[saleLotId],
          ),
          roundNotPennedCount[index],
        ),
      );
      return acc;
    }, {}),
);
export const getIsRoundBalancedByDeploymentSaleId =
  (deploymentSaleId, roundIndex) => state =>
    selectRoundIsBalancedByDeploymentSaleIdLookup(state, getLivestockSaleId())[
      deploymentSaleId
    ][roundIndex];

/**
 * Returns whether or not all of a Consignment's Sale Lots are balanced with the consignment quantity
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsPreSaleBalancedByConsignmentIdLookup = createSelector(
  [getConsignments, selectSaleLotQuantityByConsignmentIdLookup],
  (consignments, saleLotQuantityByConsignmentId) =>
    Object.entries(consignments).reduce((acc, [consignmentId, consignment]) => {
      const saleLotsQuantities = saleLotQuantityByConsignmentId[consignmentId];
      acc[consignmentId] = isConsignmentBalanced(
        consignment.quantity,
        saleLotsQuantities,
        true,
      );
      return acc;
    }, {}),
);

/**
 * Returns whether or not all of a Consignment's Sale Lots are balanced, and the total number of head in the Consignment's Sale Lots matches the Consignments Quantity, keyed by Consignment Id
 * Requires:
 *  - `Consignments`
 *  - `Sale Lots`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsPostSaleBalancedByConsignmentIdLookup = createSelector(
  [
    getConsignments,
    selectSaleLotQuantityByConsignmentIdLookup,
    selectSaleLotIdsByConsignmentIdLookup,
    selectIsBalancedBySaleLotIdLookup,
    selectIsPennedBySaleLotIdLookup,
  ],
  (
    consignments,
    saleLotQuantityByConsignmentId,
    saleLotIdsByConsignmentId,
    isBalancedBySaleLotId,
    isPennedBySaleLotId,
  ) =>
    Object.entries(consignments).reduce((acc, [consignmentId, consignment]) => {
      const saleLotIds = saleLotIdsByConsignmentId[consignmentId] || [];

      const saleLotsQuantities = saleLotQuantityByConsignmentId[consignmentId];

      const areAllSaleLotBalancedAndPenned = saleLotIds.every(
        saleLotId =>
          isBalancedBySaleLotId[saleLotId] && isPennedBySaleLotId[saleLotId],
      );

      acc[consignmentId] = isConsignmentBalanced(
        consignment.quantity,
        saleLotsQuantities,
        areAllSaleLotBalancedAndPenned,
      );
      return acc;
    }, {}),
);
/**
 * Returns whether or not all of a Deployment Sale's Consignment are balanced, keyed by Deployment Sale Id
 * Requires:
 *  - `Consignments`
 *  - `Sales`
 *  - `Sale Lots`
 * @type {function(state): Object<string, boolean>}
 */
export const selectIsBalancedByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    selectConsignmentIdsByDeploymentSaleIdLookup,
    selectRoundIsBalancedByDeploymentSaleIdLookup,
    selectReceivedCountByDeploymentSaleIdLookup,
  ],
  (
    deploymentSaleIds,
    isPostSaleBalancedByConsignmentId,
    consignmentIdsByDeploymentSaleId,
    roundIsBalancedByDeploymentSaleId,
    receivedCountByDeploymentSaleId,
  ) =>
    deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      const consignmentIds =
        consignmentIdsByDeploymentSaleId[deploymentSaleId] || [];

      const receivedCount =
        receivedCountByDeploymentSaleId[deploymentSaleId] || 0;

      const roundBalancedStatuses =
        roundIsBalancedByDeploymentSaleId[deploymentSaleId] || [];

      const areAllConsignmentsBalanced = consignmentIds.every(
        consignmentId => isPostSaleBalancedByConsignmentId[consignmentId],
      );

      const areAllRoundsBalanced = roundBalancedStatuses.every(Boolean);

      acc[deploymentSaleId] = isDeploymentSaleBalanced(
        receivedCount,
        areAllConsignmentsBalanced,
        areAllRoundsBalanced,
      );
      return acc;
    }, {}),
);

export const getIsBalancedByDeploymentSaleId = deploymentSaleId => state =>
  selectIsBalancedByDeploymentSaleIdLookup(state, getLivestockSaleId())[
    deploymentSaleId
  ] || null;
