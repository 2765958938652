import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Settings } from "constants/settings";

const MATCH_FULLSCREEN = /saleyard\/.*\/sale\/.*\/(round)|(single-weigh)/;
// Routes that are possibly fullscreen.  These must allow the user to toggle somewhere.
const MATCH_TOGGLE_FULLSCREEN = /saleyard\/.*\/sale\/.*\/(watch)|(billing)/;

export const useIsFullScreenWithRouteCheck = () => {
  const location = useLocation();
  return useSelector(
    state =>
      location.pathname.match(MATCH_FULLSCREEN) ||
      (location.pathname.match(MATCH_TOGGLE_FULLSCREEN) &&
        state.settings[Settings.fullScreen]),
  );
};
