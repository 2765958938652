import React from "react";

import { BusinessModalSection } from "constants/navigation";

import { openEditBusinessModal } from "lib/navigation";

import { CellButton } from "./CellButton";

function OpenModalCellCreator(businessModalSection, fieldName) {
  return function OpenBusinessModalCell(props) {
    const { original, value } = props;
    return (
      <CellButton
        onClick={() =>
          openEditBusinessModal(
            original[fieldName],
            undefined,
            businessModalSection,
          )
        }
      >
        {value}
      </CellButton>
    );
  };
}

export const OpenBuyerEdit = OpenModalCellCreator(
  BusinessModalSection.GENERAL,
  "buyerId",
);
export const OpenVendorEdit = OpenModalCellCreator(
  BusinessModalSection.GENERAL,
  "vendorId",
);
