import React from "react";

import { faPlus, faMinus, faDesktop } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { screenResize } from "actions";

import { MultiButton } from "components/Button";
import IconTextButton from "components/Button/IconTextButton";
import NestedCard from "components/Card/NestedCard";
import { Row } from "components/Layout";
import { tryAndOpenNominationsModal } from "components/Nominations/Table";
import { Subtitle } from "components/Text";

import { DeploymentPermissions } from "constants/permissions";

import {
  getLivestockSaleId,
  getNominationTableRoute,
  getSaleyardName,
} from "lib/navigation";

import {
  selectRoleCurrentDeployments,
  selectNominationTotalsByGroupNameAndTermLookup,
  getTotalNominationQuantity,
  getIsNominationsLocked,
} from "selectors";

import { useTheme } from "hooks";
import { useSomeHasPermission } from "hooks/useHasPermission";

export const NominationHdContainer = styled.div(
  ({ theme }) => `
  font-size: ${theme.fontSizes.charlie}px;
  font-weight: ${theme.fontWeights.bold};
`,
);

const Quantity = styled.span`
  font-size: 18px;
  margin-right: 10px;
  font-weight: bold;
`;

const DropDownRow = styled(Row)(
  ({ theme }) => `
display: flex;
flex-wrap: nowrap;
align-items: center;
margin: ${theme.space[2]}px;`,
);

const BreakLine = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.gray95};
  width: 100%;
  max-width: 800px;
`;

const totalSpeciesNominatonAccessor = terms =>
  Object.values(terms).reduce((acc, quantity) => {
    return acc + quantity || 0;
  }, 0);

export const NominationOverview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const totalNominationQuantity = useSelector(getTotalNominationQuantity);
  const nominationTotals = useSelector(
    selectNominationTotalsByGroupNameAndTermLookup,
  );
  const hasCreatePermission = useSomeHasPermission(
    selectRoleCurrentDeployments,
    DeploymentPermissions.canNominateFor,
  );

  const theme = useTheme();

  const NominationTotals = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <>
        <DropDownRow>
          <NominationHdContainer>
            Total: {totalNominationQuantity}
          </NominationHdContainer>
          <IconTextButton icon={open ? faMinus : faPlus} onClick={handleClick}>
            {open ? `Hide details` : `Show details`}
          </IconTextButton>
        </DropDownRow>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            style: { width: "100%", padding: "12px" },
          }}
        >
          {Object.entries(nominationTotals).map(
            ([groupName, terms], index, nominationTotals) => {
              return (
                <>
                  <NestedCard whiteBackground>
                    <Row>
                      <Grid
                        container
                        item
                        xs={5}
                        spacing={0}
                        justify="center"
                        direction="column"
                      >
                        <h3>
                          {groupName}
                          <Subtitle>
                            Total {totalSpeciesNominatonAccessor(terms)}
                          </Subtitle>
                        </h3>
                      </Grid>
                      <Grid container item xs={7}>
                        {Object.entries(terms).map(([termName, quantity]) => {
                          return (
                            <Grid
                              item
                              xs={6}
                              style={{
                                marginBottom: "12px",
                                justifySelf: "center",
                              }}
                            >
                              <Subtitle>{termName}</Subtitle>
                              <Quantity>{quantity}</Quantity>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Row>
                  </NestedCard>
                  {index + 1 !== nominationTotals.length && <BreakLine />}
                </>
              );
            },
          )}
        </Popover>
      </>
    );
  };

  const nominationsLocked = useSelector(getIsNominationsLocked);

  const nominationActions = [
    {
      title: "Nomination",
      isDisabled: false,
      onClick: () => tryAndOpenNominationsModal(nominationsLocked),
      default: true,
      icon: faPlus,
      dataTour: "addNomination",
    },
    {
      title: "Desktop View",
      icon: faDesktop,
      onClick: () => {
        dispatch(screenResize(theme.breakpoints[2]));
        history.push(
          getNominationTableRoute(getSaleyardName(), getLivestockSaleId()),
        );
      },
      dataTour: "desktopView",
    },
  ];

  return (
    <Row>
      <Grid container>
        <Grid item xs={8} container spacing={0} alignItems="center">
          {totalNominationQuantity > 0 && <NominationTotals />}
        </Grid>

        <Grid container item xs={4}>
          {hasCreatePermission && (
            <Row padding={2} fullWidth justifyEnd>
              <MultiButton buttons={nominationActions} />
            </Row>
          )}
        </Grid>
      </Grid>
    </Row>
  );
};
