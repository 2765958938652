import React from "react";

import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { EditButton } from "components/Button";
import { SelectField } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { CurrentSaleReadOnly } from "components/ReadOnly";

import { ForNotClearingSale } from "containers/ForSaleType";

import {
  getBusinesses,
  getConsignments,
  selectLivestockSaleConsignmentOptions,
} from "selectors";

import { useTranslatedSaleTypeText } from "hooks";

import { LotNumber } from "./LotNumber";

const HeaderLabel = styled.div`
  font-weight: bold;
  padding: 0 6px 0 12px;
`;

const Header = styled(Row)`
  border-bottom: 1px solid #e9e9e9;
  margin: 0 -12px;
  padding: 6px 12px;
`;

const Vendor = ({ readOnly }) => {
  const [editSaleLotConsignment, setEditSaleLotConsignment] =
    React.useState(false);

  const saleTypeText = useTranslatedSaleTypeText("Consignment");
  const { values } = useFormikContext();

  const businesses = useSelector(getBusinesses);

  const consignments = useSelector(getConsignments);
  const consignmentOptions = useSelector(selectLivestockSaleConsignmentOptions);

  if (!editSaleLotConsignment) {
    const {
      vendor_id: vendorId,
      vendorNumber = "#?",
      quantity,
      livestock_agency_code,
    } = consignments[values.consignment_id] || {};
    const vendor = businesses[vendorId];
    const vendorNumberAgencyShortCode = `${
      livestock_agency_code || ""
    }${vendorNumber || ""}`;
    const vendorLabel = [
      vendorNumberAgencyShortCode,
      vendor ? vendor.name : "Unknown",
    ]
      .filter(Boolean)
      .join(" - ");

    return (
      <Column full>
        <Row full>
          <CurrentSaleReadOnly>
            <EditButton
              dataTour="editVendor"
              disabled={readOnly}
              onClick={() => setEditSaleLotConsignment(true)}
            />
          </CurrentSaleReadOnly>
          <Column full justifyCenter>
            <Row>
              <HeaderLabel>Vendor</HeaderLabel>
              {vendorLabel}
            </Row>
            <ForNotClearingSale>
              <Row>
                <HeaderLabel>{saleTypeText} Head Count</HeaderLabel>
                {quantity}
              </Row>
            </ForNotClearingSale>
          </Column>
        </Row>
      </Column>
    );
  } else {
    return (
      <Column justifyCenter full>
        <HeaderLabel>Vendor</HeaderLabel>
        <SelectField name="consignment_id" options={consignmentOptions} />
      </Column>
    );
  }
};

export const ConsignmentSectionHeader = ({ readOnly, ns = null }) => {
  return (
    <Header justifyBetween>
      <Vendor readOnly={readOnly} ns={ns} />
      <Column>
        <LotNumber readOnly={readOnly} ns={ns} />
      </Column>
    </Header>
  );
};
