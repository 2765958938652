import get from "lodash/get";

import { comparePens, getAuctionPenDisplayName } from "lib/auctionPens";

export function penComparator(a, b) {
  return comparePens(a, b);
}

export function penGetter(params) {
  if (!params.data) {
    return null;
  }

  const value = get(params.data, params.column.colId);
  return getAuctionPenDisplayName(value, "");
}
