// we need this to be static so we can scroll inside independently
import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { FullScreenContainer } from "components/FullScreenContainer";
import SaleLotCard from "components/SaleWatcher/SaleLotCard";
import VideoEmbed from "components/SaleWatcher/VideoEmbed";

import {
  currentSaleSelector,
  getNextUpSaleLotsInOrder,
  getSellingNowByPriceSaleLot,
  getSoldSaleLotsInSoldOrder,
} from "selectors";

import "./transitions.css";

const Title = styled(Grid)`
  flex-grow: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  ${({ theme }) => `
          font-size: ${theme.fontSizes.delta}px;
          height: ${theme.fontSizes.eta}px;
          line-height: ${theme.fontSizes.eta}px;
          font-weight: ${theme.fontWeights.bold};
          color: ${theme.colors.primary};
`}
`;

// To get around padding causing scroll bars to show up ;\
const CardsGrid = styled(Grid)`
  margin: 0 -16px !important;
  padding: 0 16px;
`;

// TODO make the cards span off the screen, and animate, but time.
// const CardsGrid = styled(Grid)`
//   margin: 0 -15% !important;
//   width: 130%;
//   align-items: center;
// `;

const WrapperGrid = styled(Grid)`
  height: 100%;
  align-items: center;
  overflow: hidden;
`;

const VideoGrid = styled(Grid)`
  flex-grow: 1;
`;
export const BigScreenView = () => {
  const currentSale = useSelector(state => {
    return currentSaleSelector(state);
  });
  const nextUp = useSelector(getNextUpSaleLotsInOrder)?.[0];
  const sellingNow = useSelector(getSellingNowByPriceSaleLot);
  const recentlySold = useSelector(getSoldSaleLotsInSoldOrder)?.[0];

  return (
    <FullScreenContainer>
      <WrapperGrid container direction="column">
        <CardsGrid item container spacing={1}>
          <Grid xs={3} item container>
            <Title item xs={12}>
              Next Up
            </Title>
            {nextUp ? (
              <Grid xs={12} item>
                <SaleLotCard saleLot={nextUp} bigScreen showChips={false} />
              </Grid>
            ) : null}
          </Grid>
          <Grid xs={6} item container>
            <Title item xs={12}>
              Now Selling
            </Title>
            <Grid xs={12} item>
              {sellingNow && (
                <SaleLotCard saleLot={sellingNow} showChips bigScreen />
              )}
            </Grid>
          </Grid>
          <Grid xs={3} item container>
            <Title item xs={12}>
              Recently Sold
            </Title>
            {recentlySold ? (
              <Grid xs={12} item>
                <SaleLotCard
                  saleLot={recentlySold}
                  bigScreen
                  showChips={false}
                />
              </Grid>
            ) : null}
          </Grid>
        </CardsGrid>

        <VideoGrid container item>
          <Grid item xs={12}>
            <VideoEmbed
              videoUrl={currentSale.streaming_video}
              fullHeightIframe
            />
          </Grid>
        </VideoGrid>
      </WrapperGrid>
    </FullScreenContainer>
  );
};
