import React, { useCallback, useEffect, useMemo } from "react";

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import { getIn, useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import {
  AlternateFieldTextComponent,
  AlternateLabel,
  getAlternateColor,
} from "components/BusinessForm/lib";
import { SlimButton, SlimSecondaryButton } from "components/Button";
import { Warning } from "components/ErrorMessage";
import { CollapseLabel } from "components/Form";
import { Label, withNamespace } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import Table, { CellRightAlign } from "components/Table";

import { formatAddress } from "lib/address";
import { caseInsensitiveCompare } from "lib/compare";
import { pluralize } from "lib/pluralize";

import { getProperties, getSaleyards } from "selectors";

import DeploymentPropertyForm, {
  DeploymentPropertyFormMode,
} from "./DeploymentPropertyForm";

const DEFAULT_SORT = [
  {
    id: "pic",
    direction: "asc",
  },
];

export const PropertiesHeader = ({ namespace: ns }) => {
  const [{ value }] = useField(ns);
  const filteredValues = value.filter(property => property.isShown);
  return (
    <Row>
      <SubtleBadge>Properties</SubtleBadge>
      <CollapseLabel>
        {filteredValues.length} {pluralize("Property", filteredValues.length)}
      </CollapseLabel>
    </Row>
  );
};

function TickBoxCellRenderer(row) {
  return <FontAwesomeIcon icon={row.value ? faCheck : faTimes} />;
}

function AddressCellRenderer(props) {
  const { original } = props;
  const value = original.address ? formatAddress(original.address) : "-";
  const alternativesProperties = original.alternatives || [];
  const alternativeAddresses = alternativesProperties.map(
    ({ address, source }) => ({
      address,
      source,
      addressFormatted: address ? formatAddress(address) : "-",
    }),
  );

  function onClickApply(
    formikProps,
    overrideFormikField,
    formikField,
    value,
    alternativeProperty,
  ) {
    formikProps.setFieldValue(
      "editingValue.address",
      alternativeProperty.address,
    );
  }

  return (
    <Label
      altTooltip={AlternateFieldTextComponent(
        alternativeAddresses,
        "addressFormatted",
        null,
        null,
        false,
        onClickApply,
      )}
      altColor={getAlternateColor(
        alternativeAddresses,
        null,
        "addressFormatted",
        null,
        value,
      )}
    >
      {value}
    </Label>
  );
}

function DefaultSaleyardsRenderer({ value }) {
  const saleyards = useSelector(getSaleyards);
  // Renders a list of saleyards based on the ids given.  The user may not be able to SEE all of these saleyards, hence
  // the ?. and filtering.
  return (
    value
      ?.map(saleyardId => saleyards[saleyardId]?.name)
      .filter(Boolean)
      .join(", ") || "-"
  );
}

export function PropertiesTable(props) {
  const { namespace: ns, onEndEditing, onStartEditing } = props;
  const formikContext = useFormikContext();
  const { dirty, isValid, values, setFieldValue } = formikContext;

  const propertyLookup = useSelector(getProperties);

  const properties = useMemo(
    () =>
      // get the list of Properties out of the Formik state
      getIn(values, withNamespace(ns, "values"))
        // Exclude any properties flagged as hidden for this Deployment
        // TODO - only show if source is ME.
        .filter(deploymentProperty => deploymentProperty.isShown)
        .map(prop => ({
          // Spread the data from the actual property object, which may be overridden by local changes in the values.
          ...propertyLookup[prop.id],
          ...prop,
        })),
    [ns, values, propertyLookup],
  );

  const editingProperty = getIn(values, withNamespace(ns, "editingValue"));
  const isEditingExistingProperty =
    editingProperty &&
    Array.isArray(properties) &&
    properties.some(property => property.id === editingProperty.id);

  const onEditPropertyIndex = useCallback(
    index => {
      setFieldValue(withNamespace(ns, "editingValue"), properties[index]);
      onStartEditing();
    },
    [properties, ns, onStartEditing, setFieldValue],
  );

  const onRemovePropertyIndex = useCallback(
    index => {
      const newState = properties.slice();
      newState[index] = { ...newState[index], isShown: false };
      setFieldValue(withNamespace(ns, "values"), newState);

      onStartEditing();
    },
    [properties, ns, onStartEditing, setFieldValue],
  );

  function onClickCommitPropertyChanges() {
    const updatingPropertyId = editingProperty.id;
    const newState = properties.map(property =>
      // Only one property can be "default" per Saleyard
      // if the newly edited Property is set as the _default_ property for a saleyard, mark all others as not default in that saleyard.
      ({
        ...property,
        defaultForSaleyardIds:
          property.defaultForSaleyardIds?.filter(
            saleyardId =>
              !editingProperty.defaultForSaleyardIds?.includes(saleyardId),
          ) || [],
      }),
    );
    const existingIndex = newState.findIndex(
      property => property.id === updatingPropertyId,
    );
    newState.splice(existingIndex === -1 ? newState.length : existingIndex, 1, {
      ...editingProperty,
      isShown: true,
    });

    setFieldValue(withNamespace(ns, "values"), newState);
    setFieldValue(withNamespace(ns, "editingValue"), null);

    onStartEditing();
    return false;
  }

  function onClickClearPropertyChanges() {
    setFieldValue(withNamespace(ns, "editingValue"), null);
  }

  useEffect(() => {
    !dirty && onEndEditing();
  }, [dirty, onEndEditing]);
  const columns = useMemo(
    () => [
      {
        id: "PIC",
        Header: "PIC",
        sortMethod: caseInsensitiveCompare,
        accessor: "PIC",
      },
      {
        Cell: props => (
          <AlternateLabel
            props={props}
            altFieldName="name"
            overrideFormikField="editingValue.name"
            disabledButton={!isEditingExistingProperty}
            values={values}
          />
        ),
        id: "name",
        Header: "Name",
        accessor: "name",
      },

      {
        Cell: AddressCellRenderer,
        id: "address",
        Header: "Address",
        accessor: "address",
      },
      {
        id: "district",
        Header: "District",
        accessor: "district",
      },

      {
        id: "jbas",
        Header: "JBAS",
        accessor: "accreditationJbas",
      },
      {
        id: "eu",
        Header: "EU",
        accessor: "accreditationEu",
        Cell: TickBoxCellRenderer,
      },
      {
        id: "nee",
        Header: "NEE",
        accessor: "accreditationNee",
        Cell: TickBoxCellRenderer,
      },
      {
        id: "pcas",
        Header: "PCAS",
        accessor: "accreditationPcas",
        Cell: TickBoxCellRenderer,
      },
      {
        id: "lpaNumber",
        Header: "LPA #",
        accessor: "lpaNumber",
      },
      {
        id: "msaNumber",
        Header: "MSA #",
        accessor: "msaNumber",
      },
      {
        id: "defaultForSaleyardIds",
        Header: "Default Saleyards",
        accessor: "defaultForSaleyardIds",
        Cell: DefaultSaleyardsRenderer,
      },

      {
        Cell: ({ index }) => (
          <CellRightAlign>
            <SlimButton
              type="button"
              onClick={() => onEditPropertyIndex(index)}
            >
              Edit
            </SlimButton>{" "}
            <SlimButton
              color="deleteRed"
              type="button"
              onClick={() => onRemovePropertyIndex(index)}
              data-tour="delete"
            >
              Delete
            </SlimButton>
          </CellRightAlign>
        ),
        Header: "Actions",
        id: "actions",
        width: 150,
      },
    ],
    [
      onEditPropertyIndex,
      onRemovePropertyIndex,
      isEditingExistingProperty,
      values,
    ],
  );

  const isClearEnabled = editingProperty !== null;
  const isAddUpdateEnabled = editingProperty !== null && dirty && isValid;

  const isRevertEnabled = dirty;
  const isSubmitEnabled = dirty && isValid && editingProperty === null;

  return (
    <>
      <Grid container spacing={2} alignItems="flex-start">
        <DeploymentPropertyForm
          isEditPicAllowed={!isEditingExistingProperty}
          namespace={withNamespace(ns, "editingValue")}
          mode={
            editingProperty === null
              ? DeploymentPropertyFormMode.INLINE
              : DeploymentPropertyFormMode.FULL
          }
        />
        <Grid item xs={7} md={4}>
          <SlimSecondaryButton
            disabled={!isClearEnabled}
            onClick={onClickClearPropertyChanges}
            type="button"
          >
            Clear
          </SlimSecondaryButton>
          <SlimButton
            disabled={!isAddUpdateEnabled}
            onClick={onClickCommitPropertyChanges}
            type="button"
            data-tour="propertyAdd"
          >
            {isEditingExistingProperty ? "Update" : "Add"} Property
          </SlimButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={properties}
            defaultSorted={DEFAULT_SORT}
            noResultsMessage="This Business does not have any Properties yet."
          />
        </Grid>
      </Grid>
      {properties.length > 1 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Warning>
              You have multiple PICs attached to this business. Edit them to add
              an address.
            </Warning>
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={2} alignItems="flex-end">
        <Grid container item xs={12}>
          <SlimSecondaryButton type="reset" disabled={!isRevertEnabled}>
            Revert Changes
          </SlimSecondaryButton>
          <SlimButton
            type="submit"
            disabled={!isSubmitEnabled}
            data-tour="propertySubmit"
          >
            Accept Changes
          </SlimButton>
        </Grid>
      </Grid>
    </>
  );
}
PropertiesTable.propTypes = {
  namespace: PropTypes.string,
  onEndEditing: PropTypes.func.isRequired,
  onStartEditing: PropTypes.func.isRequired,
};
