import { WeighLotAction, setWeighingSessions } from "actions";

import { scannerMessages } from "constants/scanner";

export const MT_HOST_FILE_SESSION_DRIVER_NAME = "mthost-filesessions";

export function mtHostProcessMessage(device, type, _status, serializedPayload) {
  const { deviceId, name } = device;
  switch (type) {
    case scannerMessages.WEIGHING_SESSIONS:
      return setWeighingSessions(deviceId, serializedPayload.weighingSessions);
    case scannerMessages.WEIGHING_SESSION:
      return WeighLotAction.import(
        serializedPayload.sessionName,
        serializedPayload.content,
        name,
        { suppressToast: true },
      );
    default:
      // eslint-disable-next-line no-console
      console.warn("Unknown payload received in MTHOST Driver: ", {
        deviceId,
        serializedPayload,
      });
  }
}
