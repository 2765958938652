import {
  faFile,
  faFileArchive,
  faFileCsv,
  faFilePdf,
} from "@fortawesome/pro-light-svg-icons";
import queryString from "query-string";

import { userTypes } from "constants/users";

export function getReportUrl({ pathTemplate = "", queryMap = {} }, args) {
  let startIndex = 0;
  let path = "";
  for (const match of pathTemplate.matchAll(/({[\w-]+?\})+/g)) {
    // Given the example /reports/saleyard/{saleyardName}/billing-report/{ruleBookId}/
    // Add the url fragment "/billing-report/" between this argument ({ruleBookId}) and
    // the previous argument ({saleyardName}) to the path.
    path += pathTemplate.slice(startIndex, match.index);

    // Remove the leading and trailing curly braces from the template
    // i.e. Convert "{saleyardName}" to "saleyardName", so that it can be used to look up the caller provided argument
    const argsKey = match[0].slice(1, -1);

    // Interpolate the value from caller provided "args" parameter into the url path.
    path += args[argsKey];

    startIndex = match.index + match[0].length;
  }
  // Concatenate any remaining path fragment
  path += pathTemplate.slice(startIndex);

  return queryString.stringifyUrl(
    {
      url: path,
      query: Object.entries(queryMap).reduce(
        (queryArgs, [queryKey, argsKey]) => {
          queryArgs[queryKey] = args[argsKey];
          return queryArgs;
        },
        {},
      ),
    },
    { arrayFormat: "comma" },
  );
}

export function getReportIcon({ fileType }) {
  return (
    { pdf: faFilePdf, csv: faFileCsv, zip: faFileArchive }[fileType] || faFile
  );
}

export function isCommercialUser(userRole) {
  // Only show report to business users that are marked as commercial users
  // show to everyone else
  if (userRole.type === userTypes.BUSINESS_USER) {
    return userRole.is_commercial_user;
  } else {
    return true;
  }
}
