import React from "react";

import {
  faCheck,
  faQuestion,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { connect, useSelector } from "react-redux";
import styled from "styled-components/macro";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import { AngusVerifiedEIDChip, Chip, ChipBag } from "components/Chip";
import { Row } from "components/Layout";

import { AuditLogTypes } from "constants/auditLog";
import { Species } from "constants/species";

import { formatWeightKg } from "lib";

import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

import {
  getConsignmentById,
  getPropertyById,
  getSaleLotById,
  getSaleyardScanSaleLotById,
  getSaleLotsBySale,
  getScans,
  getScanByEid,
  getWeighLotScanById,
  getWeighLotById,
} from "selectors";

const Scan = styled(Grid)(
  ({ theme }) => `
  border: 1px solid ${theme.colors.gray78};
  background-color: ${theme.colors.white};
`,
);

const StyledBooleanIcon = styled(FontAwesomeIcon)(
  ({ status, theme }) => `
color: ${status === true ? theme.colors.success : theme.colors.error};
`,
);

const ScanHeader = styled.div`
  font-weight: bold;
  ${({ right }) => right && `margin-left: auto;`}
`;

const BooleanIcon = ({ status }) => {
  let icon;
  switch (status) {
    case true:
      icon = faCheck;
      break;
    case false:
      icon = faTimes;
      break;
    default:
      icon = faQuestion;
      break;
  }
  return <StyledBooleanIcon icon={icon} status={status} />;
};

export function EIDWithNLISDataItem(props) {
  const {
    buyerName,
    buyerPIC,
    current_pic,
    EID,
    ERP_status,
    EU_status,
    id,
    isCattle,
    lifetime_traceability,
    nlis_id,
    penName,
    vendorName,
    vendorPIC,
    timeWeighed,
    totalMassGrams,
  } = props;

  const lifetimeTraceabilityStatus = lifetime_traceability
    ? lifetime_traceability === "Y"
    : null;

  const currentPicStatus = current_pic ? current_pic === vendorPIC : null;

  const scan = useSelector(getScanByEid(EID));

  const weighLotScan =
    useSelector(getWeighLotScanById(scan.weigh_lot_scan_id)) || {};

  const weighLot = useSelector(getWeighLotById(weighLotScan.weighLotId));

  return (
    <Scan item>
      <Row justifyAround>
        <AuditLogLink
          auditLogType={AuditLogTypes.SCAN}
          dataId={id}
          returnTo={window.location.hash}
        />
        <ScanHeader>{EID}</ScanHeader>

        <ScanHeader right>{nlis_id}</ScanHeader>
      </Row>
      <ChipBag>
        <Chip tooltip="ERP status returned from NLIS">ERP: {ERP_status}</Chip>

        <Chip tooltip="Current device PIC registered with NLIS.">
          {`Device PIC ${current_pic || "unknown"}`}{" "}
          <BooleanIcon status={currentPicStatus} />
        </Chip>

        <Chip tooltip="Lifetime traceability status">
          LT <BooleanIcon status={lifetimeTraceabilityStatus} />
        </Chip>
        {isCattle && <Chip tooltip="EU status">EU: {EU_status}</Chip>}
        {vendorName && <Chip>Vendor: {vendorName}</Chip>}
        {vendorName && vendorPIC && <Chip>Vendor PIC: {vendorPIC}</Chip>}
        {penName && <Chip>Pen: {penName}</Chip>}
        {buyerName && <Chip>Buyer: {buyerName}</Chip>}
        {buyerPIC && <Chip>Buyer PIC: {buyerPIC}</Chip>}
        {totalMassGrams && (
          <Chip>
            Weighed: {formatWeightKg(totalMassGrams, true)}{" "}
            {timeWeighed && `@ ${formatUTCToLocalDateTimeString(timeWeighed)}`}
          </Chip>
        )}
        {weighLot && (
          <Chip>
            Weigh Lot: {weighLot.scaleName} {weighLot.lotNumber}
          </Chip>
        )}
        <AngusVerifiedEIDChip EID={EID} />
      </ChipBag>
    </Scan>
  );
}
EIDWithNLISDataItem.propTypes = {
  buyerName: PropTypes.string,
  buyerPIC: PropTypes.string,
  current_pic: PropTypes.string,
  EID: PropTypes.string.isRequired,
  ERP_status: PropTypes.string,
  EU_status: PropTypes.string,
  id: PropTypes.string.isRequired,
  isCattle: PropTypes.bool,
  lifetime_traceability: PropTypes.string,
  nlis_id: PropTypes.string,
  penName: PropTypes.string,
  vendorName: PropTypes.string,
  vendorPIC: PropTypes.string,
  timeWeighed: PropTypes.string,
  totalMassGrams: PropTypes.number,
};

function mapStateToProps(state, props) {
  const { EID, saleLotId, largeView } = props;

  const scan = getScans(state)[EID];
  if (!scan) {
    return { EID };
  }

  const {
    animal: { nlis_id },
    current_pic,
    ERP_status,
    EU_status,
    id,
    lifetime_traceability,
    sale_lot_id,
    time_weighed: timeWeighed,
    total_mass_grams: totalMassGrams,
  } = scan;

  const preferredSaleLotId = saleLotId || sale_lot_id;

  const saleLot =
    getSaleLotById(preferredSaleLotId)(state) ||
    getSaleyardScanSaleLotById(preferredSaleLotId)(state) ||
    {};
  const consignmentId = saleLot.consignment_id || saleLot.consignmentId;
  const consignment = getConsignmentById(consignmentId)(state) || {};
  const vendorPropertyId = consignment.vendor_property_id;
  const vendorProperty = getPropertyById(vendorPropertyId)(state) || {};
  const vendorPIC = vendorProperty.PIC;

  const isCattle = saleLot.sale_round?.species === Species.CATTLE;

  const itemProps = {
    current_pic,
    EID,
    ERP_status,
    EU_status,
    id,
    isCattle,
    lifetime_traceability,
    nlis_id,
    vendorPIC,
    timeWeighed,
    totalMassGrams,
  };
  if (largeView) {
    const extendedSaleLot = getSaleLotsBySale(state).find(
      sl => sl.id === preferredSaleLotId,
    );
    // This may not exist, if the scan is not assigned to a lot
    if (extendedSaleLot) {
      itemProps.vendorName = extendedSaleLot.vendor_name;
      itemProps.buyerName = extendedSaleLot.buyer_name;
      itemProps.buyerPIC = extendedSaleLot.destinationProperty.PIC;
      itemProps.penName = extendedSaleLot.penName;
    }
  }
  return itemProps;
}

const EIDWithNLISData = connect(mapStateToProps)(EIDWithNLISDataItem);
EIDWithNLISData.propTypes = {
  EID: PropTypes.string.isRequired,
  saleLotId: PropTypes.string,
  largeView: PropTypes.bool,
};

export default EIDWithNLISData;
