import React from "react";

import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import { CollapseLabel, FormCollapse } from "components/Form";
import { AddressForm } from "components/Form/Fields";
import { withNamespace } from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { formatAddress } from "lib/address";

import { getActiveLivestockAgentDeployment } from "selectors";

import { useSectionToggle } from "hooks";

import { DeploymentSettingsModalSection, FormColumn } from "./index";

const AddressSubModalSection = {
  PHYSICAL: "PHYSICAL",
  POSTAL: "POSTAL",
};

export function AddressSection(props) {
  const { sectionHelper, ns } = props;
  const deployment = useSelector(getActiveLivestockAgentDeployment);
  const modalSection = DeploymentSettingsModalSection.AddressForm;
  const [section, setSection] = sectionHelper;

  const isOpen = section === modalSection;
  const onToggle = () => setSection(modalSection);

  const [subSection, setSubSection] = useSectionToggle(
    AddressSubModalSection.PHYSICAL,
  );

  const header = (title, address) => (
    <Row>
      <SubtleBadge>{title}</SubtleBadge>
      <CollapseLabel>{address ? formatAddress(address) : ""}</CollapseLabel>
    </Row>
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header("Address")}
    >
      <Grid container spacing={2}>
        <FormColumn>
          <FormCollapse
            isOpen={subSection === AddressSubModalSection.PHYSICAL}
            onToggle={() => setSubSection(AddressSubModalSection.PHYSICAL)}
            header={header(
              "Physical Address",
              deployment?.deploymentSettings?.physicalAddress,
            )}
          >
            <AddressForm
              label="Physical Address"
              namespace={withNamespace(ns, "physicalAddress")}
            />
          </FormCollapse>
          <FormCollapse
            isOpen={subSection === AddressSubModalSection.POSTAL}
            onToggle={() => setSubSection(AddressSubModalSection.POSTAL)}
            header={header(
              "Postal Address",
              deployment?.deploymentSettings?.postalAddress,
            )}
          >
            <AddressForm
              label="Postal Address"
              namespace={withNamespace(ns, "postalAddress")}
            />
          </FormCollapse>
        </FormColumn>
      </Grid>
    </FormCollapse>
  );
}
