import reduceReducers from "reduce-reducers";

import { CHECKPOINT } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineCommentReducer,
} from "lib/reducers";
import { deserializeCheckpoint } from "lib/serializers/checkpoints";

const create = apiModelOfflineCreateReducer(CHECKPOINT, {
  deserializer: deserializeCheckpoint,
});

const deleteReducer = apiModelOfflineDeleteReducer(CHECKPOINT);

const fetch = apiModelOfflineFetchReducer(CHECKPOINT, {
  deserializer: deserializeCheckpoint,
});

const update = apiModelOfflineUpdateReducer(CHECKPOINT, {
  deserializer: deserializeCheckpoint,
});

const comment = apiModelOfflineCommentReducer(CHECKPOINT);

const checkpointReducer = reduceReducers(
  create,
  comment,
  deleteReducer,
  fetch,
  update,
);

export default checkpointReducer;
