import reduceReducers from "reduce-reducers";

import { DRAFTING_DECISION, SINGLE_WEIGH } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  defaultInitialState,
} from "lib/reducers";
import { deserializeSingleWeigh } from "lib/serializers/singleWeigh";

const fetch = apiModelOfflineFetchReducer(SINGLE_WEIGH, {
  deserializer: deserializeSingleWeigh,
});

const update = apiModelOfflineUpdateReducer(SINGLE_WEIGH, {
  deserializer: deserializeSingleWeigh,
});

const defaultState = {
  decisionHistory: [],
  currentDecision: {
    buyerId: null,
    buyerWayName: null,
    destinationPenId: null,
    draftPenId: null,
    eid: null,
    detectedEids: [],
    manualDraftPenDecision: null,
    totalMassGrams: null,
    totalMassGramsRecorded: null,
    weightSamples: [],
    // Flags
    areMultipleEidsDetected: null,
    eidWaitTimeoutStarted: null,
    eidWaitTimeout: null,
    hasGoneBelowMinimumWeight: null,
    ignoreAlreadyWeighedCheck: null,
    overrideWeight: null,
    unsteadyWeightTimeoutStarted: null,
    unsteadyWeightTimeout: null,
  },
};

function singleWeighContextReducer(
  state = { ...defaultInitialState, context: {} },
  action,
) {
  switch (action.type) {
    case SINGLE_WEIGH.INITIALIZE.REQUEST: {
      const { singleWeighId } = action;
      return {
        ...state,
        currentSingleWeighId: singleWeighId,
        context: {
          ...state.context,
          [singleWeighId]: defaultState,
        },
      };
    }

    case SINGLE_WEIGH.ACTIVATE.ACTION: {
      const { singleWeighId } = action;
      return {
        ...state,
        currentSingleWeighId: singleWeighId,
      };
    }

    case SINGLE_WEIGH.DEACTIVATE.ACTION: {
      return {
        ...state,
        currentSingleWeighId: null,
      };
    }

    case SINGLE_WEIGH.RESET.ACTION: {
      const { singleWeighId } = action;

      // A few thoughts
      // 1 - This only resets the current decision
      // 2 - this does not reset the PLC state.  Should it?
      return {
        ...state,
        context: {
          ...state.context,
          [singleWeighId]: {
            ...state.context[singleWeighId],
            currentDecision: {
              ...defaultState.currentDecision,
              weightSamples: [],
            },
          },
        },
      };
    }

    case DRAFTING_DECISION.UPDATE.REQUEST: {
      const { singleWeighId, payload } = action;

      const currentDecision = {
        ...state.context[singleWeighId].currentDecision,
        ...payload,
      };

      return {
        ...state,
        context: {
          ...state.context,
          [singleWeighId]: {
            ...state.context[singleWeighId],
            currentDecision,
          },
        },
      };
    }

    case DRAFTING_DECISION.ACTION.REQUEST: {
      // Move the current drafting decision to history. and reset our current one.
      const { singleWeighId } = action;

      // If this hasn't been initalized yet, don't try and fire.
      if (!state.context[singleWeighId]) {
        return state;
      }

      // Put the most recent decision at the head.
      const updatedDecisionHistory = [
        state.context[singleWeighId].currentDecision,
        ...state.context[singleWeighId].decisionHistory,
      ];

      return {
        ...state,
        context: {
          ...state.context,
          [singleWeighId]: {
            ...state.context[singleWeighId],
            currentDecision: {
              ...defaultState.currentDecision,
              hasGoneBelowMinimumWeight: false,
              weightSamples: [],
            },
            decisionHistory: updatedDecisionHistory,
          },
        },
      };
    }

    default:
      return state;
  }
}

export default reduceReducers(singleWeighContextReducer, fetch, update);
