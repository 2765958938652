import { flatten, sortBy, sumBy, uniq } from "lodash";
import { createSelector } from "reselect";

import { NominationStatus } from "constants/sale";

import { caseInsensitiveCompare } from "lib/compare";

import {
  createLookupCombiner,
  createLookupSelectors,
  getBusinesses,
  getCurrentSale,
  getNominations,
  getNominationTerms,
  selectConsignmentIdsByNominationIdLookup,
} from "selectors";

import { selectFilteredNominationIds } from "selectors/globalSearch/nominationFilters";
import { createAggregateIdsByKeySelector } from "selectors/lib";

const selectInUseNominationTermIds = createSelector(
  [getNominations],
  nominations =>
    uniq(
      flatten(
        Object.values(nominations).map(nomination =>
          nomination.nominationDetails.map(detail => detail.nominationTermId),
        ),
      ),
    ),
);

export const selectActiveNominationTermIds = createSelector(
  [getNominationTerms, selectInUseNominationTermIds],
  (nominationsTerms, inUseNominationTermIds) =>
    Object.keys(nominationsTerms).filter(
      nominationsTermId =>
        !nominationsTerms[nominationsTermId].deprecated ||
        inUseNominationTermIds.includes(parseInt(nominationsTermId, 10)),
    ),
);

export const selectActiveNominationTermByIdLookup = createSelector(
  [getNominationTerms, selectActiveNominationTermIds],
  (nominationTerms, activeNominationTermIds) =>
    activeNominationTermIds.reduce((acc, activeNominationTermId) => {
      acc[activeNominationTermId] = nominationTerms[activeNominationTermId];
      return acc;
    }, {}),
);

export const getNominationById = nominationId => state =>
  getNominations(state)[nominationId] || null;

export const selectNominationOptions = createSelector(
  [getNominations, getBusinesses],
  (nominations, businessById) =>
    sortBy(
      Object.values(nominations).map(nomination => {
        const nominationHd = nomination.nominationDetails?.reduce(
          (total, nomination) => nomination.quantity + total,
          0,
        );
        return {
          label: `${
            businessById[nomination.vendorId]?.name || ""
          } - ${nominationHd}`,
          value: nomination.id,
          deployment: nomination.deploymentId,
        };
      }),
      ["label"],
    ),
);

export const getConsignmentIdsByNominationId = nominationId => state =>
  selectConsignmentIdsByNominationIdLookup(state)[nominationId] || null;

export const selectOrderedNominationIds = createSelector(
  [selectFilteredNominationIds, getNominations, getBusinesses],
  (filteredNominationIds, nominations, businessByIdLookup) =>
    filteredNominationIds.sort((nominationIdA, nominationIdB) => {
      const nominationA = nominations[nominationIdA];
      const nominationB = nominations[nominationIdB];
      if (nominationA.hasArrived === nominationB.hasArrived) {
        const aBusiness = businessByIdLookup[nominationA.vendorId] || {
          name: "",
        };
        const bBusiness = businessByIdLookup[nominationB.vendorId] || {
          name: "",
        };
        return caseInsensitiveCompare(aBusiness.name, bBusiness.name);
      } else if (!nominationB.hasArrived) {
        return 1;
      }
      return -1;
    }),
);

function nominationStatusReducer(nomination) {
  return nomination.hasArrived
    ? NominationStatus.ARRIVED
    : NominationStatus.NOMINATED;
}

export const [selectStatusByNominationIdLookup, getStatusByNominationId] =
  createLookupSelectors(
    [getNominations],
    createLookupCombiner(nominationStatusReducer),
  );

export const selectNominationTermQuantityByIdLookup = createSelector(
  [getNominations],
  nominations => {
    return Object.values(nominations).reduce((acc, nomination) => {
      const { nominationDetails } = nomination;

      nominationDetails.forEach(detail => {
        if (acc[detail.nominationTermId]) {
          acc[detail.nominationTermId] += detail.quantity;
        } else {
          acc[detail.nominationTermId] = detail.quantity;
        }
      });
      return acc;
    }, {});
  },
);

export const selectNominationTotalsByGroupNameAndTermLookup = createSelector(
  [
    getNominations,
    selectActiveNominationTermByIdLookup,
    selectNominationTermQuantityByIdLookup,
  ],
  (nominations, nominationTerms, nominationTermQuantityById) =>
    Object.values(nominations).reduce((acc, nom) => {
      const { nominationDetails } = nom;

      nominationDetails.forEach(detail => {
        const nominationTerm = nominationTerms[detail.nominationTermId];

        if (!(nominationTerm.groupName in acc)) {
          acc[nominationTerm.groupName] = {};
        }
        if (!(nominationTerm.name in acc[nominationTerm.groupName])) {
          acc[nominationTerm.groupName][nominationTerm.name] =
            nominationTermQuantityById[nominationTerm.id];
        }
      });
      return acc;
    }, {}),
);

export const getTotalNominationQuantity = createSelector(
  [getNominations],
  nominations =>
    Object.values(nominations).reduce(
      (acc, nom) => (acc += sumBy(nom.nominationDetails, "quantity")),
      0,
    ),
);

export const selectNominationIdsByVendorIdLookup =
  createAggregateIdsByKeySelector(getNominations, "vendorId");

export const selectNominationTermIdsBySaleyardIdLookup =
  createAggregateIdsByKeySelector(
    selectActiveNominationTermByIdLookup,
    "saleyardId",
  );

function nominationTermByIdReducer(nominationTermIds, nominationTermById) {
  return nominationTermIds.reduce((acc, nominationTermId) => {
    acc[nominationTermId] = nominationTermById[nominationTermId];
    return acc;
  }, {});
}

export const [
  selectNominationTermsBySaleyardIdLookup,
  getNominationTermsBySaleyardId,
] = createLookupSelectors(
  [
    selectNominationTermIdsBySaleyardIdLookup,
    selectActiveNominationTermByIdLookup,
  ],
  createLookupCombiner(nominationTermByIdReducer),
);

export const getIsNominationsLocked = createSelector(
  [getCurrentSale],
  currentSale =>
    currentSale.deployment_sales[0]?.is_nominations_locked || false,
);
