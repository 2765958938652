import queryString from "query-string";
import { takeEvery } from "redux-saga/effects";

import { TEST_RULEBOOK } from "constants/actionTypes";

import { synchronousActionHandler } from "sagas/lib";

import { api } from "./api";

function* testHandler(action) {
  const url = queryString.stringifyUrl(
    {
      url: `/v2/test-rules/`,
      query: {
        id__in: action.livestockSaleIds,
        rulebook_id: action.ruleBookId,
      },
    },
    { arrayFormat: "comma" },
  );

  return yield api.post(url, action.ruleBookData);
}

function* onTestRuleBook(action) {
  yield synchronousActionHandler(action, testHandler);
}

export default function* rootSaga() {
  yield takeEvery(TEST_RULEBOOK.REQUEST, onTestRuleBook);
}
