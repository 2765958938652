import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { AlmostFullScreenModalTableWrapper } from "components/AgGrid/TableWrapper";
import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";
import { saleLotColumns } from "components/SaleLotsTable/columnDefs";
import getScanColumnDefs from "components/ScanTable/columnDefinitions";

import { AgGridTables } from "constants/aggrid";
import { SaleyardPermissions } from "constants/permissions";

import { formatWeightKg } from "lib";

import {
  currentSaleSelector,
  getCurrentSale,
  getCurrentSaleyard,
  getHasWriteAccessInCurrentSale,
  getWeighLotScanById,
  scanList,
  selectAreStudFeaturesEnabled,
  selectGeneralSaleLotsAggridData,
} from "selectors";

import { useDebounceSelector, useHasSaleyardPermission } from "hooks";

import { MapWeighLotScanButton } from "./MapWeighLotScanButton";

const MapWeighLotScanTableModal = ({
  tableName,
  weighLotScanId,
  closeSelf,
}) => {
  const agGridApiRef = useRef();
  const [selectedRows, setSelectedRows] = useState([]);

  const sale = useSelector(currentSaleSelector);

  const { sale_type: saleType } = sale;

  const areStudFeaturesEnabled = useSelector(selectAreStudFeaturesEnabled);

  const arePenScanLotFeaturesEnabled = useHasSaleyardPermission(
    SaleyardPermissions.featurePenScanLots,
  );

  const weighLotScan = useSelector(getWeighLotScanById(weighLotScanId));

  function onGridReady({ api }) {
    agGridApiRef.current = api;
  }

  const scans = useDebounceSelector(scanList, 500);

  const saleLots = useDebounceSelector(selectGeneralSaleLotsAggridData, 500);

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const currentSaleyard = useSelector(getCurrentSaleyard);

  const currentSale = useSelector(getCurrentSale);

  const scanColumnDefs = getScanColumnDefs(
    false,
    hasWriteAccessInCurrentSale,
    currentSaleyard,
    currentSale,
    true,
  );

  const saleLotColumnDefs = React.useMemo(
    () =>
      saleLotColumns(
        saleType,
        areStudFeaturesEnabled,
        arePenScanLotFeaturesEnabled,
      ),
    [saleType, areStudFeaturesEnabled, arePenScanLotFeaturesEnabled],
  );

  const extraComponents = [
    <MapWeighLotScanButton
      eid={selectedRows?.[0]?.scan?.EID || null}
      saleLotId={selectedRows?.[0]?.id || null}
      tableName={tableName}
      weighLotScanId={weighLotScanId}
      closeSelf={closeSelf}
    />,
  ];

  return (
    <Dialog fullScreen open onClose={closeSelf} maxWidth="md" fullWidth>
      <DialogTitle onClose={closeSelf}>
        Select a {tableName === AgGridTables.SALE_LOT ? "Sale Lot" : "Scan"} to
        Map {formatWeightKg(weighLotScan.totalMassGrams)} from EID:{" "}
        {weighLotScan.eid}
      </DialogTitle>
      <DialogContent dividers>
        <AgGridTable
          columnDefs={
            tableName === AgGridTables.SALE_LOT
              ? saleLotColumnDefs
              : scanColumnDefs
          }
          extraHeaderComponents={extraComponents}
          onGridReady={onGridReady}
          onRowSelectionChanged={setSelectedRows}
          rowData={tableName === AgGridTables.SALE_LOT ? saleLots : scans}
          tableName={tableName}
          rowSelection="single"
          WrapperComponent={AlmostFullScreenModalTableWrapper}
          rowSelectionId={
            tableName === AgGridTables.SALE_LOT ? "saleLot.id" : "scan.EID"
          }
          rowsSelectable
        />
      </DialogContent>
    </Dialog>
  );
};

export default MapWeighLotScanTableModal;
