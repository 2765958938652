import React, { useCallback, useEffect } from "react";

import {
  faCheck,
  faExclamationCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Grid, Tooltip } from "@material-ui/core";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { SubtleBadge } from "components/Badge";
import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import IconTextButton from "components/Button/IconTextButton";
import { Warning } from "components/ErrorMessage";
import { CollapseLabel } from "components/Form";
import { AgencyInputField, NVDNumberField } from "components/Form/Fields";
import { DeploymentSelectField } from "components/Form/Fields/DeploymentField";
import { NominationSelectField } from "components/Form/Fields/NominationField";
import {
  ControlledLifeCycleInput,
  Input,
  Label,
  parseDecimalInputValueDefaultToNull,
  PercentageInputProps,
  SelectField,
  Switch,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";
import { ReceivalLotSelector } from "components/Form/FormikControls/ReceivalLotSelector";

import { VENDOR } from "constants/businesses";
import { Accreditation } from "constants/draftingAttributes";
import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { SaleTypes } from "constants/sale";
import { Species } from "constants/species";

import { ForWithOtherDeployments } from "containers/FeatureToggles/Deployments";
import {
  ForClearingSale,
  ForConsignableSales,
  ForLivestockAuction,
  ForNotClearingSale,
} from "containers/ForSaleType";
import { ForLivestockAgent, ForSaleyardAdmin } from "containers/ForUserType";

import { formatPercentage } from "lib";

import {
  getBusinessById,
  getCurrentSaleyard,
  getCurrentSpeciesId,
  getEffectiveDeploymentId,
  getHasWriteAccessInCurrentSale,
  getIsLivestockAgent,
  getIsSaleLocked,
  getNameByDeploymentId,
  getNominations,
  getProperties,
  selectAgencyBusinessByBusinessId,
  selectCurrentDeploymentSales,
} from "selectors";

import {
  useHasDeploymentPermission,
  useHasPermission,
  useHasSaleyardPermission,
  useTranslatedSaleTypeText,
} from "hooks";

import ClearingSaleVendorDetails from "./ClearingSaleVendorDetails";

function nvdCheck(nvdExists) {
  if (nvdExists === true) {
    return <FaIcon icon={faCheck} color="success" />;
  } else {
    return <FaIcon icon={faExclamationCircle} color="warning" />;
  }
}

const businessRoles = [VENDOR];

export function GeneralFormSectionHeader(props) {
  const { headcount, vendorName, NVD } = props;

  const nvdExists = Boolean(NVD);

  return (
    <Grid container>
      <SubtleBadge>General</SubtleBadge>
      <Tooltip title="Vendor">
        <CollapseLabel>{vendorName}</CollapseLabel>
      </Tooltip>
      {headcount !== null && headcount !== "" && (
        <Tooltip title="Head count">
          <CollapseLabel>{`${headcount} Head`}</CollapseLabel>
        </Tooltip>
      )}
      <Tooltip title="NVD">
        <CollapseLabel>{nvdCheck(nvdExists)}</CollapseLabel>
      </Tooltip>
    </Grid>
  );
}

export function GeneralForm(props) {
  const {
    branches,
    isDeleteDisabled,
    deploymentSaleId,
    onDeleteConsignment,
    showDelete,
    vendorNumConflictCount,
    isCreate,
    restrictConsignedFrom,
    isVendorNumberingAllowed,
    selectedSaleType,
    requireConsignedFrom,
    saleyardName,
    consignmentHasSaleLots,
    readOnly,
    showNominations,
    defaultAgencyId,
  } = props;

  const hasReceivalLotPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featureReceivalLots,
  );

  const isLivestockAgent = useSelector(getIsLivestockAgent);
  const saleTypeText = useTranslatedSaleTypeText(
    "Receival Ref",
    selectedSaleType,
  );
  const formikProps = useFormikContext();
  const branchOptions = branches.map(b => ({ label: b.name, value: b.id }));
  const properties = useSelector(getProperties);
  const speciesId = useSelector(getCurrentSpeciesId);

  const {
    initialValues: {
      receiving_pen: initialReceivingPen,
      vendor_id: initialVendorId,
    },
    setFieldTouched,
    setFieldValue,
    values: {
      agency_id: agencyId,
      consignedFromId,
      hasArrived,
      quantity,
      vendor_id: vendorId,
      vendor_property_id: vendorPropertyId,
      draftingAttributes: {
        accreditationEU,
        accreditationJBAS,
        accreditationNEE,
        accreditationPCAS,
      },
    },
  } = formikProps;

  const agencyBusinessesByBusinessIdLookup = useSelector(
    selectAgencyBusinessByBusinessId,
  );

  const isManualVendorNumberingEnabled = useSelector(state =>
    Boolean(
      selectCurrentDeploymentSales(state)[deploymentSaleId]
        ?.manual_vendor_numbers,
    ),
  );

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const hasReceivalLotsFeature = useHasSaleyardPermission(
    SaleyardPermissions.featureReceivalLots,
  );

  const consigningDeploymentId = useSelector(getEffectiveDeploymentId);

  const defaultVendorCommission = useSelector(state => {
    return selectCurrentDeploymentSales(state)[deploymentSaleId]
      ?.default_vendor_commission;
  });

  const { name: vendorName, defaultConsigningDeploymentId } =
    useSelector(getBusinessById(vendorId)) || {};
  const deploymentName = useSelector(getNameByDeploymentId(consignedFromId));

  const hasNominations =
    useSelector(state => !isEmpty(getNominations(state))) && showNominations;

  const applyPropertyAdvancedDrafting = useCallback(
    propertyId => {
      // These values are cattle based - sheep not so much.
      const selectedProperty = properties[propertyId];
      if (speciesId === Species.CATTLE) {
        if (accreditationEU === null) {
          setFieldValue(
            withNamespace("draftingAttributes", "accreditationEU"),
            selectedProperty && selectedProperty.accreditationEu
              ? Accreditation.ALL
              : Accreditation.NONE,
          );
        }

        if (accreditationJBAS === null || accreditationJBAS === "") {
          setFieldValue(
            withNamespace("draftingAttributes", "accreditationJBAS"),
            selectedProperty.accreditationJbas,
          );
        }

        if (accreditationNEE === null) {
          setFieldValue(
            withNamespace("draftingAttributes", "accreditationNEE"),
            selectedProperty && selectedProperty.accreditationNee
              ? Accreditation.ALL
              : Accreditation.NONE,
          );
        }

        if (accreditationPCAS === null) {
          setFieldValue(
            withNamespace("draftingAttributes", "accreditationPCAS"),
            selectedProperty && selectedProperty.accreditationPcas
              ? Accreditation.ALL
              : Accreditation.NONE,
          );
        }
      }
    },
    [
      properties,
      setFieldValue,
      accreditationEU,
      accreditationJBAS,
      accreditationNEE,
      accreditationPCAS,
      speciesId,
    ],
  );

  const onSelectVendor = useCallback(
    (vendorId, propertyId) => {
      const userSelectedNewVendor = vendorId && vendorId !== initialVendorId;
      const userReselectedSameVendor = vendorId && vendorId === initialVendorId;
      if (!hasWriteAccessInCurrentSale) {
        setFieldValue("consignedFromId", consigningDeploymentId, false);
      } else if (
        !requireConsignedFrom &&
        (userSelectedNewVendor || userReselectedSameVendor)
      ) {
        const businessesByAgencyIdLookup =
          agencyBusinessesByBusinessIdLookup[vendorId] || {};
        const { defaultConsigningDeploymentId = null } =
          (agencyId
            ? businessesByAgencyIdLookup[agencyId]
            : businessesByAgencyIdLookup.master) || {};

        setFieldValue("consignedFromId", defaultConsigningDeploymentId, false);
      }
      // If the property has changed, we want to check the Advanced Drafting data and update accordingly.
      if (propertyId !== vendorPropertyId) {
        applyPropertyAdvancedDrafting(vendorPropertyId);
      }
    },
    [
      agencyBusinessesByBusinessIdLookup,
      agencyId,
      hasWriteAccessInCurrentSale,
      consigningDeploymentId,
      initialVendorId,
      setFieldValue,
      requireConsignedFrom,
      vendorPropertyId,
      applyPropertyAdvancedDrafting,
    ],
  );

  const onReceivingPenChanged = useCallback(
    event => {
      const nextReceivingPen = event.target.value;
      /* When removing the ReceivingPen:
       * * Setting to Arrived if:
       *   * The Consignment has Sale Lots
       *   * The Consignment was marked as arrived when the form was opened
       * When adding a ReceivingPen has the effect of:
       * * Always setting to Arrived
       */

      const nextHasArrived = Boolean(
        consignmentHasSaleLots || nextReceivingPen || initialReceivingPen,
      );
      if (nextHasArrived !== hasArrived) {
        setFieldValue("hasArrived", nextHasArrived).then(() =>
          setFieldTouched("hasArrived"),
        );
      }
    },
    [
      consignmentHasSaleLots,
      hasArrived,
      initialReceivingPen,
      setFieldValue,
      setFieldTouched,
    ],
  );

  const showSetDefaultConsigningDeployment =
    vendorId &&
    consignedFromId &&
    consignedFromId !== defaultConsigningDeploymentId &&
    isLivestockAgent;

  useEffect(() => {
    setFieldValue(
      "shouldUpdateVendorDefaultConsigningDeployment",
      consignedFromId &&
        defaultConsigningDeploymentId === null &&
        isLivestockAgent, // Don't allow SY admins to inadvertently update the business
    );
  }, [
    consignedFromId,
    defaultConsigningDeploymentId,
    isLivestockAgent,
    setFieldValue,
    showSetDefaultConsigningDeployment,
  ]);

  useEffect(() => {
    if (
      typeof quantity !== "number" &&
      selectedSaleType === SaleTypes.CLEARING
    ) {
      setFieldValue("quantity", 1);
    }
  }, [selectedSaleType, setFieldValue, quantity]);

  let vendorNumberHelpText;
  if (deploymentSaleId) {
    if (!isManualVendorNumberingEnabled) {
      vendorNumberHelpText = "Automatic Vendor Numbering is enabled";
    }
  } else {
    vendorNumberHelpText = "Select an Agency";
  }

  const hasBillingPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureBilling,
  );

  const isSaleLocked = useSelector(getIsSaleLocked);

  const hasBillingPermissionAndSaleIsLocked =
    hasBillingPermission && isSaleLocked;

  const consignmentPic = useSelector(getProperties)[vendorPropertyId]?.PIC;

  return (
    <>
      {isCreate && (
        <ForSaleyardAdmin>
          <Grid item xs={12}>
            <AgencyInputField
              name="agency_id"
              required
              isCurrentSaleOnly
              disabled={defaultAgencyId}
            />
          </Grid>
        </ForSaleyardAdmin>
      )}
      <Grid item xs={12}>
        <StateAwareBusinessPICSelector
          agencyId={agencyId}
          label="Vendor"
          onAfterSelect={onSelectVendor}
          businessFieldName="vendor_id"
          propertyFieldName={
            hasBillingPermissionAndSaleIsLocked ? null : "vendor_property_id"
          }
          formikProps={formikProps}
          error={formikProps.touched.vendor_id && formikProps.errors.vendor_id}
          businessRoles={businessRoles}
          saleyardName={saleyardName}
          disabled={readOnly ? !hasBillingPermissionAndSaleIsLocked : readOnly}
        />
      </Grid>
      {hasBillingPermissionAndSaleIsLocked && (
        <Grid item xs={12}>
          <Label>Vendor PIC</Label>
          <input className="p-2 w-full" value={consignmentPic} disabled />
        </Grid>
      )}
      <ForConsignableSales selectedSaleType={selectedSaleType}>
        <ForWithOtherDeployments>
          <ForLivestockAgent>
            <Grid item xs={12}>
              <DeploymentSelectField
                label="Consigned From"
                name="consignedFromId"
                restrictToOwnDeployments={restrictConsignedFrom}
                required={restrictConsignedFrom}
              />
            </Grid>
            {showSetDefaultConsigningDeployment && (
              <Grid item xs={12}>
                <Switch
                  label={`Set ${deploymentName} as default for ${vendorName}`}
                  name="shouldUpdateVendorDefaultConsigningDeployment"
                />
              </Grid>
            )}
          </ForLivestockAgent>
        </ForWithOtherDeployments>
      </ForConsignableSales>

      <ForLivestockAuction selectedSaleType={selectedSaleType}>
        {isVendorNumberingAllowed && (
          <Grid item xs={12} sm={6}>
            <Input
              label="Vendor Number"
              name="vendorNumber"
              helpText={vendorNumberHelpText}
              disabled={!isManualVendorNumberingEnabled || readOnly}
              disableAutoComplete
            />
            {vendorNumConflictCount > 0 && (
              <Warning>
                {vendorNumConflictCount} other consignment
                {vendorNumConflictCount > 1 && "s"} with the same vendor number
                found.
              </Warning>
            )}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={6}
          // If there are no branches, maintain the space in the grid,
          // but dont show any padding.
          style={{ padding: branchOptions.length === 0 ? 0 : undefined }}
        >
          {branchOptions.length > 0 && (
            <SelectField
              label="Vendor Branch"
              name="branch_id"
              options={branchOptions}
              isClearable
              disabled={readOnly}
            />
          )}
        </Grid>
      </ForLivestockAuction>
      {hasReceivalLotsFeature ? (
        <Grid item xs={12}>
          <ReceivalLotSelector modalType={ModalTypes.Consignment} />
        </Grid>
      ) : null}
      <Grid item container spacing={0} xs={12} sm={6}>
        <ForLivestockAuction selectedSaleType={selectedSaleType}>
          {!hasReceivalLotPermission && (
            <Grid item xs={12} style={{ paddingBottom: 16 }}>
              <Input
                label="Receival Pen"
                name="receiving_pen"
                onChangeExtra={onReceivingPenChanged}
                disableAutoComplete
                disabled={readOnly}
              />
            </Grid>
          )}
        </ForLivestockAuction>
        <ForNotClearingSale selectedSaleType={selectedSaleType}>
          <Grid item xs={12}>
            <NVDNumberField
              required
              label="NVD number"
              name="NVD"
              disableAutoComplete
              disabled={readOnly}
            />
          </Grid>
        </ForNotClearingSale>
        <ForClearingSale selectedSaleType={selectedSaleType}>
          <Grid item xs={12}>
            <ControlledLifeCycleInput
              {...PercentageInputProps}
              disabled={readOnly}
              label="Vendor Commission"
              name="vendorCommission"
              parseInputValue={parseDecimalInputValueDefaultToNull}
              placeholder={formatPercentage(defaultVendorCommission, {
                includeSymbol: false,
              })}
              tooltip={`The Commission rate for this Vendor. If left blank, this Vendor will use the default for the Sale (${formatPercentage(defaultVendorCommission)}).`}
            />
          </Grid>
        </ForClearingSale>
      </Grid>

      <Grid item container spacing={0} xs={12} sm={6}>
        <Grid item xs={12} sm={12} style={{ display: "flex" }}>
          <TextArea
            label={saleTypeText}
            name="receiving_reference"
            maxLength={100}
            disabled={readOnly}
          />
        </Grid>
      </Grid>

      <ForNotClearingSale selectedSaleType={selectedSaleType}>
        <Grid item xs={6}>
          <Input
            label="NVD Hd"
            name="quantity_NVD"
            type="number"
            tooltip="The number of animals as written on the National Vendor Declaration."
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label="Actual/Booked Hd"
            name="quantity"
            type="number"
            required
            tooltip="The number of animals received or expected to be received."
            disableAutoComplete
            disabled={readOnly}
          />
        </Grid>
      </ForNotClearingSale>

      {hasNominations && (
        <Grid item xs={12} sm={6}>
          <NominationSelectField
            label="Nomination"
            name="nominationId"
            disabled={!hasWriteAccessInCurrentSale || readOnly}
          />
        </Grid>
      )}

      <ForLivestockAuction selectedSaleType={selectedSaleType}>
        <Grid item xs={12} sm={6}>
          <Switch
            label="Consignment Arrived"
            name="hasArrived"
            disabled={readOnly}
          />
        </Grid>
      </ForLivestockAuction>

      <ForClearingSale selectedSaleType={selectedSaleType}>
        {vendorId && vendorId !== -1 && (
          <ClearingSaleVendorDetails
            vendorId={vendorId}
            saleyardName={saleyardName}
            disabled={readOnly}
          />
        )}
      </ForClearingSale>
      {showDelete && (
        <Grid item xs={12} sm={6}>
          <IconTextButton
            icon={faTrash}
            color="gray40"
            onClick={onDeleteConsignment}
            disabled={isDeleteDisabled}
            dataTour="deleteConsignment"
          >
            Delete Consignment
          </IconTextButton>
        </Grid>
      )}
    </>
  );
}
