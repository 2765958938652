import React, { memo } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  addSaleyardScans,
  deleteDeviceImportedScans,
  keepDeviceImportedScans,
  uploadUnallocatedScans,
  uploadScansAndDraftingInformation,
} from "actions";

import StandardScanningColumn from "components/ScanningScreen/StandardScanningColumn";

import { userTypes } from "constants/users";

import { scanLotConfig } from "lib/scanLot";

import { getActiveRole, getImportedScans } from "selectors";

function mapImportEidToSaleLotScan(importedScan) {
  const {
    created,
    deviceId,
    deviceName,
    eid,
    NLISID,
    ignoreDuplicate,
    sessionName,
  } = importedScan;
  return {
    EID: eid,
    created: new Date(created).toISOString(),
    device_id: deviceId,
    draft_name: sessionName,
    device_name: deviceName,
    NLISID,
    remove_from_salelot: ignoreDuplicate,
  };
}

export function DeviceImportScanningColumnComponent(props) {
  const {
    closeSelf,
    consignmentId,
    onOpenCreateBulkModal,
    onOpenCreateModal,
    onOpenClearModal,
    onOpenClearSavedModal,
    onOpenSelectModal,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    scanListType,
    eids,
    keepableEids,
    duplicateEids,
    showScanDiscrepancyDialog,
    isScanDiscrepancyDialogVisible,
    hideScanDiscrepancyDialog,
    hiddenEids,
  } = props;

  const { lotAction } = scanLotConfig(penType, scanLotId);

  const dispatch = useDispatch();
  const userRole = useSelector(getActiveRole);
  const importedScans = useSelector(getImportedScans);

  const disallowTaken = userRole.type === userTypes.SCALE_OPERATOR;

  const scans = eids.map(eid => mapImportEidToSaleLotScan(importedScans[eid]));

  const duplicateScans = duplicateEids.map(eid =>
    mapImportEidToSaleLotScan(importedScans[eid]),
  );

  function uploadLotScansAction(
    eids,
    scanLotId,
    penId,
    penArchetypeId,
    scanLotValues,
  ) {
    return lotAction.updateOrCreateWithPenAndScans(
      scanLotId,
      penId,
      penArchetypeId,
      scanLotValues,
      eids.map(eid => mapImportEidToSaleLotScan(importedScans[eid])),
    );
  }

  function onAfterEidsActions(actionedEids) {
    if (Array.isArray(actionedEids)) {
      // clear all of the eids which have been actioned
      dispatch(deleteDeviceImportedScans(actionedEids));
    } else {
      // clear all of the eids (which are not duplicate, or un-keepable)
      dispatch(deleteDeviceImportedScans(eids));
    }

    if (userRole.type === userTypes.SCALE_OPERATOR && !duplicateEids.length) {
      closeSelf();
    }
  }

  function uploadScansAction(eids, saleLotId) {
    return uploadScansAndDraftingInformation(
      eids.map(eid => mapImportEidToSaleLotScan(importedScans[eid])),
      saleLotId,
    );
  }

  function uploadUnallocatedScansAction(eids, sale) {
    return uploadUnallocatedScans(
      eids.map(eid => mapImportEidToSaleLotScan(importedScans[eid])),
      sale,
    );
  }

  function saleyardScanAction(eids) {
    return addSaleyardScans(
      eids.map(eid => mapImportEidToSaleLotScan(importedScans[eid])),
      consignmentId,
    );
  }

  return (
    <StandardScanningColumn
      consignmentId={consignmentId}
      disallowTaken={disallowTaken}
      draftName="Session Import"
      duplicateEids={duplicateEids}
      eids={eids}
      getConsignmentUploadAction={saleyardScanAction}
      getDeleteAction={deleteDeviceImportedScans}
      getKeepAction={keepDeviceImportedScans}
      getUploadAction={uploadScansAction}
      getUploadUnallocatedAction={uploadUnallocatedScansAction}
      getLotUploadAction={uploadLotScansAction}
      isSelected
      keepableEids={keepableEids}
      onAfterUploadAction={onAfterEidsActions}
      onAfterDeleteAction={onAfterEidsActions}
      onOpenCreateBulkModal={onOpenCreateBulkModal}
      onOpenCreateModal={onOpenCreateModal}
      onOpenClearModal={onOpenClearModal}
      onOpenClearSavedModal={onOpenClearSavedModal}
      onOpenSelectModal={onOpenSelectModal}
      saleLotId={saleLotId}
      penArchetypeId={penArchetypeId}
      penId={penId}
      scanLotId={scanLotId}
      penType={penType}
      scanListType={scanListType}
      showScanDiscrepancyDialog={showScanDiscrepancyDialog}
      isScanDiscrepancyDialogVisible={isScanDiscrepancyDialogVisible}
      hideScanDiscrepancyDialog={hideScanDiscrepancyDialog}
      scans={scans}
      duplicateScans={duplicateScans}
      hiddenEids={hiddenEids}
    />
  );
}
export default memo(DeviceImportScanningColumnComponent);
