import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { ERPChip } from "components/Chip";
import { Column, Row } from "components/Layout";

import { AuctionScreenMode } from "constants/auctionModes";
import { Settings } from "constants/settings";

import { ForSaleyardAdmin } from "containers/ForUserType";

import {
  getPenFirstWeighed,
  getPenLastSeenAtTime,
  getSaleLotDescription,
  getSalesOrderedByStatus,
  getTotalSaleLotQuantity,
} from "lib/auctionPens";
import {
  formatSaleLotOverflow,
  getProgenyCountFromSaleLots,
  getProgenyDisplayCount,
} from "lib/saleLot";
import { aggregateText } from "lib/textUtils";
import {
  formatUTCToLocalDateTimeString,
  formatUTCToLocalHighResDayTimeString,
} from "lib/timeFormats";

import { getSetting } from "selectors";

import BadgeList from "./BadgeList";

const Wrapper = styled(Column)`
  box-shadow: 0 1px 3px 0 rgba(200, 200, 200, 0.59);
  background-color: #ffffff;
  padding: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  ${({ color, selected, theme }) => {
    const selectedColor = color && theme.colors[color];
    return `
        color: ${selectedColor || "#4a4a4a"};
        ${selected && `box-shadow: 0 0 1px 2px #4A90E2`}
    `;
  }};
`;

const HeaderCard = styled(Row)`
  font-weight: 500;
`;

const MediumText = styled.div`
  font-size: 24px;
`;

const SmallerText = styled.div`
  font-size: 14px;
  padding-left: 4px;
`;

const SaleLotDescription = styled.div`
  font-size: 18px;
  padding-bottom: 6px;
  line-height: 24px;
`;

const SaleLotQuantity = styled.div`
  font-size: 24px;
`;

function AuctionPenSaleCard(props) {
  const {
    auctionPen,
    selectedRoundId,
    selectPen,
    overflowQuantity,
    overflowPen,
  } = props;

  const saleLotsFiltered = auctionPen.sale_lots.filter(
    lot =>
      lot.sale_round && parseInt(lot.sale_round.id, 10) === selectedRoundId,
  );
  const selectedSaleLotQuantity = getTotalSaleLotQuantity(saleLotsFiltered);
  const selectedSaleLotProgeny = getProgenyCountFromSaleLots(saleLotsFiltered);
  const salesOrderByStatus = getSalesOrderedByStatus(saleLotsFiltered);
  const saleLotDescription = getSaleLotDescription(
    saleLotsFiltered,
    saleLotsFiltered.map(lot => lot.id),
  );

  const agencies = aggregateText(
    saleLotsFiltered,
    lot => lot?.livestockAgency?.name,
    "Agencies",
  );

  const overflowDisplayValue = formatSaleLotOverflow(
    overflowPen,
    overflowQuantity,
  );

  const mode = useSelector(getSetting(Settings.auctionScreenMode));

  const weighTime = getPenFirstWeighed(auctionPen);
  const lastSeenTime = getPenLastSeenAtTime(auctionPen);
  const onClick = () => {
    selectPen(selectedRoundId, auctionPen.id);
  };

  return (
    <Wrapper
      data-tour={`auction:${auctionPen.start_pen}`}
      color="gray40"
      onClick={onClick}
    >
      <HeaderCard justifyBetween>
        <Row baseline>
          AP
          <MediumText>
            {`${auctionPen.start_pen}${
              auctionPen.end_pen ? `-${auctionPen.end_pen}` : ""
            }`}
          </MediumText>
          {overflowDisplayValue && (
            <SmallerText>{overflowDisplayValue}</SmallerText>
          )}
        </Row>
        <ForSaleyardAdmin>
          <Row baseline> {agencies}</Row>
        </ForSaleyardAdmin>
        <Row baseline>
          {selectedSaleLotQuantity >= 0 && (
            <>
              HC
              <SaleLotQuantity>
                {selectedSaleLotQuantity}{" "}
                {getProgenyDisplayCount(selectedSaleLotProgeny)}
              </SaleLotQuantity>
            </>
          )}
        </Row>
      </HeaderCard>

      <Row justifyBetween className="pt-2">
        <Row baseline>
          <SaleLotDescription>{saleLotDescription}</SaleLotDescription>
        </Row>
        {mode === AuctionScreenMode.Weigh ? (
          <Row>
            <SmallerText>
              Weighed:{" "}
              {weighTime
                ? formatUTCToLocalHighResDayTimeString(weighTime)
                : "-"}
            </SmallerText>
          </Row>
        ) : null}
        {mode === AuctionScreenMode.LastSeen ? (
          <Row>
            <SmallerText>
              Last Seen:{" "}
              {lastSeenTime
                ? formatUTCToLocalDateTimeString(lastSeenTime)
                : "-"}
            </SmallerText>
          </Row>
        ) : null}
        <BadgeList
          salesOrderByStatus={salesOrderByStatus}
          auctionPen={auctionPen}
          selectedSaleLotQuantity={selectedSaleLotQuantity}
        />
      </Row>
      <Row baseline>
        {" "}
        <ERPChip saleLots={saleLotsFiltered} />
      </Row>
    </Wrapper>
  );
}

AuctionPenSaleCard.propTypes = {
  auctionPen: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    start_pen: PropTypes.string.isRequired,
    end_pen: PropTypes.string,
    sale_lots: PropTypes.array.isRequired,
  }).isRequired,
  overflowPen: PropTypes.string,
  overflowQuantity: PropTypes.number,
  selectPen: PropTypes.func.isRequired,
  selectedRoundId: PropTypes.number.isRequired,
};

export default AuctionPenSaleCard;
