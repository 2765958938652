import styled from "styled-components/macro";

export const Info = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.space[1]}px;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.gray44};
  text-align: ${({ align }) => align || "left"};
  text-transform: uppercase;
`;

export const Text = styled.div`
  font-size: 1.2rem;
  color: ${({ color, theme }) => color || theme.colors.gray40};
  text-align: ${({ align }) => align || "left"};
`;
