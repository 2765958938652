import React from "react";

import { getIn, useFormikContext } from "formik";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Button from "components/Button";
import { Label, withNamespace } from "components/Form/FormikControls";

import { AlternativeType } from "constants/businesses";
import { colors } from "constants/theme";

import { getDeployments, getSaleyards } from "selectors";

export const getAlternateColor = (
  alternatives,
  values,
  altField,
  subField,
  overrideFieldValue,
) => {
  const alternativeValues = alternatives
    .map(alt => (subField ? alt[altField]?.[subField] : alt[altField])) // We want to allow false, but filter out other falsey values
    .filter(alt => alt !== null && alt !== undefined && alt !== "");
  const fieldValue =
    overrideFieldValue ||
    getIn(values, subField ? withNamespace(altField, subField) : altField);
  if (alternativeValues.every(alt => alt === fieldValue)) {
    return colors.primary;
  }

  return colors.warningOrange;
};

const ValueCell = styled.td`
  padding-left: 10px;
`;

const ButtonCell = styled.td`
  padding-left: 10px;
  padding-top: 2px;
`;

const LargeTextTable = styled.table`
  font-size: ${({ theme }) => theme.fontSizes.beta}px;
`;

function defaultOnClickApply(
  formikProps,
  overrideFormikField,
  formikField,
  value,
  _ignoredAlt,
) {
  formikProps.setFieldValue(overrideFormikField || formikField, value);
}

export const AlternateFieldTextComponent = (
  alternatives,
  altFieldName,
  subField = null,
  overrideFormikField,
  disabledButton,
  onClickApply = defaultOnClickApply,
) => {
  const formikProps = useFormikContext();
  const deployments = useSelector(getDeployments);
  const saleyards = useSelector(getSaleyards);

  return (
    <LargeTextTable>
      <thead>
        <tr>
          <td>Source</td>
          <ValueCell>Value</ValueCell>
          <ButtonCell> </ButtonCell>
        </tr>
      </thead>
      <tbody>
        {alternatives.map((alt, i) => {
          const value = subField
            ? alt[altFieldName]?.[subField]
            : alt[altFieldName];
          const source =
            alt.source.type === AlternativeType.Deployment
              ? deployments[alt.source.id]?.name
              : saleyards[alt.source.id]?.name;
          const formikField = subField
            ? withNamespace(altFieldName, subField)
            : altFieldName;
          function onClick() {
            typeof onClickApply === "function" &&
              onClickApply(
                formikProps,
                overrideFormikField,
                formikField,
                value,
                alt,
              );
          }

          return [
            <tr key={i}>
              <td>{source}</td>
              <ValueCell>
                {value !== null && value !== undefined
                  ? String(value)
                  : "Not Set"}
              </ValueCell>
              <ButtonCell>
                {onClickApply && (
                  <Button onClick={onClick} disabled={disabledButton}>
                    Apply
                  </Button>
                )}
              </ButtonCell>
            </tr>,
          ];
        })}
      </tbody>
    </LargeTextTable>
  );
};

export const AlternateLabel = ({
  props,
  altFieldName,
  subField = null,
  overrideFormikField,
  disabledButton,
  values,
}) => (
  <Label
    altTooltip={AlternateFieldTextComponent(
      props.original.alternatives || [],
      altFieldName,
      subField,
      overrideFormikField,
      disabledButton,
    )}
    altColor={getAlternateColor(
      props.original.alternatives || [],
      values,
      altFieldName,
      subField,
      props.value,
    )}
  >
    {props.value}
  </Label>
);

export const getNamespacedCompareFieldNames = (namespace, names) =>
  names.map((name, index) => `${withNamespace(namespace, name)}[${index}]`);

export const getFieldsWithAlternativeValues = (fieldNames, obj) => {
  if (obj.alternatives) {
    return fieldNames.reduce((acc, altField) => {
      const value = obj[altField];
      if (obj.alternatives.some(altObj => !isEqual(altObj[altField], value))) {
        acc.push(altField);
      }
      return acc;
    }, []);
  } else {
    return [];
  }
};
