import React from "react";

import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  TraversalOrder,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import AppBar from "@material-ui/core/AppBar";
import { isEqual } from "lodash";
import sortBy from "lodash/sortBy";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { bulkUpdateSaleLots } from "actions/bulkUpdate";

import { LotCard } from "components/ClearingSaleCards/LotCard";
import { Button } from "components/Form";
import { Row, Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";
import { SortableItem } from "components/SortableItem";

import { ApiModel } from "constants/loading";

import { getSaleLots } from "selectors";

function EmptyLots() {
  return (
    <MessageBox>No Vendors yet. Click Add Vendor to get started.</MessageBox>
  );
}

const FloatingActions = styled(AppBar)`
  && {
    &.MuiAppBar-root {
      z-index: auto;
    }
  }
`;

const StandaloneLotCard = React.memo(props => {
  const { id, showAll } = props;
  return (
    <Column margin={2}>
      <LotCard saleLotId={id} showVendor showAll={showAll} />
    </Column>
  );
});

export const Catalogue = () => {
  const [reordered, setReordered] = React.useState(null);
  const [showAll, setShowAll] = React.useState(false);
  const saleLots = useSelector(getSaleLots);
  const [activeId, setActiveId] = React.useState(null);

  const saleLotIds = React.useMemo(
    () => sortBy(saleLots, "lot_number").map(saleLot => saleLot.id),
    [saleLots],
  );

  const [sortableLotIds, setSortableLotIds] = React.useState(
    reordered || saleLotIds,
  );

  React.useEffect(() => {
    // If the reordering is the same, clear it so the reorder actions are hidden.
    if (isEqual(reordered, saleLotIds)) {
      setReordered(null);
    }
    if (saleLotIds.length !== sortableLotIds.length) {
      setSortableLotIds(saleLotIds);
    }
  }, [saleLotIds, reordered, sortableLotIds]);

  const dispatch = useDispatch();
  const clearOrder = () => setReordered(null);

  const saveOrder = () => {
    const updates = reordered.map((saleLotId, index) => ({
      id: saleLotId,
      lot_number: index + 1,
    }));

    dispatch(bulkUpdateSaleLots(updates));
  };

  const toggleAll = () => setShowAll(!showAll);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 100 } }),
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = sortableLotIds.findIndex(id => id === active.id);
      const newIndex = sortableLotIds.findIndex(id => id === over.id);
      const newOrder = arrayMove(reordered || saleLotIds, oldIndex, newIndex);
      setReordered(newOrder);
      setActiveId(null);
      setSortableLotIds(newOrder);
    }
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  return (
    <WaitForSync
      requiredData={[
        ApiModel.BUSINESSES,
        ApiModel.CONSIGNMENTS,
        ApiModel.SALE_LOTS,
        ApiModel.DEPLOYMENTS,
        ApiModel.SALES,
        ApiModel.FILES,
      ]}
    >
      <FloatingActions position="sticky">
        <Row>
          {reordered && (
            <>
              <Button onClick={saveOrder}>Save Order</Button>
              <Button onClick={clearOrder}>Reset</Button>
            </>
          )}
          <Button onClick={toggleAll}>
            {`Show ${showAll ? "Less" : "All"}`}
          </Button>
        </Row>
      </FloatingActions>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        onDragStart={handleDragStart}
        autoScroll={{ order: TraversalOrder.ReversedTreeOrder }}
      >
        {sortableLotIds.length > 0 ? (
          <SortableContext
            items={sortableLotIds}
            strategy={verticalListSortingStrategy}
          >
            {sortableLotIds.map(id => (
              <SortableItem data-tour="sortableLot" key={id} id={id}>
                <StandaloneLotCard id={id} showAll={showAll} />
              </SortableItem>
            ))}
          </SortableContext>
        ) : (
          <EmptyLots />
        )}
        <DragOverlay>
          {activeId ? <StandaloneLotCard id={activeId} /> : null}
        </DragOverlay>
      </DndContext>
    </WaitForSync>
  );
};
