import styled from "styled-components/macro";

import Card from "components/Card";

export const BuyerCard = styled(Card)`
  margin: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  flex-grow: 1;
  ${({ isTopBuyer, theme }) => `
  min-width: ${theme.space[7]}px;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.gamma}px;
  padding: ${theme.space[2]}px;
  transition: ${theme.transitions[0]};
  background: ${isTopBuyer ? theme.colors.white : theme.colors.warning};
  max-width: 100%;
  box-shadow: ${theme.shadows[1]};
    &:active {
    background: ${theme.colors.primary};
  }
  `}
`;
