import React from "react";

import Grid from "@material-ui/core/Grid";

import { SubtleBadge } from "components/Badge";
import { FormCollapse } from "components/Form";
import { Input, withNamespace } from "components/Form/FormikControls";
import { HelpText } from "components/Form/FormikControls/HelpText";
import { Row } from "components/Layout";

import { DeploymentSettingsModalSection } from ".";

export function ContactSection(props) {
  const { sectionHelper, ns } = props;
  const header = (
    <Row>
      <SubtleBadge>Contact</SubtleBadge>
    </Row>
  );

  const modalSection = DeploymentSettingsModalSection.CONTACT;

  const [section, setSection] = sectionHelper;

  const isOpen = section === modalSection;
  const onToggle = () => setSection(modalSection);

  return (
    <FormCollapse isOpen={isOpen} onToggle={onToggle} header={header}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HelpText>
            These values are used for Invoice and RCTI reports.
          </HelpText>
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Contact Person"
            name={withNamespace(ns, "contactPerson")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input label="Company Name" name={withNamespace(ns, "companyName")} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input label="Fax Number" name={withNamespace(ns, "faxNumber")} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input label="Phone Number" name={withNamespace(ns, "phoneNumber")} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            label="Email Address"
            name={withNamespace(ns, "emailAddress")}
          />
        </Grid>
      </Grid>
    </FormCollapse>
  );
}
