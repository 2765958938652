import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

export const bankingDetailsSerializeMap = {
  accountName: "account_name",
  accountNumber: "account_number",
  bsb: "bsb",
  chequePayableTo: "cheque_payable_to",
  payid: "payid",
};

const DEFAULT_BANKING_DETAILS = {
  accountName: "",
  accountNumber: "",
  bsb: "",
  chequePayableTo: "",
  payid: "",
};

export const deserializeBankingDetails = buildDeserializer(
  bankingDetailsSerializeMap,
  "bankingDetails",
  null,
  DEFAULT_BANKING_DETAILS,
);

export const serializeBankingDetails = buildSerializer(
  bankingDetailsSerializeMap,
  "bankingDetails",
  null,
);
