import React from "react";

import Slide from "@material-ui/core/Slide";

const PageShell = Page => props => {
  let direction = props.location?.state?.direction;
  // TODO: have a proper animation sliding left/right.  For now disable the animation
  // by setting direction to null.
  direction = null;
  if (direction === "left") {
    direction = "right";
  } else if (direction === "right") {
    direction = "left";
  }
  if (direction) {
    return (
      <Slide direction={direction} in mountOnEnter>
        <div>
          <Page {...props} />
        </div>
      </Slide>
    );
  } else {
    return (
      <div className="page">
        <Page {...props} />
      </div>
    );
  }
};
export default PageShell;
