import React from "react";

import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { BusinessAction } from "actions";

import { DefaultVendorSplitAction } from "actions/vendorSplits";

import BusinessForm from "components/BusinessForm";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

import {
  getPropertyEnrichedBusinessByBusinessId,
  getSelectableBranches,
} from "selectors";

const EditBusinessComponent = ({
  businessId,
  defaultTab,
  returnTo,
  defaultAddressSearch,
}) => {
  const dispatch = useDispatch();

  const editingBusiness = useSelector(
    getPropertyEnrichedBusinessByBusinessId(businessId),
  );
  const business = isEmpty(editingBusiness) ? undefined : editingBusiness;

  const allBranches = useSelector(getSelectableBranches);

  const branches = business
    ? allBranches.filter(branch => branch.agency_id === business.agencyId)
    : allBranches;

  const closeSelf = React.useCallback(() => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }, [returnTo]);

  const handleSubmit = values => {
    if (values.defaultVendorSplit?.splits?.length === 0) {
      if (values.defaultVendorSplit.id) {
        dispatch(DefaultVendorSplitAction.delete(values.defaultVendorSplit.id));
      }
      delete values.defaultVendorSplit;
    }

    // if the primary contact email is not available in the
    // email recipients or business users anymore - remove it.
    if (values.primaryContactEmail) {
      const primaryContactIsEmailRecipient = values.emailRecipients.some(
        er => er.email === values.primaryContactEmail,
      );

      const primaryContactIsBusinessUser = values.businessUsers.some(
        bu => bu.email === values.primaryContactEmail,
      );

      if (!primaryContactIsEmailRecipient && !primaryContactIsBusinessUser) {
        values.primaryContactEmail = null;
      }
    }

    if (business) {
      if (values.generate_bpay_crn) {
        delete values.bpayCustomerReferenceNumber;
      }

      dispatch(
        BusinessAction.update(values, {
          changeReason: "Updated via edit business.",
        }),
      );
    } else {
      dispatch(
        BusinessAction.create({
          id: uuidv4(),
          ...values,
        }),
      );
    }
    closeSelf();
  };

  return (
    <BusinessForm
      business={business}
      branches={branches}
      defaultTab={defaultTab}
      handleSubmit={handleSubmit}
      closeSelf={closeSelf}
      defaultAddressSearch={defaultAddressSearch}
    />
  );
};

EditBusinessComponent.propTypes = {
  businessId: PropTypes.string,
};

export const EditBusiness = React.memo(EditBusinessComponent);
