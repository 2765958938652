import React, { useCallback, useReducer } from "react";

import { faFilters } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Popper } from "@material-ui/core";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Chip } from "components/Chip";
import { BusinessField } from "components/Form/Fields/BusinessField";
import { Column } from "components/Layout";

import {
  BUSINESS_ROLE_FILTERS,
  BUYER,
  DEPLOYMENT,
  IN_SALE,
  LIVESTOCK_AGENT,
  TRANSPORTER,
  VENDOR,
} from "constants/businesses";
import { colors } from "constants/theme";

import { useToggle } from "hooks";

const PopDownOnly = ({ style, ...props }) => (
  // Prevents the dropdown becoming a dropup ever.  Stops the possibility of flipping from dropup to dropdown.
  // https://github.com/mui/material-ui/issues/21661
  <Popper
    {...props}
    style={{ ...style, height: 0 }} // width is passed in 'style' prop
  />
);

const FilterChip = ({ state, dispatch, filterKey, filterLabel }) => (
  <div
    className="cursor-pointer"
    onClick={e => {
      dispatch(filterKey);
      e.stopPropagation();
    }}
  >
    <Chip
      color={state[filterKey] ? "white" : "black"}
      backgroundColor={state[filterKey] ? "warning" : null}
    >
      {filterLabel}
    </Chip>
  </div>
);

export const BusinessFieldWithFilter = ({
  defaultBusinessFilter = [],
  ...props
}) => {
  const businessTypeFilterReducer = (state, action) => {
    if (state[action] === true) {
      return {
        ...state,
        [action]: false,
      };
    } else {
      return {
        ...state,
        [action]: true,
      };
    }
  };

  const [businessTypeFilterState, businessTypeFilterDispatch] = useReducer(
    businessTypeFilterReducer,
    defaultBusinessFilter.reduce((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, {}),
  );

  const [showAllFilters, toggleShowAllFilters] = useToggle(false);

  // Apply any extra active business type filters.
  const keysToFilter = Object.entries(businessTypeFilterState).reduce(
    (acc, [key, value]) => {
      if (value === true) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  // Adjust the filtering function to push a specific short code result to the top.
  // This is defined by the type of sale, and who is searching, so memoize the function call
  const extraFilter = useCallback(
    option => {
      // If extra filters are selected, apply them too.

      if (keysToFilter.length > 0) {
        if (!keysToFilter.every(k => option[BUSINESS_ROLE_FILTERS[k]])) {
          return false;
        }
      }

      return true;
    },
    [keysToFilter],
  );

  const ListboxComponent = React.forwardRef((props, ref) => {
    // Normal Search Results list, with the filter added at the top.
    const { children, ...other } = props;

    return (
      <ul {...other} ref={ref}>
        <Column fullHeight full padding={2}>
          <Grid container>
            <FilterChip
              state={businessTypeFilterState}
              dispatch={businessTypeFilterDispatch}
              filterKey={IN_SALE}
              filterLabel="In Sale"
            />
            <FilterChip
              state={businessTypeFilterState}
              dispatch={businessTypeFilterDispatch}
              filterKey={BUYER}
              filterLabel="Buyer"
            />
            <FilterChip
              state={businessTypeFilterState}
              dispatch={businessTypeFilterDispatch}
              filterKey={VENDOR}
              filterLabel="Vendor"
            />
            {(showAllFilters || businessTypeFilterState.TRANSPORTER) && (
              <FilterChip
                state={businessTypeFilterState}
                dispatch={businessTypeFilterDispatch}
                filterKey={TRANSPORTER}
                filterLabel="Transporter"
                hide={!showAllFilters}
              />
            )}
            {(showAllFilters || businessTypeFilterState.DEPLOYMENT) && (
              <FilterChip
                state={businessTypeFilterState}
                dispatch={businessTypeFilterDispatch}
                filterKey={DEPLOYMENT}
                filterLabel="Agencies"
              />
            )}
            {(showAllFilters || businessTypeFilterState.LIVESTOCK_AGENT) && (
              <FilterChip
                state={businessTypeFilterState}
                dispatch={businessTypeFilterDispatch}
                filterKey={LIVESTOCK_AGENT}
                filterLabel="Livestock Agents"
              />
            )}

            <div onClick={toggleShowAllFilters} className="cursor-pointer">
              <Chip
                backgroundColor="primary"
                color="white"
                onClick={toggleShowAllFilters}
              >
                {showAllFilters ? "Less" : "More"}
                <FaIcon icon={faFilters} />
              </Chip>
            </div>
          </Grid>
        </Column>
        <li>{children}</li>
      </ul>
    );
  });

  const renderOption = option => {
    if (option[IN_SALE]) {
      // Highlight if they are in the sale already
      return (
        <Box justifyContent="space-between" display="flex" width="100%">
          {option.label}
          <FontAwesomeIcon icon={faCircle} color={colors.activeColour} />
        </Box>
      );
    } else {
      return option.label;
    }
  };

  return (
    <BusinessField
      {...props}
      renderOption={renderOption}
      ListboxComponent={ListboxComponent}
      extraFilter={extraFilter}
      PopperComponent={PopDownOnly}
    />
  );
};
