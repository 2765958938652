import React from "react";

import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";
import styled from "styled-components/macro";

import { SubtleBadge } from "components/Badge";
import { Warning } from "components/ErrorMessage";
import { FormCollapse } from "components/Form";
import {
  CheckBox,
  Input,
  Label,
  TextArea,
  withNamespaces,
} from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";

import { useSectionToggle } from "hooks";

import { DeploymentSettingsModalSection, FormColumn } from "./index";

const EmailSubModalSection = {
  GENERAL: "generalEmailSettings",
  TAX_INVOICE: "taxInvoiceEmailSettings",
  RCTI: "rctiEmailSettings",
};

const BodyPreview = styled.div`
  white-space: pre-wrap;
`;

const tooltipContent =
  "General email content will be used by default if no Salutation or Body Introduction is entered for a section (Tax Invoice, RCTI). \n Default content will be used when no Salutation or Body Introduction is entered in General.";

const Header = title => (
  <Row>
    <SubtleBadge>{title}</SubtleBadge>
  </Row>
);

const Preview = ({ section, ns }) => {
  const { values } = useFormikContext();
  const value = values[ns] || {};
  const body = value[section]?.body;
  const salutation = value[section]?.salutation;
  const appendNameToSalutation = value[section]?.appendNameToSalutation;
  const showPreview = body || salutation;
  const showEmptyPreview = !body && !salutation;

  const isGeneralSection = section === EmailSubModalSection.GENERAL;

  // If we are in the general section we want to fall back to the default text
  if (isGeneralSection && showEmptyPreview) {
    return (
      <>
        <Warning>
          Using default content as no Salutation or Body Introduction entered
        </Warning>
        <Paper isSquare>
          <Row>{"Hi <<Name>>,"}</Row>
          <br />
          <Column>
            <BodyPreview>{body}</BodyPreview>
            <br />
            <div>
              {"Please find attached your <<Report>> for <<Sale>> on <<Date>>"}
            </div>
          </Column>
        </Paper>
      </>
    );
  }

  if (showPreview) {
    return (
      <Paper isSquare>
        <Row>
          {salutation || ""}
          {salutation && appendNameToSalutation ? " <<Name>>, " : ""}
        </Row>
        <br />
        <Column>
          <BodyPreview>{body}</BodyPreview>
          <br />
          <div>{"<<Email Body Content>>"}</div>
        </Column>
      </Paper>
    );
  }

  if (showEmptyPreview) {
    return (
      <div>
        General content will be used as the Salulation and Body Introduction
        text is empty
      </div>
    );
  }
};

const EmailForm = ({ ns, section }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Column>
          <Grid item xs={12} md={12}>
            <Input
              label="Salutation"
              name={withNamespaces([ns, section], "salutation")}
              tooltip="The greeting for your email"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CheckBox
              label="Append Contact First Name to Salutation (eg. “ Jane,”)?"
              name={withNamespaces([ns, section], "appendNameToSalutation")}
              tooltip="Append the name to the email greeting?"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextArea
              label="Body Introduction"
              name={withNamespaces([ns, section], "body")}
              tooltip="The body for your email"
              rows={10}
            />
          </Grid>
        </Column>
      </Grid>
      <Grid item xs={12} md={6}>
        <Label tooltip={tooltipContent}>Text Preview</Label>
        <Preview section={section} ns={ns} />
      </Grid>
    </Grid>
  );
};

export function EmailSection(props) {
  const { sectionHelper, ns } = props;

  const modalSection = DeploymentSettingsModalSection.EMAIL;
  const [section, setSection] = sectionHelper;

  const isOpen = section === modalSection;
  const onToggle = () => setSection(modalSection);

  const [subSection, setSubSection] = useSectionToggle(
    EmailSubModalSection.GENERAL,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={Header("Outgoing Email Customisation")}
    >
      <Grid container spacing={2}>
        <FormColumn>
          <FormCollapse
            isOpen={subSection === EmailSubModalSection.GENERAL}
            onToggle={() => setSubSection(EmailSubModalSection.GENERAL)}
            header={Header("General")}
          >
            <EmailForm ns={ns} section={EmailSubModalSection.GENERAL} />
          </FormCollapse>
          <FormCollapse
            isOpen={subSection === EmailSubModalSection.TAX_INVOICE}
            onToggle={() => setSubSection(EmailSubModalSection.TAX_INVOICE)}
            header={Header("Tax Invoice")}
          >
            <EmailForm ns={ns} section={EmailSubModalSection.TAX_INVOICE} />
          </FormCollapse>
          <FormCollapse
            isOpen={subSection === EmailSubModalSection.RCTI}
            onToggle={() => setSubSection(EmailSubModalSection.RCTI)}
            header={Header("RCTI")}
          >
            <EmailForm ns={ns} section={EmailSubModalSection.RCTI} />
          </FormCollapse>
        </FormColumn>
      </Grid>
    </FormCollapse>
  );
}
