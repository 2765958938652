import React, { memo } from "react";

import { ConfirmDialog } from "components/ConfirmDialog";

export function ClearEidsModalComponent(reactProps) {
  const { closeSelf, modalProps } = reactProps;
  const { draftName, message, onDelete: onDeleteCb } = modalProps;

  function onDelete() {
    onDeleteCb();
    closeSelf();
  }

  return (
    <ConfirmDialog
      isOpen
      message={
        message || `Are you sure you want to clear EIDs from ${draftName}?`
      }
      buttonMessage="Clear EIDs"
      onDelete={onDelete}
      onCancel={closeSelf}
      title="Clear EIDs?"
    />
  );
}
export default memo(ClearEidsModalComponent);
