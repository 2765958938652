import React from "react";

import {
  faCheck,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

import { isLpaAccredited } from "lib/properties";

export function LPAStatusRenderer(props) {
  if (!props.data) {
    return null;
  }

  const { value } = props;
  if (value) {
    if (isLpaAccredited(value)) {
      return <FaIcon icon={faCheck} color="success" />;
    } else {
      return <FaIcon icon={faExclamationCircle} color="warning" />;
    }
  }
  return <FaIcon icon={faExclamationCircle} color="warning" />;
}
