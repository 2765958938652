import { createSelector } from "reselect";

import { EMPTY_ARRAY } from "lib";

import {
  createLookupCombiner,
  createLookupSelectors,
  getScans,
  getWeighLotScans,
  reduceXByZId,
  selectEidByWeighLotScanIdLookup,
  selectWeighLotScanIdsByEidLookup,
} from "selectors";

export const [selectScanByWeighLotScanIdLookup, getScanByWeighLotScanId] =
  createLookupSelectors(
    [getScans, selectEidByWeighLotScanIdLookup],
    reduceXByZId,
  );

export const getWeighLotScanById = weighLotScanId => state =>
  getWeighLotScans(state)[weighLotScanId] || null;

export const getWeighLotScanIdsByEid = eid => state =>
  selectWeighLotScanIdsByEidLookup(state)[eid] || EMPTY_ARRAY;

export const selectReceivalLotIdByWeighLotScanId = createSelector(
  [getWeighLotScans, selectScanByWeighLotScanIdLookup],
  createLookupCombiner((weighLotScan, scanByWeighLotScanIdLookup) => {
    const scan = scanByWeighLotScanIdLookup[weighLotScan.id] || {};
    return scan.receival_lot_id || null;
  }),
);

export const selectPenScanLotIdByWeighLotScanId = createSelector(
  [getWeighLotScans, selectScanByWeighLotScanIdLookup],
  createLookupCombiner((weighLotScan, scanByWeighLotScanIdLookup) => {
    const scan = scanByWeighLotScanIdLookup[weighLotScan.id] || {};
    return scan.pen_scan_lot_id || null;
  }),
);

export const selectSaleLotIdByWeighLotScanId = createSelector(
  [getWeighLotScans, selectScanByWeighLotScanIdLookup],
  createLookupCombiner((weighLotScan, scanByWeighLotScanIdLookup) => {
    const scan = scanByWeighLotScanIdLookup[weighLotScan.id] || {};
    return scan.sale_lot_id || null;
  }),
);
