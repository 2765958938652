import React from "react";

import PropTypes from "prop-types";
import { withTheme } from "styled-components";

const NotDraftedLogo = ({ color, theme }) => {
  const logoColor = color || theme.colors.primary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3318.92 3318.92"
      width="33px"
      height="33px"
    >
      <defs />
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2519418886912">
          <path
            fill={logoColor}
            d="M1088.38 1285.18l1142.74 0 10.3 765.42 -1156.47 0 3.43 -765.42zm-132.53 858.15c47.37,18.86 1356.68,18.85 1404.85,-0.33 48.19,-44 -21.44,-55.79 -24.29,-157.6l2.59 -674.68c6.58,-31.45 82.92,-111.21 -6.36,-130.84l-1111.73 -0.73c-156.11,0 -278.56,-31.41 -277.45,48.56 0.91,65.66 20.59,15.27 34.24,82.85l2.28 661.54c-0.2,65.19 4.86,41.66 -17.96,85.99 -15.73,30.56 -36.92,25.02 -6.17,85.24z"
          />
          <path
            fill="none"
            stroke="#427DB3"
            strokeWidth="105.83"
            strokeMiterlimit="22.9256"
            d="M1384.39 159.82c-1989.07,374.51 -1457.83,3378.41 568.4,3012.62 1955.31,-352.98 1441.4,-3391 -568.4,-3012.62z"
          />
          <line
            fill="none"
            stroke="#427DB3"
            strokeWidth="105.83"
            strokeMiterlimit="22.9256"
            x1="487.35"
            y1="2668.76"
            x2="2835.3"
            y2="657.62"
          />
        </g>
      </g>
    </svg>
  );
};

NotDraftedLogo.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

export default withTheme(NotDraftedLogo);
