import { takeEvery } from "redux-saga/effects";

import { DEPLOYMENT_SALE } from "constants/actionTypes";

import toast from "lib/toast";

function onPatchDeploymentSaleCommit() {
  toast.success(`Deployment Sale updated successfully`);
}

function rollbackPatchDeployment() {
  toast.error(`Error Updating Deployment Sale`);
}

function onDeleteDeploymentSaleCommit() {
  toast.success(`Deployment Sale deleted successfully`);
}

function rollbackDeleteDeployment(action) {
  toast.error(`Error removing deployment sale - ${action.payload.response}`);
}

export default function* rootSaga() {
  yield takeEvery(DEPLOYMENT_SALE.UPDATE.SUCCESS, onPatchDeploymentSaleCommit);
  yield takeEvery(DEPLOYMENT_SALE.UPDATE.FAILURE, rollbackPatchDeployment);

  yield takeEvery(DEPLOYMENT_SALE.DELETE.SUCCESS, onDeleteDeploymentSaleCommit);
  yield takeEvery(DEPLOYMENT_SALE.DELETE.FAILURE, rollbackDeleteDeployment);
}
