import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { LedgerAccountAction } from "actions/ledgerAccounts";

import { SimpleGrid } from "components/AgGrid/SimpleGrid";
import { ModalTableWrapper } from "components/AgGrid/TableWrapper";
import { Button } from "components/Form";
import { LedgerAccountValidationSchema } from "components/LedgerAccount/validationSchema";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import {
  LedgerAccountColId,
  LedgerAccountColumnDef,
} from "constants/columnDefinitions/ledgerAccount";
import { LedgerAccountStatuses } from "constants/ledgerAccounts";

import { getActiveLivestockAgentDeployment } from "selectors";

import { selectLedgerAccountsAgGridData } from "selectors/aggrid/ledgerAccounts";

const columnDefs = Object.values(LedgerAccountColumnDef).filter(
  ({ colId }) => colId !== LedgerAccountColId.ACTIONS,
);

export const AutoCreateInAgrinousConfirmation = ({
  open,
  toggleShowConfirm,
}) => {
  const dispatch = useDispatch();

  const rowData = useSelector(selectLedgerAccountsAgGridData) || [];
  const autoCreateAbleLedgerEntries = rowData
    .map(ledgerAccount => {
      const code =
        ledgerAccount?.masterLedgerAccountCodes?.[0] || ledgerAccount.code;
      return {
        ...ledgerAccount,
        code,
      };
    })
    .filter(row => {
      if (!row.id) {
        try {
          LedgerAccountValidationSchema.validateSync(row);
          return true;
        } catch (e) {
          //
        }
      }
      return false;
    });
  const deployment = useSelector(getActiveLivestockAgentDeployment);

  const onSubmit = () => {
    autoCreateAbleLedgerEntries.forEach(ledgerAccount => {
      const payload = {
        ...ledgerAccount,
        deploymentId: deployment.id,
        saleyardId: null,
        status: LedgerAccountStatuses.Active,
        id: undefined,
      };
      dispatch(LedgerAccountAction.create(payload));
    });
    toggleShowConfirm();
  };

  return (
    <Dialog open={open} onClose={toggleShowConfirm} maxWidth="lg" fullWidth>
      <DialogTitle onClose={toggleShowConfirm}>
        Are you sure you wish to create the following Ledger Accounts?
      </DialogTitle>
      <DialogContent>
        <ModalTableWrapper minHeight={500}>
          <SimpleGrid
            rowData={autoCreateAbleLedgerEntries}
            columnDefs={columnDefs}
          />
        </ModalTableWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleShowConfirm}>Cancel</Button>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
