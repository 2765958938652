import React, { memo } from "react";

import { useSelector } from "react-redux";

import CommentsModalAdapter from "components/Comments/Modal/adapter";
import HashRoute from "components/HashRoute";
import { Column } from "components/Layout";
import { FocusedLayout } from "components/Layout/FocusedLayout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";

import EditSaleLotModal from "containers/SaleLot/Adapter";

import { getCurrentSale } from "selectors";

import SingleWeighViewSidebarContent from "./SingleWeighControlScreen/SingleWeighViewSidebarContent";
import SingleWeighRoutes from "./SingleWeighRoutes";

function SingleWeighViewComponent() {
  const currentSale = useSelector(getCurrentSale);
  return (
    <FocusedLayout SidebarContent={SingleWeighViewSidebarContent}>
      <Column
        fullHeight
        fullWidth
        overflow="hidden"
        background="surfaceBackground"
      >
        <WaitForSync
          subjectName="Businesses, Consignments, Sale Lots, Autodraft Pens"
          requiredData={[
            ApiModel.AUCTION_PENS,
            ApiModel.BUSINESSES,
            ApiModel.CONSIGNMENTS,
            ApiModel.PEN_ARCHETYPES,
            ApiModel.SALE_LOTS,
            ApiModel.SINGLE_WEIGH,
          ]}
        >
          <SingleWeighRoutes />
        </WaitForSync>
      </Column>
      <HashRoute
        component={EditSaleLotModal}
        hash={ModalTypes.EditSaleLot}
        componentProps={{
          saleyardId: currentSale.saleyard_id,
          saleId: currentSale.livestocksale_id,
        }}
      />
      <HashRoute component={CommentsModalAdapter} hash={ModalTypes.Comments} />
    </FocusedLayout>
  );
}

export default memo(SingleWeighViewComponent);
