import queryString from "query-string";

import {
  offlineActionCreator,
  offlineSubActionCreator,
  passthroughSerializer,
  urlCheckAndAddIdAndAction,
} from "actions/lib";

import {
  SALE,
  DEPLOYMENT_SALE,
  SET_CURRENT_LIVESTOCKSALE,
} from "constants/actionTypes";

import { getLivestockSaleId } from "lib/navigation";
import { serializeSaleCreate } from "lib/serializers/sale";

export const setCurrentLivestockSaleId = (
  livestockSaleId,
  shouldNavigate,
  requestLivestockSaleData = true,
) => ({
  type: SET_CURRENT_LIVESTOCKSALE.ACTION,
  livestockSaleId,
  shouldNavigate,
  requestLivestockSaleData,
});

const saleActionUrlCreator = ({
  changesSince,
  id,
  action,
  fetchAll = false,
} = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/sales/", id, action),
    query: {
      changesSince,
      all: fetchAll ? true : undefined,
    },
  });

export const SaleAction = offlineActionCreator(
  SALE,
  serializeSaleCreate, // only used for create/update
  saleActionUrlCreator,
  false,
);

SaleAction.fetchConsignableSales = offlineSubActionCreator(
  SALE.FETCH_CONSIGNABLE,
  () => `/v2/sales/consignable_sales/`,
  "GET",
);

SaleAction.checkAndDeleteCurrent = () => ({
  type: SALE.CHECK_AND_DELETE_CURRENT.REQUEST,
});

SaleAction.updateWithDeploymentSales = ({
  salePayload,
  livestockSaleId,
  deploymentSales,
}) => {
  return {
    type: SALE.UPDATE_WITH_DEPLOYMENT_SALES.REQUEST,
    livestockSaleId,
    salePayload,
    deploymentSales,
  };
};

const deploymentSaleUrlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/sales/", id, action);

export const DeploymentSaleAction = offlineActionCreator(
  DEPLOYMENT_SALE,
  passthroughSerializer,
  deploymentSaleUrlCreator,
  true,
);

DeploymentSaleAction.bulkUpdateLotNoToPenNo = deploymentSaleId => {
  const payload = {
    deployment_sale_id: deploymentSaleId,
  };
  const options = {
    id: getLivestockSaleId(),
    action: "bulk_update_lots_to_pens",
  };
  return {
    type: DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.REQUEST,
    meta: {
      offline: {
        effect: {
          url: deploymentSaleUrlCreator(options),
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.SUCCESS,
        },
        rollback: {
          type: DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO.FAILURE,
        },
      },
    },
  };
};
