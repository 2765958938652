import React, { useEffect } from "react";

import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

import { SidebarBranding } from "./SidebarBranding";

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(9),
  },
  drawerOpen: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: theme.spacing(30),
    height: "100vh",
  },
  drawerClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9),
    height: "100vh",
  },
}));

const DesktopSideBar = ({
  ContentElement,
  isOpen,
  setIsOpen,
  isBrandingVisible = true,
  onAfterCloseSidebar,
}) => {
  const classes = useStyles();

  const [timeoutId, setTimeoutId] = React.useState(null);

  const handleDrawerOpen = () => {
    if (!timeoutId) {
      setTimeoutId(setTimeout(() => setIsOpen(!isOpen), 250));
    }
  };

  const handleDrawerClose = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    setIsOpen(false);
    typeof onAfterCloseSidebar === "function" && onAfterCloseSidebar();
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <Drawer
      className={classes.root}
      variant="permanent"
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
      open={isOpen}
    >
      {isBrandingVisible && (
        <SidebarBranding closeSidebar={handleDrawerClose} isOpen={isOpen} />
      )}
      {ContentElement && (
        <ContentElement closeSidebar={handleDrawerClose} isOpen={isOpen} />
      )}
    </Drawer>
  );
};

DesktopSideBar.propTypes = {
  ContentElement: PropTypes.elementType,
  isBrandingVisible: PropTypes.bool,
  isOpen: PropTypes.bool,
  onAfterCloseSidebar: PropTypes.func,
  setIsOpen: PropTypes.func,
};

export default React.memo(DesktopSideBar);
