import React from "react";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faLock, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { ConsignmentChip, MarkChips } from "components/Chip";
import { UnresolvedCommentIcon } from "components/Comments/Icon";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";
import { LotActionButton } from "components/ScanningPenCards/Card/ActionButton";
import { StatusWrapper } from "components/ScanningPenCards/Card/elements";

import { PenTypes, ScanLotStatusColourMap } from "constants/auctionPens";
import { colors } from "constants/theme";

import { openPenScanning } from "lib/navigation";
import { scanLotConfig } from "lib/scanLot";

import {
  getBusinesses,
  getConsignmentById,
  getDeploymentNameByDeploymentId,
  getSaleRoundById,
  getUnassignedScans,
} from "selectors";

const RowWrapper = styled.div(
  ({ theme, fade }) => `
  ${fade ? "opacity: 35%;" : ""}
  width: 49%;
  margin-bottom: ${theme.space[1]}px; 
  box-shadow: ${theme.shadows[2]};
  cursor: pointer;
  @media only screen and (max-width: ${theme.breakpoints[1]}px){
    width: 100%;
  }
`,
);

const AddNewRowWrapper = styled(RowWrapper)`
  ${({ theme, solid }) => `
  height: 50px;
  border: ${
    solid
      ? `${theme.radii[2]}px solid black`
      : `${theme.radii[2]}px dashed black`
  };
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  `}
`;

const SelectedIcon = styled(FontAwesomeIcon).attrs({ icon: faCheck })`
  position: absolute;
  top: 2px;
  left: 2px;
  color: ${({ theme }) => theme.colors.success};
`;

function LotRowComponent(props) {
  const {
    scanLotId,
    selected = false,
    penType,
    showExtraDetails = false,
    overrideValues = {},
    isCreate,
    onClickAlt,
    showActions = true,
  } = props;
  const unassignedScans = useSelector(getUnassignedScans);
  const {
    getLotById,
    lotPenIdReference,
    lotMarksReference,
    getEidsByLotId,
    getLotStatusByLotIdLookup,
    getUnresolvedCommentCountByLotId,
    lotMarksValuesPattern,
  } = scanLotConfig(penType);

  const scanLot = useSelector(getLotById(scanLotId));

  const renderValues = {
    ...scanLot,
    ...overrideValues,
  };

  const status = useSelector(getLotStatusByLotIdLookup(scanLotId));

  const scans = useSelector(getEidsByLotId(scanLotId)).filter(Boolean);

  const { isLocked, deploymentId, saleRoundId } = renderValues;

  const penId = renderValues[lotPenIdReference];

  const marks = lotMarksValuesPattern(renderValues[lotMarksReference]);

  const deploymentName = useSelector(
    getDeploymentNameByDeploymentId(deploymentId),
  );
  const saleRoundName = useSelector(getSaleRoundById(saleRoundId)).name;

  const openNewCard = () => {
    openPenScanning(null, penId, scanLotId, null, null, penType);
  };

  const unresolvedCommentCount = useSelector(
    getUnresolvedCommentCountByLotId(scanLotId),
  );

  const consignment = useSelector(
    getConsignmentById(renderValues?.consignmentId),
  );

  const businessLookup = useSelector(getBusinesses);

  const handleCardClick = () => {
    if (typeof onClickAlt === "function") {
      onClickAlt(scanLotId);
    } else {
      openNewCard();
    }
  };

  return (
    <RowWrapper selected={selected}>
      <Row
        data-tour="lot"
        className="h-full w-full items-stretch"
        background="white"
      >
        <Paper
          spacing={0}
          backgroundColor={selected ? "primarySelectedOverlay" : null}
        >
          <StatusWrapper
            color={ScanLotStatusColourMap[status]}
            marginBottom={0}
          >
            <Row justifyBetween>
              {isLocked && (
                <Column justifyStart className="m-12">
                  <FontAwesomeIcon
                    size="2x"
                    icon={faLock}
                    color={colors.gray78}
                  />
                </Column>
              )}

              <Column
                alignCenter
                justifyBetween
                fullWidth
                paddingHorizontal={1}
                relative
                onClick={handleCardClick}
              >
                {selected ? <SelectedIcon /> : null}
                <div>
                  {isCreate ? unassignedScans.length || 0 : scans.length} /{" "}
                  {renderValues?.quantity} Hd
                </div>
                <Row>
                  <ConsignmentChip
                    consignmentId={renderValues?.consignmentId}
                  />
                  <MarkChips marks={marks} />
                  <UnresolvedCommentIcon
                    unresolvedCommentCount={unresolvedCommentCount}
                    size="2x"
                  />
                </Row>
                {penType === PenTypes.SELLING && (
                  <Column alignCenter>
                    <Row textAlignCenter>{deploymentName}</Row>
                    <Row textAlignCenter>{saleRoundName}</Row>
                  </Column>
                )}
              </Column>
              {showActions && (
                <Row>
                  <LotActionButton
                    scanLotId={scanLotId}
                    penType={penType}
                    isLocked={isLocked}
                  />
                </Row>
              )}
            </Row>
            {showExtraDetails && (
              <Row>
                <Column
                  alignCenter
                  justifyBetween
                  fullWidth
                  paddingHorizontal={1}
                  relative
                >
                  {consignment
                    ? `Vendor: ${businessLookup[consignment.vendor_id]?.name}`
                    : "No Consignment Selected"}
                </Column>
              </Row>
            )}
          </StatusWrapper>
        </Paper>
      </Row>
    </RowWrapper>
  );
}

export const LotRow = React.memo(LotRowComponent);

export const AddScanLotRowButton = ({ onClickNew, isLotSelected }) => {
  return (
    <AddNewRowWrapper
      onClick={onClickNew}
      fade
      solid={!isLotSelected}
      isLotSelected={isLotSelected}
    >
      <h3>
        <span>
          {isLotSelected ? (
            <>
              <FontAwesomeIcon icon={faPlus} /> Add New
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCheck} /> Adding New
            </>
          )}
        </span>
      </h3>
    </AddNewRowWrapper>
  );
};

LotRowComponent.propTypes = {
  scanLotId: PropTypes.string,
  selected: PropTypes.bool,
  onClickAlt: PropTypes.func,
  showActions: PropTypes.bool,
  showExtraDetails: PropTypes.bool,
  overrideValues: PropTypes.object,
  isCreate: PropTypes.bool,
};
