import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { MultiButton } from "components/Button";
import { LinkButton } from "components/Form";

import { ModalTypes } from "constants/navigation";

import { openModalLink } from "lib/navigation";
import { formatDateString } from "lib/timeFormats";

import { getRuleBookById } from "selectors";

import { InfoSpan } from ".";

export const RuleBookSummary = ({ ruleBookId, openEditSettings, readOnly }) => {
  const ruleBook = useSelector(getRuleBookById(ruleBookId)) || {};

  const {
    name,
    description,
    reports = [],
    sale_sub_type_ids = [],
    effective_start_date,
    effective_end_date,
    is_archived,
  } = ruleBook;

  const handleOpenRuleTester = () => {
    openModalLink(
      ModalTypes.RuleTester,
      [[], ruleBookId, false],
      window.location.hash,
    );
  };

  const ruleButtons = [
    {
      title: "Test Rule Book",
      isDisabled: false,
      onClick: handleOpenRuleTester,
      default: true,
      dataTour: "testRuleBook",
    },
  ];
  return (
    <Grid container xs={12}>
      <Grid item xs={8}>
        <Grid item xs={12}>
          <h2 className="leading-none">{name}</h2>
          <p>{description}</p>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={4}>
            Rule Book Reports: <InfoSpan>{reports.length} selected</InfoSpan>
          </Grid>
          <Grid item xs={4}>
            Sale Sub Types:{" "}
            <InfoSpan>{sale_sub_type_ids.length} selected</InfoSpan>
          </Grid>
          <Grid item xs={4}>
            Effective Start Date:{" "}
            <InfoSpan>
              {effective_start_date
                ? formatDateString(new Date(effective_start_date))
                : ""}
            </InfoSpan>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={4}>
            Effective End Date:{" "}
            <InfoSpan>
              {effective_end_date
                ? formatDateString(new Date(effective_end_date))
                : ""}
            </InfoSpan>
          </Grid>
          <Grid item xs={4}>
            Is Archived: <InfoSpan>{is_archived ? "True" : "False"}</InfoSpan>
          </Grid>
          {!readOnly && (
            <Grid item xs={4}>
              <LinkButton onClick={openEditSettings}>Edit Settings</LinkButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end">
        <Grid item>
          <MultiButton buttons={ruleButtons}>Test Rule Book</MultiButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
