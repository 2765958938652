import reduceReducers from "reduce-reducers";

import { WEIGHT_RANGE } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeWeightRanges } from "lib/serializers/weightRanges";

const fetch = apiModelOfflineFetchReducer(WEIGHT_RANGE, {
  deserializer: deserializeWeightRanges,
});

const weightRangeReducer = reduceReducers(fetch);

export default weightRangeReducer;
