import { createSelector } from "reselect";

import {
  getIsFetchingBusinesses,
  getIsFetchingConsignments,
  getIsFetchingProperties,
  getIsFetchingSaleLots,
  getIsFetchingSales,
} from "selectors";

export const getDashboardFetchingStatus = createSelector(
  [
    getIsFetchingBusinesses,
    getIsFetchingConsignments,
    getIsFetchingProperties,
    getIsFetchingSales,
    getIsFetchingSaleLots,
  ],
  (
    isFetchingBusinesses,
    isFetchingConsignments,
    isFetchingProperties,
    isFetchingSales,
    isFetchingSaleLots,
  ) => {
    const isFetchingDashboardData = !!(
      isFetchingBusinesses ||
      isFetchingConsignments ||
      isFetchingProperties ||
      isFetchingSales ||
      isFetchingSaleLots
    );

    return isFetchingDashboardData;
  },
);
