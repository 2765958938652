import React from "react";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { SingleWeighAction } from "actions";

import { getSingleWeighById } from "selectors";

import SettingsForm from "./SettingsForm";

const defaultValues = {
  noEidDelay: null,
  unsteadyWeightDelay: null,
  reweighWarningThreshold: null,
  minStableWeightGrams: null,
  stableWeightDebounce: null,
  isPaused: null,
};

const validationSchema = Yup.object().shape({
  noEidDelay: Yup.number().required("The EID delay is required. -1 for off"),
  unsteadyWeightDelay: Yup.number().required(
    "The Unsteady Weight Delay is required",
  ),
  reweighWarningThreshold: Yup.number().required(
    "The Reweigh Warning Threshold is required",
  ),
  minStableWeightGrams: Yup.number().required(
    "The Minimum Stable Weight is required",
  ),
  stableWeightDebounce: Yup.number().required(
    "The Stable Weight Debounce is required",
  ),
});

function SettingsModal({ singleWeighId, closeSelf }) {
  const dispatch = useDispatch();
  const singleWeighInitalValues = useSelector(
    getSingleWeighById(singleWeighId),
  );

  const onSubmit = values => {
    dispatch(SingleWeighAction.update({ ...values, id: singleWeighId }));
    closeSelf();
  };

  const initialValues = {
    ...defaultValues,
    ...singleWeighInitalValues,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <SettingsForm handleClose={closeSelf} />
    </Formik>
  );
}

export default SettingsModal;
