import { TriStateToggleFilterComponent } from "components/AgGrid/filterComponents/triStateToggleFilterComponent";
import { balancedTriStateFilter } from "components/AgGrid/filters/balancedFilter";
import { CompositeFilter } from "components/AgGrid/filters/compositeFilter";
import { hasExceptionsTriStateFilter } from "components/AgGrid/filters/hasExceptionsFilter";
import { UploadEditButton } from "components/ConsignmentsTable/columns/uploadEdit";
import { saleLotCommentsColumnId } from "components/SaleLotsTable/columns/commentsColumn";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import {
  PropertyColumnDefinitions,
  PropertyNamespaceColumnId,
} from "constants/columnDefinitions/property";
import { Column } from "constants/columns";

import { createNamespacedColumnDefinitions } from "lib/agGrid";
import {
  ConsignmentColumnDefinitions,
  speciesAccreditationChildColumns,
} from "lib/agGrid/columnDefinitions";

const consignmentSpeciesAccreditationChildColumns = speciesId =>
  speciesAccreditationChildColumns(speciesId, ConsignmentColumnDefinitions);

const exceptionsComponentParams = {
  filterComponent: TriStateToggleFilterComponent,
  params: {
    title: "Exceptions",
    filter: hasExceptionsTriStateFilter,
  },
};
const balancedFilterComponentParams = {
  filterComponent: TriStateToggleFilterComponent,
  params: {
    title: "Balanced",
    filter: balancedTriStateFilter,
  },
};
export const defaultConsignmentColumns = (
  isHooksSale,
  hasReceivalLotPermission,
  speciesId,
) =>
  [
    ConsignmentColumnDefinitions[Column.Consignment.VENDOR_NUM],
    ConsignmentColumnDefinitions[Column.Consignment.VENDOR_NAME],
    ConsignmentColumnDefinitions[Column.Consignment.BUSINESS_DISPLAY_NAME],
    ConsignmentColumnDefinitions[Column.Consignment.AGENCY_NAME],
    ConsignmentColumnDefinitions[Column.Consignment.AGENCY_VENDOR_NUM],
    ConsignmentColumnDefinitions[Column.Consignment.VENDOR_SHORT_CODE],
    ConsignmentColumnDefinitions[Column.Consignment.VENDOR_SHORT_CODE_SALEYARD],
    ConsignmentColumnDefinitions[Column.Consignment.AGENT_RELATIONSHIP],
    ConsignmentColumnDefinitions[Column.Consignment.TRANSIT_INSURANCE],
    ConsignmentColumnDefinitions[Column.Consignment.VENDOR_CONSIGNMENT_PIC],
    ConsignmentColumnDefinitions[Column.Consignment.CONSIGNED_FROM],
    ConsignmentColumnDefinitions[Column.Consignment.BRANCH],
    ConsignmentColumnDefinitions[Column.Consignment.RECEIVE_REF],
    !hasReceivalLotPermission &&
      ConsignmentColumnDefinitions[Column.Consignment.RECEIVE_PEN],
    hasReceivalLotPermission &&
      ConsignmentColumnDefinitions[Column.Consignment.RECEIVAL_PENS],
    hasReceivalLotPermission &&
      ConsignmentColumnDefinitions[Column.Consignment.RECEIVAL_LOTS_HEAD_COUNT],
    hasReceivalLotPermission &&
      ConsignmentColumnDefinitions[Column.Consignment.RECEIVAL_LOTS_SCAN_COUNT],
    hasReceivalLotPermission &&
      ConsignmentColumnDefinitions[Column.Consignment.RECEIVAL_LOTS],
    ConsignmentColumnDefinitions[Column.Consignment.QUANTITY],
    ConsignmentColumnDefinitions[Column.Consignment.SCANNED_COUNT],
    ConsignmentColumnDefinitions[Column.Consignment.SCANNED_PERCENTAGE],
    ConsignmentColumnDefinitions[Column.Consignment.CONSIGNMENT_SCANNED_COUNT],
    ConsignmentColumnDefinitions[Column.Consignment.DRAFT_HC],
    ConsignmentColumnDefinitions[Column.Consignment.NVD_HC],
    ConsignmentColumnDefinitions[Column.Consignment.NOMINATION],
    {
      ...ConsignmentColumnDefinitions[Column.Consignment.CONSIGNMENT_DIFF],
      filter: CompositeFilter.componentName,
      filterParams: {
        components: [balancedFilterComponentParams],
      },
    },
    {
      ...ConsignmentColumnDefinitions[Column.Consignment.NVD_RECEIVED],
      filter: CompositeFilter.componentName,
      filterParams: {
        components: [exceptionsComponentParams],
      },
    },
    ConsignmentColumnDefinitions[Column.Consignment.NVD_ID],
    ConsignmentColumnDefinitions[Column.Consignment.ATTACHMENTS],
    ConsignmentColumnDefinitions[Column.Consignment.PIC_PROGRAMS],
    ConsignmentColumnDefinitions[Column.Consignment.LPA_STATUS],
    ConsignmentColumnDefinitions[Column.Consignment.VENDOR_PIC_LPA_NUMBER],
    ConsignmentColumnDefinitions[Column.Consignment.VENDOR_PIC_MSA_NUMBER],
    ConsignmentColumnDefinitions[Column.Consignment.DELIVERY_DATE],
    {
      ...ConsignmentColumnDefinitions[Column.Consignment.ABN],
      field: Column.Consignment.ABN,
    },
    ConsignmentColumnDefinitions[Column.Consignment.ABN_CHECK],
    {
      ...ConsignmentColumnDefinitions[Column.Consignment.GST_STATUS],
      field: Column.Consignment.GST_STATUS,
    },
    {
      ...ConsignmentColumnDefinitions[Column.Consignment.IS_HOBBY_FARMER],
      field: Column.Consignment.IS_HOBBY_FARMER,
    },
    ConsignmentColumnDefinitions[Column.Consignment.CARRIER],
    ConsignmentColumnDefinitions[Column.Consignment.HAS_ARRIVED],
    ConsignmentColumnDefinitions[Column.Consignment.CREATED],
    ConsignmentColumnDefinitions[Column.Consignment.LAST_MODIFIED],
    ConsignmentColumnDefinitions[Column.XERO_VENDOR],
    ConsignmentColumnDefinitions[Column.Consignment.VENDOR_SPLITS],
    ConsignmentColumnDefinitions[Column.Consignment.SOLD_QUANTITY],
    ConsignmentColumnDefinitions[Column.Consignment.TOTAL_VALUE],
    ConsignmentColumnDefinitions[Column.Consignment.UNIT_VALUE],
    ConsignmentColumnDefinitions[Column.Consignment.BRANDS],
    hasReceivalLotPermission &&
      ConsignmentColumnDefinitions[Column.Consignment.WARNING],
    {
      headerName: "Drafting Attributes",
      children: consignmentSpeciesAccreditationChildColumns(speciesId),
    },
    {
      headerName: ACTION_COLUMN_NAME,
      cellRenderer: UploadEditButton,
      pinned: "right",
      colId: ACTION_COLUMN_ID,
      width: isHooksSale ? 200 : 125,
      minWidth: isHooksSale ? 200 : 0,
      suppressCellFlash: true,
    },
    ConsignmentColumnDefinitions[saleLotCommentsColumnId],

    {
      headerName: "Vendor Property",
      children: Object.values(
        createNamespacedColumnDefinitions(
          PropertyColumnDefinitions,
          "vendor_property",
          PropertyNamespaceColumnId.VENDOR_PROPERTY,
        ),
      ),
    },
  ].filter(Boolean);
