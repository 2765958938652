import {
  ADD_WEIGHT_FROM_SCALES,
  CONNECT_TO_SCALES,
  CONNECT_TO_PLC,
} from "constants/actionTypes";

export function addWeight(deviceId, timestamp, weightGrams, isStable) {
  return {
    type: ADD_WEIGHT_FROM_SCALES,
    deviceId,
    timestamp,
    weightGrams,
    isStable,
  };
}

export function connectToAllScales() {
  return { type: CONNECT_TO_SCALES };
}

export function connectToAllPLCs() {
  return { type: CONNECT_TO_PLC };
}
