import React, { useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import { FieldArray, useField, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { SubtleBadge } from "components/Badge";
import { Button, SecondaryButton, FormCollapse } from "components/Form";
import {
  AutoSizeTextArea,
  FieldArrayCheckBox,
  FullPageForm,
  Input,
  Label,
  withNamespace,
} from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "components/MaterialDialog";

import { BusinessModalSection } from "constants/navigation";

import { openEditBusinessModal } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import { getBusinesses, getCurrentDeploymentMasterBusinesses } from "selectors";

const Wrapper = styled.div`
  padding: 12px;
`;

const PaddedLabel = styled(Label)`
  padding-left: 12px;
`;

const Header = ({ name }) => (
  <Row>
    <SubtleBadge>{name}</SubtleBadge>
  </Row>
);

function hashEmailRecipientValue(value) {
  return `${value.type}__${value.id}`;
}

const EmailRecipientFieldArray = ({ name, recipients }) => {
  const [{ value }] = useField(name);
  const recipientOptions = useMemo(
    () =>
      recipients.map(recipient => ({
        label: `${recipient.firstName} ${recipient.lastName} - ${recipient.email}`,
        id: recipient.id,
        value: { id: recipient.id, type: recipient.source.type },
      })),
    [recipients],
  );

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container>
          {recipientOptions.map((recipientOption, index) => (
            <Grid item xs={12} key={index}>
              <FieldArrayCheckBox
                arrayHelpers={arrayHelpers}
                array={value}
                getIsChecked={(valueA, values) =>
                  Boolean(
                    values.find(
                      valueB =>
                        hashEmailRecipientValue(valueA) ===
                        hashEmailRecipientValue(valueB),
                    ),
                  )
                }
                label={recipientOption.label}
                name={withNamespace(name, recipientOption.id)}
                value={recipientOption.value}
              />
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
};

const BusinessUserFieldArray = ({ name, recipients }) => {
  const { values } = useFormikContext();
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container>
          {recipients.map((recipient, index) => (
            <Grid item xs={12} key={index}>
              <FieldArrayCheckBox
                label={`${recipient.firstName} ${recipient.lastName} - ${recipient.email}`}
                name={`${recipient.id}`}
                value={recipient.id}
                arrayHelpers={arrayHelpers}
                array={values[name]}
              />
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
};

export const BusinessRecipientFormComponent = ({ businessId }) => {
  const business = useSelector(getBusinesses)[businessId];
  const businessEmailRecipients = business?.emailRecipients || [];
  const businessUsers = business?.businessUsers || [];
  const hasEmailRecipients =
    businessEmailRecipients.length > 0 || businessUsers.length > 0;
  const recipientCount = businessEmailRecipients.length + businessUsers.length;
  return (
    <Wrapper>
      {hasEmailRecipients && (
        <PaddedLabel>
          Email {pluralize("Recipient", recipientCount)}
        </PaddedLabel>
      )}
      <EmailRecipientFieldArray
        name="emailRecipients"
        recipients={businessEmailRecipients}
      />
      <BusinessUserFieldArray
        name="businessUserIds"
        recipients={businessUsers}
      />
      <Input type="email" label="Email Address" name="rawEmailAddress" />
    </Wrapper>
  );
};

export const DeploymentRecipientFormComponent = () => {
  const businesses = useSelector(getCurrentDeploymentMasterBusinesses);

  const [openIdxs, setOpenIdxs] = React.useState([]);

  const toggleSection = idx => {
    if (openIdxs.includes(idx)) {
      setOpenIdxs(openIdxs.filter(i => i !== idx));
    } else {
      setOpenIdxs([...openIdxs, idx]);
    }
  };

  return (
    <>
      {Object.values(businesses).map((business, idx) => (
        <FormCollapse
          isOpen={openIdxs.includes(idx)}
          onToggle={() => toggleSection(idx)}
          header={<Header name={business.name} />}
        >
          <EmailRecipientFieldArray
            name="emailRecipients"
            recipients={business.emailRecipients}
            businessId={business.businessId}
          />

          <BusinessUserFieldArray
            name="businessUserIds"
            recipients={business.businessUsers}
            businessId={business.businessId}
          />
        </FormCollapse>
      ))}
      <Wrapper>
        <Input type="email" label="Email Address" name="rawEmailAddress" />
      </Wrapper>
    </>
  );
};

export const EmailForm = ({
  businessId,
  deploymentId,
  onClose,
  description,
  includeTemplateText = false,
}) => {
  const formikProps = useFormikContext();
  const isDisabled = !formikProps.isValid;

  return (
    <FullPageForm>
      <DialogTitle onClose={onClose}>{`Email ${description}`}</DialogTitle>
      <DialogContent dividers form>
        {businessId || deploymentId ? (
          <BusinessRecipientFormComponent businessId={businessId} />
        ) : (
          <DeploymentRecipientFormComponent />
        )}
        {includeTemplateText && (
          <Column full>
            <AutoSizeTextArea
              name="emailTemplateText"
              multiline
              label="Custom Message"
              tooltip="Add a message to be included in the emeail here"
            />
          </Column>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton type="button" onClick={onClose}>
          Cancel
        </SecondaryButton>
        {businessId && (
          <Button
            type="button"
            onClick={() =>
              openEditBusinessModal(
                businessId,
                window.location.hash,
                BusinessModalSection.EMAIL_RECIPIENTS,
              )
            }
          >
            Update Business Contact
          </Button>
        )}
        <Button type="submit" disabled={isDisabled}>
          Send Email
        </Button>
      </DialogActions>
    </FullPageForm>
  );
};

export const validationSchema = Yup.object({
  rawEmailAddress: Yup.string().email("Please enter a valid email address"),
  businessUserIds: Yup.array(),
  emailRecipients: Yup.array(),
  emailPresent: Yup.array().when(
    ["rawEmailAddress", "emailRecipients", "businessUserIds"],
    (rawEmailAddress, emailRecipients, businessUserIds, schema) => {
      return schema.test({
        test: () =>
          rawEmailAddress ||
          emailRecipients.length > 0 ||
          businessUserIds.length > 0,
        message: "Select at least one email recipient",
      });
    },
  ),
});
