import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { sendToUserOverview, setActiveRole } from "actions";

import ConcurrentUserBlockNotification from "components/BlockNotifications/ConcurrentUserBlockNotification";
import GeoBlockNotification from "components/BlockNotifications/GeoBlockNotification";
import { CenteredGreedy, Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import { UserInitials } from "components/UserAvatar";

import { getRoleDisplayName, MAX_TO_SHOW_IN_DROPDOWN } from "constants/users";

import { pluralize } from "lib/pluralize";

import {
  getActiveRole,
  getInitials,
  getOnlineStatus,
  numberOfRolesSelector,
} from "selectors";

import { RoleIcon } from "./RoleIcon";
import RolePickerHeader from "./RolePickerHeader";
import { RoleTypeGroups } from "./RoleTypeGroups";

const RoleDropdown = styled.div`
  ${({ embedded, theme }) =>
    embedded
      ? ""
      : `
  top: ${theme.space[5]}px;
  @media only screen and (max-width: ${theme.breakpoints[0]}px) {      
      right: 0;
      left: 0;
  }
  @media only screen and (min-width: ${theme.breakpoints[0]}px) {
      right: 0;
      min-width: 400px;
      width: 60vw;
  }      
`}
`;

const CurrentRoleInfo = styled.div`
  border-top: 1px solid ${props => props.theme.colors.gray7A};
  background-color: ${props => props.theme.colors.grayAE};
  display: flex;
  flex-direction: column;
`;

const RoleText = styled(Row)`
  font-size: ${props => props.theme.fontSizes.beta}px;
  line-height: ${props => props.theme.fontSizes.beta}px;
  color: ${props => props.theme.colors.primary};
  flex-direction: column;
  padding: 20px;
  p {
    margin: 5px;
    text-align: center;
  }
`;

const CurrentRoleIndicator = styled(Row)`
  font-size: ${props => props.theme.fontSizes.beta}px;
  font-weight: normal;
  background-color: white;
  height: auto;
`;

const RolePickerControl = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.gray40};
  font-weight: normal;

  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin: 0;
  }

  font-size: ${props => props.theme.fontSizes.gamma}px;
  ${({ embedded, isOpen, theme }) =>
    embedded
      ? `
      width: 100%;
      margin: ${theme.space[3]}px;
      border-left: 1px solid ${theme.colors.gray7A};
      border-right: 1px solid ${theme.colors.gray7A};

  `
      : `       
  
  & > ${RoleDropdown} {
      border-left: 1px solid ${theme.colors.gray7A};
      border-right: 1px solid ${theme.colors.gray7A};
      border-bottom: 1px solid ${theme.colors.gray7A};

    background-color: ${theme.colors.gray44};
    z-index: 5000;
    max-height: 0px;
    display: none;
    position: absolute;    
  }
  &:focus-within > ${RoleDropdown} {
    display: ${isOpen ? "block" : "none"};
    max-height: 100vh;
  }`}
  &:focus {
    outline: none;
  }
`;

const SelectRoleButton = styled.button`
  font-size: ${props => props.theme.fontSizes.alpha}px;
  line-height: ${props => props.theme.fontSizes.alpha}px;
  height: ${props => props.theme.space[5]}px;
  padding: 0 ${props => props.theme.space[1]}px;
  font-weight: bold;
  text-transform: uppercase;
  background: inherit;
  border: none;
  color: inherit;
  text-align: initial;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & svg {
    margin-right: ${props => props.theme.space[2]}px;
  }
`;

const ActiveRoleInformation = () => {
  const activeRole = useSelector(getActiveRole);

  return (
    <CurrentRoleIndicator alignCenter justifyCenter>
      <Row>
        <RoleText>
          {activeRole.syncing ? (
            <p>Loading role details...</p>
          ) : (
            <>
              <p>
                Your Current Role is &nbsp;
                <strong>
                  {activeRole.username} - {getRoleDisplayName(activeRole.type)}
                </strong>
              </p>
              <p>
                &nbsp;
                <RoleIcon role={activeRole.type} size="1x" />
                &nbsp; <strong>{activeRole.name}</strong>
              </p>
            </>
          )}
        </RoleText>
      </Row>
    </CurrentRoleIndicator>
  );
};

export const RolePicker = ({ embedded }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const hatRef = React.useRef();
  const activeRole = useSelector(getActiveRole);

  const initials = useSelector(getInitials);
  const changesOutboxSize = useSelector(
    state => getOnlineStatus(state).changesOutboxSize,
  );
  const numberOfRoles = useSelector(numberOfRolesSelector);

  const dispatch = useDispatch();

  const onRoleChange = roleSlug => {
    if (!roleSlug) {
      return;
    }
    dispatch(setActiveRole(roleSlug));
    setDropDownOpen(false);
  };

  const onSendToUserOverview = () => {
    dispatch(sendToUserOverview());
  };

  // If we have more than <cutoff>, just link them to the user overview
  // unless we're flagged to be 'always open'.
  const includeDropdown =
    (numberOfRoles !== 0 && numberOfRoles <= MAX_TO_SHOW_IN_DROPDOWN) ||
    embedded;
  return (
    <RolePickerControl tabIndex="-1" embedded={embedded} isOpen={dropDownOpen}>
      {!embedded && (
        <SelectRoleButton
          data-tour="rolePicker"
          type="button"
          ref={hatRef}
          onClick={() =>
            includeDropdown ? setDropDownOpen(true) : onSendToUserOverview()
          }
        >
          <UserInitials initials={initials} />
        </SelectRoleButton>
      )}
      {includeDropdown && (
        <RoleDropdown embedded={embedded}>
          <CurrentRoleInfo justifyAround>
            <RolePickerHeader />
            {activeRole.slug && (
              <>
                <GeoBlockNotification />
                <ConcurrentUserBlockNotification />
                <ActiveRoleInformation />
              </>
            )}
          </CurrentRoleInfo>
          {changesOutboxSize === 0 ? (
            activeRole.slug && <RoleTypeGroups onRoleChange={onRoleChange} />
          ) : (
            <CenteredGreedy>
              You have {changesOutboxSize}{" "}
              {pluralize("change", changesOutboxSize)} pending. You will be able
              to change roles once they have completed.
              <LoadingSpinner />
            </CenteredGreedy>
          )}
        </RoleDropdown>
      )}
    </RolePickerControl>
  );
};

RolePicker.propTypes = {
  embedded: PropTypes.bool,
};

RolePickerControl.displayName = "RolePickerControl";
SelectRoleButton.displayName = "SelectRoleButton";
RoleDropdown.displayName = "RoleDropdown";
