import React, { PureComponent } from "react";

import { Formik } from "formik";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { CenteredGreedy, Column } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import MessageBox from "components/MessageBox";
import { ModalBg } from "components/Modal";

import { ScanListType, ScanningMode } from "constants/scanner";
import { Settings } from "constants/settings";

import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { scanLotConfig } from "lib/scanLot";

import { getCurrentSale, getSetting } from "selectors";

import AddToLotModal from "./AddToLotModal";
import ClearEidsModal from "./ClearEidsModal";
import ClearSavedEidsModal from "./ClearSavedEidsModal";
import { ExplanationText, Title } from "./Elements";
import FileImport from "./FileImport/FileImport";
import ManualInput from "./ManualInput/ManualInput";
import RealtimeScanning from "./Realtime/RealtimeScanning";
import ScanningBulkCreateSaleLotsModal from "./ScanningBulkCreateSaleLotsModal";
import ScanningHeader from "./ScanningHeader";
import ScanningSaleLotModal from "./ScanningSaleLotModal";
import DeviceImport from "./SessionImport/SessionImport";

const ScanningModal = {
  NONE: "NONE",
  ADD_TO_LOT: "ADD_TO_LOT",
  CREATE_LOT: "CREATE_LOT",
  CREATE_LOT_BULK: "CREATE_LOT_BULK",
  CLEAR_DIALOG: "CLEAR_DIALOG",
  CLEAR_SAVED_DIALOG: "CLEAR_SAVED_DIALOG",
};

function ScanningScreenComponent(props) {
  const {
    scanningMode,
    consignmentId,
    openClearModal,
    openClearSavedModal,
    openCreateModal,
    openCreateBulkModal,
    openSelectModal,
    resume,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
    closeSelf,
  } = props;

  const { getLotById, scanningValidationSchema, lotMarksReference } =
    scanLotConfig(penType);

  const isReceivalOrPenScanning =
    Boolean(penArchetypeId || penId || scanLotId) || false;

  const scanListType = isReceivalOrPenScanning
    ? ScanListType.ScanLot
    : ScanListType.SaleLot;

  const scanLot = useSelector(getLotById(scanLotId));

  const savedRoundId = useSelector(getSetting(Settings.savedScanLotRoundId));

  const savedDeploymentId = useSelector(
    getSetting(Settings.savedScanLotDeploymentId),
  );

  const saleRoundIds = useSelector(getCurrentSale).rounds;

  const initialValues = !isEmpty(scanLot)
    ? {
        quantity: scanLot.quantity,
        [lotMarksReference]: scanLot[lotMarksReference],
        scansCount: 0,
        deploymentId: scanLot.deploymentId,
        saleRoundId: scanLot.saleRoundId
          ? scanLot.saleRoundId
          : saleRoundIds[0],
        hasMarksConflict: false,
      }
    : {
        scansCount: 0,
        deploymentId: savedDeploymentId || null,
        saleRoundId: savedRoundId || null,
      };

  // Note - no action, as we'll never 'submit' this form, just to appease the required fields.
  const onSubmit = () => {};
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={scanningValidationSchema}
      enableReinitialize
      validateOnMount
    >
      <div>
        {scanningMode === ScanningMode.REALTIME && (
          <RealtimeScanning
            consignmentId={consignmentId}
            onCancel={closeSelf}
            onOpenClearModal={openClearModal}
            onOpenClearSavedModal={openClearSavedModal}
            onOpenCreateModal={openCreateModal}
            onOpenCreateBulkModal={openCreateBulkModal}
            onOpenSelectModal={openSelectModal}
            resumeScan={resume}
            saleLotId={saleLotId}
            penArchetypeId={penArchetypeId}
            penId={penId}
            scanLotId={scanLotId}
            penType={penType}
            scanListType={scanListType}
          />
        )}
        {scanningMode === ScanningMode.DEVICE_IMPORT && (
          <DeviceImport
            consignmentId={consignmentId}
            onCancel={closeSelf}
            onOpenClearModal={openClearModal}
            onOpenClearSavedModal={openClearSavedModal}
            onOpenCreateModal={openCreateModal}
            onOpenCreateBulkModal={openCreateBulkModal}
            onOpenSelectModal={openSelectModal}
            saleLotId={saleLotId}
            penArchetypeId={penArchetypeId}
            penId={penId}
            scanLotId={scanLotId}
            penType={penType}
            scanListType={scanListType}
          />
        )}
        {scanningMode === ScanningMode.FILE_IMPORT && (
          <FileImport
            collapsible={false}
            consignmentId={consignmentId}
            onCancel={closeSelf}
            onOpenClearModal={openClearModal}
            onOpenClearSavedModal={openClearSavedModal}
            onOpenCreateModal={openCreateModal}
            onOpenCreateBulkModal={openCreateBulkModal}
            onOpenSelectModal={openSelectModal}
            saleLotId={saleLotId}
            penArchetypeId={penArchetypeId}
            penId={penId}
            scanLotId={scanLotId}
            penType={penType}
            scanListType={scanListType}
          />
        )}
        {scanningMode === ScanningMode.MANUAL_INPUT && (
          <ManualInput
            consignmentId={consignmentId}
            onCancel={closeSelf}
            onOpenClearModal={openClearModal}
            onOpenClearSavedModal={openClearSavedModal}
            onOpenCreateModal={openCreateModal}
            onOpenCreateBulkModal={openCreateBulkModal}
            onOpenSelectModal={openSelectModal}
            saleLotId={saleLotId}
            penArchetypeId={penArchetypeId}
            penId={penId}
            scanLotId={scanLotId}
            penType={penType}
            scanListType={scanListType}
          />
        )}
        {typeof scanningMode !== "number" && (
          <CenteredGreedy>
            <Title>Choose Scanning Mode</Title>
            <ExplanationText>
              <p>
                Use the options above to select your method of capturing EIDs.
              </p>
            </ExplanationText>
          </CenteredGreedy>
        )}
      </div>
    </Formik>
  );
}

class ScanningScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openModal: ScanningModal.NONE,
      scanningMode:
        typeof props.scanningMode === "number"
          ? props.scanningMode
          : props.preferredScanningMode,
      commonModalProps: null,
    };
  }

  componentDidUpdate() {
    const { newSaleLotId } = this.state;
    const { saleLotId } = this.props;
    if (saleLotId !== newSaleLotId) {
      this.setState({ newSaleLotId: saleLotId });
    }
  }

  setScanningMode = scanningMode => {
    const { preferredScanningMode, updatePreferredScanningMode } = this.props;
    this.setState({ scanningMode });
    if (preferredScanningMode !== scanningMode) {
      updatePreferredScanningMode(scanningMode);
    }
  };

  closeModals = () =>
    this.setState({ openModal: ScanningModal.NONE, commonModalProps: null });

  openClearModal = commonModalProps =>
    this.setState({ openModal: ScanningModal.CLEAR_DIALOG, commonModalProps });

  openClearSavedModal = commonModalProps =>
    this.setState({
      openModal: ScanningModal.CLEAR_SAVED_DIALOG,
      commonModalProps,
    });

  openCreateModal = commonModalProps =>
    this.setState({
      openModal: ScanningModal.CREATE_LOT,
      commonModalProps,
    });

  openCreateBulkModal = commonModalProps =>
    this.setState({
      openModal: ScanningModal.CREATE_LOT_BULK,
      commonModalProps,
    });

  openSelectModal = commonModalProps =>
    this.setState({ openModal: ScanningModal.ADD_TO_LOT, commonModalProps });

  render() {
    const { commonModalProps, openModal, scanningMode } = this.state;
    const {
      closeSelf,
      consignmentExists,
      consignmentId,
      resume,
      saleLotExists,
      saleLotId,
      isLoading,
      penArchetypeId,
      penId,
      scanLotId,
      penType,
    } = this.props;

    // Only prevent user from scanning when we are missing data, if we have partial/stale data, that's good enough
    const showLoading =
      isLoading &&
      ((consignmentId && !consignmentExists) || (saleLotId && !saleLotExists));

    return (
      <Wrapper>
        <ScanningHeader
          onBack={closeSelf}
          scanningMode={scanningMode}
          onScanningModeChanged={this.setScanningMode}
          showTotal={scanningMode === ScanningMode.REALTIME}
          saleLotId={saleLotId}
          penType={penType}
          penArchetypeId={penArchetypeId}
        />

        {showLoading ? (
          <Column flexGrow>
            <MessageBox>
              <LoadingSpinner />
            </MessageBox>
          </Column>
        ) : consignmentId && !consignmentExists ? (
          <Column flexGrow>
            <MessageBox>
              <CenteredGreedy>
                Could not find the requested{" "}
                <TranslatedSaleTypeLabel label="Consignment" />
              </CenteredGreedy>
            </MessageBox>
          </Column>
        ) : saleLotId && !saleLotExists ? (
          <Column flexGrow>
            <MessageBox>
              Could not find the requested{" "}
              <TranslatedSaleTypeLabel label="Sale Lot" />
            </MessageBox>
          </Column>
        ) : (
          <ScanningScreenComponent
            scanningMode={scanningMode}
            consignmentId={consignmentId}
            openClearModal={this.openClearModal}
            openCreateModal={this.openCreateModal}
            openClearSavedModal={this.openClearSavedModal}
            openCreateBulkModal={this.openCreateBulkModal}
            openSelectModal={this.openSelectModal}
            resume={resume}
            saleLotId={saleLotId}
            penArchetypeId={penArchetypeId}
            penId={penId}
            scanLotId={scanLotId}
            penType={penType}
            closeSelf={closeSelf}
          />
        )}

        {openModal === ScanningModal.ADD_TO_LOT && (
          <AddToLotModal
            commonModalProps={commonModalProps}
            closeSelf={this.closeModals}
            consignmentId={consignmentId}
            modalProps={commonModalProps}
          />
        )}
        {openModal === ScanningModal.CREATE_LOT && (
          <ScanningSaleLotModal
            closeSelf={this.closeModals}
            modalProps={commonModalProps}
            consignmentId={consignmentId}
          />
        )}
        {openModal === ScanningModal.CREATE_LOT_BULK && (
          <ScanningBulkCreateSaleLotsModal
            modalProps={commonModalProps}
            closeSelf={this.closeModals}
          />
        )}
        {openModal === ScanningModal.CLEAR_DIALOG && (
          <ClearEidsModal
            modalProps={commonModalProps}
            closeSelf={this.closeModals}
          />
        )}
        {openModal === ScanningModal.CLEAR_SAVED_DIALOG && (
          <ClearSavedEidsModal
            modalProps={commonModalProps}
            closeSelf={this.closeModals}
          />
        )}
      </Wrapper>
    );
  }
}

ScanningScreen.propTypes = {
  closeSelf: PropTypes.func.isRequired,
  consignmentExists: PropTypes.bool.isRequired,
  consignmentId: PropTypes.string,
  isLoading: PropTypes.bool,
  preferredScanningMode: PropTypes.number,
  resume: PropTypes.bool.isRequired,
  saleLotExists: PropTypes.bool.isRequired,
  saleLotId: PropTypes.string,
  scanningMode: PropTypes.number,
  updatePreferredScanningMode: PropTypes.func.isRequired,
};

export default ScanningScreen;

const Wrapper = styled(ModalBg).attrs({
  contentStyle: {
    height: "100%",
    width: "100%",
    backgroundColor: "#f4f4f4",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
  },
})``;
