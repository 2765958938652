import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const penScanLotSerializerMap = {
  created: "created",
  id: "id",
  isLocked: "is_locked",
  lastModified: "last_modified",
  livestockSaleId: "livestock_sale_id",
  marks: "marks",
  permissions: "permissions",
  quantity: "quantity",
  sellingPenId: "selling_pen_id",
  commentIds: "comment_ids",
  saleRoundId: "sale_round_id",
  deploymentId: "deployment_id",
};

export const serializePenScanLot = buildSerializer(
  penScanLotSerializerMap,
  "penScanLot",
);

export const deserializePenScanLot = buildDeserializer(
  penScanLotSerializerMap,
  "penScanLot",
  {},
  {
    commentIds: [],
  },
);
