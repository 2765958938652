import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { FullScreenContainer } from "components/FullScreenContainer";
import { NextLotFooter } from "components/SaleWatcher/NextLot";
import "./transitions.css";

import {
  getCurrentSale,
  getSellingNowByPriceSaleLot,
  getSoldSaleLotsInSoldOrder,
  getDeployments,
  selectWeighedSaleLotsInWeighedOrder,
  selectUnsoldWeighedSaleLotsInWeighedOrderAfterCurrentSold,
} from "selectors";

import {
  BoundingBox,
  CentsPerKilo,
  DollarsPerHead,
  AverageWeight,
} from "./WatcherComponents";

const HighContrastTable = styled.table(
  ({ theme }) => `
  border-collapse: collapse;
  font-size: 5vw;
  font-weight: bold;
  background-color: ${theme.colors.gray13};
  color: ${theme.colors.highContrastDefault};
  width: 100%;
  height: 100%;
  line-height: 0.8;
  
  colgroup #rowHeading {
    width: 20%;
  }

  colgroup #previous,
  colgroup #current {
    width: 40%;
  }

  td {
    border: 1px solid ${theme.colors.grayAE};
    padding 0 ${theme.space[2]}px; 
    font-size: 10vw;
    text-align: center;
    font-weight: bolder;
  }
  
  tfoot td {
    font-size: 5vw; 
    padding-top: ${theme.space[3]}px;
    padding-bottom: ${theme.space[3]}px;
  }
  tfoot td, 
  tfoot th {
    color: ${theme.colors.highContrastNextUp};
  }


  .current {
      color: ${theme.colors.highContrastCurrent};
  }
  
 
  img {
    width: 100%;
  }
`,
);

const getLogoUrl = (currentSale, deployments) => {
  if (currentSale.sale_watcher_logo_url) {
    return currentSale.sale_watcher_logo_url;
  } else if (deployments.length === 1) {
    return deployments[0].saleWatcherLogo;
  } else {
    return null;
  }
};

const RingSellingBriefTableBody = ({ leftSaleLot, rightSaleLot }) => (
  <tbody>
    <tr>
      <th className="current">Head</th>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        {leftSaleLot.quantity || "-"}
      </td>
      <td className="fadeIn" key={rightSaleLot.last_modified}>
        {rightSaleLot.quantity || "-"}
      </td>
    </tr>
    <tr>
      <th className="current">
        Avg.
        <br />
        kg
      </th>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        <AverageWeight saleLot={leftSaleLot} includeUnits={false} />
      </td>
      <td className="fadeIn" key={rightSaleLot.last_modified}>
        <AverageWeight saleLot={rightSaleLot} includeUnits={false} />
      </td>
    </tr>
    <tr>
      <th className="current">&#162;/kg</th>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        <CentsPerKilo saleLot={leftSaleLot} noSymbol />
      </td>
      <td className="fadeIn" key={rightSaleLot.last_modified}>
        <CentsPerKilo saleLot={rightSaleLot} noSymbol />
      </td>
    </tr>
    <tr>
      <th className="current">$/Hd</th>
      <td className="current fadeIn" key={leftSaleLot.last_modified}>
        <DollarsPerHead saleLot={leftSaleLot} noSymbol />
      </td>
      <td className="fadeIn" key={rightSaleLot.last_modified}>
        <DollarsPerHead saleLot={rightSaleLot} noSymbol />
      </td>
    </tr>
  </tbody>
);

function RingSellingBrief({
  rightLot,
  rightLotTitle,
  leftLot,
  leftLotTitle,
  nextLot,
  showNext = false,
}) {
  const currentSale = useSelector(getCurrentSale);
  const deployments = useSelector(getDeployments);
  const logo = getLogoUrl(currentSale, deployments);

  return (
    <FullScreenContainer>
      <BoundingBox>
        <HighContrastTable>
          <colgroup>
            <col id="rowHeading" />
            <col id="current" />
            <col id="previous" />
          </colgroup>
          <thead>
            <tr>
              <th>
                {logo && <img alt="logo" src={logo} />}
                {/*
                Removed until more time to figure out why its adding extra padding
                <PoweredByAgrinous />
                */}
              </th>
              <th className="current">{leftLotTitle}</th>
              <th>{rightLotTitle}</th>
            </tr>
          </thead>
          <RingSellingBriefTableBody
            leftSaleLot={leftLot}
            rightSaleLot={rightLot}
          />
          {showNext ? <NextLotFooter saleLot={nextLot} colSpan={2} /> : null}
        </HighContrastTable>
      </BoundingBox>
    </FullScreenContainer>
  );
}

export const RingSellingBriefByPriceView = () => {
  const sellingNow = useSelector(getSellingNowByPriceSaleLot) || {};
  const lastSold = useSelector(getSoldSaleLotsInSoldOrder)?.[0] || {};

  return (
    <RingSellingBrief
      rightLot={lastSold}
      rightLotTitle="PREVIOUS"
      leftLot={sellingNow}
      leftLotTitle="CURRENT"
    />
  );
};

export const RingSellingBriefByWeightView = () => {
  const weighedLots = useSelector(selectWeighedSaleLotsInWeighedOrder) || [];

  const latestTwo = weighedLots.slice(-2);
  const currentSaleLot = latestTwo.pop() || {};
  const previousSaleLot = latestTwo.pop() || {};

  return (
    <RingSellingBrief
      rightLot={previousSaleLot}
      rightLotTitle="JUST SOLD"
      leftLot={currentSaleLot}
      leftLotTitle="SELLING NOW"
    />
  );
};

export function RingSellingBriefBySoldThenWeight() {
  const lastSold = useSelector(getSoldSaleLotsInSoldOrder)?.[0] || {};
  const weighedUnsoldLots =
    useSelector(selectUnsoldWeighedSaleLotsInWeighedOrderAfterCurrentSold) ||
    [];

  // Next two unsold, by weigh time after last sold.
  const currentSaleLot = weighedUnsoldLots?.[0] || {};
  const nextSaleLot = weighedUnsoldLots?.[1] || null;

  return (
    <RingSellingBrief
      leftLot={lastSold}
      leftLotTitle="JUST SOLD"
      rightLot={currentSaleLot}
      rightLotTitle="SELLING NOW"
      showNext
      nextLot={nextSaleLot}
    />
  );
}
