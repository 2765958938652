import intersection from "lodash/intersection";
import { all, put, select, takeEvery } from "redux-saga/effects";
import { v4 } from "uuid";

import {
  MasterRuleAction,
  MasterRuleBookAction,
  RuleAction,
  RuleBookAction,
} from "actions";

import {
  MASTER_RULE,
  MASTER_RULE_BOOK,
  RULE,
  RULE_BOOK,
} from "constants/actionTypes";

import {
  getLastOrderByMasterRuleBookId,
  getLastOrderByRuleBookId,
  getMasterRuleBookById,
  getMasterRuleById,
  getMasterRuleIdsByMasterRuleBookId,
  getMasterRules,
  getMasterRulesByMasterRuleBookId,
  getRuleBookById,
  getRuleById,
  getRuleIdsByMasterRuleId,
  getRuleIdsByRuleBookId,
  getRules,
  getRulesByRuleBookId,
  selectRuleIdsByMasterRuleIdLookup,
} from "selectors";

function* onSubscribeToMasterRuleBook(action) {
  const { ruleBookId, masterRuleBookId, options = null } = action;

  const state = yield select();
  const masterRules =
    getMasterRulesByMasterRuleBookId(masterRuleBookId)(state) || [];
  const masterRuleBook = getMasterRuleBookById(masterRuleBookId)(state);

  const lastOrder = getLastOrderByRuleBookId(ruleBookId)(state);
  const rules = masterRules
    .filter(masterRule => !masterRule.is_deleted)
    .map((masterRule, index) => {
      const existingRuleIds = intersection(
        getRuleIdsByMasterRuleId(masterRule.id)(state),
        getRuleIdsByRuleBookId(ruleBookId),
      );

      return {
        ...masterRule,
        id: existingRuleIds[0]?.id || v4(),
        comment: "",
        master_rule_id: masterRule.id,
        order: lastOrder + index + 1,
        rule_book_id: ruleBookId,
      };
    });

  // For this Rule Book add a Managed Rule for each of the Master Rules in the given Master Rule Book
  yield all(
    rules.map(rule =>
      put(
        RuleAction.create(rule, {
          changeReason: `Subscribed to "${masterRuleBook.name}"`,
          ...options,
        }),
      ),
    ),
  );
}

function* onUnsubscribeFromMasterRuleBook(action) {
  const { ruleBookId, masterRuleBookId, options = null } = action;

  const state = yield select();
  const masterRuleBook = getMasterRuleBookById(masterRuleBookId)(state);
  const masterRuleIds =
    getMasterRuleIdsByMasterRuleBookId(masterRuleBookId)(state) || [];

  const rules = getRulesByRuleBookId(ruleBookId)(state) || [];

  // For this Rule Book remove all Managed Rules which are related to the Master Rules in the given Master Rule Book
  yield all(
    rules
      .filter(rule => masterRuleIds.includes(rule.master_rule_id))
      .map(rule =>
        put(
          RuleAction.delete(rule.id, {
            changeReason: `Unsubscribed from "${masterRuleBook.name}"`,
            ...options,
          }),
        ),
      ),
  );
}

function* onCopyRule(action) {
  const { ruleId, options = null } = action;
  const state = yield select();
  const rule = getRuleById(ruleId)(state);

  const lastOrder = getLastOrderByRuleBookId(rule.rule_book_id)(state);

  yield put(
    RuleAction.create(
      { ...rule, id: v4(), name: `${rule.name} (Copy)`, order: lastOrder + 1 },
      { changeReason: `Copied from "${rule.name}"`, ...options },
    ),
  );
}

function* onCopyMasterRule(action) {
  const { masterRuleId, options = null } = action;
  const state = yield select();
  const masterRule = getMasterRuleById(masterRuleId)(state);

  const lastOrder = getLastOrderByMasterRuleBookId(masterRule.rule_book_id)(
    state,
  );

  yield put(
    MasterRuleAction.create(
      {
        ...masterRule,
        id: v4(),
        name: `${masterRule.name} (Copy)`,
        order: lastOrder + 1,
      },
      { changeReason: `Copied from "${masterRule.name}"`, ...options },
    ),
  );
}

function decrementRuleOrMasterRuleOrder(thisRule, prevRule) {
  return [
    { ...thisRule, order: prevRule.order },
    {
      ...prevRule,
      order: prevRule.order + 1,
    },
  ];
}

function incrementRuleOrMasterRuleOrder(thisRule, nextRule) {
  return [
    { ...thisRule, order: nextRule.order },
    {
      ...nextRule,
      order: nextRule.order - 1,
    },
  ];
}

function* onMoveUpRule(action) {
  const { ruleId, options = null } = action;
  const state = yield select();
  const ruleBookId = getRuleById(ruleId)(state).rule_book_id;
  const rules = getRulesByRuleBookId(ruleBookId)(state);

  const thisIndex = rules.findIndex(rule => rule.id === ruleId);
  const thisRule = rules[thisIndex];
  const prevRule = rules[thisIndex - 1];

  yield all(
    decrementRuleOrMasterRuleOrder(thisRule, prevRule).map(rule =>
      put(
        RuleAction.update(
          { id: rule.id, order: rule.order },
          {
            changeReason: "Updated order",
            ...options,
          },
        ),
      ),
    ),
  );
}

function* onMoveDownRule(action) {
  const { ruleId, options = null } = action;
  const state = yield select();
  const ruleBookId = getRuleById(ruleId)(state).rule_book_id;
  const rules = getRulesByRuleBookId(ruleBookId)(state);

  const thisIndex = rules.findIndex(rule => rule.id === ruleId);
  const thisRule = rules[thisIndex];
  const nextRule = rules[thisIndex + 1];

  yield all(
    incrementRuleOrMasterRuleOrder(thisRule, nextRule).map(rule =>
      put(
        RuleAction.update(
          { id: rule.id, order: rule.order },
          {
            changeReason: "Updated order",
            ...options,
          },
        ),
      ),
    ),
  );
}

function* onMoveUpMasterRule(action) {
  const { masterRuleId, options = null } = action;
  const state = yield select();
  const masterRuleBookId = getMasterRuleById(masterRuleId)(state).rule_book_id;
  const masterRules = getMasterRulesByMasterRuleBookId(masterRuleBookId)(state);

  const currentIndex = masterRules.findIndex(
    masterRule => masterRule.id === masterRuleId,
  );
  const currentMasterRule = masterRules[currentIndex];
  const prevMasterRule = masterRules[currentIndex - 1];

  yield all(
    decrementRuleOrMasterRuleOrder(currentMasterRule, prevMasterRule).map(
      updatedMasterRule =>
        put(
          MasterRuleAction.update(updatedMasterRule, {
            changeReason: "Updated order",
            ...options,
          }),
        ),
    ),
  );
}
function* onMoveDownMasterRule(action) {
  const { masterRuleId, options = null } = action;
  const state = yield select();
  const masterRuleBookId = getMasterRuleById(masterRuleId)(state).rule_book_id;
  const masterRules = getMasterRulesByMasterRuleBookId(masterRuleBookId)(state);

  const currentIndex = masterRules.findIndex(
    masterRule => masterRule.id === masterRuleId,
  );
  const currentMasterRule = masterRules[currentIndex];
  const nextMasterRule = masterRules[currentIndex + 1];

  yield all(
    incrementRuleOrMasterRuleOrder(currentMasterRule, nextMasterRule).map(
      updatedMasterRule =>
        put(
          MasterRuleAction.update(updatedMasterRule, {
            changeReason: "Updated order",
            ...options,
          }),
        ),
    ),
  );
}

function copyRuleGeneratorFactory(
  sourceRuleBookIdKey,
  destinationRuleBookIdKey,
  ruleBookGetterSelector,
  ruleBookRulesGetterSelector,
  ruleBookOfflineActionCreator,
) {
  return function* onCopyRuleBookGenFn(action) {
    const {
      [sourceRuleBookIdKey]: sourceRuleBookId,
      [destinationRuleBookIdKey]: destinationRuleBookId = v4(),
      options = null,
    } = action;
    const state = yield select();
    const ruleBook = ruleBookGetterSelector(sourceRuleBookId)(state);

    const ruleBookRules =
      ruleBookRulesGetterSelector(sourceRuleBookId)(state) || [];
    const rulesCopy = ruleBookRules.map(rule => ({
      ...rule,
      rule_book_id: undefined,
      id: v4(),
    }));
    yield put(
      ruleBookOfflineActionCreator.create(
        {
          ...ruleBook,
          id: destinationRuleBookId,
          name: `${ruleBook.name} (Copy)`,
          rules: rulesCopy,
        },
        { changeReason: `Copied from "${ruleBook.name}"`, ...options },
      ),
    );
  };
}

const onCopyMasterRuleBook = copyRuleGeneratorFactory(
  "sourceMasterRuleBookId",
  "destinationMasterRuleBookId",
  getMasterRuleBookById,
  getMasterRulesByMasterRuleBookId,
  MasterRuleBookAction,
);
const onCopyRuleBook = copyRuleGeneratorFactory(
  "sourceRuleBookId",
  "destinationRuleBookId",
  getRuleBookById,
  getRulesByRuleBookId,
  RuleBookAction,
);

function* onUpdateManagedRuleBook(action) {
  const { sourceMasterRuleBookId, managedRuleBookId, ruleId } = action;
  const state = yield select();
  const masterRuleIds = getMasterRuleIdsByMasterRuleBookId(
    sourceMasterRuleBookId,
  )(state);
  const ruleIdsByMasterRuleId = selectRuleIdsByMasterRuleIdLookup(state);
  const rules = getRules(state);
  const masterRules = getMasterRules(state);

  yield all(
    masterRuleIds.map(masterRuleId => {
      // If there are not rules for the master rule yet, then we need to make one
      if (!ruleIdsByMasterRuleId[masterRuleId] && !ruleId) {
        const masterRule = masterRules[masterRuleId];
        return put(
          RuleAction.create(
            {
              ...masterRule,
              id: v4(),
              comment: "",
              master_rule_id: masterRule.id,
              rule_book_id: managedRuleBookId,
            },
            { changeReason: `Rolled down from "${masterRule.name}"` },
          ),
        );
      }

      const filteredManagedRuleIds =
        ruleIdsByMasterRuleId[masterRuleId]?.filter(
          managedRuleId => ruleId && ruleId === managedRuleId,
        ) || [];

      // Otherwise we can check the content and is_deleted of the master rule and managed ruleto see if it needs updating
      return all(
        filteredManagedRuleIds.map(managedRuleId => {
          const masterRule = masterRules[masterRuleId];
          const managedRule = rules[managedRuleId];

          if (masterRule.is_deleted) {
            return put(RuleAction.delete(managedRule.id));
          } else if (
            managedRule.content !== masterRule.content ||
            managedRule.unit_amount_raw_format !==
              masterRule.unit_amount_raw_format ||
            managedRule.quantity_raw_format !== masterRule.quantity_raw_format
          ) {
            return put(
              RuleAction.update(
                {
                  content: masterRule.content,
                  id: managedRule.id,
                  quantity_raw_format: masterRule.quantity_raw_format,
                  quantity_output_format: masterRule.quantity_output_format,
                  unit_amount_raw_format: masterRule.unit_amount_raw_format,
                  unit_amount_output_format:
                    masterRule.unit_amount_output_format,
                },
                {
                  changeReason:
                    "Updated from master rulebook (via the frontend)",
                },
              ),
            );
          } else {
            return null;
          }
        }),
      );
    }),
  );
}

export default function* rootSaga() {
  yield takeEvery(
    RULE_BOOK.UNSUBSCRIBE_FROM_MASTER_RULE_BOOK.ACTION,
    onUnsubscribeFromMasterRuleBook,
  );
  yield takeEvery(
    RULE_BOOK.SUBSCRIBE_TO_MASTER_RULE_BOOK.ACTION,
    onSubscribeToMasterRuleBook,
  );
  yield takeEvery(
    MASTER_RULE_BOOK.UPDATE_MANAGED_RULE_BOOK.ACTION,
    onUpdateManagedRuleBook,
  );

  yield takeEvery(RULE.COPY.ACTION, onCopyRule);
  yield takeEvery(RULE.MOVE_UP.ACTION, onMoveUpRule);
  yield takeEvery(RULE.MOVE_DOWN.ACTION, onMoveDownRule);

  yield takeEvery(MASTER_RULE_BOOK.COPY.ACTION, onCopyMasterRuleBook);

  yield takeEvery(RULE_BOOK.COPY.ACTION, onCopyRuleBook);

  yield takeEvery(MASTER_RULE.COPY.ACTION, onCopyMasterRule);
  yield takeEvery(MASTER_RULE.MOVE_UP.ACTION, onMoveUpMasterRule);
  yield takeEvery(MASTER_RULE.MOVE_DOWN.ACTION, onMoveDownMasterRule);
}
