import queryString from "query-string";

import { AUCTION_PEN, PEN_ARCHETYPE } from "constants/actionTypes";

import { getLivestockSaleId, getSaleyardName } from "lib/navigation";
import {
  serializeAuctionPen,
  serializePenOwner,
} from "lib/serializers/auctionPens";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

export function addAuctionPen(
  payload,
  tempId,
  saleLotIds = [],
  disabledToast = false,
) {
  return {
    type: AUCTION_PEN.CREATE.ACTION,
    payload,
    tempId,
    saleLotIds,
    disabledToast,
  };
}

export function addAuctionPenFromPenArchetype(
  penArchetypeId,
  tempId,
  extraPayload = {},
) {
  return {
    type: PEN_ARCHETYPE.CREATE.ACTION,
    penArchetypeId,
    tempId,
    extraPayload,
  };
}

const urlCreator = ({
  changesSince,
  saleyardName,
  livestockSaleId,
  id,
  action,
} = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction(
      `/v2/saleyards/${saleyardName}/sales/${livestockSaleId}/auctionpens/`,
      id,
      action,
    ),

    query: {
      changesSince,
    },
  });

// TODO - how can we flag that some of these aren't the 'right' way to do things?  EG - adding an action pen goes
// via AUCTION_PEN.CREATE.ACTION (or, should)
export const AuctionPenAction = offlineActionCreator(
  AUCTION_PEN,
  serializeAuctionPen,
  urlCreator,
  true,
);

AuctionPenAction.moveScans = function moveScans(fromPenId, toPenId, eids) {
  const options = {
    saleyardName: getSaleyardName(),
    livestockSaleId: getLivestockSaleId(),
    id: fromPenId,
    action: "move_scans",
  };
  return {
    type: AUCTION_PEN.MOVE_SCANS.REQUEST,
    meta: {
      fromPenId,
      toPenId,
      eids,
      offline: {
        effect: {
          url: urlCreator(options),
          method: "POST",
          body: JSON.stringify({ pen_id: toPenId }),
        },
        commit: {
          type: AUCTION_PEN.MOVE_SCANS.SUCCESS,
        },
        rollback: {
          type: AUCTION_PEN.MOVE_SCANS.FAILURE,
        },
      },
    },
  };
};

AuctionPenAction.orderAfter = function orderPenAfter(auctionPenId, afterPenId) {
  const options = {
    saleyardName: getSaleyardName(),
    livestockSaleId: getLivestockSaleId(),
    id: auctionPenId,
    action: `order-after/${afterPenId}`,
  };
  const REDUX_OFFLINE_TIMESTAMP = Date.now();
  return {
    type: AUCTION_PEN.ORDER_AFTER.REQUEST,
    REDUX_OFFLINE_TIMESTAMP,
    auctionPenId,
    afterPenId,
    meta: {
      offline: {
        effect: {
          url: urlCreator(options),
          method: "POST",
        },
        commit: {
          type: AUCTION_PEN.ORDER_AFTER.SUCCESS,
          REDUX_OFFLINE_TIMESTAMP,
        },
        rollback: {
          type: AUCTION_PEN.ORDER_AFTER.FAILURE,
        },
      },
    },
  };
};

AuctionPenAction.orderBefore = function orderPenBefore(
  auctionPenId,
  beforePenId,
) {
  const options = {
    saleyardName: getSaleyardName(),
    livestockSaleId: getLivestockSaleId(),
    id: auctionPenId,
    action: `order-before/${beforePenId}`,
  };
  const REDUX_OFFLINE_TIMESTAMP = Date.now();
  return {
    type: AUCTION_PEN.ORDER_BEFORE.REQUEST,
    REDUX_OFFLINE_TIMESTAMP,
    auctionPenId,
    beforePenId,
    meta: {
      offline: {
        effect: {
          url: urlCreator(options),
          method: "POST",
        },
        commit: {
          type: AUCTION_PEN.ORDER_BEFORE.SUCCESS,
          REDUX_OFFLINE_TIMESTAMP,
        },
        rollback: {
          type: AUCTION_PEN.ORDER_BEFORE.FAILURE,
        },
      },
    },
  };
};

AuctionPenAction.setSellingOrder = (saleRoundId, auctionPens) => {
  const options = {
    saleyardName: getSaleyardName(),
    livestockSaleId: getLivestockSaleId(),
    id: saleRoundId,
    action: "selling-order",
  };
  const REDUX_OFFLINE_TIMESTAMP = Date.now();
  const payload = auctionPens.map(serializeAuctionPen);
  return {
    type: AUCTION_PEN.SET_SELLING_ORDER.REQUEST,
    REDUX_OFFLINE_TIMESTAMP,
    payload,
    meta: {
      offline: {
        effect: {
          url: urlCreator(options),
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: AUCTION_PEN.SET_SELLING_ORDER.SUCCESS,
          REDUX_OFFLINE_TIMESTAMP,
        },
        rollback: {
          type: AUCTION_PEN.SET_SELLING_ORDER.FAILURE,
        },
      },
    },
  };
};

AuctionPenAction.orderSellingNow = function orderPenSellingNow(auctionPenId) {
  const options = {
    saleyardName: getSaleyardName(),
    livestockSaleId: getLivestockSaleId(),
    id: auctionPenId,
    action: `order-selling-now`,
  };
  const REDUX_OFFLINE_TIMESTAMP = Date.now();
  return {
    type: AUCTION_PEN.ORDER_SELLING_NOW.REQUEST,
    REDUX_OFFLINE_TIMESTAMP,
    auctionPenId,
    meta: {
      offline: {
        effect: {
          url: urlCreator(options),
          method: "POST",
        },
        commit: {
          type: AUCTION_PEN.ORDER_SELLING_NOW.SUCCESS,
          REDUX_OFFLINE_TIMESTAMP,
        },
        rollback: {
          type: AUCTION_PEN.ORDER_SELLING_NOW.FAILURE,
        },
      },
    },
  };
};

AuctionPenAction.penOwner = {
  create: (auctionPenId, penOwner) => {
    const options = {
      saleyardName: getSaleyardName(),
      livestockSaleId: getLivestockSaleId(),
      id: auctionPenId,
      action: "owner",
    };
    const payload = serializePenOwner(penOwner);
    return {
      type: AUCTION_PEN.OWNER.CREATE.REQUEST,
      payload,
      auctionPenId,
      meta: {
        offline: {
          effect: {
            url: urlCreator(options),
            method: "POST",
            body: JSON.stringify(payload),
          },
          commit: {
            type: AUCTION_PEN.OWNER.CREATE.SUCCESS,
          },
          rollback: {
            type: AUCTION_PEN.OWNER.CREATE.FAILURE,
          },
        },
      },
    };
  },
  delete: (auctionPenId, penOwner) => {
    const options = {
      saleyardName: getSaleyardName(),
      livestockSaleId: getLivestockSaleId(),
      id: auctionPenId,
      action: "owner",
    };
    const payload = serializePenOwner(penOwner);
    return {
      type: AUCTION_PEN.OWNER.DELETE.REQUEST,
      payload,
      auctionPenId,
      meta: {
        offline: {
          effect: {
            url: urlCreator(options),
            method: "DELETE",
            body: JSON.stringify(payload),
          },
          commit: {
            type: AUCTION_PEN.OWNER.DELETE.SUCCESS,
          },
          rollback: {
            type: AUCTION_PEN.OWNER.DELETE.FAILURE,
          },
        },
      },
    };
  },
};
