import React from "react";

import { printPlacardV2 } from "actions";

import { Accreditation } from "constants/draftingAttributes";
import { PageSize, PlacardVersions } from "constants/pdf";

import { toTitleCase } from "lib";

import { sortByRoundAndPen } from "lib/auctionPens";
import { blackBox, fontSize, getVendorName, tick } from "lib/pdf";
import { getMarksLocationString } from "lib/saleLot";
import { formatTime } from "lib/timeFormats";

import agrinousLogo from "img/AgriNous-logo-black-white-base64";

import { RenderPlacard } from "./RenderPlacard";

export const getContentForPlacardV2 = (
  saleLots,
  deploymentIdBySaleLotIdLookup,
  deployments,
  vendorDeploymentBusinessBySaleLotIdLookup,
  isVendorBredBySaleLotIdLookup,
) => {
  const contents = [];
  sortByRoundAndPen(saleLots).forEach((saleLot, index) => {
    const deploymentBusiness =
      vendorDeploymentBusinessBySaleLotIdLookup[saleLot.id];

    const vendorName = getVendorName(saleLot, deploymentBusiness);

    const deploymentId = deploymentIdBySaleLotIdLookup[saleLot.id];
    const deployment = deployments[deploymentId];

    contents.push([
      {
        layout: "noBorders",
        table: {
          widths: [1191],
          body: [
            [""],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                text: " ",
              },
            ],
            [
              {
                width: 90,
                text: `${vendorName.substring(0, 15)}`,
                fontSize: fontSize(vendorName),
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: saleLot.quantity
                  ? `${saleLot.quantity} ${saleLot.sex_name} ${
                      saleLot.averageWeightFormattedRounded
                        ? ` @ ${saleLot.averageWeightFormattedRounded} Kg`
                        : ""
                    }`
                  : "-",
                fontSize: 100,
                alignment: "center",
                bold: true,
              },
            ],
          ],
        },
      },
      {
        columns: [
          {
            canvas: [
              {
                type: "rect",
                x: 20,
                y: 15,
                w: 1136,
                h: 790,
                lineWidth: 10,
                lineColor: deployment.corporateColor
                  ? deployment.corporateColor
                  : "#000000",
              },
            ],
            absolutePosition: { x: 4, y: 4 },
          },
          blackBox("Vendor \nBred", 450, 458, deployment, 100, 105, 310),
          tick(isVendorBredBySaleLotIdLookup[saleLot.id] === true, 312, 458),
          blackBox("Grass Fed", 450, 475, deployment, 450, 455, 660),
          tick(
            saleLot.draftingAttributes?.accreditationGrassFed ===
              Accreditation.ALL,
            662,
            458,
          ),
          blackBox(
            getMarksLocationString(saleLot),
            450,
            475,
            deployment,
            800,
            840,
            1010,
            true,
          ),

          blackBox("NIL \n Antibiotic", 600, 606, deployment, 450, 455, 660),
          tick(
            saleLot.draftingAttributes?.accreditationAntibioticFree ===
              Accreditation.ALL,
            662,
            608,
          ),
          blackBox("Never \nEver", 600, 606, deployment, 100, 105, 310),
          tick(
            saleLot.draftingAttributes?.accreditationNEE === Accreditation.ALL,
            312,
            608,
          ),
          blackBox("Weaned", 600, 625, deployment, 800, 805, 1010),
          tick(saleLot.draftingAttributes?.isWeaned, 1012, 608),
          {
            image: `data:image/jpeg;base64,${agrinousLogo}`,
            width: 100,
            absolutePosition: { x: 30, y: 778 },
          },
          {
            text: `${toTitleCase(
              saleLot.livestockAgency ? saleLot.livestockAgency.name : "",
            )} Pen: ${saleLot.penName ? saleLot.penName : ""} ${
              saleLot.vendor.shortCode ? saleLot.vendor.shortCode : ""
            } ${saleLot.vendor_name ? saleLot.vendor_name : ""}  ${formatTime(
              Date.now(),
            )}    -    TO BE SOLD AS DESCRIBED BY THE VENDOR, PARTICULARS FOR INFORMATION ONLY BUT NOT GUARANTEED.`,
            fontSize: 8,
            absolutePosition: { x: 140, y: 788 },
            // Insert a page break if there is another page.
            pageBreak: saleLots.length > index + 1 ? "after" : undefined,
          },
          {
            image: `logo_${deployment.id}`,
            absolutePosition: { x: 500, y: 40 },
            width: 800 * 0.8,
            height: 110 * 0.8,
          },
          {
            text: `Pen ${
              saleLot.auction_pen ? saleLot.auction_pen.start_pen : ""
            }`,
            fontSize: 80,
            bold: true,
            absolutePosition: { x: 50, y: 30 },
          },
        ],
      },
    ]);
  });
  return contents;
};

export const RenderPlacardV2 = () => {
  return (
    <RenderPlacard
      action={printPlacardV2}
      version={PlacardVersions.V2}
      size={PageSize.A3}
    />
  );
};
