import React from "react";

import { ModalTypes } from "constants/navigation";

import { SelectSyncDataModal } from "./SelectSyncDataModal";

export function SelectSyncDataAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.SelectSyncData] || null;
  const [businessId] = JSON.parse(hashParams);
  const { returnTo } = hash;

  return <SelectSyncDataModal businessId={businessId} returnTo={returnTo} />;
}
