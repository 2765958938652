import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { patchSaleLot } from "actions";

import { MultiButton } from "components/Button";
import { PreventPropagation } from "components/Events";

import {
  LotStatus,
  RCTI,
  TAX_INVOICE,
  TAX_RECEIPT,
} from "constants/clearingSaleAttributes";
import {
  ConsignmentModalSection,
  SaleLotModalSection,
} from "constants/navigation";
import { ConsignmentPermissions } from "constants/permissions";

import {
  getBillingRunBuyerInvoiceUrl,
  getBillingRunBuyerReceiptUrl,
  getBillingRunVendorInvoiceUrl,
  getClearingSaleBuyerInvoiceUrl,
  getClearingSaleBuyerReceiptUrl,
  getClearingSaleRecipientCreatedTaxInvoiceUrl,
  getLivestockSaleId,
  openBusinessPaymentsModal,
  openEditBusinessModal,
  openEditConsignmentModal,
  openEditSaleLotModal,
  openEmailReportModal,
} from "lib/navigation";

import { getActiveRole, getConsignmentById, getIsStaff } from "selectors";

import { useHasPermission } from "hooks/useHasPermission";

const CardOptionsMenu = ({ options }) => (
  <PreventPropagation>
    <MultiButton buttons={options} />
  </PreventPropagation>
);

export const SaleLotMenu = ({ saleLotId }) => {
  const dispatch = useDispatch();

  const edit = () =>
    openEditSaleLotModal(saleLotId, SaleLotModalSection.GENERAL);

  const unSell = () => {
    const patch = {
      total_price_cents: 0,
      buyer_id: null,
      buyer_way: null,
      id: saleLotId,
      clearing_sale_attributes: { status: LotStatus.ACTIVE },
    };
    dispatch(patchSaleLot(patch));
  };

  const options = [
    {
      title: "Edit Lot",
      onClick: edit,
      dataTour: "editLot",
    },
    {
      title: "Set as Paid",
      onClick: edit,
      dataTour: "setAsPaid",
    },
    {
      title: "Unsell",
      onClick: unSell,
      dataTour: "unSell",
    },
  ];

  return <CardOptionsMenu options={options} />;
};

export const ConsignmentMenu = ({ consignmentId, disableAdd }) => {
  const vendorId = useSelector(
    state => getConsignmentById(consignmentId)(state)?.vendor_id,
  );
  const userRoleSlug = useSelector(state => getActiveRole(state).slug);

  const isStaff = useSelector(getIsStaff);

  const editVendorConsignment = () =>
    openEditConsignmentModal(
      consignmentId,
      ConsignmentModalSection.GENERAL,
      true,
    );
  const editVendor = () => openEditBusinessModal(vendorId);

  const addSaleLot = () =>
    openEditSaleLotModal(
      null,
      SaleLotModalSection.GENERAL,
      "",
      consignmentId,
      null,
      window.location.hash,
    );

  const RCTIUrl = getClearingSaleRecipientCreatedTaxInvoiceUrl;

  const openBillingRunVendorInvoice = () => {
    window.open(
      getBillingRunVendorInvoiceUrl(
        getLivestockSaleId(),
        vendorId,
        userRoleSlug,
      ),
    );
  };

  const emailRCTI = () =>
    openEmailReportModal(
      RCTIUrl(vendorId),
      RCTI,
      vendorId,
      null,
      window.location.hash,
    );

  const openRCTI = () => {
    window.open(RCTIUrl(vendorId, userRoleSlug));
  };

  const hasUpdateConsignmentPermission = useHasPermission(
    getConsignmentById(consignmentId),
    ConsignmentPermissions.update,
  );

  const options = [
    {
      title: `${
        hasUpdateConsignmentPermission ? "Edit" : "View"
      } Vendor Consignment`,
      onClick: editVendorConsignment,
      dataTour: "editVendorConsignment",
    },
    {
      title: `View ${RCTI}`,
      onClick: openRCTI,
      dataTour: "recipientCreatedTaxInvoice",
    },
    isStaff && {
      title: `View Billing Run ${RCTI}`,
      onClick: openBillingRunVendorInvoice,
      dataTour: "recipientCreatedTaxInvoice",
    },
    {
      title: `Email ${RCTI}`,
      onClick: emailRCTI,
      dataTour: "emailRecipientCreatedTaxInvoice",
    },
    {
      title: "Business Details",
      onClick: editVendor,
      dataTour: "businessDetails",
    },
  ].filter(Boolean);

  if (!disableAdd) {
    options.unshift({
      title: "Add Lot",
      onClick: addSaleLot,
      dataTour: "addLot",
    });
  }
  return <CardOptionsMenu options={options} />;
};

export const BuyerMenu = ({ businessId }) => {
  const isStaff = useSelector(getIsStaff);

  const editBusiness = () => openEditBusinessModal(businessId);

  const openPayments = () => openBusinessPaymentsModal(businessId);

  const userRoleSlug = useSelector(state => getActiveRole(state).slug);

  const invoiceUrl = getClearingSaleBuyerInvoiceUrl;

  const receiptUrl = getClearingSaleBuyerReceiptUrl;

  const emailInvoice = () =>
    openEmailReportModal(
      invoiceUrl(businessId),
      TAX_INVOICE,
      businessId,
      null,
      window.location.hash,
    );

  const emailReceipt = () =>
    openEmailReportModal(
      receiptUrl(businessId),
      TAX_RECEIPT,
      businessId,
      null,
      window.location.hash,
    );

  const openInvoice = () => {
    window.open(invoiceUrl(businessId, userRoleSlug));
  };

  const openReceipt = () => {
    window.open(receiptUrl(businessId, userRoleSlug));
  };

  const openBillingRunBuyerInvoice = () => {
    window.open(
      getBillingRunBuyerInvoiceUrl(
        getLivestockSaleId(),
        businessId,
        userRoleSlug,
      ),
    );
  };

  const openBillingRunBuyerReceipt = () =>
    window.open(
      getBillingRunBuyerReceiptUrl(
        getLivestockSaleId(),
        businessId,
        userRoleSlug,
      ),
    );

  const options = [
    {
      title: `View ${TAX_INVOICE}`,
      onClick: openInvoice,
    },
    isStaff && {
      title: `View Billing Run ${TAX_INVOICE}`,
      onClick: openBillingRunBuyerInvoice,
    },
    isStaff && {
      title: `View Billing Run ${TAX_RECEIPT}`,
      onClick: openBillingRunBuyerReceipt,
    },
    {
      title: `Email ${TAX_INVOICE}`,
      onClick: emailInvoice,
    },
    {
      title: `View ${TAX_RECEIPT}`,
      onClick: openReceipt,
    },
    {
      title: `Email ${TAX_RECEIPT}`,
      onClick: emailReceipt,
    },
    {
      title: "Business Details",
      onClick: editBusiness,
      dataTour: "businessDetails",
    },
    {
      title: "Payments",
      onClick: openPayments,
    },
  ].filter(Boolean);

  return <CardOptionsMenu options={options} />;
};
