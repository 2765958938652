import { useEffect, useRef } from "react";

import { sortBy } from "lodash";
import isEqual from "lodash/isEqual";

import { RuleValueFormat, ValueSource } from "constants/ruleBooks";

import {
  ALL_VALUES_FILTER_PATH,
  CheckComparator,
  CUSTOM_QUERY_FIELD_ID,
  FieldValueTypeRuleValueFormatMap,
} from "./constants";

export function getPathName(value, key) {
  return `${typeof value === "string" ? value : ""} ${
    typeof key === "string" ? key : ""
  }`
    .trim()
    .replace(/rel:|id:/g, "")
    .replace(/[^A-Za-z=]/g, " ")
    .split(" ")
    .filter(Boolean)
    .join(" ");
}

export function getSchemaField(fieldId, schema) {
  return schema.find(field => field.id === fieldId) || null;
}

export function getSchemaFieldReverseRelations(
  fieldId,
  schema,
  includeMany = false,
) {
  const reverseRelations = [];
  const field = getSchemaField(fieldId, schema);
  if (!field) {
    return reverseRelations;
  }

  schema.forEach(otherField => {
    if (Array.isArray(otherField.relations)) {
      otherField.relations.forEach(relation => {
        if (fieldId === relation.id) {
          reverseRelations.push(otherField);
        }
      });
    }
  });

  if (includeMany) {
    const manyRelations = field.manyRelations || [];
    manyRelations.forEach(manyRelation => {
      reverseRelations.push(manyRelation);
    });
  }

  return reverseRelations;
}

function convertRelationToField(relation) {
  return {
    path: relation.via,
    id: relation.id,
    isProperty: false,
    isRelation: true,
    name: relation.name,
    uniq: relation.uniq,
  };
}

function convertPropertyToField(property) {
  return typeof property === "string"
    ? {
        path: property,
        id: property,
        isProperty: true,
        isRelation: false,
      }
    : {
        id: property.name,
        isProperty: true,
        isRelation: false,
        path: property.name,
        set: property.set,
        type: property.type,
      };
}

function convertReverseRelationToField(field) {
  return {
    path: `${field.path}${ALL_VALUES_FILTER_PATH}`,
    id: field.id,
    isProperty: false,
    isRelation: true,
  };
}

function convertManyRelationsToField(field) {
  return {
    path: `${field.via}${ALL_VALUES_FILTER_PATH}`,
    id: field.id,
    via: field.via,
    name: field.name,
    isProperty: false,
    isRelation: true,
  };
}

export function getSchemaFieldValues(
  fieldId,
  schema,
  includeReverseRelations = true,
  includeManyRelations = true,
) {
  const parentField = getSchemaField(fieldId, schema);

  if (!parentField) {
    return [];
  }

  const fieldProperties = parentField.properties || [];
  const fieldRelations = parentField.relations || [];
  const fieldManyRelations = includeManyRelations
    ? parentField.manyRelations || []
    : [];
  const reverseRelations = includeReverseRelations
    ? getSchemaFieldReverseRelations(fieldId, schema)
    : [];

  return sortBy(
    [].concat(
      fieldRelations.map(convertRelationToField),
      fieldProperties.map(convertPropertyToField),
      reverseRelations.map(convertReverseRelationToField),
      fieldManyRelations.map(convertManyRelationsToField),
    ),
    "id",
  );
}

export function resolveRootSchemaField(path, schema, parentFieldId = null) {
  const pathMatchingField = schema
    .map(field => ({
      path: `${field.path}${ALL_VALUES_FILTER_PATH}`,
      id: field.id,
      relations: field.relations,
      properties: field.properties,
    }))
    .find(
      field =>
        field.path === path &&
        (!parentFieldId ||
          field.relations.some(relation => relation.id === parentFieldId)),
    );

  if (pathMatchingField) {
    return pathMatchingField;
  }

  return null;
}

export function resolveRootRelatedField(path, schema, parentFieldId) {
  const field = resolveRootSchemaField(path, schema);

  if (!field) {
    return null;
  }

  if (field.relations) {
    const matchingRelation = field.relations.find(
      relation => relation.id === parentFieldId,
    );
    if (matchingRelation) {
      return field;
    }
  }
  return null;
}

export function resolveRelatedSchemaField(pathOrAttribute, schema, fieldId) {
  const schemaFields = getSchemaFieldValues(fieldId, schema);

  const pathMatchingField = schemaFields.find(
    field => field.path === pathOrAttribute,
  );

  if (pathMatchingField) {
    return pathMatchingField;
  }
  return null;
}

export function resolveReverseRelatedField(path, schema, parentFieldId) {
  const pathRootField = resolveRootSchemaField(path, schema);
  if (!pathRootField) {
    return null;
  }

  if (pathRootField.relations) {
    const matchingRelation = pathRootField.relations.find(
      relation => relation.id === parentFieldId,
    );
    if (matchingRelation) {
      return pathRootField;
    }
  }
  return null;
}

export function getParentFieldId(query, parentFieldId) {
  if (!query || !query.fieldId) {
    return parentFieldId;
  }
  return query.fieldId;
}

export function calcInputWidth(
  inputVal,
  { isSelect = false, isNumber = false } = {},
) {
  const normalisedInputVal =
    typeof inputVal === "string"
      ? inputVal
      : typeof inputVal === "number"
        ? inputVal.toString(10)
        : inputVal === null || inputVal === undefined
          ? ""
          : "TODO";

  const valueLength = normalisedInputVal.length;

  if (valueLength === 0) {
    return 15;
  }

  return valueLength + (isSelect ? 6 : 3) + (isNumber ? 2 : 0);
}

export function autoSizeInput(inputEl) {
  if (inputEl) {
    const isSelect = typeof inputEl.selectedIndex === "number";
    const isNumber = inputEl.type === "number";

    const displayValue = isSelect
      ? inputEl.selectedIndex > -1
        ? inputEl.options[inputEl.selectedIndex].label
        : ""
      : inputEl.value;

    inputEl.style.width = `${calcInputWidth(displayValue, {
      isSelect,
      isNumber,
    })}ch`;

    if (inputEl.style.transition !== "width 0.3s ease 0s") {
      inputEl.style.transition = "width 0.3s ease 0s";
    }
  }
}

export function onInputAutoSize(event) {
  autoSizeInput(event.target);
}

export function useAutoSizeInput() {
  const inputRef = useRef(null);
  const inputEl = inputRef.current;
  useEffect(() => {
    if (inputEl === null) {
      return;
    }

    inputEl.addEventListener("input", onInputAutoSize);

    autoSizeInput(inputEl);

    return () => {
      inputEl.removeEventListener("input", onInputAutoSize);
    };
  }, [inputEl]);
  return inputRef;
}

export const PropertyType = {
  INTEGER: 1,
  WEIGHT: 2,
  CURRENCY: 4,
  BOOLEAN: 8,
  BUSINESS: 16,
  BUSINESS_NAME: 32,
  TEXT: 64,
  LIST: 128,
  TAX_TYPE: 256,
  GL_CODE: 512,
};

export const PropertyTypeValueMap = {
  boolean: PropertyType.BOOLEAN,
  business: PropertyType.BUSINESS,
  currency: PropertyType.CURRENCY,
  currency_cents: PropertyType.CURRENCY,
  gl_code: PropertyType.TEXT,
  integer: PropertyType.INTEGER,
  list: PropertyType.LIST,
  tax_type: PropertyType.TEXT,
  text: PropertyType.TEXT,
  weight: PropertyType.WEIGHT,
  weight_grams: PropertyType.WEIGHT,
};

export const PropertyTypeInputTypeMap = {
  [PropertyType.BOOLEAN]: "checkbox",
  [PropertyType.BUSINESS]: "text",
  [PropertyType.CURRENCY]: "number",
  [PropertyType.INTEGER]: "number",
  [PropertyType.TEXT]: "text",
  [PropertyType.WEIGHT]: "number",
};

const CURRENCY_PROPS = {
  type: PropertyTypeInputTypeMap[PropertyType.CURRENCY],
  step: 0.0001,
};

const WEIGHT_PROPS = {
  type: PropertyTypeInputTypeMap[PropertyType.WEIGHT],
  step: 1,
};

const BOOLEAN_PROPS = {
  type: PropertyTypeInputTypeMap[PropertyType.BOOLEAN],
};

const INTEGER_PROPS = {
  type: PropertyTypeInputTypeMap[PropertyType.INTEGER],
  step: 1,
};

const TEXT_PROPS = {
  type: PropertyTypeInputTypeMap[PropertyType.TEXT],
};

const BUSINESS_PROPS = {
  type: PropertyTypeInputTypeMap[PropertyType.BUSINESS],
};

export const PropertyTypeInputPropsMap = {
  [PropertyType.BUSINESS]: BUSINESS_PROPS,
  [PropertyType.BOOLEAN]: BOOLEAN_PROPS,
  [PropertyType.CURRENCY]: CURRENCY_PROPS,
  [PropertyType.INTEGER]: INTEGER_PROPS,
  [PropertyType.TEXT]: TEXT_PROPS,
  [PropertyType.WEIGHT]: WEIGHT_PROPS,
};

export function getPropertyType(propertyOrRelation) {
  if (
    typeof propertyOrRelation === "object" &&
    typeof propertyOrRelation.type === "string"
  ) {
    return PropertyTypeValueMap[propertyOrRelation.type] || PropertyType.TEXT;
  }
  return PropertyType.TEXT;
}

export function getInputPropsForPropertyType(propertyType) {
  return PropertyTypeInputPropsMap[propertyType] || TEXT_PROPS;
}

const NUMBER_COMPARATORS = [
  CheckComparator.EQUALS,
  CheckComparator.NOT_EQUAL,
  CheckComparator.LESS_THAN,
  CheckComparator.GREATER_THAN,
  CheckComparator.LESS_THAN_OR_EQUAL,
  CheckComparator.GREATER_THAN_OR_EQUAL,
];

const TEXT_COMPARATORS = [
  CheckComparator.EQUALS,
  CheckComparator.NOT_EQUAL,
  CheckComparator.CONTAINS,
  CheckComparator.NOT_CONTAINS,
  CheckComparator.STARTS_WITH,
  CheckComparator.ENDS_WITH,
];

const LIST_COMPARATORS = [
  CheckComparator.CONTAINS,
  CheckComparator.NOT_CONTAINS,
];

const BOOLEAN_COMPARATORS = [CheckComparator.EQUALS, CheckComparator.NOT_EQUAL];

const BUSINESS_COMPARATORS = [
  CheckComparator.EQUALS,
  CheckComparator.NOT_EQUAL,
];

export const PropertyTypeCheckComparatorMap = {
  [PropertyType.INTEGER]: NUMBER_COMPARATORS,
  [PropertyType.TEXT]: TEXT_COMPARATORS,
  [PropertyType.WEIGHT]: NUMBER_COMPARATORS,
  [PropertyType.CURRENCY]: NUMBER_COMPARATORS,
  [PropertyType.BOOLEAN]: BOOLEAN_COMPARATORS,
  [PropertyType.BUSINESS]: BUSINESS_COMPARATORS,
  [PropertyType.LIST]: LIST_COMPARATORS,
};

function filterRelation(fieldId) {
  return field => field.via === fieldId || field.id === fieldId;
}

export function resolveDataSourceRelation(
  dataSource,
  updatedParentFieldId,
  existingParentFieldId,
  schema,
) {
  if (dataSource.source === ValueSource.FIELD) {
    let resolvedFieldId = dataSource.fieldId;

    if (dataSource.fieldId === CUSTOM_QUERY_FIELD_ID) {
      if (dataSource.customQuery) {
        // The existing queryfield is a custom query path. Try to resolve the path to a schema field
        const rootField = resolveRootSchemaField(
          dataSource.customQuery,
          schema,
        );

        if (rootField) {
          resolvedFieldId = rootField.id;
        }
      } else {
        resolvedFieldId = "";
      }
    }

    const updatedAttributeRelation = getSchemaFieldValues(
      updatedParentFieldId,
      schema,
    ).find(filterRelation(resolvedFieldId));

    const updatedFieldRelation = getSchemaFieldValues(
      updatedParentFieldId,
      schema,
    ).find(filterRelation(resolvedFieldId));

    const existingRelation = getSchemaFieldValues(
      existingParentFieldId,
      schema,
    ).find(filterRelation(resolvedFieldId));

    if (updatedFieldRelation) {
      return {
        attribute: dataSource.attribute,
        customQuery: "",
        fieldId: updatedFieldRelation.id || resolvedFieldId,
        via: updatedFieldRelation.via,
        source: ValueSource.FIELD,
      };
    } else if (updatedAttributeRelation && !resolvedFieldId) {
      if (updatedAttributeRelation.isProperty) {
        return {
          attribute: dataSource.attribute,
          customQuery: "",
          fieldId: "",
          source: ValueSource.FIELD,
        };
      } else if (updatedAttributeRelation.isRelation) {
        return {
          attribute: "",
          customQuery: "",
          fieldId: updatedAttributeRelation.via || updatedAttributeRelation.id,
          source: ValueSource.FIELD,
        };
      }
    } else if (
      existingRelation &&
      dataSource.fieldId !== "" &&
      dataSource.attribute !== ""
    ) {
      // The existing queryfield is resolved to a schema field, the resolved state has been lost, convert to a custom query path
      const existingRootField = getSchemaField(resolvedFieldId, schema);
      return {
        attribute: dataSource.attribute,
        customQuery: `${existingRootField.path}${ALL_VALUES_FILTER_PATH}`,
        fieldId: CUSTOM_QUERY_FIELD_ID,
        source: ValueSource.FIELD,
      };
    } else if (
      dataSource.fieldId !== CUSTOM_QUERY_FIELD_ID &&
      dataSource.fieldId !== "" &&
      dataSource.attribute !== ""
    ) {
      // When the new parent does not have an equivalent attribute or field, and it's not already a custom query path, convert it to a custom query path
      return {
        attribute: dataSource.attribute || "",
        customQuery: dataSource.fieldId
          ? `$${dataSource.fieldId}${ALL_VALUES_FILTER_PATH}`
          : "",
        fieldId: CUSTOM_QUERY_FIELD_ID,
        source: ValueSource.FIELD,
      };
    }
  }
  return dataSource;
}

export function resolveCheckRelations(
  check,
  updatedParentFieldId,
  existingParentFieldId,
  schema,
) {
  if (updatedParentFieldId !== existingParentFieldId) {
    return {
      ...check,
      field: resolveDataSourceRelation(
        check.field,
        updatedParentFieldId,
        existingParentFieldId,
        schema,
      ),
    };
  }

  return check;
}

export function updateCriterionRelations(
  criterion,
  updatedQuery,
  updatedParentFieldId,
  existingParentFieldId,
  schema,
) {
  const { fieldId: existingFieldId } = criterion.query || {};
  const query =
    updatedQuery === null
      ? null
      : resolveDataSourceRelation(
          { ...updatedQuery, source: ValueSource.FIELD },
          updatedParentFieldId,
          existingParentFieldId,
          schema,
        );

  const updatedCheckParentFieldId = query?.fieldId || updatedParentFieldId;
  const existingCheckParentFieldId = existingFieldId || existingParentFieldId;

  return {
    ...criterion,
    checkGroups: criterion.checkGroups.map(checkGroup =>
      checkGroup.map(check =>
        resolveCheckRelations(
          check,
          updatedCheckParentFieldId,
          existingCheckParentFieldId,
          schema,
        ),
      ),
    ),
    query,
  };
}

export function updateCriteriaRelations(
  criteria,
  updatedParentFieldId,
  existingParentFieldId,
  schema,
) {
  if (updatedParentFieldId !== existingParentFieldId) {
    return criteria.map(criterion =>
      updateCriterionRelations(
        criterion,
        criterion.query,
        updatedParentFieldId,
        existingParentFieldId,
        schema,
      ),
    );
  }
  return criteria;
}

export function resolveAttributeValueType(
  rootFieldId,
  parentFieldId,
  attribute,
  schema,
) {
  if (!attribute || (!rootFieldId && !parentFieldId)) {
    return null;
  }

  const rootFieldValues = getSchemaFieldValues(rootFieldId, schema) || [];
  const rootProperties = rootFieldValues.filter(field => field.isProperty);

  const parentFieldValues = getSchemaFieldValues(parentFieldId, schema) || [];
  const parentProperties = parentFieldValues.filter(field => field.isProperty);

  if (!rootProperties.length && !parentProperties.length) {
    return null;
  }

  const propertyFields = parentProperties.length
    ? parentProperties
    : rootProperties;

  const field = propertyFields.find(field => field.id === attribute);
  if (!field) {
    return null;
  }

  return field.type || null;
}

export function updateRuleRelations(rule, inputField, schema) {
  const { fieldId: existingFieldId } = rule.input_field;

  return {
    ...rule,
    input_field: inputField,
    criteria: updateCriteriaRelations(
      rule.criteria,
      inputField.fieldId,
      existingFieldId,
      schema,
    ),
    value: {
      ...rule.value,
      amount: resolveDataSourceRelation(
        rule.value.amount,
        inputField.fieldId,
        existingFieldId,
        schema,
      ),
      offset: resolveDataSourceRelation(
        rule.value.offset,
        inputField.fieldId,
        existingFieldId,
        schema,
      ),
      units: resolveDataSourceRelation(
        rule.value.units,
        inputField.fieldId,
        existingFieldId,
        schema,
      ),
    },
    from_business: resolveDataSourceRelation(
      rule.from_business,
      inputField.fieldId,
      existingFieldId,
      schema,
    ),
    to_business: resolveDataSourceRelation(
      rule.to_business,
      inputField.fieldId,
      existingFieldId,
      schema,
    ),
  };
}

export const RoundingMethod = {
  CEILING: "CEILING",
  DOWN: "DOWN",
  FLOOR: "FLOOR",
  HALF_EVEN: "HALF_EVEN",
  HALF_DOWN: "HALF_DOWN",
  HALF_UP: "HALF_UP",
  LAST_05_UP: "LAST_05_UP",
  NONE: "NONE",
  UP: "UP",
};

export function getValueFormatByFieldType(
  fieldType,
  defaultRuleValueFormat = RuleValueFormat.UNITS,
) {
  return FieldValueTypeRuleValueFormatMap[fieldType] || defaultRuleValueFormat;
}

export const RoundingMethodOptions = [
  { value: RoundingMethod.NONE, label: "None" },
  { value: RoundingMethod.UP, label: "Up" },
  { value: RoundingMethod.DOWN, label: "Down" },
  { value: RoundingMethod.HALF_UP, label: "Half Up" },
  { value: RoundingMethod.HALF_DOWN, label: "Half Down" },
  { value: RoundingMethod.HALF_EVEN, label: "Half Even" },
  { value: RoundingMethod.CEILING, label: "Ceiling" },
  { value: RoundingMethod.FLOOR, label: "Floor" },
  { value: RoundingMethod.LAST_05_UP, label: "Last 5 Up" },
];

export function compareDynamicFieldOption(field) {
  return option => isEqual(option.value, field.value);
}

export function mapOptionToConstantFieldOption(option) {
  return {
    label: option.label,
    value: {
      source: ValueSource.CONSTANT,
      value: option.value,
    },
  };
}
