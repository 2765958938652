import { combineReducers } from "redux";

import {
  GET_BREEDS_SUCCESS,
  GET_SEXES_SUCCESS,
  GET_AGES_SUCCESS,
  GET_GRADES_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  GET_EXEMPTIONS_SUCCESS,
  GET_ROUNDS,
  GET_SPECIES,
  GET_DENTITION,
} from "constants/actionTypes";

import { apiModelReducer, createSimpleReducer } from "lib/reducers";
import { deserializeSex } from "lib/serializers/sex";
import { deserializeSpecies } from "lib/serializers/species";

const breeds = createSimpleReducer("breeds", GET_BREEDS_SUCCESS);
const sexes = createSimpleReducer(
  "sexes",
  GET_SEXES_SUCCESS,
  true,
  deserializeSex,
);
const ages = createSimpleReducer("ages", GET_AGES_SUCCESS);
const grades = createSimpleReducer("grades", GET_GRADES_SUCCESS);
const categories = createSimpleReducer("categories", GET_CATEGORIES_SUCCESS);
const exemptions = createSimpleReducer("exemptions", GET_EXEMPTIONS_SUCCESS);
const rounds = apiModelReducer("rounds", GET_ROUNDS, { includeOrder: true });
const species = createSimpleReducer(
  "species",
  GET_SPECIES.SUCCESS,
  false,
  deserializeSpecies,
);
const dentition = createSimpleReducer("dentition", GET_DENTITION.SUCCESS);

export default combineReducers({
  breeds,
  sexes,
  ages,
  grades,
  categories,
  exemptions,
  rounds,
  species,
  dentition,
});
