import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Button } from "components/Form";

import { ForStaff } from "containers/ForUserType";

import { getActiveRole } from "selectors";

import { useToggle } from "hooks";

import { RoleIcon } from "./RoleIcon";

const RoleOption = styled.div`
  display: flex;
  background-color: white;
  &:nth-child(even) {
    background-color: ${props => props.theme.colors.grayF7};
  }
`;

const RoleInput = styled.input`
  width: 0px;
  display: none;
`;

const RoleLabel = styled.label(
  ({ theme, selected }) => `
padding: ${theme.space[1]}px ${theme.space[3]}px;
font-size: ${theme.fontSizes.beta}px;
cursor: pointer;
flex: 1;
:hover {
  background-color: ${theme.colors.primaryHighlight};
  color: ${theme.colors.white};
}
background: ${selected ? theme.colors.primaryActive : "inherit"};
color: ${selected ? theme.colors.white : "inherit"};
`,
);

export function RoleList(props) {
  const { roles, onRoleChange } = props;

  const activeRole = useSelector(getActiveRole);

  const [showAll, toggleShowAll] = useToggle(false);
  const INITIAL_SHOWN = 25;

  const rolesToShow = showAll ? roles : roles.slice(0, INITIAL_SHOWN);

  const numHidden = roles.length - rolesToShow.length;

  return (
    <>
      {rolesToShow.map(role => {
        const changeRole = () => {
          onRoleChange(role);
        };

        return (
          <RoleOption data-tour={role.username} key={role.slug}>
            <RoleLabel
              selected={role.slug === activeRole.slug ? "selected" : ""}
              onClick={changeRole}
            >
              <RoleIcon role={role.type} />
              &nbsp;
              <ForStaff>{role.username} -&nbsp;</ForStaff>
              {role.name}
            </RoleLabel>
          </RoleOption>
        );
      })}
      {!showAll && numHidden > 0 && (
        <Button onClick={toggleShowAll}>Show remaining {numHidden}</Button>
      )}
    </>
  );
}

RoleOption.displayName = "RoleOption";
RoleInput.displayName = "RoleInput";
RoleLabel.displayName = "RoleLabel";
