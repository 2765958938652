import React from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import {
  deleteAttachment,
  patchConsignment,
  setAdditionalPICs,
  updateAttachment,
  updateDeclaration,
  uploadRequest,
  createPlaceholderAttachment,
} from "actions";

import AttachmentsView from "components/AttachmentsView";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { ScreenTypes } from "constants/screen";

import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
} from "lib/navigation";

import { getSales } from "selectors";

// TODO Remove this components, and move the props logic into mapStateToProps
const Attachments = ({
  screenType,
  updateDeclaration,
  setAdditionalPICs,
  updateAttachment,
  deleteAttachment,
  uploadRequest,
  createPlaceholderAttachment,
  sales,
  match,
  history,
  patchConsignment,
}) => {
  const redirectToConsignments = () => {
    const saleyard = getSaleyardName();
    const saleId = getLivestockSaleId();
    history.push(`${getSaleyardAuctionRoute(saleyard, saleId)}/consignments`);
  };

  return (
    <WaitForSync requiredData={[ApiModel.BUSINESSES, ApiModel.CONSIGNMENTS]}>
      <AttachmentsView
        updateDeclaration={updateDeclaration}
        setAdditionalPICs={setAdditionalPICs}
        updateAttachment={updateAttachment}
        deleteAttachment={deleteAttachment}
        uploadRequest={uploadRequest}
        createPlaceholderAttachment={createPlaceholderAttachment}
        sales={sales}
        match={match}
        isMobile={screenType === ScreenTypes.MOBILE}
        history={history}
        redirectToConsignments={redirectToConsignments}
        patchConsignment={patchConsignment}
      />
    </WaitForSync>
  );
};

const mapStateToProps = state => ({
  screenType: state.screen.type,
  sales: getSales(state),
});

const mapDispatchToProps = {
  updateDeclaration,
  updateAttachment,
  setAdditionalPICs,
  uploadRequest,
  deleteAttachment,
  patchConsignment,
  createPlaceholderAttachment,
};

Attachments.propTypes = {
  screenType: PropTypes.string,
  updateDeclaration: PropTypes.func.isRequired,
  setAdditionalPICs: PropTypes.func.isRequired,
  patchConsignment: PropTypes.func.isRequired,
  updateAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  uploadRequest: PropTypes.func,
  sales: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
