import React, { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { allocateConsignmentEidsToPen } from "actions";

import { Button, SecondaryButton } from "components/Form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import {
  getExpandedEndPenDisplayName,
  getExpandedStartPenDisplayName,
} from "lib/auctionPens";

import { currentSaleSelector, selectConsignmentIdByEidLookup } from "selectors";

import { ConsignmentsList } from "./ConsignmentsList";
import { BulkCreateSaleLotsLayout } from "./Layout";
import { SaleLotDefaults } from "./SaleLotDefaults";

export function BulkCreateSaleLotsModal(props) {
  const { closeSelf, eids = [], isOpen, onAfterSave } = props;

  const consignmentIdByEid = useSelector(selectConsignmentIdByEidLookup);
  const pricingTypeId = useSelector(currentSaleSelector).pricing_type_id;
  // Location to store form values for use outside of the Formik context
  const [formValues, setFormValues] = useState(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const dispatch = useDispatch();

  const allocatableEids = useMemo(
    () =>
      eids
        .map(eid => ({
          eid,
          consignmentId: consignmentIdByEid[eid],
        }))
        .filter(summaryItem => summaryItem.consignmentId),
    [eids, consignmentIdByEid],
  );

  const consignmentsSummary = useMemo(
    () =>
      allocatableEids.reduce((acc, summaryItem) => {
        const eids = acc[summaryItem.consignmentId];
        if (Array.isArray(eids)) {
          eids.push(summaryItem.eid);
        } else {
          acc[summaryItem.consignmentId] = [summaryItem.eid];
        }
        return acc;
      }, {}),
    [allocatableEids],
  );

  // event handler for when the Formik values have changed
  const onSaleLotDefaultsChanged = useCallback(
    (values, isValid, isDirty) => {
      if (isDirty && isValid) {
        setFormValues(values);
      }
    },
    [setFormValues],
  );

  function onClickSave() {
    const {
      buyer_id: buyerId,
      destination_property_id: destinationPropertyId,
      overflowPen,
      overflowQuantity,
      round_id: roundId,
    } = formValues;

    const auctionPenMatch = {
      start_pen: getExpandedStartPenDisplayName(formValues),
      end_pen: getExpandedEndPenDisplayName(formValues),
    };

    const saleLotMatch = {
      buyer_id: buyerId,
      destination_property_id: destinationPropertyId,
      overflowPen,
      overflowQuantity,
      pricing_type_id: pricingTypeId,
      quantity: 0,
      round_id: roundId,
    };
    const allocations = Object.keys(consignmentsSummary).reduce(
      (acc, consignmentId) => {
        acc.push({
          auctionPenMatch,
          consignmentId,
          eids: consignmentsSummary[consignmentId],
          saleLotMatch,
        });
        return acc;
      },
      [],
    );
    dispatch(
      allocateConsignmentEidsToPen(allocations, {
        onSuccess: () => {
          typeof onAfterSave === "function" &&
            onAfterSave(allocatableEids.map(({ eid }) => eid));
          closeSelf();
        },
      }),
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeSelf}
      maxWidth="lg"
      fullWidth
      scroll="paper"
    >
      <DialogTitle onClose={closeSelf}>Bulk Create Sale Lots</DialogTitle>
      <DialogContent form>
        <BulkCreateSaleLotsLayout>
          <ConsignmentsList summary={consignmentsSummary} />
          <SaleLotDefaults
            onChange={onSaleLotDefaultsChanged}
            setIsSubmitEnabled={setIsSubmitEnabled}
          />
        </BulkCreateSaleLotsLayout>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={closeSelf}>Close</SecondaryButton>
        <Button disabled={!isSubmitEnabled} onClick={onClickSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
BulkCreateSaleLotsModal.propTypes = {
  closeSelf: PropTypes.func.isRequired,
  eids: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAfterSave: PropTypes.func,
};
