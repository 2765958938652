import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { updateDeploymentAttributes } from "actions";

import { UPDATE_DEPLOYMENT_DENTITION } from "constants/actionTypes";

import Table from "containers/Settings/DeploymentAttributesTable";

import { getDeploymentAttributes } from "selectors";

import { getActiveSpeciesAttributes } from "selectors/speciesAttributes";

const columnDefs = [
  { headerName: "Name", field: "name", rowDrag: true, editable: false },
  { headerName: "Display Value", field: "display_value", editable: false },
  { headerName: "Age", field: "age", editable: false },
  {
    headerName: "Use",
    field: "is_used",
    cellRenderer: "checkboxRenderer",
    editable: false,
  },
];

export const DentitionSettings = () => {
  const dentition = Object.values(
    useSelector(getActiveSpeciesAttributes).dentition,
  );

  const deploymentData = Object.values(
    useSelector(getDeploymentAttributes).dentition,
  );

  const dispatch = useDispatch();

  const handleUpdate = payload =>
    dispatch(updateDeploymentAttributes(UPDATE_DEPLOYMENT_DENTITION, payload));
  return (
    <Table
      dataKey="dentition_id"
      baseData={dentition}
      deploymentData={deploymentData}
      handleUpdate={handleUpdate}
      columnDefs={columnDefs}
    />
  );
};
