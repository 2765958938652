import React from "react";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { CenteredGreedy } from "components/Layout";

export const CollapseIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => ` 
  font-size: ${theme.fontSizes.delta}px;
  color: ${theme.colors.gray40};
  cursor: pointer;
  `};
`;

const OpenIndicatorComponent = ({ isOpen, onClick }) => (
  <CenteredGreedy onClick={onClick}>
    <CollapseIcon icon={isOpen ? faChevronUp : faChevronDown} />
  </CenteredGreedy>
);

export const OpenIndicator = React.memo(OpenIndicatorComponent);
