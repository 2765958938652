import styled from "styled-components/macro";

export const ActionButton = styled.button(
  ({ theme }) => `
  margin: 0 ${theme.space[1]}px;
  padding: ${theme.space[1]}px;
  background-color: transparent;
  border: none;
  border-radius: ${theme.radii[3]}px;
  &:hover:enabled {
    background-color: ${theme.colors.blueHighlight};
  }
`,
);

export const ActionButtonContainer = styled.div`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.space[0]}px;
  margin: ${({ theme }) => `0 -${theme.space[1]}px;`};
  grid-auto-flow: column;
  justify-content: flex-start;

  // If we are using the Container, dont use the margin on the Button, handle spacing with grid-gap
  ${ActionButton} {
    margin: 0;
  }
`;
