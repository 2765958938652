import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import { ContextMenuPopoverButton } from "components/Button/MultiButton";

import { SELECT_COLUMN_ID, SELECT_COLUMN_NAME } from "constants/aggrid";

import {
  enableHeaderSelection,
  enableRowSelection,
} from "lib/agGrid/rowSelection";

import { useIsMobile } from "hooks";

export function AgGridActionButtons({ actions }) {
  const isMobile = useIsMobile();
  if (!actions || !actions.length) {
    return null;
  }

  const shownActions = actions.filter(action => !action.hide);

  if (isMobile) {
    return (
      <ActionButtonContainer>
        <ContextMenuPopoverButton buttons={shownActions} />
      </ActionButtonContainer>
    );
  } else {
    return (
      <ActionButtonContainer>
        {shownActions.map(action => (
          <ActionButton
            data-tour={action.dataTour}
            type="button"
            onClick={action.onClick}
            disabled={action.isDisabled}
            key={action.title}
          >
            {action.icon && <FontAwesomeIcon icon={action.icon} />}
            {action.title}
          </ActionButton>
        ))}
      </ActionButtonContainer>
    );
  }
}

export const SelectColumnDefinition = {
  headerName: SELECT_COLUMN_NAME,
  colId: SELECT_COLUMN_ID,
  width: 40,
  pinned: "left",
  checkboxSelection: enableRowSelection,
  headerCheckboxSelection: enableHeaderSelection,
  headerCheckboxSelectionFilteredOnly: true,
  suppressCellFlash: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
  showDisabledCheckboxes: true,
};

export const SelectCurrentPageOnlyColumnDefinition = {
  ...SelectColumnDefinition,
  // headerCheckboxSelectionCurrentPageOnly: true, // AgGrid v29.1.0+
};
