import styled from "styled-components/macro";

export const SelectInput = styled.div(
  ({ theme, disabled }) => `
  cursor: ${disabled ? "not-allowed" : "pointer"};
  color: ${disabled ? theme.colors.grayAE : theme.colors.black};
  background: ${disabled ? theme.colors.grayF7 : theme.colors.white};
  position: relative;
  border: 1px solid ${theme.colors.gray78};
  border-radius: ${theme.radii[3]}px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  min-height: 36px;
  align-items: center;
`,
);

export const Expander = styled.div(
  ({ theme }) => `
  align-self: center;
  color: ${theme.colors.gray40};
  margin-left: auto;
  justify-content: center;
  display: flex;
`,
);
