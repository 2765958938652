import React from "react";

import { Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
  importMtHostSessions,
  sendDeviceRequestListWeighingSessions,
  setSetting,
} from "actions";

import { Button, SecondaryButton } from "components/Form";
import { Row } from "components/Layout";
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  ZoomyDialog,
} from "components/MaterialDialog";

import { ModalTypes } from "constants/navigation";
import { Settings } from "constants/settings";

import {
  getConnectedMtHostSessions,
  getMtHostSettings,
  selectAvailableMtHostSessions,
} from "selectors";

import { useModalAdapter, useMountEffect } from "hooks";

import DeviceCollapseSection from "./SettingsSection";

const validationSchema = Yup.object();

const WeightSettingsSections = ({ onClose }) => {
  const [activeSection, setActiveSection] = React.useState(null);

  const dispatch = useDispatch();

  const availableMtHostSessions = useSelector(selectAvailableMtHostSessions);

  const formikProps = useFormikContext();

  const { handleSubmit, values } = formikProps;

  const handleImportWeights = () => {
    dispatch(importMtHostSessions(values));
    handleSubmit();
  };

  const allInUseSessionsHaveSelectedFile = Object.values(values).every(
    session => (session.inUse ? session.fileName !== null : true),
  );

  const isAtleastOneSessionInUse = Object.values(values).some(
    session => !!session.inUse,
  );

  const allowSubmit =
    !!availableMtHostSessions.length &&
    !!isAtleastOneSessionInUse &&
    !!allInUseSessionsHaveSelectedFile;

  return (
    <ZoomyDialog open onClose={onClose} fullWidth scroll="paper">
      <DialogTitle onClose={onClose}>
        <Row justifyBetween alignCenter>
          <Row alignCenter>Weight Import Settings</Row>
        </Row>
      </DialogTitle>
      <DialogContent dividers form>
        {availableMtHostSessions.length ? (
          availableMtHostSessions.map((device, idx) => (
            <DeviceCollapseSection
              device={device}
              id={idx}
              isOpen={activeSection === idx}
              onToggle={() =>
                activeSection === idx
                  ? setActiveSection(null)
                  : setActiveSection(idx)
              }
            />
          ))
        ) : (
          <Row padding={2}>
            No devices available - Please ensure your AgriNous Hub is configured
            correctly.
          </Row>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton data-tour="cancel" type="button" onClick={onClose}>
          Cancel
        </SecondaryButton>
        <Button
          data-tour="submit"
          type="submit"
          onClick={handleSubmit}
          disabled={!allowSubmit}
        >
          Submit
        </Button>
        <Button
          data-tour="submit"
          type="sumit"
          disabled={!allowSubmit}
          onClick={handleImportWeights}
        >
          Submit and Import
        </Button>
      </DialogActions>
    </ZoomyDialog>
  );
};

export const WeightSettingsModal = props => {
  const [_ignored1, _ignored2, onClose] = useModalAdapter(
    ModalTypes.WeightSettings,
    props,
  );

  const dispatch = useDispatch();

  const connectedMtHostSessions = useSelector(getConnectedMtHostSessions);

  const mtHostSessionDeviceIds = Object.keys(connectedMtHostSessions);

  useMountEffect(() => {
    dispatch(sendDeviceRequestListWeighingSessions(mtHostSessionDeviceIds));
  });

  const onSubmit = values => {
    const newValues = {};
    Object.entries(values).forEach(([deviceId, values]) => {
      newValues[deviceId] = values;
    });
    dispatch(setSetting(Settings.mtHostSettings, newValues));
    onClose();
  };

  const settingsByDeviceId = useSelector(getMtHostSettings);

  const initialValues = {};

  Object.keys(connectedMtHostSessions).forEach(deviceId => {
    const storedSettingsForDevice = settingsByDeviceId[deviceId];
    const inUse = storedSettingsForDevice?.inUse || false;
    const fileName = storedSettingsForDevice?.fileName || null;

    initialValues[deviceId] = {
      inUse,
      fileName,
    };
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <WeightSettingsSections onClose={onClose} />
    </Formik>
  );
};
