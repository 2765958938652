import React from "react";

import Grid from "@material-ui/core/Grid";
import { getAges, getBreeds, getRounds, getSexes } from "selectors";
import { Box } from "@material-ui/core";
import { useIsMobile } from "hooks";
import { SaleProgressTotalsAttributeAccordion } from "./SaleProgressTotalsAttributeAccordion";

export function SaleProgressTotalsTables(): React.JSX.Element {
  const isMobile = useIsMobile();
  return (
    <>
      <Grid item xs={12}>
        <Box px={isMobile ? 2 : 0}>
          <h2>Sale Totals by Attribute:</h2>
          <p>Select an attribute to view details</p>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SaleProgressTotalsAttributeAccordion
          name="Round"
          saleLotAttributeField="sale_round_id"
          attributeLookupSelector={getRounds}
          square={isMobile}
        />
        <SaleProgressTotalsAttributeAccordion
          name="Age"
          saleLotAttributeField="age_id"
          attributeLookupSelector={getAges}
        />
        <SaleProgressTotalsAttributeAccordion
          name="Breed"
          saleLotAttributeField="breed_id"
          attributeLookupSelector={getBreeds}
        />
        <SaleProgressTotalsAttributeAccordion
          name="Sex"
          saleLotAttributeField="sex_id"
          attributeLookupSelector={getSexes}
          square={isMobile}
        />
      </Grid>
    </>
  );
}
