import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const masterLabelSerializeMap = {
  id: "id",
  name: "name",
  order: "order",
  quickSelect: "quick_select",
  speciesId: "species_id",
  isManualChargeLabel: "is_manual_charge_label",
  deprecated: "deprecated",
};

export const serializeMasterLabel = buildSerializer(
  masterLabelSerializeMap,
  "masterLabel",
);

export const deserializeMasterLabel = buildDeserializer(
  masterLabelSerializeMap,
  "masterLabel",
);
