import React from "react";

import styled from "styled-components/macro";

import { ConsignmentModalSection } from "constants/navigation";

import { openEditConsignmentModal } from "lib/navigation";

export const CellButton = styled.button(
  ({ theme }) => `
  background: none;
  border: none;
  height: 100%;
  width: 100%;
  text-align: inherit;
  color: inherit;
  font: inherit;
  transition: ${theme.transitions[1]};
  
  &:active, &:hover  {
    color: ${theme.colors.white};
  }
  &:hover {
    background-color: ${theme.colors.primaryHighlight};
  }
  &:active {
    background-color: ${theme.colors.primaryActive};
  }
  &[disabled] {
    background-color: inherit;
    color: inherit;
    cursor: inherit;
  }
`,
);

export function ExceptionsCell(props) {
  const { value } = props;
  return (
    <span title={value.map(exception => `\u2022 ${exception}`).join("\n")}>
      {value.join(", ")}
    </span>
  );
}
function ClickableExceptionsCell(consignmentModalSection) {
  return function Exeptions(props) {
    const { original } = props;
    return (
      <CellButton
        onClick={() =>
          openEditConsignmentModal(
            original.consignmentId,
            consignmentModalSection,
            true,
          )
        }
      >
        {ExceptionsCell(props)}
      </CellButton>
    );
  };
}

export const OpenGeneralExceptionsCell = ClickableExceptionsCell(
  ConsignmentModalSection.GENERAL,
);
