import React, { useState } from "react";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ScanAction } from "actions";

import { SlimButton } from "components/Button";
import { Chip, MarkChips } from "components/Chip";
import { Button, SecondaryButton } from "components/Form";
import { Column, Row } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { BoldText } from "components/Text";

import { getLivestockSaleId } from "lib/navigation";
import { scanLotConfig } from "lib/scanLot";

import { getAuctionPens } from "selectors";

export const WarningIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faExclamationTriangle,
  size: "3x",
}))`
  color: ${({ theme }) => theme.colors.warning};
`;

const DiscrepancyTableRow = ({
  penType,
  idx,
  duplicateScans,
  scan,
  showAllEids,
  setShowAllEids,
}) => {
  const {
    selectLotIdByEidLookup,
    selectLotByIdLookup,
    lotPenIdReference,
    lotMarksReference,
  } = scanLotConfig(penType);

  const auctionPenByIdLookup = useSelector(getAuctionPens);

  const lotIdByEidLookup = useSelector(selectLotIdByEidLookup);

  const lotByIdLookup = useSelector(selectLotByIdLookup);
  const lotId = lotIdByEidLookup[scan.EID];
  const lot = lotByIdLookup[lotId];
  const penName = auctionPenByIdLookup[lot?.[lotPenIdReference]]?.start_pen;
  const mark = lot?.[lotMarksReference];
  if (showAllEids || idx < 5) {
    return (
      <>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Chip>{scan.EID}</Chip>
          </Grid>
          <Grid item xs={3}>
            <Chip>{penName}</Chip>
          </Grid>
          <Grid item xs={3}>
            {typeof mark === "string" ? (
              <Chip>{mark}</Chip>
            ) : (
              <MarkChips marks={mark} />
            )}
          </Grid>
        </Grid>
        {((!showAllEids && idx === 4) ||
          (showAllEids && idx === duplicateScans.length - 1)) && (
          <Grid container item xs={12}>
            <SlimButton fullWidth onClick={() => setShowAllEids(!showAllEids)}>
              {showAllEids ? "Show Less" : "Show More"}
            </SlimButton>
          </Grid>
        )}
      </>
    );
  }
};

const DiscrepancyDialogFooter = ({
  lotFormUnFilled,
  onClear,
  onClearDuplicates,
  onClose,
  onAddToScanLot,
  penId,
  penArchetypeId,
  scanLotId,
  scans,
  penType,
  onClickClose,
  duplicateScans,
  scanLotValues,
}) => {
  const formikProps = useFormikContext();

  const dispatch = useDispatch();

  const { scanReference, lotAction } = scanLotConfig(penType);

  const { resetForm } = formikProps;

  function clearDuplicates() {
    // clear duplicate eids and go back to scanning screen
    onClearDuplicates();
    onClose();
  }

  const handleClear = doNotWarn => {
    typeof onClear === "function" && onClear(doNotWarn);
    onClickClose();
  };

  const clearWithWarning = () => {
    handleClear(false);
  };

  const handleSave = () => {
    if (scanLotId) {
      onAddToScanLot({ scans: duplicateScans });
      dispatch(
        ScanAction.create(
          duplicateScans.map(scan => ({
            EID: scan.EID,
            [scanReference]: scanLotId,
            livestock_sale_id: getLivestockSaleId(),
          })),
        ),
      );
    } else {
      dispatch(
        lotAction.updateOrCreateWithPenAndScans(
          scanLotId,
          penId,
          penArchetypeId,
          scanLotValues,
          scans,
          duplicateScans,
        ),
      );
    }
    resetForm();
  };

  function saveNewScansAndDuplicates() {
    // saved new scans - move duplicate scans
    handleSave();
    handleClear(true);
  }

  return (
    <DialogActions>
      {lotFormUnFilled ? (
        <>
          <SecondaryButton
            data-tour="go-back-to-scanning"
            onClick={onClickClose}
          >
            Go Back
          </SecondaryButton>
          <Button data-tour="clear-scans" onClick={clearWithWarning}>
            Clear Scans
          </Button>
        </>
      ) : (
        <>
          <SecondaryButton
            data-tour="do-not-move-duplicate-scans"
            onClick={clearDuplicates}
          >
            No
          </SecondaryButton>
          <Button
            data-tour="move-duplicate-scans"
            onClick={saveNewScansAndDuplicates}
          >
            Yes
          </Button>
        </>
      )}
    </DialogActions>
  );
};

export function ScanDiscrepancyDialog(props) {
  const {
    duplicateScans,
    scans,
    penType,
    isOpen,
    onClose,
    scanLotId,
    penId,
    penArchetypeId,
    scanLotValues,
    onClear,
    onClearDuplicates,
    onAddToScanLot,
  } = props;

  const [showAllEids, setShowAllEids] = useState(false);

  const { lotMarksReference, lotTitle } = scanLotConfig(penType);

  const [lotFormUnFilled, setLotFormUnfilled] = React.useState(false);

  function onClickClose() {
    typeof onClose === "function" && onClose();
  }

  React.useEffect(() => {
    if (!scanLotId && !scanLotValues[lotMarksReference]) {
      setLotFormUnfilled(true);
    } else if (lotFormUnFilled) {
      setLotFormUnfilled(false);
    }
  }, [scanLotId, scanLotValues, lotMarksReference, lotFormUnFilled]);

  return (
    <Dialog fullWidth open={!!isOpen} onClose={onClickClose}>
      <DialogTitle onClose={onClickClose}>Scan Discrepancy</DialogTitle>
      <DialogContent dividers form>
        <Column padding={2}>
          {lotFormUnFilled ? (
            `Please Go Back to fill in ${lotTitle}s form before proceeding. Alternatively Clear Scans.`
          ) : (
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <WarningIcon />
              </Grid>
              <Grid item xs={10} className="p-2">
                <BoldText>
                  This lot contains{" "}
                  {duplicateScans.length === 1
                    ? "an animal"
                    : `${duplicateScans.length} animals`}{" "}
                  belonging to another lot.
                </BoldText>
                <Row fullWidth className="mt-12">
                  <BoldText>
                    Do you want to move{" "}
                    {duplicateScans.length === 1
                      ? "this animal"
                      : `these ${duplicateScans.length} animals`}{" "}
                    into this lot?
                  </BoldText>
                </Row>
                <div className="mt-12">
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <BoldText>EID</BoldText>
                    </Grid>
                    <Grid item xs={3}>
                      <BoldText> Pen</BoldText>
                    </Grid>
                    <Grid item xs={3}>
                      <BoldText> Marks</BoldText>
                    </Grid>
                  </Grid>

                  {duplicateScans.map((scan, idx) => (
                    <DiscrepancyTableRow
                      scan={scan}
                      idx={idx}
                      penType={penType}
                      duplicateScans={duplicateScans}
                      showAllEids={showAllEids}
                      setShowAllEids={setShowAllEids}
                    />
                  ))}
                </div>
              </Grid>
            </Grid>
          )}
        </Column>
      </DialogContent>
      <DiscrepancyDialogFooter
        lotFormUnFilled={lotFormUnFilled}
        onClear={onClear}
        onClearDuplicates={onClearDuplicates}
        onClose={onClose}
        onAddToScanLot={onAddToScanLot}
        penId={penId}
        penArchetypeId={penArchetypeId}
        scanLotId={scanLotId}
        scans={scans}
        penType={penType}
        onClickClose={onClickClose}
        duplicateScans={duplicateScans}
        scanLotValues={scanLotValues}
      />
    </Dialog>
  );
}

ScanDiscrepancyDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  scanLotId: PropTypes.string,
  duplicateScans: PropTypes.array,
  scans: PropTypes.array,
  penType: PropTypes.string,
  penId: PropTypes.oneOf([PropTypes.string]),
  penArchetypeId: PropTypes.oneOf([PropTypes.string]),
  scanLotValues: PropTypes.object,
  onClear: PropTypes.func,
  onClearDuplicates: PropTypes.func,
  onAddToScanLot: PropTypes.func,
};
