import React, { useEffect, useMemo, useState } from "react";

import { PropTypes } from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";

import { SlimButton } from "components/Button";
import { Label } from "components/Form/FormikControls";
import { Column } from "components/Layout";
import MessageBox from "components/MessageBox";
import { ReactSelect } from "components/SearchableSelector";

import {
  NVDDocumentTypeOptions,
  NVDRelatedDocumentTypes,
} from "constants/documentTypes";

import { filterBySaleType } from "lib/attachments";

import { getCurrentSale, selectAttachmentsByConsignmentId } from "selectors";

import { ConsignmentAttachmentForm } from "./ConsignmentAttachmentForm";
import { Declaration } from "./Declaration";
import { declarationVersions } from "./speciesDeclarations";

export const AttachmentForm = ({
  updateDeclaration,
  setAdditionalPICs,
  selectedAttachment,
  updateAttachment,
  getNextAttachment,
  patchConsignment,
  handleGoBack,
  setConfirmBrandUpdateDialogProps,
}) => {
  const speciesId = useSelector(state => getCurrentSale(state).species_id);
  const saleType = useSelector(state => getCurrentSale(state).sale_type);

  const speciesHasNVD = !!declarationVersions[speciesId];

  const [documentType, selectDocumentType] = useState(
    NVDRelatedDocumentTypes.NVD,
  );

  const deploymentSaleId =
    (selectedAttachment && selectedAttachment.deploymentSale) || null;

  const attachmentsByConsignmentIdLookup = useSelector(
    selectAttachmentsByConsignmentId,
  );

  const consignmentHasNVD = useMemo(() => {
    // Find out if the consignment has a attachment other than the selected
    // attachment that is of type NVD.
    if (selectedAttachment?.consignment) {
      return attachmentsByConsignmentIdLookup[
        selectedAttachment.consignment
      ].find(
        a =>
          a &&
          a.type === NVDRelatedDocumentTypes.NVD &&
          a.id !== selectedAttachment.id,
      );
    }
  }, [selectedAttachment, attachmentsByConsignmentIdLookup]);

  const documentTypeOptions = useMemo(
    () =>
      NVDDocumentTypeOptions.filter(filterBySaleType(saleType)).map(option => ({
        ...option,
        isDisabled:
          option.value === NVDRelatedDocumentTypes.NVD && !!consignmentHasNVD,
      })),
    [consignmentHasNVD, saleType],
  );

  useEffect(() => {
    // If there is already a NVD attachment on the consignment, default to
    // Other document type and disable the nvd from the select.
    if (selectedAttachment) {
      const defaultDocument = consignmentHasNVD
        ? NVDRelatedDocumentTypes.OTHER
        : NVDRelatedDocumentTypes.NVD;
      selectDocumentType(selectedAttachment.type || defaultDocument);
    }
  }, [selectedAttachment, consignmentHasNVD]);

  const selectedDocumentType = useMemo(
    () => documentTypeOptions.find(option => option.value === documentType),
    [documentType, documentTypeOptions],
  );

  const DocumentSelector = () => (
    <Column fullWidth>
      <Label htmlFor="documentType">Document Type</Label>
      <ReactSelect
        value={selectedDocumentType}
        options={documentTypeOptions}
        onChange={s => selectDocumentType(s.value)}
        labelledBy="documentType"
      />
    </Column>
  );

  if (!selectedAttachment) {
    return (
      <MessageBox>
        <Column>
          No attachment selected
          <SlimButton onClick={handleGoBack}>Go back</SlimButton>
        </Column>
      </MessageBox>
    );
  }

  if (documentType === NVDRelatedDocumentTypes.NVD && speciesHasNVD) {
    return (
      <Scrollbars>
        <Declaration
          deploymentSaleId={deploymentSaleId}
          selectedAttachment={selectedAttachment}
          updateDeclaration={updateDeclaration}
          updateAttachment={updateAttachment}
          documentType={documentType}
          getNextAttachment={getNextAttachment}
          setAdditionalPICs={setAdditionalPICs}
          patchConsignment={patchConsignment}
          DocumentSelector={DocumentSelector}
          handleGoBack={handleGoBack}
          setConfirmBrandUpdateDialogProps={setConfirmBrandUpdateDialogProps}
        />
      </Scrollbars>
    );
  }
  return (
    <ConsignmentAttachmentForm
      deploymentSaleId={deploymentSaleId}
      selectedAttachment={selectedAttachment}
      updateAttachment={updateAttachment}
      documentType={documentType}
      getNextAttachment={getNextAttachment}
      DocumentSelector={DocumentSelector}
      handleGoBack={handleGoBack}
    />
  );
};

AttachmentForm.propTypes = {
  updateDeclaration: PropTypes.func.isRequired,
  setAdditionalPICs: PropTypes.func.isRequired,
  updateAttachment: PropTypes.func.isRequired,
  getNextAttachment: PropTypes.func.isRequired,
  patchConsignment: PropTypes.func.isRequired,
  selectedAttachment: PropTypes.object,
  handleGoBack: PropTypes.func.isRequired,
};
