import React, { useState } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { Button, SecondaryButton } from "components/Form";
import { Fieldset } from "components/Form/Fieldset";
import { SelectField, useSubmitHandler } from "components/Form/FormikControls";
import { CenteredGreedy } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import {
  ValidationSchema,
  ValidationSchemaWithRuleBookId,
} from "components/RuleTester/Modal/validationSchema";
import { RuleTestResults } from "components/RuleTester/Results";

import { ModalTypes } from "constants/navigation";

import {
  getContextByModalType,
  getIsFetchingRuleBooks,
  selectRuleBookOptions,
  selectSaleOptionsV2,
} from "selectors";

import { useModalAdapter } from "hooks";
import { useRuleTester } from "hooks/useRuleTester";

export function Footer(props) {
  const { onClose } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  useSubmitHandler(true, setIsSubmitEnabled);

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      <Button
        data-tour="testRuleBook"
        type="submit"
        disabled={!isSubmitEnabled}
        title="Test RuleBook"
      >
        Test RuleBook
      </Button>
    </>
  );
}

function RuleTesterForm(props) {
  const {
    onClose,
    onSubmit,
    ruleBookId,
    livestockSaleIds,
    useContextRuleData,
  } = props;

  const livestockSaleOptions = useSelector(selectSaleOptionsV2);
  const isLoadingRuleBooks = useSelector(getIsFetchingRuleBooks);

  const ruleBookOptions = useSelector(selectRuleBookOptions);

  const initialValues = {
    ruleBookId,
    livestockSaleIds,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={
        useContextRuleData ? ValidationSchema : ValidationSchemaWithRuleBookId
      }
    >
      <Form data-tour="rule-tester">
        <DialogContent dividers>
          <Fieldset>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {useContextRuleData ? (
                  <SelectField
                    disabled
                    label="Rulebook"
                    name="ruleBookPlaceholder"
                    placeholder="Testing Unsaved Rulebook"
                    tooltip="This will test the data from the rulebook you have not saved yet."
                  />
                ) : (
                  <SelectField
                    disabled={isLoadingRuleBooks}
                    label="Rulebook"
                    menuPortalTarget={document.body}
                    name="ruleBookId"
                    options={ruleBookOptions}
                    required
                    tooltip="Select an existing rulebook to run test."
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  isClearable
                  menuPortalTarget={document.body}
                  name="livestockSaleIds"
                  label="Sale"
                  options={livestockSaleOptions}
                  isMulti
                  required
                  tooltip="Select one or more sales to run this rulebook against."
                />
              </Grid>
            </Grid>
          </Fieldset>
        </DialogContent>
        <DialogActions>
          <Footer onClose={onClose} isLoadingRules={isLoadingRuleBooks} />
        </DialogActions>
      </Form>
    </Formik>
  );
}

function RuleTesterModal(props) {
  const { onClose, livestockSaleIds, ruleBookId, useContextRuleData } = props;

  const [results, isLoadingResults, testRules] = useRuleTester();

  const ruleBookData = useSelector(
    getContextByModalType(ModalTypes.RuleTester),
  );

  function onSubmit(values) {
    testRules(
      values.livestockSaleIds,
      useContextRuleData ? undefined : values.ruleBookId,
      ruleBookData,
    );
  }

  return (
    <Dialog open onClose={onClose} fullScreen>
      <DialogTitle onClose={onClose}>Test RuleBook</DialogTitle>

      {isEmpty(results) && !isLoadingResults ? (
        <RuleTesterForm
          onClose={onClose}
          onSubmit={onSubmit}
          livestockSaleIds={livestockSaleIds}
          ruleBookId={ruleBookId}
          useContextRuleData={useContextRuleData}
        />
      ) : null}
      {isLoadingResults ? (
        <CenteredGreedy>
          <LoadingSpinner />
        </CenteredGreedy>
      ) : null}
      {!isEmpty(results) ? <RuleTestResults results={results} /> : null}
    </Dialog>
  );
}

function RuleTesterModalAdapter(props) {
  const [hashParams, ignored, onClose] = useModalAdapter(
    ModalTypes.RuleTester,
    props,
  );

  const [livestockSaleIds, ruleBookId, useContextRuleData] = hashParams;

  return (
    <RuleTesterModal
      onClose={onClose}
      livestockSaleIds={livestockSaleIds}
      ruleBookId={ruleBookId}
      useContextRuleData={useContextRuleData}
    />
  );
}

export default RuleTesterModalAdapter;
