import React from "react";

import {
  faCheck,
  faExclamationCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

import { LPA_ACCREDITED_PROGRAMS } from "constants/nlis";

export function erpStatusRenderer(props) {
  if (!props.data) {
    return null;
  }
  const { value } = props;

  if (value) {
    if (LPA_ACCREDITED_PROGRAMS.includes(value)) {
      return <FaIcon icon={faCheck} color="success" title={value} />;
    } else {
      return (
        <FaIcon icon={faExclamationCircle} color="warning" title={value} />
      );
    }
  }
  return <FaIcon icon={faQuestionCircle} color="primary" />;
}
