import React, { useState } from "react";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import DesktopSideBar from "components/Sidebar/DesktopSideBar";
import { SidebarListItem } from "components/Sidebar/SidebarListItem";

import { useIsMobile } from "hooks";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  iconSidebar: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
}));

export function FocusedLayout(props) {
  const { children, SidebarContent } = props;

  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.root}>
      <DesktopSideBar
        isBrandingVisible
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        ContentElement={SidebarContent}
      />
      {children}
    </div>
  );
}
FocusedLayout.propTypes = {
  SidebarContent: PropTypes.elementType,
  children: PropTypes.node,
};

export function FocusedSidebarBackButton(props) {
  const { backUrl, onAfterBack } = props;
  const history = useHistory();
  function onClickBack() {
    history.push(backUrl);
    typeof onAfterBack === "function" && onAfterBack();
  }

  return (
    <SidebarListItem onClick={onClickBack} icon={faChevronLeft} label="Back" />
  );
}

FocusedSidebarBackButton.propTypes = {
  backUrl: PropTypes.string.isRequired,
  onAfterBack: PropTypes.func,
};
