import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { openConfirmModal, closeConfirmModal } from "actions";

import { Button, SecondaryButton } from "components/Form";
import { Column } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import NLISLogo from "img/NLIS-logo.png";

const ConfirmModalContainer = ({
  isOpen,
  closeConfirmModal,
  modalProps: {
    title,
    message,
    actions = [],
    timeOut,
    maxWidth = "sm",
    nlisSubmitting,
    icon = null,
    color = null,
  },
}) => {
  useEffect(() => {
    if (timeOut) {
      setTimeout(() => {
        closeConfirmModal();
      }, timeOut);
    }
  });
  const dispatch = useDispatch();

  return isOpen ? (
    <Dialog
      open={!!isOpen}
      onClose={closeConfirmModal}
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogTitle onClose={closeConfirmModal}>
        {icon && <FontAwesomeIcon icon={icon} color={color} />}
        {title}
      </DialogTitle>
      <DialogContent dividers form>
        <Column padding={2}>
          {nlisSubmitting ? (
            <SubmittingWrapper>
              <NLISImg />
              <SubmittingText>
                <h4>Submitting Transfer</h4>
              </SubmittingText>
            </SubmittingWrapper>
          ) : (
            <div style={{ whiteSpace: "pre-line" }}>{message}</div>
          )}
        </Column>
      </DialogContent>
      <DialogActions>
        {actions.map(({ label, onClick, secondary, onClickAction }) => {
          const dispatchOnClickAction = () => {
            if (typeof onClickAction === "function") {
              dispatch(onClickAction());
            }
          };
          return secondary ? (
            <SecondaryButton
              key={label}
              data-tour={label}
              onClick={
                typeof onClick === "function" ? onClick : dispatchOnClickAction
              }
            >
              {label}
            </SecondaryButton>
          ) : (
            <Button
              key={label}
              data-tour={label}
              onClick={
                typeof onClick === "function" ? onClick : dispatchOnClickAction
              }
            >
              {label}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  ) : null;
};

const mapDispatchToProps = {
  openConfirmModal,
  closeConfirmModal,
};

const mapStateToProps = state => ({
  isOpen: state.modals.context.confirmModal.isOpen,
  modalProps: state.modals.context.confirmModal.modalProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmModalContainer);

const SubmittingWrapper = styled.div`
  padding: ${({ theme }) => theme.space[4]}px;
`;

const SubmittingText = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.space[3]}px;
`;

const NLISImg = styled.img.attrs({
  src: NLISLogo,
  alt: "Submitting Transfer",
})`
  display: flex;
  margin: auto;
`;
