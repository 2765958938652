import { createSelector } from "reselect";

import { saleLotStatuses } from "constants/sale";

import { comparePens } from "lib/auctionPens";
import { uniqueByPropertyFilter } from "lib/filters";

import { getSaleLotsBySale } from "selectors/saleLots";

import { getSaleLots } from "./root";

export const getBuyerIdsFromSaleLots = createSelector([getSaleLots], saleLots =>
  Object.values(saleLots).map(saleLot => saleLot.buyer_id),
);

export const selectRecentBuyers = createSelector(
  [getSaleLotsBySale],
  saleLots => {
    const recentBuyers = [
      ...saleLots
        // Sort by auction start_pen
        .sort((saleLotA, saleLotB) =>
          comparePens(
            saleLotA.auction_pen?.start_pen || "",
            saleLotB.auction_pen?.start_pen || "",
          ),
        ),
    ]
      .reverse()
      .filter(saleLot => saleLot.status !== saleLotStatuses.NO_SALE)
      // Just want the buyer
      .map(saleLot => saleLot.buyer)
      // And only if it exists.
      .filter(buyer => buyer && buyer.id);
    return recentBuyers.filter(uniqueByPropertyFilter("id"));
  },
);
