import { put, takeEvery } from "redux-saga/effects";
import { Action } from "redux";

import { closeConfirmModal, openConfirmModal } from "actions";

import { REPORT_JOB } from "constants/actionTypes";

import { getReportJobList, openReportJobsList } from "lib/navigation";
import toast from "lib/toast";

import history from "appHistory";
import { pluralize } from "lib/pluralize";

function* onBulkCreateReportJobsSuccess(action) {
  const { suppressSuccessPrompt } = action;
  if (suppressSuccessPrompt) {
    return;
  }
  if (!Array.isArray(action.payload)) {
    // payload should always be an array of report jobs.
    // If it's not, something is wrong. Still avoid a crash
    return;
  }
  const reportJobCount = action.payload.length;

  if (reportJobCount === 0) {
    toast.info("No Reports were created.");
    return;
  }

  if (history.location.pathname === getReportJobList()) {
    // Don't prompt the user to redirect when they are already on the Report Jobs page
    // They get a success toast and if they're on the page, AgGrid should show
    // them with the flashing on changes feature
    toast.success(
      `${reportJobCount} ${pluralize("Report", reportJobCount)} created.`,
    );
    return;
  }

  yield put(
    openConfirmModal({
      actions: [
        { label: "Close", secondary: true, onClickAction: closeConfirmModal },
        {
          label: "View Reports",
          onClickAction: () => {
            openReportJobsList();
            return closeConfirmModal();
          },
        },
      ],
      title: `${pluralize("Report", reportJobCount)} Created`,
      message: `${reportJobCount} ${pluralize("Report", reportJobCount)} have been created. You can view them on the Reports page.`,
    }) as Action,
  );
}

export default function* reportJobsSaga() {
  yield takeEvery(
    REPORT_JOB.CREATE_BULK.SUCCESS,
    onBulkCreateReportJobsSuccess,
  );
}
