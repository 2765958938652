export const getVendorSplitKey = ({
  primaryBusinessId,
  deploymentId,
  saleLotId,
  consignmentId,
  livestockSaleId,
}) =>
  [
    primaryBusinessId,
    deploymentId,
    saleLotId,
    consignmentId,
    livestockSaleId,
  ].join("|");

export const getBestMatchingVendorSplit = ({
  vendorSplitByKeyLookup,
  primaryBusinessId,
  deploymentId,
  livestockSaleId,
  consignmentId,
  saleLotId,
}) => {
  const saleLotSplitKey = saleLotId
    ? getVendorSplitKey({
        primaryBusinessId,
        deploymentId,
        livestockSaleId,
        saleLotId,
      })
    : null;

  const consignmentSplitKey = getVendorSplitKey({
    primaryBusinessId,
    deploymentId,
    livestockSaleId,
    consignmentId,
  });

  const saleSplitKey = getVendorSplitKey({
    primaryBusinessId,
    deploymentId,
    livestockSaleId,
  });

  return (
    vendorSplitByKeyLookup[saleLotSplitKey] ||
    vendorSplitByKeyLookup[consignmentSplitKey] ||
    vendorSplitByKeyLookup[saleSplitKey]
  );
};
