import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { printSheepPlacard } from "actions";

import OpenedInNewWindow from "components/Pdfs/OpenedInNewWindow";

import { Accreditation } from "constants/draftingAttributes";

import { sortByRoundAndPen } from "lib/auctionPens";
import {
  blackBox,
  breakword,
  createAndOpenPdf,
  fontSize,
  getSecondarySheepInformation,
  getSheepInformation,
  getVendorName,
  tick,
} from "lib/pdf";
import { formatTime } from "lib/timeFormats";

import {
  getIsFetchingAgencies,
  getIsFetchingAuctionPens,
  getIsFetchingConsignments,
  getIsFetchingSaleLots,
  getIsFetchingScans,
} from "selectors";

import agrinousLogo from "img/AgriNous-logo-black-white-base64";

const pageMargin = 12;
const pageMarginBottom = 12;

const getContent = (
  saleLots,
  deploymentIdBySaleLotIdLookup,
  deployments,
  vendorDeploymentBusinessBySaleLotIdLookup,
) => {
  const contents = [];
  sortByRoundAndPen(saleLots).forEach((saleLot, index) => {
    const deploymentBusiness =
      vendorDeploymentBusinessBySaleLotIdLookup[saleLot.id];
    const vendorName = getVendorName(saleLot, deploymentBusiness);
    const deploymentId = deploymentIdBySaleLotIdLookup[saleLot.id];
    const deployment = deployments[deploymentId];

    contents.push([
      {
        layout: "noBorders",
        table: {
          widths: [825],
          body: [
            [""],
            [
              {
                text: " ",
              },
            ],
            [
              {
                image: `logo_${deployment.id}`,
                width: 800,
                height: 110,
                alignment: "center",
              },
            ],

            [
              {
                width: 90,
                text: breakword(vendorName, 26),
                fontSize: fontSize(vendorName),
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                width: 90,
                text: saleLot.consignment?.vendor_property.district
                  ? saleLot.consignment?.vendor_property.district.toUpperCase()
                  : "",
                fontSize: 50,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: saleLot.quantity ? saleLot.quantity : "\n",
                fontSize: 125,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                text: `${saleLot.breed_name} ${
                  saleLot.sex_name !== "MIXED" && saleLot.quantity !== 1
                    ? saleLot.sex_name
                      ? `${saleLot.sex_name}S`
                      : ""
                    : saleLot.sex_name
                }`.toUpperCase(),
                fontSize: 50,
                alignment: "center",
                bold: true,
              },
            ],
            [
              {
                width: 50,
                text: getSheepInformation(saleLot),
                fontSize: 28,
                alignment: "center",
              },
            ],
            [
              {
                text: getSecondarySheepInformation(saleLot),
                fontSize: 28,
                alignment: "center",
              },
            ],
            [
              {
                text: `${
                  saleLot.draftingAttributes?.publicDescription
                    ? saleLot.draftingAttributes.publicDescription.toUpperCase()
                    : ""
                }`,
                fontSize: 40,
                alignment: "center",
              },
            ],
          ],
        },
      },
      {
        columns: [
          {
            canvas: [
              {
                type: "rect",
                x: 20,
                y: 15,
                w: 1136,
                h: 790,
                lineWidth: 10,
                lineColor: deployment.corporateColor
                  ? deployment.corporateColor
                  : "#000000",
              },
            ],
            absolutePosition: { x: 4, y: 4 },
          },
          blackBox("Gudair / \n Approved", 34, 37, deployment, 840, 849, 1050),
          tick(
            saleLot.draftingAttributes?.ojdVac === Accreditation.ALL,
            1052,
            34,
          ),
          tick(
            saleLot.draftingAttributes?.gudairApproved === Accreditation.ALL,
            1082,
            44,
          ),
          blackBox("M&TS", 144, 166, deployment, 840, 845, 1050),
          tick(
            saleLot.draftingAttributes?.MAndTS === Accreditation.ALL,
            1052,
            154,
          ),
          blackBox("Pain Relief", 254, 280, deployment, 840, 845, 1050),
          tick(
            saleLot.draftingAttributes?.painRelief === Accreditation.ALL,
            1052,
            264,
          ),
          blackBox("6 in 1", 364, 390, deployment, 840, 845, 1050),
          tick(
            saleLot.draftingAttributes?.sixInOne === Accreditation.ALL,
            1052,
            374,
          ),
          blackBox("B12 Vac", 474, 497, deployment, 840, 845, 1050),
          tick(
            saleLot.draftingAttributes?.b12Vac === Accreditation.ALL,
            1052,
            484,
          ),
          blackBox("Drenched", 584, 607, deployment, 840, 845, 1050),
          tick(
            saleLot.draftingAttributes?.drenched === Accreditation.ALL,
            1052,
            594,
          ),
          blackBox(
            "Backline / \n Dipped",
            694,
            700,
            deployment,
            840,
            845,
            1050,
          ),
          tick(
            saleLot.draftingAttributes?.backline === Accreditation.ALL,
            1052,
            704,
          ),
          {
            image: `data:image/jpeg;base64,${agrinousLogo}`,
            width: 100,
            absolutePosition: { x: 30, y: 778 },
          },
          {
            text: `Pen: ${saleLot.penName ? saleLot.penName : ""} ${
              saleLot.vendor.shortCode ? saleLot.vendor.shortCode : ""
            } ${saleLot.vendor_name ? saleLot.vendor_name : ""}  ${formatTime(
              Date.now(),
            )}    -    TO BE SOLD AS DESCRIBED BY THE VENDOR, PARTICULARS FOR INFORMATION ONLY BUT NOT GUARANTEED.`,
            fontSize: 8,
            absolutePosition: { x: 140, y: 788 },
            // Insert a page break if there is another page.
            pageBreak: saleLots.length > index + 1 ? "after" : undefined,
          },
        ],
      },
    ]);
  });
  return contents;
};

/**
 *
 * @param {Object} saleLots
 * @param {Object} sale
 * @param {String} saleLotId
 * @return {Document}
 */

export const SheepPlacardpdf = (
  saleLots,
  sale,
  deploymentIdBySaleLotIdLookup,
  deployments,
  vendorDeploymentBusinessBySaleLotIdLookup,
  deploymentPlacardLogos,
) => {
  const deploymentLogoImages = sale.deployment_sales.reduce(
    (acc, deploymentSale) => {
      acc[`logo_${deploymentSale.deployment_id}`] =
        deploymentPlacardLogos[deploymentSale.deployment_id];
      return acc;
    },
    {},
  );

  const pdfDefinition = {
    pageSize: "A3",
    pageOrientation: "landscape",
    pageMargins: [pageMargin, pageMargin, pageMargin, pageMarginBottom],
    content: getContent(
      saleLots,
      deploymentIdBySaleLotIdLookup,
      deployments,
      vendorDeploymentBusinessBySaleLotIdLookup,
    ),
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 20,
      },
      centerImage: {
        alignment: "center",
      },
      contentStyle: {
        fontSize: 40,
        overflow: "hidden",
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin: [30, 30, 15, 15],
      },
    },
    images: deploymentLogoImages,
  };

  createAndOpenPdf(pdfDefinition);
};

export const SheepPlacard = () => {
  const isFetchingSaleLots = useSelector(getIsFetchingSaleLots);
  const isFetchingConsignments = useSelector(getIsFetchingConsignments);
  const isFetchingAuctionPens = useSelector(getIsFetchingAuctionPens);
  const isFetchingScans = useSelector(getIsFetchingScans);
  const isFetchingAgencies = useSelector(getIsFetchingAgencies);
  const { params } = useRouteMatch();
  const dispatch = useDispatch();
  const { saleLotId, round, agencyId } = params;
  // http://localhost:3000/saleyard/Horsham/sale/10/sheepplacard/consolidated/3 for consolidated
  // path="/saleyard/:saleyard/sale/:saleId/sheepplacard/:saleLotId?/:round?"
  const isLoading =
    isFetchingSaleLots ||
    isFetchingConsignments ||
    isFetchingAuctionPens ||
    isFetchingScans ||
    isFetchingAgencies;
  useEffect(() => {
    if (!isLoading) {
      dispatch(printSheepPlacard(saleLotId, round, agencyId));
    }
  }, [dispatch, isLoading, saleLotId, round, agencyId]);
  return (
    <OpenedInNewWindow
      isLoading={isLoading}
      message="The Placard was opened in a new window."
    />
  );
};
