import styled from "styled-components/macro";

import { SecondaryStyles } from "./Styles";

const SlimButton = styled.button`
  ${({
    theme,
    disabled,
    color,
    fullWidth,
    verticalMargin,
    borderRadius = 1,
  }) => `  
  opacity: ${disabled ? 0.25 : 1};
  ${disabled ? "cursor: not-allowed;" : "cursor: pointer;"};
  background: ${
    color && theme.colors[color] ? theme.colors[color] : theme.colors.primary
  };
  margin: 0.3em;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.white};
  text-decoration: none;
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    outline: none;
  }
  ${theme.active}
  &:active {
    background: rgba(200, 200, 200, 0.59);
  }
  :not(:active) {
    transition: all linear 300ms;
  }
  color: ${theme.colors.white};
  min-height: 2em;
  padding: ${theme.space[1]}px ${theme.space[2]}px;
  ${
    verticalMargin
      ? `margin-top: ${theme.space[verticalMargin]}px; margin-bottom: ${theme.space[verticalMargin]}px;`
      : ""
  }
  ${fullWidth ? "width: 100%;" : ""}
   margin: 0px;
   border-radius: ${
     typeof borderRadius === "number"
       ? `${theme.radii[borderRadius]}px`
       : borderRadius
   };
   &:hover:enabled {
      background: ${theme.colors.primaryHighlight};
   }
   transition: ${theme.transitions[0]};
  
  `}
`;

export default SlimButton;

export const SlimSecondaryButton = styled(SlimButton)(SecondaryStyles);
