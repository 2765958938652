import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSaleProgressSetting } from "selectors";
import Grid from "@material-ui/core/Grid";
import { Checkbox } from "@material-ui/core";
import { setSaleProgressSetting } from "actions";
import { SaleProgressSetting } from "constants/saleProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export function SaleProgressSettings(): React.JSX.Element {
  const dispatch = useDispatch();
  const shouldExcludeNoSaleSaleLots =
    useSelector(
      getSaleProgressSetting(SaleProgressSetting.ExcludeNoSaleSaleLots),
    ) || false;
  const shouldExcludeDeceasedSaleLots =
    useSelector(
      getSaleProgressSetting(SaleProgressSetting.ExcludeDeceasedSaleLots),
    ) || false;
  const shouldExcludeWithdrawnSaleLots =
    useSelector(
      getSaleProgressSetting(SaleProgressSetting.ExcludeWithdrawnSaleLots),
    ) || false;

  function onClickSettingFactory(setting: SaleProgressSetting) {
    return (event: React.MouseEvent) => {
      dispatch(
        setSaleProgressSetting(
          setting,
          (event.target as HTMLInputElement).checked,
        ),
      );
    };
  }

  const onClickExcludeNoSaleSaleLots = onClickSettingFactory(
    SaleProgressSetting.ExcludeNoSaleSaleLots,
  );
  const onClickExcludeDeceasedSaleLots = onClickSettingFactory(
    SaleProgressSetting.ExcludeDeceasedSaleLots,
  );
  const onClickExcludeWithdrawnSaleLots = onClickSettingFactory(
    SaleProgressSetting.ExcludeWithdrawnSaleLots,
  );

  const excludeNoSaleControl = (
    <Checkbox
      checked={shouldExcludeNoSaleSaleLots}
      color="primary"
      id={SaleProgressSetting.ExcludeNoSaleSaleLots}
      name={SaleProgressSetting.ExcludeNoSaleSaleLots}
      onClick={onClickExcludeNoSaleSaleLots}
    />
  );

  const excludeDeceasedControl = (
    <Checkbox
      checked={shouldExcludeDeceasedSaleLots}
      color="primary"
      id={SaleProgressSetting.ExcludeDeceasedSaleLots}
      name={SaleProgressSetting.ExcludeDeceasedSaleLots}
      onClick={onClickExcludeDeceasedSaleLots}
    />
  );

  const excludeWithdrawnControl = (
    <Checkbox
      checked={shouldExcludeWithdrawnSaleLots}
      color="primary"
      id={SaleProgressSetting.ExcludeWithdrawnSaleLots}
      name={SaleProgressSetting.ExcludeWithdrawnSaleLots}
      onClick={onClickExcludeWithdrawnSaleLots}
    />
  );

  return (
    <>
      <Grid item lg={6}>
        <FormControlLabel
          control={excludeNoSaleControl}
          label="Exclude No Sale"
        />
      </Grid>
      <Grid item lg={6}>
        <FormControlLabel
          control={excludeDeceasedControl}
          label="Exclude Deceased"
        />
      </Grid>
      <Grid item lg={6}>
        <FormControlLabel
          control={excludeWithdrawnControl}
          label="Exclude Withdrawn"
        />
      </Grid>
    </>
  );
}
