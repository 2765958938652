import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { FullScreenContainer } from "components/FullScreenContainer";

import { Settings } from "constants/settings";

import { getLastSeenSaleLotsByDraftingId, getSetting } from "selectors";

import DraftingInformationSwitcher from "./DraftingInformationSelector";
import {
  AverageWeight,
  BoundingBox,
  CentsPerKilo,
  DollarsPerHead,
} from "./WatcherComponents";

const HighContrastTable = styled.table`
  ${({ theme }) => `
  border-collapse: collapse;
  font-size: ${theme.fontSizes.theta}px;
  background-color: ${theme.colors.primary}; 
  color: ${theme.colors.grayF3};
  
  width: 100%;
  height: 100%;

  td {
    width: 50%;
    padding 0 ${theme.space[2]}px;
    font-weight: bold; 
  }
  
  `}
`;

export const OnScalesView = () => {
  const draftingInformationId = useSelector(
    getSetting(Settings.saleWatcherDraftingInformationId),
  );

  const lastSeenLots = useSelector(
    getLastSeenSaleLotsByDraftingId(draftingInformationId),
  );
  const onScalesLot = lastSeenLots?.[0] || {};

  return (
    <FullScreenContainer>
      <BoundingBox>
        <DraftingInformationSwitcher />
        <HighContrastTable>
          <tbody>
            <tr>
              <td>PEN NUMBER</td>
              <td className="current">{onScalesLot.penName || "-"}</td>
            </tr>

            <tr>
              <td>PAINT MARK</td>
              <td className="current">
                {onScalesLot.marks?.map(mark => mark.location).join("/") || "-"}
              </td>
            </tr>
            <tr>
              <td>CENTS/Kg</td>
              <td className="current">
                <CentsPerKilo saleLot={onScalesLot} noSymbol />
              </td>
            </tr>
            <tr>
              <td>AVERAGE Kg</td>
              <td className="current">
                <AverageWeight saleLot={onScalesLot} includeUnits={false} />
              </td>
            </tr>
            <tr>
              <td>AVERAGE $</td>
              <td className="current">
                <DollarsPerHead saleLot={onScalesLot} noSymbol />
              </td>
            </tr>
          </tbody>
        </HighContrastTable>
      </BoundingBox>
    </FullScreenContainer>
  );
};
