import React from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components/macro";

import { useIsMobile } from "hooks";

// Use && to increase specificity to get around having to do !important.
// https://github.com/styled-components/styled-components/issues/1253#issuecomment-337871693
const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: ${({ theme }) => theme.space[1]}px;
    top: ${({ theme }) => theme.space[1]}px;
    color: ${({ theme }) => theme.colors.gray78};
    width: 48px;
    height: 48px;
  }
`;

const StyledDialogTitle = styled(({ hasClose: ignored, ...other }) => (
  <MuiDialogTitle {...other} />
))`
  && {
    ${({ theme, hasClose }) => `
    margin: ${hasClose ? `0 40px 0 0` : "0"};
    padding: ${theme.space[2]}px;
    `}
`;

export const ColoredFontAwesomeIcon = styled(FontAwesomeIcon)`
  ${({ theme, color }) => (color ? `color: ${theme.colors[color]}` : "")};
`;

export const DialogTitle = ({
  children,
  onClose,
  icon = null,
  iconColor = null,
  ...other
}) => (
  <StyledDialogTitle disableTypography hasClose={Boolean(onClose)} {...other}>
    <Typography variant="h6">
      {icon ? (
        <>
          <ColoredFontAwesomeIcon icon={icon} color={iconColor} />
          &nbsp;
        </>
      ) : null}
      {children}
    </Typography>
    {onClose ? (
      <CloseButton data-tour="closeDialog" aria-label="close" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
    ) : null}
  </StyledDialogTitle>
);

export const DialogContent = styled(
  ({
    form: ignored,
    shrink: ignored2,
    minHeight: ignored3,
    noHorizontalPadding: ignored4,
    ...other
  }) => <MuiDialogContent {...other} />,
)(
  // formContent indicates that this will contain an accordion, which we want to
  // use as much space as is available, so don't add spacing to the top/bottom
  ({ theme, form, shrink, minHeight, noHorizontalPadding }) => `
  && {
    padding: ${theme.space[form ? 0 : 2]}px ${theme.space[noHorizontalPadding ? 0 : 2]}px;
    ${typeof shrink === "number" ? `flex-shrink: ${shrink};` : ""}
    ${minHeight ? `min-height: ${minHeight};` : ""}    
  }
`,
);

export const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    border-radius: 0;
    ${({ height }) => (height ? `height: ${height};` : "")}
    transition: ${({ theme }) => theme.transitions[2]};
  }

  & .MuiDialog-paperFullScreen {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  && {
    margin: 0;
    padding: ${({ theme }) => theme.space[2]}px;
  }
`;

// Handy for using in class based components so we can use useMediaQuery
export const ZoomyDialog = ({
  open,
  onClose,
  maxWidth,
  fullWidth,
  children,
}) => {
  const isFullScreen = useIsMobile();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={isFullScreen}
    >
      {children}
    </Dialog>
  );
};
