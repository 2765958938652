import React, { useState } from "react";

import { Tab, Tabs } from "@material-ui/core";

import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";
import { UserRoleTable } from "components/UserRoles/List/";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { getUserTypeDisplayName, userTypes } from "constants/users";

import {
  useHasDeploymentPermission,
  useHasSaleyardAdminSaleyardPermission,
} from "hooks/useHasPermission";

function UserRoleSettings() {
  const hasDeploymentUserManagementPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureUserManagement,
  );

  const hasSaleyardUserManagementPermission =
    useHasSaleyardAdminSaleyardPermission(
      SaleyardPermissions.featureUserManagement,
    );

  const types = [];

  if (hasDeploymentUserManagementPermission) {
    types.push(userTypes.LIVESTOCK_AGENT);
  }

  if (hasSaleyardUserManagementPermission) {
    types.push(userTypes.SALEYARD_ADMIN);
  }

  const [selectedType, setSelectedType] = useState(types[0]);

  const onSetSelectedType = (ignored, newValue) => {
    setSelectedType(newValue);
  };

  return (
    <Column fullHeight>
      <Row flexGrow>
        {types.length > 1 && (
          <Column margin={2}>
            <Paper square elevation={2}>
              <Tabs
                orientation="vertical"
                value={selectedType}
                onChange={onSetSelectedType}
              >
                {types.map(type => (
                  <Tab
                    label={getUserTypeDisplayName(type)}
                    value={type}
                    key={type}
                  />
                ))}
              </Tabs>
            </Paper>
          </Column>
        )}

        <UserRoleTable userRoleType={selectedType} />
      </Row>
    </Column>
  );
}

export default UserRoleSettings;
