import { createSelector } from "reselect";

import { EMPTY_ARRAY } from "lib";

import {
  getBusinesses,
  getConsignments,
  getVendorSplitConsignments,
  selectVendorSplitConsignmentIdsByParentConsignmentIdLookup,
  selectVendorSplitSaleLotIdsByConsignmentIdLookup,
  selectVendorSplitSaleLotQuantityByConsignmentIdLookup,
} from "selectors";

const selectConsignmentModalVendorSplitConsignmentsAggridDataByParentConsignmentIdLookup =
  createSelector(
    [
      getConsignments,
      getVendorSplitConsignments,
      selectVendorSplitConsignmentIdsByParentConsignmentIdLookup,
      selectVendorSplitSaleLotIdsByConsignmentIdLookup,
      selectVendorSplitSaleLotQuantityByConsignmentIdLookup,
      getBusinesses,
    ],
    (
      consignments,
      vendorSplitConsignments,
      vendorSplitConsignmentIdsByParentIdLookup,
      vendorSplitSaleLotIdsByConsignmentIdLookup,
      saleLotQuantityByConsignmentIdLookup,
      businesses,
    ) =>
      Object.entries(vendorSplitConsignmentIdsByParentIdLookup).reduce(
        (acc, [parentId, vendorSplitConsignmentIds]) => {
          acc[parentId] = vendorSplitConsignmentIds.map(
            vendorSplitConsignmentId => {
              const vendorSplitConsignment =
                vendorSplitConsignments[vendorSplitConsignmentId];

              return {
                id: vendorSplitConsignmentId,
                vendor: businesses[vendorSplitConsignment.vendor_id] || {},

                lotCount:
                  vendorSplitSaleLotIdsByConsignmentIdLookup[
                    vendorSplitConsignmentId
                  ]?.length || 0,
                quantity:
                  saleLotQuantityByConsignmentIdLookup[
                    vendorSplitConsignmentId
                  ] || 0,
                consignment: vendorSplitConsignment,
                parentVendor:
                  businesses[consignments[parentId]?.vendor_id] || {},
                isPrimaryVendor:
                  vendorSplitConsignment.vendor_id ===
                  consignments[parentId]?.vendor_id,
              };
            },
          );
          return acc;
        },
        {},
      ),
  );

export const getConsignmentModalVendorSplitConsignmentsAggridDataByParentConsignmentId =
  consignmentId => state =>
    selectConsignmentModalVendorSplitConsignmentsAggridDataByParentConsignmentIdLookup(
      state,
    )[consignmentId] || EMPTY_ARRAY;
