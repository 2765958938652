import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  id: "id",
  name: "name",
  description: "description",
};

export const serializePermissionGroup = buildSerializer(serializeMap, "group");
export const deserializeGroup = buildDeserializer(serializeMap, "group");
