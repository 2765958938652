import { intersection } from "lodash";
import { createSelector } from "reselect";

import { ExportSites } from "constants/exportSites";
import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { getConsignmentCode } from "lib/consignments";
import { isSaleLotInProgress, getSortedMarkDetails } from "lib/saleLot";

import {
  getAuctionPens,
  getConsignments,
  getCurrentDeploymentSalesList,
  getGlobalSearchBySearchField,
  getSaleLots,
  selectCanHaveProgenyBySaleLotIdLookup,
  selectConsignmentHasImageLookup,
  selectConsignmentHasVideoLookup,
  selectDeliveryPenIdByEidLookup,
  selectDeliveryPenIdBySaleLotIdLookup,
  selectDeliveryPenIdsByConsignmentIdLookup,
  selectDeliveryPenIdsByDeploymentSaleIdLookup,
  selectExceptionsBySaleLotIdLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsSoldBySaleLotIdLookup,
  selectSaleLotHasImageLookup,
  selectSaleLotHasVideoLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByRoundIdLookup,
  selectScanStatusBySaleLotIdLookup,
  selectVendorIdBySaleLotIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
} from "selectors";

import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";

import {
  saleLotsFilteredBuyerIdsComparator,
  saleLotsFilteredThirdPartyIdsComparator,
  saleLotsFilteredBuyerHashesComparator,
  saleLotsFilteredIsBuyerBalancedComparator,
  saleLotsFilteredIsCountedComparator,
  saleLotsFilteredHaveOverflowPenCompararator,
  saleLotsFilteredIsDeliveredComparator,
  saleLotsFilteredLanesComparator,
  saleLotsFilteredLabelsComparator,
  saleLotsFilteredPricingTypesComparator,
  saleLotsFilteredPropertyIdComparator,
  saleLotsFilteredCheckpointComparator,
} from "./comparators";
import {
  filterAndMapObjectIdsWithLookup,
  rawObjectFilterSelectorFactory,
} from "./lib";

const selectUnfilteredDeliveryPenIds = createSelector(
  [getAuctionPens],
  deliveryPens => Object.keys(deliveryPens),
);

const selectIsAuctionsPlusCompliantDeliveryPenIds = createSelector(
  [
    getSaleLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredDeliveryPenIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsAuctionsPlusCompliant),
  ],
  (
    saleLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredDeliveryPenIds,
    searchField,
  ) => {
    return searchField === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isIntegrationCompliantBySaleLotIdLookup[saleLot.id][
                ExportSites.AUCTIONS_PLUS
              ] === searchField[0],
          )
          .map(saleLot => saleLot.deliveryPenId);
  },
);

const selectIsStockLiveCompliantDeliveryPenIds = createSelector(
  [
    getSaleLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredDeliveryPenIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsStockLiveCompliant),
  ],
  (
    saleLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredDeliveryPenIds,
    searchField,
  ) => {
    return searchField === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isIntegrationCompliantBySaleLotIdLookup[saleLot.id][
                ExportSites.STOCK_LIVE
              ] === searchField[0],
          )
          .map(saleLot => saleLot.deliveryPenId);
  },
);

const selectBuyerFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Buyer,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredBuyerIdsComparator,
  "deliveryPenId",
);

const selectThirdPartyFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.ThirdParty,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredThirdPartyIdsComparator,
  "deliveryPenId",
);

const selectBuyerAndBuyerWayFilteredDeliveryPenIds =
  rawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.BuyerAndBuyerWay,
    selectUnfilteredDeliveryPenIds,
    saleLotsFilteredBuyerHashesComparator,
    "deliveryPenId",
  );

const selectVendorFilteredDeliveryPenIds = createSelector(
  [
    selectVendorIdBySaleLotIdLookup,
    selectDeliveryPenIdBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Vendor),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    vendorIdBySaleLotIdLookup,
    deliveryPenIdBySaleLotIdLookup,
    vendorIds,
    unfilteredDeliveryPenIds,
  ) =>
    vendorIds === null
      ? unfilteredDeliveryPenIds
      : Object.entries(vendorIdBySaleLotIdLookup)
          .filter(([ignored, vendorId]) => vendorIds.includes(vendorId))
          .map(
            ([saleLotId, ignored]) => deliveryPenIdBySaleLotIdLookup[saleLotId],
          )
          .filter(Boolean),
);

const selectSaleRoundFilteredDeliveryPenIds = createSelector(
  [
    selectSaleLotIdsByRoundIdLookup,
    selectDeliveryPenIdBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.SaleRound),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    saleLotIdsByRoundIdLookup,
    deliveryPenIdBySaleLotIdLookup,
    saleRoundIds,
    unfilteredDeliveryPenIds,
  ) =>
    saleRoundIds === null
      ? unfilteredDeliveryPenIds
      : Object.entries(saleLotIdsByRoundIdLookup)
          .filter(
            ([roundId, ignored]) =>
              roundId && saleRoundIds.includes(parseInt(roundId, 10)),
          )
          .reduce(
            (acc, [ignored, saleLotIds]) =>
              acc.concat(
                saleLotIds
                  .map(saleLotId => deliveryPenIdBySaleLotIdLookup[saleLotId])
                  .filter(Boolean),
              ),
            [],
          ),
);

const selectAgencyFilteredDeliveryPenIds = createSelector(
  [
    getCurrentDeploymentSalesList,
    selectDeliveryPenIdsByDeploymentSaleIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Agency),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    deploymentSales,
    deliveryPenIdsByDeploymentSaleIdLookup,
    agencyIds,
    unfilteredDeliveryPenIds,
  ) =>
    agencyIds === null
      ? unfilteredDeliveryPenIds
      : deploymentSales
          .filter(deploymentSale =>
            agencyIds.includes(deploymentSale.livestock_agency_id),
          )
          .map(
            deploymentSale =>
              deliveryPenIdsByDeploymentSaleIdLookup[
                deploymentSale.deployment_sale_id
              ],
          )
          .reduce((acc, deliveryPenIds) => acc.concat(deliveryPenIds), []),
);

const selectHasArrivedFilteredDeliveryPenIds = createSelector(
  [
    getConsignments,
    selectDeliveryPenIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasArrived),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    consignments,
    deliveryPenIdsByConsignmentIdLookup,
    hasArrived,
    unfilteredDeliveryPenIds,
  ) =>
    hasArrived === null
      ? unfilteredDeliveryPenIds
      : Object.values(consignments)
          .filter(consignment => consignment.hasArrived === hasArrived[0])
          .reduce(
            (acc, consignment) =>
              acc.concat(deliveryPenIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectHasBuyerExceptionsFilteredDeliveryPenIds = createSelector(
  [
    selectExceptionsBySaleLotIdLookup,
    selectDeliveryPenIdBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasBuyerExceptions),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    exceptionsBySaleLotIdLookup,
    deliveryPenIdBySaleLotIdLookup,
    hasBuyerExceptions,
    unfilteredDeliveryPenIds,
  ) =>
    hasBuyerExceptions === null
      ? unfilteredDeliveryPenIds
      : Object.entries(deliveryPenIdBySaleLotIdLookup)
          .filter(
            ([saleLotId, ignored]) =>
              (exceptionsBySaleLotIdLookup[saleLotId].length === 0) ===
              hasBuyerExceptions[0],
          )
          .map(([ignored, deliveryPenId]) => deliveryPenId),
);
// I don't think this is relevant for pens (yet?)
const selectHasConsignmentScansFilteredDeliveryPenIds =
  selectUnfilteredDeliveryPenIds;

const selectHasOverflowFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.HasOverflow,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredHaveOverflowPenCompararator,
  "deliveryPenId",
);

const selectHasVendorExceptionsFilteredDeliveryPenIds =
  selectUnfilteredDeliveryPenIds;

const selectHasWeightFilteredDeliveryPenIds = createSelector(
  [
    getSaleLots,
    selectIsWeighedBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    saleLots,
    isWeighedBySaleLotIdLookup,
    hasWeight,
    unfilteredDeliveryPenIds,
  ) => {
    return hasWeight === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(
            saleLot => isWeighedBySaleLotIdLookup[saleLot.id] === hasWeight[0],
          )
          .map(lot => lot.deliveryPenId);
  },
);

const selectIsBuyerBalancedFilteredDeliveryPenIds =
  rawObjectFilterSelectorFactory(
    getSaleLots,
    GlobalSearchFields.IsBuyerBalanced,
    selectUnfilteredDeliveryPenIds,
    saleLotsFilteredIsBuyerBalancedComparator,
    "deliveryPenId",
  );

const selectIsCountedFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.IsCounted,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredIsCountedComparator,
  "deliveryPenId",
);

const selectIsDeliveredFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.IsDelivered,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredIsDeliveredComparator,
  "deliveryPenId",
);

const selectIsEidCompliantFilteredDeliveryPenIds = createSelector(
  [
    getSaleLots,
    selectScanStatusBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsEidCompliant),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    saleLots,
    scanStatusBySaleLotIdLookup,
    isEidCompliant,
    unfilteredDeliveryPenIds,
  ) =>
    isEidCompliant === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              ((scanStatusBySaleLotIdLookup[saleLot.id] || ScanStatus.PASS) ===
                ScanStatus.PASS) ===
              isEidCompliant[0],
          )
          .map(saleLot => saleLot.deliveryPenId),
);

const selectIsInProgressFilteredDeliveryPenIds = createSelector(
  [
    getSaleLots,
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredDeliveryPenIds,
  ],
  (saleLots, consignments, isInProgress, unfilteredDeliveryPenIds) =>
    isInProgress === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isSaleLotInProgress(
                saleLot,
                consignments[saleLot.consignment_id],
              ) === isInProgress[0],
          )
          .map(saleLot => saleLot.deliveryPenId),
);

const selectIsSoldFilteredDeliveryPenIds = createSelector(
  [
    getSaleLots,
    selectIsSoldBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSold),
    selectUnfilteredDeliveryPenIds,
  ],
  (saleLots, isSoldBySaleLotIdLookup, isSold, unfilteredDeliveryPenIds) =>
    isSold === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(saleLot => isSoldBySaleLotIdLookup[saleLot.id] === isSold[0])
          .map(saleLot => saleLot.deliveryPenId),
);

const selectIsVendorPostSaleBalancedFilteredDeliveryPenIds = createSelector(
  [
    getSaleLots,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPostSaleVendorBalanced),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    saleLots,
    isPostSaleBalancedByConsignmentIdLookup,
    isVendorBalanced,
    unfilteredDeliveryPenIds,
  ) =>
    isVendorBalanced === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isPostSaleBalancedByConsignmentIdLookup[
                saleLot.consignment_id
              ] === isVendorBalanced[0],
          )
          .map(saleLot => saleLot.deliveryPenId),
);

const selectIsVendorPreSaleBalancedFilteredDeliveryPenIds = createSelector(
  [
    getSaleLots,
    selectIsPreSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPreSaleVendorBalanced),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    saleLots,
    isPreSaleBalancedByConsignmentId,
    isVendorPresaleBalanced,
    unfilteredDeliveryPenIds,
  ) =>
    isVendorPresaleBalanced === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              isPreSaleBalancedByConsignmentId[saleLot.consignment_id] ===
              isVendorPresaleBalanced[0],
          )
          .map(saleLot => saleLot.deliveryPenId),
);

const selectAuctionPenFilteredDeliveryPenIds = createSelector(
  [
    selectDeliveryPenIdBySaleLotIdLookup,
    selectSaleLotIdsByAuctionPenIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.AuctionPen),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    deliveryPenIdBySaleLotIdLookup,
    saleLotIdsByAuctionPenIdLookup,
    auctionPenIds,
    unfilteredDeliveryPenIds,
  ) =>
    auctionPenIds === null
      ? unfilteredDeliveryPenIds
      : Object.entries(saleLotIdsByAuctionPenIdLookup)
          .filter(([auctionPenId, ignored]) =>
            auctionPenIds.includes(auctionPenId),
          )
          .reduce((acc, [ignored, saleLotIds]) =>
            acc.concat(
              saleLotIds
                .map(saleLotId => deliveryPenIdBySaleLotIdLookup[saleLotId])
                .filter(Boolean),
            ),
          ),
);

const selectDeliveryPenFilteredDeliveryPenIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.DeliveryPen),
    selectUnfilteredDeliveryPenIds,
  ],
  (deliveryPenIds, unfilteredDeliveryPenIds) =>
    deliveryPenIds === null ? unfilteredDeliveryPenIds : deliveryPenIds,
);

const selectOverflowPenFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.OverflowPen,
  selectUnfilteredDeliveryPenIds,
  overFlowPens => saleLot => overFlowPens.includes(saleLot.id),
  "deliveryPenId",
);

// Not sure if this is useful here.
const selectLaneFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Lane,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredLanesComparator,
  "deliveryPenId",
);
const selectLabelFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Label,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredLabelsComparator,
  "deliveryPenId",
);

const selectPricingTypeFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.PricingType,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredPricingTypesComparator,
  "delivery_pen_id",
);

const selectHasProgenyFilteredDeliveryPenIds = createSelector(
  [
    getSaleLots,
    selectCanHaveProgenyBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasProgeny),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    saleLots,
    canHaveProgenyBySaleLotIdLookup,
    hasProgeny,
    unfilteredDeliveryPenIds,
  ) =>
    hasProgeny === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(
            saleLot =>
              canHaveProgenyBySaleLotIdLookup[saleLot.id] === hasProgeny[0],
          )
          .map(saleLot => saleLot.delivery_pen_id),
);

const selectScanFilteredDeliveryPenIds = createSelector(
  [
    selectDeliveryPenIdByEidLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Scan),
    selectUnfilteredDeliveryPenIds,
  ],
  (deliveryPenIdByEidLookup, eids, unfilteredDeliveryPenIds) =>
    eids === null
      ? unfilteredDeliveryPenIds
      : eids.map(eid => deliveryPenIdByEidLookup[eid]).filter(Boolean),
);
const selectVendorNumberFilteredDeliveryPenIds = createSelector(
  [
    getConsignments,
    selectDeliveryPenIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorNumber),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    consignments,
    deliveryPenIdsByConsignmentIdLookup,
    vendorNumbers,
    unfilteredDeliveryPenIds,
  ) =>
    vendorNumbers === null
      ? unfilteredDeliveryPenIds
      : Object.values(consignments)
          .filter(consignment =>
            vendorNumbers.includes(getConsignmentCode(consignment)),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(deliveryPenIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectVendorPicFilteredDeliveryPenIds = createSelector(
  [
    getConsignments,
    selectDeliveryPenIdsByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorPic),
    selectUnfilteredDeliveryPenIds,
  ],
  (
    consignments,
    deliveryPenIdsByConsignmentIdLookup,
    vendorPic,
    unfilteredDeliveryPenIds,
  ) =>
    vendorPic === null
      ? unfilteredDeliveryPenIds
      : Object.values(consignments)
          .filter(consignment =>
            vendorPic.includes(consignment.vendor_property_id),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(deliveryPenIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectBuyerPicFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.BuyerPic,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredPropertyIdComparator,
);

const selectCheckpointFilteredDeliveryPenIds = rawObjectFilterSelectorFactory(
  getSaleLots,
  GlobalSearchFields.Checkpoint,
  selectUnfilteredDeliveryPenIds,
  saleLotsFilteredCheckpointComparator,
  "deliveryPenId",
);

const selectIsSaleLotImageUploadedDeliveryPenIds =
  filterAndMapObjectIdsWithLookup(
    getSaleLots,
    GlobalSearchFields.IsSaleLotImageUploaded,
    "deliveryPenId",
    selectUnfilteredDeliveryPenIds,
    getConsignments,
    selectSaleLotHasImageLookup,
  );

const selectIsConsignmentImageUploadedDeliveryPenIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentImageUploaded,
    "deliveryPenId",
    selectUnfilteredDeliveryPenIds,
    getSaleLots,
    selectConsignmentHasImageLookup,
    (consignment, objectKey, lots) => {
      const saleLots = Object.values(lots).filter(
        lot => lot.consignment_id === consignment.id,
      );
      return saleLots.map(saleLot => saleLot.deliveryPenId);
    },
  );

const selectIsSaleLotVideoUploadedDeliveryPenIds =
  filterAndMapObjectIdsWithLookup(
    getSaleLots,
    GlobalSearchFields.IsSaleLotVideoUploaded,
    "deliveryPenId",
    selectUnfilteredDeliveryPenIds,
    getConsignments,
    selectSaleLotHasVideoLookup,
  );

const selectIsConsignmentVideoUploadedDeliveryPenIds =
  filterAndMapObjectIdsWithLookup(
    getConsignments,
    GlobalSearchFields.IsConsignmentVideoUploaded,
    "deliveryPenId",
    selectUnfilteredDeliveryPenIds,
    getSaleLots,
    selectConsignmentHasVideoLookup,
    (consignment, objectKey, lots) => {
      const saleLots = Object.values(lots).filter(
        lot => lot.consignment_id === consignment.id,
      );
      return saleLots.map(saleLot => saleLot.deliveryPenId);
    },
  );

const selectReceivalPenDeliveryPenIds = createSelector(
  [
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.ReceivalPen),
    selectUnfilteredDeliveryPenIds,
    selectDeliveryPenIdsByConsignmentIdLookup,
  ],
  (
    consignments,
    receivalPens,
    unfilteredDeliveryPenIds,
    deliveryPenIdsByConsignmentIdLookup,
  ) =>
    receivalPens === null
      ? unfilteredDeliveryPenIds
      : Object.values(consignments)
          .filter(consignment =>
            receivalPens.includes(consignment.receiving_pen),
          )
          .reduce(
            (acc, consignment) =>
              acc.concat(deliveryPenIdsByConsignmentIdLookup[consignment.id]),
            [],
          ),
);

const selectMarksFilteredDeliveryPenIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredDeliveryPenIds,
  ],
  (saleLots, marks, unfilteredDeliveryPenIds) =>
    marks === null
      ? unfilteredDeliveryPenIds
      : Object.values(saleLots)
          .filter(saleLot =>
            marks.some(mark => mark === getSortedMarkDetails(saleLot)),
          )
          .map(lot => lot.auction_pen_id),
);

export const selectFilteredDeliveryPenIds = createSelector(
  [
    selectAgencyFilteredDeliveryPenIds,
    selectAuctionPenFilteredDeliveryPenIds,
    selectBuyerFilteredDeliveryPenIds,
    selectBuyerAndBuyerWayFilteredDeliveryPenIds,
    selectDeliveryPenFilteredDeliveryPenIds,
    selectHasArrivedFilteredDeliveryPenIds,
    selectHasBuyerExceptionsFilteredDeliveryPenIds,
    selectHasConsignmentScansFilteredDeliveryPenIds,
    selectHasOverflowFilteredDeliveryPenIds,
    selectHasProgenyFilteredDeliveryPenIds,
    selectHasVendorExceptionsFilteredDeliveryPenIds,
    selectHasWeightFilteredDeliveryPenIds,
    selectIsBuyerBalancedFilteredDeliveryPenIds,
    selectIsCountedFilteredDeliveryPenIds,
    selectIsDeliveredFilteredDeliveryPenIds,
    selectIsEidCompliantFilteredDeliveryPenIds,
    selectIsInProgressFilteredDeliveryPenIds,
    selectIsSoldFilteredDeliveryPenIds,
    selectIsVendorPreSaleBalancedFilteredDeliveryPenIds,
    selectIsVendorPostSaleBalancedFilteredDeliveryPenIds,
    selectLabelFilteredDeliveryPenIds,
    selectLaneFilteredDeliveryPenIds,
    selectOverflowPenFilteredDeliveryPenIds,
    selectPricingTypeFilteredDeliveryPenIds,
    selectSaleRoundFilteredDeliveryPenIds,
    selectScanFilteredDeliveryPenIds,
    selectThirdPartyFilteredDeliveryPenIds,
    selectVendorFilteredDeliveryPenIds,
    selectVendorNumberFilteredDeliveryPenIds,
    selectVendorPicFilteredDeliveryPenIds,
    selectBuyerPicFilteredDeliveryPenIds,
    selectCheckpointFilteredDeliveryPenIds,
    selectIsAuctionsPlusCompliantDeliveryPenIds,
    selectIsStockLiveCompliantDeliveryPenIds,
    selectIsSaleLotImageUploadedDeliveryPenIds,
    selectIsConsignmentImageUploadedDeliveryPenIds,
    selectIsSaleLotVideoUploadedDeliveryPenIds,
    selectIsConsignmentVideoUploadedDeliveryPenIds,
    selectReceivalPenDeliveryPenIds,
    selectMarksFilteredDeliveryPenIds,
  ],
  (
    agencyFilteredDeliveryPenIds,
    auctionPenFilteredDeliveryPenIds,
    buyerFilteredDeliveryPenIds,
    buyerAndBuyerWayFilteredDeliveryPenIds,
    deliveryPenFilteredDeliveryPenIds,
    hasArrivedFilteredDeliveryPenIds,
    hasBuyerExceptionsFilteredDeliveryPenIds,
    hasConsignmentScansFilteredDeliveryPenIds,
    hasOverflowFilteredDeliveryPenIds,
    hasProgenyFilteredDeliveryPenIds,
    hasVendorExceptionsFilteredDeliveryPenIds,
    hasWeightFilteredDeliveryPenIds,
    isBuyerBalancedFilteredDeliveryPenIds,
    isCountedFilteredDeliveryPenIds,
    isDeliveredFilteredDeliveryPenIds,
    isEidCompliantFilteredDeliveryPenIds,
    isInProgressFilteredDeliveryPenIds,
    isSoldFilteredDeliveryPenIds,
    isVendorPreSaleBalancedFilteredDeliveryPenIds,
    isVendorPostSaleBalancedFilteredDeliveryPenIds,
    labelFilteredDeliveryPenIds,
    laneFilteredDeliveryPenIds,
    overflowPenFilteredDeliveryPenIds,
    pricingTypeFilteredDeliveryPenIds,
    saleRoundFilteredDeliveryPenIds,
    scanFilteredDeliveryPenIds,
    thirdPartyFilteredDeliveryPenIds,
    vendorFilteredDeliveryPenIds,
    vendorNumberFilteredDeliveryPenIds,
    vendorPicFilteredDeliveryPenIds,
    buyerPicFilteredDeliveryPenIds,
    checkpointFilteredDeliveryPenIds,
    isAuctionsPlusCompliantDeliveryPenIds,
    isStockLiveCompliantDeliveryPenIds,
    isSaleLotImageUploadedDeliveryPenIds,
    isConsignmentImageUploadedDeliveryPenIds,
    isSaleLotVideoUploadedDeliveryPenIds,
    isConsignmentVideoUploadedDeliveryPenIds,
    receivalPenDeliveryPenIds,
    marksFilteredDeliveryPenIds,
  ) =>
    intersection(
      agencyFilteredDeliveryPenIds,
      auctionPenFilteredDeliveryPenIds,
      buyerFilteredDeliveryPenIds,
      buyerAndBuyerWayFilteredDeliveryPenIds,
      deliveryPenFilteredDeliveryPenIds,
      hasArrivedFilteredDeliveryPenIds,
      hasBuyerExceptionsFilteredDeliveryPenIds,
      hasConsignmentScansFilteredDeliveryPenIds,
      hasOverflowFilteredDeliveryPenIds,
      hasProgenyFilteredDeliveryPenIds,
      hasVendorExceptionsFilteredDeliveryPenIds,
      hasWeightFilteredDeliveryPenIds,
      isBuyerBalancedFilteredDeliveryPenIds,
      isCountedFilteredDeliveryPenIds,
      isDeliveredFilteredDeliveryPenIds,
      isEidCompliantFilteredDeliveryPenIds,
      isInProgressFilteredDeliveryPenIds,
      isSoldFilteredDeliveryPenIds,
      isVendorPreSaleBalancedFilteredDeliveryPenIds,
      isVendorPostSaleBalancedFilteredDeliveryPenIds,
      labelFilteredDeliveryPenIds,
      laneFilteredDeliveryPenIds,
      overflowPenFilteredDeliveryPenIds,
      pricingTypeFilteredDeliveryPenIds,
      saleRoundFilteredDeliveryPenIds,
      scanFilteredDeliveryPenIds,
      thirdPartyFilteredDeliveryPenIds,
      vendorFilteredDeliveryPenIds,
      vendorNumberFilteredDeliveryPenIds,
      vendorPicFilteredDeliveryPenIds,
      buyerPicFilteredDeliveryPenIds,
      checkpointFilteredDeliveryPenIds,
      isAuctionsPlusCompliantDeliveryPenIds,
      isStockLiveCompliantDeliveryPenIds,
      isSaleLotImageUploadedDeliveryPenIds,
      isConsignmentImageUploadedDeliveryPenIds,
      isSaleLotVideoUploadedDeliveryPenIds,
      isConsignmentVideoUploadedDeliveryPenIds,
      receivalPenDeliveryPenIds,
      marksFilteredDeliveryPenIds,
    ),
);
