import React from "react";

import { intersection } from "lodash";
import { useSelector } from "react-redux";

import NestedCard from "components/Card/NestedCard";
import { PreventPropagation } from "components/Events";
import { LotCardBySaleLotId } from "components/LotCard/LotCard";

import { auctionPenStatuses, cards } from "constants/sale";
import { Settings } from "constants/settings";

import {
  getSaleLotIdsByAuctionPenId,
  getSetting,
  getStatusByAuctionPenId,
  selectAgencyIdBySaleLotId,
  selectSaleLotIdsByRoundIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectSaleLotIdsByMarkOrder,
  selectFilteredSaleLotIds,
} from "selectors";

function SaleLotsGroup({ auctionPenId, basePath, weighedOnly }) {
  const saleLotIds = useSelector(getSaleLotIdsByAuctionPenId(auctionPenId));

  const globalSearchFilteredSaleLotIds = useSelector(selectFilteredSaleLotIds);

  const agencyIdBySaleLotId = useSelector(selectAgencyIdBySaleLotId);
  const agencyId = useSelector(getSetting(Settings.agencyId));

  const weighedOnlyFilterActive = typeof weighedOnly !== "undefined";
  const isWeighed = useSelector(selectIsWeighedBySaleLotIdLookup);

  const status = useSelector(getStatusByAuctionPenId(auctionPenId));
  // Not sold is the main flag here - a "NOT SOLD" pen = a "PENNED" lot status.
  // By definition of rendering a Pen card, the PENNED status is redundant.
  const overrideStatus = [
    auctionPenStatuses.NOT_PENNED,
    auctionPenStatuses.NOT_SOLD,
  ].includes(status)
    ? status
    : null;

  // We may get here by virtue of a null auction pen id - make sure we're only showing this round.
  const saleLotIdsByRoundIdLookup = useSelector(
    selectSaleLotIdsByRoundIdLookup,
  );
  const selectedRoundId = useSelector(getSetting(Settings.round));

  const markSortedSaleLotIds = intersection(
    useSelector(selectSaleLotIdsByMarkOrder),
    saleLotIds,
  );

  // Lint seems to not like logic in a single filter! :(
  const filteredSaleLotIds = markSortedSaleLotIds
    .filter(
      saleLotId =>
        agencyId === null || agencyIdBySaleLotId[saleLotId] === agencyId,
    )
    .filter(saleLotId =>
      saleLotIdsByRoundIdLookup[selectedRoundId].includes(saleLotId),
    )
    .filter(
      saleLotId =>
        !weighedOnlyFilterActive || isWeighed?.[saleLotId] === weighedOnly,
    );

  return (
    <PreventPropagation>
      <NestedCard smallSpacing data-tour="nestedLotCard">
        {filteredSaleLotIds.map(saleLotId => (
          <LotCardBySaleLotId
            saleLotId={saleLotId}
            key={saleLotId}
            basePath={basePath}
            cardType={cards.SALE_LOT_CARD}
            showVendor
            showBuyer
            showBuyerWay
            showScanning
            overrideStatus={overrideStatus}
            faded={!globalSearchFilteredSaleLotIds.includes(saleLotId)}
          />
        ))}
      </NestedCard>
    </PreventPropagation>
  );
}

export default React.memo(SaleLotsGroup);
