import React from "react";

import { CircularProgress } from "@material-ui/core";

function LoadingSpinner({ size, actionName = "Loading", subjectName }) {
  return [
    <CircularProgress size={size} key={0} />,
    `\u00A0${actionName}`,
    subjectName ? ` ${subjectName}` : "",
    "...",
  ];
}

export default React.memo(LoadingSpinner);
