import { offlineActionCreator, urlCheckAndAddIdAndAction } from "actions/lib";

import { DRAFTING_INFORMATION } from "constants/actionTypes";

import { serializeDraftingInformation } from "lib/serializers/draftingInformation";

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/drafting-information/", id, action);

export const DraftingInformationAction = offlineActionCreator(
  DRAFTING_INFORMATION,
  serializeDraftingInformation,
  urlCreator,
  false,
);
