import React, { useLayoutEffect } from "react";

import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import { faEngineWarning } from "@fortawesome/pro-duotone-svg-icons/faEngineWarning";
import { faExclamationCircle } from "@fortawesome/pro-duotone-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { formatWeightKg } from "lib";

import { getConnectedScales, getMostRecentWeight } from "selectors";

const LiveWeightSpan = styled.span(
  ({ theme, color }) => `
  color: ${theme.colors[color]};
  font-size: 2.1em;
  text-align: right;
  white-space: nowrap;
  `,
);

export function LiveWeight(props) {
  const { weightGrams, icon, color, title } = props;

  return (
    <LiveWeightSpan color={color}>
      <FontAwesomeIcon title={title} icon={icon} />{" "}
      {formatWeightKg(weightGrams)}
    </LiveWeightSpan>
  );
}

LiveWeight.propTypes = {
  weightGrams: PropTypes.number,
  icon: PropTypes.object,
  color: PropTypes.string,
  title: PropTypes.string,
};

const IndicatorStatusDisplayMap = {
  STABLE: { title: "Stable reading.", icon: faCheckCircle, color: "success" },
  UNSTABLE: {
    title: "Weight is unstable.",
    icon: faEngineWarning,
    color: "warning",
  },
  DISCONNECTED: {
    title: "No scales connected.",
    icon: faExclamationCircle,
    color: "error",
  },
};

function getIndicatorStatusDisplay(isConnected, isStable) {
  return isConnected
    ? isStable
      ? IndicatorStatusDisplayMap.STABLE
      : IndicatorStatusDisplayMap.UNSTABLE
    : IndicatorStatusDisplayMap.DISCONNECTED;
}

export function IndicatorReadout(props) {
  const { onWeightChanged, onWeightStable } = props;
  const lastWeight = useSelector(getMostRecentWeight);
  const isConnected = useSelector(
    state => getConnectedScales(state)?.length > 0,
  );

  const { weightGrams = 0, isStable } = lastWeight || {};

  useLayoutEffect(() => {
    typeof onWeightChanged === "function" &&
      onWeightChanged(weightGrams, isStable);
    if (isStable) {
      typeof onWeightStable === "function" && onWeightStable(weightGrams);
    }
  }, [onWeightChanged, weightGrams, onWeightStable, isStable]);

  const { icon, color, title } = getIndicatorStatusDisplay(
    isConnected,
    isStable,
  );

  return (
    <LiveWeight
      icon={icon}
      color={color}
      title={title}
      weightGrams={weightGrams}
    />
  );
}
IndicatorReadout.propTypes = {
  onWeightChanged: PropTypes.func,
  onWeightStable: PropTypes.func,
};
