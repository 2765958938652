import { createSelector } from "reselect";

import { EMPTY_ARRAY } from "lib";

import { getCombinedLotNumber } from "lib/saleLot";

import {
  getBusinesses,
  getVendorSplitConsignments,
  getVendorSplitSaleLots,
  selectVendorSplitSaleLotIdsByParentSaleLotIdLookup,
} from "selectors";

const selectSaleLotModalVendorSplitSaleLotsAggridDataByParentSaleLotIdLookup =
  createSelector(
    [
      getVendorSplitSaleLots,
      selectVendorSplitSaleLotIdsByParentSaleLotIdLookup,
      getVendorSplitConsignments,
      getBusinesses,
    ],
    (
      vendorSplitSaleLots,
      vendorSplitSaleLotIdsByParentIdLookup,
      vendorSplitConsignments,
      businesses,
    ) =>
      Object.entries(vendorSplitSaleLotIdsByParentIdLookup).reduce(
        (acc, [parentId, saleLotIds]) => {
          acc[parentId] = saleLotIds.map(saleLotId => {
            const vendorSplitSaleLot = vendorSplitSaleLots[saleLotId];
            const fullLotNumber = getCombinedLotNumber(vendorSplitSaleLot);
            const vendorSplitConsignment =
              vendorSplitConsignments[
                vendorSplitSaleLots[saleLotId].consignment_id
              ];
            return {
              id: saleLotId,
              vendor: businesses[vendorSplitConsignment?.vendor_id] || {},
              saleLot: Object.assign({}, vendorSplitSaleLot, {
                fullLotNumber,
              }),
            };
          });
          return acc;
        },
        {},
      ),
  );

export const getSaleLotModalVendorSplitSaleLotsAggridDataByParentSaleLotId =
  consignmentId => state =>
    selectSaleLotModalVendorSplitSaleLotsAggridDataByParentSaleLotIdLookup(
      state,
    )[consignmentId] || EMPTY_ARRAY;
