import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { RESTORE_SESSION } from "constants/actionTypes";

import { RedirectToSale } from "containers/Home/RedirectToSale";
import SaleyardHome from "containers/SaleyardHome";

import { getDashboardRoute, getSettingsRoute } from "lib/navigation";

import { getActiveRole, getIsStaff } from "selectors";

import useSocketListener from "hooks/useSocketListener";

const RootRedirRoute = () => {
  const isStaff = useSelector(getIsStaff);

  return (
    <Switch>
      {isStaff && (
        <Route
          exact
          path="/redir/:livestockSaleId/:userRole"
          component={RedirectToSale}
        />
      )}

      <Redirect
        exact
        path="/redir/settings__integrations/"
        to={getSettingsRoute("Integrations")}
      />
      {/* Redirect everything else just to root. */}
      <Redirect path="/redir/" to="/" />
    </Switch>
  );
};

const Home = () => {
  const dispatch = useDispatch();

  const activeRole = useSelector(getActiveRole);
  useSocketListener();
  useEffect(() => {
    // On mount, dispatch an action to trigger requesting system and user role data.
    // if the user has no roles, don't make any more server requests as they will all result in an error.
    if (activeRole?.slug) {
      dispatch({ type: RESTORE_SESSION });
    }
  }, [dispatch, activeRole?.slug]);

  return (
    <Switch>
      <Route path="/redir/" component={RootRedirRoute} />
      <Route path="/saleyard/:saleyard" component={SaleyardHome} />
      <Route path="/saleyard/" component={SaleyardHome} />
      <Route path="/app/" component={SaleyardHome} />
      <Route path={getDashboardRoute()} component={SaleyardHome} />
      <Route path="/" component={SaleyardHome} />
    </Switch>
  );
};

export default Home;
