import React, { useRef, useState, useCallback } from "react";

import {
  faCodeMerge,
  faCog,
  faFileImport,
  faSync,
  faWeight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch as MaterialSwitch, Tooltip } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  WeighLotAction,
  connectToHub,
  importMtHostSessions,
  setSetting,
  updateAvailableDevices,
} from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { MultiButton } from "components/Button";
import { Button } from "components/Form";
import { Column as LayoutColumn } from "components/Layout";
import { HubStatusIndicator } from "components/ScanningScreen";

import { ACTION_COLUMN_NAME, AgGridTables } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";
import { Settings } from "constants/settings";
import { WeighLotScanStatus } from "constants/weighScanning";

import { formatters, getters } from "lib/agGrid";
import {
  singleSaleLotLotNumbersRenderer,
  singleScanLotRenderer,
} from "lib/agGrid/renderers/scanLotrenderers";
import { openModalLink } from "lib/navigation";

import {
  getConnectedMtHostSessions,
  getIsHubConnected,
  getIsImportingMtHostSessions,
  getSetting,
  getShowDeduplicatedWeighLotScans,
  getWeighLotScans,
  selectWeighLotIdsByEidLookup,
  selectWeighLotScanIdsByWeighLotIdLookup,
  selectWeighLotScansByWeighLotId,
} from "selectors";

import { getWeighLotAggridData } from "selectors/aggrid/weighLots";

import { useDebounceSelector } from "hooks";

import { WeighLotColumnId, weighLotColumns } from "./columnDefinitions";
import {
  WeighLotScanActionsColumnRenderer,
  WeighLotScanStatusRenderer,
} from "./renderers";

const detailCellRendererParams = {
  detailGridOptions: {
    suppressRowClickSelection: true,
    enableRangeSelection: true,
    columnDefs: [
      {
        headerName: "Status",
        field: "status",
        cellRenderer: WeighLotScanStatusRenderer,
        width: 300,
        pinned: "left",
        resizable: true,
      },
      { header: "EID", field: "eid", resizable: true, maxWidth: 200 },
      {
        field: "totalMassGrams",
        headerName: "Weight (kg)",
        resizable: true,
        maxWidth: 200,
        valueFormatter: formatters.kilogramsFormatter,
        valueGetter: getters.kilogramsGetter,
      },
      {
        field: "receivalLot",
        headerName: "Receival Lot",
        cellRenderer: singleScanLotRenderer,
        resizable: true,
        maxWidth: 200,
      },
      {
        field: "penScanLot",
        headerName: "Pen Scan Lot",
        cellRenderer: singleScanLotRenderer,
        resizable: true,
        maxWidth: 200,
      },
      {
        field: "saleLot",
        headerName: "Sale Lot",
        cellRenderer: singleSaleLotLotNumbersRenderer,
        resizable: true,
        maxWidth: 200,
      },
      {
        headerName: ACTION_COLUMN_NAME,
        colId: "actions",
        suppressCellFlash: true,
        cellRenderer: WeighLotScanActionsColumnRenderer,
        width: 600,
        resizable: true,
      },
    ],
    defaultColDef: {
      sortable: true,
      flex: 1,
    },
  },
  getDetailRowData: params => {
    params.successCallback(params.data.weighLotScans);
  },
};
export const WeighScanLotsTable = () => {
  const agGridApiRef = useRef();
  const [gridApi, setGridApi] = React.useState(null);
  const [selectedWeighLotIds, setSelectedWeighLotIds] = useState([]);
  const dispatch = useDispatch();
  const connectedMtHostSessions = useSelector(getConnectedMtHostSessions);
  const isHubConnected = useSelector(getIsHubConnected);

  const isImportingMtHostSessions = useSelector(getIsImportingMtHostSessions);

  React.useEffect(() => {
    if (!isHubConnected) {
      dispatch(connectToHub());
    }
    if (!connectedMtHostSessions.length) {
      dispatch(updateAvailableDevices());
    }
  }, [connectedMtHostSessions.length, dispatch, isHubConnected]);

  const rowData = useDebounceSelector(getWeighLotAggridData);
  const weighLotScanByIdLookup = useSelector(getWeighLotScans);
  const weighLotScanIdsByWeighLotIdLookup = useSelector(
    selectWeighLotScanIdsByWeighLotIdLookup,
  );
  const weighLotIdsByEidlookup = useSelector(selectWeighLotIdsByEidLookup);

  const weighLotScansByWeighLotIdLookup = useSelector(
    selectWeighLotScansByWeighLotId,
  );

  function onClickManualImportWeights() {
    openModalLink(ModalTypes.ImportWeighLots);
  }

  function onClickAllocateScans() {
    const weighLotScans = selectedWeighLotIds
      .map(weighLotId => weighLotScansByWeighLotIdLookup[weighLotId])
      .flat()
      .filter(
        weighLotScan => weighLotScan.status === WeighLotScanStatus.UNRESOLVED,
      );
    dispatch(WeighLotAction.process(weighLotScans));
  }

  function onRowSelectionChanged(selectedRowNodes) {
    setSelectedWeighLotIds(selectedRowNodes.map(rowNode => rowNode.weighLotId));
  }

  const showDeDuplicatedWeighLotScans = useSelector(
    getShowDeduplicatedWeighLotScans,
  );

  const toggleShowAllWeighLotScans = () =>
    dispatch(
      setSetting(
        Settings.showDeduplicatedWeighLotScans,
        !showDeDuplicatedWeighLotScans,
      ),
    );

  const storedMtHostSettings = useSelector(getSetting(Settings.mtHostSettings));

  const hubStatusIndicator = (
    <LayoutColumn padding={2}>
      <HubStatusIndicator />
    </LayoutColumn>
  );

  const showDeduplicatedSwitch = (
    <LayoutColumn padding={1}>
      <Tooltip title="Show All Scans">
        <MaterialSwitch
          checked={showDeDuplicatedWeighLotScans}
          color="primary"
          onClick={() => toggleShowAllWeighLotScans()}
        />
      </Tooltip>
    </LayoutColumn>
  );

  const openWeightSettings = () => {
    openModalLink(ModalTypes.WeightSettings);
  };

  const handleImportWeights = () => {
    // if no settings are stored - open settings modal
    if (isEmpty(storedMtHostSettings)) {
      openWeightSettings();
    } else {
      // run import
      dispatch(importMtHostSessions(storedMtHostSettings));
    }
  };

  const importButtons = [
    {
      title: "Import Weights",
      onClick: handleImportWeights,
      default: true,
      spin: !!isImportingMtHostSessions,
      icon: isImportingMtHostSessions ? faSync : faWeight,
      isDisabled: !isHubConnected || !!isImportingMtHostSessions,
    },
    {
      title: "Settings",
      onClick: openWeightSettings,
      icon: faCog,
      isDisabled: !isHubConnected || !!isImportingMtHostSessions,
    },
    {
      title: "Manual Import",
      onClick: onClickManualImportWeights,
      icon: faFileImport,
    },
  ];

  const importActions = (
    <LayoutColumn padding={1}>
      <MultiButton buttons={importButtons} />
    </LayoutColumn>
  );

  const allocateScans = (
    <LayoutColumn padding={1}>
      <Button
        inline
        onClick={onClickAllocateScans}
        disabled={selectedWeighLotIds.length === 0}
      >
        <FontAwesomeIcon icon={faCodeMerge} /> Allocate Scans
      </Button>
    </LayoutColumn>
  );

  const extraComponents = [
    hubStatusIndicator,
    showDeduplicatedSwitch,
    allocateScans,
    importActions,
  ];

  function onGridReady({ api }) {
    agGridApiRef.current = api;
    setGridApi(api);
  }

  const getRowHeight = useCallback(params => {
    if (params.node && params.node.detail) {
      const offset = 80;
      const allDetailRowHeight =
        params.data.weighLotScans.length *
        params.api.getSizesForCurrentTheme().rowHeight;
      const gridSizes = params.api.getSizesForCurrentTheme();
      return (
        allDetailRowHeight +
        ((gridSizes && gridSizes.headerHeight) || 0) +
        offset
      );
    }
  }, []);

  React.useEffect(() => {
    if (gridApi) {
      gridApi.redrawRows();
    }
  }, [selectedWeighLotIds, gridApi]);

  const rowClassRules = {
    "row-warning": params => {
      const { api, node } = params;
      const selectedWeighLotId = selectedWeighLotIds[0];
      const { data } = node;
      if (!data) {
        return null;
      }
      const weighLotId = data.weighLot.id || null;
      const scanStatuses = api.getValue(WeighLotColumnId.SCAN_STATUSES, node);

      // find other weigh lots that have the same eid in their weigh lot scans
      const reweighLots =
        weighLotScanIdsByWeighLotIdLookup[selectedWeighLotId]
          ?.map(swlId =>
            weighLotIdsByEidlookup[weighLotScanByIdLookup[swlId].eid].filter(
              weighLotId => weighLotId !== selectedWeighLotId,
            ),
          )
          .flat() || [];

      return (
        (weighLotId === selectedWeighLotId &&
          scanStatuses.includes(WeighLotScanStatus.REWEIGH)) ||
        reweighLots.includes(weighLotId)
      );
    },
  };

  return (
    <AgGridTable
      detailCellRendererParams={detailCellRendererParams}
      columnDefs={weighLotColumns}
      extraHeaderComponents={extraComponents}
      onGridReady={onGridReady}
      onRowSelectionChanged={onRowSelectionChanged}
      rowData={rowData}
      rowSelection="multiple"
      tableName={AgGridTables.WEIGH_LOT}
      masterDetail
      getRowHeight={getRowHeight}
      rowClassRules={rowClassRules}
      rowSelectionId="weighLotId"
      getRowId={row => row.data.weighLot.id}
      suppressRowClickSelection
    />
  );
};
