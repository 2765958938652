import React from "react";

import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { useField } from "formik";

import { withArrayNamespace } from "components/Form/FormikControls";

import { ValueSource } from "constants/ruleBooks";

import {
  CheckComparatorDisplayNameMap,
  CUSTOM_QUERY_FIELD_ID,
  ValueSourceDisplayNameMap,
} from "containers/Settings/RuleBooks/constants";
import { getPropertyDisplayName } from "containers/Settings/RuleBooks/DataSource";
import {
  getSchemaField,
  getSchemaFieldValues,
} from "containers/Settings/RuleBooks/lib";
import { useRuleFieldSchema } from "containers/Settings/RuleBooks/schemaContext";

import { Check } from "./Check";

export function getCheckTemplate() {
  return {
    comparator: "",
    field: {
      source: ValueSource.FIELD,
      customQuery: "",
      attribute: "",
      fieldId: "",
    },
    values: [""],
  };
}

function getPropertyName(parentFieldId, fieldId, defaultValue, schema) {
  const fieldValues = getSchemaFieldValues(parentFieldId, schema);

  const schemaFieldProperty = fieldValues.find(
    fieldValue => fieldValue.uniq === fieldId || fieldValue.id === fieldId,
  );

  if (schemaFieldProperty) {
    if (schemaFieldProperty.isRelation) {
      // If this relationship has a specific name, use it.
      if (schemaFieldProperty.name) {
        return schemaFieldProperty.name;
      }
      const schemaField = getSchemaField(schemaFieldProperty.id, schema);
      if (schemaField) {
        return schemaField.singular || defaultValue;
      }
    } else {
      return getPropertyDisplayName(fieldId);
    }
  }
  return defaultValue;
}

export function summariseCheck(check, parentFieldId, schema) {
  let sourceSummary = "(Source Not Set)";
  if (check.field.source === ValueSource.FIELD) {
    if (check.field.fieldId !== CUSTOM_QUERY_FIELD_ID) {
      const fieldName = getPropertyName(
        parentFieldId,
        check.field.uniq || check.field.fieldId,
        "",
        schema,
      );

      const attributeName = getPropertyName(
        check.field.fieldId || parentFieldId,
        check.field.attribute,
        "(Attribute Not Set)",
        schema,
      );

      sourceSummary =
        [fieldName, attributeName || "(Attribute Not Set)"]
          .filter(Boolean)
          .join(" ") || "<Source>";
    } else {
      sourceSummary =
        ["(Custom Data Source)", check.field.attribute || "(Attribute Not Set)"]
          .filter(Boolean)
          .join(" ") || "<Source>";
    }
  } else if (
    check.field.source === ValueSource.COUNT ||
    check.field.source === ValueSource.SUM
  ) {
    const fieldName = getPropertyName(
      parentFieldId,
      check.field.attribute,
      check.field.attribute,
      schema,
    );

    const aggregationSummary =
      (check.field.index && `Criteria ${+check.field.index + 1}`) ||
      "(Criteria Not Set)";
    sourceSummary = `${
      ValueSourceDisplayNameMap[check.field.source]
    } ${fieldName || "(Attribute Not Set)"} from ${aggregationSummary}`;
  }
  const valuesSummary =
    check.values
      .map(value => (value === "" ? "(Value Not Set)" : value))
      .join(" or ") || "(Values Not Set)";

  return `${sourceSummary} ${
    CheckComparatorDisplayNameMap[check.comparator] || "(Comparator Not Set)"
  } ${valuesSummary}`;
}

export function CheckGroup(props) {
  const {
    criteriaOptions,
    namespace: ns,
    onRemoveLastCondition,
    parentFieldId,
  } = props;
  const schema = useRuleFieldSchema();
  const [
    { value: checks },

    // eslint-disable-next-line no-unused-vars
    ignored,
    { setValue },
  ] = useField(ns);

  function onClickAddCondition() {
    setValue([
      ...checks,
      // Add the union of all the fields for a new condition so that the preselected `select` `option` show "Select X from the dropdown"
      getCheckTemplate(),
    ]);
  }

  function onRemoveCondition(index) {
    const newValue = checks.slice();
    newValue.splice(index, 1);
    setValue(newValue);
    if (newValue.length === 0) {
      onRemoveLastCondition();
    }
  }

  return (
    <>
      {checks.map((check, i) => (
        <details key={i} className="p-2">
          <summary>
            {summariseCheck(check, parentFieldId, schema)}
            <IconButton
              aria-label="delete"
              onClick={() => onRemoveCondition(i)}
            >
              <FontAwesomeIcon icon={faTrash} size="xs" />
            </IconButton>
          </summary>
          <Check
            criteriaOptions={criteriaOptions}
            namespace={withArrayNamespace(ns, i)}
            parentFieldId={parentFieldId}
          />
        </details>
      ))}
      <IconButton
        aria-label="Add Condition"
        onClick={onClickAddCondition}
        size="small"
      >
        <FontAwesomeIcon icon={faPlus} size="xs" />
        &nbsp;Condition
      </IconButton>
    </>
  );
}
