import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const SaleSubTypeSerializeMap = {
  name: "name",
  speciesId: "species_id",
  saleType: "sale_type",
  id: "id",
  enableStudFeatures: "enable_stud_features",
  enableDentition: "enable_dentition",
  enableWeightRangeAndDressing: "enable_weight_range_and_dressing",
  isFinancialSaleSubType: "is_financial_sale_sub_type",
};

export const serializeSaleSubType = buildSerializer(
  SaleSubTypeSerializeMap,
  "saleSubType",
);

export const deserializeSaleSubType = buildDeserializer(
  SaleSubTypeSerializeMap,
  "saleSubType",
);
