import { Column } from "constants/columns";

export function hasExceptionsTriStateFilter(fieldValue, selectedValue, row) {
  const hasExceptions = row[Column.HAS_EXCEPTIONS];
  if (selectedValue > 0) {
    return hasExceptions;
  } else if (selectedValue < 0) {
    return !hasExceptions;
  }
  return true;
}
