import React from "react";

import PropTypes from "prop-types";

import { SingleWeighStatus } from "constants/singleWeighIconStatus";

import {
  FrontGateClosedBackGateClosed,
  FrontGateClosedBackGateLocked,
  FrontGateClosedBackGateOpen,
  FrontGateLockedBackGateOpen,
  FrontGateLockedBackGateClosed,
  FrontGateLockedBackGateLocked,
  FrontGateOpenBackGateOpen,
  FrontGateOpenBackGateClosed,
  FrontGateOpenBackGateLocked,
  Unknown,
} from "./SingleWeighStatuses";

const SingleWeighStatusIcon = ({ status, color, size }) => {
  switch (status) {
    // known statuses
    case SingleWeighStatus.FRONT_GATE_CLOSED_BACK_GATE_CLOSED:
      return <FrontGateClosedBackGateClosed color={color} size={size} />;
    case SingleWeighStatus.FRONT_GATE_CLOSED_BACK_GATE_LOCKED:
      return <FrontGateClosedBackGateLocked color={color} size={size} />;
    case SingleWeighStatus.FRONT_GATE_CLOSED_BACK_GATE_OPEN:
      return <FrontGateClosedBackGateOpen color={color} size={size} />;
    case SingleWeighStatus.FRONT_GATE_LOCKED_BACK_GATE_CLOSED:
      return <FrontGateLockedBackGateClosed color={color} size={size} />;
    case SingleWeighStatus.FRONT_GATE_LOCKED_BACK_GATE_OPEN:
      return <FrontGateLockedBackGateOpen color={color} size={size} />;
    case SingleWeighStatus.FRONT_GATE_LOCKED_BACK_GATE_LOCKED:
      return <FrontGateLockedBackGateLocked color={color} size={size} />;
    case SingleWeighStatus.FRONT_GATE_OPEN_BACK_GATE_OPEN:
      return <FrontGateOpenBackGateOpen color={color} size={size} />;
    case SingleWeighStatus.FRONT_GATE_OPEN_BACK_GATE_CLOSED:
      return <FrontGateOpenBackGateClosed color={color} size={size} />;
    case SingleWeighStatus.FRONT_GATE_OPEN_BACK_GATE_LOCKED:
      return <FrontGateOpenBackGateLocked color={color} size={size} />;

    // unknown status
    case SingleWeighStatus.UNKNOWN:
      return <Unknown color={color} size={size} />;

    default:
      return <Unknown color={color} size={size} />;
  }
};

SingleWeighStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default React.memo(SingleWeighStatusIcon);
