import React, { memo, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import { SaleAction, setCurrentLivestockSaleId } from "actions";

import SecurityTrimmedRoute from "components/Authorization/SecurityTrimmedRoute";
import LoadingSpinner from "components/LoadingSpinner";
import MessageBox from "components/MessageBox";
import SingleWeighView from "components/SingleWeighView";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { Settings } from "constants/settings";

import { getSaleyardAuctionRoute } from "lib/navigation";

import {
  getActiveRole,
  getCurrentSale,
  getCurrentSaleyard,
  getIsFetchingSales,
  getSaleById,
  getSetting,
  selectRoleCurrentDeployments,
} from "selectors";

import { AuctionRoutes } from "./AuctionRoutes";
import { LivestockSale } from "./LivestockSale";

const LivestockSales = () => {
  const { saleyard } = useParams();
  const match = useRouteMatch();

  const isFetchingSales = useSelector(getIsFetchingSales);

  const sale = useSelector(getCurrentSale);

  const livestockSaleId = +match.params.saleId;

  const selectedSaleExists = useSelector(state =>
    Boolean(getSaleById(livestockSaleId)(state)),
  );

  const dispatch = useDispatch();
  const activeRoleSlug = useSelector(getActiveRole).slug;
  const allSalesLoaded = useSelector(getSetting(Settings.allSalesLoaded));

  useEffect(() => {
    if (!selectedSaleExists && !allSalesLoaded) {
      dispatch(SaleAction.request({ fetchAll: true }));
    }
  }, [selectedSaleExists, allSalesLoaded, sale, dispatch]);

  useEffect(() => {
    // On Mount and changing user roles, fetch data specific to this sale, if it exists.
    // If we've just logged in from a deep link, it MAY not exist in our state yet - if that's the case, don't do anything, until
    // it does.
    if (selectedSaleExists) {
      dispatch(setCurrentLivestockSaleId(livestockSaleId, false));
    }

    return () => {
      // Clear the sale, so we don't get do changes since.
      dispatch(setCurrentLivestockSaleId(null, false));
    };
  }, [
    dispatch,
    livestockSaleId,
    activeRoleSlug,
    selectedSaleExists,
    allSalesLoaded,
  ]);
  if (!sale) {
    if (isFetchingSales) {
      return (
        <MessageBox>
          <LoadingSpinner subjectName="Sales" size={24} />
        </MessageBox>
      );
    } else {
      return (
        <MessageBox>
          Livestock sale could not be found. This may be because the sale does
          not exist (it may have been deleted), or you do not have sufficient
          access to the {saleyard} saleyard. Create a new sale or select an
          existing sale from the menu above.
        </MessageBox>
      );
    }
  }

  return (
    <Switch>
      {/* Auction routes - don't go through livestocksale as we don't want
        the header/sidebar/etc */}
      <SecurityTrimmedRoute
        objectArraySelector={selectRoleCurrentDeployments}
        permissionRequired={DeploymentPermissions.featureAuction}
        path={`${getSaleyardAuctionRoute(":saleyard", ":saleId")}/round`}
        component={AuctionRoutes}
      />

      <SecurityTrimmedRoute
        objectSelector={getCurrentSaleyard}
        permissionRequired={SaleyardPermissions.featureSingleWeigh}
        path={`${match.path}/single-weigh`}
        component={SingleWeighView}
      />

      {/* Normal routes through sales - /overview /consignment-cards, etc, */}
      <Route path={`${match.path}/:section?`} component={LivestockSale} />
    </Switch>
  );
};

export default memo(LivestockSales);
