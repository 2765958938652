import React from "react";

import { Formik } from "formik";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";
import * as Yup from "yup";

import AnimalListItem from "components/AnimalListItem";
import { ModalBg, ModalHeader } from "components/Modal";

import { getTimeSince } from "lib/timeFormats";

import AnimalForm from "./AnimalForm";

const AnimalFormValidationSchema = Yup.object().shape(
  {
    consignment: Yup.string()
      .nullable(true)
      .when("auctionPen", {
        is: Boolean,
        then: Yup.string()
          .nullable(true)
          .required("Vendor is required when Sale Lot set"),
      })
      .nullable(),
    auctionPen: Yup.string()
      .nullable(true)
      .when("consignment", {
        is: Boolean,
        then: Yup.string()
          .nullable(true)
          .required("Sale Lot is required when Vendor set"),
      }),
    deceased: Yup.bool(),
  },
  [["consignment", "auctionPen"]],
);

const AnimalModal = ({
  scan,
  consignments,
  auctionPens,
  saleLots,
  closeSelf,
  deleteAnimal,
  saveAnimal,
  markDeceased,
  allowBlankConsignment,
  allowBlankAuctionPen,
}) => {
  const saleLot = scan.sale_lot_id ? saleLots[scan.sale_lot_id] : null;

  const initialValues = {
    deceased: scan.animal ? Boolean(scan.animal.marked_deceased) : false,
    consignment: saleLot ? saleLot.consignment_id : null,
    auctionPen: saleLot ? saleLot.auction_pen_id : null,
  };

  return (
    <ModalBgStyled>
      <ModalHeaderStyled title="View Animal" closeSelf={closeSelf} />
      {scan.created ? (
        <Small>Last edit {getTimeSince(scan.created)} ago</Small>
      ) : null}

      <AnimalListItemStyled {...scan} />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AnimalFormValidationSchema}
        onSubmit={saveAnimal}
      >
        <AnimalForm
          auctionPens={auctionPens}
          consignments={consignments}
          handleDelete={deleteAnimal}
          markDeceased={markDeceased}
          allowBlankConsignment={allowBlankConsignment}
          allowBlankAuctionPen={allowBlankAuctionPen}
        />
      </Formik>
    </ModalBgStyled>
  );
};

AnimalModal.propTypes = {
  scan: PropTypes.object,
  consignments: PropTypes.array,
  auctionPens: PropTypes.array,
  saleLots: PropTypes.object,
  closeSelf: PropTypes.func,
  deleteAnimal: PropTypes.func,
  saveAnimal: PropTypes.func,
  markDeceased: PropTypes.func,
};

AnimalModal.defaultProps = {
  scan: {},
};

export default AnimalModal;

const ModalHeaderStyled = styled(ModalHeader)`
  margin-bottom: 0;
`;

const AnimalListItemStyled = styled(AnimalListItem)`
  margin: ${({ theme }) => theme.space[2]}px 0
    ${({ theme }) => theme.space[4]}px ${({ theme }) => theme.space[2]}px;
`;

const Small = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.beta}px;
  color: ${({ theme }) => theme.colors.gray9C};
  font-style: italic;
  margin-left: ${({ theme }) => theme.space[2]}px;
`;

const ModalBgStyled = styled(ModalBg).attrs(({ theme }) => ({
  backgroundStyle: {
    backgroundColor: "rgba(102,102,102,0.5)",
  },
  contentStyle: {
    height: "100%",
    maxWidth: "100%",
    padding: `${theme.space[2]}px`,
    backgroundColor: theme.colors.white,
    overflowY: "auto",
  },
}))``;
