import React, { useState } from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { patchDeploymentSale } from "actions/offline/deploymentSale";

import Badge from "components/Badge";
import SlimButton from "components/Button/SlimButton";
import RowCard from "components/Card/RowCard";
import { ConfirmDialog } from "components/ConfirmDialog";
import { ExclamationIcon } from "components/Icons";
import { Row, HorizontalScrollBox } from "components/Layout";
import TabSelector from "components/TabSelector";
import { SmHeading } from "components/Text";

import { LivestockSalePermissions } from "constants/permissions";

import { getLivestockSaleId } from "lib/navigation";

import {
  getIsBalancedByDeploymentSaleId,
  getHasExceptionsByDeploymentSaleId,
  getAgencyByDeploymentSaleId,
  getCurrentSale,
  getHasWarningsByDeploymentSaleId,
  getHasWriteAccessInCurrentSale,
} from "selectors";

import { useHasPermission } from "hooks";

import DeploymentSaleSummaryTableMobile from "./DeploymentSaleSummaryTableMobile";
import ExceptionsView from "./ExceptionsView";

const TabView = {
  NONE: 0,
  EXCEPTIONS: 2,
};

const Wrapper = styled.div(
  ({ theme, status }) => `
    padding: ${theme.space[1]}px;
    border-spacing: 0;
    border-left: ${theme.space[1]}px ${theme.colors[status]} solid;
    width: 100%; 
`,
);

function DeploymentSaleSummaryMobile(props) {
  const { deploymentSaleId } = props;
  const [isConfirmSaleVisible, setIsConfirmSaleVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TabView.NONE);
  const isBalanced = useSelector(
    getIsBalancedByDeploymentSaleId(deploymentSaleId),
  );
  const hasExceptions = useSelector(
    getHasExceptionsByDeploymentSaleId(deploymentSaleId),
  );
  const hasWarnings = useSelector(
    getHasWarningsByDeploymentSaleId(deploymentSaleId),
  );
  const sale = useSelector(getCurrentSale);

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const dispatch = useDispatch();

  const agencyShortName = useSelector(
    getAgencyByDeploymentSaleId(deploymentSaleId),
  )?.shortName;

  function toggleTab(tab) {
    tab === selectedTab ? setSelectedTab(TabView.NONE) : setSelectedTab(tab);
  }

  function onClickCloseTabs() {
    setSelectedTab(TabView.NONE);
  }
  function onClickExceptionsTab() {
    toggleTab(TabView.EXCEPTIONS);
  }

  function setConfirmSaleVisible() {
    setIsConfirmSaleVisible(true);
  }

  function setConfirmSaleHidden() {
    setIsConfirmSaleVisible(false);
  }

  const onConfirmSaleClick = () => {
    dispatch(
      patchDeploymentSale(
        { is_confirmed: true },
        deploymentSaleId,
        getLivestockSaleId(),
      ),
    );
    setConfirmSaleHidden();
  };
  const status = isBalanced ? "ready" : "ongoing";
  const statusText = isBalanced ? "Balanced" : "Ongoing";

  const deploymentSale = sale.deployment_sales.find(
    o => o.deployment_sale_id === deploymentSaleId,
  );

  const { is_sale_locked: isSaleLocked } = deploymentSale;

  const hasForceConfirmSalePermission = useHasPermission(
    getCurrentSale,
    LivestockSalePermissions.canForceConfirmSales,
  );

  const showConfirmButton =
    !deploymentSale.is_confirmed &&
    (hasForceConfirmSalePermission ||
      (hasWriteAccessInCurrentSale && isBalanced && !hasExceptions));

  return (
    <>
      <RowCard>
        <Wrapper status={status}>
          <Row justifyBetween>
            <SmHeading bold>{agencyShortName}</SmHeading>
            <div>
              {(hasExceptions || hasWarnings) && (
                <TabSelector
                  selected={selectedTab === TabView.EXCEPTIONS}
                  onClick={onClickExceptionsTab}
                  color={hasExceptions ? "error" : "gold"}
                >
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    style={{ fontSize: 12, marginRight: 4 }}
                  />
                  {hasExceptions ? "EXCEPTIONS" : "WARNINGS"}
                </TabSelector>
              )}
            </div>
            <div className="balancedStatus">
              <Badge data-tour="badge" color={status} uppercase>
                {statusText}
              </Badge>
            </div>

            <div>
              {deploymentSale.is_confirmed && (
                <div className="balancedStatus">
                  <Badge data-tour="badge" color={status} uppercase>
                    Confirmed
                  </Badge>
                </div>
              )}
            </div>
            {isSaleLocked && (
              <div>
                <Badge
                  data-tour="deploymentSaleIsLocked"
                  color="ready"
                  uppercase
                  margin={1}
                >
                  Locked
                </Badge>
              </div>
            )}
            <div>
              {showConfirmButton && (
                <div className="balancedStatus">
                  <SlimButton onClick={setConfirmSaleVisible}>
                    {hasExceptions && <ExclamationIcon />}
                    Confirm
                  </SlimButton>
                  <ConfirmDialog
                    isOpen={isConfirmSaleVisible}
                    onCancel={setConfirmSaleHidden}
                    onDelete={onConfirmSaleClick}
                    buttonMessage="Confirm"
                    messageIcon={hasExceptions && faExclamationCircle}
                    question={`Are you sure you want to confirm this sale${
                      hasExceptions ? " with exceptions" : ""
                    }?`}
                    message={
                      hasExceptions
                        ? "Confirming your sale will trigger the sending of post-sale reports to relevant parties - this may result in incomplete or incorrect information sent to Buyers. "
                        : " Confirming your sale will trigger the sending of post-sale reports to relevant parties"
                    }
                    title={`Confirm Sale ${
                      hasExceptions ? " With Exceptions" : ""
                    }`}
                  />
                </div>
              )}
            </div>
          </Row>
          <HorizontalScrollBox>
            <DeploymentSaleSummaryTableMobile
              deploymentSaleId={deploymentSaleId}
            />
          </HorizontalScrollBox>
        </Wrapper>
      </RowCard>
      {selectedTab === TabView.EXCEPTIONS && (
        <ExceptionsView
          deploymentSaleId={deploymentSaleId}
          handleClose={onClickCloseTabs}
          isMobile
        />
      )}
    </>
  );
}

export default DeploymentSaleSummaryMobile;
