import { intersection, uniq } from "lodash";
import { createSelector } from "reselect";

import { ExportSites } from "constants/exportSites";
import { GlobalSearchFields } from "constants/globalSearch";
import { ScanStatus } from "constants/scanner";

import { getConsignmentCode } from "lib/consignments";
import {
  doesSaleLotHaveOverflowPen,
  getBuyerHashFromSaleLot,
  getIsWeighed,
  getSaleLotScannedStatus,
  getScanStatusThreshold,
  isSaleLotBalanced,
  isSaleLotCounted,
  isSaleLotDelivered,
  isSaleLotInProgress,
  isSold,
} from "lib/saleLot";

import {
  createIdByKeySelector,
  createLookupCombiner,
  createLookupSelectors,
  getConsignments,
  getCurrentDeploymentSalesList,
  getCurrentSale,
  getCurrentSaleyard,
  getGlobalSearchBySearchField,
  getReceivalLots,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScans,
  getSexes,
  selectAuctionPenIdBySaleLotIdLookup,
  selectConsignmentHasImageLookup,
  selectConsignmentHasVideoLookup,
  selectConsignmentIdsByDeploymentSaleIdLookup,
  selectCurrentDeploymentSaleIdsList,
  selectEidsByReceivalLotIdLookup,
  selectEidsBySaleLotIdLookup,
  selectExceptionsByConsignmentIdLookup,
  selectExceptionsBySaleLotIdLookup,
  selectIsPostSaleBalancedByConsignmentIdLookup,
  selectIsPreSaleBalancedByConsignmentIdLookup,
  selectReceivalLotIdByEidLookup,
  selectReceivalLotIdsByConsignmentIdLookup,
  selectSaleLotHasImageLookup,
  selectSaleLotHasVideoLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByConsignmentIdLookup,
  selectSaleLotIdsByDeliveryPenIdLookup,
  selectScannedCountBySaleLotIdLookup,
  selectVendorIdBySaleLotIdLookup,
} from "selectors";

import { selectIsIntegrationCompliantBySaleLotIdLookup } from "selectors/integrations";

import { isObjectChangedAfter } from "./lib";

// sitting here instead of selectors/receivaLots.js because of circular dependancies ;'(
export const selectSaleLotIdsByReceivalLotIdLookup = createSelector(
  [getReceivalLots, selectSaleLotIdsByConsignmentIdLookup],
  (receivalLotByIdLookup, saleLotIdsByConsignmentIdLookup) => {
    return Object.entries(receivalLotByIdLookup).reduce(
      (acc, [receivalLotId, receivalLot]) => {
        acc[receivalLotId] =
          saleLotIdsByConsignmentIdLookup[receivalLot.consignmentId] || [];
        return acc;
      },
      {},
    );
  },
);

// sitting here instead of selectors/receivaLots.js because of circular dependancies ;'(
const selectReceivalLotIdsBySaleLotIdLookup = createSelector(
  [getSaleLots, selectEidsBySaleLotIdLookup, selectReceivalLotIdByEidLookup], // may cause issues?
  (saleLots, eidsBySaleLotIdLookup, receivalLotIdByEidLookup) => {
    return Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      acc[saleLot.id] = uniq(
        eidsBySaleLotIdLookup[saleLotId]?.map(
          eid => receivalLotIdByEidLookup[eid],
        ),
      );
      return acc;
    }, {});
  },
);

// sitting here instead of selectors/receivaLots.js because of circular dependancies ;'(
export const selectReceivalLotIdsByDeliveryPenIdLookup = createSelector(
  [
    selectSaleLotIdsByDeliveryPenIdLookup,
    selectReceivalLotIdsBySaleLotIdLookup,
  ],
  (saleLotIdsByDeliveryPenIdLookup, receivalLotIdsBySaleLotIdLookup) => {
    return Object.entries(saleLotIdsByDeliveryPenIdLookup).reduce(
      (acc, [deliveryPenId, saleLotIds]) => {
        acc[deliveryPenId] = uniq(
          saleLotIds
            .map(saleLotId => receivalLotIdsBySaleLotIdLookup[saleLotId])
            .flat(),
        );
        return acc;
      },
      {},
    );
  },
);

export const selectUnfilteredReceivalLotIds = createSelector(
  [getReceivalLots],
  receivalLots => Object.keys(receivalLots).map(receivalLotId => receivalLotId),
);

/**
 * Create a lookup for all receival lot ids keyed by deployment sale id
 */
export const selectReceivalIdsByDeploymentSaleIdLookup = createSelector(
  [
    selectCurrentDeploymentSaleIdsList,
    selectReceivalLotIdsByConsignmentIdLookup,
    selectConsignmentIdsByDeploymentSaleIdLookup,
  ],
  (
    deploymentSaleIds,
    receivalLotIdsByConsignmentId,
    consignmentIdsByDeploymentSaleId,
  ) => {
    // Move through deployment sale ids
    return deploymentSaleIds.reduce((acc, deploymentSaleId) => {
      // get list of consignment ids from the deployment sale
      const consignmentIds =
        consignmentIdsByDeploymentSaleId[deploymentSaleId] || [];
      // use the consignment ids to provide a list of receival lot ids
      acc[deploymentSaleId] = consignmentIds.reduce((acc, consignmentId) => {
        const receivalLotIds =
          receivalLotIdsByConsignmentId[consignmentId] || [];
        return acc.concat(receivalLotIds);
      }, []);
      // return lookup
      return acc;
    }, {});
  },
);

/**
 * Return all receival lot ids associated with deployment sales that are linked
 * to the filtered agency ids
 */
const selectAgencyFilteredReceivalLotIds = createSelector(
  [
    getCurrentDeploymentSalesList,
    getGlobalSearchBySearchField(GlobalSearchFields.Agency),
    selectUnfilteredReceivalLotIds,
    selectReceivalIdsByDeploymentSaleIdLookup,
  ],
  (
    deploymentSales,
    agencyIds,
    unfilteredReceivalLotIds,
    receivalLotIdsByDeploymentSaleIdLookup,
  ) => {
    const value =
      agencyIds === null
        ? unfilteredReceivalLotIds
        : // check the selected agency id against their deployment sales agency id
          deploymentSales
            .filter(deploymentSale =>
              agencyIds.includes(deploymentSale.livestock_agency_id),
            )
            // get the receival lot ids from the deployment sales
            .map(
              deploymentSale =>
                receivalLotIdsByDeploymentSaleIdLookup[
                  deploymentSale.deployment_sale_id
                ],
            )
            .flat();
    return value;
  },
);

/**
 * Return recevial lot ids for filtered auction pens based on their linked sale lots
 */
const selectAuctionPenFilteredReceivalLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.AuctionPen),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByAuctionPenIdLookup,
    selectReceivalLotIdsBySaleLotIdLookup,
  ],
  (
    auctionPenIds,
    unfilteredReceivalLotIds,
    saleLotIdsByAuctionPenId,
    receivalLotIdsBySaleLotIdLookup,
  ) => {
    const receivalLotIds = [];

    // build a list of receivalLotIds linked to the global search auction pens
    // and return them if they are available
    auctionPenIds?.forEach(auctionPenId => {
      Object.values(saleLotIdsByAuctionPenId[auctionPenId])?.forEach(
        saleLotId => {
          receivalLotIdsBySaleLotIdLookup[saleLotId]?.forEach(receivalLotId => {
            receivalLotIds.push(receivalLotId);
          });
        },
      );
    });

    return auctionPenIds === null ? unfilteredReceivalLotIds : receivalLotIds;
  },
);

/**
 * filter and return receival lot ids that have the filtered buyer ids
 * associated to them
 */
const selectBuyerFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Buyer),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (saleLots, buyerIds, unfilteredValues, saleLotIdsByReceivalLotIdLookup) =>
    buyerIds === null
      ? unfilteredValues
      : // filter sale lot ids based on if the buyer is included
        // in the global search field
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              buyerIds.includes(saleLots[saleLotId].buyer_id),
            ),
          )
          // return receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * filter and return receival lot ids that have the filtered buyer and buyer way
 * combinations (buyer hash) associated to them
 */
const selectBuyerAndBuyerWayFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerAndBuyerWay),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (saleLots, buyerHashes, unfilteredValues, saleLotIdsByReceivalLotIdLookup) =>
    buyerHashes === null
      ? unfilteredValues
      : // filter sale lots based on if their buyer and buyer way combination
        // matches the global search field
        uniq(
          Object.entries(saleLotIdsByReceivalLotIdLookup)
            .filter(([ignored, saleLotIds]) =>
              saleLotIds?.some(saleLotId =>
                buyerHashes.includes(
                  getBuyerHashFromSaleLot(saleLots[saleLotId]),
                ),
              ),
            )
            // return receival lot ids
            .map(([receivalLotId, ignored]) => receivalLotId),
        ),
);

/**
 * Filter and return receival lot ids that have the filtered delivery pen ids
 * associated to them
 */
const selectDeliveryPenFilteredReceivalLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.DeliveryPen),
    selectUnfilteredReceivalLotIds,
    selectReceivalLotIdsByDeliveryPenIdLookup,
  ],
  (
    deliveryPenIds,
    unfilteredReceivalLotIds,
    receivalLotIdsByDeliveryPenIdLookup,
  ) => {
    // create a list for receival lot ids
    const filteredReceivalLotIds = [];
    // filter through delivery pens linked to receival lots returning
    // delivery pens that are present in the global search field
    Object.entries(receivalLotIdsByDeliveryPenIdLookup)
      .filter(([deliveryPenId, ignored]) =>
        deliveryPenIds?.includes(deliveryPenId),
      )
      // populate a list of filtered receival lot ids
      .forEach(([ignored, receivalLotIds]) =>
        receivalLotIds.forEach(receivalLotId => {
          filteredReceivalLotIds.push(receivalLotId);
        }),
      );
    return deliveryPenIds === null
      ? unfilteredReceivalLotIds
      : filteredReceivalLotIds;
  },
);

/**
 * Filter and return receival lot ids based on their linked consignments thats
 * has arrived boolean matches the global search
 */
const selectHasArrivedFilteredReceivalLotIds = createSelector(
  [
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.HasArrived),
    selectUnfilteredReceivalLotIds,
    selectReceivalLotIdsByConsignmentIdLookup,
  ],
  (
    consignments,
    hasArrived,
    unfilteredReceivalLotIds,
    receivalLotIdsByConsignmentIdLookup,
  ) =>
    hasArrived === null
      ? unfilteredReceivalLotIds
      : // filter consignments based on if the has arrived boolean
        // matches the global search boolean
        Object.values(consignments)
          .filter(consignment => consignment.hasArrived === hasArrived[0])
          // return receival lot ids associated to the filtered consignments
          .reduce(
            (acc, consignment) =>
              acc
                .concat(
                  receivalLotIdsByConsignmentIdLookup[consignment.id]?.map(
                    receivalLotId => receivalLotId,
                  ),
                )
                .filter(Boolean),
            [],
          ),
);

/**
 * Filter and return receival lot ids based on their linked sale lots
 * that have buyer exceptions based on the global search boolean
 */
const selectHasBuyerExceptionsFilteredReceivalLotIds = createSelector(
  [
    selectExceptionsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasBuyerExceptions),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectReceivalLotIdsBySaleLotIdLookup,
  ],
  (
    exceptionsBySaleLotIdLookup,
    hasBuyerExceptions,
    unfilteredReceivalLotIds,
    saleLotIdsByReceivalLotIdLookup,
    receivalLotIdsBySaleLotIdLookup,
  ) => {
    // go through sale lot ids linked to receival lots
    // filter them based on if they have exceptions
    // equal to the global search boolean
    const filteredReceivalLotIds = Object.entries(
      saleLotIdsByReceivalLotIdLookup,
    )
      .map(([ignored, saleLotIds]) => saleLotIds)
      .flat()
      .filter(saleLotId => {
        return (
          (exceptionsBySaleLotIdLookup[saleLotId].length === 0) ===
          hasBuyerExceptions?.[0]
        );
      })
      // return receival ids linked to the filtered sale lots
      .map(saleLotId => receivalLotIdsBySaleLotIdLookup[saleLotId])
      .flat()
      .filter(Boolean);

    return hasBuyerExceptions === null
      ? unfilteredReceivalLotIds
      : filteredReceivalLotIds;
  },
);

/**
 * Filter and return receival lot ids based on if their scans are
 * consignment scanned
 */
export const selectHasConsignmentScansFilteredReceivalLotIds = createSelector(
  [
    getSaleyardScanSaleLots,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasConsignmentScans),
    selectUnfilteredReceivalLotIds,
    getScans,
  ],
  (
    saleyardScanSaleLots,
    eidsBySaleLotIdLookup,
    hasConsignmentScans,
    unfilteredReceivalLotIds,
    scanByEidLookup,
  ) =>
    hasConsignmentScans === null
      ? unfilteredReceivalLotIds
      : // go through eids linked to sale lots
        // filter for saleyard scans exist based on
        // the global search boolean
        Object.entries(eidsBySaleLotIdLookup)
          .filter(
            ([saleLotId, ignored]) =>
              Boolean(saleyardScanSaleLots[saleLotId]) ===
              hasConsignmentScans[0],
          )
          // return receival lot ids from the filtered eids
          .reduce(
            (acc, [ignored, eids]) =>
              acc.concat([
                ...eids.map(eid => scanByEidLookup[eid]?.receival_lot_id),
              ]),
            [],
          ),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots have overflow pens
 */
const selectHasOverflowFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.HasOverflow),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (saleLots, hasOverflow, unfilteredValues, saleLotIdsByReceivalLotIdLookup) =>
    hasOverflow === null
      ? unfilteredValues
      : Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                doesSaleLotHaveOverflowPen(saleLots[saleLotId]) ===
                hasOverflow[0],
            ),
          )
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Return if sale lot ids associated to receival lot id can have progeny
 */
const canSaleLotsHaveProgenyByReceivalLotIdLookup = (
  saleLotIds,
  saleLots,
  sexes,
) => {
  return saleLotIds.some(
    saleLotId => sexes?.[saleLots[saleLotId]?.sex_id]?.hasProgeny || false,
  );
};

export const [
  selectCanSaleLotsHaveProgenyByReceivalLotIdLookup,
  getCanSaleLotsHaveProgenyByReceivalLotId,
] = createLookupSelectors(
  [selectSaleLotIdsByReceivalLotIdLookup, getSaleLots, getSexes],
  createLookupCombiner(canSaleLotsHaveProgenyByReceivalLotIdLookup),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots have progeny
 */
const selectHasProgenyFilteredReceivalLotIds = createSelector(
  [
    getReceivalLots,
    selectCanSaleLotsHaveProgenyByReceivalLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasProgeny),
    selectUnfilteredReceivalLotIds,
  ],
  (
    receivalLots,
    saleLotsCanHaveProgenyByReceivalLotIdLookup,
    hasProgeny,
    unfilteredReceivalLotIds,
  ) =>
    hasProgeny === null
      ? unfilteredReceivalLotIds
      : // go through each receival lot and filter according to
        // the has progeny global filter boolean
        Object.values(receivalLots)
          .filter(
            receivalLot =>
              saleLotsCanHaveProgenyByReceivalLotIdLookup[receivalLot.id] ===
              hasProgeny[0],
          )
          // return receival lot ids
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots have vendor exceptions
 */
export const selectHasVendorExceptionsFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    selectExceptionsByConsignmentIdLookup,
    selectEidsBySaleLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasVendorExceptions),
    selectUnfilteredReceivalLotIds,
    getScans,
  ],
  (
    saleLots,
    exceptionsByConsignmentIdLookup,
    eidsBySaleLotIdLookup,
    hasVendorExceptions,
    unfilteredEids,
    scanByEidLookup,
  ) =>
    hasVendorExceptions === null
      ? unfilteredEids
      : // go through each sale lot and check if the linked consignment
        // has vendor exceptions equal to the global search boolean
        Object.values(saleLots)
          .filter(
            saleLot =>
              (exceptionsByConsignmentIdLookup[saleLot.consignment_id]
                .length !==
                0) ===
              hasVendorExceptions[0],
          )
          // return receival lot ids on filtered sale lots eids
          .reduce(
            (acc, saleLot) =>
              acc.concat([
                ...(eidsBySaleLotIdLookup[saleLot.id]
                  ?.map(eid => scanByEidLookup[eid]?.receival_lot_id)
                  .flat() || []),
              ]),
            [],
          ),
);

/**
 * Returns a lookup keyed by receival lot id and a boolean value based on whether
 * the recevial lot has a weight (on the associated sale lots, or scans)
 */
export const selectIsWeighedByReceivalLotIdLookup = createSelector(
  [
    getReceivalLots,
    selectSaleLotIdsByReceivalLotIdLookup,
    getScans,
    selectEidsByReceivalLotIdLookup,
    getSaleLots,
  ],
  createLookupCombiner(
    (
      receivalLot,
      saleLotIdsByReceivalLotIdLookup,
      scansLookup,
      eidsByReceivalLotIdLookup,
      saleLots,
    ) => {
      const eids = eidsByReceivalLotIdLookup[receivalLot.id] || [];
      const scans = eids.map(eid => scansLookup[eid]);
      const saleLotIds = saleLotIdsByReceivalLotIdLookup[receivalLot.id] || [];
      return saleLotIds.some(saleLotId =>
        getIsWeighed(saleLots[saleLotId], scans),
      );
    },
  ),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots or scans are weighed
 */
const selectHasWeightFilteredReceivalLotIds = createSelector(
  [
    selectIsWeighedByReceivalLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.HasWeight),
    selectUnfilteredReceivalLotIds,
    getReceivalLots,
  ],
  (
    isWeighedByReceivalLotIdLookup,
    hasWeight,
    unfilteredReceivalLotIds,
    receivalLots,
  ) => {
    return hasWeight === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if they have a weight
        Object.values(receivalLots)
          .filter(
            receivalLot =>
              isWeighedByReceivalLotIdLookup[receivalLot.id] === hasWeight[0],
          )
          .map(lot => lot.id)
          .filter(Boolean);
  },
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots are balanced
 */
const selectIsBuyerBalancedFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsBuyerBalanced),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (
    saleLots,
    isBuyerBalanced,
    unfilteredValues,
    saleLotIdsByReceivalLotIdLookup,
  ) =>
    isBuyerBalanced === null
      ? unfilteredValues
      : // filter sale lots on whether they are balanced based on
        // the global search field
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                isSaleLotBalanced(saleLots[saleLotId]) === isBuyerBalanced[0],
            ),
          )
          // return the receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots are counted
 */
const selectIsCountedFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsCounted),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (saleLots, isCounted, unfilteredValues, saleLotIdsByReceivalLotIdLookup) =>
    isCounted === null
      ? unfilteredValues
      : // filter sale lots based on if they are counted according to
        // the global search boolean
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                isSaleLotCounted(saleLots[saleLotId]) === isCounted[0],
            ),
          )
          // return the receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots are delivered
 */
const selectIsDeliveredFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsDelivered),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (saleLots, isDelivered, unfilteredValues, saleLotIdsByReceivalLotIdLookup) =>
    isDelivered === null
      ? unfilteredValues
      : // filter sale lots by receival scan, checking if any linked sale lots
        // are delivered according the the global search boolean
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                isSaleLotDelivered(saleLots[saleLotId]) === isDelivered[0],
            ),
          )
          // return receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

function saleLotsScanStatusByReceivalLotId(
  receivalLot,
  scannedCountBySaleLotIdLookup,
  saleLotIdsByReceivalLotIdLookup,
  saleLotLookup,
  speciesId,
  currentSaleYard,
) {
  const saleLotIds = saleLotIdsByReceivalLotIdLookup[receivalLot.id] || [];

  return saleLotIds.map(saleLotId => {
    const saleLot = saleLotLookup[saleLotId];
    return getSaleLotScannedStatus(
      saleLot,
      scannedCountBySaleLotIdLookup[saleLotId],
      getScanStatusThreshold(speciesId, currentSaleYard),
    );
  });
}

export const [
  selectSaleLotsScanStatusesByReceivalLotIdLookup,
  getSaleLotsScanStatusesByReceivalLotId,
] = createLookupSelectors(
  [
    getReceivalLots,
    selectScannedCountBySaleLotIdLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
    getSaleLots,
    state => getCurrentSale(state).species_id,
    getCurrentSaleyard,
  ],
  createLookupCombiner(saleLotsScanStatusByReceivalLotId),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots are counted
 */
const selectIsEidCompliantFilteredReceivalLotIds = createSelector(
  [
    selectSaleLotsScanStatusesByReceivalLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsEidCompliant),
    selectUnfilteredReceivalLotIds,
    getReceivalLots,
  ],
  (
    saleLotScanStatusesByReceivalLotIdLookup,
    isEidCompliant,
    unfilteredReceivalLotIds,
    receivalLots,
  ) =>
    isEidCompliant === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if their sale lot scan status is equal to
        // the global search boolean
        Object.values(receivalLots)
          .filter(
            receivalLot =>
              saleLotScanStatusesByReceivalLotIdLookup[receivalLot.id].includes(
                ScanStatus.PASS,
              ) === isEidCompliant[0],
          )
          // return the receival lots
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots are in progress
 */
const selectIsInProgressFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getReceivalLots,
    getConsignments,
    getGlobalSearchBySearchField(GlobalSearchFields.IsInProgress),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (
    saleLots,
    receivalLots,
    consignments,
    isInProgress,
    unfilteredReceivalLotIds,
    saleLotIdsByReceivalLotIdLookup,
  ) => {
    return isInProgress === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if any of their associated sale lots
        // are in progress
        Object.values(receivalLots)
          .filter(receivalLot =>
            saleLotIdsByReceivalLotIdLookup[receivalLot.id]
              ?.map(saleLotId =>
                isSaleLotInProgress(
                  saleLots[saleLotId],
                  consignments[saleLots[saleLotId]?.consignment_id],
                ),
              )
              .includes(isInProgress[0]),
          )
          // return receival lot ids
          .map(receivalLot => receivalLot.id);
  },
);

/**
 * Returns a lookup keyed by recevial lot ids - valued by a boolean - checking if any sale lots associated
 * to the receival lot is sold
 */
export const selectAreSomeSaleLotsSoldByReceivalLotIdLookup =
  createIdByKeySelector(
    createSelector(
      [
        getReceivalLots,
        getSaleLots,
        selectSaleLotIdsByReceivalLotIdLookup,
        selectVendorIdBySaleLotIdLookup,
      ],
      (
        receivalLots,
        saleLots,
        saleLotIdsByReceivalLotIdLookup,
        vendorIdBySaleLotId,
      ) =>
        Object.keys(receivalLots).reduce((acc, receivalLotId) => {
          acc[receivalLotId] = {
            receivalLotId,
            isSomeSold: saleLotIdsByReceivalLotIdLookup[receivalLotId]?.some(
              saleLotId =>
                isSold(
                  saleLots[saleLotId]?.buyer_id,
                  vendorIdBySaleLotId[saleLotId],
                ),
            ),
          };
          return acc;
        }, {}),
    ),
    "receivalLotId",
    "isSomeSold",
  );

/**
 * Filter and return receival lot ids based on if any of their linked
 * sale lots are sold
 */
const selectIsSoldFilteredReceivalLotIds = createSelector(
  [
    getReceivalLots,
    selectAreSomeSaleLotsSoldByReceivalLotIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSold),
    selectUnfilteredReceivalLotIds,
  ],
  (
    receivalLots,
    isSomeSoldByReceivalLotIdLookup,
    isSold,
    unfilteredReceivalLotIds,
  ) =>
    isSold === null
      ? unfilteredReceivalLotIds
      : // filter receival lots by if any of their associated sale lots are
        // sold based on the global search boolean
        Object.values(receivalLots)
          .filter(
            receivalLot =>
              isSomeSoldByReceivalLotIdLookup[receivalLot.id] === isSold[0],
          )
          // return receival lot ids
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if any of their linked
 * consignments are pre sale balanced
 */
const selectIsVendorPreSaleBalancedFilteredReceivalLotIds = createSelector(
  [
    getReceivalLots,
    selectIsPreSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPreSaleVendorBalanced),
    selectUnfilteredReceivalLotIds,
  ],
  (
    receivalLots,
    isPreSaleBalancedByConsignmentId,
    isVendorPresaleBalanced,
    unfilteredReceivalLotIds,
  ) =>
    isVendorPresaleBalanced === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if the linked consignment is pre sale balanced
        // in accordance with the global search boolean
        Object.values(receivalLots)
          .filter(
            receivalLot =>
              isPreSaleBalancedByConsignmentId[receivalLot.consignmentId] ===
              isVendorPresaleBalanced[0],
          )
          // return filtered receival lot ids
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if any of their linked
 * consignments are post sale balanced
 */
const selectIsVendorPostSaleBalancedFilteredReceivalLotIds = createSelector(
  [
    getReceivalLots,
    selectIsPostSaleBalancedByConsignmentIdLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.IsPostSaleVendorBalanced),
    selectUnfilteredReceivalLotIds,
  ],
  (
    receivalLots,
    isPostSaleBalancedByConsignmentIdLookup,
    isVendorBalanced,
    unfilteredReceivalLotIds,
  ) => {
    return isVendorBalanced === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if the linked consignment is post sale balanced
        // in accordance with the global search boolean
        Object.values(receivalLots)
          .filter(
            receivalLot =>
              isPostSaleBalancedByConsignmentIdLookup[
                receivalLot.consignmentId
              ] === isVendorBalanced[0],
          )
          // return filtered receival lot ids
          .map(receivalLot => receivalLot.id);
  },
);

/**
 * Filter and return receival lot ids based on if any of their linked
 * sale lot's labels are included in the global search
 */
const selectLabelFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Label),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (saleLots, labels, unfilteredValues, saleLotIdsByReceivalLotIdLookup) =>
    labels === null
      ? unfilteredValues
      : // filter sale lots based on if they have labels and if the labels
        // are in the global search field
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(
              saleLotId =>
                saleLots[saleLotId].labels?.length > 0 &&
                labels.some(
                  labelIds =>
                    intersection(saleLots[saleLotId].labels, labelIds).length,
                ),
            ),
          )
          // return receival lot ids from filtered sale lots
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Filter and return receival lot ids based on if any of their linked
 * sale lot's auction pen ids are included in the global search
 * lanes auction pen ids
 */
const selectLaneFilteredReceivalLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.Lane),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
    selectAuctionPenIdBySaleLotIdLookup,
  ],
  (
    lanes,
    unfilteredValues,
    saleLotIdsByReceivalLotIdLookup,
    auctionPenIdBySaleLotIdLookup,
  ) =>
    lanes === null
      ? unfilteredValues
      : // filter sale lots for auction pen ids available in the
        // lanes global search field
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              lanes.some(lane =>
                lane.includes(auctionPenIdBySaleLotIdLookup[saleLotId]),
              ),
            ),
          )
          // return receival lot ids from the filtered sale lots
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Filter and return receival lot ids based on if any of their linked
 * sale lot's overflow pens are included in the overflow pen global
 * search
 */
const selectOverflowPenFilteredReceivalLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.OverflowPen),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (overFlowPens, unfilteredValues, saleLotIdsByReceivalLotIdLookup) =>
    overFlowPens === null
      ? unfilteredValues
      : // filter for any sale lots linked to each receival lot that
        // has an overflow pen
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId => overFlowPens.includes(saleLotId)),
          )
          // return the receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Filter and return receival lot ids based on if any of their linked
 * sale lot's pricing type is in the global search's pricing types
 */
const selectPricingTypeFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.PricingType),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (
    saleLots,
    pricingTypes,
    unfilteredValues,
    saleLotIdsByReceivalLotIdLookup,
  ) =>
    pricingTypes === null
      ? unfilteredValues
      : // filter for any sale lots linked to each receival lot thats
        // pricing type is included in the global search field
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              pricingTypes.includes(saleLots[saleLotId].pricing_type_id),
            ),
          )
          // return the receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Filter and return receival lot ids based on if their sale round
 * is in the global search's sale rounds
 */
export const selectSaleRoundFilteredReceivalLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.SaleRound),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
    getSaleLots,
    selectReceivalLotIdsBySaleLotIdLookup,
  ],
  (
    saleRoundIds,
    unfilteredReceivalLotIds,
    saleLotIdsByReceivalLotIdLookup,
    saleLotByIdLookup,
    receivalLotIdsBySaleLotIdLookup,
  ) => {
    return saleRoundIds === null
      ? unfilteredReceivalLotIds
      : // filter sale lots linked to receival scans based on if their sale round
        // ids exist in the global search
        uniq(
          Object.entries(saleLotIdsByReceivalLotIdLookup)
            .filter(
              ([_ignored, saleLotIds]) =>
                intersection(
                  saleRoundIds,
                  saleLotIds.map(
                    saleLotId => saleLotByIdLookup[saleLotId].sale_round_id,
                  ),
                ).length,
            )
            // return receival lot ids
            .map(([_ignored, saleLotIds]) =>
              saleLotIds.map(saleLotId => [
                ...receivalLotIdsBySaleLotIdLookup[saleLotId],
              ]),
            ),
        ).flat();
  },
);

/**
 * Filter and return receival lot ids based on if their eids
 * is in the global search's eids
 */
const selectScanFilteredReceivalLotIds = createSelector(
  [
    selectReceivalLotIdByEidLookup,
    getGlobalSearchBySearchField(GlobalSearchFields.Scan),
    selectUnfilteredReceivalLotIds,
  ],
  (receivalLotIdByEidLookup, eids, unfilteredReceivalLotIds) =>
    eids === null
      ? unfilteredReceivalLotIds
      : // return the receival lot ids that have the associated
        // eid from the gloabl search field
        uniq(eids.map(eid => receivalLotIdByEidLookup[eid])).filter(Boolean),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lot's third party is in the global search third parties
 */
const selectThirdPartyFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.ThirdParty),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (
    saleLots,
    thirdPartyIds,
    unfilteredValues,
    saleLotIdsByReceivalLotIdLookup,
  ) =>
    thirdPartyIds === null
      ? unfilteredValues
      : // filter sale lots associated to receival lot ids for any sale lots
        // include the third parties in the global search field
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              thirdPartyIds.includes(saleLots[saleLotId].thirdPartyId),
            ),
          )
          // return the filtered receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

export const selectVendorIdByReceivalLotIdLookup = createSelector(
  [getReceivalLots, getConsignments],
  (receivalLotByIdLookup, consignmentByIdLookup) => {
    return Object.entries(receivalLotByIdLookup).reduce(
      (acc, [receivalLotId, receivalLot]) => {
        acc[receivalLotId] =
          consignmentByIdLookup[receivalLot.consignmentId]?.vendor_id;
        return acc;
      },
      {},
    );
  },
);

/**
 * Filter and return receival lot ids based on if their linked
 * vendor is in the global search's vendors
 */
const selectVendorFilteredReceivalLotIds = createSelector(
  [
    getGlobalSearchBySearchField(GlobalSearchFields.Vendor),
    selectUnfilteredReceivalLotIds,
    selectVendorIdByReceivalLotIdLookup,
  ],
  (vendorIds, unfilteredReceivalLotIds, vendorIdByReceivalLotIdLookup) =>
    vendorIds === null
      ? unfilteredReceivalLotIds
      : // filter vendor ids linked to receival lots that are present
        // in the global search field
        Object.entries(vendorIdByReceivalLotIdLookup)
          .filter(([ignored, vendorId]) => vendorIds.includes(vendorId))
          // return receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Filter and return receival lot ids based on if their linked
 * consignments vendor number is in the global search's vendor
 * numbers
 */
const selectVendorNumberFilteredReceivalLotIds = createSelector(
  [
    getConsignments,
    getReceivalLots,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorNumber),
    selectUnfilteredReceivalLotIds,
  ],
  (consignments, receivalLots, vendorNumbers, unfilteredReceivalLotIds) =>
    vendorNumbers === null
      ? unfilteredReceivalLotIds
      : // filter vendor numbers linked to receival lots that are present
        // in the global search field
        Object.values(receivalLots)
          .filter(receivalLot =>
            vendorNumbers.includes(
              getConsignmentCode(consignments[receivalLot.consignmentId]),
            ),
          )
          // return receival lot ids
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if their linked
 * consignments vendor PICs are in the global search's vendor
 * PICs
 */
const selectVendorPicFilteredReceivalLotIds = createSelector(
  [
    getConsignments,
    getReceivalLots,
    getGlobalSearchBySearchField(GlobalSearchFields.VendorPic),
    selectUnfilteredReceivalLotIds,
  ],
  (consignments, receivalLots, vendorPic, unfilteredReceivalLotIds) =>
    vendorPic === null
      ? unfilteredReceivalLotIds
      : // filter vendor PICs linked to receival lots that are present
        // in the global search field
        Object.values(receivalLots)
          .filter(receivalLot =>
            vendorPic.includes(
              consignments[receivalLot.consignmentId]?.vendor_property_id,
            ),
          )
          // return receival lot ids
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if their linked
 * sale lots buyer PIC is in the global search's buyer PIC
 */
const selectBuyerPicFilteredReceivalLotIds = createSelector(
  [
    getSaleLots,
    getGlobalSearchBySearchField(GlobalSearchFields.BuyerPic),
    selectUnfilteredReceivalLotIds,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (saleLots, propertyIds, unfilteredValues, saleLotIdsByReceivalLotIdLookup) =>
    propertyIds === null
      ? unfilteredValues
      : // filter sale lots associated to receival lots thats destination properties
        // are also in the global search field
        Object.entries(saleLotIdsByReceivalLotIdLookup)
          .filter(([ignored, saleLotIds]) =>
            saleLotIds?.some(saleLotId =>
              propertyIds.includes(saleLots[saleLotId].destination_property_id),
            ),
          )
          // return the associated receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Filter and return receival lot ids based on if they were
 * created or modified after the global filtered checkpoints
 */
const selectCheckpointFilteredReceivalLotIds = createSelector(
  [
    getReceivalLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Checkpoint),
    selectUnfilteredReceivalLotIds,
  ],
  (recevialLots, checkpoints, unfilteredValues) =>
    checkpoints === null
      ? unfilteredValues
      : // filter receival lots that have been updated or created after
        // the selected checkpoint
        Object.values(recevialLots)
          .filter(receivalLot =>
            isObjectChangedAfter(receivalLot, checkpoints[0]),
          )
          // return the receival lot ids
          .map(([receivalLotId, ignored]) => receivalLotId),
);

/**
 * Filter and return receival lot ids based on if any of their
 * linked sale lots are auctions plus compliant
 */
const selectIsAuctionsPlusCompliantReceivalLotIds = createSelector(
  [
    getReceivalLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredReceivalLotIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsAuctionsPlusCompliant),
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (
    receivalLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredReceivalLotIds,
    searchField,
    saleLotIdsByReceivalLotIdLookup,
  ) => {
    return searchField === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on their linked sale lots being
        // auction plus compliant in line with the global search value
        Object.values(receivalLots)
          .filter(receivalLot =>
            saleLotIdsByReceivalLotIdLookup[receivalLot.id]?.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.AUCTIONS_PLUS
                ] === searchField[0],
            ),
          )
          // return the receival lot ids
          .map(receivalLot => receivalLot.id)
          .filter(Boolean);
  },
);

/**
 * Filter and return receival lot ids based on if any of their
 * linked sale lots are stock live compliant
 */
const selectIsStockLiveCompliantReceivalLotIds = createSelector(
  [
    getReceivalLots,
    selectIsIntegrationCompliantBySaleLotIdLookup,
    selectUnfilteredReceivalLotIds,
    getGlobalSearchBySearchField(GlobalSearchFields.IsStockLiveCompliant),
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (
    receivalLots,
    isIntegrationCompliantBySaleLotIdLookup,
    unfilteredReceivalLotIds,
    searchField,
    saleLotIdsByReceivalLotIdLookup,
  ) => {
    return searchField === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on their linked sale lots being
        // stock live compliant in line with the global search value
        Object.values(receivalLots)
          .filter(receivalLot =>
            saleLotIdsByReceivalLotIdLookup[receivalLot.id]?.some(
              saleLotId =>
                isIntegrationCompliantBySaleLotIdLookup[saleLotId][
                  ExportSites.STOCK_LIVE
                ] === searchField[0],
            ),
          )
          // return the receival lot ids
          .map(receivalLot => receivalLot.id);
  },
);

/**
 * Filter and return receival lot ids based on if any of their
 * linked sale lots have images uploaded
 */
const selectIsSaleLotImageUploadedReceivalLotIds = createSelector(
  [
    getReceivalLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotImageUploaded),
    selectUnfilteredReceivalLotIds,
    selectSaleLotHasImageLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (
    receivalLots,
    IsSaleLotImageUploaded,
    unfilteredReceivalLotIds,
    SaleLotHasImageLookup,
    saleLotIdsByReceivalLotIdLookup,
  ) =>
    IsSaleLotImageUploaded === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if their linked sale lot has an image uploaded
        // in line with the global search boolean
        Object.values(receivalLots)
          .filter(receivalLot =>
            saleLotIdsByReceivalLotIdLookup[receivalLot.id]?.some(
              saleLotId =>
                SaleLotHasImageLookup[saleLotId] === IsSaleLotImageUploaded[0],
            ),
          )
          // return the receival lot ids
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if any of their
 * linked sale lots have videos uploaded
 */
const selectIsSaleLotVideoUploadedReceivalLotIds = createSelector(
  [
    getReceivalLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsSaleLotVideoUploaded),
    selectUnfilteredReceivalLotIds,
    selectSaleLotHasVideoLookup,
    selectSaleLotIdsByReceivalLotIdLookup,
  ],
  (
    receivalLots,
    isSaleLotVideoUploaded,
    unfilteredReceivalLotIds,
    saleLotHasVideoLookup,
    saleLotIdsByReceivalLotIdLookup,
  ) =>
    isSaleLotVideoUploaded === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if their linked sale lot has a video uploaded
        // in line with the global search boolean
        Object.values(receivalLots)
          .filter(receivalLot =>
            saleLotIdsByReceivalLotIdLookup[receivalLot.id]?.some(
              saleLotId =>
                saleLotHasVideoLookup[saleLotId] === isSaleLotVideoUploaded[0],
            ),
          )
          // return the receival lot ids
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if any of their
 * linked consignments have images uploaded
 */
const selectIsConsignmentImageUploadedReceivalLotIds = createSelector(
  [
    getReceivalLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsConsignmentImageUploaded),
    selectUnfilteredReceivalLotIds,
    selectConsignmentHasImageLookup,
  ],
  (
    receivalLots,
    isConsignmentImageUploaded,
    unfilteredReceivalLotIds,
    consignmentHasImageLookup,
  ) =>
    isConsignmentImageUploaded === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if their linked consignment has an image uploaded
        // in line with the global search boolean
        Object.values(receivalLots)
          .filter(
            receivalLot =>
              consignmentHasImageLookup[receivalLot.consignmentId] ===
              isConsignmentImageUploaded[0],
          )
          // return the receival lot ids
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if any of their
 * linked consignments have videos uploaded
 */
const selectIsConsignmentVideoUploadedReceivalLotIds = createSelector(
  [
    getReceivalLots,
    getGlobalSearchBySearchField(GlobalSearchFields.IsConsignmentVideoUploaded),
    selectUnfilteredReceivalLotIds,
    selectConsignmentHasVideoLookup,
  ],
  (
    receivalLots,
    isConsignmentVideoUploaded,
    unfilteredReceivalLotIds,
    consignmentHasVideoLookup,
  ) =>
    isConsignmentVideoUploaded === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if their linked consignment has a video uploaded
        // in line with the global search boolean
        Object.values(receivalLots)
          .filter(
            receivalLot =>
              consignmentHasVideoLookup[receivalLot.consignmentId] ===
              isConsignmentVideoUploaded[0],
          )
          // return the receival lot ids
          .map(receivalLot => receivalLot.id),
);

/**
 * Filter and return receival lot ids based on if any of their
 * linked sale lots have marks included in the global search's
 * marks filter
 */
const selectMarksFilteredReceivalLotIds = createSelector(
  [
    getReceivalLots,
    getGlobalSearchBySearchField(GlobalSearchFields.Marks),
    selectUnfilteredReceivalLotIds,
  ],
  (receivalLots, marks, unfilteredReceivalLotIds) =>
    marks === null
      ? unfilteredReceivalLotIds
      : // filter receival lots based on if any of their associated sale lots
        // have marks that are included in the global search field
        Object.values(receivalLots)
          .filter(receivalLot => marks.includes(receivalLot.mark))
          // return the receival lot ids
          .map(receivalLot => receivalLot.id),
);

export const selectFilteredReceivalLotIds = createSelector(
  [
    selectAgencyFilteredReceivalLotIds,
    selectAuctionPenFilteredReceivalLotIds,
    selectBuyerFilteredReceivalLotIds,
    selectBuyerAndBuyerWayFilteredReceivalLotIds,
    selectDeliveryPenFilteredReceivalLotIds,
    selectHasArrivedFilteredReceivalLotIds,
    selectHasBuyerExceptionsFilteredReceivalLotIds,
    selectHasConsignmentScansFilteredReceivalLotIds,
    selectHasOverflowFilteredReceivalLotIds,
    selectHasProgenyFilteredReceivalLotIds,
    selectHasVendorExceptionsFilteredReceivalLotIds,
    selectHasWeightFilteredReceivalLotIds,
    selectIsBuyerBalancedFilteredReceivalLotIds,
    selectIsCountedFilteredReceivalLotIds,
    selectIsDeliveredFilteredReceivalLotIds,
    selectIsEidCompliantFilteredReceivalLotIds,
    selectIsInProgressFilteredReceivalLotIds,
    selectIsSoldFilteredReceivalLotIds,
    selectIsVendorPreSaleBalancedFilteredReceivalLotIds,
    selectIsVendorPostSaleBalancedFilteredReceivalLotIds,
    selectLabelFilteredReceivalLotIds,
    selectLaneFilteredReceivalLotIds,
    selectOverflowPenFilteredReceivalLotIds,
    selectPricingTypeFilteredReceivalLotIds,
    selectSaleRoundFilteredReceivalLotIds,
    selectScanFilteredReceivalLotIds,
    selectThirdPartyFilteredReceivalLotIds,
    selectVendorFilteredReceivalLotIds,
    selectVendorNumberFilteredReceivalLotIds,
    selectVendorPicFilteredReceivalLotIds,
    selectBuyerPicFilteredReceivalLotIds,
    selectCheckpointFilteredReceivalLotIds,
    selectIsAuctionsPlusCompliantReceivalLotIds,
    selectIsStockLiveCompliantReceivalLotIds,
    selectIsSaleLotImageUploadedReceivalLotIds,
    selectIsSaleLotVideoUploadedReceivalLotIds,
    selectIsConsignmentImageUploadedReceivalLotIds,
    selectIsConsignmentVideoUploadedReceivalLotIds,
    selectMarksFilteredReceivalLotIds,
  ],
  (
    agencyFilteredReceivalLotIds,
    auctionPenFilteredReceivalLotIds,
    buyerFilteredReceivalLotIds,
    buyerAndBuyerWayFilteredReceivalLotIds,
    deliveryPenFilteredReceivalLotIds,
    hasArrivedFilteredReceivalLotIds,
    hasBuyerExceptionsFilteredReceivalLotIds,
    hasConsignmentScansFilteredReceivalLotIds,
    hasOverflowFilteredReceivalLotIds,
    hasProgenyFilteredReceivalLotIds,
    hasVendorExceptionsFilteredReceivalLotIds,
    hasWeightFilteredReceivalLotIds,
    isBuyerBalancedFilteredReceivalLotIds,
    isCountedFilteredReceivalLotIds,
    isDeliveredFilteredReceivalLotIds,
    isEidCompliantFilteredReceivalLotIds,
    isInProgressFilteredReceivalLotIds,
    isSoldFilteredReceivalLotIds,
    isVendorPreSaleBalancedFilteredReceivalLotIds,
    isVendorPostSaleBalancedFilteredReceivalLotIds,
    labelFilteredReceivalLotIds,
    laneFilteredReceivalLotIds,
    overflowPenFilteredReceivalLotIds,
    pricingTypeFilteredReceivalLotIds,
    saleRoundFilteredReceivalLotIds,
    scanFilteredReceivalLotIds,
    thirdPartyFilteredReceivalLotIds,
    vendorFilteredReceivalIds,
    vendorNumberFilteredReceivalLotIds,
    vendorPicFilteredReceivalLotIds,
    buyerPicFilteredReceivalLotIds,
    checkpointFilteredReceivalLotIds,
    isAuctionsPlusCompliantReceivalLotIds,
    isStockLiveCompliantReceivalLotIds,
    isSaleLotImageUploadedReceivalLotIds,
    isSaleLotVideoUploadedReceivalLotIds,
    isConsignmentImageUploadedReceivalLotIds,
    isConsignmentVideoUploadedReceivalLotIds,
    marksFilteredReceivalLotIds,
  ) => {
    return intersection(
      agencyFilteredReceivalLotIds,
      auctionPenFilteredReceivalLotIds,
      buyerFilteredReceivalLotIds,
      buyerAndBuyerWayFilteredReceivalLotIds,
      deliveryPenFilteredReceivalLotIds,
      hasArrivedFilteredReceivalLotIds,
      hasBuyerExceptionsFilteredReceivalLotIds,
      hasConsignmentScansFilteredReceivalLotIds,
      hasOverflowFilteredReceivalLotIds,
      hasProgenyFilteredReceivalLotIds,
      hasVendorExceptionsFilteredReceivalLotIds,
      hasWeightFilteredReceivalLotIds,
      isBuyerBalancedFilteredReceivalLotIds,
      isCountedFilteredReceivalLotIds,
      isDeliveredFilteredReceivalLotIds,
      isEidCompliantFilteredReceivalLotIds,
      isInProgressFilteredReceivalLotIds,
      isSoldFilteredReceivalLotIds,
      isVendorPreSaleBalancedFilteredReceivalLotIds,
      isVendorPostSaleBalancedFilteredReceivalLotIds,
      labelFilteredReceivalLotIds,
      laneFilteredReceivalLotIds,
      overflowPenFilteredReceivalLotIds,
      pricingTypeFilteredReceivalLotIds,
      saleRoundFilteredReceivalLotIds,
      scanFilteredReceivalLotIds,
      thirdPartyFilteredReceivalLotIds,
      vendorFilteredReceivalIds,
      vendorNumberFilteredReceivalLotIds,
      vendorPicFilteredReceivalLotIds,
      buyerPicFilteredReceivalLotIds,
      checkpointFilteredReceivalLotIds,
      isAuctionsPlusCompliantReceivalLotIds,
      isStockLiveCompliantReceivalLotIds,
      isSaleLotImageUploadedReceivalLotIds,
      isSaleLotVideoUploadedReceivalLotIds,
      isConsignmentImageUploadedReceivalLotIds,
      isConsignmentVideoUploadedReceivalLotIds,
      marksFilteredReceivalLotIds,
    );
  },
);
