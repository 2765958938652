import queryString from "query-string";

import { DEFAULT_VENDOR_SPLIT, SALE_VENDOR_SPLIT } from "constants/actionTypes";
import { DeploymentPermissions } from "constants/permissions";

import { hasPermission } from "lib/permissions";
import { serializeVendorSplit } from "lib/serializers/vendorSplits";

import {
  getActiveLivestockAgentDeployment,
  getIsFetchingDefaultVendorSplits,
} from "selectors";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreatorSale = ({ changesSince, id, action, livestockSaleId } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/vendor-splits/", id, action),
    query: {
      changesSince,
      livestock_sale_id: livestockSaleId,
    },
  });

const urlCreatorDefault = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/vendor-splits/", id, action),
    query: { changesSince, is_default: true },
  });

export const DefaultVendorSplitAction = offlineActionCreator(
  DEFAULT_VENDOR_SPLIT,
  serializeVendorSplit,
  urlCreatorDefault,
  false,
);

DefaultVendorSplitAction.actionFilter = (state, changes = false) => {
  const deployment = getActiveLivestockAgentDeployment(state);
  const hasPercentageVendorSplitPermission = hasPermission(
    deployment,
    DeploymentPermissions.featurePercentageVendorSplits,
  );
  if (!hasPercentageVendorSplitPermission) {
    return null;
  } else if (!changes) {
    return DefaultVendorSplitAction.request();
  } else if (changes && !getIsFetchingDefaultVendorSplits(state)) {
    return DefaultVendorSplitAction.requestChanges({
      changesSince: state.vendorSplits.default.lastModifiedTimestamp,
    });
  } else {
    // we requested changes but are fetching already.
    return null;
  }
};

export const SaleVendorSplitAction = offlineActionCreator(
  SALE_VENDOR_SPLIT,
  serializeVendorSplit,
  urlCreatorSale,
  true,
);
