import React from "react";

import { Grid, Tooltip } from "@material-ui/core";
import { useFormikContext } from "formik";

import { SubtleBadge } from "components/Badge";
import CarrierChargeForm from "components/CarrierChargeForm";
import { CollapseLabel, LinkButton } from "components/Form";

import { sectionHasErrors } from "lib/form";

export function CarrierChargeSectionHeader({ carrierName, invoiceAmount }) {
  const formikProps = useFormikContext();

  const hasErrorBadge = sectionHasErrors(formikProps, [
    "carrierCharge.deliveryDate",
  ]);

  return (
    <Grid container>
      <SubtleBadge hasErrorBadge={hasErrorBadge}>Carrier</SubtleBadge>
      <Tooltip title="Carrier">
        <CollapseLabel>{carrierName}</CollapseLabel>
      </Tooltip>
      {invoiceAmount && (
        <Tooltip title="Invoice amount">
          <CollapseLabel>${(invoiceAmount / 100).toFixed(2)}</CollapseLabel>
        </Tooltip>
      )}
    </Grid>
  );
}

export function CarrierChargeSection(props) {
  const {
    associatedCount,
    showLinkToExisting,
    carrierChargeId,
    onFindCarrierCharge,
    onResetCarrierCharge,
  } = props;

  let actionText = "Link to an existing Carrier Charge";
  let actionHandler = onFindCarrierCharge;

  if (carrierChargeId) {
    actionText = "Unlink this Consignment";
    actionHandler = onResetCarrierCharge;
  }

  return (
    <Grid container spacing={2} direction="row">
      <CarrierChargeForm
        carrierChargeId={carrierChargeId}
        namespace="carrierCharge"
      />
      {showLinkToExisting && (
        <Grid item xs={12} sm={12}>
          {carrierChargeId && (
            <>
              Linked to {associatedCount} other Consignment
              {associatedCount !== 1 ? "s " : " "}
            </>
          )}
          <LinkButton type="button" onClick={actionHandler}>
            {actionText}
          </LinkButton>
          .
        </Grid>
      )}
    </Grid>
  );
}
