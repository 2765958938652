// Ignore errors from these URLS.
const IGNORE_ERRORS_FROM_THESE_URLS = [
  "/reports-for-user/", // TODO: Returns a 500 from django, fix it there.
];

export class ResponseError extends Error {
  /**
   * @param {Number} code HTTP Response Code
   * @param {String} message
   * @param {string} url
   */

  constructor(code, message, url, errors) {
    super(message);
    this.name = "ResponseError";
    this.message = message;
    this.code = code;
    this.url = url;
    this.errors = errors;
  }
}

export const getFullyQualifiedUrl = path =>
  process.env.REACT_APP_API_BASE_URL
    ? new URL(path, process.env.REACT_APP_API_BASE_URL).toString()
    : path;

export const fetchWithFullyQualifiedUrl = (url, options) =>
  fetch(getFullyQualifiedUrl(url), { ...options, credentials: "include" });

/**
 * @param {String} url
 * @param {Object} options
 * @param {AuthUserContext} auth
 * @returns {Promise<Response>}
 */
export const prepareFetch = (url, options = {}, auth) => {
  const headers = { "Content-Type": "application/json" };
  if (auth) {
    if (auth?.activeRole?.slug) {
      headers["X-User-Role"] = auth?.activeRole.slug || "";
    }
    // Note the importance of the null here - it's explicitly saying we
    // have NO location, vs when location services are knocked back, we
    // get an error value.
    headers["X-Geo-Location"] = JSON.stringify(
      auth.authentication.lastLocation || null,
    );
  }

  return fetchWithFullyQualifiedUrl(url, {
    ...options,
    credentials: "include",
    headers,
  });
};

/**
 * @throws {TypeError|ResponseError|Object}
 */
export const responseToJson = async response => {
  const responseContentType = response.headers.get("content-type");
  const isJsonResponse =
    responseContentType && responseContentType.includes("application/json");

  // When the API returns a 404, the cloudfront API transforms it to /index.html as 200; this is to enable react-router-dom to handle a request to nested URLs (like /saleyard/Bendigo/sale/123/auction-pens)

  const isHtmlResponse =
    responseContentType && responseContentType.includes("text/html");

  if (response.ok && !isHtmlResponse) {
    const lastModifiedTimestamp = response.headers.get("lastChange");
    if (lastModifiedTimestamp) {
      const cacheHit = Boolean(response.headers.get("x-ag-cache"));
      return {
        lastModifiedTimestamp,
        cacheHit,
        JSON: response.json(),
      };
    }
    return isJsonResponse ? response.json() : null;
  }

  if (
    IGNORE_ERRORS_FROM_THESE_URLS.find(urlPart =>
      response.url.includes(urlPart),
    )
  ) {
    return {};
  }

  const errors = isJsonResponse ? await response.json() : null;
  let message = "";
  if (errors) {
    message += Object.values(errors).map(errorMessages =>
      Array.isArray(errorMessages) ? errorMessages.join(", ") : errorMessages,
    );
  }

  throw new ResponseError(
    response.status,
    message || response.statusText,
    response.url,
    errors,
  );
};
