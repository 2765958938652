import React, { memo } from "react";

import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch, withRouter } from "react-router-dom";

import { setSetting, deleteAllUnassignedScans } from "actions";

import { ConfirmDialog } from "components/ConfirmDialog";
import ScanningScreen from "components/ScanningScreen";

import { Settings } from "constants/settings";

import AnimalModal from "containers/AnimalModal";

import {
  getConnectedDeviceId,
  getIsHubConnected,
  getUnassignedScans,
  getConsignmentById,
  getIsFetchingConsignments,
  getIsFetchingSaleLots,
  getSaleLotById,
  getSetting,
} from "selectors";

import { useBoolean, useHasReceivalOrPenScanLotsPermission } from "hooks";

const ScanningScreenView = props => {
  const [isConfirmRescanOpen, openConfirmRescan, closeConfirmRescan] =
    useBoolean();

  const {
    closeSelf,
    consignmentId,
    mode,
    resume,
    saleLotId,
    penArchetypeId,
    penId,
    scanLotId,
    penType,
  } = props;
  const preferredScanningMode = useSelector(
    getSetting(Settings.preferredScanningMode),
  );
  const dispatch = useDispatch();

  function updatePreferredScanningMode(value) {
    dispatch(setSetting(Settings.preferredScanningMode, value));
  }
  const isLoading = useSelector(
    state => getIsFetchingSaleLots(state) || getIsFetchingConsignments(state),
  );
  const saleLot = useSelector(getSaleLotById(saleLotId));

  const useSaleLotConsignmentId = saleLot && !consignmentId;

  const calculatedConsignmentId = useSaleLotConsignmentId
    ? saleLot.consignment_id
    : consignmentId;

  const consignment = useSelector(getConsignmentById(calculatedConsignmentId));
  const match = useRouteMatch();

  const connectedDeviceId = useSelector(getConnectedDeviceId);
  const isHubConnected = useSelector(getIsHubConnected);
  const isConnectedToDevice = Boolean(connectedDeviceId);

  const isPenOrReceivalScanning =
    useHasReceivalOrPenScanLotsPermission() && !!penType;

  const hasUnassignedScans = useSelector(
    state => Object.values(getUnassignedScans(state)).length > 0,
  );

  const handleClose = () => {
    // if we are in receival or pen scanning we want to not allow exit until scans are allocated
    // otherwise we will just run the parent close function
    if (
      isPenOrReceivalScanning &&
      isHubConnected &&
      isConnectedToDevice &&
      hasUnassignedScans
    ) {
      openConfirmRescan();
    } else {
      closeSelf();
    }
  };

  const onClear = () => {
    dispatch(deleteAllUnassignedScans());
    closeSelf();
  };

  return (
    <>
      <ScanningScreen
        closeSelf={handleClose}
        consignmentExists={Boolean(consignment)}
        consignmentId={calculatedConsignmentId}
        isLoading={isLoading}
        saleLotExists={Boolean(saleLot)}
        saleLotId={saleLotId}
        scanningMode={mode}
        preferredScanningMode={preferredScanningMode}
        resume={Boolean(resume)}
        updatePreferredScanningMode={updatePreferredScanningMode}
        penArchetypeId={penArchetypeId}
        penId={penId}
        scanLotId={scanLotId}
        penType={penType}
      />
      <Switch>
        <Route path={`${match.path}/animal/:EID`} component={AnimalModal} />
      </Switch>
      <ConfirmDialog
        title="You currently have unsubmitted scans"
        message="Do you want to clear them?"
        buttonMessage="Confirm Clear Scans"
        isOpen={isConfirmRescanOpen}
        onCancel={closeConfirmRescan}
        onDelete={onClear}
      />
    </>
  );
};
ScanningScreenView.propTypes = {
  closeSelf: PropTypes.func.isRequired,
  consignmentId: PropTypes.string,
  mode: PropTypes.number,
  resume: PropTypes.bool,
  saleLotId: PropTypes.string,
};

export default memo(withRouter(ScanningScreenView));
