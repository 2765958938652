import React from "react";

import { useTheme } from "hooks";

export const FrontGateLockedBackGateOpen = ({ color, size }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.black;

  const svgSize = size || theme.fontSizes.gamma;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox="0 0 27500 27500"
    >
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6371.4"
        y1="6308.3"
        x2="6371.4"
        y2="17772.4"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21256.2"
        y1="10029.5"
        x2="21256.2"
        y2="6308.3"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6371.4"
        y1="21193.1"
        x2="6371.4"
        y2="17772.4"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13813.8"
        y1="21193.1"
        x2="21256.2"
        y2="21193.1"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="26959.7"
        y1="17901.2"
        x2="21256.2"
        y2="10029.5"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13813.8"
        y1="6308.3"
        x2="21256.2"
        y2="6308.3"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6371.4"
        y1="21193.1"
        x2="13813.8"
        y2="21193.1"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6371.4"
        y1="6308.3"
        x2="13813.8"
        y2="6308.3"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6371.4"
        cy="17772.4"
        r="334.9"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6371.4"
        cy="6308.3"
        r="334.9"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6371.4"
        cy="21193.1"
        r="334.9"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13813.8"
        cy="21193.1"
        r="334.9"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13813.8"
        cy="6308.3"
        r="334.9"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21256.2"
        cy="10029.5"
        r="334.9"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21256.2"
        cy="21193.1"
        r="334.9"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21256.2"
        cy="6308.3"
        r="334.9"
      />
      <path
        stroke={svgColor}
        fill={svgColor}
        d="M2570.7 13053.1c-13.7-119-1.5-310.2-1.4-437.2 0.1-153.6-6.7-255.6 52.1-386.8 47.3-105.4 113.1-175.6 201.1-237.6 201.1-141.5 461.8-134.5 663.5 2.3 236.8 160.7 249.1 379.3 249 629.6 0 131.5 8.4 302.5-2.4 429.6l-1161.8 0.1zm-644.1-617.2l-1.8 621-203.8-1.5c-165.6-10.7-298.3 116.6-345.1 222.1-58.1 130.7-36.6 371.3-36.8 526.7-0.5 375.9 0.3 751.8-0.1 1127.8-0.5 502.6 391.5 450.3 685 450.4l2255.5-0.2c218.1 0 519.7 45.1 640.8-210.4 40.4-85.3 44.3-137.8 44.2-239.8-0.5-375.9-0.1-751.8 0.1-1127.7 0.1-163 22.8-393.4-40.1-531.3-46.4-101.7-183.5-228.1-343.1-217l-202.2-1.8-0.8-615.2c12.3-224.1-101.6-509.7-228.6-684.6-39-53.7-98.2-126.6-139.2-163.5l-165.9-136.9c-187-130.9-452.8-212.8-692.8-212.5-316 0.4-548 103.8-778.8 277.8l-218.9 235.3c-121.2 149-238.4 467.5-227.4 681.4z"
      />
      <path
        stroke={svgColor}
        fill={svgColor}
        d="M21665.7 17714.3c416.3-56.2 704.2 89.3 1119.3-24.8l-2361.3-2619.5 412.1-394.5 2376.9 2628.1c151.9-419.5 21.4-708.5 146.7-1086.5l471.9-13c84.6 266.9-32.8 1787.7-67.2 2130.8-297.5 32.6-1884.5-26.7-2150.4-151.3l52-469.2z"
      />
    </svg>
  );
};

export default FrontGateLockedBackGateOpen;
