import React from "react";

import Cropper from "react-cropper";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";

import { deleteAttachment, updateAttachment } from "actions";

import { CropControls } from "components/AttachmentsView/Cropper";
import { Column } from "components/Layout";
import { Dialog, DialogTitle } from "components/MaterialDialog";

import { DocumentTypes } from "constants/documentTypes";

const EditImage = styled(Cropper)`
  ${({ theme }) => `
    flex: 1 1 auto;
    height: calc(100vh - 65px - ${theme.space[1] * 14}px - 124px);
    width: 100%;
    border: 1px solid ${theme.colors.controlBorder};
    align-items: center;
    justify-content: center;
    display: flex;
    > * {
      max-height: calc(100vh - 65px - ${theme.space[1] * 14}px - 24px);
    }
`}
`;

export const LightBox = ({
  defaultId,
  onClose,
  images,
  attachmentTypeOptions,
  readOnly,
}) => {
  const [cropper, setCropper] = React.useState();

  const dispatch = useDispatch();
  const activeFile = images.find(i => i.id === defaultId);

  const handleDelete = attachment => {
    dispatch(deleteAttachment(attachment));
    if (images.length === 1) {
      onClose();
    }
  };

  const handleReplace = (attachment, newFile) => {
    dispatch(updateAttachment({ ...attachment, file: newFile }));
    onClose();
  };

  return (
    <Dialog open={!!defaultId} onClose={onClose} fullScreen>
      <DialogTitle onClose={onClose}>
        <CropControls
          cropper={cropper}
          file={activeFile}
          handleDelete={handleDelete}
          handleReplace={handleReplace}
          nvdAttachmentIds={images
            .filter(attachment => attachment.type === DocumentTypes.NVD)
            .map(attachment => attachment.id)}
          attachmentTypeOptions={attachmentTypeOptions}
          disabled={readOnly}
        />
      </DialogTitle>
      <Column full padding={2}>
        <EditImage
          initialAspectRatio={1}
          preview=".img-preview"
          src={activeFile?.image_url}
          viewMode={1}
          modal
          guides
          rotatable
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive
          autoCrop={false}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={instance => {
            setCropper(instance);
          }}
          dragMode="move"
        />
      </Column>
    </Dialog>
  );
};
