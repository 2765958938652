import React from "react";

import { useDispatch } from "react-redux";

import { BusinessAction } from "actions";

import { ConfirmDialog } from "components/ConfirmDialog";

export function ConfirmBrandUpdateDialog(props) {
  const { confirmBrandUpdateDialogProps, setConfirmBrandUpdateDialogProps } =
    props;

  const dispatch = useDispatch();

  function onUpdateBusinessBrands() {
    dispatch(
      BusinessAction.update(
        {
          id: confirmBrandUpdateDialogProps.vendorId,
          brands: confirmBrandUpdateDialogProps.brands,
        },
        {
          changeReason: "Updated brands via NVD Mapping Screen.",
        },
      ),
    );
    setConfirmBrandUpdateDialogProps(null);
  }

  function onCancelUpdateBusinessBrands() {
    setConfirmBrandUpdateDialogProps(null);
  }

  if (confirmBrandUpdateDialogProps) {
    return (
      <ConfirmDialog
        title="Update Vendors Brand"
        isOpen
        messageIcon={null}
        question={confirmBrandUpdateDialogProps.question}
        message={confirmBrandUpdateDialogProps.message}
        onCancel={onCancelUpdateBusinessBrands}
        onDelete={onUpdateBusinessBrands}
        buttonMessage="Update Vendor Brand"
      />
    );
  } else {
    return null;
  }
}
