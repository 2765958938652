import React from "react";

import { useSelector } from "react-redux";

import Badge from "components/Badge";
import { MetadataField, PrimaryHeading } from "components/Card/Elements";
import { Row } from "components/Layout";

import { userTypes } from "constants/users";

import { ForScaleOperator, ForUserType } from "containers/ForUserType";

import { getNestedConsignments } from "selectors";

function ConsignmentDetailHeader(props) {
  const { consignmentId } = props;

  const consignment =
    useSelector(getNestedConsignments).find(c => c.id === consignmentId) || {};

  if (!consignmentId) {
    return null;
  }

  const { vendorName, quantity, quantityUndrafted, scannedCount } = consignment;

  return (
    <Row justifyAround alignCenter>
      <MetadataField title="Vendor" value={vendorName} />
      <MetadataField title="Head Count">
        <PrimaryHeading>
          <ForScaleOperator>{scannedCount}</ForScaleOperator>
          <ForUserType
            userType={[userTypes.LIVESTOCK_AGENT, userTypes.SALEYARD_ADMIN]}
          >
            {quantity}
          </ForUserType>
          {!!quantityUndrafted && (
            <Badge color="warning">
              {quantityUndrafted < 0 && "+"}
              {-quantityUndrafted}
            </Badge>
          )}
        </PrimaryHeading>
      </MetadataField>
    </Row>
  );
}

export default React.memo(ConsignmentDetailHeader);
