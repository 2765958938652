import queryString from "query-string";

import {
  ATTACHMENT,
  UPLOAD_FAILURE,
  UPLOAD_PROGRESS,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
} from "constants/actionTypes";

import { getLivestockSaleId, getSaleyardName } from "lib/navigation";

import { urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({
  changesSince,
  saleyardName,
  livestockSaleId,
  id,
  action,
} = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction(
      `/v2/saleyards/${saleyardName}/sales/${livestockSaleId}/attachments/`,
      id,
      action,
    ),

    query: {
      changesSince,
    },
  });

export const uploadRequest = (file, formData, id = -new Date().getTime()) => {
  return {
    type: UPLOAD_REQUEST,
    id,
    formData,
    meta: { file },
  };
};

export const uploadProgress = (id, progress) => ({
  type: UPLOAD_PROGRESS,
  progress,
  id,
});

export const uploadSuccess = (id, response) => ({
  type: UPLOAD_SUCCESS,
  response,
  id,
});

export const uploadFailure = (id, err) => ({
  type: UPLOAD_FAILURE,
  errorMessage: err,
  error: true,
  id,
});

export const requestAttachments = sale => ({
  type: ATTACHMENT.FETCH_BULK.REQUEST,
  sale,
  meta: {
    livestockSaleId: sale.livestocksale_id,
  },
});

/**
 * 2021-02-22 THIS DOES NOT YET CALL A CHANGES SINCE ENDPOINT
 * N.B. IT WILL ALWAYS REPLACE ALL ATTACHMENTS IN STATE UNTIL THIS IS IMPLEMENTED
 *
 * @param {ApiSale} sale
 * @return {{livestockSaleId: *, type: string}}
 */
export const requestAttachmentsChanges = sale => ({
  type: ATTACHMENT.FETCH_CHANGES.REQUEST,
  sale,
});

export const requestAttachmentsChangesSuccess = attachments => ({
  type: ATTACHMENT.FETCH_CHANGES.SUCCESS,
  attachments,
});

export const requestAttachmentsChangesFailure = message => ({
  type: ATTACHMENT.FETCH_CHANGES.FAILURE,
  message,
});

export const requestAttachmentsSuccess = (livestockSaleId, attachments) => ({
  type: ATTACHMENT.FETCH_BULK.SUCCESS,
  attachments,
  meta: {
    livestockSaleId,
  },
});

export const requestAttachmentsFailure = () => ({
  type: ATTACHMENT.FETCH_BULK.FAILURE,
});

export const updateAttachment = (attachment, vendor) => ({
  type: ATTACHMENT.UPDATE.REQUEST,
  attachment,
  vendor,
});

export const updateAttachmentSuccess = attachment => ({
  type: ATTACHMENT.UPDATE.SUCCESS,
  attachment,
});

export const updateAttachmentFailure = message => ({
  type: ATTACHMENT.UPDATE.FAILURE,
  message,
});

export const deleteAttachment = attachment => ({
  type: ATTACHMENT.DELETE.REQUEST,
  attachment,
});

export const deleteAttachmentSuccess = attachment => ({
  type: ATTACHMENT.DELETE.SUCCESS,
  attachment,
});

export const deleteAttachmentFailure = message => ({
  type: ATTACHMENT.DELETE.FAILURE,
  message,
});

export const deleteAttachmentFromSocket = attachment => ({
  type: ATTACHMENT.DELETE.FROM_SOCKET,
  attachment,
});

export const createAttachmentsFromSocket = message => {
  const attachments = message.object;

  return {
    type: ATTACHMENT.CREATE_BULK.FROM_SOCKET,
    attachments,
  };
};

export const updateAttachmentFromSocket = message => {
  const attachment = message.object;

  return {
    type: ATTACHMENT.UPDATE.FROM_SOCKET,
    attachment,
  };
};

export const updateAttachmentsFromSocket = message => {
  const payload = message.object;

  return {
    type: ATTACHMENT.UPDATE_BULK.FROM_SOCKET,
    payload,
  };
};

export const updateAttachments = attachments => {
  return {
    type: ATTACHMENT.UPDATE_BULK.REQUEST,
    meta: {
      offline: {
        effect: {
          url: `/v2/attachments/`,
          method: "PATCH",
          body: JSON.stringify(attachments),
        },
        commit: {
          type: ATTACHMENT.UPDATE_BULK.SUCCESS,
          attachments,
        },
        rollback: {
          type: ATTACHMENT.UPDATE_BULK.FAILURE,
          attachments,
        },
      },
    },
  };
};

export const createPlaceholderAttachment = formData => {
  const options = {
    saleyardName: getSaleyardName(),
    livestockSaleId: getLivestockSaleId(),
    action: "create-with-placeholder",
  };
  return {
    type: ATTACHMENT.CREATE_WITH_PLACEHOLDER.REQUEST,
    meta: {
      formData,
      offline: {
        effect: {
          url: urlCreator(options),
          method: "POST",
          body: JSON.stringify(formData),
        },
        commit: {
          type: ATTACHMENT.CREATE_WITH_PLACEHOLDER.SUCCESS,
        },
        rollback: {
          type: ATTACHMENT.CREATE_WITH_PLACEHOLDER.FAILURE,
        },
      },
    },
  };
};
