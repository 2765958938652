// Overwrite the default schedule from redux offline with values a bit more in line with our use cases
// const defaultDecaySchedule = [
//   1000, // After 1 seconds
//   1000 * 5, // After 5 seconds
//   1000 * 15, // After 15 seconds
//   1000 * 30, // After 30 seconds
//   1000 * 60, // After 1 minute
//   1000 * 60 * 3, // After 3 minutes
//   1000 * 60 * 5, // After 5 minutes
//   1000 * 60 * 10, // After 10 minutes
//   1000 * 60 * 30, // After 30 minutes
//   1000 * 60 * 60, // After 1 hour
// ];

import { isActionMutative } from "constants/actionTypes";

// Try a couple of times, then stop off as we are probably offline.
// If we're not getting this data we aren't necessarily dead - if it's core data (fetch bulk) we will be bailing
// on the sale, but if it's maintenance (changes since) a failure sucks but isn't the end of the world.
export const fetchDecaySchedule = [
  // A network hiccup or bad reception...
  1000, // After 1 seconds
  1000 * 2, // After 2 seconds
  1000 * 5, // After another 5 seconds
];

// These are mostly mutative - somewhat more important - try
// a bit harder here.
const defaultDecaySchedule = [
  1000, // After 1 seconds
  1000 * 5, // After 5 seconds
  1000 * 15, // After 15 seconds
  1000 * 15, // After 15 seconds
  1000 * 30, // After 30 seconds
  1000 * 30, // After 30 seconds
  1000 * 30, // After 30 seconds
  1000 * 30, // After 30 seconds
  1000 * 30, // After 30 seconds
  1000 * 30, // After 30 seconds
  1000 * 30, // After 30 seconds
  1000 * 60, // After 1 minute
  1000 * 60 * 3, // After 3 minutes
  1000 * 60 * 5, // After 5 minutes
  1000 * 60 * 10, // After 10 minutes
  1000 * 60 * 30, // After 30 minutes
  // Probably out in whoop whoop.  Try for a long while.
  1000 * 60 * 60, // After 1 hour
  1000 * 60 * 60, // After 1 hour
  1000 * 60 * 60, // After 1 hour
  1000 * 60 * 60, // After 1 hour
  1000 * 60 * 60, // After 1 hour
  1000 * 60 * 60, // After 1 hour
  1000 * 60 * 60, // After 2 hours
  1000 * 60 * 60, // After 2 hours
  1000 * 60 * 60, // After 2 hours
  1000 * 60 * 60, // After 4 hours
];

export default (action, retries) => {
  if (isActionMutative(action)) {
    return defaultDecaySchedule[retries] || null;
  }
  return fetchDecaySchedule[retries] || null;
};
