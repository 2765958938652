import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { closeConfirmModal, openConfirmModal } from "actions";

import {
  addBidderRegistration,
  deleteBidderRegistration,
  updateBidderRegistration,
} from "actions/offline";

import BidderRegistrationForm from "components/BidderRegistrationForm";

import { getBidderById, selectBiddersList } from "selectors";

import { bidderFormValidationSchema } from "./validation";

function mapStateToProps(state, props) {
  const isLoading = state.bidderRegistrations.isFetching;
  const bidder = getBidderById(props.bidderId)(state) || {};

  const biddersList = selectBiddersList(state);
  const validationSchema = bidderFormValidationSchema(biddersList);

  const lastBidderRegistration = biddersList[0];

  const nextBidderNumber = lastBidderRegistration
    ? lastBidderRegistration.registrationNumber + 1
    : 1;

  // when the bidder already has a registration number, use that
  const registrationNumber = bidder.registrationNumber
    ? bidder.registrationNumber
    : // otherwise prepopulate with the next available
      nextBidderNumber;

  const initialValues = {
    ...bidder,
    registrationNumber,
  };
  const provisionalData = bidder?.provisionalData || {};

  return {
    initialValues,
    isLoading,
    provisionalData,
    validationSchema,
    biddersList,
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    initialValues,
    isLoading,
    provisionalData,
    validationSchema,
    biddersList,
  } = stateProps;
  const {
    addBidderRegistration,
    updateBidderRegistration,
    openConfirmModal,
    closeConfirmModal,
    deleteBidderRegistration,
  } = dispatchProps;
  const { bidderId, onCancel, onClose } = ownProps;
  const isEdit = Boolean(bidderId);
  const showCreateAnother = !isEdit;

  function handleSubmit(values) {
    const { createAnother } = values;
    if (isEdit) {
      updateBidderRegistration(bidderId, values);
    } else {
      addBidderRegistration(uuidv4(), values);
    }

    !createAnother && onClose && onClose();
  }

  const handleDelete = isEdit
    ? () => {
        openConfirmModal({
          title: "Are you sure?",
          message: "Are you sure you want to delete this bidder registration?",
          actions: [
            {
              label: "No",
              secondary: true,
              onClick: () => closeConfirmModal,
            },
            {
              label: "Yes",
              onClick: () => {
                closeConfirmModal();
                deleteBidderRegistration(bidderId);
                onCancel();
              },
            },
          ],
        });
      }
    : null;

  return {
    handleDelete,
    handleSubmit,
    initialValues,
    isLoading,
    onCancel,
    provisionalData,
    showCreateAnother,
    validationSchema,
    biddersList,
  };
}

export default connect(
  mapStateToProps,
  {
    addBidderRegistration,
    updateBidderRegistration,
    openConfirmModal,
    closeConfirmModal,
    deleteBidderRegistration,
  },
  mergeProps,
)(BidderRegistrationForm);
