import React from "react";

import { useSelector } from "react-redux";

import { CellButton } from "components/DeploymentSaleSummaries/CellButton";

import { SaleLotModalSection } from "constants/navigation";

import { openEditSaleLotModal } from "lib/navigation";

import { getIsEditableBySaleLotId } from "selectors";

function OpenModalCellCreator(saleLotModalSection) {
  return function OpenSaleLotModalCell(props) {
    const { original, value } = props;
    const { saleLotId } = original;

    const isEditable = useSelector(getIsEditableBySaleLotId(saleLotId));

    return (
      <CellButton
        disabled={!isEditable}
        onClick={() => openEditSaleLotModal(saleLotId, saleLotModalSection)}
      >
        {value}
      </CellButton>
    );
  };
}

export const OpenGeneralCell = OpenModalCellCreator(
  SaleLotModalSection.GENERAL,
);
export const OpenScanningCell = OpenModalCellCreator(
  SaleLotModalSection.SCANNING,
);
export const OpenSaleCell = OpenModalCellCreator(SaleLotModalSection.SELLING);
export const OpenPenningCell = OpenModalCellCreator(
  SaleLotModalSection.AUCTION_PEN,
);
