import React from "react";

import { useSelector } from "react-redux";

import AgGridTable from "components/AgGrid/AgGridContainer";
import TableWrapper from "components/AgGrid/TableWrapper";
import { SecondaryMultiButton } from "components/Button/MultiButton";
import { Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { AgGridTables } from "constants/aggrid";
import {
  BillingDocumentColId,
  BillingDocumentColumnDef,
  BusinessColId,
  BusinessColumnDef,
  CommissionColumnDef,
  LedgerEntryColumnDef,
} from "constants/columnDefinitions";
import { ApiModel } from "constants/loading";

import { avgAggFunction } from "lib/agGrid/aggregators";

import { getActiveRole, getBillingTags, getProperties } from "selectors";

import { selectCommittedLedgerEntryData } from "selectors/aggrid/ledgerEntries";

const getRowId = params => params.data.ledgerEntry.id;
const aggFuncs = { anAggFunc: avgAggFunction };
const columnDefs = [
  {
    headerName: "Document",
    children: Object.values(BillingDocumentColumnDef).filter(
      ({ colId }) => colId !== BillingDocumentColId.ACTIONS,
    ),
  },
  {
    headerName: "Business",
    children: Object.values(BusinessColumnDef).filter(
      ({ colId }) => colId !== BusinessColId.ACTIONS,
    ),
  },
  {
    headerName: "Ledger Entry",
    children: Object.values(LedgerEntryColumnDef),
  },
  {
    headerName: "Commission",
    children: Object.values(CommissionColumnDef),
  },
];

export const LedgerEntryDetail = () => {
  const rowData = useSelector(selectCommittedLedgerEntryData);

  const billingTags = useSelector(getBillingTags);
  const userRoleSlug = useSelector(state => getActiveRole(state).slug);
  const propertyByIdLookup = useSelector(getProperties);
  const context = { billingTags, propertyByIdLookup, userRoleSlug };

  return (
    <WaitForSync
      requiredData={[
        ApiModel.BILLING_DATA,
        ApiModel.BILLING_DOCUMENTS,
        ApiModel.BILLING_RUNS,
        ApiModel.BUSINESSES,
        ApiModel.EMAIL,
        ApiModel.LEDGER_ENTRIES,
        ApiModel.MANUAL_ADJUSTMENTS,
      ]}
    >
      <Column fullHeight>
        <AgGridTable
          aggFuncs={aggFuncs}
          columnDefs={columnDefs}
          getRowId={getRowId}
          context={context}
          rowData={rowData}
          showGlobalSearchFilter={false}
          WrapperComponent={TableWrapper}
          tableName={AgGridTables.BILLING_LEDGER_ENTRIES_DETAIL}
          headerJustifyContent="space-between"
          multiButtonComponent={SecondaryMultiButton}
        />
      </Column>
    </WaitForSync>
  );
};
