import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import { getIn, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { SubtleBadge } from "components/Badge";
import { CollapseLabel, FormCollapse } from "components/Form";
import { AddressForm } from "components/Form/Fields";
import {
  DateInput,
  Switch,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";
import { Row } from "components/Layout";

import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { formatAddress } from "lib/address";

import {
  getBusinessById,
  getConsignmentById,
  getVendorIdByConsignmentId,
} from "selectors";

function ClearingSalePickupDetailsHeaderComponent(props) {
  const { namespace: ns, consignmentId } = props;
  const { values } = useFormikContext();

  const useConsignmentPickupDetails = getIn(
    values,
    withNamespace(ns, "useConsignmentPickupDetails"),
  );

  const lotPickupAddress = getIn(values, withNamespace(ns, "pickupAddress"));
  const consignmentAddress = useSelector(
    state => getConsignmentById(consignmentId)(state)?.pickupAddress,
  );
  const vendorAddress = useSelector(
    state =>
      getBusinessById(getVendorIdByConsignmentId(consignmentId)(state))(state)
        ?.address,
  );

  const addressDetails = {
    formattedAddress: "No Address",
    addressFrom: "",
  };
  if (useConsignmentPickupDetails && consignmentAddress) {
    addressDetails.formattedAddress = formatAddress(consignmentAddress);
    addressDetails.addressFrom = "(Consignment)";
  } else if (useConsignmentPickupDetails && vendorAddress) {
    addressDetails.formattedAddress = formatAddress(vendorAddress);
    addressDetails.addressFrom = "(Vendor)";
  } else if (!useConsignmentPickupDetails && lotPickupAddress) {
    addressDetails.formattedAddress = formatAddress(lotPickupAddress);
    addressDetails.addressFrom = "(Lot)";
  }

  const latestPickupDate = useSelector(state =>
    useConsignmentPickupDetails
      ? getConsignmentById(consignmentId)(state)?.latestPickupDate
      : getIn(values, withNamespace(ns, "latestPickupDate")),
  );

  return (
    <Row justifyAround data-tour="pickupDetails">
      <div>
        <SubtleBadge>Pickup Details</SubtleBadge>
      </div>
      <CollapseLabel>
        {addressDetails.formattedAddress} {addressDetails.addressFrom}
      </CollapseLabel>
      {latestPickupDate && (
        <CollapseLabel>Before {latestPickupDate}</CollapseLabel>
      )}
    </Row>
  );
}

const ClearingSalePickupDetailsHeader = memo(
  ClearingSalePickupDetailsHeaderComponent,
);

function ClearingSaleSaleLotPickupDetailsForm(props) {
  const { namespace: ns } = props;

  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const useConsignmentPickupDetails = getIn(
    values,
    withNamespace(ns, "useConsignmentPickupDetails"),
  );

  function onUseConsignmentDetailsChanged() {
    setFieldValue(withNamespace(ns, "earliestPickupDate"), null, false);
    setFieldValue(withNamespace(ns, "latestPickupDate"), null, false);
    setFieldValue(withNamespace(ns, "pickupNotes"), "", false);
    setFieldValue(withNamespace(ns, "pickupAddress"), null, true);
  }

  function onPickupDetailsFieldChanged() {
    setFieldTouched(withNamespace(ns, "pickupAddress"), true, true);
  }

  const useConsignmentPickupDetailsLabel = (
    <>
      Use <TranslatedSaleTypeLabel label="Consignment" />
      &apos;s Pickup Details.
    </>
  );

  return (
    <>
      <Grid item xs={12}>
        <Switch
          label={useConsignmentPickupDetailsLabel}
          name={withNamespace(ns, "useConsignmentPickupDetails")}
          onChangeExtra={onUseConsignmentDetailsChanged}
        />
      </Grid>

      {!useConsignmentPickupDetails && (
        <>
          <Grid item xs={6}>
            <DateInput
              label="Earliest Pickup Date"
              name={withNamespace(ns, "earliestPickupDate")}
              onChangeExtra={onPickupDetailsFieldChanged}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label="Latest Pickup Date"
              name={withNamespace(ns, "latestPickupDate")}
              onChangeExtra={onPickupDetailsFieldChanged}
            />
          </Grid>

          <Grid item xs={12}>
            <TextArea
              label="Pickup Notes"
              name={withNamespace(ns, "pickupNotes")}
              rows={3}
              maxLength={200}
              onChangeExtra={onPickupDetailsFieldChanged}
            />
          </Grid>
          <AddressForm
            label="Pickup Address"
            namespace={withNamespace(ns, "pickupAddress")}
          />
        </>
      )}
    </>
  );
}

ClearingSaleSaleLotPickupDetailsForm.propTypes = {
  namespace: PropTypes.string,
};

function ClearingSalePickupDetailsComponent(props) {
  const { consignmentId, isOpen, namespace: ns = "", onToggle } = props;

  const header = (
    <ClearingSalePickupDetailsHeader
      namespace={ns}
      consignmentId={consignmentId}
    />
  );

  return (
    <FormCollapse isOpen={isOpen} onToggle={onToggle} header={header}>
      <ClearingSaleSaleLotPickupDetailsForm namespace={ns} />
    </FormCollapse>
  );
}

export default memo(ClearingSalePickupDetailsComponent);
