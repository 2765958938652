import React from "react";

import { faSlash } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";

import { StackedIcon, Container } from "./Tools";

const StockliveLogoSmall = ({ color, theme, disabled }) => {
  const fill = !disabled ? theme.colors[color] || color : theme.colors.gray78;
  return (
    <Container>
      {disabled && <StackedIcon icon={faSlash} />}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        viewBox="0 0 4000 4000"
      >
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />

          <path
            fill={fill}
            d="M547.26 2882.97c31.28,-2.6 132.61,-66.49 164.02,-84.63l163.47 -91.24 0 -1382.71c31.66,-16.76 59.05,-35.32 93,-52.54l189.99 -107.18c76.96,-39.03 440.1,-255.24 481.14,-264.8 4.23,18.16 27.09,54.49 37.49,71.67 194.66,321.53 397.21,681.01 590.66,1010.37 11.1,18.91 28.62,64.15 51.08,64.15l345.67 0c30.36,0 13.92,-13.26 -30.63,-90.65 -128.9,-223.88 -287.78,-487.54 -408.09,-707.78l-173.49 -299.55c-20.42,-35.69 -38.32,-67.53 -59.08,-104.66l-117.4 -204.02c-16.7,-29.2 -107.97,-176.68 -114.92,-206.5 -19.57,4.56 -120.63,65.72 -144.38,80.01l-437.82 253.54c-47.85,25.88 -92.28,55.98 -141.56,82.82 -51.51,28.07 -95.54,54.53 -146.05,84.41 -99.36,58.77 -192.43,110.48 -292.01,168.9 -35.83,21.01 -47.67,22.38 -51.09,46.52l0 126.77 0 1607.1z"
          />
          <path
            fill={fill}
            d="M826.24 3040.65l377.17 211.08c26.17,15.83 51.25,29.94 77.7,43.6l758.03 424.55c14.58,1.65 816.56,-487.53 942.08,-555.86l381.19 -225.26c28.82,-17.28 175.2,-100.61 186.8,-116.44 -16.89,-11.3 -303.39,-181.93 -321.42,-181.93l-146.7 83.76c-50.76,30.65 -96.99,57.06 -148.55,87.96 -63.83,38.24 -876.79,525.7 -893.4,525.7l-463.47 -264.28c-33.25,-19.55 -59.88,-33.36 -92.56,-52.99l-189.91 -107.24c2.37,-28.47 58.23,-118.56 74.79,-149.61 26.61,-49.88 54.27,-93.63 80.87,-143.51l240.47 -432.7c14.27,-25.7 25.41,-44.91 38.48,-70.68l202.17 -361.83 -188 -327.48c-26.92,18.01 -100.06,165.25 -118.74,196.62l-84.87 151.63c-10.95,18.45 -18.72,31.95 -28.78,50.07 -101,181.99 -232.93,424.84 -340.26,605.8 -40.51,68.31 -73.26,134.48 -113.82,201.54 -20.52,33.92 -36.18,65.77 -56.89,100.79l-172.38 306.71z"
          />
          <path
            fill={fill}
            d="M1972.43 651.22l598.3 347.77c184.44,100.68 426.78,256.69 602.48,349.66 0,78.91 -6.11,137.42 -6.07,212.25l-6.06 654.97 -1273.55 0c-21.36,0 -42.58,56.12 -53.41,73.95l-47.65 85.77c-17.83,35.15 -86.73,146.9 -93.01,173.83 609.13,0 1201.64,6.06 1807.23,6.06l0 -1382.71c0,-21.62 -78.38,-59.4 -99.11,-70.69 -39.98,-21.77 -71.62,-42.44 -111.28,-64.6l-1105.61 -640.97c-36.3,-19.6 -69.48,-41.38 -107.29,-62.51 -76.19,-42.57 -94.96,-70.81 -99.2,-29.41 -11.48,112.1 17.91,244.99 -5.77,346.63z"
          />
        </g>
      </svg>
    </Container>
  );
};

StockliveLogoSmall.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default withTheme(StockliveLogoSmall);
