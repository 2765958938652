import { createSelector } from "reselect";

import {
  getEnvdDocuments,
  getSpecies,
  selectDeploymentSaleOptionsByLivestockSaleId,
  selectSaleOptionsV2,
} from "selectors";

const EnvdMappingStatus = {
  TODO: "To Do",
  VENDOR_INCOMPLETE: "Vendor Incomplete",
  DONE: "Done",
};

const getMappingStatus = envdDocument => {
  if (envdDocument.livestockSaleId || envdDocument.deploymentSaleId) {
    return EnvdMappingStatus.DONE;
  } else if (!envdDocument.isComplete) {
    return EnvdMappingStatus.VENDOR_INCOMPLETE;
  } else {
    return EnvdMappingStatus.TODO;
  }
};

export const selectEnvdDocumentsAggridData = createSelector(
  [
    getEnvdDocuments,
    getSpecies,
    selectSaleOptionsV2,
    selectDeploymentSaleOptionsByLivestockSaleId,
  ],
  (envdDocuments, species, saleOptions, deploymentSaleOptions) => {
    return Object.values(envdDocuments).map(envdDocument => ({
      envdDocument,
      livestockSaleOption: saleOptions.find(
        so => so.value === envdDocument.livestockSaleId,
      ),
      deploymentSaleOption: deploymentSaleOptions[
        `${envdDocument.livestockSaleId}`
      ]?.find(dso => dso.value === envdDocument.deploymentSaleId),
      species: species[envdDocument.speciesId],
      mappingStatus: getMappingStatus(envdDocument),
    }));
  },
);
