import React from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "formik";
import styled from "styled-components/macro";

import { ClickableLink as Link } from "components/Link";

export const StyledForm = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    margin: ${({ theme }) => theme.space[3]}px;
  }
`;

export const ButtonLink = styled(Link)`
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.radii[1]}px;
  height: ${({ theme }) => theme.space[4]}px;
  margin-left: ${({ theme }) => theme.space[1]}px;
  align-self: flex-end;
`;

export const EditButton = ({ to }) => (
  <ButtonLink to={to}>
    <Pencil icon={faPencilAlt} />
  </ButtonLink>
);

export const AddButton = ({ to }) => (
  <ButtonLink to={to}>
    <Pencil icon={faPlus} />
  </ButtonLink>
);

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
`;

export const FullWidthGridRow = styled(GridRow)`
  grid-template-columns: 1fr;
`;

export const Pencil = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: white;
`;

// Relies on the parent component being position relative.
export const Corner = styled.div`
  position: absolute;
  right: -0;
  top: -${({ theme }) => theme.space[1]}px;
  padding: ${({ theme }) => theme.space[2]}px;
  cursor: pointer;
`;

export const CornerCloseButton = ({ handleClose }) => (
  <Corner onClick={handleClose}>
    <FontAwesomeIcon icon={faTimes} />
  </Corner>
);

export const QuestionnaireRow = styled.div`
  ${({ theme: { space } }) => `padding: ${space[1]}px ${space[1]}px;`}
  align-items: baseline;
`;
