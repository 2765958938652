import React from "react";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components/macro";

import Button from "components/Button";

import { useTheme } from "hooks";

import SaleLotCard from "./SaleLotCard";

import "./transitions.css";

const LotContainer = styled(Container)`
  padding: ${({ theme }) => theme.space[2]}px;
`;

const LotListContainer = styled(TransitionGroup)`
  background-color: ${({ theme }) => theme.colors.gray8C};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  color: white;
  ${({ theme }) => `
  padding: ${theme.space[2]}px;
    font-size: ${theme.fontSizes.delta}px;
    `}
`;

const SHOW_MORE_INCREMENT = 5;

const LotList = ({ selector, transitionName, showImages = true }) => {
  const lots = useSelector(state => {
    return selector(state);
  });
  const theme = useTheme();
  const limitView = useMediaQuery(`(max-width: ${theme.breakpoints[2]}px)`);

  const [showingExtra, setShowingExtra] = React.useState(SHOW_MORE_INCREMENT);

  const showMore = () => {
    setShowingExtra(showingExtra + SHOW_MORE_INCREMENT);
  };
  const showLess = () => {
    setShowingExtra(SHOW_MORE_INCREMENT);
  };

  const showing = limitView ? lots.slice(0, showingExtra) : lots;

  return (
    <div style={{ overflow: "hidden" }}>
      <LotListContainer>
        {showing
          .filter(sl => !sl.beforeSellingNow)
          .map(saleLot => {
            const { id } = saleLot;
            return (
              <CSSTransition
                key={id}
                timeout={3500}
                classNames={transitionName}
              >
                <LotContainer key={saleLot.id}>
                  <SaleLotCard
                    saleLot={saleLot}
                    key={saleLot.id}
                    showImages={showImages}
                  />
                </LotContainer>
              </CSSTransition>
            );
          })}
        {limitView && (
          <CSSTransition
            key="show-buttons"
            timeout={3500}
            classNames="show-buttons"
          >
            <ButtonWrapper>
              {showingExtra > SHOW_MORE_INCREMENT && (
                <Button onClick={showLess}>
                  <FontAwesomeIcon icon={faChevronUp} />
                  &nbsp; Show Less
                </Button>
              )}
              {showingExtra < lots.length && (
                <Button onClick={showMore}>
                  <FontAwesomeIcon icon={faChevronDown} />
                  &nbsp; Show More
                </Button>
              )}
            </ButtonWrapper>
          </CSSTransition>
        )}
      </LotListContainer>
    </div>
  );
};

export default LotList;
