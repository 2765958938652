import { offlineActionCreator, urlCheckAndAddIdAndAction } from "actions/lib";

import { DEPLOYMENT_SALE } from "constants/actionTypes";

import { serializeComment } from "lib/serializers/comments";

export const patchDeploymentSale = (
  deploymentSale,
  deploymentSaleId,
  livestockSaleId,
  options,
) => {
  const payload = deploymentSale;
  const meta = { deploymentSaleId, deploymentSale, livestockSaleId };
  const url = `/v2/sales/${livestockSaleId}/update_deployment_sale/?deployment_sale_id=${deploymentSaleId}`;

  return {
    type: DEPLOYMENT_SALE.UPDATE.REQUEST,
    payload,
    options,
    meta: {
      deploymentSaleId,
      deploymentSale,
      livestockSaleId,
      offline: {
        effect: {
          url,
          method: "PATCH",
          body: JSON.stringify(payload),
        },
        commit: {
          type: DEPLOYMENT_SALE.UPDATE.SUCCESS,
          meta,
        },
        rollback: {
          type: DEPLOYMENT_SALE.UPDATE.FAILURE,
          meta,
        },
      },
    },
  };
};

export const deleteDeploymentSale = (deploymentSaleId, livestockSaleId) => {
  const payload = {
    deployment_sale_id: deploymentSaleId,
  };
  const meta = { deploymentSaleId, livestockSaleId };
  const url = `/v2/sales/${livestockSaleId}/deployment_sale/?deployment_sale_id=${deploymentSaleId}`;

  return {
    type: DEPLOYMENT_SALE.DELETE.REQUEST,
    payload,
    meta: {
      deploymentSaleId,
      livestockSaleId,
      offline: {
        effect: {
          url,
          method: "DELETE",
          body: JSON.stringify(payload),
        },
        commit: {
          type: DEPLOYMENT_SALE.DELETE.SUCCESS,
          meta,
        },
        rollback: {
          type: DEPLOYMENT_SALE.DELETE.FAILURE,
          meta,
        },
      },
    },
  };
};

const urlCreator = ({ id, action } = {}) =>
  urlCheckAndAddIdAndAction("/v2/deployment-sale/", id, action);

export const AddDeploymentSaleCommentAction = offlineActionCreator(
  DEPLOYMENT_SALE,
  (deserializedPayload, { id }) =>
    serializeComment(deserializedPayload, { id }),
  urlCreator,
  false,
);
