import React from "react";

import { faEye, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { BillingDocumentAction } from "actions";

import { BillingDocumentReportJobStatus } from "constants/billingDocuments";
import { AgGridActionButtons } from "constants/columnDefinitions/actions";
import { ModalTypes } from "constants/navigation";

import { getReportJobUrl, openModalLink } from "lib/navigation";

import { selectCanSendToXeroByBillingDocumentIdLookup } from "selectors";

export const BillingDocumentActionRenderer = ({ data, context }) => {
  const { billingDocument = {}, livestockSales } = data || {};

  const reportJobId = billingDocument.reportJobs?.find(
    rj => rj.documentStatus === BillingDocumentReportJobStatus.COMMITTED,
  )?.id;

  const documentPreviewUrl = getReportJobUrl(reportJobId, context.userRoleSlug);

  const openPreview = () => window.open(documentPreviewUrl);

  const saleId = livestockSales?.[0]?.livestocksale_id;

  const openEditModal = () =>
    openModalLink(ModalTypes.EditBillingDocument, {
      id: billingDocument.id,
      saleId,
    });

  const dispatch = useDispatch();

  const sendToXero = () =>
    dispatch(BillingDocumentAction.exportXeroInvoiceApi([billingDocument.id]));

  const canSendToXero = useSelector(
    selectCanSendToXeroByBillingDocumentIdLookup,
  )[billingDocument.id];

  const actions = [
    {
      onClick: openPreview,
      dataTour: "open-preview",
      icon: faEye,
      title: " View",
      hide: !reportJobId,
    },
    {
      onClick: openEditModal,
      dataTour: "open-edit-modal",
      icon: faPencil,
      title: " Edit",
      hide: !saleId,
    },
    {
      onClick: sendToXero,
      dataTour: "send-to-xero",
      icon: faPaperPlane,
      title: " Send To Xero",
      hide: !canSendToXero,
    },
  ];

  return <AgGridActionButtons actions={actions} />;
};
