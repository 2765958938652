import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

import { hasPermission, someHasPermission } from "lib/permissions";

import { getIsRolePermissionsEnabled } from "selectors";

function SecurityTrimmedRoute(props) {
  const { children, objectSelector, objectArraySelector, permissionRequired } =
    props;

  const roleHasPermission = useSelector(state => {
    if (getIsRolePermissionsEnabled(state)) {
      // Only perform the permissions test when the Active Role uses permissions
      return objectArraySelector
        ? someHasPermission(objectArraySelector(state), permissionRequired)
        : hasPermission(objectSelector(state), permissionRequired);
    }
    // Assume they have permission when the Active Role doesn't use the permissions machinery
    return true;
  });

  if (!roleHasPermission) {
    return null;
  }

  return <Route {...props}>{children}</Route>;
}

SecurityTrimmedRoute.propTypes = {
  children: PropTypes.node,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  sensitive: PropTypes.bool,
  strict: PropTypes.bool,
  permissionRequired: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  objectSelector: PropTypes.func,
  objectArraySelector: PropTypes.func,
};

export default SecurityTrimmedRoute;
