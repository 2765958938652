import reduceReducers from "reduce-reducers";

import { PAYMENT } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
} from "lib/reducers";
import { deserializePayment } from "lib/serializers/payments";

const create = apiModelOfflineCreateReducer(PAYMENT, {
  deserializer: deserializePayment,
});

const fetch = apiModelOfflineFetchReducer(PAYMENT, {
  deserializer: deserializePayment,
});

const update = apiModelOfflineUpdateReducer(PAYMENT, {
  deserializer: deserializePayment,
});

const deleteReducer = apiModelOfflineDeleteReducer(PAYMENT);

const paymentReducer = reduceReducers(create, fetch, update, deleteReducer);

export default paymentReducer;
