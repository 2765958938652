import React, { memo } from "react";

import { BulkCreateSaleLotsModal } from "components/BulkCreateSaleLotsModal";

export function ScanningBulkCreateSaleLotsModalComponent(props) {
  const { closeSelf, modalProps } = props;
  const { eids, onAfterSave } = modalProps;

  return (
    <BulkCreateSaleLotsModal
      eids={eids}
      closeSelf={closeSelf}
      isOpen
      onAfterSave={onAfterSave}
    />
  );
}

export default memo(ScanningBulkCreateSaleLotsModalComponent);
