import { useMemo } from "react";

import { sortBy } from "lodash";
import { useSelector } from "react-redux";

import { EMPTY_ARRAY } from "lib";

import {
  getAges,
  getBreeds,
  getDeploymentAttributes,
  getSexes,
} from "selectors";

export const SpeciesAttribute = {
  AGE: "AGE",
  SEX: "SEX",
  BREED: "BREED",
};

const AttributeMap = {
  [SpeciesAttribute.AGE]: {
    globalSelector: getAges,
    deploymentAttributeProperty: "ages",
    globalIdField: "age_id",
  },
  [SpeciesAttribute.SEX]: {
    globalSelector: getSexes,
    deploymentAttributeProperty: "sexes",
    globalIdField: "sex_id",
  },
  [SpeciesAttribute.BREED]: {
    globalSelector: getBreeds,
    deploymentAttributeProperty: "breeds",
    globalIdField: "breed_id",
  },
};

export const useGetAttributeOptions = (
  attribute,
  speciesId,
  deploymentId = null,
  showQuickCode,
) => {
  const { globalSelector, deploymentAttributeProperty, globalIdField } =
    AttributeMap[attribute];

  const globalAttributes = useSelector(globalSelector);
  const deploymentAttributes = useSelector(getDeploymentAttributes)[
    deploymentAttributeProperty
  ];

  const deploymentOptions = useMemo(() => {
    if (deploymentId) {
      const options = Object.values(deploymentAttributes)
        .filter(
          deploymentAttribute =>
            deploymentAttribute.deployment_id === deploymentId &&
            globalAttributes[deploymentAttribute[globalIdField]].species_id ===
              speciesId &&
            deploymentAttribute.is_used,
        )
        .map(deploymentAttribute => {
          const field = globalAttributes[deploymentAttribute[globalIdField]];

          const quickCode = showQuickCode
            ? `(${deploymentAttribute.quick_code})`
            : null;

          const label = [field.name, quickCode].filter(Boolean).join(" ");

          return {
            value: deploymentAttribute[globalIdField],
            label,
            order: deploymentAttribute.order,
          };
        });
      return sortBy(options, "order");
    }
  }, [
    deploymentAttributes,
    deploymentId,
    globalAttributes,
    globalIdField,
    speciesId,
    showQuickCode,
  ]);

  const globalOptions = useMemo(() => {
    const options = Object.values(globalAttributes)
      .filter(globalAttribute => globalAttribute.species_id === speciesId)
      .map(globalAttribute => ({
        value: globalAttribute.id,
        label: globalAttribute.name,
        order: globalAttribute.order,
      }));
    return sortBy(options, "order");
  }, [globalAttributes, speciesId]);

  return deploymentOptions || globalOptions || EMPTY_ARRAY;
};
