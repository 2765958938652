import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import AuctionPenSaleList from "components/AuctionPenSaleList";

import { Settings } from "constants/settings";

import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
} from "lib/navigation";

import { getSetting } from "selectors";

function AuctionPenList() {
  const history = useHistory();
  const { roundId: urlRoundId } = useParams();
  const selectedRoundId = useSelector(getSetting(Settings.round));

  const selectPen = (selectedRoundId, selectedActionPenId) => {
    history.push(
      getSaleyardAuctionRoute(
        getSaleyardName(),
        getLivestockSaleId(),
        selectedRoundId,
        selectedActionPenId,
      ),
    );
  };

  // If the user selects a round in the round selector, that gets pushed into settings - we also
  // want it in our URL on this screen, so, push them there.
  useEffect(() => {
    if (selectedRoundId && `${urlRoundId}` !== `${selectedRoundId}`) {
      history.push(
        getSaleyardAuctionRoute(
          getSaleyardName(),
          getLivestockSaleId(),
          selectedRoundId,
        ),
      );
    }
  }, [urlRoundId, selectedRoundId, history]);

  return <AuctionPenSaleList selectPen={selectPen} />;
}

export default AuctionPenList;
