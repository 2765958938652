import { TextareaAutosize } from "@material-ui/core";
import { Form } from "formik";
import styled from "styled-components/macro";

export const LabelText = styled.label(
  ({ error, required, theme, textAlign = "inherit" }) => `
  font-size: 0.9rem;
  font-weight: 500;
  color: ${error ? theme.colors.inputError : theme.colors.titleDark};
  ${
    required
      ? `
  &::after {
    content: "*";
    color: ${theme.colors.inputError};
  }`
      : ""
  }
  
  text-align: ${textAlign};
  `,
);

export const Required = styled.label`
  ${({ theme }) => `
    &::after {
        content: "*";
        color: ${theme.colors.inputError};
    }
  `}
`;

export const StyledInput = styled.input(
  ({
    theme,
    type,
    align,
    bold,
    height,
    beforeSymbol,
    afterSymbol,
    hideSpinner,
  }) => `
  color: ${theme.colors.titleDark};
  min-width: 0;
  width: 100%;
  padding: ${theme.space[1]}px ${theme.space[2]}px;
  border: 1px solid #c8c8c8;
  text-align: ${align || type === "number" ? "right" : "inherit"};
  border-radius: ${theme.radii[1]}px;
  font-weight: ${bold ? theme.fontWeights.bold : "inherit"};
  height: ${height || 36}px;
  ${
    beforeSymbol
      ? `padding-left: ${
          beforeSymbol.length * theme.space[2] + theme.space[2]
        }px;`
      : ""
  }
  ${
    afterSymbol
      ? `padding-right: ${
          afterSymbol.length * theme.space[2] + theme.space[2]
        }px;`
      : ""
  }
  ${
    hideSpinner
      ? `
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
   -webkit-appearance: none !important;
   margin: 0;
  }
  :disabled {
    background-color: ${theme.colors.grayF7};
  }
 `
      : ""
  }
`,
);

export const StyledTextArea = styled(StyledInput).attrs({ as: "textarea" })`
  height: 100%;
  resize: none;
  :disabled {
    background-color: ${({ theme }) => theme.colors.grayF7};
  }
`;

export const FullPageForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledTextAreaAutoSize = styled(TextareaAutosize)(
  ({ theme }) => `
  :disabled {
    background-color: ${theme.colors.grayF7};
  }
  border-radius: ${theme.radii[1]}px;
  padding: ${theme.space[2]}px;
  border: 1px solid #c8c8c8;
  height: 36px;
`,
);
