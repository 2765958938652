import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  id: "id",
  capacity: "capacity",
  isLaneStart: "is_lane_start",
  order: "order",
  penName: "pen_name",
  penType: "pen_type",
  decisionCode: "decision_code",
};

export const serializePenArchetype = buildSerializer(
  serializeMap,
  "penArchetype",
);

export const deserializePenArchetype = buildDeserializer(
  serializeMap,
  "penArchetype",
);
