import React from "react";

import { faPlus, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useSelector, useDispatch } from "react-redux";

import { addAuctionPen } from "actions";

import Badge from "components/Badge";
import { MultiButton } from "components/Button";
import FontAwesomeButton, {
  ButtonIcon,
} from "components/Button/FontAwesomeButton";
import { ForMobile } from "components/ForDeviceType";
import BuyerWayCard from "components/GroupedBuyerCard/BuyerWayCard";
import { GroupedCard } from "components/GroupedCard";
import { Row } from "components/Layout";
import { CurrentSaleReadOnly } from "components/ReadOnly";
import { ResponsiveText } from "components/ResponsiveText";
import { BigText, BoldText, Subtitle } from "components/Text";

import { Settings } from "constants/settings";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { openDeliveryPenOwnerModal } from "lib/navigation";
import { getBuyerHashFromPenOwner, getBuyerHashFromSaleLot } from "lib/saleLot";

import {
  getAuctionPenById,
  getBusinessById,
  getBuyerAndBuyerWayIdsByBuyerHash,
  getSaleLotIdsByDeliveryPenId,
  getSaleLots,
  getSetting,
  selectSaleLotIdsByBuyerHashLookup,
  selectThirdPartyIdsByBuyerHashLookup,
} from "selectors";

import { useToggle } from "hooks";

function OwnerRow({ buyerHash }) {
  const buyerSaleLotIds =
    useSelector(selectSaleLotIdsByBuyerHashLookup)[buyerHash] || [];
  const saleLots = useSelector(getSaleLots);

  const quantity = buyerSaleLotIds.reduce(
    (acc, saleLotId) => acc + saleLots[saleLotId].quantity,
    0,
  );

  const buyer = useSelector(getBuyerAndBuyerWayIdsByBuyerHash(buyerHash));
  const business = useSelector(getBusinessById(buyer.buyerId));

  return (
    <>
      <Grid item xs={6}>
        <BoldText>
          {business?.name} - {buyer.buyerWayName || "No Buyer Way"}
        </BoldText>
      </Grid>
      <Grid item xs={3}>
        <BoldText>{buyerSaleLotIds.length}</BoldText>
      </Grid>
      <Grid item xs={3}>
        <BoldText>{quantity}</BoldText>
      </Grid>
    </>
  );
}

function DeliveryPenSummary({ deliveryPenId }) {
  const deliveryPen = useSelector(getAuctionPenById(deliveryPenId));

  const saleLotIds = useSelector(getSaleLotIdsByDeliveryPenId(deliveryPenId));

  const saleLots = useSelector(getSaleLots);

  const quantity = saleLotIds.reduce(
    (acc, saleLotId) => acc + saleLots[saleLotId].quantity,
    0,
  );
  // TODO Locked status
  const ownerList = deliveryPen.penOwners?.map(getBuyerHashFromPenOwner) || [];

  return (
    <Grid container>
      <Grid item xs={12} md={6} container spacing={1}>
        <Grid item xs={6} alignItems="center" container>
          <BigText>{getAuctionPenDisplayName(deliveryPen)}</BigText>
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={6} md={12}>
            <Subtitle>
              <ResponsiveText mobile="Lots" tablet="Lots In Pen" />
            </Subtitle>
            <BoldText>{saleLotIds.length}</BoldText>
          </Grid>
          <Grid item xs={6} md={12}>
            <Subtitle>
              <ResponsiveText mobile="Hd" tablet="Hd In Pen" />
            </Subtitle>
            <BoldText>{quantity}</BoldText>
          </Grid>
        </Grid>
      </Grid>

      {ownerList.length > 0 ? (
        <>
          <ForMobile>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </ForMobile>

          <Grid item container xs={12} md={6} alignItems="center" spacing={1}>
            <Grid item xs={6}>
              <Subtitle>Owners</Subtitle>
            </Grid>
            <Grid item xs={3}>
              <Subtitle>
                <ResponsiveText mobile="Lots" tablet="Owned Lots" />
              </Subtitle>
            </Grid>

            <Grid item xs={3}>
              <Subtitle>
                <ResponsiveText mobile="Hd" tablet="Owned Hd" />
              </Subtitle>
            </Grid>

            {ownerList.map(buyerHash => (
              <OwnerRow buyerHash={buyerHash} key={buyerHash} />
            ))}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}

function DeliveryPenGroupedItems({ deliveryPenId }) {
  const deliveryPen = useSelector(getAuctionPenById(deliveryPenId));
  const agencyId = useSelector(getSetting(Settings.agencyId));
  const thirdPartyIdsByBuyerHashLookup = useSelector(
    selectThirdPartyIdsByBuyerHashLookup,
  );

  if (deliveryPen.penOwners.length > 0) {
    return (
      <>
        {deliveryPen.penOwners.map(penOwner => {
          const buyerHash = getBuyerHashFromSaleLot({
            buyer_id: penOwner.businessId,
            buyer_way: penOwner.buyerWay,
          });
          if (thirdPartyIdsByBuyerHashLookup[buyerHash]) {
            return thirdPartyIdsByBuyerHashLookup[buyerHash].map(
              thirdPartyId => (
                <BuyerWayCard
                  agencyId={agencyId}
                  key={buyerHash}
                  showCommercialData={false}
                  buyerHash={buyerHash}
                  showBuyerData
                  expectedDeliveryPenId={deliveryPenId}
                  thirdPartyId={thirdPartyId}
                />
              ),
            );
          } else {
            return (
              <BuyerWayCard
                agencyId={agencyId}
                key={buyerHash}
                showCommercialData={false}
                buyerHash={buyerHash}
                showBuyerData
                expectedDeliveryPenId={deliveryPenId}
                thirdPartyId={null}
              />
            );
          }
        })}
      </>
    );
  }
  return null;
}

function DeliveryPenTopRow({ deliveryPenId, color, status }) {
  const buttons = [
    {
      title: "Add New Owner",
      isDisabled: false,
      onClick: () => {
        openDeliveryPenOwnerModal(deliveryPenId);
      },
      default: true,
      icon: faPlus,
    },
  ];

  const deliveryPen = useSelector(getAuctionPenById(deliveryPenId));

  const dispatch = useDispatch();
  const handleSubmit = () => {
    const values = {
      end_pen: deliveryPen.end_pen,
      penType: "DELIVERY",
      saleRoundId: deliveryPen.saleRoundId,
      start_pen: deliveryPen.start_pen,
      id: deliveryPen.id,
      is_locked: !deliveryPen.isLocked,
    };
    dispatch(addAuctionPen(values));
  };
  const lockIcon = deliveryPen.isLocked ? faLock : faLockOpen;
  return (
    <Row justifyBetween alignCenter>
      <Badge color={color}>{status}</Badge>
      <Row alignCenter justifyBetween>
        <FontAwesomeButton onClick={handleSubmit} padding={13} margin={5}>
          <ButtonIcon icon={lockIcon} style={{ fontSize: 10 }} />
        </FontAwesomeButton>
        <CurrentSaleReadOnly>
          <MultiButton buttons={buttons} />
        </CurrentSaleReadOnly>
      </Row>
    </Row>
  );
}

export function DeliveryPenCard({ deliveryPenId }) {
  const deliveryPenSaleLotIds = useSelector(
    getSaleLotIdsByDeliveryPenId(deliveryPenId),
  );
  const saleLots = useSelector(getSaleLots);
  const quantity = deliveryPenSaleLotIds.reduce(
    (acc, saleLotId) => acc + saleLots[saleLotId].quantity,
    0,
  );
  const deliveryPen = useSelector(getAuctionPenById(deliveryPenId));
  const [isOpen, toggleOpen] = useToggle(false);

  let color;
  let status;

  if (deliveryPen.isLocked) {
    color = "error";
    status = "LOCKED";
  } else if (quantity === 0) {
    if (deliveryPen.penOwners.length > 0) {
      color = "ready";
      status = "EMPTY";
    } else {
      color = "ongoing";
      status = "UNOWNED";
    }
  } else if (quantity > 0) {
    if (deliveryPen.penOwners.length > 0) {
      color = "success";
      status = "OCCUPIED";
    } else {
      color = "error";
      status = "UNKNOWN OWNER";
    }
  }
  const deliverPenTopRow = (
    <DeliveryPenTopRow
      deliveryPenId={deliveryPenId}
      color={color}
      status={status}
    />
  );
  return (
    <GroupedCard
      status={color}
      onClick={deliveryPen.penOwners.length > 0 ? toggleOpen : null}
      isOpen={isOpen}
      groupSummary={<DeliveryPenSummary deliveryPenId={deliveryPenId} />}
      groupedItems={<DeliveryPenGroupedItems deliveryPenId={deliveryPenId} />}
      actions={null}
      topRow={deliverPenTopRow}
    />
  );
}
