import { groupBy, isEmpty } from "lodash";
import isEqual from "lodash/isEqual";
import { createSelector } from "reselect";

import { OwnerTypeLabels, OwnerTypes } from "constants/aggrid";
import { Settings } from "constants/settings";

import { compareAgGridColumnStates } from "lib/compare";
import { OrderedSavedViewOwnerTypes } from "lib/savedViews";

import {
  createAggregateIdsByKeySelector,
  createLookupCombiner,
  createLookupSelectors,
  getDeployments,
  getSaleyards,
  getSavedViews,
  getSetting,
  selectNameByDeploymentIdLookup,
  selectSaleyardNameBySaleyardIdLookup,
} from "selectors";

export const getSavedViewById = savedViewId => state =>
  getSavedViews(state)[savedViewId] || null;

const viewCategoriser =
  (deploymentNameByDeploymentIdLookup, saleyardNameBySaleyardIdLookup) =>
  view => {
    if (view.isGlobal) {
      return OwnerTypeLabels[OwnerTypes.GLOBAL];
    }
    if (view.ownerType === OwnerTypes.DEPLOYMENT) {
      return deploymentNameByDeploymentIdLookup?.[view.ownerId];
    }
    if (view.ownerType === OwnerTypes.SALEYARD) {
      return saleyardNameBySaleyardIdLookup?.[view.ownerId];
    }
    return OwnerTypeLabels[OwnerTypes.USER];
  };

export const selectCategorisedSavedViewsByTableNameLookup = createSelector(
  [
    getSavedViews,
    selectNameByDeploymentIdLookup,
    selectSaleyardNameBySaleyardIdLookup,
  ],
  (
    savedViews,
    deploymentNameByDeploymentIdLookup,
    saleyardNameBySaleyardIdLookup,
  ) => {
    const byTable = groupBy(Object.values(savedViews), "table");
    const categoriser = viewCategoriser(
      deploymentNameByDeploymentIdLookup,
      saleyardNameBySaleyardIdLookup,
    );

    return Object.entries(byTable).reduce((acc, [tableName, views]) => {
      acc[tableName] = groupBy(views, categoriser);
      return acc;
    }, {});
  },
);

export const getCategorisedSavedViewsByTableName = tableName => state =>
  selectCategorisedSavedViewsByTableNameLookup(state)[tableName] || {};

export const selectSavedViewIdsByTableLookup = createAggregateIdsByKeySelector(
  getSavedViews,
  "table",
  "id",
);

export const [selectOwnerTypeBySavedViewIdLookup, getOwnerTypeBySavedViewId] =
  createLookupSelectors(
    [getSavedViews],
    createLookupCombiner(savedView => {
      if (savedView.isGlobal) {
        return OwnerTypes.GLOBAL;
      } else if (
        savedView.ownerType === OwnerTypes.DEPLOYMENT ||
        savedView.ownerType === OwnerTypes.SALEYARD
      ) {
        return savedView.ownerType;
      }
      return OwnerTypes.USER;
    }),
  );

export const [
  selectOwnerNameBySavedViewIdLookup,
  getOwnerNameBySavedViewIdLookup,
] = createLookupSelectors(
  [
    getSavedViews,
    selectOwnerTypeBySavedViewIdLookup,
    getDeployments,
    getSaleyards,
  ],
  createLookupCombiner(
    (
      savedView,
      ownerTypeBySavedViewIdLookup,
      deploymentByIdLookuo,
      saleyardByIdLookup,
    ) => {
      const ownerType = ownerTypeBySavedViewIdLookup[savedView.id];
      if (OwnerTypeLabels[ownerType]) {
        return OwnerTypeLabels[ownerType];
      }

      if (ownerType === OwnerTypes.DEPLOYMENT) {
        return deploymentByIdLookuo[savedView.ownerId]?.name;
      } else if (ownerType === OwnerTypes.SALEYARD) {
        return saleyardByIdLookup[savedView.ownerId]?.name;
      }

      // It would be unexpected to ever get here.
      // If (when) we do, return a generic label.
      return "Other";
    },
  ),
);

export const selectSavedViewIdsByOwnerTypeLookup =
  createAggregateIdsByKeySelector(
    state =>
      Object.entries(selectOwnerTypeBySavedViewIdLookup(state)).reduce(
        (acc, [savedViewId, ownerType]) => {
          acc[savedViewId] = { ownerType, id: savedViewId };
          return acc;
        },
        {},
      ),
    "ownerType",
    "id",
  );

export const getSavedViewIdsByTable = table => state =>
  selectSavedViewIdsByTableLookup(state)[table] || null;

export const getSavedViewIdsByOwnerType = ownerType => state =>
  selectSavedViewIdsByOwnerTypeLookup(state)[ownerType] || null;

export const [selectSavedViewsByOwnerTypeLookup, getSavedViewsByOwnerType] =
  createLookupSelectors(
    [selectSavedViewIdsByOwnerTypeLookup, getSavedViews],
    createLookupCombiner((savedViewIds, savedViewByIdLookup) =>
      savedViewIds.map(savedViewId => savedViewByIdLookup[savedViewId]),
    ),
  );

export const [selectSavedViewsByTableLookup, getSavedViewsByTable] =
  createLookupSelectors(
    [selectSavedViewIdsByTableLookup, getSavedViews],
    createLookupCombiner((savedViewIds, savedViewByIdLookup) =>
      savedViewIds.map(savedViewId => savedViewByIdLookup[savedViewId]),
    ),
  );

export const [
  selectOrderedSavedViewsByTableLookup,
  getOrderedSavedViewsByTable,
] = createLookupSelectors(
  [selectSavedViewsByTableLookup, selectSavedViewIdsByOwnerTypeLookup],
  createLookupCombiner((savedViews, savedViewIdsByOwnerTypeLookup) =>
    OrderedSavedViewOwnerTypes.reduce((acc, ownerType) => {
      const ownerTypeSavedViewIds =
        savedViewIdsByOwnerTypeLookup[ownerType] || [];
      const tableSavedViews = savedViews.filter(savedView =>
        ownerTypeSavedViewIds.includes(savedView.id),
      );
      const orderedTableSavedViews = tableSavedViews.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      return acc.concat(orderedTableSavedViews);
    }, []),
  ),
);

export const SavedViewModificationType = {
  FILTERS: "FILTERS",
  STATE: "STATE",
};

export const [
  selectModificationTypesBySavedViewIdLookup,
  getModificationTypesBySavedViewIdLookup,
] = createLookupSelectors(
  [getSavedViews, getSetting(Settings.tableViews)],
  createLookupCombiner((savedView, tableViewByNameLookup) => {
    const tableView = tableViewByNameLookup[savedView.table] || {};
    const { content, filters } = savedView;
    const state = JSON.parse(content);
    const modificationTypes = [];
    if (tableView.viewId && tableView.viewId !== savedView.id) {
      return modificationTypes;
    }

    if (!isEqual(tableView.filters, filters)) {
      const isTableViewFiltersBlank =
        isEmpty(tableView.filters) ||
        tableView.filters === null ||
        tableView.filters === undefined;
      const isSavedViewFiltersBlank = isEmpty(filters) || filters === null;
      if (!isTableViewFiltersBlank || !isSavedViewFiltersBlank) {
        modificationTypes.push(SavedViewModificationType.FILTERS);
      }
    }

    if (!compareAgGridColumnStates(tableView.state, state)) {
      modificationTypes.push(SavedViewModificationType.STATE);
    }
    return modificationTypes;
  }),
);
