import { orderBy, sortBy } from "lodash";
import { createSelector } from "reselect";

import { EMPTY_ARRAY, getDollarPriceStringFromCents } from "lib";

import { compareSaleRoundAndPen, sortByRoundAndPen } from "lib/auctionPens";
import { unsoldSaleLotFilter } from "lib/filters";
import { hasWeight, isSold } from "lib/saleLot";
import { getDateFromISO8601DateString } from "lib/timeFormats";

import { skipCategoryIdsSelector } from "selectors/categories";
import { getSaleLotsBySale } from "selectors/saleLots";

import { selectHasSingleWeighInCurrentSale } from "./singleWeighs";

export const getSaleLotsForWatcher = createSelector(
  [getSaleLotsBySale, skipCategoryIdsSelector],
  (saleLots, skipCategoryIds) =>
    saleLots.filter(
      saleLot =>
        saleLot.start_pen && !skipCategoryIds.includes(saleLot.category_id),
    ),
);
export const getSaleLotsInLikelyOrder = createSelector(
  [getSaleLotsForWatcher],
  saleLots => sortByRoundAndPen(saleLots),
);
export const getUnSoldSaleLotsInOrder = createSelector(
  [getSaleLotsInLikelyOrder],
  saleLots => {
    return saleLots.filter(unsoldSaleLotFilter);
  },
);
export const getSoldSaleLotsInSoldOrder = createSelector(
  [getSaleLotsForWatcher],
  saleLots =>
    orderBy(
      saleLots.filter(sl => Boolean(sl.buyer_id)),
      saleLot =>
        saleLot.first_sold
          ? getDateFromISO8601DateString(saleLot.first_sold)
          : null,
      "desc",
    ),
);

export const selectSaleLotsByLastSeenDraftingIdLookup = createSelector(
  [getSaleLotsForWatcher],
  saleLots =>
    orderBy(
      Object.values(saleLots).filter(saleLot => saleLot.lastSeenAtTime),
      saleLot => saleLot.lastSeenAtTime,
      ["desc"],
    ).reduce((acc, saleLot) => {
      if (acc[saleLot.lastSeenAtDraftId]) {
        acc[saleLot.lastSeenAtDraftId].push(saleLot);
      } else {
        acc[saleLot.lastSeenAtDraftId] = [saleLot];
      }
      return acc;
    }, {}),
);

export const getLastSeenSaleLotsByDraftingId = draftingId => state =>
  selectSaleLotsByLastSeenDraftingIdLookup(state)[draftingId] || EMPTY_ARRAY;

export const selectWeighedSaleLotsInWeighedOrder = createSelector(
  [getSaleLotsForWatcher, selectHasSingleWeighInCurrentSale],
  saleLots =>
    sortBy(
      saleLots.filter(formattedSaleLot =>
        hasWeight(formattedSaleLot.total_mass_grams),
      ),
      saleLot =>
        saleLot.timeWeighed
          ? getDateFromISO8601DateString(saleLot.timeWeighed)
          : null,
    ),
);

export const selectUnsoldWeighedSaleLotsInWeighedOrderAfterCurrentSold =
  createSelector(
    [selectWeighedSaleLotsInWeighedOrder, getSoldSaleLotsInSoldOrder],
    (weighedSaleLots, soldSaleLots) => {
      // Finds unsold lots, weighed after when the last sold lot was weighed.
      // In the context of ring selling (especially Swan Hill, but elsewhere too) - this is the likely order they will appear in the ring.
      // This has a minor hole, in that if a lot was weighed BEFORE the one that was sold, it will never appear in this list.
      // but it's an acceptable logic limitation (tm)

      // Find the last sold lot, with a time weighed.
      const lastSoldLotIdWithWeighTime =
        soldSaleLots.find(saleLot => Boolean(saleLot.time_weighed))?.id || null;
      // const lastSoldId = soldSaleLots?.[0]?.id || null;

      const lastSoldIndex = lastSoldLotIdWithWeighTime
        ? weighedSaleLots.findIndex(
            lot => lot.id === lastSoldLotIdWithWeighTime,
          )
        : 0;

      //
      // Return everything weighed after the last sold, that is unsold.
      return weighedSaleLots
        .slice(lastSoldIndex)
        .filter(saleLot => !saleLot.buyer_id);
    },
  );

export const getSellingNowByPriceSaleLot = createSelector(
  [
    getSaleLotsInLikelyOrder,
    getUnSoldSaleLotsInOrder,
    getSoldSaleLotsInSoldOrder,
  ],
  (allLots, unSoldLots, soldLots) => {
    // Try to decuce the likely next one, by finding the pen AFTER the last sold.
    // if we haven't sold any, just return the top of the unsold list.
    const lastSold = soldLots?.[0];
    if (!lastSold) {
      return unSoldLots?.[0];
    }

    const idxOfLastSold = allLots.findIndex(sl => sl.id === lastSold.id);
    if (idxOfLastSold > 0 && idxOfLastSold < allLots.length) {
      // Found the last sold in the primary ordering.
      // Pull the next unsold one off the list!
      const nextUnsold = allLots.slice(idxOfLastSold).find(sl => !sl.buyer_id);
      if (nextUnsold) {
        return nextUnsold;
      }
    }
    return unSoldLots?.[0];
  },
);
// Show any lots that are unsold, but BEFORE the selling now.
export const getSkippedSaleLots = createSelector(
  [getUnSoldSaleLotsInOrder, getSellingNowByPriceSaleLot],
  (saleLots, sellingNow) =>
    saleLots.filter(
      sl =>
        sl.id !== sellingNow.id && compareSaleRoundAndPen(sl, sellingNow) < 0,
    ),
);
// Any lots unsold, AFTER the selling now.
export const getNextUpSaleLotsInOrder = createSelector(
  [getUnSoldSaleLotsInOrder, getSellingNowByPriceSaleLot],
  (saleLots, sellingNow) =>
    saleLots.filter(
      sl =>
        sl.id !== sellingNow.id && compareSaleRoundAndPen(sl, sellingNow) >= 0,
    ),
);

function summarizeByGroup(summary, saleLot, grouper, order) {
  if (!summary[grouper]) {
    summary[grouper] = {
      grouper,
      order,
      lots: 0,
      lotsSold: 0,
      head: 0,
      headSold: 0,
      averagePerHead: 0,
      totalWeightGrams: 0,
      totalWeightGramsSold: 0,
      totalPriceCents: 0,
      topTotal: null,
      topPerHead: null,
    };
  }
  summary[grouper].lots += 1;
  summary[grouper].head += saleLot.quantity;
  summary[grouper].totalWeightGrams += saleLot.total_mass_grams;
  if (isSold(saleLot.buyer_id, saleLot.vendor_id)) {
    summary[grouper].lotsSold += 1;
    summary[grouper].headSold += saleLot.quantity;
    summary[grouper].totalWeightGramsSold += saleLot.total_mass_grams;
    summary[grouper].totalPriceCents += saleLot.total_price_cents;

    if (summary[grouper].headSold > 0) {
      summary[grouper].averagePerHead = getDollarPriceStringFromCents(
        summary[grouper].totalPriceCents / summary[grouper].headSold,
        "0",
        true,
      );
    }

    if (
      !summary[grouper].topTotal ||
      summary[grouper].topTotal.total_price_cents < saleLot.total_price_cents
    ) {
      summary[grouper].topTotal = saleLot;
    }
    if (
      !summary[grouper].topUnitPrice ||
      summary[grouper].topUnitPrice.dollar_per_head < saleLot.dollar_per_head
    ) {
      summary[grouper].topUnitPrice = saleLot;
    }
  }

  if (summary[grouper].head) {
    summary[grouper].clearanceRate =
      (summary[grouper].headSold / summary[grouper].head) * 100;
  } else {
    summary[grouper].clearanceRate = 0;
  }

  return summary;
}

export const saleWatcherSummaryBySex = createSelector(
  [getSaleLotsForWatcher],
  saleLots =>
    Object.values(
      saleLots.reduce((summary, saleLot) => {
        const grouper = saleLot.sex_name || "Sex Not Recorded";
        const order = saleLot.sex_name || "";
        return summarizeByGroup(summary, saleLot, grouper, order);
      }, {}),
    ).sort((a, b) => a.order < b.order),
);

export const saleWatcherSummaryByRound = createSelector(
  [getSaleLotsForWatcher],
  saleLots =>
    Object.values(
      saleLots.reduce((summary, saleLot) => {
        // Group cattle by sex, sheep (and every thing else) by round.
        const grouper = saleLot.sale_round_name;
        const { order } = saleLot.sale_round;

        return summarizeByGroup(summary, saleLot, grouper, order);
      }, {}),
    ).sort((a, b) => a.order < b.order),
);

export const selectSaleWatcherSummary = createSelector(
  [getSaleLotsForWatcher],
  saleLots =>
    Object.values(
      saleLots.reduce((summary, saleLot) => {
        // Group cattle by sex, sheep (and every thing else) by round.
        const grouper = "Total";
        const order = "";
        return summarizeByGroup(summary, saleLot, grouper, order);
      }, {}),
    )[0],
);
