import React from "react";

import { Grid } from "@material-ui/core";
import { Form } from "formik";

import { Button, SecondaryButton } from "components/Form";
import {
  Input,
  Switch,
  useSubmitHandler,
} from "components/Form/FormikControls";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { useIsMobile } from "hooks";

const SettingsForm = ({ handleClose }) => {
  const fullScreen = useIsMobile();
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  useSubmitHandler(false, setIsSubmitEnabled);

  return (
    <Dialog
      id="settings-modal"
      open
      scroll="body"
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
    >
      <Form data-tour="settings-form">
        <DialogTitle onClose={handleClose}>Single Weigh Settings</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                name="noEidDelay"
                label="EID Delay"
                tooltip="How long to wait before flagging a missing EID as an operator action, -1 to disable"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="unsteadyWeightDelay"
                label="Unsteady Weight Delay"
                tooltip="How long to wait before flagging unsteady weight as an operator action, -1 to disable"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="reweighWarningThreshold"
                label="Reweigh Warning Threshold %"
                afterSymbol="%"
                tooltip="The difference between a new and old weight to flag as a warning on a reweigh event"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                afterSymbol="kg"
                label="Minimum Stable Weight"
                multiplier={1000}
                name="minStableWeightGrams"
                tooltip="Minimum weight to accept as a stable weight"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                afterSymbol="ms"
                name="stableWeightDebounce"
                label="Stable Weight Interval"
                tooltip="The minimum time in milliseconds between sampling weights"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                afterSymbol="kg"
                label="Stable Weight Threshold"
                multiplier={1000}
                name="stableWeightSampleThresholdGrams"
                tooltip="The maximum allowed difference in the sampled weights when determining if they are stable"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Stable Weight Samples"
                name="stableWeightSampleCount"
                tooltip="The number of weights sampled before determining if the current weight is stable."
              />
            </Grid>
            <Grid xs={12} item>
              <Switch label="Is Paused" name="isPaused" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <Button data-tour="submit" type="submit" disabled={!isSubmitEnabled}>
            Save
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default SettingsForm;
