import React, { memo } from "react";

import { ResizableBox } from "react-resizable";
import styled from "styled-components/macro";

import { Column } from "components/Layout";
import AutoDraftPensList from "components/SingleWeighView/SingleWeighPenTable";

const ResizeHandler = styled.div(
  ({ theme }) => `
  position: absolute;
  top: 0;
  cursor: row-resize;
  width: 100%;
  user-select: none;
  border: 2px solid ${theme.colors.primary};
  transition: ${theme.transitions[1]};

  &:hover {
    border: 2px solid ${theme.colors.primaryHighlight};
  }
`,
);

const Handle = React.forwardRef((props, ref) => {
  return <ResizeHandler ref={ref} {...props} />;
});

const Resizeble = styled(ResizableBox)`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;

function SingleWeighPensStatusComponent() {
  // ResizeableBox height prop must be specified and it must be an int, so set to 50% of the innerHeight.
  const expectedControlHeight = 350;
  const initialHeight = window.innerHeight - expectedControlHeight;
  return (
    <Resizeble
      axis="y"
      resizeHandles={["n"]}
      handle={<Handle />}
      height={initialHeight}
      width={Infinity}
    >
      <Column
        background="surfaceBackground"
        minHeight="50px"
        padding={1}
        fullHeight
      >
        <AutoDraftPensList />
      </Column>
    </Resizeble>
  );
}
export default memo(SingleWeighPensStatusComponent);
