import styled from "styled-components/macro";

const Card = styled.div(
  ({ theme: { colors, radii, shadows, space }, width }) => `
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  
  color: ${colors.gray40};
  border: 1px solid ${colors.gray78};
  border-radius: ${radii[1]}px;
  background-color: ${colors.white};
  box-shadow: ${shadows[3]};
  padding: ${space[3]}px ${space[2]}px;
  margin: ${space[2]}px;
  ${width ? `width: ${width}` : ""};
`,
);
export default Card;
