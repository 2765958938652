import React from "react";

import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";

import { MyAccountsTable } from "containers/Settings/Billing/Accounts/MyAccountsTable";

export const Accounts = () => {
  return (
    <Row flexGrow full>
      <Column margin={2} gridGap={2} full>
        <Paper square>
          <MyAccountsTable />
        </Paper>
      </Column>
    </Row>
  );
};
