import React from "react";

import { useTheme } from "hooks";

export const FrontGateLockedBackGateClosed = ({ color, size }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.black;

  const svgSize = size || theme.fontSizes.gamma;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox="0 0 27500 27500"
    >
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6433.4"
        y1="6331.8"
        x2="6433.4"
        y2="18110.9"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21271.3"
        y1="9722.9"
        x2="21271.3"
        y2="6331.8"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6433.4"
        y1="21169.7"
        x2="6433.4"
        y2="17893.2"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13852.3"
        y1="21169.7"
        x2="21271.3"
        y2="21169.7"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21271.3"
        y1="21169.7"
        x2="21271.3"
        y2="9722.9"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13852.3"
        y1="6331.8"
        x2="21271.3"
        y2="6331.8"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6433.4"
        y1="21169.7"
        x2="13852.3"
        y2="21169.7"
      />
      <line
        fill="none"
        stroke={svgColor}
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6433.4"
        y1="6331.8"
        x2="13852.3"
        y2="6331.8"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6433.4"
        cy="6331.8"
        r="333.9"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6433.4"
        cy="21169.7"
        r="333.9"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13852.3"
        cy="21169.7"
        r="333.9"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13852.3"
        cy="6331.8"
        r="333.9"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21271.3"
        cy="9722.9"
        r="333.9"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21271.3"
        cy="21169.7"
        r="333.9"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21271.3"
        cy="6331.8"
        r="333.9"
      />
      <path
        fill={svgColor}
        stroke={svgColor}
        d="M2884.8 13236.7c-13.7-118.6-1.4-309.3-1.4-435.8 0.1-153.1-6.6-254.8 52-385.6 47.1-105.1 112.7-175.1 200.5-236.8 200.5-141 460.3-134.1 661.4 2.3 236.1 160.2 248.3 378.1 248.2 627.6 0 131.1 8.4 301.6-2.4 428.2l-1158.2 0.1zm-642.1-615.3l-1.8 619.1-203.2-1.5c-165.1-10.7-297.3 116.2-344.1 221.4-57.9 130.3-36.4 370.1-36.7 525-0.5 374.7 0.3 749.5-0.1 1124.2-0.5 501 390.2 448.9 682.8 448.9l2248.4-0.2c217.4 0 518.1 44.9 638.7-209.7 40.3-85 44.2-137.4 44-239-0.5-374.7-0.1-749.5 0.1-1124.2 0.1-162.5 22.7-392.2-40-529.7-46.3-101.4-183-227.3-342-216.3l-201.6-1.8-0.8-613.2c12.2-223.4-101.3-508.1-227.9-682.4-38.9-53.6-97.9-126.2-138.7-163l-165.4-136.5c-186.4-130.5-451.4-212.1-690.6-211.8-315 0.4-546.3 103.5-776.3 277l-218.2 234.6c-120.8 148.5-237.7 466.1-226.7 679.2z"
      />
      <circle
        fill={svgColor}
        stroke={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6414.6"
        cy="17772.4"
        r="339"
      />
    </svg>
  );
};

export default FrontGateLockedBackGateClosed;
