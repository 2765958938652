import * as Yup from "yup";

import { NVDValidator } from "lib/validators";

export const ConsignmentValidationSchema = Yup.object().shape({
  vendor_id: Yup.string()
    .required("The vendor is required")
    .notOneOf(["-1"], "The vendor is required"),
  quantity: Yup.number()
    .required("The headcount is required")
    .max(1000, "Headcount must be less than 1000")
    .min(0, "Headcount must be greater than or equal to 0"),
  vendor_property_id: Yup.mixed(),
  fileSize: Yup.number().max(
    500000,
    "File Size too large, must be under 500kb",
  ),
  fileType: Yup.string().oneOf(
    ["image/jpeg", "image/png"],
    "Must be either jpeg or png",
  ),
  NVD: NVDValidator,
});
