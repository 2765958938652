import React from "react";

import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { SmallFaIcon, LargeFaIcon } from "components/FaIcon";
import { Row, PaddedIcon } from "components/Layout";

const NavWrapper = styled.div`
  position: relative;
`;

const LeftArrowWrapper = styled.div`
  position: absolute;
  top: 85px;
  left: 0px;
  cursor: pointer;
`;
const RightArrowWrapper = styled.div`
  position: absolute;
  top: 85px;
  right: 0px;
  cursor: pointer;
`;

const BackButton = styled.span`
  cursor: pointer;
  padding-left: ${({ theme }) => theme.space[1]}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.white};
`;

const BackWrapper = styled(Row)`
  align-items: center;
  padding: ${({ theme }) => `${theme.space[1]}px ${theme.space[2]}px`};
  ${({ theme }) => `${theme.active}`}
`;

const NavBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

const AuctionPenNavigation = ({
  handleBack,
  incrementAuctionPen,
  hasNext,
  hasPrev,
}) => (
  <NavWrapper>
    <NavBackground />
    <Row>
      <BackWrapper onClick={handleBack}>
        <SmallFaIcon icon={faAngleLeft} />
        <BackButton data-tour="back">Back</BackButton>
      </BackWrapper>
    </Row>
    <LeftArrowWrapper data-tour="leftArrow">
      {hasPrev && (
        <PaddedIcon onClick={() => incrementAuctionPen(false)}>
          <LargeFaIcon icon={faAngleLeft} />
        </PaddedIcon>
      )}
    </LeftArrowWrapper>
    <RightArrowWrapper data-tour="rightArrow">
      {hasNext && (
        <PaddedIcon onClick={() => incrementAuctionPen(true)}>
          <LargeFaIcon icon={faAngleRight} />
        </PaddedIcon>
      )}
    </RightArrowWrapper>
  </NavWrapper>
);

AuctionPenNavigation.propTypes = {
  handleBack: PropTypes.func.isRequired,
  incrementAuctionPen: PropTypes.func.isRequired,
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool,
};

export default React.memo(AuctionPenNavigation);
