import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { updateDeploymentAttributes } from "actions";

import { UPDATE_DEPLOYMENT_SEXES } from "constants/actionTypes";

import Table from "containers/Settings/DeploymentAttributesTable";
import { HeaderBulkSelectCellRenderer } from "containers/Settings/Renderers";

import { getDeploymentAttributes } from "selectors";

import { getActiveSpeciesAttributes } from "selectors/speciesAttributes";

const columnDefs = [
  { headerName: "Name", field: "name", rowDrag: true, editable: false },
  {
    headerName: "Use",
    field: "is_used",
    cellRenderer: "checkboxRenderer",
    editable: false,
    headerComponentFramework: HeaderBulkSelectCellRenderer,
  },
];

export const SexSettings = () => {
  const sexes = Object.values(useSelector(getActiveSpeciesAttributes).sexes);
  const deploymentData = Object.values(
    useSelector(getDeploymentAttributes).sexes,
  );

  const dispatch = useDispatch();

  const handleUpdate = payload =>
    dispatch(updateDeploymentAttributes(UPDATE_DEPLOYMENT_SEXES, payload));

  return (
    <Table
      dataKey="sex_id"
      baseData={sexes}
      deploymentData={deploymentData}
      handleUpdate={handleUpdate}
      columnDefs={columnDefs}
    />
  );
};
