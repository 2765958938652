import { put, select, takeEvery } from "redux-saga/effects";

import { SaleAction, setCurrentLivestockSaleId } from "actions";

import { patchDeploymentSale } from "actions/offline/deploymentSale";

import { SALE } from "constants/actionTypes";
import { SaleTypes } from "constants/sale";

import {
  getLivestockSaleId,
  getSaleEntryRoute,
  openConsignmentsTable,
  openEditConsignmentModal,
} from "lib/navigation";
import toast from "lib/toast";

import { getConsignments, getCurrentSale, getSaleLots } from "selectors";

import history from "appHistory";

function onSaleCreateSuccess(action) {
  const { payload } = action;
  const { sale_type, saleyard_name, livestocksale_id } = payload;

  history.push(getSaleEntryRoute(saleyard_name, livestocksale_id, sale_type));
  if (sale_type === SaleTypes.PADDOCK) {
    // if we create a paddock sale, we want to direct the user
    // straight to the consignment form
    openConsignmentsTable();
    openEditConsignmentModal();
  }
}

function* onSaleFetchSuccess(action) {
  const { meta, payload } = action;
  const { options } = meta;
  const { livestocksale_id } = payload;
  const { openOnFetch = false } = options;
  if (openOnFetch) {
    yield put(setCurrentLivestockSaleId(livestocksale_id, true));
  }
}

export function* deleteCurrentSale() {
  const state = yield select();
  const sale = getCurrentSale(state);
  const saleLots = getSaleLots(state);
  const consignments = getConsignments(state);

  const errorMessage = name =>
    `You can’t delete a sale that contains any ${name}`;

  if (Object.values(saleLots).length > 0) {
    toast.error(errorMessage("lots"));
  } else if (Object.values(consignments).length > 0) {
    if (sale.sale_type?.includes(SaleTypes.CLEARING)) {
      toast.error(errorMessage("vendors"));
    } else {
      toast.error(errorMessage("consignments"));
    }
  } else {
    yield put(SaleAction.delete(getLivestockSaleId()));
  }
}

export function* updateSaleAndDeploymentSale(action) {
  const { livestockSaleId, salePayload, deploymentSales } = action;

  for (const deploymentSale of deploymentSales) {
    yield put(
      patchDeploymentSale(
        deploymentSale.payload,
        deploymentSale.deploymentSaleId,
        livestockSaleId,
        {
          updateExistingSaleLotExportSites:
            deploymentSale.payload?.updateExistingSaleLotExportSites,
        },
      ),
    );
  }

  yield put(SaleAction.update({ id: livestockSaleId, ...salePayload }));
}

export default function* rootSaga() {
  yield takeEvery(SALE.CREATE.SUCCESS, onSaleCreateSuccess);
  yield takeEvery(SALE.FETCH.SUCCESS, onSaleFetchSuccess);
  yield takeEvery(SALE.CHECK_AND_DELETE_CURRENT.REQUEST, deleteCurrentSale);
  yield takeEvery(
    SALE.UPDATE_WITH_DEPLOYMENT_SALES.REQUEST,
    updateSaleAndDeploymentSale,
  );
}
