import React from "react";

import EmailReportJobModal from "components/ReportJob/SendEmailModal";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

const EmailReportJobModalAdapter = props => {
  const [hashParams, ignored, onClose] = useModalAdapter(
    ModalTypes.EmailReportJobModal,
    props,
  );
  const { id: reportJobId } = hashParams;

  return <EmailReportJobModal reportJobId={reportJobId} onClose={onClose} />;
};

export default EmailReportJobModalAdapter;
