import React, { useRef } from "react";

import partition from "lodash/partition";
import { useDispatch, useSelector } from "react-redux";

import { createBusinessFromImport } from "actions";

import { BaseTable } from "components/Importer/BaseTable";

import { validateEmail } from "lib/validators";

import { selectPropertyEnrichedBusinessByBusinessIdLookup } from "selectors";

export function BusinessImporter({
  importBusinesses,
  columns,
  agrinousBusinessLookupSelector,
  lookupKey,
}) {
  const businessIdByKeyLookup = useSelector(agrinousBusinessLookupSelector);

  const businessByIdLookup = useSelector(
    selectPropertyEnrichedBusinessByBusinessIdLookup,
  );
  const agGridInstance = useRef();

  const onGridReady = params => {
    agGridInstance.current = params;
    const defaultSortModel = [
      { colId: "agrinousBusiness.name", sort: "asc", sortIndex: 0 },
      { colId: "businessName", sort: "asc", sortIndex: 1 },
    ];

    params.columnApi.applyColumnState({
      state: defaultSortModel,
    });
  };

  const businessData = React.useMemo(
    () =>
      importBusinesses.map(b => ({
        ...b,
        agrinousBusiness:
          businessByIdLookup[businessIdByKeyLookup[b[lookupKey]]],
      })),

    [businessIdByKeyLookup, businessByIdLookup, importBusinesses, lookupKey],
  );
  const dispatch = useDispatch();

  const missingBusinesses = businessData.filter(
    business => !business.agrinousBusiness,
  );
  const createBusiness = payload => {
    const { email } = payload;
    const [validEmails, _ignored] = partition(
      email.split(/,|\s+|;/).map(e => e.trim()),
      validateEmail,
    );
    payload.email = validEmails.join(", ");

    dispatch(createBusinessFromImport(payload));
  };

  const getContextMenuItems = params => {
    const contextMenu = Array.isArray(params.defaultItems)
      ? [...params.defaultItems]
      : [];

    contextMenu.push({
      name: `Create All Missing Businesses (${missingBusinesses.length})`,
      action: () => missingBusinesses.forEach(createBusiness),
      disabled: missingBusinesses.length === 0,
    });
    return contextMenu;
  };

  return (
    <BaseTable
      columns={columns}
      data={businessData}
      onGridReady={onGridReady}
      getContextMenuItems={getContextMenuItems}
    />
  );
}
