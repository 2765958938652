import React from "react";

import { Collapse, Grid } from "@material-ui/core";
import sumBy from "lodash/sumBy";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  PrimaryInfoHeaderLarge,
  PrimaryInfoHeaderMobile,
} from "components/AuctionPens/Cards/PrimaryInfoSet";
import Badge from "components/Badge";
import StatusCard from "components/Card/StatusCard";
import { OpenIndicator } from "components/Icons/CollapseIcon";
import { Column } from "components/Layout";

import { NominationStatusColor, NominationStatusText } from "constants/sale";

import {
  getBusinessById,
  getNameByDeploymentId,
  getNominationById,
  getStatusByNominationId,
} from "selectors";

import { useToggle } from "hooks";

import { NominationDetail } from "./NominationDetail";

export const NominationHd = styled.p(
  ({ theme }) => `
font-size: ${theme.fontSizes.gamma}px;
margin-right: ${theme.space[2]}px;
font-weight: ${theme.fontWeights.bold};
text-align: center;
margin: ${theme.radii[0]}px;
`,
);

export const Nomination = ({ id }) => {
  const nomination = useSelector(getNominationById(id)) || {};

  const { consigningDeploymentId, deploymentId, nominationDetails, vendorId } =
    nomination;

  const vendorName = useSelector(getBusinessById(vendorId))?.name;
  const consigningDeploymentName = useSelector(
    getNameByDeploymentId(consigningDeploymentId || deploymentId),
  );
  const nominatedHd = sumBy(nominationDetails, "quantity");

  const status = useSelector(getStatusByNominationId(id));
  const [expanded, toggleExpanded] = useToggle(false);
  return (
    <Column margin={2}>
      <StatusCard
        status={NominationStatusColor[status]}
        expanded={expanded}
        onClick={toggleExpanded}
      >
        <Grid container>
          <Grid item container xs={11}>
            <Grid item xs={5}>
              {vendorName} ({consigningDeploymentName})
            </Grid>
            <Grid item xs={4}>
              <PrimaryInfoHeaderLarge>Nominated Hd</PrimaryInfoHeaderLarge>
              <PrimaryInfoHeaderMobile>Hd</PrimaryInfoHeaderMobile>
            </Grid>
            <Grid item xs={3} />

            <Grid item xs={5}>
              <div>
                <Badge uppercase color={NominationStatusColor[status]}>
                  {NominationStatusText[status]}
                </Badge>
              </div>
            </Grid>
            <Grid item xs={4}>
              <NominationHd>{nominatedHd}</NominationHd>
            </Grid>
            <Grid item xs={3} />
          </Grid>
          <Grid item xs={1}>
            <Column>
              <OpenIndicator isOpen={expanded} />
            </Column>
          </Grid>
        </Grid>
      </StatusCard>
      <Grid item xs={12}>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
          <NominationDetail id={id} />
        </Collapse>
      </Grid>
    </Column>
  );
};
