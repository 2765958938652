import { createSelector } from "reselect";

import { EMPTY_ARRAY } from "lib";

import { getEmails, getReportJobs } from "selectors/root";

export const selectReportJobAggridData = createSelector(
  [getReportJobs, getEmails],
  (reportJobByIdLookup, emailByIdLookup) => {
    const data = [];
    for (const reportJob of Object.values(reportJobByIdLookup)) {
      data.push({
        reportJob: {
          ...reportJob,
          emails:
            reportJob.emailIds?.map(id => emailByIdLookup[id]) || EMPTY_ARRAY,
        },
      });
    }
    return data;
  },
);
