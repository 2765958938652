import React from "react";

import Grid from "@material-ui/core/Grid";

import { CollapseHeader, FormCollapse } from "components/Form";
import { InterestSettingForm } from "components/Form/FormikControls/Interest";

import { DeploymentPermissions } from "constants/permissions";
import { FinanceSubModalSection } from "constants/settings";

import { useHasDeploymentPermission, useSectionHelper } from "hooks";

export const InterestSettings = ({ sectionHelper }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.INTEREST,
  );

  const hasInterestFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureInterest,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<CollapseHeader title="Interest" />}
    >
      <Grid item xs={12} md={6}>
        {hasInterestFeature && (
          <Grid container item spacing={2} xs={12}>
            <InterestSettingForm />
          </Grid>
        )}
      </Grid>
    </FormCollapse>
  );
};
