import React from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components/macro";

import { Button, SecondaryButton, BottomSticky } from "components/Form";
import { Row, Column } from "components/Layout";
import LotCard from "components/LotCard";
import { FormModal } from "components/Modal";

import { getNestedConsignments } from "selectors";

const SaleLotsContainer = styled.div`
  width: 100%;
  padding: 6px;
  margin: 0 -6px;
`;

const SaleLotWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  ${({ theme, selected }) =>
    selected && `box-shadow: 0 0 5px 2px ${theme.colors.ready};`};
`;

const StyledSticky = styled(BottomSticky)`
  background: white;
  margin-top: 1rem;
`;

class AddToLotModal extends React.Component {
  state = { selected: null };

  setSelected = selected => this.setState({ selected });

  onSubmit = () => {
    const { selected } = this.state;
    const { closeSelf, modalProps } = this.props;

    const { onNewSaleLotId } = modalProps;
    typeof onNewSaleLotId === "function" && onNewSaleLotId(selected);
    closeSelf();
  };

  render() {
    const { selected } = this.state;
    const { closeSelf, saleLots } = this.props;

    return (
      <FormModal
        title="Add EIDs to existing lot"
        contentStyles={{ margin: "0 24px", maxHeight: "100%" }}
        closeSelf={closeSelf}
      >
        <Column>
          <SaleLotsContainer>
            {saleLots.map(lot => (
              <SaleLotWrapper key={lot.id} selected={selected === lot.id}>
                <LotCard
                  onClickCard={() => {
                    this.setSelected(lot.id);
                  }}
                  showActions={false}
                  saleLot={lot}
                  showScanning
                  showPen
                />
              </SaleLotWrapper>
            ))}
          </SaleLotsContainer>
        </Column>
        <StyledSticky>
          <Row>
            <SecondaryButton onClick={closeSelf}>Cancel</SecondaryButton>
            <Button
              data-tour="addToLot"
              onClick={this.onSubmit}
              disabled={selected === null}
            >
              Add to Lot
            </Button>
          </Row>
        </StyledSticky>
      </FormModal>
    );
  }
}

AddToLotModal.propTypes = {
  closeSelf: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { consignmentId } = props;
  const consignments = getNestedConsignments(state, props);
  const consignment = consignments.find(c => c.id === consignmentId) || {};
  return {
    saleLots: consignment.saleLots,
  };
};

export default connect(mapStateToProps)(AddToLotModal);
