import { createSelector } from "reselect";

import { BillingDocumentType } from "constants/billingDocuments";
import { SystemLabelName } from "constants/deploymentLabels";

import { EMPTY_OBJECT } from "lib";

import {
  getBillingDocuments,
  getCurrentSale,
  getManualAdjustments,
  selectFromBusinessIdByManualAdjustmentIdLookup,
  selectLabelEntryIdsByLabelNameLookup,
  selectManualAdjustmentIdsByLivestockSaleId,
  selectToBusinessIdByManualAdjustmentIdLookup,
} from "selectors";

export const selectManualAdjustmentIdsByTypeLookup = createSelector(
  [getManualAdjustments],
  manualAdjustments => {
    return Object.values(manualAdjustments).reduce((acc, cur) => {
      acc[cur.entityType] = {
        ...(acc[cur.entityType] || {}),
        [cur.entityId]: [...(acc[cur.entityType]?.[cur.entityId] || []), cur],
      };

      return acc;
    }, {});
  },
);

export const selectorCategorisedBusinessIdsFromCurrentSaleManualAdjustments =
  createSelector(
    [
      selectManualAdjustmentIdsByLivestockSaleId,
      selectFromBusinessIdByManualAdjustmentIdLookup,
      selectToBusinessIdByManualAdjustmentIdLookup,
      getCurrentSale,
    ],
    (
      manualAdjustmentIdsByLivestockSaleId,
      fromBusinessIdByManualAdjustmentIdLookup,
      toBusinessIdByManualAdjustmentIdLookup,
      currentSale,
    ) => {
      const manualAdjustmentIdsInSale =
        manualAdjustmentIdsByLivestockSaleId[currentSale?.livestocksale_id] ||
        [];

      return manualAdjustmentIdsInSale.reduce(
        (acc, cur) => {
          acc.from.push(fromBusinessIdByManualAdjustmentIdLookup[cur]);
          acc.to.push(toBusinessIdByManualAdjustmentIdLookup[cur]);
          return acc;
        },
        { from: [], to: [] },
      );
    },
  );

// Builds a hashable key to connect a billing document to a manual adjustment
const getManualAdjustmentDocumentKey = (
  documentType,
  fromBusinessId,
  toBusinessId,
) => `${documentType}__${fromBusinessId}__${toBusinessId}`;

// Builds a lookup of manualAdjustmentIds to a manualAdjustmentDocumentKey, for any manual adjustments with the label
// INVOICE_ROUNDING_ADJUSTMENT
// or
// RCTI_ROUNDING_ADJUSTMENT

const selectManualAdjustmentIdsByRoundingAdjustmentKeyLookup = createSelector(
  [getManualAdjustments, selectLabelEntryIdsByLabelNameLookup],
  (manualAdjustments, labelEntryIdsByLabelNameLookup) => {
    const rctiLabelIds = new Set(
      labelEntryIdsByLabelNameLookup[SystemLabelName.RCTI_ROUNDING_ADJUSTMENT],
    );

    const invoiceLabelIds = new Set(
      labelEntryIdsByLabelNameLookup[
        SystemLabelName.INVOICE_ROUNDING_ADJUSTMENT
      ],
    );
    if (rctiLabelIds.size === 0 && invoiceLabelIds.size === 0) {
      return EMPTY_OBJECT;
    }

    // For each manual adjustment, work out a key of
    // expectedRecipientBusinessId, expectedIssuerBusinessId, documentType => [manualChargeIds],
    return Object.values(manualAdjustments).reduce(
      (result, manualAdjustment) => {
        const manualAdjustmentLabelSet = new Set(manualAdjustment.labels);
        // If the manual adjustment has a SystemLabelName.RCTI_ROUNDING_ADJUSTMENT,
        // we should be looking for billing documents that are
        // an RCTI
        // issued by the from business (the agency)
        // received by the to business (the vendor)
        if (manualAdjustmentLabelSet.intersection(rctiLabelIds).size > 0) {
          const key = getManualAdjustmentDocumentKey(
            BillingDocumentType.RCTI,
            manualAdjustment.fromBusinessId,
            manualAdjustment.toBusinessId,
          );
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(manualAdjustment.id);
        }

        // If the manual adjustment has a SystemLabelName.INVOICE_ROUNDING_ADJUSTMENT,
        // we should be looking for billing documents that are
        // an Invoice
        // issued by the from business (the buyer)
        // received by the to business (the agency)
        if (manualAdjustmentLabelSet.intersection(invoiceLabelIds).size > 0) {
          const key = getManualAdjustmentDocumentKey(
            BillingDocumentType.INVOICE,
            manualAdjustment.fromBusinessId,
            manualAdjustment.toBusinessId,
          );
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(manualAdjustment.id);
        }
        return result;
      },
      {},
    );
  },
);

export const selectRoundingAdjustmentManualChargeIdsByBillingDocumentIdLookup =
  createSelector(
    [
      getBillingDocuments,
      selectManualAdjustmentIdsByRoundingAdjustmentKeyLookup,
    ],
    (billingDocuments, manualAdjustmentIdsByRoundingAdjustmentKeyLookup) => {
      // For each billing document, see if we have a corresponding entry in the manual adjustment lookup
      return Object.values(billingDocuments).reduce(
        (result, billingDocument) => {
          const key = getManualAdjustmentDocumentKey(
            billingDocument.type,
            billingDocument.recipientBusinessId,
            billingDocument.issuerBusinessId,
          );
          result[billingDocument.id] =
            manualAdjustmentIdsByRoundingAdjustmentKeyLookup[key] || null;
          return result;
        },
        {},
      );
    },
  );

export const getRoundingAdjustmentManualChargeIdsByBillingDocumentId =
  billingDocumentId => state =>
    selectRoundingAdjustmentManualChargeIdsByBillingDocumentIdLookup(state)[
      billingDocumentId
    ] || null;
