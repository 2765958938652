import React, { useEffect } from "react";

import { Divider, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { patchSaleLot } from "actions";

import Button from "components/Button";
import { Label } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import { ReactSelect } from "components/SearchableSelector";

import { PenTypes } from "constants/auctionPens";
import { SaleLotModalSection } from "constants/navigation";

import { toTitleCase } from "lib";

import { openEditSaleLotModal } from "lib/navigation";
import { pluralize } from "lib/pluralize";
import { getBuyerHashFromSaleLot, getCombinedLotNumber } from "lib/saleLot";

import {
  getBuyerDisplayNameBySaleLotId,
  getCurrentDecisionSaleLotBySingleWeighId,
  getDefaultDeliveryPenIdByBuyer,
  getDeliveryPenAsOptionsByBuyerHash,
} from "selectors";

const handleAssignPen = saleLotId => () =>
  openEditSaleLotModal(saleLotId, SaleLotModalSection.AUCTION_PEN);

export const NoDeliveryPenAction = ({ singleWeighId }) => {
  const saleLot = useSelector(
    getCurrentDecisionSaleLotBySingleWeighId(singleWeighId),
  );

  const buyerHash = getBuyerHashFromSaleLot(saleLot);

  const deliveryPenOptions = useSelector(state =>
    getDeliveryPenAsOptionsByBuyerHash(buyerHash)(state),
  );

  const buyerAndBuyerWayName = useSelector(
    getBuyerDisplayNameBySaleLotId(saleLot.id),
  );
  const defaultDeliveryPenId = useSelector(
    getDefaultDeliveryPenIdByBuyer(buyerHash),
  );

  const [deliveryPen, setDeliveryPen] = React.useState(null);
  useEffect(() => {
    const defaultPen = deliveryPenOptions.find(
      o => o.value === defaultDeliveryPenId,
    );
    if (defaultPen) {
      setDeliveryPen(defaultPen);
    }
  }, [defaultDeliveryPenId, deliveryPenOptions]);

  const dispatch = useDispatch();

  const assignDeliveryPenToSaleLot = () => {
    dispatch(
      patchSaleLot(
        { deliveryPenId: deliveryPen.value, id: saleLot.id },
        { changeReason: "Set delivery pen from single weigh" },
      ),
    );
  };

  if (!deliveryPenOptions || deliveryPenOptions.length === 0) {
    return (
      <NoSellingPenAction
        singleWeighId={singleWeighId}
        penType={PenTypes.DELIVERY}
      />
    );
  }

  return (
    <Column>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <strong>{buyerAndBuyerWayName}</strong>
            {` has bought lots with Delivery ${pluralize(
              "Pen",
              deliveryPenOptions.length,
            )} ${deliveryPenOptions.map(({ label }) => label).join(", ")}`}
          </div>
        </Grid>

        <Grid item xs={4}>
          <Label>Delivery Pen</Label>
          <ReactSelect
            options={deliveryPenOptions}
            name="delivery_pen"
            value={deliveryPen}
            isClearable={false}
            onChange={setDeliveryPen}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={2} container alignItems="flex-end">
          <Button onClick={assignDeliveryPenToSaleLot}>
            Assign {deliveryPen?.label}
          </Button>
        </Grid>
        <Grid item xs={1} container alignItems="center" justifyContent="center">
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid
          item
          xs={5}
          container
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Button onClick={handleAssignPen(saleLot.id)}>
            Assign a different Delivery Pen
          </Button>
        </Grid>
      </Grid>
    </Column>
  );
};

export function NoSellingPenAction({
  singleWeighId,
  penType = PenTypes.SELLING,
}) {
  const saleLot = useSelector(
    getCurrentDecisionSaleLotBySingleWeighId(singleWeighId),
  );

  return (
    <Row justifyAround fullWidth alignCenter>
      <Column>
        <Label>
          No {toTitleCase(penType)} Pen is available on Sale Lot#:{" "}
          {getCombinedLotNumber(saleLot)}
        </Label>
      </Column>
      <Column>
        <Button
          data-tour={`assignA${toTitleCase(penType)}Pen`}
          onClick={handleAssignPen(saleLot.id)}
        >
          Assign a {toTitleCase(penType)} Pen
        </Button>
        {/* TODO? Allow assignment to different lot, a la AG-2928 for an unknown EID. */}
      </Column>
    </Row>
  );
}
