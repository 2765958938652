import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";

import {
  selectNoSaleHeadCount,
  selectNoSaleProgenyCount,
  selectSoldHeadCount,
  selectSoldProgenyCount,
  selectUnsoldHeadCount,
  selectUnsoldProgenyCount,
} from "selectors/counts";
import { formatDecimal } from "lib";
import { getProgenyDisplayCount } from "lib/saleLot";

export function SaleProgressHeadCounts(): React.JSX.Element {
  const soldHeadCount = useSelector(selectSoldHeadCount);
  const soldProgenyCount = useSelector(selectSoldProgenyCount);
  const noSaleHeadCount = useSelector(selectNoSaleHeadCount);
  const noSaleProgenyCount = useSelector(selectNoSaleProgenyCount);
  const unsoldHeadCount = useSelector(selectUnsoldHeadCount);
  const unsoldProgenyCount = useSelector(selectUnsoldProgenyCount);
  const clearanceCount = soldHeadCount + noSaleHeadCount;
  const clearanceRate =
    clearanceCount === 0 ? 0 : (soldHeadCount / clearanceCount) * 100;

  return (
    <>
      <Grid item xs={6}>
        <h3>Sold Hd</h3>
        <span>
          {`${soldHeadCount} ${getProgenyDisplayCount(soldProgenyCount)}`}
        </span>
      </Grid>
      <Grid item xs={6}>
        <h3>No Sale Hd</h3>
        <span>
          {`${noSaleHeadCount} ${getProgenyDisplayCount(noSaleProgenyCount)}`}
        </span>
      </Grid>

      <Grid item xs={6}>
        <h3>Unsold Hd</h3>
        <span>
          {`${unsoldHeadCount} ${getProgenyDisplayCount(unsoldProgenyCount)}`}
        </span>
      </Grid>

      <Grid item xs={6}>
        <h3>Clearance %</h3>
        <span>{`${formatDecimal(clearanceRate, 1)}%`}</span>
      </Grid>
    </>
  );
}
