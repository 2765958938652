import reduceReducers from "reduce-reducers";

import { SALEYARD_ADMIN } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
} from "lib/reducers";
import { deserializeSaleyardAdmin } from "lib/serializers/saleyardAdmin";

const create = apiModelOfflineCreateReducer(SALEYARD_ADMIN, {
  deserializer: deserializeSaleyardAdmin,
});

const fetch = apiModelOfflineFetchReducer(SALEYARD_ADMIN, {
  deserializer: deserializeSaleyardAdmin,
  clearOnRequest: true,
});

const update = apiModelOfflineUpdateReducer(SALEYARD_ADMIN, {
  deserializer: deserializeSaleyardAdmin,
});

const deleteReducer = apiModelOfflineDeleteReducer(SALEYARD_ADMIN);

function reinviteReducer(state, action) {
  switch (action.type) {
    case SALEYARD_ADMIN.REINVITE.SUCCESS: {
      const { payload } = action;
      // Only take most recent request success
      const data = deserializeSaleyardAdmin(payload);
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: data,
        },
      };
    }
    default:
      return state;
  }
}

const saleyardAdminReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
  reinviteReducer,
);

export default saleyardAdminReducer;
