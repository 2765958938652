import React from "react";

import { Collapse, Grid } from "@material-ui/core";
import { sortBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { connectToDevice, initializeSingleWeigh } from "actions";

import { Button } from "components/Form";
import { LabelledControl } from "components/Form/FormikControls";
import { CenteredGreedy } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Paper } from "components/Paper";
import { ReactSelect } from "components/SearchableSelector";

import { ApiModel } from "constants/loading";

import {
  getLivestockSaleId,
  getSaleyardName,
  openSingleWeighControls,
} from "lib/navigation";

import {
  getSingleWeighs,
  selectAvailablePlcOptions,
  selectAvailableScannerOptions,
  selectAvailableWeighbridgeOptions,
  selectConnectedPLC,
  selectConnectedScanner,
  selectConnectedWeighBridge,
} from "selectors";

const Page = styled.div`
  width: 320px;
`;

export const SingleWeighSelector = () => {
  const singleWeighs = useSelector(getSingleWeighs);

  const dispatch = useDispatch();

  const onSelectSingleWeigh = singleWeighId => {
    dispatch(
      initializeSingleWeigh(singleWeighId, {
        onSuccess: () =>
          openSingleWeighControls(
            getSaleyardName(),
            getLivestockSaleId(),
            singleWeighId,
          ),
      }),
    );
  };

  const [selectedSingleWeighId, setSelectedSingleWeighId] =
    React.useState(null);

  const plcOptions = useSelector(selectAvailablePlcOptions);
  const connectedPlcId = useSelector(
    state => selectConnectedPLC(state)?.deviceId,
  );
  const connectedPlcOption = plcOptions.find(o => o.value === connectedPlcId);

  const weighBridgeOptions = useSelector(selectAvailableWeighbridgeOptions);
  const connectedWeighBridgeId = useSelector(
    state => selectConnectedWeighBridge(state)?.deviceId,
  );
  const connectedWeighBridgeOption = weighBridgeOptions.find(
    o => o.value === connectedWeighBridgeId,
  );

  const scannerOptions = useSelector(selectAvailableScannerOptions);
  const connectedScannerId = useSelector(
    state => selectConnectedScanner(state)?.deviceId,
  );
  const connectedScannerOption = scannerOptions.find(
    o => o.value === connectedScannerId,
  );

  const handleConnect = deviceId => {
    dispatch(connectToDevice(deviceId));
  };

  const sortedSingleWeighs = React.useMemo(
    () => sortBy(Object.values(singleWeighs), "name"),
    [singleWeighs],
  );

  return (
    <CenteredGreedy>
      <Page>
        <WaitForSync
          requiredData={[ApiModel.SINGLE_WEIGH, ApiModel.PEN_ARCHETYPES]}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="center">
              <h1>Select Single Weigh</h1>
            </Grid>
            {sortedSingleWeighs.map(singleWeigh => (
              <Grid item xs={12} key={singleWeigh.id}>
                <Paper onClick={() => setSelectedSingleWeighId(singleWeigh.id)}>
                  <Grid
                    item
                    xs={12}
                    container
                    data-tour={singleWeigh.name}
                    justifyContent="center"
                  >
                    <h2>{singleWeigh.name}</h2>
                  </Grid>

                  <Collapse in={selectedSingleWeighId === singleWeigh.id}>
                    <Grid container spacing={2}>
                      <Grid
                        data-tour={`${singleWeigh.name}Scanner`}
                        item
                        xs={12}
                      >
                        <LabelledControl label="Scanner">
                          <ReactSelect
                            defaultValue={connectedScannerOption}
                            options={scannerOptions}
                            onChange={e => handleConnect(e.value)}
                          />
                        </LabelledControl>
                      </Grid>
                      <Grid data-tour={`${singleWeigh.name}PLC`} item xs={12}>
                        <LabelledControl label="PLC">
                          <ReactSelect
                            defaultValue={connectedPlcOption}
                            options={plcOptions}
                            onChange={e => handleConnect(e.value)}
                          />
                        </LabelledControl>
                      </Grid>
                      <Grid
                        data-tour={`${singleWeigh.name}WeighBridge`}
                        item
                        xs={12}
                      >
                        <LabelledControl label="Weigh Bridge">
                          <ReactSelect
                            defaultValue={connectedWeighBridgeOption}
                            options={weighBridgeOptions}
                            onChange={e => handleConnect(e.value)}
                          />
                        </LabelledControl>
                      </Grid>

                      <Grid
                        data-tour={`${singleWeigh.name}Connect&Open`}
                        item
                        xs={12}
                        container
                      >
                        <Button
                          type="button"
                          onClick={() => onSelectSingleWeigh(singleWeigh.id)}
                        >
                          Connect & Open
                        </Button>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </WaitForSync>
      </Page>
    </CenteredGreedy>
  );
};
