import { createSelector } from "reselect";

import { registrationNumberSort } from "lib/bidders";
import { getBuyerInformationString } from "lib/businesses";

import {
  createLookupCombiner,
  createLookupSelectors,
  getAuctionPens,
  getBidders,
  getBusinesses,
  getCurrentSale,
  getProperties,
  getSaleLots,
  selectBidderIdsByRegistrationNumberLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";

export const getBidderById = bidderId => state =>
  getBidders(state)[bidderId] || null;

export const selectBiddersList = createSelector([getBidders], bidders =>
  Object.values(bidders).sort(registrationNumberSort),
);

export const selectBidderOptions = createSelector(
  [selectBiddersList, getBusinesses, getProperties],
  (biddersList, businessByIdLookup, propertyByIdLookup) =>
    biddersList
      .map(bidder => {
        let label = `${bidder.registrationNumber}`;
        if (bidder.businessId) {
          const business = businessByIdLookup[bidder.businessId] || {};
          const businessName = business.name;
          label += ` - ${businessName}`;

          if (bidder.buyerWay) {
            label += ` (${bidder.buyerWay})`;
          }

          if (bidder.defaultPropertyId) {
            const property = propertyByIdLookup[bidder.defaultPropertyId] || {};
            const propertyName = property.PIC;
            label += ` - ${propertyName}`;
          }
        }
        return {
          label,
          value: bidder.id,
          registrationNumber: bidder.registrationNumber,
        };
      })
      .sort(
        (bidderA, bidderB) =>
          bidderA.registrationNumber - bidderB.registrationNumber,
      ),
);

export const selectBidderIdBySaleLotIdLookup = createSelector(
  [selectBiddersList, getSaleLots, getCurrentSale],
  (biddersList, saleLots, currentSale) => {
    if (!currentSale.using_registered_bidders) {
      return Object.keys(saleLots).reduce((acc, saleLotId) => {
        acc[saleLotId] = null;
        return acc;
      }, {});
    }

    const normalisedBidders = biddersList
      // Remove any Bidders which don't have a Business attached
      .filter(bidder => bidder.businessId)
      .map(bidder => ({
        businessId: bidder.businessId,
        // Coerce the BuyerWay name to lowercase once for every bidder
        buyerWayNameLower: bidder.buyerWay?.toLowerCase(),
        id: bidder.id,
      }));

    return Object.entries(saleLots).reduce((acc, [saleLotId, saleLot]) => {
      let bidderId = null;
      if (saleLot.buyer_id) {
        // Coerce the SaleLot's BuyerWay name to lowercase for case insensitive matching,
        // default to empty string to match `null` SaleLot BuyerWay name to a `""` Bidder BuyerWay name
        const buyerWayNameLower =
          (saleLot.buyer_way &&
            saleLot.buyer_way.name &&
            saleLot.buyer_way.name.toLowerCase()) ||
          "";

        const bidder =
          normalisedBidders.find(
            normalisedBidder =>
              saleLot.buyer_id === normalisedBidder.businessId &&
              // Compare the BuyerWay names case insensitive
              normalisedBidder.buyerWayNameLower === buyerWayNameLower,
          ) || null;
        bidderId = bidder?.id || null;
      }
      acc[saleLotId] = bidderId;
      return acc;
    }, {});
  },
);

export const [selectBidderByBuyerInformation, getBidderByBuyerInformation] =
  createLookupSelectors([getBidders], bidders =>
    Object.values(bidders).reduce((acc, bidder) => {
      const { businessId, defaultPropertyId, buyerWay } = bidder;
      const buyerInformationString = getBuyerInformationString(
        businessId,
        defaultPropertyId,
        buyerWay,
      );
      acc[buyerInformationString] = bidder;
      return acc;
    }, {}),
  );

const biddersByAuctionPenIdReducer = (
  auctionPen,
  bidderIdBySaleLotIdLookup,
  saleLotIdsByAuctionPenIdLookup,
  bidders,
) =>
  saleLotIdsByAuctionPenIdLookup[auctionPen.id]?.map(
    slId => bidders[bidderIdBySaleLotIdLookup[slId]]?.registrationNumber,
  );

export const [
  selectBidderNumbersByAuctionPenIdLookup,
  getBidderNumbersByAuctionPenId,
] = createLookupSelectors(
  [
    getAuctionPens,
    selectBidderIdBySaleLotIdLookup,
    selectSaleLotIdsByAuctionPenIdLookup,
    getBidders,
  ],
  createLookupCombiner(biddersByAuctionPenIdReducer),
);

export const getBidderIdsByRegistrationNumber = bidderNumber => state =>
  selectBidderIdsByRegistrationNumberLookup(state)[bidderNumber];
