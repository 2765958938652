import {
  put,
  takeEvery,
  call,
  select,
  take,
  takeLeading,
} from "redux-saga/effects";

import {
  deleteAttachmentFailure,
  deleteAttachmentSuccess,
  requestAttachmentsSuccess,
  requestAttachmentsFailure,
  updateAttachmentFailure,
  updateAttachmentSuccess,
  requestAttachments,
  requestAttachmentsChangesFailure,
  requestAttachmentsChangesSuccess,
} from "actions";

import { ATTACHMENT } from "constants/actionTypes";

import { getSaleUrl } from "lib/navigation";
import toast from "lib/toast";

import { currentSaleSelector } from "selectors";

import { refreshJWTAndRetry, api } from "sagas/api";

import { createUploadFileChannel } from "./createUploadFileChannel";

function* fetchAttachments(action) {
  try {
    const { sale } = action;
    if (sale?.livestocksale_id && sale?.saleyard_name) {
      const saleUrl = getSaleUrl(sale);
      const attachmentsPromise = yield call(api.get, `${saleUrl}/attachments/`);
      yield put(
        requestAttachmentsSuccess(
          sale.livestocksale_id,
          yield attachmentsPromise,
        ),
      );
    }
  } catch (e) {
    yield call(api.handleFetchError, e, "attachments", action);
    yield put(requestAttachmentsFailure(e.statusText));
  }
}
function* fetchAttachmentsChanges(action) {
  try {
    const { sale } = action;
    if (sale?.livestocksale_id && sale?.saleyard_name) {
      const saleUrl = getSaleUrl(sale);
      const attachmentsPromise = yield call(api.get, `${saleUrl}/attachments/`);
      yield put(requestAttachmentsChangesSuccess(yield attachmentsPromise));
    }
  } catch (e) {
    yield call(api.handleFetchError, e, "attachments", action);
    yield put(requestAttachmentsChangesFailure(e.statusText));
  }
}

function* updateAttachment(action) {
  try {
    const { attachment } = action;
    const { auth } = yield select();
    const url = `/v2/attachments/${attachment.id}/`;
    const channel = yield call(
      createUploadFileChannel,
      url,
      attachment.file,
      attachment,
      auth,
      "PATCH",
    );

    while (true) {
      const { err, status_code, success, response } = yield take(channel);
      if (err) {
        if (status_code && status_code === 401) {
          // We failed!
          // Ask for a new JWT token, and wait until it was successful before
          // trying this action again.
          yield call(refreshJWTAndRetry, action);
        } else {
          toast.error(`Error updating attachment.`);
          yield put(updateAttachmentFailure(attachment.id, err));
        }
        return;
      }
      if (success) {
        toast.success(`Attachment updated.`);
        yield put(updateAttachmentSuccess(response));
        return;
      }
      // TBD One day.
      // yield put(updateAttachmentProgress(id, progress));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    toast.error("Error updating attachment!");
    yield put(updateAttachmentFailure(e.statusText));
  }
}

function* deleteAttachment({ attachment }) {
  try {
    const url = `/v2/attachments/${attachment.id}/`;
    yield api.destroy(url);
    yield put(deleteAttachmentSuccess(attachment));
    toast.success("Attachment successfully deleted.");
  } catch (e) {
    yield put(deleteAttachmentFailure(e.statusText));
    toast.error("Error deleting attachment!");
  }
}

function* updateAttachmentsFailure() {
  toast.error("Failed to update attachments.");

  const state = yield select();
  const currentSale = currentSaleSelector(state);
  yield put(requestAttachments(currentSale));
}

export default function* rootSaga() {
  yield takeEvery(ATTACHMENT.FETCH_BULK.REQUEST, fetchAttachments);
  yield takeLeading(ATTACHMENT.FETCH_CHANGES.REQUEST, fetchAttachmentsChanges);
  yield takeEvery(ATTACHMENT.UPDATE.REQUEST, updateAttachment);
  yield takeEvery(ATTACHMENT.DELETE.REQUEST, deleteAttachment);
  yield takeEvery(ATTACHMENT.UPDATE_BULK.FAILURE, updateAttachmentsFailure);
}
