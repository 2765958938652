import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import {
  ContentOptions,
  createEmbeddingContext,
  DashboardExperience,
  EmbeddingContext,
  FrameOptions,
} from "amazon-quicksight-embedding-sdk";

type PropTypes = {
  url: string;
  hidden: boolean;
};

type ContainerPropTypes = {
  url: string;
  hidden: boolean;
};
const Container = styled.div<ContainerPropTypes>`
  height: ${props => (props.hidden ? 0 : "100%")};
`;

// see https://github.com/awslabs/amazon-quicksight-embedding-sdk
export const EmbeddedAnalytics = ({ url, hidden }: PropTypes) => {
  const dashboardRef = useRef<HTMLDivElement>();
  const [embeddedDashboard, setEmbeddedDashboard] =
    useState<DashboardExperience>(null);
  const [embeddingContext, setEmbeddingContext] =
    useState<EmbeddingContext>(null);

  useEffect(() => {
    const createContext = async () => {
      const context = await createEmbeddingContext();
      setEmbeddingContext(context);
    };
    if (url && !embeddingContext) {
      createContext();
    }
  }, [url, embeddingContext]);

  useEffect(() => {
    const embed = async () => {
      const frameOptions: FrameOptions = {
        url,
        container: dashboardRef.current,
        height: "100%",
      };
      const contentOptions: ContentOptions = {
        toolbarOptions: {
          bookmarks: true,
          export: true,
          reset: true,
          undoRedo: true,
        },
      };

      const newEmbeddedDashboard = await embeddingContext.embedDashboard(
        frameOptions,
        contentOptions,
      );
      setEmbeddedDashboard(newEmbeddedDashboard);
    };
    if (embeddingContext && !embeddedDashboard) {
      embed();
    }
  }, [embeddingContext, url, hidden, embeddedDashboard]);

  return <Container hidden={hidden} ref={dashboardRef} />;
};
