import { CommentRenderer } from "lib/agGrid/renderers/commentRenderer";

export const CommentColId = {
  COMMENTS: "comments",
};

export const CommentColumnDef = {
  [CommentColId.COMMENTS]: {
    colId: CommentColId.COMMENTS,
    headerName: "Comments",
    cellRenderer: CommentRenderer,
    width: 100,
    suppressCellFlash: true,
  },
};
