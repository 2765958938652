import { intersection } from "lodash";

export const auctionPensFilteredComparator =
  filterAuctionPenIds =>
  ([_ignored, auctionPenIds]) =>
    intersection(auctionPenIds, filterAuctionPenIds).length > 0;

export const deliveryPensFilteredComparator =
  filterDeliveryPenIds =>
  ([_ignored, deliveryPenIds]) =>
    intersection(deliveryPenIds, filterDeliveryPenIds).length > 0;

export const auctionPensLanesComparator =
  lanes =>
  ([_ignored, auctionPenIds]) =>
    lanes.some(lane => intersection(auctionPenIds, lane).length > 0);
