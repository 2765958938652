import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import StatusCard from "components/Card/StatusCard";
import { Button } from "components/Form";
import { Label, Text } from "components/LotCard/Elements";
import { tryAndOpenNominationsModal } from "components/Nominations/Table";
import { ReadOnly } from "components/ReadOnly";

import { DeploymentPermissions } from "constants/permissions";
import { getIconForSpeciesId } from "constants/species";

import { ForClearingSale, ForNotClearingSale } from "containers/ForSaleType";

import { hasPermission } from "lib/permissions";
import {
  dateTimeStringToDateTime,
  formatHeaderDateString,
} from "lib/timeFormats";

import {
  getActiveRoleDeployments,
  getAgencyByDeploymentId,
  getConsignableDeploymentSaleById,
  getConsignableSaleById,
  getDeploymentById,
  getSpeciesById,
} from "selectors";

const DeploymentSaleInline = ({
  deploymentSaleId,
  livestockSaleId,
  setDeploymentSaleId,
  saleType,
}) => {
  const deploymentSale = useSelector(
    getConsignableDeploymentSaleById(deploymentSaleId),
  );
  const agency = useSelector(
    getAgencyByDeploymentId(deploymentSale.deployment_id),
  );

  const canNominateForDeployment = useSelector(state =>
    hasPermission(
      getDeploymentById(getActiveRoleDeployments(state)[0].id)(state),
      DeploymentPermissions.canNominateFor,
    ),
  );

  const onClickConsign = () => {
    setDeploymentSaleId(deploymentSaleId);
  };

  const onClickNominate = () => {
    tryAndOpenNominationsModal(deploymentSale.is_nominations_locked, {
      presets: {
        livestockSaleId,
        deploymentId: deploymentSale.deployment_id,
      },
    });
  };

  return (
    <>
      <ForClearingSale selectedSaleType={saleType}>
        <Grid item xs={8}>
          {agency?.name}
        </Grid>
        <Grid item container xs={4}>
          <Button onClick={onClickConsign}>Consign</Button>
        </Grid>
      </ForClearingSale>
      <ForNotClearingSale selectedSaleType={saleType}>
        <Grid item xs={4}>
          {agency?.name}
        </Grid>
        <Grid item container xs={4}>
          <Button onClick={onClickConsign}>Consign</Button>
        </Grid>
        <Grid item container xs={4}>
          <Button
            onClick={onClickNominate}
            disabled={!canNominateForDeployment}
          >
            <ReadOnly readOnly={!canNominateForDeployment} showLock /> Nominate
          </Button>
        </Grid>
      </ForNotClearingSale>
    </>
  );
};
export const LivestockSaleCard = ({
  livestockSaleId,
  setDeploymentSaleId,
  speciesFilter,
}) => {
  const sale = useSelector(getConsignableSaleById(livestockSaleId));
  const species = useSelector(getSpeciesById(sale.speciesId));
  const date = dateTimeStringToDateTime(sale.date);
  const namedDate = formatHeaderDateString(date);

  if (speciesFilter && species.id !== speciesFilter) {
    return null;
  } else {
    return (
      <StatusCard>
        <Grid container>
          <Grid item xs={6} sm={3}>
            <Label>Species</Label>
            <Text>
              <FontAwesomeIcon icon={getIconForSpeciesId(species.id)} />{" "}
              {species.name}
            </Text>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Label>Date</Label>
            <Text>{namedDate}</Text>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Label>Location</Label>
            <Text>{sale.saleyardName}</Text>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Label>Type</Label>
            <Text>{sale.saleType}</Text>
          </Grid>
          {sale.saleTitle && (
            <Grid xs={12} item>
              {sale.saleTitle}
            </Grid>
          )}
          <Grid item xs={12}>
            <Label>Agencies</Label>
            <Grid container spacing={1} alignItems="center">
              {sale.deploymentSales.map(deploymentSale => (
                <DeploymentSaleInline
                  key={deploymentSale.deployment_sale_id}
                  deploymentSaleId={deploymentSale.deployment_sale_id}
                  livestockSaleId={livestockSaleId}
                  saleType={sale.saleType}
                  setDeploymentSaleId={setDeploymentSaleId}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </StatusCard>
    );
  }
};
