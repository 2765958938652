import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

export const baseInterestSettingsSerializeMap = {
  id: "id",
  daysOverdueThreshold: "days_overdue_threshold",
  interestRate: "interest_rate",
};

const interestSettingsSerializeMap = {
  ...baseInterestSettingsSerializeMap,
  businessId: "business_id",
  deploymentId: "deployment_id",
};

export const serializeInterestSettings = buildSerializer(
  interestSettingsSerializeMap,
  "interest",
);

export const deserializeInterestSettings = buildDeserializer(
  interestSettingsSerializeMap,
  "interest",
);
