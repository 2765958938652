import React, { Component } from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import OutlineButton from "components/Button/OutlineButton";
import { Row, Column } from "components/Layout";
import { ModalBg } from "components/Modal";

const MainWrapper = styled(Column)`
  margin: 25% 10%;
`;
const InputWrapper = styled(Row)`
  color: #666;
  font-size: 48px;
  line-height: 84px;
  padding-bottom: 17px;
  border-bottom: 1px solid #979797;
`;

const TextInput = styled.input`
  border: none;
  width: 100%;
  color: #666;
  font-size: 36px;
  padding: 0 24px;
  line-height: 48px;
  font-weight: 100;
  &:focus {
    outline: none;
  }
`;

const LabelInput = styled.span`
  color: #666666;
  font-size: 24px;
  font-weight: bold;
  line-height: 48px;
  white-space: nowrap;
`;

const RemainingCharsCount = styled.span`
  width: 21px;
  color: #58c1ac;
  font-size: 18px;
  line-height: 48px;
`;

const ButtonWrapper = styled(Row)`
  margin-top: 23px;
`;

const CHARACTER_LIMIT = 30;

class AddNoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteText: props.initialValue || "",
    };
  }

  handleChange = event => {
    const noteText = event.target.value.substr(0, CHARACTER_LIMIT);
    this.setState({ noteText });
  };

  render() {
    const { closeSelf, setNote } = this.props;
    const { noteText } = this.state;

    return (
      <ModalBg
        contentStyle={{
          maxWidth: "800px",
          minWidth: "500px",
          padding: "24px",
        }}
      >
        <Row justifyEnd>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={closeSelf}
            style={{ fontSize: 36, color: "#c2c2c2" }}
          />
        </Row>

        <MainWrapper>
          <InputWrapper>
            <LabelInput>Sale note:</LabelInput>
            <TextInput value={noteText} onChange={this.handleChange} />
            <RemainingCharsCount>
              {CHARACTER_LIMIT - noteText.length}
            </RemainingCharsCount>
          </InputWrapper>
          <ButtonWrapper full>
            <OutlineButton
              color="primary"
              style={{ width: "100%" }}
              onClick={() => setNote(noteText)}
            >
              Add note
            </OutlineButton>
          </ButtonWrapper>
        </MainWrapper>
      </ModalBg>
    );
  }
}

AddNoteModal.propTypes = {
  closeSelf: PropTypes.func.isRequired,
  setNote: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
};

export default AddNoteModal;
