import React from "react";

import {
  faComments,
  faContainerStorage,
  faDollarSign,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { Arrived, Delivered } from "components/Icons";

export const ConsignedIcon = React.memo(() => (
  <FontAwesomeIcon size="1x" icon={faComments} />
));

export const ArrivedIcon = React.memo(({ color }) => <Arrived color={color} />);

export const PennedIcon = React.memo(() => (
  <FontAwesomeIcon size="1x" icon={faContainerStorage} />
));

export const SoldIcon = React.memo(() => (
  <FontAwesomeIcon size="1x" icon={faDollarSign} />
));

export const DeliveredIcon = React.memo(({ color }) => (
  <Delivered color={color} />
));

export const IconWrapper = styled.div`
  z-index: 1;
  border: solid;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  ${({ theme, color, borderColor, rightBorderColor }) => `
  
  color: ${theme.colors[color]};
  
  border: solid ${theme.colors[borderColor]};
  
  ${
    rightBorderColor
      ? `border-right-color: ${theme.colors[rightBorderColor]};`
      : ""
  }
    `}
`;
