import { SALE_LOT } from "constants/actionTypes";

export const BulkUpdateModels = {
  SALE_LOT: "salelot",
  SALE_LOT_SERIALIZER: "salelot-serializer",
  AUCTION_PEN: "auctionpen",
  BILLING_DOCUMENT: "billingdocument",
};

export const BulkUpdateModelActions = {
  [BulkUpdateModels.SALE_LOT]: SALE_LOT.UPDATE_BULK,
  [BulkUpdateModels.SALE_LOT_SERIALIZER]: SALE_LOT.UPDATE_BULK,
};
