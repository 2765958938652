import React, { useCallback, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import {
  AlternateFieldTextComponent,
  getAlternateColor,
} from "components/BusinessForm/lib";
import Button, { SlimButton } from "components/Button";
import {
  GoogleMapsPlaceIdAutoComplete,
  Input,
  Label,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";
import { Column } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import { useResponsiveText } from "components/ResponsiveText";

import { convertPlaceDetailsToAddress, setAddressIn } from "lib/address";
import {
  PlacesServiceStatus,
  usePlaceAutocompleteSessionToken,
  usePlaceDetailsService,
} from "lib/googleMaps";
import toast from "lib/toast";

import { getBusinessById, getIsConsigningAgent } from "selectors";

const StateOptions = [
  { label: "ACT", value: "ACT" },
  { label: "NSW", value: "NSW" },
  { label: "NT", value: "NT" },
  { label: "QLD", value: "QLD" },
  { label: "SA", value: "SA" },
  { label: "TAS", value: "TAS" },
  { label: "VIC", value: "VIC" },
  { label: "WA", value: "WA" },
];

export const addressValidationSchema = Yup.object().shape({
  addressingInformation: Yup.string().max(
    120,
    "Contact/Place Name can not be longer than 120 characters",
  ),
  streetNumber: Yup.string()
    .max(20, "Street Number can not be longer than 20 characters")
    .nullable(),
  route: Yup.string()
    .max(100, "Route can not be longer than 100 characters")
    .nullable(),
  locality: Yup.string()
    .max(165, "Locality can not be longer than 165 characters")
    .nullable(),
  state: Yup.string()
    .max(8, "State can not be longer than 8 characters")
    .nullable(),
  postalCode: Yup.string()
    .max(10, "Postal Code can not be longer than 10 characters")
    .nullable(),
});

/**
 * @typedef {Object} AddressFormProps
 * @property {string} [namespace=""]
 * @property {function(newAddress: Address): void} onAddressChanged
 * @property {boolean} [showAutoComplete=true]
 */

/**
 * @param {AddressFormProps} props
 * @returns {React.ReactNode}
 * @constructor
 */
export function AddressForm(props) {
  const {
    disabled,
    label,
    namespace: ns,
    onAddressChanged,
    showAutoComplete = true,
    showLabel = true,
    tooltip,
    defaultAddressSearch,
    businessId,
    isClearable = true,
  } = props;
  const sessionToken = usePlaceAutocompleteSessionToken();
  const placeDetailsService = usePlaceDetailsService();
  const business = useSelector(getBusinessById(businessId));

  const isConsigning = useSelector(getIsConsigningAgent);
  const showButton = isConsigning && business && business?.address;
  const [isLoading, setIsLoading] = useState(false);

  const { values, setFieldValue, setValues, setFieldTouched } =
    useFormikContext();

  const onClick = useCallback(() => {
    setValues(setAddressIn(ns, values, business.address));
    setFieldTouched(ns, true, false);
    typeof onAddressChanged === "function" &&
      onAddressChanged(business.address);
  }, [ns, onAddressChanged, setFieldTouched, setValues, values, business]);

  function onClickClear() {
    setFieldValue(ns, null);
  }

  const onPlaceSelected = useCallback(
    /**
     * @param {string} placeId
     */
    placeId => {
      setIsLoading(true);
      placeDetailsService.getDetails(
        {
          sessionToken,
          placeId,
          fields: ["address_component", "geometry", "name"],
        },
        (result, status) => {
          if (status === PlacesServiceStatus.OK) {
            const nextAddress = convertPlaceDetailsToAddress(placeId, result);

            setValues(setAddressIn(ns, values, nextAddress));
            setFieldTouched(ns, true, false);
            setIsLoading(false);
            typeof onAddressChanged === "function" &&
              onAddressChanged(nextAddress);
          } else {
            setIsLoading(false);
            toast.warning("Could not load the requested address.");
          }
        },
      );
    },
    [
      ns,
      onAddressChanged,
      placeDetailsService,
      sessionToken,
      setFieldTouched,
      setIsLoading,
      setValues,
      values,
    ],
  );

  const setNotPrefillData = useCallback(() => {
    setFieldValue(withNamespace(ns, "isPrefillAddress"), false);

    // Set the object containing the address as _touched_
    setFieldTouched(ns, true, false);
  }, [setFieldTouched, setFieldValue, ns]);

  const alternatives = business?.alternatives || [];
  return (
    <>
      {showButton && (
        <Button type="button" onClick={onClick}>
          Copy Address from {business?.name}
        </Button>
      )}
      {showLabel && label && (
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Column>
            <Label tooltip={tooltip}>{label}</Label>
          </Column>
          {isClearable ? (
            <Column>
              <SlimButton
                disabled={disabled}
                type="button"
                onClick={onClickClear}
              >
                Clear Address
              </SlimButton>
            </Column>
          ) : null}
        </Grid>
      )}
      {showAutoComplete && (
        <Grid item xs={12}>
          <GoogleMapsPlaceIdAutoComplete
            disabled={disabled}
            label={`${showLabel ? "" : `${label} `}Search`}
            tooltip="Search for a place or business name to automatically fill the address"
            defaultAddressSearch={defaultAddressSearch}
            onChangeExtra={onPlaceSelected}
          />
        </Grid>
      )}
      {isLoading && (
        <LoadingSpinner actionName="Retrieving" subjectName="address" />
      )}
      <>
        <Grid item xs={12}>
          <Input
            disabled={disabled}
            type="text"
            name={withNamespace(ns, "addressingInformation")}
            label="Contact/Place Name"
            tooltip="The name of the contact person, or the building or complex name where this address is located."
            altTooltip={
              businessId
                ? AlternateFieldTextComponent(
                    alternatives,
                    "address",
                    "addressingInformation",
                  )
                : null
            }
            altColor={getAlternateColor(
              alternatives,
              values,
              "address",
              "addressingInformation",
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            disabled={disabled}
            type="text"
            name={withNamespace(ns, "streetNumber")}
            label={useResponsiveText({
              mobile: "Street No.",
              tablet: "Street No.",
              desktop: "Street Number",
            })}
            onChangeExtra={setNotPrefillData}
            altTooltip={
              businessId
                ? AlternateFieldTextComponent(
                    alternatives,
                    "address",
                    "streetNumber",
                  )
                : null
            }
            altColor={getAlternateColor(
              alternatives,
              values,
              "address",
              "streetNumber",
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <Input
            disabled={disabled}
            type="text"
            name={withNamespace(ns, "route")}
            label="Street Name"
            onChangeExtra={setNotPrefillData}
            altTooltip={
              businessId
                ? AlternateFieldTextComponent(alternatives, "address", "route")
                : null
            }
            altColor={getAlternateColor(
              alternatives,
              values,
              "address",
              "route",
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            disabled={disabled}
            type="text"
            name={withNamespace(ns, "locality")}
            label="City"
            onChangeExtra={setNotPrefillData}
            altTooltip={
              businessId
                ? AlternateFieldTextComponent(
                    alternatives,
                    "address",
                    "locality",
                  )
                : null
            }
            altColor={getAlternateColor(
              alternatives,
              values,
              "address",
              "locality",
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            disabled={disabled}
            type="text"
            name={withNamespace(ns, "state")}
            value={
              StateOptions
                ? StateOptions.find(option => option.value === values.state)
                : ""
            }
            options={StateOptions}
            label="State"
            onChangeExtra={setNotPrefillData}
            menuPortalTarget={document.body}
            altTooltip={
              businessId
                ? AlternateFieldTextComponent(alternatives, "address", "state")
                : null
            }
            altColor={getAlternateColor(
              alternatives,
              values,
              "address",
              "state",
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            disabled={disabled}
            type="text"
            name={withNamespace(ns, "postalCode")}
            label="Postcode"
            onChangeExtra={setNotPrefillData}
            altTooltip={
              businessId
                ? AlternateFieldTextComponent(
                    alternatives,
                    "address",
                    "postalCode",
                  )
                : null
            }
            altColor={getAlternateColor(
              alternatives,
              values,
              "address",
              "postalCode",
            )}
          />
        </Grid>
      </>
    </>
  );
}
