import React from "react";

import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

export const LockIcon = styled(({ status, ...other }) => (
  <FontAwesomeIcon icon={status ? faLock : faLockOpen} {...other} />
))(
  ({ theme }) => `
  color: ${theme.colors.primary};
  font-size: 24px;
  width: auto;
`,
);
