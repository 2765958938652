import React from "react";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { ReportJobAction } from "actions/reportJob";

import {
  EmailForm,
  validationSchema,
} from "components/EmailReportModal/EmailForm";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { ZoomyDialog } from "components/MaterialDialog";

import { ApiModel } from "constants/loading";

import { getReportJobs } from "selectors";

const initialValues = {
  rawEmailAddress: "",
  businessUserIds: [],
  emailRecipients: [],
};

const EmailReportJobModal = ({ reportJobId, onClose }) => {
  const dispatch = useDispatch();

  const reportJob = useSelector(state => getReportJobs(state)[reportJobId]);
  const businessIds = reportJob.businessIds || [];
  const businessId = businessIds.length ? businessIds[0] : null;

  const handleSubmit = ({
    businessUserIds,
    emailRecipients,
    emailTemplateText,
    rawEmailAddress,
  }) => {
    dispatch(
      ReportJobAction.email([
        {
          report_job_ids: [reportJob.id],
          email_recipients: emailRecipients,
          raw_email_address: rawEmailAddress,
          business_user_ids: businessUserIds,
          email_template_text: emailTemplateText,
        },
      ]),
    );

    onClose();
  };

  return (
    <WaitForSync
      requiredData={[
        ApiModel.DEPLOYMENTS,
        ApiModel.BUSINESSES,
        ApiModel.REPORT_JOBS,
      ]}
    >
      <ZoomyDialog open onClose={onClose} scroll="paper" fullWidth>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <EmailForm
            onClose={onClose}
            description={reportJob.title}
            businessId={businessId}
            deploymentId={reportJob.deploymentId}
            includeTemplateText
          />
        </Formik>
      </ZoomyDialog>
    </WaitForSync>
  );
};

export default EmailReportJobModal;
