import { intersection, sortBy } from "lodash";
import { createSelector } from "reselect";

import { PenTypes, ScanningPenStatus } from "constants/auctionPens";
import { Settings } from "constants/settings";

import { EMPTY_ARRAY } from "lib";

import {
  buildScanningPenGroups,
  sortPenScanLotsByGroupingName,
} from "lib/auctionPens";

import {
  getAuctionPens,
  getPenArchetypeById,
  getPenArchetypes,
  getPenScanLots,
  getSetting,
  selectFilteredAuctionPenIds,
} from "selectors";

import {
  getAuctionPenById,
  selectOrderedPenIdsByPenType,
  selectPenStatusByIdReducer,
} from "./auctionPens";
import { selectFilteredPenArchetypeIds } from "./globalSearch/penArchetypes";
import {
  selectPenIdsByPenArchetypeIdLookup,
  selectPenIdsByPenTypeLookup,
  selectPenScanLotIdsBySellingPenIdLookup,
} from "./indexes";
import { createLookupCombiner, createLookupSelectors } from "./lib";
import { selectPenScanLotStatusByIdLookup } from "./penScanLots";

const selectNextSellingPenIdsBySellingPenIdOrSellingPenArchetypeIdLookup =
  createSelector([selectOrderedPenIdsByPenType], orderedPenIdsByType =>
    orderedPenIdsByType[PenTypes.SELLING].reduce((acc, penIds, idx) => {
      if (idx < orderedPenIdsByType[PenTypes.SELLING].length - 1) {
        if (penIds.penId) {
          acc[penIds.penId] = orderedPenIdsByType[PenTypes.SELLING][idx + 1];
        }

        if (penIds.penArchetypeId) {
          acc[penIds.penArchetypeId] =
            orderedPenIdsByType[PenTypes.SELLING][idx + 1];
        }
      }
      return acc;
    }, {}),
  );

export const getNextSellingPenIdBySellingPenId = penId => state =>
  selectNextSellingPenIdsBySellingPenIdOrSellingPenArchetypeIdLookup(state)[
    penId
  ] || null;

const selectPreviousSellingPenIdsBySellingPenIdOrSellingPenArchetypeIdLookup =
  createSelector([selectOrderedPenIdsByPenType], orderedPenIdsByType =>
    orderedPenIdsByType[PenTypes.SELLING].reduce((acc, penIds, idx) => {
      if (idx > 0) {
        if (penIds.penId) {
          acc[penIds.penId] = orderedPenIdsByType[PenTypes.SELLING][idx - 1];
        }

        if (penIds.penArchetypeId) {
          acc[penIds.penArchetypeId] =
            orderedPenIdsByType[PenTypes.SELLING][idx - 1];
        }
      }
      return acc;
    }, {}),
  );

export const getPreviousSellingPenIdBySellingPenId = penId => state =>
  selectPreviousSellingPenIdsBySellingPenIdOrSellingPenArchetypeIdLookup(state)[
    penId
  ] || null;

export const getNextSellingPenByPenIdAndArchetypeId =
  (penId, penArchetypeId) => state => {
    const nextByArchetypeId =
      getNextSellingPenIdBySellingPenId(penArchetypeId)(state) || null;
    const nextByPenId = getNextSellingPenIdBySellingPenId(penId)(state) || null;
    return nextByPenId || nextByArchetypeId || null;
  };

export const getNextSellingPenTitleByPenIdAndPenArchetypeId =
  (penId, penArchetypeId) => state => {
    const nextPenDetails = getNextSellingPenByPenIdAndArchetypeId(
      penId,
      penArchetypeId,
    )(state);

    return (
      getAuctionPenById(nextPenDetails?.penId)(state)?.start_pen ||
      getPenArchetypeById(nextPenDetails?.penArchetypeId)(state)?.penName ||
      null
    );
  };

export const getPrevSellingPenByPenIdAndArchetypeId =
  (penId, penArchetypeId) => state => {
    const prevByArchetypeId =
      getPreviousSellingPenIdBySellingPenId(penArchetypeId)(state) || null;
    const prevByPenId =
      getPreviousSellingPenIdBySellingPenId(penId)(state) || null;
    return prevByPenId || prevByArchetypeId || null;
  };

export const getPrevSellingPenTitleByPenIdAndPenArchetypeId =
  (penId, penArchetypeId) => state => {
    const prevPenDetails = getPrevSellingPenByPenIdAndArchetypeId(
      penId,
      penArchetypeId,
    )(state);

    return (
      getAuctionPenById(prevPenDetails?.penId)(state)?.start_pen ||
      getPenArchetypeById(prevPenDetails?.penArchetypeId)(state)?.penName ||
      null
    );
  };

export const [selectStatusBySellingPenIdLookup, getStatusBySellingPenId] =
  createLookupSelectors(
    [
      getAuctionPens,
      selectPenScanLotIdsBySellingPenIdLookup,
      selectPenScanLotStatusByIdLookup,
    ],
    createLookupCombiner(selectPenStatusByIdReducer),
    ScanningPenStatus.NONE_SCANNED,
  );

export const selectSellingFreeFormPenIds = createSelector(
  [
    selectPenIdsByPenArchetypeIdLookup,
    selectPenIdsByPenTypeLookup,
    selectFilteredAuctionPenIds,
  ],
  (penIdsByPenArchetypeIdLookup, penIdsByPenType, filteredAuctionPenIds) =>
    intersection(
      penIdsByPenArchetypeIdLookup.null,
      penIdsByPenType[PenTypes.SELLING],
      filteredAuctionPenIds,
    ),
);

export const selectFreeFormSellingPens = createSelector(
  [selectSellingFreeFormPenIds, getAuctionPens],
  (freeFormPenIds, auctionPens) =>
    freeFormPenIds.map(penId => auctionPens[penId]),
);

export const selectFilteredSellingPenGroups = createSelector(
  [
    selectFilteredPenArchetypeIds,
    getPenArchetypes,
    selectPenIdsByPenArchetypeIdLookup,
    selectStatusBySellingPenIdLookup,
    selectFreeFormSellingPens,
    selectPenScanLotIdsBySellingPenIdLookup,
    getPenScanLots,
    getSetting(Settings.penScanningGroupType),
  ],
  (
    filteredPenArchetypeIds,
    penArchetypes,
    penIdsByPenArchetypeIdLookup,
    statusBySellingPenIdLookup,
    freeFormSellingPens,
    penScanLotIdsBySellingPenIdLookup,
    penScanLotByIdLookup,
    groupedBy,
  ) => {
    const sellingPenArchetypes = sortBy(
      filteredPenArchetypeIds
        .map(penArchetypeId => penArchetypes[penArchetypeId])
        .filter(penArchetype => penArchetype.penType === PenTypes.SELLING),
      "order",
    );

    const buildScanPenGroups = buildScanningPenGroups(
      sellingPenArchetypes,
      penIdsByPenArchetypeIdLookup,
      statusBySellingPenIdLookup,
      freeFormSellingPens,
      penScanLotIdsBySellingPenIdLookup,
      penScanLotByIdLookup,
      groupedBy,
    );

    return sortPenScanLotsByGroupingName(groupedBy, buildScanPenGroups);
  },
);

export const selectUsedSellingPenGroups = createSelector(
  [selectFilteredSellingPenGroups],
  penGroups => penGroups.filter(penGroup => penGroup.quantity > 0),
);

export const getSellingPenGroupByIndex = index => state => {
  return selectFilteredSellingPenGroups(state)[index] || EMPTY_ARRAY;
};

export const getUsedSellingPenGroupByIndex = index => state =>
  selectUsedSellingPenGroups(state)[index] || EMPTY_ARRAY;
