import { createSelector } from "reselect";

import { EMPTY_OBJECT } from "lib";

import { getSaleyardName } from "lib/navigation";

import { createIdByKeySelector } from "selectors";

import { getSaleyards } from "selectors/root";

// FIXME - this will only re-fire on change of STATE (ANY changes of state, but, still) as compared to any change of
// saleyard.
export const selectCurrentSaleyard = createSelector(
  [getSaleyards, () => getSaleyardName()],
  (saleyards, saleyardName) => {
    return (
      Object.values(saleyards).find(
        saleyard => saleyard.name === saleyardName,
      ) || EMPTY_OBJECT
    );
  },
);

export const selectSaleyardNameBySaleyardIdLookup = createIdByKeySelector(
  getSaleyards,
  "id",
  "name",
);
