import { TriStateToggleFilterComponent } from "components/AgGrid/filterComponents/triStateToggleFilterComponent";
import { CompositeFilter } from "components/AgGrid/filters/compositeFilter";
import { hasExceptionsTriStateFilter } from "components/AgGrid/filters/hasExceptionsFilter";
import { overflowTriStateFilter } from "components/AgGrid/filters/overflowFilter";
import { saleLotCommentsColumnId } from "components/SaleLotsTable/columns/commentsColumn";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import { LivestockSaleColumnDefinitions } from "constants/columnDefinitions";
import {
  PropertyColumnDefinitions,
  PropertyNamespaceColumnId,
} from "constants/columnDefinitions/property";
import { Column, LivestockSaleColId } from "constants/columns";
import { SaleTypes } from "constants/sale";

import { createNamespacedColumnDefinitions, withNestedField } from "lib/agGrid";
import {
  BaseColumnDefinitions,
  speciesAccreditationChildColumns,
} from "lib/agGrid/columnDefinitions";
import { enableRowSelection } from "lib/agGrid/rowSelection";

import { actionsColumnRenderer } from "./columns/actionsColumn";

const overflowFilterComponentParams = {
  filterComponent: TriStateToggleFilterComponent,
  params: {
    title: "Sale Lots w/ Overflow Pens",
    filter: overflowTriStateFilter,
  },
};
const hasExceptionsFilterComponentParams = {
  filterComponent: TriStateToggleFilterComponent,
  params: {
    title: "Sale Lots w/ Exceptions",
    filter: hasExceptionsTriStateFilter,
  },
};

const saleLotSpeciesAccreditationChildColumns = speciesId =>
  speciesAccreditationChildColumns(speciesId, BaseColumnDefinitions);

const defaultSaleLotColumns = (
  studFeaturesEnabled,
  penScanLotFeaturesEnabled,
  speciesId,
) =>
  [
    BaseColumnDefinitions[Column.Totals.LOW_DOLLARS_HEAD],
    BaseColumnDefinitions[Column.Totals.AVG_DOLLARS_HEAD],
    BaseColumnDefinitions[Column.Totals.HIGH_DOLLARS_HEAD],
    BaseColumnDefinitions[Column.Totals.LOW_CENTS_KG],
    BaseColumnDefinitions[Column.Totals.AVG_CENTS_KG],
    BaseColumnDefinitions[Column.Totals.HIGH_CENTS_KG],
    BaseColumnDefinitions[Column.Totals.HEAD],
    BaseColumnDefinitions[Column.Totals.CENTS],
    BaseColumnDefinitions[Column.PEN],
    BaseColumnDefinitions[Column.AGENCY_VENDOR_NUM],
    BaseColumnDefinitions[Column.VENDOR_NAME],
    BaseColumnDefinitions[Column.VENDOR_PIC],
    withNestedField(Column.BRANDS, "consignment"),
    {
      headerName: "Vendor",
      children: [
        BaseColumnDefinitions[Column.VENDOR_SHORT_CODE],
        BaseColumnDefinitions[Column.VENDOR_SHORT_CODE_SALEYARD],
        BaseColumnDefinitions[Column.VENDOR_ALTERNATIVES],
        BaseColumnDefinitions[Column.SaleLot.VENDOR_SPLITS],
      ],
    },
    {
      headerName: "Vendor Property",
      children: Object.values(
        createNamespacedColumnDefinitions(
          PropertyColumnDefinitions,
          "consignment.vendor_property",
          PropertyNamespaceColumnId.VENDOR_PROPERTY,
        ),
      ),
    },
    BaseColumnDefinitions[Column.BUSINESS_DISPLAY_NAME],
    BaseColumnDefinitions[Column.BUYER_WAY_NAME],
    BaseColumnDefinitions[Column.BIDDER_REGO_NUMBER],
    {
      headerName: "Buyer",
      children: [
        BaseColumnDefinitions[Column.BUYER_SHORT_CODE],
        BaseColumnDefinitions[Column.BUYER_SHORT_CODE_SALEYARD],
        BaseColumnDefinitions[Column.BUYER_NAME],
        BaseColumnDefinitions[Column.BUYER_ALTERNATIVES],
      ],
    },
    {
      headerName: "Buyer Property",
      children: Object.values(
        createNamespacedColumnDefinitions(
          PropertyColumnDefinitions,
          "destinationProperty",
          PropertyNamespaceColumnId.BUYER_PROPERTY,
        ),
      ),
    },

    BaseColumnDefinitions[Column.INVOICE_TO_NAME],
    BaseColumnDefinitions[Column.INVOICE_TO_CODE],
    BaseColumnDefinitions[Column.QUANTITY_PROGENY],
    BaseColumnDefinitions[Column.DESTINATION_PIC],
    {
      ...BaseColumnDefinitions[Column.LOT_NUMBER],
      filter: CompositeFilter.componentName,
      filterParams: {
        components: [
          overflowFilterComponentParams,
          hasExceptionsFilterComponentParams,
        ],
      },
    },
    BaseColumnDefinitions[Column.LOT_NUMBER_SUFFIX],
    BaseColumnDefinitions[Column.LOT_NUMBER_COMBINED],
    BaseColumnDefinitions[Column.THIRD_PARTY_NAME],
    BaseColumnDefinitions[Column.UNIT_PRICE],
    BaseColumnDefinitions[Column.SCANNED_COUNT],
    BaseColumnDefinitions[Column.SCANNED_PERCENTAGE],
    BaseColumnDefinitions[Column.QUANTITY],
    BaseColumnDefinitions[Column.QUANTITY_DELIVERED],
    BaseColumnDefinitions[Column.QUANTITY_TAGS_USED],
    BaseColumnDefinitions[Column.DIFF],
    BaseColumnDefinitions[Column.BREED_NAME],
    BaseColumnDefinitions[Column.SEX_NAME],
    BaseColumnDefinitions[Column.AGE_GROUP_NAME],
    BaseColumnDefinitions[Column.CATEGORY_NAME],
    BaseColumnDefinitions[Column.GRADE_NAME],
    BaseColumnDefinitions[Column.WEIGHT],
    BaseColumnDefinitions[Column.WEIGHT_OUTLIER],
    BaseColumnDefinitions[Column.AVERAGE_WEIGHT],
    BaseColumnDefinitions[Column.AVERAGE_WEIGHT_OUTLIER],
    BaseColumnDefinitions[Column.SALE_ROUND_NAME],
    BaseColumnDefinitions[Column.STATUS],
    BaseColumnDefinitions[Column.AGENCY_NAME],
    BaseColumnDefinitions[Column.IMAGES],
    BaseColumnDefinitions[Column.NOTE],
    BaseColumnDefinitions[Column.LABELS],
    BaseColumnDefinitions[Column.MARKS],
    BaseColumnDefinitions[Column.CREATED],
    BaseColumnDefinitions[Column.LAST_MODIFIED],
    BaseColumnDefinitions[Column.SINGLE_WEIGH_SUM],
    BaseColumnDefinitions[Column.SINGLE_WEIGH_SUM_OUTLIER],
    BaseColumnDefinitions[Column.SINGLE_WEIGH_DIFF],
    BaseColumnDefinitions[Column.TIME_BULK_WEIGHED],
    BaseColumnDefinitions[Column.CONSIGNED_FROM],
    BaseColumnDefinitions[Column.DELIVERY_PEN],
    BaseColumnDefinitions[Column.WEIGHED_COUNT],
    BaseColumnDefinitions[Column.OPEN_AUCTION_COUNT],
    BaseColumnDefinitions[Column.NOT_ANGUS_VERIFIED_COUNT],
    BaseColumnDefinitions[Column.XERO_VENDOR],
    BaseColumnDefinitions[Column.XERO_BUYER],
    BaseColumnDefinitions[Column.OVERFLOW_PEN],
    BaseColumnDefinitions[Column.SaleLot.ACCOUNT_NOTE],
    BaseColumnDefinitions[Column.EXEMPTION_REASON],
    studFeaturesEnabled
      ? BaseColumnDefinitions[Column.SaleLot.ANIMAL_NAME]
      : null,
    studFeaturesEnabled
      ? BaseColumnDefinitions[Column.SaleLot.TAG_NUMBER]
      : null,
    {
      headerName: ACTION_COLUMN_NAME,
      cellRenderer: actionsColumnRenderer,
      mobileWidth: 100,
      colId: ACTION_COLUMN_ID,
      checkboxSelection: enableRowSelection,
      width: 180,
      pinned: "right",
      menuTabs: ["columnsMenuTab"],
      suppressCellFlash: true,
    },
    BaseColumnDefinitions[Column.SaleLot.ESTIMATED_DRESSING_RANGE],
    BaseColumnDefinitions[Column.SaleLot.ESTIMATED_WEIGHT_RANGE],
    BaseColumnDefinitions[saleLotCommentsColumnId],
    penScanLotFeaturesEnabled &&
      BaseColumnDefinitions[Column.SaleLot.PEN_SCAN_LOTS],
    penScanLotFeaturesEnabled && BaseColumnDefinitions[Column.SaleLot.WARNING],
    {
      headerName: "Sale",
      children: [
        LivestockSaleColumnDefinitions[LivestockSaleColId.SALE_TYPE],
        LivestockSaleColumnDefinitions[LivestockSaleColId.SALE_SUB_TYPE],
        LivestockSaleColumnDefinitions[LivestockSaleColId.DATE],
        LivestockSaleColumnDefinitions[LivestockSaleColId.TITLE],
        LivestockSaleColumnDefinitions[LivestockSaleColId.SPECIES_NAME],
      ],
    },
    {
      headerName: "Drafting Attributes",
      children: saleLotSpeciesAccreditationChildColumns(speciesId),
    },
  ]
    .filter(Boolean)
    .map(colDef => ({ ...colDef, hide: true })); // hide by default rely on saved views to show

const clearingSaleSaleLotColumns = [
  {
    ...BaseColumnDefinitions[Column.VENDOR_SHORT_CODE],
    headerName: "Vendor Ref",
  },
  BaseColumnDefinitions[Column.VENDOR_NAME],
  {
    ...BaseColumnDefinitions[Column.SaleLot.VENDOR_SPLITS],
    headerName: "Vendor Splits",
  },
  BaseColumnDefinitions[Column.LOT_NUMBER],
  BaseColumnDefinitions[Column.LOT_NUMBER_SUFFIX],
  BaseColumnDefinitions[Column.LOT_NUMBER_COMBINED],
  BaseColumnDefinitions[Column.AGENT_RELATIONSHIP],
  { ...BaseColumnDefinitions[Column.BUSINESS_DISPLAY_NAME], hide: true },
  {
    ...BaseColumnDefinitions[Column.BUYER_SHORT_CODE],
    headerName: "Buyer Ref",
  },
  BaseColumnDefinitions[Column.ORIGINAL_LISTING_PRICE],
  BaseColumnDefinitions[Column.BUYER_NAME],
  BaseColumnDefinitions[Column.BUYER_NUMBER],
  BaseColumnDefinitions[Column.BIDDER_REGO_NUMBER],
  BaseColumnDefinitions[Column.INVOICE_TO_NAME],
  BaseColumnDefinitions[Column.INVOICE_TO_CODE],
  BaseColumnDefinitions[Column.BUYER_PAYMENTS],
  BaseColumnDefinitions[Column.UNIT_PRICE],
  BaseColumnDefinitions[Column.IS_GST_EXEMPT],
  BaseColumnDefinitions[Column.GST_STATUS],
  BaseColumnDefinitions[Column.ITEM_LOCATION],
  BaseColumnDefinitions[Column.HAS_BUYER_POSTAL_ADDRESS],
  BaseColumnDefinitions[Column.HAS_VENDOR_POSTAL_ADDRESS],
  BaseColumnDefinitions[Column.HAS_PICKUP_ADDRESS],

  BaseColumnDefinitions[Column.EFFECTIVE_VENDOR_COMMISSION_PERCENT],
  BaseColumnDefinitions[Column.EFFECTIVE_VENDOR_COMMISSION_CENTS],
  BaseColumnDefinitions[Column.EFFECTIVE_LISTING_FEE_CENTS],
  BaseColumnDefinitions[Column.RESERVE_PRICE],
  BaseColumnDefinitions[Column.STARTING_BID],
  BaseColumnDefinitions[Column.BID_INCREMENT],

  BaseColumnDefinitions[Column.EFFECTIVE_BUYERS_PREMIUM_PERCENT],
  BaseColumnDefinitions[Column.EFFECTIVE_BUYERS_PREMIUM_CENTS],
  {
    ...BaseColumnDefinitions[Column.QUANTITY],
    headerName: "Qty",
  },
  {
    headerName: ACTION_COLUMN_NAME,
    field: "status",
    cellRenderer: actionsColumnRenderer,
    colId: ACTION_COLUMN_ID,
    width: 260,
    mobileWidth: 100,
    pinned: "right",
    menuTabs: ["columnsMenuTab"],
    checkboxSelection: ({ data }) => !!data,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  BaseColumnDefinitions[Column.CLEARING_SALE_CATEGORY],
  BaseColumnDefinitions[Column.CLEARING_SALE_COMMENTS],
  BaseColumnDefinitions[Column.CLEARING_SALE_HOURS],
  BaseColumnDefinitions[Column.CLEARING_SALE_INSPECTION],
  BaseColumnDefinitions[Column.CLEARING_SALE_ITEM_CONDITION],
  BaseColumnDefinitions[Column.CLEARING_SALE_ITEM_TYPE],
  BaseColumnDefinitions[Column.CLEARING_SALE_MAKE],
  BaseColumnDefinitions[Column.CLEARING_SALE_MODEL],
  BaseColumnDefinitions[Column.CLEARING_SALE_NEW_USED],
  BaseColumnDefinitions[Column.CLEARING_SALE_ODOMETER],
  BaseColumnDefinitions[Column.CLEARING_SALE_PPSR_COMMENTS],
  BaseColumnDefinitions[Column.CLEARING_SALE_PPSR_STATUS],
  BaseColumnDefinitions[Column.CLEARING_SALE_PUBLIC_DESCRIPTION],
  BaseColumnDefinitions[Column.CLEARING_SALE_REGISTRATION],
  BaseColumnDefinitions[Column.CLEARING_SALE_SELL_SAFE_CATEGORY],
  BaseColumnDefinitions[Column.CLEARING_SALE_STATUS],
  BaseColumnDefinitions[Column.CLEARING_SALE_SUB_TYPE],
  BaseColumnDefinitions[Column.CLEARING_SALE_TITLE],
  BaseColumnDefinitions[Column.CLEARING_SALE_VIN],
  BaseColumnDefinitions[Column.CLEARING_SALE_YEAR],
  BaseColumnDefinitions[Column.LISTING_ID],
  BaseColumnDefinitions[Column.IMAGES],
  BaseColumnDefinitions[Column.NOTE],
  BaseColumnDefinitions[Column.CREATED],
  BaseColumnDefinitions[Column.LAST_MODIFIED],
  BaseColumnDefinitions[Column.CONSIGNED_FROM],
  BaseColumnDefinitions[saleLotCommentsColumnId],
  BaseColumnDefinitions[Column.XERO_VENDOR],
  BaseColumnDefinitions[Column.XERO_BUYER],
].map(colDef => ({ ...colDef, hide: true })); // hide by default rely on saved views to show;

export function saleLotColumns(
  saleType,
  studFeaturesEnabled = false,
  penScanLotFeaturesEnabled = false,
  speciesId = null,
) {
  if (saleType === SaleTypes.CLEARING) {
    return clearingSaleSaleLotColumns;
  } else {
    return defaultSaleLotColumns(
      studFeaturesEnabled,
      penScanLotFeaturesEnabled,
      speciesId,
    );
  }
}
