import reduceReducers from "reduce-reducers";

import { CONTACT_BILLING_DOCUMENT } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeBillingDocument } from "lib/serializers/billingDocuments";

const fetch = apiModelOfflineFetchReducer(CONTACT_BILLING_DOCUMENT, {
  deserializer: deserializeBillingDocument,
  clearOnRequest: false,
});

const contactBillingDocumentReducer = reduceReducers(fetch);

export default contactBillingDocumentReducer;
