import { useSelector } from "react-redux";

import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import { hasPermission } from "lib/permissions";

import { getIsLivestockAgent } from "selectors";

import { useGetParentObjectFromActiveRole } from "./useGetParentObjectFromActiveRole";

export const useHasCreateRuleBookPermission = () => {
  const isLivestockAgent = useSelector(getIsLivestockAgent);
  const parentObject = useGetParentObjectFromActiveRole();

  const createPermission = isLivestockAgent
    ? DeploymentPermissions.canAddRuleBook
    : SaleyardPermissions.canAddRuleBook;

  return hasPermission(parentObject, createPermission);
};
