import React from "react";

import { ModalTypes } from "constants/navigation";

import SplitSaleLotModal from "containers/SplitLotModal";

function SplitSaleLotModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.SplitSaleLot] || "";
  const [saleLotId, splitType] = hashParams.split(",");
  const { returnTo, fullScreen } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <SplitSaleLotModal
      fullScreen={fullScreen}
      returnTo={decodedReturnTo}
      saleLotId={saleLotId}
      splitType={splitType}
      {...props}
    />
  );
}

export default SplitSaleLotModalAdapter;
