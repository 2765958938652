import React from "react";

import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import {
  deleteScanAction,
  uploadScansAction,
  uploadUnallocatedScans,
  setAnimalDeceased,
  addSaleLot,
  openConfirmModal,
  closeConfirmModal,
} from "actions";

import AnimalModal from "components/AnimalModal";

import { TRANSFER_STATUS } from "constants/nlis";
import { SaleRoundName } from "constants/sale";

import { neg1ToNulls, removeNulls } from "lib";

import { saleIdOnly } from "lib/sale";

import {
  getNestedConsignments,
  getSaleRoundIdByName,
  getScans,
  getBobbyCalfSaleLots,
  getUnsoldBobbyCalfSaleLots,
  getSaleLots,
  getSales,
} from "selectors";

const AnimalModalContainer = ({
  history: { goBack },
  deleteScanAction,
  uploadScansAction,
  uploadUnallocatedScans,
  setAnimalDeceased,
  allConsignments,
  addSaleLot,
  openConfirmModal,
  closeConfirmModal,
  selectedSale,
  saleRoundId,
  saleLots,
  scan,
  consignments,
  auctionPens,
  allowBlankConsignment,
  allowBlankAuctionPen,
}) => {
  const deleteAnimal = () => {
    openConfirmModal({
      title: "Are you sure?",
      message: `You are about to remove an animal with the EID ${scan.EID} from this sale day. Are you sure you want to remove this animal?`,
      actions: [
        {
          label: "No",
          secondary: true,
          onClick: () => {
            closeConfirmModal();
          },
        },
        {
          label: "Yes",
          onClick: () => {
            deleteScanAction(scan);
            closeConfirmModal();
            goBack();
          },
        },
      ],
    });
  };

  const markDeceased = isDeceased => {
    setAnimalDeceased(scan, isDeceased);
    goBack();
  };

  const saveAnimal = values => {
    // Perform the deceased state change first, otherwise the editable scan
    // endpoint may change after a change to sale lot.
    if (
      scan.animal &&
      ((scan.animal.marked_deceased && !values.deceased) ||
        (!scan.animal.marked_deceased && values.deceased))
    ) {
      setAnimalDeceased(scan, values.deceased);
    }
    // Find the sale lot that links the given consignment and auction pen
    // If it doesn't exist, create it.
    if (values.consignment === null && values.auctionPen === null) {
      // Resetting to unallocated.
      uploadUnallocatedScans(
        [
          {
            EID: scan.EID,
            created: scan.created,
            remove_from_salelot: true,
          },
        ],
        selectedSale,
      );
    } else {
      const selectedConsignment = allConsignments.find(
        c => c.id === values.consignment,
      );
      if (selectedConsignment && selectedConsignment.saleLots) {
        const selectedSaleLot = selectedConsignment.saleLots.find(
          sl => sl.auctionPenId === values.auctionPen,
        );
        if (selectedSaleLot) {
          uploadScansAction([scan], selectedSaleLot.id);
        } else {
          const saleLotId = uuidv4();

          const saleLotFromSamePen = Object.values(saleLots).find(
            sl => sl.auction_pen_id === values.auctionPen,
          );
          // This should always be defined... how to handle the error?

          const saleLotPayload = {
            created: new Date().toUTCString(),
            id: saleLotId,
            pricing_type_id: selectedSale.pricing_type_id,
            auction_pen_id: values.auctionPen,
            buyer_id: saleLotFromSamePen.buyer_id,
            consignment_id: values.consignment,
            destination_property_id: saleLotFromSamePen.destination_property_id,
            livestocksale_id: selectedSale.livestocksale_id,
            quantity: 1, // The one we're editing.
            sale_round_id: saleRoundId,
          };
          addSaleLot(removeNulls(neg1ToNulls(saleLotPayload)), saleLotId);
          uploadScansAction([scan], saleLotId);
        }
      }
    }

    goBack();
  };

  return (
    <AnimalModal
      closeSelf={goBack}
      saveAnimal={saveAnimal}
      deleteAnimal={deleteAnimal}
      markDeceased={markDeceased}
      scan={scan}
      consignments={consignments}
      auctionPens={auctionPens}
      saleLots={saleLots}
      allowBlankConsignment={allowBlankConsignment}
      allowBlankAuctionPen={allowBlankAuctionPen}
    />
  );
};

const mapStateToProps = (state, props) => {
  const { match } = props;
  const { params } = match;
  const { saleId } = params;

  const scan = getScans(state)[props.match.params.EID];

  // If the animal is already assigned to a SOLD sale lot, then the
  // buyer/auctionpen cannot be edited.  Otherwise, show all unsold.
  const allSaleLots = getBobbyCalfSaleLots(state, saleIdOnly(props));
  const selectedSaleLot =
    scan && scan.sale_lot_id
      ? allSaleLots.find(sl => sl.id === scan.sale_lot_id)
      : null;
  const animalIsSold = Boolean(
    selectedSaleLot &&
      selectedSaleLot.nlisSellFileStatus &&
      selectedSaleLot.nlisSellFileStatus !== TRANSFER_STATUS.ROLLED_BACK,
  );

  const selectableSaleLots = animalIsSold
    ? [selectedSaleLot]
    : getUnsoldBobbyCalfSaleLots(state, saleIdOnly(props));
  const uniqueByAuctionPen = (a, index, self) =>
    self.findIndex(b => a.auctionPenId === b.auctionPenId) === index;

  const auctionPens = selectableSaleLots.filter(uniqueByAuctionPen).map(sl => ({
    value: sl.auctionPenId,
    label: `${sl.buyerName} - ${sl.destinationProperty}`,
  }));

  // If the animal is already assigned to a TAKEN consignment, then the
  // consignment  cannot be edited.  Otherwise, show all untaken consignments.
  const allConsignments = getNestedConsignments(state, saleIdOnly(props));
  const animalIsTaken = Boolean(
    selectedSaleLot &&
      selectedSaleLot.nlisTakeFileStatus &&
      selectedSaleLot.nlisTakeFileStatus !== TRANSFER_STATUS.ROLLED_BACK,
  );
  const selectableConsignments = animalIsTaken
    ? allConsignments.filter(c => c.id === selectedSaleLot.consignmentId)
    : allConsignments.filter(
        c =>
          !c.takeStatus ||
          c.takeStatus.filter(ts => ts.status !== TRANSFER_STATUS.ROLLED_BACK)
            .length === 0,
      );
  const consignments = selectableConsignments.map(c => ({
    value: c.id,
    label: c.vendorName,
  }));

  const selectedSale = getSales(state)[saleId];
  const saleRoundId = getSaleRoundIdByName(state, SaleRoundName.BobbyCalf);

  return {
    allConsignments,
    consignments,
    auctionPens,
    selectedSale,
    saleRoundId,
    saleLots: getSaleLots(state),
    scan,
    allowBlankConsignment: !animalIsTaken,
    allowBlankAuctionPen: !animalIsSold,
  };
};

const mapDispatchToProps = {
  deleteScanAction,
  openConfirmModal,
  closeConfirmModal,
  uploadScansAction,
  uploadUnallocatedScans,
  setAnimalDeceased,
  addSaleLot,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnimalModalContainer);
