import { PEN_ARCHETYPE } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializePenArchetype } from "lib/serializers/penArchetypes";

const fetch = apiModelOfflineFetchReducer(PEN_ARCHETYPE, {
  deserializer: deserializePenArchetype,
});

export default fetch;
