import React from "react";

import styled from "styled-components/macro";

import { SlimButton } from "components/Button";
import { Text } from "components/Text";

import { openNewUserRequestForm } from "lib/navigation";

import TechnicalSupport from "img/TechnicalSupport.png";

const NoUserRoleDisplay = () => {
  return (
    <Background>
      <NoRoleDisplayContainer>
        <NoRoleHeaderImage src={TechnicalSupport} />
        <ContentContainer>
          <h2>Welcome to AgriNous</h2>
          <BodyText>
            You do not currently have any roles in AgriNous. To get started,
            contact AgriNous support.
          </BodyText>
          <GetStartedBtn onClick={openNewUserRequestForm}>
            Get Started
          </GetStartedBtn>
        </ContentContainer>
      </NoRoleDisplayContainer>
    </Background>
  );
};

export default NoUserRoleDisplay;

const NoRoleDisplayContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  height: 420px;
  margin: auto;
  width: 500px;
`;

const ContentContainer = styled.div`
  padding: 10px;
`;

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.gray95};
`;

const NoRoleHeaderImage = styled.img`
  width: 100%;
  border-radius: 12px 12px 0px 0px;
`;

const BodyText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const GetStartedBtn = styled(SlimButton)`
  display: block;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 34px;
`;
