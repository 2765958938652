import React from "react";

import { Autocomplete, getOptionAsValue } from "components/Form/FormikControls";

import {
  SpeciesAttribute,
  useGetAttributeOptions,
} from "hooks/useGetAttributeOptions";

export const AgeField = ({
  name,
  dataTour,
  label,
  disabled,
  deploymentId,
  speciesId,
}) => {
  const options = useGetAttributeOptions(
    SpeciesAttribute.AGE,
    speciesId,
    deploymentId,
  );

  return (
    <Autocomplete
      name={name}
      dataTour={dataTour}
      label={label}
      options={options}
      getOptionValue={getOptionAsValue}
      disabled={disabled}
    />
  );
};
