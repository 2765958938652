import React from "react";

import PropTypes from "prop-types";
import { withTheme } from "styled-components";

const NotPennedLogo = ({ color, theme }) => {
  const logoColor = color || theme.colors.primary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3318.92 3318.92"
      width="33px"
      height="33px"
    >
      <defs />
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2519649390736">
          <g>
            <path
              fill={logoColor}
              d="M1114.99 1325.69l1014.41 0 9.14 679.45 -1026.59 0 3.04 -679.45zm-117.64 761.77c42.05,16.74 1204.31,16.74 1247.07,-0.3 42.78,-39.05 -19.03,-49.52 -21.56,-139.89l2.3 -598.91c5.84,-27.91 73.6,-98.72 -5.65,-116.14l-986.87 -0.65c-138.58,0 -247.28,-27.88 -246.29,43.1 0.81,58.29 18.28,13.56 30.39,73.55l2.03 587.24c-0.18,57.87 4.32,36.98 -15.94,76.33 -13.96,27.13 -32.77,22.22 -5.48,75.67z"
            />
            <path
              fill={logoColor}
              d="M1906.15 1452.2c0,480.66 -35.14,471.01 88.39,454.92 17.21,-50.88 16.34,-433.23 0,-486.32 -49.08,-6.39 -88.39,-19.55 -88.39,31.4z"
            />
            <path
              fill={logoColor}
              d="M1246.15 1908.12c85.51,7.98 90.93,16 90.73,-66.3l-0.36 -343.63c1.4,-90.77 2.3,-87.04 -90.37,-78.39 -14.86,58.75 -14.86,429.57 0,488.32z"
            />
            <path
              fill={logoColor}
              d="M1685.95 1909.53l86.22 0.86c15.23,-64.66 15.23,-428.2 0,-491.83l-82.44 -1.11 -3.78 492.08z"
            />
            <path
              fill={logoColor}
              d="M1470.85 1908.12l81.42 2.61 3.57 -492.33 -85.01 -0.19c-16.74,59 -16.74,432.5 0.02,489.91z"
            />
          </g>
          <path
            fill="none"
            stroke="#427DB3"
            strokeWidth="105.83"
            strokeMiterlimit="22.9256"
            d="M1384.4 159.81c-1989.09,374.51 -1457.85,3378.43 568.4,3012.63 1955.32,-352.98 1441.42,-3391.02 -568.4,-3012.63z"
          />
          <line
            fill="none"
            stroke="#427DB3"
            strokeWidth="105.83"
            strokeMiterlimit="22.9256"
            x1="502"
            y1="2690.16"
            x2="2877.47"
            y2="655.47"
          />
        </g>
      </g>
    </svg>
  );
};

NotPennedLogo.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

export default withTheme(NotPennedLogo);
