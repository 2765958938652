import styled from "styled-components/macro";

const Clickable = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
  text-decoration: none;
  -webkit-appearance: initial !important;
  pointer-events: ${({ disabled }) =>
    disabled
      ? "none" /* Prevents link clickable types from being clicked */
      : "auto"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  touch-action: manipulation;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`;

export default Clickable;
