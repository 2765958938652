import React from "react";

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Column, Row } from "components/Layout";

import { getTransferMessage } from "constants/nlis";

import { getIndividualIconAndColor } from "lib/nlisTake";

function EIDTransferMessage({ MessageNumber, Severity, ShowSuccessMessage }) {
  if (
    (MessageNumber ||
      MessageNumber === null ||
      (MessageNumber === 0 && ShowSuccessMessage)) &&
    Severity
  ) {
    const message = getTransferMessage(MessageNumber);
    const { color } = getIndividualIconAndColor(Severity);
    return <span style={{ color }}>{message}</span>;
  }
  return null;
}

const AnimalListItem = ({
  children,
  EID,
  className,
  onClick,
  message_no,
  response_type,
  animal: { nlis_id = "", marked_deceased = false } = {},
}) => (
  <Wrapper data-tour={EID} className={className} onClick={onClick}>
    <Row fullwidth justifyBetween>
      <Column flexGrow>
        <NLISText>{nlis_id}</NLISText>
        <EIDText deceased={Boolean(marked_deceased)}>EID {EID}</EIDText>
        <EIDTransferMessage
          MessageNumber={message_no}
          Severity={response_type}
        />
      </Column>
      {children && <Column flexGrow>{children}</Column>}
      {typeof onClick === "function" ? (
        <Column justifyCenter>
          <FontAwesomeIcon icon={faPencilAlt} />
        </Column>
      ) : null}
    </Row>
  </Wrapper>
);

AnimalListItem.propTypes = {
  animal: PropTypes.object,
  EID: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default AnimalListItem;

const Wrapper = styled(Column)`
  position: relative;
`;

const NLISText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.gamma}px;
`;

const EIDText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
  color: ${({ theme }) => theme.colors.gray8C};
  ${({ deceased, theme }) =>
    deceased
      ? `
    :after {
      content: " DECEASED";
      color: ${theme.colors.red}
    }
  `
      : ""};
`;
