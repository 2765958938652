import React, { useState } from "react";

import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faContainerStorage } from "@fortawesome/pro-light-svg-icons";
import { faDesktop, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { screenResize, setSetting } from "actions";

import { MultiButton } from "components/Button";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { GlobalSearchHeader } from "components/SearchInput/GlobalSearch";
import { BoldText } from "components/Text";

import { PenTypes, PenScanningGroupingType } from "constants/auctionPens";
import { ApiModel } from "constants/loading";
import { Settings } from "constants/settings";

import {
  getLivestockSaleId,
  getPenScanLotTableRoute,
  getReceivalScanLotTableRoute,
  getSaleyardName,
  openEditScanningPenModal,
} from "lib/navigation";
import { scanLotConfig } from "lib/scanLot";

import { getSetting } from "selectors";

import { useTheme, useToggle } from "hooks";

import { PenGroup } from "./Card/Grouped";

export function PenScanningCardsView({ penType }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [viewAllPens, toggleViewAllPens] = useToggle(true);
  const { penShortName } = scanLotConfig(penType);
  const theme = useTheme();
  const groupingType = useSelector(getSetting(Settings.penScanningGroupType));

  const [openCard, setOpenCard] = useState(undefined);

  const { selectLotByIdLookup, selectPenGroups, selectLotIdsByPenIdLookup } =
    scanLotConfig(penType);

  const penGroupsWithEmptyPens = useSelector(selectPenGroups);
  const penGroupsWithoutEmptyPens = penGroupsWithEmptyPens.filter(
    group => group.quantity > 0,
  );

  const lotByIdLookup = useSelector(selectLotByIdLookup);

  const lotIdsByPenIdLookup = useSelector(selectLotIdsByPenIdLookup);

  const totalScanned = penGroupsWithoutEmptyPens.reduce(
    (totalScanned, group) => {
      group.pens.forEach(pen => {
        const scanLotIds = lotIdsByPenIdLookup[pen.penId];
        scanLotIds?.forEach(
          scanLotId =>
            (totalScanned += lotByIdLookup[scanLotId]?.quantity || 0),
        );
      });
      return totalScanned;
    },
    0,
  );

  const changeGrouping = value => {
    dispatch(setSetting(Settings.penScanningGroupType, value));
  };

  const arrivalPenActions = [
    {
      title: penShortName,
      isDisabled: false,
      onClick: () => openEditScanningPenModal(null, null, penType),
      default: true,
      icon: faPlus,
      dataTour: `${
        penType === PenTypes.RECEIVING ? "addArrivalPen" : "addSellingPen"
      }`,
    },
    {
      title: `${viewAllPens ? "Hide" : "Show"} Empty/Unused Pens`,
      icon: faContainerStorage,
      onClick: toggleViewAllPens,
      dataTour: "hidePens",
    },
    {
      title: `Group By Lane`,
      onClick: () => changeGrouping(PenScanningGroupingType.LANE),
      dataTour: "groupByLane",
      icon: faCaretRight,
      iconColor:
        groupingType === PenScanningGroupingType.LANE ? "black" : "transparent",
    },
    {
      title: `Group By Prefix`,
      onClick: () => changeGrouping(PenScanningGroupingType.PREFIX),
      dataTour: "groupByPrefix",
      icon: faCaretRight,
      iconColor:
        groupingType === PenScanningGroupingType.PREFIX
          ? "black"
          : "transparent",
    },
    {
      title: "Desktop View",
      icon: faDesktop,
      onClick: () => {
        dispatch(screenResize(theme.breakpoints[2]));
        history.push(
          penType === PenTypes.RECEIVING
            ? getReceivalScanLotTableRoute(
                getSaleyardName(),
                getLivestockSaleId(),
              )
            : getPenScanLotTableRoute(getSaleyardName(), getLivestockSaleId()),
        );
      },
      dataTour: "desktopView",
    },
  ];

  const visiblePenGroups = viewAllPens
    ? penGroupsWithEmptyPens
    : penGroupsWithoutEmptyPens;

  return (
    <div>
      <WaitForSync
        requiredData={[
          ApiModel.RECEIVAL_LOTS,
          ApiModel.AUCTION_PENS,
          ApiModel.PEN_ARCHETYPES,
        ]}
      >
        <GlobalSearchHeader searchSize={12} buttonSize={3} />
        <Grid container>
          <Grid item xs={8} container spacing={0} alignItems="center">
            <Row padding={2} fullWidth>
              <BoldText>Total {totalScanned}</BoldText>
            </Row>
          </Grid>

          <Grid container item xs={4}>
            <Row padding={2} fullWidth justifyEnd>
              <MultiButton buttons={arrivalPenActions} />
            </Row>
          </Grid>
        </Grid>
        {visiblePenGroups.map((group, idx) => {
          const prefixKey = Array.from(group.prefixes).join("-");
          return (
            <PenGroup
              index={idx}
              key={`${prefixKey}-${group.emptyPenCount}-${group.firstPenNumber}-${group.lastPenNumber}`}
              viewAllPens={viewAllPens}
              penType={penType}
              isOpen={openCard === idx}
              setOpenCard={setOpenCard}
            />
          );
        })}
      </WaitForSync>
    </div>
  );
}
