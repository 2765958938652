import * as Yup from "yup";

export const positiveWhenSet = (key, passSchema) =>
  key ? passSchema.min(1, "Required if price is set") : passSchema;

export const baseValidationShape = {
  breed_id: Yup.number().nullable(),
  sex_id: Yup.number().nullable(),
  grade_id: Yup.number().nullable(),
  age_id: Yup.number().nullable(),
  category_id: Yup.number().nullable(),
  exemption_id: Yup.number().nullable(),
  estimatedAverageMassGrams: Yup.number().nullable(),
  note: Yup.string(),
  quantity: Yup.number()
    .min(0, "Head count must be 0 or more.")
    .required("Head count required.")
    .when("unit_price", positiveWhenSet),
  sale_round_id: Yup.number()
    .required("Sale round required")
    .positive("Sale round required"),
  marks: Yup.array(),
  quantityProgeny: Yup.number()
    .integer("Progeny count must be a whole number.")
    .min(0, "Progeny count must be 0 or more."),
};

export default baseValidationShape;
