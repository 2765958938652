import { sendRawHubPayload } from "actions";

import { scannerMessages } from "constants/scanner";

function onEraseSavedScans({ deviceId }) {
  return sendRawHubPayload({
    event: scannerMessages.ERASE_SAVED_SCANS,
    deviceId,
  });
}

function onGetDeviceTime({ deviceId }) {
  return sendRawHubPayload({
    event: scannerMessages.READ_DEVICE_TIME,
    deviceId,
  });
}

function onReadSavedScans({ deviceId }) {
  return sendRawHubPayload({
    event: scannerMessages.READ_SAVED_SCANS,
    deviceId,
  });
}

function onStartDraft({ deviceId, sessionName }) {
  if (!deviceId) {
    return;
  }
  return sendRawHubPayload({
    event: scannerMessages.START_DRAFT,
    deviceId,
    object: {
      sessionName,
    },
  });
}

export const ANDROID_HUB_SCANNER_HANDLER = {
  [scannerMessages.ERASE_SAVED_SCANS]: onEraseSavedScans,
  [scannerMessages.READ_DEVICE_TIME]: onGetDeviceTime,
  [scannerMessages.READ_SAVED_SCANS]: onReadSavedScans,
  [scannerMessages.START_DRAFT]: onStartDraft,
};
