import queryString from "query-string";
import { v4 as uuidv4 } from "uuid";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "actions/lib";

import {
  NLIS_MESSAGE_GROUPS,
  P2P_FILE,
  P2P_REVERSAL,
  SELL_FILE,
  SELL_REVERSAL,
  SIGHTING_FILE,
  SUBMIT_NVD_UPLOAD_OFFLINE,
  TAKE_FILE,
  TAKE_REVERSAL,
} from "constants/actionTypes";

import { getSaleUrl } from "lib/navigation";
import { getApiSale } from "lib/sale";
import {
  serializeNlisMessageGroup,
  serializeP2PFile,
  serializeP2PReversal,
  serializeSellFile,
  serializeSellReversal,
  serializeSighting,
  serializeTakeFile,
  serializeTakeReversal,
} from "lib/serializers/nlis";
import { getNowNoTimezone } from "lib/timeFormats";

export const reverseTakeFile = (sale, takeFileId) => {
  const tempId = uuidv4();

  const url = `${getSaleUrl(sale)}/reverse-take-possession/`;
  const payload = serializeTakeReversal({ reversal_of: takeFileId });
  return {
    type: TAKE_REVERSAL.CREATE.REQUEST,
    payload: serializeTakeReversal({
      // This is used as a holding spot for the reducers...
      id: tempId,
      created: getNowNoTimezone(),
      ...payload,
    }),
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: TAKE_REVERSAL.CREATE.SUCCESS,
          tempId,
        },
        rollback: {
          type: TAKE_REVERSAL.CREATE.FAILURE,
          tempId,
        },
      },
    },
  };
};

export const reverseSellFile = (sale, sellFileId) => {
  const tempId = uuidv4();

  const url = `${getSaleUrl(sale)}/reverse-sell-possession/`;
  const payload = serializeSellReversal({ reversal_of: sellFileId });
  return {
    type: SELL_REVERSAL.CREATE.REQUEST,
    payload: serializeSellReversal({
      // This is used as a holding spot for the reducers...
      id: tempId,
      created: getNowNoTimezone(),
      ...payload,
    }),
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: SELL_REVERSAL.CREATE.SUCCESS,
          tempId,
        },
        rollback: {
          type: SELL_REVERSAL.CREATE.FAILURE,
          tempId,
        },
      },
    },
  };
};

export const sellPossession = (sale, saleLotIds) => {
  const tempId = uuidv4();

  const payload = { sale_lots: saleLotIds };

  const url = `${getSaleUrl(sale)}/sell-possession/`;

  return {
    type: SELL_FILE.CREATE.REQUEST,
    payload: serializeSellFile({
      // This is used as a holding spot for the reducers...
      id: tempId,
      created: getNowNoTimezone(),
      ...payload,
    }),
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: SELL_FILE.CREATE.SUCCESS,
          tempId,
        },
        rollback: {
          type: SELL_FILE.CREATE.FAILURE,
          tempId,
        },
      },
    },
  };
};

export const reverseCorrectableTakes = sale => {
  const url = `${getSaleUrl(sale)}/reverse-take-possession/all/`;
  const tempId = uuidv4();
  const payload = [
    {
      id: tempId,
    },
  ];

  return {
    type: TAKE_REVERSAL.CREATE_BULK.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
        },
        commit: {
          type: TAKE_REVERSAL.CREATE_BULK.SUCCESS,
          tempIds: [tempId],
        },
        rollback: {
          type: TAKE_REVERSAL.CREATE_BULK.FAILURE,
          tempIds: [tempId],
        },
      },
    },
  };
};

export const reverseCorrectableSells = sale => {
  const url = `${getSaleUrl(sale)}/reverse-sell-possession/all/`;
  const tempId = uuidv4();
  const payload = [
    {
      id: tempId,
    },
  ];
  return {
    type: SELL_REVERSAL.CREATE_BULK.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
        },
        commit: {
          type: SELL_REVERSAL.CREATE_BULK.SUCCESS,
          tempIds: [tempId],
        },
        rollback: {
          type: SELL_REVERSAL.CREATE_BULK.FAILURE,
          tempIds: [tempId],
        },
      },
    },
  };
};

export const sellPossessionByBuyer = sellPossessions => {
  const saleUrl = getSaleUrl(getApiSale());

  const url = `${saleUrl}/sell-possession/per-buyer/`;

  const localPayload = sellPossessions.map(sellPossession =>
    serializeSellFile(sellPossession, { id: uuidv4() }),
  );

  // Submitting the "transfers" causes extra validation, however we want
  // the users to think their Sell Transfer has been created in the void
  // between submitting an API request, and the NLIS timer job creating
  // the DB model representing the transfer
  const payload = localPayload.map(
    ({ transfers: ignored, ...sellPossession }) => sellPossession,
  );

  const tempIds = payload.map(sellPossession => sellPossession.id);

  return {
    type: SELL_FILE.CREATE_BULK.REQUEST,
    payload: localPayload,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: SELL_FILE.CREATE_BULK.SUCCESS,
          tempIds,
        },
        rollback: {
          type: SELL_FILE.CREATE_BULK.FAILURE,
          tempIds,
        },
      },
    },
  };
};

export const takeConsignmentsIndividually = takePossessions => {
  const saleUrl = getSaleUrl(getApiSale());
  const url = `${saleUrl}/take-possession/per-consignment/`;

  const localPayload = takePossessions.map(takePossession =>
    serializeTakeFile(takePossession, { id: uuidv4() }),
  );

  // Submitting the "transfers" causes extra validation, however we want
  // the users to think their Sell Transfer has been created in the void
  // between submitting an API request, and the NLIS timer job creating
  // the DB model representing the transfer
  const payload = localPayload.map(
    ({ transfers: ignored, ...takePossession }) => takePossession,
  );

  const tempIds = payload.map(takePossession => takePossession.id);

  return {
    type: TAKE_FILE.CREATE_BULK.REQUEST,
    payload: localPayload,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: TAKE_FILE.CREATE_BULK.SUCCESS,
          tempIds,
        },
        rollback: {
          type: TAKE_FILE.CREATE_BULK.FAILURE,
          tempIds,
        },
      },
    },
  };
};

export const takeConsignments = (sale, consignments) => {
  const tempId = uuidv4();

  const url = `${getSaleUrl(sale)}/take-possession/`;

  const payload = serializeTakeFile({
    consignments,
    id: tempId,
    created: getNowNoTimezone(),
  });

  return {
    type: TAKE_FILE.CREATE.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: TAKE_FILE.CREATE.SUCCESS,
          tempId,
        },
        rollback: {
          type: TAKE_FILE.CREATE.FAILURE,
          tempId,
        },
      },
    },
  };
};

export function bulkCreateTakePossessions(eids) {
  return {
    type: TAKE_FILE.CREATE_BULK.ACTION,
    eids,
  };
}

export function bulkCreateAutoTakePossessions(eids, options) {
  return {
    type: TAKE_FILE.AUTO.ACTION,
    eids,
    options: { ...(options || null), isManualTransfer: false },
  };
}

export function bulkCreateSellPossessions(eids) {
  return {
    type: SELL_FILE.CREATE_BULK.ACTION,
    eids,
  };
}

export function bulkCreateAutoSellPossessions(eids, options) {
  return {
    type: SELL_FILE.AUTO.ACTION,
    eids,
    options: { ...(options || null), isManualTransfer: false },
  };
}

export const createManualSellPossessions = sellPossessions => {
  const saleUrl = getSaleUrl(getApiSale());

  const url = `${saleUrl}/sell-possession/manual/`;

  const payload = sellPossessions.map(sellPossession =>
    serializeSellFile(sellPossession, { id: uuidv4() }),
  );

  const tempIds = payload.map(sellPossession => sellPossession.id);

  return {
    type: SELL_FILE.CREATE_BULK.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: SELL_FILE.CREATE_BULK.SUCCESS,
          tempIds,
        },
        rollback: {
          type: SELL_FILE.CREATE_BULK.FAILURE,
          tempIds,
        },
      },
    },
  };
};

export const createManualTakePossessions = takePossessions => {
  const saleUrl = getSaleUrl(getApiSale());

  const url = `${saleUrl}/take-possession/manual/`;

  const payload = takePossessions.map(takePossession =>
    serializeTakeFile(takePossession, { id: uuidv4() }),
  );

  const tempIds = payload.map(takePossession => takePossession.id);

  return {
    type: TAKE_FILE.CREATE_BULK.REQUEST,
    payload,
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: TAKE_FILE.CREATE_BULK.SUCCESS,
          tempIds,
        },
        rollback: {
          type: TAKE_FILE.CREATE_BULK.FAILURE,
          tempIds,
        },
      },
    },
  };
};

export const submitNVDUploads = (consignmentIds, sale) => {
  const meta = {
    consignmentIds,
  };

  const url = `${getSaleUrl(sale)}/nvd-upload/`;
  return {
    type: SUBMIT_NVD_UPLOAD_OFFLINE.REQUEST,
    payload: {
      consignments: consignmentIds,
    },
    meta: {
      offline: {
        effect: {
          url,
          method: "POST",
          body: JSON.stringify({ consignments: consignmentIds }),
        },
        commit: {
          type: SUBMIT_NVD_UPLOAD_OFFLINE.SUCCESS,
          meta,
        },
        rollback: {
          type: SUBMIT_NVD_UPLOAD_OFFLINE.FAILURE,
          meta,
        },
      },
    },
  };
};

export const deleteTakeFile = offlineActionCreator(
  TAKE_FILE,
  null,
  ({ id }) => urlCheckAndAddIdAndAction("/v2/take-possession/", id),
  false,
).delete;

export const deleteSellFile = offlineActionCreator(
  SELL_FILE,
  null,
  ({ id }) => urlCheckAndAddIdAndAction("/v2/sell-possession/", id),
  false,
).delete;

const sightingUrlCreator = ({ changesSince, livestockSaleId, id } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/sighting/", id),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const SightingAction = offlineActionCreator(
  SIGHTING_FILE,
  serializeSighting,
  sightingUrlCreator,
  true,
);

export function bulkCreateP2pTransfer(eids, authority) {
  return {
    type: P2P_FILE.CREATE_BULK.ACTION,
    eids,
    authority,
  };
}

const p2pUrlCreator = ({ changesSince, livestockSaleId, id } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/nlis/p2p/", id),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const P2PFileAction = offlineActionCreator(
  P2P_FILE,
  serializeP2PFile,
  p2pUrlCreator,
  true,
);

const p2pReversalUrlCreator = ({
  action,
  changesSince,
  id,
  livestockSaleId,
} = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/nlis/p2p-reversals/", id, action),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const P2PReversalAction = offlineActionCreator(
  P2P_REVERSAL,
  serializeP2PReversal,
  p2pReversalUrlCreator,
  true,
);

export const NLISMessageGroupsAction = offlineActionCreator(
  NLIS_MESSAGE_GROUPS,
  serializeNlisMessageGroup,
  () => "/v2/nlis-message-groups/",
);
