import { createSelector } from "reselect";

import { getCheckpoints } from "./root";

export const getSortedCheckpoints = createSelector(
  getCheckpoints,
  checkpoints =>
    Object.values(checkpoints).sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
    ),
);

export const getCheckpointById = checkpointId => state =>
  getCheckpoints(state)[checkpointId] || null;
