import { offlineActionCreator } from "actions/lib";

import { MASTER_LABEL } from "constants/actionTypes";

import { serializeMasterLabel } from "lib/serializers/masterLabels";

const urlCreator = () => "/v2/master-labels/";

export const MasterLabelAction = offlineActionCreator(
  MASTER_LABEL,
  serializeMasterLabel,
  urlCreator,
  false,
);
