import React from "react";

import { faSyncAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faContainerStorage,
  faSlash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup } from "@material-ui/core";
import { useField } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { bulkUpdateSaleLotsSerializer, DeploymentSaleAction } from "actions";

import { deleteDeploymentSale } from "actions/offline/deploymentSale";

import { IconButton } from "components/Button";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Tooltip } from "components/Form/FormikControls/Tooltip";

import { ForNotClearingSale } from "containers/ForSaleType";
import { ForSaleyardAdmin } from "containers/ForUserType";

import { getLivestockSaleId } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import {
  selectAgencyByDeploymentSaleIdLookup,
  selectSaleLotIdsByDeploymentSaleIdLookup,
  selectSaleLotIdsByAuctionPenIdLookup,
  getAuctionPenIdsByDeploymentSaleId,
  getIsOnline,
} from "selectors";

import { StackedIcon } from "img/Tools";

export const DeploymentSaleInterfaceActions = ({
  deploymentSaleId,
  name,
  disabled,
}) => {
  const [{ value: deploymentSale }] = useField(name);
  const dispatch = useDispatch();

  const agencyByDeploymentSaleIdLookup = useSelector(
    selectAgencyByDeploymentSaleIdLookup,
  );

  const saleLotIdsByDeploymentSaleIdLookup = useSelector(
    selectSaleLotIdsByDeploymentSaleIdLookup,
  );

  const [confirmDeleteDeploymentId, setConfirmDeleteDeploymentId] =
    React.useState(null);
  const closeDeleteDialog = () => setConfirmDeleteDeploymentId(null);

  const [confirmBulkUpdateDeploymentId, setConfirmBulkUpdateDeploymentId] =
    React.useState(null);
  const closeRefreshDialog = () => setConfirmBulkUpdateDeploymentId(null);

  const [
    openBulkUpdateLotNumbersToPenNumbersDialogDeploymentId,
    setOpenBulkUpdateLotNumbersToPenNumbersDialogDeploymentId,
  ] = React.useState(null);
  const closePenToLotsDialog = () =>
    setOpenBulkUpdateLotNumbersToPenNumbersDialogDeploymentId(null);

  const penIds =
    useSelector(getAuctionPenIdsByDeploymentSaleId(deploymentSaleId)) || [];

  const isOnline = useSelector(getIsOnline);
  const saleLotIdsLookup = useSelector(selectSaleLotIdsByAuctionPenIdLookup);

  const blockBulkUpdatePensToLots = Boolean(
    penIds.find(id => saleLotIdsLookup[id].length > 1) || !isOnline,
  );
  const bulkUpdateLotsToPenTooltip = blockBulkUpdatePensToLots
    ? "Pens must contain at most a single Sale Lot to bulk update Sale Lot numbers."
    : "Overwrite all Sale Lot numbers with Pen numbers.";
  const onBulkUpdateLotsToPen = blockBulkUpdatePensToLots
    ? null
    : () =>
        setOpenBulkUpdateLotNumbersToPenNumbersDialogDeploymentId(
          deploymentSaleId,
        );
  return (
    <>
      <ButtonGroup color="primary">
        <Tooltip title="Apply Export Sites to Existing Sale Lots.">
          <IconButton
            onClick={() => setConfirmBulkUpdateDeploymentId(deploymentSaleId)}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faSyncAlt} />
          </IconButton>
        </Tooltip>
        <ForNotClearingSale>
          <Tooltip
            clickable={blockBulkUpdatePensToLots}
            title={bulkUpdateLotsToPenTooltip}
            arrow={blockBulkUpdatePensToLots}
            placement={blockBulkUpdatePensToLots ? "top-end" : ""}
          >
            <IconButton
              data-tour="bulkUpdateLotsToPen"
              onClick={onBulkUpdateLotsToPen}
              disabled={disabled}
            >
              {blockBulkUpdatePensToLots && <StackedIcon icon={faSlash} />}
              <FontAwesomeIcon icon={faContainerStorage} />
            </IconButton>
          </Tooltip>
        </ForNotClearingSale>
        <ForSaleyardAdmin>
          <Tooltip title="Remove from sale.">
            <IconButton
              data-tour="removeFromSale"
              onClick={() => setConfirmDeleteDeploymentId(deploymentSaleId)}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        </ForSaleyardAdmin>
      </ButtonGroup>

      <ConfirmDialog
        isOpen={Boolean(confirmDeleteDeploymentId)}
        onCancel={closeDeleteDialog}
        onDelete={() => {
          dispatch(
            deleteDeploymentSale(
              confirmDeleteDeploymentId,
              getLivestockSaleId(),
            ),
          );
          closeDeleteDialog();
        }}
        buttonMessage="Confirm"
        message={`Are you sure you wish to remove ${
          agencyByDeploymentSaleIdLookup[confirmDeleteDeploymentId]?.name ||
          "this agent"
        } from the sale?`}
        title="Confirm Removal"
      />

      <ConfirmDialog
        isOpen={Boolean(confirmBulkUpdateDeploymentId)}
        onCancel={closeRefreshDialog}
        onDelete={() => {
          const payload = saleLotIdsByDeploymentSaleIdLookup[
            confirmBulkUpdateDeploymentId
          ]?.map(id => ({ id, exportSites: deploymentSale.export_sites }));

          // TODO: At this point it makes sense to save the integration sites in the form
          // on the deployment sale as well; so look at values of the form instead of
          // values from the deploymentsale.

          dispatch(bulkUpdateSaleLotsSerializer(payload));
          closeRefreshDialog();
        }}
        buttonMessage="Confirm"
        message={`This will update the Export Sites for ${
          agencyByDeploymentSaleIdLookup[confirmBulkUpdateDeploymentId]?.name ||
          "this agency"
        }'s ${
          saleLotIdsByDeploymentSaleIdLookup[confirmBulkUpdateDeploymentId]
            ?.length || 0
        }  ${pluralize(
          "Sale Lot",
          saleLotIdsByDeploymentSaleIdLookup[confirmBulkUpdateDeploymentId]
            ?.length,
        )} in this sale?`}
        title="Confirm Refresh"
      />

      <ConfirmDialog
        isOpen={Boolean(
          openBulkUpdateLotNumbersToPenNumbersDialogDeploymentId !== null,
        )}
        onCancel={closePenToLotsDialog}
        onDelete={() => {
          dispatch(
            DeploymentSaleAction.bulkUpdateLotNoToPenNo(
              openBulkUpdateLotNumbersToPenNumbersDialogDeploymentId,
            ),
          );
          closePenToLotsDialog();
        }}
        buttonMessage="Confirm"
        message={`This will update the lot numbers to match pen numbers for ${
          agencyByDeploymentSaleIdLookup[
            openBulkUpdateLotNumbersToPenNumbersDialogDeploymentId
          ]?.name || "this agency"
        }'s ${
          saleLotIdsByDeploymentSaleIdLookup[
            openBulkUpdateLotNumbersToPenNumbersDialogDeploymentId
          ]?.length || 0
        }  ${pluralize(
          "Sale Lot",
          saleLotIdsByDeploymentSaleIdLookup[
            openBulkUpdateLotNumbersToPenNumbersDialogDeploymentId
          ]?.length,
        )} in this sale?`}
        title="Confirm Bulk Update" //
      />
    </>
  );
};
