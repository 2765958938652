import React from "react";

import { useField } from "formik";

import {
  ControlledLifeCycleInput,
  DecimalStringInputProps,
} from "components/Form/FormikControls/ControlledLifecycleInput";

import {
  LivestockSalePricingTypeDisplayNameLkp,
  PricingTypes,
} from "constants/pricingTypes";

export const UnitPrice = ({
  disabled,
  autoFocus,
  name,
  pricingTypeField = "pricingTypeId",
  showLabel = true,
}) => {
  const [{ value: pricingTypeId }] = useField(pricingTypeField);

  const pricingTypeAttributesLookup = {
    [PricingTypes.PER_KILO]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.PER_KILO],
      afterSymbol: "¢",
    },
    [PricingTypes.GROSS]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.GROSS],
      beforeSymbol: "$",
    },
    [PricingTypes.PER_HEAD]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.PER_HEAD],
      beforeSymbol: "$",
    },
  };

  const pricingTypeAttributes = pricingTypeAttributesLookup[pricingTypeId];

  return (
    <ControlledLifeCycleInput
      {...DecimalStringInputProps}
      label={showLabel && (pricingTypeAttributes?.label || "Price")}
      name={name}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};
