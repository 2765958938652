import React from "react";

import { intersection, sum } from "lodash";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";

import { buildLineBreakingAuctionPenDisplayName } from "lib/auctionPens";
import { createAndOpenPdf, getSaleAndDocumentInformation } from "lib/pdf";

import {
  getAges,
  getBreeds,
  getBusinesses,
  getSaleLots,
  getSexes,
  selectSaleLotIdsByAuctionPenIdLookup,
  selectSaleLotIdsByVendorIdLookup,
} from "selectors";

const pageMargin = 12;
const pageMarginTop = 12;
const pageMarginBottom = 40;

const tableHeaders = [
  { text: "PEN", style: "tableHeader" },
  { text: "HD", style: "tableHeader" },
  { text: "VENDOR", style: "tableHeader" },
  { text: "AGE", style: "tableHeader" },
  { text: "SEX", style: "tableHeader" },
  { text: "BREED", style: "tableHeader" },
];

export const SheepSpottingCardPortrait = ({
  auctionPens,
  sale,
  agencyName,
  round,
  history,
}) => {
  const businessByIdLookup = useSelector(getBusinesses);
  const saleLotByIdLookup = useSelector(getSaleLots);
  const saleLotIdsByVendorId = useSelector(selectSaleLotIdsByVendorIdLookup);
  const saleLotIdsByAuctionPenId = useSelector(
    selectSaleLotIdsByAuctionPenIdLookup,
  );
  const ageLookup = useSelector(getAges);
  const sexLookup = useSelector(getSexes);
  const breedLookup = useSelector(getBreeds);

  const getSaleLotIdsByVendorIdAndAuctionPenId = (vendorId, auctionPenId) => {
    return intersection(
      saleLotIdsByVendorId[vendorId],
      saleLotIdsByAuctionPenId[auctionPenId],
    );
  };

  const rows = [];

  let cachedPenName = null;
  let penHasChanged = true;
  let color = null;

  Object.values(auctionPens).forEach(auctionPen => {
    const vendors = [];
    const quantities = [];
    let penNameAndOverflow = "";

    auctionPen.sale_lots.forEach(saleLot => {
      if (!vendors.includes(businessByIdLookup[saleLot.vendor_id])) {
        vendors.push(businessByIdLookup[saleLot.vendor_id]);
      }
      const { overflowPen, overflowQuantity = null } = saleLot;
      penNameAndOverflow = buildLineBreakingAuctionPenDisplayName(
        auctionPen,
        overflowPen,
        overflowQuantity,
        "",
      );
    });

    vendors.forEach(vendor => {
      const vendorSaleLotIds = getSaleLotIdsByVendorIdAndAuctionPenId(
        vendor.id,
        auctionPen.id,
      );
      const vendorInPenQuantity = sum(
        vendorSaleLotIds.map(
          saleLotId => saleLotByIdLookup[saleLotId]?.quantity,
        ),
      );

      quantities.push(vendorInPenQuantity.toString());

      const vendorAges = new Set();
      const vendorSexes = new Set();
      const vendorBreeds = new Set();

      vendorSaleLotIds.forEach(saleLotId => {
        const saleLot = saleLotByIdLookup[saleLotId];
        const ageName = ageLookup[saleLot.age_id]?.name;
        const sexName = sexLookup[saleLot.sex_id]?.name;
        const breedName = breedLookup[saleLot.breed_id]?.name;
        if (ageName) {
          vendorAges.add(ageName);
        }
        if (sexName) {
          vendorSexes.add(sexName);
        }
        if (breedName) {
          vendorBreeds.add(breedName);
        }
      });

      if (cachedPenName && penNameAndOverflow !== cachedPenName) {
        if (!color) {
          color = "#CCCCCC";
        } else {
          color = null;
        }
        penHasChanged = true;
      }

      cachedPenName = penNameAndOverflow;

      rows.push({
        penName: (penHasChanged && penNameAndOverflow) || null,
        head: vendorInPenQuantity,
        vendorName: vendor.name,
        ages: Array.from(vendorAges).join(" "),
        sexes: Array.from(vendorSexes).join(" "),
        breeds: Array.from(vendorBreeds).join(" "),
        color,
      });

      penHasChanged = false;
    });
  });

  const pdfDefinition = {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [pageMargin, pageMarginTop, pageMargin, pageMarginBottom],
    content: {
      table: {
        headerRows: 1,
        widths: [40, 40, 200, 80, 80, 80],

        body: [
          tableHeaders,
          ...rows.map(row => [
            row.penName,
            row.head,
            row.vendorName,
            row.ages,
            row.sexes,
            row.breeds,
          ]),
        ],
      },
      layout: {
        fillColor(rowIndex) {
          return rows[rowIndex - 1]?.color;
        },
      },
    },
    footer: getSaleAndDocumentInformation(
      auctionPens,
      sale,
      agencyName,
      round,
      pageMargin,
    ),
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 20,
      },
      mainText: {
        bold: true,
        fontSize: 20,
      },
    },
  };

  createAndOpenPdf(pdfDefinition, history);

  return <div />;
};

SheepSpottingCardPortrait.propTypes = {
  auctionPens: PropTypes.array,
  sale: PropTypes.object,
  history: PropTypes.object,
  agencyName: PropTypes.string,
  round: PropTypes.object,
};
