import React from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { Button, SecondaryButton } from "components/Form";
import { Input, OptionTogglerField } from "components/Form/FormikControls";
import {
  DialogActions,
  DialogContent,
  ZoomyDialog,
  DialogTitle,
} from "components/MaterialDialog";

import { OwnerTypes } from "constants/aggrid";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(""),
});

const defaultValues = { name: "", ownerType: OwnerTypes.ME };

export const SavedViewsDialog = ({ handleSave, handleClose, configMap }) => {
  const visibilityOptions = [
    {
      value: OwnerTypes.ME,
      label: "Just me",
    },
  ];

  if (configMap.canCreateDeployment) {
    visibilityOptions.push({
      value: OwnerTypes.DEPLOYMENT,
      label: configMap.shareableLabel,
    });
  }

  if (configMap.canCreateSaleyard) {
    visibilityOptions.push({
      value: OwnerTypes.SALEYARD,
      label: configMap.shareableLabel,
    });
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      <ZoomyDialog open onClose={handleClose}>
        <Form>
          <DialogTitle onClose={handleClose}>
            Save Current Table View
          </DialogTitle>

          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input name="name" label="Name" autoFocus />
              </Grid>
              <Grid item xs={12}>
                <OptionTogglerField
                  name="ownerType"
                  label="Visible to"
                  labelPosition="top"
                  options={visibilityOptions}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </ZoomyDialog>
    </Formik>
  );
};
