import React, { memo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { AlternativeType } from "constants/businesses";

import { getPropertiesByBusinessId, getPropertyById } from "selectors";

import { Property } from "./Property";

export const DeploymentProperty = memo(props => {
  const { businessId, propertyId } = props;

  const masterProperty = useSelector(getPropertyById(propertyId)) || {};

  const localProperties = useSelector(
    getPropertiesByBusinessId(businessId),
  ).filter(property => property.id === propertyId);

  // only display "local" names (do not display masterProperty name)
  const names = localProperties
    .filter(localProperty => localProperty.name)
    .map(localProperty => localProperty.name);

  // fallback to using the SY property name
  //  - when Deployment Property doesn't have a name
  if (names.length === 0) {
    const saleyardName = localProperties.reduce((altName, property) => {
      return (
        altName ||
        property.alternatives
          ?.filter(
            alternative =>
              alternative.name &&
              alternative.source.type === AlternativeType.Saleyard,
          )
          .reduce((alternateName, alternative) => {
            return alternateName || alternative.name;
          }, "") ||
        ""
      );
    }, "");
    if (saleyardName) {
      names.push(saleyardName);
    }
  }

  return <Property pic={masterProperty.PIC} names={names} />;
});
DeploymentProperty.propTypes = {
  businessId: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
};
