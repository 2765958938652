import React, { useState } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import { addSaleLotWithPens, deleteSaleLot, patchSaleLot } from "actions";

import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import {
  Button,
  DeleteButton,
  FormSectionGrid,
  SecondaryButton,
} from "components/Form";
import {
  Autocomplete,
  ControlledLifeCycleInput,
  DecimalNumberInputProps,
  OptionTogglerField,
  useSubmitHandler,
} from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { ApiModel } from "constants/loading";
import { SaleLotType } from "constants/saleLots";

import { formatWeightKg, getDollarPriceStringFromCents } from "lib";

import { getCombinedLotNumber } from "lib/saleLot";

import {
  getMaxVendorSplitSubLotNumberByParentSaleLotId,
  getSaleLotById,
  getSelectableVendorSplitConsignmentOptionsBySaleLotId,
  getVendorSplitSaleLotById,
} from "selectors";

import { useBoolean, useFieldValue } from "hooks";

import { validationSchema } from "./validationSchema";

const BreakdownTable = styled.table(
  ({ theme }) => `
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    text-align: right;
    padding: 0 ${theme.space[1]}px;   
  }
  
  th { 
    background-color: ${theme.colors.white};
  }
  tbody td {
    background-color: ${theme.colors.gray85};
  }
  tbody td.parentLot {  
     background-color: ${theme.colors.gray9C};
  }
`,
);

function VendorSplitLotBreakdown({ parentSaleLotId }) {
  const parentSaleLot = useSelector(getSaleLotById(parentSaleLotId)) || {};

  const enteredQuantity = useFieldValue("quantity");

  const percentage = enteredQuantity / parentSaleLot.quantity;
  const calculatedPrice = percentage * parentSaleLot.total_price_cents;

  return (
    <Grid item xs={12}>
      {enteredQuantity > parentSaleLot.quantity ? (
        <Row justifyCenter>
          <Error>
            Warning: You have entered a Head Count greater than the Sale Lot
            Head Count. This may lead to errors in billing calculations.
          </Error>
        </Row>
      ) : null}
      <BreakdownTable>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th className="parentLot">Sale Lot Value</th>
            <th>Split Vendor Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Head</th>
            <td className="parentLot">{parentSaleLot.quantity}</td>
            <td>{enteredQuantity}</td>
          </tr>

          <tr>
            <th>$/Head</th>
            <td className="parentLot">
              $
              {parentSaleLot.quantity
                ? getDollarPriceStringFromCents(
                    parentSaleLot.total_price_cents / parentSaleLot.quantity,
                  )
                : "-"}
            </td>
            <td>
              $
              {enteredQuantity
                ? getDollarPriceStringFromCents(
                    calculatedPrice / enteredQuantity,
                  )
                : "-"}
            </td>
          </tr>

          <tr>
            <th>$/Gross</th>
            <td className="parentLot">
              ${getDollarPriceStringFromCents(parentSaleLot.total_price_cents)}
            </td>
            <td>${getDollarPriceStringFromCents(calculatedPrice)}</td>
          </tr>

          <tr>
            <th>Weight</th>
            <td className="parentLot">
              {formatWeightKg(parentSaleLot.total_mass_grams)}
            </td>
            <td>
              {formatWeightKg(percentage * parentSaleLot.total_mass_grams)}
            </td>
          </tr>
        </tbody>
      </BreakdownTable>
    </Grid>
  );
}

const getOptionValue = option => option.value;

function EditVendorSplitSaleLotForm({ setIsSubmitEnabled, parentSaleLotId }) {
  useSubmitHandler(false, setIsSubmitEnabled);

  const consignmentOptions = useSelector(
    getSelectableVendorSplitConsignmentOptionsBySaleLotId(parentSaleLotId),
  );

  return (
    <FormSectionGrid container spacing={2}>
      <Grid item xs={12}>
        {consignmentOptions.length > 3 ? (
          <Autocomplete
            name="consignmentId"
            label="Vendor"
            options={consignmentOptions}
            required
            tooltip="The Vendor this split should be attributed to."
            getOptionValue={getOptionValue}
          />
        ) : (
          <OptionTogglerField
            label="Vendor"
            name="consignmentId"
            options={consignmentOptions}
            tooltip="The Vendor this split should be attributed to."
            labelPosition="top"
            required
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <ControlledLifeCycleInput
          decimal
          decimalPlaces={2}
          fieldDecimalPlaces={2}
          label="Split Head Count"
          name="quantity"
          {...DecimalNumberInputProps}
          required
          tooltip="The number of head to be attributed to the selected Vendor Split.  This can be a decimal number (eg 0.5) but the total of all splits must be a whole number (eg 0.66 + 0.34)."
        />
      </Grid>
      <VendorSplitLotBreakdown parentSaleLotId={parentSaleLotId} />
    </FormSectionGrid>
  );
}

function DeleteSaleLot(props) {
  const { closeSelf, saleLotId } = props;

  const dispatch = useDispatch();

  const saleLot = useSelector(getVendorSplitSaleLotById(saleLotId));

  // Don't block deleted.
  const deleteDisabled = !saleLotId;
  const [isConfirmDeleteOpen, setConfirmDeleteOpen, setConfirmDeleteClosed] =
    useBoolean(false);

  const onDelete = () => {
    // Maybe later, do we rebalance, or let them?  Currently, they get a warning for non balanced lots...
    dispatch(deleteSaleLot(saleLot));
    closeSelf();
  };

  return (
    <>
      <DeleteButton
        disabled={deleteDisabled}
        onClick={setConfirmDeleteOpen}
        type="button"
      >
        Delete
      </DeleteButton>
      <ConfirmDialog
        title={createModalTitle("this Vendor Split")}
        message="This will delete the Vendor Split and cannot be undone."
        isOpen={isConfirmDeleteOpen}
        onCancel={setConfirmDeleteClosed}
        onDelete={onDelete}
      />
    </>
  );
}

export function EditVendorSplitSaleLotModal({
  parentSaleLotId,
  saleLotId,
  onClose,
}) {
  const dispatch = useDispatch();
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const vendorSplitSaleLot =
    useSelector(getVendorSplitSaleLotById(saleLotId)) || {};
  const parentSaleLot = useSelector(getSaleLotById(parentSaleLotId)) || {};
  const maxSubLotNumber = useSelector(
    getMaxVendorSplitSubLotNumberByParentSaleLotId(parentSaleLotId),
  );

  const initialValues = {};
  if (saleLotId) {
    initialValues.consignmentId = vendorSplitSaleLot.consignment_id;
    initialValues.quantity = vendorSplitSaleLot.quantity;
  }

  const handleSubmit = values => {
    const { consignmentId, quantity } = values;
    if (saleLotId) {
      dispatch(
        patchSaleLot(
          {
            id: saleLotId,
            consignment_id: consignmentId,
            quantity,
          },
          {
            changeReason: "Updated from Edit Vendor Split.",
          },
        ),
      );
    } else {
      // TODO, pricing etc, offline.  For now, let the server handle I think?
      const payload = {
        ...parentSaleLot,
        parentId: parentSaleLot.id,
        consignment_id: consignmentId,
        quantity,
        saleLotType: SaleLotType.VENDOR_SPLIT,
        // This is optimistic.  Likely to be right, but, may get changed by the backend.
        subLotNumber: maxSubLotNumber + 1,
      };
      dispatch(addSaleLotWithPens(uuidv4(), payload));
    }
    onClose();
  };

  return (
    <ZoomyDialog open onClose={onClose}>
      <WaitForSync
        requiredData={[
          ApiModel.SALE_LOTS,
          ApiModel.CONSIGNMENTS,
          ApiModel.BUSINESSES,
        ]}
      >
        <DialogTitle onClose={onClose}>
          {saleLotId === null ? "Create" : "Edit"} Vendor Split{" "}
          {saleLotId === null ? "" : getCombinedLotNumber(vendorSplitSaleLot)}
          <br />
          Split From Lot {getCombinedLotNumber(parentSaleLot)} (
          {parentSaleLot.quantity} Hd)
        </DialogTitle>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
        >
          <Form>
            <DialogContent dividers>
              <EditVendorSplitSaleLotForm
                setIsSubmitEnabled={setIsSubmitEnabled}
                parentSaleLotId={parentSaleLotId}
              />
            </DialogContent>

            <DialogActions>
              <SecondaryButton onClick={onClose}>Back</SecondaryButton>

              <DeleteSaleLot closeSelf={onClose} saleLotId={saleLotId} />
              <Button
                data-tour={saleLotId === null ? "create" : "save"}
                type="submit"
                disabled={!isSubmitEnabled}
              >
                {saleLotId === null ? "Create" : "Save"}
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </WaitForSync>
    </ZoomyDialog>
  );
}
