import React from "react";

import {
  faStar as faStarSolid,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutlined } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Clickable } from "components/Button";
import { LinkFileTypeDownload } from "components/Link";
import { userTypeConfigMap } from "components/UserRoles/constants";

import { ForEveryoneExceptBusinessUsersAndSaleWatcher } from "containers/ForUserType";

import { openEmailReportModal } from "lib/navigation";

import { getActiveRole, getReportFavourites } from "selectors";

const SendEmail = styled(Clickable)(
  ({ theme }) => `
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.bold};
`,
);

const Favourite = styled(SendEmail)(
  ({ theme }) => `
  margin-right: ${theme.space[1]}px;
`,
);

const FavouriteIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`;

const CantSendEmail = styled.div`
  width: 46px;
`;

const EmailLabel = styled.span`
  text-decoration: underline;
  margin-left: ${({ theme }) => theme.space[1]}px;
`;

export const ReportTabActions = ({ row }) => {
  const {
    name,
    business_id,
    url,
    file_type,
    internal,
    allow_manual_send,
    deployment_id,
  } = row;
  return (
    <>
      <LinkFileTypeDownload
        id={name}
        url={url}
        fileType={file_type || "pdf"}
        internal={internal}
      />
      {allow_manual_send ? (
        <SendEmail
          onClick={() =>
            openEmailReportModal(url, name, business_id, deployment_id, null)
          }
        >
          <FontAwesomeIcon icon={faPaperPlane} />
          <EmailLabel>Send</EmailLabel>
        </SendEmail>
      ) : (
        <CantSendEmail />
      )}
    </>
  );
};

export const ReportFavouriteButton = ({ slug }) => {
  const userRole = useSelector(getActiveRole);
  const reportFavourites = useSelector(getReportFavourites);
  const dispatch = useDispatch();
  const isFavourite = reportFavourites?.includes(slug) || null;

  function toggleFavourite(slug, userRole, isFavourite) {
    const action = isFavourite
      ? userTypeConfigMap[userRole.type].action.removeReportFavourite
      : userTypeConfigMap[userRole.type].action.addReportFavourite;

    dispatch(action(userRole.id, slug));
  }
  return (
    <ForEveryoneExceptBusinessUsersAndSaleWatcher>
      <Favourite
        data-tour="favourite"
        onClick={() => toggleFavourite(slug, userRole, isFavourite)}
      >
        <FavouriteIcon icon={isFavourite ? faStarSolid : faStarOutlined} />
      </Favourite>
    </ForEveryoneExceptBusinessUsersAndSaleWatcher>
  );
};
