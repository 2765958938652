import React from "react";

import { SlimButton } from "components/Button/index";

import { openPhoneSupport, supportPhoneNumber } from "lib/navigation";

export function PhoneSupportButton() {
  return (
    <SlimButton onClick={openPhoneSupport}>{supportPhoneNumber}</SlimButton>
  );
}
