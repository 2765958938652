import { EmbeddedAnalytics } from "components/Analytics/EmbeddedAnalytics";
import React, { useEffect, useState } from "react";

import { fetchWithFullyQualifiedUrl } from "lib/fetch";
import toast from "lib/toast";

import { Box } from "@material-ui/core";

import { TitleText } from "components/Text";
import LoadingSpinner from "components/LoadingSpinner";
import { CenteredGreedy } from "components/Layout";
import { useSelector } from "react-redux";
import { getActiveRole, getCurrentUser } from "selectors";
import { ROLE_TYPES } from "constants/users";

type PropTypes = {
  isMatch: boolean;
};
export const AnalyticsHome = ({ isMatch }: PropTypes) => {
  const [billingTransactionsEmbedUrl, setBillingTransactionsEmbedUrl] =
    useState(null);

  const user = useSelector(getCurrentUser);
  const activeRole = useSelector(getActiveRole);
  const displayAnalytics =
    user.allow_analytics && activeRole.type === ROLE_TYPES.STOCK_AGENT;

  useEffect(() => {
    async function fetchEmbedUrl() {
      const url = "/v2/analytics/billing-transactions-embed-url/";
      const response = await fetchWithFullyQualifiedUrl(url, {
        headers: {
          "X-User-Role": activeRole.slug,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const { embed_url: embedUrl } = data;
        setBillingTransactionsEmbedUrl(embedUrl);
      } else {
        toast.error("Unable to load the analytics.");
      }
    }

    if (!billingTransactionsEmbedUrl && isMatch && displayAnalytics)
      fetchEmbedUrl();
  });

  return (
    user.allow_analytics && (
      <>
        {isMatch && (
          <Box height={isMatch ? undefined : 0} p={2}>
            <TitleText>Analytics</TitleText>
          </Box>
        )}
        {/*
         Note that we render the analytics component in a hidden state, even when on 
         a non-matching route.  This is to prevent un-mounting so that the user can
         return to their analytics session quickly, without losing any changes
        */}
        {billingTransactionsEmbedUrl ? (
          <EmbeddedAnalytics
            url={billingTransactionsEmbedUrl}
            hidden={!isMatch}
          />
        ) : (
          isMatch && (
            <CenteredGreedy>
              <LoadingSpinner size={50} subjectName="Analytics" />
            </CenteredGreedy>
          )
        )}
      </>
    )
  );
};
