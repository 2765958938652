import reduceReducers from "reduce-reducers";

import { BUSINESS, DEPLOYMENT, INTEREST_SETTING } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeInterestSettings } from "lib/serializers/interest";

const fetch = apiModelOfflineFetchReducer(INTEREST_SETTING, {
  deserializer: deserializeInterestSettings,
});

function businessReducer(state, action) {
  switch (action.type) {
    case BUSINESS.UPDATE.REQUEST:
    case BUSINESS.CREATE.REQUEST: {
      const { payload } = action;
      const { interest_settings, id: businessId } = payload;
      if (Array.isArray(interest_settings) && businessId) {
        const newInterestSettingsById = interest_settings.reduce(
          (byId, interestSetting) => {
            byId[interestSetting.id] = deserializeInterestSettings({
              ...interestSetting,
              businessId,
            });
            return byId;
          },
          {},
        );

        const byId = Object.values(state.byId).reduce(
          (acc, interestSetting) => {
            // Re-add if:
            // not in the new dataset
            // not linked to the deployment business
            // not linked to any deployment business (ie, linked to a deployment)
            if (
              !acc[interestSetting.id] &&
              (!interestSetting.businessId ||
                interestSetting.businessId !== businessId)
            ) {
              // Not in the payload and tied to this deployment business
              acc[interestSetting.id] = interestSetting;
            }

            return acc;
          },
          newInterestSettingsById,
        );
        return {
          ...state,
          byId,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

function deploymentReducer(state, action) {
  switch (action.type) {
    case DEPLOYMENT.UPDATE.REQUEST: {
      const { payload } = action;
      const { interest_settings, id } = payload;
      if (Array.isArray(interest_settings) && id) {
        const newInterestSettingsById = interest_settings.reduce(
          (byId, interestSetting) => {
            byId[interestSetting.id] = deserializeInterestSettings({
              ...interestSetting,
              deployment_id: id,
            });
            return byId;
          },
          {},
        );

        const byId = Object.values(state.byId).reduce(
          (acc, interestSetting) => {
            // Re-add if:
            // not in the new dataset
            // not linked to the deployment
            // not linked to any deployment (ie, linked to a deployment business)
            if (
              !acc[interestSetting.id] &&
              (!interestSetting.deploymentId ||
                interestSetting.deploymentId !== id)
            ) {
              // Not in the payload and tied to this deployment
              acc[interestSetting.id] = interestSetting;
            }

            return acc;
          },
          newInterestSettingsById,
        );
        return {
          ...state,
          byId,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

const interestSettingsReducer = reduceReducers(
  fetch,
  businessReducer,
  deploymentReducer,
);

export default interestSettingsReducer;
