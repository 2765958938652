import reduceReducers from "reduce-reducers";

import { PATCH_CONSIGNMENT_OFFLINE, RECEIVAL_LOT } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineCommentReducer,
} from "lib/reducers";
import { deserializeReceivalLot } from "lib/serializers/receivalLots";

const create = apiModelOfflineCreateReducer(RECEIVAL_LOT, {
  deserializer: deserializeReceivalLot,
});

const deleteReducer = apiModelOfflineDeleteReducer(RECEIVAL_LOT);

const fetch = apiModelOfflineFetchReducer(RECEIVAL_LOT, {
  deserializer: deserializeReceivalLot,
});

const update = apiModelOfflineUpdateReducer(RECEIVAL_LOT, {
  deserializer: deserializeReceivalLot,
});

const comment = apiModelOfflineCommentReducer(RECEIVAL_LOT);
function updateConsignmentReducer(state, action) {
  switch (action.type) {
    case PATCH_CONSIGNMENT_OFFLINE: {
      const {
        payload,
        meta: { id: consignmentId },
      } = action;
      const { receival_lot_ids: receivalLotIds } = payload;
      let receivalLotUpdates = {};
      if (receivalLotIds) {
        receivalLotUpdates = Object.values(state.byId).reduce(
          (acc, receivalLot) => {
            const receivalLotIsInPayload = receivalLotIds.includes(
              receivalLot.id,
            );
            if (receivalLotIsInPayload) {
              // Handling adding receival lots to consignments
              acc[receivalLot.id] = {
                ...state.byId[receivalLot.id],
                consignmentId,
              };
            } else if (receivalLot.consignmentId === consignmentId) {
              // Handling removing receival lots from consignments
              acc[receivalLot.id] = {
                ...receivalLot,
                consignmentId: null,
              };
            }

            return acc;
          },
          {},
        );
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          ...receivalLotUpdates,
        },
      };
    }
    default:
      return state;
  }
}

const receivalLotReducer = reduceReducers(
  create,
  comment,
  deleteReducer,
  fetch,
  update,
  updateConsignmentReducer,
);

export default receivalLotReducer;
