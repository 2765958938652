import React, { memo, useState } from "react";

import { useCombobox } from "downshift";
import Fuse from "fuse.js";

import {
  InputContainer,
  InputDropdownSelect,
  InputDrowdownButton,
  SelectControlContainer,
  SelectItemNoOptions,
  SelectMenu,
  SelectMenuContainer,
} from "components/SearchableSelector/ReactSelect";

import { getAuctionsPlusItemsByManufacturer } from "constants/auctionsPlusSchema";

import { caseInsensitiveCompare } from "lib/compare";

import { AuctionsPlusMakeResultsList } from "./ResultsList";

export const MAKES = Object.keys(getAuctionsPlusItemsByManufacturer()).sort(
  caseInsensitiveCompare,
);

const makesFuse = new Fuse(MAKES, {
  distance: 50,
  includeMatches: false,
  includeScore: false,
  minMatchCharLength: 1,
  threshold: 0.3,
});

function AuctionsPlusMakeInputComponent(props) {
  const { value, onSelectMake } = props;

  const [filteredResults, setFilteredResults] = useState(MAKES);

  const onInputValueChange = props => {
    const { inputValue } = props;
    if (inputValue.length === 0) {
      setFilteredResults(MAKES);
      // We don't have this until we've called useComboBox, but, we don't call onInputValueChange until
      // selectItem is pulled into scope.  So.  Broken, but, works.
      // eslint-disable-next-line no-use-before-define
      selectItem(null);
    } else {
      const results = makesFuse.search(inputValue, { limit: 150 });
      if (Array.isArray(results)) {
        setFilteredResults(results.map(result => result.item));
      }
    }
  };

  const onSelectedItemChange = ({ selectedItem }) => {
    typeof onSelectMake === "function" && onSelectMake(selectedItem);
  };

  const {
    getToggleButtonProps,
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
    selectItem,
  } = useCombobox({
    selectedItem: value,
    items: filteredResults,
    onInputValueChange,
    onSelectedItemChange,
  });

  return (
    <SelectControlContainer>
      <InputContainer {...getComboboxProps()}>
        <InputDropdownSelect
          {...getInputProps()}
          placeholder="Select..."
          type="search"
        />
        <InputDrowdownButton
          type="button"
          {...getToggleButtonProps()}
          value="&#x25BC;"
        />
      </InputContainer>
      <SelectMenuContainer isHidden={!isOpen}>
        <SelectMenu {...getMenuProps()}>
          <AuctionsPlusMakeResultsList
            inputItems={filteredResults}
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
          />
          {isOpen && filteredResults.length === 0 && (
            <SelectItemNoOptions>No options</SelectItemNoOptions>
          )}
        </SelectMenu>
      </SelectMenuContainer>
    </SelectControlContainer>
  );
}

export const AuctionsPlusMakeInput = memo(AuctionsPlusMakeInputComponent);
