import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const nominationDetailSerializeMap = {
  quantity: "quantity",
  nominationTermId: "nomination_term_id",
  notes: "notes",
  estimatedWeightGrams: "estimated_weight_grams",
  breedId: "breed_id",
  sexId: "sex_id",
  ageId: "age_id",
};

const nominationSerializeMap = {
  commentIds: "comment_ids",
  consigningDeploymentId: "consigning_deployment_id",
  deploymentId: "deployment_id",
  hasArrived: "has_arrived",
  livestockSaleId: "livestock_sale_id",
  nominationDetails: nominationDetailSerializeMap,
  permissions: "permissions",
  propertyId: "property_id",
  transporterId: "transporter_id",
  vendorId: "vendor_id",
};

const nestedNominationSerializeMap = {
  nomination_details: "nominationDetails",
};

export const serializeNomination = buildSerializer(
  nominationSerializeMap,
  "nomination",
  nestedNominationSerializeMap,
);

export const deserializeNomination = buildDeserializer(
  nominationSerializeMap,
  "nomination",
  nestedNominationSerializeMap,
  {
    commentIds: [],
    livestockSaleId: null,
    nominationDetails: [],
  },
);
