import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  Autocomplete,
  OptionTogglerField,
  SelectField,
  getOptionAsValue,
} from "components/Form/FormikControls";

import { getSaleRoundsOptions } from "selectors";

export function SaleRoundPickerField(props) {
  const {
    disabled,
    name,
    required,
    tooltip,
    forceAutoComplete = false,
  } = props;

  const rounds = useSelector(getSaleRoundsOptions());

  if (forceAutoComplete) {
    return (
      <Autocomplete
        disabled={disabled}
        label="Sale Round"
        name={name}
        options={rounds}
        required={required}
        tooltip={tooltip}
        getOptionValue={getOptionAsValue}
      />
    );
  }

  return rounds.length <= 3 ? (
    <OptionTogglerField
      label="Sale Round"
      name={name}
      labelPosition="top"
      options={rounds}
      required={required}
      disabled={disabled}
      tooltip={tooltip}
    />
  ) : (
    <SelectField
      disabled={disabled}
      label="Sale Round"
      name={name}
      options={rounds}
      required={required}
      tooltip={tooltip}
    />
  );
}

SaleRoundPickerField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
