import React from "react";

import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@material-ui/core";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import startCase from "lodash/startCase";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Column, Row } from "components/Layout";
import MarkingInfo from "components/MarkingInfo";

import { AuctionScreenMode } from "constants/auctionModes";
import { pricingTypeString } from "constants/pricingTypes";
import { saleLotStatuses } from "constants/sale";
import { Settings } from "constants/settings";

import { firstIfAllEqual, getUnitPriceString } from "lib";

import {
  getCombinedLotNumber,
  getProgenyCountFromSaleLots,
  getProgenyDisplayCount,
} from "lib/saleLot";
import { formatUTCToLocalHighResDayTimeString } from "lib/timeFormats";

import { getSetting, selectAreStudFeaturesEnabled } from "selectors";

import { useTheme } from "hooks";

import {
  GridStateInfo,
  SoldGrid,
  StyledHeadcount,
  UnsoldGrid,
} from "./UnsoldGrid";

const Wrapper = styled(Column)`
  flex: 1;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  cursor: pointer;
  display: flex;
  background: white;
  line-height: normal;
  border-radius: ${({ isSold }) => (isSold ? "25px 0 0 25px" : "0px")};
  height: 100%;
  ${({ color, selected, theme }) => {
    const selectedColor = selected && color && theme.colors[color];
    const fontColor = selectedColor || "#4a4a4a";
    const borderColor = selected
      ? selectedColor || theme.colors.primary
      : "#f8f8f8;";

    return `
      color: ${fontColor};
      border-color: ${borderColor};
    `;
  }};
`;

const Checkbox = styled.div(
  ({ theme, hasModeInfo, shape }) => `
  width: 50px;
  min-height: 50px;
  height: 100%;
  border-radius: ${shape === "circle" ? "25px" : "0px"};
  margin-right: 1rem;
  border: 2px solid #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  box-shadow: ${theme.shadows[1]};
  ${hasModeInfo ? `grid-area: checkbox;` : ""}
`,
);

const OverflowEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ noWrap }) => (noWrap ? "white-space: nowrap;" : "")}
`;

const Progeny = styled.div`
  font-size: 10px;
`;

export const TagWrapper = styled.h5`
  margin: 0px;
  font-weight: normal;
`;

const getSaleLotDescription = saleLots => {
  if (saleLots.length === 1) {
    const saleLot = saleLots[0];
    const speciesInfo = [];
    // Summary of the species info of the sale lot.
    // For now, just show the sex name.
    ["sex"].forEach(group => {
      if (saleLot && saleLot[group] && saleLot[group].name) {
        speciesInfo.push(startCase(saleLot[group].name));
      }
    });
    return speciesInfo.join(" - ") || "-";
  } else {
    return `${saleLots.length} drafts`;
  }
};

const SaleLotSummaryCard = ({
  selected,
  setSelectedSaleLots,
  selectable,
  saleState,
  saleLots,
}) => {
  const handleClick = () => {
    selectable && setSelectedSaleLots(saleLots.map(saleLot => saleLot.id));
  };

  const selectColorByState = saleState => {
    switch (saleState) {
      case saleLotStatuses.SOLD:
        return "success";
      case saleLotStatuses.NO_SALE:
        return "error";
      default:
        return "";
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints[0]}px)`);

  const isSold =
    saleState === saleLotStatuses.SOLD || saleState === saleLotStatuses.NO_SALE;

  const marks = saleLots.length === 1 ? saleLots[0].marks : [];
  const vendorLabels = saleLots.map(
    s => `${s.vendor.name} (Ven.# ${s.consignment.vendorNumber || "N/A"})`,
  );
  const vendorCount = [...new Set(vendorLabels)].length;

  const vendorName = firstIfAllEqual(
    saleLots.map(s => s.vendor.name),
    `${vendorCount} Vendors`,
  );
  const vendorNumber = firstIfAllEqual(
    saleLots.map(s => s.consignment.vendorNumber || "N/A"),
    `${vendorCount} Vendors`,
  );
  const lotNumber = firstIfAllEqual(
    saleLots.map(s => getCombinedLotNumber(s) || "N/A"),
    null,
  );

  const quantity = saleLots.reduce(
    (sum, saleLot) => (sum += saleLot.quantity),
    0,
  );

  const quantityProgeny = getProgenyCountFromSaleLots(saleLots);

  const GridComponent = isSold ? SoldGrid : UnsoldGrid;

  const buyerWay = saleLots[0].buyer_way && saleLots[0].buyer_way.name;

  const tagNumber =
    saleLots.length === 1 ? saleLots[0]?.draftingAttributes?.tagNumber : null;

  const title = (
    <p>
      {vendorName && `VENDOR : ${vendorName}`}
      <br />
      {`SEX : ${getSaleLotDescription(saleLots)}`}
      <br />
      {saleLots[0] &&
        saleLots[0].buyer &&
        saleLots[0].buyer.name &&
        `BUYER: ${saleLots[0].buyer.name}`}
      <br />
      {buyerWay && `WAY : ${buyerWay}`}
    </p>
  );
  const mode = useSelector(getSetting(Settings.auctionScreenMode));
  const hasModeInfo = [
    AuctionScreenMode.Weigh,
    AuctionScreenMode.LastSeen,
  ].includes(mode);
  const areStudFeaturesEnabled = useSelector(selectAreStudFeaturesEnabled);
  return (
    <Wrapper
      selected={selected}
      onClick={handleClick.bind()}
      color={selectColorByState(saleState)}
      isSold={isSold}
      data-tour={`saleLot.${lotNumber}`}
    >
      <Row justifyBetween>
        <GridComponent hasModeInfo={hasModeInfo}>
          {mode === AuctionScreenMode.Weigh ? (
            <GridStateInfo>
              {saleLots[0].timeWeighed
                ? `Weighed ${formatUTCToLocalHighResDayTimeString(
                    saleLots[0].timeWeighed,
                  )}`
                : "UnWeighed"}
            </GridStateInfo>
          ) : null}
          {mode === AuctionScreenMode.LastSeen ? (
            <GridStateInfo>
              {saleLots[0].lastSeenAtTime
                ? `Weighed ${formatUTCToLocalHighResDayTimeString(
                    saleLots[0].lastSeenAtTime,
                  )}`
                : "UnWeighed"}
            </GridStateInfo>
          ) : null}

          <Checkbox
            shape={isSold ? "circle" : "square"}
            hasModeInfo={hasModeInfo}
          >
            {selected && (
              <FontAwesomeIcon
                size="2x"
                icon={faCheck}
                style={{ color: "#40C1AC" }}
              />
            )}
          </Checkbox>
          {isSold ? (
            <>
              {!isMobile ? (
                <OverflowEllipsis noWrap={hasModeInfo}>
                  {vendorName}
                </OverflowEllipsis>
              ) : null}
            </>
          ) : (
            <div>{vendorName}</div>
          )}
          <OverflowEllipsis>{vendorNumber}</OverflowEllipsis>
          <div>{lotNumber}</div>
          {isMobile ? (
            <OverflowEllipsis>
              {getSaleLotDescription(saleLots)}
            </OverflowEllipsis>
          ) : (
            <div>{getSaleLotDescription(saleLots)}</div>
          )}
          <Column>
            <Row>
              {orderBy(marks, ["location", "color"]).map(mark => (
                <MarkingInfo
                  key={`${mark.color}-${mark.location}`}
                  color={mark.color}
                  location={mark.location}
                />
              ))}
            </Row>
            {areStudFeaturesEnabled && tagNumber && (
              <TagWrapper>Tag # {tagNumber} </TagWrapper>
            )}
          </Column>
          <StyledHeadcount>
            {quantity}
            <Progeny>{getProgenyDisplayCount(quantityProgeny)}</Progeny>
          </StyledHeadcount>

          {isSold && (
            <>
              {!isMobile ? (
                <>
                  <OverflowEllipsis noWrap={hasModeInfo}>
                    {saleLots[0].buyer.name}
                  </OverflowEllipsis>

                  <OverflowEllipsis noWrap={hasModeInfo}>
                    {buyerWay}
                  </OverflowEllipsis>
                </>
              ) : null}
              <div>
                {/* // Will only show LivestockSale (Not Clearing Sale) Pricing Type display names */}
                {`${getUnitPriceString(saleLots[0])} ${pricingTypeString()(
                  saleLots[0].pricing_type_id,
                )}`}
              </div>
              {isMobile && (
                <Tooltip clickable arrow placement="top-end" title={title} />
              )}
            </>
          )}
        </GridComponent>
      </Row>
    </Wrapper>
  );
};

SaleLotSummaryCard.propTypes = {
  selected: PropTypes.bool,
  setSelectedSaleLots: PropTypes.func,
  selectable: PropTypes.bool,
  saleState: PropTypes.oneOf([
    saleLotStatuses.SOLD,
    saleLotStatuses.NOT_SOLD,
    saleLotStatuses.NO_SALE,
  ]),
  saleLots: PropTypes.array.isRequired,
};

SaleLotSummaryCard.defaultsProps = {
  saleState: saleLotStatuses.NOT_SOLD,
  selectable: false,
  selected: false,
};

// Stop unnecessary renders
const areEqual = (prevProps, nextProps) => {
  if (!isEqual(prevProps.saleLots, nextProps.saleLots)) {
    return false;
  }

  return !["selected", "saleState"].some(
    prop => prevProps[prop] !== nextProps[prop],
  );
};

export default React.memo(SaleLotSummaryCard, areEqual);
