import React, { useCallback, useMemo } from "react";

import { Grid } from "@material-ui/core";
import { getIn } from "formik";
import { useSelector } from "react-redux";

import {
  Input,
  SelectField,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";

import { groupedLineItemToolTipText } from "constants/billing";
import {
  RuleValueFormatOptionsByRawValueFormat,
  ValueSource,
} from "constants/ruleBooks";

import { FieldValueType } from "containers/Settings/RuleBooks/constants";
import {
  compareDynamicFieldOption,
  getSchemaFieldValues,
  mapOptionToConstantFieldOption,
} from "containers/Settings/RuleBooks/lib";
import { RuleOutputFormatField } from "containers/Settings/RuleBooks/Rules/CustomRules/RuleOutputFormatField";
import { useRuleFieldSchema } from "containers/Settings/RuleBooks/schemaContext";

import { EMPTY_OBJECT } from "lib";

import {
  selectCustomisationsByRuleIdLookup,
  selectLedgerAccountOptionsForRuleBuilder,
} from "selectors";

import { useFieldValue } from "hooks";

export function CustomiseManagedRuleForm(props) {
  const { namespace: ns, rootFieldId, ruleId } = props;

  const schema = useRuleFieldSchema();

  const LedgerAccountOptions = useSelector(
    selectLedgerAccountOptionsForRuleBuilder,
  );

  const value = useFieldValue(ns);

  const unitAmountRawFormat = getIn(value, "unit_amount_raw_format");
  const unitAmountOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[unitAmountRawFormat] || [];

  const quantityRawFormat = getIn(value, "quantity_raw_format");
  const quantityOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[quantityRawFormat] || [];

  // Auto GL Code can be used when the "rootFieldId" in the schema has a `gl_code` type property.
  const glCodeProperty = getSchemaFieldValues(
    rootFieldId,
    schema,
    false,
    false,
  ).find(schemaField => schemaField.type === FieldValueType.GL_CODE);

  const automaticGlCodeOptions = useMemo(
    () =>
      glCodeProperty
        ? [
            {
              label: "Automatically determined",
              value: {
                source: ValueSource.FIELD,
                value: glCodeProperty.via || glCodeProperty.id,
              },
            },
          ]
        : [],
    [glCodeProperty],
  );

  const allLedgerAccountOptions = useMemo(
    () =>
      automaticGlCodeOptions.concat(
        LedgerAccountOptions.map(mapOptionToConstantFieldOption),
      ),
    [automaticGlCodeOptions, LedgerAccountOptions],
  );

  const customisedFields =
    useSelector(selectCustomisationsByRuleIdLookup)[ruleId] || EMPTY_OBJECT;

  const getAltToolTip = useCallback(
    field => {
      if (value?.[field] && customisedFields?.[field]) {
        return `Overrides default value "${customisedFields[field]}"`;
      }
    },
    [customisedFields, value],
  );

  return (
    <>
      <Grid item xs={12}>
        <Input
          name={withNamespace(ns, "name")}
          label="Name"
          placeholder={customisedFields.name}
          altTooltip={getAltToolTip("name")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextArea
          name={withNamespace(ns, "comment")}
          label="Comment"
          placeholder={customisedFields.comment}
          altTooltip={getAltToolTip("comment")}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          name={withNamespace(ns, "title_template")}
          label="Title Template"
          altTooltip={getAltToolTip("title_template")}
          placeholder={customisedFields.title_template}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          name={withNamespace(ns, "invoice_line_item_template")}
          label="Document Grouped Line Description"
          tooltip={groupedLineItemToolTipText}
          altTooltip={getAltToolTip("invoice_line_item_template")}
          placeholder={customisedFields.invoice_line_item_template}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectField
          altTooltip={getAltToolTip("gl_code")}
          comparer={compareDynamicFieldOption}
          data-tour="glCode"
          label="GL Code"
          menuPortalTarget={document.body}
          name={withNamespace(ns, "gl_code")}
          options={allLedgerAccountOptions}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <RuleOutputFormatField
          altTooltip={getAltToolTip("quantity_output_format")}
          label="Units Format"
          name="quantity_output_format"
          options={quantityOutputFormatOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <RuleOutputFormatField
          altTooltip={getAltToolTip("unit_amount_output_format")}
          label="Unit Price Format"
          name="unit_amount_output_format"
          options={unitAmountOutputFormatOptions}
        />
      </Grid>
    </>
  );
}
