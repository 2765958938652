import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { MainContentWrapper, Row } from "components/Layout";

const TextBox = styled(Row)(
  ({ theme }) => `
  color: ${theme.colors.gray44};
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  padding: 36px;
  height: 324px;
`,
);

const MessageBox = ({ children }) => (
  <MainContentWrapper>
    <TextBox justifyCenter alignCenter>
      {children}
    </TextBox>
  </MainContentWrapper>
);

MessageBox.propTypes = {
  children: PropTypes.any,
};

export default MessageBox;
