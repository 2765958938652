import { buffers } from "redux-saga";
import { actionChannel, call, put, take, select } from "redux-saga/effects";

import { uploadProgress, uploadSuccess, uploadFailure } from "actions";

import { UPLOAD_REQUEST } from "constants/actionTypes";

import { getSaleUrl } from "lib/navigation";
import toast from "lib/toast";

import { currentSaleSelector } from "selectors";

import { refreshJWTAndRetry } from "sagas/api";

import { createUploadFileChannel } from "./createUploadFileChannel";

function* uploadFile(action) {
  const {
    meta: { file },
    id,
    formData,
  } = action;

  const state = yield select();
  const currentSale = currentSaleSelector(state);
  const url = `${getSaleUrl(currentSale)}/attachments/`;
  const { auth } = yield select();
  const channel = yield call(
    createUploadFileChannel,
    url,
    file,
    formData,
    auth,
  );
  while (true) {
    const {
      progress = 0,
      err,
      status_code,
      success,
      response,
    } = yield take(channel);
    if (err) {
      if (status_code && status_code === 401) {
        // We failed!
        // Ask for a new JWT token, and wait until it was successful before
        // trying this action again.
        yield call(refreshJWTAndRetry, action);
      } else if (status_code && status_code === 413) {
        toast.error(
          `Error uploading ${action.meta.file.name}, file too large.`,
        );
        yield put(uploadFailure(id, err));
      } else {
        toast.error(`Error uploading ${action.meta.file.name}!`);
        yield put(uploadFailure(id, err));
      }

      return;
    }
    if (success) {
      toast.success(`${action.meta.file.name} uploaded successfully.`);
      yield put(uploadSuccess(id, response));
      return;
    }
    yield put(uploadProgress(id, progress));
  }
}

function* uploadFileSaga() {
  const buffer = buffers.expanding(50);
  const requestChannel = yield actionChannel(UPLOAD_REQUEST, buffer);
  while (true) {
    const payload = yield take(requestChannel);
    yield call(uploadFile, payload);
  }
}

export default function* rootSaga() {
  yield uploadFileSaga();
}
