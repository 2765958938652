// function compact(data) {
//   const compactedData = [];
//
//   if(!Array.isArray(data) || data.length < 2) {
//     return compactedData;
//   }
//
//   let prevVal = data[0];
//   let occurrences = 0;
//   let i = 0;
//   do {
//     occurrences++;
//     i++;
//     const currVal = data[i];
//     if(prevVal !== currVal) {
//       if(occurrences > 1) {
//         compactedData.push([prevVal, occurrences]);
//       } else {
//         compactedData.push(prevVal);
//       }
//       prevVal = currVal;
//       occurrences = 0;
//     }
//   } while (i < data.length);
//
//   return compactedData;
// }

function expand(data) {
  const expandedData = [];

  if (!Array.isArray(data) || data.length < 0) {
    return expandedData;
  }

  for (let i = 0; i < data.length; i++) {
    const instruction = data[i];
    if (!Array.isArray(instruction)) {
      expandedData.push(instruction);
    } else {
      const [value, occurrences] = instruction;
      for (let j = 0; j < occurrences; j++) {
        expandedData.push(value);
      }
    }
  }

  return expandedData;
}

const compactedLogo = [
  [[0, 232]],
  [
    [0, 57],
    [1, 4],
    [0, 171],
  ],
  [
    [0, 57],
    [1, 4],
    [0, 171],
  ],
  [
    [0, 57],
    [1, 4],
    [0, 171],
  ],
  [[0, 57], [1, 4], [0, 79], 1, [0, 91]],
  [
    [0, 57],
    [1, 4],
    [0, 78],
    [1, 3],
    [0, 90],
  ],
  [
    [0, 57],
    [1, 4],
    [0, 78],
    [1, 3],
    [0, 90],
  ],
  [[0, 57], [1, 4], [0, 79], 1, [0, 91]],
  [[0, 49], 1, [0, 7], [1, 4], [0, 7], 1, [0, 163]],
  [
    [0, 48],
    [1, 3],
    [0, 6],
    [1, 4],
    [0, 6],
    [1, 3],
    [0, 162],
  ],
  [
    [0, 47],
    [1, 5],
    [0, 5],
    [1, 4],
    [0, 5],
    [1, 4],
    [0, 28],
    1,
    [0, 17],
    1,
    [0, 36],
    [1, 3],
    [0, 16],
    [1, 4],
    [0, 35],
    [1, 3],
    [0, 18],
  ],
  [
    [0, 47],
    [1, 5],
    [0, 5],
    [1, 4],
    [0, 4],
    [1, 6],
    [0, 23],
    [1, 8],
    [0, 2],
    1,
    [0, 8],
    [1, 7],
    [0, 2],
    1,
    [0, 5],
    [1, 2],
    0,
    [1, 5],
    [0, 4],
    1,
    [0, 4],
    [1, 14],
    [0, 10],
    [1, 10],
    [0, 7],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 5],
    [1, 10],
    [0, 14],
  ],
  [
    [0, 46],
    [1, 6],
    [0, 5],
    [1, 4],
    [0, 5],
    [1, 5],
    [0, 22],
    [1, 3],
    [0, 5],
    [1, 4],
    [0, 6],
    [1, 3],
    [0, 5],
    [1, 4],
    [0, 5],
    [1, 4],
    [0, 8],
    1,
    [0, 4],
    [1, 15],
    [0, 8],
    [1, 12],
    [0, 6],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 4],
    [1, 11],
    [0, 14],
  ],
  [
    [0, 46],
    [1, 5],
    [0, 6],
    [1, 4],
    [0, 6],
    [1, 5],
    [0, 20],
    [1, 2],
    [0, 8],
    [1, 3],
    [0, 5],
    [1, 2],
    [0, 9],
    [1, 2],
    [0, 5],
    [1, 3],
    [0, 9],
    1,
    [0, 4],
    [1, 16],
    [0, 6],
    [1, 14],
    [0, 5],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 3],
    [1, 11],
    [0, 15],
  ],
  [
    [0, 46],
    [1, 5],
    [0, 5],
    [1, 6],
    [0, 5],
    [1, 5],
    [0, 19],
    [1, 2],
    [0, 10],
    [1, 2],
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 5],
    [1, 2],
    [0, 10],
    1,
    [0, 4],
    [1, 7],
    [0, 3],
    [1, 6],
    [0, 5],
    [1, 7],
    [0, 2],
    [1, 7],
    [0, 4],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 3],
    [1, 5],
    [0, 4],
    1,
    [0, 16],
  ],
  [
    [0, 45],
    [1, 5],
    [0, 5],
    [1, 7],
    [0, 5],
    [1, 5],
    [0, 19],
    1,
    [0, 12],
    1,
    [0, 4],
    [1, 2],
    [0, 11],
    1,
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 7],
    [1, 5],
    [0, 4],
    [1, 5],
    [0, 6],
    [1, 5],
    [0, 4],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 3],
    [1, 5],
    [0, 21],
  ],
  [
    [0, 45],
    [1, 5],
    [0, 5],
    [1, 8],
    [0, 5],
    [1, 4],
    [0, 18],
    [1, 2],
    [0, 12],
    1,
    [0, 4],
    1,
    [0, 12],
    1,
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 7],
    [1, 5],
    [0, 3],
    [1, 5],
    [0, 8],
    [1, 5],
    [0, 3],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 3],
    [1, 6],
    [0, 20],
  ],
  [
    [0, 45],
    [1, 5],
    [0, 5],
    [1, 8],
    [0, 5],
    [1, 4],
    [0, 18],
    [1, 2],
    [0, 12],
    1,
    [0, 4],
    1,
    [0, 12],
    1,
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 3],
    [1, 5],
    [0, 8],
    [1, 5],
    [0, 3],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 4],
    [1, 8],
    [0, 17],
  ],
  [
    [0, 45],
    [1, 5],
    [0, 5],
    [1, 8],
    [0, 5],
    [1, 4],
    [0, 18],
    [1, 2],
    [0, 12],
    1,
    [0, 3],
    [1, 2],
    [0, 12],
    1,
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 3],
    [1, 5],
    [0, 8],
    [1, 5],
    [0, 3],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 4],
    [1, 10],
    [0, 15],
  ],
  [
    [0, 45],
    [1, 5],
    [0, 6],
    [1, 6],
    [0, 5],
    [1, 5],
    [0, 18],
    [1, 2],
    [0, 12],
    1,
    [0, 3],
    [1, 2],
    [0, 12],
    1,
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 3],
    [1, 5],
    [0, 8],
    [1, 5],
    [0, 3],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 6],
    [1, 9],
    [0, 14],
  ],
  [
    [0, 46],
    [1, 5],
    [0, 5],
    [1, 5],
    [0, 6],
    [1, 5],
    [0, 18],
    [1, 2],
    [0, 12],
    1,
    [0, 4],
    1,
    [0, 12],
    1,
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 3],
    [1, 5],
    [0, 8],
    [1, 5],
    [0, 3],
    [1, 4],
    [0, 8],
    [1, 5],
    [0, 9],
    [1, 6],
    [0, 14],
  ],
  [
    [0, 46],
    [1, 5],
    [0, 16],
    [1, 5],
    [0, 19],
    1,
    [0, 12],
    1,
    [0, 4],
    [1, 2],
    [0, 11],
    1,
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 3],
    [1, 6],
    [0, 6],
    [1, 5],
    [0, 4],
    [1, 5],
    [0, 7],
    [1, 5],
    [0, 11],
    [1, 5],
    [0, 13],
  ],
  [
    [0, 46],
    [1, 6],
    [0, 14],
    [1, 6],
    [0, 19],
    [1, 2],
    [0, 11],
    1,
    [0, 4],
    [1, 2],
    [0, 11],
    1,
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 4],
    [1, 6],
    [0, 4],
    [1, 6],
    [0, 4],
    [1, 6],
    [0, 5],
    [1, 6],
    [0, 4],
    1,
    [0, 6],
    [1, 5],
    [0, 13],
  ],
  [
    [0, 47],
    [1, 5],
    [0, 13],
    [1, 6],
    [0, 21],
    [1, 2],
    [0, 9],
    [1, 2],
    [0, 5],
    [1, 2],
    [0, 9],
    [1, 2],
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 5],
    [1, 14],
    [0, 6],
    [1, 16],
    [0, 4],
    [1, 3],
    [0, 3],
    [1, 6],
    [0, 13],
  ],
  [
    [0, 47],
    [1, 6],
    [0, 11],
    [1, 7],
    [0, 21],
    [1, 3],
    [0, 7],
    [1, 3],
    [0, 6],
    [1, 2],
    [0, 7],
    [1, 3],
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 5],
    [1, 14],
    [0, 6],
    [1, 16],
    [0, 3],
    [1, 12],
    [0, 14],
  ],
  [
    [0, 48],
    [1, 7],
    [0, 8],
    [1, 7],
    [0, 24],
    [1, 11],
    [0, 7],
    [1, 11],
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 7],
    [1, 10],
    [0, 9],
    [1, 15],
    [0, 3],
    [1, 12],
    [0, 14],
  ],
  [
    [0, 49],
    [1, 20],
    [0, 26],
    [1, 6],
    [0, 3],
    1,
    [0, 9],
    [1, 5],
    [0, 3],
    1,
    [0, 5],
    1,
    [0, 11],
    1,
    [0, 4],
    [1, 5],
    [0, 8],
    [1, 4],
    [0, 8],
    [1, 7],
    [0, 13],
    [1, 7],
    0,
    [1, 5],
    [0, 4],
    [1, 9],
    [0, 16],
  ],
  [[0, 50], [1, 18], [0, 54], 1, [0, 87], [1, 3], [0, 19]],
  [[0, 51], [1, 16], [0, 55], 1, [0, 109]],
  [[0, 52], [1, 13], [0, 57], 1, [0, 109]],
  [[0, 55], [1, 8], [0, 59], 1, [0, 109]],
  [[0, 57], [1, 4], [0, 49], 1, [0, 10], [1, 2], [0, 109]],
  [
    [0, 57],
    [1, 4],
    [0, 48],
    [1, 3],
    [0, 8],
    [1, 2],
    [0, 110],
  ],
  [
    [0, 57],
    [1, 4],
    [0, 49],
    [1, 3],
    [0, 6],
    [1, 3],
    [0, 110],
  ],
  [
    [0, 57],
    [1, 4],
    [0, 51],
    [1, 8],
    [0, 112],
  ],
  [
    [0, 57],
    [1, 4],
    [0, 54],
    [1, 2],
    [0, 115],
  ],
  [[0, 232]],
];

export const agriNousLogo = compactedLogo.map(expand);
