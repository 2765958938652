import React from "react";

import { faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { AuctionScreenModeSelector } from "components/AuctionPenSaleList/AuctionScreenModeSelector";
import { LaneStartLine } from "components/LaneStartLine";
import { Row } from "components/Layout";
import { ClickableLink } from "components/Link";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";

import { ApiModel } from "constants/loading";

import { RoundSelector } from "containers/ConnectedSelector";

import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
} from "lib/navigation";

import { selectModeSortedAuctionPensBySaleAndRound } from "selectors";

import AuctionPenSaleCard from "./AuctionPenSaleCard";

const ListWrapper = styled.div`
  padding: 24px 12px 12px;
  max-width: 800px;
  margin: 0 auto;
`;

const ButtonLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  padding-left: 12px;
  text-transform: uppercase;
`;

const BackWrapper = styled(Row)`
  align-items: center;
  ${({ theme }) => `${theme.active}`}
  color: ${({ theme }) => theme.colors.white};
`;

const HeaderRow = styled(Row)(
  ({ theme }) => `
background-color: ${theme.colors.gray44};
padding: ${theme.space[1]}px ${theme.space[2]}px;
`,
);

const PageWrapper = styled.div`
  overflow-x: scroll;
  padding-bottom: 100;
`;

function AuctionPenSaleList(props) {
  const { selectPen } = props;

  const saleyard = getSaleyardName();
  const saleId = getLivestockSaleId();

  const { roundId } = useParams();

  const auctionPens = useSelector(state =>
    selectModeSortedAuctionPensBySaleAndRound(state, { saleId, roundId }),
  );

  const auctionPenOverflow = {};
  auctionPens.forEach(auctionPen => {
    const lotsWithOverflow = auctionPen.sale_lots.filter(
      lot => !!lot.overflowPen && lot.overflowPen.length > 0,
    );
    auctionPenOverflow[auctionPen.id] =
      lotsWithOverflow.length > 0
        ? {
            overflowPen: lotsWithOverflow[0].overflowPen,
            overflowQuantity: lotsWithOverflow[0].overflowQuantity,
          }
        : null;
  });

  const overviewPage = `${getSaleyardAuctionRoute(saleyard, saleId)}/overview`;

  return (
    <PageWrapper>
      <HeaderRow alignCenter full justifyBetween>
        <div>
          <ClickableLink to={overviewPage}>
            <BackWrapper>
              <FontAwesomeIcon
                style={{ fontSize: "18px" }}
                icon={faAngleLeft}
              />
              <ButtonLabel data-tour="back">Back</ButtonLabel>
            </BackWrapper>
          </ClickableLink>
        </div>
        <AuctionScreenModeSelector />
        <div>
          <WaitForSync requiredData={[ApiModel.ROUNDS]}>
            <RoundSelector />
          </WaitForSync>
        </div>
      </HeaderRow>
      <WaitForSync
        requiredData={[
          ApiModel.AGENCIES,
          ApiModel.AUCTION_PENS,
          ApiModel.BUSINESSES,
          ApiModel.CONSIGNMENTS,
          ApiModel.DEPLOYMENTS,
          ApiModel.ROUNDS,
          ApiModel.SALE_LOTS,
          ApiModel.SALES,
        ]}
      >
        <ListWrapper>
          {auctionPens.map(auctionPen => (
            <React.Fragment key={auctionPen.id}>
              {auctionPen.isLaneStart && <LaneStartLine />}
              <AuctionPenSaleCard
                auctionPen={{ ...auctionPen }}
                {...auctionPenOverflow[auctionPen.id]}
                selectPen={selectPen}
                selectedRoundId={+roundId}
              />
            </React.Fragment>
          ))}
        </ListWrapper>
        {auctionPens.length === 0 && (
          <MessageBox>No pens found for the selected round.</MessageBox>
        )}
      </WaitForSync>
    </PageWrapper>
  );
}

export default AuctionPenSaleList;
