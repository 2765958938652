import React from "react";

import { Box } from "@material-ui/core";
import styled from "styled-components/macro";

import { formatWeightKg } from "lib";

export const BoundingBox = styled(Box)`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
export const TotalWeight = ({ saleLot }) => {
  if (saleLot.total_mass_grams) {
    return formatWeightKg(saleLot.total_mass_grams);
  } else if (saleLot.estimatedAverageMassGrams) {
    return `~${formatWeightKg(
      saleLot.estimatedAverageMassGrams * saleLot.quantity,
      true,
      saleLot.quantity === 1,
    )}`;
  }
  return "-";
};
export const AverageWeight = ({ saleLot, includeUnits = true }) => {
  const unit = includeUnits ? "kg" : "";
  if (saleLot.total_mass_grams && saleLot.averageWeightFormatted) {
    return `${saleLot.averageWeightFormatted}${unit}`;
  } else if (saleLot.estimatedAverageMassGrams) {
    return `~${formatWeightKg(
      saleLot.estimatedAverageMassGrams,
      includeUnits,
    )}`;
  }
  return "-";
};
export const CentsPerKilo = ({ saleLot, noSymbol }) => {
  const centsSymbol = noSymbol ? "" : "¢";
  if (saleLot.centsPerKilo) {
    return (
      <>
        {Number(saleLot.centsPerKilo).toFixed(2)} {centsSymbol}
      </>
    );
  } else if (saleLot.indCentsPerKilo) {
    return (
      <>
        ~{Number(saleLot.indCentsPerKilo).toFixed(2)} {centsSymbol};
      </>
    );
  }
  return "-";
};
export const DollarsPerHead = ({ saleLot, noSymbol }) => {
  const dollarSymbol = noSymbol ? "" : "$";
  if (saleLot.dollarsPerHead) {
    return (
      <>
        {dollarSymbol}
        {Number(saleLot.dollarsPerHead).toFixed(2)}
      </>
    );
  }
  return "-";
};
