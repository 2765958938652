import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { BusinessField, ProductSelectField } from "components/Form/Fields";
import { SelectorSelectField } from "components/Form/Fields/SelectorSelectField";
import { Input, TextArea, withNamespace } from "components/Form/FormikControls";
import { PricingType } from "components/SellingSaleLotForm/PricingType";

import { BUYER, VENDOR } from "constants/businesses";

import {
  getActiveLivestockAgentDeployment,
  getDeploymentBreedOptionsByDeploymentId,
} from "selectors";

export const ExternalAgentSaleForm = ({ ns = "", readOnly = false }) => {
  const deploymentId = useSelector(getActiveLivestockAgentDeployment)?.id;

  const optionSelector = React.useMemo(
    () => getDeploymentBreedOptionsByDeploymentId(deploymentId),
    [deploymentId],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BusinessField
          label="From"
          name="vendor_id"
          sortRecentBusinessType={VENDOR}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          type="number"
          label="Head Count"
          name={withNamespace(ns, "quantity")}
          placeholder='Enter "0" to count later'
          required
          disableAutoComplete
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          type="number"
          label="Auction Pen"
          name={withNamespace("auction_pen", "start_pen")}
          disabled={readOnly}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <ProductSelectField
          ns={ns}
          deploymentId={deploymentId}
          disabled={readOnly}
          hideQuickSelect
        />
      </Grid>

      <Grid data-tour="breed" item xs={6}>
        <SelectorSelectField
          label="Breed"
          name={withNamespace(ns, "breed_id")}
          optionsSelector={optionSelector}
          isClearable
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={4}>
        <Grid item xs={12}>
          <Input label="Account Note" name="accountNote" disabled={readOnly} />
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <BusinessField
          label="To"
          name="buyer_id"
          sortRecentBusinessType={BUYER}
        />
      </Grid>
      <Grid item xs={12}>
        <PricingType namespace={ns} readOnly={readOnly} />
      </Grid>

      <Grid item xs={12}>
        <TextArea label="Note" name="note" disabled={readOnly} />
      </Grid>
    </Grid>
  );
};
