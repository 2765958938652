import React from "react";

import { Grid } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { useSelector } from "react-redux";

import { Column, Row } from "components/Layout";

import { BUYER_LABELS } from "constants/clearingSaleAttributes";

import {
  getConsignmentById,
  selectConsignmentIdsSaleLotIdsByBuyerIdLookup,
  getSaleLotIdsByBuyerId,
} from "selectors";

import { InvoiceNumber, DeploymentBusinessName } from "./BusinessComponents";
import { BuyerMenu } from "./CardOptionsMenu";
import { CommonLabels } from "./CommonLabels";
import { ExpandablePaper } from "./ExpandablePaper";
import { SaleLotSummary, BuyerLotStatus } from "./SaleLotSummary";
import { Title } from "./Title";
import { VendorSaleLotCards } from "./VendorSaleLotCards";

export const BuyerCard = React.memo(({ id }) => {
  const saleLotIds = useSelector(getSaleLotIdsByBuyerId(id)) || [];

  const [expanded, setExpanded] = React.useState(false);
  const consignmentIds = Object.keys(
    useSelector(
      state => selectConsignmentIdsSaleLotIdsByBuyerIdLookup(state)[id],
    ),
  );

  const consignment = useSelector(getConsignmentById(consignmentIds[0]));

  return (
    <Column margin={2}>
      <ExpandablePaper
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      >
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Row>
              <BuyerLotStatus saleLotIds={saleLotIds} />

              <Title>
                <DeploymentBusinessName businessId={id} />
              </Title>
            </Row>
            <SaleLotSummary saleLotIds={saleLotIds} />
            <InvoiceNumber consignment={consignment} businessId={id} />
            <CommonLabels saleLotIds={saleLotIds} limitTo={BUYER_LABELS} />
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <BuyerMenu businessId={id} />
          </Grid>
        </Grid>
      </ExpandablePaper>

      <Grid item xs={12}>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
          {consignmentIds.map(consignmentId => {
            return (
              <VendorSaleLotCards
                key={consignmentId}
                consignmentId={consignmentId}
                buyerId={id}
              />
            );
          })}
        </Collapse>
      </Grid>
    </Column>
  );
});
