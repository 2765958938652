import * as Yup from "yup";

import { addressValidationSchema } from "components/Form/Fields";

export const bidderFormValidationSchema = biddersList =>
  Yup.lazy(values =>
    Yup.object().shape({
      businessId: Yup.string().required("Required."),
      email: Yup.string().email("Invalid email address."),
      deliveryAddress: addressValidationSchema.nullable(),
      postalAddress: addressValidationSchema.nullable(),
      registrationNumber: Yup.number()
        .required("Required.")
        .test(
          "unique-registration-number",
          "Registration number already exists",
          value =>
            !biddersList.some(
              bidder =>
                bidder.registrationNumber === value && bidder.id !== values.id,
            ),
        ),
    }),
  );
