import React, { useCallback } from "react";

import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";

import { SlimButton } from "components/Button";

import { indefiniteArticle } from "lib/textUtils";
import toast from "lib/toast";

import { useTheme } from "hooks";

const DropContainer = styled.div`
  margin: 1rem;
  padding: 1rem;
  background: white;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(SlimButton)`
  width: 100%;
  border-radius: 0;
`;

export const FileImportField = ({
  accept,
  existingFileName,
  fileDescription,
  processFile,
  color,
  disallowReupload,
}) => {
  const theme = useTheme();
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast.error(
          `Unsupported filetype - please select a ${fileDescription} file to import.`,
        );
      }

      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
          processFile(file, reader.result);
        };
        reader.readAsText(file);
      });
    },
    [processFile, fileDescription],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  if (disallowReupload && existingFileName) {
    return <p>Importing: {existingFileName}</p>;
  }

  if (existingFileName) {
    return (
      <Button {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Importing: {existingFileName}</p>
        <p>Click here to select a different file.</p>
      </Button>
    );
  }

  return (
    <DropContainer
      {...getRootProps()}
      style={{
        backgroundColor: theme.colors[color] || "white",
        cursor: "pointer",
      }}
      data-tour="dropContainer"
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the file here ...</p>
      ) : (
        <p>
          {`Drag and drop ${indefiniteArticle(
            fileDescription,
          )} ${fileDescription} file here to start the import process, or
          click to select a file.`}
        </p>
      )}
    </DropContainer>
  );
};

export const SessionStorageFileImportField = ({
  accept,
  storageKey,
  storageFilenameKey,
  processFile,
  fileReadyToast,
  fileDescription,
}) => {
  const preload = sessionStorage.getItem(storageKey);
  const existingFileName = sessionStorage.getItem(storageFilenameKey);

  React.useEffect(() => {
    // if there is already a file loaded in the session, use it, don't ask the user to upload again.
    if (preload) {
      processFile(preload);
    }
  }, [preload, processFile]);

  return (
    <FileImportField
      accept={accept}
      fileReadyToast={fileReadyToast}
      fileDescription={fileDescription}
      processFile={(file, fileContent) => {
        toast.info(fileReadyToast);
        // Session storage is limited to 5Mb, so try to use it if we can, but we can work without it if we have to.
        try {
          sessionStorage.setItem(storageKey, fileContent);
          sessionStorage.setItem(storageFilenameKey, file.name);
        } catch (e) {
          sessionStorage.removeItem(storageKey);
          sessionStorage.removeItem(storageFilenameKey);
        }
        processFile(fileContent);
      }}
      existingFileName={existingFileName}
    />
  );
};
