import React from "react";

import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  position: relative;
`;

const DateInputStyled = styled.input`
  height: 36px;
  width: 100%;
  padding-left: 12px;
  line-height: 24px;
  color: #666666;
  border-radius: 4px;
  ${({ error, theme }) => {
    const borderColor = error ? theme.colors.inputError : theme.colors.gray78;
    return `border: 1px ${borderColor} solid;
      ::placeholder {
        color: ${theme.colors.gray78};
        font-size: 14px;
        line-height: 24px;
      }
    `;
  }};
  &::-webkit-clear-button,
  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none !important;
  }
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    font-size: 18px;
  }
`;

const WrapperIcon = styled.div`
  position: absolute;
  pointer-events: none;
  border: 0;
  background: transparent;
  top: 2px;
  right: 7px;
  font-size: 18px;
  line-height: 36px;
  ${({ theme, color }) => {
    if (color) {
      return `color: ${theme.colors[color]}`;
    }
  }};
`;

export const DateInput = ({ value, includeTime, ...props }) => (
  <Wrapper>
    <WrapperIcon>
      <FontAwesomeIcon icon={faCalendarAlt} />
    </WrapperIcon>
    <DateInputStyled
      max="9999-12-31"
      type={includeTime ? "datetime-local" : "date"}
      placeholder={includeTime ? "" : "dd/mm/yyyy"}
      value={value || ""}
      {...props}
    />
  </Wrapper>
);

DateInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
