import React from "react";

import {
  faCheckCircle,
  faDollarSign,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { StatusCard } from "components/Card";
import { Row } from "components/Layout";
import NLISLogo from "components/NLISLogoStatus";
import { BoldText, Subtitle } from "components/Text";

import { colors } from "constants/theme";
import { AGRINOUS_ADMIN } from "constants/users";

import { openEditCheckpointModal } from "lib/navigation";
import {
  convertDateTimeToUTCDateTime,
  formatUTCToLocalDateTimeString,
} from "lib/timeFormats";

import { getCheckpointById } from "selectors/checkpoints";

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const IconWrapper = styled.div`
  margin-top: 15px;
`;

const Spacer = styled.div`
  margin: 1vw;
`;

const UserNameSubtitle = styled(Subtitle)`
  font-size: 0.8em;
  color: ${({ theme }) => theme.colors.primary};
`;

export const DollarSign = styled(FontAwesomeIcon)`
  font-size: 28px;
  margin-left: 2px;
`;
export const CheckpointCards = ({ checkpointId }) => {
  const checkpoint = useSelector(getCheckpointById(checkpointId)) || {};
  const { user = {} } = checkpoint;
  return (
    <StatusCard status>
      <Row>
        <Grid container>
          <Grid item xs={6} sm={8}>
            <Row alignCenter>
              <div>
                <BoldText>
                  <Subtitle display="flex" alignItems="center">
                    Sale Checkpoint - {checkpoint.type}
                  </Subtitle>
                  <UserNameSubtitle>
                    Created by{" "}
                    {user
                      ? `${user?.firstName} ${user?.lastName}`
                      : AGRINOUS_ADMIN}
                  </UserNameSubtitle>
                  {formatUTCToLocalDateTimeString(
                    convertDateTimeToUTCDateTime(checkpoint?.timestamp),
                  )}
                </BoldText>
                <Row>
                  {checkpoint.comments
                    ? `Comments: ${checkpoint.comments}`
                    : ""}
                </Row>
              </div>
            </Row>
          </Grid>
          <Grid item xs={4} sm={2}>
            <IconWrapper>
              <Row>
                {checkpoint.isCommercialCheck && (
                  <>
                    <Wrapper>
                      <DollarSign icon={faDollarSign} />
                    </Wrapper>
                    <Spacer />
                  </>
                )}
                {checkpoint.isComplianceCheck && (
                  <>
                    <NLISLogo />
                    <Spacer />
                  </>
                )}
                <Wrapper>
                  <FontAwesomeIcon
                    {...{
                      size: "2x",
                      icon: checkpoint.hasUpdatesSince
                        ? faTimesCircle
                        : faCheckCircle,
                      color: checkpoint.hasUpdatesSince
                        ? colors.errorRed
                        : colors.successGreen,
                    }}
                  />
                </Wrapper>
              </Row>
            </IconWrapper>
          </Grid>
          <Grid item xs={2}>
            <Row justifyEnd>
              {" "}
              <Button onClick={() => openEditCheckpointModal(checkpoint.id)}>
                {" "}
                Edit
              </Button>
            </Row>
          </Grid>
        </Grid>
      </Row>
    </StatusCard>
  );
};
