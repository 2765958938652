import reduceReducers from "reduce-reducers";

import { INTEGRATION_CREDENTIAL } from "constants/actionTypes";

import {
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
} from "lib/reducers";
import { deserializeIntegrationCredential } from "lib/serializers/integrationCredentials";

const create = apiModelOfflineCreateReducer(INTEGRATION_CREDENTIAL, {
  deserializer: deserializeIntegrationCredential,
});

const fetch = apiModelOfflineFetchReducer(INTEGRATION_CREDENTIAL, {
  deserializer: deserializeIntegrationCredential,
  clearOnRequest: true,
});

const update = apiModelOfflineUpdateReducer(INTEGRATION_CREDENTIAL, {
  deserializer: deserializeIntegrationCredential,
});

const deleteReducer = apiModelOfflineDeleteReducer(INTEGRATION_CREDENTIAL);

const activateReducer = (state, action) => {
  switch (action.type) {
    case INTEGRATION_CREDENTIAL.ACTIVATE.REQUEST: {
      // Set the object to syncing, so we can't do anything more, when we're trying to activate (it's processing a redirect)
      const { id } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            syncing: true,
          },
        },
      };
    }
    case INTEGRATION_CREDENTIAL.ACTIVATE.FAILURE: {
      // Set the object to syncing, so we can't do anything more, when we're trying to activate (it's processing a redirect)
      const { id } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            syncing: false,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

const integrationCredentialReducer = reduceReducers(
  create,
  fetch,
  update,
  deleteReducer,
  activateReducer,
);

export default integrationCredentialReducer;
