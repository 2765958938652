import React from "react";

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import {
  Autocomplete,
  CheckBox,
  getOptionAsValue,
  withNamespace,
} from "components/Form/FormikControls";

import { getMtHostSessionFileNameOptionsByDeviceId } from "selectors";

const WeightSettingsForm = ({ deviceId }) => {
  const fileOptions = useSelector(getMtHostSessionFileNameOptionsByDeviceId)[
    deviceId
  ];

  return (
    <>
      {fileOptions.length ? (
        <>
          <Grid item xs={12}>
            <CheckBox name={withNamespace(deviceId, "inUse")} label="In Use" />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              name={withNamespace(deviceId, "fileName")}
              label="File Name"
              options={fileOptions}
              getOptionValue={getOptionAsValue}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          No Files Available
        </Grid>
      )}
    </>
  );
};

export default WeightSettingsForm;
