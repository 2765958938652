function getNodeAuctionsPlusItemTypeByCategory() {
  return {};
}

function getNodeAuctionsPlusItemsByManufacturer() {
  return {};
}

function getBrowserAuctionsPlusItemsByManufacturer() {
  return window.AuctionsPlusItemsByManufacturer || {};
}

function getBrowserAuctionsPlusItemTypeByCategory() {
  return window.AuctionsPlusItemTypeByCategory || {};
}

const nodeExports = {
  getAuctionsPlusItemTypeByCategory: getNodeAuctionsPlusItemTypeByCategory,
  getAuctionsPlusItemsByManufacturer: getNodeAuctionsPlusItemsByManufacturer,
};

const browserExports = {
  getAuctionsPlusItemTypeByCategory: getBrowserAuctionsPlusItemTypeByCategory,
  getAuctionsPlusItemsByManufacturer: getBrowserAuctionsPlusItemsByManufacturer,
};

window === undefined
  ? (module.exports = nodeExports)
  : (module.exports = browserExports);
