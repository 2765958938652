import React, { memo } from "react";

import { faBalanceScale, faUserEdit } from "@fortawesome/pro-duotone-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import IndicatorReadout from "containers/WeightIndicator";
import { LiveWeight } from "containers/WeightIndicator/IndicatorReadout";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { formatTime } from "lib/timeFormats";

import {
  getAuctionPenById,
  getBusinessById,
  getDraftingDecisionBySingleWeighById,
  getScanByEid,
} from "selectors";

import { AnimalDetailsPopover } from "./AnimalDetailPopover";

const RecentlyeDraftedGrid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 48px;
`;

function DraftingDecisionHistoryItemComponent(props) {
  const { singleWeighId, index } = props;
  const {
    destinationPenId,
    draftPenId,
    eid,
    buyerId,
    buyerWayName,
    totalMassGrams,
    areMultipleEidsDetected,
    overrideWeight,
  } =
    useSelector(getDraftingDecisionBySingleWeighById(singleWeighId, index)) ||
    {};

  const scan = useSelector(getScanByEid(eid));

  const { animal, sale_lot_id: saleLotId } = scan || {};
  const { nlis_id: nlisId } = animal || {};

  const draftPenName = getAuctionPenDisplayName(
    useSelector(getAuctionPenById(draftPenId)),
  );

  const destinationPenName = getAuctionPenDisplayName(
    useSelector(getAuctionPenById(destinationPenId)),
  );

  const buyerName =
    useSelector(state => getBusinessById(buyerId)(state)?.name) || "";

  const isPopoverVisible = Boolean(eid && saleLotId);

  const isUnknownAnimalWarningVisible = Boolean(eid && !scan);

  return (
    <RecentlyeDraftedGrid>
      <Box alignItems="center" display="flex">
        <b>Draft Pen:&nbsp;</b>
        {draftPenId ? draftPenName : <CircularProgress size={14} />}
      </Box>

      <Box alignItems="center" display="flex">
        <b>Destination Pen:&nbsp;</b>{" "}
        {destinationPenId ? destinationPenName : ""}
        {isUnknownAnimalWarningVisible && [
          " ",
          <FontAwesomeIcon key={0} icon={faExclamationTriangle} />,
          " Not found (new EID)",
        ]}
      </Box>

      <Box alignItems="center" display="flex">
        <b>Buyer:&nbsp;</b> {buyerId ? buyerName : ""} {buyerWayName || ""}
        {isUnknownAnimalWarningVisible && [
          " ",
          <FontAwesomeIcon key={0} icon={faExclamationTriangle} />,
          " Not found (new EID)",
        ]}
      </Box>

      <Box alignItems="center" display="flex">
        <b>{nlisId ? "NLIS ID" : "EID"}:&nbsp;</b>
        {eid ? (
          // When there is an EID:
          // if there is an NLIS ID, show "<NLIS ID> (<EID>)" (when this EID is a rescan)
          // otherwise just show "<EID>" (when this EID is a new scan)
          `${nlisId ? `${nlisId} (${eid})` : eid}`
        ) : (
          <CircularProgress size={14} />
        )}
        {isUnknownAnimalWarningVisible && [
          " ",
          <FontAwesomeIcon key={0} icon={faExclamationTriangle} />,
          " New EID",
        ]}
        {areMultipleEidsDetected && [
          " ",
          <FontAwesomeIcon key={0} icon={faExclamationTriangle} />,
          " Multiple EIDs read",
        ]}
      </Box>

      <Box alignItems="center" display="flex">
        <b>Time:&nbsp;</b>{" "}
        {scan && scan.time_weighed ? formatTime(scan.time_weighed) : "-"}
      </Box>

      <Box alignItems="center" display="flex">
        {totalMassGrams === null ? (
          <IndicatorReadout />
        ) : (
          <LiveWeight
            weightGrams={totalMassGrams}
            icon={overrideWeight ? faUserEdit : faBalanceScale}
            color="primary"
            title="Recorded weight."
          />
        )}
      </Box>

      {isPopoverVisible && (
        <Box alignItems="center" display="flex">
          <AnimalDetailsPopover eid={eid} />
        </Box>
      )}
    </RecentlyeDraftedGrid>
  );
}

DraftingDecisionHistoryItemComponent.propTypes = {
  singleWeighId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default memo(DraftingDecisionHistoryItemComponent);
