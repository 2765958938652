import React from "react";

import { SubtleBadge } from "components/Badge";
import { VisibilitySection } from "components/DetailedSaleLotModal/AdvancedDrafting/Visibility";
import { FormCollapse } from "components/Form";
import { Row } from "components/Layout";

export const IntegrationCollapse = React.memo(
  ({ isOpen, onToggle, consignmentId }) => {
    const header = (
      <Row>
        <SubtleBadge>Integrations</SubtleBadge>
      </Row>
    );
    return (
      <FormCollapse
        isOpen={isOpen}
        onToggle={onToggle}
        header={header}
        id="collapse-media"
      >
        <VisibilitySection consignmentId={consignmentId} />
      </FormCollapse>
    );
  },
);
