import React, { memo, useState } from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { RowCard } from "components/Card";
import ConsignmentWarningsTable from "components/DeploymentSaleSummaries/ConsignmentWarningsTable";
import SaleLotWarningsTable from "components/DeploymentSaleSummaries/SaleLotWarningsTable";
import UnresolvedSaleLotCommentsTable from "components/DeploymentSaleSummaries/UnresolvedSaleLotCommentsTable";
import { Row, Column } from "components/Layout";
import TabSelector from "components/TabSelector";

import {
  getHasSaleLotExceptionsByDeploymentSaleId,
  getHasConsignmentExceptionsByDeploymentSaleId,
  getHasConsignmentWarningsByDeploymentSaleId,
  getHasSaleLotWarningsByDeploymentSaleId,
  getSaleLotIdsWithUnresolvedCommentsByDeploymentSaleId,
} from "selectors";

import ConsignmentExceptionsTable from "./ConsignmentExceptionsTable";
import SaleLotExceptionsTable from "./SaleLotExceptionsTable";

const ExceptionList = {
  SALE_LOTS: 1,
  CONSIGNMENTS: 2,
  CONSIGNMENT_WARNINGS: 3,
  SALE_LOT_WARNINGS: 4,
  UNRESOLVED_SALE_LOT_COMMENTS: 5,
};

const ScrollableRow = styled(Row)`
  overflow-x: scroll;
`;

function ExceptionsView(props) {
  const { deploymentSaleId, isMobile, handleClose } = props;
  const hasSaleLotExceptions = useSelector(
    getHasSaleLotExceptionsByDeploymentSaleId(deploymentSaleId),
  );
  const hasConsignmentExceptions = useSelector(
    getHasConsignmentExceptionsByDeploymentSaleId(deploymentSaleId),
  );

  const hasConsignmentWarnings = useSelector(
    getHasConsignmentWarningsByDeploymentSaleId(deploymentSaleId),
  );

  const hasSaleLotWarnings = useSelector(
    getHasSaleLotWarningsByDeploymentSaleId(deploymentSaleId),
  );

  const hasUnresolvedSaleLotComments = useSelector(
    state =>
      getSaleLotIdsWithUnresolvedCommentsByDeploymentSaleId(deploymentSaleId)(
        state,
      ).length > 0,
  );

  const defaultSelectedTab =
    (hasSaleLotExceptions && ExceptionList.SALE_LOTS) ||
    (hasConsignmentExceptions && ExceptionList.CONSIGNMENTS) ||
    (hasConsignmentWarnings && ExceptionList.CONSIGNMENT_WARNINGS) ||
    (hasSaleLotWarnings && ExceptionList.SALE_LOT_WARNINGS) ||
    (hasUnresolvedSaleLotComments &&
      ExceptionList.UNRESOLVED_SALE_LOT_COMMENTS);
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);

  return (
    <RowCard isMobile={isMobile}>
      <Column fullWidth>
        <ScrollableRow>
          {hasSaleLotExceptions && (
            <TabSelector
              selected={selectedTab === ExceptionList.SALE_LOTS}
              onClick={() => setSelectedTab(ExceptionList.SALE_LOTS)}
              color="gray40"
            >
              Sale Lot Exceptions
            </TabSelector>
          )}
          {hasConsignmentExceptions && (
            <TabSelector
              selected={selectedTab === ExceptionList.CONSIGNMENTS}
              onClick={() => setSelectedTab(ExceptionList.CONSIGNMENTS)}
              color="gray40"
            >
              Vendor Exceptions
            </TabSelector>
          )}
          {hasConsignmentWarnings && (
            <TabSelector
              selected={selectedTab === ExceptionList.CONSIGNMENT_WARNINGS}
              onClick={() => setSelectedTab(ExceptionList.CONSIGNMENT_WARNINGS)}
              color="gray40"
            >
              Vendor Warnings
            </TabSelector>
          )}
          {hasSaleLotWarnings && (
            <TabSelector
              selected={selectedTab === ExceptionList.SALE_LOT_WARNINGS}
              onClick={() => setSelectedTab(ExceptionList.SALE_LOT_WARNINGS)}
              color="gray40"
            >
              Sale Lot Warnings
            </TabSelector>
          )}

          {hasUnresolvedSaleLotComments && (
            <TabSelector
              selected={
                selectedTab === ExceptionList.UNRESOLVED_SALE_LOT_COMMENTS
              }
              onClick={() =>
                setSelectedTab(ExceptionList.UNRESOLVED_SALE_LOT_COMMENTS)
              }
              color="gray40"
            >
              Unresolved Sale Lot Comments
            </TabSelector>
          )}

          <FontAwesomeIcon
            icon={faTimes}
            style={{
              fontSize: 16,
              cursor: "pointer",
              marginLeft: "auto",
              marginRight: 12,
              color: "#C8C8C8",
              alignSelf: "center",
            }}
            onClick={handleClose}
          />
        </ScrollableRow>

        <div>
          {selectedTab === ExceptionList.SALE_LOTS && (
            <SaleLotExceptionsTable deploymentSaleId={deploymentSaleId} />
          )}
          {selectedTab === ExceptionList.CONSIGNMENTS && (
            <ConsignmentExceptionsTable deploymentSaleId={deploymentSaleId} />
          )}
          {selectedTab === ExceptionList.CONSIGNMENT_WARNINGS && (
            <ConsignmentWarningsTable deploymentSaleId={deploymentSaleId} />
          )}
          {selectedTab === ExceptionList.SALE_LOT_WARNINGS && (
            <SaleLotWarningsTable deploymentSaleId={deploymentSaleId} />
          )}
          {selectedTab === ExceptionList.UNRESOLVED_SALE_LOT_COMMENTS && (
            <UnresolvedSaleLotCommentsTable
              deploymentSaleId={deploymentSaleId}
            />
          )}
        </div>
      </Column>
    </RowCard>
  );
}

ExceptionsView.propTypes = {
  deploymentSaleId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default memo(ExceptionsView);
