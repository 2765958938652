import React from "react";

import PropTypes from "prop-types";
import { withTheme } from "styled-components";

const LogoIcon = ({
  color,
  envColor,
  strokeColor,
  envStrokeColor,
  scale,
  theme,
}) => {
  const logoColor = theme.colors[color] || theme.colors.primary;
  const logoEnvColor = theme.colors[envColor] || theme.colors.primary;
  const logoStroke = theme.colors[strokeColor] || undefined;
  const logoEnvStroke = theme.colors[envStrokeColor] || undefined;

  const style = {
    overflow: "visible",
  };
  if (scale) {
    style.transform = `scale(${scale})`;
  }

  return (
    <svg width="31px" height="39px" style={style}>
      <g fill={logoColor} stroke={logoStroke}>
        <path d="M26.4652739,7 L22.6514495,10.8519588 C26.5937849,14.8347527 26.5937849,21.3139236 22.6514495,25.296833 C18.7069419,29.2785877 12.2929438,29.2785877 8.34855045,25.296833 C4.4061008,21.3139236 4.4061008,14.8347527 8.34855045,10.8519588 L4.53472605,7 C-1.51157535,13.1067583 -1.51157535,23.0420335 4.53472605,29.1486763 C6.8781066,31.5164121 9.79281192,32.9619504 12.8345418,33.4939519 L12.8345418,39 L18.2286846,39 L18.2286846,33.4832126 C21.2475478,32.9423194 24.1380144,31.5000144 26.4652739,29.1486763 C32.5115754,23.0420335 32.5115754,13.1067583 26.4652739,7" />
        <path
          d="M15.5000574,22 C17.9853179,22 20,19.9656248 20,17.4562143 C20,15.9750037 19.2947637,14.6632664 18.2083806,13.8337513 L18.2083806,0 L12.7920786,0 L12.7920786,13.8334036 C11.7053511,14.6629186 11,15.9747719 11,17.4562143 C11,19.9656248 13.0147969,22 15.5000574,22"
          fill={logoEnvColor}
          stroke={logoEnvStroke}
        />
      </g>
    </svg>
  );
};

LogoIcon.propTypes = {
  color: PropTypes.string,
  envColor: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

export default withTheme(LogoIcon);
