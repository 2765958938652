import React from "react";

import { connect } from "react-redux";

import {
  openConfirmModal,
  closeConfirmModal,
  addConsignment,
  patchConsignment,
  deleteConsignment,
  addSaleLot,
  deleteSaleLot,
} from "actions";

import { addAuctionPen } from "actions/auctionPens";

import { ConsignmentModal } from "components/ConsignmentBobbyCalf";

import { SaleRoundName } from "constants/sale";

import { saleIdOnly } from "lib/sale";

import {
  getNextAvailableAuctionPen,
  getSaleRoundIdByName,
  getNestedConsignments,
  selectScansBySaleLotIdLookup,
  getUnsoldDefaultBobbyCalfSaleLots,
  currentSaleSelector,
} from "selectors";

const BobbyCalfConsignment = props => {
  const { consignmentToEdit, history } = props;

  return (
    <ConsignmentModal
      closeSelf={history.goBack}
      consignmentToEdit={consignmentToEdit}
      {...props}
    />
  );
};

const mapStateToProps = (state, props) => {
  const consignments = getNestedConsignments(state);
  let consignmentToEdit = consignments.find(
    c => c.id === props.match.params.consignmentId,
  );

  if (consignmentToEdit) {
    if (consignmentToEdit.attachments.length) {
      const file = consignmentToEdit.attachments[0];
      consignmentToEdit = {
        ...consignmentToEdit,
        fileAttachment: file,
      };
    }
  }

  const selectedSale = currentSaleSelector(state);

  const unsoldSaleLots = getUnsoldDefaultBobbyCalfSaleLots(
    state,
    saleIdOnly(props),
  );
  const existingAuctionPenId =
    unsoldSaleLots.length > 0 ? unsoldSaleLots[0].auctionPenId : null;

  return {
    consignments,
    consignmentToEdit,
    selectedSale,
    existingAuctionPenId,
    nextAvailableAuctionPen: getNextAvailableAuctionPen(state),
    saleRoundId: getSaleRoundIdByName(state, SaleRoundName.BobbyCalf),
    scans: selectScansBySaleLotIdLookup(state),
  };
};

const mapDispatchToProps = {
  openConfirmModal,
  closeConfirmModal,
  addConsignment,
  patchConsignment,
  deleteConsignment,
  addAuctionPen,
  addSaleLot,
  deleteSaleLot,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BobbyCalfConsignment);
