import * as Yup from "yup";

import { CarrierChargeFormValidationSchema } from "components/CarrierChargeForm";

import { saleyardAuctionNVDValidator } from "lib/validators";

const baseValidation = {
  vendor_id: Yup.string()
    .nullable()
    .required("Required")
    .test("valid", "Required", val => val !== "-1"),
  quantity: Yup.number()
    .typeError("Required")
    .required("Required")
    .positive("Required"),
  NVD_quantity: Yup.number().nullable(),
  hasArrived: Yup.bool().nullable(),
};

export const getValidationSchema = (
  requiresAgency,
  requiresConsignedFrom,
  isSaleYardAuction,
  includeCarrierCharge,
) => {
  const validation = { ...baseValidation };
  if (requiresAgency) {
    validation.agency_id = Yup.number()
      .typeError("Agency Required")
      .required("Required");
  }
  if (requiresConsignedFrom) {
    validation.consignedFromId = Yup.number()
      .typeError("Consignment From Required")
      .required("Required");
  }
  if (isSaleYardAuction) {
    validation.NVD = saleyardAuctionNVDValidator;
  }
  if (includeCarrierCharge) {
    validation.carrierCharge = CarrierChargeFormValidationSchema;
  }
  return Yup.object().shape(validation);
};
