import { SINGLE_WEIGH, DRAFTING_DECISION } from "constants/actionTypes";

import { serializeSingleWeigh } from "lib/serializers/singleWeigh";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ livestockSaleId, id, action } = {}) =>
  urlCheckAndAddIdAndAction(
    `/v2/single-weigh/livestocksale/${livestockSaleId}/`,
    id,
    action,
  );

export const SingleWeighAction = offlineActionCreator(
  SINGLE_WEIGH,
  serializeSingleWeigh,
  urlCreator,
  true,
);

export const initializeSingleWeigh = (singleWeighId, options = null) => ({
  type: SINGLE_WEIGH.INITIALIZE.REQUEST,
  singleWeighId,
  options,
});

export const initializeSingleWeighSuccess = singleWeighId => ({
  type: SINGLE_WEIGH.INITIALIZE.SUCCESS,
  singleWeighId,
});

export const initializeSingleWeighFailure = error => ({
  type: SINGLE_WEIGH.INITIALIZE.FAILURE,
  error,
});

export const activateSingleWeigh = singleWeighId => ({
  type: SINGLE_WEIGH.ACTIVATE.ACTION,
  singleWeighId,
});

export const deactivateSingleWeigh = () => ({
  type: SINGLE_WEIGH.DEACTIVATE.ACTION,
});

export const resetSingleWeigh = singleWeighId => ({
  type: SINGLE_WEIGH.RESET.ACTION,
  singleWeighId,
});

export const updateCurrentDraftingDecision = (singleWeighId, payload) => ({
  type: DRAFTING_DECISION.UPDATE.REQUEST,
  singleWeighId,
  payload,
});

export const chooseSingleWeighEid = (singleWeighId, eid) => ({
  type: SINGLE_WEIGH.CHOOSE_EID.ACTION,
  singleWeighId,
  eid,
});

export const checkDispatchDraftingDecision = () => ({
  type: DRAFTING_DECISION.CHECK.REQUEST,
});

// Note that this takes the whole drafting decision - this is so we get access to it
// despite the reducer already shifting things out.
export const sendDraftingDecision = (singleWeighId, draftingDecision) => ({
  type: DRAFTING_DECISION.ACTION.REQUEST,
  singleWeighId,
  draftingDecision,
});
