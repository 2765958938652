/**
 * An attribute which may be associated with an animal species
 * @typedef {{order: Number, species: Number}} SpeciesAttribute
 */

/**
 * Sorts an attribute based on its `order` property
 * @param {SpeciesAttribute|} a
 * @param {SpeciesAttribute} b
 * @returns {number}
 */
export function attributeOrderSort(a, b) {
  return a.order - b.order;
}

/**
 * Filters an attribute based on its `species` property
 * @param {Number} speciesId the species to filter the attribute by
 * @returns {function(SpeciesAttribute): boolean}
 */
export function attributeSpeciesFilter(speciesId) {
  return attr => attr.species_id === speciesId;
}
