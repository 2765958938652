export function agencyVendorNumFormatter({ data }) {
  if (!data) {
    return null;
  }
  return `${data.livestock_agency_code || "?"}${data.vendorNumber || "-"}`;
}

export function agencyVendorNumGetter({ data }) {
  if (!data) {
    return null;
  }
  return [data.livestock_agency_code, data.vendorNumber]
    .filter(Boolean)
    .join("");
}
