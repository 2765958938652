import React from "react";

import { connect } from "react-redux";
import styled from "styled-components/macro";

import { Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import { StatusText } from "components/Text";

import { selectBiddersList } from "selectors";

import { BuyerCard } from "./BuyerCard";

const Wrapper = styled(Row)`
  flex-wrap: wrap;
  margin: 9px;
`;

function RegisteredBidder(props) {
  const { onSelectBidder, registrationNumber, hasWarning, id } = props;
  function onClick() {
    onSelectBidder(id);
  }
  return (
    <BuyerCard isTopBuyer data-tour={registrationNumber} onClick={onClick}>
      <div>
        {registrationNumber}&nbsp;
        {hasWarning && <StatusText status="warning" />}
      </div>
    </BuyerCard>
  );
}

function RegisteredBidderGrid(props) {
  const { bidders, onSelectBidder, loadingBidders, filter } = props;
  if (loadingBidders) {
    return (
      <Wrapper>
        <LoadingSpinner size={14} subjectName="bidders" />
      </Wrapper>
    );
  }

  if (!bidders.length) {
    if (filter) {
      return <Wrapper>No bidders match &quot;{filter}&quot;.</Wrapper>;
    }
    return <Wrapper>No bidders are registered for this sale.</Wrapper>;
  }

  return (
    <Wrapper>
      {bidders.map(bidder => (
        <RegisteredBidder
          key={bidder.id}
          onSelectBidder={onSelectBidder}
          registrationNumber={bidder.registrationNumber}
          hasWarning={bidder.hasWarning}
          id={bidder.id}
        />
      ))}
    </Wrapper>
  );
}

function mapStateToProps(state, props) {
  return {
    bidders: selectBiddersList(state)
      // Filter out all values that don't contain the filter in their
      // registration_number property, or don't filter if the value isn't set
      .filter(
        bidder =>
          bidder.businessId &&
          (bidder.registrationNumber.toString().indexOf(props.filter) > -1 ||
            !props.filter),
      )
      // select only the id and the display value (registrationNumber)
      .map(({ defaultPropertyId, id, registrationNumber }) => ({
        id,
        registrationNumber,
        hasWarning: !defaultPropertyId,
      }))
      .sort((a, b) => a.registrationNumber - b.registrationNumber),
    loadingBidders: state.bidderRegistrations.isFetching,
  };
}

export default connect(mapStateToProps)(React.memo(RegisteredBidderGrid));
