import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const AddButton = styled(Link)(
  ({ theme }) => `
  padding: ${theme.space[1]}px ${theme.space[2]}px;
  margin: -${theme.space[1]}px ${theme.space[1]}px;
  display: inline-block;
  text-decoration: none;
  border-radius: ${theme.radii[2]}px;
  color: ${theme.colors.white};
  background: ${theme.colors.primary};
  cursor: pointer;
  font-size: ${theme.fontSizes.beta}px;
  font-weight: ${theme.fontWeights.medium};
  transition: ${theme.transitions[1]};
  &:hover {
    background: ${theme.colors.primaryHighlight};
  }
  &:active {
    background: ${theme.colors.primaryActive};
  }
  text-align: center;
  vertical-align: baseline;
`,
);
