import React from "react";

import { PropTypes } from "prop-types";

import { Dialog, DialogTitle, DialogContent } from "components/MaterialDialog";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

import CarrierChargePicker from "./CarrierChargePicker";

const TargetNameMap = {
  CONSIGNMENT: "Consignment",
  SALE_LOT: "Sale Lot",
};

function getTargetFriendlyName(target) {
  return TargetNameMap[target] || "";
}

function Modal({ id, fullScreen, returnTo, target }) {
  function onClose() {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }

  function onAfterSelectCarrierCharge() {
    onClose();
  }

  const dialogTitle = `Select ${getTargetFriendlyName(target)} Carrier Charge`;

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle onClose={onClose}>{dialogTitle}</DialogTitle>
      <DialogContent>
        <CarrierChargePicker
          id={id}
          target={target}
          onAfterSelectCarrierCharge={onAfterSelectCarrierCharge}
        />
      </DialogContent>
    </Dialog>
  );
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  fullScreen: PropTypes.bool,
  returnTo: PropTypes.string,
};

export default Modal;
