import React from "react";

import { isEmpty } from "lodash";
import groupBy from "lodash/groupBy";
import { useSelector } from "react-redux";

import { ResolveChanges } from "components/BillingWorkFlow/BillingDocumentReviewModal/Changes";
import { ResolveConflicts } from "components/BillingWorkFlow/BillingDocumentReviewModal/Conflict";

import { BillingLedgerEntryStatus } from "constants/billingLedgerEntries";
import { ModalTypes } from "constants/navigation";

import { getLedgerEntriesByDocumentId } from "selectors";

import { useModalAdapter } from "hooks";

export const BillingDocumentReviewModal = props => {
  const [params, _ignored2, onClose] = useModalAdapter(
    ModalTypes.BillingDocumentReview,
    props,
  );

  const { id } = params;

  // When reviewing the billing document,
  const ledgerEntries = useSelector(getLedgerEntriesByDocumentId(id));

  const ledgerEntriesGroupedByStatus = groupBy(
    ledgerEntries,
    "ledgerEntry.status",
  );

  const tempLedgerEntriesGroupedByMincenousId = groupBy(
    ledgerEntriesGroupedByStatus[BillingLedgerEntryStatus.TEMPORARY],
    "ledgerEntry.mincenousId",
  );

  const duplicatesGroupedByMinceNousId = Object.entries(
    tempLedgerEntriesGroupedByMincenousId,
  ).reduce((acc, [mincenousId, ledgerEntries]) => {
    if (ledgerEntries.length > 1) {
      acc[mincenousId] = ledgerEntries;
    }
    return acc;
  }, {});

  if (!isEmpty(duplicatesGroupedByMinceNousId)) {
    return <ResolveConflicts id={id} onClose={onClose} />;
  } else {
    return <ResolveChanges id={id} onClose={onClose} />;
  }
};
