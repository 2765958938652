import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

export function datetimeFormatter(props) {
  if (!props.data) {
    return null;
  }
  const { value } = props;
  if (value && !value.includes("1970-01-01")) {
    return formatUTCToLocalDateTimeString(value);
  } else {
    return "-";
  }
}
