import React from "react";

import PropTypes from "prop-types";
import { withTheme } from "styled-components";

const ArrivedLogo = ({ color, theme, dark }) => {
  const logoColor = color || theme.colors.primary;
  const altColor = dark ? theme.colors.black : theme.colors.white;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3318.92 3318.92"
      width="33px"
      height="33px"
    >
      <defs />
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2519574723248">
          <path
            fill={altColor}
            stroke="#427DB3"
            strokeWidth="105.83"
            strokeMiterlimit="22.9256"
            d="M1383.74 156.21c-1993.84,375.41 -1461.33,3386.5 569.77,3019.84 1959.99,-353.83 1444.86,-3399.13 -569.77,-3019.84z"
          />
          <g>
            <g>
              <path
                fill={logoColor}
                d="M1199.88 1851.16c-47.83,-7.89 -83.27,10.94 -112.84,31.52 -3.98,2.77 -5.85,5.71 -9.61,9.18 -23.55,21.76 -38.72,52.84 -43,85.06 -11.46,86.21 50.97,155.86 125.12,165.75 88.23,11.78 154.77,-49.88 166.46,-124.74 7.22,-46.24 -9.57,-85.98 -31.41,-114.09 -22.88,-29.47 -56.24,-46.33 -94.72,-52.68zm275.27 -664.76l-552.52 0.09 0.03 -110.4 585.73 -0.01c18.5,0 31.97,4 44.95,12.9 43.01,29.47 32.14,81.26 32.13,139.21l-0.03 705.49c76.06,21.53 153.56,41.65 230.12,61.87 77.44,20.46 153.01,41.18 230.35,61.83 76.55,20.43 153.28,40.15 230.38,61.74 132.01,36.96 128.4,17.01 109.1,88.36 -16.36,60.51 -7.87,52.2 -85.59,32.21 -19.21,-4.95 -38,-10.41 -57.26,-15.43l-690.23 -184.87c-37.92,-10.43 -77.59,-22.08 -115.49,-30.84 -3.85,77.79 -40.62,139.21 -79.68,175.2 -24.31,22.4 -44.2,36.48 -76.12,50.46 -31.14,13.64 -67.66,20.56 -106.55,20.19 -208.62,-1.99 -332.74,-250.37 -191.7,-423.49 2.25,-2.75 4.64,-4.91 7.02,-7.74 2.3,-2.76 3.83,-4.54 6.36,-7.18 9.08,-9.46 20.32,-17.76 30.52,-26 75.4,-60.87 199.09,-66.42 279.59,-17.66 23.27,14.09 37.54,24.75 55.63,42.66 16.59,16.44 30.29,35.62 42.26,56.04 6.68,11.41 5.52,15.3 11.33,15.29l59.52 -0.04 0.02 -363.21c0.72,-99.64 -1.44,-205.97 0.14,-304.3l-0.01 -32.37z"
              />
            </g>
            <path
              fill={logoColor}
              d="M2059.6 1588.16c-5.4,-7.61 -6.29,-10.52 -10.49,-16.18 -5.69,-7.68 -4.89,-7.9 -7.81,-12.43l-8.43 -12.07c-0.98,-1.65 -1.3,-2.85 -2.35,-4.46 -0.93,-1.43 -1.67,-2.34 -2.7,-3.74 -3.87,-5.28 -6.62,-10.82 -10.5,-16.16 -8.93,-12.29 -22.32,-35.24 -31.79,-48.86 -3.12,-4.49 -7.75,-10.2 -9.05,-17.07 -1.53,-8.09 0.22,-15.3 5.17,-21.91 3.76,-5.04 25.63,-17.6 32.02,-21.5 5.5,-3.35 9.06,-6.76 18.4,-6.9 18.03,-0.29 25.82,17.18 30.58,25.16 13.3,22.29 27.07,43.88 40.71,66.02l111.45 180.89c3.14,5.3 5.49,11.89 3.29,20.63 -2.13,8.45 -6.74,12.63 -12.86,16.75 -5.39,3.63 -10.85,6.69 -16.4,10.13l-164.4 101.46c-21.97,13.56 -43.43,27.33 -65.59,40.51 -8.79,5.23 -21.59,17.15 -38.91,8.1 -7.1,-3.71 -10.4,-9.81 -13.26,-14.45 -5.08,-8.24 -16.84,-24.95 -18.49,-33.9 -1.39,-7.53 1.17,-16.43 5.91,-21.85 4.79,-5.48 10.36,-7.25 15.82,-11.05l42.14 -24.38c3,-1.61 5.22,-3.37 8.18,-5.06l41.8 -24.04c2.9,-1.56 5.24,-3.5 8.12,-5.15 3.03,-1.73 5.49,-2.89 8.52,-4.57 2.92,-1.61 5.2,-3.53 8.13,-5.15 3.01,-1.66 5.51,-2.91 8.52,-4.58 2.9,-1.61 5.22,-3.54 8.12,-5.17 3,-1.68 5.5,-2.9 8.51,-4.56 2.67,-1.48 5.46,-3.54 7.94,-5.1 -5.25,-2 -184.87,-44.04 -189.79,-45.21 -31.57,-7.48 -63.16,-14.89 -94.74,-22.37 -15.01,-3.55 -31.37,-4.89 -40.01,-15.6 -9.5,-11.76 -4.89,-22.34 -0.86,-39.93 1.48,-6.45 3.5,-17.58 6.27,-23.05 11.58,-22.84 36.7,-12.1 55.68,-7.61l236.82 56.03c15.16,3.59 32.3,8.52 47.4,11.15l-21.07 -32.77z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ArrivedLogo.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

ArrivedLogo.defaultProps = {
  dark: false,
};

export default withTheme(ArrivedLogo);
