import React from "react";

import { ModalTypes } from "constants/navigation";

import Dialog from "./dialog";

function EditBidderRegistrationModalAdapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.EditBidder] || null;
  const [bidderId, fullScreen] = JSON.parse(hashParams);
  const { returnTo } = hash;
  const decodedReturnTo = returnTo ? atob(returnTo) : "";
  return (
    <Dialog
      bidderId={bidderId}
      returnTo={decodedReturnTo}
      fullScreen={fullScreen}
      {...props}
    />
  );
}

export default EditBidderRegistrationModalAdapter;
