import { createSelector } from "reselect";

import {
  selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
  selectResolvedSaleLotCommentCountBySaleLotIdLookup,
  getConsignmentSaleLots,
} from "selectors";

export const selectConsignmentModalSaleLotsAggridData = createSelector(
  [
    getConsignmentSaleLots,
    selectUnresolvedSaleLotCommentCountBySaleLotIdLookup,
    selectResolvedSaleLotCommentCountBySaleLotIdLookup,
  ],
  (
    saleLots,
    unresolvedSaleLotCommentCountBySaleLotIdLookup,
    resolvedSaleLotCommentCountBySaleLotIdLookup,
  ) =>
    saleLots.map(saleLot => {
      return {
        ...saleLot,
        unresolvedSaleLotCommentCount:
          unresolvedSaleLotCommentCountBySaleLotIdLookup[saleLot.id],
        resolvedSaleLotCommentCount:
          resolvedSaleLotCommentCountBySaleLotIdLookup[saleLot.id],
      };
    }),
);
