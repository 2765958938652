import React, { memo, useCallback } from "react";

import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { PaymentAction } from "actions";

import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  ZoomyDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import MessageBox from "components/MessageBox";

import { ApiModel } from "constants/loading";

import { closeAllHashModals, returnToLocation } from "lib/navigation";

import {
  getBusinessById,
  getPaymentById,
  selectActiveLivestockAgentDeploymentMasterBusinessId,
} from "selectors";

import { EditPaymentForm } from "./EditPaymentForm";
import { Footer } from "./Footer";
import { ValidationSchema } from "./validationSchema";

function EditPaymentModalModalTitleComponent(props) {
  const { businessId, isEdit, onClose } = props;

  const businessName =
    useSelector(state => getBusinessById(businessId)(state)?.name) || "";

  return (
    <DialogTitle onClose={onClose}>
      {isEdit ? "Edit" : "Record"} payment from {businessName}
    </DialogTitle>
  );
}

const EditPaymentModalModalTitle = memo(EditPaymentModalModalTitleComponent);
EditPaymentModalModalTitle.propTypes = {
  onClose: PropTypes.func.isRequired,
  businessId: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

/**
 * When paymentId is a string, the form will show in edit mode and a delete button will be shown in the actions buttons at the bottom of the modal.
 * When paymentId is `null`, the form will show in create mode.
 *
 * Create mode requires that `fromBusinessId` exists in `options.presets`
 *
 * @param {Object} props
 * @param {Object} [props.options]
 * @param {Payment|null} [props.options.presets]
 * @param {string|null} [props.paymentId]
 * @param {string} [props.returnTo]
 * @returns {*}
 * @constructor
 */
export function EditPaymentModal(props) {
  const { options, paymentId, returnTo } = props;
  const presets = options?.presets || null;

  const payment = useSelector(getPaymentById(paymentId));
  const deploymentMasterBusinessId = useSelector(
    selectActiveLivestockAgentDeploymentMasterBusinessId,
  );

  const dispatch = useDispatch();

  const isEdit = paymentId !== null;

  const onClose = useCallback(() => {
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }, [returnTo]);

  const onDelete = useCallback(() => {
    dispatch(PaymentAction.delete(paymentId));
    onClose();
  }, [dispatch, onClose, paymentId]);

  const onSubmitForm = useCallback(
    formValues => {
      if (isEdit) {
        dispatch(PaymentAction.update(formValues));
      } else {
        const paymentValues = {
          ...formValues,
          toBusinessId: deploymentMasterBusinessId,
          id: uuidv4(),
        };
        dispatch(PaymentAction.create(paymentValues));
      }
      onClose();
    },
    [deploymentMasterBusinessId, dispatch, isEdit, onClose],
  );

  const defaultValues = {
    // the the current date as an ISO8601 date string
    paymentDate: new Date().toISOString().slice(0, 10),
  };

  const initialValues = { ...defaultValues, ...payment, ...presets };

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="sm" fullWidth>
      <WaitForSync requiredData={[ApiModel.BUSINESSES]}>
        <EditPaymentModalModalTitle
          onClose={onClose}
          businessId={initialValues.fromBusinessId}
          isEdit={Boolean(paymentId)}
        />
        {!deploymentMasterBusinessId ? (
          <MessageBox>Recipient&apos;s Business could not be found.</MessageBox>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmitForm}
            validationSchema={ValidationSchema}
          >
            <Form>
              <DialogContent dividers>
                <Grid spacing={2} container>
                  <EditPaymentForm />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Footer isEdit={isEdit} onDelete={onDelete} onClose={onClose} />
              </DialogActions>
            </Form>
          </Formik>
        )}
      </WaitForSync>
    </ZoomyDialog>
  );
}
EditPaymentModal.propTypes = {
  paymentId: PropTypes.string,
  options: PropTypes.shape({
    presets: PropTypes.shape({
      fromBusinessId: PropTypes.string.isRequired,
    }),
  }),
  returnTo: PropTypes.string,
};
