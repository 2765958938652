import React, { useCallback } from "react";

import { useSelector } from "react-redux";

import AgGridTable from "components/AgGrid/AgGridContainer";
import {
  BusinessInSaleColId,
  BusinessInSaleColumnDef,
} from "components/BusinessesInSale/Table/columns";
import { columnsDefs } from "components/BusinessTable/BusinessListColumns";

import { AgGridTables } from "constants/aggrid";
import { DeploymentPermissions } from "constants/permissions";

import { openEditBusinessModal } from "lib/navigation";

import {
  currentSaleSelector,
  getActiveLivestockAgentDeployment,
  getIsLivestockAgent,
  getProperties,
  selectDefaultVendorSplitByBusinessIdLookup,
} from "selectors";

import { selectBusinessesInSaleAggridData } from "selectors/aggrid/businessesInSale";

import { useHasDeploymentPermission } from "hooks";

const getRowId = params => params.data.business.id;

const businessesInSaleColumnDefs = (isLivestockAgent, isInterestEnabled) => [
  ...columnsDefs(isLivestockAgent, isInterestEnabled),
  BusinessInSaleColumnDef[BusinessInSaleColId.ROLE_IN_SALE],
];

export const BusinessesInSaleTable = () => {
  const rowData = useSelector(selectBusinessesInSaleAggridData) || [];
  const propertyByIdLookup = useSelector(getProperties);
  const defaultSplitByBusinessIdLookup = useSelector(
    selectDefaultVendorSplitByBusinessIdLookup,
  );
  const sale = useSelector(currentSaleSelector);
  const { date: saleDate, saleyard_name: saleyard } = sale;

  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const context = { defaultSplitByBusinessIdLookup, propertyByIdLookup };

  const hasInterestFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureInterest,
  );

  const activeDeployment = useSelector(getActiveLivestockAgentDeployment);

  const isInterestEnabled =
    hasInterestFeature && activeDeployment.deploymentSettings?.enableInterest;

  const getContextMenuItems = useCallback(params => {
    const contextMenu = Array.isArray(params.defaultItems)
      ? [...params.defaultItems]
      : [];
    contextMenu.push({
      name: "Open Contact",
      action: () => openEditBusinessModal(params.node.data.id),
    });

    return contextMenu;
  }, []);

  return (
    <AgGridTable
      downloadFilename={`${saleDate}-${saleyard}-Businesses.csv`}
      columnDefs={businessesInSaleColumnDefs(
        isLivestockAgent,
        isInterestEnabled,
      )}
      rowData={rowData}
      context={context}
      getContextMenuItems={getContextMenuItems}
      getRowId={getRowId}
      tableName={AgGridTables.BUSINESSES_IN_SALE}
    />
  );
};
