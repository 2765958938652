import React from "react";

import {
  faDoorClosed,
  faDoorOpen,
  faLock,
  faLockOpen,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Tooltip } from "@material-ui/core";
import { uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { AuctionPenAction } from "actions";

import Button from "components/Button";
import { ButtonIcon } from "components/Button/FontAwesomeButton";
import { Flasher } from "components/Flasher";
import { Column, Row } from "components/Layout";
import { useResponsiveText } from "components/ResponsiveText";

import { AUCTION_PEN } from "constants/actionTypes";
import { GateStatus } from "constants/auctionPens";
import { SingleWeighMode } from "constants/singleWeigh";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { getAutoDraftPenStatusColour } from "lib/singleWeigh";

import {
  getAuctionPenById,
  getBusinesses,
  getBuyerAndBuyerWayIdsByDeliveryPenId,
  getIsResetDisabledByAutoDraftPenId,
  getEidCountByCurrentAuctionPenId,
  getIsOnline,
  getProcessedPostSaleCountByAutoDraftPenId,
  getProcessedSellingPennedEidCountByAutoDraftPenId,
  getSellingPennedHeadCountByAutoDraftPenId,
  getTotalPostSaleCountByAutoDraftPenId,
  getStatusByAutoDraftPenId,
} from "selectors";

import { useTheme } from "hooks";

import { SingleWeighIcon } from "./EntryGateStatus";

const ColorInvertMap = {
  white: "black",
  warningOrange: "black",
  successGreen: "black",
  gray78: "black",
  primary: "white",
};

const ButtonContainer = styled(Column)(
  ({ disabled }) => `
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  background: transparent;
  pointer-events: ${disabled ? "none" : "all"};
  opacity: ${disabled ? "0.4" : "1"};
`,
);

function ColumnHeadByBuyer({ autoDraftPenId }) {
  const total = useSelector(
    getTotalPostSaleCountByAutoDraftPenId(autoDraftPenId),
  );
  const processed = useSelector(
    getProcessedPostSaleCountByAutoDraftPenId(autoDraftPenId),
  );

  const label = `${processed}/${total}`;
  return <Flasher value={label}>{label}</Flasher>;
}

function ColumnHeadBySellingPen({ autoDraftPenId }) {
  const total = useSelector(
    getSellingPennedHeadCountByAutoDraftPenId(autoDraftPenId),
  );
  const processed = useSelector(
    getProcessedSellingPennedEidCountByAutoDraftPenId(autoDraftPenId),
  );

  const label = `${processed}/${total}`;

  return (
    <Flasher value={label}>
      {processed}/{total}
    </Flasher>
  );
}

function ColumnOwner({ autoDraftPenId }) {
  // This is a post sale, so destination pen = delivery pen.
  const autoDraftPen = useSelector(getAuctionPenById(autoDraftPenId));
  const destinationPenId = autoDraftPen?.autoDraftState?.destinationPenId;
  const buyerAndBuyerWayIds =
    useSelector(getBuyerAndBuyerWayIdsByDeliveryPenId(destinationPenId)) || [];

  const businesses = useSelector(getBusinesses);

  if (!destinationPenId || buyerAndBuyerWayIds.length === 0) {
    return "-";
  }

  // Unique due to the possibility this may be the same buyer visually, and literally, but
  // a different underlying business (which should be merged at a later date)
  const content = uniq(
    buyerAndBuyerWayIds.map(
      buyerAndBuyerWayId =>
        `${businesses[buyerAndBuyerWayId.buyerId]?.name}${
          buyerAndBuyerWayId.buyerWayName
            ? ` - ${buyerAndBuyerWayId.buyerWayName}`
            : ""
        }`,
    ),
  ).join(" / ");

  return (
    <Tooltip title={content} placement="top-start">
      <span>{content}</span>
    </Tooltip>
  );
}

function ColumnDestinationPen({ autoDraftPenId }) {
  const autoDraftPen = useSelector(getAuctionPenById(autoDraftPenId));
  const destinationPen = useSelector(
    getAuctionPenById(autoDraftPen?.autoDraftState?.destinationPenId),
  );
  const displayName = getAuctionPenDisplayName(destinationPen, "-");
  const theme = useTheme();
  const isLocked = destinationPen?.isLocked;
  return (
    <Row alignCenter justifyCenter>
      <div>{displayName}</div>
      {isLocked && (
        <FontAwesomeIcon
          title="Delivery Pen Is Locked"
          color={theme.colors.errorRed}
          icon={faExclamationTriangle}
        />
      )}
    </Row>
  );
}

function ColumnInPenCount({ autoDraftPenId }) {
  const inPenCount = useSelector(
    getEidCountByCurrentAuctionPenId(autoDraftPenId),
  );

  return <Flasher value={inPenCount}>{inPenCount}</Flasher>;
}

function InPenCount({ autoDraftPenId }) {
  return (
    <Row fullWidth justifyAround alignCenter>
      <SingleWeighIcon autoDraftPenId={autoDraftPenId} />
      <ColumnInPenCount autoDraftPenId={autoDraftPenId} />
    </Row>
  );
}

function ColumnPenName({ autoDraftPenId }) {
  const autoDraftPen = useSelector(getAuctionPenById(autoDraftPenId));
  return getAuctionPenDisplayName(autoDraftPen);
}

function EntryExitIcons({ autoDraftPenId, gate }) {
  return (
    <Row justifyAround>
      <LockGateButton autoDraftPenId={autoDraftPenId} gate={gate} />
      <OpenGateButton autoDraftPenId={autoDraftPenId} gate={gate} />
    </Row>
  );
}

function EntryIcons({ autoDraftPenId }) {
  return (
    <EntryExitIcons autoDraftPenId={autoDraftPenId} gate="entryGateStatus" />
  );
}

function ExitIcons({ autoDraftPenId }) {
  return (
    <EntryExitIcons autoDraftPenId={autoDraftPenId} gate="exitGateStatus" />
  );
}

function LockGateButton({ autoDraftPenId, gate }) {
  const pen = useSelector(getAuctionPenById(autoDraftPenId));
  const dispatch = useDispatch();
  const isOnline = useSelector(getIsOnline);
  const { syncing } = pen;

  const status = pen?.autoDraftState?.[gate];
  const disabled = !!(isOnline && syncing);

  const toggleLock = () => {
    dispatch(
      AuctionPenAction.update({
        id: autoDraftPenId,
        autoDraftState: {
          [gate]:
            status !== GateStatus.LOCKED
              ? GateStatus.LOCKED
              : GateStatus.CLOSED,
        },
      }),
    );
  };

  const icon = status === GateStatus.LOCKED ? faLock : faLockOpen;
  return disabled ? (
    <Flasher value={icon}>
      <ButtonContainer disabled={disabled} onClick={toggleLock}>
        <Badge
          color="primary"
          badgeContent={<FontAwesomeIcon spin icon={faSpinnerThird} />}
        >
          <ButtonIcon color="black" icon={icon} style={{ fontSize: 32 }} />
        </Badge>
      </ButtonContainer>
    </Flasher>
  ) : (
    <Flasher value={icon}>
      <ButtonContainer disabled={disabled} onClick={toggleLock}>
        <ButtonIcon color="black" icon={icon} style={{ fontSize: 32 }} />
      </ButtonContainer>
    </Flasher>
  );
}

function OpenGateButton({ autoDraftPenId, gate }) {
  const pen = useSelector(getAuctionPenById(autoDraftPenId));
  const status = pen?.autoDraftState?.[gate];
  const icon = status === GateStatus.OPEN ? faDoorOpen : faDoorClosed;
  const isOnline = useSelector(getIsOnline);
  const { syncing } = pen;

  const dispatch = useDispatch();

  const toggleOpen = () => {
    dispatch(
      AuctionPenAction.update({
        id: autoDraftPenId,
        autoDraftState: {
          [gate]:
            status === GateStatus.OPEN ? GateStatus.CLOSED : GateStatus.OPEN,
        },
      }),
    );
  };

  const onlineSyncing = !!(isOnline && syncing);

  const disabled =
    status === GateStatus.LOCKED || gate === "entryGateStatus" || onlineSyncing;

  return onlineSyncing ? (
    <ButtonContainer disabled={disabled} onClick={toggleOpen}>
      <Badge
        color="primary"
        badgeContent={<FontAwesomeIcon spin icon={faSpinnerThird} />}
      >
        <ButtonIcon
          disabled={disabled}
          color="black"
          icon={icon}
          style={{ fontSize: 32 }}
        />
      </Badge>
    </ButtonContainer>
  ) : (
    <ButtonContainer disabled={disabled} onClick={toggleOpen}>
      <ButtonIcon color="black" icon={icon} style={{ fontSize: 32 }} />
    </ButtonContainer>
  );
}

function EmptyPenButton({ autoDraftPenId }) {
  const dispatch = useDispatch();

  const resetDraftingPenDisabled = useSelector(
    getIsResetDisabledByAutoDraftPenId(autoDraftPenId),
  );

  const handleResetDraftingPen = () => {
    dispatch({
      type: AUCTION_PEN.BULK_RESET_AUTO_DRAFT.REQUEST,
      penIds: [autoDraftPenId],
    });
  };

  const buttonText = useResponsiveText({
    tablet: "Reset",
    desktop: "Reset to 0 Hd",
  });

  return (
    <Button
      onClick={handleResetDraftingPen}
      disabled={resetDraftingPenDisabled}
      data-tour="resetTo0Hd"
    >
      {buttonText}
    </Button>
  );
}

const StatusColorCellTd = styled.td(
  ({ theme, backgroundColor }) => `
background-color: ${theme.colors[backgroundColor]};
color: ${theme.colors[ColorInvertMap[backgroundColor]]};`,
);

function StatusColorCell({ autoDraftPenId, children, className }) {
  const status = useSelector(getStatusByAutoDraftPenId(autoDraftPenId));
  const backgroundColor = getAutoDraftPenStatusColour(status);
  return (
    <StatusColorCellTd className={className} backgroundColor={backgroundColor}>
      {children}
    </StatusColorCellTd>
  );
}

const HEADING_DRAFT_PEN_NAME = {
  name: "heading draft-pen-name",
  title: "Name",
};

const HEADING_WEIGHED_HD = { name: "heading weighed-hd", title: "Weighed Hd" };
const HEADING_DEST_PEN = {
  name: "heading dest-pen",
  title: "Dest. Pen",
};
const HEADING_BUYER = {
  name: "heading buyer",
  title: "Buyer",
};
const HEADING_ACTIONS = {
  name: "heading actions",
  title: "Actions",
};
const HEADING_ENTRY = {
  name: "heading entry",
  title: "Entry",
};
const HEADING_DRAFT_PEN_HD = {
  name: "heading draft-pen-hd",
  title: "Pen",
};
const HEADING_EXIT = {
  name: "heading exit",
  title: "Exit",
};

const COLUMN_DRAFT_PEN = {
  name: "draft-pen-name",
  Cell: StatusColorCell,
  Content: ColumnPenName,
};
const COLUMN_BUYER_HD = {
  name: "weighed-hd",
  Content: ColumnHeadByBuyer,
};
const COLUMN_SELLING_PEN_HD = {
  name: "weighed-hd",
  Content: ColumnHeadBySellingPen,
};
const COLUMN_DEST_PEN = {
  name: "dest-pen",
  Content: ColumnDestinationPen,
};
const COLUMN_BUYER = {
  name: "buyer",
  Content: ColumnOwner,
};
const COLUMN_ACTIONS = {
  name: "actions",
  Content: EmptyPenButton,
};
const COLUMN_ENTRY = {
  name: "entry",
  Content: EntryIcons,
};
const COLUMN_DRAFT_PEN_HD = {
  name: "draft-pen-hd",
  Content: InPenCount,
};
const COLUMN_EXIT = {
  name: "exit",
  Content: ExitIcons,
};

const PRESALE_TABLE_CONFIG = {
  headings: {
    rowClasses: "heading-row pre-sale",
    columns: [
      HEADING_DRAFT_PEN_NAME,
      HEADING_WEIGHED_HD,
      HEADING_DEST_PEN,
      HEADING_ACTIONS,
      HEADING_ENTRY,
      HEADING_DRAFT_PEN_HD,
      HEADING_EXIT,
    ],
  },
  body: {
    rowClasses: "data-row pre-sale",
    columns: [
      COLUMN_DRAFT_PEN,
      COLUMN_SELLING_PEN_HD,
      COLUMN_DEST_PEN,
      COLUMN_ACTIONS,
      COLUMN_ENTRY,
      COLUMN_DRAFT_PEN_HD,
      COLUMN_EXIT,
    ],
  },
};

const POST_SALE_TABLE_CONFIG = {
  headings: {
    rowClasses: "heading-row post-sale",
    columns: [
      HEADING_DRAFT_PEN_NAME,
      HEADING_WEIGHED_HD,
      HEADING_DEST_PEN,
      HEADING_BUYER,
      HEADING_ACTIONS,
      HEADING_ENTRY,
      HEADING_DRAFT_PEN_HD,
      HEADING_EXIT,
    ],
  },
  body: {
    rowClasses: "data-row post-sale",
    columns: [
      COLUMN_DRAFT_PEN,
      COLUMN_BUYER_HD,
      COLUMN_DEST_PEN,
      COLUMN_BUYER,
      COLUMN_ACTIONS,
      COLUMN_ENTRY,
      COLUMN_DRAFT_PEN_HD,
      COLUMN_EXIT,
    ],
  },
};
export const SingleWeighTableConfiguration = {
  [SingleWeighMode.PRESALE_AUTO]: PRESALE_TABLE_CONFIG,
  [SingleWeighMode.POST_SALE_AUTO]: POST_SALE_TABLE_CONFIG,
  [SingleWeighMode.PRESALE_SEMI_AUTO]: PRESALE_TABLE_CONFIG,
  [SingleWeighMode.POST_SALE_SEMI_AUTO]: POST_SALE_TABLE_CONFIG,
  [SingleWeighMode.PRESALE_MANUAL]: PRESALE_TABLE_CONFIG,
  [SingleWeighMode.POST_SALE_MANUAL]: POST_SALE_TABLE_CONFIG,
  [SingleWeighMode.DISABLED]: POST_SALE_TABLE_CONFIG,
};
