import { AdvancedDraftingValidationStates } from "constants/draftingAttributes";

import {
  getAuctionsPlusValidationErrorsOrStatus,
  getStockliveValidationErrorsOrStatus,
} from "lib/advancedDrafting";

import AuctionsPlus from "img/AuctionsPlus";
import StockliveLogoSmall from "img/StockliveLogoSmall";

export const ExportSites = {
  AUCTIONS_PLUS: "AUCTIONS_PLUS",
  ANGUS_AUSTRALIA: "ANGUS_AUSTRALIA",
  STOCK_LIVE: "STOCK_LIVE",
  AGRINOUS: "AGRINOUS",
  LIST_2_SELL: "LIST_2_SELL",
  FARMGATE: "FARMGATE",
  THE_HERD: "THE_HERD",
};

export const ExportSitesStatuses = {
  SUCCESS: "success",
};

export const ExportSiteHeaders = {
  [ExportSites.AUCTIONS_PLUS]: "AuctionsPlus",
  [ExportSites.ANGUS_AUSTRALIA]: "Angus Australia",
  [ExportSites.STOCK_LIVE]: "Stock Live",
  [ExportSites.AGRINOUS]: "AgriNous",
  [ExportSites.LIST_2_SELL]: "List 2 Sell",
  [ExportSites.FARMGATE]: "Farm Gate",
  [ExportSites.THE_HERD]: "The Herd",
};

const defaultStatus = () => AdvancedDraftingValidationStates.SUCCESS;

export const ExportSiteValidationErrorOrStatusGetter = {
  [ExportSites.AUCTIONS_PLUS]: getAuctionsPlusValidationErrorsOrStatus,
  [ExportSites.ANGUS_AUSTRALIA]: defaultStatus,
  [ExportSites.STOCK_LIVE]: getStockliveValidationErrorsOrStatus,
  [ExportSites.AGRINOUS]: defaultStatus,
  [ExportSites.LIST_2_SELL]: defaultStatus,
  [ExportSites.FARMGATE]: defaultStatus,
  [ExportSites.THE_HERD]: defaultStatus,
};

export const ExportSiteIcons = {
  [ExportSites.AUCTIONS_PLUS]: AuctionsPlus,
  [ExportSites.STOCK_LIVE]: StockliveLogoSmall,
};
