import reduceReducers from "reduce-reducers";

import { BILLING_TAGS } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeBillingTag } from "lib/serializers/billingTags";

const fetch = apiModelOfflineFetchReducer(BILLING_TAGS, {
  deserializer: deserializeBillingTag,
});

const billingTagsReducer = reduceReducers(fetch);

export default billingTagsReducer;
