import React, { memo, useContext, useLayoutEffect } from "react";

import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { deleteUnassignedScans, keepUnassignedScans } from "actions";

import ScanningColumn, {
  buildDuplicateTakeableEidList,
} from "components/ScanningScreen/ScanningColumn";

import { PenTypes } from "constants/auctionPens";

import {
  getConnectedDevice,
  getConsignments,
  getSaleLotById,
  getSaleLots,
  getSaleyardScanSaleLots,
  getScans,
  getUnassignedScans,
} from "selectors";

import {
  WeighBridgeSaleDispatchContext,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";

function WeighBridgeScanningColumnComponent(props) {
  const {
    consignmentId: formConsignmentId,
    eids,
    isEidsValid: wasEidsValid,
    saleLotId,
  } = props;

  const { updateEids, updateSelectedSaleLotId } = useContext(
    WeighBridgeSaleDispatchContext,
  );

  const saleLot = useSelector(getSaleLotById(saleLotId));
  const unallocatedScans = useSelector(getUnassignedScans);
  const scans = useSelector(getScans);
  const saleLots = useSelector(getSaleLots);
  const saleyardScanSaleLots = useSelector(getSaleyardScanSaleLots);
  const consignments = useSelector(getConsignments);
  const { name: deviceName } = useSelector(getConnectedDevice) || {};

  const dispatch = useDispatch();

  const consignmentId = formConsignmentId || saleLot?.consignment_id;

  const { eids: eidsList, duplicateEids } = buildDuplicateTakeableEidList(
    Object.values(unallocatedScans),
    scans,
    saleLots,
    saleyardScanSaleLots,
    consignments,
    null,
    "EID",
    "ignoreDuplicate",
    false,
    consignmentId,
  );

  // If the duplicate scan is from the Sale Lot which is currently selected, move it from the duplicateEids list to the eidsList
  for (let i = duplicateEids.length - 1; i >= 0; i--) {
    const eid = duplicateEids[i];
    if (scans[eid].sale_lot_id === saleLotId) {
      eidsList.push(duplicateEids.splice(i, 1)[0]);
    }
  }
  const isEidsValid = duplicateEids.length === 0;

  useLayoutEffect(() => {
    if (!isEqual(eidsList, eids) || wasEidsValid !== isEidsValid) {
      updateEids(eidsList, isEidsValid, eidsList.length > 0);
    }
  }, [eids, eidsList, isEidsValid, updateEids, wasEidsValid]);

  function onIgnore(eid) {
    dispatch(deleteUnassignedScans([eid]));
  }

  function onIgnoreAll() {
    dispatch(deleteUnassignedScans(duplicateEids));
  }

  function onKeep(eid) {
    dispatch(keepUnassignedScans([eid]));
  }

  function onKeepAll() {
    dispatch(keepUnassignedScans(duplicateEids));
  }

  function onSelect(eid) {
    if (scans[eid]?.sale_lot_id) {
      updateSelectedSaleLotId(scans[eid].sale_lot_id);
    }
  }

  return (
    <ScanningColumn
      consignmentId={consignmentId}
      disallowTaken={false}
      draftName={deviceName || "Weighbridge Scanner"}
      duplicateEidsOrNlisIds={duplicateEids}
      eidsOrNlisIds={eids}
      isExpanded
      isSaleLotSelected={Boolean(saleLotId)}
      onIgnore={onIgnore}
      onIgnoreAll={onIgnoreAll}
      onKeep={onKeep}
      onKeepAll={onKeepAll}
      onSelect={onSelect}
      showFooter={false}
      showIgnore
      showKeep
      showKeepAll
      showSelect
      penType={PenTypes.SELLING} // passed in to handle scan lot config
    />
  );
}
const WeighBridgeScanningColumn = memo(WeighBridgeScanningColumnComponent);

function StateAdapter() {
  const {
    eids,
    isEidsValid,
    saleLot,
    selectedSaleLotId: saleLotId,
  } = useContext(WeighBridgeSaleStateContext);

  return (
    <WeighBridgeScanningColumn
      consignmentId={saleLot?.consignmentId}
      eids={eids}
      isEidsValid={isEidsValid}
      saleLotId={saleLotId}
    />
  );
}

export default memo(StateAdapter);
