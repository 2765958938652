import get from "lodash/get";

import { Column } from "constants/columns";

import { formatDecimal } from "lib";

export const kilogramsGetter = params => {
  if (!params.data) {
    return null;
  }

  const value = get(params.data, params.colDef.field);
  return typeof value === "number" ? value / 1000 : 0;
};

export const kilogramsFormatter = ({ value }) =>
  typeof value === "number" && value > 0 ? formatDecimal(value, 2) : "";

export const totalWeightKilogramsGetter = params => {
  if (!params.data) {
    return null;
  }

  // Because it's weight, not mass...they are different
  const totalWeightGrams = get(params.data, "total_mass_grams");
  const estimatedAverageWeightGrams = get(
    params.data,
    "estimatedAverageMassGrams",
  );
  const quantity = get(params.data, Column.QUANTITY);
  const indicativeValue =
    quantity > 0 ? estimatedAverageWeightGrams * quantity : 0;
  const value = totalWeightGrams || indicativeValue;

  return typeof value === "number" ? value / 1000 : 0;
};
