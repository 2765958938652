import React, { memo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  OpenGeneralCell,
  OpenPenningCell,
  OpenSaleCell,
  OpenScanningCell,
} from "components/DeploymentSaleSummaries/SaleLotCells";
import { SummaryReactTable } from "components/DeploymentSaleSummaries/SummaryTable";

import { formatPercentage } from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import {
  getAuctionPens,
  getBusinesses,
  getProperties,
  getSaleLotExceptionsByDeploymentSaleId,
  getSaleLots,
  selectIsBalancedBySaleLotIdLookup,
  selectScannedCountBySaleLotIdLookup,
  selectScannedPercentBySaleLotIdLookup,
} from "selectors";

import { ExceptionsCell } from "./CellButton";

const SALE_LOT_EXCEPTIONS_COLUMNS = [
  { Header: "Buyer Name", accessor: "buyerName", Cell: OpenSaleCell },
  { Header: "Pen #", accessor: "pen", Cell: OpenPenningCell },
  { Header: "Drafted HC", accessor: "quantity", Cell: OpenGeneralCell },
  { Header: "Sale Lot EIDs", accessor: "scanQuantity", Cell: OpenScanningCell },
  { Header: "Act. Level", accessor: "scanPercentage", Cell: OpenScanningCell },
  { Header: "Delivered HC", accessor: "deliveredQuantity", Cell: OpenSaleCell },
  { Header: "Buyer PIC", accessor: "buyerPic", Cell: OpenSaleCell },
  { Header: "Balanced", accessor: "isBalanced", Cell: OpenGeneralCell },
  { Header: "Exceptions", accessor: "exceptions", Cell: ExceptionsCell },
];

function SaleLotExceptionsTableComponent(props) {
  const { deploymentSaleId } = props;
  const saleLotExceptions = useSelector(
    getSaleLotExceptionsByDeploymentSaleId(deploymentSaleId),
  );

  const saleLots = useSelector(getSaleLots);
  const auctionPens = useSelector(getAuctionPens);
  const isBalancedBySaleLotId = useSelector(selectIsBalancedBySaleLotIdLookup);
  const scannedPercentBySaleLotId = useSelector(
    selectScannedPercentBySaleLotIdLookup,
  );
  const scannedCountBySaleLotId = useSelector(
    selectScannedCountBySaleLotIdLookup,
  );
  const properties = useSelector(getProperties);
  const businesses = useSelector(getBusinesses);

  const data = saleLotExceptions.map(({ saleLotId, exceptions }) => {
    const saleLot = saleLots[saleLotId];
    const {
      buyer_id: buyerId,
      auction_pen_id: auctionPenId,
      destination_property_id: destinationPropertyId,
      quantity,
      quantity_delivered: deliveredQuantity,
    } = saleLot;
    const auctionPen = auctionPens[auctionPenId];
    const scannedPercent = scannedPercentBySaleLotId[saleLotId];
    const scannedCount = scannedCountBySaleLotId[saleLotId];

    return {
      buyerName: businesses[buyerId]?.name,
      deliveredQuantity,
      buyerPic: properties[destinationPropertyId]?.PIC,
      exceptions,
      isBalanced: isBalancedBySaleLotId[saleLotId] ? "Balanced" : "Unbalanced",
      pen: getAuctionPenDisplayName(auctionPen),
      quantity,
      saleLotId,
      scannedCount,
      scannedPercent: formatPercentage(scannedPercent),
    };
  });

  return (
    <SummaryReactTable data={data} columns={SALE_LOT_EXCEPTIONS_COLUMNS} />
  );
}

SaleLotExceptionsTableComponent.propTypes = {
  deploymentSaleId: PropTypes.number.isRequired,
};

export default memo(SaleLotExceptionsTableComponent);
