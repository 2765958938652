import { sortBy, uniq } from "lodash";
import { createSelector } from "reselect";

import {
  FieldToGroupLabelDescriptionMap,
  SaleSearchFields,
} from "constants/globalSearch";
import { SaleStatusLabels } from "constants/sale";

import { getSaleStatus } from "lib/sale";

import { getActiveRole, getSales, getSpecies } from "selectors";

const createSaleOption = (labelField, valueField, value) => ({
  label: labelField,
  value: {
    field: valueField,
    value,
  },
});

const createSaleSearchSelector = (valueField, mapFunction) =>
  createSelector([getSales], sales => {
    return sortBy(
      uniq(Object.values(sales).map(mapFunction)).map(option =>
        createSaleOption(option, valueField, option),
      ),
      "label",
    );
  });

const selectSaleSearchSaleIds = createSaleSearchSelector(
  SaleSearchFields.SaleId,
  sale => sale.livestocksale_id,
);

const selectSaleSearchSaleDate = createSaleSearchSelector(
  SaleSearchFields.Date,
  sale => sale.date,
);

const selectSaleSearchSaleType = createSaleSearchSelector(
  SaleSearchFields.SaleType,
  sale => sale.sale_type,
);

const selectSaleSearchSaleSaleyard = createSaleSearchSelector(
  SaleSearchFields.Saleyard,
  sale => sale.saleyard_name,
);

const selectSaleSearchSaleTitle = createSaleSearchSelector(
  SaleSearchFields.Title,
  sale => sale.sale_title,
);

const selectSaleSearchSaleSpecies = createSelector(
  [getSpecies, getSales],
  (species, sales) => {
    return sortBy(
      uniq(Object.values(sales).map(sale => sale.species_id)).map(
        saleSpecies => {
          const speciesName = species[saleSpecies]?.name || "";
          return createSaleOption(
            speciesName,
            SaleSearchFields.Species,
            saleSpecies,
          );
        },
      ),
      "label",
    );
  },
);

const selectSaleSearchSaleStatus = createSelector(
  [getSales, getActiveRole],
  (sales, role) => {
    const statusOptions = Object.values(sales).map(sale => {
      const status = getSaleStatus(sale, role);
      return status;
    });
    const uniqueStatusOptions = [...new Set(statusOptions)];
    const sortedOptions = uniqueStatusOptions.sort();
    const statusOptionLabels = sortedOptions.map(
      option => SaleStatusLabels[option],
    );
    const optionsWithLabel = sortedOptions.map((option, index) =>
      createSaleOption(
        statusOptionLabels[index],
        SaleSearchFields.Status,
        option,
      ),
    );
    return sortBy(optionsWithLabel, "label");
  },
);

export const selectSaleSearchOptions = createSelector(
  [
    selectSaleSearchSaleDate,
    selectSaleSearchSaleIds,
    selectSaleSearchSaleSaleyard,
    selectSaleSearchSaleSpecies,
    selectSaleSearchSaleStatus,
    selectSaleSearchSaleTitle,
    selectSaleSearchSaleType,
  ],
  (
    saleDateOptions,
    saleIdOptions,
    saleSaleyardOptions,
    saleSpeciesOptions,
    saleStatusOptions,
    salesTitleOptions,
    saleTypeOptions,
  ) => {
    return [
      {
        label: FieldToGroupLabelDescriptionMap[SaleSearchFields.Date],
        field: SaleSearchFields.Date,
        options: saleDateOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[SaleSearchFields.SaleId],
        field: SaleSearchFields.SaleId,
        options: saleIdOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[SaleSearchFields.Saleyard],
        field: SaleSearchFields.Saleyard,
        options: saleSaleyardOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[SaleSearchFields.Species],
        field: SaleSearchFields.Species,
        options: saleSpeciesOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[SaleSearchFields.Status],
        field: SaleSearchFields.Status,
        options: saleStatusOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[SaleSearchFields.Title],
        field: SaleSearchFields.Title,
        options: salesTitleOptions,
      },
      {
        label: FieldToGroupLabelDescriptionMap[SaleSearchFields.SaleType],
        field: SaleSearchFields.SaleType,
        options: saleTypeOptions,
      },
    ];
  },
);
