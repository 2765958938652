import { createSelector } from "reselect";

import {
  getEntryKeyIsUUID,
  getInterestSettings,
  objectMapper,
} from "selectors";

import { createLookupCombiner, createLookupSelectors } from "selectors/lib";

import { getActiveLivestockAgentDeployment } from "./deployments";
import {
  selectInterestSettingsIdsByBusinessIdLookup,
  selectInterestSettingsIdsByDeploymentIdLookup,
} from "./indexes";
import { getBusinesses } from "./root";

export const interestSettingDeploymentMapper = (
  interestSettingCbids,
  interestSettings,
) =>
  interestSettingCbids
    .filter(cbId => !interestSettings[cbId].businessId)
    .map(cbId => interestSettings[cbId]) || [];

export const [
  selectInterestSettingsByDeploymentIdLookup,
  getInterestSettingsByDeploymentId,
] = createLookupSelectors(
  [selectInterestSettingsIdsByDeploymentIdLookup, getInterestSettings],
  createLookupCombiner(interestSettingDeploymentMapper),
);

export const [
  selectInterestSettingsByBusinessIdLookup,
  getInterestSettingsByBusinessId,
] = createLookupSelectors(
  [selectInterestSettingsIdsByBusinessIdLookup, getInterestSettings],
  createLookupCombiner(objectMapper, getEntryKeyIsUUID),
);

export const selectActiveInterestRateSettingsByBusinessIdLookup =
  createSelector(
    [
      getBusinesses,
      getActiveLivestockAgentDeployment,
      selectInterestSettingsByDeploymentIdLookup,
      selectInterestSettingsByBusinessIdLookup,
    ],
    (
      businesses,
      deployment,
      interestSettingsByDeploymentId,
      interestSettingsByBusinessId,
    ) => {
      const deploymentInterestSettings =
        interestSettingsByDeploymentId[deployment.id];
      return Object.values(businesses).reduce((acc, business) => {
        acc[business.id] = business.overrideInterestSettings
          ? interestSettingsByBusinessId[business.id]
          : deploymentInterestSettings;
        return acc;
      }, []);
    },
  );

export const getActiveInterestRateSettingsByBusinesId = businessId => state =>
  selectActiveInterestRateSettingsByBusinessIdLookup(state)[businessId] || [];

export const selectResolvedInterestSettingsByBusinessIdLookup = createSelector(
  [
    getBusinesses,
    selectInterestSettingsByBusinessIdLookup,
    selectInterestSettingsByDeploymentIdLookup,
    getActiveLivestockAgentDeployment,
  ],
  (
    businesses,
    interestSettingsByBusinessIdLookup,
    interestSettingsByDeploymentIdLookup,
    activeLivestockAgentDeployment,
  ) =>
    Object.entries(businesses).reduce((acc, [businessId, business]) => {
      acc[businessId] = interestSettingsByBusinessIdLookup[business.id]
        ? interestSettingsByBusinessIdLookup[business.id]
        : interestSettingsByDeploymentIdLookup[
            activeLivestockAgentDeployment?.id
          ];
      return acc;
    }, {}),
);
