import React from "react";

import { useField } from "formik";

import { BaseInput } from "components/Form/FormikControls";

import { isMobileSafariType } from "lib/devices";

export const NVDNumberField = React.memo(
  ({ name, label, required, disabled = false }) => {
    const [field, meta, helpers] = useField(name);

    const onChange = e => {
      if (e.target.value.length > 9) {
        return;
      }
      helpers.setTouched(true);
      field.onChange(name)(e);
    };

    const error = meta.touched && meta.error;

    // Chrome desktop: input type=text allows text and numbers. ✅
    // Chrome desktop: input type=number doesnt allow text. ❌
    // Safari mobile: input type=text allows text and numbers but doesnt show the numbers on the keyboard by default. ❌
    // Safari mobile: inputMode="numeric" only shows numeric keypad, cant enter text. ❌
    // Safari mobile: input type=number allows text and numbers and shows the numbers on the keyboard by default. ✅
    // Android mobile: input type=number doesnt allow text. ❌
    // Android mobile: type=text doesnt show numbers on the keyboard.❌ So we need to override inputmode=numeric  ✅

    // So 🤠
    // For mobile safari, use type="number", for everything else use type="text" ✅

    // https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/ Gives testable examples for inputs.
    // IOS has option for number and text by default, but android is either number or text with the option to change to number.
    // Users can turn on number row in android keyboard (Gboard) settings to achieve similar result to IOS

    const type = isMobileSafariType() ? "number" : "text";
    return (
      <BaseInput
        required={required}
        label={label}
        name={name}
        maxLength={9}
        type={type}
        value={field.value || undefined}
        error={error}
        onChange={onChange}
        disableAutoComplete
        disabled={disabled}
      />
    );
  },
);
