import reduceReducers from "reduce-reducers";

import { DEPLOYMENT, TRADING_TERM } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeTradingTerm as deserializer } from "lib/serializers/tradingTerms";

function deploymentReducer(state, action) {
  switch (action.type) {
    case DEPLOYMENT.UPDATE.REQUEST: {
      const { payload } = action;

      const { trading_terms, id } = payload;
      if (Array.isArray(trading_terms) && id) {
        const newTermsById = trading_terms.reduce((byId, trading_term) => {
          byId[trading_term.id] = deserializer({
            ...trading_term,
            deployment_id: id,
          });
          return byId;
        }, {});

        const byId = Object.values(state.byId).reduce((acc, trading_term) => {
          if (
            !acc[trading_term.id] &&
            (!trading_term.deploymentId || trading_term.deploymentId !== id)
          ) {
            acc[trading_term.id] = trading_term;
          }

          return acc;
        }, newTermsById);

        return {
          ...state,
          byId,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

const fetch = apiModelOfflineFetchReducer(TRADING_TERM, {
  deserializer,
});

const tradingTermsReducer = reduceReducers(fetch, deploymentReducer);

export default tradingTermsReducer;
