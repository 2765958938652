import React from "react";

import { QuestionnaireRow } from "components/AttachmentForm/Components";
import { suggestedValueMap } from "components/AttachmentForm/constants";
import {
  NVDOptionToggler,
  DatePurchasedOptionsToggler,
  NVD_OPTIONS,
} from "components/AttachmentForm/NVDOptionToggles";
import { Input } from "components/Form/FormikControls";
import { Column } from "components/Layout";

export const C0413 = ({ formikProps }) => (
  <Column>
    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.treated_with_hgp" label="1." />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.fed_feed_containing_animal_fats"
        label="2."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.owned_since_birth" label="3." />
      {formikProps.values.declaration.owned_since_birth === NVD_OPTIONS.NO && (
        <QuestionnaireRow>
          <DatePurchasedOptionsToggler
            name="declaration.owned_since_birth_duration"
            label=""
          />
        </QuestionnaireRow>
      )}
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.fed_byproduct_stockfeeds"
        label="4."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.chemical_residue_restrictions"
        label="5."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.veterinary_treatment" label="6." />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.consumed_material_in_withholding_period"
        label="7."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.spray_drift_risk" label="8." />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <Input
        name="declaration.additional_information"
        label="9."
        suggestedValueFieldName={
          suggestedValueMap["declaration.additional_information"]
        }
      />
    </QuestionnaireRow>
  </Column>
);
