import React from "react";

import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { getSaleById } from "selectors";

import { SaleRoutes } from "./SaleRoutes";
import SaleTabs from "./SaleTabs";

export function LivestockSale() {
  const match = useRouteMatch();
  const { saleId: saleIdRaw, saleyard } = match.params;
  const sale = useSelector(getSaleById(+saleIdRaw));

  const {
    livestocksale_id: saleId,
    sale_type: saleType,
    saleyard_id: saleyardId,
  } = sale;

  return (
    <>
      <SaleTabs />
      <SaleRoutes
        saleId={saleId}
        saleType={saleType}
        saleyardId={saleyardId}
        saleyardName={saleyard}
      />
    </>
  );
}
