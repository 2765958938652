import { useSelector } from "react-redux";

import { selectMarkOptionsByDeploymentIdLookup } from "selectors";

/** *
 * Conditional Renderer for checking if the deployment
 * that drafted the sale lot has deployment marks configured
 * for the species used.
 */
export const HasDeploymentMarks = ({ deploymentId, children }) => {
  const markOptions =
    useSelector(selectMarkOptionsByDeploymentIdLookup)[deploymentId] || [];

  if (markOptions.length === 0) {
    return null;
  } else {
    return children;
  }
};
