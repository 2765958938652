import React from "react";

import { faTooth } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";

import { CheckBox, Input } from "components/Form/FormikControls";
import { Row } from "components/Layout";
import { Paper } from "components/Paper";
import { MediumText as Heading } from "components/Text";

import { Error, Icon } from "./components";

export const DentitionSection = ({
  errorMessage,
  zeroLabel = "Milk",
  readOnly,
}) => (
  <Grid item xs={12}>
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Row flexGrow>
            <Icon icon={faTooth} color="gray44" />
            <Heading>Dentition (hd)</Heading>
            {errorMessage && <Error>{errorMessage}</Error>}
          </Row>
        </Grid>
        <Grid item xs={12}>
          <CheckBox
            name="draftingAttributes.isNotMouthed"
            label="Not Mouthed"
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            label={zeroLabel}
            name="draftingAttributes.dentition0"
            type="number"
            emptyValue={null}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            label="2T"
            name="draftingAttributes.dentition2"
            type="number"
            emptyValue={null}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            label="4T"
            name="draftingAttributes.dentition4"
            type="number"
            emptyValue={null}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            label="6T"
            name="draftingAttributes.dentition6"
            type="number"
            emptyValue={null}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            label="8T"
            name="draftingAttributes.dentition8"
            type="number"
            emptyValue={null}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            label="Broken"
            name="draftingAttributes.dentitionBroken"
            type="number"
            emptyValue={null}
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);
