import { ADD_SALE_LOT_COMMIT, SALE_LOT } from "constants/actionTypes";

const initialState = {
  saleLots: {},
};

const synced = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SALE_LOT_COMMIT:
      return {
        ...state,
        saleLots: {
          ...state.saleLots,
          [action.payload.id]: { saleLotId: action.payload.id },
        },
      };
    case SALE_LOT.UPDATE.SUCCESS:
      return {
        ...state,
        saleLots: {
          ...state.saleLots,
          [action.meta.saleLotId]: action.meta,
        },
      };
    default:
      return state;
  }
};

export default synced;
