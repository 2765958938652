import * as PropTypes from "prop-types";

import { useIsMobile } from "hooks";

export function ForMobile(props) {
  const { children } = props;
  if (useIsMobile()) {
    return children;
  }
  return null;
}

ForMobile.propTypes = { children: PropTypes.node };
