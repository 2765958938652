import React from "react";

import DeliveryPenModal from "components/DeliveryPenModal";

import { ModalTypes } from "constants/navigation";

function Adapter(props) {
  const { hash } = props;
  const hashParams = hash[ModalTypes.DeliveryPen] || null;
  const saleLotIds = JSON.parse(hashParams);

  const { returnTo } = hash;

  return (
    <DeliveryPenModal
      saleLotIds={saleLotIds}
      fullScreen={false}
      returnTo={returnTo}
    />
  );
}

export default Adapter;
