import reduceReducers from "reduce-reducers";

import {
  BUSINESS,
  DEPLOYMENT,
  VENDOR_COMMISSION_BAND,
} from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeVendorCommissionBand } from "lib/serializers/vendorCommissionBands";

const fetch = apiModelOfflineFetchReducer(VENDOR_COMMISSION_BAND, {
  deserializer: deserializeVendorCommissionBand,
});

function deploymentBusinessReducer(state, action) {
  switch (action.type) {
    case BUSINESS.UPDATE.REQUEST:
    case BUSINESS.CREATE.REQUEST: {
      const { payload } = action;
      const { vendor_commission_bands, deployment_business_id } = payload;
      // Deployment business id... shouldn't really be IN the payload... but it is.  So use it.
      if (Array.isArray(vendor_commission_bands) && deployment_business_id) {
        const newBandsById = vendor_commission_bands.reduce((byId, band) => {
          byId[band.id] = deserializeVendorCommissionBand({
            ...band,
            deployment_business_id,
          });
          return byId;
        }, {});

        const byId = Object.values(state.byId).reduce((acc, band) => {
          // Re-add if:
          // not in the new dataset
          // not linked to the deployment business
          // not linked to any deployment business (ie, linked to a deployment)
          if (
            !acc[band.id] &&
            (!band.deploymentBusinessId ||
              band.deploymentBusinessId !== deployment_business_id)
          ) {
            // Not in the payload and tied to this deployment business
            acc[band.id] = band;
          }

          return acc;
        }, newBandsById);
        return {
          ...state,
          byId,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

function deploymentReducer(state, action) {
  switch (action.type) {
    case DEPLOYMENT.UPDATE.REQUEST: {
      const { payload } = action;
      const { vendor_commission_bands, id } = payload;
      if (Array.isArray(vendor_commission_bands) && id) {
        const newBandsById = vendor_commission_bands.reduce((byId, band) => {
          byId[band.id] = deserializeVendorCommissionBand({
            ...band,
            deployment_id: id,
          });
          return byId;
        }, {});

        const byId = Object.values(state.byId).reduce((acc, band) => {
          // Re-add if:
          // not in the new dataset
          // not linked to the deployment
          // not linked to any deployment (ie, linked to a deployment business)
          if (
            !acc[band.id] &&
            (!band.deploymentId || band.deploymentId !== id)
          ) {
            // Not in the payload and tied to this deployment
            acc[band.id] = band;
          }

          return acc;
        }, newBandsById);
        return {
          ...state,
          byId,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

const vendorCommissionBandReducer = reduceReducers(
  fetch,
  deploymentBusinessReducer,
  deploymentReducer,
);

export default vendorCommissionBandReducer;
