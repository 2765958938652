import { call, put, takeEvery } from "redux-saga/effects";

import { receiveAgencies, requestAgenciesFailure } from "actions/agencies";

import { GET_AGENCIES } from "constants/actionTypes";

import { api } from "./api";

function* fetchAgencies(action) {
  try {
    const agenciesPromise = yield call(api.get, `/v2/livestock_agencies/`);
    yield put(receiveAgencies(yield agenciesPromise));
  } catch (e) {
    yield call(api.handleFetchError, e, "agencies", action);
    yield put(requestAgenciesFailure(e));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_AGENCIES.REQUEST, fetchAgencies);
}
