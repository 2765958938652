import React, { memo } from "react";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SlimButton } from "components/Button";
import { Row } from "components/Layout";

import { scanLotConfig } from "lib/scanLot";

const Wrapper = styled.div`
  padding: 12px;
  min-height: ${({ extraHeight }) => (extraHeight ? 96 : 72)}px;
  white-space: nowrap;
  overflow-x: hidden;
  border-bottom: 1px solid rgba(151, 151, 151, 0.25);
`;

const Title = styled.div`
  font-size: ${({ isExpanded }) => (isExpanded ? 14 : 12)}px;
  transition: font-size 200ms ease-in-out;
`;

const ScannedCount = styled.div`
  align-self: ${({ isExpanded }) => (isExpanded ? "center" : "flex-end")};
  font-size: ${({ isExpanded }) => (isExpanded ? 36 : 18)}px;
  font-weight: ${({ isExpanded }) => (isExpanded ? 500 : 700)};
  line-height: 1;
  transition: all 200ms ease-in-out;
`;

const FaIcon = styled(FontAwesomeIcon)`
  margin-right: 9px;
  color: ${({ theme }) => theme.colors.error};
`;

const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.error};
`;

const ButtonText = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  margin: 2px 6px;
`;

const Or = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.error};
  padding: 0 12px;
`;

const DuplicateCount = styled.span`
  color: ${({ theme }) => theme.colors.error};
`;

export function ScanListHeaderComponent(props) {
  const {
    draftName,
    isExpanded,
    eidCount,
    duplicateCount,
    onKeepAll,
    onIgnoreAll,
    showKeepAll,
    showIgnoreAll,
    isArrivalOrPenScanning,
    scanLotId,
    penType,
  } = props;

  const { getEidsByLotId, getLotById } = scanLotConfig(penType);

  const { values } = useFormikContext() || {};

  const scanLot = useSelector(getLotById(scanLotId));
  const savedScanLotEids = useSelector(getEidsByLotId(scanLotId));

  const eidCountIncludingDuplicateCountAndSavedEidsCount =
    ((scanLotId && savedScanLotEids?.length) || 0) + eidCount + duplicateCount;

  // show the scanned count against the animal head for pen and arrival scanning
  let scanLotTallyString = `${eidCountIncludingDuplicateCountAndSavedEidsCount}`;
  if (scanLot || values?.quantity) {
    scanLotTallyString = scanLotTallyString.concat(
      `${"/"}${values?.quantity || scanLot?.quantity}`,
    );
  }

  return (
    <Wrapper extraHeight={isExpanded && duplicateCount > 0} justifyBetween>
      <Row justifyBetween style={{ flexGrow: 1 }}>
        <Title isExpanded={isExpanded}>{draftName}</Title>
        <ScannedCount isExpanded={isExpanded}>
          {/* arrival and pen scanning we want to show the total tally of scans (both duplicates and new ones) in one count */}
          {isArrivalOrPenScanning ? (
            scanLotTallyString
          ) : (
            <>
              {" "}
              {eidCount}
              {duplicateCount > 0 && (
                <>
                  /<DuplicateCount>{duplicateCount}</DuplicateCount>
                </>
              )}
            </>
          )}
        </ScannedCount>
      </Row>

      {isExpanded && duplicateCount > 0 && (
        <Row justifyBetween alignCenter>
          <Row alignCenter>
            <FaIcon icon={faExclamationCircle} />
            <ErrorText>
              {duplicateCount} duplicate EID{duplicateCount !== 1 && "s"}
            </ErrorText>
          </Row>
          <Row alignCenter>
            {showKeepAll && (
              <SlimButton color="error" onClick={onKeepAll}>
                <ButtonText>Keep all</ButtonText>
              </SlimButton>
            )}
            {showKeepAll && showIgnoreAll && <Or>or</Or>}
            {showIgnoreAll && (
              <SlimButton color="error" onClick={onIgnoreAll}>
                <ButtonText>Ignore all</ButtonText>
              </SlimButton>
            )}
          </Row>
        </Row>
      )}
    </Wrapper>
  );
}

ScanListHeaderComponent.propTypes = {
  draftName: PropTypes.string,
  isExpanded: PropTypes.bool,
  eidCount: PropTypes.number.isRequired,
  duplicateCount: PropTypes.number.isRequired,
  onKeepAll: PropTypes.func,
  onIgnoreAll: PropTypes.func,
  showKeepAll: PropTypes.bool,
  showIgnoreAll: PropTypes.bool,
};

export default memo(ScanListHeaderComponent);
