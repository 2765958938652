import queryString from "query-string";

import { SALE_SUB_TYPE } from "constants/actionTypes";

import { serializeSaleSubType } from "lib/serializers/saleSubTypes";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/sale-sub-types/", id, action),
    query: {
      changesSince,
    },
  });

export const SaleSubTypeAction = offlineActionCreator(
  SALE_SUB_TYPE,
  serializeSaleSubType,
  urlCreator,
  false,
);
