import React, { useMemo } from "react";

import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { Input, Label } from "components/Form/FormikControls";
import { InformationPanel } from "components/Layout";
import { Info } from "components/LotCard/Elements";

import { getBuyerInformationString } from "lib/businesses";

import {
  getBidders,
  getBuyerWaysByBusinessId,
  selectBidderByBuyerInformation,
} from "selectors";

export const QuickSellBidderNumberInput = ({
  disabled = false,
  autoFocus = false,
  inlineButton = null,
  enableTemporaryBusinessCreation = false,
  setBidderNumberFromBuyerDetails = false,
}) => {
  const formikProps = useFormikContext();

  const bidderLookup = useSelector(getBidders);

  const { values, setFieldValue, touched } = formikProps;

  const { bidderNumber, buyerId, destinationPropertyId, buyerWay } = values;

  const bidder = useMemo(
    () =>
      Object.values(bidderLookup).find(bidder => {
        return (
          String(bidder.registrationNumber) === String(bidderNumber) &&
          !!bidder.businessId
        );
      }) || {},
    [bidderLookup, bidderNumber],
  );

  const buyerInformationString = getBuyerInformationString(
    buyerId,
    destinationPropertyId,
    buyerWay?.name,
  );
  const bidderByBuyerInformationLookup = useSelector(
    selectBidderByBuyerInformation,
  );
  const existingBidder = bidderByBuyerInformationLookup[buyerInformationString];

  const showUpdateBidderNote =
    existingBidder &&
    String(existingBidder.registrationNumber) !== String(bidderNumber);

  const showCreateBidderNote =
    !existingBidder && !!bidderNumber && touched.bidderNumber;

  const buyerWays =
    useSelector(getBuyerWaysByBusinessId(bidder.businessId)) || [];

  const existingBuyerWay = buyerWays.find(bw => bw.name === bidder.buyerWay);

  React.useEffect(() => {
    if (!!bidderNumber && !!bidder) {
      if (bidder.businessId) {
        setFieldValue("buyerId", bidder.businessId);
      } else {
        setFieldValue("buyerId", null);
      }

      if (existingBuyerWay) {
        // If the buyer way already exists, use the existing.
        setFieldValue("buyerWay", {
          id: existingBuyerWay.id,
          name: existingBuyerWay.name,
        });
      } else if (bidder.buyerWay) {
        // If the buyer way does not exist, but the bidder has a buyer way, set the name so it gets created.
        setFieldValue("buyerWay", {
          name: bidder.buyerWay,
        });
      } else {
        setFieldValue("buyerWay", null);
      }

      if (bidder.defaultPropertyId) {
        setFieldValue("destinationPropertyId", bidder.defaultPropertyId);
      } else {
        setFieldValue("destinationPropertyId", null);
      }
    }
  }, [bidder, existingBuyerWay, bidderNumber, setFieldValue]);

  React.useEffect(() => {
    // if we are creating temp businesses - populate bidder number if we find a match
    // from the buyer details. if the details change, and there's no match - clear it
    if (setBidderNumberFromBuyerDetails) {
      if (existingBidder) {
        setFieldValue("bidderNumber", existingBidder.registrationNumber);
      } else if (buyerId || buyerWay || destinationPropertyId) {
        setFieldValue("bidderNumber", null);
      }
    }
  }, [
    existingBidder,
    buyerId,
    buyerWay,
    destinationPropertyId,
    setBidderNumberFromBuyerDetails,
    setFieldValue,
  ]);

  return (
    <>
      <Label inlineButton={inlineButton}>Bidder Number</Label>
      <Input
        name="bidderNumber"
        disabled={disabled}
        autoFocus={autoFocus}
        type="number"
      />
      {!enableTemporaryBusinessCreation && showUpdateBidderNote && (
        <Info className="p-1">
          <em>
            Note: Setting a new bidder number will update the current bidder
            registration number for this Buyer/Way/PIC combination
          </em>
        </Info>
      )}
      {enableTemporaryBusinessCreation && showCreateBidderNote && (
        <Grid item xs={12}>
          <InformationPanel className="mt-8">
            You&apos;ve entered a new Bidder Number. <br /> A temporary Business
            will be created with a bidder registration on Save. You can update
            this Business later.
          </InformationPanel>
        </Grid>
      )}
    </>
  );
};
