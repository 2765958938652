import React from "react";

import { useTranslatedSaleTypeText } from "hooks";

/* Allows translation of simple text based on the current sale type.
 * For example an Over the Hooks sale should call a "Consignment" a "Vendor".
 */
export const TranslatedSaleTypeLabel = React.memo(
  ({ label, saleTypeOverride }) =>
    useTranslatedSaleTypeText(label, saleTypeOverride),
);
