import { simpleUserSerializerMap } from "lib/serializers/simpleUser";
import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const eContractSerializeMap = {
  agentAcceptedBy: simpleUserSerializerMap,
  agentAcceptedOn: "agent_accepted_on",
  agentDescription: "agent_description",
  agentId: "agent_id",
  agentName: "agent_name",
  buyerAcceptedBy: simpleUserSerializerMap,
  buyerAcceptedOn: "buyer_accepted_on",
  buyerBusinessId: "buyer_business_id",
  created: "created",
  deploymentSaleId: "deployment_sale_id",
  documentId: "document_id",
  generatedOn: "generated_on",
  id: "id",
  permissions: "permissions",
  type: "type",
  vendorAcceptedBy: simpleUserSerializerMap,
  vendorAcceptedOn: "vendor_accepted_on",
  vendorBusinessId: "vendor_business_id",
  version: "version",
  voidedBy: simpleUserSerializerMap,
  voidedOn: "voided_on",
};

const nestedObjectMap = {
  agent_accepted_by: "agentAcceptedBy",
  buyer_accepted_by: "buyerAcceptedBy",
  vendor_accepted_by: "vendorAcceptedBy",
  voided_by: "voidedBy",
};

export const serializeEContract = buildSerializer(
  eContractSerializeMap,
  "eContract",
  nestedObjectMap,
);

export const deserializeEContract = buildDeserializer(
  eContractSerializeMap,
  "eContract",
  nestedObjectMap,
);
