import React from "react";

import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import { Warning } from "components/ErrorMessage";
import {
  Input,
  SelectField,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";

import {
  LedgerAccountStatusOptions,
  LedgerAccountTypeOptions,
} from "constants/ledgerAccounts";

import { selectMasterLedgerAccountOptions } from "selectors/ledgerAccounts";

export function LedgerAccountForm(props) {
  const { namespace: ns = "", isInXero } = props;

  const masterLedgerAccountOptions = useSelector(
    selectMasterLedgerAccountOptions,
  );

  return (
    <Grid container spacing={2}>
      {isInXero && (
        <Grid item xs={12}>
          <Warning>
            This Ledger Account has been synced from Xero, to make changes other
            than mapping it to a AgriNous Ledger Account, you need to make the
            changes in Xero and re-sync.
          </Warning>
        </Grid>
      )}
      <Grid item xs={12}>
        <Input label="Code" name="code" required disabled={isInXero} />
      </Grid>
      <Grid item xs={12}>
        <Input label="Name" name="name" required disabled={isInXero} />
      </Grid>

      <Grid item xs={12}>
        <TextArea
          label="Description"
          name="description"
          disabled={isInXero}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label="Account Type"
          name={withNamespace(ns, "accountType")}
          options={LedgerAccountTypeOptions}
          required
          menuPortalTarget={document.body}
          disabled={isInXero}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label="Status"
          name={withNamespace(ns, "status")}
          options={LedgerAccountStatusOptions}
          menuPortalTarget={document.body}
          required
          disabled={isInXero}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label="Mapped AgriNous Accounts"
          name={withNamespace(ns, "masterLedgerAccountIds")}
          options={masterLedgerAccountOptions}
          menuPortalTarget={document.body}
          isClearable
          isMulti
        />
      </Grid>
    </Grid>
  );
}
