import React from "react";

import { useTheme } from "hooks";

export const FrontGateClosedBackGateClosed = ({ color, size }) => {
  const theme = useTheme();
  const svgColor = theme.colors[color] || theme.colors.black;
  const svgSize = size || theme.fontSizes.gamma;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox="0 0 27500 27500"
    >
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6201.3"
        y1="6321.7"
        x2="6201.3"
        y2="17941.7"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21380.4"
        y1="9972.7"
        x2="21380.4"
        y2="6194.7"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6268.4"
        y1="21306.7"
        x2="6268.4"
        y2="17814.7"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13824.4"
        y1="21306.7"
        x2="21380.4"
        y2="21306.7"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="21380.4"
        y1="21306.7"
        x2="21380.4"
        y2="9632.7"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="13824.4"
        y1="6194.7"
        x2="21380.4"
        y2="6194.7"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6268.4"
        y1="21306.7"
        x2="13824.4"
        y2="21306.7"
      />
      <line
        stroke={svgColor}
        fill="none"
        style={{ strokeWidth: 1270, strokeMiterlimit: 22.9256 }}
        x1="6268.4"
        y1="6194.7"
        x2="13824.4"
        y2="6194.7"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6188.4"
        cy="17814.7"
        r="340"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6228.3"
        cy="6194.7"
        r="340"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="6268.4"
        cy="21306.7"
        r="340"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13824.4"
        cy="21306.7"
        r="340"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="13824.4"
        cy="6194.7"
        r="340"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21380.4"
        cy="9632.7"
        r="340"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21380.4"
        cy="21306.7"
        r="340"
      />
      <circle
        stroke={svgColor}
        fill={svgColor}
        style={{ strokeWidth: 1693.33, strokeMiterlimit: 22.9256 }}
        cx="21380.4"
        cy="6194.7"
        r="340"
      />
    </svg>
  );
};

export default FrontGateClosedBackGateClosed;
