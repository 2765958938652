import React from "react";
import Grid from "@material-ui/core/Grid";

import { Box, Divider } from "@material-ui/core";
import {
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { useIsMobile } from "hooks";
import { useSelector } from "react-redux";
import { getIsCurrentSaleClearingSale } from "selectors";
import { ForNotClearingSale } from "containers/ForSaleType";
import { SaleProgressSettings } from "./SaleProgressSettings";
import { SaleProgressHeadCounts } from "./SaleProgressHeadCounts";
import { SaleProgressAverages } from "./SaleProgressAverages";
import { SaleProgressTotals } from "./SaleProgressTotals";
import { SaleHeadCounts } from "./SaleHeadCounts";
import { SaleProgressTotalsTables } from "./SaleProgressTotalsTables";

interface SaleProgressDetailsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SaleProgressDetailsDialog(
  props: SaleProgressDetailsDialogProps,
) {
  const { isOpen, onClose } = props;
  const isClearingSale = useSelector(getIsCurrentSaleClearingSale);
  const isMobile = useIsMobile();
  return (
    <ZoomyDialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth={false}
    >
      <DialogTitle onClose={onClose}>Sale Progress Overview</DialogTitle>
      <DialogContent dividers form noHorizontalPadding={isMobile}>
        <Grid container>
          <Grid item xs={isClearingSale || isMobile ? 12 : 4}>
            <Box p={2}>
              <Grid container spacing={2}>
                <ForNotClearingSale>
                  <SaleProgressHeadCounts />

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </ForNotClearingSale>

                <SaleProgressTotals />
                <ForNotClearingSale>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <SaleProgressAverages />
                  <SaleProgressSettings />

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <SaleHeadCounts />
                </ForNotClearingSale>
              </Grid>
            </Box>
          </Grid>
          <ForNotClearingSale>
            <Grid item xs={isMobile ? 12 : 8}>
              <Box p={isMobile ? 0 : 2} bgcolor="grey.100" height="100%">
                <Grid container spacing={2}>
                  <SaleProgressTotalsTables />
                </Grid>
              </Box>
            </Grid>
          </ForNotClearingSale>
        </Grid>
      </DialogContent>
    </ZoomyDialog>
  );
}
