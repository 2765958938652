import React from "react";

import sumBy from "lodash/sumBy";
import { useSelector } from "react-redux";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";

import {
  getBusinesses,
  getConsignments,
  getNonSkinsSaleLots,
  getSales,
  selectRoleCurrentDeploymentIds,
} from "selectors";

import {
  getProductsByDeploymentId,
  getSpeciesAttributeOptions,
} from "selectors/speciesAttributes";

import { PrivateSaleOverview } from "./Overview";

export const HooksSale = ({ match }) => {
  const { saleId } = match.params;
  const sale = useSelector(state => getSales(state)[saleId]);
  const speciesAttributes = useSelector(getSpeciesAttributeOptions);
  const deploymentId = useSelector(selectRoleCurrentDeploymentIds)[0];
  const products = useSelector(getProductsByDeploymentId(deploymentId));
  const businesses = useSelector(getBusinesses);

  const consignment = useSelector(state => {
    // Assuming for now that there is only one.
    const consignments = Object.values(getConsignments(state)).filter(
      c => c.livestocksale_id === sale.livestocksale_id,
    );
    return consignments[0];
  });

  const saleLots = useSelector(getNonSkinsSaleLots);

  const deploymentSales = sale?.deployment_sales;

  const notes = consignment?.notes || sale?.notes;

  const overviewBuyer =
    businesses?.[saleLots?.[0]?.buyer_id || sale.default_buyer_id] || {};

  const buyerName = overviewBuyer.name || "Unknown Buyer";

  const overviewVendor =
    businesses?.[consignment?.vendor_id || sale.default_vendor_id] || {};
  const vendorName = overviewVendor.name || "Unknown Vendor";

  const product =
    (saleLots?.[0]?.product_id &&
      products.find(p => p.id === saleLots[0].product_id)?.name) ||
    "-";
  const carrierName = businesses?.[consignment?.carrierCharge?.carrier]?.name;

  const breed =
    (saleLots?.[0]?.breed_id &&
      speciesAttributes.breeds.find(
        breed => breed.value === saleLots[0].breed_id,
      )?.label) ||
    "-";

  const quantity = sumBy(saleLots, "quantity") || "-";

  return (
    <WaitForSync
      requiredData={[
        ApiModel.CONSIGNMENTS,
        ApiModel.SALE_LOTS,
        ApiModel.SPECIES,
        ApiModel.DEPLOYMENTS,
        ApiModel.BUSINESSES,
      ]}
    >
      <PrivateSaleOverview
        sale={sale}
        notes={notes}
        buyerName={buyerName}
        vendorName={vendorName}
        product={product}
        breed={breed}
        carrierName={carrierName}
        quantity={quantity}
        deploymentSales={deploymentSales}
      />
    </WaitForSync>
  );
};
