import { call, put, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import { addAuctionPenFromPenArchetype, ScanAction } from "actions";

import { ReceivalLotAction } from "actions/receivalLots";

import { RECEIVAL_LOT } from "constants/actionTypes";

import { getLivestockSaleId } from "lib/navigation";

import { populateDeviceIdInScans } from "sagas/scans";

function* receivalLotScanUpload(action) {
  const {
    receivalLotId: suppliedReceivalLotId,
    receivalPenId: suppliedReceivalPenId,
    receivalPenArchetypeId,
    receivalLotValues,
    scans,
    duplicateScans,
  } = action;

  if (!receivalPenArchetypeId && !suppliedReceivalPenId) {
    // We don't know what we're putting where...
    // ....
    // eslint-disable-next-line no-console
    console.error("receivalLotScanUpload received no pen archetype id.");
    return;
  }

  const receivalPenId = suppliedReceivalPenId || uuidv4();
  if (!suppliedReceivalPenId) {
    // We need to create one
    yield put(
      addAuctionPenFromPenArchetype(receivalPenArchetypeId, receivalPenId),
    );
  }

  const receivalLotId = suppliedReceivalLotId || uuidv4();
  if (suppliedReceivalLotId) {
    // We have a receival lot - make sure it's assigned to this pen.
    yield put(
      ReceivalLotAction.update({
        id: receivalLotId,
        receivalPenId,
        ...receivalLotValues,
      }),
    );
  } else {
    // Gotta create a new receival lot
    yield put(
      ReceivalLotAction.create({
        id: receivalLotId,
        livestockSaleId: getLivestockSaleId(),
        receivalPenId,
        ...receivalLotValues,
      }),
    );
  }

  const uploadableScans = yield call(populateDeviceIdInScans, scans);
  yield put(
    ScanAction.create(
      uploadableScans.map(scan => ({
        receival_lot_id: receivalLotId,
        livestock_sale_id: getLivestockSaleId(),
        ...scan,
      })),
    ),
  );

  if (duplicateScans) {
    const resolvableScans = yield call(populateDeviceIdInScans, duplicateScans);
    yield put(
      ScanAction.create(
        resolvableScans.map(scan => {
          return {
            receival_lot_id: receivalLotId,
            livestock_sale_id: getLivestockSaleId(),
            ...scan,
          };
        }),
      ),
    );
  }
}

function* rootSaga() {
  yield takeEvery(
    RECEIVAL_LOT.UPDATE_OR_CREATE_WITH_PEN,
    receivalLotScanUpload,
  );
}

export default rootSaga;
