import React from "react";

import {
  faExclamationCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const StyledStatusText = styled.span`
  ${({ theme, fontSize }) => `
    color: ${theme.colors.titleLight};
    ${fontSize ? `font-size: ${theme.fontSizes[fontSize]}px;` : ""}
    font-style: italic;
    margin: 0 12px 0 0;
  `};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  ${({ theme, status }) => `
    color: ${theme.colors[status]};
    font-size: ${theme.fontSizes.beta}px;
    margin-right: 4px;
  `};
`;

const StatusText = ({ fontSize, status, children }) => (
  <StyledStatusText fontSize={fontSize}>
    {status ? (
      <StyledIcon
        icon={
          ["warning", "error"].includes(status)
            ? faExclamationCircle
            : faCheckCircle
        }
        status={status}
      />
    ) : null}
    {children}
  </StyledStatusText>
);

StatusText.propTypes = {
  fontSize: PropTypes.string,
  status: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default StatusText;
