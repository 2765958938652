import * as Sentry from "@sentry/react";

import { isSentryActive } from "./config";

export const identifyUserToSentry = (currentUser, activeRole) => {
  if (!isSentryActive) {
    return;
  }
  const { user_id, email } = currentUser;

  Sentry.getCurrentScope().setTag("activeRoleSlug", activeRole.slug).setUser({
    id: user_id,
    email,
  });
};
