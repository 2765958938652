import React from "react";

import { faLightbulb } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import { Clickable } from "components/Button";

export const LoadAllWrapper = styled.p`
  background-color: #ffffff;
  padding: 9px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4px;
`;

export const AllLoadedWrapper = styled.span`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: auto;
`;

export const LoadAllTextWrapper = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const LoadAll = ({
  allLoaded,
  text,
  onClick,
  buttonText = "Load All",
  icon = faLightbulb,
}) => {
  return !allLoaded ? (
    <LoadAllWrapper>
      <FontAwesomeIcon icon={icon} />
      &nbsp; &nbsp; {text} &nbsp;
      <Clickable onClick={onClick}>
        <LoadAllTextWrapper>{buttonText}</LoadAllTextWrapper>&nbsp;
      </Clickable>
    </LoadAllWrapper>
  ) : (
    <AllLoadedWrapper />
  );
};
