import { offlineActionCreator } from "actions/lib";

import {
  APP_UPDATE_STATUS,
  REFRESH_INTERVAL,
  SERVER_HEARTBEAT,
} from "constants/actionTypes";

export function updateRefreshInterval(refreshIntervalMs) {
  return {
    type: REFRESH_INTERVAL.ACTION,
    refreshIntervalMs,
  };
}

const urlCreator = () => `/v2/heartbeat/`;

export const ServerHeartbeatAction = offlineActionCreator(
  SERVER_HEARTBEAT,
  () => {},
  urlCreator,
  false,
);

export function appUpdateLogoutRequired() {
  return {
    type: APP_UPDATE_STATUS.LOGOUT_REQUIRED,
  };
}

export function appUpdateRefreshRequired() {
  return {
    type: APP_UPDATE_STATUS.REFRESH_REQUIRED,
  };
}

export function appUpdateRefreshOptional() {
  return {
    type: APP_UPDATE_STATUS.REFRESH_OPTIONAL,
  };
}

export function appUpdateInvisible() {
  return {
    type: APP_UPDATE_STATUS.INVISIBLE,
  };
}

export function appUpdateComplete() {
  return {
    type: APP_UPDATE_STATUS.COMPLETE,
  };
}
