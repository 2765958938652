import React from "react";

import { Grid } from "@material-ui/core";

import { Label } from "components/Form/FormikControls";
import { Splits } from "components/VendorSplits/NewVendorSplitModal/splits";

export const DefaultVendorSplits = () => {
  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12}>
        <Label>Default Vendor Splits</Label>
      </Grid>
      <Splits namespace="defaultVendorSplit" />
    </Grid>
  );
};
