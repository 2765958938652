import { connect } from "react-redux";

import { CarrierChargeForm } from "./CarrierChargeForm";

function getConsignmentsByCarrierCharge() {
  return {};
}

function mapStateToProps(state, props) {
  const consignments = getConsignmentsByCarrierCharge(state, props);

  const associatedConsignments = consignments[props.carrierChargeId] || [];
  const associatedCount = associatedConsignments.length;

  return { associatedCount };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    associatedCount: stateProps.associatedCount,
    namespace: ownProps.namespace,
    onFind: ownProps.onFind,
    onReset: ownProps.onReset,
    optional: ownProps.optional,
  };
}

export default connect(mapStateToProps, null, mergeProps)(CarrierChargeForm);
