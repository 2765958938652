import React from "react";

import { useField } from "formik";
import { useSelector } from "react-redux";

import { Autocomplete } from "components/Form/FormikControls";

import { ConsignmentModalSection } from "constants/navigation";

import { openEditConsignmentModal } from "lib/navigation";

import { selectLivestockSaleConsignmentOptions } from "selectors";

import { useFieldState } from "hooks";

const editConsignment = receivalLotId => {
  openEditConsignmentModal(
    null,
    ConsignmentModalSection.GENERAL,
    null,
    null,
    null,
    null,
    null,
    receivalLotId,
  );
};

const sortByVendorName = options =>
  options.sort((a, b) => a.vendorName?.localeCompare(b.vendorName));

export const ConsignmentAwareConsignmentField = ({
  name,
  label,
  receivalLotId,
  readOnly,
}) => {
  const [field] = useField(name);
  const { value } = field;
  const [consignmentId, setConsignmentId] = useFieldState(field);
  const options = useSelector(selectLivestockSaleConsignmentOptions);
  const simpleOptions = options.map(option => option.value);

  const handleNew = option => {
    if (simpleOptions.includes(option)) {
      setConsignmentId(consignmentId);
    } else {
      editConsignment(receivalLotId);
    }
    return option;
  };

  const getOptionLabel = o =>
    simpleOptions.includes(o.value) ? o.label : "Add New";

  const getOptionSelected = option => option?.value === value?.value;

  return (
    <Autocomplete
      name={name}
      selectOnFocus
      handleHomeEndKeys
      label={label}
      options={sortByVendorName(options)}
      getOptionValue={o => o.value}
      groupBy={option => option.vendorName}
      getOptionLabel={options => getOptionLabel(options)}
      getOptionSelected={getOptionSelected}
      handleNew={handleNew}
      disabled={readOnly}
    />
  );
};
