// These parallel with API SaleType choices.
import { ScreenTypes } from "constants/screen";

export const SaleTypes = {
  BOBBYCALF: "Bobby Calf",
  CLEARING: "Clearing Sale",
  EXTERNAL_AGENT_SALE: "External Agent Sale",
  ON_FARM_AUCTION: "On Farm Auction",
  OVER_HOOKS: "Over Hooks",
  PADDOCK: "Paddock",
  PRIVATE_WEIGH: "Private Weigh",
  SALEYARD_AUCTION: "Saleyard Auction",
  SIGHTING: "Sighting",
  SUNDRY_SALE: "Sundry Sale",
};

export const SaleTypeVocabulary = {
  [SaleTypes.OVER_HOOKS]: {
    "Receival Ref": "Vendor Notes",
    "Sale date": "Kill date",
    Consignment: "Vendor",
    Consignments: "Vendors",
  },
  [SaleTypes.PADDOCK]: {
    "Receival Ref": "Vendor Notes",
    Consignment: "Vendor",
    Consignments: "Vendors",
  },
  [SaleTypes.CLEARING]: {
    "Receival Ref": "Vendor Notes",
    Consignment: "Vendor",
    Consignments: "Vendors",
    "Head Count": "Quantity/Units",
    "Avg/Head": "Avg/Unit",
    "Price per Head": "Price per Unit",
  },
  [SaleTypes.ON_FARM_AUCTION]: {
    "Receival Ref": "Vendor Notes",
  },
  [SaleTypes.BOBBYCALF]: {
    "Sale Lot": "Consignment",
    "Sale Lots": "Consignments",
  },
};

// Grouped sale types that are often seen together.
export const SaleyardSaleTypes = [SaleTypes.SALEYARD_AUCTION];

export const LivestockAuctionSaleTypes = [
  SaleTypes.EXTERNAL_AGENT_SALE,
  SaleTypes.ON_FARM_AUCTION,
  SaleTypes.PRIVATE_WEIGH,
  SaleTypes.SALEYARD_AUCTION,
];

export const LivestockAuctionAndClearanceSaleTypes =
  LivestockAuctionSaleTypes.concat([SaleTypes.CLEARING]);

export const LivestockAuctionAndPaddockSaleTypes =
  LivestockAuctionSaleTypes.concat([SaleTypes.PADDOCK]);

export const LivestockSaleTypes = LivestockAuctionSaleTypes.concat([
  SaleTypes.OVER_HOOKS,
  SaleTypes.PADDOCK,
]);

export const ConsignableSaleTypes = [
  SaleTypes.CLEARING,
  SaleTypes.ON_FARM_AUCTION,
  SaleTypes.PRIVATE_WEIGH,
  SaleTypes.SALEYARD_AUCTION,
];

export const TransactionalSaleTypes = [SaleTypes.OVER_HOOKS, SaleTypes.PADDOCK];

export const NlisTakeAndSellSaleTypes = [
  SaleTypes.SALEYARD_AUCTION,
  SaleTypes.BOBBYCALF,
  SaleTypes.PRIVATE_WEIGH,
];

export const ConsignmentExceptions = {
  NO_PIC: "NO PIC",
  NVD_ID_MISSING: "NVD ID MISSING",
  NVD_SCAN_MISSING: "NVD SCAN MISSING",
  NVD_INCOMPLETE: "NVD INCOMPLETE",
  RESTRICTED_PIC: "RESTRICTED PIC",
  BLOCKED_INACTIVE_PIC: "BLOCKED/INACTIVE PIC",
  // Scan Rate Compliance is an exception for Cattle, but a warning for other species
  SCAN_RATE_COMPLIANCE: "SCAN RATE COMPLIANCE",
  UNBALANCED: "UNBALANCED",
};

export const CONSIGNMENT_NVD_EXCEPTIONS = [
  ConsignmentExceptions.NO_PIC,
  ConsignmentExceptions.NVD_ID_MISSING,
  ConsignmentExceptions.NVD_SCAN_MISSING,
  ConsignmentExceptions.NVD_INCOMPLETE,
  ConsignmentExceptions.RESTRICTED_PIC,
];

export const ConsignmentWarnings = {
  NVD_HC_MISMATCH: "NVD HC MISMATCH",
  // Scan Rate Compliance is an exception for Cattle, but a warning for other species
  SCAN_RATE_COMPLIANCE: "SCAN RATE COMPLIANCE",
  CONSIGNMENT_SCANS_FOUND: "CONSIGNMENT SCANS FOUND",
  UNREGISTERED_EID: "UNREGISTERED EID",
};

export const SaleLotException = {
  BLOCKED_INACTIVE_PIC: "BLOCKED/INACTIVE PIC",
  NO_AGE: "NO AGE",
  NO_BREED: "NO BREED",
  NO_PIC: "NO PIC",
  NO_SEX: "NO SEX",
  RESTRICTED_PIC: "RESTRICTED PIC",
  UNBALANCED: "UNBALANCED",
  NO_WEIGHT_RANGE: "NO WEIGHT RANGE",
  NO_DENTITION: "NO DENTITION",
};

export const SaleLotWarning = {
  NO_RECIPIENTS: "NO EMAIL RECIPIENTS",
  SINGLE_WEIGH_SUM: "SINGLE WEIGH WEIGHT NOT EQUAL TO SALE LOT WEIGHT",
  THIRD_PARTY_EMAIL_MISSING: "THIRD PARTY EMAIL MISSING",
  UNREGISTERED_EID: "UNREGISTERED EID",
  NO_BREED: "NO BREED",
  NO_SEX: "NO SEX",
  NO_AGE: "NO AGE",
  VENDOR_SPLIT_LOTS_UNBALANCED: "VENDOR SPLIT LOTS UNBALANCED",
  PRIMARY_VENDOR_IS_NOT_PART_OF_VENDOR_SPLIT_LOTS:
    "PRIMARY VENDOR IS NOT PART OF VENDOR SPLIT LOTS",
  NO_WEIGHT_RANGE: "NO WEIGHT RANGE",
  NO_DENTITION: "NO DENTITION",
};

export const consignmentStatuses = {
  BOOKED: "BOOKED",
  ARRIVED: "ARRIVED",
};

export const consignmentStatusesOrder = [
  consignmentStatuses.BOOKED,
  consignmentStatuses.ARRIVED,
];

export const saleLotStatuses = {
  NOT_PENNED: "NOT PENNED",
  NOT_COUNTED: "NOT COUNTED",
  PENNED: "PENNED",
  STACKED: "STACKED",
  NOT_SOLD: "NOT SOLD",
  PARTIALLY_DELIVERED: "PARTIALLY DELIVERED",
  SOLD: "SOLD",
  NO_SALE: "NO SALE",
  DELIVERED: "DELIVERED",
};

export const saleLotStatusesOrder = [
  saleLotStatuses.NOT_COUNTED,
  saleLotStatuses.NOT_PENNED,
  saleLotStatuses.PENNED,
  saleLotStatuses.STACKED,
  saleLotStatuses.NOT_SOLD,
  saleLotStatuses.SOLD,
  saleLotStatuses.NO_SALE,
  saleLotStatuses.DELIVERED,
];

export const NominationStatus = {
  ARRIVED: "NOMINATION_ARRIVED",
  NOMINATED: "NOMINATION_NOMINATED",
};

export const NominationStatusText = {
  [NominationStatus.ARRIVED]: "Arrived",
  [NominationStatus.NOMINATED]: "Nominated",
};

export const NominationStatusColor = {
  [NominationStatus.ARRIVED]: "success",
  [NominationStatus.NOMINATED]: "warning",
};

export const auctionPenActions = {
  RE_PEN: "RE_PEN",
};

export const auctionPenStatuses = {
  NOT_PENNED: "NOT PENNED",
  NOT_COUNTED: "NOT COUNTED",
  NOT_SOLD: "NOT SOLD",
  PARTIALLY_SOLD: "PARTIALLY SOLD",
  SOLD: "SOLD",
  NO_SALE: "NO SALE",
  PARTIALLY_DELIVERED: "PARTIALLY DELIVERED",
  DELIVERED: "DELIVERED",
  STACKED: "STACKED",
  TEMP_PEN: "TEMP",
};

export const auctionPenStatusesOrder = [
  auctionPenStatuses.NOT_COUNTED,
  auctionPenStatuses.NOT_PENNED,
  auctionPenStatuses.NOT_SOLD,
  auctionPenStatuses.PARTIALLY_SOLD,
  auctionPenStatuses.SOLD,
  auctionPenStatuses.NO_SALE,
  auctionPenStatuses.PARTIALLY_DELIVERED,
  auctionPenStatuses.DELIVERED,
  auctionPenStatuses.STACKED,
];

export const cards = {
  AUCTION_PEN_CARD: "AUCTION_PEN_CARD",
  BUYER_CARD: "BUYER_CARD",
  BUYER_WAY_CARD: "BUYER_WAY_CARD",
  CONSIGNMENT_CARD: "CONSIGNMENT_CARD",
  SALE_LOT_CARD: "SALE_LOT_CARD",
  TEMP_PEN_CARD: "TEMP_PEN_CARD",
};

export const SaleRoundName = {
  BobbyCalf: "BobbyCalf",
  ExternalAgentSale: "External Agent Sale",
};

export const SALE_VALIDATION_ERRORS = {
  DUPLICATE_SALE: "DUPLICATE_SALE",
};

export const SystemSaleyards = [
  SaleTypes.OVER_HOOKS,
  SaleTypes.PADDOCK,
  SaleTypes.CLEARING,
  SaleTypes.EXTERNAL_AGENT_SALE,
];

export const NonBuyerWaySaleTypes = [SaleTypes.SUNDRY_SALE];

export const NonConsignableSaleyards = [
  SaleTypes.OVER_HOOKS,
  SaleTypes.PADDOCK,
];

export const SaleStatus = {
  FUTURE: "FUTURE",
  IN_PROGRESS: "IN PROGRESS",
  PAST: "PAST",
  LOCKED: "LOCKED", // todo - not used yet
  COMPLETED: "COMPLETED",
};
export const SaleStatusLabels = {
  [SaleStatus.FUTURE]: "Future",
  [SaleStatus.IN_PROGRESS]: "In Progress",
  [SaleStatus.PAST]: "Past",
  [SaleStatus.COMPLETED]: "Completed",
};

export const SaleStatusColors = {
  [SaleStatus.FUTURE]: "primary",
  [SaleStatus.IN_PROGRESS]: "pending",
  [SaleStatus.PAST]: "gray40",
  [SaleStatus.LOCKED]: "gray40",
  [SaleStatus.COMPLETED]: "lightGreen",
};

const TabsForSaleType = {
  [SaleTypes.EXTERNAL_AGENT_SALE]: {
    [ScreenTypes.DESKTOP]: [
      "consignments",
      "salelots",
      "buyer-cards",
      "eids",
      "reports",
      "billing",
      "overview",
    ],
    [ScreenTypes.MOBILE]: [
      "overview",
      "consignment-cards",
      "eids",
      "reports",
      "buyer-cards",
      "auction-pen-cards",
    ],
  },
};

export const filterTabsBySaleType = (saleType, group) => t => {
  const hasExplicit = TabsForSaleType?.[saleType]?.[group];
  if (hasExplicit) {
    return hasExplicit.includes(t.id);
  } else {
    return true;
  }
};

export const WarningLevel = {
  WARNING: "Warning",
  EXCEPTION: "Exception",
  WARNING_AND_BLOCK: "Warning & Block",
};

export const LOAD_MORE_SALES = "Load more sales...";

// Matching Prod
export const SaleSubTypeName = {
  INTEREST: "INTEREST",
};
