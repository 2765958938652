import React from "react";

import {
  faExternalLink,
  faFile,
  faFileArchive,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFilePdf,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Link as ReactRouterLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { Clickable } from "components/Button";
import { FileIcon, FilenameText } from "components/Icons";

import { extractFileExtensionFromURL, extractFileNameFromURL } from "lib";

const linkStyles = css`
  cursor: pointer;
  opacity: 1;
  font-size: ${({ theme }) => theme.fontSizes.beta}px;
  line-height: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme, color }) =>
    color && theme.colors[color] ? theme.colors[color] : theme.colors.gray40};
  ${({ theme }) => `${theme.active}`}
  &:active {
    background: rgba(200, 200, 200, 0.59);
  }
  :not(:active) {
    transition: ${({ theme }) => theme.transitions[0]};
  }
`;

const unstyledLinkStyles = css`
  &,
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const Link = styled(ReactRouterLink)`
  ${linkStyles};
`;

const ExternalLink = styled.a`
  ${linkStyles};
`;

const ExternalLinkBlock = styled(ExternalLink)`
  display: flex;
  flex-direction: column;
  ${({ alignCenter }) =>
    alignCenter ? `align-items: center; justify-content: center;` : ""}
`;

const UnstyledLink = styled(ReactRouterLink)`
  ${unstyledLinkStyles};
`;

const ClickableLink = styled(ReactRouterLink)`
  ${unstyledLinkStyles};
  ${({ theme }) => `${theme.active}`}
`;

const WrapperLinkIcon = styled(Clickable)(
  ({ theme, spaceIndex = 3 }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px ${theme.space[spaceIndex]}px;
`,
);

const LinkIcon = styled(FontAwesomeIcon)`
  margin: 0 6px;
  color: ${({ theme }) => theme.colors.primary};
`;

const LinkFileDownload = ({ id, url, icon, text, internal, spaceIndex }) => (
  <WrapperLinkIcon data-tour="file" spaceIndex={spaceIndex}>
    <LinkIcon data-tour="download" icon={icon} />
    <Link
      key={`link-file-${id}`}
      to={url}
      color="primary"
      download={window.Cypress ? "file" : undefined}
      target={internal ? undefined : "_blank"}
    >
      {text}
    </Link>
  </WrapperLinkIcon>
);

LinkFileDownload.propTypes = {
  url: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.object,
  text: PropTypes.string,
  internal: PropTypes.bool,
};

const LinkFileTypeDownload = ({
  id,
  url,
  fileType,
  spaceIndex,
  text = null,
  internal = false,
}) => {
  const iconProps = {
    icon: faFile,
    text: text || "Download",
  };
  if (fileType) {
    if (fileType === "tsv") {
      iconProps.icon = faFileExcel;
      iconProps.text = text || "TSV";
    } else if (fileType === "csv") {
      iconProps.icon = faFileCsv;
      iconProps.text = text || "CSV";
    } else if (fileType === "pdf") {
      iconProps.icon = faFilePdf;
      iconProps.text = text || "PDF";
    } else if (fileType === "zip") {
      iconProps.icon = faFileArchive;
      iconProps.text = text || "ZIP";
    } else if (fileType === "xml") {
      iconProps.icon = faFileCode;
      iconProps.text = text || "XML";
    } else if (fileType === "link") {
      iconProps.text = text || "View";
      iconProps.icon = faExternalLink;
    } else if (fileType === "text/plain") {
      iconProps.text = text || "TXT";
      iconProps.icon = faFile;
    }
  }
  return (
    <LinkFileDownload
      id={id}
      url={url}
      internal={internal}
      spaceIndex={spaceIndex}
      {...iconProps}
    />
  );
};
const ExternalFileLink = ({ imageUrl }) => (
  <ExternalLinkBlock href={imageUrl} target="_blank" alignCenter>
    <FileIcon fileExtension={extractFileExtensionFromURL(imageUrl)} />
    <FilenameText>{extractFileNameFromURL(imageUrl)}</FilenameText>
  </ExternalLinkBlock>
);

export {
  ClickableLink,
  ExternalLink,
  ExternalLinkBlock,
  Link,
  LinkFileDownload,
  LinkFileTypeDownload,
  UnstyledLink,
  WrapperLinkIcon,
  ExternalFileLink,
};
