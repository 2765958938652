import React from "react";

import { sortBy } from "lodash";
import { useSelector } from "react-redux";

import { getDressingRanges, getWeightRangeBySaleRoundId } from "selectors";

const { OptionTogglerField } = require("components/Form/FormikControls");

export const DressingRangeField = ({ name, disabled, saleRoundId }) => {
  const weightRanges = useSelector(getWeightRangeBySaleRoundId(saleRoundId));

  const dressingRanges = useSelector(getDressingRanges);
  const dressingRangeOptions = sortBy(
    Object.values(dressingRanges),
    "order",
  ).map(range => {
    let dressingLabel = `${parseInt(
      range.dressingRangeMinPercent,
      10,
    )}-${parseInt(range.dressingRangeMaxPercent, 10)}%`;
    if (!range.dressingRangeMaxPercent) {
      dressingLabel = `${range.dressingRangeMinPercent}+%`;
    }
    return {
      label: dressingLabel,
      value: range.id,
    };
  });

  if (dressingRangeOptions.length > 0 && weightRanges.length !== 0) {
    return (
      <OptionTogglerField
        labelPosition="top"
        name={name}
        label="Est Dressing %"
        options={dressingRangeOptions}
        disabled={disabled}
      />
    );
  } else {
    return false;
  }
};
