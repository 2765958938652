import {
  INVALIDATE_TABLE_VIEW,
  SET_SALE_PROGRESS_SETTING,
  SET_SETTING,
  SET_TABLE_SETTING,
  SET_TABLE_VIEW,
  UPDATE_TABLE_VIEW,
} from "constants/actionTypes";
import { allowedSettings } from "constants/settings";

export const setSetting = (setting, value) => {
  // Throw up a warning if the developer uses settings that aren't in the Settings constant.
  if (!allowedSettings.includes(setting)) {
    console.warn("Unknown setting used! ", setting, value); // eslint-disable-line no-console
  }

  return {
    type: SET_SETTING,
    payload: {
      [setting]: value,
    },
  };
};

export const setTableSetting = (tableName, value) => {
  return {
    type: SET_TABLE_SETTING,
    payload: {
      [tableName]: value,
    },
  };
};

export const setTableView = (tableName, viewId) => {
  return {
    type: SET_TABLE_VIEW,
    tableName,
    viewId,
  };
};

export const updateTableView = (tableName, viewId, payload) => {
  return {
    type: UPDATE_TABLE_VIEW,
    tableName,
    viewId,
    payload,
  };
};

// Used to force the application of the view and filter models stored in redux, irrespective of what the current view state is
export const invalidateTableView = tableName => {
  return {
    type: INVALIDATE_TABLE_VIEW,
    tableName,
  };
};

export function setSaleProgressSetting(saleProgressSetting, value) {
  return {
    type: SET_SALE_PROGRESS_SETTING,
    setting: saleProgressSetting,
    value,
  };
}
