import React from "react";

import { faArrowDown, faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { RuleAction } from "actions";

import {
  selectIsMoveDownEnabledByRuleIdLookup,
  selectIsMoveUpEnabledByRuleIdLookup,
} from "selectors";

export function RuleOrderRenderer({ data, value }) {
  const dispatch = useDispatch();

  const isMoveUpEnabledByRuleId = useSelector(
    selectIsMoveUpEnabledByRuleIdLookup,
  );
  const isMoveDownEnabledByRuleId = useSelector(
    selectIsMoveDownEnabledByRuleIdLookup,
  );

  if (!data) {
    return null;
  }
  const { rule } = data;

  const ruleId = rule.id;

  function onClickMoveUp() {
    dispatch(RuleAction.moveUp(ruleId));
  }

  function onClickMoveDown() {
    dispatch(RuleAction.moveDown(ruleId));
  }

  const isMoveUpEnabled = isMoveUpEnabledByRuleId[ruleId];
  const isMoveDownEnabled = isMoveDownEnabledByRuleId[ruleId];

  return (
    <>
      {value}
      <IconButton
        aria-label="Move Up"
        disabled={!isMoveUpEnabled}
        onClick={onClickMoveUp}
      >
        <FontAwesomeIcon icon={faArrowUp} size="xs" />
      </IconButton>
      <IconButton
        aria-label="Move Down"
        disabled={!isMoveDownEnabled}
        onClick={onClickMoveDown}
      >
        <FontAwesomeIcon icon={faArrowDown} size="xs" />
      </IconButton>
    </>
  );
}
