import { createSelector } from "reselect";

import {
  KEYWORD_CATEGORY_CRIPPLE,
  KEYWORD_CATEGORY_NCV,
  KEYWORD_CATEGORY_TRANSIT,
} from "constants/saleLots";

import { getCategories } from "selectors/root";

export const skipCategoryIdsSelector = createSelector(
  [getCategories],
  categories =>
    Object.values(categories)
      .filter(
        c =>
          c.name.toLowerCase().indexOf(KEYWORD_CATEGORY_NCV) > -1 ||
          c.name.toLowerCase().indexOf(KEYWORD_CATEGORY_CRIPPLE) > -1 ||
          c.name.toLowerCase().indexOf(KEYWORD_CATEGORY_TRANSIT) > -1,
      )
      .map(c => c.id),
);
