import React from "react";

import { Text } from "components/Text";

function NlisIdRenderer(props) {
  const { value } = props;

  return (
    <Text color={value === "EID NOT NLIS REGISTERED" ? "errorRed" : ""}>
      {value}
    </Text>
  );
}

export default NlisIdRenderer;
