import groupBy from "lodash/groupBy";
import {
  call,
  put,
  takeEvery,
  select,
  // putResolve,
} from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import {
  addAuctionPenFromPenArchetype,
  // addSaleLotWithPens,
  // bulkMoveScans,
  patchSaleLot,
  ScanAction,
  updateModalContext,
  PenScanLotAction,
  SaleLotAction,
  BusinessAction,
  requestConsignmentsChanges,
  AuctionPenAction,
  requestScansChanges,
} from "actions";

import { PEN_SCAN_LOT } from "constants/actionTypes";
import { UNKNOWN_BUSINESS_NAME } from "constants/businesses";
import { UNKNOWN_CONSIGNMENT_ID } from "constants/consignments";
import { ModalTypes } from "constants/navigation";
import { UNALLOCATED } from "constants/scanner";

import {
  // expandAuctionPen,
  getAuctionPenDisplayName,
} from "lib/auctionPens";
import { getLivestockSaleId } from "lib/navigation";

import {
  getAgencyByDeploymentId,
  getAuctionPenById,
  getAuctionPens,
  getContextByModalType,
  getCurrentSale,
  getEidsByPenScanLotId,
  getPenScanLotById,
  getPenScanLotMappings,
  getReceivalLotById,
  getSaleLotIdsByConsignmentId,
  getSaleLots,
  getScans,
  selectBusiessIdsByNameLookup,
  selectDeploymentIdByConsignmentIdLookup,
  selectConsignmentIdsByVendorIdLookup,
  getSaleLotById,
  getScanByEid,
} from "selectors";

import { populateDeviceIdInScans } from "sagas/scans";

import { modalUpdateActionPattern } from "./lib";

/**
 * Handling a pen scan lot split is a little different to general sale lot splits.
 * There are various data points that need to be updated.
 *
 * When the scanner, rescans an animal from one pen scan lot into another.
 * Generally this is fine, but if the office has already mapped the lot.
 * It causes the head out on the sale lot to be wrong, the head count on the pen scan lot to be wrong
 * and the split out pen scan lot to be mapped to the wrong sale lot.
 *
 * When a pen scan lot is mapped to a sale lot (the EIDs are allocated to a sale_lot_id) and
 * the eid is then scanned out of a pen scan lot into another pen scan lot we consider this a split.
 * That change of pen scan lot needs to be reflected on the sale lot and the user needs to be informed
 * that this happened.
 *
 * We are doing this by updating the head count of the pen scan lot and linked sale lot to remove the number
 * of animals that have been moved out. (ie: 2 scans removed, subtract the head count by 2 for the pen scan lot
 * and sale lot)
 *
 * Then adding a comment the to the pen scan lots detailing what has happened.
 *
 * @param {Array} formattedScans // This is a list of modified scan data generated from the scanning modes, containing an EID
 * @param {number} penScanLotId // The Pen Scan Lot id for the Pen Scan Lot we are updating to
 */
function* handlePenScanSplit(formattedScans, penScanLotId) {
  const state = yield select();

  const updateData = {};

  for (const formattedScan of formattedScans) {
    const scan = getScanByEid(formattedScan.EID)(state);
    // if the pen scan lot is updated from an old one, update the old one to have have less hd as the scans are moved out.
    // if a sale lot is linked to the pen scan lot - remove the head of the moved scans because they have been split.
    if (scan && scan.pen_scan_lot_id && scan.pen_scan_lot_id !== penScanLotId) {
      const oldPenScanLot = getPenScanLotById(scan.pen_scan_lot_id)(state);
      if (!updateData[scan.pen_scan_lot_id]) {
        // group scans by their pen scan lot origins
        updateData[scan.pen_scan_lot_id] = {
          movedAmount: 0,
          eids: [],
          saleLotId: scan.sale_lot_id === UNALLOCATED ? null : scan.sale_lot_id,
          oldPenScanLot,
        };
      }
      updateData[scan.pen_scan_lot_id].movedAmount += 1;
      updateData[scan.pen_scan_lot_id].eids.push(scan.EID);
    }
  }

  const newPenScanLot = getPenScanLotById(penScanLotId)(state);
  const newPenScanPen = getAuctionPenById(newPenScanLot?.sellingPenId)(state);

  for (const penScanLotData of Object.values(updateData)) {
    const { movedAmount, eids, saleLotId, oldPenScanLot } = penScanLotData;

    const saleLot = getSaleLotById(saleLotId)(state);

    // if there is a sale lot linked to the scan and we are moving the scan from one pen scan lot to another
    // add the comments, update the quantites and unmap the moved scans
    if (saleLot && oldPenScanLot) {
      const oldPenName = getAuctionPenDisplayName(
        getAuctionPenById(oldPenScanLot.sellingPenId)(state),
      );
      yield put(
        patchSaleLot(
          {
            id: saleLot.id,
            quantity: saleLot.quantity - movedAmount,
          },
          { changeReason: "Split from pen scan lot" },
        ),
      );

      const eidsString = eids.join(", ");

      const oldComment = `The EIDs ${eidsString} were scanned out of pens ${oldPenName} into pen ${getAuctionPenDisplayName(
        newPenScanPen,
      )}. Causing the head count to be updated`;

      const newComment = `The EIDs ${eidsString} have been unmapped because these EIDs were scanned out of pens ${oldPenName} into pen ${getAuctionPenDisplayName(
        newPenScanPen,
      )}.`;

      if (oldPenScanLot) {
        yield put(
          PenScanLotAction.update({
            id: oldPenScanLot.id,
            quantity: oldPenScanLot.quantity - movedAmount,
          }),
        );
        // let the old pen scan lot know what happened
        yield put(
          PenScanLotAction.comment(oldPenScanLot.id, { comment: oldComment }),
        );
      }

      // let the new pen scan lot know what happened
      yield put(
        PenScanLotAction.comment(penScanLotId, { comment: newComment }),
      );
    }
  }
}

function* penScanLotScanUpload(action) {
  const {
    penScanLotId: suppliedPenScanLotId,
    sellingPenId: suppliedSellingPenId,
    sellingPenArchetypeId,
    penScanLotValues,
    scans = [],
    duplicateScans = [],
  } = action;

  if (!sellingPenArchetypeId && !suppliedSellingPenId) {
    // We don't know what we're putting where...
    // ....
    // eslint-disable-next-line no-console
    console.error("receivalLotScanUpload received no pen archetype id.");
    return;
  }

  const sellingPenId = suppliedSellingPenId || uuidv4();

  if (!suppliedSellingPenId) {
    // We need to create one
    yield put(
      addAuctionPenFromPenArchetype(sellingPenArchetypeId, sellingPenId),
    );
  }

  const penScanLotId = suppliedPenScanLotId || uuidv4();

  if (suppliedPenScanLotId) {
    // We have a pen scan lot - make sure it's assigned to this pen.
    yield put(
      PenScanLotAction.update({
        id: penScanLotId,
        sellingPenId,
        ...penScanLotValues,
      }),
    );
  } else {
    // Gotta create a new pen scan lot
    yield put(
      PenScanLotAction.create({
        id: penScanLotId,
        livestockSaleId: getLivestockSaleId(),
        sellingPenId,
        ...penScanLotValues,
      }),
    );
  }

  yield call(handlePenScanSplit, [...scans, ...duplicateScans], penScanLotId);

  const uploadableScans = yield call(populateDeviceIdInScans, scans);
  yield put(
    ScanAction.create(
      uploadableScans.map(scan => ({
        pen_scan_lot_id: penScanLotId,
        livestock_sale_id: getLivestockSaleId(),
        ...scan,
        // if we are moving a pen scan to another pen scan lot - remove the sale lot reference (we need to remap)
        sale_lot_id: null,
      })),
    ),
  );

  const resolvableScans = yield call(populateDeviceIdInScans, duplicateScans);
  if (resolvableScans) {
    yield put(
      ScanAction.create(
        resolvableScans.map(scan => {
          return {
            pen_scan_lot_id: penScanLotId,
            livestock_sale_id: getLivestockSaleId(),
            ...scan,
            // if we are moving a pen scan to another pen scan lot - remove the sale lot reference (we need to remap)
            sale_lot_id: null,
          };
        }),
      ),
    );
  }
}

/**
 * Calculates which EIDs can be automatically allocated
 */
function* onUpdateAllocatePenScanContext(action) {
  const { context } = action;
  const state = yield select();
  const businessIdsByNameLookup = selectBusiessIdsByNameLookup(state);
  const consignmentIdsByVendorIdLookup =
    selectConsignmentIdsByVendorIdLookup(state);
  const deploymentIdByConsignmentIdLookup =
    selectDeploymentIdByConsignmentIdLookup(state);

  const {
    // Holds a key/value struct of penScanLotId => null or a list of lists of EID to Sale Lot Id mappings grouped,
    // where each group of EIDs will be going in to the same Sale Lot
    mapping = {},
  } = getContextByModalType(ModalTypes.AllocatePenScanLots)(state) || {};

  const livestockSale = getCurrentSale(state);
  if (
    // Don't attempt to generate the mappings when we don't have a list of pen scan lot ids to map to
    context.mapping ||
    // We need the livestock Sale for the Default Pricing Type, the livestock sale must be loaded
    !livestockSale
  ) {
    return;
  }

  const scanByEidLookup = getScans(state);
  const saleLotByIdLookup = getSaleLots(state);
  const penByIdLookup = getAuctionPens(state);

  const pricingTypeId = livestockSale.pricing_type_id;
  const newSaleLotData = {};

  // Generate the new EID to Sale Lot Id mappings for each of the selected Pen Scan Lots
  const newMapping = Object.keys(mapping).reduce((acc, penScanLotId) => {
    const penScanLot = getPenScanLotById(penScanLotId)(state);
    const eids = getEidsByPenScanLotId(penScanLotId)(state) || [];

    // Obtain a list of all of the Scans in this Pen Scan Lot
    const scans = eids.map(eid => scanByEidLookup[eid]);

    // Flag; indicates if all the Scans in the PenScanLot are eligible for allocation to SaleLots.
    // If any are ineligible, then the whole PenScanLot is ineligible for allocation
    const areAllScansEligible = scans.every(scan => {
      // The PenScan Lot is locked
      if (penScanLot.isLocked) {
        return false;
      }

      const receivalLot = getReceivalLotById(scan.receival_lot_id)(state) || {};
      // ReceivalLot does not have a Consignment or there is no deployment and round saved against the pen scan lot
      if (
        !scan.receival_lot_id &&
        !receivalLot.consignmentId &&
        (!penScanLot.deploymentId || !penScanLot.saleRoundId)
      ) {
        return false;
      }

      // Scan doesn't a PenScanLot
      if (!scan.pen_scan_lot_id) {
        return false;
      }

      // Scan has a a Sale Lot
      if (scan.sale_lot_id && scan.sale_lot_id !== UNALLOCATED) {
        // Already lotted, nothing to do here
        return false;
      }

      // This EID is eligible for auto allocation
      return true;
    });

    // Group the Scans by Vendor (Consignment)
    const scansBySaleLotGroupsId = groupBy(scans, scan => {
      const receivalLot = getReceivalLotById(scan.receival_lot_id)(state) || {};
      // calculate which consignment to associate to each group of scans
      // if there is a receival lot and a consignment - assign that consignment
      if (receivalLot.consignmentId) {
        return receivalLot.consignmentId;
      } else {
        // otherwise find the consignment id associated to an unknown business
        // that is linked to the agency on the pen scan lot.

        // if there isnt a consignment - pass in an agency id to create one.
        const penScanLot = getPenScanLotById(scan.pen_scan_lot_id)(state) || {};
        const agency = getAgencyByDeploymentId(penScanLot.deploymentId)(state);

        const unknownBusinessId =
          businessIdsByNameLookup[UNKNOWN_BUSINESS_NAME]?.find(
            businessId => !!consignmentIdsByVendorIdLookup[businessId],
          ) || null;

        const consignmentId = consignmentIdsByVendorIdLookup[
          unknownBusinessId
        ]?.find(
          consignmentId =>
            deploymentIdByConsignmentIdLookup[consignmentId] ===
            penScanLot.deploymentId,
        );

        return consignmentId || agency.id || UNKNOWN_CONSIGNMENT_ID;
      }
    });

    // Determine if we can easily split this Pen Scan Lot across multiple vendors
    const isSplitAllocateAvailable = scans.length === penScanLot.quantity;

    if (areAllScansEligible && isSplitAllocateAvailable) {
      acc[penScanLotId] = Object.entries(scansBySaleLotGroupsId).reduce(
        (acc, [consignmentId, scans]) => {
          const candidateSaleLotIds =
            getSaleLotIdsByConsignmentId(consignmentId)(state) || [];
          const proposedSaleLotId =
            // Try to find and existing Sale Lot attached to the Scan's Receival Lot's Consignment matching the attibutes of the Pen Scan Lot
            candidateSaleLotIds.find(saleLotId => {
              const saleLot = saleLotByIdLookup[saleLotId];

              // Determine if the existing SaleLot matches the one which would have been created during the auto-allocation process
              // if it does, we can just use the existing Sale Lot instead
              return (
                // The Scans may have been grouped into multiple consignments, meaning the number of Scans in `scans` may not equal the number of Scans in the Pen Scan Lot. Only the proportion of the Pen Scans belonging to this Vendor (Consignment)
                saleLot.quantity === scans.length &&
                // The Auction Pens are the same
                penByIdLookup[saleLot.auction_pen_id]?.start_pen ===
                  penByIdLookup[penScanLot.sellingPenId]?.start_pen &&
                // auction_pen check _should_ cover the round check, but let's be safe
                saleLot.sale_round_id === penScanLot.saleRoundId &&
                // The PenScan Lot and the Sale Lot have the name number of marks
                saleLot.marks.length === penScanLot.marks.length &&
                // The PenScan Lot has the same Marks (sans colour) as the Sale Lot
                saleLot.marks.every(saleLotMark =>
                  penScanLot.marks.find(
                    penScanLotMark =>
                      saleLotMark.location === penScanLotMark.location &&
                      saleLotMark.color === penScanLotMark.color,
                  ),
                )
              );
            }) || null;

          const agency = getAgencyByDeploymentId(penScanLot.deploymentId)(
            state,
          );
          const saleLotId = proposedSaleLotId || uuidv4();
          if (!newSaleLotData[`${penScanLotId}_${consignmentId}`]) {
            // Optimistically store a payload to create a Sale Lot matching the
            // description of the Pen Scan lot this Scan's Arrival Lot is a belongs to.
            newSaleLotData[`${penScanLotId}_${consignmentId}`] = {
              auction_pen_id: penScanLot.sellingPenId,
              consignment_id:
                consignmentId === UNKNOWN_CONSIGNMENT_ID ? null : consignmentId,
              id: saleLotId,
              marks: penScanLot.marks.map(penScanLotMark => ({
                ...penScanLotMark,
              })),
              pricing_type_id: pricingTypeId,
              quantity: scans.length,
              sale_round_id: penScanLot.saleRoundId,
              agency_id: agency.id,
            };
          }
          acc.push(
            // Add a list of "Scan to Sale Lot" mapping objects for each (Vendor) Consignment in this Pen Scan Lot
            scans.map(scan => ({
              eid: scan.EID,
              penScanLotId,
              existingSaleLotId: proposedSaleLotId || null,
              newSaleLotId: saleLotId,
            })),
          );

          return acc;
        },
        [],
      );
    }
    return acc;
  }, {});
  yield put(
    updateModalContext(ModalTypes.AllocatePenScanLots, {
      mapping: newMapping,
      saleLotData: Object.values(newSaleLotData).reduce((acc, saleLotData) => {
        acc[saleLotData.id] = saleLotData;
        return acc;
      }, {}),
    }),
  );
}

function* onAllocatePenScanLotRequest() {
  const state = yield select();
  const { mapping = {} } = getContextByModalType(
    ModalTypes.AllocatePenScanLots,
  )(state);
  yield put(PenScanLotAction.allocateAction(Object.keys(mapping)));
}

function* onAllocatePenScanLotSuccess() {
  const state = yield select();
  const sale = getCurrentSale(state);
  yield put(
    BusinessAction.requestChanges({
      changesSince: state.businessesV2.lastModifiedTimestamp,
    }),
  );
  yield put(requestConsignmentsChanges(sale));
  yield put(
    AuctionPenAction.requestChanges({
      changesSince: state.sales.lastModifiedTimestamp,
    }),
  );
  yield put(
    SaleLotAction.requestChanges({
      changesSince: state.sales.lastModifiedTimestamp,
    }),
  );
  yield put(requestScansChanges(sale));
  yield put(
    PenScanLotAction.requestChanges({
      changesSince: state.sales.lastModifiedTimestamp,
    }),
  );
}

function* onUpdatePenScanLotMapping(action) {
  const { mappingGroups, penScanLotId } = action;
  const state = yield select();
  const updatedContext = { mapping: { ...getPenScanLotMappings(state) } };
  updatedContext.mapping[penScanLotId] = mappingGroups;
  yield put(updateModalContext(ModalTypes.AllocatePenScanLots, updatedContext));
}

function* rootSaga() {
  yield takeEvery(
    PEN_SCAN_LOT.UPDATE_OR_CREATE_WITH_PEN.REQUEST,
    penScanLotScanUpload,
  );
  yield takeEvery(
    modalUpdateActionPattern(ModalTypes.AllocatePenScanLots),
    onUpdateAllocatePenScanContext,
  );
  yield takeEvery(PEN_SCAN_LOT.ALLOCATE.REQUEST, onAllocatePenScanLotRequest);
  yield takeEvery(PEN_SCAN_LOT.ALLOCATE.SUCCESS, onAllocatePenScanLotSuccess);
  yield takeEvery(
    PEN_SCAN_LOT.UPDATE_MAPPING.ACTION,
    onUpdatePenScanLotMapping,
  );
}

export default rootSaga;
