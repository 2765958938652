import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

const serializeMap = {
  id: "id",
  deviceName: "device_name",
  deviceId: "device_id",
  draftName: "draft_name",
  commonName: "common_name",
  selectableOnSaleWatcher: "selectable_on_sale_watcher",
};

export const serializeDraftingInformation = buildSerializer(
  serializeMap,
  "draftingInformation",
);
export const deserializeDraftingInformation = buildDeserializer(
  serializeMap,
  "draftingInformation",
);
