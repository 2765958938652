import React, { memo } from "react";

import { faBalanceScale, faCogs } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { FocusedSidebarBackButton } from "components/Layout/FocusedLayout";
import { SidebarListItem } from "components/Sidebar/SidebarListItem";

import {
  getLivestockSaleId,
  getSaleEntryRoute,
  getSaleyardName,
  getSingleWeighRoute,
  openEditSingleWaySettingsModal,
} from "lib/navigation";

import { getCurrentSale, getCurrentSingleWeigh } from "selectors";

const SingleWeighPickerButton = React.memo(() => {
  const history = useHistory();
  function onClickBack() {
    history.push(getSingleWeighRoute(getSaleyardName(), getLivestockSaleId()));
  }

  return (
    <SidebarListItem
      onClick={onClickBack}
      icon={faBalanceScale}
      label="Select Single Weigh"
    />
  );
});

const SingleWeighSettingsButton = React.memo(() => {
  const currentSingleWeigh = useSelector(getCurrentSingleWeigh);
  if (!currentSingleWeigh) {
    return null;
  }

  function handleClick() {
    openEditSingleWaySettingsModal(currentSingleWeigh.id);
  }
  return (
    <SidebarListItem
      onClick={handleClick}
      icon={faCogs}
      label={`${currentSingleWeigh.name} Settings`}
    />
  );
});

function SingleWeighViewSidebarContent() {
  const saleType = useSelector(state => getCurrentSale(state)?.sale_type);

  const backUrl = getSaleEntryRoute(
    getSaleyardName(),
    getLivestockSaleId(),
    saleType,
  );

  return (
    <>
      <FocusedSidebarBackButton backUrl={backUrl} />
      <SingleWeighPickerButton />
      <SingleWeighSettingsButton />
    </>
  );
}

SingleWeighViewSidebarContent.propTypes = {};

export default memo(SingleWeighViewSidebarContent);
