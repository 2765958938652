import { v4 as uuidv4 } from "uuid";

import {
  BULK_MOVE_SCANS_COMMIT,
  BULK_MOVE_SCANS_OFFLINE,
  BULK_MOVE_SCANS_ROLLBACK,
  DELETE_SALE_LOT_SCANS_COMMIT,
  DELETE_SALE_LOT_SCANS_OFFLINE,
  DELETE_SALE_LOT_SCANS_ROLLBACK,
  SCAN,
  SET_ANIMAL_DECEASED_COMMIT,
  SET_ANIMAL_DECEASED_OFFLINE,
  SET_ANIMAL_DECEASED_ROLLBACK,
  UPDATE_SCAN_NLIS_COMMIT,
  UPDATE_SCAN_NLIS_OFFLINE,
  UPDATE_SCAN_NLIS_ROLLBACK,
  UPLOAD_NLIS_ID_COMMIT,
  UPLOAD_NLIS_ID_OFFLINE,
  UPLOAD_NLIS_ID_ROLLBACK,
  UPLOAD_SCANS_COMMIT,
  UPLOAD_SCANS_OFFLINE,
  UPLOAD_SCANS_ROLLBACK,
} from "constants/actionTypes";

import { getSaleUrl } from "lib/navigation";
import { getApiSale } from "lib/sale";

/**
 * @typedef {Object} ScanPayload
 * @property {String} [device_id] the device id provided by the hub which scanned this EID
 * @property {String} [device_name] the name of the scanner which this EID came through
 * @property {String} [draft_name] the name of the Draft which this EID came through
 * @property {String} [EID] the RFID read from the EID tag
 * @property {String} [NLISID] the NLIS ID of the animal as present on the EID tag
 * @property {String} created ISO8601 date of scan time
 * @property {Boolean} [remove_from_salelot] optionally remove eid from any existing sale lots in this sale
 */

/**
 * @param {ScanPayload} scans
 * @param {ApiSale} sale
 */
export function uploadUnallocatedScans(scans, sale) {
  const apiBody = { payload: scans };
  const meta = { scans };

  return {
    type: UPLOAD_SCANS_OFFLINE,
    payload: meta,
    meta: {
      offline: {
        effect: {
          url: `${getSaleUrl(sale)}/scans/`,
          method: "POST",
          body: JSON.stringify(apiBody),
        },
        commit: {
          type: UPLOAD_SCANS_COMMIT,
          meta,
        },
        rollback: {
          type: UPLOAD_SCANS_ROLLBACK,
          meta,
        },
      },
    },
  };
}

/**
 * Sets the given eids to be assigned to the given sale lot.
 * Don't use this action directly, instead call bulkMoveScans or splitSingleWeighedSaleLot, which will prepare the currentSaleLotId map
 *
 * @param {Array<ScanPayload>} eids - a list of EIDs
 * @param saleLotId - the lot to assign to
 * @param currentSaleLotIdMap - a map of EID -> sale lot id for rolling back errors.
 * @returns {Object}
 */

export function bulkMoveScansOffline(eids, saleLotId, currentSaleLotIdMap) {
  const requestBody = { payload: eids };
  // Use the currentSaleLotIdMap to allow a rollback on failure.
  const meta = { eids, saleLotId, currentSaleLotIdMap };

  return {
    type: BULK_MOVE_SCANS_OFFLINE,
    payload: meta,
    meta: {
      offline: {
        effect: {
          url: `/v2/salelots/${saleLotId}/scans/`,
          method: "PATCH",
          body: JSON.stringify(requestBody),
        },
        commit: {
          type: BULK_MOVE_SCANS_COMMIT,
          meta,
        },
        rollback: {
          type: BULK_MOVE_SCANS_ROLLBACK,
          meta,
        },
      },
    },
  };
}

export function setAnimalDeceased(scan, is_deceased) {
  const apiBody = { is_deceased };
  const meta = { scan, is_deceased };

  return {
    type: SET_ANIMAL_DECEASED_OFFLINE,
    payload: meta,
    meta: {
      offline: {
        effect: {
          url: `/v2/scans/${scan.id}/deceased/`,
          method: "PATCH",
          body: JSON.stringify(apiBody),
        },
        commit: {
          type: SET_ANIMAL_DECEASED_COMMIT,
          meta,
        },
        rollback: {
          type: SET_ANIMAL_DECEASED_ROLLBACK,
          meta,
        },
      },
    },
  };
}

export function patchScan(scan, payload) {
  return {
    type: SCAN.UPDATE.REQUEST,
    meta: {
      EID: scan.EID,
      payload,

      offline: {
        effect: {
          url: `/v2/scans/${scan.id}/`,
          method: "PATCH",
          body: JSON.stringify(payload),
        },
        commit: {
          type: SCAN.UPDATE.SUCCESS,
        },
        rollback: {
          type: SCAN.UPDATE.FAILURE,
        },
      },
    },
  };
}

/**
 * @param {string} saleLotId
 * @param {ApiSale} sale the livestock needed for side affects
 * Don't use this action directly, instead call deleteSaleLotScansAction
 */
export function deleteSaleLotScansOffline(saleLotId, sale) {
  const meta = { saleLotId, sale };

  return {
    type: DELETE_SALE_LOT_SCANS_OFFLINE,
    payload: meta,
    meta: {
      offline: {
        effect: {
          url: `/v2/salelots/${saleLotId}/scans/`,
          method: "DELETE",
        },
        commit: {
          type: DELETE_SALE_LOT_SCANS_COMMIT,
          meta,
        },
        rollback: {
          type: DELETE_SALE_LOT_SCANS_ROLLBACK,
          meta,
        },
      },
    },
  };
}

/**
 * @param {object} scan
 * Don't use this action directly, instead call deleteScanAction
 */
export function deleteScan(scan) {
  const meta = { scan };

  return {
    type: SCAN.DELETE.REQUEST,
    payload: meta,
    meta: {
      offline: {
        effect: {
          url: `/v2/scans/${scan.id}/`,
          method: "DELETE",
        },
        commit: {
          type: SCAN.DELETE.SUCCESS,
          meta,
        },
        rollback: {
          type: SCAN.DELETE.FAILURE,
          meta,
        },
      },
    },
  };
}

export function uploadNLISIdToSale(scans, sale) {
  const payload = { payload: scans };
  const meta = { scans };

  return {
    type: UPLOAD_NLIS_ID_OFFLINE,
    payload,
    meta: {
      offline: {
        effect: {
          url: `${getSaleUrl(sale)}/scans/`,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: UPLOAD_NLIS_ID_COMMIT,
          meta,
        },
        rollback: {
          type: UPLOAD_NLIS_ID_ROLLBACK,
          meta,
        },
      },
    },
  };
}

export function updateNLISScanDetails(scans) {
  const sale = getApiSale();
  const saleUrl = getSaleUrl(sale);
  const apiBody = {
    payload: scans.map(scan => ({
      EID: scan.animal?.EID || scan.EID,
      NLISID: scan.animal?.nlis_id,
      id: scan.id,
    })),
  };
  const meta = {
    scans,
  };

  return {
    type: UPDATE_SCAN_NLIS_OFFLINE,
    payload: meta,
    meta: {
      offline: {
        effect: {
          url: `${saleUrl}/scans/nlisupdate/`,
          method: "POST",
          body: JSON.stringify(apiBody),
        },
        commit: {
          type: UPDATE_SCAN_NLIS_COMMIT,
          meta,
        },
        rollback: {
          type: UPDATE_SCAN_NLIS_ROLLBACK,
          meta,
        },
      },
    },
  };
}

/**
 *
 * @param {Array<ScanPayload>} scans
 * @param saleLotId
 * @returns {Object}
 *
 * Don't use this action directly, instead call uploadScansAction
 */
export function uploadScans(scans, saleLotId) {
  const scansWithTempId = scans.map(s => ({
    id: uuidv4(),
    ...s,
  }));
  const requestBody = { payload: scansWithTempId };

  const meta = { scans: scansWithTempId, saleLotId };

  return {
    type: UPLOAD_SCANS_OFFLINE,
    payload: meta,
    meta: {
      offline: {
        effect: {
          url: `/v2/salelots/${saleLotId}/scans/`,
          method: "POST",
          body: JSON.stringify(requestBody),
        },
        commit: {
          type: UPLOAD_SCANS_COMMIT,
          meta,
        },
        rollback: {
          type: UPLOAD_SCANS_ROLLBACK,
          meta,
        },
      },
    },
  };
}

export function bulkUpdateScanNLISStatus() {
  const sale = getApiSale();
  const saleUrl = getSaleUrl(sale);

  return {
    type: SCAN.BULK_UPDATE_NLIS_STATUS.REQUEST,
    meta: {
      offline: {
        effect: {
          url: `${saleUrl}/scans/refresh-nlis-status/`,
          method: "POST",
        },
        commit: {
          type: SCAN.BULK_UPDATE_NLIS_STATUS.SUCCESS,
        },
        rollback: {
          type: SCAN.BULK_UPDATE_NLIS_STATUS.FAILURE,
        },
      },
    },
  };
}
