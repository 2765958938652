import React from "react";

import { faCrosshairs } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { Form, useFormikContext } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import StateAwareBusinessPICSelector from "components/BusinessPICSelector";
import { Button, DeleteButton } from "components/Form";
import { Input, TextArea } from "components/Form/FormikControls";
import { ExclamationIcon } from "components/Icons";
import { Paper } from "components/Paper";

const SubHeading = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.delta}px;
  // margin: ${({ theme }) => theme.space[0]}px 0;
`;

const CrosshairIcon = styled(FontAwesomeIcon).attrs({
  icon: faCrosshairs,
})`
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

const Small = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.beta}px;
  color: ${({ theme }) => theme.colors.gray40};
  ${({ italics }) => (italics ? "font-style: italic" : null)}
  ${({ error, theme }) => (error ? `color: ${theme.colors.error}` : null)}
  ${({ bottomMargin, theme }) =>
    bottomMargin ? `display:block; margin-bottom: ${theme.space[2]}px` : null}
`;

/*
  This is for create + edit
  To switch modes, a handleDelete function must be passed into the component
*/
const BobbyCalfConsignmentForm = ({
  handleDeleteClick,
  handleScanClick,
  isEdit,
}) => {
  const formikProps = useFormikContext();
  const { errors, isValid, values, handleSubmit } = formikProps;

  const scanClick = () => {
    handleScanClick(values);
  };

  const noPIC = !values.vendor_property_id || values.vendor_property_id === -1;

  return (
    <Form data-tour="consignment-form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StateAwareBusinessPICSelector
            label="Vendor"
            businessFieldName="vendor_id"
            propertyFieldName="vendor_property_id"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SubHeading>Vendor Declaration</SubHeading>
                <Small>(can be added later)</Small>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input type="tel" label="NVD Number" name="NVD" maxLength={9} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  type="tel"
                  label="Headcount (HC)"
                  name="quantity"
                  maxLength={9}
                  helpText="Enter 0 to use scan count."
                />
              </Grid>

              {errors.fileSize && <Small error>{errors.fileSize}</Small>}
              {errors.fileType && <Small error>{errors.fileType}</Small>}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {noPIC && (
            <Small italics bottomMargin>
              <ExclamationIcon /> PIC Missing
            </Small>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextArea label="Notes" name="notes" rows={5} />
        </Grid>

        <Grid item xs={6} container>
          {isEdit ? (
            <DeleteButton
              onClick={handleDeleteClick}
              data-tour="deleteConsignment"
              type="button"
            >
              Delete Consignment
            </DeleteButton>
          ) : (
            <Button
              type="button"
              onClick={scanClick}
              disabled={!isValid}
              data-tour="save-and-scan"
            >
              <CrosshairIcon />
              Save & Scan
            </Button>
          )}
        </Grid>
        <Grid item xs={6} container>
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={!isValid}
            data-tour="save-and-close"
          >
            Save & Close
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

BobbyCalfConsignmentForm.propTypes = {
  handleDeleteClick: PropTypes.func,
  handleScanClick: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default BobbyCalfConsignmentForm;
