import {
  ADD_CONSIGNMENT_COMMIT,
  ADD_CONSIGNMENT_FROM_SOCKET,
  ADD_CONSIGNMENT_OFFLINE,
  GET_CONSIGNMENTS_CHANGES_SUCCESS,
  GET_CONSIGNMENTS_SUCCESS,
  PATCH_CONSIGNMENT_COMMIT,
  PATCH_CONSIGNMENT_FROM_SOCKET,
  PATCH_CONSIGNMENT_OFFLINE,
} from "constants/actionTypes";

import { deserializeCarrierCharge } from "lib/serializers/carrierCharges";

const initialState = {
  isFetching: false,
  charges: {},
};

function carrierChargesReducer(state = initialState, action) {
  switch (action.type) {
    // Update our list of carrier charges from consignments, e.g. A carrier
    // charge was created/set on a consignment on another device
    case GET_CONSIGNMENTS_CHANGES_SUCCESS:
    case GET_CONSIGNMENTS_SUCCESS: {
      const { consignments } = action;

      if (
        action.type === GET_CONSIGNMENTS_CHANGES_SUCCESS &&
        consignments.length === 0
      ) {
        // If nothing has changed, don't force a state update.
        return state;
      }

      const newState = {
        ...state,
        charges: state.charges,
      };
      consignments.forEach(consignment => {
        const carrierChargePayload = consignment.carrier_charge;
        if (carrierChargePayload) {
          const carrierCharge = deserializeCarrierCharge(carrierChargePayload);
          newState.charges[carrierCharge.id] = carrierCharge;
        }
      });
      return newState;
    }

    // Update our list of carrier charges from locally created consignments
    case PATCH_CONSIGNMENT_OFFLINE:
    case ADD_CONSIGNMENT_OFFLINE: {
      let { carrier_charge_id: carrierChargeId } = action.payload;
      const { carrier_charge: carrierCharge } = action.payload;
      const { tempCarrierChargeId } = action.meta.offline.commit.meta;

      const newState = {
        ...state,
        charges: {
          ...state.charges,
        },
      };

      if (carrierCharge) {
        if (!carrierChargeId) {
          carrierChargeId = tempCarrierChargeId;
        }

        newState.charges[carrierChargeId] = {
          ...carrierCharge,
          id: carrierChargeId,
          syncing: true,
        };
      }
      return newState;
    }

    case ADD_CONSIGNMENT_COMMIT:
    case ADD_CONSIGNMENT_FROM_SOCKET:
    case PATCH_CONSIGNMENT_COMMIT:
    case PATCH_CONSIGNMENT_FROM_SOCKET: {
      const { carrier_charge: carrierChargePayload } = action.payload;
      const { tempCarrierChargeId } = action.meta;
      const newState = {
        ...state,
        charges: {
          ...state.charges,
        },
      };

      delete newState.charges[tempCarrierChargeId];

      if (carrierChargePayload) {
        const carrierCharge = deserializeCarrierCharge(carrierChargePayload);
        newState.charges[carrierCharge.id] = carrierCharge;
      }

      return newState;
    }

    default:
      return state;
  }
}

export default carrierChargesReducer;
