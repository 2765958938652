import React, { useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { SaleAction } from "actions";

import { DeploymentSaleCommentAction } from "actions/comments";

import Button, { Clickable } from "components/Button";
import { Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { Paper } from "components/Paper";
import { SaleSearch } from "components/SearchInput/GlobalSearch";
import { BigText, TitleText } from "components/Text";

import { CommentTypes } from "constants/comments";
import { ApiModel } from "constants/loading";
import { SaleStatus } from "constants/sale";
import { Settings } from "constants/settings";
import { userTypes } from "constants/users";

import DashboardSaleCard from "containers/UserDashboard/DashboardSaleCard";

import { openSaleModal } from "lib/navigation";

import {
  getIsFetchingSales,
  getSetting,
  selectIsUserOfType,
  selectSaleIdsGroupedByPastAndFuture,
} from "selectors";

import { useIsMobile } from "hooks";
import DashboardIllustration from "img/dashboard_illustration.svg";

const MainWrapper = styled.div(
  ({ theme }) => `
  padding:${theme.space[3]}px;
  background-color: ${theme.colors.white};
  @media (max-width: ${theme.breakpoints[0]}px) {
    margin: 0;
  }
`,
);

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 60%;
`;

const EmptyStateActionButton = styled.button`
  background: transparent;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primaryHighlight};
  font-weight: normal;
  font-size: 14px;
  border: none;
  line-height: 1.5;
  margin: auto 0px;
`;

const IllustrationContainer = styled.div`
  height: 150px;
  width: 150px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
`;

const EmptyStateIllustration = styled.img`
  padding: 10px;
  margin: auto;
`;

const LoadMoreButton = styled(Clickable)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
  position: relative;
`;

const LoadMoreButtonSpinner = styled(CircularProgress)`
  position: absolute;
  left: calc(50% - 80px);
`;

const AddSaleButton = styled(Button)`
  margin-left: 10px;
  min-height: 2.5rem;
`;

const SaleRowsContainer = ({
  saleStatus,
  title,
  saleIds,
  isMobile,
  emptyStateText,
}) => {
  const dispatch = useDispatch();
  const allSalesLoaded = useSelector(getSetting(Settings.allSalesLoaded));
  const isFetchingSales = useSelector(getIsFetchingSales);

  const loadMoreSales = () => {
    if (!allSalesLoaded) {
      dispatch(SaleAction.request({ fetchAll: true }));
    }
  };

  return (
    <div>
      <Row marginVertical={2}>
        <span
          style={{
            fontSize: "16px",
          }}
        >
          {title}
        </span>
      </Row>
      {saleIds.length > 0 ? (
        saleIds.map(saleId => (
          <DashboardSaleCard saleId={saleId} key={saleId} isMobile={isMobile} />
        ))
      ) : (
        <Paper backgroundColor="gray95" isSquare={false}>
          <Row alignCenter flex={false} padding={2} textAlignCenter>
            {emptyStateText}
          </Row>
        </Paper>
      )}

      {!allSalesLoaded && saleStatus === SaleStatus.PAST && (
        <Row justifyCenter>
          <LoadMoreButton onClick={loadMoreSales} style={{ marginTop: "20px" }}>
            {isFetchingSales && <LoadMoreButtonSpinner size={18} />}
            Load more sales
          </LoadMoreButton>
        </Row>
      )}
    </div>
  );
};

const UserDashboard = () => {
  const [pastSaleIds, futureSaleIds] = useSelector(
    selectSaleIdsGroupedByPastAndFuture,
  );
  const isMobile = useIsMobile();
  const allSalesLoaded = useSelector(getSetting(Settings.allSalesLoaded));
  const canCreateSales = useSelector(
    selectIsUserOfType([
      userTypes.SCALE_OPERATOR,
      userTypes.SALEYARD_ADMIN,
      userTypes.LIVESTOCK_AGENT,
    ]),
  );
  const handleOpenSaleModal = () => openSaleModal();
  const dispatch = useDispatch();
  const loadMoreSales = () => {
    if (!allSalesLoaded) {
      dispatch(SaleAction.request({ fetchAll: true }));
    }
  };

  useEffect(() => {
    const requestAction = DeploymentSaleCommentAction.request({
      livestockSaleSensitive: false,
      saleyardSensitive: false,
      commentType: CommentTypes.DEPLOYMENT_SALE,
    });
    dispatch(requestAction);
  }, [dispatch]);

  const hasPastSales = pastSaleIds.length > 0;
  const hasFutureSales = futureSaleIds.length > 0;
  const hasNoTotalSales = !hasPastSales && !hasFutureSales;

  const loadMoreSalesInEmptyState = (
    <>
      There are no sales available to display. <br />
      Only the last 3 months of sales are loaded.
      <EmptyStateActionButton onClick={loadMoreSales}>
        Click to load more
      </EmptyStateActionButton>
    </>
  );
  const addSaleInEmptyState = canCreateSales ? (
    <WaitForSync
      requiredData={[
        ApiModel.ROUNDS,
        ApiModel.SPECIES,
        ApiModel.DEPLOYMENTS,
        ApiModel.SALE_DEFINITIONS,
        ApiModel.AGENCIES,
      ]}
    >
      <EmptyStateActionButton onClick={handleOpenSaleModal}>
        Add one now.
      </EmptyStateActionButton>
    </WaitForSync>
  ) : null;

  let emptyStateText;
  if (hasNoTotalSales) {
    emptyStateText = allSalesLoaded ? (
      <>
        You haven&apos;t added any sales.
        {addSaleInEmptyState}
      </>
    ) : (
      <>
        {loadMoreSalesInEmptyState}
        {addSaleInEmptyState ? "or" : ""}
        {addSaleInEmptyState}
      </>
    );
  } else if (!hasPastSales && hasFutureSales) {
    emptyStateText = allSalesLoaded ? (
      <> You don&apos;t have any past sales.</>
    ) : (
      <>{loadMoreSalesInEmptyState}</>
    );
  } else if (hasPastSales && !hasFutureSales) {
    emptyStateText = (
      <>
        You don&apos;t have any upcoming sales.
        {addSaleInEmptyState}
      </>
    );
  }

  const saleSearchFooterTextAndAction = (
    <>
      This filter is only applying to sales currently on screen. To see more
      results, first{" "}
      <LoadMoreButton data-tour="loadMoreSales" onClick={loadMoreSales}>
        Load more sales
      </LoadMoreButton>
    </>
  );

  return (
    <MainWrapper>
      <Row justifyBetween fullWidth alignCenter>
        <TitleText>Dashboard</TitleText>
        {isMobile && canCreateSales && (
          <WaitForSync
            requiredData={[
              ApiModel.ROUNDS,
              ApiModel.SPECIES,
              ApiModel.DEPLOYMENTS,
              ApiModel.SALE_DEFINITIONS,
              ApiModel.AGENCIES,
            ]}
            showLoadingSpinner={false}
          >
            <AddSaleButton
              data-tour="addSale"
              minWidth="140px"
              onClick={handleOpenSaleModal}
            >
              Add Sale
            </AddSaleButton>
          </WaitForSync>
        )}
      </Row>
      <Row fullWidth justifyBetween marginVertical={2}>
        <BigText>Sales</BigText>
        <ActionButtons>
          <SaleSearch
            overridePlaceHolder="Filter..."
            showFooter={!allSalesLoaded}
            footerTextAndAction={saleSearchFooterTextAndAction}
          />
          {!isMobile && canCreateSales && (
            <WaitForSync
              requiredData={[
                ApiModel.ROUNDS,
                ApiModel.SPECIES,
                ApiModel.DEPLOYMENTS,
                ApiModel.SALE_DEFINITIONS,
                ApiModel.AGENCIES,
              ]}
              showLoadingSpinner={false}
            >
              <AddSaleButton
                data-tour="addSale"
                minWidth="140px"
                onClick={handleOpenSaleModal}
              >
                Add Sale
              </AddSaleButton>
            </WaitForSync>
          )}
        </ActionButtons>
      </Row>
      {!hasNoTotalSales ? (
        <>
          <SaleRowsContainer
            saleStatus={SaleStatus.FUTURE}
            title="Now and Future"
            saleIds={futureSaleIds}
            isMobile={isMobile}
            emptyStateText={emptyStateText}
          />
          <SaleRowsContainer
            saleStatus={SaleStatus.PAST}
            title="Past"
            saleIds={pastSaleIds}
            isMobile={isMobile}
            emptyStateText={emptyStateText}
          />
        </>
      ) : (
        <Paper backgroundColor="gray95" isSquare={false}>
          <Row paddingVertical={3}>
            <IllustrationContainer>
              <EmptyStateIllustration src={DashboardIllustration} />
            </IllustrationContainer>
          </Row>
          <Row alignCenter flex={false} textAlignCenter fullWidth>
            {emptyStateText}
          </Row>
        </Paper>
      )}
    </MainWrapper>
  );
};

export default UserDashboard;
