import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { sendDraftingDecision, updateCurrentDraftingDecision } from "actions";

import Button from "components/Button";
import { Label } from "components/Form/FormikControls";
import { Column, Row } from "components/Layout";
import ReactSelect from "components/SearchableSelector/ReactSelect";

import { getAuctionPenDisplayName } from "lib/auctionPens";

import {
  getAuctionPenById,
  getAutoDraftPensAsOptionsBySingleWeighId,
  getCanSendDraftingDecisionBySingleWeighId,
  getCurrentDraftingDecisionBySingleWeighById,
} from "selectors";

export function PenSelectionAction({ singleWeighId }) {
  const currentDecision = useSelector(
    getCurrentDraftingDecisionBySingleWeighById(singleWeighId),
  );
  const allowSendDecision = useSelector(
    getCanSendDraftingDecisionBySingleWeighId(singleWeighId),
  );

  const dispatch = useDispatch();
  function handleDispatchDecision() {
    dispatch(sendDraftingDecision(singleWeighId, currentDecision));
  }
  function handlePenChange({ value }) {
    dispatch(
      updateCurrentDraftingDecision(singleWeighId, {
        draftPenId: value,
        manualDraftPenDecision: true,
      }),
    );
  }

  const options = useSelector(
    getAutoDraftPensAsOptionsBySingleWeighId(singleWeighId),
  );
  const { draftPenId } = currentDecision || {};
  const draftPen = useSelector(getAuctionPenById(draftPenId));

  return (
    <Row justifyAround fullWidth>
      <Column data-tour="selectDraftPen">
        <Label>Select Draft Pen</Label>
        <ReactSelect
          options={options}
          name="Draft Pen"
          value={options.find(option => option.value === draftPenId)}
          isClearable={false}
          onChange={handlePenChange}
          menuPortalTarget={document.body}
        />
      </Column>
      <Column>
        <Button
          data-tour={
            draftPenId
              ? `sendToAutoDraftPen${getAuctionPenDisplayName(draftPen)}`
              : "noAutoDraftPenSelected"
          }
          disabled={!allowSendDecision}
          onClick={handleDispatchDecision}
        >
          {draftPenId
            ? `Send to Auto Draft Pen ${getAuctionPenDisplayName(draftPen)}`
            : "No Auto Draft Pen Selected"}
        </Button>
      </Column>
    </Row>
  );
}
