import React from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

import SearchableSelector from "components/SearchableSelector";

import { openSaleModal } from "lib/navigation";

const FooterButton = styled.button`
  width: ${({ isMulti }) => (isMulti ? "40vw" : "100%")};
  height: 48px;
  border: none;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  cursor: pointer;
  font-size: ${({ theme }) => `${theme.fontSizes.beta + 2}px`};
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

const Dropdown = styled(SearchableSelector).attrs(({ theme, onAddSale }) => {
  const handleOpenSaleModal = () => openSaleModal();
  const getFooterButton = (rowHeight, isMulti) => (
    <FooterButton
      id="dropdown-footer"
      onClick={handleOpenSaleModal}
      style={{ height: rowHeight }}
      isMulti={isMulti}
      data-tour="newSaleDay"
    >
      <Icon icon={faPlus} /> New Sale Day
    </FooterButton>
  );

  return {
    large: true,
    maxDropdownHeight: 500,
    listStyle: {
      border: `1px solid ${theme.colors.gray78}`,
      borderRadius: theme.radii[1],
    },
    searchRowStyle: {
      fontSize: theme.fontSizes.gamma,
      color: theme.colors.gray40,
      border: `1px solid ${theme.colors.gray78}`,
      borderBottom: "none",
      fontWeight: 400,
      paddingLeft: "8px",
    },
    rowStyle: {
      fontSize: theme.fontSizes.gamma,
      color: theme.colors.gray40,
      borderBottom: `1px solid ${theme.colors.gray78}`,
      padding: 0,
      fontWeight: 400,
    },
    topRowStyle: {
      padding: `${theme.space[2]}px ${theme.space[1]}px`,
      fontWeight: theme.fontWeights.normal,
      fontSize: theme.fontSizes.gamma,
    },
    displayIconStyle: {
      marginLeft: theme.space[2],
    },
    renderMultiFooter: onAddSale ? getFooterButton : null,
  };
})``;

export default Dropdown;
