import * as Yup from "yup";

const arrivalScanningValidation = {
  quantity: Yup.number("Invalid number entered. Please enter a numeric value.")
    .required("You must enter a quantity for the arrival scans.")
    .min(1, "Quantity must be more than 0"),
  mark: Yup.string().required("You must enter a mark for the arrival scans."),
};

export const simpleReceivalLotValidationSchema = Yup.object().shape(
  arrivalScanningValidation,
);

export const receivalPenValidation = {
  receivalPenId: Yup.string().required("You must select a receival pen."),
};

export const extendedReceivalLotValidationSchema = Yup.object().shape({
  ...arrivalScanningValidation,
  consignmentId: Yup.string().nullable(),
  hasMarksConflict: Yup.bool().isFalse(),
  ...receivalPenValidation,
});
