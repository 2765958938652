import React from "react";

import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  closeConfirmModal,
  copySaleLot,
  deleteSaleLot,
  openConfirmModal,
  splitSingleWeighedSaleLot,
} from "actions";

import { SubtleBadge } from "components/Badge";
import { FormCollapse, LabelArea, SecondaryButton } from "components/Form";
import { Error } from "components/Form/FormikControls/Error";
import { Column, Row } from "components/Layout";
import { ExternalLink } from "components/Link";
import { StatusText } from "components/Text";

import { ConsignmentPermissions } from "constants/permissions";
import { SaleTypes } from "constants/sale";
import { SplitType } from "constants/saleLots";

import {
  ForClearingSale,
  ForLivestockSale,
  ForNotClearingSale,
} from "containers/ForSaleType";

import { formatWeightKg } from "lib";

import {
  closeAllHashModals,
  openMergeLotsModal,
  openSplitSaleLotModal,
} from "lib/navigation";
import { pluralize } from "lib/pluralize";
import { isScanWeighed } from "lib/scans";
import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

import {
  getConsignmentById,
  getCurrentSale,
  getSaleLotById,
  getScansBySaleLotId,
  selectSaleOptionsByFutureSales,
} from "selectors";

import { useHasPermission } from "hooks/useHasPermission";

const header = <SubtleBadge>Actions</SubtleBadge>;

function ActionsCollapseComponent({
  isOpen,
  onToggle,
  saleLotId,
  toggleEidsSection,
  onClose,
  readOnly,
}) {
  const sales = useSelector(selectSaleOptionsByFutureSales);
  const saleLot = useSelector(getSaleLotById(saleLotId));
  const scans = useSelector(getScansBySaleLotId(saleLotId)) || [];
  const filteredSales = Object.values(sales).filter(sale => {
    return sale.metadata[0].value === SaleTypes.CLEARING;
  });
  const sale = useSelector(getCurrentSale);
  const currentHash = window.location.hash;
  function onSplitLot() {
    openSplitSaleLotModal(saleLotId, SplitType.STANDARD, false, currentHash);
  }

  function onCrippleSplit() {
    openSplitSaleLotModal(saleLotId, SplitType.CRIPPLE, false, currentHash);
  }

  function onNCVSplit() {
    openSplitSaleLotModal(saleLotId, SplitType.NCV, false, currentHash);
  }

  function onTransitEventSplit() {
    openSplitSaleLotModal(saleLotId, SplitType.TRANSIT, false, currentHash);
  }

  function onMergeLots() {
    openMergeLotsModal(saleLotId, currentHash);
  }

  const isSaleLotPenned = saleLot.auction_pen_id || false;

  const dispatch = useDispatch();

  const copyModal = livestockSale =>
    openConfirmModal({
      title: "Copy Sale Lot to Another Sale?",
      message: `This will copy this Sale Lot to ${livestockSale.preText} ${livestockSale.name}`,
      actions: [
        {
          label: "Cancel",
          secondary: true,
          onClick: () => {
            dispatch(closeConfirmModal());
          },
        },
        {
          label: "Confirm",
          onClick: () => {
            dispatch(copySaleLot(saleLotId, livestockSale.id));
            dispatch(closeConfirmModal());
          },
        },
      ],
    });

  const moveModal = (livestockSale, saleLot) =>
    openConfirmModal({
      title: "Move Sale Lot to Another Sale?",
      message: `This will move this Sale Lot to ${livestockSale.preText} ${livestockSale.name}`,
      actions: [
        {
          label: "Cancel",
          secondary: true,
          onClick: () => {
            dispatch(closeConfirmModal());
          },
        },
        {
          label: "Confirm",
          onClick: () => {
            dispatch(copySaleLot(saleLotId, livestockSale.id));
            dispatch(deleteSaleLot(saleLot));
            dispatch(closeConfirmModal());
            onClose();
          },
        },
      ],
    });

  const handleSaleClick = (livestockSale, saleLot) => {
    const buyer = saleLot.buyer_id;
    const price = saleLot.total_price_cents;
    dispatch(
      openConfirmModal({
        title: `Copy ${!buyer ? "or Move" : ""} This Sale Lot to Another Sale?`,
        message: `Do you want to copy ${
          !buyer ? "or move" : ""
        } this Sale Lot to ${livestockSale.preText} ${livestockSale.name}?`,
        actions: [
          {
            label: "No",
            secondary: true,
            onClick: () => {
              dispatch(closeConfirmModal());
            },
          },
          {
            label: "Copy",
            onClick: () => {
              dispatch(copyModal(livestockSale, saleLot));
            },
          },
          !buyer &&
            !price && {
              label: "Move",
              onClick: () => {
                dispatch(moveModal(livestockSale, saleLot));
              },
            },
        ].filter(Boolean),
      }),
    );
  };

  const handleCopyWithinSaleClick = () => {
    dispatch(
      openConfirmModal({
        title: "Duplicate Sale Lot?",
        message: "This will duplicate this Sale Lot within the current sale.",
        actions: [
          {
            label: "No",
            secondary: true,
            onClick: () => {
              dispatch(closeConfirmModal());
            },
          },
          {
            label: "Yes",
            onClick: () => {
              dispatch(copySaleLot(saleLotId, sale.livestocksale_id));
              dispatch(closeConfirmModal());
            },
          },
        ],
      }),
    );
  };

  // If the lot has single and bulk weigh records, allow them to be split out.
  // Options are:
  // Each EID to have it's own lot, or to a single lot containing all single weighed lots.
  // Should the single weighed weights be removed from this lot, or left in place.

  const singleWeighedScans = scans.filter(isScanWeighed);
  const totalSingleWeighedWeight = singleWeighedScans.reduce(
    (acc, scan) => acc + scan.total_mass_grams,
    0,
  );
  const hasBulkAndSingleWeighEntries =
    saleLot.timeWeighed && singleWeighedScans.length > 0;

  const handleSplitSingleWeighedToMultipleLots = () => {
    const message =
      singleWeighedScans.length > 1
        ? "The EIDs that have been single weighed will be  split to their own individual sale lots.  The total weight can be subtracted from the total bulk weighed weight of this lot, or left intact."
        : "The EID that has been single weighed will be split to it's own lot.  The weight of that animal can be subtracted from the total bulk weighed weight of this lot, or left intact.";

    const actions = [
      {
        label: "Subtract Weight From This Lot",
        onClick: () => {
          dispatch(splitSingleWeighedSaleLot(saleLotId, true, true));
          dispatch(closeConfirmModal());
          closeAllHashModals();
        },
      },
      {
        label: "Keep Weight On This Lot",
        onClick: () => {
          dispatch(splitSingleWeighedSaleLot(saleLotId, true, false));
          dispatch(closeConfirmModal());
          closeAllHashModals();
        },
      },
    ];

    dispatch(
      openConfirmModal({
        title: "Split Single Weighed Lot?",
        message,
        actions,
      }),
    );
  };

  const canCreateSaleLot = useHasPermission(
    getConsignmentById(saleLot.consignment_id),
    ConsignmentPermissions.canAddSaleLot,
  );

  const disableSplit = readOnly || !canCreateSaleLot;

  const handleSplitSingleWeighedToSingleLot = () => {
    const message =
      singleWeighedScans.length > 1
        ? "The EIDs that have been single weighed will be split to a single sale lot with all EIDs.  The total weight can be subtracted from the total bulk weighed weight of this lot, or left intact."
        : "The EID that has been single weighed can be split to it's own lot.  The weight of that animal can be subtracted from the total bulk weighed weight of this lot, or left intact.";

    const actions = [
      {
        label: "Subtract Weight From This Lot",
        onClick: () => {
          dispatch(splitSingleWeighedSaleLot(saleLotId, false, true));
          dispatch(closeConfirmModal());
          closeAllHashModals();
        },
      },
      {
        label: "Keep Weight On This Lot",
        onClick: () => {
          dispatch(splitSingleWeighedSaleLot(saleLotId, false, false));
          dispatch(closeConfirmModal());
          closeAllHashModals();
        },
      },
    ];

    dispatch(
      openConfirmModal({
        title: "Split Single Weighed Lot?",
        message,
        actions,
      }),
    );
  };

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header}
      id="collapse-actions"
      dataTour={isOpen ? "hideActions" : "showActions"}
    >
      <Grid item xs={12}>
        <Column full>
          <ForClearingSale>
            <LabelArea>Copy or Move to Another Sale</LabelArea>
            {filteredSales.map(value => (
              <SecondaryButton
                data-tour={value.name}
                key={value.name}
                type="button"
                onClick={() => handleSaleClick(value, saleLot)}
              >
                {value.preText} {value.name}
              </SecondaryButton>
            ))}
            <LabelArea>Duplicate Sale Lot in Current Sale</LabelArea>
            <SecondaryButton
              type="button"
              onClick={() =>
                handleCopyWithinSaleClick(saleLotId, sale.livestocksale_id)
              }
            >
              Duplicate Sale Lot
            </SecondaryButton>
          </ForClearingSale>
          <ForNotClearingSale>
            <Row>
              <LabelArea>Lot Splitting</LabelArea>
            </Row>
            {!isSaleLotPenned && (
              <Error style={{ margin: "10px" }}>
                Lot Needs To Be Penned Before It Can Be N.C.V Split Or Transit
                Split
              </Error>
            )}

            <Grid container spacing={2}>
              <Grid item container xs={3}>
                <SecondaryButton
                  title="Split lot"
                  type="button"
                  onClick={onSplitLot}
                  disabled={disableSplit}
                >
                  Split Lot
                </SecondaryButton>
              </Grid>
              <ForLivestockSale>
                <Grid item container xs={3}>
                  <SecondaryButton
                    title="Crippled animal"
                    type="button"
                    onClick={onCrippleSplit}
                    disabled={disableSplit}
                  >
                    Cripple Split
                  </SecondaryButton>
                </Grid>

                <Grid item container xs={3}>
                  <SecondaryButton
                    title="No commercial value"
                    type="button"
                    onClick={onNCVSplit}
                    disabled={!isSaleLotPenned || disableSplit}
                  >
                    N.C.V. Split
                  </SecondaryButton>
                </Grid>

                <Grid item container xs={3}>
                  <SecondaryButton
                    title="Transit event"
                    type="button"
                    onClick={onTransitEventSplit}
                    disabled={!isSaleLotPenned || disableSplit}
                  >
                    Transit Split
                  </SecondaryButton>
                </Grid>
              </ForLivestockSale>
            </Grid>
            <Row>
              <LabelArea>Merging</LabelArea>
            </Row>
            <Grid container spacing={2}>
              <Grid item container xs={3}>
                <SecondaryButton
                  title="Merge Lots"
                  type="button"
                  onClick={onMergeLots}
                  disabled={readOnly}
                >
                  Merge Lots
                </SecondaryButton>
              </Grid>
            </Grid>

            {hasBulkAndSingleWeighEntries ? (
              <>
                <Row>
                  <LabelArea>Split Single Weighed Animals</LabelArea>
                </Row>
                <Row>
                  <StatusText status="warning">
                    This lot was bulk weighed at{" "}
                    {formatWeightKg(saleLot.total_mass_grams, true)} @
                    {formatUTCToLocalDateTimeString(saleLot.timeWeighed)} and
                    has{" "}
                    <ExternalLink onClick={toggleEidsSection}>
                      {" "}
                      {singleWeighedScans.length}{" "}
                      {pluralize("scan", singleWeighedScans.length)} with a
                      single weigh weight recorded totalling{" "}
                      {formatWeightKg(totalSingleWeighedWeight, true)}.
                    </ExternalLink>
                  </StatusText>
                </Row>

                <Grid container spacing={2}>
                  <Grid item container xs={3}>
                    <SecondaryButton
                      title="Split to Single Sale Lot"
                      type="button"
                      onClick={handleSplitSingleWeighedToSingleLot}
                      disabled={readOnly || disableSplit}
                    >
                      Split to Single Sale Lot
                    </SecondaryButton>
                  </Grid>
                  <Grid item container xs={3}>
                    <SecondaryButton
                      title="Split to Multiple Sale Lots"
                      type="button"
                      onClick={handleSplitSingleWeighedToMultipleLots}
                      disabled={
                        readOnly ||
                        singleWeighedScans.length === 1 ||
                        disableSplit
                      }
                    >
                      Split to Multiple Sale Lots
                    </SecondaryButton>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </ForNotClearingSale>
        </Column>
      </Grid>
    </FormCollapse>
  );
}

ActionsCollapseComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  saleLotId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export const ActionsCollapse = React.memo(
  ActionsCollapseComponent,
  (prevProps, nextProps) =>
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.saleLotId === nextProps.saleLotId,
);
