import React from "react";

import { useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";

import AnimalListItem from "components/AnimalListItem";
import { OutlineButton } from "components/Button";
import { Row } from "components/Layout";

import { openAnimalModal } from "lib/navigation";

const UnallocatedHeading = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.gray9C};
`;

const UnallocatedMessage = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray9C};
`;

const UnallocatedScansList = styled.div`
  padding: ${({ theme }) => theme.space[2]}px;
`;

const UnallocatedAnimalListItem = styled(AnimalListItem)`
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[3]}px;
  border: 1px dashed ${({ theme }) => theme.colors.gray40};
  border-bottom: none;
  cursor: pointer;
`;

const ScanGroup = styled.div`
  padding: ${({ theme }) => theme.space[1]}px;

  & > ${UnallocatedAnimalListItem}:nth-last-child(2) {
    border: 1px dashed ${({ theme }) => theme.colors.gray40};
  }
`;

const ScanGroupHeader = styled(Row)`
  padding: ${({ theme }) => theme.space[0]}px;
  padding-bottom: ${({ theme }) => theme.space[1]}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ScanGroupFooter = styled(Row)`
  padding: ${({ theme }) => theme.space[1]}px;
  justify-content: flex-end;
`;
/**
 * @typedef {Object} Animal
 * @property {String} nlis_id
 * @property {String} last_checked
 * @property {Boolean} ERP_status
 * @property {Boolean} EU_status
 * @property {Boolean} lifetime_traceability
 * @property {Boolean} marked_deceased
 */

/**
 * @typedef {Object} ScanGroup
 * @typedef {String} title
 * @property {Scan[]} scans
 */

/**
 * @typedef {Object} Scan
 * @property {String} EID
 * @property {String} sale_lot_id
 * @property {String} EID
 * @property {Number} livestock_sale_id
 * @property {Number} deployment_id
 * @property {String} created
 * @property {String} cbid
 * @property {Animal} animal
 */

/**
 * An Unallocated scan
 * @typedef {Scan} AllocatableScan
 * @property {string} nlisId
 * @property {string} picFromNLISId
 * @property {Consignment[]} allocatableConsignments
 */

/**
 *
 * @param {ScanGroup[]} groupedScans
 * @param {function} handleAllocateScan callback function
 * @returns {*}
 * @constructor
 */
export function UnallocatedScansListComponent({
  groupedScans,
  handleAllocateScans,
  updateNLISDetails,
}) {
  const match = useRouteMatch();
  if (!Array.isArray(groupedScans) || !groupedScans.length) {
    return null;
  }
  return (
    <UnallocatedScansList>
      <UnallocatedHeading>UNALLOCATED</UnallocatedHeading>
      {groupedScans.map(({ title, scans }, index) => {
        if (!scans.length) {
          return null;
        }
        const scansWithoutNLIS = scans.filter(
          scan => !scan.animal || !scan.animal.nlis_id,
        );
        const allocatableScanInGroup = scans.find(
          scan => scan.allocatableConsignments.length === 1,
        );
        return (
          <ScanGroup key={index}>
            <ScanGroupHeader>
              <UnallocatedHeading>{title}</UnallocatedHeading>
              {scansWithoutNLIS.length > 0 && (
                <OutlineButton
                  onClick={() => updateNLISDetails(scansWithoutNLIS)}
                >
                  Get NLIS Ids
                </OutlineButton>
              )}
              {allocatableScanInGroup && (
                <OutlineButton onClick={() => handleAllocateScans(scans)}>
                  Allocate to Consignment{" "}
                  {
                    allocatableScanInGroup.allocatableConsignments[0].vendor
                      .name
                  }{" "}
                  -{" "}
                  {
                    allocatableScanInGroup.allocatableConsignments[0]
                      .vendor_property.PIC
                  }
                </OutlineButton>
              )}
            </ScanGroupHeader>
            {scans.map(scan => (
              <UnallocatedAnimalListItem
                key={scan.EID}
                EID={scan.EID}
                animal={scan.animal}
                message_no={null}
                onClick={() => openAnimalModal(match.url, scan.EID)}
              />
            ))}
            <ScanGroupFooter>
              {scans.length} {scans.length === 1 ? "Animal" : "Animals"}
            </ScanGroupFooter>
          </ScanGroup>
        );
      })}
      <UnallocatedMessage>
        Create consignments for each vendor to allocate.
      </UnallocatedMessage>
    </UnallocatedScansList>
  );
}

export default UnallocatedScansListComponent;
