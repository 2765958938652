import { createSelector } from "reselect";

import { businessRelations } from "constants/businesses";
import { SaleTypes } from "constants/sale";

import { EMPTY_ARRAY, EMPTY_OBJECT } from "lib";

import {
  getAgencies,
  getCurrentDeploymentSalesList,
  getDeployments,
  getNominations,
  getCurrentSale,
  selectCurrentDeploymentSales,
  getScans,
  getBusinesses,
  getActiveLivestockAgentDeployment,
} from "selectors";

import { reduceXByZId } from "selectors/lib";

export const getAgencyById = agencyId => state =>
  getAgencies(state)[agencyId] || null;

export const selectAgencyIdByDeploymentSaleIdLookup = createSelector(
  [getDeployments, getCurrentDeploymentSalesList],
  (deployments, deploymentSaleList) => {
    const deploymentsList = Object.values(deployments);

    return (deploymentSaleList || []).reduce((acc, deploymentSale) => {
      const deployment = deploymentsList.find(
        deployment => deployment.id === deploymentSale.deployment_id,
      );
      acc[deploymentSale.deployment_sale_id] = deployment?.livestockAgencyId;
      return acc;
    }, {});
  },
);

export const selectAgencyByDeploymentSaleIdLookup = createSelector(
  [getAgencies, selectAgencyIdByDeploymentSaleIdLookup],
  reduceXByZId,
);

export const getAgencyByDeploymentSaleId = deploymentSaleId => state =>
  selectAgencyByDeploymentSaleIdLookup(state)[deploymentSaleId] || EMPTY_OBJECT;

export const getAgencyIdByDeploymentSaleId = deploymentSaleId => state =>
  selectAgencyIdByDeploymentSaleIdLookup(state)[deploymentSaleId] || null;

export const selectAgencyByDeploymentIdLookup = createSelector(
  [getAgencies, getDeployments],
  (agencies, deployments) =>
    Object.values(deployments).reduce((acc, deployment) => {
      acc[deployment.id] = agencies?.[deployment.livestockAgencyId];
      return acc;
    }, {}),
);

export const getAgencyByDeploymentId = deploymentId => state =>
  selectAgencyByDeploymentIdLookup(state)[deploymentId] || null;

const getIncludeAll = (state, includeAll = false) => includeAll;

export const selectAgencyIdByNominationIdLookup = createSelector(
  [selectAgencyByDeploymentIdLookup, getNominations],
  (agencyByDeploymentIdLookup, nominations) =>
    Object.values(nominations).reduce((lookup, nomination) => {
      const agency = agencyByDeploymentIdLookup[nomination.deploymentId];
      lookup[nomination.id] = agency ? agency.id : null;
      return lookup;
    }, {}),
);

const selectAgenciesOptions = createSelector(
  [getAgencies, getIncludeAll],
  (agencies, includeAll) => {
    const agencyOptions = Object.values(agencies).map(agency => ({
      label: agency.shortName || agency.name,
      value: agency.id,
    }));

    return [
      includeAll ? { label: "All", value: null } : null,
      ...agencyOptions,
    ].filter(Boolean);
  },
);

const selectCurrentAgenciesOptions = createSelector(
  [
    getCurrentDeploymentSalesList,
    getDeployments,
    selectAgenciesOptions,
    getIncludeAll,
    getCurrentSale,
  ],
  (
    deploymentSales,
    deployments,
    allAgenciesOptions,
    includeAll,
    currentSale,
  ) => {
    // If the user isn't in a sale, show them all agencies they have access to.
    if (!currentSale) {
      return allAgenciesOptions;
    }

    // Otherwise filter the list by the agencies associated with the sale.
    const agencyOptions = deploymentSales?.map(ds => {
      const deployment = deployments[ds.deployment_id] || {};
      return allAgenciesOptions.find(
        livestockAgencyOption =>
          livestockAgencyOption.value === deployment.livestockAgencyId,
      );
    });

    return [
      includeAll ? { label: "All", value: null } : null,
      ...agencyOptions,
    ].filter(Boolean);
  },
);

export const getCurrentAgenciesOptions = includeAll => state =>
  selectCurrentAgenciesOptions(state, includeAll);

export const getAgenciesOptions = includeAll => state =>
  selectAgenciesOptions(state, includeAll);

export const selectCanSeeAgencyGroups = createSelector(
  [getAgencies],
  agencies =>
    Boolean(Object.values(agencies).find(a => a.agencyGroups?.length > 0)),
);

const selectAgencyIdByEidLookup = createSelector(
  [getScans, getDeployments],
  (scans, deployments) =>
    Object.values(scans).reduce((lookup, scan) => {
      lookup[scan.EID] = deployments[scan.deployment_id]?.livestockAgencyId;
      return lookup;
    }, {}),
);

export const getAgencyIdByEid = eid => state =>
  selectAgencyIdByEidLookup(state)[eid] || null;

export const selectAgencyCodesByBusinessIdLookup = createSelector(
  [getBusinesses, selectCurrentDeploymentSales, getDeployments],
  (businesses, deploymentSales, deployments) =>
    Object.entries(businesses).reduce((acc, [businessId, business]) => {
      const codes = [];
      const businessIds = [businessId, ...(business.supplementary_ids || [])];
      businessIds.forEach(bId => {
        Object.values(deploymentSales).forEach(deploymentSale => {
          if (
            deploymentSale.livestock_agency_id === businesses[bId]?.agencyId
          ) {
            const deploymentId = deploymentSale.deployment_id;
            codes.push(deployments[deploymentId].code);
          }
        });
      });
      acc[businessId] = codes;
      return acc;
    }, {}),
);

export const getAgencyCodesByBusinessId = businessId => state =>
  selectAgencyCodesByBusinessIdLookup(state)[businessId];

export const selectListingAgentByVendorIdLookup = createSelector(
  [getBusinesses],
  businesses =>
    Object.entries(businesses).reduce((acc, [businessId, business]) => {
      const relations =
        business.relationships?.filter(
          relation => relation.relationType === businessRelations.LISTING_AGENT,
        ) || EMPTY_ARRAY;
      const relatedAgentName = relations.map(relation => {
        const name =
          businesses[relation.relatedToId]?.publicDisplayName ||
          businesses[relation.relatedToId]?.name;
        return name;
      });
      acc[businessId] = relatedAgentName;
      return acc;
    }, {}),
);

export const getListingAgentByVendorId = businessId => state => {
  return selectListingAgentByVendorIdLookup(state)[businessId] || EMPTY_ARRAY;
};

export const selectRebateAgentByVendorIdLookup = createSelector(
  [getBusinesses],
  businesses =>
    Object.entries(businesses).reduce((acc, [businessId, business]) => {
      const relations =
        business?.relationships?.filter(
          relation => relation.relationType === businessRelations.REBATE_AGENT,
        ) || EMPTY_ARRAY;
      const relatedAgentName = relations.map(relation => {
        const name =
          businesses[relation.relatedToId]?.publicDisplayName ||
          businesses[relation.relatedToId]?.name;
        return name;
      });
      acc[businessId] = relatedAgentName;
      return acc;
    }, {}),
);

export const getRebateAgentByVendorId = businessId => state => {
  return selectRebateAgentByVendorIdLookup(state)[businessId] || EMPTY_ARRAY;
};

export const selectStockAgentByVendorIdLookup = createSelector(
  [getBusinesses],
  businesses =>
    Object.entries(businesses).reduce((acc, [businessId, business]) => {
      const relations =
        business?.relationships?.filter(
          relation => relation.relationType === businessRelations.STOCK_AGENT,
        ) || EMPTY_ARRAY;
      const relatedAgentName = relations.map(relation => {
        const name =
          businesses[relation.relatedToId]?.publicDisplayName ||
          businesses[relation.relatedToId]?.name;
        return name;
      });
      acc[businessId] = relatedAgentName;
      return acc;
    }, {}),
);

export const getStockAgentByVendorId = businessId => state => {
  return selectStockAgentByVendorIdLookup(state)[businessId] || EMPTY_ARRAY;
};

export const selectDisplayedAgentByVendorId = createSelector(
  [
    selectRebateAgentByVendorIdLookup,
    selectListingAgentByVendorIdLookup,
    selectStockAgentByVendorIdLookup,
    getCurrentSale,
    getBusinesses,
  ],
  (rebate, listing, stock, sale, businesses) =>
    Object.entries(businesses).reduce((acc, [businessId, business]) => {
      let relationshipAgentText;
      const rebateAgent = rebate[business.id] || EMPTY_ARRAY;
      const listingAgent = listing[business.id] || EMPTY_ARRAY;
      const stockAgent = stock[business.id] || EMPTY_ARRAY;
      if (sale.sale_type === SaleTypes.CLEARING) {
        if (listingAgent?.length === 1) {
          relationshipAgentText = listingAgent[0];
        }
      }
      if (business?.relationships?.length > 1) {
        relationshipAgentText = `${business?.relationships.length} Agents`;
      } else if (stockAgent[0]) {
        relationshipAgentText = stockAgent[0];
      } else if (rebateAgent[0]) {
        relationshipAgentText = rebateAgent[0];
      } else if (listingAgent[0]) {
        relationshipAgentText = listingAgent[0];
      }

      acc[businessId] = relationshipAgentText;
      return acc;
    }, {}),
);

export const getDisplayedAgentByVendorId = businessId => state => {
  return selectDisplayedAgentByVendorId(state)[businessId] || EMPTY_ARRAY;
};

export const getActiveLivestockAgentAgency = createSelector(
  [getActiveLivestockAgentDeployment, selectAgencyByDeploymentIdLookup],
  (deployment, agencyByDeploymentIdLookup) => {
    return agencyByDeploymentIdLookup[deployment.id] || EMPTY_OBJECT;
  },
);
