import React from "react";

import { useSelector } from "react-redux";

import { getSaleLotById } from "selectors";

export const ForSaleLotListedWith = React.memo(
  ({ saleLotId, listedWith, children }) => {
    const saleLot = useSelector(getSaleLotById(saleLotId));
    const { exportSites } = saleLot;
    if (!exportSites) {
      return null;
    }

    return exportSites.includes(listedWith) ? children : null;
  },
);
