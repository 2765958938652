import { buildDeserializer } from "lib/serializerUtils";

const sexSerializeMap = {
  id: "id",
  name: "name",
  defaultPricingType: "default_pricing_type",
  hasProgeny: "has_progeny",
};

export const deserializeSex = buildDeserializer(sexSerializeMap, "sex");
