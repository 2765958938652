import { ALEIS_70X0_SCANNER_DRIVER_NAME } from "./aleis70x0";
import {
  ALEIS_90X0_CAPABILITIES,
  ALEIS_90X0_HANDLER,
  ALEIS_90X0_SCANNER_DRIVER_NAME,
} from "./aleis90x0";
import { ALEIS_EID8500_ALLFLEX_RS420_SCANNER_DRIVER_NAME } from "./aleisEID8500";
import { ALEIS_NXM_SCANNER_DRIVER_NAME } from "./aleisNXM";
import { ANDROID_HUB_SCANNER_HANDLER } from "./defaultScanner";
import { GENERIC_SCANNER_DRIVER_NAME } from "./genericScanner";
import {
  SHEARWELL_SDL440S_CAPABILITIES,
  SHEARWELL_SDL440S_SCANNER_DRIVER_NAME,
} from "./shearwellSDL440S";

const SCANNER_CAPABILITIES_MAP = new Map([
  [ALEIS_70X0_SCANNER_DRIVER_NAME, []],
  [ALEIS_90X0_SCANNER_DRIVER_NAME, ALEIS_90X0_CAPABILITIES],
  [ALEIS_EID8500_ALLFLEX_RS420_SCANNER_DRIVER_NAME, []],
  [ALEIS_NXM_SCANNER_DRIVER_NAME, []],
  [GENERIC_SCANNER_DRIVER_NAME, []],
  [SHEARWELL_SDL440S_SCANNER_DRIVER_NAME, SHEARWELL_SDL440S_CAPABILITIES],
]);

const SCANNER_CAPABILITIES_HANDLER = new Map([
  // [ALEIS_70X0_SCANNER_DRIVER_NAME, ALEIS_70X0_HANDLER],
  [ALEIS_90X0_SCANNER_DRIVER_NAME, ALEIS_90X0_HANDLER],
  // [ALEIS_EID8500_ALLFLEX_RS420_SCANNER_DRIVER_NAME, ALEIS_EID8500_ALLFLEX_RS420_HANDLER],
  // [ALEIS_NXM_SCANNER_DRIVER_NAME, ALEIS_NXM_HANDLER],
  // [GENERIC_SCANNER_DRIVER_NAME, GENERIC_SCANNER_HANDLER],
  // [SHEARWELL_SDL440S_SCANNER_DRIVER_NAME, SHEARWELL_SDL440S_HANDLER],
]);

/**
 * Get the list of device capabilities for a given device driver name
 * @param {String} deviceType a device driver name
 * @returns {Array<scannerMessages>}
 */
export function getDeviceTypeCapabilites(deviceType) {
  return SCANNER_CAPABILITIES_MAP.get(deviceType) || [];
}

/**
 * Get the handler for capabilities map for a given device driver name
 * @param {String} deviceType a device driver name
 * @param {number} hubApiVersion the api version exposed by the connected AgriNous Hub
 * @returns {Object}
 */
export function getCapabilitiesHandler(deviceType, hubApiVersion) {
  // Before version 770, the Android Hub handled the shape of the payloads sent to each device.
  // Version 762 (the build of the Android Hub directly preceeding 770) provided the deviceType
  // attribute which enabled the PWA to dispatch raw payloads for each specific device.
  return hubApiVersion < 770
    ? ANDROID_HUB_SCANNER_HANDLER
    : SCANNER_CAPABILITIES_HANDLER.get(deviceType) ||
        ANDROID_HUB_SCANNER_HANDLER;
}

/**
 * Get the handler for capabilities map for a given device driver name
 * @param {String} deviceType a device driver name
 * @param {string} capability a device capability to retrieve the handler for
 * @param {number} hubApiVersion the api version exposed by the connected AgriNous Hub
 * @returns {function}
 */
export function getCapabilityHandler(deviceType, capability, hubApiVersion) {
  return (
    getCapabilitiesHandler(deviceType, hubApiVersion)[capability] ||
    ANDROID_HUB_SCANNER_HANDLER[capability]
  );
}
