import React, { memo } from "react";

import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import Badge from "components/Badge";
import { XsHeading } from "components/Text";

import { getCurrentRoundsList, getRounds } from "selectors";

import {
  NotDraftedCount,
  ReceivedCount,
  RoundDeliveredCount,
  RoundNoSaleCount,
  RoundNotPennedCount,
  RoundPennedCount,
  RoundSoldCount,
} from "./Counts";

const MobileSummaryTable = styled.table(
  ({ theme }) => `
 thead th {
  min-width: 100px;
  }
  td, th {
    padding: ${theme.space[1]}px;
  }
  td {
    font-size: ${theme.fontSizes.alpha}px;
  }
  th {
    text-align: left;
  }
  th.round {
    visibility: hidden;
  }
`,
);

function DeploymentSaleSummaryTableMobileComponent(props) {
  const { deploymentSaleId } = props;
  const roundList = useSelector(getCurrentRoundsList);
  const rounds = useSelector(getRounds);

  const rowSpanCount = roundList.length;

  return (
    <MobileSummaryTable>
      <thead>
        <tr>
          <th>
            <XsHeading bold>Received</XsHeading>
          </th>
          <th>
            <XsHeading bold>Not Drafted</XsHeading>
          </th>
          <th className="round">Round</th>
          <th>
            <XsHeading bold>Not Penned</XsHeading>
          </th>
          <th>
            <XsHeading bold>Penned</XsHeading>
          </th>
          <th>
            <XsHeading bold>No Sale</XsHeading>
          </th>
          <th>
            <XsHeading bold>Sold</XsHeading>
          </th>
          <th>
            <XsHeading bold>Delivered</XsHeading>
          </th>
        </tr>
      </thead>
      <tbody>
        {roundList.map((roundId, index) => (
          <tr key={roundId}>
            {index === 0 && (
              <>
                <td className="received" rowSpan={rowSpanCount}>
                  <ReceivedCount deploymentSaleId={deploymentSaleId} />
                </td>
                <td className="notDrafted" rowSpan={rowSpanCount}>
                  <NotDraftedCount deploymentSaleId={deploymentSaleId} />
                </td>
              </>
            )}
            <td className="round">
              <Badge bold>{rounds[roundId]?.name.toUpperCase()}</Badge>
            </td>
            <td className="notPenned">
              <RoundNotPennedCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
            <td className="penned">
              <RoundPennedCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
            <td className="noSale">
              <RoundNoSaleCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
            <td className="sold">
              <RoundSoldCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
            <td className="delivered">
              <RoundDeliveredCount
                deploymentSaleId={deploymentSaleId}
                roundIndex={index}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </MobileSummaryTable>
  );
}

DeploymentSaleSummaryTableMobileComponent.propTypes = {
  deploymentSaleId: PropTypes.number.isRequired,
};

export default memo(DeploymentSaleSummaryTableMobileComponent);
