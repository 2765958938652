import React from "react";

import { faCheckSquare, faSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import styled from "styled-components/macro";

const CheckboxListItem = ({
  checked,
  highlighted,
  onClick,
  onChange,
  label,
  subLabel,
  scanItem,
}) => (
  <Wrapper
    highlighted={highlighted}
    onClick={onClick}
    htmlFor={label}
    scanItem={scanItem}
  >
    <CheckboxIcon checked={checked} onClick={onChange} />
    <TextWrapper>
      {label}
      {subLabel && <SubLabel>{subLabel}</SubLabel>}
    </TextWrapper>
  </Wrapper>
);

CheckboxListItem.propTypes = {
  checked: PropTypes.bool,
  highlighted: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOf([PropTypes.element, PropTypes.string]).isRequired,
  subLabel: PropTypes.string,
  scanItem: PropTypes.bool,
};

export default CheckboxListItem;

const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme, scanItem }) =>
    scanItem
      ? `${theme.space[1]}px ${theme.space[4]}px`
      : `${theme.space[2]}px`};
  font-size: ${({ theme, scanItem }) =>
    scanItem ? theme.fontSizes.gamma : theme.fontSizes.beta}px;

  ${({ highlighted, theme }) =>
    highlighted
      ? `
    background-color: ${theme.colors.blueHighlight};

    &:after {
      content: "";
      position: absolute;
      border: 24px solid transparent;
      border-color: transparent transparent transparent ${theme.colors.blueHighlight};
      top: 0;
      right: -48px;
    }
  `
      : ""}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxIcon = styled(FontAwesomeIcon).attrs(({ checked }) => ({
  icon: checked ? faCheckSquare : faSquare,
}))`
  position: relative;
  z-index: 1;
  color: ${({ theme, checked }) =>
    checked ? theme.colors.primary : theme.colors.gray40};
  font-size: ${({ theme }) => theme.fontSizes.epsilon}px;
  margin-right: ${({ theme }) => theme.space[2]}px;
`;

const SubLabel = styled.div`
  font-size: 75%;
  display: block;
`;
