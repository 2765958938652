import React, { useState } from "react";

import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { FormCollapse } from "components/Form";
import Property from "components/Property";

import { ForSaleyardAdmin } from "containers/ForUserType";

import {
  selectAgencyBusinessByBusinessId,
  selectNameByDeploymentIdLookup,
} from "selectors";

function PicLi(props) {
  const { propertyId } = props;

  return (
    <li>
      <Property propertyId={propertyId} />
    </li>
  );
}

const DeploymentBusinessComparePanelSection = {
  AGENCIES: 0,
  PROPERTIES: 1,
  EMAIL: 2,
  BUYER_WAY: 3,
};

function DeploymentBusinessComparePanel(props) {
  const { masterBusinessId } = props;

  const [openSections, setOpenSections] = useState(new Set());

  function createToggleSectionHandler(section) {
    return function onClickSection(e) {
      if (openSections.has(section)) {
        const nextOpenSections = new Set(openSections);
        nextOpenSections.delete(section);
        setOpenSections(nextOpenSections);
      } else {
        const nextOpenSections = new Set(openSections).add(section);
        setOpenSections(nextOpenSections);
      }
      // Clicking on the `+ Show more` button bubbles up to the header, causing this function to be called twice
      e.stopPropagation();
    };
  }

  const deploymentBusinessVariants = useSelector(
    state => selectAgencyBusinessByBusinessId(state)[masterBusinessId],
  );

  const deploymentNameByDeploymentId = useSelector(
    selectNameByDeploymentIdLookup,
  );

  const deploymentBusinesses = uniqBy(
    Object.values(deploymentBusinessVariants),
    deploymentBusiness => deploymentBusiness.deployment_id,
  );

  const deploymentNames = deploymentBusinesses
    .map(
      deploymentBusiness =>
        deploymentNameByDeploymentId[deploymentBusiness.deployment_id],
    )
    .sort();

  // TODO
  const uniquePropertyIds = uniq(
    flatten(
      deploymentBusinesses.map(
        deploymentBusiness => deploymentBusiness.propertyIds,
      ),
    ),
  );

  const uniqueBuyerWays = uniqBy(
    flatten(
      deploymentBusinesses.map(
        deploymentBusiness => deploymentBusiness.buyerWays,
      ),
    ),
    buyerWay => buyerWay.name,
  ).sort();

  const uniqueBusinessUsers = uniqBy(
    flatten(
      deploymentBusinesses.map(
        deploymentBusiness => deploymentBusiness.businessUsers,
      ),
    ),
    businessUser => businessUser.email,
  ).sort();

  return (
    <>
      <h2>{deploymentBusinesses[0].name}</h2>

      <ForSaleyardAdmin>
        {deploymentNames.length > 1 ? (
          <FormCollapse
            header={<h3>Agencies ({deploymentNames.length})</h3>}
            isOpen={openSections.has(
              DeploymentBusinessComparePanelSection.AGENCIES,
            )}
            onToggle={createToggleSectionHandler(
              DeploymentBusinessComparePanelSection.AGENCIES,
            )}
          >
            <ul>
              {deploymentNames.map(deploymentName => (
                <li key={deploymentName}>{deploymentName}</li>
              ))}
            </ul>
          </FormCollapse>
        ) : (
          <h3>{deploymentNames[0]}</h3>
        )}
      </ForSaleyardAdmin>

      <FormCollapse
        header={<h3>PICs ({uniquePropertyIds.length})</h3>}
        isOpen={openSections.has(
          DeploymentBusinessComparePanelSection.PROPERTIES,
        )}
        onToggle={createToggleSectionHandler(
          DeploymentBusinessComparePanelSection.PROPERTIES,
        )}
      >
        <ul>
          {uniquePropertyIds.map(propertyId => (
            <PicLi key={propertyId} propertyId={propertyId} />
          ))}
        </ul>
        {uniquePropertyIds.length === 0 && <h4>No PICs</h4>}
      </FormCollapse>

      <FormCollapse
        header={<h3>Emails ({uniqueBusinessUsers.length})</h3>}
        isOpen={openSections.has(DeploymentBusinessComparePanelSection.EMAIL)}
        onToggle={createToggleSectionHandler(
          DeploymentBusinessComparePanelSection.EMAIL,
        )}
      >
        <ul>
          {uniqueBusinessUsers.map(businessUser => (
            <li key={businessUser.id}>
              {businessUser.email}
              {(businessUser.firstName || businessUser.lastName) &&
                ` - ${businessUser.firstName} ${businessUser.lastName}`}
            </li>
          ))}
        </ul>
        {uniquePropertyIds.length === 0 && <h4>No Emails</h4>}
      </FormCollapse>

      <FormCollapse
        header={<h3>Buyer Ways ({uniqueBuyerWays.length})</h3>}
        isOpen={openSections.has(
          DeploymentBusinessComparePanelSection.BUYER_WAY,
        )}
        onToggle={createToggleSectionHandler(
          DeploymentBusinessComparePanelSection.BUYER_WAY,
        )}
      >
        <ul>
          {uniqueBuyerWays.map(buyerWay => (
            <li key={buyerWay.name}>{buyerWay.name}</li>
          ))}
        </ul>
        {uniquePropertyIds.length === 0 && <h4>No Buyer Ways</h4>}
      </FormCollapse>
    </>
  );
}

DeploymentBusinessComparePanel.propTypes = {
  masterBusinessId: PropTypes.string,
};

const BusinessPanelControl = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.surfaceBackground};
  border: 1px solid ${theme.colors.controlBackground};
  box-shadow: ${theme.shadows[1]};
  padding: ${theme.space[2]}px;
  transition: ${theme.transitions[0]};
  
  
  &:hover, &.selected {
    background-color: ${theme.colors.controlBackground};
  }

  &:hover {
    border-color: ${theme.colors.primaryHighlight};
    cursor: pointer;
  }
  
  &.selected {
    border-color: ${theme.colors.primaryActive};
  }
`,
);

export function BusinessComparePanel(props) {
  const { businessId, isSelected, onClick } = props;

  return (
    <BusinessPanelControl
      className={isSelected ? "selected" : ""}
      onClickCapture={onClick}
      tabIndex={0}
    >
      <DeploymentBusinessComparePanel masterBusinessId={businessId} />
    </BusinessPanelControl>
  );
}

BusinessComparePanel.propTypes = {
  businessId: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};
