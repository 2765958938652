import React, { createRef, useState } from "react";

import { useMediaQuery } from "@material-ui/core";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { v4 as uuidv4 } from "uuid";

import {
  addConsignment,
  createPlaceholderAttachment,
  deleteConsignment,
  patchConsignment,
  setModalContext,
} from "actions";

import AuditLogLink from "components/AuditLog/AuditLogLink";
import ConsignmentForm from "components/ConsignmentForm";
import { Button, SecondaryButton } from "components/Form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { AuditLogTypes } from "constants/auditLog";
import { ConsignmentModalSection, ModalTypes } from "constants/navigation";
import {
  ConsignmentPermissions,
  DeploymentPermissions,
} from "constants/permissions";
import { ConsignableSaleTypes, SaleTypes } from "constants/sale";

import { getConsignmentPayload } from "lib/consignments";
import {
  closeAllHashModals,
  getLivestockSaleId,
  openAttachments,
  openEditConsignmentModal,
  openModalLink,
  returnToLocation,
} from "lib/navigation";

import {
  selectPropertyEnrichedBusinessByBusinessIdLookup,
  getCarrierCharges,
  getConsignmentIdsByCarrierChargeId,
  getConsignments,
  getConsignmentsByVendorNumber,
  getIsSaleyardAdmin,
  getNominationById,
  getPrimaryAgency,
  getSales,
  getSelectableBranches,
  selectAgencyBusinessByBusinessId,
  selectRoleCurrentDeploymentSales,
  getIsConsigningAgentAndSaleUnlocked,
  getHasWriteAccessInCurrentSale,
  getConsignmentById,
  getCurrentSale,
  currentSaleSelector,
  selectCanSeeOtherDeployments,
  getIsLivestockAgent,
} from "selectors";

import {
  useHasAddAttachmentWithoutImagePermission,
  useHasDeploymentPermission,
  useHasPermission,
  useTheme,
  useTranslatedSaleTypeText,
} from "hooks";

const Consignment = ({
  businesses,
  charges,
  consignmentsByCarrierChargeId,
  primaryAgency,
  sales,
  addConsignment,
  deleteConsignment,
  match,
  consignments,
  consignmentsByVendorNumber,
  patchConsignment,
  branches,
  consignmentId,
  showSaleLots,
  nominationId,
  agencyId,
  receivalLotId,
  returnTo,
  saleId,
  isSaleyardAdmin,
  restrictConsignedFrom,
  changeSection,
  section,
}) => {
  const dispatch = useDispatch();
  const formRef = createRef(null);
  const hasAddAttachmentWithoutImagePermission =
    useHasAddAttachmentWithoutImagePermission();
  const theme = useTheme();
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const agencyBusinessByBusinessIdLookup = useSelector(
    selectAgencyBusinessByBusinessId,
  );
  const saleTypeText = useTranslatedSaleTypeText("Consignment");
  const nomination = useSelector(getNominationById(nominationId));
  const saleType = useSelector(getCurrentSale)?.sale_type;

  const nominationHd = nomination?.nominationDetails?.reduce(
    (total, nomination) => nomination.quantity + total,
    0,
  );
  const isAttachmentShortCut = match.url.indexOf("attachments") > -1;

  function clearModalState() {
    dispatch(setModalContext(ModalTypes.Consignment, null));
    dispatch(setModalContext(ModalTypes.EditReceivalLot, null));
  }

  function onClose() {
    // when closing the modal - make sure to clear out the modal context values stored in state
    // we don't want to continue to populate forms with old changes
    clearModalState();
    if (returnTo) {
      returnToLocation(returnTo);
    } else {
      closeAllHashModals();
    }
  }
  const selectedSale = sales[saleId];
  const isEdit = Boolean(consignmentId);
  const titleActionString = isEdit ? "Edit" : "Create";

  const defaultsFromNomination = {};
  if (nominationId) {
    defaultsFromNomination.vendor_id = nomination.vendorId;
    defaultsFromNomination.vendor_property_id = nomination.propertyId;
    defaultsFromNomination.quantity = nominationHd;
    defaultsFromNomination.consignedFromId = nomination.consigningDeploymentId;
    defaultsFromNomination.nominationId = nomination.id;
  }

  const defaultsFromReceivalLot = {};
  if (receivalLotId) {
    defaultsFromReceivalLot.receivalLotIds = [receivalLotId];
  }

  const values = isEdit
    ? consignments[consignmentId]
    : {
        agency_id: agencyId,
        vendor_id: selectedSale.default_vendor_id,
        vendor_property_id: selectedSale.default_vendor_property_id,
        ...defaultsFromNomination,
        ...defaultsFromReceivalLot,
      };

  let carrierName = "";
  let associatedCarrierChargesCount = 0;

  // Null coalesce operator used to prevent crash when opening consignment modal
  // before API request has come back. E.g. Resuming from sleep
  if (values?.carrierChargeId) {
    const selectedCarrierCharges = charges[values.carrierChargeId];
    values.carrierCharge = selectedCarrierCharges;

    const carrierBusiness = businesses[selectedCarrierCharges.carrier];
    carrierName = carrierBusiness?.name;
    // 0 or one less then the length, as this is other consignments
    associatedCarrierChargesCount = Math.max(
      consignmentsByCarrierChargeId[values.carrierChargeId].length - 1,
      0,
    );
  }

  const sale = useSelector(currentSaleSelector);

  const handleSubmit = (values, errors, formRef, afterSubmit) => {
    // create consignment and create new attachment for the created consignment.
    // direct the user to the attachment form so they can edit their new NVD
    const {
      agency_id,
      saleLot,
      shouldUpdateVendorDefaultConsigningDeployment,
      ...consignmentValues
    } = values;

    // because we are grabbing formik values from a ref - use that ref to validate and show errors on the form
    if (!!formRef && !isEmpty(errors)) {
      formRef.current
        .validateForm()
        .then(errors =>
          formRef.current.setTouched({ ...formRef.current.touched, ...errors }),
        );
      return;
    }

    const agencyBusinesses =
      agencyBusinessByBusinessIdLookup[consignmentValues.vendor_id] || {};
    const deploymentBusiness = agencyBusinesses[agency_id];

    if (consignmentValues.useVendorAddressAsPickupAddress) {
      // we can't use the vendor's address AND specify a custom address, so clear it out
      consignmentValues.pickupAddress = null;
    }

    const consignmentPayload = getConsignmentPayload(consignmentValues);
    let tempId = null;

    // Make sure non-string fields that have been edited
    if (isEdit) {
      patchConsignment(consignmentPayload, consignmentId, {
        shouldUpdateVendorDefaultConsigningDeployment,
        changeReason: "Updated from edit consignment",
      });
    } else {
      tempId = uuidv4();
      const initialBranchId = deploymentBusiness?.branchId;
      addConsignment(
        tempId,
        consignmentPayload,
        selectedSale,
        null,
        initialBranchId,
        saleLot,
        agency_id,
        { shouldUpdateVendorDefaultConsigningDeployment },
      );
    }

    if (tempId && saleType === SaleTypes.PADDOCK) {
      // if we're in a paddock sale and create a new consignment
      // we want to throw the user straight into adding lots for that consignment.
      openEditConsignmentModal(tempId, ConsignmentModalSection.SALE_LOTS, true);
    } else if (tempId && receivalLotId) {
      // if we're passing in a recieval lot id - we're safe to assume that we're creating this consignment
      // from the receival lot modal - in which we want to redirct to that modal with the new consignment selected.

      // clear modal state once leaving the consignment form
      clearModalState();

      openModalLink(
        ModalTypes.EditReceivalLot,
        { receivalLotId, consignmentId: tempId },
        null,
      );
    } else if (afterSubmit && typeof afterSubmit === "function") {
      afterSubmit(tempId, agency_id);
      // clear modal state on after submit
      clearModalState();
    } else {
      onClose();
    }
  };

  const afterSubmitWithNvd = (newConsignmentId, agencyId) => {
    dispatch(
      createPlaceholderAttachment({
        ...sale,
        consignment: newConsignmentId,
        agency: agencyId,
      }),
    );

    openAttachments({
      consignment: newConsignmentId,
      selectedAgency: agencyId,
    });
  };

  const hasChangeConsignmentPermission = useHasPermission(
    getConsignmentById(consignmentId),
    ConsignmentPermissions.update,
  );

  const hasWriteAccessInCurrentSale = useSelector(
    state =>
      getHasWriteAccessInCurrentSale(state) ||
      getIsConsigningAgentAndSaleUnlocked(state),
  );

  const readOnly =
    !hasWriteAccessInCurrentSale || (isEdit && !hasChangeConsignmentPermission);

  function onClickSubmit() {
    formRef.current.submitForm();
  }

  function onClickSumbitWithNVD() {
    !readOnly &&
      !isEdit &&
      handleSubmit(
        formRef.current.values,
        formRef.current.errors,
        formRef,
        afterSubmitWithNvd,
      );
  }

  const fullScreen = useMediaQuery(`(max-width: ${theme.breakpoints[1]}px)`);

  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const canSeeOtherDeployments = useSelector(selectCanSeeOtherDeployments);

  const hasBillingPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureBilling,
  );

  const isConsignableSale = ConsignableSaleTypes.includes(
    selectedSale.sale_type,
  );

  // allow read only submit because
  // - users with billing permissions can edit the consignments vendor
  // - or if consign from field is available it also editable
  const canSubmitWithReadOnly =
    hasBillingPermission ||
    (isConsignableSale && canSeeOtherDeployments && isLivestockAgent);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      scroll="paper"
      fullScreen={fullScreen}
      data-tour="dialog"
    >
      <DialogTitle onClose={onClose}>
        {isEdit && (
          <AuditLogLink
            auditLogType={AuditLogTypes.CONSIGNMENT}
            dataId={consignmentId}
            returnTo={window.location.hash}
          />
        )}
        &nbsp;
        {titleActionString} {saleTypeText}
      </DialogTitle>
      <DialogContent dividers form>
        <ConsignmentForm
          carrierName={carrierName}
          section={section}
          changeSection={changeSection}
          consignmentId={consignmentId}
          livestockSaleId={getLivestockSaleId()}
          vendors={businesses}
          primaryAgency={primaryAgency}
          handleClose={onClose}
          consignmentsByVendorNumber={consignmentsByVendorNumber}
          values={values}
          handleSubmit={handleSubmit}
          isCreate={!isEdit}
          showSaleLots={showSaleLots && !isAttachmentShortCut}
          deleteConsignment={deleteConsignment}
          selectedSaleType={selectedSale.sale_type}
          branches={branches}
          formRef={formRef}
          setIsSubmitEnabled={setIsSubmitEnabled}
          associatedCarrierChargesCount={associatedCarrierChargesCount}
          requiresAgency={isSaleyardAdmin && !isEdit}
          restrictConsignedFrom={restrictConsignedFrom}
          readOnly={readOnly}
          defaultAgencyId={agencyId}
        />
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <Button
          data-tour="submit"
          onClick={onClickSubmit}
          disabled={(readOnly && !canSubmitWithReadOnly) || !isSubmitEnabled}
        >
          Submit
        </Button>
        {hasAddAttachmentWithoutImagePermission &&
          !receivalLotId &&
          !isEdit && (
            <Button
              data-tour="submitWithNVD"
              onClick={onClickSumbitWithNVD}
              disabled={readOnly || !isSubmitEnabled}
            >
              Submit + NVD
            </Button>
          )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  const consignmentsByCarrierChargeId = getConsignmentIdsByCarrierChargeId(
    state,
    props,
  );
  return {
    sales: getSales(state),
    businesses: selectPropertyEnrichedBusinessByBusinessIdLookup(state),
    consignmentsByCarrierChargeId,
    charges: getCarrierCharges(state),
    primaryAgency: getPrimaryAgency(state),
    consignments: getConsignments(state),
    consignmentsByVendorNumber: getConsignmentsByVendorNumber(state, props),
    offlineLookup: state.offlineTemp,
    branches: getSelectableBranches(state, props),
    isSaleyardAdmin: getIsSaleyardAdmin(state),
    restrictConsignedFrom: selectRoleCurrentDeploymentSales(state).length === 0,
  };
};

const mapDispatchToProps = {
  addConsignment,
  deleteConsignment,
  getPrimaryAgency,
  patchConsignment,
};

Consignment.propTypes = {
  businesses: PropTypes.any,
  primaryAgency: PropTypes.any,
  sales: PropTypes.any,
  addConsignment: PropTypes.any,
  deleteConsignment: PropTypes.func,
  match: PropTypes.object,
  consignments: PropTypes.object,
  patchConsignment: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(Consignment);
