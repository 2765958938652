import React from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { openBusinessPaymentsModal } from "lib/navigation";

import { ClickableColumn } from "./columnStyles";

export const buyerPaymentsRenderer = ({ value, data, businessId }) => {
  if (!data) {
    return null;
  }
  const editOnClick = () => {
    if (data[businessId]) {
      return openBusinessPaymentsModal(data[businessId]);
    } else {
      return null;
    }
  };

  return (
    <ClickableColumn onClick={editOnClick}>
      {value}
      <FontAwesomeIcon icon={faPencil} />
    </ClickableColumn>
  );
};

export default buyerPaymentsRenderer;
