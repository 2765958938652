import { createSelector } from "reselect";

import { getBillingTags } from "selectors";

export const selectBillingTagOptions = createSelector(
  [getBillingTags],
  billingTags =>
    Object.values(billingTags).map(billingTag => ({
      value: billingTag.id,
      label: billingTag.name,
    })),
);
