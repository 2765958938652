import { uniq } from "lodash";

import {
  HandledNonMappedStatuses,
  WeighLotScanStatus,
  WeighLotScanStatusColor,
  WeighLotScanStatusText,
} from "constants/weighScanning";

export const buildWeighLotScanStatusDetails = weighLotStatus => {
  if (weighLotStatus === WeighLotScanStatus.DEDUPLICATED) {
    return {
      status: WeighLotScanStatusText[WeighLotScanStatus.DEDUPLICATED],
      colour: WeighLotScanStatusColor[WeighLotScanStatus.DEDUPLICATED],
    };
  }
  if (weighLotStatus === WeighLotScanStatus.IGNORED) {
    return {
      status: WeighLotScanStatusText[WeighLotScanStatus.IGNORED],
      colour: WeighLotScanStatusColor[WeighLotScanStatus.IGNORED],
    };
  }
  if (weighLotStatus === WeighLotScanStatus.REWEIGHED) {
    return {
      status: WeighLotScanStatusText[WeighLotScanStatus.REWEIGHED],
      colour: WeighLotScanStatusColor[WeighLotScanStatus.REWEIGHED],
    };
  }
  if (weighLotStatus === WeighLotScanStatus.MAPPED) {
    return {
      status: WeighLotScanStatusText[WeighLotScanStatus.MAPPED],
      colour: WeighLotScanStatusColor[WeighLotScanStatus.MAPPED],
    };
  }
  if (weighLotStatus === WeighLotScanStatus.MANUALLY_MAPPED) {
    return {
      status: WeighLotScanStatusText[WeighLotScanStatus.MANUALLY_MAPPED],
      colour: WeighLotScanStatusColor[WeighLotScanStatus.MANUALLY_MAPPED],
    };
  }
  if (weighLotStatus === WeighLotScanStatus.MAPPED_TO_SALE_LOT) {
    return {
      status: WeighLotScanStatusText[WeighLotScanStatus.MAPPED_TO_SALE_LOT],
      colour: WeighLotScanStatusColor[WeighLotScanStatus.MAPPED_TO_SALE_LOT],
    };
  }
  if (weighLotStatus === WeighLotScanStatus.UNRESOLVED) {
    return {
      status: WeighLotScanStatusText[WeighLotScanStatus.UNRESOLVED],
      colour: WeighLotScanStatusColor[WeighLotScanStatus.UNRESOLVED],
      showWarning: true,
    };
  }
  if (weighLotStatus === WeighLotScanStatus.REWEIGH) {
    return {
      status: WeighLotScanStatusText[WeighLotScanStatus.REWEIGH],
      colour: WeighLotScanStatusColor[WeighLotScanStatus.REWEIGH],
      showWarning: true,
    };
  }
  return {
    status: WeighLotScanStatusText[WeighLotScanStatus.NEW_EID],
    colour: WeighLotScanStatusColor[WeighLotScanStatus.NEW_EID],
    showWarning: true,
  };
};

export const calculateWeighLotScanStatus = (
  weighLotScan,
  scansLookup,
  weighLotIdsByEidlookup,
) => {
  const associatedScan = scansLookup[weighLotScan.eid];
  // if the current weigh lot scan exists in a seperate weigh lot and this weigh lot scan is unresolved - make as reweigh
  if (
    uniq(weighLotIdsByEidlookup[weighLotScan.eid]).length > 1 &&
    weighLotScan.status !== WeighLotScanStatus.DEDUPLICATED &&
    weighLotScan.status === WeighLotScanStatus.UNRESOLVED
  ) {
    return WeighLotScanStatus.REWEIGH;
  } else if (
    !associatedScan &&
    !HandledNonMappedStatuses.includes(weighLotScan.status)
  ) {
    return WeighLotScanStatus.NEW_EID;
  } else {
    return weighLotScan.status;
  }
};
