import { useSelector } from "react-redux";

import { getLedgerAccountByCode } from "selectors";

export const GLCodeRenderer = props => {
  const { value } = props;

  const ledgerAccount = useSelector(getLedgerAccountByCode(value));

  if (ledgerAccount) {
    return `${ledgerAccount.code} (${ledgerAccount.name})`;
  }
  return value;
};
