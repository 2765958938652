import React from "react";

import { useSelector } from "react-redux";

import { scanLotConfig } from "lib/scanLot";

import StoredScanRow from "./StoredScanRow";

const StoredScanRowList = ({ scanLotId, penType }) => {
  const { getEidsByLotId } = scanLotConfig(penType);

  const storedScans = useSelector(getEidsByLotId(scanLotId)).filter(Boolean);
  return (
    <>
      {storedScans.map(eid => (
        <StoredScanRow eid={eid} />
      ))}
    </>
  );
};

export default StoredScanRowList;
