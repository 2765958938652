import React, { memo, useContext, useState } from "react";

import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch } from "react-redux";

import { printSaleLotWeightSheet } from "actions";

import { MultiButton } from "components/Button";
import { SecondaryMultiButton } from "components/Button/MultiButton";
import { Row } from "components/Layout";
import {
  ScannerSelectInline,
  HubStatusIndicator,
} from "components/ScanningScreen";

import { IndicatorStatusInline } from "containers/WeightIndicator";

import { FooterLayout } from "./Layout";
import { useWeighBridgeSaleLotUpdater } from "./WeighBridgeCurrentLotForm";
import {
  WeighBridgeSaleDispatchContext,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";
import { useWeighBridgeEidsUpdater } from "./WeighBridgeScanning";
import { useWeighBridgeWeightUpdater } from "./WeighBridgeWeighing";

const ProgressionMode = {
  NEXT: 0,
  NEXT_AND_PRINT: 1,
};

const SaveMode = {
  SAVE: 0,
  SAVE_AND_CREATE: 1,
};

function WeighBridgeCurrentLotFooterComponent(props) {
  const {
    createOrUpdateSaleLot,
    isAccumulatedWeightsValid,
    isAccumulatedWeightsDirty,
    isEidsDirty,
    isEidsValid,
    onNextSaleLot,
    isSaleLotDirty,
    isSaleLotValid,
    isSellingDetailsDirty,
    saleLotId,
    updateSaleLotEids,
    updateSelectedSaleLotId,
  } = props;

  const { clearAccumulatedWeights, updateRecentlyWeighedSaleLotIds } =
    useContext(WeighBridgeSaleDispatchContext);
  const dispatch = useDispatch();
  const [progressionMode, setProgressionMode] = useState(ProgressionMode.NEXT);
  const [saveMode, setSaveMode] = useState(SaveMode.SAVE);

  function onClickNext() {
    setProgressionMode(ProgressionMode.NEXT);
    onNextSaleLot();
  }

  function onClickPrintandNext() {
    setProgressionMode(ProgressionMode.NEXT_AND_PRINT);
    dispatch(printSaleLotWeightSheet(saleLotId, true));
    onNextSaleLot();
  }

  function saveAll() {
    let offlineSaleLotId = saleLotId;
    if (isSaleLotDirty || isSellingDetailsDirty || isAccumulatedWeightsDirty) {
      offlineSaleLotId = createOrUpdateSaleLot(
        offlineSaleLotId,
        saleLotId === null,
        false,
        // Only update the weight if there are weights to update
        isAccumulatedWeightsDirty,
      );
    }
    if (isAccumulatedWeightsDirty) {
      clearAccumulatedWeights();
    }
    if (isEidsDirty) {
      updateSaleLotEids(offlineSaleLotId, false);
    }

    updateRecentlyWeighedSaleLotIds(offlineSaleLotId);
  }

  function onClickSaveAll() {
    setSaveMode(SaveMode.SAVE);
    saveAll();
  }
  function onClickSaveAllAndCreate() {
    setSaveMode(SaveMode.SAVE_AND_CREATE);
    saveAll();
    // Clear the form ready to create another.
    // (Note - the verbage is crossed around a bit here - this is a LOCAL UI STATE update,
    // NOT a server dispatched update)
    updateSelectedSaleLotId(null);
  }

  const areAllSectionsClean =
    !isAccumulatedWeightsDirty &&
    !isSaleLotDirty &&
    !isEidsDirty &&
    !isSellingDetailsDirty;

  const isSaleLotSectionValid = isSaleLotDirty
    ? isSaleLotValid // If the Sale Lot is dirty, then it is required to be valid
    : saleLotId !== null; // the Sale Lot is required when no saleLotId is set

  const isWeightSectionValid = isAccumulatedWeightsDirty
    ? isAccumulatedWeightsValid // the weights are required to be valid when they are present
    : true;

  const isEidsSectionValid = isEidsDirty
    ? isEidsValid // the eids are required to be valid when they are present
    : true;

  const areAllSectionsValid =
    isSaleLotSectionValid && isWeightSectionValid && isEidsValid;

  const isSaveDisabled = areAllSectionsClean || !areAllSectionsValid;
  const isPrintDisabled = !areAllSectionsClean || saleLotId === null;

  let saveTitle = "Save all unsaved changes";
  if (areAllSectionsClean) {
    saveTitle = "You must make changes before they can be saved";
  } else if (!areAllSectionsValid) {
    if (!saleLotId && !isSaleLotDirty) {
      saveTitle =
        "You must complete the form, or select and existing Sale Lot before changes can be saved";
    } else if (!isWeightSectionValid) {
      saveTitle = "There are errors with the captured weights";
    } else if (!isSaleLotSectionValid) {
      saveTitle = "There are errors with the entered form values";
    } else if (!isEidsSectionValid) {
      saveTitle = "There are errors with the captured EIDs";
    }
  }

  return (
    <FooterLayout>
      <Row justifyBetween>
        <Row>
          <HubStatusIndicator />
          <IndicatorStatusInline />
          <ScannerSelectInline listPosition="above" />
        </Row>
        <Row justifyBetween>
          <Row paddingHorizontal={1}>
            <MultiButton
              ButtonComponent={SecondaryMultiButton}
              buttons={[
                {
                  title: "Save All",
                  onClick: onClickSaveAll,
                  default: saveMode === SaveMode.SAVE,
                  tooltip: saveTitle,
                  isDisabled: isSaveDisabled,
                  dataTour: "saveAll",
                },

                {
                  title: "Save All & Create New",
                  onClick: onClickSaveAllAndCreate,
                  tooltip: saveTitle,
                  default: saveMode === SaveMode.SAVE_AND_CREATE,
                  isDisabled: isSaveDisabled,
                },
              ]}
            >
              Save All
            </MultiButton>
          </Row>
          <Row paddingHorizontal={1}>
            <MultiButton
              data-tour="weighBridgeNext"
              buttons={[
                {
                  title: "Next",
                  onClick: onClickNext,
                  default: progressionMode === ProgressionMode.NEXT,
                },

                {
                  title: "Next & Print",
                  icon: faPrint,
                  onClick: onClickPrintandNext,
                  isDisabled: isPrintDisabled,
                  default: progressionMode === ProgressionMode.NEXT_AND_PRINT,
                },
              ]}
            />
          </Row>
        </Row>
      </Row>
    </FooterLayout>
  );
}

const ConnectedFooter = memo(WeighBridgeCurrentLotFooterComponent);

function StateAdapter(props) {
  const { onNextSaleLot } = props;

  const {
    isAccumulatedWeightsDirty,
    isAccumulatedWeightsValid,
    isEidsDirty,
    isEidsValid,
    isSaleLotDirty,
    isSaleLotValid,
    isSellingDetailsDirty,
    selectedSaleLotId,
  } = useContext(WeighBridgeSaleStateContext);

  const { updateSelectedSaleLotId } = useContext(
    WeighBridgeSaleDispatchContext,
  );

  const createOrUpdateSaleLot = useWeighBridgeSaleLotUpdater();
  const updateSaleLotWeight = useWeighBridgeWeightUpdater();
  const updateSaleLotEids = useWeighBridgeEidsUpdater();
  return (
    <ConnectedFooter
      createOrUpdateSaleLot={createOrUpdateSaleLot}
      isAccumulatedWeightsDirty={isAccumulatedWeightsDirty}
      isAccumulatedWeightsValid={isAccumulatedWeightsValid}
      isEidsDirty={isEidsDirty}
      isEidsValid={isEidsValid}
      isSaleLotDirty={isSaleLotDirty}
      isSaleLotValid={isSaleLotValid}
      isSellingDetailsDirty={isSellingDetailsDirty}
      onNextSaleLot={onNextSaleLot}
      saleLotId={selectedSaleLotId}
      updateSaleLotEids={updateSaleLotEids}
      updateSaleLotWeight={updateSaleLotWeight}
      updateSelectedSaleLotId={updateSelectedSaleLotId}
    />
  );
}

export default memo(StateAdapter);
