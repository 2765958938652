import React, { useEffect, useRef, useState } from "react";

import { faMobile } from "@fortawesome/free-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { invalidateTableView } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { MultiButton } from "components/Button";
import { GroupedThirdPartyCard } from "components/BuyerCards/BuyerCards";
import { DataTour } from "components/DataTour";
import { Row } from "components/Layout";
import ReportsDropdown from "components/ReportsDropdown";
import SearchInput from "components/SearchInput";

import { AgGridTables } from "constants/aggrid";
import { Settings } from "constants/settings";
import { userTypes } from "constants/users";

import { CurrentAgencySelector } from "containers/ConnectedSelector";

import {
  currentSaleSelector,
  getActiveRole,
  getAgencyById,
  getSaleLotsByBuyer,
  getSettings,
  selectAgencyIdByConsignmentIdLookup,
} from "selectors";

import { isLoadingSaleReports } from "selectors/loading";

import { useIsMobile } from "hooks";

import { getSaleReportsColumns } from "./columnDefs";

const SaleReportsTable = ({ reportData, links }) => {
  const isLoading = useSelector(isLoadingSaleReports);
  const { date, saleyard_name } = useSelector(currentSaleSelector);
  const activeRole = useSelector(getActiveRole);

  const isBusinessUser = activeRole.type === userTypes.BUSINESS_USER;

  const isCommercialUser = isBusinessUser && activeRole.is_commercial_user;
  const isComplianceUser = isBusinessUser && activeRole.is_compliance_user;

  const columnDefinitions = getSaleReportsColumns(
    activeRole.type,
    isCommercialUser,
    isComplianceUser,
  );

  return (
    <SaleReportsTableFilterWrapper
      columnDefinitions={columnDefinitions}
      date={date}
      isBusinessUser={isBusinessUser}
      isCommercialUser={isCommercialUser}
      saleyard_name={saleyard_name}
      reportData={reportData}
      links={links}
      isLoading={isLoading}
    />
  );
};

const StyledAgencySelector = styled(CurrentAgencySelector)`
  width: 100%;
`;

const SaleReportsTableFilterWrapper = props => {
  const {
    columnDefinitions,
    date,
    isLoading,
    isBusinessUser,
    isCommercialUser,
    links,
    reportData,
    saleyard_name,
  } = props;
  const dispatch = useDispatch();
  const [quickFilterText, setQuickFilterText] = useState("");
  const agencyId =
    useSelector(state => getSettings(state)[Settings.agencyId]) || -1;
  const agency = useSelector(getAgencyById(agencyId));

  const isScreenSizeMobile = useIsMobile();
  const [isMobile, setIsMobile] = useState(isScreenSizeMobile);
  const thirdPartyBuyers = useSelector(getSaleLotsByBuyer);
  const agencyIdByConsignmentIdLookup = useSelector(
    selectAgencyIdByConsignmentIdLookup,
  );
  const agencyIdRef = useRef(agencyId);

  useEffect(() => {
    if (agencyIdRef.current !== agencyId) {
      dispatch(invalidateTableView(AgGridTables.REPORTS));
      agencyIdRef.current = agencyId;
    }
  }, [agencyId, agencyIdRef, dispatch]);

  const agencyIdByConsignmentIdLookupRef = useRef(
    agencyIdByConsignmentIdLookup,
  );
  agencyIdByConsignmentIdLookupRef.current = agencyIdByConsignmentIdLookup;

  const showCommercialData =
    (isBusinessUser && isCommercialUser) || !isBusinessUser;
  const isReadOnly = isBusinessUser;

  const doesExternalFilterPass = ({ data }) => {
    const consignmentAgencyId =
      agencyIdByConsignmentIdLookupRef.current[data.consignment.id];
    return Boolean(agencyIdRef.current === consignmentAgencyId);
  };

  const isExternalFilterPresent = () => {
    return Boolean(agencyIdRef.current > -1);
  };

  useEffect(() => {
    setIsMobile(isScreenSizeMobile);
  }, [setIsMobile, isScreenSizeMobile]);

  const reportLinks = React.useMemo(() => {
    let linksModified = [];
    if (links.buyer.length > 0) {
      const filterByAgent = agencyId !== -1;
      linksModified = links.buyer.reduce(
        // if not consolidated report add agency short name to the end of linkurl
        (
          accum,
          {
            name: linkName,
            url: linkUrl,
            file_type,
            business_name: businessName,
          },
        ) => {
          const url = filterByAgent
            ? `${linkUrl}${
                // determine if there are already query parameters in the link
                linkUrl.includes("?") ? "&" : "?"
              }agency_shortname=${agency?.shortName}`
            : linkUrl;
          accum.push({
            name: filterByAgent
              ? `${agency?.name} - ${linkName} - ${businessName}`
              : `Consolidated ${linkName} - ${businessName}`,
            url,
            file_type,
          });
          return accum;
        },
        [],
      );
    }
    return linksModified;
  }, [agency, agencyId, links.buyer]);

  const reportTitle = `Download ${
    agencyId > 0 ? agency?.name : "Consolidated"
  } Reports`;

  const extraHeaderComponents = [
    <ReportsDropdown
      options={reportLinks}
      title={reportTitle}
      key="reportLinks"
    />,
  ];

  const onQuickFilterChange = value => {
    setQuickFilterText(value);
  };
  const additionalActionButtions = [
    {
      title: isMobile ? "Desktop View" : "Mobile View",
      icon: faMobile,
      onClick: () => {
        setIsMobile(!isMobile);
      },
      dataTour: "mobileView",
    },
  ];

  return !isMobile ? (
    <AgGridTable
      downloadFilename={`${date}-${saleyard_name}-BuyerReport.csv`}
      columnDefs={columnDefinitions}
      rowData={reportData}
      isExternalFilterPresent={isExternalFilterPresent}
      doesExternalFilterPass={doesExternalFilterPass}
      extraHeaderComponents={extraHeaderComponents}
      defaultView={-1}
      isLoading={isLoading}
      tableName={AgGridTables.REPORTS}
      showGlobalSearchFilter={false}
      showSimpleAgencyFilter
      showTextFilter
      additionalActions={additionalActionButtions}
    />
  ) : reportData.length > 0 ? (
    <>
      <Row justifyCenter>
        <ReportsDropdown options={reportLinks} title={reportTitle} />
        <MultiButton buttons={additionalActionButtions} />
      </Row>
      <Grid container>
        <Grid item xs={6}>
          <StyledAgencySelector includeAll />
        </Grid>
        <Grid item xs={6}>
          <DataTour dataTour="quick-filter">
            <SearchInput
              value={quickFilterText.toLowerCase()}
              handleChange={onQuickFilterChange}
              style={{ marginLeft: 0 }}
            />
          </DataTour>
        </Grid>
      </Grid>
      {thirdPartyBuyers.map(thirdParty => (
        <GroupedThirdPartyCard
          readOnly={isReadOnly}
          key={`gtpc-${thirdParty.id}`}
          searchText={quickFilterText.toLowerCase()}
          agencyId={agencyId > 0 ? agencyId : null}
          filterValues={{}}
          showCommercialData={showCommercialData}
          thirdParty={thirdParty}
        />
      ))}
    </>
  ) : (
    <p style={{ textAlign: "center" }}>No rows to show</p>
  );
};

SaleReportsTable.propTypes = {
  reportData: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired,
};

export default SaleReportsTable;
