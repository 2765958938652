import React from "react";

import { QuestionnaireRow } from "components/AttachmentForm/Components";
import {
  NVDOptionToggler,
  DatePurchasedOptionsToggler,
  NVD_OPTIONS,
} from "components/AttachmentForm/NVDOptionToggles";
import { Input } from "components/Form/FormikControls";
import { Column } from "components/Layout";

export const S0413 = ({ formikProps }) => (
  <Column>
    <QuestionnaireRow>
      <NVDOptionToggler
        labelPosition="top"
        name="declaration.post_breeder_tags"
        label="Post Breeder Tags Applied"
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.qa_accredited" label="1." />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.treated_for_scabby_mouth"
        label="2."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.owned_since_birth" label="3." />
    </QuestionnaireRow>

    {formikProps.values.declaration.owned_since_birth === NVD_OPTIONS.NO && (
      <QuestionnaireRow>
        <DatePurchasedOptionsToggler
          flexDirection="column"
          name="declaration.owned_since_birth_duration"
          label="How long ago were the animals obtained?"
          labelPosition="top"
        />
      </QuestionnaireRow>
    )}

    <QuestionnaireRow>
      <NVDOptionToggler name="declaration.veterinary_treatment" label="4." />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.consumed_material_in_withholding_period"
        label="5."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <NVDOptionToggler
        name="declaration.fed_feed_containing_animal_fats"
        label="6."
      />
    </QuestionnaireRow>

    <QuestionnaireRow>
      <Input name="declaration.additional_information" label="7." />
    </QuestionnaireRow>
  </Column>
);
