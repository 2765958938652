import queryString from "query-string";

import { TRADING_TERM } from "constants/actionTypes";
import { DeploymentPermissions } from "constants/permissions";

import { hasPermission } from "lib/permissions";
import { serializeTradingTerm } from "lib/serializers/tradingTerms";

import {
  getActiveLivestockAgentDeployment,
  getIsFetchingTradingTerms,
} from "selectors";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/trading-terms/", id, action),
    query: {
      changesSince,
    },
  });

export const TradingTermAction = offlineActionCreator(
  TRADING_TERM,
  serializeTradingTerm,
  urlCreator,
  false,
);

TradingTermAction.actionFilter = (state, changes = false) => {
  const enabled = hasPermission(
    getActiveLivestockAgentDeployment(state),
    DeploymentPermissions.featureTradingTerms,
  );

  if (!enabled) {
    return null;
  } else if (!changes) {
    return TradingTermAction.request();
  } else if (changes && !getIsFetchingTradingTerms(state)) {
    return TradingTermAction.requestChanges({
      changesSince: state.tradingTerms.lastModifiedTimestamp,
    });
  } else {
    // we requested changes but are fetching already.
    return null;
  }
};
