import React from "react";

import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import { getHasWriteAccessInCurrentSale } from "selectors";

export const ReadOnly = ({ readOnly, showLock, children = null }) => {
  if (readOnly && showLock) {
    return <FontAwesomeIcon icon={faLock} />;
  } else if (!readOnly) {
    return children;
  } else {
    return null;
  }
};

export const CurrentSaleReadOnly = connect(state => ({
  readOnly: !getHasWriteAccessInCurrentSale(state),
}))(ReadOnly);
