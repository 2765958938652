import * as Yup from "yup";

import { PenScanLotAction } from "actions/penScanLots";
import { ReceivalLotAction } from "actions/receivalLots";

import { PenTypes } from "constants/auctionPens";
import { CommentTypes } from "constants/comments";
import { ModalTypes } from "constants/navigation";
import {
  PenScanLotPermissions,
  ReceivalLotPermissions,
} from "constants/permissions";

import {
  getEidsByPenScanLotId,
  getEidsByReceivalLotId,
  getNextReceivalPenByPenIdAndArchetypeId,
  getNextSellingPenByPenIdAndArchetypeId,
  getPenScanLotById,
  getPenScanLots,
  getPenScanLotsIdsBySellingPenId,
  getPenScanLotStatusById,
  getPrevReceivalPenByPenIdAndArchetypeId,
  getPrevSellingPenByPenIdAndArchetypeId,
  getReceivalLotById,
  getReceivalLots,
  getReceivalLotsIdsByReceivalPenId,
  getReceivalLotStatusById,
  getReceivalPenGroupByIndex,
  getSellingPenGroupByIndex,
  getStatusByReceivalPenId,
  getStatusBySellingPenId,
  getUnresolvedCommentCountByPenScanLotId,
  getUnresolvedCommentCountByReceivalLotId,
  getUsedReceivalPenGroupByIndex,
  getUsedSellingPenGroupByIndex,
  selectPenScanLotIdsBySellingPenIdLookup,
  selectReceivalLotIdsByReceivalPenIdLookup,
  selectStatusByReceivalPenIdLookup,
  selectStatusBySellingPenIdLookup,
  selectUsedPenScanLotIdsBySellingPenId,
  selectUsedReceivalLotIdsByReceivalPenId,
  selectUsedReceivalPenGroups,
  selectUsedSellingPenGroups,
  selectEidsByReceivalLotIdLookup,
  selectEidsByPenScanLotIdLookup,
  getNextReceivalPenTitleByPenIdOrPenArchetypeId,
  getPrevReceivalPenTitleByPenIdOrPenArchetypeId,
  getNextSellingPenTitleByPenIdAndPenArchetypeId,
  getPrevSellingPenTitleByPenIdAndPenArchetypeId,
  selectReceivalLotIdByEidLookup,
  selectPenScanLotIdByEidLookup,
  selectFilteredReceivalPenGroups,
  selectFilteredSellingPenGroups,
} from "selectors";

// receival lot values pattern
const receivalLotMarksValuesPattern = value => [{ location: value }];

// pen scan lot values pattern
const penScanLotMarksValuesPattern = value => value;

// receival scanning validation schema
const arrivalScanningValidation = Yup.object().shape({
  quantity: Yup.number()
    .required("You must enter a quantity for the arrival scans.")
    .min(1, "Quantity must be more than 0"),
  mark: Yup.string().required("You must enter a mark for the arrival scans."),
  hasMarksConflict: Yup.bool().isFalse(),
});

// pen scanning validation schema
const penScanningValidation = Yup.object().shape({
  marks: Yup.mixed()
    .required("You must select at least one mark.")
    .test("marks", "You must select at least one mark.", value =>
      Boolean(value && value.length),
    ),
  quantity: Yup.number()
    .required("You must enter a quantity for pen scans.")
    .min(1, "Quantity must be more than 0"),
  deploymentId: Yup.number().required("Please select an agency"),
  saleRoundId: Yup.number().required("Please select a round"),
});

export const scanLotConfig = penType => {
  switch (penType) {
    case PenTypes.RECEIVING:
      return {
        commentType: CommentTypes.RECEIVAL_LOT,
        editLotModalType: ModalTypes.EditReceivalLot,
        getEidsByLotId: getEidsByReceivalLotId,
        getLotById: getReceivalLotById,
        getLotIdsByPenId: getReceivalLotsIdsByReceivalPenId,
        getLotStatusByLotIdLookup: getReceivalLotStatusById,
        getNextPenByPenIdAndArchetypeId:
          getNextReceivalPenByPenIdAndArchetypeId,
        getNextPenTitleByPenIdOrPenArchetypeId:
          getNextReceivalPenTitleByPenIdOrPenArchetypeId,
        getPenGroupByIndex: getReceivalPenGroupByIndex,
        getPreviousPenByPenIdAndArchetypeId:
          getPrevReceivalPenByPenIdAndArchetypeId,
        getPreviousPenTitleByPenIdAndPenArchetypeId:
          getPrevReceivalPenTitleByPenIdOrPenArchetypeId,
        getUnresolvedCommentCountByLotId:
          getUnresolvedCommentCountByReceivalLotId,
        getUsedPenGroupByIndex: getUsedReceivalPenGroupByIndex,
        lotAction: ReceivalLotAction,
        lotIdReference: "receivalLotId",
        lotMarksReference: "mark",
        lotMarksValuesPattern: receivalLotMarksValuesPattern,
        lotPenIdReference: "receivalPenId",
        lotTitle: "Receival Lot",
        permissions: ReceivalLotPermissions,
        penShortName: "Rec Pen",
        penTitle: "Receival Pen",
        scanningValidationSchema: arrivalScanningValidation,
        scanReference: "receival_lot_id",
        selectLotByIdLookup: getReceivalLots,
        selectLotIdsByPenIdLookup: selectReceivalLotIdsByReceivalPenIdLookup,
        selectPenGroups: selectFilteredReceivalPenGroups,
        selectStatusByPenIdLookup: selectStatusByReceivalPenIdLookup,
        getPenStatusByPenIdLookup: getStatusByReceivalPenId,
        selectUsedLotIdsByPenIdLookup: selectUsedReceivalLotIdsByReceivalPenId,
        selectUsedPenGroups: selectUsedReceivalPenGroups,
        selectEidsByScanLotIdLookup: selectEidsByReceivalLotIdLookup,
        selectLotIdByEidLookup: selectReceivalLotIdByEidLookup,
      };

    case PenTypes.SELLING:
      return {
        commentType: CommentTypes.PEN_SCAN_LOT,
        editLotModalType: ModalTypes.EditPenScanLot,
        getEidsByLotId: getEidsByPenScanLotId,
        getLotById: getPenScanLotById,
        getLotIdsByPenId: getPenScanLotsIdsBySellingPenId,
        getLotStatusByLotIdLookup: getPenScanLotStatusById,
        getNextPenByPenIdAndArchetypeId: getNextSellingPenByPenIdAndArchetypeId,
        getNextPenTitleByPenIdOrPenArchetypeId:
          getNextSellingPenTitleByPenIdAndPenArchetypeId,
        getPenGroupByIndex: getSellingPenGroupByIndex,
        getPreviousPenByPenIdAndArchetypeId:
          getPrevSellingPenByPenIdAndArchetypeId,
        getPreviousPenTitleByPenIdAndPenArchetypeId:
          getPrevSellingPenTitleByPenIdAndPenArchetypeId,
        getUnresolvedCommentCountByLotId:
          getUnresolvedCommentCountByPenScanLotId,
        getUsedPenGroupByIndex: getUsedSellingPenGroupByIndex,
        lotAction: PenScanLotAction,
        lotIdReference: "penScanLotId",
        lotMarksReference: "marks",
        lotMarksValuesPattern: penScanLotMarksValuesPattern,
        lotPenIdReference: "sellingPenId",
        lotTitle: "Pen Scan Lot",
        permissions: PenScanLotPermissions,
        penShortName: "Auc Pen",
        penTitle: "Selling Pen",
        scanningValidationSchema: penScanningValidation,
        scanReference: "pen_scan_lot_id",
        selectLotByIdLookup: getPenScanLots,
        selectLotIdsByPenIdLookup: selectPenScanLotIdsBySellingPenIdLookup,
        selectPenGroups: selectFilteredSellingPenGroups,
        selectStatusByPenIdLookup: selectStatusBySellingPenIdLookup,
        getPenStatusByPenIdLookup: getStatusBySellingPenId,
        selectUsedLotIdsByPenIdLookup: selectUsedPenScanLotIdsBySellingPenId,
        selectUsedPenGroups: selectUsedSellingPenGroups,
        selectEidsByScanLotIdLookup: selectEidsByPenScanLotIdLookup,
        selectLotIdByEidLookup: selectPenScanLotIdByEidLookup,
      };

    default:
      // eslint-disable-next-line no-console
      return console.error("a pen type is required");
  }
};
