import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import LoadingSpinner from "components/LoadingSpinner";
import MessageBox from "components/MessageBox";
import OpenedInNewWindow from "components/Pdfs/OpenedInNewWindow";

import { PlacardVersions } from "constants/pdf";

import {
  getIsFetchingAuctionPens,
  getIsFetchingBusinesses,
  getIsFetchingConsignments,
  getIsFetchingSaleLots,
  getIsFetchingSales,
  getIsFetchingScans,
} from "selectors";

export const RenderPlacard = ({
  action,
  includeWeight = false,
  size,
  version,
}) => {
  const isFetchingSaleLots = useSelector(getIsFetchingSaleLots);
  const isFetchingConsignments = useSelector(getIsFetchingConsignments);
  const isFetchingAuctionPens = useSelector(getIsFetchingAuctionPens);
  const isFetchingScans = useSelector(getIsFetchingScans);
  const isFetchingSales = useSelector(getIsFetchingSales);
  const isFetchingBusinesses = useSelector(getIsFetchingBusinesses);

  const { params } = useRouteMatch();
  const dispatch = useDispatch();
  const { saleLotId, round, agentId } = params;

  let actionCall = null;

  switch (version) {
    case PlacardVersions.V1:
      actionCall = () => action(saleLotId, round, false, agentId);
      break;
    case PlacardVersions.V2:
      actionCall = () => action(saleLotId, round, agentId);
      break;
    case PlacardVersions.V3:
      actionCall = () => action(saleLotId, round, agentId, includeWeight, size);
      break;
    case PlacardVersions.V5:
      actionCall = () => action(saleLotId, round, false, agentId);
      break;

    default:
      actionCall = () => action(saleLotId, round, agentId);
  }

  const isLoading =
    isFetchingSaleLots ||
    isFetchingConsignments ||
    isFetchingAuctionPens ||
    isFetchingScans ||
    isFetchingSales ||
    isFetchingBusinesses;
  useEffect(() => {
    if (!isLoading) {
      dispatch(actionCall());
    }
  }, [
    dispatch,
    isLoading,
    saleLotId,
    round,
    agentId,
    action,
    includeWeight,
    size,
    actionCall,
  ]);
  return (
    <MessageBox>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <OpenedInNewWindow message="The Placard was opened in a new window." />
      )}
    </MessageBox>
  );
};
