import React from "react";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { bulkUpdateAuctionPen } from "actions";

import { SelectableAuctionPenCard } from "components/AuctionPens/Cards/SelectableAuctionPenCard";
import { SecondaryButton, Button } from "components/Form";
import { Row } from "components/Layout";

import { Settings } from "constants/settings";

import { sortPenByOrder } from "lib/auctionPens";
import { openAuctionPenCardsRoute } from "lib/navigation";

import {
  getPenIdsByRoundId,
  getAuctionPens,
  getSetting,
  selectSaleLotIdsByAuctionPenIdLookup,
} from "selectors";

const ListWrapper = styled.div`
  padding: 24px 12px 12px;
  overflow-x: scroll;
  padding-bottom: 100px;
`;

const ActionsWrapper = styled(Row)`
  position: fixed;
  bottom: 0;
  background-color: white;
  box-shadow: 0 -2px 4px 0 rgba(200, 200, 200, 0.6);
  height: 92px;
  padding: 24px;
  width: 100%;
  display: flex;
  z-index: 1;
`;

const InfoText = styled.span`
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 12px;
`;

export const LaneSetup = () => {
  const selectedRoundId = useSelector(getSetting(Settings.round));
  const unsortedAuctionPenIds = useSelector(
    getPenIdsByRoundId(selectedRoundId),
  );
  const allAuctionPens = useSelector(getAuctionPens);
  const saleLotIdsByAuctionPenId = useSelector(
    selectSaleLotIdsByAuctionPenIdLookup,
  );

  const auctionPens = unsortedAuctionPenIds
    .filter(auctionPenId => saleLotIdsByAuctionPenId[auctionPenId]?.length > 0)
    .map(auctionPenId => allAuctionPens[auctionPenId]);
  const auctionPenIds = sortPenByOrder(auctionPens).map(
    auctionPen => auctionPen.id,
  );

  const lanes = auctionPens.reduce((accum, auctionPen) => {
    if (auctionPen.id) {
      accum[auctionPen.id] = !!auctionPen.isLaneStart;
    }
    return accum;
  }, {});

  const dispatch = useDispatch();
  const handleSubmit = values => {
    const payload = Object.entries(lanes).reduce(
      (acc, [auctionPenId, isLaneStart]) => {
        if (values[auctionPenId] !== isLaneStart) {
          acc.push({
            id: auctionPenId,
            isLaneStart: values[auctionPenId],
          });
        }
        return acc;
      },
      [],
    );

    dispatch(bulkUpdateAuctionPen(payload));
    openAuctionPenCardsRoute();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={lanes}
      onSubmit={handleSubmit}
      component={formikProps => {
        const { handleSubmit, setFieldValue, handleBlur, values, isValid } =
          formikProps;

        return (
          <>
            <ListWrapper>
              <Row alignCenter justifyCenter>
                <InfoText>Tap to select first pen of lane</InfoText>
              </Row>
              {auctionPenIds.map(auctionPenId => (
                <SelectableAuctionPenCard
                  auctionPenId={auctionPenId}
                  key={auctionPenId}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isLaneStart={values[`${auctionPenId}`]}
                />
              ))}
            </ListWrapper>

            <ActionsWrapper alignCenter justifyCenter>
              <SecondaryButton type="button" onClick={openAuctionPenCardsRoute}>
                Cancel
              </SecondaryButton>
              <Button
                data-tour="submit"
                type="submit"
                onClick={handleSubmit}
                disabled={!isValid}
              >
                Confirm
              </Button>
            </ActionsWrapper>
          </>
        );
      }}
    />
  );
};
