import React from "react";

import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Row } from "components/Layout";

import { getBidders, getBusinessById } from "selectors";

import BuyerDetail from "./BuyerDetail";
import RegisteredBidderGrid from "./RegisteredBidderGrid";

const BidderInformation = styled.div`
  ${({ disabled }) => disabled && "opacity: 0.33; cursor: default;"}
`;

const AccountDetails = styled(Row)`
  box-shadow: ${({ theme }) => theme.shadows[1]};
  margin: ${({ theme }) => theme.space[2]}px;
  background: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  align-items: center;
`;
const AccountLabel = styled.span(
  ({ theme }) => `
  color: ${theme.colors.titleLight};
  font-weight ${theme.fontWeights.normal};
  font-size: ${theme.fontSizes.epsilon}px;
  padding:  ${theme.space[4]}px ${theme.space[1]}px;
  text-transform: uppercase;
`,
);

const AccountName = styled.span(
  ({ theme }) => `
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.epsilon}px;
  max-width: 400px;
  padding: ${theme.space[4]}px ${theme.space[3]}px;
  color: ${theme.colors.titleDark};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`,
);

function BidderSelect(props) {
  const { bidderId, bidderFilter, onSelectBidder, onClearBidder, isDisabled } =
    props;
  const bidder = useSelector(state => getBidders(state)[bidderId]) || {};
  const buyer = useSelector(getBusinessById(bidder.businessId)) || {};

  const buyerName = buyer ? buyer.name : "-";
  const buyerAccount = bidder ? bidder.buyerWay : "-";
  const registrationNumber = bidder ? bidder.registrationNumber : "";

  if (bidderId) {
    return (
      <>
        <BuyerDetail
          disabled={isDisabled}
          value={registrationNumber}
          unsetDetail={onClearBidder}
        />
        <BidderInformation disabled={isDisabled}>
          <AccountDetails justifyBetween>
            <AccountLabel>Bidder</AccountLabel>
            <AccountName title={buyerName}>{buyerName}</AccountName>
            <AccountLabel>Account</AccountLabel>
            <AccountName title={buyerAccount}>{buyerAccount}</AccountName>
          </AccountDetails>
        </BidderInformation>
      </>
    );
  }
  if (!isDisabled) {
    return (
      <RegisteredBidderGrid
        filter={bidderFilter}
        onSelectBidder={onSelectBidder}
      />
    );
  }
  return null;
}

export default BidderSelect;
