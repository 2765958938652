import * as Yup from "yup";

const penScanningValidation = {
  quantity: Yup.number("Invalid number entered. Please enter a numeric value.")
    .required("You must enter a quantity for the arrival scans.")
    .min(1, "Quantity must be more than 0"),
  marks: Yup.array().min(1, "Marks are required"),
};

export const simplePenScanLotValidationSchema = Yup.object().shape(
  penScanningValidation,
);

export const sellingPenValidation = {
  sellingPenId: Yup.string().required("You must select a selling pen."),
};

export const extendedPenScanLotValidationSchema = Yup.object().shape({
  ...penScanningValidation,
  ...sellingPenValidation,
});
