import queryString from "query-string";

import { PEN_SCAN_LOT } from "constants/actionTypes";

import { serializePenScanLot } from "lib/serializers/penScanLots";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, livestockSaleId, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/pen-scan-lots/", id, action),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const PenScanLotAction = offlineActionCreator(
  PEN_SCAN_LOT,
  serializePenScanLot,
  urlCreator,
  true,
);

PenScanLotAction.updateOrCreateWithPenAndScans = (
  penScanLotId,
  sellingPenId,
  sellingPenArchetypeId,
  penScanLotValues,
  scans,
  duplicateScans,
) => ({
  type: PEN_SCAN_LOT.UPDATE_OR_CREATE_WITH_PEN.REQUEST,
  penScanLotId,
  sellingPenId,
  sellingPenArchetypeId,
  penScanLotValues,
  scans,
  duplicateScans,
});

PenScanLotAction.allocateAction = penScanLotIds => ({
  type: PEN_SCAN_LOT.ALLOCATE.ACTION,
  payload: penScanLotIds,
  meta: {
    offline: {
      effect: {
        url: "/v2/pen-scan-lots/allocate/",
        method: "POST",
        body: JSON.stringify({ pen_scan_lot_ids: penScanLotIds }),
      },
      commit: { type: PEN_SCAN_LOT.ALLOCATE.SUCCESS, meta: { penScanLotIds } },
      rollback: { type: PEN_SCAN_LOT.ALLOCATE.FAILURE },
    },
  },
});

PenScanLotAction.allocateRequest = () => ({
  type: PEN_SCAN_LOT.ALLOCATE.REQUEST,
});
